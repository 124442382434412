import React, { memo } from 'react';
import { bool, number, shape, string } from 'prop-types';

import { t } from 'shared/utils';

import Description from './components/Description/Description';
import Header from './components/Header/Header';

import styles from './PriceContainer.module.css';

const PriceContainer = ({ plan, vrMainBankAccount, planDetails: { defaultPrice } }) => (
  <div className={styles.main}>
    <div className={styles.block}>
      <div className={styles.content}>{t('profile.payment_plan.your_choice')}</div>
    </div>
    <div className={styles.block}>
      <Header plan={plan} vrMainBankAccount={vrMainBankAccount} defaultPrice={defaultPrice} />
      <Description plan={plan} vrMainBankAccount={vrMainBankAccount} />
    </div>
  </div>
);

PriceContainer.propTypes = {
  plan: string,
  vrMainBankAccount: bool,
  planDetails: shape({
    defaultPrice: number,
  }),
};

export default memo(PriceContainer);
