import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import {
  deleteAllBankTransferConnections as deleteAllBankTransferConnectionsIncomingInvoice,
  togglePaidByCash as paidByCashIncomingInvoice,
  togglePaidByUnspecified as paidByUnspecifiedIncomingInvoice,
} from 'actions/incoming-invoices';
import {
  deleteAllBankTransferConnections as deleteAllBankTransferConnectionsOutgoingInvoice,
  togglePaidByCash as paidByCashOugoingInvoice,
  togglePaidByUnspecified as paidByUnspecifiedOutgoingInvoice,
} from 'actions/outgoing-invoice';
import { OPTION_DISCOUNT, OPTIONS_ARRAY } from 'constants/assigment-select';
import { OUTGOING_INVOICE } from 'constants/folder-names';
import BankTransfersSelect from 'containers/BankTransfersSelect';
import styles from 'containers/BankTransfersSelect/BankTransfersSelect.module.css';
import { suggestionsEnabledHelper } from 'routes/accesses';
import { t } from 'shared/utils';
import { checkIfDiscountGranted } from 'shared/utils/checkIfDiscountApplicable';
import PaymentAttentionModal from 'components/BankActivity/PaymentAttentionModal/PaymentAttentionModal';

import CashAssignmentMode from './AssigmentMode/AssignmentMode';
import BankTransferAssignmentMode from './AssigmentMode/BankTransferAssignmentMode';
import UnspecifiedAssignmentMode from './AssigmentMode/UnspecifiedAssignmentMode';
import AssignedBankTransfers from './AssignedBankTransfers/AssignedBankTransfers';
import Suggestions from './Suggestions/Suggestions';

import style from './BankActivity.module.css';

class BankActivity extends Component {
  state = {
    isPaidWithCashModalVisible: false,
    isPaidWithUnspecifiedModalVisible: false,
    isCashAssignmentModeVisible: false,
    isUnspecifiedAssignmentModeVisible: false,
    assigmentModeData: null, // bank transfer data for the assigment mode
    editAssigmentData: null, // bank transfer currently being in the edit mode
  };

  setCashAssignmentModeVisible = (isCashAssignmentModeVisible) =>
    this.setState({ isCashAssignmentModeVisible });
  setUnspecifiedAssignmentModeVisible = (isUnspecifiedAssignmentModeVisible) =>
    this.setState({ isUnspecifiedAssignmentModeVisible });

  handleOptionSelect = (option) => {
    const { incomingInvoiceBankTransfers, outgoingInvoiceBankTransfers, whichFolder } = this.props;

    const assignedBankTransfers =
      whichFolder === OUTGOING_INVOICE
        ? outgoingInvoiceBankTransfers
        : incomingInvoiceBankTransfers;

    if (option.paidByCash && !isEmpty(assignedBankTransfers)) {
      return this.setState({
        isPaidWithCashModalVisible: true,
      });
    }

    if (option.paidByUnspecified && !isEmpty(assignedBankTransfers)) {
      return this.setState({
        isPaidWithUnspecifiedModalVisible: true,
      });
    }

    if (option.paidByCash) {
      return this.setCashAssignmentModeVisible(true);
    }

    if (option.paidByUnspecified) {
      return this.setUnspecifiedAssignmentModeVisible(true);
    }

    return this.setState({
      assigmentModeData: option,
      editAssigmentData: null,
    });
  };

  handleWarningModalAccept = () => {
    const {
      currentInvoiceDetails: { id: invoiceId },
      incomingInvoiceBankTransfers,
      outgoingInvoiceBankTransfers,
      deleteAllConnectionsOutgoingInvoice,
      deleteAllConnectionsIncomingInvoice,
      whichFolder,
    } = this.props;
    const { isPaidWithCashModalVisible, isPaidWithUnspecifiedModalVisible } = this.state;

    if (isPaidWithCashModalVisible) {
      this.setCashAssignmentModeVisible(true);
    }

    if (isPaidWithUnspecifiedModalVisible) {
      this.setUnspecifiedAssignmentModeVisible(true);
    }

    this.setState({
      isPaidWithCashModalVisible: false,
      isPaidWithUnspecifiedModalVisible: false,
    });

    const assignedBankTransfers =
      whichFolder === OUTGOING_INVOICE
        ? outgoingInvoiceBankTransfers
        : incomingInvoiceBankTransfers;

    if (!isEmpty(assignedBankTransfers)) {
      const deleteAssignedBankTransfers =
        whichFolder === OUTGOING_INVOICE
          ? deleteAllConnectionsOutgoingInvoice
          : deleteAllConnectionsIncomingInvoice;

      deleteAssignedBankTransfers(invoiceId);
    }
  };

  // This method runs after accepting CashAssignmentMode
  togglePaidByCash = async (date) => {
    const {
      currentInvoiceDetails: { id, paidByCash },
      togglePaidByCashOugoingInvoice,
      togglePaidByCashIncomingInvoice,
      whichFolder,
    } = this.props;
    const togglePaidByCash =
      whichFolder === OUTGOING_INVOICE
        ? togglePaidByCashOugoingInvoice
        : togglePaidByCashIncomingInvoice;

    await togglePaidByCash(id, !paidByCash, date);
    this.setCashAssignmentModeVisible(false);
  };

  // This method runs after accepting UnspecifiedAssignmentMode
  togglePaidByUnspecified = async (date, reason) => {
    const {
      currentInvoiceDetails: { id, paidByUnspecified },
      togglePaidByUnspecifiedOutgoingInvoice,
      togglePaidByUnspecifiedIncomingInvoice,
      whichFolder,
    } = this.props;
    const togglePaidByUnspecified =
      whichFolder === OUTGOING_INVOICE
        ? togglePaidByUnspecifiedOutgoingInvoice
        : togglePaidByUnspecifiedIncomingInvoice;

    await togglePaidByUnspecified(id, !paidByUnspecified, date, reason);
    this.setUnspecifiedAssignmentModeVisible(false);
  };

  handleWarningModalClose = () => {
    this.setState({
      isPaidWithCashModalVisible: false,
      isPaidWithUnspecifiedModalVisible: false,
    });
  };

  handleAssigmentBoxClose = () => {
    this.setState({
      assigmentModeData: null,
      editAssigmentData: null,
    });
  };

  handleBankTransferEdit = (bankTransfer) => {
    this.setState({
      assigmentModeData: null,
      editAssigmentData: bankTransfer,
    });
  };

  render() {
    const {
      disabled = false,
      readonly,
      isDraft = false,
      suggestionsEnabled = false,
      currentInvoiceDetails,
      whichFolder,
    } = this.props;
    const {
      assigmentModeData,
      editAssigmentData,
      isCashAssignmentModeVisible,
      isUnspecifiedAssignmentModeVisible,
      isPaidWithCashModalVisible,
      isPaidWithUnspecifiedModalVisible,
    } = this.state;

    // Create new connection or edit existing in AssigmentMode component
    const dataForAssigmentMode = assigmentModeData || editAssigmentData;

    const isBankTransferAssignmentModeVisible = assigmentModeData || editAssigmentData;

    // Show discount aassigment option only if discount is active
    const isDiscountActive = checkIfDiscountGranted(currentInvoiceDetails);
    const selectOptions = isDiscountActive
      ? OPTIONS_ARRAY
      : OPTIONS_ARRAY.filter(({ value }) => value !== OPTION_DISCOUNT.value);

    return (
      <div className={cx(styles.formGroup, { [styles.readonly]: readonly })}>
        <div className={style.transfersTitle}>{t('assigment.transfers_title')}</div>
        {!readonly && (
          <Fragment>
            {!assigmentModeData &&
              !editAssigmentData &&
              !isPaidWithUnspecifiedModalVisible &&
              !isUnspecifiedAssignmentModeVisible && (
                <Field
                  name="bankActivity"
                  dataId="bankActivity"
                  component={BankTransfersSelect}
                  whichFolder={whichFolder}
                  handleOptionSelect={this.handleOptionSelect}
                  status={currentInvoiceDetails.status}
                  disabled={disabled}
                />
              )}
            {isBankTransferAssignmentModeVisible && (
              <BankTransferAssignmentMode
                onClose={this.handleAssigmentBoxClose}
                whichFolder={whichFolder}
                invoiceDetails={currentInvoiceDetails}
                selectOptions={selectOptions}
                isEditMode={!!editAssigmentData}
                {...dataForAssigmentMode}
              />
            )}
            {isCashAssignmentModeVisible && (
              <CashAssignmentMode
                onAccept={(date) => this.togglePaidByCash(date)}
                onClose={() => this.setCashAssignmentModeVisible(false)}
              />
            )}
            {isUnspecifiedAssignmentModeVisible && (
              <UnspecifiedAssignmentMode
                onAccept={(date, reason) => this.togglePaidByUnspecified(date, reason)}
                onClose={() => this.setUnspecifiedAssignmentModeVisible(false)}
              />
            )}
            {!isDraft && suggestionsEnabled && (
              <Suggestions
                handleSuggestionSelect={this.handleOptionSelect}
                whichFolder={whichFolder}
                assigmentModeData={assigmentModeData}
              />
            )}
          </Fragment>
        )}
        <AssignedBankTransfers
          bankTransferInEditMode={editAssigmentData}
          onEdit={this.handleBankTransferEdit}
          readonly={readonly}
          whichFolder={whichFolder}
          invoiceDetails={currentInvoiceDetails}
        />
        <PaymentAttentionModal
          isOpen={isPaidWithCashModalVisible || isPaidWithUnspecifiedModalVisible}
          isPaidBy={{ isPaidWithCashModalVisible, isPaidWithUnspecifiedModalVisible }}
          onClose={this.handleWarningModalClose}
          onAccept={this.handleWarningModalAccept}
        />
      </div>
    );
  }
}

BankActivity.propTypes = {
  disabled: PropTypes.bool, // Bank transfer select disabled
  readonly: PropTypes.bool, // Bank transfer select hidden
  isDraft: PropTypes.bool,
  suggestionsEnabled: PropTypes.bool,
  currentInvoiceDetails: PropTypes.shape({}).isRequired,
  whichFolder: PropTypes.string.isRequired,
  togglePaidByCashOugoingInvoice: PropTypes.func.isRequired,
  togglePaidByCashIncomingInvoice: PropTypes.func.isRequired,
  incomingInvoiceBankTransfers: PropTypes.arrayOf(PropTypes.shape({})),
  outgoingInvoiceBankTransfers: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapDispatchToProps = (dispatch) => ({
  togglePaidByCashOugoingInvoice: (invoiceId, isPaidByCash, date) =>
    dispatch(paidByCashOugoingInvoice(invoiceId, isPaidByCash, date)),
  togglePaidByCashIncomingInvoice: (invoiceId, isPaidByCash, date) =>
    dispatch(paidByCashIncomingInvoice(invoiceId, isPaidByCash, date)),
  togglePaidByUnspecifiedOutgoingInvoice: (invoiceId, isPaidByUnspecified, date, reason) =>
    dispatch(paidByUnspecifiedOutgoingInvoice(invoiceId, isPaidByUnspecified, date, reason)),
  togglePaidByUnspecifiedIncomingInvoice: (invoiceId, isPaidByUnspecified, date, reason) =>
    dispatch(paidByUnspecifiedIncomingInvoice(invoiceId, isPaidByUnspecified, date, reason)),
  deleteAllConnectionsIncomingInvoice: (invoiceId) =>
    dispatch(deleteAllBankTransferConnectionsIncomingInvoice(invoiceId)),
  deleteAllConnectionsOutgoingInvoice: (invoiceId) =>
    dispatch(deleteAllBankTransferConnectionsOutgoingInvoice(invoiceId)),
});

export default connect(
  (state) => ({
    suggestionsEnabled: suggestionsEnabledHelper(state),
    incomingInvoiceBankTransfers: state.incomingInvoice.bankTransfers || [],
    outgoingInvoiceBankTransfers: state.outgoingInvoice.bankTransfers,
  }),
  mapDispatchToProps
)(BankActivity);
