import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { Supplier } from 'types/entities/Supplier';
import SuggestionsField, {
  SuggestionsFieldProps,
} from 'components/SuggestionsField/SuggestionsField';

import Option from './Option';
import SearchSuppliers from './SearchSuppliers';
import { itemToString } from './utils';

export type SupplierFieldProps = Omit<
  SuggestionsFieldProps<Supplier>,
  'itemToString' | 'search' | 'option'
>;

const SupplierField = (props: SupplierFieldProps & WrappedFieldProps) => (
  <SuggestionsField<Supplier>
    search={SearchSuppliers}
    itemToString={itemToString}
    option={Option}
    {...props}
  />
);

export default SupplierField;
