import { CALL_API } from 'constants/api';
import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/contents';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiFetchContent = () => ({
  [CALL_API]: {
    endpoint: `/contents`,
    authRequired: false,
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const fetchContent = () => (dispatch) => dispatch(apiFetchContent()).catch(apiErrorHandler);
