import React, { SVGProps } from 'react';

const MonthlyOverview = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 191 118" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#198228" d="M160.012 0h16.42v94.829h-16.42z" />
      <path fill="#146423" d="M160.012 60.878h16.42v33.951h-16.42z" />
      <path fill="#C00" d="M136.555 42.146h16.42v52.683h-16.42z" />
      <path fill="#96001E" d="M136.555 67.902h16.42v26.927h-16.42z" />
      <path fill="#198228" d="M99.025 32.78h16.42v62.05h-16.42z" />
      <path fill="#146423" d="M99.025 83.121h16.42v11.707h-16.42z" />
      <path fill="#C00" d="M75.568 51.512h16.42V94.83h-16.42z" />
      <path fill="#96001E" d="M75.568 83.121h16.42v11.707h-16.42z" />
      <path fill="#198228" d="M38.037 9.366h16.42v85.463h-16.42z" />
      <path fill="#146423" d="M38.037 78.439h16.42v16.39h-16.42z" />
      <path fill="#C00" d="M14.58 67.903H31V94.83H14.58z" />
      <path fill="#96001E" d="M14.58 78.439H31v16.39H14.58z" />
      <path stroke="#979797" strokeLinecap="square" d="M1.092 94.244H189.92" />
      <text
        fontFamily="FrutigerVR-Bold, Frutiger VR"
        fontSize="15"
        fontWeight="bold"
        fill="#333"
        transform="translate(.506)"
      >
        <tspan x="142.685" y="114">
          Sept
        </tspan>
      </text>
      <text
        fontFamily="FrutigerVR-Bold, Frutiger VR"
        fontSize="15"
        fontWeight="bold"
        fill="#333"
        transform="translate(.506)"
      >
        <tspan x="81.972" y="114">
          Aug
        </tspan>
      </text>
      <text
        fontFamily="FrutigerVR-Bold, Frutiger VR"
        fontSize="15"
        fontWeight="bold"
        fill="#333"
        transform="translate(.506)"
      >
        <tspan x="22.782" y="114">
          Jul
        </tspan>
      </text>
    </g>
  </svg>
);

export default MonthlyOverview;
