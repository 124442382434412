import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import iconCancel from 'images/icon-cancel.svg';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import I18n from 'components/I18n';
import Modal from 'components/Modal';
import modalStyles from 'components/Modal/Modal.module.css';

import localStyles from './ConfirmationModalWithAlert.module.css';

const ConfirmationModalWithAlert = ({ isOpen, onClose, onConfirm }) => (
  <Modal className={modalStyles.infoModal} isOpen={isOpen} onRequestClose={onClose}>
    <div className={cx(modalStyles.header, localStyles.localHeader)}>
      <I18n
        t="product_catalog.product_catalog_items.delete_confirmation_alert_header"
        className={localStyles.headerText}
      />
      <button className={localStyles.closeButton} onClick={onClose}>
        <img src={iconCancel} alt={t('modals.cancel')} />
      </button>
    </div>
    <div className={modalStyles.body}>
      <div className={localStyles.message}>
        <I18n t="product_catalog.product_catalog_items.delete_confirmation_alert_messege" />
      </div>
      <div className={localStyles.message}>
        <I18n t="product_catalog.product_catalog_items.delete_confirmation_alert_question" />
      </div>
      <div className={modalStyles.confirmationButtons}>
        <ActionButton
          appearance="primary"
          label={t('product_catalog.product_catalog_items.delete_confirmation_alert_cancel')}
          onClick={onClose}
        />
        <ActionButton
          appearance="outlined"
          label={t('product_catalog.product_catalog_items.delete_confirmation_alert_confirm')}
          onClick={onConfirm}
        />
      </div>
    </div>
  </Modal>
);

ConfirmationModalWithAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmationModalWithAlert;
