import normalize from 'json-api-normalizer';
import find from 'lodash/find';
import build from 'redux-object';

import {
  ACCEPT_SUGGESTION_SUCCESS,
  CREATE_SUCCESS,
  DELETE_SUCCESS,
} from 'constants/bank-transfer-connections';
import { FETCH_SUCCESS } from 'constants/outgoing-invoice';
import { toMoment } from 'shared/utils';

const formatBankTransfer = ({ attributes, id }) => ({
  ...attributes,
  id,
  date: toMoment(attributes.date),
});

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
    case CREATE_SUCCESS:
    case DELETE_SUCCESS:
      if (!action.response.included) return [];
      const [head] =
        build(normalize(action.response), 'outgoingInvoices', undefined, {
          eager: true,
          ignoreLinks: true,
        }) || [];

      const response = head || {};

      if (!response.bankTransfers) return response.bankTransfers;

      return response.bankTransfers.map((bankTransfer) => {
        const transferConnection = find(
          response.bankTransferConnections,
          ({ bankTransferId }) => bankTransferId.toString() === bankTransfer.id
        );

        return {
          ...bankTransfer,
          transferConnection: transferConnection || null,
        };
      });
    case ACCEPT_SUGGESTION_SUCCESS:
      return [...state, formatBankTransfer(action.response.data)];
    default:
      return state;
  }
};
