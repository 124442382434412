import { isNumber } from 'lodash';

import { REORDER_LINE_ITEMS_LOCAL, REORDER_LINE_ITEMS_SUCCESS } from 'constants/order-confirmation';
import {
  CREATE_SUCCESS,
  DELETE_SUCCESS,
  FETCH_SUCCESS,
  UPDATE_SUCCESS,
} from 'constants/order-confirmation/line-items';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return action.response.data.map(({ attributes, id }) => ({ ...attributes, id }));

    case DELETE_SUCCESS:
      return state.filter((i) => i.id !== action.response.data.id);

    case UPDATE_SUCCESS:
      return state.map((i) => {
        if (i.id !== action.response.data.id) return i;

        return {
          ...action.response.data.attributes,
          id: action.response.data.id,
        };
      });

    case CREATE_SUCCESS:
      return [
        ...state,
        {
          ...action.response.data.attributes,
          id: action.response.data.id,
        },
      ];

    case REORDER_LINE_ITEMS_LOCAL:
      const updatedItems = action.payload || [];
      return state.map((lineItem) => {
        const updatedItem = updatedItems.find((i) => i.id === lineItem.id) || {};
        return {
          ...lineItem,
          ordinalNumber: isNumber(updatedItem.ordinalNumber)
            ? updatedItem.ordinalNumber
            : lineItem.ordinalNumber,
        };
      });

    case REORDER_LINE_ITEMS_SUCCESS:
      const response = action.response || {};
      return state.map((lineItem) => ({
        ...lineItem,
        ordinalNumber: isNumber(response[lineItem.id])
          ? response[lineItem.id]
          : lineItem.ordinalNumber,
      }));

    default:
      return state;
  }
};
