import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { createAndFetchNewOrderConfirmation } from 'actions/order-confirmation';
import { NEW_MODE } from 'constants/common/crud';
import { FORM_NAME } from 'constants/order-confirmation';
import { withTransitionPrevent, withWarningAlert } from 'shared/hoc';
import { t } from 'shared/utils';
import useLineItemsControl, { SectionType } from 'components/LineItems/useLineItemControl';
import OrderConfirmationCreator from 'features/orderConfirmation/OrderConfirmationCreator/OrderConfirmationCreator';

const NewOrderConfirmation = ({ prepareNewOrderConfirmation }) => {
  const {
    manualHandleSelectCategory,
    setLineItemState,
    setShowCategoryChangeConfirmation,
    setShowDifferentCategoryWarning,
    setShowVatIdWarning,
    showDifferentCategoryWarning,
    showCategoryChangeConfirmation,
    defaultCategory,
    canSubmit,
    showVatIdWarning,
    category,
    taxRates,
    taxRateId,
    defaultVat,
    hasSavedLineItems,
    selectedCategory,
    lineCategories,
    isInitial,
  } = useLineItemsControl({
    sectionType: SectionType.orderConfirmation,
    formName: FORM_NAME,
    isNewForm: true,
  });

  useEffect(() => {
    prepareNewOrderConfirmation();
  }, []);

  return (
    <OrderConfirmationCreator
      crudMode={NEW_MODE}
      lineCategories={lineCategories}
      hasSavedLineItems={hasSavedLineItems}
      selectedCategory={selectedCategory}
      defaultCategory={defaultCategory}
      handleSelectCategory={manualHandleSelectCategory}
      category={category}
      showCategoryChangeConfirmation={showCategoryChangeConfirmation}
      setShowCategoryChangeConfirmation={setShowCategoryChangeConfirmation}
      taxRateId={taxRateId}
      taxRates={taxRates}
      defaultVat={defaultVat}
      isInitial={isInitial}
      canSubmit={canSubmit}
      setLineItemState={setLineItemState}
      showVatIdWarning={showVatIdWarning}
      setShowVatIdWarning={setShowVatIdWarning}
      showDifferentCategoryWarning={showDifferentCategoryWarning}
      setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
    />
  );
};

const mapStateToProps = () => ({
  buttonLabel: t(
    'empty_entry_pages.order_confirmations.order_confirmation_warning_box.button_label'
  ),
  content: t('empty_entry_pages.order_confirmations.order_confirmation_warning_box.content_1'),
  dataId: 'OrderConfirmations:orange-warning-container',
});

const mapDispatchToProps = (dispatch) => ({
  prepareNewOrderConfirmation: () => dispatch(createAndFetchNewOrderConfirmation()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    persistentSubmitErrors: true,
  }),
  withTransitionPrevent(),
  withWarningAlert
)(NewOrderConfirmation);
