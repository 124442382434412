import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api';

export const getBankAccounts = () => {
  const url = '/me/bank_accounts';

  return api.get<JsonApiResponse>(url);
};

export const deleteBankAccount = (id: string) => {
  const url = `/me/bank_accounts/${id}`;

  return api.delete<JsonApiResponse>(url);
};

type UpdateBankAccountData = {
  name?: string;
  credit_line?: number;
  bank_name?: string;
};

export const updateBankAccount = (id: string, data: UpdateBankAccountData) => {
  const url = `/me/bank_accounts/${id}`;

  return api.put<JsonApiResponse>(url, data);
};

export const setMainBankAccount = (id: string) => {
  const url = `/me/bank_accounts/${id}/set_main_bank_account`;

  return api.post<JsonApiResponse>(url);
};

export const deleteMassBankAccount = (bankCode: string) => {
  const url = `/me/bank_accounts/mass_delete/${bankCode}`;

  return api.delete<JsonApiResponse>(url);
};
