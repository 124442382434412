import React, { memo, ReactNode } from 'react';

import styles from './Table.module.css';

type TableProps = {
  children: ReactNode;
};

const Table = ({ children }: TableProps) => <div className={styles.table}>{children}</div>;

export default memo(Table);
