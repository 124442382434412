import React from 'react';
import Recaptcha from 'react-recaptcha';
import { noop } from 'lodash';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { MODAL_HEADER_VARIANT } from 'constants/modal';
import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import { Client } from 'types/entities/Client';
import ActionButton from 'components/ActionPanel/ActionButton';
import Card from 'components/Card';
import { Checkbox, TextareaField, TextField } from 'components/Form';
import Section from 'components/Form/Section/Section';
import I18n from 'components/I18n';
import { ModalHeader } from 'components/Modal';

import {
  ButtonContainer,
  CheckboxContainer,
  Hint,
  InputWrapper,
  ModalContainer as Modal,
  RecaptchaContainer,
  SectionContainer,
  SpaceWrapper,
  TextAreaContainer,
} from './EmailEditor.styled';
import { email, required } from './validate';

export type FormData = {
  email: string;
  subject: string | null;
  content: string | null;
  sendCarbonCopy: boolean | null;
  updateClientsEmail: boolean | null;
};

type RecaptchaOptions = {
  isRecaptchaVisible: boolean;
  recaptchaSitekey: string;
  reloadRecaptcha: () => void;
  changeGrecaptchaResponse: (response: string) => void;
};

type EmailEditorProps = {
  onClose: () => void;
  sendMail: (data: FormData) => void;
  title: string;
  client: Client;
  recaptchaOptions?: RecaptchaOptions;
};

const BaseEmailEditor = ({
  onClose,
  handleSubmit,
  sendMail,
  title,
  client,
  invalid,
  submitting,
  recaptchaOptions,
}: EmailEditorProps & InjectedFormProps<FormData, EmailEditorProps>) => (
  <Modal isOpen contentLabel="Modal">
    <ModalHeader withCloseButton onClose={onClose} headerVariant={MODAL_HEADER_VARIANT.SMALL}>
      {title}
    </ModalHeader>
    <form onSubmit={handleSubmit(sendMail)}>
      <Card>
        <Card.Body withMargin>
          <SpaceWrapper>
            <Section title={t('modals.send_email_defaults.recipient')} />
          </SpaceWrapper>
          <SectionContainer>
            <InputWrapper>
              <Field
                name="email"
                dataId="input-email"
                component={TextField}
                clearable
                placeholder={t('modals.send_email_defaults.client_email')}
                checker={checker}
                validate={[required, email]}
                showValidationErrorText
              />
              {client && (
                <CheckboxContainer $withBottomSpace>
                  <Field
                    name="updateClientsEmail"
                    dataId="input-update-clients-email"
                    component={Checkbox}
                    checker={checker}
                  >
                    <I18n t="modals.send_email_defaults.update_clients_mail" />
                  </Field>
                </CheckboxContainer>
              )}
            </InputWrapper>
          </SectionContainer>
          <SectionContainer>
            <InputWrapper>
              <Field
                name="carbonCopy"
                dataId="input-cc"
                component={TextField}
                clearable
                placeholder={t('modals.send_email_defaults.cc')}
                checker={checker}
                validate={[email]}
                showValidationErrorText
              />
              <Hint>{t('modals.send_email_defaults.cc_hint')}</Hint>
            </InputWrapper>
          </SectionContainer>
          <CheckboxContainer $withBottomSpace>
            <Field
              name="sendCopyToMe"
              dataId="input-send-carbon-copy"
              component={Checkbox}
              checker={checker}
            >
              <I18n t="modals.send_email_defaults.send_copy_to_me" />
            </Field>
          </CheckboxContainer>

          <Section title={t('modals.send_email_defaults.email')} />
          <Field
            name="subject"
            dataId="input-subject"
            component={TextField}
            clearable
            placeholder={t('modals.send_email_defaults.subject')}
            checker={checker}
          />
          <TextAreaContainer>
            <Field
              name="content"
              dataId="input-message"
              component={TextareaField}
              clearable
              placeholder={t('modals.send_email_defaults.message')}
              checker={checker}
              label={t('modals.send_email_defaults.message')}
            />
          </TextAreaContainer>

          {recaptchaOptions && recaptchaOptions.isRecaptchaVisible && (
            <RecaptchaContainer>
              <Recaptcha
                sitekey={recaptchaOptions.recaptchaSitekey}
                verifyCallback={recaptchaOptions.changeGrecaptchaResponse}
                onloadCallback={noop}
                render="explicit"
                hl="de"
              />
            </RecaptchaContainer>
          )}

          <ButtonContainer>
            <ActionButton
              appearance="outlined"
              label={t('modals.send_email_defaults.cancel_button')}
              onClick={onClose}
              dataId="cancel-button"
            />
            <ActionButton
              type="submit"
              dataId="send-button"
              label={t('modals.send_email_defaults.submit_button')}
              disabled={invalid || submitting}
            />
          </ButtonContainer>
        </Card.Body>
      </Card>
    </form>
  </Modal>
);

export default reduxForm<FormData, EmailEditorProps>({
  form: 'emailEditor',
})(BaseEmailEditor);
