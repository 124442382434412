import React, { memo, useCallback, useEffect, useState } from 'react';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { getBankAccounts } from 'api/me/bankAccounts';
import { getCashbooks } from 'api/me/cashbooks';
import EntityPath from 'constants/entitiesPaths';
import { BANK_ACCOUNTS_TILE_GO_TO_BANK_ACCOUNTS } from 'constants/piwik';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { BankAccount } from 'types/entities/BankAccount';
import { Cashbook } from 'types/entities/Cashbook';
import TileTitle from 'redesign/components/atoms/TileTitle/TileTitle';

import Empty from './Empty';
import List from './List';
import { trackEvent } from './utils';

type BankAccountsTileProps = {
  className?: string;
};

const BankAccountsTile = ({ className }: BankAccountsTileProps) => {
  const [data, setData] = useState<Array<BankAccount | Cashbook>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isEmpty = data.length === 0;

  const handleTitleClick = useCallback(() => {
    trackEvent(BANK_ACCOUNTS_TILE_GO_TO_BANK_ACCOUNTS);
  }, []);

  useEffect(() => {
    const fetch = async () => {
      let responseBankAccounts;
      let responseCashbooks;

      setIsLoading(true);

      try {
        [responseBankAccounts, responseCashbooks] = await Promise.all([
          getBankAccounts(),
          getCashbooks(),
        ]);
      } catch (e) {
        setIsLoading(false);
        return;
      }

      const cashbooks =
        build<Cashbook>(normalize(responseCashbooks.data), EntityPath.Cashbooks) || [];
      const bankAccounts =
        build<BankAccount>(normalize(responseBankAccounts.data), EntityPath.BankAccounts) || [];

      setData([...bankAccounts, ...cashbooks]);
      setIsLoading(false);
    };

    fetch();
  }, []);

  if (isEmpty || isLoading) {
    return <Empty isLoading={isLoading} />;
  }

  return (
    <section data-id="bank-accounts-tile" className={className}>
      <TileTitle to={paths.bankAccounts} onClick={handleTitleClick}>
        {t('features.homepage.bank_accounts_tile.title')}
      </TileTitle>
      <List data={data} />
    </section>
  );
};

export default memo(BankAccountsTile);
