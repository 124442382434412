import React, { memo, SVGProps } from 'react';

const Hamburger = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 41 40" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M.5 0h40v40H.5z" />
      <path
        d="M32.147 9.5a2 2 0 01.15 3.995l-.15.005H8.853a2 2 0 01-.15-3.995l.15-.005h23.294zM32.147 18.5a2 2 0 01.15 3.995l-.15.005H8.853a2 2 0 01-.15-3.995l.15-.005h23.294zM32.147 27.5a2 2 0 01.15 3.995l-.15.005H8.853a2 2 0 01-.15-3.995l.15-.005h23.294z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(Hamburger);
