import React, { memo } from 'react';
import { TableSortByToggleProps } from 'react-table';

import SortingIndicator from './SortingIndicator';
import TableHeader, { TableHeaderProps } from './TableHeader';

export type TableHeaderWithSortingProps = TableSortByToggleProps &
  TableHeaderProps & {
    canSort: boolean;
    isSorted: boolean;
    isSortedDesc?: boolean;
  };

export const TableHeaderWithSorting = ({
  canSort,
  isSorted,
  isSortedDesc,
  children,
  ...rest
}: TableHeaderWithSortingProps) => {
  return (
    <TableHeader {...rest}>
      {children}
      {canSort && (
        <SortingIndicator direction={isSorted ? (isSortedDesc ? 'desc' : 'asc') : undefined} />
      )}
    </TableHeader>
  );
};

export default memo(TableHeaderWithSorting);
