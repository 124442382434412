import React, { useState } from 'react';

import type { ExpenseContract, RevenueContract } from 'types/entities/Contract';
import type { ExpenseInstallment, RevenueInstallment } from 'types/entities/Installment';

import ChooseInstallment from './steps/ChooseInstallment/ChooseInstallment';
import NewContract from './steps/NewContract/NewContract';

type AddRevenueContractProps = {
  onSuccess: (installment: ExpenseInstallment | RevenueInstallment) => void;
  onCancel: () => void;
};

const AddRevenueContract = ({ onCancel, onSuccess }: AddRevenueContractProps) => {
  const [contract, setContract] = useState<ExpenseContract | RevenueContract | null>(null);

  if (contract === null) {
    return (
      <NewContract contractType="basic" onCancel={onCancel} onSuccess={setContract} isRevenue />
    );
  } else if (contract) {
    return <ChooseInstallment contract={contract} onCancel={onCancel} onSuccess={onSuccess} />;
  }

  return null;
};

export default AddRevenueContract;
