import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as pushAction } from 'connected-react-router';
import { isEmpty, upperFirst } from 'lodash';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { DATEV } from 'constants/datev';
import paths from 'routes/paths';
import { canCreateDatevSelector } from 'selectors/datev';
import tableStyles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import EmptyEntryPage from 'components/EmptyEntryPage/EmptyEntryPage';
import DatevEmptyStateImage from 'components/EmptyEntryPage/images/DatevExport';
import I18n from 'components/I18n';
import ActionView from 'components/IndexActionsContainer/actionIcons/ActionView';
import IndexActionsContainer from 'components/IndexActionsContainer/IndexActionsContainer';
import { SortingIndicator } from 'components/Table';

import localStyles from './Table.module.css';

const styles = { ...tableStyles, ...localStyles };

const Row = ({ item }) => (
  <tr data-id={`Datev:table-datev-exports-row_${item.creationDate}_${item.lastRecipient}`}>
    <td className={styles.column}>
      <div className={styles.cell}>
        <I18n t="datev.table.columns.creation_date" className={styles.cellHeader} />
        <div className={styles.cellContent}>{item.creationDate}</div>
      </div>
    </td>
    <td className={styles.columnCenter}>
      <div className={styles.cell}>
        <I18n t="datev.table.columns.last_time_sent" className={styles.cellHeader} />
        <div className={styles.cellContent}>{item.lastTimeSent}</div>
      </div>
    </td>
    <td className={styles.column}>
      <div className={styles.cell}>
        <I18n t="datev.table.columns.start_date" className={styles.cellHeader} />
        <div className={styles.cellContent}>
          {item.startDate} - {item.endDate}
        </div>
      </div>
    </td>
    <td className={styles.columnCenter}>
      <div className={styles.cell}>
        <I18n t="datev.table.columns.incoming_invoices_count" className={styles.cellHeader} />
        <div className={styles.cellContent}>{item.incomingInvoicesCount}</div>
      </div>
    </td>
    <td className={styles.columnCenter}>
      <div className={styles.cell}>
        <I18n t="datev.table.columns.outgoing_invoices_count" className={styles.cellHeader} />
        <div className={styles.cellContent}>{item.outgoingInvoicesCount}</div>
      </div>
    </td>
    <td className={styles.columnCenter}>
      <div className={styles.cell}>
        <I18n t="datev.table.columns.cash_revenues_count" className={styles.cellHeader} />
        <div className={styles.cellContent}>{item.revenueCashTransactionsCount}</div>
      </div>
    </td>
    <td className={styles.columnCenter}>
      <div className={styles.cell}>
        <I18n t="datev.table.columns.cash_expenses_count" className={styles.cellHeader} />
        <div className={styles.cellContent}>{item.expenseCashTransactionsCount}</div>
      </div>
    </td>
    <td className={styles.column}>
      <div className={styles.cell}>
        <I18n t="datev.table.columns.last_recipient" className={styles.cellHeader} />
        <CellContentWithTooltip>{item.lastRecipient || '-'}</CellContentWithTooltip>
      </div>
    </td>
    <td className={styles.column}>
      <div className={styles.cell}>
        <IndexActionsContainer>
          <ActionView
            to={paths.datevEditLink(item.id)}
            title={t('expenses.table.actions.show')}
            label={t('expenses.table.actions.show')}
            onClick={() => piwikHelpers.trackEvent(DATEV, t('expenses.table.actions.show'))}
            visible
          />
        </IndexActionsContainer>
      </div>
    </td>
  </tr>
);

Row.propTypes = {
  item: shape({}).isRequired,
};

const TableHeader = ({ column, sortable, sortBy, currentSorting, align }) => (
  <th
    onClick={sortable ? () => sortBy(column) : null}
    className={cx(styles.column, {
      [styles.sortable]: sortable,
    })}
  >
    <div className={cx(styles.columnHeader, styles[`pullHeader${upperFirst(align)}`])}>
      <I18n t={`datev.table.columns.${column}`} />
      {sortable && (
        <SortingIndicator
          direction={currentSorting.column === column ? currentSorting.direction : null}
        />
      )}
    </div>
  </th>
);

TableHeader.propTypes = {
  column: string.isRequired,
  currentSorting: shape({
    column: string.isRequired,
    direction: string.isRequired,
  }),
  sortBy: func,
  sortable: bool,
  align: string,
};

const Table = ({ isFetching, data, sorting, sortBy, push, isDatevDataFilled }) => {
  const buttonLabel = isDatevDataFilled
    ? t('datev.empty_state.button_label_can_create')
    : t('datev.empty_state.button_label_cant_create');
  const handleButtonOnClick = isDatevDataFilled
    ? () => push(paths.datevNew)
    : () => push(paths.taxConsultantProfile);

  if (isEmpty(data)) {
    return (
      <EmptyEntryPage
        dataIdPrefix="Datev"
        Image={DatevEmptyStateImage}
        header={t('datev.empty_state.header')}
        subheader={t('datev.empty_state.subheader')}
        info={[
          t('datev.empty_state.info.row_1'),
          t('datev.empty_state.info.row_2'),
          t('datev.empty_state.info.row_3'),
          t('datev.empty_state.info.row_4'),
        ]}
        onCreateButtonClick={handleButtonOnClick}
        createButtonLabel={buttonLabel}
      />
    );
  }

  return (
    <div data-id="Datev:table-datev-exports" className={styles.invoicesTableWrapper}>
      <table className={cx(styles.invoicesTable, { [styles.invoicesTableLoading]: isFetching })}>
        <thead>
          <tr>
            <TableHeader column="creation_date" sortable currentSorting={sorting} sortBy={sortBy} />
            <TableHeader
              column="last_time_sent"
              sortable
              currentSorting={sorting}
              sortBy={sortBy}
              align="center"
            />
            <TableHeader column="start_date" sortable currentSorting={sorting} sortBy={sortBy} />
            <TableHeader
              column="incoming_invoices_count"
              sortable
              currentSorting={sorting}
              sortBy={sortBy}
              align="center"
            />
            <TableHeader
              column="outgoing_invoices_count"
              sortable
              currentSorting={sorting}
              sortBy={sortBy}
              align="center"
            />
            <TableHeader
              column="cash_expenses_count"
              sortable
              currentSorting={sorting}
              sortBy={sortBy}
              align="center"
            />
            <TableHeader
              column="cash_revenues_count"
              sortable
              currentSorting={sorting}
              sortBy={sortBy}
              align="center"
            />
            <TableHeader
              column="last_recipient"
              sortable
              currentSorting={sorting}
              sortBy={sortBy}
            />
            <TableHeader column="actions" />
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <Row key={item.id} item={item} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  data: arrayOf(shape({})).isRequired,
  isFetching: bool.isRequired,
  sorting: shape({}).isRequired,
  sortBy: func.isRequired,
  push: func.isRequired,
  isDatevDataFilled: bool.isRequired,
};

const mapStateToProps = (state) => ({
  isDatevDataFilled: canCreateDatevSelector(state),
});

const mapDispatchToProps = {
  push: pushAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
