import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { compose } from 'redux';

import { fetchProposal as fetchProposalAction } from 'actions/proposal';
import { fetchProposalLineItems as fetchProposalLineItemsAction } from 'actions/proposal/line-items';
import { EDIT_MODE } from 'constants/common/crud';
import { FROM_DUPLICATE_QUERY_PARAM } from 'constants/proposal';
import { FORM_NAME } from 'constants/proposal';
import ProposalCreator from 'containers/Proposals/ProposalCreator';
import { withWarningAlert } from 'shared/hoc';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import useLineItemsControl, { SectionType } from 'components/LineItems/useLineItemControl';

const EditProposal = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id: proposalId } = useParams<{ id: string }>();
  const {
    manualHandleSelectCategory,
    setLineItemState,
    setShowCategoryChangeConfirmation,
    setShowDifferentCategoryWarning,
    setShowVatIdWarning,
    showDifferentCategoryWarning,
    showCategoryChangeConfirmation,
    defaultCategory,
    isDeprecatedCategory,
    canSubmit,
    showVatIdWarning,
    category,
    taxRates,
    taxRateId,
    defaultVat,
    hasSavedLineItems,
    selectedCategory,
    lineCategories,
    isInitial,
  } = useLineItemsControl({ sectionType: SectionType.proposal, formName: FORM_NAME });

  const fetchProposal = (id: string) => dispatch(fetchProposalAction(id));
  const fetchProposalLineItems = (id: string) => dispatch(fetchProposalLineItemsAction(id));

  const isFromDuplicate = location.search.includes(FROM_DUPLICATE_QUERY_PARAM);

  //inital fetch document data
  useEffect(() => {
    fetchProposal(proposalId);
    fetchProposalLineItems(proposalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProposalCreator
      isFromDuplicate={isFromDuplicate}
      crudMode={EDIT_MODE}
      hasSavedLineItems={hasSavedLineItems}
      selectedCategory={selectedCategory}
      defaultCategory={defaultCategory}
      isDeprecatedCategory={isDeprecatedCategory}
      handleSelectCategory={manualHandleSelectCategory}
      category={category}
      showCategoryChangeConfirmation={showCategoryChangeConfirmation}
      setShowCategoryChangeConfirmation={setShowCategoryChangeConfirmation}
      taxRateId={taxRateId}
      taxRates={taxRates}
      defaultVat={defaultVat}
      lineCategories={lineCategories}
      isInitial={isInitial}
      canSubmit={canSubmit}
      setLineItemState={setLineItemState}
      showVatIdWarning={showVatIdWarning}
      setShowVatIdWarning={setShowVatIdWarning}
      showDifferentCategoryWarning={showDifferentCategoryWarning}
      setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
    />
  );
};

const mapStateToProps = () => ({
  buttonLabel: t('empty_entry_pages.proposals.proposals_warning_box.button_label'),
  content: t('empty_entry_pages.proposals.proposals_warning_box.content_1'),
  dataId: 'Proposals:orange-warning-container',
});

export default compose<any>(connect(mapStateToProps, null), withWarningAlert)(EditProposal);
