import React, { SVGAttributes } from 'react';

const IconCheck = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" {...props}>
    <path
      d="M2 8.72619042l4.4047619 3.96428568L14.3333333 3"
      fillRule="nonzero"
      stroke="currentColor"
      strokeWidth="3"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconCheck;
