import moment from 'moment';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_UNICODE = 'dd.MM.yyyy';
export const DATE_FORMAT_COMBINED = 'DDMMYYYY';
export const DATE_FORMAT_MONTH_REVERSED = 'YYYY-MM';
export const DATE_FORMAT_FULL_REVERSED = 'YYYY-MM-DD';
export const DATE_FORMAT_FULL_REVERSED_UNICODE = 'yyyy-MM-dd';
export const DATE_FORMAT_YEAR = 'YYYY';
export const DATE_FORMAT_MONTH_YEAR = 'MM.YYYY';

export const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const TIME_FORMAT = 'HH:mm';

export const DATEPICKER_MIN_DATE = moment('19000101');
export const DATEPICKER_MAX_DATE = moment('20991231');
export const DATEPICKER_HEADER = 'MMMM YYYY';
