export function addPassiveListener(container, event, listener) {
  try {
    if (container) container.addEventListener(event, listener, { passive: true });
  } catch (ex) {
    if (container) container.addEventListener(event, listener);
  }
}

export function removePassiveListener(container, event, listener) {
  if (container) container.removeEventListener(event, listener);
}
