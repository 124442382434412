import React, { ReactNode } from 'react';

import Edit from 'redesign/components/atoms/Icons/Edit';

import { Button } from './ActionIcon.styled';

export interface ActionIconProps {
  icon?: ReactNode;
  onClick: () => void;
  dataId?: string;
}

const ActionIcon = ({ icon = <Edit />, onClick, dataId }: ActionIconProps) => {
  return (
    <Button onClick={onClick} data-id={dataId}>
      {icon}
    </Button>
  );
};

export default ActionIcon;
