import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
import { useMedia } from 'react-use';

import {
  BANK_TRANSFERS_TILE_GO_TO_BANK_TRANSFERS,
  BANK_TRANSFERS_TILE_SELECT_ACCOUNT,
} from 'constants/piwik';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { BankAccount } from 'types/entities/BankAccount';
import { BankTransfer } from 'types/entities/BankTransfer';
import Select, { SelectProps } from 'redesign/components/atoms/Select/Select';
import { FieldWrapperProps } from 'redesign/components/molecules/FieldWrapper/FieldWrapper';
import Table from 'redesign/components/molecules/Table/Table';
import { Breakpoints } from 'redesign/styles/breakpoints';
import { isUpToDate } from 'redesign/utils/bankAccount';

import getColumns from './getColumns';
import { useFetchBankTransfers } from './List.hooks';
import { BankAccountField, Box, LastUpdate, TableWrapper } from './List.styled';
import { Option } from './List.types';
import { trackEvent } from './utils';

type ListProps = {
  bankAccounts: BankAccount[];
};

const List = ({ bankAccounts }: ListProps) => {
  const options = useMemo(
    () => [
      {
        id: null,
        name: t('features.homepage.bank_transfers_tile.list.bank_account_all_accounts'),
      },
      ...bankAccounts,
    ],
    [bankAccounts]
  );
  const [selectedBankAccount, setSelectedBankAccount] = useState<Option>(options[0]);
  const { isLoading, data } = useFetchBankTransfers(selectedBankAccount);
  const isMobile = useMedia(`(max-width: ${Breakpoints.xsMax})`);
  const columns = useMemo(() => getColumns(), []);
  const instance = useTable<BankTransfer>({
    columns,
    data,
  });
  const { toggleHideColumn } = instance;

  const handleChange = useCallback((bankAccount?: Option | null) => {
    if (!bankAccount) return;

    trackEvent(BANK_TRANSFERS_TILE_SELECT_ACCOUNT);
    setSelectedBankAccount(bankAccount);
  }, []);

  const handleLinkClick = useCallback(() => {
    trackEvent(BANK_TRANSFERS_TILE_GO_TO_BANK_TRANSFERS);
  }, []);

  useEffect(() => {
    toggleHideColumn('date', isMobile);
  }, [isMobile, toggleHideColumn]);

  return (
    <Box>
      <div>
        <BankAccountField<FunctionComponent<FieldWrapperProps<SelectProps<Option>>>>
          label={t('features.homepage.bank_transfers_tile.list.bank_account_label')}
          field={Select}
          dataId="bank-account"
          disabled={isLoading}
          options={options}
          onChange={handleChange}
          itemToString={(item) => (item ? item.name : '')}
          option={({ item }) => <>{item.name}</>}
          value={selectedBankAccount}
        />
      </div>
      <TableWrapper>
        <Table
          instance={instance}
          isLoading={isLoading}
          emptyText={t('features.homepage.bank_transfers_tile.list.empty')}
        />
      </TableWrapper>
      {selectedBankAccount.id === null ? (
        <LastUpdate>
          <Link data-id="bank-transfers-link" to={paths.bankTransfers} onClick={handleLinkClick}>
            {t('features.homepage.bank_transfers_tile.list.update')}
          </Link>
          <br />
          {t('features.homepage.bank_transfers_tile.list.last_update')}: -
        </LastUpdate>
      ) : (
        !isUpToDate(selectedBankAccount) && (
          <LastUpdate>
            <Link
              data-id="bank-transfers-link"
              to={paths.showbankTransfer(selectedBankAccount.id)}
              onClick={handleLinkClick}
            >
              {t('features.homepage.bank_transfers_tile.list.update')}
            </Link>
            <br />
            {t('features.homepage.bank_transfers_tile.list.last_update')}:{' '}
            {selectedBankAccount.figoBalanceDate || '-'}
          </LastUpdate>
        )
      )}
    </Box>
  );
};

export default List;
