import React from 'react';
import { Col } from 'react-flexbox-grid';
import cx from 'classnames';
import { bool, node, string } from 'prop-types';

import styles from './TableCell.module.css';

export const TableCell = ({
  className,
  children,
  name,
  customWidth = false,
  customHeight = false,
}) => (
  <Col
    className={cx(styles.cell, className, {
      [styles.customWidth]: customWidth,
      [styles.customHeight]: customHeight,
    })}
    data-id={`LineItem:table-cell(${name || 'not-defined'})`}
  >
    {children}
  </Col>
);

TableCell.propTypes = {
  className: string,
  children: node,
  name: string,
  customWidth: bool,
  customHeight: bool,
};
