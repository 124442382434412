import styled, { css } from 'styled-components';

import IconArrowDown from 'components/Icons/IconArrowDown';

type CollapseProps = {
  isOpen: boolean;
};

export const Wrapper = styled.section`
  position: relative;
  background-color: #fff;
  width: 100%;
`;

export const Header = styled.button`
  background-color: #fff;
  padding: 16px 15px;
  user-select: none;

  &:focus {
    outline: 0;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  color: #0066b3;
`;

export const Icon = styled.div`
  padding-left: 10px;
  svg {
    width: 13px;
  }
`;

export const Content = styled.div<CollapseProps>`
  padding: 0 15px 45px;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      display: none;
    `};
`;

type ArrowProps = {
  $isOpen: boolean;
};

export const Arrow = styled(IconArrowDown)<ArrowProps>`
  transition: transform 0.3s ease;

  ${({ $isOpen }) => $isOpen && 'transform: rotate(180deg)'};
`;
