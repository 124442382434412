import { actionTypes } from 'redux-form';

import { apiUpdateContract } from 'actions/contracts';
import { bindServerValidation } from 'shared/utils/server-validation';
import { FORM_NAME } from 'features/contracts/constants';

import details from './details';
import status from './status';
import uploadedFiles from './uploaded-files';

const contracts = (state = {}, action) => ({
  ...status(state.status, action),
  details: details(state.details, action),
  uploadedFiles: uploadedFiles(state.uploadedFiles, action),
});

export default (state, action) => {
  if (action.type === actionTypes.DESTROY && action.meta.form.includes(FORM_NAME)) {
    /* Clean all state on quit */
    return contracts({}, action);
  }

  return contracts(state, action);
};

export const updateContract = (contractId, data, isRevenue, hasVat) => (dispatch) =>
  bindServerValidation(
    apiUpdateContract(contractId, data, isRevenue, hasVat),
    dispatch,
    { isReduxForm: true },
    {
      client_id: { id: 'client' },
      supplier_id: { id: 'supplier' },
    }
  );
