import React, { Component, Fragment } from 'react';
import AnimateHeight from 'react-animate-height';
import { connect } from 'react-redux';
import cx from 'classnames';
import normalize from 'json-api-normalizer';
import { get, isEmpty, reverse } from 'lodash';
import { bool, func, number, shape } from 'prop-types';
import { compose } from 'recompose';
import build from 'redux-object';

import {
  duplicateOutgoingInvoice as duplicateOutgoingInvoiceAction,
  fetchSubInvoices,
} from 'actions/outgoing-invoice';
import { FETCH_SUCCESS_SUB_INVOICES } from 'constants/outgoing-invoice';
import {
  ACTION_EARNINGS_FARMPILOT_DELETE_INVOICE,
  ACTION_EARNINGS_FARMPILOT_SHOW_INFORMATION,
  CATEGORY_FARMPILOT,
  PIWIK_ACTION_CANCELL_INVOICE,
  PIWIK_ACTION_CORRECT_OUTGOING_INVOICE,
  PIWIK_ACTION_EDIT_OUTGOING_INVOICE,
  PIWIK_ACTION_REMINDER,
  PIWIK_ACTION_SHOW_OUTGOING_INVOICE,
  PIWIK_CATEGORY_REVENUE,
} from 'constants/piwik';
import { InvoiceStatuses } from 'constants/statuses';
import paths from 'routes/paths';
import withViewport from 'shared/hoc/withViewport';
import styles from 'shared/styles/table.module.css';
import { formatMoney, formatPercentage, t } from 'shared/utils';
import { shouldShowFarmpilotBadge } from 'shared/utils/farmpilot';
import { piwikHelpers } from 'shared/utils/piwik';
import Amount from 'components/Amount';
import ArrrowIcon from 'components/ArrowIcon/ArrowIcon';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import Section from 'components/Form/Section/Section';
import I18n from 'components/I18n';
import ActionAssignPayment from 'components/IndexActionsContainer/actionIcons/ActionAssignPayment';
import ActionCancel from 'components/IndexActionsContainer/actionIcons/ActionCancel';
import ActionCorrect from 'components/IndexActionsContainer/actionIcons/ActionCorrect';
import ActionDelete from 'components/IndexActionsContainer/actionIcons/ActionDelete';
import ActionDuplicate from 'components/IndexActionsContainer/actionIcons/ActionDuplicate';
import ActionEdit from 'components/IndexActionsContainer/actionIcons/ActionEdit';
import ActionPaymentReminder from 'components/IndexActionsContainer/actionIcons/ActionPaymentReminder';
import ActionTransformFromOutgoingInvoice from 'components/IndexActionsContainer/actionIcons/ActionTransformFromOutgoingInvoice';
import ActionView from 'components/IndexActionsContainer/actionIcons/ActionView';
import IndexActionsContainer from 'components/IndexActionsContainer/IndexActionsContainer';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import InvoiceColumn from 'components/InvoiceColumn/InvoiceColumn';
import LoadingIcon from 'components/LoadingIcon';
import sectionStyles from 'components/SubInvoices/SectionStyle.module.css';
import SubInvoices from 'components/SubInvoices/SubInvoices';
import InvoiceStatusBadge from 'components/Table/InvoiceStatusBadge/InvoiceStatusBadge';

import localStyles from './InvoiceRow.module.css';

const earningFarmpilotShowInformationEvent = () =>
  piwikHelpers.trackEvent(CATEGORY_FARMPILOT, ACTION_EARNINGS_FARMPILOT_SHOW_INFORMATION);
const showOutgoingInvoiceEvent = () =>
  piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_SHOW_OUTGOING_INVOICE);
const editOutgoingInvoiceEvent = () =>
  piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_EDIT_OUTGOING_INVOICE);
const cancelInvoiceEvent = () =>
  piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_CANCELL_INVOICE);
const actionReminderEvent = () =>
  piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_REMINDER);
const correctOutgoingInvoiceEvent = () =>
  piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_CORRECT_OUTGOING_INVOICE);
const earningsFarmpilotDeleteInvoiceEvent = () =>
  piwikHelpers.trackEvent(CATEGORY_FARMPILOT, ACTION_EARNINGS_FARMPILOT_DELETE_INVOICE);

class InvoiceRow extends Component {
  state = {
    isRowExpanded: false,
    subInvoices: [],
    isFetching: false,
  };

  componentDidMount() {
    const { isXlgUp } = this.props;
    const { isRowExpanded } = this.state;

    if (!isXlgUp && !isRowExpanded) this.handleOnOpen();

    this.expandNewlyCancelledInvoice();
  }

  setInvoiceData = (response) => {
    const subInvoices = reverse(build(normalize(response), 'outgoingInvoices'));
    this.setState({
      subInvoices,
      isFetching: false,
    });
  };

  expandNewlyCancelledInvoice = () => {
    const currentUrl = window.location.href;

    if (currentUrl.indexOf('expandCancelledInvoice') > 0) {
      const invoiceIdInUrl = currentUrl.substring(currentUrl.indexOf('=') + 1);
      const {
        invoice: { id },
      } = this.props;

      if (id === invoiceIdInUrl) this.handleOnOpen();
    }
  };

  handleOnClick = () => {
    const { isRowExpanded } = this.state;
    return isRowExpanded ? this.setState({ isRowExpanded: false }) : this.handleOnOpen();
  };

  handleOnOpen = () => {
    const { handleFetchSubInvoices, invoice: { id: invoiceId } = {} } = this.props;
    this.setState({ isFetching: true });
    handleFetchSubInvoices(invoiceId).then((response) => {
      if (response.type === FETCH_SUCCESS_SUB_INVOICES) this.setInvoiceData(response);
    });
    this.setState({ isRowExpanded: true });
  };

  /**
   * Send duplication request and redirect to new draft on success.
   */
  handleActionDuplicate = (event) => {
    // By default action icons are links. This stops default link behavior.
    event.preventDefault();

    const {
      duplicateOutgoingInvoice,
      invoice: { id },
    } = this.props;

    duplicateOutgoingInvoice(id);
  };

  handleActionTransform = () => {
    const {
      onTransform,
      invoice: { id },
    } = this.props;
    onTransform(id);
  };

  render() {
    const {
      client,
      invoice,
      onDelete,
      paymentRemindersEnabled,
      isIndexClassicView,
      isXlgUp,
      isEven,
      index,
    } = this.props;
    const { isRowExpanded, isFetching, subInvoices } = this.state;

    const actualClient = invoice.persistedClient || client;
    const clientName = (actualClient && actualClient.name) || invoice.clientDisplayName;
    const permittedActions = get(invoice, 'meta.actions', {});

    const invoiceStatus =
      invoice.paymentRemindersCount && invoice.status === InvoiceStatuses.OVERDUE
        ? InvoiceStatuses.PAYMENT_REMINDED
        : invoice.status;
    const isCancelledInvoice = invoice.status === InvoiceStatuses.CANCELLED;
    const discountGrossValueWithPercentage = invoice.discountGrossValue
      ? `${formatMoney(invoice.discountGrossValue, invoice.currency)} (${formatPercentage(
          invoice.discountPercentage
        )})`
      : '-';

    const isSubInvoiceRowPresent =
      !isIndexClassicView && (invoice.hasParentInvoice || !!invoice.paymentRemindersCount);
    const anySubInvoicesPresent = invoice.hasParentInvoice
      ? !isEmpty(this.state.subInvoices)
      : Boolean(invoice.paymentRemindersCount);

    const shouldShowBrandBadge = shouldShowFarmpilotBadge(invoice);

    return (
      <Fragment>
        <tr
          className={cx({
            [styles.rowEven]: isEven,
            [styles.rowOdd]: !isEven,
          })}
          data-id={`OutgoingInvoiceTable:row-${index}`}
        >
          <InvoiceColumn className={styles.cell}>
            {isXlgUp && isSubInvoiceRowPresent && (
              <ArrrowIcon
                isOpen={isRowExpanded}
                onClick={this.handleOnClick}
                dataId="OutgoingInvoiceTable:icon-arrow"
              />
            )}
          </InvoiceColumn>
          <InvoiceColumn className={cx(styles.cell, localStyles.statusCell)}>
            <I18n t="revenue.table.columns.status" className={styles.cellHeader} />
            <div
              className={cx(styles.cellContent, {
                [styles.brandBadge]: shouldShowBrandBadge,
              })}
              data-id={shouldShowBrandBadge ? 'OutgoingInvoiceTable:badge-orange' : undefined}
            >
              <InvoiceStatusBadge
                status={invoiceStatus}
                remindersCount={invoice.paymentRemindersCount}
              />
            </div>
            {shouldShowBrandBadge && (
              <InfoIcon
                text={t(`revenue.table.tooltip.${invoice.origin}`)}
                tooltipPlacement={isXlgUp ? 'top' : 'left'}
                tooltipClassName={styles.invoiceTooltip}
                onMouseEnter={earningFarmpilotShowInformationEvent}
                iconDataId="OutgoingInvoiceTable:icon-info"
              />
            )}
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell}>
            <I18n t="revenue.table.columns.client" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="OutgoingInvoiceTable:cell-client">
              {clientName || '-'}
            </CellContentWithTooltip>
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell}>
            <I18n t="revenue.table.columns.number" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="OutgoingInvoiceTable:cell-number">
              {invoice.number || '-'}
            </CellContentWithTooltip>
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell}>
            <I18n t="revenue.table.columns.subject" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="OutgoingInvoiceTable:cell-subject">
              {invoice.subject || '-'}
            </CellContentWithTooltip>
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell} align="left">
            <I18n t="revenue.table.columns.creation_date" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="OutgoingInvoiceTable:cell-creationDate">
              {invoice.creationDate || '-'}
            </CellContentWithTooltip>
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell} align="left">
            <I18n t="revenue.table.columns.due_date" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="OutgoingInvoiceTable:cell-dueDate">
              {invoice.dueDate || '-'}
            </CellContentWithTooltip>
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell} align="right">
            <I18n t="revenue.table.columns.discount_gross_value" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="OutgoingInvoiceTable:cell-discount">
              {discountGrossValueWithPercentage}
            </CellContentWithTooltip>
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell} align="right">
            <I18n t="revenue.table.columns.total_gross_amount" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="OutgoingInvoiceTable:cell-totalGrossAmount">
              <Amount currency={invoice.currency} amount={invoice.totalGrossAmount} />
            </CellContentWithTooltip>
          </InvoiceColumn>
          <InvoiceColumn className={styles.actions}>
            <IndexActionsContainer>
              <ActionView
                onClick={showOutgoingInvoiceEvent}
                to={{
                  pathname: paths.showOutgoingInvoice(invoice.id),
                  state: { isParentInvoice: true },
                }}
                title={t('revenue.table.actions.show')}
                label={t('revenue.table.actions.show')}
                id="outgoing-invoice-show-button"
                dataId="OutgoingInvoice:button-show"
                visible={permittedActions.show}
              />
              <ActionEdit
                title={t('revenue.table.actions.edit')}
                label={t('revenue.table.actions.edit')}
                to={paths.editOutgoingInvoice(invoice.id)}
                id="outgoing-invoice-edit-button"
                dataId="OutgoingInvoice:button-edit"
                onClick={editOutgoingInvoiceEvent}
                visible={permittedActions.edit || permittedActions['edit-payment-information']}
              />
              <ActionDuplicate
                title={t('revenue.table.actions.duplicate')}
                label={t('revenue.table.actions.duplicate')}
                dataId="OutgoingInvoice:button-duplicate"
                visible={permittedActions.duplicate}
                onClick={this.handleActionDuplicate}
              />
              <ActionPaymentReminder
                to={paths.createPaymentReminder(invoice.id)}
                title={t('revenue.table.actions.create_reminder')}
                id="create-invoice-reminder-button"
                onClick={actionReminderEvent}
                label={t('revenue.table.actions.create_reminder')}
                visible={permittedActions['create-payment-reminder'] && paymentRemindersEnabled}
                dataId="OutgoingInvoice:button-create-payment-reminder"
              />
              <ActionTransformFromOutgoingInvoice
                title={t('revenue.table.actions.delivery_note')}
                label={t('revenue.table.actions.delivery_note')}
                dataId="OutgoingInvoice:button-duplicate"
                visible={permittedActions['transform-into-delivery-note']}
                onClick={this.handleActionTransform}
              />
              <ActionCancel
                to={paths.cancelOutgoingInvoice(invoice.id)}
                title={t('revenue.table.actions.cancel')}
                id="outgoing-invoice-cancel-button"
                dataId="OutgoingInvoice:button-cancel"
                onClick={cancelInvoiceEvent}
                label={t('revenue.table.actions.cancel')}
                visible={permittedActions.cancel}
              />
              <ActionCorrect
                to={paths.correctOutgoingInvoice(invoice.id)}
                title={t('revenue.table.actions.correct')}
                id="outgoing-invoice-correct-button"
                onClick={correctOutgoingInvoiceEvent}
                label={t('revenue.table.actions.correct')}
                visible={permittedActions.correct}
                dataId="OutgoingInvoice:button-correct"
              />
              <ActionAssignPayment
                to={`${paths.editOutgoingInvoice(invoice.id)}#assign-payment`}
                title={t('revenue.table.actions.assign_payment')}
                label={t('revenue.table.actions.assign_payment')}
                id="outgoing-invoice-payment-button"
                visible={!isCancelledInvoice}
                dataId="OutgoingInvoice:button-payment"
              />
              <ActionDelete
                title={t('revenue.table.actions.delete')}
                label={t('revenue.table.actions.delete')}
                dataId="OutgoingInvoice:button-delete"
                id="outgoing-invoice-delete-button"
                onClick={(event) => {
                  event.preventDefault();
                  onDelete(invoice);

                  if (shouldShowBrandBadge) earningsFarmpilotDeleteInvoiceEvent();
                }}
                visible={permittedActions.delete}
              />
            </IndexActionsContainer>
          </InvoiceColumn>
          <InvoiceColumn />
        </tr>
        <tr>
          <td colSpan="10" className={sectionStyles.subInvoiceRow}>
            {isSubInvoiceRowPresent && anySubInvoicesPresent && (
              <AnimateHeight duration={200} height={isRowExpanded ? 'auto' : 0}>
                <Section
                  wrapperTheme={sectionStyles.section}
                  title={t('revenue.table.associated_documents')}
                >
                  {!isFetching ? (
                    <SubInvoices invoices={subInvoices} parentInvoice={invoice} isEven={isEven} />
                  ) : (
                    <LoadingIcon topDisabled />
                  )}
                </Section>
              </AnimateHeight>
            )}
          </td>
          <td />
        </tr>
      </Fragment>
    );
  }
}

InvoiceRow.propTypes = {
  client: shape({}),
  invoice: shape({
    meta: shape({
      actions: shape({
        cancel: bool,
        correct: bool,
        delete: bool,
        edit: bool,
        editPaymentInformation: bool,
        show: bool,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  onDelete: func.isRequired,
  paymentRemindersEnabled: bool,
  handleFetchSubInvoices: func,
  isIndexClassicView: bool.isRequired,
  isXlgUp: bool.isRequired,
  isEven: bool.isRequired,
  duplicateOutgoingInvoice: func.isRequired,
  index: number.isRequired,
  onTransform: func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  client: state.clients.data.find((c) => c.id === ownProps.invoice.clientId),
});

const mapDisptachToProps = (dispatch) => ({
  handleFetchSubInvoices: (...args) => dispatch(fetchSubInvoices(...args)),
  duplicateOutgoingInvoice: (invoiceId) => dispatch(duplicateOutgoingInvoiceAction(invoiceId)),
});

export default compose(withViewport, connect(mapStateToProps, mapDisptachToProps))(InvoiceRow);
