import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { showNotification as showNotificationAction } from 'actions/notification';
import { getOcrResult, processInvoiceFiles } from 'actions/outgoing-invoice/ocr';
import {
  deleteOutgoingInvoiceFile,
  downloadFile,
  uploadOutgoingInvoiceFile,
} from 'actions/outgoing-invoice/uploaded-files';
import { ocrEnabledHelper } from 'routes/accesses';
import Dropzone from 'components/Dropzone/Dropzone';

const UploadInvoiceSection = ({
  accessToken,
  fileId,
  uploadFile,
  downloadFilePreview,
  deleteFile,
  showNotification,
  uploadedFiles,
  ocrEnabled,
  readonly,
}) => (
  <Dropzone
    template="outgoingInvoiceFile"
    readonly={readonly}
    ocrEnabled={ocrEnabled}
    files={uploadedFiles}
    accessToken={accessToken}
    fileId={fileId}
    uploadFileAction={uploadFile}
    showNotification={showNotification}
    downloadFilePreview={downloadFilePreview}
    deleteFile={deleteFile}
    allowedFileExtensions={['application/pdf']}
  />
);

UploadInvoiceSection.propTypes = {
  readonly: PropTypes.bool,
  accessToken: PropTypes.string.isRequired,
  ocrEnabled: PropTypes.bool,
  fileId: PropTypes.string.isRequired,
  uploadFile: PropTypes.func.isRequired,
  downloadFilePreview: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  ocr: PropTypes.shape({ status: PropTypes.string.isRequired }),
  processInvoiceOcrData: PropTypes.func.isRequired,
  getInvoiceOcrData: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })).isRequired,
  showNotification: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    accessToken: state.auth.token,
    fileId: state.outgoingInvoice.details.id,
    uploadedFiles: state.outgoingInvoice.uploadedFiles,
    ocrEnabled: ocrEnabledHelper(state) && false, // Remove redundant false value after enabling ocr for outgoing inoices
    ocr: state.outgoingInvoice.ocr,
  }),
  (dispatch) => ({
    uploadFile: (invoiceId, files) => dispatch(uploadOutgoingInvoiceFile(invoiceId, files)),
    downloadFilePreview: (...args) => dispatch(downloadFile(...args)),
    deleteFile: (invoiceId, id) => dispatch(deleteOutgoingInvoiceFile(invoiceId, id)),
    processInvoiceOcrData: (...args) => dispatch(processInvoiceFiles(...args)),
    getInvoiceOcrData: (...args) => dispatch(getOcrResult(...args)),
    showNotification: (notification) => dispatch(showNotificationAction(notification)),
  })
)(UploadInvoiceSection);
