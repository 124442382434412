import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as reduxPush } from 'connected-react-router';
import { bool, func, number, shape, string } from 'prop-types';

import { CANCEL_MODE, EDIT_MODE, NEW_MODE, SHOW_MODE } from 'constants/common/crud';
import * as Piwik from 'constants/piwik';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import ActionDropdown from 'components/ActionPanel/ActionDropdown';
import { ButtonAppearances } from 'components/Button';
import I18n from 'components/I18n';
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'components/LineItems';
import { ConfirmationModal } from 'components/Modal';

import AbortButton from './AbortButton';
import PreviewModal from './PreviewModal';

import styles from './ActionsSection.module.css';

class ActionsSection extends Component {
  state = {
    modalActive: false,
    previewVisible: false,
  };

  onConfirm = () => {
    const { saveAndTransform } = this.props;
    saveAndTransform();
    this.hideModal();
  };

  onClose = () => {
    const { transform } = this.props;
    transform();
    this.hideModal();
  };

  onAbort = () => {
    const { abort, crudMode } = this.props;
    abort();

    if (crudMode === NEW_MODE) {
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_DELIVERY_NOTES,
        Piwik.ACTION_CREATE_DELIVERY_NOTE_CANCEL
      );
    }
  };

  onSaveAsDraft = () => {
    const { saveAsDraft, crudMode } = this.props;

    saveAsDraft();

    if (crudMode === NEW_MODE) {
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_DELIVERY_NOTES,
        Piwik.ACTION_CREATE_DELIVERY_NOTE_SAVE
      );
    }
    if (crudMode === EDIT_MODE) {
      piwikHelpers.trackEvent(Piwik.CATEGORY_DELIVERY_NOTES, Piwik.ACTION_EDIT_DELIVERY_NOTE_SAVE);
    }
  };

  onPreview = () => {
    const { crudMode } = this.props;
    this.togglePreview();

    if (crudMode === EDIT_MODE) {
      piwikHelpers.trackEvent(Piwik.CATEGORY_DELIVERY_NOTES, Piwik.ACTION_DELIVERY_NOTE_PREVIEW);
    }
  };

  showModal = () => this.setState({ modalActive: true });

  hideModal = () => this.setState({ modalActive: false });

  togglePreview = () => {
    const { previewVisible } = this.state;

    this.setState({
      previewVisible: !previewVisible,
    });
  };

  sendActions = () => {
    const downloadAction = {
      name: t('features.delivery_note.form.download'),
      onClick: () => this.processDownloadDeliveryNote(),
      tooltip: t('features.delivery_note.form.download_tooltip'),
      dataId: 'DeliveryNotePage:download-pdf',
    };
    const printAction = {
      name: t('features.delivery_note.form.print_delivery_note'),
      onClick: () => this.processPrintDeliveryNote(),
      tooltip: t('features.delivery_note.form.print_tooltip'),
      dataId: 'DeliveryNotePage:button-send-email',
    };

    return [downloadAction, printAction];
  };

  printDeliveryNote = (deliveryNote = {}, isPiwikTracked = false) => {
    const isCancelMode = this.props.crudMode === CANCEL_MODE;

    this.props.print(deliveryNote, isPiwikTracked, isCancelMode);
  };

  downloadDeliveryNote = (deliveryNote = {}, isPiwikTracked = false) => {
    const isCancelMode = this.props.crudMode === CANCEL_MODE;

    this.props.download(deliveryNote, isPiwikTracked, isCancelMode);
  };

  saveAndPrintDeliveryNote = async () => {
    const { save, crudMode } = this.props;
    const updatedDeliveryNote = await save();

    if (updatedDeliveryNote.success) {
      const isPiwikTracked = crudMode === NEW_MODE || crudMode === EDIT_MODE;

      this.printDeliveryNote(updatedDeliveryNote, isPiwikTracked);
    }
  };

  saveAndDownloadDeliveryNote = async () => {
    const { save, crudMode } = this.props;
    const updatedDeliveryNote = await save();

    if (updatedDeliveryNote.success) {
      const isPiwikTracked = crudMode === NEW_MODE || crudMode === EDIT_MODE;

      this.downloadDeliveryNote(updatedDeliveryNote, isPiwikTracked);
    }
  };

  processPrintDeliveryNote = () => {
    this.props.readonly && this.props.crudMode !== CANCEL_MODE
      ? this.printDeliveryNote()
      : this.saveAndPrintDeliveryNote();
  };

  processDownloadDeliveryNote = () => {
    this.props.readonly && this.props.crudMode !== CANCEL_MODE
      ? this.downloadDeliveryNote()
      : this.saveAndDownloadDeliveryNote();
  };

  render() {
    const {
      isFormSubmitting,
      readonly,
      showSave = false,
      wrapped = false,
      push,
      isProfileFilled,
      isDraft,
      crudMode,
    } = this.props;
    const { previewVisible } = this.state;

    return (
      <ActionPanel
        wrapped={wrapped}
        className={cx(styles.creatorActionsSection, IGNORE_OUTSIDE_CLICK_CLASS)}
      >
        <ActionButton
          dataId="DeliveryNotePage:button-save"
          visible={!readonly && showSave}
          label={t('features.delivery_note.form.save')}
          onClick={this.onSaveAsDraft}
          disabled={isFormSubmitting}
        />
        {isDraft && crudMode === SHOW_MODE ? (
          <ActionButton
            dataId="DeliveryNotePage:button-download-or-save"
            visible
            disabled={!isProfileFilled}
            appearance="outlined"
            label={t('features.delivery_note.form.download_draft_delivery_note')}
            onClick={this.downloadDeliveryNote}
          />
        ) : (
          <ActionDropdown
            upwards
            visible
            disabled={!isProfileFilled}
            actions={this.sendActions()}
            title={t('features.delivery_note.form.save_delivery_note')}
            dataId="DeliveryNotePage:button-download-or-save"
          />
        )}
        <ActionButton
          dataId="DeliveryNotePage:button-preview"
          visible={!readonly}
          appearance={ButtonAppearances.outlined}
          onClick={this.onPreview}
          label={t('revenue.form.preview')}
        />
        <AbortButton
          dataId="DeliveryNotePage:button-abort"
          visible={!readonly}
          onClick={this.onAbort}
        />
        <ConfirmationModal
          isOpen={this.state.modalActive}
          onClose={this.onClose}
          onConfirm={this.onConfirm}
        >
          <I18n t="features.delivery_note.form.modal" />
        </ConfirmationModal>
        <PreviewModal onClose={this.togglePreview} isOpen={previewVisible} />
        <ActionButton
          appearance={ButtonAppearances.outlined}
          onClick={() => push(paths.deliveryNotes)}
          label={t('forms.actions.back_to_index')}
          visible={readonly}
        />
      </ActionPanel>
    );
  }
}

ActionsSection.propTypes = {
  deliveryNoteId: number.isRequired,
  save: func.isRequired,
  saveAsDraft: func.isRequired,
  showTransform: bool,
  showSave: bool,
  transform: func.isRequired,
  saveAndTransform: func.isRequired,
  download: func.isRequired,
  send: func.isRequired,
  abort: func.isRequired,
  isFormDirty: bool,
  isFormSubmitting: bool.isRequired,
  readonly: bool,
  currentClient: shape(),
  sendingDocumentsByEmailEnabled: bool,
  wrapped: bool,
  crudMode: string.isRequired,
  push: func.isRequired,
  isProfileFilled: bool.isRequired,
};

const mapStateToProps = (state) => ({
  isProfileFilled: state.onboarding.data?.profileFilled,
});

const mapDispatchToProps = {
  push: (path) => reduxPush(path),
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsSection);
