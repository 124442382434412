import React, { useState } from 'react';
import cx from 'classnames';
import { WrappedFieldProps } from 'redux-form';

import { PIWIK_ACTION_TIPS, PIWIK_CATEGORY_PROFILE } from 'constants/piwik';
import iconCancel from 'images/icon-cancel.svg';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import FormField from 'components/Form/FormField';
import Section from 'components/Form/Section/Section';
import Modal from 'components/Modal';
import modalStyles from 'components/Modal/Modal.module.css';

import localStyles from './ReceiveOffers.module.css';

const ReceiveOffers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    piwikHelpers.trackEvent(PIWIK_CATEGORY_PROFILE, PIWIK_ACTION_TIPS);
    setIsModalOpen((isOpen) => !isOpen);
  };

  return (
    <div className={localStyles.sectionContainer}>
      <Section
        title={t('registration.form.receive_offers_2')}
        sectionTitleTheme={localStyles.receiveOffersHeader}
      >
        <div>
          <FormField
            name="receiveOffers"
            type="checkbox"
            component={({ input }: WrappedFieldProps) => (
              <div className={localStyles.receiveOffers}>
                <div className={localStyles.checkboxWrapper}>
                  <input
                    id="receiveOffers"
                    type="checkbox"
                    className={localStyles.checkbox}
                    {...input}
                  />
                </div>
                <div className={localStyles.label}>
                  {t('registration.form.receive_offers_1')}{' '}
                  <button
                    onClick={toggleModal}
                    className={localStyles.link}
                    data-id="ReceiveOffers:button-tipsAndProduct"
                  >
                    {t('registration.form.receive_offers_2')}
                  </button>{' '}
                  {t('registration.form.receive_offers_3')}
                </div>
              </div>
            )}
          />
          <FormField
            name="emailTaskNotifications"
            type="checkbox"
            component={({ input }: WrappedFieldProps) => (
              <div className={localStyles.receiveOffers}>
                <div className={localStyles.checkboxWrapper}>
                  <input
                    id="emailTaskNotifications"
                    type="checkbox"
                    className={localStyles.checkbox}
                    {...input}
                  />
                </div>
                <div className={localStyles.label}>
                  {t('registration.form.email_task_notifications')}
                </div>
              </div>
            )}
          />
        </div>
        <Modal
          className={cx(modalStyles.infoModal, localStyles.main)}
          isOpen={isModalOpen}
          onRequestClose={toggleModal}
        >
          <div
            className={cx(modalStyles.header, localStyles.modalHeader)}
            data-id="ReceiveOffers:modalHeader-tipsAndProducts"
          >
            <div className={localStyles.headerTitle}>{t('registration.receive_offers.title')}</div>
            <button className={localStyles.closeButton} onClick={toggleModal}>
              <img src={iconCancel} alt={t('modals.cancel')} />
            </button>
          </div>
          <div className={modalStyles.modalContent}>
            <iframe
              src="/content/receive-offers"
              title="receive-offers"
              className={localStyles.iframe}
            />
          </div>
        </Modal>
      </Section>
    </div>
  );
};

export default ReceiveOffers;
