import { useEffect, useState } from 'react';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { getInvoiceTemplates } from 'api/me/invoiceTemplates';
import EntityPath from 'constants/entitiesPaths';
import { InvoiceTemplate } from 'types/entities/InvoiceTemplate';

export const useFetchInvoiceTemplate = () => {
  const [invoiceTemplate, setInvoiceTemplate] = useState<InvoiceTemplate>();

  useEffect(() => {
    const fetch = async () => {
      const response = await getInvoiceTemplates();
      const ids = response.data.data.map((item) => item.id);
      const [invoiceTemplate] =
        build<InvoiceTemplate>(normalize(response.data), EntityPath.InvoiceTemplates, ids) || [];

      setInvoiceTemplate(invoiceTemplate);
    };

    fetch();
  }, []);

  return invoiceTemplate;
};
