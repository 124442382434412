import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { fetchCompany } from 'actions/company';
import { fetchNewCorrectionOutgoingInvoice } from 'actions/outgoing-invoice';
import { CORRECT_MODE } from 'constants/common/crud';
import { FORM_NAME } from 'constants/outgoing-invoice';
import { withReminderTabs, withTransitionPrevent, withWarningAlert } from 'shared/hoc';
import { t } from 'shared/utils';
import useLineItemsControl, { SectionType } from 'components/LineItems/useLineItemControl';

import OutgoingInvoiceCreator from '../OutgoingInvoiceCreator/OutgoingInvoiceCreator';

const OutgoingInvoiceCorrect = ({
  fetchNewCorrectionOutgoingInvoice,
  fetchCompany,
  invoiceId,
  isReadonly,
  handleSubmit,
}) => {
  const {
    manualHandleSelectCategory,
    setLineItemState,
    setShowCategoryChangeConfirmation,
    setShowDifferentCategoryWarning,
    setShowVatIdWarning,
    showDifferentCategoryWarning,
    showCategoryChangeConfirmation,
    defaultCategory,
    isDeprecatedCategory,
    canSubmit,
    showVatIdWarning,
    category,
    taxRates,
    taxRateId,
    defaultVat,
    hasSavedLineItems,
    selectedCategory,
    lineCategories,
    isInitial,
  } = useLineItemsControl({ sectionType: SectionType.outgoingInvoice, formName: FORM_NAME });

  useEffect(() => {
    fetchNewCorrectionOutgoingInvoice(invoiceId);
    fetchCompany();
  }, []);

  return (
    <OutgoingInvoiceCreator
      isReadonly={() => isReadonly('client')}
      forceDirty
      invoiceActionMode="Correction"
      crudMode={CORRECT_MODE}
      manualHandleSelectCategory={manualHandleSelectCategory}
      setLineItemState={setLineItemState}
      defaultCategory={defaultCategory}
      hasSavedLineItems={hasSavedLineItems}
      selectedCategory={selectedCategory}
      isDeprecatedCategory={isDeprecatedCategory}
      handleSelectCategory={manualHandleSelectCategory}
      category={category}
      showCategoryChangeConfirmation={showCategoryChangeConfirmation}
      setShowCategoryChangeConfirmation={setShowCategoryChangeConfirmation}
      taxRateId={taxRateId}
      taxRates={taxRates}
      defaultVat={defaultVat}
      lineCategories={lineCategories}
      isInitial={isInitial}
      canSubmit={canSubmit}
      showVatIdWarning={showVatIdWarning}
      setShowVatIdWarning={setShowVatIdWarning}
      showDifferentCategoryWarning={showDifferentCategoryWarning}
      setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
      handleSubmit={handleSubmit}
    />
  );
};

const mapStateToProps = (state, ownProps) => ({
  invoiceId: state.outgoingInvoice.details.id || ownProps.match.params.id,
  isReadonly: (fieldName) => {
    const editableFields = ['bankActivity', 'paid', 'paidByCashDate', 'remark', 'client'];
    return !editableFields.includes(fieldName);
  },
  buttonLabel: t('empty_entry_pages.outgoing_invoice.invoice_warning_box.button_label'),
  content: t('empty_entry_pages.outgoing_invoice.invoice_warning_box.content'),
  dataId: 'OutgoingInvoices:orange-warning-container',
});

const mapDispatchToProps = {
  fetchCompany,
  fetchNewCorrectionOutgoingInvoice,
};

const reduxEnhancer = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(
  reduxEnhancer,
  withWarningAlert,
  withReminderTabs,
  withRouter,
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    persistentSubmitErrors: true,
  }),
  withTransitionPrevent(),
  withWarningAlert
);

export default enhance(OutgoingInvoiceCorrect);
