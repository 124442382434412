import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import {
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  STATUS_FAILURE,
  STATUS_REQUEST,
  STATUS_SUCCESS,
  TRANSFORM_ENTRIES_FAILURE,
  TRANSFORM_ENTRIES_REQUEST,
  TRANSFORM_ENTRIES_SUCCESS,
  UPLOAD_FAILURE,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS,
} from 'constants/import-contacts';
import { transformEntriesSuccess as transformEntriesSuccessNotification } from 'notifications/import-contacts';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

export const apiUploadContacts = ({ file = '', importedModel } = {}) => ({
  [CALL_API]: {
    data: {
      importedModel,
      file: file instanceof File ? file : '',
    },
    endpoint: `/me/imports`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    types: [UPLOAD_REQUEST, UPLOAD_SUCCESS, UPLOAD_FAILURE],
  },
});

export const apiGetImports = () => ({
  [CALL_API]: {
    endpoint: `/me/imports`,
    types: [STATUS_REQUEST, STATUS_SUCCESS, STATUS_FAILURE],
  },
});

export const apiFetchEntries = ({ id, ...rest }) => ({
  [CALL_API]: {
    endpoint: `/me/imports/${id}/entries`,
    params: {
      ...rest,
    },
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiDeleteEntries = ({ id, ...rest }) => ({
  [CALL_API]: {
    endpoint: `/me/imports/${id}`,
    method: 'DELETE',
    params: {
      ...rest,
    },
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const apiTransformEntries = ({ id, ...rest }) => ({
  [CALL_API]: {
    params: {
      ...rest,
    },
    endpoint: `/me/imports/${id}/transform_entries`,
    method: 'POST',
    types: [TRANSFORM_ENTRIES_REQUEST, TRANSFORM_ENTRIES_SUCCESS, TRANSFORM_ENTRIES_FAILURE],
  },
});

export const getImports =
  (...args) =>
  (dispatch) =>
    dispatch(apiGetImports(...args)).catch(apiErrorHandler);
export const fetchEntries =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchEntries(...args)).catch(apiErrorHandler);

export const transformEntries =
  (...args) =>
  (dispatch) =>
    dispatch(apiTransformEntries(...args))
      .catch(apiErrorHandler)
      .then(() => dispatch(showNotification(transformEntriesSuccessNotification)));

export const cancelImport =
  (...args) =>
  (dispatch) =>
    bindServerValidation(apiDeleteEntries(...args), dispatch, { isReduxForm: false }).catch(
      apiErrorHandler
    );

export const uploadContacts =
  (...args) =>
  (dispatch) =>
    bindServerValidation(apiUploadContacts(...args), dispatch, { isReduxForm: false }).catch(
      apiErrorHandler
    );
