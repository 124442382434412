import React from 'react';

import I18n from 'components/I18n';

import paymentsStyles from '../../InvoicePaymentSection.module.css';
import styles from './InvoiceFullyPaidPlaceholder.module.css';

const InvoiceFullyPaidPlaceholder = () => (
  <div className={styles.container}>
    <I18n className={paymentsStyles.placeholderText} t="expenses.payments.fully_paid" />
  </div>
);

export default InvoiceFullyPaidPlaceholder;
