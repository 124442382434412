import React, { memo } from 'react';

import { MODAL_HEADER_VARIANT } from 'constants/modal';
import { ModalComponentProps } from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { ConfirmationModal as ConfirmationModalComponent } from 'components/Modal';

const OBSCancelPopup = (props: ModalComponentProps) => {
  return (
    <ConfirmationModalComponent
      headerVariant={MODAL_HEADER_VARIANT.SMALL}
      dangerousAction
      closeLabel={t('features.financial_planning.obs.cancel_popup.continue_button')}
      confirmLabel={t('features.financial_planning.obs.cancel_popup.cancel_button')}
      header={t('features.financial_planning.obs.cancel_popup.headline')}
      hasWarningIcon
      withCloseButton
      {...props}
    >
      {t('features.financial_planning.obs.cancel_popup.copytext')}
    </ConfirmationModalComponent>
  );
};

export default memo(OBSCancelPopup);
