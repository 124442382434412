import { connect } from 'react-redux';

import { createProposalLineItem } from 'actions/proposal/line-items';
import { TYPE_PROPOSAL } from 'constants/row-types';
import { proposalSelector } from 'reducers/form';
import { productCatalogItemsEnabledHelper } from 'routes/accesses';

import NewItemRow from '../LineItems/NewItemRow';

const mapStateToProps = (state) => ({
  proposalId: state.proposal.details.id,
  smallEntrepreneur: state.company.details.smallEntrepreneur,
  insertedAsGross: state.proposal.details.insertedAsGross,
  productCatalogItemsEnabled: productCatalogItemsEnabledHelper(state),
  lineCategories: state.proposal.lineCategories,
  invoiceLineCategoryId: proposalSelector(state, 'invoiceLineCategoryId'),
});

const mapDispatchToProps = (dispatch) => ({
  createLineItem: (...args) => dispatch(createProposalLineItem(...args)),
});

const mergeProps = (
  { proposalId, lineCategories, productCatalogItemsEnabled, smallEntrepreneur, insertedAsGross },
  dispatchProps,
  ownProps
) => ({
  ...ownProps,
  ...dispatchProps,
  smallEntrepreneur,
  productCatalogItemsEnabled,
  invoiceInsertedAsGross: insertedAsGross,
  translationPath: 'proposals',
  rowType: TYPE_PROPOSAL,
  cells: [
    { name: 'position', type: productCatalogItemsEnabled ? 'positionSelect' : 'positionInput' },
    { name: 'quantity', type: 'input' },
    { name: 'unit', type: 'unitSelect' },
    { type: insertedAsGross ? 'grossLinked' : 'netLinked' },
    { name: 'vat', type: 'vat' },
    { name: insertedAsGross ? 'totalGrossAmount' : 'totalNetAmount', type: 'text' },
  ],
  extendedRowCells: [
    { name: 'itemNumber', type: productCatalogItemsEnabled ? 'itemNumberSelect' : 'itemNumber' },
    { name: 'description', type: 'counterInput' },
    { name: 'internalNotes', type: 'counterInput' },
  ],
  lineCategories,
  createLineItem: (formValues) => dispatchProps.createLineItem(proposalId, formValues),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NewItemRow);
