import React from 'react';

import styles from '../StatusIcons.module.css';

const PartlyPaidStatusIcon = () => (
  <svg viewBox="0 0 20 20" className={styles.statusIcon} xmlns="http://www.w3.org/2000/svg">
    <g className={styles.strokeSuccess} fill="none" fillRule="evenodd">
      <rect x=".5" y=".5" width="19" height="19" rx="2" />
      <path fill="#F8F8F8" d="M7.667 16L3 11.417l1.867-1.834 2.8 2.75L15.133 5 17 6.833z" />
    </g>
  </svg>
);

export default PartlyPaidStatusIcon;
