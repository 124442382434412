export const FETCH_REQUEST = 'ORDER_CONFIRMATION/FETCH_REQUEST';
export const FETCH_SUCCESS = 'ORDER_CONFIRMATION/FETCH_SUCCESS';
export const FETCH_FAILURE = 'ORDER_CONFIRMATION/FETCH_FAILURE';

export const FETCH_LINE_CATEGORIES_REQUEST = 'PROPOSAL/FETCH_LINE_CATEGORIES_REQUEST';
export const FETCH_LINE_CATEGORIES_SUCCESS = 'PROPOSAL/FETCH_LINE_CATEGORIES_SUCCESS';
export const FETCH_LINE_CATEGORIES_FAILURE = 'PROPOSAL/FETCH_LINE_CATEGORIES_FAILURE';

export const CREATE_REQUEST = 'ORDER_CONFIRMATION/CREATE_REQUEST';
export const CREATE_SUCCESS = 'ORDER_CONFIRMATION/CREATE_SUCCESS';
export const CREATE_FAILURE = 'ORDER_CONFIRMATION/CREATE_FAILURE';

export const DELETE_REQUEST = 'ORDER_CONFIRMATION/DELETE_REQUEST';
export const DELETE_SUCCESS = 'ORDER_CONFIRMATION/DELETE_SUCCESS';
export const DELETE_FAILURE = 'ORDER_CONFIRMATION/DELETE_FAILURE';

export const UPDATE_REQUEST = 'ORDER_CONFIRMATION/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'ORDER_CONFIRMATION/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'ORDER_CONFIRMATION/UPDATE_FAILURE';

export const TOGGLE_GROSS_NET_REQUEST = 'ORDER_CONFIRMATION/TOGGLE_GROSS_NET_REQUEST';
export const TOGGLE_GROSS_NET_SUCCESS = 'ORDER_CONFIRMATION/TOGGLE_GROSS_NET_SUCCESS';
export const TOGGLE_GROSS_NET_FAILURE = 'ORDER_CONFIRMATION/TOGGLE_GROSS_NET_FAILURE';

export const FIRST_UPDATE_REQUEST = 'ORDER_CONFIRMATION/FIRST_UPDATE_REQUEST';
export const FIRST_UPDATE_SUCCESS = 'ORDER_CONFIRMATION/FIRST_UPDATE_SUCCESS';
export const FIRST_UPDATE_FAILURE = 'ORDER_CONFIRMATION/FIRST_UPDATE_FAILURE';

export const FETCH_SUMS_REQUEST = 'ORDER_CONFIRMATION/FETCH_SUMS_REQUEST';
export const FETCH_SUMS_SUCCESS = 'ORDER_CONFIRMATION/FETCH_SUMS_SUCCESS';
export const FETCH_SUMS_FAILURE = 'ORDER_CONFIRMATION/FETCH_SUMS_FAILURE';

export const DOWNLOAD_REQUEST = 'ORDER_CONFIRMATION/DOWNLOAD_REQUEST';
export const DOWNLOAD_SUCCESS = 'ORDER_CONFIRMATION/DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'ORDER_CONFIRMATION/DOWNLOAD_FAILURE';

export const TRANSFORM_REQUEST = 'ORDER_CONFIRMATION/TRANSFORM_REQUEST';
export const TRANSFORM_SUCCESS = 'ORDER_CONFIRMATION/TRANSFORM_SUCCESS';
export const TRANSFORM_FAILURE = 'ORDER_CONFIRMATION/TRANSFORM_FAILURE';

export const REORDER_LINE_ITEMS_LOCAL = 'ORDER_CONFIRMATION/REORDER_LINE_ITEMS_LOCAL';
export const REORDER_LINE_ITEMS_REQUEST = 'ORDER_CONFIRMATION/REORDER_LINE_ITEMS_REQUEST';
export const REORDER_LINE_ITEMS_SUCCESS = 'ORDER_CONFIRMATION/REORDER_LINE_ITEMS_SUCCEESS';
export const REORDER_LINE_ITEMS_FAILURE = 'ORDER_CONFIRMATION/REORDER_LINE_ITEMS_FAILURE';

export const FROM_ORDER_CONFIRMATION_QUERY_PARAM = 'from-order-confirmation';

export const FORM_NAME = 'orderConfirmationCreator';

export const ITEMS_DROPPABLE_ID = 'ORDER_CONFIRMATION/LINE_ITEMS_DROPPABLE';

export const DUPLICATE_ORDER_CONFIRMATION_REQUEST =
  'ORDER_CONFIRMATION/DUPLICATE_ORDER_CONFIRMATION_REQUEST';
export const DUPLICATE_ORDER_CONFIRMATION_SUCCESS =
  'ORDER_CONFIRMATION/DUPLICATE_ORDER_CONFIRMATION_SUCCESS';
export const DUPLICATE_ORDER_CONFIRMATION_FAILURE =
  'ORDER_CONFIRMATION/DUPLICATE_ORDER_CONFIRMATION_FAILURE';

export const FROM_DUPLICATE_QUERY_PARAM = 'from-duplicate';
