import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from 'shared/styles/table.module.css';

import localStyles from './PaymentColumn.module.css';

const PaymentColumn = ({ className, children, size = 'auto', align = 'left', ...rest }) => (
  <td {...rest} className={cx(localStyles.column, styles[size], styles[`align-${align}`])}>
    <div className={className}>{children}</div>
  </td>
);

PaymentColumn.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
  align: PropTypes.string,
};

export default PaymentColumn;
