import { push } from 'connected-react-router';
import { change } from 'redux-form';

import { checkOutOfSyncAndNotify } from 'actions/bank-account';
import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import {
  CHANGE_ERROR_DATA,
  GET_LOGIN_INFO_FAILURE,
  GET_LOGIN_INFO_REQUEST,
  GET_LOGIN_INFO_SUCCESS,
  INVALIDATE_TOKEN,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  PRELOGIN_FAILURE,
  PRELOGIN_REQUEST,
  PRELOGIN_SUCCESS,
  RESEND_CONFIRMATION_MAIL_FAILURE,
  RESEND_CONFIRMATION_MAIL_REQUEST,
  RESEND_CONFIRMATION_MAIL_SUCCESS,
  SET_AFTER_LOGIN_ROUTE,
  VALIDATE_FAILURE,
  VALIDATE_REQUEST,
  VALIDATE_SUCCESS,
} from 'constants/auth';
import { RegistrationSource } from 'constants/registration';
import { sessionTimeout } from 'notifications/session-timeout';
import paths from 'routes/paths';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const changeErrorData = (error) => {
  const { response: { data = {} } = {} } = error || {};
  return {
    payload: { ...data },
    type: CHANGE_ERROR_DATA,
  };
};

export const apiCheckToken = () => ({
  [CALL_API]: {
    endpoint: '/me/profile',
    types: [VALIDATE_REQUEST, VALIDATE_SUCCESS, VALIDATE_FAILURE],
  },
});

export const apiPrelogin = (email) => ({
  [CALL_API]: {
    params: { email },
    authRequired: false,
    omitReducers: true,
    endpoint: '/prelogin',
    types: [PRELOGIN_REQUEST, PRELOGIN_SUCCESS, PRELOGIN_FAILURE],
  },
});

export const apiGetLoginInfo = ({ email, token }) => ({
  [CALL_API]: {
    params: { email, token },
    authRequired: false,
    endpoint: '/login_info',
    types: [GET_LOGIN_INFO_REQUEST, GET_LOGIN_INFO_SUCCESS, GET_LOGIN_INFO_FAILURE],
  },
});

const apiResendConfirmationEmail = (values) => ({
  [CALL_API]: {
    params: values,
    method: 'POST',
    authRequired: false,
    omitReducers: true,
    endpoint: '/resend_confirmation_email',
    types: [
      RESEND_CONFIRMATION_MAIL_REQUEST,
      RESEND_CONFIRMATION_MAIL_SUCCESS,
      RESEND_CONFIRMATION_MAIL_FAILURE,
    ],
  },
});

export const apiLogin = (data = {}) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    authRequired: false,
    endpoint: '/oauth/token',
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
  },
});

export const apiLogout = (token) => ({
  [CALL_API]: {
    data: { token },
    method: 'POST',
    endpoint: '/oauth/revoke',
    types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
  },
});

export const setAfterLoginRoute = (afterLoginRoute) => ({
  type: SET_AFTER_LOGIN_ROUTE,
  payload: { afterLoginRoute },
});

export const invalidateToken = () => (dispatch) => dispatch({ type: INVALIDATE_TOKEN });

export const checkToken = () => (dispatch, getState) => {
  const { auth: { token = '' } = {} } = getState();
  if (!token) {
    return Promise.reject(new Error('Token is empty.'));
  }
  return dispatch(apiCheckToken());
};

export const logout = () => (dispatch, getState) => {
  const { auth: { token } = {} } = getState() || {};
  return dispatch(apiLogout(token))
    .then(() => dispatch(invalidateToken()))
    .catch(apiErrorHandler);
};

const redirectAfterLogin = (dispatch, getState) => {
  const { afterLoginRoute } = getState().auth;
  const shouldRedirectAfterLogin = !!afterLoginRoute;

  if (shouldRedirectAfterLogin) {
    dispatch(push(afterLoginRoute));
  }
};

export const login = (values) => async (dispatch, getState) => {
  let persistedPreloginToken;
  try {
    const { rawResponse: preloginToken } = await dispatch(apiPrelogin(values.email));
    await dispatch(apiGetLoginInfo({ email: values.email, token: preloginToken }));
    persistedPreloginToken = preloginToken;

    const { loginInfo } = getState().auth;
    const { active, registrationSource, confirmed, gracePeriodValid } = loginInfo || {};

    if (registrationSource === RegistrationSource.Vrso && !active) {
      return dispatch(push(paths.vrsoLoginBlocked));
    }

    const isUserUnconfirmed = loginInfo && !confirmed && !gracePeriodValid;
    if (isUserUnconfirmed) {
      return dispatch(push(paths.unconfirmedUser(preloginToken, values.email)));
    }
    // eslint-disable-next-line no-empty
  } catch {}

  return dispatch(
    apiLogin({
      grant_type: 'password',
      username: values.email || '',
      password: values.password || '',
    })
  )
    .then(() => {
      dispatch(checkOutOfSyncAndNotify());
      dispatch(apiGetLoginInfo({ email: values.email, token: persistedPreloginToken }));
      redirectAfterLogin(dispatch, getState);
    })
    .catch((error) => {
      dispatch(change('login', 'password', ''));
      dispatch(changeErrorData(error));
      return apiErrorHandler(error);
    });
};

export const logoutWithTimeoutNotification = () => async (dispatch, getState) => {
  const lastRoute = getState().router.location.pathname;
  await dispatch(logout());
  await dispatch(showNotification(sessionTimeout));
  await dispatch(setAfterLoginRoute(lastRoute));
};

export const invalidateAndNotify = () => (dispatch) => {
  dispatch(invalidateToken());
  return dispatch(showNotification(sessionTimeout));
};

export const getLoginInfo = (values) => (dispatch) => {
  dispatch(apiGetLoginInfo({ email: values.email, token: values.token }));
};

export const resendConfirmationEmail = (preloginToken, userEmail) => async (dispatch, getState) => {
  if (userEmail) {
    return dispatch(apiResendConfirmationEmail({ email: userEmail }));
  }

  if (preloginToken) {
    return dispatch(apiResendConfirmationEmail({ request_token: preloginToken }));
  }

  const { email } = getState().profile.credentials;

  dispatch(apiResendConfirmationEmail({ email }));
};
