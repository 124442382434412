import { t } from 'shared/utils';

export const accountOutOfSync = (bankAccountNumber) => ({
  duration: 7000,
  bankAccountNumber,
  component: 'BankAccountNotification',
  variant: 'warning',
});

export const showOnInvoiceSuccess = {
  duration: 3000,
  title: t('notifications.bank_account.show_on_invoice_success.title'),
  variant: 'success',
};

export const showOnInvoiceFailure = {
  duration: 3000,
  title: t('notifications.bank_account.show_on_invoice_failure.title'),
  variant: 'warning',
};

export const hideOnInvoiceSuccess = {
  duration: 3000,
  title: t('notifications.bank_account.hide_on_invoice_success.title'),
  variant: 'success',
};

export const hideOnInvoiceFailure = {
  duration: 3000,
  title: t('notifications.bank_account.hide_on_invoice_failure.title'),
  variant: 'warning',
};

export const bankTransferSyncFailure = {
  duration: 7000,
  title: t('notifications.bank_account.transfers_sync_failure.title'),
  text: t('notifications.bank_account.transfers_sync_failure.message'),
  variant: 'warning',
};

export const acceptSuggestion = {
  duration: 3000,
  title: t('notifications.bank_account.accept_suggestion.title'),
  variant: 'success',
};

export const rejectSuggestion = {
  duration: 3000,
  title: t('notifications.bank_account.reject_suggestion.title'),
  variant: 'success',
};
