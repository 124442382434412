import React, { memo, SVGProps } from 'react';

const ArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.492 4.103l4.514 4.124 4.513-4.124a1.237 1.237 0 011.64 0 .995.995 0 010 1.498l-5.339 4.88a1.237 1.237 0 01-1.64 0L6.84 5.6a.995.995 0 010-1.498c.454-.404 1.198-.415 1.652 0z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default memo(ArrowDown);
