import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push } from 'connected-react-router';
import { get, head, identity, isEmpty, keys, pickBy, toPlainObject } from 'lodash';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose, withState } from 'recompose';
import { change as changeAction, reset, untouch as untouchAction } from 'redux-form';

import {
  fetchBankAccounts,
  setMainBankAccount as setMainBankAccountAction,
  updateBankAccount as updateBankAccountAction,
} from 'actions/bank-account';
import {
  connectBankAccount as connectBankAccountAction,
  getBankAccess as getBankAccessAction,
  getSca as getScaAction,
  indexBanks,
  initializeSca as initializeScaAction,
  preLoginToBank as preLoginToBankAction,
  selectSca as selectScaAction,
  solveSca as solveScaAction,
} from 'actions/banks';
import { fetchPaymentsBankAccounts as fetchPaymentsBankAccountsAction } from 'actions/incoming-invoice/payments';
import {
  showErrorNotification as showErrorNotificationAction,
  showNotification,
} from 'actions/notification';
import { fetchUserPlan as fetchUserPlanAction } from 'actions/payment-plan';
import {
  CREATOR_STEPS_ACCESS_METHOD_SELECTION,
  CREATOR_STEPS_DECOUPLED_CHALLENGE,
  CREATOR_STEPS_REDIRECT_CHALLENGE,
  CREATOR_STEPS_SCA_SELECT_TAN,
  CREATOR_STEPS_TAN_CHALLENGE,
} from 'constants/banks';
import {
  PIWIK_ACTION_BANK_ACCOUNT_ADD,
  PIWIK_ACTION_BANK_DATA_SAVED,
  PIWIK_ACTION_BANK_DETAILS_ENTERED,
  PIWIK_ACTION_BANK_SELECTED,
  PIWIK_CATEGORY_PROFILE,
  PIWIK_GOAL_ADD_BANK_ACCOUNT,
} from 'constants/piwik';
import {
  addAccountsSuccess,
  addAccountSuccess,
  bankLoginSuccess,
  bankTransfersSyncInBackground,
} from 'notifications/banks';
import { getBank } from 'reducers/banks';
import { getBankAccounts } from 'reducers/banks/accounts';
import { getProfile } from 'reducers/profile';
import paths from 'routes/paths';
import { userBankAccountsSelector } from 'selectors/bank-account';
import { t } from 'shared/utils';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { piwikHelpers } from 'shared/utils/piwik';
import { ChallengeType, JobStatus } from 'types/entities/Figo';
import I18n from 'components/I18n';
import { ConfirmationModal } from 'components/Modal';
import RedirectChallenge from 'features/figoConnection/challenges/Redirect/Redirect';
import {
  DECOUPLED_CHALLENGE_RETRY_TIMEOUT,
  ERROR_NOTIFICATION_DURATION,
  REDIRECT_CHALLENGE_RETRY_TIMEOUT,
} from 'features/figoConnection/constants';

import AccessMethodSelection from './components/AccessMethodSelection/AccessMethodSelection';
import DecoupledChallenge from './components/DecoupledChallenge/DecoupledChallenge';
import FigoErrorMessage from './components/FigoErrorMessage/FigoErrorMessage';
import Page1 from './components/Page1/Page1';
import Page2 from './components/Page2/Page2';
import Page3 from './components/Page3/Page3';
import Page4 from './components/Page4/Page4';
import Page5 from './components/Page5/Page5';
import TanChallenge from './components/TanChallenge/TanChallenge';
import TanSelection from './components/TanSelection/TanSelection';

import styles from './BankAccountsCreator.module.css';

const CreatorSteps = {
  BANK_SELECTION: 1,
  ACCESS_METHOD_SELECTION: CREATOR_STEPS_ACCESS_METHOD_SELECTION,
  AUTHENTICATION: 2,
  TAN_SELECTION: CREATOR_STEPS_SCA_SELECT_TAN,
  TAN_CHALLENGE: CREATOR_STEPS_TAN_CHALLENGE,
  DECOUPLED_CHALLENGE: CREATOR_STEPS_DECOUPLED_CHALLENGE,
  REDIRECT_CHALLENGE: CREATOR_STEPS_REDIRECT_CHALLENGE,
  ACCOUNTS_SELECTION: 3,
  MAIN_ACCOUNT_SELECTION: 4,
  MANUAL_TRANSFERS_SYNC_INFO: 5,
};

const defaultScaState = {
  isLoadingTanSelection: false,
  isLoadingTanSolve: false,
  loginData: {
    savePin: false,
  },
  acceptedBankAccounts: [],
  selectedTanScheme: {},
  accessIds: {},
  challengeId: '',
  timeoutId: null,
  challenge: null,
};

const defaultState = {
  accessMethods: {},
  accessMethodType: '',
  currentPage: CreatorSteps.BANK_SELECTION,
  loginInProcess: false,
  ...defaultScaState,
};

const piwikActionForWizardStep = {
  1: PIWIK_ACTION_BANK_SELECTED,
  2: PIWIK_ACTION_BANK_DETAILS_ENTERED,
};

class BankAccountsCreator extends Component {
  static defaultProps = {
    hasExternalSteps: false,
  };

  state = { ...defaultState };

  componentDidMount() {
    this.props.indexBanks();
    piwikHelpers.trackEvent(PIWIK_CATEGORY_PROFILE, PIWIK_ACTION_BANK_ACCOUNT_ADD);
  }

  componentWillUnmount() {
    this.destroyForm();
    clearTimeout(this.state.timeoutId);
  }

  getAcceptedBankAccounts = (formData) => {
    if (!formData) return [];
    const acceptedBankAccounts = get(formData, 'accepted');
    if (!acceptedBankAccounts || isEmpty(acceptedBankAccounts)) return [];
    const creditLines = get(formData, 'creditLine') || new Array(acceptedBankAccounts.length);
    const bankAccountIds = keys(pickBy(toPlainObject(acceptedBankAccounts), identity));
    return this.props.bankAccounts
      .map((bankAccount) => ({ ...bankAccount, creditLine: creditLines[bankAccount.id] }))
      .filter((bankAccount) => bankAccountIds.includes(bankAccount.id));
  };

  shouldAssignMainBankAccount = () => {
    const { profile: { credentials: { mainBankAccount } = {} } = {}, formValues } = this.props;
    const acceptedBankAccounts = this.getAcceptedBankAccounts(formValues) || [];
    const applicableBankAccounts = acceptedBankAccounts.filter(
      (account) => account.paymentApplicable
    );
    return !mainBankAccount && !isEmpty(applicableBankAccounts);
  };

  destroyForm = () => {
    const { resetForm, setLoading } = this.props;

    this.setState({ ...defaultState });
    setLoading(false);
    resetForm();
  };

  cleanupCreation = () => {
    const { resetForm, setLoading } = this.props;
    if (this.state.loginInProcess) return;

    this.setState({ ...defaultState });
    piwikHelpers.trackGoal(PIWIK_GOAL_ADD_BANK_ACCOUNT);
    piwikHelpers.trackEvent(PIWIK_CATEGORY_PROFILE, PIWIK_ACTION_BANK_DATA_SAVED);
    setLoading(false);
    resetForm();
    clearTimeout(this.state.timeoutId);
  };

  handleCloseCreator = () => {
    const { acceptedBankAccounts } = this.state;
    const { formValues, syncInBackgroundNotification, onFinish, onReloadList } = this.props;

    if (acceptedBankAccounts.length && formValues.savePin) {
      syncInBackgroundNotification();
    }

    onReloadList();
    onFinish();
    this.cleanupCreation();
  };

  finishCreation = (mainAccountNumber) => {
    const {
      setMainBankAccount,
      fetchUserPlan,
      formValues,
      hasExternalSteps,
      redirectAfterSuccess,
    } = this.props;

    this.connectBankAccounts()
      .then(({ response: { data = [] } }) => {
        const { savePin } = formValues;

        if (mainAccountNumber) {
          const { id: mainAccountId } = data.find(
            ({ attributes: { number } }) => number === mainAccountNumber
          );
          setMainBankAccount(mainAccountId).then(fetchUserPlan);
        }

        return !savePin && !hasExternalSteps
          ? this.goToManualSyncPage()
          : this.handleCloseCreator();
      })
      .finally(() => {
        if (redirectAfterSuccess) {
          redirectAfterSuccess();
        }
      });
  };

  goToAuthenticationPage = () => {
    const { change, untouch, formValues: { credentials = [] } = {}, setLoading } = this.props;

    setLoading(false);
    change('credentials', {});
    change('savePin', false);
    // we want to untouch all fields from credentials array to skip validation errors
    Object.keys(credentials).forEach((key) => untouch(`credentials.${key}`));
    this.setState({ ...defaultScaState, currentPage: CreatorSteps.AUTHENTICATION });
  };

  goToManualSyncPage = () => {
    this.setState({ currentPage: CreatorSteps.MANUAL_TRANSFERS_SYNC_INFO });
  };

  handlePrevPage = () => {
    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  handleNextPage = () => {
    const piwikActionForCurrentPage = piwikActionForWizardStep[this.state.currentPage];

    if (piwikActionForCurrentPage) {
      piwikHelpers.trackEvent(PIWIK_CATEGORY_PROFILE, piwikActionForCurrentPage);
    }
    this.setState({ currentPage: this.state.currentPage + 1 });
  };

  handleLoginRequest = (data) => {
    const { savePin = false } = data;
    const safeData = { savePin, scope: ['ACCOUNTS'], ...data };
    this.setState({ loginData: safeData });

    if (!savePin && data.credentials) return this.props.setIsModalOpen(true);

    return this.loginToBanks(safeData);
  };

  onPinModalConfirm = () => {
    this.props.setIsModalOpen(false);
    this.loginToBanks(this.state.loginData);
  };

  onPinModalClose = () => this.props.setIsModalOpen(false);

  getSca = async (rawResponse) => {
    const { showErrorNotification, getSca } = this.props;

    try {
      const { status, challenge } = await getSca(rawResponse);

      if (status === JobStatus.Completed) {
        this.handleLoginToBanksSuccess();
        return;
      }

      if (status === JobStatus.AwaitAuth) {
        /**
         * When we receive a DECOUPLED challenge it's mean that in a loop we have to check the
         * SCA status to see if it's completed or not.
         */
        if (challenge.type === ChallengeType.Decoupled) {
          const timeoutId = setTimeout(
            () => this.getSca(rawResponse),
            DECOUPLED_CHALLENGE_RETRY_TIMEOUT
          );
          this.handleDecoupledChallenge(timeoutId, challenge);
          return;
        }

        if (challenge.type === ChallengeType.Redirect) {
          const timeoutId = setTimeout(
            () => this.getSca(rawResponse),
            REDIRECT_CHALLENGE_RETRY_TIMEOUT
          );
          this.handleRedirectChallenge(timeoutId, challenge);
          return;
        }

        if (challenge.type === ChallengeType.MethodSelection) {
          this.handleTanSelection(challenge);
          return;
        }

        if (challenge.type === ChallengeType.Embedded) {
          this.handleTanWithoutSelection(challenge);
          return;
        }
      }
    } catch (e) {
      if (e.message) {
        showErrorNotification(e.message);
      }
      apiErrorHandler(e);
      this.setState({ loginInProcess: false });
      this.goToAuthenticationPage();
    }
  };

  // TODO: Refactor this whole component with hooks and make whole login reusable.
  // Also do the same in components/SyncBankAccountButton/index.jsx

  loginToBanks = async (data) => {
    const { preLoginToBank, getBankAccess, initializeSca, showErrorNotification } = this.props;
    const { accessMethodType } = this.state;

    this.setState({ loginInProcess: true, challengeDecoupledMessage: '' });

    try {
      await preLoginToBank(data);

      const {
        rawResponse: { figo_id },
      } = await getBankAccess({ ...data, accessMethodType });
      const { rawResponse } = await initializeSca({ ...data, figo_id });

      this.setState({ accessIds: rawResponse });
      this.getSca(rawResponse);
    } catch (e) {
      if (e.message) {
        showErrorNotification(e.message);
      }
      apiErrorHandler(e);
      this.setState({ loginInProcess: false });
    }
  };

  handleDecoupledChallenge = (timeoutId, challenge) =>
    this.setState({
      currentPage: CreatorSteps.DECOUPLED_CHALLENGE,
      loginInProcess: false,
      timeoutId,
      challenge,
    });

  handleRedirectChallenge = (timeoutId, challenge) =>
    this.setState({
      currentPage: CreatorSteps.REDIRECT_CHALLENGE,
      loginInProcess: false,
      timeoutId,
      challenge,
    });

  handleTanWithoutSelection = (challenge) =>
    this.setState({
      currentPage: CreatorSteps.TAN_CHALLENGE,
      loginInProcess: false,
      challenge,
    });

  handleTanSelection = (challenge) => {
    this.setState({
      currentPage: CreatorSteps.TAN_SELECTION,
      loginInProcess: false,
      challenge,
    });
  };

  selectTan = (selectedTanMethod) => {
    this.setState({ selectedTanScheme: selectedTanMethod });
  };

  handleSetSelectedTanPage = async () => {
    const { selectSca, showErrorNotification } = this.props;
    const {
      accessIds,
      selectedTanScheme: { id },
      challenge: { id: challengeId },
    } = this.state;

    this.setState({ isLoadingTanSelection: true });

    try {
      const { status, challenge = {} } = await selectSca({
        ...accessIds,
        challenge_id: challengeId,
        method_id: id,
      });

      if (status === JobStatus.Completed) {
        this.handleLoginToBanksSuccess();
        return;
      }

      // For the case when accessIds are expired on backend
      if (isEmpty(challenge)) {
        this.goToAuthenticationPage();
        return;
      }

      /**
       * When we receive a DECOUPLED or REDIRECT challenge it's mean that in a loop we have to
       * check the SCA status to see if it's completed or not.
       */
      if ([ChallengeType.Decoupled, ChallengeType.Redirect].includes(challenge.type)) {
        this.getSca(accessIds);
        this.setState({ isLoadingTanSelection: false });
        return;
      }

      this.setState({
        isLoadingTanSelection: false,
        currentPage: CreatorSteps.TAN_CHALLENGE,
        challenge,
      });
    } catch (e) {
      if (e.message) {
        showErrorNotification(e.message);
      }
      apiErrorHandler(e);
      this.goToAuthenticationPage();
    }
  };

  handleTanChallengeSolve = async (tanResponse) => {
    const { solveSca, showErrorNotification } = this.props;
    const { accessIds, challenge } = this.state;

    this.setState({ isLoadingTanSolve: true });

    try {
      const { response = {} } = await solveSca({
        challenge_response: tanResponse,
        challenge_id: challenge.id,
        ...accessIds,
      });

      if (response.status === JobStatus.Completed) {
        this.handleLoginToBanksSuccess();
        return;
      }

      // For the case when accessIds are expired on backend
      if (isEmpty(response)) {
        this.goToAuthenticationPage();
        return;
      }

      this.setState({ isLoadingTanSolve: false });
    } catch (e) {
      if (e.message) {
        showErrorNotification(e.message);
      }
      apiErrorHandler(e);
      this.goToAuthenticationPage();
    }
  };

  handleLoginToBanksSuccess = () => {
    this.props.loginSuccessNotification();
    this.setState({
      currentPage: CreatorSteps.ACCOUNTS_SELECTION,
      loginInProcess: false,
      isLoadingTanSelection: false,
      isLoadingTanSolve: false,
    });
  };

  handleConnectBankAccounts = (formData) => {
    const acceptedBankAccounts = this.getAcceptedBankAccounts(formData);

    const successAction = this.shouldAssignMainBankAccount()
      ? this.handleNextPage
      : this.finishCreation;
    this.setState({ acceptedBankAccounts }, successAction);
  };

  connectBankAccounts = () => {
    const {
      bank: { id: bankId },
      connectBankAccount,
      updateBankAccount,
      indexBankAccounts,
      addAccountsSuccessNotification,
      setLoading,
      fetchPaymentsBankAccounts,
    } = this.props;
    const { acceptedBankAccounts, accessMethodType } = this.state;

    setLoading(true);
    const pendingConnections = acceptedBankAccounts.map(({ creditLine, accountId }) =>
      connectBankAccount(bankId, accountId, accessMethodType).then(
        ({
          response: {
            data: { id, attributes },
          },
        }) => updateBankAccount({ ...attributes, id, creditLine })
      )
    );
    return Promise.all(pendingConnections)
      .then(() => setLoading(false))
      .then((addedAccounts = []) => addAccountsSuccessNotification(addedAccounts.length))
      .then(indexBankAccounts)
      .then(fetchPaymentsBankAccounts)
      .catch(() => setLoading(false));
  };

  handleSelectMainAccount = ({ mainAccountNumber }) => this.finishCreation(mainAccountNumber);

  handleBankSelect = () => {
    const { bank } = this.props;

    const shouldShowAccessMethodSelectPage = bank.accessMethods.length !== 1;

    return shouldShowAccessMethodSelectPage
      ? this.handleSelectAccessMethodPage()
      : this.handleLoginPage();
  };

  handleLoginPage = () => {
    const {
      bank: { accessMethods },
    } = this.props;

    const { type } = head(accessMethods);
    this.setState({ accessMethodType: type }, this.handleNextPage());
  };

  handleSelectAccessMethodPage = () => {
    const {
      bank: { accessMethods },
    } = this.props;

    this.setState({ accessMethods, currentPage: CreatorSteps.ACCESS_METHOD_SELECTION });
  };

  handleAccessMethodSelect = (accessMethodType) =>
    this.setState({ accessMethodType, currentPage: CreatorSteps.AUTHENTICATION });

  renderNavigation() {
    const { currentPage, accessMethods } = this.state;

    return (
      <div
        className={cx(styles.navigation, {
          [styles.hidden]: CreatorSteps.MANUAL_TRANSFERS_SYNC_INFO,
        })}
      >
        <span
          className={cx(styles.step, {
            [styles.current]: currentPage === CreatorSteps.BANK_SELECTION,
          })}
        >
          {t('bank_accounts.creator.steps.bank')}
        </span>
        {!isEmpty(accessMethods) && (
          <span
            className={cx(styles.step, {
              [styles.current]: currentPage === CreatorSteps.ACCESS_METHOD_SELECTION,
            })}
          >
            {t('bank_accounts.creator.steps.access_method_select')}
          </span>
        )}
        <span
          className={cx(styles.step, {
            [styles.current]: [
              CreatorSteps.AUTHENTICATION,
              CreatorSteps.TAN_SELECTION,
              CreatorSteps.TAN_CHALLENGE,
              CreatorSteps.DECOUPLED_CHALLENGE,
              CreatorSteps.REDIRECT_CHALLENGE,
            ].includes(currentPage),
          })}
        >
          {t('bank_accounts.creator.steps.auth')}
        </span>
        <span
          className={cx(styles.step, {
            [styles.current]: currentPage === CreatorSteps.ACCOUNTS_SELECTION,
          })}
        >
          {t('bank_accounts.creator.steps.account')}
        </span>
        {this.shouldAssignMainBankAccount() && (
          <span
            className={cx(styles.step, {
              [styles.current]: currentPage === CreatorSteps.MAIN_ACCOUNT_SELECTION,
            })}
          >
            {t('bank_accounts.creator.steps.main_account')}
          </span>
        )}
      </div>
    );
  }

  render() {
    const { setLoading, isLoading, isModalOpen, goToBankTransfers, onCancel, hasExternalSteps } =
      this.props;
    const {
      acceptedBankAccounts = [],
      currentPage,
      loginInProcess,
      selectedTanScheme,
      isLoadingTanSelection,
      isLoadingTanSolve,
      accessMethodType,
      accessMethods,
      challenge,
    } = this.state;
    const acceptedSepaBankAccounts = acceptedBankAccounts.filter(
      (account) => account.paymentApplicable
    );

    return (
      <div className={styles.creatorWrapper}>
        {this.renderNavigation()}

        {currentPage === CreatorSteps.BANK_SELECTION && (
          <Page1
            onCancel={onCancel}
            onSubmit={this.handleBankSelect}
            setLoading={setLoading}
            hasExternalSteps={hasExternalSteps}
          />
        )}

        {currentPage === CreatorSteps.ACCESS_METHOD_SELECTION && (
          <AccessMethodSelection
            accessMethods={accessMethods}
            handleCancel={this.cleanupCreation}
            handleNextStep={this.handleAccessMethodSelect}
          />
        )}

        {currentPage === CreatorSteps.DECOUPLED_CHALLENGE && (
          <DecoupledChallenge challenge={challenge} />
        )}

        {currentPage === CreatorSteps.REDIRECT_CHALLENGE && (
          <RedirectChallenge challenge={challenge} onCancel={this.cleanupCreation} />
        )}

        {currentPage === CreatorSteps.AUTHENTICATION && (
          <Page2
            accessMethodType={accessMethodType}
            onSubmit={this.handleLoginRequest}
            handleCancel={this.cleanupCreation}
            loginInProcess={loginInProcess}
            setLoading={setLoading}
          />
        )}

        {currentPage === CreatorSteps.TAN_SELECTION && (
          <TanSelection
            supportedTanSchemes={challenge.auth_methods}
            selectedTanScheme={selectedTanScheme}
            setCreatorState={this.selectTan}
            handleCancel={this.goToAuthenticationPage}
            handleNextStep={this.handleSetSelectedTanPage}
            isLoading={isLoadingTanSelection}
          />
        )}

        {currentPage === CreatorSteps.TAN_CHALLENGE && (
          <TanChallenge
            tanChallenge={challenge}
            handleCancel={this.goToAuthenticationPage}
            handleSubmit={this.handleTanChallengeSolve}
            isLoading={isLoadingTanSolve}
            selectedTanSchemeName={selectedTanScheme.name}
          />
        )}

        {currentPage === CreatorSteps.ACCOUNTS_SELECTION && (
          <Page3
            onSubmit={this.handleConnectBankAccounts}
            handlePrevPage={this.handlePrevPage}
            shouldAssignMainBankAccount={this.shouldAssignMainBankAccount()}
            setLoading={setLoading}
            isLoading={isLoading}
            hasExternalSteps={hasExternalSteps}
          />
        )}

        {currentPage === CreatorSteps.MAIN_ACCOUNT_SELECTION && (
          <Page4
            onSubmit={this.handleSelectMainAccount}
            handlePrevPage={this.handlePrevPage}
            setLoading={setLoading}
            isLoading={isLoading}
            acceptedSepaBankAccounts={acceptedSepaBankAccounts}
          />
        )}

        {currentPage === CreatorSteps.MANUAL_TRANSFERS_SYNC_INFO && (
          <Page5 handleClose={this.handleCloseCreator} handleConfirm={goToBankTransfers} />
        )}

        <ConfirmationModal
          dataIds={{
            modal: 'BankAccount:pinConfirmationModal',
            abortButton: 'BankAccount:button-abort',
            acceptButton: 'BankAccount:button-accept',
          }}
          isOpen={isModalOpen}
          onClose={this.onPinModalClose}
          onConfirm={this.onPinModalConfirm}
          confirmLabel={t('bank_accounts.creator.ok')}
          closeLabel={t('bank_accounts.creator.cancel')}
          header={t('bank_accounts.creator.without_pin.header')}
        >
          <I18n t="bank_accounts.creator.without_pin.message" />
        </ConfirmationModal>
      </div>
    );
  }
}

BankAccountsCreator.propTypes = {
  resetForm: func.isRequired,
  change: func.isRequired,
  untouch: func.isRequired,
  indexBanks: func.isRequired,
  preLoginToBank: func.isRequired,
  getBankAccess: func.isRequired,
  initializeSca: func.isRequired,
  getSca: func.isRequired,
  selectSca: func.isRequired,
  solveSca: func.isRequired,
  indexBankAccounts: func.isRequired,
  connectBankAccount: func.isRequired,
  updateBankAccount: func.isRequired,
  setMainBankAccount: func.isRequired,
  addAccountsSuccessNotification: func.isRequired,
  onFinish: func.isRequired,
  onReloadList: func,
  onCancel: func,
  setLoading: func.isRequired,
  isLoading: bool.isRequired,
  bankAccounts: arrayOf(shape({})).isRequired,
  setIsModalOpen: func.isRequired,
  loginSuccessNotification: func.isRequired,
  isModalOpen: bool,
  hasExternalSteps: bool,
  formValues: shape({}),
  profile: shape({}),
  bank: shape({ id: string.isRequired }).isRequired,
  fetchUserPlan: func.isRequired,
  fetchPaymentsBankAccounts: func.isRequired,
  syncInBackgroundNotification: func.isRequired,
  goToBankTransfers: func.isRequired,
  showErrorNotification: func.isRequired,
};

const FORM_NAME = 'bankAccountCreator';

const mapStateToProps = (state) => ({
  bank: getBank(state, get(state, 'form.bankAccountCreator.values.bankCode')),
  formValues: get(state, 'form.bankAccountCreator.values'),
  bankAccounts: getBankAccounts(state),
  oldBankAccounts: userBankAccountsSelector(state),
  profile: getProfile(state),
});

const mapDispatchToProps = (dispatch) => ({
  indexBanks: (...args) => dispatch(indexBanks(...args)),
  preLoginToBank: (...args) => dispatch(preLoginToBankAction(...args)),
  getBankAccess: (...args) => dispatch(getBankAccessAction(...args)),
  initializeSca: (...args) => dispatch(initializeScaAction(...args)),
  getSca: (...args) => dispatch(getScaAction(...args)),
  selectSca: (...args) => dispatch(selectScaAction(...args)),
  solveSca: (...args) => dispatch(solveScaAction(...args)),
  indexBankAccounts: (...args) => dispatch(fetchBankAccounts(...args)),
  connectBankAccount: (...args) => dispatch(connectBankAccountAction(...args)),
  updateBankAccount: (...args) => dispatch(updateBankAccountAction(...args)),
  setMainBankAccount: (...args) => dispatch(setMainBankAccountAction(...args)),
  resetForm: () => dispatch(reset(FORM_NAME)),
  change: (field, value) => dispatch(changeAction(FORM_NAME, field, value)),
  untouch: (field) => dispatch(untouchAction(FORM_NAME, field)),
  loginSuccessNotification: () => dispatch(showNotification(bankLoginSuccess)),
  addAccountsSuccessNotification: (length) =>
    dispatch(showNotification(length > 1 ? addAccountsSuccess : addAccountSuccess)),
  fetchUserPlan: () => dispatch(fetchUserPlanAction()),
  syncInBackgroundNotification: () => dispatch(showNotification(bankTransfersSyncInBackground)),
  goToBankTransfers: () => {
    dispatch(push(paths.bankTransfers));
  },
  fetchPaymentsBankAccounts: () => dispatch(fetchPaymentsBankAccountsAction()),
  showErrorNotification: (...args) =>
    dispatch(
      showErrorNotificationAction(...args, {
        CustomMessage: FigoErrorMessage,
        duration: ERROR_NOTIFICATION_DURATION,
      })
    ),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('isModalOpen', 'setIsModalOpen', false)
);

export default enhance(BankAccountsCreator);
