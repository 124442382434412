import React, { FunctionComponent } from 'react';

import {
  ACTION_CREATE_NEW_INVOICE_POSITIONS_ADD_POSITION,
  CATEGORY_PRODUCT_CATALOG,
} from 'constants/piwik';
import { t } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';
import { piwikHelpers } from 'shared/utils/piwik';
import { LineItemsAutoSaveConsumer, SaveFunction } from 'components/LineItems';

import styles from './AddLineItemButton.module.css';

interface AddLineItemButtonProps {
  onClick: SaveFunction;
  disabled?: boolean;
}

const trackAddPosition = () =>
  piwikHelpers.trackEvent(
    CATEGORY_PRODUCT_CATALOG,
    ACTION_CREATE_NEW_INVOICE_POSITIONS_ADD_POSITION
  );

export const BUTTON_ADD_ITEM_DATA_ID = 'LineItem:button-add-item';

export const AddLineItemButton: FunctionComponent<AddLineItemButtonProps> = ({
  onClick,
  disabled,
}: AddLineItemButtonProps) => (
  <LineItemsAutoSaveConsumer>
    {({ handleSave }) => (
      <button
        className={styles.addItem}
        onClick={() => {
          onClick();
          trackAddPosition();
        }}
        onKeyPress={isPressedEnter(handleSave(onClick))}
        data-id={BUTTON_ADD_ITEM_DATA_ID}
        type="button"
        disabled={disabled}
      >
        {t('forms.line_items.actions.add_item_row')}
      </button>
    )}
  </LineItemsAutoSaveConsumer>
);
