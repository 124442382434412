import React from 'react';

import { t } from 'shared/utils';

import styles from './LoadMoreMessages.module.css';

const LoadMoreMessages = (props) => (
  <div className={styles.container}>
    <button {...props} className={styles.button}>
      {t('sidebar.messages.load_more')}
    </button>
  </div>
);

export default LoadMoreMessages;
