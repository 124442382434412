import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { compose, lifecycle } from 'recompose';
import { reduxForm } from 'redux-form';

import { createSupplier, fetchSupplier, updateSupplier } from 'actions/suppliers';
import { DE_ABORT, DE_SUPPLIER_MANAGEMENT } from 'constants/suppliers';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { piwikHelpers } from 'shared/utils/piwik';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import I18n from 'components/I18n';

import AbortButton from './AbortButton';
import CreateSupplierSection from './CreateSupplierSection';

import styles from './SupplierCreator.module.css';

const SupplierCreator = ({ handleSubmit, id, submitting, submitForm, updateForm, isReadonly }) => (
  <form onSubmit={handleSubmit((data) => (id ? updateForm(id, data) : submitForm(data)))}>
    <div className={cx(styles.section, styles.creator)}>
      <CreateSupplierSection isEditing={!!id} readonly={isReadonly} />
    </div>
    {!isReadonly && (
      <ActionPanel className={styles.creatorActionsSection}>
        <ActionButton
          type="submit"
          disabled={submitting}
          label={<I18n t={`suppliers.form.submit${id ? 'Edit' : ''}`} />}
          data-id="button-save"
        />
        <AbortButton trackAbort={() => piwikHelpers.trackEvent(DE_SUPPLIER_MANAGEMENT, DE_ABORT)} />
      </ActionPanel>
    )}
  </form>
);

SupplierCreator.propTypes = {
  id: PropTypes.number,
  submitForm: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  isReadonly: PropTypes.bool,
};

SupplierCreator.defaultProps = {
  id: null,
};

const reduxFormEnhancer = reduxForm({
  form: 'supplierCreator',
  enableReinitialize: true,
  persistentSubmitErrors: true,
});

const reduxStateEnhancer = connect(
  (state, ownProps) => ({
    id: ownProps.match.params.id,
    initialValues: state.form.supplierCreator.initial,
  }),
  { submitForm: createSupplier, updateForm: updateSupplier, fetchForm: fetchSupplier }
);

const lifecycleEnhancer = lifecycle({
  componentDidMount() {
    const { fetchForm, id } = this.props;
    if (id) {
      fetchForm(id);
    }
  },
});

const enhance = compose(
  reduxStateEnhancer,
  reduxFormEnhancer,
  lifecycleEnhancer,
  withTransitionPrevent()
);

export default enhance(SupplierCreator);
