import React from 'react';

import styles from '../StatusIcons.module.css';

const SuggestedStatusIcon = () => (
  <svg viewBox="0 0 20 20" className={styles.statusIcon} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g className={styles.fillNeutral} fillRule="nonzero">
        <path d="M16.09 4.832l-1.021-.999-1.297 1.275 1.021 1zM10.694 4.188h-1.45V2.063h1.45zM17.938 10.563h-2.173V9.145h2.172zM9.244 15.485V12.68l-.724-.41A2.827 2.827 0 0 1 7.07 9.819c0-1.566 1.297-2.834 2.898-2.834 1.6 0 2.897 1.268 2.897 2.834 0 1.006-.557 1.94-1.448 2.45l-.725.411V15.485H9.244zm-1.449 1.417h4.347v-3.407c1.297-.737 2.173-2.104 2.173-3.676 0-2.345-1.948-4.25-4.346-4.25s-4.347 1.905-4.347 4.25c0 1.572.877 2.94 2.173 3.676v3.407zM4.174 10.562H2V9.145h2.173zM6.173 5.101L4.876 3.826l-1.021.999 1.304 1.268z" />
      </g>
      <rect className={styles.strokeNeutral} x=".5" y=".5" width="19" height="19" rx="2" />
    </g>
  </svg>
);

export default SuggestedStatusIcon;
