import { put, select, takeLatest } from 'redux-saga/effects';

import { fetchCustomerNotifications } from 'actions/customer-notifications';
import { UPDATE_SUCCESS as BANK_TRANSFER_UPDATED } from 'constants/bank-transfer';
import {
  ACCEPT_SUGGESTION_SUCCESS,
  CREATE_SUCCESS as CREATE_TRANSFER_CONNECTION,
  DELETE_SUCCESS as DELETE_TRANSFER_CONNECTION,
  REJECT_SUGGESTION_SUCCESS,
} from 'constants/bank-transfer-connections';
import {
  CREATE_SUCCESS as CONTRACT_CREATE_SUCCESS,
  UPDATE_SUCCESS as CONTRACT_UPDATE_SUCCESS,
} from 'constants/contracts';
import {
  CREATE_SUCCESS as INCOMING_INVOICE_CREATED,
  DELETE_SUCCESS as INCOMING_INVOICE_DELETED,
  UPDATE_SUCCESS as INCOMING_INVOICE_UPDATED,
} from 'constants/incoming-invoice';
import { TOGGLE_PAID_BY_CASH_SUCCESS as INCOMING_INVOICE_PAID_BY_CASH } from 'constants/incoming-invoices';
import {
  CREATE_SUCCESS as OUTGOING_INVOICE_CREATED,
  DELETE_SUCCESS as OUTGOING_INVOICE_DELETED,
  REMINDER_UPDATE_SUCCESS as REMINDER_UPDATED,
  SEND_SUCCESS as OUTGOING_INVOICE_SENT,
  TOGGLE_PAID_BY_CASH_SUCCESS as OUTGOING_INVOICE_PAID_BY_CASH,
  UPDATE_SUCCESS as OUTGOING_INVOICE_UPDATED,
} from 'constants/outgoing-invoice';
import {
  CREATE_SUCCESS as PROPOSAL_CREATED,
  DELETE_SUCCESS as PROPOSAL_DELETED,
  SEND_SUCCESS as PROPOSAL_SENT,
  TRANSFORM_SUCCESS as PROPOSAL_TRANSFORMED,
  UPDATE_SUCCESS as PROPOSAL_UPDATED,
} from 'constants/proposal';
import { todoListEnabledHelper } from 'routes/accesses';
import { deleteContractSuccess } from 'features/contracts/contractsSlice';

export const customerNotificationsMutatingActions = [
  OUTGOING_INVOICE_CREATED,
  OUTGOING_INVOICE_UPDATED,
  OUTGOING_INVOICE_DELETED,
  OUTGOING_INVOICE_SENT,
  OUTGOING_INVOICE_PAID_BY_CASH,
  REMINDER_UPDATED,
  INCOMING_INVOICE_CREATED,
  INCOMING_INVOICE_UPDATED,
  INCOMING_INVOICE_DELETED,
  INCOMING_INVOICE_PAID_BY_CASH,
  PROPOSAL_CREATED,
  PROPOSAL_UPDATED,
  PROPOSAL_DELETED,
  PROPOSAL_SENT,
  PROPOSAL_TRANSFORMED,
  BANK_TRANSFER_UPDATED,
  ACCEPT_SUGGESTION_SUCCESS,
  REJECT_SUGGESTION_SUCCESS,
  CREATE_TRANSFER_CONNECTION,
  DELETE_TRANSFER_CONNECTION,
  CONTRACT_CREATE_SUCCESS,
  CONTRACT_UPDATE_SUCCESS,
  deleteContractSuccess.toString(), // TODO: add actions for adding / updating contracts
];

function* fetchCustomerNotificationsData() {
  const isTodoListEnabled = yield select(todoListEnabledHelper);

  if (isTodoListEnabled) {
    yield put(fetchCustomerNotifications());
  }
}

export default function* watchCustomerNotificationsDataChange() {
  yield takeLatest(customerNotificationsMutatingActions, fetchCustomerNotificationsData);
}
