import React from 'react';
import { string } from 'prop-types';

import styles from './Header.module.css';

const Header = ({ title, bold, description }) => (
  <div className={styles.container}>
    {title && <span className={styles.normal}>{title}</span>}
    {bold && <span className={styles.bold}>{bold}</span>}
    {description && <span className={styles.normal}>{description}</span>}
  </div>
);
Header.propTypes = {
  title: string,
  bold: string,
  description: string,
};

export default Header;
