import React, { PureComponent } from 'react';
import cx from 'classnames';
import { debounce } from 'lodash';
import { node, string } from 'prop-types';

import styles from './CellContentWithTooltip.module.css';

class CellContentWithTooltip extends PureComponent {
  state = {
    tooltip: false,
  };

  componentDidMount() {
    this._recalculateTooltip();
    window.addEventListener('resize', this.recalculateTooltip);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.recalculateTooltip);
  }

  rootRef = (el) => {
    this.root = el;
  };

  _recalculateTooltip = () => {
    if (this.root.scrollWidth > this.root.clientWidth) {
      this.setState({ tooltip: true });
    } else {
      this.setState({ tooltip: false });
    }
  };

  recalculateTooltip = debounce(this._recalculateTooltip, 200);

  render() {
    const { children, className, dataId } = this.props;
    const { tooltip } = this.state;

    return (
      <div className={cx(styles.cellContent, className)} ref={this.rootRef}>
        <span data-id={dataId}>{children}</span>
        <div className={cx(styles.tooltip, { [styles.tooltipEnabled]: tooltip })}>{children}</div>
      </div>
    );
  }
}

CellContentWithTooltip.propTypes = {
  children: node,
  className: string,
  dataId: string,
};

export default CellContentWithTooltip;
