import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { func, shape, string } from 'prop-types';

import { fetchUserPlan as fetchUserPlanAction } from 'actions/payment-plan';
import { downgradePlan as downgradePlanAction } from 'actions/payment-plan/payment-plan';
import { fetchProfile as fetchProfileAction } from 'actions/profile';
import { CREATE_DOWNGRADE_SUCCESS } from 'constants/payment-plan';
import { getBasicPaymentPlan } from 'selectors/payment-plans';
import { t } from 'shared/utils';

import Buttons from '../components/Buttons/Buttons';
import Header from '../components/Header/Header';
import MissingFeatures from './components/MissingFeatures/MissingFeatures';

class SwitchToBasic extends Component {
  state = {
    isLoading: false,
  };

  handleDowngrade = () => {
    this.setState({ isLoading: true });
    const {
      downgradePlan,
      confirmDowngrade,
      fetchUserPlan,
      fetchProfile,
      basicPaymentPlan: { id },
    } = this.props;

    downgradePlan(id).then((response) => {
      if (response.type === CREATE_DOWNGRADE_SUCCESS) {
        fetchUserPlan();
        fetchProfile();
        confirmDowngrade();
      }
      this.setState({ isLoading: false });
    });
  };

  render() {
    const { onClose } = this.props;
    const { isLoading } = this.state;

    return (
      <Fragment>
        <Header
          bold={t('profile.payment_plan.switch_to_basic.header_1')}
          description={t('profile.payment_plan.switch_to_basic.header_2')}
        />
        <MissingFeatures />
        <Buttons
          backLabel={t('profile.payment_plan.switch_to_basic.button_back')}
          forwardLabel={t('profile.payment_plan.switch_to_basic.button_forward')}
          onClose={onClose}
          onSubmit={this.handleDowngrade}
          disableForward={isLoading}
          dataIds={{
            backButton: 'back-button',
            forwardButton: 'PaymentPlans:switchToBasicSubmit',
          }}
        />
      </Fragment>
    );
  }
}

SwitchToBasic.propTypes = {
  onClose: func.isRequired,
  downgradePlan: func.isRequired,
  confirmDowngrade: func.isRequired,
  fetchUserPlan: func.isRequired,
  fetchProfile: func.isRequired,
  basicPaymentPlan: shape({
    id: string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  basicPaymentPlan: getBasicPaymentPlan(state),
});

const mapDispatchToProps = (dispatch) => ({
  downgradePlan: (id) => dispatch(downgradePlanAction(id)),
  fetchUserPlan: () => dispatch(fetchUserPlanAction()),
  fetchProfile: () => dispatch(fetchProfileAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwitchToBasic);
