import React from 'react';
import { bool, number, string } from 'prop-types';

import Amount from 'components/Amount';
import IncomingInvoiceAmount from 'components/Table/IncomingInvoiceAmount';

const InvoiceAmount = ({ isIncomingInvoice, totalGrossAmount, currency, creditNote }) =>
  isIncomingInvoice ? (
    <IncomingInvoiceAmount amount={totalGrossAmount} currency={currency} creditNote={creditNote} />
  ) : (
    <Amount currency={currency} amount={totalGrossAmount} />
  );

InvoiceAmount.propTypes = {
  isIncomingInvoice: bool,
  totalGrossAmount: number,
  currency: string,
  creditNote: bool,
};

export default InvoiceAmount;
