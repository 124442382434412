import React from 'react';

import { formatMoney } from 'shared/utils';

import { Number } from './Amount.styled';

type AmountProps = {
  className?: string;
  amount: number;
};

const Amount = ({ className, amount, ...rest }: AmountProps) => {
  return (
    <Number $isNegative={amount < 0} className={className} {...rest}>
      {formatMoney(amount)}
    </Number>
  );
};

export default Amount;
