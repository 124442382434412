import React from 'react';
import { bool, func, instanceOf } from 'prop-types';

import { t } from 'shared/utils';
import Button from 'components/Button';
import I18n from 'components/I18n';

import styles from './FileUploadSection.module.css';

export const FileUploadSection = ({ file, onFileChange, uploadAction, isUploading = false }) => (
  <div className={styles.uploadSection}>
    <div className={styles.upload}>
      <input
        type="file"
        className={styles.hiddenField}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={(e) => {
          onFileChange(e);
          e.target.value = null; // we have to reset inputs value after onChange, to allow user upload same file again
        }}
        id="file"
      />
      <label htmlFor="file" className={styles.fileUploadButton}>
        {file ? <span>{file.name}</span> : <I18n t="contacts.import.button" />}
      </label>
    </div>
    <Button
      onClick={uploadAction}
      centered
      label={t(`contacts.import.${isUploading ? 'upload_busy' : 'upload'}`)}
      disabled={!file || isUploading}
    />
  </div>
);

FileUploadSection.propTypes = {
  file: instanceOf(File),
  isUploading: bool,
  onFileChange: func.isRequired,
  uploadAction: func.isRequired,
};

export default FileUploadSection;
