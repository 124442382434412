import React, { memo } from 'react';
import { useHistory } from 'react-router';

import {
  CLIENT_CREATE_NEW_CLIENT,
  EXPENSES_CREATE_NEW_INCOVICE,
  REVENUE_CREATE_NEW_INVOICE,
} from 'constants/piwik';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import Card from 'redesign/components/atoms/Card/Card';
import Contacts from 'redesign/components/atoms/Icons/Contacts';
import Edit from 'redesign/components/atoms/Icons/Edit';
import Inbox from 'redesign/components/atoms/Icons/Inbox';

import { Container, Title, Wrapper } from './Quicklinks.styled';
import { trackEvent } from './utils';

const Quicklinks = () => {
  const history = useHistory();

  const handleClick = (path: string, trackingInfo: string) => {
    trackEvent(trackingInfo);
    history.push(path);
  };

  return (
    <Wrapper>
      <Title>{t('features.homepage.quicklinks.frequently_used')}</Title>
      <Container>
        <Card
          color="blue"
          title={t('features.homepage.quicklinks.create_invoice.title')}
          icon={<Edit />}
          onClick={() => handleClick(paths.newOutgoingInvoice, REVENUE_CREATE_NEW_INVOICE)}
        />
        <Card
          color="orange"
          title={t('features.homepage.quicklinks.import_invoice.title')}
          icon={<Inbox />}
          onClick={() => handleClick(paths.newIncomingInvoice, EXPENSES_CREATE_NEW_INCOVICE)}
        />
        <Card
          color="darkblue"
          title={t('features.homepage.quicklinks.create_client.title')}
          icon={<Contacts />}
          onClick={() => handleClick(paths.clientsNew, CLIENT_CREATE_NEW_CLIENT)}
        />
      </Container>
    </Wrapper>
  );
};

export default memo(Quicklinks);
