import React from 'react';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';

import { formatIBAN, t } from 'shared/utils';
import { getDirectDebitAccount } from 'shared/utils/direct-debit-mandate';
import DownshiftSelect from 'components/Form/SelectField/DownshiftSelect';

import styles from './BankAccountSelectField.module.css';

const BankAccountSelectField = ({
  addBankAccount,
  bankAccounts,
  onSelect,
  hideTrailingContent,
  debitAccountIban,
  label,
  ...props
}) => {
  const options = bankAccounts.map(({ name, iban, bankName, id, ...rest }) => ({
    ...rest,
    name,
    iban,
    bankName,
    id,
    label: `${formatIBAN(iban)} (${name})`,
    value: id,
  }));

  const initialSelectedItem = getDirectDebitAccount(options, debitAccountIban);
  const trailingContent = hideTrailingContent
    ? () => {}
    : () => (
        <button type="button" className={styles.button} onClick={addBankAccount}>
          {t('profile.payment_plan.book_premium.add_new_account')}
        </button>
      );

  return (
    <DownshiftSelect
      {...props}
      label={label || t('profile.payment_plan.book_premium.select_label')}
      defaultIcon
      menuClassName={styles.menu}
      onSelect={onSelect}
      options={options}
      initialSelectedItem={initialSelectedItem}
      getTrailingContent={trailingContent}
      isReinitializedOnInitialChange
    />
  );
};

BankAccountSelectField.propTypes = {
  onSelect: func.isRequired,
  hideTrailingContent: bool,
  debitAccountIban: string,
  label: string,
  bankAccounts: arrayOf(
    shape({
      name: string,
      iban: string,
      bankName: string,
      id: oneOfType([number, string]),
    })
  ),
  addBankAccount: func,
};

export default BankAccountSelectField;
