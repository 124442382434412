import React from 'react';
import cx from 'classnames';
import { bool, number, oneOfType, string } from 'prop-types';

import { formatMoney, t } from 'shared/utils';
import amountStyles from 'components/BankTransfer/BankTransfer.module.css';

import localStyles from './Assigment.module.css';

const UnassignedAmount = ({ amount, forceMinusSign = false }) => (
  <div className={localStyles.container}>
    <span className={localStyles.label}>{t('bank_transfers.table.unassigned_amount')}</span>
    <div
      className={cx(amountStyles.amount, {
        [amountStyles.amountNegative]: forceMinusSign || amount < 0,
      })}
      data-id="unassigned-amount"
    >
      {formatMoney(amount)}
    </div>
  </div>
);

UnassignedAmount.propTypes = {
  amount: oneOfType([number, string]).isRequired,
  forceMinusSign: bool,
};

export default UnassignedAmount;
