import React, { PureComponent } from 'react';
import { isInclusivelyBeforeDay } from 'react-dates';
import { connect } from 'react-redux';
import cx from 'classnames';
import isNull from 'lodash/isNull';
import moment from 'moment';
import { arrayOf, bool, func, string } from 'prop-types';
import { change, Field } from 'redux-form';

import { getValidation } from 'actions/datev';
import { DATE_FORMAT } from 'constants/datetime';
import { t } from 'shared/utils';
import { isSmUp, isXsmUp } from 'shared/utils/breakpoints';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import { DateRangePickerId } from 'store/slices/dateRangePicker/types';
import InfoTextField from 'components/Form/InfoTextField';
import PasswordField from 'components/Form/PasswordField';
import { DefaultRangePresets } from 'redesign/components/molecules/DatePresets/types';
import DateRangePicker from 'redesign/components/organisms/DateRangePicker/DateRangePicker';
import { DateRangePickerVersion } from 'redesign/components/organisms/DateRangePicker/types';

import ActionButton from './ActionButton';

import styles from './SettingsSection.module.css';

class SettingsSection extends PureComponent {
  constructor(props) {
    super(props);
    const { startDate, endDate, initialStartDate, initialEndDate } = props;

    this.state = {
      startDate: startDate ? moment(startDate, DATE_FORMAT) : moment(initialStartDate, DATE_FORMAT),
      endDate: endDate ? moment(endDate, DATE_FORMAT) : moment(initialEndDate, DATE_FORMAT),
    };
  }

  updateLocalDateRange = ({ startDate, endDate }) => {
    // Select one day if end date is null
    const rangeEndDate = isNull(endDate) ? undefined : endDate;

    this.setState({ startDate, endDate: rangeEndDate });
  };

  setNewDateRange = (dateRange) => {
    const { setDateRange, readonly = [] } = this.props;
    const isValid = dateRange?.startDate && dateRange?.endDate;

    if (!readonly.includes('dateRange') && isValid) setDateRange(dateRange);
  };

  setRangeQueryAction = () => (dateRange) => this.updateLocalDateRange(dateRange);

  render() {
    const { disabled, label, handleExport, isDateRangeValid, isEditMode } = this.props;
    const { startDate, endDate } = this.state;
    const period = {
      startDate: startDate ? moment(startDate, DATE_FORMAT) : undefined,
      endDate: endDate ? moment(endDate, DATE_FORMAT) : undefined,
    };
    const numberOfMonths = isSmUp() ? 2 : 1;
    const marginForXSmallDevices = isXsmUp() ? undefined : 12;

    return (
      <div className={styles.main}>
        <div className={cx(styles.columns, styles.columnsPadded)}>
          <div className={styles.column}>
            <DateRangePicker
              label={t('datev.creator.labels.period')}
              name="period"
              variant={DateRangePickerVersion.legacy}
              presets={[
                DefaultRangePresets.lastMonth,
                DefaultRangePresets.lastQuarter,
                DefaultRangePresets.lastYear,
              ]}
              horizontalMargin={marginForXSmallDevices}
              numberOfMonths={numberOfMonths}
              startDateId={'DatevCreator:input-datepicker-start-date'}
              endDateId={'DatevCreator:input-datepicker-end-date'}
              onClose={this.setNewDateRange}
              setRangeQueryAction={this.setRangeQueryAction}
              isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
              customDateRanges={this.presets}
              isFutureDateDisabled
              period={period}
              id={DateRangePickerId.datevExport}
              disabled={isEditMode}
              showClearDates={!isEditMode}
            />
          </div>

          <div className={styles.column}>
            <div className={styles.formGroup}>
              <Field
                id="email"
                name="email"
                checker={checker}
                component={InfoTextField}
                label={t('datev.creator.fields.email')}
                message={t('datev.creator.messages.email')}
                dataId="Datev:input-email"
                required
              />
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.formGroup}>
              <Field
                id="password"
                name="password"
                position="right"
                checker={checker}
                component={PasswordField}
                label={t('datev.creator.fields.password')}
                infoMessage={t('datev.creator.messages.password_criteria')}
                required
                autoComplete="new-password"
                dataId="Datev:input-password"
              />
            </div>
          </div>
          <div className={styles.column}>
            <ActionButton
              onClick={handleExport}
              disabled={disabled || !isDateRangeValid}
              label={label}
              dataId="Datev:button-send-datev"
            />
          </div>
        </div>
      </div>
    );
  }
}

SettingsSection.propTypes = {
  disabled: bool,
  label: string,
  readonly: arrayOf(string),
  setDateRange: func.isRequired,
  handleExport: func.isRequired,
  startDate: string,
  endDate: string,
  initialStartDate: string,
  initialEndDate: string,
  isEditMode: bool,
};

SettingsSection.defaultProps = {
  readonly: [],
  isEditMode: false,
};

export default connect(
  (state) => ({
    startDate: state.form.DatevCreator.values && state.form.DatevCreator.values.startDate,
    endDate: state.form.DatevCreator.values && state.form.DatevCreator.values.endDate,
    initialStartDate: state.form.DatevCreator.initial.startDate,
    initialEndDate: state.form.DatevCreator.initial.endDate,
    nextReportStartDate: state.datev.dates.nextReportStartDate,
    isDateRangeValid: state.dateRangePicker.find(
      (item) => item.id === DateRangePickerId.datevExport
    )?.isValid,
  }),
  (dispatch) => ({
    setDateRange: ({ startDate, endDate }) => {
      const startDateFormatted = startDate ? startDate.format(DATE_FORMAT) : undefined;
      const endDateFormatted = endDate ? endDate.format(DATE_FORMAT) : undefined;

      if (startDate) dispatch(change('DatevCreator', 'startDate', startDateFormatted));
      if (endDate) dispatch(change('DatevCreator', 'endDate', endDateFormatted));
      dispatch(getValidation(startDateFormatted, endDateFormatted));
    },
  })
)(SettingsSection);
