import React, { FunctionComponent } from 'react';
import cx from 'classnames';

import { MODAL_HEADER_VARIANT } from 'constants/modal';
import { noop, t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import { ButtonAppearances } from 'components/Button';
import Modal, { ModalHeader } from 'components/Modal';

import styles from './AddProductModal.module.css';

interface AddProductModalProps {
  isOpen: boolean;
  header: string;
  modalAction: string;
  onClose: () => void;
  createNewHandler: () => void;
  editHandler: () => void;
}

export const AddProductModal: FunctionComponent<AddProductModalProps> = ({
  isOpen,
  onClose = noop,
  children,
  modalAction,
  header = '',
  createNewHandler = noop,
  editHandler = noop,
}) => {
  return (
    <Modal
      data-id="AddProductModal:modal"
      isOpen={isOpen}
      onRequestClose={onClose}
      className={cx(styles.confirmationModal)}
      contentLabel="Modal"
    >
      <ModalHeader headerVariant={MODAL_HEADER_VARIANT.SMALL} onClose={onClose} withCloseButton>
        {header}
      </ModalHeader>
      <div className={styles.confirmationInner}>
        <div className={styles.confirmationMessage}>{children}</div>
        <div className={styles.confirmationButtons}>
          {modalAction === 'create_or_update' && (
            <ActionButton
              appearance={ButtonAppearances.primary}
              onClick={editHandler}
              dataId="AddProductModal:update-button"
              label={t('modals.add_product.update')}
            />
          )}
          <ActionButton
            appearance={ButtonAppearances.outlined}
            onClick={createNewHandler}
            label={t('modals.add_product.create_new')}
            dataId="AddProductModal:create-new-button"
          />
        </div>
      </div>
    </Modal>
  );
};
