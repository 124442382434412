import React, { PureComponent } from 'react';
import cx from 'classnames';
import { camelCase, isEqual } from 'lodash';
import { bool, func, instanceOf, shape, string } from 'prop-types';

import { CLIENT_FIELDS, MODELS, SUPPLIER_FIELDS } from 'constants/contacts';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { isLgUp } from 'shared/utils/breakpoints';
import Button, { ButtonAppearances } from 'components/Button';
import CardView, { HeadingSection, Section } from 'components/CardView';
import I18n from 'components/I18n';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import LoadingIcon from 'components/LoadingIcon';
import { Pagination } from 'components/Pagination/Pagination';
import {
  Body as TableBody,
  Cell,
  Header as TableHeader,
  Table,
  TableHeader as HeaderCell,
  TableRow,
} from 'components/Table';
import { TooltipPlacements } from 'redesign/components/molecules/Tooltip/Tooltip';

import { FileUploadSection } from './components/FileUploadSection';

import styles from './ImportContacts.module.css';

const pathsMap = {
  [MODELS.CLIENT]: paths.clients,
  [MODELS.SUPPLIER]: paths.suppliers,
};

const SyncInProgress = () => (
  <div className={styles.syncInProgress}>
    <LoadingIcon staticPosition />
    <I18n t="contacts.import.sync_in_progress" />
  </div>
);

const InstructionsStep1 = () => (
  <ol className={styles.list}>
    <li>
      <I18n t="contacts.import.instructions.step_1.a" />
      <a href="/Kontakte_Vorlage.xlsx" target="_blank">
        {' '}
        <I18n t="contacts.import.template_link" />
      </a>
    </li>
    <li>
      <I18n t="contacts.import.instructions.step_1.b" />
    </li>
  </ol>
);

const InstructionsStep2 = () => (
  <div className={styles.instructions}>
    <I18n t="contacts.import.instructions.step_2" className={styles.instructionsSpan} />
  </div>
);

const converErrorsArrayToString = (errors = []) => (errors || []).join('\n');

export class ImportContacts extends PureComponent {
  state = {
    entries: {
      data: [],
      isFetching: false,
    },
    pagination: {
      page: 1,
      totalPages: 1,
      perPage: 10,
    },
  };

  static propTypes = {
    file: instanceOf(File),
    onFileChange: func,
    createImport: func,
    isUploading: bool,
    syncInProgress: bool,
    importId: string,
    importedModel: string,
    actions: shape({
      transformEntries: func,
      cancelImport: func,
    }),
    push: func,
    setSyncInProgress: func,
    setImportId: func,
  };

  get fields() {
    const { importedModel } = this.props;

    if (importedModel === MODELS.CLIENT) {
      return CLIENT_FIELDS.map(camelCase);
    }
    return SUPPLIER_FIELDS.map(camelCase);
  }

  get headers() {
    const { importedModel } = this.props;

    if (importedModel === MODELS.CLIENT) {
      return CLIENT_FIELDS.map((field) => `form.fields.${field}`);
    }
    return SUPPLIER_FIELDS.map((field) => `form.fields.${field}`);
  }

  setData = (data) =>
    this.setState((prevState) => ({
      entries: {
        ...prevState.entries,
        ...data,
      },
    }));

  paginate = (newPagination) => {
    const { pagination } = this.state;
    const hasChangedPage = !isEqual(pagination, newPagination);
    if (!hasChangedPage) return;
    this.setState(({ pagination: prevPagination, entries: { isFetching } }) =>
      isFetching
        ? {}
        : {
            pagination: {
              ...prevPagination,
              ...newPagination,
            },
          }
    );
  };

  createImportAndStartFetching = async (...args) => {
    const { createImport, setSyncInProgress, setImportId } = this.props;
    this.stopPooling();
    const { data } = await createImport(...args);

    if (!data) return;

    const { id } = data;
    setSyncInProgress(true);
    setImportId(id);
    this.startPooling({ id, isFetchingImportedContacts: true });
  };

  fetchEntries = async ({ id, isFetchingImportedContacts } = {}) => {
    const {
      actions: { fetchEntries, getImports },
      setSyncInProgress,
      importId,
      importedModel,
    } = this.props;
    const { pagination } = this.state;
    this.setData({ isFetching: true });

    const {
      response: { data: importData },
    } = await getImports();
    const [imports] = importData.filter(
      ({ attributes }) => attributes.importedModel === importedModel
    );
    const { syncInProgress } = imports.attributes;
    if (syncInProgress) return;
    const {
      headers,
      response: { data },
    } = await fetchEntries({
      id: id || importId,
      ...(!isFetchingImportedContacts && pagination),
    });
    this.setData({ data, isFetching: false });
    this.paginate({
      page: Number(headers.page),
      totalPages: Number(headers['total-pages']),
    });
    this.stopPooling();
    setSyncInProgress(false);
  };

  startPooling = ({ id, isFetchingImportedContacts }) => {
    this.fetchEntries({ id, isFetchingImportedContacts });
    this.poolImportDataIntervalId = setInterval(() => this.fetchEntries({ id }), 5000);
  };

  stopPooling = () => {
    if (this.poolImportDataIntervalId) {
      clearInterval(this.poolImportDataIntervalId);
    }
  };

  componentDidMount() {
    const { importId } = this.props;
    if (importId) {
      this.startPooling({ importId });
    }
  }

  componentDidUpdate(_, { pagination: prevPagination }) {
    const { pagination } = this.state;
    const isOnWrongPage = pagination.page !== prevPagination.page;
    if (isOnWrongPage) {
      this.fetchEntries();
    }
  }

  componentWillUnmount() {
    this.stopPooling();
  }

  mapErrorsToCamelCase = (errors) =>
    Object.assign({}, ...Object.keys(errors).map((key) => ({ [camelCase(key)]: errors[key] })));

  renderRow =
    (allData = []) =>
    (data, idx) => {
      const isLastRow = idx === allData.length - 1;
      const lgUpTooltipPlacement = isLastRow ? TooltipPlacements.TOP : TooltipPlacements.BOTTOM;
      // TODO use hook when component will be a function
      const tooltipPlacement = isLgUp() ? lgUpTooltipPlacement : TooltipPlacements.LEFT;
      const errors = data.attributes.validationErrors;
      const errorsObject = (errors && this.mapErrorsToCamelCase(errors)) || {};

      return (
        <TableRow key={data.id}>
          {this.fields.map((field) => (
            <Cell
              key={field}
              overflow={errorsObject[field]}
              className={cx({ [styles.invalidCell]: errorsObject[field] })}
            >
              {!!errorsObject[field] && errorsObject[field] && (
                <InfoIcon
                  tooltipPlacement={tooltipPlacement}
                  tether
                  className={styles.errorIcon}
                  text={converErrorsArrayToString(errorsObject[field])}
                />
              )}
              {data.attributes[field]}
            </Cell>
          ))}
        </TableRow>
      );
    };

  onTransform = async () => {
    const {
      actions: { transformEntries },
      importId,
    } = this.props;
    const result = await transformEntries({ id: importId });

    this.props.push(this.redirectionPath);
    return result;
  };

  onCancel = async () => {
    const {
      actions: { cancelImport },
      importId,
    } = this.props;
    const result = await (importId ? cancelImport({ id: importId }) : Promise.resolve());

    this.props.push(this.redirectionPath);
    return result;
  };

  get redirectionPath() {
    const { importedModel } = this.props;
    return pathsMap[importedModel];
  }

  renderComponent() {
    const { syncInProgress, file, isUploading, onFileChange } = this.props;
    const { entries, pagination } = this.state;
    const hasData = Boolean(entries.data.length);

    if (syncInProgress) return <SyncInProgress />;

    if (hasData || entries.isFetching) {
      return (
        <React.Fragment>
          <InstructionsStep2 />
          <Table
            isFetching={entries.isFetching}
            fetch={() => {}}
            isEmpty={!hasData}
            theme={{
              tableContainer: styles.container,
            }}
            params={{
              pagination: this.state.pagination,
            }}
            paginationComponent={
              <Pagination {...pagination} request={this.paginate} isFetching={entries.isFetching} />
            }
          >
            <TableHeader>
              <TableRow>
                {this.headers.map((field, index) => (
                  <HeaderCell columnWidthClass={styles.column} key={index}>
                    <I18n t={field} />
                  </HeaderCell>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>{entries.data.map(this.renderRow(entries.data))}</TableBody>
          </Table>
          <FileUploadSection
            file={file}
            isUploading={isUploading}
            onFileChange={onFileChange}
            uploadAction={this.createImportAndStartFetching}
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <InstructionsStep1 />
        <FileUploadSection
          file={file}
          isUploading={isUploading}
          onFileChange={onFileChange}
          uploadAction={this.createImportAndStartFetching}
        />
      </React.Fragment>
    );
  }

  render() {
    const { entries } = this.state;
    const hasData = Boolean(entries.data.length);

    return (
      <div className={styles.root}>
        <CardView className={styles.card}>
          <HeadingSection bold>
            <I18n t="contacts.import.heading" />
          </HeadingSection>
          <Section>
            <div>{this.renderComponent()}</div>
          </Section>
        </CardView>
        <div className={styles.buttonGroup}>
          <Button
            label={t('contacts.import.cancel_import')}
            centered
            appearance={ButtonAppearances.outlined}
            onClick={this.onCancel}
            dataId="Contacts:button-abort"
          />
          <Button
            label={t('contacts.import.transform_entries')}
            centered
            disabled={!hasData || entries.isFetching}
            onClick={this.onTransform}
            dataId="Contacts:button-submit"
          />
        </div>
      </div>
    );
  }
}

export default ImportContacts;
