export const FETCH_REQUEST = 'NUMBER_RANGES/FETCH_REQUEST';
export const FETCH_SUCCESS = 'NUMBER_RANGES/FETCH_SUCCESS';
export const FETCH_FAILURE = 'NUMBER_RANGES/FETCH_FAILURE';
export const UPDATE_REQUEST = 'NUMBER_RANGES/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'NUMBER_RANGES/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'NUMBER_RANGES/UPDATE_FAILURE';
export const VALIDATE_REQUEST = 'NUMBER_RANGES/VALIDATE_REQUEST';
export const VALIDATE_SUCCESS = 'NUMBER_RANGES/VALIDATE_SUCCESS';
export const VALIDATE_FAILURE = 'NUMBER_RANGES/VALIDATE_FAILURE';
export const SET_CURSOR_POSITION = 'NUMBER_RANGES/SET_CURSOR_POSITION';
