import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { DE_CLIENT_CREATION_ATTEMPT, DE_CLIENT_MANAGEMENT } from 'constants/clients';
import { buttons } from 'constants/contacts';
import { DE_SUPPLIER_CREATION_ATTEMPT, DE_SUPPLIER_MANAGEMENT } from 'constants/suppliers';
import { contactsImportHelper } from 'routes/accesses';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import { NewItemLink } from 'components/Table';

const trackResourceCreation = (whichFolder: string) => {
  if (whichFolder === 'supplier') {
    piwikHelpers.trackEvent(DE_SUPPLIER_MANAGEMENT, DE_SUPPLIER_CREATION_ATTEMPT);
  } else {
    piwikHelpers.trackEvent(DE_CLIENT_MANAGEMENT, DE_CLIENT_CREATION_ATTEMPT);
  }
};

interface TableButtonsStateProps {
  isContactImportEnabled: boolean;
}

type WhichFolder = 'client' | 'supplier';

interface TableButtonsPassProps {
  whichFolder: WhichFolder;
}

interface TableButtonsProps extends TableButtonsStateProps, TableButtonsPassProps {}

const getDataIdPrefix = (whichFolder: WhichFolder) => {
  switch (whichFolder) {
    case 'client':
      return 'ClientsIndexPage';
    case 'supplier':
      return 'SuppliersIndexPage';
    default:
      return '';
  }
};

const TableButtons: FunctionComponent<TableButtonsProps> = ({
  isContactImportEnabled,
  whichFolder,
}: TableButtonsProps) => (
  <>
    {isContactImportEnabled && (
      <NewItemLink
        caption={t(buttons[whichFolder].importCaption)}
        path={buttons[whichFolder].importPath}
        dataId={`${getDataIdPrefix(whichFolder)}:button-import`}
      />
    )}
    <NewItemLink
      caption={t(buttons[whichFolder].newCaption)}
      path={buttons[whichFolder].newPath}
      handleClick={() => trackResourceCreation(whichFolder)}
      dataId={`${getDataIdPrefix(whichFolder)}:button-addNew`}
    />
  </>
);

const mapStateToProps = (state: any) => ({
  isContactImportEnabled: contactsImportHelper(state),
});

export default connect<TableButtonsStateProps, {}, TableButtonsPassProps>(mapStateToProps)(
  TableButtons
);
