import normalize from 'json-api-normalizer';
import { get } from 'lodash';
import moment from 'moment';
import { combineReducers } from 'redux';
import { actionTypes } from 'redux-form';
import build from 'redux-object';

import { CREATE_REQUEST, FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/proposal';

import lineCategories from './line-categories';
import lineItems from './line-items';
import sums from './sums';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
    case CREATE_REQUEST:
      return true;
    case FETCH_SUCCESS:
    case FETCH_FAILURE:
      return false;
    default:
      return state;
  }
};

const isFetched = (state = false, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return true;
    case FETCH_REQUEST:
    case FETCH_FAILURE:
      return false;
    default:
      return state;
  }
};

const mapProposal = (response) => {
  const { data: proposal } = response;
  const normalizedProposal = build(normalize(response), 'proposals', proposal.id);

  return {
    ...proposal.attributes,
    id: proposal.id,
    proposalDate: proposal.attributes.proposalDate || moment().format('DD.MM.YYYY'),
    client: normalizedProposal && normalizedProposal.client,
  };
};

const details = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return mapProposal(action.response);
    default:
      return state;
  }
};

export const meta = (state = null, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return get(action, 'response.data.meta', null);
    default:
      return state;
  }
};

const proposal = combineReducers({
  isFetching,
  isFetched,
  details,
  lineCategories,
  lineItems,
  meta,
  sums,
});

export default (state, action) => {
  if (action.type === actionTypes.DESTROY && action.meta.form.includes('proposalCreator')) {
    /* Clean all state on quit */
    return proposal({}, action);
  }

  return proposal(state, action);
};
