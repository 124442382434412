import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { compose } from 'recompose';
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmitHandler,
  WrappedFieldMetaProps,
} from 'redux-form';

import { login as loginAction } from 'actions/auth';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { existChecker } from 'shared/utils/form-checking';
import { Dispatch } from 'types/actions';
import {
  FieldNotification,
  FieldsGroup,
  PasswordField,
  SubmitButton,
  TextField,
} from 'components/Form';

import { validateLoginForm } from './LoginForm.utils';

import styles from './LoginForm.module.css';

const checker = ({ meta }: { meta: WrappedFieldMetaProps }) => existChecker(meta);

export interface LoginFormData {
  email: string;
  password: string;
}

interface LoginFormDispatchProps {
  login: () => void;
}

interface LoginFormStateProps {
  loginFailed: boolean;
  submitting: boolean;
  accountLocked: boolean;
  accountUnlocked: boolean;
  lastAttempt: boolean;
}

interface LoginFormPassProps {
  handleSubmit?: (callback: () => Dispatch) => SubmitHandler;
  query: string;
}

type LoginFormProps = LoginFormDispatchProps & LoginFormStateProps & LoginFormPassProps;

const LoginForm: FunctionComponent<LoginFormProps & InjectedFormProps<{}, LoginFormProps>> = ({
  handleSubmit,
  login,
  loginFailed,
  submitting,
  accountLocked,
  accountUnlocked,
  lastAttempt,
}) => (
  <form className={styles.main} onSubmit={handleSubmit(login)}>
    <FieldsGroup className={styles.fieldsGroup}>
      <Field
        checker={checker}
        component={TextField}
        name="email"
        type="email"
        dataId="input-email"
        label={`${t('login.form.fields.email')} *`}
        placeholder={`${t('login.form.fields.email')} *`}
        isAutofillDisabled={false}
        autoFocus
      />
      <Field
        className={styles.withMargin}
        checker={checker}
        component={PasswordField}
        name="password"
        dataId="input-password"
        label={`${t('login.form.fields.password')} *`}
        placeholder={`${t('login.form.fields.password')} *`}
        type="password"
        isAutofillDisabled={false}
      />
    </FieldsGroup>
    <FieldNotification
      show={accountUnlocked && !loginFailed}
      message={t('login.form.notifications.account_unlocked')}
      variant="success"
    />
    <FieldNotification
      show={loginFailed && lastAttempt && !accountLocked}
      message={t('login.form.errors.last_attempt')}
      variant="warning"
    />
    <FieldNotification
      show={loginFailed && accountLocked}
      message={t('login.form.errors.account_locked')}
      variant="warning"
    />
    <FieldNotification
      show={loginFailed && !accountLocked}
      message={t('login.form.errors.fields_incorrect')}
      variant="warning"
    />

    <FieldsGroup className={cx(styles.fieldsGroup, styles.fieldsGroupSubmit)}>
      <SubmitButton
        active={!submitting}
        className={styles.submitButton}
        dataId="button-submit"
        value={t('login.form.fields.submit')}
        large
      />
    </FieldsGroup>

    <div className={styles.linksContainer}>
      <Link className={styles.link} to={paths.companySelectRegistration} data-id="link-register">
        {t('login.form.links.new_account')}
      </Link>
      <Link className={styles.link} to={paths.forgotPassword} data-id="link-forgot-password">
        {t('login.form.links.forgot_password')}
      </Link>
    </div>
  </form>
);

const mapStateToProps = (state: any, ownProps: LoginFormPassProps): LoginFormStateProps => ({
  loginFailed: state.auth.error,
  submitting: state.auth.isFetching,
  accountLocked: state.auth.accountLocked,
  accountUnlocked: Object.keys(ownProps.query).includes('account-unlocked'),
  lastAttempt: state.auth.lastAttempt,
});

const mapDispatchToProps = {
  login: loginAction,
};

export default compose<LoginFormProps & InjectedFormProps<{}, LoginFormProps>, {}>(
  connect<LoginFormStateProps, LoginFormDispatchProps, LoginFormPassProps>(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm<{}, LoginFormProps>({
    form: 'login',
    validate: validateLoginForm,
  })
)(LoginForm);
