import { CALL_API } from 'constants/api';
import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/payment-plan';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apifetchUserPlan = () => ({
  [CALL_API]: {
    endpoint: '/me/payment_plans/current_payment_plan',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const fetchUserPlan = () => (dispatch) =>
  dispatch(apifetchUserPlan()).catch(apiErrorHandler);
