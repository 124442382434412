import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { get, isBoolean, isEmpty, snakeCase, toString } from 'lodash';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { clearAssignedBankTransfers as clearAssignedBankTransfersAction } from 'actions/incoming-invoice';
import {
  createProductCatalogItem as createProductCatalogItemAction,
  fetchProductCatalogItem,
  updateProductCatalogItem as updateProductCatalogItemAction,
} from 'actions/product-catalog/items/items';
import {
  CATEGORY_PRODUCT_CATALOG,
  CREATE_NEW_INVOICE_POSITIONS_DELETE_POSITION,
  CREATE_NEW_INVOICE_POSITIONS_SAVE_POSITION,
  CREATE_NEW_INVOICE_POSITIONS_SEARCH_HELPICON,
  CREATE_NEW_INVOICE_POSITIONS_SEARCH_POSITION,
  CREATE_NEW_INVOICE_POSITIONS_SEARCH_POSITION_SELECT,
} from 'constants/piwik';
import { TYPE_EXPENSES, TYPE_REVENUE } from 'constants/row-types';
import { InvoiceStatuses } from 'constants/statuses';
import { incomingInvoiceSelector } from 'reducers/form';
import lineItemStyles from 'shared/styles/line-items.module.css';
import tableStyles from 'shared/styles/table.module.css';
import {
  formatAmountWithHistory,
  l,
  noop,
  normalizeCurrency,
  parseCurrency,
  t,
} from 'shared/utils';
import { isXlgUp } from 'shared/utils/breakpoints';
import isMobile from 'shared/utils/is-mobile';
import isPressedEnter from 'shared/utils/keyboard-events';
import { ensureNumericMoneyValue, toGrossValue, toNetValue } from 'shared/utils/money';
import { piwikHelpers } from 'shared/utils/piwik';
import { BUTTON_ADD_ITEM_DATA_ID } from 'components/AddLineItemButton/AddLineItemButton';
import CategorySearch from 'components/CategorySearch/CategorySearch';
import If from 'components/Conditions/If';
import { SelectField } from 'components/Form';
import TextField from 'components/Form/TextField';
import I18n from 'components/I18n';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'components/LineItems';
import AddProductButton from 'components/LineItems/components/AddProductButton/AddProductButton';
import { LineItemContainer } from 'components/LineItems/components/LineItemContainer/LineItemContainer';
import { TableCell } from 'components/LineItems/components/TableCell/TableCell';
import { TableSubRow } from 'components/LineItems/components/TableSubRow/TableSubRow';
import { TextInputWithCounter } from 'components/LineItems/components/TextInputWithCounter/TextInputWithCounter';
import LoadingIcon from 'components/LoadingIcon';
import { ConfirmationModal } from 'components/Modal';
import ItemNumberSelect from 'components/Selects/ItemNumberSelect/ItemNumberSelect';
import PositionSelectControl from 'components/Selects/LineItemPositionSelect';
import UnitSelect from 'components/Selects/UnitSelect/UnitSelect';

import localStyles from './LineItems.module.css';

const styles = { ...localStyles, ...tableStyles, ...lineItemStyles };

const commonInitalState = ({ insertedAsGross }) => ({
  values: {
    netAmount: '',
    grossAmount: '',
    insertedAsGross,
  },
  parsedValues: {},
  errors: {},
  isLoading: false,
  isFocused: false,
});

const revenueInitialState = ({ insertedAsGross }) => ({
  position: '',
  quantity: '',
  unit: '',
  grossAmount: '',
  insertedAsGross,
});

const expensesInitialState = {
  name: '',
};

const trackSearchPositionsClick = () =>
  piwikHelpers.trackEvent(CATEGORY_PRODUCT_CATALOG, CREATE_NEW_INVOICE_POSITIONS_SEARCH_POSITION);
const trackSearchPositionsSelect = () =>
  piwikHelpers.trackEvent(
    CATEGORY_PRODUCT_CATALOG,
    CREATE_NEW_INVOICE_POSITIONS_SEARCH_POSITION_SELECT
  );
const trackSearchPositionsHelpIcon = () =>
  piwikHelpers.trackEvent(CATEGORY_PRODUCT_CATALOG, CREATE_NEW_INVOICE_POSITIONS_SEARCH_HELPICON);
const trackSavePosition = () =>
  piwikHelpers.trackEvent(CATEGORY_PRODUCT_CATALOG, CREATE_NEW_INVOICE_POSITIONS_SAVE_POSITION);
const trackDeletePosition = () =>
  piwikHelpers.trackEvent(CATEGORY_PRODUCT_CATALOG, CREATE_NEW_INVOICE_POSITIONS_DELETE_POSITION);

class NewItemRow extends Component {
  static propTypes = {
    createLineItem: func.isRequired,
    addNewLineItemCreator: func.isRequired,
    removeNewLineItemCreator: func.isRequired,
    createProductCatalogItem: func.isRequired,
    updateProductCatalogItem: func.isRequired,
    removeItem: func.isRequired,
    translationPath: string.isRequired,
    lineCategories: arrayOf(shape({})),
    cells: arrayOf(shape({})),
    extendedRowCells: arrayOf(shape({})),
    rowType: string,
    newItem: shape({}),
    fetchProductCatalogItem: func,
    prefilled: bool,
    markAsAccepted: bool,
    invoiceInsertedAsGross: bool,
    clearAssignedBankTransfers: func,
    status: string,
    crudMode: string,
    assignedBankTransfers: arrayOf(shape({})),
    addProductHidden: bool,
    productCatalogItemsEnabled: bool,
    handleDisableButton: func,
  };

  static defaultProps = {
    rowType: TYPE_REVENUE,
    cells: [],
    prefilled: false,
    newItem: {},
    markAsAccepted: false,
  };

  constructor(props) {
    super(props);
    const { rowType = TYPE_REVENUE, newItem, invoiceInsertedAsGross } = this.props;
    const insertedAsGross = invoiceInsertedAsGross || false;
    const { values, ...rest } = commonInitalState({ insertedAsGross });
    const revenueValues = {
      ...revenueInitialState({ insertedAsGross }),
      ...newItem,
    };
    const initialValues =
      rowType === TYPE_EXPENSES
        ? {
            ...expensesInitialState,
            ...newItem,
          }
        : revenueValues;

    const mergedValues = {
      ...values,
      ...initialValues,
    };

    this.state = {
      values: mergedValues,
      isConfirmModalVisible: false,
      ...rest,
    };

    this.isMobileUserAgent = isMobile();
  }

  componentDidMount() {
    const {
      rowType,
      addNewLineItemCreator,
      newItem: { rowId, prefilled },
      taxRates,
    } = this.props;
    const { insertedAsGross, vat, grossAmount, netAmount } = this.state.values;

    if (!prefilled) addNewLineItemCreator({ id: rowId, creatorFunction: this.handlePost });

    if (rowType === TYPE_REVENUE) {
      if (insertedAsGross) {
        this.setField('netAmount')(toNetValue(grossAmount, vat));
      } else {
        this.setField('grossAmount')(toGrossValue(netAmount, vat));
      }

      if (taxRates?.length)
        this.setState({
          taxRates: taxRates,
          values: {
            ...this.state.values,
            vat: this.props.defaultVat,
            taxRateId: this.props.taxRateId,
            invoiceLineCategoryId: this.props.outgoingInvoiceSelectedCategory,
          },
        });
    }
  }

  componentWillMount() {
    if (this.props.markAsAccepted) {
      this.handlePost();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      values: { vat: prevVat, insertedAsGross: previousInsertedAsGross },
    } = prevState;
    const {
      values: { vat, insertedAsGross, netAmount, grossAmount },
    } = this.state;
    const insertedAsGrossChanged = previousInsertedAsGross !== insertedAsGross;

    if (prevProps.outgoingInvoiceSelectedCategory !== this.props.outgoingInvoiceSelectedCategory) {
      this.setState({
        taxRates: this.props.taxRates,
        values: {
          ...this.state.values,
          vat: this.props.defaultVat,
          invoiceLineCategoryId: this.props.outgoingInvoiceSelectedCategory,
          taxRateId: this.props.taxRateId,
        },
      });
    }

    if (vat !== prevVat) {
      if (insertedAsGross) {
        this.setField('netAmount')(toNetValue(grossAmount, vat));
      } else {
        this.setField('grossAmount')(toGrossValue(netAmount, vat));
      }
    }

    if (!insertedAsGross && insertedAsGrossChanged) {
      this.setField('grossAmount')(toGrossValue(netAmount, vat));
    }

    const invoiceInsertedAsGrossChanged =
      prevProps.invoiceInsertedAsGross !== this.props.invoiceInsertedAsGross;

    if (isBoolean(prevProps.invoiceInsertedAsGross) && invoiceInsertedAsGrossChanged) {
      this.onGrossToggle();
    }
  }

  setFocused = (value) => this.setState({ isFocused: value });

  saveOnEnterPress = (event) => {
    const saveLineItem = () => this.onSave(event);
    isPressedEnter(saveLineItem)(event);
  };

  get sharedInputProps() {
    return {
      onKeyPress: this.saveOnEnterPress,
    };
  }

  handleClickOutside = async (event) => {
    const { isFocused } = this.state;

    if (get(event, "target.attributes['data-id'].value", '') !== BUTTON_ADD_ITEM_DATA_ID) {
      if (!isFocused) return;
      this.setFocused(false);
      this.onSave();
    } else {
      await this.onSave();
    }
  };

  closeConfirmModal = () => this.setState({ isConfirmModalVisible: false });

  onSave = (event) => {
    event?.preventDefault();

    const { isConfirmModalVisible } = this.state;
    const { assignedBankTransfers, rowType, status } = this.props;

    const isPaidOrPartlyPaid =
      status === InvoiceStatuses.PAID || status === InvoiceStatuses.PARTLY_PAID;

    if (
      isPaidOrPartlyPaid &&
      !isConfirmModalVisible &&
      rowType === TYPE_EXPENSES &&
      !isEmpty(assignedBankTransfers)
    ) {
      return this.setState({ isConfirmModalVisible: true });
    }

    this.setState({ isConfirmModalVisible: false });
    return this.handlePost();
  };

  onGrossToggle = () =>
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          insertedAsGross: !prevState.values.insertedAsGross,
        },
      }),
      this.onGrossNetStateChange
    );

  onGrossNetStateChange = () => {
    const {
      values: { insertedAsGross, netAmount, grossAmount },
    } = this.state;
    const [sourceFieldName, computedFieldName, inputValue] = insertedAsGross
      ? ['grossAmount', 'netAmount', grossAmount]
      : ['netAmount', 'grossAmount', netAmount];

    if (inputValue === '') return;

    this.onPriceChange([sourceFieldName, computedFieldName])({
      target: { value: inputValue },
    });
  };

  onPriceChange =
    ([sourceFieldName, computedFieldName] = []) =>
    (e) => {
      const { vat } = this.state.values;
      const value = e.target.valueAsNumber;

      if (isNaN(value)) {
        return;
      }

      const computedValue = Number(
        (sourceFieldName === 'netAmount'
          ? toGrossValue(value, vat)
          : toNetValue(value, vat)
        ).toFixed(3)
      );

      this.setState(({ values, parsedValues }) => ({
        values: {
          ...values,
          [sourceFieldName]: value,
          [computedFieldName]: computedValue,
        },
        parsedValues: {
          ...parsedValues,
          [sourceFieldName]: value,
          [computedFieldName]: computedValue,
        },
      }));
    };

  onQuantityChange = (e) => {
    const parsedValue = parseCurrency(e.target.value);
    const value = normalizeCurrency(parsedValue);

    this.setState(({ values, parsedValues }) => ({
      values: {
        ...values,
        quantity: value,
      },
      parsedValues: {
        ...parsedValues,
        quantity: parsedValue,
      },
    }));
  };

  setField = (name) => (value) =>
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
    }));

  setLoading = (value) => this.setState({ isLoading: value });

  handleChange = (name) => (event) => {
    const {
      target: { value },
    } = event;

    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
    }));
  };

  handlePositionSelect = async (itemId) => {
    if (!itemId) return;
    const { fetchProductCatalogItem } = this.props;
    const { taxRates } = this.state;
    const { response } = await fetchProductCatalogItem(itemId);
    const item = response.data.attributes;
    const itemWithMappedKey = { ...item, position: item.name };
    const newTaxRate = taxRates?.find(({ value }) => item.vat === value);

    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        ...itemWithMappedKey,
        vat: !!newTaxRate ? newTaxRate.value : prevState.values.vat,
        taxRateId: !!newTaxRate ? newTaxRate.id : this.state.values.taxRateId,
      },
    }));
  };

  handleCheckbox =
    (name) =>
    ({ target }) => {
      this.setState((prevState) => ({
        values: {
          ...prevState.values,
          [name]: target.checked,
        },
      }));
    };

  handleRemove = () => {
    const {
      removeNewLineItemCreator,
      removeItem,
      newItem: { rowId },
    } = this.props;
    removeNewLineItemCreator(rowId);
    removeItem();
  };

  parseValues = (values) => {
    const { insertedAsGross } = values;
    const netAmount = insertedAsGross && values.netAmount === '' ? '' : values.netAmount;
    const grossAmount = !insertedAsGross && values.grossAmount === '' ? '' : values.grossAmount;

    return {
      ...values,
      netAmount,
      grossAmount,
    };
  };

  handlePost = async () => {
    const { clearAssignedBankTransfers, createLineItem } = this.props;
    this.setLoading(true);

    try {
      /**
       * These amount can't be set to empty string, otherwise it will be displayed as this field has an error,
       * what is not exactly true. We want to just display an error for one field that is currently displayed (net or gross),
       * not for two of them at once. It should be refactored on a backend to return an error depending on `insertedAsGross` attribute
       */
      const { values } = this.state;
      const parsedValues = this.parseValues(values);

      await createLineItem(parsedValues);
      this.handleRemove();
      this.setState({ errors: {} });
      clearAssignedBankTransfers();

      return true;
    } catch (errors) {
      this.setState({ errors });

      return false;
    } finally {
      this.setLoading(false);
    }
  };

  handleSelect = (name) => (option) => {
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [name]: option && option.value,
        taxRateId: option && option.id,
      },
    }));
  };

  translationPath = (name) =>
    `${this.props.translationPath}.form.details_section.fields.${snakeCase(name)}`;

  renderNetAmount = (name, { onChange = null, settings = {}, textFieldProps = {} } = {}) => {
    const { values, errors: allErrors } = this.state;
    const errors = allErrors[name];
    const hasErrors = Boolean((errors || []).length);
    const placeholder = `${t('forms.line_items.placeholders.price_netto')} *`;
    const value = values[name] ?? undefined;
    const defaultValue = ensureNumericMoneyValue(value) || '';

    return (
      <div className={cx(styles.inputWrapper, styles.inputNetAmount)}>
        <TextField
          name={name}
          type="number"
          step="0.001"
          defaultValue={defaultValue}
          invalid={hasErrors}
          className={styles.input}
          isCurrency
          inputClassName={styles.inputContent}
          onChange={onChange || this.handleChange(name)}
          onPaste={(e) => {
            e.preventDefault();
            const pastedValue = e.clipboardData.getData('text/plain');
            const valueAsNumber = ensureNumericMoneyValue(pastedValue);
            e.target.value = valueAsNumber;

            typeof onChange === 'function'
              ? onChange(e)
              : this.handleChange(name)({
                  ...e,
                  value: valueAsNumber,
                });
          }}
          selectIfDefault={settings.selectIfDefault}
          {...textFieldProps}
          placeholder={this.isMobileUserAgent ? undefined : placeholder}
          isLabelHidden
          dataId="LineItem:input-net-gross"
          required
          inputProps={this.sharedInputProps}
        />
      </div>
    );
  };

  createSelectRenderer =
    ({ disabled = false } = {}) =>
    (name) => {
      const { values, errors: allErrors } = this.state;
      const { taxRates } = this.state;
      const errors = allErrors[name];
      const hasErrors = Boolean((errors || []).length);
      const placeholder = t(`forms.line_items.placeholders.${name}`);
      const noResultsPlaceholder =
        name === 'vat' ? t(`forms.line_items.placeholders.no_results_vat`) : '';

      return (
        <SelectField
          name={name}
          value={values[name]}
          invalid={hasErrors}
          onChange={this.handleSelect(name)}
          options={taxRates}
          className={styles.select}
          selectClassName={styles.selectControl}
          disabled={disabled}
          disableNative
          dataId={`LineItem:${name}`}
          placeholder={placeholder}
          noResultsText={noResultsPlaceholder}
        />
      );
    };

  renderQuantity = (name) => {
    const { parsedValues, values, errors: allErrors } = this.state;
    const errors = allErrors[name];
    const hasErrors = Boolean((errors || []).length);
    const placeholder = `${t(`forms.line_items.placeholders.${name}`)} *`;
    const previousAmount = toString(parsedValues[name]);
    const amount = toString(values[name]);
    const value = formatAmountWithHistory(previousAmount)(amount);

    return (
      <div className={styles.inputWrapper}>
        <TextField
          name={name}
          type="number"
          step="0.001"
          onChange={this.onQuantityChange}
          value={value}
          invalid={hasErrors}
          className={styles.input}
          inputClassName={styles.inputContent}
          placeholder={this.isMobileUserAgent ? undefined : placeholder}
          isLabelHidden
          dataId={`LineItem:input-${name}`}
          required
          inputProps={this.sharedInputProps}
        />
      </div>
    );
  };

  renderText = (name, { textType, rounded = false }) => (
    <span className={styles.text}>
      {rounded && '≈ '}
      {textType ? l(this.state.values[name], textType) : this.state.values[name]}
    </span>
  );

  renderMultiLevelSelect = (name) => {
    const { lineCategories, outgoingInvoiceSelectedCategory } = this.props;
    const {
      values: { invoiceLineCategoryId },
    } = this.state;
    const errors = this.state.errors[name];
    const hasErrors = Boolean((errors || []).length);
    const selectedItem =
      outgoingInvoiceSelectedCategory ||
      lineCategories?.find((item) => item.id === String(invoiceLineCategoryId));

    return (
      <TableCell name={name} className={styles.positionCell}>
        <div className={cx(styles.inputWrapper, styles.categoryInput)}>
          <CategorySearch
            dataId="LineItem:input-categories"
            categories={lineCategories}
            onSelectCategory={this.onSelectCategory}
            invalid={hasErrors}
            hiddenLabel
            selectedItem={selectedItem}
            onClearCategory={this.onClearCategory}
            withIcon
          />
        </div>
      </TableCell>
    );
  };

  renderNetLinked = () => (
    <TableCell name="netAmount">
      <I18n t={this.translationPath('netAmount')} className={localStyles.cellHeader} />
      {this.renderNetAmount('netAmount', {
        onChange: this.onPriceChange(['netAmount', 'grossAmount']),
      })}
    </TableCell>
  );

  renderPositionSelect = () => {
    const errors = this.state.errors.position;
    const hasErrors = Boolean((errors || []).length);

    return (
      <TableCell
        name="position"
        className={cx(styles.positionCell, {
          [styles.positionCellVertical]: this.isMobileUserAgent,
        })}
        customWidth
      >
        {this.isMobileUserAgent && (
          <div className={cx(localStyles.cellHeader, styles.cellHeaderVertical)}>
            {t('forms.line_items.placeholders.position.select')}
          </div>
        )}
        <div className={cx(styles.inputWrapper, styles.positionSelectInput)}>
          <PositionSelectControl
            name="position"
            visible
            placeholder={
              this.isMobileUserAgent ? '' : t('forms.line_items.placeholders.position.select')
            }
            dataId="LineItems:input-position"
            skipFetchOnMount={this.state.values.quantity}
            invalid={hasErrors}
            initialValue={this.state.values.position}
            onValueChange={this.setField('position')}
            onPositionSelect={this.handlePositionSelect}
            className={styles.input}
            inputClassName={cx(styles.inputContent)}
            isInsertedAsGross={this.props.invoiceInsertedAsGross}
            required
            onClick={trackSearchPositionsClick}
            onChange={trackSearchPositionsSelect}
          />
        </div>
        <InfoIcon
          className={styles.positionIcon}
          text={t('forms.line_items.position_select.info_icon')}
          onClick={trackSearchPositionsHelpIcon}
        />
      </TableCell>
    );
  };

  renderPositionInput = (name) => {
    const errors = this.state.errors[name];
    const hasErrors = Boolean((errors || []).length);

    return (
      <TableCell name={name} className={styles.positionCell} customWidth>
        <div className={cx(styles.inputWrapper, styles.positionSelectInput)}>
          <TextField
            name={name}
            onChange={this.handleChange(name)}
            value={this.state.values[name]}
            invalid={hasErrors}
            className={styles.input}
            inputClassName={styles.inputContent}
            labelClassName={this.isMobileUserAgent && localStyles.darkLabelOnMobile}
            placeholder={`${t(`forms.line_items.placeholders.position.text`)} ${
              !this.isMobileUserAgent ? '*' : ''
            }`}
            dataId={`LineItem:input-${name}`}
            isLabelHidden={!this.isMobileUserAgent}
            shouldLabelStayUp={this.isMobileUserAgent}
            isPlaceholderVisible={!this.isMobileUserAgent}
            inputProps={this.sharedInputProps}
          />
        </div>
      </TableCell>
    );
  };

  renderFullWidthInput = (name, required = false) => {
    const errors = this.state.errors[name];
    const hasErrors = Boolean((errors || []).length);

    return (
      <TableCell name={name}>
        <div className={cx(styles.inputWrapper, localStyles.fullWidthInput)}>
          <TextField
            name={name}
            onChange={this.handleChange(name)}
            value={this.state.values[name]}
            invalid={hasErrors}
            className={styles.input}
            maxLength="200"
            inputClassName={styles.inputContent}
            required={required}
            placeholder={t(`forms.line_items.placeholders.${snakeCase(name)}`)}
            dataId={`LineItem:input-${name}`}
            isPlaceholderVisible={false}
            shouldLabelStayUp
            inputProps={this.sharedInputProps}
          />
        </div>
      </TableCell>
    );
  };

  renderItemNumber = (name) => {
    const errors = this.state.errors[name];
    const hasErrors = Boolean((errors || []).length);

    return (
      <TableCell name={name} className={styles.itemNumberCell} customWidth>
        <I18n t={this.translationPath('itemNumber')} className={localStyles.cellHeader} />
        <div className={cx(styles.inputWrapper)}>
          <TextField
            name={name}
            onChange={this.handleChange(name)}
            value={this.state.values[name]}
            invalid={hasErrors}
            className={cx(styles.input, {
              [localStyles.numberInputContainer]: !this.isMobileUserAgent,
            })}
            inputClassName={styles.inputContent}
            labelContentClassName={this.isMobileUserAgent ? '' : localStyles.numberInputLabel}
            label={t(`forms.line_items.placeholders.item_number_label`)}
            placeholder={
              this.isMobileUserAgent
                ? undefined
                : t(`forms.line_items.placeholders.item_number_placeholder`)
            }
            dataId={`LineItem:input-${name}`}
            isLabelHidden={this.isMobileUserAgent}
            shouldLabelStayUp
            inputProps={this.sharedInputProps}
          />
        </div>
      </TableCell>
    );
  };

  renderItemNumberSelect = (name) => {
    const errors = this.state.errors[name];
    const hasErrors = Boolean((errors || []).length);

    return (
      <TableCell name={name} className={styles.itemNumberSelectCell}>
        <span className={localStyles.cellHeader}>{t(this.translationPath('itemNumber'))}</span>
        <div className={styles.inputWrapper}>
          <ItemNumberSelect
            name={name}
            onChange={this.handleChange(name)}
            invalid={hasErrors}
            className={styles.input}
            initialValue={this.state.values.itemNumber}
            inputClassName={styles.inputContent}
            label={t(`forms.line_items.placeholders.item_number_label`)}
            placeholder={t(`forms.line_items.placeholders.item_number_placeholder`)}
            onValueChange={this.setField('itemNumber')}
            inputProps={this.sharedInputProps}
          />
        </div>
      </TableCell>
    );
  };

  renderUnitsSelect = () => {
    const errors = this.state.errors.unit;
    const hasErrors = Boolean((errors || []).length);

    return (
      <TableCell name="unit">
        <I18n t={this.translationPath('unit')} className={localStyles.cellHeader} />
        <div className={cx(styles.inputWrapper, styles.unitSelectInput)}>
          <UnitSelect
            name="unit"
            visible
            invalid={hasErrors}
            initialValue={this.state.values.unit}
            onValueChange={this.setField('unit')}
            className={styles.input}
            inputClassName={cx(styles.inputContent)}
            placeholder={
              this.isMobileUserAgent ? undefined : t('forms.line_items.placeholders.unit')
            }
            isLabelHidden
            required
            inputProps={this.sharedInputProps}
          />
        </div>
      </TableCell>
    );
  };

  renderCounterInput = (name, required = false) => {
    const errors = this.state.errors[name];
    const hasErrors = Boolean((errors || []).length);

    return (
      <TableCell customHeight>
        <TextInputWithCounter
          name={name}
          placeholder={this.isMobileUserAgent ? undefined : t(this.translationPath(name))}
          label={this.isMobileUserAgent ? t(this.translationPath(name)) : undefined}
          invalid={hasErrors}
          required={required}
          onChange={this.handleChange(name)}
          value={this.state.values[name]}
          dataTestId={`LineItem:input-${name}`}
          isPlaceholderVisible={false}
          shouldLabelStayUp
        />
      </TableCell>
    );
  };

  renderGrossLinked = () => (
    <TableCell name="grossAmount">
      <I18n t={this.translationPath('grossAmount')} className={localStyles.cellHeader} />
      {this.renderNetAmount('grossAmount', {
        onChange: this.onPriceChange(['grossAmount', 'netAmount']),
      })}
    </TableCell>
  );

  renderCell = ({
    name = '',
    type,
    info = false,
    options = this.state.taxRates,
    textType = '',
    settings = {},
    isDisabled = false,
    className,
  }) => {
    let renderer;

    switch (type) {
      case 'input':
        renderer = this.renderQuantity;
        break;
      case 'netAmount':
        renderer = this.renderNetAmount;
        break;
      case 'select':
        renderer = this.createSelectRenderer();
        break;
      case 'multiselect':
        return this.renderMultiLevelSelect(name, { disabled: isDisabled });
      case 'text':
        renderer = this.renderText;
        break;
      case 'vat':
        renderer = this.createSelectRenderer({ disabled: isDisabled });
        break;
      case 'netLinked':
        return this.renderNetLinked(name);
      case 'grossLinked':
        return this.renderGrossLinked(name);
      case 'positionSelect':
        return this.renderPositionSelect();
      case 'positionInput':
        return this.renderPositionInput(name);
      case 'itemNumber':
        return this.renderItemNumber(name);
      case 'itemNumberSelect':
        return this.renderItemNumberSelect(name);
      case 'fullWidthInput':
        return this.renderFullWidthInput(name);
      case 'unitSelect':
        return this.renderUnitsSelect(name);
      case 'counterInput':
        return this.renderCounterInput(name);
      default:
        renderer = noop;
    }

    if (!name) {
      return <TableCell />;
    }

    return (
      <TableCell name={name} className={className}>
        <I18n t={this.translationPath(name)} className={localStyles.cellHeader} />
        {info && (
          <span className={styles.cellIcon}>
            <InfoIcon text={info} />
          </span>
        )}
        <div className={styles.inputWrapper}>{renderer(name, { textType, options, settings })}</div>
      </TableCell>
    );
  };

  setErrors = (errors) => this.setState({ errors });

  render() {
    const {
      cells = [],
      extendedRowCells = [],
      createProductCatalogItem,
      updateProductCatalogItem,
      addProductHidden = false,
      productCatalogItemsEnabled = false,
    } = this.props;
    const { values, isLoading, isConfirmModalVisible } = this.state;
    // TODO use hook when component will be a function
    const isDesktop = isXlgUp();

    return (
      <div
        className={cx(styles.lineItemRow, {
          [styles.lineItemRowExtended]: !isEmpty(extendedRowCells),
        })}
        data-id="LineItem:new-row"
      >
        <LineItemContainer
          onClick={() => this.setFocused(true)}
          outsideClickHandler={(e) => this.handleClickOutside(e)}
          outsideClickIgnoreClass={IGNORE_OUTSIDE_CLICK_CLASS}
        >
          <div className={localStyles.detailsSection}>
            <TableSubRow data-id="newLineItem">
              {!isDesktop && (
                <TableCell name="header">
                  <I18n t={this.translationPath('header')} className={localStyles.rowHeader} />
                </TableCell>
              )}
              {cells.map((cell) => this.renderCell(cell))}
              {!isDesktop && extendedRowCells.map((cell) => this.renderCell(cell))}
            </TableSubRow>
            {isDesktop && !isEmpty(extendedRowCells) && (
              <TableSubRow>{extendedRowCells.map((cell) => this.renderCell(cell))}</TableSubRow>
            )}
            <AddProductButton
              productCatalogItemsEnabled={productCatalogItemsEnabled}
              addProductHidden={addProductHidden}
              createProductCatalogItem={createProductCatalogItem}
              updateProductCatalogItem={updateProductCatalogItem}
              parseValues={this.parseValues}
              values={values}
              setErrors={this.setErrors}
            />
          </div>
          <If ok={!isLoading}>
            <TableCell name="actions" className={styles.actionsCell} customWidth>
              <button
                className={styles.action}
                onClick={(e) => {
                  this.onSave(e);
                  trackSavePosition();
                }}
                type="button"
                title={t('forms.line_items.actions.submit')}
                data-id="LineItem:button-submit"
              >
                <div className={cx(styles.actionIconAccept, localStyles.acceptIcon)} />
                <I18n t="forms.line_items.actions.submit" className={styles.actionLabel} />
              </button>
              <button
                className={styles.action}
                onClick={(e) => {
                  this.handleRemove(e);
                  trackDeletePosition();
                }}
                type="button"
                title={t('forms.line_items.actions.delete')}
                data-id="LineItem:button-delete"
              >
                <div className={cx(styles.actionIconCancel, localStyles.cancelIcon)} />
                <I18n t="forms.line_items.actions.delete" className={styles.actionLabel} />
              </button>
            </TableCell>
          </If>
          <If ok={isLoading}>
            <TableCell
              name="loading"
              className={cx(localStyles.actionsCell, localStyles.loadingCell)}
              customWidth
            >
              <LoadingIcon />
            </TableCell>
          </If>
        </LineItemContainer>
        <ConfirmationModal
          dangerousAction
          isOpen={isConfirmModalVisible}
          onClose={this.closeConfirmModal}
          onConfirm={() => this.onSave()}
          closeLabel={t('revenue.delete.cancel')}
          confirmLabel={t('modals.confirmation.ok')}
        >
          <I18n t="modals.invoice_details_section" />
        </ConfirmationModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  status: incomingInvoiceSelector(state, 'status'),
  assignedBankTransfers: state.incomingInvoice.bankTransfers,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProductCatalogItem: (id) => dispatch(fetchProductCatalogItem(id)),
  clearAssignedBankTransfers: () => dispatch(clearAssignedBankTransfersAction),
  createProductCatalogItem: (values, redirect, modifier) =>
    dispatch(createProductCatalogItemAction(values, redirect, modifier)),
  updateProductCatalogItem: (values, redirect, isNotifyingAction) =>
    dispatch(updateProductCatalogItemAction(values, redirect, isNotifyingAction)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewItemRow);
