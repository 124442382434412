import React from 'react';
import cx from 'classnames';
import { BaseFieldsProps } from 'redux-form';

import { t } from 'shared/utils';
import If from 'components/Conditions/If';
import { FieldNotification } from 'components/Form';

import type { Passwords } from '../../types';
import checkers from '../../utils';

import styles from './NotificationBox.module.css';

export interface NotificationBoxOwnProps {
  minimumLength?: number;
}

type NotificationBoxProps = NotificationBoxOwnProps &
  BaseFieldsProps<NotificationBoxOwnProps> &
  Passwords;

const NotificationBox = ({ minimumLength = 8, ...props }: NotificationBoxProps) => {
  return (
    <div id="password-requirements-box">
      <If ok={Boolean(checkers.matching(props))}>
        <FieldNotification
          message={t('registration.form.errors.passwords_not_match')}
          variant="warning"
        />
      </If>
      <div className={styles.wrapper}>
        <div
          className={cx(styles.main, {
            [styles.warning]: checkers.any(props),
          })}
        >
          <p className={styles.title}>{t('registration.form.password_suggestion')}</p>

          <div className={styles.rules}>
            <p
              className={cx(styles.rule, {
                [styles.error]: !!checkers.bothCases(props),
              })}
            >
              {t('registration.form.errors.passwords_not_both_cases')}
            </p>
            <p
              className={cx(styles.rule, {
                [styles.error]: !!checkers.specials(props),
              })}
            >
              {t('registration.form.errors.passwords_not_specials')}
            </p>
            <p
              className={cx(styles.rule, {
                [styles.error]: !!checkers.length(props),
              })}
            >
              {t('registration.form.errors.passwords_not_long_enough', { minimumLength })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationBox;
