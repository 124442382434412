import React from 'react';
import cx from 'classnames';

import styles from '../StatusIcons.module.css';

const CancelledStatusIcon = () => (
  <svg viewBox="0 0 20 20" className={styles.statusIcon} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <rect
        className={cx(styles.strokeDanger, styles.fillDanger)}
        x=".5"
        y=".5"
        width="19"
        height="19"
        rx="2"
      />
      <path
        d="M10 5c2.757 0 5 2.243 5 5s-2.243 5-5 5-5-2.243-5-5 2.243-5 5-5zm0-1a6 6 0 1 0 0 12 6 6 0 0 0 0-12z"
        fill="#FFF"
      />
      <path d="M6.367 13.64L13.6 6.4" stroke="#FFF" />
    </g>
  </svg>
);

export default CancelledStatusIcon;
