export const parseValues = (values: {
  insertedAsGross: boolean;
  netAmount: string;
  grossAmount: string;
}) => {
  const { insertedAsGross } = values;
  const netAmount = insertedAsGross && values.netAmount === '' ? '' : values.netAmount;
  const grossAmount = !insertedAsGross && values.grossAmount === '' ? '' : values.grossAmount;

  return {
    ...values,
    netAmount,
    grossAmount,
  };
};
