import React from 'react';
import InlineSvg from 'react-inlinesvg';
import { number } from 'prop-types';

import Header from 'containers/PaymentPlan/ModalsContainer/components/Header/Header';
import iconTime from 'images/icon-clock.svg';
import { t } from 'shared/utils';

import styles from './PaymentPlanWizardHeader.module.css';

const PaymentPlanWizardHeader = ({ displayDate }) => {
  const trialEndTranslation =
    displayDate === 1 ? 'payment_plan_wizard.header_1_day' : 'payment_plan_wizard.header_1_days';

  return (
    <div className={styles.container}>
      <InlineSvg src={iconTime} />
      <Header
        bold={t(trialEndTranslation, { days: displayDate })}
        description={t('payment_plan_wizard.header_2')}
      />
      <div className={styles.headerOrange}>{t('payment_plan_wizard.header_3')}</div>
    </div>
  );
};

PaymentPlanWizardHeader.propTypes = {
  displayDate: number,
};

export default PaymentPlanWizardHeader;
