import React from 'react';
import { useHistory } from 'react-router';

import { t } from 'shared/utils';
import EmptyEntryPage from 'components/EmptyEntryPage/EmptyEntryPage';

import IconLanding from './IconLanding';

const EmptyPage = () => {
  const history = useHistory();

  return (
    <EmptyEntryPage
      dataIdPrefix="Cashbook"
      Image={IconLanding}
      header={t('cashbooks.empty_page.title')}
      subheader={t('cashbooks.empty_page.subtitle')}
      info={[
        t('cashbooks.empty_page.point_1'),
        t('cashbooks.empty_page.point_2'),
        t('cashbooks.empty_page.point_3'),
      ]}
      createButtonLabel={t('cashbooks.empty_page.button')}
      onCreateButtonClick={() => history.push('/cashbooks/new')}
    />
  );
};

export default EmptyPage;
