import { connect } from 'react-redux';
import { go, goBack, goForward, push, replace } from 'connected-react-router';

const withRouter = (Component) =>
  connect(null, {
    push,
    replace,
    go,
    goForward,
    goBack,
  })(Component);

export default withRouter;
