import React from 'react';
import noop from 'lodash/noop';
import { arrayOf, func, shape } from 'prop-types';

import { t } from 'shared/utils';
import Button, { ButtonAppearances } from 'components/Button';

import styles from './Search.module.css';

const DateRangeButtons = ({ dateRanges, onRangeChange = noop }) => (
  <div className={styles.buttonWrapper}>
    {dateRanges.map((dateRange) => (
      <Button
        key={dateRange.label}
        label={t(`date_ranges.${dateRange.label}`)}
        appearance={ButtonAppearances.outlined}
        className={styles.button}
        onClick={() => {
          dateRange.onClick();
          onRangeChange();
        }}
      />
    ))}
  </div>
);

DateRangeButtons.propTypes = {
  dateRanges: arrayOf(shape({}).isRequired).isRequired,
  onRangeChange: func,
};

export default DateRangeButtons;
