import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useTable } from 'react-table';
import { format } from 'date-fns';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { downloadPdf, getCashbooks } from 'api/me/cashbooks';
import { getCashTransactions } from 'api/me/cashbooks/cashTransactions';
import EntityPath from 'constants/entitiesPaths';
import { CASHBOOKS_DOWNLOAD_PDF } from 'constants/piwik';
import { Resources } from 'constants/resources';
import paths from 'routes/paths';
import { downloadData, t } from 'shared/utils';
import { Cashbook } from 'types/entities/Cashbook';
import { CashTransaction, PaginationResource } from 'types/entities/CashTransaction';
import { Section } from 'components/CardView';
import Loading from 'components/Loading';
import { Pagination } from 'components/Pagination/Pagination';
import Table from 'components/v2/Table/Table/Table';
import { Button as TableFilterButton } from 'components/v2/Table/TableFilters/TableFilters.styled';

import { trackEventHandler } from '../utils';
import {
  AddCashTransactionButton,
  CardView,
  EmptyStateButton,
  EmptyTableText,
  ExportButtons,
  Header,
  HeaderWrapper,
  SummaryWrapper,
  TableWrapper,
} from './CashTransactions.styled';
import CreatorModal, { OrderPrefix } from './CreatorModal/CreatorModal';
import DeleteModal from './DeleteModal/DeleteModal';
import EditModal from './EditModal/EditModal';
import Summary from './Summary/Summary';
import { getColumns, getTableData, RowData } from './utils';

const CashTransactions = () => {
  const history = useHistory();
  const [pagination, setPagination] = useState<PaginationResource | undefined>();
  const [isCreatorModalOpen, setIsCreatorModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [cashTransactionToDelete, setCashTransactionToDelete] = useState<null | CashTransaction>(
    null
  );
  const [cashTransactionToEdit, setCashTransactionToEdit] = useState<CashTransaction>();
  const [cashTransactions, setCashTransactions] = useState<CashTransaction[]>([]);
  const [isInitialDataFetching, setIsInitialDataFetching] = useState(true);
  const [areCashTransactionsFetching, setAreCashTransactionsFetching] = useState(false);
  const [cashbook, setCashbook] = useState<Cashbook | null>(null);
  const tableData = useMemo(() => getTableData({ cashTransactions }), [cashTransactions]);
  const editModalInitialValues = useMemo(
    () =>
      cashTransactionToEdit
        ? {
            vat: cashTransactionToEdit.vat,
            invoiceLineCategoryId: cashTransactionToEdit.invoiceLineCategory.id,
            taxRateId: cashTransactionToEdit.taxRateId,
            grossAmount: cashTransactionToEdit.grossAmount.toString(),
            transactionType: cashTransactionToEdit.transactionType,
            transactionDate: cashTransactionToEdit.transactionDate,
            dailyOrderPrefix: OrderPrefix.After,
            documentNumber: cashTransactionToEdit.documentNumber,
            description: cashTransactionToEdit.description,
            dailyOrderId: cashTransactionToEdit.dailyOrderId,
          }
        : {},
    [cashTransactionToEdit]
  );

  const openDeleteModal = useCallback((cashTransaction?: CashTransaction) => {
    if (!cashTransaction) return;

    setIsDeleteModalOpen(true);
    setCashTransactionToDelete(cashTransaction);
  }, []);

  const openEditModal = useCallback((cashTransaction?: CashTransaction) => {
    if (!cashTransaction) return;

    setIsEditModalOpen(true);
    setCashTransactionToEdit(cashTransaction);
  }, []);

  const columns = useMemo(
    () => getColumns({ cashTransactions, onDelete: openDeleteModal, onEdit: openEditModal }),
    [cashTransactions, openDeleteModal, openEditModal]
  );

  const instance = useTable<RowData>({
    columns,
    data: tableData,
  });

  const fetchCashbook = useCallback(async () => {
    let cashbooksResponse = null;

    try {
      cashbooksResponse = await getCashbooks();
    } catch (e) {
      history.push(paths.home);
      return;
    }

    const cashbooks =
      build<Cashbook>(normalize(cashbooksResponse.data), EntityPath.Cashbooks, null, {
        ignoreLinks: true,
      }) || [];

    if (cashbooks.length === 0) return;

    // for now it's only possible to create one cashbook
    return cashbooks[0];
  }, [history]);

  const fetchCashTransactions = useCallback(
    async (cashbook: Cashbook, pagination = { page: 1 }) => {
      setAreCashTransactionsFetching(true);
      let cashTransactionsResponse = null;

      try {
        cashTransactionsResponse = await getCashTransactions(
          cashbook.id,
          {},
          {
            pagination_resource: Resources.CASH_TRANSACTIONS,
            ...pagination,
          }
        );
      } catch (e) {
        setAreCashTransactionsFetching(false);
        history.push(paths.home);
        return;
      }

      const ids = cashTransactionsResponse.data.data.map((item) => item.id);
      const cashTransactions =
        build<CashTransaction>(
          normalize(cashTransactionsResponse.data),
          EntityPath.CashTransactions,
          ids
        ) || [];
      const totalPages = parseInt(cashTransactionsResponse.headers['total-pages'], 10);

      setCashTransactions(cashTransactions);
      setPagination({
        page: pagination.page,
        totalPages: totalPages,
      });
      setAreCashTransactionsFetching(false);
    },
    [history]
  );

  const fetchCashbookAndTransactions = useCallback(
    async (pagination = { page: 1 }) => {
      const cashbook = await fetchCashbook();

      if (!cashbook) return;

      setCashbook(cashbook);
      await fetchCashTransactions(cashbook, pagination);
      setIsInitialDataFetching(false);
    },
    [fetchCashbook, fetchCashTransactions]
  );

  useEffect(() => {
    fetchCashbookAndTransactions(pagination);
  }, []);

  const openCreatorModal = useCallback(() => {
    setIsCreatorModalOpen(true);
  }, []);

  const closeCreatorModal = useCallback(() => {
    setIsCreatorModalOpen(false);
  }, []);

  const closeEditModal = useCallback(() => {
    setIsEditModalOpen(false);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
    setCashTransactionToDelete(null);
  }, []);

  const onSave = useCallback(() => {
    fetchCashbookAndTransactions();
  }, [fetchCashbookAndTransactions]);

  const handleDownloadPdf = useCallback(async () => {
    if (!cashbook) return;

    trackEventHandler(CASHBOOKS_DOWNLOAD_PDF);
    const response = await downloadPdf(cashbook.id);
    const startDate = format(new Date(cashbook.startDate), 'dd-MM-yyyy');
    const today = format(new Date(), 'dd-MM-yyyy');
    const fileName = t('cashbooks.cash_transactions.pdf_name', { startDate, today });

    downloadData(response.data, fileName);
  }, [cashbook]);

  if (isInitialDataFetching) {
    return <Loading />;
  }

  return (
    <CardView>
      <HeaderWrapper>
        <Header>{t('cashbooks.cash_transactions.title')}</Header>
        <div>
          {cashbook && (
            <SummaryWrapper>
              <Summary cashbook={cashbook} onSave={onSave} />
            </SummaryWrapper>
          )}
          <AddCashTransactionButton onClick={openCreatorModal} data-id="new-cash-entry">
            + {t('cashbooks.cash_transactions.add')}
          </AddCashTransactionButton>
        </div>
      </HeaderWrapper>
      <ExportButtons>
        <TableFilterButton
          onClick={handleDownloadPdf}
          disabled={cashTransactions.length === 0 || !cashbook}
        >
          {t('cashbooks.cash_transactions.download_pdf')}
        </TableFilterButton>
      </ExportButtons>
      <TableWrapper>
        <Table<RowData>
          instance={instance}
          isLoading={areCashTransactionsFetching}
          getRowProps={(row) => ({ 'data-deleted': !!row.original.reasonForDeletion })}
        />
        {cashTransactions.length === 0 && (
          <>
            <EmptyTableText data-id="no-entries">
              {t('cashbooks.cash_transactions.no_entities')}
            </EmptyTableText>
            <EmptyStateButton data-id="create-entry-empty-state" onClick={openCreatorModal}>
              + {t('cashbooks.cash_transactions.add')}
            </EmptyStateButton>
          </>
        )}
      </TableWrapper>
      {cashbook && isCreatorModalOpen && (
        <CreatorModal onClose={closeCreatorModal} cashbook={cashbook} onSave={onSave} />
      )}
      {cashbook && isEditModalOpen && cashTransactionToEdit && editModalInitialValues && (
        <EditModal
          onClose={closeEditModal}
          cashbook={cashbook}
          onSave={onSave}
          initialValues={editModalInitialValues}
          cashTransaction={cashTransactionToEdit}
        />
      )}
      {cashTransactionToDelete && cashbook && isDeleteModalOpen && (
        <DeleteModal
          onClose={closeDeleteModal}
          cashTransactionToDelete={cashTransactionToDelete}
          onSave={onSave}
          cashbook={cashbook}
        />
      )}
      {cashbook && !!tableData.length && (
        <Section>
          <Pagination
            {...pagination}
            request={(page: number) => fetchCashTransactions(cashbook, page)}
            isFetching={areCashTransactionsFetching}
            resource={Resources.CASH_TRANSACTIONS}
          />
        </Section>
      )}
    </CardView>
  );
};

export default CashTransactions;
