import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { routes } from './routes';

import styles from './Profile.module.css';

const getHeadingForChildren = (children: any, pathname: string) => {
  const currentChild = children.find((child: any) => child.path.startsWith(pathname));

  return currentChild ? currentChild.heading : '';
};

const Profile = () => {
  const { pathname } = useLocation();

  return (
    <div className={styles.main}>
      <Switch>
        {routes.map(
          (
            {
              component: Component,
              path,
              heading: parentHeading,
              subheading,
              children,
              description,
              ...rest
            },
            index
          ) => {
            const heading = children ? getHeadingForChildren(children, pathname) : parentHeading;

            return (
              <Route
                path={path}
                component={() => (
                  <Component
                    {...rest}
                    heading={heading}
                    subheading={subheading}
                    description={description}
                  />
                )}
                key={index}
              />
            );
          }
        )}
      </Switch>
    </div>
  );
};

export default Profile;
