import React, { SVGAttributes } from 'react';

const IconBackground = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 794 224" {...props}>
    <path
      d="M65.278 60.376c32.83-16.284 133.791-38.169 204.467-25.241 70.677 12.927 92.205 25.141 146.345 12.927C470.23 35.85 492.096 0 542.205 0c50.109 0 138.121 64.938 181.539 83 43.417 18.062 105.428 26.18 44.376 73s-87.696-6.74-189.596 39.349c-101.9 46.09-177.761-26.056-229.022 0-51.26 26.056-79.704 38.074-145.283 20.039-65.579-18.035-151.336-52.101-172.523-68.133-21.187-16.03-40.356-40.078-26.232-58.113C19.59 71.107 32.45 76.662 65.278 60.376z"
      fill="#F3F3F3"
      fillRule="evenodd"
    />
  </svg>
);

export default IconBackground;
