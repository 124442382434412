import React from 'react';
import cx from 'classnames';

import { t } from 'shared/utils';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import CardView, { HeadingSection, Section } from 'components/CardView';
import LegalsComponent from 'components/Legals';

import styles from '../RegistrationForm.module.css';

type DisclaimerModalProps = {
  title: string;
  handleOnAccept: () => void;
  body: string;
};

const DisclaimerModal = ({ title, handleOnAccept, body }: DisclaimerModalProps) => (
  <CardView className={styles.card}>
    <HeadingSection className={styles.cardSectionHeading}>
      <h3 className={styles.headingText}>{title}</h3>
    </HeadingSection>
    <Section className={cx(styles.cardSection, styles.contentSection)}>
      <div className={styles.tos}>
        <LegalsComponent title={title} body={body} />
      </div>
    </Section>
    <Section className={styles.cardSection}>
      <ActionPanel>
        <ActionButton label={t('registration.disclaimer.agree')} onClick={handleOnAccept} />
      </ActionPanel>
    </Section>
  </CardView>
);

export default DisclaimerModal;
