import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { addConfirmationBannerEmail } from 'actions/confirmation-banner';
import { EMAIL_VERIFICATION_INFOBAR_CLOSE } from 'constants/piwik';
import paths from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'shared/hooks/app';
import { t } from 'shared/utils';
import Infobar from 'redesign/components/atoms/Infobar/Infobar';

import { trackEvent } from './utils';

type AccountConfirmedProps = {
  onClose: () => void;
};

const AccountConfirmed = ({ onClose }: AccountConfirmedProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const email = useAppSelector((state) => state.profile.credentials.email);

  const handleClose = useCallback(() => {
    trackEvent(EMAIL_VERIFICATION_INFOBAR_CLOSE);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (location.pathname !== paths.home) {
      onClose();
    }
  }, [location.pathname, onClose]);

  useEffect(() => {
    return () => {
      dispatch(addConfirmationBannerEmail(email));
    };
  }, [dispatch, email]);

  return (
    <Infobar
      color="green"
      title={t('redesign.organisms.global_notifications.account_confirmed.title')}
      description={t('redesign.organisms.global_notifications.account_confirmed.description')}
      onCloseClick={handleClose}
    />
  );
};

export default AccountConfirmed;
