import normalize from 'json-api-normalizer';
import { head } from 'lodash';
import moment from 'moment';
import build from 'redux-object';

import {
  CREATE_SUCCESS,
  DELETE_MAYBE_SUGGESTION,
  DELETE_SUCCESS,
  FETCH_SUCCESS,
  FETCH_SUMS_SUCCESS,
  TOGGLE_DISABLED_BUTTON_ON_LINE_ITEM_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/incoming-invoice';
import { extend, t } from 'shared/utils';

const defaultState = {
  dueDate: moment().format('DD.MM.YYYY'),
  placeOfOrigin: t('expenses.form.inland'),
  disabled: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
    case FETCH_SUMS_SUCCESS:
      const normalizedResponse = normalize(action.response || {});
      const incomingInvoices = build(normalizedResponse, 'incomingInvoices', undefined, {
        eager: true,
        ignoreLinks: true,
      });
      const { suggestedUnassignedBankTransfers } = head(incomingInvoices) || {};

      const suggestedBankTransfersWithType = (suggestedUnassignedBankTransfers || []).map(
        (bankTransfer) => ({
          ...bankTransfer,
          type: 'incoming_invoice',
        })
      );
      const { ...attributes } = action.response.data.attributes || {};
      return {
        ...extend(state, attributes),
        suggestedUnassignedBankTransfers: suggestedBankTransfersWithType,
        meta: action.response.data.meta,
        attributes: action.response.data.attributes,
        id: action.response.data.id,
      };

    case DELETE_MAYBE_SUGGESTION:
      const { payload } = action;
      const suggestedTransfersWithoutSuggestion = state.suggestedUnassignedBankTransfers.filter(
        (transfer) => transfer.id !== payload.bankTransferId
      );

      return {
        ...state,
        suggestedUnassignedBankTransfers: suggestedTransfersWithoutSuggestion,
      };

    case DELETE_SUCCESS:
      return defaultState;

    case TOGGLE_DISABLED_BUTTON_ON_LINE_ITEM_REQUEST:
      return {
        ...state,
        disabled: !state.disabled,
      };

    default:
      return state;
  }
};
