import React from 'react';

const IconDuplicate = () => (
  <svg width="14px" height="14px" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <g id="1.0-Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-10.000000, -58.000000)">
        <g
          id="0.0-fundamentals/0.2-Icons/action-icon/duplicate-invoice"
          transform="translate(7.000000, 55.000000)"
        >
          <g id="0.0-fundamentals/0.2-Icons/action-icon/transfer-to-invoice">
            <g
              id="icon-correction-copy"
              transform="translate(2.500000, 3.000000)"
              fill="currentColor"
            >
              <path
                d="M3.5,6.98045867 L3.5,7.93965102 L7.28781176,7.93965102 C7.28781176,9.40877111 7.28781176,10.0922304 7.28781176,9.99002896 C7.28781176,9.8878275 8.02520784,8.97597989 9.5,7.25448611 L7.28781176,5 L7.28781176,6.98045867 L3.5,6.98045867 Z M3.04510462,3 L2.04510462,4.29411793 L1.79411936,4.29411793 L1.79411936,12.7058821 L10.2058806,12.7058821 L10.2058806,12.3094029 L11.5,11.3094029 L11.5,12.7058821 C11.5,13.417647 10.9176477,14 10.2058806,14 L10.2058806,14 L1.79411936,14 C1.08235226,14 0.5,13.417647 0.5,12.7058821 L0.5,12.7058821 L0.5,4.29411793 C0.5,3.58235302 1.08235226,3 1.79411936,3 L1.79411936,3 L3.04510462,3 Z M13.2058806,0 C13.9176477,0 14.5,0.582353021 14.5,1.29411793 L14.5,1.29411793 L14.5,9.70588207 C14.5,10.417647 13.9176477,11 13.2058806,11 L13.2058806,11 L8.67646773,11 L9.97058709,9.70588207 L13.2058806,9.70588207 L13.2058806,1.29411793 L4.79411936,1.29411793 L4.79411936,4.52941228 L3.5,5.82352924 L3.5,1.29411793 C3.5,0.582353021 4.08235226,-1.77635684e-15 4.79411936,-1.77635684e-15 L4.79411936,-1.77635684e-15 Z"
                id="Shape"
              />
            </g>
            <polygon id="Rectangle-3-Copy" fillRule="nonzero" points="0 0 20 0 20 20 0 20" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IconDuplicate;
