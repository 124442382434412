import React, { ChangeEvent, FunctionComponent } from 'react';
import cx from 'classnames';
import { isString, noop } from 'lodash';
import moment from 'moment';

import { DateField } from 'components/Form';

interface SearchDateProps {
  name: string;
  filters?: { [key: string]: string };
  setQueryAction?: (name: string) => (value: string) => void;
  placeholder?: string;
  label?: string;
  className?: string;
  isPastDateDisabled?: boolean;
  isFutureDateDisabled?: boolean;
  dataId?: string;
}

/**
 * This component wraps DateField component into an interface
 * which is compatible with the SearchGroup component.
 */
const SearchDate: FunctionComponent<SearchDateProps> = ({
  setQueryAction = () => noop,
  name,
  filters = {},
  placeholder = '',
  label = '',
  className,
  isPastDateDisabled = false,
  isFutureDateDisabled = false,
  ...props
}) => {
  const value = filters[name] || '';

  return (
    <div className={cx(className)}>
      <DateField
        id={name}
        onChange={noop}
        placeholder={placeholder}
        label={label}
        input={{
          value,
          onChange: (newValue: string | ChangeEvent<HTMLInputElement>) => {
            setQueryAction(name)(isString(newValue) ? newValue : newValue.target.value);
          },
          onBlur: noop,
          onDragStart: noop,
          onDrop: noop,
          onFocus: noop,
          name,
        }}
        isLabelHidden
        minDate={isPastDateDisabled ? moment() : undefined}
        maxDate={isFutureDateDisabled ? moment() : undefined}
        {...props}
      />
    </div>
  );
};

export default SearchDate;
