import { get, keys, max } from 'lodash';

import { INDEX_SUCCESS } from 'constants/banks';

import accounts from './accounts';

const byCode = (state = {}, action) => {
  if (action.type !== INDEX_SUCCESS) return state;
  return action.response.data.reduce(
    (acc, { attributes }) => ({
      ...acc,
      [attributes.code]: attributes,
    }),
    state
  );
};

const codes = (state = [], action) => {
  if (action.type !== INDEX_SUCCESS) return state;
  return action.response.data.map(({ attributes }) => attributes.code);
};

export const getAccessByType = (bank, type) =>
  bank.accessMethods.find((access) => access.type === type);

export const getBank = (state, code) => state.banks.byCode[code] || {};

export const getBanks = (state) => state.banks.codes.map((c) => state.banks.byCode[c]) || [];

export const getBankLoginFields = (access) => access.credentials;

export const getLogoFromBank = (bank) => {
  const variant = max(keys(get(bank, 'icon.resolutions')));

  return get(bank, `icon.resolutions.["${variant}"]`);
};

export default (state = {}, action) => ({
  codes: codes(state.codes, action),
  byCode: byCode(state.byCode, action),
  accounts: accounts(state.accounts, action),
});
