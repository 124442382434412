import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { isEmpty } from 'lodash';
import { bool, func, shape } from 'prop-types';

import {
  clearFiltersToInitial as clearFiltersToInitialAction,
  indexOutgoingInvoices as indexOutgoingInvoicesAction,
} from 'actions/outgoing-invoice';
import {
  PIWIK_ACTION_TAB_REVENUE_CHART,
  PIWIK_ACTION_TAB_REVENUE_TABLE,
  PIWIK_CATEGORY_REVENUE,
  PIWIK_CONVERSION_TAB_CHART,
} from 'constants/piwik';
import paths from 'routes/paths';
import { objectHasValues, t } from 'shared/utils';
import { useDebouncedHasAnyFilters } from 'shared/utils/hooks/useDebouncedHasAnyFilters';
import { useRefetchOnPropsChange } from 'shared/utils/hooks/useRefetchOnPropsChange';
import { piwikHelpers } from 'shared/utils/piwik';
import EmptyEntryPage from 'components/EmptyEntryPage/EmptyEntryPage';
import OutgoingInvoiceEmptyStateImage from 'components/EmptyEntryPage/images/OutgoingInvoice';
import Loading from 'components/Loading';
import Tabs from 'components/Tabs';
import OutgoingInvoicesKPITile from 'redesign/components/organisms/OutgoingInvoicesKPITile/OutgoingInvoicesKPITile';

import RevenueChart from './Chart';
import Table from './Table';

import styles from './OutgoingInvoices.module.css';

const sections = [
  {
    heading: t(`revenue.tabs.table`),
    component: Table,
    id: 'table',
    piwikAction: PIWIK_ACTION_TAB_REVENUE_TABLE,
  },
  {
    heading: t(`revenue.tabs.chart`),
    component: RevenueChart,
    id: 'chart',
    piwikAction: PIWIK_ACTION_TAB_REVENUE_CHART,
  },
];

const switchTab = (activeTabIndex) => {
  if (activeTabIndex === 1) {
    piwikHelpers.trackGoal(PIWIK_CONVERSION_TAB_CHART);
  }

  piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, sections[activeTabIndex].piwikAction);
};

const Revenue = ({
  areOutgoingInvoicesEmpty,
  isFetching,
  push,
  indexOutgoingInvoices,
  parsedFilters,
  pagination,
  sorting,
  clearFiltersToInitial,
}) => {
  /**
   * This flag is initially set to false and is set to true after first fetch
   * for outgoing invoices comes back. It is used for displaying the initial loader.
   */
  const [areOutgoingInvoicesFetched, setAreOutgoingInvoicesFetched] = useState(false);
  const debouncedHasAnyFiltersActive = useDebouncedHasAnyFilters(parsedFilters);

  useEffect(
    () => () => {
      clearFiltersToInitial();
    },
    [clearFiltersToInitial]
  );

  useRefetchOnPropsChange({
    fetchFunction: async ({
      pagination: newPagination,
      sorting: newSorting,
      overwrittenValues,
      parsedFilters: newParsedFilters,
    }) => {
      const isIndexClassicView = objectHasValues(newParsedFilters);

      await indexOutgoingInvoices({ ...newPagination, ...overwrittenValues }, newSorting, {
        isIndexClassicView,
      });
      setAreOutgoingInvoicesFetched(true);
    },
    props: { parsedFilters, pagination, sorting },
  });

  if (!areOutgoingInvoicesFetched) {
    return (
      <div className={styles.page}>
        <div className={styles.main}>
          <Loading />
        </div>
      </div>
    );
  }

  const isEmptyStateVisible =
    !isFetching && areOutgoingInvoicesEmpty && !debouncedHasAnyFiltersActive;

  if (isEmptyStateVisible) {
    return (
      <div className={styles.page}>
        <div className={styles.main}>
          <EmptyEntryPage
            dataIdPrefix="OutgoingInvoice"
            Image={OutgoingInvoiceEmptyStateImage}
            header={t('empty_entry_pages.outgoing_invoice.header')}
            subheader={t('empty_entry_pages.outgoing_invoice.subheader')}
            info={[
              t('empty_entry_pages.outgoing_invoice.info.row_1'),
              t('empty_entry_pages.outgoing_invoice.info.row_2'),
              t('empty_entry_pages.outgoing_invoice.info.row_3'),
              t('empty_entry_pages.outgoing_invoice.info.row_4'),
              t('empty_entry_pages.outgoing_invoice.info.row_5'),
            ]}
            createButtonLabel={t('empty_entry_pages.outgoing_invoice.create_button_label')}
            importButtonLabel={t('empty_entry_pages.outgoing_invoice.import_button_label')}
            onCreateButtonClick={() => push(paths.newOutgoingInvoice)}
            onImportButtonClick={() => push(paths.importOutgoingInvoice)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.page}>
      <OutgoingInvoicesKPITile />
      <div className={styles.main}>
        <Tabs sections={sections} switchTabAddon={switchTab} piwikTrackingEnabled />
      </div>
    </div>
  );
};

Revenue.propTypes = {
  areOutgoingInvoicesEmpty: bool.isRequired,
  isFetching: bool.isRequired,
  push: func.isRequired,
  setPage: func.isRequired,
  indexOutgoingInvoices: func.isRequired,
  parsedFilters: shape({}).isRequired,
  pagination: shape({}).isRequired,
  sorting: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  isFetching: state.outgoingInvoices.isFetching,
  areOutgoingInvoicesEmpty: isEmpty(state.outgoingInvoices.data),
  parsedFilters: state.outgoingInvoices.parsedFilters,
  pagination: state.outgoingInvoices.pagination,
  sorting: state.outgoingInvoices.sorting,
});

const mapDispatchToProps = {
  push: pushAction,
  indexOutgoingInvoices: indexOutgoingInvoicesAction,
  clearFiltersToInitial: clearFiltersToInitialAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Revenue);
