import React, { memo } from 'react';

import { t, toCurrency } from 'shared/utils';

import styles from './Result.module.css';

type ResultProps = {
  moneyNeeded: number;
};

const Result = ({ moneyNeeded }: ResultProps) => (
  <div className={styles.container}>
    <div className={styles.needs}>
      {t('features.financial_planning.liquidity_calculator.summary.result.liquidity_needs')}
    </div>
    <div className={styles.result} data-id="LiquidityCalculatorSummary:result">
      {toCurrency(moneyNeeded, { strip_insignificant_zeros: true })}
    </div>
  </div>
);

export default memo(Result);
