import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchProposal as fetchProposalAction } from 'actions/proposal';
import { fetchProposalLineItems as fetchProposalLineItemsAction } from 'actions/proposal/line-items';
import { SHOW_MODE } from 'constants/common/crud';
import { FORM_NAME } from 'constants/proposal';
import ProposalCreator from 'containers/Proposals/ProposalCreator';
import { useAppDispatch } from 'shared/hooks/app';
import { RootState } from 'store';
import useLineItemsControl, { SectionType } from 'components/LineItems/useLineItemControl';
import LoadingIcon from 'components/LoadingIcon';

const ProposalsShow = () => {
  const dispatch = useAppDispatch();
  const { isFetching, isFetched } = useSelector((state: RootState) => state.proposal);
  const { id: proposalId } = useParams<{ id: string }>();
  const {
    defaultCategory,
    isDeprecatedCategory,
    category,
    taxRates,
    taxRateId,
    hasSavedLineItems,
    selectedCategory,
    lineCategories,
  } = useLineItemsControl({ sectionType: SectionType.proposal, formName: FORM_NAME });

  const fetchProposal = (id: string) => dispatch(fetchProposalAction(id));
  const fetchProposalLineItems = (id: string) => dispatch(fetchProposalLineItemsAction(id));

  const isLoading = isFetching || !isFetched;

  isLoading && <LoadingIcon />;

  //inital fetch document data
  useEffect(() => {
    fetchProposal(proposalId);
    fetchProposalLineItems(proposalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProposalCreator
      crudMode={SHOW_MODE}
      hasSavedLineItems={hasSavedLineItems}
      defaultCategory={defaultCategory}
      isDeprecated={isDeprecatedCategory}
      selectedCategory={selectedCategory}
      category={category}
      taxRateId={taxRateId}
      taxRates={taxRates}
      lineCategories={lineCategories}
    />
  );
};

export default ProposalsShow;
