import moment from 'moment';

export const checkIfDiscountGranted = ({ discountGrant }) => Boolean(discountGrant);

export const checkIfDiscountActive = ({ discountGrant, discountPeriod }) => {
  if (!discountGrant) return false;

  const discountEndDate = moment(discountPeriod, 'DD-MM-YYYY');
  return !!discountEndDate.diff(moment(), 'days');
};
