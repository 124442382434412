import React, { memo, SVGProps } from 'react';

const NaviExpander = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M9.519 6.294c.39.39.39 1.02 0 1.41l-3.88 3.88 3.88 3.88a.996.996 0 11-1.41 1.41l-4.59-4.59a.996.996 0 010-1.41l4.59-4.59c.38-.38 1.02-.38 1.41.01zM18.707 6.294c.39.39.39 1.02 0 1.41l-3.88 3.88 3.88 3.88a.996.996 0 11-1.41 1.41l-4.59-4.59a.996.996 0 010-1.41l4.59-4.59c.38-.38 1.02-.38 1.41.01z" />
    </g>
  </svg>
);

export default memo(NaviExpander);
