import React, { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { showSuccessNotification } from 'actions/notification';
import { updateInvoiceTemplate } from 'api/me/invoiceTemplates';
import {
  DOCUMENT_LAYOUT_DOWNLOAD,
  DOCUMENT_LAYOUT_PICK_COLOR,
  DOCUMENT_LAYOUT_PREVIEW_MODE_DOWNLOAD,
  DOCUMENT_LAYOUT_PREVIEW_MODE_ENTER,
  DOCUMENT_LAYOUT_SAVE,
} from 'constants/piwik';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { InvoiceTemplate, Preset } from 'types/entities/InvoiceTemplate';
import Button from 'redesign/components/atoms/Button/Button';
import Checkbox from 'redesign/components/atoms/Checkbox/Checkbox';
import ColorPicker from 'redesign/components/atoms/ColorPicker/ColorPicker';
import Select, { SelectProps } from 'redesign/components/atoms/Select/Select';
import ControlLabel from 'redesign/components/molecules/ControlLabel/ControlLabel';
import FieldWrapper from 'redesign/components/molecules/FieldWrapper/FieldWrapper';
import RouteLeavingGuard from 'redesign/components/organisms/RouteLeavingGuard/RouteLeavingGuard';

import DiscardChangesModal from '../DiscardChangesModal/DiscardChangesModal';
import Preview from '../Preview/Preview';
import {
  FontFamilyOptions,
  FontSizeOptions6To10,
  FontSizeOptions6To12,
  FontSizeOptions6To14,
  FontSizeOptions6To16,
  FontSizeOptions6To20,
  FontSizeOptions6To24,
  Options,
} from './constants';
import {
  Buttons,
  Content,
  Description,
  Fonts,
  FormWrapper,
  IconArrowDown,
  PreviewWrapper,
  Section,
  Title,
  Wrapper,
} from './Form.styled';
import {
  useTrackEvents,
  useUpdateColouredElementsHex,
  useUpdateInvertColouredElementsTextColor,
  useUpdateValuesOnPresetChange,
} from './hooks';
import Presets from './Presets/Presets';
import { FormData, Item } from './types';
import { getDefaultValues, itemToString, trackEvent } from './utils';

const Option = ({ item }: { item: Item<number | string> }) => <div>{item.label}</div>;

type FormProps = {
  invoiceTemplate: InvoiceTemplate;
};

const Form = ({ invoiceTemplate }: FormProps) => {
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState('presets');
  const [defaultValues, setDefaultValues] = useState(getDefaultValues(invoiceTemplate));
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { isDirty, isSubmitSuccessful },
  } = useForm<FormData>({
    defaultValues,
  });
  const values = watch();
  const disabled = values.preset !== Preset.Custom;
  const disabledInvertColouredElementsTextColor = disabled || values.tableVersion.value !== 1;
  const disabledMessage = disabled
    ? t('features.settings.document_template.layout.disable_message')
    : '';

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    await updateInvoiceTemplate(invoiceTemplate.id, {
      headerVersion: data.headerVersion.value,
      tableVersion: data.tableVersion.value,
      footerVersion: data.footerVersion.value,
      fontFamily: data.fontFamily.value,
      preset: data.preset,
      fontSizeAddress: data.fontSizeAddress.value,
      fontSizeInvoiceInfo: data.fontSizeInvoiceInfo.value,
      fontSizeSubject: data.fontSizeSubject.value,
      fontSizeTableContent: data.fontSizeTableContent.value,
      fontSizeTableHeaders: data.fontSizeTableHeaders.value,
      fontSizeTableDescription: data.fontSizeTableDescription.value,
      fontSizeNotes: data.fontSizeNotes.value,
      fontSizeFooter: data.fontSizeFooter.value,
      colouredElementsHex: data.colouredElementsHex,
      invertColouredElementsTextColor: data.invertColouredElementsTextColor,
    });
    setDefaultValues(data);
    trackEvent(DOCUMENT_LAYOUT_SAVE);
    dispatch(showSuccessNotification(t('features.settings.document_template.layout.save_success')));
  };

  const handleTitleClick = useCallback(
    (panel: string) => () => {
      setExpanded((active) => (active === panel ? '' : panel));
    },
    []
  );

  const handleOpenPreview = useCallback(() => {
    trackEvent(DOCUMENT_LAYOUT_PREVIEW_MODE_ENTER);
  }, []);

  const handleDownload = useCallback((isFromFullscreeenPreview: boolean) => {
    trackEvent(
      isFromFullscreeenPreview ? DOCUMENT_LAYOUT_PREVIEW_MODE_DOWNLOAD : DOCUMENT_LAYOUT_DOWNLOAD
    );
  }, []);

  useUpdateValuesOnPresetChange(defaultValues, values.preset, setValue);

  useUpdateInvertColouredElementsTextColor(values.preset, values.tableVersion, setValue);

  useUpdateColouredElementsHex(
    values.preset,
    values.tableVersion,
    values.colouredElementsHex,
    setValue
  );

  useTrackEvents(values);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset, defaultValues]);

  return (
    <Wrapper>
      <RouteLeavingGuard blocked={isDirty} modal={DiscardChangesModal} />
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Section $isOpen={expanded === 'presets'}>
            <Title onClick={handleTitleClick('presets')}>
              {t('features.settings.document_template.layout.presets.title')}
              <IconArrowDown $isRotated={expanded === 'presets'} />
            </Title>
            <Content $noMaxWidth>
              <Description>
                {t('features.settings.document_template.layout.presets.description')}
              </Description>
              <Controller
                name="preset"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Presets onChange={onChange} value={value} />
                )}
              />
            </Content>
          </Section>
          <Section $isOpen={expanded === 'layout'} title={disabledMessage}>
            <Title onClick={handleTitleClick('layout')}>
              {t('features.settings.document_template.layout.customize.title')}
              <IconArrowDown $isRotated={expanded === 'layout'} />
            </Title>
            <Content>
              <Description>
                {t('features.settings.document_template.layout.customize.description')}
              </Description>
              <Controller
                name="headerVersion"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <FieldWrapper<SelectProps<Item<number>>>
                    label={t('features.settings.document_template.layout.fields.header_version')}
                    field={Select}
                    options={Options}
                    itemToString={itemToString}
                    option={Option}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                  />
                )}
              />
              <Controller
                name="tableVersion"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <FieldWrapper<SelectProps<Item<number>>>
                    label={t('features.settings.document_template.layout.fields.table_version')}
                    field={Select}
                    options={Options}
                    itemToString={itemToString}
                    option={Option}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                  />
                )}
              />
              <Controller
                name="footerVersion"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <FieldWrapper<SelectProps<Item<number>>>
                    label={t('features.settings.document_template.layout.fields.footer_version')}
                    field={Select}
                    options={Options}
                    itemToString={itemToString}
                    option={Option}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                  />
                )}
              />
            </Content>
          </Section>
          <Section $isOpen={expanded === 'fonts'} title={disabledMessage}>
            <Title onClick={handleTitleClick('fonts')}>
              {t('features.settings.document_template.layout.fonts.title')}
              <IconArrowDown $isRotated={expanded === 'fonts'} />
            </Title>
            <Content>
              <Description>
                {t('features.settings.document_template.layout.fonts.description_1')}
              </Description>
              <Controller
                name="fontFamily"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <FieldWrapper<SelectProps<Item<string>>>
                    label={t('features.settings.document_template.layout.fields.font_family')}
                    field={Select}
                    options={FontFamilyOptions}
                    itemToString={itemToString}
                    option={Option}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                  />
                )}
              />
              <Description>
                {t('features.settings.document_template.layout.fonts.description_2')}
              </Description>
              <Fonts>
                <div>
                  {t('features.settings.document_template.layout.fields.font_size_address')}
                </div>
                <Controller
                  name="fontSizeAddress"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select<Item<number>>
                      options={FontSizeOptions6To20}
                      itemToString={itemToString}
                      option={Option}
                      disabled={disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
                <div>
                  {t('features.settings.document_template.layout.fields.font_size_invoice_info')}
                </div>
                <Controller
                  name="fontSizeInvoiceInfo"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select<Item<number>>
                      options={FontSizeOptions6To10}
                      itemToString={itemToString}
                      option={Option}
                      disabled={disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
                <div>
                  {t('features.settings.document_template.layout.fields.font_size_subject')}
                </div>
                <Controller
                  name="fontSizeSubject"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select<Item<number>>
                      options={FontSizeOptions6To24}
                      itemToString={itemToString}
                      option={Option}
                      disabled={disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
                <div>
                  {t('features.settings.document_template.layout.fields.font_size_table_headers')}
                </div>
                <Controller
                  name="fontSizeTableHeaders"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select<Item<number>>
                      options={FontSizeOptions6To10}
                      itemToString={itemToString}
                      option={Option}
                      disabled={disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
                <div>
                  {t('features.settings.document_template.layout.fields.font_size_table_content')}
                </div>
                <Controller
                  name="fontSizeTableContent"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select<Item<number>>
                      options={FontSizeOptions6To16}
                      itemToString={itemToString}
                      option={Option}
                      disabled={disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
                <div>
                  {t(
                    'features.settings.document_template.layout.fields.font_size_table_description'
                  )}
                </div>
                <Controller
                  name="fontSizeTableDescription"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select<Item<number>>
                      options={FontSizeOptions6To16}
                      itemToString={itemToString}
                      option={Option}
                      disabled={disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
                <div>{t('features.settings.document_template.layout.fields.font_size_notes')}</div>
                <Controller
                  name="fontSizeNotes"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select<Item<number>>
                      options={FontSizeOptions6To14}
                      itemToString={itemToString}
                      option={Option}
                      disabled={disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
                <div>{t('features.settings.document_template.layout.fields.font_size_footer')}</div>
                <Controller
                  name="fontSizeFooter"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select<Item<number>>
                      options={FontSizeOptions6To12}
                      itemToString={itemToString}
                      option={Option}
                      disabled={disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
              </Fonts>
            </Content>
          </Section>
          <Section $isOpen={expanded === 'colors'} title={disabledMessage}>
            <Title onClick={handleTitleClick('colors')}>
              {t('features.settings.document_template.layout.colors.title')}
              <IconArrowDown $isRotated={expanded === 'colors'} />
            </Title>
            <Content>
              <Description>
                {t('features.settings.document_template.layout.colors.description_1')}
              </Description>
              <Controller
                name="colouredElementsHex"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <ColorPicker
                    color={value}
                    onChange={(...args) => {
                      trackEvent(DOCUMENT_LAYOUT_PICK_COLOR);
                      onChange(...args);
                    }}
                    disabled={disabled}
                  />
                )}
              />
              <Controller
                name="invertColouredElementsTextColor"
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <div>
                    <ControlLabel
                      label={t(
                        'features.settings.document_template.layout.fields.invert_coloured_elements_text_color'
                      )}
                      disabled={disabledInvertColouredElementsTextColor}
                      onChange={onChange}
                      onBlur={onBlur}
                      checked={value}
                      control={<Checkbox />}
                      name={name}
                    />
                  </div>
                )}
              />
            </Content>
          </Section>
          <Buttons>
            <Button type="submit">{t('features.settings.document_template.layout.save')}</Button>
          </Buttons>
        </div>
      </FormWrapper>
      <PreviewWrapper>
        <Preview
          values={{
            header_version: values.headerVersion.value,
            table_version: values.tableVersion.value,
            footer_version: values.footerVersion.value,
            font_family: values.fontFamily.value,
            font_size_address: values.fontSizeAddress.value,
            font_size_invoice_info: values.fontSizeInvoiceInfo.value,
            font_size_subject: values.fontSizeSubject.value,
            font_size_table_content: values.fontSizeTableContent.value,
            font_size_table_headers: values.fontSizeTableHeaders.value,
            font_size_table_description: values.fontSizeTableDescription.value,
            font_size_notes: values.fontSizeNotes.value,
            font_size_footer: values.fontSizeFooter.value,
            coloured_elements_hex: values.colouredElementsHex,
            invert_coloured_elements_text_color: values.invertColouredElementsTextColor,
          }}
          onOpenPreview={handleOpenPreview}
          onDownload={handleDownload}
        />
      </PreviewWrapper>
    </Wrapper>
  );
};

export default Form;
