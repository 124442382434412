import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Fields = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Label = styled.p`
  position: absolute;
  top: 8px;
  left: 0;
  font-size: 10px;
  font-weight: 600;
`;

export const Spearator = styled.p`
  margin: 16px 20px 0;
  color: #b2b3b2;
`;
