import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import paths from 'routes/paths';
import I18n from 'components/I18n';

import styles from './CreationForbiddenMessage.module.css';

const CreationForbiddenMessage = ({ section, path = paths.companyProfile }) => (
  <div className={styles.container}>
    <I18n t={`${section}.table.cannot_add_new.start`} />
    <Link className={styles.link} to={path}>
      <I18n t={`${section}.table.cannot_add_new.link_text`} />
    </Link>
    <I18n t={`${section}.table.cannot_add_new.end`} />
  </div>
);

CreationForbiddenMessage.propTypes = {
  section: PropTypes.oneOf(['revenue', 'proposals', 'datev']).isRequired,
  path: PropTypes.string,
};

export default CreationForbiddenMessage;
