import React from 'react';
import { bool, number, string } from 'prop-types';

import { BASIC_UID, PREMIUM_UID } from 'constants/payment-plans';
import { t } from 'shared/utils';
import { isMdUp, isXlgUp } from 'shared/utils/breakpoints';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import { TooltipPlacements } from 'redesign/components/molecules/Tooltip/Tooltip';

import PremiumHeader from './components/PremiumHeader';

import styles from './Header.module.css';

const tooltipPlacement = () => {
  if (isXlgUp()) {
    return TooltipPlacements.BOTTOM;
  } else if (isMdUp()) {
    return TooltipPlacements.TOP;
  }
  return TooltipPlacements.BOTTOM;
};

const title = {
  premium: (
    <span>
      {t('profile.payment_plan.current_payment_plan.premium')}
      <InfoIcon
        text={t('profile.payment_plan.current_payment_plan.info_icon')}
        tooltipClassName={styles.icon}
        tooltipPlacement={tooltipPlacement()}
      />
    </span>
  ),
  basic: <span>{t('profile.payment_plan.current_payment_plan.basic')}</span>,
};

const Header = ({ plan, vrMainBankAccount, defaultPrice }) => (
  <div className={styles.container}>
    <div className={styles.currentPlan}>{title[plan]}</div>
    {(() => {
      switch (plan) {
        case PREMIUM_UID:
          return (
            <PremiumHeader vrMainBankAccount={vrMainBankAccount} defaultPrice={defaultPrice} />
          );
        case BASIC_UID:
          return <span>{t('profile.payment_plan.current_payment_plan.free')}</span>;
        default:
          return null;
      }
    })()}
  </div>
);

Header.propTypes = {
  plan: string,
  vrMainBankAccount: bool,
  defaultPrice: number,
};

export default Header;
