import React, { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useUpdateEffect } from 'react-use';

import { showNotification } from 'actions/notification';
import * as ContractsCsvAPI from 'api/me/contractsCsv';
import { deleteContractFailed, deleteContractSuccess } from 'notifications/contracts';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { downloadData } from 'shared/utils';
import { bindServerValidation } from 'shared/utils/server-validation';
import { RevenueContract } from 'types/entities/Contract';
import IconDownload from 'components/Icons/IconDownload';
import { ConfirmationModal } from 'components/Modal';
import GlobalSearchFilter from 'components/v2/Table/Filters/GlobalSearchFilter/GlobalSearchFilter';
import Table from 'components/v2/Table/Table/Table';
import TableEmpty from 'components/v2/Table/TableEmpty/TableEmpty';
import TableFilters from 'components/v2/Table/TableFilters/TableFilters';
import TablePagination from 'components/v2/Table/TablePagination/TablePagination';
import * as paginationPrefrerencesActions from 'features/paginationPreferences/paginationPreferencesSlice';
import * as paginationPrefrerencesSelectors from 'features/paginationPreferences/selectors';

import * as actions from '../contractsSlice';
import NewContractButton from '../NewContractButton/NewContractButton';
import * as selectors from '../selectors';
import { CSVExportButton } from '../Shared.styled';
import { formatFilters, formatSortBy, trackEvent } from '../utils';
import getColumns from './utils/getColumns';

const RevenueContractsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector(selectors.getRevenueContracts);
  const isLoading = useSelector(selectors.getRevenueContractsIsLoading);
  const params = useSelector(selectors.getRevenueContractsParams);
  const initialPageSize = useSelector(paginationPrefrerencesSelectors.getRevenueContracts);
  const intervalOptions = useSelector(selectors.getIntervalOptions);
  const [toDelete, setToDelete] = useState<RevenueContract | null>(null);

  const handleDelete = useCallback((revenueContract: RevenueContract) => {
    setToDelete(revenueContract);
    trackEvent('Delete_Income');
  }, []);

  const columns = useMemo(
    () => getColumns({ onDelete: handleDelete, intervalOptions }),
    [handleDelete, intervalOptions]
  );

  const instance = useTable<RevenueContract>(
    {
      columns,
      data,
      pageCount: params.pageCount,
      initialState: {
        pageSize: initialPageSize,
        pageIndex: params.pageIndex,
        sortBy: params.sortBy,
        filters: params.filters,
        globalFilter: params.globalFilter,
      },
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      manualGlobalFilter: true,
      disableSortRemove: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    page,
    setGlobalFilter,
    state: { pageIndex, pageSize, sortBy, filters, globalFilter },
  } = instance;
  const isEmpty = page.length === 0;
  const hasFilters = filters.length > 0 || !!globalFilter;

  const handleDeleteContract = useCallback(async () => {
    if (!toDelete) return;

    await bindServerValidation(actions.deleteContract(toDelete.id), dispatch)
      .then(() => {
        dispatch(showNotification(deleteContractSuccess));
      })
      .catch(() => dispatch(showNotification(deleteContractFailed)));

    setToDelete(null);
    dispatch(actions.getRevenueContracts());
  }, [dispatch, toDelete]);

  const handleFetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy, filters, globalFilter }) => {
      await dispatch(
        paginationPrefrerencesActions.setPaginationPreference({
          resource: 'revenueContracts',
          pageSize,
        })
      );

      dispatch(actions.setRevenueContractsParams({ pageIndex, sortBy, filters, globalFilter }));
      dispatch(actions.getRevenueContracts());
    },
    [dispatch]
  );

  const handleNewInvoiceButtonClick = useCallback(() => {
    history.push(paths.newRevenueContract);
  }, [history]);

  const handleCsvExportClick = useCallback(async () => {
    const {
      data: {
        data: { content, format, filename },
      },
    } = await ContractsCsvAPI.getContractsCsv({
      is_revenue: true,
      sort: formatSortBy({
        sortBy,
        keysMapping: {
          client: 'clientLastName',
        },
      }),
      filters: formatFilters({ filters, globalFilter }),
    });

    downloadData(content, filename, format);
  }, [filters, globalFilter, sortBy]);

  const onFetchDataDebounced = useAsyncDebounce(handleFetchData, 200);

  useUpdateEffect(() => {
    onFetchDataDebounced({ pageIndex, pageSize, sortBy, filters, globalFilter });
  }, [onFetchDataDebounced, pageIndex, pageSize, sortBy, filters, globalFilter]);

  return (
    <>
      <div data-id="revenue-contracts-table">
        <TableFilters<RevenueContract>
          instance={instance}
          globalFilter={
            <GlobalSearchFilter
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
              placeholder={t('tables.filters.filters_group.recurring_revenues.placeholder')}
              id="full-text-search"
            />
          }
          buttons={
            <CSVExportButton onClick={handleCsvExportClick} disabled={isEmpty} data-id="csv-export">
              <IconDownload width="24" height="24" />
              {t('tables.csv_export')}
            </CSVExportButton>
          }
        />
        {isEmpty ? (
          <TableEmpty isLoading={isLoading} dataId="table-empty">
            {hasFilters ? (
              t('tables.empty_recurring_revenues')
            ) : (
              <NewContractButton
                onClick={handleNewInvoiceButtonClick}
                title="dropdown_revenue"
                dataId="add-revenue"
              />
            )}
          </TableEmpty>
        ) : (
          <>
            <Table<RevenueContract> instance={instance} isLoading={isLoading} />
            <TablePagination<RevenueContract> instance={instance} isLoading={isLoading} />
          </>
        )}
      </div>
      {!!toDelete && (
        <ConfirmationModal
          dangerousAction
          onClose={() => setToDelete(null)}
          onConfirm={handleDeleteContract}
          isOpen
        >
          <strong>{t('contracts.new.delete_revenue_confirm')}</strong>
          {toDelete.hasConnectedBankTransfers &&
            t('contracts.new.delete_assigned_expense_or_revenue_confirm')}
        </ConfirmationModal>
      )}
    </>
  );
};

export default memo(RevenueContractsTable);
