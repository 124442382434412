import { get } from 'lodash';

import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/internal-subscription-fees';

const initialState = {
  isFetching: false,
  error: false,
  data: 0,
};

export default (state = initialState, action) => {
  const { type, response } = action;

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: get(response, 'count', 0),
      };
    case FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    default:
      return state;
  }
};
