export const getPage = (year: number, currentYear: number, perPage: number) =>
  Math.floor((currentYear - year) / perPage) + 1;

export const getYears = (page: number, currentYear: number, perPage: number) => {
  const startingYear = currentYear - page * perPage;

  return Array(perPage)
    .fill(null)
    .map((_, i) => i + 1 + startingYear);
};
