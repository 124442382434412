import React from 'react';
import { Fields } from 'redux-form';

import NotificationBox, { NotificationBoxOwnProps } from './NotificationBox/NotificationBox';

const PasswordNotification = (props: NotificationBoxOwnProps) => (
  <Fields
    component={NotificationBox}
    names={['password', 'passwordConfirm']}
    variant="warning"
    {...props}
  />
);

export default PasswordNotification;
