import styled from 'styled-components';

import ButtonComponent from 'components/Button';
import IconCheckComponent from 'components/Icons/IconCheck';
import IconUploadComponent from 'components/Icons/IconUpload';

type WrapperProps = {
  isError: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  border: 1px dashed
    ${({ isError, theme }) => (isError ? theme.legacy.colors.red : theme.legacy.colors.brandBlue)};
  padding: 10px 8px 12px;
`;

export const IconUpload = styled(IconUploadComponent)`
  color: ${({ theme }) => theme.legacy.colors.brandBlue};
  width: 72px;
  margin: 55px auto 0;
  display: block;
`;

export const IconCheck = styled(IconCheckComponent)`
  color: ${({ theme }) => theme.legacy.colors.brandBlue};
  width: 65px;
  margin: 55px auto 0;
  display: block;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.legacy.colors.brandBlue};
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  margin-top: 12px;
  word-break: break-all;
`;

export const Subtitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  margin: 27px auto 0;
  max-width: 254px;
  color: #828282;
`;

export const Button = styled(ButtonComponent)`
  width: 180px;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  display: block;
  margin: 23px auto 0;
`;

export const Extensions = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  text-align: center;
  margin-top: 13px;
`;
