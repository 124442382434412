import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { arrayOf, bool, func, shape } from 'prop-types';

import BankAccountSelectField from 'containers/PaymentPlan/ModalsContainer/components/BankAccountSelectField/BankAccountSelectField';
import DebitInfo from 'containers/PaymentPlan/ModalsContainer/DirectDebit//components/DebitInfo/DebitInfo';
import AccountData from 'containers/PaymentPlan/ModalsContainer/DirectDebit/components/AccountData/AccountData';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import { Checkbox } from 'components/Form';
import I18n from 'components/I18n';
import InfoBox from 'components/InfoBox/InfoBox';
import Modal from 'components/Modal';

import styles from './NewDirectDebitModal.module.css';

const NewDirectDebitModal = ({
  isOpen,
  onClose,
  paymentsBankAccounts = [],
  debitData,
  onSubmit,
  selectAccount,
  handleCheckboxToggle,
  agreeCheckbox,
}) => (
  <Modal isOpen={isOpen} onRequestClose={onClose}>
    <div className={styles.header}>
      <span className={styles.text}>{t('profile.payment_plan.debit.modal_header')}</span>
      <button className={styles.cancel} onClick={onClose} />
    </div>
    <div className={styles.modalBody}>
      <span>{t('profile.payment_plan.debit.select_new')}</span>
      <InfoBox
        label={t('profile.payment_plan.debit.select_new_description')}
        arrowPosition="top"
        isFullHeight
      />
      <Grid className={styles.gridSection}>
        <Row>
          <Col xs={12} lg={8} className={styles.selectContainer}>
            <BankAccountSelectField
              name="accountId"
              bankAccounts={paymentsBankAccounts}
              component={BankAccountSelectField}
              onSelect={selectAccount}
              debitAccountIban={debitData.iban}
              label={t('profile.payment_plan.direct_debit.select_label')}
              hideTrailingContent
            />
          </Col>
        </Row>
      </Grid>
      <div className={styles.dataContainer}>
        <AccountData data={debitData} />
        <DebitInfo />
      </div>
      <Checkbox
        onChange={handleCheckboxToggle}
        checked={agreeCheckbox}
        className={styles.checkbox}
        preventWordBreak
      >
        <I18n t="profile.payment_plan.direct_debit.checkbox" />
      </Checkbox>
      <div className={styles.footerSection}>
        <ActionButton
          appearance="primary"
          onClick={onClose}
          label={t('profile.payment_plan.debit.abort')}
        />
        <ActionButton
          appearance="outlined"
          onClick={onSubmit}
          disabled={!agreeCheckbox}
          label={t('profile.payment_plan.debit.ok')}
        />
      </div>
    </div>
  </Modal>
);

NewDirectDebitModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  paymentsBankAccounts: arrayOf(shape({})),
  onSubmit: func.isRequired,
  selectAccount: func.isRequired,
  handleCheckboxToggle: func.isRequired,
  agreeCheckbox: bool.isRequired,
  debitData: shape({}),
};

export default NewDirectDebitModal;
