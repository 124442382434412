import api from 'api';
import { Interval } from 'features/financialPlanning/liquidityCalculator/ExpensesRevenues/constants';

export type GetDownloadPDFData = {
  bridgePeriodInMonths: number;
  bridgeAmount: number;
  monthsToShortfall: number | null;
  shortfallAmount: number;
  recurringExpenses: {
    name: string;
    type: Interval;
    amount: number;
  }[];
  recurringRevenues: {
    name: string;
    type: Interval;
    amount: number;
  }[];
  additionalExpenses: {
    name: string;
    amount: number;
  }[];
  additionalRevenues: {
    name: string;
    amount: number;
  }[];
  bankAccounts: string[];
};

export const getDownloadPdf = (data: GetDownloadPDFData) => {
  const url = '/me/liquidity/download_pdf';

  return api.post<ArrayBuffer>(
    url,
    {
      bridge_period_in_months: data.bridgePeriodInMonths,
      bridge_amount: data.bridgeAmount,
      months_to_shortfall: data.monthsToShortfall,
      shortfall_amount: data.shortfallAmount,
      additional_expenses: data.additionalExpenses,
      additional_revenues: data.additionalRevenues,
      recurring_expenses: data.recurringExpenses,
      recurring_revenues: data.recurringRevenues,
      bank_accounts: data.bankAccounts,
    },
    {
      responseType: 'arraybuffer',
    }
  );
};
