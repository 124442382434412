import React, { SVGAttributes } from 'react';

const IconDelete = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 9 12" {...props}>
    <path
      d="M.586 4.268l.651 6.545c.037.276 1.354 1.186 3.263 1.187 1.91-.001 3.227-.91 3.263-1.187l.652-6.545c-.997.565-2.487.832-3.915.832-1.427 0-2.918-.267-3.914-.832zM6.376.906l-.51-.57C5.67.051 5.458 0 5.043 0H3.958c-.414 0-.628.052-.824.335l-.509.571C1.104 1.176 0 1.89 0 2.417v.102C0 3.448 2.015 4.2 4.5 4.2S9 3.448 9 2.52v-.103C9 1.89 7.897 1.175 6.375.906zm-.65 1.698L5.091 1.8H3.908l-.633.804H2.27l1.25-1.513A.445.445 0 013.895.9h1.21c.15 0 .264.053.377.19a706.8 706.8 0 001.25 1.514H5.725z"
      fillRule="evenodd"
    />
  </svg>
);

export default IconDelete;
