import React, { useCallback } from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';
import { get, omit } from 'lodash';
import { arrayOf, bool, func, node, number, oneOfType, shape, string } from 'prop-types';

import { noop } from 'shared/utils';
import If from 'components/Conditions/If';
import SearchIcon from 'components/Form/SelectField/DownshiftSelect/components/SearchIcon';
import HiddenAutofillInput from 'components/HiddenAutofillInput/HiddenAutofillInput';

import RequiredAddon from '../RequiredAddon/RequiredAddon';

import styles from './TextField.module.css';

const blacklist = [
  'meta',
  'options',
  'password',
  'passwordConfirm',
  'names',
  'isRawField',
  'onlyPositive',
  'initialParsedValue',
  'isCurrencySignVisible',
  'parsedValue',
];

const TextField = (props) => {
  const {
    checker = noop,
    className,
    clearAction,
    defaultValue,
    disabled,
    disabledPlaceHolder,
    id,
    input = {},
    inputClassName,
    invalid = false,
    showValidationErrorText = false,
    label,
    labelClassName,
    labelContentClassName = '',
    placeholderClassName,
    meta,
    options: ignoredOptions,
    placeholder,
    onFocus = noop,
    onKeyUp = noop,
    required = false,
    shouldShowRequiredIcon = false,
    requiredClassName = '',
    selectIfDefault = false,
    type,
    validatePristine = false,
    isAutofillDisabled = true,
    dataId,
    isLabelHidden = false,
    isPlaceholderVisible = true,
    shouldLabelStayUp = false,
    isSearchIcon = false,
    icon,
    addon,
    inputProps = {},
    onClick = noop,
    onPaste,
    isCurrency = false,
    isDisabledWithUnderline = false,
    isCentered,
    ...rest
  } = props;
  const isFilled = Boolean(
    (input && input.value) || !!String(get(rest, 'value', '')).length || defaultValue
  );
  let inputField;
  const handleFocus = (e) => {
    if (selectIfDefault && rest.value === defaultValue && inputField) {
      inputField.select(); // Select field if current value equals the default value.
    }
    onFocus(e); // Call custom onFocus methods
    if (input && input.onFocus) {
      input.onFocus(e); // Call onFocus if provided as a child of input object (usually onFocus action from redux-form)
    }
  };
  const emptyDisabled = disabledPlaceHolder || '-';
  const displayedPlaceholder = disabled ? emptyDisabled : placeholder;
  const isInvalid =
    checker(props) ||
    invalid ||
    (validatePristine ? meta && meta.error : meta && meta.error && meta.dirty);

  const handlePaste = useCallback((e) => {
    e.preventDefault();

    const pastedText = e.clipboardData?.getData('text/plain') || '';
    document.execCommand('insertText', false, pastedText.replace(/\t/g, '    '));
  }, []);

  return (
    <>
      <div
        className={cx(styles.componentWrapper, { [styles.componentWrapperIsCentered]: isCentered })}
      >
        <div
          className={cx(styles.wrapper, className, {
            [styles.wrapperWithIcon]: !!icon,
            [styles.wrapperIsCentered]: isCentered,
          })}
        >
          <If ok={icon}>
            <InlineSvg src={icon} className={styles.icon} role="presentation" />
          </If>
          <input
            autoComplete="off"
            {...input}
            {...omit(rest, blacklist)}
            className={cx(styles.main, inputClassName, {
              [styles.mainDisabled]: disabled,
              [styles.withoutUnderlineWhenDisabled]: !isDisabledWithUnderline,
              [styles.mainInvalid]: isInvalid,
              [styles.mainFilled]: isFilled,
              [styles.mainHiddenLabel]: isLabelHidden,
              [styles.withClearButton]: clearAction,
              [styles.withSearchIcon]: clearAction,
              [styles.withoutLabel]: isLabelHidden,
              [styles.labelStaysUp]: shouldLabelStayUp,
              [styles.labelStaysUp]: isSearchIcon,
            })}
            data-id={dataId || rest['data-id']}
            defaultValue={defaultValue}
            disabled={disabled}
            placeholder={isPlaceholderVisible ? displayedPlaceholder : ''}
            type={type}
            id={id || rest.name}
            name={rest.name || input.name}
            onFocus={handleFocus}
            onKeyUp={onKeyUp}
            onClick={onClick}
            ref={(inputRef) => {
              inputField = inputRef;
            }}
            {...inputProps}
            onPaste={onPaste || handlePaste}
          />
          <label htmlFor={id || rest.name} className={cx(styles.label, labelClassName)}>
            <span
              className={cx(styles.labelContent, labelContentClassName, {
                [styles.labelContentEmpty]: !isFilled,
              })}
            >
              {!isLabelHidden && (
                <>
                  {label || placeholder}
                  <If ok={required || shouldShowRequiredIcon}>
                    <RequiredAddon className={requiredClassName} />
                  </If>
                </>
              )}
            </span>
          </label>
          {isAutofillDisabled && <HiddenAutofillInput name={rest.name || input.name} />}
          {isFilled && clearAction && (
            <div
              onClick={clearAction || noop}
              role="button"
              className={styles.clearField}
              onKeyPress={clearAction || noop}
              tabIndex="0"
            >
              ×
            </div>
          )}
          {isSearchIcon && (
            <div className={styles.searchIcon}>
              <SearchIcon />
            </div>
          )}
          <If ok={!isFilled}>
            <span className={cx(styles.iePlaceholder, placeholderClassName)}>
              {disabled ? emptyDisabled : placeholder}
            </span>
          </If>
        </div>
        {addon}
        {isCurrency && <span className={styles.euroIcon}>€</span>}
      </div>
      {showValidationErrorText && meta && <span className={styles.errorText}>{meta.error}</span>}
    </>
  );
};

TextField.propTypes = {
  checker: func,
  className: string,
  name: string,
  value: string,
  onChange: func,
  clearAction: func,
  defaultValue: oneOfType([number, string]),
  disabled: bool,
  disabledPlaceHolder: string,
  id: string,
  input: shape({}),
  inputClassName: string,
  invalid: bool,
  showValidationErrorText: bool,
  label: oneOfType([string, node]),
  labelClassName: string,
  labelContentClassName: string,
  placeholderClassName: string,
  meta: shape({}),
  options: oneOfType([arrayOf({}), shape({})]),
  placeholder: string,
  required: bool,
  requiredClassName: string,
  selectIfDefault: bool,
  type: string,
  wrapperClassName: string,
  onFocus: func,
  onKeyUp: func,
  onPaste: func,
  validatePristine: bool,
  dataId: string,
  icon: string,
  /** Removes a label and animation for it */
  isLabelHidden: bool,
  /** Should display a placeholder */
  isPlaceholderVisible: bool,
  /** Should label stay up even if the field is not focused */
  shouldLabelStayUp: bool,
  /**
   * Should input display required icon (*) even if required prop is set to false
   */
  shouldShowRequiredIcon: bool,
  /** Should display magnifying glass icon */
  isSearchIcon: bool,
  /** Disable autofill for a text field */
  isAutofillDisabled: bool,
  /** Addon that will be displayed on the right side of the input */
  addon: node,
  /** Call when input is clicked */
  onClick: func,
  /** Should disable euro sign after the input */
  isCurrency: bool,
  /** Props that will be spread over input */
  inputProps: shape({}),
  /** If underline should be displayed under input when input is disabled */
  isDisabledWithUnderline: bool,
  isCentered: bool,
};

TextField.defaultProps = {
  defaultValue: '',
};

export default TextField;
