import styled from 'styled-components';

export const Status = styled.div`
  display: flex;
  height: 30px;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  align-items: center;
  justify-content: flex-start;
  word-break: normal;
`;

export const Text = styled.div`
  text-align: center;
  margin-left: 6px;
  line-height: 1;
  color: ${({ theme }) => theme.legacy.colors.greenFont};
`;
