import React from 'react';
import cx from 'classnames';
import { arrayOf, bool, shape, string } from 'prop-types';

import {
  DATEV_CLIENTS_COLUMNS,
  DATEV_INCOMING_INVOICES_COLUMNS,
  DATEV_OUTGOING_INVOICES_COLUMNS,
  DATEV_PREVIEW_CLIENTS,
  DATEV_PREVIEW_INCOMING_INVOICES,
  DATEV_PREVIEW_OUTGOING_INVOICES,
  DATEV_PREVIEW_SUPPLIERS,
  DATEV_SUPPLIERS_COLUMNS,
} from 'constants/datev';
import styles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';
import If from 'components/Conditions/If';

import InvoiceHeader from './components/InvoiceHeader';
import InvoiceRow from './components/InvoiceRow';

const DatevPreviewTable = ({ type, data, isFetching, ...rest }) => (
  <table className={cx(styles.invoicesTable, { [styles.invoicesTableLoading]: isFetching })}>
    <thead>
      <tr>
        <If ok={type === DATEV_PREVIEW_OUTGOING_INVOICES}>
          {DATEV_OUTGOING_INVOICES_COLUMNS.map((column, index) => (
            <InvoiceHeader {...rest} {...column} label={t(column.label)} key={index} />
          ))}
        </If>
        <If ok={type === DATEV_PREVIEW_INCOMING_INVOICES}>
          {DATEV_INCOMING_INVOICES_COLUMNS.map((column, index) => (
            <InvoiceHeader {...rest} {...column} label={t(column.label)} key={index} />
          ))}
        </If>
        <If ok={type === DATEV_PREVIEW_CLIENTS}>
          {DATEV_CLIENTS_COLUMNS.map((column, index) => (
            <InvoiceHeader {...rest} {...column} label={t(column.label)} key={index} />
          ))}
        </If>
        <If ok={type === DATEV_PREVIEW_SUPPLIERS}>
          {DATEV_SUPPLIERS_COLUMNS.map((column, index) => (
            <InvoiceHeader {...rest} {...column} label={t(column.label)} key={index} />
          ))}
        </If>
      </tr>
    </thead>
    <tbody>
      {data.map((row) => (
        <InvoiceRow key={row.id} data={row} type={type} />
      ))}
    </tbody>
  </table>
);

DatevPreviewTable.propTypes = {
  type: string,
  data: arrayOf(shape({})),
  isFetching: bool,
};

export default DatevPreviewTable;
