import api from 'api';

export type GetOrderConfirmationData = {
  salutation_honorific: string;
  salutation_content: string;
  personal_notes: string;
};

export const getOrderConfirmation = (params: GetOrderConfirmationData) => {
  const url = '/me/previews/order_confirmation';

  return api.get<ArrayBuffer>(url, {
    params,
    responseType: 'arraybuffer',
  });
};
