import styled from 'styled-components';

export const BankDetails = styled.div`
  display: inline-block;
  margin-left: ${({ theme }) => theme.space['1']};
`;

export const BankDetailsCaption = styled.div`
  float: left;
  font-size: ${({ theme }) => theme.fontSize.sm};

  p {
    line-height: ${({ theme }) => theme.space['5']};
  }
`;

export const BankDetailsContent = styled.div`
  float: right;
  margin-left: ${({ theme }) => theme.space['4']};
  font-size: ${({ theme }) => theme.fontSize.base};

  p {
    line-height: ${({ theme }) => theme.space['5']};
  }
`;
