import React, { forwardRef } from 'react';

import { MenuItem, MenuWrapper, Title } from './AutoComplete.styled';

const Menu = forwardRef(({ isMenuOpen, options, highlightedIndex, onItemClick }, ref) => {
  const handleMenuItemMouseDown = (event) => {
    event.preventDefault();
  };

  return (
    isMenuOpen && (
      <MenuWrapper ref={ref}>
        <ul className="options-list">
          {options.map((option, index) => (
            <MenuItem
              key={index}
              id={`menu-item-${index}`}
              onMouseDown={handleMenuItemMouseDown}
              onClick={() => onItemClick(index)}
              $isHighlighted={index === highlightedIndex}
              $isAddCompanyOption={option.legal_entity_name === 'Unternehmen hinzufügen'}
            >
              <Title>{option.legal_entity_name}</Title>
            </MenuItem>
          ))}
        </ul>
      </MenuWrapper>
    )
  );
});

export default Menu;
