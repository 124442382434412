import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { debounce, find, includes, lowerCase } from 'lodash';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { fetchBankTransfers, fetchMoreBankTransfers } from 'actions/bank-transfer';
import { fetchAllBankTransfers, fetchMoreAllBankTransfers } from 'actions/incoming-invoice';
import { INCOMING_INVOICE, OUTGOING_INVOICE } from 'constants/folder-names';
import {
  PIWIK_ACTION_OPEN_BANK_TRANSFERS_DROPDOWN,
  PIWIK_CATEGORY_EXPENSES,
  PIWIK_CATEGORY_REVENUE,
} from 'constants/piwik';
import { BankTransferStatuses } from 'constants/statuses';
import { parseFilteredBankTransfers } from 'reducers/bank-transfers';
import { incomingInvoiceSelector, outgoingInvoiceSelector } from 'reducers/form';
import { noop, t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import DownshiftSelect from 'components/Form/SelectField/DownshiftSelect';
import I18n from 'components/I18n';

import BankTransferMenu from './components/Menu';

import styles from './BankTransfersSelect.module.css';

class BankTransfersSelect extends Component {
  state = {
    bankTransfers: [],
    isFetchingTransfers: false,
    pagination: {
      page: 1,
      perPage: 5,
      totalPages: 1,
    },
    filter: { findAsYouType: '', statusNot: BankTransferStatuses.FULLY_ASSIGNED },
  };

  getTransfersForCorrectFolder = ({
    fetchOutgoingInvoiceTransfersFunction,
    fetchIncomingInvoiceTransfersFunction,
  }) => {
    const { pagination, filter } = this.state;
    const { invoiceId, whichFolder } = this.props;
    if (whichFolder === OUTGOING_INVOICE)
      return fetchOutgoingInvoiceTransfersFunction(invoiceId, pagination, undefined, filter);
    if (whichFolder === INCOMING_INVOICE)
      return fetchIncomingInvoiceTransfersFunction(invoiceId, pagination, undefined, filter);
    return noop;
  };

  getBankTransfers = async () => {
    const { pagination } = this.state;
    const isLastPage = pagination.page >= pagination.totalPages && pagination.totalPages > 1;

    if (isLastPage) {
      return;
    }

    this.setState({ isFetchingTransfers: true });
    const { fetchOutgoingInvoiceTransfers, fetchIncomingInvoiceTransfers } = this.props;
    const { filter } = this.state;

    const { response, headers } = await this.getTransfersForCorrectFolder({
      fetchOutgoingInvoiceTransfersFunction: fetchOutgoingInvoiceTransfers,
      fetchIncomingInvoiceTransfersFunction: fetchIncomingInvoiceTransfers,
    });
    const totalPages = parseInt(headers['total-pages'], 10);

    this.setState({
      bankTransfers: parseFilteredBankTransfers({ response, ...filter }),
      isFetchingTransfers: false,
      pagination: { ...this.state.pagination, totalPages },
    });
  };

  getMoreBankTransfers = async () => {
    this.setState({ isFetchingTransfers: true });
    const { fetchOutgoingInvoiceMoreTransfers, fetchIncomingInvoiceMoreTransfers } = this.props;
    const { filter } = this.state;

    const { response, headers } = await this.getTransfersForCorrectFolder({
      fetchOutgoingInvoiceTransfersFunction: fetchOutgoingInvoiceMoreTransfers,
      fetchIncomingInvoiceTransfersFunction: fetchIncomingInvoiceMoreTransfers,
    });
    const newPagination = {
      page: parseInt(headers.page, 10),
      totalPages: parseInt(headers['total-pages'], 10),
    };

    this.setState({
      bankTransfers: [
        ...this.state.bankTransfers,
        ...parseFilteredBankTransfers({ response, ...filter }),
      ],
      isFetchingTransfers: false,
      pagination: { ...this.state.pagination, ...newPagination },
    });
  };

  handleFilterChange = (value) =>
    this.setState(
      (prevState) => ({ filter: { ...prevState.filter, findAsYouType: value } }),
      () => this.handlePaginationChange({ page: 1 })
    );

  handleInputChange = debounce(this.handleFilterChange, 200);

  handlePaginationChange = (pagination) => {
    if (this.state.isFetchingTransfers) return;

    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
      },
      this.getBankTransfers
    );
  };

  filterItems = (inputValue, item) => includes(lowerCase(item.purpose), lowerCase(inputValue));

  onOpen = () => {
    const { whichFolder } = this.props;
    const piwikCategory =
      whichFolder === OUTGOING_INVOICE ? PIWIK_CATEGORY_REVENUE : PIWIK_CATEGORY_EXPENSES;
    piwikHelpers.trackEvent(piwikCategory, PIWIK_ACTION_OPEN_BANK_TRANSFERS_DROPDOWN);

    this.getBankTransfers();
  };

  handleBlur = () => {
    this.setState({
      filter: { findAsYouType: '', statusNot: BankTransferStatuses.FULLY_ASSIGNED },
    });
  };

  render() {
    const {
      disabled,
      whichFolder,
      input,
      handleOptionSelect,
      selectedBankTransfers,
      status,
      ...rest
    } = this.props;
    const allTransfersWithPaidByCash = [
      { paidByCash: true },
      { paidByUnspecified: true },
      ...this.state.bankTransfers,
    ];

    const optionsWithoutAssigned = allTransfersWithPaidByCash
      .filter(({ id }) => !find(selectedBankTransfers, { id }))
      .map((option, index) => ({ ...option, dataId: `transfer-${index}` }));

    const { isFetchingTransfers, pagination } = this.state;

    const isLastPage = pagination.page >= pagination.totalPages;

    return (
      <div className={styles.bankTransfersSelect}>
        <DownshiftSelect
          {...rest}
          onBlur={this.handleBlur}
          isSearchable
          options={isFetchingTransfers ? [] : optionsWithoutAssigned}
          onInputChange={this.handleInputChange}
          onOpen={this.onOpen}
          name={this.props.name || input.name}
          isLoading={isFetchingTransfers}
          optionRenderer={(option) => (
            <BankTransferMenu
              option={option}
              whichFolder={whichFolder}
              onClick={() => handleOptionSelect(option)}
            />
          )}
          optionWrapperClassName={styles.option}
          getTrailingContent={() =>
            !isLastPage && (
              <button
                type="button"
                className={styles.fetchNext}
                onClick={() => this.getMoreBankTransfers()}
              >
                <I18n t="expenses.form.fetch_next_bank_transfers" />
              </button>
            )
          }
          placeholder={t('assigment.transfers_select_placeholder')}
          label={t('assigment.transfers_select_label')}
          disabled={disabled}
          containerClassName={cx({ [styles.bankTransferSelectDisabled]: disabled })}
        />
      </div>
    );
  }
}

BankTransfersSelect.propTypes = {
  disabled: bool,
  input: shape({}),
  name: string,
  fetchOutgoingInvoiceTransfers: func.isRequired,
  fetchOutgoingInvoiceMoreTransfers: func.isRequired,
  fetchIncomingInvoiceTransfers: func.isRequired,
  fetchIncomingInvoiceMoreTransfers: func.isRequired,
  invoiceId: string.isRequired,
  whichFolder: string.isRequired,
  handleOptionSelect: func,
  selectedBankTransfers: arrayOf(shape({})),
  status: string.isRequired,
};

const mapStateToProps = (state) => {
  const { incomingInvoice } = state;

  const selectedBankTransfers = state.outgoingInvoice.bankTransfers || [];

  const invoiceId =
    (incomingInvoice.details && incomingInvoice.details.id) || outgoingInvoiceSelector(state, 'id');
  const creditNote = incomingInvoiceSelector(state, 'creditNote');

  return {
    selectedBankTransfers,
    invoiceId,
    creditNote,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOutgoingInvoiceTransfers: (...args) => dispatch(fetchBankTransfers(...args)),
  fetchOutgoingInvoiceMoreTransfers: (...args) => dispatch(fetchMoreBankTransfers(...args)),
  fetchIncomingInvoiceTransfers: (...args) => dispatch(fetchAllBankTransfers(...args)),
  fetchIncomingInvoiceMoreTransfers: (...args) => dispatch(fetchMoreAllBankTransfers(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankTransfersSelect);
