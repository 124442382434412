import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as reduxPush } from 'connected-react-router';
import { get } from 'lodash';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { fetchExternalOriginCount as fetchExternalOriginCountAction } from 'actions/externalOriginCount';
import { getDraftId } from 'actions/outgoing-invoice';
import { MAX_REMINDERS_COUNT } from 'constants/incoming-invoice';
import { ACTION_DOWNLOAD_PDF, ACTION_DOWNLOAD_XML, ACTION_OPEN_MAIL_EDITOR } from 'constants/piwik';
import { InvoiceStatuses } from 'constants/statuses';
import { outgoingInvoiceSelector } from 'reducers/form';
import { paymentRemindersEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import ActionDropdown from 'components/ActionPanel/ActionDropdown';
import { ButtonAppearances } from 'components/Button';
import I18n from 'components/I18n';
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'components/LineItems';
import LoadingIcon from 'components/LoadingIcon';
import { InfoModal } from 'components/Modal';

import EmailEditor from '../../EmailEditor/EmailEditor';
import AbortButton from './AbortButton';
import PreviewButton from './PreviewButton';
import { trackEvent } from './utils';

import styles from './ActionsSection.module.css';

const invoiceTooltips = {
  Correction: {
    downloadPDFTooltip: t('revenue.correction_invoice.download_tooltip'),
    downloadXMLTooltip: t('revenue.form.download_xml_tooltip'),
    sendEmailTooltip: t('revenue.correction_invoice.send_email_tooltip'),
  },
  Default: {
    downloadPDFTooltip: t('revenue.form.download_tooltip'),
    downloadXMLTooltip: t('revenue.form.download_xml_tooltip'),
    sendEmailTooltip: t('revenue.form.send_email_tooltip'),
  },
};

class ActionsSection extends Component {
  state = {
    sendEmailModalVisible: false,
  };

  setSendMailModal = (value) => {
    this.setState({ sendEmailModalVisible: value });

    if (value) {
      trackEvent(ACTION_OPEN_MAIL_EDITOR);
    }
  };

  createReminder = () => this.props.push(paths.createPaymentReminder(this.props.invoiceId));

  sendActions = ({ invoiceActionMode = 'Default' }) => {
    const downloadActionPDF = {
      name: t('revenue.form.download'),
      onClick: this.downloadPDFInvoice,
      tooltip: invoiceTooltips[invoiceActionMode].downloadPDFTooltip,
      dataId: 'OutgoingInvoicePage:button-download-pdf',
    };

    const downloadActionXML = {
      name: t('revenue.form.download_xml'),
      onClick: this.downloadXMLInvoice,
      tooltip: invoiceTooltips[invoiceActionMode].downloadXMLTooltip,
      dataId: 'OutgoingInvoicePage:button-download-xml',
      isEnabled:
        !!this.props.currentClient?.einvoiceRecipientReference &&
        !!this.props.electronicInvoiceType,
    };

    const sendByEmailAction = {
      name: t('revenue.form.send_email'),
      onClick: () => this.setSendMailModal(true),
      tooltip: invoiceTooltips[invoiceActionMode].sendEmailTooltip,
      dataId: 'OutgoingInvoicePage:button-send-email',
    };

    return this.props.sendingDocumentsByEmailEnabled
      ? [downloadActionPDF, downloadActionXML, sendByEmailAction]
      : [downloadActionPDF, downloadActionXML];
  };

  downloadPDFInvoice = async () => {
    const result = await this.props.saveInvoice();

    if (result && result.success) {
      const { invoiceNumber } = result;
      this.props.downloadInvoice({ details: { number: invoiceNumber, type: 'pdf' } });
      this.props.fetchExternalOriginCount();
      trackEvent(ACTION_DOWNLOAD_PDF);
    }
  };

  downloadXMLInvoice = async () => {
    const result = await this.props.saveInvoice();

    if (result && result.success) {
      const { invoiceNumber } = result;
      this.props.downloadInvoice({ details: { number: invoiceNumber, type: 'xml' } });
      this.props.fetchExternalOriginCount();
      trackEvent(ACTION_DOWNLOAD_XML);
    }
  };

  sendInvoice = async ({ email, ccEmail }) => {
    this.setSendMailModal(false);
    const result = await this.props.saveInvoice();
    if (result.success) {
      this.props.sendInvoice({ email, ccEmail });
      this.props.fetchExternalOriginCount();
    }
  };

  redirectAfterCreation = () => {
    this.props.push(paths.outgoingInvoices);
  };

  render() {
    const {
      abort,
      isNewInvoice,
      invoiceStatus,
      invoiceAmendmentType,
      isInvoiceLoaded,
      paymentReminders,
      saveInvoice,
      saveInvoiceAsDraft,
      isFormSubmitting,
      needsAnotherDatevReport,
      paymentRemindersEnabled,
      isProfileFilled,
      currentClient,
      transformIntoDeliveryNote,
      invoiceDetails,
      disabledSubmitButton,
      canSubmit,
    } = this.props;

    if (!isInvoiceLoaded) {
      return (
        <ActionPanel className={styles.creatorActionsSection} wrapped>
          <LoadingIcon staticPosition />
        </ActionPanel>
      );
    }
    const permittedActions = get(invoiceDetails, 'actions', {});
    const isSavingDisabled = isFormSubmitting || disabledSubmitButton;
    const isDraft = invoiceStatus === 'draft';
    const isSendable = (isNewInvoice && !isSavingDisabled) || !isNewInvoice;
    const isCorrection = invoiceAmendmentType === 'correction';
    const canCreateReminder =
      (invoiceStatus === InvoiceStatuses.OVERDUE ||
        invoiceStatus === InvoiceStatuses.PAYMENT_REMINDED) &&
      paymentReminders.length < MAX_REMINDERS_COUNT;
    const isTransformableIntoDeliveryNote = permittedActions['transform-into-delivery-note'];
    const { alreadyCreated, showDialog } = needsAnotherDatevReport;
    const { sendEmailModalVisible } = this.state;

    if (alreadyCreated && !showDialog && !sendEmailModalVisible) {
      this.redirectAfterCreation();
    }

    return (
      <ActionPanel className={cx(styles.creatorActionsSection, IGNORE_OUTSIDE_CLICK_CLASS)} wrapped>
        <ActionButton
          dataId="OutgoingInvoicePage:button-save-as-draft"
          visible={isDraft && !isCorrection}
          onClick={saveInvoiceAsDraft}
          disabled={!canSubmit || isSavingDisabled}
          label={t('revenue.form.save_draft')}
        />
        <ActionButton
          dataId="OutgoingInvoicePage:button-send-reminder"
          appearance={ButtonAppearances.primary}
          visible={paymentRemindersEnabled && canCreateReminder}
          onClick={this.createReminder}
          label={t('revenue.form.create_reminder')}
          disabled={!canSubmit}
        />
        <ActionDropdown
          upwards
          visible={isDraft}
          disabled={!canSubmit || !isSendable || !isProfileFilled}
          actions={this.sendActions({ invoiceActionMode: this.props.invoiceActionMode })}
          title={t('revenue.form.download_or_send')}
          dataId="OutgoingInvoicePage:button-actions-dropdown"
        />
        <ActionButton
          dataId="OutgoingInvoicePage:button-save"
          appearance={ButtonAppearances.primary}
          visible={!isDraft}
          disabled={!canSubmit || !isSendable}
          onClick={saveInvoice}
          label={t('revenue.form.save_invoice')}
        />
        <ActionButton
          dataId="OutgoingInvoicePage:button-save"
          appearance={ButtonAppearances.primary}
          disabled={!canSubmit}
          visible={isTransformableIntoDeliveryNote}
          onClick={transformIntoDeliveryNote}
          label={'In Lieferschein überführen'}
        />
        <PreviewButton dataId="OutgoingInvoicePage:button-preview" visible={isDraft} />
        <AbortButton dataId="OutgoingInvoicePage:button-abort" onClick={abort} />
        <InfoModal
          isOpen={showDialog}
          onClose={this.redirectAfterCreation}
          message={t('modals.needs_another_datev_report.message')}
          header={t('modals.needs_another_datev_report.title')}
        >
          <I18n t="modals.needs_another_datev_report.message" />
        </InfoModal>
        {sendEmailModalVisible && (
          <EmailEditor
            onClose={() => this.setSendMailModal(false)}
            client={currentClient}
            title={t('modals.outgoing_invoice.send_email.title')}
            saveInvoice={saveInvoice}
          />
        )}
      </ActionPanel>
    );
  }
}

ActionsSection.propTypes = {
  isNewInvoice: bool.isRequired,
  isInvoiceLoaded: bool.isRequired,
  invoiceId: number,
  invoiceStatus: string,
  invoiceAmendmentType: string,
  saveInvoice: func.isRequired,
  downloadInvoice: func.isRequired,
  paymentReminders: arrayOf(shape({})),
  sendInvoice: func.isRequired,
  saveInvoiceAsDraft: func.isRequired,
  transformIntoDeliveryNote: func.isRequired,
  abort: func.isRequired,
  isFormSubmitting: bool.isRequired,
  push: func.isRequired,
  needsAnotherDatevReport: shape({
    alreadyCreated: bool,
    showDialog: bool,
  }),
  currentClient: shape(),
  sendingDocumentsByEmailEnabled: bool,
  paymentRemindersEnabled: bool,
  invoiceActionMode: string,
  fetchExternalOriginCount: func,
  isProfileFilled: bool.isRequired,
  disabledSubmitButton: bool,
};

ActionsSection.defaultProps = {
  paymentReminders: [],
};

const mapStateToProps = (state) => ({
  invoiceId: state.outgoingInvoice.details.id,
  invoiceStatus: state.outgoingInvoice.details.status,
  invoiceDetails: state.outgoingInvoice.details,
  needsAnotherDatevReport: state.outgoingInvoice.postCreateStatus,
  invoiceAmendmentType: state.outgoingInvoice.details.amendmentType,
  isInvoiceLoaded: !!state.outgoingInvoice.details.id,
  isNewInvoice: state.outgoingInvoice.details.id === getDraftId(),
  paymentReminders: state.outgoingInvoice.paymentReminders,
  paymentRemindersEnabled: paymentRemindersEnabledHelper(state),
  isProfileFilled: state.onboarding.data?.profileFilled,
  electronicInvoiceType: outgoingInvoiceSelector(state, 'electronicInvoiceType'),
  disabledSubmitButton: state.outgoingInvoice.details.disabled,
});

const mapDispatchToProps = {
  push: reduxPush,
  fetchExternalOriginCount: fetchExternalOriginCountAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsSection);
