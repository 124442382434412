import React from 'react';

import { Container, Heading } from './FormSection.styled';

export type FormSectionProps = {
  children: React.ReactElement;
  heading?: string;
};

const FormSection = ({ children, heading }: FormSectionProps) => {
  return (
    <Container>
      {heading && <Heading>{heading}</Heading>}
      {children}
    </Container>
  );
};

export default FormSection;
