import { paginationParams, sortingParams } from 'actions/helpers/table';
import { CALL_API } from 'constants/api';
import {
  INDEX_CLIENTS_FAILURE,
  INDEX_CLIENTS_REQUEST,
  INDEX_CLIENTS_SUCCESS,
  INDEX_INCOMING_INVOICES_FAILURE,
  INDEX_INCOMING_INVOICES_REQUEST,
  INDEX_INCOMING_INVOICES_SUCCESS,
  INDEX_OUTGOING_INVOICES_FAILURE,
  INDEX_OUTGOING_INVOICES_REQUEST,
  INDEX_OUTGOING_INVOICES_SUCCESS,
  INDEX_SUPPLIERS_FAILURE,
  INDEX_SUPPLIERS_REQUEST,
  INDEX_SUPPLIERS_SUCCESS,
} from 'constants/datev';
import { apiErrorHandler } from 'shared/utils/error-handlers';

const apiPreviewIncomingInvoices = (pagination = {}, sorting = {}, params = {}) => ({
  [CALL_API]: {
    endpoint: '/me/datev_reports/incoming_invoices',
    params: {
      ...params,
      ...sortingParams(sorting),
      ...paginationParams(pagination),
    },
    types: [
      INDEX_INCOMING_INVOICES_REQUEST,
      INDEX_INCOMING_INVOICES_SUCCESS,
      INDEX_INCOMING_INVOICES_FAILURE,
    ],
  },
});

const apiPreviewOutgoingInvoices = (pagination = {}, sorting = {}, params = {}) => ({
  [CALL_API]: {
    endpoint: '/me/datev_reports/outgoing_invoices',
    params: {
      ...params,
      ...sortingParams(sorting),
      ...paginationParams(pagination),
    },
    types: [
      INDEX_OUTGOING_INVOICES_REQUEST,
      INDEX_OUTGOING_INVOICES_SUCCESS,
      INDEX_OUTGOING_INVOICES_FAILURE,
    ],
  },
});

const apiPreviewClients = (pagination = {}, sorting = {}, params = {}) => ({
  [CALL_API]: {
    endpoint: '/me/datev_reports/clients',
    params: {
      ...params,
      ...sortingParams(sorting),
      ...paginationParams(pagination),
    },
    types: [INDEX_CLIENTS_REQUEST, INDEX_CLIENTS_SUCCESS, INDEX_CLIENTS_FAILURE],
  },
});

const apiPreviewSuppliers = (pagination = {}, sorting = {}, params = {}) => ({
  [CALL_API]: {
    endpoint: '/me/datev_reports/suppliers',
    params: {
      ...params,
      ...sortingParams(sorting),
      ...paginationParams(pagination),
    },
    types: [INDEX_SUPPLIERS_REQUEST, INDEX_SUPPLIERS_SUCCESS, INDEX_SUPPLIERS_FAILURE],
  },
});

const apiIndexIncomingInvoices = (datevReportId, pagination = {}, sorting = {}) => ({
  [CALL_API]: {
    endpoint: `/me/datev_reports/${datevReportId}/incoming_invoices`,
    params: {
      ...sortingParams(sorting),
      ...paginationParams(pagination),
    },
    types: [
      INDEX_INCOMING_INVOICES_REQUEST,
      INDEX_INCOMING_INVOICES_SUCCESS,
      INDEX_INCOMING_INVOICES_FAILURE,
    ],
  },
});

const apiIndexOutgoingInvoices = (datevReportId, pagination = {}, sorting = {}) => ({
  [CALL_API]: {
    endpoint: `/me/datev_reports/${datevReportId}/outgoing_invoices`,
    params: {
      ...sortingParams(sorting),
      ...paginationParams(pagination),
    },
    types: [
      INDEX_OUTGOING_INVOICES_REQUEST,
      INDEX_OUTGOING_INVOICES_SUCCESS,
      INDEX_OUTGOING_INVOICES_FAILURE,
    ],
  },
});

const apiIndexClients = (datevReportId, pagination = {}, sorting = {}) => ({
  [CALL_API]: {
    endpoint: `/me/datev_reports/${datevReportId}/clients`,
    params: {
      ...sortingParams(sorting),
      ...paginationParams(pagination),
    },
    types: [INDEX_CLIENTS_REQUEST, INDEX_CLIENTS_SUCCESS, INDEX_CLIENTS_FAILURE],
  },
});

const apiIndexSuppliers = (datevReportId, pagination = {}, sorting = {}) => ({
  [CALL_API]: {
    endpoint: `/me/datev_reports/${datevReportId}/suppliers`,
    params: {
      ...sortingParams(sorting),
      ...paginationParams(pagination),
    },
    types: [INDEX_SUPPLIERS_REQUEST, INDEX_SUPPLIERS_SUCCESS, INDEX_SUPPLIERS_FAILURE],
  },
});

// Preview actions are used when creating new DATEV report
export const previewIncomingInvoices =
  (...args) =>
  (dispatch) =>
    dispatch(apiPreviewIncomingInvoices(...args)).catch(apiErrorHandler);

export const previewOutgoingInvoices =
  (...args) =>
  (dispatch) =>
    dispatch(apiPreviewOutgoingInvoices(...args)).catch(apiErrorHandler);

export const previewClients =
  (...args) =>
  (dispatch) =>
    dispatch(apiPreviewClients(...args)).catch(apiErrorHandler);

export const previewSuppliers =
  (...args) =>
  (dispatch) =>
    dispatch(apiPreviewSuppliers(...args)).catch(apiErrorHandler);

// Index actions are used for already existing DATEV reports
export const indexIncomingInvoices =
  (...args) =>
  (dispatch) =>
    dispatch(apiIndexIncomingInvoices(...args)).catch(apiErrorHandler);

export const indexOutgoingInvoices =
  (...args) =>
  (dispatch) =>
    dispatch(apiIndexOutgoingInvoices(...args)).catch(apiErrorHandler);

export const indexClients =
  (...args) =>
  (dispatch) =>
    dispatch(apiIndexClients(...args)).catch(apiErrorHandler);

export const indexSuppliers =
  (...args) =>
  (dispatch) =>
    dispatch(apiIndexSuppliers(...args)).catch(apiErrorHandler);
