export enum AlertMessage {
  AddAtLeastOneAccount = 'add_at_least_one_account',
  ChooseAtLeastOneAccount = 'choose_at_least_one_account',
  UpdateAtLeastOneAccount = 'update_at_least_one_account',
}

export const FormName = 'liquidityCalculator';

export const SliderValues = {
  max: 6,
  default: 6,
};
