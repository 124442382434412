import React from 'react';
import { bool, string } from 'prop-types';
import { Field } from 'redux-form';

import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import Card from 'components/Card';
import TextField from 'components/Form/TextareaField/TextareaField';

import styles from './Remark.module.css';

const Remark = ({ readonly, dataId = '', hint = t('revenue.form.remark_hint') }) => (
  <Card className={styles.root}>
    <div className={styles.formGroup}>
      <div className={styles.labelGroup}>
        <label htmlFor="remark">{t('revenue.form.remark')}</label>
      </div>
      <p className={styles.note}>{hint}</p>
      <Field
        id="remark"
        fullHeight
        name="remark"
        className={styles.textarea}
        component={TextField}
        checker={checker}
        disabled={readonly}
        dataId={dataId}
      />
    </div>
  </Card>
);

Remark.propTypes = {
  hint: string,
  readonly: bool,
  dataId: string,
};

export default Remark;
