import React, { useEffect, useState } from 'react';

import { updateThreshold as updateThresholdAction } from 'actions/quarterly-summary';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import FieldWrapper from 'redesign/components/molecules/FieldWrapper/FieldWrapper';
import InputField from 'redesign/components/molecules/InputField/InputField';

import useDebounce from './hooks';
import { FieldControl, LiquidityLimitContainer } from './LiquidityDangerControl.styled';

type LiquidityDangerControlProps = {
  liquidityDangerThreshold: number;
  dataId?: string;
};

const LiquidityDangerControl = ({
  liquidityDangerThreshold,
  dataId,
}: LiquidityDangerControlProps) => {
  const dispatch = useAppDispatch();
  const updateThreshold = (value: number | string) => dispatch(updateThresholdAction(value));

  const [isFetching, setIsFetching] = useState(false);
  const [threshold, setThreshold] = useState<number | string>(liquidityDangerThreshold);
  const debouncedThresholdValue = useDebounce<number | string>(threshold, 500);

  const updateThresholdSubmit = () => {
    setIsFetching(true);
    try {
      updateThreshold(debouncedThresholdValue);
    } catch (e) {
      setThreshold(liquidityDangerThreshold);
    }
    setIsFetching(false);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setThreshold(event.target.value);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      updateThresholdSubmit();
    }
  };

  useEffect(() => {
    if (debouncedThresholdValue) {
      updateThresholdSubmit();
    }
  }, [debouncedThresholdValue]);

  return (
    <LiquidityLimitContainer data-id={dataId}>
      <FieldControl>
        <FieldWrapper
          type="number"
          label={t('dashboard.liquidity_chart.threshold.label')}
          field={InputField}
          onChange={onChange}
          onKeyPress={onKeyDown}
          value={threshold}
          icon="€"
          alignText="right"
          disabled={isFetching}
          tooltipMessage={t('dashboard.liquidity_chart.threshold.tooltipMessage')}
        />
      </FieldControl>
    </LiquidityLimitContainer>
  );
};

export default LiquidityDangerControl;
