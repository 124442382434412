export const DATEV = 'DATEV';
export const DE_DATEV_NEW_EXPORT = 'neuen Export erstellen';

export const EDIT_REQUEST = `${DATEV}/EDIT_REQUEST`;
export const EDIT_SUCCESS = `${DATEV}/EDIT_SUCCESS`;
export const EDIT_FAILURE = `${DATEV}/EDIT_FAILURE`;

export const INDEX_REQUEST = `${DATEV}/INDEX_REQUEST`;
export const INDEX_SUCCESS = `${DATEV}/INDEX_SUCCESS`;
export const INDEX_FAILURE = `${DATEV}/INDEX_FAILURE`;

export const FETCH_REQUEST = `${DATEV}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${DATEV}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${DATEV}/FETCH_FAILURE`;

export const EXPORT_REQUEST = `${DATEV}/EXPORT_REQUEST`;
export const EXPORT_SUCCESS = `${DATEV}/EXPORT_SUCCESS`;
export const EXPORT_FAILURE = `${DATEV}/EXPORT_FAILURE`;
export const EXPORT_FINISHED = `${DATEV}/EXPORT_FINISHED`;
export const CLEAR_EXPORT_STATUSES = `${DATEV}/CLEAR_EXPORT_STATUSES`;

export const INDEX_SORT = `${DATEV}/INDEX_SORT`;

export const INDEX_INCOMING_INVOICES_REQUEST = `${DATEV}/INDEX_INCOMING_INVOICES_REQUEST`;
export const INDEX_INCOMING_INVOICES_SUCCESS = `${DATEV}/INDEX_INCOMING_INVOICES_SUCCESS`;
export const INDEX_INCOMING_INVOICES_FAILURE = `${DATEV}/INDEX_INCOMING_INVOICES_FAILURE`;

export const INDEX_OUTGOING_INVOICES_REQUEST = `${DATEV}/INDEX_OUTGOING_INVOICES_REQUEST`;
export const INDEX_OUTGOING_INVOICES_SUCCESS = `${DATEV}/INDEX_OUTGOING_INVOICES_SUCCESS`;
export const INDEX_OUTGOING_INVOICES_FAILURE = `${DATEV}/INDEX_OUTGOING_INVOICES_FAILURE`;

export const INDEX_CLIENTS_REQUEST = `${DATEV}/INDEX_CLIENTS_REQUEST`;
export const INDEX_CLIENTS_SUCCESS = `${DATEV}/INDEX_CLIENTS_SUCCESS`;
export const INDEX_CLIENTS_FAILURE = `${DATEV}/INDEX_CLIENTS_FAILURE`;

export const INDEX_SUPPLIERS_REQUEST = `${DATEV}/INDEX_SUPPLIERS_REQUEST`;
export const INDEX_SUPPLIERS_SUCCESS = `${DATEV}/INDEX_SUPPLIERS_SUCCESS`;
export const INDEX_SUPPLIERS_FAILURE = `${DATEV}/INDEX_SUPPLIERS_FAILURE`;

export const FETCH_DATES_REQUEST = `${DATEV}/DATES_REQUEST`;
export const FETCH_DATES_SUCCESS = `${DATEV}/DATES_SUCCESS`;
export const FETCH_DATES_FAILURE = `${DATEV}/DATES_FAILURE`;

export const FETCH_CREDS_REQUEST = `${DATEV}/CREDS_REQUEST`;
export const FETCH_CREDS_SUCCESS = `${DATEV}/CREDS_SUCCESS`;
export const FETCH_CREDS_FAILURE = `${DATEV}/CREDS_FAILURE`;

export const VALIDATION_REQUEST = `${DATEV}/VALIDATION_REQUEST`;
export const VALIDATION_SUCCESS = `${DATEV}/VALIDATION_SUCCESS`;
export const VALIDATION_FAILURE = `${DATEV}/VALIDATION_FAILURE`;

export const TABLE_TYPES = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
};
export const SET_END_DATE_ERROR = `${DATEV}/SET_END_DATE_ERROR`;
export const REMOVE_END_DATE_ERROR = `${DATEV}/REMOVE_END_DATE_ERROR`;
export const SET_MISSING_DATES_ERROR = `${DATEV}/SET_MISSING_DATES_ERROR`;
export const REMOVE_MISSING_DATES_ERROR = `${DATEV}/REMOVE_MISSING_DATES_ERROR`;
export const SET_START_AND_END_DATE_ERROR = `${DATEV}/SET_START_AND_END_DATE_ERROR`;
export const REMOVE_START_AND_END_DATE_ERROR = `${DATEV}/REMOVE_START_AND_END_DATE_ERROR`;

export const ENTER_EDIT_INVOICE_MODE = `${DATEV}/SET_EDIT_INVOICE_MODE`;
export const SET_VIEW_INVOICE_MODE = `${DATEV}/SET_VIEW_INVOICE_MODE`;
export const SET_DELETE_INVOICE_MODE = `${DATEV}/SET_DELETE_INVOICE_MODE`;
export const EXIT_EDIT_INVOICE_MODE = `${DATEV}/EXIT_EDIT_INVOICE_MODE`;
export const EXIT_VIEW_INVOICE_MODE = `${DATEV}/EXIT_VIEW_INVOICE_MODE`;
export const EXIT_DELETE_INVOICE_MODE = `${DATEV}/EXIT_DELETE_INVOICE_MODE`;

export const DATEV_PREVIEW_TABLE_COUNT_RESET = `${DATEV}/PREVIEW_TABLE_COUNT_RESET`;
export const DATEV_PREVIEW_INCOMING_INVOICES = 'incoming_invoices';
export const DATEV_PREVIEW_OUTGOING_INVOICES = 'outgoing_invoices';
export const DATEV_PREVIEW_CLIENTS = 'clients';
export const DATEV_PREVIEW_SUPPLIERS = 'suppliers';

export const DATEV_OUTGOING_INVOICES_COLUMNS = [
  {
    column: 'status',
    label: 'datev.creator.table.headings.status',
    sortable: true,
  },
  {
    column: 'client',
    label: 'datev.creator.table.headings.client',
    sortable: true,
  },
  {
    column: 'number',
    label: 'datev.creator.table.headings.number',
    sortable: true,
  },
  {
    column: 'subject',
    label: 'datev.creator.table.headings.subject',
    sortable: true,
  },
  {
    column: 'creation_date',
    label: 'datev.creator.table.headings.creation_date',
    sortable: true,
    align: 'center',
  },
  {
    column: 'due_date',
    label: 'datev.creator.table.headings.due_date',
    sortable: true,
    align: 'center',
  },
  {
    column: 'total_gross_amount',
    label: 'datev.creator.table.headings.total_gross_amount',
    sortable: true,
    align: 'right',
  },
];

export const DATEV_INCOMING_INVOICES_COLUMNS = [
  {
    column: 'status',
    label: 'datev.creator.table.headings.status',
    sortable: true,
  },
  {
    column: 'supplier',
    label: 'datev.creator.table.headings.supplier',
    sortable: true,
  },
  {
    column: 'number',
    label: 'datev.creator.table.headings.number',
    sortable: true,
  },
  {
    column: 'name',
    label: 'datev.creator.table.headings.name',
    sortable: true,
  },
  {
    column: 'invoice_date',
    label: 'datev.creator.table.headings.invoice_date',
    sortable: true,
    align: 'center',
  },
  {
    column: 'due_date',
    label: 'datev.creator.table.headings.due_date',
    sortable: true,
    align: 'center',
  },
  {
    column: 'total_gross_amount',
    label: 'datev.creator.table.headings.total_gross_amount',
    sortable: true,
    align: 'right',
  },
];

export const DATEV_CLIENTS_COLUMNS = [
  { column: 'company_name', label: 'datev.creator.table.headings.company_name', sortable: true },
  { column: 'city', label: 'datev.creator.table.headings.city', sortable: true },
  { column: 'honorific', label: 'datev.creator.table.headings.honorific', sortable: true },
  { column: 'first_name', label: 'datev.creator.table.headings.first_name', sortable: true },
  { column: 'last_name', label: 'datev.creator.table.headings.last_name', sortable: true },
  { column: 'id_number', label: 'datev.creator.table.headings.client_number', sortable: true },
];

export const DATEV_SUPPLIERS_COLUMNS = [
  { column: 'company_name', label: 'datev.creator.table.headings.company_name', sortable: true },
  { column: 'city', label: 'datev.creator.table.headings.city', sortable: true },
  { column: 'honorific', label: 'datev.creator.table.headings.honorific', sortable: true },
  { column: 'first_name', label: 'datev.creator.table.headings.first_name', sortable: true },
  { column: 'last_name', label: 'datev.creator.table.headings.last_name', sortable: true },
  { column: 'id_number', label: 'datev.creator.table.headings.supplier_number', sortable: true },
];
