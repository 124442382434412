import { CALL_API } from 'constants/api';
import {
  FETCH_SUMS_FAILURE,
  FETCH_SUMS_REQUEST,
  FETCH_SUMS_SUCCESS,
} from 'constants/delivery-note';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiFetchDeliveryNoteSums = (invoiceId) => ({
  [CALL_API]: {
    endpoint: `/me/delivery_notes/${invoiceId}`,
    types: [FETCH_SUMS_REQUEST, FETCH_SUMS_SUCCESS, FETCH_SUMS_FAILURE],
  },
});

export const fetchDeliveryNoteSums =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchDeliveryNoteSums(...args)).catch(apiErrorHandler);
