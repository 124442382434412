import React, { memo, SVGProps } from 'react';

const ArrowRedirect = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        d="M8.233 1.162l-.077.088a1 1 0 00.077 1.327l4.699 4.699H1.766l-.126.008a1 1 0 00.126 1.993l11.08-.001-5.32 5.32-.077.089a1 1 0 001.492 1.326l7.778-7.778-7.072-7.071-.086-.077a.998.998 0 00-1.328.077z"
        id="arrow-redirect_(1)_svg__a"
      />
    </defs>
    <use
      fill="currentColor"
      xlinkHref="#arrow-redirect_(1)_svg__a"
      transform="translate(3.5 4)"
      fillRule="evenodd"
    />
  </svg>
);

export default memo(ArrowRedirect);
