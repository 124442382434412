import React, { memo, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import cx from 'classnames';

import { useAppDispatch, useAppSelector } from 'shared/hooks/app';
import { useIsXLgUp } from 'shared/hooks/useMedia';
import { t } from 'shared/utils';
import { getContractsOnboardingDismissed } from 'store/slices/onboarding/selectors';
import { fetchOnboarding, updateOnboarding } from 'store/slices/onboarding/thunks';
import Button from 'components/Button';
import Card from 'components/Card';
import DismissOnboardingCheckbox from 'components/DismissOnboardingCheckbox/DismissOnboardingCheckbox';

import BankAccount from './BankAccount';
import IconBackground from './IconBackground';
import IconNext from './IconNext';
import IconPrevious from './IconPrevious';
import Security from './Security';
import Settings from './Settings';

import styles from './WelcomeTile.module.css';

const SliderSettings = {
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  adaptiveHeight: false,
  swipe: true,
  infinite: true,
  initialSlide: 0,
};

const WelcomeTile = () => {
  const dispatch = useAppDispatch();
  const isXLgUp = useIsXLgUp();
  const sliderRef = useRef<Slider>(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const isDismissed = useAppSelector(getContractsOnboardingDismissed);
  const isTileHidden = isLoading || isDismissed || !isVisible;

  const dismissTile = () => dispatch(updateOnboarding({ contracts_onboarding_dismissed: true }));

  const hideTile = () => setIsVisible(false);

  const goToNext = () => {
    if (!sliderRef || !sliderRef.current) return;

    return sliderRef.current.slickNext();
  };

  const goToPrevious = () => {
    if (!sliderRef || !sliderRef.current) return;

    return sliderRef.current.slickPrev();
  };

  const handleButtonClick = () => {
    dismissTile();
    hideTile();
  };

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchOnboarding());
      setIsLoading(false);
    };

    fetch();
  }, [dispatch]);

  if (isTileHidden) {
    return null;
  }

  return (
    <section data-id="welcome-tile">
      <Card className={styles.card}>
        <header className={styles.title}>
          {t('contracts.welcome_tile.title.1')}
          <span className={styles.highlighted}>{t('contracts.welcome_tile.title.2')}</span>
          {t('contracts.welcome_tile.title.3')}
        </header>
        {isXLgUp ? (
          <main className={styles.columnsContainer}>
            <div className={styles.columns}>
              <div className={styles.securityColumn}>
                <Security />
              </div>
              <div className={styles.bankAccountColumn}>
                <BankAccount />
              </div>
              <div className={styles.settingsColumn}>
                <Settings />
              </div>
            </div>
            <IconBackground className={styles.iconBg} />
          </main>
        ) : (
          <main className={styles.sliderContainer}>
            <div className={cx(styles.navContainer, styles.navLeft)}>
              <button type="button" className={styles.navButton} onClick={goToPrevious}>
                <IconPrevious className={styles.iconPrevious} />
              </button>
            </div>
            <Slider className={styles.slider} {...SliderSettings} ref={sliderRef}>
              <div>
                <Security />
              </div>
              <div>
                <BankAccount />
              </div>
              <div>
                <Settings />
              </div>
            </Slider>
            <div className={cx(styles.navContainer, styles.navRight)}>
              <button type="button" className={styles.navButton} onClick={goToNext}>
                <IconNext className={styles.iconNext} />
              </button>
            </div>
          </main>
        )}
        <Button
          className={styles.button}
          onClick={handleButtonClick}
          label={t('contracts.welcome_tile.button')}
          data-id={'button-close-big'}
        />
        <DismissOnboardingCheckbox
          onClose={hideTile}
          dismissOnboardingKey="contracts_onboarding_dismissed"
          id="dismiss-contracts-onboarding"
          dataIds={{
            dismissCheckbox: 'checkbox-dismiss',
            closeButton: 'button-close',
          }}
        />
        <p className={styles.disclaimer}>{t('contracts.welcome_tile.note')}</p>
      </Card>
    </section>
  );
};

export default memo(WelcomeTile);
