import React, { ChangeEvent, ComponentType, FunctionComponent, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { snakeCase } from 'lodash';
import { change, Field } from 'redux-form';

import { setCursorPosition as setCursorPositionAction } from 'actions/number-ranges';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';

interface FormFieldDispatchProps {
  change: (formName: string, name: string, value: string) => void;
  setCursor: (x: number) => void;
}

interface FormFieldOwnProps {
  name: string;
  className: string;
  component: ComponentType;
  required?: boolean;
  disabled?: boolean;
  dataId?: string;
}

type FormFieldProps = FormFieldDispatchProps & FormFieldOwnProps;

interface onKeyUpEvent {
  target: {
    value: string;
    selectionStart: number;
  };
}

interface OnChangeParams {
  (
    event: React.ChangeEvent<HTMLInputElement>,
    setCursor: (x: number) => void,
    change: (formName: string, name: string, value: string) => void,
    name: string
  ): void;
}

const FORM_NAME = 'numberRanges';

const onChange: OnChangeParams = (event, setCursor, change, name) => {
  const { target: { value = '', selectionStart = 0 } = {} } = event;

  change(FORM_NAME, name, value);
  if (selectionStart) setCursor(selectionStart);
};

const FormField: FunctionComponent<FormFieldProps> = ({
  name,
  setCursor,
  dataId = '',
  change,
  ...rest
}) => (
  <div>
    <Field
      name={name}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, setCursor, change, name)}
      props={{
        id: name,
        checker: serverValidationChecker,
        label: t(`profile.number_ranges.form.${snakeCase(name)}`),
        onKeyUp: (e: onKeyUpEvent) => setCursor(e.target.selectionStart),
        onClick: (e: MouseEvent<HTMLInputElement>) => {
          const target = e.target as HTMLInputElement;
          const { selectionStart } = target;
          selectionStart && setCursor(selectionStart);
        },
      }}
      dataId={dataId}
      {...rest}
    />
  </div>
);

export default connect<{}, FormFieldDispatchProps, FormFieldOwnProps>(null, {
  setCursor: setCursorPositionAction,
  change,
})(FormField);
