import React from 'react';
import InlineSvg from 'react-inlinesvg';
import { string } from 'prop-types';

import { BASIC_UID, PREMIUM_UID } from 'constants/payment-plans';
import iconControll from 'images/icon-controll.svg';
import iconOverview from 'images/icon-overview.svg';
import iconTime from 'images/icon-time.svg';
import { t } from 'shared/utils';

import Section from './components/Section';

const BasicSection = () => (
  <>
    <Section
      headerTitle={t('profile.payment_plan.basic_section.header')}
      options={[
        {
          title: t('profile.payment_plan.basic_section.checkmark_one'),
        },
        {
          title: t('profile.payment_plan.basic_section.checkmark_two'),
        },
        {
          title: t('profile.payment_plan.basic_section.checkmark_three'),
        },
        {
          title: t('profile.payment_plan.basic_section.checkmark_four'),
        },
        {
          title: t('profile.payment_plan.basic_section.checkmark_five'),
        },
      ]}
    />
  </>
);

const PremiumSection = () => (
  <>
    <Section
      headerTitle={t('profile.payment_plan.premium_section.section_one.header')}
      icon={<InlineSvg src={iconOverview} />}
      options={[{ title: t('profile.payment_plan.premium_section.section_one.checkmark_one') }]}
    />
    <Section
      headerTitle={t('profile.payment_plan.premium_section.section_two.header')}
      icon={<InlineSvg src={iconControll} />}
      options={[
        { title: t('profile.payment_plan.premium_section.section_two.checkmark_one') },
        { title: t('profile.payment_plan.premium_section.section_two.checkmark_two') },
      ]}
    />
    <Section
      headerTitle={t('profile.payment_plan.premium_section.section_three.header')}
      icon={<InlineSvg src={iconTime} />}
      options={[
        { title: t('profile.payment_plan.premium_section.section_three.checkmark_one') },
        { title: t('profile.payment_plan.premium_section.section_three.checkmark_two') },
        { title: t('profile.payment_plan.premium_section.section_three.checkmark_three') },
      ]}
    />
    <Section
      headerTitle={t('profile.payment_plan.premium_section.section_four.header')}
      icon={<InlineSvg src={iconOverview} />}
      options={[
        { title: t('profile.payment_plan.premium_section.section_four.checkmark_one') },
        { title: t('profile.payment_plan.premium_section.section_four.checkmark_two') },
        { title: t('profile.payment_plan.premium_section.section_four.checkmark_three') },
        { title: t('profile.payment_plan.premium_section.section_four.checkmark_four') },
      ]}
    />
  </>
);

const List = ({ currentPlan }) => {
  switch (currentPlan) {
    case PREMIUM_UID:
      return <PremiumSection />;
    case BASIC_UID:
      return <BasicSection />;
    default:
      return null;
  }
};

List.propTypes = {
  currentPlan: string.isRequired,
};

export default List;
