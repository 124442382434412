import React from 'react';
import cx from 'classnames';
import { string } from 'prop-types';

import Tooltip from 'redesign/components/molecules/Tooltip/Tooltip';

import styles from './InfoIcon.module.css';

const InfoIcon = ({
  text = '',
  tooltipPlacement = 'bottom',
  className = '',
  tooltipClassName = '',
  referenceClassName = '',
  dataId = '',
  iconDataId = '',
  ...restProps
}) => (
  <div data-id={iconDataId} className={cx(styles.main, className)}>
    <Tooltip
      tooltipPlacement={tooltipPlacement}
      message={text}
      tooltipClassName={tooltipClassName}
      className={referenceClassName}
      dataId={dataId}
      {...restProps}
    />
  </div>
);

InfoIcon.propTypes = {
  text: string,
  tooltipPlacement: string,
  className: string,
  tooltipClassName: string,
  referenceClassName: string,
  dataId: string,
  iconDataId: string,
};

export default InfoIcon;
