import { t } from 'shared/utils';

export const updateFailure = {
  duration: 3000,
  title: t('revenue.form.save_failure.title'),
  variant: 'warning',
};

export const lineItemUpdateSuccess = {
  duration: 3000,
  title: t('notifications.outgoing_invoices.line_items.update_success.title'),
  variant: 'success',
};

export const lineItemUpdateFailure = {
  duration: 3000,
  title: t('notifications.outgoing_invoices.line_items.update_failure.title'),
  variant: 'warning',
};

export const lineItemCreateSuccess = {
  duration: 3000,
  title: t('notifications.outgoing_invoices.line_items.create_success.title'),
  variant: 'success',
};

export const lineItemCreateFailure = {
  duration: 3000,
  title: t('notifications.outgoing_invoices.line_items.create_failure.title'),
  variant: 'warning',
};

export const lineItemDeleteSuccess = {
  duration: 3000,
  title: t('notifications.outgoing_invoices.line_items.delete_success.title'),
  variant: 'success',
};

export const lineItemDeleteFailure = {
  duration: 3000,
  title: t('notifications.outgoing_invoices.line_items.delete_failure.title'),
  variant: 'warning',
};

export const invoiceSendSuccess = {
  duration: 3000,
  title: t('notifications.outgoing_invoices.send_success.title'),
  variant: 'success',
};

export const reminderSendSuccess = {
  duration: 3000,
  title: t('notifications.payment_reminders.send_success.title'),
  variant: 'success',
};

export const reminderDownloadSuccess = {
  duration: 3000,
  title: t('notifications.payment_reminders.download_success.title'),
  variant: 'success',
};

export const downloadSuccess = {
  duration: 3000,
  title: t('notifications.outgoing_invoices.download_success.title'),
  variant: 'success',
};

export const downloadEInvoiceFailureSaveAsDraft = {
  duration: 3000,
  title: t('notifications.outgoing_invoices.download_failure_e_invoice_save_as_draft.title'),
  variant: 'warning',
};

export const downloadEInvoiceFailure = {
  duration: 3000,
  title: t('notifications.outgoing_invoices.download_failure_e_invoice.title'),
  variant: 'warning',
};

export const correctionCreateFailure = {
  duration: 3000,
  title: t('notifications.outgoing_invoices.corrections.create_failure.title'),
  variant: 'warning',
};

export const invoiceDeletionSuccess = {
  duration: 3000,
  title: t('notifications.outgoing_invoices.deteletion.title'),
  variant: 'success',
};
