import React from 'react';

import { t } from 'shared/utils';
import CardView, { HeadingSection, Section } from 'components/CardView';
import IconExclamation from 'components/Icons/IconExclamation';

import sharedStyles from '../../../../pages/Shared.module.css';
import styles from './VrsoResetPasswordBlocked.module.css';

const VrsoResetPasswordBlocked = () => {
  return (
    <section className={sharedStyles.wrapper}>
      <CardView className={styles.card}>
        <HeadingSection>
          <h1 className={styles.header}>{t('login.vrso_reset_password_blocked.header')}</h1>
        </HeadingSection>
        <Section>
          <IconExclamation className={styles.icon} />
          <main className={styles.main}>
            <div className={styles.bodyText} data-id="message-first">
              {t('login.vrso_reset_password_blocked.body_1')}
            </div>
            <div className={styles.bodyText} data-id="message-second">
              {t('login.vrso_reset_password_blocked.body_2')}
            </div>
            <div className={styles.highlightedText} data-id="message-bold">
              {t('login.vrso_reset_password_blocked.highlighted')}
            </div>
          </main>
          <footer className={styles.footer}>
            <div className={styles.footerText}>
              {t('login.vrso_reset_password_blocked.footer_1')}
            </div>
            <div className={styles.footerText}>
              {t('login.vrso_reset_password_blocked.footer_2')}
            </div>
            <div className={styles.footerText}>
              {t('login.vrso_reset_password_blocked.footer_3')}
            </div>
          </footer>
        </Section>
      </CardView>
    </section>
  );
};

export default VrsoResetPasswordBlocked;
