import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';

import { showNotification } from 'actions/notification';
import { deleteCashTransaction } from 'api/me/cashbooks/cashTransactions';
import { CASHBOOKS_DELETE_CASH_TRANSACTION } from 'constants/piwik';
import validationNotification from 'notifications/validation';
import { t } from 'shared/utils';
import {
  mapServerErrorsToFieldErrors,
  mapServerErrorsToNotificationError,
} from 'shared/utils/server-validation';
import { Cashbook } from 'types/entities/Cashbook';
import { CashTransaction } from 'types/entities/CashTransaction';
import Button from 'components/Button';
import { FormField, TextareaField } from 'components/Form';
import { ModalHeader } from 'components/Modal';

import { trackEventHandler } from '../../utils';
import { MaxReasonCharacters } from './constants';
import { Buttons, Modal, ModalBody, Notification, Title } from './DeleteModal.styled';
import { capCharacters } from './validation';

import styles from './DeleteModal.module.css';

type DeleteModalOwnProps = {
  onSave: () => void;
  onClose: () => void;
  cashTransactionToDelete: CashTransaction;
  cashbook: Cashbook;
};

type FormData = {
  reasonForDeletion: string;
};

type DeleteModalProps = DeleteModalOwnProps & InjectedFormProps<FormData, DeleteModalOwnProps>;

const DeleteModal = ({
  onSave,
  onClose,
  cashTransactionToDelete,
  handleSubmit,
  cashbook,
}: DeleteModalProps) => {
  const dispatch = useDispatch();
  const [isSending, setIsSending] = useState(false);

  const onSubmit = useCallback(
    async (formValues: FormData) => {
      setIsSending(true);

      try {
        await deleteCashTransaction({
          data: { reasonForDeletion: formValues.reasonForDeletion },
          cashbookId: cashbook.id,
          cashTransactionId: cashTransactionToDelete.id,
        });
      } catch ({ response }) {
        const errors = (response as any).data.errors;
        const notification = validationNotification(mapServerErrorsToNotificationError(errors));
        dispatch(showNotification(notification));
        setIsSending(false);
        throw new SubmissionError(mapServerErrorsToFieldErrors(errors));
      }

      setIsSending(false);
      trackEventHandler(CASHBOOKS_DELETE_CASH_TRANSACTION);
      await onSave();

      onClose();
    },
    [dispatch, cashTransactionToDelete, onClose, onSave, cashbook]
  );

  return (
    <Modal data-id="deletion-modal" isOpen onRequestClose={onClose}>
      <ModalHeader
        headerStyle={styles.header}
        headerContentStyle={styles.headerContent}
        onClose={onClose}
        withCloseButton
      >
        {t('cashbooks.cash_transactions.delete_modal.header')}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)} data-id="modal-form">
          <Title>{t('cashbooks.cash_transactions.delete_modal.title')}</Title>
          <FormField
            name="reasonForDeletion"
            dataId="reason-for-deletion"
            component={TextareaField}
            label={t('cashbooks.cash_transactions.delete_modal.label')}
            isPlaceholderVisible={false}
            maxCharacters={MaxReasonCharacters}
            normalize={capCharacters}
            minHeight={63}
            required
          />
          {cashTransactionToDelete.assignedToDatevReport && (
            /* @ts-ignore Component is not written in ts and there is an issue with converting prop types */
            <Notification
              title={t('cashbooks.cash_transactions.delete_modal.datev_warning.title')}
              text={
                <>
                  <div>{t('cashbooks.cash_transactions.delete_modal.datev_warning.text_1')}</div>
                  <div>{t('cashbooks.cash_transactions.delete_modal.datev_warning.text_2')}</div>
                  <div>{t('cashbooks.cash_transactions.delete_modal.datev_warning.text_3')}</div>
                </>
              }
              variant="warning"
              isHideButtonHidden
            />
          )}
          <Buttons>
            <Button
              appearance="outlined"
              label={t('cashbooks.cash_transactions.delete_modal.cancel')}
              type="button"
              disabled={isSending}
              onClick={onClose}
            />
            <Button
              label={t('cashbooks.cash_transactions.delete_modal.save')}
              type="submit"
              disabled={isSending}
              dataId="save"
            />
          </Buttons>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default reduxForm<FormData, DeleteModalOwnProps>({
  form: 'delete-cash-transaction',
})(DeleteModal);
