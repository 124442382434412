import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RouteProps, withRouter } from 'react-router-dom';
import { push as pushAction } from 'connected-react-router';
import { get } from 'lodash';
import { compose } from 'redux'; // or 'recompose' or another library

import { setCompany } from 'actions/registration-request';
import LoginFooter from 'pages/shared/LoginFooter/LoginFooter';
import Page from 'pages/shared/Page/Page';
import paths from 'routes/paths';
import { withFormInitialValues } from 'shared/hoc';
import { t } from 'shared/utils';
import AutoComplete from 'components/AutoComplete/AutoComplete';
import { FieldsGroup } from 'components/Form';

import localStyles from './CompanySelectRegistration.module.css';

interface CompanySelectRegistrationProps extends RouteProps {
  push: (path: string) => void;
  setCompany: (company: any) => void;
}

const CompanySelectRegistration: FunctionComponent<CompanySelectRegistrationProps> = ({
  push,
  setCompany,
}) => {
  const handleCompanyChange = async (company) => {
    if (company.zip_code === '' && company.city === '' && company.crefo_id === '') {
      setCompany(company);
      await push(paths.companyInfo);
    } else {
      setCompany(company);
      await push(paths.companyInfo);
    }
  };

  return (
    <Page header={t('request_registration.header')}>
      <form>
        <div className={localStyles.title}>{t('request_registration.title')}</div>
        <div className={localStyles.subtitle}>{t('request_registration.first_subtitle')}</div>
        <FieldsGroup>
          <AutoComplete onChange={handleCompanyChange} />
        </FieldsGroup>
      </form>
      <LoginFooter />
    </Page>
  );
};

const mapStateToProps = (state: any) => ({
  isFetching: state.registrationRequest.isFetching,
});

const mapDispatchToProps = {
  push: pushAction,
  setCompany: setCompany,
};

export default compose<FunctionComponent<CompanySelectRegistrationProps>>(
  withRouter,
  withFormInitialValues(({ router }) => {
    const email = get(router, 'location.query.email', '');
    return { email };
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(CompanySelectRegistration);
