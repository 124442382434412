import {
  CLEANUP_BEFORE_REDIRECT,
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/suppliers';
import { extend } from 'shared/utils';

const defaultState = {
  initial: {
    country: 'DE',
  },
  error: false,
  isFetching: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
    case CREATE_REQUEST:
    case UPDATE_REQUEST:
    case DELETE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_SUCCESS: {
      const data = action.response.data.attributes;
      return {
        ...state,
        initial: {
          ...extend(state.initial, data),
        },
        isFetching: false,
      };
    }
    case CREATE_SUCCESS:
      return {
        ...state,
      };
    case CLEANUP_BEFORE_REDIRECT:
    case UPDATE_SUCCESS:
    case DELETE_SUCCESS: {
      return defaultState;
    }
    case FETCH_FAILURE:
    case CREATE_FAILURE:
    case UPDATE_FAILURE:
    case DELETE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    default:
      return state;
  }
};
