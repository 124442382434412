import React, { PureComponent } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { head, isEmpty, isObject } from 'lodash';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';

import { labelsEnabledHelper, suggestionsEnabledHelper } from 'routes/accesses';
import { t } from 'shared/utils';
import { ResourceType } from 'types/entities/AssignableResource';
import ActionButton from 'components/ActionPanel/ActionButton';
import UnassignedAmount from 'components/Assigment/UnassignedAmount';
import { ButtonAppearances } from 'components/Button';
import Section from 'components/Form/Section/Section';
import AssignmentModal from 'features/bankTransfers/Manager/Table/BankTransferRow/AssignmentSection/ResourceAssignmentMode/Assignment/AssignmentModal/AssignmentModal';

import AddContractModal from './AddContractModal/AddContractModal';
import LabelsSections from './Assignments/LabelsSection';
import ResourcesSection from './Assignments/ResourcesSection';
import SuggestedInvoiceSection from './Assignments/SuggestedInvoiceSection';
import { UnassignedBankTransferAmountProvider } from './components/UnassignedAmountContext';
import LabelAssignment from './LabelAssignment';
import SelectResourcesField from './SelectResourcesField/SelectResourcesField';

import styles from './AssignmentSection.module.css';

class AssignmentSection extends PureComponent {
  defaultState = {
    id: null,
    resourceType: null,
    isInEditMode: false,
    isLabelModalOpen: false,
    editableLabelData: {},
    showAddContractModal: false,
  };

  state = this.defaultState;

  handleResourceSelect = (selectedResource) => {
    const { isInEditMode } = this.state;

    if (isInEditMode) this.setState(this.defaultState);

    this.setState({
      id: selectedResource.resourceId,
      resourceType: selectedResource.resourceType,
    });
  };

  handleHideResource = () => this.setState(this.defaultState);

  triggerLabelModal = (labelId = null) => {
    if (labelId && !isObject(labelId)) {
      const { labels } = this.props;
      const editableLabelData = head(labels.filter((label) => label.id === labelId)) || {};

      return this.setState((prevState) => ({
        isLabelModalOpen: !prevState.isLabelModalOpen,
        editableLabelData,
      }));
    }

    return this.setState((prevState) => ({
      isLabelModalOpen: !prevState.isLabelModalOpen,
      editableLabelData: {},
    }));
  };

  handleShowAddContractModal = () =>
    this.setState({
      showAddContractModal: true,
    });

  handleHideAddContractModal = () =>
    this.setState({
      showAddContractModal: false,
    });

  handleAddNewContract = (installment) => {
    this.handleHideAddContractModal();
    this.handleResourceSelect({
      resourceId: installment.id,
      resourceType: ResourceType.ContractInstallment,
    });
  };

  handleResourceEdit = ({ resourceType, id }) => {
    this.setState({
      isInEditMode: true,
      resourceType,
      id,
    });
  };

  render() {
    const {
      bankTransfer,
      bankTransferAmount,
      isFullyAsigned,
      connectedResources,
      suggestedInvoices = [],
      connectResource,
      disconnectResource,
      rejectConnectionSuggestion,
      bankTransferId,
      isBankTransferPositive,
      suggestionsEnabled = false,
      labelsEnabled = false,
      isFetching,
      unassignedAmount,
      bankTransferConnections,
      createLabel,
      deleteLabel,
      labels,
    } = this.props;

    const {
      id,
      resourceType,
      isInEditMode,
      isLabelModalOpen,
      editableLabelData,
      showAddContractModal,
    } = this.state;

    const isInAssignmentMode = id && resourceType;

    return (
      <>
        <Grid fluid className={styles.main}>
          <UnassignedBankTransferAmountProvider value={unassignedAmount}>
            {!isInAssignmentMode && (
              <Row>
                <Col xs={12} lg={4}>
                  <SelectResourcesField
                    onSelect={this.handleResourceSelect}
                    bankTransferId={bankTransferId}
                    bankTransferAmount={bankTransferAmount}
                    onAddContract={this.handleShowAddContractModal}
                  />
                </Col>
                {labelsEnabled && (
                  <ActionButton
                    className={styles.buttonWrapper}
                    appearance={ButtonAppearances.outlined}
                    onClick={this.triggerLabelModal}
                    label={t('bank_transfers.table.add_other_assignment')}
                    disabled={isFullyAsigned}
                    dataId="BankTransferAssignment:button-start-label-assignment"
                  />
                )}
              </Row>
            )}
            <Row>
              {suggestionsEnabled && suggestedInvoices.length !== 0 && (
                <Col xs={12}>
                  <Section
                    wrapperTheme={styles.section}
                    className={styles.sectionContent}
                    sectionTitleTheme={styles.sectionTitle}
                    label={t('bank_transfers.table.suggestions_tooltip')}
                    title={t('bank_transfers.table.suggestions')}
                  >
                    <SuggestedInvoiceSection
                      suggestedInvoices={suggestedInvoices}
                      rejectConnectionSuggestion={rejectConnectionSuggestion}
                      isFetching={isFetching}
                      resourceType={resourceType}
                      onAccept={this.handleResourceSelect}
                    />
                  </Section>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={12}>
                <Section
                  title={t('bank_transfers.table.assigned_resources')}
                  label={t('bank_transfers.table.assigned_resources_tooltip')}
                  wrapperTheme={styles.section}
                  className={styles.sectionContent}
                  sectionTitleTheme={styles.sectionTitle}
                  rightContent={
                    unassignedAmount ? (
                      <UnassignedAmount
                        amount={unassignedAmount}
                        forceMinusSign={!isBankTransferPositive}
                      />
                    ) : null
                  }
                >
                  <ResourcesSection
                    connectedResources={connectedResources}
                    disconnectResource={disconnectResource}
                    isInEditMode={isInEditMode}
                    connectResource={connectResource}
                    bankTransferConnections={bankTransferConnections}
                    anyLabels={!isEmpty(labels)}
                    onEdit={this.handleResourceEdit}
                  />
                  {labelsEnabled && !isEmpty(labels) && (
                    <LabelsSections
                      deleteLabel={deleteLabel}
                      isBankTransferPositive={isBankTransferPositive}
                      labels={labels}
                      onEdit={this.triggerLabelModal}
                    />
                  )}
                </Section>
              </Col>
            </Row>
            {labelsEnabled && isLabelModalOpen && (
              <LabelAssignment
                isOpen={isLabelModalOpen}
                isBankTransferPositive={isBankTransferPositive}
                amount={editableLabelData.amountAssigned || Math.abs(unassignedAmount)}
                note={editableLabelData.note}
                selectedOption={editableLabelData.category}
                labelId={editableLabelData.id}
                isLabelInEditMode={!isEmpty(editableLabelData)}
                createLabel={createLabel}
                onClose={this.triggerLabelModal}
              />
            )}
          </UnassignedBankTransferAmountProvider>
        </Grid>
        {showAddContractModal && (
          <AddContractModal
            onClose={this.handleHideAddContractModal}
            onSuccess={this.handleAddNewContract}
            isRevenue={isBankTransferPositive}
          />
        )}
        <AssignmentModal
          bankTransfer={bankTransfer}
          isOpen={isInAssignmentMode || false}
          onClose={this.handleHideResource}
          isInEditMode={isInEditMode}
          id={id}
          resourceType={resourceType}
          connectResource={connectResource}
        />
      </>
    );
  }
}

AssignmentSection.propTypes = {
  connectedResources: arrayOf(
    shape({
      id: string,
      type: string,
    })
  ),
  suggestedInvoices: arrayOf(
    shape({
      id: string,
      type: string,
    })
  ),
  bankTransferId: string,
  bankTransferAmount: number,
  connectResource: func.isRequired,
  disconnectResource: func.isRequired,
  rejectConnectionSuggestion: func.isRequired,
  acceptConnectionSuggestion: func.isRequired,
  suggestionsEnabled: bool,
  labelsEnabled: bool,
  isBankTransferPositive: bool,
  isFetching: bool,
  unassignedAmount: number.isRequired,
  bankTransferConnections: oneOfType([arrayOf(shape({})), shape({})]),
  createLabel: func.isRequired,
  deleteLabel: func.isRequired,
  labels: oneOfType([arrayOf(shape({})), shape({})]),
  isFullyAsigned: bool,
};

export default connect((state) => ({
  suggestionsEnabled: suggestionsEnabledHelper(state),
  labelsEnabled: labelsEnabledHelper(state),
  isFetching: state.incomingInvoice.isFetching,
}))(AssignmentSection);
