import {
  formValueSelector as formValueSelectorBuilder,
  getFormSyncErrors as getFormSyncErrorsBuilder,
} from 'redux-form';
import { createSelector } from 'reselect';

import { RootState } from 'store';

import type { BankAccount } from './BankAccounts/types';
import { FormName } from './constants';
import type { OtherExpense, RecurringExpense } from './Expenses/types';
import { MonthlyIntervalMultiplier } from './ExpensesRevenues/constants';
import { parseCurrencyStringToNumber } from './ExpensesRevenues/utils';
import type { OtherRevenue, RecurringRevenue } from './Revenues/types';

const formValueSelector = formValueSelectorBuilder<RootState>(FormName);

export const getRecurringExpenses = (state: RootState): RecurringExpense[] =>
  formValueSelector(state, 'recurringExpenses');

export const getRecurringRevenues = (state: RootState): RecurringRevenue[] =>
  formValueSelector(state, 'recurringRevenues');

export const getOtherExpenses = (state: RootState): OtherExpense[] =>
  formValueSelector(state, 'otherExpenses');

export const getOtherRevenues = (state: RootState): OtherRevenue[] =>
  formValueSelector(state, 'otherRevenues');

export const getOtherExpense = createSelector(
  getOtherExpenses,
  (otherExpenses) => otherExpenses[0]
);

export const getOtherRevenue = createSelector(
  getOtherRevenues,
  (otherRevenues) => otherRevenues[0]
);

export const getBankAccounts = (state: RootState): BankAccount[] =>
  formValueSelector(state, 'bankAccounts');

export const getSelectedBankAccounts = createSelector(getBankAccounts, (bankAccounts) =>
  bankAccounts.filter((account) => account.isChecked)
);

export const getSelectedBankAccountsIds = createSelector(getSelectedBankAccounts, (bankAccounts) =>
  bankAccounts.map((account) => account.id)
);

const getFormSyncErrors = getFormSyncErrorsBuilder(FormName);

export const getFormErrors = (state: RootState) => getFormSyncErrors(state);

export const getValidRecurringExpenses = createSelector(
  getRecurringExpenses,
  getFormErrors,
  (recurringExpenses, errors: any) =>
    recurringExpenses.filter((_: RecurringExpense, index: number) => {
      const arrayErrors = errors['recurringExpenses'];
      return !arrayErrors || !arrayErrors[index];
    })
);

export const getValidRecurringRevenues = createSelector(
  getRecurringRevenues,
  getFormErrors,
  (recurringRevenues, errors: any) =>
    recurringRevenues.filter((_: RecurringRevenue, index: number) => {
      const arrayErrors = errors['recurringRevenues'];
      return !arrayErrors || !arrayErrors[index];
    })
);

export const getValidOtherExpenses = createSelector(
  getOtherExpenses,
  getFormErrors,
  (otherExpenses, errors: any) =>
    otherExpenses.filter((_: OtherExpense, index: number) => {
      const arrayErrors = errors['otherExpenses'];
      return !arrayErrors || !arrayErrors[index];
    })
);

export const getValidOtherRevenues = createSelector(
  getOtherRevenues,
  getFormErrors,
  (otherRevenues, errors: any) =>
    otherRevenues.filter((_: OtherRevenue, index: number) => {
      const arrayErrors = errors['otherRevenues'];
      return !arrayErrors || !arrayErrors[index];
    })
);

export const getRecurringMonthlyExpenses = createSelector(
  getValidRecurringExpenses,
  (validRecurringExpenses) =>
    validRecurringExpenses.reduce(
      (sum: number, expense: RecurringExpense) =>
        sum +
        (expense.interval ? MonthlyIntervalMultiplier[expense.interval] : 0) *
          parseCurrencyStringToNumber(expense.amount),
      0
    )
);

export const getRecurringMonthlyRevenues = createSelector(
  getValidRecurringRevenues,
  (validRecurringRevenues) =>
    validRecurringRevenues.reduce(
      (sum: number, revenue: RecurringRevenue) =>
        sum +
        (revenue.interval ? MonthlyIntervalMultiplier[revenue.interval] : 0) *
          parseCurrencyStringToNumber(revenue.amount),
      0
    )
);

export const getMonthlyExpenses = createSelector(
  getRecurringMonthlyExpenses,
  getOtherExpense,
  (recurringExpensesTotal, otherExpense) =>
    recurringExpensesTotal +
    (otherExpense.amount ? parseCurrencyStringToNumber(otherExpense.amount) : 0)
);

export const getMonthlyRevenues = createSelector(
  getRecurringMonthlyRevenues,
  getOtherRevenue,
  (recurringRevenuesTotal, otherRevenue) =>
    recurringRevenuesTotal +
    (otherRevenue.amount ? parseCurrencyStringToNumber(otherRevenue.amount) : 0)
);

export const getBankAccountsTotalBalance = createSelector(getBankAccounts, (bankAccounts) =>
  bankAccounts
    .filter((account) => account.isChecked)
    .reduce((prev, account) => prev + account.balance, 0)
);
