import { push } from 'connected-react-router';

import { CALL_API } from 'constants/api';
import {
  ADD_ITEM_FAILURE,
  ADD_ITEM_REQUEST,
  ADD_ITEM_SUCCESS,
  PRODUCT_CATALOG_GROUPS,
  REMOVE_ITEM_FAILURE,
  REMOVE_ITEM_REQUEST,
  REMOVE_ITEM_SUCCESS,
} from 'constants/product-catalog/groups';
import { getParams } from 'reducers/common/resource';
import paths from 'routes/paths';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

import * as actions from '../../index';
import { submitNewProductCatalogGroupFailure, submitNewProductCatalogGroupSuccess } from './piwik';

export const index =
  ({ name, endpoint } = {}) =>
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState().productCatalogGroups;
    const combinedParams = { ...state[name].params, ...params };
    return dispatch(
      actions.apiIndex({
        name,
        endpoint,
        params: getParams(combinedParams),
      })
    ).catch(apiErrorHandler);
  };

const endpoint = '/me/product_catalog_groups';
const name = PRODUCT_CATALOG_GROUPS;
const config = { endpoint, name };

export const fetchProductCatalogGroups = index(config);
const apiCreate = actions.apiCreate(config);
const apiDelete = actions.apiDelete(config);
const apiUpdate = actions.apiUpdate(config);
const apiFetch = actions.apiFetch(config);

export const apiAddItemToProductCatalogGroup = ({ groupId, itemId, quantity }) => ({
  [CALL_API]: {
    data: { product_catalog_group_id: groupId, quantity },
    method: 'POST',
    endpoint: `/me/product_catalog_items/${itemId}/add_to_group`,
    types: [ADD_ITEM_REQUEST, ADD_ITEM_SUCCESS, ADD_ITEM_FAILURE],
  },
});

export const apiUpdateItemInProductCatalogGroup = ({
  productCatalogItemsGroupId,
  quantity = 1,
}) => ({
  [CALL_API]: {
    data: { quantity },
    method: 'PUT',
    endpoint: `/me/product_catalog_items_groups/${productCatalogItemsGroupId}`,
    types: [REMOVE_ITEM_REQUEST, REMOVE_ITEM_SUCCESS, REMOVE_ITEM_FAILURE],
  },
});

export const apiRemoveItemFromProductCatalogGroup = ({ groupId, itemId }) => ({
  [CALL_API]: {
    data: { product_catalog_group_id: groupId },
    method: 'POST',
    endpoint: `/me/product_catalog_items/${itemId}/remove_from_group`,
    types: [REMOVE_ITEM_REQUEST, REMOVE_ITEM_SUCCESS, REMOVE_ITEM_FAILURE],
  },
});

export const fetchProductCatalogGroup = (id) => (dispatch) =>
  bindServerValidation(apiFetch(id), dispatch, { isReduxForm: false }).catch(apiErrorHandler);

export const createProductCatalogGroup =
  (data = {}) =>
  (dispatch) =>
    bindServerValidation(apiCreate(data), dispatch);

export const updateProductCatalogGroup =
  ({ id, data, creatorMode } = {}) =>
  (dispatch) =>
    bindServerValidation(apiUpdate({ id, data: { name: data.name } }), dispatch)
      .then(() => {
        dispatch(push(paths.productCatalogGroups));
        if (creatorMode === 'new') dispatch(submitNewProductCatalogGroupSuccess());
      })
      .catch(() => {
        dispatch(submitNewProductCatalogGroupFailure());
        apiErrorHandler();
      });

export const addItemToProductCatalogGroup =
  ({ groupId, ...rest }) =>
  (dispatch) =>
    bindServerValidation(apiAddItemToProductCatalogGroup({ groupId, ...rest }), dispatch, {
      isReduxForm: false,
    }).then(() => dispatch(fetchProductCatalogGroup(groupId)));

export const updateItemInProductCatalogGroup =
  ({ groupId, ...rest }) =>
  (dispatch) =>
    bindServerValidation(apiUpdateItemInProductCatalogGroup({ groupId, ...rest }), dispatch, {
      isReduxForm: false,
    }).then(() => dispatch(fetchProductCatalogGroup(groupId)));

export const removeItemFromProductCatalogGroup =
  ({ groupId, ...rest }) =>
  (dispatch) =>
    bindServerValidation(apiRemoveItemFromProductCatalogGroup({ groupId, ...rest }), dispatch, {
      isReduxForm: false,
    })
      .then(() => dispatch(fetchProductCatalogGroup(groupId)))
      .catch(apiErrorHandler);

export const deleteProductCatalogGroup = (id) => (dispatch) =>
  bindServerValidation(apiDelete(id), dispatch, { isReduxForm: false })
    .then(() => dispatch(push(paths.productCatalogGroups)))
    .catch(apiErrorHandler);

export const sort = actions.sort(config);

export const setPagination = actions.setPagination(config);
