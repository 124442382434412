import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as pushAction } from 'connected-react-router';
import flatten from 'flat';
import { debounce, filter, get, head, some } from 'lodash';
import { bool, func, number, shape } from 'prop-types';

import {
  clearFilters,
  indexOutgoingInvoices,
  resetOutgoingInvoices,
  setPage,
  setQueryParam,
  setStatusFilter,
} from 'actions/outgoing-invoice';
import {
  PIWIK_ACTION_IMPORT_OUTGOING_INVOICE,
  PIWIK_ACTION_NEW_OUTGOING_INVOICE,
} from 'constants/piwik';
import { Resources } from 'constants/resources';
import { InvoiceStatuses } from 'constants/statuses';
import WarningSignImage from 'images/warning-sign.svg';
import { paymentRemindersEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import sharedStyles from 'shared/styles/components.module.css';
import { invalidRangeChecker, objectHasValues, t } from 'shared/utils';
import { dateFilterMapper } from 'shared/utils/filters/dateFilterMapper';
import BoxWithButtonRedVariant from 'components/BoxWithButton/BoxWithButtonRedVariant/BoxWithButtonRedVariant';
import CardView, { HeadingSection, Section } from 'components/CardView';
import { StatusFilter, StatusFilterGroup } from 'components/Filter';
import { FiltersGroup } from 'components/Filter/FiltersGroup/FiltersGroup';
import I18n from 'components/I18n';
import { Pagination } from 'components/Pagination/Pagination';
import { Search, SearchDateGroup, SearchGroup } from 'components/Search';
import CSVDownloadButton from 'components/Table/CSVDownloadButton/CSVDownloadButton';

import NewInvoiceButton from '../NewInvoiceButton';
import OutgoingInvoicesTable from '../OutgoingInvoicesTable';

import localStyles from './Table.module.css';

const styles = { ...sharedStyles, ...localStyles };

const DATE_FILTERS = ['creationDateFrom', 'creationDateTo', 'dueDateFrom', 'dueDateTo'];

const getOutgoingInvoiceFilters = (paymentRemindersEnabled, invoicesCounters) =>
  [
    InvoiceStatuses.ALL,
    InvoiceStatuses.OPEN,
    InvoiceStatuses.PARTLY_PAID,
    InvoiceStatuses.PAID,
    InvoiceStatuses.OVERDUE,
    InvoiceStatuses.DRAFT,
    InvoiceStatuses.CANCELLED,
    InvoiceStatuses.PAYMENT_REMINDED,
  ].map((status) => {
    if (status === InvoiceStatuses.PAYMENT_REMINDED && !paymentRemindersEnabled) {
      return null;
    }
    return (
      <StatusFilter
        key={status}
        isDefault={status === InvoiceStatuses.ALL}
        name={status === InvoiceStatuses.ALL ? '' : status}
        dataId={`OutgoingInvoices:filter-${status}`}
      >
        <I18n className={styles.filterLabel} t={`tables.filters.${status}`} />
        {`(${invoicesCounters[status] || 0})`}
      </StatusFilter>
    );
  });

class Table extends Component {
  componentWillUnmount() {
    const { isResetedFromTable } = this.props;

    if (!isResetedFromTable) this.props.resetOutgoingInvoices();
  }

  _fetch = ({ page = 1 } = {}, sorting = this.props.sorting, overrideValues = {}) => {
    const isIndexClassicView = objectHasValues(this.props.filters);
    const correctPage = overrideValues.page || page;

    this.props.indexInvoices({ page: correctPage }, sorting, { isIndexClassicView });
  };

  fetch = debounce(this._fetch, 200);

  hasAnySearchQuery() {
    const { filters } = this.props;
    return some(flatten(filter(filters, (_, key) => key !== 'status')));
  }

  clearField = (name) => this.props.setQueryAction(name);

  render() {
    const {
      isFetching,
      pagination,
      isEmpty,
      showModal,
      sorting,
      filters,
      setFilter,
      setQueryAction,
      paymentRemindersEnabled,
      invoicesCounters,
      isProfileFilled,
      push,
    } = this.props;

    const hasAnyFiltersApplied = some(flatten(filters));
    const hasAnySearchQuery = this.hasAnySearchQuery();
    const shouldDisplayEmptyMessage = isEmpty && hasAnyFiltersApplied;
    const isInvalidRange = invalidRangeChecker({
      start: filters.amountHigherThan,
      end: filters.amountLowerThan,
    });
    const isIndexClassicView = objectHasValues(filters);

    return (
      <CardView className={styles.card}>
        <HeadingSection>
          <I18n t="revenue.subtitle" className={styles.headingText} />
          <div
            className={cx(styles.pullRight, styles.newInvoiceButton, {
              [styles.hiddenSection]: isEmpty && !hasAnyFiltersApplied,
            })}
          >
            <div className={styles.creationButtonsContainer}>
              <NewInvoiceButton
                dataId="OutgoingInvoices:button-new-invoice"
                path={paths.newOutgoingInvoice}
                title={t('revenue.add_new')}
                showModal={showModal}
                piwikAction={PIWIK_ACTION_NEW_OUTGOING_INVOICE}
              />
              {isProfileFilled && (
                <NewInvoiceButton
                  dataId="OutgoingInvoices:button-import-invoice"
                  path={paths.importOutgoingInvoice}
                  title={t('revenue.import')}
                  showModal={showModal}
                  piwikAction={PIWIK_ACTION_IMPORT_OUTGOING_INVOICE}
                />
              )}
            </div>
          </div>
          <StatusFilterGroup filterSelector={filters.status} selectFilterAction={setFilter}>
            {getOutgoingInvoiceFilters(paymentRemindersEnabled, invoicesCounters)}
          </StatusFilterGroup>
          <FiltersGroup
            className={styles.filtersGroup}
            isResetButtonDisabled={!hasAnySearchQuery}
            filters={filters}
            onFiltersChange={({ name, value }) => setQueryAction(name, value)}
            onFiltersReset={this.props.clearFilters}
            placeholder={t('tables.filters.filters_group.outgoing_invoices.placeholder')}
            dataIds={{
              input: 'OutgoingInvoices:input-search',
              resetButton: 'OutgoingInvoices:button-filters-reset',
              toggleDropdownButton: 'OutgoingInvoices:button-filters-toggle',
            }}
            additionalFilters={
              <SearchGroup
                setQueryAction={(name) => (value) => {
                  if (DATE_FILTERS.includes(name))
                    return setQueryAction(name, value, dateFilterMapper);
                  setQueryAction(name, value);
                }}
                filters={filters}
                clearFieldAction={this.clearField}
              >
                <SearchDateGroup
                  label={t('expenses.table.columns.invoice_date_range')}
                  startDateProps={{
                    name: 'creationDateFrom',
                    dataId: 'OutgoingInvoices:filters-invoice-date-from',
                  }}
                  endDateProps={{
                    name: 'creationDateTo',
                    dataId: 'OutgoingInvoices:filters-invoice-date-to',
                  }}
                />
                <SearchDateGroup
                  label={t('expenses.table.columns.due_date_range')}
                  startDateProps={{
                    name: 'dueDateFrom',
                    dataId: 'OutgoingInvoices:filters-due-date-from',
                  }}
                  endDateProps={{
                    name: 'dueDateTo',
                    dataId: 'OutgoingInvoices:filters-due-date-to',
                  }}
                />
                <Search
                  placeholder={t('expenses.table.columns.amount_higher_than')}
                  name="amountHigherThan"
                  isCurrency
                  isCurrencySignVisible
                  invalid={isInvalidRange}
                  dataId="OutgoingInvoices:filters-amount-range-from"
                />
                <Search
                  placeholder={t('expenses.table.columns.amount_lower_than')}
                  dataId="OutgoingInvoices:filters-amount-range-to"
                  name="amountLowerThan"
                  isCurrency
                  isCurrencySignVisible
                  invalid={isInvalidRange}
                />
              </SearchGroup>
            }
          >
            <CSVDownloadButton
              filters={filters}
              sorting={sorting}
              endpoint="outgoing_invoices_csv"
              disabled={isEmpty}
              className={styles.csvButton}
              dataId="OutgoingInvoices:button-CSVExport"
            />
          </FiltersGroup>
        </HeadingSection>
        <Section className={styles.section}>
          {!isProfileFilled && (
            <BoxWithButtonRedVariant
              dataId="OutgoingInvoices:warning-box"
              image={WarningSignImage}
              content={t('empty_entry_pages.outgoing_invoice.table_warning_box.content')}
              buttonLabel={t('empty_entry_pages.outgoing_invoice.table_warning_box.button_label')}
              onClick={() => push(paths.companyProfile)}
            />
          )}
          <div className={cx({ [styles.tableWarningMargin]: !isProfileFilled })}>
            {shouldDisplayEmptyMessage ? (
              <div data-id="OutgoingInvoices:table-empty-info" className={styles.emptyTableMessage}>
                {t('tables.empty')}
              </div>
            ) : (
              <OutgoingInvoicesTable
                paymentRemindersEnabled={paymentRemindersEnabled}
                refresh={this.fetch}
                hasFilters
                isIndexClassicView={isIndexClassicView}
              />
            )}
          </div>
        </Section>
        <Section
          className={cx(styles.section, styles.pagination, { [styles.hiddenSection]: isEmpty })}
        >
          <Pagination
            {...pagination}
            request={this.fetch}
            isFetching={isFetching}
            resource={Resources.OUTGOING_INVOICES}
          />
        </Section>
      </CardView>
    );
  }
}

Table.propTypes = {
  isFetching: bool.isRequired,
  indexInvoices: func.isRequired,
  setPage: func.isRequired,
  resetOutgoingInvoices: func.isRequired,
  sorting: shape({}).isRequired,
  isEmpty: bool.isRequired,
  pagination: shape({
    page: number,
  }).isRequired,
  showModal: bool.isRequired,
  filters: shape({
    status: shape({}),
  }).isRequired,
  parsedFilters: shape({}),
  setFilter: func.isRequired,
  setQueryAction: func.isRequired,
  clearFilters: func.isRequired,
  paymentRemindersEnabled: bool,
  invoicesCounters: shape({}),
  isProfileFilled: bool.isRequired,
  push: func.isRequired,
  isResetedFromTable: bool.isRequired,
};

export default connect(
  (state) => ({
    showModal: !get(state, 'outgoingInvoices.meta.bank-account-data-present', false),
    filters: state.outgoingInvoices.filters,
    parsedFilters: state.outgoingInvoices.parsedFilters,
    isEmpty: state.outgoingInvoices.data.length === 0,
    pagination: state.outgoingInvoices.pagination,
    sorting: state.outgoingInvoices.sorting,
    paymentRemindersEnabled: paymentRemindersEnabledHelper(state),
    invoicesCounters: state.outgoingInvoices.invoicesCounters,
    isFetching: state.outgoingInvoices.isFetching,
    isProfileFilled: state.onboarding.data?.profileFilled,
    isResetedFromTable:
      head(state.outgoingInvoices.data) && !head(state.outgoingInvoices.data).isReseted,
  }),
  (dispatch) => ({
    setPage: (...args) => dispatch(setPage(...args)),
    indexInvoices: (...args) => dispatch(indexOutgoingInvoices(...args)),
    resetOutgoingInvoices: (...args) => dispatch(resetOutgoingInvoices(...args)),
    setFilter: (...args) => dispatch(setStatusFilter(...args)),
    setQueryAction: (name, value, mapper) => dispatch(setQueryParam(name, value, mapper)),
    clearFilters: (...args) => dispatch(clearFilters(...args)),
    push: (path) => dispatch(pushAction(path)),
  })
)(Table);
