export const NAMESPACE = 'CLIENTS';

export const INDEX_REQUEST = `${NAMESPACE}/INDEX_REQUEST`;
export const INDEX_SUCCESS = `${NAMESPACE}/INDEX_SUCCESS`;
export const INDEX_FAILURE = `${NAMESPACE}/INDEX_FAILURE`;
export const FETCH_REQUEST = `${NAMESPACE}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${NAMESPACE}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${NAMESPACE}/FETCH_FAILURE`;
export const CREATE_REQUEST = `${NAMESPACE}/CREATE_REQUEST`;
export const CREATE_SUCCESS = `${NAMESPACE}/CREATE_SUCCESS`;
export const CREATE_FAILURE = `${NAMESPACE}/CREATE_FAILURE`;
export const UPDATE_REQUEST = `${NAMESPACE}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${NAMESPACE}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${NAMESPACE}/UPDATE_FAILURE`;
export const DELETE_REQUEST = `${NAMESPACE}/DELETE_REQUEST`;
export const DELETE_SUCCESS = `${NAMESPACE}/DELETE_SUCCESS`;
export const DELETE_FAILURE = `${NAMESPACE}/DELETE_FAILURE`;
export const INDEX_SORT = `${NAMESPACE}/INDEX_SORT`;
export const CLEANUP_BEFORE_REDIRECT = `${NAMESPACE}/CLEANUP_BEFORE_REDIRECT`;

export const DE_CLIENT_MANAGEMENT = 'Kundenverwaltung';
export const DE_CLIENT_CREATION_ATTEMPT = 'neuen Kunden hinzufügen';
export const DE_CLIENT_DELETED = 'loeschen';
export const DE_CLIENT_CREATION = 'Kunde erstellen';
export const DE_SUCCESS = 'Erfolg';
export const DE_ABORT = 'Abbrechen';
