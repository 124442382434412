import React, { memo } from 'react';

import { t } from 'shared/utils';

import IntroductionSvg from './IntroductionSvg';

import styles from './Introduction.module.css';

const Introduction = () => {
  return (
    <section className={styles.wrapper}>
      <IntroductionSvg className={styles.icon} />
      <h1 className={styles.title}>
        {t('features.financial_planning.liquidity_calculator.introduction.title')}
      </h1>
      <h2 className={styles.subtitle}>
        {t('features.financial_planning.liquidity_calculator.introduction.subtitle')}
      </h2>
      <ol className={styles.list}>
        <li>{t('features.financial_planning.liquidity_calculator.introduction.list.item_1')}</li>
        <li>{t('features.financial_planning.liquidity_calculator.introduction.list.item_2')}</li>
        <li>{t('features.financial_planning.liquidity_calculator.introduction.list.item_3')}</li>
      </ol>
      <p className={styles.notice}>
        {t('features.financial_planning.liquidity_calculator.introduction.notice')}
      </p>
    </section>
  );
};

export default memo(Introduction);
