import React, { Component } from 'react';
import { connect } from 'react-redux';
import { flow, get, identity, isEmpty, some } from 'lodash';
import { bool, func, shape, string } from 'prop-types';
import { reduxForm } from 'redux-form';

import { indexBankAccounts } from 'actions/banks';
import { getBank } from 'reducers/banks';
import { getBankAccounts } from 'reducers/banks/accounts';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import AdditionalInfo from 'components/AdditionalInfo/AdditionalInfo';
import If from 'components/Conditions/If';
import Loading from 'components/Loading';

import Table from '../Table/Table';
import { BANKS_WITH_MULTIPLE_SORTING_CODES_MAP } from './constants';
import validateCreditLineErrors from './validation';

import pageStyles from '../Page.module.css';

class Page3 extends Component {
  state = {
    bankAccounts: [],
    oldBankAccounts: [],
    loadingBankAccounts: true,
  };

  static getDerivedStateFromProps({ bank, bankAccounts, addedBankAccounts }) {
    if (isEmpty(bankAccounts)) return null;

    const { code: bankCode } = bank;

    const availableBankAccounts = bankAccounts.filter(
      (account) =>
        (BANKS_WITH_MULTIPLE_SORTING_CODES_MAP[account.bankCode] || account.bankCode) === bankCode
    );

    // First we to check for IBAN then number because PayPal doesn't have IBAN
    const addedBankAccountsIds = addedBankAccounts.map((account) => account.iban || account.number);
    const isOldAccount = (account) => addedBankAccountsIds.includes(account.iban || account.number);

    return {
      bankAccounts: availableBankAccounts.filter((account) => !isOldAccount(account)),
      oldBankAccounts: availableBankAccounts.filter((account) => isOldAccount(account)),
    };
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.loadingBankAccounts !== this.state.loadingBankAccounts) {
      this.props.setLoading(this.state.loadingBankAccounts);
    }
  }

  componentDidMount() {
    this.props
      .indexBankAccounts(this.props.bank.id)
      .then(() => this.setState({ loadingBankAccounts: false }));
  }

  render() {
    const { bankAccounts, oldBankAccounts, loadingBankAccounts } = this.state;
    const {
      shouldAssignMainBankAccount,
      anyAccountChecked,
      handleSubmit,
      onSubmit,
      submitting,
      invalid,
      handlePrevPage,
      isLoading,
      hasExternalSteps,
    } = this.props;
    const submitLabel =
      shouldAssignMainBankAccount || hasExternalSteps
        ? t('bank_accounts.creator.next_step')
        : t('bank_accounts.creator.ready');

    return (
      <form
        className={pageStyles.page}
        onSubmit={handleSubmit(onSubmit)}
        id="bank-account-creator-page-3"
      >
        <div className={pageStyles.body}>
          <div>{t('bank_accounts.creator.please_choose_accounts')}</div>
          <If ok={!hasExternalSteps}>
            <AdditionalInfo text={t('bank_accounts.creator.please_choose_accounts_info')} />
          </If>

          <If ok={!loadingBankAccounts}>
            <Table bankAccounts={bankAccounts} oldBankAccounts={oldBankAccounts} />
          </If>

          <If ok={loadingBankAccounts || submitting || isLoading}>
            <Loading />
          </If>
        </div>
        <div className={pageStyles.footer}>
          <ActionButton
            appearance="outlined"
            onClick={handlePrevPage}
            className={pageStyles.stepButton}
            disabled={isLoading}
            label={t('bank_accounts.creator.prev_step')}
          />
          <ActionButton
            type="submit"
            invalid={anyAccountChecked}
            className={pageStyles.stepButton}
            disabled={anyAccountChecked || submitting || invalid || isLoading}
            label={submitLabel}
            dataId="BankAccount:button-submit"
          />
        </div>
      </form>
    );
  }
}

Page3.propTypes = {
  bank: shape({
    id: string.isRequired,
  }).isRequired,
  handleSubmit: func.isRequired,
  handlePrevPage: func.isRequired,
  anyAccountChecked: bool.isRequired,
  indexBankAccounts: func.isRequired,
  onSubmit: func.isRequired,
  setLoading: func.isRequired,
  shouldAssignMainBankAccount: bool.isRequired,
  hasExternalSteps: bool.isRequired,
  submitting: bool,
  invalid: bool,
  isLoading: bool.isRequired,
};

const anyAccountChecked = (state) =>
  !some(get(state, 'form.bankAccountCreator.values.accepted'), identity);

export default flow(
  reduxForm({
    form: 'bankAccountCreator',
    destroyOnUnmount: false,
    validate: validateCreditLineErrors,
  }),
  connect(
    (state) => ({
      bank: getBank(state, get(state, 'form.bankAccountCreator.values.bankCode')),
      bankAccounts: getBankAccounts(state),
      addedBankAccounts: state.bankAccount.data,
      anyAccountChecked: anyAccountChecked(state),
    }),
    { indexBankAccounts }
  )
)(Page3);
