import React from 'react';

import { FeatureTypeTranslations } from 'constants/customer-notifications';
import {
  TODO_ACTION_ASSIGN_ICOMING_PAYMENT,
  TODO_ACTION_CREATE_PAYMENT_REMINDER,
  TODO_ACTION_FINALIZE_DRAFT_FROM_UPLOAD,
  TODO_ACTION_FINALIZE_OUTGOING_DRAFT,
  TODO_ACTION_PAY_INVOICE,
  TODO_ACTION_PAY_OVERDUE_PAYMENT,
  TODO_ACTION_USE_DISCOUNT,
  TODO_ASSIGN_OUTGOING_PAYMENT,
} from 'constants/piwik';
import assignPaymentIcon from 'images/sidebar/assign_payment.svg';
import reminderIcon from 'images/sidebar/create_payment_reminder.svg';
import reminderActionIcon from 'images/sidebar/create_payment_reminder_action.svg';
import customIcon from 'images/sidebar/custom.svg';
import discountIcon from 'images/sidebar/discount.svg';
import finalizeDraftIcon from 'images/sidebar/finalize_draft.svg';
import finalizeDraftActionIcon from 'images/sidebar/finalize_draft_action.svg';
import finalizeUploadIcon from 'images/sidebar/finalize_draft_from_app_upload.svg';
import finalizeUploadActionIcon from 'images/sidebar/finalize_draft_from_app_upload_action.svg';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { Task } from 'types/entities/Task';
import { CustomTask } from 'types/entities/Task';
import SubsectionHeadline from 'components/SubsectionHeadline/SubsectionHeadline';

import CustomTodoTask from '../CustomTask/TodoTask';
import TodoFeature from '../TodoFeature/TodoFeature';
import TodoFeatureDetailsHeader from '../TodoFeatureDetailsHeader/TodoFeatureDetailsHeader';
import TodoFeatureTask from '../TodoFeatureTask/TodoFeatureTask';
import { countTasks, trackEvent } from '../utils';
import { FeatureObj, Tasks, ToDoList } from './types';

import styles from './TodoList.module.css';

const { INCOMING, OUTGOING } = FeatureTypeTranslations;

const potentialText = `${t('sidebar.todos.potential')}:`;
const discountPotentialText = `${t('sidebar.todos.discount_potential')}:`;

const amountOfTasks = (tasks: Task[]): number =>
  tasks.map((task) => +task.amount).reduce((sum, amount) => sum + amount, 0);

const TodoList = ({ featuresStore, push, taskAction }: ToDoList) => {
  const customTasksCount = featuresStore.custom?.tasksCount;
  const allTasks = countTasks(featuresStore);

  return (
    <>
      {allTasks !== 0 && (
        <SubsectionHeadline title={t('sidebar.todos.subsections.open_tasks')} showDivider={false} />
      )}
      <TodoFeature
        className={styles.customTile}
        feature={{
          ...featuresStore.custom,
          nameSingular: t('sidebar.tasks.header.singular'),
          namePlural: t('sidebar.tasks.header.plural', {
            tasks: customTasksCount,
          }),
          subtitleSingular: t('sidebar.tasks.subheader.todo.singular'),
          subtitlePlural: t('sidebar.tasks.subheader.todo.plural', {
            tasks: customTasksCount,
          }),
        }}
        featureIcon={customIcon}
        taskComponent={({ task }: Tasks) => <CustomTodoTask task={task as unknown as CustomTask} />}
        onExpand={() => trackEvent('Expand_Custom')}
      />
      <TodoFeature
        feature={featuresStore.sendPaymentReminder}
        featureType={INCOMING}
        featureIcon={reminderIcon}
        detailsHeader={({ feature: { tasks } }: FeatureObj) => (
          <TodoFeatureDetailsHeader title={potentialText} value={amountOfTasks(tasks)} />
        )}
        taskComponent={({ task: { invoiceId, partnerName, number, amount, status } }: Tasks) => (
          <TodoFeatureTask
            number={number}
            title={partnerName}
            value={amount}
            status={status}
            icon={reminderActionIcon}
            action={taskAction(TODO_ACTION_CREATE_PAYMENT_REMINDER)((): any =>
              push(paths.createPaymentReminder(invoiceId))
            )}
          />
        )}
      />
      <TodoFeature
        feature={featuresStore.finalizeDraftFromMobileUpload}
        featureType={OUTGOING}
        featureIcon={finalizeUploadIcon}
        taskComponent={({ task: { invoiceId, partnerName, number, amount, status } }: Tasks) => (
          <TodoFeatureTask
            number={number}
            title={partnerName}
            value={amount}
            status={status}
            isNegative
            icon={finalizeUploadActionIcon}
            action={taskAction(TODO_ACTION_FINALIZE_DRAFT_FROM_UPLOAD)((): any =>
              push(paths.editIncomingInvoice(invoiceId))
            )}
          />
        )}
      />
      <TodoFeature
        feature={featuresStore.sendInvoice}
        featureType={INCOMING}
        featureIcon={finalizeDraftIcon}
        detailsHeader={({ feature: { tasks } }: FeatureObj) => (
          <TodoFeatureDetailsHeader title={potentialText} value={amountOfTasks(tasks)} />
        )}
        taskComponent={({ task: { invoiceId, partnerName, number, amount, status } }: Tasks) => (
          <TodoFeatureTask
            number={number}
            title={partnerName}
            value={amount}
            status={status}
            icon={finalizeDraftActionIcon}
            action={taskAction(TODO_ACTION_FINALIZE_OUTGOING_DRAFT)((): any =>
              push(paths.editOutgoingInvoice(invoiceId))
            )}
          />
        )}
      />
      <TodoFeature
        feature={featuresStore.assignIncomingPayment}
        featureType={INCOMING}
        featureIcon={assignPaymentIcon}
        taskComponent={({ task: { invoiceId, partnerName, number, amount, status } }: Tasks) => (
          <TodoFeatureTask
            number={number}
            title={partnerName}
            value={amount}
            status={status}
            icon={assignPaymentIcon}
            action={taskAction(TODO_ACTION_ASSIGN_ICOMING_PAYMENT)((): any =>
              push(paths.editOutgoingInvoice(invoiceId))
            )}
          />
        )}
      />
      <TodoFeature
        feature={featuresStore.assignOutgoingPayment}
        featureType={OUTGOING}
        featureIcon={assignPaymentIcon}
        taskComponent={({ task: { invoiceId, partnerName, number, amount, status } }: Tasks) => (
          <TodoFeatureTask
            number={number}
            title={partnerName}
            value={amount}
            status={status}
            icon={assignPaymentIcon}
            isNegative
            action={taskAction(TODO_ASSIGN_OUTGOING_PAYMENT)((): any =>
              push(paths.editIncomingInvoice(invoiceId))
            )}
          />
        )}
      />
      <TodoFeature
        feature={featuresStore.useDiscount}
        featureType={OUTGOING}
        featureIcon={discountIcon}
        detailsHeader={({ feature: { discountPotential } }: FeatureObj) => (
          <TodoFeatureDetailsHeader title={discountPotentialText} value={discountPotential} />
        )}
        taskComponent={({ task: { invoiceId, partnerName, number, amount, status } }: Tasks) => (
          <TodoFeatureTask
            number={number}
            title={partnerName}
            value={amount}
            status={status}
            icon={finalizeDraftActionIcon}
            isNegative
            action={taskAction(TODO_ACTION_USE_DISCOUNT)((): any =>
              push(paths.editIncomingInvoice(invoiceId))
            )}
          />
        )}
      />
      <TodoFeature
        feature={featuresStore.payInvoice}
        featureType={OUTGOING}
        featureIcon={reminderIcon}
        taskComponent={({ task: { invoiceId, partnerName, number, amount, status } }: Tasks) => (
          <TodoFeatureTask
            number={number}
            title={partnerName}
            value={amount}
            status={status}
            icon={finalizeDraftActionIcon}
            isNegative
            action={taskAction(TODO_ACTION_PAY_INVOICE)((): any =>
              push(paths.editIncomingInvoice(invoiceId))
            )}
          />
        )}
      />
      <TodoFeature
        feature={featuresStore.payOverdueInvoice}
        featureType={OUTGOING}
        featureIcon={reminderIcon}
        taskComponent={({ task: { invoiceId, partnerName, number, amount, status } }: Tasks) => (
          <TodoFeatureTask
            number={number}
            title={partnerName}
            value={amount}
            status={status}
            icon={finalizeDraftActionIcon}
            isNegative
            action={taskAction(TODO_ACTION_PAY_OVERDUE_PAYMENT)((): any =>
              push(paths.editIncomingInvoice(invoiceId))
            )}
          />
        )}
      />
    </>
  );
};

export default TodoList;
