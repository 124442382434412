import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchIncomingInvoice } from 'actions/incoming-invoice';
import { fetchPayments, getSca } from 'actions/incoming-invoice/payments';
import { showErrorNotification } from 'actions/notification';
import useIsMounted from 'shared/utils/hooks/useIsMounted';
import { trackCreatePaymentSuccessEvent } from 'shared/utils/tracking/payments-tracking';
import Loading from 'components/Loading';
import {
  DECOUPLED_CHALLENGE_RETRY_TIMEOUT,
  ERROR_NOTIFICATION_DURATION,
} from 'features/figoConnection/constants';

import styles from './DecoupledChallenge.module.css';

type DecoupledChallengeProps = {
  payment: {
    accountId: string;
    paymentId: string;
  };
  syncId: string;
  challenge: {
    message: string;
  };
  invoiceId: string;
  invoiceAmount: number;
  paymentDetails: { amount: string };
  setCreatorState: (state: any) => void;
  goToLastStepAction: () => void;
  resetCreatorState: () => void;
};

const DecoupledChallenge = ({
  challenge,
  payment,
  syncId,
  goToLastStepAction,
  setCreatorState,
  invoiceId,
  paymentDetails,
  invoiceAmount,
  resetCreatorState,
}: DecoupledChallengeProps) => {
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const { message } = challenge;
  const { amount: paymentAmount } = paymentDetails;
  const { paymentId: paymentFigoId, accountId: accountFigoId } = payment;

  useEffect(() => {
    let timer: number | undefined;
    const checkSca = async () => {
      let response: any;

      try {
        response = await dispatch(
          getSca({
            paymentFigoId,
            accountFigoId,
            syncId,
          })
        );
      } catch (e: any) {
        if (e.message) {
          dispatch(showErrorNotification(e.message, { duration: ERROR_NOTIFICATION_DURATION }));
        }
        resetCreatorState();
        return;
      }

      if (!isMounted()) {
        return;
      }

      const { submitted, created_at } = response;

      if (submitted) {
        trackCreatePaymentSuccessEvent({ paymentAmount, invoiceAmount });
        setCreatorState({ paymentCreationDate: created_at });
        dispatch(fetchPayments(invoiceId));
        dispatch(fetchIncomingInvoice(invoiceId));
        goToLastStepAction();
        return;
      }

      timer = window.setTimeout(checkSca, DECOUPLED_CHALLENGE_RETRY_TIMEOUT);
    };

    timer = window.setTimeout(checkSca, DECOUPLED_CHALLENGE_RETRY_TIMEOUT);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Loading text={message} />
    </div>
  );
};

export default DecoupledChallenge;
