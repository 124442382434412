import React, { memo, SVGProps } from 'react';

const ZoomOut = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <rect x={4} y={11} width={16} height={2} rx={1} />
      <path
        d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 1.846c5.608 0 10.154 4.546 10.154 10.154S17.608 22.154 12 22.154 1.846 17.608 1.846 12 6.392 1.846 12 1.846z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(ZoomOut);
