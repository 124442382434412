import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { Prompt, Route } from 'react-router-dom';
import { push as pushAction } from 'connected-react-router';

import { MODAL_HEADER_VARIANT } from 'constants/modal';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { ConfirmationModal } from 'components/Modal';

type Location = {
  pathname: string;
};

type WithTransitionPreventDispatchProps = {
  push: (pathname: string) => void;
};

type WithTransitionPreventPassProps = {
  /**
   * Indicates whether form is already submitted or not.
   * Required when submisson causes redirect.
   * Default value after submission is true
   * Comes from redux-form props.
   */
  submitSucceeded: boolean;

  /**
   * Indicates whether form is dirty or not.
   * Comes from redux-form props.
   */
  dirty: boolean;

  /**
   * Overrides default submitSucceeded value.
   * Allows to display modal on views when submission does not trigger redirect and user can make form dirty again.
   */
  isSubmitSucceeded?: boolean;

  /**
   * Indicates if form is already submitting.
   * The in-between stage of submission and submitSucceeded.
   */
  submitting?: boolean;

  /**
   * Overrides default dirty value.
   */
  isDirty?: boolean;

  /**
   * Resets form values.
   * Comes from redux-form props.
   */
  reset(): void;
  disableTransitionPrevent?: boolean;
};

type WithTransitionPreventState = {
  isOpen?: boolean;

  /**
   * Indicates if user already confirmed modal
   * If so allow for route transition
   */
  isConfirmed?: boolean;
  lastLocation: Location;
};

type WithTransitionPreventProps = WithTransitionPreventDispatchProps &
  WithTransitionPreventPassProps;

export type ModalComponentProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  dataIds?: {
    modal: string;
    abortButton: string;
    acceptButton: string;
  };
};

type withTransitionPreventProps = {
  ModalComponent: ComponentType<any>;
  onClose?: (reset: any) => void;
};

export const DefaultModalComponent = (props: ModalComponentProps) => (
  <ConfirmationModal
    headerVariant={MODAL_HEADER_VARIANT.SMALL}
    dangerousAction
    closeLabel={t('transition_prevent_modal.cancel')}
    confirmLabel={t('transition_prevent_modal.confirm')}
    header={t('transition_prevent_modal.header')}
    hasWarningIcon
    withCloseButton
    {...props}
  >
    {t('transition_prevent_modal.content')}
  </ConfirmationModal>
);

const withTransitionPrevent =
  (
    { ModalComponent, onClose }: withTransitionPreventProps = {
      ModalComponent: DefaultModalComponent,
    }
  ) =>
  (WrappedComponent: ComponentType<any>) => {
    class WithTransitionPrevent extends Component<
      WithTransitionPreventProps,
      WithTransitionPreventState
    > {
      defaultState = {
        isOpen: false,
        isConfirmed: false,
        lastLocation: {
          pathname: '',
        },
      };

      state = this.defaultState;

      displayName = `withTransitionPrevent('Component')`;

      /**
       * Shows modal
       *
       * @param location - location to redirect
       */
      showModal = (location: Location) =>
        this.setState({
          isOpen: true,
          lastLocation: location,
        });

      /**
       * Closes modal
       *
       * @param callback - Function to invoke after closing modal.
       */
      closeModal = (callback: (() => void) | undefined) =>
        this.setState(
          {
            isOpen: false,
            isConfirmed: true,
          },
          callback
        );

      whichFlagToSelect = (passedFlag: boolean | undefined, reduxFormFag: boolean) =>
        typeof passedFlag === 'undefined' ? reduxFormFag : passedFlag;

      /**
       * Indicates if modal should appear or not
       *
       * @param nextLocation - Next router path to which user wants to transit
       * @param  currentPathname - Current router path
       * @returns Display modal flag for <Prompt /> component and callback function
       */
      handleBlockedNavigation = (nextLocation: Location, currentPathname: string): boolean => {
        const {
          dirty,
          isDirty,
          submitting,
          submitSucceeded,
          isSubmitSucceeded,
          disableTransitionPrevent,
        } = this.props;
        const { isConfirmed } = this.state;
        const isRedirectToSameLocation = nextLocation.pathname === currentPathname;
        const submitSucceededFlag = this.whichFlagToSelect(isSubmitSucceeded, submitSucceeded);
        const isRedirectingToLogin = nextLocation.pathname === paths.login;

        if (
          !submitSucceededFlag &&
          (dirty || isDirty) &&
          !isRedirectToSameLocation &&
          !isConfirmed &&
          !submitting &&
          !isRedirectingToLogin &&
          !disableTransitionPrevent
        ) {
          this.showModal(nextLocation);
          return false;
        }
        return true;
      };

      /**
       * Pushes new path in browser.
       * Passes push function as callback.
       */
      handleConfirm = () =>
        this.closeModal(() => {
          this.props.push(this.state.lastLocation.pathname);

          if (onClose) {
            this.setState(this.defaultState);
            onClose(this.props.reset);
          }
        });

      /**
       * Rejects transition.
       * Passes set state to defaut function as callback.
       */
      handleClose = () => this.closeModal(() => this.setState(this.defaultState));

      render() {
        const { isOpen } = this.state;
        const { dirty, isDirty } = this.props;

        return (
          <>
            <Route
              render={({ location: { pathname } }) => (
                <>
                  <Prompt
                    when={dirty || isDirty}
                    message={(nextLocation) => this.handleBlockedNavigation(nextLocation, pathname)}
                  />
                  <ModalComponent
                    isOpen={isOpen}
                    onClose={this.handleClose}
                    onConfirm={this.handleConfirm}
                    dataIds={{
                      modal: 'TransitionPreventModal:modal',
                      abortButton: 'TransitionPreventModal:abortButton',
                      acceptButton: 'TransitionPreventModal:acceptButton',
                    }}
                  />
                </>
              )}
            />

            <WrappedComponent {...this.props} />
          </>
        );
      }
    }

    return connect<{}, WithTransitionPreventDispatchProps, WithTransitionPreventPassProps>(null, {
      push: pushAction,
    })(WithTransitionPrevent);
  };

export default withTransitionPrevent;
