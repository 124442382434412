import React from 'react';

import styles from './FormLayout.module.css';

interface FormRowProps {
  children: React.ReactNode;
}

const FormRow = ({ children }: FormRowProps) => <div className={styles.row}>{children}</div>;

export default FormRow;
