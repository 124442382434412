export const ImageSettings = {
  MIN_WIDTH: 100,
  MIN_HEIGHT: 100,
  MAX_WIDTH: 405,
  MAX_WIDTH_MOBILE: 305,
  MAX_HEIGHT: 405,
  STEP_SIZE: 50,
};

export enum Format {
  Photo = 'PHOTO',
  Text = 'TEXT',
  Html = 'HTML',
  Hhd = 'HHD',
}
