import styled from 'styled-components';

export const Header = styled.div`
  position: relative;
`;

export const Label = styled.label`
  margin: 0;
  padding: 8px 0;
  font-size: 10px;
  color: #777;
`;
