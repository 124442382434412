import styled from 'styled-components';

import media from 'shared/styles/breakpoints';

export const Wrapper = styled.div`
  height: 100px;
  display: grid;
  align-items: center;
  justify-items: center;

  ${media.greaterThan('md-up')`
    height: 497px;
  `}
`;

export const Message = styled.div`
  color: ${({ theme }) => theme.colors.grey['070']};
`;
