import { WrappedFieldProps } from 'redux-form';

import { required } from 'shared/utils/formValidation';

export const requiredValidate = (value: string) => !!required(value);

export const requiredAndTouchedChecker = (props: WrappedFieldProps) =>
  props.meta.touched && requiredValidate(props.input.value);

export const isAnyFieldFilled: <Row>(rowValues: Row) => boolean = (rowValues) =>
  Object.values(rowValues).some((value: any) => !!value);
