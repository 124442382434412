import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'classnames';
import isArray from 'lodash/isArray';

import { TabbedNavigationContext } from '../TabbedNavigation';

import styles from './TabContent.module.css';

interface TabContentProps {
  children?: ReactNode | (() => ReactNode);
  className?: string;
  tabName: string | string[];
  preventTabRerender?: boolean;
}

const TabContent: FunctionComponent<TabContentProps> = ({
  children,
  className = '',
  tabName,
  preventTabRerender = false,
}: TabContentProps) => {
  return (
    <TabbedNavigationContext.Consumer>
      {({ selectedTabName }) => {
        const isTabVisible = isArray(tabName)
          ? tabName.some((tab) => tab === selectedTabName)
          : tabName === selectedTabName;

        if (!isTabVisible && !preventTabRerender) return null;

        return (
          <div className={cx(className, { [styles.tabInvisible]: !isTabVisible })}>{children}</div>
        );
      }}
    </TabbedNavigationContext.Consumer>
  );
};

export default TabContent;
