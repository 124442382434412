import React, { SVGAttributes } from 'react';

const IconSale = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 36 43" {...props}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1.8 11.679)" stroke="#0066B3" strokeWidth={1.8}>
        <path d="M5.4 28.504V10.689M27.45 28.218V10.689" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M0 10.689L16.2 0l16.2 10.689z" />
        <path fill="#0066B3" d="M11.7 10.689v19.596M20.7 10.689v17.815" />
        <rect fill="#0066B3" x={2.7} y={27.622} width={27} height={1.763} rx={0.881} />
      </g>
      <g transform="translate(5)">
        <ellipse fill="#0066B3" cx={12.96} cy={8.551} rx={8.64} ry={8.551} />
        <g fill="#FFF">
          <path d="M9.186 8.159c-.3 0-.546-.27-.546-.607 0-.336.245-.608.546-.608h4.903c.302 0 .546.272.546.608 0 .335-.244.607-.547.607H9.186zM14.09 9.963H9.185c-.3 0-.546-.272-.546-.608 0-.334.245-.606.546-.606h4.903c.302 0 .546.27.546.607 0 .337-.244.609-.547.609" />
          <path d="M13.487 12.548c-2.148 0-3.897-1.855-3.897-4.135 0-2.281 1.749-4.137 3.897-4.137.514 0 1.016.106 1.491.315.285.125.42.472.301.774a.544.544 0 01-.726.318 2.646 2.646 0 00-1.065-.225c-1.535 0-2.783 1.326-2.783 2.955 0 1.63 1.247 2.954 2.783 2.954.367 0 .726-.075 1.065-.224a.546.546 0 01.727.32.602.602 0 01-.3.773c-.475.209-.977.315-1.491.315" />
        </g>
      </g>
    </g>
  </svg>
);

export default IconSale;
