import React from 'react';
import camelCase from 'lodash/camelCase';
import { shape } from 'prop-types';

import { DATEV_SUPPLIERS_COLUMNS } from 'constants/datev';
import tableStyles from 'shared/styles/table.module.css';

import InvoiceColumn from '../../InvoiceColumn';

const SupplierRow = ({ supplier }) => (
  <tr data-id="Datev:new-export-table-row">
    {DATEV_SUPPLIERS_COLUMNS.map(({ column }) => (
      <InvoiceColumn key={column} className={tableStyles.cell}>
        {supplier[camelCase(column)]}
      </InvoiceColumn>
    ))}
  </tr>
);

SupplierRow.propTypes = {
  supplier: shape({}).isRequired,
};

export default SupplierRow;
