import styled, { css } from 'styled-components';

import BaseIconArrowDown from 'redesign/components/atoms/Icons/ArrowDown';
import TextInput, {
  disabledPlaceholder,
  placeholder,
} from 'redesign/styles/utils/TextInput.styled';

export const Wrapper = styled.div`
  position: relative;
`;

export const Button = styled.button`
  ${TextInput}
  display: grid;
  grid-template-columns: 1fr auto;
  text-align: left;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

type IconArrowDownProps = {
  $isDisabled?: boolean;
};

export const IconArrowDown = styled(BaseIconArrowDown)<IconArrowDownProps>`
  width: 25px;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  cursor: pointer;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: not-allowed;
      color: ${({ theme }) => theme.colors.grey['040']};
    `}
`;

type PlaceholderProps = {
  $isDisabled?: boolean;
};

export const Placeholder = styled.span<PlaceholderProps>`
  ${placeholder}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      ${disabledPlaceholder}
    `}
`;

type TMenuOrientation = {
  topAlignment?: boolean;
};

export const Menu = styled.ul<TMenuOrientation>`
  position: absolute;
  width: 100%;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.4);
  margin-top: ${({ theme }) => theme.space['1']};
  z-index: 50005;
  max-height: 350px;
  overflow-y: auto;
  outline: none;
  ${({ topAlignment }) =>
    topAlignment
      ? css`
          bottom: 45px;
        `
      : css`
          transform: translate3d(0, 0, 0);
        `};
`;

type MenuItemProps = {
  $isHighlighted?: boolean;
  $isSelected?: boolean;
};

export const MenuItem = styled.li<MenuItemProps>`
  padding: ${({ theme }) => theme.space['3']};
  display: flex;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey['080']};
  user-select: none;
  flex-direction: column;
  justify-content: center;
  letter-spacing: 0.66px;
  line-height: 19px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.vrblue['025']};
    font-weight: bold;
  }

  &:active {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.vrblue['100']};
    font-weight: bold;
  }

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      background-color: ${({ theme }) => theme.colors.vrblue['025']};
      font-weight: bold;
    `}

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      font-weight: bold;
    `}
`;
