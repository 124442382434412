import React, { Component } from 'react';
import { bool, number, string } from 'prop-types';
import { Field } from 'redux-form';

import { ensureNumericMoneyValue } from 'shared/utils/money';
import FormField from 'components/Form/FormField';

class CurrencyField extends Component {
  static defaultProps = {
    isRawField: false,
    initialParsedValue: '',
    isCurrencySignVisible: true,
  };

  state = {
    parsedValue: '',
    initialParsedValue: '',
  };

  normalizeValue = (value) => {
    this.setState({ parsedValue: ensureNumericMoneyValue(value) });

    return value;
  };

  static getDerivedStateFromProps({ initialParsedValue }, prevState) {
    if (
      initialParsedValue === prevState.initialParsedValue ||
      initialParsedValue === prevState.parsedValue
    ) {
      return null;
    }
    return { parsedValue: initialParsedValue, initialParsedValue };
  }

  formatValue = (value) => ensureNumericMoneyValue(value);

  render() {
    const { isCurrencySignVisible } = this.props;
    const FieldWrapper = this.props.isRawField ? Field : FormField;

    return (
      <FieldWrapper
        {...this.props}
        type="number"
        parse={(value) => ensureNumericMoneyValue(value)}
        step={0.001}
        normalize={this.normalizeValue}
        onPaste={(e) => {
          e.preventDefault();
          const pastedValue = e.clipboardData.getData('text');
          const parsedValue = ensureNumericMoneyValue(pastedValue);
          e.target.value = parsedValue;
        }}
        // passing this prop seems to be redundant, but in fact it is required due to redux-form changing detection system
        parsedValue={this.state.parsedValue}
        isCurrency={isCurrencySignVisible}
      />
    );
  }
}

CurrencyField.propTypes = {
  isRawField: bool,
  initialParsedValue: string,
  precision: number,
  isCurrencySignVisible: bool,
  onlyPositive: bool,
};

export default CurrencyField;
