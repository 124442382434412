import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import cx from 'classnames';
import { push } from 'connected-react-router';

import { resendConfirmationEmail as resendConfirmationEmailAction } from 'actions/auth';
import { ReactComponent as WarningIcon } from 'images/icon-exclamation-transparent.svg';
import paths from 'routes/paths';
import { useQuery } from 'shared/hooks/useQuery';
import { t } from 'shared/utils';
import Button from 'components/Button';
import CardView, { HeadingSection, Section } from 'components/CardView';

import styles from './UnconfirmedUserPage.module.css';

interface UnconfirmedUserPageProps extends RouteComponentProps<MatchParams> {
  isUnconfirmed: boolean;
  redirectTo: (path: string) => void;
  resendConfirmationEmail: (token: string, email?: string | null) => Promise<any>;
}

interface MatchParams {
  token: string;
}

const UnconfirmedUserPage: FunctionComponent<UnconfirmedUserPageProps> = ({
  isUnconfirmed,
  redirectTo,
  resendConfirmationEmail,
  match,
  location,
}) => {
  useEffect(() => {
    if (!isUnconfirmed) redirectTo(paths.login);
  }, [isUnconfirmed, redirectTo]);

  const [isLoading, setIsLoading] = useState(false);
  const query = useQuery(location);

  const handleResendConfirmationEmail = () => {
    const { token } = match.params;
    const userEmail = query.get('email');

    setIsLoading(true);
    resendConfirmationEmail(token, userEmail).then(() => setIsLoading(false));
  };

  return (
    <section data-id="UnconfirmedUserPage:container" className={styles.wrapper}>
      <CardView className={styles.card}>
        <HeadingSection dataId="UnconfirmedUserPage:header">
          <h1 className={styles.header}>{t('login.unconfirmed.header')}</h1>
        </HeadingSection>
        <Section dataId="UnconfirmedUserPage:content" className={styles.cardSection}>
          <div className={styles.iconContainer}>
            <WarningIcon className={styles.icon} />
          </div>
          <p className={styles.infoParagraph}>{t('login.unconfirmed.first_paragraph')}</p>
          <p className={cx(styles.infoParagraph, styles.importantInfoParagraph)}>
            {t('login.unconfirmed.second_paragraph')}
          </p>
          <Button
            centered
            disabled={isLoading}
            className={styles.actionButton}
            dataId="AccountInfoPublicArea:action-button"
            onClick={handleResendConfirmationEmail}
            label={t('login.unconfirmed.button_label')}
          />
          <p className={styles.footerParagraph}>
            {t('login.unconfirmed.footer_first_line')} <br />
            {t('login.unconfirmed.footer_second_line')}
          </p>
        </Section>
      </CardView>
    </section>
  );
};

const mapStateToProps = (state: any) => ({
  isUnconfirmed: state.auth.loginInfo ? state.auth.loginInfo.confirmed === false : false,
});

const mapDispatchToProps = {
  redirectTo: push,
  resendConfirmationEmail: resendConfirmationEmailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnconfirmedUserPage);
