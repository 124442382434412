import counties from 'constants/counties';
import taxationTypes from 'constants/taxation-types';
import { t } from 'shared/utils/index';

export const parseRegistrationNumber = (prefix: string) => (value: string) => {
  if (value && value.indexOf(prefix) === 0) return value;
  return prefix;
};

export const formatRegistrationNumber = (prefix: string, disabled: boolean) => (value: string) => {
  if (disabled) return '-';
  if (value && value.indexOf(prefix) === 0) return value;
  return prefix;
};

/**
 * If 'registration court' had been populated at first and 'legal form' was cleared, then the 'legal form' shouldn't be displayed
 * Field should be formatted in such a way that it is empty and in a result of this action default placeholder ('-') is displayed
 */
export const formatRegistrationCourt = (disabled: boolean) => (value: string) =>
  disabled ? '' : value;

export const countyOptions = counties.map((county) => ({ value: county, label: county }));

export const taxationTypeOptions = taxationTypes.map((taxationType) => ({
  value: taxationType,
  label: t(`profile.company.taxation_types.${taxationType}`),
}));

export const charts_of_accounts_options = [
  { value: 'skr_03', label: 'SKR03' },
  { value: 'skr_04', label: 'SKR04' },
];
