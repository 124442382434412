import React, { ReactNode, useState } from 'react';

import { t } from 'shared/utils';
import { FullTextSearch } from 'components/FullTextSearch/FullTextSearch';

import styles from './FiltersGroup.module.css';

interface Filters {
  fullTextSearch?: string | null;
}

interface FiltersGroupProps<T extends Filters> {
  additionalFilters?: ReactNode;
  filters: T;
  onFiltersChange: (filter: { name: keyof T; value: any }) => void;
  onFiltersReset: () => void;
  onFiltersDropdownToggle?: (areAdditionalFiltersVisible: boolean) => void;
  isResetButtonDisabled?: boolean;
  label?: string;
  placeholder?: string;
  className?: string;
  dataIds?: {
    input?: string;
    resetButton?: string;
    toggleDropdownButton?: string;
  };
  children?: ReactNode;
}

/**
 * This is a container component for FullTextSearch and AdditionalFiltersGroup.
 * FullTextSearch is always displayed.
 * AdditionalFiltersGroup displays set of additional filters passed in additionalFilters prop.
 * By default, additional filters are hidden. They could be shown by clicking on the toggle button.
 * Component accepts children, they will be placed next to FullTextSearch.
 */
const FiltersGroup = <T extends Filters>({
  label = t('tables.filters.filters_group.label'),
  placeholder = t('tables.filters.filters_group.placeholder'),
  filters,
  additionalFilters,
  onFiltersChange,
  onFiltersReset,
  onFiltersDropdownToggle,
  isResetButtonDisabled = false,
  children,
  className = '',
  dataIds = {},
}: FiltersGroupProps<T>) => {
  const [areAdditionalFiltersVisible, setAreAdditionalFiltersVisible] = useState(false);

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.fullTextRow}>
        <FullTextSearch
          label={label}
          placeholder={placeholder}
          value={filters.fullTextSearch ?? ''}
          onChange={(value) => onFiltersChange({ name: 'fullTextSearch', value })}
          areAdditionalFiltersOpened={areAdditionalFiltersVisible}
          areAdditionalFiltersAvailable={Boolean(additionalFilters)}
          onResetButtonClick={onFiltersReset}
          isResetButtonDisabled={isResetButtonDisabled}
          onFilterDropdownClick={() => {
            if (onFiltersDropdownToggle) onFiltersDropdownToggle(!areAdditionalFiltersVisible);

            setAreAdditionalFiltersVisible(
              (prevAdditionalFiltersVisible) => !prevAdditionalFiltersVisible
            );
          }}
          dataIds={{
            input: dataIds.input,
            resetButton: dataIds.resetButton,
            toggleDropdownButton: dataIds.toggleDropdownButton,
          }}
        >
          {children}
        </FullTextSearch>
      </div>
      {areAdditionalFiltersVisible && additionalFilters}
    </div>
  );
};

export { FiltersGroup };
