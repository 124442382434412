import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.space['3']};
  justify-content: center;

  & button {
    min-width: 90px;
  }

  & button:not(:last-child) {
    margin-right: ${({ theme }) => theme.space['4']};
  }
`;
