export const UPDATE_REQUEST = 'BANK_ACCOUNT/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'BANK_ACCOUNT/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'BANK_ACCOUNT/UPDATE_FAILURE';

export const SET_MAIN_REQUEST = 'BANK_ACCOUNT/SET_MAIN_REQUEST';
export const SET_MAIN_SUCCESS = 'BANK_ACCOUNT/SET_MAIN_SUCCESS';
export const SET_MAIN_FAILURE = 'BANK_ACCOUNT/SET_MAIN_FAILURE';

export const DELETE_REQUEST = 'BANK_ACCOUNT/DELETE_REQUEST';
export const DELETE_SUCCESS = 'BANK_ACCOUNT/DELETE_SUCCESS';
export const DELETE_FAILURE = 'BANK_ACCOUNT/DELETE_FAILURE';

export const INDEX_REQUEST = 'BANK_ACCOUNT/INDEX_REQUEST';
export const INDEX_SUCCESS = 'BANK_ACCOUNT/INDEX_SUCCESS';
export const INDEX_FAILURE = 'BANK_ACCOUNT/INDEX_FAILURE';

export const FETCH_REQUEST = 'BANK_ACCOUNT/FETCH_REQUEST';
export const FETCH_SUCCESS = 'BANK_ACCOUNT/FETCH_SUCCESS';
export const FETCH_FAILURE = 'BANK_ACCOUNT/FETCH_FAILURE';

export const CREATE_REQUEST = 'BANK_ACCOUNT/CREATE_REQUEST';
export const CREATE_SUCCESS = 'BANK_ACCOUNT/CREATE_SUCCESS';
export const CREATE_FAILURE = 'BANK_ACCOUNT/CREATE_FAILURE';

export const REORDER_REQUEST = 'BANK_ACCOUNT/REORDER_REQUEST';
export const REORDER_SUCCESS = 'BANK_ACCOUNT/REORDER_SUCCESS';
export const REORDER_FAILURE = 'BANK_ACCOUNT/REORDER_FAILURE';

export const FETCH_TRANSFERS_REQUEST = 'BANK_ACCOUNT/FETCH_TRANSFERS_REQUEST';
export const FETCH_TRANSFERS_SUCCESS = 'BANK_ACCOUNT/FETCH_TRANSFERS_SUCCESS';
export const FETCH_TRANSFERS_FAILURE = 'BANK_ACCOUNT/FETCH_TRANSFERS_FAILURE';

export const FETCH_OUT_OF_SYNC_FAILURE = 'BANK_ACCOUNT/FETCH_OUT_OF_SYNC_FAILURE';
export const FETCH_OUT_OF_SYNC_REQUEST = 'BANK_ACCOUNT/FETCH_OUT_OF_SYNC_REQUEST';
export const FETCH_OUT_OF_SYNC_SUCCESS = 'BANK_ACCOUNT/FETCH_OUT_OF_SYNC_SUCCESS';

export const SYNC_FAILURE = 'BANK_ACCOUNT/SYNC_FAILURE';
export const SYNC_REQUEST = 'BANK_ACCOUNT/SYNC_REQUEST';
export const SYNC_SUCCESS = 'BANK_ACCOUNT/SYNC_SUCCESS';
