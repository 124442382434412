import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { CREATE_SUCCESS, FETCH_CONTRACT_SUCCESS } from 'constants/contracts';
import EntityPath from 'constants/entitiesPaths';
import type { ExpenseContract, RevenueContract } from 'types/entities/Contract';

const defaultState: ExpenseContract | RevenueContract | null = null;

export default (state = defaultState, action: { type: string; response: any }) => {
  switch (action.type) {
    case FETCH_CONTRACT_SUCCESS:
    case CREATE_SUCCESS:
      const [contract] = build<ExpenseContract | RevenueContract>(
        normalize(action.response),
        EntityPath.Contracts,
        null,
        {
          ignoreLinks: true,
        }
      );

      return contract;

    default:
      return state;
  }
};
