import React from 'react';
import cx from 'classnames';
import head from 'lodash/head';
import { bool, func, number, oneOfType, string } from 'prop-types';

import { TRANSLATE_OPTION_ARRAY } from 'constants/assigment-label';
import { formatMoney, t } from 'shared/utils';
import Comment from 'components/Comment/Comment';
import IncomingInvoiceAmount from 'components/Table/IncomingInvoiceAmount';

import { EmptyCell } from './Label.styled';

import styles from './AssignmentsStyles.module.css';

const getTranslatedSelectedCategory = (category) =>
  head(TRANSLATE_OPTION_ARRAY.filter((option) => option.apiValue === category)) || {};

const Label = ({
  date,
  isBankTransferPositive,
  amountAssigned,
  category,
  note,
  onEdit,
  onDelete,
}) => {
  const { label } = getTranslatedSelectedCategory(category);

  return (
    <div
      data-id={`BankTransferAssignment:assigned-bank-transfer-container_${date}`}
      className={styles.bankTransfer}
    >
      <div className={styles.dateWrapper}>
        <div data-id="BankTransferAssignment:assigned-bank-transfer-date" className={styles.date}>
          {date}
        </div>
      </div>
      <div className={styles.sender}>-</div>
      <div className={styles.sender}>-</div>
      {!isBankTransferPositive ? (
        <IncomingInvoiceAmount
          dataId="BankTransferAssignment:assigned-bank-transfer-amount"
          bold
          amount={amountAssigned}
        />
      ) : (
        <div
          data-id="BankTransferAssignment:assigned-bank-transfer-amount"
          className={cx(styles.amount, { [styles.amountNegative]: amountAssigned < 0 })}
        >
          {formatMoney(amountAssigned)}
        </div>
      )}
      <EmptyCell />
      <EmptyCell />
      <div className={styles.commentWrapper}>
        {!!note && (
          <Comment
            dataIds={{
              icon: 'BankTransferAssignment:assigned-bank-transfer-icon',
            }}
            text={
              <div className={styles.selectedOptionWrapper}>
                <span
                  data-id="BankTransferAssignment:assigned-bank-transfer-label"
                  className={styles.selectedOption}
                >
                  {label}
                </span>
                <span
                  data-id="BankTransferAssignment:assigned-bank-transfer-note"
                  className={styles.notes}
                >
                  {note}
                </span>
              </div>
            }
          />
        )}
      </div>
      <div className={styles.actionButtons}>
        {onEdit && (
          <button
            type="button"
            onClick={() => onEdit()}
            className={styles.edit}
            title={t('bank_transfers.select.actions.edit')}
            data-id="BankTransferAssignment:button-edit-assigned-bank-transfer"
          />
        )}
        {onDelete && (
          <button
            type="button"
            onClick={onDelete}
            className={styles.delete}
            title={t('bank_transfers.select.actions.unassign')}
            data-id="BankTransferAssignment:button-delete-assigned-bank-transfer"
          />
        )}
      </div>
    </div>
  );
};

Label.propTypes = {
  date: string.isRequired,
  amountAssigned: oneOfType([number, string]).isRequired,
  category: string.isRequired,
  onDelete: func,
  onEdit: func,
  isBankTransferPositive: bool.isRequired,
  note: string,
};

export default Label;
