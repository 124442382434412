import React from 'react';

import { NEW_MODE } from 'constants/common/crud';
import { t } from 'shared/utils';
import type { ExpenseContract, RevenueContract } from 'types/entities/Contract';
import ContractCreator from 'features/contracts/Creator/Creator';

import { Wrapper } from './NewContract.styled';

type NewContractProps = {
  contractType: 'basic' | 'loan';
  onCancel: () => void;
  onSuccess: (contract: ExpenseContract | RevenueContract) => void;
  isRevenue?: boolean;
};

const NewContract = ({
  contractType,
  onSuccess,
  onCancel,
  isRevenue = false,
}: NewContractProps) => {
  return (
    <Wrapper>
      <ContractCreator
        crudMode={NEW_MODE}
        contractType={contractType}
        onCancel={onCancel}
        onSuccess={onSuccess}
        saveLabel={t('features.bank_transfers.add_contract_modal.new_contract.save_label')}
        isRevenue={isRevenue}
      />
    </Wrapper>
  );
};

export default NewContract;
