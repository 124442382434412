import React from 'react';
import { bool, string } from 'prop-types';

import { t } from 'shared/utils';

import styles from '../Description.module.css';

const PlanDate = ({ isFuture, date }) => {
  const translation = isFuture
    ? 'profile.payment_plan.current_payment_plan.valid_from'
    : 'profile.payment_plan.current_payment_plan.valid_until';

  return <span className={styles.planDate}>{t(translation, { date })}</span>;
};

PlanDate.propTypes = {
  isFuture: bool,
  date: string,
};

export default PlanDate;
