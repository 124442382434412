import React from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { acceptTermsOfUse, rejectTermsOfUse } from 'actions/registration';
import { t } from 'shared/utils';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import CardView, { HeadingSection, Section } from 'components/CardView';
import I18n from 'components/I18n';
import LegalsComponent from 'components/Legals';

import styles from '../RegistrationForm.module.css';

type TermsOfUseProps = {
  title: string;
  body: string;
};

const TermsOfUse = ({ title, body }: TermsOfUseProps) => {
  const dispatch = useDispatch();
  const accept = () => dispatch(acceptTermsOfUse());
  const reject = () => dispatch(rejectTermsOfUse());

  return (
    <CardView className={styles.card}>
      <HeadingSection className={styles.cardSectionHeading}>
        <h3 className={styles.headingText}>
          <I18n t="registration.terms_of_use.title" />
        </h3>
      </HeadingSection>
      <Section className={cx(styles.cardSection, styles.contentSection)}>
        <div className={styles.tos}>
          <LegalsComponent body={body} title={title} />
        </div>
      </Section>
      <ActionPanel>
        <ActionButton
          appearance="primary"
          label={t('registration.terms_of_use.agree')}
          onClick={accept}
        />
        <ActionButton
          appearance="outlined"
          label={t('registration.terms_of_use.disagree')}
          onClick={reject}
          dataId="button-conditions-cancel"
        />
      </ActionPanel>
    </CardView>
  );
};

export default TermsOfUse;
