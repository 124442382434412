import { Bank } from './Bank';

export enum BankName {
  PayPal = 'PayPal',
}

export type BankAccount = {
  id: string;
  name: string;
  number: string;
  bank: Bank;
  bankCode: string;
  bankName: BankName | string;
  bic: string;
  iban: string;
  accountId: string;
  bankIdentifier: string;
  balance: number;
  figoBalanceDate: null | string;
  credentials: { [key: string]: any }[];
  showOnInvoice: boolean;
  creditLine: number;
  main: true;
  paymentApplicable: boolean;
  updatedAt: string;
  vrBankAccount: boolean;
  ordinalNumber: number;
  accessMethodType: string;
  deletable: boolean;
  meta: {
    actions: { sync: boolean };
    fetchingInProgress: boolean;
    fetchingSuccessful: boolean;
    savePin: boolean;
    averageRevenues: number;
    averageExpenses: number;
  };
};
