export enum JobStatus {
  Failed = 'FAILED',
  AwaitAuth = 'AWAIT_AUTH',
  Completed = 'COMPLETED',
}

export enum ChallengeType {
  MethodSelection = 'METHOD_SELECTION',
  Embedded = 'EMBEDDED',
  Redirect = 'REDIRECT',
  Decoupled = 'DECOUPLED',
}

export enum AccessMethodType {
  Fints = 'FINTS',
  Scraping = 'SCRAPING',
  Api = 'API',
}

export type RedirectChallenge = {
  id: string;
  created_at: string;
  type: 'REDIRECT';
  location: string;
};

export type DecoupledChallenge = {
  id: string;
  created_at: string;
  type: 'DECOUPLED';
  message: string;
};
