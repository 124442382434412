import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import LoadingIcon from 'components/LoadingIcon';

import styles from './Loading.module.css';

const Loading = ({
  staticIconPosition = false,
  fixedPosition = false,
  alwaysOnTop = false,
  enlarged = false,
  misty = false,
  text = '',
  dataId = '',
}) => (
  <div
    className={cx(styles.loading, {
      [styles.fixedPosition]: fixedPosition,
      [styles.alwaysOnTop]: alwaysOnTop,
      [styles.misty]: misty,
    })}
    data-id={dataId}
  >
    <LoadingIcon
      staticPosition={staticIconPosition}
      alwaysOnTop={alwaysOnTop}
      text={text}
      enlarged={enlarged}
    />
  </div>
);

Loading.propTypes = {
  staticIconPosition: PropTypes.bool,
  fixedPosition: PropTypes.bool,
  alwaysOnTop: PropTypes.bool,
  enlarged: PropTypes.bool,
  misty: PropTypes.bool,
  text: PropTypes.string,
  dataId: PropTypes.string,
};

export default Loading;
