import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import paths from 'routes/paths';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import Button from 'redesign/components/atoms/Button/Button';

import { trackEvent } from '../utils';

const NewRevenueButton = () => {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(paths.newRevenueContract);
    piwikHelpers.trackEvent('WiederkehrendeEinnahmen', 'wiederkehrende Einnahme hinzufuegen');
    trackEvent('Create_Misc_Income');
  }, [history]);

  return (
    <Button onClick={handleClick} data-id="add-revenue">
      {t('payment_reminders.create_new.empty_state')}
    </Button>
  );
};

export default NewRevenueButton;
