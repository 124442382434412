import React from 'react';
import { string } from 'prop-types';

import { t } from 'shared/utils';
import InfoIcon from 'components/InfoIcon/InfoIcon';

import styles from './Description.module.css';

const PremiumDescription = () => (
  <div className={styles.premiumDescriptionWrapper}>
    <div className={styles.premiumDescription}>
      {t('profile.payment_plan.comparison_box.descirption.premium')}
      <InfoIcon text={t('profile.payment_plan.current_payment_plan.info_icon')} />
      <div className={styles.price}>
        <span>{t('profile.payment_plan.comparison_box.monthly_price')}</span>
        <span>10,00</span>
        <span>€*</span>
      </div>
    </div>
    <span className={styles.tax}>{t('profile.payment_plan.tax')}</span>
  </div>
);

const content = {
  basic: (
    <span className={styles.basic}>
      {t('profile.payment_plan.comparison_box.descirption.basic')}
    </span>
  ),
  premium: <PremiumDescription />,
};

const Description = ({ currentPlan }) => (
  <div className={styles.description}>{content[currentPlan]}</div>
);

Description.propTypes = {
  currentPlan: string.isRequired,
};

export default Description;
