import React, { HTMLProps, memo } from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';

import trashBin from 'images/common/trash-bin.svg';
import { t } from 'shared/utils';

import styles from './RemoveButton.module.css';

type RemoveButtonProps = { withText?: boolean; dataId?: string } & HTMLProps<HTMLButtonElement>;

const RemoveButton = ({ withText = false, dataId, ...rest }: RemoveButtonProps) => (
  <button
    {...rest}
    type="button"
    className={cx(styles.button, { [styles.mobile]: withText })}
    data-id={dataId}
  >
    <InlineSvg src={trashBin} className={styles.icon} />
    {withText && (
      <span className={styles.text}>
        {t('features.financial_planning.liquidity_calculator.expenses.remove_expense')}
      </span>
    )}
  </button>
);

export default memo(RemoveButton);
