import React from 'react';
import { string } from 'prop-types';

import styles from './Hint.module.css';

const Hint = ({ text }) => <span className={styles.hint}>{text}</span>;

Hint.propTypes = {
  text: string.isRequired,
};

export default Hint;
