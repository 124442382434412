import { get } from 'lodash';

import { paginationParams, sortingParams } from 'actions/helpers/table';
import { CALL_API } from 'constants/api';
import { CLEAR_FILTERS, SET_DATE_RANGE } from 'constants/common/filters';
import {
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SORT,
  INDEX_SUCCESS,
  NAMESPACE,
} from 'constants/proposals';
import { Resources } from 'constants/resources';
import { getSanitizedFilters } from 'reducers/common/filters';
import { apiErrorHandler } from 'shared/utils/error-handlers';

import * as actions from './index';

const apiIndex = (pagination = {}, sorting = {}, filters = {}) => ({
  [CALL_API]: {
    params: {
      ...paginationParams({ pagination_resource: Resources.PROPOSALS, ...pagination }),
      ...sortingParams(sorting),
      filters,
    },
    endpoint: '/me/proposals',
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
  },
});

export const sortProposals = (column) => (dispatch) =>
  dispatch({
    type: INDEX_SORT,
    column,
  });

export const fetchProposals =
  (pagination, sorting, { noFiltering = false } = {}) =>
  (dispatch, getState) => {
    const filters = noFiltering
      ? {}
      : getSanitizedFilters(get(getState(), 'proposals.parsedFilters', {}));

    return dispatch(apiIndex(pagination, sorting, filters)).catch(apiErrorHandler);
  };

export const setQueryParam = actions.setQueryParam({ name: NAMESPACE });

export const setDateRange = (param) => (dispatch) => (values) =>
  dispatch({
    type: SET_DATE_RANGE,
    name: NAMESPACE,
    payload: {
      param,
      values,
    },
  });

export const clearFilters = ({ clearStatus = false } = {}) => ({
  type: CLEAR_FILTERS,
  name: NAMESPACE,
  payload: {
    clearStatus,
  },
});
