import React from 'react';

import { t } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';
import InfoIcon from 'components/InfoIcon/InfoIcon';

import styles from './PaymentPlanWizardCommon.module.css';

const PaymentPlanWizardCommon = ({ closeWizard = () => {} }) => (
  <div
    className={styles.close}
    onClick={closeWizard}
    role="button"
    onKeyPress={isPressedEnter(() => closeWizard())}
  >
    <span>{t('payment_plan_wizard.remind_later')}</span>
    <InfoIcon
      text={t('payment_plan_wizard.info_icon')}
      tooltipPlacement="left"
      tooltipClassName={styles.tooltip}
    />
  </div>
);

export default PaymentPlanWizardCommon;
