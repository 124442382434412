import React from 'react';

const DatevExport = ({ dataId = '', className = '' }) => (
  <svg width={305} height={126} data-id={dataId} className={className}>
    <defs>
      <ellipse id="prefix__b" cx={84.653} cy={36.154} rx={35.674} ry={35.5} />
      <ellipse id="prefix__d" cx={50.234} cy={50} rx={50.234} ry={50} />
      <filter
        x="-9.8%"
        y="-7%"
        width="119.6%"
        height="119.7%"
        filterUnits="objectBoundingBox"
        id="prefix__a"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-7%"
        y="-5%"
        width="113.9%"
        height="114%"
        filterUnits="objectBoundingBox"
        id="prefix__c"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M22.596 103c-6.894-13-34.474-49-10.834-55 23.64-6 75.843-50 112.287-9s48.264-26 90.618-20c42.353 6 98.497 27 88.647 56-9.85 29-12.804 32-58.113 22s-44.324 28-77.813 28c-33.49 0-31.52-11-75.843-5-44.324 6-62.054-4-68.949-17z"
        fill="#F3F3F3"
      />
      <path
        d="M83.665 100.346c9.85 7.333 21.013 12.333 33.489 15 18.715 4 42.354-14 65.008-60 15.103-30.667 31.52-26.667 49.25 12"
        stroke="#F60"
        strokeDasharray="4,4"
      />
      <g transform="translate(161.748 8.346)">
        <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
        <use fill="#FFF" xlinkHref="#prefix__b" />
      </g>
      <path
        stroke="#0066B3"
        strokeWidth={2}
        strokeLinejoin="round"
        d="M162.613 95h31.509v24.194h-31.509z"
      />
      <path
        stroke="#0066B3"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M194.122 95l-15.754 14.977L162.613 95"
      />
      <path
        fill="#F60"
        fillRule="nonzero"
        d="M198.79 118.622l-7.795-6.34v4.427h-7.376v4.372h7.376v3.873z"
      />
      <path
        stroke="#F60"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M198.79 118.622l-7.795-6.34v4.427h-7.376v4.372h7.376v3.873z"
      />
      <g fillRule="nonzero">
        <path
          d="M248.566 72.325l2.522-5.148c.191-.39.262-.83.203-1.26l-2.522-18.438c-.339-2.475-3.843-2.475-4.181 0l-2.522 18.437c-.06.432.011.871.202 1.261l2.522 5.148a2.092 2.092 0 003.776 0zm-2.229-6.476l.341-2.494.341 2.494-.34.696-.342-.696zm10.019-35.561c0 6.433-4.22 11.846-9.678 11.846-5.47 0-9.677-5.376-9.677-11.846 0-6.86 3.593-11.846 9.677-11.846 6.073 0 9.678 5.02 9.678 11.846zm-4.222 0c0-4.685-2.049-7.539-5.456-7.539-3.423 0-5.456 2.822-5.456 7.539 0 4.277 2.552 7.538 5.456 7.538 2.888 0 5.456-3.295 5.456-7.538z"
          fill="#FFF"
        />
        <path
          d="M229.793 57.437v-5.072c0-4.842 3.01-7.634 9.08-8.781 1.146-.217 1.903-1.34 1.691-2.51-.212-1.17-1.313-1.942-2.46-1.726-7.878 1.489-12.532 5.807-12.532 13.017v5.072c0 1.19.945 2.154 2.11 2.154 1.166 0 2.111-.965 2.111-2.154zm37.992 0v-5.072c0-7.21-4.654-11.528-12.533-13.017-1.146-.216-2.248.556-2.46 1.726-.212 1.17.545 2.293 1.691 2.51 6.07 1.147 9.08 3.94 9.08 8.78v5.073c0 1.19.945 2.154 2.111 2.154 1.166 0 2.11-.965 2.11-2.154zm-19.309-30.382l.31.012a6.914 6.914 0 005.092-1.87c1.027-.961.875-2.657-.307-3.412l-4.485-2.865a2.075 2.075 0 00-1.592-.273l-5.614 1.314c-1.786.418-2.238 2.8-.734 3.867l.435.297.311.203c.562.36 1.164.722 1.79 1.06.721.392 1.429.73 2.114 1 .958.377 1.846.612 2.68.667zm-.038-4.21l-1.592-.273.74.473.852-.2z"
          fill="#FFF"
        />
        <path
          d="M236.643 42.522l3.62 6.656a2.087 2.087 0 003.305.51l2.206-2.153a2.183 2.183 0 00-.166-3.258l-1.684-1.321-.696-.541-3.476-2.675a2.083 2.083 0 00-2.954.437 2.17 2.17 0 00-.409 1.068l-.01.188c-.008.368.076.744.264 1.09zm19.916-2.345a2.083 2.083 0 00-2.812-.536l-.502.374-.945.724c-.733.563-1.466 1.128-2.172 1.676l-2.38 1.862a2.183 2.183 0 00-.165 3.258l2.206 2.153c.999.976 2.633.724 3.304-.51l3.62-6.656c.157-.288.242-.597.261-.905l.004-.184a2.173 2.173 0 00-.42-1.256zm-1.966 14.697h4.222c1.165 0 2.11-.964 2.11-2.154s-.945-2.154-2.11-2.154h-4.222c-1.165 0-2.11.964-2.11 2.154s.945 2.154 2.11 2.154z"
          fill="#FFF"
        />
        <path
          d="M247.622 71.843l2.522-5.148c.096-.194.131-.414.102-.63l-2.523-18.437c-.169-1.237-1.92-1.237-2.09 0l-2.522 18.437c-.03.216.006.436.101.63l2.522 5.148a1.046 1.046 0 001.888 0z"
          fill="#F60"
        />
        <path
          d="M255.3 30.288c0 5.886-3.806 10.769-8.622 10.769-4.829 0-8.622-4.847-8.622-10.77 0-6.323 3.203-10.769 8.622-10.769 5.407 0 8.622 4.48 8.622 10.77zm-2.11 0c0-5.22-2.438-8.616-6.512-8.616-4.088 0-6.511 3.363-6.511 8.616 0 4.825 2.965 8.615 6.511 8.615 3.53 0 6.512-3.824 6.512-8.615zm-24.452 27.149v-5.072c0-5.434 3.42-8.608 9.943-9.84.573-.108.952-.67.846-1.255a1.057 1.057 0 00-1.23-.863c-7.427 1.403-11.67 5.34-11.67 11.958v5.072c0 .595.472 1.077 1.055 1.077s1.056-.482 1.056-1.077zm37.992 0v-5.072c0-6.618-4.243-10.555-11.67-11.958a1.057 1.057 0 00-1.23.863 1.076 1.076 0 00.846 1.255c6.522 1.232 9.943 4.406 9.943 9.84v5.072c0 .595.472 1.077 1.055 1.077s1.056-.482 1.056-1.077z"
          fill="#0066B3"
        />
        <path
          d="M237.566 42l3.62 6.655a1.044 1.044 0 001.652.255l2.206-2.153a1.091 1.091 0 00-.083-1.63l-1.677-1.315-1.407-1.092-2.757-2.117a1.041 1.041 0 00-1.477.219 1.084 1.084 0 00-.2.505l-.01.133c-.002.183.04.369.133.54zm18.147-1.178a1.042 1.042 0 00-1.366-.293l-.26.189-2.608 2.002-2.196 1.709-.888.699a1.091 1.091 0 00-.082 1.629l2.205 2.153c.5.488 1.317.362 1.653-.255L255.79 42a1.09 1.09 0 00.126-.402l.006-.138a1.087 1.087 0 00-.21-.638z"
          fill="#0066B3"
        />
        <path
          d="M254.593 53.797h4.222c.582 0 1.055-.482 1.055-1.077s-.473-1.077-1.055-1.077h-4.222c-.583 0-1.055.482-1.055 1.077s.472 1.077 1.055 1.077z"
          fill="#F60"
        />
      </g>
      <g transform="translate(22.182 9.346)">
        <use fill="#000" filter="url(#prefix__c)" xlinkHref="#prefix__d" />
        <use fill="#FFF" xlinkHref="#prefix__d" />
      </g>
      <path
        d="M62.505 35.44H85.83v5.079l2.455.484v-6.771c0-.726-.49-1.21-1.227-1.21h-25.78c-.736 0-1.227.484-1.227 1.21v9.672h2.455v-8.463z"
        fill="#0066B3"
      />
      <path
        d="M95.908 44.746l-26.864-5.142c-.392-.126-.653 0-1.044.125-.26.125-.521.502-.521.752l-.913 4.515h2.608l.522-2.759 24.387 4.766-7.303 35.618-7.564-1.505v2.509l8.346 1.63h.26c.262 0 .523-.125.783-.25.261-.126.522-.502.522-.753l7.825-38.127c.13-.501-.391-1.254-1.044-1.38z"
        fill="#0066B3"
      />
      <path
        d="M76.3 46.08H50.423c-.74 0-1.232.503-1.232 1.258V81.65c0 .377.123.628.37.88l4.436 4.525c.246.25.492.377.862.377h21.318c.74 0 1.232-.503 1.232-1.257V47.212c.123-.503-.493-1.131-1.109-1.131zm-1.232 38.964H56.954v-5.028c0-.754-.493-1.257-1.232-1.257h-4.067V48.594h23.413v36.45z"
        fill="#F60"
        fillRule="nonzero"
      />
      <g>
        <path
          stroke="#FFF"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M126.265 27H98.193l-.007-22.86 28.086-.015z"
        />
        <path fill="#FFF" fillRule="nonzero" d="M126.265 27H98.193l-.007-22.86 28.086-.015z" />
        <path
          stroke="#F60"
          strokeWidth={2}
          strokeLinejoin="round"
          d="M126.265 27H98.193l-.007-22.86 28.086-.015z"
        />
        <path
          stroke="#FFF"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M97.455 1.5h29.549v6h-29.55z"
        />
        <path fill="#0066B3" fillRule="nonzero" d="M97.455 1.5h29.549v6h-29.55z" />
        <path
          stroke="#0066B3"
          strokeWidth={2}
          strokeLinejoin="round"
          d="M97.455 1.5h29.549v6h-29.55z"
        />
        <path
          stroke="#FFF"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M102.626 2.933V0"
        />
        <path
          stroke="#F60"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M102.626 2.933V0"
        />
        <path
          stroke="#FFF"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M121.833 3V0"
        />
        <path
          stroke="#F60"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M121.833 3V0"
        />
        <path
          stroke="#F60"
          strokeWidth={2}
          fill="#F60"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M101.665 11.925h3.694v3.75h-3.694zm5.91 0h3.694v3.75h-3.694zm5.91 0h3.694v3.75h-3.694zm5.91 0h3.694v3.75h-3.694zm-17.73 6h3.694v3.75h-3.694zm5.91 0h3.694v3.75h-3.694zm5.91 0h3.694v3.75h-3.694zm5.91 0h3.694v3.75h-3.694z"
        />
      </g>
    </g>
  </svg>
);

export default DatevExport;
