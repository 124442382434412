import { push } from 'connected-react-router';

import * as actions from 'actions/index';
import { showNotification } from 'actions/notification';
import { CLEAR_FILTERS, SET_FILTERS_ACTIVE } from 'constants/common/filters';
import { PRODUCT_CATALOG_ITEMS } from 'constants/product-catalog/items';
import { itemEditSuccess, itemSaveSuccess } from 'notifications/product-catalog';
import { getParams } from 'reducers/common/resource';
import paths from 'routes/paths';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

export const index =
  ({ name, endpoint } = {}) =>
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState().productCatalogItems;
    const combinedParams = { ...state[name].params, ...params };
    return dispatch(
      actions.apiIndex({
        name,
        endpoint,
        params: getParams(combinedParams),
      })
    ).catch(apiErrorHandler);
  };

const endpoint = '/me/product_catalog_items';
const name = PRODUCT_CATALOG_ITEMS;
const config = { endpoint, name };

export const fetchProductCatalogItems = index(config);
const apiCreate = actions.apiCreate(config);
const apiDelete = actions.apiDelete(config);
const apiUpdate = actions.apiUpdate(config);
const apiFetch = actions.apiFetch(config);
const apiFetchSuggestedNumber = actions.apiFetchSuggestedItemNumber(config);

export const apiFetchSuggestedItemNumber = () => (dispatch) => {
  return bindServerValidation(apiFetchSuggestedNumber(), dispatch, { isReduxForm: false });
};

export const fetchProductCatalogItem = (id) => (dispatch) =>
  bindServerValidation(apiFetch(id), dispatch, { isReduxForm: false });

export const createProductCatalogItem =
  (data = {}, redirectToCatalog = true, modifier) =>
  (dispatch) =>
    bindServerValidation(apiCreate(data), dispatch, {}, modifier).then(({ response }) => {
      const {
        data: { check_for_existing_items },
      } = data;
      if (!check_for_existing_items) dispatch(showNotification(itemSaveSuccess));
      return redirectToCatalog ? dispatch(push(paths.productCatalogItems)) : response;
    });

export const updateProductCatalogItem =
  (data = {}, redirectToCatalog = true, isNotifyingAction = false) =>
  (dispatch) => {
    return bindServerValidation(apiUpdate(data), dispatch).then((response) => {
      if (isNotifyingAction) dispatch(showNotification(itemEditSuccess));
      return redirectToCatalog ? dispatch(push(paths.productCatalogItems)) : response;
    });
  };

export const deleteProductCatalogItem = (id) => (dispatch) =>
  bindServerValidation(apiDelete(id), dispatch, { isReduxForm: false })
    .then(() => dispatch(push(paths.productCatalogItems)))
    .catch(apiErrorHandler);

export const sort = actions.sort(config);

export const setPagination = actions.setPagination(config);

export const setQueryParam = actions.setQueryParam({ name });

export const clearFilters = ({ clearStatus = false } = {}) => ({
  type: CLEAR_FILTERS,
  name,
  payload: {
    clearStatus,
  },
});

export const setFilterByTextActive = () => ({
  type: SET_FILTERS_ACTIVE,
  name,
});

const apiFetchCategories = actions.apiFetchCategories({
  name,
  endpoint: '/line_item_categories/invoice_revenues',
});

export const fetchCategories = () => (dispatch) =>
  bindServerValidation(apiFetchCategories(), dispatch, { isReduxForm: false });
