import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { PIWIK_CATEGORY_REVENUE } from 'constants/piwik';
import { piwikHelpers } from 'shared/utils/piwik';

import styles from './NewInvoiceButton.module.css';

interface NewInvoiceButtonProps {
  title: string;
  path: string;
  piwikAction: string;
  dataId: string;
}

const NewInvoiceButton: FunctionComponent<NewInvoiceButtonProps> = ({
  title,
  path,
  piwikAction = '',
  dataId = '',
}) => (
  <Link
    data-id={dataId}
    to={path}
    className={styles.newInvoice}
    onClick={() => {
      if (!piwikAction) return;
      piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, piwikAction);
    }}
  >
    {title}
  </Link>
);

export default NewInvoiceButton;
