import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';

import {
  downloadDeliveryNote as downloadDeliveryNotePreview,
  getDeliveryNotePreview,
} from 'actions/delivery-note';
import { FORM_NAME as DELIVERY_NOTE_CREATOR } from 'constants/delivery-note';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import PreviewModalComponent from 'components/PreviewModal';

const PreviewModal = ({
  deliveryNoteId,
  details,
  deliveryNoteValues = {},
  isOpen,
  onClose,
  downloadPreview,
  getPreview,
}) => {
  const {
    pdfSignature,
    subject,
    salutationContent,
    salutationHonorific,
    personalNotes,
    deliveryNoteDate,
    useDeviatingDeliveryAddress,
  } = deliveryNoteValues;
  const values = {
    subject,
    salutationContent,
    salutationHonorific,
    personalNotes,
    deliveryNoteDate,
    pdfSignature,
    useDeviatingDeliveryAddress,
    watermark: true,
    clientId: deliveryNoteValues.client?.id,
  };
  const download = () => downloadPreview(details, values);

  return (
    <PreviewModalComponent
      title={t('modals.preview_delivery_note')}
      getPreview={() => getPreview(deliveryNoteId, values)}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ActionButton
        appearance="primary"
        onClick={download}
        label={t('features.delivery_note.form.download')}
        type="button"
      />
    </PreviewModalComponent>
  );
};

PreviewModal.propTypes = {
  deliveryNoteId: PropTypes.string.isRequired,
  details: PropTypes.shape({}).isRequired,
  deliveryNoteValues: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  downloadPreview: PropTypes.func.isRequired,
  getPreview: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  deliveryNoteId: state.deliveryNote.details.id,
  details: state.deliveryNote.details,
  deliveryNoteValues: getFormValues(DELIVERY_NOTE_CREATOR)(state),
});

export default connect(mapStateToProps, {
  downloadPreview: downloadDeliveryNotePreview,
  getPreview: getDeliveryNotePreview,
})(PreviewModal);
