import React, { Component } from 'react';
import DropdownMenu from 'react-dd-menu';
import { arrayOf, bool, func, oneOfType, shape, string } from 'prop-types';

import DropdownButton from './DropdownButton';

import './style.scss';
import styles from './Dropdown.module.css';

export const DropdownAction = ({ name, onClick, tooltip, dataId = '', isEnabled = true }) =>
  isEnabled && (
    <li>
      <button
        data-id={dataId}
        className={tooltip ? 'with-tooltip' : ''}
        type="button"
        onClick={onClick}
      >
        {name}
      </button>
      {tooltip && (
        <div className={`${styles.tooltipIcon} dd-item-ignore`}>
          <span className={styles.tooltipMessage}>{tooltip}</span>
        </div>
      )}
    </li>
  );

DropdownAction.propTypes = {
  name: string.isRequired,
  onClick: func,
  tooltip: string,
  dataId: string,
  isEnabled: bool,
};

export class Dropdown extends Component {
  state = {
    isMenuOpen: false,
  };

  toggle = () => this.setState((prevState) => ({ isMenuOpen: !prevState.isMenuOpen }));

  close = (event) => {
    if (event.target.className !== `${styles.tooltipIcon} dd-item-ignore`) {
      this.setState({ isMenuOpen: false });
    }
  };

  render() {
    const menuOptions = {
      isOpen: this.state.isMenuOpen,
      close: this.close,
      upwards: this.props.upwards,
      toggle: (
        <DropdownButton
          disabled={this.props.disabled}
          visible={this.props.visible}
          onClick={() => {
            const { onClick } = this.props;

            this.toggle();
            if (onClick) onClick();
          }}
          upwards={this.props.upwards}
          title={this.props.title}
          tooltip={this.tooltip}
          className={this.props.buttonClassName}
          dataId={this.props.dataId}
        />
      ),
      align: this.props.align || 'left',
    };
    const children = this.props.actions.map((action, idx) => (
      <DropdownAction key={idx} {...action} />
    ));

    return <DropdownMenu {...menuOptions}>{children}</DropdownMenu>;
  }
}

Dropdown.defaultProps = {
  buttonClassName: '',
  dataId: '',
};

Dropdown.propTypes = {
  title: string,
  actions: arrayOf(
    shape({
      dataId: string,
      name: string,
      onClick: func,
      tooltip: string,
    })
  ).isRequired,
  align: string,
  visible: bool,
  disabled: bool,
  upwards: bool,
  buttonClassName: oneOfType([string, arrayOf(string)]),
  dataId: string,
  onClick: func,
};
