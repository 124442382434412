import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';

import { cashbookEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import IconCashbook from 'redesign/components/atoms/Icons/Cashbooks';

import { trackEventHandler } from '../utils';
import { Link, MenuItem } from './Shared.styled';

type CashbooksProps = {
  pathname: string;
};

const Cashbooks = ({ pathname }: CashbooksProps) => {
  const areCashbooks = !!matchPath(pathname, paths.cashbooks);
  const isCashbookEnabled = useSelector(cashbookEnabledHelper);

  if (!isCashbookEnabled) {
    return null;
  }

  return (
    <MenuItem $isActive={areCashbooks}>
      <Link
        $level={1}
        to={paths.cashbooks}
        $isActive={areCashbooks}
        data-id="cashbooks"
        onClick={trackEventHandler('cashbook_open')}
      >
        <IconCashbook />
        {t('redesign.organisms.navigation.cashbooks')}
      </Link>
    </MenuItem>
  );
};

export default memo(Cashbooks);
