import React, { SVGAttributes } from 'react';

const IconArrowDown = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 10 6" {...props}>
    <path
      d="M1.284.232a.725.725 0 00-1.063 0 .818.818 0 000 1.118l4.247 4.418c.294.31.769.31 1.064 0L9.78 1.35a.82.82 0 000-1.118.725.725 0 00-1.063 0L4.999 3.855 1.284.232z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default IconArrowDown;
