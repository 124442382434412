import React from 'react';
import PropTypes from 'prop-types';

import LoadingIcon from 'components/LoadingIcon';

const withLoader = (Component, options) => {
  function WithLoader({ isFetching, ...rest }) {
    if (isFetching) return <LoadingIcon {...options} />;
    return <Component isFetching={isFetching} {...rest} />; // eslint-disable-line
  }
  WithLoader.propTypes = {
    isFetching: PropTypes.bool,
  };

  const withLoaderName = Component.displayName || Component.name || 'Component';

  WithLoader.displayName = `withLoader(${withLoaderName})`;
  return WithLoader;
};
export default withLoader;
