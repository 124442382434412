import React, { SVGAttributes } from 'react';

const IconNarrowCheck = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 100 80" {...props}>
    <path
      d="M36.373 70.853L6.259 43.811a3.756 3.756 0 00-5.296.278 3.736 3.736 0 00.278 5.285l33.036 29.665a3.756 3.756 0 005.411-.411L99.152 6.11a3.736 3.736 0 00-.527-5.265 3.756 3.756 0 00-5.277.526L36.373 70.853z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default IconNarrowCheck;
