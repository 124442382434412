import React, { memo, ReactNode } from 'react';

import Body from '../Table/Body';
import BodyCell from '../Table/BodyCell';
import BodyRow from '../Table/BodyRow';
import Table from '../Table/Table';

import sharedTableStyles from '../ExpensesRevenues.module.css';
import styles from './SummaryHorizontal.module.css';

type SummaryHorizontalProps = {
  title: ReactNode;
  result: ReactNode;
  dataId?: string;
};

const SummaryHorizontal = ({ title, result, dataId }: SummaryHorizontalProps) => (
  <div className={styles.summary}>
    <Table>
      <Body>
        <BodyRow>
          <BodyCell className={styles.textCell}>{title}</BodyCell>
          <BodyCell className={styles.resultCell} dataId={dataId}>
            {result}
          </BodyCell>
          <BodyCell className={sharedTableStyles.actionBodyCell} />
        </BodyRow>
      </Body>
    </Table>
  </div>
);

export default memo(SummaryHorizontal);
