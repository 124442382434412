import styled from 'styled-components';

type CounterProps = {
  isCapped: boolean;
};

export const Counter = styled.span<CounterProps>`
  color: ${({ theme, isCapped }) =>
    isCapped ? theme.colors.red['125'] : theme.colors.grey['020']};
`;
