import generateFilesUploadInfo from 'shared/utils/ocr';

import {
  documentFormatNotPDF,
  documentOCRIncompliant,
  documentTooBig,
} from '../../notifications/incoming-invoices-creator';
import type { ShowFilesErrors, ShowRejectedFilesErrors, UploadFile } from './types';

// File info generators
export const generateUploadingFilesInfo = (files: File[]) => {
  const filesCount = files.length;
  const filesSize = files.map((file) => file.size / 1024 ** 2).reduce((sum, size) => sum + size, 0);
  return generateFilesUploadInfo(filesSize, filesCount);
};

export const generateRejectedFilesInfo = (rejectedFiles: File[]) => {
  const isPDFFormat = rejectedFiles.every((file) => file.type === 'application/pdf');
  return { isPDFFormat };
};

export const generateUploadedFilesInfo = (files: File[]) => {
  const filesCount = files.length;
  const filesSize = files.map((file) => file.size).reduce((sum, size) => sum + size, 0);

  return generateFilesUploadInfo(filesSize, filesCount);
};

// Error handlers
export const showFilesErrors = ({
  filesInfo = {},
  showNotification,
  ocrEnabled = false,
}: ShowFilesErrors) => {
  if (ocrEnabled && !filesInfo.isOCRCompatibile) showNotification(documentOCRIncompliant);
  if (filesInfo.isTooBig) showNotification(documentTooBig);
};

export const showRejectedFilesErrors = ({
  rejectedFilesInfo,
  showNotification,
}: ShowRejectedFilesErrors) => {
  if (!rejectedFilesInfo.isPDFFormat) {
    showNotification(documentFormatNotPDF);
  }
};

export const uploadFile = ({
  files = [],
  rejectedFiles = [],
  fileId,
  uploadFileAction,
  ocrEnabled,
  processOcrData,
  callbackStatus,
  showNotification,
}: UploadFile) => {
  if (rejectedFiles.length) {
    const rejectedFilesInfo = generateRejectedFilesInfo(rejectedFiles);
    showRejectedFilesErrors({ rejectedFilesInfo, showNotification });
  }

  if (!files.length) return;
  const filesInfo = generateUploadingFilesInfo(files);
  showFilesErrors({ filesInfo, showNotification, ocrEnabled });

  if (filesInfo.isTooBig) return; // early return on isTooBig

  callbackStatus(true);

  uploadFileAction(fileId, files).then(() => {
    callbackStatus(false);

    if (!ocrEnabled) return;
    const uploadedFilesInfo = generateUploadedFilesInfo(files);
    showFilesErrors({ filesInfo: uploadedFilesInfo, ocrEnabled, showNotification });
    if (!uploadedFilesInfo.isOCRCompatibile) return;
    if (processOcrData) processOcrData(fileId);
  });
};
