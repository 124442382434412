import React, { memo, SVGProps } from 'react';

const ZoomIn = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path
        d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 1.846c5.608 0 10.154 4.546 10.154 10.154S17.608 22.154 12 22.154 1.846 17.608 1.846 12 6.392 1.846 12 1.846z"
        fillRule="nonzero"
      />
      <path d="M12 4a1 1 0 011 1v6h6a1 1 0 010 2h-6v6a1 1 0 01-2 0v-6H5a1 1 0 010-2h6V5a1 1 0 011-1z" />
    </g>
  </svg>
);

export default memo(ZoomIn);
