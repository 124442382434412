import { camelCase } from 'lodash';

import { fetchContractFiles } from 'actions/contracts/uploaded-files';
import { CALL_API } from 'constants/api';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  FETCH_CONTRACT_FAILURE,
  FETCH_CONTRACT_REQUEST,
  FETCH_CONTRACT_SUCCESS,
  NEW_CONTRACT_TRANSIENT_ID,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/contracts';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { clearItem, setItem } from 'shared/utils/storage';
import { ContractSource } from 'types/entities/Contract';

const setTransientId = setItem(NEW_CONTRACT_TRANSIENT_ID);
export const clearTransientId = clearItem(NEW_CONTRACT_TRANSIENT_ID);

export const apiCreateTransient = ({ isRevenue, contractType }) => ({
  [CALL_API]: {
    data: {
      transient: true,
      source: 'pbw',
      isRevenue,
      contractType,
      installments: { net_amount: 0 },
    },
    method: 'POST',
    endpoint: '/me/contracts',
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

const newContractTransient =
  ({ isRevenue, contractType }) =>
  (dispatch) =>
    dispatch(apiCreateTransient({ isRevenue, contractType }))
      .then(({ data }) => {
        setTransientId(data.id);
        return data.id;
      })
      .catch(apiErrorHandler);

export const apiFetchContract = (contractId, omitReducers = false) => {
  if (!contractId && contractId !== 0) {
    return null;
  }

  return {
    [CALL_API]: {
      endpoint: `/me/contracts/${contractId}`,
      types: [FETCH_CONTRACT_REQUEST, FETCH_CONTRACT_SUCCESS, FETCH_CONTRACT_FAILURE],
      omitReducers,
    },
  };
};

export const apiUpdateContract = (contractId, data, isRevenue, hasVat) => {
  if (!contractId && contractId !== 0) {
    return null;
  }

  const vendorKey = isRevenue ? 'client_id' : 'supplier_id';

  return {
    [CALL_API]: {
      data: {
        ...data,
        transient: false,
        is_revenue: data?.is_revenue || false,
        source: data?.source || ContractSource.Pbw,
        installments: {
          ...data?.installments,
          vat: hasVat ? data?.installments?.vat : 0,
          [vendorKey]: data.installments[camelCase(vendorKey)],
        },
      },
      method: 'PUT',
      endpoint: `/me/contracts/${contractId}`,
      types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
    },
  };
};

export const fetchContract = (contractId) => (dispatch) =>
  dispatch(apiFetchContract(contractId))
    .then(() => dispatch(fetchContractFiles(contractId)))
    .catch((err) => {
      apiErrorHandler(err);
    });

export const fetchContractForEdition =
  (contractId, options = {}) =>
  (dispatch) =>
    dispatch(fetchContract(contractId, options.omitReducers));

export const createContractTransient =
  ({ isRevenue, contractType }) =>
  (dispatch) =>
    dispatch(newContractTransient({ isRevenue, contractType })).then((id) =>
      dispatch(fetchContractForEdition(id))
    );
