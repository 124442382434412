import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import { useMedia } from 'react-use';

import {
  BANK_ACCOUNTS_TILE_ACTIVATE_ACCOUNT,
  BANK_ACCOUNTS_TILE_ACTIVATE_CASHBOOK,
  BANK_ACCOUNTS_TILE_DEACTIVATE_ACCOUNT,
  BANK_ACCOUNTS_TILE_DEACTIVATE_CASHBOOK,
} from 'constants/piwik';
import { t } from 'shared/utils';
import { BankAccount } from 'types/entities/BankAccount';
import { Cashbook } from 'types/entities/Cashbook';
import Amount from 'redesign/components/atoms/Amount/Amount';
import Table from 'redesign/components/molecules/Table/Table';
import { Breakpoints } from 'redesign/styles/breakpoints';

import getColumns from './getColumns';
import { Box, Summary, TableWrapper } from './List.styled';
import { isBankAccount, trackEvent } from './utils';

type ListProps = {
  data: Array<BankAccount | Cashbook>;
};

const List = ({ data }: ListProps) => {
  const [selected, setSelected] = useState(data);
  const total = selected
    .map((item) => (isBankAccount(item) ? item.balance : item.currentAmount))
    .reduce((prev, current) => prev + current, 0);
  const isMobile = useMedia(`(max-width: ${Breakpoints.xsMax})`);

  const handleClick = useCallback(
    (item: BankAccount | Cashbook) => () => {
      setSelected((selected) => {
        const result = [...selected];
        const start = result.indexOf(item);

        if (start === -1) {
          result.push(item);
          trackEvent(
            isBankAccount(item)
              ? BANK_ACCOUNTS_TILE_ACTIVATE_ACCOUNT
              : BANK_ACCOUNTS_TILE_ACTIVATE_CASHBOOK
          );
        } else {
          result.splice(start, 1);
          trackEvent(
            isBankAccount(item)
              ? BANK_ACCOUNTS_TILE_DEACTIVATE_ACCOUNT
              : BANK_ACCOUNTS_TILE_DEACTIVATE_CASHBOOK
          );
        }

        return result;
      });
    },
    []
  );

  const columns = useMemo(
    () => getColumns({ onClick: handleClick, selected }),
    [handleClick, selected]
  );
  const instance = useTable<BankAccount | Cashbook>({
    columns,
    data,
  });
  const { toggleHideColumn } = instance;

  useEffect(() => {
    toggleHideColumn('image', isMobile);
  }, [isMobile, toggleHideColumn]);

  return (
    <Box>
      <TableWrapper>
        <Table instance={instance} />
      </TableWrapper>
      <Summary>
        <span>{t('features.homepage.bank_accounts_tile.list.total_balance')}</span>
        <Amount amount={total} data-id="summary-total" />
      </Summary>
    </Box>
  );
};

export default List;
