import React, { memo } from 'react';

import { formatDate, formatMoney, padString } from 'shared/utils';
import { AssignableResource } from 'types/entities/AssignableResource';

import {
  Amount,
  Contractor,
  Date,
  Description,
  LeftColumn,
  Option,
} from './SelectResourcesField.styled';

type ContractInstallmentOptionProps = {
  installment: AssignableResource;
};

const ContractInstallmentOption = ({
  installment: { date, description, totalGrossAmount, contractor },
}: ContractInstallmentOptionProps) => (
  <Option data-id={`assignment-row-${date}`}>
    <LeftColumn>
      <Date>{formatDate(date)}</Date>
      <Description>{padString(description, 40)}</Description>
      <Contractor>{contractor}</Contractor>
    </LeftColumn>
    <Amount isNegative={totalGrossAmount < 0}>{formatMoney(totalGrossAmount)}</Amount>
  </Option>
);

export default memo(ContractInstallmentOption);
