import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import arrowDownIcon from 'images/icon-arrow-down.svg';
import plusIcon from 'images/icon-plus--white.svg';
import { t } from 'shared/utils';
import If from 'components/Conditions/If';
import Thumbnail from 'components/InvoiceUploader/Thumbnail/Thumbnail';
import { ConfirmationModal } from 'components/Modal';

import styles from './Gallery.module.css';

class Gallery extends Component {
  state = {
    isDeleteFileModalVisible: false,
  };

  toggleDeleteFileModal = () => {
    this.setState((prevState) => ({
      isDeleteFileModalVisible: !prevState.isDeleteFileModalVisible,
    }));
  };

  handleConfirmRemoval = () => {
    this.props.deleteFile(this.props.activeFileId)();
    this.toggleDeleteFileModal();
  };

  render() {
    const {
      activeFileId,
      className = '',
      files,
      invoiceId,
      readonly,
      onNewFileButtonClick,
      onNextSelect,
      onPrevSelect,
      onSelect,
      setActiveThumbnailNode,
      renderingInProgress = false,
      invoiceUploadedFilenameGenerator,
    } = this.props;
    const { isDeleteFileModalVisible } = this.state;
    return (
      <div className={cx(styles.main, className, readonly && styles.readonly)}>
        <div className={styles.thumbnailsContainer}>
          <button
            title={t('expenses.upload.previous_file')}
            className={cx(styles.arrowPrev, { [styles.arrowInactive]: renderingInProgress })}
            type="button"
            onClick={onPrevSelect}
            data-id="Gallery:button-previousFile"
          >
            <img
              alt={t('expenses.upload.previous_file')}
              className={styles.arrowPrevIcon}
              src={arrowDownIcon}
              data-id="Gallery:icon-previousFile"
            />
          </button>

          {files.map((file) => (
            <Thumbnail
              fileName={invoiceUploadedFilenameGenerator(invoiceId, file.id)}
              fileUrl={file.url}
              key={file.id}
              invoiceId={invoiceId}
              isActive={file.id === activeFileId}
              onClick={() => onSelect(file.id)}
              onRemove={this.toggleDeleteFileModal}
              readonly={readonly}
              thumbnail={file.thumbnail}
              setActiveNode={setActiveThumbnailNode}
            />
          ))}

          <If ok={!readonly}>
            <button
              className={styles.newFileButton}
              onClick={onNewFileButtonClick}
              type="button"
              title={t('expenses.upload.browse_files')}
              data-id="Gallery:button-browse"
            >
              <img
                alt={t('expenses.upload.browse_files')}
                className={styles.newFileButtonIcon}
                src={plusIcon}
                data-id="Gallery:icon-browse"
              />
            </button>
          </If>

          <button
            title={t('expenses.upload.next_file')}
            className={cx(styles.arrowNext, { [styles.arrowInactive]: renderingInProgress })}
            type="button"
            onClick={onNextSelect}
            data-id="Gallery:button-nextFile"
          >
            <img
              alt={t('expenses.upload.next_file')}
              className={styles.arrowNextIcon}
              src={arrowDownIcon}
              data-id="Gallery:icon-nextFile"
            />
          </button>
        </div>
        <ConfirmationModal
          dangerousAction
          isOpen={isDeleteFileModalVisible}
          onClose={this.toggleDeleteFileModal}
          onConfirm={this.handleConfirmRemoval}
          closeLabel={t('modals.confirmation.cancel')}
          confirmLabel={t('modals.confirmation.confirm')}
        >
          {t('modals.incoming_invoice.delete_file_confirmation_body')}
        </ConfirmationModal>
      </div>
    );
  }
}

Gallery.propTypes = {
  activeFileId: PropTypes.string.isRequired,
  className: PropTypes.string,
  deleteFile: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  readonly: PropTypes.bool,
  invoiceId: PropTypes.string.isRequired,
  onNewFileButtonClick: PropTypes.func,
  onNextSelect: PropTypes.func.isRequired,
  onPrevSelect: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  setActiveThumbnailNode: PropTypes.func.isRequired,
  renderingInProgress: PropTypes.bool,
  invoiceUploadedFilenameGenerator: PropTypes.func.isRequired,
};

export default Gallery;
