import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { flow, get } from 'lodash';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { PIWIK_ACTION_BANK_STORE_DATA_CHECKED, PIWIK_CATEGORY_PROFILE } from 'constants/piwik';
import { getAccessByType, getBank, getBankLoginFields, getLogoFromBank } from 'reducers/banks';
import { noop, t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import ActionButton from 'components/ActionPanel/ActionButton';
import Checkbox from 'components/Form/Checkbox';
import PasswordField from 'components/Form/PasswordField';
import TextField from 'components/Form/TextField';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import Linkify from 'components/Linkify/Linkify';
import Loading from 'components/Loading';

import checker from './checker';

import pageStyles from '../Page.module.css';
import styles from './Page2.module.css';

const validate = (values, { loginFields }) => {
  const errors = { credentials: {} };

  loginFields.forEach((field) => {
    if (field.is_optional || field.isOptional) return;

    if (!values.credentials?.[field.key]) {
      errors.credentials[field.key] = 'Required';
    }
  });

  return errors;
};

const handleCheckboxClick = ({ target }) => {
  if (target.checked) {
    piwikHelpers.trackEvent(PIWIK_CATEGORY_PROFILE, PIWIK_ACTION_BANK_STORE_DATA_CHECKED);
  }
};

class Page2 extends Component {
  static defaultProps = {
    setLoading: noop,
  };

  componentDidUpdate(prevProps) {
    if (this.props.loginInProcess !== prevProps.loginInProcess) {
      this.props.setLoading(this.props.loginInProcess);
    }
  }

  render() {
    const {
      bank,
      bankLogo,
      loginFields,
      handleSubmit,
      handleCancel,
      invalid,
      loginInProcess,
      submitting,
      className,
      syncingInProgress,
      bankCode,
      bankName,
    } = this.props;
    const hasFields = loginFields.length > 0;

    return (
      <form
        className={cx(pageStyles.page, styles.tooltipParent, className)}
        onSubmit={handleSubmit}
        id="bank-account-creator-page-2"
        data-id="BankAccount:bankAccountCreatorPage2"
      >
        <div className={cx(pageStyles.body, styles.body)}>
          <div>{t('bank_accounts.creator.please_log_in')}</div>

          <div className={styles.loginForm}>
            <div className={cx(styles.section, styles.logo)}>
              <img src={bankLogo} alt="" />
            </div>
            <div className={cx(styles.section, styles.credentials)}>
              <div className={styles.bankInfo}>
                {bankName && <p className={styles.name}>{bankName}</p>}
                {bankCode && (
                  <p className={styles.code}>
                    {'BLZ:'} {bankCode}
                  </p>
                )}
              </div>
              {bank.advice && (
                <div className={cx(styles.hint, { [styles.noFields]: !hasFields })}>
                  <Linkify>{bank.advice}</Linkify>
                </div>
              )}
              {loginFields.map((field) => (
                <div className={styles.field} key={field.key}>
                  <Field
                    checker={checker}
                    name={`credentials.${field.key}`}
                    label={field.label}
                    component={field.is_secret || field.isSecret ? PasswordField : TextField}
                    data-id={`BankAccount:input-${field.key}`}
                    minLength={field.min_length || field.minLength}
                    maxLength={field.max_length || field.maxLength}
                  />
                </div>
              ))}
              {hasFields && (
                <Field
                  component={Checkbox}
                  name="savePin"
                  className={cx(styles.field, styles.checkbox)}
                  onClick={handleCheckboxClick}
                  dataIds={{ input: 'BankAccount:savePinCheckbox' }}
                >
                  <span className={styles.saveCredentials}>
                    {' '}
                    {t('bank_accounts.creator.save_credentials')}
                  </span>
                  <InfoIcon
                    pullLeft
                    className={styles.infoIcon}
                    tooltipPlacement="left"
                    text={t('bank_accounts.creator.save_credentials_info')}
                  />
                </Field>
              )}
            </div>
          </div>
          {(loginInProcess || submitting || syncingInProgress) && <Loading />}
        </div>

        <div className={pageStyles.footer}>
          <ActionButton
            appearance="outlined"
            onClick={handleCancel}
            disabled={loginInProcess || submitting}
            label={t('bank_accounts.creator.prev_step')}
            className={pageStyles.stepButton}
            type="button"
          />
          <ActionButton
            disabled={loginInProcess || submitting || invalid}
            label={t('bank_accounts.creator.next_step')}
            className={pageStyles.stepButton}
            type="submit"
            data-id="BankAccount:creatorSecondStepFinish"
          />
        </div>
      </form>
    );
  }
}

Page2.propTypes = {
  bank: shape({
    advice: string,
    name: string.isRequired,
    code: string.isRequired,
  }).isRequired,
  invalid: bool.isRequired,
  bankLogo: string.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool,
  handleCancel: func.isRequired,
  loginInProcess: bool,
  loginFields: arrayOf(shape({})).isRequired,
  setLoading: func,
  className: string,
  syncingInProgress: bool,
  bankCode: string,
  bankName: string,
};

const mapStateToProps = (state, ownProps) => {
  const bank =
    ownProps.bank || getBank(state, get(state, 'form.bankAccountCreator.values.bankCode'));
  const access = getAccessByType(bank, ownProps.accessMethodType);
  const loginFields = getBankLoginFields(access);
  const bankLogo = getLogoFromBank(bank);
  const { code, name } = bank;

  return {
    bankName: name,
    bankCode: code,
    bank: access,
    loginFields,
    bankLogo,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

export default flow(
  reduxForm({
    form: 'bankAccountCreator',
    destroyOnUnmount: false,
    validate,
  }),
  connect(mapStateToProps, null, mergeProps)
)(Page2);
