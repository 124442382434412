import { omit } from 'lodash';

import { HIDE, SHOW } from 'constants/notification';

const defaultValues = [];

function filterOutNonUniqueErrors(notifications) {
  return notifications.filter(
    (notification, i) =>
      notifications.findIndex((elem) => {
        if (Array.isArray(elem.errors)) {
          return (
            elem.errors.join(',') === (notification.errors ? notification.errors.join(',') : [])
          );
        }

        return elem.title === notification.title;
      }) === i
  );
}

export default (state = defaultValues, action) => {
  switch (action.type) {
    case SHOW: {
      return filterOutNonUniqueErrors([...state, omit(action, ['type'])]);
    }
    case HIDE:
      return state.filter((n) => n.id !== action.id);
    default:
      return state;
  }
};
