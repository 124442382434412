import React, { memo, SVGProps } from 'react';

const Revenue = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <filter id="revenue_svg__a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.980392 0 0 0 0 0.980392 0 0 0 0 0.980392 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="revenue_svg__b">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.200000 0 0 0 0 0.200000 0 0 0 0 0.200000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g filter="url(#revenue_svg__a)" strokeLinecap="round">
        <g transform="translate(12.777)" filter="url(#revenue_svg__b)">
          <g strokeWidth={1.296}>
            <path stroke="#0063BB" d="M2.744 2h5.832v6.48" />
            <path stroke="#0066B3" d="M7.89 2.724l-5.498 5.5" />
          </g>
        </g>
      </g>
      <g fill="#FAFAFA">
        <path d="M12.156 13.231c-.839.018-1.539.49-1.966 1.175.58-.431 1.237-.824 1.966-1.175" />
        <path d="M10.078 10.8c0 .608-1.128 1.49-2.895 1.49-1.766 0-2.892-.882-2.892-1.49 0-.007-.004-.014-.004-.022l-.001-1.477c.74.501 1.75.803 2.897.803 1.15 0 2.16-.302 2.9-.804v1.476c-.001.008-.005.016-.005.024M7.183 2.803c1.75 0 2.868.879 2.89 1.496-.022.617-1.14 1.495-2.89 1.495-1.748 0-2.866-.878-2.888-1.495.022-.617 1.14-1.496 2.888-1.496m-2.898 3.26c.74.51 1.75.816 2.898.816 1.15 0 2.161-.306 2.902-.816v1.444c-.002.013-.007.024-.007.038 0 .615-1.128 1.509-2.895 1.509-1.766 0-2.892-.894-2.892-1.51 0-.011-.006-.02-.006-.032V6.064m7.076-1.972v-.005c0-.007-.004-.014-.004-.02-.045-1.486-1.822-2.6-4.174-2.6-2.35 0-4.127 1.114-4.171 2.6 0 .006-.005.013-.005.02v.01c0 .014-.007.027-.007.043l.007 12.697c0 1.498 1.835 2.672 4.176 2.672.85 0 1.63-.157 2.282-.425l-.001-1.485c-.503.319-1.285.574-2.28.574-1.767 0-2.893-.791-2.893-1.336v-.995c.739.442 1.746.709 2.892.709 1.141 0 2.146-.264 2.884-.703-.378-.317-.6-.698-.6-1.108 0-.032.006-.064.01-.096-.502.317-1.29.571-2.294.571-1.766 0-2.892-.78-2.892-1.318l-.002-.01-.001-1.316c.74.444 1.749.71 2.895.71 1.149 0 2.157-.266 2.897-.71v1.052c.326-.269.768-.49 1.284-.642l.006-8.841c0-.017-.008-.032-.009-.048" />
        <path d="M13.002 22.114c-1.766 0-2.893-.778-2.893-1.314l-.001-1.04c.74.444 1.748.71 2.894.71 1.147 0 2.155-.267 2.895-.71l-.001 1.043c0 .534-1.128 1.31-2.894 1.31m-2.893-7.373c0-.537 1.127-1.318 2.893-1.318 1.766 0 2.894.78 2.894 1.318s-1.128 1.32-2.894 1.32c-1.766 0-2.893-.782-2.893-1.32m5.791 3.053c0 .008-.004.015-.004.023 0 .537-1.128 1.318-2.894 1.318-1.766 0-2.893-.78-2.893-1.318 0-.008-.004-.014-.004-.021l-.001-1.112c.74.445 1.75.712 2.898.712 1.15 0 2.16-.268 2.9-.713l-.002 1.11m1.28-3.052c0-1.513-1.797-2.654-4.178-2.654-2.381 0-4.177 1.141-4.177 2.654 0 .012.003.022.003.033-.001.018-.01.034-.01.052l.007 5.975c0 1.51 1.796 2.65 4.177 2.65 2.381 0 4.177-1.139 4.177-2.647l.009-5.978c0-.02-.01-.037-.01-.057l.001-.028" />
      </g>
    </g>
  </svg>
);

export default memo(Revenue);
