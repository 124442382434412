import React from 'react';
import InlineSvg from 'react-inlinesvg';
import { func, string } from 'prop-types';

import { BOOK_PREMIUM } from 'constants/payment-plans';
import iconTime from 'images/icon-time.svg';
import { t } from 'shared/utils';
import getDaysToPremiumBilling from 'shared/utils/premium-billing-date';

import styles from './BookPremium.module.css';

const BookPremium = ({ handleOpenModal, trialEndDate }) => {
  const { displayDate } = getDaysToPremiumBilling(trialEndDate);

  return (
    <div className={styles.boxContainer} data-id="PaymentPlans:bookPremiumBox">
      <div className={styles.iconContainer}>
        <InlineSvg className={styles.svg} src={iconTime} />
      </div>
      <div className={styles.content}>
        <span>
          {t('profile.payment_plan.debit.premium_ends', {
            count: displayDate,
          })}
        </span>
        <button className={styles.button} onClick={() => handleOpenModal(BOOK_PREMIUM)}>
          {t('profile.payment_plan.debit.book_premium')}
        </button>
      </div>
    </div>
  );
};

BookPremium.propTypes = {
  handleOpenModal: func.isRequired,
  trialEndDate: string,
};

export default BookPremium;
