import { noop } from 'lodash';
import { change, focus, startAsyncValidation, stopAsyncValidation } from 'redux-form';

import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import {
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  SET_CURSOR_POSITION,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  VALIDATE_FAILURE,
  VALIDATE_REQUEST,
  VALIDATE_SUCCESS,
} from 'constants/number-ranges';
import notification from 'notifications/number-ranges';
import { numberRangesSelector } from 'reducers/form';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

export const apiFetchNumberRanges = () => ({
  [CALL_API]: {
    endpoint: '/me/settings/number_ranges',
    method: 'GET',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiUpdateNumberRanges = (data) => ({
  [CALL_API]: {
    data,
    endpoint: '/me/settings/number_ranges',
    method: 'PUT',
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const apiValidateNumberRanges = (data) => ({
  [CALL_API]: {
    data,
    endpoint: '/me/settings/number_ranges/validate',
    method: 'POST',
    types: [VALIDATE_REQUEST, VALIDATE_SUCCESS, VALIDATE_FAILURE],
  },
});

export const updateNumberRanges = (data) => (dispatch) =>
  bindServerValidation(apiUpdateNumberRanges(data), dispatch).then(() => {
    dispatch(showNotification(notification));
  });

export const fetchNumberRanges = () => (dispatch) =>
  dispatch(apiFetchNumberRanges()).catch(apiErrorHandler);

export const validateNumberRanges = (values, dispatch, transform = true) =>
  dispatch(apiValidateNumberRanges(values))
    .then(() => Promise.resolve())
    .catch(
      !transform
        ? noop
        : ({
            response: {
              data: { errors },
            },
          }) => {
            const parsedErrors = {};
            errors.forEach((error) => {
              const key = error.id.split('_');
              parsedErrors[key[0]] = { [key[1]]: error.detail };
            });
            throw parsedErrors;
          }
    );

export const insertComponent = (component) => (dispatch, getState) => {
  const state = getState();
  const {
    numberRanges: { field, form, cursor },
  } = state;
  if (field) {
    const previousValue = numberRangesSelector(state, field);
    const newValue = previousValue.slice(0, cursor) + component + previousValue.slice(cursor);
    dispatch(change(form, field, newValue));
    dispatch(focus(form, field));
    const { values } = getState().form.numberRanges;
    dispatch(startAsyncValidation('numberRanges'));
    validateNumberRanges(values, dispatch)
      .then(() => {
        dispatch(stopAsyncValidation('numberRanges'));
      })
      .catch((errors) => {
        dispatch(stopAsyncValidation('numberRanges', errors));
      });
  }
};

export const setCursorPosition = (position) => ({
  type: SET_CURSOR_POSITION,
  payload: position,
});
