import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { deleteBankTransferConnection } from 'actions/bank-transfer-connections';
import {
  togglePaidByCash as togglePaidByCashIncoming,
  togglePaidByUnspecified as togglePaidByUnspecifiedIncoming,
} from 'actions/incoming-invoices';
import {
  togglePaidByCash as togglePaidByCashOutgoing,
  togglePaidByUnspecified as togglePaidByUnspecifiedOutgoing,
} from 'actions/outgoing-invoice';
import { OUTGOING_INVOICE } from 'constants/folder-names';
import { PIWIK_ACTION_REMOVE_BANK_TRANSFER, PIWIK_CATEGORY_EXPENSES } from 'constants/piwik';
import { InvoiceAmendmentTypes } from 'constants/statuses';
import { incomingInvoiceSelector, outgoingInvoiceSelector } from 'reducers/form';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import { ResourceType } from 'types/entities/AssignableResource';
import UnassignedAmount from 'components/Assigment/UnassignedAmount';
import BankTransfer from 'components/BankTransfer';
import bankTransferStyles from 'components/BankTransfer/BankTransfer.module.css';
import Section from 'components/Form/Section/Section';
import Notification from 'components/Notification';

import localStyles from './AssignedBankTransfers.module.css';

const styles = { ...bankTransferStyles };

const { CANCELLATION } = InvoiceAmendmentTypes;

const PaidByCash = ({ onDelete, canDelete = false, paidByCashDate = '', readonly }) => (
  <div className={styles.bankTransfer}>
    <div className={localStyles.cashTitle}>
      <div>{t('revenue.form.paid_by_cash')}</div>
      <div className={localStyles.cashDate}>{paidByCashDate}</div>
    </div>
    {!readonly && canDelete && (
      <button type="button" onClick={onDelete} className={localStyles.delete} />
    )}
  </div>
);

PaidByCash.propTypes = {
  onDelete: PropTypes.func.isRequired,
  canDelete: PropTypes.bool.isRequired,
  paidByCashDate: PropTypes.string,
  readonly: PropTypes.bool,
};

const PaidByUnspecified = ({
  onDelete,
  canDelete = false,
  paidByUnspecifiedDate = '',
  paidByUnspecifiedReason = '',
  readonly,
}) => (
  <div className={styles.bankTransfer}>
    <div className={cx(localStyles.cashTitle, localStyles.contentWrapper)}>
      <div className={cx(localStyles.contentItem, localStyles.fullWidth)}>
        <div>{t('revenue.form.paid_by_unspecified')}</div>
        <div className={localStyles.cashDate}>{paidByUnspecifiedDate}</div>
      </div>
      <div className={localStyles.contentItem}>
        <div>{t('revenue.form.paid_by_unspecified_reason')}</div>
        <div className={localStyles.cashDate}>{paidByUnspecifiedReason}</div>
      </div>
    </div>
    {!readonly && canDelete && (
      <button type="button" onClick={onDelete} className={localStyles.delete} />
    )}
  </div>
);

PaidByUnspecified.propTypes = {
  onDelete: PropTypes.func.isRequired,
  canDelete: PropTypes.bool.isRequired,
  paidByUnspecifiedDate: PropTypes.string,
  paidByUnspecifiedReason: PropTypes.string,
  readonly: PropTypes.bool,
};

const AssignedBankTransfersRaw = ({
  bankTransferInEditMode, // bank transfer in edit mode is still assigned but should not be displayed
  invoiceId,
  invoiceBankTransfers,
  deleteConnection,
  readonly,
  invoiceDetails: {
    amendmentType,
    fullyPaid,
    grossAmountToBePaid,
    paidByCashDate,
    paidByUnspecifiedDate,
    paidByUnspecifiedReason,
  },
  removeCashPayment,
  removeUnspecifiedPayment,
  paidByCash = false,
  paidByUnspecified = false,
  onEdit,
  whichFolder,
  company,
}) => {
  const bankTransfersToDisplay = invoiceBankTransfers.filter(
    ({ id }) => !bankTransferInEditMode || id !== bankTransferInEditMode.id
  );

  const IS_CANCELLATION_INVOICE = amendmentType === CANCELLATION;

  const unassignedAmount = IS_CANCELLATION_INVOICE
    ? parseFloat(grossAmountToBePaid) * -1
    : grossAmountToBePaid;

  return (
    <div className={cx(localStyles.main, { [styles.readonly]: readonly })}>
      <Section
        label={t('bank_transfers.assigment.assigments_label')}
        title={t('bank_transfers.assigment.assigments_title')}
        rightContent={
          <UnassignedAmount
            forceMinusSign={whichFolder !== OUTGOING_INVOICE && !IS_CANCELLATION_INVOICE}
            amount={unassignedAmount}
          />
        }
      >
        {(!paidByCash || !paidByUnspecified) && isEmpty(bankTransfersToDisplay) && (
          <div className={localStyles.noAssigments}>
            {readonly
              ? t('bank_transfers.assigment.no_assigments_view_mode')
              : t('bank_transfers.assigment.no_assigments')}
          </div>
        )}
        {paidByCash && (
          <div className={localStyles.paidByCash}>
            <PaidByCash
              paidByCashDate={paidByCashDate}
              canDelete={!fullyPaid}
              onDelete={removeCashPayment(invoiceId)}
              readonly={readonly}
            />
            {company && (
              <Notification
                className={localStyles.notification}
                title={t('bank_transfers.assigment.cash_payment_warning.title')}
                text={t('bank_transfers.assigment.cash_payment_warning.subtitle')}
                variant="warning"
                isHideButtonHidden
              />
            )}
          </div>
        )}
        {paidByUnspecified && (
          <div className={localStyles.paidByCash}>
            <PaidByUnspecified
              paidByUnspecifiedDate={paidByUnspecifiedDate}
              paidByUnspecifiedReason={paidByUnspecifiedReason}
              canDelete={!fullyPaid}
              onDelete={removeUnspecifiedPayment(invoiceId)}
              readonly={readonly}
            />
          </div>
        )}
        {bankTransfersToDisplay.map((transfer) => (
          <div key={transfer.id} className={localStyles.assigmentWrapper}>
            <BankTransfer
              {...transfer}
              status={transfer.status}
              onEdit={onEdit}
              onDelete={() =>
                deleteConnection(
                  invoiceId,
                  whichFolder === OUTGOING_INVOICE
                    ? ResourceType.OutgoingInvoice
                    : ResourceType.IncomingInvoice,
                  transfer.id,
                  { refreshAfterDelete: true }
                )
              }
              canDelete={!fullyPaid}
              canEdit
              readonly={readonly}
            />
          </div>
        ))}
      </Section>
    </div>
  );
};

AssignedBankTransfersRaw.propTypes = {
  bankTransferInEditMode: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  invoiceId: PropTypes.string,
  paidByCash: PropTypes.bool,
  removeCashPayment: PropTypes.func.isRequired,
  deleteConnection: PropTypes.func.isRequired,
  invoiceBankTransfers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  readonly: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  whichFolder: PropTypes.string.isRequired,
  invoiceDetails: PropTypes.shape({}).isRequired,
};

const AssignedBankTransfersOutgoing = connect(
  (state) => ({
    invoiceId: outgoingInvoiceSelector(state, 'id'),
    invoiceBankTransfers: state.outgoingInvoice.bankTransfers,
    paidByCash: outgoingInvoiceSelector(state, 'paidByCash'),
    paidByUnspecified: outgoingInvoiceSelector(state, 'paidByUnspecified'),
    company: state.company.details,
  }),
  (dispatch) => ({
    deleteConnection: (...args) => dispatch(deleteBankTransferConnection(...args)),
    removeCashPayment: (id) => () => dispatch(togglePaidByCashOutgoing(id, false)),
    removeUnspecifiedPayment: (id) => () =>
      dispatch(togglePaidByUnspecifiedOutgoing(id, false, null, null)),
  })
)(AssignedBankTransfersRaw);

const AssignedBankTransfersIncoming = connect(
  (state) => ({
    invoiceId: incomingInvoiceSelector(state, 'id'),
    invoiceBankTransfers: state.incomingInvoice.bankTransfers || [],
    paidByCash: incomingInvoiceSelector(state, 'paidByCash'),
    paidByUnspecified: incomingInvoiceSelector(state, 'paidByUnspecified'),
    company: state.company.details,
  }),
  (dispatch) => ({
    deleteConnection: (...args) =>
      dispatch(deleteBankTransferConnection(...args)).then(() => {
        piwikHelpers.trackEvent(PIWIK_CATEGORY_EXPENSES, PIWIK_ACTION_REMOVE_BANK_TRANSFER);
      }),
    removeCashPayment: (id) => () => dispatch(togglePaidByCashIncoming(id, false)),
    removeUnspecifiedPayment: (id) => () =>
      dispatch(togglePaidByUnspecifiedIncoming(id, false, null, null)),
  })
)(AssignedBankTransfersRaw);

const AssignedBankTransfers = ({ whichFolder, ...restProps }) => {
  if (whichFolder === OUTGOING_INVOICE) {
    return <AssignedBankTransfersOutgoing whichFolder={whichFolder} {...restProps} />;
  }

  return <AssignedBankTransfersIncoming whichFolder={whichFolder} {...restProps} />;
};

AssignedBankTransfers.propTypes = {
  whichFolder: PropTypes.string.isRequired,
};

export default AssignedBankTransfers;
