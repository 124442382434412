import React, { PureComponent } from 'react';
import cx from 'classnames';
import Downshift from 'downshift-legacy';
import { find, isEmpty } from 'lodash';
import { arrayOf, bool, func, node, number, oneOfType, shape, string } from 'prop-types';

import { noop } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';
import ArrowIcon from 'components/ArrowIcon/ArrowIcon';
import RequiredAddon from 'components/Form/RequiredAddon/RequiredAddon';
import IconLoading from 'components/Icons/IconLoading';

import Option from './components/Option';
import SearchIcon from './components/SearchIcon';

import styles from './DownshiftSelect.module.css';

class DownshiftSelect extends PureComponent {
  getInitialState = ({ isLabel } = {}) => {
    const { initialSelectedItem = {}, meta: { initial } = {}, options } = this.props;
    const initialValue = initial
      ? find(options, (option) => option.value === initial)
      : initialSelectedItem;

    return isLabel ? initialValue.label : initialValue.value;
  };

  state = {
    inputValue: this.getInitialState({ isLabel: true }) || '',
    value: this.getInitialState() || '',
    initialSelectedItem: this.props.initialSelectedItem, // eslint-disable-line
  };

  // If isReinitializedOnInitialChange prop is set and initialSelectedItem
  // changes then update selected item
  static getDerivedStateFromProps(props, { initialSelectedItem = {} }) {
    if (
      props.isReinitializedOnInitialChange &&
      props.initialSelectedItem &&
      initialSelectedItem.value !== props.initialSelectedItem.value
    ) {
      return {
        initialSelectedItem: props.initialSelectedItem,
        value: props.initialSelectedItem.value,
        inputValue: props.initialSelectedItem.label,
      };
    }

    return null;
  }

  handleInputChange = (inputValue, isOpen) => {
    const { isSearchable, input: { onChange = noop } = {}, onInputChange = noop } = this.props;

    if (!isSearchable || inputValue === undefined) return null;
    if (!isOpen) return this.setState({ inputValue: '' });
    onInputChange(inputValue);
    onChange(inputValue);
    return this.setState({ inputValue });
  };

  handleOnSelect = (selectedItem) => {
    const { input: { onChange = noop } = {}, onSelect } = this.props;

    if (!onSelect) {
      onChange(selectedItem.value);
      return this.setState({
        inputValue: selectedItem.label || '',
        value: selectedItem.value || '',
      });
    }
    onChange(selectedItem.value);
    onSelect(selectedItem);
    return this.setState({ inputValue: selectedItem.label || '', value: selectedItem.value || '' });
  };

  handleOnOpen = (openMenu) => {
    const { disabled = false, onOpen = noop } = this.props;

    if (disabled) {
      return;
    }

    onOpen();
    openMenu();
  };

  render() {
    const {
      menuClassName,
      validationChecker = noop,
      options = [],
      suggestionOptions = [],
      label,
      placeholder,
      name,
      input = {},
      required,
      disabled,
      isLoading,
      id,
      inputDisabled,
      isSearchable,
      defaultIcon,
      isFiltrable,
      optionRenderer,
      invalid,
      filterFunction = noop,
      getTrailingContent = noop,
      getEmptyComponent = noop,
      onBlur,
      optionWrapperClassName = '',
      inputClassName = '',
      labelClassName = '',
      containerClassName = '',
      hideMenuScroll = false,
      dataIds = {},
      menuWrapperClassName = '',
      isMenuFullWidth = true,
      addNew: AddNew,
      suggestionsSelect: SuggestionsSelect,
    } = this.props;

    const optionComponent = (item) =>
      optionRenderer ? optionRenderer(item) : <Option item={item} dataId={dataIds.row} />;

    const filterItem = (inputValue, item) => filterFunction(inputValue, item);
    const selectOptions = isFiltrable
      ? options.filter((item) => filterItem(this.state.inputValue, item))
      : options;

    const validationError = validationChecker(this.props) || invalid;
    const areOptionsEmpty = isEmpty(selectOptions);

    return (
      <Downshift
        selectedItem={this.state.value}
        itemToString={(item) => (item ? item.label : '')}
        onSelect={(selectedItem) => this.handleOnSelect(selectedItem)}
        onStateChange={({ inputValue, isOpen = true }) =>
          !inputDisabled ? this.handleInputChange(inputValue, isOpen) : noop
        }
        inputValue={this.state.inputValue}
      >
        {({ getInputProps, getItemProps, isOpen, openMenu, closeMenu }) => (
          <div
            className={cx(styles.container, { [styles.relativeContainer]: !isMenuFullWidth })}
            data-id={dataIds.container || id || name}
          >
            <div
              role="button"
              onClick={!isOpen ? () => this.handleOnOpen(openMenu) : closeMenu}
              onKeyPress={isPressedEnter(!isOpen ? () => this.handleOnOpen(openMenu) : noop)}
              className={cx(styles.wrapper, containerClassName)}
            >
              <input
                {...getInputProps({
                  onFocus: noop,
                  onBlur: onBlur || ((e) => e.preventDefault()),
                  name: input.name,
                  placeholder,
                })}
                readOnly={inputDisabled} // is entire select disabled or is only input disabled
                disabled={disabled}
                className={cx(styles.main, inputClassName, {
                  [styles.filled]: this.state.inputValue,
                  [styles.invalid]: validationError,
                  [styles.isOpen]: isOpen,
                })}
                data-id={dataIds.input}
                autoComplete="off"
              />
              <label htmlFor={name} className={cx(styles.label, labelClassName)}>
                <span className={styles.labelContent}>
                  {label}
                  {required && <RequiredAddon />}
                </span>
              </label>
              {isLoading && <IconLoading width="16" height="16" />}
              {isSearchable && <SearchIcon />}
              {defaultIcon && <ArrowIcon isOpen={isOpen} dataId="ArrowIcon" />}
            </div>
            {isOpen && (
              <div className={cx(styles.menu, menuClassName)}>
                <div
                  className={cx(styles.menuWrapper, menuWrapperClassName, {
                    [styles.hideScroll]: hideMenuScroll || areOptionsEmpty,
                  })}
                >
                  {AddNew}
                  {suggestionOptions.length > 0 && SuggestionsSelect && (
                    <SuggestionsSelect
                      getItemProps={getItemProps}
                      options={suggestionOptions}
                      optionComponent={optionComponent}
                    />
                  )}
                  {!areOptionsEmpty
                    ? selectOptions.map((item, index) => (
                        <div
                          {...getItemProps({
                            key: `${item.label}${item.id}`,
                            index: index + suggestionOptions?.length,
                            item,
                          })}
                          data-id={item.dataId}
                          className={cx(optionWrapperClassName, 'Select-option')}
                          key={index}
                        >
                          {optionComponent(item)}
                        </div>
                      ))
                    : getEmptyComponent()}
                  {getTrailingContent()}
                </div>
              </div>
            )}
          </div>
        )}
      </Downshift>
    );
  }
}

DownshiftSelect.propTypes = {
  onInputChange: func,
  hideMenuScroll: bool,
  onOpen: func,
  onBlur: func,
  getEmptyComponent: func,
  filterFunction: func,
  onSelect: func,
  getTrailingContent: func,
  optionRenderer: func,
  isFiltrable: bool,
  defaultIcon: bool,
  isSearchable: bool,
  invalid: bool,
  disabled: bool,
  inputDisabled: bool,
  validationChecker: func,
  name: string,
  defaultSelected: bool,
  required: bool,
  isLoading: bool,
  label: string,
  placeholder: string,
  input: shape({}),
  meta: shape({}),
  id: string,
  initialSelectedItem: oneOfType([
    shape({
      label: string.isRequired,
      value: oneOfType([number, string, bool]),
    }),
    shape({}),
  ]),
  options: arrayOf(
    oneOfType([
      shape({
        label: string.isRequired,
        value: oneOfType([number, string, bool]),
        dataId: string,
      }),
      shape({}),
    ])
  ),
  optionWrapperClassName: oneOfType([string, shape({})]),
  inputClassName: oneOfType([string, shape({})]),
  labelClassName: oneOfType([string, shape({})]),
  menuClassName: oneOfType([string, shape({})]),
  containerClassName: oneOfType([string, shape({})]),
  isReinitializedOnInitialChange: bool,
  dataIds: shape({
    container: string,
    input: string,
    row: string,
  }),
  menuWrapperClassName: string,
  isMenuFullWidth: bool,
  addNew: node,
};

export default DownshiftSelect;
