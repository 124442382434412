import { push, replace } from 'connected-react-router';
import normalize from 'json-api-normalizer';
import { get } from 'lodash';
import { change } from 'redux-form';
import build from 'redux-object';

import { fetchLineItems } from 'actions/incoming-invoice/line-items';
import { fetchNumberOfUnpaidInternalInvoices } from 'actions/internal-subscription-fees';
import { CALL_API } from 'constants/api';
import EntityPath from 'constants/entitiesPaths';
import {
  CLEAR_ALL_BANK_TRANSFERS,
  CLEAR_ASSIGNED_BANK_TRANSFERS,
  CLOSE_DATEV_MODAL,
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_SUPPLIER_FAILURE,
  CREATE_SUPPLIER_REQUEST,
  CREATE_SUPPLIER_SUCCESS,
  DELETE_MAYBE_SUGGESTION,
  DRAFT_ID,
  FETCH_ALL_BANK_TRANSFERS_FAILURE,
  FETCH_ALL_BANK_TRANSFERS_REQUEST,
  FETCH_ALL_BANK_TRANSFERS_SUCCESS,
  FETCH_FAILURE,
  FETCH_LINE_CATEGORIES_FAILURE,
  FETCH_LINE_CATEGORIES_REQUEST,
  FETCH_LINE_CATEGORIES_SUCCESS,
  FETCH_OLD_LINE_CATEGORIES_FAILURE,
  FETCH_OLD_LINE_CATEGORIES_REQUEST,
  FETCH_OLD_LINE_CATEGORIES_SUCCESS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  REORDER_LINE_ITEMS_FAILURE,
  REORDER_LINE_ITEMS_LOCAL,
  REORDER_LINE_ITEMS_REQUEST,
  REORDER_LINE_ITEMS_SUCCESS,
  TOGGLE_DISABLED_BUTTON_ON_LINE_ITEM_REQUEST,
  TOGGLE_GROSS_NET_FAILURE,
  TOGGLE_GROSS_NET_REQUEST,
  TOGGLE_GROSS_NET_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/incoming-invoice';
import { FORM_NAME } from 'constants/incoming-invoices';
import {
  PIWIK_ACTION_CREATE_SUPPLIER,
  PIWIK_CATEGORY_EXPENSES,
  PIWIK_NAME_CREATE_SUPPLIER_ERROR,
  PIWIK_NAME_CREATE_SUPPLIER_SUCCESS,
} from 'constants/piwik';
import paths from 'routes/paths';
import { pf } from 'shared/utils';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { piwikHelpers } from 'shared/utils/piwik';
import { bindServerValidation } from 'shared/utils/server-validation';
import { clearItem, setItem } from 'shared/utils/storage';

const setDraftId = setItem(DRAFT_ID);
export const clearDraftId = clearItem(DRAFT_ID);

export const extractValues = (values) => {
  const data = {
    ...values,
    supplierId: values.supplier?.id,
    discountPercentage: `${values.discountPercentage}`.includes(',')
      ? pf(values.discountPercentage)
      : parseFloat(values.discountPercentage) || null,
  };

  // We have remove supplier from data because it's an object used in a supplier field.
  delete data.supplier;

  return data;
};

export const apiCreateDraft = () => ({
  [CALL_API]: {
    data: { draft: true },
    method: 'POST',
    endpoint: '/me/incoming_invoices',
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const apiUpdateIncomingInvoice = (invoiceId, data) => {
  if (!invoiceId && invoiceId !== 0) {
    return null;
  }

  return {
    [CALL_API]: {
      data: { ...data, draft: false },
      method: 'PUT',
      endpoint: `/me/incoming_invoices/${invoiceId}`,
      types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
    },
  };
};

export const apiToggleGrossNet = (invoiceId, insertedAsGross) => ({
  [CALL_API]: {
    data: { insertedAsGross },
    method: 'PATCH',
    endpoint: `/me/incoming_invoices/${invoiceId}/toggle_inserted_as_gross`,
    types: [TOGGLE_GROSS_NET_REQUEST, TOGGLE_GROSS_NET_SUCCESS, TOGGLE_GROSS_NET_FAILURE],
  },
});

export const apiReorderLineItems = (invoiceId, lineItemsOrder) => ({
  [CALL_API]: {
    data: { lineItemsOrder },
    method: 'PATCH',
    endpoint: `/me/incoming_invoices/${invoiceId}/reorder`,
    types: [REORDER_LINE_ITEMS_REQUEST, REORDER_LINE_ITEMS_SUCCESS, REORDER_LINE_ITEMS_FAILURE],
  },
});

export const apiFetchIncomingInvoice = (invoiceId, omitReducers = false) => {
  if (!invoiceId && invoiceId !== 0) {
    return null;
  }

  return {
    [CALL_API]: {
      endpoint: `/me/incoming_invoices/${invoiceId}`,
      types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
      omitReducers,
    },
  };
};

export const apiFetchOldLineCategories = () => ({
  [CALL_API]: {
    endpoint: '/invoice_line_categories/invoice_expenses',
    types: [
      FETCH_OLD_LINE_CATEGORIES_REQUEST,
      FETCH_OLD_LINE_CATEGORIES_SUCCESS,
      FETCH_OLD_LINE_CATEGORIES_FAILURE,
    ],
  },
});

export const apiFetchLineCategories = () => ({
  [CALL_API]: {
    endpoint: '/line_item_categories/invoice_expenses',
    types: [
      FETCH_LINE_CATEGORIES_REQUEST,
      FETCH_LINE_CATEGORIES_SUCCESS,
      FETCH_LINE_CATEGORIES_FAILURE,
    ],
  },
});

const paginationParams = (pagination) => ({
  page: pagination.page || 1,
  per_page: pagination.perPage,
});

export const apiFetchAllBankTransfers = (id, pagination, creditNote = false, filters) => ({
  [CALL_API]: {
    params: {
      creditNote,
      ...paginationParams(pagination),
      filters,
    },
    endpoint: `/me/incoming_invoices/${id}/available_bank_transfers`,
    types: [
      FETCH_ALL_BANK_TRANSFERS_REQUEST,
      FETCH_ALL_BANK_TRANSFERS_SUCCESS,
      FETCH_ALL_BANK_TRANSFERS_FAILURE,
    ],
  },
});

export const apiCreateSupplier = (data) => ({
  [CALL_API]: {
    data,
    endpoint: '/me/suppliers',
    method: 'POST',
    types: [CREATE_SUPPLIER_REQUEST, CREATE_SUPPLIER_SUCCESS, CREATE_SUPPLIER_FAILURE],
  },
});

export const apiCreateCancellationInvoice = (invoiceId) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: `/me/incoming_invoices/${invoiceId}/cancellation`,
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const toggleDisableAction = () => (dispatch) =>
  dispatch({ type: TOGGLE_DISABLED_BUTTON_ON_LINE_ITEM_REQUEST });

const newIncomingInvoiceDraft = () => (dispatch) =>
  dispatch(apiCreateDraft())
    .then(({ data }) => {
      setDraftId(data.id);
      return data.id;
    })
    .catch(apiErrorHandler);

export const fetchIncomingInvoice =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchIncomingInvoice(...args)).catch((err) => {
      apiErrorHandler(err);
      dispatch(replace(paths.expenses));
    });

export const updateIncomingInvoice = (invoiceId, data) => (dispatch) =>
  bindServerValidation(
    apiUpdateIncomingInvoice(invoiceId, extractValues(data)),
    dispatch,
    { isReduxForm: true },
    { supplier_id: { id: 'supplier' } }
  ).then(() => {
    if (data.internalSubscriptionFee) dispatch(fetchNumberOfUnpaidInternalInvoices());
    clearDraftId();
    piwikHelpers.trackGoal(6);
  });

export const toggleIncomingInvoiceGrossNet = (invoiceId) => (dispatch, getState) => {
  const insertedAsGross = get(getState(), 'incomingInvoice.details.insertedAsGross', false);

  return bindServerValidation(apiToggleGrossNet(invoiceId, !insertedAsGross), dispatch)
    .then(() => dispatch(fetchIncomingInvoice(invoiceId)))
    .then(() => dispatch(fetchLineItems(invoiceId)));
};

export const fetchLineCategories = () => (dispatch) =>
  dispatch(apiFetchLineCategories()).catch(apiErrorHandler);

export const fetchOldLineCategories = () => (dispatch) =>
  dispatch(apiFetchOldLineCategories()).catch(apiErrorHandler);

export const fetchAllBankTransfers =
  (id, pagination = { page: 1 }, creditNote, filters) =>
  (dispatch) =>
    dispatch(apiFetchAllBankTransfers(id, pagination, creditNote, filters)).catch(apiErrorHandler);

export const fetchMoreAllBankTransfers =
  (id, { page, perPage } = { page: 1 }, creditNote, filters) =>
  (dispatch) =>
    dispatch(apiFetchAllBankTransfers(id, { page: page + 1, perPage }, creditNote, filters)).catch(
      apiErrorHandler
    );

export const clearBankTransfers = () => ({ type: CLEAR_ALL_BANK_TRANSFERS });

export const clearAssignedBankTransfers = { type: CLEAR_ASSIGNED_BANK_TRANSFERS };

export const quickCreateSupplier = (formName) => (values) => (dispatch) =>
  bindServerValidation(apiCreateSupplier(values), dispatch).then(
    (response) => {
      const supplier = build(normalize(response), EntityPath.Suppliers, response.data.id);

      dispatch(change(formName, 'supplier', supplier));

      // if formName is incoming invoice creator
      if (formName === FORM_NAME) {
        piwikHelpers.trackEvent(
          PIWIK_CATEGORY_EXPENSES,
          PIWIK_ACTION_CREATE_SUPPLIER,
          PIWIK_NAME_CREATE_SUPPLIER_SUCCESS
        );
        piwikHelpers.trackGoal(5);
      }
    },
    (response) => {
      if (formName === FORM_NAME)
        piwikHelpers.trackEvent(
          PIWIK_CATEGORY_EXPENSES,
          PIWIK_ACTION_CREATE_SUPPLIER,
          PIWIK_NAME_CREATE_SUPPLIER_ERROR
        );
      throw response;
    }
  );

const fetchIncomingInvoiceResources = (dispatch, invoice) =>
  Promise.all([
    dispatch(fetchLineItems(invoice.id)),
    dispatch(fetchLineCategories()),
    dispatch(fetchOldLineCategories()),
    dispatch(fetchAllBankTransfers(invoice.id, undefined, invoice.creditNote)),
  ]);

// deletes a suggestion for a connection specified by params (from the local store only - no api call) if it exists.
export const deleteMaybeSuggestion = ({ bankTransferId }) => ({
  type: DELETE_MAYBE_SUGGESTION,
  payload: {
    bankTransferId,
  },
});

export const newIncomingCancellationInvoice = (invoiceId) => (dispatch) =>
  dispatch(apiCreateCancellationInvoice(invoiceId))
    .then(({ data }) => data)
    .catch((err) => {
      apiErrorHandler(err);
      dispatch(push(paths.expenses));
    });

export const fetchIncomingInvoiceForEdition =
  (invoiceId, options = {}) =>
  (dispatch, getState) =>
    dispatch(fetchIncomingInvoice(invoiceId, options.omitReducers)).then(() => {
      const invoice = get(getState(), 'incomingInvoice.details');

      if (invoice.meta.actions.update || invoice.meta.actions['update-payment-information']) {
        return fetchIncomingInvoiceResources(dispatch, invoice);
      }

      return dispatch(push(paths.expenses));
    });

export const fetchIncomingInvoiceForShowing = (invoiceId) => (dispatch, getState) =>
  dispatch(fetchIncomingInvoice(invoiceId)).then(() => {
    const invoice = get(getState(), 'incomingInvoice.details');
    return fetchIncomingInvoiceResources(dispatch, invoice);
  });

export const fetchNewIncomingCancellationInvoice = (invoiceId) => async (dispatch) => {
  const invoice = await dispatch(newIncomingCancellationInvoice(invoiceId));
  return fetchIncomingInvoiceResources(dispatch, invoice);
};

export const createIncomingInvoice = () => (dispatch) =>
  dispatch(newIncomingInvoiceDraft()).then((id) => dispatch(fetchIncomingInvoiceForEdition(id)));

export const closeDatevModal = { type: CLOSE_DATEV_MODAL };

export const reorderLineItems = (invoiceId, reorderedLineItems) => (dispatch) => {
  const lineItemsOrder = reorderedLineItems.map(({ id, ordinalNumber }) => ({
    id: Number(id),
    ordinalNumber: Number(ordinalNumber),
  }));
  dispatch({ type: REORDER_LINE_ITEMS_LOCAL, payload: reorderedLineItems });
  return dispatch(apiReorderLineItems(invoiceId, lineItemsOrder)).catch(apiErrorHandler);
};
