import React, { memo, ReactNode } from 'react';

import styles from './Table.module.css';

type BodyRowProps = {
  children: ReactNode;
  dataId?: string;
};

const BodyRow = ({ children, dataId, ...rest }: BodyRowProps) => (
  <div className={styles.bodyRow} data-id={dataId} {...rest}>
    {children}
  </div>
);

export default memo(BodyRow);
