import React from 'react';
import { bool, func, shape, string } from 'prop-types';

import { BASIC_UID, PREMIUM_UID, UPGRADE } from 'constants/payment-plans';

import BookPremium from './components/BookPremium/BookPremium';
import DebitDescription from './components/DebitDescritpion/DebitDescritpion';

const DebitSection = ({
  handleOpenModal,
  currentPaymentPlanDetails: {
    onTrial,
    currentPlanEndDate,
    uid,
    latestHistoryEntryMode,
    trialEndDate,
  },
}) => {
  if (onTrial && latestHistoryEntryMode !== UPGRADE && uid !== BASIC_UID)
    return <BookPremium handleOpenModal={handleOpenModal} trialEndDate={trialEndDate} />;
  switch (uid) {
    case PREMIUM_UID:
      return (
        <DebitDescription currentPlanEndDate={currentPlanEndDate} trialEndDate={trialEndDate} />
      );
    default:
      return null;
  }
};

DebitSection.propTypes = {
  handleOpenModal: func.isRequired,
  currentPaymentPlanDetails: shape({
    onTrial: bool,
    currentPlanEndDate: string,
    uid: string,
    latestHistoryEntryMode: string,
    trialEndDate: string,
  }),
};

export default DebitSection;
