import React from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';

import ConfirmedSign from 'images/icon-confirmation.svg';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import AccountInfoPublicArea from 'components/AccountInfoPublicArea/AccountInfoPublicArea';

import styles from './AccountConfirmed.module.css';

type AccountConfirmedProps = {
  push: (path: string) => void;
};

const AccountConfirmed = ({ push }: AccountConfirmedProps) => {
  return (
    <AccountInfoPublicArea
      headingText={t('registration_confirmation.account_confirmed_public.heading')}
      icon={ConfirmedSign}
      infoText={[
        t('registration_confirmation.account_confirmed_public.text_1'),
        t('registration_confirmation.account_confirmed_public.text_2'),
      ]}
      buttonLabel={t('registration_confirmation.account_confirmed_public.button_label')}
      iconClassName={styles.icon}
      onClick={() => push(paths.login)}
    />
  );
};

const mapDispatchToProps = {
  push: pushAction,
};

export default connect(null, mapDispatchToProps)(AccountConfirmed);
