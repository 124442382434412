import React from 'react';
import { Field } from 'redux-form';

import SectionWithMargin, { SectionRow } from 'containers/Profile/SectionWithMargin';
import { t } from 'shared/utils';
import { SelectField, TextField } from 'components/Form';

import { countyOptions } from './utils';

import styles from '../Company.module.css';

const ContactDetails = () => (
  <SectionWithMargin
    wrapperTheme={styles.sectionWithMargin}
    title={t('profile.company.sections.contact_details')}
  >
    <SectionRow className={styles.row}>
      <Field
        name="street"
        dataId="input-street"
        component={TextField}
        label={t('profile.company.labels.street')}
        placeholder={t('profile.company.placeholders.street')}
        required
      />
      <Field
        name="zipcode"
        dataId="input-zipcode"
        component={TextField}
        maxLength="15"
        label={t('profile.company.labels.zipcode')}
        placeholder={t('profile.company.placeholders.zipcode')}
        required
      />
      <Field
        name="city"
        dataId="input-city"
        component={TextField}
        label={t('profile.company.labels.city')}
        placeholder={t('profile.company.placeholders.city')}
        required
      />
    </SectionRow>
    <SectionRow className={styles.row}>
      <Field
        name="county"
        dataId="input-county"
        component={SelectField}
        options={countyOptions}
        clearable
        label={t('profile.company.labels.county')}
      />
      <Field
        name="country"
        dataId="input-country"
        label={t('profile.company.labels.country')}
        component={TextField}
        required
        disabled
        isDisabledWithUnderline
      />
      <Field
        name="email"
        dataId="input-email"
        component={TextField}
        label={t('profile.company.labels.email')}
        placeholder={t('profile.company.placeholders.email')}
        required
      />
    </SectionRow>
    <SectionRow className={styles.row}>
      <Field
        name="phoneNumber"
        dataId="input-phone-number"
        component={TextField}
        label={t('profile.company.labels.phone_number')}
        placeholder={t('profile.company.placeholders.phone_number')}
        required
      />
      <Field
        dataId="input-fax-number"
        name="faxNumber"
        component={TextField}
        label={t('profile.company.labels.fax_number')}
        placeholder={t('profile.company.placeholders.fax_number')}
      />

      <Field
        name="website"
        dataId="input-website"
        component={TextField}
        label={t('profile.company.labels.website')}
        placeholder={t('profile.company.placeholders.website')}
      />
    </SectionRow>
  </SectionWithMargin>
);

export default ContactDetails;
