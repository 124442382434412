import React, { Children, cloneElement, PureComponent } from 'react';
import cx from 'classnames';
import { debounce, isEqual } from 'lodash';
import { bool, func, node, number, shape, string } from 'prop-types';
import { compose, withState } from 'recompose';

import sharedStyles from 'shared/styles/table.module.css';
import { noop } from 'shared/utils';
import I18n from 'components/I18n';
import Loading from 'components/Loading';
import { Pagination } from 'components/Pagination/Pagination';
import { EmptyState } from 'components/Table';

import localStyles from './Table.module.css';

const styles = { ...sharedStyles, ...localStyles };

class Table extends PureComponent {
  static defaultProps = {
    isFetching: false,
    headers: [],
    sort: noop,
    theme: {
      container: '',
      tableContainer: '',
    },
    isPaginationHidden: false,
    preventRefetchOnUpdate: false,
  };

  componentDidMount = () => {
    this.fetch({
      page: 1,
    });
  };

  componentDidUpdate(prevProps) {
    const { params, preventRefetchOnUpdate } = this.props;
    const { sorting, parsedFilters, pagination = {} } = params;
    const { params: prevParams } = prevProps;
    const { sorting: prevSorting, parsedFilters: prevParsedFilters } = prevParams;

    if (preventRefetchOnUpdate) return;

    const paramsNotChanged =
      isEqual(sorting, prevSorting) && isEqual(parsedFilters, prevParsedFilters);

    if (paramsNotChanged) {
      return;
    }

    const isFiltering = !isEqual(parsedFilters, prevParsedFilters);
    const overwrittenValues = isFiltering ? { page: 1 } : undefined;

    this.fetch(pagination, sorting, overwrittenValues);
  }

  _fetch = (
    { page = 1 } = this.props.params.pagination || {},
    sorting = this.props.params.sorting,
    overwrittenValues
  ) => {
    const { resource, fetch } = this.props;
    return fetch(
      {
        pagination: {
          page,
          ...(resource && { pagination_resource: resource }),
          ...overwrittenValues,
        },
      },
      sorting
    );
  };

  fetch = debounce(this._fetch, 150, { leading: true, trailing: true });

  render() {
    const {
      children,
      emptyTableComponent,
      isFetching,
      params: { pagination, sorting: currentSorting },
      headers,
      setHeader,
      isEmpty,
      setHasChildren,
      paginationComponent,
      sort,
      theme,
      resource,
      isPaginationHidden,
      dataId = '',
    } = this.props;

    const displayEmptyState = isEmpty && !isFetching;
    const displayTable = !isEmpty;
    return (
      <div className={cx(styles.container, theme.container)}>
        {isFetching && <Loading />}

        {displayEmptyState && (
          <EmptyState className={styles.emptyState}>
            {emptyTableComponent || <I18n t="tables.generic_empty" />}
          </EmptyState>
        )}

        {displayTable && (
          <div className={theme.tableContainer}>
            <table className={styles.invoicesTable} data-id={dataId}>
              {Children.map(children, (child) =>
                cloneElement(child, {
                  headers,
                  setHeader,
                  setHasChildren,
                  sort,
                  currentSorting,
                })
              )}
            </table>
          </div>
        )}

        {displayTable && !isPaginationHidden && (
          <div className={styles.pagination}>
            {paginationComponent || (
              <Pagination
                {...pagination}
                request={this.fetch}
                isFetching={isFetching}
                resource={resource}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

Table.propTypes = {
  forcedPagination: number,
  fetch: func.isRequired,
  sort: func,
  isFetching: bool,
  isEmpty: bool,
  children: node,
  emptyTableComponent: node,
  headers: shape({}),
  setHasChildren: func.isRequired,
  setHeader: func.isRequired,
  params: shape({
    sorting: shape({}),
    pagination: shape({
      perPage: number,
      page: number,
    }),
    parsedFilters: shape({}),
  }).isRequired,
  paginationComponent: node,
  theme: shape({
    container: string,
  }),
  resource: string,
  isPaginationHidden: bool,
  dataId: string,
  preventRefetchOnUpdate: bool,
};

const enhance = compose(
  withState('headers', 'setHeader', {}),
  withState('hasChildren', 'setHasChildren', true)
);

export default enhance(Table);
