import React from 'react';

import { t } from 'shared/utils';
import Button from 'redesign/components/atoms/Button/Button';
import ModalActions from 'redesign/components/atoms/ModalActions/ModalActions';
import ModalBody from 'redesign/components/atoms/ModalBody/ModalBody';
import ModalTitle from 'redesign/components/atoms/ModalTitle/ModalTitle';

import * as SC from './VatIdModal.styled';

type VatIdModalProps = {
  isOpen: boolean;
  onConfirm: (...args: unknown[]) => void;
};

export const VatIdModal = ({ isOpen, onConfirm }: VatIdModalProps) => (
  <SC.Wrapper isOpen={isOpen} onRequestClose={onConfirm}>
    <SC.TipIcon />
    <ModalTitle>{t('modals.vat_id_warning.title')}</ModalTitle>
    <ModalBody>
      <p>{t('modals.vat_id_warning.message')}</p>
      <p>{t('modals.vat_id_warning.message_2')}</p>
      <ModalActions>
        <Button data-id="vatIdModalButton" onClick={onConfirm}>
          {t('modals.vat_id_warning.confirm')}
        </Button>
      </ModalActions>
    </ModalBody>
  </SC.Wrapper>
);
