import React, { ReactNode, useMemo } from 'react';
import { useTable } from 'react-table';

import { ResourceType } from 'types/entities/AssignableResource';
import { BankTransferConnection } from 'types/entities/BankTransferConnection';
import TableComponent from 'components/v2/Table/Table/Table';

import { ConnectedResource } from '../../../../../../types';
import { getColumns, getTableData } from './utils';

export type DisconnectResource = (data: {
  resourceId: string;
  resourceType: ResourceType;
}) => () => void;

type TableProps = {
  connectedResources: ConnectedResource[];
  bankTransferConnections: BankTransferConnection[];
  connectResource: () => void;
  disconnectResource: DisconnectResource;
  onEdit: (resource: ConnectedResource) => void;
  onDelete: (resource: ConnectedResource) => void;
};

export type Resource = {
  date: string;
  receiver: string;
  description: ReactNode;
  amount: ReactNode;
  amountAssigned: ReactNode;
  status: ReactNode;
  id: string;
  resourceType: ResourceType;
  bankTransferConnection: BankTransferConnection | undefined;
  actions: ReactNode;
  comment: ReactNode;
};

const Table = ({ connectedResources, bankTransferConnections, onEdit, onDelete }: TableProps) => {
  const tableData = useMemo(
    () => getTableData({ connectedResources, bankTransferConnections }),
    [connectedResources, bankTransferConnections]
  );

  const columns = useMemo(
    () =>
      getColumns({
        onEdit,
        onDelete,
      }),
    [onEdit, onDelete]
  );
  const instance = useTable<Resource>({
    columns,
    data: tableData,
  });

  return <TableComponent instance={instance} />;
};

export default Table;
