import { matchPath, RouteProps } from 'react-router-dom';

export const getMatches = (
  pathname: string,
  urls: Record<string, string | string[] | RouteProps>
) =>
  Object.keys(urls).reduce((acc: { [key: string]: boolean }, key) => {
    acc[key] = !!matchPath(pathname, urls[key]);

    return acc;
  }, {});
