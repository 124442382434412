import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { dismissGlobalNotification } from 'api/me/globalNotifications/dismiss';
import {
  CATEGORY_GLOBAL_NOTIFICATION,
  GLOBAL_NOTIFICATION_CLOSE,
  GLOBAL_NOTIFICATION_DO_NOT_SHOW_AGAIN,
  GLOBAL_NOTIFICATION_GO_TO_INFO,
} from 'constants/piwik';
import { getEmail } from 'selectors/profile';
import { useAppDispatch } from 'shared/hooks/app';
import { piwikHelpers } from 'shared/utils/piwik';
import { actions } from 'store/slices/globalNotifications/reducer';
import { GlobalNotification } from 'types/entities/GlobalNotification';
import Infobar from 'redesign/components/atoms/Infobar/Infobar';

import { Icons } from './constants';

type NotificationsProps = {
  data: GlobalNotification[];
};

const Notifications = ({ data }: NotificationsProps) => {
  const dispatch = useAppDispatch();
  const [notifications, setNotifications] = useState(data);
  const email = useSelector(getEmail);
  const notification = notifications[0];

  const handleClose = useCallback(
    (notification: GlobalNotification) => () => {
      setNotifications((notifications) =>
        notifications.filter((item) => item.id !== notification.id)
      );
      dispatch(actions.add({ id: notification.id, email }));
      piwikHelpers.trackEvent(
        `${CATEGORY_GLOBAL_NOTIFICATION}_${notification.color}`,
        GLOBAL_NOTIFICATION_CLOSE
      );
    },
    [email, dispatch]
  );

  const handleDoNotShowAgainClick = useCallback(
    (notification: GlobalNotification) => () => {
      setNotifications((notifications) =>
        notifications.filter((item) => item.id !== notification.id)
      );
      dismissGlobalNotification(notification.id);
      piwikHelpers.trackEvent(
        `${CATEGORY_GLOBAL_NOTIFICATION}_${notification.color}`,
        GLOBAL_NOTIFICATION_DO_NOT_SHOW_AGAIN
      );
    },
    []
  );

  const handleButtonClick = useCallback(
    (notification: GlobalNotification) => () => {
      window.open(notification.url);
      piwikHelpers.trackEvent(
        `${CATEGORY_GLOBAL_NOTIFICATION}_${notification.color}`,
        GLOBAL_NOTIFICATION_GO_TO_INFO
      );
    },
    []
  );

  useEffect(() => {
    setNotifications(data);
  }, [data]);

  if (!notification) return null;

  return (
    <Infobar
      key={notification.id}
      color={notification.color}
      title={notification.title}
      description={notification.description}
      buttonText={notification.buttonText}
      onButtonClick={handleButtonClick(notification)}
      icon={notification.icon ? Icons[notification.icon] : undefined}
      onCloseClick={handleClose(notification)}
      onDoNotShowAgainClick={handleDoNotShowAgainClick(notification)}
      dataId="global-notification"
    />
  );
};

export default Notifications;
