import styled, { css } from 'styled-components';

import Button from 'redesign/components/atoms/Button/Button';
import BaseBankIcon from 'redesign/components/atoms/Icons/Bank';

export const BankIcon = styled(BaseBankIcon)`
  path:last-child {
    fill: rgb(143, 184, 218);
  }
`;

export const HelperText = styled.div`
  margin-top: ${({ theme }) => `${theme.space[12]}`};
  text-align: center;
  color: ${({ theme }) => theme.colors.grey['080']};
`;

export const Heading = styled.h3`
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.space[4]};
`;

export const Description = styled.p`
  max-width: 700px;
  margin: auto;
  line-height: ${({ theme }) => theme.space[6]};
`;

type EditButtonProps = {
  isWidgetsDisabled?: boolean;
};

export const EditButton = styled(Button)<EditButtonProps>`
  margin: ${({ theme }) => `${theme.space[12]} auto ${theme.space[8]}`};
  vertical-align: bottom;

  ${({ isWidgetsDisabled }) =>
    isWidgetsDisabled &&
    css`
      bottom: ${({ theme }) => theme.space[4]};
    `};
`;

export const IconWrapper = styled.div`
  display: inline-block;
  vertical-align: bottom;
  width: ${({ theme }) => theme.space[5]};
  height: ${({ theme }) => theme.space[5]};
  margin-right: ${({ theme }) => theme.space[2]};
`;
