import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { bool, func, string } from 'prop-types';
import { compose } from 'recompose';
import { Form, reduxForm } from 'redux-form';

import { createClient, updateClient } from 'actions/clients';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { useAppSelector } from 'shared/hooks/app';
import I18n from 'components/I18n';

import AbortButton from './AbortButton';
import CreateClientSection from './CreateClientSection';

import styles from './ClientCreator.module.css';

const deliveryCountry = 'DE';

const ClientCreator = ({
  handleSubmit,
  id,
  submitting,
  submitForm,
  updateForm,
  isFarmpilot: readonly,
  initialize,
  params,
  initialValues: defaultInitialValues,
}) => {
  const clients = useAppSelector((state) => state.clients.data);
  const clientInitialValues = clients.find((client) => params.id === client.id);

  useEffect(() => {
    initialize(id ? clientInitialValues : defaultInitialValues);
  }, []);

  return (
    <Form
      onSubmit={handleSubmit((data) => (id ? updateForm(id, data) : submitForm(data)))}
      className={styles.main}
    >
      <div className={cx(styles.section, styles.creator)}>
        <CreateClientSection isEditing={Boolean(id)} isFarmpilot={readonly} readonly={readonly} />

        <div className={styles.buttons}>
          <AbortButton dataId="ClientPage:button-cancel-client-info" />
          {!readonly && (
            <button
              type="submit"
              className={cx(styles.button, styles.buttonSubmit)}
              disabled={submitting}
              data-id={`ClientPage:button-submit-${id ? 'edit' : 'create'}`}
            >
              <I18n t={`clients.form.submit${id ? 'Edit' : ''}`} />
            </button>
          )}
        </div>
      </div>
    </Form>
  );
};

ClientCreator.propTypes = {
  id: string,
  handleSubmit: func.isRequired,
  submitting: bool,
  submitForm: func.isRequired,
  updateForm: func.isRequired,
  isFarmpilot: bool,
};

ClientCreator.defaultProps = {
  id: null,
};

const reduxFormEnhancer = reduxForm({
  form: 'clientCreator',
  enableReinitialize: true,
  persistentSubmitErrors: true,
  initialValues: { deliveryCountry },
});

const reduxStateEnhancer = connect(
  (state, ownProps) => ({
    id: ownProps.params.id,
    initialValues: state.form.clientCreator.initial,
  }),
  (dispatch, ownProps) => ({
    updateForm: (id, data) => dispatch(updateClient(id, data)),
    submitForm: (data) => {
      const {
        params: { id },
      } = ownProps;

      return id ? dispatch(updateClient(id, data)) : dispatch(createClient(data));
    },
  })
);

const enhance = compose(reduxStateEnhancer, reduxFormEnhancer, withTransitionPrevent());

export default enhance(ClientCreator);
