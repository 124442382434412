import { connect } from 'react-redux';

import {
  deleteDeliveryNoteLineItem,
  updateDeliveryNoteLineItem,
} from 'actions/delivery-note/line-items';
import { TYPE_DELIVERY_NOTE } from 'constants/row-types';
import { EditItemRow } from 'components/LineItems';

import styles from './Table.module.css';

const mapStateToProps = (state) => ({
  deliveryNoteid: state.deliveryNote.details.id,
  lineCategories: state.deliveryNote.lineCategories,
  insertedAsGross: state.deliveryNote.details.insertedAsGross,
  smallEntrepreneur: state.company.details.smallEntrepreneur,
});

const mapDispatchToProps = (dispatch) => ({
  deleteLineItem: (...args) => dispatch(deleteDeliveryNoteLineItem(...args)),
  updateLineItem: (...args) => dispatch(updateDeliveryNoteLineItem(...args)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { lineItem: { id: lineItemId } = {} } = ownProps;
  const { deliveryNoteid, smallEntrepreneur } = stateProps;
  const { deleteLineItem, updateLineItem } = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,
    smallEntrepreneur,
    translationPath: 'features.delivery_note',
    rowType: TYPE_DELIVERY_NOTE,
    cells: [
      { name: 'position', type: 'positionSelect' },
      { name: 'quantity', type: 'quantity', className: styles.quantityCell },
      { name: 'unit', type: 'unitSelect' },
    ],
    extendedRowCells: [
      { name: 'description', type: 'counterInput' },
      { name: 'internalNotes', type: 'counterInput' },
    ],
    updateLineItem: (formValues) => updateLineItem(deliveryNoteid, lineItemId, formValues),
    deleteLineItem: (values) => deleteLineItem(deliveryNoteid, lineItemId, values),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditItemRow);
