import React from 'react';
import cx from 'classnames';
import { bool, node, string } from 'prop-types';

import styles from './InvoiceColumn.module.css';

const InvoiceColumn = ({ className, children, expand, size = 'auto', align = 'left', ...rest }) => (
  <td
    {...rest}
    className={cx(styles.invoiceColumn, styles.column, styles[size], styles[`align-${align}`], {
      [styles.expand]: expand,
      [styles.shrink]: !expand,
    })}
  >
    <div className={className}>{children}</div>
  </td>
);

InvoiceColumn.propTypes = {
  className: string,
  expand: bool,
  size: string,
  align: string,
  children: node.isRequired,
};

export default InvoiceColumn;
