import styled from 'styled-components';

type PreviewProps = {
  $color?: string;
  $isDisabled?: boolean;
};

export const Preview = styled.div<PreviewProps>`
  height: 97px;
  width: 145px;
  border: 1px solid #979797;
  border-radius: 5px;
  background: ${({ $color }) => ($color ? $color : '')};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};

  &:hover {
    box-shadow: 1px 1px 2px 0 rgba(15, 35, 100, 0.4);
  }
`;

export const Picker = styled.div`
  padding: ${({ theme }) => theme.space['2']};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.4);
`;
