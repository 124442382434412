import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { func, string } from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';

import { COMPANY_EDIT_FORM_NAME } from 'constants/payment-plans';
import { t } from 'shared/utils';
import { TextField } from 'components/Form';
import InfoBox from 'components/InfoBox/InfoBox';

import styles from './CompanyEditForm.module.css';

const CompanyEditForm = ({ name, handleSubmit, handleCreateDirectDebit }) => (
  <Grid className={styles.main}>
    <Row>
      <Col xs={12} lg={6}>
        <span className={styles.name}>{name}</span>
        <Form
          className={styles.main}
          onSubmit={handleSubmit(handleCreateDirectDebit)}
          id={COMPANY_EDIT_FORM_NAME}
        >
          <Field
            name="name"
            component={TextField}
            placeholder={t('profile.payment_plan.direct_debit.company_name')}
            data-id="PaymentPlans:directDebitNameField"
          />
          <Field
            name="street"
            component={TextField}
            placeholder={t('profile.payment_plan.direct_debit.street')}
            data-id="PaymentPlans:directDebitStreetField"
          />
          <Row>
            <Col xs={12} lg={6}>
              <Field
                name="zipcode"
                component={TextField}
                maxLength="15"
                placeholder={t('profile.payment_plan.direct_debit.zipcode')}
                data-id="PaymentPlans:directDebitZipcodeField"
              />
            </Col>
            <Col xs={12} lg={6}>
              <Field
                name="city"
                component={TextField}
                placeholder={t('profile.payment_plan.direct_debit.city')}
                data-id="PaymentPlans:directDebitCityField"
              />
            </Col>
          </Row>
        </Form>
      </Col>
      <Col xs={12} lg={6}>
        <InfoBox
          label={t('profile.payment_plan.direct_debit.info_box')}
          arrowPosition="left"
          isFullHeight
        />
      </Col>
    </Row>
  </Grid>
);

CompanyEditForm.propTypes = {
  name: string,
  handleSubmit: func.isRequired,
  handleCreateDirectDebit: func.isRequired,
};

export default reduxForm({
  form: COMPANY_EDIT_FORM_NAME,
  persistentSubmitErrors: true,
})(CompanyEditForm);
