import { showNotification } from 'actions/notification';
import { requestInfectedNotification } from 'notifications/clamAV';

const INFECTED_REQUEST_STATUS_CODE = 420;

const middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const wasRequestInfected = action.status === INFECTED_REQUEST_STATUS_CODE;

    if (wasRequestInfected) {
      dispatch(showNotification(requestInfectedNotification));
    }

    return next(action);
  };

export default middleware;
