import get from 'lodash/get';

import { FETCH_CONTRACT_SUCCESS } from 'constants/contracts';
import { DELETE_SUCCESS, FETCH_SUCCESS, UPLOAD_SUCCESS } from 'constants/contracts/uploaded-files';
import { extractUploadedInvoiceFiles } from 'reducers/common/uploaded-files';

type FileAttributes = {
  id: number;
  attributes: {
    id: number;
    extension: string;
    thumbnail: string;
    sizeMebibytes: number;
  };
} & File;

export default (state = [], action: { type: string }) => {
  switch (action.type) {
    case FETCH_CONTRACT_SUCCESS:
      return extractUploadedInvoiceFiles(action);

    case FETCH_SUCCESS:
    case UPLOAD_SUCCESS: {
      const data = get(action, 'response.data', []);
      return [...state, ...data.map((file: FileAttributes) => file.attributes)];
    }

    case DELETE_SUCCESS:
      return state.filter((f) => get(f, 'id') !== get(action, 'response.data.attributes.id'));

    default:
      return state;
  }
};
