import { push, replace } from 'connected-react-router';
import normalize from 'json-api-normalizer';
import { get, kebabCase } from 'lodash';
import { change } from 'redux-form';
import build from 'redux-object';

import { fetchCustomerDefaults } from 'actions/default-values';
import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import { CLEAR_FILTERS, SET_QUERY_PARAM } from 'constants/common/filters';
import {
  DOWNLOAD_FAILURE,
  DOWNLOAD_REQUEST,
  DOWNLOAD_SUCCESS,
  DUPLICATE_DELIVERY_NOTE_FAILURE,
  DUPLICATE_DELIVERY_NOTE_REQUEST,
  DUPLICATE_DELIVERY_NOTE_SUCCESS,
  FIRST_UPDATE_FAILURE,
  FIRST_UPDATE_REQUEST,
  FIRST_UPDATE_SUCCESS,
  FROM_DUPLICATE_QUERY_PARAM,
  REORDER_LINE_ITEMS_FAILURE,
  REORDER_LINE_ITEMS_LOCAL,
  REORDER_LINE_ITEMS_REQUEST,
  REORDER_LINE_ITEMS_SUCCESS,
  TRANSFORM_FAILURE,
  TRANSFORM_REQUEST,
  TRANSFORM_SUCCESS,
} from 'constants/delivery-note';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FORM_NAME,
  FROM_DELIVERY_NOTE_QUERY_PARAM,
  INDEX_FAILURE,
  INDEX_MORE_FAILURE,
  INDEX_MORE_REQUEST,
  INDEX_MORE_SUCCESS,
  INDEX_REQUEST,
  INDEX_SORT,
  INDEX_SUCCESS,
  NAMESPACE,
  PAGINATION,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/delivery-notes';
import EntityPath from 'constants/entitiesPaths';
import {
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
} from 'constants/outgoing-invoice';
import * as Piwik from 'constants/piwik';
import { Resources } from 'constants/resources';
import {
  deleteSuccess,
  downloadDraftSuccess,
  downloadSuccess,
  downloadSuccessCancelMode,
} from 'notifications/delivery-note';
import { getSanitizedFilters } from 'reducers/common/filters';
import paths from 'routes/paths';
import { downloadData, printData } from 'shared/utils';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { deliveryNoteFilename } from 'shared/utils/file-naming';
import { piwikHelpers } from 'shared/utils/piwik';
import { bindServerValidation } from 'shared/utils/server-validation';

const paginationParams = (pagination = {}) => ({
  page: pagination.page || 1,
  ...pagination,
});

const sortingParams = (sorting = {}) => {
  const direction = sorting.direction === 'DESC' ? '-' : '';
  const column = sorting.column ? kebabCase(sorting.column) : '';
  const sort = `${direction}${column}`;
  return { sort };
};

export const setPage = (page) => ({
  type: PAGINATION,
  page,
});

export const setQueryParam = (param) => (dispatch) => (value) =>
  dispatch({
    type: SET_QUERY_PARAM,
    name: NAMESPACE,
    payload: {
      param,
      parsedValue: value,
      value,
    },
  });

export const clearFilters = ({ clearStatus = false } = {}) => ({
  type: CLEAR_FILTERS,
  name: NAMESPACE,
  payload: { clearStatus },
});

export const sortDeliveryNotes = (column) => (dispatch) =>
  dispatch({
    type: INDEX_SORT,
    column,
  });

export const abortChanges =
  (path = paths.deliveryNotes) =>
  (dispatch) =>
    dispatch(push(path));

export const apiPrintDeliveryNote = (id, values) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: `/me/delivery_notes/${id}/preview`,
    data: { ...values },
    types: [DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE],
    responseType: 'arraybuffer',
  },
});

export const apiDownloadDeliveryNote = (id, values) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: `/me/delivery_notes/${id}/preview`,
    data: { ...values },
    types: [DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE],
    responseType: 'arraybuffer',
  },
});

const apiFetchDeliveryNote = (id) => ({
  [CALL_API]: {
    endpoint: `/me/delivery_notes/${id}`,
    method: 'GET',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiIndexDeliveryNotes = (pagination, sorting, filters = {}) => ({
  [CALL_API]: {
    params: {
      ...paginationParams({ pagination_resource: Resources.DELIVERY_NOTES, ...pagination }),
      ...sortingParams(sorting),
      filters,
    },
    endpoint: '/me/delivery_notes',
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
  },
});

export const apiIndexMoreDeliveryNotes = ({ page, perPage } = {}, sorting, filters = {}) => ({
  [CALL_API]: {
    params: {
      ...sortingParams(sorting),
      ...paginationParams({ page: page + 1, perPage }),
      filters,
    },
    endpoint: '/me/delivery_notes',
    types: [INDEX_MORE_REQUEST, INDEX_MORE_SUCCESS, INDEX_MORE_FAILURE],
  },
});

const apiTransform = (deliveryNote) => ({
  [CALL_API]: {
    endpoint: `/me/delivery_notes/${deliveryNote.id}/transform_into_outgoing_invoice`,
    method: 'POST',
    types: [TRANSFORM_REQUEST, TRANSFORM_SUCCESS, TRANSFORM_FAILURE],
  },
});

export const apiReorderLineItems = (deliveryNoteId, lineItemsOrder) => ({
  [CALL_API]: {
    data: { lineItemsOrder },
    method: 'PATCH',
    endpoint: `/me/delivery_notes/${deliveryNoteId}/reorder`,
    types: [REORDER_LINE_ITEMS_REQUEST, REORDER_LINE_ITEMS_SUCCESS, REORDER_LINE_ITEMS_FAILURE],
  },
});

const apiDuplicateDeliveryNote = (deliveryNoteId) => ({
  [CALL_API]: {
    endpoint: `/me/delivery_notes/${deliveryNoteId}/duplicate`,
    method: 'POST',
    types: [
      DUPLICATE_DELIVERY_NOTE_REQUEST,
      DUPLICATE_DELIVERY_NOTE_SUCCESS,
      DUPLICATE_DELIVERY_NOTE_FAILURE,
    ],
  },
});

export const apiCreateDeliveryNote = (data) => ({
  [CALL_API]: {
    data,
    endpoint: '/me/delivery_notes',
    method: 'POST',
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const apiUpdateDeliveryNote = (id, data) => ({
  [CALL_API]: {
    data,
    endpoint: `/me/delivery_notes/${id}`,
    method: 'PUT',
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const apiFirstUpdateDeliveryNote = (id, data) => ({
  [CALL_API]: {
    data,
    endpoint: `/me/delivery_notes/${id}`,
    method: 'PUT',
    types: [FIRST_UPDATE_REQUEST, FIRST_UPDATE_SUCCESS, FIRST_UPDATE_FAILURE],
  },
});

const apiDelete = (deliveryNote) => ({
  [CALL_API]: {
    endpoint: `/me/delivery_notes/${deliveryNote.id}`,
    method: 'DELETE',
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const apiCreateClient = (data) => ({
  [CALL_API]: {
    data,
    endpoint: '/me/clients',
    method: 'POST',
    types: [CREATE_CLIENT_REQUEST, CREATE_CLIENT_SUCCESS, CREATE_CLIENT_FAILURE],
  },
});

export const quickCreateClient = (formName) => (values) => (dispatch) =>
  bindServerValidation(apiCreateClient(values), dispatch).then(
    (response) => {
      const client = build(normalize(response), EntityPath.Clients, response.data.id);

      dispatch(change(formName, 'client', client));

      if (formName === FORM_NAME) {
        piwikHelpers.trackEvent(
          Piwik.CATEGORY_DELIVERY_NOTES,
          Piwik.ACTION_CREATE_CLIENT_IN_DELIVERY_NOTE_CREATOR,
          Piwik.NAME_CREATE_CLIENT_IN_DELIVERY_NOTE_CREATOR_SUCCESS
        );
        piwikHelpers.trackGoal(2);
      }
    },
    (response) => {
      if (formName === FORM_NAME) {
        piwikHelpers.trackEvent(
          Piwik.CATEGORY_DELIVERY_NOTES,
          Piwik.ACTION_CREATE_CLIENT_IN_DELIVERY_NOTE_CREATOR,
          Piwik.NAME_CREATE_CLIENT_IN_DELIVERY_NOTE_CREATOR_ERROR
        );
      }
      throw response;
    }
  );

export const duplicateDeliveryNote = (deliveryNoteId) => (dispatch) =>
  dispatch(apiDuplicateDeliveryNote(deliveryNoteId))
    .catch(apiErrorHandler)
    .then(({ data: { id: draftId } }) => {
      dispatch(
        replace({
          pathname: paths.editDeliveryNote(draftId),
          search: `?${FROM_DUPLICATE_QUERY_PARAM}`,
        })
      );
    });

export const indexMoreDeliveryNotes = (pagination, sorting) => (dispatch, getState) => {
  const filters = getSanitizedFilters(get(getState(), 'deliveryNotes.parsedFilters', {}));
  return dispatch(apiIndexMoreDeliveryNotes(pagination, sorting, filters)).catch(apiErrorHandler);
};

export const indexDeliveryNotes =
  (pagination, sorting, { noFiltering = false } = {}) =>
  (dispatch, getState) => {
    const filters = noFiltering
      ? {}
      : getSanitizedFilters(get(getState(), 'deliveryNotes.parsedFilters', {}));

    return dispatch(apiIndexDeliveryNotes(pagination, sorting, filters)).catch(apiErrorHandler);
  };

export const getDeliveryNotePreview = (id, data) => (dispatch) =>
  dispatch(apiDownloadDeliveryNote(id, data))
    .then(({ rawResponse }) => rawResponse)
    .catch(apiErrorHandler);

export const deleteDeliveryNote = (id) => (dispatch) =>
  dispatch(apiDelete(id))
    .then(() => {
      dispatch(showNotification(deleteSuccess));
    })
    .catch(apiErrorHandler);

export const getDeliveryNotePrint = (id, data) => (dispatch) =>
  dispatch(apiPrintDeliveryNote(id, data))
    .then(({ rawResponse }) => rawResponse)
    .catch(apiErrorHandler);

export const getDeliveryNoteDownload = (id, data) => (dispatch) =>
  dispatch(apiDownloadDeliveryNote(id, data))
    .then(({ rawResponse }) => rawResponse)
    .catch(apiErrorHandler);

export const transformDeliveryNote = (id) => (dispatch) =>
  dispatch(apiTransform(id)).catch(apiErrorHandler);

export const updateDeliveryNote =
  (itemid, data, transform = false, redirect = true) =>
  (dispatch) =>
    bindServerValidation(
      data.isNew ? apiFirstUpdateDeliveryNote(itemid, data) : apiUpdateDeliveryNote(itemid, data),
      dispatch,
      { isReduxForm: true },
      { client_id: { id: 'client' } }
    ).then(({ response: { data: { attributes } = {} } = {} }) => {
      if (transform) {
        dispatch(transformDeliveryNote({ id: itemid })).then(({ data: { id } }) => {
          dispatch(
            replace({
              pathname: paths.editOutgoingInvoice(id),
              search: `?${FROM_DELIVERY_NOTE_QUERY_PARAM}`,
            })
          );
        });
      } else if (redirect) {
        dispatch(replace(paths.deliveryNotes));
      }
      return { ...attributes, success: true };
    });

export const printDeliveryNote =
  ({ id } = {}, values = {}) =>
  (dispatch) =>
    dispatch(getDeliveryNotePrint(id, values))
      .then((response) => {
        printData(response, 'pdf');
      })
      .catch(apiErrorHandler);

export const downloadDeliveryNote =
  (
    { id, deliveryNoteNumber, draft } = {},
    values = {},
    isPiwikTracked = false,
    isCancelMode = false
  ) =>
  (dispatch) =>
    dispatch(getDeliveryNoteDownload(id, { ...values, watermark: draft }))
      .then((response) => {
        downloadData(response, deliveryNoteFilename({ deliveryNoteNumber, id }), 'pdf');

        if (isPiwikTracked) {
          piwikHelpers.trackEvent(
            Piwik.CATEGORY_DELIVERY_NOTES,
            Piwik.ACTION_DELIVERY_NOTE_DOWNLOAD
          );
        }

        return dispatch(
          showNotification(
            isCancelMode
              ? downloadSuccessCancelMode
              : draft
              ? downloadDraftSuccess
              : downloadSuccess
          )
        );
      })
      .catch(apiErrorHandler);

export const reorderLineItems = (deliveryNoteId, reorderedLineItems) => (dispatch) => {
  const lineItemsOrder = reorderedLineItems.map(({ id, ordinalNumber }) => ({
    id: Number(id),
    ordinalNumber: Number(ordinalNumber),
  }));
  dispatch({ type: REORDER_LINE_ITEMS_LOCAL, payload: reorderedLineItems });
  return dispatch(apiReorderLineItems(deliveryNoteId, lineItemsOrder)).catch(apiErrorHandler);
};

export const fetchDeliveryNote =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchDeliveryNote(...args))
      .then(() => dispatch(fetchCustomerDefaults()))
      .catch((err) => {
        apiErrorHandler(err);
        return dispatch(replace(paths.deliveryNotes));
      });

export const createDeliveryNote = (data) => (dispatch) =>
  dispatch(apiCreateDeliveryNote(data)).catch((err) => {
    apiErrorHandler(err);
    return dispatch(push(paths.deliveryNotes));
  });

export const createAndFetchNewDeliveryNote = () => (dispatch) => {
  dispatch(createDeliveryNote({ draft: true }))
    .then(({ response }) => {
      dispatch(fetchCustomerDefaults());
      return dispatch(fetchDeliveryNote(response.data.id));
    })
    .catch((err) => {
      apiErrorHandler(err);
      return dispatch(replace(paths.deliveryNotes));
    });
};
