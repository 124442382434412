import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import noop from 'lodash/noop';

import styles from './NewItemLink.module.css';

interface NewItemLinkProps {
  path: string;
  caption: string;
  hideIcon?: boolean;
  dataId: string;
  handleClick?: () => void;
}

const NewItemLink = ({
  path,
  caption,
  hideIcon = false,
  handleClick = noop,
  dataId,
}: NewItemLinkProps) => (
  <Link
    onClick={handleClick}
    to={path}
    className={cx(styles.root, {
      [styles.hideIcon]: hideIcon,
    })}
    data-id={dataId}
  >
    {caption}
  </Link>
);

export default NewItemLink;
