import React, { useState } from 'react';
import { bool, func, shape } from 'prop-types';

import FieldsContainer from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoicePaymentSection/PaymentCreator/components/FieldsContainer/FieldsContainer';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import Loading from 'components/Loading';
import { Format } from 'features/figoConnection/challenges/Embedded/constants';
import Hhd from 'features/figoConnection/challenges/Embedded/Hhd/Hhd';
import Photo from 'features/figoConnection/challenges/Embedded/Photo/Photo';
import TextHtml from 'features/figoConnection/challenges/Embedded/TextHtml/TextHtml';

import styles from '../Page.module.css';

const TanChallenge = ({
  selectedTanSchemeName,
  tanChallenge,
  handleCancel,
  handleSubmit,
  isLoading,
}) => {
  const [tanResponse, setTanResponse] = useState('');
  const isSubmitDisabled = !tanResponse || isLoading;

  const handleChange = ({ target: { value } }) => setTanResponse(value);

  const renderTanScheme = () => {
    const sharedProps = {
      challenge: tanChallenge,
      selectedTanSchemeName,
      value: tanResponse,
      onChange: handleChange,
    };

    switch (tanChallenge.format) {
      case Format.Photo:
        return <Photo {...sharedProps} />;

      case Format.Hhd:
        return <Hhd {...sharedProps} />;

      case Format.Text:
      case Format.Html:
        return <TextHtml {...sharedProps} />;

      // no default
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.body}>
        <FieldsContainer>{renderTanScheme()}</FieldsContainer>
        {isLoading && <Loading />}
      </div>
      <div className={styles.footer}>
        <ActionButton
          disabled={isLoading}
          appearance="outlined"
          onClick={handleCancel}
          label={t('bank_accounts.creator.prev_step')}
          className={styles.stepButton}
          type="button"
        />
        <ActionButton
          onClick={() => handleSubmit(tanResponse)}
          disabled={isSubmitDisabled}
          label={t('bank_accounts.creator.next_step')}
          className={styles.stepButton}
          type="submit"
          data-id="BankAccount:creatorSecondStepFinish"
        />
      </div>
    </div>
  );
};

TanChallenge.propTypes = {
  tanChallenge: shape({}).isRequired,
  handleCancel: func.isRequired,
  handleSubmit: func.isRequired,
  isLoading: bool.isRequired,
};

export default TanChallenge;
