import React, { HTMLProps, memo, ReactNode } from 'react';

import styles from './AddMoreButton.module.css';

type AddMoreButtonProps = HTMLProps<HTMLButtonElement> & {
  children: ReactNode;
  dataId?: string;
};

const AddMoreButton = ({ children, dataId, ...rest }: AddMoreButtonProps) => (
  <button {...rest} className={styles.add} type="button" data-id={dataId}>
    {children}
  </button>
);

export default memo(AddMoreButton);
