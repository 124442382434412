import React from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { t } from 'shared/utils';
import { ReminderType as ReminderTypeEnum } from 'types/entities/Cashbook';
import { FormField, SelectField } from 'components/Form';

import {
  monthlyReminderOptions,
  reminderDayOptions,
  reminderHourLabel,
  reminderHourOptions,
  reminderHourPlaceholder,
  reminderTypeOptions,
} from './constants';
import {
  Inputs,
  MonthlyReminder,
  ReminderDay,
  ReminderHour,
  ReminderType,
} from './NotificationSection.styled';

import styles from './NotificationSection.module.css';

type NotificationSectionProps = {
  formName: string;
};

const NotificationSection = ({ formName }: NotificationSectionProps) => {
  const formSelector = formValueSelector(formName);
  const reminderType = useSelector((state) => formSelector(state, 'reminderType'));

  return (
    <Inputs reminderType={reminderType}>
      <ReminderType>
        <FormField
          name="reminderType"
          component={SelectField}
          options={reminderTypeOptions}
          label={t('cashbooks.notifications.fields.reminderType.label')}
          placeholder={t('cashbooks.notifications.fields.reminderType.placeholder')}
          dataId="reminder-type"
        />
      </ReminderType>
      {[ReminderTypeEnum.Daily, ReminderTypeEnum.Weekly, ReminderTypeEnum.Monthly].includes(
        reminderType
      ) && (
        <ReminderHour>
          <FormField
            name="reminderHour"
            component={SelectField}
            options={reminderHourOptions}
            // remove casting when FormField will be rewritten to ts
            label={reminderHourLabel as any}
            placeholder={reminderHourPlaceholder as any}
            labelClassName={styles.reminderHourLabel}
            required
            dataId="reminder-hour"
          />
        </ReminderHour>
      )}
      {reminderType === ReminderTypeEnum.Weekly && (
        <ReminderDay>
          <FormField
            name="reminderDay"
            component={SelectField}
            options={reminderDayOptions}
            label={t('cashbooks.notifications.fields.day.label')}
            placeholder={t('cashbooks.notifications.fields.day.placeholder')}
            required
            dataId="reminder-day"
          />
        </ReminderDay>
      )}
      {reminderType === ReminderTypeEnum.Monthly && (
        <MonthlyReminder>
          <FormField
            name="monthlyReminder"
            component={SelectField}
            options={monthlyReminderOptions}
            label={t('cashbooks.notifications.fields.monthly.label')}
            placeholder={t('cashbooks.notifications.fields.monthly.placeholder')}
            required
            dataId="monthly-reminder"
          />
        </MonthlyReminder>
      )}
    </Inputs>
  );
};

export default NotificationSection;
