export enum DateRangePickerId {
  datevExport = 'datevExport',
  storybook = 'storybook',
}

export type StateEntry = {
  id: DateRangePickerId;
  isValid: boolean;
};

export type StateSlice = StateEntry[];
