import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  ADD_CONFIRMATION_BANNER_EMAIL,
  AddConfirmationBannerEmailAction,
} from 'constants/confirmation-banner';

type ConfirmationBannerState = {
  emails: string[];
};

const initialState: ConfirmationBannerState = {
  emails: [],
};

const reducer = (
  state = initialState,
  action: AddConfirmationBannerEmailAction
): ConfirmationBannerState => {
  switch (action.type) {
    case ADD_CONFIRMATION_BANNER_EMAIL:
      const email = action.payload;

      if (state.emails.includes(email)) {
        return state;
      }

      return {
        ...state,
        emails: [...state.emails, email],
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'confirmationBanner',
  version: 1,
  whitelist: ['emails'],
  storage,
};

export default persistReducer(persistConfig, reducer);
