export const FETCH_REQUEST = 'DELIVERY_NOTE/FETCH_REQUEST';
export const FETCH_SUCCESS = 'DELIVERY_NOTE/FETCH_SUCCESS';
export const FETCH_FAILURE = 'DELIVERY_NOTE/FETCH_FAILURE';

export const CREATE_REQUEST = 'DELIVERY_NOTE/CREATE_REQUEST';
export const CREATE_SUCCESS = 'DELIVERY_NOTE/CREATE_SUCCESS';
export const CREATE_FAILURE = 'DELIVERY_NOTE/CREATE_FAILURE';

export const DELETE_REQUEST = 'DELIVERY_NOTE/DELETE_REQUEST';
export const DELETE_SUCCESS = 'DELIVERY_NOTE/DELETE_SUCCESS';
export const DELETE_FAILURE = 'DELIVERY_NOTE/DELETE_FAILURE';

export const UPDATE_REQUEST = 'DELIVERY_NOTE/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'DELIVERY_NOTE/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'DELIVERY_NOTE/UPDATE_FAILURE';

export const TOGGLE_GROSS_NET_REQUEST = 'DELIVERY_NOTE/TOGGLE_GROSS_NET_REQUEST';
export const TOGGLE_GROSS_NET_SUCCESS = 'DELIVERY_NOTE/TOGGLE_GROSS_NET_SUCCESS';
export const TOGGLE_GROSS_NET_FAILURE = 'DELIVERY_NOTE/TOGGLE_GROSS_NET_FAILURE';

export const FIRST_UPDATE_REQUEST = 'DELIVERY_NOTE/FIRST_UPDATE_REQUEST';
export const FIRST_UPDATE_SUCCESS = 'DELIVERY_NOTE/FIRST_UPDATE_SUCCESS';
export const FIRST_UPDATE_FAILURE = 'DELIVERY_NOTE/FIRST_UPDATE_FAILURE';

export const FETCH_SUMS_REQUEST = 'DELIVERY_NOTE/FETCH_SUMS_REQUEST';
export const FETCH_SUMS_SUCCESS = 'DELIVERY_NOTE/FETCH_SUMS_SUCCESS';
export const FETCH_SUMS_FAILURE = 'DELIVERY_NOTE/FETCH_SUMS_FAILURE';

export const DOWNLOAD_REQUEST = 'DELIVERY_NOTE/DOWNLOAD_REQUEST';
export const DOWNLOAD_SUCCESS = 'DELIVERY_NOTE/DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'DELIVERY_NOTE/DOWNLOAD_FAILURE';

export const TRANSFORM_REQUEST = 'DELIVERY_NOTE/TRANSFORM_REQUEST';
export const TRANSFORM_SUCCESS = 'DELIVERY_NOTE/TRANSFORM_SUCCESS';
export const TRANSFORM_FAILURE = 'DELIVERY_NOTE/TRANSFORM_FAILURE';

export const REORDER_LINE_ITEMS_LOCAL = 'DELIVERY_NOTE/REORDER_LINE_ITEMS_LOCAL';
export const REORDER_LINE_ITEMS_REQUEST = 'DELIVERY_NOTE/REORDER_LINE_ITEMS_REQUEST';
export const REORDER_LINE_ITEMS_SUCCESS = 'DELIVERY_NOTE/REORDER_LINE_ITEMS_SUCCEESS';
export const REORDER_LINE_ITEMS_FAILURE = 'DELIVERY_NOTE/REORDER_LINE_ITEMS_FAILURE';

export const FROM_DELIVERY_NOTE_QUERY_PARAM = 'from-delivery-note';

export const FORM_NAME = 'deliveryNoteCreator';

export const ITEMS_DROPPABLE_ID = 'DELIVERY_NOTE/LINE_ITEMS_DROPPABLE';

export const DUPLICATE_DELIVERY_NOTE_REQUEST = 'DELIVERY_NOTE/DUPLICATE_DELIVERY_NOTE_REQUEST';
export const DUPLICATE_DELIVERY_NOTE_SUCCESS = 'DELIVERY_NOTE/DUPLICATE_DELIVERY_NOTE_SUCCESS';
export const DUPLICATE_DELIVERY_NOTE_FAILURE = 'DELIVERY_NOTE/DUPLICATE_DELIVERY_NOTE_FAILURE';

export const FROM_DUPLICATE_QUERY_PARAM = 'from-duplicate';
