import React, { useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { flow } from 'lodash';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { resetClientDefaults, updateClientDefaults } from 'actions/default-values';
import { getOutgoingInvoice, GetOutgoingInvoiceData } from 'api/me/previews/outgoingInvoice';
import { ClientDocumentsFormName } from 'containers/Clients/ClientDocumentDefaults/ClientDocumentDefaults';
import {
  FormColumn,
  FormColumns,
  FormRow,
} from 'containers/Profile/InvoiceTemplate/DefaultValues/sharedComponents';
import DefaultValuesFormWithPreview from 'containers/Profile/InvoiceTemplate/DefaultValues/sharedComponents/DefaultValuesForm/DefaultValuesFormWithPreview';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { RootState } from 'store';
import { DefaultValues } from 'types/entities/DefaultValues';
import { Checkbox, CurrencyFieldWrapper, SelectField, TextField } from 'components/Form';
import InputsGroup from 'components/InputsGroup/InputsGroup';
import HtmlField from 'components/v2/Form/HtmlField/HtmlField';

import styles from './InvoiceDefaults.module.css';

const DUE_DATE_OPTIONS = [7, 14, 21, 30].map((value) => ({
  value,
  label: t('invoice_templates.documents_defaults.due_date_option', { count: value }),
}));

export const FORM_NAME: ClientDocumentsFormName = 'clientInvoiceDefaults';

type FormData = Pick<
  DefaultValues,
  | 'invoiceDueDate'
  | 'invoiceDiscountGrant'
  | 'invoiceDiscountPercentage'
  | 'invoiceDiscountDate'
  | 'invoiceSubject'
  | 'invoiceSalutation'
  | 'invoiceSalutationContent'
  | 'invoiceFooter'
>;

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    invoiceDueDate: state.defaultValues.values.invoiceDueDate,
    invoiceDiscountGrant: state.defaultValues.values.invoiceDiscountGrant,
    invoiceDiscountPercentage: state.defaultValues.values.invoiceDiscountPercentage,
    invoiceDiscountDate: state.defaultValues.values.invoiceDiscountDate,
    invoiceSubject: state.defaultValues.values.invoiceSubject,
    invoiceSalutation: state.defaultValues.values.invoiceSalutation,
    invoiceSalutationContent: state.defaultValues.values.invoiceSalutationContent,
    invoiceFooter: state.defaultValues.values.invoiceFooter,
  },
  formValues: {
    discountGrant: selector(state, 'invoiceDiscountGrant'),
    invoiceSalutation: selector(state, 'invoiceSalutation'),
    invoiceSalutationContent: selector(state, 'invoiceSalutationContent'),
    invoiceFooter: selector(state, 'invoiceFooter'),
  },
});

const mapDispatchToProps = {
  updateClientDefaults,
  resetClientDefaults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type InvoiceDefaultsProps = ConnectedProps<typeof connector> & {
  clientId: string;
};

const InvoiceDefaults = ({
  formValues: { discountGrant, invoiceSalutation, invoiceSalutationContent, invoiceFooter },
  resetClientDefaults,
  updateClientDefaults,
  handleSubmit,
  clientId,
  reset: resetForm,
}: InvoiceDefaultsProps & InjectedFormProps<FormData, InvoiceDefaultsProps>) => {
  const topFields = useMemo(
    () => (
      <>
        <FormRow>
          <Field
            dataId="ClientDocumentDefaults:input-due-date"
            name="invoiceDueDate"
            component={SelectField}
            checker={serverValidationChecker}
            options={DUE_DATE_OPTIONS}
            placeholder={t('invoice_templates.documents_defaults.due_date')}
            label={t('invoice_templates.documents_defaults.due_date')}
          />
        </FormRow>
        <FormRow>
          <Field
            name="invoiceDiscountGrant"
            dataIds={{
              input: 'ClientDocumentDefaults:input-discount-grant',
              label: 'ClientDocumentDefaults:label-discount-grant',
            }}
            component={Checkbox}
            checker={serverValidationChecker}
          >
            {t('invoice_templates.documents_defaults.discount_grant')}
          </Field>
        </FormRow>
        <FormRow>
          <FormColumns>
            <FormColumn withLabel>
              <CurrencyFieldWrapper
                name="invoiceDiscountPercentage"
                className={styles.inputWrapper}
                precision={2}
                isRawField
                translationBase="invoice_templates.documents_defaults"
                component={TextField}
                checker={serverValidationChecker}
                readonly={!discountGrant}
                dataId="ClientDocumentDefaults:input-discount-percentage"
                isCurrencySignVisible={false}
                isDisabledWithUnderline
              />
              <span>%</span>
            </FormColumn>
            <FormColumn withLabel>
              <Field
                id="discountDate"
                name="invoiceDiscountDate"
                component={SelectField}
                checker={serverValidationChecker}
                className={styles.inputWrapper}
                disabled={!discountGrant}
                options={DUE_DATE_OPTIONS}
                label={t('invoice_templates.documents_defaults.discount_date')}
                placeholder={t('invoice_templates.documents_defaults.discount_date')}
                dataId="ClientDocumentDefaults:input-discount-date"
                disableDateInput
              />
            </FormColumn>
          </FormColumns>
        </FormRow>
        <FormRow>
          <InputsGroup label={t('invoice_templates.documents_defaults.discount_subject')}>
            <Field
              className={styles.grouppedInput}
              name="invoiceSubject"
              checker={serverValidationChecker}
              component={TextField}
              dataId="ClientDocumentDefaults:input-subject"
            />
          </InputsGroup>
        </FormRow>
      </>
    ),
    [discountGrant]
  );

  const values = useMemo(
    () => ({
      salutation_honorific: invoiceSalutation,
      salutation_content: invoiceSalutationContent,
      personal_notes: invoiceFooter,
    }),
    [invoiceSalutation, invoiceSalutationContent, invoiceFooter]
  );

  return (
    <DefaultValuesFormWithPreview<GetOutgoingInvoiceData>
      onSubmit={handleSubmit((values) => updateClientDefaults(clientId, values, 'form_invoice'))}
      onReset={async () => {
        await resetClientDefaults(clientId, 'form_invoice');
        resetForm();
      }}
      sectionName="form_invoice"
      sectionLabel={t('invoice_templates.documents_defaults.invoice_defaults_section')}
      dataIdPrefix="ClientDocumentDefaults:"
      values={values}
      fetchPreview={getOutgoingInvoice}
      topFields={topFields}
    >
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.header')}>
          <Field
            name="invoiceSalutation"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation')}
            tooltip={t('invoice_templates.documents_defaults.salutation_info')}
            dataId="ClientDocumentDefaults:input-salutation"
          />
          <br />
          <Field
            name="invoiceSalutationContent"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation_content')}
            dataId="ClientDocumentDefaults:input-salutation-content"
          />
        </InputsGroup>
      </FormRow>
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.footer')}>
          <Field
            name="invoiceFooter"
            component={HtmlField}
            checker={serverValidationChecker}
            dataId="ClientDocumentDefaults:input-footer"
          />
        </InputsGroup>
      </FormRow>
    </DefaultValuesFormWithPreview>
  );
};

export default flow(
  withTransitionPrevent(),
  reduxForm<FormData, InvoiceDefaultsProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    persistentSubmitErrors: true,
  }),
  connector
)(InvoiceDefaults);
