import React from 'react';
import PropTypes from 'prop-types';

import styles from './Notification.module.css';

const displayFormattedError = (errorWithNewLines) =>
  errorWithNewLines.map((error) => (
    <span key={error}>
      {error}
      <br />
    </span>
  ));

const ErrorBody = ({ error }) => {
  const errorWithNewLines = error.split('\n');
  const formattedError = errorWithNewLines ? displayFormattedError(errorWithNewLines) : error;
  return (
    <span className={styles.text} key={error}>
      {formattedError}
    </span>
  );
};

ErrorBody.propTypes = {
  error: PropTypes.string.isRequired,
};

export default ErrorBody;
