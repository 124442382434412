import React, { memo } from 'react';
import InlineSvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import LoadingIcon from 'components/LoadingIcon';
import Tile from 'redesign/components/atoms/Tile/Tile';
import TileTitle from 'redesign/components/atoms/TileTitle/TileTitle';

import * as SC from './TileEmpty.styled';

type TButton = {
  dataId: string;
  text: string;
  to?: string;
  action?: () => void;
};

interface ITitleEmpty {
  dataId: string;
  image?: JSX.Element | string;
  caption: string;
  title: string;
  info: string;
  buttons?: TButton[];
  center?: boolean;
  isLoading?: boolean;
}

const TileEmpty = ({
  image,
  dataId,
  caption,
  title,
  info,
  buttons,
  center = false,
  isLoading = false,
}: ITitleEmpty) => {
  const renderImage =
    image && typeof image === 'string' ? (
      <InlineSvg src={image} data-id={`${dataId}-svg`} />
    ) : (
      image
    );

  const TileLoading = () => {
    return isLoading ? (
      <SC.LoadingWrapper>
        <LoadingIcon />
      </SC.LoadingWrapper>
    ) : null;
  };

  const TileBody = () => (
    <>
      <SC.Body center={center} isLoading={isLoading}>
        <SC.Image data-id={`${dataId}-image`}>{renderImage}</SC.Image>
        <SC.Header data-id={`${dataId}-header`}>{caption}</SC.Header>
        <SC.Info data-id={`${dataId}-info`}>{info}</SC.Info>
        <SC.ButtonWrapper>
          {buttons?.map(({ dataId, text, to, action }, index) => (
            <SC.Button
              as={Link}
              key={index}
              data-id={!isLoading && dataId}
              $variant={!!index ? 'outlined' : 'contained'}
              type="button"
              onClick={action}
              to={to || ''}
              disabled={isLoading}
            >
              {text}
            </SC.Button>
          ))}
        </SC.ButtonWrapper>
      </SC.Body>

      <TileLoading />
    </>
  );

  return (
    <SC.Container>
      <TileTitle dataId="title">{title}</TileTitle>
      <Tile>
        <TileBody />
      </Tile>
    </SC.Container>
  );
};

export default memo(TileEmpty);
