import normalize from 'json-api-normalizer';
import build from 'redux-object';

import * as requests from 'api/me/customerNotifications/tasks';
import { CALL_API } from 'constants/api';
import {
  ADD_CUSTOMER_TASK,
  DELETE_CUSTOMER_TASK,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPDATE_CUSTOMER_TASK,
} from 'constants/customer-notifications';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { Status } from 'types/entities/Task';

import EntityPath from '../constants/entitiesPaths';
import { bindServerValidation } from '../shared/utils/server-validation';

const apiFetchCustomerNotifications = (etag = '') => ({
  [CALL_API]: {
    endpoint: `/me/customer_notifications/tasks`,
    method: 'GET',
    headers: { 'if-none-match': etag },
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const fetchCustomerNotifications =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchCustomerNotifications(...args)).catch((err) => apiErrorHandler(err));

export const addCustomTask = (task) => async (dispatch) => {
  return bindServerValidation(() => requests.addCustomTask(task), dispatch).then((response) => {
    const task = build(normalize(response.data), EntityPath.CustomerTasks, response.data.id)[0];

    dispatch({
      type: ADD_CUSTOMER_TASK,
      payload: {
        ...task,
        id: Number(task.id),
      },
    });
  });
};

export const updateCustomTask = (task) => async (dispatch) => {
  return bindServerValidation(() => requests.updateCustomTask(task), dispatch).then((response) => {
    const task = build(normalize(response.data), EntityPath.CustomerTasks, response.data.id)[0];

    dispatch({
      type: UPDATE_CUSTOMER_TASK,
      payload: {
        ...task,
        id: Number(task.id),
      },
    });
  });
};

export const toggleCustomTask = (task) => (dispatch) => {
  return requests
    .updateCustomTask({
      ...task,
      status: task.status === Status.Done ? Status.Open : Status.Done,
    })
    .then((response) => {
      const normalizedTask = build(
        normalize(response.data),
        EntityPath.CustomerTasks,
        response.data.id
      )[0];
      const updatedTask = {
        ...normalizedTask,
        id: Number(normalizedTask.id),
      };

      dispatch({
        type: DELETE_CUSTOMER_TASK,
        payload: task,
      });
      dispatch({
        type: ADD_CUSTOMER_TASK,
        payload: updatedTask,
      });
    });
};

export const deleteCustomTask = (id) => (dispatch) => {
  return requests.deleteCustomTask(id).then((response) => {
    const task = build(normalize(response.data), EntityPath.CustomerTasks, response.data.id)[0];

    dispatch({
      type: DELETE_CUSTOMER_TASK,
      payload: {
        ...task,
        id: Number(task.id),
      },
    });
  });
};
