import { validate as validateEmail } from 'email-validator';

export const generateValidation = (validators) => (values) => {
  const validation = validators.map((v) => v(values)).filter((v) => !!v);

  return validation.length ? validation : null;
};

export const filledValidator = (property, error) => (values) => {
  const exist = values[property];

  if (exist) {
    return null;
  }

  return error;
};

export const maxLengthValidator = (maxLength, property, error) => (values) => {
  const value = values[property];
  const maxLengthKept = value && value.length <= maxLength;

  if (!value) {
    return null;
  }
  if (maxLengthKept) {
    return null;
  }

  return error;
};

export const emailValidator = (property, error) => (values) => {
  const email = values[property] || '';

  if (!email.length) return null;
  if (validateEmail(email)) return null;

  return error;
};

// threat values that are empty arrays as falsy
export const safeValidate = (o) =>
  Object.keys(o).reduce((acc, x) => {
    if (Array.isArray(o[x]) && !o[x].length) {
      return acc;
    }
    return Object.assign({}, acc, { [x]: o[x] });
  }, {});

export const numberWithDecimals = (value) => {
  const numbersCommaDot = /^[0-9.,]+$/;
  if (value && !numbersCommaDot.test(value)) return 'error';
  if (value && !value > 0) return 'error';
  return undefined;
};
