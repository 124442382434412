import { drop, isArray, take } from 'lodash';

import { defaultGroupSize } from 'constants/index-actions-groups';

// Split list of actions into two groups
// First n of actions into first group
// Remaining actions into second group
export default (actions, firstGroupSize = defaultGroupSize) => {
  if (!isArray(actions)) {
    return [[actions], []];
  }

  const filteredActions = actions.filter((action) => action.props.visible);

  const visibleActions = take(filteredActions, firstGroupSize);
  const dropdownActions = drop(filteredActions, firstGroupSize);

  return [visibleActions, dropdownActions];
};
