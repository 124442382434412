import React from 'react';

import { t } from 'shared/utils';
import PaidStatusIcon from 'components/StatusIcons/Installment/PaidStatusIcon';
import PartlyPaidStatusIcon from 'components/StatusIcons/Installment/PartlyPaidStatusIcon';

import { Status, Text } from './InstallmentStatusBadge.styled';

type InstallmentStatusBadgeProps = {
  isPaid: boolean;
};

const InstallmentStatusBadge = ({ isPaid }: InstallmentStatusBadgeProps) => (
  <Status>
    {isPaid ? <PaidStatusIcon /> : <PartlyPaidStatusIcon />}
    <Text>{t(`contracts.installment_status.${isPaid ? 'paid' : 'partly_paid'}`)}</Text>
  </Status>
);

export default InstallmentStatusBadge;
