import { combineReducers } from 'redux';

import {
  CLEAR_ALL_BANK_TRANSFERS,
  FETCH_ALL_BANK_TRANSFERS_FAILURE,
  FETCH_ALL_BANK_TRANSFERS_REQUEST,
  FETCH_ALL_BANK_TRANSFERS_SUCCESS,
} from 'constants/incoming-invoice';
import { mapBankTransfer } from 'reducers/incoming-invoice/bank-transfers';
import { getPaginationReducer } from 'reducers/pagination';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_ALL_BANK_TRANSFERS_REQUEST:
      return true;
    case FETCH_ALL_BANK_TRANSFERS_FAILURE:
    case FETCH_ALL_BANK_TRANSFERS_SUCCESS:
      return false;
    default:
      return state;
  }
};

const initialState = [];

const data = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_BANK_TRANSFERS_SUCCESS:
      return state.concat(action.response.data.map(mapBankTransfer));
    case CLEAR_ALL_BANK_TRANSFERS:
      return initialState;
    default:
      return state;
  }
};

const pagination = getPaginationReducer(FETCH_ALL_BANK_TRANSFERS_SUCCESS);

export default combineReducers({
  isFetching,
  data,
  pagination,
});
