import normalize from 'json-api-normalizer';
import { camelCase, omit } from 'lodash';
import { flow, fromPairs, map, values } from 'lodash/fp';

import { VALIDATE_FAILURE, VALIDATE_REQUEST, VALIDATE_SUCCESS } from 'constants/auth';
import { REMOVE_EXTERNAL_TOKEN_SUCCESS } from 'constants/external-tokens';
import {
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/profile';

const normalizeFeatures = (applicationFeatures = {}) =>
  flow(
    values,
    map((rawFeature) => rawFeature.attributes.uid),
    map(camelCase),
    map((featureName) => [featureName, true]),
    fromPairs
  )(applicationFeatures);

const normalizeExternalAppConnections = (externalAppConnections) =>
  flow(values, (connections) =>
    connections.reduce(
      (acc, curr) => ({ ...acc, [curr.attributes.externalAppId]: curr.attributes }),
      {}
    )
  )(externalAppConnections);

export const defaultState = {
  credentials: {
    honorific: '',
    firstName: '',
    lastName: '',
    email: '',
  },
  error: false,
  isFetching: false,
  isFetched: false,
  applicationFeatures: {},
  meta: {
    allBankPinsSaved: true,
  },
};

export const getProfile = (state) => state.profile;

export default (state = defaultState, action) => {
  switch (action.type) {
    case VALIDATE_REQUEST:
    case FETCH_REQUEST:
    case UPDATE_REQUEST:
      return {
        ...state,
        error: false,
        isFetching: true,
      };
    case VALIDATE_FAILURE:
    case FETCH_FAILURE:
    case UPDATE_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };
    case FETCH_SUCCESS:
    case VALIDATE_SUCCESS:
      const response = normalize(action.response || {}, { endpoint: 'meta' });
      const applicationFeatures = normalizeFeatures(response.applicationFeatures);
      const externalAppConnections = normalizeExternalAppConnections(
        response.externalAppConnections
      );

      return {
        ...state,
        applicationFeatures,
        externalAppConnections,
        credentials: action.response.data.attributes,
        isFetched: true,
        isFetching: false,
      };
    case REMOVE_EXTERNAL_TOKEN_SUCCESS:
      const { externalAppConnections: appConnections } = state;
      const appId = action.response.data.attributes.externalAppId;

      return {
        ...state,
        externalAppConnections: omit(appConnections, [appId]),
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          ...action.response.data.attributes,
        },
        isFetched: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
