import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { noop } from 'shared/utils';

import styles from './FieldNotification.module.css';

const FieldNotification = (props) => {
  const { checker = noop, className, message, show = true, variant } = props;
  const errorMessage = checker(props) || message;

  if (!errorMessage || !show) {
    return null;
  }

  return (
    <div data-id="FieldNotification" className={cx(styles.normal, className, styles[variant])}>
      {errorMessage.message || errorMessage}
    </div>
  );
};

FieldNotification.propTypes = {
  checker: PropTypes.func,
  className: PropTypes.string,
  message: PropTypes.string,
  show: PropTypes.bool,
  variant: PropTypes.string,
};

export default FieldNotification;
