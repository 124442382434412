import { GET_SUCCESS, PROCESS_SUCCESS, PROCESSING_STATUS } from 'constants/outgoing-invoice/ocr';
import { parseOcrResponse as parseOcr } from 'reducers/common/ocr';

export const DEFAULT_STATE = {
  status: PROCESSING_STATUS.INITIAL,
  processedData: {},
};

export const parseOcrResponse = parseOcr;

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case PROCESS_SUCCESS:
    case GET_SUCCESS:
      return { ...state, ...parseOcrResponse(action.response) };

    default:
      return state;
  }
};
