import { noop } from 'lodash';

import { isProduction } from 'shared/utils/environment';

type LogValue = string | string[] | number | number[] | object;

type LogAction = (...args: LogValue[]) => void;

interface Logger {
  log: LogAction;
  warn: LogAction;
  error: LogAction;
  alert: LogAction;
}

const getConsoleFn = (name: string, onlyDev: boolean = true): LogAction => {
  if ((onlyDev && isProduction) || typeof console === 'undefined') return noop;
  return (console as any)[name].bind(console); // eslint-disable-line no-console, @typescript-eslint/no-explicit-any
};

const getWindowFn = (name: string, onlyDev: boolean = true): LogAction => {
  if ((onlyDev && isProduction) || typeof window === 'undefined') return noop;
  return (window as any)[name].bind(window); // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const logger: Logger = {
  log: getConsoleFn('log'),
  warn: getConsoleFn('warn'),
  error: getConsoleFn('error'),
  alert: getWindowFn('alert', false),
};
