import { get, isArray } from 'lodash';

import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import {
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/company';
import { updateSuccess } from 'notifications/company';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';
import { fetchOnboarding } from 'store/slices/onboarding/thunks';

export const apiFetchCompany = () => ({
  [CALL_API]: {
    endpoint: '/me/company',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiUpdateCompany = (data = {}) => ({
  [CALL_API]: {
    data,
    method: 'PUT',
    endpoint: '/me/company',
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const fetchCompany = () => (dispatch) => dispatch(apiFetchCompany()).catch(apiErrorHandler);

const serializeCompanyAuthorizedPeopleAttributes = (values) => {
  const companyAuthorizedPeopleAttributes = isArray(values.companyAuthorizedPeopleAttributes)
    ? values.companyAuthorizedPeopleAttributes
    : [];
  const generalPartnerAuthorizedPeopleAttributes = isArray(
    values.generalPartnerAuthorizedPeopleAttributes
  )
    ? values.generalPartnerAuthorizedPeopleAttributes
    : [];

  const authorizedPeople = [
    ...companyAuthorizedPeopleAttributes,
    ...generalPartnerAuthorizedPeopleAttributes,
  ];

  return {
    ...values,
    companyAuthorizedPeopleAttributes: authorizedPeople.map((person) => {
      // fieldArray sets fields as "" by default - we need a {}.
      if (person === '') return {};
      return person;
    }),
  };
};

const extractValues = (rawValues, state) => {
  const values = serializeCompanyAuthorizedPeopleAttributes(rawValues);
  const companyForms = get(state, 'company.companyForms', []);
  const currentCompanyForm = companyForms.find((c) => c.id === values.form);
  const isGeneraPartnerForm =
    currentCompanyForm &&
    currentCompanyForm.id ===
      'limited_partnership_with_limited_liability_company_as_general_partner';
  const companyRegistration = currentCompanyForm && currentCompanyForm.companyRegistration;
  const valuesWithoutGeneralPartner = values.companyAuthorizedPeopleAttributes.filter(
    (person) => !person.forGeneralPartner
  );
  const sortedValues = {
    ...values,
    ...(!isGeneraPartnerForm && { companyAuthorizedPeopleAttributes: valuesWithoutGeneralPartner }),
  };

  if (!companyRegistration || companyRegistration === 'forbidden') {
    return {
      ...sortedValues,
      registered: null,
      registrationNumber: null,
      registrationCourt: null,
    };
  }

  if (companyRegistration === 'optional' && !values.registered) {
    return {
      ...sortedValues,
      registered: false,
      registrationNumber: null,
      registrationCourt: null,
    };
  }

  if (companyRegistration === 'required') {
    return {
      ...sortedValues,
      registered: null,
    };
  }

  return sortedValues;
};

export const updateCompany = (values) => (dispatch, getState) =>
  bindServerValidation(apiUpdateCompany(extractValues(values, getState())), dispatch).then(() => {
    dispatch(showNotification(updateSuccess));
    dispatch(fetchOnboarding());
  });
