import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { get, orderBy } from 'lodash';
import PropTypes from 'prop-types';

import { ITEMS_DROPPABLE_ID } from 'constants/incoming-invoice';
import { companySmallEntrepreneurSelector } from 'selectors/company';
import { incomingInvoiceDetailsSelector } from 'selectors/incomingInvoice';
import { t } from 'shared/utils';
import {
  LineItemsAutoSaveConsumer,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableHeaderCellWithInfo,
} from 'components/LineItems';

import NewItemRow from './NewItemRow';
import Row from './Row';

import styles from './LineItems/LineItems.module.css';

const LineItemsTable = ({
  lineItems = [],
  newItems = [],
  removeNewItem,
  readonly,
  hasErrors,
  insertedAsGross,
  status,
  crudMode,
  smallEntrepreneur,
  handleSelectCategory,
  defaultCategory,
  selectedCategory,
  category,
  taxRates,
  taxRateId,
  defaultVat,
  isInitial,
  setLineItemState,
  lineCategories,
  oldLineCategories,
}) => (
  <Table hasErrors={hasErrors} readonly={readonly}>
    <TableHeader>
      <TableHeaderCell
        title={t('expenses.form.details_section.fields.name')}
        className={styles.positionCell}
      />
      <TableHeaderCellWithInfo
        title={t('expenses.form.details_section.fields.invoice_line_category_id')}
        infoText={t('expenses.form.details_section.fields.invoice_line_category_id_info')}
      />
      <TableHeaderCell
        title={t('expenses.form.details_section.fields.vat')}
        className={styles.vat}
      />
      <TableHeaderCell
        title={
          insertedAsGross
            ? t('expenses.form.details_section.fields.gross_amount')
            : t('expenses.form.details_section.fields.net_amount')
        }
        alignRight
        className={styles.grossAmount}
      />
      <TableHeaderCell
        title={t('expenses.form.details_section.fields.actions')}
        className={cx(styles.actionsHeader, {
          [styles.actionsCellHidden]: readonly,
        })}
        customWidth
      />
    </TableHeader>
    <LineItemsAutoSaveConsumer>
      {({
        addNewLineItemCreator,
        addEditLineItemCreator,
        removeNewLineItemCreator,
        removeEditLineItemCreator,
      }) => (
        <TableBody droppableId={ITEMS_DROPPABLE_ID}>
          {({ provided }) => (
            <>
              {orderBy(lineItems, (item) => item.ordinalNumber).map((item) => (
                <Row
                  key={item.id}
                  lineItem={item}
                  dndId={item.id}
                  dndIndex={item.ordinalNumber}
                  status={status}
                  readonly={readonly}
                  lineItemsLength={lineItems.length}
                  addEditLineItemCreator={addEditLineItemCreator}
                  removeEditLineItemCreator={removeEditLineItemCreator}
                  crudMode={crudMode}
                  addProductHidden
                  smallEntrepreneur={smallEntrepreneur}
                  handleSelectCategory={handleSelectCategory}
                  selectedCategory={selectedCategory}
                  defaultCategory={defaultCategory}
                  category={category}
                  taxRates={taxRates}
                  taxRateId={taxRateId}
                  defaultVat={defaultVat}
                  isInitial={isInitial}
                  setLineItemState={setLineItemState}
                  lineCategories={lineCategories}
                  oldLineCategories={oldLineCategories}
                />
              ))}
              {provided.placeholder}
              {newItems.map((item) => (
                <NewItemRow
                  key={item.rowId}
                  newItem={item}
                  removeItem={removeNewItem(item.rowId)}
                  readonly={readonly}
                  addNewLineItemCreator={addNewLineItemCreator}
                  removeNewLineItemCreator={removeNewLineItemCreator}
                  crudMode={crudMode}
                  addProductHidden
                  smallEntrepreneur={smallEntrepreneur}
                  handleSelectCategory={handleSelectCategory}
                  defaultCategory={defaultCategory}
                  selectedCategory={selectedCategory}
                  category={category}
                  taxRates={taxRates}
                  taxRateId={taxRateId}
                  defaultVat={defaultVat}
                  isInitial={isInitial}
                  setLineItemState={setLineItemState}
                  lineCategories={lineCategories}
                  oldLineCategories={oldLineCategories}
                />
              ))}
            </>
          )}
        </TableBody>
      )}
    </LineItemsAutoSaveConsumer>
  </Table>
);

LineItemsTable.propTypes = {
  newItems: PropTypes.arrayOf(PropTypes.shape({})),
  lineItems: PropTypes.arrayOf(PropTypes.shape({})),
  removeNewItem: PropTypes.func,
  readonly: PropTypes.bool,
  hasErrors: PropTypes.bool,
  insertedAsGross: PropTypes.bool,
  status: PropTypes.string,
  crudMode: PropTypes.string,
  smallEntrepreneur: PropTypes.bool,
};

export default connect((state) => ({
  insertedAsGross: incomingInvoiceDetailsSelector(state).insertedAsGross,
  hasErrors:
    get(state, 'form.invoiceCreator.submitErrors.base') && !state.incomingInvoice.lineItems.length,
  smallEntrepreneur: companySmallEntrepreneurSelector(state),
}))(LineItemsTable);
