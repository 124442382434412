import styled from 'styled-components';

import { SubmitButton } from 'components/Form';
import CircleIcon from 'redesign/components/atoms/CircleIcon/CircleIcon';

export const ReturnButton = styled(SubmitButton)`
  display: block;
  width: 75%;
  margin: 0 auto;
`;

export const Logo = styled(CircleIcon)`
  margin: ${({ theme }) => `${theme.space['4']} auto ${theme.space['8']}`};
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.colors.grey['080']};
  font-size: ${({ theme }) => theme.fontSize['base']};
  letter-spacing: 0.25px;
  line-height: ${({ theme }) => theme.space['6']};
  text-align: center;
`;

export const BoldParagraph = styled(Paragraph)`
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.space['4']};
`;

export const TextWrapper = styled.div`
  margin: 0 auto 5rem;
  width: calc(100% - 3.5rem);
`;
