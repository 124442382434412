import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { bool, elementType, func, shape, string } from 'prop-types';
import { change as changeAction, reset, untouch as untouchAction } from 'redux-form';

import { fetchBankAccount as fetchBankAccountAction, syncBankAccount } from 'actions/bank-account';
import {
  getBankAccess as getBankAccessAction,
  getSca as getScaAction,
  initializeSca as initializeScaAction,
  preLoginToBank as preLoginToBankAction,
  selectSca as selectScaAction,
  solveSca as solveScaAction,
  synchronizeSavePin as synchronizeSavePinAction,
} from 'actions/banks';
import { showErrorNotification as showErrorNotificationAction } from 'actions/notification';
import {
  CREATOR_STEPS_DECOUPLED_CHALLENGE,
  CREATOR_STEPS_REDIRECT_CHALLENGE,
  CREATOR_STEPS_SCA_SELECT_TAN,
  CREATOR_STEPS_TAN_CHALLENGE,
} from 'constants/banks';
import { DE_KONTOUMSAETZE, DE_UPDATE } from 'constants/kontoumsaetze';
import DecoupledChallenge from 'containers/Profile/BankAccounts/BankAccountsCreator/components/DecoupledChallenge/DecoupledChallenge';
import FigoErrorMessage from 'containers/Profile/BankAccounts/BankAccountsCreator/components/FigoErrorMessage/FigoErrorMessage';
import BankLoginForm from 'containers/Profile/BankAccounts/BankAccountsCreator/components/Page2/Page2';
import TanChallenge from 'containers/Profile/BankAccounts/BankAccountsCreator/components/TanChallenge/TanChallenge';
import TanSelection from 'containers/Profile/BankAccounts/BankAccountsCreator/components/TanSelection/TanSelection';
import refreshIcon from 'images/icon-refresh.svg';
import { t } from 'shared/utils';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { piwikHelpers } from 'shared/utils/piwik';
import { ChallengeType, JobStatus } from 'types/entities/Figo';
import Button from 'components/Button';
import Modal, { ConfirmationModal, ModalHeader } from 'components/Modal';
import RedirectChallenge from 'features/figoConnection/challenges/Redirect/Redirect';
import {
  DECOUPLED_CHALLENGE_RETRY_TIMEOUT,
  ERROR_NOTIFICATION_DURATION,
  REDIRECT_CHALLENGE_RETRY_TIMEOUT,
} from 'features/figoConnection/constants';

import styles from './SyncBankAccountButton.module.css';

const SYNC_CHECK_PERIOD = 5000;
const SYNC_TIMEOUT = 30000;
const CreatorSteps = {
  AUTHENTICATION: 1,
  TAN_SELECTION: CREATOR_STEPS_SCA_SELECT_TAN,
  TAN_CHALLENGE: CREATOR_STEPS_TAN_CHALLENGE,
  DECOUPLED_CHALLENGE: CREATOR_STEPS_DECOUPLED_CHALLENGE,
  REDIRECT_CHALLENGE: CREATOR_STEPS_REDIRECT_CHALLENGE,
};

const defaultScaState = {
  isScaCheckInProgress: false,
  isLoadingTanSelection: false,
  isLoadingTanSolve: false,
  acceptedBankAccounts: [],
  selectedTanScheme: {},
  challenge: null,
  accessIds: {},
  loginData: {
    savePin: false,
  },
  timeoutId: null,
};

const defaultState = {
  currentPage: CreatorSteps.AUTHENTICATION,
  isBankLoginModalOpen: false,
  isSyncConfirmModalOpen: false,
  syncingInProgress: false,
  ...defaultScaState,
};

class SyncBankAccountButton extends Component {
  state = { ...defaultState };

  syncInterval = null;
  syncTimeout = null;

  cleanupCreation = () => {
    const { resetForm } = this.props;
    if (this.state.loginInProcess) return;

    clearTimeout(this.state.timeoutId);
    this.setState({ ...defaultState });
    resetForm();
  };

  goToAuthenticationPage = () => {
    const { isScaCheckInProgress } = this.state;
    const {
      change,
      untouch,
      formValues: { credentials = [] } = {},
      setSyncInProgress,
    } = this.props;

    if (isScaCheckInProgress) {
      setSyncInProgress(false);
      this.setBankLoginModalOpen(false);
      return this.setState({ ...defaultScaState });
    }

    change('credentials', {});
    change('savePin', false);
    // we want to untouch all fields from credentials array to skip validation errors
    Object.keys(credentials).forEach((key) => untouch(`credentials.${key}`));

    return this.setState({ ...defaultScaState, currentPage: CreatorSteps.AUTHENTICATION });
  };

  setBankLoginModalOpen = (value) => this.setState({ isBankLoginModalOpen: value });

  setSyncConfirmModalOpen = (value) => this.setState({ isSyncConfirmModalOpen: value });

  handleRefreshButtonClick = () => {
    piwikHelpers.trackEvent(DE_KONTOUMSAETZE, DE_UPDATE);
    this.setSyncConfirmModalOpen(true);
  };

  async fetchScaWithSavedPin() {
    const { getSca, showErrorNotification, setSyncInProgress } = this.props;
    const { accessIds } = this.state;

    try {
      const { status, challenge } = await getSca(accessIds);

      if (status === JobStatus.Completed) {
        this.finishBankAccountSync();
        return;
      }

      if (status === JobStatus.AwaitAuth) {
        /**
         * When we receive a DECOUPLED challenge it's mean that in a loop we have to check the
         * SCA status to see if it's completed or not.
         */
        if (challenge.type === ChallengeType.Decoupled) {
          this.setBankLoginModalOpen(true);
          const timeoutId = setTimeout(
            () => this.fetchScaWithSavedPin(),
            DECOUPLED_CHALLENGE_RETRY_TIMEOUT
          );
          this.handleDecoupledChallenge(timeoutId, challenge);
          return;
        }

        /**
         * When we receive a REDIRECT challenge it's mean that in a loop we have to check the
         * SCA status to see if it's completed or not.
         */
        if (challenge.type === ChallengeType.Redirect) {
          this.setBankLoginModalOpen(true);
          const timeoutId = setTimeout(
            () => this.fetchScaWithSavedPin(),
            REDIRECT_CHALLENGE_RETRY_TIMEOUT
          );
          this.handleRedirectChallenge(timeoutId, challenge);
          return;
        }

        if (challenge.type === ChallengeType.MethodSelection) {
          this.setBankLoginModalOpen(true);
          this.handleTanSelection(challenge);
          return;
        }

        if (challenge.type === ChallengeType.Embedded) {
          this.setBankLoginModalOpen(true);
          this.handleTanWithoutSelection(challenge);
          return;
        }
      }
    } catch (e) {
      if (e.message) {
        showErrorNotification(e.message);
      }
      apiErrorHandler(e);
      setSyncInProgress(false);
      this.goToAuthenticationPage();
    }
  }

  handleScaCheck = async (id) => {
    const {
      getBankAccess,
      initializeSca,
      showErrorNotification,
      bankAccount: { bank = {}, accessMethodType } = {},
      setSyncInProgress,
    } = this.props;

    try {
      setSyncInProgress(true);
      this.setState({ isScaCheckInProgress: true, challengeDecoupledMessage: '' });
      const { figo_id } = await getBankAccess({
        bankCode: bank.code,
        accessMethodType,
        bankAccountID: id,
      });
      const { rawResponse } = await initializeSca({ bankCode: bank.code, figo_id });
      this.setState({ accessIds: rawResponse });
      this.fetchScaWithSavedPin();
    } catch (e) {
      if (e.message) showErrorNotification(e.message);
      apiErrorHandler(e);
      setSyncInProgress(false);
      this.goToAuthenticationPage();
    }
  };

  handleSyncBankAccounts = () => {
    const { bankAccount: { id } = {} } = this.props;
    this.props.syncBankAccount(id);
    return this.props.postLoginAction();
  };

  startBankAccountSync = async () => {
    const { bankAccount, fetchBankAccount, synchronizeSavePin } = this.props;

    this.setSyncConfirmModalOpen(false);

    await synchronizeSavePin();

    const {
      response: {
        data: {
          meta: { savePin },
        },
      },
    } = await fetchBankAccount(bankAccount.id);

    if (savePin) {
      return this.handleScaCheck(bankAccount.id);
    }

    return this.setBankLoginModalOpen(true);
  };

  resetBankAccountSync = () => {
    this.cleanupCreation();
    this.setBankLoginModalOpen(false);
  };

  finishBankAccountSync = () => {
    const { isScaCheckInProgress } = this.state;
    const { setSyncInProgress } = this.props;

    if (isScaCheckInProgress) {
      setSyncInProgress(false);
      this.handleSyncBankAccounts();
      this.setBankLoginModalOpen(false);
      return;
    }

    this.resetBankAccountSync();
    this.props.postLoginAction();
  };

  stopCheckingBankAccount = () => {
    if (this.syncInterval) clearInterval(this.syncInterval);
    if (this.syncTimeout) clearTimeout(this.syncTimeout);

    this.setState({ syncingInProgress: false });
    this.finishBankAccountSync();
  };

  getBankAccount = async () => {
    const { bankAccount, fetchBankAccount } = this.props;
    try {
      const { response: { data: { meta: { fetchingInProgress } = {} } = {} } = {} } =
        await fetchBankAccount(bankAccount.id);

      if (!fetchingInProgress) this.stopCheckingBankAccount();
    } catch (errors) {
      this.stopCheckingBankAccount();
    }
  };

  startCheckingBankAccount = () => {
    this.setState({ syncingInProgress: true });
    this.syncInterval = setInterval(this.getBankAccount, SYNC_CHECK_PERIOD);
    this.syncTimeout = setTimeout(this.stopCheckingBankAccount, SYNC_TIMEOUT);
  };

  // TODO  refactore this whole component with hooks and make whole login reusable. Aslo do the same in containers/Profile/BankAccounts/BankAccountsCreator/BankAccountsCreator.jsx

  async fetchScaWithoutSavedPin() {
    const { getSca, waitForSync, showErrorNotification } = this.props;
    const { accessIds } = this.state;

    try {
      const { status, challenge } = await getSca(accessIds);

      if (status === JobStatus.Completed && waitForSync) {
        this.startCheckingBankAccount();
        return;
      }

      if (status === JobStatus.Completed) {
        this.finishBankAccountSync();
        this.setState({ syncingInProgress: false });
        return;
      }

      if (status === JobStatus.AwaitAuth) {
        /**
         * When we receive a DECOUPLED challenge it's mean that in a loop we have to check the
         * SCA status to see if it's completed or not.
         */
        if (challenge.type === ChallengeType.Decoupled) {
          const timeoutId = setTimeout(
            () => this.fetchScaWithoutSavedPin(),
            DECOUPLED_CHALLENGE_RETRY_TIMEOUT
          );
          this.handleDecoupledChallenge(timeoutId, challenge);
          this.setState({ syncingInProgress: false });
          return;
        }

        /**
         * When we receive a REDIRECT challenge it's mean that in a loop we have to check the
         * SCA status to see if it's completed or not.
         */
        if (challenge.type === ChallengeType.Redirect) {
          const timeoutId = setTimeout(
            () => this.fetchScaWithoutSavedPin(),
            REDIRECT_CHALLENGE_RETRY_TIMEOUT
          );
          this.handleRedirectChallenge(timeoutId, challenge);
          this.setState({ syncingInProgress: false });
          return;
        }

        if (challenge.type === ChallengeType.MethodSelection) {
          this.handleTanSelection(challenge);
          this.setState({ syncingInProgress: false });
          return;
        }

        if (challenge.type === ChallengeType.Embedded) {
          this.handleTanWithoutSelection(challenge);
          this.setState({ syncingInProgress: false });
          return;
        }
      }
    } catch (e) {
      if (e.message) showErrorNotification(e.message);
      apiErrorHandler(e);
      this.setState({ syncingInProgress: false });
      this.goToAuthenticationPage();
    }
  }

  handleSubmit = async (credentials) => {
    const {
      preLoginToBank,
      getBankAccess,
      initializeSca,
      bankAccount: { bank, accessMethodType, id },
      showErrorNotification,
    } = this.props;
    const data = { savePin: false, ...credentials, bankCode: bank.code, bankAccountID: id };

    this.setState({ syncingInProgress: true, challengeDecoupledMessage: '' });

    try {
      await preLoginToBank(data);
      await this.props.syncBankAccount(id);
      const { figo_id } = await getBankAccess({ ...data, accessMethodType });
      const { rawResponse } = await initializeSca({ ...data, figo_id });
      this.setState({ accessIds: rawResponse });
      this.fetchScaWithoutSavedPin();
    } catch (e) {
      if (e.message) {
        showErrorNotification(e.message);
      }
      apiErrorHandler(e);
      this.setState({ syncingInProgress: false });
      this.goToAuthenticationPage();
    }
  };

  handleDecoupledChallenge = (timeoutId, challenge) =>
    this.setState({
      currentPage: CreatorSteps.DECOUPLED_CHALLENGE,
      timeoutId,
      challenge,
    });

  handleRedirectChallenge = (timeoutId, challenge) =>
    this.setState({
      currentPage: CreatorSteps.REDIRECT_CHALLENGE,
      timeoutId,
      challenge,
    });

  handleTanWithoutSelection = (challenge) =>
    this.setState({
      currentPage: CreatorSteps.TAN_CHALLENGE,
      challenge,
    });

  handleTanSelection = (challenge) => {
    this.setState({
      currentPage: CreatorSteps.TAN_SELECTION,
      challenge,
    });
  };

  selectTan = (selectedTanMethod) => {
    this.setState({ selectedTanScheme: selectedTanMethod });
  };

  handleSetSelectedTanPage = async () => {
    const {
      selectSca,
      waitForSync,
      showErrorNotification,
      bankAccount: {
        meta: { savePin },
      },
    } = this.props;
    const {
      accessIds,
      selectedTanScheme: { id },
      challenge: { id: challengeId },
    } = this.state;

    this.setState({ isLoadingTanSelection: true });

    try {
      const { status, challenge } = await selectSca({
        ...accessIds,
        challenge_id: challengeId,
        method_id: id,
      });

      if (status === JobStatus.Completed && waitForSync) {
        this.startCheckingBankAccount();
        return;
      }

      if (status === JobStatus.Completed) {
        this.finishBankAccountSync();
        return;
      }

      // For the case when accessIds are expired on backend
      if (isEmpty(challenge)) {
        this.goToAuthenticationPage();
        return;
      }

      /**
       * When we receive a DECOUPLED challenge it's mean that in a loop we have to check the
       * SCA status to see if it's completed or not.
       */
      if (challenge.type === ChallengeType.Decoupled) {
        if (savePin) {
          this.fetchScaWithSavedPin();
        } else {
          this.fetchScaWithoutSavedPin();
        }
        this.setState({ isLoadingTanSelection: false });
        return;
      }

      return this.setState({
        isLoadingTanSelection: false,
        currentPage: CreatorSteps.TAN_CHALLENGE,
        challenge,
      });
    } catch (e) {
      if (e.message) showErrorNotification(e.message);
      apiErrorHandler(e);
      return this.goToAuthenticationPage();
    }
  };

  handleTanChallengeSolve = async (tanResponse) => {
    const { solveSca, waitForSync, showErrorNotification } = this.props;
    const {
      accessIds,
      challenge: { id: challengeId },
    } = this.state;

    this.setState({ isLoadingTanSolve: true });

    try {
      const { response = {} } = await solveSca({
        challenge_response: tanResponse,
        challenge_id: challengeId,
        ...accessIds,
      });

      if (response.status === JobStatus.Completed && waitForSync) {
        this.startCheckingBankAccount();
        return;
      }

      if (response.status === JobStatus.Completed) this.finishBankAccountSync();

      // For the case when accessIds are expired on backend
      if (isEmpty(response)) {
        this.goToAuthenticationPage();
        return;
      }

      this.setState({ isLoadingTanSolve: false });
    } catch (e) {
      if (e.message) {
        showErrorNotification(e.message);
      }
      apiErrorHandler(e);
      this.goToAuthenticationPage();
    }
  };

  componentWillUnmount() {
    if (this.syncInterval) clearInterval(this.syncInterval);
    if (this.syncTimeout) clearTimeout(this.syncTimeout);
    this.cleanupCreation();
  }

  render() {
    const {
      bankAccount: { bank = {}, name, accessMethodType },
      disabled,
      customButton: CustomButton,
    } = this.props;
    const {
      isBankLoginModalOpen,
      isSyncConfirmModalOpen,
      syncingInProgress,
      selectedTanScheme,
      isLoadingTanSelection,
      isLoadingTanSolve,
      currentPage,
      challenge,
    } = this.state;

    return (
      <>
        {CustomButton && (
          <CustomButton disabled={disabled} onClick={this.handleRefreshButtonClick} />
        )}
        {!CustomButton && (
          <Button
            label={t('bank_transfers.table.refresh')}
            icon={refreshIcon}
            onClick={this.handleRefreshButtonClick}
            disabled={disabled}
            className={styles.refresh}
            snapIconToLeft
          />
        )}
        <Modal isOpen={isBankLoginModalOpen} className={styles.modal}>
          <ModalHeader>
            <span className={styles.modalHeader}>
              {t('bank_transfers.sync.login_modal_header', {
                name,
              })}
            </span>
          </ModalHeader>

          {currentPage === CreatorSteps.AUTHENTICATION && (
            <BankLoginForm
              bank={bank}
              onSubmit={this.handleSubmit}
              className={styles.form}
              handleCancel={this.resetBankAccountSync}
              syncingInProgress={syncingInProgress}
              accessMethodType={accessMethodType}
            />
          )}

          {currentPage === CreatorSteps.DECOUPLED_CHALLENGE && (
            <DecoupledChallenge challenge={challenge} />
          )}

          {currentPage === CreatorSteps.REDIRECT_CHALLENGE && (
            <RedirectChallenge challenge={challenge} onCancel={this.goToAuthenticationPage} />
          )}

          {currentPage === CreatorSteps.TAN_SELECTION && (
            <TanSelection
              supportedTanSchemes={challenge.auth_methods}
              selectedTanScheme={selectedTanScheme}
              setCreatorState={this.selectTan}
              handleCancel={this.goToAuthenticationPage}
              handleNextStep={this.handleSetSelectedTanPage}
              isLoading={isLoadingTanSelection}
            />
          )}

          {currentPage === CreatorSteps.TAN_CHALLENGE && (
            <TanChallenge
              tanChallenge={challenge}
              selectedTanSchemeName={selectedTanScheme.name}
              handleCancel={this.goToAuthenticationPage}
              handleSubmit={this.handleTanChallengeSolve}
              isLoading={isLoadingTanSolve}
            />
          )}
        </Modal>
        <ConfirmationModal
          dangerousAction={false}
          isOpen={isSyncConfirmModalOpen}
          header={t('bank_transfers.sync.confirmation_modal.header')}
          onClose={() => this.setSyncConfirmModalOpen(false)}
          onConfirm={this.startBankAccountSync}
          closeLabel={t('bank_transfers.sync.confirmation_modal.cancel')}
          confirmLabel={t('bank_transfers.sync.confirmation_modal.confirm')}
        >
          <div>{t('bank_transfers.sync.confirmation_modal.message_1')}</div>
          <div>{t('bank_transfers.sync.confirmation_modal.message_2')}</div>
        </ConfirmationModal>
      </>
    );
  }
}

SyncBankAccountButton.propTypes = {
  change: func.isRequired,
  untouch: func.isRequired,
  disabled: bool,
  syncBankAccount: func,
  fetchBankAccount: func,
  bankAccount: shape({
    name: string,
  }),
  resetForm: func,
  postLoginAction: func,
  waitForSync: bool,
  preLoginToBank: func.isRequired,
  getBankAccess: func.isRequired,
  initializeSca: func.isRequired,
  getSca: func.isRequired,
  selectSca: func.isRequired,
  solveSca: func.isRequired,
  showErrorNotification: func.isRequired,
  formValues: shape({}),
  setSyncInProgress: func.isRequired,
  synchronizeSavePin: func.isRequired,
  /**
   * Pass custom component to be used instead of the default button.
   */
  customButton: elementType,
};

SyncBankAccountButton.defaultProps = {
  waitForSync: false,
};

const formName = 'bankAccountCreator';

const mapStateToProps = (state) => ({
  formValues: get(state, 'form.bankAccountCreator.values'),
});

const mapDispatchToProps = (dispatch) => ({
  syncBankAccount: (...args) => dispatch(syncBankAccount(...args)),
  fetchBankAccount: (...args) => dispatch(fetchBankAccountAction(...args)),
  resetForm: () => dispatch(reset(formName)),
  change: (field, value) => dispatch(changeAction(formName, field, value)),
  untouch: (field) => dispatch(untouchAction(formName, field)),
  preLoginToBank: (...args) => dispatch(preLoginToBankAction(...args)),
  getBankAccess: (...args) => dispatch(getBankAccessAction(...args)),
  initializeSca: (...args) => dispatch(initializeScaAction(...args)),
  getSca: (...args) => dispatch(getScaAction(...args)),
  selectSca: (...args) => dispatch(selectScaAction(...args)),
  solveSca: (...args) => dispatch(solveScaAction(...args)),
  showErrorNotification: (...args) =>
    dispatch(
      showErrorNotificationAction(...args, {
        CustomMessage: FigoErrorMessage,
        duration: ERROR_NOTIFICATION_DURATION,
      })
    ),
  synchronizeSavePin: (...args) => dispatch(synchronizeSavePinAction(...args)),
});

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export default withRedux(SyncBankAccountButton);
