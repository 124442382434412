import { head } from 'lodash';

import { CALL_API } from 'constants/api';
import {
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DOWNLOAD_FAILURE,
  DOWNLOAD_REQUEST,
  DOWNLOAD_SUCCESS,
  UPLOAD_FAILURE,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS,
} from 'constants/incoming-invoice/uploaded-files';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

export const apiUploadInvoiceFile = (invoiceId, data) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    endpoint: `/me/incoming_invoices/${invoiceId}/invoice_files`,
    types: [UPLOAD_REQUEST, UPLOAD_SUCCESS, UPLOAD_FAILURE],
  },
});

export const apiDeleteInvoiceFile = (invoiceId, id) => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: `/me/incoming_invoices/${invoiceId}/invoice_files/${id}`,
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const apiDownloadFilePreview = (invoiceId, id) => ({
  [CALL_API]: {
    endpoint: `/me/incoming_invoices/${invoiceId}/invoice_files/${id}`,
    types: [DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE],
    responseType: 'arraybuffer',
  },
});

export const uploadInvoiceFile = (invoiceId, files) => (dispatch) =>
  bindServerValidation(apiUploadInvoiceFile(invoiceId, { file: head(files) }), dispatch, {
    isReduxForm: false,
  }).catch(apiErrorHandler);

export const deleteInvoiceFile =
  (...args) =>
  (dispatch) =>
    dispatch(apiDeleteInvoiceFile(...args)).catch(apiErrorHandler);

export const downloadFile = (invoiceId, id) => (dispatch) =>
  dispatch(apiDownloadFilePreview(invoiceId, id)).catch(apiErrorHandler);
