import React, { ReactNode, useCallback, useState } from 'react';

import { OBS_DROPDOWN_MORE_INFORMATION } from 'constants/piwik';

import { trackEvent } from '../utils';
import { Arrow, Content, Header, Icon, Title, Wrapper } from './Collapsable.styled';

type CollapsableProps = {
  title: ReactNode;
  children: ReactNode;
  defaultOpen?: boolean;
  eventName?: string;
  dataId?: string;
};

const Collapsable = ({ title, children, dataId }: CollapsableProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTriggerOpen = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
    trackEvent(OBS_DROPDOWN_MORE_INFORMATION);
  }, [isOpen]);

  return (
    <Wrapper>
      <Header onClick={handleTriggerOpen}>
        <Title data-id={dataId}>
          {title}
          <Icon>
            <Arrow $isOpen={isOpen} />
          </Icon>
        </Title>
      </Header>
      <Content isOpen={isOpen}>{children}</Content>
    </Wrapper>
  );
};

export default Collapsable;
