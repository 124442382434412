import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { usePrevious } from 'react-use';
import cx from 'classnames';
import { push as reduxPush } from 'connected-react-router';
import { parse } from 'date-fns';
import { get } from 'lodash';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';

import { fetchClientDefaults as fetchClientDefaultsAction } from 'actions/default-values';
import {
  abortChanges,
  createReminder,
  downloadReminder,
  fetchOutgoingInvoiceResources,
  sendReminder,
  updateReminder,
} from 'actions/outgoing-invoice';
import { CREATE_REMINDER_MODE } from 'constants/common/crud';
import { MAX_REMINDERS_COUNT } from 'constants/incoming-invoice';
import { FORM_NAME } from 'constants/outgoing-invoice';
import {
  PIWIK_ACTION_CANCELL_REMINDER,
  PIWIK_ACTION_SAVE_PDF_REMINDER,
  PIWIK_CATEGORY_REVENUE,
  PIWIK_CONVERSION_ASSIGN_CREATE_REVENUE_REMINDER,
} from 'constants/piwik';
import { InvoiceStatuses } from 'constants/statuses';
import Client from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/CreateInvoiceSection/Client';
import { FormField } from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/CreateInvoiceSection/CreateInvoiceSection';
import { outgoingInvoiceSelector } from 'reducers/form';
import paths from 'routes/paths';
import componentsStyles from 'shared/styles/components.module.css';
import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import { getPaymentReminderInitialValues } from 'shared/utils/payment-remidner-initial-values';
import { piwikHelpers } from 'shared/utils/piwik';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import ActionDropdown from 'components/ActionPanel/ActionDropdown';
import { ButtonAppearances } from 'components/Button';
import Card from 'components/Card';
import CategorySearch from 'components/CategorySearch/CategorySearch';
import { DateField, TextField } from 'components/Form';
import I18n from 'components/I18n';
import { LineItemsAutoSaveProvider } from 'components/LineItems';
import useLineItemsControl, { SectionType } from 'components/LineItems/useLineItemControl';
import HtmlField from 'components/v2/Form/HtmlField/HtmlField';

import InvoiceDetailsSection from '../OutgoingInvoiceCreator/InvoiceDetailsSection';
import InvoiceSumSection from '../OutgoingInvoiceCreator/InvoiceSumSection';
import EmailEditor from './EmailEditor/EmailEditor';

import fieldStyles from '../OutgoingInvoiceCreator/CreateInvoiceSection/CreateInvoiceSection.module.css';
import notesStyles from '../OutgoingInvoiceCreator/CreateInvoiceSection/Notes/Notes.module.css';
import formStyles from '../OutgoingInvoiceCreator/OutgoingInvoiceCreator.module.css';
import localStyles from './ReminderNew.module.css';

const styles = { ...componentsStyles, ...formStyles, ...fieldStyles, ...localStyles };

const defaultIsReadonly = () => false;

const ReminderNew = ({
  companyName,
  invoiceId,
  paymentRemindersCount: reminderCount,
  invoiceNumber,
  selectedDueDate,
  abort,
  downloadPdf,
  canCreate,
  isReadonly = defaultIsReadonly,
  paymentRemindersEnabled,
  canCreateNextReminder,
  currentClient = {},
  invoiceDueDate,
  imported,
  invoiceDetails,
  push,
  isShowing,
  reminderId,
  handleSubmit,
  updateForm,
  submitForm,
  isEditing,
  isCreating,
  reminder,
  fetchClientDefaults,
  fetchOutgoingInvoiceResources,
  sendingDocumentsByEmailEnabled,
}) => {
  const { category, lineCategories, handleSelectCategory, selectedCategory } = useLineItemsControl({
    sectionType: SectionType.outgoingInvoice,
    formName: FORM_NAME,
  });

  const [sendEmailModalVisible, setSendEmailModalVisible] = useState();
  const prevCurrentClient = usePrevious(currentClient);

  useEffect(() => {
    if (!reminder) {
      fetchOutgoingInvoiceResources(invoiceId).then(() => {
        if (!canCreate && !isShowing && !isEditing) {
          abort();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!prevCurrentClient?.id && currentClient?.id) fetchClientDefaults(currentClient.id);
  }, [currentClient]);

  const setSendMailModal = (value) => setSendEmailModalVisible(value);

  const saveReminder = async (redirect = true) => {
    return handleSubmit((data) =>
      isEditing
        ? updateForm({ redirect, reminderId, ...data })
        : submitForm({ redirect, companyName, ...data })
    )();
  };

  const downloadPdfWrapper = async () => {
    if (!isShowing) {
      const { id } = await saveReminder();
      reminderId = id;
    }

    const reminderNumber = isCreating ? reminderCount + 1 : reminderCount;
    downloadPdf({
      reminderId,
      reminderNumber,
      invoiceNumber,
      companyName,
      invoiceId,
      paymentReminderDueDate: selectedDueDate,
      currentDueDate: selectedDueDate,
    });

    piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_SAVE_PDF_REMINDER);
    piwikHelpers.trackGoal(PIWIK_CONVERSION_ASSIGN_CREATE_REVENUE_REMINDER);
  };

  const createReminder = () => push(paths.createPaymentReminder(invoiceId));

  const sendActions = () => {
    const downloadAction = {
      name: t('revenue.form.download'),
      onClick: downloadPdfWrapper,
      tooltip: t('revenue.reminder.download_tooltip'),
      dataId: 'NewPaymentReminder:button-save-or-download',
    };
    const sendByEmailAction = {
      name: t('revenue.form.send_email'),
      onClick: () => setSendMailModal(true),
      tooltip: t('revenue.reminder.send_email_tooltip'),
      dataId: 'NewPaymentReminder:button-save_and_send_email',
    };

    return sendingDocumentsByEmailEnabled ? [downloadAction, sendByEmailAction] : [downloadAction];
  };

  return (
    <form className={cx({ [styles.tabsSeparator]: imported })}>
      <LineItemsAutoSaveProvider>
        <div className={cx(styles.section, styles.creator)}>
          <Card className={styles.main}>
            <Card.Header className={styles.header}>
              <I18n t="reminder.header" className={styles.headerLeft} />
            </Card.Header>
            <Card.Body>
              <div className={cx(styles.columns, styles.columnsPadded)}>
                <div className={styles.column}>
                  <FormField
                    name="subject"
                    readonly={isReadonly('subject')}
                    component={TextField}
                    required
                    dataId="PaymentReminder:input-subject"
                  />
                  <Client readonly={isReadonly('client')} />
                </div>
                <div className={styles.column}>
                  <FormField
                    name="number"
                    readonly
                    component={TextField}
                    disabledPlaceHolder={t('revenue.form.number_disabled')}
                  />
                  <FormField
                    disabled
                    name="dueDate"
                    component={DateField}
                    label={t('revenue.form.old_due_date')}
                    required
                  />
                  <FormField
                    disabled={isReadonly('currentDueDate')}
                    name="currentDueDate"
                    minDate={
                      invoiceDueDate ? parse(invoiceDueDate, 'dd.MM.yyyy', new Date()) : undefined
                    }
                    component={DateField}
                    label={t('revenue.form.current_due_date')}
                    required
                  />
                  <FormField
                    name="invoiceLineCategoryId"
                    dataId="ProposalPage:select-category"
                    label={`${t('revenue.form.category')} *`}
                    component={CategorySearch}
                    categories={lineCategories}
                    selectedItem={selectedCategory}
                    initialSelectedItem={category}
                    initialInputValue={category?.name}
                    onSelectCategory={handleSelectCategory}
                    disabled={true}
                    withHint
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card className={styles.main}>
            <div className={styles.columnsPadded}>
              <label htmlFor="introduction">
                <I18n t="revenue.form.payment_reminder.introduction" />
              </label>
              <Field
                data-id="introduction"
                name="introduction"
                disabled={isReadonly('introduction')}
                component={HtmlField}
                checker={checker}
              />
            </div>
          </Card>
          <InvoiceDetailsSection
            crudMode={CREATE_REMINDER_MODE}
            readonly={isReadonly('lineItems')}
            lineCategories={lineCategories}
          />
          <div className={styles.bottomSection}>
            <Card className={notesStyles.wrapper}>
              <label htmlFor="footer">
                <I18n t="revenue.form.payment_reminder.footer" />
              </label>
              <Field
                data-id="footer"
                name="footer"
                disabled={isReadonly('footer')}
                component={HtmlField}
                checker={checker}
              />
            </Card>
            <InvoiceSumSection insertedAsGross={invoiceDetails.insertedAsGross} />
          </div>
        </div>

        <ActionPanel className={styles.creatorActionsSection}>
          <ActionDropdown
            upwards
            visible
            actions={sendActions()}
            title={t('reminder.send')}
            dataId="NewPaymentReminder:button-create"
          />
          <ActionButton
            appearance={ButtonAppearances.outlined}
            visible={paymentRemindersEnabled && canCreateNextReminder && !isCreating}
            onClick={createReminder}
            label={t('revenue.form.create_next_reminder')}
            dataId="NextPaymentReminder:button-create"
          />
          <ActionButton
            appearance={ButtonAppearances.outlined}
            visible={isCreating}
            onClick={() => {
              piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_CANCELL_REMINDER);
              abort();
            }}
            label={t('revenue.form.abort')}
          />
          {sendEmailModalVisible && (
            <EmailEditor
              onClose={() => setSendMailModal(false)}
              client={currentClient}
              title={t('modals.payment_reminder.send_email.title')}
              saveReminder={saveReminder}
            />
          )}
        </ActionPanel>
      </LineItemsAutoSaveProvider>
    </form>
  );
};

const mapStateToProps = (state, { match, isShowing = false, reminder = {}, imported }) => {
  const { id: reminderId } = reminder;
  const client = outgoingInvoiceSelector(state, 'client');
  const companyName = client?.companyName;
  const paymentRemindersCount = get(state, 'outgoingInvoice.details.paymentRemindersCount', 0);
  const invoiceNumber = state.outgoingInvoice.details.number;
  const invoiceStatus = state.outgoingInvoice.details.status;
  const isDraft = invoiceStatus === 'draft';
  const isOverdue = invoiceStatus === 'overdue';
  const canCreateNextReminder =
    (invoiceStatus === InvoiceStatuses.OVERDUE ||
      invoiceStatus === InvoiceStatuses.PAYMENT_REMINDED) &&
    paymentRemindersCount < MAX_REMINDERS_COUNT;
  const isCreating = !reminderId;
  const { actions = {} } = state.outgoingInvoice.details;
  const canCreate =
    actions['create-payment-reminder'] === undefined ? true : actions['create-payment-reminder'];

  return {
    invoiceId: match.params.id,
    /*
     * when editing draft, every field is editable
     * when editing non-draft, only editable fields are editable
     */
    initialValues: getPaymentReminderInitialValues(state, reminder, isCreating),
    companyName,
    currentClient: client,
    isShowing,
    isEditing: Boolean(reminderId),
    isCreating,
    reminderId,
    canCreate,
    canCreateNextReminder,
    imported,
    paymentRemindersCount,
    invoiceNumber,
    isReadonly: (fieldName) => {
      const reminderDisabledFields = ['lineItems', 'subject', 'client'];
      if (reminderDisabledFields.includes(fieldName)) return true;
      if (isShowing) return true;
      if (isDraft) return false;
      if (isOverdue && isCreating) return false;
      const editableFields = [
        'bankActivity',
        'paid',
        'paidByCashDate',
        'introduction',
        'footer',
        'paymentReminderDueDate',
        'currentDueDate',
        'invoiceLineCategoryId',
      ];
      return !editableFields.includes(fieldName);
    },
    sendingDocumentsByEmailEnabled: state.profile.applicationFeatures.sendingDocumentsByEmail,
    paymentRemindersEnabled: state.profile.applicationFeatures.paymentReminders,
    invoiceDueDate: state.outgoingInvoice.details.dueDate,
    selectedDueDate: outgoingInvoiceSelector(state, 'paymentReminderDueDate'),
    invoiceDetails: state.outgoingInvoice.details,
  };
};

const mapDispatchToProps = {
  abort: abortChanges,
  submitForm: createReminder,
  updateForm: updateReminder,
  fetchOutgoingInvoiceResources,
  downloadPdf: downloadReminder,
  sendPaymentReminder: sendReminder,
  push: reduxPush,
  fetchClientDefaults: fetchClientDefaultsAction,
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    enableReinitialize: true,
    persistentSubmitErrors: true, // allow submiting invalid form
    form: 'outgoingInvoiceCreator',
  })
);

export default enhance(ReminderNew);
