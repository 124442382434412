import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api';

export type GetContractsData = {
  is_revenue?: boolean;
  page?: number;
  pagination_resource?: string;
  without_pagination?: boolean;
  sort?: string;
  filters?: {
    gross_amount_gte?: number;
    gross_amount_lte?: number;
    recurring_transaction_interval_id?: number;
    subject?: string;
    full_text_search?: string;
    transaction_date_gte?: string;
    transaction_date_lte?: string;
  };
};

export const getContracts = (data?: GetContractsData) => {
  const url = '/me/contracts';

  return api.get<JsonApiResponse>(url, {
    params: data,
  });
};

export const deleteContract = (id: string, password?: string) => {
  const url = `/me/contracts/${id}`;
  const data = password ? { password } : undefined;

  return api.delete<JsonApiResponse>(url, {
    data,
  });
};

export const getExpenseContractsLineCategories = () => {
  const url = '/line_item_categories/contract_expenses';

  return api.get<JsonApiResponse>(url);
};

export const getRevenueContractsLineCategories = () => {
  const url = '/line_item_categories/contract_revenues';

  return api.get<JsonApiResponse>(url);
};

export const getLoanContractsLineCategories = () => {
  const url = '/line_item_categories/loan_expenses';

  return api.get<JsonApiResponse>(url);
};

export const getOldExpenseContractsLineCategories = () => {
  const url = 'invoice_line_categories/invoice_expenses';

  return api.get<JsonApiResponse>(url);
};
