import { connect } from 'react-redux';

import { deleteProposalLineItem, updateProposalLineItem } from 'actions/proposal/line-items';
import { TYPE_PROPOSAL } from 'constants/row-types';
import { proposalSelector } from 'reducers/form';
import { productCatalogItemsEnabledHelper } from 'routes/accesses';

import EditItemRow from '../LineItems/EditItemRow';

const mapStateToProps = (state) => ({
  proposalId: state.proposal.details.id,
  insertedAsGross: state.proposal.details.insertedAsGross,
  smallEntrepreneur: state.company.details.smallEntrepreneur,
  productCatalogItemsEnabled: productCatalogItemsEnabledHelper(state),
  vatValue: proposalSelector(state, 'vat'),
  invoiceLineCategoryId: proposalSelector(state, 'invoiceLineCategoryId'),
});

const mapDispatchToProps = (dispatch) => ({
  deleteLineItem: (...args) => dispatch(deleteProposalLineItem(...args)),
  updateLineItem: (...args) => dispatch(updateProposalLineItem(...args)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { lineItem: { id: lineItemId } = {} } = ownProps;
  const { proposalId, productCatalogItemsEnabled, smallEntrepreneur, insertedAsGross } = stateProps;
  const { deleteLineItem, updateLineItem } = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,
    smallEntrepreneur,
    invoiceInsertedAsGross: insertedAsGross,
    translationPath: 'proposals',
    rowType: TYPE_PROPOSAL,
    cells: [
      {
        name: 'position',
        type: productCatalogItemsEnabled ? 'positionSelect' : 'positionInput',
      },
      { name: 'quantity', type: 'quantity' },
      { name: 'unit', type: 'unitSelect' },
      { type: insertedAsGross ? 'grossLinked' : 'netLinked' },
      { name: 'vat', type: 'select' },
      {
        name: insertedAsGross ? 'totalGrossAmount' : 'totalNetAmount',
        type: 'text',
        textType: 'currency',
      },
    ],
    extendedRowCells: [
      {
        name: 'itemNumber',
        type: productCatalogItemsEnabled ? 'itemNumberSelect' : 'itemNumber',
      },
      { name: 'description', type: 'counterInput' },
      { name: 'internalNotes', type: 'counterInput' },
    ],
    updateLineItem: (formValues) => updateLineItem(proposalId, lineItemId, formValues),
    deleteLineItem: (values) => deleteLineItem(proposalId, lineItemId, values),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditItemRow);
