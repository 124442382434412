import { generateMedia } from 'styled-media-query';

import { LG, MD, SM, XLG, XSM, XXSM } from 'constants/breakpoints';

const media = generateMedia({
  'xxsm-up': XXSM,
  'iphone-xs': '415px',
  'xsm-up': XSM,
  'sm-up': SM,
  'md-up': MD,
  'lg-up': LG,
  'xlg-up': XLG,
  'xxlg-up': '92em',
  'googlelarge-up': '115em',
});

export default media;
