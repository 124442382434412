import React, { SVGAttributes } from 'react';

const IconDone = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 21 15" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-3.13-6H24v26H-3.13z" />
      <path
        d="M18.27.473L7.906 12.128l-5.26-4.356a1.286 1.286 0 00-1.718.085 1.098 1.098 0 00.092 1.635l6.207 5.14c.518.428 1.312.373 1.755-.125L20.155 1.943a1.1 1.1 0 00-.174-1.63c-.521-.392-1.283-.322-1.711.16z"
        fill="#1EA532"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default IconDone;
