import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { bool, func, shape, string } from 'prop-types';

import { duplicateProposal as duplicateProposalAction } from 'actions/proposal';
import * as Piwik from 'constants/piwik';
import { InvoiceStatuses } from 'constants/statuses';
import paths from 'routes/paths';
import tableStyles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import Amount from 'components/Amount';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import I18n from 'components/I18n';
import ActionDelete from 'components/IndexActionsContainer/actionIcons/ActionDelete';
import ActionDuplicate from 'components/IndexActionsContainer/actionIcons/ActionDuplicate';
import ActionEdit from 'components/IndexActionsContainer/actionIcons/ActionEdit';
import ActionEditFromProposal from 'components/IndexActionsContainer/actionIcons/ActionEditFromProposal';
import ActionView from 'components/IndexActionsContainer/actionIcons/ActionView';
import IndexActionsContainer from 'components/IndexActionsContainer/IndexActionsContainer';
import InvoiceStatusBadge from 'components/Table/InvoiceStatusBadge/InvoiceStatusBadge';

import localStyles from '../ProposalsTable.module.css';

export const styles = { ...tableStyles, ...localStyles };

class ProposalRow extends Component {
  handleShowClick = () =>
    piwikHelpers.trackEvent(Piwik.CATEGORY_PROPOSALS, Piwik.ACTION_SHOW_PROPOSAL);

  handleEditClick = () =>
    piwikHelpers.trackEvent(Piwik.CATEGORY_PROPOSALS, Piwik.ACTION_EDIT_PROPOSAL);

  handleTransformToOutgoingInvoiceClick = () => {
    const { item, onTransformToOutgoingInvoice } = this.props;
    onTransformToOutgoingInvoice(item);
    piwikHelpers.trackEvent(Piwik.CATEGORY_PROPOSALS, Piwik.ACTION_INDEX_VIEW_TRANSFORM_PROPOSAL);
  };

  handleTransformToOrderConfirmationClick = () => {
    const { item, onTransformToOrderConfirmation } = this.props;
    onTransformToOrderConfirmation(item);
  };

  handleActionDuplicate = (event) => {
    event.preventDefault();

    const {
      duplicateProposal,
      item: { id },
    } = this.props;

    duplicateProposal(id);
  };

  render() {
    const {
      item,
      onDelete,
      showPath = paths.showProposal,
      editPath = paths.editProposal,
    } = this.props;

    const permittedActions = get(item, 'meta.actions', {});
    const canShow = permittedActions.show;
    const canEdit = permittedActions.edit;
    const canDuplicate = permittedActions.duplicate;
    const canTransformIntoOrderConfirmation = permittedActions.transform_into_order_confirmation;
    const canTransformIntoOutgoingInvoice = permittedActions.transform_into_outgoing_invoice;
    const canDelete = permittedActions.delete;
    const invoiceStatus = item.draft ? InvoiceStatuses.DRAFT : InvoiceStatuses.SENT;

    return (
      <tr className={styles.main} data-id="ProposalRow">
        <td className={styles.column}>
          <div className={styles.cellContent}>
            <InvoiceStatusBadge status={invoiceStatus} remindersCount={0} />
          </div>
        </td>
        <td className={styles.column}>
          <div className={styles.cell}>
            <I18n t="proposals.table.columns.client" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="ProposalRow:client-name">
              {get(item, 'relationships.client.name')}
            </CellContentWithTooltip>
          </div>
        </td>
        <td className={styles.column}>
          <div className={styles.cell}>
            <I18n t="proposals.table.columns.proposal_number" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="ProposalRow:number">
              {item.proposalNumber}
            </CellContentWithTooltip>
          </div>
        </td>
        <td className={styles.column}>
          <div className={styles.cell}>
            <I18n t="proposals.table.columns.subject" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="ProposalRow:subject">
              {item.subject}
            </CellContentWithTooltip>
          </div>
        </td>
        <td className={styles.column}>
          <div className={styles.cell}>
            <I18n t="proposals.table.columns.proposal_date" className={styles.cellHeader} />
            <div className={styles.cellContent} data-id="ProposalRow:creation-date">
              {item.proposalDate}
            </div>
          </div>
        </td>
        <td className={styles.column}>
          <div className={styles.cell}>
            <I18n t="proposals.table.columns.valid_until" className={styles.cellHeader} />
            <div className={styles.cellContent} data-id="ProposalRow:valid-util-date">
              {item.validUntil}
            </div>
          </div>
        </td>
        <td className={styles.columnRight}>
          <div className={styles.cell}>
            <I18n t="proposals.table.columns.total_gross_amount" className={styles.cellHeader} />
            <div className={styles.cellContent}>
              <Amount
                dataId="ProposalRow:amount"
                amount={item.totalGrossAmount}
                currency={item.currency}
              />
            </div>
          </div>
        </td>
        <td className={styles.column}>
          <IndexActionsContainer>
            <ActionView
              id="outgoing-invoice-show-button"
              title={t('proposals.table.actions.show')}
              onClick={this.handleShowClick}
              to={showPath(item.id)}
              label={t('proposals.table.actions.show')}
              visible={canShow}
              dataId="ProposalRow:button-show"
            />
            <ActionEdit
              id="outgoing-invoice-edit-button"
              to={editPath(item.id)}
              title={t('proposals.table.actions.edit')}
              onClick={this.handleEditClick}
              label={t('proposals.table.actions.edit')}
              visible={canEdit}
              dataId="ProposalRow:button-edit"
            />
            <ActionDuplicate
              title={t('proposals.table.actions.duplicate')}
              label={t('proposals.table.actions.duplicate')}
              dataId="ProposalRow:button-duplicate"
              visible={canDuplicate}
              onClick={this.handleActionDuplicate}
            />
            <ActionEditFromProposal
              title={t('proposals.table.actions.transform_into_outgoing_invoice')}
              dataId="ProposalRow:button-transform"
              onClick={this.handleTransformToOutgoingInvoiceClick}
              label={t('proposals.table.actions.transform_into_outgoing_invoice')}
              visible={canTransformIntoOutgoingInvoice}
            />
            <ActionEditFromProposal
              title={t('proposals.table.actions.transform_into_order_confirmation')}
              dataId="transform-to-order-confirmation-button"
              onClick={this.handleTransformToOrderConfirmationClick}
              label={t('proposals.table.actions.transform_into_order_confirmation')}
              visible={canTransformIntoOrderConfirmation}
            />
            <ActionDelete
              title={t('proposals.table.actions.delete')}
              onClick={() => onDelete(item)}
              label={t('proposals.table.actions.delete')}
              visible={canDelete}
              dataId="ProposalRow:button-delete"
            />
          </IndexActionsContainer>
        </td>
      </tr>
    );
  }
}

ProposalRow.propTypes = {
  item: shape({
    city: string,
    companyName: string,
    idNumber: string,
    lastName: string,
    meta: shape({
      actions: shape({
        delete: bool,
        edit: bool,
        show: bool,
        transform: bool,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  showPath: func,
  editPath: func,
  onDelete: func.isRequired,
  onTransformToOutgoingInvoice: func.isRequired,
  onTransformToOrderConfirmation: func.isRequired,
  duplicateProposal: func.isRequired,
};

const mapDisptachToProps = (dispatch) => ({
  duplicateProposal: (proposalId) => dispatch(duplicateProposalAction(proposalId)),
});

export default connect(null, mapDisptachToProps)(ProposalRow);
