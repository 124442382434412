import { JsonApiResponseSingle } from 'json-api-normalizer';

import api from '../index';

export const getOutgoingInvoicesSummary = () => {
  const url = '/me/outgoing_invoices/outgoing_invoices_summary';

  return api.get<JsonApiResponseSingle>(url);
};

type UpdateOutgoingInvoicesSummaryData = {
  kpi_tile_tooltip_dismissed?: boolean;
};

export const updateOutgoingInvoicesSummary = (data: UpdateOutgoingInvoicesSummaryData) => {
  const url = '/me/outgoing_invoices/outgoing_invoices_summary';

  return api.put<JsonApiResponseSingle>(url, data);
};
