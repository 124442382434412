import { t } from 'shared/utils';
import { filledValidator, generateValidation } from 'shared/utils/form-validation';

import { LoginFormData } from './LoginForm';

const passwordFilled = filledValidator('password', {
  type: 'password-not-filled',
  message: t('login.form.errors.passwords_not_filled'),
});

const validatePassword = generateValidation([passwordFilled]);

export const validateLoginForm = (values: LoginFormData) => ({
  password: validatePassword(values),
});
