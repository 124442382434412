import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './FieldsGroup.module.css';

const FieldsGroup = (props) => {
  const { children, className } = props;

  return (
    <div {...props} className={cx(styles.fieldsGroup, className)}>
      {children}
    </div>
  );
};

FieldsGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default FieldsGroup;
