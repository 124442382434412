import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.space[4]}`};
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;
