import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { fetchAppSettings as fetchAppSettingsAction } from 'actions/app-settings';
import { checkToken as checkTokenAction, logoutWithTimeoutNotification } from 'actions/auth';
import { isAuthenticated } from 'routes/accesses';
import { isDevelopment } from 'shared/utils/environment';
import LoadingIcon from 'components/LoadingIcon';

import styles from './Authenticator.module.css';

const Loading = () => (
  <div className={styles.loading}>
    <LoadingIcon />
  </div>
);

class Authenticator extends Component {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    checkToken: PropTypes.func.isRequired,
    children: PropTypes.node,
    fetchAppSettings: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };

  state = {
    lastTick: Date.now(),
  };

  componentDidMount() {
    const { fetchAppSettings, checkToken } = this.props;
    checkToken().then(() => fetchAppSettings());

    window.addEventListener('click', this.onClick);

    if (isDevelopment) return;
    const checkLastTick = () => {
      const { logout } = this.props;
      const { lastTick } = this.state;
      const limit = 120 * 60 * 1000; // 120 minutes
      if (Date.now() - lastTick > limit) {
        logout();
      }
    };
    this.tick = setInterval(checkLastTick, 5000);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClick);
    clearInterval(this.tick);
  }

  onClick = () => {
    this.setState({ lastTick: Date.now() });
  };

  render() {
    const { authenticated, children } = this.props;

    return authenticated ? children : <Loading />;
  }
}

const enhance = compose(
  isAuthenticated,
  connect((s) => ({ authenticated: s.auth.isAuthenticated && !s.auth.isFetching }), {
    checkToken: checkTokenAction,
    fetchAppSettings: fetchAppSettingsAction,
    logout: logoutWithTimeoutNotification,
  })
);

export default enhance(Authenticator);
