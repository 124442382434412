import React from 'react';
import cx from 'classnames';
import { string } from 'prop-types';

import Button from 'components/Button';

import styles from './ActionButton.module.css';

const ActionButton = ({ className = '', ...props }) => (
  <Button centered className={cx(styles.actionButton, className)} {...props} />
);

ActionButton.propTypes = {
  className: string,
};

export default ActionButton;
