import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { number, shape } from 'prop-types';

import {
  DATEV_PREVIEW_CLIENTS,
  DATEV_PREVIEW_INCOMING_INVOICES,
  DATEV_PREVIEW_OUTGOING_INVOICES,
  DATEV_PREVIEW_SUPPLIERS,
} from 'constants/datev';
import { DATEV_PREVIEW_TABLE_COUNT_RESET } from 'constants/datev';
import { t } from 'shared/utils';
import Tabs from 'components/Tabs';

import DatevPreviewManager from './DatevPreviewManager/DatevPreviewManager';
import ExpenseCashTransactions from './ExpenseCashTransactions/ExpenseCashTransactions';
import RevenueCashTransactions from './RevenueCashTransactions/RevenueCashTransactions';

import styles from './DatevPreview.module.css';

const DatevPreview = ({ datevPreviewCounts, isValidDateRange, resetDatevPreviewTableCount }) => {
  const {
    outgoingInvoicesCount,
    incomingInvoicesCount,
    clientsCount,
    suppliersCount,
    revenueCashTransactionsCount,
    expenseCashTransactionsCount,
  } = datevPreviewCounts;

  const sections = [
    {
      heading: `${t(`datev.creator.sections.${DATEV_PREVIEW_OUTGOING_INVOICES}`)} ${
        outgoingInvoicesCount ? `(${outgoingInvoicesCount})` : ''
      }`,
      component: DatevPreviewManager,
      type: DATEV_PREVIEW_OUTGOING_INVOICES,
      dataId: 'Datev:tab-outgoing-invoices',
    },
    {
      heading: `${t(`datev.creator.sections.${DATEV_PREVIEW_INCOMING_INVOICES}`)} ${
        incomingInvoicesCount ? `(${incomingInvoicesCount})` : ''
      }`,
      component: DatevPreviewManager,
      type: DATEV_PREVIEW_INCOMING_INVOICES,
      dataId: 'Datev:tab-incoming-invoices',
    },
    {
      heading: `${t(`datev.creator.sections.${DATEV_PREVIEW_CLIENTS}`)} ${
        clientsCount ? `(${clientsCount})` : ''
      }`,
      component: DatevPreviewManager,
      type: DATEV_PREVIEW_CLIENTS,
      dataId: 'Datev:tab-clients',
    },
    {
      heading: `${t(`datev.creator.sections.${DATEV_PREVIEW_SUPPLIERS}`)} ${
        suppliersCount ? `(${suppliersCount})` : ''
      }`,
      component: DatevPreviewManager,
      type: DATEV_PREVIEW_SUPPLIERS,
      dataId: 'Datev:tab-suppliers',
    },
    {
      heading: `${t('datev.creator.sections.revenue_cash_transactions')} ${
        revenueCashTransactionsCount ? `(${revenueCashTransactionsCount})` : ''
      }`,
      component: RevenueCashTransactions,
      dataId: 'Datev:tab-revenue-cash-transactions',
    },
    {
      heading: `${t('datev.creator.sections.expense_cash_transactions')} ${
        expenseCashTransactionsCount ? `(${expenseCashTransactionsCount})` : ''
      }`,
      component: ExpenseCashTransactions,
      dataId: 'Datev:tab-expense-cash-transaction',
    },
  ];

  useEffect(() => {
    if (!isValidDateRange) resetDatevPreviewTableCount();
  }, [isValidDateRange]);

  return (
    <div data-id="datev-table">
      <Tabs theme={styles} sections={sections} />
    </div>
  );
};

DatevPreview.propTypes = {
  datevPreviewCounts: shape({
    outgoingInvoicesCount: number,
    incomingInvoicesCount: number,
    clientsCount: number,
    suppliersCount: number,
    revenueCashTransactionsCount: number,
    expenseCashTransactionsCount: number,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  datevPreviewCounts: state.datev.datevPreviewCounts,
  isValidDateRange: state.dateRangePicker[0]?.isValid,
});

const mapDispatchToProps = (dispatch) => ({
  resetDatevPreviewTableCount: () => {
    dispatch({ type: DATEV_PREVIEW_TABLE_COUNT_RESET });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DatevPreview);
