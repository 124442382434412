import { AxiosPromise, CancelToken } from 'axios';
import { JsonApiResponse } from 'json-api-normalizer';

import api from '../index';

type GetSuppliersData = {
  page?: number;
  per_page?: number;
  filters?: {
    first_name?: string;
    last_name?: string;
    company_name?: string;
    city?: string;
    id_number?: string;
    full_text_search?: string;
  };
};

export const getSuppliers = (
  data?: GetSuppliersData,
  token?: CancelToken
): AxiosPromise<JsonApiResponse> => {
  const url = '/me/suppliers';

  return api.get(url, {
    params: data,
    cancelToken: token,
  });
};
