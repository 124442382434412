import React, { ComponentType, useCallback, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useSelect } from 'downshift';

import { Button, IconArrowDown, Menu, MenuItem, Placeholder, Wrapper } from './Select.styled';

export type SelectProps<Item> = {
  dataId?: string;
  placeholder?: string;
  disabled?: boolean;
  topAlignment?: boolean;
  itemToString: (item: Item | null) => string;
  option: ComponentType<{ item: Item; isHighlighted: boolean; isSelected: boolean }>;
  options: Item[];
  onChange: (item?: Item | null) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  value?: Item;
  className?: string;
  children?: never;
};

const Select = <Item,>({
  dataId,
  placeholder,
  disabled,
  topAlignment,
  itemToString,
  value,
  onBlur,
  onFocus,
  onChange,
  options,
  className,
  option: Option,
}: SelectProps<Item>) => {
  const [isFocused, setIsFocused] = useState(false);
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect<Item>({
    items: options,
    itemToString,
    onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem),
    selectedItem: value,
  });
  const isActive = isFocused || isOpen;

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useUpdateEffect(() => {
    if (isActive) {
      onFocus && onFocus();
    } else {
      onBlur && onBlur();
    }
  }, [isActive, onFocus, onBlur]);

  useUpdateEffect(() => {
    if (disabled) setIsFocused(false);
  }, [disabled]);

  return (
    <Wrapper data-id={dataId} className={className}>
      <Button
        {...getToggleButtonProps({
          disabled,
          type: 'button',
          onBlur: handleBlur,
          onFocus: handleFocus,
        })}
        $isActive={isActive}
      >
        {selectedItem ? (
          <span>{itemToString(selectedItem)}</span>
        ) : (
          placeholder && <Placeholder $isDisabled={disabled}>{placeholder}</Placeholder>
        )}
        <IconArrowDown $isDisabled={disabled} />
      </Button>
      <Menu {...getMenuProps()} data-id="menu" topAlignment={topAlignment}>
        {isOpen &&
          options.map((item, index) => (
            <MenuItem
              {...getItemProps({
                index,
                item,
              })}
              key={index}
              data-id={`option-${index + 1}`}
              $isHighlighted={highlightedIndex === index}
              $isSelected={selectedItem === item}
            >
              <Option
                item={item}
                isHighlighted={highlightedIndex === index}
                isSelected={selectedItem === item}
              />
            </MenuItem>
          ))}
      </Menu>
    </Wrapper>
  );
};

export default Select;
