import React, { ElementType, ReactNode } from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';

import existIcon from 'images/exist.svg';
import CardView from 'components/CardView';
import Button from 'redesign/components/atoms/Button/Button';

import styles from './EmptyEntryPage.module.css';

interface ImageProps {
  dataId: string;
  className: string;
}

interface EmptyEntryPageProps {
  Image?: ElementType<ImageProps>;
  header: string;
  subheader: string;
  info?: string[];
  dataIdPrefix: string;
  createButtonLabel?: string;
  importButtonLabel?: string;
  onCreateButtonClick?: () => void;
  onImportButtonClick?: () => void;
  contentText?: string;
  className?: string;
  isButtonDisabled?: boolean;
  customClasses?: {
    wrapper?: {};
    image?: {};
    header?: {};
    subheader?: {};
    infoContainer?: {};
    info?: {};
  };
  children?: ReactNode;
}

const EmptyEntryPage = ({
  Image,
  header,
  subheader,
  info,
  dataIdPrefix,
  createButtonLabel,
  importButtonLabel,
  onCreateButtonClick,
  onImportButtonClick,
  contentText = '',
  customClasses = {},
  isButtonDisabled,
  children,
}: EmptyEntryPageProps) => (
  <CardView className={cx(styles.wrapper, customClasses.wrapper)}>
    {Image && (
      <Image
        dataId={`${dataIdPrefix}EmptyEntryPage:image`}
        className={cx(styles.image, customClasses.image)}
      />
    )}
    <div
      data-id={`${dataIdPrefix}EmptyEntryPage:header`}
      className={cx(styles.header, customClasses.header)}
    >
      {header}
    </div>
    <div
      data-id={`${dataIdPrefix}EmptyEntryPage:subheader`}
      className={cx(styles.subheader, customClasses.subheader)}
    >
      {subheader}
    </div>
    <div
      data-id={`${dataIdPrefix}EmptyEntryPage:info-container`}
      className={cx(styles.infoContainer, customClasses.infoContainer)}
    >
      {info?.map((row) => (
        <div key={row} className={cx(styles.info, customClasses.info)}>
          <div className={styles.checkmarkContainer}>
            <InlineSvg src={existIcon} />
          </div>
          <span className={styles.row}>{row}</span>
        </div>
      ))}
    </div>
    {contentText && <div className={styles.contentText}>{contentText}</div>}
    {createButtonLabel && onCreateButtonClick && (
      <Button
        data-id={`${dataIdPrefix}EmptyEntryPage:action-button`}
        className={styles.button}
        onClick={onCreateButtonClick}
        disabled={isButtonDisabled}
      >
        {createButtonLabel}
      </Button>
    )}
    {importButtonLabel && onImportButtonClick && (
      <Button
        data-id={`${dataIdPrefix}EmptyEntryPage:import-button`}
        className={styles.buttonPrimary}
        onClick={onImportButtonClick}
        disabled={isButtonDisabled}
        $variant="outlined"
      >
        {importButtonLabel}
      </Button>
    )}
    {children}
  </CardView>
);

export default EmptyEntryPage;
