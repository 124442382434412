import React, { useCallback } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { t } from 'shared/utils';
import { formatDate } from 'shared/utils';
import { ExpenseInstallment, RevenueInstallment } from 'types/entities/Installment';
import ActionButton from 'components/ActionPanel/ActionButton';

import { Buttons, Content, InstallmentOption, Title, Wrapper } from './Shared.styled';
import { validate } from './validation';

type FormData = {
  installmentId: string;
};

type ChooseInstallmentProps = {
  onCancel: () => void;
  onSuccess: (installment: ExpenseInstallment | RevenueInstallment) => void;
  installments: Array<ExpenseInstallment | RevenueInstallment>;
};

const Form = ({
  onCancel,
  onSuccess,
  handleSubmit,
  invalid,
  installments,
}: ChooseInstallmentProps & InjectedFormProps<FormData, ChooseInstallmentProps>) => {
  const handleOnSubmit = useCallback(
    ({ installmentId }: FormData) => {
      const installment = installments.find(({ id }) => id === installmentId);

      installment && onSuccess(installment);
    },
    [installments, onSuccess]
  );

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Content>
          <Title>
            {t('features.bank_transfers.add_contract_modal.choose_installment.form.title')}
          </Title>
          <div>
            {installments.map(({ id, transactionDate }) => (
              <InstallmentOption key={id}>
                <Field
                  name="installmentId"
                  component="input"
                  type="radio"
                  value={id}
                  data-id={`installment-id-${id}`}
                />
                <span>
                  {t(
                    'features.bank_transfers.add_contract_modal.choose_installment.form.rate_from'
                  )}{' '}
                  {formatDate(transactionDate)}
                </span>
              </InstallmentOption>
            ))}
          </div>
        </Content>
        <Buttons>
          <ActionButton
            appearance="outlined"
            type="button"
            dataId="button-cancel"
            label={t('features.bank_transfers.add_contract_modal.cancel')}
            onClick={onCancel}
          />
          <ActionButton
            type="submit"
            dataId="button-continue"
            label={t('features.bank_transfers.add_contract_modal.choose_installment.form.assign')}
            disabled={invalid}
          />
        </Buttons>
      </form>
    </Wrapper>
  );
};

export default reduxForm<FormData, ChooseInstallmentProps>({
  form: 'addContractModal/chooseIntallment',
  validate,
})(Form);
