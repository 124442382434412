export const NAMESPACE = 'NOTIFICATIONS_SIDEBAR';

export const SHOW_SIDEBAR = `${NAMESPACE}/SHOW`;
export const HIDE_SIDEBAR = `${NAMESPACE}/HIDE`;

export const IGNORE_OUTSIDE_CLICK_CLASS = 'ignore-outside-right-sidebar';

export const SidebarSections = {
  TODOS: 'TODOS',
  MESSAGES: 'MESSAGES',
};
