import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { bool, func, shape } from 'prop-types';

import { fetchBankAccounts as fetchBankAccountsAction } from 'actions/bank-account';
import {
  getIncomingInvoices as getIncomingInvoicesAction,
  resetIncomingInvoices,
} from 'actions/incoming-invoices';
import { fetchOverview as fetchOverviewAction } from 'actions/monthly-overview/monthly-overview';
import { resetOutgoingInvoices } from 'actions/outgoing-invoice';
import { fetchQuarterlySummary as fetchQuarterlySummaryAction } from 'actions/quarterly-summary';
import { CATEGORY_DASHBOARD } from 'constants/piwik';
import { liquidityStatePreviewEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import withViewport from 'shared/hoc/withViewport';
import { useAppDispatch } from 'shared/hooks/app';
import { piwikHelpers } from 'shared/utils/piwik';
import { getDashboardSettings, getIsLoaded } from 'store/slices/dashboard/selectors';
import { fetchDashboard as fetchDashboardAction } from 'store/slices/dashboard/thunks';
import { updateDashboard } from 'store/slices/dashboard/thunks';
import Loading from 'components/Loading';
import RouteLeavingGuard from 'redesign/components/organisms/RouteLeavingGuard/RouteLeavingGuard';
import BankAccountsTile from 'features/homepage/BankAccountsTile/BankAccountsTile';
import BankTransfersTile from 'features/homepage/BankTransfersTile/BankTransfersTile';
import CustomisationWidgets from 'features/homepage/CustomisationWidgets/CustomisationWidgets';
import KPITile from 'features/homepage/KPITile/KPITile';
import OnboardingTile from 'features/homepage/OnboardingTile/OnboardingTile';
import Quicklinks from 'features/homepage/Quicklinks/Quicklinks';
import WelcomeTile from 'features/homepage/WelcomeTile/WelcomeTile';

import DiscardChangesModal from './components/DiscardChangesModal/DiscardChangesModal';
import WidgetWrapper from './components/WidgetWrapper/WidgetWrapper';
import LiquidityChart from './LiquidityChart/LiquidityChart';
import { OBSTooltip } from './OBSTooltip/OBSTooltip';
import Overview from './Overview';

import styles from './Dashboard.module.css';

const Dashboard = (props) => {
  const { liquidityStatePreviewEnabled, isSmUp, dashboardSettings, isLoaded } = props;
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [tempWidgetStates, setTempWidgetStates] = useState(dashboardSettings);
  const shouldShowLiquidityChart = liquidityStatePreviewEnabled && tempWidgetStates?.liquiChart;

  const handleWidgetStates = (name) => {
    setIsDirty(true);
    setTempWidgetStates({
      ...tempWidgetStates,
      [name]: !tempWidgetStates[name],
    });

    piwikHelpers.trackEvent(
      CATEGORY_DASHBOARD,
      `widget-${[name]}_${tempWidgetStates[name] ? 'hide' : 'show'}`
    );
  };

  const resetDashboard = () => {
    setTempWidgetStates(dashboardSettings);
    setEditMode(false);
    setIsDirty(false);
  };

  const saveDashboard = () => {
    setEditMode(false);
    setIsDirty(false);

    dispatch(
      updateDashboard({
        kpi: tempWidgetStates.kpi,
        liqui_chart: tempWidgetStates.liquiChart,
        bank_accounts: tempWidgetStates.bankAccounts,
        bank_transfers: tempWidgetStates.bankTransfers,
        monthly_overview: tempWidgetStates.monthlyOverview,
      })
    );
  };

  useEffect(() => {
    const {
      getIncomingInvoices,
      fetchQuarterlySummary,
      fetchBankAccounts,
      fetchOverview,
      fetchDashboard,
    } = props;

    fetchDashboard();
    getIncomingInvoices();
    fetchOverview(true, 5);
    fetchQuarterlySummary();
    fetchBankAccounts();
    return () => {
      const { areIncomingInvoicesEmpty, areOutgoingInvoicesEmpty } = props;

      if (!areIncomingInvoicesEmpty) props.resetIncomingInvoices();
      if (!areOutgoingInvoicesEmpty) props.resetOutgoingInvoices();
    };
  }, []);

  useEffect(() => {
    editMode && window.scrollBy({ left: 0, top: 700, behavior: 'smooth' });
  }, [editMode]);

  useEffect(() => {
    setTempWidgetStates(dashboardSettings);
  }, [dashboardSettings]);

  return (
    <div className={cx(styles.main, { [styles.mainMenuOpen]: props.isOpen })}>
      <div className={styles.section}>
        <OnboardingTile className={styles.onboardingTile} />
        <WelcomeTile className={styles.welcomeTile} />
      </div>
      <div className={styles.section}>
        <Quicklinks />
      </div>

      {isLoaded ? (
        <>
          <WidgetWrapper
            widgetId="kpi"
            removeAction={() => handleWidgetStates('kpi')}
            isEditMode={editMode}
            isVisible={tempWidgetStates?.kpi}
            className={styles.section}
          >
            <KPITile className={styles.kpiTile} />
          </WidgetWrapper>

          <div className={styles.section}>
            <WidgetWrapper
              widgetId="monthlyOverview"
              removeAction={() => handleWidgetStates('monthlyOverview')}
              isEditMode={editMode}
              isVisible={tempWidgetStates?.monthlyOverview}
              className={styles.row}
            >
              <Overview className={styles.tile} />
            </WidgetWrapper>

            {!isSmUp && (
              <div className={styles.col}>
                <OBSTooltip showOnDashboard={!isSmUp} />
              </div>
            )}

            <WidgetWrapper
              widgetId="liquiChart"
              removeAction={() => handleWidgetStates('liquiChart')}
              isEditMode={editMode}
              isVisible={shouldShowLiquidityChart}
              className={styles.row}
            >
              <LiquidityChart className={styles.tile} />
            </WidgetWrapper>
          </div>
          <div className={styles.section}>
            <div className={styles.row}>
              <WidgetWrapper
                widgetId="bankAccounts"
                removeAction={() => handleWidgetStates('bankAccounts')}
                isEditMode={editMode}
                isVisible={tempWidgetStates?.bankAccounts}
                className={styles.colHalf}
              >
                <BankAccountsTile className={styles.tile} />
              </WidgetWrapper>

              <WidgetWrapper
                widgetId="bankTransfers"
                removeAction={() => handleWidgetStates('bankTransfers')}
                isEditMode={editMode}
                isVisible={tempWidgetStates?.bankTransfers}
                className={styles.colHalf}
              >
                <BankTransfersTile className={styles.tile} />
              </WidgetWrapper>
            </div>
          </div>
          <div className={styles.section}>
            <CustomisationWidgets
              editMode={editMode}
              setEditMode={setEditMode}
              widgetsState={tempWidgetStates}
              resetDashboard={resetDashboard}
              saveDashboard={saveDashboard}
              handleWidgetStates={handleWidgetStates}
            />
          </div>
        </>
      ) : (
        <Loading />
      )}
      <RouteLeavingGuard blocked={isDirty} modal={DiscardChangesModal} exclude={[paths.home]} />
    </div>
  );
};

Dashboard.propTypes = {
  getIncomingInvoices: func.isRequired,
  fetchOverview: func.isRequired,
  resetIncomingInvoices: func.isRequired,
  resetOutgoingInvoices: func.isRequired,
  fetchQuarterlySummary: func.isRequired,
  isOpen: bool,
  isSmUp: bool,
  liquidityStatePreviewEnabled: bool,
  fetchBankAccounts: func,
  fetchDashboard: func,
  areIncomingInvoicesEmpty: bool.isRequired,
  areOutgoingInvoicesEmpty: bool.isRequired,
  isFromVrso: bool,
  dashboardSettings: shape({
    kpi: bool,
    monthlyOverview: bool,
    liquiChart: bool,
    bankAccounts: bool,
    bankTransfers: bool,
  }),
  isLoaded: bool,
};

const mapStateToProps = (state) => ({
  isOpen: state.menu.isOpen,
  liquidityStatePreviewEnabled: liquidityStatePreviewEnabledHelper(state),
  areIncomingInvoicesEmpty: isEmpty(state.incomingInvoices.data),
  areOutgoingInvoicesEmpty: isEmpty(state.outgoingInvoices.data),
  dashboardSettings: getDashboardSettings(state),
  isLoaded: getIsLoaded(state),
});

const mapDispatchToProps = {
  fetchOverview: fetchOverviewAction,
  fetchQuarterlySummary: fetchQuarterlySummaryAction,
  resetIncomingInvoices,
  resetOutgoingInvoices,
  getIncomingInvoices: getIncomingInvoicesAction,
  fetchBankAccounts: fetchBankAccountsAction,
  fetchDashboard: fetchDashboardAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withViewport(Dashboard));
