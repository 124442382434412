import { get } from 'lodash';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  CHANGE_ERROR_DATA,
  GET_LOGIN_INFO_SUCCESS,
  INVALIDATE_TOKEN,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  SET_AFTER_LOGIN_ROUTE,
  VALIDATE_SUCCESS,
} from 'constants/auth';
import { CHANGE_PASSWORD_SUCCESS, FETCH_SUCCESS as FETCH_PROFILE_SUCCESS } from 'constants/profile';
import { camelizeKeysDeep } from 'shared/utils/entity-mapper';

export const getLoginProcessStatus = (state) => get(state, 'isFetching');

export const defaultState = {
  error: false,
  isFetching: false,
  token: null,
  isAuthenticated: false,
  accountLocked: false,
  lastAttempt: false,
  roles: [],
  rolesFetched: false,
  forcePasswordChange: false,
  afterLoginRoute: null,
  loginInfo: null,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case REGISTER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        error: false,
        isFetching: false,
        isAuthenticated: true,
        token: get(action, 'response.access_token'),
        forcePasswordChange: get(action, 'response.force_password_change'),
      };
    case LOGIN_FAILURE:
    case REGISTER_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
        isAuthenticated: false,
        accountLocked: action.errorMessage === 'account_locked',
      };
    case CHANGE_ERROR_DATA:
      return {
        ...state,
        lastAttempt: action.payload.last_attempt || false,
      };
    case INVALIDATE_TOKEN:
    case LOGOUT_SUCCESS:
      return defaultState;
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, forcePasswordChange: false };
    case FETCH_PROFILE_SUCCESS:
    case VALIDATE_SUCCESS:
      return {
        ...state,
        roles: get(action, 'response.data.attributes.roles', []),
        rolesFetched: true,
      };
    case SET_AFTER_LOGIN_ROUTE:
      return { ...state, afterLoginRoute: action.payload.afterLoginRoute };
    case GET_LOGIN_INFO_SUCCESS:
      return { ...state, loginInfo: camelizeKeysDeep(action.response) || null };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'auth',
  version: 1,
  blacklist: ['error'],
  storage,
};

export default persistReducer(persistConfig, reducer);
