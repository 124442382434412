import 'react-dates-initializer';

import React from 'react';
import ReactDOM from 'react-dom';

import App from 'containers/App';

import 'slick-carousel/slick/slick.css';
import 'react-flexbox-grid/dist/react-flexbox-grid.css';

const rootElement = document.getElementById('pbw-react-root');

ReactDOM.render(<App />, rootElement);
