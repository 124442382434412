export const CREATE_REQUEST = 'CONTRACT/CREATE_REQUEST';
export const CREATE_SUCCESS = 'CONTRACT/CREATE_SUCCESS';
export const CREATE_FAILURE = 'CONTRACT/CREATE_FAILURE';

export const UPDATE_REQUEST = 'CONTRACT/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'CONTRACT/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'CONTRACT/UPDATE_FAILURE';

export const DELETE_REQUEST = 'CONTRACT/DELETE_REQUEST';
export const DELETE_SUCCESS = 'CONTRACT/DELETE_SUCCESS';
export const DELETE_FAILURE = 'CONTRACT/DELETE_FAILURE';

export const FETCH_CONTRACT_REQUEST = 'CONTRACT/FETCH_REQUEST';
export const FETCH_CONTRACT_SUCCESS = 'CONTRACT/FETCH_SUCCESS';
export const FETCH_CONTRACT_FAILURE = 'CONTRACT/FETCH_FAILURE';

export const NEW_CONTRACT_TRANSIENT_ID = 'CONTRACTS/CURRENT_TRANSIENT_ID';
