import React from 'react';

const IncomingInvoice = ({ dataId = '', className = '' }) => (
  <svg width={318} height={160} data-id={dataId} className={className}>
    <defs>
      <filter
        x="-9.7%"
        y="-7%"
        width="119.3%"
        height="119.7%"
        filterUnits="objectBoundingBox"
        id="prefix__a"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-9.7%"
        y="-7%"
        width="119.3%"
        height="119.7%"
        filterUnits="objectBoundingBox"
        id="prefix__c"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-29.7%"
        y="-19%"
        width="159.4%"
        height="145%"
        filterUnits="objectBoundingBox"
        id="prefix__e"
      >
        <feOffset dy={4} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={8} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <ellipse id="prefix__b" cx={36.219} cy={89.5} rx={36.219} ry={35.5} />
      <ellipse id="prefix__d" cx={259.219} cy={35.5} rx={36.219} ry={35.5} />
      <path id="prefix__f" d="M0 0h87.615v115.493H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M97 53c28.697 9.811-2.983-10.73 19-20s64.654 11 85 11 36.971-16.555 54.6-2.847C273.228 54.861 305.789 46.468 281 82c-24.789 35.532-3.026 19.174-44.4 54.153C195.226 171.132 144 133 116 126s-50.373 2.687-77-11c-26.627-13.687-28.654-5.924-37.256-18.09C-6.86 84.742 18.864 61.84 24.6 48.152 30.335 34.466 68.303 43.189 97 53z"
        fill="#F3F3F3"
      />
      <path
        d="M58.829 115c-21.289-33.333-23.18-57.667-5.677-73 26.255-23 61.026-2 56.769 30-4.258 32-51.802-34 28.384-12M196 91c.667 26.667 9.333 36.333 26 29 25-11 3-44 29-52"
        stroke="#F60"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="4,4"
      />
      <g transform="translate(18 15)">
        <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
        <use fill="#FFF" xlinkHref="#prefix__b" />
      </g>
      <g transform="translate(18 15)">
        <use fill="#000" filter="url(#prefix__c)" xlinkHref="#prefix__d" />
        <use fill="#FFF" xlinkHref="#prefix__d" />
      </g>
      <g strokeLinecap="round" strokeLinejoin="round">
        <path stroke="#FFF" strokeWidth={4} d="M40 101.52L53.98 87.5 68 101.48h0" />
        <path stroke="#FFF" strokeWidth={4} d="M43 86.5h17.85L65 90.53v22.97H43z" />
        <path stroke="#FFF" strokeWidth={4} d="M65 91.5h-5v-5" />
        <path stroke="#FFF" strokeWidth={4} d="M43 86.5h17.85L65 90.53v22.97H43z" />
        <path stroke="#FFF" strokeWidth={4} d="M65 91.5h-5v-5" />
        <path stroke="#FFF" strokeWidth={4} d="M43 86.5h17.85L65 90.53v22.97H43z" />
        <path
          stroke="#FFF"
          strokeWidth={4}
          d="M65 91.5h-5v-5m-13 10h14m-14 4h14m7 1l-14 12-14-11.98v19.98h28z"
        />
        <path stroke="#FFF" strokeWidth={4} d="M68 121.47L54 109.5l-14 11.99z" />
        <path
          stroke="#0066B3"
          strokeWidth={2}
          fill="#FFF"
          fillRule="nonzero"
          d="M40 101.52L53.98 87.5 68 101.48h0"
        />
        <path stroke="#FFF" strokeWidth={4} d="M43 86.5h17.85L65 90.53v22.97H43z" />
        <path stroke="#FFF" strokeWidth={4} d="M65 91.5h-5v-5" />
        <path
          stroke="#F60"
          strokeWidth={2}
          fill="#FFF"
          fillRule="nonzero"
          d="M43 86.5h17.85L65 90.53v22.97H43z"
        />
        <path stroke="#F60" strokeWidth={2} fill="#F60" fillRule="nonzero" d="M65 91.5h-5v-5" />
        <path stroke="#F60" strokeWidth={2} d="M47 96.5h14m-14 4h14" />
        <path
          stroke="#0066B3"
          strokeWidth={2}
          fill="#FFF"
          fillRule="nonzero"
          d="M68 101.5l-14 12-14-11.98v19.98h28z"
        />
        <path
          stroke="#0066B3"
          strokeWidth={2}
          fill="#FFF"
          fillRule="nonzero"
          d="M68 121.47L54 109.5l-14 11.99z"
        />
      </g>
      <g transform="translate(121 25)">
        <use fill="#000" filter="url(#prefix__e)" xlinkHref="#prefix__f" />
        <use fill="#FFF" xlinkHref="#prefix__f" />
      </g>
      <path
        d="M134.318 48.92h21.739c.757 0 1.37-.6 1.37-1.34 0-.74-.613-1.34-1.37-1.34h-21.74c-.756 0-1.37.6-1.37 1.34 0 .74.614 1.34 1.37 1.34zm-.004 29.48l57.545.168c.756.003 1.372-.596 1.374-1.336.002-.74-.61-1.341-1.366-1.344l-57.545-.167c-.757-.002-1.372.596-1.374 1.336-.003.74.609 1.342 1.366 1.344zm.403-24.12H153.1c.756 0 1.37-.6 1.37-1.34 0-.74-.614-1.34-1.37-1.34h-18.383c-.756 0-1.37.6-1.37 1.34 0 .74.614 1.34 1.37 1.34zm-.005 29.481l43.444.168c.757.002 1.373-.595 1.376-1.335.003-.74-.608-1.343-1.365-1.345l-43.444-.168c-.757-.003-1.373.595-1.376 1.335-.003.74.608 1.342 1.365 1.345zm-.394-24.12h21.739c.757 0 1.37-.6 1.37-1.34 0-.74-.613-1.34-1.37-1.34h-21.74c-.756 0-1.37.6-1.37 1.34 0 .74.614 1.34 1.37 1.34zm-.004 29.48l60.285.168c.757.002 1.372-.596 1.374-1.337.002-.74-.61-1.341-1.366-1.343l-60.285-.168c-.757-.002-1.372.596-1.374 1.336-.003.74.609 1.342 1.366 1.344zm.689 34.686h15.071c.378 0 .685-.378.685-.844 0-.466-.307-.843-.685-.843h-15.071c-.379 0-.685.377-.685.843 0 .466.306.844.685.844zm26.717 0h15.071c.379 0 .685-.378.685-.844 0-.466-.306-.843-.685-.843H161.72c-.378 0-.685.377-.685.843 0 .466.307.844.685.844zm-26.717 4.02h10.96c.379 0 .686-.377.686-.843s-.307-.844-.685-.844h-10.961c-.379 0-.685.378-.685.844s.306.843.685.843zm26.717 0h10.961c.378 0 .685-.377.685-.843s-.307-.844-.685-.844H161.72c-.378 0-.685.378-.685.844s.307.843.685.843zm-26.717 4.02h15.071c.378 0 .685-.377.685-.843 0-.466-.307-.844-.685-.844h-15.071c-.379 0-.685.378-.685.844 0 .466.306.843.685.843zm26.717 0h15.071c.379 0 .685-.377.685-.843 0-.466-.306-.844-.685-.844H161.72c-.378 0-.685.378-.685.844 0 .466.307.843.685.843z"
        fill="#979797"
        fillRule="nonzero"
      />
      <g fill="#2382C8">
        <path d="M195.602 103.395h-10.985c-.674 0-1.224-.56-1.224-1.256 0-.695.548-1.258 1.224-1.258h10.985c.677 0 1.224.563 1.224 1.258 0 .695-.548 1.256-1.225 1.256m.001 3.735h-10.985c-.674 0-1.224-.563-1.224-1.258 0-.691.548-1.255 1.224-1.255h10.985c.677 0 1.224.56 1.224 1.257 0 .697-.548 1.26-1.225 1.26" />
        <path d="M194.254 112.482c-4.815 0-8.733-3.84-8.733-8.56 0-4.722 3.918-8.564 8.733-8.564a8.86 8.86 0 013.34.652c.637.26.94.978.675 1.603-.261.62-.99.917-1.627.657a6.35 6.35 0 00-2.386-.465c-3.44 0-6.237 2.744-6.237 6.117 0 3.373 2.795 6.115 6.237 6.115.822 0 1.626-.155 2.386-.465a1.256 1.256 0 011.629.664 1.21 1.21 0 01-.674 1.598 8.816 8.816 0 01-3.34.653" />
      </g>
      <g fillRule="nonzero">
        <path
          d="M278.735 50.77c-1.149.024-2.107.67-2.69 1.608.794-.59 1.692-1.129 2.69-1.608"
          fill="#F26F21"
        />
        <path
          d="M275.89 47.442c0 .831-1.544 2.04-3.963 2.04-2.417 0-3.96-1.209-3.96-2.04 0-.01-.004-.02-.004-.03l-.002-2.023c1.014.687 2.395 1.1 3.966 1.1 1.573 0 2.956-.413 3.97-1.101l-.001 2.02c-.001.012-.006.022-.006.034m-3.963-10.948c2.395 0 3.926 1.202 3.957 2.047-.031.845-1.562 2.047-3.957 2.047-2.393 0-3.923-1.202-3.954-2.047.03-.845 1.561-2.047 3.954-2.047m-3.968 4.463c1.013.698 2.396 1.117 3.968 1.117 1.575 0 2.959-.42 3.972-1.118v1.977c-.001.018-.01.033-.01.052 0 .842-1.543 2.066-3.962 2.066-2.417 0-3.96-1.224-3.96-2.066 0-.017-.007-.03-.007-.045v-1.983m9.687-2.699v-.006c0-.01-.005-.02-.005-.03-.062-2.033-2.495-3.557-5.715-3.557-3.218 0-5.65 1.524-5.71 3.558 0 .01-.007.019-.007.029v.013c0 .02-.01.037-.01.058l.01 17.384c0 2.05 2.512 3.657 5.717 3.657 1.163 0 2.231-.214 3.125-.582l-.003-2.033c-.688.437-1.758.786-3.122.786-2.417 0-3.96-1.083-3.96-1.828v-1.363c1.012.606 2.391.97 3.96.97 1.563 0 2.937-.36 3.949-.962-.519-.434-.822-.955-.822-1.516 0-.045.008-.088.013-.132-.688.434-1.765.782-3.14.782-2.417 0-3.96-1.069-3.96-1.805l-.002-.013c0-.568 0-1.173-.002-1.802 1.014.607 2.395.973 3.964.973 1.572 0 2.953-.366 3.966-.973v1.44c.447-.368 1.051-.67 1.757-.878l.01-12.105c0-.023-.012-.043-.013-.065"
          fill="#F26F21"
        />
        <path
          d="M279.893 62.93c-2.418 0-3.96-1.065-3.96-1.798l-.002-1.424c1.013.607 2.393.972 3.962.972 1.57 0 2.95-.366 3.964-.973l-.002 1.428c0 .732-1.543 1.795-3.962 1.795m-3.96-10.094c0-.736 1.542-1.805 3.96-1.805 2.419 0 3.962 1.069 3.962 1.805 0 .737-1.543 1.805-3.962 1.805-2.418 0-3.96-1.068-3.96-1.805m7.928 4.179c0 .01-.006.02-.006.03 0 .737-1.543 1.806-3.962 1.806-2.418 0-3.96-1.07-3.96-1.805 0-.01-.006-.019-.006-.029l-.002-1.522c1.013.61 2.395.975 3.968.975s2.956-.367 3.97-.976l-.002 1.52m1.751-4.178c0-2.072-2.459-3.634-5.72-3.634-3.259 0-5.717 1.562-5.717 3.634 0 .015.004.029.004.044-.002.025-.014.047-.014.072l.01 8.18c0 2.067 2.458 3.626 5.718 3.626s5.72-1.558 5.72-3.622l.01-8.184c0-.028-.012-.051-.013-.078l.002-.038"
          fill="#0066B2"
        />
      </g>
      <g>
        <path
          stroke="#4D4E4C"
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M249.178 123.876l1.697-1.697 6.788 6.789-1.697 1.697z"
        />
        <path fill="#4D4E4C" d="M250.962 122.066l.54.618v3.6l-2.4-2.394z" />
        <path
          d="M241.986 122.948a5.628 5.628 0 117.962-7.956 5.628 5.628 0 01-7.962 7.956zm2.748-7.434a3.672 3.672 0 013.828.858"
          stroke="#4D4E4C"
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g>
        <g transform="translate(93.25 .75)">
          <path
            d="M13.714.013L2.286 0C1.029 0 0 1.164 0 2.586v23.273c0 1.422 1.029 2.585 2.286 2.585h11.428c1.257 0 2.286-1.163 2.286-2.585V2.586C16 1.164 14.971.013 13.714.013zm.404 24.444H1.882v-21h12.236v21z"
            fill="#0066B3"
            fillRule="nonzero"
          />
          <circle fill="#FFF" cx={7.75} cy={26.25} r={1} />
        </g>
      </g>
    </g>
  </svg>
);

export default IncomingInvoice;
