import React from 'react';
import { bool, string } from 'prop-types';

import { PREMIUM_UID } from 'constants/payment-plans';

import PremiumDescription from './components/PremiumDescription';

import styles from './Description.module.css';

const Description = ({ plan, vrMainBankAccount }) => (
  <div className={styles.container}>
    {(() => {
      switch (plan) {
        case PREMIUM_UID:
          return <PremiumDescription vrMainBankAccount={vrMainBankAccount} />;
        default:
          return null;
      }
    })()}
  </div>
);

Description.propTypes = {
  plan: string,
  vrMainBankAccount: bool,
};

export default Description;
