import { getHours } from 'date-fns';

import { CATEGORY_DASHBOARD } from 'constants/piwik';
import { piwikHelpers } from 'shared/utils/piwik';

export const trackEvent = piwikHelpers.trackEventCurried(CATEGORY_DASHBOARD);

export const getTimeOfDay = (date: Date) => {
  const hours = getHours(date);

  return hours >= 5 && hours < 10 ? 'morning' : hours >= 10 && hours < 17 ? 'day' : 'evening';
};
