// TODO remove or refactore this - it is only used for incoming and outgoing invoice
import * as INCOMING_INVOICE_ACTIONS from 'constants/incoming-invoice';
import * as OUTGOING_INVOICE_ACTIONS from 'constants/outgoing-invoice';

const getTypes = (type, actions) => {
  const arr = [];
  Object.keys(actions).reduce((sum, key) => {
    if (key.endsWith(type)) {
      arr.push(actions[key]);
    }
    return sum;
  }, {});

  return arr;
};

const REQUEST_SUFFIX = '_REQUEST';
const SUCCESS_SUFFIX = '_SUCCESS';
const ERROR_SUFFIX = '_ERROR';
const FAILURE_SUFFIX = '_FAILURE';

const INCOMING_INVOICE_REQUESTS = getTypes(REQUEST_SUFFIX, INCOMING_INVOICE_ACTIONS);
const INCOMING_INVOICE_SUCCESS = getTypes(SUCCESS_SUFFIX, INCOMING_INVOICE_ACTIONS);
const INCOMING_INVOICE_ERROR = getTypes(ERROR_SUFFIX, INCOMING_INVOICE_ACTIONS);
const INCOMING_INVOICE_FAILURE = getTypes(FAILURE_SUFFIX, INCOMING_INVOICE_ACTIONS);

const OUTGOING_INVOICE_REQUESTS = getTypes(REQUEST_SUFFIX, OUTGOING_INVOICE_ACTIONS);
const OUTGOING_INVOICE_SUCCESS = getTypes(SUCCESS_SUFFIX, OUTGOING_INVOICE_ACTIONS);
const OUTGOING_INVOICE_ERROR = getTypes(ERROR_SUFFIX, OUTGOING_INVOICE_ACTIONS);
const OUTGOING_INVOICE_FAILURE = getTypes(FAILURE_SUFFIX, OUTGOING_INVOICE_ACTIONS);

const defaultState = {
  error: false,
  isFetching: false,
};

export default (state = defaultState, action) => {
  switch (!!action.type) {
    case INCOMING_INVOICE_REQUESTS.includes(action.type):
    case OUTGOING_INVOICE_REQUESTS.includes(action.type):
      return { error: false, isFetching: true };

    case INCOMING_INVOICE_SUCCESS.includes(action.type):
    case OUTGOING_INVOICE_SUCCESS.includes(action.type):
      return { error: false, isFetching: false };

    case INCOMING_INVOICE_FAILURE.includes(action.type):
    case OUTGOING_INVOICE_FAILURE.includes(action.type):
    case INCOMING_INVOICE_ERROR.includes(action.type):
    case OUTGOING_INVOICE_ERROR.includes(action.type):
      return { error: true, isFetching: false };

    default:
      return state;
  }
};
