import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

const TableRow = ({ children, dataId = '', className = '', ...rest }) => (
  <tr className={className} data-id={dataId}>
    {Children.map(children, (child, index) => cloneElement(child, { ...rest, index }))}
  </tr>
);

TableRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataId: PropTypes.string,
};

export default TableRow;
