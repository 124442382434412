export const SET_PIWIK_IFRAME_SRC = 'PIWIK/SET_IFRAME_SRC';
export default SET_PIWIK_IFRAME_SRC;
export const PIWIK_PUSH_EVENT = 'trackEvent';
export const PIWIK_PUSH_GOAL = 'trackGoal';
export const PIWIK_PUSH_INTERACTION = 'trackContentInteractionNode';

/**
 * Menu
 */

export const PIWIK_ACTION_MENU_CATEGORY = '';
export const PIWIK_ACTION_CLICK_MENU_ITEM = 'settings_navigationpoint_active';
export const PIWIK_ACTION_CLICK_SUBMENU_ITEM = 'settings_subnavigationpoint_active';
export const PIWIK_ACTION_CLICK_SUBMENU_SUBPOINT_ITEM =
  'settings_subnavigationpoint_subpoint_active';
export const PIWIK_ACTION_LOGOUT = 'usersettings_logout_pressed';

export const PIWIK_ACTION_MOBILE_CLICK_GO_BACK = 'mobile_settings_subnavigationpoint_back_pressed';
export const PIWIK_ACTION_MOBILE_CLICK_MENU_ITEM = 'mobile_settings_navigationpoint_pressed';
export const PIWIK_ACTION_MOBILE_CLICK_SUBMENU_ITEM = 'mobile_settings_subnavigationpoint_pressed';
export const PIWIK_ACTION_MOBILE_CLICK_SUBMENU_SUBPOINT_ITEM =
  'mobile_settings_subnavigationpoint_subpoint_pressed';

/**
 * Profile
 */
export const PIWIK_CATEGORY_PROFILE = 'Profil';

export const PIWIK_ACTION_SUBMIT_NUMBER_RANGES = 'Nummernkreise gespeichert';
export const PIWIK_ACTION_TIPS = 'Abruf Tipps und Produktempfehlungen';
export const PIWIK_ACTION_BANK_ACCOUNT_ADD = 'Bankverbindung hinzufuegen: gestartet';
export const PIWIK_ACTION_BANK_ACCOUNT_DELETE = 'Bankverbindung geloescht';
export const PIWIK_ACTION_BANK_ACCOUNT_EDIT = 'Bankverbindung aendern';
export const PIWIK_ACTION_BANK_SELECTED = 'Bankverbindung hinzufuegen: Bank ausgewaehlt';
export const PIWIK_ACTION_BANK_DETAILS_ENTERED = 'Bankverbindung hinzufuegen: Bankdaten eingegeben';
export const PIWIK_ACTION_BANK_STORE_DATA_CHECKED =
  'Bankverbindung hinzufuegen: Bankdaten speichern';
export const PIWIK_ACTION_BANK_DATA_SAVED = 'Bankverbindung hinzufuegen: Bankdaten gespeichert';
export const PIWIK_GOAL_SAVE_NUMBER_RANGE = 11;
export const PIWIK_GOAL_ADD_BANK_ACCOUNT = 13;

/**
 * RevenuesBasic
 */

export const CATEGORY_REVENUES = 'revenues';
export const PIWIK_CATEGORY_REVENUE = 'revenue';

export const ACTION_OPEN_MAIL_EDITOR = 'open-mail-editor';
export const ACTION_SEND_VIA_MAIL = 'send-via-mail';
export const ACTION_DOWNLOAD_PDF = 'download-pdf';
export const ACTION_DOWNLOAD_XML = 'download-xml';
export const ACTION_EMAIL_ERROR = 'email-error';

export const PIWIK_ACTION_TAB_REVENUE_CHART = 'revenue_invoices_chart-view';
export const PIWIK_ACTION_TAB_REVENUE_TABLE = 'revenue_ionvoices_table-view';
export const PIWIK_ACTION_NEW_OUTGOING_INVOICE = 'revenue_invoices_new';
export const PIWIK_ACTION_SHOW_OUTGOING_INVOICE = 'revenue_invoices_view-mode';
export const PIWIK_ACTION_EDIT_OUTGOING_INVOICE = 'revenue_invoices_edit-mode';
export const PIWIK_ACTION_CORRECT_OUTGOING_INVOICE = 'revenue_invoices_correction_start';
export const PIWIK_ACTION_SAVE_CORRECT_OUTGOING_INVOICE = 'revenue_invoices_correction_save';
export const PIWIK_ACTION_REMINDER = 'revenue_invoices_reminder_new';
export const PIWIK_ACTION_SAVE_PDF_REMINDER = 'revenue_invoices_reminder-pdf_save';
export const PIWIK_ACTION_SEND_REMINDER = 'revenue_invoices_reminder_send';
export const PIWIK_ACTION_CANCELL_REMINDER = 'revenue_invoices_reminder_cancel';
export const PIWIK_ACTION_CANCELL_INVOICE = 'revenue_invoices_cancellation';
export const PIWIK_ACTION_SAVE_CANCELL_INVOICE = 'revenue_invoices_cancellation_save';
export const PIWIK_ACTION_DELETE_INVOICE = 'revenue_invoices_delete';
export const PIWIK_ACTION_REVENUE_CREATE_CUSTOMER = 'revenue_invoices_customer_create';
export const PIWIK_ACTION_REVENUE_ABORT = 'revenue_invoices_abort';
export const PIWIK_ACTION_REVENUE_ADD_CASH_PAYMENT = 'revenue_invoices_cash-payment';
export const PIWIK_ACTION_REVENUE_PREVIEW = 'revenue_invoices_preview_open';
export const PIWIK_ACTION_REVENUE_AS_DRAFT = 'revenue_invoices_draft_save';
export const PIWIK_ACTION_REVENUE_PDF = 'revenue_invoices_pdf_download';
export const PIWIK_ACTION_REVENUE_SEND_EMAIL = 'revenue_invoices_email_send';
export const PIWIK_ACTION_REVENUE_CHANGE_DUE_DATE = 'revenue_invoices_due-date_change';
export const PIWIK_ACTION_EDIT_REVENUE_SAVE = 'revenue_invoices_edit-mode_save';
export const PIWIK_ACTION_OPEN_BANK_TRANSFERS_DROPDOWN = 'revenue_invoices_bank-transfer_select';
export const PIWIK_ACTION_ASSIGN_BANK_TRANSFER = 'revenue_invoices_bank-transfer_assign'; // on create or edit invoice
export const PIWIK_ACTION_REMOVE_BANK_TRANSFER = 'revenue_invoices_bank-transfer_remove'; // on create or edit invoice
export const PIWIK_ACTION_IMPORT_OUTGOING_INVOICE = 'revenue_invoices_existing-invoice_import';
export const PIWIK_NAME_CREATE_CUSTOMER_SUCCESS = 'revenue_invoices_customer_create-success'; //to be reviewed
export const PIWIK_NAME_CREATE_CUSTOMER_ERROR = 'revenue_invoices_customer_create-error'; // to be reviewed
export const PIWIK_CONVERSION_NEW_CUSTOMER_REVENUE = 2;
export const PIWIK_CONVERSION_ASSIGN_BANK_TRANSFER_REVENUE = 3;
export const PIWIK_CONVERSION_CREATE_REVENUE = 4; // Create outgoing invoice by email or pdf
export const PIWIK_CONVERSION_ASSIGN_CREATE_REVENUE_REMINDER = 14; // Create outgoing invoice reminder by email or pdf
export const PIWIK_CONVERSION_TAB_CHART = 7;

/**
 * Product-catalog-groups (piwik middleware)
 */

export const CATEGORY_PRODUCT_CATALOG_GROUPS = 'Leistungskatalog/Leistungsgruppen';
export const ACTION_NEW_PRODUCT_CATALOG_GROUP = 'neue Leistungsgruppe hinzufuegen';
export const ACTION_EDIT_PRODUCT_CATALOG_GROUP = 'bearbeiten';
export const ACTION_DELETE_PRODUCT_CATALOG_GROUP = 'loeschen';
export const ACTION_CREATE_PRODUCT_CATALOG_GROUP = 'Leistungsgruppe erstellen';
export const NAME_CREATE_PRODUCT_CATALOG_GROUP_SUCCESS = 'Erfolg';
export const NAME_CREATE_PRODUCT_CATALOG_GROUP_ERROR = 'Fehler';
export const ACTION_CREATE_PRODUCT_CATALOG_GROUP_CANCEL = 'abbrechen';
export const GOAL_CREATE_PRODUCT_CATALOG_GROUP_SUCCESS = 17;

/**
 * Home
 */

export const CATEGORY_HOME = 'dashboard';
export const ACTION_MONTH_TABLE_VIEW = 'monthly-view_table-view';
export const ACTION_LIQUIDITY_TABLE_VIEW = 'smart-budget_table-view';
export const DASHBOARD_KPI_TILE_OPEN_DATEPICKER = 'kpi-tile_open-datepicker';
export const DASHBOARD_KPI_TILE_CHOSE_YEAR = 'kpi-tile_chose-year';

/**
 * Product-catalog-items (piwik middleware)
 */

export const CATEGORY_PRODUCT_CATALOG_ITEMS = 'Leistungskatalog/Leistungen';
export const ACTION_NEW_PRODUCT_CATALOG_ITEM = 'settings_product-catalogue_product_new';
export const ACTION_EDIT_PRODUCT_CATALOG_ITEM = 'settings_product-product_edit';
export const ACTION_DELETE_PRODUCT_CATALOG_ITEM = 'settings_product-product_delete';
export const ACTION_CREATE_PRODUCT_CATALOG_ITEM = 'settings_product-product_create';
export const NAME_CREATE_PRODUCT_CATALOG_ITEM_SUCCESS = 'settings_product-product_create_success';
export const NAME_CREATE_PRODUCT_CATALOG_ITEM_ERROR = 'settings_product-product_create_error';
export const ACTION_CREATE_PRODUCT_CATALOG_ITEM_CANCEL = 'settings_product-product_cancel';

/**
 * Proposals
 */

export const CATEGORY_PROPOSALS = 'Proposals';
export const ACTION_NEW_PROPOSAL = 'revenue_proposals_new';
export const ACTION_SHOW_PROPOSAL = 'revenue_proposals_view-mode';
export const ACTION_EDIT_PROPOSAL = 'revenue_proposals_edit-mode';
export const ACTION_DELETE_PROPOSAL_SUCCESS = 'revenue_proposals_delete';
export const ACTION_INDEX_VIEW_TRANSFORM_PROPOSAL = 'revenue_proposals_invoice';
export const ACTION_NEW_VIEW_TRANSFORM_PROPOSAL =
  'revenue_proposals_new_into-invoice-transform in Rechnung ueberfuehren von Erstellungsseite';
export const ACTION_EDIT_VIEW_TRANSFORM_PROPOSAL =
  'revenue_proposals_edit-mode_into-invoice-transform';
export const ACTION_SHOW_VIEW_TRANSFORM_PROPOSAL =
  'revenue_proposals_view-mode_into-invoice-transform';
export const ACTION_CREATE_CLIENT_IN_PROPOSAL_CREATOR = 'revenue_proposals_new_customer_create';
export const NAME_CREATE_CLIENT_IN_PROPOSAL_CREATOR_SUCCESS =
  'revenue_proposals_new_customer_success';
export const NAME_CREATE_CLIENT_IN_PROPOSAL_CREATOR_ERROR = 'revenue_proposals_new_customer_error';
export const ACTION_CREATE_PROPOSAL_CANCEL = 'revenue_proposals_cancel';
export const ACTION_CREATE_PROPOSAL_SAVE = 'revenue_proposals_save';
export const ACTION_PROPOSAL_DOWNLOAD = 'revenue_proposals_download';
export const ACTION_PROPOSAL_SEND = 'revenue_proposals_send';
export const ACTION_EDIT_PROPOSAL_SAVE = 'revenue_proposals_edit-mode-save';
export const ACTION_PROPOSAL_PREVIEW = 'revenue_proposals_preview-open';

/**
 * Order Confirmation
 */

export const CATEGORY_ORDER_CONFIRMATIONS = 'OrderConfirmations';
export const ACTION_NEW_ORDER_CONFIRMATION = 'revenue_order_confirmation_new';
export const ACTION_SHOW_ORDER_CONFIRMATION = 'revenue_order_confirmation_view-mode';
export const ACTION_EDIT_ORDER_CONFIRMATION = 'revenue_order_confirmation_edit-mode';
export const ACTION_DELETE_ORDER_CONFIRMATION_SUCCESS = 'revenue_order_confirmation_delete';
export const ACTION_INDEX_VIEW_TRANSFORM_ORDER_CONFIRMATION = 'revenue_order_confirmation_invoice';
export const ACTION_NEW_VIEW_TRANSFORM_ORDER_CONFIRMATION =
  'revenue_order_confirmation_new_into-invoice-transform in Rechnung ueberfuehren von Erstellungsseite';
export const ACTION_EDIT_VIEW_TRANSFORM_ORDER_CONFIRMATION =
  'revenue_order_confirmation_edit-mode_into-invoice-transform';
export const ACTION_SHOW_VIEW_TRANSFORM_ORDER_CONFIRMATION =
  'revenue_order_confirmation_view-mode_into-invoice-transform';
export const ACTION_CREATE_CLIENT_IN_ORDER_CONFIRMATION_CREATOR =
  'revenue_order_confirmation_new_customer_create';
export const NAME_CREATE_CLIENT_IN_ORDER_CONFIRMATION_CREATOR_SUCCESS =
  'revenue_order_confirmation_new_customer_success';
export const NAME_CREATE_CLIENT_IN_ORDER_CONFIRMATION_CREATOR_ERROR =
  'revenue_order_confirmation_new_customer_error';
export const ACTION_CREATE_ORDER_CONFIRMATION_CANCEL = 'revenue_order_confirmation_cancel';
export const ACTION_CREATE_ORDER_CONFIRMATION_SAVE = 'revenue_order_confirmation_save';
export const ACTION_ORDER_CONFIRMATION_DOWNLOAD = 'revenue_order_confirmation_download';
export const ACTION_ORDER_CONFIRMATION_SEND = 'revenue_order_confirmation_send';
export const ACTION_EDIT_ORDER_CONFIRMATION_SAVE = 'revenue_order_confirmation_edit-mode-save';
export const ACTION_ORDER_CONFIRMATION_PREVIEW = 'revenue_order_confirmation_preview-open';

/**
 * Delivery Note
 */

export const CATEGORY_DELIVERY_NOTES = 'DeliveryNotes';
export const ACTION_NEW_DELIVERY_NOTE = 'revenue_delivery_note_new';
export const ACTION_SHOW_DELIVERY_NOTE = 'revenue_delivery_note_view-mode';
export const ACTION_EDIT_DELIVERY_NOTE = 'revenue_delivery_note_edit-mode';
export const ACTION_DELETE_DELIVERY_NOTE_SUCCESS = 'revenue_delivery_note_delete';
export const ACTION_INDEX_VIEW_TRANSFORM_DELIVERY_NOTE = 'revenue_delivery_note_invoice';
export const ACTION_NEW_VIEW_TRANSFORM_DELIVERY_NOTE =
  'revenue_delivery_note_new_into-invoice-transform in Rechnung ueberfuehren von Erstellungsseite';
export const ACTION_EDIT_VIEW_TRANSFORM_DELIVERY_NOTE =
  'revenue_delivery_note_edit-mode_into-invoice-transform';
export const ACTION_SHOW_VIEW_TRANSFORM_DELIVERY_NOTE =
  'revenue_delivery_note_view-mode_into-invoice-transform';
export const ACTION_CREATE_CLIENT_IN_DELIVERY_NOTE_CREATOR =
  'revenue_delivery_note_new_customer_create';
export const NAME_CREATE_CLIENT_IN_DELIVERY_NOTE_CREATOR_SUCCESS =
  'revenue_delivery_note_new_customer_success';
export const NAME_CREATE_CLIENT_IN_DELIVERY_NOTE_CREATOR_ERROR =
  'revenue_delivery_note_new_customer_error';
export const ACTION_CREATE_DELIVERY_NOTE_CANCEL = 'revenue_delivery_note_cancel';
export const ACTION_CREATE_DELIVERY_NOTE_SAVE = 'revenue_delivery_note_save';
export const ACTION_DELIVERY_NOTE_DOWNLOAD = 'revenue_delivery_note_download';
export const ACTION_DELIVERY_NOTE_SEND = 'revenue_delivery_note_send';
export const ACTION_EDIT_DELIVERY_NOTE_SAVE = 'revenue_delivery_note_edit-mode-save';
export const ACTION_DELIVERY_NOTE_PREVIEW = 'revenue_delivery_note_preview-open';

/*
 * Expenses
 */

export const PIWIK_CATEGORY_EXPENSES = 'expense';
export const PIWIK_ACTION_TAB_EXPENSES_DIAGRAM = 'expense_invoices_chart-view';
export const PIWIK_ACTION_TAB_EXPENSES_TABLE = 'expense_invoices_table-view';
export const PIWIK_ACTION_NEW_INCOMING_INVOICE = 'expense_invoices_new';
export const PIWIK_ACTION_SHOW_INCOMING_INVOICE = 'expense_invoices_view-mode';
export const PIWIK_ACTION_EDIT_INCOMING_INVOICE = 'expense_invoices_edit-mode';
export const PIWIK_ACTION_DELETE_INCOMING_INVOICE = 'expense_invoices_delete';
export const PIWIK_ACTION_CREATE_SUPPLIER = 'expense_invoices_create-supplier';
export const PIWIK_ACTION_OPEN_BANK_TRANSFER_ASSIGNMENT_DROPDOWN =
  'expense_invoices_bank-transfer_select';
export const PIWIK_ACTION_MARK_INVOICE_FULLY_PAID = ' expense_invoices_completely_paid';
export const PIWIK_ACTION_NEW_INCOMING_INVOICE_CANCEL = 'expense_invoices_new_cancel';
export const PIWIK_ACTION_NEW_INCOMING_INVOICE_SAVE = 'expense_invoices_new_save';
export const PIWIK_ACTION_EDIT_INCOMING_INVOICE_SAVE = 'expense_invoices_edit-mode_save';
export const PIWIK_NAME_CREATE_SUPPLIER_SUCCESS = 'expense_invoices_create-supplier_success';
export const PIWIK_NAME_CREATE_SUPPLIER_ERROR = 'expense_invoices_create-supplier_error';

/*
 * Recurring contracts
 */

export const PIWIK_CATEGORY_RECURRING_EXPENSES = 'recurring-contracts';

export const PIWIK_ACTION_CREATE_RECURRING_EXPENSE = 'recurring_contracts_create';

/**
 * Sidebar notifications
 */

export const CATEGORY_NOTIFICATIONS_SIDEBAR = 'notification-sidebar';

export const SHOW_TODOS = 'todos_show';
export const HIDE_TODOS = 'todos_hide';
export const SWITCH_TO_TODOS = 'todos_switch';

export const TODO_ACTION_CREATE_PAYMENT_REMINDER = 'todo_payment-reminder_create';
export const TODO_ACTION_ASSIGN_ICOMING_PAYMENT = 'todo_payment-incoming_assign';
export const TODO_ACTION_FINALIZE_OUTGOING_DRAFT = 'todo_outgoing-draft_finalize';
export const TODO_ACTION_FINALIZE_DRAFT_FROM_UPLOAD = 'todo_draft-from-upload_finalize';
export const TODO_ACTION_PAY_OVERDUE_PAYMENT = 'todo_payment-overdue';
export const TODO_ACTION_PAY_INVOICE = 'todo_invoice_pay';
export const TODO_ACTION_USE_DISCOUNT = 'todo_discount_use';
export const TODO_ASSIGN_OUTGOING_PAYMENT = 'todo_outgoing-payment';

export const GOAL_CLICK_TODO_TASK_ACTION_BUTTON = 19;

/**
 * Payment creator
 */

export const CATEGORY_PAYMENT_CREATOR = 'transaction';

export const ACTION_NEW_PAYMENT = 'transaction_new-payment_start';
export const ACTION_CANCEL_PAYMENT = 'transaction_step-1_cancel';
export const ACTION_UPDATE_SUPPLIER = 'transaction_supplier_update';
export const ACTION_PAYMENT_CREATOR_STEP_2 = 'transaction_step-2_start';
export const ACTION_PAYMENT_CREATOR_STEP_2_BACK = 'transaction_step-2_back';
export const ACTION_SELECT_TAN = 'transaction_select-tan';
export const ACTION_PAYMENT_CREATOR_STEP_3 = 'transaction_step-3_start';
export const ACTION_PAYMENT_CREATOR_STEP_3_BACK = 'transaction_step-3_back';
export const ACTION_SAVE_BANK_CREDENTIALS = 'transaction_bank-credentials_save';
export const ACTION_PAYMENT_CREATOR_STEP_4 = 'transaction_step-4_start';
export const ACTION_PAYMENT_CREATOR_STEP_4_BACK = 'transaction_step-4_back';
export const ACTION_PAYMENT_CREATION_SUCCESS_FULL = 'transaction_full-transaction-creation_success';
export const ACTION_PAYMENT_CREATION_SUCCESS_PARTIAL =
  'transaction_partly-transaction-creation_success';
export const ACTION_FINISH_PAYMENT_CREATOR = 'transaction_payment-creator_finish';

export const GOAL_CREATE_PAYMENT = 20;

/**
 * Product catalog
 */

export const CATEGORY_PRODUCT_CATALOG = 'product-catalog';

export const ACTION_CREATE_NEW_INVOICE_POSITIONS_ADD_POSITION =
  'create_new_invoice_positions_add-position';
export const CREATE_NEW_INVOICE_POSITIONS_SEARCH_POSITION =
  'create_new_invoice_positions_search-position';
export const CREATE_NEW_INVOICE_POSITIONS_SEARCH_POSITION_SELECT =
  'create_new_invoice_positions_search-position-select';
export const CREATE_NEW_INVOICE_POSITIONS_SEARCH_HELPICON =
  'create_new_invoice_positions-helpicon_search';
export const CREATE_NEW_INVOICE_POSITIONS_SAVE_POSITION =
  'create_new_invoice_positions_save-position';
export const CREATE_NEW_INVOICE_POSITIONS_DELETE_POSITION =
  'create_new_invoice_positions_delete-position';
export const CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG =
  'create_new_invoice_positions-in-catalog_save';
export const CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG_SUCCESS =
  'create_new_invoice_positions_save-in-catalog_success';
export const CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG_CHANGE =
  'create_new_invoice_positions_save-in-catalog_change';
export const CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG_CREATE_NEW =
  'create_new_invoice_positions_save-in-catalog_create-new';
export const CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG_CANCEL =
  'create_new_invoice_positions_save-in-catalog_cancel';
export const PRODUCT_CATALOG_INDEX_SEARCH_PRODUCT = 'product_catalog_index-product_search';
export const PRODUCT_CATALOG_INDEX_SHOW_FILTER = 'product_catalog_index-filter_show';
export const PRODUCT_CATALOG_INDEX_HIDE_FILTER = 'product_catalog_index-filter_hide';
export const PRODUCT_CATALOG_INDEX_RESET_FILTER = 'product_catalog_index-filter_reset';
export const PRODUCT_CATALOG_INDEX_EDIT_PRODUCT = 'product_catalog_index-product_edit';
export const PRODUCT_CATALOG_INDEX_DELETE_PRODUCT = 'product_catalog_index-product_delete';
export const PRODUCT_CATALOG_NEW_PRODUCT_GET_NEXT_PRODUCT_NUMBER =
  'product_catalog_new-product-next-productnumber_get';
export const PRODUCT_CATALOG_NEW_PRODUCT_SELECT_UNIT = 'product_catalog_new-product-unit_select';
export const PRODUCT_CATALOG_NEW_PRODUCT_SWITCH_NET_GROSS =
  'product_catalog_new-product-net-gross_switch';

/**
 * Dashboard
 */

export const CATEGORY_DASHBOARD = 'dashboard';
export const CATEGORY_LIQUIDITY_CHART = 'liquidity-chart';

export const ACTION_REDIRECT_TO_BANK_ACCOUNTS = 'dashboard_account_balance_bank-account_redirect';
export const ACTION_OVERVIEW_REDIRECT_TO_REVENUE = 'dashboard_monthly_overview-revenue_redirect';
export const ACTION_OVERVIEW_REDIRECT_TO_EXPENSES = 'dashboard_monthly_overview-expenses_redirect';
export const ACTION_ENTER_PROFILE = 'dashboard_profile_enter';
export const ACTION_ENTER_BANK_ACCOUNTS = 'dashboard_bank-accounts_enter';
export const ACTION_ENTER_INVOICE_TEMPLATES = 'dashboard_invoice-templates_enter';
export const ACTION_CLOSE_WELCOME_CARD = 'dashboard_welcome_card-contracts_close';
export const ACTION_CLOSE_WELCOME_CARD_PERMA = 'dashboard_welcome_card-perma_close';
export const ACTION_CLOSE_WELCOME_CARD_CONTRACTS = 'dashboard_welcome_card-contracts_close';
export const ACTION_CLOSE_WELCOME_CARD_CONTRACTS_PERMA =
  'dashboard_welcome_card-contracts-perma_close';
export const CLIENT_CREATE_NEW_CLIENT = 'client_create-new-client';
export const EXPENSES_CREATE_NEW_INCOVICE = 'expenses_create-new-invoice';
export const REVENUE_CREATE_NEW_INVOICE = 'revenue_create-new-invoice';
export const EDIT_WIDGETS = 'dashboard_edit';
export const SAVE_WIDGETS = 'dashboard_save';

/**
 * Farmpilot
 */

export const CATEGORY_FARMPILOT = 'farmpilot';

export const ACTION_EARNINGS_FARMPILOT_SHOW_INFORMATION = 'earnings_farmpilot_show-information';
export const ACTION_EARNINGS_FARMPILOT_DELETE_INVOICE = 'earnings_farmpilot_delete-invoice';
export const ACTION_SETTINGS_FARMPILOT_SEE_CODE_INFO = 'settings_addons_farmpilot_see-code-info';
export const ACTION_SETTINGS_FARMPILOT_GENERATE_CODE = 'settings_addons_farmpilot_generate-code';
export const ACTION_SETTINGS_FARMPILOT_OPEN_HANDBOOK = 'settings_addons_farmpilot_open-handbook';
export const ACTION_EDIT_IN_FARMPILOT = 'customer_farmpilot_open-edit-in-farmpilot';
export const ACTION_SHOW_EDIT_FARMPILOT_INFORMATION = 'customer_farmpilot_show-edit-information';
export const ACTION_OPEN_REQUIRMENTS = 'settings_addons_farmpilot_open-requirements';
export const ACTION_CLOSE_REQUIREMENTS = 'settings_addons_farmpilot_close-page';
export const ACTION_CANCEL_REMOVE_CODE = 'settings_addons_farmpilot_cancel-remove-code';
export const ACTION_REMOVE_CODE = 'settings_addons_farmpilot_remove-code';
export const ACTION_SHOW_PREMIUM_INFO = 'settings_addons_farmpilot_show-premium-info';
export const ACTION_PREMIUM_HINT_OPEN_LANDING_PAGE =
  'settings_addons_farmpilot_premium-hint-open-landingpage';
export const ACTION_PREMIUM_HINT_SHOW_TARIF_OPTIONS =
  'settings_addons_farmpilot_premium-hint-show-tarif-options';

/**
 * OBS
 */

export const CATEGORY_NAVIGATION = 'navigation';

export const OBS_DROPDOWN_MORE_INFORMATION = 'OBS_dropdown-more-information';
export const OBS_REQUEST_NOW = 'OBS_request-now';
export const OBS_OPEN_MODAL = 'OBS_open-modal';
export const OBS_CLOSE_MODAL = 'OBS_close-modal';
export const OBS_OPEN = 'OBS_open';

/**
 * Liquidity Tooltip
 */

export const CATEGORY_LIQUI_TOOLTIP = 'liqui-tooltip';

export const DISMISS = 'dismiss';
export const DONT_SHOW_AGAIN = 'nevershowagain';

/**
 * Cashbooks
 */

export const CATEGORY_CASHBOOKS = 'cashbooks';

export const CASHBOOKS_CREATE_CASHBOOK = 'cashbook_setup';
export const CASHBOOKS_DOWNLOAD_PDF = 'cashbook_create-export';
export const CASHBOOKS_TRANSACTION_TYPE_CHANGED = 'cashbook_save-entry';
export const CASHBOOKS_CREATE_CASH_TRANSACTION = 'cashbook_create-entry';
export const CASHBOOKS_EDIT_CASH_TRANSACTION = 'cashbook_correcting-entry';
export const CASHBOOKS_DELETE_CASH_TRANSACTION = 'cashbook_delete-entry';
export const CASHBOOKS_CREATE_REMINDER = 'cashbook_create-reminder';
export const CASHBOOKS_EDIT_REMINDER = 'cashbook_edit-reminder';

/**
 * Global Search
 */
export const CATEGORY_GLOBAL_SEARCH = 'global-search';

export const GLOBAL_SEARCH_CLICK_ON_RESULT = 'search_go-to-result';
export const GLOBAL_SEARCH_SEARCH_TERM = 'search_search-term';

/**
 * Bank transfers tile
 */
export const BANK_TRANSFERS_TILE_GO_TO_BANK_TRANSFERS = 'bank-transfers_goto-banktransfers';
export const BANK_TRANSFERS_TILE_ADD_ACCOUNT = 'bank-transfers_add-account';
export const BANK_TRANSFERS_TILE_SELECT_ACCOUNT = 'bank-transfers_select-account';

/**
 * Bank accounts tile
 */
export const BANK_ACCOUNTS_TILE_GO_TO_BANK_ACCOUNTS = 'bank-accounts_goto-bank-accounts';
export const BANK_ACCOUNTS_TILE_ADD_ACCOUNT = 'bank-accounts_add-account';
export const BANK_ACCOUNTS_TILE_ACTIVATE_ACCOUNT = 'bank-accounts_activate-account';
export const BANK_ACCOUNTS_TILE_DEACTIVATE_ACCOUNT = 'bank-accounts_deactivate-account';
export const BANK_ACCOUNTS_TILE_ACTIVATE_CASHBOOK = 'bank-accounts_activate-cashbook';
export const BANK_ACCOUNTS_TILE_DEACTIVATE_CASHBOOK = 'bank-accounts_deactivate-cashbook';

/**
 * Email verification infobar
 */
export const CATEGORY_EMAIL_VERIFICATION_INFOBAR = 'banner-confirm-mail';
export const EMAIL_VERIFICATION_INFOBAR_CLOSE = 'close';
export const EMAIL_VERIFICATION_INFOBAR_RESEND = 'resend-link';

/**
 * Onboarding tile
 */
export const ONBOARDING_TILE_CLOSE = 'onboarding_close';
export const ONBOARDING_TILE_DO_NOT_SHOW_AGAIN = 'onboarding_never-show-again';

/**
 * Welcome tile
 */
export const WELCOME_TILE_CLOSE = 'welcome_close';
export const WELCOME_TILE_DO_NOT_SHOW_AGAIN = 'welcome_never-show-again';

/**
 * Global notifications
 */
export const CATEGORY_GLOBAL_NOTIFICATION = 'global-notification';
export const GLOBAL_NOTIFICATION_CLOSE = 'close';
export const GLOBAL_NOTIFICATION_GO_TO_INFO = 'go-to-info';
export const GLOBAL_NOTIFICATION_DO_NOT_SHOW_AGAIN = 'never-show-again';

/**
 * Settings -> Document Template -> Layout
 */
export const CATEGORY_DOCUMENT_LAYOUT = 'document-layout';
export const DOCUMENT_LAYOUT_CHOOSE_PRESET = 'choose-preset';
export const DOCUMENT_LAYOUT_CHOOSE_HEADER_STYLE = 'choose-header-style';
export const DOCUMENT_LAYOUT_CHOOSE_TABLE_STYLE = 'choose-table-style';
export const DOCUMENT_LAYOUT_CHOOSE_FOOTER_STYLE = 'choose-footer-style';
export const DOCUMENT_LAYOUT_CHOOSE_FONT_FAMILY = 'choose-font-family';
export const DOCUMENT_LAYOUT_PICK_COLOR = 'pick-color';
export const DOCUMENT_LAYOUT_SAVE = 'layout-save';
export const DOCUMENT_LAYOUT_DOWNLOAD = 'download';
export const DOCUMENT_LAYOUT_PREVIEW_MODE_DOWNLOAD = 'preview-mode_download';
export const DOCUMENT_LAYOUT_PREVIEW_MODE_ENTER = 'preview-mode_enter';

/**
 * Settings -> Document Template -> Table defaults
 */
export const CATEGORY_TABLE_DEFAULTS = 'table-defaults';
export const TABLE_DEFAULTS_SAVE = 'tables-save';
export const TABLE_DEFAULTS_DOWNLOAD = 'download';
export const TABLE_DEFAULTS_PREVIEW_MODE_DOWNLOAD = 'preview-mode_download';
export const TABLE_DEFAULTS_PREVIEW_MODE_ENTER = 'preview-mode_enter';

/**
 * Settings -> Document Template -> Logo and Briefpapier
 */
export const CATEGORY_LOGO_AND_BRIEFPAPIER = 'logo-and-briefpapier';
export const LOGO_AND_BRIEFPAPIER_SAVE = 'logo-and-briefpapier-save';
export const LOGO_AND_BRIEFPAPIER_DOWNLOAD = 'download';
export const LOGO_AND_BRIEFPAPIER_PREVIEW_MODE_DOWNLOAD = 'preview-mode_download';
export const LOGO_AND_BRIEFPAPIER_PREVIEW_MODE_ENTER = 'preview-mode_enter';
export const LOGO_AND_BRIEFPAPIER_LOGO_UPLOAD = 'logo_upload';
export const LOGO_AND_BRIEFPAPIER_BRIEFPAPIER_UPLOAD = 'briefpapier_upload';
