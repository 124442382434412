import React from 'react';
import cx from 'classnames';
import { shape } from 'prop-types';

import tableStyles from 'shared/styles/table.module.css';
import { mapClient, t } from 'shared/utils';
import Amount from 'components/Amount';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import InvoiceStatusBadge from 'components/Table/InvoiceStatusBadge/InvoiceStatusBadge';

import InvoiceColumn from '../../InvoiceColumn';

import styles from '../InvoiceRow.module.css';

const OutgoingInvoiceRow = ({ invoice }) => {
  const clientKey = invoice['persisted-client'] ? 'persisted-client' : 'client';

  return (
    <tr data-id="Datev:new-export-table-row">
      <InvoiceColumn className={tableStyles.cell}>
        <span className={styles.cellHeader}>{t('datev.creator.table.headings.status')}</span>
        <div className={cx(styles.cellContent, styles.status)}>
          <InvoiceStatusBadge
            status={invoice.status}
            remindersCount={invoice.paymentRemindersCount}
          />
        </div>
      </InvoiceColumn>
      <InvoiceColumn className={tableStyles.cell} expand>
        <span className={styles.cellHeader}>{t('datev.creator.table.headings.client')}</span>
        <CellContentWithTooltip>
          {invoice[clientKey] && mapClient(invoice[clientKey]).name}
        </CellContentWithTooltip>
      </InvoiceColumn>
      <InvoiceColumn className={tableStyles.cell} expand>
        <span className={styles.cellHeader}>{t('datev.creator.table.headings.number')}</span>
        <CellContentWithTooltip>{invoice.number || '-'}</CellContentWithTooltip>
      </InvoiceColumn>
      <InvoiceColumn className={tableStyles.cell} expand>
        <span className={styles.cellHeader}>{t('datev.creator.table.headings.subject')}</span>
        <CellContentWithTooltip>{invoice.subject || '-'}</CellContentWithTooltip>
      </InvoiceColumn>
      <InvoiceColumn className={tableStyles.cell} align="center">
        <span className={styles.cellHeader}>{t('datev.creator.table.headings.creation_date')}</span>
        <div className={styles.cellContent}>{invoice.creationDate || '-'}</div>
      </InvoiceColumn>
      <InvoiceColumn className={tableStyles.cell} align="center">
        <span className={styles.cellHeader}>{t('datev.creator.table.headings.due_date')}</span>
        <div className={styles.cellContent}>{invoice.dueDate || '-'}</div>
      </InvoiceColumn>
      <InvoiceColumn className={tableStyles.cell} align="right">
        <span className={styles.cellHeader}>
          {t('datev.creator.table.headings.total_gross_amount')}
        </span>
        <div className={styles.cellContent}>
          <Amount currency={invoice.currency} amount={invoice.totalGrossAmount} />
        </div>
      </InvoiceColumn>
    </tr>
  );
};

OutgoingInvoiceRow.propTypes = {
  invoice: shape({}).isRequired,
};

export default OutgoingInvoiceRow;
