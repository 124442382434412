import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncDebounce, usePagination, useTable } from 'react-table';
import { useUpdateEffect } from 'react-use';

import Table from 'components/v2/Table/Table/Table';
import TablePagination from 'components/v2/Table/TablePagination/TablePagination';
import * as paginationPrefrerencesActions from 'features/paginationPreferences/paginationPreferencesSlice';

import * as actions from './expenseCashTransactionsSlice';
import * as selectors from './selectors';
import { getColumns, getTableData, RowData } from './utils';

const ExpenseCashTransactions = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectors.getData);
  const isLoading = useSelector(selectors.getIsLoading);
  const params = useSelector(selectors.getParams);
  const initialPageSize = useSelector(selectors.getPaginationPreference);
  const columns = useMemo(() => getColumns(), []);
  const tableData = useMemo(() => getTableData({ cashTransactions: data }), [data]);
  const instance = useTable<RowData>(
    {
      columns,
      data: tableData,
      pageCount: params.pageCount,
      initialState: {
        pageSize: initialPageSize,
        pageIndex: params.pageIndex,
      },
      manualPagination: true,
      manualSortBy: true,
      disableSortRemove: true,
    },
    usePagination
  );
  const {
    state: { pageIndex, pageSize },
  } = instance;

  const handleFetchData = useCallback(
    async ({ pageSize, pageIndex }) => {
      await dispatch(
        paginationPrefrerencesActions.setPaginationPreference({
          resource: 'datevExports',
          pageSize,
        })
      );

      dispatch(actions.setParams({ pageIndex }));
      dispatch(actions.getData());
    },
    [dispatch]
  );

  const onFetchDataDebounced = useAsyncDebounce(handleFetchData, 200);

  useUpdateEffect(() => {
    onFetchDataDebounced({ pageIndex, pageSize });
  }, [onFetchDataDebounced, pageIndex, pageSize]);

  return (
    <>
      <div data-id="expense-cash-transactions-table">
        <Table<RowData> instance={instance} isLoading={isLoading} />
        <TablePagination<RowData> instance={instance} isLoading={isLoading} />
      </div>
    </>
  );
};

export default memo(ExpenseCashTransactions);
