import { t } from 'shared/utils';

export enum Interval {
  Weekly = 'weekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Yearly = 'yearly',
}

export const MonthlyIntervalMultiplier = {
  [Interval.Weekly]: 4,
  [Interval.Monthly]: 1,
  [Interval.Quarterly]: 1 / 3,
  [Interval.Yearly]: 1 / 12,
};

export const IntervalOptions = [
  Interval.Weekly,
  Interval.Monthly,
  Interval.Quarterly,
  Interval.Yearly,
].map((interval) => ({
  label: t(`features.financial_planning.liquidity_calculator.interval_options.${interval}`),
  value: interval,
}));
