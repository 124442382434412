import { AxiosPromise } from 'axios';
import { JsonApiResponse } from 'json-api-normalizer';

import api from '../index';

export const getRevenueCategories = (): AxiosPromise<JsonApiResponse> => {
  const url = '/line_item_categories/cash_transaction_revenues';

  return api.get(url);
};

export const getExpenseCategories = (): AxiosPromise<JsonApiResponse> => {
  const url = '/line_item_categories/cash_transaction_expenses';

  return api.get(url);
};
