import React from 'react';
import { number } from 'prop-types';

import { formatMoney, t } from 'shared/utils';

import styles from '../Header.module.css';

const PriceContainer = ({ defaultPrice }) => {
  if (!defaultPrice)
    return (
      <span className={styles.pricingTransaltion}>
        {t('profile.payment_plan.comparison_box.free')}
      </span>
    );

  const price = formatMoney(defaultPrice);

  return (
    <>
      <span className={styles.pricingTransaltion}>
        {t('profile.payment_plan.comparison_box.monthly_price')}
      </span>
      {` ${price}`}
    </>
  );
};

PriceContainer.propTypes = {
  defaultPrice: number,
};

export default PriceContainer;
