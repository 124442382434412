import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

import styles from './StatusFiltersGroup.module.css';

const StatusFilterGroup = ({ filterSelector, selectFilterAction, children }) => {
  const validChildren = children.filter((child) => child);
  const childrenWithInjectedProps = Children.map(validChildren, (child) =>
    cloneElement(child, { onClick: selectFilterAction, currentFilter: filterSelector })
  );

  return (
    <div className={styles.filterGroup} data-id="filter-group">
      {childrenWithInjectedProps}
    </div>
  );
};

StatusFilterGroup.propTypes = {
  filterSelector: PropTypes.string,
  selectFilterAction: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default StatusFilterGroup;
