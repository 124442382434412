import {
  ACTION_PAYMENT_CREATION_SUCCESS_FULL,
  ACTION_PAYMENT_CREATION_SUCCESS_PARTIAL,
  ACTION_SELECT_TAN,
  CATEGORY_PAYMENT_CREATOR,
  GOAL_CREATE_PAYMENT,
} from 'constants/piwik';
import { piwikHelpers } from 'shared/utils/piwik';

export const trackPaymentEvent = piwikHelpers.trackEventHandlerCurried(CATEGORY_PAYMENT_CREATOR);

export const trackTanSelectionEvent = (tanScheme) =>
  trackPaymentEvent(`${ACTION_SELECT_TAN}[${tanScheme}]`)();

export const trackCreatePaymentSuccessEvent = ({ paymentAmount, invoiceAmount }) =>
  paymentAmount >= invoiceAmount
    ? trackPaymentEvent(ACTION_PAYMENT_CREATION_SUCCESS_FULL)()
    : trackPaymentEvent(ACTION_PAYMENT_CREATION_SUCCESS_PARTIAL)();

export const trackCreatePaymentGoal = () => piwikHelpers.trackGoal(GOAL_CREATE_PAYMENT);
