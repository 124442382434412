import styled from 'styled-components';

import Calendar from 'redesign/components/atoms/Icons/Calendar';
import BaseLabel from 'redesign/components/atoms/Label/Label';

export const Label = styled(BaseLabel)`
  margin-bottom: ${({ theme }) => theme.space['1']};
`;

export const CalendarIcon = styled(Calendar)`
  width: ${({ theme }) => theme.space['5']};
  height: ${({ theme }) => theme.space['5']};
  color: ${({ theme }) => theme.colors.vrblue['100']};
`;

type DatePickerProps = {
  $isActive: boolean;
  $error: string | undefined;
};

export const DatePickerWrapper = styled.div<DatePickerProps>`
  .SingleDatePicker {
    display: block;
    padding-bottom: ${({ theme }) => theme.space['1']};
  }

  .SingleDatePickerInput__withBorder {
    border: 1px solid
      ${({ theme, $isActive, $error }) =>
        $error
          ? theme.colors.red['100']
          : $isActive
          ? theme.colors.vrblue['100']
          : theme.colors.grey['070']};
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  .SingleDatePickerInput_calendarIcon {
    margin: 0;
    padding: ${({ theme }) => theme.space['2']};
  }

  .DateInput_input {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};
    color: #121212;
    font-size: ${({ theme }) => theme.fontSize.base};
  }

  .DateInput_input::placeholder {
    color: #6d6d78;
  }

  .DateInput_fang {
    top: 47px !important;
  }

  .DateInput_input__focused {
    border-bottom: 2px solid transparent;
  }
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.red['100']};
  font-size: ${({ theme }) => theme.fontSize.sm};
`;
