import styled from 'styled-components';

import IconArrow from 'images/icon-arrow-brand-blue.svg';
import media from 'shared/styles/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 1;
  flex-flow: column wrap;
  min-height: 80px;

  ${media.greaterThan('lg-up')`
    flex-flow: row nowrap;
    align-items: flex-start;
  `}
`;

export const Navigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  order: 1;
  margin-bottom: 20px;

  ${media.greaterThan('lg-up')`
    width: 33.333%;
    order: 0;
    margin-bottom: 0;
  `}
`;

export const PageNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
  font-size: 12px;
  color: ${({ theme }) => theme.legacy.colors.brandBlue};

  > span {
    margin: 0 5px;
  }
`;

export const Input = styled.input`
  display: inline-flex;
  width: 23px;
  min-height: 23px;
  text-align: center;
  border: 1px solid #979797;
  border-radius: 2px;
  color: #5f656b;
  justify-content: center;
  appearance: textfield;
  margin: 3px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const PageSize = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;

  span {
    color: ${({ theme }) => theme.legacy.colors.brandBlue};
  }

  button:first-child {
    margin-left: 6px;
  }

  ${media.greaterThan('lg-up')`
    width: 33.333%;
    margin-bottom: 0;
  `}
`;

type ButtonProps = {
  isActive?: boolean;
};

export const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  width: 26px;
  height: 26px;
  background-color: transparent;
  color: ${({ theme }) => theme.legacy.colors.brandBlue};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    outline: #7fb1d6 5px solid;
    outline: auto 5px -webkit-focus-ring-color;
  }
`;

export const PreviousPage = styled(Button)`
  background-image: url('${IconArrow}');
  transform: rotate(-90deg);
`;

export const NextPage = styled(Button)`
  background-image: url('${IconArrow}');
  transform: rotate(90deg);
`;

export const LastPage = styled(Button)`
  width: auto;
`;
