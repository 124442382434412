import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  space: {
    px: '1px',
    '0': '0',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '10': '2.5rem',
    '12': '3rem',
    '15': '3.75rem',
    '16': '4rem',
    '18': '4.5rem',
  },
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2rem',
    '5xl': '2.25rem',
    '6xl': '3rem',
    '7xl': '4rem',
  },
  letterSpacing: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  colors: {
    white: '#FAFAFA',
    black: '#121212',
    lightblue: '#ECF1F5',
    nightblue: {
      '050': '#436BEE',
      '075': '#2747B0',
      '100': '#0F2364',
    },
    vrorange: {
      '050': '#FFBF3A',
      '075': '#FF9C14',
      '100': '#FF6600',
      '125': '#8B3C08',
    },
    vrblue: {
      '025': '#BFD9EC',
      '050': '#33A7FF',
      '075': '#0487EA',
      '100': '#0066B3',
    },
    grey: {
      '020': '#C7CBCE',
      '040': '#A1A5A7',
      '050': '#7D7F81',
      '070': '#58595A',
      '080': '#333333',
    },
    iceblue: {
      '025': '#BFEEFF',
      '050': '#80DCFF',
      '075': '#3FCBFF',
      '100': '#00B9FF',
      '125': '#008BBF',
      '150': '#005D80',
      '175': '#002E40',
    },
    green: {
      '025': '#C9EFCC',
      '050': '#90E09A',
      '075': '#59D067',
      '100': '#22C034',
      '125': '#188528',
      '150': '#106019',
      '175': '#09300E',
    },
    red: {
      '025': '#F6BFCC',
      '050': '#EE8099',
      '075': '#E54065',
      '100': '#DC0032',
      '125': '#A60126',
      '150': '#6E0019',
      '175': '#37000D',
    },
    yellow: {
      '025': '#FEEEBF',
      '050': '#FFDC80',
      '075': '#FFCA40',
      '100': '#FFB900',
      '125': '#EA8905',
      '150': '#965404',
      '175': '#401F01',
    },
  },
  legacy: {
    colors: {
      white: '#fff',
      black: '#000',
      brandBlue: '#0066b3',
      brandLightBlue: '#2382c8',
      brandLighterBlue: '#7fb1d6',
      linkBlue: '#0166b3',
      darkBlue: '#0f2364',
      brandDarkBlue: '#23527c',
      shiningBlue: '#87b7e0',
      brandOrange: '#f60',
      primaryColor: '#333',
      primaryBackground: '#e6e6e6',
      secondaryColor: '#979797',
      greenDark: '#146423',
      green: '#1e9b32',
      greenFont: '#198228',
      greenLight: '#78c984',
      greenLighter: '#bbe4c2',
      redDark: '#96001e',
      red: '#c5002d',
      redFont: '#aa0028',
      redLight: '#ea6684',
      redLighter: '#f4b3c2',
      greyFont: '#4d4e4c',
      silver: '#bababa',
      grey: '#b2b3b2',
      greyLight: '#777',
      greyLighter: '#b2b3b2',
      greyLightest: '#f0f0f0',
      greyWhite: '#f7f7f7',
      infoIcon: '#b4b5b4',
      fileUploadBackground: 'rgba(#eff8ff, 0.2)',
      fileUploadBorder: '#b5d3e6',
      fileUploadBorderActive: '#0065b2',
    },
  },
};

export default theme;
