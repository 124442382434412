import React from 'react';
import { connect } from 'react-redux';

import {
  deleteContractFile,
  downloadContract,
  downloadContractFile,
  uploadContractFile,
} from 'actions/contracts/uploaded-files';
import { showNotification as showNotificationAction } from 'actions/notification';
import paths from 'routes/paths';
import { RootState } from 'store';
import { Dispatch } from 'types/actions';
import Dropzone from 'components/Dropzone/Dropzone';
import type { DeleteFile, PreviewFile, TrackingEvents } from 'components/Dropzone/types';

type UploadFileProps = {
  accessToken: string;
  contractId: string;
  uploadedFiles: File[] & PreviewFile[];
  uploadFile: (contractId: string, files: File[]) => Promise<any>;
  downloadFilePreview: (contractId: string, fileId: string) => Promise<any>;
  deleteFile: DeleteFile;
  showNotification: (notification: {} | Notification) => void;
  trackingEvents: TrackingEvents;
  fileSubject: string;
  contractNumber: string;
  onDownloadContract: (contractId: string) => Promise<any>;
  readonly?: boolean;
};

const UploadFile = ({
  accessToken,
  contractId,
  uploadFile,
  downloadFilePreview,
  deleteFile,
  showNotification,
  uploadedFiles,
  trackingEvents,
  fileSubject,
  contractNumber,
  onDownloadContract,
  readonly = false,
}: UploadFileProps) => (
  <Dropzone
    template="contractFile"
    readonly={readonly}
    ocrEnabled={false}
    files={uploadedFiles}
    accessToken={accessToken}
    fileId={contractId}
    uploadFileAction={uploadFile}
    showNotification={showNotification}
    downloadFilePreview={downloadFilePreview}
    deleteFile={deleteFile}
    trackingEvents={trackingEvents}
    allowedFileExtensions={['application/pdf']}
    fileSubject={fileSubject}
    contractNumber={contractNumber}
    isFullscreen={readonly}
    onDownloadContract={onDownloadContract}
    contractDownloadUrl={paths.contractDownload(contractId, accessToken)}
  />
);

const mapStateToProps = (state: RootState) => ({
  // @ts-ignore: TODO remove when auth will be rewritten to ts
  accessToken: state.auth.token,
  contractId: state.oldContracts.details!.id,
  uploadedFiles: state.oldContracts.uploadedFiles,
  // @ts-ignore TODO: rewrite formReducer to TS
  contractNumber: state.form?.NewContract?.values?.contractNumber,
  // @ts-ignore TODO: rewrite formReducer to TS
  fileSubject: state.form?.NewContract?.values?.subject,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  uploadFile: (contractId: string, files: File[]) =>
    dispatch(uploadContractFile(contractId, files)),
  downloadFilePreview: (contractId: string, fileId: string) =>
    dispatch(downloadContractFile(contractId, fileId)),
  onDownloadContract: (contractId: string) => dispatch(downloadContract(contractId)),
  deleteFile: (contractId: string, fileId: string) =>
    dispatch(deleteContractFile(contractId, fileId)),
  showNotification: (notification: Notification) => dispatch(showNotificationAction(notification)),
});

// @ts-ignore: TODO remove when component will be REALLY rewritten to follow our conventions
export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
