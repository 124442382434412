import React, { FunctionComponent, Ref, useEffect, useRef } from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';

import styles from './Svg.module.css';

interface SvgProps {
  icon: string;
  width?: string;
  height?: string;
  fill?: string;
  shouldSetParametersWithJS?: boolean;
  className?: string;
}

const Svg: FunctionComponent<SvgProps> = ({
  icon = '',
  width = '',
  height = '',
  fill = '',
  shouldSetParametersWithJS = true,
  className = '',
}) => {
  const containerRef: Ref<HTMLDivElement> = useRef(null);

  useEffect(() => {
    if (!shouldSetParametersWithJS) return;

    const container = containerRef.current;

    if (!container) return;

    const span = container.querySelector('span');
    const svg = container.querySelector('svg');

    if (span === null || svg === null) return;

    container.style.width = width;
    container.style.height = height;
    span.style.width = width;
    span.style.height = height;
    svg.style.width = width;
    svg.style.height = height;
    svg.style.fill = fill;
  });

  return (
    <div className={styles.container} ref={containerRef}>
      <InlineSvg src={icon} className={cx(styles.icon, className)} />
    </div>
  );
};

export default Svg;
