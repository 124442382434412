import styled from 'styled-components';

const PopperArrow = styled.div`
  visibility: hidden;

  &,
  &::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  [data-popper-placement^='top'] & {
    bottom: -4px;
  }

  [data-popper-placement^='bottom'] & {
    top: -4px;
  }

  [data-popper-placement^='left'] & {
    right: -4px;
  }

  [data-popper-placement^='right'] & {
    left: -4px;
  }
`;

export default PopperArrow;
