import React, { useRef, useState } from 'react';
import ClipboardButton from 'react-clipboard.js';
import cx from 'classnames';
import { bool, string } from 'prop-types';

import checkmark from 'images/common/checkmark.svg';
import { t } from 'shared/utils';
import Button from 'components/Button';

import { animateButton } from './CopyButton.utils';

import styles from './CopyButton.module.css';

const copyText = t('farmpilot.connection_section.buttons.copy');

const CopyButton = ({
  dataId = 'Farmpilot:button-copy',
  textToCopy = '',
  addedClass,
  shouldExpand,
}) => {
  const buttonRef = useRef();
  const [wasCopied, setWasCopied] = useState(false);

  const handleClick = () => {
    setWasCopied(true);

    const button = buttonRef.current.element;
    animateButton(button, shouldExpand);
  };

  return (
    <Button
      className={cx(styles.button, addedClass)}
      Component={ClipboardButton}
      onClick={wasCopied ? undefined : handleClick}
      label={copyText}
      data-clipboard-text={textToCopy}
      buttonRef={buttonRef}
      icon={checkmark}
      svgClass={styles.iconContainer}
      dataId={dataId}
    />
  );
};

CopyButton.propTypes = {
  /** Text that will be copied */
  textToCopy: string,
  /** Additional class */
  addedClass: string,
  /** Should button expand on click */
  shouldExpand: bool,
  dataId: string,
};

export default CopyButton;
