import { v4 as uuidv4 } from 'uuid';

import { CALL_NOTIFICATION, HIDE, SHOW } from 'constants/notification';
import { isTest } from 'shared/utils/environment';

export default (store) => (next) => (action) => {
  if (typeof action[CALL_NOTIFICATION] === 'undefined') {
    return next(action);
  }

  const id = !isTest ? uuidv4() : 'Static ID, test friendly';
  const { duration = 0, variant, shouldThrow, ...options } = action[CALL_NOTIFICATION];

  if (duration !== 0) {
    setTimeout(() => {
      store.dispatch({
        type: HIDE,
        id,
      });
    }, duration);
  }

  store.dispatch({
    type: SHOW,
    id,
    variant,
    duration,
    ...options,
  });

  if (isTest) return Promise.resolve();

  return variant === 'warning' && shouldThrow ? Promise.reject() : Promise.resolve();
};
