import { JsonApiObject } from 'json-api-normalizer';
import { snakeCase } from 'lodash';

import { CATEGORY_GLOBAL_SEARCH } from 'constants/piwik';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';

export const trackEvent = piwikHelpers.trackEventCurried(CATEGORY_GLOBAL_SEARCH);

export const getCategory = (type: string) =>
  t(`redesign.molecules.site_search.categories.${snakeCase(type)}`);

export const getUrl = ({ id, type, attributes }: JsonApiObject) => {
  switch (type) {
    case 'proposals':
      return paths.showProposal(id);

    case 'clients':
      return paths.editClient(id);

    case 'suppliers':
      return attributes['internal-supplier'] ? paths.showSupplier(id) : paths.editSupplier(id);

    case 'outgoing-invoices':
      return paths.showOutgoingInvoice(id);

    case 'incoming-invoices':
      return paths.showIncomingInvoice(id);

    case 'contracts':
      const category = attributes['is-revenue'] ? 'revenues' : 'expenses';

      return paths.viewContract(category, attributes['contract-type'], id);

    case 'product-catalog-items':
      return paths.editProductCatalogItem(id);

    case 'order-confirmations':
      return paths.showOrderConfirmation(id);

    case 'delivery-notes':
      return paths.showDeliveryNote(id);
  }
};

export const getNumber = ({ type, attributes }: JsonApiObject) => {
  switch (type) {
    case 'proposals':
      return attributes['proposal-number'];

    case 'outgoing-invoices':
    case 'incoming-invoices':
      return attributes['number'];

    case 'contracts':
      return attributes['contract-number'];

    case 'delivery-notes':
      return attributes['delivery-note-number'];

    case 'order-confirmations':
      return attributes['order-confirmation-number'];
  }
};
