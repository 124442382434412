import React from 'react';
import cx from 'classnames';
import { arrayOf, number, shape, string } from 'prop-types';

import { t } from 'shared/utils';
import InfoIcon from 'components/InfoIcon/InfoIcon';

import Column from './Column/Column';
import Row from './Row/Row';

import styles from './Table.module.css';

const Table = ({ bankAccounts, oldBankAccounts = [] }) => (
  <table
    className={cx(styles.table, styles.flexibleTable)}
    id="bank-account-creator-account-results"
  >
    <thead>
      <tr>
        <Column className={styles.headColumn} />
        <Column className={styles.headColumn}>
          {t('bank_accounts.creator.fields.account_number')}
        </Column>
        <Column className={styles.headColumn}>
          {t('bank_accounts.creator.fields.account_name')}
        </Column>
        <Column className={cx(styles.headColumn, styles.alignCenter)}>
          {t('bank_accounts.creator.fields.credit_line')}
          <InfoIcon align="left" text={t('bank_accounts.creator.fields.credit_line_info')} />
        </Column>
      </tr>
    </thead>

    <tbody>
      {bankAccounts.map((x) => (
        <Row bankAccount={x} key={x.accountId} />
      ))}
      {oldBankAccounts.map((x) => (
        <Row isOldAccount bankAccount={x} key={x.accountId} />
      ))}
    </tbody>
  </table>
);

const BankAccountShape = shape({
  id: string,
  name: string,
  number: string,
  accountId: string,
  balance: number,
});

Table.propTypes = {
  bankAccounts: arrayOf(BankAccountShape).isRequired,
  oldBankAccounts: arrayOf(BankAccountShape),
};

export default Table;
