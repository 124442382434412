import { format } from 'date-fns';
import { get, isUndefined, pickBy } from 'lodash';

import { EDIT_MODE, NEW_MODE, SHOW_MODE } from 'constants/common/crud';
import { DATE_FORMAT_UNICODE } from 'constants/datetime';
import { deliveryNoteSelector } from 'reducers/form';
import { t } from 'shared/utils';
import getSalutationHonorific from 'shared/utils/getSalutationHonorific';
import { RootState } from 'store';
import { Client } from 'types/entities/Client';
import { AddressType } from 'features/deliveryNotes/DeliveryNoteCreator/CreateDeliveryNoteSection/DeviatingDeliveryAddress/DeviatingDeliveryAddress';

interface DeliveryNoteValues {
  deliveryDate?: number;
  subject?: string;
  salutation?: string;
  salutationContent?: string;
  deliveryInfo?: string;
  personalNotes?: string;
  deliveryNoteDate?: string;
  deliveryNoteSignature: boolean;
  deviatingAddress: string;
}

interface DeliveryNoteInitialValues extends Partial<DeliveryNoteValues> {
  salutationHonorific?: string;
  salutationContent?: string;
}

export const getDefaultValues = (
  fetchedDefaultValues: {
    subject: string;
    salutation: string;
    salutationContent: string;
    deliveryInfo: string;
    personalNotes: string;
  },
  client: Client
) => {
  const { subject, salutation, salutationContent, deliveryInfo, personalNotes } =
    fetchedDefaultValues;
  const salutationHonorific =
    salutation ||
    getSalutationHonorific('defaultDeliveryNoteSalutationDefined', { salutation }, client);

  return {
    subject,
    salutationHonorific,
    salutationContent:
      salutationContent || t('revenue.form.individual_contact.initial_message_order_confirmation'),
    deliveryInfo,
    personalNotes,
    deliveryNoteDate: format(new Date(), DATE_FORMAT_UNICODE),
  };
};

const isNotUndefined = (value: any) => !isUndefined(value);

export const getDeliveryNoteInitialValues = (
  state: RootState,
  crudMode: string,
  isFromOutgoingInvoice: boolean,
  isFromDuplicate: boolean
): DeliveryNoteInitialValues => {
  const client = deliveryNoteSelector(state, 'client') || get(state, 'deliveryNote.details.client');
  const deliveryNote = pickBy(get(state, 'deliveryNote.details', {}), isNotUndefined);
  const defaultValues = getDefaultValues(
    {
      subject: get(state, 'defaultValues.values.deliveryNoteSubject'),
      salutation: get(state, 'defaultValues.values.deliveryNoteSalutation'),
      salutationContent: get(state, 'defaultValues.values.deliveryNoteSalutationContent'),
      deliveryInfo: get(state, 'defaultValues.values.deliveryNoteDeliveryInfo'),
      personalNotes: get(state, 'defaultValues.values.deliveryNoteFooter'),
    },
    client
  );
  const setDeviatingAddress = deliveryNote.useDeviatingDeliveryAddress
    ? AddressType.Deviating
    : AddressType.Default;

  if (crudMode === NEW_MODE) {
    return {
      ...defaultValues,
      salutationHonorific: getSalutationHonorific(
        'defaultDeliveryNoteSalutationDefined',
        { salutation: defaultValues.salutationHonorific },
        client
      ),
    };
  }

  if (crudMode === SHOW_MODE) {
    return {
      ...defaultValues,
      ...deliveryNote,
      salutationHonorific: getSalutationHonorific(
        'defaultDeliveryNoteSalutationDefined',
        { salutation: deliveryNote.salutationHonorific || defaultValues.salutationHonorific },
        client
      ),
      deviatingAddress: setDeviatingAddress,
    };
  }

  if (crudMode === EDIT_MODE && isFromOutgoingInvoice) {
    return {
      ...defaultValues,
      ...deliveryNote,
      salutationHonorific: getSalutationHonorific(
        'defaultDeliveryNoteSalutationDefined',
        { salutation: deliveryNote.salutationHonorific || defaultValues.salutationHonorific },
        client
      ),
      deliveryNoteSignature: deliveryNote.pdfSignature,
      deviatingAddress: setDeviatingAddress,
    };
  }

  if (crudMode === EDIT_MODE && isFromDuplicate) {
    return {
      ...defaultValues,
      ...deliveryNote,
      salutationHonorific: getSalutationHonorific(
        'defaultDeliveryNoteSalutationDefined',
        { salutation: deliveryNote.salutationHonorific || defaultValues.salutationHonorific },
        client
      ),
      deliveryNoteSignature: deliveryNote.pdfSignature,
      deviatingAddress: setDeviatingAddress,
    };
  }

  if (crudMode === EDIT_MODE) {
    return {
      ...defaultValues,
      ...deliveryNote,
      salutationHonorific: getSalutationHonorific(
        'defaultDeliveryNoteSalutationDefined',
        { salutation: deliveryNote.salutationHonorific || defaultValues.salutationHonorific },
        client
      ),
      deliveryNoteSignature: deliveryNote.pdfSignature,
      deviatingAddress: setDeviatingAddress,
    };
  }

  return {
    ...pickBy(defaultValues, isNotUndefined),
    ...pickBy(state.deliveryNote.details, isNotUndefined),
  };
};
