import React from 'react';
import { node, string } from 'prop-types';

import styles from '../List.module.css';

const ListHeader = ({ icon, title }) => (
  <span className={styles.listItemHeader}>
    {icon} {title}
  </span>
);

ListHeader.propTypes = {
  icon: node,
  title: string.isRequired,
};

export default ListHeader;
