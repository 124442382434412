import React from 'react';
import InlineSvg from 'react-inlinesvg';
import { string } from 'prop-types';

import ConfirmationIcon from 'images/icon-confirmation.svg';
import { t } from 'shared/utils';

import styles from './NextPlanDate.module.css';

const NextPlanDate = ({ nextPlanValidFrom }) => (
  <div className={styles.boxContainer}>
    <div className={styles.iconContainer}>
      <InlineSvg src={ConfirmationIcon} />
    </div>
    <div className={styles.content}>
      <span>{t('profile.payment_plan.debit.plan_valid_from', { date: nextPlanValidFrom })}</span>
    </div>
  </div>
);

NextPlanDate.propTypes = {
  nextPlanValidFrom: string.isRequired,
};

export default NextPlanDate;
