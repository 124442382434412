import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { isArray } from 'lodash';
import PropTypes from 'prop-types';

import { fetchContent } from 'actions/contents';
import LegalsComponent from 'components/Legals';

import CookieLink from './CookieLink';
import { shouldRenderContentLink } from './utils';

import styles from './LegalsFooter.module.css';

class LegalsFooter extends Component {
  state = {
    isOpen: false,
    currentOpenContent: {},
    contentsData: [],
  };

  componentDidMount() {
    const { fetchContents } = this.props;
    // this one is required for the case, when user logouts - redux state being reseted
    fetchContents().then(({ data }) => this.setState({ contentsData: data }));
  }

  handleModalOpen = (slug, title, body) => {
    this.setState({
      isOpen: true,
      currentOpenContent: {
        slug,
        title,
        body,
      },
    });
  };

  handleModalClose = () =>
    this.setState({
      isOpen: false,
      currentOpenContent: {},
    });

  renderContentLink = (content) => {
    const { web, slug, title, body } = content.attributes;
    const { authenticated } = this.props;
    const isPublic = content.attributes.public;
    const showOnFooter = content.attributes['show-on-footer'];

    return (
      shouldRenderContentLink(web, showOnFooter, isPublic, authenticated) && (
        <button
          key={slug}
          data-id={`LegalsFooter:button(${slug})`}
          onClick={() => this.handleModalOpen(slug, title, body)}
          className={styles.link}
        >
          {title}
        </button>
      )
    );
  };

  render() {
    const { currentOpenContent, isOpen, contentsData } = this.state;
    const { authenticated, absolutePosition } = this.props;
    const footerClass = cx(styles.footer, {
      [styles.loginPage]: !authenticated,
      [styles.authenticated]: authenticated,
      [styles.absolutePosition]: absolutePosition,
    });

    return (
      <div className={footerClass}>
        <div className={styles.linksWrapper}>
          {isArray(contentsData) && contentsData.map(this.renderContentLink)}
          <CookieLink />
        </div>
        {isOpen && (
          <LegalsComponent
            {...currentOpenContent}
            isOpen={isOpen}
            onClose={this.handleModalClose}
            isModal
          />
        )}
      </div>
    );
  }
}

LegalsFooter.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  absolutePosition: PropTypes.bool.isRequired,
  fetchContents: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  fetchContents: () => dispatch(fetchContent()),
});

export default connect(null, mapDispatchToProps)(LegalsFooter);
