import { createAsyncThunk } from '@reduxjs/toolkit';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import * as api from 'api/me/dashboard';
import EntityPath from 'constants/entitiesPaths';
import { RootState } from 'store';
import { Dashboard } from 'types/entities/Dashboard';

import { name } from './constants';

export const fetchDashboard = createAsyncThunk<
  Dashboard,
  void,
  {
    state: RootState;
  }
>(
  `${name}/fetch`,
  async () => {
    const response = await api.getDashboard();
    const dashboard = build<Dashboard>(
      normalize(response.data),
      EntityPath.CustomerWidgets,
      response.data.data.id,
      {
        ignoreLinks: true,
      }
    );

    return dashboard;
  },
  {
    condition: (_, { getState }) => {
      const { dashboard } = getState();

      return !dashboard.isLoading;
    },
  }
);

export const updateDashboard = createAsyncThunk<Dashboard, api.UpdateDashboardData>(
  `${name}/update`,
  async (data: api.UpdateDashboardData) => {
    const response = await api.updateDashboard(data);
    const dashboard = build<Dashboard>(
      normalize(response.data),
      EntityPath.CustomerWidgets,
      response.data.data.id
    );

    return dashboard;
  }
);
