import React from 'react';
import { snakeCase } from 'lodash';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';

const FormField = ({ name, label = '', placeholder = '', readonly = false, value, ...rest }) => (
  <Field
    id={name}
    name={name}
    disabled={readonly}
    label={rest.isLabelHidden ? '' : label || t(`form.fields.${snakeCase(name)}`)}
    placeholder={rest.isPlaceholderHidden ? '' : placeholder || t(`form.fields.${snakeCase(name)}`)}
    checker={checker}
    value={value}
    {...rest}
  />
);

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
};

export default FormField;
