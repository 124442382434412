import React from 'react';
import { Transition } from 'react-transition-group';
import cx from 'classnames';
import { snakeCase } from 'lodash';
import { flatten, flow, map, mapValues, pick, values } from 'lodash/fp';
import { arrayOf, bool, func, number as numberType, oneOfType, shape, string } from 'prop-types';

import { formatDate } from 'shared/utils';
import { ResourceType } from 'types/entities/AssignableResource';
import Amount from 'components/Amount';
import BankTransferStatusBadge from 'components/BankTransferStatusBadge/BankTransferStatusBadge';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import I18n from 'components/I18n';

import AssignmentSection from './AssignmentSection';
import ActionButton from './components/ActionButton';
import DisplayConnections from './components/DisplayConnections';

import styles from '../Table.module.css';
import rowStyles from './BankTransferRow.module.css';

const mapValuesWithKey = mapValues.convert({ cap: false });
const normalizeKey = (key) => snakeCase(key).replace(/s$/, '');
const attachKeyToObjectValue = (value, key) =>
  value.map((v) => ({ type: normalizeKey(key), ...v }));

const mapResourceType = map((resource) => ({
  ...resource,
  resourceType: {
    outgoing_invoice: ResourceType.OutgoingInvoice,
    incoming_invoice: ResourceType.IncomingInvoice,
    contract_installment: ResourceType.ContractInstallment,
  }[resource.type],
}));

const normalizeResources = (wantedTypes = []) =>
  flow(
    pick(wantedTypes),
    mapValuesWithKey(attachKeyToObjectValue),
    map(values),
    flatten,
    mapResourceType
  );

const getConnectedResources = normalizeResources([
  'incomingInvoices',
  'outgoingInvoices',
  'contractInstallments',
]);

const removeSuggestedPrefix = ({ type, ...rest }) => ({
  ...rest,
  type: type.replace(/suggested_unassigned_/g, ''),
});

const getSuggestedInvoices = (type) =>
  flow(normalizeResources([type]), map(removeSuggestedPrefix), mapResourceType);

const getSuggestedOutgoingInvoices = getSuggestedInvoices('suggestedUnassignedOutgoingInvoices');
const getSuggestedIncomingInvoices = getSuggestedInvoices('suggestedUnassignedIncomingInvoices');

const BankTransferRow = ({
  index,
  isFetchingFromAllAcounts = false,
  item,
  actions: {
    connectResource,
    disconnectResource,
    rejectConnectionSuggestion,
    createLabel,
    deleteLabel,
  },
  isOpen,
  toggle,
  bankTransferConnections,
  labels,
}) => {
  const connectedResources = getConnectedResources(item);
  const suggestedOutgoingInvoices = getSuggestedOutgoingInvoices(item);
  const suggestedIncomingInvoices = getSuggestedIncomingInvoices(item);
  const isBankTransferPositive = item.amount >= 0;
  const isFullyAsigned = item.status === 'fully-assigned';
  const hasConnectedLabels = Boolean(labels.length);
  const connectedLabelsAmount = labels.length;
  const hasConnectedResources = Boolean(connectedResources.length);
  const connectedResourcesAmount = connectedResources.length;
  const suggestedInvoices = [...suggestedOutgoingInvoices, ...suggestedIncomingInvoices];

  return (
    <>
      <tr
        className={rowStyles.rowUpper}
        data-id={`BankTransferAssignment:bank-transfer-row_${formatDate(item.date)}`}
      >
        <td className={styles.column}>
          <div className={styles.cell}>
            <I18n t="bank_transfers.table.columns.date" className={styles.cellHeader} />
            <div data-id="BankTransferAssignment:bank-transfer-date" className={styles.cellContent}>
              {formatDate(item.date)}
            </div>
          </div>
        </td>
        {isFetchingFromAllAcounts && (
          <td className={styles.column}>
            <div className={styles.cell}>
              <I18n t="bank_transfers.table.columns.account" className={styles.cellHeader} />
              <CellContentWithTooltip dataID="BankTransferAssignment:bank-transfer-account-name">
                {item.bankAccountName}
              </CellContentWithTooltip>
            </div>
          </td>
        )}
        <td className={styles.column}>
          <div className={styles.cell}>
            <I18n t="bank_transfers.table.columns.creditor_name" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="BankTransferAssignment:bank-transfer-creditor-name">
              {item.creditorName || '-'}
            </CellContentWithTooltip>
          </div>
        </td>
        <td className={styles.column}>
          <div className={styles.cell}>
            <I18n t="bank_transfers.table.columns.purpose" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="BankTransferAssignment:bank-transfer-purpose">
              {item.purpose || '-'}
            </CellContentWithTooltip>
          </div>
        </td>
        <td className={styles.columnCenter}>
          <div className={styles.cell}>
            <I18n t="bank_transfers.table.columns.amount_cents" className={styles.cellHeader} />
            <div className={styles.cellContent}>
              <Amount
                dataId="BankTransferAssignment:bank-transfer-amount"
                amount={item.amount}
                currency={item.currency}
              />
            </div>
          </div>
        </td>
        <td className={styles.column}>
          <div className={styles.cell}>
            <div className={cx(styles.cellContent, styles.statusCell)}>
              <BankTransferStatusBadge
                dataId="BankTransferAssignment:bank-transfer-assignment-status"
                status={item.status}
              />

              {(hasConnectedResources || hasConnectedLabels) && (
                // INFO the container was introduced to sort out Firefox 52 alignment issue
                <div
                  data-id="BankTransferAssignment:bank-transfers-amount-of-assignments"
                  className={cx(styles.connectedResourcesAmount)}
                >
                  <DisplayConnections
                    connectedResourcesAmount={connectedResourcesAmount}
                    connectedLabelsAmount={connectedLabelsAmount}
                  />
                </div>
              )}
            </div>
          </div>
        </td>
        <td className={styles.column}>
          <div className={styles.cell}>
            <I18n
              t="bank_transfers.table.columns.invoice_assignment"
              className={styles.cellHeader}
            />
            <div className={styles.cellContentAssignment}>
              <ActionButton
                dataId={`BankTransferAssignment:button-toggle-bank-transfer-dropdown_${formatDate(
                  item.date
                )}`}
                onClick={() => toggle(item.id)}
                isOpen={isOpen}
              >
                <I18n t="bank_transfers.table.manage" />
              </ActionButton>
            </div>
          </div>
        </td>
      </tr>
      <tr className={rowStyles.rowLower}>
        <td colSpan={isFetchingFromAllAcounts ? 7 : 6}>
          {/* Timeout prop is required */}
          <Transition
            in={isOpen}
            timeout={{
              enter: 0,
              exit: 0,
            }}
            unmountOnExit
          >
            {() => (
              <div className={rowStyles.transition}>
                <AssignmentSection
                  bankTransfer={item}
                  bankTransferAmount={item.amount}
                  isFullyAsigned={isFullyAsigned}
                  connectedResources={connectedResources}
                  suggestedInvoices={suggestedInvoices}
                  bankTransferId={item.id}
                  isBankTransferPositive={isBankTransferPositive}
                  data-id={`assignment-section-${index}`}
                  connectResource={connectResource}
                  disconnectResource={disconnectResource}
                  rejectConnectionSuggestion={rejectConnectionSuggestion}
                  createLabel={createLabel}
                  deleteLabel={deleteLabel}
                  unassignedAmount={item.amountLeft}
                  bankTransferConnections={bankTransferConnections}
                  labels={labels}
                />
              </div>
            )}
          </Transition>
        </td>
      </tr>
    </>
  );
};

BankTransferRow.propTypes = {
  isFetchingFromAllAcounts: bool,
  item: shape({
    date: string.isRequired,
    creditorName: string.isRequired,
    amount: numberType.isRequired,
  }).isRequired,
  actions: shape({
    connectResource: func.isRequired,
    disconnectResource: func.isRequired,
    rejectConnectionSuggestion: func.isRequired,
    createLabel: func.isRequired,
    deleteLabel: func.isRequired,
  }).isRequired,
  isOpen: bool,
  toggle: func,
  index: numberType.isRequired,
  bankTransferConnections: oneOfType([arrayOf(shape({})), shape({})]),
  labels: oneOfType([arrayOf(shape({})), shape({})]),
};

export default BankTransferRow;
