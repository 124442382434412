import React from 'react';

import { t } from 'shared/utils';
import IconDownload from 'components/Icons/IconDownload';
import IconShow from 'components/Icons/IconShow';
import Loading from 'components/Loading';

import styles from './PreviewActions.module.css';

type PreviewActionsProps = {
  onPreviewClick: () => void;
  onContractDownloadClick?: () => void;
  contractDownloadUrl?: string;
  contractDownloadName: string;
  isDownloadingContract: boolean;
};

const PreviewActions = ({
  onPreviewClick,
  onContractDownloadClick,
  contractDownloadUrl,
  contractDownloadName,
  isDownloadingContract,
}: PreviewActionsProps) => {
  return (
    <div className={styles.previewActionsContainer}>
      <div
        onClick={onPreviewClick}
        title={t('contracts.new.preview_contract')}
        data-id="Contracts:button-previewContract"
      >
        <IconShow />
      </div>
      <a
        onClick={isDownloadingContract ? undefined : onContractDownloadClick}
        title={t('expenses.upload.browse_files')}
        data-id="Contracts:button-onDownloadContract"
        download={contractDownloadName}
        href={contractDownloadUrl}
      >
        {isDownloadingContract ? <Loading /> : <IconDownload className={styles.downloadIcon} />}
      </a>
    </div>
  );
};

export default PreviewActions;
