import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { hasAnyFiltersOrQuickFiltersActive } from 'shared/utils/hasAnyFiltersOrQuickFiltersActive';

export const useDebouncedHasAnyFilters = (filters: any): boolean => {
  const [hasAnyFiltersActive, setHasAnyFiltersActive] = useState(false);
  const debouncedSetHasAnyFiltersActive = useRef(debounce(setHasAnyFiltersActive, 10));

  useEffect(() => {
    if (debouncedSetHasAnyFiltersActive.current)
      debouncedSetHasAnyFiltersActive.current(hasAnyFiltersOrQuickFiltersActive(filters));
  }, [debouncedSetHasAnyFiltersActive, filters]);

  return hasAnyFiltersActive;
};
