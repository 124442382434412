import { combineReducers } from 'redux';

import {
  DOWNLOAD_FAILURE,
  DOWNLOAD_REQUEST,
  DOWNLOAD_SUCCESS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SUCCESS,
} from 'constants/datev-download';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case DOWNLOAD_REQUEST:
    case INDEX_REQUEST:
      return true;
    case DOWNLOAD_SUCCESS:
    case DOWNLOAD_FAILURE:
    case INDEX_SUCCESS:
    case INDEX_FAILURE:
      return false;
    default:
      return state;
  }
};

const isInvalid = (state = false, action) => {
  switch (action.type) {
    case INDEX_SUCCESS:
      return false;
    case INDEX_FAILURE:
      return true;
    default:
      return state;
  }
};

const download = (state = { error: false, isDownloadSucceded: false }, action) => {
  switch (action.type) {
    case DOWNLOAD_SUCCESS:
      return { ...state, error: false, isDownloadSucceded: true };
    case DOWNLOAD_FAILURE:
      return { ...state, error: true, isDownloadSucceded: false };
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  isInvalid,
  download,
});
