import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: min(90vw, 545px);

  p {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Content = styled.div`
  padding: 28px 20px 46px 20px;
`;
