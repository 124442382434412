import React from 'react';
import cx from 'classnames';

import isPressedEnter from 'shared/utils/keyboard-events';
import type { PreviewFile, SetIsPreviewRendering } from 'components/Dropzone/types';

import styles from '../Preview.module.css';

type PreviewIMGProps = {
  setIsPreviewRendering: SetIsPreviewRendering;
  file: PreviewFile;
  imageClassName: string;
  onClick: () => void;
};

const PreviewIMG = ({
  file = { extension: '', url: '', id: '' },
  setIsPreviewRendering,
  imageClassName = '',
  onClick,
}: PreviewIMGProps) => (
  <div role="button" tabIndex={0} onKeyPress={isPressedEnter(onClick)} onClick={onClick}>
    <img
      className={cx(styles.preview, imageClassName)}
      onLoad={() => setIsPreviewRendering(false)}
      alt=""
      src={file.url}
    />
  </div>
);

export default PreviewIMG;
