import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { formatDate, formatMoney } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';

import styles from '../BankTransfersSelect.module.css';

const BankTransferOption = ({ handleOptionSelect, ...option }) => {
  const onClick = () => {
    if (handleOptionSelect) {
      handleOptionSelect(option);
    }
  };

  const { date, amount, currency, creditorName } = option;

  return (
    <div
      className={cx('Select-option', `${styles.bankTransfer}`)}
      role="button"
      onKeyPress={isPressedEnter(() => onClick())}
      onClick={onClick}
    >
      <div className={styles.date}>{formatDate(date)}</div>
      <div className={styles.creditorName}>{creditorName}</div>
      <div className={cx(styles.amount, { [styles.amountNegative]: amount < 0 })}>
        {formatMoney(amount, currency)}
      </div>
    </div>
  );
};

BankTransferOption.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.shape({}).isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    creditorName: PropTypes.string.isRequired,
  }),
  handleOptionSelect: PropTypes.func.isRequired,
};

export default BankTransferOption;
