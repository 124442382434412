import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

export const useScrollableShades = (ref: React.RefObject<HTMLDivElement>) => {
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);

  useEffect(() => {
    const el = ref.current;

    if (!el) {
      return;
    }

    const updateShades = debounce(() => {
      if (el.clientWidth === el.scrollWidth) {
        setLeft(false);
        setRight(false);
      }

      setLeft(el.scrollLeft > 0);
      setRight(el.scrollLeft + el.clientWidth < el.scrollWidth);
    }, 20);

    updateShades();

    el.addEventListener('scroll', updateShades);
    window.addEventListener('resize', updateShades);

    return () => {
      el.removeEventListener('scroll', updateShades);
      window.addEventListener('resize', updateShades);
    };
  }, [ref]);

  return { left, right };
};
