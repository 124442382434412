import React from 'react';

const IconMore = () => (
  <svg width="3" height="11">
    <g transform="translate(-9 -4)" fill="none">
      <rect width="20" height="20" rx="1" />
      <circle cx="1.5" cy="1.5" r="1.5" transform="translate(9 4)" />
      <circle cx="1.5" cy="1.5" r="1.5" transform="translate(9 8)" />
      <circle cx="1.5" cy="1.5" r="1.5" transform="translate(9 12)" />
    </g>
  </svg>
);

export default IconMore;
