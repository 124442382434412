import React, { SVGAttributes } from 'react';

const IconRefresh = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 18 18" {...props}>
      <path
        d="M2.343 10.125H0v5.625l1.665-1.53a9.001 9.001 0 0016.265-4.095h-2.272A6.754 6.754 0 019 15.75a6.742 6.742 0 01-5.659-3.071l2.779-2.554H2.344zM9 0A9.001 9.001 0 00.07 7.875h2.272A6.755 6.755 0 019 2.25a6.743 6.743 0 015.764 3.236l-2.389 2.389H18V2.25l-1.611 1.611A8.987 8.987 0 009 0z"
        fill="#0063BB"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default IconRefresh;
