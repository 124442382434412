import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import * as contractsInstallmentsAPI from 'api/me/contracts/installments';
import { DATE_FORMAT_FULL_REVERSED_UNICODE } from 'constants/datetime';
import EntityPath from 'constants/entitiesPaths';
import type { ExpenseContract, RevenueContract } from 'types/entities/Contract';
import type { ExpenseInstallment, RevenueInstallment } from 'types/entities/Installment';
import Loading from 'components/Loading';

import Form from './Form';
import NoInstallments from './NoInstallments';
import { Wrapper } from './Shared.styled';

type ChooseInstallmentProps = {
  contract: ExpenseContract | RevenueContract;
  onCancel: () => void;
  onSuccess: (installment: ExpenseInstallment | RevenueInstallment) => void;
};

const ChooseInstallment = ({ contract, onCancel, onSuccess }: ChooseInstallmentProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [installments, setInstallments] = useState<Array<ExpenseInstallment | RevenueInstallment>>(
    []
  );

  useEffect(() => {
    const fetch = async () => {
      const response = await contractsInstallmentsAPI.getInstallments(contract.id, {
        page: 1,
        per_page: 3,
        filters: {
          transaction_date_lte: format(new Date(), DATE_FORMAT_FULL_REVERSED_UNICODE),
        },
      });
      const installments =
        build<ExpenseInstallment | RevenueInstallment>(
          normalize(response.data),
          EntityPath.ContractInstallments
        ) || [];

      setInstallments(installments);
      setIsLoading(false);
    };

    setIsLoading(true);
    fetch();
  }, [contract]);

  if (isLoading) {
    return <Wrapper isLoading>{isLoading && <Loading />}</Wrapper>;
  }

  return installments.length === 0 ? (
    <NoInstallments onSuccess={onCancel} />
  ) : (
    <Form onCancel={onCancel} onSuccess={onSuccess} installments={installments} />
  );
};

export default ChooseInstallment;
