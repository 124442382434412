import { keyBy } from 'lodash';

import {
  CREATE_SUCCESS,
  DELETE_SUCCESS,
  FETCH_OUT_OF_SYNC_SUCCESS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SUCCESS,
  UPDATE_SUCCESS,
} from 'constants/bank-account';

const defaultState = {
  data: [],
  outOfSync: {},
  error: false,
  isFetching: false,
  isFetched: false,
};

export const getOutOfSyncAccounts = (state) =>
  state.outOfSync.ids.map((id) => state.outOfSync.byId[id]);

export const bankAccounts = (state, action) => {
  switch (action.type) {
    case INDEX_REQUEST: {
      return {
        ...defaultState,
        isFetching: true,
      };
    }
    case INDEX_SUCCESS:
      return {
        ...defaultState,
        data: action.response.data.map((b) => ({
          ...b.attributes,
          id: b.id,
        })),
        isFetching: false,
        isFetched: true,
      };
    case INDEX_FAILURE:
      return {
        ...defaultState,
        isFetching: false,
        isFetched: true,
      };
    case CREATE_SUCCESS:
      return {
        ...defaultState,
        data: [...state.data, { ...action.response.data.attributes, id: action.response.data.id }],
      };
    case UPDATE_SUCCESS: {
      return {
        ...defaultState,
        data: [
          ...state.data.filter((b) => b.id !== action.response.data.id),
          { ...action.response.data.attributes, id: action.response.data.id },
        ],
      };
    }
    case DELETE_SUCCESS:
      return {
        ...defaultState,
        data: state.data.filter((b) => b.id !== action.response.data.id),
      };
    default:
      return state;
  }
};

export const accountsOfOutSync = (state, action) => {
  switch (action.type) {
    case FETCH_OUT_OF_SYNC_SUCCESS:
      return {
        ...state,
        outOfSync: {
          ids: action.response.data.map((e) => e.id),
          byId: keyBy(
            action.response.data.map((e) => ({ ...e.attributes, id: e.id })),
            'id'
          ),
        },
      };
    default:
      return state;
  }
};

export default (state = defaultState, action) => {
  if (!action.type) {
    return state;
  }
  if (action.type.includes('FETCH_OUT_OF_SYNC')) {
    return accountsOfOutSync(state, action);
  }
  return bankAccounts(state, action);
};
