import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { change, WrappedFieldProps } from 'redux-form';

import { CrudMode, EDIT_MODE, NEW_MODE, SHOW_MODE } from 'constants/common/crud';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import { CategoryResponse } from 'types/entities/Category';
import { ContractSource } from 'types/entities/Contract';
import { TaxOption } from 'types/entities/TaxRate';
import CategorySearch from 'components/CategorySearch/CategorySearch';
import { CurrencyField, DateField, FormField, SelectField, TextField } from 'components/Form';
import {
  getCategory,
  getDefaultCategory,
  getDefaultTaxRate,
  getTaxRatesOptions,
  taxOptions,
} from 'components/LineItems/utils';
import {
  FORM_NAME,
  NET_AMOUNT,
  RECURRING_TRANSACTION_INTERVAL_ID,
  SUBJECT,
  TRANSACTION_DATE,
} from 'features/contracts/constants';
import { getRevenueLineCategories } from 'features/contracts/contractsSlice';

import ClientSection from './ClientSection';

import styles from '../Fields.module.css';

type BasicRevenueProps = {
  readonly: boolean;
  crudMode: CrudMode;
  contractSource?: ContractSource;
  intervalOptions: { label: any; value: string }[];
  lineCategories: CategoryResponse[];
  selectedCategory: CategoryResponse;
  initialValues: any;
};

const BasicRevenue = ({
  intervalOptions,
  readonly,
  crudMode,
  contractSource,
  lineCategories,
  selectedCategory,
  initialValues,
}: BasicRevenueProps) => {
  const dispatch = useAppDispatch();
  const defaultCategory = getDefaultCategory(lineCategories);
  const [category, setCategory] = useState<CategoryResponse>();
  const [taxRates, setTaxRates] = useState<TaxOption[] | undefined>();
  const [isInitial, setIsInitial] = useState(true);
  const initialCategory = getCategory(lineCategories, selectedCategory);

  const handleSelectCategory = (item: any) => {
    if (item?.id) {
      setCategory(item);
      setTaxRates(getTaxRatesOptions(item.taxRates));

      dispatch(change(FORM_NAME, 'taxRateId', getDefaultTaxRate(item)?.id));
      dispatch(change(FORM_NAME, 'invoiceLineCategoryId', item?.id));
      dispatch(change(FORM_NAME, 'vat', getDefaultTaxRate(item?.taxRates)?.value));
    }
  };

  const manualHandleSelectCategory = (item: any) => {
    setIsInitial(false);
    handleSelectCategory(item);
  };

  const setInitialData = () => {
    if (crudMode === SHOW_MODE && initialCategory) {
      setCategory(initialCategory);
      setTaxRates(getTaxRatesOptions(initialCategory?.taxRates));

      dispatch(change(FORM_NAME, 'taxRateId', initialValues?.taxRateId));
      dispatch(change(FORM_NAME, 'invoiceLineCategoryId', initialValues?.invoiceLineCategoryId));
      dispatch(change(FORM_NAME, 'vat', initialValues?.vat));
    }

    if (crudMode === SHOW_MODE && isEmpty(initialCategory)) {
      setCategory(undefined);
    }
  };

  useEffect(() => {
    dispatch(getRevenueLineCategories());
  }, [dispatch]);

  useEffect(() => {
    setInitialData();
    if (crudMode === NEW_MODE && isInitial) {
      handleSelectCategory(defaultCategory);
    }

    if (crudMode === EDIT_MODE && isEmpty(initialCategory) && isInitial) {
      handleSelectCategory(defaultCategory);
    }
  }, [initialValues, lineCategories, crudMode]);

  return (
    <>
      <FormField
        dataId="Contracts:input-subject"
        name={SUBJECT}
        component={TextField}
        disabled={readonly}
        required
      />
      <FormField
        dataId="Contracts:input-FirstTransaction"
        name={TRANSACTION_DATE}
        dataIds={{
          input: 'Contracts:input-FirstTransaction',
        }}
        component={DateField}
        disabled={readonly}
        required
      />
      <ClientSection
        required
        className={styles.supplier}
        name="client"
        label={t('form.fields.payer')}
        readonly={
          // TODO it will be just readonly. This form will not be available in edit mode for VRSO contracts.
          readonly || (crudMode === EDIT_MODE && contractSource === ContractSource.Vrso)
        }
      />
      <FormField
        name={RECURRING_TRANSACTION_INTERVAL_ID}
        component={SelectField}
        dataId="Contracts:dropdown-interval"
        label={t('form.fields.interval')}
        options={intervalOptions}
        disabled={readonly}
        checker={checker}
        required
      />
      <CurrencyField
        dataId="Contracts:input-netAmount"
        label={t('form.fields.net_amount')}
        name={NET_AMOUNT}
        component={TextField}
        disabled={readonly}
        precision={2}
        required
      />
      <FormField
        name="invoiceLineCategoryId"
        component={({ input: { onChange, value }, meta: { error } }: WrappedFieldProps) => (
          <CategorySearch
            dataId="Contracts:select-category"
            categories={lineCategories}
            selectedItem={category || { ...value }}
            disabled={readonly}
            invalid={error}
            label={`${t('recurring_expenses.form.fields.category')} *`}
            onSelectCategory={manualHandleSelectCategory || onChange}
            withIcon={!readonly}
            initialSelectedItem={category}
            initialInputValue={category?.name}
          />
        )}
      />
      {!initialCategory ? (
        <FormField
          name="vat"
          options={taxOptions.map((o) => ({ value: o.value, label: o.label }))}
          component={SelectField}
          readonly={readonly}
          dataId="Contracts:input-vat"
          required
        />
      ) : (
        <FormField
          dataId="Contracts:input-taxRateId"
          name="taxRateId"
          options={taxRates}
          component={SelectField}
          readonly={readonly}
          required
        />
      )}
    </>
  );
};

export default BasicRevenue;
