import React, { FocusEvent, KeyboardEvent, useCallback, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import IconArrowDown from 'redesign/components/atoms/Icons/ArrowDown';

import InputField from '../InputField/InputField';
import { GotoLastPageButton, NextButton, PreviousButton, Wrapper } from './PagePicker.styled';

export type PagePickerProps = {
  page: number;
  onChange: (page: number) => void;
  totalPages: number;
  disabled?: boolean;
  className?: string;
};

const PagePicker = ({ page, totalPages, onChange, disabled, className }: PagePickerProps) => {
  const [value, setValue] = useState(`${page}`);

  const handlePreviousPage = useCallback(() => {
    onChange(page - 1);
  }, [page, onChange]);

  const handleNextPage = useCallback(() => {
    onChange(page + 1);
  }, [page, onChange]);

  const handleGoToLastPage = useCallback(() => {
    onChange(totalPages);
  }, [totalPages, onChange]);

  const calculateNextPage = useCallback(
    (event: FocusEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>) => {
      const value = Number((event.target as HTMLInputElement).value);

      if (value === page) return;

      if (isNaN(value) || !Number.isInteger(value)) {
        setValue(`${page}`);
        return;
      }

      let newPage = value <= 1 ? 1 : value > totalPages ? totalPages : value;

      if (newPage !== page) {
        onChange(newPage);
        setValue(`${newPage}`);
      } else {
        setValue(`${page}`);
      }
    },
    [page, totalPages, onChange]
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter') return;

      calculateNextPage(event);
    },
    [calculateNextPage]
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      calculateNextPage(event);
    },
    [calculateNextPage]
  );

  const handleChange = useCallback((event: FocusEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  useUpdateEffect(() => {
    setValue(`${page}`);
  }, [page]);

  return (
    <Wrapper className={className}>
      <PreviousButton onClick={handlePreviousPage} disabled={disabled || page === 1}>
        <IconArrowDown />
      </PreviousButton>
      <InputField
        type="number"
        value={value}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        onChange={handleChange}
      />
      /
      <GotoLastPageButton onClick={handleGoToLastPage} disabled={page === totalPages}>
        {totalPages}
      </GotoLastPageButton>
      <NextButton onClick={handleNextPage} disabled={disabled || page === totalPages}>
        <IconArrowDown />
      </NextButton>
    </Wrapper>
  );
};

export default PagePicker;
