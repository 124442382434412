import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import { fetchOrderConfirmation as fetchOrderConfirmationAction } from 'actions/order-confirmation';
import { fetchOrderConfirmationLineItems as fetchOrderConfirmationLineItemsAction } from 'actions/order-confirmation/line-items';
import { EDIT_MODE } from 'constants/common/crud';
import { FORM_NAME, FROM_DUPLICATE_QUERY_PARAM } from 'constants/order-confirmation';
import paths from 'routes/paths';
import { withTransitionPrevent, withWarningAlert } from 'shared/hoc';
import { t } from 'shared/utils';
import useLineItemsControl, { SectionType } from 'components/LineItems/useLineItemControl';
import LoadingIcon from 'components/LoadingIcon';
import OrderConfirmationCreator from 'features/orderConfirmation/OrderConfirmationCreator/OrderConfirmationCreator';

const NewOrderConfirmation = ({
  fetchOrderConfirmation,
  fetchOrderConfirmationLineItems,
  location,
  canEdit,
  isFetching,
  isFetched,
  history,
  match,
}) => {
  const {
    manualHandleSelectCategory,
    setLineItemState,
    setShowCategoryChangeConfirmation,
    setShowDifferentCategoryWarning,
    setShowVatIdWarning,
    showDifferentCategoryWarning,
    showCategoryChangeConfirmation,
    defaultCategory,
    isDeprecatedCategory,
    canSubmit,
    showVatIdWarning,
    category,
    taxRates,
    taxRateId,
    defaultVat,
    hasSavedLineItems,
    selectedCategory,
    lineCategories,
    isInitial,
  } = useLineItemsControl({ sectionType: SectionType.orderConfirmation, formName: FORM_NAME });

  const isFromDuplicate = location.search.includes(FROM_DUPLICATE_QUERY_PARAM);

  useEffect(() => {
    fetchOrderConfirmation(match.params.id);
    fetchOrderConfirmationLineItems(match.params.id);
  }, []);

  if (isFetching) return <LoadingIcon />;
  if (!isFetched) return null;
  if (!canEdit) {
    history.push(paths.showOrderConfirmation(match.params.id));
    return null;
  }

  return (
    <OrderConfirmationCreator
      isFromDuplicate={isFromDuplicate}
      crudMode={EDIT_MODE}
      lineCategories={lineCategories}
      hasSavedLineItems={hasSavedLineItems}
      selectedCategory={selectedCategory}
      defaultCategory={defaultCategory}
      isDeprecatedCategory={isDeprecatedCategory}
      handleSelectCategory={manualHandleSelectCategory}
      category={category}
      showCategoryChangeConfirmation={showCategoryChangeConfirmation}
      setShowCategoryChangeConfirmation={setShowCategoryChangeConfirmation}
      taxRateId={taxRateId}
      taxRates={taxRates}
      defaultVat={defaultVat}
      isInitial={isInitial}
      canSubmit={canSubmit}
      setLineItemState={setLineItemState}
      showVatIdWarning={showVatIdWarning}
      setShowVatIdWarning={setShowVatIdWarning}
      showDifferentCategoryWarning={showDifferentCategoryWarning}
      setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
    />
  );
};

const mapStateToProps = (state) => ({
  isReadonly: (fieldName) => {
    if (state.orderConfirmation.details.draft) return false;

    const editableFields = [];
    return !editableFields.includes(fieldName);
  },
  isFetching: state.orderConfirmation.isFetching,
  isFetched: state.orderConfirmation.isFetched,
  canEdit: get(state, 'orderConfirmation.meta.actions.edit', false),
  buttonLabel: t(
    'empty_entry_pages.order_confirmations.order_confirmation_warning_box.button_label'
  ),
  content: t('empty_entry_pages.order_confirmations.order_confirmation_warning_box.content_1'),
  dataId: 'OrderConfirmations:orange-warning-container',
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrderConfirmation: (id) => dispatch(fetchOrderConfirmationAction(id)),
  fetchOrderConfirmationLineItems: (id) => dispatch(fetchOrderConfirmationLineItemsAction(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    persistentSubmitErrors: true,
  }),
  withTransitionPrevent(),
  withWarningAlert
)(NewOrderConfirmation);
