import React from 'react';
import { Row } from 'react-flexbox-grid';
import cx from 'classnames';
import { node, string } from 'prop-types';

import localStyles from './TableHeader.module.css';

export const TableHeader = ({ className, children, ...rest }) => (
  <Row {...rest} className={cx(className, localStyles.headerRow)}>
    {children}
  </Row>
);

TableHeader.propTypes = {
  className: string,
  children: node,
  size: string,
  align: string,
};
