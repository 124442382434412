import styled from 'styled-components';

import BaseButton from 'redesign/components/atoms/Button/Button';

interface IBodyProps {
  center: boolean;
  isLoading: boolean;
}

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  margin-bottom: ${({ theme }) => theme.space['5']};
  width: 100%;
`;

export const Body = styled.div<IBodyProps>`
  display: block;
  padding: ${({ theme }) => theme.space['4']} 0;
  height: ${(props) => (props.center ? 'auto' : '100%')};
  opacity: ${(props) => (props.isLoading ? '15%' : '100%')};
`;

export const LoadingWrapper = styled.div`
  position: absolute;
`;

export const Image = styled.div`
  display: inline-grid;
  min-height: 200px;

  svg {
    margin: auto;
  }
`;

export const Header = styled.h3`
  color: ${({ theme }) => theme.colors.grey['080']};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: bold;
  margin-top: ${({ theme }) => theme.space['6']};
  margin-bottom: ${({ theme }) => theme.space['2']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  line-height: ${({ theme }) => theme.space['6']};
`;

export const Info = styled.p`
  color: ${({ theme }) => theme.colors.grey['080']};
  font-size: ${({ theme }) => theme.fontSize.base};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  line-height: ${({ theme }) => theme.space['6']};
  margin-bottom: 60px;
`;

export const ButtonWrapper = styled.div`
  display: inline-grid;
`;

export const Button = styled(BaseButton)`
  margin: 10px;
`;
