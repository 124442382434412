import React from 'react';
import { bool, shape, string } from 'prop-types';

import HeaderRow from './HeaderRow';
import OptionRow from './OptionRow';

const Section = ({ header, options }) => (
  <>
    {header && <HeaderRow header={header} />}
    {options.map(({ title, isPremium, isHeader }) =>
      isHeader ? (
        <HeaderRow title={title} key={title} />
      ) : (
        <OptionRow title={title} isPremium={isPremium} key={title} />
      )
    )}
  </>
);

Section.propTypes = {
  header: string,
  options: shape({
    title: string.isRequired,
    isPremium: bool,
  }),
};

export default Section;
