import { t } from 'shared/utils';

export const getUpdateSuccess = (section) => ({
  duration: 3000,
  title: t('invoice_templates.defaults_update_success', { section }),
  variant: 'success',
});

export const getResetSuccess = (section) => ({
  duration: 3000,
  title: t('invoice_templates.defaults_reset_success', { section }),
  variant: 'success',
});
