import React from 'react';
import PropTypes from 'prop-types';

import styles from './Column.module.css';

const Column = ({ className, children, ...rest }) => (
  <td {...rest} className={styles.column}>
    <div className={className}>{children}</div>
  </td>
);

Column.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
  align: PropTypes.string,
};

export default Column;
