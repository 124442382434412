import values from 'lodash/values';

import {
  FETCH_BANK_ACCOUNTS_FAILURE,
  FETCH_BANK_ACCOUNTS_REQUEST,
  FETCH_BANK_ACCOUNTS_SUCCESS,
} from 'constants/incoming-invoice/payments';

export const DEFAULT_STATE = {
  list: [],
  isFetching: false,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_BANK_ACCOUNTS_REQUEST:
      return { ...state, isFetching: true };
    case FETCH_BANK_ACCOUNTS_SUCCESS:
      const accounts = values(action.normalizedResponse.bankAccounts).map(
        ({ id, attributes, meta }) => ({
          id,
          ...attributes,
          ...meta,
        })
      );
      return { ...state, list: accounts, isFetching: false };
    case FETCH_BANK_ACCOUNTS_FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};
