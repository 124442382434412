import styled, { css } from 'styled-components';

type Error = { startDate: boolean; endDate: boolean };
type DateRangePickerProps = {
  $error: Error;
};

export const DateRangePickerRedesign = styled.div<DateRangePickerProps>`
  .DateRangePicker_picker {
    z-index: 10;
    box-shadow: 0px 10px 15px -5px;
  }

  .DateRangePickerInput {
    height: 40px;
    align-items: center;
    border-radius: 3px;
    border: 1px solid ${({ theme }) => theme.colors.vrblue['100']};
    white-space: nowrap;

    :hover {
      box-shadow: ${({ theme }) => theme.colors.grey['050']} 1px 1px 2px 0px;
    }

    :focus-within {
      border-color: rgb(0, 102, 179);
      box-shadow: ${({ theme }) => theme.colors.grey['050']} 1px 1px 2px 0px;
    }

    svg {
      height: 20px;
    }
  }

  .DateInput {
    height: 35px;
    width: 105px;

    :last-child > input {
      text-align: end;
    }

    > input {
      height: 38px;
      font-size: 16px;
    }

    ${({ $error }) =>
      $error.startDate &&
      css`
        > input[aria-label='Start Datum'] {
          color: ${({ theme }) => theme.colors.red['100']};
        }
      `}

    ${({ $error }) =>
      $error.endDate &&
      css`
        > input[aria-label='End Datum'] {
          color: ${({ theme }) => theme.colors.red['100']};
        }
      `}

      font-size: ${({ theme }) => theme.fontSize.base};

    > svg {
      z-index: 11;
    }
  }

  .DateInput_input__focused {
    border-bottom: 3px solid ${({ theme }) => theme.colors.vrblue['100']};
  }
`;

export const DateRangePickerLegacy = styled.div<DateRangePickerProps>`
  min-height: ${({ theme }) => theme.space['18']};

  label {
    font-size: 0.625rem;
    color: ${({ theme }) => theme.colors.grey['050']};
    width: 100%;
    margin: 0;
    padding-top: ${({ theme }) => theme.space['1']};
    pointer-events: none;
  }

  .DateRangePicker_picker {
    z-index: 10;
    box-shadow: 0px 10px 15px -5px;
  }

  .DateRangePickerInput {
    height: 28px;
    align-items: center;
    border: none;
    border-bottom: 1px solid
      ${({ $error, theme }) =>
        $error.startDate || $error.endDate ? theme.colors.red['100'] : theme.colors.grey['020']};
    border-radius: 0;

    .DateRangePickerInput_arrow {
      vertical-align: top;
    }

    svg {
      height: 20px;
      margin: 0 4px;
    }
  }

  .DateInput {
    height: 23px;
    width: 82px;

    > input {
      font-size: ${({ theme }) => theme.fontSize.base};
      padding: 0;
      height: 26px;
      text-align: center;
    }

    ${({ $error }) =>
      $error.startDate &&
      css`
        > input[aria-label='Start Datum'] {
          color: ${({ theme }) => theme.colors.red['100']};
        }
      `}

    ${({ $error }) =>
      $error.endDate &&
      css`
        > input[aria-label='End Datum'] {
          color: ${({ theme }) => theme.colors.red['100']};
        }
      `}

    > svg {
      top: 44px !important;
      z-index: 11;
    }
  }

  .DateInput_input__focused {
    border-bottom: 3px solid ${({ theme }) => theme.colors.vrblue['100']};
  }

  .DateRangePickerInput__showClearDates {
    padding-right: 0;
  }

  .DateRangePickerInput_clearDates {
    right: -25%;
    padding: 5px;
  }

  .DateRangePickerInput_clearDates_svg {
    fill: ${({ theme }) => theme.colors.vrblue['100']};
    width: 17px;
  }

  .DateRangePickerInput_clearDates_default:focus,
  .DateRangePickerInput_clearDates_default:hover {
    background: ${({ theme }) => theme.colors.white};
  }
`;

export const WarningMessage = styled.p`
  margin-top: 3px;
  color: ${({ theme }) => theme.colors.red['100']};
  font-size: 0.75rem;
`;
