import React from 'react';
import { string } from 'prop-types';

const BillingData = ({ name, street, zipcode, city }) => (
  <>
    <div>{name}</div>
    <div>{street}</div>
    <div>{`${zipcode} ${city}`}</div>
  </>
);

BillingData.propTypes = {
  name: string,
  street: string,
  zipcode: string,
  city: string,
};

export default BillingData;
