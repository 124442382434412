import { useCallback, useState } from 'react';

export const useCookies = () => {
  const accepted = localStorage.getItem('cookies') === 'true';
  const preselected = { statistic: localStorage.getItem('cookies:statistic') === 'true' };
  const [show, setShow] = useState(!accepted);

  const handleSave = useCallback((selected: { statistic: boolean }) => {
    localStorage.setItem('cookies', 'true');

    if (selected.statistic) {
      localStorage.setItem('cookies:statistic', 'true');
    } else {
      localStorage.removeItem('cookies:statistic');
    }

    setShow(false);
    window.location.reload();
  }, []);

  const showModal = useCallback(() => setShow(true), []);

  return [show, preselected, showModal, handleSave] as const;
};
