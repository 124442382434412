import { WrappedFieldProps } from 'redux-form';

export const required = (value?: string) => (value ? undefined : 'Required');

export const checker = ({ meta: { touched, error } }: WrappedFieldProps) => touched && !!error;

const minValue = (min: number) => (value?: string) =>
  value && value.length < min ? `Must be at least ${min}` : undefined;

export const minValue8 = minValue(8);
