import { t } from 'shared/utils';
import { Cashbook, ReminderType } from 'types/entities/Cashbook';

import { MonthlyReminder } from '../../NotificationSection/constants';

export const getMonthlyReminder = (cashbook: Cashbook) => {
  if (cashbook.reminderDay === 'first' && !cashbook.weekdayReminder) {
    return MonthlyReminder.First;
  }

  if (cashbook.reminderDay === 'first' && cashbook.weekdayReminder) {
    return MonthlyReminder.FirstWorking;
  }

  if (cashbook.reminderDay === 'last' && !cashbook.weekdayReminder) {
    return MonthlyReminder.Last;
  }

  if (cashbook.reminderDay === 'last' && cashbook.weekdayReminder) {
    return MonthlyReminder.LastWorking;
  }

  return undefined;
};

export const getCurrentRhythm = (cashbook: Cashbook) => {
  const monthlyReminder = getMonthlyReminder(cashbook);

  if (!cashbook.reminder) {
    return t('cashbooks.cash_transactions.summary.current_rhythm.no');
  }

  switch (cashbook.reminderType) {
    case ReminderType.Daily:
      return t('cashbooks.cash_transactions.summary.current_rhythm.daily', {
        hour: cashbook.reminderHour,
      });

    case ReminderType.Weekly:
      return t('cashbooks.cash_transactions.summary.current_rhythm.weekly.label', {
        hour: cashbook.reminderHour,
        day: t(
          `cashbooks.cash_transactions.summary.current_rhythm.weekly.day.${cashbook.reminderDay}`
        ),
      });

    case ReminderType.Monthly:
      switch (monthlyReminder) {
        case MonthlyReminder.First:
          return t('cashbooks.cash_transactions.summary.current_rhythm.monthly.first');

        case MonthlyReminder.FirstWorking:
          return t('cashbooks.cash_transactions.summary.current_rhythm.monthly.first_working');

        case MonthlyReminder.Last:
          return t('cashbooks.cash_transactions.summary.current_rhythm.monthly.last');

        case MonthlyReminder.LastWorking:
          return t('cashbooks.cash_transactions.summary.current_rhythm.monthly.last_working');
      }
  }
};
