import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import find from 'lodash/find';
import { func, shape } from 'prop-types';

import { fetchContent as fetchContentAction } from 'actions/contents';
import { termsAndConditions } from 'constants/legals';
import CancelAccountButton from 'containers/Profile/Personal/CancelAccountButton';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';
import LegalsComponent from 'components/Legals';

import styles from './Footer.module.css';

class Footer extends Component {
  state = {
    isModalOpen: false,
  };

  componentDidMount() {
    const { termsOfUse, fetchContent } = this.props;
    if (!termsOfUse) fetchContent();
  }

  handleModalOpen = () => {
    const {
      termsOfUse: { slug, title, body },
    } = this.props;

    this.setState({
      isModalOpen: true,
      currentOpenContent: {
        slug,
        title,
        body,
      },
    });
  };

  handleModalClose = () =>
    this.setState({
      isModalOpen: false,
      currentOpenContent: {},
    });

  render() {
    const { isModalOpen, currentOpenContent } = this.state;
    return (
      <>
        <Link role="link" to={paths.bankAccounts} className={styles.link}>
          {t('profile.payment_plan.footer.link_1')}
        </Link>
        <div
          className={styles.link}
          role="button"
          tabIndex="0"
          onKeyPress={isPressedEnter(this.handleModalOpen)}
          onClick={this.handleModalOpen}
        >
          {t('profile.payment_plan.footer.link_2')}
        </div>
        <CancelAccountButton
          className={styles.link}
          label={t('profile.payment_plan.footer.link_3')}
        />
        {isModalOpen && (
          <LegalsComponent
            {...currentOpenContent}
            isOpen={isModalOpen}
            onClose={this.handleModalClose}
            isModal
          />
        )}
      </>
    );
  }
}

Footer.propTypes = {
  fetchContent: func.isRequired,
  termsOfUse: shape({}),
};

const mapStateToProps = (state) => {
  const {
    contents: { data = {} },
  } = state;
  const { attributes } =
    find(data, (content) => content.attributes.slug === termsAndConditions) || {};

  return {
    termsOfUse: attributes,
  };
};

const mapDispatchToProps = {
  fetchContent: fetchContentAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
