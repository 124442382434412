import React from 'react';

import { t } from 'shared/utils';
import { Client } from 'types/entities/Client';
import FormField from 'components/Form/FormField';

import {
  AddAddressButton,
  Container,
  Detail,
  Details,
  FirstAdress,
  Label,
  Radio,
  SecondADress,
} from './DeviatingDeliveryAddress.styled';

type Props = {
  handleOpenModalState: () => void;
  client: Client;
  readonly: boolean;
  handleChange: (value: boolean) => void;
};

export enum AddressType {
  Default = 'default',
  Deviating = 'deviating',
}

const DeviatingDeliveryAddress = ({
  handleOpenModalState,
  client,
  readonly,
  handleChange,
}: Props) => {
  const disabled = !client.deliveryName;

  return (
    <Container>
      <FirstAdress>
        <Radio>
          <FormField
            name="deviatingAddress"
            component="input"
            type="radio"
            data-id="firstAddress"
            onChange={() => handleChange(false)}
            value={AddressType.Default}
            readonly={readonly}
          />
          <Label>{t('clients.deviating_delivery_address.default_address_label')}</Label>
        </Radio>
      </FirstAdress>
      <SecondADress>
        <Radio disabled={disabled}>
          <FormField
            name="deviatingAddress"
            component="input"
            type="radio"
            onChange={() => handleChange(true)}
            data-id="secondAddress"
            readonly={disabled || readonly}
            value={AddressType.Deviating}
          />
          <Label>{t('clients.deviating_delivery_address.deviated_address_label')}</Label>
        </Radio>
        <FormField
          name="useDeviatingDeliveryAddress"
          component="input"
          type="radio"
          data-id="useDeviatingDeliveryAddress"
          hidden
        />
        {!client.deliveryName && !readonly ? (
          <AddAddressButton onClick={handleOpenModalState} type="button">
            {t('clients.deviating_delivery_address.add_deviated_address_link')}
          </AddAddressButton>
        ) : client.deliveryName ? (
          <Details>
            <Detail>{client.companyName}</Detail>
            <Detail>{client.deliveryName}</Detail>
            <Detail>{client.deliveryStreet}</Detail>
            <div>
              <Detail>{client.deliveryZipcode}</Detail>
              <Detail>{client.deliveryCity}</Detail>
            </div>
            <Detail>{client.deliveryCountry}</Detail>
          </Details>
        ) : null}
      </SecondADress>
    </Container>
  );
};

export default DeviatingDeliveryAddress;
