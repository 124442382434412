export const PROCESSING_STATUS = {
  INITIAL: 'initial',
  PROCESSING: 'processing',
  SUCCESS: 'successful',
  PREPARATION_FAILED: 'file_preparation_failed',
  TIMEOUT: 'ocr_timeout',
  FAILURE: 'ocr_post_document_error',
};

export const OCR_MAX_FILES_COUNT = 10;
export const OCR_MAX_FILES_SIZE = 8;

export default PROCESSING_STATUS;
