import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import { InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';

import { showNotification } from 'actions/notification';
import { addCashbook } from 'api/me/cashbooks';
import { CASHBOOKS_CREATE_CASHBOOK, CASHBOOKS_CREATE_REMINDER } from 'constants/piwik';
import validationNotification from 'notifications/validation';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import {
  mapServerErrorsToFieldErrors,
  mapServerErrorsToNotificationError,
} from 'shared/utils/server-validation';
import { CurrencyField, DateField, FormField, TextField } from 'components/Form';

import { useHasAnyCashbooks } from '../hooks';
import NotificationSection from '../NotificationSection/NotificationSection';
import { NotificationFormData } from '../NotificationSection/types';
import { trackEventHandler } from '../utils';
import {
  Button,
  CardView,
  Description,
  GeneralInputs,
  HeadingSection,
  NotificationTitle,
  Section,
} from './Creator.styled';
import { getNotificationPayload } from './utils';

type FormData = {
  startDate: string;
  startAmount: number;
} & NotificationFormData;

type CreatorProps = InjectedFormProps<FormData>;

const formName = 'cashbookCreator';

const Creator = ({ handleSubmit }: CreatorProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isFetching, hasAnyCashbooks } = useHasAnyCashbooks();

  const onSubmit = useCallback(
    async (formValues: FormData) => {
      const sharedPayload = {
        startDate: formValues.startDate,
        startAmount: formValues.startAmount,
        reminder: false,
        reminderType: null,
        reminderDay: null,
        reminderHour: null,
        weekdayReminder: null,
      };

      const notificationPayload = getNotificationPayload(formValues);

      try {
        await addCashbook({ ...sharedPayload, ...notificationPayload });
      } catch ({ response }) {
        const { errors } = (response as any).data;

        const notification = validationNotification(mapServerErrorsToNotificationError(errors));
        dispatch(showNotification(notification));

        throw new SubmissionError(mapServerErrorsToFieldErrors(errors));
      }

      trackEventHandler(CASHBOOKS_CREATE_CASHBOOK);
      if (notificationPayload.reminder) {
        trackEventHandler(CASHBOOKS_CREATE_REMINDER);
      }
      history.push(paths.cashbooks);
    },
    [dispatch, history]
  );

  if (isFetching) {
    return null;
  }

  if (hasAnyCashbooks) {
    history.push(paths.home);
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardView>
        <HeadingSection bold>{t('cashbooks.creator.title')}</HeadingSection>
        <Section>
          <Description>{t('cashbooks.creator.description')}</Description>
          <GeneralInputs>
            <FormField
              name="startDate"
              label={t('cashbooks.creator.fields.startDate.label')}
              placeholder={t('cashbooks.creator.fields.startDate.placeholder')}
              component={DateField}
              maxDate={moment()}
              required
              dataIds={{ container: 'start-date', input: 'start-date-input' }}
            />
            <CurrencyField
              name="startAmount"
              component={TextField}
              label={t('cashbooks.creator.fields.startAmount.label')}
              placeholder={t('cashbooks.creator.fields.startAmount.placeholder')}
              isCurrencySignVisible
              onlyPositive
              dataId="start-amount"
            />
          </GeneralInputs>
          <NotificationTitle>{t('cashbooks.notifications.title')}</NotificationTitle>
          <NotificationSection formName={formName} />
          <Button label={t('cashbooks.creator.save')} type="submit" dataId="submit-cashbook" />
        </Section>
      </CardView>
    </form>
  );
};

export default reduxForm<FormData>({
  form: formName,
  initialValues: {
    reminderHour: 9,
  },
})(Creator);
