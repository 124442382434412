export const withComma = (value: string) => value.replace('.', ',');
export const withDot = (value: string) => value.replace(',', '.');

// empty string '' or starts with the '-', '-0'
export const isValueEmpty = (value: string) => /^$|^-$|^-0$/.test(value);

// number ends with a dot '0,'
export const isValuePossiblyFloat = (value: string) => /[0-9],$/.test(value);

// every float
export const isValueFloat = (value: string) => /[0-9],\d+$/.test(value);

// format like '0,0'
export const isValueFormatSatisfying = (value: string) => /^-?(\d+,?\d*|\d*,?\d+)$/.test(value);

type GetNumberWithinRangeProps = {
  loopRange: boolean;
  min: number;
  max: number;
  value: string;
};

export const getNumberWithinRange = ({ loopRange, min, max, value }: GetNumberWithinRangeProps) => {
  if (loopRange && Number.isFinite(min) && Number.isFinite(max)) {
    if (Number(value) < min) {
      return max;
    }
    if (Number(value) > max) {
      return min;
    }
  }

  return Math.max(min, Math.min(max, Number(value)));
};
