import { isString } from 'lodash';
import moment from 'moment';

import { DATE_FORMAT_FULL_REVERSED } from 'constants/datetime';

export const isValidBic = (bic) => /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(bic);
export const isValidIban = (iban) =>
  /^[a-zA-Z]{2}[0-9]{2}(\s?[0-9]{4}){2}(\s?[0-9]{3}[0-9]?)((\s?[0-9]{1,4})?){4}$/.test(iban);
export const isValidTaxNumber = (taxNumber) => /^[a-zA-Z]{2}\d{1,13}$/.test(taxNumber);
export const isValidWebsite = (website) => /^([a-zA-Z0-9]+\.)+[a-z]{2,14}$/.test(website);
export const isValidDigit = (digit) => /^[0-9]$/.test(digit);
export const isNumber = (number) => {
  const isNumberRegex = /^[-+]*[0-9,+-]+/;

  if (!isString(number)) return isNumberRegex.test(number);

  const nonNumericPart = number.replace(isNumberRegex, '');
  /**
   * If nonNumericPart variable is not empty then given number is not a number.
   * The nonNumericPart is part of the string that is left after we cut out number part of the string with isNumberRegex.
   */
  return !nonNumericPart;
};
export const isParsedDate = (value) => moment(value, DATE_FORMAT_FULL_REVERSED, true).isValid();
export const isValidEmail = (text) =>
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(text);
export const hasNumericCharacters = (value) => /^[,+-]+/.test(value);

export const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

export const filenameRegex = /[^0-9A-Z]/gi;
export const stringParsableToNumberRegex = /\d+(\.|,)?(\d{1,2})?/;
export const precisionCoherentStringRegex = /\d+(,\d{4})?/;
export const precisionParserEdgeCaseRegex = /\d+,\d{1,2},/;
export const precisionFormatterEdgeCaseRegex = /\d+(\.|,)\d?0/;

// match chars which are not: ',', '.', digit, '+', '-'
export const nonCalcCharsRegex = /[^,.\d+-]/gi;
