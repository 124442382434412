import React from 'react';
import snakeCase from 'lodash/snakeCase';
import { bool, node, string } from 'prop-types';
import { Field } from 'redux-form';

import { formatIBAN, t } from 'shared/utils';

const FormField = ({ name, readonly, className, label, placeholder, children, ...rest }) => (
  <div className={className}>
    <Field
      id={name}
      name={name}
      disabled={readonly}
      label={label || t(`expenses.payments.creator.form.${snakeCase(name)}`)}
      placeholder={placeholder || t(`expenses.payments.creator.form.${snakeCase(name)}`)}
      labelMapper={({ iban, name, bankName }) => `${formatIBAN(iban)} (${bankName} ${name})`}
      {...rest}
    />
    {children}
  </div>
);

FormField.propTypes = {
  name: string.isRequired,
  className: string,
  label: string,
  placeholder: string,
  readonly: bool,
  children: node,
};

export default FormField;
