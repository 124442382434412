import React, { ChangeEvent } from 'react';

import {
  OPTION_DISCOUNT,
  OPTION_FULLY_PAID,
  OPTION_JOKER,
  OPTION_PARTLY_PAID,
} from 'constants/assigment-select';
import { formatMoney, t } from 'shared/utils';
import { ensureNumericMoneyValue } from 'shared/utils/money';
import TextareaField from 'components/Form/TextareaField/TextareaField';
import TextField from 'components/Form/TextField';
import RadioGroupOption from 'components/RadioGroup/RadioGroupOption/RadioGroupOption';
import Tooltip from 'redesign/components/molecules/Tooltip/Tooltip';

import {
  CurrencyField,
  JokerLabel,
  Label,
  Option,
  OptionFieldWrapper,
  OptionsWrapper,
  RadioGroup,
} from '../AssignmentModal.styled';

type OptionsType = {
  isJoker: boolean;
  isPartlyPaid: boolean;
  handleOptionChange: (value: string) => void;
  selectedOption: string | undefined;
  jokerValue: string;
  inputValue: string;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleJokerValueChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  isInputInvalid: boolean;
  isInvoice: boolean;
  discountGrossValue: number;
};

const Options = ({
  isJoker,
  isPartlyPaid,
  handleOptionChange,
  selectedOption,
  jokerValue,
  inputValue,
  handleInputChange,
  handleJokerValueChange,
  isInputInvalid,
  isInvoice,
  discountGrossValue,
}: OptionsType) => (
  <OptionsWrapper>
    <Label>
      {isInvoice
        ? t('bank_transfers.assigment.modal.invoice_label')
        : t('bank_transfers.assigment.modal.contract_label')}
    </Label>
    <RadioGroup name="assignment" onChange={handleOptionChange} value={selectedOption}>
      <Option>
        <RadioGroupOption
          option={{
            label: isInvoice
              ? t(OPTION_FULLY_PAID.translateKey)
              : t('bank_transfers.assigment.modal.option.fully_paid'),
            value: OPTION_FULLY_PAID.value,
            dataIds: {
              input: 'fully-paid-input',
              label: 'fully-paid-label',
            },
          }}
        />
      </Option>
      <Option>
        <RadioGroupOption
          option={{
            label: isInvoice
              ? t(OPTION_PARTLY_PAID.translateKey)
              : t('bank_transfers.assigment.modal.option.partly_paid'),
            value: OPTION_PARTLY_PAID.value,
            dataIds: {
              input: 'partly-paid-input',
              label: 'partly-paid-label',
            },
          }}
        />
        {isPartlyPaid && (
          <OptionFieldWrapper>
            <CurrencyField>
              <TextField
                dataId="assignment-box-amount"
                name="option"
                type="number"
                onChange={handleInputChange}
                value={inputValue}
                invalid={isInputInvalid}
                label={t(`bank_transfers.select.input_labels.partly_paid`)}
                onPaste={(e: ClipboardEvent) => {
                  e.preventDefault();
                  const pastedValue = e.clipboardData?.getData('text/plain');
                  const valueAsNumber = ensureNumericMoneyValue(pastedValue);
                  if (e.target) {
                    (e.target as HTMLInputElement).value = valueAsNumber;
                  }
                  handleInputChange(e as unknown as ChangeEvent<HTMLInputElement>);
                }}
                isCurrency
              />
            </CurrencyField>
          </OptionFieldWrapper>
        )}
      </Option>
      {isInvoice && discountGrossValue > 0 && (
        <Option>
          <RadioGroupOption
            option={{
              label: t('bank_transfers.assigment.modal.option.skonto', {
                amount: formatMoney(discountGrossValue),
              }),
              value: OPTION_DISCOUNT.value,
              dataIds: {
                input: 'paid-skonto-input',
                label: 'paid-skonto-label',
              },
            }}
          />
        </Option>
      )}
      <Option>
        <RadioGroupOption
          option={{
            label: (
              <>
                <JokerLabel>
                  {isInvoice
                    ? t(OPTION_JOKER.translateKey)
                    : t('bank_transfers.assigment.modal.option.joker')}
                </JokerLabel>
                <Tooltip message={t('bank_transfers.assigment.modal.option.joker_tooltip')} />
              </>
            ),
            value: OPTION_JOKER.value,
            dataIds: {
              input: 'paid-joker-input',
              label: 'paid-joker-label',
            },
          }}
        />
        {isJoker && (
          <OptionFieldWrapper>
            <CurrencyField>
              <TextField
                dataId="assignment-box-amount"
                name="option"
                type="number"
                onChange={handleInputChange}
                value={inputValue}
                invalid={isInputInvalid}
                label={t(`bank_transfers.select.input_labels.partly_paid`)}
                onPaste={(e: ClipboardEvent) => {
                  e.preventDefault();
                  const pastedValue = e.clipboardData?.getData('text/plain');
                  const valueAsNumber = ensureNumericMoneyValue(pastedValue);
                  if (e.target) {
                    (e.target as HTMLInputElement).value = valueAsNumber;
                  }
                  handleInputChange(e as unknown as ChangeEvent<HTMLInputElement>);
                }}
                isCurrency
              />
            </CurrencyField>
            <TextareaField
              label={t('bank_transfers.joker_modal.header')}
              dataId="input-joker-reason"
              placeholder={t('bank_transfers.joker_modal.placeholder')}
              minHeight={50}
              name="jokerValue"
              onChange={handleJokerValueChange}
              value={jokerValue}
            />
          </OptionFieldWrapper>
        )}
      </Option>
    </RadioGroup>
  </OptionsWrapper>
);

export default Options;
