import Downshift, { DownshiftState, StateChangeOptions } from 'downshift';

export const stateReducer = <Item>(
  state: DownshiftState<Item>,
  changes: StateChangeOptions<Item>
): Partial<StateChangeOptions<Item>> => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.mouseUp:
    case Downshift.stateChangeTypes.blurInput:
      return {
        ...changes,
        inputValue: !state.selectedItem ? state.inputValue : changes.inputValue,
      };
    case Downshift.stateChangeTypes.changeInput:
      return {
        ...changes,
        isOpen: !!changes.inputValue,
      };
    default:
      return changes;
  }
};
