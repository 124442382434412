import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as pushAction } from 'connected-react-router';
import { get } from 'lodash';
import { arrayOf, bool, func, shape } from 'prop-types';

import { deleteOrderConfirmation, transformOrderConfirmation } from 'actions/order-confirmation';
import { sortOrderConfirmations } from 'actions/order-confirmations';
import { FROM_ORDER_CONFIRMATION_QUERY_PARAM } from 'constants/order-confirmation';
import WarningSignImage from 'images/warning-sign.svg';
import paths from 'routes/paths';
import tableStyles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';
import BoxWithButtonRedVariant from 'components/BoxWithButton/BoxWithButtonRedVariant/BoxWithButtonRedVariant';
import If from 'components/Conditions/If';
import I18n from 'components/I18n';
import { ConfirmationModal } from 'components/Modal';
import { CreationForbiddenMessage, EmptyState, TableHeader } from 'components/Table';

import NewOrderConfirmationButton from '../NewOrderConfirmationButton';
import OrderConfirmationRow from './OrderConfirmationRow/OrderConfirmationRow';

import localStyles from './OrderConfirmationsTable.module.css';

export const styles = { ...tableStyles, ...localStyles };

class OrderConfirmationsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deletingItem: undefined,
    };
  }

  onClearConfirmations = () => {
    this.setState({ deletingItem: undefined });
  };

  onDelete = (item) => {
    this.onClearConfirmations();
    const { refresh, remove } = this.props;
    remove(item).then(refresh);
  };

  onTransform = (item) => {
    this.props.transformOrderConfirmation(item).then(({ data: { id } }) => {
      this.props.push({
        pathname: paths.editOutgoingInvoice(id),
        search: `?${FROM_ORDER_CONFIRMATION_QUERY_PARAM}`,
      });
    });
  };

  onRequestDelete = (orderConfirmation) => {
    this.setState({ deletingItem: orderConfirmation });
  };

  render() {
    const {
      canCreate,
      showModal,
      isFetching,
      data,
      sorting,
      sort,
      refresh,
      isProfileFilled,
      push,
    } = this.props;
    if (!data.length) {
      return (
        <EmptyState isFetching={isFetching}>
          <If ok={canCreate}>
            <NewOrderConfirmationButton showModal={showModal} />
          </If>
          <If ok={!canCreate}>
            <CreationForbiddenMessage section="order_confirmations" />
          </If>
        </EmptyState>
      );
    }

    const sortableColumns = [
      { column: 'status' },
      { column: 'client', sortable: true },
      { column: 'order_confirmation_number', sortable: true },
      { column: 'subject', sortable: true },
      { column: 'order_confirmation_date', sortable: true },
      { column: 'total_gross_amount', align: 'right', sortable: true },
    ];

    return (
      <div className={styles.invoicesTableWrapper}>
        {!isProfileFilled && (
          <BoxWithButtonRedVariant
            dataId="OrderConfirmations:warning-box"
            image={WarningSignImage}
            content={t('empty_entry_pages.order_confirmations.table_warning_box.content_1')}
            contentSecondLine={t(
              'empty_entry_pages.order_confirmations.table_warning_box.content_2'
            )}
            buttonLabel={t('empty_entry_pages.order_confirmations.table_warning_box.button_label')}
            onClick={() => push(paths.companyProfile)}
          />
        )}
        <table
          className={cx(styles.invoicesTable, {
            [styles.invoicesTableLoading]: isFetching,
            [styles.tableWarningMargin]: !isProfileFilled,
          })}
        >
          <thead>
            <tr>
              <th className={styles.column} />
              {sortableColumns.map((column) => (
                <TableHeader
                  {...column}
                  key={column.column}
                  columnWidthClass={styles.column}
                  currentSorting={sorting}
                  refresh={refresh}
                  section="order_confirmations"
                  sort={sort}
                  sortable={column.sortable}
                />
              ))}
              <TableHeader
                column="actions"
                columnWidthClass={styles.column}
                info={t('features.order_confirmation.table.actions.info_text')}
                refresh={refresh}
                sort={sort}
              />
            </tr>
          </thead>
          <tbody>
            {data.length &&
              data.map((item, index) => {
                return (
                  <OrderConfirmationRow
                    key={item.id}
                    item={item}
                    onDelete={this.onRequestDelete}
                    onTransform={this.onTransform}
                    isEven={index % 2 === 0}
                  />
                );
              })}
          </tbody>
        </table>
        <ConfirmationModal
          dangerousAction
          isOpen={!!this.state.deletingItem}
          onClose={this.onClearConfirmations}
          closeLabel={t('features.order_confirmation.table.confirm_delete.discard')}
          onConfirm={() => this.onDelete(this.state.deletingItem)}
        >
          <I18n t="features.order_confirmation.table.confirm_delete.message" paragraphs />
        </ConfirmationModal>
      </div>
    );
  }
}

OrderConfirmationsTable.propTypes = {
  canCreate: bool.isRequired,
  showModal: bool.isRequired,
  data: arrayOf(shape({})).isRequired,
  isFetching: bool.isRequired,
  push: func.isRequired,
  refresh: func.isRequired,
  remove: func.isRequired,
  sort: func.isRequired,
  sorting: shape({}).isRequired,
  transformOrderConfirmation: func.isRequired,
  isProfileFilled: bool.isRequired,
};

const mapStateToProps = (state) => ({
  canCreate: get(state, 'orderConfirmations.meta.actions.create', false),
  showModal: !get(state, 'orderConfirmations.meta.bank-account-data-present', false),
  isProfileFilled: state.onboarding.data?.profileFilled,
});

const mapDispatchToProps = {
  push: pushAction,
  remove: deleteOrderConfirmation,
  sortOrderConfirmations,
  transformOrderConfirmation,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationsTable);
