import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Breakpoints } from 'redesign/styles/breakpoints';

type NavProps = {
  $isOpen?: boolean;
};

export const Nav = styled.nav<NavProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.nightblue['100']};
  will-change: width;
  transition: width 0.3s;
  overflow-x: hidden;
  box-shadow: 5px 5px 9px 0 ${({ theme }) => transparentize(0.6, theme.colors.nightblue['100'])};
  z-index: 50006;

  @media (min-width: ${Breakpoints.lg}) and (any-hover: hover) and (any-pointer: fine) {
    position: sticky;
  }

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          width: 245px;
        `
      : css`
          width: 0;

          @media (min-width: ${Breakpoints.lg}) and (any-hover: hover) and (any-pointer: fine) {
            width: 56px;
          }

          ul ul {
            display: none;
          }
        `}
`;

type CloseProps = {
  $isHidden?: boolean;
};

export const Close = styled.span<CloseProps>`
  height: 72px;
  display: grid;
  padding-left: ${({ theme }) => theme.space['4']};
  grid-template-columns: 24px auto 24px;
  align-items: center;
  grid-column-gap: ${({ theme }) => theme.space['4']};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.sm};
  user-select: none;
  white-space: nowrap;

  svg {
    width: 24px;
  }

  @media (min-width: ${Breakpoints.lg}) and (any-hover: hover) and (any-pointer: fine) {
    display: none;
  }
`;
