import { fetchDeliveryNoteSums } from 'actions/delivery-note/sums';
import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/delivery-note/line-items';
import {
  lineItemCreateSuccess,
  lineItemDeleteFailure,
  lineItemDeleteSuccess,
  lineItemUpdateSuccess,
} from 'notifications/delivery-note';
import { apiErrorHandler, isNetworkError } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

export const apiFetchDeliveryNoteLineItems = (deliveryNoteId) => ({
  [CALL_API]: {
    endpoint: `/me/delivery_notes/${deliveryNoteId}/delivery_note_line_items`,
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiDeleteDeliveryNoteLineItem = (deliveryNoteId, id) => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: `/me/delivery_notes/${deliveryNoteId}/delivery_note_line_items/${id}`,
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const apiUpdateDeliveryNoteLineItem = (deliveryNoteId, id, data) => ({
  [CALL_API]: {
    data,
    method: 'PUT',
    endpoint: `/me/delivery_notes/${deliveryNoteId}/delivery_note_line_items/${id}`,
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const apiCreateDeliveryNoteLineItem = (deliveryNoteId, data) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: `/me/delivery_notes/${deliveryNoteId}/delivery_note_line_items`,
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const fetchDeliveryNoteLineItems =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchDeliveryNoteLineItems(...args)).catch(apiErrorHandler);

export const deleteDeliveryNoteLineItem = (deliveryNoteId, id) => (dispatch) =>
  bindServerValidation(apiDeleteDeliveryNoteLineItem(deliveryNoteId, id), dispatch, {
    isReduxForm: false,
  })
    .then(() => {
      dispatch(showNotification(lineItemDeleteSuccess));
      dispatch(fetchDeliveryNoteSums(deliveryNoteId));
    })
    .catch((err) => {
      apiErrorHandler(err);
      if (!isNetworkError(err)) {
        dispatch(showNotification(lineItemDeleteFailure));
      }
    });

export const updateDeliveryNoteLineItem = (deliveryNoteId, id, formValues) => (dispatch) =>
  bindServerValidation(apiUpdateDeliveryNoteLineItem(deliveryNoteId, id, formValues), dispatch, {
    isReduxForm: false,
  })
    .then(() => {
      dispatch(showNotification(lineItemUpdateSuccess));
      dispatch(fetchDeliveryNoteSums(deliveryNoteId));
    })
    .catch((err) => {
      apiErrorHandler(err);
      throw err;
    });

export const createDeliveryNoteLineItem = (deliveryNoteId, formValues) => (dispatch) =>
  bindServerValidation(apiCreateDeliveryNoteLineItem(deliveryNoteId, formValues), dispatch, {
    isReduxForm: false,
  })
    .then(() => {
      dispatch(showNotification(lineItemCreateSuccess));
      dispatch(fetchDeliveryNoteSums(deliveryNoteId));
    })
    .catch((err) => {
      apiErrorHandler(err);
      throw err;
    });
