import React from 'react';

const IconCorrection = () => (
  <svg width="12" height="13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.452.552c-.735-.735-1.388-.518-1.388-.518L4.304 5.79l-.97 2.88 2.879-.97 5.757-5.76s.216-.653-.518-1.388zM6.405 7.073l-.314.314-.982.333a1.478 1.478 0 0 0-.332-.493 1.484 1.484 0 0 0-.494-.333l.333-.982.315-.313s.517-.06 1.025.449c.508.507.449 1.025.449 1.025zm3.598 3.598h-8.67V2h3.335L6.002.669H1.334C.6.668 0 1.268 0 2.002v8.669c0 .733.6 1.333 1.334 1.333h8.669c.733 0 1.333-.6 1.333-1.333V6.003l-1.333 1.334v3.334z"
      fillRule="evenodd"
    />
  </svg>
);

export default IconCorrection;
