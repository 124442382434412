import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import { createExternalAppToken as createExternalAppTokenAction } from 'actions/external-tokens';
import { fetchProfile as fetchProfileAction } from 'actions/profile';
import { LG, MD, SM, XLG } from 'constants/breakpoints';
import { isFarmpilotTokenConnected } from 'selectors/app-connections';
import { getFarmpilotAppId as getFarmpilotAppIdSelector } from 'selectors/app-settings';
import { t } from 'shared/utils';
import Button from 'components/Button';
import CopyButton from 'components/CopyButton/CopyButton';

import farmpilotStyles from '../Farmpilot.module.css';
import styles from './TokenGenerator.module.css';

interface PostTokenResponse {
  token: string;
}
interface TokenGeneratorProps {
  trackGeneratingToken?: () => void;
  createExternalAppToken: (id: string | null) => Promise<PostTokenResponse>;
  fetchProfile: () => void;
  isFarmpilotConnected: boolean;
  farmpilotAppId: string | null;
}

const TokenGenerator: FunctionComponent<TokenGeneratorProps> = ({
  createExternalAppToken,
  trackGeneratingToken = () => {},
  fetchProfile = () => {},
  isFarmpilotConnected = false,
  farmpilotAppId = null,
}) => {
  const getAsteriskCount = () => {
    const width = window.innerWidth;
    switch (true) {
      case width < 370:
        return 30;
      case width < 415:
        return 38;
      case width < 14 * parseInt(SM, 10):
        return 25;
      case width < 14 * parseInt(MD, 10):
        return 37;
      case width < 14 * parseInt(LG, 10):
        return 45;
      case width < 14 * parseInt(XLG, 10):
        return 65;
      default:
        return 91;
    }
  };

  const [token, setToken] = useState('');
  const [asteriskCount, setAsteriskCount] = useState(getAsteriskCount());

  useEffect(() => {
    const handleResize = () => {
      setAsteriskCount(getAsteriskCount());
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const hideToken = (token: string) => {
    return token
      ? `${'*'.repeat(asteriskCount - 6)}${token.substr(-6)}`
      : '*'.repeat(asteriskCount);
  };

  const handleClick = async () => {
    trackGeneratingToken();
    const { token: responseToken } = await createExternalAppToken(farmpilotAppId);
    await fetchProfile();
    setToken(responseToken);
  };

  return (
    <div className={cx(farmpilotStyles.tokenGenerator, styles.tokenGenerator)}>
      <div className={styles.buttonsWrapper}>
        <p className={styles.header}>{t('farmpilot.connection_section.subheader')}</p>
        {!isFarmpilotConnected ? (
          <Button
            onClick={handleClick}
            dataId="Farmpilot:button-generate-token"
            className={styles.createTokenButton}
            label={t('farmpilot.connection_section.buttons.create_token')}
          />
        ) : (
          <span data-id="Farmpilot:token-container" className={styles.tokenString}>
            {hideToken(token)}
          </span>
        )}
      </div>
      {isFarmpilotConnected && (
        <div className={styles.buttonsWrapper}>
          {token ? (
            <CopyButton
              shouldExpand={window.innerWidth > 414}
              addedClass={styles.copyButton}
              dataId="Farmpilot:button-copy-token"
              textToCopy={token}
            />
          ) : (
            <Button
              className={styles.createTokenButton}
              onClick={handleClick}
              label={t('farmpilot.connection_section.buttons.renew')}
              dataId="Farmpilot:button-renew-token"
            />
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: object) => ({
  isFarmpilotConnected: isFarmpilotTokenConnected(state),
  farmpilotAppId: getFarmpilotAppIdSelector(state),
});

const mapDispatchToProps = {
  createExternalAppToken: createExternalAppTokenAction,
  fetchProfile: fetchProfileAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenGenerator);
