import React, { ReactNode } from 'react';

import { t } from 'shared/utils';

import { Content } from './DefaultValuesForm.styled';
import ResetDefaultsButton from './ResetDefaultsButton';
import { Button, Buttons, Form } from './shared.styled';

type DefaultValuesFormProps = {
  children: ReactNode;
  onSubmit: any;
  onReset: () => void;
  sectionName: string;
  sectionLabel: string;
  dataIdPrefix?: string;
  invalid?: boolean;
};

const DefaultValuesForm = ({
  children,
  onSubmit,
  onReset,
  sectionName,
  sectionLabel,
  dataIdPrefix = 'Defaults:',
  invalid,
}: DefaultValuesFormProps) => (
  <Form onSubmit={onSubmit}>
    <Content>{children}</Content>
    <Buttons>
      <Button
        as={ResetDefaultsButton}
        sectionName={sectionName}
        sectionLabel={sectionLabel}
        onReset={onReset}
        dataIdPrefix={dataIdPrefix}
        $appearance="outline"
      />
      <Button
        tabIndex={0}
        data-id={`${dataIdPrefix}button-save-template_${sectionName}`}
        type="submit"
        disabled={invalid}
      >
        {t('invoice_templates.documents_defaults.save_button')}
      </Button>
    </Buttons>
  </Form>
);

export default DefaultValuesForm;
