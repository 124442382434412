import React from 'react';
import { func, number, shape, string } from 'prop-types';

import ButtonOutlined from './components/Buttons/ButtonOutlined';
import ButtonPrimary from './components/Buttons/ButtonPrimary';
import Description from './components/Description/Description';
import Header from './components/Header/Header';
import List from './components/List/List';

import styles from './ComparisonBox.module.css';

const ComparisonBox = ({ plan, handleModalOpen, handleScrollToTable, details }) => (
  <div className={styles.main}>
    <Header currentPlan={plan} defaultPrice={details.defaultPrice} />
    <Description currentPlan={plan} />
    <ButtonPrimary currentPlan={plan} handleModalOpen={handleModalOpen} />
    <List currentPlan={plan} />
    <ButtonOutlined onClick={handleScrollToTable} />
  </div>
);

ComparisonBox.propTypes = {
  plan: string.isRequired,
  handleModalOpen: func.isRequired,
  handleScrollToTable: func.isRequired,
  details: shape({
    defaultPrice: number.isRequired,
  }).isRequired,
};

export default ComparisonBox;
