import React from 'react';

import { MODAL_HEADER_VARIANT } from 'constants/modal';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import { ModalHeader } from 'components/Modal';

import { Buttons, Container, Modal, Text } from './FinishContractModal.styled';

type FinishContractModalProps = {
  onClose: () => void;
  onSubmit: () => void;
};

const FinishContractModal = ({ onClose, onSubmit }: FinishContractModalProps) => {
  return (
    <Modal isOpen onRequestClose={onClose}>
      <ModalHeader withCloseButton onClose={onClose} headerVariant={MODAL_HEADER_VARIANT.SMALL}>
        {t('contracts.cancellation.finish_contract')}
      </ModalHeader>
      <Container>
        <Text>
          <p>{t('contracts.cancellation.body.1')}</p>
          <p>{t('contracts.cancellation.body.2')}</p>
        </Text>
        <Buttons>
          <ActionButton
            appearance="outlined"
            label={t('form.cancel')}
            onClick={onClose}
            dataId="cancel-button"
          />
          <ActionButton
            dataId="finish-button"
            label={t('contracts.cancellation.finish_contract')}
            onClick={onSubmit}
          />
        </Buttons>
      </Container>
    </Modal>
  );
};

export default FinishContractModal;
