import normalize from 'json-api-normalizer';
import { combineReducers } from 'redux';
import build from 'redux-object';

import { PRODUCT_CATALOG_GROUPS } from 'constants/product-catalog/groups';
import { getResourceReducer } from 'reducers/common/resource';
import { createFilteredReducer } from 'shared/utils';

export default combineReducers({
  [PRODUCT_CATALOG_GROUPS]: createFilteredReducer(
    getResourceReducer(),
    (action) => action.name === PRODUCT_CATALOG_GROUPS
  ),
});

export const transformProductCatalogGroupResponse = (response, groupId) => {
  const { productCatalogItems, productCatalogItemsGroups } = build(
    normalize(response),
    'productCatalogGroups',
    groupId,
    {
      eager: true,
      ignoreLinks: true,
    }
  );

  const productCatalogItemsWithCorrectKey = productCatalogItems.map((item) => ({
    ...item,
    position: item.name,
    quantity: productCatalogItemsGroups.find(
      ({ productCatalogItemId }) => String(productCatalogItemId) === item.id
    ).quantity,
  }));

  return productCatalogItemsWithCorrectKey;
};
