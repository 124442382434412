import styled from 'styled-components';

import BaseIconClose from 'redesign/components/atoms/Icons/Close';

export const IconClose = styled(BaseIconClose)`
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  cursor: pointer;
  position: absolute;
  top: ${({ theme }) => theme.space['2']};
  right: ${({ theme }) => theme.space['2']};

  &:hover {
    color: ${({ theme }) => theme.colors.vrblue['075']};
  }
`;
