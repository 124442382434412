import normalize from 'json-api-normalizer';
import { camelCase, get, isArray, isObject, isUndefined, mapKeys, omitBy, snakeCase } from 'lodash';

import contentTypes from 'constants/content-types';
import { mapKeysDeep } from 'shared/utils';

const sanitize = (data, mapper) => {
  if (isArray(data)) return data.map(mapper);
  return mapper(data);
};

const sanitizers = {
  response:
    (mapper) =>
    ({ data, included, ...rest } = {}) => {
      const sanitizedData = isObject(data) ? sanitize(data, mapper) : data;

      const sanitizedIncluded = isObject(included) ? sanitize(included, mapper) : included;

      return omitBy(
        {
          ...rest,
          data: sanitizedData,
          included: sanitizedIncluded,
        },
        isUndefined
      );
    },
  request: (mapper) => (data) => {
    if (!isObject(data)) return data;

    return { ...mapper(data) };
  },
};

const mappers = {
  response: {
    keysTo:
      (converter, { omitOriginalKeys = false } = {}) =>
      ({ attributes, meta, relationships = {}, ...rest }) => ({
        attributes: {
          ...(omitOriginalKeys ? {} : attributes),
          ...mapKeys(attributes, (_, k) => converter(k)),
        },
        meta: {
          ...meta,
          ...mapKeys(meta, (_, k) => converter(k)),
        },
        relationships,
        ...rest,
        ...mapKeysDeep(rest, (_, k) => converter(k)),
      }),
    attachId:
      () =>
      ({ attributes, id, ...rest }) => ({
        attributes: {
          ...attributes,
          id,
        },
        id,
        ...rest,
      }),
  },
  request: {
    keysTo: (converter) => (data) => mapKeysDeep(data, (_, k) => converter(k)),
  },
};

export const sanitizeRequest = {
  keysToSnakeCase: sanitizers.request(mappers.request.keysTo(snakeCase)),
};

export const sanitizeResponse = {
  attachId: sanitizers.response(mappers.response.attachId()),
  keysToCamelCase: sanitizers.response(
    mappers.response.keysTo(camelCase, { omitOriginalKeys: true })
  ),
};

export const getFormData = (data) =>
  Object.keys(data).reduce((acc, x) => {
    acc.append(x, data[x]);
    return acc;
  }, new FormData());

export const processParams = (params) => {
  const isMultipartFormData = params.headers['Content-Type'] === contentTypes.FORM_DATA;

  const { keysToSnakeCase } = sanitizeRequest;

  const convertedData = keysToSnakeCase(params.data);
  const convertedParams = keysToSnakeCase(params.params);

  return {
    ...params,
    params: convertedParams,
    data: isMultipartFormData ? getFormData(convertedData) : convertedData,
  };
};

export const unauthorizedRequestChecker = (response) => get(response, 'status') === 401;
export const timedOutRequestChecker = (err) =>
  err.message === 'Network Error' || get(err, 'response.status') === 503;

export const safelyNormalize = (data) => {
  try {
    return normalize(data);
  } catch (error) {
    return undefined;
  }
};
