import React, { ChangeEvent, Children, cloneElement, isValidElement, ReactNode } from 'react';

import { Wrapper } from './RadioGroup.styled';

export type RadioGroupProps = {
  children: ReactNode;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  className?: string;
  direction?: 'row' | 'column';
};

const RadioGroup = ({
  direction = 'column',
  className,
  children,
  value,
  name,
  onChange,
}: RadioGroupProps) => {
  return (
    <Wrapper className={className} $direction={direction}>
      {Children.map(children, (child) => {
        if (!isValidElement(child)) {
          return null;
        }

        return cloneElement(child, {
          checked: value ? value === child.props.value : undefined,
          name,
          onChange,
        });
      })}
    </Wrapper>
  );
};

export default RadioGroup;
