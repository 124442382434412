import { ReminderType } from '../../../types/entities/Cashbook';
import { MonthlyReminder, NoReminderOption } from '../NotificationSection/constants';
import { NotificationFormData } from '../NotificationSection/types';

export const getNotificationPayload = (formValues: NotificationFormData) => {
  const defaultPayload = {
    reminder: false,
    reminderType: null,
    reminderDay: null,
    reminderHour: null,
    weekdayReminder: false,
  };
  let payload = {};

  if (formValues.reminderType === undefined || formValues.reminderType === NoReminderOption) {
    payload = {
      reminder: false,
    };
  } else if (formValues.reminderType === ReminderType.Daily) {
    payload = {
      reminder: true,
      reminderType: ReminderType.Daily,
      reminderHour: formValues.reminderHour,
    };
  } else if (formValues.reminderType === ReminderType.Weekly) {
    payload = {
      reminder: true,
      reminderType: ReminderType.Weekly,
      reminderDay: formValues.reminderDay,
      reminderHour: formValues.reminderHour,
    };
  } else if (formValues.reminderType === ReminderType.Monthly) {
    payload = {
      reminder: true,
      reminderType: ReminderType.Monthly,
      reminderHour: formValues.reminderHour,
      ...(formValues.monthlyReminder === MonthlyReminder.First && {
        reminderDay: 'first',
        weekdayReminder: false,
      }),
      ...(formValues.monthlyReminder === MonthlyReminder.FirstWorking && {
        reminderDay: 'first',
        weekdayReminder: true,
      }),
      ...(formValues.monthlyReminder === MonthlyReminder.Last && {
        reminderDay: 'last',
        weekdayReminder: false,
      }),
      ...(formValues.monthlyReminder === MonthlyReminder.LastWorking && {
        reminderDay: 'last',
        weekdayReminder: true,
      }),
    };
  }

  return { ...defaultPayload, ...payload };
};
