import React, { memo } from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';

import { t } from 'shared/utils';

import AddMoreButton from '../../ExpensesRevenues/AddMoreButton/AddMoreButton';
import Amount, { Color } from '../../ExpensesRevenues/Fields/Amount';
import Interval from '../../ExpensesRevenues/Fields/Interval';
import Name from '../../ExpensesRevenues/Fields/Name';
import { requiredAndTouchedChecker, requiredValidate } from '../../ExpensesRevenues/Fields/utils';
import RemoveButton from '../../ExpensesRevenues/RemoveButton/RemoveButton';
import { trackEvent } from '../../utils';
import { NewExpense } from '../constants';
import type { RecurringExpense } from '../types';

import styles from '../../ExpensesRevenues/ExpensesRevenues.module.css';

const List = ({ fields }: WrappedFieldArrayProps<RecurringExpense>) => (
  <div className={styles.list}>
    {fields.map((expense, index) => (
      <div className={styles.item} key={index}>
        <div className={styles.nameRow}>
          <Name
            name={`${expense}.name`}
            label={t(
              'features.financial_planning.liquidity_calculator.expenses.fields.recurring_expenses.name.label'
            )}
            validate={requiredValidate}
            checker={requiredAndTouchedChecker}
          />
        </div>
        <div className={styles.intervalAmountRow}>
          <div>
            <Interval
              name={`${expense}.interval`}
              label={t(
                'features.financial_planning.liquidity_calculator.expenses.fields.recurring_expenses.interval.label'
              )}
              validate={requiredValidate}
              checker={requiredAndTouchedChecker}
            />
          </div>
          <div>
            <Amount
              name={`${expense}.amount`}
              label={t(
                'features.financial_planning.liquidity_calculator.expenses.fields.recurring_expenses.amount.label'
              )}
              color={Color.Red}
              validate={requiredValidate}
              checker={requiredAndTouchedChecker}
            />
          </div>
        </div>
        <div className={styles.removeButton}>
          <RemoveButton
            onClick={() => {
              fields.remove(index);
              trackEvent('Delete_Expense');
            }}
            withText
          />
        </div>
      </div>
    ))}
    <AddMoreButton
      onClick={() => {
        fields.push(NewExpense);
        trackEvent('Add_Expense');
      }}
    >
      {`+ ${t('features.financial_planning.liquidity_calculator.expenses.add_expense')}`}
    </AddMoreButton>
  </div>
);

export default memo(List);
