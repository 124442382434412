import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FROM_CONFIRMATION_QUERY_PARAM } from 'constants/registration';
import { useAppSelector } from 'shared/hooks/app';
import { useQuery } from 'shared/hooks/useQuery';

import AccountConfirmed from './AccountConfirmed';
import AccountNotConfirmed from './AccountNotConfirmed';
import { useFetchGlobalNotifications } from './hooks';
import Notifications from './Notifications/Notifications';

type LoginInfo = null | {
  firstLoginAfterConfirmation: boolean;
};

const GlobalNotifications = () => {
  const location = useLocation();
  const globalNotifications = useFetchGlobalNotifications();
  const [isAccountConfirmedOpen, setIsAccountConfirmedOpen] = useState(true);
  const [isAccountNotConfirmedOpen, setIsAccountNotConfirmedOpen] = useState(true);
  const loginInfo = useAppSelector<LoginInfo>((state) => state.auth.loginInfo);
  const confirmationBannerEmails = useAppSelector((state) => state.confirmationBanner.emails);
  const email = useAppSelector((state) => state.profile.credentials.email);
  const isUserConfirmed = useAppSelector((state) => state.profile.credentials.confirmed);
  const hasBeenConfirmationBannerSeen = confirmationBannerEmails.includes(email);
  const isFirstLoginAfterConfirmation = loginInfo?.firstLoginAfterConfirmation;
  const query = useQuery(location);
  const isRedirectedFromConfirmationPage = Boolean(query.get(FROM_CONFIRMATION_QUERY_PARAM));
  const isAccountConfirmed =
    isUserConfirmed &&
    (isRedirectedFromConfirmationPage || isFirstLoginAfterConfirmation) &&
    !hasBeenConfirmationBannerSeen;

  const handleCloseAccountNotConfirmed = useCallback(() => {
    setIsAccountNotConfirmedOpen(false);
  }, []);

  const handleCloseAccountConfirmed = useCallback(() => {
    setIsAccountConfirmedOpen(false);
  }, []);

  if (isAccountConfirmedOpen && isAccountConfirmed) {
    return <AccountConfirmed onClose={handleCloseAccountConfirmed} />;
  }

  if (isAccountNotConfirmedOpen && !isUserConfirmed) {
    return <AccountNotConfirmed onClose={handleCloseAccountNotConfirmed} />;
  }

  return <Notifications data={globalNotifications} />;
};

export default GlobalNotifications;
