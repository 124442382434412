import React from 'react';

import styles from '../StatusIcons.module.css';

const RemindedStatusIcon = () => (
  <svg viewBox="0 0 20 20" className={styles.statusIcon} xmlns="http://www.w3.org/2000/svg">
    <g className={styles.strokeDanger} fill="none" fillRule="evenodd">
      <rect x=".5" y=".5" width="19" height="19" rx="2" />
      <path
        d="M8.75 5h2.5l-.833 6.743h-.834L8.75 5zM10 16.25a1.048 1.048 0 0 1-1.042-1.054c0-.581.467-1.053 1.042-1.053s1.042.472 1.042 1.053c0 .582-.467 1.054-1.042 1.054z"
        className={styles.fillDanger}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default RemindedStatusIcon;
