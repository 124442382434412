import React, { FormEvent, useEffect, useState } from 'react';

import { fetchBankDetails } from 'actions/banks';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { isValidIban } from 'shared/utils/regex';
import FieldWrapper from 'redesign/components/molecules/FieldWrapper/FieldWrapper';
import InputField, { InputFieldProps } from 'redesign/components/molecules/InputField/InputField';

import { TManualBankAccount } from '../types';
import * as SC from './ManualBankForm.styled';

type ManualBankFormProps = {
  bankDetails: TManualBankAccount;
  setBankDetails: (data: TManualBankAccount) => void;
};

const ManualBankForm = ({ bankDetails, setBankDetails }: ManualBankFormProps) => {
  const dispatch = useAppDispatch();
  const [ibanError, setIbanError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [iban, setIban] = useState('');
  const [bic, setBic] = useState<string | undefined>();
  const [bankName, setBankName] = useState<string | undefined>();

  useEffect(() => {
    if (iban === '') {
      bankDetails?.iban && setIban(bankDetails.iban);
      bankDetails?.bic && setBic(bankDetails.bic);
      bankDetails?.bankName && setBankName(bankDetails.bankName);
    }
  }, [iban, bic, bankName, bankDetails]);

  const onIbanBlur = () => {
    setIbanError(!isValidIban(iban));
    isValidIban(iban) ? handleFetch(iban) : setBankDetails(undefined);
  };

  const onIbanChange = (event: FormEvent<HTMLInputElement>) => {
    setBankDetails(undefined);
    setDisabled(false);
    setIban(event.currentTarget.value);
  };

  const handleBlur = () => {
    setBankDetails({
      ...bankDetails,
      bic: bic ?? '',
      bankName: bankName ?? '',
      iban: iban,
    });
  };

  const onBicChange = (event: FormEvent<HTMLInputElement>) => {
    /* there is an unexpected div event that returns undefined for some reason */
    if (event.currentTarget.value === undefined) {
      return;
    }

    if (event.currentTarget.value === '') {
      setBankDetails({
        ...bankDetails,
        bic: '',
        bankName: bankName ?? '',
        iban: iban ?? '',
      });
    }
    setBic(event.currentTarget.value);
  };

  const onBankNameChange = (event: FormEvent<HTMLInputElement>) => {
    /* there is an unexpected div event that returns undefined for some reason */
    if (event.currentTarget.value === undefined) {
      return;
    }

    if (event.currentTarget.value === '') {
      setBankDetails({
        ...bankDetails,
        bic: bic ?? '',
        bankName: '',
        iban: iban ?? '',
      });
    }
    setBankName(event.currentTarget.value);
  };

  const handleFetch = async (value: string) => {
    const { attributes: selectBankDetails } = await dispatch(fetchBankDetails(value));

    selectBankDetails?.bic && setBic(selectBankDetails.bic);
    selectBankDetails?.bic && setDisabled(true);
    selectBankDetails?.name && setBankName(selectBankDetails.name);

    setBankDetails({
      ...selectBankDetails,
      bic: selectBankDetails?.bic ? selectBankDetails.bic : bic,
      bankName: selectBankDetails?.name ? selectBankDetails.name : bankName,
      iban: value,
    });
  };

  return (
    <>
      <InputField
        placeholder={t('form.fields.iban')}
        onBlur={onIbanBlur}
        onChange={onIbanChange}
        value={bankDetails?.iban ? bankDetails.iban : iban}
        error={ibanError ? t('invoice_templates.form.bankAccounts.modal_error') : undefined}
      />
      <SC.InputContainer>
        <FieldWrapper<InputFieldProps>
          label={t('form.fields.bic')}
          field={InputField}
          onChange={onBicChange}
          onBlur={handleBlur}
          placeholder={bankDetails?.bic || t('revenue.form.number_disabled')}
          value={bic ? bic : bankDetails?.bic}
          disabled={disabled}
        />
      </SC.InputContainer>
      <SC.InputContainer>
        <FieldWrapper<InputFieldProps>
          label={t('form.fields.bank_name')}
          field={InputField}
          onChange={onBankNameChange}
          onBlur={handleBlur}
          placeholder={bankDetails?.bankName || t('revenue.form.number_disabled')}
          value={bankName ? bankName : bankDetails?.bankName}
          disabled={disabled}
        />
      </SC.InputContainer>
    </>
  );
};

export default ManualBankForm;
