import React, { memo } from 'react';
import { matchPath } from 'react-router-dom';

import paths from 'routes/paths';
import { t } from 'shared/utils';
import IconHome from 'redesign/components/atoms/Icons/Home';

import { trackEventHandler } from '../utils';
import { Link, MenuItem } from './Shared.styled';

type HomepageProps = {
  pathname: string;
};

const Homepage = ({ pathname }: HomepageProps) => {
  const isHomepage = !!matchPath(pathname, { path: paths.home, exact: true });

  return (
    <MenuItem $isActive={isHomepage}>
      <Link
        $level={1}
        to={paths.home}
        $isActive={isHomepage}
        data-id="homepage"
        onClick={trackEventHandler('dashboard')}
      >
        <IconHome /> {t('redesign.organisms.navigation.homepage')}
      </Link>
    </MenuItem>
  );
};

export default memo(Homepage);
