import React, { forwardRef } from 'react';

import { IconSearch, Input, InputWrapper } from './AutoComplete.styled';

import localStyles from './AutoCompleteInput.module.css';

const AutoCompleteInput = forwardRef(({ onChange, onFocus, onBlur, onKeyDown }, ref) => {
  const handleBlur = () => {
    onBlur && onBlur();
  };

  return (
    <InputWrapper>
      <Input
        className={localStyles.input}
        autoComplete="off"
        id="company-select"
        ref={ref}
        autoFocus
        onChange={(event) => onChange(event)}
        onFocus={onFocus}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
      />
      <IconSearch />
    </InputWrapper>
  );
});

export default AutoCompleteInput;
