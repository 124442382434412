import React from 'react';
import cx from 'classnames';
import { string } from 'prop-types';

import styles from './Subheader.module.css';

const Subheader = ({ title, className }) => (
  <div className={cx(styles.container, className)}>
    <span className={styles.title}>{title}</span>
    <span className={styles.divider} />
  </div>
);

Subheader.propTypes = {
  title: string,
  className: string,
};

export default Subheader;
