import React from 'react';
import { Field } from 'redux-form';

import { SectionRow } from 'containers/Profile/SectionWithMargin';
import { t } from 'shared/utils';
import { InfoTextField, TextField } from 'components/Form';

import styles from '../Company.module.css';

const TaxData = () => (
  <>
    <SectionRow className={styles.row}>
      <Field
        name="taxNumber"
        dataId="input-tax-number"
        component={TextField}
        label={t('profile.company.labels.tax_number')}
        placeholder={t('profile.company.placeholders.tax_number')}
        required
      />
      <Field
        name="vatId"
        dataId="input-vat-id"
        component={InfoTextField}
        label={t('profile.company.labels.vat_id')}
        placeholder={t('profile.company.placeholders.vat_id')}
        message={t('profile.company.vat_id_message')}
        tooltipPlacement="left"
        required
      />
      <Field
        name="taxOffice"
        dataId="input-tax-office"
        component={TextField}
        label={t('profile.company.labels.tax_office')}
        placeholder={t('profile.company.placeholders.tax_office')}
        required
      />
    </SectionRow>
  </>
);

export default TaxData;
