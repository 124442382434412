import React, { Component, createRef } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

import { getOcrResult, processInvoiceFiles } from 'actions/incoming-invoice/ocr';
import {
  deleteInvoiceFile,
  downloadFile,
  uploadInvoiceFile,
} from 'actions/incoming-invoice/uploaded-files';
import { showNotification as showNotificationAction } from 'actions/notification';
import { PROCESSING_STATUS } from 'constants/incoming-invoice/ocr';
import { documentOCRIncompliant, documentTooBig } from 'notifications/incoming-invoices-creator';
import { DEFAULT_STATE as defaultOcrState, parseOcrResponse } from 'reducers/incoming-invoice/ocr';
import { ocrEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { incomingInvoiceDetailsSelector } from 'selectors/incomingInvoice';
import { urlWithParams } from 'shared/utils';
import { t } from 'shared/utils';
import { incomingInvoiceUploadedFilename } from 'shared/utils/file-naming';
import generateFilesUploadInfo from 'shared/utils/ocr';
import If from 'components/Conditions/If';
import EmptyStateBanner from 'components/Dropzone/EmptyStateBanner/EmptyStateBanner';
import PreviewSection from 'components/InvoiceUploader/PreviewSection/PreviewSection';
import Loading from 'components/Loading';

import styles from './UploadInvoiceSection.module.css';

class UploadInvoiceSection extends Component {
  static defaultProps = {
    ocrEnabled: false,
    ocr: defaultOcrState,
    shouldTriggerOCR: false,
  };

  state = {
    isFileUploading: false,
  };

  dropzoneRef = createRef();

  componentDidUpdate({ ocr: { status: prevStatus } }) {
    const {
      ocr: { status },
    } = this.props;
    if (prevStatus !== status) {
      this.processOcrStatusChange(status);
    }
  }

  get isEmpty() {
    return isEmpty(this.props.uploadedFiles);
  }

  ocrDataPolling = () => {
    const { invoiceId, getInvoiceOcrData } = this.props;
    getInvoiceOcrData(invoiceId).then((data) => {
      const { status } = parseOcrResponse(data.response);
      if (status === PROCESSING_STATUS.PROCESSING) {
        setTimeout(this.ocrDataPolling, 1000);
      }
    });
  };

  attachUrlWithParams = (invoiceId, accessToken) => (file) => ({
    ...file,
    url: urlWithParams(`${paths.incomingInvoiceFile(invoiceId, file.id)}`, {
      access_token: accessToken,
    }),
  });

  processOcrStatusChange = (status) => {
    if (status === PROCESSING_STATUS.PROCESSING) this.ocrDataPolling();
  };

  showFilesErrors = (filesInfo = {}) => {
    const { showNotification, ocrEnabled } = this.props;
    if (ocrEnabled && !filesInfo.isOCRCompatibile) showNotification(documentOCRIncompliant);
    if (filesInfo.isTooBig) showNotification(documentTooBig);
  };

  // pre-upload check
  generateUploadingFilesInfo = (files) => {
    const filesCount = files.length;
    const filesSize = files
      .map((file) => file.size / 1024 ** 2)
      .reduce((sum, size) => sum + size, 0);
    return generateFilesUploadInfo(filesSize, filesCount);
  };

  // post-upload check
  generateUploadedFilesInfo = (files) => {
    const filesCount = files.length;
    const filesSize = files.map((file) => file.sizeMebibytes).reduce((sum, size) => sum + size, 0);
    return generateFilesUploadInfo(filesSize, filesCount);
  };

  setUploading = (value) => {
    this.setState({ isFileUploading: value });
  };

  uploadFile = (files = []) => {
    const { invoiceId, uploadFile, ocrEnabled, processInvoiceOcrData, shouldTriggerOCR } =
      this.props;

    if (!files.length) return;

    const filesInfo = this.generateUploadingFilesInfo(files);
    this.showFilesErrors(filesInfo);
    if (filesInfo.isTooBig) return; // early return on isTooBig

    this.setUploading(true);

    uploadFile(invoiceId, files).then(() => {
      this.setUploading(false);

      // Disable OCR on cancellation invoice and in edit mode
      if (!ocrEnabled || !shouldTriggerOCR) return;
      const uploadedFilesInfo = this.generateUploadedFilesInfo(this.props.uploadedFiles);
      this.showFilesErrors(uploadedFilesInfo);
      if (!uploadedFilesInfo.isOCRCompatibile) return;
      processInvoiceOcrData(invoiceId);
    });
  };

  openFilePicker = () => {
    this.dropzoneRef.current.open();
  };

  render() {
    const {
      accessToken,
      invoiceId,
      readonly,
      ocrEnabled,
      uploadedFiles,
      downloadFilePreview,
      deleteFile,
      shouldTriggerOCR,
    } = this.props;

    return (
      <div className={styles.main}>
        <If ok={!readonly} className={styles.main}>
          <Dropzone
            accept={['image/x-png', 'image/png', 'image/jpeg', 'image/tiff', 'application/pdf']}
            multiple={false}
            onDrop={this.uploadFile}
            ref={this.dropzoneRef}
          >
            {({ getRootProps, getInputProps, isDragAccept }) => (
              <>
                <If ok={this.state.isFileUploading}>
                  <Loading />
                </If>
                <If ok={!this.state.isFileUploading}>
                  <div
                    className={cx(styles.previewContainer, styles.dropzone, {
                      [styles.isActive]: isDragAccept,
                    })}
                    {...omit(getRootProps(), ['onClick'])}
                  >
                    <input {...getInputProps()} data-id="Dropzone:input" />
                    <If ok={this.isEmpty}>
                      <EmptyStateBanner
                        ocrEnabled={ocrEnabled && shouldTriggerOCR}
                        onNewFileButtonClick={this.openFilePicker}
                        title={t('expenses.upload.dropzone.title')}
                        subtitle={t('expenses.upload.dropzone.text')}
                        buttonLabel={t('expenses.upload.browse_files_invoice')}
                      />
                    </If>
                    <If ok={!this.isEmpty}>
                      <PreviewSection
                        invoiceId={invoiceId}
                        accessToken={accessToken}
                        files={uploadedFiles}
                        readonly={readonly}
                        ocrEnabled={ocrEnabled}
                        downloadFilePreview={downloadFilePreview}
                        deleteFile={deleteFile}
                        attachUrlWithParams={this.attachUrlWithParams}
                        invoiceUploadedFilenameGenerator={incomingInvoiceUploadedFilename}
                        onNewFileButtonClick={this.openFilePicker}
                      />
                    </If>
                  </div>
                </If>
              </>
            )}
          </Dropzone>
        </If>
        <If ok={readonly && !this.isEmpty && !this.state.isFileUploading}>
          <div className={styles.previewContainer}>
            <PreviewSection
              invoiceId={invoiceId}
              accessToken={accessToken}
              ocrEnabled={ocrEnabled}
              files={uploadedFiles}
              downloadFilePreview={downloadFilePreview}
              deleteFile={deleteFile}
              attachUrlWithParams={this.attachUrlWithParams}
              invoiceUploadedFilenameGenerator={incomingInvoiceUploadedFilename}
              readonly={readonly}
            />
          </div>
        </If>
      </div>
    );
  }
}

UploadInvoiceSection.propTypes = {
  readonly: PropTypes.bool,
  accessToken: PropTypes.string.isRequired,
  ocrEnabled: PropTypes.bool,
  invoiceId: PropTypes.string.isRequired,
  uploadFile: PropTypes.func.isRequired,
  downloadFilePreview: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  ocr: PropTypes.shape({ status: PropTypes.string.isRequired }),
  processInvoiceOcrData: PropTypes.func.isRequired,
  getInvoiceOcrData: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })).isRequired,
  showNotification: PropTypes.func.isRequired,
  shouldTriggerOCR: PropTypes.bool,
};

export default connect(
  (state) => ({
    accessToken: state.auth.token,
    invoiceId: incomingInvoiceDetailsSelector(state).id,
    uploadedFiles: state.incomingInvoice.uploadedFiles,
    ocrEnabled: ocrEnabledHelper(state),
    ocr: state.incomingInvoice.ocr,
  }),
  (dispatch) => ({
    uploadFile: (invoiceId, files) => dispatch(uploadInvoiceFile(invoiceId, files)),
    downloadFilePreview: (...args) => dispatch(downloadFile(...args)),
    deleteFile: (invoiceId) => (id) => () => dispatch(deleteInvoiceFile(invoiceId, id)),
    processInvoiceOcrData: (...args) => dispatch(processInvoiceFiles(...args)),
    getInvoiceOcrData: (...args) => dispatch(getOcrResult(...args)),
    showNotification: (notification) => dispatch(showNotificationAction(notification)),
  })
)(UploadInvoiceSection);
