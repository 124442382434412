import React from 'react';
import { bool, func } from 'prop-types';

import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import Modal from 'components/Modal';

import styles from '../Buttons.module.css';

const CancelBasicModal = ({ isOpen, onAccept, onClose, buttonDisabled }) => (
  <Modal isOpen={isOpen} onRequestClose={onClose}>
    <div className={styles.header} data-id="PaymentPlans:cancelBasicModal">
      <span className={styles.text}>{t('profile.payment_plan.cancel_basic_modal.header')}</span>
      <button className={styles.cancel} onClick={onClose} />
    </div>
    <div className={styles.modalBody}>
      <span className={styles.subHeader}>
        {t('profile.payment_plan.cancel_basic_modal.sub_header')}
      </span>
      <div className={styles.paragraph}>{t('profile.payment_plan.cancel_basic_modal.body_1')}</div>
      <div className={styles.paragraph}>{t('profile.payment_plan.cancel_basic_modal.body_2')}</div>
      <div className={styles.footerSection}>
        <ActionButton
          appearance="outlined"
          onClick={onClose}
          label={t('profile.payment_plan.cancel_basic_modal.abort')}
        />
        <ActionButton
          disabled={buttonDisabled}
          appearance="primary"
          onClick={onAccept}
          label={t('profile.payment_plan.cancel_basic_modal.ok')}
          dataId="PaymentPlans:cancelBasicModalSubmit"
        />
      </div>
    </div>
  </Modal>
);

CancelBasicModal.propTypes = {
  isOpen: bool.isRequired,
  onAccept: func.isRequired,
  onClose: func.isRequired,
  buttonDisabled: bool.isRequired,
};

export default CancelBasicModal;
