import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push as reduxPush } from 'connected-react-router';
import PropTypes from 'prop-types';

import AbortButton from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/ActionsSection/AbortButton';
import PreviewButton from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/ActionsSection/PreviewButton';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import ActionPanel from 'components/ActionPanel';
import ActionDropdown from 'components/ActionPanel/ActionDropdown';
import I18n from 'components/I18n';
import { InfoModal } from 'components/Modal';

import EmailEditor from '../EmailEditor/EmailEditor';

import styles from './ActionsSection.module.css';

class ActionsSection extends Component {
  state = {
    sendEmailModalVisible: false,
  };

  setSendMailModal = (value) => this.setState({ sendEmailModalVisible: value });

  sendActions = () => {
    const downloadAction = {
      name: t('revenue.form.download'),
      onClick: this.downloadInvoice,
      tooltip: t('revenue.cancellation_invoice.download_tooltip'),
      dataId: 'OutgoingInvoicePage:button-download-pdf',
    };
    const sendByEmailAction = {
      name: t('revenue.form.send_email'),
      onClick: () => this.setSendMailModal(true),
      tooltip: t('revenue.cancellation_invoice.send_email_tooltip'),
      dataId: 'OutgoingInvoicePage:button-send-email',
    };

    return this.props.sendingDocumentsByEmailEnabled
      ? [downloadAction, sendByEmailAction]
      : [downloadAction];
  };

  downloadInvoice = async () => {
    const result = await this.props.saveInvoice();
    if (result.success) {
      this.props.downloadInvoice({ details: { number: result.invoiceNumber } });
    }
  };

  sendInvoice = async ({ email, ccEmail }) => {
    this.setSendMailModal(false);
    const result = await this.props.saveInvoice();
    if (result.success) {
      this.props.sendInvoice({ email, ccEmail });
    }
  };

  redirectAfterCreation = (isCancelled = false, invoiceId = '') => {
    const urlProps = `?expandCancelledInvoice=${invoiceId}`;

    this.props.push(`${paths.outgoingInvoices}${isCancelled ? urlProps : ''}`);
  };

  render() {
    const {
      abort,
      invoiceStatus,
      isInvoiceLoaded,
      isFormSubmitting,
      needsAnotherDatevReport,
      currentClient = {},
      saveInvoice,
      invoiceId,
      canSubmit,
    } = this.props;

    if (!isInvoiceLoaded) return null;

    const isSavingDisabled = isFormSubmitting;
    const isDraft = invoiceStatus === 'draft';

    const { alreadyCreated, showDialog } = needsAnotherDatevReport;
    if (alreadyCreated && !showDialog) {
      this.redirectAfterCreation(true, invoiceId);
    }
    const { sendEmailModalVisible } = this.state;

    return (
      <ActionPanel className={styles.creatorActionsSection}>
        <ActionDropdown
          upwards
          visible={isDraft}
          disabled={!canSubmit || isSavingDisabled}
          actions={this.sendActions()}
          title={t('revenue.form.download_or_cancel')}
          dataId="OutgoingInvoicePage:button-actions-dropdown"
        />
        <PreviewButton visible={isDraft} />
        <AbortButton onClick={abort} />
        <InfoModal
          isOpen={showDialog}
          onClose={this.redirectAfterCreation}
          message={t('modals.needs_another_datev_report.message')}
          header={t('modals.needs_another_datev_report.title')}
        >
          <I18n t="modals.needs_another_datev_report.message" />
        </InfoModal>
        {sendEmailModalVisible && (
          <EmailEditor
            onClose={() => this.setSendMailModal(false)}
            client={currentClient}
            title={t('modals.invoice_cancellation.send_email.title')}
            saveInvoice={saveInvoice}
          />
        )}
      </ActionPanel>
    );
  }
}

ActionsSection.propTypes = {
  invoiceId: PropTypes.number,
  isInvoiceLoaded: PropTypes.bool.isRequired,
  invoiceStatus: PropTypes.string,
  saveInvoice: PropTypes.func.isRequired,
  downloadInvoice: PropTypes.func.isRequired,
  sendInvoice: PropTypes.func.isRequired,
  abort: PropTypes.func.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired,
  needsAnotherDatevReport: PropTypes.shape({
    alreadyCreated: PropTypes.bool,
    showDialog: PropTypes.bool,
  }),
  currentClient: PropTypes.shape(),
  sendingDocumentsByEmailEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  invoiceId: state.outgoingInvoice.details.id,
  invoiceStatus: state.outgoingInvoice.details.status,
  isInvoiceLoaded: !!state.outgoingInvoice.details.id,
  needsAnotherDatevReport: state.outgoingInvoice.postCreateStatus,
  clients: state.clients.data,
});

export default connect(mapStateToProps, { push: reduxPush })(ActionsSection);
