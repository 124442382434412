import React, { memo, SVGProps } from 'react';

const Export = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M8.31 5c.408 0 .802.141 1.119.4l.13.12L10.8 6.76h7.04c.92 0 1.68.715 1.754 1.616l.006.144v3.175l-.621-.019H10.8l-.19.009c-1.28.147-2.19 1.162-2.19 2.372l.006.162a2.383 2.383 0 002.374 2.218l8.179-.001.621.05v.834c0 .92-.715 1.68-1.616 1.754l-.144.006H3.76c-.92 0-1.68-.715-1.754-1.616L2 17.32V6.76c0-.92.715-1.68 1.616-1.754L3.76 5h4.55zm12.868 6.243l.059.05 2.455 2.456a.434.434 0 01.043.556l-.052.06-2.455 2.455a.435.435 0 01-.742-.232l-.006-.076v-1.575H10.8a.883.883 0 01-.88-.88c0-.45.341-.823.778-.875l.102-.005h9.68V11.6a.44.44 0 01.698-.358z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default memo(Export);
