import React, { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDebounce } from 'react-use';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';

import { showSuccessNotification } from 'actions/notification';
import { updateInvoiceTemplate } from 'api/me/invoiceTemplates';
import {
  LOGO_AND_BRIEFPAPIER_DOWNLOAD,
  LOGO_AND_BRIEFPAPIER_PREVIEW_MODE_DOWNLOAD,
  LOGO_AND_BRIEFPAPIER_PREVIEW_MODE_ENTER,
  LOGO_AND_BRIEFPAPIER_SAVE,
} from 'constants/piwik';
import { invoiceLayoutEnabledHelper } from 'routes/accesses';
import { useAppDispatch, useAppSelector } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { InvoiceTemplate } from 'types/entities/InvoiceTemplate';
import Button from 'redesign/components/atoms/Button/Button';
import Checkbox from 'redesign/components/atoms/Checkbox/Checkbox';
import Radio from 'redesign/components/atoms/Radio/Radio';
import ControlLabel from 'redesign/components/molecules/ControlLabel/ControlLabel';
import RadioGroup from 'redesign/components/molecules/RadioGroup/RadioGroup';
import Zoom from 'redesign/components/molecules/Zoom/Zoom';
import RouteLeavingGuard from 'redesign/components/organisms/RouteLeavingGuard/RouteLeavingGuard';

import DiscardChangesModal from '../DiscardChangesModal/DiscardChangesModal';
import Preview from '../Preview/Preview';
import BackgroundField from './BackgroundField';
import { ZoomSteps } from './constants';
import {
  Buttons,
  Content,
  Description,
  FieldTitle,
  FormWrapper,
  IconArrowDown,
  PreviewWrapper,
  Section,
  Title,
  Wrapper,
} from './Form.styled';
import LogoField from './LogoField';
import { FormData } from './types';
import { getDefaultValues, trackEvent } from './utils';
import validationSchema from './validationSchema';

type FormProps = {
  invoiceTemplate: InvoiceTemplate;
};

const Form = ({ invoiceTemplate }: FormProps) => {
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState('logo');
  const [defaultValues, setDefaultValues] = useState(getDefaultValues(invoiceTemplate));
  const [forcePreviewRefresh, setForcePreviewRefresh] = useState(0);
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isValid, isSubmitSuccessful, dirtyFields },
  } = useForm<FormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const [previewValues, setPreviewValues] = useState(defaultValues);
  const values = watch();
  const isInvoiceLayoutEnabled = useAppSelector(invoiceLayoutEnabledHelper);
  const isNavigationBlocked = Object.keys(dirtyFields).some(
    (key) => !['logo', 'background'].includes(key)
  );

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    await updateInvoiceTemplate(invoiceTemplate.id, data);
    setDefaultValues(data);
    trackEvent(LOGO_AND_BRIEFPAPIER_SAVE);
    dispatch(
      showSuccessNotification(
        t('features.settings.document_template.logo_and_briefpapier.save_success')
      )
    );
  };

  const handleTitleClick = useCallback(
    (panel: string) => () => {
      setExpanded((active) => (active === panel ? '' : panel));
    },
    []
  );

  const handleOpenPreview = useCallback(() => {
    trackEvent(LOGO_AND_BRIEFPAPIER_PREVIEW_MODE_ENTER);
  }, []);

  const handleDownload = useCallback((isFromFullscreeenPreview: boolean) => {
    trackEvent(
      isFromFullscreeenPreview
        ? LOGO_AND_BRIEFPAPIER_PREVIEW_MODE_DOWNLOAD
        : LOGO_AND_BRIEFPAPIER_DOWNLOAD
    );
  }, []);

  const handleRefreshPreview = useCallback(() => {
    setForcePreviewRefresh((counter) => counter + 1);
  }, []);

  useDebounce(
    () => {
      if (!isValid || isEqual(previewValues, values)) return;

      setPreviewValues(values);
    },
    500,
    [isValid, values]
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset, defaultValues]);

  return (
    <Wrapper>
      <RouteLeavingGuard blocked={isNavigationBlocked} modal={DiscardChangesModal} />
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Section $isOpen={expanded === 'logo'}>
            <Title onClick={handleTitleClick('logo')}>
              {t('features.settings.document_template.logo_and_briefpapier.logo.title')}
              <IconArrowDown $isRotated={expanded === 'logo'} />
            </Title>
            <Content>
              <Description>
                {t('features.settings.document_template.logo_and_briefpapier.logo.description')}
              </Description>
              <div>
                <FieldTitle htmlFor="logo">
                  {t('features.settings.document_template.logo_and_briefpapier.fields.logo')}
                </FieldTitle>
                <LogoField
                  control={control}
                  invoiceTemplateId={invoiceTemplate.id}
                  onRefreshPreview={handleRefreshPreview}
                />
              </div>
              <Controller
                name="logoSizePercentage"
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <div>
                    <FieldTitle htmlFor={name}>
                      {t(
                        'features.settings.document_template.logo_and_briefpapier.fields.logo_size_percentage'
                      )}
                    </FieldTitle>
                    <Zoom
                      onChange={onChange}
                      onBlur={onBlur}
                      zoom={value}
                      name={name}
                      steps={ZoomSteps}
                      responsive={false}
                    />
                  </div>
                )}
              />
              <Controller
                name="logoPosition"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <div>
                    <FieldTitle htmlFor={name}>
                      {t(
                        'features.settings.document_template.logo_and_briefpapier.fields.logo_position'
                      )}
                    </FieldTitle>
                    <RadioGroup name={name} onChange={onChange} value={value} direction="row">
                      <ControlLabel
                        label={t(
                          'features.settings.document_template.logo_and_briefpapier.fields.logo_position_left'
                        )}
                        value="left"
                        control={<Radio />}
                      />
                      <ControlLabel
                        label={t(
                          'features.settings.document_template.logo_and_briefpapier.fields.logo_position_center'
                        )}
                        value="center"
                        control={<Radio />}
                      />
                      <ControlLabel
                        label={t(
                          'features.settings.document_template.logo_and_briefpapier.fields.logo_position_right'
                        )}
                        value="right"
                        control={<Radio />}
                      />
                    </RadioGroup>
                  </div>
                )}
              />
              <Controller
                name="logoOnAllPages"
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <ControlLabel
                    label={t(
                      'features.settings.document_template.logo_and_briefpapier.fields.logo_on_all_pages'
                    )}
                    control={<Checkbox />}
                    checked={value}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Content>
          </Section>
          {isInvoiceLayoutEnabled && (
            <Section $isOpen={expanded === 'briefpapier'}>
              <Title onClick={handleTitleClick('briefpapier')}>
                {t('features.settings.document_template.logo_and_briefpapier.briefpapier.title')}
                <IconArrowDown $isRotated={expanded === 'briefpapier'} />
              </Title>
              <Content>
                <Description>
                  {t(
                    'features.settings.document_template.logo_and_briefpapier.briefpapier.description'
                  )}
                </Description>
                <BackgroundField
                  control={control}
                  invoiceTemplateId={invoiceTemplate.id}
                  onRefreshPreview={handleRefreshPreview}
                />
                <Controller
                  name="backgroundOnlyOnFirstPage"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <ControlLabel
                      label={t(
                        'features.settings.document_template.logo_and_briefpapier.fields.background_only_on_first_page'
                      )}
                      control={<Checkbox />}
                      checked={value}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </Content>
            </Section>
          )}
          <Buttons>
            <Button type="submit">
              {t('features.settings.document_template.logo_and_briefpapier.save')}
            </Button>
          </Buttons>
        </div>
      </FormWrapper>
      <PreviewWrapper>
        <Preview
          values={{
            logo_size_percentage: previewValues.logoSizePercentage,
            logo_position: previewValues.logoPosition,
            logo_on_all_pages: previewValues.logoOnAllPages,
            background_only_on_first_page: previewValues.backgroundOnlyOnFirstPage,
          }}
          onOpenPreview={handleOpenPreview}
          onDownload={handleDownload}
          forceRefresh={forcePreviewRefresh}
        />
      </PreviewWrapper>
    </Wrapper>
  );
};

export default Form;
