import { CALL_API } from 'constants/api';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
} from 'constants/direct-debit';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiCreateDirectDebit = (data) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: `/me/direct_debit_mandates`,
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const createDirectDebit = (data) => (dispatch) => dispatch(apiCreateDirectDebit(data));

export const apiFetchDirectDebit = () => ({
  [CALL_API]: {
    endpoint: `/me/direct_debit_mandates/current`,
    method: 'GET',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const fetchDirectDebit = () => (dispatch) =>
  dispatch(apiFetchDirectDebit()).catch(apiErrorHandler);
