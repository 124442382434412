import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { fetchOrderConfirmation as fetchOrderConfirmationAction } from 'actions/order-confirmation';
import { fetchOrderConfirmationLineItems as fetchOrderConfirmationLineItemsAction } from 'actions/order-confirmation/line-items';
import { SHOW_MODE } from 'constants/common/crud';
import { FORM_NAME } from 'constants/order-confirmation';
import { withTransitionPrevent, withWarningAlert } from 'shared/hoc';
import { t } from 'shared/utils';
import useLineItemsControl, { SectionType } from 'components/LineItems/useLineItemControl';
import LoadingIcon from 'components/LoadingIcon';

import OrderConfirmationCreator from '../OrderConfirmationCreator/OrderConfirmationCreator';

const OrderConfirmationShow = ({
  orderConfirmationId,
  fetchOrderConfirmation,
  fetchOrderConfirmationLineItems,
  isFetching,
  isFetched,
}) => {
  const {
    manualHandleSelectCategory,
    defaultCategory,
    category,
    taxRates,
    taxRateId,
    defaultVat,
    hasSavedLineItems,
    selectedCategory,
    lineCategories,
  } = useLineItemsControl({
    sectionType: SectionType.orderConfirmation,
    formName: FORM_NAME,
  });

  useEffect(() => {
    fetchOrderConfirmation(orderConfirmationId);
    fetchOrderConfirmationLineItems(orderConfirmationId);
  }, []);

  if (isFetching) return <LoadingIcon />;
  if (!isFetched) return null;

  return (
    <OrderConfirmationCreator
      crudMode={SHOW_MODE}
      lineCategories={lineCategories}
      hasSavedLineItems={hasSavedLineItems}
      selectedCategory={selectedCategory}
      defaultCategory={defaultCategory}
      handleSelectCategory={manualHandleSelectCategory}
      category={category}
      taxRateId={taxRateId}
      taxRates={taxRates}
      defaultVat={defaultVat}
      canSubmit={true}
    />
  );
};

const mapStateToProps = (state, ownProps) => ({
  isFetching: state.orderConfirmation.isFetching,
  isFetched: state.orderConfirmation.isFetched,
  orderConfirmationId: ownProps.match.params.id,
  buttonLabel: t(
    'empty_entry_pages.order_confirmations.order_confirmation_warning_box.button_label'
  ),
  content: t('empty_entry_pages.order_confirmations.order_confirmation_warning_box.content_1'),
  dataId: 'OrderConfirmations:orange-warning-container',
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrderConfirmation: (id) => dispatch(fetchOrderConfirmationAction(id)),
  fetchOrderConfirmationLineItems: (id) => dispatch(fetchOrderConfirmationLineItemsAction(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    persistentSubmitErrors: true,
  }),
  withTransitionPrevent(),
  withWarningAlert
)(OrderConfirmationShow);
