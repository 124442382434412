import { AxiosPromise } from 'axios';
import { JsonApiResponse } from 'json-api-normalizer';

import api from './index';

export const getContents = (): AxiosPromise<JsonApiResponse> => {
  const url = '/contents';

  return api.get(url);
};
