import { HIDE_SIDEBAR, SHOW_SIDEBAR, SidebarSections } from 'constants/notifications-sidebar';
import {
  CATEGORY_NOTIFICATIONS_SIDEBAR,
  HIDE_TODOS,
  SHOW_TODOS,
  SWITCH_TO_TODOS,
} from 'constants/piwik';
import { piwikHelpers } from 'shared/utils/piwik';

const defaultState = {
  isOpen: false,
  activeSection: null,
};

const sendShowSectionEvent = (section, isSidebarOpen) => {
  switch (section) {
    case SidebarSections.TODOS:
      const eventId = isSidebarOpen ? SWITCH_TO_TODOS : SHOW_TODOS;
      return piwikHelpers.trackEvent(CATEGORY_NOTIFICATIONS_SIDEBAR, eventId);
    default:
      return null;
  }
};

const sendHideSidebarEvent = (section) => {
  switch (section) {
    case SidebarSections.TODOS:
      return piwikHelpers.trackEvent(CATEGORY_NOTIFICATIONS_SIDEBAR, HIDE_TODOS);
    default:
      return null;
  }
};

export default (state = defaultState, { type, activeSection } = {}) => {
  switch (type) {
    case SHOW_SIDEBAR:
      sendShowSectionEvent(activeSection);
      return {
        ...state,
        activeSection,
        isOpen: true,
      };
    case HIDE_SIDEBAR:
      sendHideSidebarEvent(state.activeSection);
      return {
        ...state,
        activeSection: null,
        isOpen: false,
      };
    default:
      return state;
  }
};
