import styled from 'styled-components';

import BaseModal from 'components/Modal';

export const Modal = styled(BaseModal)`
  padding: ${({ theme }) => `${theme.space[2]} ${theme.space[5]} 0`};

  span {
    white-space: nowrap;
  }
`;

export const Container = styled.div`
  display: flex;
  max-width: 530px;
  padding: ${({ theme }) => `${theme.space[10]} ${theme.space[12]}`};
  flex-direction: column;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;

  button {
    margin-left: ${({ theme }) => theme.space[3]};

    :first-child {
      margin-left: 0;
    }
  }
`;

export const Text = styled.div`
  margin-bottom: ${({ theme }) => theme.space[8]};

  p:first-child {
    margin-bottom: ${({ theme }) => theme.space[4]};
  }
`;
