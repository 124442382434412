import React from 'react';

import { t } from 'shared/utils';
import IconNodoc from 'components/Icons/IconNodoc';

import styles from './EmptyStateBanner.module.css';

const ReadonlyEmptyStateBanner = () => {
  return (
    <div className={styles.section}>
      <div>
        <IconNodoc className={styles.nodocIcon} />
        <p className={styles.sectionReadonly}>{t('contracts.empty_entry_state.no_doc')}</p>
      </div>
    </div>
  );
};

export default ReadonlyEmptyStateBanner;
