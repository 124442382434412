import { useCombobox, UseComboboxState, UseComboboxStateChangeOptions } from 'downshift';

import { CategoryResponse } from 'types/entities/Category';

export const buildCategories = (data: any[]) => {
  let result: any[] = [];

  data
    .filter((item: any) => item?.parentId === null)
    .sort((a: any, b: any) => a.id.localeCompare(b.id))
    .forEach((item: any) => {
      result = [
        ...result,
        item,
        ...data
          .filter((child: any) => parseInt(child.parentId) === parseInt(item.id))
          .sort((a: any, b: any) => a.id.localeCompare(b.id)),
      ];
    });

  return result;
};

export const stateReducer = (
  state: UseComboboxState<CategoryResponse>,
  actionAndChanges: UseComboboxStateChangeOptions<CategoryResponse>
) => {
  const { type, changes } = actionAndChanges;

  switch (type) {
    case useCombobox.stateChangeTypes.FunctionOpenMenu:
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.ItemClick:
      return changes.selectedItem?.parentId === null
        ? {
            ...changes,
            isOpen: state.isOpen,
            highlightedIndex: state.highlightedIndex,
            selectedItem: state.selectedItem,
            inputValue: state.inputValue,
          }
        : changes;

    case useCombobox.stateChangeTypes.InputBlur:
    case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
      return changes.selectedItem?.parentId === null
        ? {
            ...changes,
            selectedItem: state.selectedItem,
            inputValue: state.inputValue,
          }
        : changes;

    case useCombobox.stateChangeTypes.FunctionSelectItem:
      return changes.selectedItem === null
        ? {
            ...changes,
            isOpen: true,
            selectedItem: null,
            inputValue: '',
          }
        : changes;

    case useCombobox.stateChangeTypes.InputKeyDownEscape:
      return {
        ...changes,
        isOpen: false,
        selectedItem: state.selectedItem,
        inputValue: state.inputValue,
      };

    default:
      return changes;
  }
};
