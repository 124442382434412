import { t } from 'shared/utils';

export const updateFailure = {
  duration: 3000,
  title: t('feature.delivery_note.form.save_failure.title'),
  variant: 'warning',
};

export const lineItemUpdateSuccess = {
  duration: 3000,
  title: t('notifications.delivery_notes.line_items.update_success.title'),
  variant: 'success',
};

export const lineItemUpdateFailure = {
  duration: 3000,
  title: t('notifications.delivery_notes.line_items.update_failure.title'),
  variant: 'warning',
};

export const lineItemCreateSuccess = {
  duration: 3000,
  title: t('notifications.delivery_notes.line_items.create_success.title'),
  variant: 'success',
};

export const lineItemCreateFailure = {
  duration: 3000,
  title: t('notifications.delivery_notes.line_items.create_failure.title'),
  variant: 'warning',
};

export const lineItemDeleteSuccess = {
  duration: 3000,
  title: t('notifications.delivery_notes.line_items.delete_success.title'),
  variant: 'success',
};

export const lineItemDeleteFailure = {
  duration: 3000,
  title: t('notifications.delivery_notes.line_items.delete_failure.title'),
  variant: 'warning',
};

export const downloadSuccess = {
  duration: 3000,
  title: t('notifications.delivery_notes.download_success.title'),
  variant: 'success',
};

export const downloadDraftSuccess = {
  duration: 3000,
  title: t('notifications.delivery_notes.download_draft_success.title'),
  variant: 'success',
};

export const downloadSuccessCancelMode = {
  duration: 3000,
  title: t('notifications.delivery_notes.download_success.cancel_title'),
  variant: 'success',
};

export const deleteSuccess = {
  duration: 3000,
  title: t('notifications.delivery_notes.delete_success.title'),
  variant: 'success',
};
