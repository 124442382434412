import React from 'react';
import Recaptcha from 'react-recaptcha';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { download as downloadAC } from 'actions/datev-download';
import { changeGrecaptchaResponse as changeGrecaptchaResponseAC } from 'actions/grecaptcha';
import { noop, t } from 'shared/utils';
import { existChecker } from 'shared/utils/form-checking';
import getGrecaptchaKey from 'shared/utils/grecaptcha';
import { FieldNotification, FieldsGroup, PasswordField, SubmitButton } from 'components/Form';

import formPageStyles from '../../../Shared.module.css';
import styles from './Form.module.css';

const checker = ({ meta }) => existChecker(meta);

const Form = ({
  handleSubmit,
  id,
  download,
  downloadFailed,
  submitting,
  grecaptchaResponse,
  verifyGrecaptchaCallback,
}) => (
  <div>
    <div className={formPageStyles.title}>{t('datev.download.title')}</div>
    <div className={formPageStyles.subtitle}> {t('datev.download.subtitle.line_1')}</div>
    <div className={formPageStyles.subtitle}> {t('datev.download.subtitle.line_2')}</div>
    <form className={styles.main} onSubmit={handleSubmit(download(id, grecaptchaResponse))}>
      <Field
        component={PasswordField}
        name="password"
        placeholder={`${t('datev.download.placeholder')} *`}
        type="password"
        checker={checker}
      />

      <FieldNotification
        show={downloadFailed}
        message={t('datev.download.error')}
        variant="warning"
      />

      <FieldsGroup className={styles.recaptchaWrapper}>
        <Recaptcha
          sitekey={getGrecaptchaKey()}
          verifyCallback={verifyGrecaptchaCallback}
          onloadCallback={noop}
          render="explicit"
          hl="de"
        />
      </FieldsGroup>

      <FieldsGroup className={cx(styles.fieldsGroup, styles.fieldsGroupSubmit)}>
        <SubmitButton
          active={!submitting}
          className={styles.submitButton}
          name="submit"
          value={t('datev.download.submit')}
        />
      </FieldsGroup>
    </form>
  </div>
);

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
  downloadFailed: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  id: PropTypes.string,
  grecaptchaResponse: PropTypes.string,
  verifyGrecaptchaCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  downloadFailed: state.datevDownload.download.error,
  submitting: state.datevDownload.isFetching,
  ...state.grecaptcha,
});

const mapDispatchToProps = (dispatch) => ({
  download: (id, grecaptchaResponse) => (data) =>
    dispatch(downloadAC(id, grecaptchaResponse, data)),
  verifyGrecaptchaCallback: (response) => {
    dispatch(changeGrecaptchaResponseAC(response));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
