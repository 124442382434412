import React, { SVGAttributes } from 'react';

const IconLanding = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 288 181"
      width={288}
      {...props}
    >
      <defs>
        <filter
          x="-.6%"
          y="-.7%"
          width="101.1%"
          height="101.7%"
          filterUnits="objectBoundingBox"
          id="prefix__a"
        >
          <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0.788235294 0 0 0 0 0.788235294 0 0 0 0 0.788235294 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <filter
          x="-9.7%"
          y="-7%"
          width="119.3%"
          height="119.7%"
          filterUnits="objectBoundingBox"
          id="prefix__c"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
        </filter>
        <filter
          x="-6%"
          y="-4.4%"
          width="112%"
          height="112.2%"
          filterUnits="objectBoundingBox"
          id="prefix__e"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
        </filter>
        <filter
          x="-9.7%"
          y="-7%"
          width="119.3%"
          height="119.7%"
          filterUnits="objectBoundingBox"
          id="prefix__g"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
        </filter>
        <filter
          x="-21.3%"
          y="-16%"
          width="142.6%"
          height="137.2%"
          filterUnits="objectBoundingBox"
          id="prefix__i"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={4.5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
        </filter>
        <ellipse id="prefix__d" cx={57.219} cy={139.5} rx={36.219} ry={35.5} />
        <ellipse id="prefix__f" cx={58.545} cy={57.384} rx={58.545} ry={57.384} />
        <ellipse id="prefix__h" cx={242.219} cy={59.5} rx={36.219} ry={35.5} />
        <path
          d="M2 0h1166a2 2 0 012 2v748a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z"
          id="prefix__b"
        />
        <path id="prefix__j" d="M0 0h68v78H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M-671-158H769V868H-671z" fill="#E6E6E6" />
        <g transform="translate(-421 -74)">
          <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
          <use fill="#FFF" xlinkHref="#prefix__b" />
        </g>
        <path
          d="M98.402 12.813c28.698 9.784 37.439 19.029 59.422 9.784 21.984-9.244 38.437-9.45 58.784-9.45 20.346 0 21.372 15.25 39.001 28.922 17.63 13.67 47.79 22.406 23 57.843-24.789 35.436-.625 2.016-42 36.9s-99.004 21.94-127.005 14.96c-28.001-6.982-25.374-20.259-52.002-33.909-26.628-13.65-47.255-8.055-55.858-20.189C-6.86 85.541 18.865 62.7 24.6 49.05 30.336 35.4 69.704 3.028 98.402 12.813z"
          fill="#F3F3F3"
        />
        <path d="M58 128c-5-4-36-13-24-44s62-49 66 17" stroke="#F60" strokeDasharray="4,4" />
        <use fill="#000" filter="url(#prefix__c)" xlinkHref="#prefix__d" />
        <use fill="#FFF" xlinkHref="#prefix__d" />
        <g strokeLinejoin="round">
          <path
            stroke="#FFF"
            strokeWidth={2.4}
            strokeLinecap="round"
            d="M66.862 153.166H47.115l-4.478-16.249 28.726-.009zM57 136.781v15.406m-7.432-16.13l2.37 16.837m10.124 0l2.378-16.838M45 142.22h24.75m-23.25 5.437h21M42 134.97h30v1.812H42z"
          />
          <path
            stroke="#FFF"
            strokeWidth={2.4}
            strokeLinecap="round"
            d="M66.862 153.166H47.115l-4.478-16.249 28.726-.009zM48.21 134.443l7.117-8.61m10.463 8.61l-7.117-8.61m7.117 8.61l-7.117-8.61m7.117 8.61l-7.117-8.61"
          />
          <path
            stroke="#0066B3"
            strokeWidth={1.2}
            fill="#FFF"
            fillRule="nonzero"
            d="M66.862 153.166H47.115l-4.478-16.249 28.726-.009z"
          />
          <path stroke="#F60" strokeWidth={1.2} strokeLinecap="round" d="M57 136.781v15.406" />
          <path
            stroke="#F60"
            strokeWidth={1.194}
            fill="#FFF"
            fillRule="nonzero"
            strokeLinecap="round"
            d="M49.568 136.056l2.37 16.838m10.124 0l2.378-16.838"
          />
          <path
            stroke="#F60"
            strokeWidth={1.2}
            strokeLinecap="round"
            d="M45 142.219h24.75m-23.25 5.437h21"
          />
          <path
            stroke="#0066B3"
            strokeWidth={1.2}
            fill="#0066B3"
            fillRule="nonzero"
            d="M42 134.969h30v1.812H42z"
          />
          <path
            stroke="#0066B3"
            strokeWidth={1.2}
            d="M66.862 153.166H47.115l-4.478-16.249 28.726-.009z"
          />
          <path
            stroke="#0066B3"
            strokeWidth={1.2}
            strokeLinecap="round"
            d="M48.21 134.443l7.117-8.61m10.463 8.61l-7.117-8.61m7.117 8.61l-7.117-8.61"
          />
        </g>
        <path
          d="M194 108c2 39.333 17 54.667 45 46 28-8.667 33-31.333 15-68"
          stroke="#F60"
          strokeDasharray="4,4"
        />
        <g transform="translate(85 24)">
          <use fill="#000" filter="url(#prefix__e)" xlinkHref="#prefix__f" />
          <use fill="#FFF" xlinkHref="#prefix__f" />
        </g>
        <use fill="#000" filter="url(#prefix__g)" xlinkHref="#prefix__h" />
        <use fill="#FFF" xlinkHref="#prefix__h" />
        <g strokeLinecap="round" strokeLinejoin="round">
          <path
            d="M255.69 57.79h4.8m-12.45-7.751h4.8m-2.4-2.417v4.833"
            stroke="#FFF"
            strokeWidth={2.4}
          />
          <path
            d="M255.69 57.79h4.8m-12.45-7.751h4.8m-2.4-2.417v4.833"
            stroke="#0066B3"
            strokeWidth={1.2}
          />
          <path stroke="#FFF" strokeWidth={2.4} d="M250.044 58.086l7.668-7.721" />
          <path stroke="#F60" strokeWidth={1.2} d="M250.044 58.086l7.668-7.721" />
          <path
            d="M244.242 47.139H237.6V67.68c0 .997 1.47 1.765 3.3 1.765 1.83 0 3.306-.75 3.306-1.746l.036-20.56z"
            stroke="#FFF"
            strokeWidth={2.4}
          />
          <path
            d="M244.242 46.964c0 .99-1.482 1.812-3.306 1.812s-3.306-.804-3.306-1.812c0-1.01 1.482-1.813 3.306-1.813s3.306.822 3.306 1.813zm-.006 3.069c0 .997-1.482 1.812-3.306 1.812s-3.33-.803-3.33-1.812m6.636 2.96c0 .991-1.482 1.813-3.306 1.813s-3.33-.822-3.33-1.813m6.636 2.96c0 .991-1.482 1.813-3.306 1.813s-3.33-.822-3.33-1.812m6.636 2.942c0 .99-1.482 1.812-3.306 1.812s-3.33-.81-3.33-1.812m6.636 2.972c0 .991-1.482 1.813-3.306 1.813s-3.33-.822-3.33-1.813m6.636 2.84c0 .99-1.482 1.812-3.306 1.812s-3.33-.821-3.33-1.812"
            stroke="#FFF"
            strokeWidth={2.4}
          />
          <path
            d="M238.14 59.554h-6.6v11.328c0 .991 1.482 1.861 3.306 1.861s3.306-.906 3.306-1.897l-.012-11.292z"
            stroke="#FFF"
            strokeWidth={2.4}
          />
          <path
            d="M238.134 59.5c0 .997-1.482 1.813-3.306 1.813s-3.306-.804-3.306-1.813 1.482-1.813 3.306-1.813 3.306.798 3.306 1.813z"
            stroke="#FFF"
            strokeWidth={2.4}
          />
          <path
            d="M238.134 59.446c0 .997-1.482 1.812-3.306 1.812s-3.306-.803-3.306-1.812m6.612 2.96c0 .99-1.482 1.813-3.306 1.813s-3.306-.804-3.306-1.813m6.612 2.96c0 .991-1.482 1.813-3.306 1.813s-3.306-.804-3.306-1.813m6.612 2.955c0 .99-1.482 1.812-3.306 1.812s-3.306-.81-3.306-1.812m16.92-1.698H241.8v5.39c0 .99 1.47 1.812 3.3 1.812 1.83 0 3.306-.84 3.306-1.813l.036-5.389z"
            stroke="#FFF"
            strokeWidth={2.4}
          />
          <path
            d="M248.442 66.593c0 .99-1.482 1.812-3.306 1.812s-3.306-.803-3.306-1.812 1.482-1.813 3.306-1.813 3.306.816 3.306 1.813zm-.006 2.78c0 .99-1.482 1.812-3.306 1.812s-3.33-.81-3.33-1.813"
            stroke="#FFF"
            strokeWidth={2.4}
          />
          <path
            d="M244.242 47.139H237.6V67.68c0 .997 1.47 1.765 3.3 1.765 1.83 0 3.306-.75 3.306-1.746l.036-20.56z"
            stroke="#F60"
            strokeWidth={1.2}
            fill="#FFF"
            fillRule="nonzero"
          />
          <path
            d="M244.242 46.964c0 .99-1.482 1.812-3.306 1.812s-3.306-.804-3.306-1.812c0-1.01 1.482-1.813 3.306-1.813s3.306.822 3.306 1.813zm-.006 3.069c0 .997-1.482 1.812-3.306 1.812s-3.33-.803-3.33-1.812m6.636 2.96c0 .991-1.482 1.813-3.306 1.813s-3.33-.822-3.33-1.813m6.636 2.96c0 .991-1.482 1.813-3.306 1.813s-3.33-.822-3.33-1.812m6.636 2.942c0 .99-1.482 1.812-3.306 1.812s-3.33-.81-3.33-1.812m6.636 2.972c0 .991-1.482 1.813-3.306 1.813s-3.33-.822-3.33-1.813m6.636 2.84c0 .99-1.482 1.812-3.306 1.812s-3.33-.821-3.33-1.812"
            stroke="#F60"
            strokeWidth={1.2}
            fill="#FFF"
            fillRule="nonzero"
          />
          <path
            d="M238.14 59.554h-6.6v11.328c0 .991 1.482 1.861 3.306 1.861s3.306-.906 3.306-1.897l-.012-11.292z"
            stroke="#0066B3"
            strokeWidth={1.2}
            fill="#FFF"
            fillRule="nonzero"
          />
          <path
            d="M238.134 59.5c0 .997-1.482 1.813-3.306 1.813s-3.306-.804-3.306-1.813 1.482-1.813 3.306-1.813 3.306.798 3.306 1.813z"
            stroke="#0066B3"
            strokeWidth={1.2}
            fill="#FFF"
            fillRule="nonzero"
          />
          <path
            d="M238.134 59.446c0 .997-1.482 1.812-3.306 1.812s-3.306-.803-3.306-1.812m6.612 2.96c0 .99-1.482 1.813-3.306 1.813s-3.306-.804-3.306-1.813m6.612 2.96c0 .991-1.482 1.813-3.306 1.813s-3.306-.804-3.306-1.813m6.612 2.955c0 .99-1.482 1.812-3.306 1.812s-3.306-.81-3.306-1.812m16.92-1.698H241.8v5.39c0 .99 1.47 1.812 3.3 1.812 1.83 0 3.306-.84 3.306-1.813l.036-5.389z"
            stroke="#0066B3"
            strokeWidth={1.2}
            fill="#FFF"
            fillRule="nonzero"
          />
          <path
            d="M248.442 66.593c0 .99-1.482 1.812-3.306 1.812s-3.306-.803-3.306-1.812 1.482-1.813 3.306-1.813 3.306.816 3.306 1.813zm-.006 2.78c0 .99-1.482 1.812-3.306 1.812s-3.33-.81-3.33-1.813"
            stroke="#0066B3"
            strokeWidth={1.2}
            fill="#FFF"
            fillRule="nonzero"
          />
        </g>
        <g transform="translate(110 43)">
          <use fill="#000" filter="url(#prefix__i)" xlinkHref="#prefix__j" />
          <use fill="#FFF" xlinkHref="#prefix__j" />
        </g>
        <path
          stroke="#198228"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="bevel"
          d="M160 60.5h7.714"
        />
        <text
          fontFamily="FrutigerVR-Roman, Frutiger VR"
          fontSize={11}
          fill="#198228"
          transform="translate(114 53)"
        >
          <tspan x={35} y={10}>
            {'+'}
          </tspan>
        </text>
        <text
          fontFamily="FrutigerVR-Roman, Frutiger VR"
          fontSize={11}
          fill="#198228"
          transform="translate(114 53)"
        >
          <tspan x={35} y={28}>
            {'+'}
          </tspan>
        </text>
        <text
          fontFamily="FrutigerVR-Roman, Frutiger VR"
          fontSize={11}
          fill="#AA0028"
          transform="translate(114 53)"
        >
          <tspan x={36} y={19}>
            {'-'}
          </tspan>
        </text>
        <text
          fontFamily="FrutigerVR-Roman, Frutiger VR"
          fontSize={11}
          fill="#AA0028"
          transform="translate(114 53)"
        >
          <tspan x={36} y={37}>
            {'-'}
          </tspan>
        </text>
        <path
          stroke="#AA0028"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="bevel"
          d="M160 86.5h7.714"
        />
        <path
          stroke="#198228"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="bevel"
          d="M160 78.5h7.714"
        />
        <path
          stroke="#AA0028"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="bevel"
          d="M160 69.5h7.714"
        />
        <path
          stroke="#979797"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="bevel"
          d="M117.295 60H138m-20.705 9.5h25.41m-25.41 8.5H137m-19.705 9H141"
        />
        <path stroke="#198228" strokeWidth={5} strokeLinecap="round" d="M154.333 101h13.334" />
        <path stroke="#979797" strokeLinecap="square" d="M114.34 96h59.32M150 106h22m-22 2h22" />
      </g>
    </svg>
  );
};

export default IconLanding;
