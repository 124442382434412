import React, { SVGAttributes } from 'react';
import cx from 'classnames';

import CaretUp from './CaretUp';

import styles from './CaretDown.module.css';

const CaretDown = (props: SVGAttributes<SVGSVGElement>) => (
  <CaretUp {...props} className={cx(styles.svg, props.className)} />
);

export default CaretDown;
