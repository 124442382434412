import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { get, orderBy } from 'lodash';
import PropTypes from 'prop-types';

import { ITEMS_DROPPABLE_ID } from 'constants/delivery-note';
import { t } from 'shared/utils';
import {
  LineItemsAutoSaveConsumer,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
} from 'components/LineItems';
import lineItemsStyles from 'components/LineItems/LineItems.module.css';

import NewItemRow from './NewItemRow';
import Row from './Row';

import styles from './Table.module.css';

const headers = (readonly) => [
  {
    name: t('features.delivery_note.form.details_section.fields.position'),
    className: lineItemsStyles.positionCell,
    customWidth: true,
  },
  {
    name: t('features.delivery_note.form.details_section.fields.quantity'),
    className: styles.quantityCell,
    customWidth: true,
  },
  { name: t('features.delivery_note.form.details_section.fields.unit') },
  {
    name: t('features.delivery_note.form.details_section.fields.actions'),
    className: cx(lineItemsStyles.actionsHeader, { [lineItemsStyles.actionsCellHidden]: readonly }),
    customWidth: true,
  },
];

const LineItemsTable = ({
  lineItems = [],
  newItems = [],
  removeNewItem,
  readonly,
  hasErrors,
  crudMode,
}) => (
  <Table hasErrors={hasErrors} readonly={readonly}>
    <TableHeader>
      {headers(readonly).map(({ name, className, customWidth, alignRight }, index) => (
        <TableHeaderCell
          key={`${index}-${name}`}
          title={name}
          className={className}
          customWidth={customWidth}
          alignRight={alignRight}
        />
      ))}
    </TableHeader>
    <LineItemsAutoSaveConsumer>
      {({
        addNewLineItemCreator,
        addEditLineItemCreator,
        removeNewLineItemCreator,
        removeEditLineItemCreator,
      }) => (
        <TableBody droppableId={ITEMS_DROPPABLE_ID}>
          {({ provided }) => (
            <>
              {orderBy(lineItems, (item) => item.ordinalNumber).map((item) => (
                <Row
                  key={item.id}
                  lineItem={item}
                  dndId={item.id}
                  dndIndex={item.ordinalNumber}
                  readonly={readonly}
                  addEditLineItemCreator={addEditLineItemCreator}
                  removeEditLineItemCreator={removeEditLineItemCreator}
                  crudMode={crudMode}
                />
              ))}
              {provided.placeholder}
              {newItems.map((item) => (
                <NewItemRow
                  key={item.rowId}
                  newItem={item}
                  prefilled={item.prefilled}
                  markAsAccepted={item.markAsAccepted}
                  removeItem={removeNewItem(item.rowId)}
                  readonly={readonly}
                  addNewLineItemCreator={addNewLineItemCreator}
                  removeNewLineItemCreator={removeNewLineItemCreator}
                  crudMode={crudMode}
                  isDeliveryNote
                />
              ))}
            </>
          )}
        </TableBody>
      )}
    </LineItemsAutoSaveConsumer>
  </Table>
);

LineItemsTable.propTypes = {
  newItems: PropTypes.arrayOf(PropTypes.shape({})),
  lineItems: PropTypes.arrayOf(PropTypes.shape({})),
  removeNewItem: PropTypes.func,
  readonly: PropTypes.bool,
  hasErrors: PropTypes.bool,
  crudMode: PropTypes.string,
};

export default connect((state) => ({
  hasErrors:
    get(state, 'form.deliveryNoteCreator.submitErrors.base') &&
    !state.deliveryNote.lineItems.length,
}))(LineItemsTable);
