const NAMESPACE = 'INCOMING_INVOICE/PAYMENTS';

export const FORM_NAME = 'paymentCreator';

export const INDEX_REQUEST = `${NAMESPACE}/INDEX_REQUEST`;
export const INDEX_SUCCESS = `${NAMESPACE}/INDEX_SUCCESS`;
export const INDEX_FAILURE = `${NAMESPACE}/INDEX_FAILURE`;

export const INIT_REQUEST = `${NAMESPACE}/INIT_REQUEST`;
export const INIT_SUCCESS = `${NAMESPACE}/INIT_SUCCESS`;
export const INIT_FAILURE = `${NAMESPACE}/INIT_FAILURE`;

export const NEW_REQUEST = `${NAMESPACE}/NEW_REQUEST`;
export const NEW_SUCCESS = `${NAMESPACE}/NEW_SUCCESS`;
export const NEW_FAILURE = `${NAMESPACE}/NEW_FAILURE`;

export const CREATE_REQUEST = `${NAMESPACE}/CREATE_REQUEST`;
export const CREATE_SUCCESS = `${NAMESPACE}/CREATE_SUCCESS`;
export const CREATE_FAILURE = `${NAMESPACE}/CREATE_FAILURE`;

export const FETCH_BANK_ACCOUNTS_REQUEST = `${NAMESPACE}/FETCH_BANK_ACCOUNTS_REQUEST`;
export const FETCH_BANK_ACCOUNTS_SUCCESS = `${NAMESPACE}/FETCH_BANK_ACCOUNTS_SUCCESS`;
export const FETCH_BANK_ACCOUNTS_FAILURE = `${NAMESPACE}/FETCH_BANK_ACCOUNTS_FAILURE`;

export const GET_SCA_REQUEST = `${NAMESPACE}/GET_SCA_REQUEST`;
export const GET_SCA_SUCCESS = `${NAMESPACE}/GET_SCA_SUCCESS`;
export const GET_SCA_FAILURE = `${NAMESPACE}/GET_SCA_FAILURE`;

export const SELECT_SCA_REQUEST = `${NAMESPACE}/SELECT_SCA_REQUEST`;
export const SELECT_SCA_SUCCESS = `${NAMESPACE}/SELECT_SCA_SUCCESS`;
export const SELECT_SCA_FAILURE = `${NAMESPACE}/SELECT_SCA_FAILURE`;

export const SOLVE_SCA_REQUEST = `${NAMESPACE}/SOLVE_SCA_REQUEST`;
export const SOLVE_SCA_SUCCESS = `${NAMESPACE}/SOLVE_SCA_SUCCESS`;
export const SOLVE_SCA_FAILURE = `${NAMESPACE}/SOLVE_SCA_FAILURE`;

export enum PaymentStep {
  PaymentDetails = 1,
  Login,
  MethodSelectionChallenge,
  EmbeddedChallenge,
  DecoupledChallenge,
  RedirectChallenge,
  PaymentSummary,
}
