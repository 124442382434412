import { isToday, parse } from 'date-fns';

import { BankAccount } from 'types/entities/BankAccount';

export const isUpToDate = (account: BankAccount) => {
  const lastUpdated =
    account.figoBalanceDate && parse(account.figoBalanceDate, 'dd.MM.yyyy', new Date());
  const isUpToDate = lastUpdated && isToday(lastUpdated);

  return Boolean(isUpToDate);
};
