import React from 'react';

import { t } from 'shared/utils';
import DraftStatusIcon from 'components/StatusIcons/Invoice/DraftStatusIcon';
import PaidStatusIcon from 'components/StatusIcons/Invoice/PaidStatusIcon';
import { Status as DeliveryNoteStatus } from 'features/deliveryNotes/types';

import { Status, Text } from './StatusBadge.styled';

type StatusBadgeProps = {
  status: DeliveryNoteStatus;
  dataId?: string;
};

const getStatusIcon = (status: DeliveryNoteStatus) => {
  switch (status) {
    case DeliveryNoteStatus.Draft:
      return <DraftStatusIcon />;
    case DeliveryNoteStatus.Saved:
      return <PaidStatusIcon />;
    default:
      return null;
  }
};

const StatusBadge = ({ status, dataId = 'status' }: StatusBadgeProps) => {
  if (!status) return null;

  return (
    <Status status={status}>
      {getStatusIcon(status)}
      <Text data-id={dataId}>{t(`delivery_notes.table.columns.status.${status}`)}</Text>
    </Status>
  );
};

export default StatusBadge;
