import styled from 'styled-components';

import media from 'shared/styles/breakpoints';
import { ReminderType as ReminderTypeEnum } from 'types/entities/Cashbook';

import { NoReminderOption } from './constants';

type InputProps = {
  reminderType: ReminderTypeEnum | typeof NoReminderOption | undefined;
};

export const Inputs = styled.div<InputProps>`
  display: grid;
  grid-row-gap: 15px;

  ${media.greaterThan<InputProps>('md-up')`
    grid-template-columns: 222px 89px;
    grid-column-gap: 28px;

    ${(props) => {
      switch (props.reminderType) {
        case NoReminderOption:
        case undefined:
          return `grid-template-areas: "reminder-type .";`;
        case ReminderTypeEnum.Daily:
          return `grid-template-areas: "reminder-type reminder-hour";`;
        case ReminderTypeEnum.Weekly:
          return `grid-template-areas: "reminder-type ."
                                       "reminder-day  reminder-hour";`;
        case ReminderTypeEnum.Monthly:
          return `grid-template-areas: "reminder-type     ."
                                       "monthly-reminder  reminder-hour";`;
        default:
          return '';
      }
    }}
  `}
`;

export const ReminderType = styled.div`
  ${media.greaterThan('md-up')`
    grid-area: reminder-type;
 `}
`;

export const ReminderDay = styled.div`
  ${media.greaterThan('md-up')`
    grid-area: reminder-day;
 `}
`;

export const ReminderHour = styled.div`
  ${media.greaterThan('md-up')`
    grid-area: reminder-hour;
 `}
`;

export const MonthlyReminder = styled.div`
  ${media.greaterThan('md-up')`
    grid-area: monthly-reminder;
 `}
`;
