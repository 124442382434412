import React, { cloneElement, isValidElement, ReactNode, useRef } from 'react';

import { useScrollableShades } from './hooks';
import { LeftShade, RightShade, Wrapper } from './HorizontalScrollShadow.styled';

type HorizontalScrollShadowProps = {
  className?: string;
  children: ReactNode;
};

const HorizontalScrollShadow = ({ className, children }: HorizontalScrollShadowProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { left, right } = useScrollableShades(ref);

  return (
    <Wrapper className={className}>
      {left && <LeftShade />}
      {right && <RightShade />}
      {isValidElement(children) && cloneElement(children, { ref })}
    </Wrapper>
  );
};

export default HorizontalScrollShadow;
