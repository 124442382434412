import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import SelectField from 'components/Form/SelectField';

import { CONTRACT_TYPE_OPTIONS } from './constants';
import { Buttons, Content, Form, Title } from './SelectContractType.styled';
import { checker, required } from './validation';

type FormData = {
  contractType: 'basic' | 'loan';
};

type SelectContractTypeProps = {
  onCancel: () => void;
  onSuccess: (values: FormData) => void;
};

const SelectContractType = ({
  onCancel,
  onSuccess,
  handleSubmit,
  invalid,
}: SelectContractTypeProps & InjectedFormProps<FormData, SelectContractTypeProps>) => {
  return (
    <Form onSubmit={handleSubmit(onSuccess)}>
      <Content>
        <Title>{t('features.bank_transfers.add_contract_modal.select_contract_type.title')}</Title>
        <Field
          name="contractType"
          component={SelectField}
          dataId="input-contract-type"
          label={t(
            'features.bank_transfers.add_contract_modal.select_contract_type.contract_type_placeholder'
          )}
          options={CONTRACT_TYPE_OPTIONS}
          validate={[required]}
          checker={checker}
          required
        />
      </Content>
      <Buttons>
        <ActionButton
          appearance="outlined"
          type="button"
          dataId="button-cancel"
          label={t('features.bank_transfers.add_contract_modal.cancel')}
          onClick={onCancel}
        />
        <ActionButton
          type="submit"
          dataId="button-continue"
          label={t('features.bank_transfers.add_contract_modal.continue')}
          disabled={invalid}
        />
      </Buttons>
    </Form>
  );
};

export default reduxForm<FormData, SelectContractTypeProps>({
  form: 'addContractModal/selectContractType',
})(SelectContractType);
