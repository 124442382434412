import DAYS_LEFT_TO_TRIAL from 'constants/days-left-to-trial';
import { DOWNGRADE, UPGRADE } from 'constants/payment-plans';
import getDaysToPremiumBilling from 'shared/utils/premium-billing-date';

interface CurrentPaymentPlanDetails {
  onTrial: boolean;
  trialEndDate: string;
  latestHistoryEntryMode: string;
}

/** Calculates date to premium biling and based on that indicates if wizard should be open or not*/

export const shouldDisplyaModal = (currentPaymentPlanDetails: CurrentPaymentPlanDetails) => {
  const { onTrial, trialEndDate, latestHistoryEntryMode } = currentPaymentPlanDetails;
  if (latestHistoryEntryMode === UPGRADE || latestHistoryEntryMode === DOWNGRADE) return;

  const { displayDate } = getDaysToPremiumBilling(trialEndDate);

  if (onTrial) {
    if (displayDate <= DAYS_LEFT_TO_TRIAL) return { shouldOpenModal: true };
  }
};
