import React from 'react';
import { connect } from 'react-redux';
import { bool, func, shape } from 'prop-types';

import { OUTGOING_INVOICE } from 'constants/folder-names';
import { outgoingInvoiceSelector } from 'reducers/form';
import isInvoicePaid from 'shared/utils/isInvoicePaid/is-invoice-paid';
import BankActivity from 'components/BankActivity/BankActivity';
import Card from 'components/Card';

import styles from './BankTransferAssigment.module.css';

const BankTransferAssigment = ({ isDraft, isReadonly, invoiceDetails }) => (
  <Card className={styles.root} id="assign-payment">
    <BankActivity
      disabled={isInvoicePaid(invoiceDetails)}
      isDraft={isDraft}
      readonly={isReadonly('bankActivity')}
      currentInvoiceDetails={invoiceDetails}
      whichFolder={OUTGOING_INVOICE}
    />
  </Card>
);

BankTransferAssigment.propTypes = {
  isReadonly: func.isRequired,
  isDraft: bool.isRequired,
  invoiceDetails: shape({}).isRequired,
};

const mapStateToProps = (s) => ({
  isDraft: outgoingInvoiceSelector(s, 'draft'),
  isPaidByCash: outgoingInvoiceSelector(s, 'paidByCash'),
});

export default connect(mapStateToProps)(BankTransferAssigment);
