import React from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';
import noop from 'lodash/noop';

import { FeatureStatuses } from 'constants/customer-notifications';
import checkedIcon from 'images/sidebar/accept.svg';

import Currency from '../Currency/Currency';

import styles from './TodoFeatureTask.module.css';

type TodoFeatureTaskProps = {
  number?: string;
  title: string;
  status: string;
  value?: number;
  icon: string;
  action: (props: TodoFeatureTaskProps) => void;
  isNegative?: boolean;
};

const TodoFeatureTask = (props: TodoFeatureTaskProps) => {
  const { number, title, status, value, icon, action, isNegative } = props;
  const numberLength = 7;
  const numberShortcut = ((number && number.substring(0, numberLength)) || '').padEnd(
    numberLength,
    ' '
  );
  const clickAction = status === FeatureStatuses.OPEN ? () => action(props) : noop;
  const ActionIcon =
    status === FeatureStatuses.OPEN
      ? () => <InlineSvg src={icon} />
      : () => <InlineSvg src={checkedIcon} className={styles.checkedIcon} />;

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <span className={styles.number} title={numberShortcut}>
          {numberShortcut}
        </span>
        <span className={cx(styles.title, { [styles.short]: value, [styles.long]: !value })}>
          {title}
        </span>
      </div>
      <div className={styles.rigthContainer}>
        {value && <Currency value={value} isNegative={isNegative} />}
        <div className={styles.icon} onClick={clickAction} onKeyPress={clickAction} role="button">
          <ActionIcon />
        </div>
      </div>
    </div>
  );
};

export default TodoFeatureTask;
