import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import {
  CHANGE_EMAIL_FAILURE,
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/profile';
import { updateSuccess } from 'notifications/profile';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

const defaultValues = {
  firstName: '',
  lastName: '',
  honorific: '',
  email: '',
};

export const extractValues = (values) => {
  const savedValues = { ...defaultValues, ...values };

  return {
    first_name: savedValues.firstName,
    last_name: savedValues.lastName,
    honorific: savedValues.honorific,
    degree: savedValues.degree,
    phone_number: savedValues.phoneNumber,
    mobile_phone_number: savedValues.mobilePhoneNumber,
    fax_number: savedValues.faxNumber,
    company_position: savedValues.companyPosition,
    current_password: savedValues.currentPassword,
    password: savedValues.password,
    password_confirmation: savedValues.passwordConfirm,
    receive_offers: savedValues.receiveOffers,
    email_task_notifications: savedValues.emailTaskNotifications,
  };
};

const extractPasswordValues = (values) => ({
  ...values,
  passwordConfirmation: values.passwordConfirm,
});

export const apiFetchProfile = () => ({
  [CALL_API]: {
    endpoint: '/me/profile',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiUpdateProfile = (data = {}) => ({
  [CALL_API]: {
    data,
    method: 'PUT',
    endpoint: '/me/profile',
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const apiChangePassword = (data = {}) => ({
  [CALL_API]: {
    data,
    method: 'PUT',
    endpoint: '/me/profile/password',
    types: [CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE],
  },
});

export const apiChangeEmail = (data = {}) => ({
  [CALL_API]: {
    data,
    method: 'PUT',
    endpoint: '/me/profile/email',
    types: [CHANGE_EMAIL_REQUEST, CHANGE_EMAIL_SUCCESS, CHANGE_EMAIL_FAILURE],
  },
});

export const fetchProfile = () => (dispatch) => dispatch(apiFetchProfile()).catch(apiErrorHandler);

export const updateProfile = (values) => (dispatch) =>
  bindServerValidation(apiUpdateProfile(extractValues(values)), dispatch).then(() =>
    dispatch(showNotification(updateSuccess))
  );

export const changePassword = (values) => (dispatch) =>
  bindServerValidation(apiChangePassword(extractPasswordValues(values)), dispatch).then(() =>
    dispatch(showNotification(updateSuccess))
  );

export const changeEmail = (values) => (dispatch) =>
  bindServerValidation(apiChangeEmail(values), dispatch).then(() =>
    dispatch(showNotification(updateSuccess))
  );
