import styled, { css } from 'styled-components';

import TextInput from 'redesign/styles/utils/TextInput.styled';

export const Wrapper = styled.div`
  width: 112px;
  position: relative;
`;

type FieldProps = {
  $isActive?: boolean;
};

export const Field = styled.div<FieldProps>`
  ${TextInput}

  svg {
    width: 14px;
    color: ${({ theme }) => theme.colors.vrblue['100']};
    margin: 0 ${({ theme }) => theme.space['2']} 0 0;
  }
`;

export const Menu = styled.div`
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 3px 3px 10px 0 rgba(109, 109, 120, 0.6);
  z-index: 2;
`;

export const Years = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${({ theme }) => theme.space['2']};
  padding: ${({ theme }) => theme.space['2']};
`;

type YearProps = {
  $isActive?: boolean;
};

export const Year = styled.li<YearProps>`
  height: 66px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 3px;
  user-select: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.vrblue['075']};
    box-shadow: 3px 3px 5px 0 rgba(0, 41, 143, 0.4);
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.vrblue['100']};
      font-weight: bold;
      box-shadow: 1px 1px 2px 0 rgba(0, 41, 143, 0.4);
      color: ${({ theme }) => theme.colors.white};
    `}
`;

export const Arrows = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.space['4']} ${theme.space['2']} ${theme.space['2']}`};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  outline: 0;

  &:disabled,
  &:disabled:hover {
    color: ${({ theme }) => theme.colors.grey['020']};
    cursor: not-allowed;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.vrblue['075']};
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const PreviousButton = styled(Button)`
  svg {
    transform: rotateZ(90deg);
  }
`;

export const NextButton = styled(Button)`
  svg {
    transform: rotateZ(270deg);
  }
`;
