import moment from 'moment';
import { formValueSelector, reducer } from 'redux-form';

import { FORM_NAME as PAYMENT_SELECTOR } from 'constants/incoming-invoice/payments';
import { COMPANY_EDIT_FORM_NAME } from 'constants/payment-plans';
import { FORM_NAME as PRODUCT_CATALOG_GROUP_FORM_NAME } from 'constants/product-catalog/groups';
import { FORM_NAME as PRODUCT_CATALOG_ITEM_FORM_NAME } from 'constants/product-catalog/items';
import { catalogReduxFormReducer } from 'reducers/product-catalog/items';

import clientCreator from './clientCreator';
import company from './company';
import DatevCreator from './DatevCreator';
import invoiceCreator from './invoiceCreator';
import numberRangesCreator from './numberRangesCreator';
import outgoingInvoiceCreator from './outgoingInvoiceCreator';
import paymentCreator from './paymentCreator';
import supplierCreator from './supplierCreator';
import taxConsultant from './tax-consultant';

export const proposalSelector = formValueSelector('proposalCreator');
export const orderConfirmationSelector = formValueSelector('orderConfirmationCreator');
export const deliveryNoteSelector = formValueSelector('deliveryNoteCreator');
export const outgoingInvoiceSelector = formValueSelector('outgoingInvoiceCreator');
export const incomingInvoiceSelector = formValueSelector('invoiceCreator');
export const datevDownloadSelector = formValueSelector('datevDownload');
export const numberRangesSelector = formValueSelector('numberRanges');
export const companySelector = formValueSelector('company');
export const clientCreatorSelector = formValueSelector('clientCreator');
export const clientQuickCreatorSelector = formValueSelector('clientQuickCreator');
export const supplierCreatorSelector = formValueSelector('supplierCreator');
export const supplierQuickCreatorSelector = formValueSelector('supplierQuickCreator');
export const registrationSelector = formValueSelector('registration');
export const sendEmailSelector = formValueSelector('sendEmail');
export const productCatalogItemSelector = formValueSelector(PRODUCT_CATALOG_ITEM_FORM_NAME);
export const productCatalogGroupSelector = formValueSelector(PRODUCT_CATALOG_GROUP_FORM_NAME);
export const paymentSelector = formValueSelector(PAYMENT_SELECTOR);
export const companyEditFormSelector = formValueSelector(COMPANY_EDIT_FORM_NAME);
export const incomingInvoiceInitialValuesSelector = (state) => {
  const ocrData = state.incomingInvoice.ocr.processedData;
  const formData = state.form.invoiceCreator.initial;
  const { subject, invoiceId, documentDate, paymentDueDate } = ocrData;
  const { name, number: invoiceNumber, invoiceDate, dueDate } = formData;

  return {
    ...formData,
    ocr: subject,
    name: subject || name,
    number: invoiceId || invoiceNumber,
    invoiceDate: (documentDate && moment(documentDate).format('L')) || invoiceDate,
    dueDate: (paymentDueDate && moment(paymentDueDate).format('L')) || dueDate,
  };
};

export default reducer.plugin({
  DatevCreator,
  clientCreator,
  invoiceCreator,
  outgoingInvoiceCreator,
  supplierCreator,
  paymentCreator,
  numberRanges: numberRangesCreator,
  company,
  taxConsultant,
  [PRODUCT_CATALOG_ITEM_FORM_NAME]: catalogReduxFormReducer,
});
