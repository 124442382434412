export const NAMESPACE = 'OUTGOING_INVOICE';

export const CREATE_REQUEST = 'OUTGOING_INVOICE/CREATE_REQUEST';
export const CREATE_SUCCESS = 'OUTGOING_INVOICE/CREATE_SUCCESS';
export const CREATE_FAILURE = 'OUTGOING_INVOICE/CREATE_FAILURE';

export const UPDATE_REQUEST = 'OUTGOING_INVOICE/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'OUTGOING_INVOICE/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'OUTGOING_INVOICE/UPDATE_FAILURE';

export const TOGGLE_GROSS_NET_REQUEST = 'OUTGOING_INVOICE/TOGGLE_GROSS_NET_REQUEST';
export const TOGGLE_GROSS_NET_SUCCESS = 'OUTGOING_INVOICE/TOGGLE_GROSS_NET_SUCCESS';
export const TOGGLE_GROSS_NET_FAILURE = 'OUTGOING_INVOICE/TOGGLE_GROSS_NET_FAILURE';

export const REMINDER_UPDATE_REQUEST = 'OUTGOING_INVOICE_REMINDER/UPDATE_REQUEST';
export const REMINDER_UPDATE_SUCCESS = 'OUTGOING_INVOICE_REMINDER/UPDATE_SUCCESS';
export const REMINDER_UPDATE_FAILURE = 'OUTGOING_INVOICE_REMINDER/UPDATE_FAILURE';

export const DELETE_REQUEST = 'OUTGOING_INVOICE/DELETE_REQUEST';
export const DELETE_SUCCESS = 'OUTGOING_INVOICE/DELETE_SUCCESS';
export const DELETE_FAILURE = 'OUTGOING_INVOICE/DELETE_FAILURE';

export const FETCH_REQUEST = 'OUTGOING_INVOICE/FETCH_REQUEST';
export const FETCH_SUCCESS = 'OUTGOING_INVOICE/FETCH_SUCCESS';
export const FETCH_FAILURE = 'OUTGOING_INVOICE/FETCH_FAILURE';

export const INDEX_REQUEST = 'OUTGOING_INVOICE/INDEX_REQUEST';
export const INDEX_SUCCESS = 'OUTGOING_INVOICE/INDEX_SUCCESS';
export const INDEX_FAILURE = 'OUTGOING_INVOICE/INDEX_FAILURE';
export const INDEX_RESET = 'OUTGOING_INVOICE/INDEX_RESET';

export const INDEX_MORE_REQUEST = 'OUTGOING_INVOICE/INDEX_MORE_REQUEST';
export const INDEX_MORE_SUCCESS = 'OUTGOING_INVOICE/INDEX_MORE_SUCCESS';
export const INDEX_MORE_FAILURE = 'OUTGOING_INVOICE/INDEX_MORE_FAILURE';

export const TRANSFORM_REQUEST = 'OUTGOING_INVOICE/TRANSFORM_REQUEST';
export const TRANSFORM_SUCCESS = 'OUTGOING_INVOICE/TRANSFORM_SUCCESS';
export const TRANSFORM_FAILURE = 'OUTGOING_INVOICE/TRANSFORM_FAILURE';

export const SEARCH_REQUEST = 'OUTGOING_INVOICE/SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'OUTGOING_INVOICE/SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'OUTGOING_INVOICE/SEARCH_FAILURE';

export const SEND_REQUEST = 'OUTGOING_INVOICE/SEND_REQUEST';
export const SEND_SUCCESS = 'OUTGOING_INVOICE/SEND_SUCCESS';
export const SEND_FAILURE = 'OUTGOING_INVOICE/SEND_FAILURE';

export const DOWNLOAD_REQUEST = 'OUTGOING_INVOICE/DOWNLOAD_REQUEST';
export const DOWNLOAD_SUCCESS = 'OUTGOING_INVOICE/DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'OUTGOING_INVOICE/DOWNLOAD_FAILURE';

export const SORT_SUCCESS = 'OUTGOING_INVOICE/SORT_SUCCESS';

export const FETCH_SUMS_REQUEST = 'OUTGOING_INVOICE/FETCH_SUMS_REQUEST';
export const FETCH_SUMS_SUCCESS = 'OUTGOING_INVOICE/FETCH_SUMS_SUCCESS';
export const FETCH_SUMS_FAILURE = 'OUTGOING_INVOICE/FETCH_SUMS_FAILURE';

export const REORDER_LINE_ITEMS_LOCAL = 'OUTGOING_INVOICE/REORDER_LINE_ITEMS_LOCAL';
export const REORDER_LINE_ITEMS_REQUEST = 'OUTGOING_INVOICE/REORDER_LINE_ITEMS_REQUEST';
export const REORDER_LINE_ITEMS_SUCCESS = 'OUTGOING_INVOICE/REORDER_LINE_ITEMS_SUCCEESS';
export const REORDER_LINE_ITEMS_FAILURE = 'OUTGOING_INVOICE/REORDER_LINE_ITEMS_FAILURE';

export const FETCH_LINE_CATEGORIES_REQUEST = 'OUTGOING_LINE_CATEGORIES/FETCH_REQUEST';
export const FETCH_LINE_CATEGORIES_SUCCESS = 'OUTGOING_LINE_CATEGORIES/FETCH_SUCCESS';
export const FETCH_LINE_CATEGORIES_FAILURE = 'OUTGOING_LINE_CATEGORIES/FETCH_FAILURE';

export const TOGGLE_PAID_BY_CASH_REQUEST = 'OUTGOING_LINE_CATEGORIES/TOGGLE_PAID_BY_CASH_REQUEST';
export const TOGGLE_PAID_BY_CASH_SUCCESS = 'OUTGOING_LINE_CATEGORIES/TOGGLE_PAID_BY_CASH_SUCCESS';
export const TOGGLE_PAID_BY_CASH_FAILURE = 'OUTGOING_LINE_CATEGORIES/TOGGLE_PAID_BY_CASH_FAILURE';

export const TOGGLE_PAID_BY_UNSPECIFIED_REQUEST =
  'OUTGOING_LINE_CATEGORIES/TOGGLE_PAID_BY_UNSPECIFIED_REQUEST';
export const TOGGLE_PAID_BY_UNSPECIFIED_SUCCESS =
  'OUTGOING_LINE_CATEGORIES/TOGGLE_PAID_BY_UNSPECIFIED_SUCCESS';
export const TOGGLE_PAID_BY_UNSPECIFIED_FAILURE =
  'OUTGOING_LINE_CATEGORIES/TOGGLE_PAID_BY_UNSPECIFIED_FAILURE';

export const DELETE_MAYBE_SUGGESTION = 'OUTGOING_INVOICE/DELETE_MAYBE_SUGGESTION';

export const DRAFT_ID = 'OUTGOING_INVOICE/CURRENT_DRAFT_ID';

export const CREATE_CLIENT_REQUEST = 'QUICK_CLIENT/CREATE_REQUEST';
export const CREATE_CLIENT_SUCCESS = 'QUICK_CLIENT/CREATE_SUCCESS';
export const CREATE_CLIENT_FAILURE = 'QUICK_CLIENT/CREATE_FAILURE';

export const MIN_NET_AMOUNT = -20000000;
export const MAX_NET_AMOUNT = +19999999;

export const DESTROY_FORM = 'DESTROY_FORM';

export const PAGINATION = 'OUTGOING_INVOICE/PAGINATION';

export const FORM_NAME = 'outgoingInvoiceCreator';

export const PREVIEW_REQUEST = 'OUTGOING_INVOICE/PREVIEW_REQUEST';
export const PREVIEW_SUCCESS = 'OUTGOING_INVOICE/PREVIEW_SUCCESS';
export const PREVIEW_FAILURE = 'OUTGOING_INVOICE/PREVIEW_FAILURE';

export const FETCH_REQUEST_SUB_INVOICES = 'OUTGOING_INVOICE/FETCH_REQUEST_SUB_INVOICES';
export const FETCH_SUCCESS_SUB_INVOICES = 'OUTGOING_INVOICE/FETCH_SUCCESS_SUB_INVOICES';
export const FETCH_FAILURE_SUB_INVOICES = 'OUTGOING_INVOICE/FETCH_FAILURE_SUB_INVOICES';

export const ITEMS_DROPPABLE_ID = 'OUTGOING_INVOICE/LINE_ITEMS_DROPPABLE';

export const DUPLICATE_INVOICE_REQUEST = 'OUTGOING_INVOICE/DUPLICATE_INVOICE_REQUEST';
export const DUPLICATE_INVOICE_SUCCESS = 'OUTGOING_INVOICE/DUPLICATE_INVOICE_SUCCESS';
export const DUPLICATE_INVOICE_FAILURE = 'OUTGOING_INVOICE/DUPLICATE_INVOICE_FAILURE';

export const FROM_DUPLICATE_QUERY_PARAM = 'from-duplicate';
export const FROM_OUTGOING_INVOICE_QUERY_PARAM = 'from-outgoing-invoice';

export const DELETE_ALL_BANK_TRANSFERS_REQUEST = 'ALL_BANK_TRANSFERS/DELETE_REQUEST';
export const DELETE_ALL_BANK_TRANSFERS_SUCCESS = 'ALL_BANK_TRANSFERS/DELETE_SUCCESS';
export const DELETE_ALL_BANK_TRANSFERS_FAILURE = 'ALL_BANK_TRANSFERS/DELETE_FAILURE';

export const TOGGLE_DISABLED_BUTTON_ON_LINE_ITEM_REQUEST =
  'OUTGOING_INVOICE/TOGGLE_DISABLED_BUTTON_ON_LINE_ITEM_REQUEST';
