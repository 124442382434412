import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import ClientCreator from 'containers/Clients/ClientCreator';
import { defaultValuesEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { ConfirmationModal } from 'components/Modal';
import TabbedNavigation, {
  TabContent,
  TabHeader,
} from 'components/TabbedNavigation/TabbedNavigation';
import { TabHeaderRenderProps } from 'components/TabbedNavigation/TabHeader/TabHeader';
import Tab from 'components/Tabs/Tab';

import ClientDocumentDefaults from '../ClientDocumentDefaults/ClientDocumentDefaults';
import ClientEmailDefaults from '../ClientEmailDefaults/ClientEmailDefaults';
import ClientPageLink from './ClientPageLink/ClientPageLink';

import styles from './ClientPage.module.css';

const CLIENT_PAGE_TABS = {
  CREATOR: 'creator',
  DOCUMENT_DEFAULTS: 'document_defaults',
  EMAIL_DEFAULTS: 'email_defaults',
};

interface ClientPagePassProps {
  isFarmpilot?: boolean;
  submitting?: boolean;
  match: {
    params: Record<'id', string>;
  };
}

interface ClientPageStateProps {
  areDefaultValuesEnabled: boolean;
}

interface ClientPageDispatchProps {
  submitClientForm: () => void;
}

interface ClientPageProps
  extends ClientPagePassProps,
    ClientPageStateProps,
    ClientPageDispatchProps {}

const ClientPage: FunctionComponent<ClientPageProps> = ({
  areDefaultValuesEnabled,
  match: { params },
  submitClientForm,
  isFarmpilot = false,
  submitting: isSubmitting = false,
}) => {
  const [isDefaultsModalOpen, setIsDefaultsModalOpen] = useState(false);

  const isCreationMode = !params.id;

  const handleTabSelect = () => {
    if (isCreationMode) setIsDefaultsModalOpen(true);
  };

  const handleDefaultsModalConfirm = () => {
    setIsDefaultsModalOpen(false);
    submitClientForm();
  };

  const TABS_PATHS = {
    [paths.showClient(params.id)]: CLIENT_PAGE_TABS.CREATOR,
    [paths.editClient(params.id)]: CLIENT_PAGE_TABS.CREATOR,
    [paths.showClientFarmpilot(params.id)]: CLIENT_PAGE_TABS.CREATOR,
    [paths.clientsNew]: CLIENT_PAGE_TABS.CREATOR,
    [paths.clientDocumentDefaults(params.id)]: CLIENT_PAGE_TABS.DOCUMENT_DEFAULTS,
    [paths.clientEmailsDefaults(params.id)]: CLIENT_PAGE_TABS.EMAIL_DEFAULTS,
  };

  return (
    <div className={styles.container}>
      <TabbedNavigation tabsPaths={TABS_PATHS}>
        <header className={styles.header}>
          <TabHeader>
            {({ selectedTabName }: TabHeaderRenderProps) => (
              <ClientPageLink
                to={paths.editClient(params.id)}
                dataId="ClientPage:tab-edit-info"
                className={styles.tab}
                isCreationMode={isCreationMode}
              >
                <Tab
                  heading={t('clients.defaults.tab_client_data')}
                  active={selectedTabName === CLIENT_PAGE_TABS.CREATOR}
                />
              </ClientPageLink>
            )}
          </TabHeader>
          {areDefaultValuesEnabled && (
            <>
              <TabHeader>
                {({ selectedTabName }: TabHeaderRenderProps) => (
                  <ClientPageLink
                    to={paths.clientDocumentDefaults(params.id)}
                    dataId="ClientPage:tab-document-defaults"
                    className={styles.tab}
                    isCreationMode={isCreationMode}
                  >
                    <Tab
                      heading={t('clients.defaults.tab_document_defaults')}
                      active={selectedTabName === CLIENT_PAGE_TABS.DOCUMENT_DEFAULTS}
                      onClick={handleTabSelect}
                    />
                  </ClientPageLink>
                )}
              </TabHeader>
              <TabHeader>
                {({ selectedTabName }: TabHeaderRenderProps) => (
                  <ClientPageLink
                    to={paths.clientEmailsDefaults(params.id)}
                    dataId="ClientPage:tab-email-defaults"
                    className={styles.tab}
                    isCreationMode={isCreationMode}
                  >
                    <Tab
                      heading={t('clients.defaults.tab_email_defaults')}
                      active={selectedTabName === CLIENT_PAGE_TABS.EMAIL_DEFAULTS}
                      onClick={handleTabSelect}
                    />
                  </ClientPageLink>
                )}
              </TabHeader>
            </>
          )}
        </header>
        <main>
          <TabContent tabName={CLIENT_PAGE_TABS.CREATOR}>
            <ClientCreator submitting={isSubmitting} isFarmpilot={isFarmpilot} params={params} />
          </TabContent>
          {areDefaultValuesEnabled && (
            <>
              <TabContent tabName={CLIENT_PAGE_TABS.DOCUMENT_DEFAULTS}>
                <ClientDocumentDefaults clientId={params.id} />
              </TabContent>
              <TabContent tabName={CLIENT_PAGE_TABS.EMAIL_DEFAULTS}>
                <ClientEmailDefaults clientId={params.id} />
              </TabContent>
            </>
          )}
        </main>
      </TabbedNavigation>
      <ConfirmationModal
        header={t('clients.defaults_modal.header')}
        isOpen={isDefaultsModalOpen}
        onClose={() => setIsDefaultsModalOpen(false)}
        onConfirm={handleDefaultsModalConfirm}
        closeLabel={t('clients.defaults_modal.cancel')}
        confirmLabel={t('clients.defaults_modal.accept')}
        dataIds={{
          modal: 'ClientDefaults:defaults-modal-container',
          abortButton: 'ClientDefaults:button-cancel-defaults-modal',
          acceptButton: 'ClientDefaults:button-accept-defaults-modal',
        }}
      >
        <p className={styles.modalContent}>{t('clients.defaults_modal.content')}</p>
      </ConfirmationModal>
    </div>
  );
};

const mapStateToProps = (state: any): ClientPageStateProps => ({
  areDefaultValuesEnabled: defaultValuesEnabledHelper(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  submitClientForm: () => dispatch(submit('clientCreator')),
});

export default connect<ClientPageStateProps, ClientPageDispatchProps, ClientPagePassProps>(
  mapStateToProps,
  mapDispatchToProps
)(ClientPage);
