import React from 'react';

import { t } from 'shared/utils';
import { Supplier } from 'types/entities/Supplier';

import { Wrapper } from './Option.styled';

type OptionProps = {
  item: Supplier;
  isHighlighted?: boolean;
};

const Option = ({
  item: { companyName, firstName, lastName, city, street, zipcode, idNumber, taxNumber },
  isHighlighted,
}: OptionProps) => (
  <Wrapper $isHighlighted={isHighlighted}>
    <h3>{companyName || `${firstName || ''} ${lastName || ''}`}</h3>
    <div>
      <p>{street}</p>
      <p>
        {zipcode} {city}
      </p>
      {taxNumber && <p>{`${t('expenses.form.supplier_select.tax_number')}: ${taxNumber}`}</p>}
      {idNumber && (
        <p>
          {t(`expenses.form.supplier_select.id_number_client`)}: {idNumber}
        </p>
      )}
    </div>
  </Wrapper>
);

export default Option;
