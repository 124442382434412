import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import Downshift from 'downshift-legacy';
import { bool, func, shape, string } from 'prop-types';

import { apiFetchSuggestedItemNumber } from 'actions/product-catalog/items/items';
import { getSuggestedNumber } from 'reducers/common/resource';
import lineItemStyles from 'shared/styles/line-items.module.css';
import { noop, t } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';
import ArrowIcon from 'components/ArrowIcon/ArrowIcon';
import inputStyles from 'components/Form/TextField/TextField.module.css';

import styles from '../Selects.module.css';

class ItemNumberSelect extends PureComponent {
  static defaultProps = {
    placeholder: '',
    disabled: false,
    invalid: false,
    inputClassName: '',
    initialValue: '',
    onClick: noop,
    inputProps: {},
  };

  state = {
    inputValue: '',
  };

  componentDidMount() {
    const { fetchSuggestedItemNumber, initialValue } = this.props;
    fetchSuggestedItemNumber();

    if (initialValue) {
      this.handleInputChange(initialValue);
    }
  }

  componentDidUpdate({ initialValue: prevInitialValue }) {
    const { initialValue } = this.props;

    if (initialValue !== prevInitialValue) {
      this.handleInputChange(initialValue);
    }
  }

  handleInputChange = (inputValue) => {
    this.props.onValueChange(inputValue);
    this.setState({ inputValue });
  };

  render() {
    const {
      suggestedNumber,
      name,
      placeholder,
      disabled,
      invalid,
      inputClassName,
      label,
      input,
      initialValue,
      onClick,
      shouldLabelStayUp,
      dataIds = { input: '', suggestion: '' },
      inputProps,
      required,
      fetchSuggestedItemNumber,
    } = this.props;
    const { inputValue } = this.state;

    return (
      <Downshift
        onOuterClick={noop}
        itemToString={this.itemToString}
        defaultInputValue={initialValue}
        defaultSelectedItem={initialValue}
        onSelect={({ label: selectedLabel }) => this.handleInputChange(selectedLabel)}
        selectedItem={inputValue}
        onInputValueChange={(value) => this.handleInputChange(value)}
      >
        {({
          getInputProps,
          getLabelProps,
          getMenuProps,
          getItemProps,
          isOpen,
          openMenu,
          closeMenu,
        }) => (
          <div>
            <div
              role="button"
              className={cx(inputStyles.wrapper, inputStyles.wrapperWithArrow)}
              onClick={!isOpen ? openMenu : closeMenu}
              onKeyPress={isPressedEnter(!isOpen ? openMenu : noop)}
            >
              <input
                onClick={onClick}
                {...input}
                {...getInputProps({
                  ...inputProps,
                  onBlur: (e) => e.preventDefault(),
                  onFocus: fetchSuggestedItemNumber,
                  placeholder,
                })}
                name={name}
                className={cx(
                  inputStyles.main,
                  inputClassName,
                  styles.itemNumberSelectInput,
                  lineItemStyles.inputContent,
                  {
                    [styles.invalid]: invalid,
                    [inputStyles.mainFilled]: !!inputValue,
                    [inputStyles.labelStaysUp]: shouldLabelStayUp,
                  }
                )}
                disabled={disabled}
                data-id={dataIds.input}
              />
              <label
                {...getLabelProps()}
                htmlFor={name}
                className={cx(inputStyles.label, { [styles.invalid]: invalid })}
              >
                <span className={inputStyles.labelContent}>
                  {label} {required && '*'}
                </span>
              </label>
              <div className={styles.icons}>
                <span className={styles.multiDot}>{'{...}'}</span>
                <ArrowIcon onClick={noop} isOpen={isOpen} />
              </div>
            </div>
            {isOpen && (
              <div
                {...getMenuProps()}
                className={cx(styles.items, styles.itemNumberOptionOuter)}
                tabIndex="-1"
              >
                <div
                  {...getItemProps({ item: { label: suggestedNumber } })}
                  className={cx(styles.item, styles.itemNumberOptionInner)}
                >
                  <div className={styles.itemNumberLabel} data-id={dataIds.suggestion}>
                    {t('revenue.form.details_section.fields.item_number_select_option')}
                    {suggestedNumber}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Downshift>
    );
  }
}

ItemNumberSelect.propTypes = {
  name: string.isRequired,
  placeholder: string,
  disabled: bool,
  invalid: bool,
  inputClassName: string,
  initialValue: string,
  label: string.isRequired,
  input: shape({}).isRequired,
  onValueChange: func.isRequired,
  fetchSuggestedItemNumber: func.isRequired,
  suggestedNumber: string.isRequired,
  onClick: func,
  shouldLabelStayUp: bool,
  dataIds: shape({ input: string, suggestion: string }),
  inputProps: shape({}),
  required: bool,
};

ItemNumberSelect.defaultProps = {
  initialValue: '',
};

const mapStateToProps = (state) => ({
  suggestedNumber: getSuggestedNumber(state),
});

export default connect(mapStateToProps, { fetchSuggestedItemNumber: apiFetchSuggestedItemNumber })(
  ItemNumberSelect
);
