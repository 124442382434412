import React, { Ref } from 'react';

import isPressedEnter from 'shared/utils/keyboard-events';
import Loading from 'components/Loading';

import styles from './PreviewPDF.module.css';

type PreviewPDFProps = {
  scrollContainerRef: Ref<HTMLDivElement>;
  canvasRef: Ref<HTMLCanvasElement>;
  onClick: () => void;
  isPreviewRendering: boolean;
};

const PreviewPDF = ({
  canvasRef,
  scrollContainerRef,
  onClick,
  isPreviewRendering,
}: PreviewPDFProps) => (
  <div className={styles.canvasContainer} ref={scrollContainerRef}>
    <canvas
      onClick={onClick}
      ref={canvasRef}
      role="button"
      tabIndex={0}
      onKeyPress={isPressedEnter(onClick)}
    />
    {isPreviewRendering && <Loading />}
  </div>
);

export default PreviewPDF;
