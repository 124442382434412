import React, { Component } from 'react';
import cx from 'classnames';
import filter from 'lodash/filter';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';

import { DE_BALANCE_ASSIGNMENT_CALLED, DE_KONTOUMSAETZE } from 'constants/kontoumsaetze';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import If from 'components/Conditions/If';
import LoadingIcon from 'components/LoadingIcon';
import { EmptyState, TableHeader } from 'components/Table';

import BankTransferRow from './BankTransferRow';

import styles from './Table.module.css';

class BankTransfersTable extends Component {
  state = {
    rowId: null,
  };

  getConnectionsForBankTransfer = (id) =>
    this.props.bankTransfersConnections.filter(
      (connection) => connection.bankTransferId === Number(id)
    );

  getConnectedLabels = (id) =>
    filter(this.props.labels || [], (connection) => connection.bankTransferId === Number(id));

  toggle = (id) => {
    const { rowId } = this.state;
    if (rowId === id) return this.setState({ rowId: null });
    piwikHelpers.trackEvent(DE_KONTOUMSAETZE, DE_BALANCE_ASSIGNMENT_CALLED);
    return this.setState({ rowId: id });
  };

  render() {
    const {
      isFetching,
      isFetchingFromAllAcounts,
      data,
      actions,
      refresh,
      sorting,
      sort,
      backgroundSyncInProgress,
    } = this.props;
    if (!data.length && isFetching) {
      return <LoadingIcon />;
    }

    if (backgroundSyncInProgress) {
      return (
        <EmptyState isFetching={false}>
          {t('bank_transfers.table.background_sync_in_progress')}
        </EmptyState>
      );
    }

    if (!data.length) {
      return <EmptyState isFetching={false}>{t('bank_transfers.table.empty')}</EmptyState>;
    }

    const columns = isFetchingFromAllAcounts
      ? [
          { column: 'date', sortable: true },
          { column: 'account', sortable: true },
          { column: 'creditor_name', sortable: true },
          { column: 'purpose', sortable: true },
          { column: 'amount_cents', align: 'center', sortable: true },
          { column: 'invoice_assignment', sortable: true },
          { column: 'action', align: 'right', sortable: false },
        ]
      : [
          { column: 'date', sortable: true },
          { column: 'creditor_name', sortable: true },
          { column: 'purpose', sortable: true },
          { column: 'amount_cents', align: 'center', sortable: true },
          { column: 'invoice_assignment', sortable: true },
          { column: 'action', align: 'right', sortable: false },
        ];

    const { rowId } = this.state;

    return (
      <div className={styles.tableWrapper}>
        <If ok={isFetching}>
          <div className={styles.loadingWrapper}>
            <LoadingIcon />
          </div>
        </If>
        <table className={styles.table}>
          <thead>
            <tr>
              {columns.map((column, id) => (
                <TableHeader
                  section="bank_transfers"
                  key={`${column}_${id}`}
                  sort={sort}
                  {...column}
                  refresh={refresh}
                  currentSorting={sorting}
                  columnWidthClass={cx(styles.column, {
                    [styles.columnDatev]: isFetchingFromAllAcounts,
                  })}
                  sortable={column.sortable}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <BankTransferRow
                toggle={this.toggle}
                isOpen={item.id === rowId}
                isFetchingFromAllAcounts={isFetchingFromAllAcounts}
                bankTransferConnections={this.getConnectionsForBankTransfer(item.id)}
                item={item}
                key={item.id}
                index={index}
                labels={this.getConnectedLabels(item.id)}
                actions={{
                  connectResource: actions.connectResource(item.id),
                  disconnectResource: actions.disconnectResource(item.id),
                  rejectConnectionSuggestion: actions.rejectConnectionSuggestion(item.id),
                  createLabel: actions.createLabel(item.id),
                  deleteLabel: actions.deleteLabel,
                }}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

BankTransfersTable.propTypes = {
  data: arrayOf(
    shape({
      amount: number.isRequired,
      creditorName: string.isRequired,
      currency: string.isRequired,
      id: string.isRequired,
      date: string.isRequired,
    })
  ).isRequired,
  isFetching: bool.isRequired,
  isFetchingFromAllAcounts: bool,
  backgroundSyncInProgress: bool.isRequired,
  actions: shape({
    connectResource: func.isRequired,
    disconnectResource: func.isRequired,
    rejectConnectionSuggestion: func.isRequired,
  }).isRequired,
  sort: func.isRequired,
  refresh: func.isRequired,
  sorting: shape({}).isRequired,
  bankTransfersConnections: oneOfType([arrayOf(shape({})), shape({})]),
  labels: oneOfType([arrayOf(shape({})), shape({})]),
};

export default BankTransfersTable;
