import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { getCompanies } from 'api/me/companies';

import { Wrapper } from './AutoComplete.styled';
import AutoCompleteInput from './AutoCompleteInput';
import Menu from './Menu';

const AutoComplete = React.forwardRef(({ onChange }, ref) => {
  const [options, setOptions] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showAddCompanyOption, setShowAddCompanyOption] = useState(false);

  const inputRef = useRef(null);
  const menuListRef = useRef(null);
  const isOptionSelectedRef = useRef(false);
  const closeMenuTimeoutRef = useRef(null);

  useImperativeHandle(ref, () => ({
    setMenuListRef: (menuRef) => {
      menuListRef.current = menuRef;
    },
  }));

  const debouncedFetchOptions = useRef(
    debounce((inputValue) => fetchOptions(inputValue), 300)
  ).current;

  useEffect(() => {
    debouncedFetchOptions('');

    return () => {
      debouncedFetchOptions.cancel();
    };
  }, [debouncedFetchOptions]);

  const fetchOptions = async (inputValue) => {
    try {
      if (inputValue.length >= 2) {
        const response = await getCompanies({ search_filter: inputValue });
        const data = JSON.parse(response.data);

        const companyOptions = data.map((company) => ({
          legal_entity_name: company.legal_entity_name,
          commercial_name: company.commercial_name,
          crefo_id: company.crefo_id,
          country_code: company.country_code,
          state: company.state,
          city: company.city,
          zip_code: company.zip_code,
          address: company.address,
        }));

        companyOptions.push({
          legal_entity_name: 'Unternehmen hinzufügen',
          commercial_name: '',
          crefo_id: '',
          country_code: '',
          state: '',
          city: '',
          zip_code: '',
          address: '',
        });

        setOptions(companyOptions);
        setShowAddCompanyOption(companyOptions.length === 0);
        setIsMenuOpen(true);
      } else {
        setOptions([]);
        setShowAddCompanyOption(false);
        setIsMenuOpen(false);
      }
    } catch (error) {
      console.error('Failed to fetch companies:', error);
      setOptions([]);
      setShowAddCompanyOption(false);
      setIsMenuOpen(false);
    }
  };

  const handleAddCompanyOptionClick = () => {
    const inputValue = inputRef.current.value.trim();
    if (inputValue) {
      const newCompany = {
        legal_entity_name: inputValue,
        commercial_name: '',
        crefo_id: '',
        country_code: '',
        state: '',
        city: '',
        zip_code: '',
        address: '',
        new_company: true,
      };
      onChange && onChange(newCompany);
      setIsMenuOpen(false);
    }
  };

  const handleMenuItemClick = (index) => {
    const selectedOption = options[index];

    if (selectedOption.legal_entity_name === 'Unternehmen hinzufügen') {
      const emptyCompany = {
        legal_entity_name: '',
        commercial_name: '',
        crefo_id: '',
        country_code: '',
        state: '',
        city: '',
        zip_code: '',
        address: '',
        new_company: true, // Indicating that this is a new company
      };
      onChange && onChange(emptyCompany);
    } else {
      onChange && onChange(selectedOption);
      inputRef.current.value = selectedOption.legal_entity_name;
    }

    setIsMenuOpen(false);
    isOptionSelectedRef.current = true;
  };

  const handleKeyDown = (event) => {
    const { key } = event;
    if (key === 'ArrowDown') {
      event.preventDefault();
      setHighlightedIndex((prevIndex) => (prevIndex < options.length - 1 ? prevIndex + 1 : 0));
    } else if (key === 'ArrowUp') {
      event.preventDefault();
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : options.length - 1));
    } else if (key === 'Enter' && highlightedIndex !== -1) {
      handleMenuItemClick(highlightedIndex);
    }
  };

  const scrollIntoView = (index) => {
    const menuItem = document.getElementById(`menu-item-${index}`);
    const menuList = menuListRef.current;

    if (menuItem && menuList) {
      const menuItemRect = menuItem.getBoundingClientRect();
      const menuListRect = menuList.getBoundingClientRect();
      const itemTopOffset = menuItemRect.top - menuListRect.top;
      const itemBottomOffset = menuItemRect.bottom - menuListRect.bottom;

      if (itemTopOffset < 0) {
        menuList.scrollTop += itemTopOffset;
      } else if (itemBottomOffset > 0) {
        menuList.scrollTop += itemBottomOffset;
      }
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    fetchOptions(inputValue);
    isOptionSelectedRef.current = false;
  };

  useEffect(() => {
    if (isMenuOpen && highlightedIndex !== -1) {
      scrollIntoView(highlightedIndex);
    }
  }, [highlightedIndex, isMenuOpen]);

  useEffect(() => {
    const handleInputBlur = () => {
      if (isOptionSelectedRef.current) {
        return;
      }

      closeMenuTimeoutRef.current = setTimeout(() => {
        setIsMenuOpen(false);
      }, 100);
    };

    const handleInputFocus = () => {
      clearTimeout(closeMenuTimeoutRef.current);
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('blur', handleInputBlur);
      inputElement.addEventListener('focus', handleInputFocus);
    }

    return () => {
      clearTimeout(closeMenuTimeoutRef.current);
      const inputElement = inputRef.current;
      if (inputElement) {
        inputElement.removeEventListener('blur', handleInputBlur);
        inputElement.removeEventListener('focus', handleInputFocus);
      }
    };
  }, []);

  return (
    <Wrapper>
      <AutoCompleteInput
        ref={inputRef}
        onChange={handleInputChange}
        onFocus={() => setIsMenuOpen(true)}
        onBlur={() => setIsMenuOpen(false)}
        onKeyDown={handleKeyDown}
      />
      <Menu
        isMenuOpen={isMenuOpen}
        options={options}
        highlightedIndex={highlightedIndex}
        onItemClick={handleMenuItemClick}
        showAddCompanyOption={showAddCompanyOption}
        onAddCompanyClick={handleAddCompanyOptionClick}
        ref={menuListRef}
      />
    </Wrapper>
  );
});

export default AutoComplete;
