import styled from 'styled-components';

import { Status as DeliveryNotesStatus } from 'features/deliveryNotes/types';

type StatusProps = {
  status: DeliveryNotesStatus;
};

export const Status = styled.div<StatusProps>`
  display: flex;
  height: 30px;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: bold;
  text-transform: uppercase;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme, status }) =>
    status === DeliveryNotesStatus.Saved
      ? theme.legacy.colors.greenFont
      : theme.legacy.colors.greyFont};
`;

export const Text = styled.span`
  margin-left: ${({ theme }) => theme.space[2]};
`;
