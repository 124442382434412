import styled from 'styled-components';

import media from 'shared/styles/breakpoints';

export const Wrapper = styled.div`
  position: relative;
  min-height: 30vh;
  width: 100vw;

  ${media.greaterThan('sm-up')`
    width: 90vw;
    max-width: 1300px;
  `}
`;
