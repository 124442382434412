import React from 'react';

import styles from '../StatusIcons.module.css';

const FinishedStatusIcon = () => (
  <svg
    className={styles.statusIcon}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter
        x="-.5%"
        y="-1%"
        width="101%"
        height="102.3%"
        filterUnits="objectBoundingBox"
        id="paid_svg__a"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.788235294 0 0 0 0 0.788235294 0 0 0 0 0.788235294 0 0 0 1 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        d="M2 0h1340a2 2 0 012 2v562a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z"
        id="paid_svg__b"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M-114-509h1434v688H-114z" fill="#E6E6E6" />
      <g transform="translate(-48 -425)">
        <use fill="#000" filter="url(#paid_svg__a)" xlinkHref="#paid_svg__b" />
        <use fill="#FFF" xlinkHref="#paid_svg__b" />
      </g>
      <path d="M-18-21h1280v66H-18z" fill="#F8F8F8" />
      <g>
        <rect stroke="#4D4E4C" fill="#4D4E4C" x={0.5} y={0.5} width={19} height={19} rx={2} />
        <path d="M7.667 16L3 11.417l1.867-1.834 2.8 2.75L15.133 5 17 6.833z" fill="#FFF" />
      </g>
    </g>
  </svg>
);

export default FinishedStatusIcon;
