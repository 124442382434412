import React, { SVGAttributes } from 'react';

const IconCross = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" {...props}>
    <g
      fill="#D8D8D8"
      stroke="#FFF"
      strokeWidth="2"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1.5 1.5l12.9818425 12.9818425M14.484342 1.5L1.5024995 14.4818425" />
    </g>
  </svg>
);

export default IconCross;
