import React from 'react';

const Clients = ({ dataId = '', className = '' }) => (
  <svg width={304} height={136} data-id={dataId} className={className}>
    <defs>
      <ellipse id="prefix__b" cx={170} cy={50} rx={51} ry={50} />
      <ellipse id="prefix__d" cx={33} cy={82.5} rx={33} ry={32.5} />
      <filter
        x="-6.9%"
        y="-5%"
        width="113.7%"
        height="114%"
        filterUnits="objectBoundingBox"
        id="prefix__a"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-10.6%"
        y="-7.7%"
        width="121.2%"
        height="121.5%"
        filterUnits="objectBoundingBox"
        id="prefix__c"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M89.347 53.713c-23 7-85-17-89 22s27.516 36.669 47.516 46.669 50 14 66 12 51-24 69-20 84.484 20.331 110.484.331-3-64-30-72-53-37-102-28-49 32-72 39z"
        fill="#F3F3F3"
      />
      <path
        d="M186.884 129.825h-24.14s-4.41 0-4.41-5.707V96h26.252v14.325"
        stroke="#FFF"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.586 110.325v16.742c.084 1.463 1.269 2.606 2.702 2.606 1.434 0 2.619-1.143 2.703-2.606v-16.742h-5.405zm-20.577-8.037h14.81m-14.81 4.385h14.81m-14.81 4.394h14.81"
        stroke="#FFF"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M186.884 129.825h-24.14s-4.41 0-4.41-5.707V96h26.252v14.325"
        stroke="#0066B3"
        strokeWidth={2}
        fill="#FFF"
        fillRule="nonzero"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.586 110.325v16.742c.084 1.463 1.269 2.606 2.702 2.606 1.434 0 2.619-1.143 2.703-2.606v-16.742h-5.405z"
        stroke="#0066B3"
        strokeWidth={2}
        fill="#FFF"
        fillRule="nonzero"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke="#F60"
        strokeWidth={2}
        fill="#FFF"
        fillRule="nonzero"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M164.01 102.288h14.809m-14.81 4.385h14.81m-14.81 4.394h14.81"
      />
      <path
        d="M164.047 128.541a8.419 8.419 0 001.562-3.443c.155-.712.224-1.44.205-2.169a5.787 5.787 0 00-1.321-3.805c-.17-.2-.354-.387-.549-.561a6.103 6.103 0 00-4.195-1.35 8.412 8.412 0 00-5.098 1.826c-.222.17-.433.354-.632.551a9.988 9.988 0 00-3.089 7.239c-.004.93.15 1.854.456 2.73.159.447.371.872.633 1.265.212.37.472.709.772 1.008a8.267 8.267 0 005.72 2.217 9.579 9.579 0 006.02-2.169 8.98 8.98 0 001.283-1.094l-.781-1.027-.986-1.218z"
        stroke="#FFF"
        strokeWidth={2}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M160.856 121.683h1.553l-.65 3.025a28.38 28.38 0 00-.55 3.339c0 .485.177.732.493.732.317 0 .93-.514 1.024-.637a7.306 7.306 0 001.609-3.643c.077-.446.117-.898.12-1.351a5.283 5.283 0 00-.502-2.416c-.2-.35-.447-.67-.734-.952a4.96 4.96 0 00-3.517-1.179 7.312 7.312 0 00-5.432 2.768 8.818 8.818 0 00-1.944 5.707 5.456 5.456 0 001.86 4.243c.32.255.662.478 1.023.666a7.309 7.309 0 003.07.76 8.659 8.659 0 005.265-1.712 8.09 8.09 0 001.442-1.227l.828.98c-.38.4-.793.762-1.237 1.084a9.578 9.578 0 01-6.065 2.179 8.267 8.267 0 01-5.721-2.217 4.804 4.804 0 01-.8-1.008 5.756 5.756 0 01-.633-1.265 8.155 8.155 0 01-.428-2.73 9.987 9.987 0 013.107-7.239c.2-.197.411-.381.633-.551a8.41 8.41 0 015.107-1.808 6.104 6.104 0 014.176 1.351c.196.174.379.362.55.561a5.787 5.787 0 011.32 3.805 9.095 9.095 0 01-.242 2.169c-.697 3.272-2.734 5.127-4.418 5.05a1.66 1.66 0 01-1.47-.874 1.686 1.686 0 01-.158-.666c-.14.162-.214.276-.354.418a3.054 3.054 0 01-2.297 1.132 2.54 2.54 0 01-1.126-.266 3.15 3.15 0 01-1.535-3.053 7.037 7.037 0 011.619-4.452 3.507 3.507 0 012.614-1.303 2.292 2.292 0 011.711.742c.188.236.341.499.456.78l.233-.942zm-.93 2.188a1.462 1.462 0 00-1.386-1.237 1.905 1.905 0 00-1.191.523 5.375 5.375 0 00-1.33 3.5c-.08.622.13 1.245.567 1.684.07.065.148.12.233.162.2.129.435.186.67.162a2.259 2.259 0 001.46-.847 5.501 5.501 0 001.098-3.281c.004-.231-.021-.461-.075-.685l-.046.019z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M160.856 121.683h1.553l-.65 3.025a28.38 28.38 0 00-.55 3.339c0 .485.177.732.493.732.317 0 .93-.514 1.024-.637a7.306 7.306 0 001.609-3.643c.077-.446.117-.898.12-1.351a5.283 5.283 0 00-.502-2.416c-.2-.35-.447-.67-.734-.952a4.96 4.96 0 00-3.517-1.179 7.312 7.312 0 00-5.432 2.768 8.818 8.818 0 00-1.944 5.707 5.456 5.456 0 001.86 4.243c.32.255.662.478 1.023.666a7.309 7.309 0 003.07.76 8.659 8.659 0 005.265-1.712 8.09 8.09 0 001.442-1.227l.828.98c-.38.4-.793.762-1.237 1.084a9.578 9.578 0 01-6.065 2.179 8.267 8.267 0 01-5.721-2.217 4.804 4.804 0 01-.8-1.008 5.756 5.756 0 01-.633-1.265 8.155 8.155 0 01-.428-2.73 9.987 9.987 0 013.107-7.239c.2-.197.411-.381.633-.551a8.41 8.41 0 015.107-1.808 6.104 6.104 0 014.176 1.351c.196.174.379.362.55.561a5.787 5.787 0 011.32 3.805 9.095 9.095 0 01-.242 2.169c-.697 3.272-2.734 5.127-4.418 5.05a1.66 1.66 0 01-1.47-.874 1.686 1.686 0 01-.158-.666c-.14.162-.214.276-.354.418a3.054 3.054 0 01-2.297 1.132 2.54 2.54 0 01-1.126-.266 3.15 3.15 0 01-1.535-3.053 7.037 7.037 0 011.619-4.452 3.507 3.507 0 012.614-1.303 2.292 2.292 0 011.711.742c.188.236.341.499.456.78l.233-.942zm-.93 2.188a1.462 1.462 0 00-1.386-1.237 1.905 1.905 0 00-1.191.523 5.375 5.375 0 00-1.33 3.5c-.08.622.13 1.245.567 1.684.07.065.148.12.233.162.2.129.435.186.67.162a2.259 2.259 0 001.46-.847 5.501 5.501 0 001.098-3.281c.004-.231-.021-.461-.075-.685l-.046.019z"
        fill="#F60"
        fillRule="nonzero"
      />
      <path
        d="M103 96c9.333-35.333 18-47.333 26-36 12 17 34 36 41-14"
        stroke="#F60"
        strokeLinecap="round"
        strokeDasharray="4,4"
      />
      <g transform="translate(47 2)">
        <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
        <use fill="#FFF" xlinkHref="#prefix__b" />
      </g>
      <g transform="translate(47 2)">
        <use fill="#000" filter="url(#prefix__c)" xlinkHref="#prefix__d" />
        <use fill="#FFF" xlinkHref="#prefix__d" />
      </g>
      <g fillRule="nonzero">
        <path
          d="M89.17 74.41c0 5.974-3.998 11-9.17 11-5.184 0-9.17-4.992-9.17-11 0-6.37 3.405-11 9.17-11 5.755 0 9.17 4.662 9.17 11zm-4 0c0-4.35-1.941-7-5.17-7-3.243 0-5.17 2.62-5.17 7 0 3.972 2.418 7 5.17 7 2.736 0 5.17-3.06 5.17-7z"
          fill="#FFF"
        />
        <path
          d="M64 99.62v-4.71c0-4.496 2.852-7.089 8.604-8.153a2 2 0 00-.728-3.934C64.41 84.206 60 88.215 60 94.91v4.71a2 2 0 104 0zm36 0v-4.71c0-6.695-4.41-10.704-11.876-12.087a2 2 0 00-.728 3.934C93.148 87.82 96 90.414 96 94.91v4.71a2 2 0 104 0zM81.704 71.408l.294.012a6.63 6.63 0 004.824-1.736 2 2 0 00-.29-3.17l-4.25-2.66a2 2 0 00-1.509-.253l-5.32 1.22c-1.692.388-2.12 2.6-.695 3.59l.412.276.295.189c.532.335 1.103.67 1.695.985a18.41 18.41 0 002.004.928c.907.35 1.75.568 2.54.62zm-.037-3.909l-1.508-.254.701.44.807-.186z"
          fill="#FFF"
        />
        <path
          d="M70.491 85.77l3.43 6.18a2 2 0 003.132.475l2.09-2a2 2 0 00-.157-3.025l-1.596-1.227-.659-.502-3.294-2.483a2 2 0 00-3.187 1.397l-.01.174c-.007.342.073.69.251 1.012zm18.871-2.177a2 2 0 00-2.663-.498l-.477.348-.895.672c-.694.522-1.389 1.047-2.058 1.556L81.014 87.4a2 2 0 00-.157 3.025l2.09 2a2 2 0 003.132-.474l3.43-6.18c.148-.268.229-.555.247-.84l.004-.172a1.991 1.991 0 00-.398-1.166z"
          fill="#FFF"
        />
        <path
          d="M88.17 74.41c0 5.466-3.607 10-8.17 10-4.576 0-8.17-4.501-8.17-10 0-5.872 3.035-10 8.17-10 5.123 0 8.17 4.16 8.17 10zm-2 0c0-4.847-2.31-8-6.17-8-3.874 0-6.17 3.122-6.17 8 0 4.48 2.81 8 6.17 8 3.345 0 6.17-3.551 6.17-8zM63 99.62v-4.71c0-5.045 3.241-7.993 9.422-9.137a1 1 0 00-.364-1.966C65.02 85.11 61 88.765 61 94.91v4.71a1 1 0 002 0zm36 0v-4.71c0-6.145-4.02-9.8-11.058-11.103a1 1 0 00-.364 1.966C93.758 86.917 97 89.865 97 94.91v4.71a1 1 0 002 0z"
          fill="#0066B3"
        />
        <path
          d="M71.366 85.285l3.43 6.18a1 1 0 001.565.237l2.09-2a1 1 0 00-.078-1.512l-1.59-1.222-1.332-1.013-2.613-1.966a1 1 0 00-1.59.672l-.008.124a.995.995 0 00.126.5zm17.195-1.093a1 1 0 00-1.294-.272l-.247.175-2.47 1.86-2.082 1.586-.841.649a1 1 0 00-.078 1.512l2.09 2a1 1 0 001.565-.237l3.43-6.18a.997.997 0 00.12-.373l.006-.127a.996.996 0 00-.199-.593z"
          fill="#0066B3"
        />
      </g>
      <g strokeLinejoin="round">
        <path
          d="M211.87 38c0 4.13-2.68 7.47-6 7.47s-6-3.34-6-7.47 2-7.46 6-7.46 6 3.35 6 7.46zm-20.94 21v-3.92c0-5 3.48-7.47 8.53-8.43m21.32 6.9c-.66-4-3.92-6-8.42-6.88"
          stroke="#FFF"
          strokeWidth={4}
          strokeLinecap="round"
        />
        <path
          d="M199.43 46.67s2.67 2 4.6 3.49l-1.74 1.65-2.86-5.14m12.93 0s-2.66 2-4.59 3.49l1.73 1.65 2.86-5.14m24.07-2.39c0 3-2 5.38-4.38 5.38s-4.38-2.41-4.38-5.38c0-2.97 1.46-5.38 4.38-5.38s4.38 2.41 4.38 5.38zm-2.85 10a29.22 29.22 0 003.16-2.75h.11c3.7 0 6.25 2.3 6.25 5.93v1.83m-12.58-5.01a30.36 30.36 0 01-3.16-2.75h-.11a6 6 0 00-5.45 2.78"
          stroke="#FFF"
          strokeWidth={4}
          strokeLinecap="round"
        />
        <path
          d="M223.2 58.6c0 3-2 5.38-4.38 5.38s-4.38-2.41-4.38-5.38c0-2.97 1.46-5.38 4.38-5.38s4.38 2.41 4.38 5.38zm-2.85 9.84a24.89 24.89 0 003.16-2.91h.11c3.69 1 6.25 2.46 6.25 6.09v1.83m-12.58-5.01a24.14 24.14 0 01-3.16-2.91H214c-3.7 1-6.25 2.46-6.25 6.09v1.83"
          stroke="#FFF"
          strokeWidth={4}
          strokeLinecap="round"
        />
        <path
          d="M211.87 38c0 4.13-2.68 7.47-6 7.47s-6-3.34-6-7.47 2-7.46 6-7.46 6 3.35 6 7.46z"
          stroke="#F60"
          strokeWidth={2}
        />
        <path
          d="M190.93 59v-3.92c0-5 3.48-7.47 8.53-8.43m21.32 6.9c-.66-4-3.92-6-8.42-6.88"
          stroke="#F60"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          d="M199.43 46.67s2.67 2 4.6 3.49l-1.74 1.65-2.86-5.14m12.93 0s-2.66 2-4.59 3.49l1.73 1.65 2.86-5.14"
          stroke="#F60"
          strokeWidth={2}
          fill="#F60"
          fillRule="nonzero"
          strokeLinecap="round"
        />
        <path
          d="M236.43 44.28c0 3-2 5.38-4.38 5.38s-4.38-2.41-4.38-5.38c0-2.97 1.46-5.38 4.38-5.38s4.38 2.41 4.38 5.38z"
          stroke="#0066B3"
          strokeWidth={2}
        />
        <path
          d="M233.58 54.28a29.22 29.22 0 003.16-2.75h.11c3.7 0 6.25 2.3 6.25 5.93v1.83m-12.58-5.01a30.36 30.36 0 01-3.16-2.75h-.11a6 6 0 00-5.45 2.78"
          stroke="#0066B3"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          d="M223.2 58.6c0 3-2 5.38-4.38 5.38s-4.38-2.41-4.38-5.38c0-2.97 1.46-5.38 4.38-5.38s4.38 2.41 4.38 5.38z"
          stroke="#0066B3"
          strokeWidth={2}
        />
        <path
          d="M220.35 68.44a24.89 24.89 0 003.16-2.91h.11c3.69 1 6.25 2.46 6.25 6.09v1.83m-12.58-5.01a24.14 24.14 0 01-3.16-2.91H214c-3.7 1-6.25 2.46-6.25 6.09v1.83"
          stroke="#0066B3"
          strokeWidth={2}
          strokeLinecap="round"
        />
      </g>
    </g>
  </svg>
);

export default Clients;
