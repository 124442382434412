import { createSelector } from 'reselect';

import { t } from 'shared/utils';
import { RootState } from 'store';
import { Interval } from 'types/entities/RecurringTransactionInterval';
import { buildCategories } from 'components/CategorySearch/utils';

export const getIntervals = (state: RootState) => state.contracts.intervals.data;
export const getLineCategories = (state: RootState) => state.contracts.lineCategories.data;
export const getOldLineCategories = (state: RootState) => state.contracts.oldLineCategories.data;

export const getIntervalOptions = createSelector(getIntervals, (intervals) =>
  intervals.map((interval) => ({
    label: t(`recurring_expenses.interval_options.${interval.name}`),
    value: interval.id,
  }))
);

export const getContractIntervalOptions = createSelector(getIntervals, (intervals) =>
  intervals
    .filter((interval) =>
      [Interval.Weekly, Interval.Monthly, Interval.Quarterly, Interval.Yearly].includes(
        interval.name
      )
    )
    .map((interval) => ({
      label: t(`recurring_expenses.interval_options.${interval.name}`),
      value: interval.id,
    }))
);

export const getLineCategoryOptions = createSelector(getLineCategories, (categories) =>
  buildCategories(categories)
);

export const getOldLineCategoryOptions = createSelector(getOldLineCategories, (categories) => {
  return categories;
});

export const getExpenseContracts = (state: RootState) => state.contracts.expenseContracts.data;

export const getExpenseContractsIsLoading = (state: RootState) =>
  state.contracts.expenseContracts.isLoading;

export const getExpenseContractsParams = (state: RootState) =>
  state.contracts.expenseContracts.params;

export const getExpenseContractsParamsFilters = (state: RootState) =>
  state.contracts.expenseContracts.params.filters;

export const getExpenseContractsParamsGlobalFilter = (state: RootState) =>
  state.contracts.expenseContracts.params.globalFilter;

export const getRevenueContracts = (state: RootState) => state.contracts.revenueContracts.data;

export const getRevenueContractsIsLoading = (state: RootState) =>
  state.contracts.revenueContracts.isLoading;

export const getRevenueContractsParams = (state: RootState) =>
  state.contracts.revenueContracts.params;

export const getRevenueContractsParamsFilters = (state: RootState) =>
  state.contracts.revenueContracts.params.filters;

export const getRevenueContractsParamsGlobalFilter = (state: RootState) =>
  state.contracts.revenueContracts.params.globalFilter;
