import React, { FunctionComponent } from 'react';
import { Field } from 'redux-form';

import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import { SelectField, TextField } from 'components/Form';
import { SectionRow } from 'components/Form/Section/Section';
import RemoveButton from 'components/RemoveButton/RemoveButton';

import styles from './CompanyAuthorizedPerson.module.css';

const honorifics = ['Herr', 'Frau'];
const honorificsOptions = honorifics.map((h) => ({ value: h, label: h }));

interface CompanyAuthorizedPersonProps {
  person: string;
  onDelete: () => void;
  forGeneralPartner: boolean;
}

const CompanyAuthorizedPerson: FunctionComponent<CompanyAuthorizedPersonProps> = ({
  person,
  onDelete,
  forGeneralPartner = false,
}) => (
  <SectionRow dataId="authorized-person-row" className={styles.person}>
    <div>
      <Field
        name={`${person}.honorific`}
        dataId="input-authorized-person-honorific"
        label={t('profile.company.honorific')}
        component={SelectField}
        options={honorificsOptions}
        placeholder={t('forms.placeholders.select')}
        theme={styles.honorific}
        small
        required
        checker={checker}
      />
    </div>
    <div>
      <Field
        name={`${person}.degree`}
        dataId="input-authorized-person-degree"
        label={t('profile.company.degree')}
        component={TextField}
        validatePristine
        placeholder={t('profile.company.placeholders.degree')}
        small
        checker={checker}
      />
    </div>
    <div>
      <Field
        name={`${person}.name`}
        dataId="input-authorized-person-name"
        label={t('profile.company.representative_name')}
        component={TextField}
        validatePristine
        placeholder={t('profile.company.placeholders.representative_name')}
        checker={checker}
        required
      />
    </div>
    <div>
      <Field
        name={`${person}.companyPosition`}
        dataId="input-authorized-person-company-position"
        theme={styles.lastField}
        label={t('profile.company.company_position')}
        component={TextField}
        validatePristine
        placeholder={t('profile.company.placeholders.company_position')}
        checker={checker}
        required={forGeneralPartner}
      />
    </div>
    <div>
      <RemoveButton
        onClick={onDelete}
        dataId="button-authorized-person-delete"
        label={t('profile.company.remove_authorized_person')}
        className={styles.deletePerson}
      />
    </div>
  </SectionRow>
);

export default CompanyAuthorizedPerson;
