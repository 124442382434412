import React, { memo, SVGProps } from 'react';

const DocumentRound = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 49 48" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <ellipse fill="#BFD9EC" opacity={0.5} cx={24.253} cy={24} rx={24.253} ry={24} />
      <path
        d="M28.818 10.346a2.575 2.575 0 0 0-1.875-.826h-9.467c-1.453 0-2.641 1.26-2.641 2.8v22.4c0 1.54 1.175 2.8 2.628 2.8H33.32c1.453 0 2.641-1.26 2.641-2.8V19.082c0-.742-.277-1.456-.779-1.974l-6.365-6.762Zm.542 21.574h-7.923c-.726 0-1.32-.63-1.32-1.4 0-.77.594-1.4 1.32-1.4h7.923c.726 0 1.32.63 1.32 1.4 0 .77-.594 1.4-1.32 1.4Zm0-5.6h-7.923c-.726 0-1.32-.63-1.32-1.4 0-.77.594-1.4 1.32-1.4h7.923c.726 0 1.32.63 1.32 1.4 0 .77-.594 1.4-1.32 1.4Zm-2.641-8.4v-6.3l7.262 7.7H28.04c-.726 0-1.32-.63-1.32-1.4Z"
        fill="#0487EA"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(DocumentRound);
