import get from 'lodash/get';
import isString from 'lodash/isString';
import moment from 'moment';

import { DATE_FORMAT } from 'constants/datetime';
import honorifics from 'constants/honorifics';
import { outgoingInvoiceSelector } from 'reducers/form';
import { t } from 'shared/utils';
import getDateXDaysFromNow from 'shared/utils/getDateXDaysFromNow';
import getSalutationHonorific from 'shared/utils/getSalutationHonorific';

const [SIR] = honorifics;

interface PaymentReminderValues {
  salutation: string;
  salutationContent: string;
  footer: string;
}

interface PaymentReminderInitialValues extends Partial<PaymentReminderValues> {
  introduction: string;
  paymentReminderDueDate: string;
  currentDueDate: string;
}

interface Reminder {
  id: number;
  introduction: string;
  footer: string;
  subject: string;
  paymentReminderDueDate: string;
  currentDueDate: string;
}

// TODO: Use Store type when its ready
const buildPaymentReminderValues = (state: any): PaymentReminderValues => ({
  salutation: get(state, 'defaultValues.values.paymentReminderSalutation'),
  salutationContent: get(state, 'defaultValues.values.paymentReminderSalutationContent'),
  footer: get(state, 'defaultValues.values.paymentReminderFooter'),
});

export const getPaymentReminderInitialValues = (
  state: any,
  reminder: Reminder,
  isCreating: boolean
): PaymentReminderInitialValues => {
  const defaultInitialValues = buildPaymentReminderValues(state);
  const { introduction, subject, footer } = reminder;
  const client = outgoingInvoiceSelector(state, 'client') || get(state, 'outgoingInvoice.client');
  const { honorific = '', lastName = '' } = client || {};
  const politeReturn = honorific
    ? t(`revenue.form.individual_contact.polite_return.${honorific === SIR ? 'sir' : 'mrs'}`, {
        lastName,
      })
    : t('revenue.form.individual_contact.polite_return.company');
  const paymentRemindersCount = get(state, 'outgoingInvoice.details.paymentRemindersCount', 0) + 1;

  const currentReminderDueDate = reminder.currentDueDate
    ? moment(reminder.currentDueDate).format(DATE_FORMAT)
    : undefined;

  if (!isCreating) {
    return {
      introduction:
        introduction || `${politeReturn}\n\n${t('reminder.template_messages.introduction')}`,
      footer: defaultInitialValues.footer || footer || t('reminder.template_messages.footer'),
      ...state.outgoingInvoice.details,
      subject: `${subject || paymentRemindersCount}. ${t('reminder.subject')}`,
      paymentReminderDueDate: currentReminderDueDate || getDateXDaysFromNow(14),
      currentDueDate: currentReminderDueDate || getDateXDaysFromNow(14),
      client,
    };
  }

  const salutation = getSalutationHonorific(
    'defaultPaymentReminderSalutationDefined',
    defaultInitialValues,
    client
  );
  const salutationContent = isString(defaultInitialValues.salutationContent)
    ? defaultInitialValues.salutationContent
    : t('reminder.template_messages.introduction');

  return {
    introduction: `${salutation}\n\n${salutationContent}`,
    footer: isString(defaultInitialValues.footer)
      ? defaultInitialValues.footer
      : footer || t('reminder.template_messages.footer'),
    ...state.outgoingInvoice.details,
    subject: `${subject || paymentRemindersCount}. ${t('reminder.subject')}`,
    paymentReminderDueDate: currentReminderDueDate || getDateXDaysFromNow(14),
    currentDueDate: currentReminderDueDate || getDateXDaysFromNow(14),
    client,
  };
};
