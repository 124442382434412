import { useEffect, useState } from 'react';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { getBankTransfers as getBankAccountTransfers } from 'api/me/bankAccounts/bankTransfers';
import { getBankTransfers } from 'api/me/bankTransfers';
import EntityPath from 'constants/entitiesPaths';
import { BankTransfer } from 'types/entities/BankTransfer';

import { Option } from './List.types';

export const useFetchBankTransfers = (selectedOption: Option) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<BankTransfer[]>([]);

  useEffect(() => {
    if (!selectedOption) return;

    const fetch = async () => {
      const params = {
        per_page: 5,
        sort: '-date',
      };
      let response;

      setIsLoading(true);

      try {
        response =
          selectedOption.id === null
            ? await getBankTransfers(params)
            : await getBankAccountTransfers(selectedOption.id, params);
      } catch (e) {
        setData([]);
        setIsLoading(false);
        return;
      }

      const bankTransfers =
        build<BankTransfer>(normalize(response.data), EntityPath.BankTransfers) || [];

      setData(bankTransfers);
      setIsLoading(false);
    };

    fetch();
  }, [selectedOption]);

  return { isLoading, data };
};
