import React, { FunctionComponent, ReactNode } from 'react';

import { TabbedNavigationContext, TabbedNavigationContextValue } from '../TabbedNavigation';

export interface TabHeaderRenderProps {
  selectedTabName: string | null;
  selectTab: (tabName: string) => void;
}

const TabHeader: FunctionComponent = ({ children }) => {
  const renderProps = children as (props: TabHeaderRenderProps) => ReactNode;

  return (
    <TabbedNavigationContext.Consumer>
      {({ selectedTabName, selectTab }: TabbedNavigationContextValue) =>
        renderProps({ selectTab, selectedTabName })
      }
    </TabbedNavigationContext.Consumer>
  );
};

export default TabHeader;
