import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import logoImage from 'images/app-logo.svg';

import styles from './Logo.module.css';

const Logo = ({ className = '', ...rest }) => (
  <img {...rest} alt="VR Smart Guide" className={cx(styles.logo, className)} src={logoImage} />
);

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
