import React, { ReactNode } from 'react';
import cx from 'classnames';

import { t } from 'shared/utils';

import { Box, Header, Wrapper } from './FlipCard.styled';

import styles from './FlipCard.module.css';

type FlipCardProps = {
  className?: string;
  children: ReactNode;
  id?: string;
  dataId?: string;
};

const FlipCard = ({ children, className = '', id = '', dataId, ...rest }: FlipCardProps) => (
  <div className={cx(styles.cardContainer, className)} id={id} data-id={dataId} {...rest}>
    {children}
  </div>
);

type SideProps = {
  toggleView: () => void;
  isFetching?: boolean;
  active?: boolean;
  front?: boolean;
  back?: boolean;
  header?: ReactNode;
  className?: string;
  children: ReactNode;
};

export const Side = ({
  active = false,
  front = false,
  back = false,
  toggleView,
  isFetching = false,
  className = '',
  header = null,
  children,
}: SideProps) => (
  <Wrapper
    className={cx(
      styles.root,
      {
        [styles.front]: front,
        [styles.back]: back,
        [styles.active]: active,
        [styles.disableAnimation]: true,
      },
      className
    )}
  >
    <Header>
      {header}
      {!isFetching && (
        <button
          type="button"
          title={t('dashboard.charts.toggle_view')}
          className={cx(styles.viewIcon, {
            [styles.iconTable]: !active,
            [styles.iconGraph]: active,
          })}
          onClick={toggleView}
        />
      )}
    </Header>
    <Box>{children!}</Box>
  </Wrapper>
);

export default FlipCard;
