import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

import { name } from './constants';

const slice = (state: RootState) => state[name];

export const getIsLoaded = createSelector(slice, (state) => !state.isLoading || !!state.data);

export const getVrsoContractId = createSelector(slice, (slice) => slice.data?.vrsoContractId);

export const getOnboardingDismissed = createSelector(
  slice,
  (slice) => slice.data?.onboardingDismissed
);

export const getObsTooltipDismissed = createSelector(
  slice,
  (slice) => slice.data?.obsTooltipDismissed
);

export const getContractsOnboardingDismissed = createSelector(
  slice,
  (slice) => slice.data?.contractsOnboardingDismissed
);

export const getWelcomeTileDismissed = createSelector(
  slice,
  (slice) => slice.data?.welcomeTileDismissed
);

export const getFinishedInvoiceTemplatesStep = createSelector(
  slice,
  (slice) => slice.data?.finishedInvoiceTemplatesStep
);

export const getFinishedAllSteps = createSelector(
  getFinishedInvoiceTemplatesStep,
  (hasFinishedInvoiceTemplatesStep) => hasFinishedInvoiceTemplatesStep
);

export const getVisitedTaskPlanner = createSelector(
  slice,
  (slice) => slice.data?.visitedTaskPlanner
);

export const getIsFetchingProfile = (state: RootState) => state.profile.isFetching;

export const getFeatureRatings = (state: RootState) => state.profile.credentials.featureRatings;
