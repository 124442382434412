import React from 'react';
import cx from 'classnames';
import { string } from 'prop-types';

import InfoIcon from 'components/InfoIcon/InfoIcon';

import styles from './AdditionalInfo.module.css';

const AdditionalInfo = ({ text, containerClassName = '' }) => (
  <div className={cx(styles.info, containerClassName)}>
    <InfoIcon className={styles.infoIcon} />
    <div className={styles.text}>{text}</div>
  </div>
);

AdditionalInfo.propTypes = {
  text: string.isRequired,
  containerClassName: string,
};

export default AdditionalInfo;
