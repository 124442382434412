import { connect } from 'react-redux';

import {
  deleteLineItem as deleteLineItemAC,
  updateLineItem as updateLineItemAC,
} from 'actions/incoming-invoice/line-items';
import { TYPE_EXPENSES } from 'constants/row-types';
import { incomingInvoiceSelector } from 'reducers/form';
import {
  incomingInvoiceDetailsSelector,
  incomingInvoiceFormValuesSelector,
} from 'selectors/incomingInvoice';
import { t } from 'shared/utils';

import EditItemRow from '../LineItems/EditItemRow';

const categoryInfo = t('expenses.form.details_section.fields.invoice_line_category_id_info');

const mapStateToProps = (state) => ({
  invoiceId: incomingInvoiceDetailsSelector(state).id,
  insertedAsGross: incomingInvoiceDetailsSelector(state).insertedAsGross,
  isCreditNote: incomingInvoiceFormValuesSelector(state).creditNote,
  lineCategories: state.incomingInvoice.lineCategories,
  vatValue: incomingInvoiceSelector(state, 'vat'),
  invoiceLineCategoryId: incomingInvoiceSelector(state, 'invoiceLineCategoryId'),
});

const mapDispatchToProps = (dispatch) => ({
  deleteLineItem: (...args) => dispatch(deleteLineItemAC(...args)),
  updateLineItem: (...args) => dispatch(updateLineItemAC(...args)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { lineItem: { id: lineItemId } = {} } = ownProps;
  const { invoiceId, insertedAsGross } = stateProps;
  const { deleteLineItem, updateLineItem } = dispatchProps;

  return {
    ...ownProps,
    ...stateProps,
    invoiceInsertedAsGross: insertedAsGross,
    translationPath: 'expenses',
    rowType: TYPE_EXPENSES,
    cells: [
      { name: 'position', type: 'positionInput' },
      {
        name: 'invoiceLineCategoryId',
        type: 'multiselect',
        renderName: 'lineCategory',
        info: categoryInfo,
      },
      { name: 'vat', type: 'select' },
      { type: insertedAsGross ? 'grossLinked' : 'netLinked' },
    ],
    updateLineItem: (values) => updateLineItem(invoiceId, lineItemId, values),
    deleteLineItem: (values) => deleteLineItem(invoiceId, lineItemId, values),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditItemRow);
