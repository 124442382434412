import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { requestPasswordReset } from 'actions/password-reset';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { existChecker } from 'shared/utils/form-checking';
import CardView, { HeadingSection, Section } from 'components/CardView';
import { FieldsGroup, SubmitButton, TextField } from 'components/Form';
import I18n from 'components/I18n';
import Logo from 'components/Logo';

import formPageStyles from '../Shared.module.css';
import localStyles from './ForgotPassword.module.css';

const styles = { ...formPageStyles, ...localStyles };

const checker = ({ meta }) => existChecker(meta);

let Form = ({ handleSubmit, requestReset, submitting }) => {
  const [didRequestReset, setDidRequestReset] = useState(false);

  const onSubmit = useCallback(
    (formValues) =>
      requestReset(formValues).finally(() => {
        setDidRequestReset(true);
      }),
    []
  );

  const formContents = () => (
    <div>
      <div className={styles.title}>{t('password_reset.forgot_password.title')}</div>
      <div className={styles.contentSection}>
        {`${t('password_reset.forgot_password.subtitle.line_1')}`}
        <br />
        {`${t('password_reset.forgot_password.subtitle.line_2')}`}
      </div>
      <FieldsGroup>
        <Field
          component={TextField}
          name="email"
          placeholder={t('password_reset.forgot_password.email')}
          type="email"
          checker={checker}
          dataId="input-email"
        />
      </FieldsGroup>

      <SubmitButton
        wrapperClassName={styles.center}
        className={styles.submitButton}
        value={t('password_reset.forgot_password.submit')}
        active={!submitting}
        dataId="button-submit"
      />
      <div className={cx(styles.footer, styles.bold)}>
        <Link to={paths.login} className={styles.link} data-id="link-login">
          <I18n t="password_reset.forgot_password.back_to_login" />
        </Link>
      </div>
    </div>
  );
  const success = () => (
    <div>
      <div className={cx(styles.contentSection, styles.bold)} data-id="message-success1">
        {`${t('password_reset.forgot_password.success.line_1')}`}
      </div>
      <div className={styles.instruction} data-id="message-success2">
        <div className={styles.bold} data-id="message-success">
          {`${t('password_reset.forgot_password.success.subtitle')}`}
        </div>
        {`${t('password_reset.forgot_password.success.line_2')}`}
        <div>{`${t('password_reset.forgot_password.success.line_3')}`}</div>
      </div>
    </div>
  );
  return (
    <form onSubmit={handleSubmit(onSubmit)}>{didRequestReset ? success() : formContents()}</form>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  requestReset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const validate = ({ email }) => {
  const errors = {};
  if (!email) errors.email = true;
  return errors;
};

const mapDispatchToProps = (dispatch) => ({
  requestReset(values) {
    return dispatch(requestPasswordReset(values.email));
  },
});

Form = compose(
  connect(
    (state) => ({
      initialValues: { email: formValueSelector('login')(state, 'email') },
    }),
    mapDispatchToProps
  ),
  reduxForm({ form: 'forgot_password', validate })
)(Form);

export default connect((s) => s.injected)(() => (
  <div className={styles.pageWrapper}>
    <div className={styles.wrapper}>
      <CardView className={cx(styles.card, styles.root)}>
        <HeadingSection className={styles.cardSectionHeading}>
          <Logo className={styles.logo} />
        </HeadingSection>
        <Section className={styles.cardSection}>
          <Form />
        </Section>
      </CardView>
    </div>
  </div>
));
