import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';

import { OBSTooltip } from 'containers/Dashboard/OBSTooltip/OBSTooltip';
import { isLgUp } from 'shared/utils/breakpoints';

import * as SC from './OBSTooltipContainer.styled';

type OBSTooltipContainerProps = {
  scale?: {
    x: (n: number) => number;
    y: (n: number) => number;
  };
  datum?: {
    index: number;
    totalSum: number;
  };
};

type OBSTooltipContainerState = {
  isTooltipVisible: boolean;
  x: number | null;
  y: number | null;
  scrollPosition: number | null;
  position: {
    x: number | null;
    y: number | null;
  };
};

const OBSTooltipContainer = ({ scale, datum }: OBSTooltipContainerProps) => {
  const { x: scaleX, y: scaleY } = scale!;
  const { index, totalSum } = datum!;

  const [state, setState] = useState<OBSTooltipContainerState>({
    isTooltipVisible: true,
    x: null,
    y: null,
    scrollPosition: null,
    position: {
      x: null,
      y: null,
    },
  });

  const rectRef = useRef<SVGRectElement>(null);

  useEffect(() => {
    calcTooltipPosition();
    window.addEventListener('scroll', calcTooltipPosition, { passive: true });

    return () => {
      (window as any).removeEventListener('scroll', calcTooltipPosition, { passive: true });
    };
  });

  const getCoordinates = () => {
    const x = scaleX(index);
    const y = scaleY(totalSum);
    return { x, y };
  };

  const calcTooltipPosition = () => {
    const { x, y } = getCoordinates();
    const scrollPosition = window?.pageYOffset;

    const domainHasChanged = state.x !== x || state.y !== y;
    const documentHasScrolled = scrollPosition !== state.scrollPosition;

    const shouldRecalculate = domainHasChanged || documentHasScrolled;

    if (rectRef && shouldRecalculate) {
      const {
        x: domX,
        left,
        y: domY,
        bottom,
      } = rectRef.current?.getBoundingClientRect() as DOMRect;
      const newCords = {
        x: Math.floor(domX || left),
        y: Math.floor(domY || bottom),
      };

      setState((prevState) => ({ ...prevState, x, y, scrollPosition, position: newCords }));
    }
  };

  const swallowEvent = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const { position } = state;
  const { x, y } = getCoordinates();
  const isLg = isLgUp();
  return (
    <g
      onMouseDown={swallowEvent}
      onTouchStart={swallowEvent}
      onMouseMove={swallowEvent}
      onClick={swallowEvent}
    >
      <rect x={x} y={y} width={1} height={1} fill="red" ref={rectRef} />
      <Portal>
        <SC.Obs
          style={{
            left: position.x!,
            top: position.y!,
            zIndex: 1000,
            position: 'fixed',
            transform: `translateY(-50%) translateX(${isLg ? '10px' : '-290px'})`,
          }}
        >
          <OBSTooltip />
        </SC.Obs>
      </Portal>
    </g>
  );
};

export default OBSTooltipContainer;
