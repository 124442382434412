import styled from 'styled-components';

import media from 'shared/styles/breakpoints';

type WrapperProps = {
  isLoading?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  min-width: min(90vw, 570px);
  position: relative;

  ${({ isLoading }) => isLoading && 'min-height: 20vh'}
`;

export const Content = styled.div`
  padding: 28px 20px 0 20px;
`;

export const Title = styled.h1`
  margin: 0 0 26px 0;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
`;

export const Buttons = styled.div`
  padding: 20px 20px 10px 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media.greaterThan('md-up')`
    flex-direction: row;
    padding-bottom: 20px;

    button:first-child {
      margin-left: 0;
    }
  `}
`;

export const InstallmentOption = styled.label`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;
  align-items: center;

  input {
    margin: 0;
  }
`;
