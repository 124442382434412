import React from 'react';

import buttonStyles from 'containers/OutgoingInvoices/Table/Table.module.css';
import { t } from 'shared/utils';

type NewContractButtonProps = {
  onClick: () => void;
  title: string;
  dataId?: string;
};

const NewContractButton = ({ onClick, title, dataId }: NewContractButtonProps) => (
  <button className={buttonStyles.newInvoice} onClick={onClick} type="button" data-id={dataId}>
    {t(`payment_reminders.create_new.${title}`)}
  </button>
);

export default NewContractButton;
