import * as React from 'react';

const Success = (props: any) => (
  <svg width={27} height={22} viewBox="0 0 27 22" {...props}>
    <path
      d="M9.7 19.188l-8.031-7.001a1.023 1.023 0 00-1.412.072.948.948 0 00.074 1.368l8.81 7.68c.424.37 1.081.322 1.442-.106L26.441 2.426a.948.948 0 00-.141-1.363 1.022 1.022 0 00-1.407.136L9.699 19.19z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </svg>
);

export default Success;
