/**
 * Number util
 * Return a string representation of a number
 */

export const toFixed = (number, precision = 2) => {
  const floatNumber = parseFloat(number);

  if (Number.isNaN(floatNumber)) {
    return null;
  }

  return floatNumber.toFixed(precision).replace('.', ',');
};

export const sub = (a, b, precision = 2) => {
  const floatA = parseFloat(a);
  const floatB = parseFloat(b);

  if (Number.isNaN(floatA) || Number.isNaN(floatB)) {
    return null;
  }

  const result = floatA - floatB;
  return result.toFixed(precision);
};

export const getAbsoluteValue = (value) => {
  if (Number.isNaN(Math.abs(value))) {
    return null;
  }

  return Math.abs(value).toString();
};
