import { flow, isNil, mapValues, pickBy, set } from 'lodash';

import {
  CLEAR_FILTERS,
  CLEAR_FILTERS_TO_INITIAL,
  SET_FILTERS_ACTIVE,
  SET_QUERY_PARAM,
  SET_STATUS_FILTER,
} from 'constants/common/filters';
import { sanitizeNumber } from 'shared/utils';
import { hasNumericCharacters, isNumber, isParsedDate } from 'shared/utils/regex';

const FULL_TEXT_SEARCH_FILTER = 'fullTextSearch';

export function getSanitizedFilters(filters) {
  return pickBy(
    mapValues(filters, (filterValue, filterName) => {
      if (filterName === FULL_TEXT_SEARCH_FILTER) return filterValue;

      if (isParsedDate(filterValue)) return filterValue;
      if (isNumber(filterValue)) return sanitizeNumber(filterValue);
      if (!isNumber(filterValue) && hasNumericCharacters(filterValue)) return '';
      return filterValue;
    }),
    (f) => !isNil(f) && f !== ''
  );
}

export const initialState = {};

export const parsedFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FILTERS_TO_INITIAL:
      return initialState;
    case SET_STATUS_FILTER:
      return { ...state, status: action.payload.status };
    case SET_QUERY_PARAM:
      return { ...state, [action.payload.param]: action.payload.parsedValue };
    case CLEAR_FILTERS:
      if (action.payload.clearStatus) {
        return mapValues(state, () => '');
      }

      const emptyValues = (s) => mapValues(s, () => '');
      const setStatus = (s) => set(s, 'status', state.status);

      return flow([emptyValues, setStatus])(state);

    default:
      return state;
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FILTERS_TO_INITIAL:
      return initialState;
    case SET_FILTERS_ACTIVE:
      return { ...state, forcedFilterActive: true };
    case SET_STATUS_FILTER:
      return { ...state, status: action.payload.status };
    case SET_QUERY_PARAM:
      return { ...state, [action.payload.param]: action.payload.value };
    case CLEAR_FILTERS:
      if (action.payload.clearStatus) {
        return mapValues(state, () => '');
      }

      const emptyValues = (s) => mapValues(s, () => '');
      const setStatus = (s) => set(s, 'status', state.status);

      return flow([emptyValues, setStatus])(state);

    default:
      return state;
  }
};
