import React from 'react';
import cx from 'classnames';

import styles from './SubsectionHeadline.module.css';

interface SubsectionHeadlineProps {
  title: string;
  className?: string;
  // Temporary flag until we adjust a11y styles for other components using SubsectionHeadline
  // as the idea is to remove divider completely
  // check 'https://jira.finstreet.de/browse/PBW-5263 - #6 | 2nd warning' for details
  showDivider?: boolean;
}

const SubsectionHeadline = ({ title, className, showDivider = true }: SubsectionHeadlineProps) => (
  <div className={cx(styles.container, className)}>
    <span className={!showDivider ? styles.titleWithoutDivider : styles.title}>{title}</span>
    {showDivider && <span className={styles.divider} />}
  </div>
);

export default SubsectionHeadline;
