import { fetchIncomingInvoice } from 'actions/incoming-invoice';
import { showNotification } from 'actions/notification';
import { fetchOutgoingInvoice } from 'actions/outgoing-invoice';
import { CALL_API } from 'constants/api';
import {
  ACCEPT_SUGGESTION_FAILURE,
  ACCEPT_SUGGESTION_REQUEST,
  ACCEPT_SUGGESTION_SUCCESS,
  CREATE_FAILURE,
  CREATE_LABEL_FAILURE,
  CREATE_LABEL_REQUEST,
  CREATE_LABEL_SUCCESS,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_FAILURE,
  DELETE_LABEL_FAILURE,
  DELETE_LABEL_REQUEST,
  DELETE_LABEL_SUCCESS,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  REJECT_SUGGESTION_FAILURE,
  REJECT_SUGGESTION_REQUEST,
  REJECT_SUGGESTION_SUCCESS,
  UPDATE_LABEL_FAILURE,
  UPDATE_LABEL_REQUEST,
  UPDATE_LABEL_SUCCESS,
} from 'constants/bank-transfer-connections';
import { DE_ACCOUNT_REVENUE_ASSIGNED, DE_KONTOUMSAETZE } from 'constants/kontoumsaetze';
import {
  connectionCreateSuccess,
  connectionDeleteFailure,
  connectionDeleteSuccess,
  createLabelSuccess,
  deleteLabelSuccess,
  updateLabelSuccess,
} from 'notifications/bank-transfer-connections';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { piwikHelpers } from 'shared/utils/piwik';
import { bindServerValidation } from 'shared/utils/server-validation';
import { ResourceType } from 'types/entities/AssignableResource';

export const apiCreateConnection = (
  resourceId,
  resourceType,
  bankTransferId,
  assigmentOption,
  assignedValue,
  jokerOptionReason
) => ({
  [CALL_API]: {
    data: {
      transfer_connectable_id: resourceId,
      transfer_connectable_type: resourceType,
      bank_transfer_id: bankTransferId,
      selected_option: assigmentOption,
      amount_assigned: assignedValue,
      joker_option_reason: jokerOptionReason,
    },
    method: 'POST',
    endpoint: '/me/bank_transfer_connections',
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const apiUpdateConnection = (
  resourceId,
  resourceType,
  bankTransferId,
  assigmentOption,
  assignedValue,
  jokerOptionReason
) => ({
  [CALL_API]: {
    data: {
      transfer_connectable_id: resourceId,
      transfer_connectable_type: resourceType,
      bank_transfer_id: bankTransferId,
      selected_option: assigmentOption,
      amount_assigned: assignedValue,
      joker_option_reason: jokerOptionReason,
    },
    method: 'PUT',
    endpoint: '/me/bank_transfer_connections',
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const apiDeleteConnection = (resourceId, resourceType, bankTransferId) => ({
  [CALL_API]: {
    data: {
      transfer_connectable_id: resourceId,
      transfer_connectable_type: resourceType,
      bank_transfer_id: bankTransferId,
    },
    method: 'DELETE',
    endpoint: '/me/bank_transfer_connections',
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

const acceptSuggestionTypes = [
  ACCEPT_SUGGESTION_REQUEST,
  ACCEPT_SUGGESTION_SUCCESS,
  ACCEPT_SUGGESTION_FAILURE,
];
const rejectSuggestionTypes = [
  REJECT_SUGGESTION_REQUEST,
  REJECT_SUGGESTION_SUCCESS,
  REJECT_SUGGESTION_FAILURE,
];
export const createApiSuggestionAction =
  ({ endpoint, method = 'PUT', types = [] } = {}) =>
  ({ invoiceId, bankTransferId, assigmentOption, assignedValue, jokerOptionReason }) => ({
    [CALL_API]: {
      data: {
        invoiceId,
        bankTransferId,
        selected_option: assigmentOption,
        amount_assigned: assignedValue,
        joker_option_reason: jokerOptionReason,
      },
      method,
      endpoint,
      types,
    },
  });

export const createBankTransferConnection =
  (...args) =>
  (dispatch) =>
    dispatch(apiCreateConnection(...args)).then(() => {
      dispatch(showNotification(connectionCreateSuccess));
    });

export const updateBankTransferConnection =
  (...args) =>
  (dispatch) =>
    dispatch(apiUpdateConnection(...args)).then(() => {
      piwikHelpers.trackEvent(DE_KONTOUMSAETZE, DE_ACCOUNT_REVENUE_ASSIGNED);
      piwikHelpers.trackGoal(3);
      dispatch(showNotification(connectionCreateSuccess));
    });

export const deleteBankTransferConnection =
  (resourceId, resourceType, bankTransferId, options = {}) =>
  (dispatch) =>
    dispatch(apiDeleteConnection(resourceId, resourceType, bankTransferId))
      .then(() => {
        if (!options.refreshAfterDelete) {
          return;
        }

        const refreshConnectionAction =
          resourceType === ResourceType.OutgoingInvoice
            ? fetchOutgoingInvoice
            : fetchIncomingInvoice;

        dispatch(refreshConnectionAction(resourceId));
      })
      .then(() => dispatch(showNotification(connectionDeleteSuccess)))
      .catch((err) => {
        apiErrorHandler(err);
        dispatch(showNotification(connectionDeleteFailure));
      });

const apiAcceptIncomingInvoiceSuggestion = createApiSuggestionAction({
  endpoint: '/me/incoming_invoice_transfer_connection_suggestion/accept',
  types: acceptSuggestionTypes,
});

const apiRejectIncomingInvoiceSuggestion = createApiSuggestionAction({
  endpoint: '/me/incoming_invoice_transfer_connection_suggestion/reject',
  types: rejectSuggestionTypes,
});

const apiAcceptOutgoingInvoiceSuggestion = createApiSuggestionAction({
  endpoint: '/me/outgoing_invoice_transfer_connection_suggestion/accept',
  types: acceptSuggestionTypes,
});

const apiRejectOutgoingInvoiceSuggestion = createApiSuggestionAction({
  endpoint: '/me/outgoing_invoice_transfer_connection_suggestion/reject',
  types: rejectSuggestionTypes,
});

const apiCreateBankTransferLabel = ({ bankTransferId, ...label }) => ({
  [CALL_API]: {
    data: {
      bank_transfer_id: bankTransferId,
      ...label,
    },
    method: 'POST',
    endpoint: '/me/bank_transfer_labels',
    types: [CREATE_LABEL_REQUEST, CREATE_LABEL_SUCCESS, CREATE_LABEL_FAILURE],
    omitReducers: true,
  },
});

const apiUpdateBankTransferLabel = ({ bankTransferId, ...label }) => ({
  [CALL_API]: {
    data: {
      bank_transfer_id: bankTransferId,
      ...label,
    },
    method: 'PUT',
    endpoint: `/me/bank_transfer_labels/${label.id}`,
    types: [UPDATE_LABEL_REQUEST, UPDATE_LABEL_SUCCESS, UPDATE_LABEL_FAILURE],
    omitReducers: true,
  },
});

const apiDeleteBankTransferLabel = (id) => ({
  [CALL_API]: {
    data: {
      id,
    },
    method: 'DELETE',
    endpoint: `/me/bank_transfer_labels/${id}`,
    types: [DELETE_LABEL_REQUEST, DELETE_LABEL_SUCCESS, DELETE_LABEL_FAILURE],
    omitReducers: true,
  },
});

export const rejectConnectionSuggestion =
  ({ resourceType, ...args } = {}) =>
  (dispatch) =>
    bindServerValidation(
      resourceType === ResourceType.OutgoingInvoice
        ? apiRejectOutgoingInvoiceSuggestion(args)
        : apiRejectIncomingInvoiceSuggestion(args),
      dispatch
    ).catch((err) => {
      apiErrorHandler(err);
    });

export const acceptConnectionSuggestion =
  ({ resourceType, ...args } = {}) =>
  (dispatch) =>
    bindServerValidation(
      resourceType === ResourceType.OutgoingInvoice
        ? apiAcceptOutgoingInvoiceSuggestion(args)
        : apiAcceptIncomingInvoiceSuggestion(args),
      dispatch
    ).catch((err) => {
      apiErrorHandler(err);
    });

export const createBankTransferLabel =
  ({ bankTransferId, ...label }) =>
  (dispatch) =>
    dispatch(apiCreateBankTransferLabel({ bankTransferId, ...label })).then(() =>
      dispatch(showNotification(createLabelSuccess))
    );

export const updateBankTransferLabel =
  ({ bankTransferId, ...label }) =>
  (dispatch) =>
    dispatch(apiUpdateBankTransferLabel({ bankTransferId, ...label })).then(() =>
      dispatch(showNotification(updateLabelSuccess))
    );

export const deleteBankTransferLabel = (id) => (dispatch) =>
  bindServerValidation(apiDeleteBankTransferLabel(id), dispatch)
    .catch((err) => {
      apiErrorHandler(err);
    })
    .then(() => dispatch(showNotification(deleteLabelSuccess)));
