import React, { ComponentType, useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { Location } from 'history';

type RouteLeavingGuardProps = {
  exclude?: string[];
  blocked?: boolean;
  modal: ComponentType<{ onCancel: () => void; onConfirm: () => void }>;
};

const RouteLeavingGuard = ({ blocked, modal: Modal, exclude }: RouteLeavingGuardProps) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [location, setLocation] = useState<Location | null>(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleBlockNavigation = (location: Location) => {
    const excluded = exclude?.includes(location.pathname);

    if (!isConfirmed && !excluded) {
      setShowModal(true);
      setLocation(location);

      return false;
    }

    return true;
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    setShowModal(false);
    setIsConfirmed(true);
  };

  useEffect(() => {
    if (isConfirmed && location) {
      setLocation(null);
      setIsConfirmed(false);
      history.push(location);
    }
  }, [history, isConfirmed, location]);

  return (
    <>
      <Prompt when={blocked} message={handleBlockNavigation} />
      {showModal && <Modal onCancel={handleCancel} onConfirm={handleConfirm} />}
    </>
  );
};

export default RouteLeavingGuard;
