import styled from 'styled-components';

import BaseBox from 'redesign/components/atoms/Box/Box';

export const Tile = styled(BaseBox)`
  min-height: 150px;
  width: 100%;
  padding-left: ${({ theme }) => theme.space['6']};
  padding-right: ${({ theme }) => theme.space['6']};
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
