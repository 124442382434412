import React, { useCallback, useState } from 'react';
import { bool, func, string } from 'prop-types';

import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import Modal from 'components/Modal';

import TextareaField from '../Form/TextareaField/TextareaField';

import styles from './Assigment.module.css';

const JokerModal = ({ isOpen, onAccept, onClose, value, onChange }) => {
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    await onAccept();
    setIsSaving(false);
  }, [onAccept]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className={styles.header}>
        <span className={styles.text}>{t('bank_transfers.joker_modal.header')}</span>
        <button className={styles.cancel} onClick={onClose} />
      </div>
      <div className={styles.modalBody}>
        <div className={styles.title}>{t('bank_transfers.joker_modal.title')}</div>
        <div className={styles.subtitle}>{t('bank_transfers.joker_modal.subtitle')}</div>
        <TextareaField
          label={t('bank_transfers.joker_modal.label')}
          dataId="BankTransferAssignment:input-joker-reason"
          placeholder={t('bank_transfers.joker_modal.placeholder')}
          minHeight={64}
          name="jokerValue"
          onChange={onChange}
          value={value}
        />
        <div className={styles.footerSection}>
          <ActionButton
            dataId="BankTransferAssignment:button-joker-cancel"
            appearance="outlined"
            onClick={onClose}
            label={t('bank_transfers.joker_modal.abort')}
          />
          <ActionButton
            dataId="BankTransferAssignment:button-joker-accept"
            disabled={!value || isSaving}
            appearance="primary"
            onClick={handleSave}
            label={t('bank_transfers.joker_modal.ok')}
          />
        </div>
      </div>
    </Modal>
  );
};

JokerModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onChange: func.isRequired,
  onAccept: func.isRequired,
  value: string,
};

export default JokerModal;
