import React from 'react';
import { connect } from 'react-redux';

import ProductCatalogGroupCreator from 'containers/ProductCatalog/Groups/common/Creator';

const EditProductCatalogGroup = (props) => (
  <ProductCatalogGroupCreator {...props} creatorMode="edit" />
);

const mapStateToProps = (_, ownProps) => ({ id: ownProps.match.params.id });

export default connect(mapStateToProps)(EditProductCatalogGroup);
