export const OPTION_FULLY_PAID = {
  id: 1,
  value: 'fully-paid',
  apiValue: 'fully-paid',
  translateKey: 'bank_transfers.options.fully_paid',
  isInputVisible: false,
  dataId: 'BankTransferAssignment:assignment-box-type-fully-paid',
};

export const OPTION_PARTLY_PAID = {
  id: 2,
  value: 'partly-paid',
  apiValue: 'partly-paid',
  translateKey: 'bank_transfers.options.partly_paid',
  isInputVisible: true,
  inputLabelTranslateKey: 'bank_transfers.partly_invoice_sum',
  inputPlaceholderTranslateKey: 'bank_transfers.enter_partly_paid_amount',
  dataId: 'BankTransferAssignment:assignment-box-type-partly-paid',
};

export const OPTION_DISCOUNT = {
  id: 3,
  value: 'discount',
  apiValue: 'discount',
  translateKey: 'bank_transfers.options.discount',
  isInputVisible: false,
  dataId: 'BankTransferAssignment:assignment-box-type-discount',
};

export const OPTION_JOKER = {
  id: 4,
  value: 'joker',
  apiValue: 'joker',
  translateKey: 'bank_transfers.options.joker',
  isInputVisible: true,
  inputLabelTranslateKey: 'bank_transfers.joker_invoice_sum',
  inputPlaceholderTranslateKey: 'bank_transfers.joker_invoice_sum',
  dataId: 'BankTransferAssignment:assignment-box-type-joker',
};

export const OPTIONS_ARRAY = [OPTION_FULLY_PAID, OPTION_PARTLY_PAID, OPTION_DISCOUNT, OPTION_JOKER];
