import 'react-dates-legacy/initialize';

import { RefObject, useEffect } from 'react';
import { Moment } from 'moment';

import { DATE_FORMAT } from 'constants/datetime';
import { getMomentDateForDatepicker } from 'shared/utils/date/parser';

import { SetIsFocused } from './DateField.types';
import { isOutsideRange, reopenPopper } from './DateField.utils';

import 'react-dates-legacy/lib/css/_datepicker.css';

export const useCloseOnOutsideClick = ({
  datepickerRef,
  setIsFocused,
}: {
  datepickerRef: RefObject<HTMLDivElement>;
  setIsFocused: SetIsFocused;
}) =>
  useEffect(() => {
    const handleClick = (e: Event) => {
      const datePickerElement = datepickerRef.current;
      const target = e.target instanceof HTMLElement ? e.target : null;
      if (!datePickerElement || !target) return;

      const isClickInsideOfDatepicker = datePickerElement.contains(target);
      if (isClickInsideOfDatepicker) return;

      setIsFocused(false);
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

export const useReopenWhenTyping = ({
  date,
  isFocused,
  setIsFocused,
  minDate,
  maxDate,
  setIgnoreFormattingOnClose,
}: {
  date: string;
  isFocused: boolean;
  setIsFocused: SetIsFocused;
  minDate?: Moment;
  maxDate?: Moment;
  setIgnoreFormattingOnClose: (v: boolean) => void;
}) =>
  useEffect(() => {
    const currentMomentDate = getMomentDateForDatepicker(date);
    const isDateValid = currentMomentDate.isValid();

    if (!isFocused) return;
    const shouldReopenPopper =
      isDateValid &&
      !isOutsideRange({
        momentDate: currentMomentDate,
        minDate,
        maxDate,
      });
    if (shouldReopenPopper) {
      reopenPopper(setIsFocused);
      setIgnoreFormattingOnClose(true);
    }
  }, [date]);

export const useFormatWhenPopperCloses = ({
  date,
  isFocused,
  fieldOnChange,
  ignoreFormattingOnClose,
  setIgnoreFormattingOnClose,
}: {
  date: string;
  isFocused: boolean;
  fieldOnChange: (date: string) => void;
  ignoreFormattingOnClose: boolean;
  setIgnoreFormattingOnClose: (v: boolean) => void;
}) =>
  useEffect(() => {
    if (ignoreFormattingOnClose) {
      setIgnoreFormattingOnClose(false);
      return;
    }
    if (isFocused || !date) return;

    const dateMoment = getMomentDateForDatepicker(date);
    const isDateValid = dateMoment.isValid();
    const textToSet = isDateValid ? dateMoment.format(DATE_FORMAT) : date;

    fieldOnChange(textToSet);
  }, [date]);
