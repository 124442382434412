import { CALL_API } from 'constants/api';
import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/monthly-overview';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiFetchOverview = (
  includePreviousYear = false,
  monthsCount = 12,
  { incomingInvoices = true, outgoingInvoices = true } = {}
) => ({
  [CALL_API]: {
    params: {
      includePreviousYear,
      monthsCount,
      includeResources: {
        incomingInvoices,
        outgoingInvoices,
      },
    },
    endpoint: '/me/revenue_expenses/months',
    method: 'GET',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const fetchOverview =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchOverview(...args)).catch(apiErrorHandler);
