import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as reduxPush } from 'connected-react-router';
import PropTypes from 'prop-types';

import AbortButton from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/ActionsSection/AbortButton';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import { ButtonAppearances } from 'components/Button';
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'components/LineItems';

import styles from './ActionSection.module.css';

const ActionsSection = ({
  abort,
  isInvoiceLoaded,
  isFormSubmitting,
  needsAnotherDatevReport: { alreadyCreated, showDialog },
  saveInvoice,
  push,
  disabledSubmitButton,
  canSubmit,
}) => {
  if (!isInvoiceLoaded) return null;

  if (alreadyCreated && !showDialog) {
    push(paths.outgoingInvoices);
  }

  const isSavingDisabled = isFormSubmitting || disabledSubmitButton;

  return (
    <ActionPanel className={cx(styles.creatorActionsSection, IGNORE_OUTSIDE_CLICK_CLASS)} wrapped>
      <ActionButton
        appearance={ButtonAppearances.primary}
        disabled={!canSubmit || isSavingDisabled}
        onClick={saveInvoice}
        label={t('revenue.form.save_imported_invoice')}
      />
      <AbortButton onClick={abort} />
    </ActionPanel>
  );
};

ActionsSection.propTypes = {
  abort: PropTypes.func.isRequired,
  isInvoiceLoaded: PropTypes.bool.isRequired,
  saveInvoice: PropTypes.func.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired,
  needsAnotherDatevReport: PropTypes.shape({
    alreadyCreated: PropTypes.bool,
    showDialog: PropTypes.bool,
  }),
  disabledSubmitButton: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isInvoiceLoaded: !!state.outgoingInvoice.details.id,
  needsAnotherDatevReport: state.outgoingInvoice.postCreateStatus,
  disabledSubmitButton: state.outgoingInvoice.details.disabled,
});

export default connect(mapStateToProps, { push: reduxPush })(ActionsSection);
