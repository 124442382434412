import { combineReducers } from 'redux';

import { DefaultValuesActions } from 'actions/default-values';
import {
  CLEAR_DEFAULT_VALUES,
  FETCH_CLIENT_DEFAULTS_FAILURE,
  FETCH_CLIENT_DEFAULTS_REQUEST,
  FETCH_CLIENT_DEFAULTS_SUCCESS,
  FETCH_CUSTOMER_DEFAULTS_FAILURE,
  FETCH_CUSTOMER_DEFAULTS_REQUEST,
  FETCH_CUSTOMER_DEFAULTS_SUCCESS,
  RESET_CLIENT_DEFAULTS_FAILURE,
  RESET_CLIENT_DEFAULTS_SUCCESS,
  RESET_CUSTOMER_DEFAULTS_FAILURE,
  RESET_CUSTOMER_DEFAULTS_SUCCESS,
  UPDATE_CLIENT_DEFAULTS_SUCCESS,
  UPDATE_CUSTOMER_DEFAULTS_FAILURE,
  UPDATE_CUSTOMER_DEFAULTS_SUCCESS,
} from 'constants/default-values';
import { mapToCamelCaseRecursive } from 'shared/utils';
import { DefaultValues } from 'types/entities/DefaultValues';

const isFetching = (state: boolean = false, action: DefaultValuesActions): boolean => {
  switch (action.type) {
    case FETCH_CUSTOMER_DEFAULTS_REQUEST:
      return true;
    case FETCH_CUSTOMER_DEFAULTS_SUCCESS:
    case FETCH_CUSTOMER_DEFAULTS_FAILURE:
      return false;
    default:
      return state;
  }
};

const values = (
  state: Partial<DefaultValues> = {},
  action: DefaultValuesActions | { type: typeof CLEAR_DEFAULT_VALUES }
): Partial<DefaultValues> => {
  switch (action.type) {
    case FETCH_CUSTOMER_DEFAULTS_SUCCESS:
    case UPDATE_CUSTOMER_DEFAULTS_SUCCESS:
    case RESET_CUSTOMER_DEFAULTS_SUCCESS:
    case FETCH_CLIENT_DEFAULTS_SUCCESS:
    case UPDATE_CLIENT_DEFAULTS_SUCCESS:
    case RESET_CLIENT_DEFAULTS_SUCCESS:
      return mapToCamelCaseRecursive(action.response) as DefaultValues;
    case CLEAR_DEFAULT_VALUES:
      return {};
    default:
      return state;
  }
};

const errors = (state = {}, action: DefaultValuesActions): object => {
  switch (action.type) {
    case FETCH_CUSTOMER_DEFAULTS_FAILURE:
    case UPDATE_CUSTOMER_DEFAULTS_FAILURE:
    case RESET_CUSTOMER_DEFAULTS_FAILURE:
    case FETCH_CLIENT_DEFAULTS_FAILURE:
    case RESET_CLIENT_DEFAULTS_FAILURE:
      return action.errors;
    case FETCH_CUSTOMER_DEFAULTS_REQUEST:
    case FETCH_CLIENT_DEFAULTS_REQUEST:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  values,
  errors,
});
