import React from 'react';

import { t } from 'shared/utils';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import Section from 'components/Section/Section';

import BankAccountSection from './BankAccountSection/BankAccountSection';
import HideFooterModal from './HideFooterModal/HideFooterModal';
import InvoiceTemplateCheckbox from './InvoiceTemplateCheckbox/InvoiceTemplateCheckbox';
import * as SC from './TemplateTabEditInfo.styled';

type TemplateTabEditInfoProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  showHideFooterModal: () => void;
  template: {
    qrCodesEnabled?: boolean;
    showSenderAddress?: boolean;
    showContactDetails?: boolean;
    showFooter?: boolean;
    showValidityInformation?: boolean;
  };
  updateTemplate: (data: { [fieldName: string]: boolean }) => void;
  isInvoiceLayoutEnabled: boolean;
  isLoading: boolean;
};

const TemplateTabEditInfo = ({
  template: {
    qrCodesEnabled,
    showSenderAddress,
    showContactDetails,
    showFooter,
    showValidityInformation,
  } = {},
  updateTemplate,
  isOpen,
  onClose,
  onConfirm,
  showHideFooterModal,
  isInvoiceLayoutEnabled,
  isLoading,
}: TemplateTabEditInfoProps) => {
  const handleInputChange = (name: string) => (value: boolean) => () =>
    !isLoading ? updateTemplate({ [name]: value }) : undefined;

  return (
    <div id="invoice-template-upload">
      {isInvoiceLayoutEnabled && (
        <Section title={t('invoice_templates.form.stationery')} column>
          <InvoiceTemplateCheckbox
            name="address"
            dataIds={{
              input: 'InvoiceTemplates:checkbox-address-input',
              label: 'InvoiceTemplates:checkbox-address-label',
            }}
            customOnChange={handleInputChange('showSenderAddress')(!showSenderAddress)}
            checked={showSenderAddress}
          >
            {t('invoice_templates.form.address')}
          </InvoiceTemplateCheckbox>
          <InvoiceTemplateCheckbox
            name="contactDetails"
            dataIds={{
              input: 'InvoiceTemplates:checkbox-contact-details-input',
              label: 'InvoiceTemplates:checkbox-contact-details-label',
            }}
            customOnChange={handleInputChange('showContactDetails')(!showContactDetails)}
            checked={showContactDetails}
          >
            {t('invoice_templates.form.contact_details')}
          </InvoiceTemplateCheckbox>
        </Section>
      )}
      <Section title={t('invoice_templates.form.section_content')}>
        <SC.List>
          <li>{t('invoice_templates.form.content_1')}</li>
          <li>{t('invoice_templates.form.content_2')}</li>
          <li>{t('invoice_templates.form.content_3')}</li>
        </SC.List>
      </Section>

      <Section title={t('invoice_templates.form.section_footer')} column>
        {isInvoiceLayoutEnabled && (
          <InvoiceTemplateCheckbox
            name="footer"
            dataIds={{
              input: 'InvoiceTemplates:checkbox-contact-footer-input',
              label: 'InvoiceTemplates:checkbox-contact-footer-label',
            }}
            checked={showFooter}
            customOnChange={
              showFooter ? showHideFooterModal : handleInputChange('showFooter')(true)
            }
          >
            {t('invoice_templates.form.footer')}
          </InvoiceTemplateCheckbox>
        )}
        <SC.FooterText>
          <li>{t('invoice_templates.form.footer_text_1')}</li>
          <li>{t('invoice_templates.form.footer_text_2')}</li>
          <li>{t('invoice_templates.form.footer_text_3')}</li>
        </SC.FooterText>
        <InvoiceTemplateCheckbox
          name="qrCodes"
          dataIds={{
            input: 'InvoiceTemplates:checkbox-qr-input',
            label: 'InvoiceTemplates:checkbox-qr-label',
          }}
          customOnChange={handleInputChange('qrCodesEnabled')(!qrCodesEnabled)}
          checked={qrCodesEnabled}
        >
          {t('invoice_templates.form.qr_codes_enabled')}
          <InfoIcon text={t('invoice_templates.form.qr_codes_enabled_tooltip')} />
        </InvoiceTemplateCheckbox>
        <InvoiceTemplateCheckbox
          name="validityInformation"
          dataIds={{
            input: 'InvoiceTemplates:checkbox-validity-information-input',
            label: 'InvoiceTemplates:checkbox-validity-information-label',
          }}
          customOnChange={handleInputChange('showValidityInformation')(!showValidityInformation)}
          checked={showValidityInformation}
        >
          {t('invoice_templates.form.validity_information')}
          <InfoIcon text={t('invoice_templates.form.validity_information_tooltip')} />
        </InvoiceTemplateCheckbox>
      </Section>

      <BankAccountSection onChange={handleInputChange('showFooter')(true)} />

      <HideFooterModal isOpen={isOpen} onClose={onClose} onConfirm={onConfirm} />
    </div>
  );
};

export default TemplateTabEditInfo;
