export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const SET_QUERY_PARAM = 'SET_QUERY_PARAM';
export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const SET_QUICK_FILTER = 'SET_QUICK_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CLEAR_FILTERS_TO_INITIAL = 'CLEAR_FILTERS_TO_INITIAL';
export const CLEAR_SEARCH_FILTERS = 'CLEAR_SEARCH_FILTERS';
export const SET_FILTERS_ACTIVE = 'SET_FILTERS_ACTIVE';

export const QuickFilters = {
  ALL: 'all',
  SUGGESTIONS: 'suggestions',
};

export const FilterTypes = {
  WITH_ANY_INVOICE_SUGGESTIONS: 'with_any_invoice_suggestions',
  STATUS: 'status',
};
