import React from 'react';
import cx from 'classnames';

import styles from 'containers/Profile/SectionWithMargin.module.css';
import Section from 'components/Form/Section/Section';
import { SectionProps } from 'components/Form/Section/Section.types';

const SectionWithMargin = ({ wrapperTheme, ...rest }: SectionProps) => (
  <Section wrapperTheme={cx(styles.theme, wrapperTheme)} {...rest} />
);

export default SectionWithMargin;

export { SectionRow } from 'components/Form/Section/Section';
