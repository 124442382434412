import React, { useEffect, useRef, useState } from 'react';

import { checkIsTruncated, formatDate, formatMoney, t } from 'shared/utils';
import { Client } from 'types/entities/Client';
import { Supplier } from 'types/entities/Supplier';

import {
  Amount,
  Box,
  ContractSubject,
  ContractTooltip,
  Date,
  Icon,
  IconInvoice,
  Party,
  Type,
} from './BankTransferDetails.styled';

type InstallmentAssignmentProps = {
  transactionDate: string;
  grossAmount: number;
  isRevenue: boolean;
  contractId: number;
  client?: Client;
  supplier?: Supplier;
  contractSubject: string;
};

const InstallmentAssignment = ({
  transactionDate,
  grossAmount,
  isRevenue,
  client,
  contractId,
  supplier,
  contractSubject,
}: InstallmentAssignmentProps) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const supplierOrClient = isRevenue ? client : supplier;
  const contractNumberRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const contractNumber = contractNumberRef.current;

    if (contractNumber === null) return;

    setIsTruncated(checkIsTruncated(contractNumber));
  }, [contractNumberRef.current]);

  return (
    <Box>
      <Icon>
        <IconInvoice width={34} />
        <p>{t('bank_transfers.assigment.modal.details.contract')}</p>
      </Icon>
      <Party>{supplierOrClient}</Party>
      <ContractTooltip offset={0.1} message={contractId} isTooltipHidden={!isTruncated}>
        <ContractSubject ref={contractNumberRef}>{contractSubject}</ContractSubject>
      </ContractTooltip>
      <Date>{formatDate(transactionDate)}</Date>
      <Type>{t('bank_transfers.assigment.modal.details.installment')}</Type>
      <Amount isNegative={!isRevenue}>{formatMoney((isRevenue ? 1 : -1) * grossAmount)}</Amount>
    </Box>
  );
};

export default InstallmentAssignment;
