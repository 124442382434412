import { CALL_API } from 'constants/api';
import {
  GET_FAILURE,
  GET_REQUEST,
  GET_SUCCESS,
  PROCESS_FAILURE,
  PROCESS_REQUEST,
  PROCESS_SUCCESS,
} from 'constants/outgoing-invoice/ocr';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

export const apiProcessInvoiceFiles = (invoiceId) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: `/me/outgoing_invoices/${invoiceId}/ocr`, // change url to proper one after consultation with backend-developer
    types: [PROCESS_REQUEST, PROCESS_SUCCESS, PROCESS_FAILURE],
  },
});

export const apiGetOcrResult = (invoiceId) => ({
  [CALL_API]: {
    method: 'GET',
    endpoint: `/me/outgoing_invoices/${invoiceId}/ocr`, // change url to proper one after consultation with backend-developer
    types: [GET_REQUEST, GET_SUCCESS, GET_FAILURE],
  },
});

export const processInvoiceFiles =
  (...args) =>
  (dispatch) =>
    bindServerValidation(apiProcessInvoiceFiles(...args), dispatch, {
      isReduxForm: false,
    }).catch(apiErrorHandler);

export const getOcrResult =
  (...args) =>
  (dispatch) =>
    dispatch(apiGetOcrResult(...args)).catch(apiErrorHandler);
