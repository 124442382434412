import React from 'react';

const ProductCatalog = ({ dataId = '', className = '' }) => (
  <svg width={380} height={138} data-id={dataId} className={className}>
    <defs>
      <filter
        x="-12.7%"
        y="-11%"
        width="128.8%"
        height="128.8%"
        filterUnits="objectBoundingBox"
        id="prefix__a"
      >
        <feOffset dx={2} dy={4} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-19.7%"
        y="-17.1%"
        width="144.7%"
        height="144.7%"
        filterUnits="objectBoundingBox"
        id="prefix__c"
      >
        <feOffset dx={2} dy={4} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" in="shadowBlurOuter1" />
      </filter>
      <circle id="prefix__b" cx={59} cy={59} r={59} />
      <circle id="prefix__d" cx={38} cy={38} r={38} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M7.06 44.889c16.14-13.334 33.492-22 52.053-26 27.843-6 48.422 5 79.897 0s14.526-17 61.738-11 48.314-12 80.999-7c32.685 5 37 35 83.636 44 46.636 9-33.682 51-64.16 56-30.476 5-30.423 23-82.476 23-52.054 0-79.737 1-93.053-13-13.316-14-24.11-24-83.528-27-39.613-2-51.315-15-35.106-39z"
        fill="#F3F3F3"
      />
      <path
        d="M139.339 60.455c-7.007 16-1.347 28 16.977 36 27.487 12 45.272-10 71.142-38s14.552-28 54.974-10"
        stroke="#0066B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="4,4"
      />
      <g transform="translate(36.432 6)">
        <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
        <use fill="#FFF" xlinkHref="#prefix__b" />
      </g>
      <g transform="translate(256.432 14)">
        <use fill="#000" filter="url(#prefix__c)" xlinkHref="#prefix__d" />
        <use fill="#FFF" xlinkHref="#prefix__d" />
      </g>
      <g transform="translate(61.432 22.5)">
        <path
          d="M20 21.5h53.605a4 4 0 013.909 4.85l-8.261 38a4 4 0 01-3.909 3.15H20a4 4 0 01-4-4v-38a4 4 0 014-4z"
          stroke="#0066B3"
          strokeWidth={2}
          fill="#FFF"
          strokeLinejoin="round"
        />
        <path
          d="M46.429 17.5a4 4 0 014 4l-.001 1.879L61 23.38a4 4 0 014 4V55.5a4 4 0 01-4 4H9a4 4 0 01-4-4V27.38a4 4 0 014-4l20-.001V21.5a4 4 0 014-4h13.429z"
          stroke="#0066B3"
          strokeWidth={2}
          strokeLinejoin="round"
        />
        <path
          d="M24.513.5a4 4 0 014 4v1.879L63 6.38a4 4 0 014 4V38.5a4 4 0 01-4 4H6a4 4 0 01-4-4V10.38a4 4 0 013.422-3.959V4.5a4 4 0 014-4h15.091z"
          stroke="#0066B3"
          strokeWidth={2}
          fill="#FFF"
          strokeLinejoin="round"
        />
        <g transform="translate(5.568 10.5)" fill="#F60">
          <rect width={8} height={8} rx={2} />
          <rect y={10} width={8} height={8} rx={2} />
          <rect x={24} width={8} height={8} rx={2} />
          <rect x={24} y={10} width={8} height={8} rx={2} />
          <rect x={12} width={8} height={8} rx={2} />
          <rect x={12} y={10} width={8} height={8} rx={2} />
          <rect x={36} width={8} height={8} rx={2} />
          <rect x={36} y={10} width={8} height={8} rx={2} />
          <rect x={48} width={8} height={8} rx={2} />
          <rect x={48} y={10} width={8} height={8} rx={2} />
        </g>
        <rect
          stroke="#0066B3"
          strokeWidth={2}
          fill="#FFF"
          strokeLinejoin="round"
          y={26.5}
          width={69}
          height={41}
          rx={4}
        />
      </g>
      <g fill="#2382C8">
        <path d="M283.302 69.327h21.259V41.51l-3.192-3.16h-18.067v30.976zm22.194 1.85h-23.128a.931.931 0 01-.936-.926V37.424c0-.51.42-.924.936-.924h19.387c.25 0 .488.097.662.272l3.74 3.7a.92.92 0 01.275.656v29.124a.929.929 0 01-.936.925z" />
        <path d="M306.27 42.339h-4.838V37.5" />
        <path d="M305.509 43.127h-4.612a.934.934 0 01-.922-.947v-4.733c0-.524.414-.947.922-.947s.922.422.922.947v3.787h3.691c.508 0 .922.422.922.946a.934.934 0 01-.922.947m-11.068 0h-8.056a.933.933 0 01-.922-.947c0-.523.411-.946.922-.946h8.056c.507 0 .922.422.922.946a.935.935 0 01-.923.947m-1.843 3.788h-6.212a.935.935 0 01-.922-.947c0-.524.411-.947.922-.947h6.212c.507 0 .922.422.922.947a.934.934 0 01-.922.946m2.767 3.788h-8.979a.933.933 0 01-.922-.947c0-.526.411-.949.922-.949h8.98c.507 0 .92.422.92.948a.935.935 0 01-.922.945m-.693 10.404h-8.275a.934.934 0 01-.922-.946c0-.524.413-.948.922-.948h8.275c.51 0 .922.424.922.948s-.413.946-.923.946m.001 2.814h-8.275a.936.936 0 01-.922-.948c0-.52.413-.945.922-.945h8.275c.51 0 .922.422.922.946a.936.936 0 01-.923.95" />
        <path d="M293.656 67.948c-3.627 0-6.579-2.893-6.579-6.448 0-3.557 2.952-6.452 6.579-6.452.867 0 1.714.167 2.516.492.48.195.708.736.508 1.207a.943.943 0 01-1.225.495 4.783 4.783 0 00-1.798-.35c-2.591 0-4.698 2.067-4.698 4.608 0 2.54 2.106 4.607 4.698 4.607a4.75 4.75 0 001.798-.35.946.946 0 011.227.5.912.912 0 01-.508 1.203c-.8.326-1.648.492-2.516.492" />
      </g>
      <path
        d="M230.4 95.8V73M219 84.4h22.8"
        stroke="#0066B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.5}
      />
      <path
        stroke="#F60"
        strokeWidth={2.2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M172.848 44.168l3.317-3.317 13.268 13.267-3.317 3.317z"
      />
      <path fill="#F60" d="M176.335 40.629l1.055 1.208v7.036l-4.69-4.679z" />
      <path
        d="M158.79 42.353c-4.293-4.297-4.291-11.26.004-15.555 4.296-4.294 11.26-4.294 15.555 0 4.296 4.295 4.298 11.258.004 15.555a10.988 10.988 0 01-15.562 0zm5.372-14.53a7.177 7.177 0 017.482 1.677"
        stroke="#F60"
        strokeWidth={2.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ProductCatalog;
