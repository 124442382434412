import React, { memo } from 'react';
import cx from 'classnames';
import { Validator } from 'redux-form';

import CurrencyField from 'components/Form/CurrencyField';
import TextField from 'components/Form/TextField';

import styles from './Amount.module.css';

export enum Color {
  Red = 'red',
  Green = 'green',
}

// TODO add CurrencyFieldProp when CurrencyField will support TS
type AmountProps = {
  name: string;
  label: string;
  checker?: (props: any) => boolean;
  validate?: Validator;
  isLabelHidden?: boolean;
  color: Color;
  dataId?: string;
};

const Amount = ({
  name,
  label,
  isLabelHidden,
  color,
  checker,
  validate,
  dataId,

  ...rest
}: AmountProps) => (
  <CurrencyField
    name={name}
    label={label}
    component={TextField}
    inputClassName={cx(styles.amount, {
      [styles.redText]: color === Color.Red,
      [styles.greenText]: color === Color.Green,
    })}
    isRawField
    checker={checker}
    validate={validate}
    isLabelHidden={isLabelHidden}
    onlyPositive
    /**
     * Remove this prop when currency field will be refactored. Without it this component won't be able
     * to display initial values properly (when set by initialValues prop)
     */
    initialParsedValue="0"
    dataId={dataId}
    {...rest}
  />
);

export default memo(Amount);
