import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import noop from 'lodash/noop';
import { func, string } from 'prop-types';

import styles from './TableBody.module.css';

export const TableBody = ({ droppableId, children = noop }) => (
  <Droppable droppableId={droppableId}>
    {(provided) => (
      <div
        className={styles.tableBody}
        {...provided.droppableProps}
        ref={provided.innerRef}
        innerRef={provided.innerRef}
      >
        {children({ provided })}
      </div>
    )}
  </Droppable>
);

TableBody.propTypes = {
  droppableId: string,
  children: func,
};
