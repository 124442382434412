import React from 'react';
import noop from 'lodash/noop';
import { bool, func, string } from 'prop-types';

import styles from './ActionButton.module.css';

const ActionButton = ({ disabled, label, dataId, onClick = noop }) => (
  <div className={styles.root}>
    <button
      type="submit"
      onClick={onClick}
      disabled={disabled}
      className={styles.primaryButton}
      data-id={dataId}
    >
      {label}
    </button>
  </div>
);

ActionButton.propTypes = {
  disabled: bool,
  label: string,
  onClick: func,
  dataId: string,
};

export default ActionButton;
