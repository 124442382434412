import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import { fetchCompany } from 'actions/company';
import {
  abortChanges,
  downloadOutgoingInvoice,
  fetchNewOutgoingInvoice,
  importOutgoingInvoice,
  sendOutgoingInvoice,
} from 'actions/outgoing-invoice';
import { IMPORT_MODE } from 'constants/common/crud';
import { FORM_NAME } from 'constants/outgoing-invoice';
import { PIWIK_ACTION_REVENUE_ABORT, PIWIK_CATEGORY_REVENUE } from 'constants/piwik';
import { outgoingInvoiceSelector } from 'reducers/form';
import { withTransitionPrevent, withWarningAlert } from 'shared/hoc';
import { t } from 'shared/utils';
import getDiscountData from 'shared/utils/get-discount-data';
import getDateXDaysFromNow from 'shared/utils/getDateXDaysFromNow';
import { piwikHelpers } from 'shared/utils/piwik';
import { LineItemsAutoSaveConsumer, LineItemsAutoSaveProvider } from 'components/LineItems';
import useLineItemsControl, { SectionType } from 'components/LineItems/useLineItemControl';

import CreateInvoiceSection from '../OutgoingInvoiceCreator/CreateInvoiceSection/CreateInvoiceSection';
import InvoiceDetailsSection from '../OutgoingInvoiceCreator/InvoiceDetailsSection';
import InvoiceSumSection from '../OutgoingInvoiceCreator/InvoiceSumSection';
import ActionsSection from './ActionsSection/ActionSection';
import UploadInvoiceSection from './UploadInvoiceSection/UploadInvoiceSection';

import styles from './OutgoingInvoiceImport.module.css';

const OutgoingInvoiceImport = ({
  abort,
  invoiceId,
  submitting,
  saveInvoice,
  handleSubmit,
  ocr,
  invoiceDetails,
  fetchNewInvoice,
}) => {
  const {
    manualHandleSelectCategory,
    setLineItemState,
    setShowCategoryChangeConfirmation,
    setShowVatIdWarning,
    showCategoryChangeConfirmation,
    defaultCategory,
    canSubmit,
    showVatIdWarning,
    category,
    taxRates,
    taxRateId,
    defaultVat,
    selectedCategory,
    lineCategories,
    isInitial,
  } = useLineItemsControl({
    sectionType: SectionType.outgoingInvoice,
    formName: FORM_NAME,
    isNewForm: true,
  });

  const abortAndTrack = () => {
    abort();
    piwikHelpers.trackEvent(
      PIWIK_CATEGORY_REVENUE,
      PIWIK_ACTION_REVENUE_ABORT,
      PIWIK_ACTION_REVENUE_ABORT
    );
  };

  const isReadonly = () => false;

  useEffect(() => {
    fetchNewInvoice();
    fetchCompany();
  }, []);

  return (
    <form>
      <LineItemsAutoSaveProvider>
        <div className={styles.main}>
          <div className={cx(styles.section, styles.creator)}>
            <div className={styles.subsection}>
              <div className={cx(styles.upload, styles.subsectionItem)}>
                <UploadInvoiceSection readonly={false} />
              </div>
              <div className={cx(styles.creator, styles.subsectionItem)}>
                <CreateInvoiceSection
                  newInvoice={true}
                  hasUploadSection
                  ocr={ocr}
                  isReadonly={isReadonly}
                  selectedCategory={selectedCategory}
                  lineCategories={lineCategories}
                  defaultCategory={defaultCategory}
                  category={category}
                  handleSelectCategory={manualHandleSelectCategory}
                  showCategoryChangeConfirmation={showCategoryChangeConfirmation}
                  setShowCategoryChangeConfirmation={setShowCategoryChangeConfirmation}
                  setShowVatIdWarning={setShowVatIdWarning}
                  showVatIdWarning={showVatIdWarning}
                />
              </div>
            </div>
            <InvoiceDetailsSection
              crudMode={IMPORT_MODE}
              isNew={true}
              readonly={isReadonly('lineItems')}
              defaultCategory={defaultCategory}
              isDraft={invoiceDetails?.draft}
              selectedCategory={selectedCategory}
              lineCategories={lineCategories}
              taxRates={taxRates}
              taxRateId={taxRateId}
              defaultVat={defaultVat}
              isInitial={isInitial}
              setLineItemState={setLineItemState}
            />
            <div className={styles.bottomSection}>
              <InvoiceSumSection />
            </div>
          </div>
        </div>
        <LineItemsAutoSaveConsumer>
          {({ handleSave }) => (
            <ActionsSection
              abort={abortAndTrack}
              saveInvoice={handleSave(handleSubmit(saveInvoice(invoiceId)))}
              isFormSubmitting={submitting}
              canSubmit={canSubmit}
            />
          )}
        </LineItemsAutoSaveConsumer>
      </LineItemsAutoSaveProvider>
    </form>
  );
};

OutgoingInvoiceImport.propTypes = {
  abort: func,
  invoiceId: string,
  invoiceDetails: shape(),
  submitting: bool,
  saveInvoice: func.isRequired,
  handleSubmit: func.isRequired,
  downloadInvoice: func.isRequired,
  sendInvoice: func.isRequired,
  fetchCompany: func.isRequired,
  fetchNewInvoice: func.isRequired,
  ocr: shape({ status: string.isRequired }),
  change: func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const client = outgoingInvoiceSelector(state, 'client') || get(state, 'outgoingInvoice.client');

  return {
    invoiceId: state.outgoingInvoice.details.id || ownProps.match.params.id,
    invoiceDetails: state.outgoingInvoice.details,
    initialValues: {
      ...state.outgoingInvoice.details,
      dueDate: getDateXDaysFromNow(14),
      creationDate: moment().format('DD.MM.YYYY'),
      client,
    },
    ocr: state.outgoingInvoice.ocr,
    currentClient: client,
    sendingDocumentsByEmailEnabled: state.profile.applicationFeatures.sendingDocumentsByEmail,
    buttonLabel: t('empty_entry_pages.outgoing_invoice.invoice_warning_box.button_label'),
    content: t('empty_entry_pages.outgoing_invoice.invoice_warning_box.content'),
    dataId: 'OutgoingInvoices:orange-warning-container',
  };
};

const mapDispatchToProps = (dispatch) => ({
  abort: () => dispatch(abortChanges()),
  fetchNewInvoice: () => dispatch(fetchNewOutgoingInvoice()),
  downloadInvoice: (details) => dispatch(downloadOutgoingInvoice(details)),
  sendInvoice: (arg) => dispatch(sendOutgoingInvoice(arg)),
  saveInvoice: (id) => (data) =>
    dispatch(importOutgoingInvoice(id, { ...data, ...getDiscountData(data), draft: false })),
  fetchCompany,
});

const reduxEnhancer = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(
  reduxEnhancer,
  reduxForm({
    form: 'outgoingInvoiceCreator',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
  }),
  withWarningAlert,
  withTransitionPrevent()
);

export default enhance(OutgoingInvoiceImport);
