import React, { memo, SVGProps } from 'react';

const Warning = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.48 2.493c1.024-1.99 3.953-1.99 4.978 0l11.128 19.912c1.171 1.991-.293 4.595-2.49 4.595H4.988c-2.343 0-3.66-2.604-2.635-4.595zM16 19a2 2 0 100 4 2 2 0 000-4zm0-10a2 2 0 00-2 2v4a2 2 0 004 0v-4a2 2 0 00-2-2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default memo(Warning);
