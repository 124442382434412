import React from 'react';

const IconDragAndDrop = () => (
  <svg
    width="9px"
    height="14px"
    viewBox="0 0 9 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Ziehen Sie die Position an die gewünschte Stelle</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-8.000000, -26.000000)">
        <g id="0.0-fundamentals/icons/draganddrop" transform="translate(5.000000, 26.000000)">
          <circle id="Oval" cx="5.2" cy="1.5" r="1.5" />
          <circle id="Oval-Copy-3" cx="10" cy="1.5" r="1.5" />
          <circle id="Oval-Copy" cx="5.2" cy="7" r="1.5" />
          <circle id="Oval-Copy-4" cx="10" cy="7" r="1.5" />
          <circle id="Oval-Copy-2" cx="5.2" cy="12.5" r="1.5" />
          <circle id="Oval-Copy-5" cx="10" cy="12.5" r="1.5" />
        </g>
      </g>
    </g>
  </svg>
);

export default IconDragAndDrop;
