import React, { Ref, useCallback, useRef } from 'react';
import { useHistory } from 'react-router';
import Downshift, { GetInputPropsOptions } from 'downshift';
import { JsonApiObject } from 'json-api-normalizer';

import { GLOBAL_SEARCH_CLICK_ON_RESULT } from 'constants/piwik';
import { t } from 'shared/utils';
import IconLoading from 'components/Icons/IconLoading';

import Search from './Search';
import {
  Category,
  IconSearch,
  Input,
  InputWrapper,
  Loading,
  MenuItem,
  MenuWrapper,
  Text,
  Title,
  Wrapper,
} from './SiteSearch.styled';
import { getCategory, getNumber, getUrl, trackEvent } from './utils';

export type SiteSearchProps = {
  className?: string;
  onChange?: (item: JsonApiObject) => void;
};

interface GetInputPropsOptionsRef extends GetInputPropsOptions {
  ref?: Ref<HTMLInputElement>;
  as?: undefined;
}

const SiteSearch = ({ className, onChange }: SiteSearchProps) => {
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (item: JsonApiObject | null) => {
      if (!item) return;

      trackEvent(GLOBAL_SEARCH_CLICK_ON_RESULT);

      const url = getUrl(item);

      if (url) {
        inputRef.current?.blur();
        onChange && onChange(item);
        history.push(url);
      }
    },
    [history, onChange]
  );

  return (
    <Downshift<JsonApiObject> itemToString={() => ''} selectedItem={null} onChange={handleChange}>
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        getRootProps,
        openMenu,
        highlightedIndex,
      }) => (
        <Wrapper
          data-id="site-search"
          {...getRootProps(undefined, { suppressRefError: true })}
          className={className}
        >
          <InputWrapper>
            <Input
              {...(getInputProps({
                name: 'site-search',
                autoComplete: 'off',
                onFocus: () => {
                  openMenu();
                },
                ref: inputRef,
                placeholder: t('redesign.molecules.site_search.placeholder'),
              }) as GetInputPropsOptionsRef)}
            />
            <IconSearch />
          </InputWrapper>
          <MenuWrapper {...getMenuProps()} data-id="menu">
            {isOpen &&
              (!inputValue || inputValue.length < 3 ? (
                <Text data-id="help-text">{t('redesign.molecules.site_search.help_text')}</Text>
              ) : (
                <Search text={inputValue}>
                  {({ loading, data }) => {
                    if (loading) {
                      return (
                        <Loading>
                          <IconLoading width="16" height="16" />
                        </Loading>
                      );
                    }

                    if (data.length) {
                      return (
                        <ul>
                          {data.map((item, index) => {
                            const title =
                              item.attributes.subject ||
                              item.attributes['company-name'] ||
                              item.attributes['last-name'] ||
                              item.attributes['name'];
                            const category = getCategory(item.type);
                            const number = getNumber(item);

                            return (
                              <MenuItem
                                {...getItemProps({
                                  index,
                                  item,
                                })}
                                key={index}
                                data-id={`option-${index + 1}`}
                                $isHighlighted={highlightedIndex === index}
                              >
                                <Title title={title}>{title}</Title>
                                <Category>
                                  {number && (
                                    <>
                                      <span title={number}>{number}</span>
                                      <span>•</span>
                                    </>
                                  )}
                                  <span>{category}</span>
                                </Category>
                              </MenuItem>
                            );
                          })}
                        </ul>
                      );
                    } else {
                      return (
                        <Text data-id="empty-text">
                          {t('redesign.molecules.site_search.empty_text')}
                        </Text>
                      );
                    }
                  }}
                </Search>
              ))}
          </MenuWrapper>
        </Wrapper>
      )}
    </Downshift>
  );
};

export default SiteSearch;
