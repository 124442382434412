import styled from 'styled-components';

import BaseInfoIcon from 'components/InfoIcon/InfoIcon';
import BaseBox from 'redesign/components/atoms/Box/Box';

export const TitleWrapper = styled.div`
  display: flex;
`;

export const Wrapper = styled.section`
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
`;

export const Box = styled(BaseBox)`
  display: grid;
  padding-top: 30px;
  min-height: 540px;
  grid-template-rows: auto 1fr auto;
`;

export const InfoIcon = styled(BaseInfoIcon)`
  margin-bottom: ${({ theme }) => theme.space['1']};
`;
