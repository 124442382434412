import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { fetchCompany } from 'actions/company';
import { EDIT_MODE } from 'constants/common/crud';
import { FROM_ORDER_CONFIRMATION_QUERY_PARAM } from 'constants/order-confirmation';
import { FORM_NAME, FROM_DUPLICATE_QUERY_PARAM } from 'constants/outgoing-invoice';
import { FROM_PROPOSAL_QUERY_PARAM } from 'constants/proposal';
import { InvoiceStatuses } from 'constants/statuses';
import { paymentRemindersEnabledHelper } from 'routes/accesses';
import { withReminderTabs, withWarningAlert } from 'shared/hoc';
import { useAppDispatch, useAppSelector } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { RootState } from 'store';
import useLineItemsControl, { SectionType } from 'components/LineItems/useLineItemControl';

import OutgoingInvoiceCreator from '../OutgoingInvoiceCreator/OutgoingInvoiceCreator';

const OutgoingInvoiceEdit = () => {
  const dispatch = useAppDispatch();
  const formStatus = useAppSelector((state) => state.outgoingInvoice.details.status);

  const {
    manualHandleSelectCategory,
    setLineItemState,
    setShowCategoryChangeConfirmation,
    setShowDifferentCategoryWarning,
    setShowVatIdWarning,
    showDifferentCategoryWarning,
    showCategoryChangeConfirmation,
    defaultCategory,
    isDeprecatedCategory,
    canSubmit,
    showVatIdWarning,
    category,
    taxRates,
    taxRateId,
    defaultVat,
    hasSavedLineItems,
    selectedCategory,
    lineCategories,
    isInitial,
  } = useLineItemsControl({ sectionType: SectionType.outgoingInvoice, formName: FORM_NAME });
  const location = useLocation();

  const isFromDuplicate = location.search.includes(FROM_DUPLICATE_QUERY_PARAM);
  const isFromProposal = location.search.includes(FROM_PROPOSAL_QUERY_PARAM);
  const isFromOrderConfirmation = location.search.includes(FROM_ORDER_CONFIRMATION_QUERY_PARAM);

  /* when editing draft, every field is editable
   * when editing non-draft, only editable fields are editable */
  const isReadonly = (fieldName: string) => {
    if (formStatus === InvoiceStatuses.DRAFT) return false;

    const editableFields = ['bankActivity', 'paid', 'paidByCashDate', 'remark'];
    return !editableFields.includes(fieldName);
  };

  useEffect(() => {
    dispatch(fetchCompany());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OutgoingInvoiceCreator
      editInvoice
      isFromDuplicate={isFromDuplicate}
      isFromProposal={isFromProposal}
      isFromOrderConfirmation={isFromOrderConfirmation}
      isReadonly={isReadonly}
      destroyOnUnmount={false}
      forceDirty
      crudMode={EDIT_MODE}
      hasSavedLineItems={hasSavedLineItems}
      selectedCategory={selectedCategory}
      defaultCategory={defaultCategory}
      isDeprecatedCategory={isDeprecatedCategory}
      handleSelectCategory={manualHandleSelectCategory}
      category={category}
      showCategoryChangeConfirmation={showCategoryChangeConfirmation}
      setShowCategoryChangeConfirmation={setShowCategoryChangeConfirmation}
      taxRateId={taxRateId}
      taxRates={taxRates}
      defaultVat={defaultVat}
      lineCategories={lineCategories}
      isInitial={isInitial}
      canSubmit={canSubmit}
      setLineItemState={setLineItemState}
      showVatIdWarning={showVatIdWarning}
      setShowVatIdWarning={setShowVatIdWarning}
      showDifferentCategoryWarning={showDifferentCategoryWarning}
      setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
      disableTransitionPrevent={false}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  paymentReminders: state.outgoingInvoice.paymentReminders,
  paymentRemindersEnabled: paymentRemindersEnabledHelper(state),
  buttonLabel: t('empty_entry_pages.outgoing_invoice.invoice_warning_box.button_label'),
  content: t('empty_entry_pages.outgoing_invoice.invoice_warning_box.content'),
  dataId: 'OutgoingInvoices:orange-warning-container',
});

const reduxEnhancer = connect(mapStateToProps, null);
const enhance = compose(reduxEnhancer, withWarningAlert, withReminderTabs, withRouter);

export default enhance(OutgoingInvoiceEdit);
