import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import {
  CLEAR_DEFAULT_VALUES,
  FETCH_CLIENT_DEFAULTS_FAILURE,
  FETCH_CLIENT_DEFAULTS_REQUEST,
  FETCH_CLIENT_DEFAULTS_SUCCESS,
  FETCH_CUSTOMER_DEFAULTS_FAILURE,
  FETCH_CUSTOMER_DEFAULTS_REQUEST,
  FETCH_CUSTOMER_DEFAULTS_SUCCESS,
  FORM_SECTION,
  RESET_CLIENT_DEFAULTS_FAILURE,
  RESET_CLIENT_DEFAULTS_REQUEST,
  RESET_CLIENT_DEFAULTS_SUCCESS,
  RESET_CUSTOMER_DEFAULTS_FAILURE,
  RESET_CUSTOMER_DEFAULTS_REQUEST,
  RESET_CUSTOMER_DEFAULTS_SUCCESS,
  UPDATE_CLIENT_DEFAULTS_FAILURE,
  UPDATE_CLIENT_DEFAULTS_REQUEST,
  UPDATE_CLIENT_DEFAULTS_SUCCESS,
  UPDATE_CUSTOMER_DEFAULTS_FAILURE,
  UPDATE_CUSTOMER_DEFAULTS_REQUEST,
  UPDATE_CUSTOMER_DEFAULTS_SUCCESS,
} from 'constants/default-values';
import { getResetSuccess, getUpdateSuccess } from 'notifications/default-values';
import { t } from 'shared/utils';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';
import { ApiAction, ApiResponseRaw, CallApiAction, Dispatch } from 'types/actions';
import { DefaultValues } from 'types/entities/DefaultValues';
import { ApiResponseDefaultValues } from 'types/models/defaultValues';

export type FetchCustomerDefaults = ApiResponseRaw<
  | typeof FETCH_CUSTOMER_DEFAULTS_SUCCESS
  | typeof FETCH_CUSTOMER_DEFAULTS_REQUEST
  | typeof FETCH_CUSTOMER_DEFAULTS_FAILURE,
  ApiResponseDefaultValues
>;
export type FetchClientDefaults = ApiResponseRaw<
  | typeof FETCH_CLIENT_DEFAULTS_SUCCESS
  | typeof FETCH_CLIENT_DEFAULTS_REQUEST
  | typeof FETCH_CLIENT_DEFAULTS_FAILURE,
  ApiResponseDefaultValues
>;
export type UpdateCustomerDefaults = ApiResponseRaw<
  | typeof UPDATE_CUSTOMER_DEFAULTS_SUCCESS
  | typeof UPDATE_CUSTOMER_DEFAULTS_REQUEST
  | typeof UPDATE_CUSTOMER_DEFAULTS_FAILURE,
  ApiResponseDefaultValues
>;
export type UpdateClientDefaults = ApiResponseRaw<
  | typeof UPDATE_CLIENT_DEFAULTS_SUCCESS
  | typeof UPDATE_CLIENT_DEFAULTS_REQUEST
  | typeof UPDATE_CLIENT_DEFAULTS_FAILURE,
  ApiResponseDefaultValues
>;
export type ResetCustomerDefaults = ApiResponseRaw<
  | typeof RESET_CUSTOMER_DEFAULTS_SUCCESS
  | typeof RESET_CUSTOMER_DEFAULTS_REQUEST
  | typeof RESET_CUSTOMER_DEFAULTS_FAILURE,
  ApiResponseDefaultValues
>;
export type ResetClientDefaults = ApiResponseRaw<
  | typeof RESET_CLIENT_DEFAULTS_SUCCESS
  | typeof RESET_CLIENT_DEFAULTS_REQUEST
  | typeof RESET_CLIENT_DEFAULTS_FAILURE,
  ApiResponseDefaultValues
>;

export type DefaultValuesActions =
  | FetchCustomerDefaults
  | FetchClientDefaults
  | UpdateCustomerDefaults
  | UpdateClientDefaults
  | ResetCustomerDefaults
  | ResetClientDefaults;

const getParsedDefaults = (data: Partial<DefaultValues>): Partial<DefaultValues> => {
  if (data.invoiceDiscountGrant === false) {
    return {
      ...data,
      invoiceDiscountPercentage: null,
      invoiceDiscountDate: null,
    };
  }

  return data;
};

export const apiFetchCustomerDefaults = (): CallApiAction<FetchCustomerDefaults> => ({
  [CALL_API]: {
    endpoint: '/me/document_defaults/customer/fetch',
    types: [
      FETCH_CUSTOMER_DEFAULTS_REQUEST,
      FETCH_CUSTOMER_DEFAULTS_SUCCESS,
      FETCH_CUSTOMER_DEFAULTS_FAILURE,
    ],
  },
});

export const apiFetchClientDefaults = (clientId: string): CallApiAction<FetchClientDefaults> => ({
  [CALL_API]: {
    endpoint: '/me/document_defaults/client/fetch',
    params: {
      client_id: clientId,
    },
    types: [
      FETCH_CLIENT_DEFAULTS_REQUEST,
      FETCH_CLIENT_DEFAULTS_SUCCESS,
      FETCH_CLIENT_DEFAULTS_FAILURE,
    ],
  },
});

const apiUpdateCustomerDefaults = (
  data: Partial<DefaultValues> = {}
): CallApiAction<UpdateCustomerDefaults> => ({
  [CALL_API]: {
    data,
    endpoint: '/me/document_defaults/customer/update',
    method: 'PUT',
    types: [
      UPDATE_CUSTOMER_DEFAULTS_REQUEST,
      UPDATE_CUSTOMER_DEFAULTS_SUCCESS,
      UPDATE_CUSTOMER_DEFAULTS_FAILURE,
    ],
  },
});

const apiUpdateClientDefaults = (
  clientId: string,
  data: Partial<DefaultValues> = {}
): CallApiAction<UpdateClientDefaults> => ({
  [CALL_API]: {
    data: {
      client_id: clientId,
      ...data,
    },
    endpoint: '/me/document_defaults/client/update',
    method: 'PUT',
    types: [
      UPDATE_CLIENT_DEFAULTS_REQUEST,
      UPDATE_CLIENT_DEFAULTS_SUCCESS,
      UPDATE_CLIENT_DEFAULTS_FAILURE,
    ],
  },
});

const apiResetCustomerDefaults = (section: FORM_SECTION): CallApiAction<ResetClientDefaults> => ({
  [CALL_API]: {
    data: {
      section,
    },
    endpoint: '/me/document_defaults/customer/reset',
    method: 'PUT',
    types: [
      RESET_CUSTOMER_DEFAULTS_REQUEST,
      RESET_CUSTOMER_DEFAULTS_SUCCESS,
      RESET_CUSTOMER_DEFAULTS_FAILURE,
    ],
  },
});

const apiResetClientDefaults = (
  clientId: string,
  section: FORM_SECTION
): CallApiAction<ResetClientDefaults> => ({
  [CALL_API]: {
    data: {
      client_id: clientId,
      section,
    },
    endpoint: '/me/document_defaults/client/reset',
    method: 'PUT',
    types: [
      RESET_CLIENT_DEFAULTS_REQUEST,
      RESET_CLIENT_DEFAULTS_SUCCESS,
      RESET_CLIENT_DEFAULTS_FAILURE,
    ],
  },
});

export const fetchCustomerDefaults = (): ApiAction<FetchCustomerDefaults> => (dispatch: Dispatch) =>
  dispatch(apiFetchCustomerDefaults()).catch(apiErrorHandler);

export const fetchClientDefaults =
  (clientId: string): ApiAction<FetchClientDefaults> =>
  (dispatch: Dispatch) =>
    dispatch(apiFetchClientDefaults(clientId)).catch(apiErrorHandler);

export const updateCustomerDefaults =
  (data: Partial<DefaultValues>, section: FORM_SECTION): ApiAction<UpdateCustomerDefaults> =>
  (dispatch: Dispatch) =>
    bindServerValidation(apiUpdateCustomerDefaults(getParsedDefaults(data)), dispatch).then(() =>
      dispatch(
        showNotification(getUpdateSuccess(t(`invoice_templates.defaults_section_${section}`)))
      )
    );

export const updateClientDefaults =
  (
    clientId: string,
    data: Partial<DefaultValues>,
    section: FORM_SECTION
  ): ApiAction<UpdateClientDefaults> =>
  (dispatch: Dispatch) =>
    bindServerValidation(apiUpdateClientDefaults(clientId, getParsedDefaults(data)), dispatch).then(
      () =>
        dispatch(
          showNotification(getUpdateSuccess(t(`invoice_templates.defaults_section_${section}`)))
        )
    );

export const resetCustomerDefaults =
  (section: FORM_SECTION): ApiAction<ResetCustomerDefaults> =>
  (dispatch: Dispatch) =>
    bindServerValidation(apiResetCustomerDefaults(section), dispatch).then(() =>
      dispatch(
        showNotification(getResetSuccess(t(`invoice_templates.defaults_section_${section}`)))
      )
    );

export const resetClientDefaults =
  (clientId: string, section: FORM_SECTION): ApiAction<ResetClientDefaults> =>
  (dispatch: Dispatch) =>
    bindServerValidation(apiResetClientDefaults(clientId, section), dispatch).then(() =>
      dispatch(
        showNotification(getResetSuccess(t(`invoice_templates.defaults_section_${section}`)))
      )
    );

export const clearDefaultValues = () => ({
  type: CLEAR_DEFAULT_VALUES,
});
