import React from 'react';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';

import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import DownshiftSelect from 'components/Form/SelectField/DownshiftSelect';
import TextareaField from 'components/Form/TextareaField/TextareaField';
import TextField from 'components/Form/TextField';
import Modal from 'components/Modal';
import WithCurrencySign from 'components/WithCurrencySign/WithCurrencySign';
import Tooltip from 'redesign/components/molecules/Tooltip/Tooltip';

import styles from './style.module.css';

const LabelAssignmentModal = ({
  onSelect,
  isOpen,
  onClose,
  selectOptions,
  labelDescription,
  optionRenderer,
  onBlur,
  onAmountInputChange,
  amount,
  onNoteInputChange,
  note,
  createLabel,
  disabled,
  selectedOption,
  isMdUp,
}) => (
  <Modal isOpen={isOpen} onRequestClose={onClose}>
    <div className={styles.header}>
      <span className={styles.text}>{t('bank_transfers.label_assignment.modal.header')}</span>
      <button
        data-id="BankTransferAssignment:button-close-label-modal"
        className={styles.cancel}
        onClick={onClose}
      />
    </div>
    <div className={styles.modalBody}>
      <div className={styles.inputColumn}>
        <DownshiftSelect
          initialSelectedItem={selectedOption}
          defaultIcon
          options={selectOptions}
          label={t('bank_transfers.label_assignment.modal.category_of_assignment')}
          onSelect={onSelect}
          inputDisabled
          hideMenuScroll
          optionRenderer={optionRenderer}
          onBlur={onBlur}
          dataIds={{ input: 'BankTransferAssignment:label-modal-category-dropdown' }}
        />
        {!isMdUp && (
          <div className={styles.infoBox}>
            <div className={styles.icon}>
              <Tooltip />
            </div>
            {labelDescription}
          </div>
        )}
        <WithCurrencySign>
          <TextField
            className={styles.amount}
            name="amount"
            onChange={onAmountInputChange}
            value={amount}
            label={t('bank_transfers.label_assignment.modal.amount')}
            dataId="BankTransferAssignment:input-amount-label-modal"
          />
        </WithCurrencySign>
        <TextareaField
          name="note"
          onChange={onNoteInputChange}
          value={note}
          placeholder={t('bank_transfers.label_assignment.modal.note')}
          dataId="BankTransferAssignment:input-notes-label-modal"
        />
      </div>
      {isMdUp && (
        <div className={styles.infoBox} data-id="BankTransferAssignment:label-modal-description">
          <div className={styles.icon}>
            <Tooltip />
          </div>
          {labelDescription}
        </div>
      )}
    </div>
    <div className={styles.footerSection}>
      <ActionButton
        disabled={disabled}
        appearance="primary"
        onClick={createLabel}
        label={t('bank_transfers.label_assignment.modal.assign')}
        dataId="BankTransferAssignment:button-accept-label-modal"
      />
    </div>
  </Modal>
);

LabelAssignmentModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onSelect: func.isRequired,
  selectOptions: arrayOf(shape({})),
  labelDescription: oneOfType([string, number]),
  optionRenderer: func.isRequired,
  onAmountInputChange: func.isRequired,
  onBlur: func.isRequired,
  amount: oneOfType([number, string]).isRequired,
  onNoteInputChange: func.isRequired,
  note: string,
  createLabel: func.isRequired,
  disabled: bool.isRequired,
  isMdUp: bool.isRequired,
  selectedOption: shape({}),
};

export default LabelAssignmentModal;
