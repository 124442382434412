import { replace } from 'connected-react-router';

import { paginationParams, sortingParams } from 'actions/helpers/table';
import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import { CLEAR_FILTERS, SET_QUERY_PARAM } from 'constants/common/filters';
import {
  CLEANUP_BEFORE_REDIRECT,
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DE_SUCCESS,
  DE_SUPPLIER_CREATION,
  DE_SUPPLIER_MANAGEMENT,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SORT,
  INDEX_SUCCESS,
  NAMESPACE,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/suppliers';
import { updateFailure, updateSuccess } from 'notifications/suppliers';
import paths from 'routes/paths';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { piwikHelpers } from 'shared/utils/piwik';
import { bindServerValidation } from 'shared/utils/server-validation';

export const apiIndex = (pagination = {}, sorting = {}, filters = {}) => ({
  [CALL_API]: {
    params: {
      ...paginationParams(pagination),
      ...sortingParams(sorting),
      filters,
    },
    endpoint: '/me/suppliers',
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
  },
});

const apiFetchSupplier = (supplier) => ({
  [CALL_API]: {
    endpoint: `/me/suppliers/${supplier}`,
    method: 'GET',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiCreateSupplier = (data) => ({
  [CALL_API]: {
    data,
    endpoint: '/me/suppliers',
    method: 'POST',
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const apiUpdateSupplier = (id, data) => ({
  [CALL_API]: {
    data,
    endpoint: `/me/suppliers/${id}`,
    method: 'PUT',
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

const apiDelete = (supplier) => ({
  [CALL_API]: {
    endpoint: `/me/suppliers/${supplier.id}`,
    method: 'DELETE',
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const cleanupBeforeRedirect = () => ({ type: CLEANUP_BEFORE_REDIRECT });

export const trackCreatingSupplier = () => {
  piwikHelpers.trackEvent(DE_SUPPLIER_MANAGEMENT, DE_SUPPLIER_CREATION, DE_SUCCESS);
};

export const sortSuppliers = (column) => (dispatch) =>
  dispatch({
    type: INDEX_SORT,
    column,
  });

export const fetchSuppliers =
  (pagination = { page: 1, perPage: 10000, pagination_resource: '' }, sorting, filters) =>
  (dispatch) =>
    dispatch(apiIndex(pagination, sorting, filters)).catch(apiErrorHandler);

export const deleteSupplier = (supplier) => (dispatch) =>
  dispatch(apiDelete(supplier)).catch(apiErrorHandler);

export const fetchSupplier =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchSupplier(...args)).catch((err) => {
      apiErrorHandler(err);
      dispatch(replace(paths.suppliers));
    });

export const createSupplier = (data) => (dispatch) =>
  bindServerValidation(apiCreateSupplier(data), dispatch).then(() => {
    trackCreatingSupplier();
    dispatch(cleanupBeforeRedirect());
    dispatch(replace(paths.suppliers));
  });

export const updateSupplier = (id, data) => (dispatch) =>
  bindServerValidation(apiUpdateSupplier(id, data), dispatch).then(() => {
    dispatch(replace(paths.suppliers));
  });

export const dynamicUpdateSupplier = (id, data) => (dispatch) =>
  dispatch(apiUpdateSupplier(id, data))
    .then(() => {
      dispatch(showNotification(updateSuccess));
      dispatch(fetchSuppliers());
    })
    .catch(() => {
      dispatch(showNotification(updateFailure));
    });

export const setQueryParam = (param) => (dispatch) => (value) =>
  dispatch({
    type: SET_QUERY_PARAM,
    name: NAMESPACE,
    payload: {
      param,
      value,
    },
  });

export const clearFilters = ({ clearStatus = false } = {}) => ({
  type: CLEAR_FILTERS,
  name: NAMESPACE,
  payload: {
    clearStatus,
  },
});
