import { SET_PIWIK_IFRAME_SRC } from 'constants/piwik';

export interface PiwikState {
  src: string;
}

interface Action<T> {
  type: string;
  payload: T;
}

const initialState: PiwikState = {
  src: '',
};

export default (state: PiwikState = initialState, action: Action<string>): PiwikState => {
  switch (action.type) {
    case SET_PIWIK_IFRAME_SRC:
      return { ...state, src: action.payload };
    default:
      return state;
  }
};
