import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(18, 18, 18, 0.6);
  z-index: 50007;
`;

export default ModalOverlay;
