import { CALL_API } from 'constants/api';
import {
  FETCH_SUMS_FAILURE,
  FETCH_SUMS_REQUEST,
  FETCH_SUMS_SUCCESS,
} from 'constants/order-confirmation';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiFetchOrderConfirmationSums = (invoiceId) => ({
  [CALL_API]: {
    endpoint: `/me/order_confirmations/${invoiceId}`,
    types: [FETCH_SUMS_REQUEST, FETCH_SUMS_SUCCESS, FETCH_SUMS_FAILURE],
  },
});

export const fetchOrderConfirmationSums =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchOrderConfirmationSums(...args)).catch(apiErrorHandler);
