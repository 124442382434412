import React, { useCallback, useEffect, useState } from 'react';
import Recaptcha from 'react-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import cx from 'classnames';
import { isEmpty, noop } from 'lodash';
import { Field, WrappedFieldProps } from 'redux-form';

import { changeGrecaptchaResponse as changeGrecaptchaResponseAction } from 'actions/grecaptcha';
import { register, registerVrso, toggleDisclaimer } from 'actions/registration';
import paths from 'routes/paths';
import { persistedCustomerSelector } from 'selectors/reqistrationRequest';
import { t } from 'shared/utils';
import { isDevelopment, isTest } from 'shared/utils/environment';
import { existChecker } from 'shared/utils/form-checking';
import getGrecaptchaKey from 'shared/utils/grecaptcha';
import isPressedEnter from 'shared/utils/keyboard-events';
import { RootState } from 'store';
import { FieldsGroup, PasswordField, SubmitButton, TextField } from 'components/Form';
import FormField from 'components/Form/FormField';

import PasswordValidityPopover from './PasswordValidityPopover/PasswordValidityPopover';
import ProductPropositionsCheckbox from './ProductPropositionsCheckbox/ProductPropositionsCheckbox';
import TermsOfUseCheckbox from './TermsOfUseCheckbox/TermsOfUseCheckbox';
import type { FormProps, FormValues, GoogleCaptchaResponse, Popover } from './types';

import styles from './RegistrationForm.module.css';

const checker = ({ meta }: WrappedFieldProps) => existChecker(meta);

const isCaptchaEnabled = !(isTest || isDevelopment);

const Form = ({ handleSubmit, disclaimerTitle, isVrso }: FormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isRecaptchaVisible, setIsRecaptchaVisible] = useState(isCaptchaEnabled);
  const grecaptchaResponse = useSelector((state: RootState) => state.grecaptcha.grecaptchaResponse);
  const submitting = useSelector((state: any) => state.auth.isFetching);
  const persistedCustomer = useSelector(persistedCustomerSelector);
  const handleToggleDisclaimer = useCallback(() => dispatch(toggleDisclaimer()), [dispatch]);
  const changeGrecaptchaResponse = useCallback(
    (response: GoogleCaptchaResponse) => dispatch(changeGrecaptchaResponseAction(response)),
    [dispatch]
  );
  const selectedCompanyFromStore = useSelector((state) => state.registrationRequest.company);

  useEffect(() => {
    if (
      Object.keys(selectedCompanyFromStore).length === 0 &&
      selectedCompanyFromStore.constructor === Object &&
      !isVrso
    ) {
      history.push(paths.companySelectRegistration);
    }
  }, [selectedCompanyFromStore, history]);

  if (isEmpty(persistedCustomer) && !isVrso) {
    return <Redirect to={paths.requestRegistration} />;
  }

  /**
   * Every time request fails, it is required to force user to enter recaptcha again.
   * To make it happen we have to reset visibility of it by setting a state to false
   * and true again.
   *
   * @param {*} errors errors that will be passed to redux-form
   */
  const reloadRecaptcha = () => {
    setIsRecaptchaVisible(false);
    setIsRecaptchaVisible(true);
  };

  const recaptchaSitekey = getGrecaptchaKey() || '';

  const onSubmit = (formValues: FormValues) => {
    const selectedCompany = selectedCompanyFromStore;
    console.log(selectedCompany);

    formValues = {
      ...formValues,
      ...selectedCompany,
    };

    console.log(formValues);

    const registerAction = isVrso ? registerVrso : register;

    // @ts-ignore: Registration's actions have to rewritten to ts
    return dispatch(registerAction({ formValues, grecaptchaResponse })).catch((errors) => {
      if (isCaptchaEnabled) reloadRecaptcha();

      throw errors;
    });
  };

  return (
    <form className={styles.main} onSubmit={handleSubmit(onSubmit)}>
      {isVrso ? (
        <>
          <FieldsGroup className={styles.contractNumber}>
            <p className={styles.contractNumberDescription}>
              {t('registration.form.contract_number_description')}
            </p>
            <FormField
              name="contractNumber"
              dataId="input-contract-nr"
              placeholder={t('registration.form.contract_number')}
              component={TextField}
              required
            />
          </FieldsGroup>
          <div className={styles.subtitleWithContract}>
            {t('registration.form.subtitle.line_1')}
          </div>
        </>
      ) : (
        <div className={styles.subtitle}>{t('registration.form.subtitle.line_1')}</div>
      )}
      <PasswordValidityPopover>
        {({ showPopover, hidePopover }: Popover) => (
          <PasswordField
            name="password"
            placeholder={`${t('registration.form.password')}`}
            onFocus={showPopover}
            onBlur={hidePopover}
            dataId="input-password"
            checker={checker}
            required
          />
        )}
      </PasswordValidityPopover>
      <FieldsGroup className={styles.checkboxes}>
        <TermsOfUseCheckbox />
        <p className={styles.contentDescription}>
          {t('registration.form.privacy_content_description')}
          <span
            className={styles.modalButton}
            onKeyPress={isPressedEnter(handleToggleDisclaimer)}
            onClick={handleToggleDisclaimer}
            role="button"
          >
            {` ${disclaimerTitle}`}
          </span>
        </p>
        <ProductPropositionsCheckbox />
      </FieldsGroup>
      {!isVrso && (
        <FieldsGroup className={styles.recaptcha}>
          {isRecaptchaVisible && (
            <div data-id="Registration:input-recaptcha">
              <Recaptcha
                sitekey={recaptchaSitekey}
                verifyCallback={changeGrecaptchaResponse}
                onloadCallback={noop}
                render="explicit"
                hl="de"
              />
            </div>
          )}
        </FieldsGroup>
      )}
      <FieldsGroup className={cx(styles.fieldsGroup, styles.fieldsGroupSubmit)}>
        <SubmitButton
          className={styles.submitButton}
          name="submit"
          value={t('registration.form.submit')}
          dataId="button-submit"
          active={!submitting}
        />
      </FieldsGroup>
      <Field component="input" hidden name="email" />
      <Field component="input" hidden name="token" />
      {isVrso && <p className={styles.disclaimer}>{t('registration.form.disclaimer')}</p>}
    </form>
  );
};

export default Form;
