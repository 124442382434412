import orderBy from 'lodash/orderBy';

import { UnitActions } from 'actions/units';
import { CREATE_SUCCESS, INDEX_SUCCESS } from 'constants/units';
import { Unit, Units } from 'types/models/units';

const defaultState: Units = {
  predefinedUnits: [],
  userUnits: [],
  allUnits: [],
};

const flattenUnits = (predefinedUnits: string[], userUnits: Unit[]): string[] =>
  orderBy([...predefinedUnits, ...userUnits.map((unit) => unit.name)], (s) => s.toLowerCase());

export default (state: Units = defaultState, action: UnitActions): Units => {
  switch (action.type) {
    case INDEX_SUCCESS:
      const { predefinedUnits = [], userUnits = [] } = action.response.data;
      const mappedUserUnits = userUnits.map((unit) => unit.attributes);
      return {
        predefinedUnits,
        userUnits: mappedUserUnits,
        allUnits: flattenUnits(predefinedUnits, mappedUserUnits),
      };
    case CREATE_SUCCESS:
      const updatedUserUnits = [...state.userUnits, action.response.data.attributes];
      return {
        ...state,
        userUnits: updatedUserUnits,
        allUnits: flattenUnits(state.predefinedUnits, updatedUserUnits),
      };
    default:
      return state;
  }
};
