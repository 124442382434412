import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Unsubscribe from './Unsubscribe/Unsubscribe';

const Routes = () => {
  const match = useRouteMatch();

  if (!match) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={`${match.path}/unsubscribe`}>
        <Unsubscribe />
      </Route>
    </Switch>
  );
};

export default Routes;
