import React, { SVGAttributes } from 'react';

const IconSettings = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 170 170" {...props}>
    <defs>
      <filter
        y="-8.7%"
        width="122.7%"
        height="122.7%"
        filterUnits="objectBoundingBox"
        id="3_svg__a"
      >
        <feOffset dx={2} dy={4} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-23.8%"
        y="-15.2%"
        width="147.6%"
        height="134.3%"
        filterUnits="objectBoundingBox"
        id="3_svg__c"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={5.5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <filter id="3_svg__e">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.098039 0 0 0 0 0.509804 0 0 0 0 0.156863 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="3_svg__f">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.098039 0 0 0 0 0.509804 0 0 0 0 0.156863 0 0 0 1.000000 0"
        />
      </filter>
      <circle id="3_svg__b" cx={75} cy={75} r={75} />
      <path id="3_svg__d" d="M42 27h73.5v102H42z" />
    </defs>
    <g transform="translate(8 6)" fill="none" fillRule="evenodd">
      <use fill="#000" filter="url(#3_svg__a)" xlinkHref="#3_svg__b" />
      <use fill="#FFF" xlinkHref="#3_svg__b" />
      <use fill="#000" filter="url(#3_svg__c)" xlinkHref="#3_svg__d" />
      <use fill="#FFF" xlinkHref="#3_svg__d" />
      <rect
        stroke="#979797"
        strokeWidth={0.75}
        x={50.25}
        y={45.75}
        width={12.75}
        height={12.75}
        rx={2.25}
      />
      <path
        stroke="#979797"
        strokeWidth={2}
        strokeLinecap="round"
        d="M70.125 50.25h30m-30 20.625h30m-30 23.25h30"
      />
      <path
        d="M9.822 18.513l-6.21-5.59a.773.773 0 00-1.036 1.15l6.814 6.132a.773.773 0 001.116-.085L22.77 5.13a.773.773 0 00-1.197-.98L9.823 18.514z"
        fill="#FFF"
        fillRule="nonzero"
        transform="translate(47.25 34.5)"
        filter="url(#3_svg__e)"
      />
      <path
        d="M9.822 18.513l-6.21-5.59a.773.773 0 00-1.036 1.15l6.814 6.132a.773.773 0 001.116-.085L22.77 5.13a.773.773 0 00-1.197-.98L9.823 18.514z"
        fill="#FFF"
        fillRule="nonzero"
        transform="translate(47.25 58.5)"
        filter="url(#3_svg__f)"
      />
      <path
        stroke="#979797"
        strokeWidth={2}
        strokeLinecap="round"
        d="M70.125 56.25h15m-15 20.625h15m-15 23.25h15"
      />
      <rect
        stroke="#979797"
        strokeWidth={0.75}
        x={50.25}
        y={67.5}
        width={12.75}
        height={12.75}
        rx={2.25}
      />
      <rect
        stroke="#979797"
        strokeWidth={0.75}
        x={50.25}
        y={90.75}
        width={12.75}
        height={12.75}
        rx={2.25}
      />
      <path d="M91 98h35v35H91z" />
      <path
        d="M119.335 116.93c.059-.468.103-.934.103-1.43 0-.496-.044-.962-.103-1.43l3.078-2.405a.736.736 0 00.174-.934l-2.916-5.046c-.175-.32-.569-.437-.89-.32l-3.631 1.458a10.655 10.655 0 00-2.465-1.43l-.554-3.864a.711.711 0 00-.714-.612h-5.834a.711.711 0 00-.714.612l-.554 3.865c-.89.364-1.707.86-2.465 1.429l-3.631-1.458a.711.711 0 00-.89.32l-2.916 5.046a.719.719 0 00.175.934l3.077 2.406c-.059.467-.102.948-.102 1.429 0 .481.043.963.102 1.43l-3.077 2.405a.736.736 0 00-.175.934l2.916 5.046c.175.32.569.437.89.32l3.631-1.458a10.655 10.655 0 002.465 1.43l.554 3.864a.71.71 0 00.714.612h5.834a.71.71 0 00.714-.612l.554-3.865c.89-.364 1.707-.86 2.465-1.429l3.631 1.458c.336.132.715 0 .89-.32l2.916-5.046a.736.736 0 00-.175-.934l-3.077-2.406zm-10.835 3.674a5.11 5.11 0 01-5.104-5.104 5.11 5.11 0 015.104-5.104 5.11 5.11 0 015.104 5.104 5.11 5.11 0 01-5.104 5.104z"
        fill="#0066B3"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default IconSettings;
