import React, { memo } from 'react';
import { matchPath } from 'react-router-dom';

import paths from 'routes/paths';
import { t } from 'shared/utils';
import IconBank from 'redesign/components/atoms/Icons/Bank';

import { trackEventHandler } from '../utils';
import { Link, MenuItem } from './Shared.styled';

type AccountsProps = {
  pathname: string;
};

const Accounts = ({ pathname }: AccountsProps) => {
  const isBankTransfers = !!matchPath(pathname, paths.bankTransfers);

  return (
    <MenuItem $isActive={isBankTransfers}>
      <Link
        $level={1}
        to={paths.bankTransfers}
        $isActive={isBankTransfers}
        data-id="bank-transfers"
        onClick={trackEventHandler('bank-tranfers')}
      >
        <IconBank />
        {t('redesign.organisms.navigation.accounts')}
      </Link>
    </MenuItem>
  );
};

export default memo(Accounts);
