import React from 'react';
import { TableInstance } from 'react-table';

import BasePagination from 'components/v2/Pagination/Pagination';

import { Pagination } from './TablePagination.styled';

export type TablePaginationProps<T extends object> = {
  instance: TableInstance<T>;
  isLoading?: boolean;
};

const TablePagination = <T extends object>({ instance, isLoading }: TablePaginationProps<T>) => {
  const {
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = instance;

  return (
    <Pagination
      as={BasePagination}
      canNextPage={canNextPage}
      canPreviousPage={canPreviousPage}
      pageIndex={pageIndex}
      onPreviousPage={previousPage}
      onNextPage={nextPage}
      onPageChange={gotoPage}
      onChangePageSize={setPageSize}
      pageSize={pageSize}
      totalPages={pageCount}
      isLoading={isLoading}
    />
  );
};

export default TablePagination;
