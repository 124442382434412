import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

import { camelizeKeysDeep } from 'shared/utils/entity-mapper';
import DownshiftSelect from 'components/Form/SelectField/DownshiftSelect';

import styles from './TanSchemeSelectField.module.css';

const TanSchemeSelectField = ({ tanSchemes, onSelect, dataIds = {}, ...props }) => {
  const options = tanSchemes.map((tanScheme) => {
    const { mediumName, id } = camelizeKeysDeep(tanScheme) || {};

    return {
      name: mediumName,
      id,
      label: mediumName,
      value: id,
      dataId: `${dataIds.optionPrefix}${id}`,
    };
  });

  return (
    <div data-id={dataIds.container} className={styles.wrapper}>
      <DownshiftSelect
        {...props}
        defaultIcon
        menuClassName={styles.menu}
        onSelect={onSelect}
        options={options}
      />
    </div>
  );
};

TanSchemeSelectField.propTypes = {
  onSelect: func.isRequired,
  tanSchemes: arrayOf(
    shape({
      type: string,
      id: string,
      mediumName: string,
    })
  ),
  dataIds: shape({
    container: string,
    optionPrefix: string,
  }),
};

export default TanSchemeSelectField;
