import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as reduxPush } from 'connected-react-router';
import { flow, get, property } from 'lodash';
import { bool, func } from 'prop-types';
import { reduxForm } from 'redux-form';

import { addEmailToReport, getReport } from 'actions/datev';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import { ButtonAppearances } from 'components/Button';
import Card from 'components/Card';
import I18n from 'components/I18n';
import LoadingIcon from 'components/LoadingIcon';
import { InfoModal } from 'components/Modal';

import DatevPreview from '../shared/DatevPreview/DatevPreview';
import SettingsSection from '../shared/SettingsSection';
import ReportsSection from './ReportsSection';

import creatorStyles from '../Creator/Creator.module.css';
import styles from './Editor.module.css';

class DatevEditor extends Component {
  static propTypes = {
    change: func,
    submitting: bool,
    getReport: func,
    push: func,
    handleSubmit: func,
    submitSucceeded: bool,
    addEmailToReport: func,
  };

  state = {
    reports: [],
    isLoading: true,
  };

  componentDidMount() {
    const reportId = get(this.props, 'match.params.id');

    this.props.getReport(reportId).then(({ response }) => {
      const values = get(response, 'data.attributes');
      const reports = get(response, 'included', []).map(property('attributes'));

      this.props.change('startDate', values.startDate);
      this.props.change('endDate', values.endDate);

      this.setState({ isLoading: false, reports });
      const latestReport = reports.slice(-1).pop();
      if (latestReport) {
        this.initializeFormFields(latestReport);
      }
    });
  }

  onSubmit = (data) => {
    piwikHelpers.trackGoal(10);
    const reportId = get(this.props, 'match.params.id');
    return this.props.addEmailToReport(reportId, data);
  };

  initializeFormFields = ({ password, email }) => {
    const { change } = this.props;
    change('password', password);
    change('email', email);
  };

  render() {
    if (this.state.isLoading) return <LoadingIcon />;

    const { submitting, push, handleSubmit, submitSucceeded } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={styles.section}>
          <Card className={creatorStyles.main}>
            <Card.Header>
              <I18n t="datev.creator.header" className={creatorStyles.headerLeft} />
            </Card.Header>
            <Card.Body className={cx(styles.body, creatorStyles.body)}>
              <ReportsSection reports={this.state.reports} />
              <div className={creatorStyles.description}>
                <I18n t="datev.creator.description" />
              </div>
              <SettingsSection
                disabled={submitting}
                label={t('datev.creator.labels.export_edit')}
                readonly={['dateRange']}
                isEditMode
              />
              <DatevPreview />
            </Card.Body>
          </Card>
          <InfoModal
            isOpen={submitSucceeded}
            onClose={() => push(paths.datev)}
            dataIds={{
              acceptButton: 'confirmation',
            }}
          >
            {t('datev.creator.messages.export_success')}
          </InfoModal>
        </div>
        <ActionPanel className={styles.creatorActionsSection} wrapped>
          <ActionButton
            appearance={ButtonAppearances.outlined}
            onClick={() => push(paths.datev)}
            label={t('forms.actions.back_to_index')}
          />
        </ActionPanel>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({ initialValues: get(state, 'form.DatevCreator.initial') });

const mapDispatchToProps = {
  addEmailToReport,
  push: reduxPush,
  getReport,
};

export default flow(
  reduxForm({
    form: 'DatevCreator',
    enableReinitialize: true,
    persistentSubmitErrors: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(DatevEditor);
