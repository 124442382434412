import { find, indexOf } from 'lodash';
import { combineReducers } from 'redux';

import {
  FETCH_SUCCESS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SORT,
  INDEX_SUCCESS,
  NAMESPACE,
} from 'constants/clients';
import filters from 'reducers/common/filters';
import { getPaginationReducer } from 'reducers/pagination';
import { createFilteredReducer } from 'shared/utils';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case INDEX_REQUEST:
      return true;
    case INDEX_SUCCESS:
    case INDEX_FAILURE:
      return false;
    default:
      return state;
  }
};

export const mapClient = ({ attributes, meta, id }) => {
  const prefix =
    attributes['company-name'] ||
    attributes['last-name'] ||
    attributes.companyName ||
    attributes.lastName;
  const postfix = attributes.city ? `, ${attributes.city}` : '';

  return {
    id,
    ...attributes,
    meta,
    name: `${prefix}${postfix}`,
  };
};

const data = (state = [], action) => {
  switch (action.type) {
    case INDEX_SUCCESS: {
      const { response } = action;
      return response.data.map(mapClient);
    }
    case FETCH_SUCCESS: {
      const { response } = action;
      const newClientData = { ...response.data.attributes };

      const index = indexOf(state, find(state, { id: newClientData.id }));

      if (index > -1) {
        return state.map((client) => (client.id === newClientData.id ? newClientData : client));
      }

      return [newClientData, ...state];
    }
    default:
      return state;
  }
};

const pagination = getPaginationReducer(INDEX_SUCCESS);

const defaultSortingState = {
  column: 'company_name',
  direction: 'DESC',
};

export const sorting = (state = defaultSortingState, action) => {
  switch (action.type) {
    case INDEX_SORT: {
      let direction = 'ASC';

      if (state.column === action.column && state.direction === 'ASC') {
        direction = 'DESC';
      }

      return {
        column: action.column,
        direction,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  data,
  pagination,
  sorting,
  filters: createFilteredReducer(filters, (action) => action.name === NAMESPACE),
});
