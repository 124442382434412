import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import BaseBadge from 'redesign/components/atoms/Badge/Badge';
import IconArrowDown from 'redesign/components/atoms/Icons/ArrowDown';
import { media } from 'redesign/styles/breakpoints';

type LinkProps = {
  $level: number;
  $isActive?: boolean;
  $withIcon?: boolean;
};

const Level1 = css`
  min-height: 56px;
  display: grid;
  padding-left: ${({ theme }) => theme.space['4']};
  padding-right: ${({ theme }) => theme.space['4']};
  grid-template-columns: 24px auto 24px;
  align-items: center;
  grid-column-gap: ${({ theme }) => theme.space['4']};
`;

const Level2And3 = css<LinkProps>`
  min-height: 40px;
  display: grid;
  padding-left: ${({ $level }) => ($level === 2 ? '56px' : '72px')};
  padding-right: ${({ theme }) => theme.space['4']};
  grid-template-columns: ${({ $withIcon }) => $withIcon && 'auto 24px'};
  align-items: center;
  grid-column-gap: ${({ theme }) => theme.space['2']};
`;

export const Link = styled(RouterLink)<LinkProps>`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ $isActive }) => $isActive && 'bold'};
  user-select: none;
  white-space: nowrap;
  position: relative;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
  }

  svg {
    width: 24px;
  }

  ${({ $level }) => ($level === 1 ? Level1 : Level2And3)};
`;

type BadgeProps = {
  $isShifted?: boolean;
};

export const Badge = styled(BaseBadge)<BadgeProps>`
  ${({ $isShifted }) =>
    $isShifted &&
    css`
      ${media.greaterThan('lg')`
        position: absolute;
        right: ${({ theme }) => theme.space['2']};
        bottom: ${({ theme }) => theme.space['2']};
      `}
    `}
`;

type MenuItemProps = {
  $isActive?: boolean;
  $isOpen?: boolean;
};

export const MenuItem = styled.li<MenuItemProps>`
  background-color: ${({ $isActive, theme }) => $isActive && theme.colors.vrblue['100']};

  > ul {
    display: ${({ $isOpen }) => !$isOpen && 'none'};
  }

  ${Link} {
    &:hover {
      background-color: ${({ $isActive, theme }) =>
        $isActive ? theme.colors.vrblue['075'] : theme.colors.nightblue['075']};
    }

    &:active {
      background-color: ${({ $isActive, theme }) =>
        $isActive ? theme.colors.vrblue['050'] : theme.colors.nightblue['050']};
    }
  }
`;

type ArrowProps = {
  $isOpen: boolean;
};

export const Arrow = styled(IconArrowDown)<ArrowProps>`
  transition: transform 0.3s ease;

  ${({ $isOpen }) => $isOpen && 'transform: rotate(180deg)'};
`;
