import React, { ChangeEvent, SyntheticEvent, useState } from 'react';

import FieldWrapper from 'redesign/components/molecules/FieldWrapper/FieldWrapper';
import InputField from 'redesign/components/molecules/InputField/InputField';

import { PasswordClosedIcon, PasswordOpenIcon } from './PasswordField.styled';

export type PasswordFieldProps = {
  label: string;
  name?: string;
  value?: string;
  placeholder?: string;
  dataId?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: SyntheticEvent<Element, Event>) => void;
};

const PasswordField = ({ onChange, label, dataId, ...props }: PasswordFieldProps) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const renderIcon = isPasswordShown ? (
    <PasswordOpenIcon onClick={() => setIsPasswordShown(!isPasswordShown)} />
  ) : (
    <PasswordClosedIcon onClick={() => setIsPasswordShown(!isPasswordShown)} />
  );

  return (
    <FieldWrapper
      type={isPasswordShown ? 'text' : 'password'}
      label={label}
      field={InputField}
      onChange={onChange}
      icon={renderIcon}
      dataId={dataId}
      {...props}
    />
  );
};

export default PasswordField;
