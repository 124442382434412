import { addDays, format } from 'date-fns';

import { DATE_FORMAT_UNICODE } from 'constants/datetime';
import { NO_CURRENT_PLAN_END_DATE } from 'constants/payment-plans';

export const isNextPlanPresent = (trialEndDate, currentPlanEndDate) =>
  trialEndDate !== NO_CURRENT_PLAN_END_DATE || currentPlanEndDate !== NO_CURRENT_PLAN_END_DATE;

export const nextPlanDate = (trialEndDate, currentPlanEndDate) => {
  if ([trialEndDate, currentPlanEndDate].every((date) => date === NO_CURRENT_PLAN_END_DATE)) {
    return {
      date: undefined,
      nextPlanValidFrom: undefined,
    };
  }

  const date = trialEndDate === NO_CURRENT_PLAN_END_DATE ? currentPlanEndDate : trialEndDate;
  const addDay = addDays(new Date(date), 1);
  const nextPlanValidFrom = format(new Date(addDay), DATE_FORMAT_UNICODE);

  return { date, nextPlanValidFrom };
};

export const getDeletionDate = (deleteAt) => {
  if (deleteAt === null) return;

  return format(new Date(deleteAt), DATE_FORMAT_UNICODE);
};
