import React, { useState } from 'react';
import { omit } from 'lodash';

import {
  CATEGORY_PRODUCT_CATALOG,
  CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG,
  CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG_CANCEL,
  CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG_CHANGE,
  CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG_CREATE_NEW,
  CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG_SUCCESS,
} from 'constants/piwik';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import Button from 'components/Button';

import { AddProductModal } from '../AddProductModal/AddProductModal';
import { TableCell } from '../TableCell/TableCell';
import { TableSubRow } from '../TableSubRow/TableSubRow';

import styles from './AddProductButton.module.css';

const trackSaveInCatalog = () =>
  piwikHelpers.trackEvent(CATEGORY_PRODUCT_CATALOG, CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG);
const trackSaveInCatalogCancel = () =>
  piwikHelpers.trackEvent(
    CATEGORY_PRODUCT_CATALOG,
    CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG_CANCEL
  );
const trackSaveInCatalogSuccess = () =>
  piwikHelpers.trackEvent(
    CATEGORY_PRODUCT_CATALOG,
    CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG_SUCCESS
  );
const trackSaveInCatalogCreateNew = () =>
  piwikHelpers.trackEvent(
    CATEGORY_PRODUCT_CATALOG,
    CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG_CREATE_NEW
  );
const trackSaveInCatalogChange = () =>
  piwikHelpers.trackEvent(
    CATEGORY_PRODUCT_CATALOG,
    CREATE_NEW_INVOICE_POSITIONS_SAVE_IN_CATALOG_CHANGE
  );

interface Values {
  position: string;
}
interface ProductToBeChecked {
  name?: string;
  check_for_existing_items?: boolean;
}
interface ProductToBeCreated extends ProductToBeChecked {}
type ParseValues = (valuesToParse: Values) => Object;
type SetErrors = (errors: Object) => void;
type CreateProductCatalogItem = (
  data: Object,
  redirectToCatalog: boolean,
  modifier?: Object
) => Promise<{ status: ProductCreationStatuses; item_to_update: Object }>;
type UpdateProductCatalogItem = (
  data: Object,
  redirectToCatalog: boolean,
  isNotifyingAction: boolean
) => void;
type IsAddProductModalVisible = boolean;
type SetIsAddProductModalVisible = (isAddProductModalVisible: boolean) => void;
type AddProductModalAction = string;
type SetAddProductModalAction = (addProductModalAction: string) => void;
type ItemToUpdate = Object;
type SetItemToUpdate = (itemToUpdate: Object) => void;
type ProductCreationStatuses = 'skip' | 'create' | 'create_or_update';

const PRODUCT_CREATION_STATUSES: {
  [key: string]: ProductCreationStatuses;
} = {
  SKIP: 'skip',
  CREATE: 'create',
  CREATE_OR_UPDATE: 'create_or_update',
};

interface AddProductButtonProps {
  productCatalogItemsEnabled: boolean;
  addProductHidden: boolean;
  createProductCatalogItem: CreateProductCatalogItem;
  updateProductCatalogItem: UpdateProductCatalogItem;
  parseValues: ParseValues;
  values: Values;
  setErrors: SetErrors;
}

const prepareDataForProduct = ({
  parseValues,
  values,
}: {
  parseValues: ParseValues;
  values: Values;
}) => {
  const productToBeChecked: ProductToBeChecked = omit(
    {
      ...parseValues(values),
      name: values.position,
      check_for_existing_items: true,
    },
    ['position']
  );
  const productToBeCreated: ProductToBeCreated = omit(productToBeChecked, [
    'check_for_existing_items',
  ]);

  return {
    productToBeChecked,
    productToBeCreated,
  };
};

const checkForExistingProducts = async ({
  createProductCatalogItem,
  productToBeChecked,
  productToBeCreated,
  setIsAddProductModalVisible,
  setAddProductModalAction,
  setItemToUpdate,
  setErrors,
}: {
  createProductCatalogItem: CreateProductCatalogItem;
  productToBeChecked: ProductToBeChecked;
  productToBeCreated: ProductToBeCreated;
  setIsAddProductModalVisible: SetIsAddProductModalVisible;
  setAddProductModalAction: SetAddProductModalAction;
  setItemToUpdate: SetItemToUpdate;
  setErrors: SetErrors;
}) => {
  setErrors({});
  try {
    const { status, item_to_update: itemToUpdate } = await createProductCatalogItem(
      { data: productToBeChecked },
      false,
      {
        /**
         * This has to be hardcoded due to divergence of our field names for
         * product catalog and invoice line items (name vs position). Without this
         * there would be need to rewrite areas where those fields are used (on BE & FE)
         */
        name: {
          id: 'position',
          detail: t('revenue.form.details_section.errors.position_required'),
        },
      }
    );
    switch (status) {
      case PRODUCT_CREATION_STATUSES.SKIP:
        createProductCatalogItem({ data: productToBeCreated }, false);
        break;
      case PRODUCT_CREATION_STATUSES.CREATE:
        setIsAddProductModalVisible(true);
        setAddProductModalAction(status);
        break;
      case PRODUCT_CREATION_STATUSES.CREATE_OR_UPDATE:
        setIsAddProductModalVisible(true);
        setAddProductModalAction(status);
        setItemToUpdate(itemToUpdate);
        break;
      default:
        return null;
    }
  } catch ({ errors }) {
    if (errors) setErrors(errors as Object);
  }
  return null;
};

const AddProductButton = ({
  productCatalogItemsEnabled,
  addProductHidden,
  createProductCatalogItem,
  updateProductCatalogItem,
  parseValues,
  values,
  setErrors,
}: AddProductButtonProps) => {
  const [isAddProductModalVisible, setIsAddProductModalVisible] =
    useState<IsAddProductModalVisible>(false);
  const [addProductModalAction, setAddProductModalAction] = useState<AddProductModalAction>('');
  const [itemToUpdate, setItemToUpdate] = useState<ItemToUpdate>({});

  if (!productCatalogItemsEnabled || addProductHidden) {
    return null;
  }
  const { productToBeChecked, productToBeCreated } = prepareDataForProduct({ parseValues, values });

  return (
    <>
      <TableSubRow>
        <TableCell name="addProductButton">
          <Button
            label={t('forms.line_items.actions.add_to_catalog')}
            className={styles.addProduct}
            type="button"
            onClick={() => {
              checkForExistingProducts({
                createProductCatalogItem,
                productToBeChecked,
                productToBeCreated,
                setIsAddProductModalVisible,
                setAddProductModalAction,
                setItemToUpdate,
                setErrors,
              });
              trackSaveInCatalog();
            }}
          />
        </TableCell>
      </TableSubRow>
      <AddProductModal
        isOpen={isAddProductModalVisible}
        onClose={() => {
          setIsAddProductModalVisible(false);
          trackSaveInCatalogCancel();
        }}
        header={t('modals.add_product.header')}
        modalAction={addProductModalAction}
        createNewHandler={() => {
          createProductCatalogItem({ data: productToBeCreated }, false);
          trackSaveInCatalogSuccess();
          trackSaveInCatalogCreateNew();
          setIsAddProductModalVisible(false);
        }}
        editHandler={() => {
          updateProductCatalogItem({ id: itemToUpdate, data: productToBeCreated }, false, true);
          trackSaveInCatalogSuccess();
          trackSaveInCatalogChange();
          setIsAddProductModalVisible(false);
        }}
      >
        {addProductModalAction === 'create_or_update'
          ? t('modals.add_product.one_existing_message')
          : t('modals.add_product.many_existing_message')}
      </AddProductModal>
    </>
  );
};

export default AddProductButton;
