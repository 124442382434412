import { get, pick } from 'lodash';
import { combineReducers } from 'redux';

import {
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/tax-consultant';
import { ApiResponse } from 'types/actions';
import {
  FetchedTaxConsultantData,
  TaxConsultantDataFields,
  UpdatedTaxConsultantData,
} from 'types/models/tax-consultant-data';

type TaxConsultantAction =
  | ApiResponse<typeof FETCH_REQUEST, FetchedTaxConsultantData>
  | ApiResponse<typeof UPDATE_REQUEST, UpdatedTaxConsultantData>
  | ApiResponse<typeof FETCH_FAILURE, FetchedTaxConsultantData>
  | ApiResponse<typeof UPDATE_FAILURE, UpdatedTaxConsultantData>
  | ApiResponse<typeof FETCH_SUCCESS, FetchedTaxConsultantData>
  | ApiResponse<typeof UPDATE_SUCCESS, UpdatedTaxConsultantData>;

const defaultState = {
  data: {},
  isSwitchApplicable: false,
  isFetching: false,
  error: {},
};

const data = (state = defaultState.data, action: TaxConsultantAction): Pick<any, string> => {
  switch (action.type) {
    case FETCH_SUCCESS:
    case UPDATE_SUCCESS:
      const attributes = get(action, 'response.data.attributes', null);

      return pick(attributes, TaxConsultantDataFields);
    default:
      return state;
  }
};

const isSwitchApplicable = (
  state = defaultState.isSwitchApplicable,
  action: TaxConsultantAction
): boolean => {
  switch (action.type) {
    case FETCH_SUCCESS:
    case UPDATE_SUCCESS:
      return get(
        action,
        'response.data.attributes.datevAccountNumbersStrategySwitchApplicable',
        null
      );
    default:
      return state;
  }
};

const isFetching = (state = defaultState.isFetching, action: TaxConsultantAction): boolean => {
  switch (action.type) {
    case FETCH_REQUEST:
    case UPDATE_REQUEST:
      return true;
    case FETCH_SUCCESS:
    case FETCH_FAILURE:
    case UPDATE_SUCCESS:
    case UPDATE_FAILURE:
      return false;
    default:
      return state;
  }
};

const error = (state = defaultState.data, action: TaxConsultantAction) => {
  switch (action.type) {
    case FETCH_FAILURE:
    case UPDATE_FAILURE:
      return get(action, 'errors', {});
    default:
      return state;
  }
};

export default combineReducers<{
  data: Record<any, any>;
  isSwitchApplicable: boolean;
  isFetching: boolean;
  error: object;
}>({
  data,
  isSwitchApplicable,
  isFetching,
  error,
});
