import { CashTransaction } from './CashTransaction';

export enum ReminderType {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export enum ReminderDay {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export type Cashbook = {
  id: string;
  currentAmount: number;
  startAmount: number;
  startDate: string;
  reminder: boolean;
  reminderType: ReminderType | null;
  reminderDay: ReminderDay | 'first' | 'last' | null;
  reminderHour: number | null;
  weekdayReminder: boolean | null;
  cashTransactions: CashTransaction[];
  expensesSum: number;
  revenuesSum: number;
};
