import React from 'react';

import { t } from 'shared/utils';

import styles from './PaymentCreatorFooter.module.css';

const PaymentCreatorFooter = () => (
  <div className={styles.footerContainer}>
    <span className={styles.footerText}>{t('expenses.payments.creator.footer')}</span>
  </div>
);

export default PaymentCreatorFooter;
