import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DocumentTemplate from './DocumentTemplate/DocumentTemplate';

const Routes = () => {
  const match = useRouteMatch();

  if (!match) {
    return null;
  }

  return (
    <Switch>
      <Route path={`${match.path}/document-template`}>
        <DocumentTemplate />
      </Route>
    </Switch>
  );
};

export default Routes;
