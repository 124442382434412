import React, { ElementType, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchClient } from 'actions/clients';

type InjectedFetchClientProps = {
  match: {
    params: {
      id: string;
    };
  };
};

type WrapperProps = InjectedFetchClientProps & {
  WrappedComponent: ElementType;
};

const Wrapper = (props: WrapperProps) => {
  const dispatch = useDispatch();
  const {
    match: {
      params: { id },
    },
    WrappedComponent,
  } = props;

  useEffect(() => {
    dispatch(fetchClient(id));
  }, [dispatch, id]);

  return <WrappedComponent {...props} />;
};

const FetchClient = (WrappedComponent: ElementType) => (props: InjectedFetchClientProps) => {
  return <Wrapper WrappedComponent={WrappedComponent} {...props} />;
};

export default FetchClient;
