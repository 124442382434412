import React, { memo, SVGProps } from 'react';

const FinanceStatus = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 63 38" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#8FB8DA" fillRule="nonzero">
        <path d="M9.955 28.98v-7.623H2.263a2.063 2.063 0 0 1 0-4.125h7.692V9.568a2.085 2.085 0 1 1 4.17 0v7.664h7.61a2.063 2.063 0 0 1 0 4.125h-7.61v7.623a2.085 2.085 0 1 1-4.17 0ZM62.214 20.097a2.194 2.194 0 0 1-2.193 2.193H44.65a2.194 2.194 0 0 1 0-4.387h15.37c1.211 0 2.193.982 2.193 2.194Z" />
      </g>
      <path
        stroke="#8FB8DA"
        strokeWidth={4}
        strokeLinecap="round"
        d="M38.341 2.274 26.268 35.726"
      />
    </g>
  </svg>
);

export default memo(FinanceStatus);
