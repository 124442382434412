import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import normalize from 'json-api-normalizer';
import { debounce } from 'lodash';
import build from 'redux-object';

import { fetchPlans as fetchPlansAction } from 'actions/payment-plans';
import * as bankTransfersAPI from 'api/me/bankTransfers';
import EntityPath from 'constants/entitiesPaths';
import { PREMIUM_UID } from 'constants/payment-plans';
import { t } from 'shared/utils';
import { RootState } from 'store';
import { AssignableResource, ResourceType } from 'types/entities/AssignableResource';
import DownshiftSelect from 'components/Form/SelectField/DownshiftSelect';

import AddContract from './AddContract';
import ContractInstallmentOption from './ContractInstallmentOption';
import InvoiceOption from './InvoiceOption';
import SuggestionsSelect from './SuggestionsSelect';

import localStyles from '../AssignmentSection.module.css';

const PerPage = 5;

type SelectResourcesFieldProps = {
  bankTransferId: string;
  bankTransferAmount: number;
  onSelect: (assignableResource: AssignableResource) => void;
  onAddContract: () => void;
};

const dataIds = {
  input: 'BankTransferAssignment:assignment-input',
};

const SelectResourcesField = ({
  bankTransferId,
  bankTransferAmount,
  onSelect,
  onAddContract,
}: SelectResourcesFieldProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [resources, setResources] = useState<AssignableResource[]>([]);
  const [suggestedResources, setSuggestedResources] = useState<AssignableResource[]>([]);
  const [page, setPage] = useState(1);
  const [fetchedAll, setFetchedAll] = useState(false);
  const [filter, setFilter] = useState('');
  const dispatch = useDispatch();
  const isPremiumUser = useSelector(
    // @ts-ignore Reducer is not typed
    (state: RootState) => state.paymentPlan.details.uid === PREMIUM_UID
  );

  const fetchResources = useCallback(
    async (value, page) => {
      setIsFetching(true);

      const response = await bankTransfersAPI.getAssignableResources(bankTransferId, {
        filter: value,
        page,
        per_page: PerPage,
      });
      const resources =
        build<AssignableResource>(
          normalize(response.data),
          EntityPath.BankTransferAssignableItems
        ) || [];
      setFetchedAll(resources.length < PerPage);
      setFilter(value);
      setIsFetching(false);

      return resources;
    },
    [bankTransferId]
  );

  const fetchSuggestionOptions = useCallback(
    async (page) => {
      const response = await bankTransfersAPI.getSuggestedResources(bankTransferId, {
        page,
      });
      const resources =
        build<AssignableResource>(
          normalize(response.data),
          EntityPath.BankTransferAssignableItems
        ) || [];

      return resources;
    },
    [bankTransferId]
  );

  const debouncedFetchOnType = useCallback(
    debounce(async (value: string) => {
      setPage(1);
      const resources = await fetchResources(value, 1);

      if (isPremiumUser) {
        value.length > 0 ? setSuggestedResources([]) : initialFetchSuggestionResources();
      }

      setResources(resources);
    }, 200),
    [fetchResources]
  );

  const fetchAllResources = () => {
    if (isPremiumUser) {
      initialFetchSuggestionResources();
    }

    initialFetchResources();
  };

  const initialFetchResources = useCallback(async () => {
    setPage(1);
    const resources = await fetchResources('', 1);

    setResources(resources);
  }, [fetchResources]);

  const initialFetchSuggestionResources = useCallback(async () => {
    setPage(1);
    const suggestionResources = await fetchSuggestionOptions(1);
    setSuggestedResources(suggestionResources);
  }, [fetchSuggestionOptions]);

  const fetchMoreResources = useCallback(async () => {
    setPage(page + 1);
    const resources = await fetchResources(filter, page + 1);

    setResources((currentResources) => [...currentResources, ...resources]);
  }, [page, filter, fetchResources]);

  const getOption = useCallback((resource: AssignableResource) => {
    if (
      [ResourceType.IncomingInvoice, ResourceType.OutgoingInvoice].includes(resource.resourceType)
    ) {
      return (
        <InvoiceOption
          key={resource.resourceId}
          invoice={resource}
          bankTransferAmount={bankTransferAmount}
        />
      );
    } else if (resource.resourceType === ResourceType.ContractInstallment) {
      return <ContractInstallmentOption key={resource.resourceId} installment={resource} />;
    } else {
      return null;
    }
  }, []);

  const getTrailingContent = useCallback(
    () =>
      fetchedAll ? null : (
        <button
          type="button"
          data-id="button-fetch-more-resources"
          className={localStyles.fetchMore}
          onClick={fetchMoreResources}
        >
          {t('bank_transfers.table.fetch_more_resources')}
        </button>
      ),
    [fetchedAll, fetchMoreResources]
  );

  const getEmptyComponent = useCallback(
    () => (
      <div className={localStyles.optionIsEmpty}>
        {t('bank_transfers.table.no_resources_found')}
      </div>
    ),
    []
  );

  useEffect(() => {
    dispatch(fetchPlansAction());
  });

  return (
    <DownshiftSelect
      menuClassName={localStyles.menu}
      className={localStyles.selectResourceField}
      options={resources}
      suggestionOptions={suggestedResources}
      placeholder={t('bank_transfers.table.select.placeholder')}
      label={t('bank_transfers.table.select.label')}
      isLoading={isFetching}
      isSearchable
      onSelect={onSelect}
      onInputChange={debouncedFetchOnType}
      onOpen={fetchAllResources}
      optionRenderer={getOption}
      getTrailingContent={getTrailingContent}
      getEmptyComponent={getEmptyComponent}
      dataIds={dataIds}
      addNew={<AddContract onClick={onAddContract} />}
      suggestionsSelect={SuggestionsSelect}
    />
  );
};

export default SelectResourcesField;
