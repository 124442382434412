import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { debounce } from 'lodash';
import { bool, instanceOf, object, oneOfType, shape, string } from 'prop-types';

import If from 'components/Conditions/If';
import Loading from 'components/LoadingIcon';

import styles from './PDFViewer.module.css';

const fileTypes = [
  string,
  instanceOf(ArrayBuffer),
  shape({
    data: object,
    httpHeaders: object,
    range: object,
    url: string,
    withCredentials: bool,
  }),
];

export default class PreviewModal extends Component {
  static propTypes = {
    file: oneOfType(fileTypes),
  };

  state = {
    numPages: null,
    width: null,
  };

  componentDidMount() {
    window.addEventListener('resize', this.setPageWidth);
    this.setPageWidth();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setPageWidth);
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  };

  setRef = (ref) => {
    this.document = ref;
  };

  setPageWidth = () => {
    const node = findDOMNode(this.document);
    const { width } = node.getBoundingClientRect();
    this.setWidth(width);
  };

  setWidth = debounce(this._setWidth, 200);

  _setWidth(value) {
    this.setState({ width: value });
  }

  render() {
    const { file } = this.props;
    const { numPages, width } = this.state;

    return (
      file && (
        <div className={styles.flexbugFix}>
          <div className={styles.root} ref={this.setRef}>
            <If ok={!width}>
              <Loading className={styles.loading} staticPosition />
            </If>
            <Document
              className={styles.document}
              file={file}
              onLoadSuccess={this.onDocumentLoad}
              loading={<Loading className={styles.loading} staticPosition />}
            >
              {width &&
                Array.from(new Array(numPages), (_el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    onRenderSuccess={this.onPageRenderSuccess}
                    width={width}
                  />
                ))}
            </Document>
          </div>
        </div>
      )
    );
  }
}
