export enum ResourceType {
  IncomingInvoice = 'IncomingInvoice',
  OutgoingInvoice = 'OutgoingInvoice',
  ContractInstallment = 'ContractInstallment',
}

export type AssignableResource = {
  date: string;
  resourceId: number;
  resourceType: ResourceType;
  description: string;
  totalGrossAmount: number;
  grossAmountToBePaid: number;
  partlyPaid: boolean;
  discountAmount: number;
  jokerOptionJustification: string | null;
  creditNote: boolean;
  contractor: string | null;
};
