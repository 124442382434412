import { t } from 'shared/utils';

export const updateSuccess = {
  duration: 3000,
  title: t('notifications.profile.update_success.title'),
  variant: 'success',
};

export const updateFailure = {
  duration: 3000,
  title: t('notifications.profile.update_failure.title'),
  variant: 'warning',
};
