import React, { Component } from 'react';
import { connect } from 'react-redux';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { fetchIncomingInvoice as fetchIncomingInvoiceAction } from 'actions/incoming-invoice';
import {
  createPayment as createPaymentAction,
  fetchPayments as fetchPaymentsAction,
  getSca as getScaAction,
} from 'actions/incoming-invoice/payments';
import { ACTION_PAYMENT_CREATOR_STEP_3_BACK, ACTION_PAYMENT_CREATOR_STEP_4 } from 'constants/piwik';
import { t } from 'shared/utils';
import { camelizeKeysDeep } from 'shared/utils/entity-mapper';
import {
  trackCreatePaymentSuccessEvent,
  trackPaymentEvent,
} from 'shared/utils/tracking/payments-tracking';
import ActionButton from 'components/ActionPanel/ActionButton';
import { PasswordField, TextField } from 'components/Form';

import FieldsContainer from '../../components/FieldsContainer/FieldsContainer';
import Hint from '../../components/Hint/Hint';
import ParallelContainer from '../../components/ParallelContainer/ParallelContainer';
import PaymentCreatorActions from '../../components/PaymentCreatorActions/PaymentCreatorActions';

class Login extends Component {
  state = {};

  resetState = () => this.setState({});

  goBack = () => {
    this.resetState();
    this.props.backwardAction();
  };

  goToTheNextStep({ challenge, syncId }) {
    const { setCreatorState, forwardAction } = this.props;

    setCreatorState({
      challenge,
      syncId,
    });
    this.resetState();
    forwardAction();
  }

  async getAndValidateSca({ paymentFigoId, accountFigoId, syncId }) {
    const {
      getSca,
      setCreatorState,
      goToLastStepAction,
      paymentDetails: { amount: paymentAmount },
      invoiceAmount,
      invoiceId,
      fetchPayments,
      fetchIncomingInvoice,
    } = this.props;
    const rawResponse = await getSca({
      paymentFigoId,
      accountFigoId,
      syncId,
    });
    const { challenge, createdAt, submitted } = camelizeKeysDeep(rawResponse);

    if (submitted) {
      trackCreatePaymentSuccessEvent({ paymentAmount, invoiceAmount });
      setCreatorState({ paymentCreationDate: createdAt });
      fetchPayments(invoiceId);
      fetchIncomingInvoice(invoiceId);
      goToLastStepAction();
      return;
    }

    this.goToTheNextStep({ challenge, syncId });
  }

  validatePayment = async () => {
    const { invoiceId, createPayment, payment } = this.props;
    /**
     * Use Figo payment object id to create a payment in Figo.
     * This action will initially put the Figo payment in QUEUED status.
     */

    const { paymentId: figoPaymentObjectId, accountId } = payment;
    const paymentData = {
      accountId,
      figoPaymentId: figoPaymentObjectId,
      credentials: this.state,
    };

    const createPaymentResponse = await createPayment(invoiceId, paymentData);

    if (!createPaymentResponse.isSuccess) return;

    /**
     * Check what is the status of the Figo payment.
     * Payment could be in one of this states: AWAIT_AUTH, FAILED.
     * If response submitted is set to true then payment is done and we proceed to the last step.
     * If the status is AWAIT_AUTH then we need to redirect user to the TanSelection step.
     */

    const {
      payment_id,
      account_id,
      sync: { id: syncId },
    } = createPaymentResponse;

    this.getAndValidateSca({
      paymentFigoId: payment_id,
      accountFigoId: account_id,
      syncId,
    });
  };

  handleInputChange = ({ target: { name, value } }) => this.setState({ [name]: value });

  render() {
    const { selectedAccount } = this.props;
    const isValid = selectedAccount.credentials.every((field) => !!this.state[field.key]);

    return (
      <>
        <FieldsContainer>
          <Hint text={t('expenses.payments.creator.hints.login')} />
          <ParallelContainer>
            {selectedAccount.credentials.map((field) => {
              const Component = field.isSecret ? PasswordField : TextField;

              return (
                <Component
                  name={field.key}
                  label={field.label}
                  component={field.isSecret ? PasswordField : TextField}
                  data-id={`FigoPayments:input-${field.key}`}
                  minLength={field.minLength}
                  maxLength={field.maxLength}
                  value={this.state[field.key]}
                  key={field.key}
                  onChange={this.handleInputChange}
                />
              );
            })}
          </ParallelContainer>
        </FieldsContainer>
        <PaymentCreatorActions>
          <ActionButton
            appearance="primary"
            disabled={!isValid}
            onClick={this.validatePayment}
            dataId="FigoPayments:button-accept-pin"
            onClickSideAction={trackPaymentEvent(ACTION_PAYMENT_CREATOR_STEP_4)}
            label={t('expenses.payments.actions.insert_pin')}
          />
          <ActionButton
            appearance="outlined"
            dataId="FigoPayments:button-cancel-pin"
            onClick={this.goBack}
            onClickSideAction={trackPaymentEvent(ACTION_PAYMENT_CREATOR_STEP_3_BACK)}
            label={t('expenses.payments.actions.backward')}
          />
        </PaymentCreatorActions>
      </>
    );
  }
}

const CredentialsShape = shape({
  key: string.isRequired,
  label: string.isRequired,
  minLength: number.isRequired,
  maxLength: number.isRequired,
  isSecret: bool.isRequired,
  isOptional: bool.isRequired,
});

Login.propTypes = {
  selectedAccount: shape({ credentials: arrayOf(CredentialsShape) }).isRequired,
  payment: shape({}),
  backwardAction: func.isRequired,
  goToLastStepAction: func.isRequired,
  forwardAction: func.isRequired,
  setCreatorState: func.isRequired,
  invoiceId: string,
  createPayment: func,
  getSca: func,
  fetchPayments: func.isRequired,
  fetchIncomingInvoice: func.isRequired,
  invoiceAmount: number,
  paymentDetails: shape({}),
};

const mapDispatchToProps = (dispatch, { paymentAction }) => ({
  createPayment: paymentAction(dispatch)(createPaymentAction),
  getSca: paymentAction(dispatch)(getScaAction),
  fetchPayments: paymentAction(dispatch)(fetchPaymentsAction),
  fetchIncomingInvoice: (id) => dispatch(fetchIncomingInvoiceAction(id)),
});

export default connect(null, mapDispatchToProps)(Login);
