import styled from 'styled-components';

import { Breakpoints } from 'redesign/styles/breakpoints';

type ContainerProps = {
  $withTopBorder?: boolean;
};

export const Container = styled.div<ContainerProps>`
  border: none;
  padding-top: ${({ theme }) => theme.space['6']};
  margin-right: ${({ theme }) => theme.space['8']};

  ul {
    list-style: disc;
    list-style-position: outside;
  }

  @media (min-width: ${Breakpoints.lg}) {
    padding: ${({ theme }) => theme.space['6']} ${({ theme }) => theme.space['0']};
    border-top: ${({ $withTopBorder }) => ($withTopBorder ? '1px solid black' : 'none')};
  }
`;

export const ListItem = styled.li`
  margin-left: ${({ theme }) => theme.space['6']};
  ::marker {
    font-weight: bold;
    font-size: 150%;
  }
`;
export const InfoHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.space['3']};
  font-weight: bold;
`;
