import React, { useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { flow } from 'lodash';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { resetClientDefaults, updateClientDefaults } from 'actions/default-values';
import { getPaymentReminder, GetPaymentReminderData } from 'api/me/previews/paymentReminder';
import { ClientDocumentsFormName } from 'containers/Clients/ClientDocumentDefaults/ClientDocumentDefaults';
import { FormRow } from 'containers/Profile/InvoiceTemplate/DefaultValues/sharedComponents';
import DefaultValuesFormWithPreview from 'containers/Profile/InvoiceTemplate/DefaultValues/sharedComponents/DefaultValuesForm/DefaultValuesFormWithPreview';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { RootState } from 'store';
import { DefaultValues } from 'types/entities/DefaultValues';
import { TextField } from 'components/Form';
import InputsGroup from 'components/InputsGroup/InputsGroup';
import HtmlField from 'components/v2/Form/HtmlField/HtmlField';

import styles from './PaymentReminderDefaults.module.css';

export const FORM_NAME: ClientDocumentsFormName = 'clientPaymentReminderDefaults';

type FormData = Pick<
  DefaultValues,
  | 'paymentReminderSubject'
  | 'paymentReminderSalutation'
  | 'paymentReminderSalutationContent'
  | 'paymentReminderFooter'
>;

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    paymentReminderSubject: state.defaultValues.values.paymentReminderSubject,
    paymentReminderSalutation: state.defaultValues.values.paymentReminderSalutation,
    paymentReminderSalutationContent: state.defaultValues.values.paymentReminderSalutationContent,
    paymentReminderFooter: state.defaultValues.values.paymentReminderFooter,
  },
  formValues: {
    paymentReminderSalutation: selector(state, 'paymentReminderSalutation'),
    paymentReminderSalutationContent: selector(state, 'paymentReminderSalutationContent'),
    paymentReminderFooter: selector(state, 'paymentReminderFooter'),
  },
});

const mapDispatchToProps = {
  updateClientDefaults,
  resetClientDefaults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PaymentReminderDefaultsProps = ConnectedProps<typeof connector> & {
  clientId: string;
};

const PaymentReminderDefaults = ({
  formValues: {
    paymentReminderSalutation,
    paymentReminderSalutationContent,
    paymentReminderFooter,
  },
  resetClientDefaults,
  updateClientDefaults,
  handleSubmit,
  clientId,
  reset: resetForm,
}: PaymentReminderDefaultsProps & InjectedFormProps<FormData, PaymentReminderDefaultsProps>) => {
  const topFields = useMemo(
    () => (
      <InputsGroup
        label={t('invoice_templates.documents_defaults.discount_subject')}
        containerClass={styles.inputGroupContainer}
      >
        <Field
          disabled
          className={styles.grouppedInput}
          name="paymentReminderSubject"
          checker={serverValidationChecker}
          component={TextField}
          dataId="ClientDocumentDefaults:input-payment-reminder-subject"
          isDisabledWithUnderline
        />
      </InputsGroup>
    ),
    []
  );

  const values = useMemo(
    () => ({
      introduction:
        paymentReminderSalutation && paymentReminderSalutationContent
          ? `${paymentReminderSalutation} ${paymentReminderSalutationContent}`
          : undefined,
      footer: paymentReminderFooter,
    }),
    [paymentReminderSalutation, paymentReminderSalutationContent, paymentReminderFooter]
  );

  return (
    <DefaultValuesFormWithPreview<GetPaymentReminderData>
      onSubmit={handleSubmit((values) =>
        updateClientDefaults(clientId, values, 'form_payment_reminder')
      )}
      onReset={async () => {
        await resetClientDefaults(clientId, 'form_payment_reminder');
        resetForm();
      }}
      sectionName="form_payment-reminder"
      sectionLabel={t('invoice_templates.documents_defaults.payment_reminder_defaults_section')}
      dataIdPrefix="ClientDocumentDefaults:"
      values={values}
      fetchPreview={getPaymentReminder}
      topFields={topFields}
    >
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.header')}>
          <Field
            name="paymentReminderSalutation"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation')}
            tootlip={t('invoice_templates.documents_defaults.salutation_info')}
            dataId="ClientDocumentDefaults:input-payment-reminder-salutation"
          />
          <br />
          <Field
            name="paymentReminderSalutationContent"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation_content')}
            dataId="ClientDocumentDefaults:input-payment-reminder-salutation-content"
          />
        </InputsGroup>
      </FormRow>
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.footer')}>
          <Field
            name="paymentReminderFooter"
            component={HtmlField}
            checker={serverValidationChecker}
            dataId="ClientDocumentDefaults:input-payment-reminder-footer"
          />
        </InputsGroup>
      </FormRow>
    </DefaultValuesFormWithPreview>
  );
};

export default flow(
  withTransitionPrevent(),
  reduxForm<FormData, PaymentReminderDefaultsProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    persistentSubmitErrors: true,
  }),
  connector
)(PaymentReminderDefaults);
