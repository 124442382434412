/* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { arrayOf, bool, func, shape } from 'prop-types';

import { deleteClient, sortClients } from 'actions/clients';
import { DE_CLIENT_DELETED, DE_CLIENT_MANAGEMENT } from 'constants/clients';
import { MODELS } from 'constants/contacts';
import TableButtons from 'containers/TableButtons/TableButtons';
import { farmpilotEnabledHelper } from 'routes/accesses';
import { isFarmpilotCustomerConnectionActive as isFarmpilotCustomerConnectionActiveSelector } from 'selectors/app-connections';
import { isFarmpilotIntegrationActive as isFarmpilotIntegrationActiveSelector } from 'selectors/app-settings';
import tableStyles from 'shared/styles/table.module.css';
import { piwikHelpers } from 'shared/utils/piwik';
import I18n from 'components/I18n';
import { ConfirmationModal } from 'components/Modal';
import { EmptyState, TableHeader } from 'components/Table';

import ClientRow from './ClientRow';

import localStyles from './ClientsTable.module.css';

export const styles = { ...tableStyles, ...localStyles };

class ClientsTable extends Component {
  state = {
    deletingItem: undefined,
  };

  onClearConfirmations = () => {
    this.setState({ deletingItem: undefined });
  };

  onDelete = (client) => {
    this.onClearConfirmations();
    const { refresh, remove } = this.props;
    remove(client).then(() => refresh());
  };

  onRequestDelete = (invoice) => {
    this.setState({ deletingItem: invoice });
  };

  fields = ['company_name', 'city', 'honorific', 'first_name', 'last_name', 'id_number', 'actions'];
  sortableFields = ['company_name', 'city', 'honorific', 'first_name', 'last_name', 'id_number'];

  render() {
    const {
      isFetching,
      data = [],
      sorting,
      sort,
      isFarmpilotEnabled,
      isFarmpilotIntegrationActive,
      isFarmpilotCustomerConnectionActive,
    } = this.props;

    if (isEmpty(data)) {
      return (
        <EmptyState isFetching={isFetching} id="empty-clients-table">
          <TableButtons whichFolder={MODELS.CLIENT} />
        </EmptyState>
      );
    }

    return (
      <div className={styles.invoicesTableWrapper}>
        <table
          className={cx(styles.invoicesTable, { [styles.invoicesTableLoading]: isFetching })}
          data-id="table"
        >
          <thead>
            <tr>
              {this.fields.map((field) => (
                <TableHeader
                  columnWidthClass={styles.column}
                  sort={sort}
                  column={field}
                  section="clients"
                  sortable={this.sortableFields.includes(field)}
                  currentSorting={sorting}
                  key={field}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((client) => (
              <ClientRow
                key={client.id}
                client={client}
                onDelete={this.onRequestDelete}
                isFarmpilotEnabled={isFarmpilotEnabled}
                isFarmpilotIntegrationActive={isFarmpilotIntegrationActive}
                isFarmpilotCustomerConnectionActive={isFarmpilotCustomerConnectionActive}
              />
            ))}
          </tbody>
        </table>
        <ConfirmationModal
          dangerousAction
          isOpen={!!this.state.deletingItem}
          onClose={this.onClearConfirmations}
          onConfirm={() => {
            piwikHelpers.trackEvent(DE_CLIENT_MANAGEMENT, DE_CLIENT_DELETED);
            this.onDelete(this.state.deletingItem);
          }}
        >
          <I18n t="clients.confirm_delete" paragraphs />
        </ConfirmationModal>
      </div>
    );
  }
}

ClientsTable.propTypes = {
  data: arrayOf(shape({})).isRequired,
  sorting: shape({}).isRequired,
  isFetching: bool.isRequired,
  refresh: func.isRequired,
  remove: func.isRequired,
  sort: func.isRequired,
  isFarmpilotEnabled: bool.isRequired,
  isFarmpilotIntegrationActive: bool.isRequired,
  isFarmpilotCustomerConnectionActive: bool.isRequired,
};

const mapStateToProps = (state) => ({
  isFarmpilotEnabled: farmpilotEnabledHelper(state),
  isFarmpilotIntegrationActive: isFarmpilotIntegrationActiveSelector(state),
  isFarmpilotCustomerConnectionActive: isFarmpilotCustomerConnectionActiveSelector(state),
});

const mapDispatchToProps = { sortClients, deleteClient };

export default connect(mapStateToProps, mapDispatchToProps)(ClientsTable);
