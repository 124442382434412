import styled from 'styled-components';

const Badge = styled.span`
  display: inline-flex;
  height: 16px;
  align-items: center;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.vrorange['100']};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: bold;
  padding: 0 ${({ theme }) => theme.space['1']};
  user-select: none;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.white};
`;

export default Badge;
