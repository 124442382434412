import styled from 'styled-components';

import BaseBox from 'redesign/components/atoms/Box/Box';
import { Breakpoints } from 'redesign/styles/breakpoints';

export const Box = styled(BaseBox)`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.space['4']};
  padding: ${({ theme }) => `${theme.space['2']} ${theme.space['4']} ${theme.space['4']}`};
`;

export const Content = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space['4']};
  grid-template-areas: 'picture' 'title' 'text';
  justify-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey['080']};

  @media (min-width: ${Breakpoints.md}) {
    grid-gap: ${({ theme }) => theme.space['3']};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'title picture' 'text picture';
    text-align: left;
    justify-items: start;
    align-items: start;
  }
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize['2xl']};
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 29px;
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSize.base};
  letter-spacing: 0.25px;
  line-height: 24px;
  margin-bottom: 0;
`;

export const Picture = styled.svg`
  max-width: 311px;
  grid-area: picture;

  @media (min-width: ${Breakpoints.md}) {
    justify-self: end;
  }
`;

export const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: -${({ theme }) => theme.space['2']};

  label {
    font-weight: normal;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.grey['070']};
    margin: 0 ${({ theme }) => theme.space['4']} 0 0;
    cursor: pointer;

    > div {
      margin-right: ${({ theme }) => theme.space['2']};
    }
  }

  > svg:last-child {
    color: ${({ theme }) => theme.colors.vrblue['100']};
    cursor: pointer;
  }
`;
