import normalize from 'json-api-normalizer';
import { get } from 'lodash';
import moment from 'moment';
import { combineReducers } from 'redux';
import { actionTypes } from 'redux-form';
import build from 'redux-object';

import {
  CREATE_REQUEST,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
} from 'constants/order-confirmation';

import lineCategories from './line-categories';
import lineItems from './line-items';
import sums from './sums';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
    case CREATE_REQUEST:
      return true;
    case FETCH_SUCCESS:
    case FETCH_FAILURE:
      return false;
    default:
      return state;
  }
};

const isFetched = (state = false, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return true;
    case FETCH_REQUEST:
    case FETCH_FAILURE:
      return false;
    default:
      return state;
  }
};

const mapOrderConfirmation = (response) => {
  const { data: orderConfirmation } = response;
  const normalizedOrderConfirmation = build(
    normalize(response),
    'orderConfirmations',
    orderConfirmation.id
  );

  return {
    ...orderConfirmation.attributes,
    id: orderConfirmation.id,
    orderConfirmationDate:
      orderConfirmation.attributes.orderConfirmationDate || moment().format('DD.MM.YYYY'),
    client: normalizedOrderConfirmation && normalizedOrderConfirmation.client,
  };
};

const details = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return mapOrderConfirmation(action.response);
    default:
      return state;
  }
};

export const meta = (state = null, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return get(action, 'response.data.meta', null);
    default:
      return state;
  }
};

const orderConfirmation = combineReducers({
  isFetching,
  isFetched,
  lineCategories,
  details,
  lineItems,
  meta,
  sums,
});

export default (state, action) => {
  if (
    action.type === actionTypes.DESTROY &&
    action.meta.form.includes('orderConfirmationCreator')
  ) {
    /* Clean all state on quit */
    return orderConfirmation({}, action);
  }

  return orderConfirmation(state, action);
};
