import { CALL_API } from 'constants/api';
import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/app-settings';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiFetchAppSettings = () => ({
  [CALL_API]: {
    endpoint: '/app_settings',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const fetchAppSettings = () => (dispatch) =>
  dispatch(apiFetchAppSettings()).catch(apiErrorHandler);
