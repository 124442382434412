import React from 'react';

import honorifics from 'constants/honorifics';
import SectionWithMargin, { SectionRow } from 'containers/Profile/SectionWithMargin';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import { InfoTextField, TextField } from 'components/Form';
import FormField from 'components/Form/FormField';
import DownshiftSelect from 'components/Form/SelectField/DownshiftSelect';

import styles from './Form.module.css';

const honorificsOptions = honorifics.map((h) => ({ value: h, label: h }));

const sharedInputClass = styles.fieldLarge;

const Form = ({ ...props }) => {
  return (
    <SectionWithMargin title={t('profile.sections.personal.data')}>
      <SectionRow className={styles.sectionRow}>
        <FormField
          name="honorific"
          component={DownshiftSelect}
          options={honorificsOptions}
          className={sharedInputClass}
          required
          dataIds={{
            container: 'selectHonorific',
            input: 'inputHonorific',
            row: 'optionHonorific',
          }}
          isMenuFullWidth={false}
        />
        <FormField
          name="firstName"
          component={TextField}
          placeholder={t('profile.company.placeholders.first_name')}
          className={sharedInputClass}
          dataId="firstName"
          required
        />
        <FormField
          name="lastName"
          component={TextField}
          placeholder={t('profile.company.placeholders.last_name')}
          className={sharedInputClass}
          dataId="lastName"
          required
        />
      </SectionRow>

      <SectionRow className={styles.sectionRow}>
        <FormField
          name="degree"
          component={TextField}
          className={sharedInputClass}
          placeholder={t('profile.company.placeholders.degree')}
          dataId="degree"
        />
        <FormField
          name="companyPosition"
          component={TextField}
          className={sharedInputClass}
          placeholder={t('profile.company.placeholders.company_position')}
          dataId="companyPosition"
        />
        <FormField
          name="email"
          component={InfoTextField}
          className={sharedInputClass}
          placeholder={t('profile.company.placeholders.email')}
          disabled
          required
          dataId="email"
        />
        <div>
          <ActionButton
            appearance="outlined"
            onClick={props.handleChangeEmailModalVisibility}
            label={t('profile.personal.change_email')}
            type="button"
            dataId="PersonalPage:button-openChangeEmailModal"
          />
        </div>
      </SectionRow>

      <SectionRow className={styles.sectionRow}>
        <FormField
          name="phoneNumber"
          component={TextField}
          className={sharedInputClass}
          placeholder={t('profile.company.placeholders.phone_number')}
          dataId="phoneNr"
        />
        <FormField
          name="mobilePhoneNumber"
          component={TextField}
          className={sharedInputClass}
          placeholder={t('profile.company.placeholders.mobile_phone_number')}
          dataId="mobileNr"
        />
        <FormField
          name="faxNumber"
          component={TextField}
          className={sharedInputClass}
          placeholder={t('profile.company.placeholders.fax_number')}
          dataId="fax"
        />
      </SectionRow>
    </SectionWithMargin>
  );
};

export default Form;
