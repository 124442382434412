import { t } from 'shared/utils';

export const columns = [
  {
    title: t('profile.payment_plan.table.headers.function'),
  },
  {
    title: t('profile.payment_plan.table.headers.basic'),
  },
  {
    title: t('profile.payment_plan.table.headers.premium'),
  },
];

export const options = [
  {
    title: t('profile.payment_plan.table.content.row_a'),
  },
  {
    title: t('profile.payment_plan.table.content.row_b'),
  },
  {
    title: t('profile.payment_plan.table.content.row_c'),
  },
  {
    title: t('profile.payment_plan.table.content.row_d'),
  },
  {
    title: t('profile.payment_plan.table.content.row_e'),
  },
  {
    title: t('profile.payment_plan.table.content.row_v'),
  },
  {
    title: t('profile.payment_plan.table.content.row_f'),
  },
  {
    title: t('profile.payment_plan.table.content.row_g'),
  },
  {
    title: t('profile.payment_plan.table.content.row_h'),
  },
  {
    title: t('profile.payment_plan.table.content.row_i'),
  },
  {
    title: t('profile.payment_plan.table.content.row_j'),
  },
  {
    title: t('profile.payment_plan.table.content.row_k'),
  },
  {
    title: t('profile.payment_plan.table.content.row_l'),
    isPremium: true,
  },
  {
    title: t('profile.payment_plan.table.content.row_m'),
    isPremium: true,
  },
  {
    title: t('profile.payment_plan.table.content.row_n'),
    isPremium: true,
  },
  {
    title: t('profile.payment_plan.table.content.row_o'),
    isPremium: true,
  },
  {
    title: t('profile.payment_plan.table.content.row_p'),
    isPremium: true,
  },
  {
    title: t('profile.payment_plan.table.content.row_q'),
    isPremium: true,
  },
  {
    title: t('profile.payment_plan.table.content.row_r'),
    isPremium: true,
  },
  {
    title: t('profile.payment_plan.table.content.row_s'),
    isPremium: true,
  },
  {
    title: t('profile.payment_plan.table.content.row_t'),
    isPremium: true,
  },
  {
    title: t('profile.payment_plan.table.content.row_u'),
    isPremium: true,
  },
  {
    title: t('profile.payment_plan.table.content.row_v'),
    isPremium: true,
  },
  {
    title: t('profile.payment_plan.table.content.row_w'),
    isPremium: true,
  },
];
