import React, { cloneElement, InputHTMLAttributes, isValidElement, ReactNode } from 'react';

import { Label } from './ControlLabel.styled';

type ControlLabelProps = {
  label: string;
  control: ReactNode;
  className?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const ControlLabel = ({ className, label, control, disabled, ...rest }: ControlLabelProps) => {
  return (
    <Label className={className} $isDisabled={disabled}>
      {isValidElement(control) && cloneElement(control, { disabled, ...rest })} {label}
    </Label>
  );
};

export default ControlLabel;
