import React from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';
import { bool, func } from 'prop-types';

import rightArrowIcon from 'images/icon-arrow.svg';
import { t } from 'shared/utils';

import styles from './ReadMore.module.css';

const ReadMore = ({ onClick, isMessageActive }) => (
  <button
    onClick={onClick}
    className={cx(styles.button, {
      [styles.active]: isMessageActive,
    })}
  >
    <div>{t('sidebar.messages.read_more')}</div>
    <div className={styles.arrow}>
      <InlineSvg src={rightArrowIcon} />
    </div>
  </button>
);

ReadMore.propTypes = {
  onClick: func.isRequired,
  isMessageActive: bool.isRequired,
};

export default ReadMore;
