import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { getCashbooks } from 'api/me/cashbooks';
import EntityPath from 'constants/entitiesPaths';
import paths from 'routes/paths';
import { Cashbook } from 'types/entities/Cashbook';

export const useHasAnyCashbooks = () => {
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(true);
  const [hasAnyCashbooks, setHasAnyCashbooks] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await getCashbooks();
        const cashbooks = build<Cashbook>(normalize(response.data), EntityPath.Cashbooks) || [];

        setIsFetching(false);
        setHasAnyCashbooks(cashbooks.length >= 1);
      } catch (e) {
        setIsFetching(false);
        history.push(paths.home);
      }
    };

    fetch();
  }, [history]);

  return { isFetching, hasAnyCashbooks };
};
