import React, { useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { t } from 'shared/utils';
import Link from 'redesign/components/atoms/Link/Link';
import ModalActions from 'redesign/components/atoms/ModalActions/ModalActions';
import ModalTitle from 'redesign/components/atoms/ModalTitle/ModalTitle';
import Modal from 'redesign/components/molecules/Modal/Modal';

import ManualBankForm from '../ManualBankForm/ManualBankForm';
import { TManualBankAccount } from '../types';
import * as SC from './BankAccountModal.styled';

type BankAccountModalProps = {
  onSubmit: (data: TManualBankAccount) => void;
  onClose: () => void;
  isActive: boolean;
  bankDetails: TManualBankAccount;
};

const BankAccountModal = ({ isActive, onClose, onSubmit, bankDetails }: BankAccountModalProps) => {
  const [bankDetailsForm, setBankDetailsForm] = useState<TManualBankAccount | undefined>(
    bankDetails
  );
  const [bankDetailsFormSaved, setBankDetailsFormSaved] = useState<TManualBankAccount | undefined>(
    undefined
  );

  useUpdateEffect(() => {
    bankDetails?.iban && setBankDetailsFormSaved(bankDetails);
    setBankDetailsForm(bankDetails);
  }, [bankDetails]);

  const handleClose = () => {
    !bankDetailsFormSaved && setBankDetailsForm(undefined);
    if (JSON.stringify(bankDetailsForm) !== JSON.stringify(bankDetailsFormSaved)) {
      setBankDetailsForm(bankDetailsFormSaved);
    }
    onClose();
  };

  const submitModal = () => {
    if (bankDetailsForm?.bankName) {
      onSubmit(bankDetailsForm);
    }
  };

  return (
    <Modal shouldCloseOnOverlayClick={false} isOpen={isActive} onRequestClose={() => onClose()}>
      <ModalTitle>{t('invoice_templates.form.bankAccounts.add_manually')}</ModalTitle>

      <SC.ModalBody>
        <SC.ModalDescription>
          {t('invoice_templates.form.bankAccounts.modal_caption')}
        </SC.ModalDescription>
        <ManualBankForm setBankDetails={setBankDetailsForm} bankDetails={bankDetailsForm} />
      </SC.ModalBody>

      <ModalActions $direction="horizontal">
        <Link onClick={handleClose}>{t('invoice_templates.form.bankAccounts.cancel')}</Link>
        <SC.ButtonSubmit onClick={submitModal}>
          {t('invoice_templates.form.bankAccounts.save')}
        </SC.ButtonSubmit>
      </ModalActions>
    </Modal>
  );
};

export default BankAccountModal;
