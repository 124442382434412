import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { fetchProductCatalogGroup } from 'actions/product-catalog/groups/groups';
import {
  reorderLineItems as reorderLineItemsAction,
  toggleProposalGrossNet,
} from 'actions/proposal';
import { IMPORT_MODE, NEW_MODE } from 'constants/common/crud';
import { proposalHasLineItems } from 'reducers';
import { transformProductCatalogGroupResponse } from 'reducers/product-catalog/groups';
import { productCatalogGroupsAndItemsEnabledHelper } from 'routes/accesses';
import { t } from 'shared/utils';
import { AddLineItemButton } from 'components/AddLineItemButton/AddLineItemButton';
import Card from 'components/Card';
import I18n from 'components/I18n';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import { IGNORE_OUTSIDE_CLICK_CLASS, LineItemsOrderManager } from 'components/LineItems';
import ProductCatalogItemsSelect from 'components/ProductCatalog/ItemsGroupsSelect';
import NetGrossField from 'components/v2/Form/NetGrossField/NetGrossField';

import Table from './Table';

import styles from './ProposalDetailsSection.module.css';

class ProposalDetailsSection extends Component {
  state = {
    newItemsId: 0,
    newItems: [],
  };

  componentDidMount() {
    const { readonly, crudMode, hasLineItems } = this.props;
    const hasProperCrudMode = [NEW_MODE, IMPORT_MODE].includes(crudMode);
    if (!readonly && hasProperCrudMode && !hasLineItems) this.queueItem();
  }

  componentDidUpdate({ hasLineItems: hadLineItems }, { newItems: prevNewItems }) {
    const { hasLineItems } = this.props;
    const { newItems } = this.state;

    if (isEmpty(newItems) && !hasLineItems && (hadLineItems || !isEmpty(prevNewItems))) {
      this.queueItem();
    }
  }

  queueItem = (item = {}, prefilled = false) => {
    this.setState((prevState) => ({
      newItemsId: prevState.newItemsId + 1,
      newItems: [
        ...prevState.newItems,
        {
          markAsAccepted: prefilled,
          rowId: prevState.newItemsId,
          ...item,
          prefilled,
        },
      ],
    }));
  };

  removeItem = (id) => () => {
    const toRemoveIndex = this.state.newItems.findIndex((a) => a.rowId === id);

    this.setState({
      newItems: [
        ...this.state.newItems.slice(0, toRemoveIndex),
        ...this.state.newItems.slice(toRemoveIndex + 1),
      ],
    });
  };

  onChange = async (selectedGroup) => {
    if (!selectedGroup) return;
    this.seedLineItemsFromGroup(selectedGroup);
  };

  async seedLineItemsFromGroup(groupId) {
    const { response } = await this.props.fetchProductCatalogGroup(groupId);
    const productCatalogItems = transformProductCatalogGroupResponse(response, groupId);
    productCatalogItems.map((item) => this.queueItem(item, true));
  }

  render() {
    const {
      readonly,
      productCatalogGroupsAndItemsEnabled,
      hasLineItems,
      proposalId,
      toggleGrossNetMode,
      lineItems,
      reorderLineItems,
      crudMode,
      defaultCategory,
      selectedCategory,
      lineCategories,
      taxRates,
      taxRateId,
      defaultVat,
      isInitial,
      setLineItemState,
      setShowDifferentCategoryWarning,
    } = this.props;
    const netGrossSwitchDisabled = readonly || hasLineItems;

    return (
      <Card className={cx(styles.main, { [styles.readonly]: readonly })}>
        <Card.Header className={styles.heading}>
          <div className={styles.headingNameContainer}>
            <I18n className={styles.title} t="proposals.form.details_section.heading" />
            <Field
              name="insertedAsGross"
              component={NetGrossField}
              disabled={netGrossSwitchDisabled}
              className={cx(styles.grossNetSwitch, IGNORE_OUTSIDE_CLICK_CLASS)}
              onChange={() => {
                toggleGrossNetMode(proposalId);
              }}
            />
            {netGrossSwitchDisabled && (
              <InfoIcon
                tooltipPlacement="top"
                message={t('forms.tooltips.net_gross_switch_disabled')}
              />
            )}
          </div>
          <div className={styles.headingItemsContainer}>
            {productCatalogGroupsAndItemsEnabled && (
              <Field
                name="selectItem"
                className={styles.selectItem}
                dataId="selectItem"
                label={t('product_catalog.product_catalog_group.select_product_catalog_group')}
                component={ProductCatalogItemsSelect}
                visible={!readonly}
                changeHandler={this.onChange}
              />
            )}
          </div>
        </Card.Header>
        <Card.Body className={styles.cardBody}>
          <LineItemsOrderManager
            invoiceId={proposalId}
            lineItems={lineItems}
            updateLineItemsOrder={reorderLineItems}
          >
            <Table
              newItems={this.state.newItems}
              lineItems={lineItems}
              removeNewItem={this.removeItem}
              crudMode={crudMode}
              readonly={readonly}
              defaultCategory={defaultCategory}
              selectedCategory={selectedCategory}
              lineCategories={lineCategories}
              taxRates={taxRates}
              taxRateId={taxRateId}
              defaultVat={defaultVat}
              isInitial={isInitial}
              setLineItemState={setLineItemState}
              setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
            />
          </LineItemsOrderManager>
          <div className={styles.actionsContainer}>
            {!readonly && <AddLineItemButton onClick={() => this.queueItem()} />}
          </div>
        </Card.Body>
      </Card>
    );
  }
}

ProposalDetailsSection.propTypes = {
  readonly: PropTypes.bool,
  fetchProductCatalogGroup: PropTypes.func.isRequired,
  reorderLineItems: PropTypes.func.isRequired,
  productCatalogGroupsAndItemsEnabled: PropTypes.bool,
  hasLineItems: PropTypes.bool,
  proposalId: PropTypes.string,
  toggleGrossNetMode: PropTypes.func,
  crudMode: PropTypes.string,
  lineItems: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
  proposalId: state.proposal.details.id,
  productCatalogGroupsAndItemsEnabled: productCatalogGroupsAndItemsEnabledHelper(state),
  hasLineItems: proposalHasLineItems(state),
  lineItems: state.proposal.lineItems,
});

const mapDispatchToProps = (dispatch) => ({
  toggleGrossNetMode: (id) => dispatch(toggleProposalGrossNet(id)),
  fetchProductCatalogGroup: (arg) => dispatch(fetchProductCatalogGroup(arg)),
  reorderLineItems: (...args) => dispatch(reorderLineItemsAction(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProposalDetailsSection);
