import { connect } from 'react-redux';

import { quickCreateClient } from 'actions/outgoing-invoice';
import ClientSection from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/CreateInvoiceSection/Client';
import { RootState } from 'store';
import { FORM_NAME } from 'features/contracts/constants';
import { getContractsClient } from 'features/contracts/formSelectors';

const mapStateToProps = (state: RootState) => ({
  client: getContractsClient(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  onSubmitClient: (values: any) => quickCreateClient(FORM_NAME)(values)(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientSection);
