import styled from 'styled-components';

import media from 'shared/styles/breakpoints';
import CardViewComponent from 'components/CardView';
import BaseIconDownload from 'components/Icons/IconDownload';
import BaseIconLock from 'components/Icons/IconLock';
import { Breakpoints } from 'redesign/styles/breakpoints';

export const Header = styled.h1`
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
`;

export const CardView = styled(CardViewComponent)`
  padding: 30px 20px 48px;
`;

type GrossAmountProps = {
  isNegative?: boolean;
};

export const GrossAmount = styled.span<GrossAmountProps>`
  color: ${(props) =>
    props.isNegative ? props.theme.colors.red['125'] : props.theme.colors.green['125']};
`;

export const TableWrapper = styled.div`
  [data-id='header-empty'] {
    width: 180px;
  }

  [data-id='header-actions'] {
    width: 120px;
  }

  [data-deleted='true'] {
    td {
      position: relative;
    }

    td:not(:last-child):not([data-id='cell-empty']):before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.grey['080']};
      margin-left: -5px;
    }
  }
`;

export const IconDownload = styled(BaseIconDownload)`
  color: ${(props) => props.theme.legacy.colors.brandBlue};
  height: 18px;
`;

export const Button = styled.button`
  padding: 5px 8px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.legacy.colors.brandBlue};
  color: ${(props) => props.theme.colors.white};
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
`;

export const AddCashTransactionButton = styled(Button)`
  display: block;
  margin-left: auto;
`;

export const EmptyTableText = styled.div`
  color: ${(props) => props.theme.colors.grey['050']};
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  max-width: 270px;
  margin: 25px auto 30px;
`;

export const EmptyStateButton = styled(Button)`
  display: block;
  margin: 0 auto;
`;

export const HeaderWrapper = styled.div`
  display: grid;
  margin-bottom: 21px;
  grid-auto-columns: 100%;
  grid-row-gap: 30px;

  ${media.greaterThan('md-up')`
    grid-template-columns: auto 341px;
  `}
`;

export const SummaryWrapper = styled.div`
  margin-bottom: 53px;
`;

export const CommentWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const ActionCell = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 10px;
  }
`;

export const ActionButton = styled.button`
  padding: 0;
  font: inherit;
  outline: inherit;
  border: none;
  color: inherit;
  background: none;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.legacy.colors.brandBlue};
  }
`;

export const CommentTooltip = styled.div`
  display: grid;
  grid-row-gap: 20px;
`;

export const CommentHeader = styled.div`
  display: block;
  font-size: 10px;
  line-height: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  @media (max-width: ${Breakpoints.mdMax}) {
    margin-bottom: ${({ theme }) => theme.space['1']};
  }
`;

export const CommentBody = styled.div`
  font-size: 10px;
  line-height: 16px;
  word-break: break-all;
`;

export const IconLock = styled(BaseIconLock)`
  height: 14px;
  color: ${({ theme }) => theme.colors.grey['070']};
  margin-bottom: 3px;
`;

export const ExportButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  align-items: flex-end;

  > *:not(:last-child) {
    margin-bottom: 15px;
  }

  ${media.greaterThan('md-up')`
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 16px;
      margin-bottom: 0;
    }
  `}
`;

export const IdNumber = styled.div`
  text-align: center;
`;
