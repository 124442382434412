export const InvoiceStatuses = {
  ALL: 'all',
  OPEN: 'open',
  PARTLY_PAID: 'partly_paid',
  PAID: 'paid',
  OVERDUE: 'overdue',
  PAYMENT_REMINDED: 'payment_reminded',
  CANCELLED: 'cancelled',
  DRAFT: 'draft',
  SENT: 'sent',
  APP_UPLOAD: 'appupload',
  SUGGESTED: 'suggested',
  NEW: 'internal_subscription_fee',
  HAS_FILE: 'has_file',
  NO_FILE: 'no_file',
  PART_PAYMENT_INITIATED: 'part_payment_initiated',
  FULL_PAYMENT_INITIATED: 'full_payment_initiated',
};

export const BankTransferStatuses = {
  FULLY_ASSIGNED: 'fully-assigned',
  PARTLY_ASSIGNED: 'partly-assigned',
  UNASSIGNED: 'unassigned',
};

export const MessageStatuses = {
  UNREAD: 'unread',
  READ: 'read',
};

export const InvoiceAmendmentTypes = {
  CANCELLATION: 'cancellation',
};

export const DatevTransfersStatuses = {
  VALID: 'datev-transfers-valid',
  INVALID: 'datev-transfers-invalid',
};
