import React from 'react';
import { useHistory } from 'react-router-dom';

import paths from 'routes/paths';
import { t } from 'shared/utils';
import Button from 'components/Button';
import CardView, { HeadingSection, Section } from 'components/CardView';
import IconDone from 'components/Icons/IconDone';

import sharedStyles from '../../../pages/Shared.module.css';
import styles from './Unsubscribe.module.css';

const Unsubscribe = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push(paths.login);
  };

  return (
    <div className={sharedStyles.pageWrapper}>
      <div className={sharedStyles.wrapper}>
        <CardView className={sharedStyles.card}>
          <HeadingSection>
            <h1 className={styles.title} data-id="title">
              {t('email_notifications.unsubscribe.title')}
            </h1>
          </HeadingSection>
          <Section className={sharedStyles.cardSection}>
            <div className={styles.content}>
              <IconDone className={styles.icon} />
              <p data-id="text">
                <strong>{t('email_notifications.unsubscribe.text_1')}</strong>
                <br />
                <br />
                {t('email_notifications.unsubscribe.text_2')}
              </p>
              <Button
                label={t('email_notifications.unsubscribe.button')}
                dataId="button"
                onClick={handleClick}
              />
            </div>
          </Section>
        </CardView>
      </div>
    </div>
  );
};

export default Unsubscribe;
