import styled, { css } from 'styled-components';

import BaseButton from 'redesign/components/atoms/Button/Button';
import BaseIconClose from 'redesign/components/atoms/Icons/Close';
import { Breakpoints } from 'redesign/styles/breakpoints';

type WrapperProps = {
  $color: 'red' | 'orange' | 'blue' | 'green';
  $hasIcon?: boolean;
  $hasDoNotShowAgain?: boolean;
  $hasButton?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: grid;
  grid-template-areas: ${({ $hasIcon, $hasDoNotShowAgain, $hasButton }) =>
    [
      'close',
      $hasIcon && 'icon',
      'text',
      $hasButton && 'button',
      $hasDoNotShowAgain && 'do-not-show-again',
    ]
      .filter((area) => !!area)
      .map((area) => `'${area}'`)
      .join(' ')};
  align-items: center;
  background-color: ${({ $color, theme }) =>
    $color === 'red'
      ? theme.colors.red['100']
      : $color === 'orange'
      ? theme.colors.vrorange['100']
      : $color === 'blue'
      ? theme.colors.vrblue['100']
      : $color === 'green'
      ? theme.colors.green['125']
      : ''};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.space['4']} ${theme.space['4']} ${theme.space['6']}`};

  @media (min-width: ${Breakpoints.sm}) {
    grid-gap: ${({ theme }) => theme.space['4']};
    padding-top: ${({ theme }) => theme.space['6']};

    ${({ $hasIcon, $hasDoNotShowAgain, $hasButton }) => {
      if ($hasIcon && $hasButton && $hasDoNotShowAgain) {
        return css`
          grid-template-areas: 'icon text text close' '. button do-not-show-again do-not-show-again';
          grid-template-columns: min-content 1fr min-content;
        `;
      } else if ($hasIcon && $hasButton) {
        return css`
          grid-template-areas: 'icon text close' '. button .';
          grid-template-columns: min-content 1fr min-content;
        `;
      } else if ($hasIcon && $hasDoNotShowAgain) {
        return css`
          grid-template-areas: 'icon text text close' '. . do-not-show-again do-not-show-again';
          grid-template-columns: min-content 1fr min-content;
        `;
      } else if ($hasButton && $hasDoNotShowAgain) {
        return css`
          grid-template-areas: 'text text close' 'button do-not-show-again do-not-show-again';
          grid-template-columns: 1fr min-content;
        `;
      } else if ($hasIcon) {
        return css`
          grid-template-areas: 'icon text close';
          grid-template-columns: min-content 1fr min-content;
        `;
      } else if ($hasButton) {
        return css`
          grid-template-areas: 'text close' 'button .';
          grid-template-columns: 1fr min-content;
        `;
      } else if ($hasDoNotShowAgain) {
        return css`
          grid-template-areas: 'text text close' '. do-not-show-again do-not-show-again';
          grid-template-columns: 1fr min-content;
        `;
      } else {
        return css`
          grid-template-areas: 'text close';
          grid-template-columns: 1fr min-content;
        `;
      }
    }}
  }

  @media (min-width: ${Breakpoints.lg}) {
    min-height: 65px;
    padding-top: ${({ theme }) => theme.space['3']};
    padding-bottom: ${({ theme }) => theme.space['3']};

    ${({ $hasIcon, $hasDoNotShowAgain, $hasButton }) => {
      const areas = `'${$hasIcon ? 'icon ' : ''}text ${$hasButton ? 'button ' : ''}${
        $hasDoNotShowAgain ? 'do-not-show-again ' : ''
      }close'`;
      const columns = `${$hasIcon ? 'min-content ' : ''}auto ${$hasButton ? '1fr ' : ''}${
        $hasDoNotShowAgain ? 'min-content ' : ''
      }min-content`;

      return css`
        grid-template-areas: ${areas};
        grid-template-columns: ${columns};
      `;
    }}
  }
`;

export const Icon = styled.svg`
  width: 32px;
  grid-area: icon;
  justify-self: center;
  align-self: start;
  margin-bottom: ${({ theme }) => theme.space['2']};

  @media (min-width: ${Breakpoints.sm}) {
    margin-bottom: 0;
  }

  @media (min-width: ${Breakpoints.lg}) {
    align-self: auto;
  }
`;

export const Text = styled.p`
  grid-area: text;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.sm};
  line-height: 19px;
  margin-bottom: 0;

  strong {
    display: block;
  }

  @media (min-width: ${Breakpoints.sm}) {
    font-size: ${({ theme }) => theme.fontSize.base};
    line-height: 22px;
    text-align: left;
  }

  @media (min-width: ${Breakpoints.lg}) {
    strong {
      display: inline;
    }
  }
`;

export const IconClose = styled(BaseIconClose)`
  grid-area: close;
  justify-self: end;
  align-self: start;
  margin-top: ${({ theme }) => `-${theme.space['2']}`};
  margin-right: ${({ theme }) => `-${theme.space['2']}`};
  cursor: pointer;

  @media (min-width: ${Breakpoints.lg}) {
    margin-top: 0;
    align-self: auto;
  }
`;

export const Button = styled(BaseButton)`
  grid-area: button;
  justify-self: center;
  margin-top: ${({ theme }) => theme.space['4']};

  @media (min-width: ${Breakpoints.sm}) {
    justify-self: start;
    margin-top: 0;
  }
`;

export const DoNotShowAgain = styled.button`
  background: none;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: bold;
  letter-spacing: 0.58px;
  line-height: 17px;
  text-decoration: underline;
  justify-self: center;
  margin-top: ${({ theme }) => theme.space['4']};
  grid-area: do-not-show-again;
  white-space: nowrap;
  padding: 0;
  align-self: center;

  &:hover {
    text-decoration: none;
  }

  @media (min-width: ${Breakpoints.sm}) {
    font-size: ${({ theme }) => theme.fontSize.base};
    justify-self: end;
    margin-top: 0;
  }

  @media (min-width: ${Breakpoints.lg}) {
    margin-right: ${({ theme }) => theme.space['4']};
  }
`;
