import React, { ComponentPropsWithoutRef, forwardRef } from 'react';

import { Box, Wrapper } from './ModalContent.styled';

export type ModalContentProps = ComponentPropsWithoutRef<'div'> & {
  size?: 'small' | 'medium' | 'large' | 'full-screen';
};

const ModalContent = forwardRef<HTMLDivElement, ModalContentProps>(
  ({ size = 'medium', children, ...rest }, ref) => {
    return (
      <Wrapper ref={ref} {...rest}>
        <Box $size={size}>{children}</Box>
      </Wrapper>
    );
  }
);

ModalContent.displayName = 'ModalContent';

export default ModalContent;
