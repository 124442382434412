import { goBack, replace } from 'connected-react-router';

import { paginationParams, sortingParams } from 'actions/helpers/table';
import { CALL_API } from 'constants/api';
import {
  CLEANUP_BEFORE_REDIRECT,
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DE_CLIENT_CREATION,
  DE_CLIENT_MANAGEMENT,
  DE_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SORT,
  INDEX_SUCCESS,
  NAMESPACE,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/clients';
import { CLEAR_FILTERS, SET_QUERY_PARAM } from 'constants/common/filters';
import { Resources } from 'constants/resources';
import paths from 'routes/paths';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { piwikHelpers } from 'shared/utils/piwik';
import { bindServerValidation } from 'shared/utils/server-validation';

export const apiIndex = (pagination = {}, sorting = {}, filters = {}) => ({
  [CALL_API]: {
    params: {
      ...paginationParams({ pagination_resource: Resources.CLIENTS, ...pagination }),
      ...sortingParams(sorting),
      filters,
    },
    endpoint: '/me/clients',
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
  },
});

export const apiFetchAllClients = (pagination = {}, sorting = {}, filters = {}) => ({
  [CALL_API]: {
    params: {
      ...paginationParams({ pagination_resource: Resources.INVOICE_CLIENTS, ...pagination }),
      ...sortingParams(sorting),
      filters,
    },
    endpoint: '/me/clients',
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
  },
});

const apiFetchClient = (client) => ({
  [CALL_API]: {
    endpoint: `/me/clients/${client}`,
    method: 'GET',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiCreateClient = (data) => ({
  [CALL_API]: {
    data,
    endpoint: '/me/clients',
    method: 'POST',
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const apiUpdateClient = (id, data, updateOnlyDeliveryData) => ({
  [CALL_API]: {
    params: { updateOnlyDeliveryData },
    data,
    endpoint: `/me/clients/${id}`,
    method: 'PUT',
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

const apiDelete = (client) => ({
  [CALL_API]: {
    endpoint: `/me/clients/${client.id}`,
    method: 'DELETE',
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

const cleanupBeforeRedirect = () => ({ type: CLEANUP_BEFORE_REDIRECT });

export const trackCreatingClient = () => {
  piwikHelpers.trackEvent(DE_CLIENT_MANAGEMENT, DE_CLIENT_CREATION, DE_SUCCESS);
};

export const sortClients = (column) => (dispatch) =>
  dispatch({
    type: INDEX_SORT,
    column,
  });

export const fetchClients = (pagination, sorting, filters) => (dispatch) =>
  dispatch(apiIndex(pagination, sorting, filters)).catch(apiErrorHandler);

export const deleteClient = (client) => (dispatch) =>
  dispatch(apiDelete(client)).catch(apiErrorHandler);

export const fetchClient =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchClient(...args)).catch((err) => {
      apiErrorHandler(err);
      dispatch(replace(paths.clients));
    });

export const createClient = (data) => (dispatch) =>
  bindServerValidation(apiCreateClient(data), dispatch).then(() => {
    trackCreatingClient();
    dispatch(cleanupBeforeRedirect());
    dispatch(goBack());
  });

export const updateClient =
  (id, data, { redirectAfter = true } = {}, updateOnlyDeliveryData = false) =>
  (dispatch) =>
    bindServerValidation(apiUpdateClient(id, data, updateOnlyDeliveryData), dispatch).then(
      (response) => (redirectAfter ? dispatch(replace(paths.clients)) : response)
    );

export const setQueryParam = (param) => (dispatch) => (value) =>
  dispatch({
    type: SET_QUERY_PARAM,
    name: NAMESPACE,
    payload: {
      param,
      value,
    },
  });

export const clearFilters = ({ clearStatus = false } = {}) => ({
  type: CLEAR_FILTERS,
  name: NAMESPACE,
  payload: {
    clearStatus,
  },
});
