import { head } from 'lodash';
import { actionTypes } from 'redux-form';

import { SET_CURSOR_POSITION } from 'constants/number-ranges';

const initialState = {
  field: null,
  form: null,
  cursor: null,
  dirty: false,
};

export default (state = initialState, action) => {
  /*
    Since we're reinitializing every time the async response arrives,
    we need to handle dirty/pristine states manually, because a shallow
    compare from redux-form will always return isPristine: true
  */
  const getFormNameFromObject = action.meta ? action.meta.form : '';

  const formName =
    typeof getFormNameFromObject === 'string' ? getFormNameFromObject : head(getFormNameFromObject);

  if (formName === 'numberRanges') {
    if (action.type === actionTypes.CHANGE) {
      return { ...state, dirty: true };
    }
    const actions = [actionTypes.DESTROY, actionTypes.RESET, actionTypes.SET_SUBMIT_SUCCEEDED];
    if (actions.includes(action.type)) {
      return { initialState };
    }
  }
  switch (action.type) {
    case actionTypes.FOCUS:
      return { ...state, ...action.meta };
    case SET_CURSOR_POSITION:
      return { ...state, cursor: action.payload };
    default:
      return state;
  }
};
