import styled from 'styled-components';

import media from 'shared/styles/breakpoints';
import { visibleScrollbar } from 'shared/styles/Common.styled';
import { Breakpoints } from 'redesign/styles/breakpoints';

export const Wrapper = styled.div`
  position: relative;

  table {
    width: 100%;
    border: 0;

    ${media.greaterThan('xxlg-up')`
      table-layout: fixed;
    `}

    thead {
      background-color: #f0f0f0;

      tr {
        font-size: 11px;
        color: #4d4e4c;

        th {
          height: ${({ theme }) => theme.space[10]};
          padding: 10px;
          user-select: none;
          white-space: nowrap;
        }
      }
    }

    tbody {
      font-size: 13px;
      color: #777;

      tr {
        &:nth-child(even) {
          background-color: #f8f8f8 !important;
        }

        &:last-child {
          border-bottom: 1px solid #f0f0f0;
        }

        td {
          height: ${({ theme }) => theme.space[16]};
          padding: 10px;

          ${media.greaterThan('xxlg-up')`
            height: ${({ theme }) => theme.space[10]};
          `}
        }
      }
    }
  }
`;

export const Responsive = styled.div`
  @media (max-width: ${Breakpoints.mdMax}) {
    width: 100%;
    overflow-x: auto;

    ${visibleScrollbar}
  }
`;
