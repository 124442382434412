import React, { useState } from 'react';

import { t } from 'shared/utils';

import * as SC from './DatevInformationDropdown.styled';
import InfoSection from './Infosection';
import { allItems } from './utils';

export const DatevInformationDropdown = () => {
  const [showCollabsable, setShowCollapsable] = useState<boolean>(false);

  return (
    <SC.Container>
      <SC.HeaderContainer onClick={() => setShowCollapsable((prevState) => !prevState)}>
        <SC.TipIconContainer>
          <SC.TipIcon />
        </SC.TipIconContainer>
        <SC.Header>
          <SC.HeaderMain>{t('datev.validation.information_label')}</SC.HeaderMain>
          <SC.HeaderSupport>{t('datev.validation.information_supporting_text')}</SC.HeaderSupport>
        </SC.Header>
        <SC.IconContainer>
          <SC.IconArrowDown $isOpen={showCollabsable} />
        </SC.IconContainer>
      </SC.HeaderContainer>
      {showCollabsable && (
        <>
          <SC.InfoHeadline>{t('datev.validation.information_section.header')}</SC.InfoHeadline>
          <SC.CollapsableSection>
            {allItems.map((items, index) => {
              const hasBorder = !!items.find((item) => item.withTopBorder);

              return <InfoSection items={items} key={index} withTopBorder={hasBorder} />;
            })}
          </SC.CollapsableSection>
        </>
      )}
    </SC.Container>
  );
};

export default DatevInformationDropdown;
