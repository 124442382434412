import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Push, push as pushAction } from 'connected-react-router';

import { fetchProfile as fetchProfileAction } from 'actions/profile';
import {
  CATEGORY_NOTIFICATIONS_SIDEBAR,
  GOAL_CLICK_TODO_TASK_ACTION_BUTTON,
} from 'constants/piwik';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import { Dispatch } from 'types/actions';

import CreateCustomTaskButton from './CreateCustomTaskButton';
import CustomTaskCreator from './CustomTaskCreator/CustomTaskCreator';
import DoneList from './DoneList/DoneList';
import EmptyState from './EmptyState/EmptyState';
import { getIsFetched } from './selectors';
import TodoList from './TodoList/TodoList';
import type { FeaturesStore } from './TodoList/types';
import { countTasks } from './utils';

import styles from './Todos.module.css';

type TodosProps = {
  openFeatures: FeaturesStore;
  doneFeatures: FeaturesStore;
  push: Push;
  doneTasks: boolean;
  fetchProfile: () => void;
};

const Todos = ({ openFeatures, doneFeatures, push, fetchProfile }: TodosProps) => {
  const taskAction = (eventId: string) => (action: Dispatch) => (params: any) => {
    piwikHelpers.trackGoal(GOAL_CLICK_TODO_TASK_ACTION_BUTTON);
    piwikHelpers.trackEvent(CATEGORY_NOTIFICATIONS_SIDEBAR, eventId);
    action(params);
  };
  const isFetched = useSelector(getIsFetched);
  const openFeaturesTasks = countTasks(openFeatures);
  const hasNoTasks = openFeaturesTasks === 0 && countTasks(doneFeatures) === 0;

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  if (!isFetched) return null;

  if (hasNoTasks)
    return (
      <CustomTaskCreator>
        <div className={styles.emptyStateWrapper}>
          <EmptyState />
          <CreateCustomTaskButton
            label={t('sidebar.tasks.new_task.add.empty')}
            className={styles.emptyStateNewTask}
          />
        </div>
      </CustomTaskCreator>
    );

  return (
    <CustomTaskCreator>
      <div className={styles.container}>
        <div className={styles.newTaskWrapper}>
          <CreateCustomTaskButton
            label={t('sidebar.tasks.new_task.add.filled')}
            className={styles.newTask}
          />
        </div>
        <TodoList push={push} taskAction={taskAction} featuresStore={openFeatures} />
        <DoneList featuresStore={doneFeatures} openFeaturesTasks={openFeaturesTasks} />
      </div>
    </CustomTaskCreator>
  );
};

const mapStateToProps = (state: any) => ({
  openFeatures: state.customerNotifications.openFeatures,
  doneFeatures: state.customerNotifications.doneFeatures,
  doneTasks: state.profile.credentials.hasCustomerTasks,
});

const mapDispatchToProps = {
  push: pushAction,
  fetchProfile: fetchProfileAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Todos);
