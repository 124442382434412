import { combineReducers } from 'redux';

import {
  ActionTypes,
  BANK_ACCOUNTS_SUCCESS,
  CLEAR_SINGLE_TEMPLATE,
  DELETE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SUCCESS,
  RESET_TEMPLATE_SUCCESS,
  UPDATE_SUCCESS,
} from 'constants/invoice-templates';

const isFetching = (state = false, action: ActionTypes) => {
  switch (action.type) {
    case INDEX_REQUEST:
    case FETCH_REQUEST:
      return true;
    case FETCH_SUCCESS:
    case INDEX_SUCCESS:
    case INDEX_FAILURE:
    case FETCH_FAILURE:
      return false;
    default:
      return state;
  }
};

const mapInvoiceTemplate = (data: any) => ({
  ...data.attributes,
  logo: data.attributes.logo,
  preview: data.attributes.previewUrl,
});

const data = (state: any[] = [], action: ActionTypes) => {
  switch (action.type) {
    case INDEX_SUCCESS:
      return action.response.data.map(mapInvoiceTemplate);
    case UPDATE_SUCCESS:
      return state.map((t) => {
        if (t.id !== action.response.data.id) return t;
        return mapInvoiceTemplate(action.response.data);
      });
    case DELETE_SUCCESS:
      return state.filter((e) => e.id !== action.response.data.id);
    default:
      return state;
  }
};

const bankAccounts = (state: any[] = [], action: ActionTypes) => {
  switch (action.type) {
    case BANK_ACCOUNTS_SUCCESS:
      return action.response.data.map((data) => data.attributes);
    case DELETE_SUCCESS:
      return state.filter((e) => e.id !== action.response.data.id);
    default:
      return state;
  }
};

const templateDefaultState = {};

const template = (state = templateDefaultState, action: any) => {
  switch (action.type) {
    case FETCH_SUCCESS:
    case UPDATE_SUCCESS:
    case RESET_TEMPLATE_SUCCESS:
      return mapInvoiceTemplate(action.response.data);
    case CLEAR_SINGLE_TEMPLATE:
      return templateDefaultState;
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  data,
  template,
  bankAccounts,
});
