import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { snakeCase } from 'lodash';
import { bool, node, number, shape, string } from 'prop-types';
import { Field } from 'redux-form';

import honorifics from 'constants/honorifics';
import { shape as countriesShape } from 'reducers/countries';
import { supplierCreatorSelector } from 'reducers/form';
import { withCountries } from 'shared/hoc';
import { noop, t } from 'shared/utils';
import { checkLength, serverValidationChecker as checker } from 'shared/utils/form-checking';
import Card from 'components/Card';
import { SelectField, TextareaField, TextField } from 'components/Form';
import Counter from 'components/Form/Counter';
import Section, { SectionRow } from 'components/Form/Section/Section';
import I18n from 'components/I18n';
import MaskedField from 'components/v2/Form/MaskedField/MaskedField';

import styles from './CreateSupplierSection.module.css';

const honorificsOptions = honorifics.map((h) => ({ value: h, label: h }));
const MAX_NOTES_LENGTH = 400;

const ClientSelectField = (props) => {
  const { input } = props;
  return <SelectField {...props} input={input} onBlur={noop} searchable />;
};

ClientSelectField.propTypes = {
  input: shape({}).isRequired,
};

const FormField = ({
  name,
  label,
  placeholder,
  readonly,
  children,
  hint,
  hidePlaceholder,
  ...rest
}) => (
  <div className={styles.formGroup}>
    {children}
    {hint}
    <Field
      id={name}
      name={name}
      disabled={readonly}
      label={hint ? '' : label || t(`form.fields.${snakeCase(name)}`)}
      placeholder={hidePlaceholder ? '' : placeholder || t(`form.fields.${snakeCase(name)}`)}
      checker={checker}
      {...rest}
    />
  </div>
);

FormField.propTypes = {
  name: string.isRequired,
  label: string,
  placeholder: string,
  readonly: bool,
  children: node,
  hint: string,
  hidePlaceholder: bool,
};

const CreateSupplierSection = ({
  readonly,
  isEditing,
  countries,
  isHonorificRequired,
  isCompanyNameRequired,
  isLastNameRequired,
  notesLength,
}) => (
  <Card className={styles.main}>
    <Card.Header>
      <I18n t={isEditing ? 'suppliers.form.header.edit' : 'suppliers.form.header.new'} />
    </Card.Header>
    <Card.Body>
      <div className={cx(styles.columns, styles.columnsPadded)}>
        <div className={styles.column}>
          <Section title={t('suppliers.form.sections.suppliers_data')}>
            <SectionRow>
              <FormField
                name="honorific"
                component={SelectField}
                clearable
                options={honorificsOptions}
                placeholder={t('forms.placeholders.select')}
                required={isHonorificRequired}
                readonly={readonly}
                dataId="honorific"
              />
              <FormField
                name="firstName"
                component={TextField}
                readonly={readonly}
                dataId="first-name"
              />
              <FormField
                name="lastName"
                component={TextField}
                required={isLastNameRequired}
                readonly={readonly}
                dataId="last-name"
              />
            </SectionRow>
            <SectionRow>
              <FormField
                name="companyName"
                component={TextField}
                required={isCompanyNameRequired}
                readonly={readonly}
                dataId="company-name"
              />
              <FormField
                name="addressAddition"
                component={TextField}
                placeholder={t('form.fields.placeholders.address_addition')}
                readonly={readonly}
                dataId="address-addition"
              />
              <FormField name="email" component={TextField} readonly={readonly} dataId="email" />
              <FormField
                name="website"
                component={TextField}
                readonly={readonly}
                dataId="website"
              />
            </SectionRow>
            <SectionRow>
              <FormField
                name="phoneNumber"
                component={TextField}
                readonly={readonly}
                dataId="phone-number"
              />
              <FormField
                name="faxNumber"
                component={TextField}
                readonly={readonly}
                dataId="fax-number"
              />
              <FormField
                name="taxNumber"
                component={TextField}
                readonly={readonly}
                dataId="tax-number"
              />
              <FormField
                name="idNumber"
                label={t('form.fields.supplier_number')}
                placeholder={t('form.fields.supplier_number')}
                component={TextField}
                readonly
                disabledPlaceHolder={t('revenue.form.number_disabled')}
                dataId="id-number"
              />
            </SectionRow>
          </Section>
          <Section title={t('suppliers.form.sections.postal_address')}>
            <SectionRow>
              <FormField
                name="street"
                component={TextField}
                readonly={readonly}
                required
                dataId="street"
              />
              <FormField
                name="zipcode"
                component={TextField}
                maxLength="15"
                readonly={readonly}
                required
                dataId="zipcode"
              />
              <FormField
                name="city"
                component={TextField}
                readonly={readonly}
                required
                dataId="city"
              />
              <FormField
                name="country"
                component={ClientSelectField}
                options={countries.data}
                isLoading={countries.isFetching}
                readonly={readonly}
                required
                dataId="country"
              />
            </SectionRow>
          </Section>
          <Section title={t('suppliers.form.sections.bank_data')}>
            <SectionRow>
              <FormField
                name="bankName"
                component={TextField}
                readonly={readonly}
                dataId="bank-name"
              />
              <FormField
                name="iban"
                component={MaskedField}
                readonly={readonly}
                dataId="iban"
                mask="aa** **** **** **** **** **** **** **** **"
                maskChar=" "
              />
              <FormField name="bic" component={TextField} readonly={readonly} dataId="bic" />
            </SectionRow>
          </Section>
          <Section title={t('suppliers.form.sections.other')}>
            <SectionRow className={styles.longField}>
              <FormField
                name="notes"
                component={TextareaField}
                readonly={readonly}
                maxLength={MAX_NOTES_LENGTH}
                hidePlaceholder
                hint={
                  <span className={styles.hint}>
                    {t('clients.form.sections.notes_description')}
                  </span>
                }
                dataId="textarea-notes"
              >
                <div className={styles.notes}>
                  {t('clients.form.sections.notes_header')}
                  <Counter value={notesLength} max={MAX_NOTES_LENGTH} />
                </div>
              </FormField>
            </SectionRow>
          </Section>
        </div>
      </div>
    </Card.Body>
  </Card>
);

CreateSupplierSection.propTypes = {
  readonly: bool,
  isEditing: bool,
  isHonorificRequired: bool,
  isLastNameRequired: bool,
  isCompanyNameRequired: bool,
  notesLength: number,
  countries: countriesShape,
};

export default connect((s) => ({
  isHonorificRequired: supplierCreatorSelector(s, 'lastName'),
  isLastNameRequired: !supplierCreatorSelector(s, 'companyName'),
  isCompanyNameRequired: !supplierCreatorSelector(s, 'lastName'),
  notesLength: checkLength(supplierCreatorSelector(s, 'notes')),
}))(withCountries(CreateSupplierSection));
