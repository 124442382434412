export const CHART_RED = '#DC0032';
export const DARK_RED = '#96001E';
export const FONT_RED = '#AA0028';
export const RED = '#C5002D';
export const LIGHT_RED = '#EA6684';
export const LIGHTER_RED = '#F4B3C2';

export const CHART_GREEN = '#1EA532';
export const DARK_GREEN = '#146423';
export const FONT_GREEN = '#198228';
export const GREEN = '#1E9B32';
export const LIGHT_GREEN = '#78c984';
export const LIGHTER_GREEN = '#BBE4C2';

export const GREY = '#c9c9c9';
export const DARK_GREY = '#333333';

export const LABEL_COLOR = '#6fa691';
export const PAST_DATA_COLOR = '#85c3df';
export const TICK_LABEL_COLOR = '#b6b6b6';
export const COLOR_BLUE = '#4D4E4C';
