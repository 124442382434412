enum EntityPath {
  BankAccounts = 'bankAccounts',
  InfoBanners = 'infoBanners',
  RecurringTransactionIntervals = 'recurringTransactionIntervals',
  PaginationPreferences = 'paginationPreferences',
  CustomerTasks = 'customerTasks',
  Contracts = 'contracts',
  BankTransferAssignableItems = 'bankTransferAssignableItems',
  IncomingInvoices = 'incomingInvoices',
  OutgoingInvoices = 'outgoingInvoices',
  ContractInstallments = 'contractInstallments',
  Categories = 'categories',
  OldCategories = 'invoiceLineCategories',
  Contents = 'contents',
  Cashbooks = 'cashbooks',
  CashTransactions = 'cashTransactions',
  CashTransactionFiles = 'cashTransactionFiles',
  InvoiceLineCategories = 'invoiceLineCategories',
  Clients = 'clients',
  Proposals = 'proposals',
  Suppliers = 'suppliers',
  BankTransfers = 'bankTransfers',
  Onboardings = 'onboardings',
  GlobalNotifications = 'globalNotifications',
  OutgoingInvoicesSummary = 'kpis',
  InvoiceTemplates = 'invoiceTemplates',
  CustomerWidgets = 'customerWidgets',
}

export default EntityPath;
