import React from 'react';

import customIcon from 'images/sidebar/custom.svg';
import { t } from 'shared/utils';
import { CustomTask } from 'types/entities/Task';
import SubsectionHeadline from 'components/SubsectionHeadline/SubsectionHeadline';

import DoneCustomTask from '../CustomTask/DoneTask';
import TodoFeature from '../TodoFeature/TodoFeature';
import { FeaturesStore, Tasks } from '../TodoList/types';

import styles from './DoneList.module.css';

type DoneListProps = {
  featuresStore: FeaturesStore;
  openFeaturesTasks: number;
};

const DoneList = ({ featuresStore, openFeaturesTasks }: DoneListProps) => {
  const customTasksCount = featuresStore.custom?.tasksCount;

  if (customTasksCount === 0) return null;

  return (
    <>
      <SubsectionHeadline
        title={t('sidebar.todos.subsections.completed_tasks')}
        className={openFeaturesTasks === 0 ? styles.sectionWithoutOpenFeatures : styles.section}
        showDivider={false}
      />
      <TodoFeature
        className={styles.customTile}
        feature={{
          ...featuresStore.custom,
          nameSingular: t('sidebar.tasks.header.singular'),
          namePlural: t('sidebar.tasks.header.plural', {
            tasks: customTasksCount,
          }),
          subtitleSingular: t('sidebar.tasks.subheader.done.singular'),
          subtitlePlural: t('sidebar.tasks.subheader.done.plural', {
            tasks: customTasksCount,
          }),
        }}
        featureIcon={customIcon}
        taskComponent={({ task }: Tasks) => <DoneCustomTask task={task as unknown as CustomTask} />}
      />
    </>
  );
};

export default DoneList;
