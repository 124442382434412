import React from 'react';
import PropTypes from 'prop-types';

import { formatDate, toMoment } from 'shared/utils';

import styles from './ReportsSection.module.css';

const Report = ({ index, email, createdAt }) => (
  <div className={styles.report}>
    {index}. Dieser Export wurde am {formatDate(toMoment(createdAt))} an {email} verschickt.
  </div>
);

Report.propTypes = {
  index: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};

const Reports = ({ reports }) => {
  const reversedReports = [...reports].reverse();

  return (
    <div className={styles.reports}>
      {reversedReports.map((x, i) => {
        return <Report key={x.id} index={reports.length - i} {...x} />;
      })}
    </div>
  );
};

Reports.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
    })
  ),
};

export default Reports;
