import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMedia, useUpdateEffect } from 'react-use';

import { fetchCustomerNotifications } from 'actions/customer-notifications';
import { fetchCustomerMessages } from 'actions/message-inbox';
import { fetchUserPlan } from 'actions/payment-plan';
import { messagesEnabledHelper, todoListEnabledHelper } from 'routes/accesses';
import { RootState } from 'store';
import { fetchOnboarding } from 'store/slices/onboarding/thunks';
import Loading from 'components/Loading';
import NotificationsHeader from 'components/NotificationsSidebar/NotificationsHeader/NotificationsHeader';
import NotificationsSidebar from 'components/NotificationsSidebar/NotificationsSidebar';
import GlobalNotifications from 'redesign/components/organisms/GlobalNotifications/GlobalNotifications';
import Header from 'redesign/components/organisms/Header/Header';
import Navigation from 'redesign/components/organisms/Navigation/Navigation';
import { Breakpoints } from 'redesign/styles/breakpoints';

import { Content, Main } from './Page.styled';

type PageProps = {
  children: ReactNode;
};

const Page = ({ children }: PageProps) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isMobile = useMedia(
    `(max-width: ${Breakpoints.mdMax}), not all and (any-hover: hover) and (any-pointer: fine)`
  );
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const notificationsRef = useRef(null);
  const [wereMessagesFetched, setWereMessagesFetched] = useState(false);
  const [wereNotificationsFetched, setWereNotificationsFetched] = useState(false);
  const todoListEnabled = useSelector(todoListEnabledHelper);
  const messagesEnabled = useSelector(messagesEnabledHelper);
  const rolesFetched = useSelector((state: RootState) => state.auth.rolesFetched);
  const serializedAccountCreationDate = useSelector(
    (state: RootState) => state.profile.credentials.createdAt
  );
  const isSidebarOpen = useSelector((state: RootState) => state.notificationsSidebar.isOpen);
  const isNotificationSidebarEnabled = todoListEnabled || messagesEnabled;

  const handleNavigationOpen = useCallback(() => setIsNavigationOpen(true), []);

  const handleNavigationClose = useCallback(() => setIsNavigationOpen(false), []);

  useUpdateEffect(() => {
    if (isMobile) setIsNavigationOpen(false);
  }, [pathname, isMobile]);

  useEffect(() => {
    dispatch(fetchUserPlan());
    dispatch(fetchOnboarding());
  }, [dispatch]);

  // Comment both useEffects if you want to disable activePolling of customer messages or customer Notifications
  // !!!NEVER PUSH THIS CHANGE!!! use it only locally for debugging purposes

  useEffect(() => {
    const shouldFetchInitialCustomerMessages = messagesEnabled && !wereMessagesFetched;

    if (!shouldFetchInitialCustomerMessages) return;

    dispatch(fetchCustomerMessages());
    setWereMessagesFetched(true);
  }, [dispatch, messagesEnabled, wereMessagesFetched]);

  useEffect(() => {
    const shouldFetchCustomerNotifications = todoListEnabled && !wereNotificationsFetched;

    if (!shouldFetchCustomerNotifications) return;

    dispatch(fetchCustomerNotifications());
    setWereNotificationsFetched(true);
  }, [dispatch, todoListEnabled, wereNotificationsFetched]);

  if (!rolesFetched || !serializedAccountCreationDate) return <Loading />;

  return (
    <>
      <Header onHamburgerClick={handleNavigationOpen} />
      <Main role="main">
        <aside>
          <Navigation
            isOpen={isNavigationOpen}
            pathname={pathname}
            onCloseClick={handleNavigationClose}
            onMouseEnter={!isMobile ? handleNavigationOpen : undefined}
            onMouseLeave={!isMobile ? handleNavigationClose : undefined}
          />
        </aside>
        <Content>
          <div>
            <GlobalNotifications />
          </div>
          <div>{children}</div>
        </Content>
      </Main>
      {isNotificationSidebarEnabled && (
        <div ref={notificationsRef} id="notifications">
          <NotificationsHeader />
          <NotificationsSidebar
            notificationsRef={notificationsRef}
            disableOnClickOutside={!isSidebarOpen}
          />
        </div>
      )}
    </>
  );
};

export default Page;
