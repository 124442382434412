import { isDirty } from 'redux-form';

import { fetchCompany } from 'actions/company';
import {
  clearDefaultValues as clearDefaultValuesAction,
  fetchClientDefaults as fetchClientDefaultsAction,
} from 'actions/default-values';
import {
  abortChanges,
  downloadOutgoingInvoice,
  sendOutgoingInvoice,
  updateOutgoingInvoice,
} from 'actions/outgoing-invoice';
import { EDIT_MODE } from 'constants/common/crud';
import { FORM_NAME } from 'constants/outgoing-invoice';
import {
  PIWIK_ACTION_EDIT_REVENUE_SAVE,
  PIWIK_CATEGORY_REVENUE,
  PIWIK_CONVERSION_CREATE_REVENUE,
} from 'constants/piwik';
import { outgoingInvoiceSelector } from 'reducers/form';
import { eInvoicesEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import getDiscountData from 'shared/utils/get-discount-data';
import { getOutgoingInvoiceInitialValues } from 'shared/utils/outgoing-invoice-initial-values';
import { piwikHelpers } from 'shared/utils/piwik';

export const mapStateToProps = (state, ownProps) => {
  return {
    invoiceId: state.outgoingInvoice.details.id,
    currentValues: state.form.outgoingInvoiceCreator
      ? state.form.outgoingInvoiceCreator.values
      : {},
    isSubjectDirty: isDirty(FORM_NAME)(state, 'subject'),
    invoiceDetails: state.outgoingInvoice.details,
    invoiceLineItems: state.outgoingInvoice.lineItems,
    initialValues: getOutgoingInvoiceInitialValues({
      state,
      ownProps,
    }),
    currentClient: outgoingInvoiceSelector(state, 'client'),
    individualContact: !!outgoingInvoiceSelector(state, 'salutationContent'),
    salutationHonorific: outgoingInvoiceSelector(state, 'salutationHonorific'),
    sendingDocumentsByEmailEnabled: state.profile.applicationFeatures.sendingDocumentsByEmail,
    paymentPlan: state.paymentPlan.details.name,
    lineCategories: state.outgoingInvoice.lineCategories,
    eInvoicesEnabled: eInvoicesEnabledHelper(state),
    electronicInvoiceType: outgoingInvoiceSelector(state, 'electronicInvoiceType'),
    disableTransitionPrevent:
      !state.outgoingInvoice.details.draft && ownProps.crudMode === EDIT_MODE,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  const { isFromProposal, isFromOrderConfirmation } = ownProps;

  return {
    saveInvoice: (id) => (data) => {
      piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_EDIT_REVENUE_SAVE);
      piwikHelpers.trackGoal(PIWIK_CONVERSION_CREATE_REVENUE);

      return dispatch(
        updateOutgoingInvoice(id, {
          ...data,
          ...getDiscountData(data),
          draft: false,
          isRedirectFromProposals: isFromProposal,
        })
      );
    },
    downloadInvoice: (arg) => dispatch(downloadOutgoingInvoice(arg)),
    sendInvoice: (arg) => dispatch(sendOutgoingInvoice(arg)),
    saveInvoiceAsDraft: (id) => (data) =>
      dispatch(
        updateOutgoingInvoice(id, {
          ...data,
          draft: true,
          isRedirectFromProposals: isFromProposal,
        })
      ),
    transformInvoiceIntoDeliveryNote: (id) => (data) => {
      return dispatch(
        updateOutgoingInvoice(id, {
          ...data,
          draft: false,
          transform: 'deliveryNote',
        })
      );
    },
    abort: () => {
      if (isFromProposal) {
        return dispatch(abortChanges(paths.proposals));
      } else if (isFromOrderConfirmation) {
        return dispatch(abortChanges(paths.orderConfirmations));
      }

      return dispatch(abortChanges());
    },
    fetchCompany,
    clearDefaultValues: () => dispatch(clearDefaultValuesAction()),
    fetchClientDefaults: (clientId) => dispatch(fetchClientDefaultsAction(clientId)),
  };
};
