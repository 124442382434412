import React from 'react';
import { number } from 'prop-types';

import { t } from 'shared/utils';

const DisplayConnections = ({ connectedResourcesAmount = null, connectedLabelsAmount = null }) => {
  if (connectedResourcesAmount && connectedLabelsAmount) {
    const connectionsAmount = connectedResourcesAmount + connectedLabelsAmount;
    return (
      <span>
        {t(`bank_transfers.table.connection`, {
          count: connectionsAmount,
        })}
      </span>
    );
  }
  if (connectedResourcesAmount > 0 && !connectedLabelsAmount)
    return (
      <span>
        {t(`bank_transfers.table.connected_resource`, {
          count: connectedResourcesAmount,
        })}
      </span>
    );
  return (
    <span>
      {t(`bank_transfers.table.connected_label`, {
        count: connectedLabelsAmount,
      })}
    </span>
  );
};

DisplayConnections.propTypes = {
  connectedResourcesAmount: number,
  connectedLabelsAmount: number,
};

export default DisplayConnections;
