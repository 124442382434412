import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from 'constants/auth';
import getImpersonateToken from 'shared/utils/impersonateToken';
import { store } from 'store';

/**
 * If 'impersonate' token exist it means that admin user wants to login as a
 * different user to the application.
 */
export const onBeforeLift = () => {
  const token = getImpersonateToken();

  if (token) {
    store.dispatch({ type: LOGOUT_SUCCESS });
    store.dispatch({ type: LOGIN_SUCCESS, response: { access_token: token } });
  }
};
