import { css } from 'styled-components';

export const focus = css`
  outline: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.vrblue['100']};
  box-shadow: 2px 2px 5px 0 rgba(15, 35, 100, 0.4);
`;

export const hover = css`
  box-shadow: 1px 1px 2px 0 rgba(15, 35, 100, 0.4);
`;

export const placeholder = css`
  color: ${({ theme }) => theme.colors.grey['070']};
`;

export const disabled = css`
  color: ${({ theme }) => theme.colors.grey['040']};
  border-color: ${({ theme }) => theme.colors.grey['040']};
  cursor: not-allowed;
`;

export const disabledPlaceholder = css`
  color: ${({ theme }) => theme.colors.grey['040']};
`;

type TextInputProps = {
  $isActive?: boolean;
};

const TextInput = css<TextInputProps>`
  width: 100%;
  padding: 0 ${({ theme }) => theme.space['2']};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey['070']};
  border-radius: 3px;
  font-size: ${({ theme }) => theme.fontSize['base']};
  color: ${({ theme }) => theme.colors.grey['080']};
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  letter-spacing: 0.66px;

  &::placeholder {
    ${placeholder}
  }

  &:hover {
    ${hover}
  }

  &:focus {
    ${focus}
  }

  &:disabled {
    ${disabled}

    &::placeholder {
      ${disabledPlaceholder}
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      &,
      &:hover {
        ${focus}
      }
    `}
`;

export default TextInput;
