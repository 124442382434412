import React, { memo } from 'react';

import { BANK_ACCOUNTS_TILE_ADD_ACCOUNT } from 'constants/piwik';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import PictureBankAccounts from 'redesign/components/atoms/Pictures/BankAccounts';
import TileEmpty from 'redesign/components/molecules/TileEmpty/TileEmpty';

import { trackEvent } from './utils';

interface IEmpty {
  isLoading: boolean;
}

const Empty = ({ isLoading }: IEmpty) => {
  const trackRedirectionToBankAccounts = () => trackEvent(BANK_ACCOUNTS_TILE_ADD_ACCOUNT);

  return (
    <TileEmpty
      dataId="bank-accounts-tile:EmptyStateTile"
      image={<PictureBankAccounts width={241} />}
      title={t('features.homepage.bank_accounts_tile.title')}
      caption={t('features.homepage.bank_accounts_tile.empty.title')}
      info={t('features.homepage.bank_accounts_tile.empty.description')}
      isLoading={isLoading}
      buttons={[
        {
          dataId: 'bank-accounts-tile:EmptyStateTile-buttonBankAccounts',
          text: t('features.homepage.bank_accounts_tile.empty.button'),
          to: paths.bankAccounts,
          action: trackRedirectionToBankAccounts,
        },
      ]}
    />
  );
};

export default memo(Empty);
