import { trackPageEnterForLoggedOutUser } from 'shared/utils/gtag';
import historyObserver from 'shared/utils/historyObserver';
import { scrollTop } from 'shared/utils/scroll';

const handleRouterListeners = (history, store) => {
  const historyObserverBuilder = historyObserver(history);
  const scrollTopListener = () => historyObserverBuilder(scrollTop);
  const gtagListener = () =>
    historyObserverBuilder(() => trackPageEnterForLoggedOutUser(store.getState()), {
      ignoreFirstPageEntry: true,
    });

  scrollTopListener();
  gtagListener();
};

export default handleRouterListeners;
