import React from 'react';
import PropTypes from 'prop-types';

import I18n from 'components/I18n';
import { ConfirmationModal } from 'components/Modal';

const SimpleConfirmationModal = ({ isOpen, onClose, onConfirm }) => (
  <ConfirmationModal dangerousAction isOpen={isOpen} onClose={onClose} onConfirm={onConfirm}>
    <I18n t="product_catalog.product_catalog_items.delete_confirmation_message" />
  </ConfirmationModal>
);

SimpleConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default SimpleConfirmationModal;
