import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { t } from 'shared/utils';

import type { DownloadFilePreview, PreviewFile, SetIsPreviewRendering } from '../types';
import generatePreview, { pdfPreview } from './generatePreview';
import PreviewIMG from './PreviewIMG/PreviewIMG';
import PreviewPDF from './PreviewPDF/PreviewPDF';

import styles from './Preview.module.css';

const supportedPdfExtensions = ['pdf'];
const supportedImgExtensions = ['jpg', 'png'];
const unavailableExtensions = ['tif', 'tiff'];

type PreviewProps = {
  downloadFilePreview: DownloadFilePreview;
  onClick: () => void;
  setIsPreviewRendering: SetIsPreviewRendering;
  contractId: string;
  file?: PreviewFile;
  imageClassName: string;
  isPreviewRendering: boolean;
  isFullscreen: boolean;
};

const Preview = ({
  file = { extension: '', id: '', url: '' },
  contractId,
  downloadFilePreview,
  onClick,
  setIsPreviewRendering,
  imageClassName = '',
  isPreviewRendering,
  isFullscreen,
}: PreviewProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [activePdf, setActivePdf] = useState(null);
  const { width } = useWindowSize();

  useEffect(() => {
    if (file) {
      setIsPreviewRendering(true);
      const canvas = canvasRef.current;

      generatePreview({
        canvas,
        contractId,
        fileId: file.id,
        downloadFilePreview,
        setIsPreviewRendering,
        setActivePdf,
      });
    }

    scrollContainerRef?.current?.scrollTo(0, 0);
  }, [file.id]);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (activePdf && canvas) {
      pdfPreview({ pdf: activePdf, canvas, setIsPreviewRendering });
    }
  }, [width]);

  if (!file || isEmpty(file)) return null;

  if (unavailableExtensions.includes(file.extension)) {
    return (
      <div className={styles.main}>
        <div className={cx(styles.preview, styles.unavailable)} />
        <p className={styles.previewUnavailable}>
          {t('expenses.upload.actions.overlay_unavailable')}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {isFullscreen && <button className={styles.close} onClick={onClick} />}
      {supportedPdfExtensions.includes(file.extension) && (
        <PreviewPDF
          scrollContainerRef={scrollContainerRef}
          canvasRef={canvasRef}
          onClick={onClick}
          isPreviewRendering={isPreviewRendering}
        />
      )}
      {supportedImgExtensions.includes(file.extension) && (
        <PreviewIMG
          file={file}
          imageClassName={imageClassName}
          setIsPreviewRendering={setIsPreviewRendering}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default Preview;
