/* eslint-disable react/prop-types */ // temp
import React, { Component } from 'react';
import { bool, func, number } from 'prop-types';

import { t } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';

import { ResourcesPerPage } from './ResourcesPerPage/ResourcesPerPage';
import { UnderlinedButton } from './UnderlinedButton/UnderlinedButton';

import styles from './Pagination.module.css';

export class Pagination extends Component {
  state = {
    pageIndex: this.props.page,
  };

  componentDidUpdate(prevProps) {
    const { page } = this.props;

    if (prevProps.page !== page && isNaN(page) === false) {
      // eslint-disable-next-line
      this.setState({ pageIndex: page });
    }
  }

  changePage = (page) => {
    const { request } = this.props;

    request({ page });
  };

  handleChange = (e) => {
    const { value } = e.target;
    return this.setState({ pageIndex: value });
  };

  handleBlur = () => {
    const { totalPages, request, page } = this.props;
    const pageIndexInt = parseInt(this.state.pageIndex, 10);

    if (!pageIndexInt || pageIndexInt <= 0 || pageIndexInt > totalPages || pageIndexInt === page) {
      // If state's pageIndex value doesn't match any reasonable value, fallback to page value derived from props
      this.setState({ pageIndex: this.props.page });
    } else {
      request({ page: pageIndexInt });
    }
  };

  inputRef = React.createRef();

  render() {
    const { isFetching, page, totalPages, resource, request } = this.props;
    const { pageIndex } = this.state;
    const canNext = page && totalPages && page < totalPages;
    const canPrevious = page && page > 1;

    return (
      <div className={styles.flexContainer}>
        <nav className={styles.pagination} data-id="pagination">
          <button
            onClick={() => this.changePage(page - 1)}
            className={styles.buttonPrev}
            data-id="previous-page"
            disabled={!canPrevious || isFetching}
            title={t('pagination.previous_page')}
          />
          <div className={styles.indicator}>
            <input
              ref={this.inputRef}
              type="number"
              data-id="current-page"
              size={1}
              minLength={1}
              min={1}
              max={totalPages || 1}
              className={styles.indicatorInput}
              value={page && totalPages ? pageIndex : '-'}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onKeyPress={isPressedEnter(() => this.inputRef.current.blur())}
              disabled={totalPages <= 1}
              autoComplete="off"
            />
            <span>/</span>
            <UnderlinedButton
              className={styles.button}
              disabled={page === totalPages}
              onClick={() => this.changePage(totalPages)}
              data-id="total-pages"
            >
              {totalPages}
            </UnderlinedButton>
          </div>
          <button
            onClick={() => this.changePage(page + 1)}
            className={styles.buttonNext}
            disabled={!canNext || isFetching}
            title={t('pagination.next_page')}
            data-id="next-page"
          />
        </nav>
        <ResourcesPerPage request={request} resource={resource} />
      </div>
    );
  }
}

Pagination.propTypes = {
  isFetching: bool.isRequired,
  page: number.isRequired,
  totalPages: number.isRequired,
  request: func.isRequired,
};
