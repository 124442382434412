import styled from 'styled-components';

import Button from 'redesign/components/atoms/Button/Button';
import WarningBulb from 'redesign/components/atoms/Icons/WarningBulb';
import Select, { SelectProps } from 'redesign/components/atoms/Select/Select';
import BaseTooltip from 'redesign/components/molecules/Tooltip/Tooltip';
import { Breakpoints } from 'redesign/styles/breakpoints';

import { Option } from './types';

export const SubmitButton = styled(Button)`
  padding-left: ${({ theme }) => theme.space['8']};
  padding-right: ${({ theme }) => theme.space['8']};
  margin-right: ${({ theme }) => theme.space['3']};
`;

export const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.vrblue['100']};
  box-shadow: none !important;

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.vrblue['100']}};
    box-shadow: none !important;
  }
`;

export const ActionsSection = styled.div`
  margin-top: ${({ theme }) => theme.space['10']};

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${Breakpoints.sm}) {
    margin-top: 4rem;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const FieldControl = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.space['8']};
`;

export const SmallEntrepeneurText = styled.p`
  font-size: ${({ theme }) => theme.fontSize['sm']};
  color: ${({ theme }) => theme.legacy.colors.greyFont};
  letter-spacing: ${({ theme }) => theme.letterSpacing.normal};
  line-height: ${({ theme }) => theme.space['4']};
`;

export const Tooltip = styled(BaseTooltip)`
  position: relative;
  margin-left: ${({ theme }) => theme.space['7']};
  margin-top: ${({ theme }) => theme.space['6']};
`;

export const CheckboxControl = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.space['4']};

  @media (min-width: ${Breakpoints.sm}) {
    align-items: end;
    margin-left: -1.5rem;
    margin-bottom: 0.625rem;
  }
`;

export const FormControl = styled.div`
  max-width: 33em;
`;

export const FormFieldRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.lg}) {
    flex-direction: row;
  }
`;

export const TextConsultantCredsColumn = styled.div`
  flex: 1;

  &:first-child {
    margin-bottom: ${({ theme }) => theme.space['8']};
  }

  @media (min-width: ${Breakpoints.lg}) {
    flex-direction: row;
    flex: 2;

    &:first-child {
      margin-bottom: ${({ theme }) => theme.space['0']};
      margin-right: ${({ theme }) => theme.space['5']};
    }
  }
`;

export const PasswordContainer = styled.div`
  max-width: 25rem;
`;

export const WarningBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.space['4']};
`;

export const WarningIcon = styled(WarningBulb)`
  width: ${({ theme }) => theme.space['7']};
  height: ${({ theme }) => theme.space['7']};
  margin-right: 0.375rem;
`;

export const WarningText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.base};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  line-height: ${({ theme }) => theme.space['4']};
`;

export const TaxationTypeSelect = styled(Select)<SelectProps<Option>>`
  width: 15.25rem;
`;

export const TaxationIntervalSelect = styled(Select)<SelectProps<Option>>`
  max-width: 15.25rem;
`;

export const AccountChartSelect = styled(Select)<SelectProps<Option>>`
  max-width: 7.75rem;
`;

export const Row = styled.div`
  display: flex;
`;

export const TaxationRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.sm}) {
    flex-direction: row;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey['050']};

  margin-left: -1.5rem;
  margin-right: -1.5rem;
`;
