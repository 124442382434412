import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { noop } from 'lodash';
import { bool, func, string } from 'prop-types';

import paths from 'routes/paths';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import { ButtonAppearances } from 'components/Button';

import styles from './AbortButton.module.css';

const AbortButton = ({ goToDashboard, onClickAddon = noop, noRedirect = false, dataId }) => (
  <div className={styles.transparentContainer}>
    <ActionButton
      fullWidth
      appearance={ButtonAppearances.outlined}
      onClick={() => {
        onClickAddon();
        return noRedirect ? null : goToDashboard();
      }}
      label={t('expenses.form.abort')}
      dataId={dataId}
    />
  </div>
);

AbortButton.propTypes = {
  goToDashboard: func.isRequired,
  onClickAddon: func,
  noRedirect: bool,
  dataId: string,
};

export default connect(null, (dispatch) => ({
  goToDashboard: () => {
    dispatch(push(paths.expenses));
  },
}))(AbortButton);
