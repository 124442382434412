import React from 'react';

import plusIcon from 'images/icon-plus--white.svg';
import { t } from 'shared/utils';

import styles from '../Gallery.module.css';

type NewFileBtnProps = {
  onClick: () => void;
};

const NewFileBtn = ({ onClick }: NewFileBtnProps) => (
  <button
    className={styles.newFileButton}
    onClick={onClick}
    type="button"
    title={t('expenses.upload.browse_files')}
    data-id="Gallery:button-browse"
  >
    <img
      alt={t('expenses.upload.browse_files')}
      className={styles.newFileButtonIcon}
      src={plusIcon}
      data-id="Gallery:icon-browse"
    />
  </button>
);

export default NewFileBtn;
