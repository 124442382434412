import React from 'react';

import { t } from 'shared/utils';

import { Wrapper } from './AddContract.styled';

type AddContractProps = {
  onClick: () => void;
};

const AddContract = ({ onClick }: AddContractProps) => {
  return (
    <Wrapper onClick={onClick} data-id="add-contract">
      {t('features.bank_transfers.add_contract')}
    </Wrapper>
  );
};

export default AddContract;
