import React, { memo, useCallback } from 'react';

import { t } from 'shared/utils';
import IconNaviExpander from 'redesign/components/atoms/Icons/NaviExpander';

import Accounts from './MenuItems/Accounts';
import Administration from './MenuItems/Administration';
import Cashbooks from './MenuItems/Cashbooks';
import Contracts from './MenuItems/Contracts';
import Expense from './MenuItems/Expense';
import Export from './MenuItems/Export';
import FinancialPlanning from './MenuItems/FinancialPlanning';
import Help from './MenuItems/Help';
import Homepage from './MenuItems/Homepage';
import Revenue from './MenuItems/Revenue';
import Settings from './MenuItems/Settings';
import { Close, Nav } from './Navigation.styled';
import { trackEvent } from './utils';

type NavigationProps = {
  isOpen: boolean;
  pathname: string;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCloseClick?: () => void;
};

const Navigation = ({
  isOpen,
  pathname,
  onCloseClick,
  onMouseEnter,
  onMouseLeave,
}: NavigationProps) => {
  const handleCloseClick = useCallback(() => {
    trackEvent('burger_close');
    onCloseClick && onCloseClick();
  }, [onCloseClick]);

  return (
    <Nav
      $isOpen={isOpen}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-id="navigation"
    >
      <Close onClick={handleCloseClick} $isHidden={!isOpen} data-id="close">
        <IconNaviExpander /> {t('redesign.organisms.navigation.close')}
      </Close>
      <ul>
        <Homepage pathname={pathname} />
        <Revenue pathname={pathname} isNavOpen={isOpen} />
        <Expense pathname={pathname} isNavOpen={isOpen} />
        <Contracts pathname={pathname} />
        <FinancialPlanning pathname={pathname} isNavOpen={isOpen} />
        <Accounts pathname={pathname} />
        <Cashbooks pathname={pathname} />
        <Export pathname={pathname} />
        <Administration pathname={pathname} isNavOpen={isOpen} />
        <Settings pathname={pathname} isNavOpen={isOpen} />
        <Help />
      </ul>
      {/* It's a placeholder for chat icon */}
      <div style={{ height: '105px' }} />
    </Nav>
  );
};

export default memo(Navigation);
