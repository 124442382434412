import { format } from 'date-fns';
import { get, isUndefined, pickBy } from 'lodash';

import { NEW_MODE } from 'constants/common/crud';
import { DATE_FORMAT_UNICODE } from 'constants/datetime';
import { orderConfirmationSelector } from 'reducers/form';
import { t } from 'shared/utils';
import getSalutationHonorific from 'shared/utils/getSalutationHonorific';
import { RootState } from 'store';
import { Client } from 'types/entities/Client';

interface OrderConfirmationValues {
  deliveryDate?: number;
  subject?: string;
  salutation?: string;
  salutationContent?: string;
  deliveryInfo?: string;
  personalNotes?: string;
  orderConfirmationDate?: string;
}

interface OrderConfirmationInitialValues extends Partial<OrderConfirmationValues> {
  salutationHonorific?: string;
  salutationContent?: string;
}

export const getDefaultValues = (
  fetchedDefaultValues: {
    subject: string;
    salutation: string;
    salutationContent: string;
    deliveryInfo: string;
    personalNotes: string;
  },
  client: Client
) => {
  const { subject, salutation, salutationContent, deliveryInfo, personalNotes } =
    fetchedDefaultValues;
  const salutationHonorific =
    salutation ||
    getSalutationHonorific('defaultOrderConfirmationSalutationDefined', { salutation }, client);

  return {
    subject,
    salutationHonorific,
    salutationContent:
      salutationContent || t('revenue.form.individual_contact.initial_message_order_confirmation'),
    deliveryInfo,
    personalNotes,
    orderConfirmationDate: format(new Date(), DATE_FORMAT_UNICODE),
  };
};

const isNotUndefined = (value: any) => !isUndefined(value);

export const getOrderConfirmationInitialValues = (
  state: RootState,
  crudMode: string
): OrderConfirmationInitialValues => {
  const client =
    orderConfirmationSelector(state, 'client') || get(state, 'orderConfirmation.details.client');
  const defaultValues = getDefaultValues(
    {
      subject: get(state, 'defaultValues.values.orderConfirmationSubject'),
      salutation: get(state, 'defaultValues.values.orderConfirmationSalutation'),
      salutationContent: get(state, 'defaultValues.values.orderConfirmationSalutationContent'),
      deliveryInfo: get(state, 'defaultValues.values.orderConfirmationDeliveryInfo'),
      personalNotes: get(state, 'defaultValues.values.orderConfirmationFooter'),
    },
    client
  );

  if (crudMode === NEW_MODE) {
    return {
      ...defaultValues,
      salutationHonorific: getSalutationHonorific(
        'defaultOrderConfirmationSalutationDefined',
        { salutation: defaultValues.salutationHonorific },
        client
      ),
    };
  }

  return {
    ...pickBy(defaultValues, isNotUndefined),
    ...pickBy(state.orderConfirmation.details, isNotUndefined),
  };
};
