import { sortingParams } from 'actions/helpers/table';
import { CALL_API } from 'constants/api';
import { DOWNLOAD_FAILURE, DOWNLOAD_REQUEST, DOWNLOAD_SUCCES } from 'constants/csv-download';
import { getSanitizedFilters } from 'reducers/common/filters';
import { downloadData } from 'shared/utils';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiDownload = ({ endpoint, filters, sorting }) => ({
  [CALL_API]: {
    params: {
      filters,
      ...sorting,
    },
    endpoint: `/me/${endpoint}`,
    types: [DOWNLOAD_REQUEST, DOWNLOAD_SUCCES, DOWNLOAD_FAILURE],
  },
});

export const download =
  ({ endpoint, filters, sorting = {} }) =>
  (dispatch) => {
    const safeFilters = getSanitizedFilters(filters);
    const urlSafeSorting = sorting.column ? sortingParams(sorting) : '';
    return dispatch(apiDownload({ endpoint, filters: safeFilters, sorting: urlSafeSorting }))
      .then(({ rawResponse: { data: { content, format, filename = endpoint } = {} } = {} }) => {
        downloadData(content, filename, format);
      })
      .catch(apiErrorHandler);
  };
