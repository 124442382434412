import React, { memo, SVGProps } from 'react';

const Bank = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M5 12.242v4c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5zm6 0v4c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5zm-6.5 10.5h16c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-16c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5zm12.5-10.5v4c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5zm-5.43-10.01l-7.9 4.16c-.41.21-.67.64-.67 1.1 0 .69.56 1.25 1.25 1.25h16.51c.68 0 1.24-.56 1.24-1.25 0-.46-.26-.89-.67-1.1l-7.9-4.16c-.58-.31-1.28-.31-1.86 0z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default memo(Bank);
