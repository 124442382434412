import React, { useCallback, useRef, useState } from 'react';
import { ChromePicker, ColorChangeHandler } from 'react-color';
import { usePopper } from 'react-popper';
import { useClickAway, useUpdateEffect } from 'react-use';

import PopperArrow from '../PopperArrow/PopperArrow';
import { Picker, Preview } from './ColorPicker.styled';

export type ColorPickerProps = {
  color?: string;
  onChange: (color: string) => void;
  className?: string;
  disabled?: boolean;
};

const ColorPicker = ({ color: initialColor, onChange, disabled, className }: ColorPickerProps) => {
  const wrapperRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState(initialColor);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start', 'bottom-start'],
        },
      },
    ],
  });

  const handleShowPicker = useCallback(() => {
    if (disabled) return;

    setShowPicker((show) => !show);
  }, [disabled]);

  const handleHidePicker = useCallback(() => {
    setShowPicker(false);
  }, []);

  const handleChange: ColorChangeHandler = useCallback((color) => {
    setColor(color.hex);
  }, []);

  const handleChangeComplete: ColorChangeHandler = useCallback(
    (color) => {
      onChange(color.hex);
    },
    [onChange]
  );

  useClickAway(wrapperRef, handleHidePicker);

  useUpdateEffect(() => {
    setColor(initialColor);
  }, [initialColor]);

  return (
    <div
      ref={wrapperRef}
      className={className}
      onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
    >
      <Preview
        $color={color}
        $isDisabled={disabled}
        onClick={handleShowPicker}
        ref={setReferenceElement}
      />
      {showPicker && (
        <Picker ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <ChromePicker
            color={color}
            disableAlpha
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
            styles={{
              default: {
                picker: {
                  boxShadow: 'none',
                  background: 'none',
                },
              },
            }}
          />
          <PopperArrow ref={setArrowElement} style={styles.arrow} />
        </Picker>
      )}
    </div>
  );
};

export default ColorPicker;
