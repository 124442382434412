export enum TransactionType {
  Expense = 'expense',
  Revenue = 'revenue',
}

type InvoiceLineCategory = {
  id: string;
  parentId: number;
};

export type CashTransaction = {
  id: string;
  idNumber: string;
  documentNumber: string | null;
  transactionType: TransactionType;
  transactionDate: string;
  invoiceLineCategoryId: string;
  invoiceLineCategory: InvoiceLineCategory;
  grossAmount: number;
  description: string;
  taxAmount: number;
  vat: number;
  cashbookId: string;
  reasonForDeletion: string | null;
  assignedToDatevReport: boolean;
  hasFiles: boolean;
  cashbookAmountAfterTransaction: number;
  dailyOrderId: number;
  reasonForRetroactiveCreation: string;
  reasonForCorrection: string;
  taxRateId: string;
};

export type PaginationResource = {
  page?: number;
  perPage?: number;
  totalPages?: number;
  pagination_resource?: string;
};
