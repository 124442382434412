import React, { memo, SVGProps } from 'react';

const Delete = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M.5 0h24v24H.5z" />
      <path
        d="M16.278 9.333v8.89H9.167v-8.89h7.11M14.945 4H10.5l-.889.889H6.5v1.778h12.444V4.889h-3.11L14.943 4zm3.112 3.556H7.389v10.666c0 .978.8 1.778 1.778 1.778h7.11c.979 0 1.779-.8 1.779-1.778V7.556z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(Delete);
