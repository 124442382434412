import { pickBy } from 'lodash';

export const defaultState = {
  page: 1,
  totalPages: 1,
};

export const getPaginationReducer = (successTypes) => {
  const successTypesArray = Array.isArray(successTypes) ? successTypes : [successTypes];

  return (state = { ...defaultState }, action) => {
    if (!successTypesArray.includes(action.type)) {
      return state;
    }

    if ('headers' in action) {
      const page = parseInt(action.headers.page, 10);
      const totalPages = parseInt(action.headers['total-pages'], 10);

      if (isNaN(page) || isNaN(totalPages)) {
        process.env.NODE_ENV !== 'production' &&
          console.warn('Pagination reducer: invalid headers', action.headers);
      }

      return {
        ...state,
        page: page || 1,
        totalPages: totalPages || 1,
      };
    }

    return {
      ...state,
      ...pickBy({
        page: parseInt(action.page, 10) || 1,
      }),
    };
  };
};
