import { push } from 'connected-react-router';

import { CALL_API } from 'constants/api';
import {
  REQUEST_RESET_FAILURE,
  REQUEST_RESET_REQUEST,
  REQUEST_RESET_SUCCESS,
  RESET_FAILURE,
  RESET_REQUEST,
  RESET_SUCCESS,
} from 'constants/password-reset';
import paths from 'routes/paths';
import { apiErrorHandler } from 'shared/utils/error-handlers';

import { RegistrationSource } from '../constants/registration';
import { apiGetLoginInfo, apiPrelogin } from './auth';

const requestReset = (email) => ({
  [CALL_API]: {
    data: {
      customer: { email },
    },
    method: 'POST',
    authRequired: false,
    endpoint: '/../password.json',
    types: [REQUEST_RESET_REQUEST, REQUEST_RESET_SUCCESS, REQUEST_RESET_FAILURE],
  },
});

const reset = (data) => ({
  [CALL_API]: {
    data: {
      customer: {
        reset_password_token: data.resetPasswordToken,
        password: data.password,
        password_confirmation: data.passwordConfirm,
      },
    },
    method: 'PUT',
    authRequired: false,
    endpoint: '/../password.json',
    types: [RESET_REQUEST, RESET_SUCCESS, RESET_FAILURE],
  },
});

export const requestPasswordReset = (email) => async (dispatch) => {
  const { rawResponse: preloginToken } = await dispatch(apiPrelogin(email));
  const { active, registration_source: registrationSource } = await dispatch(
    apiGetLoginInfo({ email, token: preloginToken })
  );

  if (registrationSource === RegistrationSource.Vrso && !active) {
    return dispatch(push(paths.vrsoResetPasswordBlocked));
  }

  return dispatch(requestReset(email)).catch(apiErrorHandler);
};

export const passwordReset = (data) => (dispatch) => dispatch(reset(data)).catch(apiErrorHandler);
