import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import cx from 'classnames';
import { isBoolean, isEmpty, isEqual, snakeCase, toString } from 'lodash';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { clearAssignedBankTransfers as clearAssignedBankTransfersAction } from 'actions/incoming-invoice';
import {
  createProductCatalogItem as createProductCatalogItemAction,
  fetchProductCatalogItem,
  updateProductCatalogItem as updateProductCatalogItemAction,
} from 'actions/product-catalog/items/items';
import { TYPE_EXPENSES } from 'constants/row-types';
import { InvoiceStatuses } from 'constants/statuses';
import lineItemStyles from 'shared/styles/line-items.module.css';
import tableStyles from 'shared/styles/table.module.css';
import {
  formatAmountWithHistory,
  l,
  noop,
  normalizeCurrency,
  parseCurrency,
  t,
} from 'shared/utils';
import { isXlgUp } from 'shared/utils/breakpoints';
import isMobile from 'shared/utils/is-mobile';
import isPressedEnter from 'shared/utils/keyboard-events';
import { ensureNumericMoneyValue, toGrossValue, toNetValue } from 'shared/utils/money';
import IconDragAndDrop from 'components/ActionIcons/IconDragAndDrop';
import CategorySearch from 'components/CategorySearch/CategorySearch';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import If from 'components/Conditions/If';
import RawCheckbox from 'components/Form/RawCheckbox/RawCheckbox';
import SelectField from 'components/Form/SelectField';
import TextField from 'components/Form/TextField';
import I18n from 'components/I18n';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'components/LineItems';
import AddProductButton from 'components/LineItems/components/AddProductButton/AddProductButton';
import { LineItemContainer } from 'components/LineItems/components/LineItemContainer/LineItemContainer';
import { TableCell } from 'components/LineItems/components/TableCell/TableCell';
import { TableSubRow } from 'components/LineItems/components/TableSubRow/TableSubRow';
import { TextInputWithCounter } from 'components/LineItems/components/TextInputWithCounter/TextInputWithCounter';
import { getDefaultTaxRate, humanizeAll, transformLineItemProps } from 'components/LineItems/utils';
import LoadingIcon from 'components/LoadingIcon';
import { ConfirmationModal } from 'components/Modal';
import ItemNumberSelect from 'components/Selects/ItemNumberSelect/ItemNumberSelect';
import PositionSelectControl from 'components/Selects/LineItemPositionSelect';
import UnitSelect from 'components/Selects/UnitSelect/UnitSelect';

import { parseValues } from './LineItems.utils';

import localStyles from './LineItems.module.css';

const styles = { ...localStyles, ...tableStyles, ...lineItemStyles };

class EditItemRow extends Component {
  static propTypes = {
    deleteLineItem: func.isRequired,
    updateLineItem: func.isRequired,
    addEditLineItemCreator: func.isRequired,
    removeEditLineItemCreator: func.isRequired,
    lineItem: shape({
      id: string,
      quantity: string,
    }),
    translationPath: string.isRequired,
    absoluteTranslationPath: string.isRequired,
    lineCategories: arrayOf(shape({})),
    cells: arrayOf(shape({})),
    extendedRowCells: arrayOf(shape({})),
    numberOfColumns: number,
    rowType: string.isRequired,
    fetchProductCatalogItem: func,
    clearAssignedBankTransfers: func,
    smallEntrepreneur: bool,
    invoiceInsertedAsGross: bool,
    status: string,
    lineItemsLength: number,
    dndId: number,
    dndIndex: number,
    readonly: bool,
    isDragDisabled: bool,
    isCreditNote: bool,
    productCatalogItemsEnabled: bool,
    addProductHidden: bool,
    createProductCatalogItem: func.isRequired,
    updateProductCatalogItem: func.isRequired,
  };

  static defaultProps = {
    numberOfColumns: 5,
    smallEntrepreneur: false,
    isDragDisabled: false,
    isCreditNote: false,
  };

  constructor(props) {
    super(props);
    this.state = this.defaultState;

    this.isMobileUserAgent = isMobile();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      values: { vat: prevVat, insertedAsGross: previousInsertedAsGross },
    } = prevState;
    const {
      values: { vat, insertedAsGross, netAmount, grossAmount },
      isEditable,
    } = this.state;
    const {
      lineItem,
      lineItem: { id },
      invoiceInsertedAsGross,
      addEditLineItemCreator,
      removeEditLineItemCreator,
      outgoingInvoiceSelectedCategory,
      defaultVat,
      readonly,
    } = this.props;
    const vatHasChanged = vat !== prevVat;
    const insertedAsGrossChanged = previousInsertedAsGross !== insertedAsGross;

    // will receive props
    if (!isEqual(prevProps.lineItem, lineItem)) {
      const values = humanizeAll(transformLineItemProps(lineItem));

      this.setPristine(true);
      this.setState({
        values,
        initialInsertedAsGross: values.insertedAsGross,
      });
    }

    if (
      prevProps.outgoingInvoiceSelectedCategory !== outgoingInvoiceSelectedCategory ||
      prevProps.taxRateId !== this.props.taxRateId
    ) {
      this.setState({
        values: {
          ...this.state.values,
          vat: (this.props.isInitial && prevVat) || readonly ? vat : defaultVat,
          invoiceLineCategoryId: this.props.invoiceLineCategoryId,
          taxRateId: this.props.taxRateId,
        },
      });

      if (!this.props.isInitial && !readonly) {
        this.setFocused(true);
        this.onEdit(undefined, true);
      }
    }

    if (!prevState.isEditable && isEditable) {
      addEditLineItemCreator({ id, creatorFunction: this.handleEdition });
    }

    if (prevState.isEditable && !isEditable) removeEditLineItemCreator(id);

    if (vatHasChanged) {
      this.handleVatChange(vatHasChanged);

      if (insertedAsGross) {
        this.setField('netAmount')(toNetValue(grossAmount, vat));
      } else {
        this.setField('grossAmount')(toGrossValue(netAmount, vat));
      }
    }

    if (!insertedAsGross && insertedAsGrossChanged) {
      this.setField('grossAmount')(toGrossValue(netAmount, vat));
    }

    const invoiceInsertedAsGrossChanged =
      prevProps.invoiceInsertedAsGross !== invoiceInsertedAsGross;

    if (isBoolean(prevProps.invoiceInsertedAsGross) && invoiceInsertedAsGrossChanged) {
      this.onGrossToggle({ invoiceInsertedAsGross });
    }
  }

  setEditable = (isEditable) => {
    this.setState({ isEditable });
  };

  setPristine = (pristine) => {
    this.setState({ pristine });
  };

  editOnEnterPress = (event) => {
    const editLineItem = () => this.onEdit(event);

    isPressedEnter(editLineItem)(event);
  };

  get sharedInputProps() {
    return {
      onKeyPress: this.editOnEnterPress,
    };
  }

  setFocused = (value) => this.setState({ isFocused: value });

  handleClickOutside = () => {
    const { isFocused, isEditable, pristine } = this.state;

    if (!isFocused) return;
    this.setFocused(false);
    if (!isEditable || pristine) return;
    this.handleEdition();
  };

  get hasDistinctGrossNetMode() {
    const {
      values: { insertedAsGross },
      initialInsertedAsGross,
    } = this.state;
    const { invoiceInsertedAsGross } = this.props;

    return (
      insertedAsGross !== initialInsertedAsGross ||
      initialInsertedAsGross !== invoiceInsertedAsGross
    );
  }

  onEdit = (event, categoryChanged = false) => {
    event?.preventDefault();

    const { isEditable, pristine } = this.state;
    const canSubmit = isEditable && !pristine;

    this.setPristine(false);
    if (!canSubmit) {
      return this.setEditable(!isEditable);
    }

    if (canSubmit && isEditable && !categoryChanged) {
      return this.handleEdition();
    }
  };

  closeModal = () =>
    this.setState({ isRemoveItemModalVisible: false, isLineEditModalVisible: false });

  onGrossToggle = ({ invoiceInsertedAsGross }) => {
    const insertedAsGross = isBoolean(invoiceInsertedAsGross)
      ? invoiceInsertedAsGross
      : !this.state.values.insertedAsGross;

    this.setPristine(false);

    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          insertedAsGross,
        },
      }),
      this.onGrossNetStateChange
    );
  };

  onGrossNetStateChange = () => {
    const {
      values: { insertedAsGross, netAmount, grossAmount },
    } = this.state;
    const [sourceFieldName, computedFieldName, inputValue] = insertedAsGross
      ? ['grossAmount', 'netAmount', grossAmount]
      : ['netAmount', 'grossAmount', netAmount];

    this.onPriceChange([sourceFieldName, computedFieldName])({
      target: { value: inputValue },
    });
  };

  onPriceChange =
    ([sourceFieldName, computedFieldName] = []) =>
    (e) => {
      const { vat } = this.state.values;
      const value = e.target.valueAsNumber;

      if (isNaN(value)) {
        return;
      }

      const computedValue = Number(
        (sourceFieldName === 'netAmount'
          ? toGrossValue(value, vat)
          : toNetValue(value, vat)
        ).toFixed(3)
      );

      this.setPristine(false);
      this.setState(({ values, parsedValues }) => ({
        values: {
          ...values,
          [sourceFieldName]: value,
          [computedFieldName]: computedValue,
        },
        parsedValues: {
          ...parsedValues,
          [sourceFieldName]: value,
          [computedFieldName]: computedValue,
        },
      }));
    };

  onQuantityChange = (e) => {
    const parsedValue = parseCurrency(e.target.value);
    const value = normalizeCurrency(parsedValue);

    this.setState(({ values, parsedValues }) => ({
      values: {
        ...values,
        quantity: value,
      },
      parsedValues: {
        ...parsedValues,
        quantity: parsedValue,
      },
    }));
  };

  setField = (name) => (value) => {
    this.setPristine(false);
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
    }));
  };

  setLoading(value) {
    this.setState({ isLoading: value });
  }

  handleVatChange(valueHasChangedValue) {
    this.setState({ vatHasChanged: valueHasChangedValue });
  }

  handlePositionSelect = async (itemId) => {
    if (!itemId) return;
    const { fetchProductCatalogItem, taxRates } = this.props;
    const { response } = await fetchProductCatalogItem(itemId);
    const item = response.data.attributes;
    const itemWithMappedKey = { ...item, position: item.name };
    const newTaxRate = taxRates?.find(({ value }) => item.vat === value);

    this.setPristine(false);
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        ...itemWithMappedKey,
        vat: !!newTaxRate ? newTaxRate.value : prevState.values.vat,
        taxRateId: !!newTaxRate ? newTaxRate.id : prevState.values.taxRateId,
      },
    }));
  };

  defaultLineItem = this.props.lineItem;

  get defaultState() {
    const values = humanizeAll(transformLineItemProps(this.defaultLineItem));
    const { grossAmount, netAmount, insertedAsGross } = values;
    const [sourceFieldName, sourceFieldValue] = insertedAsGross
      ? ['grossAmount', grossAmount]
      : ['netAmount', netAmount];

    return {
      values,
      initialInsertedAsGross: insertedAsGross,
      parsedValues: { [sourceFieldName]: sourceFieldValue },
      submitFailed: false,
      isLoading: false,
      vatHasChanged: false,
      errors: {},
      isLineEditModalVisible: false,
      isRemoveItemModalVisible: false,
      isFocused: false,
      haveChangedFromLastSave: false,
      isEditable: false,
      pristine: true,
    };
  }

  resetState = () => {
    this.setState({
      ...this.defaultState,
      values: humanizeAll(this.props.lineItem),
    });
  };

  handleEdition = async () => {
    const { updateLineItem, clearAssignedBankTransfers } = this.props;
    const { values } = this.state;

    this.setLoading(true);

    try {
      await updateLineItem(values);

      this.setLoading(false);
      this.setState({ errors: {}, isLineEditModalVisible: false });
      this.setEditable(false);
      clearAssignedBankTransfers();

      return true;
    } catch (errors) {
      this.setState({ errors });

      return false;
    } finally {
      this.setLoading(false);
    }
  };

  handleDeletion = async () => {
    const { deleteLineItem, status, lineItemsLength, clearAssignedBankTransfers } = this.props;
    const { isRemoveItemModalVisible, isEditable } = this.state;
    const isPaidOrPartlyPaid =
      status === InvoiceStatuses.PAID || status === InvoiceStatuses.PARTLY_PAID;
    const isNotLastLineItem = lineItemsLength > 1;

    if (!isEditable && !isRemoveItemModalVisible && isPaidOrPartlyPaid && isNotLastLineItem) {
      return this.setState({ isRemoveItemModalVisible: true });
    }

    this.setLoading(true);
    await deleteLineItem();
    this.setLoading(false);

    this.setState({ isRemoveItemModalVisible: false });

    return clearAssignedBankTransfers();
  };

  handleCancellation = () => {
    this.resetState();
  };

  handleChange = (name) => (event) => {
    const {
      target: { value },
    } = event;

    this.setPristine(false);
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
    }));
  };

  onSelectCategory = (item) => {
    this.setState({
      values: {
        ...this.state.values,
        vat: getDefaultTaxRate(item)?.value,
        taxRateId: getDefaultTaxRate(item)?.id,
        invoiceLineCategoryId: parseInt(item?.id),
      },
    });
  };

  handleSelect = (name) => (option) => {
    this.setPristine(false);
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [name]: option && option.value,
        taxRateId: option && option.id,
      },
    }));
  };

  translationPath = (name) =>
    `${this.props.translationPath}.form.details_section.fields.${snakeCase(name)}`;

  handleCheckbox =
    (name) =>
    ({ target }) => {
      this.setPristine(false);
      this.setState((prevState) => ({
        values: {
          ...prevState.values,
          [name]: target.checked,
        },
      }));
    };

  renderMultiLevelSelect = (name, info) => {
    const { values, isEditable } = this.state;
    const { lineCategories } = this.props;
    const placeholder = isEditable ? t('forms.line_items.placeholders.category') : '';
    const selectedItem = lineCategories.find((item) => {
      if (values.invoiceLineCategoryId) {
        return item.id === values.invoiceLineCategoryId.toString();
      } else {
        return undefined;
      }
    });

    return (
      <TableCell name={name}>
        <I18n t={this.translationPath(name)} className={localStyles.cellHeader} />
        <span className={styles.cellIcon}>
          <InfoIcon text={info} />
        </span>
        <div
          className={cx(styles.inputWrapper, styles.categoryInput, {
            [styles.categoryInputFixed]: !isEditable,
          })}
        >
          <CategorySearch
            disabled={!isEditable}
            placeholder={placeholder}
            categories={lineCategories}
            onSelectCategory={this.onSelectCategory}
            initialSelectedItem={selectedItem}
            initialInputValue={selectedItem?.name}
            withIcon
          />
        </div>
      </TableCell>
    );
  };

  renderText = (name, { textType, rounded = false, options = {} } = {}) => {
    const { rowType, isCreditNote } = this.props;
    const value = this.state.values[name];
    const isCurrency = textType === 'currency';
    const isNegative = isCurrency && String(value).startsWith('-');
    const isExpenseType = rowType === TYPE_EXPENSES;
    const isIncome = isExpenseType === (isNegative || isCreditNote);

    return (
      <span
        data-id={`LineItem:fixed-text(${name})`}
        className={cx(styles.text, {
          [localStyles.money]: isCurrency,
          [localStyles.moneyIncome]: isCurrency && isIncome,
          [localStyles.moneyExpense]: isCurrency && !isIncome,
        })}
      >
        {rounded && '≈ '}
        {textType ? l(value, textType, options) : value}
        {name === 'vat' && '%'}
      </span>
    );
  };

  renderTextCell = (name, { fullWidth = false, info, ...options } = {}, className) => (
    <TableCell name={name} className={className}>
      <div
        className={cx(localStyles.textContainer, {
          [localStyles.textContainerFullWidth]: fullWidth,
        })}
      >
        <I18n
          t={this.translationPath(snakeCase(name))}
          className={cx(localStyles.cellHeader, localStyles.textCellHeader, {
            [localStyles.textCellHeaderFullWidth]: fullWidth,
          })}
        />

        {Boolean(info) && (
          <span className={styles.cellIcon}>
            <InfoIcon text={info} />
          </span>
        )}
        {this.renderText(name, { info, fullWidth, ...options })}
      </div>
    </TableCell>
  );

  renderInput = (name) => {
    const { isEditable } = this.state;
    const errors = this.state.errors[name];
    const hasErrors = Boolean((errors || []).length);
    const placeholder = isEditable ? `${t(`forms.line_items.placeholders.${name}`)} *` : '';

    return (
      <div className={styles.inputWrapper}>
        <TextField
          name={name}
          onChange={this.handleChange(name)}
          value={this.state.values[name]}
          invalid={hasErrors}
          className={styles.input}
          inputClassName={cx(styles.inputContent, {
            [styles.inputDisabled]: !isEditable,
          })}
          labelClassName={cx({
            [styles.labelDisabled]: !isEditable,
          })}
          disabled={!isEditable}
          placeholder={this.isMobileUserAgent ? undefined : placeholder}
          isLabelHidden
          inputProps={this.sharedInputProps}
        />
      </div>
    );
  };

  renderQuantity = () => {
    const {
      parsedValues: { quantity: parsedQuantity },
      values: { quantity },
      errors: { quantity: quantityErrors },
    } = this.state;
    const { isEditable } = this.state;
    const placeholder = isEditable ? `${t('forms.line_items.placeholders.quantity')} *` : '';
    const hasErrors = Boolean((quantityErrors || []).length);
    const prevAmount = toString(parsedQuantity);
    const amount = toString(quantity);
    const value = formatAmountWithHistory(prevAmount)(amount);

    return (
      <div className={styles.inputWrapper}>
        <TextField
          name="quantity"
          type="number"
          step="0.001"
          onChange={this.onQuantityChange}
          value={value}
          invalid={hasErrors}
          className={styles.input}
          inputClassName={cx(styles.inputContent, {
            [styles.inputDisabled]: !isEditable,
          })}
          labelClassName={cx({
            [styles.labelDisabled]: !isEditable,
          })}
          disabled={!isEditable}
          placeholder={this.isMobileUserAgent ? undefined : placeholder}
          isLabelHidden
          required
          inputProps={this.sharedInputProps}
        />
      </div>
    );
  };

  createSelectRenderer =
    ({ disabled = false } = {}) =>
    (name, { options = [] }) => {
      const { values, isEditable } = this.state;
      const placeholder = isEditable ? t(`forms.line_items.placeholders.${name}`) : '';
      const noResultsPlaceholder =
        name === 'vat' ? t(`forms.line_items.placeholders.no_results_vat`) : '';

      return (
        <SelectField
          name={name}
          value={values[name]}
          onChange={this.handleSelect(name)}
          options={options.map((o) => ({ id: o.id, value: o.value, label: o.label }))}
          className={cx(styles.select, {
            [styles.selectDisabled]: !isEditable,
          })}
          selectClassName={styles.selectControl}
          labelClassName={cx({
            [styles.selectLabelDisabled]: !isEditable,
          })}
          disabled={!isEditable || disabled}
          disableNative
          placeholder={placeholder}
          noResultsText={noResultsPlaceholder}
        />
      );
    };

  renderNetAmount = (name, { onChange = null } = {}) => {
    const { values, errors: allErrors, isEditable } = this.state;
    const placeholder = isEditable ? `${t('forms.line_items.placeholders.price_netto')} *` : '';
    const errors = allErrors[name];
    const hasErrors = Boolean((errors || []).length);
    const value = values[name] ?? undefined;
    const defaultValue = ensureNumericMoneyValue(value);

    return (
      <div className={cx(styles.inputWrapper, styles.inputNetAmount)}>
        <TextField
          name={name}
          defaultValue={defaultValue}
          type="number"
          step="0.001"
          invalid={hasErrors}
          dataId={`LineItem:input-${name}`}
          className={styles.input}
          inputClassName={styles.inputContent}
          onChange={onChange || this.handleChange(name)}
          onPaste={(e) => {
            e.preventDefault();
            const pastedValue = e.clipboardData.getData('text/plain');
            const valueAsNumber = ensureNumericMoneyValue(pastedValue);
            e.target.value = valueAsNumber;
            typeof onChange === 'function'
              ? onChange(e)
              : this.handleChange(name)({
                  ...e,
                  value: valueAsNumber,
                });
          }}
          placeholder={this.isMobileUserAgent ? undefined : placeholder}
          isLabelHidden
          isCurrency
          inputProps={this.sharedInputProps}
        />
      </div>
    );
  };

  renderPositionText = (name, provided) => {
    const { readonly } = this.props;
    const { position, itemNumber, description } = this.state.values;
    const secondaryText =
      itemNumber && description ? `${itemNumber}, ${description}` : itemNumber || description;

    return (
      <TableCell name={name} className={styles.positionCell} customWidth>
        <div className={localStyles.positionTextContainer}>
          {!readonly && (
            <div className={localStyles.dragHandler} {...provided.dragHandleProps}>
              <IconDragAndDrop />
            </div>
          )}
          <div className={localStyles.positionTextWrapper}>
            <span className={localStyles.positionTextHeader}>
              <CellContentWithTooltip>{position}</CellContentWithTooltip>
            </span>
            {secondaryText && (
              <span className={localStyles.positionTextDescription}>
                <CellContentWithTooltip>{secondaryText}</CellContentWithTooltip>
              </span>
            )}
          </div>
        </div>
      </TableCell>
    );
  };

  renderPositionSelect = () => {
    const { isEditable } = this.state;
    const errors = this.state.errors.position;
    const hasErrors = Boolean((errors || []).length);

    return (
      <TableCell
        name="position"
        className={cx(styles.positionCell, {
          [styles.positionCellVertical]: this.isMobileUserAgent,
        })}
        customWidth
      >
        {this.isMobileUserAgent && (
          <div className={cx(localStyles.cellHeader, styles.cellHeaderVertical)}>
            {t('forms.line_items.placeholders.position.select')}
          </div>
        )}
        <div className={cx(styles.inputWrapper, styles.positionSelectInput)}>
          <PositionSelectControl
            name="position"
            visible
            placeholder={
              this.isMobileUserAgent
                ? ''
                : `${t('forms.line_items.placeholders.position.select')} *`
            }
            dataId="LineItems:input-position"
            skipFetchOnMount={this.state.values.quantity}
            invalid={hasErrors}
            initialValue={this.state.values.position}
            onValueChange={this.setField('position')}
            onPositionSelect={this.handlePositionSelect}
            isInsertedAsGross={this.props.invoiceInsertedAsGross}
            className={styles.input}
            inputClassName={cx(styles.inputContent, {
              [styles.inputDisabled]: !isEditable,
            })}
            disabled={!isEditable}
          />
        </div>
        <InfoIcon
          className={styles.positionIcon}
          text={t('forms.line_items.position_select.info_icon')}
        />
      </TableCell>
    );
  };

  renderPositionInput = (name) => {
    const { isEditable } = this.state;
    const errors = this.state.errors[name];
    const hasErrors = Boolean((errors || []).length);

    if (!isEditable) return this.renderTextCell(name, { fullWidth: true });

    return (
      <TableCell name={name} className={styles.positionCell} customWidth>
        <div className={cx(styles.inputWrapper, styles.positionSelectInput)}>
          <TextField
            name={name}
            onChange={this.handleChange(name)}
            value={this.state.values[name]}
            invalid={hasErrors}
            className={styles.input}
            inputClassName={styles.inputContent}
            labelClassName={this.isMobileUserAgent && localStyles.darkLabelOnMobile}
            placeholder={`${t(`forms.line_items.placeholders.position.text`)} ${
              !this.isMobileUserAgent ? '*' : ''
            }`}
            dataId={`LineItem:input-${name}`}
            disabled={!isEditable}
            isLabelHidden={!this.isMobileUserAgent}
            shouldLabelStayUp={this.isMobileUserAgent}
            isPlaceholderVisible={!this.isMobileUserAgent}
            inputProps={this.sharedInputProps}
          />
        </div>
      </TableCell>
    );
  };

  renderFullWidthInput = (name, required = false) => {
    const errors = this.state.errors[name];
    const hasErrors = Boolean((errors || []).length);

    return (
      <TableCell name={name}>
        <div className={cx(styles.inputWrapper, localStyles.fullWidthInput)}>
          <TextField
            name={name}
            onChange={this.handleChange(name)}
            value={this.state.values[name]}
            invalid={hasErrors}
            className={styles.input}
            maxLength="200"
            inputClassName={styles.inputContent}
            required={required}
            placeholder={t(`forms.line_items.placeholders.${snakeCase(name)}`)}
            data-id={`LineItem:input-${name}`}
          />
        </div>
      </TableCell>
    );
  };

  renderItemNumber = (name) => {
    const errors = this.state.errors[name];
    const hasErrors = Boolean((errors || []).length);

    return (
      <TableCell name={name} className={styles.itemNumberCell} customWidth>
        <I18n t={this.translationPath('itemNumber')} className={localStyles.cellHeader} />
        <div className={cx(styles.inputWrapper)}>
          <TextField
            name={name}
            onChange={this.handleChange(name)}
            value={this.state.values[name]}
            invalid={hasErrors}
            className={cx(styles.input, {
              [localStyles.numberInputContainer]: !this.isMobileUserAgent,
            })}
            inputClassName={styles.inputContent}
            labelContentClassName={this.isMobileUserAgent ? '' : localStyles.numberInputLabel}
            label={t(`forms.line_items.placeholders.item_number_label`)}
            placeholder={
              this.isMobileUserAgent
                ? undefined
                : t(`forms.line_items.placeholders.item_number_placeholder`)
            }
            isLabelHidden={this.isMobileUserAgent}
            shouldLabelStayUp
            dataId={`LineItem:input-${name}`}
          />
        </div>
      </TableCell>
    );
  };

  renderItemNumberSelect = (name) => {
    const errors = this.state.errors[name];
    const hasErrors = Boolean((errors || []).length);

    return (
      <TableCell name={name} className={styles.itemNumberSelectCell}>
        <span className={localStyles.cellHeader}>{t(this.translationPath('itemNumber'))}</span>
        <div className={styles.inputWrapper}>
          <ItemNumberSelect
            name={name}
            onChange={this.handleChange(name)}
            invalid={hasErrors}
            className={styles.input}
            value={this.state.values.itemNumber}
            initialValue={this.state.values.itemNumber}
            inputClassName={styles.inputContent}
            label={t(`forms.line_items.placeholders.item_number_label`)}
            placeholder={t(`forms.line_items.placeholders.item_number_placeholder`)}
            onValueChange={this.setField('itemNumber')}
            inputProps={this.sharedInputProps}
          />
        </div>
      </TableCell>
    );
  };

  renderUnitsSelect = () => {
    const { isEditable } = this.state;
    const placeholder = isEditable ? t('forms.line_items.placeholders.unit') : '';
    const errors = this.state.errors.unit;
    const hasErrors = Boolean((errors || []).length);

    return (
      <TableCell name="unit">
        <I18n t={this.translationPath('unit')} className={localStyles.cellHeader} />
        <div className={cx(styles.inputWrapper, styles.unitSelectInput)}>
          <UnitSelect
            name="unit"
            visible
            required
            invalid={hasErrors}
            initialValue={this.state.values.unit}
            onValueChange={this.setField('unit')}
            className={styles.input}
            placeholder={this.isMobileUserAgent ? undefined : placeholder}
            isLabelHidden
            inputProps={this.sharedInputProps}
          />
        </div>
      </TableCell>
    );
  };

  renderCheckbox = (name, info, isDisabled) => {
    const { isEditable } = this.state;

    return (
      <TableCell name="name">
        <I18n t={this.translationPath(name)} className={localStyles.cellHeader} />
        <span className={styles.cellIcon}>
          <InfoIcon text={info} />
        </span>
        <div className={cx(styles.inputWrapper, styles.checkboxInput)}>
          <RawCheckbox
            name={name}
            disabled={!isEditable || isDisabled}
            containerClassName={styles.cellCheckbox}
            onChange={this.handleCheckbox(name)}
            checked={this.state.values[name]}
          />
        </div>
      </TableCell>
    );
  };

  renderLoadingRow = () => (
    <LineItemContainer>
      <TableSubRow className={localStyles.loadingRow}>
        <TableCell name="loading" className={cx(localStyles.loadingCell)}>
          <LoadingIcon />
        </TableCell>
      </TableSubRow>
    </LineItemContainer>
  );

  renderNetLinked = () => {
    const { isEditable } = this.state;
    if (!isEditable) {
      return this.renderTextCell('netAmount', {
        fullWidth: false,
        textType: 'currency',
        rounded: this.hasDistinctGrossNetMode,
      });
    }

    return (
      <TableCell name="netAmount">
        <I18n t={this.translationPath('netAmount')} className={localStyles.cellHeader} />
        {this.renderNetAmount('netAmount', {
          onChange: this.onPriceChange(['netAmount', 'grossAmount']),
        })}
      </TableCell>
    );
  };

  renderGrossLinked = () => {
    const { isEditable } = this.state;

    if (!isEditable) {
      return this.renderTextCell('grossAmount', {
        fullWidth: false,
        textType: 'currency',
        rounded: this.hasDistinctGrossNetMode,
      });
    }

    return (
      <TableCell name="grossAmount">
        <I18n t={this.translationPath('grossAmount')} className={localStyles.cellHeader} />
        {this.renderNetAmount('grossAmount', {
          onChange: this.onPriceChange(['grossAmount', 'netAmount']),
        })}
      </TableCell>
    );
  };

  renderCounterInput = (name, required = false) => {
    const errors = this.state.errors[name];
    const hasErrors = Boolean((errors || []).length);

    return (
      <TableCell customHeight>
        <TextInputWithCounter
          name={name}
          placeholder={this.isMobileUserAgent ? undefined : t(this.translationPath(name))}
          label={this.isMobileUserAgent ? t(this.translationPath(name)) : undefined}
          invalid={hasErrors}
          required={required}
          onChange={this.handleChange(name)}
          value={this.state.values[name]}
          dataTestId={`LineItem:input-${name}`}
          isPlaceholderVisible={false}
        />
      </TableCell>
    );
  };

  renderCell = (
    {
      name = '',
      type,
      renderName = '',
      info = false,
      editOnly,
      options = this.props.taxRates,
      textType = '',
      className,
    },
    provided
  ) => {
    const { isEditable } = this.state;
    // TODO use hook when component will be a function
    const isDesktop = isXlgUp();
    let renderer;

    switch (type) {
      case 'input':
        if (!isEditable) return this.renderTextCell(name);
        renderer = this.renderInput;
        break;
      case 'quantity':
        if (!isEditable)
          return this.renderTextCell(
            name,
            {
              textType: 'number',
              options: { delimiter: '.', separator: ',', strip_insignificant_zeros: true },
            },
            className
          );
        renderer = this.renderQuantity;
        break;
      case 'select':
        if (!isEditable) return this.renderTextCell(name);
        renderer = this.createSelectRenderer();
        break;
      case 'multiselect':
        if (!isEditable) return this.renderTextCell(renderName || name);
        return this.renderMultiLevelSelect(name, info);
      case 'text':
        renderer = this.renderText;
        break;
      case 'vat':
        if (!isEditable) return this.renderTextCell(name, { textType: 'percentage' });
        return this.createSelectRenderer();
      case 'netLinked':
        return this.renderNetLinked(name);
      case 'grossLinked':
        return this.renderGrossLinked(name);
      case 'positionSelect':
        if (isEditable) return this.renderPositionSelect(name);
        if (!isDesktop) return this.renderTextCell(name, { fullWidth: true });
        return this.renderPositionText(name, provided);
      case 'positionInput':
        if (isEditable) return this.renderPositionInput(name);
        if (!isDesktop) return this.renderTextCell(name, { fullWidth: true });
        return this.renderPositionText(name, provided);
      case 'itemNumber':
        if (!isEditable) return this.renderTextCell(name);
        return this.renderItemNumber(name);
      case 'itemNumberSelect':
        if (!isEditable) return this.renderTextCell(name);
        return this.renderItemNumberSelect(name);
      case 'fullWidthInput':
        if (!isEditable) return this.renderTextCell(name, { fullWidth: true });
        return this.renderFullWidthInput(name);
      case 'unitSelect':
        if (!isEditable) return this.renderTextCell(name);
        return this.renderUnitsSelect(name);
      case 'counterInput':
        return this.renderCounterInput(name);
      default:
        renderer = noop;
    }

    if (editOnly && !isEditable) {
      renderer = this.renderText;
    }

    if (!name) {
      return <TableCell />;
    }

    const translationPath = this.props.absoluteTranslationPath
      ? `${this.props.absoluteTranslationPath}.${snakeCase(name)}`
      : `${this.props.translationPath}.form.details_section.fields.${snakeCase(name)}`;

    return (
      <TableCell name={name} key={name} className={className}>
        <I18n t={translationPath} className={localStyles.cellHeader} />
        {info && (
          <span className={styles.cellIcon}>
            <InfoIcon text={info} />
          </span>
        )}
        {renderer(name, { textType, options })}
      </TableCell>
    );
  };

  setErrors = (errors) => this.setState({ errors });

  render() {
    if (this.state.isLoading) {
      return this.renderLoadingRow();
    }
    const {
      cells = [],
      status,
      dndId,
      dndIndex,
      readonly,
      isDragDisabled,
      extendedRowCells = [],
      productCatalogItemsEnabled,
      addProductHidden,
      createProductCatalogItem,
      updateProductCatalogItem,
    } = this.props;
    const { values, isEditable } = this.state;
    const isDesktop = isXlgUp();
    const isPaidOrPartlyPaid =
      status === InvoiceStatuses.PAID || status === InvoiceStatuses.PARTLY_PAID;

    return (
      <Draggable
        draggableId={`id-${dndId}`}
        index={dndIndex}
        isDragDisabled={isDragDisabled || isEditable || readonly || !isDesktop}
      >
        {(provided, snapshot) => (
          <div
            className={cx(styles.lineItemRow, {
              [styles.lineItemRowExtended]: !isEmpty(extendedRowCells),
              [styles.lineItemRowFixed]: !isEditable,
              [styles.lineItemRowMoving]: snapshot.isDragging,
            })}
            data-id="LineItem:edit-row"
            ref={provided.innerRef}
            innerRef={provided.innerRef}
            {...provided.draggableProps}
          >
            <LineItemContainer
              onClick={() => this.setFocused(true)}
              outsideClickHandler={() => this.handleClickOutside()}
              outsideClickIgnoreClass={IGNORE_OUTSIDE_CLICK_CLASS}
            >
              <div className={localStyles.detailsSection}>
                <TableSubRow>
                  <TableCell className={styles.hiddenInput}>
                    <input hidden name="id" value={this.props.lineItem.id} />
                  </TableCell>
                  {cells.map((cell) => this.renderCell(cell, provided))}
                  {!isDesktop && extendedRowCells.map((cell) => this.renderCell(cell))}
                </TableSubRow>
                {isDesktop && isEditable && !isEmpty(extendedRowCells) && (
                  <TableSubRow>{extendedRowCells.map((cell) => this.renderCell(cell))}</TableSubRow>
                )}
                {isEditable && (
                  <AddProductButton
                    productCatalogItemsEnabled={productCatalogItemsEnabled}
                    addProductHidden={addProductHidden}
                    createProductCatalogItem={createProductCatalogItem}
                    updateProductCatalogItem={updateProductCatalogItem}
                    parseValues={parseValues}
                    values={values}
                    setErrors={this.setErrors}
                  />
                )}
              </div>
              <If ok={!this.state.isLoading && !readonly}>
                <TableCell name="actions" className={styles.actionsCell} customWidth>
                  <button
                    className={styles.action}
                    onClick={this.onEdit}
                    type="button"
                    title={
                      isEditable
                        ? t('forms.line_items.actions.submit')
                        : t('forms.line_items.actions.edit')
                    }
                    data-id={isEditable ? 'LineItem:button-submit' : 'LineItem:button-edit'}
                  >
                    <div
                      className={cx({
                        [styles.actionIconEdit]: !isEditable,
                        [localStyles.editIcon]: !isEditable,
                        [styles.actionIconAccept]: isEditable,
                        [localStyles.acceptIcon]: isEditable,
                      })}
                    />
                    <I18n
                      t={
                        isEditable
                          ? 'forms.line_items.actions.submit'
                          : 'forms.line_items.actions.edit'
                      }
                      className={styles.actionLabel}
                    />
                  </button>
                  <button
                    className={styles.action}
                    onClick={isEditable ? this.handleCancellation : this.handleDeletion}
                    type="button"
                    title={
                      isEditable
                        ? t('forms.line_items.actions.cancel')
                        : t('forms.line_items.actions.delete')
                    }
                    data-id={isEditable ? 'LineItem:button-cancel' : 'LineItem:button-delete'}
                  >
                    <div
                      className={
                        isEditable
                          ? cx(styles.actionIconCancel, localStyles.cancelIcon)
                          : cx(styles.actionIconDelete, localStyles.deleteIcon)
                      }
                    />
                    <I18n
                      t={
                        isEditable
                          ? 'forms.line_items.actions.cancel'
                          : 'forms.line_items.actions.delete'
                      }
                      className={styles.actionLabel}
                    />
                  </button>
                </TableCell>
              </If>
              <If ok={this.state.isLoading}>
                <TableCell
                  name="loading"
                  className={cx(localStyles.actionsCell, localStyles.loadingCell)}
                  customWidth
                >
                  <LoadingIcon />
                </TableCell>
              </If>
            </LineItemContainer>
            {isPaidOrPartlyPaid && (
              <ConfirmationModal
                dangerousAction
                isOpen={this.state.isLineEditModalVisible}
                onClose={this.closeModal}
                onConfirm={this.onEdit}
                closeLabel={t('revenue.delete.cancel')}
                confirmLabel={t('modals.confirmation.ok')}
              >
                <I18n t="modals.invoice_details_section" />
              </ConfirmationModal>
            )}
            {isPaidOrPartlyPaid && (
              <ConfirmationModal
                dangerousAction
                isOpen={this.state.isRemoveItemModalVisible}
                onClose={this.closeModal}
                onConfirm={this.handleDeletion}
                closeLabel={t('revenue.delete.cancel')}
                confirmLabel={t('modals.confirmation.ok')}
              >
                <I18n t="modals.invoice_details_section" />
              </ConfirmationModal>
            )}
          </div>
        )}
      </Draggable>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearAssignedBankTransfers: () => dispatch(clearAssignedBankTransfersAction),
  fetchProductCatalogItem: (id) => dispatch(fetchProductCatalogItem(id)),
  createProductCatalogItem: (values, redirect, modifier) =>
    dispatch(createProductCatalogItemAction(values, redirect, modifier)),
  updateProductCatalogItem: (values, redirect, isNotifyingAction) =>
    dispatch(updateProductCatalogItemAction(values, redirect, isNotifyingAction)),
});

export default connect(null, mapDispatchToProps)(EditItemRow);
