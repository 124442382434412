import React, { memo } from 'react';
import { Field, Validator } from 'redux-form';

import { SelectField } from 'components/Form';

import { IntervalOptions } from '../constants';

// TODO add SelectFieldProps when SelectField will support TS
type SelectOption = {
  label: string;
  value: string | number;
};

type IntervalProps = {
  name: string;
  label: string;
  checker?: (props: any) => boolean;
  validate?: Validator;
  options?: SelectOption[];
  isLabelHidden?: boolean;
  dataId?: string;
  required?: boolean;
  disabled?: boolean;
};

const Interval = ({
  name,
  label,
  isLabelHidden,
  checker,
  validate,
  dataId,
  required,
  options,
  disabled,
  ...rest
}: IntervalProps) => (
  <Field
    name={name}
    label={isLabelHidden ? '' : label}
    component={SelectField}
    options={options || IntervalOptions}
    isLabelHidden={isLabelHidden}
    checker={checker}
    validate={validate}
    dataId={dataId}
    required={required}
    disabled={disabled}
    {...rest}
  />
);

export default memo(Interval);
