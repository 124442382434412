import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchIncomingInvoice } from 'actions/incoming-invoice';
import { fetchPayments, getSca } from 'actions/incoming-invoice/payments';
import { showErrorNotification } from 'actions/notification';
import useIsMounted from 'shared/utils/hooks/useIsMounted';
import { trackCreatePaymentSuccessEvent } from 'shared/utils/tracking/payments-tracking';
import { RedirectChallenge as RedirectChallengeType } from 'types/entities/Figo';
import RedirectChallengeComponent from 'features/figoConnection/challenges/Redirect/Redirect';
import {
  ERROR_NOTIFICATION_DURATION,
  REDIRECT_CHALLENGE_RETRY_TIMEOUT,
} from 'features/figoConnection/constants';

import { Wrapper } from './RedirectChallenge.styled';

type RedirectChallengeProps = {
  payment: {
    accountId: string;
    paymentId: string;
  };
  syncId: string;
  challenge: RedirectChallengeType;
  invoiceId: string;
  invoiceAmount: number;
  paymentDetails: { amount: string };
  setCreatorState: (state: any) => void;
  goToLastStepAction: () => void;
  resetCreatorState: () => void;
  backwardAction: () => void;
};

const RedirectChallenge = ({
  challenge,
  payment,
  syncId,
  goToLastStepAction,
  setCreatorState,
  invoiceId,
  paymentDetails,
  invoiceAmount,
  resetCreatorState,
  backwardAction,
}: RedirectChallengeProps) => {
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const { amount: paymentAmount } = paymentDetails;
  const { paymentId: paymentFigoId, accountId: accountFigoId } = payment;

  useEffect(() => {
    let timer: number | undefined;
    const checkSca = async () => {
      let response: any;

      try {
        response = await dispatch(
          getSca({
            paymentFigoId,
            accountFigoId,
            syncId,
          })
        );
      } catch (e: any) {
        if (e.message) {
          dispatch(showErrorNotification(e.message, { duration: ERROR_NOTIFICATION_DURATION }));
        }
        resetCreatorState();
        return;
      }

      if (!isMounted()) {
        return;
      }

      const { submitted, created_at } = response;

      if (submitted) {
        trackCreatePaymentSuccessEvent({ paymentAmount, invoiceAmount });
        setCreatorState({ paymentCreationDate: created_at });
        dispatch(fetchPayments(invoiceId));
        dispatch(fetchIncomingInvoice(invoiceId));
        goToLastStepAction();
        return;
      }

      timer = window.setTimeout(checkSca, REDIRECT_CHALLENGE_RETRY_TIMEOUT);
    };

    timer = window.setTimeout(checkSca, REDIRECT_CHALLENGE_RETRY_TIMEOUT);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Wrapper>
      <RedirectChallengeComponent challenge={challenge} onCancel={backwardAction} />
    </Wrapper>
  );
};

export default RedirectChallenge;
