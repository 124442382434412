export const BASIC_UID = 'basic';
export const PREMIUM_UID = 'premium';

export const BOOK_PREMIUM = 'BOOK_PREMIUM';
export const SWTICH_TO_BASIC = 'SWTICH_TO_BASIC';
export const DIRECT_DEBIT = 'DIRECT_DEBIT';
export const CONFIRMATION = 'CONFIRMATION';
export const PAYMENT_PLAN_WIZARD = 'PAYMENT_PLAN_WIZARD';
export const BANK_ACCOUNT = 'BANK_ACCOUNT';

const NAMESPACE = 'ALL_PAYMENT_PLANS';

export const FETCH_REQUEST = `${NAMESPACE}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${NAMESPACE}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${NAMESPACE}/FETCH_FAILURE`;

export const DOWNGRADE = 'downgrade';
export const UPGRADE = 'upgrade';
export const TRIAL = 'trial';
export const DOWNGRADE_CANCELLATION = 'downgrade_cancellation';

// this one is because api sends null for currentPlanEndDate ONLY when something went really wrong on backend side.
// For all other cases this is date string or N/A
export const NO_CURRENT_PLAN_END_DATE = 'N/A';

export const COMPANY_EDIT_FORM_NAME = 'company-edit-form';
