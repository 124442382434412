import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import * as moment from 'moment';
import { ThunkDispatch } from 'redux-thunk';

import { download as downloadAction } from 'actions/csv-download';
import { t } from 'shared/utils';
import { Dispatch } from 'types/actions';
import IconDownload from 'components/Icons/IconDownload';
import { Label } from 'components/Label/Label';

import styles from './CSVDownloadButton.module.css';

interface FilterRangeDate {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

interface Filters {
  creationDate?: FilterRangeDate;
  dueDate?: FilterRangeDate;
  amountHigherThan?: number;
  amountLowerThan?: number;
  fullTextSearch?: string;
}

interface CSVDownloadButtonPassProps {
  filters?: Filters;
  sorting?: any;
  endpoint: string;
  className?: string;
  disabled?: boolean;
  dataId?: string;
}

interface DownloadArguments {
  filters?: Filters;
  sorting?: any;
  endpoint: string;
}

interface CSVDownloadButtonDispatchProps {
  download: (arg: DownloadArguments) => Dispatch;
}

type CSVDownloadButtonProps = CSVDownloadButtonPassProps & CSVDownloadButtonDispatchProps;

const CSVDownloadButton: FunctionComponent<CSVDownloadButtonProps> = ({
  download,
  filters = {},
  endpoint,
  sorting,
  disabled = false,
  className = '',
  dataId = 'CSVExport:button',
}) => (
  <Label
    onClick={() => download({ filters, endpoint, sorting })}
    className={cx(className, {
      [styles.containerDisabled]: disabled,
    })}
    dataId={dataId}
  >
    <IconDownload
      className={cx(styles.icon, { [styles.disabled]: disabled })}
      width="24"
      height="24"
    />{' '}
    <span>{t('tables.csv_export')}</span>
  </Label>
);

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  download: (data: DownloadArguments) =>
    dispatch(
      downloadAction(data as { endpoint: string; filters: Filters; sorting?: {} | undefined })
    ),
});

export default connect<{}, CSVDownloadButtonDispatchProps, CSVDownloadButtonPassProps>(
  null,
  mapDispatchToProps
)(CSVDownloadButton);
