import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { parse } from 'query-string';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import { Field } from 'redux-form';

import { CANCEL_MODE, NEW_MODE, SHOW_MODE } from 'constants/common/crud';
import { FORM_NAME } from 'constants/order-confirmation';
import { FROM_PROPOSAL_QUERY_PARAM } from 'constants/proposal';
import Remark from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/CreateInvoiceSection/Remark';
import IndividualContactSection from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/IndividualContactSection';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import If from 'components/Conditions/If';
import I18n from 'components/I18n';
import { LineItemsAutoSaveConsumer, LineItemsAutoSaveProvider } from 'components/LineItems';
import HtmlField from 'components/v2/Form/HtmlField/HtmlField';

import ActionsSection from './ActionsSection';
import CreateOrderConfirmationSection from './CreateOrderConfirmationSection/CreateOrderConfirmationSection';
import Notes from './CreateOrderConfirmationSection/Notes/Notes';
import { mapDispatchToProps, mapStateToProps, mergeProps } from './helper';
import OrderConfirmationDetailsSection from './OrderConfirmationDetailsSection/OrderConfirmationDetailsSection';
import OrderConfirmationSumSection from './OrderConfirmationSumSection/OrderConfirmationSumSection';

import styles from './OrderConfirmationCreator.module.css';

export const isRedirectFromProposals = ({ search }) =>
  Object.keys(parse(search)).includes(FROM_PROPOSAL_QUERY_PARAM);

const OrderConfirmationCreator = ({
  fetchCompanyInfo,
  clearDefaultValues,
  fetchClientDefaults,
  currentValues,
  isSubjectDirty,
  change,
  initialValues,
  abort,
  canUpdate,
  canTransform,
  dirty,
  download,
  handleSubmit,
  orderConfirmation,
  save,
  saveAsDraft,
  saveAndTransform,
  submitting,
  transform,
  currentClient,
  individualContact = false,
  salutationHonorific,
  sendingDocumentsByEmailEnabled = false,
  crudMode,
  orderConfirmationDetails,
  deliveryDate,
  saveWithoutRedirect,
  hasSavedLineItems,
  lineCategories,
  defaultCategory,
  canSubmit,
  selectedCategory,
  taxRates,
  taxRateId,
  defaultVat,
  isInitial,
  setLineItemState,
  isDeprecatedCategory,
  handleSelectCategory,
  category,
  showCategoryChangeConfirmation,
  setShowCategoryChangeConfirmation,
  showVatIdWarning,
  setShowVatIdWarning,
  showDifferentCategoryWarning,
  setShowDifferentCategoryWarning,
}) => {
  const isReadOnly = crudMode === SHOW_MODE || crudMode === CANCEL_MODE;
  const orderConfirmationId = orderConfirmation.details.id;
  const showSalutation = !(isReadOnly && !individualContact);
  const isNew = crudMode === NEW_MODE;

  const handleClientSelected = async (client) => {
    if (!client) return;

    const subject = currentValues ? currentValues.subject : null;

    await fetchClientDefaults(client.id);

    if (isSubjectDirty && !initialValues.subject) change('subject', subject);

    change('client', client);
  };

  useEffect(() => {
    fetchCompanyInfo();

    return () => {
      clearDefaultValues();
    };
  }, []);

  return (
    <form>
      <LineItemsAutoSaveProvider>
        <div className={styles.main}>
          <div className={cx(styles.section, styles.creator)}>
            <CreateOrderConfirmationSection
              onClientSelect={handleClientSelected}
              readonly={isReadOnly}
              lineCategories={lineCategories}
              category={category}
              hasSavedLineItems={hasSavedLineItems}
              defaultCategory={defaultCategory}
              isDeprecatedCategory={isDeprecatedCategory}
              handleSelectCategory={handleSelectCategory}
              crudMode={crudMode}
              showCategoryChangeConfirmation={showCategoryChangeConfirmation}
              setShowCategoryChangeConfirmation={setShowCategoryChangeConfirmation}
              showVatIdWarning={showVatIdWarning}
              setShowVatIdWarning={setShowVatIdWarning}
              showDifferentCategoryWarning={showDifferentCategoryWarning}
              setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
            />
            <If ok={showSalutation}>
              <IndividualContactSection
                change={change}
                client={currentClient}
                disabled={isReadOnly}
                salutationHonorific={salutationHonorific}
                dataIds={{
                  salutation: 'OrderConfirmationPage:input-salutation',
                  salutationContent: 'OrderConfirmationPage:input-salutation-content',
                }}
              >
                {!!deliveryDate && (
                  <div className={styles.footnote}>
                    <label htmlFor="deliveryInfo">
                      <I18n t="revenue.form.individual_contact.order_confirmation_delivery_info" />
                    </label>
                    <Field
                      dataId="deliveryInfo"
                      name="deliveryInfo"
                      disabled={false}
                      component={HtmlField}
                      checker={checker}
                    />
                  </div>
                )}
              </IndividualContactSection>
            </If>
            <OrderConfirmationDetailsSection
              crudMode={crudMode}
              readonly={isReadOnly}
              defaultCategory={defaultCategory}
              selectedCategory={selectedCategory}
              lineCategories={lineCategories}
              taxRates={taxRates}
              taxRateId={taxRateId}
              defaultVat={defaultVat}
              isInitial={isInitial}
              setLineItemState={setLineItemState}
              setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
            />
            <div className={styles.bottomSection}>
              <Notes disabled={isReadOnly} />
              <OrderConfirmationSumSection
                insertedAsGross={orderConfirmationDetails.insertedAsGross}
              />
            </div>
            <Remark
              dataId="OrderConfirmationPage:notes"
              readonly={isReadOnly}
              hint={t('features.order_confirmation.form.remark_hint')}
            />
          </div>
        </div>
        <LineItemsAutoSaveConsumer>
          {({ handleSave }) => (
            <ActionsSection
              currentClient={currentClient}
              wrapped={!isReadOnly}
              abort={abort}
              isFormDirty={dirty}
              isFormSubmitting={submitting}
              readonly={isReadOnly}
              showSave={canUpdate}
              showTransform={canTransform}
              download={download(orderConfirmation.details)}
              save={handleSave(handleSubmit(save(orderConfirmationId, isNew)))}
              saveAsDraft={handleSave(handleSubmit(saveAsDraft(orderConfirmationId, isNew)))}
              saveWithoutRedirect={handleSave(
                handleSubmit(saveWithoutRedirect(orderConfirmationId, isNew))
              )}
              saveAndTransform={handleSave(
                handleSubmit(saveAndTransform(orderConfirmationId, isNew))
              )}
              transform={handleSave(handleSubmit(transform(orderConfirmationId)))}
              sendingDocumentsByEmailEnabled={sendingDocumentsByEmailEnabled}
              crudMode={crudMode}
              canSubmit={canSubmit}
            />
          )}
        </LineItemsAutoSaveConsumer>
      </LineItemsAutoSaveProvider>
    </form>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    persistentSubmitErrors: true,
  }),
  withTransitionPrevent()
)(OrderConfirmationCreator);
