import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchOverview } from 'actions/monthly-overview/monthly-overview';
import { EXPENSES } from 'constants/tables';
import Card from 'components/Card';
import If from 'components/Conditions/If';
import I18n from 'components/I18n';
import LoadingIcon from 'components/LoadingIcon';
import Table from 'components/MonthlyOverviewTable';

import Chart from './chart';

import styles from './Chart.module.css';

class ExpensesChart extends Component {
  componentDidMount() {
    this.props.fetchOverview(false, 12);
  }

  render() {
    const { isFetching, data } = this.props;

    return (
      <Card className={styles.root}>
        <Card.Header>
          <I18n t="expenses.chart.header" className={styles.headingText} />
        </Card.Header>
        <Card.Body>
          <div className={styles.body}>
            <If ok={isFetching}>
              <LoadingIcon />
            </If>
            <If ok={!isFetching}>
              <Chart data={data} />
            </If>

            <If ok={!isFetching}>
              <Table type={EXPENSES} />
            </If>
          </div>
        </Card.Body>
      </Card>
    );
  }
}

ExpensesChart.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  fetchOverview: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  isFetching: state.monthlyOverview.isFetching,
  data: state.monthlyOverview.revenueExpenses,
});

export default connect(mapStateToProps, { fetchOverview })(ExpensesChart);
