export const UPDATE_REQUEST = 'PROFILE/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'PROFILE/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'PROFILE/UPDATE_FAILURE';

export const FETCH_REQUEST = 'PROFILE/FETCH_REQUEST';
export const FETCH_SUCCESS = 'PROFILE/FETCH_SUCCESS';
export const FETCH_FAILURE = 'PROFILE/FETCH_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'PROFILE/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'PROFILE/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'PROFILE/CHANGE_PASSWORD_FAILURE';

export const CHANGE_EMAIL_REQUEST = 'PROFILE/CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCESS = 'PROFILE/CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAILURE = 'PROFILE/CHANGE_EMAIL_FAILURE';
