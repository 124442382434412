import React from 'react';

import IconTransferToInvoice from 'components/ActionIcons/IconTransferToInvoice';

import Action from './Action';

const ActionTransformFromOutgoingInvoice = ({ ...props }) => (
  <Action icon={IconTransferToInvoice} {...props} />
);

export default ActionTransformFromOutgoingInvoice;
