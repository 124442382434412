import React from 'react';
import { connect } from 'react-redux';
import flatten from 'flat';
import { filter, some } from 'lodash';
import { bool, func, shape } from 'prop-types';

import {
  clearFilters as clearFiltersAction,
  fetchProductCatalogItems as fetchProductCatalogItemsAction,
  setFilterByTextActive as setFilterByTextActiveAction,
  setQueryParam as setQueryParamAction,
} from 'actions/product-catalog/items/items';
import {
  CATEGORY_PRODUCT_CATALOG,
  PRODUCT_CATALOG_INDEX_HIDE_FILTER,
  PRODUCT_CATALOG_INDEX_RESET_FILTER,
  PRODUCT_CATALOG_INDEX_SHOW_FILTER,
} from 'constants/piwik';
import { PRODUCT_CATALOG_ITEMS } from 'constants/product-catalog/items';
import { invalidRangeChecker, t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import { FiltersGroup } from 'components/Filter/FiltersGroup/FiltersGroup';
import { Search, SearchGroup } from 'components/Search';

import styles from './Filters.module.css';

const sharedSearchProps = {
  isCurrency: true,
  shouldLabelStayUp: true,
};

const trackShowFilter = () =>
  piwikHelpers.trackEvent(CATEGORY_PRODUCT_CATALOG, PRODUCT_CATALOG_INDEX_SHOW_FILTER);
const trackHideFilter = () =>
  piwikHelpers.trackEvent(CATEGORY_PRODUCT_CATALOG, PRODUCT_CATALOG_INDEX_HIDE_FILTER);
const trackResetFilter = () =>
  piwikHelpers.trackEvent(CATEGORY_PRODUCT_CATALOG, PRODUCT_CATALOG_INDEX_RESET_FILTER);

const Filters = ({ filters, clearFilters, setQueryParam }) => {
  const hasAnySearchQuery = some(flatten(filter(filters, (_, key) => key !== 'status')));

  const isNetRangeInvalid = invalidRangeChecker({
    start: filters.net_amount_higher_than,
    end: filters.net_amount_lower_than,
  });
  const isGrossRangeInvalid = invalidRangeChecker({
    start: filters.gross_amount_higher_than,
    end: filters.gross_amount_lower_than,
  });

  return (
    <FiltersGroup
      className={styles.filtersGroup}
      isResetButtonDisabled={!hasAnySearchQuery}
      filters={filters}
      onFiltersChange={({ name, value }) => setQueryParam(name, value)}
      onFiltersDropdownToggle={(areFiltersVisible) => {
        if (areFiltersVisible) return trackShowFilter();

        return trackHideFilter();
      }}
      onFiltersReset={() => {
        trackResetFilter();
        clearFilters();
      }}
      placeholder={t('tables.filters.filters_group.product_catalog.placeholder')}
      dataIds={{
        input: 'ProductCatalog:input-search',
      }}
      additionalFilters={
        <SearchGroup
          setQueryAction={(name) => (value) => setQueryParam(name, value)}
          clearFieldAction={clearFilters}
          filters={filters}
        >
          <Search
            label={t('product_catalog.product_catalog_items.filter.net_from')}
            placeholder="0"
            name="net_amount_higher_than"
            invalid={isNetRangeInvalid}
            {...sharedSearchProps}
          />
          <Search
            label={t('product_catalog.product_catalog_items.filter.net_to')}
            placeholder="-"
            name="net_amount_lower_than"
            invalid={isNetRangeInvalid}
            {...sharedSearchProps}
          />
          <Search
            label={t('product_catalog.product_catalog_items.filter.gross_from')}
            placeholder="0"
            name="gross_amount_higher_than"
            invalid={isGrossRangeInvalid}
            {...sharedSearchProps}
          />
          <Search
            label={t('product_catalog.product_catalog_items.filter.gross_to')}
            placeholder="-"
            name="gross_amount_lower_than"
            invalid={isGrossRangeInvalid}
            {...sharedSearchProps}
          />
        </SearchGroup>
      }
    />
  );
};

Filters.propTypes = {
  isTextFilterActive: bool,
  filters: shape({}),
  clearFilters: func,
  setQueryParam: func,
  fetchProductCatalogItems: func.isRequired,
  setFilterByTextActive: func.isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.productCatalogItems[PRODUCT_CATALOG_ITEMS].params.filters,
  isTextFilterActive:
    state.productCatalogItems[PRODUCT_CATALOG_ITEMS].params.filters.forcedFilterActive,
});

const mapDispatchToProps = {
  setQueryParam: setQueryParamAction,
  clearFilters: clearFiltersAction,
  fetchProductCatalogItems: fetchProductCatalogItemsAction,
  setFilterByTextActive: setFilterByTextActiveAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
