import api from 'api';

export type GetDeliveryNoteData = {
  salutation_honorific: string;
  salutation_content: string;
  personal_notes: string;
};

export const getDeliveryNote = (params: GetDeliveryNoteData) => {
  const url = '/me/previews/delivery_note';

  return api.get<ArrayBuffer>(url, {
    params,
    responseType: 'arraybuffer',
  });
};
