import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { flow } from 'lodash';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { resetClientDefaults, updateClientDefaults } from 'actions/default-values';
import { ClientEmailsFormName } from 'containers/Clients/ClientEmailDefaults/ClientEmailDefaults';
import {
  DefaultValuesForm,
  FormFooterContainer,
  FormRow,
} from 'containers/Profile/InvoiceTemplate/DefaultValues/sharedComponents';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { RootState } from 'store';
import { DefaultValues } from 'types/entities/DefaultValues';
import { InfoTextField, TextareaField } from 'components/Form';
import InputsGroup from 'components/InputsGroup/InputsGroup';

import styles from './InvoiceEmailsDefaults.module.css';

const FORM_NAME: ClientEmailsFormName = 'ClientInvoiceEmailsDefaults';

type FormData = Pick<
  DefaultValues,
  | 'emailInvoiceSubject'
  | 'emailInvoiceSalutation'
  | 'emailInvoiceSalutationContent'
  | 'emailInvoiceSignature'
>;

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    emailInvoiceSubject: state.defaultValues.values.emailInvoiceSubject,
    emailInvoiceSalutation: state.defaultValues.values.emailInvoiceSalutation,
    emailInvoiceSalutationContent: state.defaultValues.values.emailInvoiceSalutationContent,
    emailInvoiceSignature: state.defaultValues.values.emailInvoiceSignature,
  },
});

const mapDispatchToProps = {
  updateClientDefaults,
  resetClientDefaults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type InvoiceEmailsDefaultsProps = ConnectedProps<typeof connector> & {
  clientId: string;
};

const InvoiceEmailsDefaults = ({
  resetClientDefaults,
  updateClientDefaults,
  handleSubmit,
  clientId,
  reset: resetForm,
}: InvoiceEmailsDefaultsProps & InjectedFormProps<FormData, InvoiceEmailsDefaultsProps>) => {
  return (
    <DefaultValuesForm
      onSubmit={handleSubmit((values) => updateClientDefaults(clientId, values, 'email_invoice'))}
      onReset={async () => {
        await resetClientDefaults(clientId, 'email_invoice');
        resetForm();
      }}
      sectionName="email_invoice"
      sectionLabel={t('invoice_templates.emails_defaults.invoice_defaults_section')}
      dataIdPrefix="ClientDocumentDefaults:"
    >
      <>
        <FormRow>
          <InputsGroup label={t('invoice_templates.emails_defaults.subject')}>
            <Field
              disabled
              className={styles.grouppedInput}
              name="emailInvoiceSubject"
              checker={serverValidationChecker}
              component={InfoTextField}
              message={t('invoice_templates.emails_defaults.subject_info')}
              dataId="ClientEmailDefaults:input-subject"
              isDisabledWithUnderline
            />
          </InputsGroup>
        </FormRow>
        <FormRow>
          <InputsGroup label={t('invoice_templates.emails_defaults.header_message')}>
            <Field
              name="emailInvoiceSalutation"
              component={InfoTextField}
              checker={serverValidationChecker}
              label={t('invoice_templates.emails_defaults.salutation')}
              message={t('invoice_templates.emails_defaults.salutation_info')}
              dataId="ClientEmailDefaults:input-salutation"
            />
            <Field
              name="emailInvoiceSalutationContent"
              component={TextareaField}
              checker={serverValidationChecker}
              label={t('invoice_templates.emails_defaults.salutation_content')}
              fullHeight
              dataId="ClientEmailDefaults:input-salutation-content"
            />
          </InputsGroup>
        </FormRow>
        <FormRow>
          <FormFooterContainer>
            <InputsGroup label={t('invoice_templates.emails_defaults.signature')}>
              <Field
                disabled
                className={styles.grouppedInput}
                name="emailInvoiceSignature"
                checker={serverValidationChecker}
                component={InfoTextField}
                message={t('invoice_templates.emails_defaults.signature_info')}
                dataId="ClientEmailDefaults:input-signature"
                isDisabledWithUnderline
              />
            </InputsGroup>
          </FormFooterContainer>
        </FormRow>
      </>
    </DefaultValuesForm>
  );
};

export default flow(
  withTransitionPrevent(),
  reduxForm<FormData, InvoiceEmailsDefaultsProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    persistentSubmitErrors: true,
  }),
  connector
)(InvoiceEmailsDefaults);
