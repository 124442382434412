import normalize from 'json-api-normalizer';
import { get, head } from 'lodash';
import moment from 'moment';
import build from 'redux-object';

import { DATE_FORMAT } from 'constants/datetime';
import {
  DELETE_MAYBE_SUGGESTION,
  FETCH_SUCCESS,
  TOGGLE_DISABLED_BUTTON_ON_LINE_ITEM_REQUEST,
} from 'constants/outgoing-invoice';
import { extend } from 'shared/utils';

const defaultState = {
  creationDate: moment().format(DATE_FORMAT),
  suggestedUnassignedBankTransfers: [],
  disabled: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS: {
      const { data } = action.response;
      const normalizedResponse = normalize(action.response || {});
      const outgoingInvoices = build(normalizedResponse, 'outgoingInvoices', undefined, {
        eager: true,
        ignoreLinks: true,
      });
      const { suggestedUnassignedBankTransfers } = head(outgoingInvoices) || {};

      const { attributes = {}, id, meta = {} } = data;
      const { discountPercentage = '' } = attributes;

      const suggestedBankTransfersWithType = (suggestedUnassignedBankTransfers || []).map(
        (bankTransfer) => ({
          ...bankTransfer,
          type: 'outgoing_invoice',
        })
      );
      return extend(state, {
        ...attributes,
        id,
        actions: meta.actions,
        suggestedUnassignedBankTransfers: suggestedBankTransfersWithType,
        discountPercentage: `${discountPercentage || ''}`.replace('.', ','),
        invoiceTemplateId: get(data, 'relationships.invoice-template.data.id'),
        isClientDeleted: get(outgoingInvoices, '[0].client.deleted', false),
      });
    }
    case DELETE_MAYBE_SUGGESTION:
      const { payload } = action;
      const suggestedTransfersWithoutSuggestion = state.suggestedUnassignedBankTransfers.filter(
        (transfer) => transfer.id !== payload.bankTransferId
      );

      return {
        ...state,
        suggestedUnassignedBankTransfers: suggestedTransfersWithoutSuggestion,
      };
    case TOGGLE_DISABLED_BUTTON_ON_LINE_ITEM_REQUEST:
      return {
        ...state,
        disabled: !state.disabled,
      };

    default:
      return state;
  }
};
