import React from 'react';
import cx from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import If from 'components/Conditions/If';
import styles from 'components/Form/TextField/TextField.module.css';

import RequiredAddon from '../RequiredAddon/RequiredAddon';

import localStyles from './StaticTextField.module.css';

const StaticTextField = (props) => {
  const {
    className,
    defaultValue,
    id,
    input = {},
    inputClassName,
    label,
    labelClassName,
    meta,
    options,
    placeholder,
    required = false,
    requiredClassName = '',
    haveFormValue = false,
    valuePrefix = '',
    valueSuffix = '',
    type,
    parsedValue = '',
    isWithoutUnderline = false,
    isWithoutEuroSign,
    dataId = '',
    ...rest
  } = props;
  const formValue =
    parsedValue ||
    (input && input.value) ||
    !!String(get(rest, 'value', '')).length ||
    defaultValue;
  const textValue = `${valuePrefix}${formValue !== undefined ? formValue : ''}${valueSuffix}`;

  return (
    <div className={cx(styles.wrapper, className)}>
      {haveFormValue && (
        <input
          type="hidden"
          disabled
          value={formValue}
          defaultValue={formValue}
          id={id || rest.name}
          name={rest.name || input.name}
        />
      )}
      <input
        className={cx(styles.main, localStyles.input, inputClassName, {
          [styles.mainFilled]: true,
          [styles.withoutUnderline]: isWithoutUnderline,
        })}
        disabled
        placeholder={textValue}
        defaultValue={textValue}
        value={textValue}
        type={type}
        data-id={dataId}
      />
      <label htmlFor={id || rest.name} className={cx(styles.label, labelClassName)}>
        <span className={styles.labelContent}>
          {label || placeholder}
          <If ok={required}>
            <RequiredAddon className={requiredClassName} />
          </If>
        </span>
        {!isWithoutEuroSign && <span className={styles.euroIconStaticField}>€</span>}
      </label>
      <span className={styles.iePlaceholder}>{placeholder}</span>
    </div>
  );
};

StaticTextField.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  haveFormValue: PropTypes.bool,
  id: PropTypes.string,
  input: PropTypes.shape({}),
  valuePrefix: PropTypes.string,
  valueSuffix: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  meta: PropTypes.shape({}),
  options: PropTypes.oneOfType([PropTypes.arrayOf({}), PropTypes.shape({})]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  requiredClassName: PropTypes.string,
  type: PropTypes.string,
  parsedValue: PropTypes.string,
  isWithoutUnderline: PropTypes.bool,
  isWithoutEuroSign: PropTypes.bool,
  dataId: PropTypes.string,
};

export default StaticTextField;
