import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bool, func, string } from 'prop-types';
import { Field } from 'redux-form';

import { clearBankTransfers, fetchAllBankTransfers } from 'actions/incoming-invoice';
import { toggleCreditNote as toggleCreditNoteAC } from 'actions/incoming-invoices';
import { incomingInvoiceSelector } from 'reducers/form';
import { t } from 'shared/utils';
import Checkbox from 'components/Form/Checkbox';
import I18n from 'components/I18n';
import InfoIcon from 'components/InfoIcon/InfoIcon';

import styles from './CreditNote.module.css';

const CreditNoteCheckbox = ({ disabled, ...rest }) => (
  <Checkbox preventWordBreak disabled={disabled} {...rest} className={styles.checkbox}>
    <I18n t="expenses.form.credit_note" />
    <InfoIcon text={t('expenses.form.credit_note_tooltip')} className={styles.infoIcon} />
  </Checkbox>
);

CreditNoteCheckbox.propTypes = {
  disabled: bool.isRequired,
};

class CreditNote extends Component {
  toggle = () =>
    this.props.toggleCreditNote(this.props.id, !this.props.creditNote).then(() => {
      this.props.clearTransfers();
      return this.fetchBankTransfers(this.props.creditNote);
    });

  checkboxRenderer = (props) => (fieldProps) =>
    <CreditNoteCheckbox onChange={this.toggle} {...props} {...fieldProps} />;

  fetchBankTransfers = (withCreditNote = false) => {
    const { fetchBankTransfers, id } = this.props;
    fetchBankTransfers(id, undefined, withCreditNote);
  };

  render() {
    const { readonly, disabled, hideIcon = false } = this.props;

    return (
      <div className={styles.formGroup}>
        <Field
          id="creditNote"
          name="creditNote"
          dataId="IncomingInvoices:checkbox-creditNote"
          component={this.checkboxRenderer({ disabled })}
          type="checkbox"
          disabled={disabled || readonly}
          hideIcon={hideIcon}
        />
      </div>
    );
  }
}

CreditNote.propTypes = {
  id: string.isRequired,
  creditNote: bool.isRequired,
  disabled: bool.isRequired,
  hideIcon: bool,
  readonly: bool,
  fetchBankTransfers: func.isRequired,
  clearTransfers: func.isRequired,
  toggleCreditNote: func.isRequired,
};

export default connect(
  (s) => ({
    id: incomingInvoiceSelector(s, 'id'),
    creditNote: incomingInvoiceSelector(s, 'creditNote'),
  }),
  {
    fetchBankTransfers: fetchAllBankTransfers,
    clearTransfers: clearBankTransfers,
    toggleCreditNote: toggleCreditNoteAC,
  }
)(CreditNote);
