import React, { SVGAttributes } from 'react';

const IconNodoc = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 80 83" {...props}>
    <g fill="#0066B3" fillRule="nonzero" opacity=".6">
      <path d="M67.512 69.96c.043-.896.107-1.728.192-2.496s.363-1.6.832-2.496c.17-.384.363-.736.576-1.056.213-.32.459-.661.736-1.024.277-.363.619-.779 1.024-1.248.405-.47.907-1.024 1.504-1.664a60.53 60.53 0 011.312-1.504c.405-.448.843-.95 1.312-1.504.768-.939 1.515-2.07 2.24-3.392.725-1.323 1.13-3.008 1.216-5.056 0-.299-.021-.81-.064-1.536-.043-.725-.224-1.525-.544-2.4-.32-.875-.843-1.792-1.568-2.752-.725-.96-1.792-1.867-3.2-2.72a12.667 12.667 0 00-2.976-1.28 21.988 21.988 0 00-2.912-.64A16.988 16.988 0 0064.824 37h-1.216c-1.195 0-2.304.043-3.328.128a26.305 26.305 0 00-2.976.416c-.96.192-1.952.416-2.976.672L51 39.048l.704 7.424c.981-.427 2.261-.928 3.84-1.504 1.579-.576 3.52-.885 5.824-.928 2.56-.043 4.448.437 5.664 1.44 1.216 1.003 1.867 2.293 1.952 3.872a6.697 6.697 0 01-1.024 3.808c-.725 1.173-1.643 2.421-2.752 3.744a59.63 59.63 0 00-2.016 2.24 19.792 19.792 0 00-1.76 2.432 8.843 8.843 0 00-.8 1.632c-.192.533-.352 1.12-.48 1.76a23.99 23.99 0 00-.32 2.176c-.085.81-.15 1.75-.192 2.816h7.872zm.64 12.992v-8.448h-8.96v8.448h8.96z" />
      <path d="M40.375 0L64 23.4V34h-7.875v-6.7H36.437V7.8H8.875v62.4H56V78H8.836c-4.248 0-7.7-3.376-7.832-7.553L1 70.2V7.8C1 3.51 4.544 0 8.875 0h31.5zm7.875 54.6v7.8h-31.5v-7.8h31.5zm0-15.6v7.8h-31.5V39h31.5z" />
    </g>
  </svg>
);

export default IconNodoc;
