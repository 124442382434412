import styled from 'styled-components';

import media from 'shared/styles/breakpoints';
import ModalComponent from 'components/Modal';

export const Modal = styled(ModalComponent)`
  max-width: 452px;
  width: 100%;

  ${media.greaterThan('md-up')`
     overflow: visible;
  `}
`;

export const ModalBody = styled.div`
  padding: 20px;
`;

export const Title = styled.h2`
  margin-bottom: ${({ theme }) => theme.space['6']};
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-row: 5 / 6;
  padding-top: 80px;

  > *:not(:last-child) {
    margin-right: 20px;
  }

  ${media.greaterThan('md-up')`
    grid-column: 1 / 3;
  `}
`;

export const CityAndZipcode = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 30% calc(70% - 30px);
  grid-column-gap: 30px;
`;
