import React from 'react';
import ReactQuill, { Quill, ReactQuillProps } from 'react-quill';
import { WrappedFieldProps } from 'redux-form';

import Tooltip from 'redesign/components/molecules/Tooltip/Tooltip';

import { Header, Label } from './HtmlField.styled';

import './quill.css';
import './quill-overrides.css';

const SizeStyle = Quill.import('attributors/style/size');

SizeStyle.whitelist = [
  '7px',
  '8px',
  '9px',
  '10px',
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
];

Quill.register(SizeStyle, true);

const modules = {
  toolbar: [
    [
      {
        size: [
          false,
          '7px',
          '8px',
          '9px',
          '10px',
          '11px',
          '12px',
          '14px',
          '16px',
          '18px',
          '20px',
          '24px',
        ],
      },
      'bold',
      'italic',
      'underline',
      { list: 'ordered' },
      { list: 'bullet' },
      'clean',
    ],
  ],
};

const formats = ['size', 'bold', 'italic', 'underline', 'list', 'bullet', 'indent'];

type HtmlFieldProps = WrappedFieldProps &
  ReactQuillProps & {
    label?: string;
    dataId?: string;
    disabled?: boolean;
    tooltip?: string;
  };

const HtmlField = ({
  input,
  meta,
  label,
  disabled,
  dataId,
  id,
  className,
  tooltip,
  ...rest
}: HtmlFieldProps) => {
  const { value, onChange, onBlur } = input;

  return (
    <div className={className} id={id} data-id={dataId}>
      {(label || tooltip) && (
        <Header>
          {label && <Label>{label}</Label>}
          {tooltip && <Tooltip message={tooltip} />}
        </Header>
      )}
      <ReactQuill
        value={value}
        onChange={(value, _delta, source) => {
          if (source !== 'user') return;

          onChange(value);
        }}
        onBlur={(_range, _source, quill) => {
          onBlur(quill.getHTML());
        }}
        modules={modules}
        formats={formats}
        readOnly={disabled}
        preserveWhitespace
        {...rest}
      />
    </div>
  );
};

export default HtmlField;
