import * as Resource from 'constants/common/resource';
import * as Piwik from 'constants/piwik';
import * as ProductCatalogGroups from 'constants/product-catalog/groups';
import { piwikHelpers } from 'shared/utils/piwik';

const productCatalogGroupsActionSwitch = (actionType) => {
  switch (actionType) {
    // when user clicks on "neue Leistung hinzufügen" on index-page of product-catalog-groups, then track with params category="Leistungskatalog/Leistungsgruppen", action="neue Leistungsgruppe hinzufuegen"
    case ProductCatalogGroups.CLICK_CREATE:
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_PRODUCT_CATALOG_GROUPS,
        Piwik.ACTION_NEW_PRODUCT_CATALOG_GROUP
      );
      break;

    // when user clicks on "bearbeiten"-button on index-page of product-catalog-groups, then track with params category="Leistungskatalog/Leistungsgruppen", action="bearbeiten"
    case ProductCatalogGroups.CLICK_EDIT:
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_PRODUCT_CATALOG_GROUPS,
        Piwik.ACTION_EDIT_PRODUCT_CATALOG_GROUP
      );
      break;

    // when user deletes product-catalog-groups, then track with params category="Leistungskatalog/Leistungsgruppen", action="loeschen"
    case Resource.DELETE_SUCCESS:
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_PRODUCT_CATALOG_GROUPS,
        Piwik.ACTION_DELETE_PRODUCT_CATALOG_GROUP
      );
      break;

    // when user creates a new product-catalog-group successfully in create-page of product-catalog-groups, then track with params category="Leistungskatalog/Leistungsgruppen", action="Leistungsgruppe erstellen",  name="Erfolg"
    case ProductCatalogGroups.CREATE_SUCCESS:
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_PRODUCT_CATALOG_GROUPS,
        Piwik.ACTION_CREATE_PRODUCT_CATALOG_GROUP,
        Piwik.NAME_CREATE_PRODUCT_CATALOG_GROUP_SUCCESS
      );
      piwikHelpers.trackGoal(17);
      break;

    // when user tries to save a product-catalog-group in create-page of product-catalog-groups, but an error occurred, then track with params category="Leistungskatalog/Leistungsgruppen", action="Leistungsgruppe erstellen", name="Fehler"
    case ProductCatalogGroups.CREATE_FAILURE:
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_PRODUCT_CATALOG_GROUPS,
        Piwik.ACTION_CREATE_PRODUCT_CATALOG_GROUP,
        Piwik.NAME_CREATE_PRODUCT_CATALOG_GROUP_ERROR
      );
      break;

    // when user clicks on "Abbrechen"-button in create-page of product-catalog-groups, then track with params category="Leistungskatalog/Leistungsgruppen", action="abbrechen"
    case ProductCatalogGroups.CLICK_CANCEL:
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_PRODUCT_CATALOG_GROUPS,
        Piwik.ACTION_CREATE_PRODUCT_CATALOG_GROUP_CANCEL
      );
      break;
    default:
      break;
  }
};

export default productCatalogGroupsActionSwitch;
