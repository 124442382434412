import React from 'react';
import cx from 'classnames';
import { bool, func, number, shape, string } from 'prop-types';

import { DE_SUPPLIER_MANAGEMENT } from 'constants/suppliers';
import paths from 'routes/paths';
import tableStyles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import I18n from 'components/I18n';
import ActionDelete from 'components/IndexActionsContainer/actionIcons/ActionDelete';
import ActionEdit from 'components/IndexActionsContainer/actionIcons/ActionEdit';
import ActionView from 'components/IndexActionsContainer/actionIcons/ActionView';
import IndexActionsContainer from 'components/IndexActionsContainer/IndexActionsContainer';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import { TooltipPlacements } from 'redesign/components/molecules/Tooltip/Tooltip';

import localStyles from '../SuppliersTable.module.css';

export const styles = { ...tableStyles, ...localStyles };

const editTitle = t('administration.actions.edit');
const deleteTitle = t('administration.actions.delete');
const infoIconTooltip = t('administration.actions.supplier_info_icon_tooltip');

const SupplierRow = ({
  supplier,
  onDelete,
  editPath = paths.editSupplier,
  showPath = paths.showSupplier,
  index,
}) => {
  const { actions = [] } = supplier.meta || {};
  const permittedActions = {
    ...actions,
    info: !(actions.delete || supplier.internalSupplier),
    view: supplier.internalSupplier,
  };

  return (
    <tr className={styles.main} data-id={`Suppliers:row-${index}`}>
      <td className={styles.column}>
        <div className={styles.cell}>
          <I18n t="suppliers.table.columns.company_name" className={styles.cellHeader} />
          <CellContentWithTooltip>{supplier.companyName}</CellContentWithTooltip>
        </div>
      </td>
      <td className={styles.column}>
        <div className={styles.cell}>
          <I18n t="suppliers.table.columns.city" className={styles.cellHeader} />
          <CellContentWithTooltip>{supplier.city}</CellContentWithTooltip>
        </div>
      </td>
      <td className={styles.column}>
        <div className={styles.cell}>
          <I18n t="suppliers.table.columns.honorific" className={styles.cellHeader} />
          <CellContentWithTooltip>{supplier.honorific}</CellContentWithTooltip>
        </div>
      </td>
      <td className={styles.column}>
        <div className={styles.cell}>
          <I18n t="suppliers.table.columns.first_name" className={styles.cellHeader} />
          <CellContentWithTooltip>{supplier.firstName}</CellContentWithTooltip>
        </div>
      </td>
      <td className={styles.column}>
        <div className={styles.cell}>
          <I18n t="suppliers.table.columns.last_name" className={styles.cellHeader} />
          <CellContentWithTooltip>{supplier.lastName}</CellContentWithTooltip>
        </div>
      </td>
      <td className={styles.column}>
        <div className={styles.cell}>
          <I18n t="suppliers.table.columns.id_number" className={styles.cellHeader} />
          <CellContentWithTooltip>{supplier.idNumber}</CellContentWithTooltip>
        </div>
      </td>
      <td className={styles.column}>
        <div className={styles.cell}>
          <div className={cx(localStyles.actionsWithTooltip)}>
            <div>
              <IndexActionsContainer>
                <ActionView
                  to={showPath(supplier.id)}
                  title={editTitle}
                  label={editTitle}
                  visible={permittedActions.view}
                  dataId="button-show"
                />
                <ActionEdit
                  to={editPath(supplier.id)}
                  title={editTitle}
                  label={editTitle}
                  onClick={() => piwikHelpers.trackEvent(DE_SUPPLIER_MANAGEMENT, editTitle)}
                  visible={permittedActions.edit}
                  dataId="button-edit"
                />
                <ActionDelete
                  onClick={() => onDelete(supplier)}
                  title={deleteTitle}
                  label={deleteTitle}
                  visible={permittedActions.delete}
                  dataId="delete-edit"
                />
              </IndexActionsContainer>
            </div>
            {permittedActions.info && (
              <div className={localStyles.tooltipWithText}>
                <InfoIcon
                  message={infoIconTooltip}
                  tooltipPlacement={TooltipPlacements.TOP_END}
                  className={localStyles.infoIcon}
                  dataId="info-icon"
                />
                <div className={localStyles.tooltipOnMobile}>{infoIconTooltip}</div>
              </div>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

SupplierRow.propTypes = {
  supplier: shape({
    companyName: string,
    lastName: string,
    idNumber: string,
    city: string,
    internalSupplier: bool,
  }).isRequired,
  editPath: func,
  showPath: func,
  onDelete: func.isRequired,
  index: number.isRequired,
};

export default SupplierRow;
