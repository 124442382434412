import styled from 'styled-components';

import BaseModalBody from 'redesign/components/atoms/ModalBody/ModalBody';

export const ModalBody = styled(BaseModalBody)`
  padding: ${({ theme }) => `0 ${theme.space['6']}`};
  padding-bottom: ${({ theme }) => theme.space['10']};
`;

export const Content = styled.div`
  display: inline-block;
  width: 100%;
  color: ${({ theme }) => theme.colors.grey['070']};

  > label {
    margin-top: ${({ theme }) => theme.space['3']};
  }
`;

export const IconWrapper = styled.div`
  margin: ${({ theme }) => theme.space['6']};

  div {
    margin: 0 auto;
  }

  svg {
    margin: auto auto;
  }
`;

export const Headline = styled.h1`
  font-weight: bold;
  text-align: center;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.grey['080']};
  margin-bottom: ${({ theme }) => theme.space['5']};
`;

export const Warning = styled.p`
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.red['100']};
  margin: ${({ theme }) => `${theme.space['10']} 0`};
`;

type MessageProps = {
  bold?: boolean;
};
export const Message = styled.p<MessageProps>`
  text-align: center;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  color: ${({ theme }) => theme.colors.grey['080']};
  line-height: ${({ theme }) => theme.space['6']};
`;
