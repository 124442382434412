export const CREATE_REQUEST = 'INVOICE/CREATE_REQUEST';
export const CREATE_SUCCESS = 'INVOICE/CREATE_SUCCESS';
export const CREATE_FAILURE = 'INVOICE/CREATE_FAILURE';

export const UPDATE_REQUEST = 'INVOICE/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'INVOICE/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'INVOICE/UPDATE_FAILURE';

export const TOGGLE_GROSS_NET_REQUEST = 'INVOICE/TOGGLE_GROSS_NET_REQUEST';
export const TOGGLE_GROSS_NET_SUCCESS = 'INVOICE/TOGGLE_GROSS_NET_SUCCESS';
export const TOGGLE_GROSS_NET_FAILURE = 'INVOICE/TOGGLE_GROSS_NET_FAILURE';

export const FETCH_REQUEST = 'INVOICE/FETCH_REQUEST';
export const FETCH_SUCCESS = 'INVOICE/FETCH_SUCCESS';
export const FETCH_FAILURE = 'INVOICE/FETCH_FAILURE';

export const REORDER_LINE_ITEMS_LOCAL = 'INVOICE/REORDER_LINE_ITEMS_LOCAL';
export const REORDER_LINE_ITEMS_REQUEST = 'INVOICE/REORDER_LINE_ITEMS_REQUEST';
export const REORDER_LINE_ITEMS_SUCCESS = 'INVOICE/REORDER_LINE_ITEMS_SUCCEESS';
export const REORDER_LINE_ITEMS_FAILURE = 'INVOICE/REORDER_LINE_ITEMS_FAILURE';

export const FETCH_SUMS_REQUEST = 'INVOICE/FETCH_SUMS_REQUEST';
export const FETCH_SUMS_SUCCESS = 'INVOICE/FETCH_SUMS_SUCCESS';
export const FETCH_SUMS_FAILURE = 'INVOICE/FETCH_SUMS_FAILURE';

export const FETCH_LINE_CATEGORIES_REQUEST = 'LINE_CATEGORIES/FETCH_REQUEST';
export const FETCH_LINE_CATEGORIES_SUCCESS = 'LINE_CATEGORIES/FETCH_SUCCESS';
export const FETCH_LINE_CATEGORIES_FAILURE = 'LINE_CATEGORIES/FETCH_FAILURE';

export const FETCH_OLD_LINE_CATEGORIES_REQUEST = 'OLD_LINE_CATEGORIES/FETCH_REQUEST';
export const FETCH_OLD_LINE_CATEGORIES_SUCCESS = 'OLD_LINE_CATEGORIES/FETCH_SUCCESS';
export const FETCH_OLD_LINE_CATEGORIES_FAILURE = 'OLD_LINE_CATEGORIES/FETCH_FAILURE';

export const DELETE_REQUEST = 'INVOICE/DELETE_REQUEST';
export const DELETE_SUCCESS = 'INVOICE/DELETE_SUCCESS';
export const DELETE_FAILURE = 'INVOICE/DELETE_FAILURE';

export const DRAFT_ID = 'INVOICE/CURRENT_DRAFT_ID';

export const FETCH_ALL_BANK_TRANSFERS_REQUEST = 'ALL_BANK_TRANSFERS/FETCH_REQUEST';
export const FETCH_ALL_BANK_TRANSFERS_SUCCESS = 'ALL_BANK_TRANSFERS/FETCH_SUCCESS';
export const FETCH_ALL_BANK_TRANSFERS_FAILURE = 'ALL_BANK_TRANSFERS/FETCH_FAILURE';
export const CLEAR_ALL_BANK_TRANSFERS = 'ALL_BANK_TRANSFERS/CLEAR_ALL_BANK_TRANSFERS';

export const CREATE_SUPPLIER_REQUEST = 'QUICK_SUPPLIER/CREATE_REQUEST';
export const CREATE_SUPPLIER_SUCCESS = 'QUICK_SUPPLIER/CREATE_SUCCESS';
export const CREATE_SUPPLIER_FAILURE = 'QUICK_SUPPLIER/CREATE_FAILURE';

export const DELETE_MAYBE_SUGGESTION = `INVOICE/DELETE_MAYBE_SUGGESTION`;

export const CLEAR_ASSIGNED_BANK_TRANSFERS = 'INCOMING_INVOICE/CLEAR_ASSIGNED_BANK_TRANSFERS';

export const MIN_NET_AMOUNT = -20000000;
export const MAX_NET_AMOUNT = +19999999;

export const MAX_REMINDERS_COUNT = 3;

export const ITEMS_DROPPABLE_ID = 'INCOMING_INVOICE/LINE_ITEMS_DROPPABLE';

export const CLOSE_DATEV_MODAL = 'INCOMING_INVOICE/CLOSE_DATEV_MODAL';

export const TOGGLE_DISABLED_BUTTON_ON_LINE_ITEM_REQUEST =
  'INCOMING_INVOICE/TOGGLE_DISABLED_BUTTON_ON_LINE_ITEM_REQUEST';

export const CREATION_TYPE = {
  MANUAL: 'manual',
  UPLOAD: 'upload',
};
