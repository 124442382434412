import React, { memo, ReactNode } from 'react';

import * as SC from './Tile.styled';

interface ITile {
  children?: ReactNode;
}

const Tile = ({ children }: ITile) => {
  return <SC.Tile>{children}</SC.Tile>;
};

export default memo(Tile);
