import React from 'react';

const IconEuro = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="12" height="13">
    <path
      d="M77.32 24.61c-1.4-.59-3.14-1.22-5.2-1.88-2.07-.66-4.65-1-7.75-1-4.87 0-8.91.96-12.12 2.88-3.21 1.92-5.66 4.13-7.36 6.64-.52.74-1 1.53-1.44 2.38-.44.85-.81 1.66-1.11 2.43-.3.77-.54 1.44-.72 1.99-.18.55-.31.87-.39.94h32.86l-1.66 6.75H39.92a31.26 31.26 0 00-.28 2.54 29.845 29.845 0 00.05 4.43c.07.74.15 1.29.22 1.66h30.54l-1.55 6.75H41.69c.07.22.33 1 .77 2.32.44 1.33 1 2.54 1.66 3.65.74 1.26 1.7 2.51 2.88 3.76 1.18 1.26 2.6 2.4 4.26 3.43 1.66 1.03 3.54 1.88 5.64 2.54 2.1.66 4.44 1 7.03 1 1.4 0 3.04-.11 4.92-.33 1.88-.22 3.49-.52 4.81-.89.96-.22 1.57-.39 1.83-.5.26-.11.87-.35 1.83-.72l.66 12.39c-3.54 1.11-6.6 1.75-9.18 1.94-2.59.2-4.54.29-5.87.29-6.57 0-11.97-.77-16.21-2.32-4.24-1.55-7.76-3.5-10.57-5.86-2.29-1.92-3.98-3.69-5.09-5.31-1.11-1.62-1.88-2.84-2.32-3.65-.66-1.18-1.22-2.4-1.66-3.65-.44-1.25-.83-2.43-1.16-3.54-.33-1.11-.59-2.08-.77-2.93-.19-.85-.31-1.38-.39-1.6H13.81l1.66-6.75h8.3c-.07-.81-.13-1.53-.17-2.16-.04-.63-.06-1.35-.06-2.16s.02-1.53.06-2.16c.04-.63.09-1.35.17-2.16h-9.96L15.47 39h9.41c.07-.52.26-1.35.55-2.49.29-1.14.72-2.45 1.27-3.93.55-1.47 1.29-3.06 2.21-4.76.92-1.7 2.12-3.43 3.6-5.2 3.32-3.98 6.86-6.82 10.62-8.52 3.76-1.7 7.52-2.84 11.29-3.43 1.92-.29 3.72-.48 5.42-.55 1.7-.07 2.84-.11 3.43-.11 4.13 0 7.54.37 10.24 1.11 2.69.74 4.92 1.4 6.69 1.99l-2.88 11.5z"
      fill="evenodd"
    />
  </svg>
);

export default IconEuro;
