import React, { Component } from 'react';
import { Col, getRowProps, Grid, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import cx from 'classnames';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';

import { fetchDirectDebit as fetchDirectDebitAction } from 'actions/direct-debit';
import { fetchPaymentsBankAccounts as fetchPaymentsBankAccountsAction } from 'actions/incoming-invoice/payments';
import { fetchPlans as fetchPlansAction } from 'actions/payment-plans';
import ProfileSection from 'containers/Profile/ProfileSection/ProfileSection';
import SectionWithMargin from 'containers/Profile/SectionWithMargin';
import { plansSelector } from 'selectors/payment-plans';
import { t } from 'shared/utils';
import { filteredPlans } from 'shared/utils/payment-plans';
import Section from 'components/Form/Section/Section';

import ComparisonBox from './ComparisonBox/ComparisonBox';
import CurrentPlanBadge from './ComparisonBox/components/CurrentPlanBadge/CurrentPlanBadge';
import ComparisonTable from './ComparisonTable/ComparisonTable';
import CurrentPaymentPlan from './CurrentPaymentPlan/CurrentPaymentPlan';
import DebitContainer from './DebitContainer/DebitContainer';
import Footer from './Footer/Footer';
import LegalsDescription from './ModalsContainer/components/LegalsDescription/LegalsDescription';
import ModalsContainer from './ModalsContainer/ModalsContainer';

import styles from './PaymentPlan.module.css';

class PaymentPlan extends Component {
  defaultState = {
    isModalOpen: false,
    modalType: null,
    isLoading: true,
  };

  state = this.defaultState;

  comparisonTableRef = React.createRef();

  componentDidMount() {
    const { fetchPaymentsBankAccounts, fetchPlans, fetchDirectDebit } = this.props;

    Promise.all([fetchPlans(), fetchDirectDebit(), fetchPaymentsBankAccounts()]).then(() => {
      this.setState({ isLoading: false });
    });
  }

  handleOpenModal = (type) => {
    this.setState({ isModalOpen: true, modalType: type });
  };

  handleCloseModal = () => this.setState({ isModalOpen: false, modalType: null });

  handleScrollToTable = () =>
    this.comparisonTableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  render() {
    const { plans = [], currentPaymentPlanDetails, heading, subheading, description } = this.props;
    const { isModalOpen, modalType, isLoading } = this.state;
    const plansPresentedForUser = filteredPlans(plans);

    if (isLoading) return null;

    return (
      <ProfileSection heading={heading} subheading={subheading} description={description}>
        <Grid fluid className={styles.main}>
          <Row className={styles.spacer}>
            <Col xs={12} lg={4} className={styles.noPadding}>
              <CurrentPaymentPlan currentPaymentPlanDetails={currentPaymentPlanDetails} />
            </Col>
            <Col xs={12} lg={8} className={styles.debitContainer}>
              <DebitContainer
                handleOpenModal={this.handleOpenModal}
                currentPaymentPlanDetails={currentPaymentPlanDetails}
              />
            </Col>
          </Row>
          <Row className={styles.mobileMargin}>
            <Col xs={12}>
              <SectionWithMargin title={t('profile.payment_plan.comparison_header')}>
                <span className={styles.spacer}>{t('profile.payment_plan.modals_header')}</span>
                <Col xs={12} lg={10} className={cx(styles.noPadding, getRowProps({}).className)}>
                  {plansPresentedForUser.map((plan, index) => (
                    <Col xs={12} lg={6} className={styles.noPadding} key={index}>
                      {plan.uid === currentPaymentPlanDetails.uid && (
                        <CurrentPlanBadge
                          label={t('profile.payment_plan.comparison_box.current_plan_badge')}
                        />
                      )}
                      <ComparisonBox
                        handleScrollToTable={this.handleScrollToTable}
                        plan={plan.uid}
                        handleModalOpen={this.handleOpenModal}
                        details={plan}
                      />
                    </Col>
                  ))}
                </Col>
                <div className={styles.spacer} />
                <Col xs={12} lg={10} className={styles.noPadding}>
                  <ComparisonTable
                    plans={plansPresentedForUser}
                    tableRef={this.comparisonTableRef}
                    handleModalOpen={this.handleOpenModal}
                  />
                </Col>
                <LegalsDescription className={styles.legalsContainer} />
              </SectionWithMargin>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Section title={t('profile.payment_plan.footer.title')}>
                <Footer />
              </Section>
            </Col>
          </Row>
        </Grid>
        {isModalOpen && (
          <ModalsContainer
            isOpen={isModalOpen}
            type={modalType}
            onClose={this.handleCloseModal}
            plans={plansPresentedForUser}
          />
        )}
      </ProfileSection>
    );
  }
}

PaymentPlan.propTypes = {
  currentPaymentPlanDetails: shape({
    uid: string,
    onTrial: bool,
    currentPlanEndDate: string,
  }),
  fetchPaymentsBankAccounts: func.isRequired,
  fetchPlans: func.isRequired,
  fetchDirectDebit: func.isRequired,
  plans: arrayOf(
    shape({
      name: string,
      uid: string,
      price: number,
      defaultPrice: number,
      vrMainBankAccount: bool,
      discountAmount: oneOfType([number, string]),
      id: string,
    })
  ),
  heading: string.isRequired,
  subheading: string,
  description: string,
};

const mapStateToProps = (state) => ({
  paymentsBankAccounts: state.incomingInvoice.paymentsBankAccounts.list,
  currentPaymentPlanDetails: state.paymentPlan.details,
  plans: plansSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchPaymentsBankAccounts: () => dispatch(fetchPaymentsBankAccountsAction()),
  fetchPlans: () => dispatch(fetchPlansAction()),
  fetchDirectDebit: () => dispatch(fetchDirectDebitAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPlan);
