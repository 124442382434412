import React from 'react';
import cx from 'classnames';
import { bool, func, node, oneOfType, string } from 'prop-types';

import ConfirmationIcon from 'images/icon-confirmation.svg';
import WarningIcon from 'images/icon-exclamation-warning.svg';
import If from 'components/Conditions/If';
import { IGNORE_OUTSIDE_CLICK_CLASS as IGNORE_OUTSIDE_LINE_ITEM_CLICK_CLASS } from 'components/LineItems';

import styles from './Notification.module.css';

export { default as BankAccountNotification } from './BankAccountNotification';
export { default as ValidationErrorsNotification } from './ValidationErrorsNotification';

const Notification = ({
  hide = () => {},
  text,
  title = '',
  variant = '',
  CustomMessage = null,
  isHideButtonHidden,
  className,
}) => (
  <div
    className={cx(
      styles.notification,
      styles[`border-${variant}`],
      IGNORE_OUTSIDE_LINE_ITEM_CLICK_CLASS,
      className
    )}
    data-id="FlashNotification"
  >
    <div className={cx(styles.imageWrapper, styles[variant])}>
      {variant === 'success' ? (
        <img src={ConfirmationIcon} alt="Confirmation checked icon" />
      ) : (
        <img src={WarningIcon} alt="Warning icon" />
      )}
    </div>
    <div className={styles.section}>
      <h2 data-id="FlashNotification:header" className={styles.title}>
        {title}
      </h2>
      <If ok={text}>
        <div data-id="FlashNotification:content" className={styles.text}>
          {text}
        </div>
      </If>
      {Boolean(CustomMessage) && (
        <p className={styles.text}>
          <CustomMessage />
        </p>
      )}
      {!isHideButtonHidden && (
        <button
          onClick={hide}
          className={styles.closeButton}
          data-id="FlashNotification:button-close"
        />
      )}
    </div>
  </div>
);

Notification.propTypes = {
  hide: func,
  title: string.isRequired,
  text: string,
  variant: string,
  CustomMessage: oneOfType([node, func]),
  isHideButtonHidden: bool,
  className: string,
};

export default Notification;
