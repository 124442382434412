import React, { memo, SVGProps } from 'react';

const ArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 37" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <clipPath id="ArrowLeft_svg__a">
        <path d="M3469 0v2603H0V0h3469Z" />
      </clipPath>
    </defs>
    <g clipPath="url(#ArrowLeft_svg__a)" transform="translate(-110 -544)">
      <path
        d="m127.416 546.95-14 16 14 16"
        stroke="#0066B3"
        strokeWidth={4}
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
    </g>
  </svg>
);

export default memo(ArrowLeft);
