import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { downloadData, t } from 'shared/utils';
import Button, { ButtonAppearances } from 'components/Button';
import Card, { Body, FullWidthOnMobile, Header } from 'components/Card';
import OBSModalFlow from 'components/OBS/OBSModalFlow/OBSModalFlow';

import Alert from '../Alert/Alert';
import { SliderValues } from '../constants';
import * as formSelectors from '../formSelectors';
import { getPDF, setBridgeAmountInMonths } from '../liquidityCalculatorSlice';
import * as selectors from '../selectors';
import { getAlertMessage, trackEvent } from '../utils';
import DownloadPDF from './DownloadPDF/DownloadPDF';
import HintWithHelp from './HintWithHelp/HintWithHelp';
import HintWithNoHelp from './HintWithNoHelp/HintWithNoHelp';
import Result from './Result/Result';
import Slider from './Slider/Slider';
import Total from './Total/Total';

import styles from './Summary.module.css';

const Summary = () => {
  const dispatch = useDispatch();
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
  const [isOBSModalOpen, setIsOBSModalOpen] = useState(false);
  const { balance } = useSelector(selectors.getAverageAccountDetailsForSelectedAccounts);
  const isHelpNeeded = useSelector(selectors.getIsHelpNeeded);
  const noShortfall = useSelector(selectors.getIsNoShortfall);
  const monthlyExpenses = useSelector(formSelectors.getMonthlyExpenses);
  const monthlyRevenues = useSelector(formSelectors.getMonthlyRevenues);
  const moneyPerMonth = useSelector(selectors.getMoneyPerMonth);
  const monthsToDeficit = useSelector(selectors.getMonthsToDeficit);
  const moneyNeeded = useSelector(selectors.getMoneyNeeded);
  const isContinueButtonVisible = useSelector(selectors.getIsOBSReady);
  const alert = useSelector(selectors.getSummaryAlert);
  const bridgePeriodInMonths = useSelector(selectors.getBridgePeriodInMonths);

  const handleDownloadPDF = useCallback(async () => {
    setIsDownloadingPDF(true);
    try {
      const file = await dispatch(getPDF());
      downloadData(file, 'liquiditätsplan', 'pdf');
      setIsDownloadingPDF(false);
      trackEvent('Sum_Expenses', monthlyExpenses);
      trackEvent('Sum_Revenues', monthlyRevenues);
      trackEvent('Export_Calculation');
    } catch (error) {
      setIsDownloadingPDF(false);
    }
  }, [dispatch, monthlyExpenses, monthlyRevenues]);

  const handleContinue = useCallback(() => {
    setIsOBSModalOpen(true);
  }, []);

  const handleSliderValueChange = useCallback(
    (months) => dispatch(setBridgeAmountInMonths(months)),
    [dispatch]
  );

  useEffect(() => {
    if (isHelpNeeded) {
      trackEvent('Loan_duration', bridgePeriodInMonths);
    }
  }, [dispatch, bridgePeriodInMonths, isHelpNeeded]);

  const getContent = () => {
    if (alert) {
      return (
        <div className={styles.alert}>
          <Alert dataId="LiquidityCalculatorSummary:alert">{getAlertMessage(alert)}</Alert>
        </div>
      );
    }

    if (noShortfall) {
      return (
        <FullWidthOnMobile className={styles.hint}>
          <HintWithNoHelp dataId="Summary:hint-noShortfall">
            {t('features.financial_planning.liquidity_calculator.summary.hints.no_shortfall', {
              months: SliderValues.max,
            })}
          </HintWithNoHelp>
        </FullWidthOnMobile>
      );
    }

    if (isHelpNeeded && typeof monthsToDeficit === 'number') {
      return (
        <>
          <FullWidthOnMobile className={styles.hint}>
            <HintWithHelp
              moneyPerMonth={moneyPerMonth}
              monthsToDeficit={monthsToDeficit}
              dataId="Summary:hint-help"
            />
          </FullWidthOnMobile>
          <div data-id="Summary:calculation-section">
            <div className={styles.slider}>
              <Slider
                value={bridgePeriodInMonths}
                min={monthsToDeficit + 1}
                max={SliderValues.max}
                defaultValue={SliderValues.default}
                onChange={handleSliderValueChange}
              />
            </div>
            <FullWidthOnMobile className={styles.result}>
              <Result moneyNeeded={moneyNeeded} />
            </FullWidthOnMobile>
            {isContinueButtonVisible && (
              <div className={styles.continueContainer}>
                <Button
                  className={styles.continue}
                  appearance={ButtonAppearances.orange}
                  label={t('features.financial_planning.liquidity_calculator.summary.continue')}
                  onClick={handleContinue}
                  dataId="Summary:sendInquiry-button"
                />
              </div>
            )}
            <div className={styles.download}>
              <DownloadPDF onDownloadPDF={handleDownloadPDF} isDisabled={isDownloadingPDF} />
            </div>
            <div className={styles.note}>
              {t('features.financial_planning.liquidity_calculator.summary.note')}
            </div>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <Card className={styles.card}>
        <Header>{t('features.financial_planning.liquidity_calculator.summary.header')}</Header>
        <Body withMargin>
          <Total
            balance={balance}
            monthlyExpenses={monthlyExpenses}
            monthlyRevenues={monthlyRevenues}
          />
          {getContent()}
        </Body>
      </Card>
      <OBSModalFlow isOBSModalOpen={isOBSModalOpen} setIsOBSModalOpen={setIsOBSModalOpen} />
    </>
  );
};

export default memo(Summary);
