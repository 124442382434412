import React, { InputHTMLAttributes } from 'react';

import IconCheck from 'redesign/components/atoms/Icons/Check';

import { Checkmark, Input, Wrapper } from './Checkbox.styled';

type CheckboxProps = InputHTMLAttributes<HTMLInputElement>;

const Checkbox = ({ className, onClick, ...props }: CheckboxProps) => {
  return (
    <Wrapper className={className}>
      <Input {...props} type="checkbox" />
      <Checkmark onClick={onClick}>
        <IconCheck />
      </Checkmark>
    </Wrapper>
  );
};

export default Checkbox;
