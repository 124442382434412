import React, {
  FocusEvent,
  FocusEventHandler,
  KeyboardEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useUpdateEffect } from 'react-use';

import IconZoomIn from 'redesign/components/atoms/Icons/ZoomIn';
import IconZoomOut from 'redesign/components/atoms/Icons/ZoomOut';

import InputField from '../InputField/InputField';
import { STEPS } from './constants';
import { Button, Wrapper } from './Zoom.styled';

export type ZoomProps = {
  zoom: number;
  onChange: (zoom: number) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  steps?: Array<number>;
  className?: string;
  disabled?: boolean;
  name?: string;
  responsive?: boolean;
};

const Zoom = ({
  zoom = 100,
  steps = STEPS,
  onChange,
  onBlur,
  onFocus,
  className,
  disabled,
  name,
  responsive = true,
}: ZoomProps) => {
  const [value, setValue] = useState(zoom.toFixed(0));
  const revesedSteps = useMemo(() => [...steps].reverse(), [steps]);

  const calculateNextStep = useCallback(
    (event: FocusEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>) => {
      const value = Number((event.target as HTMLInputElement).value);

      if (value === zoom) return;

      if (isNaN(value) || !Number.isInteger(value)) {
        setValue(zoom.toFixed(0));
        return;
      }

      const first = steps[0];
      const last = steps[steps.length - 1];
      const newZoom = value <= first ? first : value >= last ? last : value;

      if (newZoom !== zoom) {
        onChange(newZoom);
        setValue(`${newZoom}`);
      } else {
        setValue(`${zoom}`);
      }
    },
    [zoom, steps, onChange]
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter') return;

      event.preventDefault();
      calculateNextStep(event);
    },
    [calculateNextStep]
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      onBlur?.(event);
      calculateNextStep(event);
    },
    [calculateNextStep, onBlur]
  );

  const handleChange = useCallback((event: FocusEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const handleZoomIn = useCallback(() => {
    const last = steps[steps.length - 1];

    if (zoom === last) return;

    const nextZoom = steps.find((value) => value > zoom)!;

    onChange(nextZoom);
  }, [zoom, steps, onChange]);

  const handleZoomOut = useCallback(() => {
    const first = steps[0];

    if (zoom === first) return;

    const nextZoom = revesedSteps.find((value) => value < zoom)!;

    onChange(nextZoom);
  }, [zoom, revesedSteps, steps, onChange]);

  useUpdateEffect(() => {
    setValue(zoom.toFixed(0));
  }, [zoom]);

  return (
    <Wrapper className={className} $responsive={responsive}>
      <InputField
        type="number"
        value={value}
        icon="%"
        onSubmit={() => null}
        onBlur={handleBlur}
        onFocus={onFocus}
        onKeyPress={handleKeyPress}
        onChange={handleChange}
        disabled={disabled}
        name={name}
      />
      <Button onClick={handleZoomOut} disabled={disabled || zoom === steps[0]} type="button">
        <IconZoomOut />
      </Button>
      <Button
        onClick={handleZoomIn}
        disabled={disabled || zoom === steps[steps.length - 1]}
        type="button"
      >
        <IconZoomIn />
      </Button>
    </Wrapper>
  );
};

export default Zoom;
