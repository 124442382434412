import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import honorifics from 'constants/honorifics';
import { shape as countriesShape } from 'reducers/countries';
import { supplierQuickCreatorSelector } from 'reducers/form';
import { withCountries } from 'shared/hoc';
import styles from 'shared/styles/client_creator.module.css';
import { noop, t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import SelectField from 'components/Form/SelectField';
import TextField from 'components/Form/TextField';
import MaskedField from 'components/v2/Form/MaskedField/MaskedField';

const ClientSelectField = (props) => {
  const { input } = props;

  return (
    <SelectField
      {...props}
      input={input}
      onBlur={noop}
      searchable
      label={t('expenses.form.supplier_select.quick_creator.country')}
    />
  );
};

ClientSelectField.propTypes = {
  input: PropTypes.shape({}).isRequired,
};

const SupplierQuickCreator = ({
  onCancel,
  handleSubmit,
  onSubmit,
  submitting,
  countries,
  isHonorificRequired = false,
  isLastNameRequired,
  isCompanyNameRequired,
}) => (
  <div className={styles.root}>
    <div className={styles.header}>{t('expenses.form.supplier_select.quick_creator.header')}</div>
    <p className={styles.info}>{t('expenses.form.supplier_select.quick_creator.info')}</p>
    <div className={styles.content}>
      <Field
        name="companyName"
        component={TextField}
        checker={checker}
        className={styles.wrapper}
        label={t('expenses.form.supplier_select.quick_creator.company_name')}
        required={isCompanyNameRequired}
      />
      <Field
        name="addressAddition"
        component={TextField}
        className={styles.wrapper}
        label={t('form.fields.address_addition')}
        placeholder={t('form.fields.placeholders.address_addition')}
      />
      <Field
        name="honorific"
        component={SelectField}
        label={t('expenses.form.supplier_select.quick_creator.honorific')}
        options={honorifics.map((h) => ({ value: h, label: h }))}
        className={styles.wrapper}
        checker={checker}
        clearable
        required={isHonorificRequired}
      />
      <Field
        name="firstName"
        label={t('expenses.form.supplier_select.quick_creator.first_name')}
        component={TextField}
        className={styles.wrapper}
        checker={checker}
      />
      <Field
        label={t('expenses.form.supplier_select.quick_creator.last_name')}
        name="lastName"
        component={TextField}
        className={styles.wrapper}
        checker={checker}
        required={isLastNameRequired}
      />
      <Field
        name="idNumber"
        label={t('expenses.form.supplier_select.quick_creator.supplier_number')}
        component={TextField}
        className={styles.wrapper}
        checker={checker}
        disabled
        disabledPlaceHolder={t('revenue.form.number_disabled')}
      />
      <Field
        name="street"
        label={t('expenses.form.supplier_select.quick_creator.street')}
        component={TextField}
        className={styles.wrapper}
        checker={checker}
        required
      />
      <Field
        name="zipcode"
        component={TextField}
        label={t('expenses.form.supplier_select.quick_creator.zip_code')}
        maxLength="15"
        className={styles.wrapper}
        checker={checker}
        required
      />
      <Field
        name="city"
        label={t('expenses.form.supplier_select.quick_creator.city')}
        component={TextField}
        className={styles.wrapper}
        checker={checker}
        required
      />
      <Field
        name="country"
        component={ClientSelectField}
        options={countries.data}
        isLoading={countries.isFetching}
        required
      />
      <Field
        name="iban"
        component={MaskedField}
        placeholder={t('expenses.form.supplier_select.quick_creator.iban')}
        label={t('expenses.form.supplier_select.quick_creator.iban')}
        checker={checker}
        className={styles.wrapper}
        dataId="iban"
        mask="aa** **** **** **** **** **** **** **** **"
        maskChar=" "
      />
      <div className={styles.buttons}>
        <button type="button" onClick={onCancel} className={styles.cancelButton}>
          {t('expenses.form.supplier_select.quick_creator.abort')}
        </button>
        <button
          type="button"
          className={styles.submitButton}
          onClick={handleSubmit(onSubmit)}
          disabled={submitting}
        >
          {t('expenses.form.supplier_select.quick_creator.submit')}
        </button>
      </div>
    </div>
  </div>
);

SupplierQuickCreator.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  isHonorificRequired: PropTypes.bool,
  isLastNameRequired: PropTypes.bool.isRequired,
  isCompanyNameRequired: PropTypes.bool.isRequired,
  countries: countriesShape,
};

const ReduxForm = reduxForm({
  form: 'supplierQuickCreator',
  persistentSubmitErrors: true, // allows submitting even if errors are present
})(withCountries(SupplierQuickCreator));

const createInitialData = ({ ocrData = {}, formData = {} }) => {
  const { senderName, senderNameAddition, senderStreet, senderPostalCode, senderCity, iban } =
    ocrData;
  const { companyName, addressAddition, street, zipcode, city } = formData;
  return {
    ...formData,
    companyName: senderName || companyName,
    addressAddition: senderNameAddition || addressAddition,
    street: senderStreet || street,
    zipcode: senderPostalCode || zipcode,
    city: senderCity || city,
    iban,
  };
};

export default connect((state) => ({
  initialValues: createInitialData({
    ocrData: state.incomingInvoice.ocr.processedData,
    formData: state.form.clientCreator.initial,
  }),
  isHonorificRequired: supplierQuickCreatorSelector(state, 'lastName'),
  isLastNameRequired: !supplierQuickCreatorSelector(state, 'companyName'),
  isCompanyNameRequired: !supplierQuickCreatorSelector(state, 'lastName'),
}))(ReduxForm);
