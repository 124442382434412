import styled from 'styled-components';

export const BankName = styled.p`
  font-size: ${({ theme }) => theme.fontSize.base};
  white-space: nowrap;
`;

export const SelectBankItem = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.grey['050']};
  white-space: nowrap;
  overflow: hidden;
`;
