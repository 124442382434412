import React, { memo, SVGProps } from 'react';

const Expenses = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <filter id="expenses_svg__a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.980392 0 0 0 0 0.980392 0 0 0 0 0.980392 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g filter="url(#expenses_svg__a)" fill="none" fillRule="evenodd">
      <g fill="#4D4E4C">
        <path d="M11.78 13.376c-.84.017-1.54.49-1.966 1.175.58-.432 1.236-.825 1.965-1.175" />
        <path d="M9.7 10.944c0 .608-1.127 1.49-2.893 1.49-1.766 0-2.893-.882-2.893-1.49 0-.007-.003-.013-.003-.022l-.002-1.477c.74.502 1.75.804 2.898.804 1.148 0 2.158-.302 2.899-.805v1.476c-.001.008-.005.016-.005.024M6.807 2.947c1.748 0 2.867.879 2.89 1.496-.023.617-1.142 1.495-2.89 1.495-1.748 0-2.866-.878-2.889-1.495.023-.617 1.14-1.496 2.889-1.496m-2.899 3.26c.74.51 1.75.816 2.899.816 1.15 0 2.16-.306 2.901-.816v1.444c-.001.013-.007.024-.007.038 0 .615-1.128 1.509-2.894 1.509-1.766 0-2.893-.894-2.893-1.509 0-.012-.005-.021-.005-.033l-.001-1.448m7.076-1.972v-.004c0-.008-.003-.014-.003-.022-.045-1.485-1.822-2.598-4.174-2.598-2.351 0-4.128 1.113-4.172 2.598 0 .008-.005.014-.005.022v.01c0 .014-.007.026-.007.042l.007 12.698c0 1.498 1.835 2.67 4.177 2.67.849 0 1.629-.155 2.282-.424l-.002-1.485c-.503.32-1.284.574-2.28.574-1.766 0-2.893-.791-2.893-1.335v-.996c.739.443 1.747.71 2.893.71 1.14 0 2.145-.265 2.884-.704-.379-.317-.6-.697-.6-1.107 0-.033.005-.064.009-.096-.502.316-1.289.57-2.293.57-1.766 0-2.893-.78-2.893-1.318l-.002-.01v-1.315c.74.443 1.748.71 2.895.71 1.148 0 2.157-.267 2.896-.711v1.052c.326-.269.768-.49 1.284-.641l.006-8.842c0-.017-.008-.032-.009-.048" />
        <path d="M12.625 22.258c-1.766 0-2.893-.778-2.893-1.314l-.001-1.04c.74.444 1.748.71 2.894.71 1.147 0 2.156-.267 2.896-.71l-.002 1.043c0 .534-1.127 1.31-2.894 1.31m-2.893-7.373c0-.537 1.127-1.318 2.893-1.318 1.767 0 2.894.78 2.894 1.318 0 .539-1.127 1.32-2.894 1.32-1.766 0-2.893-.781-2.893-1.32m5.792 3.053c0 .008-.005.015-.005.023 0 .537-1.127 1.319-2.894 1.319-1.766 0-2.893-.782-2.893-1.32 0-.007-.004-.013-.004-.02l-.001-1.112c.74.445 1.75.712 2.898.712 1.15 0 2.16-.268 2.9-.713l-.001 1.111m1.279-3.053c0-1.513-1.797-2.653-4.178-2.653-2.38 0-4.176 1.14-4.176 2.653l.002.033c-.001.018-.01.034-.01.052l.008 5.975c0 1.51 1.795 2.65 4.176 2.65 2.381 0 4.178-1.139 4.178-2.646l.008-5.979c0-.02-.009-.037-.01-.056l.002-.029" />
      </g>
      <path
        stroke="#0063BB"
        strokeWidth={1.224}
        strokeLinecap="round"
        d="M22.339 4.372V9.88h-6.12"
      />
      <path
        stroke="#0066B3"
        strokeWidth={1.224}
        strokeLinecap="round"
        d="M21.655 9.232l-5.193-5.193"
      />
    </g>
  </svg>
);

export default memo(Expenses);
