import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { usePrevious } from 'react-use';
import cx from 'classnames';
import { snakeCase } from 'lodash';
import moment from 'moment';
import { bool, func, string } from 'prop-types';
import { change, Field } from 'redux-form';

import { DATE_FORMAT } from 'constants/datetime';
import { FORM_NAME } from 'constants/incoming-invoices';
import { incomingInvoiceSelector } from 'reducers/form';
import { incomingInvoiceDetailsSelector } from 'selectors/incomingInvoice';
import { useAppDispatch } from 'shared/hooks/app';
import { noop, t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import Card from 'components/Card';
import { Checkbox, CurrencyFieldWrapper, DateField, SelectField, TextField } from 'components/Form';
import I18n from 'components/I18n';
import InvoiceStatusBadge from 'components/Table/InvoiceStatusBadge/InvoiceStatusBadge';

import CreditNote from './CreditNote';
import Supplier from './Supplier';

import styles from './CreateInvoiceSection.module.css';

const FormField = ({ name, readonly, ...rest }) => (
  <div className={styles.formGroup}>
    <Field
      id={name}
      name={name}
      checker={checker}
      disabled={readonly}
      label={t(`expenses.form.${snakeCase(name)}`)}
      placeholder={t(`expenses.form.${snakeCase(name)}`)}
      {...rest}
    />
  </div>
);

FormField.propTypes = {
  name: string.isRequired,
  readonly: bool,
};

const originOptions = [
  t('expenses.form.inland'),
  t('expenses.form.eu'),
  t('expenses.form.third_country'),
].map((o) => ({
  value: o,
  label: o,
}));

const CreateInvoiceSection = ({
  isReadonly,
  invoiceDate,
  discountGrant,
  isCancelInvoice = false,
  isNewInvoice = false,
  creditNote,
  isDraft,
  status,
  amendmentType,
  dueDate,
}) => {
  // Disable creditNote when discountGrant is checked and vice versa
  const isCreditNoteEnabled = isDraft && !discountGrant;
  const isDiscountGrantEnabled = !isReadonly('discountGrant') && !creditNote;
  const isCancellationInvoice = amendmentType === 'cancellation';
  const dispatch = useAppDispatch();
  const previousInvoiceDate = usePrevious(invoiceDate);

  useEffect(() => {
    const invoiceDateMomentObj = moment(invoiceDate, DATE_FORMAT);
    const dueDateMomentObj = moment(dueDate, DATE_FORMAT);
    const isInvoiceDateAfterDueDate = invoiceDateMomentObj.isAfter(dueDateMomentObj);
    const invoiceDatePlusWeek = invoiceDateMomentObj.add(7, 'days').format(DATE_FORMAT);

    if (previousInvoiceDate !== invoiceDate && isInvoiceDateAfterDueDate) {
      dispatch(change(FORM_NAME, 'dueDate', invoiceDatePlusWeek));
    }
  }, [dispatch, dueDate, invoiceDate, previousInvoiceDate]);

  return (
    <Card className={styles.main}>
      <Card.Header className={styles.header}>
        <I18n t="expenses.form.header_data" className={styles.headerLeft} />
        {!isCancellationInvoice && <InvoiceStatusBadge status={status} />}
      </Card.Header>
      <Card.Body>
        <div className={cx(styles.columns, styles.columnsPadded)}>
          <div className={styles.column}>
            <FormField
              dataId="IncomingInvoices:input-name"
              name="name"
              readonly={isReadonly('name')}
              component={TextField}
              required
            />
            <Supplier readonly={isReadonly('supplier')} isNewInvoice={isNewInvoice} required />
            <FormField
              dataId="IncomingInvoices:input-number"
              name="number"
              readonly={isReadonly('number')}
              component={TextField}
              required
            />
            <FormField
              name="placeOfOrigin"
              readonly={isReadonly('placeOfOrigin')}
              hasTooltip
              message={t(`expenses.form.place_of_origin_info`)}
              tooltipPlacement="left"
              component={SelectField}
              options={originOptions}
            />
            {!isCancelInvoice && (
              <CreditNote disabled={!isCreditNoteEnabled} readonly={isReadonly('creditNote')} />
            )}
            <div className={styles.columns}>
              <div className={styles.column}>
                <FormField
                  name="invoiceDate"
                  readonly={isReadonly('invoiceDate')}
                  component={DateField}
                  required
                  onChange={noop}
                  dataIds={{ input: 'IncomingInvoices:input-invoice-date' }}
                />
              </div>
              <div className={styles.column}>
                <FormField
                  name="dueDate"
                  readonly={isReadonly('dueDate')}
                  component={DateField}
                  required
                  dataIds={{ input: 'IncomingInvoices:input-due-date' }}
                  dueDate={dueDate}
                />
              </div>
            </div>
            <Field
              name="discountGrant"
              dataId="IncomingInvoices:checkbox-discountGrant"
              disabled={!isDiscountGrantEnabled}
              component={Checkbox}
              className={styles.formGroupCheckbox}
            >
              {t('revenue.form.discount_grant')}
            </Field>
            <div className={styles.columns}>
              <div className={cx(styles.column, styles.withSuffix)}>
                <div className={styles.formGroup}>
                  <CurrencyFieldWrapper
                    dataId="IncomingInvoices:input-discount-percentage"
                    name="discountPercentage"
                    precision={2}
                    isRawField
                    translationBase="expenses.form"
                    component={TextField}
                    readonly={!discountGrant || isReadonly('discountPercentage')}
                    isCurrencySignVisible={false}
                  />
                </div>
                <span>%</span>
              </div>
              <div className={styles.column}>
                <FormField
                  name="discountPeriod"
                  component={DateField}
                  readonly={!discountGrant || isReadonly('discountPeriod')}
                  disableDateInput
                  minDate={invoiceDate ? moment(invoiceDate, 'DD.MM.YYYY') : undefined}
                  dataIds={{ input: 'IncomingInvoices:input-discount-period' }}
                  invoiceDate={invoiceDate}
                />
              </div>
            </div>
            <FormField
              dataId="IncomingInvoices:input-discount-service-period"
              name="servicePeriod"
              readonly={isReadonly('servicePeriod')}
              component={TextField}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

CreateInvoiceSection.propTypes = {
  discountGrant: bool,
  isReadonly: func.isRequired,
  isCancelInvoice: bool,
  isNewInvoice: bool,
  creditNote: bool.isRequired,
  isDraft: bool.isRequired,
  invoiceDate: string,
  status: string,
  amendmentType: string,
  dueDate: string,
};

const mapStateToProps = (s) => ({
  discountGrant: incomingInvoiceSelector(s, 'discountGrant'),
  creditNote: incomingInvoiceSelector(s, 'creditNote'),
  isDraft: incomingInvoiceSelector(s, 'draft'),
  invoiceDate: incomingInvoiceSelector(s, 'invoiceDate'),
  status: incomingInvoiceDetailsSelector(s).status,
  amendmentType: incomingInvoiceSelector(s, 'amendmentType'),
  dueDate: incomingInvoiceSelector(s, 'dueDate'),
});

export default connect(mapStateToProps)(CreateInvoiceSection);
