import React from 'react';

import { t } from 'shared/utils';
import Button from 'redesign/components/atoms/Button/Button';
import CircleIcon from 'redesign/components/atoms/CircleIcon/CircleIcon';
import IconWarning from 'redesign/components/atoms/Icons/Warning';
import ModalActions from 'redesign/components/atoms/ModalActions/ModalActions';
import Modal from 'redesign/components/molecules/Modal/Modal';

import { ModalBody, Title } from './DiscardChangesModal.styled';

export type DiscardChangesModalProps = {
  onCancel: () => void;
  onConfirm: () => void;
};

const DiscardChangesModal = ({ onCancel, onConfirm }: DiscardChangesModalProps) => {
  return (
    <Modal isOpen onRequestClose={onCancel} data={{ id: 'dashboard-discard-changes-modal' }}>
      <ModalBody>
        <CircleIcon icon={IconWarning} color="red" />
        <Title>{t('features.settings.document_template.discard_changes_modal.title_2')}</Title>
      </ModalBody>
      <ModalActions>
        <Button onClick={onConfirm} data-id="dashboard-discard-changes-modal-confirm-button">
          {t('features.settings.document_template.discard_changes_modal.confirm')}
        </Button>
        <Button
          $variant="outlined"
          onClick={onCancel}
          data-id="dashboard-discard-changes-modal-cancel-button"
        >
          {t('features.settings.document_template.discard_changes_modal.cancel')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DiscardChangesModal;
