import React from 'react';

import { t } from 'shared/utils';

import { TManualBankAccount } from '../../types';
import * as SC from './ManualBankDetails.styled';

type ManualBankDetailsProps = {
  bankDetails: TManualBankAccount;
};

const ManualBankDetails = ({ bankDetails }: ManualBankDetailsProps) => {
  return (
    <SC.BankDetails>
      <SC.BankDetailsCaption>
        <p>{t('form.fields.iban')}</p>
        <p>{t('form.fields.bic')}</p>
        <p>{t('form.fields.bank_name')}</p>
      </SC.BankDetailsCaption>
      <SC.BankDetailsContent>
        <p>{bankDetails?.iban}</p>
        <p>{bankDetails?.bic}</p>
        <p>{bankDetails?.bankName}</p>
      </SC.BankDetailsContent>
    </SC.BankDetails>
  );
};

export default ManualBankDetails;
