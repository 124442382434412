import moment from 'moment';

import { toMoment } from 'shared/utils';

const defaultValues = {
  initial: {
    startDate: moment().add(-1, 'months').endOf('month').add(1, 'days').format('DD.MM.YYYY'),
    endDate: moment().format('DD.MM.YYYY'),
  },
};

export default (state = defaultValues) => {
  if (!state.values) return state;
  const { startDate, endDate } = state.values;

  const isDateRangeInputDirty =
    defaultValues.initial.startDate !== state.values.startDate ||
    defaultValues.initial.endDate !== state.values.endDate;

  if (toMoment(startDate).isBefore(toMoment(endDate))) {
    return { ...state, isDateRangeInputDirty };
  }

  return {
    ...state,
    values: {
      ...state.values,
      endDate: startDate,
    },
    isDateRangeInputDirty,
  };
};
