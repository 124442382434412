export const TOGGLE_TERMS_OF_USE = 'REGISTRATION/TOGGLE_TERMS_OF_USE';
export const TOGGLE_RECEIVE_OFFERS = 'REGISTRATION/TOGGLE_RECEIVE_OFFERS';
export const TOGGLE_DISCLAIMER = 'REGISTRATION/TOGGLE_DISCLAIMER_OFFERS';
export const SET_PREFILLED_REGISTRATION_VALUES = 'REGISTRATION/SET_PREFILLED_REGISTRATION_VALUES';

export const FROM_CONFIRMATION_QUERY_PARAM = 'from-email-confirmation';

export enum RegistrationSource {
  Vrso = 'vrso',
}
