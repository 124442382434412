import React from 'react';

import SectionWithMargin from 'containers/Profile/SectionWithMargin';
import PasswordNotification from 'containers/RegistrationForm/Form/PasswordNotification/PasswordNotification';
import { t } from 'shared/utils';
import PasswordField from 'components/Form/PasswordField';

import FormField from '../common/FormField';
import NewPasswordField from '../common/NewPasswordField';

import styles from './ChangePassword.module.css';

interface ChangePasswordProps {
  minimumLength?: number;
}

const ChangePassword = ({ minimumLength = 8 }: ChangePasswordProps) => (
  <SectionWithMargin title={t('profile.sections.personal.password')}>
    <div className={styles.fieldsAndNotificationContainer}>
      <div className={styles.fieldsContainer}>
        <FormField
          name="currentPassword"
          component={PasswordField}
          required
          dataId="currentPassword"
        />
        <NewPasswordField name="password" required />
        <NewPasswordField name="passwordConfirm" required />
      </div>
      <div className={styles.passwordNotification}>
        <PasswordNotification minimumLength={minimumLength} />
      </div>
    </div>
  </SectionWithMargin>
);

export default ChangePassword;
