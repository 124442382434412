import React from 'react';
import { bool, string } from 'prop-types';

import { t } from 'shared/utils';

import Checkmark from './Checkmark';

import styles from '../ComparisonTable.module.css';

const OptionRow = ({ title, isPremium }) => (
  <div className={styles.option}>
    <span className={styles.title}>{title}</span>
    <div className={styles.checkmarksContainer}>
      <span className={styles.checkmarkTitle}>{t('profile.payment_plan.table.headers.basic')}</span>
      <Checkmark isPremium={!isPremium} />
      <span className={styles.checkmarkTitle}>
        {t('profile.payment_plan.table.headers.premium')}
      </span>
      <Checkmark isPremium />
    </div>
  </div>
);

OptionRow.propTypes = {
  title: string.isRequired,
  isPremium: bool,
};

export default OptionRow;
