import React, { useState } from 'react';
import { func, node, shape } from 'prop-types';

import SearchNavigationButtons from './SearchNavigationButtons';
import { SearchNavigationContext } from './SearchNavigationContext';
import SearchNavigationInputs from './SearchNavigationInputs';

/**
 * Compound component that is a wrapper for `Buttons` and `Inputs` components. It only has
 * a provider that will pass through props to another components. They can be accessed using
 * context (Consumer).
 */
const SearchNavigation = ({ filters, clearFilters, children }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <SearchNavigationContext.Provider
      value={{ filters, clearFilters, isSearchOpen, setIsSearchOpen }}
    >
      {children}
    </SearchNavigationContext.Provider>
  );
};

SearchNavigation.propTypes = {
  // object that will keep all input values
  filters: shape({}),
  // a function to reset filters object
  clearFilters: func,
  children: node,
};

SearchNavigation.Buttons = SearchNavigationButtons;

SearchNavigation.Inputs = SearchNavigationInputs;

export default SearchNavigation;
