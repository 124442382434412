import { get } from 'lodash';
import { change } from 'redux-form';

import { paginationParams, sortingParams } from 'actions/helpers/table';
import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import { DATE_FORMAT } from 'constants/datetime';
import {
  EDIT_FAILURE,
  EDIT_REQUEST,
  EDIT_SUCCESS,
  ENTER_EDIT_INVOICE_MODE,
  EXIT_EDIT_INVOICE_MODE,
  EXPORT_FAILURE,
  EXPORT_REQUEST,
  EXPORT_SUCCESS,
  FETCH_CREDS_FAILURE,
  FETCH_CREDS_REQUEST,
  FETCH_CREDS_SUCCESS,
  FETCH_DATES_FAILURE,
  FETCH_DATES_REQUEST,
  FETCH_DATES_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SORT,
  INDEX_SUCCESS,
  REMOVE_END_DATE_ERROR,
  REMOVE_MISSING_DATES_ERROR,
  REMOVE_START_AND_END_DATE_ERROR,
  SET_END_DATE_ERROR,
  SET_MISSING_DATES_ERROR,
  SET_START_AND_END_DATE_ERROR,
  VALIDATION_FAILURE,
  VALIDATION_REQUEST,
  VALIDATION_SUCCESS,
} from 'constants/datev';
import { Resources } from 'constants/resources';
import { datevExportSuccess } from 'notifications/datev';
import { parseDate } from 'shared/utils/date/parser';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { piwikHelpers } from 'shared/utils/piwik';
import { bindServerValidation } from 'shared/utils/server-validation';

export const apiEdit = (id, data) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: `/me/datev_reports/${id}/datev_report_emails`,
    types: [EDIT_REQUEST, EDIT_SUCCESS, EDIT_FAILURE],
  },
});

const apiIndex = (pagination = {}, sorting = {}) => ({
  [CALL_API]: {
    params: {
      ...paginationParams(pagination),
      ...sortingParams(sorting),
    },
    endpoint: '/me/datev_reports',
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
  },
});

const apiValidation = (startDate, endDate) => {
  const types = [VALIDATION_REQUEST, VALIDATION_SUCCESS, VALIDATION_FAILURE];

  return {
    [CALL_API]: {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
      endpoint: '/me/datev_reports/verify_new_report_data',
      types,
    },
  };
};

export const apiGetReport = (id) => ({
  [CALL_API]: {
    endpoint: `/me/datev_reports/${id}`,
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiExportData = (data) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: '/me/datev_reports',
    types: [EXPORT_REQUEST, EXPORT_SUCCESS, EXPORT_FAILURE],
  },
});

const sort = (column) => ({
  type: INDEX_SORT,
  column,
});

export const getIndex = (pagination, sorting) => (dispatch) =>
  dispatch(apiIndex({ pagination_resource: Resources.DATEV, ...pagination }, sorting)).catch(
    apiErrorHandler
  );

export const getValidation = (startDate, endDate) => (dispatch) => {
  // If startDate or endDate is undefined then date range is only one day
  const startDateFormatted = startDate
    ? parseDate(startDate, DATE_FORMAT)
    : parseDate(endDate, DATE_FORMAT);

  const endDateFormatted = endDate
    ? parseDate(endDate, DATE_FORMAT)
    : parseDate(startDate, DATE_FORMAT);

  return dispatch(apiValidation(startDateFormatted, endDateFormatted)).catch(apiErrorHandler);
};

export const sortAndRefresh = (column) => (dispatch, getState) => {
  dispatch(sort(column));
  const { pagination, sorting } = getState().datev;
  dispatch(getIndex({ ...pagination, page: 1 }, sorting));
};

export const getReport =
  (...args) =>
  (dispatch) =>
    dispatch(apiGetReport(...args)).catch(apiErrorHandler);

export const exportData =
  (...args) =>
  (dispatch) =>
    bindServerValidation(apiExportData(...args), dispatch).then(() => {
      dispatch(showNotification(datevExportSuccess));
      piwikHelpers.trackGoal(10);
    });

export const addEmailToReport = (id, data) => (dispatch) =>
  bindServerValidation(apiEdit(id, data), dispatch);

export const apiFetchLatestCreds = () => ({
  [CALL_API]: {
    endpoint: '/me/datev_report_emails/latest',
    types: [FETCH_CREDS_REQUEST, FETCH_CREDS_SUCCESS, FETCH_CREDS_FAILURE],
  },
});

export const apiGetReportDates = () => ({
  [CALL_API]: {
    endpoint: `/me/datev_reports/new_report_dates`,
    types: [FETCH_DATES_REQUEST, FETCH_DATES_SUCCESS, FETCH_DATES_FAILURE],
  },
});

export const getReportDates = () => (dispatch) =>
  dispatch(apiGetReportDates()).catch(apiErrorHandler);

export const fetchLatestCreds =
  (startDate, endDate, options = {}) =>
  (dispatch) => {
    dispatch(apiFetchLatestCreds())
      .then(({ response }) => {
        const { email, password } = get(response, 'data.attributes');
        if (options.isFormReinitialized) {
          dispatch(change('DatevCreator', 'email', email || ''));
          dispatch(change('DatevCreator', 'password', password || ''));
        }
        dispatch(getValidation(startDate, endDate));
      })
      .catch(apiErrorHandler);
  };

export const setReportDatePeriod = (startDate, endDate) => (dispatch) => {
  dispatch(change('DatevCreator', 'startDate', startDate));
  dispatch(change('DatevCreator', 'endDate', endDate));
};

export const setEndDateError = () => ({
  type: SET_END_DATE_ERROR,
});

export const removeEndDateError = () => ({
  type: REMOVE_END_DATE_ERROR,
});

export const setMissingDatesError = () => ({
  type: SET_MISSING_DATES_ERROR,
});

export const removeMissingDatesError = () => ({
  type: REMOVE_MISSING_DATES_ERROR,
});

export const setStartAndEndDateError = () => ({
  type: SET_START_AND_END_DATE_ERROR,
});

export const removeStartAndEndDateError = () => ({
  type: REMOVE_START_AND_END_DATE_ERROR,
});

export const enterEditInvoiceMode = (invoiceId) => ({
  type: ENTER_EDIT_INVOICE_MODE,
  invoiceId,
});

export const exitEditInvoiceMode = () => ({
  type: EXIT_EDIT_INVOICE_MODE,
});
