import React from 'react';

import { Item } from '../types';
import * as SC from './Infosection.styled';

type Props = {
  items: Item[];
  withTopBorder: boolean;
};

const InfoSection = ({ items, withTopBorder }: Props) => (
  <SC.Container $withTopBorder={withTopBorder}>
    <ul>
      {items.map((item) => {
        return (
          <>
            {item.header && <SC.InfoHeader>{item.header}</SC.InfoHeader>}
            <SC.ListItem>{item.title}</SC.ListItem>
          </>
        );
      })}
    </ul>
  </SC.Container>
);

export default InfoSection;
