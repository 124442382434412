import React from 'react';
import { bool } from 'prop-types';

import IconGift from 'images/icon-gift.svg';
import { t } from 'shared/utils';

import styles from './PriceInfo.module.css';

const VrBankCustomer = () => (
  <div className={styles.vrBankContainer}>
    <div className={styles.iconGift}>
      <img src={IconGift} alt="gift" />
    </div>
    <div className={styles.contentContainer}>
      <div className={styles.vrBankContent}>
        <span className={styles.content}>
          {t('profile.payment_plan.book_premium.price_info.content_2')}
        </span>
      </div>
      <div className={styles.vrBankContent}>
        <span className={styles.vrBankSubcontent}>
          {t('profile.payment_plan.book_premium.price_info.content_3')}
        </span>
      </div>
    </div>
  </div>
);

const NoVrBankCustomer = () => (
  <div className={styles.container}>
    <span className={styles.content}>
      {t('profile.payment_plan.book_premium.price_info.content_1')}
    </span>
    <div className={styles.price}>
      <span>{t('profile.payment_plan.book_premium.price_info.monthly')}</span>
      <span>10,00</span>
      <span>€*</span>
    </div>
  </div>
);

const PriceInfo = ({ isVrBankCustomer }) => (
  <>
    {!isVrBankCustomer && <NoVrBankCustomer />}
    {isVrBankCustomer && <VrBankCustomer />}
  </>
);

PriceInfo.propTypes = {
  isVrBankCustomer: bool.isRequired,
};

export default PriceInfo;
