import React from 'react';

import WarningIcon from 'images/icon-exclamation-warning.svg';
import { t } from 'shared/utils';
import AccountInfoPublicArea from 'components/AccountInfoPublicArea/AccountInfoPublicArea';

import styles from './ConfirmationLinkOutdatedPublic.module.css';

const ConfirmationLinkOutdatedPublic = () => (
  <AccountInfoPublicArea
    headingText={t('registration_confirmation.account_confirm_link_outdated.heading')}
    icon={WarningIcon}
    infoText={[t('registration_confirmation.account_confirm_link_outdated.text')]}
    iconClassName={styles.icon}
  />
);

export default ConfirmationLinkOutdatedPublic;
