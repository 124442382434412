import React from 'react';
import { bool, func } from 'prop-types';

import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import Modal, { ModalHeader } from 'components/Modal';

import localStyles from './PaymentAttentionModal.module.css';

const PaymentAttentionModal = ({
  isOpen,
  onAccept,
  onClose,
  isPaidBy: {
    isPaidWithCashModalVisible: isPaidByCash,
    isPaidWithUnspecifiedModalVisible: isPaidByUnspecified,
  },
}) => {
  const type = isPaidByCash ? 'paid_with_cash' : isPaidByUnspecified ? 'paid_by_unspecified' : '';

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader>
        <span className={localStyles.modalHeaderContent}>
          {t(`bank_transfers.${type}.modal.title`)}
        </span>
      </ModalHeader>
      <div className={localStyles.modalBody}>
        <div className={localStyles.paragraph}>
          {t(`bank_transfers.${type}.modal.paragraph_one`)}
        </div>
        <div className={localStyles.paragraph}>
          {t(`bank_transfers.${type}.modal.paragraph_two`)}
          {isPaidByUnspecified && (
            <>
              <br />
              {t(`bank_transfers.paid_by_unspecified.modal.paragraph_three`)}
            </>
          )}
        </div>
        <div className={localStyles.footerSection}>
          <ActionButton
            appearance="primary"
            onClick={onClose}
            label={t(`bank_transfers.${type}.modal.button_close`)}
          />
          <ActionButton
            appearance="outlined"
            onClick={onAccept}
            label={t(`bank_transfers.${type}.modal.button_accept`)}
          />
        </div>
      </div>
    </Modal>
  );
};

PaymentAttentionModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onAccept: func.isRequired,
};

export default PaymentAttentionModal;
