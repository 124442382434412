import React from 'react';

import { t } from 'shared/utils';

import Checkmark from '../../../../ComparisonTable/components/Checkmark';

import styles from './MissingFeatures.module.css';

const options = [
  {
    title: t('profile.payment_plan.missing_features.row_a'),
  },
  {
    title: t('profile.payment_plan.missing_features.row_b'),
  },
  {
    title: t('profile.payment_plan.missing_features.row_c'),
  },
  {
    title: t('profile.payment_plan.missing_features.row_d'),
  },
  {
    title: t('profile.payment_plan.missing_features.row_e'),
  },
  {
    title: t('profile.payment_plan.missing_features.row_f'),
  },
  {
    title: t('profile.payment_plan.missing_features.row_g'),
  },
  {
    title: t('profile.payment_plan.missing_features.row_h'),
  },
  {
    title: t('profile.payment_plan.missing_features.row_i'),
  },
  {
    title: t('profile.payment_plan.missing_features.row_j'),
  },
];

const MissingFeatures = () => (
  <div className={styles.container}>
    {options.map(({ title }, index) => (
      <div className={styles.row} key={`${title}_${index}`}>
        <span>{title}</span>
        <Checkmark />
      </div>
    ))}
  </div>
);

export default MissingFeatures;
