import React, { ReactNode, useRef, useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper-legacy';
import { useClickAway } from 'react-use';

import useMedia from 'shared/hooks/useMedia';
import IconComment from 'components/Icons/IconComment';
import { Breakpoints } from 'redesign/styles/breakpoints';

import { Arrow, Button, Content, Tooltip } from './Comment.styled';

type CommentProps = {
  text: ReactNode;
  dataIds?: {
    icon?: string;
    comment?: string;
  };
};

const Comment = ({ text, dataIds }: CommentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const popperRef = useRef(null);

  const close = () => setIsOpen(false);

  useClickAway(popperRef, close);

  const toggle = () => setIsOpen((isOpen) => !isOpen);
  const isShowBelow = useMedia([`(min-width: ${Breakpoints.mdMax})`], [true], false);

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <Button ref={ref} onClick={toggle} isOpen={isOpen} data-id={dataIds?.icon}>
            <IconComment width="20" height="20" />
          </Button>
        )}
      </Reference>
      {isOpen && (
        <Popper
          modifiers={{
            preventOverflow: {
              enabled: false,
            },
            flip: {
              enabled: false,
            },
            offset: { offset: isShowBelow ? '0, 11' : '-40px, 13' },
            keepTogether: { enabled: !isShowBelow },
          }}
          placement={isShowBelow ? 'bottom' : 'left-start'}
        >
          {({ ref, style, placement }) => (
            <Tooltip ref={ref} style={style} data-placement={placement}>
              <div ref={popperRef}>
                <Content data-id={dataIds?.comment}>{text}</Content>
                <Arrow />
              </div>
            </Tooltip>
          )}
        </Popper>
      )}
    </Manager>
  );
};

export default Comment;
