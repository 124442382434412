import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as pushAction } from 'connected-react-router';
import { get, isEmpty } from 'lodash';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { deleteProposal } from 'actions/proposal';
import {
  clearFilters as clearFiltersAction,
  fetchProposals,
  setQueryParam,
  sortProposals,
} from 'actions/proposals';
import { Resources } from 'constants/resources';
import paths from 'routes/paths';
import sharedStyles from 'shared/styles/components.module.css';
import { invalidRangeChecker, objectHasValues, t } from 'shared/utils';
import { dateFilterMapper } from 'shared/utils/filters/dateFilterMapper';
import { hasAnyFiltersActive as hasAnyFiltersActiveChecker } from 'shared/utils/hasAnyFiltersActive';
import { useDebouncedHasAnyFilters } from 'shared/utils/hooks/useDebouncedHasAnyFilters';
import { useRefetchOnPropsChange } from 'shared/utils/hooks/useRefetchOnPropsChange';
import CardView, { HeadingSection, Section } from 'components/CardView';
import If from 'components/Conditions/If';
import EmptyEntryPage from 'components/EmptyEntryPage/EmptyEntryPage';
import ProposalsEmptyStateImage from 'components/EmptyEntryPage/images/Proposals';
import { FiltersGroup } from 'components/Filter/FiltersGroup/FiltersGroup';
import I18n from 'components/I18n';
import Loading from 'components/Loading';
import { Pagination } from 'components/Pagination/Pagination';
import { Search, SearchDateGroup, SearchGroup } from 'components/Search';
import { CreationForbiddenMessage } from 'components/Table';
import CSVDownloadButton from 'components/Table/CSVDownloadButton/CSVDownloadButton';

import NewProposalButton from './NewProposalButton';
import ProposalsTable from './ProposalsTable';

import localStyles from './Proposals.module.css';

const styles = { ...sharedStyles, ...localStyles };

const DATE_FILTERS = ['proposalDateFrom', 'proposalDateTo', 'validUntilFrom', 'validUntilTo'];

const Proposals = ({
  canCreate,
  showModal,
  data,
  fetchProposals,
  isFetching,
  pagination,
  remove,
  sort,
  sorting,
  filters,
  parsedFilters,
  setQueryAction,
  clearFilters,
  push,
}) => {
  /**
   * This flag is initially set to false and is set to true after first fetch
   * for proposals comes back. It is used for displaying the initial loader.
   */
  const [areProposalsFetched, setAreProposalsFetched] = useState(false);
  const debouncedHasAnyFiltersActive = useDebouncedHasAnyFilters(parsedFilters);

  const fetch = useCallback(
    (pagination = { page: 1 }) => {
      const isIndexClassicView = objectHasValues(parsedFilters);

      fetchProposals({ pagination_resource: Resources.PROPOSALS, ...pagination }, sorting, {
        isIndexClassicView,
      });
    },
    [fetchProposals, sorting, parsedFilters]
  );

  useEffect(
    () => () => {
      clearFilters();
    },
    [clearFilters]
  );

  useRefetchOnPropsChange({
    fetchFunction: async ({
      pagination: newPagination,
      sorting: newSorting,
      overwrittenValues,
      parsedFilters: newParsedFilters,
    }) => {
      const isIndexClassicView = objectHasValues(newParsedFilters);

      await fetchProposals({ ...newPagination, ...overwrittenValues }, newSorting, {
        isIndexClassicView,
      });
      setAreProposalsFetched(true);
    },
    props: { parsedFilters, pagination, sorting },
  });

  if (!areProposalsFetched) {
    return (
      <div className={styles.page}>
        <div className={styles.main}>
          <Loading />
        </div>
      </div>
    );
  }

  const hasAnyFiltersActive = hasAnyFiltersActiveChecker(filters);
  const areProposalsEmpty = isEmpty(data);
  const isEmptyStateVisible = !isFetching && areProposalsEmpty && !debouncedHasAnyFiltersActive;

  if (isEmptyStateVisible) {
    return (
      <div className={styles.page}>
        <CardView className={styles.card}>
          <Section className={styles.section}>
            <EmptyEntryPage
              dataIdPrefix="Proposals"
              Image={ProposalsEmptyStateImage}
              header={t('empty_entry_pages.proposals.header')}
              subheader={t('empty_entry_pages.proposals.subheader')}
              info={[
                t('empty_entry_pages.proposals.info.row_1'),
                t('empty_entry_pages.proposals.info.row_2'),
              ]}
              createButtonLabel={t('empty_entry_pages.proposals.button_label')}
              onCreateButtonClick={() => push(paths.newProposal)}
            />
          </Section>
        </CardView>
      </div>
    );
  }

  const isInvalidRange = invalidRangeChecker({
    start: filters.amountHigherThan,
    end: filters.amountLowerThan,
  });

  return (
    <div className={styles.page}>
      <CardView className={styles.card}>
        <HeadingSection>
          <I18n t="proposals.subheader.index" className={styles.headingText} />
          <div
            className={cx(styles.pullRight, {
              [styles.hiddenSection]: areProposalsEmpty && !hasAnyFiltersActive,
            })}
          >
            <If ok={canCreate}>
              <NewProposalButton showModal={showModal} />
            </If>
            <If ok={!canCreate}>
              <CreationForbiddenMessage section="proposals" />
            </If>
          </div>
          <FiltersGroup
            className={styles.filtersGroup}
            isResetButtonDisabled={!hasAnyFiltersActive}
            filters={filters}
            onFiltersChange={({ name, value }) => setQueryAction(name, value)}
            onFiltersReset={clearFilters}
            placeholder={t('tables.filters.filters_group.proposals.placeholder')}
            dataIds={{
              input: 'Proposals:input-search',
            }}
            additionalFilters={
              <SearchGroup
                setQueryAction={(name) => (value) => {
                  if (DATE_FILTERS.includes(name))
                    return setQueryAction(name, value, dateFilterMapper);
                  setQueryAction(name, value);
                }}
                clearFieldAction={(name) => setQueryAction(name)}
                filters={filters}
              >
                <SearchDateGroup
                  label={t('proposals.table.columns.proposal_date_range')}
                  startDateProps={{
                    name: 'proposalDateFrom',
                  }}
                  endDateProps={{
                    name: 'proposalDateTo',
                  }}
                />
                <SearchDateGroup
                  label={t('proposals.table.columns.valid_until_range')}
                  startDateProps={{
                    name: 'validUntilFrom',
                  }}
                  endDateProps={{
                    name: 'validUntilTo',
                  }}
                />
                <Search
                  placeholder={t('expenses.table.columns.amount_higher_than')}
                  name="amountHigherThan"
                  invalid={isInvalidRange}
                  isCurrency
                  isCurrencySignVisible
                />
                <Search
                  placeholder={t('expenses.table.columns.amount_lower_than')}
                  name="amountLowerThan"
                  invalid={isInvalidRange}
                  type="tel"
                  isCurrency
                  isCurrencySignVisible
                />
              </SearchGroup>
            }
          >
            <CSVDownloadButton
              filters={filters}
              sorting={sorting}
              endpoint="proposals_csv"
              disabled={areProposalsEmpty}
              className={styles.csvButton}
            />
          </FiltersGroup>
        </HeadingSection>
        <Section className={styles.section}>
          {areProposalsEmpty && hasAnyFiltersActive ? (
            <div className={localStyles.emptyTableMessage} data-id="Proposals:table-empty-info">
              {t('tables.empty_proposal')}
            </div>
          ) : (
            <ProposalsTable
              data={data}
              isFetching={isFetching}
              refresh={fetch}
              remove={remove}
              sort={sort}
              sorting={sorting}
            />
          )}
        </Section>
        <Section
          className={cx(styles.section, styles.pagination, {
            [styles.hiddenSection]: areProposalsEmpty,
          })}
        >
          <Pagination
            {...pagination}
            request={fetch}
            isFetching={isFetching}
            resource={Resources.PROPOSALS}
          />
        </Section>
      </CardView>
    </div>
  );
};

Proposals.propTypes = {
  canCreate: bool.isRequired,
  showModal: bool.isRequired,
  data: arrayOf(shape({})).isRequired,
  fetchProposals: func.isRequired,
  isFetching: bool.isRequired,
  pagination: shape({
    perPage: number,
    page: number,
  }).isRequired,
  remove: func.isRequired,
  sort: func.isRequired,
  sorting: shape({}).isRequired,
  filters: string.isRequired,
  parsedFilters: shape({}),
  setQueryAction: func.isRequired,
  clearFilters: func.isRequired,
  push: func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state.proposals,
  canCreate: get(state, 'proposals.meta.actions.create', false),
  showModal: !get(state, 'proposals.meta.bank-account-data-present', false),
});

const mapDipatchToProps = {
  fetchProposals: fetchProposals,
  remove: deleteProposal,
  sort: sortProposals,
  setQueryAction: setQueryParam,
  clearFilters: clearFiltersAction,
  push: pushAction,
};

export default connect(mapStateToProps, mapDipatchToProps)(Proposals);
