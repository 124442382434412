import React from 'react';
import InlineSvg from 'react-inlinesvg';
import { func, string } from 'prop-types';

import WarningSignImage from 'images/warning-sign.svg';
import { t } from 'shared/utils';

import styles from './AccountDeletion.module.css';

const AccountDeletion = ({ deletionDate, onClick }) => {
  return (
    <div className={styles.boxContainer}>
      <div className={styles.iconContainer}>
        <InlineSvg src={WarningSignImage} />
      </div>
      <div className={styles.content}>
        <div>
          <span className={styles.boldText}>
            {t('profile.payment_plan.debit.account_deletion.deletion_process_started', {
              date: deletionDate,
            })}
          </span>
          <br />
          <br />
          <span className={styles.boldText}>
            {t('profile.payment_plan.debit.account_deletion.important_hint_headline')}
          </span>
          <br />
          <span className={styles.normalText}>
            {t('profile.payment_plan.debit.account_deletion.important_hint')}
          </span>
          <br />
          <br />
          <div onClick={onClick}>
            <span className={styles.link}>
              {t('profile.payment_plan.debit.account_deletion.link')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

AccountDeletion.propTypes = {
  deletionDate: string.isRequired,
  onClick: func.isRequired,
};

export default AccountDeletion;
