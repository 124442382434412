import { t } from 'shared/utils';

export const connectionCreateSuccess = {
  duration: 3000,
  title: t('notifications.invoice_transfer_connections.create_success.title'),
  variant: 'success',
};

export const connectionCreateFailure = {
  duration: 3000,
  title: t('notifications.invoice_transfer_connections.create_failure.title'),
  variant: 'warning',
};

export const connectionDeleteSuccess = {
  duration: 3000,
  title: t('notifications.invoice_transfer_connections.delete_success.title'),
  variant: 'success',
};

export const connectionDeleteFailure = {
  duration: 3000,
  title: t('notifications.invoice_transfer_connections.delete_failure.title'),
  variant: 'warning',
};

export const createLabelSuccess = {
  duration: 3000,
  title: t('notifications.invoice_transfer_connections.create_label_success.title'),
  variant: 'success',
};

export const updateLabelSuccess = {
  duration: 3000,
  title: t('notifications.invoice_transfer_connections.update_label_success.title'),
  variant: 'success',
};

export const deleteLabelSuccess = {
  duration: 3000,
  title: t('notifications.invoice_transfer_connections.delete_label_success.title'),
  variant: 'success',
};
