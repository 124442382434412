import styled from 'styled-components';

import media from 'shared/styles/breakpoints';
import IconEditComponent from 'components/Icons/IconEdit';

export const IconEdit = styled(IconEditComponent)`
  width: 12px;
  fill: ${({ theme }) => theme.legacy.colors.brandBlue};
  margin-left: 5px;
`;

export const NotificationRow = styled.div`
  display: grid;
  grid-row-gap: 5px;
  margin-bottom: 16px;

  ${media.greaterThan('sm-up')`
    grid-template-columns: min-content min-content;
    grid-row-gap: 0px;
    grid-column-gap: 5px;
    align-items: center;
    justify-content: flex-end;
  `}
`;

export const Rhythm = styled.span`
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
`;

export const CurrentRhythm = styled.span`
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
  white-space: nowrap;
`;

export const StartState = styled.div`
  border: 1px solid ${({ theme }) => theme.legacy.colors.greyLightest};
  background-color: ${({ theme }) => theme.legacy.colors.greyLightest};
  padding: 12px;
`;

export const CurrentState = styled(StartState)`
  margin-top: 2px;
`;

export const StateRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const StateDate = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
`;

export const StateAmount = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: right;
  white-space: nowrap;
`;

export const StateExpenses = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

export const StateRevenues = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

export const StateExpensesAmount = styled.div`
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
`;

export const StateRevenuesAmount = styled.div`
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
`;

export const ActionButton = styled.button`
  padding: 0;
  font: inherit;
  outline: inherit;
  border: none;
  color: inherit;
  background: none;
  cursor: pointer;
`;
