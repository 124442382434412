import React from 'react';
import moment from 'moment';
import { func, shape } from 'prop-types';

import { DATE_FORMAT, TIME_FORMAT } from 'constants/datetime';
import { ACTION_FINISH_PAYMENT_CREATOR } from 'constants/piwik';
import { formatMoneyInput, t } from 'shared/utils';
import { trackCreatePaymentGoal, trackPaymentEvent } from 'shared/utils/tracking/payments-tracking';
import ActionButton from 'components/ActionPanel/ActionButton';
import I18n from 'components/I18n';

import FieldsContainer from '../../components/FieldsContainer/FieldsContainer';
import Hint from '../../components/Hint/Hint';
import PaymentCreatorActions from '../../components/PaymentCreatorActions/PaymentCreatorActions';

import styles from './PaymentSummary.module.css';

const trackCreatePaymentDone = () => {
  trackPaymentEvent(ACTION_FINISH_PAYMENT_CREATOR)();
  trackCreatePaymentGoal();
};

const PaymentSummary = ({
  paymentDetails: { purpose, iban, amount, recipientName },
  paymentCreationDate,
  finishAction,
}) => {
  const totalAmount = formatMoneyInput(amount, ',', '.');
  const date = moment(paymentCreationDate).format(DATE_FORMAT);
  const time = moment(paymentCreationDate).format(TIME_FORMAT);
  const notificationMessage = `${t('expenses.payments.creator.notification.part_1')} ${date}${t(
    'expenses.payments.creator.notification.part_2'
  )}${time}${t('expenses.payments.creator.notification.part_3')}`;

  return (
    <>
      <FieldsContainer>
        <div data-id="FigoPayments:success-banner" className={styles.notification}>
          {notificationMessage}
        </div>
        <Hint text={t('expenses.payments.creator.hints.summary')} />
        <div className={styles.details}>
          <div className={styles.detailsRow}>
            <I18n className={styles.detailsLabel} t="expenses.payments.creator.form.purpose" />
            <span className={styles.detailsValue}>{purpose}</span>
          </div>
          <div className={styles.detailsRow}>
            <I18n
              className={styles.detailsLabel}
              t="expenses.payments.creator.form.recipient_name"
            />
            <span className={styles.detailsValue}>{recipientName}</span>
          </div>
          <div className={styles.detailsRow}>
            <I18n className={styles.detailsLabel} t="expenses.payments.creator.form.iban" />
            <span className={styles.detailsValue}>{iban}</span>
          </div>
          <div className={styles.detailsRow}>
            <I18n className={styles.detailsLabel} t="expenses.payments.creator.form.amount" />
            <span className={styles.detailsValue}>{`${totalAmount} €`}</span>
          </div>
        </div>
        <div />
      </FieldsContainer>
      <PaymentCreatorActions>
        <ActionButton
          appearance="primary"
          onClick={finishAction}
          onClickSideAction={trackCreatePaymentDone}
          label={t('expenses.payments.actions.finish')}
        />
      </PaymentCreatorActions>
    </>
  );
};

PaymentSummary.propTypes = {
  finishAction: func.isRequired,
  paymentCreationDate: shape({}).isRequired,
  paymentDetails: shape({}).isRequired,
};

export default PaymentSummary;
