import React from 'react';
import { useHistory } from 'react-router';
import { values } from 'lodash';

import { t } from 'shared/utils';
import Modal from 'components/Modal';

import {
  ButtonContainer,
  ButtonPrimary,
  ButtonPrimaryContainer,
  ButtonSecondary,
  ButtonSecondaryContainer,
  Close,
  Header,
  Headline,
  ListItem,
  ModalBody,
  OrderedList,
  Text,
} from './ExportModal.styled';

type ExportModalProps = {
  onClose: () => void;
};

const ExportModal = ({ onClose }: ExportModalProps) => {
  const history = useHistory();
  const optionItems = values(
    t('profile.payment_plan.debit.export_modal.items') as unknown as {
      text: string;
    }[]
  );

  return (
    <Modal isOpen onRequestClose={onClose}>
      <Header>
        <Text>{t('profile.payment_plan.debit.export_modal.header')}</Text>
        <Close onClick={onClose} />
      </Header>
      <ModalBody>
        <Headline>{t('profile.payment_plan.debit.export_modal.headline')}</Headline>
        <OrderedList>
          {optionItems.map(({ text }, id) => (
            <ListItem key={id}>{text}</ListItem>
          ))}
        </OrderedList>
        <ButtonContainer>
          <ButtonSecondaryContainer>
            <ButtonSecondary onClick={onClose}>
              {t('profile.payment_plan.debit.export_modal.close')}
            </ButtonSecondary>
          </ButtonSecondaryContainer>
          <ButtonPrimaryContainer>
            <ButtonPrimary onClick={() => history.push('/datev')}>
              {t('profile.payment_plan.debit.export_modal.export')}
            </ButtonPrimary>
          </ButtonPrimaryContainer>
        </ButtonContainer>
      </ModalBody>
    </Modal>
  );
};

export default ExportModal;
