import React, { Component } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { arrayOf, func, node, number, shape } from 'prop-types';

export class LineItemsOrderManager extends Component {
  static propTypes = {
    invoiceId: number,
    children: node.isRequired,
    lineItems: arrayOf(shape({})).isRequired,
    updateLineItemsOrder: func.isRequired,
  };

  reoderLineItem = (oldIndex, newIndex) => (item) => {
    const index = item.ordinalNumber;
    if (index === oldIndex) return { ...item, ordinalNumber: newIndex };
    if (newIndex < oldIndex && index >= newIndex && index < oldIndex)
      return { ...item, ordinalNumber: index + 1 };
    if (newIndex > oldIndex && index <= newIndex && index > oldIndex)
      return { ...item, ordinalNumber: index - 1 };
    return { ...item, ordinalNumber: index };
  };

  onDragEnd = ({ source, destination }) => {
    const { lineItems, updateLineItemsOrder, invoiceId } = this.props;
    if (!destination) return;
    const oldIndex = source.index;
    const newIndex = destination.index;
    if (oldIndex === newIndex) return;

    const reorderedLineItems = lineItems.map(this.reoderLineItem(oldIndex, newIndex));

    updateLineItemsOrder(invoiceId, reorderedLineItems);
  };

  render() {
    const { children } = this.props;
    return <DragDropContext onDragEnd={this.onDragEnd}>{children}</DragDropContext>;
  }
}
