import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { push } from 'connected-react-router';
import normalize from 'json-api-normalizer';
import { get } from 'lodash';
import { bool, func, number, shape, string } from 'prop-types';
import { parse } from 'query-string';
import { compose } from 'recompose';
import { isDirty, reduxForm } from 'redux-form';
import build from 'redux-object';

import { updateClient } from 'actions/clients';
import { fetchCompany } from 'actions/company';
import {
  clearDefaultValues as clearDefaultValuesAction,
  fetchClientDefaults as fetchClientDefaultsAction,
} from 'actions/default-values';
import {
  abortChanges,
  downloadDeliveryNote,
  printDeliveryNote,
  transformDeliveryNote,
  updateDeliveryNote,
} from 'actions/delivery-note';
import { CANCEL_MODE, NEW_MODE, SHOW_MODE } from 'constants/common/crud';
import { FORM_NAME } from 'constants/delivery-notes';
import EntityPath from 'constants/entitiesPaths';
import { FROM_OUTGOING_INVOICE_QUERY_PARAM } from 'constants/outgoing-invoice';
import { FROM_PROPOSAL_QUERY_PARAM } from 'constants/proposal';
import Remark from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/CreateInvoiceSection/Remark';
import IndividualContactSection from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/IndividualContactSection';
import { deliveryNoteSelector } from 'reducers/form';
import paths from 'routes/paths';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { getDeliveryNoteInitialValues } from 'shared/utils/delivery-note-initial-values';
import If from 'components/Conditions/If';
import { LineItemsAutoSaveConsumer, LineItemsAutoSaveProvider } from 'components/LineItems';

import ActionsSection from './ActionsSection';
import AddAdressModal from './AddAddressModal/AddAddressModal';
import CreateDeliveryNoteSection from './CreateDeliveryNoteSection/CreateDeliveryNoteSection';
import { AddressType } from './CreateDeliveryNoteSection/DeviatingDeliveryAddress/DeviatingDeliveryAddress';
import Notes from './CreateDeliveryNoteSection/Notes/Notes';
import DeliveryNoteDetailsSection from './DeliveryNoteDetailsSections/DeliveryNoteDetailsSection';
import SignatureField from './DeliveryNoteDetailsSections/SignatureField/SignatureField';

import styles from './DeliveryNoteCreator.module.css';

export const isRedirectFromProposals = ({ search }) =>
  Object.keys(parse(search)).includes(FROM_PROPOSAL_QUERY_PARAM);

class DeliveryNoteCreator extends Component {
  state = {
    isModalOpen: false,
  };

  componentDidMount() {
    this.props.fetchCompanyInfo();
  }

  componentWillUnmount() {
    const { clearDefaultValues } = this.props;

    clearDefaultValues();
  }

  handleClientSelected = async (client) => {
    if (!client) return;

    const {
      fetchClientDefaults,
      reset: resetForm,
      currentValues,
      isSubjectDirty,
      change,
    } = this.props;
    const subject = currentValues ? currentValues.subject : null;

    await fetchClientDefaults(client.id);
    resetForm();

    const { initialValues } = this.props;

    if (isSubjectDirty && !initialValues.subject) change('subject', subject);

    change('client', client);
    change('useDeviatingDeliveryAddress', false);
    change('deviatingAddress', AddressType.Default);
  };

  handleOpenModalState = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModalState = () => {
    this.setState({ isModalOpen: false });
  };

  handleDeliveryData = async (values) => {
    const {
      updateClientData,
      client: { id },
      change,
    } = this.props;

    const response = await updateClientData(id, values, false, true);
    const client = build(normalize(response), EntityPath.Clients, response.data.id);

    change('client', client);
    change('deviatingAddress', AddressType.Deviating);
    change('useDeviatingDeliveryAddress', true);
    this.setState({ isModalOpen: false });
  };

  handleAddressChange = (value) => {
    this.props.change('useDeviatingDeliveryAddress', value);
  };

  render() {
    const {
      abort,
      canUpdate,
      canTransform,
      dirty,
      download,
      print,
      handleSubmit,
      deliveryNote,
      save,
      saveAsDraft,
      saveAndTransform,
      submitting,
      transform,
      currentClient,
      change,
      individualContact = false,
      salutationHonorific,
      crudMode,
      saveWithoutRedirect,
      client,
      deliveryNoteDetails,
    } = this.props;
    const { isModalOpen } = this.state;
    const isReadOnly = crudMode === SHOW_MODE || crudMode === CANCEL_MODE || !canUpdate;
    const deliveryNoteId = deliveryNote.details.id;
    const showSalutation = !(isReadOnly && !individualContact);
    const isNew = crudMode === NEW_MODE;
    const clientFullName = `${client?.firstName || ''} ${client?.lastName || ''}`.trim();
    const deliveryCompanyName = client?.companyName;
    const deliveryName = clientFullName ? clientFullName : deliveryCompanyName;
    const initialCountryValue = 'DE';

    return (
      <>
        <form>
          <LineItemsAutoSaveProvider>
            <div className={styles.main}>
              <div className={cx(styles.section, styles.creator)}>
                <CreateDeliveryNoteSection
                  onClientSelect={this.handleClientSelected}
                  readonly={isReadOnly}
                  handleOpenModalState={this.handleOpenModalState}
                  client={client}
                  handleChange={this.handleAddressChange}
                />
                <If ok={showSalutation}>
                  <IndividualContactSection
                    change={change}
                    client={currentClient}
                    disabled={isReadOnly}
                    salutationHonorific={salutationHonorific}
                    dataIds={{
                      salutation: 'DeliveryNotePage:input-salutation',
                      salutationContent: 'DeliveryNotePage:input-salutation-content',
                    }}
                  />
                </If>
                <DeliveryNoteDetailsSection crudMode={crudMode} readonly={isReadOnly} />
                <div className={styles.bottomSection}>
                  <Notes disabled={isReadOnly} />
                  <SignatureField readOnly={isReadOnly} />
                </div>
                <Remark
                  dataId="DeliveryNotePage:notes"
                  readonly={isReadOnly}
                  hint={t('features.delivery_note.form.remark_hint')}
                />
              </div>
            </div>
            <LineItemsAutoSaveConsumer>
              {({ handleSave }) => (
                <ActionsSection
                  currentClient={currentClient}
                  wrapped={!isReadOnly}
                  abort={abort}
                  isFormDirty={dirty}
                  isFormSubmitting={submitting}
                  isDraft={deliveryNoteDetails.draft}
                  readonly={isReadOnly}
                  showSave={canUpdate}
                  showTransform={canTransform}
                  print={print(deliveryNote.details)}
                  download={download(deliveryNote.details)}
                  save={handleSave(handleSubmit(save(deliveryNoteId, isNew)))}
                  saveAsDraft={handleSave(handleSubmit(saveAsDraft(deliveryNoteId, isNew)))}
                  saveWithoutRedirect={handleSave(
                    handleSubmit(saveWithoutRedirect(deliveryNoteId, isNew))
                  )}
                  saveAndTransform={handleSave(
                    handleSubmit(saveAndTransform(deliveryNoteId, isNew))
                  )}
                  transform={handleSave(handleSubmit(transform(deliveryNoteId)))}
                  crudMode={crudMode}
                />
              )}
            </LineItemsAutoSaveConsumer>
          </LineItemsAutoSaveProvider>
        </form>
        {isModalOpen && (
          <AddAdressModal
            handleCloseModalState={this.handleCloseModalState}
            clientName={deliveryName}
            handleDeliveryData={this.handleDeliveryData}
            initialValues={{
              deliveryName,
              deliveryCountry: initialCountryValue,
            }}
          />
        )}
      </>
    );
  }
}

DeliveryNoteCreator.propTypes = {
  abort: func.isRequired,
  canTransform: bool,
  canUpdate: bool,
  dirty: bool.isRequired,
  deliveryNote: shape({
    details: shape({
      id: number,
    }),
  }),
  submitting: bool.isRequired,
  download: func.isRequired,
  print: func.isRequired,
  send: func.isRequired,
  fetchCompanyInfo: func.isRequired,
  handleSubmit: func.isRequired,
  clearDefaultValues: func.isRequired,
  save: func.isRequired,
  saveAsDraft: func.isRequired,
  saveAndTransform: func.isRequired,
  transform: func.isRequired,
  currentClient: shape(),
  change: func.isRequired,
  individualContact: bool,
  salutationHonorific: string,
  deliveryDate: string.isRequired,
  crudMode: string.isRequired,
  fetchClientDefaults: func.isRequired,
  reset: func.isRequired, // This property comes from reduxForm HOC
  isSubjectDirty: bool.isRequired,
  initialValues: shape({}).isRequired,
  currentValues: shape({}).isRequired,
  isFromOutgoingInvoice: bool,
  isFromDuplicate: bool,
};

const mapStateToProps = (state, ownProps) => {
  const { crudMode, isFromOutgoingInvoice, isFromDuplicate } = ownProps;

  return {
    client: deliveryNoteSelector(state, 'client'),
    deliveryNoteDetails: state.deliveryNote.details,
    currentValues: state.form.deliveryNoteCreator ? state.form.deliveryNoteCreator.values : {},
    isSubjectDirty: isDirty(FORM_NAME)(state, 'subject'),
    isDirty: isDirty(FORM_NAME)(state),
    initialValues: getDeliveryNoteInitialValues(
      state,
      crudMode,
      isFromOutgoingInvoice,
      isFromDuplicate
    ),
    deliveryNote: state.deliveryNote,
    canTransform: get(state, 'deliveryNote.meta.actions.transform', false),
    canUpdate: get(state, 'deliveryNote.meta.actions.update', false),
    currentClient: deliveryNoteSelector(state, 'client'),
    individualContact: !!deliveryNoteSelector(state, 'salutationContent'),
    salutationHonorific: deliveryNoteSelector(state, 'salutationHonorific'),
    deliveryDate: deliveryNoteSelector(state, 'deliveryDate'),
    isProfileFilled: state.onboarding.data?.profileFilled,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateClientData: (id, values) =>
    dispatch(updateClient(id, values, { redirectAfter: false }, true)),
  fetchCompanyInfo: (...args) => dispatch(fetchCompany(...args)),
  save:
    (id, isNew = false) =>
    (values) => {
      const data = {
        ...values,
        isNew,
        draft: false,
        clientId: values.client?.id,
        deviatingAddress: values.deviatingAddress !== AddressType.Default,
      };

      delete data.client;

      return dispatch(updateDeliveryNote(id, data));
    },
  saveAsDraft:
    (id, isNew = false) =>
    (values) => {
      const data = {
        ...values,
        isNew,
        draft: true,
        clientId: values.client?.id,
        deviatingAddress: values.deviatingAddress !== AddressType.Default,
      };

      delete data.client;

      return dispatch(updateDeliveryNote(id, data));
    },
  transform: (itemid) => () =>
    dispatch(transformDeliveryNote({ id: itemid })).then(({ data: { id } }) => {
      dispatch(
        push({
          pathname: paths.editOutgoingInvoice(id),
          search: `?${FROM_OUTGOING_INVOICE_QUERY_PARAM}`,
        })
      );
    }),
  saveAndTransform:
    (id, isNew = false) =>
    (values) => {
      const data = {
        ...values,
        isNew,
        draft: false,
        clientId: values.client?.id,
        deviatingAddress: values.deviatingAddress === AddressType.Default ? false : true,
      };

      delete data.client;

      return dispatch(updateDeliveryNote(id, data, true));
    },
  saveWithoutRedirect:
    (id, isNew = false) =>
    (values) => {
      const data = {
        ...values,
        isNew,
        draft: false,
        clientId: values.client?.id,
        deviatingAddress: values.deviatingAddress === AddressType.Default ? false : true,
      };

      delete data.client;

      return dispatch(updateDeliveryNote(id, data, false, false));
    },
  print: (values) => (details) => (additionalData, isPiwikTracked, isCancelMode) => {
    const enhancedDetails = { ...details, ...additionalData };

    dispatch(printDeliveryNote(enhancedDetails, values, isPiwikTracked, isCancelMode));
  },
  download: (values) => (details) => (additionalData, isPiwikTracked, isCancelMode) => {
    const enhancedDetails = { ...details, ...additionalData };

    dispatch(downloadDeliveryNote(enhancedDetails, values, isPiwikTracked, isCancelMode));
  },
  abort: () => {
    if (isRedirectFromProposals(ownProps.location)) {
      return dispatch(abortChanges(paths.deliveryNotes));
    }

    return dispatch(abortChanges());
  },
  clearDefaultValues: () => dispatch(clearDefaultValuesAction()),
  fetchClientDefaults: (clientId) => dispatch(fetchClientDefaultsAction(clientId)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  print: dispatchProps.print(stateProps.values),
  download: dispatchProps.download(stateProps.values),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    persistentSubmitErrors: true,
  }),
  withTransitionPrevent()
)(DeliveryNoteCreator);
