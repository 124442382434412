import React, { ChangeEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { useDebounce, useMedia, useWindowSize } from 'react-use';
import { isEqual } from 'lodash';

import { getOutgoingInvoice, GetOutgoingInvoiceData } from 'api/me/previews/outgoingInvoice';
import { downloadData, t } from 'shared/utils';
import Loading from 'components/Loading';
import Checkbox from 'redesign/components/atoms/Checkbox/Checkbox';
import IconDownload from 'redesign/components/atoms/Icons/Download';
import IconSearch from 'redesign/components/atoms/Icons/Search';
import ControlLabel from 'redesign/components/molecules/ControlLabel/ControlLabel';
import PdfFullscreenPreview from 'redesign/components/organisms/PdfFullscreenPreview/PdfFullscreenPreview';
import { Breakpoints } from 'redesign/styles/breakpoints';

import { DocumentWrapper, Download, Page, ShowPreview, Tools, Wrapper } from './Preview.styled';

type PreviewProps = {
  values: GetOutgoingInvoiceData;
  onOpenPreview?: () => void;
  onDownload?: (isFromFullScreenPreview: boolean) => void;
  forceRefresh?: number;
};

const Preview = ({ values, onOpenPreview, onDownload }: PreviewProps) => {
  const isMobile = useMedia(`(max-width: ${Breakpoints.smMax})`);
  const documentWrapperRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width?: number; height?: number }>();
  const { width, height } = useWindowSize();
  const [multipageDocument, setMultipageDocument] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<ArrayBuffer>();
  const [numPages, setNumPages] = useState<number>();
  const [showPreview, setShowPreview] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  }, []);

  const handleRenderSuccess = useCallback(() => {
    setIsLoading(false);
  }, []);

  const handleCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setMultipageDocument(event.target.checked);
  }, []);

  const handleShowPreview = useCallback(() => {
    setShowPreview(true);
    onOpenPreview?.();
  }, [onOpenPreview]);

  const handleClosePreview = useCallback(() => {
    setShowPreview(false);
  }, []);

  const handleDownload = useCallback(
    (isFromFullScreenPreview = false) =>
      async () => {
        const download = async () => {
          const response = await getOutgoingInvoice({
            ...values,
            multipage_document: multipageDocument,
            watermark: true,
          });

          downloadData(response.data, 'vr-smart-guide_dokumentvorschau');
          setIsDownloading(false);
        };

        onDownload?.(isFromFullScreenPreview);
        setIsDownloading(true);
        await download();
      },
    [values, multipageDocument, onDownload]
  );

  useDebounce(
    () => {
      const margin = isMobile ? 8 : 16;
      const calculateHeight = !isMobile && width - width * 0.21 >= height;
      const element = documentWrapperRef.current;

      if (!element) return;

      const sizes = element.getBoundingClientRect();

      setDimensions(
        calculateHeight
          ? { height: Math.min(height - sizes.top - margin, height) }
          : { width: sizes.width }
      );
    },
    100,
    [width, height, isMobile, numPages]
  );

  useEffect(() => {
    const fetch = async () => {
      const response = await getOutgoingInvoice({
        ...values,
        multipage_document: multipageDocument,
      });

      setFile(response.data);
      setIsLoading(false);
    };

    setIsLoading(true);
    fetch();
  }, [values, multipageDocument]);

  return (
    <Wrapper>
      <Tools>
        <ControlLabel
          label={t('features.settings.document_template.preview.show_more_pages')}
          control={<Checkbox />}
          checked={multipageDocument}
          onChange={handleCheckboxChange}
          disabled={isLoading}
        />
      </Tools>
      <DocumentWrapper ref={documentWrapperRef}>
        {file && (
          <>
            <Document file={file} loading="" onLoadSuccess={handleLoadSuccess}>
              {numPages !== undefined &&
                Array.from(new Array(numPages), (_el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    loading=""
                    onRenderSuccess={handleRenderSuccess}
                    {...dimensions}
                  >
                    {index === 0 && (
                      <>
                        <ShowPreview onClick={handleShowPreview}>
                          <IconSearch />
                        </ShowPreview>
                        <Download
                          onClick={handleDownload()}
                          disabled={isDownloading}
                          title={t('features.settings.document_template.preview.download_preview')}
                        >
                          <IconDownload />
                        </Download>
                      </>
                    )}
                  </Page>
                ))}
            </Document>
            {showPreview && (
              <PdfFullscreenPreview
                file={file}
                onClose={handleClosePreview}
                onDownload={handleDownload(true)}
                title={t('features.settings.document_template.preview.fullscreen_preview_title')}
              />
            )}
          </>
        )}
        {isLoading && <Loading />}
      </DocumentWrapper>
    </Wrapper>
  );
};

export default memo(Preview, isEqual);
