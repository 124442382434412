import React from 'react';

import { t } from 'shared/utils';
import Section from 'components/Form/Section/Section';

import ProfileSection from '../ProfileSection/ProfileSection';
import ComponentWithTooltip, {
  DataShape as ComponentWithTooltipDataShape,
} from './ComponentWithTooltip/ComponentWithTooltip';
import Form from './NumberRangesForm/NumberRangesForm';
import TableOfExamples, {
  DataShape as TableOfExamplesDataShape,
} from './TableOfExamples/TableOfExamples';

import styles from './NumberRangesSection.module.css';

const arrayOfCounterExamples = t('profile.number_ranges.components_for_counter', {
  returnObjects: true,
}) as unknown as ComponentWithTooltipDataShape[];
const arrayOfDateExamples = t('profile.number_ranges.components_for_date', {
  returnObjects: true,
}) as unknown as ComponentWithTooltipDataShape[];

const examplesTableHeaderColumns = {
  expression: t('profile.number_ranges.examples_table.columns.expression'),
  result: t('profile.number_ranges.examples_table.columns.result'),
  annotation: t('profile.number_ranges.examples_table.columns.annotation'),
};
const examplesTableData = t('profile.number_ranges.examples_table.data', {
  returnObjects: true,
}) as unknown as TableOfExamplesDataShape[];

type NumberRangesSectionProps = {
  heading: string;
  subheading?: string;
  description?: string;
};

const NumberRangesSection = ({ heading, subheading, description }: NumberRangesSectionProps) => (
  <ProfileSection heading={heading} subheading={subheading} description={description}>
    <div className={styles.main}>
      <div className={styles.row}>
        <div className={styles.colHalf}>
          <Section
            title={t('profile.number_ranges.components_for_date_header')}
            sectionTitleTheme={styles.sectionTitle}
          >
            <ComponentWithTooltip data={arrayOfDateExamples} />
          </Section>
        </div>
        <div className={styles.colHalf}>
          <Section
            label={t('profile.number_ranges.components_for_counter_header_tooltip')}
            title={t('profile.number_ranges.components_for_counter_header')}
            sectionTitleTheme={styles.sectionTitle}
          >
            <ComponentWithTooltip data={arrayOfCounterExamples} />
          </Section>
        </div>
      </div>
      <Form />
      <Section className={styles.tableSection}>
        <span className={styles.title}>
          {t('profile.number_ranges.examples_table.section_title')}
        </span>
        <TableOfExamples header={examplesTableHeaderColumns} data={examplesTableData} />
      </Section>
    </div>
  </ProfileSection>
);

export default NumberRangesSection;
