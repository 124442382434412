import React from 'react';

import RegistrationForm from 'containers/RegistrationForm';
import { t } from 'shared/utils';

import LoginFooter from '../shared/LoginFooter/LoginFooter';
import Page from '../shared/Page/Page';

type RegistrationCardProps = {
  initialValues: any;
  disclaimerTitle: string;
  isVrso: boolean;
};

const RegistrationCard = ({ initialValues, disclaimerTitle, isVrso }: RegistrationCardProps) => {
  const header = isVrso ? 'registration.vrso_header' : 'registration.header';

  return (
    <Page header={t(header)}>
      <RegistrationForm
        initialValues={{ ...initialValues }}
        disclaimerTitle={disclaimerTitle}
        isVrso={isVrso}
      />
      <LoginFooter />
    </Page>
  );
};

export default RegistrationCard;
