import styled, { css } from 'styled-components';

import { Breakpoints } from 'redesign/styles/breakpoints';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 158px);
  grid-column-gap: ${({ theme }) => theme.space['4']};
  overflow-x: auto;
  padding: 4px 4px 0;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${Breakpoints.sm}) {
    grid-column-gap: ${({ theme }) => theme.space['8']};
  }
`;

type PresetProps = {
  $isActive?: boolean;
};

export const Preset = styled.div<PresetProps>`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.space['2']};
  text-align: center;
  cursor: pointer;

  ${({ $isActive }) =>
    $isActive &&
    css`
      cursor: auto;
      font-weight: bold;

      ${Preview}::before {
        box-shadow: 0 2px 4px 0 rgba(15, 35, 100, 0.4);
        border: 4px solid ${({ theme }) => theme.colors.vrblue['100']};
        top: -4px;
        right: -4px;
        bottom: -4px;
        left: -4px;
      }
    `};
`;

export const Preview = styled.div`
  position: relative;
  min-height: 220px;

  &::before {
    content: '';
    position: absolute;
    border: 1px solid #58595a;
    border-radius: 3px;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
  }
`;
