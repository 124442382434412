import React, { memo, ReactNode } from 'react';
import InlineSvg from 'react-inlinesvg';

import WarningSignImage from 'images/warning-sign.svg';

import styles from './Alert.module.css';

type AlertProps = {
  children: ReactNode;
  dataId: string;
};

const Alert = ({ children, dataId }: AlertProps) => (
  <div className={styles.alert} data-id={dataId}>
    <div className={styles.iconContainer}>
      <InlineSvg src={WarningSignImage} />
    </div>
    <div className={styles.content}>{children}</div>
  </div>
);

export default memo(Alert);
