import React from 'react';
import cx from 'classnames';
import { arrayOf, bool, node, string } from 'prop-types';

import styles from './ActionPanel.module.css';

const ActionPanel = ({ reverse = false, wrapped = false, className = '', children, ...rest }) => (
  <div
    className={cx(styles.root, className, {
      [styles.reverse]: reverse,
      [styles.wrapped]: wrapped,
    })}
    {...rest}
  >
    {children}
  </div>
);

ActionPanel.propTypes = {
  reverse: bool,
  wrapped: bool,
  className: string,
  children: arrayOf(node),
};

export default ActionPanel;
