import React from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { func } from 'prop-types';

import { clickCreateProductCatalogItem as clickCreateProductCatalogItemAction } from 'actions/product-catalog/items/piwik';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { Body, Header } from 'components/Card';

import Filters from './common/Filters/Filters';
import ProductCatalogItemsTable, { NewProductCatalogItemButton } from './common/Table';

import localStyles from '../ProductCatalog.module.css';

export const translatedIntervalName = (interval) =>
  t(`recurring_expenses.interval_options.${interval.name}`);

const ProductCatalogItems = ({ clickCreateProductCatalogItem, push }) => {
  const createEntity = () => {
    push(paths.newProductCatalogItem);
    clickCreateProductCatalogItem();
  };

  return (
    <div className={localStyles.card}>
      <Header className={localStyles.header}>
        <div className={localStyles.headerTitle}>
          {t('product_catalog.product_catalog_items.header.index')}
          <NewProductCatalogItemButton onClick={createEntity} />
        </div>
        <div className={localStyles.headerFilters}>
          <Filters />
        </div>
      </Header>
      <Body dataId="ProductCatalog:table-container" withMargin>
        <ProductCatalogItemsTable />
      </Body>
    </div>
  );
};

ProductCatalogItems.propTypes = {
  clickCreateProductCatalogItem: func.isRequired,
  push: func.isRequired,
};

const mapDispatchToProps = {
  push: pushAction,
  clickCreateProductCatalogItem: clickCreateProductCatalogItemAction,
};

export default connect(null, mapDispatchToProps)(ProductCatalogItems);
