import { push } from 'connected-react-router';
import { get } from 'lodash';
import { isDirty } from 'redux-form';

import { fetchCompany } from 'actions/company';
import {
  clearDefaultValues as clearDefaultValuesAction,
  fetchClientDefaults as fetchClientDefaultsAction,
} from 'actions/default-values';
import {
  abortChanges,
  downloadOrderConfirmation,
  transformOrderConfirmation,
  updateOrderConfirmation,
} from 'actions/order-confirmation';
import { FORM_NAME, FROM_ORDER_CONFIRMATION_QUERY_PARAM } from 'constants/order-confirmation';
import { orderConfirmationSelector } from 'reducers/form';
import paths from 'routes/paths';
import { getOrderConfirmationInitialValues } from 'shared/utils/order-confirmation-initial-values';

import { isRedirectFromProposals } from './OrderConfirmationCreator';

export const mapStateToProps = (state, { crudMode }) => {
  return {
    orderConfirmationDetails: state.orderConfirmation.details,
    lineCategories: state.orderConfirmation.lineCategories,
    orderConfirmationLineItems: state.orderConfirmation.lineItems,
    currentValues: state.form.orderConfirmationCreator
      ? state.form.orderConfirmationCreator.values
      : {},
    isSubjectDirty: isDirty(FORM_NAME)(state, 'subject'),
    isDirty: isDirty(FORM_NAME)(state),
    initialValues: getOrderConfirmationInitialValues(state, crudMode),
    orderConfirmation: state.orderConfirmation,
    canTransform: get(state, 'orderConfirmation.meta.actions.transform', false),
    canUpdate: get(state, 'orderConfirmation.meta.actions.update', false),
    currentClient: orderConfirmationSelector(state, 'client'),
    individualContact: !!orderConfirmationSelector(state, 'salutationContent'),
    salutationHonorific: orderConfirmationSelector(state, 'salutationHonorific'),
    deliveryDate: orderConfirmationSelector(state, 'deliveryDate'),
    sendingDocumentsByEmailEnabled: state.profile.applicationFeatures.sendingDocumentsByEmail,
    isProfileFilled: state.onboarding.data?.profileFilled,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchCompanyInfo: (...args) => dispatch(fetchCompany(...args)),
  save:
    (id, isNew = false) =>
    (values) => {
      const data = {
        ...values,
        isNew,
        draft: false,
        clientId: values.client?.id,
      };

      delete data.client;

      return dispatch(updateOrderConfirmation(id, data));
    },
  saveAsDraft:
    (id, isNew = false) =>
    (values) => {
      const data = {
        ...values,
        isNew,
        draft: true,
        clientId: values.client?.id,
      };

      delete data.client;

      return dispatch(updateOrderConfirmation(id, data));
    },
  transform: (itemid) => () =>
    dispatch(transformOrderConfirmation({ id: itemid })).then(({ data: { id } }) => {
      dispatch(
        push({
          pathname: paths.editOutgoingInvoice(id),
          search: `?${FROM_ORDER_CONFIRMATION_QUERY_PARAM}`,
        })
      );
    }),
  saveAndTransform:
    (id, isNew = false) =>
    (values) => {
      const data = {
        ...values,
        isNew,
        draft: false,
        clientId: values.client?.id,
      };

      delete data.client;

      return dispatch(updateOrderConfirmation(id, data, true));
    },
  saveWithoutRedirect:
    (id, isNew = false) =>
    (values) => {
      const data = {
        ...values,
        isNew,
        draft: false,
        clientId: values.client?.id,
      };

      delete data.client;

      return dispatch(updateOrderConfirmation(id, data, false, false));
    },
  download: (values) => (details) => (additionalData, isPiwikTracked, isCancelMode) => {
    const enhancedDetails = { ...details, ...additionalData };

    dispatch(downloadOrderConfirmation(enhancedDetails, values, isPiwikTracked, isCancelMode));
  },
  abort: () => {
    if (isRedirectFromProposals(ownProps.location)) {
      return dispatch(abortChanges(paths.proposals));
    }

    return dispatch(abortChanges());
  },
  clearDefaultValues: () => dispatch(clearDefaultValuesAction()),
  fetchClientDefaults: (clientId) => dispatch(fetchClientDefaultsAction(clientId)),
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  download: dispatchProps.download(stateProps.values),
});
