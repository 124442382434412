import React from 'react';
import { bool, func } from 'prop-types';

import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import Modal from 'components/Modal';

import styles from './UnsavedInvoiceModal.module.css';

const UnsavedInvoiceModal = ({ isOpen, onAccept, onClose }) => (
  <Modal isOpen={isOpen} onRequestClose={onClose}>
    <div className={styles.header}>
      <span className={styles.text}>{t('expenses.payments.unsaved_invoice_modal.header')}</span>
      <button className={styles.cancel} onClick={onClose} />
    </div>
    <div className={styles.modalBody}>
      <span>{t('expenses.payments.unsaved_invoice_modal.body')}</span>
      <div className={styles.footerSection}>
        <ActionButton
          appearance="outlined"
          onClick={onClose}
          label={t('expenses.payments.unsaved_invoice_modal.abort')}
        />
        <ActionButton
          appearance="primary"
          onClick={onAccept}
          label={t('expenses.payments.unsaved_invoice_modal.ok')}
        />
      </div>
    </div>
  </Modal>
);

UnsavedInvoiceModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onAccept: func.isRequired,
};

export default UnsavedInvoiceModal;
