import React from 'react';

const OutgoingInvoice = ({ dataId = '', className = '' }) => (
  <svg width={290} height={157} data-id={dataId} className={className}>
    <defs>
      <filter
        x="-9.7%"
        y="-7%"
        width="119.3%"
        height="119.7%"
        filterUnits="objectBoundingBox"
        id="prefix__a"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-29.7%"
        y="-19%"
        width="159.4%"
        height="145%"
        filterUnits="objectBoundingBox"
        id="prefix__c"
      >
        <feOffset dy={4} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={8} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <ellipse id="prefix__b" cx={36.219} cy={35.5} rx={36.219} ry={35.5} />
      <path id="prefix__d" d="M.63.09h87.615v115.493H.63z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M97 47c28.697 9.811-2.983-10.73 19-20s64.654 11 85 11 36.971-16.555 54.6-2.847C273.228 48.861 305.789 40.468 281 76c-24.789 35.532-3.026 19.174-44.4 54.153C195.226 165.132 144 127 116 120s-50.373 2.687-77-11c-26.627-13.687-28.654-5.924-37.256-18.09C-6.86 78.742 18.864 55.84 24.6 42.152 30.335 28.466 68.303 37.189 97 47z"
        fill="#F3F3F3"
      />
      <path
        d="M272.296 15a.68.68 0 01.566.298.664.664 0 01.138.424.669.669 0 01-.143.429l-12.072 24.453a.72.72 0 01-1.094.237l-7.74-6.23-5.885 5.205c-.403.356-1.038.166-1.182-.316l-.021-.1-1.39-9.743-6.894-4.785a.712.712 0 01.14-1.247l.101-.032 35.254-8.563a.675.675 0 01.13-.026h.01l.02-.003.046-.001zm-2.323 3.776L253.375 33.94l6.52 5.25 10.078-20.415zm-2.08-.147l-22.955 11.09 1.167 8.177 21.787-19.267zm-2.087-.606l-27.1 6.583 5.462 3.79 21.638-10.373z"
        fill="#F60"
        fillRule="nonzero"
      />
      <path
        d="M102.62 66c17.003-26.667 36.388-34 58.153-22 32.648 18 38.77 50 19.385 61s-52.032-30 64.275-75"
        stroke="#F60"
        strokeDasharray="4,4"
      />
      <g transform="translate(174 80)">
        <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
        <use fill="#FFF" xlinkHref="#prefix__b" />
      </g>
      <path
        stroke="#0066B3"
        strokeWidth={2}
        strokeLinejoin="round"
        d="M193.385 104h31.99v24.194h-31.99z"
      />
      <path
        stroke="#0066B3"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M225.374 104l-15.995 14.977L193.385 104"
      />
      <path
        fill="#F60"
        fillRule="nonzero"
        d="M230.113 127.622l-7.913-6.34v4.427h-7.489v4.372h7.489v3.873z"
      />
      <path
        stroke="#F60"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M230.113 127.622l-7.913-6.34v4.427h-7.489v4.372h7.489v3.873z"
      />
      <g transform="translate(27 12)">
        <use fill="#000" filter="url(#prefix__c)" xlinkHref="#prefix__d" />
        <use fill="#FFF" xlinkHref="#prefix__d" />
      </g>
      <path
        d="M40.948 36.01h21.739c.757 0 1.37-.6 1.37-1.34 0-.74-.613-1.34-1.37-1.34h-21.74c-.756 0-1.37.6-1.37 1.34 0 .74.614 1.34 1.37 1.34zm-.004 29.48l57.545.168c.756.003 1.372-.596 1.374-1.336.002-.74-.61-1.341-1.366-1.344l-57.545-.167c-.757-.002-1.372.596-1.375 1.336-.002.74.61 1.342 1.367 1.344zm.403-24.12H59.73c.756 0 1.37-.6 1.37-1.34 0-.74-.614-1.34-1.37-1.34H41.347c-.756 0-1.37.6-1.37 1.34 0 .74.614 1.34 1.37 1.34zm-.005 29.481l43.444.168c.757.002 1.373-.595 1.376-1.335.003-.74-.608-1.343-1.365-1.345l-43.444-.168c-.757-.003-1.373.595-1.376 1.335-.003.74.608 1.342 1.365 1.345zm-.394-24.12h21.739c.757 0 1.37-.6 1.37-1.34 0-.74-.613-1.34-1.37-1.34h-21.74c-.756 0-1.37.6-1.37 1.34 0 .74.614 1.34 1.37 1.34zm-.004 29.48l60.285.168c.757.002 1.372-.596 1.374-1.337.002-.74-.61-1.341-1.366-1.343l-60.285-.168c-.757-.002-1.372.596-1.374 1.336-.003.74.609 1.342 1.366 1.344zm.689 34.686h15.071c.378 0 .685-.378.685-.844 0-.466-.307-.843-.685-.843H41.633c-.379 0-.685.377-.685.843 0 .466.306.844.685.844zm26.717 0h15.071c.379 0 .685-.378.685-.844 0-.466-.306-.843-.685-.843H68.35c-.378 0-.685.377-.685.843 0 .466.307.844.685.844zm-26.717 4.02h10.96c.379 0 .686-.377.686-.843s-.307-.844-.685-.844H41.633c-.379 0-.685.378-.685.844s.306.843.685.843zm26.717 0h10.961c.378 0 .685-.377.685-.843s-.307-.844-.685-.844H68.35c-.378 0-.685.378-.685.844s.307.843.685.843zm-26.717 4.02h15.071c.378 0 .685-.377.685-.843 0-.466-.307-.844-.685-.844H41.633c-.379 0-.685.378-.685.844 0 .466.306.843.685.843zm26.717 0h15.071c.379 0 .685-.377.685-.843 0-.466-.306-.844-.685-.844H68.35c-.378 0-.685.378-.685.844 0 .466.307.843.685.843z"
        fill="#979797"
        fillRule="nonzero"
      />
      <g fill="#2382C8">
        <path d="M102.232 90.485H91.247c-.674 0-1.225-.56-1.225-1.256 0-.695.55-1.258 1.225-1.258h10.985c.677 0 1.224.563 1.224 1.258 0 .695-.548 1.256-1.225 1.256m.001 3.735H91.247c-.674 0-1.225-.563-1.225-1.258 0-.691.55-1.255 1.225-1.255h10.985c.677 0 1.224.56 1.224 1.257 0 .697-.548 1.26-1.225 1.26" />
        <path d="M100.884 99.572c-4.815 0-8.733-3.84-8.733-8.56 0-4.722 3.918-8.565 8.733-8.565a8.86 8.86 0 013.34.653c.637.26.94.978.675 1.603-.261.62-.99.917-1.627.657a6.35 6.35 0 00-2.386-.465c-3.44 0-6.237 2.744-6.237 6.117 0 3.373 2.795 6.115 6.237 6.115.822 0 1.626-.155 2.386-.465a1.256 1.256 0 011.629.664 1.21 1.21 0 01-.674 1.598 8.816 8.816 0 01-3.34.653" />
      </g>
    </g>
  </svg>
);

export default OutgoingInvoice;
