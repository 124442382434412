export const NAMESPACE = 'ORDER_CONFIRMATIONS';
export const INDEX_REQUEST = `${NAMESPACE}/INDEX_REQUEST`;
export const INDEX_SUCCESS = `${NAMESPACE}/INDEX_SUCCESS`;
export const INDEX_FAILURE = `${NAMESPACE}/INDEX_FAILURE`;
export const INDEX_SORT = `${NAMESPACE}/INDEX_SORT`;

export const FETCH_REQUEST_SUB_ORDER_CONFIRMATIONS =
  'ORDER_CONFIRMATIONS/FETCH_REQUEST_SUB_ORDER_CONFIRMATIONS';
export const FETCH_SUCCESS_SUB_ORDER_CONFIRMATIONS =
  'ORDER_CONFIRMATIONS/FETCH_SUCCESS_SUB_ORDER_CONFIRMATIONS';
export const FETCH_FAILURE_SUB_ORDER_CONFIRMATIONS =
  'ORDER_CONFIRMATIONS/FETCH_FAILURE_SUB_ORDER_CONFIRMATIONS';
