import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

import { toggleCustomTask } from 'actions/customer-notifications';
import { DATE_FORMAT_UNICODE } from 'constants/datetime';
import { CustomTask } from 'types/entities/Task';
import RawCheckbox from 'components/Form/RawCheckbox/RawCheckbox';
import IconEdit from 'components/Icons/IconEdit';

import { CustomTaskCreatorContext } from '../CustomTaskCreator/CustomTaskCreator';
import { trackEvent } from '../utils';

import styles from './CustomTask.module.css';

type TodoTaskProps = {
  task: CustomTask;
};

const TodoTask = ({ task }: TodoTaskProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const customTaskContext = useContext(CustomTaskCreatorContext);

  const handleCheckboxClick = async () => {
    try {
      setIsLoading(true);
      await dispatch(toggleCustomTask(task));
      setIsLoading(false);
      trackEvent('New_Task_completed');
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleEditClick = (task: CustomTask) => {
    if (!customTaskContext.setEditedTask || !customTaskContext.open) return;

    customTaskContext.setEditedTask(task);
    customTaskContext.open();
    trackEvent('New_Task_edit');
  };

  return (
    <div className={styles.task}>
      <div className={styles.checkbox}>
        <RawCheckbox
          value="false"
          checked={false}
          onClick={handleCheckboxClick}
          disabled={isLoading}
          dataId="checkbox"
        />
      </div>
      <div className={styles.description}>{task.description}</div>
      <div className={styles.dueDate}>
        {task.dueDate && format(new Date(task.dueDate), DATE_FORMAT_UNICODE)}
      </div>
      <div className={styles.iconContainer}>
        <button onClick={() => handleEditClick(task)}>
          <IconEdit className={styles.icon} data-id="edit-task" />
        </button>
      </div>
    </div>
  );
};

export default TodoTask;
