import React from 'react';
import { connect } from 'react-redux';
import { fill, keys, mapValues, toArray } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { EXPENSES, REVENUES, SUM } from 'constants/tables';
import { formatMoney, t } from 'shared/utils';
import I18n from 'components/I18n';

import styles from './MonthlyOverviewTable.module.css';

const months = t('dashboard.revenue_cost.months_abrv', { returnObjects: true });

const getMoneyBasedOnType =
  (type = SUM) =>
  (v, key) => {
    switch (type) {
      case SUM:
        return v.expenses[key] + v.revenues[key];
      case REVENUES:
        return v.revenues[key];
      case EXPENSES:
        return v.expenses[key];
      default:
        return null;
    }
  };

const transformData = (data, monthsRange = 12, type) => {
  const getMoney = getMoneyBasedOnType(type);
  const object = {
    months: [],
    sums: [],
    paid: [],
    unpaid: [],
  };

  const order = toArray(keys(data)).sort().slice(-monthsRange);

  order.forEach((id) => {
    const v = data[id].data.attributes;
    object.months.push(<td>{months[moment(id, 'YYYY-MM').month()]}</td>);
    object.sums.push(<td> {formatMoney(getMoney(v, 'sum'))} </td>);
    object.paid.push(<td> {formatMoney(getMoney(v, 'paid'))} </td>);
    object.unpaid.push(<td> {formatMoney(getMoney(v, 'unpaid'))} </td>);
  });
  const lastMonth = moment(order.slice(-1).pop(), 'YYYY-MM').month();
  fill(Array(monthsRange - order.length), {}).forEach((_obj, id) => {
    const qwe = months[lastMonth + id + 1];
    object.months.push(<td> {qwe} </td>);
    object.sums.push(<td />);
    object.paid.push(<td />);
    object.unpaid.push(<td />);
  });

  return mapValues(object, (_v, k, obj) => obj[k]);
};

const Table = ({ data, monthsRange = 12, type = SUM }) => {
  const parsedData = transformData(data, monthsRange, type);
  return (
    <div className={styles.main}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Monat</th>
            {data && parsedData.months}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              <I18n t="dashboard.revenue_cost.sum" />
            </th>
            {data && parsedData.sums}
          </tr>
          <tr>
            <th>
              <I18n t="dashboard.revenue_cost.sum_unpaid" />
            </th>
            {data && parsedData.unpaid}
          </tr>
          <tr>
            <th>
              <I18n t="dashboard.revenue_cost.sum_paid" />
            </th>
            {data && parsedData.paid}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.shape({}).isRequired,
  monthsRange: PropTypes.number,
  type: PropTypes.string,
};

export default connect((s) => ({ data: s.monthlyOverview.revenueExpenses }))(Table);
