import { spawn } from 'redux-saga/effects';

import customerNotificationsSaga from './watchCustomerNotificationsTriggers';
import dashboardSaga from './watchDashboardDataChange';
import notificationsSidebarSaga from './watchNotificationsSidebar';
import watchRehydrateSaga from './watchRehydrate';

/*
 * Spawn/fork is about error propagation (in case of root saga), since we do not have policy
 * to handle these on a top level, we are using spawn, so that errors won't bubble up.
 */
export default function* rootSaga() {
  yield [
    dashboardSaga,
    customerNotificationsSaga,
    notificationsSidebarSaga,
    watchRehydrateSaga,
  ].map((saga) => spawn(saga));
}
