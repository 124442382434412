import styled from 'styled-components';

type RadioProps = {
  disabled?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FirstAdress = styled.div`
  display: flex;
  border-width: 1px;
  flex: 1;
  border-color: black;
`;

export const Label = styled.span`
  padding-left: 15px;
`;

export const Radio = styled.label<RadioProps>`
  padding-bottom: 15px;
  color: ${(props) => (props.disabled ? 'darkgrey' : '#5f656b')};
  font-size: 16px;
  line-height: 17px;
  font-weight: normal;
`;

export const SecondADress = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-width: 1px;
  border-color: black;
  align-items: flex-start;
`;

export const AddAdressLink = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 14px;
`;
export const Details = styled.div`
  padding-left: 15px;
`;

export const Detail = styled.div`
  padding-bottom: 5px;
`;

export const AddAddressButton = styled.button`
  background-color: #fff;
  color: #0066b3;
  font-size: 14px;
  font-weight: bold;
`;
