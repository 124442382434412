import { push } from 'connected-react-router';
import { get } from 'lodash';
import { isDirty } from 'redux-form';

import { fetchCompany } from 'actions/company';
import {
  clearDefaultValues as clearDefaultValuesAction,
  fetchClientDefaults as fetchClientDefaultsAction,
} from 'actions/default-values';
import {
  abortChanges,
  downloadProposal,
  transformProposalIntoOrderConfirmation,
  transformProposalIntoOutgoingInvoice,
  updateProposal,
} from 'actions/proposal';
import { NEW_MODE } from 'constants/common/crud';
import { FORM_NAME, FROM_PROPOSAL_QUERY_PARAM } from 'constants/proposal';
import { proposalSelector } from 'reducers/form';
import paths from 'routes/paths';
import { getProposalInitialValues } from 'shared/utils/proposal-initial-values';

export const mapStateToProps = (state, { crudMode, isFromDuplicate }) => {
  const isNewMode = crudMode === NEW_MODE;
  return {
    proposalDetails: state.proposal.details,
    currentValues: state.form.proposalCreator ? state.form.proposalCreator.values : {},
    isSubjectDirty: isDirty(FORM_NAME)(state, 'subject'),
    isDirty: isDirty(FORM_NAME)(state),
    initialValues: getProposalInitialValues(state, isNewMode, isFromDuplicate),
    proposal: state.proposal,

    canTransformIntoOutgoingInvoice: get(
      state,
      'proposal.meta.actions.transform_into_outgoing_invoice',
      false
    ),
    canTransformIntoOrderConfirmation: get(
      state,
      'proposal.meta.actions.transform_into_order_confirmation',
      false
    ),
    canUpdate: get(state, 'proposal.meta.actions.update', false),
    currentClient: proposalSelector(state, 'client'),
    individualContact: !!proposalSelector(state, 'salutationContent')
      ? !!proposalSelector(state, 'salutationContent')
      : ' ',
    salutationHonorific: proposalSelector(state, 'salutationHonorific'),

    sendingDocumentsByEmailEnabled: state.profile.applicationFeatures.sendingDocumentsByEmail,
    isProfileFilled: state.onboarding.data?.profileFilled,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchCompanyInfo: (...args) => dispatch(fetchCompany(...args)),
  save:
    (id, isNew = false) =>
    (values) => {
      const data = {
        ...values,
        isNew,
        draft: false,
        clientId: values.client?.id,
      };

      delete data.client;

      return dispatch(updateProposal(id, data));
    },
  saveAsDraft:
    (id, isNew = false) =>
    (values) => {
      const data = {
        ...values,
        isNew,
        draft: true,
        clientId: values.client?.id,
      };
      delete data.client;

      return dispatch(updateProposal(id, data, false));
    },
  transformIntoOutgoingInvoice: (itemid) => () =>
    dispatch(transformProposalIntoOutgoingInvoice({ id: itemid })).then(({ data: { id } }) => {
      dispatch(
        push({
          pathname: paths.editOutgoingInvoice(id),
          search: `?${FROM_PROPOSAL_QUERY_PARAM}`,
        })
      );
    }),
  transformIntoOrderConfirmation: (itemid) => () =>
    dispatch(transformProposalIntoOrderConfirmation({ id: itemid })).then(({ data: { id } }) => {
      dispatch(
        push({
          pathname: paths.editOrderConfirmation(id),
          search: `?${FROM_PROPOSAL_QUERY_PARAM}`,
        })
      );
    }),
  saveAndTransformIntoOutgoingInvoice:
    (id, isNew = false) =>
    (values) => {
      const data = {
        ...values,
        isNew,
        draft: false,
        clientId: values.client?.id,
      };

      delete data.client;

      return dispatch(updateProposal(id, data, 'outgoingInvoice'));
    },
  saveAndTransformIntoOrderConfirmation:
    (id, isNew = false) =>
    (values) => {
      const data = {
        ...values,
        isNew,
        draft: false,
        clientId: values.client?.id,
      };

      delete data.client;

      return dispatch(updateProposal(id, data, 'orderConfirmation'));
    },
  saveWithoutRedirect:
    (id, isNew = false) =>
    (values) => {
      const data = {
        ...values,
        isNew,
        draft: false,
        clientId: values.client?.id,
      };

      delete data.client;

      return dispatch(updateProposal(id, data, false, false));
    },
  download:
    (values) =>
    (details) =>
    (additionalData = {}) => {
      const enhancedDetails = { ...details, ...additionalData };
      dispatch(downloadProposal(enhancedDetails, values));
    },
  abort: () => dispatch(abortChanges()),
  clearDefaultValues: () => dispatch(clearDefaultValuesAction()),
  fetchClientDefaults: (clientId) => dispatch(fetchClientDefaultsAction(clientId)),
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  download: dispatchProps.download(stateProps.values),
});
