import React, { SVGAttributes } from 'react';

const Icon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 108 93" {...props}>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="91.118%" id="prefix__a">
        <stop stopColor="#E80000" offset="0%" />
        <stop stopColor="#D80000" stopOpacity={0.431} offset="33.987%" />
        <stop stopColor="#C00" stopOpacity={0} offset="100%" />
      </linearGradient>
      <filter
        x="-16%"
        y="-20%"
        width="132%"
        height="140%"
        filterUnits="objectBoundingBox"
        id="prefix__b"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.245669158 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path id="prefix__c" d="M0 0h37.5v26h-4.217l-4.133 4-4.066-4H0z" />
    </defs>
    <g transform="translate(1 4)" fill="none" fillRule="evenodd">
      <path fillOpacity={0.2} fill="url(#prefix__a)" d="M1 61.5h104.5v23H1z" />
      <path
        d="M24 61.5l10.136-23.71L51.87 35.07 61.5 46l2 10 12.164 1.89 13.837-13.798L105.5 31.5"
        stroke="#1EA532"
        opacity={0.4}
      />
      <ellipse fill="#1EA532" cx={76.459} cy={58} rx={1.959} ry={2} />
      <path stroke="#333" strokeWidth={0.5} d="M52.75 5.75l-.25 82.875" />
      <ellipse fill="#1EA532" cx={63.459} cy={56} rx={1.959} ry={2} />
      <ellipse fill="#1EA532" cx={61.459} cy={46} rx={1.959} ry={2} />
      <ellipse fill="#1EA532" cx={34.459} cy={38} rx={1.959} ry={2} />
      <ellipse fill="#73AF9B" cx={52.454} cy={35} rx={3.454} ry={3.5} />
      <g transform="translate(24)">
        <use fill="#000" filter="url(#prefix__b)" xlinkHref="#prefix__c" />
        <use fill="#FFF" xlinkHref="#prefix__c" />
      </g>
      <path
        d="M56.974 20.072c.072 0 .178-.005.318-.015.14-.01.306-.045.498-.105l-.036-.672-.099.039a.77.77 0 01-.099.027 2.324 2.324 0 01-.528.066 1.262 1.262 0 01-.918-.378 1.155 1.155 0 01-.156-.204.995.995 0 01-.09-.198l-.042-.126h1.476l.084-.366h-1.656a1.476 1.476 0 010-.468h1.764l.09-.366h-1.782a.204.204 0 00.021-.051l.039-.108a1.245 1.245 0 01.138-.261c.092-.136.225-.256.399-.36.174-.104.393-.156.657-.156.168 0 .308.018.42.054.112.036.206.07.282.102l.156-.624a7.184 7.184 0 00-.363-.108 2.146 2.146 0 00-.555-.06c-.032 0-.094.002-.186.006s-.19.014-.294.03a2.385 2.385 0 00-.612.186 1.642 1.642 0 00-.576.462 1.75 1.75 0 00-.195.282 2.061 2.061 0 00-.219.606h-.51l-.09.366h.54a3.725 3.725 0 00-.012.234v.062l.003.055.009.117h-.45l-.09.366h.594c.004.012.011.041.021.087.01.046.024.099.042.159.018.06.039.124.063.192s.054.134.09.198c.024.044.066.11.126.198a1.5 1.5 0 00.276.288c.152.128.343.234.573.318.23.084.523.126.879.126z"
        fill="#1A812B"
        fillRule="nonzero"
      />
      <path stroke="#198228" strokeWidth={2} strokeLinecap="round" d="M28 17.5h23" />
      <path stroke="#B0B0B0" strokeWidth={2} strokeLinecap="round" d="M28 9.5h31" />
      <ellipse fill="#1EA532" cx={89.459} cy={44} rx={1.959} ry={2} />
      <ellipse fill="#1EA532" cx={104.459} cy={32} rx={1.959} ry={2} />
      <path stroke="#AA0028" d="M19.5 71h-8L9 61.5" />
      <path stroke="#C00" d="M19.5 71l4.5-9.5" />
      <ellipse fill="#C00" cx={11.959} cy={70} rx={1.959} ry={2} />
      <ellipse fill="#C00" cx={19.959} cy={71} rx={1.959} ry={2} />
      <path stroke="#A1D7A9" d="M9 61.5l-9-10" />
      <path stroke="#96001E" strokeWidth={0.5} d="M1 61.465l104.25-.215" />
      <path
        d="M55.144 88.5v-1.667h1.496V88.5h.786v-3.883h-.786v1.551h-1.496v-1.551h-.792V88.5h.792zm5.313 0v-.654h-1.524v-1.013h1.381v-.637h-1.38v-.925h1.507v-.654h-2.305V88.5h2.321zm2.079.06c.128 0 .254-.007.377-.021a1.544 1.544 0 00.608-.207 1.214 1.214 0 00.49-.58c.054-.147.087-.299.098-.457a1.6 1.6 0 00.011-.186v-2.492h-.748v2.26a.667.667 0 01-.005.083c0 .062-.002.131-.006.206a.969.969 0 01-.137.448.708.708 0 01-.182.198.76.76 0 01-.462.138.815.815 0 01-.352-.058.82.82 0 01-.231-.145c-.062-.06-.11-.123-.146-.193s-.06-.148-.077-.234a2.119 2.119 0 01-.033-.286 6.816 6.816 0 01-.008-.355v-2.062h-.786v2.195l.01.302a2.175 2.175 0 00.121.677 1 1 0 00.237.368l.058.058a1.106 1.106 0 00.344.209c.091.035.185.062.28.082.095.02.19.034.283.041.094.008.179.011.256.011zm3.839-.06v-3.228h1.111v-.655h-3.009v.654h1.106V88.5h.792zm3.822 0v-.654h-1.523v-1.013h1.38v-.637h-1.38v-.925h1.507v-.654h-2.304V88.5h2.32z"
        fill="#787878"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default Icon;
