import { Moment } from 'moment';

import { DateRangePickerId } from 'store/slices/dateRangePicker/types';
import { DefaultRangePresets, TDatePreset } from 'redesign/components/molecules/DatePresets/types';

export enum DateRangePickerVersion {
  redesign = 'redesign',
  legacy = 'legacy',
}

export type TDateRange = {
  startDate: Moment | null;
  endDate: Moment | null;
};

export type TDateRangePickerProps = {
  period?: TDateRange;
  customDateRanges?: TDatePreset;
  presets: DefaultRangePresets[];
  variant: DateRangePickerVersion;
  label: string;
  setRangeQueryAction: any;
  startDateId: string;
  endDateId: string;
  onClose: (range: TDateRange) => void;
  isOutsideRange: (day: Moment) => boolean;
  id: DateRangePickerId;
};
