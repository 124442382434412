import React, { ComponentType, MemoExoticComponent, ReactElement, SVGProps } from 'react';

import { Circle } from './CircleIcon.styled';

export type CircleIconProps = {
  className?: string;
  icon:
    | ComponentType<SVGProps<SVGSVGElement>>
    | MemoExoticComponent<(props: SVGProps<SVGSVGElement>) => ReactElement>;
  color: 'red' | 'green';
  size?: 'medium';
};

const CircleIcon = ({ className, icon: Icon, size = 'medium', color }: CircleIconProps) => {
  return (
    <Circle className={className} $size={size} $color={color}>
      <Icon />
    </Circle>
  );
};

export default CircleIcon;
