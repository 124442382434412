import { CALL_API } from 'constants/api';
import { FETCH_SUMS_FAILURE, FETCH_SUMS_REQUEST, FETCH_SUMS_SUCCESS } from 'constants/proposal';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiFetchProposalSums = (invoiceId) => ({
  [CALL_API]: {
    endpoint: `/me/proposals/${invoiceId}`,
    types: [FETCH_SUMS_REQUEST, FETCH_SUMS_SUCCESS, FETCH_SUMS_FAILURE],
  },
});

export const fetchProposalSums =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchProposalSums(...args)).catch(apiErrorHandler);
