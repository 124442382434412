import styled from 'styled-components';

import Button from 'components/Button';

export const BankAccountField = styled.div`
  max-width: 400px;
`;

export const BankAccountContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.space['8']};
`;

export const BankManualDetailsInner = styled.div`
  margin-top: ${({ theme }) => theme.space['4']};
  display: inline-block;
`;

export const OpenModalButton = styled(Button)`
  padding: 0;
  margin-left: ${({ theme }) => theme.space['1']};
  display: block;
`;
