import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ConfirmationIcon from 'images/icon-confirmation.svg';
import WarningIcon from 'images/icon-exclamation-warning.svg';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import I18n from 'components/I18n';

import styles from './Notification.module.css';

const BankAccountNotification = ({ variant, hide, bankAccountNumber }) => (
  <div
    className={cx(styles.notification, styles[`border-${variant}`])}
    data-id="flash-notification"
  >
    <div className={cx(styles.imageWrapper, styles[variant])}>
      <img
        src={variant === 'warning' ? WarningIcon : ConfirmationIcon}
        alt={variant === 'warning' ? 'Warning icon' : 'Confirmation checked icon'}
      />
    </div>
    <div className={styles.section}>
      <h2 data-id="BankAccountNotification:header" className={styles.title}>
        {t('notifications.bank_account.out_of_sync.message', { bankAccountNumber })}
        <Link to={paths.bankAccounts} className={styles.link} dataId="BankAccountNotification:link">
          <I18n t="notifications.bank_account.out_of_sync.link" />
        </Link>
      </h2>
      <button onClick={hide} className={styles.closeButton} data-id="flash-notification-close" />
    </div>
  </div>
);

BankAccountNotification.propTypes = {
  hide: PropTypes.func.isRequired,
  bankAccountNumber: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

export default BankAccountNotification;
