import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm, reset } from 'redux-form';

import { fetchProfile, updateProfile } from 'actions/profile';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { RootState } from 'store';
import { Dispatch } from 'types/actions';
import ActionButton from 'components/ActionPanel/ActionButton';
import { SubmitButton } from 'components/Form';
import LoadingIcon from 'components/LoadingIcon';

import ChangeEmailModal from '../Personal/Form/ChangeEmailModal/ChangeEmailModal';
import ProfileSection from '../ProfileSection/ProfileSection';
import ChangePassword from './ChangePassword/ChangePassword';
import Form from './Form/Form';
import PiwikOptOut from './PiwikOptOut';
import ReceiveOffers from './ReceiveOffers/ReceiveOffers';

import styles from './Personal.module.css';

type PersonalProps = {
  submitHandler: () => void;
  abort: () => void;
  fetchProfile: () => void;
  isFetched: boolean;
  heading: string;
  subheading?: string;
  description?: string;
};

const Personal = (props: InjectedFormProps & PersonalProps) => {
  const {
    fetchProfile,
    submitting,
    handleSubmit,
    submitHandler,
    abort,
    isFetched,
    heading,
    subheading,
    description,
    initialize,
    initialized,
    initialValues,
  } = props;
  const [isChangeEmailModalVisible, setIsChangeEmailModalVisible] = useState(false);

  const handleChangeEmailModalVisibility = () =>
    setIsChangeEmailModalVisible((isVisible) => !isVisible);

  useEffect(() => {
    fetchProfile();
    initialized && initialize(initialValues);
  }, []);

  if (!isFetched) {
    return <LoadingIcon />;
  }

  return (
    <ProfileSection
      heading={heading}
      subheading={subheading}
      description={description}
      requiredMessage={`* ${t('shared.fields_required')}`}
    >
      <div className={styles.main}>
        <form onSubmit={handleSubmit(submitHandler)} data-id="formSection">
          <Form {...props} handleChangeEmailModalVisibility={handleChangeEmailModalVisibility} />
          <ChangePassword />
          <ReceiveOffers />
          <PiwikOptOut />

          <div className={styles.imprint}>{t('profile.sections.personal.imprint')}</div>
          <div data-id="section" className={styles.buttons}>
            <ActionButton
              appearance="outlined"
              onClick={abort}
              label={t('profile.personal.abort')}
              type="button"
              dataId="reset"
            />
            <SubmitButton
              dataId="submit"
              active={!submitting}
              value={t('profile.personal.submit')}
            />
          </div>
        </form>

        <ChangeEmailModal
          isOpen={isChangeEmailModalVisible}
          onClose={handleChangeEmailModalVisibility}
        />
      </div>
    </ProfileSection>
  );
};

const mapStateToProps: any = (s: RootState) => ({
  isFetched: s.profile.isFetched,
  initialValues: s.profile.credentials,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProfile: () => dispatch(fetchProfile()),
  submitHandler: (...args: any) => dispatch(updateProfile(...args)),
  abort: () => dispatch(reset('profile')),
});

export default compose(
  connect<PersonalProps>(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'profile',
    enableReinitialize: true,
    persistentSubmitErrors: true,
  }),
  withTransitionPrevent()
)(Personal);
