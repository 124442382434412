import React from 'react';
import { func, string } from 'prop-types';

import styles from './style.module.css';

const Option = ({ label, handleOnMouseEnter }) => (
  <div onMouseEnter={handleOnMouseEnter} className={styles.option}>
    {label}
  </div>
);

Option.propTypes = {
  label: string.isRequired,
  handleOnMouseEnter: func.isRequired,
};

export default Option;
