import { JsonApiResponse } from 'json-api-normalizer';

import api from '../index';

export const getRevenueCashTransactions = ({
  page,
  startDate,
  endDate,
}: {
  page: number;
  startDate: string;
  endDate: string;
}) => {
  const url = '/me/datev_reports/revenue_cash_transactions';

  return api.get<JsonApiResponse>(url, {
    params: {
      page,
      start_date: startDate,
      end_date: endDate,
      pagination_resource: 'datev_exports',
    },
  });
};

export const getExpenseCashTransactions = ({
  page,
  startDate,
  endDate,
}: {
  page: number;
  startDate: string;
  endDate: string;
}) => {
  const url = '/me/datev_reports/expense_cash_transactions';

  return api.get<JsonApiResponse>(url, {
    params: {
      page,
      start_date: startDate,
      end_date: endDate,
      pagination_resource: 'datev_exports',
    },
  });
};
