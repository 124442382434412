import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Section from 'components/Form/Section/Section';

import styles from './PiwikOptOut.module.css';

function PiwikOptOut({ src = '' }) {
  if (!src) return null;
  return (
    <Section title="Webanalyse">
      <iframe className={styles.iframe} title="piwik" id="profile-piwik-opt-out-iframe" src={src} />
    </Section>
  );
}

PiwikOptOut.propTypes = {
  src: PropTypes.string,
};

export default connect((s) => ({
  src: s.piwik.src,
}))(PiwikOptOut);
