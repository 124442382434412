import styled from 'styled-components';

import Button from 'redesign/components/atoms/Button/Button';
import BaseHelpIcon from 'redesign/components/atoms/Icons/Help';
import { Breakpoints } from 'redesign/styles/breakpoints';

export const Wrapper = styled.div`
  margin: ${({ theme }) => theme.space[10]} auto;
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey['080']};
  margin: ${({ theme }) => theme.space[8]} 0;
  text-align: center;
`;

export const Row = styled.div`
  max-width: 1440px;
  display: flex;
  margin: 0 auto ${({ theme }) => theme.space[10]};
  gap: ${({ theme }) => theme.space[5]};
  flex-wrap: wrap;
  justify-content: center;
`;

export const Customize = styled.div`
  position: relative;
  max-width: 344px;
  min-height: 344px;
  border: 3px dashed ${({ theme }) => theme.colors.vrblue['025']};
  padding: ${({ theme }) => theme.space[4]};
  text-align: center;
`;

export const IconWrapper = styled.div`
  width: 62px;
  margin: ${({ theme }) => theme.space[6]} auto;
`;

export const ItemTitle = styled.h3`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey['080']};
  margin: ${({ theme }) => theme.space[6]} 0;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.grey['080']};
  margin: ${({ theme }) => theme.space[4]} 0;
`;

export const ItemButton = styled(Button)`
  width: 208px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  margin: ${({ theme }) => theme.space[10]} auto;
`;

export const HelpIcon = styled(BaseHelpIcon)`
  path:last-child {
    fill: rgb(143, 184, 218);
  }
`;

export const ActionRow = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  background: ${({ theme }) => theme.legacy.colors.primaryBackground};

  a {
    margin: ${({ theme }) => theme.space[2]} auto;
    font-weight: bold;
  }

  @media (min-width: ${Breakpoints.md}) {
    position: relative;
  }
`;

export const Save = styled(Button)`
  width: 208px;
  margin: ${({ theme }) => theme.space[4]} auto;
`;
