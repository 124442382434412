import { UseFormSetValue } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';

import {
  DOCUMENT_LAYOUT_CHOOSE_FONT_FAMILY,
  DOCUMENT_LAYOUT_CHOOSE_FOOTER_STYLE,
  DOCUMENT_LAYOUT_CHOOSE_HEADER_STYLE,
  DOCUMENT_LAYOUT_CHOOSE_PRESET,
  DOCUMENT_LAYOUT_CHOOSE_TABLE_STYLE,
} from 'constants/piwik';
import { Preset } from 'types/entities/InvoiceTemplate';

import { PresetValues } from './constants';
import { FormData } from './types';
import { trackEvent } from './utils';

export const useUpdateValuesOnPresetChange = (
  defaultValues: Partial<FormData>,
  preset: FormData['preset'],
  setValue: UseFormSetValue<FormData>
) => {
  useUpdateEffect(() => {
    const presetData = PresetValues[preset];
    const formData = presetData ? presetData : { ...defaultValues, preset: preset };

    for (const [key, value] of Object.entries(formData)) {
      setValue(key as keyof FormData, value, { shouldDirty: true });
    }
  }, [preset]);
};

export const useUpdateInvertColouredElementsTextColor = (
  preset: FormData['preset'],
  tableVersion: FormData['tableVersion'],
  setValue: UseFormSetValue<FormData>
) => {
  useUpdateEffect(() => {
    if (preset !== Preset.Custom && tableVersion.value === 1) return;

    setValue('invertColouredElementsTextColor', false);
  }, [preset, tableVersion]);
};

export const useUpdateColouredElementsHex = (
  preset: FormData['preset'],
  tableVersion: FormData['tableVersion'],
  colouredElementsHex: FormData['colouredElementsHex'],
  setValue: UseFormSetValue<FormData>
) => {
  useUpdateEffect(() => {
    if (preset !== Preset.Custom) return;

    if (tableVersion.value === 1 && colouredElementsHex === '#000000') {
      setValue('colouredElementsHex', '#e5e5e5');
    } else if (
      (tableVersion.value === 2 || tableVersion.value === 3) &&
      colouredElementsHex === '#e5e5e5'
    ) {
      setValue('colouredElementsHex', '#000000');
    }
  }, [preset, tableVersion]);
};

export const useTrackEvents = (values: FormData) => {
  useUpdateEffect(() => {
    trackEvent(DOCUMENT_LAYOUT_CHOOSE_PRESET, `preset-${values.preset}`);
  }, [values.preset]);

  useUpdateEffect(() => {
    trackEvent(DOCUMENT_LAYOUT_CHOOSE_HEADER_STYLE, `header-${values.headerVersion.value}`);
  }, [values.headerVersion]);

  useUpdateEffect(() => {
    trackEvent(DOCUMENT_LAYOUT_CHOOSE_TABLE_STYLE, `table-${values.tableVersion.value}`);
  }, [values.tableVersion]);

  useUpdateEffect(() => {
    trackEvent(DOCUMENT_LAYOUT_CHOOSE_FOOTER_STYLE, `footer-${values.footerVersion.value}`);
  }, [values.footerVersion]);

  useUpdateEffect(() => {
    trackEvent(DOCUMENT_LAYOUT_CHOOSE_FONT_FAMILY, values.fontFamily.value);
  }, [values.fontFamily]);
};
