import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { formatMoney, t } from 'shared/utils';

import styles from './ProposalSumSection.module.css';

const InvoiceSumSection = ({ vatAmount, nettoAmount, grossAmount, currency, insertedAsGross }) => (
  <div className={styles.main}>
    <div className={styles.section}>
      {insertedAsGross ? (
        <div className={cx(styles.sum, styles.total)}>
          <div className={styles.name}>{t('expenses.sum_section.total')}</div>
          <div data-id="ProposalPage:amount-gross" className={styles.amount}>
            {formatMoney(grossAmount, currency)}
          </div>
        </div>
      ) : (
        <div className={cx(styles.sum)}>
          <div className={styles.name}>{t('expenses.sum_section.subtotal')}</div>
          <div data-id="ProposalPage:amount-net" className={styles.amount}>
            {formatMoney(nettoAmount, currency)}
          </div>
        </div>
      )}
      <div className={styles.sum}>
        <div className={styles.name}>{t('expenses.sum_section.vattotal')}</div>
        <div data-id="ProposalPage:amount-tax" className={styles.amount}>
          {formatMoney(vatAmount, currency)}
        </div>
      </div>
    </div>

    <div className={styles.section}>
      {insertedAsGross ? (
        <div className={cx(styles.sum, styles.withoutPadding)}>
          <div className={styles.name}>{t('expenses.sum_section.subtotal')}</div>
          <div data-id="ProposalPage:amount-net" className={styles.amount}>
            {formatMoney(nettoAmount, currency)}
          </div>
        </div>
      ) : (
        <div className={cx(styles.sum, styles.total, styles.withoutPadding)}>
          <div className={styles.name}>{t('expenses.sum_section.total')}</div>
          <div data-id="ProposalPage:amount-gross" className={styles.amount}>
            {formatMoney(grossAmount, currency)}
          </div>
        </div>
      )}
    </div>
  </div>
);

InvoiceSumSection.propTypes = {
  currency: PropTypes.string.isRequired,
  vatAmount: PropTypes.number.isRequired,
  nettoAmount: PropTypes.number.isRequired,
  grossAmount: PropTypes.number.isRequired,
};

export default connect((state) => ({
  currency: state.proposal.sums.currency,
  vatAmount: state.proposal.sums.totalVatAmount,
  nettoAmount: state.proposal.sums.totalNetAmount,
  grossAmount: state.proposal.sums.totalGrossAmount,
}))(InvoiceSumSection);
