import React from 'react';
import cx from 'classnames';

import { noop, t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import If from 'components/Conditions/If';

import type { ActiveNode, PreviewFile, TrackingEvents } from '../types';

import styles from './Thumbnail.module.css';

type ThumbnailProps = {
  file: PreviewFile & { name: string | number };
  isActive: boolean;
  onClick: () => void;
  onRemove?: () => void;
  readonly?: boolean;
  setActiveThumbnailNode: ActiveNode;
  trackingEvents?: TrackingEvents;
  fileNumber?: number;
  isFullscreen?: boolean;
};

const Thumbnail = ({
  file: { thumbnail, name, url },
  isActive,
  onClick,
  onRemove,
  readonly,
  setActiveThumbnailNode,
  trackingEvents,
  fileNumber,
  isFullscreen = false,
}: ThumbnailProps) => {
  const handleDownload = () => {
    if (trackingEvents?.trackDownload) {
      const { category, name } = trackingEvents.trackDownload;
      piwikHelpers.trackEvent(category, name);
    }
  };

  return (
    <div className={styles.container} ref={isActive ? setActiveThumbnailNode : noop}>
      <button
        className={cx(styles.thumbnail, isActive && styles.isActive)}
        type="button"
        onClick={onClick}
        data-id="Gallery:button-previewThumbnail"
      >
        <img className={styles.thumbnailImage} src={thumbnail} alt="test" />
      </button>
      <div className={styles.buttons}>
        {isFullscreen && (
          <div className={cx(styles.fileNumber, { [styles.fileNumberActive]: isActive })}>
            {fileNumber}
          </div>
        )}
        {!isFullscreen && (
          <>
            <a
              className={cx(styles.button, styles.download, { [styles.isFull]: readonly })}
              download={name}
              href={url}
              title={t('expenses.upload.actions.download')}
              data-id="Gallery:button-download"
              onClick={handleDownload}
            >
              {t('expenses.upload.actions.download')}
            </a>
            <If ok={!readonly}>
              <button
                className={cx(styles.button, styles.remove)}
                onClick={onRemove}
                title={t('expenses.upload.actions.remove')}
                type="button"
                data-id="Gallery:button-remove"
              />
            </If>
          </>
        )}
      </div>
    </div>
  );
};

export default Thumbnail;
