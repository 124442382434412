import styled from 'styled-components';

export const List = styled.ul`
  padding-left: ${({ theme }) => theme.space['7']};
  list-style-type: none;

  & li::before {
    margin-right: ${({ theme }) => theme.space['2']};
    content: '—';
  }
`;

export const FooterText = styled(List)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.grey['040']};
`;

export const Subtext = styled.div`
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-bottom: ${({ theme }) => theme.space['4']};
`;

export const BankAccountHint = styled.p`
  margin-top: ${({ theme }) => theme.space['2']};
  margin-bottom: ${({ theme }) => theme.space['8']};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  font-size: ${({ theme }) => theme.fontSize.sm};
`;
