export const PRODUCT_CATALOG_GROUPS = 'productCatalogGroups';

export const FORM_NAME = 'productCatalogGroupCreator';

export const NAMESPACE = 'PRODUCT_CATALOG_GROUPS';

export const ADD_ITEM_REQUEST = 'PRODUCT_CATALOG_GROUPS/ADD_ITEM_REQUEST';
export const ADD_ITEM_SUCCESS = 'PRODUCT_CATALOG_GROUPS/ADD_ITEM_SUCCESS';
export const ADD_ITEM_FAILURE = 'PRODUCT_CATALOG_GROUPS/ADD_ITEM_FAILURE';

export const REMOVE_ITEM_REQUEST = 'PRODUCT_CATALOG_GROUPS/REMOVE_ITEM_REQUEST';
export const REMOVE_ITEM_SUCCESS = 'PRODUCT_CATALOG_GROUPS/REMOVE_ITEM_SUCCESS';
export const REMOVE_ITEM_FAILURE = 'PRODUCT_CATALOG_GROUPS/REMOVE_ITEM_FAILURE';

// Piwik
export const CLICK_CREATE = 'PRODUCT_CATALOG_GROUPS/CLICK_CREATE';
export const CLICK_EDIT = 'PRODUCT_CATALOG_GROUPS/CLICK_EDIT';
export const CLICK_CANCEL = 'PRODUCT_CATALOG_GROUPS/CLICK_CANCEL';

export const CREATE_SUCCESS = 'PRODUCT_CATALOG_GROUPS/CREATE_SUCCESS';
export const CREATE_FAILURE = 'PRODUCT_CATALOG_GROUPS/CREATE_FAILURE';

export const ITEMS_DROPPABLE_ID = 'PRODUCT_CATALOG_GROUPS/LINE_ITEMS_DROPPABLE';
