import styled from 'styled-components';

import BaseBox from 'redesign/components/atoms/Box/Box';
import { media } from 'redesign/styles/breakpoints';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const YearPicker = styled.div`
  margin-bottom: ${({ theme }) => theme.space['1']};
`;

export const Box = styled(BaseBox)`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  position: relative;
  min-height: 462px;

  ${media.greaterThan('md')`
    min-height: 153px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: initial;
  `}
`;

export const Column = styled.div`
  padding-top: ${({ theme }) => theme.space['4']};
  padding-right: ${({ theme }) => theme.space['6']};
  padding-bottom: ${({ theme }) => theme.space['6']};
  padding-left: ${({ theme }) => theme.space['6']};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey['050']};
  font-size: ${({ theme }) => theme.fontSize.sm};

  &:last-child {
    border: 0;
  }

  > div {
    color: ${({ theme }) => theme.colors.grey['050']};
  }

  ${media.greaterThan('md')`
    border-bottom: 0;
    border-right: 1px solid ${({ theme }) => theme.colors.grey['050']};
  `}
`;

export const Subtitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.space['6']};
  color: ${({ theme }) => theme.colors.grey['070']};
  font-weight: bold;
`;

type AmountProps = {
  $isSmall?: boolean;
};

export const Amount = styled.span<AmountProps>`
  display: block;
  font-size: ${({ theme, $isSmall }) => ($isSmall ? theme.fontSize['2xl'] : theme.fontSize['4xl'])};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey['080']};
  margin-bottom: ${({ theme }) => theme.space['4']};
  white-space: nowrap;
`;

type NumberProps = {
  $isNegative?: boolean;
};

export const Number = styled.span<NumberProps>`
  color: ${({ theme, $isNegative }) =>
    $isNegative ? theme.colors.red['100'] : theme.colors.green['125']};
  font-weight: bold;
`;
