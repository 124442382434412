import React, { memo, SVGProps } from 'react';

const FinancialPlanning = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M19.778 2H4.222C3 2 2 3 2 4.222v15.556C2 21 3 22 4.222 22h15.556C21 22 22 21 22 19.778V4.222C22 3 21 2 19.778 2zM13.26 6.251a.873.873 0 011.226 0l1.018 1.018 1.018-1.018a.873.873 0 011.226 0 .872.872 0 010 1.226L16.73 8.494l1.018 1.018a.872.872 0 010 1.225.873.873 0 01-1.226 0l-1.018-1.006-1.018 1.018a.873.873 0 01-1.226 0 .872.872 0 010-1.226l1.018-1.017-1.018-1.018a.865.865 0 010-1.237zM5.9 7h4.2c.492 0 .9.453.9 1 0 .547-.408 1-.9 1H5.9C5.408 9 5 8.547 5 8c0-.547.408-1 .9-1zm4.282 9.818H8.818v1.364c0 .447-.37.818-.818.818a.824.824 0 01-.818-.818v-1.364H5.818A.824.824 0 015 16c0-.447.37-.818.818-.818h1.364v-1.364c0-.447.37-.818.818-.818.447 0 .818.37.818.818v1.364h1.364c.447 0 .818.37.818.818 0 .447-.37.818-.818.818zM18.1 18h-4.2c-.492 0-.9-.453-.9-1 0-.547.408-1 .9-1h4.2c.492 0 .9.453.9 1 0 .547-.408 1-.9 1zm0-3h-4.2c-.492 0-.9-.453-.9-1 0-.547.408-1 .9-1h4.2c.492 0 .9.453.9 1 0 .547-.408 1-.9 1z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default memo(FinancialPlanning);
