import React from 'react';

import I18n from 'components/I18n';

import styles from './TableLoading.module.css';

const TableLoading = () => (
  <div className={styles.loading} data-id="tableLoadingIcon">
    <I18n t="suppliers.table.loading" />
  </div>
);

export default TableLoading;
