import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import { FARMPILOT_HANDBOOK_LINK, FARMPILOT_PARTNER_SERVICE } from 'constants/farmpilot';
import {
  ACTION_CLOSE_REQUIREMENTS,
  ACTION_OPEN_REQUIRMENTS,
  ACTION_SETTINGS_FARMPILOT_GENERATE_CODE,
  ACTION_SETTINGS_FARMPILOT_OPEN_HANDBOOK,
  ACTION_SETTINGS_FARMPILOT_SEE_CODE_INFO,
  CATEGORY_FARMPILOT,
} from 'constants/piwik';
import FarmpilotPicture from 'images/farmpilot-field-picture.png';
import FarmpilotLogo from 'images/farmpilot-logo.svg';
import { isFarmpilotTokenConnected as isFarmpilotTokenConnectedSelector } from 'selectors/app-connections';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import Accordion from 'components/Accordion/Accordion';
import Button, { ButtonAppearances } from 'components/Button';
import CardView, { HeadingSection, Section } from 'components/CardView';
import formSectionStyles from 'components/Form/Section/Section.module.css';
import Tooltip from 'redesign/components/molecules/Tooltip/Tooltip';

import RemoveConnectionButton from './RemoveConnectionButton/RemoveConnectionButton';
import TokenGenerator from './TokenGenerator/TokenGenerator';

import styles from './Farmpilot.module.css';

const label = t('farmpilot.description_section.requirements.label');
const content = t('farmpilot.description_section.requirements.content');

const trackCodeInfo = () =>
  piwikHelpers.trackEvent(CATEGORY_FARMPILOT, ACTION_SETTINGS_FARMPILOT_SEE_CODE_INFO);
const trackGeneratingToken = () =>
  piwikHelpers.trackEvent(CATEGORY_FARMPILOT, ACTION_SETTINGS_FARMPILOT_GENERATE_CODE);
const trackOpeningHandbook = () =>
  piwikHelpers.trackEvent(CATEGORY_FARMPILOT, ACTION_SETTINGS_FARMPILOT_OPEN_HANDBOOK);
const trackOpeningRequirements = () =>
  piwikHelpers.trackEvent(CATEGORY_FARMPILOT, ACTION_OPEN_REQUIRMENTS);
const trackClosingRequirements = () =>
  piwikHelpers.trackEvent(CATEGORY_FARMPILOT, ACTION_CLOSE_REQUIREMENTS);

interface FarmpilotProps {
  isFarmpilotTokenConnected: boolean;
}

const Farmpilot: FunctionComponent<FarmpilotProps> = ({ isFarmpilotTokenConnected }) => {
  return (
    <div className={styles.page}>
      <CardView className={styles.card}>
        <HeadingSection>
          <p className={styles.headingText}>{t('farmpilot.header')}</p>
        </HeadingSection>
        <div className={styles.line} />
        <Section className={styles.section}>
          <p className={cx(styles.sectionParagraph, styles.subheader)}>
            {t('farmpilot.subheader')}
          </p>
        </Section>
        <Section className={cx(styles.section, styles.cooperationSection)}>
          <div className={cx(formSectionStyles.sectionTitle, styles.sectionTitle)}>
            {t('farmpilot.description_section.title')}
          </div>
          <div className={styles.picture}>
            <img src={FarmpilotPicture} alt="farmpilot" />
          </div>
          <img className={styles.logo} src={FarmpilotLogo} alt="farmpilot logo" />
          <p className={styles.subheadingText}>{t('farmpilot.description_section.header')}</p>
          <p className={styles.sectionParagraph}>{t('farmpilot.description_section.info')}</p>
          <Accordion
            label={label}
            onOpen={trackOpeningRequirements}
            onClose={trackClosingRequirements}
          >
            {content}
          </Accordion>
        </Section>
        <Section className={styles.tokenSection}>
          <div className={cx(formSectionStyles.sectionTitle, styles.sectionTitle)}>
            {t('farmpilot.connection_section.title')}
          </div>
          <p className={styles.subheadingText}>{t('farmpilot.connection_section.header')}</p>
          <p className={styles.sectionParagraph}>
            <span>{t('farmpilot.connection_section.info_1')}</span>
            <div className={styles.tooltip}>
              <Tooltip
                clickHandler={trackCodeInfo}
                message={t('farmpilot.connection_section.info_tooltip')}
              />
            </div>
            <span>{t('farmpilot.connection_section.info_2')}</span>
          </p>
          <TokenGenerator trackGeneratingToken={trackGeneratingToken} />
          {isFarmpilotTokenConnected && (
            <div className={styles.tokenSectionButtons}>
              <RemoveConnectionButton />
              <Button
                appearance={ButtonAppearances.outlined}
                redirect={FARMPILOT_PARTNER_SERVICE}
                label={t('farmpilot.connection_section.buttons.open_farmpilot')}
                className={styles.redirectButton}
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>
          )}
          <a
            onClick={trackOpeningHandbook}
            href={FARMPILOT_HANDBOOK_LINK}
            className={styles.handbookLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('farmpilot.connection_section.link_handbook')}
          </a>
        </Section>
      </CardView>
    </div>
  );
};

const mapStateToProps = (state: object) => ({
  isFarmpilotTokenConnected: isFarmpilotTokenConnectedSelector(state),
});

export default connect(mapStateToProps)(Farmpilot);
