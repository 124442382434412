import { WrappedFieldProps } from 'redux-form';

const checker = ({ meta: passwordMeta }: WrappedFieldProps, wantedError: string) => {
  if (!passwordMeta.error) {
    return null;
  }

  if (!passwordMeta.error.find((e: Event) => e.type === wantedError)) {
    return null;
  }

  const error: Error | { message?: string } =
    passwordMeta.error.find((e: Event) => e.type === wantedError) || {};

  return error.message;
};

const bothCases = (props: WrappedFieldProps) => checker(props, 'passwords-not-both-cases');
const specials = (props: WrappedFieldProps) => checker(props, 'passwords-not-specials');
const length = (props: WrappedFieldProps) => checker(props, 'passwords-not-long-enough');

export default {
  bothCases,
  specials,
  length,
};
