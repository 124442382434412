import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { branch, compose, lifecycle, renderComponent, withState } from 'recompose';
import { destroy } from 'redux-form';

import { destroyForm, fetchOutgoingInvoiceResources } from 'actions/outgoing-invoice';
import ReminderNew from 'containers/OutgoingInvoices/ReminderNew';
import { t } from 'shared/utils';
import Loading from 'components/Loading';
import Tabs from 'components/Tabs';

import styles from './hoc.module.css';

const enhance = compose(
  connect(
    (state, { match = {} }) => ({
      invoiceId: match.params.id,
      imported: state.outgoingInvoice.details.imported,
    }),
    { fetchOutgoingInvoiceResources, destroyForm, destroy }
  ),
  withState('isLoading', 'setLoading', true),
  lifecycle({
    async componentDidMount() {
      await this.props.fetchOutgoingInvoiceResources(this.props.invoiceId);
      this.props.setLoading(false);
    },
    componentWillUnmount() {
      this.props.destroyForm();
      this.props.destroy('outgoingInvoiceCreator');
    },
  }),
  branch(({ isLoading }) => isLoading, renderComponent(Loading))
);

const transformReminderToTab = (reminder, index) => {
  const subject = index + 1;

  return {
    heading: `${index + 1}.${t('reminder.subject')}`,
    component: ReminderNew,
    reminder: { subject, ...reminder },
  };
};

export default function withPaymentRemindersTabs(Component) {
  const PaymentReminderWrapper = (props) => {
    const invoice = { heading: t('reminder.invoice_header'), component: Component };
    const reminders = props.paymentReminders.map(transformReminderToTab);
    const {
      location: { state: { paymentReminderIndex, isParentInvoice = false } = {} },
    } = props;
    const sections = props.paymentRemindersEnabled ? [invoice, ...reminders] : [invoice];
    const initialTab = isParentInvoice ? 0 : paymentReminderIndex || sections.length - 1;

    return (
      <Tabs
        sections={sections}
        {...props}
        initialTab={initialTab}
        destroyOnUnmount={false}
        theme={{
          main: styles.main,
          content: styles.content,
        }}
      />
    );
  };

  PaymentReminderWrapper.propTypes = {
    paymentReminders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    paymentRemindersEnabled: PropTypes.bool,
    location: PropTypes.shape({}),
  };

  PaymentReminderWrapper.defaultProps = {
    paymentReminders: [],
    paymentRemindersEnabled: false,
  };

  return enhance(PaymentReminderWrapper);
}
