import { showNotification } from 'actions/notification';
import { fetchOrderConfirmationSums } from 'actions/order-confirmation/sums';
import { CALL_API } from 'constants/api';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/order-confirmation/line-items';
import {
  lineItemCreateSuccess,
  lineItemDeleteFailure,
  lineItemDeleteSuccess,
  lineItemUpdateSuccess,
} from 'notifications/order-confirmation';
import { apiErrorHandler, isNetworkError } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

export const apiFetchOrderConfirmationLineItems = (orderConfirmationId) => ({
  [CALL_API]: {
    endpoint: `/me/order_confirmations/${orderConfirmationId}/order_confirmation_line_items`,
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiDeleteOrderConfirmationLineItem = (orderConfirmationId, id) => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: `/me/order_confirmations/${orderConfirmationId}/order_confirmation_line_items/${id}`,
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const apiUpdateOrderConfirmationLineItem = (orderConfirmationId, id, data) => ({
  [CALL_API]: {
    data,
    method: 'PUT',
    endpoint: `/me/order_confirmations/${orderConfirmationId}/order_confirmation_line_items/${id}`,
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const apiCreateOrderConfirmationLineItem = (orderConfirmationId, data) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: `/me/order_confirmations/${orderConfirmationId}/order_confirmation_line_items`,
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const fetchOrderConfirmationLineItems =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchOrderConfirmationLineItems(...args)).catch(apiErrorHandler);

export const deleteOrderConfirmationLineItem = (orderConfirmationId, id) => (dispatch) =>
  bindServerValidation(apiDeleteOrderConfirmationLineItem(orderConfirmationId, id), dispatch, {
    isReduxForm: false,
  })
    .then(() => {
      dispatch(showNotification(lineItemDeleteSuccess));
      dispatch(fetchOrderConfirmationSums(orderConfirmationId));
    })
    .catch((err) => {
      apiErrorHandler(err);
      if (!isNetworkError(err)) {
        dispatch(showNotification(lineItemDeleteFailure));
      }
    });

export const updateOrderConfirmationLineItem =
  (orderConfirmationId, id, formValues) => (dispatch) =>
    bindServerValidation(
      apiUpdateOrderConfirmationLineItem(orderConfirmationId, id, formValues),
      dispatch,
      {
        isReduxForm: false,
      }
    )
      .then(() => {
        dispatch(showNotification(lineItemUpdateSuccess));
        dispatch(fetchOrderConfirmationSums(orderConfirmationId));
      })
      .catch((err) => {
        apiErrorHandler(err);
        throw err;
      });

export const createOrderConfirmationLineItem = (orderConfirmationId, formValues) => (dispatch) =>
  bindServerValidation(
    apiCreateOrderConfirmationLineItem(orderConfirmationId, formValues),
    dispatch,
    {
      isReduxForm: false,
    }
  )
    .then(() => {
      dispatch(showNotification(lineItemCreateSuccess));
      dispatch(fetchOrderConfirmationSums(orderConfirmationId));
    })
    .catch((err) => {
      apiErrorHandler(err);
      throw err;
    });
