import { AxiosPromise, CancelToken } from 'axios';
import { JsonApiResponse } from 'json-api-normalizer';

import api from './index';

type GetSearchData = {
  search: string;
  page?: number;
  per_page?: number;
};

export const getSearch = (
  data?: GetSearchData,
  token?: CancelToken
): AxiosPromise<JsonApiResponse> => {
  const url = '/search';

  return api.get(url, {
    params: data,
    cancelToken: token,
  });
};
