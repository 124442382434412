import { push, replace } from 'connected-react-router';
import normalize from 'json-api-normalizer';
import { get } from 'lodash';
import { change } from 'redux-form';
import build from 'redux-object';

import { fetchCustomerDefaults } from 'actions/default-values';
import { showNotification } from 'actions/notification';
import { fetchProposalLineItems } from 'actions/proposal/line-items';
import { CALL_API } from 'constants/api';
import EntityPath from 'constants/entitiesPaths';
import {
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
} from 'constants/outgoing-invoice';
import * as Piwik from 'constants/piwik';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DOWNLOAD_FAILURE,
  DOWNLOAD_REQUEST,
  DOWNLOAD_SUCCESS,
  DUPLICATE_PROPOSAL_FAILURE,
  DUPLICATE_PROPOSAL_REQUEST,
  DUPLICATE_PROPOSAL_SUCCESS,
  FETCH_FAILURE,
  FETCH_LINE_CATEGORIES_FAILURE,
  FETCH_LINE_CATEGORIES_REQUEST,
  FETCH_LINE_CATEGORIES_SUCCESS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FIRST_UPDATE_FAILURE,
  FIRST_UPDATE_REQUEST,
  FIRST_UPDATE_SUCCESS,
  FORM_NAME,
  FROM_DUPLICATE_QUERY_PARAM,
  FROM_PROPOSAL_QUERY_PARAM,
  REORDER_LINE_ITEMS_FAILURE,
  REORDER_LINE_ITEMS_LOCAL,
  REORDER_LINE_ITEMS_REQUEST,
  REORDER_LINE_ITEMS_SUCCESS,
  SEND_FAILURE,
  SEND_REQUEST,
  SEND_SUCCESS,
  TOGGLE_GROSS_NET_FAILURE,
  TOGGLE_GROSS_NET_REQUEST,
  TOGGLE_GROSS_NET_SUCCESS,
  TRANSFORM_FAILURE,
  TRANSFORM_REQUEST,
  TRANSFORM_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/proposal';
import { downloadSuccess, sendSuccess } from 'notifications/proposals';
import paths from 'routes/paths';
import { downloadData } from 'shared/utils';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { proposalFilename } from 'shared/utils/file-naming';
import { piwikHelpers } from 'shared/utils/piwik';
import { bindServerValidation } from 'shared/utils/server-validation';

const apiFetchProposal = (id) => ({
  [CALL_API]: {
    endpoint: `/me/proposals/${id}`,
    method: 'GET',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiFetchLineCategories = () => ({
  [CALL_API]: {
    endpoint: '/line_item_categories/invoice_revenues',
    types: [
      FETCH_LINE_CATEGORIES_REQUEST,
      FETCH_LINE_CATEGORIES_SUCCESS,
      FETCH_LINE_CATEGORIES_FAILURE,
    ],
  },
});

export const apiCreateProposal = (data) => ({
  [CALL_API]: {
    data,
    endpoint: '/me/proposals',
    method: 'POST',
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const apiUpdateProposal = (id, data) => ({
  [CALL_API]: {
    data,
    endpoint: `/me/proposals/${id}`,
    method: 'PUT',
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const apiToggleGrossNet = (proposalId, insertedAsGross) => ({
  [CALL_API]: {
    data: { insertedAsGross },
    method: 'PATCH',
    endpoint: `/me/proposals/${proposalId}/toggle_inserted_as_gross`,
    types: [TOGGLE_GROSS_NET_REQUEST, TOGGLE_GROSS_NET_SUCCESS, TOGGLE_GROSS_NET_FAILURE],
  },
});

export const apiReorderLineItems = (proposalId, lineItemsOrder) => ({
  [CALL_API]: {
    data: { lineItemsOrder },
    method: 'PATCH',
    endpoint: `/me/proposals/${proposalId}/reorder`,
    types: [REORDER_LINE_ITEMS_REQUEST, REORDER_LINE_ITEMS_SUCCESS, REORDER_LINE_ITEMS_FAILURE],
  },
});

export const apiFirstUpdateProposal = (id, data) => ({
  [CALL_API]: {
    data,
    endpoint: `/me/proposals/${id}`,
    method: 'PUT',
    types: [FIRST_UPDATE_REQUEST, FIRST_UPDATE_SUCCESS, FIRST_UPDATE_FAILURE],
  },
});

export const apiDownloadProposal = (id, values) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: `/me/proposals/${id}/preview`,
    data: { ...values },
    types: [DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE],
    responseType: 'arraybuffer',
  },
});

export const apiSendProposal = ({ id, email, ccEmail }) => ({
  [CALL_API]: {
    endpoint: `/me/proposals/${id}/send_by_email`,
    method: 'PUT',
    data: { email, ccEmail },
    types: [SEND_REQUEST, SEND_SUCCESS, SEND_FAILURE],
  },
});

export const sendProposal =
  ({ id, email, ccEmail, isPiwikTracked = false } = {}) =>
  (dispatch) =>
    dispatch(apiSendProposal({ id, email, ccEmail }))
      .then(() => {
        dispatch(showNotification(sendSuccess));

        if (isPiwikTracked) {
          piwikHelpers.trackEvent(Piwik.CATEGORY_PROPOSALS, Piwik.ACTION_PROPOSAL_SEND);
        }
      })
      .catch(apiErrorHandler);

export const fetchOutgoingInvoiceResources = () => (dispatch) =>
  Promise.all([dispatch(fetchLineCategories()), dispatch(fetchCustomerDefaults())]);

export const fetchProposal =
  (...args) =>
  (dispatch) => {
    dispatch(apiFetchProposal(...args))
      .then(() => dispatch(fetchOutgoingInvoiceResources()))
      .catch((err) => {
        apiErrorHandler(err);
        return dispatch(replace(paths.proposals));
      });
  };

export const fetchLineCategories = () => (dispatch) =>
  dispatch(apiFetchLineCategories()).catch(apiErrorHandler);

export const createProposal = (data) => (dispatch) => {
  return dispatch(apiCreateProposal(data)).catch((err) => {
    apiErrorHandler(err);
    return dispatch(push(paths.proposals));
  });
};

export const createAndFetchNewProposal = () => (dispatch) => {
  return dispatch(createProposal({ draft: true }))
    .then(({ response }) => {
      dispatch(fetchLineCategories());
      dispatch(fetchCustomerDefaults());
      return dispatch(fetchProposal(response.data.id));
    })
    .catch((err) => {
      apiErrorHandler(err);
      return dispatch(replace(paths.proposals));
    });
};

export const toggleProposalGrossNet = (proposalId) => (dispatch, getState) => {
  const insertedAsGross = get(getState(), 'proposal.details.insertedAsGross', false);
  return bindServerValidation(apiToggleGrossNet(proposalId, !insertedAsGross), dispatch)
    .then(() => dispatch(fetchProposal(proposalId)))
    .then(() => dispatch(fetchProposalLineItems(proposalId)));
};

export const abortChanges = () => (dispatch) => dispatch(push(paths.proposals));

export const getProposalPreview = (id, data) => (dispatch) =>
  dispatch(apiDownloadProposal(id, data))
    .then(({ rawResponse }) => rawResponse)
    .catch(apiErrorHandler);

export const downloadProposal =
  ({ id, proposalNumber } = {}, values = {}, isPiwikTracked = false) =>
  (dispatch) =>
    dispatch(getProposalPreview(id, values))
      .then((response) => {
        downloadData(response, proposalFilename({ proposalNumber, id }), 'pdf');

        if (isPiwikTracked) {
          piwikHelpers.trackEvent(Piwik.CATEGORY_PROPOSALS, Piwik.ACTION_PROPOSAL_DOWNLOAD);
        }

        return dispatch(showNotification(downloadSuccess));
      })
      .catch(apiErrorHandler);

const apiDelete = (proposal) => ({
  [CALL_API]: {
    endpoint: `/me/proposals/${proposal.id}`,
    method: 'DELETE',
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const deleteProposal = (id) => (dispatch) => dispatch(apiDelete(id)).catch(apiErrorHandler);

const apiTransformIntoOutgoingInvoice = (proposal) => ({
  [CALL_API]: {
    endpoint: `/me/proposals/${proposal.id}/transform_into_outgoing_invoice`,
    method: 'POST',
    types: [TRANSFORM_REQUEST, TRANSFORM_SUCCESS, TRANSFORM_FAILURE],
  },
});

export const transformProposalIntoOutgoingInvoice = (proposal) => (dispatch) =>
  dispatch(apiTransformIntoOutgoingInvoice(proposal)).catch(apiErrorHandler);

const apiTransformIntoOrderConfirmation = (proposal) => ({
  [CALL_API]: {
    endpoint: `/me/proposals/${proposal.id}/transform_into_order_confirmation`,
    method: 'POST',
    types: [TRANSFORM_REQUEST, TRANSFORM_SUCCESS, TRANSFORM_FAILURE],
  },
});

export const transformProposalIntoOrderConfirmation = (id) => (dispatch) =>
  dispatch(apiTransformIntoOrderConfirmation(id)).catch(apiErrorHandler);

export const updateProposal =
  (itemid, data, transform = false, redirect = true) =>
  (dispatch) =>
    bindServerValidation(
      data.isNew ? apiFirstUpdateProposal(itemid, data) : apiUpdateProposal(itemid, data),
      dispatch,
      { isReduxForm: true },
      { client_id: { id: 'client' } }
    ).then(({ response: { data: { attributes } = {} } = {} }) => {
      if (transform === 'outgoingInvoice') {
        dispatch(transformProposalIntoOutgoingInvoice({ id: itemid })).then(({ data: { id } }) => {
          dispatch(
            replace({
              pathname: paths.editOutgoingInvoice(id),
              search: `?${FROM_PROPOSAL_QUERY_PARAM}`,
            })
          );
        });
      } else if (transform === 'orderConfirmation') {
        dispatch(transformProposalIntoOrderConfirmation({ id: itemid })).then(
          ({ data: { id } }) => {
            dispatch(
              replace({
                pathname: paths.editOrderConfirmation(id),
                search: `?${FROM_PROPOSAL_QUERY_PARAM}`,
              })
            );
          }
        );
      } else if (redirect) {
        dispatch(replace(paths.proposals));
      }
      return { ...attributes, success: true };
    });

export const apiCreateClient = (data) => ({
  [CALL_API]: {
    data,
    endpoint: '/me/clients',
    method: 'POST',
    types: [CREATE_CLIENT_REQUEST, CREATE_CLIENT_SUCCESS, CREATE_CLIENT_FAILURE],
  },
});

export const quickCreateClient = (formName) => (values) => (dispatch) =>
  bindServerValidation(apiCreateClient(values), dispatch).then(
    (response) => {
      const client = build(normalize(response), EntityPath.Clients, response.data.id);

      dispatch(change(formName, 'client', client));

      if (formName === FORM_NAME) {
        piwikHelpers.trackEvent(
          Piwik.CATEGORY_PROPOSALS,
          Piwik.ACTION_CREATE_CLIENT_IN_PROPOSAL_CREATOR,
          Piwik.NAME_CREATE_CLIENT_IN_PROPOSAL_CREATOR_SUCCESS
        );
        piwikHelpers.trackGoal(2);
      }
    },
    (response) => {
      if (formName === FORM_NAME) {
        piwikHelpers.trackEvent(
          Piwik.CATEGORY_PROPOSALS,
          Piwik.ACTION_CREATE_CLIENT_IN_PROPOSAL_CREATOR,
          Piwik.NAME_CREATE_CLIENT_IN_PROPOSAL_CREATOR_ERROR
        );
      }
      throw response;
    }
  );

export const reorderLineItems = (proposalId, reorderedLineItems) => (dispatch) => {
  const lineItemsOrder = reorderedLineItems.map(({ id, ordinalNumber }) => ({
    id: Number(id),
    ordinalNumber: Number(ordinalNumber),
  }));
  dispatch({ type: REORDER_LINE_ITEMS_LOCAL, payload: reorderedLineItems });
  return dispatch(apiReorderLineItems(proposalId, lineItemsOrder)).catch(apiErrorHandler);
};

const apiDuplicateProposal = (proposalId) => ({
  [CALL_API]: {
    endpoint: `/me/proposals/${proposalId}/duplicate`,
    method: 'POST',
    types: [DUPLICATE_PROPOSAL_REQUEST, DUPLICATE_PROPOSAL_SUCCESS, DUPLICATE_PROPOSAL_FAILURE],
  },
});

export const duplicateProposal = (proposalId) => (dispatch) =>
  dispatch(apiDuplicateProposal(proposalId))
    .catch(apiErrorHandler)
    .then(({ data: { id: draftId } }) => {
      dispatch(
        replace({
          pathname: paths.editProposal(draftId),
          search: `?${FROM_DUPLICATE_QUERY_PARAM}`,
        })
      );
    });
