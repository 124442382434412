import React, { memo } from 'react';
import SliderComponent from 'rc-slider';

import { t } from 'shared/utils';

import 'rc-slider/assets/index.css';
import styles from './Slider.module.css';

type SliderProps = {
  value: number;
  min: number;
  max: number;
  defaultValue: number;
  onChange: (currentValue: number) => void;
};

const Slider = ({ value, min, max, defaultValue, onChange }: SliderProps) => {
  return (
    <div>
      <div className={styles.period}>
        {t('features.financial_planning.liquidity_calculator.summary.slider.what_period')}
      </div>
      <SliderComponent
        className="liquidity-calculator-slider"
        min={min}
        max={max}
        defaultValue={defaultValue}
        onChange={onChange}
      />
      <div className={styles.months}>
        {t('features.financial_planning.liquidity_calculator.summary.slider.months_amounts', {
          count: value,
        })}
      </div>
    </div>
  );
};

export default memo(Slider);
