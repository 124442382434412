import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { resetCustomerDefaults, updateCustomerDefaults } from 'actions/default-values';
import { EmailsFormName } from 'containers/Profile/InvoiceTemplate/DefaultValues/TabEmailsDefaults/TabEmailsDefaults';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { RootState } from 'store';
import { DefaultValues } from 'types/entities/DefaultValues';
import { InfoTextField, TextareaField } from 'components/Form';
import InputsGroup from 'components/InputsGroup/InputsGroup';

import { DefaultValuesForm, FormFooterContainer, FormRow } from '../../sharedComponents';

import styles from './PaymentReminderEmailsDefaults.module.css';

export const FORM_NAME: EmailsFormName = 'PaymentReminderEmailsDefaults';

type FormData = Pick<
  DefaultValues,
  | 'emailPaymentReminderSubject'
  | 'emailPaymentReminderSalutation'
  | 'emailPaymentReminderSalutationContent'
  | 'emailPaymentReminderSignature'
>;

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    emailPaymentReminderSubject: state.defaultValues.values.emailPaymentReminderSubject,
    emailPaymentReminderSalutation: state.defaultValues.values.emailPaymentReminderSalutation,
    emailPaymentReminderSalutationContent:
      state.defaultValues.values.emailPaymentReminderSalutationContent,
    emailPaymentReminderSignature: state.defaultValues.values.emailPaymentReminderSignature,
  },
});

const mapDispatchToProps = {
  updateCustomerDefaults,
  resetCustomerDefaults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PaymentReminderEmailsDefaultsProps = ConnectedProps<typeof connector>;

const PaymentReminderEmailsDefaults = ({
  resetCustomerDefaults,
  updateCustomerDefaults,
  handleSubmit,
  reset: resetForm,
}: PaymentReminderEmailsDefaultsProps &
  InjectedFormProps<FormData, PaymentReminderEmailsDefaultsProps>) => {
  return (
    <DefaultValuesForm
      onSubmit={handleSubmit((values) => updateCustomerDefaults(values, 'email_payment_reminder'))}
      onReset={async () => {
        await resetCustomerDefaults('email_payment_reminder');
        resetForm();
      }}
      sectionName="email_payment_reminder"
      sectionLabel={t('invoice_templates.emails_defaults.payment_reminder_defaults_section')}
      dataIdPrefix="DocumentDefaults:"
    >
      <>
        <FormRow>
          <InputsGroup label={t('invoice_templates.emails_defaults.subject')}>
            <Field
              disabled
              className={styles.grouppedInput}
              name="emailPaymentReminderSubject"
              checker={serverValidationChecker}
              component={InfoTextField}
              message={t('invoice_templates.emails_defaults.subject_info')}
              dataId="EmailsDefaults:input-payment-reminder-subject"
              isDisabledWithUnderline
            />
          </InputsGroup>
        </FormRow>

        <FormRow>
          <InputsGroup label={t('invoice_templates.emails_defaults.header_message')}>
            <Field
              name="emailPaymentReminderSalutation"
              component={InfoTextField}
              checker={serverValidationChecker}
              label={t('invoice_templates.emails_defaults.salutation')}
              message={t('invoice_templates.emails_defaults.salutation_info')}
              dataId="EmailsDefaults:input-payment-reminder-salutation"
            />
            <Field
              name="emailPaymentReminderSalutationContent"
              component={TextareaField}
              checker={serverValidationChecker}
              label={t('invoice_templates.emails_defaults.salutation_content')}
              fullHeight
              dataId="EmailsDefaults:input-payment-reminder-salutation-content"
            />
          </InputsGroup>
        </FormRow>
        <FormRow>
          <FormFooterContainer>
            <InputsGroup label={t('invoice_templates.emails_defaults.signature')}>
              <Field
                disabled
                className={styles.grouppedInput}
                name="emailPaymentReminderSignature"
                checker={serverValidationChecker}
                component={InfoTextField}
                message={t('invoice_templates.emails_defaults.signature_info')}
                dataId="EmailsDefaults:input-payment-reminder-signature"
                isDisabledWithUnderline
              />
            </InputsGroup>
          </FormFooterContainer>
        </FormRow>
      </>
    </DefaultValuesForm>
  );
};

const FormEnhancedComponent = reduxForm<FormData, PaymentReminderEmailsDefaultsProps>({
  form: FORM_NAME,
  enableReinitialize: true,
  persistentSubmitErrors: true,
})(PaymentReminderEmailsDefaults);

export default connector(FormEnhancedComponent);
