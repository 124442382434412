import React, { Fragment } from 'react';
import { isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import { arrayOf, bool, shape } from 'prop-types';

import SubOrderConfirmationRow from './SubOrderConfirmationRow/SubOrderConfirmationRow';

import styles from './SubOrderConfirmations.module.css';

const DisplayPaymentReminders = (orderConfirmation = {}, isEven) =>
  orderBy(orderConfirmation.paymentReminders, (payment) => moment(payment.sentAt).unix(), [
    'desc',
  ]).map((paymentReminder, index) => {
    const paymentReminderIndex = orderConfirmation.paymentReminders.length - index;

    return (
      <SubOrderConfirmationRow
        orderConfirmation={orderConfirmation}
        key={`${paymentReminder.id}${index}${paymentReminder.sentAt}`}
        isPaymentReminder
        paymentReminder={paymentReminder}
        paymentReminderIndex={paymentReminderIndex}
        isEven={isEven}
      />
    );
  });

const SubOrderConfirmations = ({ subOrderConfirmations, parentOrderConfirmation = {}, isEven }) => (
  <table className={styles.table}>
    <tbody>
      {!!parentOrderConfirmation.paymentRemindersCount &&
        DisplayPaymentReminders(parentOrderConfirmation, isEven)}
      {!isEmpty(subOrderConfirmations) &&
        subOrderConfirmations.map((orderConfirmation, index) => (
          <Fragment key={index}>
            <SubOrderConfirmationRow
              subOrderConfirmation={orderConfirmation}
              key={`${orderConfirmation.id}${index}${orderConfirmation}`}
              isEven={isEven}
            />
            {!!orderConfirmation.paymentRemindersCount &&
              DisplayPaymentReminders(orderConfirmation, isEven)}
          </Fragment>
        ))}
    </tbody>
  </table>
);

SubOrderConfirmations.propTypes = {
  isEven: bool.isRequired,
  parentOrderConfirmation: shape({
    meta: shape({
      actions: shape({
        cancel: bool,
        correct: bool,
        delete: bool,
        edit: bool,
        editPaymentInformation: bool,
        show: bool,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  subOrderConfirmations: arrayOf(
    shape({
      meta: shape({
        actions: shape({
          cancel: bool,
          correct: bool,
          delete: bool,
          edit: bool,
          editPaymentInformation: bool,
          show: bool,
        }).isRequired,
      }).isRequired,
    }).isRequired
  ),
};

export default SubOrderConfirmations;
