import React from 'react';
import { identity, pickBy } from 'lodash';
import PropTypes from 'prop-types';

import { noop } from 'shared/utils';
import { SelectField, TextField } from 'components/Form';

import styles from './Search.module.css';

const defaultMapper = (x) => x;

const pickValue = ({ target: { value = '' } = {} }) => value;

const Search = ({
  name = '',
  filters = {},
  setQueryAction = noop,
  isCurrency = false,
  options = [],
  placeholder = '',
  label = '',
  ...restProps
}) => {
  const Component = options ? SelectField : TextField;

  const props = pickBy(
    {
      options,
      clearable: Boolean(options),
      label,
    },
    identity
  );

  const value = defaultMapper(filters[name] || '');

  return (
    <Component
      value={value}
      className={options && styles.select}
      onChange={(e) => setQueryAction(name)(options ? (e || {}).value : pickValue(e, isCurrency))}
      placeholder={placeholder}
      isCurrency={isCurrency}
      {...props}
      {...restProps}
    />
  );
};

Search.propTypes = {
  name: PropTypes.string.isRequired,
  filters: PropTypes.shape({}),
  setQueryAction: PropTypes.func,
  isCurrency: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

Search.defaultProps = {
  filters: {},
  setQueryAction: defaultMapper,
  options: null,
  placeholder: '',
  label: '',
};

export default Search;
