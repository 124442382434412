import styled from 'styled-components';

import ModalComponent, { ModalHeader as ModalHeaderComponent } from 'components/Modal';
import RadioGroupComponent from 'components/RadioGroup/RadioGroup';

export const Modal = styled(ModalComponent)`
  width: 782px;
  position: relative;
  overflow-y: auto;
`;

export const ModalHeader = styled(ModalHeaderComponent)`
  padding: 0 20px;
`;

export const ModalBody = styled.div`
  padding: 30px 20px 75px 20px;
`;

export const Label = styled.div`
  font-weight: bold;
  margin-bottom: 25px;
`;

export const OptionsWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 95px;
`;

export const RadioGroup = styled(RadioGroupComponent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Option = styled.div`
  margin-bottom: 17px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const OptionFieldWrapper = styled.div`
  margin-left: 25px;
`;

type SummaryProps = {
  isNegative: boolean;
};

export const Summary = styled.div<SummaryProps>`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.legacy.colors.greyLightest};
  padding: 8px;
  padding-right: 21px;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }

  span {
    font-weight: bold;
    color: ${({ theme, isNegative }) =>
      isNegative ? theme.legacy.colors.redFont : theme.legacy.colors.greenFont};
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;

  button {
    width: 172px;

    &:first-child {
      margin-right: 15px;
    }
  }
`;

export const JokerLabel = styled.span`
  margin-right: 20px;
`;

export const CurrencyField = styled.div`
  width: 190px;
`;
