import React, { memo, SVGProps } from 'react';

const Inbox = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 33 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <filter id="inbox">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.980392 0 0 0 0 0.980392 0 0 0 0 0.980392 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g filter="url(#inbox)" transform="translate(.5)">
        <path d="M0 0h32v32H0z" />
      </g>
      <path d="M.5 1h32v32H.5z" />
      <path
        d="M29.5 7.75C29.5 6.237 28.33 5 26.9 5H6.1C4.67 5 3.5 6.237 3.5 7.75v16.5C3.5 25.762 4.67 27 6.1 27h20.8c1.43 0 2.6-1.238 2.6-2.75V7.75zm-2.6 0l-10.4 6.875L6.1 7.75h20.8zm0 16.5H6.1V10.5l10.4 6.875L26.9 10.5v13.75z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(Inbox);
