import React from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';

import ModalContent from 'redesign/components/atoms/ModalContent/ModalContent';
import ModalOverlay from 'redesign/components/atoms/ModalOverlay/ModalOverlay';

import { IconClose } from './Modal.styled';

export type ModalProps = ReactModalProps & {
  size?: 'small' | 'medium' | 'large' | 'full-screen';
};

const Modal = ({ size = 'medium', children, onRequestClose, ...rest }: ModalProps) => {
  return (
    <ReactModal
      overlayElement={({ style, ...props }, contentElement) => (
        <ModalOverlay {...props}>{contentElement}</ModalOverlay>
      )}
      contentElement={({ style, ...props }, contentElement) => (
        <ModalContent size={size} {...props}>
          {contentElement}
        </ModalContent>
      )}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
      appElement={document.getElementById('pbw-react-root') as HTMLElement}
      onRequestClose={onRequestClose}
      {...rest}
    >
      {children}
      {onRequestClose && <IconClose onClick={onRequestClose} />}
    </ReactModal>
  );
};

export default Modal;
