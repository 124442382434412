import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { getGlobalNotifications } from 'api/me/globalNotifications';
import EntityPath from 'constants/entitiesPaths';
import { getEmail } from 'selectors/profile';
import { getEntities } from 'store/slices/globalNotifications/selectors';
import { GlobalNotification } from 'types/entities/GlobalNotification';

import { filterOutClosedGlobalNotifications } from './utils';

export const useFetchGlobalNotifications = () => {
  const [data, setData] = useState<GlobalNotification[]>([]);
  const email = useSelector(getEmail);
  const closed = useSelector(getEntities);

  useEffect(() => {
    const fetch = async () => {
      const response = await getGlobalNotifications();
      const ids = response.data.data.map((item) => item.id);
      const notifications =
        build<GlobalNotification>(normalize(response.data), EntityPath.GlobalNotifications, ids) ||
        [];

      setData(filterOutClosedGlobalNotifications(notifications, closed, email));
    };

    fetch();
  }, []);

  return data;
};
