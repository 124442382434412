import React, { SVGAttributes } from 'react';

const IconSecurity = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 171 170" {...props}>
    <defs>
      <filter
        y="-8.7%"
        width="122.7%"
        height="122.7%"
        filterUnits="objectBoundingBox"
        id="1_svg__a"
      >
        <feOffset dx={2} dy={4} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-22%"
        y="-14.4%"
        width="143.9%"
        height="133.3%"
        filterUnits="objectBoundingBox"
        id="1_svg__c"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={4.5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <circle id="1_svg__b" cx={75} cy={75} r={75} />
      <path id="1_svg__d" d="M42 31h66v87H42z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(8.415 6)">
        <use fill="#000" filter="url(#1_svg__a)" xlinkHref="#1_svg__b" />
        <use fill="#FFF" xlinkHref="#1_svg__b" />
      </g>
      <g transform="translate(8.415 6)">
        <use fill="#000" filter="url(#1_svg__c)" xlinkHref="#1_svg__d" />
        <use fill="#FFF" xlinkHref="#1_svg__d" />
      </g>
      <path
        d="M60.447 55.019h16.376c.57 0 1.033-.452 1.033-1.01 0-.557-.463-1.009-1.033-1.009H60.447c-.57 0-1.032.452-1.032 1.01a1.02 1.02 0 001.032 1.009zm-.003 22.208l43.349.126a1.021 1.021 0 001.035-1.007 1.02 1.02 0 00-1.03-1.012l-43.348-.126a1.021 1.021 0 00-1.035 1.006 1.02 1.02 0 001.03 1.013zm.304-18.17h13.848c.57 0 1.032-.452 1.032-1.01 0-.557-.462-1.01-1.032-1.01H60.748c-.57 0-1.032.453-1.032 1.01 0 .558.462 1.01 1.032 1.01zm-.004 22.207l32.727.127a1.021 1.021 0 001.036-1.006 1.02 1.02 0 00-1.028-1.013l-32.727-.126a1.021 1.021 0 00-1.036 1.005 1.02 1.02 0 001.028 1.013zm-.297-18.17h16.376c.57 0 1.033-.451 1.033-1.009 0-.557-.463-1.01-1.033-1.01H60.447c-.57 0-1.032.453-1.032 1.01 0 .558.462 1.01 1.032 1.01zm-.003 22.208l45.413.126a1.02 1.02 0 001.035-1.006 1.02 1.02 0 00-1.029-1.012l-45.413-.127a1.021 1.021 0 00-1.035 1.007 1.02 1.02 0 001.03 1.012zm.519 25.868h11.354a.51.51 0 00.516-.505.51.51 0 00-.516-.505H60.963a.51.51 0 00-.516.505.51.51 0 00.516.504zm20.127 0h11.353a.51.51 0 00.516-.505.51.51 0 00-.516-.505H81.089a.51.51 0 00-.516.505.51.51 0 00.516.504zm-20.127 3.028h8.257a.51.51 0 00.516-.505.51.51 0 00-.516-.505h-8.257a.51.51 0 00-.516.505.51.51 0 00.516.505zm20.127 0h8.256a.51.51 0 00.516-.505.51.51 0 00-.516-.505H81.09a.51.51 0 00-.516.505.51.51 0 00.516.505zm-20.127 3.028h11.354a.51.51 0 00.516-.505.51.51 0 00-.516-.505H60.963a.51.51 0 00-.516.505.51.51 0 00.516.505zm20.127 0h11.353a.51.51 0 00.516-.505.51.51 0 00-.516-.505H81.089a.51.51 0 00-.516.505.51.51 0 00.516.505z"
        fill="#979797"
        fillRule="nonzero"
      />
      <path d="M98 95h36v36H98z" />
      <path opacity={0.87} d="M98 95h36v36H98z" />
      <path
        d="M125 107h-1.5v-3c0-4.14-3.36-7.5-7.5-7.5-4.14 0-7.5 3.36-7.5 7.5v3H107c-1.65 0-3 1.35-3 3v15c0 1.65 1.35 3 3 3h18c1.65 0 3-1.35 3-3v-15c0-1.65-1.35-3-3-3zm-9 13.5c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3zm-4.5-13.5v-3c0-2.49 2.01-4.5 4.5-4.5s4.5 2.01 4.5 4.5v3h-9z"
        fill="#0066B3"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default IconSecurity;
