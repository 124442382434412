import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api';

type GetCompaniesData = {
  search_filter?: string;
};

export const getCompanies = (data?: GetCompaniesData) => {
  let url = '/me/companies/';

  // If search_filter is provided, append it to the URL path
  if (data && data.search_filter) {
    url += data.search_filter;
  }

  return api.get<JsonApiResponse>(url);
};
