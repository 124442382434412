import React, { memo } from 'react';

import { formatMoney, t } from 'shared/utils';
import Hint from 'components/Hint/Hint';

import Alert from './Alert';

import styles from './HintWithHelp.module.css';

type HintWithHelpProps = {
  moneyPerMonth: number;
  monthsToDeficit: number;
  dataId?: string;
};

const HintWithHelp = ({ moneyPerMonth, monthsToDeficit, dataId = '' }: HintWithHelpProps) => {
  const shortfall = formatMoney(Math.abs(moneyPerMonth));

  return (
    <Hint>
      <div className={styles.content} data-id={dataId}>
        <div className={styles.icon}>
          <Alert />
        </div>
        <div className={styles.text}>
          {t('features.financial_planning.liquidity_calculator.summary.hints.help', {
            count: monthsToDeficit,
            shortfall,
          })}
        </div>
      </div>
    </Hint>
  );
};

export default memo(HintWithHelp);
