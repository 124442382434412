import React from 'react';
import InputMask, { Props as InputFieldProps } from 'react-input-mask';
import { WrappedFieldProps } from 'redux-form';

import TextField from 'components/Form/TextField';

type MaskedFieldProps = InputFieldProps &
  WrappedFieldProps & {
    dataId: string;
  };

const MaskedField = ({ name, placeholder, input, dataId, ...rest }: MaskedFieldProps) => {
  const { value, onChange, onBlur } = input;

  return (
    <InputMask {...rest} value={value} onChange={onChange} onBlur={onBlur}>
      {() => <TextField name={name} placeholder={placeholder} dataId={dataId} />}
    </InputMask>
  );
};

export default MaskedField;
