import React, { memo, ReactNode } from 'react';
import cx from 'classnames';

import styles from './TwoColumnLayout.module.css';

type RightProps = {
  children: ReactNode;
  className?: string;
};

const Right = ({ className, children }: RightProps) => (
  <div className={cx(styles.right, className)}>{children}</div>
);

export default memo(Right);
