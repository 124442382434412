import styled from 'styled-components';

import BaseBox from 'redesign/components/atoms/Box/Box';
import BaseIconClose from 'redesign/components/atoms/Icons/Close';
import BaseIconOverdue from 'redesign/components/atoms/Icons/Overdue';
import BaseTipIcon from 'redesign/components/atoms/Icons/Tip';
import { Breakpoints } from 'redesign/styles/breakpoints';

type TooltipProps = {
  isTooltipOpen: boolean;
};

export const Section = styled.section<TooltipProps>`
  display: grid;
  grid-template-rows: 1fr;
  position: relative;
  padding-bottom: ${({ theme }) => theme.space['5']};

  @media (min-width: ${Breakpoints.lg}) {
    display: flex;
    padding-top: ${({ theme }) => theme.space['4']};
    grid-template-columns: ${({ isTooltipOpen }) => (isTooltipOpen ? '3fr 1fr' : '1fr')};
  }
`;

export const Box = styled(BaseBox)`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  position: relative;
  min-height: 219px;

  @media (min-width: ${Breakpoints.lg}) {
    min-height: 190px;
    flex: 3;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: initial;
  }
`;

export const IconWrapper = styled.div`
  width: ${({ theme }) => theme.space['7']};
  height: ${({ theme }) => theme.space['7']};

  @media (min-width: ${Breakpoints.lg}) {
    width: ${({ theme }) => theme.space['12']};
    height: ${({ theme }) => theme.space['12']};
    margin-bottom: ${({ theme }) => theme.space['3']};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  padding-top: ${({ theme }) => theme.space['4']};
  padding-right: ${({ theme }) => theme.space['6']};
  padding-bottom: ${({ theme }) => theme.space['4']};
  padding-left: ${({ theme }) => theme.space['2']};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey['050']};
  font-size: ${({ theme }) => theme.fontSize.sm};

  &:last-child {
    border: 0;
  }

  @media (min-width: ${Breakpoints.lg}) {
    flex-direction: column;
    border-bottom: 0;
    border-right: 1px solid ${({ theme }) => theme.colors.grey['050']};
    align-items: flex-start;
    padding-bottom: ${({ theme }) => theme.space['6']};
    padding-left: ${({ theme }) => theme.space['6']};
  }
`;

export const Amount = styled.span`
  font-size: ${({ theme }) => theme.fontSize['lg']};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey['080']};
  white-space: nowrap;

  @media (min-width: ${Breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize['2xl']};
  }
`;

export const Amounts = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${Breakpoints.lg}) {
    justify-content: space-between;
  }
`;

export const Summary = styled.div`
  padding-left: ${({ theme }) => theme.space['2']};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.space['2']};

  @media (min-width: ${Breakpoints.lg}) {
    padding-left: 0;
    min-width: 100%;
  }
`;

type Props = {
  $hasOverdueInvoices?: boolean;
};

export const Subtitle = styled.h3<Props>`
  font-size: ${({ theme }) => theme.fontSize['xs']};
  color: ${({ theme, $hasOverdueInvoices }) =>
    $hasOverdueInvoices ? theme.colors.grey['040'] : theme.colors.grey['070']};
  margin-bottom: ${({ theme }) => theme.space['1']};

  @media (min-width: ${Breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize['sm']};
  }
`;

export const IconOverdue = styled(BaseIconOverdue)<Props>`
  background-color: ${({ theme, $hasOverdueInvoices }) =>
    $hasOverdueInvoices ? theme.colors.grey['020'] : theme.colors.red['025']};
  border-radius: 50px;
  fill: ${({ theme, $hasOverdueInvoices }) =>
    $hasOverdueInvoices ? theme.colors.grey['040'] : theme.colors.red['050']};
`;

export const Tooltip = styled.div<TooltipProps>`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: ${({ theme }) => theme.space['5']};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 4px 4px 12px 0 rgba(12, 61, 104, 0.5);
  text-align: center;
  display: ${({ isTooltipOpen }) => (isTooltipOpen ? 'block' : 'none')};
  z-index: 100;

  &::after {
    content: ' ';
    width: 0;
    height: 0;
    border-top: ${({ theme }) => theme.space['4']} solid transparent;
    border-right: ${({ theme }) => theme.space['4']} solid transparent;
    border-left: ${({ theme }) => theme.space['4']} solid transparent;
    border-bottom: ${({ theme }) => theme.space['4']} solid ${({ theme }) => theme.colors.white};
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  p {
    color: ${({ theme }) => theme.colors.grey['080']};

    &:nth-child(3) {
      font-weight: bold;
    }
  }

  @media (min-width: ${Breakpoints.lg}) {
    position: relative;
    flex: 1;
    margin: ${({ theme }) =>
      `-${theme.space['2']} -${theme.space['2']} -${theme.space['2']} ${theme.space['5']} `};

    &::after {
      border-bottom: ${({ theme }) => theme.space['4']} solid transparent;
      border-right: ${({ theme }) => theme.space['4']} solid ${({ theme }) => theme.colors.white};
      left: auto;
      right: 100%;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
  }
`;

export const RoundIcon = styled.div`
  width: ${({ theme }) => theme.space['12']};
  height: ${({ theme }) => theme.space['12']};
  background: rgba(255, 156, 20, 0.3);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => `0 auto ${theme.space['3']}`};

  @media (min-width: ${Breakpoints.lg}) {
    margin: ${({ theme }) => `0 auto ${theme.space['6']}`};
  }
`;

export const Tip = styled(BaseTipIcon)`
  color: ${({ theme }) => theme.colors.vrorange['075']};
  width: ${({ theme }) => theme.space['8']};
  height: ${({ theme }) => theme.space['8']};
`;

export const IconClose = styled(BaseIconClose)`
  width: ${({ theme }) => theme.space['8']};
  position: absolute;
  right: ${({ theme }) => theme.space['3']};
  top: ${({ theme }) => theme.space['3']};

  @media (min-width: ${Breakpoints.lg}) {
    display: none;
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.vrblue['100']};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: bold;
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
`;

export const PreferenceWrapper = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.space['4']};
  margin-bottom: 0;

  > div {
    margin-right: ${({ theme }) => theme.space['2']};
  }

  @media (min-width: ${Breakpoints.lg}) {
    display: none;
  }
`;
