export const NAMESPACE = 'EXTERNAL_ORIGIN_COUNT';

export const FETCH_REQUEST = `${NAMESPACE}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${NAMESPACE}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${NAMESPACE}/FETCH_FAILURE`;

export interface FetchRequestAction {
  type: typeof FETCH_REQUEST;
}

export interface FetchSuccessAction {
  type: typeof FETCH_SUCCESS;
  response: {
    farmpilot_drafts_count: number;
  };
}

export interface FetchFailureAction {
  type: typeof FETCH_REQUEST;
}

export type ActionTypes = FetchRequestAction | FetchSuccessAction | FetchFailureAction;
