import { HIDE_SIDEBAR, SHOW_SIDEBAR } from 'constants/notifications-sidebar';
import { disableScrollOnBody, enableScrollOnBody } from 'shared/utils/disable-body-scroll';
import isMobile from 'shared/utils/is-mobile';

export const showSidebar = (activeSection) => (dispatch) => {
  if (isMobile()) disableScrollOnBody();
  return dispatch({ type: SHOW_SIDEBAR, activeSection });
};

export const hideSidebar = () => (dispatch) => {
  enableScrollOnBody();
  return dispatch({ type: HIDE_SIDEBAR });
};
