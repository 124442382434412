import React, { ReactNode } from 'react';

type IfProps = {
  ok: boolean;
  className?: string;
  children: ReactNode;
};

const If = ({ ok, children, className }: IfProps) => {
  if (!ok) return null;

  if (className) return <div className={className}>{children}</div>;

  return <>{children}</>;
};

export default If;
