import React from 'react';
import cx from 'classnames';
import { upperFirst } from 'lodash';
import { bool, node, number, shape, string } from 'prop-types';

import styles from 'shared/styles/table.module.css';

const Cell = ({
  className,
  children,
  align = 'left',
  index,
  overflow = false,
  headers = {},
  isVerticalOnMobile = false,
  isWithoutHeaderOnMobile = false,
  cellContentClassName = '',
  dataId = '',
}) => (
  <td className={cx(styles[`pullRow${upperFirst(align)}`], className)}>
    <div className={cx(styles.cell, { [styles.cellVerticalOnMobile]: isVerticalOnMobile })}>
      <span
        className={cx(styles.cellHeader, {
          [styles.cellHeaderHiddenOnMobile]: isWithoutHeaderOnMobile,
        })}
      >
        {headers[index]}
      </span>
      <div
        className={cx(
          styles.cellContent,
          {
            [styles.cellContentOverflow]: overflow,
            [styles[`cellAlign${upperFirst(align)}`]]: align,
          },
          cellContentClassName
        )}
        data-id={dataId}
      >
        {children}
      </div>
    </div>
  </td>
);

Cell.propTypes = {
  className: string,
  children: node,
  align: string,
  index: number,
  overflow: bool,
  headers: shape({}),
  isVerticalOnMobile: bool,
  isWithoutHeaderOnMobile: bool,
  cellContentClassName: string,
  dataId: string,
};

export default Cell;
