import 'react-dates-legacy/initialize';

import React, { Component } from 'react';
import {
  DayPickerRangeController,
  FocusedInputShape,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from 'react-dates-legacy';
import moment, { Moment } from 'moment';

import { DATE_FORMAT } from 'constants/datetime';
import { isLgUp, isMdUp } from 'shared/utils/breakpoints';

import DateRangeButtons from './DateRangesButtons';

import 'react-dates-legacy/lib/css/_datepicker.css';

interface Dates {
  startDate: Moment;
  endDate: Moment;
}

interface ControllerProps {
  dateRanges: Array<{
    label: string;
    onClick: () => void;
  }>;
  name: string;
  focusedInput: FocusedInputShape;
  onFocus: () => void;
  setIsOpen: (isOpen: boolean | null) => void;
  // TODO please this this type when containers/Datev/shared/SettingsSection/index.jsx will be rewritten to typescript
  onClose: any;
  dates: Dates;
  // TODO please change this type when actions/incoming-invoices will be rewritten to typescript
  setRangeQueryAction: any;
  isSingleDayAllowed: boolean;
  isPastDateDisabled: boolean;
  isFutureDateDisabled: boolean;
  saveDateToState: (startDate: Moment | null, endDate: Moment | null) => void;
}

class Controller extends Component<ControllerProps> {
  static defaultProps = {
    onFocus: () => {},
    setIsOpen: () => {},
    onClose: () => {},
    isSingleDayAllowed: false,
    isPastDateDisabled: false,
    isFutureDateDisabled: false,
  };

  shouldComponentUpdate(nextProps: ControllerProps) {
    return this.shouldControllerUpdate(this.props, nextProps);
  }

  shouldControllerUpdate = (currentProps: ControllerProps, nextProps: ControllerProps) => {
    const currentStartDateMoment = currentProps.dates.startDate;
    const currentEndDateMoment = currentProps.dates.endDate;
    const newStartDateMoment = nextProps.dates.startDate;
    const newEndDateMoment = nextProps.dates.endDate;

    const getFormattedDate = (date: Moment) => date && date.format(DATE_FORMAT);

    const currentStartDateText = getFormattedDate(currentStartDateMoment);
    const currentEndDateText = getFormattedDate(currentEndDateMoment);
    const newStartDateText = getFormattedDate(newStartDateMoment);
    const newEndDateText = getFormattedDate(newEndDateMoment);

    const didStartDateChanged = currentStartDateText !== newStartDateText;
    const didEndDateChanged = currentEndDateText !== newEndDateText;
    const didFocusInputChanged = currentProps.focusedInput !== nextProps.focusedInput;

    return didStartDateChanged || didEndDateChanged || didFocusInputChanged;
  };

  render() {
    const {
      dateRanges,
      name,
      focusedInput,
      onFocus,
      setIsOpen,
      isSingleDayAllowed,
      dates,
      setRangeQueryAction,
      isPastDateDisabled,
      isFutureDateDisabled,
      saveDateToState,
      onClose,
    } = this.props;

    return (
      <div data-id="searchRangePopper">
        <DayPickerRangeController
          renderCalendarInfo={() => (
            <DateRangeButtons onRangeChange={() => setIsOpen(null)} dateRanges={dateRanges} />
          )}
          minimumNights={isSingleDayAllowed ? 0 : 1}
          startDate={dates.startDate}
          endDate={dates.endDate}
          hideKeyboardShortcutsPanel
          onDatesChange={({ startDate, endDate }) => {
            saveDateToState(startDate, endDate);
            setRangeQueryAction(name)({ startDate, endDate });
          }}
          focusedInput={focusedInput}
          onFocusChange={onFocus}
          isOutsideRange={(day) => {
            if (isPastDateDisabled) {
              return !isInclusivelyAfterDay(day, moment());
            }

            if (isFutureDateDisabled) {
              return !isInclusivelyBeforeDay(day, moment());
            }

            return false;
          }}
          onClose={({ startDate, endDate }) => onClose && onClose(name)({ startDate, endDate })}
          numberOfMonths={2}
          transitionDuration={0}
          orientation={isMdUp() ? 'horizontal' : 'vertical'}
          // TODO use hook when component will be a function
          daySize={isLgUp() ? undefined : 35}
        />
      </div>
    );
  }
}

export default Controller;
