import React from 'react';
import { connect } from 'react-redux';
import snakeCase from 'lodash/snakeCase';
import { number, oneOf, shape, string } from 'prop-types';

import { BASIC_UID, PREMIUM_UID } from 'constants/payment-plans';
import { directDebitSelector } from 'selectors/direct-debit';
import { t } from 'shared/utils';

import styles from './DebitData.module.css';

const DebitData = ({
  debitData: {
    id,
    creditorIdentificationNumber,
    iban,
    createdAt,
    number: directDebitNumber,
    invalidSince,
  } = {},
  plan,
  mandateReference,
}) => {
  const data = {
    creditorIdentificationNumber,
    mandateReference: `${mandateReference}${directDebitNumber}`,
    iban,
    createdAt,
  };

  const isValueHidden = !id || (plan === PREMIUM_UID && !!invalidSince);

  return (
    <div>
      {Object.entries(data).map(([key, value]) => (
        <div className={styles.dataContainer} key={`${key}_${value}`}>
          <span>{t(`profile.payment_plan.debit.${snakeCase(key)}`)}</span>
          <span>{!isValueHidden && value}</span>
        </div>
      ))}
    </div>
  );
};

DebitData.propTypes = {
  debitData: shape({
    creditorIdentificationNumber: string,
    iban: string,
    createdAt: string,
    number: string,
    invalidSince: string,
  }),
  mandateReference: number,
  plan: oneOf([BASIC_UID, PREMIUM_UID]),
};

const mapStateToProps = (state) => ({
  debitData: directDebitSelector(state),
  mandateReference: state.profile.credentials.mandateReference,
  plan: state.paymentPlan.details.uid,
});

export default connect(mapStateToProps)(DebitData);
