import { CATEGORY_TABLE_DEFAULTS } from 'constants/piwik';
import { piwikHelpers } from 'shared/utils/piwik';
import { InvoiceTemplate } from 'types/entities/InvoiceTemplate';

import { FormData } from './types';

export const trackEvent = piwikHelpers.trackEventCurried(CATEGORY_TABLE_DEFAULTS);

export const getDefaultValues = (invoiceTemplate: InvoiceTemplate): FormData => ({
  tableHeaderDescriptionText: invoiceTemplate.tableHeaderDescriptionText,
  tableHeaderNetAmountText: invoiceTemplate.tableHeaderNetAmountText,
  tableHeaderPositionText: invoiceTemplate.tableHeaderPositionText,
  tableHeaderQuantityText: invoiceTemplate.tableHeaderQuantityText,
  tableHeaderTotalLineNetAmountText: invoiceTemplate.tableHeaderTotalLineNetAmountText,
  tableHeaderUnitText: invoiceTemplate.tableHeaderUnitText,
  tableHeaderVatText: invoiceTemplate.tableHeaderVatText,
  tableSummaryGrossTotalText: invoiceTemplate.tableSummaryGrossTotalText,
  tableSummaryNetTotalText: invoiceTemplate.tableSummaryNetTotalText,
});
