import React, { useCallback } from 'react';
import cx from 'classnames';
import { WrappedFieldProps } from 'redux-form';

import { t } from 'shared/utils';
import RadioGroup from 'components/RadioGroup/RadioGroup';
import RadioGroupOption from 'components/RadioGroup/RadioGroupOption/RadioGroupOption';

import styles from './NetGrossField.module.css';

enum Options {
  Net = 'NET',
  Gross = 'GROSS',
}

type NetGrossSwitchProps = WrappedFieldProps & {
  className?: string;
  disabled?: boolean;
};

const NetGrossSwitch = ({ input, disabled, className }: NetGrossSwitchProps) => {
  const { name, value, onChange } = input;

  const handleChange = useCallback(
    (value: string) => {
      onChange(value === Options.Net ? false : true);
    },
    [onChange]
  );

  return (
    <RadioGroup
      name={name}
      value={!!value === false ? Options.Net : Options.Gross}
      onChange={handleChange}
      className={cx(styles.netGrossSwitch, className)}
      isDisabled={disabled}
    >
      <RadioGroupOption
        option={{
          label: t('forms.tooltips.netto'),
          value: Options.Net,
          dataIds: {
            input: 'NetGross:input-net',
            label: 'NetGross:label-net',
          },
        }}
      />
      <RadioGroupOption
        option={{
          label: t('forms.tooltips.gross'),
          value: Options.Gross,
          dataIds: {
            input: 'NetGross:input-gross',
            label: 'NetGross:label-gross',
          },
        }}
      />
    </RadioGroup>
  );
};

export default NetGrossSwitch;
