import { find } from 'lodash';

import { VALIDATE_SUCCESS as VALIDATE_PROFILE_SUCCESS } from 'constants/auth';
import {
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_SUCCESS,
} from 'constants/company';
import {
  FETCH_SUCCESS as FETCH_PROFILE_SUCCESS,
  UPDATE_SUCCESS as UPDATE_PROFILE_SUCCESS,
} from 'constants/profile';

const defaultState = {
  details: {},
  companyForms: [],
  error: false,
  isFetching: false,
};

const fetchFromProfile = ({ response: { included = [] } = {} } = {}) => {
  const { attributes } = find(included, (i) => i.type === 'companies') || {};
  return attributes || {};
};

const companyForms = (state = [], action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
    case UPDATE_SUCCESS:
      return action.response.included
        .filter((i) => i.type === 'company-forms')
        .map((i) => i.attributes);
    default:
      return state;
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        error: false,
        isFetching: true,
      };

    case FETCH_FAILURE:
    case UPDATE_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };

    case FETCH_SUCCESS:
    case UPDATE_SUCCESS:
      return {
        ...defaultState,
        details: {
          ...state.details,
          ...action.response.data.attributes,
        },
        companyForms: companyForms(state.companyForms, action),
      };

    case FETCH_PROFILE_SUCCESS:
    case UPDATE_PROFILE_SUCCESS:
    case VALIDATE_PROFILE_SUCCESS:
      return {
        ...defaultState,
        ...state,
        details: {
          ...fetchFromProfile(action),
        },
      };
    default:
      return state;
  }
};
