import React, { memo, SVGProps } from 'react';

const Dashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3 3.5h8v8H3v-8Zm10 0h8v8h-8v-8Zm-10 10h8v8H3v-8Zm15 0h-2v3h-3v2h3v3h2v-3h3v-2h-3v-3Z"
      fill="#0F2364"
      fillRule="nonzero"
    />
  </svg>
);

export default memo(Dashboard);
