import React from 'react';
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom';
import styled from 'styled-components';

import media from 'shared/styles/breakpoints';
import BaseCardView, { HeadingSection as BaseHeadingSection } from 'components/CardView';
import { Button as TableFilterButton } from 'components/v2/Table/TableFilters/TableFilters.styled';

export const CardView = styled(BaseCardView)`
  min-width: 0;
  min-height: 0;
  margin-bottom: 20px;
  box-shadow: 0 1px 2px 0 rgba(16, 22, 31, 0.25);

  &:last-child {
    margin-bottom: 0;
  }
`;

export const HeadingSection = styled(BaseHeadingSection)`
  display: flex;
  align-items: center;
`;

export const Headline = styled.div`
  margin: 0;
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
  color: #4d4e4c;
  align-self: flex-start;

  ${media.greaterThan('md-up')`
    align-self: center;
  `}
`;

export const Content = styled.div`
  margin: 0 20px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 8px;

    ${media.greaterThan('xlg-up')`
      margin-right: 4px;
    `}

    &:last-child {
      margin-right: 0;
    }
  }
`;

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
type LinkProps = RouterLinkProps;
const isLink = (props: ButtonProps | LinkProps): props is LinkProps => 'to' in props;

const BaseAction = (props: ButtonProps | LinkProps) => {
  if (isLink(props)) {
    return <Link {...(props as LinkProps)} />;
  } else {
    return <button type="button" {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)} />;
  }
};

export const Action = styled(BaseAction)<ButtonProps | LinkProps>`
  outline: none;
  appearance: none;
  background: transparent;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #337ab7;
  padding: 6px;

  &:hover {
    background-color: #0271bb;
    color: #fff;
  }

  svg {
    height: 100%;
    fill: currentColor;
  }

  ${media.greaterThan('xlg-up')`
    width: 20px;
    height: 20px;
    padding: 3px;
  `}
`;

export const CSVExportButton = styled(TableFilterButton)`
  display: flex;
  align-items: center;
  margin-left: 15px;

  > *:first-child {
    margin-right: 5px;
  }

  ${media.greaterThan('md-up')`
    margin-left: auto;
  `}
`;
