import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchClientDefaults as fetchClientDefaultsAction } from 'actions/default-values';
import { t } from 'shared/utils';
import Card from 'components/Card';
import Section from 'components/Form/Section/Section';

import InvoiceEmailsDefaults from './InvoiceEmailsDefaults/InvoiceEmailsDefaults';
import OrderConfirmationEmailsDefaults from './OrderConfirmationEmailsDefaults/OrderConfirmationEmailsDefaults';
import PaymentReminderEmailsDefaults from './PaymentReminderEmailsDefaults/PaymentReminderEmailsDefaults';
import ProposalEmailsDefaults from './ProposalEmailsDefaults/ProposalEmailsDefaults';

import styles from './ClientEmailDefaults.module.css';

export type ClientEmailsFormName =
  | 'ClientInvoiceEmailsDefaults'
  | 'ClientPaymentReminderEmailsDefaults'
  | 'ClientProposalEmailsDefaults'
  | 'ClientOrderConfirmationEmailsDefaults';

interface ClientEmailDefaultsProps {
  fetchClientDefaults: (clientId: string) => void;
  clientId: string;
}

const ClientEmailDefaults: FunctionComponent<ClientEmailDefaultsProps> = ({
  fetchClientDefaults,
  clientId,
}: ClientEmailDefaultsProps): ReactElement => {
  useEffect(() => {
    fetchClientDefaults(clientId);
  }, [fetchClientDefaults, clientId]);

  return (
    <Card className={styles.container}>
      <Card.Header>{t('clients.defaults.tab_email_defaults_header')}</Card.Header>
      <Card.Body className={styles.body}>
        <Section
          className={styles.section}
          title={t('invoice_templates.documents_defaults.invoice_defaults_section')}
        >
          <InvoiceEmailsDefaults clientId={clientId} />
        </Section>
        <Section
          className={styles.section}
          title={t('invoice_templates.documents_defaults.payment_reminder_defaults_section')}
        >
          <PaymentReminderEmailsDefaults clientId={clientId} />
        </Section>
        <Section
          className={styles.section}
          title={t('invoice_templates.documents_defaults.proposal_defaults_section')}
        >
          <ProposalEmailsDefaults clientId={clientId} />
        </Section>
        <Section
          className={styles.section}
          title={t('invoice_templates.documents_defaults.order_confirmation_defaults_section')}
        >
          <OrderConfirmationEmailsDefaults clientId={clientId} />
        </Section>
      </Card.Body>
    </Card>
  );
};

const mapDispatchToProps = {
  fetchClientDefaults: fetchClientDefaultsAction,
};

export default connect(null, mapDispatchToProps)(ClientEmailDefaults);
