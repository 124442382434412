import styled from 'styled-components';

import BaseButton from 'redesign/components/atoms/Button/Button';
import BaseModalBody from 'redesign/components/atoms/ModalBody/ModalBody';

export const ModalBody = styled(BaseModalBody)`
  width: 100%;
  margin: ${({ theme }) => theme.space['5']} auto;
`;

export const ModalDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-bottom: ${({ theme }) => theme.space['12']};
`;

export const ButtonSubmit = styled(BaseButton)`
  padding-left: ${({ theme }) => theme.space['12']};
  padding-right: ${({ theme }) => theme.space['12']};
  float: right;
`;
