export const LOGIN_REQUEST = 'AUTH/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'AUTH/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'AUTH/LOGIN_FAILURE';

export const PRELOGIN_REQUEST = 'AUTH/PRELOGIN_REQUEST';
export const PRELOGIN_SUCCESS = 'AUTH/PRELOGIN_SUCCESS';
export const PRELOGIN_FAILURE = 'AUTH/PRELOGIN_FAILURE';

export const GET_LOGIN_INFO_REQUEST = 'AUTH/GET_LOGIN_INFO_REQUEST';
export const GET_LOGIN_INFO_SUCCESS = 'AUTH/GET_LOGIN_INFO_SUCCESS';
export const GET_LOGIN_INFO_FAILURE = 'AUTH/GET_LOGIN_INFO_FAILURE';

export const LOGOUT_REQUEST = 'AUTH/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'AUTH/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'AUTH/LOGOUT_FAILURE';

export const REGISTER_REQUEST = 'AUTH/REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'AUTH/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'AUTH/REGISTER_FAILURE';

export const VALIDATE_REQUEST = 'AUTH/VALIDATE_REQUEST';
export const VALIDATE_SUCCESS = 'AUTH/VALIDATE_SUCCESS';
export const VALIDATE_FAILURE = 'AUTH/VALIDATE_FAILURE';

export const INVALIDATE_TOKEN = 'AUTH/INVALIDATE_TOKEN';
export const CHANGE_ERROR_DATA = 'AUTH/CHANGE_ERROR_DATA';

export const SET_AFTER_LOGIN_ROUTE = 'AUTH/SET_AFTER_LOGIN_ROUTE';

export const RESEND_CONFIRMATION_MAIL_REQUEST = 'RESEND_CONFIRMATION_MAIL_REQUEST';
export const RESEND_CONFIRMATION_MAIL_SUCCESS = 'RESEND_CONFIRMATION_MAIL_SUCCESS';
export const RESEND_CONFIRMATION_MAIL_FAILURE = 'RESEND_CONFIRMATION_MAIL_FAILURE';

export const CONFIRMATION_BANNER_USERS = 'CONFIRMATION_BANNER_USERS';
