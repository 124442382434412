import { JsonApiResponse } from 'json-api-normalizer';

import api from 'api';

type SendReminderData = {
  subject: string;
  content: string;
  email: string;
  sendCopyToMe: boolean;
  carbonCopy: string;
};

export const sendByEmail = (id: string, data: SendReminderData, grecaptchaResponse: string) => {
  const url = `/me/order_confirmations/${id}/send_by_email`;

  return api.put<JsonApiResponse>(url, {
    email: data.email,
    subject: data.subject,
    content: data.content,
    send_copy_to_me: data.sendCopyToMe,
    cc_email: data.carbonCopy,
    g_recaptcha_response: grecaptchaResponse,
  });
};
