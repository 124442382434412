import React, { memo, SVGProps } from 'react';

const Info = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M.989.065h24v24h-24z" />
      <path
        d="M12.989 2.065c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10Zm1 15h-2v-6h2v6Zm0-8h-2v-2h2v2Z"
        fill="#0066B3"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(Info);
