import React, { Component, createContext } from 'react';
import { node } from 'prop-types';

export interface LineItemCreator {
  id: string;
  creatorFunction: () => Promise<boolean>;
}

interface SaveResponse {
  success: boolean;
}

export type SaveFunction = () => Promise<SaveResponse>;

interface LineItemsAutoSaveContextValue {
  handleSave: (saveFunction: SaveFunction) => () => Promise<SaveResponse>;
  addNewLineItemCreator: (creator: LineItemCreator) => void;
  addEditLineItemCreator: (creator: LineItemCreator) => void;
  removeNewLineItemCreator: (lineItemId: string) => void;
  removeEditLineItemCreator: (lineItemId: string) => void;
  resetNewLineItemCreators: () => void;
  resetEditLineItemCreators: () => void;
}

const LineItemsAutoSaveContext = createContext<LineItemsAutoSaveContextValue>(
  {} as LineItemsAutoSaveContextValue
);

export class LineItemsAutoSaveProvider extends Component {
  static propTypes = {
    children: node,
  };

  state = {
    newLineItemCreators: [],
    editLineItemCreators: [],
  };

  addNewLineItemCreator = ({ id, creatorFunction }: LineItemCreator) =>
    this.setState({
      newLineItemCreators: [...this.state.newLineItemCreators, { id, creatorFunction }],
    });

  addEditLineItemCreator = ({ id, creatorFunction }: LineItemCreator) => {
    this.setState({
      editLineItemCreators: [...this.state.editLineItemCreators, { id, creatorFunction }],
    });
  };

  removeNewLineItemCreator = (lineItemId: string) =>
    this.setState({
      newLineItemCreators: [
        ...this.state.newLineItemCreators.filter(({ id }) => id !== lineItemId),
      ],
    });

  removeEditLineItemCreator = (lineItemId: string) =>
    this.setState({
      editLineItemCreators: [
        ...this.state.editLineItemCreators.filter(({ id }) => id !== lineItemId),
      ],
    });

  resetNewLineItemCreators = () => this.setState({ newLineItemCreators: [] });

  resetEditLineItemCreators = () => this.setState({ editLineItemCreators: [] });

  processNewLineItemCreator = async ({ id, creatorFunction }: LineItemCreator) => {
    const success = await creatorFunction();
    if (success) this.removeNewLineItemCreator(id);
    return success;
  };

  processEditLineItemCreator = async ({ id, creatorFunction }: LineItemCreator) => {
    const success = await creatorFunction();
    if (success) this.removeEditLineItemCreator(id);
    return success;
  };

  handleSave = (saveFunction: SaveFunction) => async () => {
    const { newLineItemCreators, editLineItemCreators } = this.state;
    const newLineItemCreatorPromises = newLineItemCreators.map(this.processNewLineItemCreator);
    const editLineItemCreatorPromises = editLineItemCreators.map(this.processEditLineItemCreator);
    const saveResults = await Promise.all([
      ...newLineItemCreatorPromises,
      ...editLineItemCreatorPromises,
    ]);
    const saveSuccess = saveResults.reduce((accResult, result) => accResult && result, true);

    if (!saveSuccess) return Promise.resolve({ success: false });
    return saveFunction();
  };

  render() {
    const { children } = this.props;
    return (
      <LineItemsAutoSaveContext.Provider
        value={{
          handleSave: this.handleSave,
          addNewLineItemCreator: this.addNewLineItemCreator,
          addEditLineItemCreator: this.addEditLineItemCreator,
          removeNewLineItemCreator: this.removeNewLineItemCreator,
          removeEditLineItemCreator: this.removeEditLineItemCreator,
          resetNewLineItemCreators: this.resetNewLineItemCreators,
          resetEditLineItemCreators: this.resetEditLineItemCreators,
        }}
      >
        {children}
      </LineItemsAutoSaveContext.Provider>
    );
  }
}

export const LineItemsAutoSaveConsumer = LineItemsAutoSaveContext.Consumer;
