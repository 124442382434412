import React from 'react';
import cx from 'classnames';
import { bool, func, shape, string } from 'prop-types';

import Button from 'components/Button';

import styles from './Buttons.module.css';

const Buttons = ({
  backLabel,
  forwardLabel,
  onClose,
  onSubmit,
  disableForward,
  containerClassName,
  dataIds: { backButton = '', forwardButton = '' } = {},
}) => (
  <div className={cx(containerClassName, styles.container)}>
    <Button onClick={onClose} label={backLabel} appearance="outlined" dataId={backButton} />
    <Button
      onClick={onSubmit}
      label={forwardLabel}
      appearance="orange"
      disabled={disableForward}
      dataId={forwardButton}
    />
  </div>
);

Buttons.propTypes = {
  backLabel: string.isRequired,
  forwardLabel: string.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  disableForward: bool,
  containerClassName: string,
  dataIds: shape({
    backButton: string,
    forwardButton: string,
  }),
};

export default Buttons;
