import React, { ReactNode } from 'react';

import { Wrapper } from './TableCell.styled';

export type TableCellProps = {
  align?: 'left' | 'right' | 'center';
  children: ReactNode;
};

const TableCell = ({ align = 'left', children }: TableCellProps) => {
  return (
    <Wrapper align={align}>
      <div>{children}</div>
    </Wrapper>
  );
};

export default TableCell;
