import React, { memo, SVGProps } from 'react';

const Contract = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M14.884 2.59c-.355-.38-.83-.59-1.325-.59H6.867C5.84 2 5 2.9 5 4v16c0 1.1.83 2 1.857 2h11.21c1.026 0 1.866-.9 1.866-2V8.83c0-.53-.196-1.04-.55-1.41l-4.499-4.83zM15.267 18h-5.6c-.514 0-.934-.45-.934-1s.42-1 .934-1h5.6c.513 0 .933.45.933 1s-.42 1-.933 1zm0-4h-5.6c-.514 0-.934-.45-.934-1s.42-1 .934-1h5.6c.513 0 .933.45.933 1s-.42 1-.933 1zM13.4 8V3.5L18.533 9h-4.2c-.513 0-.933-.45-.933-1z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default memo(Contract);
