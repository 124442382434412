import get from 'lodash/get';

import { FETCH_SUCCESS } from 'constants/incoming-invoice';
import { DELETE_SUCCESS, UPLOAD_SUCCESS } from 'constants/incoming-invoice/uploaded-files';
import { extractUploadedInvoiceFiles } from 'reducers/common/uploaded-files';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return extractUploadedInvoiceFiles(action);

    case UPLOAD_SUCCESS: {
      const data = get(action, 'response.data', []);
      return [
        ...state,
        ...data.map((file) => ({
          id: file.id,
          ...file.attributes,
        })),
      ];
    }

    case DELETE_SUCCESS:
      return state.filter((f) => get(f, 'id') !== get(action, 'response.data.attributes.id'));

    default:
      return state;
  }
};
