import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { push as pushAction } from 'connected-react-router';
import { isEmpty } from 'lodash';

import {
  clearFilters as clearFiltersAction,
  deleteClient,
  fetchClients as fetchClientsAction,
  setQueryParam,
  sortClients,
} from 'actions/clients';
import { MODELS } from 'constants/contacts';
import { Resources } from 'constants/resources';
import TableButtons from 'containers/TableButtons/TableButtons';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { hasAnyFiltersActive as hasAnyFiltersActiveChecker } from 'shared/utils/hasAnyFiltersActive';
import { useRefetchOnPropsChange } from 'shared/utils/hooks/useRefetchOnPropsChange';
import CardView, { HeadingSection, Section } from 'components/CardView';
import EmptyEntryPage from 'components/EmptyEntryPage/EmptyEntryPage';
import ClientsEmptyStateImage from 'components/EmptyEntryPage/images/Clients';
import { FiltersGroup } from 'components/Filter/FiltersGroup/FiltersGroup';
import Loading from 'components/Loading';
import { Pagination } from 'components/Pagination/Pagination';

import ClientsTable from './ClientsTable';

import styles from './Clients.module.css';

export interface Client {
  addressAddition?: string;
  bankName?: string;
  bic?: string;
  city?: string;
  companyName?: string;
  country?: string;
  email?: string;
  faxNumber?: string;
  firstName?: string;
  honorific?: string;
  iban?: string;
  id: string;
  idNumber?: string;
  lastName?: string;
  name?: string;
  notes?: string;
  phoneNumber?: string;
  street?: string;
  vatId?: string;
  website?: string;
  zipcode?: string;
}

interface PaginationResource {
  page?: number;
  perPage?: number;
  totalPages?: number;
  pagination_resource?: string;
}

interface SortingResource {
  column: string;
  direction: string;
}

interface ClientsDispatchProps {
  fetchClients: (
    pagination: Partial<PaginationResource>,
    sorting?: SortingResource,
    filters?: any
  ) => Promise<any>;
  remove: (client: Client) => Promise<any>;
  sort: (column: string) => void;
  setQueryAction: (name: any) => (dispatch?: any) => (value: string) => void;
  clearFilters: () => void;
  push: (path: string) => void;
}

interface ClientsStateProps {
  pagination: PaginationResource;
  data: Client[];
  sorting: SortingResource;
  isFetching: boolean;
  filters?: any;
}

interface ClientsProps extends ClientsDispatchProps, ClientsStateProps {}

const Clients = ({
  fetchClients,
  remove,
  sort,
  setQueryAction,
  clearFilters,
  push,
  pagination,
  data,
  sorting,
  isFetching,
  filters = {},
}: ClientsProps) => {
  /**
   * This flag is initially set to false and is set to true after first fetch
   * for clients comes back. It is used for displaying the initial loader.
   */
  const [areClientsFetched, setAreClientsFetched] = useState(false);

  const fetch = useCallback(
    (pagination: { page: number } = { page: 1 }) => {
      fetchClients({ pagination_resource: Resources.CLIENTS, ...pagination }, sorting, filters);
    },
    [fetchClients, sorting, filters]
  );

  useEffect(() => {
    return () => clearFilters();
  }, [clearFilters]);

  useRefetchOnPropsChange({
    fetchFunction: async ({
      pagination: newPagination,
      sorting: newSorting,
      overwrittenValues,
      parsedFilters,
    }) => {
      await fetchClients(
        { pagination_resource: Resources.CLIENTS, ...newPagination, ...overwrittenValues },
        newSorting,
        parsedFilters
      );
      setAreClientsFetched(true);
    },
    props: { parsedFilters: filters, pagination, sorting },
  });

  if (!areClientsFetched) {
    return (
      <div className={styles.page}>
        <div>
          <Loading />
        </div>
      </div>
    );
  }

  const areClientsEmpty = isEmpty(data);
  const hasAnyFiltersActive = hasAnyFiltersActiveChecker(filters);
  const isEmptyStateVisible = !isFetching && areClientsEmpty && !hasAnyFiltersActive;

  if (isEmptyStateVisible) {
    return (
      <div className={styles.page}>
        <EmptyEntryPage
          dataIdPrefix="Clients"
          Image={ClientsEmptyStateImage}
          header={t('empty_entry_pages.clients.header')}
          subheader={t('empty_entry_pages.clients.subheader')}
          info={[
            t('empty_entry_pages.clients.info.row_1'),
            t('empty_entry_pages.clients.info.row_2'),
          ]}
          createButtonLabel={t('empty_entry_pages.clients.button_add_label')}
          onCreateButtonClick={() => push(paths.clientsNew)}
          importButtonLabel={t('empty_entry_pages.clients.button_import_label')}
          onImportButtonClick={() => push(paths.clientsImport)}
        />
      </div>
    );
  }

  return (
    <div className={styles.page}>
      <div className={styles.defaultsLinkContainer}>
        <Link
          role="button"
          tabIndex={0}
          className={styles.defaultsLink}
          to={paths.settingsTemplateDocumentsDefaults}
        >
          {t('clients.defaults.link_to_document_defaults')}
        </Link>
      </div>
      <CardView className={styles.card}>
        <HeadingSection>
          <div className={styles.headingText}>{t('clients.subtitle')}</div>
          <div
            className={cx(styles.pullRight, styles.links, {
              [styles.hiddenSection]: areClientsEmpty && !isFetching && !hasAnyFiltersActive,
            })}
          >
            <TableButtons whichFolder={MODELS.CLIENT as 'client'} />
          </div>
          <FiltersGroup
            className={styles.filtersGroup}
            isResetButtonDisabled={!hasAnyFiltersActive}
            filters={filters}
            onFiltersChange={({ name, value }) => setQueryAction(name)(value)}
            onFiltersReset={clearFilters}
            placeholder={t('tables.filters.filters_group.clients.placeholder')}
            dataIds={{
              input: 'Clients:input-search',
            }}
          />
        </HeadingSection>
        <Section className={styles.section}>
          {areClientsEmpty && hasAnyFiltersActive && !isFetching ? (
            <div className={styles.emptyTableMessage} data-id="Clients:table-empty-info">
              {t('tables.empty_clients')}
            </div>
          ) : (
            <ClientsTable
              data={data}
              sorting={sorting}
              isFetching={isFetching}
              refresh={fetch}
              remove={remove}
              sort={sort}
            />
          )}
        </Section>
        <Section
          className={cx(styles.section, styles.pagination, {
            [styles.hiddenSection]: areClientsEmpty,
          })}
        >
          <Pagination
            {...pagination}
            request={fetch}
            isFetching={isFetching}
            resource={Resources.CLIENTS}
          />
        </Section>
      </CardView>
    </div>
  );
};

export default connect(
  (state: any) => ({
    pagination: state.clients.pagination,
    data: state.clients.data,
    sorting: state.clients.sorting,
    isFetching: state.clients.isFetching,
    filters: state.clients.filters,
  }),
  {
    fetchClients: fetchClientsAction,
    remove: deleteClient,
    sort: sortClients,
    setQueryAction: setQueryParam,
    clearFilters: clearFiltersAction,
    push: pushAction,
  }
)(Clients);
