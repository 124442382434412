import React from 'react';
import cx from 'classnames';
import { bool, number, string } from 'prop-types';

import { DatevTransfersStatuses, InvoiceStatuses } from 'constants/statuses';
import If from 'components/Conditions/If';
import I18n from 'components/I18n';
import AppUploadStatusIcon from 'components/StatusIcons/Invoice/AppUploadStatusIcon';
import CancelledStatusIcon from 'components/StatusIcons/Invoice/CancelledStatusIcon';
import DraftStatusIcon from 'components/StatusIcons/Invoice/DraftStatusIcon';
import FullPaymentInitiatedStatusIcon from 'components/StatusIcons/Invoice/FullPaymentInitiatedStatusIcon';
import OverdueStatusIcon from 'components/StatusIcons/Invoice/OverdueStatusIcon';
import PaidStatusIcon from 'components/StatusIcons/Invoice/PaidStatusIcon';
import PartlyPaidStatusIcon from 'components/StatusIcons/Invoice/PartlyPaidStatusIcon';
import PartPaymentInitiatedStatusIcon from 'components/StatusIcons/Invoice/PartPaymentInitiatedStatusIcon';
import RemindedStatusIcon from 'components/StatusIcons/Invoice/RemindedStatusIcon';
import SuggestedStatusIcon from 'components/StatusIcons/Invoice/SuggestedStatusIcon';
import UnpaidStatusIcon from 'components/StatusIcons/Invoice/UnpaidStatusIcon';

import styles from './InvoiceStatusBadge.module.css';

const getInvoiceStatusIcon = (status) => {
  switch (status) {
    case InvoiceStatuses.PAYMENT_REMINDED:
    case InvoiceStatuses.NO_FILE:
    case DatevTransfersStatuses.INVALID:
      return <RemindedStatusIcon />;
    case InvoiceStatuses.SENT:
    case InvoiceStatuses.PAID:
    case InvoiceStatuses.HAS_FILE:
    case DatevTransfersStatuses.VALID:
      return <PaidStatusIcon />;
    case InvoiceStatuses.OPEN:
      return <UnpaidStatusIcon />;
    case InvoiceStatuses.PARTLY_PAID:
      return <PartlyPaidStatusIcon />;
    case InvoiceStatuses.OVERDUE:
      return <OverdueStatusIcon />;
    case InvoiceStatuses.CANCELLED:
      return <CancelledStatusIcon />;
    case InvoiceStatuses.DRAFT:
      return <DraftStatusIcon />;
    case InvoiceStatuses.APP_UPLOAD:
      return <AppUploadStatusIcon />;
    case InvoiceStatuses.SUGGESTED:
      return <SuggestedStatusIcon />;
    case InvoiceStatuses.PART_PAYMENT_INITIATED:
      return <PartPaymentInitiatedStatusIcon />;
    case InvoiceStatuses.FULL_PAYMENT_INITIATED:
      return <FullPaymentInitiatedStatusIcon />;
    default:
      return null;
  }
};

const InvoiceStatusBadge = ({
  customLabel = '',
  isIncomingInvoice = false,
  status,
  remindersCount = 0,
  dataId = 'InvoiceStatus',
}) => {
  if (!status) return null;
  // For draft incoming invoice change status to appupload
  const statusToDisplay =
    status === InvoiceStatuses.DRAFT && isIncomingInvoice ? InvoiceStatuses.APP_UPLOAD : status;

  return (
    <div className={cx(styles.status, styles[`status-${statusToDisplay}`])}>
      {getInvoiceStatusIcon(statusToDisplay)}
      <span className={styles.statusText} data-id={dataId}>
        {customLabel || <I18n t={`revenue.table.values.status.${statusToDisplay}`} />}
        <If ok={status === InvoiceStatuses.PAYMENT_REMINDED}>
          <span>&nbsp;({remindersCount})</span>
        </If>
      </span>
    </div>
  );
};

InvoiceStatusBadge.propTypes = {
  customLabel: string,
  isIncomingInvoice: bool,
  status: string.isRequired,
  remindersCount: number,
  dataId: string,
};

export default InvoiceStatusBadge;
