import styled from 'styled-components';

import media from 'shared/styles/breakpoints';
import Card from 'components/Card';

export const Wrapper = styled(Card)`
  padding: 20px;

  ${media.lessThan('lg-up')`
    width: 100%
  `}
`;

export const Headline = styled.span`
  font-weight: bold;
`;
