import { FETCH_SUCCESS, FETCH_SUMS_SUCCESS } from 'constants/delivery-note';

const defaultState = {
  totalNetAmount: 0,
  totalVatAmount: 0,
  totalGrossAmount: 0,
};

export default (state = defaultState, action) => {
  if (action.type !== FETCH_SUCCESS && action.type !== FETCH_SUMS_SUCCESS) return state;

  return {
    currency: action.response.data.attributes.currency,
    totalNetAmount: action.response.data.attributes.totalNetAmount,
    totalVatAmount: action.response.data.attributes.totalVatAmount,
    totalGrossAmount: action.response.data.attributes.totalGrossAmount,
  };
};
