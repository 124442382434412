import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';

import { showNotification } from 'actions/notification';
import { deleteMe } from 'api/me/customers';
import validationNotification from 'notifications/validation';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import {
  mapServerErrorsToFieldErrors,
  mapServerErrorsToNotificationError,
} from 'shared/utils/server-validation';
import { FieldsGroup, PasswordField, SubmitButton } from 'components/Form';
import FormField from 'components/Form/FormField';

import Page from '../shared/Page/Page';

type FormData = { password: string };

type RegistrationsDeletionProps = InjectedFormProps<FormData>;

const RegistrationsDeletion = ({ handleSubmit }: RegistrationsDeletionProps) => {
  const dispatch = useDispatch();
  const { token } = useParams<{ token: string }>();
  const history = useHistory();

  const onSubmit = useCallback(async (values: FormData) => {
    let response;

    try {
      response = await deleteMe(token, values.password);
    } catch ({ response }) {
      const notification = validationNotification(
        mapServerErrorsToNotificationError((response as any).data.errors)
      );

      dispatch(showNotification(notification));
      throw new SubmissionError(mapServerErrorsToFieldErrors((response as any).data.errors));
    }

    dispatch(
      showNotification({
        duration: 30000,
        text: response.data,
        title: t('customer_deletion.confirmation.success.title'),
        variant: 'success',
      })
    );
    history.push(paths.login);
  }, []);

  return (
    <Page header={t('customer_deletion.confirmation.title')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldsGroup>
          <p>{t('customer_deletion.confirmation.info')}</p>
          <FormField
            name="password"
            dataId="password"
            placeholder={t('customer_deletion.confirmation.password')}
            component={PasswordField}
            required
          />
        </FieldsGroup>
        <FieldsGroup>
          <SubmitButton
            active
            name="submit"
            dataId="submit"
            value={t('customer_deletion.confirmation.submit')}
          />
        </FieldsGroup>
      </form>
    </Page>
  );
};

export default reduxForm<FormData>({
  form: 'registrationsDeletion',
})(RegistrationsDeletion);
