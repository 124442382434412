import { get } from 'lodash';

import { isTest } from 'shared/utils/environment';
import { logger } from 'shared/utils/logger';

interface Error {
  route: string;
  message: string;
  actionType: string;
  response: object;
}

export const apiErrorHandler = <T>(
  error: T,
  { shouldThrow } = { shouldThrow: false }
): Promise<T> => {
  if (isTest) return Promise.resolve(error);
  logger.warn(`
API Error occured!
[route]:       ${get(error, 'route')}
[message]:     ${get(error, 'message')}
[action type]: ${get(error, 'actionType')}
  `);

  if (shouldThrow) {
    return Promise.reject(error);
  }

  return Promise.resolve(error);
};

export const isNetworkError = (error: Error): boolean => error && error.response === undefined;
