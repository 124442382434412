import React from 'react';
import cx from 'classnames';
import { func, shape, string } from 'prop-types';

import { t } from 'shared/utils';
import If from 'components/Conditions/If';

import ImagePreview from './ImagePreview/ImagePreview';

import styles from './Preview.module.css';

const imageExtensions = ['jpg', 'png'];
const unavailableExtensions = ['tif', 'tiff'];

const Preview = ({
  file,
  notifyRenderFinish,
  imageClassName = '',
  dataId = '',
  className = '',
}) => {
  const { extension, url } = file || {};

  return (
    <div data-id={dataId} className={cx(styles.main, className)}>
      <If ok={imageExtensions.includes(extension)}>
        <ImagePreview
          imageClassName={imageClassName}
          notifyRenderFinish={notifyRenderFinish}
          url={url}
        />
      </If>
      <If ok={unavailableExtensions.includes(extension)}>
        <div className={cx(styles.preview, styles.unavailable)} />
      </If>

      {unavailableExtensions.includes(extension) && (
        <p className={styles.previewUnavailable}>
          {t('expenses.upload.actions.overlay_unavailable')}
        </p>
      )}
    </div>
  );
};

Preview.propTypes = {
  notifyRenderFinish: func.isRequired,
  file: shape({
    extension: string.isRequired,
    id: string,
    url: string.isRequired,
  }),
  imageClassName: string,
  dataId: string,
  className: string,
};

export default Preview;
