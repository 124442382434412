import { find, omit, uniqBy } from 'lodash';
import { combineReducers } from 'redux';

import { FETCH_TRANSFERS_SUCCESS } from 'constants/bank-account';
import {
  INDEX_FAILURE,
  INDEX_MORE_FAILURE,
  INDEX_MORE_REQUEST,
  INDEX_MORE_SUCCESS,
  INDEX_REQUEST,
  INDEX_RESET,
  INDEX_SORT,
  INDEX_SUCCESS,
  NAMESPACE,
  PAGINATION,
} from 'constants/incoming-invoices';
import filters, { parsedFiltersReducer } from 'reducers/common/filters';
import { getPaginationReducer } from 'reducers/pagination';
import { mapSupplier } from 'reducers/suppliers';
import { createFilteredReducer } from 'shared/utils';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case INDEX_REQUEST:
    case INDEX_MORE_REQUEST:
      return true;
    case INDEX_SUCCESS:
    case INDEX_FAILURE:
    case INDEX_MORE_SUCCESS:
    case INDEX_MORE_FAILURE:
      return false;
    default:
      return state;
  }
};

const isDirty = (state = false, action) => {
  switch (action.type) {
    case INDEX_SUCCESS:
    case INDEX_MORE_SUCCESS:
    case FETCH_TRANSFERS_SUCCESS:
      return true;
    case INDEX_RESET:
      return false;
    default:
      return state;
  }
};

const getRelationship = (data, included, type, mapper) => {
  const relationshipData = data.relationships[type];
  if (!relationshipData || !relationshipData.data) return undefined;
  const relationshipId = relationshipData.data.id;
  const relationshipType = relationshipData.data.type;

  const relationship = find(
    included,
    (obj) => relationshipType === obj.type && relationshipId === obj.id
  );
  if (relationship) return mapper(relationship);
  return undefined;
};

export const mapIncomingInvoice = (included) => (data) => ({
  id: data.id,
  creationType: data.attributes.creationType,
  invoiceDate: data.attributes.invoiceDate,
  dueDate: data.attributes.dueDate,
  totalNetAmount: data.attributes.totalNetAmount,
  totalGrossAmount: data.attributes.totalGrossAmount,
  relationships: {
    supplier: getRelationship(data, included, 'supplier', mapSupplier),
    persistedSupplier: getRelationship(data, included, 'persisted-supplier', mapSupplier),
  },
  meta: data.meta,
  ...omit(data.attributes, [
    'creation-type',
    'invoiceDate',
    'dueDate',
    'total-net-amount',
    'total-gross-amount',
  ]),
});

const data = (state = [], action) => {
  switch (action.type) {
    case INDEX_SUCCESS: {
      const { response } = action;
      return response.data.map(mapIncomingInvoice(response.included));
    }
    case INDEX_MORE_SUCCESS: {
      const { response } = action;
      return uniqBy([...state, ...response.data.map(mapIncomingInvoice(response.included))], 'id');
    }
    case INDEX_RESET: {
      return [{ isReseted: true }];
    }
    case FETCH_TRANSFERS_SUCCESS: {
      const includedInvoices = action.response.included || [];
      const includedIncoming = includedInvoices.filter((i) => i.type === 'incoming-invoices');
      return uniqBy(
        [
          ...state,
          ...includedIncoming.map((i) => ({
            id: i.id,
            ...i.attributes,
          })),
        ],
        'id'
      );
    }
    default:
      return state;
  }
};

const pagination = getPaginationReducer([PAGINATION, INDEX_SUCCESS, INDEX_MORE_SUCCESS]);

const defaultSortingState = {
  column: 'invoice_date',
  direction: 'DESC',
};

export const sorting = (state = defaultSortingState, action) => {
  switch (action.type) {
    case INDEX_SORT: {
      let direction = 'ASC';
      if (state.column === action.column && state.direction === 'ASC') direction = 'DESC';

      return {
        column: action.column,
        direction,
      };
    }
    default:
      return state;
  }
};

export const invoicesCounters = (state = {}, action) => {
  switch (action.type) {
    case INDEX_SUCCESS:
      return action.response.meta.quickfilter_counters;
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  isDirty,
  filters: createFilteredReducer(filters, (action) => action.name === NAMESPACE),
  parsedFilters: createFilteredReducer(parsedFiltersReducer, (action) => action.name === NAMESPACE),
  data,
  pagination,
  sorting,
  invoicesCounters,
});
