import React from 'react';

import { t } from 'shared/utils';
import IconCheck from 'redesign/components/atoms/Icons/Check';

import { BoldParagraph, Logo, Paragraph, ReturnButton, TextWrapper } from './SuccessModal.styled';

type SuccessModalProps = {
  resetDownload: () => void;
};

const SuccessModal = ({ resetDownload }: SuccessModalProps) => (
  <>
    <Logo icon={IconCheck} color="green" size="medium" />
    <TextWrapper>
      <BoldParagraph>{t('datev.download.success.download_started')}</BoldParagraph>
      <Paragraph>{t('datev.download.success.check_folder')}</Paragraph>
    </TextWrapper>
    <ReturnButton active value={t('datev.download.success.return')} inputHandler={resetDownload} />
  </>
);

export default SuccessModal;
