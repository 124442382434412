import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import Modal, { ModalHeader } from 'components/Modal';
import PDFViewer from 'components/PDFViewer';

import styles from './PreviewModal.module.css';

class PreviewModal extends Component {
  state = {
    preview: null,
  };

  async componentDidUpdate({ isOpen: wasOpen }) {
    const { isOpen } = this.props;

    if (isOpen && !wasOpen) await this.downloadPreview();
  }

  async downloadPreview() {
    this.setState({ preview: null });

    const preview = await this.props.getPreview();

    this.setState({ preview });
  }

  render() {
    const { title, isOpen, onClose, children } = this.props;
    const { preview } = this.state;

    return (
      <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.root}>
        <ModalHeader>{title}</ModalHeader>
        <div className={styles.contentInner}>
          {preview && <PDFViewer file={preview} />}
          <div className={styles.buttons}>
            <ActionButton
              appearance="outlined"
              onClick={onClose}
              label={t('modals.preview_modal.close')}
            />
            {children}
          </div>
        </div>
      </Modal>
    );
  }
}

PreviewModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  getPreview: PropTypes.func.isRequired,
};

export default PreviewModal;
