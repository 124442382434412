import React, { Fragment } from 'react';
import { isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import { arrayOf, bool, shape } from 'prop-types';

import SubInvoiceRow from './components/SubInvoiceRow';

import styles from './SubInvoices.module.css';

const DisplayPaymentReminders = (invoice = {}, isEven) =>
  orderBy(invoice.paymentReminders, (payment) => moment(payment.sentAt).unix(), ['desc']).map(
    (paymentReminder, index) => {
      const paymentReminderIndex = invoice.paymentReminders.length - index;
      return (
        <SubInvoiceRow
          invoice={invoice}
          key={`${paymentReminder.id}${index}${paymentReminder.sentAt}`}
          isPaymentReminder
          paymentReminder={paymentReminder}
          paymentReminderIndex={paymentReminderIndex}
          isEven={isEven}
        />
      );
    }
  );

const SubInvoices = ({ invoices, isIncomingInvoice = false, parentInvoice = {}, isEven }) => (
  <table className={styles.table}>
    <tbody>
      {!!parentInvoice.paymentRemindersCount && DisplayPaymentReminders(parentInvoice, isEven)}
      {!isEmpty(invoices) &&
        invoices.map((invoice, index) => (
          <Fragment key={index}>
            <SubInvoiceRow
              invoice={invoice}
              key={`${invoice.id}${index}${invoice}`}
              isIncomingInvoice={isIncomingInvoice}
              isEven={isEven}
            />
            {!!invoice.paymentRemindersCount && DisplayPaymentReminders(invoice, isEven)}
          </Fragment>
        ))}
    </tbody>
  </table>
);

SubInvoices.propTypes = {
  isEven: bool.isRequired,
  isIncomingInvoice: bool,
  parentInvoice: shape({
    meta: shape({
      actions: shape({
        cancel: bool,
        correct: bool,
        delete: bool,
        edit: bool,
        editPaymentInformation: bool,
        show: bool,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  invoices: arrayOf(
    shape({
      meta: shape({
        actions: shape({
          cancel: bool,
          correct: bool,
          delete: bool,
          edit: bool,
          editPaymentInformation: bool,
          show: bool,
        }).isRequired,
      }).isRequired,
    }).isRequired
  ),
};

export default SubInvoices;
