import styled from 'styled-components';

import BaseButton from 'redesign/components/atoms/Button/Button';
import BasePictureWelcome from 'redesign/components/atoms/Pictures/Welcome';
import { Breakpoints } from 'redesign/styles/breakpoints';

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.space['4']};
  padding: ${({ theme }) => `${theme.space['2']} ${theme.space['4']} ${theme.space['6']}`};
`;

export const Content = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space['2']};
  grid-template-areas: 'picture' 'title' 'text';
  justify-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey['080']};

  @media (min-width: ${Breakpoints.md}) {
    grid-gap: ${({ theme }) => theme.space['3']};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'title picture' 'text picture';
    text-align: left;
    justify-items: start;
    align-items: start;
  }
`;

export const PictureWelcome = styled(BasePictureWelcome)`
  max-width: 377px;
  grid-area: picture;

  @media (min-width: ${Breakpoints.md}) {
    justify-self: end;
  }
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 24px;
  grid-area: title;

  @media (min-width: ${Breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSize['2xl']};
    letter-spacing: 0.6px;
    line-height: 29px;
  }
`;

export const Text = styled.div`
  grid-area: text;
  max-width: 514px;

  p {
    font-size: ${({ theme }) => theme.fontSize.base};
    letter-spacing: 0.25px;
    line-height: 24px;
    margin-bottom: ${({ theme }) => theme.space['4']};

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  span {
    display: block;
    color: ${({ theme }) => theme.colors.grey['070']};
    font-size: ${({ theme }) => theme.fontSize.sm};
    letter-spacing: 0.25px;
    line-height: 17px;
  }
`;

export const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: -${({ theme }) => theme.space['2']};

  label {
    font-weight: normal;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.grey['070']};
    margin: 0 ${({ theme }) => theme.space['4']} 0 0;
    cursor: pointer;

    > div {
      margin-right: ${({ theme }) => theme.space['2']};
    }
  }

  > svg:last-child {
    color: ${({ theme }) => theme.colors.vrblue['100']};
    cursor: pointer;
  }
`;

export const Button = styled(BaseButton)`
  margin-top: ${({ theme }) => theme.space['6']};
  margin-bottom: ${({ theme }) => theme.space['4']};
`;
