import React, { FunctionComponent } from 'react';

import { MODAL_HEADER_VARIANT } from 'constants/modal';
import { isTest } from 'shared/utils/environment';
import Modal, { ModalHeader } from 'components/Modal';
import OBSCancelPopup from 'features/financialPlanning/obs/OBSCancelPopup/OBSCancelPopup';

import styles from './OBSIframeModal.module.css';

interface OBSIframeModalProps {
  isOpen: boolean;
  isCanceled: boolean;
  onCancel: () => void;
  onTriggerCancelPopup: () => void;
  dataIds: {
    container: string;
    closeButton: string;
  };
}

/**
 * Show OBS iframe in modal
 * Hide iframe for testing env (enzyme can't render iframe correctly)
 */
const OBSIframeModal: FunctionComponent<OBSIframeModalProps> = ({
  isOpen,
  onTriggerCancelPopup,
  dataIds,
  isCanceled,
  onCancel,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onTriggerCancelPopup}
    contentLabel="OBS iFrame Modal"
    className={styles.modal}
  >
    <div data-id={dataIds.container} className={styles.container}>
      <ModalHeader
        dataIds={{ closeButton: dataIds.closeButton }}
        withCloseButton
        onClose={onTriggerCancelPopup}
        headerVariant={MODAL_HEADER_VARIANT.SMALL}
      />
      {isCanceled && (
        <OBSCancelPopup isOpen={isCanceled} onConfirm={onCancel} onClose={onTriggerCancelPopup} />
      )}
      {!isTest && (
        <iframe
          title="OBS"
          src="https://onlinefinanzierung.vr-smart-finanz.de/#/partner?partnerId=88888888&domain=www.vr-smart-guide.de&referrer=vr_smart_guide"
          className={styles.iframe}
        />
      )}
    </div>
  </Modal>
);

export default OBSIframeModal;
