export const existChecker = (meta) => {
  if (!meta.error) {
    return false;
  }
  if (!meta.touched) {
    return false;
  }
  return true;
};

export const wantedErrorChecker = (meta, errors) =>
  meta.error.find((e) => errors.includes(e.type)) || null;

export const multipleAnyChecker = (checkers, props) => checkers.some((c) => c(props));

export const checkLength = (string) => {
  if (string) {
    return string.length;
  }
  return 0;
};

export const serverValidationChecker = ({ meta }) => !!meta.error;
