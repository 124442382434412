import React from 'react';
import cx from 'classnames';

import styles from '../StatusIcons.module.css';

const OverdueStatusIcon = () => (
  <svg viewBox="0 0 20 20" className={styles.statusIcon} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <rect
        className={cx(styles.strokeDanger, styles.fillDanger)}
        x=".5"
        y=".5"
        width="19"
        height="19"
        rx="2"
      />
      <path
        d="M17 6.492L14.01 4l-.838.988 2.99 2.491.838-.987zM7.822 4.988L6.99 4 4 6.485l.839.988 2.983-2.485zm3.003 2.975H9.85v3.873l3.087 1.84.488-.794-2.6-1.53V7.963zM10.5 5.381c-3.23 0-5.85 2.602-5.85 5.81C4.65 14.399 7.263 17 10.5 17c3.23 0 5.85-2.601 5.85-5.81 0-3.207-2.62-5.809-5.85-5.809zm0 10.328c-2.515 0-4.55-2.02-4.55-4.518 0-2.498 2.034-4.519 4.55-4.519 2.515 0 4.55 2.02 4.55 4.519 0 2.498-2.035 4.518-4.55 4.518z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default OverdueStatusIcon;
