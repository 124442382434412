import React from 'react';

import { t } from 'shared/utils';
import EmptyEntryPage from 'components/EmptyEntryPage/EmptyEntryPage';
import PlannerEmptyStateImage from 'components/EmptyEntryPage/images/Planner';

import styles from './EmptyState.module.css';

const EmptyState = () => (
  <EmptyEntryPage
    customClasses={{ ...styles }}
    dataIdPrefix="Planner"
    Image={PlannerEmptyStateImage}
    header={t('empty_entry_pages.planner.header')}
    subheader={t('empty_entry_pages.planner.subheader')}
    info={[
      t('empty_entry_pages.planner.info.row_1'),
      t('empty_entry_pages.planner.info.row_2'),
      t('empty_entry_pages.planner.info.row_3'),
      t('empty_entry_pages.planner.info.row_4'),
      t('empty_entry_pages.planner.info.row_5'),
    ]}
  />
);

export default EmptyState;
