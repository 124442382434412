import moment from 'moment';

import taxationTypes from 'constants/taxation-types';
import { t } from 'shared/utils/index';

import { Option } from './types';

export const taxationTypeOptions = taxationTypes.map((taxationType) => ({
  value: taxationType,
  label: t(`profile.tax_consultant.taxation_types.${taxationType}`),
}));

export const getCurrentOption = (currentOption: string, options: Option[]) =>
  options.find(({ value }) => value === currentOption);

export const chartsOfAccountsOptions = [
  { value: 'skr_03', label: 'SKR03' },
  { value: 'skr_04', label: 'SKR04' },
];

export const exportTimePeriodOptions = ['month', 'quarter', 'year'].map((value) => ({
  label: t(`profile.tax_consultant.export_info.${value}`),
  value,
}));

export const itemToString = (item: Option | null) => (item ? item.label : '');

export const getDefaultValues = () => ({
  smallEntrepreneur: false,
  taxationExtension: false,
  fiscalYearBeginning: moment().startOf('month'),
  taxationType: taxationTypeOptions[0],
  taxationInterval: exportTimePeriodOptions[0],
  accountChart: chartsOfAccountsOptions[0],
  taxConsultantName: '',
  taxConsultantId: '',
  taxConsultantClientId: '',
  taxConsultantPhone: '',
  taxConsultantEmail: '',
  exportPassword: '',
});
