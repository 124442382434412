import React, { FunctionComponent } from 'react';
import InlineSvg from 'react-inlinesvg';
import { values } from 'lodash';

import { MODAL_HEADER_VARIANT } from 'constants/modal';
import obsBanner from 'images/obs-modal/banner.png';
import checkmarkIcon from 'images/obs-modal/list-checkmark.svg';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import { ButtonAppearances } from 'components/Button';
import Modal, { ModalHeader } from 'components/Modal';

import styles from './OBSModal.module.css';

type OBSModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  dataIds?: {
    container: string;
    acceptButton: string;
    closeButton: string;
  };
};

const featuresItems = values(
  t('dashboard.liquidity.apply_for_funding.modal.feature_items') as unknown as {
    text: string;
    option_text: string;
  }[]
);

const optionItems = values(
  t('dashboard.liquidity.apply_for_funding.modal.option_items') as unknown as {
    text: string;
  }[]
);

const OBSModal: FunctionComponent<OBSModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  dataIds = {
    container: '',
    acceptButton: '',
    closeButton: '',
  },
}) => {
  return (
    <Modal isOpen={isOpen} contentLabel="Modal">
      <ModalHeader withCloseButton onClose={onClose} headerVariant={MODAL_HEADER_VARIANT.SMALL}>
        {t('dashboard.liquidity.apply_for_funding.modal.header')}
      </ModalHeader>
      <div className={styles.modal}>
        <div className={styles.scrollableArea}>
          <img alt="Corona Banner" src={obsBanner} className={styles.obsBanner} />
          <div className={styles.contentContainer} data-id={dataIds.container}>
            <div className={styles.title}>
              {t('dashboard.liquidity.apply_for_funding.modal.title')}
            </div>
            <div className={styles.subtitle}>
              {t('dashboard.liquidity.apply_for_funding.modal.subtitle')}
            </div>
            <div className={styles.featureItems}>
              {featuresItems.map(({ text }, index) => (
                <div className={styles.featureItem} key={index}>
                  <div>
                    <InlineSvg src={checkmarkIcon} className={styles.featureItemIcon} />
                  </div>
                  <div className={styles.featureItemText}>
                    <p>{text}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.optionItems}>
              <div className={styles.optionsTitle}>
                {t('dashboard.liquidity.apply_for_funding.modal.options_title')}
              </div>
              {optionItems.map(({ text }, index) => {
                return (
                  <div key={index}>
                    <ul>
                      <li>
                        <div className={styles.featureItemText}>
                          <p>{text}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                );
              })}
              <div className={styles.hint}>
                {t('dashboard.liquidity.apply_for_funding.modal.hint')}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.buttonsContainer}>
            <ActionButton
              className={styles.actionButton}
              appearance={ButtonAppearances.outlined}
              onClick={onClose}
              dataId={dataIds.closeButton}
              label={t('dashboard.liquidity.apply_for_funding.modal.cancel_button')}
            />
            <ActionButton
              className={styles.actionButton}
              appearance={ButtonAppearances.primary}
              onClick={onConfirm}
              label={t('dashboard.liquidity.apply_for_funding.modal.count_now_button')}
              dataId={dataIds.acceptButton}
            />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.textContainerHeader}>
              <strong>
                {t('dashboard.liquidity.apply_for_funding.modal.forwarding_hint_title')}
              </strong>
            </div>
            {t('dashboard.liquidity.apply_for_funding.modal.forwarding_hint_text')}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OBSModal;
