import { Interval } from '../ExpensesRevenues/constants';

export enum Category {
  Rent = 'rent',
}

export type RecurringRevenue = {
  name: string;
  interval: Interval;
  amount: string;
};

export type OtherRevenue = {
  name: string;
  amount: string;
};
