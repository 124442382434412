import { Preset } from 'types/entities/InvoiceTemplate';

import { FormData } from './types';

export const Options = [
  { value: 1, label: 'Layout 1 (Standard)' },
  { value: 2, label: 'Layout 2' },
  { value: 3, label: 'Layout 3' },
];

const FontSizeOptions = [6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24].map((size) => ({
  value: size,
  label: `${size}`,
}));

export const FontSizeOptions6To10 = FontSizeOptions.slice(0, 5);

export const FontSizeOptions6To12 = FontSizeOptions.slice(0, 7);

export const FontSizeOptions6To14 = FontSizeOptions.slice(0, 8);

export const FontSizeOptions6To16 = FontSizeOptions.slice(0, 9);

export const FontSizeOptions6To20 = FontSizeOptions.slice(0, 11);

export const FontSizeOptions6To24 = FontSizeOptions;

export const FontFamilyOptions = [
  'Fira Sans',
  'Cormorant Garamond',
  'Noticia Slab',
  'Noto Sans',
  'Noto Serif',
  'Georama',
  'Josefin Sans',
].map((font) => ({
  value: font,
  label: font,
}));

export const PresetValues: { [key in Preset]?: Partial<FormData> } = {
  [Preset.Design1]: {
    preset: Preset.Design1,
    headerVersion: Options[0],
    tableVersion: Options[0],
    footerVersion: Options[0],
    fontFamily: FontFamilyOptions[0],
    fontSizeAddress: FontSizeOptions6To20[4],
    fontSizeInvoiceInfo: FontSizeOptions6To10[2],
    fontSizeSubject: FontSizeOptions6To24[8],
    fontSizeTableContent: FontSizeOptions6To16[2],
    fontSizeTableHeaders: FontSizeOptions6To10[1],
    fontSizeTableDescription: FontSizeOptions6To16[1],
    fontSizeNotes: FontSizeOptions6To14[1],
    fontSizeFooter: FontSizeOptions6To12[1],
    colouredElementsHex: '#e5e5e5',
    invertColouredElementsTextColor: false,
  },
  [Preset.Design2]: {
    preset: Preset.Design2,
    headerVersion: Options[1],
    tableVersion: Options[1],
    footerVersion: Options[1],
    fontFamily: FontFamilyOptions[0],
    fontSizeAddress: FontSizeOptions6To20[4],
    fontSizeInvoiceInfo: FontSizeOptions6To10[2],
    fontSizeSubject: FontSizeOptions6To24[8],
    fontSizeTableContent: FontSizeOptions6To16[2],
    fontSizeTableHeaders: FontSizeOptions6To10[1],
    fontSizeTableDescription: FontSizeOptions6To16[1],
    fontSizeNotes: FontSizeOptions6To14[1],
    fontSizeFooter: FontSizeOptions6To12[1],
    colouredElementsHex: '#000000',
    invertColouredElementsTextColor: false,
  },
  [Preset.Design3]: {
    preset: Preset.Design3,
    headerVersion: Options[2],
    tableVersion: Options[2],
    footerVersion: Options[2],
    fontFamily: FontFamilyOptions[0],
    fontSizeAddress: FontSizeOptions6To20[4],
    fontSizeInvoiceInfo: FontSizeOptions6To10[2],
    fontSizeSubject: FontSizeOptions6To24[8],
    fontSizeTableContent: FontSizeOptions6To16[2],
    fontSizeTableHeaders: FontSizeOptions6To10[1],
    fontSizeTableDescription: FontSizeOptions6To16[1],
    fontSizeNotes: FontSizeOptions6To14[1],
    fontSizeFooter: FontSizeOptions6To12[1],
    colouredElementsHex: '#000000',
    invertColouredElementsTextColor: false,
  },
};
