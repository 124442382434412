import React from 'react';

interface RequiredAddonProps {
  className?: string;
}

const RequiredAddon = ({ className = '' }: RequiredAddonProps) => (
  <span data-id="RequiredAddon:sign" className={className}>
    {' *'}
  </span>
);

export default RequiredAddon;
