import { BankAccount } from 'types/entities/BankAccount';

export enum SelectType {
  NONE = 'none',
  MANUAL = 'manual',
}

export type TManualBankAccount =
  | undefined
  | {
      bankName: string;
      bic: string;
      iban: string;
    };

export type TTemplateBankAccount = {
  bankAccountId?: number;
  manual: boolean;
  ordinalNumber: number;
} & TManualBankAccount;

export type TBankDetails = BankAccount & TTemplateBankAccount & { type: SelectType };
