import styled from 'styled-components';

export const SuggestionHeader = styled.div`
  display: flex;
  padding: ${({ theme }) => `${theme.space['2']} ${theme.space['4']}`};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.vrblue['100']};
  flex-direction: row;

  > div {
    margin-left: ${({ theme }) => theme.space['2']};
  }
`;

export const AllOptionsHeader = styled.div`
  padding: ${({ theme }) => `${theme.space['2']} ${theme.space['4']}`};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.grey['080']};
`;

export const ShowMoreButton = styled.button`
  display: flex;
  width: 100%;
  height: 44px;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: bold;
  outline: none;
  border: 0;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
`;

export const Option = styled.div`
  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey['020']};
  }
`;
