import React, { MouseEvent, ReactNode } from 'react';
import { useCallback } from 'react';

import { Wrapper } from './Tab.styled';

export type TabProps = {
  className?: string;
  children: ReactNode;
  isActive?: boolean;
  value?: any;
  onClick?: (event: MouseEvent<HTMLDivElement>, newValue: number) => void;
};

const Tab = ({ className, children, isActive, value, onClick }: TabProps) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (isActive || value === undefined || onClick === undefined) {
        return;
      }

      onClick(event, value);
    },
    [onClick, value, isActive]
  );

  return (
    <Wrapper className={className} $isActive={isActive} onClick={handleClick} data-id={value}>
      {children}
    </Wrapper>
  );
};

export default Tab;
