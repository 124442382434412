import React, { SVGAttributes } from 'react';

const IconCookie = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" {...props}>
    <g transform="translate(1 1)" fill="#5A5A5A" fillRule="evenodd">
      <circle cx={3} cy={8.905} r={1} />
      <circle cx={7} cy={6.905} r={1} />
      <circle cx={6.5} cy={11.405} r={1.5} />
      <circle cx={3.5} cy={5.405} r={1.5} />
      <circle cx={11} cy={8.905} r={1} />
      <path
        d="M5.76-.493A7.502 7.502 0 007 14.405a7.5 7.5 0 007.182-9.666.5.5 0 00-.73-.288 4.717 4.717 0 01-1.544.563c-2.583.459-5.047-1.278-5.502-3.881A4.8 4.8 0 016.34.029a.5.5 0 00-.58-.522zM5.22.65l.121-.033.002.019c.013.223.039.446.078.668.55 3.147 3.533 5.25 6.661 4.694l.284-.058c.28-.064.555-.15.82-.254l.184-.078.007.03A6.5 6.5 0 115.22.651z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default IconCookie;
