import React from 'react';

import CoreGlobalStyles from './CoreGlobalStyles';

// Styles for cookies modal
import '@vr-smart-finanz/cookie-banner/dist/styles.min.css';

const GlobalStyles = () => (
  <>
    <CoreGlobalStyles />
  </>
);

export default GlobalStyles;
