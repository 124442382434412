import React from 'react';

import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';

import { Content, Wrapper } from './NoInstallments.styled';
import { Buttons, Title } from './Shared.styled';

type NoInstallmentsProps = {
  onSuccess: () => void;
};

const NoInstallments = ({ onSuccess }: NoInstallmentsProps) => {
  return (
    <Wrapper>
      <Content>
        <Title>
          {t(
            'features.bank_transfers.add_contract_modal.choose_installment.no_installments.title_1'
          )}
          <br />
          {t(
            'features.bank_transfers.add_contract_modal.choose_installment.no_installments.title_2'
          )}
        </Title>
        <div>
          <p>
            {t(
              'features.bank_transfers.add_contract_modal.choose_installment.no_installments.text_1'
            )}
          </p>
          <p>
            {t(
              'features.bank_transfers.add_contract_modal.choose_installment.no_installments.text_2'
            )}
          </p>
        </div>
      </Content>
      <Buttons>
        <ActionButton
          type="button"
          dataId="button-continue"
          label={t(
            'features.bank_transfers.add_contract_modal.choose_installment.no_installments.close'
          )}
          onClick={onSuccess}
        />
      </Buttons>
    </Wrapper>
  );
};

export default NoInstallments;
