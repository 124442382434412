import React, { memo, useCallback } from 'react';

import { BANK_TRANSFERS_TILE_GO_TO_BANK_TRANSFERS } from 'constants/piwik';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import TileTitle from 'redesign/components/atoms/TileTitle/TileTitle';

import { useFetchBankAccounts } from './BankTransfersTile.hooks';
import Empty from './Empty';
import List from './List';
import { trackEvent } from './utils';

type BankTranfersTileProps = {
  className?: string;
};

const BankTranfersTile = ({ className }: BankTranfersTileProps) => {
  const { isLoading, data: bankAccounts } = useFetchBankAccounts();
  const isEmpty = bankAccounts.length === 0;

  const handleTitleClick = useCallback(() => {
    trackEvent(BANK_TRANSFERS_TILE_GO_TO_BANK_TRANSFERS);
  }, []);

  if (isEmpty || isLoading) {
    return <Empty isLoading={isLoading} />;
  }

  return (
    <section data-id="bank-transfers-tile" className={className}>
      <TileTitle to={paths.bankTransfers} onClick={handleTitleClick}>
        {t('features.homepage.bank_transfers_tile.title')}
      </TileTitle>
      <List bankAccounts={bankAccounts} />
    </section>
  );
};

export default memo(BankTranfersTile);
