import React, { memo } from 'react';
import { matchPath } from 'react-router-dom';

import paths from 'routes/paths';
import { t } from 'shared/utils';
import IconContract from 'redesign/components/atoms/Icons/Contract';

import { trackEventHandler } from '../utils';
import { Link, MenuItem } from './Shared.styled';

type ContractsProps = {
  pathname: string;
};

const Contracts = ({ pathname }: ContractsProps) => {
  const isContracts = !!matchPath(pathname, paths.contracts);

  return (
    <MenuItem $isActive={isContracts}>
      <Link
        $level={1}
        to={paths.contracts}
        $isActive={isContracts}
        data-id="contracts"
        onClick={trackEventHandler('contracts')}
      >
        <IconContract />
        {t('redesign.organisms.navigation.contracts')}
      </Link>
    </MenuItem>
  );
};

export default memo(Contracts);
