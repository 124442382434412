import React from 'react';
import { connect } from 'react-redux';
import { arrayOf, func, number, shape, string } from 'prop-types';

import { fetchIncomingInvoice as fetchIncomingInvoiceAction } from 'actions/incoming-invoice';
import {
  fetchPayments as fetchPaymentsAction,
  selectScaChallenge as selectScaChallengeAction,
} from 'actions/incoming-invoice/payments';
import { ACTION_PAYMENT_CREATOR_STEP_2_BACK, ACTION_PAYMENT_CREATOR_STEP_3 } from 'constants/piwik';
import { t } from 'shared/utils';
import { camelizeKeysDeep } from 'shared/utils/entity-mapper';
import {
  trackCreatePaymentSuccessEvent,
  trackPaymentEvent,
  trackTanSelectionEvent,
} from 'shared/utils/tracking/payments-tracking';
import ActionButton from 'components/ActionPanel/ActionButton';

import FieldsContainer from '../../components/FieldsContainer/FieldsContainer';
import FormField from '../../components/FormField/FormField';
import Hint from '../../components/Hint/Hint';
import ParallelContainer from '../../components/ParallelContainer/ParallelContainer';
import PaymentCreatorActions from '../../components/PaymentCreatorActions/PaymentCreatorActions';
import TanSchemeSelectField from '../../components/TanSchemeSelectField/TanSchemeSelectField';

import styles from './MethodSelectionChallenge.module.css';

const MethodSelectionChallenge = ({
  challenge,
  payment: { paymentId: paymentFigoId, accountId: accountFigoId },
  syncId,
  selectScaChallenge,
  selectedTanScheme,
  setCreatorState,
  backwardAction,
  goToLastStepAction,
  forwardAction,
  fetchIncomingInvoice,
  fetchPayments,
  invoiceId,
  paymentDetails: { amount: paymentAmount },
  invoiceAmount,
}) => {
  const { authMethods = [], id: challengeId } = challenge;

  const selectChallenge = async () => {
    const { id: methodId, name: tanSchemeName } = selectedTanScheme;
    const formData = {
      methodId,
      accountFigoId,
      paymentFigoId,
      syncId,
      challengeId,
    };

    /**
     * Select sca challenge solving method and send this information to Figo.
     * If response submitted is set to true then payment is done and we proceed to the last step.
     * If response status is AWAIT_AUTH and the method type is the same as the selected method then proceed to the next step.
     * This step updates challenge id.
     */
    const rawResponse = await selectScaChallenge(formData);
    const { challenge: newChallenge, createdAt, submitted } = camelizeKeysDeep(rawResponse);

    if (submitted) {
      trackCreatePaymentSuccessEvent({ paymentAmount, invoiceAmount });
      setCreatorState({ paymentCreationDate: createdAt });
      fetchPayments(invoiceId);
      fetchIncomingInvoice(invoiceId);
      return goToLastStepAction();
    }

    setCreatorState({ challenge: newChallenge });
    trackTanSelectionEvent(tanSchemeName);
    return forwardAction();
  };

  return (
    <>
      <FieldsContainer>
        <Hint text={t('expenses.payments.creator.hints.challenge_method_selection')} />
        <ParallelContainer>
          <FormField
            name="tanSchemeId"
            className={styles.field}
            {...(selectedTanScheme ? { initialSelectedItem: selectedTanScheme } : {})}
            tanSchemes={authMethods}
            component={TanSchemeSelectField}
            dataIds={{
              container: 'FigoPayments:dropdown-container',
              optionPrefix: 'FigoPayments:dropdown-option_',
            }}
            onSelect={(scheme) => setCreatorState({ selectedTanScheme: scheme })}
            required
          />
          <div />
        </ParallelContainer>
      </FieldsContainer>
      <PaymentCreatorActions>
        <ActionButton
          appearance="primary"
          dataId="FigoPayments:button-accept-tan-selection"
          disabled={!selectedTanScheme}
          onClick={selectChallenge}
          onClickSideAction={trackPaymentEvent(ACTION_PAYMENT_CREATOR_STEP_3)}
          label={t('expenses.payments.actions.order')}
        />
        <ActionButton
          appearance="outlined"
          dataId="FigoPayments:button-cancel-tan-selection"
          onClick={backwardAction}
          onClickSideAction={trackPaymentEvent(ACTION_PAYMENT_CREATOR_STEP_2_BACK)}
          label={t('expenses.payments.actions.backward')}
        />
      </PaymentCreatorActions>
    </>
  );
};

MethodSelectionChallenge.propTypes = {
  challenge: shape({
    authMethods: arrayOf(
      shape({
        type: string,
        id: string,
        mediumName: string,
      })
    ),
  }).isRequired,
  backwardAction: func.isRequired,
  forwardAction: func.isRequired,
  goToLastStepAction: func.isRequired,
  syncId: string.isRequired,
  setCreatorState: func.isRequired,
  payment: shape({}),
  invoiceId: number,
  selectScaChallenge: func.isRequired,
  fetchPayments: func.isRequired,
  fetchIncomingInvoice: func.isRequired,
  selectedTanScheme: shape({}),
  paymentDetails: shape({}),
  invoiceAmount: string,
};

const mapDispatchToProps = (dispatch, { paymentAction }) => ({
  selectScaChallenge: paymentAction(dispatch)(selectScaChallengeAction),
  fetchPayments: paymentAction(dispatch)(fetchPaymentsAction),
  fetchIncomingInvoice: paymentAction(dispatch)(fetchIncomingInvoiceAction),
});

export default connect(null, mapDispatchToProps)(MethodSelectionChallenge);
