import styled, { css } from 'styled-components';

const vertical = css`
  display: grid;
  grid-gap: ${({ theme }) => theme.space['4']};
  justify-items: center;
  text-align: center;

  > * {
    min-width: 250px;
  }
`;

const horizontal = css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.space['6']};
  }
`;

type ModalActionsProps = {
  $direction?: 'vertical' | 'horizontal';
};

const ModalActions = styled.div<ModalActionsProps>`
  padding-top: ${({ theme }) => theme.space['8']};
  padding-bottom: ${({ theme }) => theme.space['4']};

  ${({ $direction }) => ($direction === 'vertical' ? vertical : horizontal)}
`;

ModalActions.defaultProps = {
  $direction: 'vertical',
};

export default ModalActions;
