import 'react-dates-legacy/initialize';

import React, { Component, createRef } from 'react';
import { START_DATE } from 'react-dates-legacy/constants';
import onClickOutside from 'react-onclickoutside';
import cx from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { DATE_FORMAT } from 'constants/datetime';
import { getMomentDateForDatepicker } from 'shared/utils/date/parser';
import TextField from 'components/Form/TextField';

import Controller from './Controller';

import 'react-dates-legacy/lib/css/_datepicker.css';
import styles from './Search.module.css';

const DATE_TYPES = {
  START_DATE: 'START_DATE',
  END_DATE: 'END_DATE',
};

class SearchRange extends Component {
  state = {
    focusedInput: null,
    startDateText: '',
    endDateText: '',
    ignoreFormattingOnClose: false,
  };

  wrapperRef = createRef();
  popperRef = createRef();

  componentDidMount() {
    this.setDateTextBasedOnProps(DATE_TYPES.START_DATE);
    this.setDateTextBasedOnProps(DATE_TYPES.END_DATE);
  }

  componentDidUpdate(prevProps, prevState) {
    this.formatInputsWhenPopperCloses(prevState);
    this.setDatesOnFiltersChange(prevProps);
  }

  setDatesOnFiltersChange = (prevProps) => {
    const { name } = this.props;
    const getFilters = (props) => get(props, `filters.${name}`, {});
    const didStartDateInFiltersChanged =
      getFilters(prevProps).startDate !== getFilters(this.props).startDate;
    const didEndDateInFiltersChanged =
      getFilters(prevProps).endDate !== getFilters(this.props).endDate;

    if (didStartDateInFiltersChanged) {
      this.setDateTextBasedOnProps(DATE_TYPES.START_DATE);
    }
    if (didEndDateInFiltersChanged) {
      this.setDateTextBasedOnProps(DATE_TYPES.END_DATE);
    }
  };

  formatInputsWhenPopperCloses = (prevState) => {
    if (this.state.ignoreFormattingOnClose) {
      this.setState({ ignoreFormattingOnClose: false });
      return;
    }

    if (prevState.focusedInput && !this.state.focusedInput) {
      const startDateDateMoment = getMomentDateForDatepicker(this.state.startDateText);
      const endDateDateMoment = getMomentDateForDatepicker(this.state.endDateText);

      this.setState({
        startDateText: startDateDateMoment.isValid()
          ? startDateDateMoment.format(DATE_FORMAT)
          : this.state.startDateText,
        endDateText: endDateDateMoment.isValid()
          ? endDateDateMoment.format(DATE_FORMAT)
          : this.state.endDateText,
      });
    }
  };

  getDateAsText = (dateMoment) =>
    dateMoment && dateMoment.isValid() ? dateMoment.format(DATE_FORMAT) : '';

  setDateTextBasedOnProps = (dateType) => {
    const { filters: currentFilters, name } = this.props;
    const currentDates = get(currentFilters, name, {});
    const isStartDate = dateType === DATE_TYPES.START_DATE;

    const currentDateText = this.getDateAsText(
      isStartDate ? currentDates.startDate : currentDates.endDate
    );

    this.setState({ [isStartDate ? 'startDateText' : 'endDateText']: currentDateText });
  };

  saveDateToState = (startDate, endDate) => {
    const startDateText = this.getDateAsText(startDate);
    const endDateText = this.getDateAsText(endDate);

    this.setState({ startDateText, endDateText });
  };

  handleClear = (dateType) => {
    const { name, clearFieldAction, setRangeQueryAction } = this.props;

    if (clearFieldAction) clearFieldAction();

    const date = this.extractDatesFromFilters();

    let datesToSet = {};
    if (dateType === DATE_TYPES.START_DATE) {
      datesToSet = { startDate: null, endDate: date.endDate };
    } else if (dateType === DATE_TYPES.END_DATE) {
      datesToSet = { startDate: date.startDate, endDate: null };
    }

    setRangeQueryAction(name)(datesToSet);
    this.setState({ [dateType === DATE_TYPES.START_DATE ? 'startDateText' : 'endDateText']: '' });
  };

  defaultDateRanges = [
    {
      label: 'current_month',
      onClick: () => this.handleCurrentMonth(),
    },
    {
      label: 'current_quarter',
      onClick: () => this.handleCurrentQuarter(),
    },
    {
      label: 'current_year',
      onClick: () => this.handleCurrentYear(),
    },
  ];

  handleCurrentMonth = () => {
    const { name, setRangeQueryAction } = this.props;
    const startDate = moment().startOf('month');
    const endDate = moment().endOf('month');

    setRangeQueryAction(name)({ startDate, endDate });
    this.saveDateToState(startDate, endDate);
  };

  handleCurrentQuarter = () => {
    const { name, setRangeQueryAction } = this.props;
    const quarter = moment().quarter();
    const startDate = moment().quarter(quarter).startOf('quarter');
    const endDate = moment().quarter(quarter).endOf('quarter');

    setRangeQueryAction(name)({ startDate, endDate });
    this.saveDateToState(startDate, endDate);
  };

  handleCurrentYear = () => {
    const { name, setRangeQueryAction } = this.props;
    const startDate = moment().startOf('year');
    const endDate = moment().endOf('year');

    setRangeQueryAction(name)({ startDate, endDate });
    this.saveDateToState(startDate, endDate);
  };

  setIsOpen = (value) => {
    this.setState({ focusedInput: value });
  };

  handleClickOutside = () => {
    const { filters, name, onClose, setRangeQueryAction } = this.props;
    const { focusedInput } = this.state;
    const date = get(filters, name, {});

    if (focusedInput) setRangeQueryAction(name)(date);
    if (focusedInput && onClose) onClose(name)(date);

    this.setIsOpen(null);
  };

  reopenPopper = () => {
    const { focusedInput } = this.state;

    this.setState({ focusedInput: null }, () => {
      this.setState({ focusedInput });
    });
  };

  extractDatesFromFilters = () => {
    const { filters, name } = this.props;

    return get(filters, name, {});
  };

  getStylesForCentering() {
    const wrapper = this.wrapperRef.current;
    const popper = this.popperRef.current;

    if (!wrapper || !popper) return {};

    const wrapperStyles = wrapper.getBoundingClientRect();
    const popperStyles = popper.getBoundingClientRect();
    const windowWidth = window.innerWidth;

    const expectedLeft = (windowWidth - popperStyles.width) / 2;
    const initialLeft = wrapperStyles.left;

    return { left: expectedLeft - initialLeft };
  }

  handleInputChange = (text, dateType) => {
    const { setRangeQueryAction, name } = this.props;
    const date = this.extractDatesFromFilters();
    const dateMoment = getMomentDateForDatepicker(text);
    const isDateValid = dateMoment.isValid();
    const dateMomentToSet = isDateValid ? dateMoment : null;

    let datesToSet = {};
    if (dateType === DATE_TYPES.START_DATE) {
      datesToSet = { startDate: dateMomentToSet, endDate: date.endDate };
    } else if (dateType === DATE_TYPES.END_DATE) {
      datesToSet = { startDate: date.startDate, endDate: dateMomentToSet };
    }

    setRangeQueryAction(name)(datesToSet);

    this.setState({
      [dateType === DATE_TYPES.START_DATE ? 'startDateText' : 'endDateText']: text,
    });

    if (isDateValid) {
      this.reopenPopper();
      this.setState({ ignoreFormattingOnClose: true });
    }
  };

  closePopperOnEnterPress = (e) => {
    // if enter
    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
      this.setState({ focusedInput: null });
    }
  };

  render() {
    const {
      setRangeQueryAction,
      name,
      isPastDateDisabled,
      isFutureDateDisabled,
      placeholder,
      alignDatepickerRight,
      customDateRanges,
      onClose,
      isSingleDayAllowed,
      dataIds,
    } = this.props;
    const { startDateText, endDateText } = this.state;
    const dates = this.extractDatesFromFilters();

    const dateRanges = customDateRanges || this.defaultDateRanges;

    return (
      <div className={styles.searchRange} ref={this.wrapperRef}>
        <label htmlFor="datepicker-input-start-date" className={styles.label}>
          {placeholder}
        </label>
        <div className={styles.inputsContainer}>
          <div className={styles.input}>
            <TextField
              value={startDateText}
              onChange={(e) => this.handleInputChange(e.target.value, DATE_TYPES.START_DATE)}
              onFocus={() => this.setIsOpen(START_DATE)}
              label={placeholder}
              clearAction={() => this.handleClear(DATE_TYPES.START_DATE)}
              placeholder="TT.MM.JJJJ"
              onKeyDown={this.closePopperOnEnterPress}
              id="datepicker-input-start-date"
              isLabelHidden
              dataId={dataIds.startDate}
              isAutofillDisabled={false}
            />
          </div>
          <div className={styles.preposition}>bis</div>
          <div className={styles.input}>
            <TextField
              value={endDateText}
              onChange={(e) => this.handleInputChange(e.target.value, DATE_TYPES.END_DATE)}
              onFocus={() => this.setIsOpen(START_DATE)}
              clearAction={() => this.handleClear(DATE_TYPES.END_DATE)}
              placeholder="TT.MM.JJJJ"
              onKeyDown={this.closePopperOnEnterPress}
              id="datepicker-input-end-date"
              isLabelHidden
              dataId={dataIds.endDate}
              isAutofillDisabled={false}
            />
          </div>
        </div>
        <div
          className={cx(styles.datepicker, {
            [styles.alignDatepickerRight]: alignDatepickerRight,
            [styles.hiddenDatepicker]: !this.state.focusedInput,
          })}
          ref={this.popperRef}
          style={this.getStylesForCentering()}
        >
          <Controller
            dateRanges={dateRanges}
            name={name}
            focusedInput={this.state.focusedInput}
            onFocus={(focusedInput) => this.setState({ focusedInput })}
            setIsOpen={this.setIsOpen}
            isSingleDayAllowed={isSingleDayAllowed}
            dates={dates}
            setRangeQueryAction={setRangeQueryAction}
            isPastDateDisabled={isPastDateDisabled}
            isFutureDateDisabled={isFutureDateDisabled}
            saveDateToState={this.saveDateToState}
            onClose={onClose}
          />
        </div>
      </div>
    );
  }
}

SearchRange.propTypes = {
  name: string.isRequired,
  filters: shape({}),
  setRangeQueryAction: func,
  clearFieldAction: func,
  isPastDateDisabled: bool,
  isFutureDateDisabled: bool,
  placeholder: string,
  alignDatepickerRight: bool,
  customDateRanges: arrayOf(
    shape({
      label: string.isRequired,
      onClick: func.isRequired,
    })
  ),
  onClose: func,
  isSingleDayAllowed: bool,
  dataIds: shape({
    startDate: string,
    endDate: string,
  }),
};

SearchRange.defaultProps = {
  filters: {},
  isPastDateDisabled: false,
  isFutureDateDisabled: false,
  placeholder: '',
  customDateRanges: null,
  onClose: null,
  isSingleDayAllowed: false,
  dataIds: {
    startDate: '',
    endDate: '',
  },
};

export default onClickOutside(SearchRange);
