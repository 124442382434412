import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { shape as countriesShape } from 'reducers/countries';
import { withCountries } from 'shared/hoc';
import { t } from 'shared/utils';
import { Client } from 'types/entities/Client';
import Button from 'components/Button';
import { SelectField, TextField } from 'components/Form';
import { ModalHeader } from 'components/Modal';

import { Buttons, CityAndZipcode, Modal, ModalBody, Title } from './AddAddressModal.styled';
import { checker, required } from './validate';

import styles from './AddAddressModal.module.css';

const FormName = 'create-new-client-adress';

const ClientSelectField = (props: any) => {
  const { input } = props;
  return <SelectField {...props} input={input} searchable />;
};

export type FormData = {
  deliveryName: string;
  deliveryStreet: string;
  deliveryZipcode: number;
  deliveryCity: string;
  deliveryCountry: string;
};

type AdressModalProps = {
  open: boolean;
  handleCloseModalState: () => void;
  clientName: string;
  client: Client;
  countries: typeof countriesShape;
  handleDeliveryData: (values: FormData) => void;
};

type ModalProps = AdressModalProps & InjectedFormProps<FormData, AdressModalProps>;

const AddAdressModal = ({
  handleCloseModalState,
  handleSubmit,
  clientName,
  countries,
  handleDeliveryData,
}: ModalProps) => {
  return (
    <Modal isOpen>
      <ModalHeader
        withCloseButton
        onClose={handleCloseModalState}
        headerStyle={styles.header}
        headerContentStyle={styles.headerContent}
      >
        {t('clients.deviating_delivery_address.modal.add_deviated_address')}
      </ModalHeader>
      <ModalBody>
        <Title>
          {t('clients.deviating_delivery_address.modal.add_deviated_address_for', { clientName })}
        </Title>
        <form onSubmit={handleSubmit(handleDeliveryData)}>
          <div>
            <Field
              component={TextField}
              name="deliveryName"
              label={t('features.delivery_note.form.deviating_adress_modal.name')}
              placeholder={t('features.delivery_note.form.deviating_adress_modal.name')}
              data-id="name"
              checker={checker}
              validate={[required]}
              required
            />
            <Field
              component={TextField}
              name="deliveryStreet"
              label={t('features.delivery_note.form.deviating_adress_modal.street_and_number')}
              placeholder={t(
                'features.delivery_note.form.deviating_adress_modal.street_and_number'
              )}
              data-id="streetAndNumber"
              checker={checker}
              validate={[required]}
              required
            />
            <CityAndZipcode>
              <Field
                component={TextField}
                name="deliveryZipcode"
                label={t('features.delivery_note.form.deviating_adress_modal.zipcode')}
                placeholder={t('features.delivery_note.form.deviating_adress_modal.zipcode')}
                data-id="zipCode"
                checker={checker}
                validate={[required]}
                required
                type="number"
              />
              <Field
                component={TextField}
                name="deliveryCity"
                label={t('features.delivery_note.form.deviating_adress_modal.city')}
                placeholder={t('features.delivery_note.form.deviating_adress_modal.city')}
                data-id="city"
                checker={checker}
                validate={[required]}
                required
              />
            </CityAndZipcode>
            <Field
              name="deliveryCountry"
              label={t('features.delivery_note.form.deviating_adress_modal.country')}
              component={ClientSelectField}
              options={countries.data}
              isLoading={countries.isFetching}
              required
            />
            <Buttons>
              <Button
                appearance="outlined"
                label={t('features.delivery_note.form.deviating_adress_modal.cancel_button')}
                type="button"
                onClick={handleCloseModalState}
              />
              <Button
                label={t('features.delivery_note.form.deviating_adress_modal.save_button')}
                type="submit"
                dataId="submit-transaction"
              />
            </Buttons>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default reduxForm<FormData, ModalProps>({
  form: FormName,
  enableReinitialize: true,
})(withCountries(AddAdressModal));
