import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { QUARTERLY_SUMMARY } from 'constants/quarterly-summary';
import paths from 'routes/paths';
import { useAppSelector } from 'shared/hooks/app';
import { formatMoney, t } from 'shared/utils';
import { getIsFetchingProfile } from 'store/slices/onboarding/selectors';
import Tooltip from 'components/Tooltip/Tooltip';

import LiquidityDangerControl from '../LiquidityDangerControl/LiquidityDangerControl';
import * as SC from './ControlSection.styled';

const Feedback = () => {
  const isFetchingProfile = useSelector(getIsFetchingProfile);
  const { liquidityDangerThreshold, chartData } = useAppSelector(
    (state) => state[QUARTERLY_SUMMARY]
  );
  const { nextTaxationDate, totalTaxes, totalRevenuesTaxes, totalExpensesTaxes } = chartData?.data;

  if (isFetchingProfile) {
    return null;
  }

  const isRefund = totalTaxes >= 0;

  const taxes =
    totalTaxes < 0
      ? {
          isPositive: false,
          todayTitle: t('dashboard.liquidity_chart.tooltip.today.total_revenues_taxes_title'),
          futureTitle: t('dashboard.liquidity_chart.tooltip.future.total_revenues_taxes_title'),
          value: formatMoney(totalTaxes),
        }
      : {
          isPositive: true,
          todayTitle: t('dashboard.liquidity_chart.tooltip.today.total_expenses_taxes_title'),
          futureTitle: t('dashboard.liquidity_chart.tooltip.future.total_expenses_taxes_title'),
          value: formatMoney(totalTaxes),
        };

  return (
    <SC.Wrapper>
      <SC.OrangeSquare />
      <SC.TaxInfoContainer>
        <SC.TaxInfoDescription>
          {t('dashboard.liquidity_chart.control_section.taxation_date')}
        </SC.TaxInfoDescription>
        <SC.TaxDate>
          {nextTaxationDate}
          <Link to={paths.taxConsultantProfile}>
            <SC.EditIcon />
          </Link>
        </SC.TaxDate>
        <Tooltip
          message={
            <SC.TooltipWrapper>
              <SC.Row>
                <h5>{t('dashboard.liquidity_chart.tooltip.future.total_revenues_taxes')}</h5>
                <SC.Value isPositive={totalRevenuesTaxes > 0}>
                  {formatMoney(totalRevenuesTaxes)}
                </SC.Value>
              </SC.Row>
              <SC.Row>
                <h5>{t('dashboard.liquidity_chart.tooltip.future.total_expenses_taxes')}</h5>
                <SC.Value isPositive={totalExpensesTaxes > 0}>
                  {formatMoney(totalExpensesTaxes)}
                </SC.Value>
              </SC.Row>
              <SC.VerticalLine />
              <SC.Row bold>
                <h5>{taxes.futureTitle}</h5>
                <SC.Value isPositive={taxes.isPositive}>{formatMoney(taxes.value)}</SC.Value>
              </SC.Row>
            </SC.TooltipWrapper>
          }
          isTooltipHidden={false}
        >
          <SC.TaxInfoDescription>
            {isRefund
              ? t('dashboard.liquidity_chart.control_section.forecast.refund')
              : t('dashboard.liquidity_chart.control_section.forecast.payment')}
          </SC.TaxInfoDescription>

          <SC.TaxValue isNegative={!isRefund}>{formatMoney(totalTaxes)}</SC.TaxValue>
        </Tooltip>
      </SC.TaxInfoContainer>
      <SC.LiquidityDangerControl>
        <LiquidityDangerControl
          dataId="LiquidityGraph:RealDataTile-dangerControl"
          liquidityDangerThreshold={liquidityDangerThreshold}
        />
      </SC.LiquidityDangerControl>
    </SC.Wrapper>
  );
};

export default Feedback;
