import {
  CLICK_CANCEL_PRODUCT_CATALOG_ITEM,
  CLICK_CREATE_PRODUCT_CATALOG_ITEM,
  CLICK_EDIT_PRODUCT_CATALOG_ITEM,
  PRODUCT_CATALOG_ITEMS,
} from 'constants/product-catalog/items';

export const clickCreateProductCatalogItem = () => (dispatch) =>
  dispatch({
    type: CLICK_CREATE_PRODUCT_CATALOG_ITEM,
    name: PRODUCT_CATALOG_ITEMS,
  });

export const clickEditProductCatalogItem = () => (dispatch) =>
  dispatch({
    type: CLICK_EDIT_PRODUCT_CATALOG_ITEM,
    name: PRODUCT_CATALOG_ITEMS,
  });

export const clickCancelProductCatalogItem = () => (dispatch) =>
  dispatch({
    type: CLICK_CANCEL_PRODUCT_CATALOG_ITEM,
    name: PRODUCT_CATALOG_ITEMS,
  });
