import { get } from 'lodash';

import {
  DEFAULT_ERROR_MESSAGE,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
} from 'constants/app-uploads';

const initialState = {
  isFetching: false,
  error: null,
  data: 0,
};

export default (state = initialState, action) => {
  const { type, response } = action;

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: get(response, 'count', 0),
      };
    case FETCH_FAILURE:
      return {
        ...state,
        error: get(response, 'error', DEFAULT_ERROR_MESSAGE),
      };
    default:
      return state;
  }
};
