import React, { FunctionComponent } from 'react';
import cx from 'classnames';

import SectionHeader from '../../atoms/SectionHeader/SectionHeader';
import { Box } from './ProfileSection.styled';

type ProfileSectionProps = {
  heading: string;
  theme?: {
    main?: string;
    content?: string;
  };
  className?: string;
};

const ProfileSection: FunctionComponent<ProfileSectionProps> = ({
  heading,
  theme = {},
  children,
  className,
}) => (
  <div className={cx(theme.main, className)}>
    <SectionHeader header={heading} />
    <Box>{children}</Box>
  </div>
);

export default ProfileSection;
