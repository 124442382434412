import React from 'react';
import cx from 'classnames';
import { bool, number } from 'prop-types';

import { formatMoney, t } from 'shared/utils';

import styles from '../Header.module.css';

const PremiumNoVrBankCustomer = ({ price }) => (
  <div className={styles.priceContainer}>
    <span>
      {t('profile.payment_plan.current_payment_plan.regular')}
      <span className={cx(styles.price, styles.priceAmount)}>{formatMoney(price)}</span>
      <div />
      <span className={styles.price}>
        {t('profile.payment_plan.current_payment_plan.monthly')} *
      </span>
    </span>
  </div>
);

PremiumNoVrBankCustomer.propTypes = {
  price: number.isRequired,
};

const PremiumVrBankCustomer = ({ price }) => (
  <div className={cx(styles.priceContainer, styles.crossed)}>
    <span>{t('profile.payment_plan.current_payment_plan.regular')}</span>
    <span className={styles.price}>{formatMoney(price)}</span>
    <div />
    <span>{t('profile.payment_plan.current_payment_plan.monthly')} *</span>
  </div>
);

PremiumVrBankCustomer.propTypes = {
  price: number.isRequired,
};

const PremiumHeader = ({ vrMainBankAccount, defaultPrice }) =>
  vrMainBankAccount ? (
    <PremiumVrBankCustomer price={defaultPrice} />
  ) : (
    <PremiumNoVrBankCustomer price={defaultPrice} />
  );

PremiumHeader.propTypes = {
  vrMainBankAccount: bool.isRequired,
  defaultPrice: number.isRequired,
};

export default PremiumHeader;
