import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as reduxPush } from 'connected-react-router';
import { bool, func, number, oneOf, shape, string } from 'prop-types';
import { reduxForm } from 'redux-form';

import { fetchIncomingInvoiceForShowing as fetchIncomingInvoiceForShowingAction } from 'actions/incoming-invoice';
import { getOcrResult } from 'actions/incoming-invoice/ocr';
import {
  fetchPayments as fetchPaymentsAction,
  fetchPaymentsBankAccounts as fetchPaymentsBankAccountsAction,
} from 'actions/incoming-invoice/payments';
import { SHOW_MODE } from 'constants/common/crud';
import { INCOMING_INVOICE } from 'constants/folder-names';
import { CREATION_TYPE } from 'constants/incoming-invoice';
import CreateInvoiceSection from 'containers/IncomingInvoices/IncomingInvoiceCreator/CreateInvoiceSection/CreateInvoiceSection';
import styles from 'containers/IncomingInvoices/IncomingInvoiceCreator/IncomingInvoiceCreator.module.css';
import InvoiceDetailsSection from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoiceDetailsSection';
import InvoicePaymentSection from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoicePaymentSection/InvoicePaymentSection';
import InvoiceSumSection from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoiceSumSection';
import UploadInvoiceSection from 'containers/IncomingInvoices/IncomingInvoiceCreator/UploadInvoiceSection';
import { incomingInvoiceInitialValuesSelector } from 'reducers/form';
import { paymentsEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { incomingInvoiceDetailsSelector } from 'selectors/incomingInvoice';
import { t } from 'shared/utils';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import BankActivity from 'components/BankActivity/BankActivity';
import { ButtonAppearances } from 'components/Button';
import Card from 'components/Card';
import If from 'components/Conditions/If';
import { LineItemsAutoSaveConsumer, LineItemsAutoSaveProvider } from 'components/LineItems';
import LoadingIcon from 'components/LoadingIcon';

const isReadonly = () => true;

const InvoiceShow = ({
  invoiceId,
  fetchIncomingInvoiceForShowing,
  fetchPayments,
  fetchPaymentsBankAccounts,
  customInvoiceId = '',
  creationType,
  isDraft,
  getInvoiceOcrData,
  hasUploadedFiles,
  invoiceDetails,
  paymentsEnabled,
  invoiceAmount,
  invoiceHasCreditNote,
  push,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isOCRProcessed, setIsOCRProcessed] = useState(false);

  const fetchIncomingInvoiceForShowingRequest = async () => {
    try {
      setIsFetching(true);
      await fetchIncomingInvoiceForShowing(customInvoiceId || invoiceId);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
    }
  };

  const getInvoiceOcrDataRequest = async () => {
    try {
      await getInvoiceOcrData(invoiceId);
      setIsOCRProcessed(true);
    } catch (error) {
      setIsOCRProcessed(false);
    }
  };

  const processOCR = useCallback(() => {
    const isReadyForProcessing = !!creationType && !isOCRProcessed;
    if (!isReadyForProcessing) return;

    const shouldProcess = creationType === CREATION_TYPE.UPLOAD;
    if (shouldProcess) {
      getInvoiceOcrDataRequest();
    }
  }, []);

  useEffect(() => {
    fetchIncomingInvoiceForShowingRequest();
    fetchPayments(customInvoiceId || invoiceId);
    fetchPaymentsBankAccounts();
  }, [customInvoiceId, invoiceId]);

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      processOCR();
    }
  }, [processOCR]);

  const shouldShowPayments = paymentsEnabled && invoiceAmount >= 0 && !invoiceHasCreditNote;
  const shouldProcessOCR = creationType === 'upload' && isDraft;
  const isORCDataReady = !shouldProcessOCR || isOCRProcessed;

  if (isFetching || !isORCDataReady) return <LoadingIcon />;

  return (
    <form className={styles.main}>
      <LineItemsAutoSaveProvider>
        <div className={cx(styles.section, styles.isHorizontal)}>
          <div className={styles.subsection}>
            <If ok={hasUploadedFiles}>
              <div className={cx(styles.upload, styles.subsectionItem)}>
                <UploadInvoiceSection readonly />
              </div>
            </If>
            <div className={cx(styles.creator, styles.subsectionItem)}>
              <CreateInvoiceSection isReadonly={isReadonly} />
            </div>
          </div>
          <LineItemsAutoSaveConsumer>
            {({ resetNewLineItemCreators, resetEditLineItemCreators }) => (
              <InvoiceDetailsSection
                crudMode={SHOW_MODE}
                readonly
                resetNewLineItemCreators={resetNewLineItemCreators}
                resetEditLineItemCreators={resetEditLineItemCreators}
              />
            )}
          </LineItemsAutoSaveConsumer>
          <div
            className={cx(styles.subsection, styles.parallelSubsection, {
              [styles.inversed]: !shouldShowPayments,
            })}
          >
            {shouldShowPayments && <InvoicePaymentSection readonly />}
            <InvoiceSumSection insertedAsGross={invoiceDetails.details.insertedAsGross} />
          </div>
          <Card className={styles.card} data-id="assignment-row">
            <BankActivity
              isDraft={isDraft}
              readonly={isReadonly()}
              invoiceDetails={invoiceDetails} // This value needs to be passed or invoice form will break
              currentInvoiceDetails={invoiceDetails.details}
              whichFolder={INCOMING_INVOICE}
            />
          </Card>
        </div>
        <ActionPanel className={styles.creatorActionsSection} wrapped>
          <ActionButton
            appearance={ButtonAppearances.outlined}
            onClick={() => push(paths.expenses)}
            label={t('forms.actions.back_to_index')}
            dataId="back-button"
          />
        </ActionPanel>
      </LineItemsAutoSaveProvider>
    </form>
  );
};

InvoiceShow.propTypes = {
  hasUploadedFiles: bool.isRequired,
  fetchIncomingInvoiceForShowing: func.isRequired,
  invoiceId: string.isRequired,
  invoiceAmount: number.isRequired,
  invoiceDetails: shape({}).isRequired,
  isDraft: bool.isRequired,
  paymentsEnabled: bool.isRequired,
  invoiceHasCreditNote: bool.isRequired,
  fetchPaymentsBankAccounts: func.isRequired,
  fetchPayments: func.isRequired,
  customInvoiceId: string,
  push: func.isRequired,
  getInvoiceOcrData: func.isRequired,
  creationType: oneOf([CREATION_TYPE.MANUAL, CREATION_TYPE.UPLOAD]),
};

const ReduxForm = reduxForm({
  form: 'invoiceCreator',
  enableReinitialize: true,
})(InvoiceShow);

const mapStateToProps = (state, ownProps) => {
  const invoice = incomingInvoiceDetailsSelector(state);

  return {
    invoiceId: ownProps.match.params ? ownProps.match.params.id : '',
    invoiceAmount: invoice.totalGrossAmount,
    invoiceHasCreditNote: invoice.creditNote,
    isFetching: state.incomingInvoice.isFetching,
    initialValues: incomingInvoiceInitialValuesSelector(state),
    hasUploadedFiles: !!state.incomingInvoice.uploadedFiles.length,
    invoiceDetails: state.incomingInvoice,
    isDraft: invoice.draft,
    paymentsEnabled: paymentsEnabledHelper(state),
    creationType: invoice.creationType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchIncomingInvoiceForShowing: (id) => dispatch(fetchIncomingInvoiceForShowingAction(id)),
  fetchPaymentsBankAccounts: () => dispatch(fetchPaymentsBankAccountsAction()),
  fetchPayments: (id) => dispatch(fetchPaymentsAction(id)),
  push: (path) => dispatch(reduxPush(path)),
  getInvoiceOcrData: (...args) => dispatch(getOcrResult(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxForm);
