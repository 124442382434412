import styled, { css } from 'styled-components';

type WrapperProps = { $direction: 'row' | 'column' };

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  ${({ $direction }) =>
    $direction === 'row'
      ? css`
          > *:not(:last-child) {
            margin-right: ${({ theme }) => theme.space['8']};
          }
        `
      : css`
          flex-direction: column;

          > *:not(:last-child) {
            margin-bottom: ${({ theme }) => theme.space['4']};
          }
        `}
`;
