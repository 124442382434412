import styled from 'styled-components';

type LabelProps = {
  $isActive?: boolean;
  $isDisabled?: boolean;
  $error?: boolean | string;
};

const Label = styled.label<LabelProps>`
  color: ${({ theme, $isActive, $isDisabled, $error }) =>
    $error
      ? theme.colors.red['100']
      : $isActive
      ? theme.colors.vrblue['100']
      : $isDisabled
      ? theme.colors.grey['040']
      : theme.colors.grey['070']};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: normal;
  letter-spacing: 0.58px;
  line-height: 17px;
  margin-bottom: 0;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
`;

export default Label;
