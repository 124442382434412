import React, { Component } from 'react';
import cx from 'classnames';
import { boolean, number, shape, string } from 'prop-types';
import { Field } from 'redux-form';

import { t } from 'shared/utils';
import { CurrencyField } from 'components/Form';
import Checkbox from 'components/Form/Checkbox';
import TextField from 'components/Form/TextField';
import I18n from 'components/I18n';
import InfoIcon from 'components/InfoIcon/InfoIcon';

import Column from '../Column/Column';

import styles from './Row.module.css';

class Row extends Component {
  static defaultProps = {
    bankAccount: {
      accountId: '',
      name: '',
      number: '',
    },
    isOldAccount: false,
  };

  renderText = (name) => <span className={styles.text}>{this.props.bankAccount[name]}</span>;

  render() {
    const { isOldAccount } = this.props;
    const checkboxProps = isOldAccount ? { checked: true } : {};
    return (
      <tr>
        <Column className={styles.cell}>
          <I18n t="bank_accounts.creator.steps.account" className={styles.cellHeader} />
          <div>
            <Field
              component={Checkbox}
              disabled={isOldAccount}
              {...checkboxProps}
              className={cx(styles.column, styles.checkbox)}
              name={`accepted[${this.props.bankAccount.id}]`}
              dataIds={{ input: 'BankAccount:bankAccountCheckbox' }}
            />
          </div>
        </Column>
        <Column className={styles.cell}>
          <I18n t="bank_accounts.creator.fields.account_number" className={styles.cellHeader} />
          {this.renderText('number')}
        </Column>
        <Column className={styles.cell}>
          <I18n t="bank_accounts.creator.fields.account_name" className={styles.cellHeader} />
          {this.renderText('name')}
        </Column>
        <Column className={cx(styles.cell, styles.currencyColumn)}>
          <div className={styles.currencyLabel}>
            <I18n t="bank_accounts.creator.fields.credit_line" className={styles.cellHeader} />
            <InfoIcon align="left" text={t('bank_accounts.creator.fields.credit_line_info')} />
          </div>
          <div className={styles.currencyFieldWrapper}>
            <CurrencyField
              isRawField
              component={TextField}
              className={cx(styles.column, styles.creditLine)}
              inputClassName={styles.input}
              name={`creditLine[${this.props.bankAccount.id}]`}
              isCurrencySignVisible={false}
            />
            <span>€</span>
          </div>
        </Column>
      </tr>
    );
  }
}

Row.propTypes = {
  bankAccount: shape({
    id: string,
    name: string,
    number: string,
    accountId: string,
    balance: number,
  }),
  isOldAccount: boolean,
};

export default Row;
