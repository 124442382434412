import styled from 'styled-components';

import BaseModalBody from 'redesign/components/atoms/ModalBody/ModalBody';

export const ModalBody = styled(BaseModalBody)`
  padding-top: ${({ theme }) => theme.space['12']};
  padding-bottom: ${({ theme }) => theme.space['12']};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.grey['080']};
  font-size: ${({ theme }) => theme.fontSize['base']};
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: ${({ theme }) => theme.space['6']};
  margin-top: ${({ theme }) => theme.space['6']};
  text-align: center;
`;
