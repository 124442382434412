import React from 'react';
import { isEmpty } from 'lodash';

import { ACTION_REDIRECT_TO_BANK_ACCOUNTS, CATEGORY_LIQUIDITY_CHART } from 'constants/piwik';
import { QUARTERLY_SUMMARY } from 'constants/quarterly-summary';
import paths from 'routes/paths';
import withViewport from 'shared/hoc/withViewport';
import { useAppSelector } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import { default as LiquidityChartImage } from 'components/EmptyEntryPage/images/LiquidityChart';
import TileTitle from 'redesign/components/atoms/TileTitle/TileTitle';
import TileEmpty from 'redesign/components/molecules/TileEmpty/TileEmpty';

import ControlSection from './components/ControlSection/ControlSection';
import VictoryChart from './components/VictoryChart/VictoryChart';
import * as SC from './LiquidityChart.styled';

const trackRedirectionToBankAccounts = () =>
  piwikHelpers.trackEvent(CATEGORY_LIQUIDITY_CHART, ACTION_REDIRECT_TO_BANK_ACCOUNTS);

type LiquidityChartProps = {
  className: string;
  isSmUp: boolean;
  isMdUp: boolean;
  isLgUp: boolean;
  isXlgUp: boolean;
  isXxlgUp: boolean;
};

const LiquidityChart = ({
  className,
  isSmUp,
  isMdUp,
  isLgUp,
  isXlgUp,
  isXxlgUp,
  ...rest
}: LiquidityChartProps) => {
  const hasBankAccount = useAppSelector((state) => !isEmpty(state.bankAccount.data));
  const isFetchingBankAccount = useAppSelector((state) => state.bankAccount.isFetching);
  const { liquidityDangerThreshold, chartData } = useAppSelector(
    (state) => state[QUARTERLY_SUMMARY]
  );
  const hasChartData = Boolean(chartData.data.all.length);
  const viewportKey = `${isSmUp}${isMdUp}${isLgUp}${isXlgUp}${isXxlgUp}${liquidityDangerThreshold}`;
  const isLoading = isFetchingBankAccount || !hasChartData;

  if (isLoading || !hasBankAccount) {
    return (
      <TileEmpty
        dataId="LiquidityGraph:EmptyStateTile-wrapper"
        image={<LiquidityChartImage height={200} />}
        title={t('dashboard.liquidity_chart.header')}
        caption={t('dashboard.liquidity_chart.empty_state.header')}
        info={t('dashboard.liquidity_chart.empty_state.info')}
        center
        isLoading={isLoading}
        buttons={[
          {
            dataId: 'LiquidityGraph:EmptyStateTile-buttonBankAccounts',
            text: t('dashboard.liquidity_chart.empty_state.button'),
            to: paths.bankAccounts,
            action: trackRedirectionToBankAccounts,
          },
        ]}
      />
    );
  }

  return (
    <SC.Wrapper data-id="LiquidityGraph:RealDataTile-wrapper" className={className}>
      <SC.TitleWrapper>
        <TileTitle>{t('dashboard.liquidity_chart.header')}</TileTitle>
        <SC.InfoIcon text={t(`dashboard.liquidity_chart.tooltip.help_text`)} />
      </SC.TitleWrapper>
      <SC.Box>
        {hasChartData && !isFetchingBankAccount && (
          <VictoryChart
            dataId="LiquidityGraph:RealDataTile-victoryChart"
            key={viewportKey}
            {...chartData}
            {...rest}
            liquidityDangerThreshold={liquidityDangerThreshold}
          />
        )}
        <ControlSection />
      </SC.Box>
    </SC.Wrapper>
  );
};

export default withViewport(LiquidityChart);
