import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  overflow: auto;
`;

const Shade = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  z-index: 1;
`;

export const LeftShade = styled(Shade)`
  left: 0;
  background: radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
`;

export const RightShade = styled(Shade)`
  right: 0;
  background: radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)) 0
    100%;
`;
