import { t } from 'shared/utils';
import { generateValidation } from 'shared/utils/form-validation';

const MINIMUM_LENGTH = 8;
const REGEX_LOWERCASE = /[a-z]/g;
const REGEX_UPPERCASE = /[A-Z]/g;
const REGEX_SPECIAL_CHAR = /[@!#$%&'*+/=?^_`{|}~\][+]/g;
const REGEX_DIGIT = /[0-9]/g;

export const passwordsMatch = (values) => {
  const match = values.password === values.passwordConfirm;

  if (match) {
    return null;
  }

  return {
    type: 'passwords-not-match',
    message: t('registration.form.errors.passwords_not_match'),
  };
};

export const passwordsFilled = (values) => {
  const exist = values.password && values.passwordConfirm;

  if (exist) {
    return null;
  }

  return {
    type: 'passwords-not-filled',
    message: t('registration.form.errors.passwords_not_filled'),
  };
};

export const passwordsLongEnough =
  ({ minimumLength = MINIMUM_LENGTH } = {}) =>
  (values) => {
    const { password } = values;
    const longEnough = password && password.length >= minimumLength;

    if (longEnough) {
      return null;
    }

    return {
      type: 'passwords-not-long-enough',
      message: t('registration.form.errors.passwords_not_long_enough', { minimumLength }),
    };
  };

export const passwordsHaveBothCases = (values) => {
  const { password } = values;
  const haveBothCases =
    password && password.match(REGEX_LOWERCASE) && password.match(REGEX_UPPERCASE);

  if (haveBothCases) {
    return null;
  }

  return {
    type: 'passwords-not-both-cases',
    message: t('registration.form.errors.passwords_not_both_cases'),
  };
};

export const passwordsHaveSpecials = (values) => {
  const { password } = values;
  const longEnough =
    password && (password.match(REGEX_DIGIT) || password.match(REGEX_SPECIAL_CHAR));

  if (longEnough) {
    return null;
  }

  return {
    type: 'passwords-not-specials',
    message: t('registration.form.errors.passwords_not_specials'),
  };
};

export const singlePasswordValidator = (values, { minimumLength } = {}) =>
  generateValidation([
    passwordsHaveSpecials,
    passwordsHaveBothCases,
    passwordsLongEnough({ minimumLength }),
  ])(values);

export default (values, { minimumLength } = {}) =>
  generateValidation([
    passwordsHaveSpecials,
    passwordsHaveBothCases,
    passwordsLongEnough({ minimumLength }),
    passwordsMatch,
  ])(values);
