import React from 'react';

import styles from './SectionHeader.module.css';

type SectionHeaderProps = {
  header: string;
  subheader?: string;
  description?: string;
  requiredMessage?: string;
};

const SectionHeader = ({ header, subheader, description, requiredMessage }: SectionHeaderProps) => (
  <div className={styles.container}>
    <div className={styles.headerWrapper}>
      <span className={styles.header}>{header}</span>
    </div>
    {(subheader || description || requiredMessage) && (
      <div className={styles.subheaderWrapper}>
        <div>
          {subheader && <span className={styles.subheader}>{subheader}</span>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {requiredMessage && <p className={styles.requiredMessage}>{requiredMessage}</p>}
      </div>
    )}
  </div>
);

export default SectionHeader;
