import DefaultTheme from 'react-dates-legacy/lib/theme/DefaultTheme';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';

const SELECTED_FONT_COLOR = '#fff';
const SELECTED_BORDER_COLOR = 'rgba(228, 231, 231, 0.2)';

const colorTheme = {
  color: SELECTED_FONT_COLOR,
  color_hover: SELECTED_FONT_COLOR,
  color_active: SELECTED_FONT_COLOR,
};

const borderTheme = {
  borderColor: SELECTED_BORDER_COLOR,
  borderColor_active: SELECTED_BORDER_COLOR,
  borderColor_hover: SELECTED_BORDER_COLOR,
};

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    color: {
      ...DefaultTheme.reactDates.color,
      selected: {
        ...DefaultTheme.reactDates.color.selected,
        ...colorTheme,
        ...borderTheme,
        backgroundColor: '#0066b3',
        backgroundColor_hover: '#0066b3',
        backgroundColor_active: '#005799',
      },
      selectedSpan: {
        ...DefaultTheme.reactDates.color.selectedSpan,
        ...colorTheme,
        ...borderTheme,
        backgroundColor: '#2382c8',
        backgroundColor_hover: '#0066b3',
        backgroundColor_active: '#005799',
      },

      hoveredSpan: {
        ...DefaultTheme.reactDates.color.hoveredSpan,
        ...colorTheme,
        ...borderTheme,
        backgroundColor: '#2382c8',
        backgroundColor_hover: '#0066b3',
        backgroundColor_active: '#005799',
      },
    },
  },
});
