import React, { ReactNode } from 'react';
import { CellProps, Column } from 'react-table';

import { formatMoney, l, t } from 'shared/utils/index';
import { CashTransaction } from 'types/entities/CashTransaction';
import TableCell from 'components/v2/Table/TableCell/TableCell';
import TableHeader from 'components/v2/Table/TableHeader/TableHeader';
import { GrossAmount } from 'features/cashbooks/CashTransactions/CashTransactions.styled';

export type RowData = {
  id: string;
  idNumber: string;
  documentNumber: string | null;
  transactionDate: string;
  description: string;
  grossAmount: ReactNode;
  vat: string;
  taxAmount: string;
  cashbookId: string;
  reasonForDeletion: string | null;
  empty: string;
  hasFiles: boolean;
};

/* eslint-disable react/display-name */
export const getColumns = (): Column<RowData>[] => [
  {
    Header: t('cashbooks.cash_transactions.table.headers.id_number'),
    accessor: 'idNumber',
  },
  {
    Header: t('cashbooks.cash_transactions.table.headers.transaction_date'),
    accessor: 'transactionDate',
  },
  {
    Header: t('cashbooks.cash_transactions.table.headers.document_number'),
    accessor: 'documentNumber',
  },
  {
    Header: t('cashbooks.cash_transactions.table.headers.description'),
    accessor: 'description',
  },
  {
    Header: (
      <TableHeader align="right">
        {t('cashbooks.cash_transactions.table.headers.gross_amount')}
      </TableHeader>
    ),
    accessor: 'grossAmount',
    Cell: ({ value }: CellProps<RowData>) => <TableCell align="right">{value}</TableCell>,
  },
  {
    Header: (
      <TableHeader align="right">{t('cashbooks.cash_transactions.table.headers.vat')}</TableHeader>
    ),
    accessor: 'vat',
    Cell: ({ value }: CellProps<RowData>) => <TableCell align="right">{value}</TableCell>,
  },
  {
    Header: (
      <TableHeader align="right">
        {t('cashbooks.cash_transactions.table.headers.tax_amount')}
      </TableHeader>
    ),
    accessor: 'taxAmount',
    Cell: ({ value }: CellProps<RowData>) => <TableCell align="right">{value}</TableCell>,
  },
];
/* eslint-enable react/display-name */

export const getTableData = ({
  cashTransactions,
}: {
  cashTransactions: CashTransaction[];
}): RowData[] =>
  cashTransactions.map((cashTransaction) => ({
    id: cashTransaction.id,
    documentNumber: cashTransaction.documentNumber,
    idNumber: cashTransaction.idNumber,
    transactionDate: cashTransaction.transactionDate,
    description: cashTransaction.description,
    grossAmount: (
      <GrossAmount isNegative>{formatMoney(-1 * cashTransaction.grossAmount)}</GrossAmount>
    ),
    vat: l(cashTransaction.vat, 'percentage', { precision: 2 }),
    taxAmount: formatMoney(cashTransaction.taxAmount),
    cashbookId: cashTransaction.cashbookId,
    reasonForDeletion: cashTransaction.reasonForDeletion,
    hasFiles: cashTransaction.hasFiles,
    empty: '',
  }));
