import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { getExpenseCashTransactions } from 'api/me/datevReports';
import EntityPath from 'constants/entitiesPaths';
import { AppThunk } from 'store';
import type { CashTransaction } from 'types/entities/CashTransaction';

import * as formSelectors from '../selectors';
import * as selectors from './selectors';

type Pagination = {
  pageIndex: number;
  pageCount: number;
};

type State = {
  params: Pagination;
  isLoading: boolean;
  error: string | null;
  data: CashTransaction[];
};

const initialState: State = {
  params: {
    pageIndex: 0,
    pageCount: 0,
  },
  isLoading: false,
  error: null,
  data: [],
};

const slice = createSlice({
  name: 'datev/datevExport/expenseCashTransactions',
  initialState,
  reducers: {
    setStart(state) {
      state.isLoading = true;
    },
    setSuccess(state, { payload }: PayloadAction<CashTransaction[]>) {
      state.data = payload;
      state.isLoading = false;
    },
    setFailure(state) {
      state.isLoading = false;
    },
    setParams(state, { payload }: PayloadAction<Partial<Pagination>>) {
      state.params = { ...state.params, ...payload };
    },
  },
});

export const { setParams } = slice.actions;

export default slice.reducer;

export const getData = (): AppThunk => async (dispatch, getState) => {
  const { setStart, setSuccess, setFailure } = slice.actions;
  const state = getState();
  const { pageIndex } = selectors.getParams(state);
  const {
    // @ts-ignore not typed
    values: { startDate, endDate },
  } = formSelectors.getDatevExportDates(state);
  let response;

  dispatch(setStart());

  try {
    response = await getExpenseCashTransactions({
      page: pageIndex + 1,
      startDate: startDate,
      endDate: endDate,
    });
  } catch (error) {
    dispatch(setFailure());
    return;
  }

  const ids = response.data.data.map((item) => item.id);
  const cashTransactions =
    build<CashTransaction>(normalize(response.data), EntityPath.CashTransactions, ids, {
      ignoreLinks: true,
    }) || [];
  const pageCount = parseInt(response.headers['total-pages'], 10);

  dispatch(setParams({ pageCount }));
  dispatch(setSuccess(cashTransactions));
};
