import React, { memo, SVGProps } from 'react';

const Lamp = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#F60" cx={12} cy={12} r={12} />
      <g fill="#FAFAFA">
        <path
          d="M11.357 4.537a5.48 5.48 0 0 1 4.297 1.355 5.506 5.506 0 0 1 1.846 4.122 5.49 5.49 0 0 1-1.561 3.842c-.695.713-1.123 2.336-1.281 2.974l-4.89.697c-.47-1.616-1.022-2.911-1.829-3.795a5.51 5.51 0 0 1-1.4-4.386 5.485 5.485 0 0 1 1.58-3.238 5.465 5.465 0 0 1 3.238-1.571Z"
          stroke="#FAFAFA"
        />
        <path d="M13.5 20h-3a.5.5 0 1 1 0-1h3a.5.5 0 1 1 0 1Z" />
      </g>
      <g stroke="#F60" strokeLinecap="round">
        <path d="M12 17v-5.152L14 10M12 12l-2-2" />
      </g>
    </g>
  </svg>
);

export default memo(Lamp);
