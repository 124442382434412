import React, { memo, SVGProps } from 'react';

const Crown = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path
        d="M19.377 15.341l-2.458-5.735-.058-.115c-.4-.691-1.451-.653-1.78.115l-2.459 5.735-6.963-6.094c-.74-.646-1.87.048-1.628 1l3.555 14a1 1 0 00.97.753h14.888a1 1 0 00.97-.754l3.555-14c.242-.951-.889-1.645-1.628-.999l-6.964 6.094zM16 12.538l2.08 4.856.057.112a1 1 0 001.522.247l5.58-4.884L22.666 23H9.333L6.76 12.869l5.581 4.884a1 1 0 001.578-.36L16 12.539z"
        fillRule="nonzero"
      />
      <circle cx={16} cy={6} r={2} />
      <circle cx={28} cy={6} r={2} />
      <circle cx={4} cy={6} r={2} />
      <rect x={8} y={27} width={16} height={2} rx={1} />
    </g>
  </svg>
);

export default memo(Crown);
