export const NAMESPACE = 'INCOMING_INVOICES';

export const INDEX_REQUEST = `${NAMESPACE}/INDEX_REQUEST`;
export const INDEX_SUCCESS = `${NAMESPACE}/INDEX_SUCCESS`;
export const INDEX_FAILURE = `${NAMESPACE}/INDEX_FAILURE`;
export const INDEX_RESET = `${NAMESPACE}/INDEX_RESET`;

export const DELETE_REQUEST = `${NAMESPACE}/DELETE_REQUEST`;
export const DELETE_SUCCESS = `${NAMESPACE}/DELETE_SUCCESS`;
export const DELETE_FAILURE = `${NAMESPACE}/DELETE_FAILURE`;

export const INDEX_MORE_REQUEST = `${NAMESPACE}/INDEX_MORE_REQUEST`;
export const INDEX_MORE_SUCCESS = `${NAMESPACE}/INDEX_MORE_SUCCESS`;
export const INDEX_MORE_FAILURE = `${NAMESPACE}/INDEX_MORE_FAILURE`;

export const INDEX_SORT = `${NAMESPACE}/INDEX_SORT`;

export const SEARCH_REQUEST = `${NAMESPACE}/SEARCH_REQUEST`;
export const SEARCH_SUCCESS = `${NAMESPACE}/SEARCH_SUCCESS`;
export const SEARCH_FAILURE = `${NAMESPACE}/SEARCH_FAILURE`;

export const TOGGLE_PAID_BY_CASH_REQUEST = `${NAMESPACE}/TOGGLE_PAID_BY_CASH_REQUEST`;
export const TOGGLE_PAID_BY_CASH_SUCCESS = `${NAMESPACE}/TOGGLE_PAID_BY_CASH_SUCCESS`;
export const TOGGLE_PAID_BY_CASH_FAILURE = `${NAMESPACE}/TOGGLE_PAID_BY_CASH_FAILURE`;

export const TOGGLE_PAID_BY_UNSPECIFIED_REQUEST = `${NAMESPACE}/TOGGLE_PAID_BY_UNSPECIFIED_REQUEST`;
export const TOGGLE_PAID_BY_UNSPECIFIED_SUCCESS = `${NAMESPACE}/TOGGLE_PAID_BY_UNSPECIFIED_SUCCESS`;
export const TOGGLE_PAID_BY_UNSPECIFIED_FAILURE = `${NAMESPACE}/TOGGLE_PAID_BY_UNSPECIFIED_FAILURE`;

export const TOGGLE_CREDIT_NOTE_SUCCESS = `${NAMESPACE}/TOGGLE_CREDIT_NOTE_SUCCESS`;
export const TOGGLE_CREDIT_NOTE_FAILURE = `${NAMESPACE}/TOGGLE_CREDIT_NOTE_FAILURE`;
export const TOGGLE_CREDIT_NOTE_REQUEST = `${NAMESPACE}/TOGGLE_CREDIT_NOTE_REQUEST`;

export const PAGINATION = `${NAMESPACE}/PAGINATION`;

export const FORM_NAME = 'invoiceCreator';

export const FETCH_REQUEST_SUB_INVOICES = `${NAMESPACE}/FETCH_REQUEST_SUB_INVOICES`;
export const FETCH_SUCCESS_SUB_INVOICES = `${NAMESPACE}/FETCH_SUCCESS_SUB_INVOICES`;
export const FETCH_FAILURE_SUB_INVOICES = `${NAMESPACE}/FETCH_FAILURE_SUB_INVOICES`;

export const DUPLICATE_INVOICE_REQUEST = 'INCOMING_INVOICES/DUPLICATE_INVOICE_REQUEST';
export const DUPLICATE_INVOICE_SUCCESS = 'INCOMING_INVOICES/DUPLICATE_INVOICE_SUCCESS';
export const DUPLICATE_INVOICE_FAILURE = 'INCOMING_INVOICES/DUPLICATE_INVOICE_FAILURE';

export const FROM_DUPLICATE_QUERY_PARAM = 'from-duplicate';

export const DELETE_ALL_BANK_TRANSFER_CONNECTIONS_REQUEST =
  'INCOMING_INVOICE/DELETE_ALL_BANK_TRANSFER_CONNECTIONS_REQUEST';
export const DELETE_ALL_BANK_TRANSFER_CONNECTIONS_SUCCESS =
  'INCOMING_INVOICE/DELETE_ALL_BANK_TRANSFER_CONNECTIONS_SUCCESS';
export const DELETE_ALL_BANK_TRANSFER_CONNECTIONS_FAILURE =
  'INCOMING_INVOICE/DELETE_ALL_BANK_TRANSFER_CONNECTIONS_FAILURE';
