import { omit } from 'lodash';
import { formValueSelector } from 'redux-form';

import type { ExpenseContract, RevenueContract } from 'types/entities/Contract';

import { FORM_NAME } from '../constants';

export const getInitialValues = (contract: ExpenseContract | RevenueContract | null) => {
  if (contract === null) return {};

  // TODO: Add the correct type for initial form values
  const values: any = {
    ...contract,
    transactionDate: contract.firstTransaction,
    recurringTransactionIntervalId: contract.recurringTransactionInterval?.id,
    invoiceLineCategoryId: contract.invoiceLineCategory?.id,
  };

  if (contract.isRevenue) {
    values.client = contract.client;
  } else {
    values.recurringTransactionIntervalId = contract.recurringTransactionInterval?.id;
    values.supplier = contract.supplier;
  }

  return omit(values, ['installments', 'recurringTransactionInterval', 'firstTransaction']);
};

export const contractSelector = formValueSelector(FORM_NAME);
