import React from 'react';
import { func, string } from 'prop-types';

import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import { ButtonAppearances } from 'components/Button';

import styles from './AbortButton.module.css';

const AbortButton = ({ onClick, dataId }) => (
  <div className={styles.transparentContainer}>
    <ActionButton
      fullWidth
      appearance={ButtonAppearances.outlined}
      onClick={onClick}
      label={t('proposals.form.abort')}
      dataId={dataId}
    />
  </div>
);

AbortButton.propTypes = {
  onClick: func,
  dataId: string,
};

export default AbortButton;
