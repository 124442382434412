import paths from 'routes/paths';

export const MODELS = {
  CLIENT: 'client',
  SUPPLIER: 'supplier',
};

export const CLIENT_FIELDS = [
  'honorific',
  'first_name',
  'last_name',
  'company_name',
  'address_addition',
  'email',
  'website',
  'phone_number',
  'fax_number',
  'vat_id',
  'street',
  'zipcode',
  'city',
  'country',
  'bank_name',
  'iban',
  'bic',
];

export const SUPPLIER_FIELDS = [
  'honorific',
  'first_name',
  'last_name',
  'company_name',
  'address_addition',
  'email',
  'website',
  'phone_number',
  'fax_number',
  'tax_number',
  'street',
  'zipcode',
  'city',
  'country',
  'bank_name',
  'iban',
  'bic',
];

export const buttons = {
  client: {
    importCaption: 'clients.import',
    newCaption: 'clients.add_new',
    importPath: paths.clientsImport,
    newPath: paths.clientsNew,
  },
  supplier: {
    importCaption: 'suppliers.import',
    newCaption: 'suppliers.add_new',
    importPath: paths.suppliersImport,
    newPath: paths.suppliersNew,
  },
};
