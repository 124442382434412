import React from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';

import Button from 'components/Button';
import CardView, { HeadingSection, Section } from 'components/CardView';

import sharedStyles from '../../pages/Shared.module.css';
import localStyles from './AccountInfoPublicArea.module.css';

const styles = { ...sharedStyles, ...localStyles };

type AccountInfoPublicAreaProps = {
  headingText: string;
  icon: string;
  infoText: string[];
  buttonLabel?: string;
  onClick?: () => void;
  iconClassName: string;
  isButtonDisabled?: boolean;
};

const AccountInfoPublicArea = ({
  headingText,
  icon,
  infoText,
  buttonLabel,
  onClick = () => {},
  iconClassName = '',
  isButtonDisabled,
}: AccountInfoPublicAreaProps) => (
  <div className={styles.pageWrapper}>
    <div className={styles.wrapper}>
      <CardView className={styles.card}>
        <HeadingSection
          dataId="AccountInfoPublicArea:heading-section"
          className={styles.cardSectionHeading}
        >
          {headingText}
        </HeadingSection>
        <Section className={styles.cardSection}>
          <InlineSvg src={icon} className={cx(styles.icon, iconClassName)} />
          <div data-id="AccountInfoPublicArea:card-title" className={styles.subtitle}>
            {infoText.map((p) => (
              <p key={p} className={styles.paragraph}>
                {p}
              </p>
            ))}
          </div>
          {buttonLabel && onClick && (
            <Button
              centered
              disabled={isButtonDisabled}
              className={styles.actionButton}
              dataId="AccountInfoPublicArea:action-button"
              onClick={onClick}
              label={buttonLabel}
            />
          )}
        </Section>
      </CardView>
    </div>
  </div>
);

export default AccountInfoPublicArea;
