import React, { SVGAttributes } from 'react';

const IconComment = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 20 20" {...props}>
      <g fill="none">
        <rect width={20} height={20} rx={1} />
        <path
          d="M2.507 5c0-.825.668-1.5 1.493-1.5h12c.825 0 1.5.675 1.5 1.5v9c0 .825-.675 1.5-1.5 1.5H5.5l-3 3L2.507 5zM5.5 12.5h9V11h-9v1.5zm0-2.25h9v-1.5h-9v1.5zM5.5 8h9V6.5h-9V8z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default IconComment;
