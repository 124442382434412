import React, { memo, ReactNode } from 'react';
import cx from 'classnames';

import styles from './TwoColumnLayout.module.css';

type RowProps = {
  children: ReactNode;
  className?: string;
};

const Row = ({ children, className }: RowProps) => (
  <div className={cx(styles.row, className)}>{children}</div>
);

export default memo(Row);
