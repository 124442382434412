import React, { ComponentType } from 'react';
import { snakeCase } from 'lodash';
import { Field } from 'redux-form';

import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { PasswordFieldProps } from 'components/Form/PasswordField/PasswordField.types';

interface FormFieldProps extends PasswordFieldProps {
  name: string;
  component: ComponentType;
}

const FormField = ({ name, ...rest }: FormFieldProps) => (
  <Field
    id={name}
    name={name}
    checker={serverValidationChecker}
    label={t(`profile.personal.${snakeCase(name)}`)}
    {...rest}
  />
);

export default FormField;
