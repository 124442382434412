import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';

import { fetchCustomerDefaults as fetchCustomerDefaultsAction } from 'actions/default-values';
import { t } from 'shared/utils';
import Section from 'components/Form/Section/Section';

import InvoiceEmailsDefaults, {
  FORM_NAME as INVOICE_EMAILS_DEFAULTS_FORM_NAME,
} from './InvoiceEmailsDefaults/InvoiceEmailsDefaults';
import OrderConfirmationEmailsDefaults, {
  FORM_NAME as ORDER_CONFIRMATION_EMAILS_DEFAULTS_FORM_NAME,
} from './OrderConfirmationEmailsDefaults/OrderConfirmationEmailsDefaults';
import PaymentReminderEmailsDefaults, {
  FORM_NAME as PAYMENT_REMINDER_EMAILS_DEFAULTS_FORM_NAME,
} from './PaymentReminderEmailsDefaults/PaymentReminderEmailsDefaults';
import ProposalEmailsDefaults, {
  FORM_NAME as PROPOSALS_EMAILS_DEFAULTS_FORM_NAME,
} from './ProposalEmailsDefaults/ProposalEmailsDefaults';

import styles from './TabEmailsDefaults.module.css';

export type EmailsFormName =
  | 'InvoiceEmailsDefaults'
  | 'PaymentReminderEmailsDefaults'
  | 'OrderConfirmationEmailsDefaults'
  | 'ProposalEmailsDefaults';

interface TabEmailsDefaultsProps {
  fetchCustomerDefaults: () => void;
}

const TabEmailsDefaults = ({ fetchCustomerDefaults }: TabEmailsDefaultsProps) => {
  useEffect(() => {
    fetchCustomerDefaults();
  }, [fetchCustomerDefaults]);

  return (
    <div className={styles.container}>
      <Section
        className={styles.section}
        title={t('invoice_templates.emails_defaults.invoice_defaults_section')}
      >
        <InvoiceEmailsDefaults />
      </Section>
      <Section
        className={styles.section}
        title={t('invoice_templates.emails_defaults.payment_reminder_defaults_section')}
      >
        <PaymentReminderEmailsDefaults />
      </Section>
      <Section
        className={styles.section}
        title={t('invoice_templates.emails_defaults.proposal_defaults_section')}
      >
        <ProposalEmailsDefaults />
      </Section>
      <Section
        className={styles.section}
        title={t('invoice_templates.emails_defaults.order_confirmation_defaults_section')}
      >
        <OrderConfirmationEmailsDefaults />
      </Section>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  isDirty:
    isDirty(INVOICE_EMAILS_DEFAULTS_FORM_NAME)(state) ||
    isDirty(PAYMENT_REMINDER_EMAILS_DEFAULTS_FORM_NAME)(state) ||
    isDirty(PROPOSALS_EMAILS_DEFAULTS_FORM_NAME)(state) ||
    isDirty(ORDER_CONFIRMATION_EMAILS_DEFAULTS_FORM_NAME)(state),
});

const mapDispatchToProps = {
  fetchCustomerDefaults: fetchCustomerDefaultsAction,
};

export default connect<{}, TabEmailsDefaultsProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(TabEmailsDefaults);
