import React, { SVGAttributes } from 'react';

const IconShow = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 14 14" {...props}>
    <path
      d="M13.686 11.843l-3.349-3.348a5.449 5.449 0 00.796-2.844C11.133 2.623 8.509 0 5.48 0A5.48 5.48 0 000 5.481c0 3.027 2.624 5.65 5.65 5.65a5.45 5.45 0 002.76-.747l3.367 3.368a.844.844 0 001.193 0l.835-.835c.329-.33.21-.745-.12-1.074zM1.687 5.481a3.794 3.794 0 013.794-3.795c2.097 0 3.965 1.867 3.965 3.965 0 2.095-1.7 3.795-3.795 3.795-2.097-.001-3.964-1.87-3.964-3.965z"
      fillRule="evenodd"
    />
  </svg>
);

export default IconShow;
