import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { documentLayoutEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'shared/hooks/app';
import { getFinishedInvoiceTemplatesStep, getIsLoaded } from 'store/slices/onboarding/selectors';
import { updateOnboarding } from 'store/slices/onboarding/thunks';

import DocumentsDefaults from './DocumentsDefaults/DocumentsDefaults';
import EmailsDefaults from './EmailsDefaults/EmailsDefaults';
import InvoiceTemplate from './InvoiceTemplate/InvoiceTemplate';
import Layout from './Layout/Layout';
import LogoAndBriefpapier from './LogoAndBriefpapier/LogoAndBriefpapier';
import Table from './Table/Table';

const Routes = () => {
  const match = useRouteMatch();
  const dispatch = useAppDispatch();
  const isDocumentLayoutEnabled = useAppSelector(documentLayoutEnabledHelper);
  const onboardingIsLoaded = useAppSelector(getIsLoaded);
  const areInvoiceTemplatesVisited = useAppSelector(getFinishedInvoiceTemplatesStep);

  useEffect(() => {
    if (!onboardingIsLoaded || areInvoiceTemplatesVisited) return;

    dispatch(updateOnboarding({ visited_invoice_templates: true }));
  }, [onboardingIsLoaded, areInvoiceTemplatesVisited, dispatch]);

  if (!match) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={`${match.path}/logo-and-briefpapier`}>
        <LogoAndBriefpapier />
      </Route>
      <Route exact path={`${match.path}/layout`}>
        {isDocumentLayoutEnabled ? <Layout /> : <Redirect to={paths.home} />}
      </Route>
      <Route exact path={`${match.path}/template-edit`}>
        <InvoiceTemplate />
      </Route>
      <Route exact path={`${match.path}/table`}>
        {isDocumentLayoutEnabled ? <Table /> : <Redirect to={paths.home} />}
      </Route>
      <Route exact path={`${match.path}/documents-defaults`}>
        <DocumentsDefaults />
      </Route>
      <Route exact path={`${match.path}/email-defaults`}>
        <EmailsDefaults />
      </Route>
      <Route
        path={match.path}
        render={() => <Redirect to={`${match.path}/logo-and-briefpapier`} />}
      />
    </Switch>
  );
};

export default Routes;
