import React, { memo } from 'react';
import cx from 'classnames';

import { t } from 'shared/utils';

import IconSecurity from './IconSecurity';

import styles from './WelcomeTile.module.css';

const Security = () => (
  <>
    <IconSecurity className={cx(styles.icon, styles.iconSecurity)} />
    <div className={styles.header}>1. {t('contracts.welcome_tile.columns.security.header')}</div>
    <div className={styles.description}>
      {t('contracts.welcome_tile.columns.security.description')}
    </div>
  </>
);

export default memo(Security);
