import React from 'react';
import cx from 'classnames';
import { arrayOf, bool, node, oneOfType } from 'prop-types';

import styles from './Table.module.css';

export const Table = ({ hasErrors, children }) => (
  <div className={cx(styles.table, { [styles.invalid]: hasErrors })}>{children}</div>
);

Table.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
  hasErrors: bool,
};
