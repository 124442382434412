import React, { ReactNode } from 'react';

import { Icon, Title, Wrapper } from './Card.styled';

export type CardProps = {
  color: 'blue' | 'darkblue' | 'orange';
  icon: ReactNode;
  title: string;
  onClick: () => void;
};

const Card = ({ color, icon, title, onClick }: CardProps) => {
  return (
    <Wrapper $color={color} onClick={onClick}>
      <Icon>{icon}</Icon>
      <Title>{title}</Title>
    </Wrapper>
  );
};

export default Card;
