import styled, { css } from 'styled-components';

import BaseIconClose from 'redesign/components/atoms/Icons/Close';
import BaseIconLamp from 'redesign/components/atoms/Icons/Lamp';
import BaseIconSearch from 'redesign/components/atoms/Icons/Search';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Input = styled.input`
  width: 100%;
`;

export const Menu = styled.div`
  position: absolute;
  z-index: 10000;
  overflow-y: scroll;
  max-height: 300px;
  background-color: white;
  min-width: 400px;
  box-shadow: 2px 2px 20px 0 rgb(0 0 0 / 40%);
  width: 100%;
`;

const ItemContainer = styled.div`
  cursor: pointer;
  padding: ${({ theme }) => theme.space['1']};
  padding-top: ${({ theme }) => theme.space['2']};
  padding-bottom: ${({ theme }) => theme.space['2']};
`;

export const MainCategory = styled(ItemContainer)`
  font-weight: bold;
  background-color: ${({ theme }) => theme.legacy.colors['primaryBackground']};
`;

type SubCategoryProps = {
  highlighted?: boolean;
};

export const SubCategory = styled(ItemContainer)<SubCategoryProps>`
  padding-left: ${({ theme }) => theme.space['5']};
  background-color: '#fff';

  ${({ highlighted }) =>
    highlighted &&
    css`
      font-weight: bold;
      background-color: ${({ theme }) => theme.colors.vrblue['025']};
    `};

  &:hover {
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.vrblue['025']};
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.space['6']};
  right: 0;
  z-index: 10;
  cursor: pointer;
`;

export const SearchIcon = styled(BaseIconSearch)`
  width: ${({ theme }) => theme.space['5']};
  height: ${({ theme }) => theme.space['5']};
  color: ${({ theme }) => theme.colors.vrblue['100']};
`;

export const CloseIcon = styled(BaseIconClose)`
  width: ${({ theme }) => theme.space['5']};
  height: ${({ theme }) => theme.space['5']};
  color: ${({ theme }) => theme.colors.vrblue['100']};
  background-color: white;
`;

export const MatchingCategoriesHeader = styled.div`
  display: flex;
  height: ${({ theme }) => theme.space['6']};
  padding-left: ${({ theme }) => theme.space['2']};
  font-size: ${({ theme }) => theme.fontSize['xs']};
  font-weight: bold;
  color: ${({ theme }) => theme.legacy.colors.greyLight};
  background: ${({ theme }) => theme.legacy.colors['primaryBackground']};
  align-items: center;
`;

export const Hint = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize['xs']};
  color: ${({ theme }) => theme.legacy.colors.greyLight};

  p {
    margin: auto 0;
  }

  span {
    font-weight: bold;
  }

  div {
    display: flex;
    margin-right: ${({ theme }) => theme.space['2']};
  }
`;

export const IconLamp = styled(BaseIconLamp)`
  width: ${({ theme }) => theme.space['6']};
  height: ${({ theme }) => theme.space['6']};
`;

export const LoadingWrapper = styled.div`
  padding: ${({ theme }) => theme.space['5']};
  display: flex;
  justify-content: center;
  align-items: center;
`;
