import { CALL_API } from 'constants/api';
import {
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  SET_ACTIVE_MESSAGE,
  TOGGLE_READ_FAILURE,
  TOGGLE_READ_REQUEST,
  TOGGLE_READ_SUCCESS,
} from 'constants/message-inbox';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

const apiFetchCustomerMessages = (etag = '') => ({
  [CALL_API]: {
    endpoint: `/me/customer_notifications/messages`,
    method: 'GET',
    headers: { 'if-none-match': etag },
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const fetchCustomerMessages =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchCustomerMessages(...args)).catch((err) => apiErrorHandler(err));

const apiToggleRead = (id, data) => ({
  [CALL_API]: {
    data,
    method: 'PATCH',
    endpoint: `/me/customer_notifications/messages/${id}/toggle_read`,
    types: [TOGGLE_READ_REQUEST, TOGGLE_READ_SUCCESS, TOGGLE_READ_FAILURE],
  },
});

export const markAsRead = (id) => (dispatch) =>
  bindServerValidation(apiToggleRead(id, { marked_as_read: true }), dispatch, {
    isReduxForm: false,
  });

export const markAsUnread = (id) => (dispatch) =>
  bindServerValidation(apiToggleRead(id, { marked_as_read: false }), dispatch, {
    isReduxForm: false,
  });

export const apiDeleteCustomerMessage = (id) => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: `/me/customer_notifications/messages/${id}`,
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const deleteMessage = (id) => (dispatch) =>
  bindServerValidation(apiDeleteCustomerMessage(id), dispatch, {
    isReduxForm: false,
  });

export const setActiveMessage = (id = null) => ({
  type: SET_ACTIVE_MESSAGE,
  payload: { activeMessageId: id },
});
