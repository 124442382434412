import { JsonApiResponse, JsonApiResponseSingle } from 'json-api-normalizer';

import { InvoiceTemplate } from 'types/entities/InvoiceTemplate';

import api from '../index';

export const getInvoiceTemplates = () => {
  const url = '/me/invoice_templates';

  return api.get<JsonApiResponse>(url, { params: { page: 1, per_page: 10000 } });
};

export const getInvoiceTemplate = (id: string) => {
  const url = `/me/invoice_templates/${id}`;

  return api.get<JsonApiResponseSingle>(url);
};

export const updateInvoiceTemplate = (
  id: string,
  data: Partial<Omit<InvoiceTemplate, 'logo' | 'background'>>
) => {
  const url = `/me/invoice_templates/${id}`;

  return api.put<JsonApiResponseSingle>(url, {
    bank_name: data.bankName,
    bic: data.bic,
    iban: data.iban,
    logo_position: data.logoPosition,
    logo_size_percentage: data.logoSizePercentage,
    name: data.name,
    preview_url: data.previewUrl,
    qr_codes_enabled: data.qrCodesEnabled,
    show_contact_details: data.showContactDetails,
    show_footer: data.showFooter,
    show_sender_address: data.showSenderAddress,
    header_version: data.headerVersion,
    table_version: data.tableVersion,
    footer_version: data.footerVersion,
    preset: data.preset,
    font_family: data.fontFamily,
    font_size_address: data.fontSizeAddress,
    font_size_invoice_info: data.fontSizeInvoiceInfo,
    font_size_subject: data.fontSizeSubject,
    font_size_table_content: data.fontSizeTableContent,
    font_size_table_headers: data.fontSizeTableHeaders,
    font_size_notes: data.fontSizeNotes,
    font_size_footer: data.fontSizeFooter,
    font_size_table_description: data.fontSizeTableDescription,
    coloured_elements_hex: data.colouredElementsHex,
    invert_coloured_elements_text_color: data.invertColouredElementsTextColor,
    table_header_description_text: data.tableHeaderDescriptionText,
    table_header_net_amount_text: data.tableHeaderNetAmountText,
    table_header_position_text: data.tableHeaderPositionText,
    table_header_quantity_text: data.tableHeaderQuantityText,
    table_header_total_line_net_amount_text: data.tableHeaderTotalLineNetAmountText,
    table_header_unit_text: data.tableHeaderUnitText,
    table_header_vat_text: data.tableHeaderVatText,
    table_summary_gross_total_text: data.tableSummaryGrossTotalText,
    table_summary_net_total_text: data.tableSummaryNetTotalText,
    logo_on_all_pages: data.logoOnAllPages,
    background_only_on_first_page: data.backgroundOnlyOnFirstPage,
  });
};

export const uploadLogo = (id: string, logo: File) => {
  const formData = new FormData();
  const url = `/me/invoice_templates/${id}`;

  formData.append('logo', logo);

  return api.put<JsonApiResponseSingle>(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteLogo = (id: string) => {
  const url = `/me/invoice_templates/${id}/delete_logo`;

  return api.delete<JsonApiResponseSingle>(url);
};

export const uploadBackground = (id: string, background: File) => {
  const formData = new FormData();
  const url = `/me/invoice_templates/${id}`;

  formData.append('background', background);

  return api.put<JsonApiResponseSingle>(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteBackground = (id: string) => {
  const url = `/me/invoice_templates/${id}/delete_background`;

  return api.delete<JsonApiResponseSingle>(url);
};
