import React from 'react';
import cx from 'classnames';
import { number, string } from 'prop-types';

import { formatMoney } from 'shared/utils';

import styles from './Amount.module.css';

const Amount = ({ amount, currency, dataId = '' }) => (
  <span
    data-id={dataId}
    className={cx(styles.amount, {
      [styles.negative]: amount < 0,
      [styles.positive]: amount >= 0,
    })}
  >
    {formatMoney(amount, currency)}
  </span>
);

Amount.propTypes = {
  amount: number.isRequired,
  currency: string,
  dataId: string,
};

export default Amount;
