import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import { createIncomingInvoice } from 'actions/incoming-invoice';
import { NEW_MODE } from 'constants/common/crud';
import IncomingInvoiceCreator from 'containers/IncomingInvoices/IncomingInvoiceCreator/IncomingInvoiceCreator';
import { getDefaultCategory } from 'components/LineItems/utils';
import LoadingIcon from 'components/LoadingIcon';

const InvoiceNew = ({
  createIncomingInvoice,
  location = {},
  needsAnotherDatevReport,
  lineCategories,
  invoiceLineItems,
  selectedCategory,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [showDialogFromNewInvoice, setShowDialogFromNewInvoice] = useState(
    needsAnotherDatevReport.showDialog
  );
  const [canSubmit, setCanSubmit] = useState(false);
  const [lineItemsState, setLineItemsState] = useState({});

  const defaultCategory = getDefaultCategory(lineCategories);

  const hasSavedLineItems = !!invoiceLineItems.length;
  const bufferLineItemsState = {};
  const handleLineItemStateChange = ({ id, open }) => {
    bufferLineItemsState[id] = open;
    setLineItemsState({ ...lineItemsState, ...bufferLineItemsState, [id]: open });
  };

  const showDatevModal = () => setShowDialogFromNewInvoice(true);
  const closeDatevModal = () => setShowDialogFromNewInvoice(false);

  const fetchData = async () => {
    try {
      setIsFetching(true);
      await createIncomingInvoice();
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle the saving behaviour of the document based on line items and selected Category
  useEffect(() => {
    //get the keys on lineItemsState
    const keys = Object.values(lineItemsState);
    //check if every line Item is saved/closed
    const allLineItemsSaved = keys.length ? keys.every((open) => !open) : true;

    setCanSubmit(allLineItemsSaved);
  }, [selectedCategory, lineCategories, lineItemsState]);

  const { state = {} } = location;
  const { redirectedFromSavedDraftInvoice, redirectedFromDatevModal, isSavedFormPaymentSection } =
    state;
  const { showDialog } = needsAnotherDatevReport;

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (redirectedFromSavedDraftInvoice && !showDialog) {
        showDatevModal();
      }
    }
  }, [showDialog, redirectedFromSavedDraftInvoice]);

  return isFetching ? (
    <LoadingIcon dataId="InvoiceTemplates:preview-loader" />
  ) : (
    <IncomingInvoiceCreator
      crudMode={NEW_MODE}
      isFetching={isFetching}
      newInvoice
      showDialogFromNewInvoice={showDialogFromNewInvoice}
      closeDatevModalFromNewInvoice={closeDatevModal}
      redirectedFromSavedDraftInvoice={redirectedFromSavedDraftInvoice}
      redirectedFromDatevModal={redirectedFromDatevModal}
      isSavedFormPaymentSection={isSavedFormPaymentSection}
      hasSavedLineItems={hasSavedLineItems}
      selectedCategory={selectedCategory}
      defaultCategory={defaultCategory}
      lineCategories={lineCategories}
      canSubmit={canSubmit}
      setLineItemState={handleLineItemStateChange}
    />
  );
};

const mapDispatchToProps = {
  createIncomingInvoice,
};

const mapStateToProps = (state, ownProps) => ({
  invoiceId: ownProps.match.params ? ownProps.match.params.id : '',
  needsAnotherDatevReport: state.incomingInvoice.postCreateStatus,
  lineCategories: state.incomingInvoice.lineCategories,
  invoiceLineItems: state.incomingInvoice.lineItems,
});

const reduxEnhancer = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(
  reduxEnhancer,
  withRouter,
  reduxForm({
    form: 'invoiceCreator',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    persistentSubmitErrors: true,
  })
);

export default enhance(InvoiceNew);
