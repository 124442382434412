import React from 'react';
import cx from 'classnames';
import { bool, number, string } from 'prop-types';

import { InvoiceAmendmentTypes } from 'constants/statuses';
import { formatMoney } from 'shared/utils';

import styles from './IncomingInvoiceAmount.module.css';

const { CANCELLATION } = InvoiceAmendmentTypes;

const IncomingInvoiceAmount = ({
  bold = false,
  amount,
  creditNote,
  amendmentType = '',
  dataId = '',
  inAssignment = false,
  bankTransferAmount = number | undefined,
  precision,
}) => {
  const options = { precision: precision ? precision : 2 };
  const formattedAmount = formatMoney(
    (creditNote && !bankTransferAmount) || !!bankTransferAmount ? amount : -amount,
    options
  );
  const isBankTransferPositive = bankTransferAmount && bankTransferAmount >= 0;
  const isBankTransferNegative = bankTransferAmount && bankTransferAmount < 0;
  const isPositive = !!bankTransferAmount
    ? isBankTransferPositive
    : creditNote || amount >= 0 || amendmentType === CANCELLATION;
  const isNegative = !!bankTransferAmount
    ? isBankTransferNegative
    : (!creditNote || amount < 0) && amendmentType !== CANCELLATION;
  const className = cx(styles.amountItem, {
    [styles.amountPositive]: isPositive,
    [styles.amountNegative]: isNegative,
    [styles.bold]: bold,
    [styles.inAssignment]: inAssignment,
  });

  return (
    <span data-id={dataId} className={className}>
      {formattedAmount}
    </span>
  );
};

IncomingInvoiceAmount.propTypes = {
  currency: string,
  creditNote: bool,
  bold: bool,
  amount: number,
  amendmentType: string,
  dataId: string,
  inAssignment: bool,
  bankTransferAmount: number,
  precision: number,
};

export default IncomingInvoiceAmount;
