import React from 'react';

import { t } from 'shared/utils';
import Link from 'redesign/components/atoms/Link/Link';

import { CustomizeType } from '../types';
import * as SC from './Customizer.styled';

interface ICustomizer {
  widgets: CustomizeType[];
  saveDashboard: () => void;
  resetDashboard: () => void;
}

const Customizer = ({ widgets, saveDashboard, resetDashboard }: ICustomizer) => (
  <SC.Wrapper>
    <SC.Title>{t('dashboard.customisation_widgets.title')}</SC.Title>
    <SC.Row>
      {widgets.map((widget) => (
        <SC.Customize key={widget.title}>
          <SC.IconWrapper>{widget.icon}</SC.IconWrapper>
          <SC.ItemTitle>{widget.title}</SC.ItemTitle>
          <SC.Description>{widget.description}</SC.Description>
          <SC.ItemButton
            onClick={widget.handleSwitch}
            disabled={widget.isActivated}
            $variant="outlined"
            data-id={`dashboard-widget-activate-button-${widget.widgetId}`}
          >
            {widget.isActivated
              ? t('dashboard.customisation_widgets.already_activated')
              : t('dashboard.customisation_widgets.activate')}
          </SC.ItemButton>
        </SC.Customize>
      ))}
      <SC.Customize>
        <SC.IconWrapper>
          <SC.HelpIcon />
        </SC.IconWrapper>
        <SC.Description>
          {t('dashboard.customisation_widgets.more_details.description')}
        </SC.Description>
        <Link
          target="_blank"
          href="https://www.vr-smart-guide.de/service/hilfe-im-vr-smart-guide/dashboard"
        >
          {t('dashboard.customisation_widgets.more_details.link')}
        </Link>
      </SC.Customize>
    </SC.Row>
    <SC.ActionRow>
      <SC.Save onClick={saveDashboard} data-id="dashboard-save-button">
        {t('dashboard.customisation_widgets.save_dashboard')}
      </SC.Save>
      <Link onClick={resetDashboard} data-id="dashboard-reset-link">
        {t('dashboard.customisation_widgets.cancel')}
      </Link>
    </SC.ActionRow>
  </SC.Wrapper>
);

export default Customizer;
