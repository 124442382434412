import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, identity, some } from 'lodash';
import head from 'lodash/head';
import { arrayOf, bool, func, shape } from 'prop-types';
import { compose } from 'recompose';
import { change, Field, reduxForm } from 'redux-form';

import { indexBankAccounts } from 'actions/banks';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import AdditionalInfo from 'components/AdditionalInfo/AdditionalInfo';
import BankAccountSelectField from 'components/BankAccountSelectField/BankAccountSelectField';
import If from 'components/Conditions/If';
import Loading from 'components/Loading';

import pageStyles from '../Page.module.css';
import styles from './Page4.module.css';

const formName = 'bankAccountCreator';
const selectName = 'mainAccountNumber';
const selectPlaceholder = t('bank_accounts.creator.fields.main_account_id');

class Page4 extends Component {
  get shouldInitiallySelectBankAccount() {
    return this.props.acceptedSepaBankAccounts.length === 1;
  }

  get initiallySelectedBankAccount() {
    return head(this.props.acceptedSepaBankAccounts);
  }

  componentDidMount() {
    if (this.shouldInitiallySelectBankAccount)
      this.selectAccount(this.initiallySelectedBankAccount);
  }

  selectAccount = ({ number }) => this.props.selectMainAccountNumber(number);

  goBack = () => {
    const { handlePrevPage, selectMainAccountNumber } = this.props;
    selectMainAccountNumber('');
    handlePrevPage();
  };

  render() {
    const {
      acceptedSepaBankAccounts = [],
      handleSubmit,
      onSubmit,
      isLoading,
      invalid,
    } = this.props;

    return (
      <form
        className={pageStyles.page}
        onSubmit={handleSubmit(onSubmit)}
        id="bank-account-creator-page-4"
      >
        <div className={pageStyles.body}>
          <div>{t('bank_accounts.creator.select_main_bank_account')}</div>
          <AdditionalInfo text={t('bank_accounts.creator.select_main_bank_account_info')} />
          <Field
            name={selectName}
            label={selectPlaceholder}
            placeholder={selectPlaceholder}
            bankAccounts={acceptedSepaBankAccounts}
            component={BankAccountSelectField}
            onSelect={this.selectAccount}
            wrapperClassName={styles.selectWrapper}
            shouldInitiallySelectBankAccount={this.shouldInitiallySelectBankAccount}
            initiallySelectedBankAccount={this.initiallySelectedBankAccount}
            required
          />
          <If ok={isLoading}>
            <Loading />
          </If>
        </div>
        <div className={pageStyles.footer}>
          <ActionButton
            appearance="outlined"
            onClick={this.goBack}
            disabled={isLoading}
            className={pageStyles.stepButton}
            label={t('bank_accounts.creator.prev_step')}
          />
          <ActionButton
            type="submit"
            disabled={isLoading || invalid}
            className={pageStyles.stepButton}
            label={t('bank_accounts.creator.ready')}
            dataId="BankAccount:button-finish"
          />
        </div>
      </form>
    );
  }
}

Page4.propTypes = {
  handleSubmit: func.isRequired,
  handlePrevPage: func.isRequired,
  onSubmit: func.isRequired,
  selectMainAccountNumber: func.isRequired,
  invalid: bool,
  isLoading: bool.isRequired,
  acceptedSepaBankAccounts: arrayOf(shape({})),
};

const anyAccountChecked = (state) =>
  !some(get(state, 'form.bankAccountCreator.values.mainAccountNumber'), identity);

const mapStateToProps = (state) => ({
  anyAccountChecked: anyAccountChecked(state),
});

const mapDispatchToProps = (dispatch) => ({
  indexBankAccounts: (...args) => dispatch(indexBankAccounts(...args)),
  selectMainAccountNumber: (id) => dispatch(change(formName, selectName, id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    destroyOnUnmount: false,
  })
)(Page4);
