import React, { ReactNode } from 'react';

import { Message, Wrapper } from './EmptyMessage.styled';

type EmptyMessageProps = {
  children: ReactNode;
};

const EmptyMessage = ({ children }: EmptyMessageProps) => {
  return (
    <Wrapper>
      <Message data-id="Datev:empty-content-message">{children}</Message>
    </Wrapper>
  );
};

export default EmptyMessage;
