import React, { useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { flow } from 'lodash';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { resetClientDefaults, updateClientDefaults } from 'actions/default-values';
import { getDeliveryNote, GetDeliveryNoteData } from 'api/me/previews/deliveryNote';
import { ClientDocumentsFormName } from 'containers/Clients/ClientDocumentDefaults/ClientDocumentDefaults';
import { FormRow } from 'containers/Profile/InvoiceTemplate/DefaultValues/sharedComponents';
import DefaultValuesFormWithPreview from 'containers/Profile/InvoiceTemplate/DefaultValues/sharedComponents/DefaultValuesForm/DefaultValuesFormWithPreview';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { RootState } from 'store';
import { DefaultValues } from 'types/entities/DefaultValues';
import { TextField } from 'components/Form';
import InputsGroup from 'components/InputsGroup/InputsGroup';
import HtmlField from 'components/v2/Form/HtmlField/HtmlField';

import styles from './DeliveryNoteDefaults.module.css';

export const FORM_NAME: ClientDocumentsFormName = 'clientDeliveryNoteDefaults';

type FormData = Pick<
  DefaultValues,
  | 'deliveryNoteSubject'
  | 'deliveryNoteSalutation'
  | 'deliveryNoteSalutationContent'
  | 'deliveryNoteFooter'
>;

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    deliveryNoteSubject: state.defaultValues.values.deliveryNoteSubject,
    deliveryNoteSalutation: state.defaultValues.values.deliveryNoteSalutation,
    deliveryNoteSalutationContent: state.defaultValues.values.deliveryNoteSalutationContent,
    deliveryNoteFooter: state.defaultValues.values.deliveryNoteFooter,
  },
  formValues: {
    deliveryNoteSalutation: selector(state, 'deliveryNoteSalutation'),
    deliveryNoteSalutationContent: selector(state, 'deliveryNoteSalutationContent'),
    deliveryNoteFooter: selector(state, 'deliveryNoteFooter'),
  },
});

const mapDispatchToProps = {
  updateClientDefaults,
  resetClientDefaults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type DeliveryNoteDefaultsProps = ConnectedProps<typeof connector> & {
  clientId: string;
};

const DeliveryNoteDefaults = ({
  resetClientDefaults,
  updateClientDefaults,
  handleSubmit,
  clientId,
  reset: resetForm,
  formValues: { deliveryNoteSalutation, deliveryNoteSalutationContent, deliveryNoteFooter },
}: DeliveryNoteDefaultsProps & InjectedFormProps<FormData, DeliveryNoteDefaultsProps>) => {
  const topFields = useMemo(
    () => (
      <InputsGroup label={t('invoice_templates.documents_defaults.discount_subject')}>
        <Field
          className={styles.grouppedInput}
          name="deliveryNoteSubject"
          checker={serverValidationChecker}
          component={TextField}
          dataId="ClientDocumentDefaults:input-delivery-note-subject"
        />
      </InputsGroup>
    ),
    []
  );

  const values = useMemo(
    () => ({
      salutation_honorific: deliveryNoteSalutation,
      salutation_content: deliveryNoteSalutationContent,
      personal_notes: deliveryNoteFooter,
    }),
    [deliveryNoteSalutation, deliveryNoteSalutationContent, deliveryNoteFooter]
  );

  return (
    <DefaultValuesFormWithPreview<GetDeliveryNoteData>
      onSubmit={handleSubmit((values) =>
        updateClientDefaults(clientId, values, 'form_delivery_note')
      )}
      onReset={async () => {
        await resetClientDefaults(clientId, 'form_delivery_note');
        resetForm();
      }}
      sectionName="form_delivery_note"
      sectionLabel={t('invoice_templates.documents_defaults.delivery_note_defaults_section')}
      dataIdPrefix="ClientDocumentDefaults:"
      fetchPreview={getDeliveryNote}
      values={values}
      topFields={topFields}
    >
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.header')}>
          <Field
            name="deliveryNoteSalutation"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation')}
            tooltip={t('invoice_templates.documents_defaults.salutation_info')}
            dataId="ClientDocumentDefaults:input-delivery-note-salutation"
          />
          <br />
          <Field
            name="deliveryNoteSalutationContent"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation_content')}
            dataId="ClientDocumentDefaults:input-delivery-note-salutation-content"
          />
        </InputsGroup>
      </FormRow>
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.footer')}>
          <Field
            name="deliveryNoteFooter"
            component={HtmlField}
            checker={serverValidationChecker}
            dataId="ClientDocumentDefaults:input-delivery-note-footer"
          />
        </InputsGroup>
      </FormRow>
    </DefaultValuesFormWithPreview>
  );
};

export default flow(
  withTransitionPrevent(),
  reduxForm<FormData, DeliveryNoteDefaultsProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    persistentSubmitErrors: true,
  }),
  connector
)(DeliveryNoteDefaults);
