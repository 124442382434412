import * as MonthlyOverview from 'constants/monthly-overview';
import * as Piwik from 'constants/piwik';
import { piwikHelpers } from 'shared/utils/piwik';

const homeActionSwitch = (action) => {
  switch (action.type) {
    // when user switches to table-view on "Monatssicht Einnahmen & Ausgaben", then track with params category="Home", action="Monatsansicht Tabellenansicht"
    case MonthlyOverview.FLIP_BACK:
      piwikHelpers.trackEvent(Piwik.CATEGORY_HOME, Piwik.ACTION_MONTH_TABLE_VIEW);
      break;

    default:
      break;
  }
};

export default homeActionSwitch;
