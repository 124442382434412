import { multipleAnyChecker } from 'shared/utils/form-checking';

import type { Passwords } from './types';

type Error = {
  type: string;
  message: string;
};

const checker = ({ password, passwordConfirm }: Passwords, wantedError: string) => {
  const { meta: passwordMeta } = password;
  const { meta: passwordConfirmMeta } = passwordConfirm;

  if (!passwordMeta.error || !passwordConfirmMeta.error) {
    return null;
  }

  if (!passwordMeta.error.find((e: Error) => e.type === wantedError)) {
    return null;
  }

  if (!passwordConfirmMeta.error.find((e: Error) => e.type === wantedError)) {
    return null;
  }

  const error = passwordMeta.error.find((e: Error) => e.type === wantedError) ||
    passwordConfirmMeta.error.find((e: Error) => e.type === wantedError) || { message: '' };

  return error.message;
};

const bothCases = (props: Passwords) => checker(props, 'passwords-not-both-cases');
const specials = (props: Passwords) => checker(props, 'passwords-not-specials');
const length = (props: Passwords) => checker(props, 'passwords-not-long-enough');
const matching = (props: Passwords) => {
  const { password, passwordConfirm } = props;

  if (!password.meta.touched || !passwordConfirm.meta.touched) {
    return null;
  }

  return checker(props, 'passwords-not-match');
};

const any = (props: Passwords) => {
  const { password, passwordConfirm } = props;

  if (!password.meta.touched || !passwordConfirm.meta.touched) {
    return null;
  }

  return multipleAnyChecker([bothCases, specials, length, matching], props);
};

export default {
  any,
  bothCases,
  specials,
  length,
  matching,
};
