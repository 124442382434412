import React, { ComponentType } from 'react';
import cx from 'classnames';

import isPressedEnter from 'shared/utils/keyboard-events';
import BadgeIcon from 'components/NotificationsSidebar/NotificationsHeader/BadgeIcon/BadgeIcon';

import styles from './SectionButton.module.css';

type SectionButtonProps = {
  count: number;
  isActive: boolean;
  onClickAction: () => void;
  icon: ComponentType<{ className: string; role: string }>;
  dataId: string;
};

const SectionButton = ({ count, isActive, onClickAction, icon, dataId }: SectionButtonProps) => (
  <div
    role="button"
    className={cx(styles.container, { [styles.active]: isActive })}
    onClick={onClickAction}
    onKeyPress={isPressedEnter(onClickAction)}
    data-id={dataId}
  >
    <BadgeIcon count={count} icon={icon} />
  </div>
);

export default SectionButton;
