import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { t } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';
import IconMore from 'components/ActionIcons/IconMore';
import actionStyles from 'components/IndexActionsContainer/actionIcons/Action.module.css';

import localStyles from './iconsDropdown.module.css';

type IconsDropdownProps = {
  actions: ReactNode[];
  withDropdownLabel: boolean;
  alignRight: boolean;
  className: string;
};

const IconsDropdown = ({
  actions,
  withDropdownLabel = false,
  alignRight = false,
  className,
}: IconsDropdownProps) => {
  const [isDropdownMenuVisible, setIsDropdownMenuVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = useCallback(() => setIsDropdownMenuVisible(false), []);
  const toggleMenu = useCallback(
    () => setIsDropdownMenuVisible((isDropdownMenuVisible) => !isDropdownMenuVisible),
    []
  );

  useClickAway(containerRef, handleClickOutside);

  if (isEmpty(actions)) {
    return null;
  }

  if (actions.length === 1) {
    const action = actions[0];

    return (
      <div className={cx(localStyles.dropdown, className)}>
        <div className={actionStyles.actionNoLabel}>{action}</div>
      </div>
    );
  }

  return (
    <div className={cx(localStyles.dropdown, className)} ref={containerRef}>
      <div
        role="button"
        id="dropdown-more-button"
        onKeyPress={isPressedEnter(toggleMenu)}
        onClick={toggleMenu}
        data-id="Dropdown:button-more"
        className={cx(actionStyles.action, actionStyles.actionNoLabel)}
      >
        <div
          className={cx(localStyles.actionIconMore, {
            [localStyles.actionIconMoreDropdownOpen]: isDropdownMenuVisible,
          })}
        >
          <IconMore />
        </div>
        {withDropdownLabel && (
          <div className={localStyles.actionLabel}>{t('revenue.table.actions.more')}</div>
        )}
      </div>
      {isDropdownMenuVisible && (
        <div
          className={cx(localStyles.dropdownMenu, {
            [localStyles.dropdownAlignRight]: alignRight,
          })}
        >
          {actions.map((action, index) => (
            <div
              className={cx(localStyles.dropdownMenuElement, actionStyles.actionOnHover)}
              key={index}
              role="button"
              onKeyPress={isPressedEnter(toggleMenu)}
              onClick={toggleMenu}
            >
              {action}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default IconsDropdown;
