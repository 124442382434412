import { get } from 'lodash';

import { paginationParams, sortingParams } from 'actions/helpers/table';
import { CALL_API } from 'constants/api';
import { CLEAR_FILTERS, SET_DATE_RANGE } from 'constants/common/filters';
import {
  FETCH_FAILURE_SUB_ORDER_CONFIRMATIONS,
  FETCH_REQUEST_SUB_ORDER_CONFIRMATIONS,
  FETCH_SUCCESS_SUB_ORDER_CONFIRMATIONS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SORT,
  INDEX_SUCCESS,
  NAMESPACE,
} from 'constants/order-confirmations';
import { Resources } from 'constants/resources';
import { getSanitizedFilters } from 'reducers/common/filters';
import { apiErrorHandler } from 'shared/utils/error-handlers';

import * as actions from './index';

const apiIndex = (pagination = {}, sorting = {}, filters = {}) => ({
  [CALL_API]: {
    params: {
      ...paginationParams({ pagination_resource: Resources.ORDER_CONFIRMATIONS, ...pagination }),
      ...sortingParams(sorting),
      filters,
    },
    endpoint: '/me/order_confirmations',
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
  },
});

export const sortOrderConfirmations = (column) => (dispatch) =>
  dispatch({
    type: INDEX_SORT,
    column,
  });

export const apifetchSubOrderConfirmations = (orderConfirmationId) => ({
  [CALL_API]: {
    method: 'GET',
    endpoint: `/me/order_confirmations/${orderConfirmationId}/sub_order_confirmations`,
    types: [
      FETCH_REQUEST_SUB_ORDER_CONFIRMATIONS,
      FETCH_SUCCESS_SUB_ORDER_CONFIRMATIONS,
      FETCH_FAILURE_SUB_ORDER_CONFIRMATIONS,
    ],
  },
});

export const fetchSubOrderConfirmations = (orderConfirmationId) => (dispatch) =>
  dispatch(apifetchSubOrderConfirmations(orderConfirmationId)).catch(apiErrorHandler);

export const fetchOrderConfirmations =
  (pagination, sorting, { noFiltering = false } = {}) =>
  (dispatch, getState) => {
    const filters = noFiltering
      ? {}
      : getSanitizedFilters(get(getState(), 'orderConfirmations.parsedFilters', {}));

    return dispatch(apiIndex(pagination, sorting, filters)).catch(apiErrorHandler);
  };

export const setQueryParam = actions.setQueryParam({ name: NAMESPACE });

export const setDateRange = (param) => (dispatch) => (values) =>
  dispatch({
    type: SET_DATE_RANGE,
    name: NAMESPACE,
    payload: {
      param,
      values,
    },
  });

export const clearFilters = ({ clearStatus = false } = {}) => ({
  type: CLEAR_FILTERS,
  name: NAMESPACE,
  payload: {
    clearStatus,
  },
});
