import React, { memo, SVGProps } from 'react';

const WelcomeMorning = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 283 151" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <linearGradient x1=".201%" y1="22.085%" x2="86.808%" y2="78.141%" id="Welcome-morning_svg__a">
        <stop stopColor="#FFDC80" offset="0%" />
        <stop stopColor="#F60" offset="100%" />
      </linearGradient>
      <linearGradient x1="59.542%" y1="70.838%" x2="0%" y2="19.361%" id="Welcome-morning_svg__b">
        <stop stopColor="#090547" offset="0%" />
        <stop stopColor="#3FCBFF" offset="100%" />
      </linearGradient>
      <linearGradient x1="55.26%" y1="56.818%" x2="0%" y2="41.856%" id="Welcome-morning_svg__c">
        <stop stopColor="#0066B3" offset="0%" />
        <stop stopColor="#3FCBFF" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="Welcome-morning_svg__d">
        <stop stopColor="#09BCF6" offset="0%" />
        <stop stopColor="#0487EA" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M281.726 71.696c.03-.584.046-1.172.046-1.763 0-18.502-15.046-33.5-33.606-33.5-3.182 0-6.26.441-9.178 1.265a24.068 24.068 0 00-16.71-6.715c-8.903 0-16.674 4.813-20.852 11.967a24.18 24.18 0 00-7.125-1.067c-13.321 0-24.12 10.764-24.12 24.042 0 1.99.248 3.922.705 5.77h110.84zM169.986 62.009c.045-.885.07-1.777.07-2.674 0-28.05-22.88-50.79-51.105-50.79-4.839 0-9.52.67-13.956 1.918A36.662 36.662 0 0079.584.283c-13.54 0-25.357 7.297-31.712 18.144a36.871 36.871 0 00-10.834-1.618C16.78 16.809.359 33.129.359 53.259c0 3.018.376 5.946 1.072 8.75h168.555z"
        fill="#EEF2F6"
      />
      <path
        d="M134.04 8.09c49.231 0 89.14 39.689 89.14 88.648 0 16.468-4.515 31.888-12.382 45.1H57.283c-7.867-13.212-12.383-28.632-12.383-45.1 0-48.96 39.91-88.649 89.14-88.649z"
        fill="url(#Welcome-morning_svg__a)"
        transform="translate(.263 .283)"
      />
      <g fill="#C7E8FB">
        <path d="M239.52 25.812h24.247M239.52 36.992h24.247M239.52 31.402h17.243" />
      </g>
      <path fill="#FFEDBA" d="M235.409 115.996l-84.147 26.09-12.58-40.164 84.146-26.09z" />
      <path
        stroke="#FBB900"
        strokeWidth={1.275}
        d="M235.409 115.996l-84.147 26.09-12.58-40.164 84.146-26.09z"
      />
      <path fill="#FFBF3A" d="M231.446 114.364l-78.38 24.301-10.946-34.948 78.38-24.301z" />
      <path
        stroke="#FBB900"
        strokeWidth={1.184}
        d="M231.446 114.364l-78.38 24.301-10.946-34.948 78.38-24.301z"
      />
      <path
        d="M203.338 104.05c2.798 8.93-2.211 18.427-11.187 21.21-8.976 2.783-18.52-2.201-21.318-11.131-2.797-8.93 2.212-18.426 11.188-21.21 8.976-2.782 18.52 2.201 21.317 11.132"
        fillOpacity={0.5}
        fill="#FFF"
      />
      <path
        d="M203.338 104.05c2.798 8.93-2.211 18.427-11.187 21.21-8.976 2.783-18.52-2.201-21.318-11.131-2.797-8.93 2.212-18.426 11.188-21.21 8.976-2.782 18.52 2.201 21.317 11.132z"
        stroke="#FFD760"
        strokeWidth={1.184}
      />
      <path
        d="M169.26 114.616c1.128 3.601-.891 7.43-4.51 8.552-3.62 1.123-7.469-.887-8.596-4.488-1.128-3.6.891-7.43 4.51-8.552 3.62-1.122 7.469.887 8.597 4.488"
        fill="#FFEDBA"
      />
      <path
        d="M169.26 114.616c1.128 3.601-.891 7.43-4.51 8.552-3.62 1.123-7.469-.887-8.596-4.488-1.128-3.6.891-7.43 4.51-8.552 3.62-1.122 7.469.887 8.597 4.488z"
        stroke="#FFD760"
        strokeWidth={0.5}
      />
      <path
        d="M219.329 99.093c1.128 3.6-.892 7.43-4.511 8.551-3.62 1.123-7.468-.886-8.596-4.488-1.128-3.6.892-7.43 4.511-8.552 3.62-1.122 7.468.888 8.596 4.489"
        fillOpacity={0.5}
        fill="#FFF"
      />
      <path
        d="M219.329 99.093c1.128 3.6-.892 7.43-4.511 8.551-3.62 1.123-7.468-.886-8.596-4.488-1.128-3.6.892-7.43 4.511-8.552 3.62-1.122 7.468.888 8.596 4.489z"
        stroke="#FFD760"
        strokeWidth={0.5}
      />
      <g>
        <path fill="#FFEDBA" d="M145.266 145.287h88.138v-42.069h-88.138z" />
        <path stroke="#FBB900" strokeWidth={1.275} d="M145.266 145.287h88.138v-42.069h-88.138z" />
        <path fill="#FFBF3A" d="M148.011 142.556h82.098V105.95H148.01z" />
        <path stroke="#FBB900" strokeWidth={1.184} d="M148.011 142.556h82.098V105.95H148.01z" />
        <path
          d="M206.358 124.39c0 9.353-7.621 16.936-17.023 16.936s-17.024-7.583-17.024-16.937c0-9.354 7.622-16.937 17.024-16.937 9.402 0 17.023 7.583 17.023 16.937"
          fillOpacity={0.5}
          fill="#FFF"
        />
        <path
          d="M206.358 124.39c0 9.353-7.621 16.936-17.023 16.936s-17.024-7.583-17.024-16.937c0-9.354 7.622-16.937 17.024-16.937 9.402 0 17.023 7.583 17.023 16.937z"
          stroke="#FFD760"
          strokeWidth={1.184}
        />
        <path
          d="M170.664 124.39c0 3.771-3.073 6.829-6.865 6.829-3.79 0-6.864-3.058-6.864-6.83 0-3.772 3.074-6.83 6.864-6.83 3.792 0 6.865 3.058 6.865 6.83"
          fill="#FFEDBA"
        />
        <path
          d="M170.664 124.39c0 3.771-3.073 6.829-6.865 6.829-3.79 0-6.864-3.058-6.864-6.83 0-3.772 3.074-6.83 6.864-6.83 3.792 0 6.865 3.058 6.865 6.83z"
          stroke="#FFD760"
          strokeWidth={0.5}
        />
        <path
          d="M223.107 124.39c0 3.771-3.073 6.829-6.864 6.829s-6.865-3.058-6.865-6.83c0-3.772 3.074-6.83 6.865-6.83 3.79 0 6.864 3.058 6.864 6.83"
          fill="#FFEDBA"
        />
        <path
          d="M223.107 124.39c0 3.771-3.073 6.829-6.864 6.829s-6.865-3.058-6.865-6.83c0-3.772 3.074-6.83 6.865-6.83 3.79 0 6.864 3.058 6.864 6.83z"
          stroke="#FFD760"
          strokeWidth={0.5}
        />
      </g>
      <g>
        <path
          d="M98.42 49.148c-.151-1.408 2.424-24.669-6.283-21.075-8.707 3.594-10.75 13.772-16.094 6.83C70.7 27.964 42.821-10.804 41.56 2.918c-1.892 20.592-7.786 7.53-17.573 3.607-21.14-8.471-41.1 15.655-.39 30.33 40.71 14.677 12.736 14.882 12.736 14.882S19.336 67.3 57.481 68.383c38.144 1.084 40.049 8.85 40.049 8.85l.89-28.085z"
          fill="url(#Welcome-morning_svg__b)"
          transform="translate(5.546 56.88)"
        />
        <path
          d="M70.171 101.18c-.76-4.159-5.455-26.61-17.757-38.24M46.198 112.837c1.671.724 22.844-3.582 31.62-5.218M22.45 75.716s61.208 28.242 75.402 50.306"
          stroke="#155FAA"
          strokeWidth={1.378}
          strokeLinecap="round"
        />
      </g>
      <g>
        <path fill="#FFF" d="M134.19 131.696l-52.17 15.161-21.66-73.779 52.17-15.16z" />
        <path
          stroke="#E6E6E6"
          strokeWidth={0.66}
          d="M134.19 131.696l-52.17 15.161-21.66-73.779 52.17-15.16z"
        />
        <path
          stroke="#4D4D4D"
          strokeWidth={1.981}
          strokeLinecap="round"
          d="M72.472 95.592l14.436-4.196M73.296 98.4l14.437-4.195"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.732}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M68.163 80.914l5.725-1.664M68.5 82.066l6.757-1.964M68.839 83.217l5.725-1.664"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.887}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M85.619 140.373l8.393-2.439"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.818}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M85.957 141.525l5.725-1.664"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.887}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M86.295 142.676l8.393-2.439"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.818}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M97.695 136.864l7.149-2.078M98.033 138.015l8.437-2.452M98.37 139.166l7.15-2.077M108.22 133.805l7.15-2.077M108.558 134.956l6.534-1.898M108.896 136.108l7.15-2.078"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.732}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M69.177 84.368l6.756-1.963"
        />
        <path
          stroke="#4D4D4D"
          strokeWidth={1.981}
          strokeLinecap="round"
          d="M73.977 100.718L86.5 97.08M77.189 111.344l8.51-2.652M112.473 100.35l5.355-1.669M87.898 108.007l21.362-6.657M78.698 116.138l8.512-2.652M113.983 105.144l5.354-1.669M89.407 112.801l21.363-6.656M79.872 119.867l8.51-2.652M115.156 108.873l5.355-1.668M90.58 116.531l21.364-6.657M81.38 124.662l8.513-2.652M116.666 113.668L122.02 112M92.09 121.325l21.363-6.656"
        />
        <text
          fontFamily="FrutigerVR-Bold, Frutiger VR"
          fontSize={15.569}
          fontWeight="bold"
          fill="#EB5C0B"
          transform="translate(60.36 57.917)"
        >
          <tspan x={58.757} y={64.422}>
            {'\u20AC'}
          </tspan>
        </text>
        <path
          d="M104.89 69.278a3.97 3.97 0 01-2.521 5.03c-2.092.697-4.356-.427-5.056-2.509a3.97 3.97 0 012.522-5.03c2.092-.696 4.356.427 5.056 2.51"
          fill="#EB5C0B"
        />
        <path
          d="M110.918 67.273a3.97 3.97 0 01-2.521 5.03c-2.093.696-4.357-.427-5.056-2.509a3.97 3.97 0 012.521-5.03c2.093-.696 4.357.427 5.056 2.509"
          fill="#52B4E7"
        />
        <path
          d="M109.085 72.074a3.97 3.97 0 01-2.521 5.03c-2.093.696-4.357-.427-5.056-2.51a3.97 3.97 0 012.521-5.029c2.093-.696 4.357.427 5.056 2.509"
          fill="#0F2364"
        />
      </g>
      <g>
        <path
          d="M76.841 21.19C70.288 19.168 42.957-11.61 0 4.799c0 0 12.48 38.697 63.107 25.59 1.053.2 18.902-4.241 18.902-4.241L76.84 21.19z"
          fill="url(#Welcome-morning_svg__c)"
          transform="translate(39.254 116.72)"
        />
        <path
          d="M119.314 142.967s-7.82-1.02-18.445-2.989c-15.577-2.885-44.238-8.412-56.055-15.135M96.967 138.867c.201-.2-5.034-10.217-14.297-13.423M77.434 135.661s-9.464 4.809-22.15 2.204M70.185 121.237s5.583 5.246 4.503 12.84M59.715 130.653s-2.82-7.412-9.062-10.818M93.419 138.955s-6.722 5.722-18.602 8.326"
          stroke="#26295B"
          strokeWidth={0.976}
          strokeLinecap="round"
        />
      </g>
      <g>
        <path fill="#436BEE" d="M202.123 145.798h17.168v-45.784h-17.168z" />
        <path fill="#2747B0" d="M226.554 145.798h17.168V73.215h-17.168z" />
        <path fill="#0F2364" d="M250.985 145.798h17.168V87.37h-17.168z" />
      </g>
      <path
        stroke="#615D5C"
        strokeWidth={1.275}
        strokeLinecap="round"
        d="M161.605 145.586l113.672.145"
      />
      <g>
        <path
          d="M186.338 147.654h-66.251c-3.84 0-6.952-3.096-6.952-6.916V53.075c0-3.82 3.113-6.916 6.952-6.916h66.25c3.84 0 6.952 3.096 6.952 6.916v87.663c0 3.82-3.112 6.916-6.951 6.916"
          fill="#0F2364"
        />
        <path
          d="M70.745 29.482H8.962c-.993 0-1.797-.8-1.797-1.788v-16.24c0-.987.804-1.788 1.797-1.788h61.783c.992 0 1.797.8 1.797 1.788v16.24c0 .987-.805 1.788-1.797 1.788"
          fill="url(#Welcome-morning_svg__d)"
          transform="translate(113.135 46.159)"
        />
        <path
          d="M132.58 100.113h-10.852c-.7 0-1.268-.565-1.268-1.262v-7.11c0-.697.568-1.261 1.268-1.261h10.851c.7 0 1.268.564 1.268 1.261v7.11c0 .697-.568 1.262-1.268 1.262M149.803 100.113H138.95c-.7 0-1.268-.565-1.268-1.262v-7.11c0-.697.568-1.261 1.268-1.261h10.852c.7 0 1.267.564 1.267 1.261v7.11c0 .697-.567 1.262-1.267 1.262M167.026 100.113h-10.852c-.7 0-1.267-.565-1.267-1.262v-7.11c0-.697.567-1.261 1.267-1.261h10.852c.7 0 1.267.564 1.267 1.261v7.11c0 .697-.567 1.262-1.267 1.262"
          fill="#0487EA"
        />
        <path
          d="M137.605 85.139h-16.083a1.06 1.06 0 01-1.062-1.057V81.22a1.06 1.06 0 011.062-1.058h16.083a1.06 1.06 0 011.062 1.058v2.862a1.06 1.06 0 01-1.062 1.057"
          fill="#E54065"
        />
        <path
          d="M161.03 85.139h-16.083a1.06 1.06 0 01-1.062-1.057V81.22a1.06 1.06 0 011.062-1.058h16.082a1.06 1.06 0 011.063 1.058v2.862a1.06 1.06 0 01-1.063 1.057"
          fill="#FBB900"
        />
        <path
          d="M184.454 85.139h-16.082a1.06 1.06 0 01-1.062-1.057V81.22a1.06 1.06 0 011.062-1.058h16.082a1.06 1.06 0 011.063 1.058v2.862a1.06 1.06 0 01-1.063 1.057"
          fill="#C7E8FB"
        />
        <path
          d="M184.454 85.139h-16.082a1.06 1.06 0 01-1.062-1.057V81.22a1.06 1.06 0 011.062-1.058h16.082a1.06 1.06 0 011.063 1.058v2.862a1.06 1.06 0 01-1.063 1.057z"
          stroke="#C7E8FB"
          strokeWidth={0.5}
        />
        <path
          d="M183.086 125.905h-8.525a2.425 2.425 0 01-2.431-2.418V92.898a2.425 2.425 0 012.43-2.418h8.526a2.425 2.425 0 012.43 2.418v30.589a2.425 2.425 0 01-2.43 2.418"
          fill="#FBB900"
        />
        <path
          d="M132.58 113.108h-10.852c-.7 0-1.268-.565-1.268-1.261v-7.11c0-.697.568-1.262 1.268-1.262h10.851c.7 0 1.268.565 1.268 1.261v7.11c0 .697-.568 1.262-1.268 1.262M149.803 113.108H138.95c-.7 0-1.268-.565-1.268-1.261v-7.11c0-.697.568-1.262 1.268-1.262h10.852c.7 0 1.267.565 1.267 1.261v7.11c0 .697-.567 1.262-1.267 1.262M167.026 113.108h-10.852c-.7 0-1.267-.565-1.267-1.261v-7.11c0-.697.567-1.262 1.267-1.262h10.852c.7 0 1.267.565 1.267 1.261v7.11c0 .697-.567 1.262-1.267 1.262M132.58 126.103h-10.852c-.7 0-1.268-.565-1.268-1.261v-7.11c0-.697.568-1.262 1.268-1.262h10.851c.7 0 1.268.565 1.268 1.261v7.11c0 .697-.568 1.262-1.268 1.262M149.803 126.103H138.95c-.7 0-1.268-.565-1.268-1.261v-7.11c0-.697.568-1.262 1.268-1.262h10.852c.7 0 1.267.565 1.267 1.261v7.11c0 .697-.567 1.262-1.267 1.262M167.026 126.103h-10.852c-.7 0-1.267-.565-1.267-1.261v-7.11c0-.697.567-1.262 1.267-1.262h10.852c.7 0 1.267.565 1.267 1.261v7.11c0 .697-.567 1.262-1.267 1.262"
          fill="#0487EA"
        />
        <path
          d="M149.111 139.098h-26.736a1.91 1.91 0 01-1.915-1.906v-5.821a1.91 1.91 0 011.915-1.906h26.736a1.91 1.91 0 011.915 1.906v5.821a1.91 1.91 0 01-1.915 1.906"
          fill="#FBB900"
        />
        <path
          d="M167.026 139.098h-10.852c-.7 0-1.267-.565-1.267-1.261v-7.11c0-.697.567-1.262 1.267-1.262h10.852c.7 0 1.267.565 1.267 1.261v7.111c0 .696-.567 1.26-1.267 1.26"
          fill="#0066B3"
        />
        <path
          d="M184.25 139.098h-10.853c-.7 0-1.267-.565-1.267-1.261v-7.11c0-.697.567-1.262 1.267-1.262h10.852c.7 0 1.268.565 1.268 1.261v7.111c0 .696-.568 1.26-1.268 1.26"
          fill="#EB5C0B"
        />
      </g>
    </g>
  </svg>
);

export default memo(WelcomeMorning);
