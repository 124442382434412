import { Component, ReactNode } from 'react';
import axios, { Canceler } from 'axios';
import normalize from 'json-api-normalizer';
import debounce from 'lodash/debounce';
import build from 'redux-object';

import { getSuppliers } from 'api/me/suppliers';
import EntityPath from 'constants/entitiesPaths';
import { Supplier } from 'types/entities/Supplier';

type SearchSuppliersState = {
  loading: boolean;
  error: string | null;
  data: Supplier[];
  page: number;
  perPage: number;
};

type SearchSuppliersProps = {
  text: string | null;
  children(props: Pick<SearchSuppliersState, 'loading' | 'data' | 'error'>): ReactNode;
};

class SearchSuppliers extends Component<SearchSuppliersProps, SearchSuppliersState> {
  state = {
    data: [],
    loading: false,
    error: null,
    page: 1,
    perPage: 20,
  };

  cancelToken: Canceler | null = null;

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: SearchSuppliersProps) {
    if (this.props.text !== prevProps.text) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.cancelToken?.();
  }

  makeNetworkRequest = debounce(async () => {
    const { text } = this.props;
    const { page, perPage } = this.state;
    let response;

    if (text === null) return;

    try {
      const cancelToken = new axios.CancelToken((token) => {
        this.cancelToken = token;
      });

      response = await getSuppliers(
        {
          page,
          per_page: perPage,
          filters: {
            full_text_search: text,
          },
        },
        cancelToken
      );
    } catch (e: any) {
      if (axios.isCancel(e)) {
        return;
      }
      this.setState({ data: [], error: e.message, loading: false });
      return;
    }

    const suppliers =
      build<Supplier>(normalize(response.data), EntityPath.Suppliers, null, {
        ignoreLinks: true,
      }) || [];

    this.cancelToken = null;
    this.setState({
      data: suppliers,
      loading: false,
      error: null,
    });
  }, 300);

  fetchData = () => {
    this.cancelToken?.();
    this.setState({ page: 1, error: null, loading: true });
    this.makeNetworkRequest();
  };

  render() {
    const { children } = this.props;
    const { data, loading, error } = this.state;

    return children({
      data,
      loading,
      error,
    });
  }
}

export default SearchSuppliers;
