import styled from 'styled-components';

export const Button = styled.button`
  background: transparent;

  svg {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.vrblue['100']};
  }
`;
