import { CALL_API } from 'constants/api';
import {
  GENERATE_TOKEN_FAILURE,
  GENERATE_TOKEN_REQUEST,
  GENERATE_TOKEN_SUCCESS,
  REMOVE_EXTERNAL_TOKEN_FAILURE,
  REMOVE_EXTERNAL_TOKEN_REQUEST,
  REMOVE_EXTERNAL_TOKEN_SUCCESS,
} from 'constants/external-tokens';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiCreateExternalAppToken = (id) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: '/external_tokens',
    types: [GENERATE_TOKEN_REQUEST, GENERATE_TOKEN_SUCCESS, GENERATE_TOKEN_FAILURE],
    data: {
      externalAppId: id,
    },
  },
});

export const createExternalAppToken = (id) => (dispatch) =>
  dispatch(apiCreateExternalAppToken(id)).catch(apiErrorHandler);

export const apiRemoveExternalToken = (appId) => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: '/external_tokens',
    types: [
      REMOVE_EXTERNAL_TOKEN_REQUEST,
      REMOVE_EXTERNAL_TOKEN_SUCCESS,
      REMOVE_EXTERNAL_TOKEN_FAILURE,
    ],
    data: {
      externalAppId: appId,
    },
  },
});

export const removeExternalToken = (appId) => (dispatch) =>
  dispatch(apiRemoveExternalToken(appId)).catch(apiErrorHandler);
