import { t } from 'shared/utils';

export const CONTRACT_TYPE_OPTIONS = [
  {
    value: 'loan',
    label: t('features.bank_transfers.add_contract_modal.select_contract_type.contract_type_loan'),
  },
  {
    value: 'basic',
    label: t('features.bank_transfers.add_contract_modal.select_contract_type.contract_type_basic'),
  },
];
