import { push as reduxPush } from 'connected-react-router';
import { get } from 'lodash';

import { exitEditInvoiceMode as exitEditInvoiceModeAC, getValidation } from 'actions/datev';
import {
  closeDatevModal as closeDatevModalAction,
  updateIncomingInvoice,
} from 'actions/incoming-invoice';
import { getOcrResult } from 'actions/incoming-invoice/ocr';
import {
  fetchPayments as fetchPaymentsAction,
  fetchPaymentsBankAccounts as fetchPaymentsBankAccountsAction,
} from 'actions/incoming-invoice/payments';
import { PROCESSING_STATUS } from 'constants/incoming-invoice/ocr';
import { InvoiceAmendmentTypes } from 'constants/statuses';
import { incomingInvoiceInitialValuesSelector, incomingInvoiceSelector } from 'reducers/form';
import { paymentsEnabledHelper } from 'routes/accesses';
import { incomingInvoiceDetailsSelector } from 'selectors/incomingInvoice';
import getDiscountData from 'shared/utils/get-discount-data';

const { CANCELLATION } = InvoiceAmendmentTypes;

export const mapStateToProps = (state, { isCancelInvoice }) => ({
  invoiceDetails: state.incomingInvoice,
  disabledSaveButton: state.incomingInvoice.details.disabled,
  isDraft: incomingInvoiceDetailsSelector(state).draft,
  amendmentType: incomingInvoiceSelector(state, 'amendmentType'),
  invoiceId: incomingInvoiceDetailsSelector(state).id,
  invoiceAmount: incomingInvoiceDetailsSelector(state).totalGrossAmount,
  invoiceHasCreditNote: incomingInvoiceDetailsSelector(state).creditNote,
  creatorHasCreditNote: incomingInvoiceSelector(state, 'creditNote'),
  uploadedFiles: state.incomingInvoice.uploadedFiles,
  initialValues: incomingInvoiceInitialValuesSelector(state),
  isLoading: state.incomingInvoice.ocr.status === PROCESSING_STATUS.PROCESSING,
  needsAnotherDatevReport: state.incomingInvoice.postCreateStatus,
  ocr: state.incomingInvoice.ocr,
  paymentsEnabled: paymentsEnabledHelper(state),
  isEditingInvoice: state.datev.invoiceActions.isEditingInvoice,
  datevDates: state.form.DatevCreator,
  isReadonly: (field) => {
    const actions = get(state, 'incomingInvoice.details.meta.actions', {});
    const amendmentType = get(state, 'incomingInvoice.details.attributes.amendmentType');

    if (amendmentType === CANCELLATION && field === 'bankActivity') {
      return isCancelInvoice;
    }

    if (amendmentType === CANCELLATION) {
      return !['invoiceFiles', 'number'].includes(field);
    }

    if (!actions.update && actions['update-payment-information']) {
      return !['bankActivity', 'paid', 'paidByCashDate'].includes(field);
    }

    if (!actions['update-payment-information']) {
      return true;
    }

    return false;
  },
});

export const mapDispatchToProps = (dispatch) => ({
  handleGetOcrResult: (id) => dispatch(getOcrResult(id)),
  fetchPaymentsBankAccounts: () => dispatch(fetchPaymentsBankAccountsAction()),
  fetchPayments: (id) => dispatch(fetchPaymentsAction(id)),
  submitInvoice: (id) => (data) =>
    dispatch(updateIncomingInvoice(id, { ...data, ...getDiscountData(data) })),
  push: (loc) => dispatch(reduxPush(loc)),
  exitEditInvoiceMode: () => dispatch(exitEditInvoiceModeAC()),
  refreshValidationData: (startDate, endDate) => dispatch(getValidation(startDate, endDate)),
  closeDatevModal: () => dispatch(closeDatevModalAction),
});
