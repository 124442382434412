import { change } from 'redux-form';

import { login } from 'actions/auth';
import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import * as authConstants from 'constants/auth';
import contentTypes from 'constants/content-types';
import {
  SET_PREFILLED_REGISTRATION_VALUES,
  TOGGLE_DISCLAIMER,
  TOGGLE_RECEIVE_OFFERS,
  TOGGLE_TERMS_OF_USE,
} from 'constants/registration';
import { trackRegistrationSuccess } from 'shared/utils/gtag';
import prefilledRegistrationData from 'shared/utils/prefilled-registration';
import { bindServerValidation } from 'shared/utils/server-validation';

export const apiRegister = (data = {}) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    authRequired: false,
    endpoint: '/registrations',
    types: [
      authConstants.REGISTER_REQUEST,
      authConstants.REGISTER_SUCCESS,
      authConstants.REGISTER_FAILURE,
    ],
  },
});

export const apiRegisterVrso = (data = {}) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    authRequired: false,
    endpoint: '/vrso/register',
    headers: {
      'Accept-Version': 'v1',
      'Content-Type': contentTypes.JSON,
    },
    types: [
      authConstants.REGISTER_REQUEST,
      authConstants.REGISTER_SUCCESS,
      authConstants.REGISTER_FAILURE,
    ],
  },
});

export const fetchPrefilledRegistrationData = (blz) => {
  const prefilledRegistrationValues = prefilledRegistrationData();

  if (blz) {
    prefilledRegistrationValues.blz = blz;
  }

  return {
    type: SET_PREFILLED_REGISTRATION_VALUES,
    prefilledRegistrationValues,
  };
};

export const toggleTermsOfUse = () => ({
  type: TOGGLE_TERMS_OF_USE,
});

export const toggleReceiveOffers = () => ({
  type: TOGGLE_RECEIVE_OFFERS,
});

export const toggleDisclaimer = () => ({
  type: TOGGLE_DISCLAIMER,
});

export const register =
  ({ formValues, grecaptchaResponse }) =>
  (dispatch) =>
    bindServerValidation(
      apiRegister({
        g_recaptcha_response: grecaptchaResponse,
        email: formValues.email,
        password: formValues.password,
        terms_acceptance: formValues.termsAcceptance,
        receive_offers: formValues.receiveOffers,
        bank_employee_id: formValues.bankEmployeeId,
        ...formValues,
      }),
      dispatch,
      {
        isReduxForm: true,
        catchMiddleware: (error) => {
          if (error.response.status === 423) {
            dispatch(
              showNotification({
                duration: 7500,
                title: error.response.data.error,
                variant: 'warning',
              })
            );
          }
          throw error;
        },
      }
    ).then((response) => {
      trackRegistrationSuccess();
      dispatch(
        login({
          email: response && response.email,
          password: formValues.password,
        })
      );
    });

export const registerVrso =
  ({ formValues }) =>
  (dispatch) => {
    return bindServerValidation(
      apiRegisterVrso({
        token: formValues.token,
        contract_number: formValues.contractNumber,
        password: formValues.password,
        terms_acceptance: formValues.termsAcceptance,
        receive_offers: formValues.receiveOffers,
      }),
      dispatch
    ).then(
      ({
        response: {
          data: {
            attributes: { email },
          },
        },
      }) => {
        trackRegistrationSuccess();
        dispatch(
          login({
            email: email,
            password: formValues.password,
          })
        );
      }
    );
  };

export const acceptTermsOfUse = () => (dispatch) =>
  Promise.all([
    dispatch(toggleTermsOfUse()),
    dispatch(change('registration', 'termsAcceptance', true, false, false)),
  ]);

export const rejectTermsOfUse = () => (dispatch) =>
  Promise.all([
    dispatch(toggleTermsOfUse()),
    dispatch(change('registration', 'termsAcceptance', false, false, false)),
  ]);

export const acceptReceiveOffers = () => (dispatch) =>
  Promise.all([
    dispatch(toggleReceiveOffers()),
    dispatch(change('registration', 'receiveOffers', true, false, false)),
  ]);

export const rejectReceiveOffers = () => (dispatch) =>
  Promise.all([
    dispatch(toggleReceiveOffers()),
    dispatch(change('registration', 'receiveOffers', false, false, false)),
  ]);
