import styled from 'styled-components';

export const Card = styled.section`
  max-width: 456px;
  border-radius: 2px;
  box-shadow: 0 1px 80px 0 rgba(16, 22, 31, 0.25);
`;

export const Logo = styled.img`
  width: 156px;
  height: 14px;
`;

export const Main = styled.main`
  padding: 0 35px 71px;

  h1,
  p {
    letter-spacing: 0;
    text-align: center;
    margin: 0 0 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const Icon = styled.div`
  display: block;
  width: 100px;
  height: 80px;
  margin: 49px auto 32px;
  color: ${({ theme }) => theme.legacy.colors.greenFont};
`;
