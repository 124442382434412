import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import {
  fetchTaxConsultant as fetchTaxConsultantAction,
  updateTaxConsultant as updateTaxConsultantAction,
} from 'actions/tax-consultant';
import { useAppDispatch, useAppSelector } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { getMomentDateForDatepicker } from 'shared/utils/date/parser';
import { ConfirmationModal } from 'components/Modal';
import Checkbox from 'redesign/components/atoms/Checkbox/Checkbox';
import { SelectProps } from 'redesign/components/atoms/Select/Select';
import ControlLabel from 'redesign/components/molecules/ControlLabel/ControlLabel';
import DateField, { DateFieldDate } from 'redesign/components/molecules/DateField/DateField';
import FieldWrapper from 'redesign/components/molecules/FieldWrapper/FieldWrapper';
import FormSection from 'redesign/components/molecules/FormSection/FormSection';
import InputField from 'redesign/components/molecules/InputField/InputField';
import PasswordField from 'redesign/components/molecules/PasswordField/PasswordField';
import ProfileSection from 'redesign/components/molecules/ProfileSection/ProfileSection';

import { getIsFetching, getTaxConsultantFormData } from './selectors';
import {
  AccountChartSelect,
  ActionsSection,
  CancelButton,
  CheckboxControl,
  Divider,
  FieldControl,
  FormControl,
  FormFieldRow,
  PasswordContainer,
  Row,
  SubmitButton,
  TaxationIntervalSelect,
  TaxationRow,
  TaxationTypeSelect,
  TextConsultantCredsColumn,
  Tooltip,
  // WarningBlock,
  // WarningIcon,
  // WarningText,
} from './TaxConsultantForm.styled';
import { Option } from './types';
import {
  chartsOfAccountsOptions,
  exportTimePeriodOptions,
  getCurrentOption,
  getDefaultValues,
  itemToString,
  taxationTypeOptions,
} from './utils';
import validationSchema from './validationSchema';

import styles from '../Company/Company.module.css';

type TaxConsultantFormProps = {
  heading: string;
  subheading?: string;
  description?: string;
};

type FiscalYearDateState = {
  date: moment.Moment | string;
  focused: boolean;
};

type FormData = {
  smallEntrepreneur: boolean;
  taxationExtension: boolean;
  fiscalYearBeginning: object | string;
  taxationType: Option;
  taxationInterval: Option;
  accountChart: Option;
  taxConsultantName: string;
  taxConsultantId: string;
  taxConsultantClientId: string;
  taxConsultantPhone: string;
  taxConsultantEmail: string;
  exportPassword: string;
};

const TaxConsultantForm = ({ heading }: TaxConsultantFormProps) => {
  const dispatch = useAppDispatch();
  const { goBack } = useHistory();

  const updateTaxConsultant = (data: FormData) => {
    dispatch(
      updateTaxConsultantAction({
        ...data,
        fiscalYearBeginning: data.fiscalYearBeginning as unknown as string,
        taxationInterval: data.taxationInterval.value,
        taxationType: data.taxationType.value,
        accountChart: data.accountChart.value,
      })
    );
  };

  const data = useAppSelector(getTaxConsultantFormData);
  const isFetching = useAppSelector(getIsFetching);

  const { handleSubmit, control, reset, watch } = useForm<FormData>({
    defaultValues: getDefaultValues(),
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });
  const values = watch();

  const [fiscalYearDateState, setFiscalYearDateState] = useState<FiscalYearDateState>({
    date: '',
    focused: false,
  });
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const onSubmit: SubmitHandler<FormData> = (data) => updateTaxConsultant(data);

  useEffect(() => {
    const fetchTaxConsultant = () => dispatch(fetchTaxConsultantAction());
    fetchTaxConsultant();
  }, [dispatch]);

  const hasFetchedData = Object.keys(data).length > 0;
  const isSmallEntrepreneur = values.smallEntrepreneur;

  useEffect(() => {
    if (hasFetchedData) {
      reset({
        smallEntrepreneur: data.smallEntrepreneur,
        taxationExtension: data.taxationExtension,
        fiscalYearBeginning: data.fiscalYearBeginning
          ? getMomentDateForDatepicker(data.fiscalYearBeginning)
          : '',
        taxationType: getCurrentOption(data.taxationType, taxationTypeOptions),
        taxationInterval: getCurrentOption(data.taxationInterval, exportTimePeriodOptions),
        accountChart: getCurrentOption(data.accountChart, chartsOfAccountsOptions),
        taxConsultantName: data.taxConsultantName,
        taxConsultantId: data.taxConsultantId,
        taxConsultantClientId: data.taxConsultantClientId,
        taxConsultantPhone: data.taxConsultantPhone,
        taxConsultantEmail: data.taxConsultantEmail,
        exportPassword: data.exportPassword,
      });
    }
  }, [hasFetchedData, reset, data]);

  return (
    <ProfileSection heading={heading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection heading={t('profile.tax_consultant.sections.taxation')}>
          <>
            <FieldControl>
              <Controller
                name="smallEntrepreneur"
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <ControlLabel
                    label={t('profile.tax_consultant.small_entrepreneur')}
                    control={<Checkbox />}
                    onClick={() => {
                      if (isSmallEntrepreneur) return;
                      setModalOpen(true);
                    }}
                    checked={value}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    data-id="TaxConsultantPage:input-smallEntrepreneur"
                  />
                )}
              />
            </FieldControl>
            <FieldControl>
              <Controller
                name="fiscalYearBeginning"
                control={control}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <DateField
                    name={name}
                    label={t('profile.tax_consultant.taxation_fiscal_year_beginning')}
                    date={value as DateFieldDate}
                    focused={fiscalYearDateState.focused}
                    placeholder="Datum"
                    onDateChange={(date) => onChange(date)}
                    onFocusChange={({ focused }) =>
                      setFiscalYearDateState((value: FiscalYearDateState) => ({
                        ...value,
                        focused,
                      }))
                    }
                    id="TaxConsultantPage:datePicker-fiscalYearBeginning"
                    error={error?.message}
                  />
                )}
              />
            </FieldControl>
            <FieldControl>
              <Row>
                <Controller
                  name="taxationType"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <FieldWrapper<SelectProps<Option>>
                      name={name}
                      label={t('profile.tax_consultant.taxation_type')}
                      field={TaxationTypeSelect}
                      onChange={onChange}
                      onBlur={onBlur}
                      itemToString={itemToString}
                      option={({ item: { label } }) => <div>{label}</div>}
                      options={taxationTypeOptions}
                      value={value}
                      disabled={isSmallEntrepreneur}
                      dataId={'TaxConsultantPage:select-taxationType'}
                    />
                  )}
                />
                <Tooltip
                  message={`${t('profile.tax_consultant.taxation_type_message_line_1')} ${t(
                    'profile.tax_consultant.taxation_type_message_line_2'
                  )}`}
                />
              </Row>
            </FieldControl>
            <FieldControl>
              <TaxationRow>
                <Controller
                  name="taxationInterval"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <FieldWrapper<SelectProps<Option>>
                      name={name}
                      label={t('profile.tax_consultant.taxation_interval')}
                      field={TaxationIntervalSelect}
                      onChange={onChange}
                      onBlur={onBlur}
                      itemToString={itemToString}
                      option={({ item: { label } }) => <div>{label}</div>}
                      options={exportTimePeriodOptions}
                      value={value}
                      disabled={isSmallEntrepreneur}
                      dataId={'TaxConsultantPage:select-taxationInterval'}
                    />
                  )}
                />
                {values.taxationInterval.value !== 'year' && (
                  <CheckboxControl>
                    <Controller
                      name="taxationExtension"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name } }) => (
                        <ControlLabel
                          name={name}
                          label={t('profile.tax_consultant.taxation_extension')}
                          control={<Checkbox />}
                          checked={value}
                          disabled={isSmallEntrepreneur}
                          onChange={onChange}
                          onBlur={onBlur}
                          data-id="TaxConsultantPage:input-taxationExtension"
                        />
                      )}
                    />
                  </CheckboxControl>
                )}
              </TaxationRow>
            </FieldControl>
            {/* <WarningBlock>
              <WarningIcon />
              <WarningText>{t('profile.tax_consultant.taxation_extension_warning')}</WarningText>
            </WarningBlock> */}
            <FieldControl>
              <Row>
                <Controller
                  name="accountChart"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <FieldWrapper<SelectProps<Option>>
                      name={name}
                      label={t('profile.tax_consultant.account_chart')}
                      field={AccountChartSelect}
                      onChange={onChange}
                      onBlur={onBlur}
                      itemToString={itemToString}
                      option={({ item: { label } }) => <div>{label}</div>}
                      options={chartsOfAccountsOptions}
                      value={value}
                      dataId={'TaxConsultantPage:select-accountChart'}
                    />
                  )}
                />
                <Tooltip message={t('profile.tax_consultant.account_chart_message')} />
              </Row>
            </FieldControl>
          </>
        </FormSection>

        <Divider />

        <FormSection heading={t('profile.tax_consultant.sections.contacts')}>
          <FormControl>
            <FieldControl>
              <Controller
                name="taxConsultantName"
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <FieldWrapper
                    type="text"
                    field={InputField}
                    name={name}
                    label={t('profile.tax_consultant.tax_consultant_name')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    dataId="TaxConsultantPage:input-taxConsultantName"
                  />
                )}
              />
            </FieldControl>
            <FieldControl>
              <FormFieldRow>
                <TextConsultantCredsColumn>
                  <Controller
                    name="taxConsultantId"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name },
                      fieldState: { error },
                    }) => (
                      <FieldWrapper
                        type="text"
                        field={InputField}
                        name={name}
                        label={t('profile.tax_consultant.tax_consultant_id')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        dataId="TaxConsultantPage:input-taxConsultantId"
                        error={error?.message}
                      />
                    )}
                  />
                </TextConsultantCredsColumn>
                <TextConsultantCredsColumn>
                  <Controller
                    name="taxConsultantClientId"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name },
                      fieldState: { error },
                    }) => (
                      <FieldWrapper
                        type="text"
                        field={InputField}
                        name={name}
                        label={t('profile.tax_consultant.tax_consultant_client_id')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        dataId="TaxConsultantPage:input-taxConsultantClientId"
                        error={error?.message}
                      />
                    )}
                  />
                </TextConsultantCredsColumn>
              </FormFieldRow>
            </FieldControl>

            <FieldControl>
              <Controller
                name="taxConsultantPhone"
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <FieldWrapper
                    type="text"
                    field={InputField}
                    name={name}
                    label={t('profile.tax_consultant.tax_consultant_phone')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    dataId="TaxConsultantPage:input-taxConsultantPhoneNumber"
                  />
                )}
              />
            </FieldControl>
            <FieldControl>
              <Controller
                name="taxConsultantEmail"
                control={control}
                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                  <FieldWrapper
                    type="text"
                    field={InputField}
                    name={name}
                    label={t('profile.tax_consultant.tax_consultant_email')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    dataId="TaxConsultantPage:input-taxConsultantEmail"
                    error={error?.message}
                  />
                )}
              />
            </FieldControl>
          </FormControl>
        </FormSection>

        <Divider />

        <FormSection heading={t('profile.tax_consultant.sections.export')}>
          <FieldControl>
            <PasswordContainer>
              <Controller
                name="exportPassword"
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <PasswordField
                    name={name}
                    label={t('profile.tax_consultant.export_password')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    dataId="TaxConsultantPage:input-exportPassword"
                  />
                )}
              />
            </PasswordContainer>
          </FieldControl>
        </FormSection>

        <Divider />

        <ActionsSection>
          <SubmitButton
            type="submit"
            $variant="contained"
            color="darkblue"
            disabled={isFetching}
            data-id="TaxConsultantPage:button-save"
          >
            {t('profile.tax_consultant.submit')}
          </SubmitButton>
          <CancelButton
            type="reset"
            onClick={goBack}
            $variant="contained"
            $color="white"
            data-id="TaxConsultantPage:button-abort"
          >
            {t('profile.tax_consultant.abort')}
          </CancelButton>
        </ActionsSection>

        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onConfirm={() => {
            setModalOpen(false);
          }}
          header={t('profile.company.modal_header')}
          customClass={styles.companyModal}
          confirmLabel={t('modals.confirmation.ok')}
          closeLabel={t('modals.cancel')}
          dangerousAction
        >
          {t('profile.company.modal_body')}
        </ConfirmationModal>
      </form>
    </ProfileSection>
  );
};

export default TaxConsultantForm;
