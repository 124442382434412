import React from 'react';

import { t } from 'shared/utils';
import Button from 'redesign/components/atoms/Button/Button';
import ModalActions from 'redesign/components/atoms/ModalActions/ModalActions';
import ModalBody from 'redesign/components/atoms/ModalBody/ModalBody';
import Modal from 'redesign/components/molecules/Modal/Modal';

import * as SC from './DatevWarningModal.styled';

type DatevWarningModalProps = {
  isOpen: boolean;
  onConfirm: (...args: unknown[]) => void;
};

export const DatevWarningModal = ({ isOpen, onConfirm }: DatevWarningModalProps) => (
  <Modal isOpen={isOpen} onRequestClose={onConfirm}>
    <SC.Title>{t('modals.outgoing_invoice_different_category_warning.title')}</SC.Title>
    <ModalBody>
      <SC.Message>{t('modals.outgoing_invoice_different_category_warning.message')}</SC.Message>
      <ModalActions>
        <Button data-id="changedCategoryConfirmationButton" onClick={onConfirm}>
          {t('modals.outgoing_invoice_different_category_warning.confirm')}
        </Button>
      </ModalActions>
    </ModalBody>
  </Modal>
);
