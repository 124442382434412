import React, { SVGAttributes } from 'react';

const IconInvoice = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 42 49" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M2.2 1.5h21.6L31 8.626v33.242a1.2 1.2 0 01-1.2 1.2H2.2a1.2 1.2 0 01-1.2-1.2V2.7a1.2 1.2 0 011.2-1.2z"
        stroke="#0066B3"
        strokeWidth={1.6}
        strokeLinejoin="round"
      />
      <path
        d="M6.27 12.585h7.46c.26 0 .47-.355.47-.792 0-.437-.21-.792-.47-.792H6.27c-.26 0-.47.355-.47.792 0 .437.21.792.47.792zM6.262 23.18l19.473.094c.256 0 .464-.332.465-.743 0-.412-.206-.747-.462-.748L6.265 21.69c-.256-.001-.464.331-.465.743 0 .412.206.746.462.747zM6.267 14.96h6.266c.258 0 .467-.354.467-.792 0-.437-.21-.792-.467-.792H6.267c-.258 0-.467.355-.467.792 0 .438.21.792.467.792zM6.261 25.556l14.674.093c.256.001.464-.33.465-.742.001-.412-.205-.747-.461-.749l-14.674-.093c-.256-.001-.464.331-.465.743-.001.411.205.746.461.748zM6.27 17.335h7.46c.26 0 .47-.354.47-.791 0-.438-.21-.792-.47-.792H6.27c-.26 0-.47.354-.47.792 0 .437.21.791.47.791zM6.242 27.931l19.513.093c.245.001.444-.331.445-.743 0-.412-.197-.746-.442-.747L6.245 26.44c-.245-.002-.444.331-.445.743 0 .411.197.746.442.747z"
        fill="#0066B3"
        fillRule="nonzero"
      />
      <g transform="translate(17.8 28.816)">
        <ellipse fill="#0066B3" cx={12.96} cy={8.551} rx={8.64} ry={8.551} />
        <g fill="#FFF">
          <path d="M9.186 8.159c-.3 0-.546-.27-.546-.607 0-.336.245-.608.546-.608h4.903c.302 0 .546.272.546.608 0 .335-.244.607-.547.607H9.186zM14.09 9.963H9.185c-.3 0-.546-.272-.546-.608 0-.334.245-.606.546-.606h4.903c.302 0 .546.27.546.607 0 .337-.244.609-.547.609" />
          <path d="M13.487 12.548c-2.148 0-3.897-1.855-3.897-4.135 0-2.281 1.749-4.137 3.897-4.137.514 0 1.016.106 1.491.315.285.125.42.472.301.774a.544.544 0 01-.726.318 2.646 2.646 0 00-1.065-.225c-1.535 0-2.783 1.326-2.783 2.955 0 1.63 1.247 2.954 2.783 2.954.367 0 .726-.075 1.065-.224a.546.546 0 01.727.32.602.602 0 01-.3.773c-.475.209-.977.315-1.491.315" />
        </g>
      </g>
      <path
        stroke="#0066B3"
        strokeWidth={1.6}
        fill="#FFF"
        strokeLinejoin="round"
        d="M23.8 1.5L31 8.626h-7.2z"
      />
    </g>
  </svg>
);
export default IconInvoice;
