export enum Preset {
  Custom,
  Design1,
  Design2,
  Design3,
}

export type InvoiceTemplate = {
  id: string;
  bankName: null | string;
  bic: null | string;
  iban: null | string;
  logoPosition: 'left' | 'center' | 'right';
  logoSizePercentage: number;
  logo: string | null;
  background: string | null;
  name: string;
  previewUrl: string;
  qrCodesEnabled: boolean;
  showContactDetails: boolean;
  showFooter: boolean;
  showSenderAddress: boolean;
  headerVersion: number;
  tableVersion: number;
  footerVersion: number;
  preset: Preset;
  fontFamily: string;
  fontSizeAddress: number;
  fontSizeInvoiceInfo: number;
  fontSizeSubject: number;
  fontSizeTableContent: number;
  fontSizeTableHeaders: number;
  fontSizeTableDescription: number;
  fontSizeNotes: number;
  fontSizeFooter: number;
  colouredElementsHex: string;
  invertColouredElementsTextColor: boolean;
  tableHeaderDescriptionText: string;
  tableHeaderNetAmountText: string;
  tableHeaderPositionText: string;
  tableHeaderQuantityText: string;
  tableHeaderTotalLineNetAmountText: string;
  tableHeaderUnitText: string;
  tableHeaderVatText: string;
  tableSummaryGrossTotalText: string;
  tableSummaryNetTotalText: string;
  logoOnAllPages: boolean;
  backgroundOnlyOnFirstPage: boolean;
};
