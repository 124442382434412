import React, { memo, useCallback, useState } from 'react';

import { t } from 'shared/utils';
import type { VrsoInstallment } from 'types/entities/Contract';
import Card from 'components/Card';

import PredictionSection, { Prediction } from './PredictionSection';
import Slider from './Slider';
import { getIdxToPay } from './utils';

import styles from './ProgressBar.module.css';

type ProgressBarProps = {
  installments: VrsoInstallment[];
  currentDate: Date;
};

export const ProgressBar = ({ installments, currentDate }: ProgressBarProps) => {
  const idxToPay = getIdxToPay(installments, currentDate);
  const [prediction, setPrediction] = useState<Prediction>(installments[idxToPay]);
  const lastInstallment = installments[installments.length - 1];

  const onIdxChange = useCallback(
    (idx: number | null) => {
      let prediction: Prediction = installments[idxToPay];

      if (idx !== null) {
        prediction = installments[idx];
      } else if (idxToPay === installments.length - 1) {
        prediction = lastInstallment;
      } else if (idxToPay === 0) {
        prediction = {
          dueDate: currentDate,
          totalPaid: 0,
          totalOwed: lastInstallment.totalPaid,
        };
      }

      setPrediction(prediction);
    },
    [currentDate, idxToPay, installments, lastInstallment]
  );

  return (
    <Card className={styles.card}>
      <div className={styles.header}>{t('contracts.new.progress_bar.header')}</div>
      <div className={styles.columns}>
        <div className={styles.left}>
          <Slider
            installments={installments}
            currentDate={currentDate}
            onIdxChange={onIdxChange}
            initialIdx={idxToPay === 0 ? 1 : idxToPay}
            idxToPay={idxToPay}
          />
        </div>
        <div className={styles.right}>
          <PredictionSection
            prediction={prediction}
            endDate={lastInstallment.dueDate}
            overall={lastInstallment.totalPaid}
          />
        </div>
      </div>
    </Card>
  );
};

type ProgressBarWrapperProps = {
  installments: VrsoInstallment[];
};

const ProgressBarWrapper = ({ installments }: ProgressBarWrapperProps) => (
  <ProgressBar
    installments={installments.map((installment) => ({
      ...installment,
      dueDate: new Date(installment.dueDate),
    }))}
    currentDate={new Date()}
  />
);

export default memo(ProgressBarWrapper);
