import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import normalize from 'json-api-normalizer';
import { arrayRemoveAll, change } from 'redux-form';
import build from 'redux-object';

import { getTransferAverages } from 'api/me/bankAccounts/transferAverages';
import { getContracts } from 'api/me/contracts';
import { getDownloadPdf as getDownloadPdfApiCall } from 'api/me/liquidity/downloadPdf';
import { DATE_FORMAT_FULL_REVERSED_UNICODE } from 'constants/datetime';
import EntityPath from 'constants/entitiesPaths';
import { AppThunk } from 'store';
import type { BankAccountTransferAverage } from 'types/entities/BankAccountTransferAverage';
import type { ExpenseContract, RevenueContract } from 'types/entities/Contract';

import type { BankAccount } from './BankAccounts/types';
import { isBankAccountUpToDate } from './BankAccounts/utils';
import { FormName } from './constants';
import { SliderValues } from './constants';
import * as formSelectors from './formSelectors';
import * as selectors from './selectors';

type LiquidityCalculatorState = {
  expenseContracts: {
    isLoading: boolean;
    error: string | null;
    data: ExpenseContract[];
  };
  revenueContracts: {
    isLoading: boolean;
    error: string | null;
    data: RevenueContract[];
  };
  bankAccounts: {
    isLoading: boolean;
    error: string | null;
    data: BankAccountTransferAverage[];
    selected: {
      [key: string]: boolean;
    };
  };
  bridgePeriodInMonths: number;
};

const initialState: LiquidityCalculatorState = {
  expenseContracts: {
    isLoading: false,
    error: null,
    data: [],
  },
  revenueContracts: {
    isLoading: false,
    error: null,
    data: [],
  },
  bankAccounts: {
    isLoading: false,
    error: null,
    data: [],
    selected: {},
  },
  bridgePeriodInMonths: SliderValues.default,
};

const slice = createSlice({
  name: 'liquidityCalculator',
  initialState,
  reducers: {
    setExpenseContractsStart({ expenseContracts }) {
      expenseContracts.isLoading = true;
    },
    setExpenseContractsSuccess(
      { expenseContracts },
      { payload }: PayloadAction<ExpenseContract[]>
    ) {
      expenseContracts.data = payload;
      expenseContracts.isLoading = false;
    },
    setExpenseContractsFailure({ expenseContracts }) {
      expenseContracts.isLoading = false;
    },
    setRevenueContractsStart({ revenueContracts }) {
      revenueContracts.isLoading = true;
    },
    setRevenueContractsSuccess(
      { revenueContracts },
      { payload }: PayloadAction<RevenueContract[]>
    ) {
      revenueContracts.data = payload;
      revenueContracts.isLoading = false;
    },
    setRevenueContractsFailure({ revenueContracts }) {
      revenueContracts.isLoading = false;
    },
    setBankAccountsStart({ bankAccounts }) {
      bankAccounts.isLoading = true;
    },
    setBankAccountsSuccess(
      { bankAccounts },
      { payload }: PayloadAction<BankAccountTransferAverage[]>
    ) {
      bankAccounts.data = payload;
      bankAccounts.isLoading = false;
    },
    setBankAccountsFailure({ bankAccounts }) {
      bankAccounts.isLoading = false;
    },
    setBridgeAmountInMonths(state, { payload }: PayloadAction<number>) {
      state.bridgePeriodInMonths = payload;
    },
  },
});

export const { setBridgeAmountInMonths } = slice.actions;

export default slice.reducer;

export const getExpenses = (): AppThunk => async (dispatch) => {
  const { setExpenseContractsStart, setExpenseContractsSuccess, setExpenseContractsFailure } =
    slice.actions;

  try {
    dispatch(setExpenseContractsStart());
    const response = await getContracts({
      is_revenue: false,
      without_pagination: true,
      // to make sure that upcomingInstallment is present
      filters: { transaction_date_gte: format(new Date(), DATE_FORMAT_FULL_REVERSED_UNICODE) },
    });
    const expenseContracts =
      build<ExpenseContract>(normalize(response.data), EntityPath.Contracts, null, {
        ignoreLinks: true,
      }) || [];
    dispatch(setExpenseContractsSuccess(expenseContracts));
  } catch (error) {
    dispatch(setExpenseContractsFailure());
  }
};

export const getRevenues = (): AppThunk => async (dispatch) => {
  const { setRevenueContractsStart, setRevenueContractsSuccess, setRevenueContractsFailure } =
    slice.actions;

  try {
    dispatch(setRevenueContractsStart());
    const response = await getContracts({
      is_revenue: true,
      without_pagination: true,
      // to make sure that upcomingInstallment is present
      filters: { transaction_date_gte: format(new Date(), DATE_FORMAT_FULL_REVERSED_UNICODE) },
    });
    const revenueContracts =
      build<RevenueContract>(normalize(response.data), EntityPath.Contracts, null, {
        ignoreLinks: true,
      }) || [];
    dispatch(setRevenueContractsSuccess(revenueContracts));
  } catch (error) {
    dispatch(setRevenueContractsFailure());
  }
};

export const getBankAccounts = (): AppThunk => async (dispatch) => {
  const { setBankAccountsStart, setBankAccountsSuccess, setBankAccountsFailure } = slice.actions;

  try {
    dispatch(setBankAccountsStart());
    const response = await getTransferAverages();
    const bankAccounts =
      build<BankAccountTransferAverage>(
        normalize(response.data, { camelizeKeys: true }),
        EntityPath.BankAccounts
      ) || [];
    dispatch(setBankAccountsSuccess(bankAccounts));
  } catch (error) {
    dispatch(setBankAccountsFailure());
  }
};

export const updateFormBankAccounts = (): AppThunk => async (dispatch, getState) => {
  const { setBankAccountsStart, setBankAccountsSuccess, setBankAccountsFailure } = slice.actions;
  const state = getState();
  const checkedBankAccounts = formSelectors
    .getBankAccounts(state)
    .filter((account) => account.isChecked)
    .reduce((acc: { [key: string]: boolean }, account) => {
      acc[account.id] = true;
      return acc;
    }, {});

  try {
    dispatch(setBankAccountsStart());
    const response = await getTransferAverages();
    const bankAccounts =
      build<BankAccountTransferAverage>(
        normalize(response.data, { camelizeKeys: true }),
        EntityPath.BankAccounts
      ) || [];
    const bankAccountsWithIsChecked = bankAccounts.map<BankAccount>((account) => ({
      ...account,
      isChecked: checkedBankAccounts[account.id],
      isDisabled: !isBankAccountUpToDate(account),
    }));

    dispatch(setBankAccountsSuccess(bankAccounts));
    dispatch(arrayRemoveAll(FormName, 'bankAccounts'));
    dispatch(change(FormName, 'bankAccounts', bankAccountsWithIsChecked));
  } catch (error) {
    dispatch(setBankAccountsFailure());
  }
};

export const getPDF = (): AppThunk => async (_dispatch, getState) => {
  const state = getState();
  const response = await getDownloadPdfApiCall(selectors.getPDFData(state));

  return response.data;
};
