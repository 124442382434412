import React from 'react';

import { t } from 'shared/utils';

import styles from './Chart.module.css';

const Legend = () => (
  <div className={styles.legend}>
    <div className={styles.legendCol}>
      <div className={styles.expensesUnpaid}>{t('dashboard.revenue_cost.expenses_unpaid')}</div>
      <div className={styles.expensesPaid}>{t('dashboard.revenue_cost.expenses_paid')}</div>
      <div className={styles.recurringExpenses}>
        {t('dashboard.revenue_cost.contract_expenses')}
      </div>
      <div className={styles.expenseCashTransactions}>
        {t('dashboard.revenue_cost.expense_cash_transactions')}
      </div>
    </div>
    <div className={styles.legendCol}>
      <div className={styles.revenueUnpaid}>{t('dashboard.revenue_cost.revenue_unpaid')}</div>
      <div className={styles.revenuePaid}>{t('dashboard.revenue_cost.revenue_paid')}</div>
      <div className={styles.recurringRevenues}>
        {t('dashboard.revenue_cost.contract_revenues')}
      </div>
      <div className={styles.revenueCashTransactions}>
        {t('dashboard.revenue_cost.revenue_cash_transactions')}
      </div>
    </div>
  </div>
);

export default Legend;
