import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import invalidLink from 'images/icon-invalid-link.svg';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import CardView, { HeadingSection, Section } from 'components/CardView';
import I18n from 'components/I18n';

import styles from '../Shared.module.css';

const InvalidToken = () => (
  <div className={styles.pageWrapper}>
    <div className={styles.wrapper}>
      <CardView className={styles.card}>
        <HeadingSection className={styles.cardSectionHeading}>
          <h3 className={styles.headingText}>
            <I18n t="invalid_token.title" />
          </h3>
        </HeadingSection>
        <Section className={cx(styles.cardSection, styles.contentSection)}>
          <img src={invalidLink} alt={t('registration.error.icon')} />
          <I18n t="invalid_token.description" />
          <Link to={paths.login} className={styles.button}>
            <I18n t="invalid_token.link" />
          </Link>
        </Section>
      </CardView>
    </div>
  </div>
);

export default InvalidToken;
