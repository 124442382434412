import React from 'react';
import cx from 'classnames';
import {
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';

import { BASIC_UID, PREMIUM_UID } from 'constants/payment-plans';
import { t } from 'shared/utils';

import ButtonPrimary from '../ComparisonBox/components/Buttons/ButtonPrimary';
import Description from '../ComparisonBox/components/Description/Description';
import Header from '../ComparisonBox/components/Header/Header';
import Section from './components/Section';
import { columns, options } from './table';

import styles from './ComparisonTable.module.css';

const ComparisonTable = ({ plans, tableRef, handleModalOpen }) => (
  <div ref={tableRef} className={styles.main}>
    <h2 className={styles.header}>{t('profile.payment_plan.functions_comparison')}</h2>
    <div className={styles.table}>
      <div className={styles.tableHeader}>
        {columns.map((column, index) => (
          <span key={`${column.title}_${index}`}>{column.title}</span>
        ))}
      </div>
      <Section options={options} />
      <div className={styles.plansContainer}>
        {plans.map((plan, planIndex) => {
          const isLast = planIndex + 1 === plans.length;

          return (
            <div className={styles.planBox} key={plan.uid}>
              <Header currentPlan={plan.uid} defaultPrice={plan.defaultPrice} isTable />
              {plan.uid === PREMIUM_UID && <Description currentPlan={plan.uid} />}
              {plan.uid === BASIC_UID && <div className={styles.emptySpace} />}
              <div className={cx(styles.button, { [styles.lastButton]: isLast })}>
                <ButtonPrimary currentPlan={plan.uid} handleModalOpen={handleModalOpen} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

ComparisonTable.propTypes = {
  handleModalOpen: func.isRequired,
  tableRef: shape({ current: instanceOf(element) }),
  plans: arrayOf(
    shape({
      name: string,
      uid: string,
      price: number,
      defaultPrice: number,
      vrMainBankAccount: bool,
      discountAmount: oneOfType([number, string]),
      id: string,
    })
  ),
};

export default ComparisonTable;
