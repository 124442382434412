import React from 'react';

const IconFarmpilotEdit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g>
      <path
        d="M5.287 17V8.343H4V7.028h1.287v-1.06c0-.783.085-1.37.255-1.761.17-.392.43-.69.781-.897.35-.207.79-.31 1.321-.31.413 0 .865.056 1.356.169l-.216 1.474a4.207 4.207 0 00-.855-.094c-.432 0-.741.108-.928.324-.187.216-.28.628-.28 1.235v.92h1.67v1.315h-1.67V17H5.287z"
        fillRule="evenodd"
      />
      <g transform="translate(5 5)">
        <rect width="15" height="15" rx="1" fill="none" />
        <path
          d="M11.366 3.633c-.737-.737-1.29-.627-1.29-.627L6.98 6.101 3.517 9.565 3 12l2.435-.517L8.9 8.02l3.094-3.094s.111-.554-.628-1.293zm-6.075 7.558l-.83.179a1.898 1.898 0 00-.353-.477c-.14-.142-.3-.26-.477-.353l.179-.83.24-.24s.452.01.962.52.52.961.52.961l-.24.24z"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export default IconFarmpilotEdit;
