import React, { useCallback, useState } from 'react';
import cx from 'classnames';

import { t } from 'shared/utils';
import {
  DownloadContract,
  NewFileBtn,
  NextFileBtn,
  PrevFileBtn,
} from 'components/Dropzone/Gallery';
import Thumbnail from 'components/Dropzone/Thumbnail/Thumbnail';
import { ConfirmationModal } from 'components/Modal';

import type {
  ActiveNode,
  DeleteFile,
  FileNameGenerator,
  PreviewFile,
  TrackingEvents,
} from '../types';

import styles from './Gallery.module.css';

type GalleryProps = {
  activeFileId?: string;
  className?: string;
  deleteFile: DeleteFile;
  files: PreviewFile[];
  readonly?: boolean;
  contractId: string;
  onNewFileButtonClick: () => void;
  onNextSelect: () => void;
  onPrevSelect: () => void;
  onSelect: (fileId: string) => void;
  setActiveThumbnailNode: ActiveNode;
  isPreviewRendering: boolean;
  fileNameGenerator: FileNameGenerator;
  trackingEvents?: TrackingEvents;
  fileSubject?: string;
  contractNumber?: string;
  isFullscreen?: boolean;
  onDownloadContractClick?: () => void;
  contractDownloadName: string;
  contractDownloadUrl?: string;
  isDownloadingContract: boolean;
};

const Gallery = ({
  deleteFile,
  activeFileId = '',
  className = '',
  files,
  contractId,
  readonly,
  onNewFileButtonClick,
  onNextSelect,
  onPrevSelect,
  onSelect,
  setActiveThumbnailNode,
  isPreviewRendering = false,
  fileNameGenerator,
  trackingEvents,
  fileSubject,
  contractNumber,
  isFullscreen,
  onDownloadContractClick,
  contractDownloadName,
  contractDownloadUrl,
  isDownloadingContract,
}: GalleryProps) => {
  const [isDeleteFileModalVisible, setIsDeleteFileModalVisible] = useState(false);
  const [focusedFileId, setFocusedFileId] = useState('');

  const toggleDeleteFileModal = (fileId: string) => {
    setFocusedFileId(fileId);
    setIsDeleteFileModalVisible(true);
  };

  const hideModal = useCallback(() => {
    setFocusedFileId('');
    setIsDeleteFileModalVisible(false);
  }, []);

  const handleConfirmRemoval = () => {
    deleteFile(contractId, focusedFileId);
    hideModal();
  };

  return (
    <div className={cx(styles.main, className, readonly && !isFullscreen && styles.readonly)}>
      <div className={styles.thumbnailsContainer}>
        <PrevFileBtn onClick={onPrevSelect} isPreviewRendering={isPreviewRendering} />

        {files.map((file, index: number) => (
          <Thumbnail
            file={{
              ...file,
              name: fileNameGenerator(contractId, file.id, fileSubject, contractNumber, index + 1),
            }}
            key={file.id}
            isActive={file.id === activeFileId}
            onClick={() => onSelect(file.id)}
            onRemove={() => toggleDeleteFileModal(file.id)}
            readonly={readonly && !isFullscreen}
            setActiveThumbnailNode={setActiveThumbnailNode}
            trackingEvents={trackingEvents}
            fileNumber={index + 1}
            isFullscreen={isFullscreen}
          />
        ))}

        {!readonly && !isFullscreen && <NewFileBtn onClick={onNewFileButtonClick} />}
        {isFullscreen && (
          <DownloadContract
            onClick={onDownloadContractClick}
            contractDownloadUrl={contractDownloadUrl}
            contractDownloadName={contractDownloadName}
            isDownloadingContract={isDownloadingContract}
          />
        )}

        <NextFileBtn onClick={onNextSelect} isPreviewRendering={isPreviewRendering} />
      </div>
      <ConfirmationModal
        dangerousAction
        isOpen={isDeleteFileModalVisible}
        onClose={hideModal}
        onConfirm={handleConfirmRemoval}
        closeLabel={t('modals.confirmation.cancel')}
        confirmLabel={t('modals.confirmation.confirm')}
      >
        {t('modals.incoming_invoice.delete_file_confirmation_body')}
      </ConfirmationModal>
    </div>
  );
};

export default Gallery;
