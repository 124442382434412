import React, { memo, SVGProps } from 'react';

const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 37" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <clipPath id="Arrow_svg__a">
        <path d="M3469 0v2603H0V0h3469Z" />
      </clipPath>
    </defs>
    <g clipPath="url(#Arrow_svg__a)" transform="translate(-1388 -544)">
      <path
        d="m1390.793 546.95 14 16-14 16"
        stroke="#0066B3"
        strokeWidth={4}
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
    </g>
  </svg>
);

export default memo(ArrowRight);
