import { showNotification } from 'actions/notification';
import { fetchProposalSums } from 'actions/proposal/sums';
import { CALL_API } from 'constants/api';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/proposal/line-items';
import {
  lineItemCreateSuccess,
  lineItemDeleteFailure,
  lineItemDeleteSuccess,
  lineItemUpdateSuccess,
} from 'notifications/proposals';
import { apiErrorHandler, isNetworkError } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

export const apiFetchProposalLineItems = (proposalId) => ({
  [CALL_API]: {
    endpoint: `/me/proposals/${proposalId}/proposal_line_items`,
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiDeleteProposalLineItem = (proposalId, id) => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: `/me/proposals/${proposalId}/proposal_line_items/${id}`,
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const apiUpdateProposalLineItem = (proposalId, id, data) => ({
  [CALL_API]: {
    data,
    method: 'PUT',
    endpoint: `/me/proposals/${proposalId}/proposal_line_items/${id}`,
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const apiCreateProposalLineItem = (proposalId, data) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: `/me/proposals/${proposalId}/proposal_line_items`,
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const fetchProposalLineItems =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchProposalLineItems(...args)).catch(apiErrorHandler);

export const deleteProposalLineItem = (proposalId, id) => (dispatch) =>
  bindServerValidation(apiDeleteProposalLineItem(proposalId, id), dispatch, { isReduxForm: false })
    .then(() => {
      dispatch(showNotification(lineItemDeleteSuccess));
      dispatch(fetchProposalSums(proposalId));
    })
    .catch((err) => {
      apiErrorHandler(err);
      if (!isNetworkError(err)) {
        dispatch(showNotification(lineItemDeleteFailure));
      }
    });

export const updateProposalLineItem = (proposalId, id, formValues) => (dispatch) =>
  bindServerValidation(apiUpdateProposalLineItem(proposalId, id, formValues), dispatch, {
    isReduxForm: false,
  })
    .then(() => {
      dispatch(showNotification(lineItemUpdateSuccess));
      dispatch(fetchProposalSums(proposalId));
    })
    .catch((err) => {
      apiErrorHandler(err);
      throw err;
    });

export const createProposalLineItem = (proposalId, formValues) => (dispatch) =>
  bindServerValidation(apiCreateProposalLineItem(proposalId, formValues), dispatch, {
    isReduxForm: false,
  })
    .then(() => {
      dispatch(showNotification(lineItemCreateSuccess));
      dispatch(fetchProposalSums(proposalId));
    })
    .catch((err) => {
      apiErrorHandler(err);
      throw err;
    });
