import { combineReducers } from 'redux';

import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/summary';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return true;
    case FETCH_SUCCESS:
    case FETCH_FAILURE:
      return false;
    default:
      return state;
  }
};

const data = (state = {}, action) => {
  let response;
  switch (action.type) {
    case FETCH_SUCCESS:
      response = action.response.data.attributes;
      return { ...response };
    case FETCH_REQUEST:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  data,
});
