import * as fromAuth from './auth';
import * as fromBankAccount from './bank-account';
import * as fromCountries from './countries';
import * as fromSuppliers from './suppliers';

export const getSupplier = (state, data) => fromSuppliers.getSupplier(state.suppliers, data);

export const getLoginProcessStatus = (state) => fromAuth.getLoginProcessStatus(state.auth);

export const getOutOfSyncAccounts = (state, data) =>
  fromBankAccount.getOutOfSyncAccounts(state.bankAccount, data);

export const getCountryNameByCode = (state) => fromCountries.getCountryNameByCode(state.countries);

const hasLineItems = ({ lineItems = [] } = {}) => Boolean(lineItems.length);

export const proposalHasLineItems = (state) => hasLineItems(state.proposal);

export const orderConfirmationHasLineItems = (state) => hasLineItems(state.orderConfirmation);

export const outgoingInvoiceHasLineItems = (state) => hasLineItems(state.outgoingInvoice);

export const incomingInvoiceHasLineItems = (state) => hasLineItems(state.incomingInvoice);

export const deliveryNoteHasLineItems = (state) => hasLineItems(state.deliveryNote);
