import React from 'react';
import { isEmpty } from 'lodash';
import { arrayOf, bool, func, shape } from 'prop-types';

import ParallelContainer from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoicePaymentSection/PaymentCreator/components//ParallelContainer/ParallelContainer';
import FieldsContainer from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoicePaymentSection/PaymentCreator/components/FieldsContainer/FieldsContainer';
import Hint from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoicePaymentSection/PaymentCreator/components/Hint/Hint';
import TanSchemeSelectField from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoicePaymentSection/PaymentCreator/components/TanSchemeSelectField/TanSchemeSelectField';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import Loading from 'components/Loading';

import styles from '../Page.module.css';
import localStyles from './TanSelection.module.css';

const TanSelection = ({
  supportedTanSchemes,
  selectedTanScheme,
  setCreatorState,
  handleCancel,
  handleNextStep,
  isLoading,
}) => (
  <div className={styles.page}>
    <div className={styles.body}>
      <FieldsContainer>
        <Hint text={t('expenses.payments.creator.hints.challenge_method_selection')} />
        <ParallelContainer>
          <TanSchemeSelectField
            name="tanSchemeId"
            className={localStyles.field}
            {...(selectedTanScheme ? { initialSelectedItem: selectedTanScheme } : {})}
            tanSchemes={supportedTanSchemes}
            onSelect={setCreatorState}
            menuWrapperClassName={localStyles.menuHeight}
            label={t('expenses.payments.creator.form.tan_scheme_id')}
            dataIds={{
              container: 'BankAccount:dropdown-container',
              optionPrefix: 'BankAccount:dropdown-option_',
            }}
          />
          <div />
        </ParallelContainer>
      </FieldsContainer>
      {isLoading && <Loading />}
    </div>
    <div className={styles.footer}>
      <ActionButton
        appearance="outlined"
        disabled={isLoading}
        onClick={handleCancel}
        label={t('bank_accounts.creator.prev_step')}
        className={styles.stepButton}
        type="button"
        dataId="BankAccount:button-previousStep"
      />
      <ActionButton
        onClick={handleNextStep}
        disabled={isEmpty(selectedTanScheme) || isLoading}
        label={t('bank_accounts.creator.next_step')}
        className={styles.stepButton}
        type="submit"
        data-id="BankAccount:creatorSecondStepFinish"
      />
    </div>
  </div>
);

TanSelection.propTypes = {
  supportedTanSchemes: arrayOf(shape({})).isRequired,
  setCreatorState: func.isRequired,
  selectedTanScheme: shape({}),
  handleCancel: func.isRequired,
  handleNextStep: func.isRequired,
  isLoading: bool.isRequired,
};

export default TanSelection;
