import { generateMedia } from 'styled-media-query';

export enum Breakpoints {
  xsMax = '575px',
  sm = '576px',
  smMax = '767px',
  md = '768px',
  mdMax = '991px',
  lg = '992px',
  lgMax = '1199px',
  xl = '1200px',
}

export const media = generateMedia(Breakpoints);
