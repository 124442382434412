import React, { PureComponent } from 'react';

import { t } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';
import { ConfirmationModal } from 'components/Modal';

interface ResetDefaultsButtonProps {
  className?: string;
  sectionName: string;
  sectionLabel: string;
  onReset: () => void;
  dataIdPrefix?: string;
}

interface ResetDefaultsButtonState {
  isModalOpened: boolean;
}

class ResetDefaultsButton extends PureComponent<
  ResetDefaultsButtonProps,
  ResetDefaultsButtonState
> {
  state = {
    isModalOpened: false,
  };

  setModalOpened = (isModalOpened: boolean) => {
    this.setState({ isModalOpened });
  };

  handleButtonClick = () => {
    this.setModalOpened(true);
  };

  handleModalAccept = (): void => {
    const { onReset } = this.props;

    this.setModalOpened(false);
    onReset();
  };

  render() {
    const { className = '', sectionName, sectionLabel, dataIdPrefix = 'Defaults:' } = this.props;
    const { isModalOpened } = this.state;

    return (
      <>
        <div
          tabIndex={0}
          data-id={`${dataIdPrefix}reset-button_${sectionName}`}
          role="button"
          onKeyPress={isPressedEnter(this.handleButtonClick)}
          onClick={this.handleButtonClick}
          className={className}
        >
          {t('invoice_templates.documents_defaults.reset_button')}
        </div>
        <ConfirmationModal
          dangerousAction
          hasWarningIcon
          isOpen={isModalOpened}
          onClose={() => this.setModalOpened(false)}
          onConfirm={this.handleModalAccept}
          header={t('invoice_templates.reset.modal_header')}
          closeLabel={t('invoice_templates.reset.modal_abort')}
          confirmLabel={t('invoice_templates.reset.modal_accept')}
          dataIds={{
            modal: `${dataIdPrefix}reset-modal`,
            abortButton: `${dataIdPrefix}button-abort-reset`,
            acceptButton: `${dataIdPrefix}button-accept-reset`,
          }}
        >
          {t('invoice_templates.reset.modal_content', {
            tabName: sectionLabel,
          })}
        </ConfirmationModal>
      </>
    );
  }
}

export default ResetDefaultsButton;
