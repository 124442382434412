import * as Yup from 'yup';

import { t } from 'shared/utils';

export default Yup.object({
  tableHeaderDescriptionText: Yup.string()
    .trim()
    .required(t('features.settings.document_template.table.errors.required')),
  tableHeaderNetAmountText: Yup.string()
    .trim()
    .required(t('features.settings.document_template.table.errors.required')),
  tableHeaderPositionText: Yup.string()
    .trim()
    .required(t('features.settings.document_template.table.errors.required')),
  tableHeaderQuantityText: Yup.string()
    .trim()
    .required(t('features.settings.document_template.table.errors.required')),
  tableHeaderTotalLineNetAmountText: Yup.string()
    .trim()
    .required(t('features.settings.document_template.table.errors.required')),
  tableHeaderUnitText: Yup.string()
    .trim()
    .required(t('features.settings.document_template.table.errors.required')),
  tableHeaderVatText: Yup.string()
    .trim()
    .required(t('features.settings.document_template.table.errors.required')),
  tableSummaryGrossTotalText: Yup.string()
    .trim()
    .required(t('features.settings.document_template.table.errors.required')),
  tableSummaryNetTotalText: Yup.string()
    .trim()
    .required(t('features.settings.document_template.table.errors.required')),
}).required();
