import React, { memo, MouseEvent, useCallback, useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { isEqual } from 'lodash';

import { t } from 'shared/utils';
import { Preset as PresetType } from 'types/entities/InvoiceTemplate';
import ThumbnailDesign1 from 'redesign/components/atoms/Pictures/Design1';
import ThumbnailDesign2 from 'redesign/components/atoms/Pictures/Design2';
import ThumbnailDesign3 from 'redesign/components/atoms/Pictures/Design3';
import ThumbnailDesignCustom from 'redesign/components/atoms/Pictures/DesignCustom';
import HorizontalScrollShadow from 'redesign/components/molecules/HorizontalScrollShadow/HorizontalScrollShadow';

import { Preset, Preview, Wrapper } from './Presets.styled';

type PresetsProps = {
  value: PresetType;
  onChange: (value: PresetType) => void;
};

const Presets = ({ value, onChange }: PresetsProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const newValue =
        event.currentTarget.dataset.id !== undefined && parseInt(event.currentTarget.dataset.id);

      if (newValue === undefined || newValue === value) return;

      event.currentTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
      onChange(newValue as PresetType);
    },
    [onChange, value]
  );

  useEffectOnce(() => {
    const wrapper = wrapperRef.current;
    const preset = wrapper?.querySelector<HTMLDivElement>(`[data-id="${value}"]`);
    const border = 4;

    if (!wrapper || !preset) return;

    wrapper.scrollTo({ left: preset.offsetLeft - wrapper.offsetLeft - border });
  });

  return (
    <HorizontalScrollShadow>
      <Wrapper ref={wrapperRef}>
        <Preset
          $isActive={value === PresetType.Design1}
          onClick={handleClick}
          data-id={PresetType.Design1}
        >
          <Preview>
            <ThumbnailDesign1 />
          </Preview>
          {t('features.settings.document_template.layout.presets.design_1')}
        </Preset>
        <Preset
          $isActive={value === PresetType.Design2}
          onClick={handleClick}
          data-id={PresetType.Design2}
        >
          <Preview>
            <ThumbnailDesign2 />
          </Preview>
          {t('features.settings.document_template.layout.presets.design_2')}
        </Preset>
        <Preset
          $isActive={value === PresetType.Design3}
          onClick={handleClick}
          data-id={PresetType.Design3}
        >
          <Preview>
            <ThumbnailDesign3 />
          </Preview>
          {t('features.settings.document_template.layout.presets.design_3')}
        </Preset>
        <Preset
          $isActive={value === PresetType.Custom}
          onClick={handleClick}
          data-id={PresetType.Custom}
        >
          <Preview>
            <ThumbnailDesignCustom />
          </Preview>
          {t('features.settings.document_template.layout.presets.custom')}
        </Preset>
      </Wrapper>
    </HorizontalScrollShadow>
  );
};

export default memo(Presets, isEqual);
