export const XXSM = '22em';
export const XSM = '25em';
export const SM = '34em';
export const MD = '48em';
export const LG = '62em';
export const XLG = '75em';
export const XXLG = '85em';

export const XXSM_UP = `(min-width: ${XXSM})`;
export const XSM_UP = `(min-width: ${XSM})`;
export const SM_UP = `(min-width: ${SM})`;
export const MD_UP = `(min-width: ${MD})`;
export const LG_UP = `(min-width: ${LG})`;
export const XLG_UP = `(min-width: ${XLG})`;
export const XXLG_UP = `(min-width: ${XXLG})`;
