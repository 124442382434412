import React, { ChangeEvent, FunctionComponent } from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';
import { isNull, isUndefined, noop } from 'lodash';

import iconCancel from 'images/icon-cancel.svg';
import { t } from 'shared/utils';
import ArrowIcon from 'components/ArrowIcon/ArrowIcon';
import TextField from 'components/Form/TextField';
import { Label } from 'components/Label/Label';

import styles from './FullTextSearch.module.css';

interface FullTextSearchProps {
  value: string | null;
  label: string;
  placeholder: string;
  onChange: (value: string) => void;
  onResetButtonClick: () => void;
  onFilterDropdownClick: () => void;
  isResetButtonDisabled?: boolean;
  areAdditionalFiltersOpened: boolean;
  areAdditionalFiltersAvailable?: boolean;
  dataIds?: {
    input?: string;
    resetButton?: string;
    toggleDropdownButton?: string;
  };
}

const FullTextSearch: FunctionComponent<FullTextSearchProps> = ({
  value,
  label,
  placeholder,
  onChange,
  onFilterDropdownClick,
  areAdditionalFiltersOpened,
  areAdditionalFiltersAvailable = true,
  onResetButtonClick,
  isResetButtonDisabled = false,
  children,
  dataIds = {},
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapContainer}>
        <TextField
          placeholder={placeholder}
          label={label}
          input={{
            value: isUndefined(value) || isNull(value) ? '' : value,
            onChange: (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value),
          }}
          className={styles.input}
          isSearchIcon
          dataId={dataIds.input}
        />
        <div className={styles.buttonsContainer}>
          <div className={styles.wrapContainer}>
            {areAdditionalFiltersAvailable && (
              <Label
                onClick={onFilterDropdownClick}
                className={cx(styles.button, styles.toggleFiltersButton)}
                dataId={dataIds.toggleDropdownButton}
              >
                <ArrowIcon className={styles.icon} isOpen={areAdditionalFiltersOpened} />{' '}
                {areAdditionalFiltersOpened
                  ? t('tables.filters.filters_group.full_text_search.close_additional_filters')
                  : t('tables.filters.filters_group.full_text_search.open_additional_filters')}
              </Label>
            )}
            <Label
              className={cx(styles.button, styles.resetFiltersButton, {
                [styles.buttonDisabled]: isResetButtonDisabled,
              })}
              onClick={isResetButtonDisabled ? noop : onResetButtonClick}
              dataId={dataIds.resetButton}
            >
              <div className={styles.icon}>
                <InlineSvg src={iconCancel} />
              </div>
              {t('tables.filters.filters_group.full_text_search.clear_filters')}
            </Label>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export { FullTextSearch };
