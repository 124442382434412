import React from 'react';
import cx from 'classnames';
import { bool, func, node, shape, string } from 'prop-types';

import RawCheckbox from 'components/Form/RawCheckbox/RawCheckbox';

import styles from './Checkbox.module.css';

const Checkbox = ({
  children,
  input = {},
  meta = {},
  className,
  verticallyCentered = false,
  preventWordBreak = false,
  customOnChange,
  dataIds = {},
  ...rest
}) => {
  const checkboxId = `checkbox-${Math.random()}`;
  const value = 'checked' in rest ? rest.checked : Boolean(input.value);
  const onChange = (event) => {
    if (customOnChange) customOnChange(event);
    input.onChange(event);
  };
  const { name } = input;

  return (
    <div
      className={cx(styles.wrapper, className, {
        [styles.verticallyCentered]: verticallyCentered,
      })}
    >
      <RawCheckbox
        onChange={onChange}
        name={name}
        checked={value}
        invalid={meta.touched && !meta.valid}
        checkboxId={checkboxId}
        dataId={dataIds.input}
        {...rest}
      />
      <label
        className={cx(styles.label, {
          [styles.verticallyCentered]: verticallyCentered,
          [styles.labelPreventBreak]: preventWordBreak,
        })}
        htmlFor={checkboxId}
        data-id={dataIds.label}
      >
        {children}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  className: string,
  children: node.isRequired,
  meta: shape({}).isRequired,
  input: shape({}).isRequired,
  customOnChange: func,
  verticallyCentered: bool,
  preventWordBreak: bool,
  dataIds: shape({
    input: string,
    label: string,
  }),
};

export default Checkbox;
