import React, { ComponentType, MouseEvent, Ref } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { isExternalLink } from 'shared/utils/links';

import ButtonContent from './ButtonContent';

import styles from './Button.module.css';

export const ButtonAppearances: {
  [key: string]: 'primary' | 'stroke' | 'outlined' | 'outlinedReversed' | 'flat' | 'orange';
} = {
  primary: 'primary',
  secondary: 'stroke',
  outlined: 'outlined',
  outlinedReversed: 'outlinedReversed',
  flat: 'flat',
  orange: 'orange',
};

interface CustomComponent {
  ref?: Ref<HTMLButtonElement>;
}

interface ButtonProps {
  label: string;
  icon?: string;
  rightIcon?: string;
  onClick?: (e?: MouseEvent) => void;
  /** Class that will be set for `button` element on custom component passed as props */
  className?: string | string[];
  visible?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  /** Reverse label and icon positions */
  snapIconToRight?: boolean;
  centered?: boolean;
  fullWidth?: boolean;
  /** Additional onClick that will be called after onClick */
  onClickSideAction?: (e?: MouseEvent) => void;
  appearance?: 'primary' | 'stroke' | 'outlined' | 'outlinedReversed' | 'flat' | 'orange';
  type?: 'button' | 'reset' | 'submit';
  dataId?: string;
  /** Custom component to be used instead of `button` element */
  Component?: ComponentType<CustomComponent>;
  /** Class for svg container */
  svgClass?: string;
  /** Ref that will be passed to button html element */
  buttonRef?: Ref<HTMLButtonElement>;
  redirect?: string;
  target?: string;
  rel?: string;
}

/**
 *  `Button` component which should be used whenever there is a need to display a `button` html element.
 */
const Button = ({
  label = '',
  icon = '',
  rightIcon = '',
  svgClass = '',
  onClick = () => {},
  className = '',
  visible = true,
  disabled = false,
  invalid = false,
  snapIconToRight = false,
  centered = false,
  fullWidth = false,
  onClickSideAction = () => {},
  appearance = ButtonAppearances.primary,
  type = 'button',
  dataId = '',
  Component,
  buttonRef,
  redirect,
  ...rest
}: ButtonProps) => {
  const mainStyle = () => {
    switch (appearance) {
      case ButtonAppearances.primary:
      default:
        return styles.buttonPrimary;
      case ButtonAppearances.secondary:
        return styles.buttonSecondary;
      case ButtonAppearances.outlined:
        return styles.buttonOutlined;
      case ButtonAppearances.outlinedReversed:
        return styles.buttonOutlinedReversed;
      case ButtonAppearances.flat:
        return styles.buttonFlat;
      case ButtonAppearances.orange:
        return styles.buttonOrange;
    }
  };

  const onClickWithSideAction = (...args: []) => {
    onClick(...args);
    onClickSideAction(...args);
  };

  const getProps = {
    type,
    className: cx(mainStyle(), className, {
      [styles.reverse]: snapIconToRight,
      [styles.disabled]: disabled,
      [styles.invalid]: invalid,
      [styles.centered]: centered,
      [styles.fullWidth]: fullWidth,
    }),
    onClick: onClickWithSideAction,
    disabled,
    ...rest,
  };

  if (!visible) return null;

  if (redirect) {
    return isExternalLink(redirect) ? (
      <a href={redirect} data-id={dataId} {...getProps}>
        {label}
      </a>
    ) : (
      <Link to={redirect} data-id={dataId} {...getProps}>
        {label}
      </Link>
    );
  }

  if (Component) {
    return (
      <Component data-id={dataId} ref={buttonRef} {...getProps}>
        <ButtonContent label={label} icon={icon} rightIcon={rightIcon} svgClass={svgClass} />
      </Component>
    );
  }

  return (
    <button data-id={dataId} ref={buttonRef} {...getProps}>
      <ButtonContent label={label} icon={icon} rightIcon={rightIcon} svgClass={svgClass} />
    </button>
  );
};

export default Button;
