import React from 'react';
import { CellProps, Column } from 'react-table';

import { t } from 'shared/utils';
import { formatIBAN } from 'shared/utils';
import { BankAccount } from 'types/entities/BankAccount';
import Amount from 'redesign/components/atoms/Amount/Amount';
import Checkbox from 'redesign/components/atoms/Checkbox/Checkbox';
import CheckIcon from 'redesign/components/atoms/Icons/Check';
import CloseIcon from 'redesign/components/atoms/Icons/Close';
import DeleteIcon from 'redesign/components/atoms/Icons/Delete';
import EuroIcon from 'redesign/components/atoms/Icons/Euro';
import Label from 'redesign/components/atoms/Label/Label';
import TableCell from 'redesign/components/atoms/TableCell/TableCell';
import TableHeader from 'redesign/components/atoms/TableHeader/TableHeader';
import ActionIcon from 'redesign/components/molecules/ActionIcon/ActionIcon';
import EditableInput from 'redesign/components/molecules/EditableInput/EditableInput';

import { withComma, withDot } from '../../../components/v2/Form/NumberField/utils';
import { AmountCreditLine, InputWrapper } from './TableItem.styled';

type GetColumnsProps = {
  onClickMain: (item: BankAccount) => () => void;
  showDeleteModal: (item: BankAccount) => () => void;
  handleEdit: (item: BankAccount) => () => void;
  onSaveEdit: () => void;
  onEndEditMode: () => void;
  itemToEdit: Pick<BankAccount, 'id' | 'name' | 'creditLine'> | null;
};

/* eslint-disable react/display-name */
const getColumns = ({
  onClickMain,
  showDeleteModal,
  handleEdit,
  onEndEditMode,
  onSaveEdit,
  itemToEdit,
}: GetColumnsProps): Column<BankAccount>[] => {
  return [
    {
      id: 'name',
      Header: t('redesign.organisms.bank_accounts_list.description'),
      Cell: ({ row: { original } }: CellProps<BankAccount>) => {
        return itemToEdit && itemToEdit.id === original.id ? (
          <InputWrapper>
            <EditableInput
              value={itemToEdit.name}
              updateData={(value) => {
                itemToEdit.name = value;
              }}
            />
          </InputWrapper>
        ) : (
          <TableCell title={original.name}>{original.name}</TableCell>
        );
      },
    },
    {
      id: 'iban',
      Header: t('redesign.organisms.bank_accounts_list.account_number'),
      Cell: ({ row: { original } }: CellProps<BankAccount>) => {
        return <TableCell title={original.iban}>{formatIBAN(original.iban)}</TableCell>;
      },
    },
    {
      id: 'balance',
      Header: t('redesign.organisms.bank_accounts_list.balance'),
      Cell: ({ row: { original } }: CellProps<BankAccount>) => {
        return <Amount amount={original.balance} />;
      },
    },
    {
      id: 'creditLine',
      Header: t('redesign.organisms.bank_accounts_list.credit_line'),
      Cell: ({ row: { original } }: CellProps<BankAccount>) => {
        return itemToEdit && itemToEdit.id === original.id ? (
          <InputWrapper>
            <EditableInput
              value={`${withComma(itemToEdit.creditLine.toString())}`}
              updateData={(value) => {
                const creditLine = Number(withDot(value));

                if (!isNaN(creditLine)) itemToEdit.creditLine = creditLine;
              }}
              icon={<EuroIcon />}
            />
          </InputWrapper>
        ) : (
          <AmountCreditLine amount={original.creditLine} />
        );
      },
    },
    {
      Header: t('redesign.organisms.bank_accounts_list.main_account'),
      id: 'main',
      Cell: ({ row: { original } }: CellProps<BankAccount>) => {
        return original.paymentApplicable ? (
          <Checkbox
            checked={original.main}
            onClick={original.main ? undefined : onClickMain(original)}
          />
        ) : (
          <Label $isDisabled>
            {t('redesign.organisms.bank_accounts_list.main_account_not_selectable')}
          </Label>
        );
      },
    },
    {
      id: 'actions',
      Header: () => (
        <TableHeader align="right">
          {t('redesign.organisms.bank_accounts_list.actions')}
        </TableHeader>
      ),
      Cell: ({ row: { original } }: CellProps<BankAccount>) => {
        return (
          <TableCell align="right">
            {itemToEdit && itemToEdit.id === original.id ? (
              <>
                <ActionIcon onClick={onSaveEdit} icon={<CheckIcon />} />
                <ActionIcon onClick={onEndEditMode} icon={<CloseIcon />} />
              </>
            ) : (
              <>
                <ActionIcon dataId="cellButton-edit" onClick={handleEdit(original)} />
                <ActionIcon
                  dataId="cellButton-delete"
                  onClick={showDeleteModal(original)}
                  icon={<DeleteIcon />}
                />
              </>
            )}
          </TableCell>
        );
      },
    },
  ];
};
/* eslint-enable react/display-name */

export default getColumns;
