import React from 'react';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { func, string } from 'prop-types';

import { DE_ABORT, DE_CLIENT_MANAGEMENT } from 'constants/clients';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import ActionButton from 'components/ActionPanel/ActionButton';
import { ButtonAppearances } from 'components/Button';

import styles from './AbortButton.module.css';

const trackAbort = () => piwikHelpers.trackEvent(DE_CLIENT_MANAGEMENT, DE_ABORT);

const AbortButton = ({ onClick, dataId = '' }) => (
  <div className={styles.transparentContainer}>
    <ActionButton
      fullWidth
      appearance={ButtonAppearances.outlined}
      onClick={() => {
        trackAbort();
        onClick();
      }}
      label={t('clients.form.abort')}
      dataId={dataId}
    />
  </div>
);

AbortButton.propTypes = {
  onClick: func.isRequired,
  dataId: string.isRequired,
};

export default connect(null, (dispatch) => ({
  onClick: () => dispatch(goBack()),
}))(AbortButton);
