import React from 'react';
import { Link } from 'react-router-dom';

import paths from 'routes/paths';
import { t } from 'shared/utils';

import localStyles from './LoginFooter.module.css';

const LoginFooter = () => (
  <div className={localStyles.footer}>
    <div className={localStyles.alreadyHaveAnAccount}>
      {t('request_registration.already_have_account')}
    </div>
    <Link to={paths.login} data-id="LoginFooter:link-backToLogin">
      {t('request_registration.go_to_login')}
    </Link>
  </div>
);

export default LoginFooter;
