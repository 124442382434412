import { CLOSE_DATEV_MODAL } from 'constants/incoming-invoice';

const initialState = {
  alreadyCreated: false,
  showDialog: false,
};

export default (successType) =>
  (state = initialState, action) => {
    switch (action.type) {
      case successType: {
        const { data } = action.response;
        return {
          alreadyCreated: true,
          showDialog: data.meta.needsAnotherDatevReport,
        };
      }
      case CLOSE_DATEV_MODAL: {
        return initialState;
      }
      default:
        return state;
    }
  };
