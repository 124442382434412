import React from 'react';
import { connect } from 'react-redux';
import { fill, isFinite, mapValues } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { DATE_FORMAT_MONTH_REVERSED } from 'constants/datetime';
import { EXPENSES, REVENUES, SUM } from 'constants/tables';
import { formatMoney, getMonthNumber, t } from 'shared/utils';
import I18n from 'components/I18n';

import styles from './Table.module.css';

const monthNames = t('dashboard.revenue_cost.months_abrv', { returnObjects: true });

const getValuesBasedOnType = (type) => (v, key) => {
  const countKey = `${key}-count`;

  switch (type) {
    case SUM:
      return {
        amount: v.expenses[key] + v.revenues[key],
        count: v.expenses[countKey] + v.revenues[countKey],
      };
    case REVENUES:
      return {
        amount: v.revenues[key],
        count: v.revenues[countKey],
      };
    case EXPENSES:
      return {
        amount: v.expenses[key],
        count: v.expenses[countKey],
      };
    default:
      return null;
  }
};

const transformData = (data = {}, monthsRange = 12, type = SUM) => {
  const object = {
    months: [],
    sums: [],
    paid: [],
    unpaid: [],
    expenseContracts: [],
    revenueContracts: [],
  };
  const getOfType = getValuesBasedOnType(type);
  const order = Array.from(Object.keys(data)).sort().slice(-monthsRange);
  const lastMonth = moment(order.slice(-1).pop(), DATE_FORMAT_MONTH_REVERSED).month();

  order.forEach((id) => {
    const v = data[id].data.attributes;
    const monthNumber = moment(id, DATE_FORMAT_MONTH_REVERSED).month();
    object.months.push(monthNames[monthNumber]);
    object.sums.push(getOfType(v, 'sum'));
    object.paid.push(getOfType(v, 'paid'));
    object.unpaid.push(getOfType(v, 'unpaid'));
    object.expenseContracts.push(getOfType(v, 'contract-expenses'));
    object.revenueContracts.push(getOfType(v, 'contract-revenues'));
  });

  fill(Array(monthsRange - order.length), {}).forEach((_obj, id) => {
    const monthNumber = getMonthNumber(lastMonth + id + 1);

    object.months.push(monthNames[monthNumber]);
    object.sums.push({});
    object.paid.push({});
    object.unpaid.push({});
    object.expenseContracts.push({});
    object.revenueContracts.push({});
  });

  return mapValues(object, (_v, k, obj) => obj[k]);
};

const Header = ({ h = '' }) => (
  <th colSpan="2">
    <I18n t={`dashboard.revenue_cost.${h}`} />
  </th>
);

Header.propTypes = {
  h: PropTypes.string,
};

const getSubheaders = (header) => [
  <th key={`${header}-0`}>
    <I18n t="dashboard.revenue_cost.amount" />
  </th>,
  <th key={`${header}-1`}>
    <I18n t="dashboard.revenue_cost.value" />
  </th>,
];

const toValueCells = ({ amount, count }) => [
  <td key={`${amount}-0`}>{isFinite(count) ? count : 0}</td>,
  <td key={`${amount}-1`}>{formatMoney(isFinite(amount) ? amount : 0)}</td>,
];

const Table = ({ data, monthsRange = 12, type = SUM }) => {
  const { months, sums, paid, unpaid, expenseContracts, revenueContracts } =
    transformData(data, monthsRange, type) || {};
  var headers = ['sum', 'sum_unpaid', 'sum_paid'];

  if (type === EXPENSES) {
    headers.push('sum_expense_contracts');
  } else if (type === REVENUES) {
    headers.push('sum_revenue_contracts');
  }

  return (
    <div className={styles.main}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <I18n t="dashboard.revenue_cost.month" />
            </th>
            {headers.map((header) => (
              <Header key={header} h={header} />
            ))}
          </tr>
          <tr>
            <td />
            {headers.map(getSubheaders)}
          </tr>
        </thead>
        <tbody>
          {data &&
            months
              .map((month, index) => (
                <tr key={`${month}-${index}`}>
                  <th>{month}</th>
                  {toValueCells(sums[index])}
                  {toValueCells(unpaid[index])}
                  {toValueCells(paid[index])}
                  {type === EXPENSES && toValueCells(expenseContracts[index])}
                  {type === REVENUES && toValueCells(revenueContracts[index])}
                </tr>
              ))
              .reverse()}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.shape({}).isRequired,
  monthsRange: PropTypes.number,
  type: PropTypes.string,
};

const mapStateToProps = (state) => ({
  data: state.monthlyOverview.revenueExpenses,
});

export default connect(mapStateToProps)(Table);
