import React, { Fragment } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import { bool, func, number, shape } from 'prop-types';

import { DATE_FORMAT, DATE_FORMAT_FULL_REVERSED } from 'constants/datetime';
import {
  PIWIK_ACTION_CANCELL_INVOICE,
  PIWIK_ACTION_CORRECT_OUTGOING_INVOICE,
  PIWIK_ACTION_EDIT_OUTGOING_INVOICE,
  PIWIK_ACTION_REMINDER,
  PIWIK_ACTION_SHOW_OUTGOING_INVOICE,
  PIWIK_CATEGORY_REVENUE,
} from 'constants/piwik';
import { InvoiceStatuses } from 'constants/statuses';
import paths from 'routes/paths';
import sharedStyles from 'shared/styles/table.module.css';
import { formatMoney, formatPercentage, t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import I18n from 'components/I18n';
import ActionCancel from 'components/IndexActionsContainer/actionIcons/ActionCancel';
import ActionCorrect from 'components/IndexActionsContainer/actionIcons/ActionCorrect';
import ActionDelete from 'components/IndexActionsContainer/actionIcons/ActionDelete';
import ActionEdit from 'components/IndexActionsContainer/actionIcons/ActionEdit';
import ActionPaymentReminder from 'components/IndexActionsContainer/actionIcons/ActionPaymentReminder';
import ActionView from 'components/IndexActionsContainer/actionIcons/ActionView';
import IndexActionsContainer from 'components/IndexActionsContainer/IndexActionsContainer';
import InvoiceColumn from 'components/InvoiceColumn/InvoiceColumn';
import InvoiceStatusBadge from 'components/Table/InvoiceStatusBadge/InvoiceStatusBadge';

import InvoiceAmount from './InvoiceAmount';

import localStyles from './SubInvoiceRow.module.css';

const styles = { ...sharedStyles, ...localStyles };

const SubInvoiceRow = ({
  invoice,
  isIncomingInvoice,
  onDelete,
  paymentRemindersEnabled,
  isPaymentReminder,
  paymentReminder,
  paymentReminderIndex,
  isEven,
}) => {
  const permittedActions = get(invoice, 'meta.actions', {});

  const reminderNumber = paymentReminderIndex || invoice.paymentRemindersCount;
  const paymentReminderSubject = `${reminderNumber}. ${t(
    'revenue.table.payment_reminder_subject'
  )}`;

  const invoiceStatus = isPaymentReminder ? InvoiceStatuses.PAYMENT_REMINDED : invoice.status;

  const dueDate = isPaymentReminder
    ? moment(paymentReminder.currentDueDate, DATE_FORMAT_FULL_REVERSED).format(DATE_FORMAT)
    : invoice.dueDate;

  const discountGrossValueWithPercentage = invoice.discountGrossValue
    ? `${formatMoney(invoice.discountGrossValue, invoice.currency)} (${formatPercentage(
        invoice.discountPercentage
      )})`
    : '-';

  return (
    <Fragment>
      <tr
        className={cx(styles.rowWrapper, {
          [styles.rowEven]: isEven,
          [styles.rowOdd]: !isEven,
        })}
      >
        {/* empty column is required for correct alignment of subinvoice */}
        <td />
        <InvoiceColumn className={styles.cell}>
          <I18n t="revenue.table.columns.status" className={styles.cellHeader} />
          <div className={styles.cellContent} data-id="Subinvoice:Status">
            <InvoiceStatusBadge status={invoiceStatus} remindersCount={reminderNumber} />
          </div>
        </InvoiceColumn>
        <InvoiceColumn className={styles.cell}>
          <I18n t="revenue.table.columns.client" className={styles.cellHeader} />
          <div className={styles.cellContent}>
            {invoice.clientDisplayName || invoice.supplierDisplayName}
          </div>
        </InvoiceColumn>
        <InvoiceColumn className={styles.cell}>
          <I18n t="revenue.table.columns.number" className={styles.cellHeader} />
          <div className={styles.cellContent} data-id="Subinvoice:Number">
            {(!isPaymentReminder && invoice.number) || '-'}
          </div>
        </InvoiceColumn>
        <InvoiceColumn className={styles.cell}>
          <I18n t="revenue.table.columns.subject" className={styles.cellHeader} />
          <div className={styles.cellContent}>
            {!isPaymentReminder ? invoice.name || invoice.subject || '-' : paymentReminderSubject}
          </div>
        </InvoiceColumn>
        <InvoiceColumn className={styles.cell} align="center">
          <I18n t="revenue.table.columns.creation_date" className={styles.cellHeader} />
          <div className={styles.cellContent}>
            {invoice.invoiceDate || (!isPaymentReminder && invoice.creationDate) || '-'}
          </div>
        </InvoiceColumn>
        <InvoiceColumn className={styles.cell} align="center">
          <I18n t="revenue.table.columns.due_date" className={styles.cellHeader} />
          <div className={styles.cellContent}>{dueDate || '-'}</div>
        </InvoiceColumn>
        <InvoiceColumn className={styles.cell} align="right">
          <I18n t="revenue.table.columns.discount_gross_value" className={styles.cellHeader} />
          <div className={styles.cellContent}>{discountGrossValueWithPercentage}</div>
        </InvoiceColumn>
        <InvoiceColumn className={styles.cell} align="right">
          <I18n t="revenue.table.columns.total_gross_amount" className={styles.cellHeader} />
          <div className={styles.cellContent}>
            <InvoiceAmount
              isIncomingInvoice={isIncomingInvoice}
              totalGrossAmount={invoice.totalGrossAmount}
              currency={invoice.currency}
              creditNote={invoice.creditNote}
            />
          </div>
        </InvoiceColumn>
        <InvoiceColumn className={styles.actions}>
          <IndexActionsContainer>
            <ActionView
              onClick={() => {
                if (isIncomingInvoice) return;
                piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_SHOW_OUTGOING_INVOICE);
              }}
              to={{
                pathname: isIncomingInvoice
                  ? paths.showIncomingInvoice(invoice.id)
                  : paths.showOutgoingInvoice(invoice.id),
                state: { paymentReminderIndex: isPaymentReminder ? reminderNumber : null },
              }}
              title={t('revenue.table.actions.show')}
              label={t('revenue.table.actions.show')}
              id={
                isIncomingInvoice ? 'incoming-invoice-show-button' : 'outgoing-invoice-show-button'
              }
              visible={permittedActions.show}
              dataId="Subinvoice:icon-show"
            />
            <ActionEdit
              title={t('revenue.table.actions.edit')}
              label={t('revenue.table.actions.edit')}
              to={
                isIncomingInvoice
                  ? paths.editIncomingInvoice(invoice.id)
                  : paths.editOutgoingInvoice(invoice.id)
              }
              id={
                isIncomingInvoice ? 'incoming-invoice-edit-button' : 'outgoing-invoice-edit-button'
              }
              onClick={() => {
                if (isIncomingInvoice) return;
                piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_EDIT_OUTGOING_INVOICE);
              }}
              visible={
                !isPaymentReminder &&
                (permittedActions.edit || permittedActions['edit-payment-information'])
              }
            />
            <ActionPaymentReminder
              title={t('revenue.table.actions.create_reminder')}
              id="create-invoice-reminder-button"
              to={isIncomingInvoice ? '/' : paths.createPaymentReminder(invoice.id)}
              onClick={() => {
                if (isIncomingInvoice) return;
                piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_REMINDER);
              }}
              label={t('revenue.table.actions.create_reminder')}
              visible={
                !isPaymentReminder &&
                permittedActions['create-payment-reminder'] &&
                paymentRemindersEnabled
              }
            />
            <ActionCancel
              to={
                isIncomingInvoice
                  ? paths.cancelIncomingInvoice(invoice.id)
                  : paths.cancelOutgoingInvoice(invoice.id)
              }
              id={
                isIncomingInvoice
                  ? 'incoming-invoice-cancel-button'
                  : 'outgoing-invoice-cancel-button'
              }
              title={t('revenue.table.actions.cancel')}
              onClick={() => {
                if (isIncomingInvoice) return;
                piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_CANCELL_INVOICE);
              }}
              label={t('revenue.table.actions.cancel')}
              visible={!isPaymentReminder && permittedActions.cancel}
            />
            <ActionCorrect
              to={paths.correctOutgoingInvoice(invoice.id)}
              title={t('revenue.table.actions.correct')}
              id="outgoing-invoice-correct-button"
              onClick={() => {
                if (isIncomingInvoice) return;
                piwikHelpers.trackEvent(
                  PIWIK_CATEGORY_REVENUE,
                  PIWIK_ACTION_CORRECT_OUTGOING_INVOICE
                );
              }}
              label={t('revenue.table.actions.correct')}
              visible={!isPaymentReminder && permittedActions.correct}
            />
            <ActionDelete
              title={t('revenue.table.actions.delete')}
              label={t('revenue.table.actions.delete')}
              id={
                isIncomingInvoice
                  ? 'incoming-invoice-delete-button'
                  : 'outgoing-invoice-delete-button'
              }
              onClick={(event) => {
                event.preventDefault();
                onDelete(invoice);
              }}
              visible={!isPaymentReminder && permittedActions.delete}
            />
          </IndexActionsContainer>
        </InvoiceColumn>
      </tr>
    </Fragment>
  );
};

SubInvoiceRow.propTypes = {
  isIncomingInvoice: bool,
  paymentReminderIndex: number,
  isPaymentReminder: bool,
  invoice: shape({
    meta: shape({
      actions: shape({
        cancel: bool,
        correct: bool,
        delete: bool,
        edit: bool,
        editPaymentInformation: bool,
        show: bool,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  paymentReminder: shape({}),
  onDelete: func,
  paymentRemindersEnabled: bool,
  isEven: bool.isRequired,
};

export default SubInvoiceRow;
