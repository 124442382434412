import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { format } from 'date-fns';
import normalize from 'json-api-normalizer';
import { isEmpty, omit } from 'lodash';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import build from 'redux-object';

import { fetchBankAccounts } from 'actions/bank-account';
import { clearTransientId } from 'actions/contracts';
import { showNotification } from 'actions/notification';
import { CrudMode, EDIT_MODE, NEW_MODE, SHOW_MODE } from 'constants/common/crud';
import EntityPath from 'constants/entitiesPaths';
import { MODAL_HEADER_VARIANT } from 'constants/modal';
import { createContractSuccess } from 'notifications/contracts';
import { updateContract } from 'reducers/contracts';
import paths from 'routes/paths';
import { getContractDetails } from 'selectors/contracts';
import { getIsFromVrso } from 'selectors/profile';
import { t } from 'shared/utils';
import { CategoryResponse } from 'types/entities/Category';
import type { ExpenseContract, RevenueContract } from 'types/entities/Contract';
import { ContractSource, ContractTemplates } from 'types/entities/Contract';
import { ExpenseContractType } from 'types/entities/Contract';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'components/LineItems';
import { ConfirmationModal } from 'components/Modal';
import { CATEGORY_CONTRACTS, FORM_NAME } from 'features/contracts/constants';
import type { FormData } from 'features/contracts/types';
import { prepareNewContractData } from 'features/contracts/utils';

import FinishContractModal from '../FinishContractModal/FinishContractModal';
import Fields from './Fields/Fields';
import UploadFile from './Fields/UploadFile';
import InfoBanner from './InfoBanner/InfoBanner';
import ProgressBar from './ProgressBar/ProgressBar';
import WelcomeTile from './WelcomeTile/WelcomeTile';

import styles from './Form.module.css';

export type ContractsCreateFormOwnProps = {
  initialValues: any;
  crudMode: CrudMode;
  onCancel?: () => void;
  onSuccess?: (contract: ExpenseContract | RevenueContract) => void;
  isRevenue: boolean;
  contractType: 'basic' | 'loan';
  saveLabel?: string;

  selectedCategory: CategoryResponse;

  categoryOptions: CategoryResponse[];
};

const Form = ({
  handleSubmit,
  crudMode,
  initialValues,
  onCancel,
  onSuccess,
  isRevenue,
  contractType,
  saveLabel,
  selectedCategory,
  categoryOptions,
  reset,
}: ContractsCreateFormOwnProps & InjectedFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [hasBankAccounts, setHasBankAccounts] = useState<boolean | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const category = isRevenue ? 'revenues' : 'expenses';
  const activeTemplate = `${category}_${contractType}`;
  const contract = useSelector(getContractDetails)!;
  const contractSource = contract?.source;
  const isVrsoUser = useSelector(getIsFromVrso);
  const isVrsoContract = contractSource === ContractSource.Vrso;
  const hasVat = activeTemplate !== ContractTemplates.ExpensesLoan;
  const isLoan = ExpenseContractType.loan === contractType;
  const submit: any = async (data: FormData) => {
    const clientId = data.client?.id;
    const supplierId = data.supplier?.id;
    const invoiceLineCategoryId =
      typeof data.invoiceLineCategoryId === 'object'
        ? data.invoiceLineCategoryId?.id
        : data.invoiceLineCategoryId;
    const taxRateId = data.taxRateId;
    const today = format(new Date(), 'dd-MM-yyyy');
    const updatedData = { ...data, transactionEndDate: today };
    const ommitedUnnecessaryData = omit(showModal ? updatedData : data, [
      'recurringTransactionInterval',
      'upcomingInstallment',
      'client',
      'supplier',
      'invoiceLineCategory',
    ]);
    const preparedData = {
      ...prepareNewContractData(ommitedUnnecessaryData),
      is_revenue: isRevenue,
      client_id: clientId,
      supplier_id: supplierId,
      invoice_line_category_id: invoiceLineCategoryId,
      tax_rate_id: taxRateId,
    };
    const response = await dispatch(
      (updateContract as any)(contract.id, preparedData, isRevenue, hasVat)
    );

    dispatch(showNotification(createContractSuccess));
    clearTransientId();

    if (onSuccess) {
      onSuccess(
        build<ExpenseContract | RevenueContract>(
          normalize(response),
          EntityPath.Contracts,
          contract.id,
          { ignoreLinks: true }
        )
      );
    } else {
      history.push(paths.contracts);
    }
  };

  const topFields = useMemo(
    () => (
      <div className={cx(styles.creator, styles.subsectionItem)}>
        <Fields
          crudMode={crudMode}
          contractSource={contractSource}
          activeTemplate={activeTemplate}
          isRevenue={isRevenue}
          isLoan={isLoan}
          selectedCategory={selectedCategory}
          categoryOptions={categoryOptions}
          initialValues={initialValues}
        />
      </div>
    ),
    [crudMode, contractSource, activeTemplate, isRevenue, isLoan, selectedCategory, categoryOptions]
  );

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
      return;
    }

    history.push(paths.contracts);
  };

  const handleEdit = () => history.push(paths.editContract(category, contractType, contract.id));

  const handleViewContract = () => {
    setShowCancellationModal(true);
  };

  useEffect(() => {
    const fetch = async () => {
      const response: any = await dispatch(fetchBankAccounts({ omitReducers: true }));

      setHasBankAccounts(response.data.length > 0);
    };

    fetch();
  }, [dispatch]);

  if (crudMode === EDIT_MODE && contractSource === ContractSource.Vrso) {
    history.push(paths.home);
    return null;
  }

  const trackUpload = {
    category: CATEGORY_CONTRACTS,
    name: 'Contract_attempt',
  };

  const trackDownload = {
    category: CATEGORY_CONTRACTS,
    name: isVrsoUser ? 'Download_Contract_VRSO' : 'Download_Contract_expense',
  };

  const trackingEvents = {
    trackUpload,
    trackDownload,
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className={cx(styles.section)}>
        {crudMode === SHOW_MODE && isVrsoContract && <WelcomeTile />}
        <div className={styles.subsection}>
          <div className={cx(styles.upload, styles.subsectionItem)}>
            {/*
              // @ts-ignore: TODO: refator to follow our conventions */}
            <UploadFile trackingEvents={trackingEvents} readonly={crudMode === SHOW_MODE} />
          </div>
          {topFields}
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.infoBanner}>
          {hasBankAccounts !== null && !hasBankAccounts && <InfoBanner />}
        </div>
        <div className={styles.buttons}>
          <ActionPanel
            className={cx(styles.creatorActionsSection, IGNORE_OUTSIDE_CLICK_CLASS)}
            wrapped
          >
            <ConfirmationModal
              headerVariant={MODAL_HEADER_VARIANT.SMALL}
              dangerousAction
              isOpen={showCancellationModal}
              onClose={() => setShowCancellationModal(false)}
              onConfirm={() => {
                reset();
                setShowCancellationModal(false);
                history.push(paths.viewContract(category, contractType, contract.id));
              }}
              closeLabel={t('transition_prevent_modal.cancel')}
              confirmLabel={t('transition_prevent_modal.confirm')}
              header={t('transition_prevent_modal.header')}
              hasWarningIcon
            >
              {t('transition_prevent_modal.content')}
            </ConfirmationModal>

            {showModal && (
              <FinishContractModal
                onSubmit={handleSubmit(submit)}
                onClose={() => setShowModal(false)}
              />
            )}
            {!isVrsoContract && crudMode === SHOW_MODE && (
              <ActionButton
                type="submit"
                dataId="Contracts:button-submit"
                onClick={handleEdit}
                label={t('contracts.edit')}
              />
            )}
            {[EDIT_MODE, NEW_MODE].includes(crudMode) && (
              <ActionButton
                type="submit"
                dataId="Contracts:button-submit"
                label={saveLabel || t('contracts.save')}
              />
            )}
            {[SHOW_MODE, NEW_MODE].includes(crudMode) && (
              <ActionButton
                appearance="outlined"
                type="button"
                dataId="Contracts:button-cancel"
                onClick={handleCancel}
                label={t('form.cancel')}
              />
            )}
            {crudMode === EDIT_MODE && (
              <ActionButton
                appearance="outlined"
                dataId="Contracts:button-cancel"
                onClick={handleViewContract}
                label={t('form.cancel')}
              />
            )}
            {crudMode === EDIT_MODE && !isLoan && (
              <ActionButton
                appearance="outlined"
                dataId="Contracts:button-finish-contract"
                onClick={() => setShowModal(true)}
                label={t('contracts.cancellation.finish_contract')}
              />
            )}
          </ActionPanel>
        </div>
      </div>
      {isVrsoContract && !isEmpty(initialValues.vrsoInstallments) && (
        <div className={styles.progressBar}>
          <ProgressBar installments={initialValues.vrsoInstallments} />
        </div>
      )}
    </form>
  );
};

export default compose<FC<ContractsCreateFormOwnProps>>(
  reduxForm({
    form: FORM_NAME,
  })
)(Form);
