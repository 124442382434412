import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  cursor: pointer;
  color: ${({ theme }) => theme.legacy.colors.brandBlue};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  background: ${({ theme }) => theme.legacy.colors.white};
  border-bottom: 1px solid #eee;
`;
