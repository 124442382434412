export const NAMESPACE = 'DELIVERY_NOTES';

export const CREATE_REQUEST = 'DELIVERY_NOTES/CREATE_REQUEST';
export const CREATE_SUCCESS = 'DELIVERY_NOTES/CREATE_SUCCESS';
export const CREATE_FAILURE = 'DELIVERY_NOTES/CREATE_FAILURE';

export const UPDATE_REQUEST = 'DELIVERY_NOTES/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'DELIVERY_NOTES/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'DELIVERY_NOTES/UPDATE_FAILURE';

export const TOGGLE_GROSS_NET_REQUEST = 'DELIVERY_NOTES/TOGGLE_GROSS_NET_REQUEST';
export const TOGGLE_GROSS_NET_SUCCESS = 'DELIVERY_NOTES/TOGGLE_GROSS_NET_SUCCESS';
export const TOGGLE_GROSS_NET_FAILURE = 'DELIVERY_NOTES/TOGGLE_GROSS_NET_FAILURE';

export const REMINDER_UPDATE_REQUEST = 'DELIVERY_NOTES_REMINDER/UPDATE_REQUEST';
export const REMINDER_UPDATE_SUCCESS = 'DELIVERY_NOTES_REMINDER/UPDATE_SUCCESS';
export const REMINDER_UPDATE_FAILURE = 'DELIVERY_NOTES_REMINDER/UPDATE_FAILURE';

export const DELETE_REQUEST = 'DELIVERY_NOTES/DELETE_REQUEST';
export const DELETE_SUCCESS = 'DELIVERY_NOTES/DELETE_SUCCESS';
export const DELETE_FAILURE = 'DELIVERY_NOTES/DELETE_FAILURE';

export const FETCH_REQUEST = 'DELIVERY_NOTES/FETCH_REQUEST';
export const FETCH_SUCCESS = 'DELIVERY_NOTES/FETCH_SUCCESS';
export const FETCH_FAILURE = 'DELIVERY_NOTES/FETCH_FAILURE';

export const INDEX_REQUEST = 'DELIVERY_NOTES/INDEX_REQUEST';
export const INDEX_SUCCESS = 'DELIVERY_NOTES/INDEX_SUCCESS';
export const INDEX_FAILURE = 'DELIVERY_NOTES/INDEX_FAILURE';
export const INDEX_RESET = 'DELIVERY_NOTES/INDEX_RESET';

export const INDEX_MORE_REQUEST = 'DELIVERY_NOTES/INDEX_MORE_REQUEST';
export const INDEX_MORE_SUCCESS = 'DELIVERY_NOTES/INDEX_MORE_SUCCESS';
export const INDEX_MORE_FAILURE = 'DELIVERY_NOTES/INDEX_MORE_FAILURE';

export const INDEX_SORT = `${NAMESPACE}/INDEX_SORT`;

export const SEARCH_REQUEST = 'DELIVERY_NOTES/SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'DELIVERY_NOTES/SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'DELIVERY_NOTES/SEARCH_FAILURE';

export const SEND_REQUEST = 'DELIVERY_NOTES/SEND_REQUEST';
export const SEND_SUCCESS = 'DELIVERY_NOTES/SEND_SUCCESS';
export const SEND_FAILURE = 'DELIVERY_NOTES/SEND_FAILURE';

export const DOWNLOAD_REQUEST = 'DELIVERY_NOTES/DOWNLOAD_REQUEST';
export const DOWNLOAD_SUCCESS = 'DELIVERY_NOTES/DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'DELIVERY_NOTES/DOWNLOAD_FAILURE';

export const SORT_SUCCESS = 'DELIVERY_NOTES/SORT_SUCCESS';

export const FETCH_SUMS_REQUEST = 'DELIVERY_NOTES/FETCH_SUMS_REQUEST';
export const FETCH_SUMS_SUCCESS = 'DELIVERY_NOTES/FETCH_SUMS_SUCCESS';
export const FETCH_SUMS_FAILURE = 'DELIVERY_NOTES/FETCH_SUMS_FAILURE';

export const REORDER_LINE_ITEMS_LOCAL = 'DELIVERY_NOTES/REORDER_LINE_ITEMS_LOCAL';
export const REORDER_LINE_ITEMS_REQUEST = 'DELIVERY_NOTES/REORDER_LINE_ITEMS_REQUEST';
export const REORDER_LINE_ITEMS_SUCCESS = 'DELIVERY_NOTES/REORDER_LINE_ITEMS_SUCCEESS';
export const REORDER_LINE_ITEMS_FAILURE = 'DELIVERY_NOTES/REORDER_LINE_ITEMS_FAILURE';

export const FETCH_LINE_CATEGORIES_REQUEST = 'DELIVERY_NOTE_CATEGORIES/FETCH_REQUEST';
export const FETCH_LINE_CATEGORIES_SUCCESS = 'DELIVERY_NOTE_CATEGORIES/FETCH_SUCCESS';
export const FETCH_LINE_CATEGORIES_FAILURE = 'DELIVERY_NOTE_CATEGORIES/FETCH_FAILURE';

export const TOGGLE_REQUEST = 'DELIVERY_NOTE_CATEGORIES/TOGGLE_PAID_BY_CASH_REQUEST';
export const TOGGLE_SUCCESS = 'DELIVERY_NOTE_CATEGORIES/TOGGLE_PAID_BY_CASH_SUCCESS';
export const TOGGLE_FAILURE = 'DELIVERY_NOTE_CATEGORIES/TOGGLE_PAID_BY_CASH_FAILURE';

export const DELETE_MAYBE_SUGGESTION = 'DELIVERY_NOTES/DELETE_MAYBE_SUGGESTION';

export const DRAFT_ID = 'DELIVERY_NOTES/CURRENT_DRAFT_ID';

export const MIN_NET_AMOUNT = -20000000;
export const MAX_NET_AMOUNT = +19999999;

export const DESTROY_FORM = 'DESTROY_FORM';

export const PAGINATION = 'DELIVERY_NOTES/PAGINATION';

export const FORM_NAME = 'deliveryNoteCreator';

export const FROM_DELIVERY_NOTE_QUERY_PARAM = 'from-delivery-note';
