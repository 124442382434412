import styled from 'styled-components';

import { Breakpoints } from 'redesign/styles/breakpoints';

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.space['5']};
`;

export const Heading = styled.h2`
  font-size: ${({ theme }) => theme.fontSize['base']};
  letter-spacing: 0.25px;
  line-height: ${({ theme }) => theme.space['5']};
  font-weight: bold;
  padding: ${({ theme }) => `${theme.space['4']} ${theme.space['2']}`};

  @media (min-width: ${Breakpoints.md}) {
    padding: ${({ theme }) => theme.space['4']} 0;
    font-size: ${({ theme }) => theme.fontSize['2xl']};
    letter-spacing: 0.5px;
    line-height: ${({ theme }) => theme.space['6']};
  }
`;
