import React, { SVGAttributes } from 'react';

const IconLock = (props: SVGAttributes<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18" {...props}>
    <path
      d="M12.8 7H11V4.6C11 1.703 9.665 0 7 0 4.334 0 3 1.703 3 4.6V7H1c-.553 0-1 .646-1 1.199V16c0 .549.428 1.139.951 1.307l1.197.387c.635.185 1.29.288 1.952.306h5.8a7.68 7.68 0 001.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V8.199C14 7.646 13.352 7 12.8 7zM9 7H5V4.199C5 2.754 5.797 2 7 2c1.203 0 2 .754 2 2.199V7z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default IconLock;
