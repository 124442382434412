import honorifics from 'constants/honorifics';
import { t } from 'shared/utils';
import { Client } from 'types/entities/Client';

const [SIR, MRS, MARRIED_COUPLE, FAMILY] = honorifics;

/**
 * If client default salutation is set (defaultInvoiceSalutationDefined flag) then return default value.
 * If not, check if client has honorific and last name. If they are present then return translation.
 * If not, return customer default.
 *
 * @param defaultValues - default values from the reducer
 * @param client - currently selected client
 */
export default function getSalutationHonorific(
  definedKey:
    | 'defaultInvoiceSalutationDefined'
    | 'defaultProposalSalutationDefined'
    | 'defaultPaymentReminderSalutationDefined'
    | 'defaultOrderConfirmationSalutationDefined'
    | 'defaultDeliveryNoteSalutationDefined',
  defaultValues: {
    salutation: string;
  },
  client: Client
): string {
  if (!client) return defaultValues.salutation;

  const { lastName, honorific } = client;

  if (client[definedKey]) return defaultValues.salutation;

  if (lastName && honorific) {
    switch (honorific) {
      case SIR:
        return t('revenue.form.individual_contact.polite_return.sir', {
          lastName,
        });
      case MRS:
        return t('revenue.form.individual_contact.polite_return.mrs', {
          lastName,
        });
      case FAMILY:
        return t('revenue.form.individual_contact.polite_return.family', {
          lastName,
        });
      case MARRIED_COUPLE:
        return t('revenue.form.individual_contact.polite_return.married_couple', {
          lastName,
        });
      default:
        return t('revenue.form.individual_contact.polite_return.company');
    }
  }

  return defaultValues.salutation;
}
