import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { push } from 'connected-react-router';
import { isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { branch, compose, lifecycle, renderComponent, withState } from 'recompose';
import { reduxForm } from 'redux-form';

import {
  createProductCatalogGroup,
  fetchProductCatalogGroup,
  updateProductCatalogGroup,
} from 'actions/product-catalog/groups/groups';
import { clickCancelProductCatalogGroup as clickCancelProductCatalogGroupAction } from 'actions/product-catalog/groups/piwik';
import { FORM_NAME, PRODUCT_CATALOG_GROUPS } from 'constants/product-catalog/groups';
import { makeGetEntity } from 'reducers/common/resource';
import paths from 'routes/paths';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import componentsStyles from 'shared/styles/components.module.css';
import { t } from 'shared/utils';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import Card from 'components/Card';
import { TextField } from 'components/Form';
import FormField from 'components/Form/FormField';
import I18n from 'components/I18n';
import { LineItemsAutoSaveProvider } from 'components/LineItems';
import Loading from 'components/Loading';

import GroupDetails from './GroupDetails';

import localStyles from '../../../ProductCatalog.module.css';
import componentStyles from './Creator.module.css';

const styles = { ...componentsStyles, ...localStyles, ...componentStyles };

const Creator = ({
  id = null,
  updateForm,
  handleSubmit,
  submitting,
  readonly = false,
  pushState,
  productCatalogItems = [],
  change,
  initialValues,
  submitFailed,
  creatorMode,
  clickCancelProductCatalogGroup,
}) => {
  const resetField = (name) => change(name, initialValues[name]);
  const areLineItemsEmpty = submitFailed && isEmpty(productCatalogItems);
  return (
    <form
      className={cx(styles.form, styles.groupForm)}
      onSubmit={handleSubmit((data) => updateForm({ id, data, creatorMode }))}
    >
      <LineItemsAutoSaveProvider>
        <div className={styles.section}>
          <Card>
            <Card.Header>
              <I18n t="product_catalog.product_catalog_group.header" />
            </Card.Header>
            <Card.Body>
              <div className={cx(styles.columns, styles.columnsPadded)}>
                <div className={styles.column}>
                  <FormField name="name" required component={TextField} readonly={readonly} />
                </div>
              </div>
            </Card.Body>
          </Card>

          <GroupDetails
            resetField={resetField}
            readonly={readonly}
            productCatalogItems={productCatalogItems}
            groupId={id}
            areLineItemsEmpty={areLineItemsEmpty}
          />
        </div>
        <ActionPanel className={styles.creatorActionsSection}>
          <ActionButton
            type="submit"
            disabled={submitting}
            visible={!readonly}
            label={t(`product_catalog.product_catalog_group.submit_${id ? 'edit' : 'new'}`)}
          />
          <ActionButton
            disabled={submitting}
            visible={readonly}
            onClick={() => pushState(paths.editProductCatalogGroup(id))}
            label={t('product_catalog.product_catalog_group.edit')}
          />
          <ActionButton
            appearance="outlined"
            label={t('form.cancel')}
            Component={Link}
            onClick={() => clickCancelProductCatalogGroup()}
            to={paths.productCatalogGroups}
          />
        </ActionPanel>
      </LineItemsAutoSaveProvider>
    </form>
  );
};

Creator.propTypes = {
  id: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  readonly: PropTypes.bool,
  submitFailed: PropTypes.bool,
  pushState: PropTypes.func,
  productCatalogItems: PropTypes.arrayOf(PropTypes.shape({})),
  initialValues: PropTypes.shape({}),
  change: PropTypes.func,
  creatorMode: PropTypes.string,
  clickCancelProductCatalogGroup: PropTypes.func.isRequired,
};

const reduxFormEnhancer = reduxForm({
  form: FORM_NAME,
  enableReinitialize: false,
  keepDirtyOnReinitialize: true,
  persistentSubmitErrors: true,
});

const makeMapStateToProps = () => {
  const getEntity = makeGetEntity();

  return (state, { id }) => {
    const productCatalogGroup = id
      ? getEntity(id)(
          state.productCatalogGroups[PRODUCT_CATALOG_GROUPS],
          PRODUCT_CATALOG_GROUPS,
          id
        )
      : null;
    const { productCatalogItems = [], productCatalogItemsGroups = [] } = productCatalogGroup || {};

    const productCatalogItemsWithQuantity = isArray(productCatalogItems)
      ? productCatalogItems.map((item) => {
          const { quantity, id: productCatalogItemsGroupId } =
            productCatalogItemsGroups.find(
              (itemGroup) => String(itemGroup.productCatalogItemId) === item.id
            ) || {};
          return {
            ...item,
            productCatalogItemsGroupId,
            quantity,
          };
        })
      : [];

    return {
      id,
      initialValues: {
        quantity: 1,
        ...productCatalogGroup,
        /* redux-form is a mean beast and dispatching change(field, value)
         ** with value as null does not include the payload...
         ** so we have to return something falsy here. Bool made the most sense */
        selectItem: false,
      },
      productCatalogItems: productCatalogItemsWithQuantity,
    };
  };
};

const mapDispatchToProps = {
  createTransientProductCatalogGroup: createProductCatalogGroup,
  updateForm: updateProductCatalogGroup,
  fetchForm: fetchProductCatalogGroup,
  pushState: push,
  clickCancelProductCatalogGroup: clickCancelProductCatalogGroupAction,
};

const reduxActionsEnhancer = connect(null, mapDispatchToProps);
const reduxStateEnhancer = connect(makeMapStateToProps);

const lifecycleEnhancer = lifecycle({
  async componentDidMount() {
    const { fetchForm, id, setId, setHasLoadedInitialData, createTransientProductCatalogGroup } =
      this.props;
    if (id) {
      await fetchForm(id);
      setHasLoadedInitialData(true);
    } else {
      const response = await createTransientProductCatalogGroup();
      setId(response.data.id);
      setHasLoadedInitialData(true);
    }
  },
});

const enhance = compose(
  reduxActionsEnhancer,
  withState('hasLoadedInitialData', 'setHasLoadedInitialData', false),
  withState('id', 'setId', ({ id }) => id || null),
  lifecycleEnhancer,
  reduxStateEnhancer,
  reduxFormEnhancer,
  branch(({ hasLoadedInitialData }) => !hasLoadedInitialData, renderComponent(Loading)),
  withTransitionPrevent()
);

export default enhance(Creator);
