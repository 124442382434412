export const NAMESPACE = 'BANK_TRANSFER';
export const FETCH_REQUEST = `${NAMESPACE}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${NAMESPACE}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${NAMESPACE}/FETCH_FAILURE`;

export const UPDATE_REQUEST = `${NAMESPACE}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${NAMESPACE}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${NAMESPACE}/UPDATE_FAILURE`;

export const INDEX_SORT = `${NAMESPACE}/INDEX_SORT`;

export const FETCH_ALL_TRANSFERS_REQUEST = `${NAMESPACE}/FETCH_ALL_TRANSFERS_REQUEST`;
export const FETCH_ALL_TRANSFERS_SUCCESS = `${NAMESPACE}/FETCH_ALL_TRANSFERS_SUCCESS`;
export const FETCH_ALL_TRANSFERS_FAILURE = `${NAMESPACE}/FETCH_ALL_TRANSFERS_FAILURE`;
