import React, { Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { resetCustomerDefaults, updateCustomerDefaults } from 'actions/default-values';
import { EmailsFormName } from 'containers/Profile/InvoiceTemplate/DefaultValues/TabEmailsDefaults/TabEmailsDefaults';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { RootState } from 'store';
import { DefaultValues } from 'types/entities/DefaultValues';
import { InfoTextField, TextareaField } from 'components/Form';
import InputsGroup from 'components/InputsGroup/InputsGroup';

import { DefaultValuesForm, FormFooterContainer, FormRow } from '../../sharedComponents';

import styles from './InvoiceEmailsDefaults.module.css';

export const FORM_NAME: EmailsFormName = 'InvoiceEmailsDefaults';

type FormData = Pick<
  DefaultValues,
  | 'emailInvoiceSubject'
  | 'emailInvoiceSalutation'
  | 'emailInvoiceSalutationContent'
  | 'emailInvoiceSignature'
>;

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    emailInvoiceSubject: state.defaultValues.values.emailInvoiceSubject,
    emailInvoiceSalutation: state.defaultValues.values.emailInvoiceSalutation,
    emailInvoiceSalutationContent: state.defaultValues.values.emailInvoiceSalutationContent,
    emailInvoiceSignature: state.defaultValues.values.emailInvoiceSignature,
  },
});

const mapDispatchToProps = {
  updateCustomerDefaults,
  resetCustomerDefaults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type InvoiceEmailsDefaultsProps = ConnectedProps<typeof connector>;

const InvoiceEmailsDefaults = ({
  resetCustomerDefaults,
  updateCustomerDefaults,
  handleSubmit,
  reset: resetForm,
}: InvoiceEmailsDefaultsProps & InjectedFormProps<FormData, InvoiceEmailsDefaultsProps>) => {
  return (
    <DefaultValuesForm
      onSubmit={handleSubmit((values) => updateCustomerDefaults(values, 'email_invoice'))}
      onReset={async () => {
        await resetCustomerDefaults('email_invoice');
        resetForm();
      }}
      sectionName="email_invoice"
      sectionLabel={t('invoice_templates.emails_defaults.invoice_defaults_section')}
      dataIdPrefix="DocumentDefaults:"
    >
      <Fragment>
        <FormRow>
          <InputsGroup label={t('invoice_templates.emails_defaults.subject')}>
            <Field
              disabled
              className={styles.grouppedInput}
              name="emailInvoiceSubject"
              checker={serverValidationChecker}
              component={InfoTextField}
              message={t('invoice_templates.emails_defaults.subject_info')}
              dataId="EmailsDefaults:input-subject"
              isDisabledWithUnderline
            />
          </InputsGroup>
        </FormRow>
        <FormRow>
          <InputsGroup label={t('invoice_templates.emails_defaults.header_message')}>
            <Field
              name="emailInvoiceSalutation"
              component={InfoTextField}
              checker={serverValidationChecker}
              label={t('invoice_templates.emails_defaults.salutation')}
              message={t('invoice_templates.emails_defaults.salutation_info')}
              dataId="EmailsDefaults:input-salutation"
            />
            <Field
              name="emailInvoiceSalutationContent"
              component={TextareaField}
              checker={serverValidationChecker}
              label={t('invoice_templates.emails_defaults.salutation_content')}
              fullHeight
              dataId="EmailsDefaults:input-salutation-content"
            />
          </InputsGroup>
        </FormRow>
        <FormRow>
          <FormFooterContainer>
            <InputsGroup label={t('invoice_templates.emails_defaults.signature')}>
              <Field
                disabled
                className={styles.grouppedInput}
                name="emailInvoiceSignature"
                checker={serverValidationChecker}
                component={InfoTextField}
                message={t('invoice_templates.emails_defaults.signature_info')}
                dataId="EmailsDefaults:input-signature"
                isDisabledWithUnderline
              />
            </InputsGroup>
          </FormFooterContainer>
        </FormRow>
      </Fragment>
    </DefaultValuesForm>
  );
};

const FormEnhancedComponent = reduxForm<FormData, InvoiceEmailsDefaultsProps>({
  form: FORM_NAME,
  enableReinitialize: true,
  persistentSubmitErrors: true,
})(InvoiceEmailsDefaults);

export default connector(FormEnhancedComponent);
