import React, { useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { flow } from 'lodash';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { resetClientDefaults, updateClientDefaults } from 'actions/default-values';
import { getOrderConfirmation, GetOrderConfirmationData } from 'api/me/previews/orderConfirmation';
import { ClientDocumentsFormName } from 'containers/Clients/ClientDocumentDefaults/ClientDocumentDefaults';
import { FormRow } from 'containers/Profile/InvoiceTemplate/DefaultValues/sharedComponents';
import DefaultValuesFormWithPreview from 'containers/Profile/InvoiceTemplate/DefaultValues/sharedComponents/DefaultValuesForm/DefaultValuesFormWithPreview';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { RootState } from 'store';
import { DefaultValues } from 'types/entities/DefaultValues';
import { TextField } from 'components/Form';
import InputsGroup from 'components/InputsGroup/InputsGroup';
import HtmlField from 'components/v2/Form/HtmlField/HtmlField';

import styles from './OrderConfirmationDefaults.module.css';

export const FORM_NAME: ClientDocumentsFormName = 'clientOrderConfirmationDefaults';

type FormData = Pick<
  DefaultValues,
  | 'orderConfirmationSubject'
  | 'orderConfirmationSalutation'
  | 'orderConfirmationSalutationContent'
  | 'orderConfirmationFooter'
  | 'orderConfirmationDeliveryInfo'
>;

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    orderConfirmationSubject: state.defaultValues.values.orderConfirmationSubject,
    orderConfirmationSalutation: state.defaultValues.values.orderConfirmationSalutation,
    orderConfirmationSalutationContent:
      state.defaultValues.values.orderConfirmationSalutationContent,
    orderConfirmationFooter: state.defaultValues.values.orderConfirmationFooter,
    orderConfirmationDeliveryInfo: state.defaultValues.values.orderConfirmationDeliveryInfo,
  },
  formValues: {
    orderConfirmationSalutation: selector(state, 'orderConfirmationSalutation'),
    orderConfirmationSalutationContent: selector(state, 'orderConfirmationSalutationContent'),
    orderConfirmationFooter: selector(state, 'orderConfirmationFooter'),
  },
});

const mapDispatchToProps = {
  updateClientDefaults,
  resetClientDefaults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OrderConfirmationDefaultsProps = ConnectedProps<typeof connector> & {
  clientId: string;
};

const OrderConfirmationDefaults = ({
  resetClientDefaults,
  updateClientDefaults,
  handleSubmit,
  clientId,
  reset: resetForm,
  formValues: {
    orderConfirmationSalutation,
    orderConfirmationSalutationContent,
    orderConfirmationFooter,
  },
}: OrderConfirmationDefaultsProps &
  InjectedFormProps<FormData, OrderConfirmationDefaultsProps>) => {
  const topFields = useMemo(
    () => (
      <InputsGroup label={t('invoice_templates.documents_defaults.discount_subject')}>
        <Field
          className={styles.grouppedInput}
          name="orderConfirmationSubject"
          checker={serverValidationChecker}
          component={TextField}
          dataId="ClientDocumentDefaults:input-order-confirmation-subject"
        />
      </InputsGroup>
    ),
    []
  );

  const values = useMemo(
    () => ({
      salutation_honorific: orderConfirmationSalutation,
      salutation_content: orderConfirmationSalutationContent,
      personal_notes: orderConfirmationFooter,
    }),
    [orderConfirmationSalutation, orderConfirmationSalutationContent, orderConfirmationFooter]
  );

  return (
    <DefaultValuesFormWithPreview<GetOrderConfirmationData>
      onSubmit={handleSubmit((values) =>
        updateClientDefaults(clientId, values, 'form_order_confirmation')
      )}
      onReset={async () => {
        await resetClientDefaults(clientId, 'form_order_confirmation');
        resetForm();
      }}
      sectionName="form_order_confirmation"
      sectionLabel={t('invoice_templates.documents_defaults.order_confirmation_defaults_section')}
      dataIdPrefix="ClientDocumentDefaults:"
      fetchPreview={getOrderConfirmation}
      values={values}
      topFields={topFields}
    >
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.header')}>
          <Field
            name="orderConfirmationSalutation"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation')}
            tooltip={t('invoice_templates.documents_defaults.salutation_info')}
            dataId="ClientDocumentDefaults:input-order-confirmation-salutation"
          />
          <br />
          <Field
            name="orderConfirmationSalutationContent"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation_content')}
            dataId="ClientDocumentDefaults:input-order-confirmation-salutation-content"
          />
          <br />
          <Field
            name="orderConfirmationDeliveryInfo"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.delivery_info')}
            dataId="DocumentDefaults:input-order-confirmation-delivery-info"
          />
        </InputsGroup>
      </FormRow>
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.footer')}>
          <Field
            name="orderConfirmationFooter"
            component={HtmlField}
            checker={serverValidationChecker}
            dataId="ClientDocumentDefaults:input-order-confirmation-footer"
          />
        </InputsGroup>
      </FormRow>
    </DefaultValuesFormWithPreview>
  );
};

export default flow(
  withTransitionPrevent(),
  reduxForm<FormData, OrderConfirmationDefaultsProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    persistentSubmitErrors: true,
  }),
  connector
)(OrderConfirmationDefaults);
