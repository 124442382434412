import React, { memo, SVGProps } from 'react';

const Calendar = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.167 3.833H19v2.334h-3.5V3.833h-7v2.334H5V3.833H3.833A2.34 2.34 0 001.5 6.167v14A2.34 2.34 0 003.833 22.5h16.334a2.34 2.34 0 002.333-2.333v-14a2.34 2.34 0 00-2.333-2.334zm0 16.334H3.833v-9.334h16.334v9.334zM7.917 1.5H5.583v4.083h2.334V1.5zm10.5 0h-2.334v4.083h2.334V1.5z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default memo(Calendar);
