import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import kebabCase from 'lodash/kebabCase';
import { func, string } from 'prop-types';

import {
  getPaginationPreference as getPaginationPreferenceAction,
  setPaginationPreference as setPaginationPreferenceAction,
} from 'actions/pagination-preference';
import { PER_PAGE_VALUES } from 'constants/pagination';
import { t } from 'shared/utils';

import { UnderlinedButton } from '../UnderlinedButton/UnderlinedButton';

import styles from './ResourcesPerPage.module.css';

class RawResourcesPerPage extends Component {
  state = { currentPerPage: null };

  componentDidMount() {
    const { resource, getPaginationPreference } = this.props;

    getPaginationPreference()
      .then(({ data }) => {
        const newPerPage = data.attributes[kebabCase(resource)];
        this.setCurrentPerPage(newPerPage);
      })
      .catch(() => this.setCurrentPerPage(10));
  }

  setCurrentPerPage = (newPerPage) => this.setState({ currentPerPage: newPerPage });

  changePaginationPreference = (perPage) => () => {
    const { resource, setPaginationPreference } = this.props;

    setPaginationPreference({
      perPage,
      resource,
    }).then(() => {
      this.setCurrentPerPage(perPage);
      this.props.request({ page: 1 });
    });
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.label}>{t('pagination.entries_per_page')} </div>
        <div className={styles.buttonsContainer}>
          {PER_PAGE_VALUES.map((perPage) => {
            return (
              <span className={styles.span} key={perPage}>
                <UnderlinedButton
                  onClick={this.changePaginationPreference(perPage)}
                  data-id={`${perPage}-per-page`}
                  className={cx(styles.button, {
                    [styles.isActive]: this.state.currentPerPage === perPage,
                  })}
                >
                  {perPage}
                </UnderlinedButton>
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}

RawResourcesPerPage.propTypes = {
  resource: string.isRequired,
  getPaginationPreference: func.isRequired,
  setPaginationPreference: func.isRequired,
  request: func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setPaginationPreference: (paginationParams) =>
    dispatch(setPaginationPreferenceAction(paginationParams)),
  getPaginationPreference: () => dispatch(getPaginationPreferenceAction(ownProps.resource)),
});

const ResourcesPerPage = connect(undefined, mapDispatchToProps)(RawResourcesPerPage);

export { RawResourcesPerPage, ResourcesPerPage };
