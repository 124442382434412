import React, { Component } from 'react';
import { bool, string } from 'prop-types';

import { PIWIK_ACTION_REVENUE_PREVIEW, PIWIK_CATEGORY_REVENUE } from 'constants/piwik';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import ActionButton from 'components/ActionPanel/ActionButton';
import { ButtonAppearances } from 'components/Button';

import PreviewModal from '../PreviewModal';

import styles from '../ActionsSection.module.css';

export default class PreviewButton extends Component {
  state = {
    previewVisible: false,
  };

  togglePreview = () => {
    const { previewVisible } = this.state;

    if (!previewVisible)
      piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_REVENUE_PREVIEW);

    this.setState({
      previewVisible: !previewVisible,
    });
  };

  render() {
    const { visible, dataId = '' } = this.props;
    const { previewVisible } = this.state;
    if (!visible) return null;

    return (
      <div className={styles.transparentContainer}>
        <ActionButton
          dataId={dataId}
          fullWidth
          appearance={ButtonAppearances.outlined}
          onClick={this.togglePreview}
          label={t('revenue.form.preview')}
        />
        <PreviewModal onClose={this.togglePreview} isOpen={previewVisible} />
      </div>
    );
  }
}

PreviewButton.propTypes = {
  visible: bool,
  dataId: string,
};
