import moment from 'moment';

import { DATE_FORMAT, DATE_FORMAT_COMBINED } from 'constants/datetime';

export const parseAndFormatDate = (date, inputFormat, outputFormat) =>
  moment(date, inputFormat).format(outputFormat);

export const parseDate = (date, format) => parseAndFormatDate(date, format, format);

export const getMomentDateForDatepicker = (date) =>
  moment(date, [DATE_FORMAT, DATE_FORMAT_COMBINED], true);

export const getFormattedDateTextForDatepicker = (date) =>
  getMomentDateForDatepicker(date).format(DATE_FORMAT);
