import { Action, configureStore, Store as StoreType } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { ThunkAction } from 'redux-thunk';

import apiMiddleware from 'middlewares/api';
import clamAVMiddleware from 'middlewares/clamAV';
import notificationMiddleware from 'middlewares/notification';
import piwikMiddleware from 'middlewares/piwik/piwik';
import { history } from 'routes/history';
import rootSaga from 'sagas';
import { isDevelopment, isProduction } from 'shared/utils/environment';

import rootReducer from './rootReducer';

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: rootReducer,
  devTools: !isProduction,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      apiMiddleware,
      sagaMiddleware,
      notificationMiddleware,
      clamAVMiddleware,
      piwikMiddleware,
      routerMiddleware(history)
    ),
});
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

if (isDevelopment && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type RootState = ReturnType<typeof store.getState>;
export type Store = StoreType<RootState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, null, Action<string>>;
