// redux action types
import * as Clients from 'constants/clients';
import * as DateV from 'constants/datev';
import * as Invoice from 'constants/incoming-invoice';
import * as InvoiceUploadedFiles from 'constants/incoming-invoice/uploaded-files';
import * as Liquidity from 'constants/liquidity';
import * as MonthlyOverview from 'constants/monthly-overview';
import * as OrderConfirmation from 'constants/order-confirmation';
import * as Piwik from 'constants/piwik';
import * as ProductCatalogGroups from 'constants/product-catalog/groups';
import * as ProductCatalogItems from 'constants/product-catalog/items';
import * as Proposal from 'constants/proposal';
import * as Suppliers from 'constants/suppliers';
import { piwikHelpers } from 'shared/utils/piwik';

import homeActionSwitch from './actionSwitches/homeSwitch';
import productCatalogGroupsActionSwitch from './actionSwitches/productCatalogGroupSwitch';
import productCatalogItemsActionSwitch from './actionSwitches/productCatalogItemSwitch';

function processAttributes(action, func) {
  try {
    func(action.response.data.attributes);
  } catch (ex) {} // eslint-disable-line no-empty
}

export default () => (next) => (action) => {
  // TODO: refactor to a separate switch statements for each part of the app, see, e.g.: productCatalogGroupsActionSwitch
  switch (action.type) {
    case Suppliers.CREATE_SUCCESS:
      piwikHelpers.trackGoal(5);
      break;

    case Invoice.UPDATE_SUCCESS:
      processAttributes(action, ({ creditNote }) => {
        piwikHelpers.trackGoal(creditNote ? 10 : 8);
      });
      break;

    case Invoice.CREATE_SUPPLIER_SUCCESS:
      piwikHelpers.trackGoal(19);
      break;

    case InvoiceUploadedFiles.UPLOAD_SUCCESS:
      piwikHelpers.trackGoal(9);
      break;

    case Clients.DELETE_SUCCESS:
      piwikHelpers.trackGoal(18);
      break;

    case Clients.CREATE_SUCCESS:
      piwikHelpers.trackGoal(2);
      break;

    case DateV.EXPORT_SUCCESS:
      piwikHelpers.trackGoal(14);
      break;

    case Proposal.TRANSFORM_SUCCESS:
      piwikHelpers.trackGoal(13);
      break;

    case Proposal.FIRST_UPDATE_SUCCESS:
      piwikHelpers.trackGoal(12);
      piwikHelpers.trackGoal(8);
      break;

    case Proposal.DELETE_SUCCESS:
      piwikHelpers.trackEvent(Piwik.CATEGORY_PROPOSALS, Piwik.ACTION_DELETE_PROPOSAL_SUCCESS);
      break;

    case OrderConfirmation.DELETE_SUCCESS:
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_ORDER_CONFIRMATIONS,
        Piwik.ACTION_DELETE_ORDER_CONFIRMATION_SUCCESS
      );
      break;

    default:
      break;
  }

  // Product Catalog Groups section
  if (action.name === ProductCatalogGroups.PRODUCT_CATALOG_GROUPS) {
    productCatalogGroupsActionSwitch(action.type);
  }

  // Home section
  if (action.name === MonthlyOverview.NAMESPACE || action.name === Liquidity.NAMESPACE) {
    homeActionSwitch(action);
  }

  if (action.name === ProductCatalogItems.PRODUCT_CATALOG_ITEMS) {
    productCatalogItemsActionSwitch(action.type);
  }

  return next(action);
};
