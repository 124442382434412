import { CALL_API } from 'constants/api';
import {
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/pagination';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiSetPaginationPreference = ({ resource, perPage }) => ({
  [CALL_API]: {
    data: {
      pagination_preference_params: { [resource]: perPage },
    },
    method: 'PUT',
    endpoint: `/me/pagination_preferences`,
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const setPaginationPreference =
  ({ resource, perPage }) =>
  (dispatch) =>
    dispatch(apiSetPaginationPreference({ resource, perPage })).catch(apiErrorHandler);

export const apiGetPaginationPreference = () => ({
  [CALL_API]: {
    endpoint: '/me/pagination_preferences',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const getPaginationPreference = () => (dispatch) =>
  dispatch(apiGetPaginationPreference()).catch(apiErrorHandler);
