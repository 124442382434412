import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { Client } from 'types/entities/Client';
import SuggestionsField, {
  SuggestionsFieldProps,
} from 'components/SuggestionsField/SuggestionsField';

import Option from './Option';
import SearchClients from './SearchClients';
import { itemToString } from './utils';

export type ClientFieldProps = Omit<
  SuggestionsFieldProps<Client>,
  'itemToString' | 'search' | 'option'
>;

const ClientField = (props: ClientFieldProps & WrappedFieldProps) => (
  <SuggestionsField<Client>
    search={SearchClients}
    itemToString={itemToString}
    option={Option}
    {...props}
  />
);

export default ClientField;
