import React from 'react';
import { groupBy } from 'lodash';

import { useFetchBankAccounts } from './List.hooks';
import TableItem from './TableItem/TableItem';

type BankAccountsListProps = {
  reloadCounter: number;
  onReload: () => void;
};
const BankAccountsList = ({ reloadCounter, onReload }: BankAccountsListProps) => {
  const { isLoading, data: bankAccounts } = useFetchBankAccounts(reloadCounter);
  const data = groupBy(bankAccounts, (item) => item.bankName);

  return (
    <>
      {Object.keys(data).map((key, index) => (
        <TableItem key={index} data={data[key]} isLoading={isLoading} onReload={onReload} />
      ))}
    </>
  );
};

export default BankAccountsList;
