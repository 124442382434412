import React from 'react';
import { bool, number, shape, string } from 'prop-types';

import { t } from 'shared/utils';

import Description from './components/Description/Description';

import styles from './CurrentPaymentPlan.module.css';

const CurrentPaymentPlan = ({
  currentPaymentPlanDetails: {
    uid,
    price,
    vrMainBankAccount,
    onTrial,
    latestHistoryEntryMode,
    currentPlanEndDate,
    trialEndDate,
  },
}) => (
  <div className={styles.main}>
    <div className={styles.block}>
      <div className={styles.content}>{t('profile.payment_plan.current_payment_plan.header')}</div>
    </div>
    <div className={styles.block}>
      <Description
        plan={uid}
        price={price}
        vrMainBankAccount={vrMainBankAccount}
        onTrial={onTrial}
        latestHistoryEntryMode={latestHistoryEntryMode}
        currentPlanEndDate={currentPlanEndDate}
        trialEndDate={trialEndDate}
      />
    </div>
  </div>
);

CurrentPaymentPlan.propTypes = {
  currentPaymentPlanDetails: shape({
    uid: string,
    price: number,
    vrMainBankAccount: bool,
    onTrial: bool,
    latestHistoryEntryMode: string,
    currentPlanEndDate: string,
    trialEndDate: string,
  }),
};

export default CurrentPaymentPlan;
