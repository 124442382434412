import { showNotification } from 'actions/notification';
import { fetchOutgoingInvoiceSums } from 'actions/outgoing-invoice/sums';
import { CALL_API } from 'constants/api';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/outgoing-invoice/line-items';
import {
  lineItemCreateSuccess,
  lineItemDeleteFailure,
  lineItemDeleteSuccess,
  lineItemUpdateSuccess,
} from 'notifications/outgoing-invoices';
import { apiErrorHandler, isNetworkError } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

import { toggleDisableAction } from '.';

export const apiFetchOutgoingLineItems = (invoiceId) => ({
  [CALL_API]: {
    endpoint: `/me/outgoing_invoices/${invoiceId}/invoice_line_items`,
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiDeleteOutgoingLineItem = (invoiceId, id) => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: `/me/outgoing_invoices/${invoiceId}/invoice_line_items/${id}`,
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const apiUpdateOutgoingLineItem = (invoiceId, id, data) => ({
  [CALL_API]: {
    data,
    method: 'PUT',
    endpoint: `/me/outgoing_invoices/${invoiceId}/invoice_line_items/${id}`,
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const apiCreateOutgoingLineItem = (invoiceId, data) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: `/me/outgoing_invoices/${invoiceId}/invoice_line_items`,
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const fetchOutgoingLineItems =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchOutgoingLineItems(...args)).catch(apiErrorHandler);

export const deleteOutgoingLineItem = (invoiceId, id) => (dispatch) => {
  dispatch(toggleDisableAction());

  return bindServerValidation(apiDeleteOutgoingLineItem(invoiceId, id), dispatch, {
    isReduxForm: false,
  })
    .then(() => {
      dispatch(showNotification(lineItemDeleteSuccess));
      dispatch(fetchOutgoingInvoiceSums(invoiceId));
    })
    .catch((err) => {
      apiErrorHandler(err);
      if (!isNetworkError(err)) {
        dispatch(showNotification(lineItemDeleteFailure));
      }
    })
    .finally(() => {
      dispatch(toggleDisableAction());
    });
};

export const updateOutgoingLineItem = (invoiceId, id, formValues) => (dispatch) => {
  dispatch(toggleDisableAction());

  return bindServerValidation(apiUpdateOutgoingLineItem(invoiceId, id, formValues), dispatch, {
    isReduxForm: false,
  })
    .then(() => {
      dispatch(showNotification(lineItemUpdateSuccess));
      dispatch(fetchOutgoingInvoiceSums(invoiceId));
    })
    .catch((err) => {
      apiErrorHandler(err);
      throw err;
    })
    .finally(() => {
      dispatch(toggleDisableAction());
    });
};

export const createOutgoingLineItem = (invoiceId, formValues) => (dispatch) => {
  dispatch(toggleDisableAction());

  return bindServerValidation(apiCreateOutgoingLineItem(invoiceId, formValues), dispatch, {
    isReduxForm: false,
  })
    .then(() => {
      dispatch(showNotification(lineItemCreateSuccess));
      dispatch(fetchOutgoingInvoiceSums(invoiceId));
    })
    .catch((err) => {
      apiErrorHandler(err);
      throw err;
    })
    .finally(() => {
      dispatch(toggleDisableAction());
    });
};
