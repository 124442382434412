import React from 'react';
import { bool, number } from 'prop-types';

import { formatMoney, t } from 'shared/utils';

import styles from '../Description.module.css';

const Premium = ({ price, vrMainBankAccount }) => {
  const priceDescription = vrMainBankAccount
    ? t('profile.payment_plan.current_payment_plan.discounted')
    : t('profile.payment_plan.current_payment_plan.regular');
  const priceString = formatMoney(price);

  return (
    <div className={styles.priceContainer}>
      {priceDescription}
      <span className={styles.price}>{priceString}</span>
      <p>{t('profile.payment_plan.current_payment_plan.monthly')} *</p>
      <p className={styles.tax}>{t('profile.payment_plan.tax')}</p>
    </div>
  );
};

Premium.propTypes = {
  price: number,
  vrMainBankAccount: bool,
};

export default Premium;
