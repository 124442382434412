import { useEffect, useRef } from 'react';
import { usePrevious } from 'react-use';
import { debounce, isEqual } from 'lodash';

import { hasAnyFiltersActive } from 'shared/utils/hasAnyFiltersActive';

interface Props {
  sorting: any;
  parsedFilters: any;
  pagination: any;
}

interface FetchFunctionArgs extends Props {
  overwrittenValues: any;
}

interface UseRefetchOnPropsChangeParams {
  fetchFunction: (props: FetchFunctionArgs) => void;
  props: Props;
  debounceTimeout?: number;
}

/**
 * Universal hook for refetching data when sorting, pagination or filters change.
 * @param props Object containing props which should be observed (when one of them change, refetch will occur)
 * @param fetchFunction Function which will be called with props when refetch should happens.
 */
export const useRefetchOnPropsChange = ({
  fetchFunction,
  props,
  debounceTimeout = 500,
}: UseRefetchOnPropsChangeParams) => {
  const prevProps = usePrevious(props);
  const debouncedFetch = useRef(debounce(fetchFunction, debounceTimeout));

  useEffect(() => {
    const { sorting, parsedFilters, pagination = {} } = props;
    const paramsNotChanged =
      prevProps &&
      isEqual(sorting, prevProps.sorting) &&
      isEqual(parsedFilters, prevProps.parsedFilters);

    /**
     * If neither sorting, pagination or filters changed then there
     * is no need to fetch anything.
     */
    if (paramsNotChanged) {
      return;
    }

    const isFiltering = !isEqual(parsedFilters, prevProps?.parsedFilters);

    /**
     * If filters changed then we should always show first page.
     */
    const overwrittenValues = isFiltering ? { page: 1 } : undefined;

    /**
     * If new filters are empty then there is no need to debounce fetch.
     */
    const areFiltersCleared = !hasAnyFiltersActive(parsedFilters);

    if (areFiltersCleared) {
      fetchFunction({ pagination, sorting, parsedFilters, overwrittenValues });
      return;
    }

    if (debouncedFetch.current)
      debouncedFetch.current({ pagination, sorting, parsedFilters, overwrittenValues });
  }, [fetchFunction, prevProps, props]);
};
