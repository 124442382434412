import React from 'react';
import InlineSvg from 'react-inlinesvg';

import existIcon from 'images/exist.svg';
import { t } from 'shared/utils';
import CardView from 'components/CardView';

import NewContractDropdown from '../NewContractDropdown/NewContractDropdown';
import NewRevenueButton from '../NewRevenueButton/NewRevenueButton';
import Icon from './Icon';

import styles from './EmptyEntryPage.module.css';

const EmptyEntryPage = () => (
  <CardView className={styles.wrapper}>
    <Icon data-id="ContractsEmptyEntryPage:image" className={styles.image} />
    <div data-id="ContractsEmptyEntryPage:header" className={styles.header}>
      {t('contracts.empty_entry_state.header')}
    </div>
    <div data-id="ContractsEmptyEntryPage:subheader" className={styles.subheader}>
      {t('contracts.empty_entry_state.subheader')}
    </div>
    <div data-id="ContractsEmptyEntryPage:info-container" className={styles.infoContainer}>
      {[
        t('contracts.empty_entry_state.tasks.task_1'),
        t('contracts.empty_entry_state.tasks.task_2'),
        t('contracts.empty_entry_state.tasks.task_3'),
      ].map((row) => (
        <div key={row} className={styles.info}>
          <div className={styles.checkmarkContainer}>
            <InlineSvg src={existIcon} />
          </div>
          <span className={styles.row}>{row}</span>
        </div>
      ))}
    </div>
    <div className={styles.buttons}>
      <NewContractDropdown />
      <NewRevenueButton />
    </div>
  </CardView>
);

export default EmptyEntryPage;
