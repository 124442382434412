import styled from 'styled-components';

import ModalTitle from 'redesign/components/atoms/ModalTitle/ModalTitle';

export const Title = styled(ModalTitle)`
  text-align: center;
  margin-top: ${({ theme }) => theme.space['12']};
`;

export const Message = styled.p`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.space['7']};
`;
