import React, { FunctionComponent, MouseEvent } from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';

import trashBin from 'images/common/trash-bin.svg';

import styles from './RemoveButton.module.css';

interface RemoveButtonProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  label: string;
  dataId?: string;
  className?: string;
}

const RemoveButton: FunctionComponent<RemoveButtonProps> = ({
  onClick,
  label,
  dataId,
  className = '',
}) => (
  <button className={cx(styles.button, className)} data-id={dataId} onClick={onClick}>
    <InlineSvg src={trashBin} className={styles.icon} />
    {label}
  </button>
);

export default RemoveButton;
