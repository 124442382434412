import React from 'react';

import Loading from 'components/Loading';

import { useFetchInvoiceTemplate } from '../hooks';
import Form from './Form';
import { LoadingWrapper } from './Table.styled';

const Table = () => {
  const invoiceTemplate = useFetchInvoiceTemplate();

  if (!invoiceTemplate)
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );

  return <Form invoiceTemplate={invoiceTemplate} />;
};

export default Table;
