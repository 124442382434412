import styled from 'styled-components';

import BaseBox from 'redesign/components/atoms/Box/Box';
import FieldWrapper from 'redesign/components/molecules/FieldWrapper/FieldWrapper';
import { Breakpoints } from 'redesign/styles/breakpoints';

export const Box = styled(BaseBox)`
  height: 451px;
  padding: ${({ theme }) => `${theme.space['4']} ${theme.space['2']}`};
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-row-gap: ${({ theme }) => theme.space['4']};
`;

export const BankAccountField = styled(FieldWrapper)`
  max-width: 272px;
  margin-left: ${({ theme }) => theme.space['2']};
  margin-right: ${({ theme }) => theme.space['2']};
`;

export const TableWrapper = styled.div`
  overflow-y: auto;

  th[data-id='header-name'] {
    width: 38%;
  }

  th[data-id='header-total'] {
    width: 38%;
  }

  @media (min-width: ${Breakpoints.sm}) {
    th[data-id='header-name'] {
      width: 50%;
    }
  }
`;

export const LastUpdate = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.sm};
  letter-spacing: 0.25px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.grey['070']};

  a {
    color: ${({ theme }) => theme.colors.vrblue['100']};
    font-weight: bold;
    letter-spacing: 0.58px;
    line-height: 17px;
    margin-bottom: ${({ theme }) => theme.space['1']};
    display: inline-block;
  }
`;
