export const CREATE_REQUEST = 'BANK_TRANSFER_CONNECTIONS/CREATE_REQUEST';
export const CREATE_SUCCESS = 'BANK_TRANSFER_CONNECTIONS/CREATE_SUCCESS';
export const CREATE_FAILURE = 'BANK_TRANSFER_CONNECTIONS/CREATE_FAILURE';

export const DELETE_REQUEST = 'BANK_TRANSFER_CONNECTIONS/DELETE_REQUEST';
export const DELETE_SUCCESS = 'BANK_TRANSFER_CONNECTIONS/DELETE_SUCCESS';
export const DELETE_FAILURE = 'BANK_TRANSFER_CONNECTIONS/DELETE_FAILURE';

export const REJECT_SUGGESTION_REQUEST = 'BANK_TRANSFER_CONNECTIONS/REJECT_SUGGESTION_REQUEST';
export const REJECT_SUGGESTION_SUCCESS = 'BANK_TRANSFER_CONNECTIONS/REJECT_SUGGESTION_SUCCESS';
export const REJECT_SUGGESTION_FAILURE = 'BANK_TRANSFER_CONNECTIONS/REJECT_SUGGESTION_FAILURE';

export const ACCEPT_SUGGESTION_REQUEST = 'BANK_TRANSFER_CONNECTIONS/ACCEPT_SUGGESTION_REQUEST';
export const ACCEPT_SUGGESTION_SUCCESS = 'BANK_TRANSFER_CONNECTIONS/ACCEPT_SUGGESTION_SUCCESS';
export const ACCEPT_SUGGESTION_FAILURE = 'BANK_TRANSFER_CONNECTIONS/ACCEPT_SUGGESTION_FAILURE';

export const CREATE_LABEL_REQUEST = 'BANK_TRANSFER_LABEL/CREATE_REQUEST';
export const CREATE_LABEL_SUCCESS = 'BANK_TRANSFER_LABEL/CREATE_SUCCESS';
export const CREATE_LABEL_FAILURE = 'BANK_TRANSFER_LABEL/CREATE_FAILURE';

export const UPDATE_LABEL_REQUEST = 'BANK_TRANSFER_LABEL/UPDATE_REQUEST';
export const UPDATE_LABEL_SUCCESS = 'BANK_TRANSFER_LABEL/UPDATE_SUCCESS';
export const UPDATE_LABEL_FAILURE = 'BANK_TRANSFER_LABEL/UPDATE_FAILURE';

export const DELETE_LABEL_REQUEST = 'BANK_TRANSFER_LABEL/DELETE_REQUEST';
export const DELETE_LABEL_SUCCESS = 'BANK_TRANSFER_LABEL/DELETE_SUCCESS';
export const DELETE_LABEL_FAILURE = 'BANK_TRANSFER_LABEL/DELETE_FAILURE';
