import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { CrudMode, SHOW_MODE } from 'constants/common/crud';
import { getContractStatus } from 'selectors/contracts';
import { t } from 'shared/utils/index';
import { CategoryResponse } from 'types/entities/Category';
import { ContractSource, ContractTemplates } from 'types/entities/Contract';
import Card from 'components/Card/index';
import { getContractIntervalOptions } from 'features/contracts/selectors';

import ActiveTemplate from './ActiveTemplate';

import styles from './Fields.module.css';

type FieldsProps = {
  crudMode: CrudMode;
  contractSource?: ContractSource;
  activeTemplate: ContractTemplates[keyof ContractTemplates];
  isRevenue: boolean;
  selectedCategory: CategoryResponse;
  isLoan: boolean;
  initialValues: any;
  categoryOptions: CategoryResponse[];
};

const Fields = ({
  crudMode,
  contractSource,
  activeTemplate,
  selectedCategory,
  initialValues,
  categoryOptions,
}: FieldsProps) => {
  const isUpdating = useSelector(getContractStatus);
  const readonly = crudMode === SHOW_MODE || isUpdating;
  const intervalOptions = useSelector(getContractIntervalOptions);

  return (
    <Card className={styles.main}>
      <Card.Header className={styles.header}>
        <div className={styles.headerLeft}>{t('contracts.new.heading')}</div>
      </Card.Header>
      <Card.Body>
        <div className={cx(styles.columns, styles.columnsPadded)}>
          <div className={styles.column}>
            <ActiveTemplate
              type={activeTemplate}
              intervalOptions={intervalOptions}
              readonly={readonly}
              crudMode={crudMode}
              contractSource={contractSource}
              lineCategories={categoryOptions}
              selectedCategory={selectedCategory}
              initialValues={initialValues}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Fields;
