import normalize from 'json-api-normalizer';
import {
  get,
  identity,
  isArray,
  isFunction,
  isPlainObject,
  mapValues,
  mergeWith,
  omit,
  pickBy,
} from 'lodash';
import { combineReducers } from 'redux';
import build from 'redux-object';
import { createSelector } from 'reselect';

import { paginationParams, sortingParams } from 'actions/helpers/table';
import {
  FAILURE_SUFFIX,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_SUCCESS,
  FETCH_SUGGESTED_NUMBER_SUCCESS,
  INDEX_SUCCESS,
  PAGINATE,
  REQUEST_SUFFIX,
  SORT,
  SORT_DIRECTION,
  SUCCESS_SUFFIX,
} from 'constants/common/resource';
import EntityPath from 'constants/entitiesPaths';
import filtersReducer, { getSanitizedFilters, parsedFiltersReducer } from 'reducers/common/filters';
import { getPaginationReducer } from 'reducers/pagination';
import { buildCategories } from 'components/CategorySearch/utils';

const paginationReducer = getPaginationReducer([PAGINATE, INDEX_SUCCESS]);

export const getParams = ({ sorting, pagination, parsedFilters, ...rest }) => ({
  ...rest,
  ...pickBy(
    {
      ...paginationParams(pagination),
      ...sortingParams(sorting),
      filters: getSanitizedFilters(parsedFilters),
    },
    identity
  ),
});

export const getIds = (state) => state.data.ids || [];
export const getEntities = (state, object) => state.data.entities[object];
export const getRelatedEntites = (state, object) => omit(state.data.entities, [object]);
export const getSuggestedNumber = (state) =>
  state.productCatalogItems.productCatalogItems.suggestedNumber;
export const getLineCategories = (state) =>
  state.productCatalogItems.productCatalogItems.lineCategories || [];

const mapRelations =
  (related) =>
  ({ relationships, attributes, meta } = {}) => {
    const mapEntity = ({ id, type }) => related[type][id].attributes;

    const relations = mapValues(relationships, ({ data } = {}) => {
      if (!data) return {};
      if (isArray(data)) return data.map((obj) => mapEntity(obj));
      return mapEntity(data);
    });

    return { ...attributes, ...relations, ...meta };
  };

export const makeGetList = () =>
  createSelector(getIds, getEntities, getRelatedEntites, (ids, entities, related) =>
    ids.map((id) => entities[id]).map(mapRelations(related))
  );

export const makeGetEntity = () => (id) =>
  createSelector(getEntities, getRelatedEntites, (entities, related) =>
    entities ? mapRelations(related)(entities[id]) : null
  );

const isFetching = (state = false, action) => {
  if (action.type.endsWith(REQUEST_SUFFIX)) {
    return true;
  }
  if (action.type.endsWith(SUCCESS_SUFFIX) || action.type.endsWith(FAILURE_SUFFIX)) {
    return false;
  }

  return state;
};

export const initialDataState = {
  entities: {},
  ids: [],
};

const data = (state = initialDataState, action) => {
  const response = normalize(action.response || {}, { endpoint: 'meta' });
  const ids = (isArray(get(response, 'meta.meta.data')) ? get(response, 'meta.meta.data') : []).map(
    (obj) => obj.id
  );
  switch (action.type) {
    case INDEX_SUCCESS:
      return {
        ...state,
        entities: { ...state.entities, ...response },
        ids,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        entities: { ...state.entities, ...response },
        ids,
      };
    default:
      return state;
  }
};

export const defaultSortingState = {
  column: '',
  direction: SORT_DIRECTION.DESCENDING,
};

export const sorting = (state = defaultSortingState, action) => {
  switch (action.type) {
    case SORT:
      const getDirection = () => {
        if (action.direction) {
          return action.direction;
        }
        if (state.column === action.column && state.direction === SORT_DIRECTION.ASCENDING) {
          return SORT_DIRECTION.DESCENDING;
        }

        return SORT_DIRECTION.ASCENDING;
      };

      return {
        column: action.column,
        direction: getDirection(),
      };
    default:
      return state;
  }
};

const suggestedNumber = (state = '', action) =>
  action.type === FETCH_SUGGESTED_NUMBER_SUCCESS ? action.rawResponse : state;

const lineCategories = (state = '', action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
    case FETCH_CATEGORIES_FAILURE:
      return state;

    case FETCH_CATEGORIES_SUCCESS:
      const ids = action.response.data.map((item) => item.id);
      const outgoingInvoiceRevenueCategories = buildCategories(
        build(normalize(action.response || {}), EntityPath.Categories, ids)
      );

      return outgoingInvoiceRevenueCategories;

    default:
      return state;
  }
};

const defaultReducerShape = {
  isFetching,
  suggestedNumber,
  lineCategories,
  data,
  params: {
    pagination: paginationReducer,
    sorting,
    filters: filtersReducer,
    parsedFilters: parsedFiltersReducer,
  },
};

const combineReducersRecursive = (obj = {}) => {
  const reducers = pickBy(obj, isFunction);
  const nestedObjects = pickBy(obj, isPlainObject);
  const nestedReducers = mapValues(nestedObjects, (o) => combineReducersRecursive(o));

  return combineReducers({ ...reducers, ...nestedReducers });
};

export const getResourceReducer = (customReducers = {}) => {
  const mergedReducers = mergeWith({}, defaultReducerShape, customReducers);
  return combineReducersRecursive(mergedReducers);
};

export default combineReducersRecursive(defaultReducerShape);
