export const Resources = {
  BANK_ACCOUNTS_BANK_TRANSFERS: 'bank_accounts_bank_transfers',
  CASH_TRANSACTIONS: 'cash_transactions',
  CLIENTS: 'clients',
  DATEV: 'datev',
  DATEV_EXPORTS: 'datev_exports',
  DELIVERY_NOTES: 'delivery_notes',
  INCOMING_INVOICES: 'incoming_invoices',
  OUTGOING_INVOICES: 'outgoing_invoices',
  PRODUCT_CATALOG_GROUPS: 'product_catalog_groups',
  PRODUCT_CATALOG_ITEMS: 'product_catalog_items',
  PROPOSALS: 'proposals',
  ORDER_CONFIRMATIONS: 'order_confirmations',
  SUPPLIERS: 'suppliers',
  INVOICE_CLIENTS: 'invoice_clients',
  INVOICE_SUPPLIERS: 'invoice_suppliers',
};
