import React, { ComponentType, ReactNode, SyntheticEvent, useCallback, useState } from 'react';

import { Label, LabelWrapper, Tooltip } from './FieldWrapper.styled';

export type FieldWrapperProps<FieldProps> = {
  label: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  onFocus?: (event: SyntheticEvent) => void;
  onBlur?: (event: SyntheticEvent) => void;
  field: ComponentType<FieldProps>;
  children?: ReactNode;
  name?: string;
  icon?: ReactNode | string;
  dataId?: string;
  type?: string;
  tooltipMessage?: string;
} & FieldProps;

const FieldWrapper = <FieldProps,>({
  label,
  className,
  disabled,
  onBlur,
  onFocus,
  field: Field,
  name,
  tooltipMessage,
  ...rest
}: FieldWrapperProps<FieldProps>) => {
  const [isActive, setIsActive] = useState(false);

  const handleFocus = useCallback(
    (event) => {
      onFocus && onFocus(event);
      setIsActive(true);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (event) => {
      onBlur && onBlur(event);
      setIsActive(false);
    },
    [onBlur]
  );

  return (
    <div className={className} {...rest}>
      <LabelWrapper>
        <Label $isDisabled={disabled} $isActive={isActive} htmlFor={name}>
          {label}
        </Label>
        {tooltipMessage && <Tooltip message={tooltipMessage} />}
      </LabelWrapper>
      <Field
        {...(rest as unknown as FieldProps)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
        name={name}
      />
    </div>
  );
};

export default FieldWrapper;
