import React, { useCallback } from 'react';
import { FileRejection } from 'react-dropzone';
import { Control, useController } from 'react-hook-form';
import { AxiosError } from 'axios';

import { showErrorNotification, showNotification } from 'actions/notification';
import { deleteLogo, uploadLogo } from 'api/me/invoiceTemplates';
import { ValidationErrors } from 'api/types';
import { LOGO_AND_BRIEFPAPIER_LOGO_UPLOAD } from 'constants/piwik';
import validationNotification from 'notifications/validation';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { mapServerErrorsToNotificationError } from 'shared/utils/server-validation';
import Dropzone from 'redesign/components/organisms/Dropzone/Dropzone';

import { FormData } from './types';
import { trackEvent } from './utils';

type LogoFieldProps = {
  control: Control<FormData>;
  invoiceTemplateId: string;
  onRefreshPreview: () => void;
};

const LogoField = ({ invoiceTemplateId, control, onRefreshPreview }: LogoFieldProps) => {
  const dispatch = useAppDispatch();
  const {
    field: { value, onChange },
  } = useController({ control: control, name: 'logo' });

  const handleDelete = useCallback(async () => {
    await deleteLogo(invoiceTemplateId);
    onChange(null);
    onRefreshPreview();
  }, [onRefreshPreview, onChange, invoiceTemplateId]);

  const handleDropAccepted = useCallback(
    async (files: File[]) => {
      trackEvent(LOGO_AND_BRIEFPAPIER_LOGO_UPLOAD);

      if (!files.length) return;

      let response;

      try {
        response = await uploadLogo(invoiceTemplateId, files[0]);
      } catch (err) {
        let error = err as AxiosError<ValidationErrors>;

        if (!error.response) {
          throw err;
        }

        const notification = validationNotification(
          mapServerErrorsToNotificationError(error.response.data.errors)
        );

        dispatch(showNotification(notification));
        return;
      }

      onChange(response.data.data.attributes.logo);
      onRefreshPreview();
    },
    [dispatch, onChange, onRefreshPreview, invoiceTemplateId]
  );

  const handleDropRejected = useCallback(
    (files: FileRejection[]) => {
      const errors: { [key: string]: boolean } = {};

      files.forEach((file) => {
        file.errors.forEach((error) => {
          errors[error.code] = true;
        });
      });

      if (errors['file-invalid-type']) {
        dispatch(
          showErrorNotification(
            t('features.settings.document_template.logo_and_briefpapier.errors.file_type_jpeg_png')
          )
        );
      } else if (errors['file-too-large']) {
        dispatch(
          showErrorNotification(
            t('features.settings.document_template.logo_and_briefpapier.errors.file_size', {
              size: 5,
            })
          )
        );
      } else if (errors['too-many-files']) {
        dispatch(
          showErrorNotification(
            t('features.settings.document_template.logo_and_briefpapier.errors.too_many_files')
          )
        );
      }
    },
    [dispatch]
  );

  return (
    <Dropzone
      file={value}
      accept="image/x-png,image/png,image/jpeg,image/jpg"
      maxFiles={1}
      multiple={false}
      maxSize={5e6}
      fileInfo={t('features.settings.document_template.logo_and_briefpapier.fields.logo_file_info')}
      deletionTitle={t(
        'features.settings.document_template.logo_and_briefpapier.fields.logo_deletion_title'
      )}
      deletionButtonText={t(
        'features.settings.document_template.logo_and_briefpapier.fields.logo_deletion_button_text'
      )}
      replacingTitle={t(
        'features.settings.document_template.logo_and_briefpapier.fields.logo_replacing_title'
      )}
      replacingButtonText={t(
        'features.settings.document_template.logo_and_briefpapier.fields.logo_replacing_button_text'
      )}
      onDelete={handleDelete}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
    />
  );
};

export default LogoField;
