import React, { useCallback, useState } from 'react';
import { format } from 'date-fns';

import { DATE_FORMAT_UNICODE } from 'constants/datetime';
import { formatMoney, t } from 'shared/utils';
import { Cashbook } from 'types/entities/Cashbook';

import { NoReminderOption } from '../../NotificationSection/constants';
import NotificationModal from './NotificationModal/NotificationModal';
import {
  ActionButton,
  CurrentRhythm,
  CurrentState,
  IconEdit,
  NotificationRow,
  Rhythm,
  StartState,
  StateAmount,
  StateDate,
  StateExpenses,
  StateExpensesAmount,
  StateRevenues,
  StateRevenuesAmount,
  StateRow,
} from './Summary.styled';
import { getCurrentRhythm, getMonthlyReminder } from './utils';

type SummaryProps = {
  cashbook: Cashbook;
  onSave: () => void;
};

const Summary = ({ cashbook, onSave }: SummaryProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const monthlyReminder = getMonthlyReminder(cashbook);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <NotificationRow>
        <Rhythm>{t('cashbooks.cash_transactions.summary.rhythm')}</Rhythm>
        <CurrentRhythm data-id="notification-rhythm">
          {getCurrentRhythm(cashbook)}
          <ActionButton onClick={openModal} data-id="edit-notification-intrval">
            <IconEdit />
          </ActionButton>
        </CurrentRhythm>
      </NotificationRow>
      <StartState>
        <StateRow>
          <StateDate>
            {t('cashbooks.cash_transactions.summary.start_state', {
              date: format(new Date(cashbook.startDate), DATE_FORMAT_UNICODE),
            })}
          </StateDate>
          <StateAmount data-id="start-amount-summary">
            {formatMoney(cashbook.startAmount)}
          </StateAmount>
        </StateRow>
        <StateRow>
          <StateExpenses>{t('cashbooks.cash_transactions.summary.revenues')}</StateExpenses>
          <StateExpensesAmount data-id="revenues-summary">
            +{formatMoney(cashbook.revenuesSum)}
          </StateExpensesAmount>
        </StateRow>
        <StateRow>
          <StateRevenues>{t('cashbooks.cash_transactions.summary.expenses')}</StateRevenues>
          <StateRevenuesAmount data-id="expenses-summary">
            -{formatMoney(cashbook.expensesSum)}
          </StateRevenuesAmount>
        </StateRow>
      </StartState>
      <CurrentState>
        <StateRow>
          <StateDate>
            {t('cashbooks.cash_transactions.summary.current_state', {
              date: format(new Date(), DATE_FORMAT_UNICODE),
            })}
          </StateDate>
          <StateAmount data-id="current-amount">{formatMoney(cashbook.currentAmount)}</StateAmount>
        </StateRow>
      </CurrentState>
      <NotificationModal
        isOpen={isOpen}
        onClose={closeModal}
        onSave={onSave}
        cashbook={cashbook}
        initialValues={{
          reminderType: cashbook.reminderType === null ? NoReminderOption : cashbook.reminderType,
          reminderHour: cashbook.reminderHour || 9,
          reminderDay:
            cashbook.reminderDay !== 'last' &&
            cashbook.reminderDay !== 'first' &&
            cashbook.reminderDay !== null
              ? cashbook.reminderDay
              : undefined,
          monthlyReminder,
        }}
      />
    </>
  );
};

export default Summary;
