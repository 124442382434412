import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { formValueSelector } from 'redux-form';

import { createContractTransient, fetchContract } from 'actions/contracts';
import { CrudMode, EDIT_MODE, NEW_MODE, SHOW_MODE } from 'constants/common/crud';
import { getContractDetails } from 'selectors/contracts';
import { RootState } from 'store';
import type { ExpenseContract, RevenueContract } from 'types/entities/Contract';
import { ExpenseContractType } from 'types/entities/Contract';
import LoadingIcon from 'components/LoadingIcon';
import { getIntervals } from 'features/contracts/contractsSlice';
import { getLineCategoryOptions } from 'features/contracts/selectors';

import { FORM_NAME } from '../constants';
import Form from './Form';
import { getInitialValues } from './utils';

type CreatorProps = {
  crudMode: CrudMode;
  isRevenue?: boolean;
  contractType: 'basic' | 'loan';
  onCancel?: () => void;
  onSuccess?: (contract: ExpenseContract | RevenueContract) => void;
  saveLabel?: string;
};

const Creator = ({
  crudMode,
  isRevenue = false,
  contractType,
  onCancel,
  onSuccess,
  saveLabel,
}: CreatorProps) => {
  const dispatch = useDispatch();
  const selector = formValueSelector(FORM_NAME);
  const selectedCategory = useSelector((state: RootState) =>
    selector(state, 'invoiceLineCategoryId')
  );
  const isLoan = ExpenseContractType.loan === contractType;
  const [isFetching, setIsFetching] = useState(true);
  const { id: contractId } = useParams<{ id: string }>();
  const contract = useSelector(getContractDetails);
  const categoryOptions = useSelector(getLineCategoryOptions);
  const initialValues = getInitialValues(contract);

  useEffect(() => {
    if ([EDIT_MODE, SHOW_MODE].includes(crudMode) && contractId) {
      const fetch = async () => {
        await dispatch(fetchContract(contractId));

        setIsFetching(false);
      };

      fetch();
    }
  }, [dispatch, contractId, crudMode, isRevenue, isLoan]);

  useEffect(() => {
    if (crudMode === NEW_MODE) {
      dispatch(createContractTransient({ isRevenue, contractType }) as any).finally(() =>
        setIsFetching(false)
      );
    }
  }, [dispatch, crudMode, isRevenue, contractType]);

  useEffect(() => {
    dispatch(getIntervals());
  }, [dispatch]);

  if (isFetching) return <LoadingIcon dataId="loading" />;

  return (
    <Form
      initialValues={initialValues}
      crudMode={crudMode}
      onCancel={onCancel}
      onSuccess={onSuccess}
      contractType={contractType}
      isRevenue={isRevenue}
      saveLabel={saveLabel}
      selectedCategory={selectedCategory}
      categoryOptions={categoryOptions}
    />
  );
};

export default Creator;
