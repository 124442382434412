import React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import styles from './SubmitButton.module.css';

const SubmitButton = ({
  active = false,
  wrapperClassName = '',
  className = '',
  value = '',
  large = false,
  inputHandler = noop,
  dataId = '',
}) => (
  <div className={cx(styles.wrapper, wrapperClassName)}>
    <button
      className={cx(styles.main, className, {
        [styles.active]: active,
        [styles.large]: large,
      })}
      disabled={!active}
      type="submit"
      onClick={inputHandler}
      data-id={dataId}
    >
      {value}
    </button>
  </div>
);

SubmitButton.propTypes = {
  active: PropTypes.bool,
  large: PropTypes.bool,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  value: PropTypes.string,
  inputHandler: PropTypes.func,
  dataId: PropTypes.string,
  name: PropTypes.string,
};

export default SubmitButton;
