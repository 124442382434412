import { JsonApiResponse } from 'json-api-normalizer';

import api from '../index';

export const deleteMe = (token: string, password: string) => {
  const url = '/me';

  return api.delete<JsonApiResponse>(url, {
    data: { token, password },
  });
};
