import React from 'react';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';

import { InvoiceStatuses } from 'constants/statuses';

import Invoice from './components/Invoice';

const SuggestedInvoiceSection = ({
  suggestedInvoices,
  rejectConnectionSuggestion,
  isFetching,
  onAccept,
}) =>
  suggestedInvoices.map((invoice, index) => {
    return (
      <Invoice
        key={`${invoice.id}-${invoice.type}-${index}`}
        {...invoice}
        status={InvoiceStatuses.SUGGESTED}
        isFetching={isFetching}
        shouldLink
        displayName={invoice.clientDisplayName || invoice.supplierDisplayName}
        onAccept={() => onAccept({ resourceId: invoice.id, resourceType: invoice.resourceType })}
        onReject={rejectConnectionSuggestion({
          invoiceId: invoice.id,
          resourceType: invoice.resourceType,
        })}
      />
    );
  });

SuggestedInvoiceSection.propTypes = {
  suggestedInvoices: arrayOf(
    shape({
      id: string,
      type: string,
    })
  ),
  acceptConnectionSuggestion: func.isRequired,
  rejectConnectionSuggestion: func.isRequired,
  isFetching: bool,
  suggestedInvoiceAssginmentMode: bool,
  invoiceType: string,
  id: oneOfType([string, number]),
  handleHideResource: func,
};

export default SuggestedInvoiceSection;
