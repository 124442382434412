export const DISABLE_SCROLL_CLASS_NAME = 'disable-scroll';

export const disableScrollOnBody = () => {
  const element = document.getElementsByTagName('body')[0];

  if (element) element.classList.add(DISABLE_SCROLL_CLASS_NAME);
};

export const enableScrollOnBody = () => {
  const element = document.getElementsByTagName('body')[0];

  if (element) element.classList.remove(DISABLE_SCROLL_CLASS_NAME);
};
