import { ApiResource } from 'types/models';

export interface TaxConsultantData {
  'tax-consultant-client-id': string;
  'tax-consultant-id': string;
  'tax-consultant-email': string;
  'tax-consultant-name': string;
  'tax-consultant-phone': string;
  'taxation-extension': boolean;
  'law-office-name': string;
  'export-password': string;
  'export-due-date': string;
  'fiscal-year-beginning': string;
  'export-time-period': string;
  'small-entrepreneur': boolean;
  'account-chart': string;
  'taxation-interval': string;
  'taxation-type': string;
}

export const TaxConsultantDataFields = [
  'taxConsultantClientId',
  'taxConsultantId',
  'taxConsultantEmail',
  'taxConsultantName',
  'taxConsultantPhone',
  'taxationExtension',
  'lawOfficeName',
  'exportPassword',
  'exportDueDate',
  'fiscalYearBeginning',
  'exportTimePeriod',
  'datevAccountNumbersStrategy',
  'smallEntrepreneur',
  'accountChart',
  'taxationInterval',
  'taxationType',
];

export type UpdateTaxConsultantDataPayload = {
  smallEntrepreneur: boolean;
  taxationExtension: boolean;
  fiscalYearBeginning: string;
  taxationType: string;
  taxationInterval: string;
  accountChart: string;
  taxConsultantName: string;
  taxConsultantId: string;
  taxConsultantClientId: string;
  taxConsultantPhone: string;
  taxConsultantEmail: string;
  exportPassword: string;
};

export type FetchedTaxConsultantData = ApiResource<TaxConsultantData>;

export type UpdatedTaxConsultantData = ApiResource<TaxConsultantData>;
