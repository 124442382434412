export const REQUEST_SUFFIX = '_REQUEST';
export const SUCCESS_SUFFIX = '_SUCCESS';
export const FAILURE_SUFFIX = '_FAILURE';

export const INDEX_REQUEST = 'INDEX_REQUEST';
export const INDEX_SUCCESS = 'INDEX_SUCCESS';
export const INDEX_FAILURE = 'INDEX_FAILURE';
export const INDEX = [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE];

export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const FETCH = [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE];

export const FETCH_SUGGESTED_NUMBER_REQUEST = 'FETCH_SUGGESTED_NUMBER_REQUEST';
export const FETCH_SUGGESTED_NUMBER_SUCCESS = 'FETCH_SUGGESTED_NUMBER_SUCCESS';
export const FETCH_SUGGESTED_NUMBER_FAILURE = 'FETCH_SUGGESTED_NUMBER_FAILURE';
export const FETCH_SUGGESTED_NUMBER = [
  FETCH_SUGGESTED_NUMBER_REQUEST,
  FETCH_SUGGESTED_NUMBER_SUCCESS,
  FETCH_SUGGESTED_NUMBER_FAILURE,
];

export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'UPDATE_FAILURE';
export const UPDATE = [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE];

export const CREATE_REQUEST = 'CREATE_REQUEST';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAILURE = 'CREATE_FAILURE';
export const CREATE = [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE];

export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAILURE = 'DELETE_FAILURE';
export const DELETE = [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE];

export const SORT = 'SORT';
export const SORT_DIRECTION = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
};

export const PAGINATE = 'PAGINATE';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const FETCH_CATEGORIES = [
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
];
