import styled from 'styled-components';

import { Sizes } from './constants';

type CircleProps = {
  $size: 'medium';
  $color: 'red' | 'green';
};

export const Circle = styled.div<CircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ $size }) => Sizes[$size]};
  width: ${({ $size }) => Sizes[$size]};
  border-radius: 50%;
  background: ${({ theme, $color }) => theme.colors[$color]['025']};
  color: ${({ theme, $color }) => theme.colors[$color]['100']};
  line-height: 0;
  font-size: 0;

  > svg {
    width: 65%;
    height: 65%;
  }
`;

Circle.defaultProps = {
  $size: 'medium',
};
