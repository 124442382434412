import React, { useCallback, useState } from 'react';
import CookieModal from '@vr-smart-finanz/cookie-banner';
import cx from 'classnames';

import IconCookie from 'components/Icons/IconCookie';

import styles from './CookieLink.module.css';
import legalsFooterStyles from './LegalsFooter.module.css';

const CookieLink = () => {
  const accepted = localStorage.getItem('cookies') === 'true';
  const preselected = { statistic: localStorage.getItem('cookies:statistic') === 'true' };
  const [show, setShow] = useState(!accepted);

  const handleSave = useCallback((selected: { statistic: boolean }) => {
    localStorage.setItem('cookies', 'true');

    if (selected.statistic) {
      localStorage.setItem('cookies:statistic', 'true');
    } else {
      localStorage.removeItem('cookies:statistic');
    }

    setShow(false);
    window.location.reload();
  }, []);

  return (
    <>
      <button onClick={() => setShow(true)} className={cx(legalsFooterStyles.link, styles.link)}>
        <IconCookie className={styles.icon} /> Cookie-Einstellungen
      </button>
      <CookieModal
        dataProtectionUrl="https://www.vr-smart-guide.de/datenschutz-erklaerung"
        legalNoticeUrl="https://www.vr-smart-guide.de/impressum"
        onSave={handleSave}
        preselected={preselected}
        show={show}
      />
    </>
  );
};

export default CookieLink;
