import { t } from 'shared/utils';

import { Interval } from '../ExpensesRevenues/constants';
import type { RecurringRevenue } from './types';
import { Category } from './types';

export const DefaultRecurringRevenues: RecurringRevenue[] = [Category.Rent].map((category) => ({
  name: t(
    `features.financial_planning.liquidity_calculator.revenues.default_categories.${category}`
  ),
  interval: Interval.Monthly,
  amount: '',
}));

export const NewRevenue = {
  name: '',
  interval: Interval.Monthly,
  amount: '',
};
