import { formatMoneyInput } from 'shared/utils';

export const parseValues = (values: {
  insertedAsGross: boolean;
  netAmount: string;
  grossAmount: string;
}) => {
  const { insertedAsGross } = values;
  const netAmount =
    insertedAsGross && values.netAmount === '' ? formatMoneyInput() : values.netAmount;
  const grossAmount =
    !insertedAsGross && values.grossAmount === '' ? formatMoneyInput() : values.grossAmount;

  return {
    ...values,
    netAmount,
    grossAmount,
  };
};
