import React from 'react';
import { node } from 'prop-types';

import { SearchNavigationContext } from './SearchNavigationContext';

/**
 * If condition (`isSearchOpen` is true) is satisfied it will display
 * inputs used for filtering.
 */
const SearchNavigationInputs = ({ children }) => (
  <SearchNavigationContext.Consumer>
    {({ isSearchOpen }) => (isSearchOpen ? children : null)}
  </SearchNavigationContext.Consumer>
);

SearchNavigationInputs.propTypes = {
  children: node,
};

export default SearchNavigationInputs;
