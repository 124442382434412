import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { t } from 'shared/utils';
import { updateOnboarding } from 'store/slices/onboarding/thunks';

import RawCheckbox from '../Form/RawCheckbox/RawCheckbox';

import styles from './DismissOnboardingCheckbox.module.css';

type DismissOnboardingCheckboxProps = {
  dismissOnboardingKey: string;
  onClose?: () => void;
  onDismiss?: () => void;
  id: string;
  dataIds?: {
    label?: string;
    dismissCheckbox?: string;
    closeButton?: string;
  };
};

const DismissOnboardingCheckbox = ({
  dismissOnboardingKey,
  onClose,
  onDismiss,
  id,
  dataIds,
  ...rest
}: DismissOnboardingCheckboxProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();

  const handleChange = () => setIsChecked((isChecked) => !isChecked);

  const handleClose = () => {
    if (isChecked) {
      isChecked && dispatch(updateOnboarding({ [dismissOnboardingKey]: true }));
      if (onDismiss) onDismiss();
    }

    if (onClose) onClose();
  };

  return (
    <div className={styles.dismissCheckbox}>
      <RawCheckbox
        checkboxId={id}
        dataId={dataIds?.dismissCheckbox}
        checked={isChecked}
        onChange={handleChange}
        {...rest}
      />
      <label htmlFor={id} data-id={dataIds?.label} className={styles.label}>
        {t('form.fields.dismiss_onboarding_tile')}
      </label>
      <button className={styles.close} onClick={handleClose} data-id={dataIds?.closeButton} />
    </div>
  );
};
export default DismissOnboardingCheckbox;
