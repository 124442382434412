import { push, replace } from 'connected-react-router';
import normalize from 'json-api-normalizer';
import { get } from 'lodash';
import { change } from 'redux-form';
import build from 'redux-object';

import { fetchCustomerDefaults } from 'actions/default-values';
import { showNotification } from 'actions/notification';
import { fetchOrderConfirmationLineItems } from 'actions/order-confirmation/line-items';
import { CALL_API } from 'constants/api';
import EntityPath from 'constants/entitiesPaths';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DOWNLOAD_FAILURE,
  DOWNLOAD_REQUEST,
  DOWNLOAD_SUCCESS,
  DUPLICATE_ORDER_CONFIRMATION_FAILURE,
  DUPLICATE_ORDER_CONFIRMATION_REQUEST,
  DUPLICATE_ORDER_CONFIRMATION_SUCCESS,
  FETCH_FAILURE,
  FETCH_LINE_CATEGORIES_FAILURE,
  FETCH_LINE_CATEGORIES_REQUEST,
  FETCH_LINE_CATEGORIES_SUCCESS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FIRST_UPDATE_FAILURE,
  FIRST_UPDATE_REQUEST,
  FIRST_UPDATE_SUCCESS,
  FORM_NAME,
  FROM_DUPLICATE_QUERY_PARAM,
  FROM_ORDER_CONFIRMATION_QUERY_PARAM,
  REORDER_LINE_ITEMS_FAILURE,
  REORDER_LINE_ITEMS_LOCAL,
  REORDER_LINE_ITEMS_REQUEST,
  REORDER_LINE_ITEMS_SUCCESS,
  TOGGLE_GROSS_NET_FAILURE,
  TOGGLE_GROSS_NET_REQUEST,
  TOGGLE_GROSS_NET_SUCCESS,
  TRANSFORM_FAILURE,
  TRANSFORM_REQUEST,
  TRANSFORM_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/order-confirmation';
import {
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
} from 'constants/outgoing-invoice';
import * as Piwik from 'constants/piwik';
import {
  deleteSuccess,
  downloadSuccess,
  downloadSuccessCancelMode,
} from 'notifications/order-confirmation';
import paths from 'routes/paths';
import { downloadData } from 'shared/utils';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { orderConfirmationFilename } from 'shared/utils/file-naming';
import { piwikHelpers } from 'shared/utils/piwik';
import { bindServerValidation } from 'shared/utils/server-validation';

export const apiFetchLineCategories = () => ({
  [CALL_API]: {
    endpoint: '/line_item_categories/invoice_revenues',
    types: [
      FETCH_LINE_CATEGORIES_REQUEST,
      FETCH_LINE_CATEGORIES_SUCCESS,
      FETCH_LINE_CATEGORIES_FAILURE,
    ],
  },
});

const apiFetchOrderConfirmation = (id) => ({
  [CALL_API]: {
    endpoint: `/me/order_confirmations/${id}`,
    method: 'GET',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiCreateOrderConfirmation = (data) => ({
  [CALL_API]: {
    data,
    endpoint: '/me/order_confirmations',
    method: 'POST',
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const apiUpdateOrderConfirmation = (id, data) => ({
  [CALL_API]: {
    data,
    endpoint: `/me/order_confirmations/${id}`,
    method: 'PUT',
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const apiToggleGrossNet = (orderConfirmationId, insertedAsGross) => ({
  [CALL_API]: {
    data: { insertedAsGross },
    method: 'PATCH',
    endpoint: `/me/order_confirmations/${orderConfirmationId}/toggle_inserted_as_gross`,
    types: [TOGGLE_GROSS_NET_REQUEST, TOGGLE_GROSS_NET_SUCCESS, TOGGLE_GROSS_NET_FAILURE],
  },
});

export const apiReorderLineItems = (orderConfirmationId, lineItemsOrder) => ({
  [CALL_API]: {
    data: { lineItemsOrder },
    method: 'PATCH',
    endpoint: `/me/order_confirmations/${orderConfirmationId}/reorder`,
    types: [REORDER_LINE_ITEMS_REQUEST, REORDER_LINE_ITEMS_SUCCESS, REORDER_LINE_ITEMS_FAILURE],
  },
});

export const apiFirstUpdateOrderConfirmation = (id, data) => ({
  [CALL_API]: {
    data,
    endpoint: `/me/order_confirmations/${id}`,
    method: 'PUT',
    types: [FIRST_UPDATE_REQUEST, FIRST_UPDATE_SUCCESS, FIRST_UPDATE_FAILURE],
  },
});

export const apiDownloadOrderConfirmation = (id, values) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: `/me/order_confirmations/${id}/preview`,
    data: { ...values },
    types: [DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE],
    responseType: 'arraybuffer',
  },
});

export const fetchLineCategories = () => (dispatch) =>
  dispatch(apiFetchLineCategories()).catch(apiErrorHandler);

export const fetchOutgoingInvoiceResources = () => (dispatch) =>
  Promise.all([dispatch(fetchLineCategories()), dispatch(fetchCustomerDefaults())]);

export const fetchOrderConfirmation =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchOrderConfirmation(...args))
      .then(() => dispatch(fetchOutgoingInvoiceResources()))
      .catch((err) => {
        apiErrorHandler(err);
        return dispatch(replace(paths.orderConfirmation));
      });

export const createOrderConfirmation = (data) => (dispatch) =>
  dispatch(apiCreateOrderConfirmation(data)).catch((err) => {
    apiErrorHandler(err);
    return dispatch(push(paths.orderConfirmation));
  });

export const createAndFetchNewOrderConfirmation = () => (dispatch) => {
  dispatch(createOrderConfirmation({ draft: true }))
    .then(({ response }) => {
      dispatch(fetchCustomerDefaults());
      return dispatch(fetchOrderConfirmation(response.data.id));
    })
    .catch((err) => {
      apiErrorHandler(err);
      return dispatch(replace(paths.orderConfirmation));
    });
};

export const toggleOrderConfirmationGrossNet = (orderConfirmationId) => (dispatch, getState) => {
  const insertedAsGross = get(getState(), 'orderConfirmation.details.insertedAsGross', false);
  return bindServerValidation(apiToggleGrossNet(orderConfirmationId, !insertedAsGross), dispatch)
    .then(() => dispatch(fetchOrderConfirmation(orderConfirmationId)))
    .then(() => dispatch(fetchOrderConfirmationLineItems(orderConfirmationId)));
};

export const abortChanges =
  (path = paths.orderConfirmation) =>
  (dispatch) =>
    dispatch(push(path));

export const getOrderConfirmationPreview = (id, data) => (dispatch) =>
  dispatch(apiDownloadOrderConfirmation(id, data))
    .then(({ rawResponse }) => rawResponse)
    .catch(apiErrorHandler);

export const downloadOrderConfirmation =
  (
    { id, orderConfirmationNumber } = {},
    values = {},
    isPiwikTracked = false,
    isCancelMode = false
  ) =>
  (dispatch) =>
    dispatch(getOrderConfirmationPreview(id, values))
      .then((response) => {
        downloadData(response, orderConfirmationFilename({ orderConfirmationNumber, id }), 'pdf');

        if (isPiwikTracked) {
          piwikHelpers.trackEvent(
            Piwik.CATEGORY_ORDER_CONFIRMATIONS,
            Piwik.ACTION_ORDER_CONFIRMATION_DOWNLOAD
          );
        }

        return dispatch(
          showNotification(isCancelMode ? downloadSuccessCancelMode : downloadSuccess)
        );
      })
      .catch(apiErrorHandler);

const apiDelete = (orderConfirmation) => ({
  [CALL_API]: {
    endpoint: `/me/order_confirmations/${orderConfirmation.id}`,
    method: 'DELETE',
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const deleteOrderConfirmation = (id) => (dispatch) =>
  dispatch(apiDelete(id))
    .then(() => {
      dispatch(showNotification(deleteSuccess));
    })
    .catch(apiErrorHandler);

const apiTransform = (orderConfirmation) => ({
  [CALL_API]: {
    endpoint: `/me/order_confirmations/${orderConfirmation.id}/transform_into_outgoing_invoice`,
    method: 'POST',
    types: [TRANSFORM_REQUEST, TRANSFORM_SUCCESS, TRANSFORM_FAILURE],
  },
});

export const transformOrderConfirmation = (id) => (dispatch) =>
  dispatch(apiTransform(id)).catch(apiErrorHandler);

export const updateOrderConfirmation =
  (itemid, data, transform = false, redirect = true) =>
  (dispatch) =>
    bindServerValidation(
      data.isNew
        ? apiFirstUpdateOrderConfirmation(itemid, data)
        : apiUpdateOrderConfirmation(itemid, data),
      dispatch,
      { isReduxForm: true },
      { client_id: { id: 'client' } }
    ).then(({ response: { data: { attributes } = {} } = {} }) => {
      if (transform) {
        dispatch(transformOrderConfirmation({ id: itemid })).then(({ data: { id } }) => {
          dispatch(
            replace({
              pathname: paths.editOutgoingInvoice(id),
              search: `?${FROM_ORDER_CONFIRMATION_QUERY_PARAM}`,
            })
          );
        });
      } else if (redirect) {
        dispatch(replace(paths.orderConfirmation));
      }
      return { ...attributes, success: true };
    });

export const apiCreateClient = (data) => ({
  [CALL_API]: {
    data,
    endpoint: '/me/clients',
    method: 'POST',
    types: [CREATE_CLIENT_REQUEST, CREATE_CLIENT_SUCCESS, CREATE_CLIENT_FAILURE],
  },
});

export const quickCreateClient = (formName) => (values) => (dispatch) =>
  bindServerValidation(apiCreateClient(values), dispatch).then(
    (response) => {
      const client = build(normalize(response), EntityPath.Clients, response.data.id);

      dispatch(change(formName, 'client', client));

      if (formName === FORM_NAME) {
        piwikHelpers.trackEvent(
          Piwik.CATEGORY_ORDER_CONFIRMATIONS,
          Piwik.ACTION_CREATE_CLIENT_IN_ORDER_CONFIRMATION_CREATOR,
          Piwik.NAME_CREATE_CLIENT_IN_ORDER_CONFIRMATION_CREATOR_SUCCESS
        );
        piwikHelpers.trackGoal(2);
      }
    },
    (response) => {
      if (formName === FORM_NAME) {
        piwikHelpers.trackEvent(
          Piwik.CATEGORY_ORDER_CONFIRMATIONS,
          Piwik.ACTION_CREATE_CLIENT_IN_ORDER_CONFIRMATION_CREATOR,
          Piwik.NAME_CREATE_CLIENT_IN_ORDER_CONFIRMATION_CREATOR_ERROR
        );
      }
      throw response;
    }
  );

export const reorderLineItems = (orderConfirmationId, reorderedLineItems) => (dispatch) => {
  const lineItemsOrder = reorderedLineItems.map(({ id, ordinalNumber }) => ({
    id: Number(id),
    ordinalNumber: Number(ordinalNumber),
  }));
  dispatch({ type: REORDER_LINE_ITEMS_LOCAL, payload: reorderedLineItems });
  return dispatch(apiReorderLineItems(orderConfirmationId, lineItemsOrder)).catch(apiErrorHandler);
};

const apiDuplicateOrderConfirmation = (orderConfirmationId) => ({
  [CALL_API]: {
    endpoint: `/me/order_confirmations/${orderConfirmationId}/duplicate`,
    method: 'POST',
    types: [
      DUPLICATE_ORDER_CONFIRMATION_REQUEST,
      DUPLICATE_ORDER_CONFIRMATION_SUCCESS,
      DUPLICATE_ORDER_CONFIRMATION_FAILURE,
    ],
  },
});

export const duplicateOrderConfirmation = (orderConfirmationId) => (dispatch) =>
  dispatch(apiDuplicateOrderConfirmation(orderConfirmationId))
    .catch(apiErrorHandler)
    .then(({ data: { id: draftId } }) => {
      dispatch(
        replace({
          pathname: paths.editOrderConfirmation(draftId),
          search: `?${FROM_DUPLICATE_QUERY_PARAM}`,
        })
      );
    });

export const apiCreateCancelledOrderConfirmation = (orderConfirmationId) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: `/me/order_confirmations/${orderConfirmationId}/cancellation`,
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const cancelOrderConfirmation = (orderConfirmationId) => (dispatch) =>
  dispatch(apiCreateCancelledOrderConfirmation(orderConfirmationId))
    .then(({ data }) => data.id)
    .catch((err) => {
      apiErrorHandler(err);
      dispatch(push(paths.orderConfirmation));
    });

export const fetchOrderConfirmationResources = (id) => (dispatch) =>
  Promise.all([
    dispatch(fetchOrderConfirmation(id)),
    dispatch(fetchOrderConfirmationLineItems(id)),
  ]);

export const fetchNewCancelledOrderConfirmation = (invoiceId) => async (dispatch) => {
  const id = await dispatch(cancelOrderConfirmation(invoiceId));
  return dispatch(fetchOrderConfirmationResources(id));
};
