import { t } from 'shared/utils';

export const removeBankAccess = {
  duration: 3000,
  title: t('redesign.organisms.bank_accounts_list.notification.delete_access'),
  variant: 'success',
};

export const removeBankAccount = {
  duration: 3000,
  title: t('redesign.organisms.bank_accounts_list.notification.delete_account'),
  variant: 'success',
};

export const selectBankAccount = {
  duration: 3000,
  title: t('redesign.organisms.bank_accounts_list.notification.select_main_account'),
  variant: 'success',
};
