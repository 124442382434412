import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { toggleReceiveOffers } from 'actions/registration';
import { t } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';
import { Dispatch } from 'types/actions';
import { Checkbox } from 'components/Form';

import styles from './ProductPropositionsCheckbox.module.css';

interface ProductPropositionsCheckboxProps {
  onClickToggle?: (event: MouseEvent) => void;
}

const ProductPropositionsCheckbox = ({ onClickToggle }: ProductPropositionsCheckboxProps) => (
  <Field component={Checkbox} name="receiveOffers" dataIds={{ input: 'checkbox-receive-offers' }}>
    <p>
      {t('registration.form.receive_offers_1')}{' '}
      <span
        role="button"
        onKeyPress={isPressedEnter(onClickToggle)}
        className={styles.highlighted}
        onClick={onClickToggle}
        tabIndex={-1}
        data-id="button-trigger"
      >
        {t('registration.form.receive_offers_2')}
      </span>{' '}
      {t('registration.form.receive_offers_3')}
    </p>
  </Field>
);

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickToggle: (event: MouseEvent): void => {
    event.preventDefault();
    dispatch(toggleReceiveOffers());
  },
});

export default connect<ProductPropositionsCheckboxProps>(
  null,
  mapDispatchToProps
)(ProductPropositionsCheckbox);
