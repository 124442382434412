import styled from 'styled-components';

import BaseIconUploadCloud from 'redesign/components/atoms/Icons/UploadCloud';
import BaseLink from 'redesign/components/atoms/Link/Link';

type WrapperProps = {
  $isDragActive: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  height: 322px;
  max-width: 362px;
  border: 2px dashed
    ${({ theme, $isDragActive }) => theme.colors.vrblue[$isDragActive ? '075' : '100']};
  padding: ${({ theme }) => theme.space['2']};
`;

export const Empty = styled.div`
  display: grid;
  height: 100%;
  align-content: center;
  justify-content: center;
  justify-items: center;
`;

type IconUploadCloudProps = {
  $isDragActive: boolean;
};

export const IconUploadCloud = styled(BaseIconUploadCloud)<IconUploadCloudProps>`
  height: 72px;
  width: 86px;
  color: ${({ theme, $isDragActive }) => theme.colors.vrblue[$isDragActive ? '050' : '025']};
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.grey['080']};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 24px;
  text-align: center;
  margin-top: ${({ theme }) => theme.space['2']};
  margin-bottom: ${({ theme }) => theme.space['4']};
`;

export const Info = styled.p`
  color: ${({ theme }) => theme.colors.grey['050']};
  font-size: ${({ theme }) => theme.fontSize.sm};
  letter-spacing: 0.25px;
  line-height: 17px;
  text-align: center;
  margin-top: ${({ theme }) => theme.space['10']};
  margin-bottom: 0;
`;

export const Cancel = styled(BaseLink)`
  margin-top: ${({ theme }) => theme.space['6']};
  font-size: ${({ theme }) => theme.fontSize['sm']};
  letter-spacing: 0.58px;
`;

export const Delete = styled(BaseLink)`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize['sm']};
  margin-top: ${({ theme }) => theme.space['1']};
  letter-spacing: 0.58px;

  svg {
    width: 24px;
    height: 24px;
    margin-right: ${({ theme }) => theme.space['1']};
  }
`;

export const Preview = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
  }
`;

export const Loading = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;

  p {
    color: ${({ theme }) => theme.colors.grey['080']};
    font-size: ${({ theme }) => theme.fontSize.base};
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 24px;
    text-align: center;
    margin-top: ${({ theme }) => theme.space['2']};
    margin-bottom: 0;
  }
`;
