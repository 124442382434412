import React, { Component } from 'react';
import { bool, func } from 'prop-types';

import { t } from 'shared/utils';
import If from 'components/Conditions/If';
import Modal from 'components/Modal';

import BankAccountsCreator from '../BankAccountsCreator/BankAccountsCreator';

import styles from './BankAccountsCreatorModal.module.css';

class BankAccountsCreatorModal extends Component {
  state = { isLoading: false };

  setLoading = (isLoading) => this.setState({ isLoading });

  render() {
    const { isActive, onClose, hasExternalSteps, onReloadList } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        className={styles.modal}
        shouldCloseOnOverlayClick={false}
        onRequestClose={onClose}
        isOpen={isActive}
      >
        <If ok={isActive}>
          <div className={styles.header} id="bank-account-creator-header">
            <span className={styles.headerText} data-id="BankAccount:bankAccountWizardHeader">
              {t('bank_accounts.creator.add_new_account')}
            </span>
            <button className={styles.cancel} onClick={onClose} id="bank-account-creator-close" />
          </div>
          <BankAccountsCreator
            onFinish={onClose}
            setLoading={this.setLoading}
            isLoading={isLoading}
            hasExternalSteps={hasExternalSteps}
            onReloadList={onReloadList}
          />
        </If>
      </Modal>
    );
  }
}

BankAccountsCreatorModal.propTypes = {
  onClose: func.isRequired,
  onReloadList: func,
  isActive: bool,
  hasExternalSteps: bool,
};

export default BankAccountsCreatorModal;
