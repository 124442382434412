import React, { PureComponent } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as pushAction } from 'connected-react-router';
import head from 'lodash/head';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import {
  BANK_ACCOUNT,
  BASIC_UID,
  BOOK_PREMIUM,
  CONFIRMATION,
  DIRECT_DEBIT,
  PREMIUM_UID,
  SWTICH_TO_BASIC,
} from 'constants/payment-plans';
import paths from 'routes/paths';

import BankAccountCreatorWrapper from '.././BankAccountCreatorWrapper/BankAccountCreatorWrapper';
import BookPremium from '.././BookPremium/BookPremium';
import LegalsDescription from '.././components/LegalsDescription/LegalsDescription';
import PriceContainer from '.././components/PriceContainer/PriceContainer';
import Confirmation from '.././Confirmation/Confirmation';
import DirectDebit from '.././DirectDebit/DirectDebit';
import SwitchToBasic from '.././SwitchToBasic/SwitchToBasic';

import styles from './ModalsLayout.module.css';

const renderModal = (props) => {
  const {
    paymentsBankAccounts,
    onClose,
    modalType,
    setModalToRender,
    shouldInitiallySelectMainBankAccount,
    push,
    currentPaymentPlanDetails,
    firstMountedModalType,
    isPaymentsBankAccountsFetching,
    closePaymentPlanWizard = () => {},
  } = props;

  const commonProps = {
    currentPaymentPlanDetails,
    paymentsBankAccounts,
    onClose,
    shouldInitiallySelectMainBankAccount,
    addBankAccount: () => setModalToRender(BANK_ACCOUNT),
  };

  switch (modalType) {
    case DIRECT_DEBIT:
      return (
        <DirectDebit
          {...commonProps}
          onClose={() => setModalToRender(BOOK_PREMIUM)}
          onSubmit={onClose}
          confirmDirectDebit={() => setModalToRender(CONFIRMATION)}
        />
      );
    case SWTICH_TO_BASIC:
      return (
        <SwitchToBasic {...commonProps} confirmDowngrade={() => setModalToRender(CONFIRMATION)} />
      );
    case BOOK_PREMIUM:
      return (
        <BookPremium
          {...commonProps}
          isPaymentsBankAccountsFetching={isPaymentsBankAccountsFetching}
          moveForward={() => setModalToRender(DIRECT_DEBIT)}
        />
      );
    case BANK_ACCOUNT:
      return <BankAccountCreatorWrapper finishCreation={() => setModalToRender(BOOK_PREMIUM)} />;
    default:
      return (
        <Confirmation
          firstMountedModalType={firstMountedModalType}
          onTrial={currentPaymentPlanDetails.onTrial}
          currentPlanEndDate={currentPaymentPlanDetails.currentPlanEndDate}
          redirectToPlanAdmin={() => {
            push(paths.paymentPlan);
            closePaymentPlanWizard();
            onClose();
          }}
          redirectToHomepage={() => {
            push(paths.home);
            closePaymentPlanWizard();
          }}
        />
      );
  }
};

renderModal.propTypes = {
  paymentsBankAccounts: shape({}),
  onClose: func.isRequired,
  modalType: string.isRequired,
  setModalToRender: func.isRequired,
  shouldInitiallySelectMainBankAccount: bool,
  push: func.isRequired,
  closePaymentPlanWizard: func,
  currentPaymentPlanDetails: shape({
    uid: string,
    onTrial: bool,
  }),
  firstMountedModalType: string.isRequired,
  isPaymentsBankAccountsFetching: bool,
};

const containerVariations = {
  [BOOK_PREMIUM]: PREMIUM_UID,
  [SWTICH_TO_BASIC]: BASIC_UID,
};

class ModalsLayout extends PureComponent {
  state = {
    firstMountedModalType: this.props.modalType,
  };

  get planDetails() {
    const { plans } = this.props;
    const { firstMountedModalType } = this.state;

    return (
      head(plans.filter((plan) => plan.uid === containerVariations[firstMountedModalType])) || {}
    );
  }

  render() {
    const { firstMountedModalType } = this.state;

    return (
      <Grid className={cx(styles.wrapper, styles.fullHeight)}>
        <Row className={styles.fullHeight} center="xs">
          <Col xs={12} lg={6}>
            {renderModal({ ...this.props, firstMountedModalType })}
          </Col>
          <LegalsDescription />
          <div className={styles.priceContainer}>
            <PriceContainer
              plan={containerVariations[firstMountedModalType]}
              vrMainBankAccount={this.props.currentPaymentPlanDetails.vrMainBankAccount}
              planDetails={this.planDetails}
            />
          </div>
        </Row>
      </Grid>
    );
  }
}

ModalsLayout.propTypes = {
  modalType: string.isRequired,
  plans: arrayOf(
    shape({
      price: number.isRequired,
    }).isRequired
  ).isRequired,
  currentPaymentPlanDetails: shape({
    uid: string.isRequired,
    onTrial: bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  currentPaymentPlanDetails: state.paymentPlan.details,
});

const mapDispatchToProps = (dispatch) => ({
  push: (...args) => dispatch(pushAction(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalsLayout);
