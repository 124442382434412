import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';

import { farmpilotEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import IconSettings from 'redesign/components/atoms/Icons/Settings';

import { trackEvent, trackEventHandler } from '../utils';
import { Arrow, Link, MenuItem } from './Shared.styled';
import { getMatches } from './utils';

type SettingsProps = {
  pathname: string;
  isNavOpen: boolean;
};

const URLS = {
  personal: paths.personalProfile,
  company: paths.companyProfile,
  taxConsultant: paths.taxConsultantProfile,
  bankAccounts: paths.bankAccounts,
  settingsTemplateLogoAndBriefpapier: paths.settingsTemplateLogoAndBriefpapier,
  settingsTemplateLayout: paths.settingsTemplateLayout,
  settingsTemplateTable: paths.settingsTemplateTable,
  settingsTemplateEdit: paths.settingsTemplateEdit,
  settingsTemplateDocumentsDefaults: paths.settingsTemplateDocumentsDefaults,
  settingsTemplateEmailsDefaults: paths.settingsTemplateEmailDefaults,
  numberRanges: paths.numberRanges,
  paymentPlan: paths.paymentPlan,
  farmpilot: paths.farmpilot,
};

const Settings = ({ pathname, isNavOpen }: SettingsProps) => {
  const matches = getMatches(pathname, URLS);
  const isActive = Object.values(matches).some((match) => match);
  const [isOpen, setOpen] = useState(isActive);
  const isDocumentTemplateActive = [
    matches.settingsTemplateLogoAndBriefpapier,
    matches.settingsTemplateLayout,
    matches.settingsTemplateTable,
    matches.settingsTemplateEdit,
    matches.settingsTemplateDocumentsDefaults,
    matches.settingsTemplateEmailsDefaults,
  ].some((match) => match);
  const areExtensionsActive = matches.farmpilot;
  const [areExtensionsOpen, setExtensionsOpen] = useState(areExtensionsActive);
  const isFarmpilotEnabled = useSelector(farmpilotEnabledHelper);

  const handleToggle = useCallback(() => {
    trackEvent(`settings_${!isOpen ? 'open' : 'close'}`);
    setOpen((isOpen) => !isOpen);
  }, [isOpen]);

  const handleExtensionsToggle = useCallback(() => {
    trackEvent(`settings_addons_${!areExtensionsOpen ? 'open' : 'close'}`);
    setExtensionsOpen((areExtensionsOpen) => !areExtensionsOpen);
  }, [areExtensionsOpen]);

  useUpdateEffect(() => {
    setOpen(isActive);
    setExtensionsOpen(areExtensionsActive);
  }, [pathname, isNavOpen]);

  return (
    <MenuItem $isActive={isActive} $isOpen={isOpen}>
      <Link $level={1} as="span" $isActive={isActive} onClick={handleToggle} data-id="settings">
        <IconSettings />
        {t('redesign.organisms.navigation.settings')}
        <Arrow $isOpen={isOpen} />
      </Link>

      <ul>
        <li>
          <Link
            $level={2}
            to={paths.personalProfile}
            $isActive={matches.personal}
            data-id="personal"
            onClick={trackEventHandler('settings_user-profile')}
          >
            {t('redesign.organisms.navigation.personal')}
          </Link>
        </li>
        <li>
          <Link
            $level={2}
            to={paths.companyProfile}
            $isActive={matches.company}
            data-id="company"
            onClick={trackEventHandler('settings_company-data')}
          >
            {t('redesign.organisms.navigation.company')}
          </Link>
        </li>
        <li>
          <Link
            $level={2}
            to={paths.taxConsultantProfile}
            $isActive={matches.taxConsultant}
            data-id="tax-consultant"
            onClick={trackEventHandler('settings_tax-consultant')}
          >
            {t('redesign.organisms.navigation.tax_consultant')}
          </Link>
        </li>
        <li>
          <Link
            $level={2}
            to={paths.bankAccounts}
            $isActive={matches.bankAccounts}
            data-id="bank-accounts"
            onClick={trackEventHandler('settings_bank-accounts')}
          >
            {t('redesign.organisms.navigation.bank_accounts')}
          </Link>
        </li>
        <li>
          <Link
            $level={2}
            to={paths.settingsTemplateLogoAndBriefpapier}
            $isActive={isDocumentTemplateActive}
            onClick={trackEventHandler('settings_document-template')}
            data-id="document-template"
          >
            {t('redesign.organisms.navigation.document_template')}
          </Link>
        </li>
        <li>
          <Link
            $level={2}
            to={paths.numberRanges}
            $isActive={matches.numberRanges}
            data-id="number-ranges"
            onClick={trackEventHandler('settings_number-range')}
          >
            {t('redesign.organisms.navigation.number_ranges')}
          </Link>
        </li>
        <li>
          <Link
            $level={2}
            to={paths.paymentPlan}
            $isActive={matches.paymentPlan}
            data-id="payment-plan"
            onClick={trackEventHandler('settings_payment-plan')}
          >
            {t('redesign.organisms.navigation.payment_plan')}
          </Link>
        </li>
        {isFarmpilotEnabled && (
          <MenuItem $isActive={isActive || areExtensionsActive} $isOpen={areExtensionsOpen}>
            <Link
              $level={2}
              as="span"
              $isActive={areExtensionsActive}
              onClick={handleExtensionsToggle}
              $withIcon
              data-id="extensions"
            >
              {t('redesign.organisms.navigation.extensions')}
              <Arrow $isOpen={areExtensionsOpen} />
            </Link>

            <ul>
              <li>
                <Link
                  $level={3}
                  to={paths.farmpilot}
                  $isActive={matches.farmpilot}
                  data-id="farmpilot"
                  onClick={trackEventHandler('settings_addons_farmpilot')}
                >
                  {t('redesign.organisms.navigation.farmpilot')}
                </Link>
              </li>
            </ul>
          </MenuItem>
        )}
      </ul>
    </MenuItem>
  );
};

export default memo(Settings);
