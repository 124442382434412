import React from 'react';
import cx from 'classnames';
import { bool, node, string } from 'prop-types';

import styles from './WithCurrencySign.module.css';

const WithCurrencySign = ({ children, className, iconClassName = '', isSignVisible }) => (
  <div className={cx(styles.wrapper, className)}>
    {children}
    {isSignVisible && <span className={(cx(styles.icon), iconClassName)}>€</span>}
  </div>
);

WithCurrencySign.propTypes = {
  className: string,
  children: node.isRequired,
  iconClassName: string,
  isSignVisible: bool,
};

export default WithCurrencySign;
