import React, { memo, SVGProps } from 'react';

const BankAccounts = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 241 126" {...props}>
    <defs>
      <filter
        x="-28.1%"
        y="-25.9%"
        width="156.1%"
        height="186.5%"
        filterUnits="objectBoundingBox"
        id="bankaccounts_svg__c"
      >
        <feOffset dy={3} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-28.1%"
        y="-25.9%"
        width="156.1%"
        height="186.5%"
        filterUnits="objectBoundingBox"
        id="bankaccounts_svg__e"
      >
        <feOffset dy={3} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-28.1%"
        y="-25.9%"
        width="156.1%"
        height="186.5%"
        filterUnits="objectBoundingBox"
        id="bankaccounts_svg__g"
      >
        <feOffset dy={3} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-57%"
        y="-40%"
        width="214%"
        height="188%"
        filterUnits="objectBoundingBox"
        id="bankaccounts_svg__i"
      >
        <feMorphology
          radius={0.703}
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        />
        <feOffset dy={1} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={1.5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <rect id="bankaccounts_svg__d" x={0} y={0} width={26.719} height={17.345} rx={2.637} />
      <rect id="bankaccounts_svg__f" x={0} y={0} width={26.719} height={17.345} rx={2.637} />
      <rect id="bankaccounts_svg__h" x={0} y={0} width={26.719} height={17.345} rx={2.637} />
      <linearGradient x1="53.818%" y1="16.215%" x2="36.66%" y2="129.468%" id="bankaccounts_svg__a">
        <stop stopColor="#FFB900" offset="0%" />
        <stop stopColor="#F76B1C" offset="100%" />
      </linearGradient>
      <linearGradient x1="90.229%" y1="72.879%" x2="18.674%" y2="29.66%" id="bankaccounts_svg__b">
        <stop stopColor="#0066B3" offset="0%" />
        <stop stopColor="#00B9FF" offset="100%" />
      </linearGradient>
      <path
        d="M12.33 5.17l5.383 10.767h-3.68v3.176h-3.077v-3.176h-4.01L12.33 5.171z"
        id="bankaccounts_svg__j"
      />
    </defs>
    <g fill="none" fillRule="evenodd" opacity={0.75}>
      <path
        d="M50.327 44.176c.013-.252.02-.506.02-.762 0-7.988-6.636-14.464-14.822-14.464-1.404 0-2.761.19-4.048.546a10.738 10.738 0 00-7.37-2.899c-3.927 0-7.355 2.078-9.198 5.167-.993-.299-2.048-.46-3.142-.46-5.876 0-10.639 4.647-10.639 10.38 0 .86.11 1.693.311 2.492h48.888z"
        fill="#EEF2F6"
      />
      <g transform="rotate(10 -308.818 108.554)">
        <rect
          stroke="#58595A"
          strokeWidth={0.703}
          fill="#FAFAFA"
          x={0.691}
          y={0.21}
          width={62.143}
          height={41.088}
          rx={4.219}
        />
        <rect
          fill="url(#bankaccounts_svg__a)"
          x={4.621}
          y={10.803}
          width={13.205}
          height={12.404}
          rx={4.219}
        />
        <path
          fill="#D8D8D8"
          d="M21.17 14.624h25.634v2.326H21.17zM20.486 19.791h37.286v2.326H20.486z"
        />
        <path fill="#58595A" d="M.621 31.89h62.143v1H.621z" />
      </g>
      <path
        d="M232.565 52.613c.031-.595.048-1.194.048-1.796 0-18.852-15.642-34.136-34.939-34.136-3.308 0-6.508.45-9.541 1.29-4.507-4.235-10.626-6.842-17.373-6.842-9.256 0-17.335 4.904-21.68 12.194a25.61 25.61 0 00-7.407-1.088c-13.85 0-25.076 10.968-25.076 24.498 0 2.028.257 3.996.733 5.88h115.235z"
        fill="#EEF2F6"
      />
      <g transform="translate(36.285 20.27)">
        <rect fill="#0F2364" x={18.984} width={136.406} height={87.188} rx={5.625} />
        <path fill="url(#bankaccounts_svg__b)" d="M21.094 5.625h132.891v78.047H21.094z" />
        <path
          d="M0 80.86h171.563a8.437 8.437 0 01-8.438 8.437H8.437A8.437 8.437 0 010 80.859z"
          fill="#0F2364"
        />
        <path
          d="M62.557 20.341L91.12 36.017a2.11 2.11 0 01-1.015 3.959h-2.78v22.981h1.33a2.11 2.11 0 012.11 2.11v.656l-.002.02h1.013a1.741 1.741 0 110 3.482H31.272a1.741 1.741 0 110-3.482l1.7.001v-.677a2.11 2.11 0 012.109-2.11h.642V39.975H33.5a2.11 2.11 0 01-1.03-3.95l28.043-15.676a2.11 2.11 0 012.044-.008zm16.51 42.616V39.975h-6.192v22.982h6.192zM64.62 39.975h-6.192v22.982h6.192V39.975zm-14.448 0H43.98v22.982h6.192V39.975z"
          stroke="#FFF"
          strokeWidth={2.109}
        />
        <g transform="translate(109.688 35.783)">
          <use fill="#000" filter="url(#bankaccounts_svg__c)" xlinkHref="#bankaccounts_svg__d" />
          <use fill="#FAFAFA" xlinkHref="#bankaccounts_svg__d" />
        </g>
        <g fill="#1D1D1B" fillRule="nonzero">
          <path d="M119.344 41.565v3.945c0 .351.017.559.034.766h-.017c-.084-.24-.202-.43-.353-.702l-2.47-4.01h-1.125v5.767h.874V43.4c0-.35-.017-.558-.034-.766h.017c.084.24.201.431.353.703l2.452 4.009h1.11v-5.782h-.84zM129.023 43.523c-.654 0-1.125.314-1.353.768 0-1.097.365-2.037 1.247-2.037.562 0 .775.33.897.878l.806-.173c-.198-.94-.776-1.394-1.703-1.394-1.171 0-2.053.924-2.053 3.008 0 1.85.73 2.774 1.992 2.774 1.05 0 1.825-.737 1.825-1.943 0-.94-.487-1.88-1.658-1.88zm-.167 3.15c-.654 0-1.095-.611-1.171-1.426.198-.69.639-1.034 1.156-1.034.669 0 1.034.501 1.034 1.222 0 .768-.426 1.238-1.019 1.238zM122.331 46.588c.43-.3 1.05-.79 1.336-1.058.318-.316 1.209-1.106 1.209-2.243 0-1.185-.78-1.722-1.813-1.722-1.273 0-1.893.774-1.893 1.659 0 .142.016.284.064.458l.875.079a1.968 1.968 0 01-.064-.49c0-.664.445-.98 1.002-.98.54 0 .938.3.938 1.027 0 .727-.366 1.232-.906 1.785-.573.57-1.495 1.248-1.94 1.564v.68h3.816v-.743h-2.624v-.016zM120.184 49.274v.963h-4.771v-.963h4.771zM120.184 39.638v.963h-4.771v-.963z" />
        </g>
        <g transform="translate(109.688 12.656)">
          <use fill="#000" filter="url(#bankaccounts_svg__e)" xlinkHref="#bankaccounts_svg__f" />
          <use fill="#FAFAFA" xlinkHref="#bankaccounts_svg__f" />
        </g>
        <path
          d="M128.772 25.183v-1.37h-4.324c-.121.63-.216 1.165-.256 1.37h4.58zM117.321 23.813v1.37h4.58l-.31-1.37z"
          fill="#F60"
        />
        <path
          d="M119.587 20.602l-.341-1.388h2.866l.942 4.085.6-2.643c.3-1.16.41-1.429 2.061-1.429h1.188s-.246 1.065-.546 2.225c-.11.445-1.106.39-1.065-.027 0-.04.055-.35.082-.58.054-.324-.328-.364-.396-.04-.054.229-.273 1.361-.478 2.413h4.272v-5.744h-11.45v5.73h4.176l-.6-2.602h-1.311z"
          fill="#0066B3"
        />
        <g>
          <g transform="translate(109.688 58.91)">
            <use fill="#000" filter="url(#bankaccounts_svg__g)" xlinkHref="#bankaccounts_svg__h" />
            <use fill="#FAFAFA" xlinkHref="#bankaccounts_svg__h" />
          </g>
          <g fillRule="nonzero">
            <path
              d="M122.2 71.437l.17-1.138-.378-.01h-1.808l1.257-8.396a.105.105 0 01.102-.092h3.048c1.012 0 1.71.222 2.075.66.171.206.28.42.333.657.055.248.056.544.002.905l-.004.027v.231l.17.102c.131.07.249.164.347.279.146.175.24.399.28.663.042.273.028.597-.04.964a3.51 3.51 0 01-.374 1.09c-.15.27-.352.503-.593.685a2.35 2.35 0 01-.799.38 3.79 3.79 0 01-.998.122h-.237a.698.698 0 00-.464.18.768.768 0 00-.242.455l-.018.102-.3 2.006-.014.073c-.003.024-.01.035-.018.043a.049.049 0 01-.032.012h-1.464z"
              fill="#253B80"
            />
            <path
              d="M126.947 64.692c-.01.061-.02.124-.033.188-.426 2.175-1.884 2.926-3.746 2.926h-.949a.46.46 0 00-.455.388l-.485 3.06-.138.868a.24.24 0 00.24.279h1.681c.2 0 .369-.144.4-.34l.017-.084.316-1.998.02-.11c.032-.195.201-.339.4-.339h.252c1.629 0 2.904-.657 3.277-2.56.156-.795.075-1.459-.337-1.926a1.607 1.607 0 00-.46-.352z"
              fill="#179BD7"
            />
            <path
              d="M126.453 63.881a2.885 2.885 0 00-.381-.092 4.47 4.47 0 00-.77-.06h-2.331c-.184 0-.34.144-.368.341l-.496 3.413-.014.1c.032-.225.21-.39.419-.39h.872c1.715 0 3.057-.757 3.45-2.945l.03-.189a2.005 2.005 0 00-.41-.178z"
              fill="#222D65"
            />
            <path
              d="M122.396 64.566c.029-.205.19-.357.381-.356h2.421c.287 0 .555.02.799.064.165.028.328.07.487.126.12.044.232.095.335.155.122-.843 0-1.417-.418-1.937-.461-.572-1.293-.817-2.356-.817h-3.09c-.216 0-.402.172-.435.407l-1.287 8.894a.308.308 0 00.06.233c.05.065.124.102.202.102h1.907l.479-3.313.515-3.558z"
              fill="#253B80"
            />
          </g>
        </g>
        <g>
          <path d="M125.86 11.25h24.608v24.609H125.86z" />
          <g strokeLinecap="round" strokeLinejoin="round" transform="rotate(-30 96.252 -217.09)">
            <use fill="#000" filter="url(#bankaccounts_svg__i)" xlinkHref="#bankaccounts_svg__j" />
            <use stroke="#000" strokeWidth={1.406} fill="#FFF" xlinkHref="#bankaccounts_svg__j" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default memo(BankAccounts);
