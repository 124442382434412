import React from 'react';
import snakeCase from 'lodash/snakeCase';
import { shape, string } from 'prop-types';

import { t } from 'shared/utils';

import styles from './StatusOptions.module.css';

const StatusOptions = ({ selectedPaymentOption, amounts, dataId = '' }) => {
  const { displayAmount, amount } = amounts[selectedPaymentOption] || {};
  if (!amount) return null;
  const amountLeftTranslation = t(
    `bank_transfers.statuses_options.${snakeCase(selectedPaymentOption)}`
  );

  return (
    <div
      data-id={dataId}
      className={styles.statusInfo}
    >{`${amountLeftTranslation} ${displayAmount}`}</div>
  );
};

StatusOptions.propTypes = {
  selectedPaymentOption: string,
  amounts: shape({}),
  dataId: string,
};

export default StatusOptions;
