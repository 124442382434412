import { isEmpty, kebabCase } from 'lodash';

export const paginationParams = (pagination = {}) => ({
  page: pagination.page || 1,
  ...(pagination.pagination_resource &&
    !isEmpty(pagination.pagination_resource) && {
      pagination_resource: pagination.pagination_resource,
    }),
});

export const sortingParams = (sorting = {}) => {
  if (!sorting.column && !sorting.value) {
    return { sort: '' };
  }
  const direction = sorting.direction === 'DESC' ? '-' : '';
  const column = sorting.column ? kebabCase(sorting.column) : '';

  return { sort: `${direction}${column}` };
};

export default { paginationParams, sortingParams };
