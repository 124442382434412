import styled from 'styled-components';

import IconTip from 'components/Icons/IconTip';
import Modal from 'redesign/components/molecules/Modal/Modal';

export const Wrapper = styled(Modal)`
  line-height: ${({ theme }) => theme.space['6']};
  text-align: center;
`;

export const TipIcon = styled(IconTip)`
  height: 102px;
  width: 102px;
  padding: ${({ theme }) => theme.space['5']};
  margin: ${({ theme }) => `${theme.space['12']} auto ${theme.space['6']} auto`};
  background-color: ${({ theme }) => theme.colors.yellow['025']};
  border-radius: 100%;
  fill: ${({ theme }) => theme.colors.vrorange['100']};
`;
