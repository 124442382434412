import { get } from 'lodash';

import { FETCH_SUCCESS } from 'constants/incoming-invoice';
import { mapSupplier } from 'reducers/suppliers';

export const extractPersistedSupplier = (id, type, included) => {
  if (!id || !type || !included) return null;

  const includedSupplier = included.find((item) => item.type === type && item.id === id);

  return includedSupplier ? mapSupplier(includedSupplier) : null;
};

export const persistedSupplier = (state = null, action) => {
  if (action.type !== FETCH_SUCCESS) return state;

  const { id, type } = get(action, 'response.data.relationships.persisted-supplier.data') || {};
  const included = get(action, 'response.included', []);

  return extractPersistedSupplier(id, type, included);
};
