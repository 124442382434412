import React, { Component } from 'react';
import InlineSvg from 'react-inlinesvg';
import { bool, node, number, string } from 'prop-types';

import zoomInIcon from 'images/zoom-in.svg';
import zoomOutIcon from 'images/zoom-out.svg';
import isPressedEnter from 'shared/utils/keyboard-events';

import styles from './ResizableContainer.module.css';

export const ResizeBase = {
  WIDTH: 'width',
  HEIGHT: 'height',
};

class ResizableContainer extends Component {
  state = {
    width: this.props.initialWidth,
    height: this.props.initialHeight,
    aspectRatio: this.props.initialWidth / this.props.initialHeight,
  };

  get calculatedZoomIn() {
    const { step, keepAspectRatio, resizeBase } = this.props;
    const { width, height, aspectRatio } = this.state;
    if (!keepAspectRatio) return { width: width + step, height: height + step };
    return resizeBase === ResizeBase.WIDTH
      ? { width: width + step, height: (width + step) / aspectRatio }
      : { width: (height + step) * aspectRatio, height: height + step };
  }

  get calculatedZoomOut() {
    const { step, keepAspectRatio, resizeBase } = this.props;
    const { width, height, aspectRatio } = this.state;
    if (!keepAspectRatio) return { width: width - step, height: height - step };
    return resizeBase === ResizeBase.WIDTH
      ? { width: width - step, height: (width - step) / aspectRatio }
      : { width: (height - step) * aspectRatio, height: height - step };
  }

  get canZoomIn() {
    const { maxWidth, maxHeight } = this.props;
    const { width, height } = this.calculatedZoomIn;
    return width <= maxWidth && height <= maxHeight;
  }

  get canZoomOut() {
    const { minWidth, minHeight } = this.props;
    const { width, height } = this.calculatedZoomOut;
    return width >= minWidth && height >= minHeight;
  }

  zoomIn = () => {
    if (!this.canZoomIn) return;
    const { width, height } = this.calculatedZoomIn;

    this.setState({ width, height });
  };

  zoomOut = () => {
    if (!this.canZoomOut) return;
    const { width, height } = this.calculatedZoomOut;

    this.setState({ width, height });
  };

  render() {
    const { children } = this.props;
    const { width, height } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.childWrapper}>{children({ width, height })}</div>
        <div className={styles.buttons}>
          <div
            className={styles.icon}
            onKeyPress={isPressedEnter(this.zoomIn)}
            onClick={this.zoomIn}
            role="button"
          >
            <InlineSvg src={zoomInIcon} />
          </div>
          <div
            className={styles.icon}
            onKeyPress={isPressedEnter(this.zoomOut)}
            onClick={this.zoomOut}
            role="button"
          >
            <InlineSvg
              className={styles.icon}
              src={zoomOutIcon}
              onKeyPress={isPressedEnter(this.zoomOut)}
              onClick={this.zoomOut}
              role="button"
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
}

ResizableContainer.propTypes = {
  initialWidth: number.isRequired,
  initialHeight: number.isRequired,
  minWidth: number.isRequired,
  minHeight: number.isRequired,
  maxWidth: number.isRequired,
  maxHeight: number.isRequired,
  step: number.isRequired,
  keepAspectRatio: bool,
  resizeBase: string,
  children: node.isRequired,
};

export default ResizableContainer;
