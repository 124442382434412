import styled from 'styled-components';

import BaseIconArrowDown from 'components/Icons/IconArrowDown';
import BaseIconTip from 'redesign/components/atoms/Icons/Tip';
import { Breakpoints } from 'redesign/styles/breakpoints';

export const Container = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.space['5']};
  background-color: ${({ theme }) => theme.colors.lightblue};
  margin-bottom: ${({ theme }) => theme.space['6']};
  flex-direction: column;
`;

export const Header = styled.div`
  flex: 1;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export const HeaderMain = styled.span`
  font-weight: bold;
  padding-right: ${({ theme }) => theme.space['2']};
`;

export const HeaderSupport = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

export const CollapsableSection = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.lg}) {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.lightblue};
    padding-top: ${({ theme }) => theme.space['5']};
    padding-left: ${({ theme }) => theme.space['10']};
    padding-right: ${({ theme }) => theme.space['18']};
  }
`;

export const IconContainer = styled.div`
  width: 12px;
`;

type IconArrowProps = {
  $isOpen: boolean;
};

export const IconArrowDown = styled(BaseIconArrowDown)<IconArrowProps>`
  transition: transform 0.3s ease;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  ${({ $isOpen }) => $isOpen && 'transform: rotate(180deg)'};
`;

export const InfoHeadline = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding-top: ${({ theme }) => theme.space['6']};
  padding-left: ${({ theme }) => theme.space['10']};
`;

export const TipIcon = styled(BaseIconTip)`
  color: white;
`;

export const TipIconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.vrorange['075']};
  border-radius: 25px;
  height: 30px;
  width: 30px;
  padding: ${({ theme }) => theme.space['1']};
  margin-right: ${({ theme }) => theme.space['3']};
`;
