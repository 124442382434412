import React, { ComponentType } from 'react';
import cx from 'classnames';

import Badge from 'redesign/components/atoms/Badge/Badge';

import styles from './BadgeIcon.module.css';

const bigNumberBreakpoint = 99;

type BadgeIconProps = {
  icon: ComponentType<{ className: string; role: string }>;
  count: number;
};

const BadgeIcon = ({ icon: Icon, count }: BadgeIconProps) => (
  <div className={styles.container}>
    <Icon className={styles.icon} role="presentation" />
    {count ? (
      <Badge className={cx(styles.badge, { [styles.bigNumberBadge]: count > bigNumberBreakpoint })}>
        {count}
      </Badge>
    ) : null}
  </div>
);

export default BadgeIcon;
