import { t } from 'shared/utils';

export const itemSaveSuccess = {
  duration: 3000,
  title: t('notifications.product_catalog_item.item_save_success'),
  variant: 'success',
};

export const itemEditSuccess = {
  duration: 3000,
  title: t('notifications.product_catalog_item.item_edit_success'),
  variant: 'success',
};
