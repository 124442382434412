import styled from 'styled-components';

import BaseBox from 'redesign/components/atoms/Box/Box';

export const Wrapper = styled.section`
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const Box = styled(BaseBox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;
