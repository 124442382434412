import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import honorifics from 'constants/honorifics';
import { shape as countriesShape } from 'reducers/countries';
import { clientQuickCreatorSelector } from 'reducers/form';
import { withCountries } from 'shared/hoc';
import styles from 'shared/styles/client_creator.module.css';
import { noop, t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import SelectField from 'components/Form/SelectField';
import TextField from 'components/Form/TextField';

const ClientQuickCreator = ({
  onCancel,
  handleSubmit,
  onSubmit,
  submitting,
  countries,
  isHonorificRequired,
  isLastNameRequired,
  isCompanyNameRequired,
}) => (
  <div className={styles.root}>
    <div className={styles.header}>{t('revenue.form.client_select.quick_creator.header')}</div>
    <p className={styles.info}>{t('revenue.form.client_select.quick_creator.info')}</p>
    <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="companyName"
        component={TextField}
        className={styles.wrapper}
        label={t('revenue.form.client_select.quick_creator.company_name')}
        checker={checker}
        required={isCompanyNameRequired}
      />
      <Field
        name="addressAddition"
        component={TextField}
        className={styles.wrapper}
        label={t('form.fields.address_addition')}
        placeholder={t('form.fields.placeholders.address_addition')}
      />
      <Field
        name="honorific"
        component={SelectField}
        className={styles.wrapper}
        label={t('revenue.form.client_select.quick_creator.honorific')}
        options={honorifics.map((h) => ({ value: h, label: h }))}
        checker={checker}
        clearable
        required={isHonorificRequired}
      />
      <Field
        name="firstName"
        label={t('revenue.form.client_select.quick_creator.first_name')}
        component={TextField}
        className={styles.wrapper}
        checker={checker}
      />
      <Field
        name="lastName"
        label={t('revenue.form.client_select.quick_creator.last_name')}
        component={TextField}
        className={styles.wrapper}
        checker={checker}
        required={isLastNameRequired}
      />
      <Field
        name="idNumber"
        label={t('revenue.form.client_select.quick_creator.client_number')}
        component={TextField}
        className={styles.wrapper}
        checker={checker}
        disabled
        disabledPlaceHolder={t('revenue.form.number_disabled')}
      />
      <Field
        name="street"
        label={t('revenue.form.client_select.quick_creator.street')}
        component={TextField}
        className={styles.wrapper}
        checker={checker}
        required
      />
      <Field
        name="zipcode"
        label={t('revenue.form.client_select.quick_creator.zip_code')}
        component={TextField}
        maxLength="15"
        className={styles.wrapper}
        checker={checker}
        required
      />
      <Field
        name="city"
        label={t('revenue.form.client_select.quick_creator.city')}
        component={TextField}
        className={styles.wrapper}
        checker={checker}
        required
      />
      <Field
        name="country"
        label={t('expenses.form.supplier_select.quick_creator.country')}
        component={SelectField}
        options={countries.data}
        isLoading={countries.isFetching}
        onBlur={noop}
        required
        searchable
      />
      <div className={styles.buttons}>
        <button type="button" onClick={onCancel} className={styles.cancelButton}>
          {t('revenue.form.client_select.quick_creator.abort')}
        </button>
        <button
          type="button"
          className={styles.submitButton}
          onClick={handleSubmit(onSubmit)}
          disabled={submitting}
        >
          {t('revenue.form.client_select.quick_creator.submit')}
        </button>
      </div>
    </form>
  </div>
);

ClientQuickCreator.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  isHonorificRequired: PropTypes.bool.isRequired,
  isLastNameRequired: PropTypes.bool.isRequired,
  isCompanyNameRequired: PropTypes.bool.isRequired,
  countries: countriesShape,
};

const ReduxForm = reduxForm({
  form: 'clientQuickCreator',
  persistentSubmitErrors: true, // allows submitting even if errors are present
  initialValues: {
    country: 'DE',
  },
})(withCountries(ClientQuickCreator));

export default connect((state) => ({
  isHonorificRequired: clientQuickCreatorSelector(state, 'lastName'),
  isLastNameRequired: !clientQuickCreatorSelector(state, 'companyName'),
  isCompanyNameRequired: !clientQuickCreatorSelector(state, 'lastName'),
}))(ReduxForm);
