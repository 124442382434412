import React from 'react';
import { connect } from 'react-redux';
import { func, shape } from 'prop-types';

import { setQueryParam as setQueryParamAction } from 'actions/bank-transfer';
import { invalidRangeChecker, t } from 'shared/utils';
import { dateFilterMapper } from 'shared/utils/filters/dateFilterMapper';
import { Search, SearchDateGroup, SearchGroup } from 'components/Search';

const DATE_FILTERS = ['dateFrom', 'dateTo'];

const SearchSection = (props) => {
  const { searchFilters, setQueryParam } = props;
  const clearField = (name) => setQueryParam(name);
  const isInvalidRange = invalidRangeChecker({
    start: searchFilters.amountHigherThan,
    end: searchFilters.amountLowerThan,
  });
  return (
    <SearchGroup
      setQueryAction={(name) => (value) => {
        if (DATE_FILTERS.includes(name)) return setQueryParam(name, value, dateFilterMapper);
        setQueryParam(name, value);
      }}
      clearFieldAction={clearField}
      filters={searchFilters}
    >
      <SearchDateGroup
        isSingleDayAllowed
        isFutureDateDisabled
        label={t('bank_transfers.table.search.period')}
        startDateProps={{
          name: 'dateFrom',
        }}
        endDateProps={{
          name: 'dateTo',
        }}
      />
      <Search
        placeholder={t('bank_transfers.table.search.amount_higher_than')}
        name="amountHigherThan"
        invalid={isInvalidRange}
        isCurrency
        isCurrencySignVisible
      />
      <Search
        placeholder={t('bank_transfers.table.search.amount_lower_than')}
        invalid={isInvalidRange}
        name="amountLowerThan"
        isCurrency
        isCurrencySignVisible
      />
    </SearchGroup>
  );
};

SearchSection.propTypes = {
  searchFilters: shape({}).isRequired,
  setQueryParam: func.isRequired,
};

export default connect(null, {
  setQueryParam: setQueryParamAction,
})(SearchSection);
