import React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import ItemNumberSelect from './ItemNumberSelect';

interface FormControlledItemNumberSelectProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
}

interface ItemNumberSelectProps {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
  inputClassName?: string;
  initialValue?: string;
  label: string;
  input: any;
  onValueChange: () => void;
  fetchSuggestedItemNumber: () => void;
  suggestedNumber: string;
  onClick: () => void;
  shouldLabelStayUp: boolean;
  dataIds?: { input: string; suggestion: string };
  inputProps?: {};
  required: boolean;
}

const FormControlledItemNumberSelect = ({
  input: { value, onChange },
  meta: { error },
  ...restProps
}: FormControlledItemNumberSelectProps) => (
  <ItemNumberSelect
    {...(restProps as ItemNumberSelectProps)}
    initialValue={value}
    onValueChange={onChange}
    invalid={error && error.length >= 1}
  />
);

export default FormControlledItemNumberSelect;
