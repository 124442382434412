import { createContext } from 'react';

type RadioGroupContextValue = {
  name: string;
  onChange: (value: string) => void;
  value?: string;
  isDisabled?: boolean;
};

export default createContext({} as RadioGroupContextValue);
