import { LOCATION_CHANGE } from 'connected-react-router';

import { MENU_TOGGLE } from 'constants/menu';

const defaultState = {
  isOpen: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case MENU_TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};
