export const FETCH_REQUEST = 'PROPOSAL/FETCH_REQUEST';
export const FETCH_SUCCESS = 'PROPOSAL/FETCH_SUCCESS';
export const FETCH_FAILURE = 'PROPOSAL/FETCH_FAILURE';

export const CREATE_REQUEST = 'PROPOSAL/CREATE_REQUEST';
export const CREATE_SUCCESS = 'PROPOSAL/CREATE_SUCCESS';
export const CREATE_FAILURE = 'PROPOSAL/CREATE_FAILURE';

export const DELETE_REQUEST = 'PROPOSAL/DELETE_REQUEST';
export const DELETE_SUCCESS = 'PROPOSAL/DELETE_SUCCESS';
export const DELETE_FAILURE = 'PROPOSAL/DELETE_FAILURE';

export const UPDATE_REQUEST = 'PROPOSAL/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'PROPOSAL/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'PROPOSAL/UPDATE_FAILURE';

export const TOGGLE_GROSS_NET_REQUEST = 'PROPOSAL/TOGGLE_GROSS_NET_REQUEST';
export const TOGGLE_GROSS_NET_SUCCESS = 'PROPOSAL/TOGGLE_GROSS_NET_SUCCESS';
export const TOGGLE_GROSS_NET_FAILURE = 'PROPOSAL/TOGGLE_GROSS_NET_FAILURE';

export const FIRST_UPDATE_REQUEST = 'PROPOSAL/FIRST_UPDATE_REQUEST';
export const FIRST_UPDATE_SUCCESS = 'PROPOSAL/FIRST_UPDATE_SUCCESS';
export const FIRST_UPDATE_FAILURE = 'PROPOSAL/FIRST_UPDATE_FAILURE';

export const FETCH_SUMS_REQUEST = 'PROPOSAL/FETCH_SUMS_REQUEST';
export const FETCH_SUMS_SUCCESS = 'PROPOSAL/FETCH_SUMS_SUCCESS';
export const FETCH_SUMS_FAILURE = 'PROPOSAL/FETCH_SUMS_FAILURE';

export const SEND_REQUEST = 'PROPOSAL/SEND_REQUEST';
export const SEND_SUCCESS = 'PROPOSAL/SEND_SUCCESS';
export const SEND_FAILURE = 'PROPOSAL/SEND_FAILURE';

export const DOWNLOAD_REQUEST = 'PROPOSAL/DOWNLOAD_REQUEST';
export const DOWNLOAD_SUCCESS = 'PROPOSAL/DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'PROPOSAL/DOWNLOAD_FAILURE';

export const TRANSFORM_REQUEST = 'PROPOSAL/TRANSFORM_REQUEST';
export const TRANSFORM_SUCCESS = 'PROPOSAL/TRANSFORM_SUCCESS';
export const TRANSFORM_FAILURE = 'PROPOSAL/TRANSFORM_FAILURE';

export const REORDER_LINE_ITEMS_LOCAL = 'PROPOSAL/REORDER_LINE_ITEMS_LOCAL';
export const REORDER_LINE_ITEMS_REQUEST = 'PROPOSAL/REORDER_LINE_ITEMS_REQUEST';
export const REORDER_LINE_ITEMS_SUCCESS = 'PROPOSAL/REORDER_LINE_ITEMS_SUCCEESS';
export const REORDER_LINE_ITEMS_FAILURE = 'PROPOSAL/REORDER_LINE_ITEMS_FAILURE';

export const FROM_PROPOSAL_QUERY_PARAM = 'from-proposal';

export const FORM_NAME = 'proposalCreator';

export const ITEMS_DROPPABLE_ID = 'PROPOSAL/LINE_ITEMS_DROPPABLE';

export const DUPLICATE_PROPOSAL_REQUEST = 'PROPOSAL/DUPLICATE_PROPOSAL_REQUEST';
export const DUPLICATE_PROPOSAL_SUCCESS = 'PROPOSAL/DUPLICATE_PROPOSAL_SUCCESS';
export const DUPLICATE_PROPOSAL_FAILURE = 'PROPOSAL/DUPLICATE_PROPOSAL_FAILURE';

export const FROM_DUPLICATE_QUERY_PARAM = 'from-duplicate';

export const FETCH_LINE_CATEGORIES_REQUEST = 'PROPOSAL/FETCH_LINE_CATEGORIES_REQUEST';
export const FETCH_LINE_CATEGORIES_SUCCESS = 'PROPOSAL/FETCH_LINE_CATEGORIES_SUCCESS';
export const FETCH_LINE_CATEGORIES_FAILURE = 'PROPOSAL/FETCH_LINE_CATEGORIES_FAILURE';
