import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import SelectField from 'components/Form/SelectField';

import styles from './Template.module.css';

const TemplateSection = ({ templates, isFetching = false, readonly }) => {
  const templateOptions = templates.map(({ id, name }) => ({ value: id, label: name }));

  return (
    <div className={cx(styles.formGroup, styles.root, styles.required)}>
      <Field
        id="invoiceTemplateId"
        name="invoiceTemplateId"
        component={SelectField}
        checker={checker}
        options={templateOptions}
        isLoading={isFetching}
        label={t('revenue.form.layout')}
        disabled={readonly}
      />
    </div>
  );
};

TemplateSection.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool.isRequired,
  readonly: PropTypes.bool,
};

export default connect((state) => ({
  templates: state.invoiceTemplates.data,
}))(TemplateSection);
