import React from 'react';

import { MODAL_HEADER_VARIANT } from 'constants/modal';
import type { ExpenseInstallment, RevenueInstallment } from 'types/entities/Installment';
import { ModalHeader } from 'components/Modal';
import Modal from 'components/Modal';

import AddExpenseContract from './AddExpenseContract';
import AddRevenueContract from './AddRevenueContract';

type AddContractModalProps = {
  onClose: () => void;
  onSuccess: (installment: ExpenseInstallment | RevenueInstallment) => void;
  isRevenue?: boolean;
};

const AddContractModal = ({ onClose, onSuccess, isRevenue = false }: AddContractModalProps) => {
  return (
    <Modal isOpen>
      <ModalHeader withCloseButton headerVariant={MODAL_HEADER_VARIANT.SMALL} onClose={onClose}>
        Neuen Vertrag erstellen
      </ModalHeader>
      {isRevenue ? (
        <AddRevenueContract onCancel={onClose} onSuccess={onSuccess} />
      ) : (
        <AddExpenseContract onCancel={onClose} onSuccess={onSuccess} />
      )}
    </Modal>
  );
};

export default AddContractModal;
