import React from 'react';
import cx from 'classnames';
import { shape } from 'prop-types';

import tableStyles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';

import PaymentRow from './PaymentRow/PaymentRow';

import styles from './PaymentsTable.module.css';

const PaymentsTable = ({ payments }) => (
  <table className={cx(styles.table, tableStyles.invoicesTable)}>
    <thead>
      <tr>
        <th>{t('expenses.payments.fields.date')}</th>
        <th>{t('expenses.payments.fields.issuers_bank_account')}</th>
        <th>{t('expenses.payments.fields.recipients_bank_account')}</th>
        <th>{t('expenses.payments.fields.payment_task_date')}</th>
        <th>{t('expenses.payments.fields.amount')}</th>
      </tr>
    </thead>
    <tbody className={styles.tableBody}>
      {payments.map((payment, index) => (
        <PaymentRow payment={payment} key={index} />
      ))}
    </tbody>
  </table>
);

PaymentsTable.propTypes = {
  payments: shape({}).isRequired,
};

export default PaymentsTable;
