import { connect } from 'react-redux';

import {
  removeItemFromProductCatalogGroup,
  updateItemInProductCatalogGroup,
} from 'actions/product-catalog/groups/groups';
import { TYPE_PRODUCT_CATALOG_ITEM } from 'constants/row-types';
import { EditItemRow } from 'components/LineItems';

const mapDispatchToProps = (dispatch) => ({
  updateItem: (...args) => dispatch(updateItemInProductCatalogGroup(...args)),
  removeItem: (...args) => dispatch(removeItemFromProductCatalogGroup(...args)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { lineItem: { id: itemId } = {}, groupId } = ownProps;
  const { updateItem, removeItem } = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,
    absoluteTranslationPath: 'form.fields',
    rowType: TYPE_PRODUCT_CATALOG_ITEM,
    cells: [
      { name: 'name', type: 'text' },
      { name: 'unit', type: 'text' },
      { name: 'netAmount', type: 'text', textType: 'currency' },
      { name: 'grossAmount', type: 'text', textType: 'currency' },
      { name: 'vat', type: 'text', textType: 'percentage' },
      { name: 'quantity', type: 'quantity', textType: 'currency' },
    ],
    numberOfColumns: 7,
    updateLineItem: (item) =>
      updateItem({
        groupId,
        itemId,
        quantity: item.quantity,
        productCatalogItemsGroupId: item.productCatalogItemsGroupId,
      }),
    deleteLineItem: () => removeItem({ groupId, itemId }),
  };
};

export default connect(null, mapDispatchToProps, mergeProps)(EditItemRow);
