import styled, { css } from 'styled-components';

import { Breakpoints } from 'redesign/styles/breakpoints';

type WrapperProps = {
  $color: 'blue' | 'darkblue' | 'orange';
};

export const Wrapper = styled.div<WrapperProps>`
  cursor: pointer;
  display: flex;
  min-width: 211px;
  flex: 1;
  height: 96px;
  border-radius: 5px;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ theme }) => theme.space[4]};
  overflow: hidden;
  padding: 16px 8px 8px 15px;
  box-shadow: 2px 2px 10px 0px rgba(0, 102, 179, 0.4);

  ${({ $color }) => {
    switch ($color) {
      case 'blue':
        return css`
          background: ${({ theme }) => `linear-gradient(3deg,
              ${theme.colors.vrblue['100']},
              ${theme.colors.iceblue['100']}
            )`};
        `;

      case 'orange':
        return css`
          background: ${({ theme }) => `linear-gradient(3deg,
              ${theme.colors.vrorange['075']},
              ${theme.colors.yellow['100']}
            )`};
        `;

      case 'darkblue':
        return css`
          background: ${({ theme }) => `linear-gradient(3deg,
              ${theme.colors.nightblue['100']},
              ${theme.colors.vrblue['100']}
            )`};
        `;
    }
  }}

  &:hover {
    box-shadow: 5px 5px 10px 0px rgba(15, 35, 100, 0.4);
  }

  &:active {
    box-shadow: 1px 1px 3px 0px rgba(15, 35, 100, 0.4);
  }

  @media (min-width: ${Breakpoints.md}) {
    margin-right: ${({ theme }) => theme.space[4]};
    max-width: 234px;
  }

  @media (min-width: ${Breakpoints.xl}) {
    max-width: 290px;
  }
`;

export const Icon = styled.span`
  flex: 1;
  color: white;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: bold;
`;
