import React, { memo, SVGProps } from 'react';

const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        d="M24.4 7.613c-.52-.52-1.36-.52-1.88 0L16 14.12 9.48 7.6c-.52-.52-1.36-.52-1.88 0-.52.52-.52 1.36 0 1.88L14.12 16 7.6 22.52c-.52.52-.52 1.36 0 1.88.52.52 1.36.52 1.88 0L16 17.88l6.52 6.52c.52.52 1.36.52 1.88 0 .52-.52.52-1.36 0-1.88L17.88 16l6.52-6.52a1.336 1.336 0 000-1.867z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default memo(Close);
