import { createSlice } from '@reduxjs/toolkit';

import { initialState, name } from './constants';
import * as thunks from './thunks';
import { StateSlice } from './types';

const slice = createSlice({
  name,
  initialState: initialState as StateSlice,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunks.fetchDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunks.fetchDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(thunks.fetchDashboard.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(thunks.updateDashboard.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
