import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchClientDefaults as fetchClientDefaultsAction } from 'actions/default-values';
import { t } from 'shared/utils';
import Card from 'components/Card';
import Section from 'components/Form/Section/Section';

import DeliveryNoteDefaults from './DeliveryNoteDefaults/DeliveryNoteDefaults';
import InvoiceDefaults from './InvoiceDefaults/InvoiceDefaults';
import OrderConfirmationDefaults from './OrderConfirmationDefaults/OrderConfirmationDefaults';
import PaymentReminderDefaults from './PaymentReminderDefaults/PaymentReminderDefaults';
import ProposalDefaults from './ProposalDefaults/ProposalDefaults';

import styles from './ClientDocumentDefaults.module.css';

export type ClientDocumentsFormName =
  | 'clientInvoiceDefaults'
  | 'clientPaymentReminderDefaults'
  | 'clientOrderConfirmationDefaults'
  | 'clientDeliveryNoteDefaults'
  | 'clientProposalDefaults';

interface ClientDocumentDefaultsProps {
  fetchClientDefaults: (clientId: string) => void;
  clientId: string;
}

const ClientDocumentDefaults = ({ fetchClientDefaults, clientId }: ClientDocumentDefaultsProps) => {
  useEffect(() => {
    fetchClientDefaults(clientId);
  }, [fetchClientDefaults, clientId]);

  return (
    <Card className={styles.container}>
      <Card.Header>{t('clients.defaults.tab_document_defaults_header')}</Card.Header>
      <Card.Body className={styles.body}>
        <Section
          className={styles.section}
          title={t('invoice_templates.documents_defaults.invoice_defaults_section')}
        >
          <InvoiceDefaults clientId={clientId} />
        </Section>
        <Section
          className={styles.section}
          title={t('invoice_templates.documents_defaults.payment_reminder_defaults_section')}
        >
          <PaymentReminderDefaults clientId={clientId} />
        </Section>
        <Section
          className={styles.section}
          title={t('invoice_templates.documents_defaults.proposal_defaults_section')}
        >
          <ProposalDefaults clientId={clientId} />
        </Section>
        <Section
          className={styles.section}
          title={t('invoice_templates.documents_defaults.order_confirmation_defaults_section')}
        >
          <OrderConfirmationDefaults clientId={clientId} />
        </Section>
        <Section
          className={styles.section}
          title={t('invoice_templates.documents_defaults.delivery_note_defaults_section')}
        >
          <DeliveryNoteDefaults clientId={clientId} />
        </Section>
      </Card.Body>
    </Card>
  );
};

const mapDispatchToProps = {
  fetchClientDefaults: fetchClientDefaultsAction,
};

export default connect(null, mapDispatchToProps)(ClientDocumentDefaults);
