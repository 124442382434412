import React, { useCallback } from 'react';
import { FilterProps } from 'react-table';

import SelectField from 'components/Form/SelectField';

import { Field } from './SelectFilter.styled';

type SelectFilterProps<T extends object> = FilterProps<T> & {
  options: {
    label: string;
    value: any;
  }[];
  label: string;
};

const SelectFilter = <T extends object>({
  column: { filterValue, setFilter, id },
  options,
  label,
}: SelectFilterProps<T>) => {
  const handleChange = useCallback(
    (newValue: { label: string; value: string } | undefined) => {
      setFilter(newValue?.value);
    },
    [setFilter]
  );

  return (
    <Field
      as={SelectField}
      label={label}
      name={id}
      value={filterValue}
      options={options}
      onChange={handleChange}
      dataId={`filter-${id}`}
      clearable
    />
  );
};

export default SelectFilter;
