import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import { SubmissionError } from 'redux-form';

import { updateClient as updateClientAC } from 'actions/clients';
import {
  changeGrecaptchaResponse as changeGrecaptchaResponseAction,
  resetGrecaptcha as resetGrecaptchaAction,
} from 'actions/grecaptcha';
import { showNotification } from 'actions/notification';
import { sendInvoiceByEmail } from 'api/me/sendInvoiceByEmail';
import { ACTION_EMAIL_ERROR, ACTION_SEND_VIA_MAIL } from 'constants/piwik';
import validationNotification from 'notifications/validation';
import { companySelector, outgoingInvoiceSelector } from 'reducers/form';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import getGrecaptchaKey from 'shared/utils/grecaptcha';
import {
  mapServerErrorsToFieldErrors,
  mapServerErrorsToNotificationError,
} from 'shared/utils/server-validation';
import { RootState } from 'store';
import { Client } from 'types/entities/Client';
import BaseEmailEditor from 'features/emailEditor/EmailEditor';

import { trackEvent } from './utils';

type EmailEditorProps = {
  onClose: () => void;
  client: Client;
  title: string;
  saveInvoice: any;
};

const EmailEditor = ({ onClose, client, title, saveInvoice }: EmailEditorProps) => {
  const dispatch = useDispatch();
  const salutationContent = useSelector(
    (state: RootState) => state.defaultValues.values.emailInvoiceSalutationContent
  );
  const salutationHonorific = useSelector(
    (state: RootState) => state.defaultValues.values.emailInvoiceSalutation
  );
  const personalNotes = useSelector(
    (state: RootState) => state.defaultValues.values.emailInvoiceSignature
  );
  const invoiceDate = useSelector((state: RootState) =>
    outgoingInvoiceSelector(state, 'creationDate')
  );
  const invoiceId = useSelector((state: RootState) => state.outgoingInvoice.details.id);
  const credentials = useSelector((state: RootState) => state.profile.credentials);
  const street = useSelector((state: RootState) => companySelector(state, 'street'));
  const city = useSelector((state: RootState) => companySelector(state, 'city'));
  const email = useSelector((state: RootState) => companySelector(state, 'email'));
  const phone = useSelector((state: RootState) => companySelector(state, 'phoneNumber'));
  const zipcode = useSelector((state: RootState) => companySelector(state, 'zipcode'));
  const companyName = useSelector((state: RootState) => state.company.details.name);

  const firstName = credentials?.firstName;
  const lastName = credentials?.lastName;
  const subject = `${companyName}: Ihre Rechnung vom ${invoiceDate}`;
  const content = `${salutationHonorific || ''} \n\n${salutationContent || ''} \n\n${
    personalNotes || ''
  } \n\n${firstName} ${lastName} \n${street} \n${zipcode} ${city} \n\n${phone} \n${email}\n\n\n`;

  const grecaptchaResponse = useSelector((state: RootState) => state.grecaptcha.grecaptchaResponse);
  const [isRecaptchaVisible, setIsRecaptchaVisible] = useState(false);
  const recaptchaSitekey = getGrecaptchaKey() || '';

  const reloadRecaptcha = () => setIsRecaptchaVisible(true);

  const changeGrecaptchaResponse = useCallback(
    (response: string) => dispatch(changeGrecaptchaResponseAction(response)),
    [dispatch]
  );

  const sendMail = useCallback(
    async (data) => {
      const response = await saveInvoice();

      if (!response.success) {
        onClose();
        return;
      }

      try {
        await sendInvoiceByEmail(invoiceId, data, grecaptchaResponse);
        dispatch(replace(paths.outgoingInvoices));
      } catch ({ response }) {
        if (
          (response as any).status === 422 &&
          (response as any).data.errors[0].detail === 'Das Captcha wurde nicht korrekt ausgefüllt'
        ) {
          dispatch(resetGrecaptchaAction());
          reloadRecaptcha();
          return;
        }

        const notification = validationNotification(
          mapServerErrorsToNotificationError((response as any).data.errors)
        );
        dispatch(showNotification(notification));
        trackEvent(ACTION_EMAIL_ERROR);
        throw new SubmissionError(mapServerErrorsToFieldErrors((response as any).data.errors));
      }

      dispatch(
        showNotification({
          duration: 3000,
          title: t('features.outgoing_invoices.email_editor.successfully_sent'),
          variant: 'success',
        })
      );

      if (client && data.updateClientsEmail) {
        const { id: clientId, ...clientData } = client;

        dispatch(
          updateClientAC(clientId, { ...clientData, email: data.email }, { redirectAfter: false })
        );
      }

      trackEvent(ACTION_SEND_VIA_MAIL);
      onClose();
    },
    [dispatch, client, invoiceId, onClose, saveInvoice]
  );

  useEffect(() => {
    return () => {
      dispatch(resetGrecaptchaAction());
    };
  }, []);

  return (
    <BaseEmailEditor
      onClose={onClose}
      initialValues={{
        email: client?.email,
        content: content?.replace(/<[^>]*>?/gm, ''),
        subject: subject?.replace(/<[^>]*>?/gm, ''),
        sendCarbonCopy: false,
        updateClientsEmail: false,
      }}
      sendMail={sendMail}
      title={title}
      client={client}
      recaptchaOptions={{
        isRecaptchaVisible,
        reloadRecaptcha,
        recaptchaSitekey,
        changeGrecaptchaResponse,
      }}
    />
  );
};

export default EmailEditor;
