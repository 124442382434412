import React from 'react';
import { bool, number, string } from 'prop-types';

import {
  BASIC_UID,
  DOWNGRADE,
  NO_CURRENT_PLAN_END_DATE,
  PREMIUM_UID,
  TRIAL,
  UPGRADE,
} from 'constants/payment-plans';
import { formatDate, t } from 'shared/utils';
import { isNextPlanPresent, nextPlanDate } from 'shared/utils/payment-plans-dates';
import InfoIcon from 'components/InfoIcon/InfoIcon';

import PlanDate from './components/PlanDate';
import Premium from './components/Premium';

import styles from './Description.module.css';

const freeTrialTranslation = t('profile.payment_plan.current_payment_plan.free_trial');
const freeTranslation = t('profile.payment_plan.current_payment_plan.free');

const title = {
  basic: t('profile.payment_plan.current_payment_plan.basic'),
  premium: (
    <div>
      {t('profile.payment_plan.current_payment_plan.premium')}
      <InfoIcon
        text={t('profile.payment_plan.current_payment_plan.info_icon')}
        className={styles.icon}
      />
    </div>
  ),
};

const displayFuturePaymentPlan = (
  price,
  vrMainBankAccount,
  latestHistoryEntryMode,
  currentPlanEndDate,
  trialEndDate,
  onTrial
) => {
  if (
    latestHistoryEntryMode === TRIAL ||
    (!onTrial && currentPlanEndDate === NO_CURRENT_PLAN_END_DATE)
  )
    return null;

  const { nextPlanValidFrom } = nextPlanDate(trialEndDate, currentPlanEndDate);

  return (
    <div className={styles.futurePlanContainer} data-id="PaymentPlans:futurePlanBox">
      <div className={styles.currentPlan}>
        <span>
          {latestHistoryEntryMode === UPGRADE && title[PREMIUM_UID]}
          {latestHistoryEntryMode === DOWNGRADE && title[BASIC_UID]}
        </span>
        <PlanDate isFuture date={nextPlanValidFrom} />
      </div>
      {latestHistoryEntryMode === UPGRADE && (
        <Premium price={price} vrMainBankAccount={vrMainBankAccount} />
      )}
      {latestHistoryEntryMode === DOWNGRADE && freeTranslation}
    </div>
  );
};

const Description = ({
  plan,
  onTrial,
  price,
  vrMainBankAccount,
  latestHistoryEntryMode,
  currentPlanEndDate,
  trialEndDate,
}) => {
  const { date } = nextPlanDate(trialEndDate, currentPlanEndDate);
  const displayCurrentPlanDescription = () => {
    switch (plan) {
      case PREMIUM_UID:
        return <Premium price={price} vrMainBankAccount={vrMainBankAccount} />;
      case BASIC_UID:
        return freeTranslation;
      default:
        return null;
    }
  };
  const shouldDisplayPlanDate =
    isNextPlanPresent(trialEndDate, currentPlanEndDate) && latestHistoryEntryMode !== TRIAL;

  return (
    <div className={styles.container} data-id="PaymentPlans:currentPlanBox">
      <div className={styles.currentPlanContainer}>
        <div className={styles.currentPlan}>
          <span>{title[plan]}</span>
          {shouldDisplayPlanDate && <PlanDate date={formatDate(date)} />}
        </div>
        {/* FreeTrial should render ONLY when user is on trial, for each other case - standard plan description,
      we need this condition here, as trial is not another plan - it is just indicator that user don't pays for
      premium */}
        {onTrial ? freeTrialTranslation : displayCurrentPlanDescription()}
      </div>
      {displayFuturePaymentPlan(
        price,
        vrMainBankAccount,
        latestHistoryEntryMode,
        currentPlanEndDate,
        trialEndDate,
        onTrial
      )}
    </div>
  );
};

Description.propTypes = {
  plan: string.isRequired,
  onTrial: bool,
  price: number,
  vrMainBankAccount: bool,
  latestHistoryEntryMode: string,
  currentPlanEndDate: string,
  trialEndDate: string,
};

export default Description;
