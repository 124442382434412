import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import WarningIcon from 'images/icon-exclamation-warning.svg';
import { t } from 'shared/utils';
import { IGNORE_OUTSIDE_CLICK_CLASS as IGNORE_OUTSIDE_LINE_ITEM_CLICK_CLASS } from 'components/LineItems';

import ErrorBody from './ErrorBody';

import styles from './Notification.module.css';

const ValidationErrorsNotification = ({ hide, variant, errors, customTitle }) => (
  <div
    className={cx(
      styles.notification,
      styles[`border-${variant}`],
      IGNORE_OUTSIDE_LINE_ITEM_CLICK_CLASS
    )}
    data-id="FlashNotification"
  >
    <div className={cx(styles.imageWrapper, styles[variant])}>
      <img src={WarningIcon} alt="Warning icon" />
    </div>
    <div className={styles.section}>
      <h2 data-id="FlashNotification:header" className={cx(styles.title, styles.multiline)}>
        {t(customTitle || 'notifications.validation.title')}
      </h2>
      <div
        className={cx(styles.section, styles.errorsMultiline)}
        data-id="FlashNotification:content"
      >
        {errors.map((error = [], index) => {
          const errorWithDot = error.slice(-1) === '.' ? error : `${error}.`;
          return <ErrorBody key={error[0] || index} error={errorWithDot} />;
        })}
      </div>
      <button
        onClick={hide}
        className={styles.closeButton}
        data-id="FlashNotification:button-close"
      />
    </div>
  </div>
);

ValidationErrorsNotification.propTypes = {
  hide: PropTypes.func.isRequired,
  variant: PropTypes.string,
  customTitle: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ValidationErrorsNotification;
