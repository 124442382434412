import React from 'react';
import cx from 'classnames';
import { bool, func, shape, string } from 'prop-types';

import { DE_CLIENT_MANAGEMENT } from 'constants/clients';
import {
  ACTION_EDIT_IN_FARMPILOT,
  ACTION_SHOW_EDIT_FARMPILOT_INFORMATION,
  CATEGORY_FARMPILOT,
} from 'constants/piwik';
import paths from 'routes/paths';
import tableStyles from 'shared/styles/table.module.css';
import { farmpilot, t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import I18n from 'components/I18n';
import ActionDelete from 'components/IndexActionsContainer/actionIcons/ActionDelete';
import ActionEdit from 'components/IndexActionsContainer/actionIcons/ActionEdit';
import ActionFarmpilotEdit from 'components/IndexActionsContainer/actionIcons/ActionFarmpilotEdit';
import IndexActionsContainer from 'components/IndexActionsContainer/IndexActionsContainer';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import { TooltipPlacements } from 'redesign/components/molecules/Tooltip/Tooltip';

import localStyles from '../ClientsTable.module.css';

export const styles = { ...tableStyles, ...localStyles };

const editTitle = t('administration.actions.edit');
const farmpilotEditTitle = t('administration.actions.farmpilotEdit');
const deleteTitle = t('administration.actions.delete');
const farmpilotTooltip = t('clients.table.farmpilot.info');
const infoIconTooltip = t('administration.actions.client_info_icon_tooltip');

const editTitleEvent = () => piwikHelpers.trackEvent(DE_CLIENT_MANAGEMENT, editTitle);
const editInFarmpilotEvent = () =>
  piwikHelpers.trackEvent(CATEGORY_FARMPILOT, ACTION_EDIT_IN_FARMPILOT);
const showEditFarmpilotInformationEvent = () =>
  piwikHelpers.trackEvent(CATEGORY_FARMPILOT, ACTION_SHOW_EDIT_FARMPILOT_INFORMATION);

const ClientRow = ({
  client,
  onDelete,
  editPath = paths.editClient,
  showFarmpilotPath = paths.showClientFarmpilot,
  isFarmpilotEnabled,
  isFarmpilotIntegrationActive,
  isFarmpilotCustomerConnectionActive,
}) => {
  const isClientFromFarmpilot =
    isFarmpilotEnabled &&
    farmpilot.isFarmpilotOrigin(client.origin) &&
    isFarmpilotIntegrationActive &&
    isFarmpilotCustomerConnectionActive;
  const { actions = [] } = client.meta || {};
  const permittedActions = {
    ...actions,
    edit: true,
    info: !isClientFromFarmpilot && !actions.delete,
    farmpilotEdit: isClientFromFarmpilot,
    farmpilotInfo: isClientFromFarmpilot,
  };

  return (
    <tr
      data-id={
        isClientFromFarmpilot
          ? `ClientsIndexPage:farmpilot-client-row_${client.lastName}`
          : `ClientsIndexPage:client-row_${client.lastName}`
      }
      className={styles.main}
    >
      <td className={styles.column}>
        <div className={styles.cell}>
          <I18n t="clients.table.columns.company_name" className={styles.cellHeader} />
          <CellContentWithTooltip>{client.companyName}</CellContentWithTooltip>
        </div>
      </td>
      <td className={styles.column}>
        <div className={styles.cell}>
          <I18n t="clients.table.columns.city" className={styles.cellHeader} />
          <CellContentWithTooltip>{client.city}</CellContentWithTooltip>
        </div>
      </td>
      <td className={styles.column}>
        <div className={styles.cell}>
          <I18n t="clients.table.columns.honorific" className={styles.cellHeader} />
          <CellContentWithTooltip>{client.honorific}</CellContentWithTooltip>
        </div>
      </td>
      <td className={styles.column}>
        <div className={styles.cell}>
          <I18n t="clients.table.columns.first_name" className={styles.cellHeader} />
          <CellContentWithTooltip>{client.firstName}</CellContentWithTooltip>
        </div>
      </td>
      <td className={styles.column}>
        <div className={styles.cell}>
          <I18n t="clients.table.columns.last_name" className={styles.cellHeader} />
          <CellContentWithTooltip>{client.lastName}</CellContentWithTooltip>
        </div>
      </td>
      <td className={styles.column}>
        <div className={styles.cell}>
          <I18n t="clients.table.columns.id_number" className={styles.cellHeader} />
          <CellContentWithTooltip>{client.idNumber}</CellContentWithTooltip>
        </div>
      </td>
      <td className={styles.column}>
        <div className={styles.cell}>
          <div className={cx(localStyles.actionsWithTooltip)}>
            <div>
              <IndexActionsContainer>
                <ActionEdit
                  dataId="ClientsIndexPage:button-edit"
                  to={isClientFromFarmpilot ? showFarmpilotPath(client.id) : editPath(client.id)}
                  title={editTitle}
                  label={editTitle}
                  onClick={editTitleEvent}
                  visible={permittedActions.edit}
                />
                <ActionFarmpilotEdit
                  href={farmpilot.getFarmpilotClientActionPath(client.externalId)}
                  title={farmpilotEditTitle}
                  label={farmpilotEditTitle}
                  onClick={editInFarmpilotEvent}
                  visible={permittedActions.farmpilotEdit}
                  dataId="Farmpilot:icon-edit"
                />
                <ActionDelete
                  onClick={() => onDelete(client)}
                  title={deleteTitle}
                  label={deleteTitle}
                  visible={permittedActions.delete}
                  dataId="button-delete"
                />
              </IndexActionsContainer>
            </div>
            {permittedActions.farmpilotInfo && (
              <div className={localStyles.tooltipWithText}>
                <InfoIcon
                  message={farmpilotTooltip}
                  tooltipPlacement={TooltipPlacements.TOP_END}
                  onMouseEnter={showEditFarmpilotInformationEvent}
                  className={localStyles.infoIcon}
                  dataId="Farmpilot:tooltip"
                />
                <div className={localStyles.tooltipOnMobile}>{farmpilotTooltip}</div>
              </div>
            )}
            {permittedActions.info && (
              <div className={localStyles.tooltipWithText}>
                <InfoIcon
                  message={infoIconTooltip}
                  tooltipPlacement={TooltipPlacements.TOP_END}
                  className={localStyles.infoIcon}
                  dataId="Supplier:info-icon"
                />
                <div className={localStyles.tooltipOnMobile}>{infoIconTooltip}</div>
              </div>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

ClientRow.propTypes = {
  client: shape({
    companyName: string,
    lastName: string,
    idNumber: string,
    city: string,
  }).isRequired,
  editPath: func,
  showFarmpilotPath: func,
  onDelete: func.isRequired,
  isFarmpilotEnabled: bool.isRequired,
  isFarmpilotIntegrationActive: bool.isRequired,
  isFarmpilotCustomerConnectionActive: bool.isRequired,
};

export default ClientRow;
