import React, { memo, SVGProps } from 'react';

const Overdue = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd">
      <path
        d="M14.467 11.2h-1.05v4.2l3.325 1.995.525-.861-2.8-1.659V11.2zm3.386-4.333l3.224 2.691-.896 1.075-3.227-2.69.899-1.076zm-7.472 0l.897 1.075-3.225 2.691-.896-1.075 3.224-2.691zM14.117 8.4a6.3 6.3 0 100 12.601 6.3 6.3 0 000-12.601zm0 11.2a4.905 4.905 0 01-4.9-4.9c0-2.702 2.198-4.9 4.9-4.9 2.702 0 4.9 2.198 4.9 4.9 0 2.702-2.198 4.9-4.9 4.9z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(Overdue);
