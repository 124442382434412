import React, { useState } from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { isEmpty } from 'lodash';
import { bool, func, shape } from 'prop-types';

import { fetchProductCatalogItems as fetchProductCatalogItemsAction } from 'actions/product-catalog/items/items';
import { clickCreateProductCatalogItem as clickCreateProductCatalogItemAction } from 'actions/product-catalog/items/piwik';
import { PRODUCT_CATALOG_ITEMS } from 'constants/product-catalog/items';
import { makeGetList } from 'reducers/common/resource';
import {
  productCatalogGroupsEnabledHelper,
  productCatalogItemsEnabledHelper,
} from 'routes/accesses';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { useDebouncedHasAnyFilters } from 'shared/utils/hooks/useDebouncedHasAnyFilters';
import { useRefetchOnPropsChange } from 'shared/utils/hooks/useRefetchOnPropsChange';
import EmptyEntryPage from 'components/EmptyEntryPage/EmptyEntryPage';
import ProductsEmptyStateImage from 'components/EmptyEntryPage/images/ProductCatalog';
import Loading from 'components/Loading';

import Groups from './Groups';
import Items from './Items';
import Tabs from './Tabs';

import styles from './ProductCatalog.module.css';

const ProductCatalog = ({
  productCatalogItemsEnabled,
  productCatalogGroupsEnabled,
  isProductCatalogEmpty,
  isFetching,
  fetchProductCatalogItems,
  clickCreateProductCatalogItem,
  push,
  params: { filters, sorting, pagination },
}) => {
  /**
   * This flag is initially set to false and is set to true after first fetch
   * for items comes back. It is used for displaying the initial loader.
   */
  const [areItemsFetched, setAreItemsFetched] = useState(false);
  const debouncedHasAnyFiltersActive = useDebouncedHasAnyFilters(filters);

  useRefetchOnPropsChange({
    fetchFunction: async ({
      pagination: newPagination,
      sorting: newSorting,
      overwrittenValues,
    }) => {
      await fetchProductCatalogItems({ ...newPagination, ...overwrittenValues }, newSorting);
      setAreItemsFetched(true);
    },
    props: { parsedFilters: filters, sorting, pagination },
  });

  if (!areItemsFetched) {
    return (
      <div className={styles.page}>
        <Loading />
      </div>
    );
  }

  const isEmptyStateVisible = !isFetching && isProductCatalogEmpty && !debouncedHasAnyFiltersActive;

  if (isEmptyStateVisible) {
    const createEntity = () => {
      push(paths.newProductCatalogItem);
      clickCreateProductCatalogItem();
    };

    return (
      <div className={styles.main}>
        <EmptyEntryPage
          dataIdPrefix="ProductCatalog"
          Image={ProductsEmptyStateImage}
          header={t('empty_entry_pages.product_catalog.header')}
          subheader={t('empty_entry_pages.product_catalog.subheader')}
          info={[
            t('empty_entry_pages.product_catalog.info.row_1'),
            t('empty_entry_pages.product_catalog.info.row_2'),
          ]}
          createButtonLabel={t('empty_entry_pages.product_catalog.button_label')}
          onCreateButtonClick={createEntity}
        />
      </div>
    );
  }

  const sections = [
    {
      heading: t('product_catalog.tab_headers.product_catalog_items'),
      component: Items,
      path: paths.productCatalogItems,
      enabled: productCatalogItemsEnabled,
    },
    {
      heading: t('product_catalog.tab_headers.product_catalog_groups'),
      component: Groups,
      path: paths.productCatalogGroups,
      enabled: productCatalogGroupsEnabled,
    },
  ];
  const enabledSections = sections.filter((i) => i.enabled);

  return (
    <div className={styles.main}>
      <Tabs
        sections={enabledSections}
        areTabsVisible={sections.every((section) => section.enabled)}
      />
    </div>
  );
};

ProductCatalog.propTypes = {
  productCatalogItemsEnabled: bool,
  productCatalogGroupsEnabled: bool,
  isProductCatalogEmpty: bool.isRequired,
  isFetching: bool.isRequired,
  setPagination: func.isRequired,
  fetchProductCatalogItems: func.isRequired,
  push: func.isRequired,
  clickCreateProductCatalogItem: func.isRequired,
  params: shape({}),
};

const mapStateToProps = (state) => {
  const getList = makeGetList();

  return {
    isFetching: state.productCatalogItems[PRODUCT_CATALOG_ITEMS].isFetching,
    productCatalogItemsEnabled: productCatalogItemsEnabledHelper(state),
    productCatalogGroupsEnabled: productCatalogGroupsEnabledHelper(state),
    isProductCatalogEmpty: isEmpty(
      getList(state.productCatalogItems[PRODUCT_CATALOG_ITEMS], PRODUCT_CATALOG_ITEMS)
    ),
    params: state.productCatalogItems[PRODUCT_CATALOG_ITEMS].params,
  };
};

const mapDispatchToProps = {
  fetchProductCatalogItems: fetchProductCatalogItemsAction,
  push: pushAction,
  clickCreateProductCatalogItem: clickCreateProductCatalogItemAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCatalog);
