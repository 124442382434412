import React from 'react';

import BoxWithButton, { BoxWithButtonProps } from '../BoxWithButton';

import styles from './BoxWithButtonRedVariant.module.css';

const BoxWithButtonRedVariant = (props: BoxWithButtonProps) => (
  <BoxWithButton
    {...props}
    containerClassName={styles.hintBox}
    iconClassName={styles.hintBoxIcon}
  />
);

export default BoxWithButtonRedVariant;
