import { get, uniqBy } from 'lodash';
import { combineReducers } from 'redux';

import { FETCH_TRANSFERS_SUCCESS } from 'constants/bank-account';
import {
  DELETE_SUCCESS,
  INDEX_FAILURE,
  INDEX_MORE_FAILURE,
  INDEX_MORE_REQUEST,
  INDEX_MORE_SUCCESS,
  INDEX_REQUEST,
  INDEX_RESET,
  INDEX_SUCCESS,
  NAMESPACE,
  PAGINATION,
  SORT_SUCCESS,
} from 'constants/outgoing-invoice';
import filters, { parsedFiltersReducer } from 'reducers/common/filters';
import { extractPersistedClient } from 'reducers/outgoing-invoice/client';
import { extractPaymentReminders } from 'reducers/outgoing-invoice/payment-reminders';
import { getPaginationReducer } from 'reducers/pagination';
import { createFilteredReducer } from 'shared/utils';

const mapOutgoingInvoice =
  (included = []) =>
  (data = {}) => {
    const { id, type } = get(data, 'relationships.persisted-client.data') || {};
    return {
      ...data.attributes,
      id: data.id,
      clientId: get(data, 'relationships.client.data.id'),
      persistedClient: extractPersistedClient(id, type, included),
      meta: data.meta || null,
      paymentReminders: extractPaymentReminders(data, included),
    };
  };

const isFetching = (state = false, action) => {
  switch (action.type) {
    case INDEX_REQUEST:
    case INDEX_MORE_REQUEST:
      return true;

    case INDEX_SUCCESS:
    case INDEX_FAILURE:
    case INDEX_MORE_SUCCESS:
    case INDEX_MORE_FAILURE:
      return false;

    default:
      return state;
  }
};

/* eslint-disable no-shadow */
export const data = (state = [], action) => {
  switch (action.type) {
    case INDEX_SUCCESS: {
      const { data, included } = action.response;
      return data.map(mapOutgoingInvoice(included));
    }

    case INDEX_MORE_SUCCESS: {
      const { data, included } = action.response;
      return uniqBy([...state, ...data.map(mapOutgoingInvoice(included))], 'id');
    }

    case INDEX_RESET: {
      return [{ isReseted: true }];
    }

    case FETCH_TRANSFERS_SUCCESS: {
      const included = action.response.included || [];

      return uniqBy(
        [
          ...state,
          ...included
            .filter((item) => item.type === 'outgoing-invoices')
            .map(mapOutgoingInvoice(action.response.included)),
        ],
        'id'
      );
    }

    case DELETE_SUCCESS: {
      const { id } = action.response.data;
      return state.filter((i) => i.id !== id);
    }

    default:
      return state;
  }
};
/* esling-enable no-shadow */

export const meta = (state = null, action) => {
  switch (action.type) {
    case INDEX_SUCCESS:
    case INDEX_MORE_SUCCESS:
      return get(action, 'response.meta', null);
    default:
      return state;
  }
};

const pagination = getPaginationReducer([PAGINATION, INDEX_SUCCESS, INDEX_MORE_SUCCESS]);

const defaultSortingState = {
  column: 'creation_date',
  direction: 'DESC',
};

export const sorting = (state = defaultSortingState, action) => {
  if (action.type !== SORT_SUCCESS) {
    return state;
  }

  const direction = state.column === action.column && state.direction === 'ASC' ? 'DESC' : 'ASC';

  return {
    column: action.column,
    direction,
  };
};

export const invoicesCounters = (state = {}, action) => {
  switch (action.type) {
    case INDEX_SUCCESS:
      return action.response.meta.quickfilter_counters;
    default:
      return state;
  }
};

export default combineReducers({
  data,
  filters: createFilteredReducer(filters, (action) => action.name === NAMESPACE),
  parsedFilters: createFilteredReducer(parsedFiltersReducer, (action) => action.name === NAMESPACE),
  isFetching,
  meta,
  pagination,
  sorting,
  invoicesCounters,
});
