import React, { memo, ReactElement, useEffect } from 'react';
import { useCountUp } from 'react-countup';
import InlineSvg from 'react-inlinesvg';

import styles from './TodoFeatureTile.module.css';

type TodoFeatureTileProps = {
  featureType?: string;
  featureIcon: string | ReactElement;
  feature: {
    nameSingular: string;
    namePlural: string;
    subtitleSingular: string;
    subtitlePlural: string;
    tasksCount: number;
  };
};

const TodoFeatureTile = ({
  featureType,
  featureIcon,
  feature: { nameSingular, namePlural, subtitleSingular, subtitlePlural, tasksCount = 0 },
}: TodoFeatureTileProps) => {
  const { countUp, update: countUpUpdate } = useCountUp({ end: tasksCount });
  const name = tasksCount > 1 ? namePlural : nameSingular;
  const description = tasksCount > 1 ? subtitlePlural : subtitleSingular;
  const featureTypeHeader = `${featureType} - ${tasksCount}`;

  useEffect(() => {
    countUpUpdate(tasksCount);
  }, [countUpUpdate, tasksCount]);

  return (
    <div className={styles.container} data-id="MainHeader:toDoListSingleTaskContainer">
      <div className={styles.subcontainer}>
        <div className={styles.icon}>
          {typeof featureIcon === 'string' ? <InlineSvg src={featureIcon} /> : featureIcon}
        </div>
        <span className={styles.counter} data-id="MainHeader:toDoListTaskCounter">
          {countUp}
        </span>
      </div>
      <div className={styles.headersContainer}>
        <p className={styles.header}>{name}</p>
        <p className={styles.subheader}>
          {featureType && featureTypeHeader} {description}
        </p>
      </div>
    </div>
  );
};

export default memo(TodoFeatureTile);
