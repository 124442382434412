const NAMESPACE = 'PAGINATION_PREFERENCE';

export const UPDATE_REQUEST = `${NAMESPACE}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${NAMESPACE}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${NAMESPACE}/UPDATE_FAILURE`;
export const FETCH_REQUEST = `${NAMESPACE}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${NAMESPACE}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${NAMESPACE}/FETCH_FAILURE`;

export const PER_PAGE_VALUES = [10, 25, 50];
