import React, { Component } from 'react';
import cx from 'classnames';
import { first } from 'lodash';
import PropTypes from 'prop-types';

import { piwikHelpers } from 'shared/utils/piwik';
import Gallery from 'components/InvoiceUploader/Gallery/Gallery';
import Preview from 'components/InvoiceUploader/Preview/Preview';

import styles from './PreviewSection.module.css';

const hasId = (id) => (object) => object.id === id;

// Export React component alone for testing purposes
export class PreviewSectionComponent extends Component {
  state = {
    activeFileId: first(this.props.files).id,
    activeFileIndex: 0,
    isGalleryVisible: true,
    renderingInProgress: true,
  };

  static getDerivedStateFromProps(props, state) {
    const { activeFileId } = state;
    const { files: currentFiles } = props;
    const isActiveFile = hasId(activeFileId);
    const isActiveFileDeleted = !currentFiles.find(isActiveFile);

    if (isActiveFileDeleted) {
      const { activeFileIndex } = state;
      const nextFileIndex =
        activeFileIndex >= currentFiles.length ? currentFiles.length - 1 : activeFileIndex;
      return {
        activeFileId: props.files[nextFileIndex].id,
        activeFileIndex: nextFileIndex,
      };
    }
    return null;
  }

  componentDidUpdate(_, { activeFileId: prevActiveId }) {
    const { activeFileId: currentActiveId } = this.state;
    if (prevActiveId !== currentActiveId) {
      this.scrollPreviewIntoView();
    }
  }

  setActiveThumbnailNode = (node) => {
    this.activeThumbnailNode = node;
  };

  scrollPreviewIntoView = () => {
    if (this.activeThumbnailNode) {
      this.activeThumbnailNode.scrollIntoView(false);
    }
  };

  setActiveFileId = (id) => {
    const activeFileIndex = this.props.files.findIndex(hasId(id));
    this.setState({ activeFileIndex, activeFileId: id });
  };

  setNextFileActive = () => {
    const { files } = this.props;
    const { activeFileIndex } = this.state;
    const nextFileId =
      activeFileIndex >= files.length - 1 ? files[1].id : files[activeFileIndex + 1].id;

    this.setActiveFileId(nextFileId);
  };

  setPrevFileActive = () => {
    const { files } = this.props;
    const { activeFileIndex } = this.state;
    const prevFileId =
      activeFileIndex <= 0 ? files[files.length - 1].id : files[activeFileIndex - 1].id;

    this.setActiveFileId(prevFileId);
  };

  setRenderingInProgress = (value) => () => this.setState({ renderingInProgress: value });

  toggleGallery = () => {
    const { trackingPreview } = this.props;
    this.setState({ isGalleryVisible: !this.state.isGalleryVisible });

    if (trackingPreview && this.state.isGalleryVisible) {
      const { category, name } = trackingPreview;
      piwikHelpers.trackEvent(category, name);
    }
  };

  render() {
    const {
      accessToken,
      deleteFile,
      files,
      invoiceId,
      readonly,
      onNewFileButtonClick,
      downloadFilePreview,
      attachUrlWithParams,
      invoiceUploadedFilenameGenerator,
    } = this.props;
    const { activeFileId, isGalleryVisible, renderingInProgress } = this.state;
    const filesWithUrl = files.map(attachUrlWithParams(invoiceId, accessToken));
    const activeFile = filesWithUrl.find(hasId(activeFileId));

    return (
      <div className={styles.main}>
        <Preview
          className={styles.preview}
          file={activeFile}
          invoiceId={invoiceId}
          readonly={readonly}
          downloadFilePreview={downloadFilePreview}
          notifyRenderFinish={this.setRenderingInProgress(false)}
          onClick={this.toggleGallery}
        />
        <Gallery
          activeFileId={activeFileId}
          className={cx(styles.gallery, {
            [styles.isHidden]: !isGalleryVisible,
          })}
          deleteFile={deleteFile(invoiceId)}
          files={filesWithUrl}
          invoiceUploadedFilenameGenerator={invoiceUploadedFilenameGenerator}
          readonly={readonly}
          invoiceId={invoiceId}
          onNewFileButtonClick={onNewFileButtonClick}
          onNextSelect={this.setNextFileActive}
          onPrevSelect={this.setPrevFileActive}
          onSelect={this.setActiveFileId}
          setActiveThumbnailNode={this.setActiveThumbnailNode}
          renderingInProgress={renderingInProgress}
        />
      </div>
    );
  }
}

PreviewSectionComponent.propTypes = {
  accessToken: PropTypes.string.isRequired,
  downloadFilePreview: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  attachUrlWithParams: PropTypes.func.isRequired,
  invoiceUploadedFilenameGenerator: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  invoiceId: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
  onNewFileButtonClick: PropTypes.func.isRequired,
  trackingPreview: PropTypes.shape({
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }),
};

export default PreviewSectionComponent;
