export const FETCH_REQUEST = 'INVOICE_TEMPLATES/FETCH_REQUEST';
export const FETCH_SUCCESS = 'INVOICE_TEMPLATES/FETCH_SUCCESS';
export const FETCH_FAILURE = 'INVOICE_TEMPLATES/FETCH_FAILURE';
export const DELETE_REQUEST = 'INVOICE_TEMPLATES/DELETE_REQUEST';
export const DELETE_SUCCESS = 'INVOICE_TEMPLATES/DELETE_SUCCESS';
export const DELETE_FAILURE = 'INVOICE_TEMPLATES/DELETE_FAILURE';
export const RESET_TEMPLATE_REQUEST = 'INVOICE_TEMPLATES/RESET_TEMPLATE_REQUEST';
export const RESET_TEMPLATE_SUCCESS = 'INVOICE_TEMPLATES/RESET_TEMPLATE_SUCCESS';
export const RESET_TEMPLATE_FAILURE = 'INVOICE_TEMPLATES/RESET_TEMPLATE_FAILURE';
export const UPDATE_REQUEST = 'INVOICE_TEMPLATES/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'INVOICE_TEMPLATES/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'INVOICE_TEMPLATES/UPDATE_FAILURE';
export const INDEX_REQUEST = 'INVOICE_TEMPLATES/INDEX_REQUEST';
export const INDEX_SUCCESS = 'INVOICE_TEMPLATES/INDEX_SUCCESS';
export const INDEX_FAILURE = 'INVOICE_TEMPLATES/INDEX_FAILURE';
export const DOWNLOAD_REQUEST = 'INVOICE_TEMPLATES/DOWNLOAD_REQUEST';
export const DOWNLOAD_SUCCESS = 'INVOICE_TEMPLATES/DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'INVOICE_TEMPLATES/DOWNLOAD_FAILURE';
export const BANK_ACCOUNTS_REQUEST = 'INVOICE_TEMPLATES/BANK_ACCOUNTS_REQUEST';
export const BANK_ACCOUNTS_SUCCESS = 'INVOICE_TEMPLATES/BANK_ACCOUNTS_SUCCESS';
export const BANK_ACCOUNTS_FAILURE = 'INVOICE_TEMPLATES/BANK_ACCOUNTS_FAILURE';
export const CLEAR_SINGLE_TEMPLATE = 'INVOICE_TEMPLATES/CLEAR_SINGLE_TEMPLATE';

export const TEMPLATE_UPDATE_TIMEOUT = 600;
// 3 decimal places for linked net/gross: VRSGE2E-597
export const DECIMAL_PLACES = 3;

export type ResponseBankData = {
  id: string;
  attributes: {
    manual: string;
    ordinalNumber: number;
    bankAccountId?: number;
    bankName?: string;
    bic?: string;
    iban?: string;
  };
};

export type FetchRequestAction = {
  type: typeof FETCH_REQUEST | typeof INDEX_REQUEST;
};

export type UpdateSuccessAction = {
  type: typeof UPDATE_SUCCESS;
  response: any;
};

export type FetchSuccessAction = {
  type: typeof FETCH_SUCCESS | typeof INDEX_SUCCESS;
  response: any;
};

export type FetchBankSuccessAction = {
  type: typeof BANK_ACCOUNTS_SUCCESS;
  response: {
    data: ResponseBankData[];
  };
};

export type DeleteSuccessAction = {
  type: typeof DELETE_SUCCESS;
  response: {
    data: ResponseBankData;
  };
};

export type FetchFailureAction = {
  type: typeof FETCH_FAILURE | typeof INDEX_FAILURE;
};

export type ActionTypes =
  | FetchRequestAction
  | UpdateSuccessAction
  | DeleteSuccessAction
  | FetchSuccessAction
  | FetchFailureAction
  | FetchBankSuccessAction;

export interface IUpdateBankOnInvoice {
  ordinalNumber: number;
  manual: boolean;
  bankAccountId?: number;
  iban?: string;
  bic?: string;
  bankName?: string;
}
