import { numberWithDecimals } from 'shared/utils/form-validation';

const validateCreditLineErrors = (values) => {
  const errors = {
    creditLine: {},
  };

  const creditLineOnSpecificIndex = (index) =>
    Boolean(values.creditLine) && values.creditLine[index];

  const checkboxHasCorrespondingCreditlineFilled = (value, index) =>
    value && Boolean(creditLineOnSpecificIndex(index));

  const validateForErrors = (index) => {
    if (numberWithDecimals(creditLineOnSpecificIndex(index))) {
      errors.creditLine[index] = 'error';
      return errors;
    }
    return errors;
  };
  if (values.accepted) {
    values.accepted.forEach(
      (value, index) =>
        checkboxHasCorrespondingCreditlineFilled(value, index) && validateForErrors(index)
    );
  }
  return errors;
};

export default validateCreditLineErrors;
