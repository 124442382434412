export const INDEX_REQUEST = 'BANKS/INDEX_REQUEST';
export const INDEX_SUCCESS = 'BANKS/INDEX_SUCCESS';
export const INDEX_FAILURE = 'BANKS/INDEX_FAILURE';

export const FETCH_REQUEST = 'BANKS/FETCH_REQUEST';
export const FETCH_SUCCESS = 'BANKS/FETCH_SUCCESS';
export const FETCH_FAILURE = 'BANKS/FETCH_FAILURE';

export const LOGIN_REQUEST = 'BANKS/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'BANKS/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'BANKS/LOGIN_FAILURE';

export const INDEX_ACCOUNTS_REQUEST = 'BANKS/INDEX_ACCOUNTS_REQUEST';
export const INDEX_ACCOUNTS_SUCCESS = 'BANKS/INDEX_ACCOUNTS_SUCCESS';
export const INDEX_ACCOUNTS_FAILURE = 'BANKS/INDEX_ACCOUNTS_FAILURE';

export const CONNECT_ACCOUNT_REQUEST = 'BANKS/CONNECT_ACCOUNT_REQUEST';
export const CONNECT_ACCOUNT_SUCCESS = 'BANKS/CONNECT_ACCOUNT_SUCCESS';
export const CONNECT_ACCOUNT_FAILURE = 'BANKS/CONNECT_ACCOUNT_FAILURE';

// NEW

export const PRE_LOGIN_REQUEST = 'BANKS/PRE_LOGIN_REQUEST';
export const PRE_LOGIN_SUCCESS = 'BANKS/PRE_LOGIN_SUCCESS';
export const PRE_LOGIN_FAILURE = 'BANKS/PRE_LOGIN_FAILURE';

export const ACCESSES_REQUEST = 'BANKS/ACCESSES_REQUEST';
export const ACCESSES_SUCCESS = 'BANKS/ACCESSES_SUCCESS';
export const ACCESSES_FAILURE = 'BANKS/ACCESSES_FAILUER';

export const CUSTOMER_BANKS_REQUEST = 'BANKS/CUSTOMER_BANKS_REQUEST';
export const CUSTOMER_BANKS_SUCCESS = 'BANKS/CUSTOMER_BANKS_SUCCESS';
export const CUSTOMER_BANKS_FAILURE = 'BANKS/CUSTOMER_BANKS_FAILUER';

export const DETAILS_REQUEST = 'BANKS/DETAILS_REQUEST';
export const DETAILS_SUCCESS = 'BANKS/DETAILS_SUCCESS';
export const DETAILS_FAILURE = 'BANKS/DETAILS_FAILURE';

export const INITIALIZE_SCA_REQUEST = 'BANKS/INITIALIZE_SCA_REQUEST';
export const INITIALIZE_SCA_SUCCESS = 'BANKS/INITIALIZE_SCA_SUCCESS';
export const INITIALIZE_SCA_FAILURE = 'BANKS/INITIALIZE_SCA_FAILURE';

export const GET_SCA_REQUEST = 'BANKS/GET_SCA_REQUEST';
export const GET_SCA_SUCCESS = 'BANKS/GET_SCA_SUCCESS';
export const GET_SCA_FAILURE = 'BANKS/GET_SCA_FAILURE';

export const SELECT_SCA_REQUEST = 'BANKS/SELECT_SCA_REQUEST';
export const SELECT_SCA_SUCCESS = 'BANKS/SELECT_SCA_SUCCESS';
export const SELECT_SCA_FAILURE = 'BANKS/SELECT_SCA_FAILURE';

export const SOLVE_SCA_REQUEST = 'BANKS/SOLVE_SCA_REQUEST';
export const SOLVE_SCA_SUCCESS = 'BANKS/SOLVE_SCA_SUCCESS';
export const SOLVE_SCA_FAILURE = 'BANKS/SOLVE_SCA_FAILURE';

export const CREATOR_STEPS_ACCESS_METHOD_SELECTION = 'ACCESS_METHOD_SELECTION';
export const CREATOR_STEPS_SCA_SELECT_TAN = 'SCA_SELECT_TAN';
export const CREATOR_STEPS_TAN_CHALLENGE = 'TAN_CHALLENGE';
export const CREATOR_STEPS_DECOUPLED_CHALLENGE = 'DECOUPLED_CHALLENGE';
export const CREATOR_STEPS_REDIRECT_CHALLENGE = 'REDIRECT_CHALLENGE';
