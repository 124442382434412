import React, { useState } from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';

import caretDown from 'images/common/caret-down.svg';

import styles from './Accordion.module.css';

interface AccordionProps {
  label: string;
  children: React.ReactNode;
  icon?: string;
  isInitiallyOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

const Accordion = ({
  label,
  children,
  icon = caretDown,
  isInitiallyOpen = false,
  onOpen = () => {},
  onClose = () => {},
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  const handleClick = () => {
    setIsOpen((isOpen) => !isOpen);

    if (isOpen) onClose();
    else onOpen();
  };

  return (
    <div className={cx({ [styles.accordionOpen]: isOpen })}>
      <div className={styles.header} onClick={handleClick} data-id="Accordion:header">
        <InlineSvg src={icon} className={styles.icon} />
        <span className={styles.label}>{label}</span>
      </div>
      {isOpen && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default Accordion;
