import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api';

export const getPaginationPreferences = () => {
  const url = '/me/pagination_preferences';

  return api.get<JsonApiResponse>(url);
};

type UpdatePaginationPreferenceData = {
  bank_accounts_bank_transfers?: number;
  cash_transactions?: number;
  clients?: number;
  dashboard?: number;
  datev?: number;
  datev_exports?: number;
  incoming_invoices?: number;
  invoice_clients?: number;
  invoice_suppliers?: number;
  outgoing_invoices?: number;
  product_catalog_groups?: number;
  product_catalog_items?: number;
  proposals?: number;
  recurring_expenses?: number;
  recurring_revenues?: number;
  suppliers?: number;
};

export const updatePaginationPreference = (data: UpdatePaginationPreferenceData) => {
  const url = '/me/pagination_preferences';

  return api.put<JsonApiResponse>(url, { pagination_preference_params: data });
};
