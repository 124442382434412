import React from 'react';
import { FieldArray } from 'redux-form';

import { t } from 'shared/utils';
import Section from 'components/Form/Section/Section';
import InfoIcon from 'components/InfoIcon/InfoIcon';

import SectionWithMargin from '../../SectionWithMargin';
import { FormSelector } from '../Company.types';
import CompanyAuthorizedPeople from '../CompanyAuthorizedPeople/CompanyAuthorizedPeople';

import styles from '../Company.module.css';

type RepresentativeTypes = {
  formSelector: FormSelector;
  SectionComponent?: typeof Section | typeof SectionWithMargin;
  forGeneralPartner?: boolean;
};

const Representative = ({
  formSelector,
  SectionComponent = Section,
  forGeneralPartner = false,
}: RepresentativeTypes) => (
  <SectionComponent title={t('profile.company.sections.representative')}>
    <div className={styles.representativeHeader}>
      {t('profile.company.representative_subheader')}
      <InfoIcon
        tooltipPlacement="bottom-end"
        message={t(
          forGeneralPartner
            ? 'profile.company.general_partner_representative_subheader_info'
            : 'profile.company.representative_subheader_info'
        )}
      />
    </div>
    <FieldArray
      name={
        forGeneralPartner
          ? 'generalPartnerAuthorizedPeopleAttributes'
          : 'companyAuthorizedPeopleAttributes'
      }
      component={CompanyAuthorizedPeople}
      formSelector={formSelector}
      forGeneralPartner={forGeneralPartner}
    />
  </SectionComponent>
);
export default Representative;
