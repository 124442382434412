import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ProductCatalogItemCreator from 'containers/ProductCatalog/Items/common/Creator/Creator';

const EditProductCatalogItem = ({ id }) => <ProductCatalogItemCreator id={id} />;

EditProductCatalogItem.propTypes = {
  id: PropTypes.number,
};

const mapStateToProps = (_, ownProps) => ({ id: ownProps.match.params.id });

export default connect(mapStateToProps)(EditProductCatalogItem);
