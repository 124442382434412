import { Page as BasePage } from 'react-pdf/dist/esm/entry.webpack';
import styled from 'styled-components';

import { Breakpoints } from 'redesign/styles/breakpoints';

export const Wrapper = styled.div`
  padding: ${({ theme }) => `${theme.space['4']} ${theme.space['2']}`};

  @media (min-width: ${Breakpoints.md}) {
    padding: ${({ theme }) => `${theme.space['10']} ${theme.space['4']}`};
  }
`;

export const DocumentWrapper = styled.div`
  position: relative;
  min-height: 200px;
`;

export const Page = styled(BasePage)`
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  width: min-content;

  & + & {
    margin-top: ${({ theme }) => theme.space['4']};
  }
`;

export const ShowPreview = styled.button`
  height: 33px;
  width: 33px;
  padding: 0;
  background: ${({ theme }) => theme.colors.vrblue['100']};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: absolute;
  top: ${({ theme }) => theme.space['2']};
  right: ${({ theme }) => theme.space['2']};

  &:hover {
    background: ${({ theme }) => theme.colors.vrblue['075']};
  }

  svg {
    height: 24.75px;
    width: 24.75px;
  }
`;

export const Download = styled(ShowPreview)`
  right: ${({ theme }) => `calc(${theme.space['2']} * 2 + 33px)`};

  &[disabled],
  &[disabled]:hover {
    background: ${({ theme }) => theme.colors.grey['020']};
    cursor: not-allowed;
  }
`;

export const Tools = styled.div`
  display: grid;
  align-items: center;
  grid-gap: ${({ theme }) => theme.space['4']};
  margin-bottom: ${({ theme }) => theme.space['4']};

  @media (min-width: ${Breakpoints.sm}) {
    display: flex;
    justify-content: space-between;
  }
`;
