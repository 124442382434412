import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { change, WrappedFieldProps } from 'redux-form';

import { CrudMode, EDIT_MODE, SHOW_MODE } from 'constants/common/crud';
import { useAppDispatch, useAppSelector } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import { CategoryResponse } from 'types/entities/Category';
import { ContractSource } from 'types/entities/Contract';
import { TaxOption } from 'types/entities/TaxRate';
import CategorySearch from 'components/CategorySearch/CategorySearch';
import { CurrencyField, DateField, FormField, SelectField, TextField } from 'components/Form';
import {
  getCategory,
  getDefaultTaxRate,
  getTaxRatesOptions,
  taxOptions,
} from 'components/LineItems/utils';
import {
  FORM_NAME,
  NET_AMOUNT,
  RECURRING_TRANSACTION_INTERVAL_ID,
  SUBJECT,
  TRANSACTION_DATE,
} from 'features/contracts/constants';
import {
  getExpenseLineCategories,
  getOldExpenseLineCategories,
} from 'features/contracts/contractsSlice';

import SupplierSection from './SupplierSection';

import styles from '../Fields.module.css';

type BasicExpenseProps = {
  readonly: boolean;
  crudMode: CrudMode;
  contractSource?: ContractSource;
  intervalOptions: { label: any; value: string }[];
  lineCategories: CategoryResponse[];
  selectedCategory: CategoryResponse;
  initialValues: any;
};

const BasicExpense = ({
  intervalOptions,
  readonly,
  crudMode,
  contractSource,
  lineCategories,
  initialValues,
  selectedCategory,
}: BasicExpenseProps) => {
  const showIconForCategorySearch = crudMode === EDIT_MODE;
  const dispatch = useAppDispatch();
  const oldCategories = useAppSelector((state) => state.contracts.oldLineCategories.data);

  const oldCategory = oldCategories?.find(
    (item: any) => item?.id.toString() === selectedCategory?.toString()
  );
  const cat = lineCategories?.find(
    (item: any) => item?.id.toString() === selectedCategory?.toString()
  );

  const [category, setCategory] = useState<CategoryResponse>();
  const [taxRates, setTaxRates] = useState<TaxOption[]>();

  const handleSelectCategory = (item: CategoryResponse) => {
    if (item?.id) {
      setCategory(item);
      setTaxRates(getTaxRatesOptions(item.taxRates));

      dispatch(change(FORM_NAME, 'taxRateId', getDefaultTaxRate(item)?.id));
      dispatch(change(FORM_NAME, 'invoiceLineCategoryId', item?.id));
      // This should for my understanding be `getDefaultTaxRate(item)?.value` - since this is production code
      // I won't change it but we should keep an eye on this.
      // @ts-expect-error
      dispatch(change(FORM_NAME, 'vat', getDefaultTaxRate(item?.taxRates)?.value));
    }
  };

  const setInitialData = () => {
    if (crudMode === SHOW_MODE && !isEmpty(cat)) {
      const initialCategory = getCategory(lineCategories, initialValues.invoiceLineCategoryId);

      setCategory(initialCategory);
      setTaxRates(getTaxRatesOptions(initialCategory?.taxRates));

      dispatch(change(FORM_NAME, 'taxRateId', initialValues?.taxRateId));
      dispatch(change(FORM_NAME, 'invoiceLineCategoryId', initialValues?.invoiceLineCategoryId));
    }

    if (crudMode === SHOW_MODE && !isEmpty(oldCategory)) {
      setCategory(oldCategory);
    }

    if (crudMode === EDIT_MODE && oldCategory) {
      setCategory(undefined);
      dispatch(change(FORM_NAME, 'invoiceLineCategoryId', null));
      dispatch(change(FORM_NAME, 'vat', null));
    }
  };

  useEffect(() => {
    dispatch(getExpenseLineCategories());
    dispatch(getOldExpenseLineCategories());
  }, []);

  useEffect(() => {
    setInitialData();
  }, [initialValues, lineCategories, dispatch, oldCategories, crudMode]);

  return (
    <>
      <FormField
        dataId="Contracts:input-subject"
        name={SUBJECT}
        component={TextField}
        disabled={readonly}
        required
      />
      <FormField
        dataId="Contracts:input-FirstTransaction"
        name={TRANSACTION_DATE}
        dataIds={{
          input: 'Contracts:input-FirstTransaction',
        }}
        component={DateField}
        disabled={readonly}
        required
      />
      <SupplierSection
        required
        className={styles.supplier}
        name="supplier"
        label={t('form.fields.contracts_supplier_basic')}
        readonly={
          // TODO it will be just readonly. This form will not be available in edit mode for VRSO contracts.
          readonly || (crudMode === EDIT_MODE && contractSource === ContractSource.Vrso)
        }
      />
      <FormField
        name={RECURRING_TRANSACTION_INTERVAL_ID}
        component={SelectField}
        dataId="Contracts:dropdown-interval"
        label={t('form.fields.interval')}
        options={intervalOptions}
        disabled={readonly}
        checker={checker}
        required
      />
      <CurrencyField
        dataId="Contracts:input-netAmount"
        label={t('form.fields.contracts_net_amount_basic')}
        name={NET_AMOUNT}
        component={TextField}
        disabled={readonly}
        precision={2}
        required
      />
      <FormField
        name="invoiceLineCategoryId"
        component={({ input: { value, onChange }, meta: { error } }: WrappedFieldProps) => (
          <CategorySearch
            dataId="Contracts:select-category"
            categories={lineCategories}
            selectedItem={category || { ...value }}
            initialSelectedItem={category}
            initialInputValue={category?.name}
            disabled={readonly}
            invalid={error}
            label={`${t('recurring_expenses.form.fields.category')} *`}
            onSelectCategory={handleSelectCategory || onChange}
            withIcon={showIconForCategorySearch}
          />
        )}
      />
      {!isEmpty(oldCategory) ? (
        <FormField
          name="vat"
          options={taxOptions.map((o) => ({ value: o.value, label: o.label }))}
          component={SelectField}
          readonly={readonly}
          dataId="Contracts:input-vat"
          required
        />
      ) : (
        <FormField
          dataId="Contracts:input-taxRateId"
          name="taxRateId"
          options={taxRates}
          component={SelectField}
          readonly={readonly}
          required
        />
      )}
    </>
  );
};

export default BasicExpense;
