/* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as pushAction } from 'connected-react-router';
import { get } from 'lodash';
import { arrayOf, bool, func, shape } from 'prop-types';

import {
  deleteProposal,
  transformProposalIntoOrderConfirmation,
  transformProposalIntoOutgoingInvoice,
} from 'actions/proposal';
import { sortProposals } from 'actions/proposals';
import { FROM_PROPOSAL_QUERY_PARAM } from 'constants/proposal';
import WarningSignImage from 'images/warning-sign.svg';
import paths from 'routes/paths';
import tableStyles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';
import BoxWithButtonRedVariant from 'components/BoxWithButton/BoxWithButtonRedVariant/BoxWithButtonRedVariant';
import If from 'components/Conditions/If';
import I18n from 'components/I18n';
import { ConfirmationModal } from 'components/Modal';
import { CreationForbiddenMessage, EmptyState, TableHeader } from 'components/Table';

import NewProposalButton from '../NewProposalButton';
import ProposalRow from './ProposalRow';

import localStyles from './ProposalsTable.module.css';

export const styles = { ...tableStyles, ...localStyles };

class ProposalsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deletingItem: undefined,
    };
  }

  onClearConfirmations = () => {
    this.setState({ deletingItem: undefined });
  };

  onDelete = (item) => {
    this.onClearConfirmations();
    const { refresh, remove } = this.props;
    remove(item).then(refresh);
  };

  onTransformToOutgoingInvoice = (item) => {
    this.props.transformProposalIntoOutgoingInvoice(item).then(({ data: { id } }) => {
      this.props.push({
        pathname: paths.editOutgoingInvoice(id),
        search: `?${FROM_PROPOSAL_QUERY_PARAM}`,
      });
    });
  };

  onTransformToOrderConfirmation = (item) => {
    this.props.transformProposalIntoOrderConfirmation(item).then(({ data: { id } }) => {
      this.props.push({
        pathname: paths.editOrderConfirmation(id),
        search: `?${FROM_PROPOSAL_QUERY_PARAM}`,
      });
    });
  };

  onRequestDelete = (invoice) => {
    this.setState({ deletingItem: invoice });
  };

  render() {
    const {
      canCreate,
      showModal,
      isFetching,
      data,
      sorting,
      sort,
      refresh,
      isProfileFilled,
      push,
    } = this.props;
    if (!data.length) {
      return (
        <EmptyState isFetching={isFetching}>
          <If ok={canCreate}>
            <NewProposalButton showModal={showModal} />
          </If>
          <If ok={!canCreate}>
            <CreationForbiddenMessage section="proposals" />
          </If>
        </EmptyState>
      );
    }

    const sortableColumns = [
      { column: 'status' },
      { column: 'client', sortable: true },
      { column: 'proposal_number', sortable: true },
      { column: 'subject', sortable: true },
      { column: 'proposal_date', sortable: true },
      { column: 'valid_until', sortable: true },
      { column: 'total_gross_amount', align: 'right', sortable: true },
    ];

    return (
      <div className={styles.invoicesTableWrapper}>
        {!isProfileFilled && (
          <BoxWithButtonRedVariant
            dataId="Proposals:warning-box"
            image={WarningSignImage}
            content={t('empty_entry_pages.proposals.table_warning_box.content_1')}
            contentSecondLine={t('empty_entry_pages.proposals.table_warning_box.content_2')}
            buttonLabel={t('empty_entry_pages.proposals.table_warning_box.button_label')}
            onClick={() => push(paths.companyProfile)}
          />
        )}
        <table
          className={cx(styles.invoicesTable, {
            [styles.invoicesTableLoading]: isFetching,
            [styles.tableWarningMargin]: !isProfileFilled,
          })}
        >
          <thead>
            <tr>
              {sortableColumns.map((column) => (
                <TableHeader
                  {...column}
                  key={column.column}
                  columnWidthClass={styles.column}
                  currentSorting={sorting}
                  refresh={refresh}
                  section="proposals"
                  sort={sort}
                  sortable={column.sortable}
                />
              ))}
              <TableHeader
                column="actions"
                columnWidthClass={styles.column}
                info={t('proposals.table.actions.info_text')}
                refresh={refresh}
                sort={sort}
              />
            </tr>
          </thead>
          <tbody>
            {data.length &&
              data.map((item) => (
                <ProposalRow
                  key={item.id}
                  item={item}
                  onDelete={this.onRequestDelete}
                  onTransformToOutgoingInvoice={this.onTransformToOutgoingInvoice}
                  onTransformToOrderConfirmation={this.onTransformToOrderConfirmation}
                />
              ))}
          </tbody>
        </table>
        <ConfirmationModal
          dangerousAction
          isOpen={!!this.state.deletingItem}
          onClose={this.onClearConfirmations}
          closeLabel={t('proposals.table.confirm_delete.discard')}
          onConfirm={() => this.onDelete(this.state.deletingItem)}
        >
          <I18n t="proposals.table.confirm_delete.message" paragraphs />
        </ConfirmationModal>
      </div>
    );
  }
}

ProposalsTable.propTypes = {
  canCreate: bool.isRequired,
  showModal: bool.isRequired,
  data: arrayOf(shape({})).isRequired,
  isFetching: bool.isRequired,
  push: func.isRequired,
  refresh: func.isRequired,
  remove: func.isRequired,
  sort: func.isRequired,
  sorting: shape({}).isRequired,
  transformProposalIntoOutgoingInvoice: func.isRequired,
  transformProposalIntoOrderConfirmation: func.isRequired,
  isProfileFilled: bool.isRequired,
};

const mapStateToProps = (state) => ({
  canCreate: get(state, 'proposals.meta.actions.create', false),
  showModal: !get(state, 'proposals.meta.bank-account-data-present', false),
  isProfileFilled: state.onboarding.data?.profileFilled,
});

const mapDispatchToProps = {
  push: pushAction,
  remove: deleteProposal,
  sortProposals,
  transformProposalIntoOutgoingInvoice,
  transformProposalIntoOrderConfirmation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProposalsTable);
