import styled, { css } from 'styled-components';

import Button from 'redesign/components/atoms/Button/Button';

interface IWrapper {
  isEditMode: boolean;
}

const EditStyle = css`
  & > :first-child {
    filter: grayscale(1) blur(2px);
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const BlockingOverlay = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const RemoveButton = styled(Button)`
  position: absolute;
  width: ${({ theme }) => theme.space['15']};
  height: ${({ theme }) => theme.space['15']};
  top: 0;
  right: 0;
  border-radius: ${({ theme }) => theme.space['8']};
  color: ${({ theme }) => theme.legacy.colors.white};
  padding: 5px;
  filter: drop-shadow(2px 4px 6px black);
`;

export const Wrapper = styled.div<IWrapper>`
  position: relative;
  width: 100%;
  transition: all 1s ease;
  animation: scaler 0.2s ease-out;

  ${({ isEditMode }) => isEditMode && EditStyle}

  @keyframes scaler {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
`;
