import React from 'react';

const IconEdit = () => (
  <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.155.844C10.172-.139 9.434.008 9.434.008L5.307 4.135.69 8.753 0 12l3.247-.689 4.62-4.617 4.125-4.126s.148-.739-.837-1.724zm-8.1 10.077l-1.108.239a2.53 2.53 0 0 0-.47-.636 2.551 2.551 0 0 0-.636-.47l.239-1.107.32-.32s.602.012 1.283.693c.68.679.693 1.282.693 1.282l-.321.32z"
      fillRule="evenodd"
    />
  </svg>
);

export default IconEdit;
