import { INDEX_FAILURE, INDEX_REQUEST, INDEX_SUCCESS } from 'constants/incoming-invoice/payments';
import { camelizeKeysDeep } from 'shared/utils/entity-mapper';

export const DEFAULT_STATE = {
  list: [],
  isFetching: false,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case INDEX_REQUEST:
      return { ...state, isFetching: true };
    case INDEX_SUCCESS:
      const { payments = [] } = camelizeKeysDeep(action.response);
      const submittedPayments = payments.filter((payment) => payment.submitted);
      return { ...state, list: submittedPayments, isFetching: false };
    case INDEX_FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};
