import React, { Component } from 'react';
import { Manager, Popper, Reference } from 'react-popper-legacy';
import cx from 'classnames';
import { bool, func, node, number, oneOfType, string } from 'prop-types';

import styles from './Tooltip.module.css';

class Tooltip extends Component {
  state = {
    isTooltipVisible: false,
  };

  showTooltip = () => this.setState({ isTooltipVisible: true });

  hideTooltip = () => this.setState({ isTooltipVisible: false });

  tooltipMobileHandler = () => this.setState({ isTooltipVisible: !this.state.isTooltipVisible });

  render() {
    const { children, message, offset, isTooltipHidden, customTooltipStyles, ...props } =
      this.props;
    const { isTooltipVisible } = this.state;

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              {...props}
              ref={ref}
              onMouseOver={this.showTooltip}
              onMouseLeave={this.hideTooltip}
              onTouchEnd={this.tooltipMobileHandler}
              onFocus={this.showTooltip}
              onBlur={this.hideTooltip}
            >
              {children}
            </div>
          )}
        </Reference>
        {isTooltipVisible && !isTooltipHidden && (
          <Popper
            placement="top"
            modifiers={{
              offset: { offset: offset || '0,15' },
              keepTogether: { enabled: true },
              flip: {
                behavior: ['top'],
              },
              preventOverflow: {
                boundariesElement: 'offsetParent',
              },
            }}
          >
            {({ ref, style, placement }) => (
              <div
                className={cx(styles.message, customTooltipStyles)}
                ref={ref}
                style={style}
                data-placement={placement}
              >
                {message}
                <div className={styles.arrow} />
              </div>
            )}
          </Popper>
        )}
      </Manager>
    );
  }
}

Tooltip.propTypes = {
  children: oneOfType([func, node]),
  message: oneOfType([node, string]).isRequired,
  offset: number,
  isTooltipHidden: bool,
};

export default Tooltip;
