import styled from 'styled-components';

import media from 'shared/styles/breakpoints';

export const Option = styled.div`
  cursor: pointer;
  display: flex;
  min-height: 44px;
  padding: 5px 20px 5px 10px;
  align-items: center;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  *:not(:last-child) {
    margin-bottom: 5px;
  }

  ${media.greaterThan('lg-up')`
    flex-direction: row;
    align-items: center;

    *:not(:last-child) {
      margin-bottom: 0;
    }
  `}
`;

export const Date = styled.div`
  flex-basis: auto;
  display: flex;
  margin-right: 15px;
  font-size: 12px;
  color: #777;
  align-items: center;
`;

export const Description = styled.div`
  width: 120px;
  font-size: 14px;
  font-weight: bold;
  margin-right: 15px;
  color: ${({ theme }) => theme.legacy.colors.greyFont};

  ${media.greaterThan('xxsm-up')`
    width: 275px;
  `}
`;

export const Contractor = styled.div`
  margin-right: 15px;
  flex: 1;
`;

type AmountProps = {
  isNegative: boolean;
};

export const Amount = styled.div<AmountProps>`
  width: 50%;
  ${media.greaterThan('md-up')`
    width: 25%;
  `}
  color: ${(props) =>
    props.isNegative ? props.theme.legacy.colors.red : props.theme.legacy.colors.green};
  font-weight: bold;
`;
