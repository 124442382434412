import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { CORRECT_MODE, NEW_MODE } from 'constants/common/crud';
import { FORM_NAME } from 'constants/outgoing-invoice';
import {
  PIWIK_ACTION_REVENUE_ABORT,
  PIWIK_ACTION_REVENUE_AS_DRAFT,
  PIWIK_ACTION_REVENUE_PDF,
  PIWIK_ACTION_REVENUE_SEND_EMAIL,
  PIWIK_ACTION_SAVE_CORRECT_OUTGOING_INVOICE,
  PIWIK_CATEGORY_REVENUE,
} from 'constants/piwik';
import { withTransitionPrevent } from 'shared/hoc';
import { piwikHelpers } from 'shared/utils/piwik';
import If from 'components/Conditions/If';
import { LineItemsAutoSaveConsumer, LineItemsAutoSaveProvider } from 'components/LineItems';

import UploadInvoiceSection from '../OutgoingInvoiceImport/UploadInvoiceSection/UploadInvoiceSection';
import ActionsSection from './ActionsSection';
import BankTransferAssigment from './BankTransferAssigment/bankTransferAssigment';
import CreateInvoiceSection from './CreateInvoiceSection/CreateInvoiceSection';
import ElectronicInvoiceInfo from './CreateInvoiceSection/ElectronicInvoiceInfo/ElectronicInvoiceInfo';
import Notes from './CreateInvoiceSection/Notes/Notes';
import Remark from './CreateInvoiceSection/Remark';
import { mapDispatchToProps, mapStateToProps } from './helper';
import IndividualContactSection from './IndividualContactSection';
import InvoiceDetailsSection from './InvoiceDetailsSection';
import InvoiceSumSection from './InvoiceSumSection';

import styles from './OutgoingInvoiceCreator.module.css';

const defaultIsReadonly = () => false;

const OutgoingInvoiceCreator = ({
  change,
  submitting,
  isReadonly = defaultIsReadonly,
  currentClient,
  individualContact = false,
  sendingDocumentsByEmailEnabled = false,
  salutationHonorific,
  invoiceActionMode,
  handleSubmit,
  saveInvoice,
  invoiceId,
  newInvoice,
  paymentPlan,
  eInvoicesEnabled,
  invoiceDetails,
  invoiceLineItems,
  crudMode,
  editInvoice,
  lineCategories,
  defaultCategory,
  selectedCategory,
  taxRates,
  taxRateId,
  defaultVat,
  isDeprecatedCategory,
  isInitial,
  category,
  handleSelectCategory,
  canSubmit,
  showCategoryChangeConfirmation,
  setShowCategoryChangeConfirmation,
  setLineItemState,
  setShowVatIdWarning,
  showVatIdWarning,
  showDifferentCategoryWarning,
  setShowDifferentCategoryWarning,
  fetchCompany,
  clearDefaultValues,
  forceDirty,
  abort,
  saveInvoiceAsDraft,
  transformInvoiceIntoDeliveryNote,
  downloadInvoice,
  sendInvoice,
  fetchClientDefaults,
  currentValues,
  isSubjectDirty,
  initialValues,
  electronicInvoiceType,
}) => {
  let conversionsToTrack = forceDirty ? 4 : 3;
  const isCorrectInvoice = crudMode === CORRECT_MODE;
  const showSalutation = !(isReadonly('salutation') && !individualContact);
  const isBankTransferAssigmentVisible = !newInvoice && !invoiceDetails.draft && !isCorrectInvoice;
  const { imported } = invoiceDetails;
  const hasSavedLineItems = !!invoiceLineItems.length;

  const abortAndTrack = () => {
    abort();
    if (newInvoice) {
      piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_REVENUE_ABORT);
    }
  };

  const saveAsDraftAndTrack = () => {
    handleSubmit(saveInvoiceAsDraft(invoiceId))();
    if (newInvoice) {
      piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_REVENUE_AS_DRAFT);
    }
  };

  const transformIntoDeliveryNote = () => {
    handleSubmit(transformInvoiceIntoDeliveryNote(invoiceId))();
  };

  const downloadAndTrack = async ({ details }) => {
    await downloadInvoice({
      id: invoiceId,
      details: {
        ...invoiceDetails,
        ...details,
      },
    });

    piwikHelpers.trackGoal(conversionsToTrack);

    if (crudMode === CORRECT_MODE) {
      piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_SAVE_CORRECT_OUTGOING_INVOICE);
    }

    if (newInvoice) {
      piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_REVENUE_PDF);
    }
  };

  const scrollToAnchor = () => {
    const currentUrl = window.location.href;

    const isBankTransferAssigmentVisible =
      !newInvoice && !invoiceDetails.draft && !isCorrectInvoice;

    if (currentUrl.indexOf('#') > 0 && isBankTransferAssigmentVisible) {
      const idInAnchor = currentUrl.substring(currentUrl.indexOf('#') + 1);
      const paymentElement = document.getElementById(idInAnchor);

      paymentElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const sendAndTrack = async ({ email, ccEmail }) => {
    await sendInvoice({ id: invoiceId, email, ccEmail });

    piwikHelpers.trackGoal(conversionsToTrack);
    if (crudMode === CORRECT_MODE) {
      piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_SAVE_CORRECT_OUTGOING_INVOICE);
    }

    if (newInvoice) {
      piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_REVENUE_SEND_EMAIL);
    }
  };

  const handleClientSelected = async (client) => {
    if (!client) return;

    const subject = currentValues ? currentValues.subject : null;

    await fetchClientDefaults(client.id);

    if (isSubjectDirty && !initialValues.subject) change('subject', subject);
    change('client', client);
  };

  /**
   * If new invoice is being created then "electronicInvoiceType" prop is undefined.
   * We should return "electronicInvoiceType" prop for existing invoice.
   * We should set "electronicInvoiceType" to "electronic" if invoice is new and e-invoices are enabled.
   */
  const getElectronicInvoiceType = () => {
    if (!newInvoice) return electronicInvoiceType;

    return eInvoicesEnabled ? 'electronic' : undefined;
  };

  useEffect(() => {
    fetchCompany();
    scrollToAnchor();

    return () => {
      clearDefaultValues();
    };
  }, []);

  return (
    <form className={cx({ [styles.tabsSeparator]: imported && !isCorrectInvoice })}>
      <LineItemsAutoSaveProvider>
        <div className={styles.main}>
          <div
            className={cx(styles.section, styles.creator, {
              [styles.isCreator]: crudMode === NEW_MODE,
            })}
          >
            <div className={styles.createInvoiceSectionWrapper}>
              <If ok={imported && !isCorrectInvoice}>
                <div className={cx(styles.subsection, styles.uploader)}>
                  <UploadInvoiceSection readonly={!editInvoice} />
                </div>
              </If>
              <div className={styles.subsection}>
                <CreateInvoiceSection
                  isReadonly={isReadonly}
                  category={category}
                  newInvoice={newInvoice}
                  isCorrectInvoice={isCorrectInvoice}
                  isDeprecatedCategory={isDeprecatedCategory}
                  selectedCategory={selectedCategory}
                  onClientSelect={handleClientSelected}
                  handleSelectCategory={handleSelectCategory}
                  lineCategories={lineCategories}
                  defaultCategory={defaultCategory}
                  hasSavedLineItems={hasSavedLineItems}
                  crudMode={crudMode}
                  showCategoryChangeConfirmation={showCategoryChangeConfirmation}
                  setShowCategoryChangeConfirmation={setShowCategoryChangeConfirmation}
                  setShowVatIdWarning={setShowVatIdWarning}
                  showVatIdWarning={showVatIdWarning}
                  showDifferentCategoryWarning={showDifferentCategoryWarning}
                  setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
                />
              </div>
            </div>
            <If ok={showSalutation}>
              <IndividualContactSection
                dataIds={{
                  salutation: 'OutgoingInvoicePage:input-salutation',
                  salutationContent: 'OutgoingInvoicePage:input-salutation-content',
                }}
                change={change}
                client={currentClient}
                isReadonly={isReadonly}
                newInvoice={newInvoice}
                isCorrectInvoice={isCorrectInvoice}
                salutationHonorific={salutationHonorific}
              />
            </If>
            <InvoiceDetailsSection
              isNew={newInvoice}
              readonly={isReadonly('lineItems')}
              crudMode={crudMode}
              defaultCategory={defaultCategory}
              isDraft={invoiceDetails?.draft}
              selectedCategory={selectedCategory}
              lineCategories={lineCategories}
              taxRates={taxRates}
              taxRateId={taxRateId}
              defaultVat={defaultVat}
              isInitial={isInitial}
              setLineItemState={setLineItemState}
              setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
            />
            <div className={styles.bottomSection}>
              <Notes disabled={isReadonly('personalNotes')} className={styles.noteCard} />
              <InvoiceSumSection />
            </div>
            <div className={styles.largeSection}>
              {isBankTransferAssigmentVisible && (
                <BankTransferAssigment invoiceDetails={invoiceDetails} isReadonly={isReadonly} />
              )}
              <div className={styles.column}>
                <Remark dataId="OutgoingInvoicePage:notes" readonly={isReadonly('remark')} />
                <ElectronicInvoiceInfo
                  paymentPlan={paymentPlan}
                  eInvoicesEnabled={eInvoicesEnabled}
                  electronicInvoiceType={getElectronicInvoiceType}
                />
              </div>
            </div>
          </div>
        </div>
        <LineItemsAutoSaveConsumer>
          {({ handleSave }) => (
            <ActionsSection
              currentClient={currentClient}
              downloadInvoice={downloadAndTrack}
              sendInvoice={(data) => handleSubmit(handleSave(sendAndTrack(data)))}
              saveInvoice={handleSave(handleSubmit(saveInvoice(invoiceId)))}
              saveInvoiceAsDraft={handleSave(saveAsDraftAndTrack)}
              transformIntoDeliveryNote={handleSave(transformIntoDeliveryNote)}
              abort={abortAndTrack}
              isFormSubmitting={submitting}
              sendingDocumentsByEmailEnabled={sendingDocumentsByEmailEnabled}
              invoiceActionMode={invoiceActionMode}
              crudMode={crudMode}
              canSubmit={canSubmit}
            />
          )}
        </LineItemsAutoSaveConsumer>
      </LineItemsAutoSaveProvider>
    </form>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    persistentSubmitErrors: true,
  }),
  withTransitionPrevent()
)(OutgoingInvoiceCreator);
