import React from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';

import EmptyInfoBox, { InfoBox } from 'containers/Datev/DefaultInfoBox/DefaultInfoBox';
import paths from 'routes/paths';
import {
  exportPasswordSelector,
  taxConsultantEmailSelector,
  taxConsultantNameSelector,
  taxConsultantPhoneSelector,
} from 'selectors/company';
import { t } from 'shared/utils';
import DatevEmptyStateImage from 'components/EmptyEntryPage/images/DatevContact';
import HeaderWithEdit from 'components/HeaderWithEdit/HeaderWithEdit';

import styles from './ContactDetailsBox.module.css';

const NOT_AVAILABLE_LABEL = t('datev.tax_consultant.not_available');

const ContactDetailsBox = ({ name, email, phone, password }) => {
  const isConsultantDataPresent = name && email && password;

  if (!isConsultantDataPresent) {
    return (
      <EmptyInfoBox
        dataIdPrefix="ContactDetailsBox"
        image={DatevEmptyStateImage}
        infoTextHeader={t('datev.contact_details.info_text_heading')}
        headingText={t('datev.contact_details.heading')}
        infoText={t('datev.contact_details.info_text')}
        buttonText={t('datev.contact_details.add_in_settings')}
        buttonPath={paths.taxConsultantProfile}
      />
    );
  }

  return (
    <InfoBox headingTextKey="datev.contact_details.heading">
      <HeaderWithEdit
        buttonPath={paths.taxConsultantProfile}
        iconDataId="Datev:taxConsultant-edit"
        bold
      >
        <p className={styles.infoEntry}>{name}</p>
      </HeaderWithEdit>
      <div className={styles.contactInfo}>
        <div className={styles.contactInfoSection}>
          <p className={styles.infoEntry} data-id="Datev:taxConsultant-email">
            {t('datev.tax_consultant.e_mail')}&nbsp;
            <span className={!email && styles.notAvailable}>{email || NOT_AVAILABLE_LABEL}</span>
          </p>
          <p className={styles.infoEntry} data-id="Datev:taxConsultant-phone">
            {t('datev.tax_consultant.phone')}&nbsp;
            <span className={!phone && styles.notAvailable}>{phone || NOT_AVAILABLE_LABEL}</span>
          </p>
        </div>
        <div className={styles.contactInfoSection}>
          <p className={styles.infoEntry} data-id="Datev:taxConsultant-password">
            {t('datev.tax_consultant.password')}&nbsp;
            <span className={!password && styles.notAvailable}>
              {password ? Array(password.length + 1).join('*') : NOT_AVAILABLE_LABEL}
            </span>
          </p>
        </div>
      </div>
    </InfoBox>
  );
};

ContactDetailsBox.propTypes = {
  name: string,
  email: string,
  phone: string,
  password: string,
};

const mapStateToProps = (state) => ({
  name: taxConsultantNameSelector(state),
  email: taxConsultantEmailSelector(state),
  phone: taxConsultantPhoneSelector(state),
  password: exportPasswordSelector(state),
});

export default connect(mapStateToProps)(ContactDetailsBox);
