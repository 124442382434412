import React, { memo, ReactNode } from 'react';
import cx from 'classnames';

import styles from './Table.module.css';

type BodyCellProps = {
  children?: ReactNode;
  className?: string;
  dataId?: string;
};

const BodyCell = ({ children, dataId, className = '' }: BodyCellProps) => (
  <div className={cx(styles.bodyCell, className)} data-id={dataId}>
    {children}
  </div>
);

export default memo(BodyCell);
