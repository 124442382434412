import { sortingParams } from 'actions/helpers/table';
import { apiFetchPaymentsBankAccounts } from 'actions/incoming-invoice/payments';
import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FETCH_FAILURE,
  FETCH_OUT_OF_SYNC_FAILURE,
  FETCH_OUT_OF_SYNC_REQUEST,
  FETCH_OUT_OF_SYNC_SUCCESS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_TRANSFERS_FAILURE,
  FETCH_TRANSFERS_REQUEST,
  FETCH_TRANSFERS_SUCCESS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SUCCESS,
  REORDER_FAILURE,
  REORDER_REQUEST,
  REORDER_SUCCESS,
  SET_MAIN_FAILURE,
  SET_MAIN_REQUEST,
  SET_MAIN_SUCCESS,
  SYNC_FAILURE,
  SYNC_REQUEST,
  SYNC_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/bank-account';
import { Resources } from 'constants/resources';
import { accountOutOfSync } from 'notifications/bank-account';
import { getOutOfSyncAccounts } from 'reducers';
import { getSanitizedFilters } from 'reducers/common/filters';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

export const apiFetchBankAccount = (id) => ({
  [CALL_API]: {
    endpoint: `/me/bank_accounts/${id}`,
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiFetchBankAccounts = (config = {}) => ({
  [CALL_API]: {
    endpoint: '/me/bank_accounts',
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
    ...config,
  },
});

export const apiFetchOutOfSync = () => ({
  [CALL_API]: {
    endpoint: '/me/bank_accounts/out_of_sync',
    types: [FETCH_OUT_OF_SYNC_REQUEST, FETCH_OUT_OF_SYNC_SUCCESS, FETCH_OUT_OF_SYNC_FAILURE],
  },
});

export const apiFetchBankAccountTransfers = (bankAccountId, pagination, sorting, rawFilters) => {
  const filters = getSanitizedFilters(rawFilters);
  return {
    [CALL_API]: {
      params: {
        pagination_resource: Resources.BANK_ACCOUNTS_BANK_TRANSFERS,
        ...pagination,
        ...sortingParams(sorting),
        filters,
      },
      endpoint: `/me/bank_accounts/${bankAccountId}/bank_transfers`,
      types: [FETCH_TRANSFERS_REQUEST, FETCH_TRANSFERS_SUCCESS, FETCH_TRANSFERS_FAILURE],
    },
  };
};

export const apiCreateBankAccount = (data) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: '/me/bank_accounts',
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const apiReorderBankAccounts = (data) => ({
  [CALL_API]: {
    data,
    method: 'PATCH',
    endpoint: '/me/bank_accounts/reorder',
    types: [REORDER_REQUEST, REORDER_SUCCESS, REORDER_FAILURE],
  },
});

export const apiUpdateBankAccount = ({ id, name, bankName, showOnInvoice, creditLine }) => ({
  [CALL_API]: {
    data: { id, name, bankName, showOnInvoice, creditLine },
    method: 'PUT',
    endpoint: `/me/bank_accounts/${id}`,
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const apiSetMainBankAccount = (id) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: `/me/bank_accounts/${id}/set_main_bank_account`,
    types: [SET_MAIN_REQUEST, SET_MAIN_SUCCESS, SET_MAIN_FAILURE],
  },
});

export const apiDeleteBankAccount = (id) => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: `/me/bank_accounts/${id}`,
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const apiSyncBankAccount = (id) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: `/me/bank_accounts/${id}/sync`,
    types: [SYNC_REQUEST, SYNC_SUCCESS, SYNC_FAILURE],
  },
});

export const fetchBankAccount =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchBankAccount(...args)).catch(apiErrorHandler);

export const syncBankAccount =
  (...args) =>
  (dispatch) =>
    dispatch(apiSyncBankAccount(...args)).catch(apiErrorHandler);

export const fetchBankAccounts = (config) => (dispatch) =>
  dispatch(apiFetchBankAccounts(config)).catch(apiErrorHandler);

export const fetchBankAccountTransfers =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchBankAccountTransfers(...args)).catch(apiErrorHandler);

export const createBankAccount = (data) => (dispatch) =>
  dispatch(apiCreateBankAccount(data)).catch(apiErrorHandler);

export const updateBankAccount = (data) => (dispatch) =>
  bindServerValidation(apiUpdateBankAccount(data), dispatch);

export const setMainBankAccount =
  (...args) =>
  (dispatch) =>
    dispatch(apiSetMainBankAccount(...args))
      .then(() => dispatch(apiFetchBankAccounts()))
      .then(() => dispatch(apiFetchPaymentsBankAccounts()))
      .catch(apiErrorHandler);

// this one is added for the case when we don't need addonational apiFetchBankAccounts
export const setMainPaymentBankAccount =
  (...args) =>
  (dispatch) =>
    dispatch(apiSetMainBankAccount(...args))
      .then(() => dispatch(apiFetchPaymentsBankAccounts()))
      .catch(apiErrorHandler);

export const deleteBankAccount = (id) => (dispatch) =>
  dispatch(apiDeleteBankAccount(id))
    .then(() => dispatch(apiFetchBankAccounts()))
    .catch(apiErrorHandler);

export const checkOutOfSyncAndNotify = () => (dispatch, getState) =>
  dispatch(apiFetchOutOfSync())
    .then(() => {
      const accounts = getOutOfSyncAccounts(getState());
      return Promise.all(
        accounts.map((account) => dispatch(showNotification(accountOutOfSync(account.bankName))))
      );
    })
    .catch(apiErrorHandler);
