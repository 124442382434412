import { CALL_API } from 'constants/api';
import {
  DOWNLOAD_FAILURE,
  DOWNLOAD_REQUEST,
  DOWNLOAD_SUCCESS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SUCCESS,
} from 'constants/datev-download';
import { downloadData } from 'shared/utils';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiFetchDatevDownload = (token) => ({
  [CALL_API]: {
    authRequired: false,
    endpoint: `/datev_reports/${token}`,
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
  },
});

export const fetch = (token) => (dispatch) => {
  dispatch(apiFetchDatevDownload(token)).catch(apiErrorHandler);
};

export const apiDownload = (token, grecaptchaResponse, password) => ({
  [CALL_API]: {
    method: 'POST',
    data: {
      g_recaptcha_response: grecaptchaResponse,
      password,
    },
    authRequired: false,
    responseType: 'arraybuffer',
    endpoint: `/datev_reports/${token}/download`,
    types: [DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE],
  },
});

export const download =
  (token, grecaptchaResponse, { password }) =>
  (dispatch) =>
    dispatch(apiDownload(token, grecaptchaResponse, password))
      .then(({ rawResponse }) => {
        downloadData(rawResponse, `datev-${token}`, 'zip');
      })
      .catch(apiErrorHandler);

export const resetDownload = () => (dispatch) => dispatch({ type: DOWNLOAD_FAILURE });
