import React, { Component, Fragment } from 'react';
import { arrayOf, bool, func, oneOfType, shape } from 'prop-types';

import { t } from 'shared/utils';
import { ConfirmationModal } from 'components/Modal';

import Label from './components/Label';

class LabelsSection extends Component {
  state = {
    isOpen: false,
    labelId: null,
  };

  triggerModal = (labelId = null) =>
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
      labelId,
    }));

  handleOnDelete = () => {
    const { deleteLabel } = this.props;
    const { labelId } = this.state;
    deleteLabel(labelId).then(() => this.triggerModal());
  };

  handleOnEdit = (labelId) => {
    const { onEdit } = this.props;
    onEdit(labelId);
  };

  render() {
    const { isBankTransferPositive, labels } = this.props;
    const { isOpen } = this.state;

    return (
      <Fragment>
        {labels.map((label, index) => (
          <Label
            key={`${label.id}-${index}`}
            isBankTransferPositive={isBankTransferPositive}
            onEdit={() => this.handleOnEdit(label.id)}
            onDelete={() => this.triggerModal(label.id)}
            canEdit
            {...label}
          />
        ))}
        {isOpen && (
          <ConfirmationModal
            isOpen={isOpen}
            onConfirm={this.handleOnDelete}
            onClose={this.triggerModal}
            dangerousAction
          >
            {t('bank_transfers.label_assignment.delete_label_modal_description')}
          </ConfirmationModal>
        )}
      </Fragment>
    );
  }
}

LabelsSection.propTypes = {
  isBankTransferPositive: bool.isRequired,
  labels: oneOfType([arrayOf(shape({})), shape({})]),
  onEdit: func.isRequired,
  deleteLabel: func.isRequired,
};

export default LabelsSection;
