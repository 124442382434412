import React, { memo, SVGProps } from 'react';

const WelcomeDay = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 311 154" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <linearGradient x1=".201%" y1=".948%" x2="86.808%" y2="99.45%" id="Welcome-day_svg__a">
        <stop stopColor="#FFDC80" offset="0%" />
        <stop stopColor="#FFC700" offset="100%" />
      </linearGradient>
      <linearGradient x1="59.542%" y1="70.838%" x2="0%" y2="19.361%" id="Welcome-day_svg__b">
        <stop stopColor="#090547" offset="0%" />
        <stop stopColor="#3FCBFF" offset="100%" />
      </linearGradient>
      <linearGradient x1="55.26%" y1="56.818%" x2="0%" y2="41.856%" id="Welcome-day_svg__c">
        <stop stopColor="#0066B3" offset="0%" />
        <stop stopColor="#3FCBFF" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="Welcome-day_svg__d">
        <stop stopColor="#09BCF6" offset="0%" />
        <stop stopColor="#0487EA" offset="100%" />
      </linearGradient>
    </defs>
    <g transform="translate(12.546 .657)" fill="none" fillRule="evenodd">
      <ellipse fill="url(#Welcome-day_svg__a)" cx={212.946} cy={36.444} rx={36.646} ry={36.444} />
      <path
        d="M289.386 68.13c.03-.585.046-1.173.046-1.764 0-18.501-15.046-33.5-33.606-33.5-3.181 0-6.26.442-9.177 1.266a24.068 24.068 0 00-16.71-6.715c-8.903 0-16.674 4.813-20.853 11.967a24.18 24.18 0 00-7.125-1.068c-13.321 0-24.119 10.764-24.119 24.043 0 1.99.247 3.922.704 5.77h110.84zM173.059 33.49c.023-.443.035-.889.035-1.337 0-14.025-11.485-25.395-25.652-25.395-2.429 0-4.778.334-7.005.958a18.44 18.44 0 00-12.755-5.09 18.433 18.433 0 00-15.918 9.072 18.574 18.574 0 00-5.438-.809c-10.168 0-18.41 8.16-18.41 18.226 0 1.508.188 2.973.537 4.374h84.606zM81.945 51.876c.018-.349.028-.7.028-1.053 0-11.04-9.08-19.992-20.283-19.992-1.92 0-3.778.264-5.54.755a14.615 14.615 0 00-10.084-4.007 14.587 14.587 0 00-12.586 7.142 14.746 14.746 0 00-4.3-.637c-8.04 0-14.558 6.424-14.558 14.348 0 1.187.15 2.34.426 3.444h66.897z"
        fill="#EEF2F6"
      />
      <g fill="#C7E8FB">
        <path d="M233.975 28.156h24.246M233.975 39.335h24.246M233.975 33.745h17.242" />
      </g>
      <path fill="#FFEDBA" d="M229.863 118.34l-84.146 26.09-12.58-40.164 84.145-26.09z" />
      <path
        stroke="#FBB900"
        strokeWidth={1.275}
        d="M229.863 118.34l-84.146 26.09-12.58-40.164 84.145-26.09z"
      />
      <path fill="#FFBF3A" d="M225.9 116.707l-78.379 24.301-10.947-34.947 78.38-24.302z" />
      <path
        stroke="#FBB900"
        strokeWidth={1.184}
        d="M225.9 116.707l-78.379 24.301-10.947-34.947 78.38-24.302z"
      />
      <path
        d="M197.793 106.394c2.797 8.93-2.212 18.426-11.188 21.209-8.976 2.783-18.52-2.2-21.317-11.13-2.798-8.931 2.212-18.427 11.187-21.21 8.976-2.783 18.52 2.2 21.318 11.13"
        fillOpacity={0.5}
        fill="#FFF"
      />
      <path
        d="M197.793 106.394c2.797 8.93-2.212 18.426-11.188 21.209-8.976 2.783-18.52-2.2-21.317-11.13-2.798-8.931 2.212-18.427 11.187-21.21 8.976-2.783 18.52 2.2 21.318 11.13z"
        stroke="#FFD760"
        strokeWidth={1.184}
      />
      <path
        d="M163.715 116.96c1.128 3.6-.892 7.43-4.511 8.552-3.62 1.122-7.468-.887-8.596-4.488-1.128-3.602.892-7.43 4.511-8.552 3.62-1.122 7.468.887 8.596 4.488"
        fill="#FFEDBA"
      />
      <path
        d="M163.715 116.96c1.128 3.6-.892 7.43-4.511 8.552-3.62 1.122-7.468-.887-8.596-4.488-1.128-3.602.892-7.43 4.511-8.552 3.62-1.122 7.468.887 8.596 4.488z"
        stroke="#FFD760"
        strokeWidth={0.5}
      />
      <path
        d="M213.783 101.436c1.128 3.6-.892 7.43-4.51 8.552-3.62 1.122-7.469-.887-8.597-4.488-1.128-3.601.892-7.43 4.512-8.552 3.62-1.122 7.468.887 8.595 4.488"
        fillOpacity={0.5}
        fill="#FFF"
      />
      <path
        d="M213.783 101.436c1.128 3.6-.892 7.43-4.51 8.552-3.62 1.122-7.469-.887-8.597-4.488-1.128-3.601.892-7.43 4.512-8.552 3.62-1.122 7.468.887 8.595 4.488z"
        stroke="#FFD760"
        strokeWidth={0.5}
      />
      <g>
        <path fill="#FFEDBA" d="M139.72 147.63h88.138v-42.069H139.72z" />
        <path stroke="#FBB900" strokeWidth={1.275} d="M139.72 147.63h88.138v-42.069H139.72z" />
        <path fill="#FFBF3A" d="M142.466 144.899h82.097v-36.606h-82.097z" />
        <path stroke="#FBB900" strokeWidth={1.184} d="M142.466 144.899h82.097v-36.606h-82.097z" />
        <path
          d="M200.813 126.733c0 9.354-7.622 16.936-17.024 16.936-9.402 0-17.023-7.582-17.023-16.936s7.621-16.937 17.023-16.937 17.024 7.583 17.024 16.937"
          fillOpacity={0.5}
          fill="#FFF"
        />
        <path
          d="M200.813 126.733c0 9.354-7.622 16.936-17.024 16.936-9.402 0-17.023-7.582-17.023-16.936s7.621-16.937 17.023-16.937 17.024 7.583 17.024 16.937z"
          stroke="#FFD760"
          strokeWidth={1.184}
        />
        <path
          d="M165.118 126.733c0 3.771-3.073 6.829-6.864 6.829s-6.864-3.058-6.864-6.83c0-3.771 3.073-6.829 6.864-6.829 3.79 0 6.864 3.058 6.864 6.83"
          fill="#FFEDBA"
        />
        <path
          d="M165.118 126.733c0 3.771-3.073 6.829-6.864 6.829s-6.864-3.058-6.864-6.83c0-3.771 3.073-6.829 6.864-6.829 3.79 0 6.864 3.058 6.864 6.83z"
          stroke="#FFD760"
          strokeWidth={0.5}
        />
        <path
          d="M217.561 126.733c0 3.771-3.073 6.829-6.864 6.829-3.79 0-6.864-3.058-6.864-6.83 0-3.771 3.073-6.829 6.864-6.829s6.864 3.058 6.864 6.83"
          fill="#FFEDBA"
        />
        <path
          d="M217.561 126.733c0 3.771-3.073 6.829-6.864 6.829-3.79 0-6.864-3.058-6.864-6.83 0-3.771 3.073-6.829 6.864-6.829s6.864 3.058 6.864 6.83z"
          stroke="#FFD760"
          strokeWidth={0.5}
        />
      </g>
      <g>
        <path
          d="M98.42 49.148c-.151-1.408 2.424-24.669-6.283-21.075-8.707 3.594-10.75 13.772-16.094 6.83C70.7 27.964 42.821-10.804 41.56 2.918c-1.892 20.592-7.786 7.53-17.573 3.607-21.14-8.471-41.1 15.655-.39 30.33 40.71 14.677 12.736 14.882 12.736 14.882S19.336 67.3 57.481 68.383c38.144 1.084 40.049 8.85 40.049 8.85l.89-28.085z"
          fill="url(#Welcome-day_svg__b)"
          transform="translate(0 59.223)"
        />
        <path
          d="M64.625 103.524c-.76-4.16-5.455-26.61-17.756-38.24M40.653 115.18c1.67.724 22.843-3.581 31.62-5.217M16.904 78.06s61.209 28.241 75.402 50.306"
          stroke="#155FAA"
          strokeWidth={1.378}
          strokeLinecap="round"
        />
      </g>
      <g>
        <path fill="#FFF" d="M128.644 134.04l-52.17 15.16-21.66-73.779 52.17-15.16z" />
        <path
          stroke="#E6E6E6"
          strokeWidth={0.66}
          d="M128.644 134.04l-52.17 15.16-21.66-73.779 52.17-15.16z"
        />
        <path
          stroke="#4D4D4D"
          strokeWidth={1.981}
          strokeLinecap="round"
          d="M66.926 97.936l14.436-4.196M67.75 100.744l14.437-4.195"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.732}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M62.617 83.258l5.725-1.664M62.955 84.41l6.756-1.964M63.293 85.56l5.725-1.663"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.887}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M80.073 142.717l8.393-2.44"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.818}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M80.411 143.869l5.725-1.664"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.887}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M80.75 145.02l8.392-2.44"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.818}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M92.15 139.208l7.148-2.078M92.487 140.359l8.437-2.452M92.825 141.51l7.15-2.077M102.674 136.149l7.15-2.077M103.012 137.3l6.534-1.898M103.35 138.452l7.15-2.078"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.732}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M63.631 86.712l6.756-1.963"
        />
        <path
          stroke="#4D4D4D"
          strokeWidth={1.981}
          strokeLinecap="round"
          d="M68.431 103.062l12.523-3.64M71.643 113.688l8.511-2.652M106.927 102.693l5.355-1.668M82.352 110.35l21.362-6.656M73.152 118.482l8.512-2.652M108.437 107.488l5.354-1.67M83.861 115.145l21.363-6.656M74.326 122.21l8.511-2.651M109.61 111.217l5.355-1.668M85.035 118.875l21.363-6.657M75.835 127.006l8.512-2.652M111.12 116.012l5.354-1.67M86.544 123.669l21.363-6.656"
        />
        <text
          fontFamily="FrutigerVR-Bold, Frutiger VR"
          fontSize={15.569}
          fontWeight="bold"
          fill="#EB5C0B"
          transform="translate(54.814 60.26)"
        >
          <tspan x={58.757} y={64.422}>
            {'\u20AC'}
          </tspan>
        </text>
        <path
          d="M99.345 71.622a3.97 3.97 0 01-2.522 5.03c-2.092.696-4.356-.427-5.055-2.51a3.97 3.97 0 012.521-5.03c2.093-.695 4.356.428 5.056 2.51"
          fill="#EB5C0B"
        />
        <path
          d="M105.373 69.616a3.97 3.97 0 01-2.522 5.03c-2.092.697-4.356-.427-5.056-2.509a3.97 3.97 0 012.522-5.03c2.092-.696 4.356.427 5.056 2.51"
          fill="#52B4E7"
        />
        <path
          d="M103.54 74.417a3.97 3.97 0 01-2.522 5.03c-2.092.696-4.356-.427-5.056-2.509a3.97 3.97 0 012.522-5.03c2.092-.696 4.356.427 5.056 2.51"
          fill="#0F2364"
        />
      </g>
      <g>
        <path
          d="M76.841 21.19C70.288 19.168 42.957-11.61 0 4.799c0 0 12.48 38.697 63.107 25.59 1.053.2 18.902-4.241 18.902-4.241L76.84 21.19z"
          fill="url(#Welcome-day_svg__c)"
          transform="translate(33.709 119.063)"
        />
        <path
          d="M113.768 145.31s-7.82-1.02-18.444-2.988c-15.578-2.886-44.24-8.413-56.056-15.135M91.421 141.21c.201-.2-5.034-10.217-14.297-13.423M71.889 138.005s-9.464 4.808-22.15 2.204M64.64 123.58s5.582 5.247 4.503 12.84M54.17 132.996s-2.82-7.412-9.062-10.818M87.873 141.299s-6.721 5.721-18.602 8.326"
          stroke="#26295B"
          strokeWidth={0.976}
          strokeLinecap="round"
        />
      </g>
      <g>
        <path fill="#436BEE" d="M196.577 148.14h17.168v-45.783h-17.168z" />
        <path fill="#2747B0" d="M221.008 148.141h17.168V75.56h-17.168z" />
        <path fill="#0F2364" d="M245.44 148.14h17.167V89.715H245.44z" />
      </g>
      <path
        stroke="#615D5C"
        strokeWidth={1.275}
        strokeLinecap="round"
        d="M156.059 147.929l113.672.146"
      />
      <g>
        <path
          d="M180.792 149.997h-66.25c-3.84 0-6.953-3.096-6.953-6.916V55.418c0-3.82 3.113-6.916 6.952-6.916h66.251c3.84 0 6.952 3.096 6.952 6.916v87.663c0 3.82-3.113 6.916-6.952 6.916"
          fill="#0F2364"
        />
        <path
          d="M70.745 29.482H8.962c-.993 0-1.797-.8-1.797-1.788v-16.24c0-.987.804-1.788 1.797-1.788h61.783c.992 0 1.797.8 1.797 1.788v16.24c0 .987-.805 1.788-1.797 1.788"
          fill="url(#Welcome-day_svg__d)"
          transform="translate(107.59 48.502)"
        />
        <path
          d="M127.034 102.456h-10.852c-.7 0-1.268-.565-1.268-1.261v-7.11c0-.697.568-1.262 1.268-1.262h10.852c.7 0 1.267.565 1.267 1.261v7.11c0 .697-.567 1.262-1.267 1.262M144.257 102.456h-10.852c-.7 0-1.267-.565-1.267-1.261v-7.11c0-.697.567-1.262 1.267-1.262h10.852c.7 0 1.268.565 1.268 1.261v7.11c0 .697-.568 1.262-1.268 1.262M161.48 102.456H150.63c-.7 0-1.268-.565-1.268-1.261v-7.11c0-.697.567-1.262 1.268-1.262h10.851c.7 0 1.268.565 1.268 1.261v7.11c0 .697-.568 1.262-1.268 1.262"
          fill="#0487EA"
        />
        <path
          d="M132.059 87.482h-16.082a1.06 1.06 0 01-1.063-1.057v-2.862a1.06 1.06 0 011.063-1.057h16.082a1.06 1.06 0 011.063 1.057v2.862a1.06 1.06 0 01-1.063 1.057"
          fill="#E54065"
        />
        <path
          d="M155.484 87.482h-16.082a1.06 1.06 0 01-1.063-1.057v-2.862a1.06 1.06 0 011.063-1.057h16.082a1.06 1.06 0 011.062 1.057v2.862a1.06 1.06 0 01-1.062 1.057"
          fill="#FBB900"
        />
        <path
          d="M178.908 87.482h-16.082a1.06 1.06 0 01-1.062-1.057v-2.862a1.06 1.06 0 011.062-1.057h16.082a1.06 1.06 0 011.063 1.057v2.862a1.06 1.06 0 01-1.063 1.057"
          fill="#C7E8FB"
        />
        <path
          d="M178.908 87.482h-16.082a1.06 1.06 0 01-1.062-1.057v-2.862a1.06 1.06 0 011.062-1.057h16.082a1.06 1.06 0 011.063 1.057v2.862a1.06 1.06 0 01-1.063 1.057z"
          stroke="#C7E8FB"
          strokeWidth={0.5}
        />
        <path
          d="M177.54 128.248h-8.525a2.425 2.425 0 01-2.431-2.418V95.242a2.425 2.425 0 012.43-2.419h8.526a2.425 2.425 0 012.431 2.419v30.588a2.425 2.425 0 01-2.43 2.418"
          fill="#FBB900"
        />
        <path
          d="M127.034 115.451h-10.852c-.7 0-1.268-.564-1.268-1.261v-7.11c0-.697.568-1.262 1.268-1.262h10.852c.7 0 1.267.565 1.267 1.262v7.11c0 .697-.567 1.261-1.267 1.261M144.257 115.451h-10.852c-.7 0-1.267-.564-1.267-1.261v-7.11c0-.697.567-1.262 1.267-1.262h10.852c.7 0 1.268.565 1.268 1.262v7.11c0 .697-.568 1.261-1.268 1.261M161.48 115.451H150.63c-.7 0-1.268-.564-1.268-1.261v-7.11c0-.697.567-1.262 1.268-1.262h10.851c.7 0 1.268.565 1.268 1.262v7.11c0 .697-.568 1.261-1.268 1.261M127.034 128.446h-10.852c-.7 0-1.268-.565-1.268-1.26v-7.111c0-.697.568-1.261 1.268-1.261h10.852c.7 0 1.267.564 1.267 1.26v7.111c0 .696-.567 1.261-1.267 1.261M144.257 128.446h-10.852c-.7 0-1.267-.565-1.267-1.26v-7.111c0-.697.567-1.261 1.267-1.261h10.852c.7 0 1.268.564 1.268 1.26v7.111c0 .696-.568 1.261-1.268 1.261M161.48 128.446H150.63c-.7 0-1.268-.565-1.268-1.26v-7.111c0-.697.567-1.261 1.268-1.261h10.851c.7 0 1.268.564 1.268 1.26v7.111c0 .696-.568 1.261-1.268 1.261"
          fill="#0487EA"
        />
        <path
          d="M143.565 141.441H116.83a1.91 1.91 0 01-1.916-1.906v-5.82a1.91 1.91 0 011.916-1.906h26.735a1.91 1.91 0 011.916 1.905v5.821a1.91 1.91 0 01-1.916 1.906"
          fill="#FBB900"
        />
        <path
          d="M161.48 141.441H150.63c-.7 0-1.268-.564-1.268-1.26v-7.111c0-.697.567-1.261 1.268-1.261h10.851c.7 0 1.268.564 1.268 1.26v7.111c0 .697-.568 1.261-1.268 1.261"
          fill="#0066B3"
        />
        <path
          d="M178.703 141.441h-10.851c-.7 0-1.268-.564-1.268-1.26v-7.111c0-.697.568-1.261 1.268-1.261h10.851c.7 0 1.268.564 1.268 1.26v7.111c0 .697-.567 1.261-1.268 1.261"
          fill="#EB5C0B"
        />
      </g>
    </g>
  </svg>
);

export default memo(WelcomeDay);
