import React, { useState } from 'react';
import InlineSvg from 'react-inlinesvg';
import { connect } from 'react-redux';

import { removeExternalToken as removeExternalTokenAction } from 'actions/external-tokens';
import { MODAL_HEADER_VARIANT } from 'constants/modal';
import { ACTION_CANCEL_REMOVE_CODE, ACTION_REMOVE_CODE, CATEGORY_FARMPILOT } from 'constants/piwik';
import trashBin from 'images/common/trash-bin.svg';
import {
  getFarmpilotAppId as getFarmpilotAppIdSelector,
  isFarmpilotIntegrationActive,
} from 'selectors/app-settings';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import { ConfirmationModal } from 'components/Modal';

import styles from './RemoveConnectionButton.module.css';

interface RemoveConnectionButtonProps {
  removeExternalToken: (appId: string) => void;
  farmpilotAppId: string | null;
  isFarmpilotIntegrationActive: boolean;
}

const buttonLabel = t('farmpilot.connection_section.buttons.remove_connection');
const header = t('farmpilot.connection_section.remove_connection_modal.header');
const confirmationMessage1 = t('farmpilot.connection_section.remove_connection_modal.message1');
const confirmationMessage2 = t('farmpilot.connection_section.remove_connection_modal.message2');
const confirmButtonLabel = t('farmpilot.connection_section.remove_connection_modal.confirm');
const cancelButtonLabel = t('farmpilot.connection_section.remove_connection_modal.cancel');

const trackRemoveCode = () => piwikHelpers.trackEvent(CATEGORY_FARMPILOT, ACTION_REMOVE_CODE);
const trackCancelRemoveCode = () =>
  piwikHelpers.trackEvent(CATEGORY_FARMPILOT, ACTION_CANCEL_REMOVE_CODE);

export const RemoveConnectionButton = ({
  removeExternalToken = () => {},
  farmpilotAppId = null,
  isFarmpilotIntegrationActive = false,
}: RemoveConnectionButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClose = () => {
    setIsModalOpen(false);
    trackCancelRemoveCode();
  };
  const handleConfirm = () => {
    if (isFarmpilotIntegrationActive && farmpilotAppId !== null) {
      removeExternalToken(farmpilotAppId);
      trackRemoveCode();
    }

    setIsModalOpen(false);
  };

  return (
    <>
      <button
        className={styles.button}
        data-id="Farmpilot:button-remove-connection"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <InlineSvg src={trashBin} className={styles.icon} />
        {buttonLabel}
      </button>
      <ConfirmationModal
        isOpen={isModalOpen}
        headerVariant={MODAL_HEADER_VARIANT.SMALL}
        onClose={handleClose}
        onConfirm={handleConfirm}
        header={header}
        closeLabel={cancelButtonLabel}
        confirmLabel={confirmButtonLabel}
        hasWarningIcon
        withCloseButton
      >
        <div>{confirmationMessage1}</div>
        <div>{confirmationMessage2}</div>
      </ConfirmationModal>
    </>
  );
};

const connection = connect(
  (state) => ({
    farmpilotAppId: getFarmpilotAppIdSelector(state),
    isFarmpilotIntegrationActive: isFarmpilotIntegrationActive(state),
  }),
  { removeExternalToken: removeExternalTokenAction }
);

export default connection(RemoveConnectionButton);
