import { CALL_API } from 'constants/api';
import { OBS_FETCH_FAILURE, OBS_FETCH_REQUEST, OBS_FETCH_SUCCESS } from 'constants/liquidity';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiFetchOBSQualified = () => ({
  [CALL_API]: {
    endpoint: '/me/liquidity/obs_qualified',
    method: 'GET',
    types: [OBS_FETCH_REQUEST, OBS_FETCH_SUCCESS, OBS_FETCH_FAILURE],
  },
});

export const fetchOBSQualified = () => (dispatch) =>
  dispatch(apiFetchOBSQualified()).catch(apiErrorHandler);
