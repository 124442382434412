import React, { ChangeEvent, ReactNode, useEffect } from 'react';

import InputField from 'redesign/components/molecules/InputField/InputField';

type EditableInputProps = {
  value: string;
  updateData: (value: string) => void;
  icon?: ReactNode;
};

const EditableInput = ({ value: initialValue, updateData, icon }: EditableInputProps) => {
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateData(value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <InputField value={value} onChange={onChange} onBlur={onBlur} icon={icon} />;
};

export default EditableInput;
