import styled from 'styled-components';

import BaseIconSearch from 'redesign/components/atoms/Icons/Search';
import TextInput from 'redesign/styles/utils/TextInput.styled';

export const Wrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  ${TextInput}
  padding-right: 40px;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const IconSearch = styled(BaseIconSearch)`
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  width: 25px;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  margin-right: ${({ theme }) => theme.space['2']};
`;

export const MenuWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 225px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

export const Text = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.grey['050']};
  padding: ${({ theme }) => theme.space['3']};
  line-height: 19px;
  font-size: ${({ theme }) => theme.fontSize['sm']};
`;

export const Title = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${({ theme }) => theme.space['1']};
`;

type MenuItemProps = {
  $isHighlighted?: boolean;
  $isAddCompanyOption?: boolean;
};

export const MenuItem = styled.li<MenuItemProps>`
  padding: ${({ theme }) => theme.space['3']};
  display: flex;
  cursor: pointer;
  color: ${({ $isAddCompanyOption, theme }) =>
    $isAddCompanyOption ? theme.colors.white : theme.colors.grey['080']};
  user-select: none;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ $isAddCompanyOption, $isHighlighted, theme }) =>
    $isAddCompanyOption
      ? '#f60' /* Orange background for 'Unternehmen hinzufügen' */
      : $isHighlighted
      ? theme.colors.vrblue['025']
      : 'transparent'};
  letter-spacing: 0.66px;
  line-height: 19px;
  width: 100%;
  font-size: ${({ $isAddCompanyOption }) =>
    $isAddCompanyOption ? '85%' : '68%'}; /* Increased font size for 'Unternehmen hinzufügen' */

  &:active {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.vrblue['100']};
    font-weight: bold;

    span {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 62px;
  color: ${({ theme }) => theme.colors.vrblue['100']};
`;
