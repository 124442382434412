import { JsonApiResponse } from 'json-api-normalizer';

import api from 'api';

type GetBankTransfersData = {
  sort?: string;
  page?: number;
  per_page?: number;
};

export const getBankTransfers = (data?: GetBankTransfersData) => {
  const url = `/me/bank_transfers`;

  return api.get<JsonApiResponse>(url, {
    params: data,
  });
};

type GetAssignableResourcesData = {
  filter: string;
  page: number;
  per_page: number;
};

export const getAssignableResources = (id: string, data?: GetAssignableResourcesData) => {
  const url = `/me/bank_transfers/${id}/assignable_resources`;

  return api.get<JsonApiResponse>(url, {
    params: data,
  });
};

type GetSuggestedResourcesData = {
  page: number;
};

export const getSuggestedResources = (id: string, data?: GetSuggestedResourcesData) => {
  const url = `/me/bank_transfers/${id}/suggested_resources`;

  return api.get<JsonApiResponse>(url, {
    params: data,
  });
};
