import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { createAndFetchNewDeliveryNote } from 'actions/delivery-note';
import { NEW_MODE } from 'constants/common/crud';
import { withWarningAlert } from 'shared/hoc';
import { t } from 'shared/utils';
import LoadingIcon from 'components/LoadingIcon';
import DeliveryNoteCreator from 'features/deliveryNotes/DeliveryNoteCreator/DeliveryNoteCreator';

class DeliveryNotesNew extends Component {
  static propTypes = {
    prepareNewDeliveryNote: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.prepareNewDeliveryNote();
  }

  render() {
    const { isFetching, isFetched } = this.props;

    if (isFetching) return <LoadingIcon />;
    if (!isFetched) return null;

    return <DeliveryNoteCreator crudMode={NEW_MODE} />;
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.deliveryNote.isFetching,
  isFetched: state.deliveryNote.isFetched,
  buttonLabel: t('empty_entry_pages.delivery_notes.delivery_notes_warning_box.button_label'),
  content: t('empty_entry_pages.delivery_notes.delivery_notes_warning_box.content_1'),
  dataId: 'DeliveryNotes:orange-warning-container',
});

const mapDispatchToProps = (dispatch) => ({
  prepareNewDeliveryNote: () => dispatch(createAndFetchNewDeliveryNote()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWarningAlert
)(DeliveryNotesNew);
