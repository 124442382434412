import 'react-dates-legacy/initialize';

import React, { memo } from 'react';
import { DayPickerSingleDateController, isInclusivelyBeforeDay } from 'react-dates-legacy';
import { Popper } from 'react-popper-legacy';
import moment from 'moment';

import { useIsSmUp } from 'shared/hooks/useMedia';
import { getFormattedDateTextForDatepicker } from 'shared/utils/date/parser';

import { ControllerProps } from './DateField.types';
import {
  getDateForController,
  handleDateChange,
  handleFocusChange,
  renderDayContents,
  renderMonth,
} from './DateField.utils';
import ScheduleUpdateOnFocus from './ShouldUpdateOnFocus';

import 'react-dates-legacy/lib/css/_datepicker.css';
import styles from './DateField.module.css';

const Controller = ({
  input,
  onChange,
  setIsFocused,
  isFocused,
  placement,
  invoiceDate,
}: ControllerProps) => {
  const isSmUp = useIsSmUp();
  const date = moment(invoiceDate, 'DD.MM.YYYY');
  const isDueDate = input.name === 'dueDate';
  const isDiscountPeriod = input.name === 'discountPeriod';

  return (
    <Popper
      placement={placement}
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport',
        },
      }}
    >
      {({ ref, style, placement, scheduleUpdate }) => (
        <div className={styles.popperWrapper} ref={ref} style={style} data-placement={placement}>
          <DayPickerSingleDateController
            date={getDateForController(input.value)}
            onDateChange={(selectedMomentDate) => {
              handleDateChange({
                selectedMomentDate,
                formOnChange: input.onChange,
                fieldOnChange: onChange,
                setIsFocused,
              });
            }}
            onFocusChange={({ focused }) => handleFocusChange(focused, setIsFocused)}
            numberOfMonths={1}
            focused={isFocused}
            isOutsideRange={(momentDate) =>
              (isDueDate || isDiscountPeriod) &&
              isInclusivelyBeforeDay(momentDate, moment(date).subtract(1, 'day'))
            }
            hideKeyboardShortcutsPanel
            renderDayContents={renderDayContents}
            renderMonth={renderMonth}
            transitionDuration={0}
            daySize={isSmUp ? undefined : 35}
          />
          <ScheduleUpdateOnFocus scheduleUpdate={scheduleUpdate} />
        </div>
      )}
    </Popper>
  );
};

const areEqual = (prevProps: ControllerProps, nextProps: ControllerProps) => {
  const oldDateText = getFormattedDateTextForDatepicker(prevProps.input.value);
  const newDateText = getFormattedDateTextForDatepicker(nextProps.input.value);

  return oldDateText === newDateText;
};

export default memo(Controller, areEqual);
