import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import * as ContractsAPI from 'api/me/contracts';
import {
  getExpenseContractsLineCategories,
  getLoanContractsLineCategories,
  getOldExpenseContractsLineCategories,
  getRevenueContractsLineCategories,
} from 'api/me/contracts/index';
import { getRecurringTransactionIntervals } from 'api/me/recurringTransactionIntervals';
import EntityPath from 'constants/entitiesPaths';
import { AppThunk } from 'store';
import type { ExpenseContract, RevenueContract } from 'types/entities/Contract';
import { RecurringTransactionInterval } from 'types/entities/RecurringTransactionInterval';

import * as selectors from './selectors';
import { formatFilters, formatSortBy } from './utils';

type Pagination = {
  filters: { id: string; value: any }[];
  sortBy: { id: string; desc: boolean }[];
  pageIndex: number;
  pageCount: number;
  globalFilter?: string;
};

type ContractsState = {
  intervals: {
    data: RecurringTransactionInterval[];
    isLoading: boolean;
  };
  expenseContracts: {
    params: Pagination;
    isLoading: boolean;
    error: string | null;
    data: ExpenseContract[];
  };
  revenueContracts: {
    params: Pagination;
    isLoading: boolean;
    error: string | null;
    data: RevenueContract[];
  };
  lineCategories: any;
  oldLineCategories: any;
};

const initialState: ContractsState = {
  intervals: {
    data: [],
    isLoading: false,
  },
  expenseContracts: {
    params: {
      filters: [],
      sortBy: [],
      pageIndex: 0,
      pageCount: 0,
    },
    isLoading: false,
    error: null,
    data: [],
  },
  revenueContracts: {
    params: {
      filters: [],
      sortBy: [],
      pageIndex: 0,
      pageCount: 0,
    },
    isLoading: false,
    error: null,
    data: [],
  },
  lineCategories: {
    data: [],
    isLoading: false,
  },
  oldLineCategories: {
    data: [],
    isLoading: false,
  },
};

const slice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    setIntervalsStart({ intervals }) {
      intervals.isLoading = true;
    },
    setIntervalsSuccess({ intervals }, { payload }: PayloadAction<RecurringTransactionInterval[]>) {
      intervals.data = payload;
      intervals.isLoading = false;
    },
    setIntervalsFailure({ intervals }) {
      intervals.isLoading = true;
    },
    setExpenseContractsStart({ expenseContracts }) {
      expenseContracts.isLoading = true;
    },
    setExpenseContractsSuccess(
      { expenseContracts },
      { payload }: PayloadAction<ExpenseContract[]>
    ) {
      expenseContracts.data = payload;
      expenseContracts.isLoading = false;
    },
    setExpenseContractsFailure({ expenseContracts }) {
      expenseContracts.isLoading = false;
    },
    setExpenseContractsParams(
      { expenseContracts },
      { payload }: PayloadAction<Partial<Pagination>>
    ) {
      expenseContracts.params = { ...expenseContracts.params, ...payload };
    },
    setRevenueContractsStart({ revenueContracts }) {
      revenueContracts.isLoading = true;
    },
    setRevenueContractsSuccess(
      { revenueContracts },
      { payload }: PayloadAction<RevenueContract[]>
    ) {
      revenueContracts.data = payload;
      revenueContracts.isLoading = false;
    },
    setRevenueContractsFailure({ revenueContracts }) {
      revenueContracts.isLoading = false;
    },
    setRevenueContractsParams(
      { revenueContracts },
      { payload }: PayloadAction<Partial<Pagination>>
    ) {
      revenueContracts.params = { ...revenueContracts.params, ...payload };
    },
    resetContractsParams({ expenseContracts, revenueContracts }) {
      expenseContracts.params = initialState.expenseContracts.params;
      revenueContracts.params = initialState.revenueContracts.params;
    },
    setOldExpenseContractLineCategoriesStart({ oldLineCategories }) {
      oldLineCategories.isLoading = true;
    },
    setOldExpenseContractLineCategoriesSuccess(
      { oldLineCategories },
      { payload }: PayloadAction<any>
    ) {
      oldLineCategories.data = payload;
      oldLineCategories.isLoading = false;
    },
    setOldExpenseContractLineCategoriesFailure({ oldLineCategories }) {
      oldLineCategories.isLoading = true;
    },
    setExpenseContractLineCategoriesStart({ lineCategories }) {
      lineCategories.isLoading = true;
    },
    setExpenseContractLineCategoriesSuccess({ lineCategories }, { payload }: PayloadAction<any>) {
      lineCategories.data = payload;
      lineCategories.isLoading = false;
    },
    setExpenseContractLineCategoriesFailure({ lineCategories }) {
      lineCategories.isLoading = true;
    },
    setRevenueContractLineCategoriesStart({ lineCategories }) {
      lineCategories.isLoading = true;
    },
    setRevenueContractLineCategoriesSuccess({ lineCategories }, { payload }: PayloadAction<any>) {
      lineCategories.data = payload;
      lineCategories.isLoading = false;
    },
    setRevenueContractLineCategoriesFailure({ lineCategories }) {
      lineCategories.isLoading = true;
    },
    setLoanContractLineCategoriesStart({ lineCategories }) {
      lineCategories.isLoading = true;
    },
    setLoanContractLineCategoriesSuccess({ lineCategories }, { payload }: PayloadAction<any>) {
      lineCategories.data = payload;
      lineCategories.isLoading = false;
    },
    setLoanContractLineCategoriesFailure({ lineCategories }) {
      lineCategories.isLoading = true;
    },

    /**
     * It's empty because we are just using it for refreshing notifications.
     */
    deleteContractSuccess() {},
  },
});

export const {
  setExpenseContractsParams,
  setRevenueContractsParams,
  deleteContractSuccess,
  resetContractsParams,
} = slice.actions;

export default slice.reducer;

export const getIntervals = (): AppThunk => async (dispatch) => {
  const { setIntervalsStart, setIntervalsSuccess, setIntervalsFailure } = slice.actions;
  let response;

  dispatch(setIntervalsStart());

  try {
    response = await getRecurringTransactionIntervals();
  } catch (error) {
    dispatch(setIntervalsFailure());
    return;
  }

  const intervals =
    build<RecurringTransactionInterval>(
      normalize(response.data),
      EntityPath.RecurringTransactionIntervals
    ) || [];
  dispatch(setIntervalsSuccess(intervals));
};

export const getExpenseContracts = (): AppThunk => async (dispatch, getState) => {
  const { setExpenseContractsStart, setExpenseContractsSuccess, setExpenseContractsFailure } =
    slice.actions;
  const state = getState();
  const { pageIndex, sortBy, filters, globalFilter } = selectors.getExpenseContractsParams(state);
  let response;

  dispatch(setExpenseContractsStart());

  try {
    response = await ContractsAPI.getContracts({
      is_revenue: false,
      pagination_resource: 'expense_contracts',
      page: pageIndex + 1,
      sort: formatSortBy({
        sortBy,
        keysMapping: {
          supplier: 'supplierLastName',
        },
        defaultValue: '-category-name',
      }),
      filters: formatFilters({ filters, globalFilter }),
    });
  } catch (error) {
    dispatch(setExpenseContractsFailure());
    return;
  }

  const ids = response.data.data.map((item) => item.id);
  const contracts =
    build<ExpenseContract>(normalize(response.data), EntityPath.Contracts, ids, {
      ignoreLinks: true,
    }) || [];
  const pageCount = parseInt(response.headers['total-pages'], 10);

  dispatch(setExpenseContractsParams({ pageCount }));
  dispatch(setExpenseContractsSuccess(contracts));
};

export const getRevenueContracts = (): AppThunk => async (dispatch, getState) => {
  const { setRevenueContractsStart, setRevenueContractsSuccess, setRevenueContractsFailure } =
    slice.actions;
  const state = getState();
  const { pageIndex, sortBy, filters, globalFilter } = selectors.getRevenueContractsParams(state);
  let response;

  dispatch(setRevenueContractsStart());

  try {
    response = await ContractsAPI.getContracts({
      is_revenue: true,
      pagination_resource: 'revenue_contracts',
      page: pageIndex + 1,
      sort: formatSortBy({
        sortBy,
        keysMapping: {
          client: 'clientLastName',
        },
      }),
      filters: formatFilters({ filters, globalFilter }),
    });
  } catch (error) {
    dispatch(setRevenueContractsFailure());
    return;
  }

  const ids = response.data.data.map((item) => item.id);
  const contracts =
    build<RevenueContract>(normalize(response.data), EntityPath.Contracts, ids, {
      ignoreLinks: true,
    }) || [];
  const pageCount = parseInt(response.headers['total-pages'], 10);

  dispatch(setRevenueContractsParams({ pageCount }));
  dispatch(setRevenueContractsSuccess(contracts));
};

export const deleteContract =
  (id: string, password?: string): AppThunk =>
  async (dispatch) => {
    const { deleteContractSuccess } = slice.actions;

    await ContractsAPI.deleteContract(id, password);
    dispatch(deleteContractSuccess());
  };

export const getExpenseLineCategories = (): AppThunk => async (dispatch) => {
  const {
    setExpenseContractLineCategoriesStart,
    setExpenseContractLineCategoriesSuccess,
    setExpenseContractLineCategoriesFailure,
  } = slice.actions;
  let response;

  dispatch(setExpenseContractLineCategoriesStart());

  try {
    response = await getExpenseContractsLineCategories();
  } catch (error) {
    dispatch(setExpenseContractLineCategoriesFailure());
    return;
  }

  const ids = response.data.data.map((item) => item.id);
  const lineCategories = build<any>(normalize(response.data), EntityPath.Categories, ids) || [];

  dispatch(setExpenseContractLineCategoriesSuccess(lineCategories));
};

export const getOldExpenseLineCategories = (): AppThunk => async (dispatch) => {
  const {
    setOldExpenseContractLineCategoriesStart,
    setOldExpenseContractLineCategoriesSuccess,
    setOldExpenseContractLineCategoriesFailure,
  } = slice.actions;
  let response;

  dispatch(setOldExpenseContractLineCategoriesStart());

  try {
    response = await getOldExpenseContractsLineCategories();
  } catch (error) {
    dispatch(setOldExpenseContractLineCategoriesFailure());
    return;
  }

  const ids = response.data.data.map((item) => item.id);
  const oldLineCategories = build(normalize(response.data), EntityPath.OldCategories, ids) || [];

  dispatch(setOldExpenseContractLineCategoriesSuccess(oldLineCategories));
};

export const getRevenueLineCategories = (): AppThunk => async (dispatch) => {
  const {
    setRevenueContractLineCategoriesStart,
    setRevenueContractLineCategoriesSuccess,
    setRevenueContractLineCategoriesFailure,
  } = slice.actions;
  let response;

  dispatch(setRevenueContractLineCategoriesStart());

  try {
    response = await getRevenueContractsLineCategories();
  } catch (error) {
    dispatch(setRevenueContractLineCategoriesFailure());
    return;
  }

  const ids = response.data.data.map((item) => item.id);
  const lineCategories = build<any>(normalize(response.data), EntityPath.Categories, ids) || [];

  dispatch(setRevenueContractLineCategoriesSuccess(lineCategories));
};

export const getLoanLineCategories = (): AppThunk => async (dispatch) => {
  const {
    setLoanContractLineCategoriesStart,
    setLoanContractLineCategoriesSuccess,
    setLoanContractLineCategoriesFailure,
  } = slice.actions;
  let response;

  dispatch(setLoanContractLineCategoriesStart());

  try {
    response = await getLoanContractsLineCategories();
  } catch (error) {
    dispatch(setLoanContractLineCategoriesFailure());
    return;
  }

  const ids = response.data.data.map((item) => item.id);
  const lineCategories = build<any>(normalize(response.data), EntityPath.Categories, ids) || [];

  dispatch(setLoanContractLineCategoriesSuccess(lineCategories));
};
