import React from 'react';
import PropTypes from 'prop-types';

import IconCaretDown from 'images/icon-caret-down.svg';
import IconCaretUp from 'images/icon-caret-up.svg';
import Button from 'components/Button';

const DropdownButton = ({ visible, onClick, disabled, upwards, title, ...rest }) => {
  if (!visible) return null;

  return (
    <Button
      centered
      fullWidth
      label={title}
      onClick={onClick}
      rightIcon={upwards ? IconCaretUp : IconCaretDown}
      disabled={disabled}
      {...rest}
    />
  );
};

DropdownButton.propTypes = {
  title: PropTypes.string,
  upwards: PropTypes.bool,
  visible: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DropdownButton;
