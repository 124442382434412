import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { compose } from 'recompose';
import { Form, InjectedFormProps, isPristine, reduxForm } from 'redux-form';

import { fetchCompany, updateCompany as updateCompanyAction } from 'actions/company';
import { companySelector } from 'reducers/form';
import { companyIsFetchingSelector } from 'selectors/company';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { RootState } from 'store';
import ActionButton from 'components/ActionPanel/ActionButton';
import { SubmitButton } from 'components/Form';
import Section from 'components/Form/Section/Section';
import LoadingIcon from 'components/LoadingIcon';

import ProfileSection from '../ProfileSection/ProfileSection';
import SectionWithMargin from '../SectionWithMargin';
import { CompanyFormData, CompanyFormProps } from './Company.types';
import CompanyInformation from './Sections/CompanyInformation';
import { FORM_NAME } from './Sections/constants';
import ContactDetails from './Sections/ContactDetails';
import GeneralPartnerCompanyInformation from './Sections/GeneralPartnerCompanyInformation';
import Representative from './Sections/Representative';
import TaxData from './Sections/TaxData';

import styles from './Company.module.css';

export const Company: FunctionComponent<
  InjectedFormProps<CompanyFormData, CompanyFormProps> & CompanyFormProps
> = ({
  companyForms,
  formSelector,
  handleSubmit,
  reset,
  updateCompany,
  submitting,
  selectedCompanyForm,
  heading,
  subheading,
  description,
  fetchCompany,
  isFetching,
}) => {
  const isGeneralPartnerSelected =
    selectedCompanyForm === 'limited_partnership_with_limited_liability_company_as_general_partner';
  const representativeSectionComponent = isGeneralPartnerSelected ? SectionWithMargin : Section;

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  if (isFetching) {
    return <LoadingIcon />;
  }

  return (
    <Form
      className={styles.main}
      onSubmit={handleSubmit((values: CompanyFormData) => updateCompany(values))}
      id="company-form"
    >
      <ProfileSection
        heading={heading}
        subheading={subheading}
        description={description}
        requiredMessage={t('profile.company.required_message')}
      >
        <CompanyInformation
          companyForms={companyForms}
          formSelector={formSelector}
          selectedCompanyForm={selectedCompanyForm}
        />

        <ContactDetails />

        <Representative
          formSelector={formSelector}
          SectionComponent={representativeSectionComponent}
        />

        {isGeneralPartnerSelected && (
          <>
            <GeneralPartnerCompanyInformation
              companyForms={companyForms}
              formSelector={formSelector}
              selectedCompanyForm={selectedCompanyForm}
            />
            <Representative
              formSelector={formSelector}
              SectionComponent={Section}
              forGeneralPartner
            />
          </>
        )}
      </ProfileSection>

      <ProfileSection heading={t('profile.company.sections.tax_data')} className={styles.taxData}>
        <TaxData />
      </ProfileSection>

      <div className={styles.buttons} id="company-form-actions">
        <ActionButton
          appearance="outlined"
          dataId="button-reset"
          onClick={reset}
          label={t('profile.company.abort')}
          type="button"
          className={styles.buttonAbort}
        />
        <SubmitButton
          active={!submitting}
          dataId="button-submit"
          value={t('profile.company.submit')}
        />
      </div>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isFetching: companyIsFetchingSelector(state),
  formSelector: (name: string) => companySelector(state, name),
  companyForms: state.company.companyForms,
  selectedCompanyForm: get(state, 'form.company.values.form', ''),
  isSubmitSucceeded: isPristine(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCompany: (data: CompanyFormData) => dispatch(updateCompanyAction(data)),
  fetchCompany: () => dispatch(fetchCompany()),
});

export default compose<InjectedFormProps<CompanyFormData, CompanyFormProps> & CompanyFormProps, {}>(
  reduxForm({
    form: FORM_NAME,
    persistentSubmitErrors: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTransitionPrevent()
)(Company);
