import React from 'react';
import { Row, TableInstance } from 'react-table';

import Loading from 'components/Loading';

import { Responsive, Wrapper } from './Table.styled';

export type TableProps<T extends object> = {
  instance: TableInstance<T>;
  isLoading?: boolean;
  getRowProps?: (row: Row<T>) => object;
};

const Table = <T extends object>({ instance, isLoading, getRowProps }: TableProps<T>) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = instance;

  return (
    <Wrapper>
      <Responsive>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th {...column.getHeaderProps()} key={index} data-id={`header-${column.id}`}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  {...(getRowProps && getRowProps(row))}
                  key={row.index}
                  data-id={`row-${row.index}`}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td {...cell.getCellProps()} key={index} data-id={`cell-${cell.column.id}`}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Responsive>
      {isLoading && <Loading />}
    </Wrapper>
  );
};

export default Table;
