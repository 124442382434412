import React, { ReactNode, useMemo } from 'react';
import cx from 'classnames';

import RadioGroupContext from './RadioGroupContext';

import styles from './RadioGroup.module.css';

type RadioGroupProps = {
  children: ReactNode;
  name: string;
  onChange: (value: string) => void;
  value?: string;
  isDisabled?: boolean;
  className?: string;
};

const RadioGroup = ({
  children,
  name,
  onChange,
  value,
  className,
  isDisabled,
}: RadioGroupProps) => {
  const contextValue = useMemo(
    () => ({
      name,
      onChange,
      value,
      isDisabled,
    }),
    [name, onChange, value, isDisabled]
  );

  return (
    <div className={cx(styles.container, className)}>
      <RadioGroupContext.Provider value={contextValue}>{children}</RadioGroupContext.Provider>
    </div>
  );
};

export default RadioGroup;
