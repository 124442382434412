import React, { Component, createRef } from 'react';
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import cx from 'classnames';
import { push } from 'connected-react-router';
import { debounce, get, head, isEmpty, isUndefined } from 'lodash';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'recompose';

import * as invoiceTemplateActions from 'actions/invoice-templates';
import { TEMPLATE_UPDATE_TIMEOUT } from 'constants/invoice-templates';
import { defaultValuesEnabledHelper, invoiceLayoutEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { getFinishedInvoiceTemplatesStep } from 'store/slices/onboarding/selectors';
import { fetchOnboarding, updateOnboarding } from 'store/slices/onboarding/thunks';
import Section from 'components/Form/Section/Section';
import LoadingIcon from 'components/LoadingIcon';
import PreviewModal from 'components/PreviewModal';

import TabDocumentsDefaults from './DefaultValues/TabDocumentsDefaults/TabDocumentsDefaults';
import TabEmailsDefaults from './DefaultValues/TabEmailsDefaults/TabEmailsDefaults';
import { getInvoiceTemplateVisibleFields } from './InvoiceTemplate.utils';
import InvoiceTemplateForm from './InvoiceTemplateForm/InvoiceTemplateForm';
import ResetTemplateButton from './ResetTemplateButton/ResetTemplateButton';
import TemplateTabEditInfo from './TemplateTabEditInfo/TemplateTabEditInfo';

import styles from './InvoiceTemplate.module.css';

const DEFAULT_VISIBLE_TAB_FIELDS = {
  qr_codes_enabled: false,
  show_sender_address: false,
  show_contact_details: false,
  show_footer: false,
  show_validity_information: false,
};

class InvoiceTemplate extends Component {
  state = {
    id: '',
    isPreviewModalOpen: false,
    isGeneratingPreview: false,
    isSubmitting: false,
    windowWidth: -1,
    /**
     * The invoice template consists of many fields spread across different tabs.
     * visibleTabFields describes which fields are visible (on currently selected tab).
     * Only visible fields should be sent to update and reset actions.
     */
    visibleTabFields: DEFAULT_VISIBLE_TAB_FIELDS,
    isHideFooterModalOpen: false,
  };

  canvasRef = createRef();

  componentDidMount() {
    this.checkIfInvoiceTemplateAlreadyVisited();
    this.loadInvoiceTemplate();
    window.addEventListener(
      'resize',
      (this.debouncedGeneratePreview = debounce(this.generatePreview, 1000))
    );
  }

  checkIfInvoiceTemplateAlreadyVisited = async () => {
    const { fetchOnboarding, updateOnboarding, areInvoiceTemplatesVisited } = this.props;

    // this could be already fetched on home page
    if (areInvoiceTemplatesVisited) return;

    const action = await fetchOnboarding();

    if (!action.payload.finishedInvoiceTemplatesStep)
      updateOnboarding({ visited_invoice_templates: true });
  };

  componentWillUnmount() {
    this.props.clear();
    window.removeEventListener('resize', this.debouncedGeneratePreview);
  }

  static getDerivedStateFromProps(props) {
    const {
      location: { pathname },
      isInvoiceLayoutEnabled,
    } = props;

    const visibleTabFields = getInvoiceTemplateVisibleFields({
      path: pathname,
      isInvoiceLayoutEnabled,
    });

    return {
      visibleTabFields: {
        ...DEFAULT_VISIBLE_TAB_FIELDS,
        ...visibleTabFields,
      },
    };
  }

  setPreviewModal = (isPreviewModalOpen) => this.setState({ isPreviewModalOpen });

  closePreviewModal = () => this.setPreviewModal(false);

  openPreviewModal = () => this.setPreviewModal(true);

  loadInvoiceTemplate() {
    return this.fetchTemplate().then(this.generatePreview);
  }

  fetchModalPreview = async () => {
    const { id } = this.state;
    const { downloadPreview } = this.props;

    const { rawResponse } = await downloadPreview(id);
    return rawResponse;
  };

  async fetchTemplate() {
    const id = get(this.props, 'match.params.id');

    if (id) return this.fetchTemplateById(id);

    const response = await this.props.fetchInvoiceTemplates();

    const firstTemplate = head(get(response, 'data'));
    const firstId = get(firstTemplate, 'id');

    if (isUndefined(firstId)) return '';

    this.setState({ id: firstId });
    return this.fetchTemplateById(firstId);
  }

  fetchTemplateById(id) {
    if (isUndefined(id)) return;
    this.props.getInvoiceTemplate(id);
  }

  generatePreview = async ({ forceFetchPreview = false } = {}) => {
    const { id, windowWidth } = this.state;
    const { innerWidth: currentWindowWidth } = window;
    if (currentWindowWidth === windowWidth && !forceFetchPreview) return; // workaround for endless spinner + reversed pdf on IE11/Safari

    const { downloadPreview } = this.props;

    if (isEmpty(id)) return;

    this.setState({ isGeneratingPreview: true });

    const { rawResponse } = await downloadPreview(id);

    if (!rawResponse) return;

    const pdf = await pdfjs.getDocument(rawResponse).promise;
    const page = await pdf.getPage(1);

    const scale = 1;
    const viewport = page.getViewport({ scale });

    // Prepare canvas using PDF page dimensions.
    const canvas = this.canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext('2d');

    const newScale = canvas.parentElement.offsetWidth / viewport.width;
    const newViewport = page.getViewport({ scale: newScale });

    canvas.width = newViewport.width;
    canvas.height = newViewport.height;

    // Clear canvas for safety when updating existing one.
    context.clearRect(0, 0, newViewport.width, newViewport.height);

    // Render PDF page into canvas context.
    await page.render({
      canvasContext: context,
      viewport: newViewport,
    }).promise;

    this.setState({ isGeneratingPreview: false, windowWidth: currentWindowWidth });
  };

  updateTemplate = async (newTemplateValues, options = {}) => {
    const { isGeneratingPreview, isSubmitting, visibleTabFields } = this.state;

    if (isGeneratingPreview || isSubmitting) return;

    const {
      resetTemplate,
      formData: { initial },
      template: { id } = {},
      updateInvoiceTemplate,
    } = this.props;

    this.setState({
      isSubmitting: true,
    });

    try {
      if (!options.isResetting) {
        await updateInvoiceTemplate(id, {
          ...initial,
          ...newTemplateValues,
        });
        this.generatePreview({ forceFetchPreview: true });
      } else {
        await resetTemplate(id, visibleTabFields);
        this.generatePreview({ forceFetchPreview: true });
      }

      this.setState({
        isSubmitting: false,
        windowWidth: -1,
      });
    } catch (errors) {
      this.setState({
        isSubmitting: false,
        windowWidth: -1,
      });
    }
  };

  debouncedUpdateTemplate = debounce(async (newTemplateValues) => {
    const {
      formData: { initial },
      template: { id } = {},
      updateInvoiceTemplate,
    } = this.props;
    const { isGeneratingPreview, isSubmitting } = this.state;

    if (isGeneratingPreview || isSubmitting) return;

    this.setState({
      isSubmitting: true,
    });

    try {
      await updateInvoiceTemplate(id, {
        ...initial,
        ...newTemplateValues,
      });

      this.generatePreview({ forceFetchPreview: true });

      this.setState({ isSubmitting: false, windowWidth: -1 });
    } catch (errors) {
      this.setState({ isSubmitting: false, windowWidth: -1 });
    }
  }, TEMPLATE_UPDATE_TIMEOUT);

  handleTemplateChange = (newTemplateValues, options = {}) => {
    const { isResetting, noDebounce } = options;

    if (noDebounce || isResetting) return this.updateTemplate(newTemplateValues, options);

    return this.debouncedUpdateTemplate(newTemplateValues);
  };

  handleHideFooterModalOpen = () => this.setState({ isHideFooterModalOpen: true });

  handleHideFooterModalClose = () => this.setState({ isHideFooterModalOpen: false });

  handleHideFooterModalConfirm = () =>
    this.setState({ isHideFooterModalOpen: false }, () =>
      this.handleTemplateChange({ showFooter: false })
    );

  render() {
    const { areDefaultValuesEnabled, isInvoiceLayoutEnabled } = this.props;
    const { isPreviewModalOpen, isGeneratingPreview, isSubmitting, isHideFooterModalOpen } =
      this.state;
    const { template } = this.props;
    const isCanvasLoading = isGeneratingPreview || isSubmitting;

    return (
      <div className={styles.main} id="invoice-template">
        <Route path={paths.settingsTemplateEdit} exact>
          <Section title={t('invoice_templates.section')} sectionTitleTheme={styles.title}>
            <div className={styles.row}>
              <InvoiceTemplateForm>
                <div className={styles.uploadPreview}>
                  <button
                    className={cx(styles.canvas, { [styles.canvasLoading]: isCanvasLoading })}
                    onClick={isCanvasLoading ? undefined : this.openPreviewModal}
                  >
                    <canvas ref={this.canvasRef} />
                    {isCanvasLoading && <LoadingIcon dataId="InvoiceTemplates:preview-loader" />}
                  </button>
                </div>
                <TemplateTabEditInfo
                  template={template}
                  updateTemplate={this.handleTemplateChange}
                  isOpen={isHideFooterModalOpen}
                  onClose={this.handleHideFooterModalClose}
                  onConfirm={this.handleHideFooterModalConfirm}
                  showHideFooterModal={this.handleHideFooterModalOpen}
                  isInvoiceLayoutEnabled={isInvoiceLayoutEnabled}
                  isLoading={isSubmitting}
                />
              </InvoiceTemplateForm>
            </div>
            <div className={cx(styles.row, styles.resetContainer)}>
              <ResetTemplateButton
                heading={t('profile.section_headers.template_edit')}
                onReset={() => this.handleTemplateChange({}, { isResetting: true })}
              />
            </div>
          </Section>
        </Route>

        {areDefaultValuesEnabled && (
          <>
            <Route path={paths.settingsTemplateDocumentsDefaults} exact>
              <div className={styles.defaults}>
                <TabDocumentsDefaults />
              </div>
            </Route>
            <Route path={paths.settingsTemplateEmailDefaults} exact>
              <div className={styles.defaults}>
                <TabEmailsDefaults />
              </div>
            </Route>
          </>
        )}
        <PreviewModal
          title={t('modals.preview_outgoing_invoice.title')}
          getPreview={this.fetchModalPreview}
          isOpen={isPreviewModalOpen}
          onClose={this.closePreviewModal}
        />
      </div>
    );
  }
}

InvoiceTemplate.propTypes = {
  isInvoiceLayoutEnabled: bool,
  getInvoiceTemplate: func.isRequired,
  areDefaultValuesEnabled: bool.isRequired,
  areInvoiceTemplatesVisited: bool.isRequired,
  clear: func.isRequired,
  updateInvoiceTemplate: func.isRequired,
  fetchInvoiceTemplates: func.isRequired,
  formData: shape({
    values: shape({}),
  }),
  downloadPreview: func.isRequired,
  resetTemplate: func.isRequired,
  fetchOnboarding: func.isRequired,
  updateOnboarding: func.isRequired,
  template: shape({
    id: string,
    logo: string,
    background: string,
  }).isRequired,
};

export default compose(
  withRouter,
  connect(
    (state) => ({
      template: state.invoiceTemplates.template,
      formData: state.form.invoiceTemplate || {},
      areDefaultValuesEnabled: defaultValuesEnabledHelper(state),
      isInvoiceLayoutEnabled: invoiceLayoutEnabledHelper(state),
      areInvoiceTemplatesVisited: getFinishedInvoiceTemplatesStep(state),
    }),
    {
      getInvoiceTemplate: invoiceTemplateActions.getInvoiceTemplate,
      clear: invoiceTemplateActions.clearInvoiceTemplate,
      updateInvoiceTemplate: invoiceTemplateActions.updateInvoiceTemplate,
      fetchInvoiceTemplates: invoiceTemplateActions.fetchInvoiceTemplates,
      downloadPreview: invoiceTemplateActions.downloadTemplate,
      resetTemplate: invoiceTemplateActions.resetTemplate,
      push,
      fetchOnboarding,
      updateOnboarding,
    }
  )
)(InvoiceTemplate);
