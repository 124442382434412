import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/Loading';
import { getPaginationPreferences } from 'features/paginationPreferences/paginationPreferencesSlice';

import { Wrapper } from './Contracts.styled';
import * as actions from './contractsSlice';
import EmptyEntryPage from './EmptyEntryPage/EmptyEntryPage';
import ExpenseContracts from './ExpenseContracts/ExpenseContracts';
import RevenueContracts from './RevenueContracts/RevenueContracts';
import * as selectors from './selectors';

const Contracts = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const expenseContracts = useSelector(selectors.getExpenseContracts);
  const expenseContractsFilters = useSelector(selectors.getExpenseContractsParamsFilters);
  const expenseContractsGlobalFilter = useSelector(selectors.getExpenseContractsParamsGlobalFilter);
  const revenueContracts = useSelector(selectors.getRevenueContracts);
  const revenueContractsFilters = useSelector(selectors.getRevenueContractsParamsFilters);
  const revenueContractsGlobalFilter = useSelector(selectors.getRevenueContractsParamsGlobalFilter);
  const areRevenueContractsFiltersActive =
    revenueContractsFilters.length > 0 || !!revenueContractsGlobalFilter;
  const areExpenseContractsFiltersActive =
    expenseContractsFilters.length > 0 || !!expenseContractsGlobalFilter;
  const showEmptyState =
    !areRevenueContractsFiltersActive &&
    !areExpenseContractsFiltersActive &&
    expenseContracts.length === 0 &&
    revenueContracts.length === 0;

  useEffect(() => {
    const fetch = async () => {
      await Promise.all([
        dispatch(getPaginationPreferences()),
        dispatch(actions.getIntervals()),
        dispatch(actions.getExpenseContracts()),
        dispatch(actions.getRevenueContracts()),
      ]);
      setIsLoading(false);
    };

    fetch();

    return () => {
      dispatch(actions.resetContractsParams());
    };
  }, [dispatch]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      {showEmptyState ? (
        <EmptyEntryPage />
      ) : (
        <>
          <ExpenseContracts />
          <RevenueContracts />
        </>
      )}
    </Wrapper>
  );
};

export default Contracts;
