import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';
import { differenceInDays, parse } from 'date-fns';
import normalize from 'json-api-normalizer';
import moment from 'moment';
import {
  formValueSelector as formValueSelectorBuilder,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from 'redux-form';
import build from 'redux-object';

import { showNotification } from 'actions/notification';
import { correctCashTransaction, getCashTransactions } from 'api/me/cashbooks/cashTransactions';
import { getExpenseCategories, getRevenueCategories } from 'api/me/invoiceLineCategories';
import EntityPath from 'constants/entitiesPaths';
import {
  CASHBOOKS_EDIT_CASH_TRANSACTION,
  CASHBOOKS_TRANSACTION_TYPE_CHANGED,
} from 'constants/piwik';
import HintImage from 'images/hint.svg';
import validationNotification from 'notifications/validation';
import { formatMoney, l, t } from 'shared/utils';
import {
  mapServerErrorsToFieldErrors,
  mapServerErrorsToNotificationError,
} from 'shared/utils/server-validation';
import { RootState } from 'store';
import { Cashbook } from 'types/entities/Cashbook';
import { CashTransaction, TransactionType } from 'types/entities/CashTransaction';
import { CategoryResponse } from 'types/entities/Category';
import { TaxOption } from 'types/entities/TaxRate';
import BoxWithButton from 'components/BoxWithButton/BoxWithButton';
import Button from 'components/Button';
import CategorySearch from 'components/CategorySearch/CategorySearch';
import { buildCategories } from 'components/CategorySearch/utils';
import {
  CurrencyField,
  DateField,
  FormField,
  SelectField,
  StaticTextField,
  TextareaField,
  TextField,
} from 'components/Form';
import { getDefaultTaxRate, getTaxRate } from 'components/LineItems/utils';
import { ModalHeader } from 'components/Modal';

import { trackEventHandler } from '../../utils';
import Dropzone from '../CreatorModal/Dropzone/Dropzone';
import { capDocumentNumberCharacters } from '../CreatorModal/validation';
import {
  Amount,
  Buttons,
  Category,
  DailyOrder,
  DailyOrderId,
  DailyOrderPrefix,
  Description,
  DocumentNumber,
  DropzoneHint,
  DropzoneWrapper,
  Fields,
  Label,
  Modal,
  ModalBody,
  NumbersWrapper,
  OrderIdOption,
  OrderIdOptionDescription,
  OrderIdOptionGrossAmount,
  OrderIdOptionNumber,
  Radio,
  Radios,
  Reasoning,
  RetroactiveReasoning,
  TaxAmount,
  TransactionDate,
  TransactionType as TransactionTypeStyled,
  Vat,
  Warning,
} from './EditModal.styled';

import styles from '../CreatorModal/CreatorModal.module.css';

const FormName = 'cash-transaction-creator';

const formValueSelector = formValueSelectorBuilder<RootState>(FormName);

export const getTransactionType = (state: RootState) => formValueSelector(state, 'transactionType');
export const getCategory = (state: RootState) => formValueSelector(state, 'category');
export const getGrossAmount = (state: RootState) => formValueSelector(state, 'grossAmount');
export const getTransactionDate = (state: RootState) => formValueSelector(state, 'transactionDate');
export const getDailyOrderPrefix = (state: RootState) =>
  formValueSelector(state, 'dailyOrderPrefix');
export const getDailyOrderId = (state: RootState) => formValueSelector(state, 'dailyOrderId');
export const getTaxRateId = (state: RootState) => formValueSelector(state, 'taxRateId');

export enum OrderPrefix {
  Before = 'Before',
  After = 'After',
}

const DailyOrderPrefixOptions = [
  {
    value: OrderPrefix.Before,
    label: t('cashbooks.cash_transactions.creator.fields.daily_order_prefix.options.before'),
  },
  {
    value: OrderPrefix.After,
    label: t('cashbooks.cash_transactions.creator.fields.daily_order_prefix.options.after'),
  },
];

const OrderIdDataIds = {
  input: 'order-id-input',
};

type FormData = {
  transactionType: TransactionType;
  documentNumber: string | null;
  description: string;
  category: CategoryResponse;
  grossAmount: string;
  vat: number;
  transactionDate: string;
  dailyOrderPrefix: OrderPrefix;
  dailyOrderId: number;
  reasonForDeletion: string;
  reasonForRetroactiveCreation: string;
  taxRateId: string;
};

type EditModalOwnProps = {
  onClose: () => void;
  cashbook: Cashbook;
  onSave: () => void;
  cashTransaction: CashTransaction;
};

type EditModalProps = EditModalOwnProps & InjectedFormProps<FormData, EditModalOwnProps>;

const renderOrderOption = (option: CashTransaction & { label: string; value: string }) => (
  <OrderIdOption>
    <OrderIdOptionNumber>{option.idNumber}</OrderIdOptionNumber>
    <OrderIdOptionDescription>{option.description}</OrderIdOptionDescription>
    <OrderIdOptionGrossAmount>{formatMoney(option.grossAmount)}</OrderIdOptionGrossAmount>
  </OrderIdOption>
);

const EditModal = ({
  handleSubmit,
  onClose,
  change,
  cashbook,
  onSave,
  cashTransaction,
}: EditModalProps) => {
  const dispatch = useDispatch();
  const transactionType = useSelector(getTransactionType);
  const grossAmount = useSelector(getGrossAmount);
  const transactionDate = useSelector(getTransactionDate);
  const dailyOrderPrefix = useSelector(getDailyOrderPrefix);
  const dailyOrderId = useSelector(getDailyOrderId);
  const taxRateId = useSelector(getTaxRateId);
  const isSmallEntrepreneur = useSelector(
    (state: RootState) => state.company.details.smallEntrepreneur
  );

  const [category, setCategory] = useState<CategoryResponse | undefined>();
  const [taxAmount, setTaxAmount] = useState<number>(0);
  const [file, setFile] = useState<File | null>(null);
  const [isSending, setIsSending] = useState(false);
  const [initialSelectedCategory, setInitialSelectedCategory] = useState<CategoryResponse>();
  const [hasInitialCategory, setHasInitialCategory] = useState(true);
  const [revenueCategories, setRevenueCategories] = useState<CategoryResponse[]>([]);
  const [expenseCategories, setExpenseCategories] = useState<CategoryResponse[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<CategoryResponse[]>([]);
  const [taxRateOptions, setTaxRateOptions] = useState<TaxOption[]>([]);
  const [transactionsForDay, setTransactionsForDay] = useState<CashTransaction[]>([]);
  const [isFileError, setIsFileError] = useState(false);
  const hasAnyTransactionsForADay = transactionsForDay.length > 0;
  const transactionDateAsDate = parse(transactionDate, 'dd.MM.yyyy', new Date());
  const isTransactionDateInThePast = differenceInDays(transactionDateAsDate, new Date()) < 0;
  const shouldSetDailyOrder = hasAnyTransactionsForADay;
  const newDailyOrderId = useMemo(() => {
    if (!hasAnyTransactionsForADay) return 1;

    if (!dailyOrderPrefix || !dailyOrderId) return undefined;

    return dailyOrderPrefix === OrderPrefix.Before ? dailyOrderId : dailyOrderId + 1;
  }, [hasAnyTransactionsForADay, dailyOrderPrefix, dailyOrderId]);
  const shouldSetReasonForRetroactiveEntry =
    isTransactionDateInThePast ||
    (hasAnyTransactionsForADay && newDailyOrderId <= transactionsForDay[0].dailyOrderId);

  const resetCategoryValues = () => {
    setInitialSelectedCategory(undefined);
    setCategory(undefined);
    setTaxAmount(0);
    setTaxRateOptions([]);
    change('category', undefined);
    change('taxRateId', undefined);
    change('vat', undefined);
  };

  const handleTransactionTypeChange = () => {
    trackEventHandler(CASHBOOKS_TRANSACTION_TYPE_CHANGED);
    resetCategoryValues();
  };

  const onSubmit = useCallback(
    async (values: FormData) => {
      setIsSending(true);
      setIsFileError(false);

      const getInvoiceLineCategoryId = () => {
        if (values?.category) {
          return values.category?.id;
        }

        if (initialSelectedCategory) {
          return initialSelectedCategory.id;
        }

        return undefined;
      };

      const getVatValue = () => {
        if (values && taxRateOptions) {
          return taxRateOptions.find((item) => String(item.id) === String(values.taxRateId))?.vat;
        }

        return undefined;
      };

      try {
        await correctCashTransaction(
          {
            documentNumber: values.documentNumber,
            transactionType: values.transactionType,
            transactionDate: values.transactionDate,
            invoiceLineCategoryId: getInvoiceLineCategoryId(),
            grossAmount:
              values.grossAmount !== undefined
                ? Number(String(values.grossAmount).replace(',', '.'))
                : undefined,
            description: values.description,
            taxAmount:
              values.grossAmount !== undefined && getVatValue() !== undefined
                ? taxAmount
                : undefined,
            taxRateId: values.taxRateId,
            cashbookId: cashbook.id,
            dailyOrderId: newDailyOrderId,
            reasonForDeletion: values.reasonForDeletion,
            reasonForRetroactiveCreation: values.reasonForRetroactiveCreation,
          },
          cashbook.id,
          cashTransaction.id,
          file ? [file] : []
        );
      } catch ({ response }) {
        const errors = (response as any).data.errors;

        if (!Array.isArray(errors)) {
          setIsSending(false);
          return;
        }

        const notification = validationNotification(
          mapServerErrorsToNotificationError(errors).filter((error: string) => error !== '')
        );
        dispatch(showNotification(notification));
        // TODO remove any when it's rewritten to TS
        const fieldErrors: any = mapServerErrorsToFieldErrors(errors);
        if ('invoiceLineCategoryId' in fieldErrors) {
          if (!values.category) {
            fieldErrors.category = fieldErrors.invoiceLineCategoryId;
          }
        }

        if ('cashTransactionFiles' in fieldErrors) {
          setIsFileError(true);
        }
        if ('customFileOrDocumentNumberMissing' in fieldErrors) {
          setIsFileError(true);
          fieldErrors.documentNumber = '#';
        }
        if ('customCashbookAmountNegative' in fieldErrors) {
          fieldErrors.grossAmount = '#';
        }
        setIsSending(false);
        throw new SubmissionError(fieldErrors);
      }

      setIsSending(false);
      trackEventHandler(CASHBOOKS_EDIT_CASH_TRANSACTION);
      await onSave();

      onClose();
    },
    [
      onSave,
      onClose,
      initialSelectedCategory,
      taxRateOptions,
      taxAmount,
      cashbook.id,
      newDailyOrderId,
      cashTransaction.id,
      file,
      dispatch,
    ]
  );

  const getTaxRateOptions = useCallback(
    (category) =>
      category?.taxRates?.map((taxRate: { id: string; value: string }) => ({
        id: parseInt(taxRate.id),
        value: parseInt(taxRate.id),
        vat: parseFloat(taxRate.value),
        label: l(taxRate.value, 'percentage'),
      })),
    []
  );

  const handleSelectCategory = (item: CategoryResponse) => {
    if (item) {
      setCategory(item);
      change('category', item);
      change('vat', getDefaultTaxRate(item)?.value);
    }
  };

  // fetch categories
  useEffect(() => {
    const fetch = async () => {
      const [revenueCategoriesResponse, expenseCategoriesResponse] = await Promise.all([
        getRevenueCategories(),
        getExpenseCategories(),
      ]);

      const revenueCategories = buildCategories(
        build<CategoryResponse>(normalize(revenueCategoriesResponse.data), EntityPath.Categories) ||
          []
      );
      setRevenueCategories(revenueCategories);

      const expenseCategories = buildCategories(
        build<CategoryResponse>(normalize(expenseCategoriesResponse.data), EntityPath.Categories) ||
          []
      );
      setExpenseCategories(expenseCategories);
    };

    fetch();
  }, []);

  // set category options
  useEffect(() => {
    const categories =
      transactionType === TransactionType.Revenue ? revenueCategories : expenseCategories;

    setCategoryOptions(categories);
  }, [transactionType, revenueCategories, expenseCategories]);

  useEffect(() => {
    const initialCategory = categoryOptions?.find(
      (category: CategoryResponse) =>
        category && parseInt(category.id) === parseInt(cashTransaction.invoiceLineCategoryId)
    );

    setInitialSelectedCategory(initialCategory);
    categoryOptions?.length > 0 && setHasInitialCategory(initialCategory != null);
  }, [categoryOptions, cashTransaction.invoiceLineCategoryId]);

  useEffect(() => {
    if (category) {
      const taxRateId = getTaxRate(getDefaultTaxRate(category)?.id, category?.taxRates);

      change('taxRateId', taxRateId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    setTaxRateOptions(getTaxRateOptions(category || initialSelectedCategory));
  }, [category, getTaxRateOptions, initialSelectedCategory]);

  // calculate tax amount
  useEffect(() => {
    const vat = taxRateOptions?.find((taxRate) => taxRate.id === taxRateId)?.vat;

    if (grossAmount === undefined || vat === undefined) return;

    const grossAmountAsNumber = Number(String(grossAmount).replace(',', '.'));
    const netAmount = grossAmountAsNumber / (1 + vat / 100);
    const taxAmount = Number((grossAmountAsNumber - netAmount).toFixed(4));

    setTaxAmount(taxAmount);
  }, [grossAmount, taxRateId, taxRateOptions]);

  useEffect(() => {
    if (!hasAnyTransactionsForADay) return;

    const initialDailyOrderOption = transactionsForDay.find(
      (item) => item.dailyOrderId === cashTransaction.dailyOrderId
    );

    change('dailyOrderId', initialDailyOrderOption?.dailyOrderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsForDay, hasAnyTransactionsForADay]);

  // set transactions for chosen day
  useDebounce(
    () => {
      if (!transactionDate) {
        setTransactionsForDay([]);
        return;
      }

      const fetch = async () => {
        let response = null;

        try {
          response = await getCashTransactions(cashbook.id, {
            transactionDate,
          });
        } catch (e) {
          setTransactionsForDay([]);
          return;
        }

        const ids = response.data.data.map((item) => item.id);
        const cashTransactions =
          build<CashTransaction>(normalize(response.data), EntityPath.CashTransactions, ids, {
            ignoreLinks: true,
          }) || [];

        setTransactionsForDay(
          cashTransactions.map((cashTransaction) => ({
            ...cashTransaction,
            label: `${cashTransaction.idNumber} ${cashTransaction.description}`,
            value: cashTransaction.dailyOrderId,
          }))
        );
      };

      fetch();
    },
    500,
    [transactionDate, cashbook.id]
  );

  return (
    <Modal isOpen onRequestClose={onClose}>
      <ModalHeader
        headerStyle={styles.header}
        headerContentStyle={styles.headerContent}
        onClose={onClose}
        withCloseButton
      >
        {t('cashbooks.cash_transactions.creator.correcting.title')}
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Warning>
          {!hasInitialCategory && (
            <>
              <BoxWithButton
                isButtonDisabled
                image={HintImage}
                content={t('datev.deprecated_category_warning_cashbook')}
                dataId="Outgoinginvoices-CreateInvoiceSection:orange-warning-container"
              />
              <br />
            </>
          )}

          <h2>{t('cashbooks.cash_transactions.creator.correcting.warning_header')}</h2>
          <p>{t('cashbooks.cash_transactions.creator.correcting.warning_body')}</p>
        </Warning>
        <ModalBody>
          <DropzoneWrapper>
            <Dropzone onFileChange={setFile} isError={isFileError} />
          </DropzoneWrapper>
          <DropzoneHint>{t('cashbooks.cash_transactions.creator.dropzone.hint')}</DropzoneHint>
          <Fields>
            <TransactionTypeStyled>
              <Label>
                {t('cashbooks.cash_transactions.creator.fields.transaction_type.label')}
              </Label>
              <Radios data-id="transactionTypeRadio">
                <Radio>
                  <FormField
                    name="transactionType"
                    component="input"
                    type="radio"
                    value={TransactionType.Revenue}
                    data-id="revenue"
                    onChange={handleTransactionTypeChange}
                  />{' '}
                  {t('cashbooks.cash_transactions.creator.fields.transaction_type.options.revenue')}
                </Radio>
                <Radio>
                  <FormField
                    name="transactionType"
                    component="input"
                    type="radio"
                    value={TransactionType.Expense}
                    data-id="expense"
                    onChange={handleTransactionTypeChange}
                  />{' '}
                  {t('cashbooks.cash_transactions.creator.fields.transaction_type.options.expense')}
                </Radio>
              </Radios>
            </TransactionTypeStyled>
            <Description>
              <FormField
                component={TextField}
                name="description"
                label={t('cashbooks.cash_transactions.creator.fields.description.label')}
                placeholder={t(
                  'cashbooks.cash_transactions.creator.fields.description.placeholder'
                )}
                data-id="description"
                required
              />
            </Description>
            <Category>
              <FormField
                name="category"
                dataId="select-category"
                label={t('cashbooks.cash_transactions.creator.fields.category_id.label')}
                component={CategorySearch}
                categories={categoryOptions}
                selectedItem={category || initialSelectedCategory}
                initialSelectedItem={category}
                initialInputValue={category?.name}
                onSelectCategory={handleSelectCategory}
                onClearCategory={resetCategoryValues}
                withIcon
              />
            </Category>
            <Amount>
              <CurrencyField
                label={t('cashbooks.cash_transactions.creator.fields.gross_amount.label')}
                placeholder={t(
                  'cashbooks.cash_transactions.creator.fields.gross_amount.placeholder'
                )}
                name="grossAmount"
                component={TextField}
                onlyPositive
                data-id="amount"
                precision={2}
                required
              />
            </Amount>
            <NumbersWrapper>
              <Vat>
                <FormField
                  name="taxRateId"
                  data-id="tax-rate-id"
                  label={t('cashbooks.cash_transactions.creator.fields.tax_rate.label')}
                  placeholder={t('cashbooks.cash_transactions.creator.fields.tax_rate.placeholder')}
                  component={SelectField}
                  options={taxRateOptions}
                  message={
                    isSmallEntrepreneur &&
                    t('cashbooks.cash_transactions.creator.small_entrepreneur_hint')
                  }
                  isMenuFullWidth={false}
                  disableNative
                  required
                />
              </Vat>
              <TaxAmount>
                <StaticTextField
                  label={t('cashbooks.cash_transactions.creator.fields.tax_amount.label')}
                  parsedValue={formatMoney(taxAmount)}
                  isWithoutUnderline
                  isWithoutEuroSign
                />
              </TaxAmount>
            </NumbersWrapper>
            <TransactionDate>
              <FormField
                name="transactionDate"
                label={t('cashbooks.cash_transactions.creator.fields.transaction_date.label')}
                placeholder={t(
                  'cashbooks.cash_transactions.creator.fields.transaction_date.placeholder'
                )}
                component={DateField}
                minDate={moment(cashbook.startDate)}
                maxDate={moment()}
                placement="top-start"
                className={styles.datePicker}
                required
                data-id="transaction-date"
              />
            </TransactionDate>
          </Fields>
          {shouldSetDailyOrder && (
            <DailyOrder>
              <Label>{t('cashbooks.cash_transactions.creator.fields.daily_order.label')}</Label>
              <h3>{t('cashbooks.cash_transactions.creator.fields.daily_order.hint')}</h3>
              <DailyOrderPrefix>
                <FormField
                  name="dailyOrderPrefix"
                  component={SelectField}
                  label={t('cashbooks.cash_transactions.creator.fields.daily_order_prefix.label')}
                  placeholder={t(
                    'cashbooks.cash_transactions.creator.fields.daily_order_prefix.label'
                  )}
                  options={DailyOrderPrefixOptions}
                  data-id="order-prefix"
                  required
                  isMenuFullWidth={false}
                  hasTooltip
                  clearable={false}
                  message={
                    <span>
                      {t(
                        'cashbooks.cash_transactions.creator.fields.daily_order_prefix.tooltip.line_1'
                      )}
                      <br />
                      <br />
                      {t(
                        'cashbooks.cash_transactions.creator.fields.daily_order_prefix.tooltip.line_2'
                      )}
                      <br />
                      <br />
                      {t(
                        'cashbooks.cash_transactions.creator.fields.daily_order_prefix.tooltip.line_3'
                      )}
                    </span>
                  }
                />
              </DailyOrderPrefix>
              <DailyOrderId>
                <FormField
                  name="dailyOrderId"
                  label={t(
                    'cashbooks.cash_transactions.creator.fields.daily_order_prefix.options.label'
                  )}
                  component={SelectField}
                  options={transactionsForDay}
                  optionRenderer={renderOrderOption}
                  dataIds={OrderIdDataIds}
                  isPlaceholderHidden
                  clearable={false}
                />
              </DailyOrderId>
            </DailyOrder>
          )}
          <Reasoning>
            <Label>
              {t('cashbooks.cash_transactions.corrector.fields.reason_for_deletion.header')}
            </Label>
            <h3>{t('cashbooks.cash_transactions.corrector.fields.reason_for_deletion.hint')}</h3>
            <FormField
              name="reasonForDeletion"
              component={TextareaField}
              label={t('cashbooks.cash_transactions.corrector.fields.reason_for_deletion.label')}
              placeholder={t(
                'cashbooks.cash_transactions.corrector.fields.reason_for_deletion.placeholder'
              )}
              required
              dataId="deletion-reason"
              maxCharacters={250}
              minHeight={46}
            />
          </Reasoning>
          {shouldSetReasonForRetroactiveEntry && (
            <RetroactiveReasoning>
              <Label>
                {t(
                  'cashbooks.cash_transactions.corrector.fields.reason_for_retroactive_creation.header'
                )}
              </Label>
              <h3>
                {t(
                  'cashbooks.cash_transactions.corrector.fields.reason_for_retroactive_creation.hint'
                )}
              </h3>
              <FormField
                name="reasonForRetroactiveCreation"
                component={TextareaField}
                label={t(
                  'cashbooks.cash_transactions.corrector.fields.reason_for_retroactive_creation.label'
                )}
                placeholder={t(
                  'cashbooks.cash_transactions.corrector.fields.reason_for_retroactive_creation.placeholder'
                )}
                required
                dataId="retroactive-reason"
                minHeight={46}
                maxCharacters={250}
                tooltipPlacement="top"
                message={
                  <span>
                    {t(
                      'cashbooks.cash_transactions.creator.fields.reason_for_retroactive_creation.tooltip.line_1'
                    )}
                    <br />
                    <br />
                    {t(
                      'cashbooks.cash_transactions.creator.fields.reason_for_retroactive_creation.tooltip.line_2'
                    )}
                  </span>
                }
              />
            </RetroactiveReasoning>
          )}
          <DocumentNumber>
            <FormField
              component={TextField}
              normalize={capDocumentNumberCharacters}
              name="documentNumber"
              label={t('cashbooks.cash_transactions.creator.fields.document_number.label')}
              placeholder={t(
                'cashbooks.cash_transactions.creator.fields.document_number.placeholder'
              )}
            />
          </DocumentNumber>
          <Buttons>
            <Button
              appearance="outlined"
              label={t('cashbooks.cash_transactions.creator.cancel')}
              type="button"
              disabled={isSending}
              onClick={onClose}
            />
            <Button
              label={
                transactionType === TransactionType.Expense
                  ? t('cashbooks.cash_transactions.creator.save.expense')
                  : t('cashbooks.cash_transactions.creator.save.revenue')
              }
              type="submit"
              disabled={isSending || newDailyOrderId === undefined}
              dataId="submit-transaction"
            />
          </Buttons>
        </ModalBody>
      </form>
    </Modal>
  );
};

export default reduxForm<FormData, EditModalOwnProps>({
  form: FormName,
  enableReinitialize: true,
  persistentSubmitErrors: true,
})(EditModal);
