import api from 'api';

export type GetContractsCsvData = {
  is_revenue?: boolean;
  page?: number;
  sort?: string;
  filters?: {
    gross_amount_gte?: number;
    gross_amount_lte?: number;
    recurring_transaction_interval_id?: number;
    subject?: string;
    full_text_search?: string;
    transaction_date_gte?: string;
    transaction_date_lte?: string;
  };
};

type GetContractsCsvResponse = {
  data: {
    content: string;
    filename: string;
    format: string;
  };
};

export const getContractsCsv = (data?: GetContractsCsvData) => {
  const url = '/me/contracts_csv';

  return api.get<GetContractsCsvResponse>(url, {
    params: data,
  });
};
