import React, { ChangeEvent, useCallback } from 'react';

import { t } from 'shared/utils';
import TextField from 'components/Form/TextField';

type GlobalSearchFilterProps = {
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  id: string;
  label?: string;
  placeholder?: string;
  className?: string;
};

const GlobalSearchFilter = ({
  globalFilter,
  setGlobalFilter,
  id,
  label = t('tables.filters.filters_group.label'),
  placeholder = t('tables.filters.filters_group.placeholder'),
  className,
}: GlobalSearchFilterProps) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setGlobalFilter(event.target.value);
    },
    [setGlobalFilter]
  );

  return (
    <TextField
      dataId={`filter-${id}`}
      name={id}
      placeholder={placeholder}
      label={label}
      value={globalFilter || ''}
      onChange={handleChange}
      className={className}
      isSearchIcon
    />
  );
};

export default GlobalSearchFilter;
