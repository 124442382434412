import { get, isString, isUndefined, pickBy } from 'lodash';
import moment from 'moment';

import { CORRECT_MODE, EDIT_MODE, NEW_MODE } from 'constants/common/crud';
import { outgoingInvoiceSelector } from 'reducers/form';
import { t } from 'shared/utils';
import getDateXDaysFromNow from 'shared/utils/getDateXDaysFromNow';
import getSalutationHonorific from 'shared/utils/getSalutationHonorific';
import { RootState } from 'store';
import { Client } from 'types/entities/Client';

interface OutgoingInvoiceValues {
  invoiceDueDate: number;
  discountGrant: boolean;
  discountPercentage: string;
  invoiceDiscountDate: number;
  subject: string;
  salutation: string;
  salutationContent: string;
  personalNotes: string;
}

interface OutgoingInvoiceInitialValues extends Partial<OutgoingInvoiceValues> {
  salutationHonorific: string;
  salutationContent: string;
  invoiceTemplateId: number;
  client: Client;
  dueDate: string;
  creationDate: string;
  discountPeriod: string | null;
}

type RequiredOwnProps = {
  crudMode: typeof NEW_MODE | typeof EDIT_MODE | typeof CORRECT_MODE;
  location: any;
  isFromDuplicate: boolean;
  isFromProposal: boolean;
  isFromOrderConfirmation: boolean;
};

const buildOutgoingInvoiceValues = (state: RootState): OutgoingInvoiceValues => ({
  invoiceDueDate: get(state, 'defaultValues.values.invoiceDueDate'),
  discountGrant: get(state, 'defaultValues.values.invoiceDiscountGrant'),
  discountPercentage: get(state, 'defaultValues.values.invoiceDiscountPercentage'),
  invoiceDiscountDate: get(state, 'defaultValues.values.invoiceDiscountDate'),
  subject: get(state, 'defaultValues.values.invoiceSubject'),
  salutation: get(state, 'defaultValues.values.invoiceSalutation'),
  salutationContent: get(state, 'defaultValues.values.invoiceSalutationContent'),
  personalNotes: get(state, 'defaultValues.values.invoiceFooter'),
});

const isNotUndefined = (value: any) => !isUndefined(value);

export const getOutgoingInvoiceInitialValues = ({
  state,
  ownProps,
}: {
  state: RootState;
  ownProps: RequiredOwnProps;
}): OutgoingInvoiceInitialValues | {} => {
  const { crudMode, isFromDuplicate, isFromProposal, isFromOrderConfirmation } = ownProps;
  const client = outgoingInvoiceSelector(state, 'client') || get(state, 'outgoingInvoice.client');
  const outgoingInvoice = pickBy(get(state, 'outgoingInvoice.details', {}), isNotUndefined);
  const formValues = pickBy(get(state, 'form.outgoingInvoiceCreator.values', {}), isNotUndefined);
  const defaultValues = pickBy(buildOutgoingInvoiceValues(state), isNotUndefined);
  const invoiceTemplateId = get(state.invoiceTemplates, 'data[0].id');
  const today = moment().format('DD.MM.YYYY');
  const salutationInitialMessage = t('revenue.form.individual_contact.initial_message');

  if (crudMode === NEW_MODE) {
    return {
      ...outgoingInvoice,
      ...formValues,
      ...defaultValues,
      invoiceTemplateId: invoiceTemplateId,
      client,
      discountPercentage: defaultValues.discountPercentage || '',
      salutationHonorific: getSalutationHonorific(
        'defaultInvoiceSalutationDefined',
        { salutation: defaultValues.salutation },
        client
      ),
      salutationContent: isString(defaultValues.salutationContent)
        ? defaultValues.salutationContent
        : salutationInitialMessage,
      dueDate:
        defaultValues.invoiceDueDate === 0
          ? today
          : defaultValues.invoiceDueDate
          ? getDateXDaysFromNow(defaultValues.invoiceDueDate)
          : getDateXDaysFromNow(14),
      discountPeriod: defaultValues.invoiceDiscountDate
        ? getDateXDaysFromNow(defaultValues.invoiceDiscountDate)
        : null,
      creationDate: today,
    };
  }

  if (crudMode === EDIT_MODE && isFromDuplicate) {
    return {
      ...outgoingInvoice,
      invoiceTemplateId,
      client,
      dueDate: defaultValues.invoiceDueDate
        ? getDateXDaysFromNow(defaultValues.invoiceDueDate)
        : outgoingInvoice.dueDate,
      subject: defaultValues.subject || outgoingInvoice.subject,
      discountGrant: defaultValues.discountGrant || outgoingInvoice.discountGrant,
      discountPercentage: defaultValues.discountPercentage || outgoingInvoice.discountPercentage,
      discountPeriod: defaultValues.invoiceDiscountDate
        ? getDateXDaysFromNow(defaultValues.invoiceDiscountDate)
        : null,
      salutationContent: outgoingInvoice.salutationContent || defaultValues.salutationContent,
      salutationHonorific: getSalutationHonorific(
        'defaultInvoiceSalutationDefined',
        { salutation: outgoingInvoice.salutationHonorific || defaultValues.salutation },
        client
      ),
      personalNotes: outgoingInvoice.personalNotes || defaultValues.personalNotes,
    };
  }

  if (crudMode === CORRECT_MODE) {
    return {
      ...outgoingInvoice,
      invoiceTemplateId: invoiceTemplateId,
      client,
      dueDate: outgoingInvoice.dueDate || getDateXDaysFromNow(14),
      creationDate: today,
    };
  }

  if (crudMode === EDIT_MODE && isFromProposal) {
    return {
      ...outgoingInvoice,
      invoiceTemplateId,
      client,
      dueDate: isFromProposal
        ? getDateXDaysFromNow(defaultValues.invoiceDueDate)
        : outgoingInvoice.dueDate || null,
      salutationHonorific: getSalutationHonorific(
        'defaultInvoiceSalutationDefined',
        { salutation: outgoingInvoice.salutationHonorific || defaultValues.salutation },
        client
      ),
    };
  }

  if (crudMode === EDIT_MODE && isFromOrderConfirmation) {
    return {
      ...outgoingInvoice,
      invoiceTemplateId,
      client,
      dueDate: isFromOrderConfirmation
        ? getDateXDaysFromNow(defaultValues.invoiceDueDate)
        : outgoingInvoice.dueDate || null,
    };
  }

  if (crudMode === EDIT_MODE) {
    return {
      ...outgoingInvoice,
      invoiceTemplateId,
      client,
      dueDate: outgoingInvoice.dueDate || null,
    };
  }

  return {};
};
