import styled from 'styled-components';

import { OrderConfirmationStatus } from '../types';

type StatusProps = {
  status: OrderConfirmationStatus;
};

export const Status = styled.div<StatusProps>`
  display: flex;
  height: 30px;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: bold;
  text-transform: uppercase;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme, status }) =>
    status === OrderConfirmationStatus.CANCELLED
      ? theme.legacy.colors.redFont
      : status === OrderConfirmationStatus.DRAFT
      ? theme.legacy.colors.greyFont
      : theme.legacy.colors.greenFont};
`;

export const Text = styled.span`
  margin-left: ${({ theme }) => theme.space[2]};
`;
