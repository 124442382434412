import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/payment-plans';

const defaultState = {
  plans: [],
  error: false,
  isFetching: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        error: false,
        isFetching: true,
      };

    case FETCH_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };

    case FETCH_SUCCESS:
      return {
        ...defaultState,
        plans: action.response.data.map((plan) => plan.attributes),
      };
    default:
      return state;
  }
};
