import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, number, shape, string } from 'prop-types';

import { fetchIncomingInvoice as fetchIncomingInvoiceAction } from 'actions/incoming-invoice';
import {
  fetchPayments as fetchPaymentsAction,
  solveScaChallenge as solveScaChallengeAction,
} from 'actions/incoming-invoice/payments';
import { ACTION_PAYMENT_CREATOR_STEP_4_BACK } from 'constants/piwik';
import { t } from 'shared/utils';
import { showValidationNotification } from 'shared/utils/client-validation';
import { camelizeKeysDeep } from 'shared/utils/entity-mapper';
import {
  trackCreatePaymentSuccessEvent,
  trackPaymentEvent,
} from 'shared/utils/tracking/payments-tracking';
import { JobStatus } from 'types/entities/Figo';
import ActionButton from 'components/ActionPanel/ActionButton';
import { Format } from 'features/figoConnection/challenges/Embedded/constants';
import Hhd from 'features/figoConnection/challenges/Embedded/Hhd/Hhd';
import Photo from 'features/figoConnection/challenges/Embedded/Photo/Photo';
import TextHtml from 'features/figoConnection/challenges/Embedded/TextHtml/TextHtml';

import FieldsContainer from '../../components/FieldsContainer/FieldsContainer';
import PaymentCreatorActions from '../../components/PaymentCreatorActions/PaymentCreatorActions';

const initialState = {
  tanResponse: '',
};

class EmbeddedChallenge extends Component {
  state = initialState;

  submitScaChallenge = async () => {
    const {
      challenge,
      payment: { paymentId: paymentFigoId, accountId: accountFigoId },
      syncId,
      invoiceId,
      solveScaChallenge,
      goToLastStepAction,
      setCreatorState,
      fetchPayments,
      fetchIncomingInvoice,
      showNotification,
      paymentDetails: { amount: paymentAmount },
      invoiceAmount,
    } = this.props;
    const { id: challengeId } = challenge;

    const { tanResponse } = this.state;

    const formData = {
      accountFigoId,
      paymentFigoId,
      syncId,
      challengeId,
      challengeResponse: tanResponse,
    };

    const rawResponse = await solveScaChallenge(formData);
    const {
      status,
      challenge: newChallenge,
      isSuccess,
      error,
      createdAt,
    } = camelizeKeysDeep(rawResponse);

    if (status === JobStatus.AwaitAuth) {
      setCreatorState({ challenge: newChallenge });
      this.setState(initialState);
      return;
    }

    if (!isSuccess) {
      const errorMessage = error && error.message;

      showNotification([errorMessage]);
      return;
    }

    trackCreatePaymentSuccessEvent({ paymentAmount, invoiceAmount });
    this.setState(initialState);
    setCreatorState({ paymentCreationDate: createdAt });
    fetchPayments(invoiceId);
    fetchIncomingInvoice(invoiceId);
    goToLastStepAction();
  };

  handleTanInputChange = ({ target: { value: tanResponse } }) => this.setState({ tanResponse });

  renderTanScheme = () => {
    const { challenge } = this.props;
    const { tanResponse } = this.state;
    const sharedProps = {
      challenge,
      value: tanResponse,
      onChange: this.handleTanInputChange,
    };

    switch (challenge.format) {
      case Format.Photo:
        return <Photo {...sharedProps} />;

      case Format.Hhd:
        return <Hhd {...sharedProps} />;

      case Format.Text:
      case Format.Html:
        return <TextHtml {...sharedProps} />;

      // no default
    }
  };

  render() {
    const { backwardAction } = this.props;
    const { tanResponse } = this.state;

    return (
      <>
        <FieldsContainer>{this.renderTanScheme()}</FieldsContainer>
        <PaymentCreatorActions>
          <ActionButton
            appearance="primary"
            dataId="FigoPayments:button-accept-tan-confirmation"
            disabled={!tanResponse}
            onClick={this.submitScaChallenge}
            label={t('expenses.payments.actions.create')}
          />
          <ActionButton
            appearance="outlined"
            dataId="FigoPayments:button-cancel-tan-confirmation"
            onClick={backwardAction}
            onClickSideAction={trackPaymentEvent(ACTION_PAYMENT_CREATOR_STEP_4_BACK)}
            label={t('expenses.payments.actions.backward')}
          />
        </PaymentCreatorActions>
      </>
    );
  }
}

EmbeddedChallenge.propTypes = {
  backwardAction: func.isRequired,
  goToLastStepAction: func.isRequired,
  setCreatorState: func.isRequired,
  challenge: shape({}).isRequired,
  payment: shape({ paymentId: string, accountId: string }).isRequired,
  syncId: string.isRequired,
  solveScaChallenge: func.isRequired,
  fetchPayments: func.isRequired,
  invoiceId: number.isRequired,
  fetchIncomingInvoice: func.isRequired,
  showNotification: func.isRequired,
  paymentDetails: shape({}),
  invoiceAmount: string,
};

const mapDispatchToProps = (dispatch, { paymentAction }) => ({
  showNotification: (args) => dispatch(showValidationNotification(args)),
  solveScaChallenge: paymentAction(dispatch)(solveScaChallengeAction),
  fetchPayments: paymentAction(dispatch)(fetchPaymentsAction),
  fetchIncomingInvoice: (id) => dispatch(fetchIncomingInvoiceAction(id)),
});

export default connect(null, mapDispatchToProps)(EmbeddedChallenge);
