const NAMESPACE = 'USER_PAYMENT_PLAN';

export const FETCH_REQUEST = `${NAMESPACE}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${NAMESPACE}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${NAMESPACE}/FETCH_FAILURE`;

export const CREATE_DOWNGRADE_REQUEST = `${NAMESPACE}/CREATE_DOWNGRADE_REQUEST`;
export const CREATE_DOWNGRADE_SUCCESS = `${NAMESPACE}/CREATE_DOWNGRADE_SUCCESS`;
export const CREATE_DOWNGRADE_FAILURE = `${NAMESPACE}/CREATE_DOWNGRADE_FAILURE`;

export const CREATE_UPGRADE_REQUEST = `${NAMESPACE}/CREATE_UPGRADE_REQUEST`;
export const CREATE_UPGRADE_SUCCESS = `${NAMESPACE}/CREATE_UPGRADE_SUCCESS`;
export const CREATE_UPGRADE_FAILURE = `${NAMESPACE}/CREATE_UPGRADE_FAILURE`;

export const CREATE_CANCELLATION_REQUEST = `${NAMESPACE}/CREATE_CANCELLATION_REQUEST`;
export const CREATE_CANCELLATION_SUCCESS = `${NAMESPACE}/CREATE_CANCELLATION_SUCCESS`;
export const CREATE_CANCELLATION_FAILURE = `${NAMESPACE}/CREATE_CANCELLATION_FAILURE`;
