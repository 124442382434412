import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { arrayOf, func, number, shape } from 'prop-types';
import { reduxForm, SubmissionError } from 'redux-form';

import {
  fetchPaymentsBankAccounts as fetchPaymentsBankAccountsAction,
  newPayment as newPaymentAction,
} from 'actions/incoming-invoice/payments';
import { dynamicUpdateSupplier as dynamicUpdateSupplierAction } from 'actions/suppliers';
import { DATE_FORMAT } from 'constants/datetime';
import { FORM_NAME } from 'constants/incoming-invoice/payments';
import {
  ACTION_CANCEL_PAYMENT,
  ACTION_PAYMENT_CREATOR_STEP_2,
  ACTION_UPDATE_SUPPLIER,
} from 'constants/piwik';
import { paymentSelector } from 'reducers/form';
import { formatIBAN, removeSpaces, t } from 'shared/utils';
import { camelizeKeysDeep } from 'shared/utils/entity-mapper';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { isValidIban } from 'shared/utils/regex';
import { trackPaymentEvent } from 'shared/utils/tracking/payments-tracking';
import ActionButton from 'components/ActionPanel/ActionButton';
import BankAccountSelectField from 'components/BankAccountSelectField/BankAccountSelectField';
import { CurrencyField, DateField, TextField } from 'components/Form';
import Counter from 'components/Form/Counter';
import WithCurrencySign from 'components/WithCurrencySign/WithCurrencySign';

import AccountNumberUpdater from '../../components/AccountNumberUpdater/AccountNumberUpdater';
import FieldsContainer from '../../components/FieldsContainer/FieldsContainer';
import FormField from '../../components/FormField/FormField';
import Hint from '../../components/Hint/Hint';
import ParallelContainer from '../../components/ParallelContainer/ParallelContainer';
import PaymentCreatorActions from '../../components/PaymentCreatorActions/PaymentCreatorActions';
import Subheader from '../../components/Subheader/Subheader';

import styles from './PaymentDetails.module.css';

const transformIban = (iban) => ({
  ibanPrefix: removeSpaces(iban).substring(0, 4),
  bankCode: removeSpaces(iban).substring(4, 12),
  accountNumber: removeSpaces(iban).substring(12),
});

const transformData = ({ accountNumber: iban, amount, ...data }) => ({
  ...data,
  ...transformIban(iban),
  iban,
  paymentType: 'SEPA transfer',
  amount,
});

const MAX_LENGTH = 108;
class PaymentDetails extends Component {
  get initiallySelectedBankAccount() {
    const { paymentsBankAccounts } = this.props;
    return paymentsBankAccounts.find((account) => account.main);
  }

  componentDidMount() {
    const { payment, reset: resetForm, fetchPaymentsBankAccounts } = this.props;

    fetchPaymentsBankAccounts().then(() => this.selectAccount(this.initiallySelectedBankAccount));

    if (payment) return;
    resetForm();
  }

  updateSupplier = async () => {
    const { supplier, paymentAccountNumber, dynamicUpdateSupplier } = this.props;

    trackPaymentEvent(ACTION_UPDATE_SUPPLIER)();

    return dynamicUpdateSupplier(supplier.id, { ...supplier, iban: paymentAccountNumber });
  };

  selectAccount = (selectedAccount = {}) => {
    const { setCreatorState } = this.props;
    setCreatorState({ selectedAccount });
  };

  handleSubmit = async (data) => {
    const { newPayment, invoiceId, setCreatorState, selectedAccount } = this.props;
    const formData = transformData({ ...data, accountId: selectedAccount.id });
    setCreatorState({ paymentDetails: formData });
    const rawResponse = await newPayment(invoiceId, formData);
    const { response, errors } = camelizeKeysDeep(rawResponse);

    if (errors) throw new SubmissionError(errors);
    return response;
  };

  submitNewPayment = async () => {
    const { handleSubmit, setCreatorState, forwardAction } = this.props;

    /**
     * Start the payment process.
     * This action will notify Figo that I want to perform a payment.
     * This action will return Figo payment object that will be used to continue the process.
     */
    const { data: { attributes: figoPaymentObject = {} } = {} } = await handleSubmit(
      this.handleSubmit
    )();

    if (isEmpty(figoPaymentObject)) return;

    setCreatorState({
      payment: figoPaymentObject,
    });

    forwardAction();
  };

  cancelPayment = () => {
    const { finishAction, resetCreatorState, reset: resetForm } = this.props;
    resetCreatorState();
    finishAction();
    resetForm(); // required because of keepDirtyOnReinitialize: true
  };

  render() {
    const {
      supplierAccountNumber = '',
      paymentAccountNumber = '',
      paymentsBankAccounts,
      purposeLength,
    } = this.props;

    const isValidAccountNumber = isValidIban(paymentAccountNumber);
    const hasNewValidAccountNumber =
      isValidAccountNumber &&
      removeSpaces(paymentAccountNumber) !== removeSpaces(supplierAccountNumber);
    const isPurposeInvalid = purposeLength > MAX_LENGTH;

    return (
      <>
        <FieldsContainer>
          <Hint text={t('expenses.payments.creator.hints.creator')} />
          <Subheader
            title={t('expenses.payments.creator.subheaders.owners_account')}
            className={styles.subheader}
          />
          <ParallelContainer>
            <FormField name="ownerName" readonly component={TextField} />
            <FormField
              name="accountId"
              bankAccounts={paymentsBankAccounts}
              component={BankAccountSelectField}
              onSelect={this.selectAccount}
              initiallySelectedBankAccount={this.initiallySelectedBankAccount}
              shouldInitiallySelectBankAccount
              required
            />
          </ParallelContainer>
          <Subheader
            title={t('expenses.payments.creator.subheaders.recipient')}
            className={styles.subheader}
          />
          <ParallelContainer>
            <FormField name="recipientName" readonly component={TextField} />
            <FormField
              name="accountNumber"
              component={TextField}
              required
              checker={serverValidationChecker}
              dataId="FigoPayments:input-account-number"
            />
          </ParallelContainer>
          {hasNewValidAccountNumber && (
            <AccountNumberUpdater updateSupplier={this.updateSupplier} />
          )}
          <Subheader title={t('expenses.payments.creator.subheaders.additional_data')} />
          <FormField
            name="purpose"
            dataId="FigoPayments:input-purpose"
            component={TextField}
            required
            invalid={isPurposeInvalid}
          >
            <Counter value={purposeLength} max={MAX_LENGTH} />
          </FormField>
          <ParallelContainer>
            <FormField
              name="orderDate"
              className={styles.orderDate}
              readonly
              component={DateField}
            />
            <WithCurrencySign>
              <CurrencyField
                className={styles.amount}
                name="amount"
                required
                component={TextField}
                label={t('expenses.payments.creator.form.amount')}
                placeholder={t('expenses.payments.creator.form.amount')}
              />
            </WithCurrencySign>
          </ParallelContainer>
        </FieldsContainer>
        <PaymentCreatorActions>
          <ActionButton
            appearance="primary"
            dataId="FigoPayments:button-accept-payment-details"
            onClick={this.submitNewPayment}
            onClickSideAction={trackPaymentEvent(ACTION_PAYMENT_CREATOR_STEP_2)}
            disabled={isPurposeInvalid}
            label={t('expenses.payments.actions.forward')}
          />
          <ActionButton
            appearance="outlined"
            onClick={this.cancelPayment}
            onClickSideAction={trackPaymentEvent(ACTION_CANCEL_PAYMENT)}
            label={t('expenses.payments.actions.abort')}
          />
        </PaymentCreatorActions>
      </>
    );
  }
}

PaymentDetails.propTypes = {
  handleSubmit: func.isRequired,
  forwardAction: func.isRequired,
  finishAction: func.isRequired,
  newPayment: func.isRequired,
  dynamicUpdateSupplier: func.isRequired,
  setCreatorState: func.isRequired,
  resetCreatorState: func.isRequired,
  fetchPaymentsBankAccounts: func.isRequired,
  invoiceId: number.isRequired,
  supplier: shape({}),
  supplierAccountNumber: number.isRequired,
  payment: shape({}),
  paymentAccountNumber: number.isRequired,
  paymentsBankAccounts: arrayOf(shape({})).isRequired,
  purposeLength: number,
  reset: func.isRequired,
  selectedAccount: shape({}),
};

const PaymentDetailsReduxForm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true, // required for client IBAN change
  updateUnregisteredFields: false,
  persistentSubmitErrors: true,
})(PaymentDetails);

const mapStateToProps = (state, ownProps) => {
  const {
    supplier,
    invoiceNumber,
    invoiceName,
    invoiceAmount,
    paymentDetails,
    payments,
    bankTransfers,
  } = ownProps;
  const details = paymentDetails || {};
  const recipientName = supplier && (supplier.companyName || supplier.lastName);
  const accountNumber =
    details.iban ||
    state.incomingInvoice.ocr.processedData.iban ||
    (supplier && supplier.iban) ||
    '';
  const purpose =
    details.purpose ||
    (invoiceName || invoiceNumber ? `${invoiceName || ''} ${invoiceNumber || ''}` : '');
  const paymentAccountNumber = paymentSelector(state, 'accountNumber') || '';
  const amount =
    details.amount || (isEmpty(payments) && isEmpty(bankTransfers) ? invoiceAmount : '');
  const purposeLength = !state.form.paymentCreator.values
    ? purpose.length
    : state.form.paymentCreator.values.purpose.length;

  return {
    purposeLength,
    supplierAccountNumber: accountNumber,
    paymentAccountNumber,
    initialValues: {
      ownerName: state.company.details.name,
      recipientName,
      accountNumber: formatIBAN(removeSpaces(accountNumber)),
      purpose,
      amount,
      accountId: details.accountId || '',
      orderDate: moment().format(DATE_FORMAT),
      savePin: false,
    },
  };
};

const mapDispatchToProps = (dispatch, { paymentAction }) => ({
  dynamicUpdateSupplier: paymentAction(dispatch)(dynamicUpdateSupplierAction),
  newPayment: paymentAction(dispatch)(newPaymentAction),
  fetchPaymentsBankAccounts: () => dispatch(fetchPaymentsBankAccountsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsReduxForm);
