import React, { memo, ReactNode } from 'react';
import cx from 'classnames';

import styles from './Subtitle.module.css';

type SubtitleProps = {
  children: ReactNode;
  className?: string;
};

const Subtitle = ({ children, className }: SubtitleProps) => (
  <div className={cx(styles.subtitle, className)}>{children}</div>
);

export default memo(Subtitle);
