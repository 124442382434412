import { pdfjs } from 'react-pdf/dist/esm/entry.webpack';

import type { DownloadFilePreview, SetIsPreviewRendering } from '../types';

type GeneratePDFPreviewProps = {
  canvas: HTMLCanvasElement | null;
  contractId: string;
  fileId: string;
  downloadFilePreview: DownloadFilePreview;
  setIsPreviewRendering: SetIsPreviewRendering;
  setActivePdf: (pdf: any) => void;
};

export default ({
  canvas,
  contractId,
  fileId,
  downloadFilePreview,
  setIsPreviewRendering,
  setActivePdf,
}: GeneratePDFPreviewProps) => {
  if (!canvas) return;

  downloadFilePreview(contractId, fileId).then(({ rawResponse }) => {
    if (!rawResponse) return;

    pdfjs
      // @ts-ignore
      .getDocument(rawResponse)
      .promise.then((pdf: any) => {
        setActivePdf(pdf);
        pdfPreview({ pdf, canvas, setIsPreviewRendering });
      });
  });
};

type PdfPreview = {
  pdf: any;
  canvas: HTMLCanvasElement;
  setIsPreviewRendering: SetIsPreviewRendering;
};

export const pdfPreview = ({ pdf, canvas, setIsPreviewRendering }: PdfPreview) =>
  pdf.getPage(1).then((page: any) => {
    const initialScale = 1;
    const viewport = page.getViewport({ scale: initialScale });
    const newScale = canvas!.parentElement!.offsetWidth / viewport.width;
    const newViewport = page.getViewport({ scale: newScale });
    const context = canvas.getContext('2d');

    canvas.width = newViewport.width;
    canvas.height = newViewport.height;

    // Clear canvas for safety when updating existing one.
    context!.clearRect(0, 0, viewport.width, viewport.height);

    // Render PDF page into canvas context.
    page
      .render({
        canvasContext: context,
        viewport: newViewport,
      })
      .promise.then(() => setIsPreviewRendering(false));
  });
