import React, { Children, cloneElement } from 'react';
import noop from 'lodash/noop';
import { func, node, shape } from 'prop-types';

import Section from 'components/Form/Section/Section';

import styles from './Search.module.css';

const SearchGroup = ({
  filters,
  setQueryAction,
  setRangeQueryAction = noop,
  clearFieldAction = noop,
  children,
}) => {
  const childrenWithInjectedProps = Children.map(
    children,
    (child) =>
      child &&
      cloneElement(child, { setQueryAction, setRangeQueryAction, filters, clearFieldAction })
  );

  return (
    <Section wrapperTheme={styles.section} className={styles.searchGroup} isHeaderHidden>
      {childrenWithInjectedProps}
    </Section>
  );
};

SearchGroup.propTypes = {
  filters: shape({}).isRequired,
  setQueryAction: func,
  setRangeQueryAction: func,
  clearFieldAction: func,
  children: node,
};

export default SearchGroup;
