import React, { RefObject, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway } from 'react-use';
import cx from 'classnames';
import { noop } from 'lodash';

import { hideSidebar } from 'actions/notifications-sidebar';
import { SidebarSections } from 'constants/notifications-sidebar';
import withViewport from 'shared/hoc/withViewport';
import { t } from 'shared/utils';
import { RootState } from 'store';
import { IGNORE_OUTSIDE_CLICK_CLASS as IGNORE_OUTSIDE_LINE_ITEM_CLICK_CLASS } from 'components/LineItems';
import Section from 'components/NotificationsSidebar/components/Section/Section';
import Messages from 'components/NotificationsSidebar/Messages/Messages';
import Todos from 'features/todos/Todos';

import sectionStyles from './components/Section/Section.module.css';
import styles from './NotificationsSidebar.module.css';

type NotificationsSidebarProps = {
  isLgUp: boolean;
  disableOnClickOutside: boolean;
  notificationsRef: RefObject<HTMLDivElement>;
};

const NotificationsSidebar = ({
  isLgUp,
  disableOnClickOutside,
  notificationsRef,
}: NotificationsSidebarProps) => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state: RootState) => state.notificationsSidebar.isOpen);
  const activeSection = useSelector((state: RootState) => state.notificationsSidebar.activeSection);
  const activeMessageId = useSelector((state: RootState) => state.messageInbox.activeMessageId);
  const isSectionActive = useCallback((section) => section === activeSection, [activeSection]);
  const isMessagesSectionActive = isSectionActive(SidebarSections.MESSAGES);
  const isTodosSectionActive = isSectionActive(SidebarSections.TODOS);

  const handleCloseSidebar = useCallback(() => dispatch(hideSidebar()), [dispatch]);
  const isOnClickOutsideDisabled = disableOnClickOutside ? noop : handleCloseSidebar;

  useClickAway(notificationsRef, isOnClickOutsideDisabled);

  return (
    <div
      id="notifications-sidebar"
      className={cx(styles.container, IGNORE_OUTSIDE_LINE_ITEM_CLICK_CLASS, {
        [styles.open]: isSidebarOpen,
        [styles.animateLeft]: isLgUp && isMessagesSectionActive && activeMessageId,
      })}
      data-id="NotificationsSidebar:container"
    >
      <Section
        title={t('sidebar.todos.header')}
        closeAction={handleCloseSidebar}
        isActive={isTodosSectionActive}
      >
        <Todos />
      </Section>
      <Section
        title={t('sidebar.messages.header')}
        closeAction={handleCloseSidebar}
        isActive={isMessagesSectionActive}
        className={isMessagesSectionActive ? sectionStyles.detailsExtension : undefined}
      >
        <Messages isActive={isMessagesSectionActive} />
      </Section>
    </div>
  );
};

export default withViewport(NotificationsSidebar);
