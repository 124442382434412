import React, { memo, ReactNode } from 'react';

import { Wrapper } from './TableHeader.styled';

export type TableHeaderProps = {
  children: ReactNode;
  align?: 'left' | 'right' | 'center';
};

export const TableHeader = ({ align = 'left', children, ...rest }: TableHeaderProps) => {
  return (
    <Wrapper align={align} {...rest}>
      {children}
    </Wrapper>
  );
};

export default memo(TableHeader);
