export const NEW_MODE = 'new_mode';
export const EDIT_MODE = 'edit_mode';
export const SHOW_MODE = 'show_mode';
export const CORRECT_MODE = 'correct_mode';
export const CANCEL_MODE = 'cancel_mode';
export const CREATE_REMINDER_MODE = 'create_reminder_mode';
export const IMPORT_MODE = 'import_mode';

// TODO create enum
export type CrudMode =
  | typeof NEW_MODE
  | typeof EDIT_MODE
  | typeof SHOW_MODE
  | typeof CORRECT_MODE
  | typeof CANCEL_MODE
  | typeof CREATE_REMINDER_MODE
  | typeof IMPORT_MODE;
