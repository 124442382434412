import React, { Component } from 'react';
import { connect } from 'react-redux';
import noop from 'lodash/noop';

import { PaymentStep } from 'constants/incoming-invoice/payments';
import { incomingInvoiceSelector } from 'reducers/form';
import { incomingInvoiceDetailsSelector } from 'selectors/incomingInvoice';
import { RootState } from 'store';
import { ChallengeType } from 'types/entities/Figo';
import type { Supplier } from 'types/entities/Supplier';
import LoadingIcon from 'components/LoadingIcon';
import Modal from 'components/Modal';

import PaymentCreatorFooter from './components/PaymentCreatorFooter/PaymentCreatorFooter';
import PaymentCreatorHeader from './components/PaymentCreatorHeader/PaymentCreatorHeader';
import DecoupledChallenge from './steps/DecoupledChallenge/DecoupledChallenge';
import EmbeddedChallenge from './steps/EmbeddedChallenge/EmbeddedChallenge';
import Login from './steps/Login/Login';
import MethodSelectionChallenge from './steps/MethodSelectionChallenge/MethodSelectionChallenge';
import PaymentDetails from './steps/PaymentDetails/PaymentDetails';
import PaymentSummary from './steps/PaymentSummary/PaymentSummary';
import RedirectChallenge from './steps/RedirectChallenge/RedirectChallenge';

import styles from './PaymentCreator.module.css';

const STEP_VIEWS: { [key in PaymentStep]: any } = {
  [PaymentStep.PaymentDetails]: PaymentDetails,
  [PaymentStep.PaymentSummary]: PaymentSummary,
  [PaymentStep.Login]: Login,
  [PaymentStep.MethodSelectionChallenge]: MethodSelectionChallenge,
  [PaymentStep.DecoupledChallenge]: DecoupledChallenge,
  [PaymentStep.EmbeddedChallenge]: EmbeddedChallenge,
  [PaymentStep.RedirectChallenge]: RedirectChallenge,
};

const CHALLENGE_TYPE_TO_PAYMENT_STEP: { [key in ChallengeType]?: PaymentStep } = {
  [ChallengeType.Decoupled]: PaymentStep.DecoupledChallenge,
  [ChallengeType.MethodSelection]: PaymentStep.MethodSelectionChallenge,
  [ChallengeType.Embedded]: PaymentStep.EmbeddedChallenge,
  [ChallengeType.Redirect]: PaymentStep.RedirectChallenge,
};

const initialState = {
  paymentStep: PaymentStep.PaymentDetails,
  /**
   * Figo payment object
   */
  payment: null,
  /**
   * Challenge information from Figo.
   */
  challenge: {
    createdAt: '',
    type: '',
    id: '',
    authMethods: [],
  },
  syncId: '',
  paymentDetails: {
    accountNumber: '',
    amount: 0,
  },
  paymentCreationDate: null,
  accountId: null,
  selectedTanScheme: null,
  isLoading: false,
};

type PaymentCreatorProps = {
  invoiceId: number;
  invoiceNumber: number;
  invoiceName: string;
  invoiceAmount: number;
  supplierAccountNumber: string;
  paymentAccountNumber: string;
  paymentStep: number;
  isOpen: boolean;
  cancelPayment: () => void;
  finishPayment: () => void;
  payments?: Array<object>;
  supplier?: Supplier;
  bankTransfers: Array<object>;
  paymentsBankAccounts: Array<object>;
  initialValues?: object;
};

class PaymentCreator extends Component<PaymentCreatorProps, any> {
  state = initialState;

  get viewProps() {
    const { finishPayment, ...props } = this.props;

    return {
      ...props,
      ...this.state,
      forwardAction: this.moveForward,
      backwardAction: this.moveBackward,
      goToLastStepAction: this.moveToLastStep,
      finishAction: this.finishCreator,
      setCreatorState: this.setCreatorState,
      resetCreatorState: this.resetCreatorState,
      paymentAction: this.paymentAction,
      moveToLoginStep: this.moveToLoginStep,
    };
  }

  paymentAction =
    (dispatch: any) =>
    (action: any) =>
    async (...args: any) => {
      this.setState({ isLoading: true });

      try {
        const result = await dispatch(action(...args));
        return { isSuccess: true, ...result };
      } catch (error) {
        return Promise.resolve({ isSuccess: false, error });
      } finally {
        this.setState({ isLoading: false });
      }
    };

  setCreatorState = async (newState: any) =>
    new Promise((resolve) => this.setState(newState, () => resolve(this.state)));

  resetCreatorState = () => this.setState(initialState);

  finishCreator = () => {
    this.props.finishPayment();
    this.resetCreatorState();
  };

  moveForward = () => {
    const { challenge, paymentStep } = this.state;
    const nextStep =
      CHALLENGE_TYPE_TO_PAYMENT_STEP[challenge.type as ChallengeType] || paymentStep + 1;

    this.setState({ paymentStep: nextStep });
  };

  moveBackward = () => {
    const { paymentStep } = this.state;
    const stepSize = paymentStep === PaymentStep.EmbeddedChallenge ? 2 : 1;

    if (paymentStep === PaymentStep.PaymentDetails) return;

    this.setState({ paymentStep: paymentStep - stepSize });
  };

  moveToLastStep = () => {
    this.setState({ paymentStep: PaymentStep.PaymentSummary });
  };

  moveToLoginStep = () => {
    this.setState({ paymentStep: PaymentStep.Login });
  };

  renderStep = () => {
    const Component = STEP_VIEWS[this.state.paymentStep];

    return React.createElement(Component, this.viewProps);
  };

  render() {
    const { isOpen, invoiceName } = this.props;
    const { paymentStep, isLoading } = this.state;

    return (
      <Modal isOpen={isOpen} onRequestClose={noop}>
        <div className={styles.main}>
          {isLoading && (
            <div className={styles.modal}>
              <LoadingIcon />
            </div>
          )}
          <PaymentCreatorHeader invoiceName={invoiceName} paymentStep={paymentStep} />
          {this.renderStep()}
          <PaymentCreatorFooter />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  invoiceId: incomingInvoiceDetailsSelector(state).id,
  invoiceNumber: incomingInvoiceSelector(state, 'number'),
  invoiceName: incomingInvoiceSelector(state, 'name'),
  invoiceAmount: incomingInvoiceDetailsSelector(state).paymentAmount,
  bankTransfers: state.incomingInvoice.bankTransfers,
  payments: state.incomingInvoice.payments.list,
  paymentsBankAccounts: state.incomingInvoice.paymentsBankAccounts.list,
  supplier: incomingInvoiceSelector(state, 'supplier'),
});

export default connect(mapStateToProps)(PaymentCreator);
