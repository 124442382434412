import React from 'react';
import cx from 'classnames';
import { func, node, string } from 'prop-types';

import styles from './UnderlinedButton.module.css';

export const UnderlinedButton = ({ className, children, ...rest }) => (
  <button className={cx(styles.buttonUnderlined, className)} type="button" {...rest}>
    <span>{children}</span>
  </button>
);

UnderlinedButton.propTypes = {
  onClick: func,
  className: string,
  children: node,
};
