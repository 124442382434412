import 'react-dates-legacy/initialize';

import React from 'react';
import { Moment } from 'moment';

import { DATE_FORMAT, DATE_FORMAT_MONTH_YEAR, DATEPICKER_HEADER } from 'constants/datetime';
import { getMomentDateForDatepicker } from 'shared/utils/date/parser';

import { FieldOnChange, SetIsFocused } from './DateField.types';

import 'react-dates-legacy/lib/css/_datepicker.css';

export const reopenPopper = (setIsFocused: SetIsFocused) => {
  setIsFocused(false);
  setTimeout(() => setIsFocused(true)); // delay is needed, otherwise it won't trigger
};

export const getDateForController = (date: string) => {
  if (date) {
    const momentDate = getMomentDateForDatepicker(date);
    return momentDate.isValid() ? momentDate : null;
  }

  return null;
};

export const handleDateChange = ({
  selectedMomentDate,
  formOnChange,
  fieldOnChange,
  setIsFocused,
}: {
  selectedMomentDate: Moment | null;
  formOnChange: (value: any) => void;
  fieldOnChange: FieldOnChange;
  setIsFocused: SetIsFocused;
}) => {
  selectedMomentDate && formOnChange(selectedMomentDate.format(DATE_FORMAT));
  fieldOnChange();
  setIsFocused(false);
};

export const handleFocusChange = (isFocused: boolean | null, setIsFocused: SetIsFocused) =>
  isFocused && setIsFocused(isFocused);

export const isOutsideRange = ({
  momentDate,
  minDate,
  maxDate,
}: {
  momentDate: Moment;
  minDate?: Moment | null;
  maxDate?: Moment | null;
}) => {
  if (minDate && maxDate)
    return momentDate.isBefore(minDate, 'day') || momentDate.isAfter(maxDate, 'day');
  if (minDate) return momentDate.isBefore(minDate, 'day');
  if (maxDate) return momentDate.isAfter(maxDate, 'day');

  return false;
};

export const renderDayContents = (momentDate: Moment) => {
  const day = momentDate.date();

  return (
    <span className={`DatePickerDay-${day}`} data-id={`DatePicker:day-${day}`}>
      {day}
    </span>
  );
};

export const renderMonth = (momentDate: Moment) => (
  <span className="DatePickerMonth" data-date={momentDate.format(DATE_FORMAT_MONTH_YEAR)}>
    {momentDate.format(DATEPICKER_HEADER)}
  </span>
);

export const handleInputChange = (text: string, fieldOnChange: (text: string) => void) => {
  const dateMoment = getMomentDateForDatepicker(text);
  const isDateValid = dateMoment.isValid();
  const textToSet = isDateValid ? dateMoment.format(DATE_FORMAT) : text;

  fieldOnChange(textToSet);
};

export const handleKeyDown = ({
  e,
  isFocused,
  setIsFocused,
}: {
  e: KeyboardEvent;
  isFocused: boolean;
  setIsFocused: SetIsFocused;
}) => {
  if (isFocused) {
    closePopperOnEnterPress({ e, setIsFocused });
    closePopperOnShiftTabPress({ e, setIsFocused });
  }
};

const closePopperOnEnterPress = ({
  e,
  setIsFocused,
}: {
  e: KeyboardEvent;
  setIsFocused: SetIsFocused;
}) => {
  // if enter
  if (e.keyCode === 13) {
    const target = e.target as HTMLInputElement;
    e.preventDefault();
    target.blur();
    setIsFocused(false);
  } else if (keyNotAllowed({ e })) {
    e.preventDefault();
  }
};

const closePopperOnShiftTabPress = ({
  e,
  setIsFocused,
}: {
  e: KeyboardEvent;
  setIsFocused: SetIsFocused;
}) => {
  // if shift tab
  if (e.shiftKey && e.keyCode === 9) {
    setIsFocused(false);
  }
};

const keyNotAllowed = ({ e }: { e: KeyboardEvent }) => {
  // allow only backspace, tab, left arrow, right arrow, delete, dot and numbers
  return !(
    [8, 9, 37, 39, 46, 190].includes(e.keyCode) ||
    (e.keyCode >= 48 && e.keyCode <= 57) ||
    (e.keyCode >= 96 && e.keyCode <= 105)
  );
};
