import { AxiosPromise } from 'axios';
import { JsonApiResponse } from 'json-api-normalizer';

import api from '../index';

export const getIncomingInvoice = (id: string): AxiosPromise<JsonApiResponse> => {
  const url = `/me/incoming_invoices/${id}`;

  return api.get(url);
};
