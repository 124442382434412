import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { sortBy } from 'lodash';

import { fetchBankAccounts } from 'actions/bank-account';
import { DE_KONTOUMSAETZE, DE_MANAGE_ACCOUNTS } from 'constants/kontoumsaetze';
import paths from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import EmptyEntryPage from 'components/EmptyEntryPage/EmptyEntryPage';
import BankTransfersEmptyStateImage from 'components/EmptyEntryPage/images/BankTransfers';
import Loading from 'components/Loading';
import Tabs from 'components/Tabs';

import BankTransfersManager from './Manager';

import styles from './BankTransfers.module.css';

const TabsAddon = (
  <Link
    className={styles.link}
    to={paths.bankAccounts}
    onClick={() => piwikHelpers.trackEvent(DE_KONTOUMSAETZE, DE_MANAGE_ACCOUNTS)}
  >
    {t('bank_transfers.table.header.manage_bank_accounts')}
  </Link>
);

const BankTransfers = () => {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const bankAccounts = useAppSelector((state) => state.bankAccount.data);
  const isEmpty = bankAccounts.length === 0;
  const sections = useMemo(
    () =>
      sortBy(bankAccounts, ['ordinalNumber']).map(({ name, id, credentials }) => ({
        heading: name,
        bankAccountId: id,
        component: BankTransfersManager,
        credentials,
        id,
      })),
    [bankAccounts]
  );
  const initialTab = useMemo(() => {
    if (!id) return undefined;

    const index = sections.findIndex(({ bankAccountId }) => bankAccountId === id);

    return index === -1 ? undefined : index;
  }, [sections, id]);

  const handleTabSwitch = useCallback(
    (tabIndex: number) => {
      const tab = sections[tabIndex];

      history.push(paths.showbankTransfer(tab.bankAccountId));
    },
    [sections, history]
  );

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchBankAccounts());
      setIsLoading(false);
    };

    fetch();
  }, [dispatch]);

  if (isLoading) {
    return (
      <div className={styles.main}>
        <Loading />
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div className={styles.main}>
        <EmptyEntryPage
          dataIdPrefix="BankTransfers"
          Image={BankTransfersEmptyStateImage}
          header={t('empty_entry_pages.bank_transfers.header')}
          subheader={t('empty_entry_pages.bank_transfers.subheader')}
          info={[
            t('empty_entry_pages.bank_transfers.info.row_1'),
            t('empty_entry_pages.bank_transfers.info.row_2'),
          ]}
          contentText={t('empty_entry_pages.bank_transfers.content_text')}
          createButtonLabel={t('empty_entry_pages.bank_transfers.button_label')}
          onCreateButtonClick={() => history.push(paths.bankAccounts)}
        />
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <Tabs
        sections={sections}
        tabsAddon={TabsAddon}
        switchTabAddon={handleTabSwitch}
        initialTab={initialTab}
      />
    </div>
  );
};

export default BankTransfers;
