import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { arrayOf, bool, func, shape } from 'prop-types';

import {
  clearFilters as clearFiltersAction,
  deleteSupplier,
  fetchSuppliers as fetchSuppliersAction,
  setQueryParam,
  sortSuppliers,
} from 'actions/suppliers';
import { MODELS } from 'constants/contacts';
import { Resources } from 'constants/resources';
import TableButtons from 'containers/TableButtons/TableButtons';
import sharedStyles from 'shared/styles/components.module.css';
import { t } from 'shared/utils';
import { hasAnyFiltersActive as hasAnyFiltersActiveChecker } from 'shared/utils/hasAnyFiltersActive';
import { useRefetchOnPropsChange } from 'shared/utils/hooks/useRefetchOnPropsChange';
import CardView, { HeadingSection, Section } from 'components/CardView';
import { FiltersGroup } from 'components/Filter/FiltersGroup/FiltersGroup';
import I18n from 'components/I18n';
import Loading from 'components/Loading';
import { Pagination } from 'components/Pagination/Pagination';

import SuppliersTable from './SuppliersTable';

import localStyles from './Suppliers.module.css';

const styles = { ...sharedStyles, ...localStyles };

const Suppliers = ({
  fetchSuppliers,
  remove,
  sort,
  pagination,
  data,
  sorting,
  isFetching,
  setQueryAction,
  clearFilters,
  filters,
}) => {
  /**
   * This flag is initially set to false and is set to true after first fetch
   * for suppliers comes back. It is used for displaying the initial loader.
   */
  const [areSuppliersFetched, setAreSuppliersFetched] = useState(false);

  const fetch = useCallback(
    (pagination = { page: 1 }) => {
      fetchSuppliers({ pagination_resource: Resources.SUPPLIERS, ...pagination }, sorting, filters);
    },
    [fetchSuppliers, sorting, filters]
  );

  useRefetchOnPropsChange({
    fetchFunction: async ({
      pagination: newPagination,
      sorting: newSorting,
      overwrittenValues,
      parsedFilters: newFilters,
    }) => {
      await fetchSuppliers(
        { pagination_resource: Resources.SUPPLIERS, ...newPagination, ...overwrittenValues },
        newSorting,
        newFilters
      );
      setAreSuppliersFetched(true);
    },
    props: { parsedFilters: filters, pagination, sorting },
  });

  if (!areSuppliersFetched) {
    return (
      <div className={styles.page}>
        <div className={styles.main}>
          <Loading />
        </div>
      </div>
    );
  }

  const areProposalsEmpty = data.length === 0;
  const hasAnyFiltersActive = hasAnyFiltersActiveChecker(filters);

  return (
    <div className={styles.page}>
      <CardView className={styles.card}>
        <HeadingSection>
          <I18n t="suppliers.subtitle" className={styles.headingText} />
          <div
            className={cx(styles.pullRight, styles.links, {
              [styles.hiddenSection]: areProposalsEmpty && !hasAnyFiltersActive,
            })}
          >
            <TableButtons whichFolder={MODELS.SUPPLIER} />
          </div>
          <FiltersGroup
            className={styles.filtersGroup}
            isResetButtonDisabled={!hasAnyFiltersActive}
            filters={filters}
            onFiltersChange={({ name, value }) => setQueryAction(name)(value)}
            onFiltersReset={clearFilters}
            placeholder={t('tables.filters.filters_group.suppliers.placeholder')}
            dataIds={{
              input: 'Suppliers:input-search',
              resetButton: 'Suppliers:button-reset',
              toggleDropdownButton: 'Suppliers:button-toggleDropdown',
            }}
          />
        </HeadingSection>
        <Section className={styles.section}>
          {areProposalsEmpty && hasAnyFiltersActive && !isFetching ? (
            <I18n
              t="tables.empty_suppliers"
              className={styles.emptyTableMessage}
              data-id="Suppliers:table-empty-info"
            />
          ) : (
            <SuppliersTable
              data={data}
              sorting={sorting}
              isFetching={isFetching}
              refresh={fetch}
              remove={remove}
              sort={sort}
            />
          )}
        </Section>
        <Section
          className={cx(styles.section, styles.pagination, {
            [styles.hiddenSection]: areProposalsEmpty,
          })}
        >
          <Pagination
            {...pagination}
            request={fetch}
            isFetching={isFetching}
            resource={Resources.SUPPLIERS}
          />
        </Section>
      </CardView>
    </div>
  );
};

Suppliers.propTypes = {
  fetchSuppliers: func.isRequired,
  remove: func.isRequired,
  sort: func.isRequired,
  pagination: shape({}).isRequired,
  data: arrayOf(shape({})).isRequired,
  sorting: shape({}).isRequired,
  isFetching: bool.isRequired,
  setQueryAction: func.isRequired,
  clearFilters: func.isRequired,
  filters: shape({}),
};

const mapStateToProps = (state) => ({
  isFetching: state.suppliers.isFetching,
  data: state.suppliers.data,
  pagination: state.suppliers.pagination,
  sorting: state.suppliers.sorting,
  filters: state.suppliers.filters,
});

export default connect(mapStateToProps, {
  fetchSuppliers: fetchSuppliersAction,
  remove: deleteSupplier,
  sort: sortSuppliers,
  setQueryAction: setQueryParam,
  clearFilters: clearFiltersAction,
})(Suppliers);
