import { isTest } from 'shared/utils/environment';

const prefilledRegistrationData = () => {
  if (isTest) {
    // We don't want to apply JSDOM to our every test, it slows tests down greatly
    return null;
  }
  const rootElement = document.getElementById('pbw-react-root');
  const getValue = (key) => rootElement.getAttribute(key);

  const registrationData = {
    email: getValue('data-email') || '',
    firstName: getValue('data-first-name') || '',
    lastName: getValue('data-last-name') || '',
    honorific: getValue('data-honorific') || '',
    companyName: getValue('data-company-name') || '',
    zipCode: getValue('data-zipcode') || '',
    city: getValue('data-city') || '',
    blz: getValue('data-blz') || '',
    isBankGroupCustomer: !!getValue('data-blz') || false,
  };

  return registrationData;
};

export default prefilledRegistrationData;
