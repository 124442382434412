import React, { memo, SVGAttributes } from 'react';

const Euro = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <text fill="currentColor" fontFamily="FrutigerVR-Roman, Frutiger VR" fontSize={24}>
        <tspan x="50%" y="50%" dominantBaseline="central" textAnchor="middle">
          {'\u20AC'}
        </tspan>
      </text>
    </svg>
  );
};

export default memo(Euro);
