import { differenceInCalendarDays, isBefore, isEqual } from 'date-fns';

import type { VrsoInstallment } from 'types/entities/Contract';

/**
 * Get index of the next installment that is going to be paid. If current date is
 * same as installment's due date, it's assumed that it's already paid.
 * @returns {number} next installment index
 */
export const getIdxToPay = (installments: VrsoInstallment[], currentDate: Date) => {
  const idx = installments.findIndex((installment) => isBefore(currentDate, installment.dueDate));

  return idx !== -1 ? idx : installments.length - 1;
};

export const calculateProgress = ({
  installments,
  currentDate,
  idxToPay,
}: {
  installments: VrsoInstallment[];
  currentDate: Date;
  idxToPay: number;
}) => {
  if (idxToPay === 0) {
    return 0;
  }

  for (const [index, installment] of installments.entries()) {
    if (isEqual(currentDate, installment.dueDate)) {
      return (index / (installments.length - 1)) * 100;
    } else if (isBefore(currentDate, installment.dueDate)) {
      const previousPaymentDate = installments[index - 1].dueDate;
      const startEndDiff = differenceInCalendarDays(installment.dueDate, previousPaymentDate);
      const startCurrentDiff = differenceInCalendarDays(currentDate, previousPaymentDate);
      const percentage = (startCurrentDiff / startEndDiff) * 100;
      const oneBar = (1 / (installments.length - 1)) * 100;
      const diff = (percentage / 100) * oneBar;

      return ((index - 1) / (installments.length - 1)) * 100 + diff;
    }
  }

  return 100;
};
