import { CATEGORY_DOCUMENT_LAYOUT } from 'constants/piwik';
import { piwikHelpers } from 'shared/utils/piwik';
import { InvoiceTemplate } from 'types/entities/InvoiceTemplate';

import {
  FontFamilyOptions,
  FontSizeOptions6To10,
  FontSizeOptions6To12,
  FontSizeOptions6To14,
  FontSizeOptions6To16,
  FontSizeOptions6To20,
  FontSizeOptions6To24,
  Options,
} from './constants';
import { FormData, Item } from './types';

export const trackEvent = piwikHelpers.trackEventCurried(CATEGORY_DOCUMENT_LAYOUT);

export const itemToString = (item: Item<string | number> | null) => (item ? item.label : '');

export const findOption = (options: Item<any>[], id: number | string) =>
  options.find((option) => option.value === id) || options[0];

export const getDefaultValues = (invoiceTemplate: InvoiceTemplate): Partial<FormData> => ({
  preset: invoiceTemplate.preset,
  headerVersion: findOption(Options, invoiceTemplate.headerVersion),
  tableVersion: findOption(Options, invoiceTemplate.tableVersion),
  footerVersion: findOption(Options, invoiceTemplate.footerVersion),
  fontFamily: findOption(FontFamilyOptions, invoiceTemplate.fontFamily),
  fontSizeAddress: findOption(FontSizeOptions6To20, invoiceTemplate.fontSizeAddress),
  fontSizeInvoiceInfo: findOption(FontSizeOptions6To10, invoiceTemplate.fontSizeInvoiceInfo),
  fontSizeSubject: findOption(FontSizeOptions6To24, invoiceTemplate.fontSizeSubject),
  fontSizeTableContent: findOption(FontSizeOptions6To16, invoiceTemplate.fontSizeTableContent),
  fontSizeTableHeaders: findOption(FontSizeOptions6To10, invoiceTemplate.fontSizeTableHeaders),
  fontSizeTableDescription: findOption(
    FontSizeOptions6To16,
    invoiceTemplate.fontSizeTableDescription
  ),
  fontSizeNotes: findOption(FontSizeOptions6To14, invoiceTemplate.fontSizeNotes),
  fontSizeFooter: findOption(FontSizeOptions6To12, invoiceTemplate.fontSizeFooter),
  colouredElementsHex: invoiceTemplate.colouredElementsHex,
  invertColouredElementsTextColor: invoiceTemplate.invertColouredElementsTextColor,
});
