import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { flow } from 'lodash';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { resetClientDefaults, updateClientDefaults } from 'actions/default-values';
import { ClientEmailsFormName } from 'containers/Clients/ClientEmailDefaults/ClientEmailDefaults';
import {
  DefaultValuesForm,
  FormFooterContainer,
  FormRow,
} from 'containers/Profile/InvoiceTemplate/DefaultValues/sharedComponents';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { RootState } from 'store';
import { DefaultValues } from 'types/entities/DefaultValues';
import { InfoTextField, TextareaField } from 'components/Form';
import InputsGroup from 'components/InputsGroup/InputsGroup';

import styles from './OrderConfirmationEmailsDefaults.module.css';

const FORM_NAME: ClientEmailsFormName = 'ClientOrderConfirmationEmailsDefaults';

type FormData = Pick<
  DefaultValues,
  | 'emailOrderConfirmationSubject'
  | 'emailOrderConfirmationSalutation'
  | 'emailOrderConfirmationSalutationContent'
  | 'emailOrderConfirmationSignature'
>;

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    emailOrderConfirmationSubject: state.defaultValues.values.emailOrderConfirmationSubject,
    emailOrderConfirmationSalutation: state.defaultValues.values.emailOrderConfirmationSalutation,
    emailOrderConfirmationSalutationContent:
      state.defaultValues.values.emailOrderConfirmationSalutationContent,
    emailOrderConfirmationSignature: state.defaultValues.values.emailOrderConfirmationSignature,
  },
});

const mapDispatchToProps = {
  updateClientDefaults,
  resetClientDefaults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OrderConfirmationEmailsDefaultsProps = ConnectedProps<typeof connector> & {
  clientId: string;
};

const OrderConfirmationEmailsDefaults = ({
  resetClientDefaults,
  updateClientDefaults,
  handleSubmit,
  clientId,
  reset: resetForm,
}: OrderConfirmationEmailsDefaultsProps &
  InjectedFormProps<FormData, OrderConfirmationEmailsDefaultsProps>) => {
  return (
    <DefaultValuesForm
      onSubmit={handleSubmit((values) =>
        updateClientDefaults(clientId, values, 'email_order_confirmation')
      )}
      onReset={async () => {
        await resetClientDefaults(clientId, 'email_order_confirmation');
        resetForm();
      }}
      sectionName="email_order_confirmation"
      sectionLabel={t('invoice_templates.emails_defaults.order_confirmation_defaults_section')}
      dataIdPrefix="ClientDocumentDefaults:"
    >
      <>
        <FormRow>
          <InputsGroup label={t('invoice_templates.emails_defaults.subject')}>
            <Field
              disabled
              className={styles.grouppedInput}
              name="emailOrderConfirmationSubject"
              checker={serverValidationChecker}
              component={InfoTextField}
              message={t('invoice_templates.emails_defaults.subject_info')}
              dataId="ClientEmailDefaults:input-order-confirmation-subject"
              isDisabledWithUnderline
            />
          </InputsGroup>
        </FormRow>

        <FormRow>
          <InputsGroup label={t('invoice_templates.emails_defaults.header_message')}>
            <Field
              name="emailOrderConfirmationSalutation"
              component={InfoTextField}
              checker={serverValidationChecker}
              label={t('invoice_templates.emails_defaults.salutation')}
              message={t('invoice_templates.emails_defaults.salutation_info')}
              dataId="ClientEmailDefaults:input-order-confirmation-salutation"
            />
            <Field
              name="emailOrderConfirmationSalutationContent"
              component={TextareaField}
              checker={serverValidationChecker}
              label={t('invoice_templates.emails_defaults.salutation_content')}
              fullHeight
              dataId="ClientEmailDefaults:input-order-confirmation-salutation-content"
            />
          </InputsGroup>
        </FormRow>
        <FormRow>
          <FormFooterContainer>
            <InputsGroup label={t('invoice_templates.emails_defaults.signature')}>
              <Field
                disabled
                className={styles.grouppedInput}
                name="emailOrderConfirmationSignature"
                checker={serverValidationChecker}
                component={InfoTextField}
                message={t('invoice_templates.emails_defaults.signature_info')}
                dataId="ClientEmailDefaults:input-order-confirmation-signature"
                isDisabledWithUnderline
              />
            </InputsGroup>
          </FormFooterContainer>
        </FormRow>
      </>
    </DefaultValuesForm>
  );
};

export default flow(
  withTransitionPrevent(),
  reduxForm<FormData, OrderConfirmationEmailsDefaultsProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    persistentSubmitErrors: true,
  }),
  connector
)(OrderConfirmationEmailsDefaults);
