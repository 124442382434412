export const UPLOAD_REQUEST = 'INCOMING_INVOICE_UPLOADED_FILES/UPLOAD_REQUEST';
export const UPLOAD_SUCCESS = 'INCOMING_INVOICE_UPLOADED_FILES/UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'INCOMING_INVOICE_UPLOADED_FILES/UPLOAD_FAILURE';

export const DELETE_REQUEST = 'INCOMING_INVOICE_UPLOADED_FILES/DELETE_REQUEST';
export const DELETE_SUCCESS = 'INCOMING_INVOICE_UPLOADED_FILES/DELETE_SUCCESS';
export const DELETE_FAILURE = 'INCOMING_INVOICE_UPLOADED_FILES/DELETE_FAILURE';

export const DOWNLOAD_REQUEST = 'INCOMING_INVOICE_UPLOADED_FILES/DOWNLOAD_REQUEST';
export const DOWNLOAD_SUCCESS = 'INCOMING_INVOICE_UPLOADED_FILES/DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'INCOMING_INVOICE_UPLOADED_FILES/DOWNLOAD_FAILURE';
