import React, { memo } from 'react';

import { t } from 'shared/utils';

import { AscIndicator, DescIndicator, Wrapper } from './SortingIndicator.styled';

export type SortingIndicatorProps = {
  direction?: 'asc' | 'desc';
};

export const SortingIndicator = ({ direction }: SortingIndicatorProps) => {
  return (
    <Wrapper title={t('tables.toggle_sorting')} data-id="SortingIndicator">
      <AscIndicator isActive={direction === 'asc'} data-id="ascending" />
      <DescIndicator isActive={direction === 'desc'} data-id="descending" />
    </Wrapper>
  );
};

export default memo(SortingIndicator);
