import React, { Component } from 'react';
import { Portal } from 'react-portal';
import { connect } from 'react-redux';
import { arrayOf, bool, func, number, shape } from 'prop-types';
import { compose } from 'recompose';

import {
  deleteMessage as deleteMessageAction,
  fetchCustomerMessages as fetchCustomerMessagesAction,
  setActiveMessage as setActiveMessageAction,
} from 'actions/message-inbox';
import { MessageStatuses } from 'constants/statuses';
import {
  messageInboxActiveMessageIdSelector,
  messageInboxMessagesSelector,
  messageInboxMessagesTotalSelector,
  messageInboxReadMessagesSelector,
  messageInboxUnreadMessagesSelector,
  messageInboxUnreadMessagesTotalSelector,
} from 'selectors/messageInbox';
import withViewport from 'shared/hoc/withViewport';

import LoadMoreMessages from './LoadMoreMessages/LoadMoreMessages';
import MessageDetails from './MessageDetails/MessageDetails';
import MessagesSubsection from './MessagesSubsection/MessagesSubsection';
import NoMessagesInfo from './NoMessagesInfo/NoMessagesInfo';

import styles from './Messages.module.css';

const MORE_MESSAGES_INT = 4;

class Messages extends Component {
  state = {
    shownMessages: 4,
  };

  loadMoreMessages = () => {
    this.setState((prevState) => ({
      shownMessages: prevState.shownMessages + MORE_MESSAGES_INT,
    }));
  };

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (prevProps.isActive !== props.isActive) {
      this.props.setActiveMessage();
    }
  }

  render() {
    const {
      messagesTotal,
      unreadMessagesTotal,
      messages,
      activeMessageId,
      setActiveMessage,
      deleteMessage,
      fetchCustomerMessages,
    } = this.props;
    const { shownMessages } = this.state;
    const hasMore = shownMessages < messagesTotal;
    const notificationsNode = document.getElementById('notifications');
    const readMessagesTotal = messagesTotal - unreadMessagesTotal;
    const unreadMessages = messages.filter((message) => message.markedAsRead === false);
    const readMessages = messages.filter((message) => message.markedAsRead === true);
    const messagesSortedByMarkedAsRead = [...unreadMessages, ...readMessages];
    const messagesToShow = messagesSortedByMarkedAsRead.slice(0, shownMessages);
    const unreadMessagesToShow = messagesToShow.filter((message) => message.markedAsRead === false);
    const readMessagesToShow = messagesToShow.filter((message) => message.markedAsRead === true);
    const shouldShowUnreadSection =
      Boolean(unreadMessagesTotal) && Boolean(unreadMessagesToShow.length);
    const shouldShowReadSection = Boolean(readMessagesTotal) && Boolean(readMessagesToShow.length);
    if (!messagesTotal) return <NoMessagesInfo />;

    return (
      <>
        <div className={styles.container}>
          {shouldShowUnreadSection && (
            <MessagesSubsection
              status={MessageStatuses.UNREAD}
              messages={unreadMessagesToShow}
              activeMessageId={activeMessageId}
            />
          )}

          {shouldShowReadSection && (
            <MessagesSubsection
              status={MessageStatuses.READ}
              messages={readMessagesToShow}
              activeMessageId={activeMessageId}
            />
          )}
          {hasMore && <LoadMoreMessages onClick={this.loadMoreMessages} />}
        </div>

        {activeMessageId && (
          <Portal node={document && notificationsNode}>
            <MessageDetails
              handleClose={setActiveMessage} // when called with no parameters sets activeMessageId to null, which results in closing the details view
              deleteMessage={deleteMessage}
              activeMessageId={activeMessageId}
              fetchCustomerMessages={fetchCustomerMessages}
              messages={messages}
            />
          </Portal>
        )}
      </>
    );
  }
}

Messages.propTypes = {
  messages: arrayOf(shape({})),
  messagesTotal: number.isRequired,
  unreadMessagesTotal: number.isRequired,
  setActiveMessage: func.isRequired,
  activeMessageId: number,
  deleteMessage: func.isRequired,
  fetchCustomerMessages: func.isRequired,
  isActive: bool.isRequired,
};

const mapStateToProps = (state) => ({
  messages: messageInboxMessagesSelector(state),
  unreadMessages: messageInboxUnreadMessagesSelector(state),
  readMessages: messageInboxReadMessagesSelector(state),
  messagesTotal: messageInboxMessagesTotalSelector(state),
  unreadMessagesTotal: messageInboxUnreadMessagesTotalSelector(state),
  activeMessageId: messageInboxActiveMessageIdSelector(state),
});

const mapDispatchToProps = {
  setActiveMessage: setActiveMessageAction,
  deleteMessage: deleteMessageAction,
  fetchCustomerMessages: fetchCustomerMessagesAction,
};

export default compose(withViewport, connect(mapStateToProps, mapDispatchToProps))(Messages);
