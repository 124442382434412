import normalize from 'json-api-normalizer';
import { get, head } from 'lodash';
import moment from 'moment';
import { combineReducers } from 'redux';

import { VALIDATE_SUCCESS as VALIDATE_PROFILE_SUCCESS } from 'constants/auth';
import { INDEX_SUCCESS } from 'constants/common/resource';
import { DATE_FORMAT } from 'constants/datetime';
import { FETCH_SUCCESS } from 'constants/profile';
import { QUARTERLY_SUMMARY, UPDATE_THRESHOLD_SUCCESS } from 'constants/quarterly-summary';
import { getResourceReducer } from 'reducers/common/resource';
import { createFilteredReducer } from 'shared/utils';
import { camelizeKeysDeep } from 'shared/utils/entity-mapper';

const initialState = {
  past: [],
  present: [],
  future: [],
  all: [],
  startingWeek: null,
  weeksInYear: null,
};

function data(state = initialState, { type, rawResponse = {} } = {}) {
  if (type === INDEX_SUCCESS) {
    const response = camelizeKeysDeep(rawResponse);
    const dailyRecords = response.dailyRecords;

    if (!dailyRecords.length) {
      return initialState;
    }

    const startDate = moment(response.startDate, DATE_FORMAT);
    const endDate = moment(response.endDate, DATE_FORMAT);
    const todayDate = moment(response.today, DATE_FORMAT);
    const allDays = [];
    const days = [];

    for (const m = startDate; m.isBefore(endDate); m.add(1, 'days')) {
      days.push(m.format(DATE_FORMAT));
    }

    let totalSum = 0;
    days.forEach((date) => {
      const dailyRecord = dailyRecords.find(({ day }) => day === date);

      if (dailyRecord) {
        allDays.push(dailyRecord);
        totalSum = dailyRecord.totalSum;
      } else {
        allDays.push({
          day: date,
          totalSum: totalSum,
          contractsExpensesAmount: 0,
          contractsRevenuesAmount: 0,
          invoicesExpensesAmount: 0,
          invoicesRevenuesAmount: 0,
          period: moment(date, DATE_FORMAT).isBefore(todayDate) ? 'past' : 'future',
          totalExpensesAmount: 0,
          totalExpensesTaxes: 0,
          totalRevenuesAmount: 0,
          totalRevenuesTaxes: 0,
          totalTaxes: 0,
        });
      }
    });

    const limitedDays = allDays.map((object, index) => ({ ...object, index }));

    const startingDay = moment(head(limitedDays).day, DATE_FORMAT);
    const startingWeek = startingDay.isoWeek();
    const weeksInYear = startingDay.isoWeeksInYear();

    const past = limitedDays.filter((day) => day.period === 'past');
    const future = limitedDays.filter((day) => day.period === 'future');
    const today = limitedDays.filter((day) => day.period === 'today');
    const nextTaxationDate = response.nextTaxationDate;
    const { totalTaxes, totalRevenuesTaxes, totalExpensesTaxes } = dailyRecords.find(
      ({ day }) => day === nextTaxationDate
    );

    return {
      past: past,
      present: today,
      future: future,
      all: limitedDays,
      startingWeek,
      weeksInYear,
      nextTaxationDate,
      totalTaxes,
      totalRevenuesTaxes,
      totalExpensesTaxes,
    };
  }
  return state;
}

const getDangerValueFromResponse = (response) => {
  const normalizedResponse = normalize(response);
  const key = head(Object.keys(get(normalizedResponse, 'accountSettings', {})));
  return get(
    normalizedResponse,
    `accountSettings[${key}].attributes.liquidityDangerThreshold`,
    null
  );
};

function liquidityDangerThreshold(state = null, action) {
  if ([VALIDATE_PROFILE_SUCCESS, FETCH_SUCCESS, UPDATE_THRESHOLD_SUCCESS].includes(action.type)) {
    return getDangerValueFromResponse(action.response);
  }
  return state;
}

export default combineReducers({
  chartData: createFilteredReducer(
    getResourceReducer({
      data,
      liquidityDangerThreshold,
    }),
    (action) => action.name === QUARTERLY_SUMMARY
  ),
  liquidityDangerThreshold,
});
