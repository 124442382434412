import React, { Component } from 'react';
import { connect } from 'react-redux';
import normalize from 'json-api-normalizer';
import { bool, func, shape } from 'prop-types';
import build from 'redux-object';

import { flipMonthlyOverviewBack as flipMonthlyOverviewBackAction } from 'actions/monthly-overview/piwik';
import { getContracts } from 'api/me/contracts';
import EntityPath from 'constants/entitiesPaths';
import {
  ACTION_OVERVIEW_REDIRECT_TO_EXPENSES,
  ACTION_OVERVIEW_REDIRECT_TO_REVENUE,
  CATEGORY_DASHBOARD,
} from 'constants/piwik';
import { EXPENSES, REVENUES } from 'constants/tables';
import paths from 'routes/paths';
import withLoader from 'shared/hoc/withLoader';
import { t } from 'shared/utils';
import { camelizeKeysDeep } from 'shared/utils/entity-mapper';
import { piwikHelpers } from 'shared/utils/piwik';
import MonthlyOverview from 'components/EmptyEntryPage/images/MonthlyOverview';
import Tabs from 'components/Tabs';
import TileTitle from 'redesign/components/atoms/TileTitle/TileTitle';
import TileEmpty from 'redesign/components/molecules/TileEmpty/TileEmpty';

import FlipCard, { Side } from '../components/FlipCard/FlipCard';
import BarChart from './Chart';
import Table from './Table';

import styles from './Overview.module.css';

const trackRedirectionToRevenue = () =>
  piwikHelpers.trackEvent(CATEGORY_DASHBOARD, ACTION_OVERVIEW_REDIRECT_TO_REVENUE);
const trackRedirectionToExpenses = () =>
  piwikHelpers.trackEvent(CATEGORY_DASHBOARD, ACTION_OVERVIEW_REDIRECT_TO_EXPENSES);

const TabsWithLoader = withLoader(Tabs);
const ChartWithLoader = withLoader(BarChart);

class Overview extends Component {
  state = {
    table: false,
    hasContracts: false,
  };

  componentDidMount() {
    this.setHasContracts();
  }

  async setHasContracts() {
    const response = await getContracts();
    const contracts =
      build(normalize(response.data), EntityPath.Contracts, null, {
        ignoreLinks: true,
      }) || [];

    this.setState({ hasContracts: contracts.length > 0 });
  }

  toggleView = () => {
    this.setState({ table: !this.state.table }, () => {
      if (this.state.table) this.props.flipMonthlyOverviewBack();
    });
  };

  render() {
    const {
      isFetching,
      data,
      areAnyNotDraftOutgoingInvoicesPresent,
      areAnyNotDraftIncomingInvoicesPresent,
      className,
    } = this.props;

    const { table, hasContracts } = this.state;
    const tableSections = [
      {
        heading: t('menu.revenue'),
        component: Table,
        data,
        monthsRange: 5,
        type: REVENUES,
      },
      {
        heading: t('menu.expenses'),
        component: Table,
        data,
        monthsRange: 5,
        type: EXPENSES,
      },
    ];
    const tabsTheme = {
      activeTab: styles.activeTab,
      inactiveTab: styles.inactiveTab,
      tabs: styles.tabs,
      primary: styles.primary,
      tab: styles.tab,
      main: styles.main,
      content: styles.content,
    };

    if (
      (!areAnyNotDraftOutgoingInvoicesPresent &&
        !areAnyNotDraftIncomingInvoicesPresent &&
        !hasContracts) ||
      isFetching
    ) {
      return (
        <TileEmpty
          dataId="MonthlyOverview:EmptyStateTile-wrapper"
          image={<MonthlyOverview height={135} />}
          title={t('dashboard.revenue_cost.header')}
          caption={t('dashboard.revenue_cost.empty_state.header')}
          info={t('dashboard.revenue_cost.empty_state.info')}
          isLoading={isFetching}
          buttons={[
            {
              dataId: 'MonthlyOverview:EmptyStateTile-buttonRevenue',
              text: t('dashboard.revenue_cost.empty_state.button_1'),
              to: paths.outgoingInvoices,
              action: trackRedirectionToRevenue,
            },
            {
              dataId: 'MonthlyOverview:EmptyStateTile-buttonExpenses',
              text: t('dashboard.revenue_cost.empty_state.button_2'),
              to: paths.incomingInvoices,
              action: trackRedirectionToExpenses,
            },
          ]}
        />
      );
    }

    return (
      <FlipCard dataId="MonthlyOverview:RealDataTile-wrapper" className={className}>
        <Side
          toggleView={this.toggleView}
          isFetching={isFetching}
          front
          active={table}
          header={<TileTitle>{t('dashboard.revenue_cost.header')}</TileTitle>}
        >
          <ChartWithLoader
            dataId="MonthlyOverview:RealDataTile-chart"
            isFetching={isFetching}
            data={data}
            monthsRange={5}
          />
        </Side>
        <Side
          toggleView={this.toggleView}
          isFetching={isFetching}
          back
          active={table}
          header={<TileTitle>{t('dashboard.revenue_cost.header')}</TileTitle>}
        >
          <TabsWithLoader
            dataId="MonthlyOverview:RealDataTile-tabs"
            isFetching={isFetching}
            sections={tableSections}
            theme={tabsTheme}
          />
        </Side>
      </FlipCard>
    );
  }
}

Overview.propTypes = {
  isFetching: bool.isRequired,
  data: shape({}).isRequired,
  flipMonthlyOverviewBack: func.isRequired,
  areAnyNotDraftOutgoingInvoicesPresent: bool,
  areAnyNotDraftIncomingInvoicesPresent: bool,
};

const mapStateToProps = (state) => {
  const { quickfilterCounters: outgoingInvoicesStatuses = {} } = camelizeKeysDeep(
    state.outgoingInvoices.meta || {}
  );
  const { invoicesCounters: incomingInvoicesStatuses = {} } = camelizeKeysDeep(
    state.incomingInvoices || {}
  );

  return {
    isFetching: state.monthlyOverview.isFetching,
    data: state.monthlyOverview.revenueExpenses,
    areAnyNotDraftOutgoingInvoicesPresent:
      !state.outgoingInvoices.isFetching &&
      (outgoingInvoicesStatuses.cancelled ||
        outgoingInvoicesStatuses.open ||
        outgoingInvoicesStatuses.paid ||
        outgoingInvoicesStatuses.overdue ||
        outgoingInvoicesStatuses.partlyPaid ||
        outgoingInvoicesStatuses.paymentReminded),
    areAnyNotDraftIncomingInvoicesPresent:
      !state.incomingInvoices.isFetching &&
      (incomingInvoicesStatuses.open ||
        incomingInvoicesStatuses.paid ||
        incomingInvoicesStatuses.overdue ||
        incomingInvoicesStatuses.partlyPaid ||
        incomingInvoicesStatuses.internalSubscriptionFee),
  };
};

const mapDispatchToProps = {
  flipMonthlyOverviewBack: flipMonthlyOverviewBackAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
