import { CategoryResponse } from 'types/entities/Category';
import { Client } from 'types/entities/Client';
import { ContractSource } from 'types/entities/Contract';
import { Supplier } from 'types/entities/Supplier';

export type FormData = {
  subject: string;
  contract_number: string;
  transient: boolean;
  occurrences: number;
  source: ContractSource;
  total_amount: string;
  recurring_transaction_interval_id: number;
  installments: {
    transaction_date: string;
    transaction_end_date: string;
    net_amount: number;
    supplier_id: number;
    tax_rate_id: number;
  };
  invoiceLineCategoryId: CategoryResponse;
  client?: Client;
  supplier?: Supplier;
  taxRateId?: number;
};

export enum Status {
  Active = 'active',
  Finished = 'finished',
}
