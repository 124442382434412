import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PaidByCash from '../PaidByCash/';
import PaidByUnspecified from '../PaidByUnspecified/';
import BankTransferOption from './Option';

const BankTransferMenu = ({ option, whichFolder, onClick }) => {
  if (option.paidByCash) {
    return <PaidByCash {...option} handleOptionSelect={onClick} whichFolder={whichFolder} />;
  }
  if (option.paidByUnspecified) {
    return <PaidByUnspecified {...option} handleOptionSelect={onClick} whichFolder={whichFolder} />;
  }

  return <BankTransferOption {...option} handleOptionSelect={onClick} whichFolder={whichFolder} />;
};

BankTransferMenu.propTypes = {
  onClick: PropTypes.func,
  option: PropTypes.shape({}).isRequired,
  whichFolder: PropTypes.string.isRequired,
};

export default connect(null, null, (stateProps, _, ownProps) => ({
  ...ownProps,
  ...stateProps,
}))(BankTransferMenu);
