import { formValueSelector as formValueSelectorBuilder } from 'redux-form';

import { RootState } from 'store';

import { FORM_NAME } from './constants';

const formValueSelector = formValueSelectorBuilder<RootState>(FORM_NAME);

export const getContractsSupplier = (state: RootState) => formValueSelector(state, 'supplier');
export const getContractsClient = (state: RootState) => formValueSelector(state, 'client');
