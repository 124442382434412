import { useEffect } from 'react';

const HubSpotScript = () => {
  const applyStylesToIframe = () => {
    const iframe = document.getElementById('hubspot-messages-iframe-container');

    if (iframe) {
      // Apply styles to the iframe
      iframe.style.marginLeft = '43px';
      // Make the iframe visible
      iframe.style.display = 'block';
      // Add more styles if needed
    } else {
      // If the iframe is not yet available, schedule another check
      requestAnimationFrame(applyStylesToIframe);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js-eu1.hs-scripts.com/139695684.js';

    script.onload = () => {
      // Use requestAnimationFrame to continuously check for the iframe
      requestAnimationFrame(applyStylesToIframe);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // or a placeholder if needed
};

export default HubSpotScript;
