import normalize from 'json-api-normalizer';
import moment from 'moment';
import { actionTypes } from 'redux-form';
import build from 'redux-object';

import {
  CREATE_SUCCESS as BANK_ACTIVITY_CREATE_SUCCESS,
  DELETE_SUCCESS as BANK_ACTIVITY_DELETE_SUCCESS,
} from 'constants/bank-transfer-connections';
import { DATE_FORMAT } from 'constants/datetime';
import EntityPath from 'constants/entitiesPaths';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_SUMS_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/incoming-invoice';
import {
  DELETE_SUCCESS as UPLOADED_FILES_DELETE_SUCCESS,
  UPLOAD_SUCCESS as UPLOADED_FILES_UPLOAD_SUCCESS,
} from 'constants/incoming-invoice/uploaded-files';
import { extend, t } from 'shared/utils';

const defaultState = {
  initial: {
    dueDate: moment().format(DATE_FORMAT),
    placeOfOrigin: t('expenses.form.inland'),
  },
  error: false,
  isFetching: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
    case CREATE_REQUEST:
    case UPDATE_REQUEST:
    case DELETE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_SUCCESS:
    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
    case DELETE_SUCCESS: {
      const invoice = build(
        normalize(action.response),
        EntityPath.IncomingInvoices,
        action.response.data.id
      );
      const data = action.response.data.attributes;

      return {
        ...state,
        initial: {
          ...extend(state.initial, data),
          supplier: invoice && invoice.supplier,
        },
        values: {
          ...state.values,
          paid: data.paid,
        },
        isFetching: false,
      };
    }

    case FETCH_FAILURE:
    case CREATE_FAILURE:
    case UPDATE_FAILURE:
    case DELETE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    case FETCH_SUMS_SUCCESS:
      return {
        ...state,
        values: {
          ...state.values,
          lineItemsEdited: true,
        },
      };

    case BANK_ACTIVITY_CREATE_SUCCESS:
    case BANK_ACTIVITY_DELETE_SUCCESS:
      return {
        ...state,
        values: {
          ...state.values,
          bankActivityChanged: true,
        },
      };

    case UPLOADED_FILES_UPLOAD_SUCCESS:
    case UPLOADED_FILES_DELETE_SUCCESS:
      return {
        ...state,
        values: {
          ...state.values,
          uploadedFilesChanged: true,
        },
      };

    case actionTypes.CHANGE: {
      if (action.meta.form.includes('invoiceCreator') && action.meta.field === 'paidByCash') {
        if (action.payload) {
          return {
            ...state,
            values: {
              ...state.values,
              paidByCashDate: moment().format(DATE_FORMAT),
            },
          };
        }

        return {
          ...state,
          values: {
            ...state.values,
            paidByCashDate: null,
          },
        };
      }
      return state;
    }

    default:
      return state;
  }
};
