/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import cx from 'classnames';
import { camelCase, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import { compose, lifecycle } from 'recompose';

import withViewport from 'shared/hoc/withViewport';
import styles from 'shared/styles/table.module.css';
import { noop } from 'shared/utils';
import If from 'components/Conditions/If';
import I18n from 'components/I18n';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import { SortingIndicator } from 'components/Table';

const getThStyles = ({ isLgUp, width }) =>
  pickBy(
    {
      width: isLgUp && width,
    },
    (i) => i
  );

const TableHeader = ({
  column,
  columnWidthClass = null,
  currentSorting,
  info = '',
  dataId = `Table:header-${column}`,
  section,
  sort,
  sortable,
  align,
  children,
  isLgUp,
  width = null,
  customClassName = '',
}) => (
  <th
    style={getThStyles({ isLgUp, width })}
    onClick={() => sortable && sort(column)}
    dataId={dataId}
    className={cx(
      {
        [styles.sortable]: sortable,
        [columnWidthClass]: !!columnWidthClass,
      },
      customClassName
    )}
  >
    <div
      className={cx(styles.columnHeader, {
        [`${styles[align]}`]: align,
      })}
    >
      <div className={styles.hoverContainer} data-id={`Table:cell-${column}`}>
        {children || <I18n t={`${section}.table.columns.${column}`} />}
        <If ok={sortable}>
          <SortingIndicator
            direction={
              currentSorting && camelCase(currentSorting.column) === camelCase(column)
                ? currentSorting.direction
                : null
            }
          />
        </If>
        <If ok={Boolean(info)}>
          <InfoIcon align="left" className={styles.infoIcon} text={info} />
        </If>
      </div>
    </div>
  </th>
);

TableHeader.defaultProps = {
  section: 'suppliers',
  sort: noop,
  sortable: false,
  info: '',
  column: '',
};

TableHeader.propTypes = {
  column: PropTypes.string,
  columnWidthClass: PropTypes.string,
  currentSorting: PropTypes.shape({
    column: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
  }),
  info: PropTypes.string,
  children: PropTypes.node,
  section: PropTypes.string,
  sort: PropTypes.func,
  sortable: PropTypes.bool,
  align: PropTypes.string,
  width: PropTypes.string,
  isLgUp: PropTypes.bool,
  customClassName: PropTypes.string,
  dataId: PropTypes.string,
};

const enhance = compose(
  withViewport,
  lifecycle({
    componentDidMount() {
      const { setHeader = noop, index, children } = this.props;
      setHeader((value) => ({ ...value, [index]: children }));
    },
  })
);

export default enhance(TableHeader);
