import React, { FunctionComponent } from 'react';

import styles from './TableOfExamples.module.css';

export type DataShape = {
  expression: string;
  result: string;
  annotation: string;
};

interface TableOfExamplesProps {
  header: DataShape;
  data: DataShape[];
}

const TableOfExamples: FunctionComponent<TableOfExamplesProps> = ({
  header: { expression, result, annotation },
  data,
}) => (
  <table className={styles.table}>
    <thead>
      <tr>
        <th>{expression}</th>
        <th>{result}</th>
        <th>{annotation}</th>
      </tr>
    </thead>
    <tbody>
      {data.map((e) => (
        <tr key={e.expression}>
          <td>
            <div className={styles.cell}>
              <span className={styles.cellHeader}>{expression}</span>
              <div className={styles.cellContent}>{e.expression}</div>
            </div>
          </td>
          <td>
            <div className={styles.cell}>
              <span className={styles.cellHeader}>{result}</span>
              <div className={styles.cellContent}>{e.result}</div>
            </div>
          </td>
          <td>
            <div className={styles.cell}>
              <span className={styles.cellHeader}>{annotation}</span>
              <div className={styles.cellContent}>{e.annotation}</div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default TableOfExamples;
