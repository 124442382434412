import React, { useState } from 'react';
import Textarea from 'react-textarea-autosize';
import cx from 'classnames';

import { noop } from 'shared/utils';
import InfoIcon from 'components/InfoIcon/InfoIcon';

import { Counter } from './TextAreaField.styled';
import { TextareaFieldProps, TextAreaRef } from './TextareaField.types';

import styles from './TextareaField.module.css';

const TextareaField = ({
  checker = noop,
  className,
  disabled,
  input = {},
  placeholder,
  fullHeight = false,
  minHeight = 120,
  meta,
  dataId = '',
  label,
  name,
  isPlaceholderVisible = true,
  required,
  maxCharacters,
  message,
  tooltipPlacement,
  ...rest
}: TextareaFieldProps) => {
  let textareaRef: TextAreaRef;
  const inputLength = input.value?.length;

  const handleContainerClick = () => {
    if (!textareaRef) return;

    const { _rootDOMNode: texareaNode } = textareaRef;
    if (texareaNode && fullHeight) {
      texareaNode.focus();
    }
  };

  const [height, setHeight] = useState(minHeight);

  const handleHeightChange = (newHeight: number) => {
    if (newHeight > minHeight) setHeight(newHeight);
  };

  return (
    <div
      className={cx(styles.container, className)}
      onClick={handleContainerClick}
      role="presentation"
    >
      {label && (
        <>
          <label
            htmlFor={name}
            className={cx(styles.label, {
              [styles.textAreaInvalid]: checker({ meta }),
            })}
          >
            {label} {!!required && '*'}
            {!!maxCharacters && (
              <>
                {' '}
                <Counter isCapped={inputLength >= maxCharacters}>{`${
                  inputLength ?? 0
                }/${maxCharacters}`}</Counter>
              </>
            )}
            {/* @ts-ignore InfoIcon is not written in ts */}
            {message && (
              <InfoIcon
                text={message}
                className={styles.tooltip}
                tooltipPlacement={tooltipPlacement}
              />
            )}
          </label>
        </>
      )}
      <Textarea
        {...input}
        {...rest}
        data-id={dataId}
        style={{ minHeight: height }}
        className={cx(styles.textArea, {
          [styles.textAreaInvalid]: checker({ meta }),
        })}
        disabled={disabled}
        placeholder={isPlaceholderVisible ? placeholder : ''}
        inputRef={(ref: TextAreaRef) => {
          textareaRef = ref;
        }}
        onHeightChange={handleHeightChange}
      />
      <div className={styles.underline} />
    </div>
  );
};

export default TextareaField;
