import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';

import { OBS_OPEN } from 'constants/piwik';
import { isOBSEnabled, liquidityCalculatorEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import IconFinancialPlanning from 'redesign/components/atoms/Icons/FinancialPlanning';

import { trackEvent, trackEventHandler } from '../utils';
import { Arrow, Link, MenuItem } from './Shared.styled';
import { getMatches } from './utils';

type FinancialPlanningProps = {
  pathname: string;
  isNavOpen: boolean;
};

const URLS = {
  liquidityCalculator: paths.liquidityCalculator,
  obs: paths.obs,
};

const FinancialPlanning = ({ pathname, isNavOpen }: FinancialPlanningProps) => {
  const matches = getMatches(pathname, URLS);
  const isActive = Object.values(matches).some((match) => match);
  const [isOpen, setOpen] = useState(isActive);
  const isLiquidityCalculatorEnabled = useSelector(liquidityCalculatorEnabledHelper);
  const isObsEnabled = useSelector(isOBSEnabled);
  const handleToggle = useCallback(() => {
    trackEvent(`financialplanning_${!isOpen ? 'open' : 'close'}`);
    setOpen((isOpen) => !isOpen);
  }, [isOpen]);

  useUpdateEffect(() => {
    setOpen(isActive);
  }, [pathname, isNavOpen]);

  if (!isLiquidityCalculatorEnabled && !isObsEnabled) {
    return null;
  }

  return (
    <MenuItem $isActive={isActive} $isOpen={isOpen}>
      <Link
        $level={1}
        as="span"
        $isActive={isActive}
        onClick={handleToggle}
        data-id="financial-planning"
      >
        <IconFinancialPlanning />
        {t('redesign.organisms.navigation.financial_planning')}
        <Arrow $isOpen={isOpen} />
      </Link>

      <ul>
        {isLiquidityCalculatorEnabled && (
          <li>
            <Link
              $level={2}
              to={paths.liquidityCalculator}
              $isActive={matches.liquidityCalculator}
              data-id="liquidity-calculator"
              onClick={trackEventHandler('financialplanning_liquidity-calculator')}
            >
              {t('redesign.organisms.navigation.liquidity_calculator')}
            </Link>
          </li>
        )}
        {isObsEnabled && (
          <li>
            <Link
              $level={2}
              to={paths.obs}
              $isActive={matches.obs}
              data-id="obs"
              onClick={trackEventHandler(OBS_OPEN)}
            >
              {t('redesign.organisms.navigation.obs')}
            </Link>
          </li>
        )}
      </ul>
    </MenuItem>
  );
};

export default memo(FinancialPlanning);
