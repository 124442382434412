import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { arrayOf, func, shape, string } from 'prop-types';

import { createDirectDebit as createDirectDebitAction } from 'actions/direct-debit';
import { showNotification as showNotificationAction } from 'actions/notification';
import { fetchUserPlan as fetchUserPlanAction } from 'actions/payment-plan';
import { DATE_FORMAT } from 'constants/datetime';
import { directDebitSuccess } from 'notifications/payment-plans';
import { directDebitSelector } from 'selectors/direct-debit';
import { t } from 'shared/utils';
import { getDirectDebitAccount } from 'shared/utils/direct-debit-mandate';
import { getDeletionDate, isNextPlanPresent, nextPlanDate } from 'shared/utils/payment-plans-dates';
import Section from 'components/Form/Section/Section';

import AccountDeletion from './components/AccountDeletion/AccountDeletion';
import DebitData from './components/DebitData/DebitData';
import ExportModal from './components/ExportModal/ExportModal';
import NewDirectDebitModal from './components/NewDirectDebitModal/NewDirectDebitModal';
import NextPlanDate from './components/NextPlanDate/NextPlanDate';

import styles from '../../DebitContainer.module.css';

class DebitDescription extends Component {
  defaultState = {
    isModalOpen: false,
    isExportModalOpen: false,
    agreeCheckbox: false,
    isCreationInProgress: false,
    directDebitAccount: getDirectDebitAccount(
      this.props.paymentsBankAccounts,
      this.props.debitData.iban
    ),
  };

  state = this.defaultState;

  get getModalsDebitData() {
    const {
      credentials: { mandateReference },
      creditorIdentificationNumber,
    } = this.props;
    const { directDebitAccount: { bankName, bic, iban } = {} } = this.state;

    return {
      name: bankName,
      bic,
      iban,
      direct_debit_mandate: creditorIdentificationNumber,
      mandate_reference: mandateReference,
    };
  }

  handleCheckboxToggle = () =>
    this.setState((prevState) => ({ agreeCheckbox: !prevState.agreeCheckbox }));

  selectAccount = (selectedAccount = {}) => {
    const { directDebitAccount } = this.state;

    if (directDebitAccount?.accountId === selectedAccount.accountId) return;
    this.setState({ directDebitAccount: selectedAccount });
  };

  handleOpen = () =>
    this.setState({
      isModalOpen: true,
    });

  handleExportModalOpen = () => {
    this.setState({
      isExportModalOpen: true,
    });
  };

  handleClose = () => this.setState(this.defaultState);

  handleExportModalClose = () => this.setState({ isExportModalOpen: false });

  onSubmit = async () => {
    const { createDirectDebit, showNotification, fetchUserPlan } = this.props;
    const {
      agreeCheckbox,
      directDebitAccount: { iban },
    } = this.state;

    this.setState({ isCreationInProgress: true });

    try {
      await createDirectDebit({ agreeCheckbox, bankIban: iban });
    } catch (error) {
      return error;
    }

    const date = moment().add(1, 'months').startOf('month').format(DATE_FORMAT);

    fetchUserPlan();
    showNotification(date);
    return this.handleClose();
  };

  render() {
    const { isModalOpen, agreeCheckbox, isCreationInProgress, isExportModalOpen } = this.state;
    const { paymentsBankAccounts, currentPlanEndDate, trialEndDate, deleteAt } = this.props;
    const { nextPlanValidFrom } = nextPlanDate(trialEndDate, currentPlanEndDate);
    const deletionDate = getDeletionDate(deleteAt);

    return (
      <div data-id="PaymentPlans:directDebitMandateInfo">
        {deletionDate ? (
          <AccountDeletion deletionDate={deletionDate} onClick={this.handleExportModalOpen} />
        ) : (
          isNextPlanPresent(trialEndDate, currentPlanEndDate) && (
            <NextPlanDate nextPlanValidFrom={nextPlanValidFrom} />
          )
        )}

        <Section title={t('profile.payment_plan.debit.section_header')}>
          <div className={styles.container}>
            <DebitData />
            <button className={styles.button} onClick={this.handleOpen}>
              {t('profile.payment_plan.debit.new_direct_debit')}
            </button>
          </div>
          <span className={styles.footer}>{t('profile.payment_plan.debit.footer')}</span>
        </Section>
        {isModalOpen && (
          <NewDirectDebitModal
            isOpen={isModalOpen}
            onClose={this.handleClose}
            onSubmit={this.onSubmit}
            debitData={this.getModalsDebitData}
            selectAccount={this.selectAccount}
            paymentsBankAccounts={paymentsBankAccounts}
            handleCheckboxToggle={this.handleCheckboxToggle}
            agreeCheckbox={isCreationInProgress || agreeCheckbox}
          />
        )}
        {isExportModalOpen && <ExportModal onClose={this.handleExportModalClose} />}
      </div>
    );
  }
}

DebitDescription.propTypes = {
  creditorIdentificationNumber: string.isRequired,
  paymentsBankAccounts: arrayOf(shape({})),
  fetchUserPlan: func.isRequired,
  showNotification: func.isRequired,
  currentPlanEndDate: string,
  trialEndDate: string,
  createDirectDebit: func.isRequired,
  credentials: shape({}),
  debitData: shape({
    number: string,
    iban: string,
  }),
};

const mapStateToProps = (state) => ({
  paymentsBankAccounts: state.incomingInvoice.paymentsBankAccounts.list,
  credentials: state.profile.credentials,
  deleteAt: state.profile.credentials.deleteAt,
  debitData: directDebitSelector(state),
  creditorIdentificationNumber: state.appSettings.directDebitMandates.creditorIdentificationNumber,
});

const mapDispatchToProps = (dispatch) => ({
  createDirectDebit: (...args) => dispatch(createDirectDebitAction(...args)),
  fetchUserPlan: (date) => dispatch(fetchUserPlanAction(directDebitSuccess(date))),
  showNotification: (date) => dispatch(showNotificationAction(directDebitSuccess(date))),
});

export default connect(mapStateToProps, mapDispatchToProps)(DebitDescription);
