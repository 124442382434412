import { reduxForm } from 'redux-form';

import { filledValidator, generateValidation } from 'shared/utils/form-validation';

import Form from './Form';

const passwordFilled = filledValidator('password', {
  type: 'password-not-filled',
});

const validate = (values) => ({
  password: generateValidation([passwordFilled])(values),
});

export default reduxForm({
  form: 'datevDownload',
  destroyOnUnmount: false,
  validate,
})(Form);
