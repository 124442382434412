import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { createAndFetchNewProposal } from 'actions/proposal';
import { NEW_MODE } from 'constants/common/crud';
import { FORM_NAME } from 'constants/proposal';
import ProposalCreator from 'containers/Proposals/ProposalCreator';
import { withWarningAlert } from 'shared/hoc';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import useLineItemsControl, { SectionType } from 'components/LineItems/useLineItemControl';

const NewProposal = () => {
  const dispatch = useAppDispatch();
  const {
    manualHandleSelectCategory,
    setLineItemState,
    setShowCategoryChangeConfirmation,
    setShowDifferentCategoryWarning,
    setShowVatIdWarning,
    showDifferentCategoryWarning,
    showCategoryChangeConfirmation,
    defaultCategory,
    canSubmit,
    showVatIdWarning,
    category,
    taxRates,
    taxRateId,
    defaultVat,
    selectedCategory,
    lineCategories,
  } = useLineItemsControl({
    sectionType: SectionType.proposal,
    formName: FORM_NAME,
    isNewForm: true,
  });

  const prepareNewProposal = () => dispatch(createAndFetchNewProposal());

  //inital fetch document data
  useEffect(() => {
    prepareNewProposal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProposalCreator
      crudMode={NEW_MODE}
      defaultCategory={defaultCategory}
      handleSelectCategory={manualHandleSelectCategory}
      selectedCategory={selectedCategory}
      category={category}
      showCategoryChangeConfirmation={showCategoryChangeConfirmation}
      setShowCategoryChangeConfirmation={setShowCategoryChangeConfirmation}
      taxRateId={taxRateId}
      taxRates={taxRates}
      defaultVat={defaultVat}
      lineCategories={lineCategories}
      canSubmit={canSubmit}
      setLineItemState={setLineItemState}
      showVatIdWarning={showVatIdWarning}
      setShowVatIdWarning={setShowVatIdWarning}
      showDifferentCategoryWarning={showDifferentCategoryWarning}
      setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
    />
  );
};

const mapStateToProps = () => ({
  buttonLabel: t('empty_entry_pages.proposals.proposals_warning_box.button_label'),
  content: t('empty_entry_pages.proposals.proposals_warning_box.content_1'),
  dataId: 'Proposals:orange-warning-container',
});

export default compose<any>(connect(mapStateToProps, null), withWarningAlert)(NewProposal);
