import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';

import { downloadProposal as downloadProposalPreview, getProposalPreview } from 'actions/proposal';
import { FORM_NAME as PROPOSAL_CREATOR } from 'constants/proposal';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import PreviewModalComponent from 'components/PreviewModal';

const PreviewModal = ({
  proposalId,
  details,
  proposalValues = {},
  isOpen,
  onClose,
  downloadPreview,
  getPreview,
}) => {
  const values = { ...proposalValues, watermark: true, clientId: proposalValues.client?.id };
  const download = () => downloadPreview(details, values);

  return (
    <PreviewModalComponent
      title={t('modals.preview_proposal.title')}
      getPreview={() => getPreview(proposalId, values)}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ActionButton
        appearance="primary"
        onClick={download}
        label={t('proposals.form.download')}
        type="button"
      />
    </PreviewModalComponent>
  );
};

PreviewModal.propTypes = {
  proposalId: PropTypes.string.isRequired,
  details: PropTypes.shape({}).isRequired,
  proposalValues: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  downloadPreview: PropTypes.func.isRequired,
  getPreview: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  proposalId: state.proposal.details.id,
  details: state.proposal.details,
  proposalValues: getFormValues(PROPOSAL_CREATOR)(state),
});

export default connect(mapStateToProps, {
  downloadPreview: downloadProposalPreview,
  getPreview: getProposalPreview,
})(PreviewModal);
