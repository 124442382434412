import { connect } from 'react-redux';

import { createDeliveryNoteLineItem } from 'actions/delivery-note/line-items';
import { TYPE_DELIVERY_NOTE } from 'constants/row-types';
import { NewItemRow } from 'components/LineItems';

import styles from './Table.module.css';

const mapStateToProps = (state) => ({
  deliveryNoteId: state.deliveryNote.details.id,
  smallEntrepreneur: state.company.details.smallEntrepreneur,
  insertedAsGross: state.deliveryNote.details.insertedAsGross,
});

const mapDispatchToProps = (dispatch) => ({
  createLineItem: (...args) => dispatch(createDeliveryNoteLineItem(...args)),
});

const mergeProps = (
  { deliveryNoteId, smallEntrepreneur, insertedAsGross },
  dispatchProps,
  ownProps
) => ({
  ...ownProps,
  ...dispatchProps,
  smallEntrepreneur,
  invoiceInsertedAsGross: insertedAsGross,
  translationPath: 'features.delivery_note',
  rowType: TYPE_DELIVERY_NOTE,
  cells: [
    { name: 'position', type: 'positionSelect' },
    { name: 'quantity', type: 'input', className: styles.quantityCell },
    { name: 'unit', type: 'unitSelect' },
  ],
  extendedRowCells: [
    { name: 'description', type: 'counterInput' },
    { name: 'internalNotes', type: 'counterInput' },
  ],
  createLineItem: (formValues) => dispatchProps.createLineItem(deliveryNoteId, formValues),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NewItemRow);
