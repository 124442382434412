import React, { ChangeEvent, useCallback, useState } from 'react';

import { showNotification } from 'actions/notification';
import { deleteMassBankAccount } from 'api/me/bankAccounts';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import Button from 'redesign/components/atoms/Button/Button';
import Checkbox from 'redesign/components/atoms/Checkbox/Checkbox';
import CircleIcon from 'redesign/components/atoms/CircleIcon/CircleIcon';
import IconWarning from 'redesign/components/atoms/Icons/Warning';
import ModalActions from 'redesign/components/atoms/ModalActions/ModalActions';
import ControlLabel from 'redesign/components/molecules/ControlLabel/ControlLabel';
import Modal from 'redesign/components/molecules/Modal/Modal';

import { Content, Headline, IconWrapper, ModalBody, Warning } from './Modals.styled';
import { removeBankAccess } from './notifications';

type ModalProps = {
  bankCode: string;
  onClose: () => void;
  onReload: () => void;
};

const DeleteAccessModal = ({ bankCode, onClose, onReload }: ModalProps) => {
  const dispatch = useAppDispatch();
  const [isCheckedDeleteAccounts, setIsCheckedDeleteAccounts] = useState(false);
  const [isCheckedDeleteTransactions, setIsCheckedDeleteTransactions] = useState(false);

  const handleAccountsCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsCheckedDeleteAccounts(event.target.checked);
  }, []);

  const handleTransactionsCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsCheckedDeleteTransactions(event.target.checked);
  }, []);

  const handleDelete = async () => {
    await deleteMassBankAccount(bankCode);
    onReload();
    onClose();
    dispatch(showNotification(removeBankAccess));
  };

  return (
    <Modal isOpen onRequestClose={onClose}>
      <ModalBody>
        <IconWrapper>
          <CircleIcon icon={IconWarning} color="red" />
        </IconWrapper>

        <Content>
          <Headline>
            {t('redesign.organisms.bank_accounts_list.delete_modal.header_1')}
            <br /> {t('redesign.organisms.bank_accounts_list.delete_modal.header_2')}
          </Headline>

          <Warning>{t('redesign.organisms.bank_accounts_list.delete_modal.warning')}</Warning>

          <ControlLabel
            label={t('redesign.organisms.bank_accounts_list.delete_modal.checkbox_accounts')}
            control={<Checkbox />}
            checked={isCheckedDeleteAccounts}
            onChange={handleAccountsCheckboxChange}
            data-id="checkbox-accounts"
          />
          <ControlLabel
            label={t(
              'redesign.organisms.bank_accounts_list.delete_access_modal.checkbox_transaction'
            )}
            control={<Checkbox />}
            checked={isCheckedDeleteTransactions}
            onChange={handleTransactionsCheckboxChange}
            data-id="checkbox-transactions"
          />
        </Content>
      </ModalBody>
      <ModalActions>
        <Button
          $color="red"
          disabled={!(isCheckedDeleteTransactions && isCheckedDeleteAccounts)}
          onClick={handleDelete}
        >
          {t('redesign.organisms.bank_accounts_list.delete_modal.confirm_deletion')}
        </Button>
        <Button $variant="outlined" onClick={onClose}>
          {t('redesign.organisms.bank_accounts_list.delete_modal.cancel')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeleteAccessModal;
