import styled from 'styled-components';

import { Breakpoints } from 'redesign/styles/breakpoints';

export const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.space['10']};
`;

export const Title = styled.h2`
  margin-bottom: ${({ theme }) => theme.space['1']};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey['070']};
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (min-width: ${Breakpoints.md}) {
    flex-direction: row;
  }
`;
