import styled from 'styled-components';

import media from 'shared/styles/breakpoints';

export const Form = styled.form`
  min-width: min(90vw, 396px);
`;

export const Content = styled.div`
  padding: 28px 20px 100px 20px;
`;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  margin: 0 0 55px 0;
`;

export const Buttons = styled.div`
  padding: 20px 20px 10px 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media.greaterThan('md-up')`
    flex-direction: row;
    padding-bottom: 20px;

    button:first-child {
      margin-left: 0;
    }
  `}
`;
