import { Document as BaseDocument, Page as BasePage } from 'react-pdf/dist/esm/entry.webpack';
import styled from 'styled-components';

import BaseIconClose from 'redesign/components/atoms/Icons/Close';
import BaseLink from 'redesign/components/atoms/Link/Link';
import { Breakpoints } from 'redesign/styles/breakpoints';

export const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(250, 250, 250, 0.9);
  z-index: 50007;
`;

export const ModalContent = styled.div`
  position: absolute;
  inset: 0;
  outline: none;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
`;

export const Header = styled.div`
  background-color: rgba(250, 250, 250, 0.9);
  box-shadow: 0 4px 5px 0 rgba(12, 61, 104, 0.5);
  padding: ${({ theme }) => theme.space['4']};
  position: relative;
  z-index: 1;
`;

export const HeaderContainer = styled.div`
  > div {
    &:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: ${({ theme }) => theme.space['4']};
    }

    &:last-child {
      display: grid;
      grid-template-columns: 1fr auto auto;
      align-items: center;
      grid-column-gap: ${({ theme }) => theme.space['2']};
    }
  }

  @media (min-width: ${Breakpoints.sm}) {
    > div:last-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (min-width: ${Breakpoints.xl}) {
    margin: 0 auto;
    max-width: 1200px;
  }
`;

export const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grey['080']};
`;

export const Document = styled(BaseDocument)`
  overflow: auto;
  padding: ${({ theme }) => `0 ${theme.space['4']} ${theme.space['4']}`};
  position: relative;
`;

export const IconClose = styled(BaseIconClose)`
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.vrblue['075']};
  }
`;

export const Page = styled(BasePage)`
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
`;

export const PageWrapper = styled.div`
  padding-top: ${({ theme }) => theme.space['4']};
  width: min-content;
  margin: 0 auto;
`;

export const Download = styled(BaseLink)`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize['sm']};

  span {
    display: none;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media (min-width: ${Breakpoints.sm}) {
    text-indent: 0;

    svg {
      margin-right: ${({ theme }) => theme.space['1']};
    }

    span {
      display: inline;
    }
  }
`;
