import React, { PureComponent } from 'react';
import { func, string } from 'prop-types';

import { t } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';
import { ConfirmationModal } from 'components/Modal';

import styles from './ResetTemplateButton.module.css';

class ResetTemplateButton extends PureComponent {
  state = {
    isModalOpened: false,
  };

  setModalOpened = (isModalOpened) => this.setState({ isModalOpened });

  handleButtonClick = () => this.setModalOpened(true);

  handleModalAccept = () => {
    const { onReset } = this.props;

    this.setModalOpened(false);
    onReset();
  };

  render() {
    const { heading } = this.props;
    const { isModalOpened } = this.state;

    return (
      <>
        <div
          tabIndex="0"
          data-id="InvoiceTemplates:button-reset-template"
          role="button"
          onKeyPress={isPressedEnter(this.handleButtonClick)}
          onClick={this.handleButtonClick}
          className={styles.button}
        >
          {t('invoice_templates.reset.reset_button')}
        </div>
        <ConfirmationModal
          dangerousAction
          hasWarningIcon
          isOpen={isModalOpened}
          onClose={() => this.setModalOpened(false)}
          onConfirm={this.handleModalAccept}
          header={t('invoice_templates.reset.modal_header')}
          closeLabel={t('invoice_templates.reset.modal_abort')}
          confirmLabel={t('invoice_templates.reset.modal_accept')}
          dataIds={{
            modal: 'InvoiceTemplates:reset-template-modal',
            abortButton: 'InvoiceTemplates:button-abort-reset-template',
            acceptButton: 'InvoiceTemplates:button-accept-reset-template',
          }}
        >
          {t('invoice_templates.reset.modal_content', {
            tabName: heading,
          })}
        </ConfirmationModal>
      </>
    );
  }
}

ResetTemplateButton.propTypes = {
  heading: string,
  onReset: func.isRequired,
};

export default ResetTemplateButton;
