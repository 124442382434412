import React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

import { DATE_FORMAT_UNICODE } from 'constants/datetime';
import { formatMoney, t } from 'shared/utils';

import styles from './PredictionSection.module.css';

export type Prediction = {
  dueDate: Date;
  totalPaid: number;
  totalOwed: number;
};

type PredictionSectionProps = {
  prediction: Prediction;
  endDate: Date;
  overall: number;
};

const PredictionSection = ({ prediction, endDate, overall }: PredictionSectionProps) => (
  <div>
    <div className={cx(styles.row, styles.dateRow)}>
      <div className={styles.label}>{t('contracts.new.progress_bar.prediction')}</div>
      <div className={styles.date}>{format(prediction.dueDate, 'MMMM yyyy', { locale: de })}</div>
    </div>
    <div className={cx(styles.row, styles.endDateRow)}>
      <div className={styles.label}>{t('contracts.new.progress_bar.end_date')}</div>
      <div className={styles.endDate}>{format(endDate, DATE_FORMAT_UNICODE)}</div>
    </div>
    <div className={cx(styles.row, styles.paidRow)}>
      <div className={styles.label}>{t('contracts.new.progress_bar.paid')}</div>
      <div className={styles.paid}>{formatMoney(prediction.totalPaid)}</div>
    </div>
    <div className={cx(styles.row, styles.owedRow)}>
      <div className={styles.label}>{t('contracts.new.progress_bar.owed')}</div>
      <div className={styles.owed}>{formatMoney(prediction.totalOwed)}</div>
    </div>
    <div className={styles.row}>
      <div className={styles.label}>{t('contracts.new.progress_bar.overall')}</div>
      <div className={styles.overall}>{formatMoney(overall)}</div>
    </div>
  </div>
);

export default PredictionSection;
