import React from 'react';

import type { DecoupledChallenge as DecoupledChallengeType } from 'types/entities/Figo';
import Loading from 'components/Loading';

import styles from './DecoupledChallenge.module.css';

type DecoupledChallengeProps = {
  challenge: DecoupledChallengeType;
};

const DecoupledChallenge = ({ challenge }: DecoupledChallengeProps) => {
  return (
    <div className={styles.wrapper}>
      <Loading text={challenge.message} />
    </div>
  );
};

export default DecoupledChallenge;
