import React from 'react';
import { Column } from 'react-table';
import { format } from 'date-fns';
import moment from 'moment';

import { DATE_FORMAT_UNICODE } from 'constants/datetime';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { ContractSource, ExpenseContract, ExpenseContractType } from 'types/entities/Contract';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import IconDelete from 'components/Icons/IconDelete';
import IconShow from 'components/Icons/IconShow';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import IncomingInvoiceAmount from 'components/Table/IncomingInvoiceAmount';
import CurrencyRangeFilter from 'components/v2/Table/Filters/CurrencyRangeFilter/CurrencyRangeFilter';
import DateRangeFilter from 'components/v2/Table/Filters/DateRangeFilter/DateRangeFilter';
import SelectFilter from 'components/v2/Table/Filters/SelectFilter/SelectFilter';
import TableCell from 'components/v2/Table/TableCell/TableCell';
import TableHeaderWithSorting from 'components/v2/Table/TableHeader/TableHeaderWithSorting';
import { TooltipPlacements } from 'redesign/components/molecules/Tooltip/Tooltip';
import { Status } from 'features/contracts/types';

import { Action, Actions } from '../../Shared.styled';
import StatusBadge from '../../StatusBadge/StatusBadge';

import styles from './getColumns.module.css';

type GetColumnsProps = {
  onDelete: (expenseContract: ExpenseContract) => void;
  intervalOptions?: { label: string; value: string }[];
};

/* eslint-disable react/display-name */
const getColumns = ({
  onDelete,
  intervalOptions = [],
}: GetColumnsProps): Column<ExpenseContract>[] => {
  return [
    {
      Header: t('contracts.contract_status.status'),
      Cell: ({ row: { original } }: { row: { original: ExpenseContract } }) => {
        return <StatusBadge status={original.finishedAt ? Status.Finished : Status.Active} />;
      },
      id: 'status',
      disableSortBy: true,
    },
    {
      Header: ({ column: { canSort, isSorted, isSortedDesc, getSortByToggleProps } }) => (
        <TableHeaderWithSorting
          canSort={canSort}
          isSorted={isSorted}
          isSortedDesc={isSortedDesc}
          {...getSortByToggleProps()}
        >
          {t('tables.headers.subject')}
        </TableHeaderWithSorting>
      ),
      Cell: ({ value }) => <CellContentWithTooltip>{value}</CellContentWithTooltip>,
      accessor: 'subject',
      disableFilters: true,
    },
    {
      Header: ({ column: { canSort, isSorted, isSortedDesc, getSortByToggleProps } }) => (
        <TableHeaderWithSorting
          canSort={canSort}
          isSorted={isSorted}
          isSortedDesc={isSortedDesc}
          {...getSortByToggleProps()}
        >
          {t('tables.headers.payment_recipient')}
        </TableHeaderWithSorting>
      ),
      Cell: ({ row: { original } }) => {
        const { lastName, companyName, city } = original.supplier;
        const supplierDeleted = (!lastName || !companyName) && !city;

        return supplierDeleted ? (
          ''
        ) : (
          <CellContentWithTooltip>{`${companyName || lastName}, ${city}`}</CellContentWithTooltip>
        );
      },
      accessor: 'supplier',
      disableFilters: true,
    },
    {
      Header: ({ column: { canSort, isSorted, isSortedDesc, getSortByToggleProps } }) => (
        <TableHeaderWithSorting
          canSort={canSort}
          isSorted={isSorted}
          isSortedDesc={isSortedDesc}
          {...getSortByToggleProps()}
        >
          {t('tables.headers.next_payment_date')}
        </TableHeaderWithSorting>
      ),
      id: 'transactionDate',
      accessor: (row) =>
        row.upcomingInstallment?.transactionDate
          ? format(new Date(row.upcomingInstallment.transactionDate), DATE_FORMAT_UNICODE)
          : t('tables.headers.no_next_payment_date'),
      Filter: (props) => (
        <DateRangeFilter
          {...props}
          minDateFrom={moment()}
          minDateTo={moment()}
          label={t('recurring_expenses.search.next_payment_date_range')}
        />
      ),
    },
    {
      Header: t('tables.headers.payment_cycle'),
      Cell: ({ value }) => t(`recurring_expenses.interval_options.${value.name}`),
      accessor: 'recurringTransactionInterval',
      disableSortBy: true,
      Filter: (props) => (
        <SelectFilter
          {...props}
          label={t('tables.headers.payment_cycle')}
          options={intervalOptions}
        />
      ),
    },
    {
      Header: ({ column: { canSort, isSorted, isSortedDesc, getSortByToggleProps } }) => (
        <TableHeaderWithSorting
          canSort={canSort}
          isSorted={isSorted}
          isSortedDesc={isSortedDesc}
          align="right"
          {...getSortByToggleProps()}
        >
          {t('tables.headers.gross_amount')}
        </TableHeaderWithSorting>
      ),
      Cell: ({ value }: { value: number }) =>
        !value ? (
          ''
        ) : (
          <TableCell align="right">
            <IncomingInvoiceAmount amount={value} precision={2} />
          </TableCell>
        ),
      id: 'grossAmount',
      accessor: (row) => row.upcomingInstallment?.grossAmount,
      Filter: (props) => (
        <CurrencyRangeFilter
          {...props}
          labelFrom={t('expenses.table.columns.amount_higher_than')}
          labelTo={t('expenses.table.columns.amount_lower_than')}
        />
      ),
    },
    {
      id: 'actions',
      Header: t('tables.headers.actions'),
      Cell: ({ row: { original } }: { row: { original: ExpenseContract } }) => {
        const { id, source, contractType } = original;
        const isVrso = source === ContractSource.Vrso;
        const viewPath = paths.viewExpenseContract(
          id,
          contractType === ExpenseContractType.loan ? 'loan' : 'basic'
        );

        return (
          <Actions>
            <Action to={viewPath} data-id="view">
              <IconShow />
            </Action>
            <Action onClick={() => onDelete(original)} data-id="delete">
              <IconDelete />
            </Action>
            {isVrso && (
              <InfoIcon
                tooltipPlacement={TooltipPlacements.LEFT}
                text={t('contracts.index_view.vrso_tooltip')}
                tooltipClassName={styles.message}
              />
            )}
          </Actions>
        );
      },
      disableSortBy: true,
      disableFilters: true,
    },
  ];
};
/* eslint-enable react/display-name */

export default getColumns;
