import React, { Component } from 'react';
import { connect } from 'react-redux';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import Modal from 'components/Modal';

import ModalsLayout from './ModalsLayout/ModalsLayout';

import styles from './ModalsContainer.module.css';

class ModalsContainer extends Component {
  state = {
    modalType: this.props.type,
  };

  get shouldInitiallySelectMainBankAccount() {
    const { paymentsBankAccounts } = this.props;
    const isMainBankAccountPresent = paymentsBankAccounts.find((account) => account.main);

    return !!isMainBankAccountPresent;
  }

  setModalToRender = (modalType) => this.setState({ modalType });

  render() {
    const { isOpen, onClose } = this.props;
    const { modalType } = this.state;

    return (
      <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.container}>
        <button className={styles.cancel} onClick={onClose} />
        <ModalsLayout
          {...this.props}
          modalType={modalType}
          setModalToRender={this.setModalToRender}
          shouldInitiallySelectMainBankAccount={this.shouldInitiallySelectMainBankAccount}
        />
      </Modal>
    );
  }
}

ModalsContainer.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  type: string.isRequired,
  paymentsBankAccounts: arrayOf(shape({})),
};

const mapStateToProps = (state) => ({
  paymentsBankAccounts: state.incomingInvoice.paymentsBankAccounts.list,
  isPaymentsBankAccountsFetching: state.incomingInvoice.paymentsBankAccounts.isFetching,
});

export default connect(mapStateToProps)(ModalsContainer);
