import React, { ReactElement, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { get } from 'lodash';
import { compose } from 'recompose';
import { FormAction, InjectedFormProps, reduxForm, reset, SubmitHandler } from 'redux-form';

import { fetchNumberRanges, updateNumberRanges, validateNumberRanges } from 'actions/number-ranges';
import {
  PIWIK_ACTION_SUBMIT_NUMBER_RANGES,
  PIWIK_CATEGORY_PROFILE,
  PIWIK_GOAL_SAVE_NUMBER_RANGE,
} from 'constants/piwik';
import { productCatalogItemsEnabledHelper } from 'routes/accesses';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import { Dispatch } from 'types/actions';
import ActionButtons from 'components/ActionButtons/ActionButtons';
import ActionButton from 'components/ActionPanel/ActionButton';
import { TextField } from 'components/Form';
import Section, { SectionRow } from 'components/Form/Section/Section';

import FormField from './FormField';
import { Hint, HintTitle, Notice } from './NumberRangesForm.styled';

import styles from './NumberRangesForm.module.css';

const FORM_NAME = 'numberRanges';

interface HideDisabledProps {
  children: ReactElement;
  disabled: boolean;
}

const HideDisabled = ({ children, disabled }: HideDisabledProps) => {
  if (disabled)
    return <span className={styles.disabled}>{t('profile.number_ranges.form.disabled')}</span>;
  return children;
};

type InitialValueShape = {
  disabled: boolean;
  preview: string;
  start: number;
  template: string;
};

interface InitialValues {
  clients: InitialValueShape;
  invoices: InitialValueShape;
  products: InitialValueShape;
  proposals: InitialValueShape;
  orderConfirmations: InitialValueShape;
  deliveryNotes: InitialValueShape;
  suppliers: InitialValueShape;
}

interface DisabledInputs {
  [key: string]: boolean;
}

interface FormOwnProps {
  submitting?: boolean;
  handleSubmit?: (callback: () => Dispatch) => SubmitHandler;
}

interface FormStateProps {
  isSubmitSucceeded: boolean;
  initialValues: InitialValues;
  disabled: DisabledInputs;
  productCatalogItemsEnabled: boolean;
}

interface FormDispatchProps {
  fetch: () => (dispatch: Dispatch) => any;
  submitHandler: () => void;
  resetForm: (formName: string) => FormAction;
}

type FormProps = FormOwnProps & FormStateProps & FormDispatchProps;

const handleSubmitButtonClick = () => {
  piwikHelpers.trackEvent(PIWIK_CATEGORY_PROFILE, PIWIK_ACTION_SUBMIT_NUMBER_RANGES);
  piwikHelpers.trackGoal(PIWIK_GOAL_SAVE_NUMBER_RANGE);
};

const Form = ({
  disabled,
  productCatalogItemsEnabled,
  submitHandler,
  fetch,
  resetForm,
  handleSubmit,
  submitting,
}: FormProps & InjectedFormProps<{}, FormProps>) => {
  const items = useMemo(
    () => [
      { name: 'proposals', isEnabled: true },
      { name: 'orderConfirmations', isEnabled: true },
      { name: 'invoices', isEnabled: true },
      { name: 'deliveryNotes', isEnabled: true },
      { name: 'suppliers', isEnabled: true },
      { name: 'clients', isEnabled: true },
      { name: 'products', isEnabled: productCatalogItemsEnabled },
    ],
    [productCatalogItemsEnabled]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <form className={styles.main} onSubmit={handleSubmit(submitHandler)}>
      <Section withoutHeader>
        {items.map(
          ({ name, isEnabled }) =>
            isEnabled && (
              <SectionRow className={styles.row} key={name}>
                <FormField
                  name={`${name}.template`}
                  component={TextField}
                  className={styles.fieldLarge}
                  dataId={`NumberRanges:input-${name}Template`}
                  required
                />
                <HideDisabled disabled={disabled[name]}>
                  <FormField
                    name={`${name}.start`}
                    component={TextField}
                    className={styles.fieldLarge}
                    dataId={`NumberRanges:input-${name}Start`}
                    required
                  />
                </HideDisabled>
                <FormField
                  name={`${name}.preview`}
                  component={TextField}
                  className={styles.fieldLarge}
                  dataId={`NumberRanges:input-${name}Preview`}
                  disabled
                />
              </SectionRow>
            )
        )}
      </Section>
      <HintTitle>{t('profile.number_ranges.help_message.heading')}</HintTitle>
      <Hint>
        {t('profile.number_ranges.help_message.description')}
        <br />
        {t('profile.number_ranges.help_message.description_2')}
      </Hint>
      <HintTitle>{t('profile.number_ranges.help_message.example.text')}</HintTitle>
      <Hint>
        {t('profile.number_ranges.help_message.example.1')}
        <br />
        {t('profile.number_ranges.help_message.example.2')}
      </Hint>
      <Notice>{t('profile.number_ranges.help_message.hint')}</Notice>
      <HintTitle>{t('profile.number_ranges.help_message.example.text')}</HintTitle>
      <Hint>
        {t('profile.number_ranges.help_message.example.3')}
        <br />
        {t('profile.number_ranges.help_message.example.4')}
      </Hint>
      <ActionButtons>
        <ActionButton
          appearance="outlined"
          onClick={() => {
            fetch();
            resetForm(FORM_NAME);
          }}
          label={t('profile.number_ranges.buttons.cancel')}
          type="button"
        />
        <button
          type="submit"
          disabled={submitting}
          className={cx(styles.button, styles.buttonSubmit)}
          onClick={handleSubmitButtonClick}
          data-id="NumberRanges:button-save"
        >
          {t('profile.number_ranges.buttons.save')}
        </button>
      </ActionButtons>
    </form>
  );
};

const mapStateToProps = (state: any): FormStateProps => ({
  isSubmitSucceeded: !!state.numberRanges.asyncValidating,
  initialValues: state.form.numberRanges.initial,
  disabled: {
    proposals: get(state, `form.${FORM_NAME}.initial.proposals.disabled`) || false,
    orderConfirmations:
      get(state, `form.${FORM_NAME}.initial.orderConfirmations.disabled`) || false,
    deliveryNotes: get(state, `form.${FORM_NAME}.initial.deliveryNotes.disabled`) || false,
    invoices: get(state, `form.${FORM_NAME}.initial.invoices.disabled`) || false,
    suppliers: get(state, `form.${FORM_NAME}.initial.suppliers.disabled`) || false,
    clients: get(state, `form.${FORM_NAME}.initial.clients.disabled`) || false,
    products: get(state, `form.${FORM_NAME}.initial.products.disabled`) || false,
  },
  productCatalogItemsEnabled: productCatalogItemsEnabledHelper(state),
});

const mapDispatchToProps = {
  fetch: fetchNumberRanges,
  submitHandler: updateNumberRanges,
  resetForm: reset,
};

export default compose<FormProps & InjectedFormProps<{}, FormProps>, {}>(
  connect<FormStateProps, FormDispatchProps, FormOwnProps>(mapStateToProps, mapDispatchToProps),
  reduxForm<{}, FormProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    persistentSubmitErrors: true,
    asyncBlurFields: [
      'proposals.template',
      'proposals.start',
      'orderConfirmations.template',
      'orderConfirmations.start',
      'deliveryNotes.template',
      'deliveryNotes.start',
      'invoices.template',
      'invoices.start',
      'suppliers.template',
      'suppliers.start',
      'clients.template',
      'clients.start',
      'products.template',
      'products.start',
    ],
    asyncValidate(values, dispatch, _data, target) {
      return validateNumberRanges(values, dispatch, target !== undefined);
    },
  }),
  withTransitionPrevent()
)(Form);
