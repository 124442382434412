import { camelCase, mapKeys } from 'lodash';

import { mapKeysDeep } from 'shared/utils';

export const mapEntity = (data) => ({
  ...data.attributes,
  id: data.id,
});

export const camelizeKeys = (object) => mapKeys(object, (_, key) => camelCase(key));
export const camelizeKeysDeep = (object) => mapKeysDeep(object, (_, key) => camelCase(key));

export default {};
