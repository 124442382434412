import React from 'react';

import { formatMoney, t } from 'shared/utils';

import { Summary as StyledSummary } from '../AssignmentModal.styled';

type SummaryProps = {
  amount: number;
  isExpense: boolean;
};

const Summary = ({ amount, isExpense }: SummaryProps) => (
  <StyledSummary isNegative={isExpense}>
    <p>{t('bank_transfers.assigment.modal.summary')}</p>
    <span data-id="assignment-box-bank-transfer-sum">
      {formatMoney(isExpense ? -1 * amount : amount)}
    </span>
  </StyledSummary>
);

export default Summary;
