import React, { memo, ReactNode } from 'react';
import cx from 'classnames';

import styles from './Title.module.css';

type TitleProps = {
  children: ReactNode;
  className?: string;
};

const Title = ({ children, className }: TitleProps) => (
  <div className={cx(styles.title, className)}>{children}</div>
);

export default memo(Title);
