import React, { memo, SVGProps } from 'react';

const LiquiChart = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 97 61" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="#BFD9EC" fillRule="evenodd">
      <g fillRule="nonzero">
        <path d="M82.578 12.297a2 2 0 0 1 2.96 2.684l-.116.128L51.2 49.724a2 2 0 0 1-2.432.32l-.144-.092-20.41-14.418-19.808 19.59a2 2 0 0 1-2.701.103l-.127-.118a2 2 0 0 1-.102-2.702l.118-.126 21-20.77a2 2 0 0 1 2.417-.303l.143.092 20.393 14.406 33.03-33.41Z" />
        <path d="M28 28.203a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9ZM50 42.203a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9ZM5 51.203a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Z" />
      </g>
      <path d="M87.931 7.975 71.2 14.566a1 1 0 0 0-.34 1.638l10.14 10.14a1 1 0 0 0 1.638-.34l6.591-16.732a1 1 0 0 0-1.297-1.297Z" />
    </g>
  </svg>
);

export default memo(LiquiChart);
