import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { head, isEmpty } from 'lodash';
import { bool, func, number, string } from 'prop-types';

import { showNotification } from 'actions/notification';
import {
  TRANSLATE_NEGATIVE_BANK_TRANSFER,
  TRANSLATE_OPTION_ARRAY,
  TRANSLATE_POSTIVE_BANK_TRANSFER,
} from 'constants/assigment-label';
import productionUrl from 'constants/productionUrl';
import withViewport from 'shared/hoc/withViewport';
import { formatAmountWithHistory, t } from 'shared/utils';
import getConnectionError from 'shared/utils/get-connection-error';
import Link from 'redesign/components/atoms/Link/Link';

import LabelAssignmentModal from './components/LabelAssignmentModal';
import Option from './components/Option';

class LabelAssignment extends Component {
  state = {
    selectedOption:
      head(
        TRANSLATE_OPTION_ARRAY.filter((option) => option.apiValue === this.props.selectedOption)
      ) || {},
    hoveredLabelDescription: null,
    amount: formatAmountWithHistory()(this.props.amount),
    parsedValue: undefined,
    note: this.props.note || undefined,
    labelId: this.props.labelId || undefined,
  };

  normalizeValue = (value) => {
    return {
      parsedValue: value,
      normalizedValue: value,
    };
  };

  handleOnSelect = (selectedOption) => this.setState({ selectedOption });

  handleOnMouseEnter = (option) =>
    this.setState({ hoveredLabelDescription: t(option.description) });

  handleOnBlur = () => this.setState({ hoveredLabelDescription: null });

  defaultDescription = () => {
    const url = `${productionUrl}/service/hilfe-im-vr-smart-guide/einnahmen/wie-erstelle-ich-einen-Eigenbeleg`;

    return (
      <Fragment>
        <p>{t('bank_transfers.label_assignment.modal.descritpions.default.p_1')}</p>
        <p>
          {`${t('bank_transfers.label_assignment.modal.descritpions.default.p_2')} `}
          <Link target="_blank" href={url}>
            {t('bank_transfers.label_assignment.modal.descritpions.default.link_label')}
          </Link>
        </p>
      </Fragment>
    );
  };

  get labelDescription() {
    const { selectedOption, hoveredLabelDescription } = this.state;
    if (hoveredLabelDescription) return hoveredLabelDescription;
    if (!isEmpty(selectedOption) && selectedOption.description) {
      return t(selectedOption.description);
    }
    return this.defaultDescription();
  }

  handleOnNoteInputChange = ({ target: { value } }) => this.setState({ note: value });

  handleAmountOnInputChange = ({ target: { value } }) => {
    this.setState({
      amount: value,
      parsedValue: value,
    });
  };

  handleCreateLabel = async () => {
    const { createLabel, onClose, isLabelInEditMode } = this.props;
    const {
      amount,
      note,
      selectedOption: { value },
      labelId,
    } = this.state;
    const { normalizedValue } = this.normalizeValue(amount);
    try {
      await createLabel({
        amount: normalizedValue,
        note,
        selectedOption: value,
        id: labelId,
        isLabelInEditMode,
      });
    } catch (error) {
      const { validationErrorNotification } = this.props;
      const errorMessage = getConnectionError(error);
      return validationErrorNotification(errorMessage);
    }
    return onClose();
  };

  render() {
    const { isBankTransferPositive, isOpen, onClose, isMdUp } = this.props;
    const { amount, parsedValue, note, selectedOption } = this.state;
    const options = isBankTransferPositive
      ? TRANSLATE_POSTIVE_BANK_TRANSFER
      : TRANSLATE_NEGATIVE_BANK_TRANSFER;
    const disabled = isEmpty(selectedOption) || !formatAmountWithHistory(parsedValue)(amount);

    return (
      <LabelAssignmentModal
        amount={amount}
        createLabel={this.handleCreateLabel}
        disabled={disabled}
        isMdUp={isMdUp}
        isOpen={isOpen}
        labelDescription={this.labelDescription}
        note={note}
        onAmountInputChange={this.handleAmountOnInputChange}
        onBlur={this.handleOnBlur}
        onClose={onClose}
        onNoteInputChange={this.handleOnNoteInputChange}
        optionRenderer={(option) => (
          <Option {...option} handleOnMouseEnter={() => this.handleOnMouseEnter(option)} />
        )}
        onSelect={this.handleOnSelect}
        selectedOption={selectedOption}
        selectOptions={options}
      />
    );
  }
}

LabelAssignment.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  isBankTransferPositive: bool.isRequired,
  amount: number,
  createLabel: func.isRequired,
  isLabelInEditMode: bool.isRequired,
  isMdUp: bool.isRequired,
  validationErrorNotification: func.isRequired,
  note: string,
  selectedOption: string,
  labelId: string,
};

const mapDispatchToProps = (dispatch) => ({
  validationErrorNotification: (message) => dispatch(showNotification(message)),
});

export default withViewport(connect(null, mapDispatchToProps)(LabelAssignment));
