import React from 'react';

import { t } from 'shared/utils';
import Button from 'redesign/components/atoms/Button/Button';
import CircleIcon from 'redesign/components/atoms/CircleIcon/CircleIcon';
import IconWarning from 'redesign/components/atoms/Icons/Warning';
import ModalActions from 'redesign/components/atoms/ModalActions/ModalActions';
import Modal from 'redesign/components/molecules/Modal/Modal';

import { ModalBody, Title } from './ConfirmationModal.styled';

export type ConfirmationModalProps = {
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  buttonText: string;
  isOpen: boolean;
};

const ConfirmationModal = ({
  isOpen,
  onCancel,
  onConfirm,
  title,
  buttonText,
}: ConfirmationModalProps) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel}>
      <ModalBody>
        <CircleIcon icon={IconWarning} color="red" />
        <Title>{title}</Title>
      </ModalBody>
      <ModalActions>
        <Button onClick={onConfirm}>{buttonText}</Button>
        <Button $variant="outlined" onClick={onCancel}>
          {t('redesign.organisms.dropzone.cancel')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default ConfirmationModal;
