import React from 'react';
import { SingleDatePicker } from 'react-dates';
import * as moment from 'moment';

import { CalendarIcon, DatePickerWrapper, Error, Label } from './DateField.styled';

export type DateFieldDate = moment.Moment | null;

export type DateFieldProps = {
  date: DateFieldDate;
  focused: boolean;
  id: string;
  name?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  onDateChange: (date: DateFieldDate) => void;
  onFocusChange: (value: { focused: boolean }) => void;
  isOutsideRange?: () => boolean;
};

const DateField = ({
  name,
  label,
  date,
  focused,
  onDateChange,
  onFocusChange,
  id,
  isOutsideRange = () => false,
  disabled,
  placeholder,
  error,
}: DateFieldProps) => {
  return (
    <DatePickerWrapper $isActive={focused} $error={error}>
      {label && (
        <Label $isDisabled={disabled} $isActive={focused} $error={error} htmlFor={name}>
          {label}
        </Label>
      )}
      <SingleDatePicker
        date={date}
        focused={focused}
        onDateChange={onDateChange}
        onFocusChange={onFocusChange}
        isOutsideRange={isOutsideRange}
        placeholder={placeholder || undefined}
        id={id}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        customInputIcon={<CalendarIcon />}
      />
      {error && <Error>{error}</Error>}
    </DatePickerWrapper>
  );
};

export default DateField;
