import React from 'react';
import { differenceWith, get } from 'lodash';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';
import { compose, withProps, withPropsOnChange, withState } from 'recompose';

import { noop, t } from 'shared/utils';
import Section from 'components/Form/Section/Section';
import SuggestedBankTransfer from 'components/SuggestedBankTransfer/SuggestedBankTransfer';

import styles from './Suggestions.module.css';

const hideSuggestionAfterSuccess =
  ({ apiCallAction, setProcessedSuggestions }) =>
  async (...args) => {
    const response = await apiCallAction(...args);
    const id = get(response, 'response.data.id', undefined);
    if (id) {
      setProcessedSuggestions((prevValue) => [...prevValue, id]);
    }
    return response;
  };

const enhance = compose(
  withState('processedSuggestions', 'setProcessedSuggestions', []),
  withProps(
    ({ acceptConnectionSuggestion, rejectConnectionSuggestion, setProcessedSuggestions }) => ({
      acceptConnectionSuggestion: hideSuggestionAfterSuccess({
        apiCallAction: acceptConnectionSuggestion,
        setProcessedSuggestions,
      }),
      rejectConnectionSuggestion: hideSuggestionAfterSuccess({
        apiCallAction: rejectConnectionSuggestion,
        setProcessedSuggestions,
      }),
    })
  ),
  withPropsOnChange(
    ['suggestions', 'processedSuggestions'],
    ({ suggestions, processedSuggestions }) => ({
      suggestions: differenceWith(
        suggestions,
        processedSuggestions,
        ({ id: suggestionId }, processedSuggestionId) => suggestionId === processedSuggestionId
      ),
    })
  )
);

const isInAssignmentMode = (suggestion, assigmentModeData) => {
  if (!assigmentModeData) {
    return false;
  }

  const { id: suggestionId } = suggestion;
  const { id: assignmentId } = assigmentModeData;

  return suggestionId === assignmentId;
};

const Suggestions = ({
  invoiceId,
  suggestions = [],
  fullyPaid = false,
  rejectConnectionSuggestion = noop,
  handleSuggestionSelect,
  assigmentModeData,
}) =>
  !fullyPaid &&
  suggestions.length > 0 && (
    <Section
      label={t('bank_transfers.assigment.suggestion_label')}
      title={t('bank_transfers.assigment.suggestion_title')}
      className={styles.main}
    >
      {suggestions.map(
        (suggestion) =>
          !isInAssignmentMode(suggestion, assigmentModeData) && (
            <SuggestedBankTransfer
              {...suggestion}
              key={suggestion.id}
              onAccept={() => handleSuggestionSelect(suggestion)}
              onReject={() =>
                rejectConnectionSuggestion({
                  type: suggestion.type,
                  bankTransferId: suggestion.id,
                  invoiceId,
                })
              }
            />
          )
      )}
    </Section>
  );

Suggestions.propTypes = {
  suggestions: arrayOf(shape({})),
  fullyPaid: bool,
  invoiceId: oneOfType([string, number]),
  rejectConnectionSuggestion: func.isRequired,
  handleSuggestionSelect: func.isRequired,
  assigmentModeData: shape({}),
};

export default enhance(Suggestions);
