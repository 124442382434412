import styled from 'styled-components';

import { Breakpoints } from 'redesign/styles/breakpoints';

const Th = styled.th`
  position: sticky;
  top: 0;
  z-index: 1;
  height: 28px;
  padding: 0 ${({ theme }) => theme.space['2']};
  background-color: ${({ theme }) => theme.colors.lightblue};
  color: ${({ theme }) => theme.colors.grey['080']};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: bold;
  line-height: 17px;

  @media (min-width: ${Breakpoints.sm}) {
    padding: 0 ${({ theme }) => theme.space['4']};
  }
`;

export default Th;
