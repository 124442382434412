import React from 'react';

import { CHART_RED } from 'constants/colors';

const AreaGradientFilter = () => (
  <defs>
    <linearGradient id="myGradient" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop
        offset="0%"
        style={{
          stopColor: CHART_RED,
          stopOpacity: 0.2,
        }}
      />
      <stop offset="100%" style={{ stopColor: CHART_RED, stopOpacity: 0 }} />
    </linearGradient>
  </defs>
);

export default AreaGradientFilter;
