import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { OBS_CLOSE_MODAL, OBS_REQUEST_NOW } from 'constants/piwik';
import OBSIframeModal from 'components/OBS/OBSIframeModal/OBSIframeModal';
import OBSModal from 'components/OBS/OBSModal/OBSModal';

import { trackEvent } from './utils';

export const DATA_IDS = {
  obsModal: {
    container: 'OBS:modal-container',
    acceptButton: 'OBS:button-modal-accept',
    closeButton: 'OBS:button-modal-abort',
  },
  iframeModal: {
    container: 'OBS:iframe-modal-container',
    closeButton: 'OBS:button-iframe-modal-close',
  },
};

type SetIsOBSModalOpen = Dispatch<SetStateAction<boolean>>;

type OBSModalFlowProps = {
  isOBSModalOpen: boolean;
  setIsOBSModalOpen: SetIsOBSModalOpen;
};

const OBSModalFlow = ({ isOBSModalOpen, setIsOBSModalOpen }: OBSModalFlowProps) => {
  const [isIframeOpened, setIsIframeOpened] = useState<boolean>(false);
  const [isCanceled, setIsCanceled] = useState<boolean>(false);

  const handleCancel = useCallback(() => {
    setIsIframeOpened(false);
    setIsCanceled(false);
    trackEvent(OBS_CLOSE_MODAL);
  }, [isIframeOpened, isCanceled]);

  const handleTriggerCancelPopup = useCallback(() => {
    setIsCanceled((isCanceled) => !isCanceled);
  }, [isCanceled]);

  return (
    <>
      <OBSModal
        isOpen={isOBSModalOpen}
        onClose={() => {
          setIsOBSModalOpen(false);
          trackEvent(OBS_CLOSE_MODAL);
        }}
        onConfirm={() => {
          setIsOBSModalOpen(false);
          setIsIframeOpened(true);
          trackEvent(OBS_REQUEST_NOW);
        }}
        dataIds={{
          container: DATA_IDS.obsModal.container,
          acceptButton: DATA_IDS.obsModal.acceptButton,
          closeButton: DATA_IDS.obsModal.closeButton,
        }}
      />
      <OBSIframeModal
        dataIds={{
          container: DATA_IDS.iframeModal.container,
          closeButton: DATA_IDS.iframeModal.closeButton,
        }}
        isOpen={isIframeOpened}
        onTriggerCancelPopup={handleTriggerCancelPopup}
        isCanceled={isCanceled}
        onCancel={handleCancel}
      />
    </>
  );
};

export default OBSModalFlow;
