import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import { arrayOf, bool, func, shape } from 'prop-types';

import { enterEditInvoiceMode } from 'actions/datev';
import { deleteIncomingInvoice as deleteIncomingInvoiceAction } from 'actions/incoming-invoices';
import { PIWIK_ACTION_DELETE_INCOMING_INVOICE, PIWIK_CATEGORY_EXPENSES } from 'constants/piwik';
import paths from 'routes/paths';
import styles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import I18n from 'components/I18n';
import { ConfirmationModal } from 'components/Modal';

import InvoiceHeader from './InvoiceHeader/';
import InvoiceRow from './InvoiceRow/InvoiceRow';

const InvoiceType = ({ creditNote }) => {
  if (creditNote) {
    return <I18n t="expenses.table.values.credit_note.true" />;
  }
  return <I18n t="expenses.table.values.credit_note.false" />;
};

InvoiceType.propTypes = {
  creditNote: bool.isRequired,
};

class IncomingInvoicesTable extends Component {
  state = {
    editingInvoice: undefined,
    deletingInvoice: undefined,
  };

  render() {
    const {
      isFetching,
      data,
      deleteIncomingInvoice,
      refresh,
      hasFilters,
      isIndexClassicView,
      hasInvoicesWithoutFiles,
      pushPath,
    } = this.props;
    const onClearConfirmations = () =>
      this.setState({ editingInvoice: undefined, deletingInvoice: undefined });
    const onEdit = (invoice) => {
      onClearConfirmations();
      if (hasInvoicesWithoutFiles) {
        return this.props.enterEditInvoiceMode(invoice.id);
      }
      return pushPath(paths.editIncomingInvoice(invoice.id));
    };
    const onDelete = (invoice) => {
      onClearConfirmations();
      deleteIncomingInvoice(invoice).then(() => {
        refresh();
        piwikHelpers.trackEvent(PIWIK_CATEGORY_EXPENSES, PIWIK_ACTION_DELETE_INCOMING_INVOICE);
      });
    };
    const onRequestEdit = (invoice) => {
      if (invoice.status === 'paid') {
        this.setState({ editingInvoice: invoice });
      } else {
        onEdit(invoice);
      }
    };
    const onRequestDelete = (invoice) => {
      this.setState({ deletingInvoice: invoice });
    };

    // On views with filters, sorting by status is disabled.
    const isStatusSortable = !hasFilters;
    const columns = [
      { column: 'arrowIcon', hidden: true },
      { column: 'status', sortable: isStatusSortable },
      { column: 'supplier', sortable: true },
      { column: 'number', sortable: true },
      { column: 'name', sortable: true },
      { column: 'invoice_date', sortable: true },
      { column: 'due_date', sortable: true },
      { column: 'discount_gross_value', sortable: true, align: 'right' },
      { column: 'total_gross_amount', sortable: true, align: 'right' },
      { column: 'actions', info: t('expenses.table.actions.info_text') },
      // we have to set fixed width for last hidden colum so sub invoice component
      // will be rendered with right space which is equal to this hidden column width
      { column: 'space', hidden: true },
    ];

    return (
      <div className={styles.invoicesTableWrapper} data-id="table">
        <table className={cx(styles.invoicesTable, { [styles.invoicesTableLoading]: isFetching })}>
          <thead>
            <tr>
              {columns.map((column) => (
                <InvoiceHeader
                  key={column.column}
                  isCentered={hasInvoicesWithoutFiles}
                  {...column}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((invoice, index) => (
              <InvoiceRow
                index={index}
                key={invoice.id}
                invoice={invoice}
                onEdit={onRequestEdit}
                onDelete={onRequestDelete}
                isIndexClassicView={isIndexClassicView}
                hasFilePresenceStatus={hasInvoicesWithoutFiles}
                isEven={index % 2 === 0}
              />
            ))}
          </tbody>
        </table>
        <ConfirmationModal
          dangerousAction
          isOpen={!!this.state.editingInvoice}
          onClose={onClearConfirmations}
          onConfirm={() => onEdit(this.state.editingInvoice)}
        >
          <I18n t="expenses.confirm_edit_paid" paragraphs />
        </ConfirmationModal>
        <ConfirmationModal
          dangerousAction
          isOpen={!!this.state.deletingInvoice}
          onClose={onClearConfirmations}
          onConfirm={() => onDelete(this.state.deletingInvoice)}
        >
          <I18n
            t={`expenses.confirm_delete${
              get(this.state.deletingInvoice || {}, 'status') === 'paid' ? '_paid' : ''
            }`}
            paragraphs
          />
        </ConfirmationModal>
      </div>
    );
  }
}

IncomingInvoicesTable.propTypes = {
  data: arrayOf(shape({})).isRequired,
  isFetching: bool.isRequired,
  dispatch: func,
  refresh: func.isRequired,
  hasFilters: bool,
  isIndexClassicView: bool.isRequired,
  hasInvoicesWithoutFiles: bool,
  enterEditInvoiceMode: func,
  pushPath: func,
  deleteIncomingInvoice: func.isRequired,
};

IncomingInvoicesTable.defaultProps = {
  hasFilters: false,
  hasInvoicesWithoutFiles: false,
};

const mapDispatchToProps = (dispatch) => ({
  enterEditInvoiceMode: (invoiceId) => dispatch(enterEditInvoiceMode(invoiceId)),
  pushPath: (path) => dispatch(push(path)),
  deleteIncomingInvoice: (invoice) => dispatch(deleteIncomingInvoiceAction(invoice)),
});

export default connect(null, mapDispatchToProps)(IncomingInvoicesTable);
