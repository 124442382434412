import React, { memo, ReactNode } from 'react';

import styles from './Table.module.css';

type BodyProps = {
  children: ReactNode;
};

const Body = ({ children }: BodyProps) => <div className={styles.body}>{children}</div>;

export default memo(Body);
