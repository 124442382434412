import { l } from 'shared/utils';
import { ensureNumericMoneyValue } from 'shared/utils/money';
import { precisionCoherentStringRegex } from 'shared/utils/regex';
import { CategoryResponse } from 'types/entities/Category';
import { TaxOption } from 'types/entities/TaxRate';

export const normalize = (values: { netAmount?: string | number; quantity?: number }) => {
  return {
    ...values,
    quantity: Number(values.quantity) || 0,
    netAmount: ensureNumericMoneyValue(values.netAmount),
  };
};

// taxOptions below should be identical to ./app/constants/vat.rb ::VAT::RATES
export const taxOptions = [0, 5, 5.5, 7, 9, 9.5, 10.7, 19].map((o) => ({
  value: o,
  label: l(o, 'percentage'),
}));

//the LineItem version
export const getTaxRates = (items: TaxOption[] | undefined) => {
  if (items === undefined) {
    return;
  }

  const taxOptions: TaxOption[] = [];

  items &&
    items?.map(({ value, id, defaultTaxValue }) => {
      const parsedId = parseInt(String(id));
      const parsedVatValue = parseFloat(String(value));

      taxOptions.push({
        id: parsedId,
        value: parsedVatValue,
        label: l(value, 'percentage'),
        defaultTaxValue,
      });
    });

  return taxOptions.sort((a, b) => b.value - a.value);
};

export const getDefaultTaxRate = (lineItem: CategoryResponse) => {
  if (!lineItem?.taxRates) {
    return;
  }

  const taxRates = getTaxRates(lineItem?.taxRates);
  const defaultTaxRate = taxRates && taxRates.find((item) => item.defaultTaxValue === true);
  const highestVatValue =
    taxRates &&
    taxRates.find((item) => item.value === Math.max(...taxRates.map(({ value }) => value)));

  if (defaultTaxRate) {
    return defaultTaxRate;
  }

  return highestVatValue;
};

export const getTaxRatesOptions = (items: TaxOption[] | undefined) => {
  let taxOptions: TaxOption[] = [];

  items &&
    items.map(({ value, id, defaultTaxValue }) => {
      const parsedId = parseInt(String(id));
      const parsedVatValue = parseFloat(String(value));

      taxOptions.push({
        id: parsedId,
        value: parsedId,
        label: l(value, 'percentage'),
        vat: parsedVatValue,
        defaultTaxValue,
      });
    });

  return taxOptions.sort((a, b) => a.value - b.value);
};

export const getDefaultCategory = (lineCategories: CategoryResponse[]) => {
  const getSubcategories = lineCategories.filter((item) => item.parentId !== null);

  if (getSubcategories.length === 1) return getSubcategories[0];

  return lineCategories?.find(({ defaultCategory }) => defaultCategory === true);
};

export const getCategory = (
  lineCategories: CategoryResponse[],
  selectedCategory: CategoryResponse | CategoryResponse['id']
) =>
  lineCategories?.find((item) => {
    if (typeof selectedCategory !== 'string' && selectedCategory?.id) {
      return item.id?.toString() === selectedCategory?.id?.toString();
    }

    return item.id?.toString() === selectedCategory?.toString();
  });

export const getLineItemState = (lineItemsState: any, defaultBool = true) => {
  let keys = Object.values(lineItemsState);

  return keys.length ? keys.every((open) => !open) : defaultBool;
};

type ValuesType = { [prop: string]: string };

export const humanize = (values: ValuesType, name: keyof ValuesType) => {
  const parsers = {
    netAmount: ensureNumericMoneyValue,
    grossAmount: ensureNumericMoneyValue,
  };

  // @ts-expect-error
  return typeof parsers[name] === 'function' ? parsers[name](values[name]) : values[name];
};

export const humanizeAll = (values: ValuesType) => {
  const keys = Object.keys(values);
  const result: ValuesType = {};

  keys.forEach((key) => {
    result[key] = humanize(values, key);
  });
  return result;
};

export const getTaxRate = (
  taxRateId: string | number | undefined,
  taxRatesOptions: TaxOption[]
) => {
  if (typeof taxRateId === 'string' || typeof taxRateId === 'number') {
    return parseInt(String(taxRateId));
  }

  if (taxRatesOptions?.length === 1) {
    return parseInt(String(taxRatesOptions[0].id));
  }

  return null;
};

export const isStateValidForToggle = ({
  netAmount,
  grossAmount,
  insertedAsGross,
}: {
  netAmount: number;
  grossAmount: number;
  insertedAsGross: boolean;
}) => {
  const value = insertedAsGross ? String(grossAmount) : String(netAmount);
  const regexTest = value.match(precisionCoherentStringRegex);

  if (regexTest !== null) {
    const [matchedValue] = regexTest;

    return matchedValue === value;
  }

  return false;
};

export const transformLineItemProps = (lineItem: CategoryResponse) => ({
  ...lineItem,
});
