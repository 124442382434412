import React from 'react';

import { t } from 'shared/utils';
import Button from 'redesign/components/atoms/Button/Button';
import CircleIcon from 'redesign/components/atoms/CircleIcon/CircleIcon';
import IconWarning from 'redesign/components/atoms/Icons/Warning';
import ModalActions from 'redesign/components/atoms/ModalActions/ModalActions';
import Modal from 'redesign/components/molecules/Modal/Modal';

import { Content, Headline, IconWrapper, Message, ModalBody } from './Modals.styled';

type ModalProps = {
  onClose: () => void;
  isSingleAccount: boolean;
  isMainAccount: boolean;
};

const DeletionErrorModal = ({ onClose, isSingleAccount, isMainAccount }: ModalProps) => {
  const errorContent = {
    singleMain: {
      title: t('redesign.organisms.bank_accounts_list.deletion_error_modal.single_main_title'),
      body: t('redesign.organisms.bank_accounts_list.deletion_error_modal.single_main_body'),
    },
    single: {
      title: t('redesign.organisms.bank_accounts_list.deletion_error_modal.single_title'),
      body: t('redesign.organisms.bank_accounts_list.deletion_error_modal.single_body'),
    },
    multipleMain: {
      title: t('redesign.organisms.bank_accounts_list.deletion_error_modal.multiple_main_title'),
      body: t('redesign.organisms.bank_accounts_list.deletion_error_modal.multiple_main_body'),
    },
    multiple: {
      title: t('redesign.organisms.bank_accounts_list.deletion_error_modal.multiple_title'),
      body: t('redesign.organisms.bank_accounts_list.deletion_error_modal.multiple_body'),
    },
  };

  const error = isSingleAccount
    ? isMainAccount
      ? errorContent.singleMain
      : errorContent.single
    : isMainAccount
    ? errorContent.multipleMain
    : errorContent.multiple;

  return (
    <Modal isOpen onRequestClose={onClose}>
      <ModalBody>
        <IconWrapper>
          <CircleIcon icon={IconWarning} color="red" />
        </IconWrapper>

        <Content>
          <Headline>{error.title}</Headline>
          <Message>{error.body}</Message>
        </Content>
      </ModalBody>
      <ModalActions>
        <Button onClick={onClose}>
          {t('redesign.organisms.bank_accounts_list.deletion_error_modal.close')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeletionErrorModal;
