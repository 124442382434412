import React, { useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { differenceInDays, isFuture as isFutureFn, isToday as isTodayFn, parse } from 'date-fns';

import { formatMoney, t } from 'shared/utils';

import * as SC from './Tooltip.styled';

type CustomFlyoutProps = {
  threshold?: number;
  liquidityChartWidth: number;
  isTaxDay: boolean;
  scale?: {
    x: (n: number) => number;
    y: (n: number) => number;
  };
  datum?: {
    bankTransferExpensesSum: number;
    bankTransferRevenuesSum: number;
    bankTransfersBalance: number;
    contractsExpensesAmount: number;
    contractsRevenuesAmount: number;
    invoicesExpensesAmount: number;
    invoicesRevenuesAmount: number;
    totalExpensesAmount: number;
    cashTransactionExpensesSum: number;
    cashTransactionRevenuesSum: number;
    cashTransactionsBalance: number;
    expensesSum: number;
    revenuesSum: number;
    totalSum: number;
    totalTaxes: number;
    totalExpensesTaxes: number;
    totalRevenuesAmount: number;
    totalRevenuesTaxes: number;
    index: number;
    day: string;
  };
};

type CustomFlyoutState = {
  x: number | null;
  y: number | null;
  scrollPosition: number | null;
  position: {
    x: number | null;
    y: number | null;
  };
};

const CustomFlyout = ({ liquidityChartWidth, scale, datum, isTaxDay }: CustomFlyoutProps) => {
  const { x: scaleX, y: scaleY } = scale!;
  const {
    bankTransferExpensesSum,
    bankTransferRevenuesSum,
    bankTransfersBalance,
    cashTransactionExpensesSum,
    cashTransactionRevenuesSum,
    cashTransactionsBalance,
    contractsExpensesAmount,
    contractsRevenuesAmount,
    expensesSum,
    invoicesExpensesAmount,
    invoicesRevenuesAmount,
    revenuesSum,
    totalExpensesAmount,
    totalTaxes,
    totalExpensesTaxes,
    totalRevenuesAmount,
    totalRevenuesTaxes,
    totalSum,
    index,
    day,
  } = datum!;
  const [state, setState] = useState<CustomFlyoutState>({
    x: null,
    y: null,
    scrollPosition: null,
    position: {
      x: null,
      y: null,
    },
  });

  const rectRef = useRef<SVGRectElement>(null);

  useEffect(() => {
    calcTooltipPosition();
    window.addEventListener('scroll', calcTooltipPosition, { passive: true });

    return () => {
      (window as any).removeEventListener('scroll', calcTooltipPosition, { passive: true });
    };
  });

  const getCoordinates = () => {
    const x = scaleX(index);
    const y = scaleY(totalSum);
    return { x, y };
  };

  const calcTooltipPosition = () => {
    const { x, y } = getCoordinates();
    const scrollPosition = window?.pageYOffset;

    const domainHasChanged = state.x !== x || state.y !== y;
    const documentHasScrolled = scrollPosition !== state.scrollPosition;

    const shouldRecalculate = domainHasChanged || documentHasScrolled;

    if (rectRef && shouldRecalculate) {
      const {
        x: domX,
        left,
        y: domY,
        bottom,
      } = rectRef.current?.getBoundingClientRect() as DOMRect;
      const newCords = {
        x: Math.floor(domX || left),
        y: Math.floor(domY || bottom),
      };

      setState({
        x: x!,
        y: y!,
        scrollPosition,
        position: newCords,
      });
    }
  };

  const { position } = state;
  const { x, y } = getCoordinates();
  const dayAsDate = parse(day, 'dd.MM.yyyy', new Date());
  const isPast = differenceInDays(dayAsDate, new Date()) < 0;
  const isToday = isTodayFn(dayAsDate);
  const isFuture = isFutureFn(dayAsDate);
  const isShowOnRight = x < liquidityChartWidth / 2;
  const taxes =
    totalTaxes < 0
      ? {
          todayTitle: t('dashboard.liquidity_chart.tooltip.today.total_revenues_taxes_title'),
          futureTitle: t('dashboard.liquidity_chart.tooltip.future.total_revenues_taxes_title'),
          value: formatMoney(totalTaxes),
        }
      : {
          todayTitle: t('dashboard.liquidity_chart.tooltip.today.total_expenses_taxes_title'),
          futureTitle: t('dashboard.liquidity_chart.tooltip.future.total_expenses_taxes_title'),
          value: formatMoney(totalTaxes),
        };

  return (
    <g>
      <rect x={x} y={y} width="1" height="1" opacity="0" ref={rectRef} />
      <Portal>
        <SC.Wrapper posX={position.x} posY={position.y} isShowOnRight={isShowOnRight}>
          {isPast && (
            <SC.Past>
              {/* Salden am */}
              <SC.PastTitle>{`${t(
                'dashboard.liquidity_chart.tooltip.past.title'
              )} ${day}`}</SC.PastTitle>
              <SC.RevenueSection>
                {/* Einnahmen */}
                <SC.Row bold>
                  <h5>{t('dashboard.liquidity_chart.tooltip.past.revenues_sum')}:</h5>
                  <SC.Value isPositive>{formatMoney(revenuesSum)}</SC.Value>
                </SC.Row>

                {/* Banktransaktionen */}
                <SC.Row>
                  <h5>{t('dashboard.liquidity_chart.tooltip.past.bank_transfer_revenues_sum')}</h5>
                  <SC.Value isPositive>{formatMoney(bankTransferRevenuesSum)}</SC.Value>
                </SC.Row>
                {/* Bartransaktionen */}
                <SC.Row>
                  <h5>
                    {t('dashboard.liquidity_chart.tooltip.past.cash_transaction_revenues_sum')}
                  </h5>
                  <SC.Value isPositive>{formatMoney(cashTransactionRevenuesSum)}</SC.Value>
                </SC.Row>
              </SC.RevenueSection>
              <SC.ExpensesSection>
                {/* Ausgaben */}
                <SC.Row bold>
                  <h5>{t('dashboard.liquidity_chart.tooltip.past.expenses_sum')}:</h5>
                  <SC.Value isPositive={false}>{formatMoney(expensesSum)}</SC.Value>
                </SC.Row>
                {/* Banktransaktionen */}
                <SC.Row>
                  <h5>{t('dashboard.liquidity_chart.tooltip.past.bank_transfer_expenses_sum')}</h5>
                  <SC.Value isPositive={false}>{formatMoney(bankTransferExpensesSum)}</SC.Value>
                </SC.Row>
                {/* Bartransaktionen */}
                <SC.Row>
                  <h5>
                    {t('dashboard.liquidity_chart.tooltip.past.cash_transaction_expenses_sum')}
                  </h5>
                  <SC.Value isPositive={false}>{formatMoney(cashTransactionExpensesSum)}</SC.Value>
                </SC.Row>
              </SC.ExpensesSection>
              <SC.TotalBalanceSection>
                {/* Gesamtsaldo */}
                <SC.Row bold>
                  <h5>{t('dashboard.liquidity_chart.tooltip.past.total_sum')}</h5>
                  <SC.Value isPositive={totalSum > 0}>{formatMoney(totalSum)}</SC.Value>
                </SC.Row>
                {/* Kontensaldo */}
                <SC.Row>
                  <h5>{t('dashboard.liquidity_chart.tooltip.past.bank_transfers_balance')}</h5>
                  <SC.Value isPositive={bankTransfersBalance > 0}>
                    {formatMoney(bankTransfersBalance)}
                  </SC.Value>
                </SC.Row>
                {/* Kassensaldo */}
                <SC.Row>
                  <h5>{t('dashboard.liquidity_chart.tooltip.past.cash_transactions_balance')}</h5>
                  <SC.Value isPositive={cashTransactionsBalance > 0}>
                    {formatMoney(cashTransactionsBalance)}
                  </SC.Value>
                </SC.Row>
              </SC.TotalBalanceSection>
            </SC.Past>
          )}
          {isToday && (
            <SC.Today>
              {/* Heute */}
              <SC.TodayTitle>{`${t(
                'dashboard.liquidity_chart.tooltip.today.title'
              )} ${day}`}</SC.TodayTitle>
              <SC.AccountBalanceSection>
                {/* aktueller Kontensaldo */}
                <SC.Row bold>
                  <h5>{t('dashboard.liquidity_chart.tooltip.today.bank_transfers_balance')}</h5>
                  <SC.Value isPositive={bankTransfersBalance > 0}>
                    {formatMoney(bankTransfersBalance)}
                  </SC.Value>
                </SC.Row>
                {/* Einnahmen */}
                <SC.Row short>
                  <h5>{t('dashboard.liquidity_chart.tooltip.today.bank_transfer_revenues_sum')}</h5>
                  <SC.Value isPositive>{formatMoney(bankTransferRevenuesSum)}</SC.Value>
                </SC.Row>
                {/* Ausgaben */}
                <SC.Row short>
                  <h5>{t('dashboard.liquidity_chart.tooltip.today.bank_transfer_expenses_sum')}</h5>
                  <SC.Value isPositive={false}>{formatMoney(bankTransferExpensesSum)}</SC.Value>
                </SC.Row>
              </SC.AccountBalanceSection>
              <SC.CashbookBalanceSection>
                {/* aktueller Kassensaldo */}
                <SC.Row bold>
                  <h5>{t('dashboard.liquidity_chart.tooltip.today.cash_transactions_balance')}</h5>
                  <SC.Value isPositive={cashTransactionsBalance > 0}>
                    {formatMoney(cashTransactionsBalance)}
                  </SC.Value>
                </SC.Row>
                {/* Einnahmen */}
                <SC.Row short>
                  <h5>
                    {t('dashboard.liquidity_chart.tooltip.today.cash_transaction_revenues_sum')}
                  </h5>
                  <SC.Value isPositive>{formatMoney(cashTransactionRevenuesSum)}</SC.Value>
                </SC.Row>
                {/* Ausgaben */}
                <SC.Row short>
                  <h5>
                    {t('dashboard.liquidity_chart.tooltip.today.cash_transaction_expenses_sum')}
                  </h5>
                  <SC.Value isPositive={false}>{formatMoney(cashTransactionExpensesSum)}</SC.Value>
                </SC.Row>
              </SC.CashbookBalanceSection>
              <SC.ExpectedRevenueSection>
                {/* voraussichtl. Einnahmen */}
                <SC.Row bold>
                  <h5>{t('dashboard.liquidity_chart.tooltip.today.total_revenues_amount')}</h5>
                  <SC.Value isPositive>{formatMoney(totalRevenuesAmount)}</SC.Value>
                </SC.Row>
                {/* aus noch fälligen Rechnungen */}
                <SC.Row short>
                  <h5>{t('dashboard.liquidity_chart.tooltip.today.invoices_revenues_amount')}</h5>
                  <SC.Value isPositive>{formatMoney(invoicesRevenuesAmount)}</SC.Value>
                </SC.Row>
                {/* aus noch fälligen Vertragsraten */}
                <SC.Row short>
                  <h5>{t('dashboard.liquidity_chart.tooltip.today.contracts_revenues_amount')}</h5>
                  <SC.Value isPositive>{formatMoney(contractsRevenuesAmount)}</SC.Value>
                </SC.Row>
              </SC.ExpectedRevenueSection>
              <SC.ExpectedExpensesSection>
                {/* voraussichtl. Ausgaben */}
                <SC.Row bold>
                  <h5>{t('dashboard.liquidity_chart.tooltip.today.total_expenses_amount')}</h5>
                  <SC.Value isPositive={false}>{formatMoney(totalExpensesAmount)}</SC.Value>
                </SC.Row>
                {/* aus noch fälligen Rechnungen */}
                <SC.Row short>
                  <h5>{t('dashboard.liquidity_chart.tooltip.today.invoices_expenses_amount')}</h5>
                  <SC.Value isPositive={false}>{formatMoney(invoicesExpensesAmount)}</SC.Value>
                </SC.Row>
                {/* aus noch fälligen Vertragsraten */}
                <SC.Row short>
                  <h5>{t('dashboard.liquidity_chart.tooltip.today.contracts_expenses_amount')}</h5>
                  <SC.Value isPositive={false}>{formatMoney(contractsExpensesAmount)}</SC.Value>
                </SC.Row>
              </SC.ExpectedExpensesSection>
              <SC.ExpectedTotalBalanceSection>
                {/* voraussichtl. Gesamtsaldo */}
                <SC.Row bold>
                  <h5>{t('dashboard.liquidity_chart.tooltip.today.total_sum')}</h5>
                  <SC.Value isPositive={totalSum > 0}>{formatMoney(totalSum)}</SC.Value>
                </SC.Row>
              </SC.ExpectedTotalBalanceSection>
              {isTaxDay && (
                <SC.Taxes>
                  <SC.WarningBulbIcon />
                  {/* ihre Umsatzsteuervoranmeldung ist heute fällig */}
                  <SC.TaxesTitle>
                    {t('dashboard.liquidity_chart.tooltip.today.taxes_title')}
                  </SC.TaxesTitle>
                  {/* fälliger Vorsteuerüberhang */}
                  <SC.Row bold white shortest>
                    <h5>{taxes.todayTitle}</h5>
                    <SC.Value>{taxes.value}</SC.Value>
                  </SC.Row>
                  <SC.VerticalLine />
                  {/* Umsatzsteuer */}
                  <SC.Row white shortest>
                    <h5>{t('dashboard.liquidity_chart.tooltip.today.total_revenues_taxes')}</h5>
                    <SC.Value>{formatMoney(totalRevenuesTaxes)}</SC.Value>
                  </SC.Row>
                  {/* Vorsteuer */}
                  <SC.Row white shortest>
                    <h5>{t('dashboard.liquidity_chart.tooltip.today.total_expenses_taxes')}</h5>
                    <SC.Value>{formatMoney(totalExpensesTaxes)}</SC.Value>
                  </SC.Row>
                </SC.Taxes>
              )}
            </SC.Today>
          )}
          {isFuture && (
            <SC.Future>
              {/* Vorschau für den */}
              <SC.FutureTitle>{`${t(
                'dashboard.liquidity_chart.tooltip.future.title'
              )} ${day}`}</SC.FutureTitle>
              <SC.ExpectedRevenueSection>
                {/* voraussichtl. Einnahmen */}
                <SC.Row bold>
                  <h5>{t('dashboard.liquidity_chart.tooltip.future.total_revenues_amount')}</h5>
                  <SC.Value isPositive>{formatMoney(totalRevenuesAmount)}</SC.Value>
                </SC.Row>
                {/* Rechnungen */}
                <SC.Row short>
                  <h5>{t('dashboard.liquidity_chart.tooltip.future.invoices_revenues_amount')}</h5>
                  <SC.Value isPositive>{formatMoney(invoicesRevenuesAmount)}</SC.Value>
                </SC.Row>
                {/* Vertragsraten */}
                <SC.Row short>
                  <h5>{t('dashboard.liquidity_chart.tooltip.future.contracts_revenues_amount')}</h5>
                  <SC.Value isPositive>{formatMoney(contractsRevenuesAmount)}</SC.Value>
                </SC.Row>
              </SC.ExpectedRevenueSection>
              <SC.ExpectedExpensesSection>
                {/* voraussichtl. Ausgaben */}
                <SC.Row bold>
                  <h5>{t('dashboard.liquidity_chart.tooltip.future.total_expenses_amount')}</h5>
                  <SC.Value isPositive={false}>{formatMoney(totalExpensesAmount)}</SC.Value>
                </SC.Row>
                {/* Rechnungen */}
                <SC.Row short>
                  <h5>{t('dashboard.liquidity_chart.tooltip.future.invoices_expenses_amount')}</h5>
                  <SC.Value isPositive={false}>{formatMoney(invoicesExpensesAmount)}</SC.Value>
                </SC.Row>
                {/* Vertragsraten */}
                <SC.Row short>
                  <h5>{t('dashboard.liquidity_chart.tooltip.future.contracts_expenses_amount')}</h5>
                  <SC.Value isPositive={false}>{formatMoney(contractsExpensesAmount)}</SC.Value>
                </SC.Row>
              </SC.ExpectedExpensesSection>
              {isTaxDay && (
                <SC.ExpectedTaxesSection>
                  {/* voraussichtl. Umsatzsteuerzahlast */}
                  <SC.Row bold>
                    <h5>{taxes.futureTitle}</h5>
                    <SC.Value isPositive={totalTaxes >= 0}>{taxes.value}</SC.Value>
                  </SC.Row>
                  {/* Umsatzsteuer */}
                  <SC.Row short>
                    <h5>{t('dashboard.liquidity_chart.tooltip.future.total_revenues_taxes')}</h5>
                    <SC.Value isPositive={false}>{formatMoney(totalRevenuesTaxes)}</SC.Value>
                  </SC.Row>
                  {/* Vorsteuer */}
                  <SC.Row short>
                    <h5>{t('dashboard.liquidity_chart.tooltip.future.total_expenses_taxes')}</h5>
                    <SC.Value isPositive>{formatMoney(totalExpensesTaxes)}</SC.Value>
                  </SC.Row>
                </SC.ExpectedTaxesSection>
              )}
              <SC.ExpectedTotalBalanceSection>
                {/* expected_total_balance */}
                <SC.Row bold>
                  <h5>{t('dashboard.liquidity_chart.tooltip.future.total_sum')}</h5>
                  <SC.Value isPositive={totalSum > 0}>{formatMoney(totalSum)}</SC.Value>
                </SC.Row>
              </SC.ExpectedTotalBalanceSection>
            </SC.Future>
          )}
        </SC.Wrapper>
      </Portal>
    </g>
  );
};

export default CustomFlyout;
