import React from 'react';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { bool, func, node, oneOf, oneOfType, shape, string } from 'prop-types';

import { MODAL_HEADER_VARIANT } from 'constants/modal';
import WarningIcon from 'images/icon-warning.svg';
import { noop, t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import { ButtonAppearances } from 'components/Button';
import If from 'components/Conditions/If';
import { IGNORE_OUTSIDE_CLICK_CLASS as IGNORE_OUTSIDE_LINE_ITEM_CLICK_CLASS } from 'components/LineItems';

import styles from './Modal.module.css';

export const ModalHeader = ({
  children = '',
  headerStyle = '',
  headerContentStyle = '',
  headerVariant = MODAL_HEADER_VARIANT.BIG,
  noPadding = false,
  withCloseButton = false,
  dataIds = {},
  onClose = noop,
}) => (
  <div
    className={cx(styles.header, headerStyle, {
      [styles.headerSmall]: headerVariant === MODAL_HEADER_VARIANT.SMALL,
      [styles.headerWithCloseButton]: withCloseButton,
      [styles.noPadding]: noPadding,
      [styles.headerWithCloseButton]: withCloseButton,
    })}
  >
    <h3
      className={cx(
        { [styles.headerContentSmall]: headerVariant === MODAL_HEADER_VARIANT.SMALL },
        headerContentStyle
      )}
    >
      {children}
    </h3>
    {withCloseButton && (
      <button data-id={dataIds.closeButton} className={styles.cancel} onClick={onClose} />
    )}
  </div>
);

ModalHeader.propTypes = {
  children: node,
  headerStyle: string,
  headerContentStyle: string,
  headerVariant: oneOf([MODAL_HEADER_VARIANT.SMALL, MODAL_HEADER_VARIANT.BIG]),
  noPadding: bool,
  onClose: func,
  withCloseButton: bool,
  dataIds: shape({
    closeButton: string,
  }),
};

// eslint-disable-next-line react/prefer-stateless-function
const Modal = ({ className = '', customOverlayClassName = '', ...props }) => (
  <ReactModal
    {...props}
    contentLabel="Modal"
    appElement={document.getElementById('pbw-react-root')}
    overlayClassName={cx(styles.overlay, customOverlayClassName)}
    className={cx(styles.content, className, IGNORE_OUTSIDE_LINE_ITEM_CLICK_CLASS)}
  />
);

Modal.propTypes = {
  customOverlayClassName: string,
  className: string,
};

export default Modal;

export const InfoModal = ({
  isOpen,
  onClose,
  children,
  header,
  headerVariant = MODAL_HEADER_VARIANT.BIG,
  customClass,
  hasWarningIcon = false,
  dataIds = {},
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    className={cx(styles.infoModal, styles[customClass])}
  >
    <If ok={header}>
      <ModalHeader headerVariant={headerVariant}>{header}</ModalHeader>
    </If>
    <div data-id={dataIds.modal} className={styles.body}>
      {hasWarningIcon && <img src={WarningIcon} alt="Warning icon" />}
      <div className={styles.message}>{children}</div>
      <div className={styles.buttons}>
        <ActionButton
          dataId={dataIds.acceptButton}
          appearance="primary"
          onClick={onClose}
          label={t('modals.confirmation.ok')}
        />
      </div>
    </div>
  </Modal>
);

InfoModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  children: node.isRequired,
  header: string,
  customClass: string,
  hasWarningIcon: bool,
  headerVariant: oneOf([MODAL_HEADER_VARIANT.SMALL, MODAL_HEADER_VARIANT.BIG]),
  dataIds: shape({
    modal: string,
    acceptButton: string,
  }),
};

export const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  children,
  confirmLabel = t('modals.confirmation.confirm'),
  closeLabel = t('modals.confirmation.cancel'),
  dangerousAction = false,
  headerVariant = MODAL_HEADER_VARIANT.BIG,
  header = '',
  disabled = false,
  customClass = '',
  redirectLocation = '',
  hasWarningIcon = false,
  withCloseButton = false,
  dataIds = {
    modal: 'ConfirmationModal:modal',
    abortButton: 'ConfirmationModal:button-reject',
    acceptButton: 'ConfirmationModal:button-confirm',
  },
}) => (
  <Modal
    dataId={dataIds.modal}
    isOpen={isOpen}
    onRequestClose={onClose}
    className={cx(styles.confirmationModal, customClass)}
    contentLabel="Modal"
  >
    <If ok={header}>
      <ModalHeader
        withCloseButton={withCloseButton}
        onClose={onClose}
        headerVariant={headerVariant}
      >
        {header}
      </ModalHeader>
    </If>
    <div data-id={dataIds.modal} className={styles.confirmationInner}>
      {hasWarningIcon && <img src={WarningIcon} alt="Warning icon" />}
      <div className={styles.confirmationMessage}>{children}</div>
      <div className={styles.confirmationButtons}>
        <ActionButton
          appearance={dangerousAction ? ButtonAppearances.primary : ButtonAppearances.outlined}
          onClick={onClose}
          dataId={dataIds.abortButton}
          label={closeLabel}
        />
        {redirectLocation ? (
          <Link to={redirectLocation}>
            <ActionButton
              disabled={disabled}
              appearance={dangerousAction ? ButtonAppearances.outlined : ButtonAppearances.primary}
              onClick={onConfirm}
              label={confirmLabel}
              dataId={dataIds.acceptButton}
            />
          </Link>
        ) : (
          <ActionButton
            disabled={disabled}
            appearance={dangerousAction ? ButtonAppearances.outlined : ButtonAppearances.primary}
            onClick={onConfirm}
            label={confirmLabel}
            dataId={dataIds.acceptButton}
          />
        )}
      </div>
    </div>
  </Modal>
);

ConfirmationModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  children: node,
  confirmLabel: string,
  closeLabel: string,
  dangerousAction: bool,
  header: oneOfType([func, node, string]),
  headerVariant: oneOf([MODAL_HEADER_VARIANT.SMALL, MODAL_HEADER_VARIANT.BIG]),
  customClass: string,
  disabled: bool,
  redirectLocation: shape({}),
  hasWarningIcon: bool,
  withCloseButton: bool,
  dataIds: shape({
    modal: string,
    abortButton: string,
    acceptButton: string,
  }),
  coverImg: string,
};
