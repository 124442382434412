import moment from 'moment';

import { DATE_FORMAT_FULL_REVERSED } from 'constants/datetime';

const getDaysToDate = (date: string) => {
  const momentFormattedDate = moment(date, DATE_FORMAT_FULL_REVERSED);
  const daysToDate = moment.duration(moment().diff(momentFormattedDate)).asDays();

  const displayDate = Math.ceil(daysToDate) * -1;
  return displayDate + 1;
};

export default getDaysToDate;
