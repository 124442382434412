import React, { useEffect, useState } from 'react';
import dimensions from 'react-dimensions';
import { head, isNumber, last, times } from 'lodash';
import moment from 'moment';
import { compose } from 'recompose';
import {
  createContainer,
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryCursorContainerProps,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryZoomContainerProps,
} from 'victory';
import { DomainTuple } from 'victory-core';

import { fetchOBSQualified as fetchOBSQualifiedAction } from 'actions/liquidity/liquidity';
import {
  CHART_GREEN,
  CHART_RED,
  COLOR_BLUE,
  DARK_GREY,
  PAST_DATA_COLOR,
  TICK_LABEL_COLOR,
} from 'constants/colors';
import { DATE_FORMAT } from 'constants/datetime';
import withViewport from 'shared/hoc/withViewport';
import withVisibility from 'shared/hoc/withVisibility';
import { useAppDispatch, useAppSelector } from 'shared/hooks/app';
import { formatMoney } from 'shared/utils';
import theme from 'redesign/styles/theme';
import { getIsOBSReady } from 'features/financialPlanning/liquidityCalculator/selectors';

import AreaGradientFilter from '../AreaGradientFilter/AreaGradientFilter';
import {
  BIG_POINT_SIZE,
  DEFAULT_CHART_DOMAIN_RADIUS,
  DEFAULT_POINT_SIZE,
  HEIGHT,
  MEDIUM_POINT_SIZE,
  PADDING,
} from '../constants';
import CurrentDayLine from '../CurrentDayLine/CurrentDayLine';
import OBSTooltipContainer from '../OBSTooltipContainer/OBSTooltipContainer';
import Tooltip from '../Tooltip/Tooltip';
import * as SC from '../VictoryChart/VictoryChart.styled';

const VictoryZoomVoronoiContainer = createContainer<
  VictoryCursorContainerProps,
  VictoryZoomContainerProps
>('zoom', 'cursor');

const RenderNull = () => null;

const findClosestPointSorted = (data: Day[], x: number) => {
  if (x === null) return null;
  const start = head(data)?.index;
  const range = last(data)?.index! - start!;
  const index = Math.round(((x - start!) / range) * (data.length - 1));

  return data[index];
};

type Day = {
  day: string;
  totalSum: number;
  totalRevenuesTaxes?: number;
  totalTaxes?: number;
  index?: number;
  taxesDetails?: Object;
};

type ChartProps = {
  inViewport: boolean;
  isSmUp: boolean;
  isMdUp: boolean;
  isLgUp: boolean;
  isXlgUp: boolean;
  isXxlgUp: boolean;
  data: {
    past: Day[];
    present: Day[];
    future: Day[];
    all: Day[];
    startingWeek: number;
    weeksInYear: number;
  };
  liquidityDangerThreshold: number;
  containerWidth: number;
  dataId: string;
};

const Chart = ({
  data: { present, future, all, startingWeek, weeksInYear },
  liquidityDangerThreshold,
  dataId,
  containerWidth,
  isSmUp,
  isMdUp,
  isLgUp,
  isXlgUp,
  isXxlgUp,
}: ChartProps) => {
  const dispatch = useAppDispatch();
  const isOBSReady = useAppSelector(getIsOBSReady);

  const accessors = {
    x: (d: Day) => d.index || 0,
    y: (d: Day) => d.totalSum,
  };

  const [zoomedXDomain, setZoomedXDomain] = useState(getInitialZoom());
  const [activePoint, setActivePoint] = useState<Day | null>(null);
  const [isCurrentDayLineInView, setIsCurrentDayLineInView] = useState(true);

  useEffect(() => {
    dispatch(fetchOBSQualifiedAction());
  }, []);

  const onDomainChange = ({ x }: { x: DomainTuple }) => {
    setZoomedXDomain(x);
  };

  function getVictoryLineProps({ color = PAST_DATA_COLOR } = {}) {
    return {
      style: {
        data: {
          stroke: color,
        },
      },
      ...accessors,
    };
  }

  function getWeekNumber() {
    return (_: any, lineIndex: number) => {
      const { index: zoomIndex = 0 } = head(getAll()) || { index: undefined };
      const zoomOffset = Math.floor(zoomIndex / 7);
      const calculatedWeek = ((startingWeek + zoomOffset + lineIndex) % weeksInYear) + 1;
      return `KW ${calculatedWeek}`;
    };
  }

  function getMoney(value: number) {
    return formatMoney(value);
  }

  function getExtremeValues() {
    const values = all.map(({ totalSum }) => totalSum);
    const min = Math.min(...values, liquidityDangerThreshold);
    const max = Math.max(...values, liquidityDangerThreshold);
    if (min === max) {
      return [min - DEFAULT_CHART_DOMAIN_RADIUS, max + DEFAULT_CHART_DOMAIN_RADIUS];
    }
    return [min, max];
  }

  function getMinimumValue() {
    const [min] = getExtremeValues();
    return min;
  }

  function getMaximumValue() {
    const [, max] = getExtremeValues();
    return max;
  }

  function getBaseline() {
    return getMinimumValue() - getDomainVerticalPadding();
  }

  function getCurrentDay() {
    return head(present);
  }

  function getCurrentDayLineData() {
    return [
      {
        index: getCurrentDay()?.index,
        totalSum: getMinimumValue() - getDomainVerticalPadding(),
        customLabel: getCurrentDay()?.day,
      },
      {
        index: getCurrentDay()?.index,
        totalSum: getMaximumValue() + getDomainVerticalPadding() * 0.85,
      },
    ];
  }

  function getDomainVerticalPadding() {
    const [min, max] = getExtremeValues();
    const padding = Math.ceil((max - min) * 0.15);
    return padding;
  }

  function getYDomain() {
    const [min, max] = getExtremeValues();
    const padding = getDomainVerticalPadding();
    return [min - padding, max + padding];
  }

  function getXDomain() {
    return [head(all)?.index || 0, last(all)?.index || 0];
  }

  function getEntireDomain() {
    return { x: getXDomain() as DomainTuple, y: getYDomain() as DomainTuple };
  }

  function getAreaData() {
    return [
      { index: 0, totalSum: liquidityDangerThreshold },
      { index: all.length - 1, totalSum: liquidityDangerThreshold },
    ];
  }

  function getRadius() {
    const breakpoints = [isSmUp, isMdUp, isLgUp, isXlgUp, isXxlgUp];
    const daysRadius = [14, 14, 30, 35, 48];
    let radius = 14;
    breakpoints.forEach((breakpoint, index) => {
      if (breakpoint) {
        radius = daysRadius[index];
      }
    });

    return radius;
  }

  function getInitialZoom(): DomainTuple {
    const { index: currentDayIndex } = getCurrentDay()!;

    const initialDay = Math.max(head(getXDomain())!, currentDayIndex! - getRadius());
    const lastDay = Math.min(last(getXDomain())!, currentDayIndex! + getRadius());

    return [initialDay, lastDay];
  }

  function getObsTooltipPosition() {
    if (!isNumber(liquidityDangerThreshold) || !isOBSReady) return [];
    const data = future.find(({ totalSum }) => totalSum < liquidityDangerThreshold);
    if (!data) return [];
    const { index } = data || { index: undefined };
    const [start, end] = zoomedXDomain;
    if (index! < start || index! > end) return [];
    return [data];
  }

  function getFirstMondayIndex() {
    const firstMonday = all.findIndex(({ day }) => moment(day, DATE_FORMAT).isoWeekday() === 1);
    return firstMonday;
  }

  function getChartTickValues() {
    const tickValues = times(Math.floor(all.length / 7), (i) => getFirstMondayIndex() + i * 7);
    return tickValues;
  }

  function getAll() {
    return getDataFromCurrentDomain(all);
  }

  function getDataFromCurrentDomain(data: Day[] = []) {
    const [startDay, endDay] = zoomedXDomain;
    return data.filter((d) => d.index! >= startDay && d.index! <= endDay);
  }

  function moveDomain(value: number) {
    const [minimum, maximum] = getXDomain();
    const [start, end] = zoomedXDomain as number[];

    const range = getRadius() * 2;

    let safeStartValue = Math.max(start + value, minimum!);
    let safeEndValue = Math.min(end + value, maximum!);

    const direction = value > 0 ? 'RIGHT' : 'LEFT';
    let isCurrentDayLineInView;

    if (direction === 'RIGHT' && safeEndValue - safeStartValue < range) {
      safeStartValue = safeEndValue - range;
    }

    if (direction === 'LEFT' && safeEndValue - safeStartValue < range) {
      safeEndValue = safeStartValue + range;
    }

    if (getCurrentDay()?.index! < safeStartValue || getCurrentDay()?.index! > safeEndValue) {
      isCurrentDayLineInView = false;
    } else {
      isCurrentDayLineInView = true;
    }

    setZoomedXDomain([safeStartValue, safeEndValue]);
    setIsCurrentDayLineInView(isCurrentDayLineInView);
  }

  function isCurrentDay({ day }: { day?: string } = { day: undefined }) {
    return day === present[0].day;
  }

  const victoryAreaStyle = {
    data: { fill: 'url(#myGradient)', stroke: CHART_RED, strokeWidth: 2 },
  };

  const victoryAxisStyle = {
    axis: {
      opacity: 0,
    },
    ticks: {
      opacity: 0,
    },
    grid: {
      strokeDasharray: '0 0',
    },
    tickLabels: {
      fontWeight: 'bold',
      fontSize: '13px',
      fontColor: TICK_LABEL_COLOR,
      fill: (dayNumber: number) => (isCurrentDay(all[dayNumber]) ? COLOR_BLUE : TICK_LABEL_COLOR),
    },
  };

  const referenceLineStyle = {
    data: { stroke: COLOR_BLUE },
  };

  const handleCursorChange = (value: number) => {
    setActivePoint(findClosestPointSorted(getAll(), value));
  };

  const renderPoint = ({
    point,
    color,
    size = DEFAULT_POINT_SIZE,
  }: {
    point: Day;
    color?: string;
    size?: number;
  }) => {
    const { totalSum } = point;
    const taxDay = isTaxDay(point);
    const pointColor =
      color || taxDay
        ? theme.colors.vrorange['100']
        : totalSum > liquidityDangerThreshold
        ? CHART_GREEN
        : CHART_RED;

    return (
      <VictoryScatter
        size={size}
        symbol={taxDay ? 'square' : 'circle'}
        style={{
          data: { fill: pointColor },
        }}
        data={[point]}
        {...accessors}
      />
    );
  };

  const renderDataPoints = () =>
    getAll().map((point, idx) => {
      const areSimilar = (x: number, y: number, throttle = 1) => Math.abs(x - y) < throttle;
      if (idx === 0 || idx === getAll().length - 1) return null;
      if (
        areSimilar(point.totalSum, getAll()[idx + 1].totalSum) &&
        areSimilar(point.totalSum, getAll()[idx - 1].totalSum)
      )
        return null;
      return renderPoint({
        point,
        size: isTaxDay(point) ? MEDIUM_POINT_SIZE : DEFAULT_POINT_SIZE,
      });
    });

  const renderActivePoint = () => {
    return activePoint && renderPoint({ point: activePoint, size: BIG_POINT_SIZE });
  };

  const isTaxDay = (point: Day) => {
    if (point) {
      const { taxesDetails } = point;
      const taxDay = taxesDetails !== undefined;
      return taxDay;
    }
    return false;
  };

  const createLine = ({
    elements,
    startIndex,
    endIndex,
    takeGreaterThanThreshold,
    compareValue,
  }: {
    elements: Day[];
    startIndex: number;
    endIndex: number;
    takeGreaterThanThreshold: boolean;
    compareValue: number;
  }) => {
    let index = startIndex;
    const line: Day[] = [];
    while (index <= endIndex) {
      const point: Day = elements[index];
      const totalSumIsGreaterThanCompareValue: boolean = point.totalSum > compareValue;
      if (totalSumIsGreaterThanCompareValue !== takeGreaterThanThreshold) break;
      line.push(point);
      index += 1;
    }

    return [index, line];
  };

  const getClusteredLines = () => {
    const neutralPoint = (index: number) => ({ totalSum: liquidityDangerThreshold, index });
    const countSubIndex = (prevPoint: Day, nextPoint: Day) => {
      const sum = Math.abs(prevPoint.totalSum - nextPoint.totalSum);
      return Math.abs(prevPoint.totalSum - liquidityDangerThreshold) / sum;
    };
    const elements = getAll();
    const lines = [];
    if (!(elements && elements.length)) return [];

    let takeGreaterThanThreshold = elements[0].totalSum > 0;
    let index = 0;
    const endIndex = elements.length - 1;

    while (index < endIndex) {
      const [newIndex, newLine] = createLine({
        elements,
        startIndex: index,
        endIndex,
        takeGreaterThanThreshold,
        compareValue: liquidityDangerThreshold,
      });

      lines.push({ points: newLine as Day[], isGreaterThanThreshold: takeGreaterThanThreshold });
      index = newIndex as number;
      takeGreaterThanThreshold = !takeGreaterThanThreshold;
    }

    const filteredLines = lines.filter(({ points }) => points.length);
    if (filteredLines.length === 1) return filteredLines;

    return filteredLines.map(({ points: linePoints, isGreaterThanThreshold }, idx) => {
      switch (idx) {
        case 0:
          return {
            isGreaterThanThreshold,
            points: [
              ...linePoints,
              neutralPoint(
                linePoints[linePoints.length - 1].index! +
                  countSubIndex(linePoints[linePoints.length - 1], filteredLines[idx + 1].points[0])
              ),
            ],
          };
        case filteredLines.length - 1:
          return {
            isGreaterThanThreshold,
            points: [
              neutralPoint(
                linePoints[0].index! -
                  countSubIndex(
                    linePoints[0],
                    filteredLines[idx - 1].points[filteredLines[idx - 1].points.length - 1]
                  )
              ),
              ...linePoints,
            ],
          };
        default:
          return {
            isGreaterThanThreshold,
            points: [
              neutralPoint(
                linePoints[0].index! -
                  countSubIndex(
                    linePoints[0],
                    filteredLines[idx - 1].points[filteredLines[idx - 1].points.length - 1]
                  )
              ),
              ...linePoints,
              neutralPoint(
                linePoints[linePoints.length - 1].index! +
                  countSubIndex(linePoints[linePoints.length - 1], filteredLines[idx + 1].points[0])
              ),
            ],
          };
      }
    });
  };

  const isOBSTooltipDisplayed = isNumber(liquidityDangerThreshold) && isOBSReady && isSmUp;
  const chartContainerLeftPadding = isMdUp ? 95 : 30;
  const chartContainerRightPadding = isMdUp ? 60 : 30;
  const leftArrowXPosition = isMdUp ? 0 : -10;
  const rightArrowXPosition = containerWidth - (isMdUp ? 60 : 45);

  return (
    <SC.VictoryChartContainer
      data-id={dataId}
      onMouseLeave={() => setActivePoint(null)}
      height={HEIGHT}
      padding={PADDING}
    >
      <VictoryChart
        width={containerWidth - 2 * PADDING}
        theme={VictoryTheme.material}
        containerComponent={
          <VictoryZoomVoronoiContainer
            zoomDimension="x"
            onZoomDomainChange={onDomainChange}
            minimumZoom={{ x: 20 }}
            allowZoom={false}
            zoomDomain={{ x: zoomedXDomain }}
            style={{ touchAction: 'all' }}
            onCursorChange={(value) => handleCursorChange(Number(value))}
            cursorDimension="x"
            cursorComponent={<RenderNull />}
          />
        }
        domain={getEntireDomain()}
        padding={{
          top: 20,
          bottom: 0,
          left: chartContainerLeftPadding,
          right: chartContainerRightPadding,
        }}
      >
        <AreaGradientFilter />
        <VictoryAxis
          tickValues={getChartTickValues()}
          tickFormat={getWeekNumber()}
          style={victoryAxisStyle}
          offsetY={HEIGHT - 35}
          tickLabelComponent={<VictoryLabel dx={25} />}
          height={HEIGHT}
        />
        <VictoryAxis
          dependentAxis
          tickValues={[0]}
          tickFormat={getMoney}
          style={{ ...victoryAxisStyle, grid: { opacity: 0 } }}
          offsetY={HEIGHT - 80}
          tickLabelComponent={<VictoryLabel dx={-30} />}
          height={HEIGHT}
        />
        {/* chart lines */}
        {getClusteredLines().map((line, index) => (
          <VictoryLine
            data={line.points}
            {...getVictoryLineProps({
              color: line.isGreaterThanThreshold ? CHART_GREEN : CHART_RED,
            })}
            key={index}
          />
        ))}
        {isOBSTooltipDisplayed && (
          <VictoryScatter
            data={getObsTooltipPosition()}
            {...accessors}
            dataComponent={<OBSTooltipContainer />}
          />
        )}
        <CurrentDayLine
          referenceLineStyle={referenceLineStyle}
          currentDayLineData={getCurrentDayLineData()}
          accessors={accessors}
          isCurrentDayLineInView={isCurrentDayLineInView}
        />
        <VictoryArea
          style={{ data: { stroke: DARK_GREY, strokeWidth: 1 } }}
          data={[
            { x: 0, y: 0 },
            { x: all.length - 1, y: 0 },
          ]}
        />
        <VictoryArea
          style={victoryAreaStyle}
          data={getAreaData()}
          y0={() => getBaseline()}
          {...accessors}
        />
        {/* details tooltip */}
        {activePoint && (
          <VictoryScatter
            data={[activePoint]}
            dataComponent={
              <Tooltip
                threshold={liquidityDangerThreshold}
                isTaxDay={isTaxDay(activePoint)}
                liquidityChartWidth={containerWidth}
              />
            }
            {...accessors}
          />
        )}
        {renderDataPoints()}
        {renderActivePoint()}

        <VictoryLegend
          data={[{ name: '' }]}
          x={leftArrowXPosition}
          y={HEIGHT / 2 - 28}
          dataComponent={<SC.LeftArrow onClick={() => moveDomain(-7)} width={18} height={36} />}
        />
        <VictoryLegend
          data={[{ name: '' }]}
          x={rightArrowXPosition}
          y={HEIGHT / 2 - 28}
          dataComponent={<SC.RightArrow onClick={() => moveDomain(7)} width={18} height={36} />}
        />
      </VictoryChart>
    </SC.VictoryChartContainer>
  );
};

const enhance = compose(withViewport, withVisibility, dimensions());

export default enhance(Chart as any);
