import React from 'react';

import { Header } from './SectionHeader.styled';

type SectionHeaderProps = {
  header: string;
};

const SectionHeader = ({ header }: SectionHeaderProps) => <Header>{header}</Header>;

export default SectionHeader;
