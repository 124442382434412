import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/app-settings';
import { camelizeKeys } from 'shared/utils/entity-mapper';

export const defaultState = {
  directDebitMandates: {},
  isFetched: false,
  isFetching: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        error: false,
        isFetching: true,
      };
    case FETCH_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };
    case FETCH_SUCCESS:
      const {
        data: settings,
        directDebitMandates,
        externalApps = {},
      } = camelizeKeys(action.response);

      return {
        ...defaultState,
        ...camelizeKeys(settings),
        directDebitMandates: camelizeKeys(directDebitMandates),
        externalApps: camelizeKeys(externalApps.data),
        isFetched: true,
        isFetching: false,
      };

    default:
      return state;
  }
};
