import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { DATE_FORMAT } from 'constants/datetime';
import { PIWIK_ACTION_REVENUE_ADD_CASH_PAYMENT, PIWIK_CATEGORY_REVENUE } from 'constants/piwik';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import bankTransferStyles from 'components/BankTransfer/BankTransfer.module.css';
import { DateField } from 'components/Form';
import { FormField } from 'components/Form';

import styles from './AssignmentMode.module.css';

type FormData = {
  cashDate: string;
};

type AssignmentModeOwnProps = {
  onAccept: (date: string | null) => void;
  onClose: () => void;
};

type AssignmentModeProps = AssignmentModeOwnProps &
  InjectedFormProps<FormData, AssignmentModeOwnProps>;

const AssignmentMode = ({ onAccept, onClose, handleSubmit }: AssignmentModeProps) => {
  const handleAccept = (values: FormData) => {
    piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_REVENUE_ADD_CASH_PAYMENT);
    return onAccept(values.cashDate || null);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{t('bank_transfers.assigment.assigment_mode_title')}</div>
      <div className={cx(bankTransferStyles.assigmentMode, styles.details)}>
        <div className={bankTransferStyles.detailsSection}>
          <div className={bankTransferStyles.title}>{t('revenue.form.paid_by_cash')}</div>
        </div>
        <div className={bankTransferStyles.selectSection}>
          <FormField
            name="cashDate"
            label={t(`expenses.form.paid_by_cash_date`)}
            placeholder={t(`expenses.form.paid_by_cash_date`)}
            component={DateField}
          />
        </div>
        <div className={bankTransferStyles.actionSection}>
          <button
            type="submit"
            data-id="accept-paid-by-cash-assignment"
            className={bankTransferStyles.accept}
            title={t('bank_transfers.select.actions.accept_suggestion')}
            onClick={handleSubmit(handleAccept)}
          />
          <button
            type="button"
            className={bankTransferStyles.cancel}
            onClick={onClose}
            title={t('bank_transfers.select.actions.reject_suggestion')}
          />
        </div>
      </div>
    </div>
  );
};

export default reduxForm<FormData, AssignmentModeOwnProps>({
  form: 'AssignmentMode',
  initialValues: {
    cashDate: moment().format(DATE_FORMAT),
  },
})(AssignmentMode);
