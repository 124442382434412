import {
  SET_PREFILLED_REGISTRATION_VALUES,
  TOGGLE_DISCLAIMER,
  TOGGLE_RECEIVE_OFFERS,
  TOGGLE_TERMS_OF_USE,
} from 'constants/registration';

export const defaultState = {
  prefilledRegistrationValues: {},
  termsOfUse: false,
  receiveOffers: false,
  disclaimerModal: false,
  isBankGroupCustomer: false,
};

export const getPrefilledRegistrationData = (state) =>
  state.registration.prefilledRegistrationValues;

export default (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_TERMS_OF_USE:
      return {
        ...state,
        termsOfUse: !state.termsOfUse,
      };
    case TOGGLE_RECEIVE_OFFERS:
      return {
        ...state,
        receiveOffers: !state.receiveOffers,
      };
    case TOGGLE_DISCLAIMER:
      return {
        ...state,
        disclaimerModal: !state.disclaimerModal,
      };
    case SET_PREFILLED_REGISTRATION_VALUES:
      return {
        ...state,
        prefilledRegistrationValues: action.prefilledRegistrationValues,
      };
    default:
      return state;
  }
};
