import React from 'react';

const IconCancel = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12pt"
    height="12pt"
    viewBox="0 0 12 12"
    version="1.1"
  >
    <g id="surface1">
      <path
        style={{ stroke: 'none', fillRule: 'evenodd', fillOpacity: 1 }}
        d="M 9.644531 5.980469 C 9.644531 5.261719 9.449219 4.601562 9.058594 4.003906 L 4.011719 9.046875 C 4.621094 9.445312 5.285156 9.644531 6 9.644531 C 6.496094 9.644531 6.96875 9.546875 7.417969 9.351562 C 7.863281 9.15625 8.253906 8.898438 8.578125 8.570312 C 8.902344 8.246094 9.164062 7.855469 9.355469 7.402344 C 9.546875 6.949219 9.644531 6.476562 9.644531 5.980469 Z M 2.953125 7.980469 L 8.007812 2.933594 C 7.40625 2.527344 6.738281 2.324219 6 2.324219 C 5.339844 2.324219 4.730469 2.488281 4.171875 2.8125 C 3.613281 3.136719 3.171875 3.582031 2.847656 4.144531 C 2.519531 4.707031 2.355469 5.320312 2.355469 5.980469 C 2.355469 6.703125 2.554688 7.371094 2.953125 7.980469 Z M 11.144531 5.980469 C 11.144531 6.679688 11.007812 7.351562 10.734375 7.988281 C 10.460938 8.628906 10.097656 9.175781 9.640625 9.636719 C 9.183594 10.097656 8.636719 10.460938 8 10.734375 C 7.363281 11.007812 6.695312 11.144531 6 11.144531 C 5.304688 11.144531 4.636719 11.007812 4 10.734375 C 3.363281 10.460938 2.816406 10.097656 2.359375 9.636719 C 1.902344 9.175781 1.539062 8.628906 1.265625 7.988281 C 0.992188 7.351562 0.855469 6.679688 0.855469 5.980469 C 0.855469 5.277344 0.992188 4.609375 1.265625 3.972656 C 1.539062 3.339844 1.902344 2.789062 2.359375 2.332031 C 2.816406 1.871094 3.363281 1.503906 4 1.230469 C 4.636719 0.960938 5.304688 0.824219 6 0.824219 C 6.695312 0.824219 7.363281 0.960938 8 1.230469 C 8.636719 1.503906 9.183594 1.871094 9.640625 2.332031 C 10.097656 2.789062 10.460938 3.339844 10.734375 3.972656 C 11.007812 4.609375 11.144531 5.277344 11.144531 5.980469 Z M 11.144531 5.980469 "
      />
    </g>
  </svg>
);

export default IconCancel;
