import React from 'react';

import { CATEGORY_DASHBOARD, EDIT_WIDGETS, SAVE_WIDGETS } from 'constants/piwik';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import { Dashboard } from 'types/entities/Dashboard';
import BankTransfersIcon from 'redesign/components/atoms/Icons/BankTransfers';
import DashboardIcon from 'redesign/components/atoms/Icons/Dashboard';
import FinanceStatusIcon from 'redesign/components/atoms/Icons/FinanceStatus';
import LiquiChartIcon from 'redesign/components/atoms/Icons/LiquiChart';
import MonthlyOverviewIcon from 'redesign/components/atoms/Icons/MonthlyOverview';

import Customizer from './components/Customizer';
import * as SC from './CustomisationWidgets.styled';
import { CustomizeType } from './types';

type TWidgets = keyof Dashboard;

interface ICustomisationWidgets {
  editMode: boolean;
  widgetsState: Dashboard;
  handleWidgetStates: (name: TWidgets) => void;
  setEditMode: (x: boolean) => void;
  resetDashboard: () => void;
  saveDashboard: () => void;
}

const CustomisationWidgets = ({
  editMode,
  setEditMode,
  widgetsState,
  handleWidgetStates,
  resetDashboard,
  saveDashboard,
}: ICustomisationWidgets) => {
  const widgets: CustomizeType[] = [
    {
      widgetId: 'kpi',
      icon: <FinanceStatusIcon />,
      title: t('dashboard.customisation_widgets.total_overview.title'),
      description: t('dashboard.customisation_widgets.total_overview.description'),
      isActivated: widgetsState?.kpi,
      handleSwitch: () => handleWidgetStates('kpi'),
    },
    {
      widgetId: 'monthlyOverview',
      icon: <MonthlyOverviewIcon />,
      title: t('dashboard.customisation_widgets.monthly_overview.title'),
      description: t('dashboard.customisation_widgets.monthly_overview.description'),
      isActivated: widgetsState?.monthlyOverview,
      handleSwitch: () => handleWidgetStates('monthlyOverview'),
    },
    {
      widgetId: 'liquiChart',
      icon: <LiquiChartIcon />,
      title: t('dashboard.customisation_widgets.liqui_development.title'),
      description: t('dashboard.customisation_widgets.liqui_development.description'),
      isActivated: widgetsState?.liquiChart,
      handleSwitch: () => handleWidgetStates('liquiChart'),
    },
    {
      widgetId: 'bankAccounts',
      icon: <SC.BankIcon />,
      title: t('dashboard.customisation_widgets.account_overview.title'),
      description: t('dashboard.customisation_widgets.account_overview.description'),
      isActivated: widgetsState?.bankAccounts,
      handleSwitch: () => handleWidgetStates('bankAccounts'),
    },
    {
      widgetId: 'bankTransfers',
      icon: <BankTransfersIcon />,
      title: t('dashboard.customisation_widgets.current_bank_transactions.title'),
      description: t('dashboard.customisation_widgets.current_bank_transactions.description'),
      isActivated: widgetsState?.bankTransfers,
      handleSwitch: () => handleWidgetStates('bankTransfers'),
    },
  ];

  const isPristine = widgetsState?.pristine;

  const enableEditMode = () => {
    setEditMode(true);
    piwikHelpers.trackEvent(CATEGORY_DASHBOARD, EDIT_WIDGETS);
  };

  const saveDashboardSettings = () => {
    saveDashboard();
    piwikHelpers.trackEvent(CATEGORY_DASHBOARD, SAVE_WIDGETS);
  };

  return editMode ? (
    <Customizer
      widgets={widgets}
      saveDashboard={saveDashboardSettings}
      resetDashboard={resetDashboard}
    />
  ) : (
    <>
      {isPristine && (
        <SC.HelperText>
          <SC.Heading>{t('dashboard.helper_text.heading')}</SC.Heading>
          <SC.Description>{t('dashboard.helper_text.description')}</SC.Description>
        </SC.HelperText>
      )}

      <SC.EditButton
        isWidgetsDisabled={isPristine}
        $variant="outlined"
        onClick={enableEditMode}
        data-id="dashboard-edit-button"
      >
        <div>
          <SC.IconWrapper>
            <DashboardIcon />
          </SC.IconWrapper>
          {!isPristine
            ? t('dashboard.customisation_widgets.edit_dashboard')
            : t('dashboard.customisation_widgets.add_more_widgets')}
        </div>
      </SC.EditButton>
    </>
  );
};

export default CustomisationWidgets;
