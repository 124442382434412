import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { togglePaidByCash as paidByCashIncomingInvoice } from 'actions/incoming-invoices';
import { togglePaidByCash as paidByCashOugoingInvoice } from 'actions/outgoing-invoice';
import { incomingInvoiceSelector, outgoingInvoiceSelector } from 'reducers/form';
import isPressedEnter from 'shared/utils/keyboard-events';
import I18n from 'components/I18n';

import styles from './PaidByCash.module.css';

const PaidByCash = ({ handleOptionSelect, fullyPaid, canDelete, ...option }) => {
  const onClick = () => {
    handleOptionSelect(option);
  };

  return (
    <div
      onClick={onClick}
      role="button"
      onKeyPress={isPressedEnter(() => onClick())}
      className={`${styles.bankTransfer} Select-option`}
    >
      <div data-id="mark-as-paid-by-cash-option" className={styles.creditorName}>
        <I18n t="revenue.form.paid_by_cash" />
      </div>
    </div>
  );
};

PaidByCash.propTypes = {
  togglePaidByCashOugoingInvoice: PropTypes.func.isRequired,
  togglePaidByCashIncomingInvoice: PropTypes.func.isRequired,
  paidByCash: PropTypes.bool,
  fullyPaid: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool,
  handleOptionSelect: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  id: outgoingInvoiceSelector(state, 'id') || incomingInvoiceSelector(state, 'id'),
  paidByCash:
    outgoingInvoiceSelector(state, 'paidByCash') || incomingInvoiceSelector(state, 'paidByCash'),
  fullyPaid: outgoingInvoiceSelector(state, 'paid'),
  canDelete: !incomingInvoiceSelector(state, 'paid'),
});

const mapDispatchToProps = (dispatch) => ({
  togglePaidByCashOugoingInvoice: (...args) => dispatch(paidByCashOugoingInvoice(...args)),
  togglePaidByCashIncomingInvoice: (...args) => dispatch(paidByCashIncomingInvoice(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaidByCash);
