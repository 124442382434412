import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { EDIT_MODE, NEW_MODE, SHOW_MODE } from 'constants/common/crud';

import Contracts from './Contracts';
import Creator from './Creator/Creator';

const Routes = () => {
  const match = useRouteMatch();

  if (!match) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={match.path}>
        <Contracts />
      </Route>
      <Route exact path={`${match.path}/expenses/loan/new`}>
        <Creator crudMode={NEW_MODE} contractType="loan" />
      </Route>
      <Route exact path={`${match.path}/expenses/loan/:id/edit`}>
        <Creator crudMode={EDIT_MODE} contractType="loan" />
      </Route>
      <Route exact path={`${match.path}/expenses/loan/:id`}>
        <Creator crudMode={SHOW_MODE} contractType="loan" />
      </Route>
      <Route exact path={`${match.path}/expenses/basic/new`}>
        <Creator crudMode={NEW_MODE} contractType="basic" />
      </Route>
      <Route exact path={`${match.path}/expenses/basic/:id/edit`}>
        <Creator crudMode={EDIT_MODE} contractType="basic" />
      </Route>
      <Route exact path={`${match.path}/expenses/basic/:id`}>
        <Creator crudMode={SHOW_MODE} contractType="basic" />
      </Route>
      <Route exact path={`${match.path}/revenues/basic/new`}>
        <Creator crudMode={NEW_MODE} contractType="basic" isRevenue />
      </Route>
      <Route exact path={`${match.path}/revenues/basic/:id/edit`}>
        <Creator crudMode={EDIT_MODE} contractType="basic" isRevenue />
      </Route>
      <Route exact path={`${match.path}/revenues/basic/:id`}>
        <Creator crudMode={SHOW_MODE} contractType="basic" isRevenue />
      </Route>
    </Switch>
  );
};

export default Routes;
