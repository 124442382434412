import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { func, shape, string } from 'prop-types';
import { compose } from 'recompose';

import { fetchDeliveryNote as fetchDeliveryNoteAction } from 'actions/delivery-note';
import { fetchDeliveryNoteLineItems as fetchDeliveryNoteLineItemsAction } from 'actions/delivery-note/line-items';
import { EDIT_MODE } from 'constants/common/crud';
import { FROM_DUPLICATE_QUERY_PARAM } from 'constants/delivery-note';
import { FROM_OUTGOING_INVOICE_QUERY_PARAM } from 'constants/outgoing-invoice';
import { withWarningAlert } from 'shared/hoc';
import { t } from 'shared/utils';
import LoadingIcon from 'components/LoadingIcon';
import DeliveryNoteCreator from 'features/deliveryNotes/DeliveryNoteCreator/DeliveryNoteCreator';

class DeliveryNoteEdit extends Component {
  static propTypes = {
    deliveryNoteId: string.isRequired,
    fetchDeliveryNote: func.isRequired,
    fetchDeliveryNoteLineItems: func.isRequired,
    location: shape({
      search: string,
    }),
  };

  componentDidMount() {
    const { deliveryNoteId } = this.props;
    this.props.fetchDeliveryNote(deliveryNoteId);
    this.props.fetchDeliveryNoteLineItems(deliveryNoteId);
  }

  render() {
    const { location, isFetching, isFetched } = this.props;
    const isFromOutgoingInvoice = location.search.includes(FROM_OUTGOING_INVOICE_QUERY_PARAM);
    const isFromDuplicate = location.search.includes(FROM_DUPLICATE_QUERY_PARAM);

    if (isFetching) return <LoadingIcon />;
    if (!isFetched) return null;

    return (
      <DeliveryNoteCreator
        crudMode={EDIT_MODE}
        isFromOutgoingInvoice={isFromOutgoingInvoice}
        isFromDuplicate={isFromDuplicate}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isFetching: state.deliveryNote.isFetching,
  isFetched: state.deliveryNote.isFetched,
  deliveryNoteId: ownProps.match.params.id,
  buttonLabel: t('empty_entry_pages.delivery_notes.delivery_notes_warning_box.button_label'),
  content: t('empty_entry_pages.delivery_notes.delivery_notes_warning_box.content_1'),
  dataId: 'DeliveryNotes:orange-warning-container',
});

const mapDispatchToProps = (dispatch) => ({
  fetchDeliveryNote: (id) => dispatch(fetchDeliveryNoteAction(id)),
  fetchDeliveryNoteLineItems: (id) => dispatch(fetchDeliveryNoteLineItemsAction(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withWarningAlert
)(DeliveryNoteEdit);
