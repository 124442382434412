import { get, last } from 'lodash';
import { combineReducers } from 'redux';

import {
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  INDEX_SORT,
  NAMESPACE,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/bank-transfer';
import {
  CLEAR_FILTERS,
  SET_DATE_RANGE,
  SET_QUERY_PARAM,
  SET_QUICK_FILTER,
} from 'constants/common/filters';
import { parsedFiltersReducer } from 'reducers/common/filters';
import { getPaginationReducer } from 'reducers/pagination';
import { createFilteredReducer, resolveInvoiceType, toMoment } from 'shared/utils';

export const extractInvoiceMeta = (relationships) => {
  const incomingInvoices = get(relationships, 'incoming-invoices.data', []);
  const outgoingInvoices = get(relationships, 'outgoing-invoices.data', []);
  const invoice = last([...incomingInvoices, ...outgoingInvoices]);

  return {
    invoiceId: get(invoice, 'id'),
    invoiceType: resolveInvoiceType(get(invoice, 'type')),
    invoicePaid: get(invoice, 'paid'),
  };
};

export const orderBankTransfers = ({ transfers, query }) => {
  const predicate = (transfer) =>
    transfer.creditorName.toLowerCase().startsWith(query) ||
    transfer.amount.toString().startsWith(query);

  return [
    ...transfers.filter((transfer) => predicate(transfer)),
    ...transfers.filter((transfer) => !predicate(transfer)),
  ];
};

export const parseFilteredBankTransfers = ({ response, query = '' }) => {
  const transfers = response.data.map((x) => ({
    ...x.attributes,
    ...extractInvoiceMeta(x.relationships),
  }));

  return orderBankTransfers({ transfers, query });
};

const formatBankTransfer = ({ attributes, relationships }) => ({
  ...attributes,
  date: toMoment(attributes.date),
  ...extractInvoiceMeta(relationships),
});

const pagination = getPaginationReducer([FETCH_SUCCESS, UPDATE_SUCCESS]);

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
    case UPDATE_REQUEST:
      return true;

    case FETCH_SUCCESS:
    case FETCH_FAILURE:
    case UPDATE_SUCCESS:
    case UPDATE_FAILURE:
      return false;

    default:
      return state;
  }
};

const data = (state = [], action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return action.response.data.map(formatBankTransfer);

    case UPDATE_SUCCESS:
      return [...state, ...action.response.data.map(formatBankTransfer)];

    default:
      return state;
  }
};

const defaultSortingState = {
  column: 'date',
  direction: 'DESC',
};

const sorting = (state = defaultSortingState, action) => {
  switch (action.type) {
    case INDEX_SORT: {
      let direction = 'ASC';
      if (state.column === action.column && state.direction === 'ASC') direction = 'DESC';
      return {
        column: action.column,
        direction,
      };
    }
    default:
      return state;
  }
};

const initialFiltersState = { quickFilter: {}, searchFilters: {} };

// eslint-disable-next-line consistent-return
const filters = (state = initialFiltersState, action) => {
  switch (action.type) {
    case SET_QUICK_FILTER:
      return {
        ...state,
        quickFilter: action.payload,
      };
    case SET_QUERY_PARAM:
      return {
        ...state,
        searchFilters: { ...state.searchFilters, [action.payload.param]: action.payload.value },
      };

    case SET_DATE_RANGE:
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          [action.payload.param]: {
            ...action.payload.values,
          },
        },
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        searchFilters: {},
      };
    default:
      return state;
  }
};

export default combineReducers({
  data,
  pagination,
  isFetching,
  sorting,
  filters: createFilteredReducer(filters, (action) => action.name === NAMESPACE),
  parsedFilters: createFilteredReducer(parsedFiltersReducer, (action) => action.name === NAMESPACE),
});
