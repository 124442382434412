export enum Status {
  Open = 'open',
  Done = 'done',
}

export type Task = {
  id?: number;
  description: string;
  dueDate?: string;
  invoiceId: number;
  partnerName: string;
  number: string;
  amount: number;
  status: Status;
};

export type CustomTask = {
  id: number;
  description: string;
  dueDate?: string;
  status: Status;
  updatedAt: string;
};
