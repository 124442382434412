import React, { useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useClickAway } from 'react-use';
import { noop } from 'lodash';

import InfoIcon from 'redesign/components/atoms/Icons/Info';
import PopperArrow from 'redesign/components/atoms/PopperArrow/PopperArrow';

import * as SC from './Tooltip.styled';

type TooltipPlacement = 'left' | 'right' | 'top' | 'bottom';

export enum TooltipPlacements {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  TOP_END = 'top-end',
  BOTTOM = 'bottom',
}

export interface TooltipProps {
  message?: string;
  className?: string;
  tooltipPlacement?: TooltipPlacement;
  clickHandler?: () => void;
  onMouseEnter?: () => void;
}

const Tooltip = ({
  message,
  className,
  tooltipPlacement = TooltipPlacements.TOP,
  clickHandler = noop,
  onMouseEnter = noop,
}: TooltipProps) => {
  const wrapperRef = useRef(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: tooltipPlacement,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start', 'bottom-start'],
        },
      },
    ],
  });

  const handleHidePicker = useCallback(() => {
    setIsTooltipVisible(false);
  }, []);

  useClickAway(wrapperRef, handleHidePicker);

  return (
    <div
      ref={wrapperRef}
      className={className}
      onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
    >
      <SC.Icon
        data-id="Tooltip"
        onMouseOver={() => setIsTooltipVisible(true)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setIsTooltipVisible(false)}
        onTouchEnd={() => setIsTooltipVisible((prevIsTooltipVisible) => !prevIsTooltipVisible)}
        onFocus={() => setIsTooltipVisible(true)}
        onBlur={() => setIsTooltipVisible(false)}
        onClick={clickHandler}
        ref={setReferenceElement}
      >
        <InfoIcon />
      </SC.Icon>

      {isTooltipVisible && message && (
        <SC.Tooltip ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <div>{message}</div>
          <PopperArrow ref={setArrowElement} style={styles.arrow} />
        </SC.Tooltip>
      )}
    </div>
  );
};

export default Tooltip;
