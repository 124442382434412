import React from 'react';
import { arrayOf, node } from 'prop-types';

import ActionPanel from 'components/ActionPanel';

import styles from './PaymentCreatorActions.module.css';

const PaymentCreatorActions = ({ children = [] }) => (
  <div className={styles.main}>
    <ActionPanel>{children}</ActionPanel>
  </div>
);

PaymentCreatorActions.propTypes = {
  children: arrayOf(node),
};

export default PaymentCreatorActions;
