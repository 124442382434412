export const companyDetailsSelector = (state) => state.company.details;

export const companySmallEntrepreneurSelector = (state) => state.company.details.smallEntrepreneur;

export const taxConsultantNameSelector = (state) => companyDetailsSelector(state).taxConsultantName;

export const taxConsultantEmailSelector = (state) =>
  companyDetailsSelector(state).taxConsultantEmail;

export const taxConsultantPhoneSelector = (state) =>
  companyDetailsSelector(state).taxConsultantPhone;

export const exportPasswordSelector = (state) => companyDetailsSelector(state).exportPassword;

export const companyIsFetchingSelector = (state) => state.company.isFetching;
