import { FETCH_SUCCESS, SET_ACTIVE_MESSAGE } from 'constants/message-inbox';
import { camelizeKeysDeep } from 'shared/utils/entity-mapper';

const defaultState = {
  messages: [],
  messagesTotal: 0,
  unreadMessagesTotal: 0,
  activeMessageId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS: {
      const res = camelizeKeysDeep(action.response.data);
      const {
        messages,
        meta: { messagesTotal, unreadMessagesTotal },
      } = res;

      return {
        ...state,
        messages,
        messagesTotal,
        unreadMessagesTotal,
        etag: action.headers.etag,
      };
    }

    case SET_ACTIVE_MESSAGE: {
      return {
        ...state,
        activeMessageId: action.payload.activeMessageId,
      };
    }

    default:
      return state;
  }
};
