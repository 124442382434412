import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { incomingInvoiceDetailsSelector } from 'selectors/incomingInvoice';
import { formatMoney, t } from 'shared/utils';

import styles from './InvoiceSumSection.module.css';

const InvoiceSumSection = ({ vatAmount, nettoAmount, grossAmount }) => (
  <div className={styles.main}>
    <div className={styles.section}>
      <div className={styles.sum}>
        <div className={styles.name}>{t('expenses.sum_section.subtotal')}</div>
        <div className={styles.amount} id="totalNetAmount" data-id="IncomingInvoice:amount-net">
          {formatMoney(nettoAmount)}
        </div>
      </div>
      <div className={styles.sum}>
        <div className={styles.name}>{t('expenses.sum_section.vattotal')}</div>
        <div className={styles.amount} id="totalVatAmount" data-id="IncomingInvoice:amount-tax">
          {formatMoney(vatAmount)}
        </div>
      </div>
    </div>
    <div className={styles.section}>
      <div className={cx(styles.sum, styles.total)}>
        <div className={styles.name}>{t('expenses.sum_section.total')}</div>
        <div className={styles.amount} id="totalGrossAmount" data-id="IncomingInvoice:amount-gross">
          {formatMoney(grossAmount)}
        </div>
      </div>
    </div>
  </div>
);

InvoiceSumSection.propTypes = {
  vatAmount: PropTypes.number.isRequired,
  nettoAmount: PropTypes.number.isRequired,
  grossAmount: PropTypes.number.isRequired,
};

export default connect((state) => ({
  vatAmount: incomingInvoiceDetailsSelector(state).totalVatAmount,
  nettoAmount: incomingInvoiceDetailsSelector(state).totalNetAmount,
  grossAmount: incomingInvoiceDetailsSelector(state).totalGrossAmount,
}))(InvoiceSumSection);
