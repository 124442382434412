import React, { FunctionComponent } from 'react';
import cx from 'classnames';

import tableStyles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';

import localStyles from './ActionButton.module.css';

type ActionButtonTypes = 'accept' | 'edit' | 'delete';

export const ACTION_BUTTON_TYPES: {
  [key: string]: ActionButtonTypes;
} = {
  EDIT: 'edit',
  DELETE: 'delete',
};

type ActionButtonProps = {
  type?: ActionButtonTypes;
};

const getButtonText = (type: ActionButtonTypes) => {
  let name = '';
  switch (type) {
    case ACTION_BUTTON_TYPES.EDIT:
      name = 'edit';
      break;
    case ACTION_BUTTON_TYPES.DELETE:
      name = 'delete';
      break;
    default:
      name = '';
  }

  return t(`shared.${name}`);
};

const ActionButton: FunctionComponent<ActionButtonProps> = ({
  type = ACTION_BUTTON_TYPES.EDIT,
  ...restProps
}) => (
  <button className={cx(localStyles.button, tableStyles.action)} type="button" {...restProps}>
    <div
      className={cx(localStyles.icon, {
        [localStyles.editIcon]: type === ACTION_BUTTON_TYPES.EDIT,
        [localStyles.deleteIcon]: type === ACTION_BUTTON_TYPES.DELETE,
      })}
    />
    <div>{getButtonText(type)}</div>
  </button>
);

export default ActionButton;
