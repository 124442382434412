import React, { StrictMode } from 'react';
import CacheBuster from 'react-cache-buster';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import Routes from 'routes';
import { history } from 'routes/history';
import { onBeforeLift } from 'shared/utils/app';
import HubSpotScript from 'shared/utils/hubspot';
import configurePiwikHistory from 'shared/utils/piwik';
import { persistor, store } from 'store';
import GlobalStyles from 'redesign/styles/GlobalStyles/GlobalStyles';
import theme from 'redesign/styles/theme';

import packageFile from '../../../../package.json';
import handleRouterListeners from './routerListeners';

configurePiwikHistory(history, store);

handleRouterListeners(history, store);

moment.locale('de');

const { version } = packageFile;

const App = () => (
  <CacheBuster
    currentVersion={version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
  >
    <StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor} onBeforeLift={onBeforeLift}>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <CookiesProvider>
              <ConnectedRouter history={history}>
                <Routes />
                <div id="hubspotScript" style={{ marginLeft: '20px' }}>
                  <HubSpotScript />
                </div>
              </ConnectedRouter>
            </CookiesProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </StrictMode>
  </CacheBuster>
);

export default App;
