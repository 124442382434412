import React, { useCallback, useState } from 'react';

import { resendConfirmationEmail } from 'actions/auth';
import {
  EMAIL_VERIFICATION_INFOBAR_CLOSE,
  EMAIL_VERIFICATION_INFOBAR_RESEND,
} from 'constants/piwik';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import Infobar from 'redesign/components/atoms/Infobar/Infobar';

import { trackEvent } from './utils';

type AccountNotConfirmedProps = {
  onClose: () => void;
};

const AccountNotConfirmed = ({ onClose }: AccountNotConfirmedProps) => {
  const dispatch = useAppDispatch();
  const [isSent, setIsSent] = useState(false);
  const key = isSent ? 'sent' : 'request';

  const handleResendConfirmationEmail = useCallback(() => {
    trackEvent(EMAIL_VERIFICATION_INFOBAR_RESEND);
    setIsSent(true);
    dispatch(resendConfirmationEmail());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    trackEvent(EMAIL_VERIFICATION_INFOBAR_CLOSE);
    onClose();
  }, [onClose]);

  return (
    <Infobar
      color="red"
      title={t(`redesign.organisms.global_notifications.account_not_confirmed.${key}.title`)}
      description={t(
        `redesign.organisms.global_notifications.account_not_confirmed.${key}.description`
      )}
      onButtonClick={handleResendConfirmationEmail}
      buttonText={t(`redesign.organisms.global_notifications.account_not_confirmed.${key}.button`)}
      buttonIsDisabled={isSent}
      onCloseClick={handleClose}
    />
  );
};

export default AccountNotConfirmed;
