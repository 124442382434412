import React, { Component, Fragment } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { setMainPaymentBankAccount as setMainPaymentBankAccountAction } from 'actions/bank-account';
import { showNotification as showNotificationAction } from 'actions/notification';
import { fetchUserPlan as fetchUserPlanAction } from 'actions/payment-plan';
import { FETCH_BANK_ACCOUNTS_SUCCESS } from 'constants/incoming-invoice/payments';
import { noBankAccount } from 'notifications/payment-plans';
import { t } from 'shared/utils';
import { mainBankAccount } from 'shared/utils/main-bank-account';
import InfoBox from 'components/InfoBox/InfoBox';

import BankAccountSelectField from '../components/BankAccountSelectField/BankAccountSelectField';
import Buttons from '../components/Buttons/Buttons';
import Header from '../components/Header/Header';
import PriceInfo from '../components/PriceInfo/PriceInfo';

import styles from './BookPremium.module.css';

class BookPremium extends Component {
  state = {
    setMainPaymentBankAccountLoading: false,
  };

  selectAccount = (selectedAccount = {}) => {
    const { paymentsBankAccounts, setMainPaymentBankAccount, fetchUserPlan } = this.props;
    const currentMainAccount = mainBankAccount(paymentsBankAccounts) || {};

    if (currentMainAccount.accountId === selectedAccount.accountId) return;

    this.setState({ setMainPaymentBankAccountLoading: true });

    setMainPaymentBankAccount(selectedAccount.id).then((response) => {
      if (response.type === FETCH_BANK_ACCOUNTS_SUCCESS) {
        fetchUserPlan();
      }
      this.setState({ setMainPaymentBankAccountLoading: false });
    });
  };

  moveForward = () => {
    const { moveForward, paymentsBankAccounts, showNotification } = this.props;

    const noAccountsPresent = isEmpty(paymentsBankAccounts);

    if (noAccountsPresent) {
      return showNotification();
    }
    return moveForward();
  };

  render() {
    const {
      addBankAccount,
      paymentsBankAccounts,
      isPaymentsBankAccountsFetching,
      onClose,
      currentPaymentPlanDetails: { vrMainBankAccount },
    } = this.props;
    const { setMainPaymentBankAccountLoading } = this.state;

    const forwardButtonLabel = vrMainBankAccount
      ? t('profile.payment_plan.book_premium.button_forward_vr')
      : t('profile.payment_plan.book_premium.button_forward');

    return (
      <Fragment>
        <Header
          title={t('profile.payment_plan.book_premium.header_1')}
          bold={t('profile.payment_plan.book_premium.header_2')}
          description={t('profile.payment_plan.book_premium.header_3')}
        />
        <PriceInfo isVrBankCustomer={vrMainBankAccount} />
        <Row>
          <Col xs={12} lg={8} className={styles.selectContainer}>
            <BankAccountSelectField
              addBankAccount={addBankAccount}
              name="accountId"
              bankAccounts={paymentsBankAccounts}
              component={BankAccountSelectField}
              onSelect={this.selectAccount}
              disabled={isPaymentsBankAccountsFetching || setMainPaymentBankAccountLoading}
            />
          </Col>
        </Row>
        <InfoBox label={t('profile.payment_plan.book_premium.info_box')} />
        <Buttons
          backLabel={t('profile.payment_plan.book_premium.button_back')}
          forwardLabel={forwardButtonLabel}
          onClose={onClose}
          onSubmit={this.moveForward}
          dataIds={{ forwardButton: 'PaymentPlans:bookPremiumContinue', backButton: 'back-button' }}
        />
      </Fragment>
    );
  }
}

BookPremium.propTypes = {
  paymentsBankAccounts: arrayOf(
    shape({
      accountId: string,
      action: shape({
        sync: bool,
      }),
      balance: number,
      bankCode: string,
      bankIdentifier: string,
      bankName: string,
      bic: string,
      credentials: arrayOf(
        shape({
          label: string,
          masked: bool,
        })
      ),
      creditLine: number,
      fetchingInProgress: bool,
      fetchingSuccessful: bool,
      figoBalanceDate: string,
      iban: string,
      id: string,
      main: bool,
      name: string,
      number: string,
      paymentApplicable: bool,
      savePin: bool,
      showOnInvoice: bool,
      updatedAt: string,
    })
  ),
  currentPaymentPlanDetails: shape({
    id: string,
    defaultPrice: number,
    discountAmount: number,
    currentPlanEndDate: string,
    name: string,
    onTrial: bool,
    uid: string,
    vrMainBankAccount: bool,
    price: number,
  }),
  onClose: func.isRequired,
  moveForward: func.isRequired,
  addBankAccount: func.isRequired,
  showNotification: func.isRequired,
  setMainPaymentBankAccount: func.isRequired,
  fetchUserPlan: func.isRequired,
  isPaymentsBankAccountsFetching: bool.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  showNotification: () => dispatch(showNotificationAction(noBankAccount)),
  setMainPaymentBankAccount: (...args) => dispatch(setMainPaymentBankAccountAction(...args)),
  fetchUserPlan: () => dispatch(fetchUserPlanAction()),
});

export default connect(null, mapDispatchToProps)(BookPremium);
