import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import paths from 'routes/paths';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';

import { CATEGORY_CONTRACTS } from '../../constants';
import Icon from './Icon';

import styles from './InfoBanner.module.css';

const trackRedirect = () => piwikHelpers.trackEvent(CATEGORY_CONTRACTS, 'Bank-Banner');

const InfoBanner = () => (
  <div className={cx(styles.card, styles.columns)}>
    <div className={styles.left}>
      <Icon className={styles.icon} />
    </div>
    <div className={styles.right}>
      <div>
        <span className={cx(styles.heading, styles.highlighted)}>
          {t('contracts.new.info_banner.heading.1')}
        </span>
        <span className={styles.heading}>{t('contracts.new.info_banner.heading.2')}</span>
      </div>
      <div className={styles.text}>{t('contracts.new.info_banner.text')}</div>
      <Link
        className={styles.link}
        to={paths.bankAccounts}
        onClick={trackRedirect}
        data-id="link-bank-account"
      >
        {t('contracts.new.info_banner.link')}
      </Link>
    </div>
  </div>
);

export default InfoBanner;
