import { JsonApiResponse } from 'json-api-normalizer';

import { CustomTask } from 'types/entities/Task';

import api from '../../index';

type NewTask = {
  description: string;
  dueDate?: string;
};

export const addCustomTask = (task: NewTask) => {
  const url = '/me/customer_notifications/tasks';

  return api.post<JsonApiResponse>(url, {
    description: task.description,
    due_date: task.dueDate,
  });
};

export const deleteCustomTask = (id: string) => {
  const url = `/me/customer_notifications/tasks/${id}`;

  return api.delete<JsonApiResponse>(url);
};

export const updateCustomTask = (task: CustomTask) => {
  const url = `/me/customer_notifications/tasks/${task.id}`;

  return api.put<JsonApiResponse>(url, {
    description: task.description,
    due_date: task.dueDate,
    status: task.status,
  });
};
