import React from 'react';
import cx from 'classnames';
import { snakeCase } from 'lodash';
import { number, string } from 'prop-types';

import { BankTransferStatuses } from 'constants/statuses';
import { t } from 'shared/utils';
import { toFixed } from 'shared/utils/number/number';
import FullyAssignedStatusIcon from 'components/StatusIcons/BankTransfer/FullyAssignedStatusIcon';
import PartlyAssignedStatusIcon from 'components/StatusIcons/BankTransfer/PartlyAssignedStatusIcon';
import UnassignedStatusIcon from 'components/StatusIcons/BankTransfer/UnassignedStatusIcon';

import styles from './BankTransferStatusBadge.module.css';

const getBankTransferStatusIcon = (status) => {
  switch (status) {
    case BankTransferStatuses.UNASSIGNED:
      return <UnassignedStatusIcon />;
    case BankTransferStatuses.PARTLY_ASSIGNED:
      return <PartlyAssignedStatusIcon />;
    case BankTransferStatuses.FULLY_ASSIGNED:
      return <FullyAssignedStatusIcon />;
    default:
      return null;
  }
};

const BankTransferStatusBadge = ({ status, unusedAmount = 0, dataId = '' }) => (
  // Outer div required for the component to work
  <div data-id={dataId}>
    <div className={cx(styles.status, styles[`status-${status}`])}>
      {getBankTransferStatusIcon(status)}
      <span className={styles['status-text']} data-id="assigned-transfer-status">
        {t(`bank_transfers.bank_transfer_status.${snakeCase(status)}`)}
      </span>
    </div>
    {unusedAmount !== 0 && (
      <div className={styles.unusedAmount}>
        {t('bank_transfers.unused')}: ({toFixed(unusedAmount)} €)
      </div>
    )}
  </div>
);

BankTransferStatusBadge.propTypes = {
  status: string.isRequired,
  unusedAmount: number,
  dataId: string,
};

export default BankTransferStatusBadge;
