import { connect } from 'react-redux';

import { createOutgoingLineItem } from 'actions/outgoing-invoice/line-items';
import { TYPE_REVENUE } from 'constants/row-types';
import { productCatalogItemsEnabledHelper } from 'routes/accesses';

import NewItemRow from '../LineItems/NewItemRow';

const mapStateToProps = (state) => ({
  invoiceId: state.outgoingInvoice.details.id,
  insertedAsGross: state.outgoingInvoice.details.insertedAsGross,
  smallEntrepreneur: state.company.details.smallEntrepreneur,
  productCatalogItemsEnabled: productCatalogItemsEnabledHelper(state),
  lineCategories: state.outgoingInvoice.lineCategories,
});

const mapDispatchToProps = (dispatch) => ({
  createLineItem: (...args) => dispatch(createOutgoingLineItem(...args)),
});

const mergeProps = (
  { invoiceId, lineCategories, productCatalogItemsEnabled, smallEntrepreneur, insertedAsGross },
  dispatchProps,
  ownProps
) => ({
  ...ownProps,
  ...dispatchProps,
  smallEntrepreneur,
  productCatalogItemsEnabled,
  invoiceInsertedAsGross: insertedAsGross,
  translationPath: 'revenue',
  type: TYPE_REVENUE,
  cells: [
    {
      name: 'position',
      type: productCatalogItemsEnabled ? 'positionSelect' : 'positionInput',
    },
    { name: 'quantity', type: 'input' },
    { name: 'unit', type: 'unitSelect' },
    { type: insertedAsGross ? 'grossLinked' : 'netLinked' },
    { name: 'vat', type: 'select' },
    { name: insertedAsGross ? 'totalGrossAmount' : 'totalNetAmount', type: 'text' },
  ],
  extendedRowCells: [
    { name: 'itemNumber', type: productCatalogItemsEnabled ? 'itemNumberSelect' : 'itemNumber' },
    { name: 'description', type: 'counterInput' },
    { name: 'internalNotes', type: 'counterInput' },
  ],
  lineCategories,
  createLineItem: (formValues) => dispatchProps.createLineItem(invoiceId, formValues),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NewItemRow);
