import { CALL_API } from 'constants/api';
import { CLEAR_FILTERS, SET_DATE_RANGE, SET_QUERY_PARAM } from 'constants/common/filters';
import {
  CREATE,
  DELETE,
  FETCH,
  FETCH_CATEGORIES,
  FETCH_SUGGESTED_NUMBER,
  INDEX,
  PAGINATE,
  SORT,
  UPDATE,
} from 'constants/common/resource';

export const apiIndex = ({ name, params, endpoint, ...rest } = {}) => ({
  [CALL_API]: {
    name,
    params,
    endpoint,
    types: [...INDEX],
    ...rest,
  },
});

export const apiDelete =
  ({ name, endpoint, ...rest }) =>
  (id) => ({
    [CALL_API]: {
      endpoint: `${endpoint}/${id}`,
      method: 'DELETE',
      types: [...DELETE],
      name,
      ...rest,
    },
  });

export const apiCreate =
  ({ name, endpoint, ...rest }) =>
  ({ data }) => ({
    [CALL_API]: {
      data,
      endpoint,
      method: 'POST',
      types: [...CREATE],
      name,
      ...rest,
    },
  });

export const apiUpdate =
  ({ name, endpoint, ...rest }) =>
  ({ id, data }) => ({
    [CALL_API]: {
      data,
      endpoint: `${endpoint}/${id}`,
      method: 'PUT',
      types: [...UPDATE],
      name,
      ...rest,
    },
  });

export const apiFetch =
  ({ name, endpoint, ...rest }) =>
  (id) => ({
    [CALL_API]: {
      endpoint: `${endpoint}/${id}`,
      method: 'GET',
      types: [...FETCH],
      name,
      ...rest,
    },
  });

export const apiFetchSuggestedItemNumber =
  ({ name, endpoint, ...rest }) =>
  () => ({
    [CALL_API]: {
      endpoint: `${endpoint}/suggested_item_number`,
      method: 'GET',
      types: [...FETCH_SUGGESTED_NUMBER],
      name,
      ...rest,
    },
  });

export const apiFetchCategories =
  ({ name, endpoint, ...rest }) =>
  () => ({
    [CALL_API]: {
      endpoint: `${endpoint}`,
      method: 'GET',
      types: [...FETCH_CATEGORIES],
      name,
      ...rest,
    },
  });

export const sort =
  ({ name } = {}) =>
  (column) =>
  (dispatch) =>
    dispatch({
      type: SORT,
      column,
      name,
    });

export const setPagination =
  ({ name } = {}) =>
  (pagination) =>
  (dispatch) =>
    dispatch({
      type: PAGINATE,
      ...pagination,
      name,
    });

export const setQueryParam =
  ({ name } = {}) =>
  (param, value, mapper = (val) => val) =>
  (dispatch) =>
    dispatch({
      type: SET_QUERY_PARAM,
      name,
      payload: {
        param,
        value,
        parsedValue: mapper(value),
      },
    });

export const setDateRange =
  ({ name } = {}) =>
  (param) =>
  (dispatch) =>
  (values) =>
    dispatch({
      type: SET_DATE_RANGE,
      name,
      payload: {
        param,
        values,
      },
    });

export const clearFilters =
  ({ name } = {}) =>
  ({ clearStatus = false } = {}) => ({
    type: CLEAR_FILTERS,
    name,
    payload: { clearStatus },
  });
