import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import { showNotification } from 'actions/notification';
import { t } from 'shared/utils';

import {
  Button,
  Extensions,
  IconCheck,
  IconUpload,
  Subtitle,
  Title,
  Wrapper,
} from './Dropzone.styled';

const allowedFileExtensions = [
  'image/x-png',
  'image/png',
  'image/jpeg',
  'image/tiff',
  'application/pdf',
];

type DropzoneProps = { onFileChange: (file: File | null) => void; isError: boolean };

const Dropzone = ({ onFileChange, isError }: DropzoneProps) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);

  const onDropAccepted = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const onDropRejected = useCallback(() => {
    dispatch(
      showNotification({
        duration: 3000,
        title: t('cashbooks.cash_transactions.creator.dropzone.error.incorrect_file.title'),
        text: t('cashbooks.cash_transactions.creator.dropzone.error.incorrect_file.text'),
        variant: 'warning',
      })
    );
  }, [dispatch]);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDropAccepted,
    onDropRejected,
    maxFiles: 1,
    maxSize: 8e6, // 8MB
    accept: allowedFileExtensions,
  });

  useEffect(() => {
    onFileChange(file);
  }, [file, onFileChange]);

  return (
    <Wrapper {...getRootProps()} isError={isError}>
      {file ? (
        <>
          <IconCheck />
          <Title>{file.name}</Title>
        </>
      ) : (
        <>
          <IconUpload />
          <Title>{t('cashbooks.cash_transactions.creator.dropzone.title')}</Title>
        </>
      )}

      <Subtitle>{t('cashbooks.cash_transactions.creator.dropzone.subtitle')}</Subtitle>
      <Button label={t('cashbooks.cash_transactions.creator.dropzone.button')} onClick={open} />
      <Extensions>
        <div>{t('cashbooks.cash_transactions.creator.dropzone.extensions_1')}</div>
        <div>{t('cashbooks.cash_transactions.creator.dropzone.extensions_2')}</div>
      </Extensions>
      <input {...getInputProps()} data-id="dropzone-input" />
    </Wrapper>
  );
};

export default Dropzone;
