import styled from 'styled-components';

type WrapperProps = {
  align?: 'left' | 'right' | 'center';
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => {
    switch (align) {
      case 'left':
        return 'flex-start';
      case 'right':
        return 'flex-end';
      case 'center':
        return 'center';
      default:
        return 'flex-start';
    }
  }};

  > div {
    word-break: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
