import { AxiosPromise, CancelToken } from 'axios';
import { JsonApiResponse } from 'json-api-normalizer';

import api from '../index';

type GetClientsData = {
  page?: number;
  per_page?: number;
  filters?: {
    first_name?: string;
    last_name?: string;
    company_name?: string;
    city?: string;
    id_number?: string;
    full_text_search?: string;
  };
};

export const getClients = (
  data?: GetClientsData,
  token?: CancelToken
): AxiosPromise<JsonApiResponse> => {
  const url = '/me/clients';

  return api.get(url, {
    params: data,
    cancelToken: token,
  });
};
