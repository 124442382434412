import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { FieldArray } from 'redux-form';

import { useIsMdUp, useIsXXLgUp } from 'shared/hooks/useMedia';
import { formatMoney, t } from 'shared/utils';
import Hint, { ArrowPosition } from 'components/Hint/Hint';

import Alert from '../Alert/Alert';
import Result, { Color } from '../ExpensesRevenues/Result/Result';
import Subtitle from '../ExpensesRevenues/Subtitle/Subtitle';
import SummaryHorizontal from '../ExpensesRevenues/SummaryHorizontal/SummaryHorizontal';
import SummaryVertical from '../ExpensesRevenues/SummaryVertical/SummaryVertical';
import Title from '../ExpensesRevenues/Title/Title';
import Left from '../ExpensesRevenues/TwoColumnLayout/Left';
import Right from '../ExpensesRevenues/TwoColumnLayout/Right';
import Row from '../ExpensesRevenues/TwoColumnLayout/Row';
import * as formSelectors from '../formSelectors';
import Section from '../Section/Section';
import * as selectors from '../selectors';
import { getAlertMessage } from '../utils';
import List from './List/List';
import OtherExpenses from './OtherExpenses/OtherExpenses';
import Table from './Table/Table';
import type { OtherExpense, RecurringExpense } from './types';

import styles from '../ExpensesRevenues/ExpensesRevenues.module.css';

const Expenses = () => {
  const monthlyExpenses = useSelector(formSelectors.getMonthlyExpenses);
  const { averageExpenses } = useSelector(selectors.getAverageAccountDetailsForSelectedAccounts);
  const alert = useSelector(selectors.getExpensesAlert);
  const isDisabled = !!alert;
  const isMdUp = useIsMdUp();
  const isXXLgUp = useIsXXLgUp();
  const SummaryComponent = isMdUp ? SummaryHorizontal : SummaryVertical;
  const expensesDifference = averageExpenses - monthlyExpenses;
  const summaryHint = (
    <div className={styles.summaryHint}>
      <Hint arrowPosition={isXXLgUp ? ArrowPosition.Right : ArrowPosition.Top}>
        <div className={styles.summaryHintText}>
          {t(
            expensesDifference > 0
              ? 'features.financial_planning.liquidity_calculator.expenses.hint.positive'
              : 'features.financial_planning.liquidity_calculator.expenses.hint.negative',
            {
              amount: formatMoney(Math.abs(expensesDifference)),
            }
          )}
        </div>
      </Hint>
    </div>
  );

  return (
    <Section
      title={t('features.financial_planning.liquidity_calculator.expenses.title')}
      eventName="Expand_Step2"
      dataId={'LiquidityCalculatorSection:title-expenses'}
    >
      {alert && <Alert dataId="LiquidityCalculatorExpenses:alert">{getAlertMessage(alert)}</Alert>}
      <div className={cx(styles.content, { [styles.disabledSection]: isDisabled })}>
        <Row>
          <Left>
            <Title className={styles.title}>
              {t(
                'features.financial_planning.liquidity_calculator.expenses.recurring_expenses.title'
              )}
            </Title>
            <Subtitle className={styles.subtitle}>
              {t(
                'features.financial_planning.liquidity_calculator.expenses.recurring_expenses.subtitle'
              )}
            </Subtitle>
          </Left>
          <Right>
            {isMdUp ? (
              <div className={styles.table}>
                <FieldArray<{}, RecurringExpense> name="recurringExpenses" component={Table} />
              </div>
            ) : (
              <div className={styles.list}>
                <FieldArray<{}, RecurringExpense> name="recurringExpenses" component={List} />
              </div>
            )}
          </Right>
        </Row>
        <Row className={styles.otherRow}>
          <Left>
            <Title className={styles.title}>
              {t('features.financial_planning.liquidity_calculator.expenses.other_expenses.title')}
            </Title>
            <Subtitle className={styles.subtitle}>
              {t(
                'features.financial_planning.liquidity_calculator.expenses.other_expenses.subtitle'
              )}
            </Subtitle>
          </Left>
          <Right>
            <div className={styles.other}>
              <FieldArray<{}, OtherExpense> name="otherExpenses" component={OtherExpenses} />
            </div>
          </Right>
        </Row>
        <Row className={styles.summaryRow}>
          {isXXLgUp && <Left>{!isDisabled && summaryHint}</Left>}
          <Right>
            <SummaryComponent
              title={t('features.financial_planning.liquidity_calculator.expenses.summary.title')}
              dataId={`LiquidityCalculatorExpenses:summary`}
              result={<Result result={monthlyExpenses} color={Color.Red} />}
            />
            {!isXXLgUp && summaryHint}
          </Right>
        </Row>
      </div>
    </Section>
  );
};

export default memo(Expenses);
