import React, { memo, SVGProps } from 'react';

const UploadCloud = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 48 48" {...props}>
    <defs>
      <path
        d="M14.929 21.962l-.118.135a1.44 1.44 0 00.118 1.893l.134.12a1.419 1.419 0 001.881-.12l5.63-5.663V38.75c0 .792.638 1.436 1.427 1.436a1.43 1.43 0 001.423-1.436V18.327l5.631 5.663.134.12a1.419 1.419 0 001.882-.12 1.44 1.44 0 000-2.028L24 12.837l-9.072 9.125z"
        id="upload-cloud_svg__a"
      />
    </defs>
    <g transform="translate(0 5.581)" fill="none" fillRule="evenodd">
      <path
        d="M24 0c7.28 0 13.34 5.15 14.7 12.01 5.2.358 9.3 4.613 9.3 9.862 0 5.488-4.48 9.942-10 9.942l-10.094-.001v-3.976H38c3.225 0 5.877-2.563 5.996-5.742l.004-.223c0-3.082-2.44-5.687-5.56-5.885l-3.06-.22-.6-2.982C33.76 7.675 29.24 3.977 24 3.977c-4.12 0-7.84 2.266-9.74 5.905l-1 1.89-2.14.218A7.917 7.917 0 004 19.884c0 4.394 3.58 7.953 8 7.953h8.093v3.976L12 31.814C5.482 31.814.166 26.629.004 20.187L0 19.884C0 13.74 4.68 8.669 10.7 8.033A15.002 15.002 0 0124 0z"
        fill="currentColor"
        fillRule="nonzero"
      />
      <use fill="currentColor" xlinkHref="#upload-cloud_svg__a" />
    </g>
  </svg>
);

export default memo(UploadCloud);
