import values from 'lodash/values';

import { piwikHelpers } from 'shared/utils/piwik';

import { FeaturesStore } from './TodoList/types';

export const trackEvent = piwikHelpers.trackEventCurried('Notification-Widget-Sidebar');

export const countTasks = (features: FeaturesStore) =>
  values(features).reduce((totalTasks, task) => totalTasks + task!.tasksCount, 0);
