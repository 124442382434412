import React, { useCallback, useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { resetCustomerDefaults, updateCustomerDefaults } from 'actions/default-values';
import { getOutgoingInvoice, GetOutgoingInvoiceData } from 'api/me/previews/outgoingInvoice';
import { DocumentsFormName } from 'containers/Profile/InvoiceTemplate/DefaultValues/TabDocumentsDefaults/TabDocumentsDefaults';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { required } from 'shared/utils/formValidation';
import { RootState } from 'store';
import { DefaultValues } from 'types/entities/DefaultValues';
import { Checkbox, CurrencyFieldWrapper, SelectField, TextField } from 'components/Form';
import InputsGroup from 'components/InputsGroup/InputsGroup';
import HtmlField from 'components/v2/Form/HtmlField/HtmlField';

import { FormColumn, FormColumns, FormRow } from '../../sharedComponents';
import DefaultValuesFormWithPreview from '../../sharedComponents/DefaultValuesForm/DefaultValuesFormWithPreview';

import styles from './InvoiceDefaults.module.css';

const valueToRemove = 0;
const DUE_DATE_OPTIONS = [0, 7, 14, 21, 30].map((value) => ({
  value,
  label:
    value === 0
      ? t('invoice_templates.documents_defaults.due_date_immediatly')
      : t('invoice_templates.documents_defaults.due_date_option', { count: value }),
}));
const DUE_DATE_OPTIONS_WITH_DISCOUNT = DUE_DATE_OPTIONS.filter(
  (item) => item.value !== valueToRemove
);

export const FORM_NAME: DocumentsFormName = 'invoiceDefaults';

type FormData = Pick<
  DefaultValues,
  | 'invoiceDueDate'
  | 'invoiceDiscountGrant'
  | 'invoiceDiscountPercentage'
  | 'invoiceDiscountDate'
  | 'invoiceSubject'
  | 'invoiceSalutation'
  | 'invoiceSalutationContent'
  | 'invoiceFooter'
>;

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    invoiceDueDate: state.defaultValues.values.invoiceDueDate,
    invoiceDiscountGrant: state.defaultValues.values.invoiceDiscountGrant,
    invoiceDiscountPercentage: state.defaultValues.values.invoiceDiscountPercentage,
    invoiceDiscountDate: state.defaultValues.values.invoiceDiscountDate,
    invoiceSubject: state.defaultValues.values.invoiceSubject,
    invoiceSalutation: state.defaultValues.values.invoiceSalutation,
    invoiceSalutationContent: state.defaultValues.values.invoiceSalutationContent,
    invoiceFooter: state.defaultValues.values.invoiceFooter,
  },
  formValues: {
    discountGrant: selector(state, 'invoiceDiscountGrant'),
    invoiceSalutation: selector(state, 'invoiceSalutation'),
    invoiceSalutationContent: selector(state, 'invoiceSalutationContent'),
    invoiceFooter: selector(state, 'invoiceFooter'),
  },
});

const mapDispatchToProps = {
  updateCustomerDefaults,
  resetCustomerDefaults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type InvoiceDefaultsProps = ConnectedProps<typeof connector>;

const buildInvoiceDefaultsValues = (values: FormData, discountGrant: boolean) => {
  if (discountGrant) return values;

  return {
    ...values,
    invoiceDiscountPercentage: null,
    invoiceDiscountGrant: false,
  };
};

const InvoiceDefaults = ({
  formValues: { discountGrant, invoiceSalutation, invoiceSalutationContent, invoiceFooter },
  initialValues,
  resetCustomerDefaults,
  updateCustomerDefaults,
  handleSubmit: onSubmit,
  reset: resetForm,
  invalid,
  change,
  initialized,
  initialize,
}: InvoiceDefaultsProps & InjectedFormProps<FormData, InvoiceDefaultsProps>) => {
  const handleDueDateChange = useCallback(
    (selectedValue) => {
      if (
        selectedValue === 0 &&
        invoiceFooter.replace(/<[^>]*>?/gm, '') === initialValues.invoiceFooter
      ) {
        change('invoiceFooter', t('document_defaults.invoice_immediate'));
      }
    },
    [invoiceFooter, initialValues.invoiceFooter, change]
  );

  const topFields = useMemo(
    () => (
      <>
        <FormRow>
          <Field
            dataId="DocumentDefaults:input-due-date"
            name="invoiceDueDate"
            component={SelectField}
            checker={serverValidationChecker}
            options={DUE_DATE_OPTIONS}
            placeholder={t('invoice_templates.documents_defaults.due_date')}
            label={t('invoice_templates.documents_defaults.due_date')}
            onChange={handleDueDateChange}
          />
        </FormRow>
        <FormRow>
          <Field
            name="invoiceDiscountGrant"
            dataIds={{
              input: 'DocumentDefaults:input-discount-grant',
              label: 'DocumentDefaults:label-discount-grant',
            }}
            component={Checkbox}
            checker={serverValidationChecker}
          >
            {t('invoice_templates.documents_defaults.discount_grant')}
          </Field>
        </FormRow>
        <FormRow>
          <FormColumns>
            <FormColumn withLabel>
              <CurrencyFieldWrapper
                name="invoiceDiscountPercentage"
                className={styles.inputWrapper}
                precision={2}
                isRawField
                translationBase="invoice_templates.documents_defaults"
                component={TextField}
                checker={serverValidationChecker}
                readonly={!discountGrant}
                dataId="DocumentDefaults:input-discount-percentage"
                validate={discountGrant ? [required] : []}
                isCurrencySignVisible={false}
              />
              <span>%</span>
            </FormColumn>
            <FormColumn withLabel>
              <Field
                id="discountDate"
                name="invoiceDiscountDate"
                component={SelectField}
                checker={serverValidationChecker}
                className={styles.inputWrapper}
                disabled={!discountGrant}
                options={
                  discountGrant
                    ? DUE_DATE_OPTIONS_WITH_DISCOUNT
                    : [...DUE_DATE_OPTIONS, { label: '-', value: '' }]
                }
                label={t('invoice_templates.documents_defaults.discount_date')}
                placeholder={t('invoice_templates.documents_defaults.discount_date')}
                dataId="DocumentDefaults:input-discount-date"
                disableDateInput
                validate={discountGrant ? [required] : []}
                isDisabledWithUnderline
              />
            </FormColumn>
          </FormColumns>
        </FormRow>
        <FormRow>
          <InputsGroup label={t('invoice_templates.documents_defaults.discount_subject')}>
            <Field
              className={styles.grouppedInput}
              name="invoiceSubject"
              checker={serverValidationChecker}
              component={TextField}
              dataId="DocumentDefaults:input-subject"
            />
          </InputsGroup>
        </FormRow>
      </>
    ),
    [discountGrant, handleDueDateChange]
  );

  const values = useMemo(
    () => ({
      salutation_honorific: invoiceSalutation,
      salutation_content: invoiceSalutationContent,
      personal_notes: invoiceFooter,
    }),
    [invoiceSalutation, invoiceSalutationContent, invoiceFooter]
  );

  const handleSubmit = useCallback(
    onSubmit((values) => {
      updateCustomerDefaults(buildInvoiceDefaultsValues(values, discountGrant), 'form_invoice');
    }),
    [onSubmit, updateCustomerDefaults, discountGrant]
  );

  const handleReset = useCallback(async () => {
    await resetCustomerDefaults('form_invoice');
    resetForm();
  }, [resetCustomerDefaults, resetForm]);

  useEffect(() => {
    initialized && initialize(initialValues);
  }, []);

  return (
    <>
      <DefaultValuesFormWithPreview<GetOutgoingInvoiceData>
        onSubmit={handleSubmit}
        onReset={handleReset}
        sectionName="form_invoice"
        sectionLabel={t('invoice_templates.documents_defaults.invoice_defaults_section')}
        dataIdPrefix="DocumentDefaults:"
        invalid={invalid}
        values={values}
        fetchPreview={getOutgoingInvoice}
        topFields={topFields}
      >
        <FormRow>
          <InputsGroup label={t('invoice_templates.documents_defaults.header')}>
            <Field
              name="invoiceSalutation"
              component={HtmlField}
              checker={serverValidationChecker}
              label={t('invoice_templates.documents_defaults.salutation')}
              tooltip={t('invoice_templates.documents_defaults.salutation_info')}
              dataId="DocumentDefaults:input-salutation"
            />
            <br />
            <Field
              name="invoiceSalutationContent"
              component={HtmlField}
              checker={serverValidationChecker}
              label={t('invoice_templates.documents_defaults.salutation_content')}
              dataId="DocumentDefaults:input-salutation-content"
            />
          </InputsGroup>
        </FormRow>
        <FormRow>
          <InputsGroup label={t('invoice_templates.documents_defaults.footer')}>
            <Field
              name="invoiceFooter"
              component={HtmlField}
              checker={serverValidationChecker}
              dataId="DocumentDefaults:input-footer"
            />
          </InputsGroup>
        </FormRow>
      </DefaultValuesFormWithPreview>
    </>
  );
};

const FormEnhancedComponent = reduxForm<FormData, InvoiceDefaultsProps>({
  form: FORM_NAME,
  enableReinitialize: true,
})(InvoiceDefaults);

export default connector(FormEnhancedComponent);
