import React, { ElementType } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { compose } from 'redux';
import { Form, InjectedFormProps, reduxForm, reset } from 'redux-form';

import { changeEmail, fetchProfile } from 'actions/profile';
import { MODAL_HEADER_VARIANT } from 'constants/modal';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import { PasswordField, SubmitButton, TextField } from 'components/Form';
import FormField from 'components/Form/FormField';
import Modal, { ModalHeader } from 'components/Modal';

import styles from './ChangeEmailModal.module.css';

type ChangeEmailModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type FormData = {
  newEmail: string;
  confirmNewEmail: string;
  password: string;
};

const ChangeEmailModal = ({
  isOpen = false,
  onClose,
  handleSubmit,
  submitting,
}: InjectedFormProps & ChangeEmailModalProps) => {
  const dispatch = useDispatch();

  const abort = () => dispatch(reset('changeEmail'));

  const onSubmitHandler: any = (values: FormData) => {
    return dispatch<any>(changeEmail(values))
      .then(() => {
        handleAbort();
        dispatch(fetchProfile());
      })
      .catch((errors: any) => {
        throw errors;
      });
  };

  const handleAbort = () => {
    abort();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleAbort}
      className={styles.modal}
      data={{ id: 'change-email-modal' }}
    >
      <ModalHeader headerVariant={MODAL_HEADER_VARIANT.SMALL}>
        {t('profile.personal.change_email')}
      </ModalHeader>
      <div className={styles.content}>
        <div className={styles.description}>{t('profile.personal.change_email_description')}</div>
        <Form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className={styles.inputs}>
            <FormField
              component={TextField}
              name="newEmail"
              placeholder={t('form.fields.new_email')}
              required
              dataId="Personal:input-newEmail"
            />
            <FormField
              component={TextField}
              name="emailConfirmation"
              placeholder={t('form.fields.email_confirmation')}
              required
              dataId="Personal:input-confirmNewEmail"
            />
            <p className={cx(styles.description, styles.pwdDescription)}>
              {t('profile.personal.change_email_password_description')}
            </p>
            <FormField
              component={PasswordField}
              name="password"
              placeholder={t('form.fields.password')}
              required
              dataId="Personal:input-confirmNewEmailWithPassword"
            />
          </div>
          <div className={styles.buttons}>
            <ActionButton
              appearance="outlined"
              onClick={handleAbort}
              label={t('profile.personal.abort_email_change')}
              className={styles.button}
              dataId={'button-abort'}
            />
            <SubmitButton
              active={!submitting}
              value={t('profile.personal.change_email_submit')}
              className={cx(styles.button, styles.buttonSave)}
              dataId={'button-submit'}
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default compose<ElementType>(
  reduxForm<{}, FormData>({
    form: 'changeEmail',
    enableReinitialize: true,
    persistentSubmitErrors: true,
  }),
  withTransitionPrevent()
)(ChangeEmailModal);
