import { CHANGE_GRECAPTCHA_RESPONSE, RESET_GRECAPTCHA } from 'constants/grecaptcha';

export default (state = { grecaptchaResponse: undefined }, action) => {
  switch (action.type) {
    case CHANGE_GRECAPTCHA_RESPONSE:
      return {
        ...state,
        grecaptchaResponse: action.response,
      };
    case RESET_GRECAPTCHA:
      return {
        ...state,
        grecaptchaResponse: undefined,
      };
    default:
      return state;
  }
};
