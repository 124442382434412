import * as React from 'react';
import { RouterChildContext, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

type getInitialValues = (props: RouterChildContext) => object;

const withFormInitialValues =
  (getInitialValues: getInitialValues) => (Component: any) => (props: RouterChildContext) => {
    const initialValues = getInitialValues({ router: props.router });
    return <Component {...props} initialValues={initialValues} />;
  };

export default (getInitialValues: getInitialValues) =>
  compose(withRouter, withFormInitialValues(getInitialValues));
