import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';

import { fetchCustomerDefaults as fetchCustomerDefaultsAction } from 'actions/default-values';
import { t } from 'shared/utils';
import Section from 'components/Form/Section/Section';

import DeliveryNoteDefaults, {
  FORM_NAME as DELIVERY_NOTE_DEFAULTS_FORM_NAME,
} from './DeliveryNoteDefaults/DeliveryNoteDefaults';
import InvoiceDefaults, {
  FORM_NAME as INVOICE_DEFAULTS_FORM_NAME,
} from './InvoiceDefaults/InvoiceDefaults';
import OrderConfirmationDefaults, {
  FORM_NAME as ORDER_CONFIRMATION_DEFAULTS_FORM_NAME,
} from './OrderConfirmationDefaults/OrderConfirmationDefaults';
import PaymentReminderDefaults, {
  FORM_NAME as PAYMENT_REMINDER_DEFAULTS_FORM_NAME,
} from './PaymentReminderDefaults/PaymentReminderDefaults';
import ProposalDefaults, {
  FORM_NAME as PROPOSAL_DEFAULTS_FORM_NAME,
} from './ProposalDefaults/ProposalDefaults';

import styles from './TabDocumentsDefaults.module.css';

export type DocumentsFormName =
  | 'invoiceDefaults'
  | 'paymentReminderDefaults'
  | 'proposalDefaults'
  | 'orderConfirmationDefaults'
  | 'deliveryNoteDefaults';

type TabDocumentsDefaultsProps = {
  fetchCustomerDefaults: () => void;
};

const TabDocumentsDefaults = ({ fetchCustomerDefaults }: TabDocumentsDefaultsProps) => {
  useEffect(() => {
    fetchCustomerDefaults();
  }, [fetchCustomerDefaults]);

  return (
    <div className={styles.container}>
      <Section
        className={styles.section}
        title={t('invoice_templates.documents_defaults.invoice_defaults_section')}
      >
        <InvoiceDefaults />
      </Section>
      <Section
        className={styles.section}
        title={t('invoice_templates.documents_defaults.payment_reminder_defaults_section')}
      >
        <PaymentReminderDefaults />
      </Section>
      <Section
        className={styles.section}
        title={t('invoice_templates.documents_defaults.proposal_defaults_section')}
      >
        <ProposalDefaults />
      </Section>
      <Section
        className={styles.section}
        title={t('invoice_templates.documents_defaults.order_confirmation_defaults_section')}
      >
        <OrderConfirmationDefaults />
      </Section>
      <Section
        className={styles.section}
        title={t('invoice_templates.documents_defaults.delivery_note_defaults_section')}
      >
        <DeliveryNoteDefaults />
      </Section>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isDirty:
    isDirty(INVOICE_DEFAULTS_FORM_NAME)(state) ||
    isDirty(PAYMENT_REMINDER_DEFAULTS_FORM_NAME)(state) ||
    isDirty(PROPOSAL_DEFAULTS_FORM_NAME)(state) ||
    isDirty(ORDER_CONFIRMATION_DEFAULTS_FORM_NAME)(state) ||
    isDirty(DELIVERY_NOTE_DEFAULTS_FORM_NAME)(state),
});

const mapDispatchToProps = {
  fetchCustomerDefaults: fetchCustomerDefaultsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabDocumentsDefaults);
