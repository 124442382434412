import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';

import {
  downloadOrderConfirmation as downloadOrderConfirmationPreview,
  getOrderConfirmationPreview,
} from 'actions/order-confirmation';
import { FORM_NAME as ORDER_CONFIRMATION_CREATOR } from 'constants/order-confirmation';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import PreviewModalComponent from 'components/PreviewModal';

const PreviewModal = ({
  orderConfirmationId,
  details,
  orderConfirmationValues = {},
  isOpen,
  onClose,
  downloadPreview,
  getPreview,
}) => {
  const { client } = orderConfirmationValues;
  const values = {
    ...orderConfirmationValues,
    watermark: true,
    clientId: client?.id,
  };
  const download = () => downloadPreview(details, values);

  return (
    <PreviewModalComponent
      title={t('modals.preview_order_confirmation.title')}
      getPreview={() => getPreview(orderConfirmationId, values)}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ActionButton
        appearance="primary"
        onClick={download}
        label={t('features.order_confirmation.form.download')}
        type="button"
      />
    </PreviewModalComponent>
  );
};

PreviewModal.propTypes = {
  orderConfirmationId: PropTypes.string.isRequired,
  details: PropTypes.shape({}).isRequired,
  orderConfirmationValues: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  downloadPreview: PropTypes.func.isRequired,
  getPreview: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  orderConfirmationId: state.orderConfirmation.details.id,
  details: state.orderConfirmation.details,
  orderConfirmationValues: getFormValues(ORDER_CONFIRMATION_CREATOR)(state),
});

export default connect(mapStateToProps, {
  downloadPreview: downloadOrderConfirmationPreview,
  getPreview: getOrderConfirmationPreview,
})(PreviewModal);
