import { OCR_MAX_FILES_COUNT, OCR_MAX_FILES_SIZE } from 'reducers/incoming-invoice';

const generateFilesUploadInfo = (filesSize, filesCount) => {
  const isTooBig = filesSize > OCR_MAX_FILES_SIZE;
  const hasTooManySites = filesCount > OCR_MAX_FILES_COUNT;

  return {
    isTooBig,
    hasTooManySites,
    isOCRCompatibile: !(isTooBig || hasTooManySites),
  };
};

export default generateFilesUploadInfo;
