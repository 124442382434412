import api from 'api';

export type GetPaymentReminderData = {
  introduction: string;
  footer: string;
};

export const getPaymentReminder = (params: GetPaymentReminderData) => {
  const url = '/me/previews/payment_reminder';

  return api.get<ArrayBuffer>(url, {
    params,
    responseType: 'arraybuffer',
  });
};
