export const FETCH_REQUEST = 'OUTGOING_LINE_ITEMS/FETCH_REQUEST';
export const FETCH_SUCCESS = 'OUTGOING_LINE_ITEMS/FETCH_SUCCESS';
export const FETCH_FAILURE = 'OUTGOING_LINE_ITEMS/FETCH_FAILURE';

export const DELETE_REQUEST = 'OUTGOING_LINE_ITEMS/DELETE_REQUEST';
export const DELETE_SUCCESS = 'OUTGOING_LINE_ITEMS/DELETE_SUCCESS';
export const DELETE_FAILURE = 'OUTGOING_LINE_ITEMS/DELETE_FAILURE';

export const UPDATE_REQUEST = 'OUTGOING_LINE_ITEMS/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'OUTGOING_LINE_ITEMS/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'OUTGOING_LINE_ITEMS/UPDATE_FAILURE';

export const CREATE_REQUEST = 'OUTGOING_LINE_ITEMS/CREATE_REQUEST';
export const CREATE_SUCCESS = 'OUTGOING_LINE_ITEMS/CREATE_SUCCESS';
export const CREATE_FAILURE = 'OUTGOING_LINE_ITEMS/CREATE_FAILURE';
