import React, { ReactNode } from 'react';

import { t } from 'shared/utils';
import Loading from 'components/Loading';

import { Wrapper } from './TableEmpty.styled';

export type TableEmptyProps = {
  isLoading?: boolean;
  children?: ReactNode;
  dataId?: string;
};

const TableEmpty = ({
  isLoading,
  dataId,
  children = t('tables.generic_empty'),
}: TableEmptyProps) => {
  return <Wrapper data-id={dataId}>{isLoading ? <Loading /> : children}</Wrapper>;
};

export default TableEmpty;
