import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { fetch as fetchData, resetDownload } from 'actions/datev-download';
import { t } from 'shared/utils';
import CardView, { HeadingSection, Section } from 'components/CardView';
import I18n from 'components/I18n';
import Logo from 'components/Logo';

import Form from './Form';
import SuccessModal from './SuccessModal/SuccessModal';

import formPageStyles from '../Shared.module.css';
import localStyles from './DatevDownload.module.css';

const styles = { ...formPageStyles, ...localStyles };

const Loading = () => (
  <div>
    <div className={styles.loading} />
    <div> {t('datev.download.loading.line_1')}</div>
    <div> {t('datev.download.loading.line_2')}</div>
  </div>
);

const InvalidLink = () => (
  <div className={styles.invalidLink}>
    <I18n t="datev.download.invalid" />
  </div>
);

class DatevDownload extends Component {
  componentDidMount() {
    const { id, fetch } = this.props;
    fetch(id);
  }

  render() {
    const { isFetching, isInvalid, id, download, resetDownload } = this.props;
    const { isDownloadSucceded } = download;

    let FormSection = isDownloadSucceded ? SuccessModal : Form;
    let View = isInvalid ? InvalidLink : FormSection;
    View = isFetching ? Loading : View;

    return (
      <div className={styles.pageWrapper}>
        <div className={styles.wrapper}>
          <CardView className={styles.card}>
            <HeadingSection className={styles.cardSectionHeading}>
              <Logo className={cx(styles.logo)} />
            </HeadingSection>
            <Section className={styles.cardSection}>
              <View id={id} resetDownload={resetDownload} />
            </Section>
          </CardView>
        </div>
      </div>
    );
  }
}

DatevDownload.propTypes = {
  id: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  isInvalid: PropTypes.bool,
  download: PropTypes.object,
  fetch: PropTypes.func.isRequired,
  resetDownload: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => ({
    ...state.datevDownload,
    id: ownProps.match.params.id,
  }),
  {
    fetch: fetchData,
    resetDownload,
  }
)(DatevDownload);
