import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { fetchBankAccounts as fetchBankAccountsAction } from 'actions/bank-account';
import { t } from 'shared/utils';
import EmptyEntryPage from 'components/EmptyEntryPage/EmptyEntryPage';
import BankAccountsEmptyStateImage from 'components/EmptyEntryPage/images/BankAccounts';
import Button from 'redesign/components/atoms/Button/Button';
import ProfileSection from 'redesign/components/molecules/ProfileSection/ProfileSection';
import BankAccountsList from 'features/BankAccountsList/BankAccountsList';

import BankAccountsCreatorModal from './BankAccountsCreatorModal/BankAccountsCreatorModal';

import styles from './BankAccounts.module.css';

const BankAccounts = ({ fetchBankAccounts, bankAccounts, heading }) => {
  const [areBankAccountsFetched, setAreBankAccountFetched] = useState(false);
  const [isCreatorModalVisible, setIsCreatorModalVisible] = useState(false);
  const [reloadCounter, setReloadCounter] = useState(0);

  const handleReload = () => {
    setReloadCounter((reloadCounter) => reloadCounter + 1);
  };

  useEffect(() => {
    fetchBankAccounts().finally(() => {
      setAreBankAccountFetched(true);
    });
  }, [fetchBankAccounts]);

  const noBankAccounts = isEmpty(bankAccounts);

  if (!areBankAccountsFetched) return null;

  return (
    <ProfileSection heading={heading}>
      {!noBankAccounts && (
        <Button
          onClick={() => setIsCreatorModalVisible(true)}
          id="add-bank-account-button"
          data-id="BankAccount:addBankAccountButton"
        >
          {t('bank_accounts.creator.add_new_account')}
        </Button>
      )}
      <BankAccountsList onReload={handleReload} reloadCounter={reloadCounter} />
      <div className={styles.main}>
        <div
          data-id="BankAccount:emptyInfo"
          className={cx({ [styles.centeredContainer]: noBankAccounts })}
        >
          {noBankAccounts && (
            <EmptyEntryPage
              dataIdPrefix="BankAccounts"
              Image={BankAccountsEmptyStateImage}
              header={t('empty_entry_pages.bank_accounts.header')}
              subheader={t('empty_entry_pages.bank_accounts.subheader')}
              info={[
                t('empty_entry_pages.bank_accounts.info.row_1'),
                t('empty_entry_pages.bank_accounts.info.row_2'),
                t('empty_entry_pages.bank_accounts.info.row_3'),
                t('empty_entry_pages.bank_accounts.info.row_4'),
              ]}
              createButtonLabel={t('empty_entry_pages.bank_accounts.button_label')}
              onCreateButtonClick={() => setIsCreatorModalVisible(true)}
            />
          )}
        </div>
        <BankAccountsCreatorModal
          isActive={isCreatorModalVisible}
          onClose={() => setIsCreatorModalVisible(false)}
          onReloadList={handleReload}
        />
      </div>
    </ProfileSection>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.bankAccount.isFetching,
  bankAccounts: state.bankAccount.data,
});

const mapDispatchToProps = {
  fetchBankAccounts: fetchBankAccountsAction,
};

BankAccounts.propTypes = {
  bankAccounts: arrayOf(shape({})),
  isFetching: bool.isRequired,
  fetchBankAccounts: func.isRequired,
  heading: string.isRequired,
  subheading: string,
  description: string,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts);
