import styled, { css, keyframes } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  flex: 1;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 58px;
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.sm};
  outline: 0;
  color: #777;
  background: none;
  align-items: center;
`;

export const MenuWrapper = styled.div`
  position: absolute;
  z-index: 9999;
  width: 100%;
  margin-left: -1px;
  border-radius: 4px;
  background-color: #fff;
`;

export const Menu = styled.ul`
  overflow: scroll;
  overflow-x: hidden;
  max-height: 200px;
  border-right: 1px solid ${({ theme }) => theme.colors.grey['020']};
  border-left: 1px solid ${({ theme }) => theme.colors.grey['020']};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey['020']};
`;

export const Clear = styled.button`
  padding: 0;
  outline: 0;
  border: none;
  color: #0066b3;
  background: none;
`;

export const Loading = styled.li`
  display: flex;
  min-height: 44px;
  border-right: 1px solid ${({ theme }) => theme.colors.grey['020']};
  border-left: 1px solid ${({ theme }) => theme.colors.grey['020']};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey['020']};
  color: #0066b3;
  align-items: center;
  justify-content: center;
`;

export const EmptyText = styled.div`
  display: flex;
  min-height: 44px;
  padding: 10px;
  font-size: 0.875rem;
  border-right: 1px solid ${({ theme }) => theme.colors.grey['020']};
  border-left: 1px solid ${({ theme }) => theme.colors.grey['020']};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey['020']};
  color: #b4b5b4;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const HelpText = styled.div`
  display: flex;
  min-height: 44px;
  padding: 10px;
  font-size: 0.875rem;
  border-right: 1px solid ${({ theme }) => theme.colors.grey['020']};
  border-left: 1px solid ${({ theme }) => theme.colors.grey['020']};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey['020']};
  color: #b4b5b4;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ResultsHeader = styled.div`
  display: flex;
  height: 24px;
  padding-left: 9px;
  font-size: 10px;
  font-weight: bold;
  color: #777;
  background: #e9e9e9;
  align-items: center;
`;

export const MenuItem = styled.li`
  padding: 8px 10px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: #b4b5b4;
  background-color: #f8f8f8;

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid #e7e7e7;
  }

  &:nth-child(even) {
    background-color: #fefefe;
  }
`;

export const Label = styled.label`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 1em);
  margin: 0;
  padding: 0 0.15em;
  padding-top: 0.1em;
  pointer-events: none;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 10px);
    border-bottom: 1px solid #e7e7e7;
    content: '';
    pointer-events: none;
  }

  &::after {
    margin-top: 2px;
    border-width: 4px;
    border-color: #0066b3;
    transform: translate3d(-101%, 0, 0);
    transition: transform 0.3s;
  }
`;

export const LabelContent = styled.span`
  position: absolute;
  display: block;
  width: 100%;
  padding: 0.8em 0;
  white-space: nowrap;
`;

const hoshiSelect = keyframes`
  50% {
    opacity: 0;
    transform: translate3d(1em, 0, 0);
  }

  51% {
    opacity: 0;
    transform: translate3d(-1em, -20px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
`;

type InputProps = {
  $isFilled?: boolean;
  $isInvalid?: boolean;
};

export const Input = styled.input<InputProps>`
  position: relative;
  display: block;
  width: 100%;
  margin-top: 1em;
  padding: 0.6em 0.15em;
  outline: 0;
  border: none;
  background: none;
  opacity: 0.01;
  box-shadow: none;
  cursor: pointer;

  &:focus,
  &:disabled {
    opacity: 1;
  }

  &:focus + ${Label}::after {
    transform: translateZ(0);
  }

  &:focus + ${Label} ${LabelContent}, &:disabled + ${Label} ${LabelContent} {
    font-size: 0.625rem;
    animation: ${hoshiSelect} 0.3s forwards;
  }

  &:focus + ${Label} ${LabelContent} {
    font-size: 0.625rem;
    animation: ${hoshiSelect} 0.3s forwards;
  }

  &:disabled {
    color: #bababa;
    background: none;
    cursor: default;
    -webkit-text-fill-color: #bababa; /* Fixes text input color on Safari */
  }

  &:disabled + ${Label}::after {
    border-color: #eaebea;
  }

  ${({ $isFilled }) =>
    $isFilled &&
    css`
      opacity: 1;

      & + ${Label} ${LabelContent} {
        font-size: 0.625rem;
        animation: ${hoshiSelect} 0.3s forwards;
      }
    `}

  ${({ $isInvalid }) =>
    $isInvalid &&
    css`
      & + ${Label}::after, &:focus + ${Label}::after {
        transform: translateZ(0);
      }

      & + ${Label}::after {
        border-color: #aa0028;
      }

      & + ${Label} ${LabelContent} {
        color: #aa0028;
      }
    `}
`;
