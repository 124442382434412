import { CALL_API } from 'constants/api';
import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/summary';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiFetchSummary = (params) => ({
  [CALL_API]: {
    params,
    endpoint: '/me/revenue_expenses/current_year_summary',
    method: 'GET',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const fetchSummary = (params) => (dispatch) =>
  dispatch(apiFetchSummary(params)).catch(apiErrorHandler);
