import React from 'react';
import cx from 'classnames';
import { shape } from 'prop-types';

import tableStyles from 'shared/styles/table.module.css';
import { mapSupplier, t } from 'shared/utils';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import IncomingInvoiceAmount from 'components/Table/IncomingInvoiceAmount';
import InvoiceStatusBadge from 'components/Table/InvoiceStatusBadge/InvoiceStatusBadge';

import InvoiceColumn from '../../InvoiceColumn';

import styles from '../InvoiceRow.module.css';

const IncomingInvoiceRow = ({ invoice }) => {
  const supplierKey = invoice['persisted-supplier'] ? 'persisted-supplier' : 'supplier';

  return (
    <tr data-id="Datev:new-export-table-row">
      <InvoiceColumn className={tableStyles.cell}>
        <span className={styles.cellHeader}>{t('datev.creator.table.headings.status')}</span>
        <div className={cx(styles.cellContent, styles.status)}>
          <InvoiceStatusBadge status={invoice.status} />
        </div>
      </InvoiceColumn>
      <InvoiceColumn className={tableStyles.cell} expand>
        <span className={styles.cellHeader}>{t('datev.creator.table.headings.supplier')}</span>
        <CellContentWithTooltip>
          {invoice[supplierKey] && mapSupplier(invoice[supplierKey]).name}
        </CellContentWithTooltip>
      </InvoiceColumn>
      <InvoiceColumn className={tableStyles.cell} expand>
        <span className={styles.cellHeader}>{t('datev.creator.table.headings.number')}</span>
        <CellContentWithTooltip>{invoice.number || '-'}</CellContentWithTooltip>
      </InvoiceColumn>
      <InvoiceColumn className={tableStyles.cell} expand>
        <span className={styles.cellHeader}>{t('datev.creator.table.headings.name')}</span>
        <CellContentWithTooltip>{invoice.name || '-'}</CellContentWithTooltip>
      </InvoiceColumn>
      <InvoiceColumn className={tableStyles.cell} align="center">
        <span className={styles.cellHeader}>{t('datev.creator.table.headings.invoice_date')}</span>
        <div className={styles.cellContent}>{invoice.invoiceDate || '-'}</div>
      </InvoiceColumn>
      <InvoiceColumn className={tableStyles.cell} align="center">
        <span className={styles.cellHeader}>{t('datev.creator.table.headings.due_date')}</span>
        <div className={styles.cellContent}>{invoice.dueDate || '-'}</div>
      </InvoiceColumn>
      <InvoiceColumn className={tableStyles.cell} align="right">
        <span className={styles.cellHeader}>
          {t('datev.creator.table.headings.total_gross_amount')}
        </span>
        <div className={styles.cellContent}>
          <IncomingInvoiceAmount
            currency={invoice.currency}
            amount={invoice.totalGrossAmount}
            creditNote={invoice.creditNote}
          />
        </div>
      </InvoiceColumn>
    </tr>
  );
};

IncomingInvoiceRow.propTypes = {
  invoice: shape({}).isRequired,
};

export default IncomingInvoiceRow;
