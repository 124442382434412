import React from 'react';
import InlineSvg from 'react-inlinesvg';
import { bool, func, string } from 'prop-types';

import linkIcon from 'images/link-solid.svg';
import isPressedEnter from 'shared/utils/keyboard-events';
import ArrowIcon from 'components/ArrowIcon/ArrowIcon';

import styles from './ActionButton.module.css';

const ActionButton = ({ dataId, onClick, isOpen }) => {
  return (
    <div
      role="button"
      className={styles.actionButtonWrapper}
      data-id={dataId}
      onClick={onClick}
      onKeyPress={isPressedEnter(onClick)}
      tabIndex="0"
      dataId={dataId}
    >
      <InlineSvg src={linkIcon} className={styles.iconLink} role="presentation" />
      <ArrowIcon isOpen={isOpen} />
    </div>
  );
};

ActionButton.propTypes = {
  dataId: string,
  onClick: func.isRequired,
  isOpen: bool.isRequired,
};

export default ActionButton;
