import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as pushAction } from 'connected-react-router';
import { values } from 'lodash';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { fetchClients as fetchClientsAction } from 'actions/clients';
import { fetchExternalOriginCount as fetchExternalOriginCountAction } from 'actions/externalOriginCount';
import { showNotification } from 'actions/notification';
import {
  deleteOutgoingInvoice,
  transformOutgoingInvoiceIntoDeliveryNote,
} from 'actions/outgoing-invoice';
import { FROM_OUTGOING_INVOICE_QUERY_PARAM } from 'constants/outgoing-invoice';
import { PIWIK_ACTION_DELETE_INVOICE, PIWIK_CATEGORY_REVENUE } from 'constants/piwik';
import { invoiceDeletionSuccess } from 'notifications/outgoing-invoices';
import paths from 'routes/paths';
import tableStyles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import I18n from 'components/I18n';
import { ConfirmationModal } from 'components/Modal';

import InvoiceDeletion from './components/InvoiceDeletetion';
import InvoiceHeader from './components/InvoiceHeader';
import InvoiceRow from './components/InvoiceRow/InvoiceRow';

import localStyles from './OutgoingInvoicesTable.module.css';

export const styles = { ...tableStyles, ...localStyles };

class OutgoingInvoicesTable extends Component {
  defaultState = {
    invoiceId: null,
    invoiceNumber: null,
    password: null,
    error: null,
    isDraft: false,
    errorMessages: [],
    isFieldInvalid: false,
  };

  state = this.defaultState;

  onRequestDelete = (invoice) => {
    const { number, id, draft } = invoice;
    this.setState({
      invoiceId: id,
      invoiceNumber: number,
      isDraft: draft,
    });
  };

  handlePassword = ({ target: { value } }) =>
    this.setState({ password: value, isFieldInvalid: false });

  showErrors = (errorMessages) => {
    this.setState({ errorMessages, isFieldInvalid: true });
  };

  deleteInvoice = () => {
    const { invoiceId, password } = this.state;
    const { deleteInvoice, fetchExternalOriginCount } = this.props;
    deleteInvoice(invoiceId, password).then((response) => {
      const { error } = this.props;
      if (error) {
        const { errors = {} } = response;
        this.showErrors(values(errors));
        return;
      }

      fetchExternalOriginCount();
      this.updatePage();
      piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_DELETE_INVOICE);
    });
  };

  updatePage = () => {
    const { refresh, deletionNotificationSuccess } = this.props;
    this.hideModal();
    deletionNotificationSuccess();
    refresh();
  };

  onTransform = (item) => {
    this.props.transformToDeliveryNote(item).then(({ data: { id } }) => {
      this.props.push({
        pathname: paths.editDeliveryNote(id),
        search: `?${FROM_OUTGOING_INVOICE_QUERY_PARAM}`,
      });
    });
  };

  hideModal = () => this.setState({ ...this.defaultState });

  componentDidMount() {
    const { fetchClients, fetchExternalOriginCount } = this.props;

    fetchClients();
    fetchExternalOriginCount();
  }

  renderModalContent = () => {
    const { url } = this.props;
    const { isDraft, invoiceNumber, errorMessages, isFieldInvalid } = this.state;
    return isDraft ? (
      <I18n t="revenue.confirm_delete" paragraphs />
    ) : (
      <InvoiceDeletion
        handlePassword={this.handlePassword}
        invoiceNumber={invoiceNumber}
        errorMessages={errorMessages}
        isFieldInvalid={isFieldInvalid}
        siteUrl={url}
      />
    );
  };

  render() {
    const { isFetching, paymentRemindersEnabled, hasFilters, isIndexClassicView } = this.props;
    const { invoiceId, password, isDraft } = this.state;

    // On views with filters, sorting by status is disabled.
    const isStatusSortable = !hasFilters;
    const columns = [
      { column: 'arrowIcon', hidden: true },
      { column: 'status', sortable: isStatusSortable },
      { column: 'client', sortable: true },
      { column: 'number', sortable: true },
      { column: 'subject', sortable: true },
      { column: 'creation_date', sortable: true },
      { column: 'due_date', sortable: true },
      { column: 'discount_gross_value', sortable: true, align: 'right' },
      { column: 'total_gross_amount', sortable: true, align: 'right' },
      { column: 'actions', info: t('revenue.table.actions.info_text') },
      // we have to set fixed width for last hidden colum so sub invoice component
      // will be rendered with right space which is equal to this hidden column width
      { column: 'space', hidden: true },
    ];

    return (
      <>
        <table
          className={cx(styles.invoicesTable, { [styles.invoicesTableLoading]: isFetching })}
          data-id="table"
        >
          <thead>
            <tr>
              {columns.map((column) => (
                <InvoiceHeader key={column.column} {...column} />
              ))}
            </tr>
          </thead>
          <tbody>
            {this.props.invoices.map((i, index) => (
              <InvoiceRow
                index={index}
                key={i.id}
                invoice={i}
                onDelete={this.onRequestDelete}
                onTransform={this.onTransform}
                paymentRemindersEnabled={paymentRemindersEnabled}
                isIndexClassicView={isIndexClassicView}
                isEven={index % 2 === 0}
              />
            ))}
          </tbody>
        </table>
        <ConfirmationModal
          dangerousAction
          isOpen={!!invoiceId}
          disabled={!(isDraft || password)}
          closeLabel={t('revenue.delete.cancel')}
          confirmLabel={t('revenue.delete.confirm_delete')}
          onClose={this.hideModal}
          onConfirm={this.deleteInvoice}
        >
          {this.renderModalContent()}
        </ConfirmationModal>
      </>
    );
  }
}

OutgoingInvoicesTable.defaultProps = {
  isIndexClassicView: false,
};

OutgoingInvoicesTable.propTypes = {
  deletionNotificationSuccess: func.isRequired,
  invoices: arrayOf(shape({})),
  isEmpty: bool.isRequired,
  isFetching: bool.isRequired,
  deleteInvoice: func.isRequired,
  fetchClients: func.isRequired,
  refresh: func.isRequired,
  paymentRemindersEnabled: bool,
  hasFilters: bool,
  error: bool,
  url: string,
  isIndexClassicView: bool,
  fetchExternalOriginCount: func,
  transformToDeliveryNote: func.isRequired,
};

const mapStateToProps = (state) => ({
  invoices: state.outgoingInvoices.data,
  sorting: state.outgoingInvoices.sorting,
  pagination: state.outgoingInvoices.pagination,
  isFetching: state.outgoingInvoices.isFetching,
  error: state.outgoingInvoice.error,
  url: state.appSettings.url,
});

const mapDispatchToProps = (dispatch) => ({
  deleteInvoice: (...args) => dispatch(deleteOutgoingInvoice(...args)),
  deletionNotificationSuccess: () => dispatch(showNotification(invoiceDeletionSuccess)),
  fetchClients: () => dispatch(fetchClientsAction()),
  fetchExternalOriginCount: () => dispatch(fetchExternalOriginCountAction()),
  transformToDeliveryNote: (...args) => dispatch(transformOutgoingInvoiceIntoDeliveryNote(...args)),
  push: (path) => dispatch(pushAction(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutgoingInvoicesTable);
