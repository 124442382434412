import { push, replace } from 'connected-react-router';
import normalize from 'json-api-normalizer';
import { get, kebabCase } from 'lodash';
import { change } from 'redux-form';
import build from 'redux-object';

import { fetchBankTransfers } from 'actions/bank-transfer';
import { fetchCustomerDefaults } from 'actions/default-values';
import { fetchInvoiceTemplates } from 'actions/invoice-templates';
import { showNotification } from 'actions/notification';
import { fetchOutgoingLineItems } from 'actions/outgoing-invoice/line-items';
import { CALL_API } from 'constants/api';
import {
  CLEAR_FILTERS,
  CLEAR_FILTERS_TO_INITIAL,
  SET_DATE_RANGE,
  SET_STATUS_FILTER,
} from 'constants/common/filters';
import EntityPath from 'constants/entitiesPaths';
import {
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_ALL_BANK_TRANSFERS_FAILURE,
  DELETE_ALL_BANK_TRANSFERS_REQUEST,
  DELETE_ALL_BANK_TRANSFERS_SUCCESS,
  DELETE_FAILURE,
  DELETE_MAYBE_SUGGESTION,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DESTROY_FORM,
  DOWNLOAD_FAILURE,
  DOWNLOAD_REQUEST,
  DOWNLOAD_SUCCESS,
  DRAFT_ID,
  DUPLICATE_INVOICE_FAILURE,
  DUPLICATE_INVOICE_REQUEST,
  DUPLICATE_INVOICE_SUCCESS,
  FETCH_FAILURE,
  FETCH_FAILURE_SUB_INVOICES,
  FETCH_LINE_CATEGORIES_FAILURE,
  FETCH_LINE_CATEGORIES_REQUEST,
  FETCH_LINE_CATEGORIES_SUCCESS,
  FETCH_REQUEST,
  FETCH_REQUEST_SUB_INVOICES,
  FETCH_SUCCESS,
  FETCH_SUCCESS_SUB_INVOICES,
  FROM_DUPLICATE_QUERY_PARAM,
  FROM_OUTGOING_INVOICE_QUERY_PARAM,
  INDEX_FAILURE,
  INDEX_MORE_FAILURE,
  INDEX_MORE_REQUEST,
  INDEX_MORE_SUCCESS,
  INDEX_REQUEST,
  INDEX_RESET,
  INDEX_SUCCESS,
  NAMESPACE,
  PAGINATION,
  PREVIEW_FAILURE,
  PREVIEW_REQUEST,
  PREVIEW_SUCCESS,
  REMINDER_UPDATE_FAILURE,
  REMINDER_UPDATE_REQUEST,
  REMINDER_UPDATE_SUCCESS,
  REORDER_LINE_ITEMS_FAILURE,
  REORDER_LINE_ITEMS_LOCAL,
  REORDER_LINE_ITEMS_REQUEST,
  REORDER_LINE_ITEMS_SUCCESS,
  SEARCH_FAILURE,
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEND_FAILURE,
  SEND_REQUEST,
  SEND_SUCCESS,
  SORT_SUCCESS,
  TOGGLE_DISABLED_BUTTON_ON_LINE_ITEM_REQUEST,
  TOGGLE_GROSS_NET_FAILURE,
  TOGGLE_GROSS_NET_REQUEST,
  TOGGLE_GROSS_NET_SUCCESS,
  TOGGLE_PAID_BY_CASH_FAILURE,
  TOGGLE_PAID_BY_CASH_REQUEST,
  TOGGLE_PAID_BY_CASH_SUCCESS,
  TOGGLE_PAID_BY_UNSPECIFIED_FAILURE,
  TOGGLE_PAID_BY_UNSPECIFIED_REQUEST,
  TOGGLE_PAID_BY_UNSPECIFIED_SUCCESS,
  TRANSFORM_FAILURE,
  TRANSFORM_REQUEST,
  TRANSFORM_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/outgoing-invoice';
import {
  PIWIK_ACTION_REVENUE_CREATE_CUSTOMER,
  PIWIK_CATEGORY_REVENUE,
  PIWIK_CONVERSION_NEW_CUSTOMER_REVENUE,
  PIWIK_NAME_CREATE_CUSTOMER_SUCCESS,
} from 'constants/piwik';
import { Resources } from 'constants/resources';
import {
  tickPaidByCashFailure,
  tickPaidByCashSuccess,
  tickPaidByUnspecifiedFailure,
  tickPaidByUnspecifiedSuccess,
  untickPaidByCashFailure,
  untickPaidByCashSuccess,
  untickPaidByUnspecifiedFailure,
  untickPaidByUnspecifiedSuccess,
} from 'notifications/cash-payment';
import {
  correctionCreateFailure,
  downloadEInvoiceFailure,
  downloadEInvoiceFailureSaveAsDraft,
  downloadSuccess,
  invoiceSendSuccess,
  reminderDownloadSuccess,
  reminderSendSuccess,
} from 'notifications/outgoing-invoices';
import { getSanitizedFilters } from 'reducers/common/filters';
import paths from 'routes/paths';
import { downloadData, pf } from 'shared/utils';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import {
  cancellationInvoiceFilename,
  correctionInvoiceFilename,
  outgoingInvoiceFilename,
  paymentReminderFilename,
} from 'shared/utils/file-naming';
import { piwikHelpers } from 'shared/utils/piwik';
import { bindServerValidation } from 'shared/utils/server-validation';
import { clearItem, getItem, setItem } from 'shared/utils/storage';

import * as actions from '../index';

export const getDraftId = getItem(DRAFT_ID);
const setDraftId = setItem(DRAFT_ID);
const clearDraftId = clearItem(DRAFT_ID);

export const apiCreateDraft = () => ({
  [CALL_API]: {
    data: { draft: true },
    method: 'POST',
    endpoint: '/me/outgoing_invoices',
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const apiCreateCorrectionDraft = (invoiceId) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: `/me/outgoing_invoices/${invoiceId}/correction`,
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const apiFetchOutgoingInvoice = (invoiceId, omitReducers) => {
  if (!invoiceId && invoiceId !== 0) {
    return null;
  }

  return {
    [CALL_API]: {
      endpoint: `/me/outgoing_invoices/${invoiceId}`,
      types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
      omitReducers,
    },
  };
};

export const apiFetchLineCategories = () => ({
  [CALL_API]: {
    endpoint: '/line_item_categories/invoice_revenues',
    types: [
      FETCH_LINE_CATEGORIES_REQUEST,
      FETCH_LINE_CATEGORIES_SUCCESS,
      FETCH_LINE_CATEGORIES_FAILURE,
    ],
  },
});

export const apiUpdateOutgoingInvoice = (invoiceId, data) => ({
  [CALL_API]: {
    data,
    method: 'PUT',
    endpoint: `/me/outgoing_invoices/${invoiceId}`,
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const apiImportOutgoingInvoice = (invoiceId, data) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: `/me/outgoing_invoices/${invoiceId}/import`,
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const apiToggleGrossNet = (invoiceId, insertedAsGross) => ({
  [CALL_API]: {
    data: { insertedAsGross },
    method: 'PATCH',
    endpoint: `/me/outgoing_invoices/${invoiceId}/toggle_inserted_as_gross`,
    types: [TOGGLE_GROSS_NET_REQUEST, TOGGLE_GROSS_NET_SUCCESS, TOGGLE_GROSS_NET_FAILURE],
  },
});

export const apiReorderLineItems = (invoiceId, lineItemsOrder) => ({
  [CALL_API]: {
    data: { lineItemsOrder },
    method: 'PATCH',
    endpoint: `/me/outgoing_invoices/${invoiceId}/reorder`,
    types: [REORDER_LINE_ITEMS_REQUEST, REORDER_LINE_ITEMS_SUCCESS, REORDER_LINE_ITEMS_FAILURE],
  },
});

export const apiDeleteOutgoingInvoice = (invoiceId, password) => ({
  [CALL_API]: {
    data: { password },
    endpoint: `/me/outgoing_invoices/${invoiceId}`,
    method: 'DELETE',
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const apiCreateCancellationInvoice = (invoiceId) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: `/me/outgoing_invoices/${invoiceId}/cancellation`,
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const apiDeleteAllBankTransferConnections = (invoiceId) => {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `/me/outgoing_invoices/${invoiceId}/delete_all_bank_transfer_connections`,
      types: [
        DELETE_ALL_BANK_TRANSFERS_REQUEST,
        DELETE_ALL_BANK_TRANSFERS_SUCCESS,
        DELETE_ALL_BANK_TRANSFERS_FAILURE,
      ],
    },
  };
};

export const toggleDisableAction = () => (dispatch) =>
  dispatch({ type: TOGGLE_DISABLED_BUTTON_ON_LINE_ITEM_REQUEST });

const paginationParams = (pagination = {}) => ({
  page: pagination.page || 1,
  ...pagination,
});

const sortingParams = (sorting = {}) => {
  const direction = sorting.direction === 'DESC' ? '-' : '';
  const column = sorting.column ? kebabCase(sorting.column) : '';
  const sort = `${direction}${column}`;

  return { sort };
};

const fileName = ({ id, number: invoiceNumber, amendmentType }) => {
  switch (amendmentType) {
    case 'cancellation':
      return cancellationInvoiceFilename({ invoiceNumber, id });
    case 'correction':
      return correctionInvoiceFilename({ invoiceNumber, id });
    default:
      return outgoingInvoiceFilename({ invoiceNumber, id });
  }
};

export const apiIndexOutgoingInvoices = (
  pagination,
  sorting,
  filters = {},
  isIndexClassicView
) => ({
  [CALL_API]: {
    params: {
      ...sortingParams(sorting),
      ...paginationParams({ pagination_resource: Resources.OUTGOING_INVOICES, ...pagination }),
      filters,
    },
    endpoint: `/me/outgoing_invoices${isIndexClassicView ? '/classic_view' : ''}`,
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
  },
});

export const apiIndexMoreOutgoingInvoices = ({ page, perPage } = {}, sorting, filters = {}) => ({
  [CALL_API]: {
    params: {
      ...sortingParams(sorting),
      ...paginationParams({ page: page + 1, perPage }),
      filters,
    },
    endpoint: '/me/outgoing_invoices',
    types: [INDEX_MORE_REQUEST, INDEX_MORE_SUCCESS, INDEX_MORE_FAILURE],
  },
});

export const apiSearchOutgoingInvoices = (filter = '', pagination) => ({
  [CALL_API]: {
    params: {
      ...paginationParams(pagination),
      filter,
    },
    endpoint: '/me/outgoing_invoices/classic_view',
    types: [SEARCH_REQUEST, SEARCH_SUCCESS, SEARCH_FAILURE],
  },
});

export const apiSendOutgoingInvoice = ({ id, email, ccEmail }) => ({
  [CALL_API]: {
    endpoint: `/me/outgoing_invoices/${id}/send_by_email`,
    method: 'PUT',
    data: { email, ccEmail },
    types: [SEND_REQUEST, SEND_SUCCESS, SEND_FAILURE],
  },
});

export const apiPreviewOutgoingInvoice = (id, data = {}) => ({
  [CALL_API]: {
    endpoint: `/me/outgoing_invoices/${id}/preview`,
    method: 'GET',
    params: data,
    types: [PREVIEW_REQUEST, PREVIEW_SUCCESS, PREVIEW_FAILURE],
    responseType: 'arraybuffer',
  },
});

const apiTransformIntoDeliveryNote = (deliveryNote) => ({
  [CALL_API]: {
    endpoint: `/me/outgoing_invoices/${deliveryNote}/transform_into_delivery_note`,
    method: 'POST',
    types: [TRANSFORM_REQUEST, TRANSFORM_SUCCESS, TRANSFORM_FAILURE],
  },
});

export const setPage = (page) => ({
  type: PAGINATION,
  page,
});

const extractValues = (values) => {
  const data = {
    ...values,
    clientId: values.client?.id,
    discountPercentage: `${values.discountPercentage}`.includes(',')
      ? pf(values.discountPercentage)
      : parseFloat(values.discountPercentage) || null,
  };

  // We have remove client from data because it's an object used in a client field.
  delete data.client;

  return data;
};

export const newOutgoingInvoiceDraft = () => (dispatch) =>
  dispatch(apiCreateDraft())
    .then(({ data }) => {
      setDraftId(data.id);

      return data.id;
    })
    .catch(apiErrorHandler);

export const indexOutgoingInvoices =
  (pagination, sorting, { noFiltering = false, isIndexClassicView = false } = {}) =>
  (dispatch, getState) => {
    const filters = noFiltering
      ? {}
      : getSanitizedFilters(get(getState(), 'outgoingInvoices.parsedFilters', {}));

    return dispatch(
      apiIndexOutgoingInvoices(pagination, sorting, filters, isIndexClassicView)
    ).catch(apiErrorHandler);
  };

export const indexMoreOutgoingInvoices = (pagination, sorting) => (dispatch, getState) => {
  const filters = getSanitizedFilters(get(getState(), 'outgoingInvoices.parsedFilters', {}));

  return dispatch(apiIndexMoreOutgoingInvoices(pagination, sorting, filters)).catch(
    apiErrorHandler
  );
};

export const resetOutgoingInvoices = () => (dispatch) =>
  dispatch({
    type: INDEX_RESET,
  });

export const searchOutgoingInvoices = (filter, pagination) => (dispatch) =>
  dispatch(apiSearchOutgoingInvoices(filter, pagination)).catch(apiErrorHandler);

export const fetchOutgoingInvoice = (invoiceId) => (dispatch) =>
  dispatch(apiFetchOutgoingInvoice(invoiceId)).catch((err) => {
    apiErrorHandler(err);
    dispatch(push(paths.outgoingInvoices));
  });

export const fetchLineCategories = () => (dispatch) =>
  dispatch(apiFetchLineCategories()).catch(apiErrorHandler);

export const toggleOutgoingInvoiceGrossNet = (invoiceId) => (dispatch, getState) => {
  const insertedAsGross = get(getState(), 'outgoingInvoice.details.insertedAsGross', false);

  return bindServerValidation(apiToggleGrossNet(invoiceId, !insertedAsGross), dispatch)
    .then(() => dispatch(fetchOutgoingInvoice(invoiceId)))
    .then(() => dispatch(fetchOutgoingLineItems(invoiceId)));
};

export const transformOutgoingInvoiceIntoDeliveryNote = (id) => (dispatch) =>
  dispatch(apiTransformIntoDeliveryNote(id)).catch(apiErrorHandler);

export const updateOutgoingInvoice = (invoiceId, invoiceDetails) => (dispatch) =>
  bindServerValidation(
    apiUpdateOutgoingInvoice(invoiceId, extractValues(invoiceDetails)),
    dispatch,
    {
      isReduxForm: true,
    },
    { client_id: { id: 'client' } }
  )
    .then((res) => {
      if (invoiceDetails.transform === 'deliveryNote') {
        dispatch(transformOutgoingInvoiceIntoDeliveryNote(invoiceId)).then(({ data: { id } }) => {
          dispatch(
            replace({
              pathname: paths.editDeliveryNote(id),
              search: `?${FROM_OUTGOING_INVOICE_QUERY_PARAM}`,
            })
          );
        });
      }
      if (invoiceDetails.isRedirectFromProposals) {
        piwikHelpers.trackGoal(9);
      }
      clearDraftId();

      return {
        success: true,
        invoiceNumber: res.data.attributes.number,
      };
    })
    .catch((err = {}) => {
      const isEInvoice = !!invoiceDetails.electronicInvoiceType;
      if (err.response && err.response.status === 424 && isEInvoice) {
        const { amendmentType } = invoiceDetails;

        if (amendmentType === 'none') {
          dispatch(showNotification(downloadEInvoiceFailureSaveAsDraft));
        } else {
          dispatch(showNotification(downloadEInvoiceFailure));
        }
      }
      apiErrorHandler(err);

      throw err;
    });

export const importOutgoingInvoice = (invoiceId, data) => (dispatch) =>
  bindServerValidation(apiImportOutgoingInvoice(invoiceId, extractValues(data)), dispatch, {
    isReduxForm: false,
    customTitle: 'notifications.outgoing_invoices.number_exists.title',
  }).then((res) => {
    clearDraftId();

    return {
      success: true,
      invoiceNumber: res.data.attributes.number,
    };
  });

export const deleteOutgoingInvoice = (invoiceId, password) => (dispatch) =>
  bindServerValidation(apiDeleteOutgoingInvoice(invoiceId, password), dispatch, {
    disableNotification: true,
  }).catch(apiErrorHandler);

export const newOutgoingCancellationInvoice = (invoiceId) => (dispatch) =>
  dispatch(apiCreateCancellationInvoice(invoiceId))
    .then(({ data }) => data.id)
    .catch((err) => {
      apiErrorHandler(err);
      dispatch(push(paths.outgoingInvoices));
    });

export const sortOutgoingInvoices = (column) => ({
  type: SORT_SUCCESS,
  column,
});

export const sendOutgoingInvoice =
  ({ id, email, ccEmail } = {}) =>
  (dispatch) =>
    dispatch(apiSendOutgoingInvoice({ id, email, ccEmail }))
      .then(() => dispatch(showNotification(invoiceSendSuccess)))
      .catch(apiErrorHandler);

export const apiDownloadOutgoingInvoicePDF = (id, data = {}) => ({
  [CALL_API]: {
    method: 'GET',
    endpoint: `/me/outgoing_invoices/${id}/download_pdf`,
    data: { download: true, ...data },
    types: [DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE],
    responseType: 'arraybuffer',
  },
});

export const apiDownloadOutgoingInvoiceXML = (id, data = {}) => ({
  [CALL_API]: {
    method: 'GET',
    endpoint: `/me/outgoing_invoices/${id}/download_xml`,
    data: { download: true, ...data },
    types: [DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE],
    responseType: 'text',
  },
});

export const downloadOutgoingInvoice =
  ({ id, details = {} }) =>
  (dispatch) => {
    const idNumber = id || details.id;
    const apiDownloadOutgoingInvoice =
      details.type === 'xml' ? apiDownloadOutgoingInvoiceXML : apiDownloadOutgoingInvoicePDF;
    return dispatch(apiDownloadOutgoingInvoice(idNumber))
      .then(({ rawResponse }) => {
        downloadData(
          rawResponse,
          fileName({
            id: idNumber,
            ...details,
          }),
          details.type
        );

        return dispatch(showNotification(downloadSuccess));
      })
      .catch(apiErrorHandler);
  };

export const getOutgoingInvoicePreview = (id, data) => (dispatch) =>
  dispatch(apiPreviewOutgoingInvoice(id, extractValues(data)))
    .then(({ rawResponse }) => rawResponse)
    .catch(apiErrorHandler);

export const downloadOutgoingInvoicePreview = (id, data) => (dispatch) =>
  dispatch(getOutgoingInvoicePreview(id, data))
    .then((response) => {
      downloadData(response, fileName(data));
      dispatch(showNotification(downloadSuccess));
    })
    .catch(apiErrorHandler);

export const abortChanges =
  (path = paths.revenue) =>
  (dispatch) => {
    clearDraftId();
    dispatch(push(path));
  };

export const newCorrectionOutgoingInvoiceDraft = (invoiceId) => (dispatch) =>
  dispatch(apiCreateCorrectionDraft(invoiceId))
    .then(({ data }) => {
      setDraftId(data.id);

      return data.id;
    })
    .catch((error) => {
      apiErrorHandler(error);
      dispatch(showNotification(correctionCreateFailure));
    });

export const apiTogglePaidByCash = (invoiceId, isPaidByCash, date) => ({
  [CALL_API]: {
    data: {
      paidByCash: isPaidByCash,
      paidByCashDate: date,
    },
    method: 'PUT',
    endpoint: `/me/outgoing_invoices/${invoiceId}/toggle_paid_by_cash`,
    types: [TOGGLE_PAID_BY_CASH_REQUEST, TOGGLE_PAID_BY_CASH_SUCCESS, TOGGLE_PAID_BY_CASH_FAILURE],
  },
});

export const apiTogglePaidByUnspecified = (invoiceId, isPaidByUnspecified, date, reason) => ({
  [CALL_API]: {
    data: {
      paidByUnspecified: isPaidByUnspecified,
      paidByUnspecifiedDate: date,
      paidByUnspecifiedReason: reason,
    },
    method: 'PUT',
    endpoint: `/me/outgoing_invoices/${invoiceId}/toggle_paid_by_unspecified`,
    types: [
      TOGGLE_PAID_BY_UNSPECIFIED_REQUEST,
      TOGGLE_PAID_BY_UNSPECIFIED_SUCCESS,
      TOGGLE_PAID_BY_UNSPECIFIED_FAILURE,
    ],
  },
});

export const togglePaidByUnspecified =
  (invoiceId, isPaidByUnspecified, date, reason) => (dispatch) =>
    bindServerValidation(
      apiTogglePaidByUnspecified(invoiceId, isPaidByUnspecified, date, reason),
      dispatch
    )
      .then((response) => {
        const paidByUnspecified = get(response, 'data.attributes.paid-by-unspecified');
        dispatch(change('outgoingInvoiceCreator', 'paidByUnspecified', paidByUnspecified));
        dispatch(change('outgoingInvoiceCreator', 'paidByUnspecifiedDate', date));
        dispatch(change('outgoingInvoiceCreator', 'paidByUnspecifiedReason', reason));
        dispatch(change('outgoingInvoiceCreator', 'paid', paidByUnspecified));
        dispatch(
          showNotification(
            isPaidByUnspecified ? tickPaidByUnspecifiedSuccess : untickPaidByUnspecifiedSuccess
          )
        );
        dispatch(fetchOutgoingInvoice(invoiceId));

        return response;
      })
      .catch((error) => {
        dispatch(
          showNotification(
            isPaidByUnspecified ? tickPaidByUnspecifiedFailure : untickPaidByUnspecifiedFailure
          )
        );
        apiErrorHandler(error);

        throw error;
      });

export const togglePaidByCash = (invoiceId, isPaidByCash, date) => (dispatch) =>
  bindServerValidation(apiTogglePaidByCash(invoiceId, isPaidByCash, date), dispatch)
    .then((response) => {
      const paidByCash = get(response, 'data.attributes.paid-by-cash');
      dispatch(change('outgoingInvoiceCreator', 'paidByCash', paidByCash));
      dispatch(change('outgoingInvoiceCreator', 'paid', paidByCash));
      dispatch(showNotification(isPaidByCash ? tickPaidByCashSuccess : untickPaidByCashSuccess));
      dispatch(fetchOutgoingInvoice(invoiceId));

      return response;
    })
    .catch((error) => {
      dispatch(showNotification(isPaidByCash ? tickPaidByCashFailure : untickPaidByCashFailure));
      apiErrorHandler(error);

      throw error;
    });

export const apiCreateClient = (data) => ({
  [CALL_API]: {
    data,
    endpoint: '/me/clients',
    method: 'POST',
    types: [CREATE_CLIENT_REQUEST, CREATE_CLIENT_SUCCESS, CREATE_CLIENT_FAILURE],
  },
});

export const quickCreateClient = (formName) => (values) => (dispatch) =>
  bindServerValidation(apiCreateClient(values), dispatch).then(
    (response) => {
      const client = build(normalize(response), EntityPath.Clients, response.data.id);

      dispatch(change(formName, 'client', client));
      piwikHelpers.trackEvent(
        PIWIK_CATEGORY_REVENUE,
        PIWIK_ACTION_REVENUE_CREATE_CUSTOMER,
        PIWIK_NAME_CREATE_CUSTOMER_SUCCESS
      );
      piwikHelpers.trackGoal(PIWIK_CONVERSION_NEW_CUSTOMER_REVENUE);
    },
    (response) => {
      throw response;
    }
  );

// deletes a suggestion for a connection specified by params (from the local store only - no api call) if it exists.
export const deleteMaybeSuggestion = ({ bankTransferId }) => ({
  type: DELETE_MAYBE_SUGGESTION,
  payload: {
    bankTransferId,
  },
});

export const setStatusFilter = (value) => ({
  type: SET_STATUS_FILTER,
  name: NAMESPACE,
  payload: {
    status: value,
  },
});

export const setQueryParam = actions.setQueryParam({ name: NAMESPACE });

export const setDateRange = (param) => (dispatch) => (values) =>
  dispatch({
    type: SET_DATE_RANGE,
    name: NAMESPACE,
    payload: {
      param,
      values,
    },
  });

export const clearFilters = ({ clearStatus = false } = {}) => ({
  type: CLEAR_FILTERS,
  name: NAMESPACE,
  payload: { clearStatus },
});

export const clearFiltersToInitial = () => ({
  type: CLEAR_FILTERS_TO_INITIAL,
  name: NAMESPACE,
});

export const fetchOutgoingInvoiceResources = (id) => (dispatch) =>
  Promise.all([
    dispatch(fetchOutgoingInvoice(id)),
    dispatch(fetchLineCategories()),
    dispatch(fetchOutgoingLineItems(id)),
    dispatch(fetchBankTransfers(id)),
    dispatch(fetchInvoiceTemplates()),
    dispatch(fetchCustomerDefaults()),
  ]);

export const fetchNewOutgoingInvoice = () => async (dispatch) => {
  await dispatch(fetchCustomerDefaults());
  const id = await dispatch(newOutgoingInvoiceDraft());

  return dispatch(fetchOutgoingInvoiceResources(id)).then(() => id);
};

export const fetchNewOutgoingCancellationInvoice = (invoiceId) => async (dispatch) => {
  await dispatch(fetchCustomerDefaults());
  const id = await dispatch(newOutgoingCancellationInvoice(invoiceId));

  return dispatch(fetchOutgoingInvoiceResources(id));
};

export const fetchNewCorrectionOutgoingInvoice = (invoiceId) => async (dispatch) => {
  await dispatch(fetchCustomerDefaults());
  const id = await dispatch(newCorrectionOutgoingInvoiceDraft(invoiceId));

  return dispatch(fetchOutgoingInvoiceResources(id));
};

export const apiDownloadReminder = (id) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: `/me/payment_reminders/${id}/preview`,
    types: [DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE],
    responseType: 'arraybuffer',
  },
});

export const apiSendReminder = ({ id, email, ccEmail }) => ({
  [CALL_API]: {
    endpoint: `/me/payment_reminders/${id}/send_by_email`,
    method: 'PUT',
    data: { email, ccEmail },
    types: [SEND_REQUEST, SEND_SUCCESS, SEND_FAILURE],
  },
});

export const downloadReminder =
  ({ reminderId, ...rest } = {}) =>
  (dispatch) =>
    dispatch(apiDownloadReminder(reminderId))
      .then(({ rawResponse }) => {
        downloadData(
          rawResponse,
          paymentReminderFilename({
            ...rest,
          })
        );
        return dispatch(showNotification(reminderDownloadSuccess));
      })
      .catch(apiErrorHandler);

export const sendReminder =
  ({ id, email, ccEmail } = {}) =>
  (dispatch) =>
    dispatch(apiSendReminder({ id, email, ccEmail }))
      .then(() => dispatch(showNotification(reminderSendSuccess)))
      .catch(apiErrorHandler);

export const apiCreateReminder = (
  outgoingInvoiceId,
  { introduction, footer, currentDueDate } = {}
) => ({
  [CALL_API]: {
    data: {
      outgoingInvoiceId,
      introduction,
      footer,
      currentDueDate,
    },
    method: 'POST',
    endpoint: '/me/payment_reminders',
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const createReminder =
  ({ id: invoiceId, paymentRemindersCount, companyName, redirect = true, ...data } = {}) =>
  (dispatch) =>
    bindServerValidation(apiCreateReminder(invoiceId, data), dispatch)
      .then(({ response: { data: result = {} } = {} }) => {
        if (redirect) {
          dispatch(push(paths.outgoingInvoices));
        }

        return { ...result };
      })
      .catch(apiErrorHandler);

export const apiUpdateReminder = (id, { introduction, footer, currentDueDate } = {}) => ({
  [CALL_API]: {
    data: {
      introduction,
      footer,
      currentDueDate,
    },
    method: 'PUT',
    endpoint: `/me/payment_reminders/${id}`,
    types: [REMINDER_UPDATE_REQUEST, REMINDER_UPDATE_SUCCESS, REMINDER_UPDATE_FAILURE],
  },
});

export const apifetchSubInvoices = (invoiceId) => ({
  [CALL_API]: {
    method: 'GET',
    endpoint: `/me/outgoing_invoices/${invoiceId}/sub_invoices`,
    types: [FETCH_REQUEST_SUB_INVOICES, FETCH_SUCCESS_SUB_INVOICES, FETCH_FAILURE_SUB_INVOICES],
  },
});

export const updateReminder =
  ({ reminderId, redirect = true, ...data } = {}) =>
  (dispatch) =>
    bindServerValidation(apiUpdateReminder(reminderId, data), dispatch)
      .then(({ response: { data: result = {} } = {} }) => {
        if (redirect) {
          dispatch(push(paths.outgoingInvoices));
        }

        return { ...result };
      })
      .catch(apiErrorHandler);

export const destroyForm = () => ({ type: DESTROY_FORM });

export const fetchSubInvoices = (invoiceId) => (dispatch) =>
  dispatch(apifetchSubInvoices(invoiceId)).catch(apiErrorHandler);

export const reorderLineItems = (invoiceId, reorderedLineItems) => (dispatch) => {
  const lineItemsOrder = reorderedLineItems.map(({ id, ordinalNumber }) => ({
    id: Number(id),
    ordinalNumber: Number(ordinalNumber),
  }));
  dispatch({ type: REORDER_LINE_ITEMS_LOCAL, payload: reorderedLineItems });

  return dispatch(apiReorderLineItems(invoiceId, lineItemsOrder)).catch(apiErrorHandler);
};

const apiDuplicateOutgoingInvoice = (outgoingInvoiceId) => ({
  [CALL_API]: {
    endpoint: `/me/outgoing_invoices/${outgoingInvoiceId}/duplicate`,
    method: 'POST',
    types: [DUPLICATE_INVOICE_REQUEST, DUPLICATE_INVOICE_SUCCESS, DUPLICATE_INVOICE_FAILURE],
  },
});

export const duplicateOutgoingInvoice = (outgoingInvoiceId) => (dispatch) =>
  dispatch(apiDuplicateOutgoingInvoice(outgoingInvoiceId))
    .catch(apiErrorHandler)
    .then(({ data: { id: draftId } }) => {
      dispatch(
        replace({
          pathname: paths.editOutgoingInvoice(draftId),
          search: `?${FROM_DUPLICATE_QUERY_PARAM}`,
        })
      );
    });

export const deleteAllBankTransferConnections = (invoiceId) => (dispatch) =>
  dispatch(apiDeleteAllBankTransferConnections(invoiceId))
    .then(() => dispatch(fetchOutgoingInvoice(invoiceId)))
    .catch(apiErrorHandler);
