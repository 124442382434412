import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { bool, func, shape, string } from 'prop-types';

import { duplicateDeliveryNote as duplicateDeliveryNoteAction } from 'actions/delivery-note';
import * as Piwik from 'constants/piwik';
import paths from 'routes/paths';
import tableStyles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import I18n from 'components/I18n';
import ActionDelete from 'components/IndexActionsContainer/actionIcons/ActionDelete';
import ActionDuplicate from 'components/IndexActionsContainer/actionIcons/ActionDuplicate';
import ActionEdit from 'components/IndexActionsContainer/actionIcons/ActionEdit';
import ActionView from 'components/IndexActionsContainer/actionIcons/ActionView';
import IndexActionsContainer from 'components/IndexActionsContainer/IndexActionsContainer';
import StatusBadge from 'features/deliveryNotes/StatusBadge/StatusBadge';
import { Status as DeliveryNoteStatus } from 'features/deliveryNotes/types';

import localStyles from '../DeliveryNotesTable.module.css';

export const styles = { ...tableStyles, ...localStyles };

class DeliveryNoteRow extends Component {
  handleShowClick = () =>
    piwikHelpers.trackEvent(Piwik.CATEGORY_DELIVERY_NOTES, Piwik.ACTION_SHOW_DELIVERY_NOTE);

  handleEditClick = () =>
    piwikHelpers.trackEvent(Piwik.CATEGORY_DELIVERY_NOTES, Piwik.ACTION_EDIT_DELIVERY_NOTE);

  handleActionDuplicate = (event) => {
    event.preventDefault();
    const {
      duplicateDeliveryNote,
      item: { id },
    } = this.props;

    duplicateDeliveryNote(id);
  };

  render() {
    const {
      item: deliveryNote,
      onDelete,
      showPath = paths.showDeliveryNote,
      editPath = paths.editDeliveryNote,
    } = this.props;

    const permittedActions = get(deliveryNote, 'meta.actions', {});
    const canShow = permittedActions.show;
    const canEdit = permittedActions.edit;
    const canDelete = permittedActions.delete;
    const canDuplicate = permittedActions.duplicate;
    const status = deliveryNote.draft ? DeliveryNoteStatus.Draft : DeliveryNoteStatus.Saved;

    return (
      <tr className={styles.main} data-id="DeliveryNoteRow">
        <td className={styles.column}>
          <div className={styles.cell}>
            <I18n
              t="delivery_notes.table.columns.delivery_note_status"
              className={styles.cellHeader}
            />
            <StatusBadge status={status} />
          </div>
        </td>
        <td className={styles.column}>
          <div className={styles.cell}>
            <I18n t="delivery_notes.table.columns.client" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="DeliveryNotesRow:client-name">
              {get(deliveryNote, 'relationships.client.name')}
            </CellContentWithTooltip>
          </div>
        </td>
        <td className={styles.column}>
          <div className={styles.cell}>
            <I18n
              t="delivery_notes.table.columns.delivery_note_number"
              className={styles.cellHeader}
            />
            <CellContentWithTooltip dataId="DeliveryNotesRow:number">
              {deliveryNote.deliveryNoteNumber}
            </CellContentWithTooltip>
          </div>
        </td>
        <td className={styles.column}>
          <div className={styles.cell}>
            <I18n t="delivery_notes.table.columns.subject" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="DeliveryNotesRow:subject">
              {deliveryNote.subject}
            </CellContentWithTooltip>
          </div>
        </td>
        <td className={styles.columnCenter}>
          <div className={styles.cell}>
            <I18n t="delivery_notes.table.columns.delivery_date" className={styles.cellHeader} />
            <div className={styles.cell} data-id="DeliveryNotesRow:creation-date">
              {deliveryNote.deliveryNoteDate}
            </div>
          </div>
        </td>
        <td className={styles.columnCenter}>
          <IndexActionsContainer>
            <ActionView
              id="delivery-notes-show-button"
              title={t('features.delivery_note.table.actions.show')}
              onClick={this.handleShowClick}
              to={showPath(deliveryNote.id)}
              label={t('features.delivery_note.table.actions.show')}
              visible={canShow}
              dataId="DeliveryNotesRow:button-show"
            />
            <ActionDuplicate
              title={t('features.delivery_note.table.actions.duplicate')}
              label={t('features.delivery_note.table.actions.duplicate')}
              dataId="DeliveryNoteRow:button-duplicate"
              visible={canDuplicate}
              onClick={this.handleActionDuplicate}
            />
            <ActionEdit
              id="delivery-notes-edit-button"
              to={editPath(deliveryNote.id)}
              title={t('features.delivery_note.table.actions.edit')}
              onClick={this.handleEditClick}
              label={t('features.delivery_note.table.actions.edit')}
              visible={canEdit}
              dataId="DeliveryNotesRow:button-edit"
            />
            <ActionDelete
              title={t('features.delivery_note.table.actions.delete')}
              onClick={() => onDelete(deliveryNote)}
              label={t('features.delivery_note.table.actions.delete')}
              visible={canDelete}
              dataId="DeliveryNotesRow:button-delete"
            />
          </IndexActionsContainer>
        </td>
      </tr>
    );
  }
}

DeliveryNoteRow.propTypes = {
  deliveryNote: shape({
    city: string,
    companyName: string,
    idNumber: string,
    lastName: string,
    meta: shape({
      actions: shape({
        delete: bool,
        edit: bool,
        show: bool,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  showPath: func,
  editPath: func,
  onDelete: func.isRequired,
  duplicateDeliveryNote: func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  duplicateDeliveryNote: (deliveryNoteId) => dispatch(duplicateDeliveryNoteAction(deliveryNoteId)),
});

export default connect(null, mapDispatchToProps)(DeliveryNoteRow);
