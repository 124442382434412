import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { snakeCase, upperFirst } from 'lodash';
import PropTypes from 'prop-types';

import { sortOutgoingInvoices } from 'actions/outgoing-invoice';
import styles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';
import If from 'components/Conditions/If';
import I18n from 'components/I18n';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import InvoiceColumn from 'components/InvoiceColumn/InvoiceColumn';

const SortingIndicator = ({ active, direction }) => (
  <div className={styles.sortingIndicator} title={t('tables.toggle_sorting')}>
    <div
      className={cx(styles.sortingButton, styles.ascending, {
        [styles.isActive]: active && direction === 'ASC',
      })}
      data-id="SortingIndicator:ascending"
    />
    <div
      className={cx(styles.sortingButton, styles.descending, {
        [styles.isActive]: active && direction === 'DESC',
      })}
      data-id="SortingIndicator:descending"
    />
  </div>
);

SortingIndicator.propTypes = {
  active: PropTypes.bool,
  direction: PropTypes.string,
};

const InvoiceHeader = ({
  sortInvoices,
  column,
  sortable,
  sortBy,
  info,
  align,
  hidden,
  ...rest
}) => (
  <InvoiceColumn
    {...rest}
    onClick={sortable ? sortInvoices(column) : null}
    className={cx(styles.columnHeader, {
      [styles.sortable]: sortable,
      [styles[`pullHeader${upperFirst(align)}`]]: align,
    })}
    data-id={`Table:header-${column}`}
  >
    {!hidden && (
      <>
        <I18n t={`revenue.table.columns.${snakeCase(column)}`} />
        <If ok={sortable}>
          <SortingIndicator direction={sortBy.direction} active={sortBy.column === column} />
        </If>
        <If ok={Boolean(info)}>
          <InfoIcon align="left" className={styles.infoIcon} text={info} />
        </If>
      </>
    )}
  </InvoiceColumn>
);

InvoiceHeader.propTypes = {
  sortInvoices: PropTypes.func,
  column: PropTypes.string,
  sortable: PropTypes.bool,
  align: PropTypes.string,
  hidden: PropTypes.bool,
  sortBy: PropTypes.shape({
    direction: PropTypes.string,
    column: PropTypes.string,
  }),
  info: PropTypes.string,
};

InvoiceHeader.defaultProps = {
  sortInvoices: (ident) => ident,
  column: '',
  sortable: false,
  sortBy: {},
  info: '',
  align: '',
};

export default connect(
  (state) => ({
    sortBy: state.outgoingInvoices.sorting,
  }),
  (dispatch) => ({
    sortInvoices: (column) => () => dispatch(sortOutgoingInvoices(column)),
  })
)(InvoiceHeader);
