import styled, { css } from 'styled-components';

import { Breakpoints } from 'redesign/styles/breakpoints';

type WrapperProps = {
  $responsive: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: grid;
  align-items: center;
  grid-column-gap: ${({ theme }) => theme.space['2']};

  ${({ $responsive }) =>
    $responsive
      ? css`
          grid-template-columns: max-content max-content;

          > div:first-child {
            display: none;
          }

          @media (min-width: ${Breakpoints.sm}) {
            width: 140px;
            grid-template-columns: auto max-content max-content;

            > div:first-child {
              display: flex;
            }
          }
        `
      : css`
          width: 140px;
          grid-template-columns: auto max-content max-content;

          > div:first-child {
            display: flex;
          }
        `}
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  outline: 0;
  user-select: none;

  &:disabled,
  &:disabled:hover {
    color: ${({ theme }) => theme.colors.grey['020']};
    cursor: not-allowed;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.vrblue['075']};
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;
