import { combineReducers } from 'redux';

import {
  OBS_FETCH_FAILURE,
  OBS_FETCH_SUCCESS,
  SET_FACTORS_FAILURE,
  SET_FACTORS_REQUEST,
  SET_FACTORS_SUCCESS,
} from 'constants/liquidity';

const obs = (state = { isOBSQualified: false }, action) => {
  switch (action.type) {
    case OBS_FETCH_SUCCESS:
      return {
        ...state,
        isOBSQualified: action.response.obs_qualified,
      };
    case OBS_FETCH_FAILURE:
      return { isOBSQualified: false };
    default:
      return state;
  }
};

const meta = (state = { isLoading: false, data: {} }, action) => {
  switch (action.type) {
    case SET_FACTORS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          ...action.response.data.attributes,
        },
      };
    case SET_FACTORS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SET_FACTORS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  obs,
  meta,
});
