import React, { FunctionComponent } from 'react';
import cx from 'classnames';

import SectionHeader from 'components/SectionHeader/SectionHeader';
import tableStyles from 'components/Tabs/Tabs.module.css';

import styles from './ProfileSection.module.css';

interface ProfileSectionProps {
  heading: string;
  subheading?: string;
  description?: string;
  theme?: {
    main?: string;
    content?: string;
  };
  className?: string;
  requiredMessage?: string;
}

const ProfileSection: FunctionComponent<ProfileSectionProps> = ({
  heading,
  subheading,
  description,
  theme = {},
  children,
  className,
  requiredMessage,
}) => (
  <div className={cx(tableStyles.main, theme.main, className)}>
    <div className={cx(tableStyles.content, theme.content)}>
      <SectionHeader
        header={heading}
        subheader={subheading}
        description={description}
        requiredMessage={requiredMessage}
      />
      <div className={styles.container}>{children}</div>
    </div>
  </div>
);

export default ProfileSection;
