import React from 'react';
import PropTypes from 'prop-types';

import styles from './NativeSelect.module.css';

const Select = ({ shown, disabled, options, value, onChange, ...rest }) => {
  if (!shown) return null;

  return (
    <select
      disabled={disabled}
      className={styles.select}
      value={value}
      onChange={(e) => {
        onChange({ value: e.target.value });
      }}
      {...rest}
    >
      {/* Dummy option so the browser won't choose first option by default.
        Selecting first option wouldn't trigger onChange in that case. */}
      {!value && <option disabled selected value="" />}
      {options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      }),
      PropTypes.shape({}),
    ])
  ),
  shown: PropTypes.bool,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  shown: false,
  disabled: false,
  options: [],
};

export default Select;
