import { WrappedFieldProps } from 'redux-form';

import { existChecker, wantedErrorChecker } from 'shared/utils/form-checking';

const wantedErrors = [
  'passwords-not-specials',
  'passwords-not-both-cases',
  'passwords-not-long-enough',
  'passwords-not-match',
  'passwords-not-filled',
];

export default ({
  password,
  passwordConfirm,
}: {
  password: WrappedFieldProps;
  passwordConfirm: WrappedFieldProps;
}) => {
  const { meta: passwordMeta } = password;
  const { meta: passwordConfirmMeta } = passwordConfirm;

  if (!existChecker(passwordMeta)) {
    return null;
  }
  if (!existChecker(passwordConfirmMeta)) {
    return null;
  }

  return (
    wantedErrorChecker(passwordMeta, wantedErrors) ||
    wantedErrorChecker(passwordConfirmMeta, wantedErrors)
  );
};
