import { get, isEmpty, last } from 'lodash';
import moment from 'moment';
import { combineReducers } from 'redux';

import {
  CLEAR_EXPORT_STATUSES,
  DATEV_PREVIEW_TABLE_COUNT_RESET,
  ENTER_EDIT_INVOICE_MODE,
  EXIT_EDIT_INVOICE_MODE,
  EXPORT_FAILURE,
  EXPORT_FINISHED,
  EXPORT_REQUEST,
  EXPORT_SUCCESS,
  FETCH_CREDS_REQUEST,
  FETCH_CREDS_SUCCESS,
  FETCH_DATES_FAILURE,
  FETCH_DATES_REQUEST,
  FETCH_DATES_SUCCESS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SORT,
  INDEX_SUCCESS,
  REMOVE_END_DATE_ERROR,
  REMOVE_MISSING_DATES_ERROR,
  REMOVE_START_AND_END_DATE_ERROR,
  SET_END_DATE_ERROR,
  SET_MISSING_DATES_ERROR,
  SET_START_AND_END_DATE_ERROR,
  VALIDATION_REQUEST,
  VALIDATION_SUCCESS,
} from 'constants/datev';
import { getPaginationReducer } from 'reducers/pagination';
import {
  transformDefaultToReversedFormat,
  transformReversedToDefaultFormat,
} from 'shared/utils/date/transform';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case INDEX_REQUEST:
      return true;
    case INDEX_SUCCESS:
    case INDEX_FAILURE:
      return false;
    default:
      return state;
  }
};

const mapper = (data) => ({
  id: data.id,
  ...data.attributes,
});

const data = (state = [], action) => {
  switch (action.type) {
    case INDEX_SUCCESS: {
      const { response } = action;
      return response.data.map(mapper);
    }
    default:
      return state;
  }
};

const pagination = getPaginationReducer(INDEX_SUCCESS);

const defaultSortingState = {
  column: 'creation-date',
  direction: 'DESC',
};

export const sorting = (state = defaultSortingState, action) => {
  switch (action.type) {
    case INDEX_SORT: {
      let direction = 'ASC';
      if (state.column === action.column && state.direction === 'ASC') direction = 'DESC';
      return {
        column: action.column,
        direction,
      };
    }
    default:
      return state;
  }
};

export const meta = (state = null, action) => {
  switch (action.type) {
    case INDEX_SUCCESS:
      return get(action, 'response.meta', null);
    default:
      return state;
  }
};

const today = moment().format('DD.MM.YYYY');

const EXPORT_DATES_DEFAULT_STATE = {
  lastReportEndDate: null,
  nextReportDueDate: today,
  nextReportStartDate: moment().startOf('month').format('DD.MM.YYYY'),
  nextReportEndDate: today,
  exportEndDateError: false,
  exportMissingDatesError: false,
  exportStartAndEndDateError: false,
};
const dates = (state = EXPORT_DATES_DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_DATES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_DATES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case FETCH_DATES_SUCCESS: {
      const { response } = action;
      const { lastReportEndDate } = state;
      return {
        ...state,
        isFetching: false,
        lastReportEndDate: response.previous_reports_latest_end_date || lastReportEndDate,
        nextReportDueDate: response.next_report_due_date,
        nextReportStartDate: response.next_report_start_date,
        nextReportEndDate: response.next_report_end_date,
      };
    }

    case INDEX_SUCCESS: {
      const { response } = action;
      const datevExports = response.data.map(mapper);
      if (isEmpty(datevExports)) return state;

      const exportDates = datevExports
        .map((datevExport) => datevExport.endDate)
        .map(transformDefaultToReversedFormat)
        .sort()
        .map(transformReversedToDefaultFormat);

      return {
        ...state,
        lastReportEndDate: last(exportDates),
      };
    }

    case SET_END_DATE_ERROR:
      return {
        ...state,
        exportEndDateError: true,
      };

    case REMOVE_END_DATE_ERROR:
      return {
        ...state,
        exportEndDateError: false,
      };

    case SET_MISSING_DATES_ERROR:
      return {
        ...state,
        exportMissingDatesError: true,
      };

    case REMOVE_MISSING_DATES_ERROR:
      return {
        ...state,
        exportMissingDatesError: false,
      };

    case SET_START_AND_END_DATE_ERROR:
      return {
        ...state,
        exportStartAndEndDateError: true,
      };

    case REMOVE_START_AND_END_DATE_ERROR:
      return {
        ...state,
        exportStartAndEndDateError: false,
      };

    default:
      return state;
  }
};

const defaultStateForPreviewCounts = {
  outgoingInvoicesCount: null,
  incomingInvoicesCount: null,
  clientsCount: null,
  suppliersCount: null,
  revenueCashTransactionsCount: null,
  expenseCashTransactionsCount: null,
};

const datevPreviewCounts = (state = defaultStateForPreviewCounts, action) => {
  switch (action.type) {
    case VALIDATION_SUCCESS: {
      const {
        response: { metadata },
      } = action;

      return {
        outgoingInvoicesCount: metadata.number_of_outgoing_invoices,
        incomingInvoicesCount: metadata.number_of_incoming_invoices,
        clientsCount: metadata.number_of_clients,
        suppliersCount: metadata.number_of_suppliers,
        revenueCashTransactionsCount: metadata.number_of_revenue_cash_transactions,
        expenseCashTransactionsCount: metadata.number_of_expense_cash_transactions,
      };
    }

    case FETCH_SUCCESS: {
      const {
        response: {
          data: { attributes },
        },
      } = action;

      return {
        outgoingInvoicesCount: attributes.outgoingInvoicesCount,
        incomingInvoicesCount: attributes.incomingInvoicesCount,
        clientsCount: attributes.clientsCount,
        suppliersCount: attributes.suppliersCount,
        revenueCashTransactionsCount: attributes.revenueCashTransactionsCount,
        expenseCashTransactionsCount: attributes.expenseCashTransactionsCount,
      };
    }

    case VALIDATION_REQUEST:
    case DATEV_PREVIEW_TABLE_COUNT_RESET:
    case FETCH_REQUEST:
      return {
        ...defaultStateForPreviewCounts,
      };

    default:
      return state;
  }
};

const INVOICE_ACTIONS_DEFAULT_STATE = {
  isEditingInvoice: false,
  invoiceId: '',
  data: [],
};

const invoiceActions = (state = INVOICE_ACTIONS_DEFAULT_STATE, action) => {
  switch (action.type) {
    case ENTER_EDIT_INVOICE_MODE:
      return {
        ...state,
        isEditingInvoice: true,
        invoiceId: action.invoiceId,
      };

    case EXIT_EDIT_INVOICE_MODE:
      return {
        ...state,
        isEditingInvoice: false,
      };

    default:
      return state;
  }
};

const exportStatus = (
  state = { isExporting: false, isExportSuccessful: false, isExportFinished: false },
  action
) => {
  switch (action.type) {
    case EXPORT_REQUEST:
      return {
        ...state,
        isExporting: true,
        isExportSuccessful: false,
      };
    case EXPORT_FAILURE:
      return {
        ...state,
        isExportSuccessful: false,
        isExportFinished: true,
      };
    case EXPORT_SUCCESS:
      return {
        ...state,
        isExportSuccessful: true,
        isExportFinished: true,
      };
    case EXPORT_FINISHED:
      return {
        ...state,
        isExporting: false,
        isExportSuccessful: false,
      };
    case CLEAR_EXPORT_STATUSES: {
      return { ...state, isExporting: false, isExportSuccessful: false, isExportFinished: false };
    }
    default:
      return state;
  }
};

const fetchedCredentialsInitialState = {
  email: null,
  password: null,
};

const fetchedCredentials = (state = fetchedCredentialsInitialState, action) => {
  switch (action.type) {
    case FETCH_CREDS_REQUEST:
      return fetchedCredentialsInitialState;
    case FETCH_CREDS_SUCCESS:
      return {
        ...state,
        email: get(action, 'response.data.attributes.email'),
        password: get(action, 'response.data.attributes.password'),
      };
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  data,
  meta,
  pagination,
  sorting,
  exportStatus,
  dates,
  invoiceActions,
  datevPreviewCounts,
  fetchedCredentials,
});
