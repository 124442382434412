import { NO_CURRENT_PLAN_END_DATE } from 'constants/payment-plans';

import getDaysToDate from './get-days-to-date';

const getDaysToPremiumBilling = (trialEndDate: string = '') => {
  if (trialEndDate === NO_CURRENT_PLAN_END_DATE)
    return { displayDate: NO_CURRENT_PLAN_END_DATE, isPremiumBillingStarted: true };

  const daysToPremiumBilling = getDaysToDate(trialEndDate);
  const isPremiumBillingStarted = daysToPremiumBilling < 0;

  return { displayDate: daysToPremiumBilling, isPremiumBillingStarted };
};

export default getDaysToPremiumBilling;
