import React, { SVGAttributes } from 'react';

const IconTip = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd">
      <path
        d="M15.335 7.19c-3.733.425-6.741 3.413-7.18 7.137a8.154 8.154 0 0 0 1.892 6.288l.196.221c1.075 1.174 1.82 2.824 1.944 4.352.056.695.64 1.225 1.335 1.225h5.448a1.34 1.34 0 0 0 1.303-1.02c.461-1.856 1.132-3.596 1.89-4.371a8.129 8.129 0 0 0 2.33-5.704c0-4.795-4.102-8.553-8.904-8.152l-.254.025Zm7.602 8.128a6.57 6.57 0 0 1-1.678 4.395l-.385.415c-.873 1.01-1.526 2.662-2.014 4.518l-.055.212h-5.09l.022.205c-.144-1.775-.947-3.635-2.135-5.035l-.213-.242a6.6 6.6 0 0 1-1.69-5.276c.354-3.007 2.795-5.432 5.812-5.775 3.987-.452 7.426 2.631 7.426 6.583Z"
        fillRule="nonzero"
      />
      <path
        d="M19.874 14.082a.777.777 0 0 1 1.173 1.014l-.075.087-3.883 3.873v6.333a.778.778 0 0 1-.671.77l-.106.007a.778.778 0 0 1-.77-.672l-.007-.105V18.41l4.339-4.329Z"
        fillRule="nonzero"
      />
      <path
        d="M11.615 14.092a.778.778 0 0 1 1.012-.076l.087.075 4.11 4.102a.777.777 0 0 1-1.01 1.175l-.087-.075-4.111-4.101a.777.777 0 0 1-.001-1.1Z"
        fillRule="nonzero"
      />
      <path d="M19.404 28.955h-6.22a.777.777 0 1 1 0-1.555h6.22a.777.777 0 0 1 0 1.555ZM17.849 31.482h-3.11a.777.777 0 1 1 0-1.555h3.11a.777.777 0 0 1 0 1.555Z" />
      <path
        d="M16.09.178a1.07 1.07 0 0 1 1.064.952l.006.117v3.207a1.069 1.069 0 0 1-2.132.116l-.006-.116V1.247c0-.59.479-1.07 1.069-1.07ZM25.495 4.167a1.069 1.069 0 0 1 1.6 1.413l-.09.1-2.27 2.265a1.069 1.069 0 0 1-1.599-1.412l.09-.1 2.27-2.266ZM5.334 4.325a1.07 1.07 0 0 1 1.41-.09l.102.088 2.27 2.265a1.069 1.069 0 0 1-1.41 1.603l-.1-.09-2.27-2.264a1.069 1.069 0 0 1-.002-1.512ZM29.332 12.017a1.069 1.069 0 0 1 .116 2.131l-.116.007h-3.207a1.069 1.069 0 0 1-.116-2.132l.116-.006h3.207ZM6.454 12.017a1.069 1.069 0 0 1 .117 2.131l-.117.007H3.247a1.069 1.069 0 0 1-.116-2.132l.116-.006h3.207Z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default IconTip;
