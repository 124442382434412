import { CALL_API } from 'constants/api';
import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/payment-plans';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apifetchPlans = () => ({
  [CALL_API]: {
    endpoint: '/me/payment_plans',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const fetchPlans = () => (dispatch) => dispatch(apifetchPlans()).catch(apiErrorHandler);
