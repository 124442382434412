import React, { ElementType, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import CreateAction from 'containers/Datev/CreateAction';
import CardView, { HeadingSection, Section } from 'components/CardView';
import EmptyEntryPage from 'components/EmptyEntryPage/EmptyEntryPage';
import I18n from 'components/I18n';

import styles from './DefaultInfoBox.module.css';

interface IEmptyInfoBox {
  dataIdPrefix: string;
  image: ElementType;
  headingText: string;
  infoTextHeader: string;
  infoText: string;
  buttonText: string;
  buttonPath: string;
  customStyles: {};
}

interface IInfoBox {
  headingTextKey: string;
  children: ReactNode;
  buttonTextKey: string;
  hasButton: boolean;
}

const EmptyInfoBox = ({
  dataIdPrefix,
  image,
  headingText,
  infoTextHeader,
  infoText,
  buttonText,
  buttonPath,
  customStyles = {},
}: IEmptyInfoBox) => {
  const { push } = useHistory();

  return (
    <CardView className={styles.halfWidth}>
      <HeadingSection bold>{headingText}</HeadingSection>
      <EmptyEntryPage
        dataIdPrefix={dataIdPrefix}
        customClasses={{ ...customStyles }}
        Image={image}
        header={infoTextHeader}
        subheader={infoText}
        createButtonLabel={buttonText}
        onCreateButtonClick={() => push(buttonPath)}
      />
    </CardView>
  );
};

export const InfoBox = ({ headingTextKey, buttonTextKey, hasButton, children }: IInfoBox) => (
  <CardView className={styles.halfWidth}>
    <HeadingSection bold className={(hasButton && styles.headerWithButton) || undefined}>
      <I18n t={headingTextKey} />
      {hasButton && (
        <div className={cx(styles.pullRight)}>
          <CreateAction
            dataId="Datev:button-new-datev-from-info-box"
            customText={buttonTextKey}
            nextDueDateExport={true}
          />
        </div>
      )}
    </HeadingSection>
    <Section className={cx(styles.small)}>{children}</Section>
  </CardView>
);

export default EmptyInfoBox;
