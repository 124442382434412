import React from 'react';
import cx from 'classnames';

import IconArrowDown from 'components/Icons/IconArrowDown';

import styles from './ArrowIcon.module.css';

type ArrowIconProps = {
  isOpen?: boolean;
  pointRight?: boolean;
  pointLeft?: boolean;
  onClick?: () => void;
  className?: string;
  dataId?: string;
};

const ArrowIcon = ({
  isOpen = false,
  onClick,
  className,
  pointRight = false,
  pointLeft = false,
  dataId,
}: ArrowIconProps) => (
  <div
    onClick={onClick}
    data-id={dataId}
    className={cx(className, styles.arrow, {
      [styles.isOpen]: isOpen,
      [styles.pointLeft]: pointLeft,
      [styles.pointRight]: pointRight,
    })}
  >
    <IconArrowDown />
  </div>
);

export default ArrowIcon;
