import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { push } from 'connected-react-router';
import { bool, func, string } from 'prop-types';

import * as Piwik from 'constants/piwik';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import I18n from 'components/I18n';
import { ConfirmationModal } from 'components/Modal';
import modalStyles from 'components/Modal/Modal.module.css';

import localStyles from './NewOrderConfirmationButton.module.css';

const styles = { ...modalStyles, ...localStyles };

class NewOrderConfirmationButton extends Component {
  state = {
    isModalOpen: false,
  };

  setModalVisibility = (value) => () => {
    this.setState({ isModalOpen: value });
  };

  handleClick = () => {
    const { pathname } = this.props;
    if (pathname === paths.orderConfirmation) {
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_ORDER_CONFIRMATIONS,
        Piwik.ACTION_NEW_ORDER_CONFIRMATION
      );
    }

    this.setModalVisibility(true)();
  };

  render() {
    if (this.props.showModal) {
      return (
        <div>
          <button
            className={cx(styles.newInvoice, styles.link)}
            onClick={this.handleClick}
            data-id="OrderConfirmation:button-new"
          >
            <I18n t="features.order_confirmation.table.add_new" />
          </button>
          <ConfirmationModal
            dangerousAction
            isOpen={this.state.isModalOpen}
            onClose={this.setModalVisibility(false)}
            onConfirm={() => this.props.push(paths.newOrderConfirmation)}
            closeLabel={t('modals.cancel')}
            confirmLabel={t('modals.no_bank_account_data.continue')}
          >
            <div className={cx(styles.modalHeader)}>
              <I18n t="modals.no_bank_account_data.title" />
            </div>
            <div className={cx(styles.body)}>
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t('modals.no_bank_account_data.order_confirmation_message_html', {
                    bank_accounts_path: paths.bankAccounts,
                    invoice_templates_path: paths.settingsTemplateLogoAndBriefpapier,
                  }),
                }}
              />
            </div>
          </ConfirmationModal>
        </div>
      );
    }

    return (
      <Link
        to={paths.newOrderConfirmation}
        className={cx(styles.newInvoice, styles.link)}
        data-id="OrderConfirmation:button-new"
      >
        <I18n t="features.order_confirmation.table.add_new" />
      </Link>
    );
  }
}

NewOrderConfirmationButton.propTypes = {
  showModal: bool.isRequired,
  push: func.isRequired,
  pathname: string,
};

const mapStateToProps = (state) => ({ location: state.router.location.pathname });

export default connect(mapStateToProps, {
  push,
})(NewOrderConfirmationButton);
