import React, { Component } from 'react';
import cx from 'classnames';
import { string } from 'prop-types';

import { t } from 'shared/utils';

import CancelAccountModal from './CancelAccountModal';

import styles from './CancelAccountButton.module.css';

class CancelAccountButton extends Component {
  state = {
    modal: false,
  };

  render() {
    const { className, label } = this.props;
    const { modal } = this.state;

    const showModal = () => this.setState({ modal: true });
    const hideModal = () => this.setState({ modal: false });
    const displayLabel = label || t('profile.cancel');

    return (
      <div>
        <button
          className={cx(className, {
            [styles.deleteAccountButton]: !className,
          })}
          id="delete-account-button"
          type="button"
          onClick={showModal}
        >
          {displayLabel}
        </button>
        <CancelAccountModal isOpen={modal} onClose={hideModal} />
      </div>
    );
  }
}

CancelAccountButton.propTypes = {
  className: string,
  label: string,
};

export default CancelAccountButton;
