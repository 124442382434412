import React from 'react';
import snakeCase from 'lodash/snakeCase';
import { Fields } from 'redux-form';

import { t } from 'shared/utils';
import { PasswordField as PasswordFieldComponent } from 'components/Form';

import passwordFieldChecker from './checker';

type PasswordFieldProps = {
  name: string;
  placeholder?: string;
};

const PasswordField = ({ name, placeholder = '', ...props }: PasswordFieldProps) => (
  <Fields
    {...props}
    name={name}
    checker={passwordFieldChecker}
    component={PasswordFieldComponent}
    names={['password', 'passwordConfirm']}
    placeholder={
      placeholder ? `${placeholder} *` : `${t(`registration.form.${snakeCase(name)}`)} *`
    }
    type="password"
  />
);

export default PasswordField;
