import React from 'react';

import WarningIcon from 'images/icon-exclamation-warning.svg';
import { t } from 'shared/utils';
import AccountInfoPublicArea from 'components/AccountInfoPublicArea/AccountInfoPublicArea';

import styles from './RegistrationsDeletionTokenInvalid.module.css';

const RegistrationsDeletionTokenInvalid = () => (
  <AccountInfoPublicArea
    headingText={t('customer_deletion.token_invalid.heading')}
    icon={WarningIcon}
    infoText={[t('customer_deletion.token_invalid.text')]}
    iconClassName={styles.icon}
  />
);

export default RegistrationsDeletionTokenInvalid;
