import React, { memo, SVGProps } from 'react';

const UserProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 .5h40v40H0z" />
      <path
        d="M20 3.833C10.8 3.833 3.333 11.3 3.333 20.5c0 9.2 7.467 16.667 16.667 16.667 9.2 0 16.667-7.467 16.667-16.667C36.667 11.3 29.2 3.833 20 3.833zm0 5c2.767 0 5 2.234 5 5 0 2.767-2.233 5-5 5s-5-2.233-5-5c0-2.766 2.233-5 5-5zM20 32.5a12 12 0 01-10-5.367C10.05 23.817 16.667 22 20 22c3.317 0 9.95 1.817 10 5.133A12 12 0 0120 32.5z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(UserProfile);
