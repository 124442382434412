import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SubmissionError } from 'redux-form';

import { updateClient } from 'actions/clients';
import {
  changeGrecaptchaResponse as changeGrecaptchaResponseAction,
  resetGrecaptcha as resetGrecaptchaAction,
} from 'actions/grecaptcha';
import { showNotification } from 'actions/notification';
import { sendByEmail } from 'api/me/orderConfirmations/sendByEmail';
import { CANCEL_MODE } from 'constants/common/crud';
import { ACTION_EMAIL_ERROR, ACTION_ORDER_CONFIRMATION_SEND } from 'constants/piwik';
import validationNotification from 'notifications/validation';
import { orderConfirmationSelector } from 'reducers/form';
import { companySelector } from 'reducers/form';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import getGrecaptchaKey from 'shared/utils/grecaptcha';
import {
  mapServerErrorsToFieldErrors,
  mapServerErrorsToNotificationError,
} from 'shared/utils/server-validation';
import { RootState } from 'store';
import { Client } from 'types/entities/Client';
import BaseEmailEditor from 'features/emailEditor/EmailEditor';

import { trackEvent } from './utils';

type EmailEditorProps = {
  onClose: () => void;
  client: Client;
  onSave: any;
  readonly: boolean;
  crudMode: string;
};

const EmailEditor = ({ onClose, client, onSave, readonly, crudMode }: EmailEditorProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const salutationContent = useSelector(
    (state: RootState) => state.defaultValues.values.emailOrderConfirmationSalutationContent
  );
  const salutationHonorific = useSelector(
    (state: RootState) => state.defaultValues.values.emailOrderConfirmationSalutation
  );
  const personalNotes = useSelector(
    (state: RootState) => state.defaultValues.values.emailOrderConfirmationSignature
  );
  const street = useSelector((state: RootState) => companySelector(state, 'street'));
  const city = useSelector((state: RootState) => companySelector(state, 'city'));
  const email = useSelector((state: RootState) => companySelector(state, 'email'));
  const companyName = useSelector((state: RootState) => companySelector(state, 'name'));
  const phone = useSelector((state: RootState) => companySelector(state, 'phoneNumber'));
  const zipcode = useSelector((state: RootState) => companySelector(state, 'zipcode'));
  const orderConfirmationDate = useSelector((state: RootState) =>
    orderConfirmationSelector(state, 'orderConfirmationDate')
  );
  const firstName = useSelector((state: RootState) => state.profile.credentials.firstName);
  const lastName = useSelector((state: RootState) => state.profile.credentials.lastName);
  const orderConfirmationId = useSelector((state: RootState) => state.orderConfirmation.details.id);
  const isCancelMode = crudMode === CANCEL_MODE;
  const title = t(
    `modals.order_confirmation.send_email.${isCancelMode ? 'cancel_title' : 'title'}`
  );
  let content: string;
  let subject: string;

  if (crudMode === CANCEL_MODE) {
    subject = `${companyName}: Ihre Auftragsbestätigung vom ${orderConfirmationDate}`;
    content = `${
      salutationHonorific ? salutationHonorific : ''
    } \n\nMit dieser E-Mail senden wir Ihnen die Stornierung der Auftragsbestätigung zu (siehe Anhang).\n\n${
      personalNotes ? personalNotes : ''
    } \n\n${firstName} ${lastName} \n${street} \n${zipcode} ${city} \n\n${phone} \n${email}\n\n\n`;
  } else {
    subject = `Auftragsbestätigung von ${companyName}`;
    content = `${salutationHonorific ? salutationHonorific : ''} \n\n${
      salutationContent ? salutationContent : ''
    } \n\n${
      personalNotes ? personalNotes : ''
    } \n\n${firstName} ${lastName} \n${street} \n${zipcode} ${city} \n\n${phone} \n${email}\n\n\n`;
  }

  const grecaptchaResponse = useSelector((state: RootState) => state.grecaptcha.grecaptchaResponse);
  const [isRecaptchaVisible, setIsRecaptchaVisible] = useState(false);
  const recaptchaSitekey = getGrecaptchaKey() || '';

  const reloadRecaptcha = () => setIsRecaptchaVisible(true);

  const changeGrecaptchaResponse = useCallback(
    (response: string) => dispatch(changeGrecaptchaResponseAction(response)),
    [dispatch]
  );

  const handleSendMail = useCallback(
    async (data) => {
      if (!readonly || isCancelMode) {
        const response = await onSave();

        if (!response.success) {
          onClose();
          return;
        }
      }

      try {
        await sendByEmail(orderConfirmationId, data, grecaptchaResponse);
      } catch ({ response }) {
        if (
          (response as any).status === 422 &&
          (response as any).data.errors[0].detail === 'Das Captcha wurde nicht korrekt ausgefüllt'
        ) {
          dispatch(resetGrecaptchaAction());
          reloadRecaptcha();
          return;
        }

        const notification = validationNotification(
          mapServerErrorsToNotificationError((response as any).data.errors)
        );
        dispatch(showNotification(notification));
        trackEvent(ACTION_EMAIL_ERROR);
        throw new SubmissionError(mapServerErrorsToFieldErrors((response as any).data.errors));
      }

      dispatch(
        showNotification({
          duration: 3000,
          title: t(
            `notifications.order_confirmations.send_success.${
              isCancelMode ? 'cancel_title' : 'title'
            }`
          ),
          variant: 'success',
        })
      );

      if (client && data.updateClientsEmail) {
        const { id: clientId, ...clientData } = client;

        dispatch(
          updateClient(clientId, { ...clientData, email: data.email }, { redirectAfter: false })
        );
      }

      trackEvent(ACTION_ORDER_CONFIRMATION_SEND);
      onClose();
      history.push(paths.orderConfirmation);
    },
    [dispatch, onClose, onSave, readonly, client, orderConfirmationId, history, isCancelMode]
  );

  useEffect(() => {
    return () => {
      dispatch(resetGrecaptchaAction());
    };
  }, []);

  return (
    <BaseEmailEditor
      onClose={onClose}
      initialValues={{
        email: client?.email,
        content: content?.replace(/<[^>]*>?/gm, ''),
        subject: subject?.replace(/<[^>]*>?/gm, ''),
        sendCarbonCopy: false,
        updateClientsEmail: false,
      }}
      sendMail={handleSendMail}
      title={title}
      client={client}
      recaptchaOptions={{
        isRecaptchaVisible,
        reloadRecaptcha,
        recaptchaSitekey,
        changeGrecaptchaResponse,
      }}
    />
  );
};

export default EmailEditor;
