import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { debounce, flow, get, noop } from 'lodash';
import moment from 'moment';
import { bool, func, shape, string } from 'prop-types';
import { isDirty, reduxForm } from 'redux-form';

import { fetchAppUploads } from 'actions/app-uploads';
import {
  exitEditInvoiceMode as exitEditInvoiceModeAction,
  exportData,
  fetchLatestCreds as fetchLatestCredsAction,
  removeMissingDatesError as removeMissingDatesErrorAction,
  setMissingDatesError as setMissingDatesErrorAction,
} from 'actions/datev';
import {
  clearFiltersToInitial,
  getIncomingInvoices,
  setPage,
  setQueryParam,
} from 'actions/incoming-invoices';
import { DATE_FORMAT_FULL_REVERSED } from 'constants/datetime';
import { CLEAR_EXPORT_STATUSES, EXPORT_FINISHED } from 'constants/datev';
import { MODAL_HEADER_VARIANT } from 'constants/modal';
import { Resources } from 'constants/resources';
import InvoiceEdit from 'containers/IncomingInvoices/IncomingInvoiceEdit';
import paths from 'routes/paths';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { objectHasValues, t, toMoment } from 'shared/utils';
import {
  getTaxConsultantEmail,
  getTaxConsultantPassword,
} from 'shared/utils/datev-tax-consultant-data';
import Card from 'components/Card';
import I18n from 'components/I18n';
import { ConfirmationModal, InfoModal } from 'components/Modal';

import DatevInformationDropdown from '../shared/DatevInformationDropdown';
import DatevPreview from '../shared/DatevPreview/DatevPreview';
import SettingsSection from '../shared/SettingsSection';

import styles from './Creator.module.css';

const getTableDateRange = (startDate, endDate) => ({
  start_date: moment(startDate, 'DD.MM.YYYY').format(DATE_FORMAT_FULL_REVERSED),
  end_date: moment(endDate, 'DD.MM.YYYY').format(DATE_FORMAT_FULL_REVERSED),
});

class DatevCreator extends Component {
  state = {
    isTableVisible: false,
    tableType: '',
    isSubmitting: false,
  };

  componentDidMount() {
    const {
      initialValues: { startDate, endDate },
      fetchLatestCreds,
      hasDateValues,
      datevTableDateRangeRaw,
      initialDatevTableDateRangeRaw,
      setQueryAction,
      isTaxConsultantDataFilled,
      setMissingDatesError,
      nextReportStartDate,
      lastReportEndDate,
      setPage,
    } = this.props;

    setQueryAction('draft', false);
    setQueryAction(
      'invoice_date_from',
      hasDateValues ? datevTableDateRangeRaw.start_date : initialDatevTableDateRangeRaw.start_date
    );
    setQueryAction(
      'invoice_date_to',
      hasDateValues ? datevTableDateRangeRaw.end_date : initialDatevTableDateRangeRaw.end_date
    );
    setQueryAction('files_present', false);
    setQueryAction('datev_exported', false);
    setQueryAction('status_not', 'cancelled');
    setPage(1);
    this.fetchInvoices({ page: 1 });

    fetchLatestCreds(startDate, endDate, { isFormReinitialized: !isTaxConsultantDataFilled });

    const isEmptyPeriodAfterLastEnd = moment(toMoment(startDate || nextReportStartDate)).isAfter(
      toMoment(lastReportEndDate).add(1, 'days')
    );

    if (isEmptyPeriodAfterLastEnd) {
      setMissingDatesError();
    }
  }

  componentWillUnmount() {
    this.props.clearFiltersToInitial();
    this.props.handleOnComponentUnmount();
  }

  _fetchInvoices = ({ page = 1 } = {}, sorting = this.props.sorting) => {
    const isIndexClassicView = objectHasValues(this.props.filters);

    this.props.fetchAppUploads();
    this.props.getIncomingInvoices(
      { page, pagination_resource: Resources.DATEV_EXPORTS },
      sorting,
      {
        isIndexClassicView,
      }
    );
  };

  fetchInvoices = debounce(this._fetchInvoices, 200);

  /**
   * When the button is clicked it should be disabled until we get
   * a response from a request. After it happens it should be possible to click this
   * button again.
   */
  handleExport = () => {
    this.setState({ isSubmitting: true });

    this.props
      .handleExport(this.props.formValues)
      .finally(() => this.setState({ isSubmitting: false }));
  };

  render() {
    const { isTableVisible, isSubmitting } = this.state;
    const {
      onSubmit,
      submitPush,
      handleSubmit,
      exportMissingDatesError,
      removeMissingDatesError,
      lastReportEndDate,
      isEditingInvoice,
      datevInvoiceId,
      exportStatus,
    } = this.props;

    const { isExporting, isExportSuccessful } = exportStatus;

    return (
      <Fragment>
        <ConfirmationModal
          dangerousAction
          isOpen={exportMissingDatesError}
          onClose={() => submitPush(paths.datev)}
          onConfirm={() => removeMissingDatesError()}
          closeLabel={t('datev.dynamic_date.info_messages.missing_dates.cancel')}
          confirmLabel={t('datev.dynamic_date.info_messages.missing_dates.continue')}
          header={t('datev.dynamic_date.info_messages.missing_dates.header')}
          headerVariant={MODAL_HEADER_VARIANT.SMALL}
          hasWarningIcon
        >
          <I18n
            t="datev.dynamic_date.info_messages.missing_dates.content"
            lastExportDate={lastReportEndDate}
          />
        </ConfirmationModal>

        {isEditingInvoice && <InvoiceEdit customInvoiceId={datevInvoiceId} />}
        {!isTableVisible && !isEditingInvoice && (
          <div>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.section}>
              <Card className={styles.main}>
                <Card.Header>
                  <I18n t="datev.creator.header" className={styles.headerLeft} />
                </Card.Header>
                <Card.Body>
                  <div className={styles.body}>
                    <div className={styles.description}>
                      <I18n t="datev.creator.description" />
                    </div>
                    <SettingsSection
                      handleExport={this.handleExport}
                      disabled={isSubmitting}
                      label={t('datev.creator.labels.export')}
                    />
                  </div>
                  <DatevInformationDropdown />
                </Card.Body>
              </Card>
              <Card className={styles.main}>
                <Card.Header>
                  <I18n t="datev.creator.headings.invoices" className={styles.headerLeft} />
                </Card.Header>
                <Card.Body className={styles.body}>
                  <DatevPreview />
                </Card.Body>
              </Card>
              <InfoModal
                isOpen={isExporting && isExportSuccessful}
                onClose={() => submitPush(paths.datev)}
                dataIds={{
                  acceptButton: 'confirmation',
                }}
              >
                {t('datev.creator.messages.export_success')}
              </InfoModal>
            </form>
          </div>
        )}
      </Fragment>
    );
  }
}

DatevCreator.propTypes = {
  initialValues: shape({}),
  formValues: shape({}),
  onSubmit: func,
  submitPush: func,
  handleSubmit: func,
  fetchLatestCreds: func,
  handleExport: func.isRequired,
  exportMissingDatesError: bool.isRequired,
  removeMissingDatesError: func,
  lastReportEndDate: string,
  nextReportStartDate: string,
  isEditingInvoice: bool,
  hasDateValues: bool,
  initialDatevTableDateRange: string,
  datevInvoiceId: string,
  setQueryAction: func.isRequired,
  fetchAppUploads: func.isRequired,
  setPage: func.isRequired,
  sorting: shape({}).isRequired,
  getIncomingInvoices: func.isRequired,
  filters: shape({
    status: shape({}),
    invoice_date: shape({}),
    draft: bool,
    files_present: bool,
  }),
  clearFiltersToInitial: func.isRequired,
  isTaxConsultantDataFilled: bool.isRequired,
  exportStatus: shape({
    isExporting: bool.isRequired,
    isExportSuccessful: bool.isRequired,
  }).isRequired,
  areTransferLabelsEnabled: bool,
  setMissingDatesError: func.isRequired,
  handleOnComponentUnmount: func.isRequired,
  datevTableDateRangeRaw: shape({
    start_date: string,
    end_date: string,
  }),
  initialDatevTableDateRangeRaw: shape({
    start_date: string,
    end_date: string,
  }),
};

export default flow(
  withTransitionPrevent(),
  reduxForm({
    form: 'DatevCreator',
    persistentSubmitErrors: true,
    enableReinitialize: true,
  }),
  connect(
    (state) => {
      const { startDate, endDate } = get(state, 'form.DatevCreator.values', {});
      const { startDate: initialStartDate, endDate: initialEndDate } = get(
        state,
        'form.DatevCreator.initial',
        {}
      );
      const isDateRangeInputDirty = get(state, 'form.DatevCreator.isDateRangeInputDirty', {});

      return {
        isDirty: isDirty('DatevCreator')(state) || isDateRangeInputDirty,
        isSubmitSucceeded: state.datev.exportStatus.isExportFinished,
        initialValues: {
          ...get(state, 'form.DatevCreator.initial'),
          ...get(state, 'form.DatevCreator.values'),
          email: getTaxConsultantEmail(state),
          password: getTaxConsultantPassword(state),
        },
        isTaxConsultantDataFilled:
          state.company.details.taxConsultantEmail || state.company.details.exportPassword,
        formValues: get(state, 'form.DatevCreator.values'),
        startDate,
        endDate,
        hasDateValues: startDate && endDate,
        datevTableDateRange: JSON.stringify(getTableDateRange(startDate, endDate)),
        initialDatevTableDateRange: JSON.stringify(
          getTableDateRange(initialStartDate, initialEndDate)
        ),
        datevTableDateRangeRaw: getTableDateRange(startDate, endDate),
        initialDatevTableDateRangeRaw: getTableDateRange(initialStartDate, initialEndDate),
        exportMissingDatesError: state.datev.dates.exportMissingDatesError,
        lastReportEndDate: state.datev.dates.lastReportEndDate,
        nextReportStartDate: state.datev.dates.nextReportStartDate,
        invoices: state.incomingInvoices.data,
        filters: state.incomingInvoices.filters,
        sorting: state.incomingInvoices.sorting,
        pagination: state.incomingInvoices.pagination,
        isEditingInvoice: state.datev.invoiceActions.isEditingInvoice,
        datevInvoiceId: state.datev.invoiceActions.invoiceId,
        exportStatus: state.datev.exportStatus,
        areTransferLabelsEnabled: state.profile.applicationFeatures.bankTransferLabels,
      };
    },
    (dispatch) => ({
      onSubmit: () => noop,
      handleExport: (...args) => dispatch(exportData(...args)),
      submitPush: (...args) => {
        dispatch(push(...args));
        dispatch({ type: EXPORT_FINISHED });
      },
      handleOnComponentUnmount: () => dispatch({ type: CLEAR_EXPORT_STATUSES }),
      fetchLatestCreds: (...args) => dispatch(fetchLatestCredsAction(...args)),
      removeMissingDatesError: (...args) => dispatch(removeMissingDatesErrorAction(...args)),
      setQueryAction: (...args) => dispatch(setQueryParam(...args)),
      fetchAppUploads: (...args) => dispatch(fetchAppUploads(...args)),
      getIncomingInvoices: (...args) => dispatch(getIncomingInvoices(...args)),
      setPage: (...args) => dispatch(setPage(...args)),
      clearFiltersToInitial: (...args) => dispatch(clearFiltersToInitial(...args)),
      exitEditInvoiceMode: (...args) => dispatch(exitEditInvoiceModeAction(...args)),
      setMissingDatesError: (...args) => dispatch(setMissingDatesErrorAction(...args)),
    })
  )
)(DatevCreator);
