import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import { fetchCompany } from 'actions/company';
import {
  downloadOutgoingInvoice,
  downloadOutgoingInvoicePreview,
  sendOutgoingInvoice,
  transformOutgoingInvoiceIntoDeliveryNote,
} from 'actions/outgoing-invoice';
import { SHOW_MODE } from 'constants/common/crud';
import { MAX_REMINDERS_COUNT } from 'constants/incoming-invoice';
import { FROM_OUTGOING_INVOICE_QUERY_PARAM } from 'constants/outgoing-invoice';
import { FORM_NAME } from 'constants/outgoing-invoice';
import { InvoiceStatuses } from 'constants/statuses';
import BankTransferAssigment from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/BankTransferAssigment/bankTransferAssigment';
import { outgoingInvoiceSelector } from 'reducers/form';
import { eInvoicesEnabledHelper, paymentRemindersEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { hasOutgoingInvoiceAnyUploadedFiles } from 'selectors/outgoingInvoice';
import { withReminderTabs, withWarningAlert } from 'shared/hoc';
import { t } from 'shared/utils';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import ActionDropdown from 'components/ActionPanel/ActionDropdown';
import { ButtonAppearances } from 'components/Button';
import If from 'components/Conditions/If';
import { LineItemsAutoSaveProvider } from 'components/LineItems';
import useLineItemsControl, { SectionType } from 'components/LineItems/useLineItemControl';

import CreateInvoiceSection from '../OutgoingInvoiceCreator/CreateInvoiceSection/CreateInvoiceSection';
import ElectronicInvoiceInfo from '../OutgoingInvoiceCreator/CreateInvoiceSection/ElectronicInvoiceInfo/ElectronicInvoiceInfo';
import Notes from '../OutgoingInvoiceCreator/CreateInvoiceSection/Notes/Notes';
import Remark from '../OutgoingInvoiceCreator/CreateInvoiceSection/Remark';
import IndividualContactSection from '../OutgoingInvoiceCreator/IndividualContactSection';
import InvoiceDetailsSection from '../OutgoingInvoiceCreator/InvoiceDetailsSection';
import InvoiceSumSection from '../OutgoingInvoiceCreator/InvoiceSumSection';
import UploadInvoiceSection from '../OutgoingInvoiceImport/UploadInvoiceSection/UploadInvoiceSection';
import EmailEditor from './EmailEditor/EmailEditor';

import styles from './OutgoingInvoiceShow.module.css';

const isReadonly = () => true;

const OutgoingInvoiceShow = ({
  fetchCompany,
  currentClient = {},
  individualContact,
  status,
  paymentReminders = [],
  paymentRemindersEnabled,
  invoiceDetails,
  paymentPlan,
  eInvoicesEnabled,
  electronicInvoiceType,
  hasUploadedFiles,
  downloadInvoice,
  crudMode,
  sendingDocumentsByEmailEnabled,
  push,
  transformInvoice,
  invoiceId,
  downloadPreview,
}) => {
  const {
    manualHandleSelectCategory,
    setShowCategoryChangeConfirmation,
    showCategoryChangeConfirmation,
    defaultCategory,
    isDeprecatedCategory,
    category,
    taxRates,
    taxRateId,
    defaultVat,
    hasSavedLineItems,
    selectedCategory,
    lineCategories,
    isInitial,
  } = useLineItemsControl({ sectionType: SectionType.outgoingInvoice, formName: FORM_NAME });

  const [isEmailModalVisible, setIsMailModalVisibile] = useState(false);
  const permittedActions = get(invoiceDetails, 'actions', {});
  const isDraft = status === InvoiceStatuses.DRAFT;
  const showSalutation = !(isReadonly('salutation') && !individualContact);
  const canCreatePaymentReminder =
    (status === InvoiceStatuses.OVERDUE || status === InvoiceStatuses.PAYMENT_REMINDED) &&
    paymentReminders.length < MAX_REMINDERS_COUNT;
  const { imported } = invoiceDetails;
  const isImportedAndHasFiles = imported && hasUploadedFiles;
  const isTransformableIntoDeliveryNote = permittedActions['transform-into-delivery-note'];
  const safeInvoiceDetails = { ...invoiceDetails };

  const sendActions = () => {
    const downloadActionPDF = {
      name: t('revenue.form.download'),
      onClick: () => downloadInvoice({ details: { ...invoiceDetails, type: 'pdf' } }),
      tooltip: t('revenue.form.download_tooltip'),
      dataId: 'pdf-download',
    };

    const downloadActionXML = {
      name: t('revenue.form.download_xml'),
      onClick: () => downloadInvoice({ details: { ...invoiceDetails, type: 'xml' } }),
      tooltip: t('revenue.form.download_xml_tooltip'),
      dataId: 'xml-download',
      isEnabled: !!currentClient?.einvoiceRecipientReference && !!electronicInvoiceType,
    };

    const sendByEmailAction = {
      name: t('revenue.form.send_email'),
      onClick: () => setIsMailModalVisibile(true),
      tooltip: t('revenue.form.send_email_tooltip'),
      dataId: 'resend-email',
    };

    return sendingDocumentsByEmailEnabled
      ? [downloadActionPDF, downloadActionXML, sendByEmailAction]
      : [downloadActionPDF, downloadActionXML];
  };

  const transformIntoDeliveryNote = () => {
    transformInvoice(invoiceId).then(({ data: { id } }) => {
      push({
        pathname: paths.editDeliveryNote(id),
        search: `?${FROM_OUTGOING_INVOICE_QUERY_PARAM}`,
      });
    });
  };

  const createReminder = () => push(paths.createPaymentReminder(invoiceId));

  useEffect(() => {
    fetchCompany();
  }, []);

  return (
    <form className={cx({ [styles.tabsSeparator]: isImportedAndHasFiles })}>
      <LineItemsAutoSaveProvider>
        <div className={styles.main}>
          <div className={cx(styles.section, styles.creator)}>
            <div className={styles.createInvoiceSectionWrapper}>
              <If ok={isImportedAndHasFiles}>
                <div className={cx(styles.subsection, styles.uploader)}>
                  <UploadInvoiceSection readonly={isReadonly} />
                </div>
              </If>
              <div className={styles.subsection}>
                <CreateInvoiceSection
                  hasUploadSection={isImportedAndHasFiles}
                  isReadonly={isReadonly}
                  lineCategories={lineCategories}
                  crudMode={crudMode}
                  defaultCategory={defaultCategory}
                  hasSavedLineItems={hasSavedLineItems}
                  handleSelectCategory={manualHandleSelectCategory}
                  category={category}
                  isDeprecatedCategory={isDeprecatedCategory}
                  selectedCategory={selectedCategory}
                  showCategoryChangeConfirmation={showCategoryChangeConfirmation}
                  setShowCategoryChangeConfirmation={setShowCategoryChangeConfirmation}
                />
              </div>
            </div>
            <If ok={showSalutation}>
              <IndividualContactSection
                dataIds={{
                  salutation: 'OutgoingInvoicePage:input-salutation',
                  salutationContent: 'OutgoingInvoicePage:input-salutation-content',
                }}
                client={currentClient}
                isReadonly={isReadonly}
              />
            </If>
            <InvoiceDetailsSection
              crudMode={SHOW_MODE}
              readonly={isReadonly('lineItems')}
              defaultCategory={defaultCategory}
              selectedCategory={selectedCategory}
              lineCategories={lineCategories}
              taxRates={taxRates}
              taxRateId={taxRateId}
              defaultVat={defaultVat}
              isInitial={isInitial}
            />
            <div className={styles.bottomSection}>
              <Notes disabled />
              <InvoiceSumSection insertedAsGross={invoiceDetails.insertedAsGross} />
            </div>
            <div className={styles.largeSection}>
              <BankTransferAssigment invoiceDetails={invoiceDetails} isReadonly={isReadonly} />
              <div className={styles.column}>
                <Remark dataId="OutgoingInvoicePage:notes" readonly={isReadonly('remark')} />
                <ElectronicInvoiceInfo
                  paymentPlan={paymentPlan}
                  eInvoicesEnabled={eInvoicesEnabled}
                  electronicInvoiceType={electronicInvoiceType}
                />
              </div>
            </div>
          </div>
        </div>
        <ActionPanel className={styles.creatorActionsSection} wrapped>
          <ActionDropdown
            upwards
            visible={!isDraft}
            actions={sendActions()}
            title={t('revenue.form.send_again')}
            dataId="download-again"
          />
          <ActionButton
            appearance={ButtonAppearances.outlined}
            visible={isDraft}
            onClick={() => downloadPreview(invoiceId, safeInvoiceDetails)}
            label={t('revenue.form.download')}
          />
          <ActionButton
            appearance={ButtonAppearances.primary}
            visible={paymentRemindersEnabled && canCreatePaymentReminder}
            onClick={createReminder}
            label={t('revenue.form.create_reminder')}
          />
          <ActionButton
            dataId="OutgoingInvoicePage:button-save"
            appearance={ButtonAppearances.primary}
            visible={isTransformableIntoDeliveryNote}
            onClick={transformIntoDeliveryNote}
            label={'In Lieferschein überführen'}
          />
          {isEmailModalVisible && (
            <EmailEditor
              onClose={() => setIsMailModalVisibile(false)}
              client={currentClient}
              title={'Rechnung per E-Mail versenden'}
              status={invoiceDetails}
            />
          )}

          <ActionButton
            appearance={ButtonAppearances.outlined}
            onClick={() => push(paths.outgoingInvoices)}
            label={t('forms.actions.back_to_index')}
          />
        </ActionPanel>
      </LineItemsAutoSaveProvider>
    </form>
  );
};

const formEnhancer = reduxForm({
  form: 'outgoingInvoiceCreator',
  enableReinitialize: true,
});

const mapStateToProps = (state) => {
  const client = outgoingInvoiceSelector(state, 'client') || get(state, 'outgoingInvoice.client');

  return {
    initialValues: {
      ...state.outgoingInvoice.details,
      client,
    },
    invoiceDetails: state.outgoingInvoice.details,
    paymentReminders: state.outgoingInvoice.paymentReminders,
    status: state.outgoingInvoice.details.status,
    isShowing: true,
    currentClient: outgoingInvoiceSelector(state, 'client'),
    individualContact: outgoingInvoiceSelector(state, 'salutationContent'),
    sendingDocumentsByEmailEnabled: state.profile.applicationFeatures.sendingDocumentsByEmail,
    paymentRemindersEnabled: paymentRemindersEnabledHelper(state),
    eInvoicesEnabled: eInvoicesEnabledHelper(state),
    electronicInvoiceType: outgoingInvoiceSelector(state, 'electronicInvoiceType'),
    paymentPlan: state.paymentPlan.details.name,
    hasUploadedFiles: hasOutgoingInvoiceAnyUploadedFiles(state),
    buttonLabel: t('empty_entry_pages.outgoing_invoice.invoice_warning_box.button_label'),
    content: t('empty_entry_pages.outgoing_invoice.invoice_warning_box.content'),
    dataId: 'OutgoingInvoices:orange-warning-container',
  };
};

const mapDispatchToProps = {
  downloadInvoice: downloadOutgoingInvoice,
  downloadPreview: downloadOutgoingInvoicePreview,
  transformInvoice: transformOutgoingInvoiceIntoDeliveryNote,
  sendInvoice: sendOutgoingInvoice,
  fetchCompany,
  push,
};

const reduxEnhancer = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(reduxEnhancer, withWarningAlert, withReminderTabs, formEnhancer);

export default enhance(OutgoingInvoiceShow);
