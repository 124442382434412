import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { useDebounce } from 'react-use';
import type { AxiosPromise } from 'axios';

import { useIsXLgUp } from 'shared/hooks/useMedia';
import { t } from 'shared/utils';
import Loading from 'components/Loading';
import PreviewModal from 'components/PreviewModal';

import { Fields, Preview, PreviewButton, Wrapper } from './DefaultValuesFormWithPreview.styled';
import ResetDefaultsButton from './ResetDefaultsButton';
import { Button, Buttons, Form } from './shared.styled';

const PREVIEW_WIDTH = 320;
const DEBOUNCE_MS = 1000;

type DefaultValuesFormWithPreviewProps<Values> = {
  children: ReactNode;
  onSubmit: any;
  onReset: () => void;
  sectionName: string;
  sectionLabel: string;
  dataIdPrefix?: string;
  invalid?: boolean;
  topFields?: ReactNode;
  values: Partial<Values>;
  fetchPreview: (params: Values) => AxiosPromise<ArrayBuffer>;
};

const DefaultValuesFormWithPreview = <Values,>({
  children,
  onSubmit,
  onReset,
  sectionName,
  sectionLabel,
  dataIdPrefix = 'Defaults:',
  invalid,
  topFields,
  values,
  fetchPreview,
}: DefaultValuesFormWithPreviewProps<Values>) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const isXLgUp = useIsXLgUp();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleGetPreview = useCallback(async () => {
    const { data } = await fetchPreview(values as Values);

    return data;
  }, [fetchPreview, values]);

  const handleOpenPreview = useCallback(() => {
    setIsPreviewOpen(true);
  }, []);

  const handleClosePreview = useCallback(() => {
    setIsPreviewOpen(false);
  }, []);

  useDebounce(
    async () => {
      if (!isXLgUp) return;

      for (let value of Object.values(values)) {
        if (value === undefined) return;
      }

      setIsLoading(true);
      const ctx = canvasRef.current?.getContext('2d');

      if (!ctx) return;

      const preview = await handleGetPreview();
      const pdf = await pdfjs.getDocument(new Uint8Array(preview)).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });
      const newScale = PREVIEW_WIDTH / viewport.width;
      const newViewport = page.getViewport({ scale: newScale });

      ctx.canvas.width = newViewport.width;
      ctx.canvas.height = newViewport.height;
      ctx.clearRect(0, 0, newViewport.width, newViewport.height);

      await page.render({
        canvasContext: ctx,
        viewport: newViewport,
      }).promise;
      setIsLoading(false);
    },
    DEBOUNCE_MS,
    [isXLgUp, values]
  );

  return (
    <>
      <Form onSubmit={onSubmit}>
        {topFields && <Fields>{topFields}</Fields>}
        <Wrapper>
          <Fields>{children}</Fields>
          {isXLgUp && (
            <Preview>
              <canvas ref={canvasRef} onClick={handleOpenPreview} />
              {isLoading && <Loading />}
            </Preview>
          )}
        </Wrapper>
        <Buttons>
          <PreviewButton
            data-id={`${dataIdPrefix}preview-button_${sectionName}`}
            $appearance="outline"
            onClick={handleOpenPreview}
            type="button"
          >
            {t('invoice_templates.documents_defaults.preview_button')}
          </PreviewButton>
          <Button
            as={ResetDefaultsButton}
            sectionName={sectionName}
            sectionLabel={sectionLabel}
            onReset={onReset}
            dataIdPrefix={dataIdPrefix}
            $appearance="outline"
          />
          <Button
            tabIndex={0}
            data-id={`${dataIdPrefix}button-save-template_${sectionName}`}
            type="submit"
            disabled={invalid}
          >
            {t('invoice_templates.documents_defaults.save_button')}
          </Button>
        </Buttons>
      </Form>
      <PreviewModal
        title={t('modals.preview_outgoing_invoice.title')}
        getPreview={handleGetPreview}
        isOpen={isPreviewOpen}
        onClose={handleClosePreview}
      />
    </>
  );
};

export default DefaultValuesFormWithPreview;
