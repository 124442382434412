import React, { useCallback, useState } from 'react';

import type { ExpenseContract, RevenueContract } from 'types/entities/Contract';
import type { ExpenseInstallment, RevenueInstallment } from 'types/entities/Installment';

import ChooseInstallment from './steps/ChooseInstallment/ChooseInstallment';
import NewContract from './steps/NewContract/NewContract';
import SelectContractType from './steps/SelectContractType/SelectContractType';

type AddExpenseContractProps = {
  onSuccess: (installment: ExpenseInstallment | RevenueInstallment) => void;
  onCancel: () => void;
};

const AddExpenseContract = ({ onCancel, onSuccess }: AddExpenseContractProps) => {
  const [contractType, setContractType] = useState<'basic' | 'loan' | null>(null);
  const [contract, setContract] = useState<ExpenseContract | RevenueContract | null>(null);

  const handleSetContractType = useCallback((values: { contractType: 'basic' | 'loan' }) => {
    setContractType(values.contractType);
  }, []);

  if (contractType === null) {
    return <SelectContractType onCancel={onCancel} onSuccess={handleSetContractType} />;
  } else if (contractType && contract === null) {
    return <NewContract contractType={contractType} onCancel={onCancel} onSuccess={setContract} />;
  } else if (contract) {
    return <ChooseInstallment contract={contract} onCancel={onCancel} onSuccess={onSuccess} />;
  }

  return null;
};

export default AddExpenseContract;
