import { FETCH_SUCCESS, FETCH_SUMS_SUCCESS } from 'constants/outgoing-invoice';

const defaultState = {
  totalNetAmount: 0,
  totalVatAmount: 0,
  totalGrossAmount: 0,
};

export default (state = defaultState, action) => {
  if (action.type !== FETCH_SUCCESS && action.type !== FETCH_SUMS_SUCCESS) return state;
  const {
    response: { data },
  } = action;

  return {
    currency: data.attributes.currency,
    totalNetAmount: data.attributes.totalNetAmount,
    totalVatAmount: data.attributes.totalVatAmount,
    totalGrossAmount: data.attributes.totalGrossAmount,
  };
};
