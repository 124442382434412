import { t } from 'shared/utils';

import { Item } from './types';

const revenueItems: Item[] = [
  {
    header: t('datev.validation.information_section.revenues.header'),
    title: t('datev.validation.information_section.revenues.item1_title'),
  },
  {
    title: t('datev.validation.information_section.revenues.item2_title'),
  },
  {
    title: t('datev.validation.information_section.revenues.item3_title'),
  },
  {
    title: t('datev.validation.information_section.revenues.item4_title'),
  },
];

const appointmentItems: Item[] = [
  {
    header: t('datev.validation.information_section.appointments.header'),
    title: t('datev.validation.information_section.appointments.item1_title'),
  },
  {
    title: t('datev.validation.information_section.appointments.item2_title'),
  },
  {
    title: t('datev.validation.information_section.appointments.item3_title'),
  },
];

const insuranceItems: Item[] = [
  {
    header: t('datev.validation.information_section.insurance.header'),
    title: t('datev.validation.information_section.insurance.item1_title'),
  },
  {
    title: t('datev.validation.information_section.insurance.item2_title'),
  },
];

const rentItems: Item[] = [
  {
    withTopBorder: true,
    header: t('datev.validation.information_section.rent.header'),
    title: t('datev.validation.information_section.rent.item1_title'),
  },
];

const employeeItems: Item[] = [
  {
    withTopBorder: true,
    header: t('datev.validation.information_section.employee.header'),
    title: t('datev.validation.information_section.employee.item1_title'),
  },
];

const goodItems: Item[] = [
  {
    withTopBorder: true,
    header: t('datev.validation.information_section.goods.header'),
    title: t('datev.validation.information_section.goods.item1_title'),
  },
];

export const allItems: Item[][] = [
  revenueItems,
  appointmentItems,
  insuranceItems,
  rentItems,
  employeeItems,
  goodItems,
];
