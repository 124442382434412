import { CALL_API } from 'constants/api';
import { QUARTERLY_SUMMARY, UPDATE_THRESHOLD } from 'constants/quarterly-summary';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

import * as actions from './index';

export const index =
  ({ name, endpoint } = {}) =>
  () =>
  (dispatch) =>
    dispatch(
      actions.apiIndex({
        name,
        endpoint,
      })
    ).catch(apiErrorHandler);

export const apiUpdateThreshold = (value) => ({
  [CALL_API]: {
    data: { liquidityDangerThreshold: value },
    method: 'PUT',
    endpoint: '/me/settings/account_setting',
    types: UPDATE_THRESHOLD,
  },
});

export const updateThreshold = (value) => (dispatch) =>
  bindServerValidation(apiUpdateThreshold(value), dispatch, { isReduxForm: false });

const endpoint = '/me/liquidity/quarterly_summary?refresh=true';
const name = QUARTERLY_SUMMARY;
const config = { endpoint, name };

export const fetchQuarterlySummary = index(config);
