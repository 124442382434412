import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import flow from 'lodash/fp/flow';
import mapKeys from 'lodash/fp/mapKeys';
import mapValues from 'lodash/fp/mapValues';
import reduce from 'lodash/fp/reduce';

import {
  ADD_CUSTOMER_TASK,
  DELETE_CUSTOMER_TASK,
  FETCH_SUCCESS,
  UPDATE_CUSTOMER_TASK,
} from 'constants/customer-notifications';

import { Status } from '../types/entities/Task';

const camelizeKeys = (object) => flow(mapKeys(camelCase))(object);

const parseFeature = ({ attributes, id, meta, ...feature }) => ({
  ...feature,
});

const parseFeatures = (data = []) =>
  data.reduce((prev, curr) => ({ ...prev, ...parseFeature(curr) }), {});

const normalizeFeature = (feature = {}) =>
  flow(camelizeKeys, ({ tasks = [], ...rest }) => ({ tasks: tasks.map(camelizeKeys), ...rest }))(
    feature
  );

const normalizeFeatures = (features = {}) =>
  flow(camelizeKeys, mapValues(normalizeFeature))(features);

const getFeatureByTaskStatus = (feature = {}, statusPredicate) =>
  flow(({ tasks = [], ...rest }) => {
    const filteredTasks = tasks.filter(({ status }) => statusPredicate(status));
    return {
      tasks: filteredTasks,
      ...rest,
      tasksCount: filteredTasks.length,
    };
  })(feature);

const getFeaturesByTaskStatus = (features = {}, statusPredicate) =>
  flow(mapValues((feature) => getFeatureByTaskStatus(feature, statusPredicate)))(features);

const countNotifications = (normalizedFeatures = {}) =>
  flow(
    mapValues(({ tasksCount }) => Number(tasksCount > 0)),
    reduce((sum, tasksCount) => sum + tasksCount, 0)
  )(normalizedFeatures);

const sortByDueDate = (a, b) => {
  if (a.dueDate && b.dueDate) return new Date(a.dueDate) - new Date(b.dueDate);
  if (a.dueDate && !b.dueDate) return -1;
  if (!a.dueDate && b.dueDate) return 1;
  return new Date(b.updatedAt) - new Date(a.updatedAt);
};

const getTaskCollection = (status) => (status === Status.Open ? 'openFeatures' : 'doneFeatures');

const defaultState = {
  features: {},
  openFeatures: {},
  doneFeatures: {},
  notificationsCount: 0,
  isFetched: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS: {
      const { response: { data } = {}, headers } = action;
      const parsedFeatures = parseFeatures(data);
      const normalizedFeatures = normalizeFeatures(parsedFeatures);
      normalizedFeatures.custom = normalizedFeatures.custom
        ? normalizedFeatures.custom
        : { tasks: [], tasksCount: 0 };
      normalizedFeatures.custom.tasks = normalizedFeatures.custom.tasks.sort(sortByDueDate);
      const openFeatures = getFeaturesByTaskStatus(
        normalizedFeatures,
        (status) => status === 'open'
      );
      const doneFeatures = getFeaturesByTaskStatus(
        normalizedFeatures,
        (status) => status !== 'open'
      );
      const notificationsCount = countNotifications(openFeatures);
      const { etag } = headers;
      return {
        openFeatures,
        doneFeatures,
        features: normalizedFeatures,
        notificationsCount,
        etag,
        isFetched: true,
      };
    }
    case ADD_CUSTOMER_TASK: {
      const stateCopy = cloneDeep(state);
      const taskCollection = getTaskCollection(action.payload.status);
      stateCopy[taskCollection].custom.tasks.push(action.payload);
      stateCopy[taskCollection].custom.tasks =
        stateCopy[taskCollection].custom.tasks.sort(sortByDueDate);
      stateCopy[taskCollection].custom.tasksCount = stateCopy[taskCollection].custom.tasks.length;

      return stateCopy;
    }
    case UPDATE_CUSTOMER_TASK: {
      const stateCopy = cloneDeep(state);
      const taskCollection = getTaskCollection(action.payload.status);
      const taskIdx = stateCopy[taskCollection].custom.tasks.findIndex(
        (task) => task.id === action.payload.id
      );
      stateCopy[taskCollection].custom.tasks[taskIdx] = action.payload;
      stateCopy[taskCollection].custom.tasks =
        stateCopy.openFeatures.custom.tasks.sort(sortByDueDate);

      return stateCopy;
    }
    case DELETE_CUSTOMER_TASK: {
      const stateCopy = cloneDeep(state);
      const taskCollection = getTaskCollection(action.payload.status);
      const taskIdx = stateCopy[taskCollection].custom.tasks.findIndex(
        (task) => task.id === action.payload.id
      );
      stateCopy[taskCollection].custom.tasks.splice(taskIdx, 1);
      stateCopy[taskCollection].custom.tasksCount = stateCopy[taskCollection].custom.tasks.length;

      return stateCopy;
    }
    default:
      return state;
  }
};
