import React from 'react';
import { useSelector } from 'react-redux';

import LegalsFooter from 'containers/LegalsFooter';
import logo from 'images/app-logo.svg';
import { t } from 'shared/utils/index';
import { RootState } from 'store';
import CardView, { HeadingSection } from 'components/CardView';
import IconNarrowCheck from 'components/Icons/IconNarrowCheck';

import { Card, Icon, Logo, Main } from './BankAccountConnected.styled';

import sharedStyles from '../../../pages/Shared.module.css';

const BankAccountConnected = () => {
  const isAuthenticated = useSelector<RootState>((state) => state.auth.isAuthenticated);
  const isAuthFetching = useSelector<RootState>((state) => state.auth.isFetching);
  // We have to show footer even if user is authenticated.
  const showFooter = isAuthenticated && !isAuthFetching;

  return (
    <>
      <section className={sharedStyles.wrapper}>
        <Card as={CardView}>
          <HeadingSection>
            <Logo alt="VR Smart Guide" src={logo} />
          </HeadingSection>
          <Icon as={IconNarrowCheck} />
          <Main>
            <h1>{t('features.landing_pages.bank_account_connected.title')}</h1>
            <p>{t('features.landing_pages.bank_account_connected.text_1')}</p>
            <p>
              <strong>{t('features.landing_pages.bank_account_connected.text_2')}</strong>
            </p>
          </Main>
        </Card>
      </section>
      {showFooter && <LegalsFooter authenticated={false} absolutePosition />}
    </>
  );
};

export default BankAccountConnected;
