import { RefObject, useEffect } from 'react';

export const useUpdatePageNumberOnScroll = (
  ref: RefObject<HTMLElement>,
  setPage: (page: number) => void,
  isLoading: boolean,
  zoom: number
) => {
  useEffect(() => {
    const element = ref.current;

    if (isLoading || !element) return;

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      const pageNumbers = entries
        .filter((entry) => entry.intersectionRatio >= 0.5)
        .map((entry) => parseInt((entry.target as HTMLDivElement).dataset.pageNumber!));

      pageNumbers.length && setPage(Math.min(...pageNumbers));
    };

    const observer = new IntersectionObserver(handleIntersect, {
      root: element,
      threshold: 0.5,
    });

    element.childNodes.forEach((page) => observer.observe(page as HTMLDivElement));

    return () => {
      observer.disconnect();
    };
  }, [isLoading, ref, setPage, zoom]);
};

export const useUpdateZoom = (
  ref: RefObject<HTMLElement>,
  setZoom: (page: number) => void,
  windowWidth: number,
  widnowHeight: number,
  pageWidth?: number,
  pageHeight?: number
) => {
  useEffect(() => {
    const element = ref.current;

    if (!element || pageWidth === undefined || pageHeight === undefined) return;

    const sizes = element.getBoundingClientRect();
    const margin = 32;
    const contentHeight = widnowHeight - sizes.top - margin;
    const contentWidth = sizes.width - margin;
    const calculateHeight = contentWidth + contentWidth * 0.4 >= contentHeight;
    const zoom = calculateHeight
      ? Math.min((contentHeight / pageHeight) * 100, widnowHeight)
      : (contentWidth / pageWidth) * 100;

    setZoom(Math.max(Math.min(Math.round(zoom), 300), 25));
  }, [windowWidth, widnowHeight, pageWidth, pageHeight, ref, setZoom]);
};
