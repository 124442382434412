import styled from 'styled-components';

import BaseLabel from 'redesign/components/atoms/Label/Label';
import BaseTooltip from 'redesign/components/molecules/Tooltip/Tooltip';

export const Label = styled(BaseLabel)`
  margin-bottom: ${({ theme }) => theme.space['1']};
`;

export const LabelWrapper = styled.div`
  display: flex;
`;

export const Tooltip = styled(BaseTooltip)`
  min-width: ${({ theme }) => theme.space['6']};
  margin-bottom: ${({ theme }) => theme.space['1']};
  position: relative;
`;
