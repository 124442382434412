import styled, { css } from 'styled-components';

import BaseIconArrowDown from 'redesign/components/atoms/Icons/ArrowDown';
import { Breakpoints } from 'redesign/styles/breakpoints';

export const Wrapper = styled.div`
  display: grid;
  grid-template-areas: 'preview' 'form';
  flex: 1;

  @media (min-width: ${Breakpoints.md}) {
    grid-template-columns: 5fr 5fr;
    grid-template-areas: 'form preview';

    > :first-child {
      border-right: 1px solid ${({ theme }) => theme.colors.grey['040']};
    }
  }
`;

export const FormWrapper = styled.form`
  grid-area: form;
  overflow: auto;
  position: relative;

  @media (min-width: ${Breakpoints.md}) {
    > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
`;

export const PreviewWrapper = styled.div`
  grid-area: preview;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey['040']};
  overflow: auto;
  position: relative;

  @media (min-width: ${Breakpoints.md}) {
    border-bottom: 0;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
`;

type SectionProps = {
  $isOpen: boolean;
};

export const Section = styled.section<SectionProps>`
  color: ${({ theme }) => theme.colors.grey['080']};

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.grey['040']};
  }

  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      @media (max-width: ${Breakpoints.smMax}) {
        ${Content} {
          display: none;
        }
      }
    `}
`;

type ContentProps = {
  $noMaxWidth?: boolean;
};

export const Content = styled.div<ContentProps>`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.space['10']};
  grid-template-columns: ${({ $noMaxWidth }) => !$noMaxWidth && 'minmax(auto, 420px)'};
  padding: ${({ theme }) => `0 ${theme.space['2']} ${theme.space['6']}`};

  @media (min-width: ${Breakpoints.md}) {
    padding: ${({ theme }) => `0 ${theme.space['4']} ${theme.space['10']}`};
  }
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize['base']};
  letter-spacing: 0.25px;
  line-height: ${({ theme }) => theme.space['5']};
  font-weight: bold;
  padding: ${({ theme }) => `${theme.space['4']} ${theme.space['2']}`};

  @media (min-width: ${Breakpoints.md}) {
    padding: ${({ theme }) => `${theme.space['10']} ${theme.space['4']} ${theme.space['4']}`};
    font-size: ${({ theme }) => theme.fontSize['2xl']};
    letter-spacing: 0.5px;
    line-height: ${({ theme }) => theme.space['6']};
  }
`;

export const Description = styled.p`
  letter-spacing: 0.25px;
  line-height: ${({ theme }) => theme.space['6']};
  margin-bottom: 0;
`;

type IconArrowDownProps = {
  $isRotated?: boolean;
};

export const IconArrowDown = styled(BaseIconArrowDown)<IconArrowDownProps>`
  width: 25px;
  height: 14px;
  color: ${({ theme }) => theme.colors.vrblue['100']};

  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }

  ${({ $isRotated }) => $isRotated && 'transform: rotate(180deg)'};
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `${theme.space['4']} ${theme.space['2']}`};
  border-top: 1px solid ${({ theme }) => theme.colors.grey['040']};

  @media (min-width: ${Breakpoints.md}) {
    justify-content: flex-start;
    padding: ${({ theme }) => `${theme.space['10']} ${theme.space['4']}`};
  }
`;

export const FieldTitle = styled.label`
  color: ${({ theme }) => theme.colors.grey['080']};
  font-size: ${({ theme }) => theme.fontSize['base']};
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: ${({ theme }) => theme.space['5']};
  margin-bottom: ${({ theme }) => theme.space['4']};
`;
