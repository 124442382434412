import React, { memo, SVGProps } from 'react';

const Welcome = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 377 188" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <linearGradient x1="59.542%" y1="70.912%" x2="0%" y2="19.252%" id="Welcome_svg__a">
        <stop stopColor="#090547" offset="0%" />
        <stop stopColor="#3FCBFF" offset="100%" />
      </linearGradient>
      <linearGradient x1="55.26%" y1="56.843%" x2="0%" y2="41.827%" id="Welcome_svg__b">
        <stop stopColor="#0066B3" offset="0%" />
        <stop stopColor="#3FCBFF" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="Welcome_svg__c">
        <stop stopColor="#09BCF6" offset="0%" />
        <stop stopColor="#0487EA" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M282.804 50.46c.021-.402.032-.806.032-1.213 0-12.73-10.354-23.05-23.127-23.05-2.19 0-4.308.304-6.316.87a16.564 16.564 0 00-11.5-4.62 16.597 16.597 0 00-14.349 8.234 16.643 16.643 0 00-4.903-.734c-9.168 0-16.598 7.406-16.598 16.543 0 1.369.17 2.698.484 3.97h76.277zM175.666 45.09c.03-.576.045-1.155.045-1.738 0-18.239-14.834-33.025-33.134-33.025-3.137 0-6.172.435-9.049 1.247a23.734 23.734 0 00-16.475-6.619c-8.778 0-16.44 4.745-20.56 11.797a23.842 23.842 0 00-7.025-1.052c-13.134 0-23.78 10.612-23.78 23.701 0 1.962.244 3.866.694 5.689h109.284z"
        fill="#EEF2F6"
      />
      <path
        d="M334.909 22.154h-37.544c-.689 0-1.248.557-1.248 1.244v24.117c0 .687.559 1.244 1.248 1.244h14.857l3.264 3.92 3.537-3.92h15.886c.69 0 1.248-.557 1.248-1.244V23.398c0-.687-.559-1.244-1.248-1.244"
        fill="#436BEE"
      />
      <path fill="#C7E8FB" d="M300.407 27.683h29.314" />
      <path stroke="#C7E8FB" strokeWidth={2.434} strokeLinecap="round" d="M300.407 27.683h29.314" />
      <path fill="#C7E8FB" d="M300.407 41.222h29.314" />
      <path stroke="#C7E8FB" strokeWidth={2.434} strokeLinecap="round" d="M300.407 41.222h29.314" />
      <path fill="#C7E8FB" d="M300.407 34.453h20.846" />
      <path stroke="#C7E8FB" strokeWidth={2.434} strokeLinecap="round" d="M300.407 34.453h20.846" />
      <path
        stroke="#EB5C0B"
        strokeWidth={2.975}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M215.145 86.943l59.701-22.447 18.233 11.402 22.88-22.091 26.096 24.585 27.885-27.436"
      />
      <path
        d="M374.26 44.813l-12.548 5.872a1.067 1.067 0 00-.283 1.742l7.192 6.819c.544.515 1.437.33 1.728-.36l5.356-12.69c.38-.904-.555-1.8-1.445-1.383"
        fill="#EB5C0B"
      />
      <path fill="#FFEDBA" d="M278.697 84.781l-82.668 67.014-32.257-39.529 82.67-67.013z" />
      <path
        stroke="#FBB900"
        strokeWidth={1.275}
        d="M278.697 84.781l-82.668 67.014-32.257-39.529 82.67-67.013z"
      />
      <path fill="#FFBF3A" d="M273.512 84.72l-77.002 62.42-28.068-34.395 77.003-62.42z" />
      <path
        stroke="#FBB900"
        strokeWidth={1.184}
        d="M273.512 84.72l-77.002 62.42-28.068-34.395 77.003-62.42z"
      />
      <path
        d="M237.307 85.709c7.172 8.789 5.838 21.709-2.98 28.857-8.82 7.149-21.782 5.818-28.954-2.97-7.173-8.79-5.838-21.71 2.98-28.858 8.819-7.149 21.782-5.819 28.954 2.97"
        fillOpacity={0.5}
        fill="#FFF"
      />
      <path
        d="M237.307 85.709c7.172 8.789 5.838 21.709-2.98 28.857-8.82 7.149-21.782 5.818-28.954-2.97-7.173-8.79-5.838-21.71 2.98-28.858 8.819-7.149 21.782-5.819 28.954 2.97z"
        stroke="#FFD760"
        strokeWidth={1.184}
      />
      <path
        d="M203.828 112.848a8.253 8.253 0 01-1.202 11.636c-3.556 2.883-8.783 2.346-11.675-1.198a8.253 8.253 0 011.201-11.636c3.556-2.882 8.783-2.346 11.676 1.198"
        fill="#FFEDBA"
      />
      <path
        d="M203.828 112.848a8.253 8.253 0 01-1.202 11.636c-3.556 2.883-8.783 2.346-11.675-1.198a8.253 8.253 0 011.201-11.636c3.556-2.882 8.783-2.346 11.676 1.198z"
        stroke="#FFD760"
        strokeWidth={0.5}
      />
      <path
        d="M253.017 72.974a8.253 8.253 0 01-1.202 11.636c-3.556 2.882-8.783 2.346-11.675-1.198a8.253 8.253 0 011.201-11.636c3.556-2.882 8.783-2.346 11.676 1.198"
        fillOpacity={0.5}
        fill="#FFF"
      />
      <path
        d="M253.017 72.974a8.253 8.253 0 01-1.202 11.636c-3.556 2.882-8.783 2.346-11.675-1.198a8.253 8.253 0 011.201-11.636c3.556-2.882 8.783-2.346 11.676 1.198z"
        stroke="#FFD760"
        strokeWidth={0.5}
      />
      <g>
        <path fill="#FFEDBA" d="M295.436 136.977l-101.733 31.599-15.21-48.645 101.733-31.599z" />
        <path
          stroke="#FBB900"
          strokeWidth={1.275}
          d="M295.436 136.977l-101.733 31.599-15.21-48.645 101.733-31.599z"
        />
        <path fill="#FFBF3A" d="M290.646 135l-94.761 29.432-13.235-42.327 94.76-29.433z" />
        <path
          stroke="#FBB900"
          strokeWidth={1.184}
          d="M290.646 135l-94.761 29.432-13.235-42.327 94.76-29.433z"
        />
        <path
          d="M256.663 122.508c3.383 10.816-2.673 22.317-13.525 25.688-10.852 3.37-22.391-2.665-25.773-13.481-3.382-10.817 2.674-22.317 13.526-25.688 10.852-3.37 22.39 2.665 25.772 13.481"
          fillOpacity={0.5}
          fill="#FFF"
        />
        <path
          d="M256.663 122.508c3.383 10.816-2.673 22.317-13.525 25.688-10.852 3.37-22.391-2.665-25.773-13.481-3.382-10.817 2.674-22.317 13.526-25.688 10.852-3.37 22.39 2.665 25.772 13.481z"
          stroke="#FFD760"
          strokeWidth={1.184}
        />
        <path
          d="M215.463 135.305c1.364 4.362-1.078 9-5.454 10.358-4.376 1.36-9.029-1.074-10.392-5.436-1.364-4.361 1.078-8.998 5.454-10.358 4.376-1.359 9.029 1.075 10.392 5.436"
          fill="#FFEDBA"
        />
        <path
          d="M215.463 135.305c1.364 4.362-1.078 9-5.454 10.358-4.376 1.36-9.029-1.074-10.392-5.436-1.364-4.361 1.078-8.998 5.454-10.358 4.376-1.359 9.029 1.075 10.392 5.436z"
          stroke="#FFD760"
          strokeWidth={0.5}
        />
        <path
          d="M275.996 116.504c1.363 4.36-1.078 8.998-5.454 10.357-4.376 1.36-9.029-1.074-10.392-5.436-1.364-4.36 1.078-8.998 5.454-10.358 4.376-1.359 9.029 1.075 10.392 5.437"
          fillOpacity={0.5}
          fill="#FFF"
        />
        <path
          d="M275.996 116.504c1.363 4.36-1.078 8.998-5.454 10.357-4.376 1.36-9.029-1.074-10.392-5.436-1.364-4.36 1.078-8.998 5.454-10.358 4.376-1.359 9.029 1.075 10.392 5.437z"
          stroke="#FFD760"
          strokeWidth={0.5}
        />
      </g>
      <g>
        <path fill="#FFEDBA" d="M186.454 172.452h106.558V121.5H186.454z" />
        <path stroke="#FBB900" strokeWidth={1.275} d="M186.454 172.452h106.558V121.5H186.454z" />
        <path fill="#FFBF3A" d="M189.773 169.144h99.256V124.81h-99.256z" />
        <path stroke="#FBB900" strokeWidth={1.184} d="M189.773 169.144h99.256V124.81h-99.256z" />
        <path
          d="M260.314 147.142c0 11.33-9.214 20.513-20.58 20.513-11.368 0-20.583-9.184-20.583-20.513 0-11.33 9.215-20.513 20.582-20.513s20.581 9.184 20.581 20.513"
          fillOpacity={0.5}
          fill="#FFF"
        />
        <path
          d="M260.314 147.142c0 11.33-9.214 20.513-20.58 20.513-11.368 0-20.583-9.184-20.583-20.513 0-11.33 9.215-20.513 20.582-20.513s20.581 9.184 20.581 20.513z"
          stroke="#FFD760"
          strokeWidth={1.184}
        />
        <path
          d="M217.16 147.142c0 4.568-3.716 8.271-8.3 8.271-4.582 0-8.298-3.703-8.298-8.271 0-4.568 3.716-8.272 8.299-8.272 4.583 0 8.299 3.704 8.299 8.272"
          fill="#FFEDBA"
        />
        <path
          d="M217.16 147.142c0 4.568-3.716 8.271-8.3 8.271-4.582 0-8.298-3.703-8.298-8.271 0-4.568 3.716-8.272 8.299-8.272 4.583 0 8.299 3.704 8.299 8.272z"
          stroke="#FFD760"
          strokeWidth={0.5}
        />
        <path
          d="M280.564 147.142c0 4.568-3.716 8.271-8.3 8.271-4.582 0-8.298-3.703-8.298-8.271 0-4.568 3.716-8.272 8.299-8.272 4.583 0 8.299 3.704 8.299 8.272"
          fill="#FFEDBA"
        />
        <path
          d="M280.564 147.142c0 4.568-3.716 8.271-8.3 8.271-4.582 0-8.298-3.703-8.298-8.271 0-4.568 3.716-8.272 8.299-8.272 4.583 0 8.299 3.704 8.299 8.272z"
          stroke="#FFD760"
          strokeWidth={0.5}
        />
      </g>
      <g>
        <path
          d="M118.99 59.527c-.183-1.706 2.93-29.878-7.596-25.526-10.527 4.353-12.997 16.68-19.457 8.273-6.46-8.407-40.167-55.36-41.691-38.742-2.287 24.94-9.413 9.12-21.246 4.37-25.559-10.26-49.69 18.96-.471 36.735C77.747 62.412 43.927 62.66 43.927 62.66S23.377 81.51 69.494 82.823c46.117 1.312 48.42 10.719 48.42 10.719l1.077-34.015z"
          fill="url(#Welcome_svg__a)"
          transform="translate(.767 65.376)"
        />
        <path
          d="M78.9 119.033c-.92-5.038-6.596-32.23-21.469-46.315M49.916 133.15c2.02.877 27.618-4.338 38.23-6.32M21.204 88.19s74.001 34.206 91.161 60.93"
          stroke="#155FAA"
          strokeWidth={1.378}
          strokeLinecap="round"
        />
      </g>
      <g>
        <path fill="#000" d="M156.538 157.026l-63.074 18.362L67.277 86.03l63.074-18.361z" />
        <path
          stroke="#000"
          strokeWidth={0.66}
          d="M156.538 157.026l-63.074 18.362L67.277 86.03l63.074-18.361z"
        />
        <path
          stroke="#000"
          strokeWidth={1.981}
          strokeLinecap="round"
          d="M81.921 113.299l17.453-5.081M82.917 116.7l17.454-5.082"
        />
        <path
          stroke="#000"
          strokeWidth={0.732}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M76.711 95.522l6.921-2.015M77.12 96.917l8.168-2.378M77.528 98.31l6.922-2.014"
        />
        <path
          stroke="#000"
          strokeWidth={0.887}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M97.815 167.536l10.148-2.954"
        />
        <path
          stroke="#000"
          strokeWidth={0.818}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M98.224 168.93l6.921-2.014"
        />
        <path
          stroke="#000"
          strokeWidth={0.887}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M98.633 170.325l10.148-2.954"
        />
        <path
          stroke="#000"
          strokeWidth={0.818}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M112.415 163.286l8.644-2.517M112.824 164.68l10.2-2.97M113.233 166.075l8.644-2.516M125.14 159.58l8.644-2.515M125.548 160.976l7.901-2.3M125.957 162.37l8.644-2.516"
        />
        <path
          stroke="#000"
          strokeWidth={0.732}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M77.937 99.705l8.168-2.377"
        />
        <path
          stroke="#000"
          strokeWidth={1.981}
          strokeLinecap="round"
          d="M83.74 119.508l15.14-4.408M87.623 132.377l10.29-3.212M130.282 119.06l6.474-2.02M100.571 128.336l25.827-8.063M89.448 138.184l10.29-3.212M132.107 124.868l6.473-2.021M102.395 134.142l25.828-8.062M90.867 142.7l10.29-3.212M133.526 129.385l6.474-2.021M103.815 138.659l25.827-8.062M92.692 148.508l10.29-3.213M135.351 135.192l6.474-2.021M105.639 144.466l25.828-8.062"
        />
      </g>
      <g>
        <path fill="#FFF" d="M155.5 155.992l-63.074 18.362-26.187-89.358 63.074-18.362z" />
        <path
          stroke="#E6E6E6"
          strokeWidth={0.66}
          d="M155.5 155.992l-63.074 18.362-26.187-89.358 63.074-18.362z"
        />
        <path
          stroke="#4D4D4D"
          strokeWidth={1.981}
          strokeLinecap="round"
          d="M80.882 112.264l17.454-5.081M81.88 115.665l17.453-5.081"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.732}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M75.673 94.487l6.921-2.015M76.081 95.882l8.168-2.378M76.49 97.276l6.922-2.015"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.887}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M96.777 166.5l10.148-2.953"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.818}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M97.185 167.896l6.922-2.015"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.887}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M97.594 169.29l10.148-2.954"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.818}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M111.377 162.25l8.644-2.515M111.786 163.645l10.2-2.97M112.194 165.04l8.645-2.516M124.102 158.547l8.644-2.516M124.51 159.94l7.901-2.3M124.92 161.336l8.643-2.517"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.732}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M76.9 98.67l8.167-2.377"
        />
        <path
          stroke="#4D4D4D"
          strokeWidth={1.981}
          strokeLinecap="round"
          d="M82.702 118.473l15.14-4.407M86.585 131.342l10.29-3.212M129.244 118.026l6.474-2.021M99.532 127.3l25.828-8.062M88.41 137.149l10.29-3.212M131.07 123.833l6.472-2.021M101.357 133.108l25.828-8.062M89.83 141.666l10.29-3.212M132.488 128.35l6.474-2.021M102.777 137.624l25.827-8.062M91.654 147.473l10.29-3.212M134.313 134.157l6.473-2.021M104.601 143.43l25.828-8.06"
        />
        <text
          fontFamily="FrutigerVR-Bold, Frutiger VR"
          fontSize={15.569}
          fontWeight="bold"
          fill="#EB5C0B"
          transform="translate(66.24 66.634)"
        >
          <tspan x={71.037} y={76.864}>
            {'\u20AC'}
          </tspan>
        </text>
        <path
          d="M120.077 80.394a4.81 4.81 0 01-3.049 6.092 4.834 4.834 0 01-6.112-3.039 4.81 4.81 0 013.049-6.092 4.833 4.833 0 016.112 3.039"
          fill="#EB5C0B"
        />
        <path
          d="M127.365 77.965a4.811 4.811 0 01-3.05 6.092 4.834 4.834 0 01-6.112-3.039 4.81 4.81 0 013.05-6.092 4.833 4.833 0 016.112 3.039"
          fill="#52B4E7"
        />
        <path
          d="M125.148 83.78a4.81 4.81 0 01-3.048 6.092 4.834 4.834 0 01-6.113-3.04 4.81 4.81 0 013.049-6.091 4.833 4.833 0 016.112 3.039"
          fill="#0F2364"
        />
      </g>
      <g>
        <path
          d="M92.901 25.665C84.978 23.214 51.935-14.063 0 5.812c0 0 15.089 46.867 76.297 30.993 1.272.242 22.852-5.137 22.852-5.137L92.9 25.665z"
          fill="url(#Welcome_svg__b)"
          transform="translate(33.538 137.057)"
        />
        <path
          d="M130.33 168.848s-9.455-1.237-22.3-3.62c-18.833-3.495-53.484-10.19-67.77-18.332M103.312 163.881c.244-.243-6.086-12.374-17.285-16.257M79.698 159.999s-11.442 5.823-26.78 2.67M70.934 142.529s6.749 6.354 5.444 15.551M58.275 153.933s-3.409-8.978-10.956-13.103M99.023 163.988s-8.127 6.93-22.49 10.084"
          stroke="#26295B"
          strokeWidth={0.976}
          strokeLinecap="round"
        />
      </g>
      <g>
        <path fill="#436BEE" d="M255.194 173.07h20.756v-55.45h-20.756z" />
        <path fill="#2747B0" d="M284.731 173.071h20.756V85.162h-20.756z" />
        <path fill="#0F2364" d="M314.268 173.07h20.756v-70.764h-20.756z" />
      </g>
      <path
        stroke="#615D5C"
        strokeWidth={1.275}
        strokeLinecap="round"
        d="M206.207 172.814h167.665"
      />
      <g strokeLinecap="round">
        <g stroke="#CCC" strokeWidth={1.251}>
          <path d="M330.032 172.923l-13.48 12.606M342.005 172.923l-8.509 12.606M319.451 183.715h15.287M320.091 182.18l15.701-.004M321.786 180.63l14.985.007M323.442 179.08h14.384M325.1 177.53h14.081M326.756 175.98h13.178M328.412 174.298h12.652" />
        </g>
        <path
          stroke="#FFAA71"
          strokeWidth={1.791}
          d="M318.51 120.831l-2.485 64.698M330.484 120.831l2.485 64.698M316.401 176.297h16.342M317.08 168.341h15.211M317.08 160.386h14.91M317.681 152.43h14.083M317.983 144.473h13.254M317.983 136.518h12.802M318.51 127.886h11.974"
        />
      </g>
      <g>
        <path
          d="M218.547 175.32h-80.098c-4.641 0-8.404-3.75-8.404-8.378V60.77c0-4.627 3.763-8.377 8.404-8.377h80.098c4.641 0 8.404 3.75 8.404 8.377v106.173c0 4.627-3.763 8.377-8.404 8.377"
          fill="#0F2364"
        />
        <path
          d="M85.53 35.708H10.836a2.17 2.17 0 01-2.173-2.166v-19.67c0-1.195.973-2.164 2.173-2.164h74.696c1.2 0 2.172.969 2.172 2.165v19.669a2.17 2.17 0 01-2.172 2.166"
          fill="url(#Welcome_svg__c)"
          transform="translate(130.045 52.392)"
        />
        <path
          d="M153.553 117.739h-13.12a1.53 1.53 0 01-1.533-1.528V107.6a1.53 1.53 0 011.533-1.528h13.12a1.53 1.53 0 011.532 1.528v8.611a1.53 1.53 0 01-1.532 1.528M174.376 117.739h-13.12a1.53 1.53 0 01-1.533-1.528V107.6a1.53 1.53 0 011.533-1.528h13.12a1.53 1.53 0 011.532 1.528v8.611a1.53 1.53 0 01-1.532 1.528M195.199 117.739h-13.12a1.53 1.53 0 01-1.532-1.528V107.6a1.53 1.53 0 011.532-1.528h13.12a1.53 1.53 0 011.532 1.528v8.611a1.53 1.53 0 01-1.532 1.528"
          fill="#0487EA"
        />
        <path
          d="M159.628 99.603h-19.443c-.71 0-1.284-.573-1.284-1.28v-3.466c0-.708.575-1.281 1.284-1.281h19.443c.71 0 1.285.573 1.285 1.28v3.467c0 .707-.575 1.28-1.285 1.28"
          fill="#E54065"
        />
        <path
          d="M187.949 99.603h-19.443c-.71 0-1.285-.573-1.285-1.28v-3.466c0-.708.575-1.281 1.285-1.281h19.443c.71 0 1.285.573 1.285 1.28v3.467c0 .707-.576 1.28-1.285 1.28"
          fill="#FBB900"
        />
        <path
          d="M216.27 99.603h-19.444c-.709 0-1.284-.573-1.284-1.28v-3.466c0-.708.575-1.281 1.284-1.281h19.444c.709 0 1.284.573 1.284 1.28v3.467c0 .707-.575 1.28-1.284 1.28"
          fill="#C7E8FB"
        />
        <path
          d="M216.27 99.603h-19.444c-.709 0-1.284-.573-1.284-1.28v-3.466c0-.708.575-1.281 1.284-1.281h19.444c.709 0 1.284.573 1.284 1.28v3.467c0 .707-.575 1.28-1.284 1.28z"
          stroke="#C7E8FB"
          strokeWidth={0.5}
        />
        <path
          d="M214.616 148.978h-10.308a2.934 2.934 0 01-2.939-2.93v-37.047a2.934 2.934 0 012.94-2.929h10.307a2.934 2.934 0 012.939 2.93v37.046a2.934 2.934 0 01-2.94 2.93"
          fill="#FBB900"
        />
        <path
          d="M153.553 133.478h-13.12a1.53 1.53 0 01-1.533-1.527v-8.612a1.53 1.53 0 011.533-1.527h13.12a1.53 1.53 0 011.532 1.527v8.612a1.53 1.53 0 01-1.532 1.527M174.376 133.478h-13.12a1.53 1.53 0 01-1.533-1.527v-8.612a1.53 1.53 0 011.533-1.527h13.12a1.53 1.53 0 011.532 1.527v8.612a1.53 1.53 0 01-1.532 1.527M195.199 133.478h-13.12a1.53 1.53 0 01-1.532-1.527v-8.612a1.53 1.53 0 011.532-1.527h13.12a1.53 1.53 0 011.532 1.527v8.612a1.53 1.53 0 01-1.532 1.527M153.553 149.217h-13.12a1.53 1.53 0 01-1.533-1.527v-8.612a1.53 1.53 0 011.533-1.527h13.12a1.53 1.53 0 011.532 1.527v8.612a1.53 1.53 0 01-1.532 1.527M174.376 149.217h-13.12a1.53 1.53 0 01-1.533-1.527v-8.612a1.53 1.53 0 011.533-1.527h13.12a1.53 1.53 0 011.532 1.527v8.612a1.53 1.53 0 01-1.532 1.527M195.199 149.217h-13.12a1.53 1.53 0 01-1.532-1.527v-8.612a1.53 1.53 0 011.532-1.527h13.12a1.53 1.53 0 011.532 1.527v8.612a1.53 1.53 0 01-1.532 1.527"
          fill="#0487EA"
        />
        <path
          d="M173.54 164.956h-32.324a2.312 2.312 0 01-2.316-2.308v-7.05a2.312 2.312 0 012.316-2.308h32.324a2.312 2.312 0 012.315 2.307v7.051a2.312 2.312 0 01-2.315 2.308"
          fill="#FBB900"
        />
        <path
          d="M195.199 164.956h-13.12a1.53 1.53 0 01-1.532-1.527v-8.612a1.53 1.53 0 011.532-1.527h13.12a1.53 1.53 0 011.532 1.527v8.612a1.53 1.53 0 01-1.532 1.527"
          fill="#0066B3"
        />
        <path
          d="M216.022 164.956h-13.12a1.53 1.53 0 01-1.532-1.527v-8.612a1.53 1.53 0 011.532-1.527h13.12a1.53 1.53 0 011.532 1.527v8.612a1.53 1.53 0 01-1.532 1.527"
          fill="#EB5C0B"
        />
      </g>
    </g>
  </svg>
);

export default memo(Welcome);
