import React, { memo } from 'react';
import cx from 'classnames';
import type { WrappedFieldArrayProps } from 'redux-form';

import { t } from 'shared/utils';
import InfoIcon from 'components/InfoIcon/InfoIcon';

import AddMoreButton from '../../ExpensesRevenues/AddMoreButton/AddMoreButton';
import Amount, { Color } from '../../ExpensesRevenues/Fields/Amount';
import Interval from '../../ExpensesRevenues/Fields/Interval';
import Name from '../../ExpensesRevenues/Fields/Name';
import { requiredAndTouchedChecker, requiredValidate } from '../../ExpensesRevenues/Fields/utils';
import RemoveButton from '../../ExpensesRevenues/RemoveButton/RemoveButton';
import Body from '../../ExpensesRevenues/Table/Body';
import BodyCell from '../../ExpensesRevenues/Table/BodyCell';
import BodyRow from '../../ExpensesRevenues/Table/BodyRow';
import HeaderCell from '../../ExpensesRevenues/Table/HeaderCell';
import HeaderRow from '../../ExpensesRevenues/Table/HeaderRow';
import TableComponent from '../../ExpensesRevenues/Table/Table';
import { trackEvent } from '../../utils';
import { NewExpense } from '../constants';
import type { RecurringExpense } from '../types';

import sharedTableStyles from '../../ExpensesRevenues/ExpensesRevenues.module.css';
import styles from './Table.module.css';

const Table = ({ fields }: WrappedFieldArrayProps<RecurringExpense>) => (
  <>
    <TableComponent>
      <HeaderRow>
        <HeaderCell className={sharedTableStyles.nameHeaderCell}>
          {t(
            'features.financial_planning.liquidity_calculator.expenses.fields.recurring_expenses.name.table_header'
          )}
        </HeaderCell>
        <HeaderCell className={cx(sharedTableStyles.intervalHeaderCell, styles.intervalHeaderCell)}>
          <span className={styles.intervalHeaderText}>
            {t(
              'features.financial_planning.liquidity_calculator.expenses.fields.recurring_expenses.interval.table_header'
            )}
          </span>
          <InfoIcon
            className={styles.infoIcon}
            text={t(
              'features.financial_planning.liquidity_calculator.expenses.fields.recurring_expenses.interval.table_header_tooltip'
            )}
          />
        </HeaderCell>
        <HeaderCell className={sharedTableStyles.amountHeaderCell}>
          {t(
            'features.financial_planning.liquidity_calculator.expenses.fields.recurring_expenses.amount.table_header'
          )}
        </HeaderCell>
        <HeaderCell className={sharedTableStyles.actionHeaderCell} />
      </HeaderRow>
      <Body>
        {fields.map((expense, index) => (
          <BodyRow key={index} dataId={`LiquidityCalculatorExpenses:row-${index}`}>
            <BodyCell className={sharedTableStyles.nameBodyCell}>
              <Name
                name={`${expense}.name`}
                label={t(
                  'features.financial_planning.liquidity_calculator.expenses.fields.recurring_expenses.name.label'
                )}
                validate={requiredValidate}
                checker={requiredAndTouchedChecker}
                isLabelHidden
                dataId={'LiquidityCalculatorExpenses:input-name'}
              />
            </BodyCell>
            <BodyCell className={sharedTableStyles.intervalBodyCell}>
              <Interval
                name={`${expense}.interval`}
                label={t(
                  'features.financial_planning.liquidity_calculator.expenses.fields.recurring_expenses.interval.label'
                )}
                validate={requiredValidate}
                checker={requiredAndTouchedChecker}
                isLabelHidden
                dataId={`LiquidityCalculatorExpenses:input-interval`}
              />
            </BodyCell>
            <BodyCell className={sharedTableStyles.amountBodyCell}>
              <Amount
                name={`${expense}.amount`}
                label={t(
                  'features.financial_planning.liquidity_calculator.expenses.fields.recurring_expenses.amount.label'
                )}
                color={Color.Red}
                validate={requiredValidate}
                checker={requiredAndTouchedChecker}
                isLabelHidden
                dataId="LiquidityCalculatorExpenses:input-amount"
              />
            </BodyCell>
            <BodyCell className={sharedTableStyles.actionBodyCell}>
              <RemoveButton
                onClick={() => {
                  fields.remove(index);
                  trackEvent('Delete_Expense');
                }}
                dataId="LiquidityCalculatorExpenses:button-remove"
              />
            </BodyCell>
          </BodyRow>
        ))}
      </Body>
    </TableComponent>
    <AddMoreButton
      onClick={() => {
        fields.push(NewExpense);
        trackEvent('Add_Expense');
      }}
      dataId={`LiquidityCalculatorExpenses:button-addMore`}
    >
      {`+ ${t('features.financial_planning.liquidity_calculator.expenses.add_expense')}`}
    </AddMoreButton>
  </>
);

export default memo(Table);
