import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { camelCase, snakeCase } from 'lodash';
import { bool, func, number, oneOfType, shape, string } from 'prop-types';

import { OPTION_DISCOUNT, OPTION_JOKER, OPTION_PARTLY_PAID } from 'constants/assigment-select';
import { DE_ACCOUNT_REVENUE_REMOVED, DE_KONTOUMSAETZE } from 'constants/kontoumsaetze';
import paths from 'routes/paths';
import { formatDate, formatMoney, noop, t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import { ResourceType } from 'types/entities/AssignableResource';
import IncomingInvoiceAmount from 'components/Table/IncomingInvoiceAmount';
import InvoiceStatusBadge from 'components/Table/InvoiceStatusBadge/InvoiceStatusBadge';

import StatusOptions from './StatusOptions';

import styles from './AssignmentsStyles.module.css';

const pathsMap = {
  incomingInvoice: paths.showIncomingInvoice,
  outgoingInvoice: paths.showOutgoingInvoice,
};

const Invoice = ({
  invoiceDate,
  creationDate,
  type,
  id,
  number: invoiceNumber,
  shouldLink,
  totalGrossAmount: amount,
  canDelete,
  canEdit,
  onDelete,
  onAccept,
  onReject,
  onEdit,
  currency,
  creditNote,
  displayName,
  isFetching,
  status,
  grossAmountToBePaid,
  discountGrossValue,
  jokerOptionAmount,
  bankTransferConnection: { selectedOption } = {},
}) => {
  const amounts = {
    [OPTION_PARTLY_PAID.value]: {
      displayAmount: formatMoney(grossAmountToBePaid),
      amount: grossAmountToBePaid,
    },
    [OPTION_DISCOUNT.value]: {
      displayAmount: formatMoney(discountGrossValue),
      amount: discountGrossValue,
    },
    [OPTION_JOKER.value]: {
      displayAmount: formatMoney(jokerOptionAmount),
      amount: jokerOptionAmount,
    },
  };
  return (
    <div
      data-id={`BankTransferAssignment:assigned-bank-transfer-container_${formatDate(
        invoiceDate || creationDate
      )}`}
      className={styles.bankTransfer}
    >
      <div className={styles.dataWrapper}>
        <div data-id="BankTransferAssignment:assigned-bank-transfer-date" className={styles.date}>
          {formatDate(invoiceDate || creationDate)}
        </div>
        <div
          data-id="BankTransferAssignment:assigned-bank-transfer-invoice"
          className={styles.creditorName}
        >
          {shouldLink ? (
            <Link to={pathsMap[camelCase(type)](id)}>{invoiceNumber}</Link>
          ) : (
            invoiceNumber
          )}
        </div>
        <div
          data-id="BankTransferAssignment:assigned-bank-transfer-name"
          className={styles.displayName}
        >
          {displayName}
        </div>
      </div>
      <div
        data-id="BankTransferAssignment:assigned-bank-transfer-status"
        className={styles.statusWrapper}
      >
        <InvoiceStatusBadge status={status} />
        <StatusOptions
          dataId="BankTransferAssignment:assigned-bank-transfer-remaining-amount"
          selectedPaymentOption={selectedOption}
          amounts={amounts}
        />
      </div>
      {snakeCase(type) === 'incoming_invoice' ? (
        <IncomingInvoiceAmount
          dataId="BankTransferAssignment:assigned-bank-transfer-amount"
          bold
          amount={amount}
          creditNote={creditNote}
        />
      ) : (
        <div
          data-id="BankTransferAssignment:assigned-bank-transfer-amount"
          className={cx(styles.amount, { [styles.amountNegative]: amount < 0 })}
        >
          {formatMoney(amount, currency)}
        </div>
      )}
      <div className={styles.actionButtons}>
        {canEdit && (
          <button
            type="button"
            onClick={() =>
              onEdit(
                id,
                type === 'incoming_invoice'
                  ? ResourceType.IncomingInvoice
                  : ResourceType.OutgoingInvoice
              )
            }
            className={styles.edit}
            title={t('bank_transfers.select.actions.edit')}
            data-id="BankTransferAssignment:button-edit-assigned-bank-transfer"
          />
        )}
        {canDelete && (
          <button
            type="button"
            onClick={() => {
              piwikHelpers.trackEvent(DE_KONTOUMSAETZE, DE_ACCOUNT_REVENUE_REMOVED);
              onDelete();
            }}
            className={styles.delete}
            title={t('bank_transfers.select.actions.unassign')}
            data-id="BankTransferAssignment:button-delete-assigned-bank-transfer"
          />
        )}
        {(onAccept || onReject) && onAccept && (
          <button
            type="button"
            onClick={onAccept}
            className={styles.accept}
            title={t('bank_transfers.select.actions.accept_suggestion')}
            disabled={isFetching}
            data-id="BankTransferAssignment:button-accept-suggestion"
          />
        )}
        {onReject && (
          <button
            type="button"
            onClick={onReject}
            className={styles.cancel}
            title={t('bank_transfers.select.actions.reject_suggestion')}
            disabled={isFetching}
            data-id="BankTransferAssignment:button-decline-suggestion"
          />
        )}
      </div>
    </div>
  );
};
Invoice.propTypes = {
  bankTransferConnection: shape({
    selectedOption: string,
  }),
  status: string,
  invoiceDate: string,
  creationDate: string,
  number: string,
  totalGrossAmount: oneOfType([number, string]).isRequired,
  currency: string.isRequired,
  onDelete: func,
  onAccept: func,
  onReject: func,
  onEdit: func,
  canDelete: bool,
  canEdit: bool,
  creditNote: bool,
  type: string,
  shouldLink: bool,
  id: string.isRequired,
  displayName: string,
  isFetching: bool,
  grossAmountToBePaid: number,
  discountGrossValue: number,
  jokerOptionAmount: number,
};

Invoice.defaultProps = {
  onAccept: null,
  onReject: null,
  onDelete: noop,
  canDelete: false,
  number: '',
  shouldLink: false,
  displayName: '',
  grossAmountToBePaid: 0,
};

export default Invoice;
