import axios from 'axios';
import { stringify } from 'qs';

import { BASE_URL } from 'constants/api';

const api = axios.create({
  baseURL: BASE_URL,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'brackets' }),
});

api.interceptors.request.use(
  (config) => {
    const { store } = require('store');
    const {
      auth: { token },
    } = store.getState();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers['Accept-Version'] = 'v2';

    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
