import React from 'react';
import moment from 'moment';
import { shape } from 'prop-types';

import { DATE_FORMAT, DATE_FORMAT_FULL_REVERSED } from 'constants/datetime';
import lineItemStyles from 'shared/styles/line-items.module.css';
import tableStyles from 'shared/styles/table.module.css';
import I18n from 'components/I18n';
import IncomingInvoiceAmount from 'components/Table/IncomingInvoiceAmount';

import PaymentColumn from '../PaymentColumn/PaymentColumn';

const PaymentRow = ({
  payment: { createdAt, figoPaymentDate, payerAccountName, recipientIban, amount },
}) => (
  <tr>
    <PaymentColumn className={lineItemStyles.cell}>
      <I18n t="expenses.payments.fields.date" className={tableStyles.cellHeader} />
      {moment(createdAt).format(DATE_FORMAT)}
    </PaymentColumn>
    <PaymentColumn className={lineItemStyles.cell}>
      <I18n t="expenses.payments.fields.issuers_bank_account" className={tableStyles.cellHeader} />
      {payerAccountName}
    </PaymentColumn>
    <PaymentColumn className={lineItemStyles.cell}>
      <I18n
        t="expenses.payments.fields.recipients_bank_account"
        className={tableStyles.cellHeader}
      />
      {recipientIban}
    </PaymentColumn>
    <PaymentColumn className={lineItemStyles.cell}>
      <I18n t="expenses.payments.fields.payment_task_date" className={tableStyles.cellHeader} />
      {moment(figoPaymentDate, DATE_FORMAT_FULL_REVERSED).format(DATE_FORMAT)}
    </PaymentColumn>
    <PaymentColumn className={lineItemStyles.cell}>
      <I18n t="expenses.payments.fields.amount" className={tableStyles.cellHeader} />
      <IncomingInvoiceAmount amount={amount} />
    </PaymentColumn>
  </tr>
);

PaymentRow.propTypes = {
  payment: shape({}).isRequired,
};

export default PaymentRow;
