import React from 'react';
import { bool, func, number, string } from 'prop-types';

import { checkLength } from 'shared/utils/form-checking';
import TextareaField from 'components/Form/TextareaField/TextareaField';

import styles from './TextInputWithCounter.module.css';

const MAX_INPUT_LENGTH = 1000;

export const TextInputWithCounter = ({
  placeholder = '',
  label,
  name,
  invalid = false,
  required = false,
  onChange,
  value,
  dataTestId = '',
  maxInputLength = MAX_INPUT_LENGTH,
  ...props
}) => {
  const inputLength = checkLength(value);

  return (
    <div className={styles.container}>
      <TextareaField
        name={name}
        className={styles.input}
        minHeight={20}
        invalid={invalid}
        required={required}
        maxLength={maxInputLength}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        label={
          <>
            {label || placeholder}{' '}
            <span className={styles.counter}>
              {inputLength}/{maxInputLength}
            </span>
          </>
        }
        dataTestId={dataTestId}
        {...props}
      />
    </div>
  );
};

TextInputWithCounter.propTypes = {
  placeholder: string,
  label: string,
  name: string.isRequired,
  invalid: bool,
  required: bool,
  onChange: func.isRequired,
  value: string.isRequired,
  dataTestId: string,
  maxInputLength: number,
};
