import React from 'react';

import styles from '../StatusIcons.module.css';

const ActiveStatusIcon = () => (
  <svg
    className={styles.statusIcon}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter
        x="-.5%"
        y="-1%"
        width="101%"
        height="102.3%"
        filterUnits="objectBoundingBox"
        id="aktiv_svg__a"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.788235294 0 0 0 0 0.788235294 0 0 0 0 0.788235294 0 0 0 1 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        d="M2 0h1340a2 2 0 012 2v562a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z"
        id="aktiv_svg__b"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M-114-445h1434v688H-114z" fill="#E6E6E6" />
      <g transform="translate(-48 -361)">
        <use fill="#000" filter="url(#aktiv_svg__a)" xlinkHref="#aktiv_svg__b" />
        <use fill="#FFF" xlinkHref="#aktiv_svg__b" />
      </g>
      <path d="M-19-24h1281v66H-19z" fill="#FFF" />
      <g stroke="#198228">
        <rect x={0.5} y={0.5} width={19} height={19} rx={2} />
        <path d="M12.238 14.842c-.075.478-.154.557-.25.605h0L9.224 16.83a.498.498 0 01-.623-.145h0L8.5 13.61a.498.498 0 01.408-.485h0l3.085 1.43c.12.062.205.166.245.286z" />
        <path d="M8 15c-2.488 0-5-1.145-5-4.365C3 8.488 3.833 6.943 5.5 6" strokeLinecap="round" />
        <g>
          <path d="M7.703 4.907c.076-.478.154-.557.251-.605h0l2.764-1.382a.498.498 0 01.623.146h0l.1 3.072a.498.498 0 01-.407.485h0l-3.085-1.43a.498.498 0 01-.246-.286z" />
          <path
            d="M11.942 4.75c2.488 0 5 1.144 5 4.364 0 2.147-.834 3.692-2.5 4.635"
            strokeLinecap="round"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ActiveStatusIcon;
