import React from 'react';
import cx from 'classnames';
import { bool, func, node, oneOfType, shape, string } from 'prop-types';
import { Field } from 'redux-form';

import { Checkbox } from 'components/Form';

import styles from './InvoiceTemplateCheckbox.module.css';

const InvoiceTemplateCheckbox = ({ children, disabled = false, name, dataIds, ...props }) => (
  <Field
    component={(renderProps) => <Checkbox {...renderProps} {...props} />}
    name={name}
    className={cx(styles.content, disabled && styles.disabled)}
    disabled={disabled}
    dataIds={dataIds}
    {...props}
  >
    <p className={styles.labelContent}>{children}</p>
  </Field>
);

InvoiceTemplateCheckbox.propTypes = {
  children: oneOfType([func, node]),
  disabled: bool,
  name: string.isRequired,
  dataIds: shape({
    input: string,
    label: string,
  }),
};

export default InvoiceTemplateCheckbox;
