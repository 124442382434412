import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { RouteProps, useHistory, withRouter } from 'react-router-dom';
import { Push, push as pushAction } from 'connected-react-router';
import { get } from 'lodash';
import { parse } from 'query-string';
import { compose } from 'redux';
import { Form, InjectedFormProps, reduxForm } from 'redux-form';

import {
  requestRegistration as requestRegistrationAction,
  resetPersistedCustomer as resetPersistedCustomerAction,
} from 'actions/registration-request';
import { setCompany } from 'actions/registration-request'; // Import setCompany action
import LoginFooter from 'pages/shared/LoginFooter/LoginFooter';
import Page from 'pages/shared/Page/Page';
import paths from 'routes/paths';
import { withFormInitialValues } from 'shared/hoc';
import { t } from 'shared/utils';
import { FieldsGroup, SubmitButton, TextField } from 'components/Form';
import FormField from 'components/Form/FormField';

import localStyles from './RequestRegistration.module.css';

interface RequestRegistrationOwnProps {}

interface RequestRegistrationMergeProps {
  iframe: string;
  registrationSource: string;
}

interface RequestRegistrationStateProps {
  isFetching: boolean;
}

interface RequestRegistrationDispatchProps {
  requestRegistration: any;
  push: Push;
  resetPersistedCustomer: any;
}

interface RequestRegistrationProps
  extends RequestRegistrationStateProps,
    RequestRegistrationDispatchProps,
    RequestRegistrationOwnProps,
    RequestRegistrationMergeProps,
    InjectedFormProps<FormData, RequestRegistrationProps> {}

interface FormData {
  address: string;
  firstName: string;
  lastName: string;
  email: string;
  crefo_id: string;
  commercial_name: string;
  legal_entity_name: string;
  zip_code: string;
  honorific: string;
}

const RequestRegistration: FunctionComponent<RequestRegistrationProps> = ({
  handleSubmit,
  requestRegistration,
  registrationSource,
  resetPersistedCustomer,
  isFetching,
  setCompany,
  push,
}) => {
  const history = useHistory();
  const selectedCompanyFromStore = useSelector((state) => state.registrationRequest.company);
  const [selectedGender, setSelectedGender] = useState('');

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  useEffect(() => {
    resetPersistedCustomer();
  }, [resetPersistedCustomer]);

  useEffect(() => {
    if (
      Object.keys(selectedCompanyFromStore).length === 0 &&
      selectedCompanyFromStore.constructor === Object
    ) {
      history.push(paths.companySelectRegistration);
    }
  }, [selectedCompanyFromStore, history]);

  const onSubmit = (values: FormData) => {
    const selectedCompany = { ...selectedCompanyFromStore };
    selectedCompany.firstName = values.firstName;
    selectedCompany.lastName = values.lastName;
    selectedCompany.email = values.email;
    selectedCompany.honorific = selectedGender;

    setCompany(selectedCompany);

    const mergedData = {
      ...values,
      registrationSource,
    };

    mergedData.honorific = selectedGender;

    return requestRegistration(mergedData).then(
      (requestRegistrationResponse: { response: { token: string } } | null) => {
        if (!requestRegistrationResponse) return;

        push(paths.getRegisterPath(values.email));
      }
    );
  };

  return (
    <Page header={t('request_registration.header')}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={localStyles.title}>{t('request_registration.title')}</div>
        <div className={localStyles.subtitle}>{t('request_registration.subtitle')}</div>
        <FieldsGroup>
          <div>
            <label className={localStyles.radioLabel}>
              <input
                type="radio"
                name="gender"
                value="Herr"
                checked={selectedGender === 'Herr'}
                onChange={handleGenderChange}
                className={localStyles.radioButton}
              />
              Herr
            </label>
            <label className={localStyles.radioLabel}>
              <input
                type="radio"
                name="gender"
                value="Frau"
                checked={selectedGender === 'Frau'}
                onChange={handleGenderChange}
                className={localStyles.radioButton}
              />
              Frau
            </label>
          </div>
          <FormField
            component={TextField}
            name="firstName"
            placeholder={t('request_registration.first_name')}
            required
            dataId="input-first-name"
          />
          <FormField
            component={TextField}
            name="lastName"
            placeholder={t('request_registration.last_name')}
            required
            dataId="input-last-name"
          />
          <FormField
            component={TextField}
            name="email"
            placeholder={t('request_registration.email')}
            type="text"
            required
            dataId="input-email"
          />
        </FieldsGroup>
        <FieldsGroup className={localStyles.submitFieldsGroup}>
          <SubmitButton
            className={localStyles.submitButton}
            value={t('request_registration.submit')}
            active={!isFetching}
            dataId="button-submit"
          />
        </FieldsGroup>
      </Form>
      <LoginFooter />
    </Page>
  );
};

const mapStateToProps = (state: any) => ({
  ...state.registrationRequest,
});

const mapDispatchToProps = {
  requestRegistration: requestRegistrationAction,
  push: pushAction,
  resetPersistedCustomer: resetPersistedCustomerAction,
  setCompany: setCompany,
};

const mergeProps = (
  stateProps: RequestRegistrationStateProps,
  dispatchProps: RequestRegistrationDispatchProps,
  ownProps: RouteProps
) => {
  const { iframe = '', source = '' } = parse(ownProps.location ? ownProps.location.search : '');
  const registrationSource = iframe ? 'iframe' : source;

  return {
    ...stateProps,
    ...dispatchProps,
    registrationSource,
  };
};

export default compose<FunctionComponent<RequestRegistrationOwnProps>>(
  withRouter,
  withFormInitialValues(({ router }) => {
    const email = get(router, 'location.query.email', '');
    return { email };
  }),
  connect<
    RequestRegistrationStateProps,
    RequestRegistrationDispatchProps,
    RequestRegistrationMergeProps & RouteProps
  >(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm<FormData>({
    form: 'requestRegistration',
    enableReinitialize: false,
    keepDirtyOnReinitialize: true,
    persistentSubmitErrors: true,
  })
)(RequestRegistration);
