import React, { CSSProperties, forwardRef, Ref } from 'react';
import cx from 'classnames';
import { format } from 'date-fns';

import { DATE_FORMAT_UNICODE } from 'constants/datetime';
import { formatMoney, t } from 'shared/utils';
import type { VrsoInstallment } from 'types/entities/Contract';

import styles from './Tooltip.module.css';

type TooltipProps = {
  indicatorInstallment: VrsoInstallment;
  indicatorIndex: number;
  style: CSSProperties;
};

const Tooltip = (
  { indicatorInstallment, style, indicatorIndex }: TooltipProps,
  ref: Ref<HTMLDivElement>
) => (
  <div
    ref={ref}
    className={styles.tooltip}
    onClick={(e) => e.stopPropagation()}
    onMouseDown={(e) => e.stopPropagation()}
    onMouseUp={(e) => e.stopPropagation()}
    style={style}
  >
    <div className={styles.row}>
      <div className={styles.bold}>
        {t('contracts.new.progress_bar.installment', {
          amount: indicatorIndex + 1,
        })}
      </div>
      <div className={styles.bold}>{format(indicatorInstallment.dueDate, DATE_FORMAT_UNICODE)}</div>
    </div>
    <div className={styles.row}>
      <div>{t('contracts.new.progress_bar.repaymentAmount')}</div>
      <div className={styles.bold}>{formatMoney(indicatorInstallment.repaymentAmount)}</div>
    </div>
    <div className={cx(styles.row, styles.interestRow)}>
      <div>{t('contracts.new.progress_bar.interestRate')}</div>
      <div className={styles.bold}>{formatMoney(indicatorInstallment.interestAmount)}</div>
    </div>
    <div className={styles.row}>
      <div>{t('contracts.new.progress_bar.installmentAmount')}</div>
      <div className={styles.bold}>{formatMoney(indicatorInstallment.installmentAmount)}</div>
    </div>
  </div>
);

export default forwardRef<HTMLDivElement, TooltipProps>(Tooltip);
