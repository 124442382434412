import React, { InputHTMLAttributes, ReactNode } from 'react';

import {
  Container,
  Error,
  IconSecure,
  IconSecureWrapper,
  IconWrapper,
  Input,
  InputWrapper,
  Wrapper,
} from './InputField.styled';

export type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: string;
  isLocked?: boolean;
  icon?: ReactNode | string;
  iconPosition?: 'left' | 'right';
  alignText?: 'left' | 'right';
  dataId?: string;
};

export const InputField = ({
  disabled,
  icon,
  iconPosition = 'right',
  alignText = 'left',
  error,
  isLocked,
  name,
  dataId,
  ...rest
}: InputFieldProps) => {
  const hasError = !!error;

  return (
    <Wrapper>
      <Container>
        <InputWrapper $inactive={disabled} $hasError={hasError}>
          <Input
            id={name}
            name={name}
            disabled={disabled}
            $inactive={disabled}
            $hasError={hasError}
            $alignText={alignText}
            data-id={dataId}
            {...rest}
          />
          {icon && (
            <IconWrapper $inactive={disabled} $iconPosition={iconPosition}>
              {icon}
            </IconWrapper>
          )}
        </InputWrapper>
        {isLocked && disabled && (
          <IconSecureWrapper>
            <IconSecure />
          </IconSecureWrapper>
        )}
      </Container>
      {hasError && <Error>{error}</Error>}
    </Wrapper>
  );
};

export default InputField;
