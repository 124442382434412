import { find, get } from 'lodash';
import { combineReducers } from 'redux';

import {
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SORT,
  INDEX_SUCCESS,
  NAMESPACE,
} from 'constants/proposals';
import { mapClient } from 'reducers/clients';
import filters, { parsedFiltersReducer } from 'reducers/common/filters';
import { getPaginationReducer } from 'reducers/pagination';
import { createFilteredReducer } from 'shared/utils';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case INDEX_REQUEST:
      return true;
    case INDEX_SUCCESS:
    case INDEX_FAILURE:
      return false;
    default:
      return state;
  }
};

const getRelationship = (data, included, type, mapper) => {
  const relationshipData = data.relationships[type];
  if (!relationshipData || !relationshipData.data) return undefined;
  const relationshipId = relationshipData.data.id;
  const relationshipType = relationshipData.data.type;
  const relationship = find(
    included,
    (obj) => relationshipType === obj.type && relationshipId === obj.id
  );
  if (relationship) return mapper(relationship);
  return undefined;
};

const mapProposalWithRelationship = (included) => (data) => ({
  ...data.attributes,
  id: data.id,
  meta: data.meta || null,
  relationships: {
    client: getRelationship(data, included, 'client', mapClient),
  },
});

const data = (state = [], action) => {
  switch (action.type) {
    case INDEX_SUCCESS: {
      const { response } = action;
      return response.data.map(mapProposalWithRelationship(response.included));
    }
    default:
      return state;
  }
};

export const meta = (state = null, action) => {
  switch (action.type) {
    case INDEX_SUCCESS:
      return get(action, 'response.meta', null);
    default:
      return state;
  }
};

const pagination = getPaginationReducer(INDEX_SUCCESS);

const defaultSortingState = {
  column: 'proposal_number',
  direction: 'DESC',
};

export const sorting = (state = defaultSortingState, action) => {
  switch (action.type) {
    case INDEX_SORT: {
      let direction = 'ASC';
      if (state.column === action.column && state.direction === 'ASC') direction = 'DESC';
      return {
        column: action.column,
        direction,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  data,
  filters: createFilteredReducer(filters, (action) => action.name === NAMESPACE),
  parsedFilters: createFilteredReducer(parsedFiltersReducer, (action) => action.name === NAMESPACE),
  meta,
  isFetching,
  pagination,
  sorting,
});
