import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import { arrayOf, bool, func, oneOf, shape, string } from 'prop-types';
import { Field } from 'redux-form';

import {
  reorderLineItems as reorderLineItemsAction,
  toggleIncomingInvoiceGrossNet,
} from 'actions/incoming-invoice';
import { toggleDisableAction } from 'actions/incoming-invoice';
import { deleteAllLineItems as deleteAllLineItemsAC } from 'actions/incoming-invoice/line-items';
import { EDIT_MODE, IMPORT_MODE, NEW_MODE } from 'constants/common/crud';
import { CREATION_TYPE } from 'constants/incoming-invoice';
import { incomingInvoiceHasLineItems } from 'reducers';
import { companySmallEntrepreneurSelector } from 'selectors/company';
import { incomingInvoiceDetailsSelector } from 'selectors/incomingInvoice';
import { t } from 'shared/utils';
import { AddLineItemButton } from 'components/AddLineItemButton/AddLineItemButton';
import Card from 'components/Card';
import I18n from 'components/I18n';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import { IGNORE_OUTSIDE_CLICK_CLASS, LineItemsOrderManager } from 'components/LineItems';
import NetGrossField from 'components/v2/Form/NetGrossField/NetGrossField';

import Table from './Table';

import styles from './InvoiceDetailsSection.module.css';

class InvoiceDetailsSection extends Component {
  state = {
    newItemsId: 0,
    newItems: [],
  };

  componentDidMount() {
    const { readonly, crudMode, hasLineItems } = this.props;
    const hasProperCrudMode = [NEW_MODE, IMPORT_MODE, EDIT_MODE].includes(crudMode);
    if (!readonly && hasProperCrudMode && !hasLineItems) this.queueItem();

    this.setInitialItemsBasedOnOCR();
  }

  componentDidUpdate(
    { hasLineItems: hadLineItems, ocrItems: prevOcrItems },
    { newItems: prevNewItems }
  ) {
    const { hasLineItems, ocrItems } = this.props;
    const { newItems } = this.state;

    if (isEmpty(newItems) && !hasLineItems && (hadLineItems || !isEmpty(prevNewItems))) {
      this.queueItem();
    }

    if (ocrItems.length && ocrItems.length > prevOcrItems.length) {
      this.processOCRItems(ocrItems);
    }
  }

  processOCRItems = (ocrItems) => {
    this.deleteSavedItems();
    this.queueOcrItems(ocrItems);
  };

  setInitialItemsBasedOnOCR = () => {
    const { ocrItems, creationType, isDraft } = this.props;
    const shouldProcessItems = creationType === CREATION_TYPE.UPLOAD && isDraft && ocrItems.length;

    if (shouldProcessItems) {
      this.processOCRItems(ocrItems);
    }
  };

  queueOcrItems = (items = []) => {
    const { resetNewLineItemCreators } = this.props;
    resetNewLineItemCreators();
    this.setState({ newItems: [] }, () => items.map((item) => this.queueItem(item)));
  };

  queueItem = ({ prefilled = false, ...item } = {}) => {
    this.setState((prevState) => ({
      newItemsId: prevState.newItemsId + 1,
      newItems: [
        ...this.state.newItems,
        {
          id: prevState.newItemsId,
          rowId: prevState.newItemsId,
          ...item,
          prefilled,
        },
      ],
    }));
  };

  removeItem = (id) => () => {
    const toRemoveIndex = this.state.newItems.findIndex((a) => a.rowId === id);

    this.setState({
      newItems: [
        ...this.state.newItems.slice(0, toRemoveIndex),
        ...this.state.newItems.slice(toRemoveIndex + 1),
      ],
    });
  };

  deleteSavedItems = () => {
    const { invoiceId, deleteAllLineItems, resetEditLineItemCreators } = this.props;
    deleteAllLineItems(invoiceId);
    resetEditLineItemCreators();
  };

  render() {
    const {
      readonly,
      toggleGrossNetMode,
      invoiceId,
      hasLineItems,
      status,
      lineItems,
      reorderLineItems,
      crudMode,
      disabledButton,
      handleSelectCategory,
      defaultCategory,
      selectedCategory,
      category,
      taxRateId,
      taxRates,
      defaultVat,
      isInitial,
      setLineItemState,
      lineCategories,
      oldLineCategories,
      smallEntrepreneur,
    } = this.props;
    const netGrossSwitchDisabled = readonly || hasLineItems;

    return (
      <Card className={cx(styles.main, { [styles.readonly]: readonly })}>
        <Card.Header className={styles.heading}>
          <div className={styles.headingNameContainer}>
            <I18n className={styles.title} t="expenses.form.details_section.heading" />
            <div className={styles.fieldContainer}>
              <Field
                name="insertedAsGross"
                component={NetGrossField}
                disabled={netGrossSwitchDisabled || smallEntrepreneur}
                className={cx(styles.grossNetSwitch, IGNORE_OUTSIDE_CLICK_CLASS)}
                onChange={() => {
                  toggleGrossNetMode(invoiceId);
                }}
              />
              {netGrossSwitchDisabled && (
                <InfoIcon
                  tooltipPlacement="top"
                  message={t('forms.tooltips.net_gross_switch_disabled')}
                />
              )}
              {smallEntrepreneur && (
                <InfoIcon
                  tooltipPlacement="top"
                  message={t('forms.tooltips.net_gross_switch_warning_for_small_entrepreneur')}
                />
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body className={styles.cardBody}>
          <LineItemsOrderManager
            invoiceId={invoiceId}
            lineItems={lineItems}
            updateLineItemsOrder={reorderLineItems}
          >
            <Table
              newItems={this.state.newItems}
              lineItems={lineItems}
              removeNewItem={this.removeItem}
              readonly={readonly}
              status={status}
              crudMode={crudMode}
              handleSelectCategory={handleSelectCategory}
              defaultCategory={defaultCategory}
              selectedCategory={selectedCategory}
              category={category}
              taxRateId={taxRateId}
              taxRates={taxRates}
              defaultVat={defaultVat}
              isInitial={isInitial}
              setLineItemState={setLineItemState}
              lineCategories={lineCategories}
              oldLineCategories={oldLineCategories}
            />
          </LineItemsOrderManager>
          <div className={styles.actionsContainer}>
            {!readonly && (
              <AddLineItemButton onClick={() => this.queueItem()} disabled={disabledButton} />
            )}
          </div>
        </Card.Body>
      </Card>
    );
  }
}

InvoiceDetailsSection.propTypes = {
  readonly: bool,
  reorderLineItems: func.isRequired,
  ocrItems: arrayOf(shape({})),
  invoiceId: string.isRequired,
  deleteAllLineItems: func.isRequired,
  toggleGrossNetMode: func,
  resetNewLineItemCreators: func.isRequired,
  resetEditLineItemCreators: func.isRequired,
  hasLineItems: bool,
  status: string,
  crudMode: string,
  lineItems: arrayOf(shape({})),
  creationType: oneOf([CREATION_TYPE.MANUAL, CREATION_TYPE.UPLOAD]),
  isDraft: bool,
  handleDisableSaveButtonOnLineItemPost: func,
  disabledButton: bool,
};

InvoiceDetailsSection.defaultProps = {
  ocrItems: [],
};

const createOcrLineItems = ({ amountToPay = 0, vat = 0 } = {}) => {
  const netAmount = round((amountToPay / (100 + vat)) * 100, 2);
  return amountToPay
    ? [
        {
          position: t('expenses.form.details_section.fields.default_name'),
          netAmount,
          grossAmount: amountToPay,
          vat,
          insertedAsGross: false,
          prefilled: true,
          ocrPrefilled: true,
        },
      ]
    : [];
};

const getLineCategory = (state, invoice) => {
  const found = state.incomingInvoice.lineCategories.find(
    (c) => parseInt(c.id, 10) === invoice.invoiceLineCategoryId
  );

  if (!found) return '';
  return found.name;
};

const mapStateToProps = (state) => {
  const invoice = incomingInvoiceDetailsSelector(state);

  return {
    ocrItems: createOcrLineItems(state.incomingInvoice.ocr.processedData),
    invoiceId: invoice.id,
    disabledButton: state.incomingInvoice.details.disabled,
    hasLineItems: incomingInvoiceHasLineItems(state),
    lineItems: state.incomingInvoice.lineItems.map((i) => ({
      ...i,
      lineCategory: getLineCategory(state, i),
    })),
    oldLineCategories: state.incomingInvoice.oldLineCategories,
    creationType: invoice.creationType,
    isDraft: invoice.draft,
    smallEntrepreneur: companySmallEntrepreneurSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleGrossNetMode: (id) => dispatch(toggleIncomingInvoiceGrossNet(id)),
  deleteAllLineItems: (...args) => dispatch(deleteAllLineItemsAC(...args)),
  reorderLineItems: (...args) => dispatch(reorderLineItemsAction(...args)),
  handleDisableButton: (value) => dispatch(toggleDisableAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetailsSection);
