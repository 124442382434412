import React from 'react';
import { bool, string } from 'prop-types';

import electronicInvoicePDF from 'images/electronic-invoice-pdf.svg';
import regularInvoicePDF from 'images/regular-invoice-pdf.svg';
import { t } from 'shared/utils';
import Card from 'components/Card';

import styles from './ElectronicInvoiceInfo.module.css';

const ElectronicInvoiceInfo = ({ paymentPlan, eInvoicesEnabled, electronicInvoiceType = null }) => {
  const typeOfInvoice = electronicInvoiceType ? 'electronic' : 'regular';
  const electronicInvoiceInfoMessage = eInvoicesEnabled
    ? t(`revenue.form.electronic_invoice_info_hint`, { payment_plan: paymentPlan })
    : t(`revenue.form.regular_invoice_info_hint`);

  return (
    <Card className={styles.root}>
      <div className={styles.labelGroup}>
        <label htmlFor="electronicInvoiceInfo">
          {t(`revenue.form.${typeOfInvoice}_invoice_info`)}
        </label>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.imageWrapper}>
          <img
            alt={t('expenses.upload.previous_file')}
            src={electronicInvoiceType ? electronicInvoicePDF : regularInvoicePDF}
          />
        </div>
        <div className={styles.noteWrapper}>
          <p className={styles.note}>{electronicInvoiceInfoMessage}</p>
          <a
            href="https://www.vr-smart-guide.de/e-rechnung"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.infoLink}
          >
            {t('revenue.form.electronic_invoice_info_link')}
          </a>
        </div>
      </div>
    </Card>
  );
};

ElectronicInvoiceInfo.propTypes = {
  paymentPlan: string.isRequired,
  eInvoicesEnabled: bool.isRequired,
  electronicInvoiceType: string,
};

export default ElectronicInvoiceInfo;
