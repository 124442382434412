import React, { useCallback, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useClickAway } from 'react-use';

import IconArrowDown from 'redesign/components/atoms/Icons/ArrowDown';
import IconCalendar from 'redesign/components/atoms/Icons/Calendar';

import { MAX_PAGE, MIN_PAGE, MIN_YEAR, PER_PAGE } from './constants';
import { getPage, getYears } from './utils';
import {
  Arrows,
  Field,
  Menu,
  NextButton,
  PreviousButton,
  Wrapper,
  Year,
  Years,
} from './YearPicker.styled';

export type YearPickerProps = {
  value: number;
  onChange?: (year: number) => void;
  onOpen?: () => void;
  className?: string;
};

const YearPicker = ({ value, onChange, onOpen, className }: YearPickerProps) => {
  const currentYear = new Date().getFullYear();
  const [page, setPage] = useState(getPage(value, currentYear, PER_PAGE));
  const referenceElement = useRef<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { styles, attributes } = usePopper(referenceElement.current, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const handleFieldClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleYearClick = useCallback(
    (year: number) => () => {
      if (value !== year && onChange) onChange(year);
      setIsOpen(false);
    },
    [value, onChange]
  );

  const handlePreviousPage = useCallback(() => setPage((page) => page + 1), []);

  const handleNextPage = useCallback(() => setPage((page) => page - 1), []);

  useClickAway(referenceElement, () => {
    setIsOpen(false);
    setPage(getPage(value, currentYear, PER_PAGE));
  });

  useEffect(() => {
    isOpen && onOpen && onOpen();
  }, [isOpen, onOpen]);

  return (
    <Wrapper ref={referenceElement} className={className}>
      <Field onClick={handleFieldClick} $isActive={isOpen}>
        <IconCalendar /> {value}
      </Field>
      {isOpen && (
        <Menu ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <Arrows>
            <PreviousButton type="button" onClick={handlePreviousPage} disabled={page >= MAX_PAGE}>
              <IconArrowDown />
            </PreviousButton>
            <NextButton type="button" onClick={handleNextPage} disabled={page <= MIN_PAGE}>
              <IconArrowDown />
            </NextButton>
          </Arrows>
          <Years>
            {getYears(page, currentYear, PER_PAGE).map(
              (year: number) =>
                year >= MIN_YEAR && (
                  <Year key={year} $isActive={year === value} onClick={handleYearClick(year)}>
                    {year}
                  </Year>
                )
            )}
          </Years>
        </Menu>
      )}
    </Wrapper>
  );
};

export default YearPicker;
