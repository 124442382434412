import { Client } from './Client';
import { Installment } from './Installment';
import { InvoiceLineCategory } from './InvoiceLineCategory';
import { RecurringTransactionInterval } from './RecurringTransactionInterval';
import { Supplier } from './Supplier';

export enum ContractSource {
  Vrso = 'vrso',
  Pbw = 'pbw',
}

export enum ExpenseContractType {
  loan = 'loan',
  basic = 'basic',
}

export type VrsoInstallment = {
  dueDate: Date;
  totalPaid: number;
  totalOwed: number;
  interestAmount: number;
  repaymentAmount: number;
  installmentAmount: number;
};

export type Contract = {
  id: string;
  subject: string;
  occurrences: number;
  totalAmount: number;
  contractNumber: number;
  firstTransaction: string;
  transient: boolean;
  source: ContractSource;
  vrsoInstallments: VrsoInstallment[];
  recurringTransactionInterval: RecurringTransactionInterval;
  upcomingInstallment: Installment;
  hasConnectedBankTransfers: boolean;
  finishedAt: string;
  transactionEndDate: string;
  invoiceLineCategory: InvoiceLineCategory;
};

export type ExpenseContract = Contract & {
  contractType: ExpenseContractType;
  supplier: Supplier;
  isRevenue: false;
};

export type RevenueContract = Contract & {
  contractType: 'basic';
  client: Client;
  isRevenue: true;
};

export enum ContractTemplates {
  ExpensesLoan = 'expenses_loan',
  ExpensesBasic = 'expenses_basic',
  RevenuesBasic = 'revenues_basic',
}
