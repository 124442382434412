import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import normalize from 'json-api-normalizer';
import { snakeCase } from 'lodash';
import build from 'redux-object';

import * as Api from 'api/me/paginationPreferences';
import EntityPath from 'constants/entitiesPaths';
import { AppThunk } from 'store';
import { PaginationPreferences } from 'types/entities/PaginationPreferences';

type PaginationPreferencesState = {
  isLoading: boolean;
  error: string | null;
  data: PaginationPreferences | null;
};

const initialState: PaginationPreferencesState = {
  isLoading: false,
  error: null,
  data: null,
};

const slice = createSlice({
  name: 'paginationPreferences',
  initialState,
  reducers: {
    setStart(state) {
      state.isLoading = true;
    },
    setSuccess(state, { payload }: PayloadAction<PaginationPreferences>) {
      state.data = payload;
      state.isLoading = false;
    },
    setFailure(state) {
      state.isLoading = true;
    },
    setPaginationPreference(
      { data },
      {
        payload: { resource, pageSize },
      }: PayloadAction<{ resource: keyof PaginationPreferences; pageSize: number }>
    ) {
      if (!data) return;
      data[resource] = pageSize;
    },
  },
});

export default slice.reducer;

export const getPaginationPreferences = (): AppThunk => async (dispatch) => {
  const { setStart, setSuccess, setFailure } = slice.actions;
  let response;

  dispatch(setStart());

  try {
    response = await Api.getPaginationPreferences();
  } catch (error) {
    dispatch(setFailure());
    return;
  }

  const [paginationPreferences] = build<PaginationPreferences>(
    normalize(response.data),
    EntityPath.PaginationPreferences
  );

  dispatch(setSuccess(paginationPreferences));
};

export const setPaginationPreference =
  ({ resource, pageSize }: { resource: keyof PaginationPreferences; pageSize: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setPaginationPreference } = slice.actions;
    const state = getState();
    const currentPageSize = state.paginationPreferences.data?.[resource];

    if (pageSize === currentPageSize) return;

    dispatch(setPaginationPreference({ resource, pageSize }));
    await Api.updatePaginationPreference({ [snakeCase(resource)]: pageSize });
  };
