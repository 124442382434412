// Dont send any discount data if discount checkbox is not checked
export default (data) => {
  if (data.discountGrant) return data;

  return {
    ...data,
    discountPeriod: '',
    discountPercentage: null,
  };
};
