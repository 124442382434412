import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';

import { productCatalogItemsEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import IconContacts from 'redesign/components/atoms/Icons/Contacts';

import { trackEvent, trackEventHandler } from '../utils';
import { Arrow, Link, MenuItem } from './Shared.styled';
import { getMatches } from './utils';

type AdministrationProps = {
  pathname: string;
  isNavOpen: boolean;
};

const URLS = {
  clients: paths.clients,
  suppliers: paths.suppliers,
  productCatalogItems: paths.productCatalogItems,
};

const Administration = ({ pathname, isNavOpen }: AdministrationProps) => {
  const matches = getMatches(pathname, URLS);
  const isActive = Object.values(matches).some((match) => match);
  const [isOpen, setOpen] = useState(isActive);
  const areProductCatalogItemsEnabled = useSelector(productCatalogItemsEnabledHelper);

  const handleToggle = useCallback(() => {
    trackEvent(`administration_${!isOpen ? 'open' : 'close'}`);
    setOpen((isOpen) => !isOpen);
  }, [isOpen]);

  useUpdateEffect(() => {
    setOpen(isActive);
  }, [pathname, isNavOpen]);

  return (
    <MenuItem $isActive={isActive} $isOpen={isOpen}>
      <Link
        $level={1}
        as="span"
        $isActive={isActive}
        onClick={handleToggle}
        data-id="administration"
      >
        <IconContacts />
        {t('redesign.organisms.navigation.administration')}
        <Arrow $isOpen={isOpen} />
      </Link>

      <ul>
        <li>
          <Link
            $level={2}
            to={paths.clients}
            $isActive={matches.clients}
            data-id="clients"
            onClick={trackEventHandler('administration_clients')}
          >
            {t('redesign.organisms.navigation.clients')}
          </Link>
        </li>
        <li>
          <Link
            $level={2}
            to={paths.suppliers}
            $isActive={matches.suppliers}
            data-id="suppliers"
            onClick={trackEventHandler('administration_suppliers')}
          >
            {t('redesign.organisms.navigation.suppliers')}
          </Link>
        </li>
        {areProductCatalogItemsEnabled && (
          <li>
            <Link
              $level={2}
              to={paths.productCatalogItems}
              $isActive={matches.productCatalogItems}
              data-id="product-catalog-items"
              onClick={trackEventHandler('administration_product-catalouge')}
            >
              {t('redesign.organisms.navigation.product_catalog_items')}
            </Link>
          </li>
        )}
      </ul>
    </MenuItem>
  );
};

export default memo(Administration);
