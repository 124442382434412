import styled, { css } from 'styled-components';

import media from 'shared/styles/breakpoints';

export const Form = styled.form`
  width: 100%;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: flex-end;
  flex-direction: column;

  ${media.greaterThan('sm-up')`
    flex-direction: row;
  `}
`;

const OutlinedButton = css`
  color: ${({ theme }) => theme.legacy.colors.brandBlue};
`;

const PrimaryButton = css`
  color: #fff;
  background-color: ${({ theme }) => theme.legacy.colors.brandBlue};
`;

type ButtonProps = {
  $appearance?: 'outline';
};

export const Button = styled.button<ButtonProps>`
  padding: 10px;
  font-weight: 600;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.legacy.colors.brandBlue};
  background-color: ${({ theme }) => theme.legacy.colors.white};
  ${(props) => (props.$appearance === 'outline' ? OutlinedButton : PrimaryButton)}

  &:disabled {
    opacity: 0.6;
  }

  ${media.greaterThan('sm-up')`
    padding: 4px 15px;
    margin-bottom: 0px;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  `}
`;
