import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import noop from 'lodash/noop';
import { boolean, func, shape, string } from 'prop-types';
import { Field } from 'redux-form';

import { quickCreateClient } from 'actions/outgoing-invoice';
import { FORM_NAME } from 'constants/outgoing-invoice';
import { getCountryNameByCode } from 'reducers';
import { outgoingInvoiceSelector } from 'reducers/form';
import { withCountries } from 'shared/hoc';
import { t } from 'shared/utils';
import ClientField from 'components/ClientField/ClientField';
import If from 'components/Conditions/If';

import { AddNew, ClientRow } from './Client.styled';
import ClientQuickCreator from './ClientQuickCreator';

import styles from './Client.module.css';

class ClientSection extends Component {
  state = {
    view: 'dropdown', // or form
  };

  switchView = (view) => () => {
    this.setState({ view });
  };

  renderForm = () => {
    const onCancel = this.switchView('dropdown');
    const submitHandler = this.props.submitHandler || this.props.onSubmitClient;
    const onSubmit = (values) => submitHandler(values).then(onCancel);

    return <ClientQuickCreator onSubmit={onSubmit} onCancel={onCancel} />;
  };

  renderDropdown = () => {
    const {
      client,
      label = t('revenue.form.client'),
      placeholder = t('revenue.form.client_placeholder'),
      readonly,
      getCountry,
      onChange = noop,
    } = this.props;

    return (
      <div className={client ? styles.mainWithBg : styles.main}>
        <ClientRow>
          <If ok={!readonly}>
            <Field
              name="client"
              label={label}
              component={ClientField}
              emptyText={t('revenue.form.client_select.no_results')}
              onChange={onChange}
              placeholder={placeholder}
              required
            />
          </If>
          {!client && (
            <AddNew onClick={this.switchView('form')} data-id="add-new">
              {t('revenue.form.client_select.add_new')}
            </AddNew>
          )}
        </ClientRow>
        {client && (
          <div className={styles.clientDetails}>
            <p>{client.companyName}</p>
            <p>
              {client.firstName} {client.lastName}
            </p>
            <p>{client.idNumber}</p>
            <p>{client.vatId}</p>
            <p>{client.street}</p>
            <p>
              {client.zipcode} {client.city}
            </p>
            <p>{getCountry(client.country)}</p>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { view } = this.state;

    return (
      <div className={cx(styles.formGroup, styles.required)}>
        {view === 'form' ? this.renderForm() : this.renderDropdown()}
      </div>
    );
  }
}

const clientShape = {
  id: string.isRequired,
  companyName: string.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  idNumber: string.isRequired,
  street: string.isRequired,
  zipcode: string.isRequired,
  city: string.isRequired,
  country: string,
};

ClientSection.propTypes = {
  client: shape(clientShape),
  readonly: boolean,
  submitHandler: func,
  onSubmitClient: func.isRequired,
  getCountry: func.isRequired,
  label: string,
  onChange: func,
  placeholder: string,
};

const mapStateToProps = (state, ownProps) => ({
  client: ownProps.client || outgoingInvoiceSelector(state, 'client'),
  getCountry: getCountryNameByCode(state),
  readonly: ownProps.readonly || state.outgoingInvoice.details.amendmentType === 'correction',
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmitClient:
    ownProps.onSubmitClient || ((values) => quickCreateClient(FORM_NAME)(values)(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withCountries(ClientSection));
