import React from 'react';
import { Col } from 'react-flexbox-grid';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { bool, node, string } from 'prop-types';

import styles from './TableHeaderCell.module.css';

export const TableHeaderCell = ({
  title,
  className,
  customWidth = false,
  alignRight = false,
  children = [],
}) => {
  const child = isEmpty(children) ? title : children;
  return (
    <Col
      className={cx(styles.header, className, {
        [styles.customWidth]: customWidth,
        [styles.alignRight]: alignRight,
      })}
    >
      {child}
    </Col>
  );
};

TableHeaderCell.propTypes = {
  className: string,
  title: string,
  customWidth: bool,
  alignRight: bool,
  children: node,
};
