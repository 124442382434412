import React, { SVGAttributes } from 'react';

const IconLightBulb = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    id="icon_suggestion_svg__Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 14.8"
    {...props}
  >
    <defs>
      <style>{'.icon_suggestion_svg__cls-1{fill:#fafafa}'}</style>
    </defs>
    <path
      className="icon_suggestion_svg__cls-1"
      d="M14.1 2.7l-1-1L11.8 3l1 1 1.3-1.3zM7.3 0h1.5v2.1H7.3zM13.8 7.1H16v1.4h-2.2z"
    />
    <path
      className="icon_suggestion_svg__cls-1"
      d="M5.89 14.92h4.4v-3.4a4.21 4.21 0 002.2-3.7 4.35 4.35 0 00-4.4-4.3 4.42 4.42 0 00-4.4 4.3 4.21 4.21 0 002.2 3.7zm1.5-1.4v-2.8l-.7-.4a3 3 0 01-1.5-2.5A2.82 2.82 0 018.09 5 2.88 2.88 0 0111 7.82a3.08 3.08 0 01-1.5 2.5l-.7.4v2.8z"
      transform="translate(-.09 -.12)"
    />
    <path
      className="icon_suggestion_svg__cls-1"
      d="M0 7.1h2.2v1.4H0zM4.2 3L2.9 1.7l-1 1L3.2 4l1-1z"
    />
  </svg>
);

export default IconLightBulb;
