import React, { useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { flow } from 'lodash';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { resetClientDefaults, updateClientDefaults } from 'actions/default-values';
import { getProposal, GetProposalData } from 'api/me/previews/proposal';
import { ClientDocumentsFormName } from 'containers/Clients/ClientDocumentDefaults/ClientDocumentDefaults';
import { FormRow } from 'containers/Profile/InvoiceTemplate/DefaultValues/sharedComponents';
import DefaultValuesFormWithPreview from 'containers/Profile/InvoiceTemplate/DefaultValues/sharedComponents/DefaultValuesForm/DefaultValuesFormWithPreview';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { RootState } from 'store';
import { DefaultValues } from 'types/entities/DefaultValues';
import { SelectField, TextField } from 'components/Form';
import InputsGroup from 'components/InputsGroup/InputsGroup';
import HtmlField from 'components/v2/Form/HtmlField/HtmlField';

import styles from './ProposalDefaults.module.css';

const VALID_UNTIL_OPTIONS = [7, 14, 21, 30].map((value) => ({
  value,
  label: t('invoice_templates.documents_defaults.proposal_valid_until_option', { count: value }),
}));

export const FORM_NAME: ClientDocumentsFormName = 'clientProposalDefaults';

type FormData = Pick<
  DefaultValues,
  | 'proposalValidUntil'
  | 'proposalSubject'
  | 'proposalSalutation'
  | 'proposalSalutationContent'
  | 'proposalFooter'
>;

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    proposalValidUntil: state.defaultValues.values.proposalValidUntil,
    proposalSubject: state.defaultValues.values.proposalSubject,
    proposalSalutation: state.defaultValues.values.proposalSalutation,
    proposalSalutationContent: state.defaultValues.values.proposalSalutationContent,
    proposalFooter: state.defaultValues.values.proposalFooter,
  },
  formValues: {
    proposalSalutation: selector(state, 'proposalSalutation'),
    proposalSalutationContent: selector(state, 'proposalSalutationContent'),
    proposalFooter: selector(state, 'proposalFooter'),
  },
});

const mapDispatchToProps = {
  updateClientDefaults,
  resetClientDefaults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ProposalDefaultsProps = ConnectedProps<typeof connector> & {
  clientId: string;
};

const ProposalDefaults = ({
  resetClientDefaults,
  updateClientDefaults,
  handleSubmit,
  clientId,
  reset: resetForm,
  formValues: { proposalSalutation, proposalSalutationContent, proposalFooter },
}: ProposalDefaultsProps & InjectedFormProps<FormData, ProposalDefaultsProps>) => {
  const topFields = useMemo(
    () => (
      <>
        <FormRow>
          <Field
            dataId="ClientDocumentDefaults:input-proposal-valid-until"
            name="proposalValidUntil"
            component={SelectField}
            checker={serverValidationChecker}
            options={VALID_UNTIL_OPTIONS}
            placeholder={t('invoice_templates.documents_defaults.proposal_valid_until')}
            label={t('invoice_templates.documents_defaults.proposal_valid_until')}
          />
        </FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.discount_subject')}>
          <Field
            className={styles.grouppedInput}
            name="proposalSubject"
            checker={serverValidationChecker}
            component={TextField}
            dataId="ClientDocumentDefaults:input-proposal-subject"
          />
        </InputsGroup>
      </>
    ),
    []
  );

  const values = useMemo(
    () => ({
      salutation_honorific: proposalSalutation,
      salutation_content: proposalSalutationContent,
      personal_notes: proposalFooter,
    }),
    [proposalSalutation, proposalSalutationContent, proposalFooter]
  );

  return (
    <DefaultValuesFormWithPreview<GetProposalData>
      onSubmit={handleSubmit((values) => updateClientDefaults(clientId, values, 'form_proposal'))}
      onReset={async () => {
        await resetClientDefaults(clientId, 'form_proposal');
        resetForm();
      }}
      sectionName="form_proposal"
      sectionLabel={t('invoice_templates.documents_defaults.proposal_defaults_section')}
      dataIdPrefix="ClientDocumentDefaults:"
      fetchPreview={getProposal}
      values={values}
      topFields={topFields}
    >
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.header')}>
          <Field
            name="proposalSalutation"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation')}
            tooltip={t('invoice_templates.documents_defaults.salutation_info')}
            dataId="ClientDocumentDefaults:input-proposal-salutation"
          />
          <br />
          <Field
            name="proposalSalutationContent"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation_content')}
            dataId="ClientDocumentDefaults:input-proposal-salutation-content"
          />
        </InputsGroup>
      </FormRow>
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.footer')}>
          <Field
            name="proposalFooter"
            component={HtmlField}
            checker={serverValidationChecker}
            dataId="ClientDocumentDefaults:input-proposal-footer"
          />
        </InputsGroup>
      </FormRow>
    </DefaultValuesFormWithPreview>
  );
};

export default flow(
  withTransitionPrevent(),
  reduxForm<FormData, ProposalDefaultsProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    persistentSubmitErrors: true,
  }),
  connector
)(ProposalDefaults);
