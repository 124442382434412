import React, { memo, SVGProps } from 'react';

const SvgCalendarRound = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 29 28" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(.537)" fill="none" fillRule="evenodd">
      <ellipse fill="#BFD9EC" opacity={0.5} cx={13.859} cy={13.714} rx={13.859} ry={13.714} />
      <path
        d="M19.518 8.114h-.808v1.6h-2.426v-1.6h-4.85v1.6H9.008v-1.6H8.2c-.89 0-1.617.72-1.617 1.6v9.6c0 .88.727 1.6 1.617 1.6h11.318c.89 0 1.617-.72 1.617-1.6v-9.6c0-.88-.728-1.6-1.617-1.6zm0 11.2H8.2v-6.4h11.318v6.4zm-8.489-12.8H9.413v2.8h1.616v-2.8zm7.276 0H16.69v2.8h1.616v-2.8z"
        fill="#0487EA"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(SvgCalendarRound);
