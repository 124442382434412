import { CHANGE_GRECAPTCHA_RESPONSE, RESET_GRECAPTCHA } from 'constants/grecaptcha';

export const changeGrecaptchaResponse = (response) => ({
  response,
  type: CHANGE_GRECAPTCHA_RESPONSE,
});

export const resetGrecaptcha = () => ({
  type: RESET_GRECAPTCHA,
});

export default changeGrecaptchaResponse;
