import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
`;

export const Circle = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span ${Circle} {
    display: block;
  }

  &:checked ~ span:hover {
    border-color: ${({ theme }) => theme.colors.vrblue['075']};
  }

  &:checked ~ span:active {
    border-color: ${({ theme }) => theme.colors.vrblue['050']};
  }

  &:disabled ~ span ${Circle} {
    background-color: ${({ theme }) => theme.colors.grey['070']};
  }

  &:disabled ~ span {
    border-color: ${({ theme }) => theme.colors.grey['070']};
    cursor: not-allowed;
    box-shadow: none;
  }

  &:disabled ~ span:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:checked:disabled ~ span {
    background-color: ${({ theme }) => theme.colors.grey['040']};
    border-color: ${({ theme }) => theme.colors.grey['040']};
  }

  &:checked:disabled ~ span:hover {
    background-color: ${({ theme }) => theme.colors.grey['040']};
  }
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 2px solid ${({ theme }) => theme.colors.vrblue['100']};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 1px 1px 2px 0 rgba(0, 102, 179, 0.3);
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 3px 3px 5px 0 rgba(0, 102, 179, 0.3);
  }

  ${Circle} {
    display: none;
    background-color: ${({ theme }) => theme.colors.vrblue['100']};
  }
`;
