import { FETCH_SUCCESS, VALIDATE_SUCCESS } from 'constants/number-ranges';

const defaultState = {
  initial: {},
  error: false,
  isFetching: false,
};

/*
    Since we're reinitializing every time the async response arrives,
    we need to handle dirty/pristine states manually, because a shallow
    compare from redux-form will always return isPristine: true

    For implementation check number-ranges reducers
  */

export default (state = defaultState, action) => {
  switch (action.type) {
    case VALIDATE_SUCCESS:
    case FETCH_SUCCESS: {
      const data = action.response.data.attributes;
      return {
        ...state,
        initial: { ...state.inital, ...data },
      };
    }
    default:
      return state;
  }
};
