import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import cx from 'classnames';

import { fetchPrefilledRegistrationData, toggleDisclaimer } from 'actions/registration';
import { disclaimer, termsAndConditions } from 'constants/legals';
import ReceiveOffersCard from 'containers/ReceiveOffers';
import DisclaimerModal from 'containers/RegistrationForm/Form/DisclaimerModal/DisclaimerModal';
import TermsOfUseCard from 'containers/RegistrationForm/Form/TermsOfUse/TermsOfUse';
import { getPrefilledRegistrationData } from 'reducers/registration';
import { contentBySlug } from 'shared/utils/contentBySlug';
import { RootState } from 'store';

import RegistrationCard from './RegistrationCard';
import { getBlzCode, getEmail } from './utils';

import formPageStyles from '../Shared.module.css';
import localStyles from './Registration.module.css';

const styles = { ...formPageStyles, ...localStyles };

type RegistrationStateProps = {
  termsOfUse: boolean;
  receiveOffers: boolean;
  disclaimerModal: boolean;
  contents: { attributes: { slug: string } }[];
  initialValues: any;
};

type RegistrationOwnProps = {
  isVrso: boolean;
};

type RegistrationProps = RegistrationStateProps & RegistrationOwnProps;

const Registration = ({
  contents,
  termsOfUse,
  receiveOffers,
  disclaimerModal,
  initialValues,
  isVrso,
}: RegistrationProps) => {
  const { token } = useParams<{ token: string }>();
  const location = useLocation();
  const dispatch = useDispatch();
  const { email } = getEmail({ location });
  const blz = getBlzCode({ location });
  const disclaimerContent = contentBySlug(contents, disclaimer);
  const termsAndConditionsContent = contentBySlug(contents, termsAndConditions);
  const { attributes: { title = '' } = {} } = disclaimerContent;
  const handleToggleDisclaimer = () => dispatch(toggleDisclaimer());

  useEffect(() => {
    dispatch(fetchPrefilledRegistrationData(blz));
  }, []);

  return (
    <div>
      <div
        className={cx({
          [styles.hidden]: termsOfUse || receiveOffers || disclaimerModal,
        })}
      >
        <RegistrationCard
          initialValues={{ ...initialValues, email, token }}
          disclaimerTitle={title}
          isVrso={isVrso}
        />
      </div>
      <div className={cx(styles.wrapper, { [styles.hidden]: !termsOfUse })}>
        <TermsOfUseCard {...termsAndConditionsContent.attributes} />
      </div>
      <div className={cx(styles.wrapper, { [styles.hidden]: !receiveOffers })}>
        <ReceiveOffersCard />
      </div>
      <div className={cx(styles.wrapper, { [styles.hidden]: !disclaimerModal })}>
        <DisclaimerModal
          {...disclaimerContent.attributes}
          handleOnAccept={handleToggleDisclaimer}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  ...state.registration,
  initialValues: { ...getPrefilledRegistrationData(state) },
  contents: state.contents.data,
});

export default connect<RegistrationStateProps, {}, RegistrationOwnProps, RootState>(
  mapStateToProps
)(Registration);
