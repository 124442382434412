import styled from 'styled-components';

type WrapperProps = {
  $isHighlighted?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  width: 100%;
  min-height: 44px;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: bold;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
  color: ${({ $isHighlighted }) => $isHighlighted && '#777'};

  h3 {
    font-size: 15px;
    margin: 0 0 ${({ theme }) => theme.space['3']} 0;
    font-weight: 700;
  }

  p {
    margin: 2px 0;
  }
`;
