import { createAsyncThunk } from '@reduxjs/toolkit';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import * as api from 'api/me/onboarding';
import EntityPath from 'constants/entitiesPaths';
import { RootState } from 'store';
import { Onboarding } from 'types/entities/Onboarding';

import { name } from './constants';

export const fetchOnboarding = createAsyncThunk<
  Onboarding,
  void,
  {
    state: RootState;
  }
>(
  `${name}/fetch`,
  async () => {
    const response = await api.getOnboarding();
    const onboarding = build<Onboarding>(
      normalize(response.data),
      EntityPath.Onboardings,
      response.data.data.id,
      {
        ignoreLinks: true,
      }
    );

    return onboarding;
  },
  {
    condition: (_, { getState }) => {
      const { onboarding } = getState();

      return !onboarding.isLoading;
    },
  }
);

export const updateOnboarding = createAsyncThunk<Onboarding, api.UpdateOnboardingData>(
  `${name}/update`,
  async (data: api.UpdateOnboardingData) => {
    const response = await api.updateOnboarding(data);
    const onboarding = build<Onboarding>(
      normalize(response.data),
      EntityPath.Onboardings,
      response.data.data.id
    );

    return onboarding;
  }
);
