export const _parseHHDUC = (sChallengeHHDUC: string, fHHD140: boolean) => {
  let nOffset = 0;
  let nLength = sChallengeHHDUC.length;
  let sTB = '';
  let nLuhn = 0;

  // Helper functions to extract portions of the challenge string.

  const __getString = (n: number) => {
    const s = sChallengeHHDUC.slice(nOffset, nOffset + n);
    nOffset += n;
    return s;
  };

  const __getHexByte = () => {
    return parseInt(__getString(2), 16);
  };

  const __getNumber = (nDigits: number) => {
    return Number(__getString(nDigits));
  };

  const __getDE = () => {
    if (nOffset + 2 > nLength) {
      return '';
    }
    const nLenDE = __getNumber(2);
    return __getString(nLenDE);
  };

  //

  const __mustEncodeZKA = (sText: string) => {
    for (let i = 0; sText && i < sText.length; ++i) {
      let ch = sText.charCodeAt(i);
      if (ch < 0x30 || ch > 0x39) {
        return true;
      }
    }
    return false;
  };

  const __addLuhn = (b: number) => {
    var nLow = (b & 0x0f) * 2;
    nLuhn += ((b & 0xf0) >> 4) + Math.floor(nLow / 10) + (nLow % 10);
  };

  // Encode the given text using the ISO 646 based ZKA encoding.
  // The encoding used is specified in the document "Secoder - Connected Mode Reader
  // Applications".

  const __encodeZKA = (sText: string) => {
    let sOut = '';

    for (let i = 0; sText && i < sText.length; ++i) {
      let ch = sText.charCodeAt(i);
      switch (ch) {
        case 0x20ac: // EURO
          ch = 0x24;
          break;
        case 0x00c4: // A umlaut
          ch = 0x5b;
          break;
        case 0x00d6: // O umlaut
          ch = 0x5c;
          break;
        case 0x00dc: // U umlaut
          ch = 0x5d;
          break;
        case 0x00a3: // British Pound
          ch = 0x5e;
          break;
        case 0x00e4: // a umlaut
          ch = 0x7b;
          break;
        case 0x00f6: // o umlaut
          ch = 0x7c;
          break;
        case 0x00fc: // u umlaut
          ch = 0x7d;
          break;
        case 0x00df: // sz ligature
          ch = 0x7e;
          break;
        default:
          ch = ch & 0x00ff;
          break;
      }

      __addLuhn(ch);
      sOut += String.fromCharCode(ch);
    }

    return sOut;
  };

  // Encode the given string of digits as packed BCD, padding with 0x0F if needed.

  const __encodeBCD = (sText: string) => {
    let sBCD = '';

    for (let i = 0; sText && i < sText.length; i += 2) {
      let nHi = sText.charCodeAt(i);
      let nLo = sText.charCodeAt(i + 1) || 0xff;
      let ch = ((nHi & 0x0f) << 4) + (nLo & 0x0f);

      __addLuhn(ch);
      sBCD += String.fromCharCode(ch);
    }

    return sBCD;
  };

  //

  const __append = (sText: string) => {
    if (!sText) {
      return;
    }

    var nLDE = 0;

    if (__mustEncodeZKA(sText)) {
      sText = __encodeZKA(sText);
      nLDE = fHHD140 ? 0x40 : 0x10;
    } else {
      sText = __encodeBCD(sText);
    }

    nLDE |= sText.length;

    if (!sTB && fHHD140) {
      sTB = String.fromCharCode(nLDE | 0x80, 0x01);
      __addLuhn(0x01);
    } else {
      sTB += String.fromCharCode(nLDE);
    }

    sTB += sText;
  };

  // First get the total length LC. Depending on the version of the HHD the length is either
  // two or three digits in length.

  const nLC = __getNumber(fHHD140 ? 3 : 2);
  if (nOffset + nLC !== sChallengeHHDUC.length) {
    // throw new ArgumentException("LC");
    return null;
  }

  // Parse StartCode, DE1, DE2 and DE3

  let sStartCode, sDE1, sDE2, sDE3;

  let nLenLS = fHHD140 ? __getHexByte() : __getNumber(2);
  if (nLenLS & 0x80) {
    nLenLS &= 0x7f;
    nOffset += 2;
  }

  sStartCode = __getString(nLenLS);
  sDE1 = __getDE();
  sDE2 = __getDE();
  sDE3 = __getDE();

  // Build the transmission block

  __append(sStartCode);
  __append(sDE1);
  __append(sDE2);
  __append(sDE3);

  // Prepend the final LC length byte.

  sTB = String.fromCharCode(sTB.length + 1) + sTB;

  // Final Luhn computation.

  nLuhn = 10 - (nLuhn % 10);
  if (nLuhn === 10) {
    nLuhn = 0;
  }

  // Compute XOR checksum.

  var nXOR = 0;
  for (var i = 0; i < sTB.length; ++i) {
    var b = sTB.charCodeAt(i);
    nXOR ^= (b & 0x0f) ^ ((b >> 4) & 0x0f);
  }

  // Append checksum.

  sTB += String.fromCharCode((nLuhn << 4) + nXOR);

  //

  return sTB;
};
