import React from 'react';
import { node, shape, string } from 'prop-types';

import ListHeader from './ListHeader';
import ListItem from './ListItem';

const Section = ({ headerTitle, icon, options }) => (
  <>
    <ListHeader title={headerTitle} icon={icon} />
    {options.map(({ title }) => (
      <ListItem key={title} title={title} />
    ))}
  </>
);

Section.propTypes = {
  icon: node,
  options: shape({
    title: string.isRequired,
  }),
  headerTitle: string.isRequired,
};

export default Section;
