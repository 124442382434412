import styled from 'styled-components';

import { visibleScrollbar } from 'shared/styles/Common.styled';
import IconInvoiceComponent from 'components/Icons/IconInvoice';
import Tooltip from 'components/Tooltip/Tooltip';

export const Box = styled.div`
  display: grid;
  grid-template-columns: 45px 180px 180px 85px 120px 90px;
  padding: ${({ theme }) => `${theme.space['2']} ${theme.space['5']}`};
  justify-items: flex-start;
  align-items: center;
  overflow-x: auto;
  border: ${({ theme }) => theme.space['px']} solid ${({ theme }) => theme.legacy.colors.greyFont};
  margin-bottom: ${({ theme }) => theme.space['4']};

  p {
    margin-bottom: ${({ theme }) => theme.space['0']};
    align-self: center;
  }

  ${visibleScrollbar}
`;

export const Party = styled.p`
  margin-left: ${({ theme }) => theme.space['5']};
  font-size: ${({ theme }) => theme.fontSize['sm']};
  font-weight: bold;
`;

export const Icon = styled.div`
  display: grid;
  place-items: center;
  color: ${({ theme }) => theme.legacy.colors.brandBlue};

  p {
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSize['xs']};
  }
`;

export const IconInvoice = styled(IconInvoiceComponent)`
  justify-self: center;
`;

export const DiscountWrapper = styled.div`
  align-self: center;
`;

export const Discount = styled.div`
  font-size: ${({ theme }) => theme.fontSize['xs']};
  margin-bottom: ${({ theme }) => theme.space['px']};
  color: ${({ theme }) => theme.legacy.colors.greyLighter};
`;

export const ContractTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
`;

export const ContractSubject = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 180px;
  display: block;
  overflow: hidden;
  font-size: ${({ theme }) => theme.fontSize['sm']};
`;

export const Date = styled.p`
  font-size: ${({ theme }) => theme.fontSize['sm']};
  padding-left: ${({ theme }) => theme.space['2']};
`;

export const Type = styled.p`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize['xs']};
  justify-self: end;
  text-align: end;
`;

type AmountProps = {
  isNegative?: boolean;
};

export const Amount = styled.p<AmountProps>`
  justify-self: end;
  font-size: ${({ theme }) => theme.fontSize['base']};
  font-weight: bold;
  color: ${({ theme, isNegative }) =>
    isNegative ? theme.legacy.colors.redFont : theme.legacy.colors.greenFont};
`;
