import styled from 'styled-components';

import { Breakpoints } from 'redesign/styles/breakpoints';

export type ButtonProps = {
  isOpen: boolean;
};

export const Button = styled.button<ButtonProps>`
  width: 20px;
  height: 20px;
  padding: 0;
  font: inherit;
  outline: inherit;
  border: none;
  color: inherit;
  background: ${({ theme, isOpen }) => (isOpen ? theme.legacy.colors.brandBlue : 'none')};
  cursor: pointer;

  svg {
    color: ${({ theme, isOpen }) =>
      isOpen ? theme.legacy.colors.white : theme.legacy.colors.brandBlue};
  }

  &:focus {
    background: ${({ theme }) => theme.legacy.colors.brandBlue};
  }

  &:focus svg {
    color: ${({ theme }) => theme.legacy.colors.white};
  }
`;

export const Tooltip = styled.div`
  position: relative;
  z-index: 10;
`;

export const Content = styled.div`
  width: 160px;
  padding: ${({ theme }) => `${theme.space['4']} ${theme.space['2']}`};
  background-color: ${({ theme }) => theme.legacy.colors.white};
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: ${({ theme }) => theme.fontSize.xs};
  line-height: 18px;
  @media (max-width: ${Breakpoints.mdMax}) {
    width: max-content;
    padding: ${({ theme }) => `${theme.space['3']} ${theme.space['2']}`};
    min-width: 160px;
    max-width: 360px;
    height: max-content;
    margin-bottom: 2px;
  }
`;

export const Arrow = styled.div`
  content: '';
  width: 13px;
  height: 13px;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  background: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
  box-shadow: -3px -3px 4px -1px rgba(0, 0, 0, 0.5);
  @media (max-width: ${Breakpoints.mdMax}) {
    top: 50px;
    bottom: -13px;
    right: -13px;
    left: unset;
    box-shadow: 2px -2px 4px -2px rgba(0, 0, 0, 0.5);
  }
`;
