import { replace } from 'connected-react-router';
import { get } from 'lodash';
import { change } from 'redux-form';

import { paginationParams, sortingParams } from 'actions/helpers/table';
import { fetchIncomingInvoice } from 'actions/incoming-invoice';
import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import {
  CLEAR_FILTERS,
  CLEAR_FILTERS_TO_INITIAL,
  SET_DATE_RANGE,
  SET_STATUS_FILTER,
} from 'constants/common/filters';
import {
  DELETE_ALL_BANK_TRANSFER_CONNECTIONS_FAILURE,
  DELETE_ALL_BANK_TRANSFER_CONNECTIONS_REQUEST,
  DELETE_ALL_BANK_TRANSFER_CONNECTIONS_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DUPLICATE_INVOICE_FAILURE,
  DUPLICATE_INVOICE_REQUEST,
  DUPLICATE_INVOICE_SUCCESS,
  FETCH_FAILURE_SUB_INVOICES,
  FETCH_REQUEST_SUB_INVOICES,
  FETCH_SUCCESS_SUB_INVOICES,
  FROM_DUPLICATE_QUERY_PARAM,
  INDEX_FAILURE,
  INDEX_MORE_FAILURE,
  INDEX_MORE_REQUEST,
  INDEX_MORE_SUCCESS,
  INDEX_REQUEST,
  INDEX_RESET,
  INDEX_SORT,
  INDEX_SUCCESS,
  NAMESPACE,
  PAGINATION,
  SEARCH_FAILURE,
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  TOGGLE_CREDIT_NOTE_FAILURE,
  TOGGLE_CREDIT_NOTE_REQUEST,
  TOGGLE_CREDIT_NOTE_SUCCESS,
  TOGGLE_PAID_BY_CASH_FAILURE,
  TOGGLE_PAID_BY_CASH_REQUEST,
  TOGGLE_PAID_BY_CASH_SUCCESS,
  TOGGLE_PAID_BY_UNSPECIFIED_FAILURE,
  TOGGLE_PAID_BY_UNSPECIFIED_REQUEST,
  TOGGLE_PAID_BY_UNSPECIFIED_SUCCESS,
} from 'constants/incoming-invoices';
import { Resources } from 'constants/resources';
import {
  tickPaidByCashFailure,
  tickPaidByCashSuccess,
  tickPaidByUnspecifiedFailure,
  tickPaidByUnspecifiedSuccess,
  untickPaidByCashFailure,
  untickPaidByCashSuccess,
  untickPaidByUnspecifiedFailure,
  untickPaidByUnspecifiedSuccess,
} from 'notifications/cash-payment';
import { getSanitizedFilters } from 'reducers/common/filters';
import paths from 'routes/paths';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

import * as actions from './index';

const apiIndex = (pagination = {}, sorting = {}, filters = {}, isIndexClassicView) => ({
  [CALL_API]: {
    params: {
      ...paginationParams({ pagination_resource: Resources.INCOMING_INVOICES, ...pagination }),
      ...sortingParams(sorting),
      filters,
    },
    endpoint: `/me/incoming_invoices${isIndexClassicView ? '/classic_view' : ''}`,
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
  },
});

const apiIndexMore = ({ page, perPage } = {}, sorting = {}, filters = {}) => ({
  [CALL_API]: {
    params: {
      ...paginationParams({ perPage, page: page + 1 }),
      ...sortingParams(sorting),
      filters,
    },
    endpoint: '/me/incoming_invoices',
    types: [INDEX_MORE_REQUEST, INDEX_MORE_SUCCESS, INDEX_MORE_FAILURE],
  },
});

const apiSearch = (filter = '', pagination) => ({
  [CALL_API]: {
    params: {
      ...paginationParams(pagination),
      filter,
    },
    endpoint: '/me/incoming_invoices/classic_view',
    types: [SEARCH_REQUEST, SEARCH_SUCCESS, SEARCH_FAILURE],
  },
});

const apiDelete = (invoice) => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: `/me/incoming_invoices/${invoice.id}`,
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const sortIncomingInvoices = (column) => (dispatch) =>
  dispatch({
    type: INDEX_SORT,
    column,
  });

export const getIncomingInvoices =
  (
    pagination,
    sorting,
    { noFiltering = false, isIndexClassicView = false, customFilters = {} } = {}
  ) =>
  (dispatch, getState) => {
    const filters = noFiltering
      ? {}
      : {
          ...getSanitizedFilters(get(getState(), 'incomingInvoices.parsedFilters', {})),
          ...customFilters,
        };

    return dispatch(apiIndex(pagination, sorting, filters, isIndexClassicView)).catch(
      apiErrorHandler
    );
  };

export const resetIncomingInvoices = () => (dispatch) =>
  dispatch({
    type: INDEX_RESET,
  });

export const getMoreIncomingInvoices = (pagination, sorting) => (dispatch, getState) => {
  const filters = getSanitizedFilters(get(getState(), 'incomingInvoices.parsedFilters', {}));

  return dispatch(apiIndexMore(pagination, sorting, filters)).catch(apiErrorHandler);
};

export const setPage = (page) => ({
  type: PAGINATION,
  page,
});

export const searchIncomingInvoices = (filter, pagination) => (dispatch) =>
  dispatch(apiSearch(filter, pagination)).catch(apiErrorHandler);

export const deleteIncomingInvoice = (invoice) => (dispatch) =>
  dispatch(apiDelete(invoice)).catch(apiErrorHandler);

export const apiTogglePaidByCash = (invoiceId, isPaidByCash, date) => ({
  [CALL_API]: {
    data: {
      paidByCash: isPaidByCash,
      paidByCashDate: date,
    },
    method: 'PUT',
    endpoint: `/me/incoming_invoices/${invoiceId}/toggle_paid_by_cash`,
    types: [TOGGLE_PAID_BY_CASH_REQUEST, TOGGLE_PAID_BY_CASH_SUCCESS, TOGGLE_PAID_BY_CASH_FAILURE],
  },
});

export const togglePaidByCash = (invoiceId, isPaidByCash, date) => (dispatch) =>
  bindServerValidation(apiTogglePaidByCash(invoiceId, isPaidByCash, date), dispatch)
    .then((response) => {
      const paidByCash = get(response, 'data.attributes.paid-by-cash');
      dispatch(change('invoiceCreator', 'paidByCash', paidByCash));
      dispatch(change('invoiceCreator', 'paid', paidByCash));
      dispatch(showNotification(isPaidByCash ? tickPaidByCashSuccess : untickPaidByCashSuccess));
      dispatch(fetchIncomingInvoice(invoiceId));

      return response;
    })
    .catch((error) => {
      dispatch(showNotification(isPaidByCash ? tickPaidByCashFailure : untickPaidByCashFailure));
      apiErrorHandler(error);

      throw error;
    });

export const apiTogglePaidByUnspecified = (invoiceId, isPaidByUnspecified, date, reason) => ({
  [CALL_API]: {
    data: {
      paidByUnspecified: isPaidByUnspecified,
      paidByUnspecifiedDate: date,
      paidByUnspecifiedReason: reason,
    },
    method: 'PUT',
    endpoint: `/me/incoming_invoices/${invoiceId}/toggle_paid_by_unspecified`,
    types: [
      TOGGLE_PAID_BY_UNSPECIFIED_REQUEST,
      TOGGLE_PAID_BY_UNSPECIFIED_SUCCESS,
      TOGGLE_PAID_BY_UNSPECIFIED_FAILURE,
    ],
  },
});

export const togglePaidByUnspecified =
  (invoiceId, isPaidByUnspecified, date, reason) => (dispatch) =>
    bindServerValidation(
      apiTogglePaidByUnspecified(invoiceId, isPaidByUnspecified, date, reason),
      dispatch
    )
      .then((response) => {
        const paidByUnspecified = get(response, 'data.attributes.paid-by-unspecified');
        dispatch(change('invoiceCreator', 'paidByUnspecified', paidByUnspecified));
        dispatch(change('invoiceCreator', 'paidByUnspecifiedDate', date));
        dispatch(change('invoiceCreator', 'paidByUnspecifiedReason', reason));
        dispatch(change('invoiceCreator', 'paid', paidByUnspecified));
        dispatch(
          showNotification(
            isPaidByUnspecified ? tickPaidByUnspecifiedSuccess : untickPaidByUnspecifiedSuccess
          )
        );
        dispatch(fetchIncomingInvoice(invoiceId));

        return response;
      })
      .catch((error) => {
        dispatch(
          showNotification(
            isPaidByUnspecified ? tickPaidByUnspecifiedFailure : untickPaidByUnspecifiedFailure
          )
        );
        apiErrorHandler(error);

        throw error;
      });

export const apiToggleCreditNote = (invoiceId, value) => ({
  [CALL_API]: {
    data: { creditNote: value },
    method: 'PUT',
    endpoint: `/me/incoming_invoices/${invoiceId}/toggle_credit_note`,
    types: [TOGGLE_CREDIT_NOTE_REQUEST, TOGGLE_CREDIT_NOTE_SUCCESS, TOGGLE_CREDIT_NOTE_FAILURE],
  },
});

export const apifetchSubInvoices = (invoiceId) => ({
  [CALL_API]: {
    method: 'GET',
    endpoint: `/me/incoming_invoices/${invoiceId}/sub_invoices`,
    types: [FETCH_REQUEST_SUB_INVOICES, FETCH_SUCCESS_SUB_INVOICES, FETCH_FAILURE_SUB_INVOICES],
  },
});

export const apiDeleteAllBankTransferConnections = (invoiceId) => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: `/me/incoming_invoices/${invoiceId}/delete_all_bank_transfer_connections`,
    types: [
      DELETE_ALL_BANK_TRANSFER_CONNECTIONS_REQUEST,
      DELETE_ALL_BANK_TRANSFER_CONNECTIONS_SUCCESS,
      DELETE_ALL_BANK_TRANSFER_CONNECTIONS_FAILURE,
    ],
  },
});

export const toggleCreditNote = (invoiceId, value) => (dispatch) =>
  dispatch(apiToggleCreditNote(invoiceId, value))
    .then(({ response }) => {
      const val = get(response.data, 'attributes.creditNote');
      dispatch(change('invoiceCreator', 'creditNote', val));
    })
    .catch(apiErrorHandler);

export const setStatusFilter = (value) => ({
  type: SET_STATUS_FILTER,
  name: NAMESPACE,
  payload: {
    status: value,
  },
});

export const setQueryParam = actions.setQueryParam({ name: NAMESPACE });

export const setDateRange = (param) => (dispatch) => (values) =>
  dispatch({
    type: SET_DATE_RANGE,
    name: NAMESPACE,
    payload: {
      param,
      values,
    },
  });

export const clearFilters = ({ clearStatus = false } = {}) => ({
  type: CLEAR_FILTERS,
  name: NAMESPACE,
  payload: {
    clearStatus,
  },
});

export const clearFiltersToInitial = () => ({
  type: CLEAR_FILTERS_TO_INITIAL,
  name: NAMESPACE,
});

export const fetchSubInvoices = (invoiceId) => (dispatch) =>
  dispatch(apifetchSubInvoices(invoiceId)).catch(apiErrorHandler);

const apiDuplicateIncomingInvoice = (incomingInvoiceId) => ({
  [CALL_API]: {
    endpoint: `/me/incoming_invoices/${incomingInvoiceId}/duplicate`,
    method: 'POST',
    types: [DUPLICATE_INVOICE_REQUEST, DUPLICATE_INVOICE_SUCCESS, DUPLICATE_INVOICE_FAILURE],
  },
});

export const duplicateIncomingInvoice = (incomingInvoiceId) => (dispatch) =>
  dispatch(apiDuplicateIncomingInvoice(incomingInvoiceId))
    .catch(apiErrorHandler)
    .then(({ data: { id: draftId } }) => {
      dispatch(
        replace({
          pathname: paths.editIncomingInvoice(draftId),
          search: `?${FROM_DUPLICATE_QUERY_PARAM}`,
        })
      );
    });

export const deleteAllBankTransferConnections = (invoiceId) => (dispatch) =>
  dispatch(apiDeleteAllBankTransferConnections(invoiceId))
    .then(() => dispatch(fetchIncomingInvoice(invoiceId)))
    .catch(apiErrorHandler);
