import { CALL_API } from 'constants/api';
import {
  CREATE_CANCELLATION_FAILURE,
  CREATE_CANCELLATION_REQUEST,
  CREATE_CANCELLATION_SUCCESS,
  CREATE_DOWNGRADE_FAILURE,
  CREATE_DOWNGRADE_REQUEST,
  CREATE_DOWNGRADE_SUCCESS,
  CREATE_UPGRADE_FAILURE,
  CREATE_UPGRADE_REQUEST,
  CREATE_UPGRADE_SUCCESS,
} from 'constants/payment-plan';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiDowngradeUserPlan = (id) => ({
  [CALL_API]: {
    endpoint: `/me/payment_plans/${id}`,
    method: 'POST',
    types: [CREATE_DOWNGRADE_REQUEST, CREATE_DOWNGRADE_SUCCESS, CREATE_DOWNGRADE_FAILURE],
  },
});

export const apiUpgradeUserPlan = (id) => ({
  [CALL_API]: {
    endpoint: `/me/payment_plans/${id}`,
    method: 'POST',
    types: [CREATE_UPGRADE_REQUEST, CREATE_UPGRADE_SUCCESS, CREATE_UPGRADE_FAILURE],
  },
});

export const apiDowngradeCancellation = () => ({
  [CALL_API]: {
    endpoint: '/me/payment_plans/downgrade_cancellation',
    method: 'POST',
    types: [CREATE_CANCELLATION_REQUEST, CREATE_CANCELLATION_SUCCESS, CREATE_CANCELLATION_FAILURE],
  },
});

export const downgradePlan = (id) => (dispatch) =>
  dispatch(apiDowngradeUserPlan(id)).catch(apiErrorHandler);

export const upgradePlan = (id) => (dispatch) =>
  dispatch(apiUpgradeUserPlan(id)).catch(apiErrorHandler);

export const downgradeCancellation = (id) => (dispatch) =>
  dispatch(apiDowngradeCancellation(id)).catch(apiErrorHandler);
