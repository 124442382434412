import React, { memo, SVGProps } from 'react';

const Tip = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M18.97 25.636c.26 0 .486-.176.549-.429.253-1.02.977-3.593 2.089-4.73a7.353 7.353 0 002.107-5.16c0-4.375-3.804-7.863-8.292-7.354-3.375.384-6.1 3.09-6.497 6.455a7.379 7.379 0 001.89 5.893c1.164 1.27 2.005 3.075 2.146 4.815.023.29.269.51.56.51h5.448z"
        stroke="currentColor"
        strokeWidth={1.555}
      />
      <path
        stroke="currentColor"
        strokeWidth={1.555}
        strokeLinecap="round"
        d="M16.312 25.389v-6.655l4.11-4.101M16.276 18.743l-4.111-4.101"
      />
      <path
        d="M19.404 28.955h-6.22a.777.777 0 110-1.555h6.22a.777.777 0 010 1.555zM17.849 31.482h-3.11a.777.777 0 010-1.555h3.11a.777.777 0 010 1.555z"
        fill="currentColor"
      />
      <path
        stroke="currentColor"
        strokeWidth={2.138}
        strokeLinecap="round"
        d="M16.091 1.247v3.207M26.25 4.924l-2.27 2.265M8.361 7.345L6.091 5.08M26.125 13.086h3.207M3.247 13.086h3.207"
      />
    </g>
  </svg>
);

export default memo(Tip);
