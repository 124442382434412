import React, { memo, SVGProps } from 'react';

const Check = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        d="M27.123 7.875a1.285 1.285 0 00-1.71-.093l-.104.093L11.582 21.46l-4.89-4.84c-.506-.5-1.31-.5-1.815 0-.469.463-.5 1.18-.094 1.677l.094.103 5.784 5.725a1.303 1.303 0 001.724.093l.105-.093 14.633-14.47c.469-.463.5-1.18.094-1.677l-.094-.103z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(Check);
