import React, { useEffect, useMemo, useState } from 'react';

import { invoiceTemplateSelector } from 'selectors/invoice-templates';
import { useAppSelector } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { BankAccount } from 'types/entities/BankAccount';
import Select, { SelectProps } from 'redesign/components/atoms/Select/Select';
import FieldWrapper from 'redesign/components/molecules/FieldWrapper/FieldWrapper';

import BankAccountModal from '../BankAccountModal/BankAccountModal';
import { SelectType, TBankDetails, TManualBankAccount, TTemplateBankAccount } from '../types';
import * as SC from './BankAccountPicker.styled';
import BankListItem from './BankListItem/BankListItem';
import ManualBankDetails from './ManualBankDetails/ManualBankDetails';

const BankAccountPicker = ({
  index,
  label,
  disabled,
  bankAccounts,
  onChange,
}: {
  index: number;
  label: string;
  disabled?: boolean;
  bankAccounts: BankAccount[];
  onChange: (data: TBankDetails) => void;
}) => {
  const templateBankAccounts: TTemplateBankAccount[] =
    useAppSelector(invoiceTemplateSelector).bankAccounts;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState<TBankDetails>();
  const tBankAccounts = useMemo(
    () => [
      {
        type: SelectType.NONE,
        name: 'Kein Konto',
      },
      {
        ...templateBankAccounts.find((item) => item?.ordinalNumber === index && item.manual),
        type: SelectType.MANUAL,
        name: 'Kontodaten manuell eintragen',
      },

      ...bankAccounts?.filter(
        (item) =>
          item.id !==
          templateBankAccounts
            .find((item) => item.ordinalNumber !== index && !item.manual)
            ?.bankAccountId?.toString()
      ),
    ],
    [bankAccounts, index, templateBankAccounts]
  ) as TBankDetails[];

  const noBankAccount = tBankAccounts[0];
  const manualBankAccount = tBankAccounts[1];
  const isBankDetailsFilled = manualBankAccount?.bankName && manualBankAccount?.iban;

  const handleSubmitManual = (bankDetails: TManualBankAccount) => {
    onChange({ ...bankDetails, manual: true } as TBankDetails);
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleChangeItem = (item?: TBankDetails | null) => {
    if (item?.type === SelectType.MANUAL) {
      setIsModalOpen(true);
    } else {
      item && onChange(item);
    }
  };

  useEffect(() => {
    const tBankAccount = templateBankAccounts.find((item) => item?.ordinalNumber === index);
    const BankOption =
      bankAccounts.find((item) => +item.id === tBankAccount?.bankAccountId) || noBankAccount;
    const option = tBankAccount?.manual ? manualBankAccount : BankOption;
    option && setSelectedBankAccount(option as TBankDetails);
  }, [bankAccounts, index, manualBankAccount, noBankAccount, templateBankAccounts]);

  if (disabled) return null;

  return (
    <SC.BankAccountContainer>
      <BankAccountModal
        isActive={isModalOpen}
        onClose={handleClose}
        onSubmit={handleSubmitManual}
        bankDetails={manualBankAccount}
      />

      <SC.BankAccountField>
        <FieldWrapper<SelectProps<TBankDetails>>
          label={label}
          field={Select}
          topAlignment
          dataId="bank-account"
          disabled={false}
          options={tBankAccounts}
          onChange={handleChangeItem}
          itemToString={(item) => (item ? item.name : '')}
          option={({ item }) => <BankListItem item={item} />}
          value={selectedBankAccount}
        />
      </SC.BankAccountField>

      {selectedBankAccount?.type === SelectType.MANUAL && isBankDetailsFilled && (
        <SC.BankManualDetailsInner>
          <ManualBankDetails bankDetails={manualBankAccount} />

          <SC.OpenModalButton
            label={t('invoice_templates.form.bankAccounts.edit_bank_data')}
            type="button"
            appearance="flat"
            onClick={() => setIsModalOpen(true)}
          />
        </SC.BankManualDetailsInner>
      )}
    </SC.BankAccountContainer>
  );
};

export default BankAccountPicker;
