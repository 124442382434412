import React, { ReactNode, useState } from 'react';

import { t } from 'shared/utils';
import { AssignableResource } from 'types/entities/AssignableResource';
import IconLightBulb from 'components/Icons/IconLightBulb';

import {
  AllOptionsHeader,
  Option,
  ShowMoreButton,
  SuggestionHeader,
} from './SuggestionsSelect.styled';

let PerPageTime = 1;
let PerPage = PerPageTime * 3;

type AssignableSuggestions = AssignableResource & {
  label: string;
  id: number;
  dataId: number;
};

type ItemProps = {
  key: string;
  index: number;
  item: AssignableSuggestions;
};

type SuggestionsSelectProps = {
  getItemProps: (itemProps: ItemProps) => object;
  options: AssignableSuggestions[];
  optionComponent: (item: AssignableResource) => ReactNode;
};

const SuggestionsSelect = ({ getItemProps, options, optionComponent }: SuggestionsSelectProps) => {
  const [buttonHidden, setButtonHidden] = useState(options.length < 4 || options.length <= PerPage);
  const [suggestions, setSuggestions] = useState(options.slice(0, PerPage));

  const fetchMoreResources = () => {
    PerPageTime += 1;
    PerPage = PerPageTime * 3;
    const optionsCount = options.length;

    if (optionsCount <= PerPage) {
      setButtonHidden(true);
    }

    setSuggestions(options.slice(0, PerPage));
  };

  const trailingContent = buttonHidden ? null : (
    <ShowMoreButton
      type="button"
      data-id="button-fetch-more-suggestions"
      onClick={fetchMoreResources}
    >
      {t('bank_transfers.table.fetch_more_resources')}
    </ShowMoreButton>
  );

  return (
    <div>
      {options.length >= 1 && (
        <SuggestionHeader>
          <IconLightBulb width="15" height="15" />{' '}
          <div>{t('features.bank_transfers.suggestions.title')}</div>
        </SuggestionHeader>
      )}
      <div>
        {suggestions.map((item, index) => (
          <Option
            {...getItemProps({
              key: `${item.label}${item.id}`,
              index,
              item,
            })}
            data-id={item.dataId}
            className="Select-option"
            key={index}
          >
            {optionComponent(item)}
          </Option>
        ))}
      </div>
      {trailingContent}
      <AllOptionsHeader>{t('features.bank_transfers.suggestions.all_options')}</AllOptionsHeader>
    </div>
  );
};

export default SuggestionsSelect;
