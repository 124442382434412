import styled from 'styled-components';

type Props = {
  width: number;
};

export const HbciTanBeamer = styled.div<Props>`
  position: relative;
  padding: 6pt 6pt 6pt 6pt;
  margin: 0;
  border-radius: 1em;
  border: solid 3pt #2868a8;
  background-image: -moz-linear-gradient(top, #000, #222 15%, #222 85%, #444);
  background-image: -webkit-gradient(
    linear,
    center top,
    center bottom,
    from(#000),
    to(#444),
    color-stop(15%, #222),
    color-stop(85%, #222)
  );
  background-color: #222;
  width: ${({ width }) => `${width}px`};
  height: 140px;
`;

export const HbciTanBeamerContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const HbciTanBeamerBars = styled.table`
  position: absolute;
  left: 0;
  top: 25%;
  width: 100%;
  height: 75%;
`;

export const HbciTanBeamerBarPadding = styled.td`
  width: 2.5%;
`;

export const HbciTanBeamerBarBlack = styled.td`
  width: 15%;
  background-color: #000;
`;

export const HbciTanBeamerBarWhite = styled.td`
  width: 15%;
  background-color: #fff;
  box-shadow: 0 0 6pt 1pt rgba(255, 255, 255, 0.5);
`;
