import 'd3-transition';

import { easeLinear } from 'd3-ease';
import { select as d3select } from 'd3-selection';

import constants from 'shared/styles/constants.module.css';

import styles from './CopyButton.module.css';

const TRANSITION_DURATIONS = {
  BUTTON_WIDTH: 200,
  COLOR: 200,
  ICON_MAX_WIDTH: 200,
  ICON_NORMAL_WIDTH: 200,
};
const DELAYS = {
  COLOR: 200,
  ICON_MAX_WIDTH: 200,
};
const BUTTON_ADDITIONAL_WIDTH = 30;
const ICON_MAX_WIDTH = '19px';
const ICON_NORMAL_WIDTH = '15px';
const ICON_NORMAL_HEIGHT = '12px';
const ICON_LABEL_GAP = '8px';
const ICON_TOP_OFFSET = '3px';
const EASING_FUNCTION = easeLinear;

export const animateButton = (button, shouldExpand) => {
  const svg = button.querySelector('svg');
  const iconContainer = button.querySelector(`.${styles.iconContainer}`);
  const { width } = button.getBoundingClientRect();

  d3select(button)
    .transition()
    .ease(EASING_FUNCTION)
    .duration(TRANSITION_DURATIONS.BUTTON_WIDTH)
    .style('width', shouldExpand ? `${width + BUTTON_ADDITIONAL_WIDTH}px` : `${width}px`)
    .on('end', () => {
      d3select(button)
        .transition()
        .ease(EASING_FUNCTION)
        .delay(DELAYS.COLOR)
        .duration(TRANSITION_DURATIONS.COLOR)
        .style('background-color', constants.colorWhite)
        .style('color', constants.colorBrandBlue)
        .style('border-width', '1px')
        .on('end', () => {
          d3select(iconContainer)
            .transition()
            .ease(EASING_FUNCTION)
            .delay(DELAYS.ICON_MAX_WIDTH)
            .duration(TRANSITION_DURATIONS.ICON_MAX_WIDTH)
            .style('width', ICON_NORMAL_WIDTH)
            .style('height', ICON_NORMAL_HEIGHT)
            .style('margin-right', ICON_LABEL_GAP)
            .style('margin-top', ICON_TOP_OFFSET);

          d3select(svg)
            .transition()
            .ease(EASING_FUNCTION)
            .delay(DELAYS.ICON_MAX_WIDTH)
            .duration(TRANSITION_DURATIONS.ICON_MAX_WIDTH)
            .style('position', 'absolute')
            .style('top', '-2px')
            .style('left', '0')
            .style('width', ICON_MAX_WIDTH)

            .on('end', () => {
              d3select(svg)
                .transition()
                .ease(EASING_FUNCTION)
                .duration(TRANSITION_DURATIONS.ICON_NORMAL_WIDTH)
                .style('width', ICON_NORMAL_WIDTH);
            });
        });
    });
};
