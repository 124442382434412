import { get, isString, isUndefined, pickBy } from 'lodash';

import { getSalutation } from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/IndividualContactSection';
import { proposalSelector } from 'reducers/form';
import { t } from 'shared/utils';
import getDateXDaysFromNow from 'shared/utils/getDateXDaysFromNow';
import getSalutationHonorific from 'shared/utils/getSalutationHonorific';
import { RootState } from 'store';

interface ProposalValues {
  validUntil: number;
  subject: string;
  salutation: string;
  salutationContent: string;
  personalNotes: string;
}

interface ProposalInitialValues extends Partial<ProposalValues> {
  salutationHonorific: string;
  salutationContent: string;
}

const buildProposalValues = (state: any): ProposalValues => ({
  validUntil: get(state, 'defaultValues.values.proposalValidUntil'),
  subject: get(state, 'defaultValues.values.proposalSubject'),
  salutation: get(state, 'defaultValues.values.proposalSalutation'),
  salutationContent: get(state, 'defaultValues.values.proposalSalutationContent'),
  personalNotes: get(state, 'defaultValues.values.proposalFooter'),
});

export const getProposalInitialValues = (
  state: RootState,
  isNew: boolean,
  isFromDuplicate: boolean
): ProposalInitialValues => {
  const client = proposalSelector(state, 'client') || get(state, 'proposal.details.client');
  const defaultInitialValues = buildProposalValues(state);

  if (!isNew && !isFromDuplicate) {
    return {
      ...state.proposal.details,
      salutationHonorific: state.proposal.details.salutationHonorific || getSalutation(client),
      salutationContent: isNew
        ? t('revenue.form.individual_contact.initial_message_proposal')
        : state.proposal.details.salutationContent,
    };
  }

  return {
    ...pickBy(state.proposal.details, (value: any) => !isUndefined(value)),
    ...pickBy(defaultInitialValues, (value: any) => !isUndefined(value)),
    validUntil: defaultInitialValues.validUntil
      ? getDateXDaysFromNow(defaultInitialValues.validUntil)
      : get(state, 'proposal.details.validUntil'),
    salutationHonorific: getSalutationHonorific(
      'defaultProposalSalutationDefined',
      defaultInitialValues,
      client
    ),
    salutationContent: isString(defaultInitialValues.salutationContent)
      ? defaultInitialValues.salutationContent
      : t('revenue.form.individual_contact.initial_message_proposal'),
  };
};
