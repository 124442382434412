import React, { memo, SVGProps } from 'react';

const MonthlyOverview = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 74" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#BFD9EC">
        <path d="M64.235 0h12.631v72.869h-12.63z" />
        <path
          style={{
            mixBlendMode: 'multiply',
          }}
          d="M19.582 46.78h12.631v26.089H19.582z"
          transform="translate(44.653)"
        />
        <path d="M44.653 32.386h12.631v40.483h-12.63z" />
        <path
          style={{
            mixBlendMode: 'multiply',
          }}
          d="M0 52.178h12.631v20.691H0z"
          transform="translate(44.653)"
        />
      </g>
      <g fill="#BFD9EC">
        <path d="M24.245 25.19h12.631v47.68H24.245z" />
        <path
          style={{
            mixBlendMode: 'multiply',
          }}
          d="M0 38.683h12.631v8.996H0z"
          transform="translate(24.245 25.19)"
        />
      </g>
      <path fill="#8FB8DA" fillRule="nonzero" d="M100 73.232v-1H0v1z" />
    </g>
  </svg>
);

export default memo(MonthlyOverview);
