import React, { ChangeEvent, useCallback, useState } from 'react';

import { showNotification } from 'actions/notification';
import { deleteBankAccount } from 'api/me/bankAccounts';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { BankAccount } from 'types/entities/BankAccount';
import Button from 'redesign/components/atoms/Button/Button';
import Checkbox from 'redesign/components/atoms/Checkbox/Checkbox';
import CircleIcon from 'redesign/components/atoms/CircleIcon/CircleIcon';
import IconWarning from 'redesign/components/atoms/Icons/Warning';
import ModalActions from 'redesign/components/atoms/ModalActions/ModalActions';
import ControlLabel from 'redesign/components/molecules/ControlLabel/ControlLabel';
import Modal from 'redesign/components/molecules/Modal/Modal';

import { Content, Headline, IconWrapper, ModalBody, Warning } from './Modals.styled';
import { removeBankAccount } from './notifications';

type ModalProps = {
  bankAccount: BankAccount;
  onClose: () => void;
  onReload: () => void;
};

const DeleteModal = ({ bankAccount, onClose, onReload }: ModalProps) => {
  const dispatch = useAppDispatch();
  const [isCheckedDeleteTransactions, setIsCheckedDeleteTransactions] = useState(false);

  const handleTransactionsCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsCheckedDeleteTransactions(event.target.checked);
  }, []);

  const handleDelete = async () => {
    await deleteBankAccount(bankAccount.id);
    onReload();
    onClose();
    dispatch(showNotification(removeBankAccount));
  };

  return (
    <Modal isOpen onRequestClose={onClose}>
      <ModalBody>
        <IconWrapper>
          <CircleIcon icon={IconWarning} color="red" />
        </IconWrapper>

        <Content>
          <Headline>
            {t('redesign.organisms.bank_accounts_list.delete_modal.header_1')}
            <br /> {t('redesign.organisms.bank_accounts_list.delete_modal.header_2')}
          </Headline>

          <Warning>{t('redesign.organisms.bank_accounts_list.delete_modal.warning')}</Warning>
          <ControlLabel
            label={t('redesign.organisms.bank_accounts_list.delete_modal.checkbox_transactions')}
            control={<Checkbox />}
            checked={isCheckedDeleteTransactions}
            onChange={handleTransactionsCheckboxChange}
            data-id="checkbox-transactions"
          />
        </Content>
      </ModalBody>
      <ModalActions>
        <Button $color="red" disabled={!isCheckedDeleteTransactions} onClick={handleDelete}>
          {t('redesign.organisms.bank_accounts_list.delete_modal.confirm_deletion')}
        </Button>
        <Button $variant="outlined" onClick={onClose}>
          {t('redesign.organisms.bank_accounts_list.delete_modal.cancel')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeleteModal;
