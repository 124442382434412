import React from 'react';
import { func } from 'prop-types';

import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import I18n from 'components/I18n';

import styles from './AccountNumberUpdater.module.css';

const AccountNumberUpdater = ({ updateSupplier }) => (
  <div className={styles.main}>
    <div className={styles.placeholderTextContainer}>
      <I18n
        className={styles.placeholderText}
        t="expenses.payments.creator.update_account.text_1"
      />
      <I18n
        className={styles.placeholderText}
        t="expenses.payments.creator.update_account.text_2"
      />
    </div>
    <ActionButton
      appearance="primary"
      className={styles.updateButton}
      onClick={updateSupplier}
      label={t('expenses.payments.creator.update_account.button')}
    />
  </div>
);

AccountNumberUpdater.propTypes = {
  updateSupplier: func.isRequired,
};

export default AccountNumberUpdater;
