import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import { t } from 'shared/utils';
import SubsectionHeadline from 'components/SubsectionHeadline/SubsectionHeadline';

import MessageTile from '../MessageTile/MessageTile';

import styles from './MessagesSubsection.module.css';

const MessagesSubsection = ({ messages, status, ...rest }) => {
  if (!messages) return null;

  return (
    <div className={styles.container}>
      <SubsectionHeadline title={t(`sidebar.messages.subsections.${status}`)} />
      {messages.map((message) => (
        <MessageTile key={message.id} {...message} {...rest} />
      ))}
    </div>
  );
};

MessagesSubsection.propTypes = {
  messages: arrayOf(shape({})),
  status: string.isRequired,
};

export default MessagesSubsection;
