import configureMockStore from 'redux-mock-store';
import thunk from 'redux-thunk';

import apiMiddleware from 'middlewares/api.mock';
import notificationMiddleware from 'middlewares/notification';

export const createMockStore = (
  { apiFailing = false, apiOffline = false } = { apiFailing: false, apiOffline: false }
) => configureMockStore([thunk, apiMiddleware({ apiFailing, apiOffline }), notificationMiddleware]);

export const actionReturns = (action, expectedResult) => () => {
  expect(action).toEqual(expectedResult);
};

export const actionDispatches = (action, dispatched, options) => async () => {
  const store = createMockStore(options)({});
  const expectedActions = Array.isArray(dispatched) ? dispatched : [dispatched];

  await store.dispatch(action);
  expectedActions.forEach((expectedAction) => {
    expect(store.getActions()).toContainEqual(expectedAction);
  });
};

export const actionHandlesAPI = (apiAction, actionTypes) => async () => {
  const [requestType, successType, failureType] = actionTypes;

  const successStore = createMockStore()({});
  const failureStore = createMockStore({ apiFailing: true })({});

  const successActions = [{ type: requestType }, { type: successType }];
  const failureActions = [{ type: requestType }, { type: failureType }];

  await Promise.all([
    successStore.dispatch(apiAction),
    failureStore.dispatch(apiAction).catch((ex) => ex),
    // catch as server-validation.js throws on purpose
  ]);

  successActions.forEach((successAction) => {
    expect(successStore.getActions()).toContainEqual(successAction);
  });
  failureActions.forEach((failureAction) => {
    expect(failureStore.getActions()).toContainEqual(failureAction);
  });
};

export const actionHandlesOffline = (apiAction, expectedStore) => async () => {
  const store = createMockStore({ apiOffline: true })({});
  await store.dispatch(apiAction);
  expect(store.getActions()).toEqual(expectedStore);
};

export const getPushAction = (route) => ({
  type: '@@router/CALL_HISTORY_METHOD',
  payload: { args: [route], method: 'push' },
});
