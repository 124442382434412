import { t } from 'shared/utils';

export const downloadContractSuccess = {
  duration: 3000,
  title: t('contracts.new.downloads.full_contract.success'),
  variant: 'success',
};

export const createContractSuccess = {
  duration: 3000,
  title: t('contracts.new.create_success'),
  variant: 'success',
};

export const deleteContractSuccess = {
  duration: 3000,
  title: t('contracts.deletion.success'),
  variant: 'success',
};

export const deleteContractFailed = {
  duration: 3000,
  title: t('contracts.deletion.failed'),
  variant: 'warning',
};
