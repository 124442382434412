import styled from 'styled-components';

type AmountProps = {
  isNegative?: boolean;
};

export const Amount = styled.div<AmountProps>`
  font-weight: bold;
  color: ${({ theme, isNegative }) =>
    isNegative ? theme.legacy.colors.red : theme.legacy.colors.green};
`;

export const AmountToRight = styled(Amount)`
  text-align: right;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 15px;
  }
`;

export const ActionButton = styled.button`
  padding: 0;
  font: inherit;
  outline: inherit;
  border: none;
  color: inherit;
  background: none;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.legacy.colors.brandBlue};
  }
`;

export const InvoiceAmount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  > * {
    text-align: right;
  }
`;

export const Reason = styled.div`
  font-weight: bold;
`;
