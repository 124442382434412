import React, { useContext } from 'react';
import cx from 'classnames';
import { shape, string } from 'prop-types';

import { noop } from 'shared/utils';

import RadioGroupContext from '../RadioGroupContext';

import styles from './RadioGroupOption.module.css';

const RadioGroupOption = ({ option: { label, value: optionValue, dataIds = {} } }) => {
  const { name, onChange, value, isDisabled } = useContext(RadioGroupContext);
  const isChecked = optionValue === value;

  return (
    <div key={label} className={styles.option}>
      <input
        className={styles.optionInput}
        onClick={() => !isChecked && onChange(optionValue)}
        // if 'checked' attribute is present it is required to have onChange, otherwise error is displayed
        onChange={noop}
        type="radio"
        id={label}
        name={name}
        checked={isChecked}
        data-id={dataIds.input}
        disabled={isDisabled}
      />
      <label
        data-id={dataIds.label}
        className={cx(styles.optionLabel, {
          [styles.optionLabelChecked]: isChecked,
          [styles.optionLabelDisabled]: isChecked && isDisabled,
          [styles.optionLabelNotAllowed]: isDisabled,
        })}
        htmlFor={label}
      >
        {label}
      </label>
    </div>
  );
};

RadioGroupOption.propTypes = {
  option: shape({
    label: string.isRequired,
    value: string.isRequired,
    dataIds: shape({
      input: string,
      label: string,
    }),
  }).isRequired,
};

export default RadioGroupOption;
