import { FETCH_SUCCESS, UPDATE_SUCCESS } from 'constants/company';

const defaultState = {
  initial: {},
};

const values = (state, action) => {
  const {
    response: { data },
  } = action;
  const included = action.response.included || [];
  const companyAttributes = {
    ...state.initial,
    ...data.attributes,
  };
  const { companyAuthorizedPeopleAttributes, generalPartnerAuthorizedPeopleAttributes } =
    included.reduce(
      ({ companyAuthorizedPeopleAttributes, generalPartnerAuthorizedPeopleAttributes }, person) => {
        const companyAuthorizedPeople = person.type === 'company-authorized-people';
        if (companyAuthorizedPeople) {
          return person.attributes.forGeneralPartner
            ? {
                companyAuthorizedPeopleAttributes,
                generalPartnerAuthorizedPeopleAttributes: [
                  ...generalPartnerAuthorizedPeopleAttributes,
                  person.attributes,
                ],
              }
            : {
                generalPartnerAuthorizedPeopleAttributes,
                companyAuthorizedPeopleAttributes: [
                  ...companyAuthorizedPeopleAttributes,
                  person.attributes,
                ],
              };
        }
        return { companyAuthorizedPeopleAttributes, generalPartnerAuthorizedPeopleAttributes };
      },
      { companyAuthorizedPeopleAttributes: [], generalPartnerAuthorizedPeopleAttributes: [] }
    );

  return {
    ...companyAttributes,
    companyAuthorizedPeopleAttributes,
    generalPartnerAuthorizedPeopleAttributes,
  };
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
    case UPDATE_SUCCESS: {
      return {
        ...state,
        initial: values(state, action),
        values: values(state, action),
      };
    }
    default:
      return state;
  }
};
