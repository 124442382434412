import 'react-dates-legacy/initialize';

import React, { FocusEvent, useRef, useState } from 'react';
import { Manager, Reference } from 'react-popper-legacy';
import cx from 'classnames';

import calendarIcon from 'images/icon-calendar.svg';
import { noop } from 'shared/utils';
import InfoTextField from 'components/Form/InfoTextField';

import Controller from './Controller';
import {
  useCloseOnOutsideClick,
  useFormatWhenPopperCloses,
  useReopenWhenTyping,
} from './DateField.hooks';
import { DateFieldProps } from './DateField.types';
import { handleKeyDown } from './DateField.utils';

import 'react-dates-legacy/lib/css/_datepicker.css';
import styles from './DateField.module.css';

const DateField = ({
  id,
  input,
  disabled = false,
  label,
  placeholder = 'TT.MM.JJJJ',
  minDate,
  maxDate,
  className = '',
  inputClassName = '',
  onChange = noop,
  message,
  shouldShowRequiredIcon = false,
  dataIds = { container: '', input: '' },
  isLabelHidden = false,
  placement = 'bottom-start',
  invoiceDate,
  ...rest
}: DateFieldProps) => {
  const datepickerRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [ignoreFormattingOnClose, setIgnoreFormattingOnClose] = useState(false);

  useCloseOnOutsideClick({
    datepickerRef,
    setIsFocused,
  });

  useReopenWhenTyping({
    date: input.value,
    isFocused,
    setIsFocused,
    minDate,
    maxDate,
    setIgnoreFormattingOnClose,
  });

  useFormatWhenPopperCloses({
    date: input.value,
    isFocused,
    fieldOnChange: input.onChange,
    ignoreFormattingOnClose,
    setIgnoreFormattingOnClose,
  });

  const closeOnBlur = (e: FocusEvent<HTMLDivElement>) => {
    if (datepickerRef.current === null || e.relatedTarget === null) return;

    const isElInside = datepickerRef.current.contains(e.relatedTarget as Node);
    if (isElInside) return;

    setIsFocused(false);
  };

  return (
    <div
      className={cx(styles.inputWrapper, className)}
      ref={datepickerRef}
      data-id={dataIds.container}
      onBlur={closeOnBlur}
    >
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref}>
              <InfoTextField
                {...rest}
                shouldShowRequiredIcon={shouldShowRequiredIcon}
                isLabelHidden={isLabelHidden}
                value={input.value}
                onChange={input.onChange}
                onFocus={() => setIsFocused(true)}
                onKeyDown={(e: KeyboardEvent) => handleKeyDown({ e, isFocused, setIsFocused })}
                label={label}
                placeholder={placeholder}
                id={id}
                icon={calendarIcon}
                disabled={disabled}
                message={message}
                className={inputClassName}
                input={input}
                dataId={dataIds.input}
              />
            </div>
          )}
        </Reference>
        {isFocused && (
          <Controller
            input={input}
            onChange={onChange}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
            minDate={minDate}
            maxDate={maxDate}
            placement={placement}
            invoiceDate={invoiceDate}
          />
        )}
      </Manager>
    </div>
  );
};

export default DateField;
