import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as reduxPush } from 'connected-react-router';
import { bool, func, shape, string } from 'prop-types';

import { EDIT_MODE, NEW_MODE, SHOW_MODE } from 'constants/common/crud';
import * as Piwik from 'constants/piwik';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import ActionDropdown from 'components/ActionPanel/ActionDropdown';
import { ButtonAppearances } from 'components/Button';
import I18n from 'components/I18n';
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'components/LineItems';
import { ConfirmationModal } from 'components/Modal';

import EmailEditor from '../../EmailEditor/EmailEditor';
import AbortButton from './AbortButton';
import PreviewModal from './PreviewModal';

import styles from './ActionsSection.module.css';

class ActionsSection extends Component {
  state = {
    modalActiveTransformProposalIntoOutgoingInvoice: false,
    modalActiveTransformProposalIntoOrderConfirmation: false,
    sendEmailModalVisible: false,
    previewVisible: false,
  };

  onConfirmTransformProposalIntoOutgoingInvoice = () => {
    const { saveAndTransformIntoOutgoingInvoice } = this.props;
    saveAndTransformIntoOutgoingInvoice();
    this.hideModalTransformProposalIntoOutgoingInvoice();
  };

  onConfirmTransformProposalIntoOrderConfirmation = () => {
    const { saveAndTransformIntoOrderConfirmation } = this.props;
    saveAndTransformIntoOrderConfirmation();
    this.hideModalTransformProposalIntoOrderConfirmation();
  };

  onCloseTransformProposalIntoOutgoingInvoice = () => {
    const { transformIntoOutgoingInvoice } = this.props;
    transformIntoOutgoingInvoice();
    this.hideModalTransformProposalIntoOutgoingInvoice();
  };

  onCloseTransformProposalIntoOrderConfirmation = () => {
    const { transformIntoOrderConfirmation } = this.props;
    transformIntoOrderConfirmation();
    this.hideModalTransformProposalIntoOrderConfirmation();
  };

  onTransformIntoOutgoingInvoice = () => {
    const { transformIntoOutgoingInvoice } = this.props;
    piwikHelpers.trackEvent(Piwik.CATEGORY_PROPOSALS, Piwik.ACTION_SHOW_VIEW_TRANSFORM_PROPOSAL);
    transformIntoOutgoingInvoice();
  };

  onTransformIntoOrderConfirmation = () => {
    const { transformIntoOrderConfirmation } = this.props;
    transformIntoOrderConfirmation();
  };

  onAbort = () => {
    const { abort, crudMode } = this.props;
    abort();

    if (crudMode === NEW_MODE) {
      piwikHelpers.trackEvent(Piwik.CATEGORY_PROPOSALS, Piwik.ACTION_CREATE_PROPOSAL_CANCEL);
    }
  };

  onSaveAsDraft = () => {
    const { saveAsDraft, crudMode } = this.props;

    saveAsDraft();

    if (crudMode === NEW_MODE) {
      piwikHelpers.trackEvent(Piwik.CATEGORY_PROPOSALS, Piwik.ACTION_CREATE_PROPOSAL_SAVE);
    }
    if (crudMode === EDIT_MODE) {
      piwikHelpers.trackEvent(Piwik.CATEGORY_PROPOSALS, Piwik.ACTION_EDIT_PROPOSAL_SAVE);
    }
  };

  onPreview = () => {
    const { crudMode } = this.props;
    this.togglePreview();

    if (crudMode === EDIT_MODE) {
      piwikHelpers.trackEvent(Piwik.CATEGORY_PROPOSALS, Piwik.ACTION_PROPOSAL_PREVIEW);
    }
  };

  setSendMailModal = (value) => this.setState({ sendEmailModalVisible: value });

  transformIntoOutgoingInvoiceWithSaveConfirmation = () => {
    const { isFormDirty, transformIntoOutgoingInvoice, crudMode } = this.props;

    if (crudMode === NEW_MODE) {
      piwikHelpers.trackEvent(Piwik.CATEGORY_PROPOSALS, Piwik.ACTION_NEW_VIEW_TRANSFORM_PROPOSAL);
    }
    if (crudMode === EDIT_MODE) {
      piwikHelpers.trackEvent(Piwik.CATEGORY_PROPOSALS, Piwik.ACTION_EDIT_VIEW_TRANSFORM_PROPOSAL);
    }

    if (isFormDirty) {
      this.showModalTransformProposalIntoOutgoingInvoice();
    } else {
      transformIntoOutgoingInvoice();
    }
  };

  transformIntoOrderConfirmationWithSaveConfirmation = () => {
    const { isFormDirty, transformIntoOrderConfirmation } = this.props;

    if (isFormDirty) {
      this.showModalTransformProposalIntoOrderConfirmation();
    } else {
      transformIntoOrderConfirmation();
    }
  };

  showModalTransformProposalIntoOutgoingInvoice = () =>
    this.setState({ modalActiveTransformProposalIntoOutgoingInvoice: true });

  hideModalTransformProposalIntoOutgoingInvoice = () =>
    this.setState({ modalActiveTransformProposalIntoOutgoingInvoice: false });

  showModalTransformProposalIntoOrderConfirmation = () =>
    this.setState({ modalActiveTransformProposalIntoOrderConfirmation: true });

  hideModalTransformProposalIntoOrderConfirmation = () =>
    this.setState({ modalActiveTransformProposalIntoOrderConfirmation: false });

  togglePreview = () => {
    const { previewVisible } = this.state;

    this.setState({
      previewVisible: !previewVisible,
    });
  };

  sendActions = () => {
    const downloadAction = {
      name: t('proposals.form.download'),
      onClick: () => this.processDownloadProposal(),
      tooltip: t('proposals.form.download_tooltip'),
      dataId: 'download-pdf',
    };
    const sendByEmailAction = {
      name: t('proposals.form.send_email'),
      onClick: () => this.setSendMailModal(true),
      tooltip: t('proposals.form.send_email_tooltip'),
      dataId: 'ProposalPage:button-send-email',
    };

    return this.props.sendingDocumentsByEmailEnabled
      ? [downloadAction, sendByEmailAction]
      : [downloadAction];
  };

  downloadProposal = (proposal = {}, isTracked = false) => {
    this.props.download(proposal, isTracked);
  };

  saveAndDownloadProposal = async () => {
    const { save, crudMode } = this.props;
    const updatedProposal = await save();

    if (updatedProposal.success) {
      const isPiwikTracked = crudMode === NEW_MODE || crudMode === EDIT_MODE;
      this.downloadProposal(updatedProposal, isPiwikTracked);
    }
  };

  processDownloadProposal = () => {
    this.setSendMailModal(false);
    return this.props.readonly ? this.downloadProposal() : this.saveAndDownloadProposal();
  };

  render() {
    const {
      isFormSubmitting,
      readonly,
      showSave = false,
      showTransformIntoOrderConfirmation = false,
      showTransformIntoOutgoingInvoice = false,
      currentClient = {},
      wrapped = false,
      push,
      isProfileFilled,
      canSubmit,
      crudMode,
      isDraft,
    } = this.props;
    const { previewVisible, sendEmailModalVisible } = this.state;
    const isShowMode = crudMode === SHOW_MODE;
    const submittable = canSubmit || isShowMode;
    const isSentProposal = crudMode === EDIT_MODE && !isDraft;

    return (
      <ActionPanel
        wrapped={wrapped}
        className={cx(styles.creatorActionsSection, IGNORE_OUTSIDE_CLICK_CLASS)}
      >
        <ActionButton
          dataId="ProposalPage:button-save"
          visible={!readonly && showSave && !isSentProposal}
          label={t('proposals.form.save_draft')}
          onClick={this.onSaveAsDraft}
          disabled={!submittable}
        />
        <ActionDropdown
          upwards
          visible
          disabled={!submittable || !isProfileFilled}
          actions={this.sendActions()}
          title={t('proposals.form.download_or_send')}
          dataId="ProposalPage:button-download-or-save"
        />
        <ActionButton
          dataId="proposal-into-order-confirmation-button"
          visible={showTransformIntoOrderConfirmation && !readonly}
          label={t('proposals.form.save_and_transform_into_order_confirmation')}
          onClick={this.transformIntoOrderConfirmationWithSaveConfirmation}
          disabled={!submittable || isFormSubmitting}
        />
        <ActionButton
          dataId="proposal-into-order-confirmation-button"
          visible={showTransformIntoOrderConfirmation && readonly}
          label={t('proposals.form.save_and_transform_into_order_confirmation')}
          onClick={this.onTransformIntoOrderConfirmation}
        />
        <ActionButton
          dataId="ProposalPage:button-transform"
          visible={showTransformIntoOutgoingInvoice && !readonly}
          label={t('proposals.form.save_and_transform_into_outgoing_invoice')}
          onClick={this.transformIntoOutgoingInvoiceWithSaveConfirmation}
          disabled={!submittable || isFormSubmitting}
        />
        <ActionButton
          dataId="ProposalPage:button-transform"
          visible={showTransformIntoOutgoingInvoice && readonly}
          label={t('proposals.form.save_and_transform_into_outgoing_invoice')}
          onClick={this.onTransformIntoOutgoingInvoice}
        />
        <ActionButton
          dataId="ProposalPage:button-preview"
          visible={!readonly}
          appearance={ButtonAppearances.outlined}
          onClick={this.onPreview}
          label={t('revenue.form.preview')}
        />
        <AbortButton
          dataId="ProposalPage:button-abort"
          visible={!readonly}
          onClick={this.onAbort}
        />
        <ConfirmationModal
          isOpen={this.state.modalActiveTransformProposalIntoOutgoingInvoice}
          onClose={this.onCloseTransformProposalIntoOutgoingInvoice}
          onConfirm={this.onConfirmTransformProposalIntoOutgoingInvoice}
        >
          <I18n t="proposals.form.modal" />
        </ConfirmationModal>
        <ConfirmationModal
          isOpen={this.state.modalActiveTransformProposalIntoOrderConfirmation}
          onClose={this.onCloseTransformProposalIntoOrderConfirmation}
          onConfirm={this.onConfirmTransformProposalIntoOrderConfirmation}
        >
          <I18n t="proposals.form.modal" />
        </ConfirmationModal>
        {sendEmailModalVisible && (
          <EmailEditor
            onClose={() => this.setSendMailModal(false)}
            client={currentClient}
            title={t('modals.proposal.send_email.title')}
            saveProposal={this.props.saveWithoutRedirect}
            readonly={readonly}
          />
        )}
        <PreviewModal onClose={this.togglePreview} isOpen={previewVisible} />
        <ActionButton
          appearance={ButtonAppearances.outlined}
          onClick={() => push(paths.proposals)}
          label={t('forms.actions.back_to_index')}
          visible={readonly}
        />
      </ActionPanel>
    );
  }
}

ActionsSection.propTypes = {
  save: func.isRequired,
  saveAsDraft: func.isRequired,
  showTransformIntoOrderConfirmation: bool,
  showTransformIntoOutgoingInvoice: bool,
  showSave: bool,
  transformIntoOutgoingInvoice: func.isRequired,
  transformIntoOrderConfirmation: func.isRequired,
  saveAndTransformIntoOutgoingInvoice: func.isRequired,
  download: func.isRequired,
  abort: func.isRequired,
  isFormDirty: bool,
  isFormSubmitting: bool.isRequired,
  readonly: bool,
  currentClient: shape(),
  sendingDocumentsByEmailEnabled: bool,
  wrapped: bool,
  crudMode: string.isRequired,
  push: func.isRequired,
  isProfileFilled: bool.isRequired,
  saveWithoutRedirect: func.isRequired,
  isDraft: bool,
};

const mapStateToProps = (state) => ({
  isProfileFilled: state.onboarding.data?.profileFilled,
});

const mapDispatchToProps = {
  push: (path) => reduxPush(path),
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsSection);
