import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'classnames';

import { t } from 'shared/utils';
import InfoIcon from 'components/InfoIcon/InfoIcon';

import styles from './Section.module.css';

interface SectionProps {
  title?: string;
  children?: ReactNode;
  className?: string;
  sectionTitleTheme?: string;
  wrapperTheme?: string;
  label?: string;
  underline?: boolean;
  withoutHeader?: boolean;
  rightContent?: ReactNode;
  areFieldsRequired?: boolean;
  isHeaderHidden?: boolean;
}

const Section: FunctionComponent<SectionProps> = ({
  title = '',
  children = null,
  className = '',
  sectionTitleTheme = '',
  wrapperTheme = '',
  label = '',
  underline = false,
  withoutHeader = false,
  rightContent = null,
  areFieldsRequired = false,
  isHeaderHidden = false,
}) => (
  <div className={cx(styles.section, wrapperTheme)}>
    {areFieldsRequired && (
      <div className={styles.required}>{`* ${t('shared.fields_required')}`}</div>
    )}
    {!isHeaderHidden && (
      <div
        className={cx(
          underline ? styles.sectionTitleUnderline : styles.sectionTitle,
          sectionTitleTheme,
          {
            [styles.noTitle]: !title,
            [styles.noHeader]: withoutHeader,
          }
        )}
      >
        <div>
          <p className={styles.title}>{title}</p>
          {!!label && <InfoIcon text={label} className={styles.icon} />}
        </div>
        {rightContent && <div className={styles.rightContentWrapper}>{rightContent}</div>}
      </div>
    )}
    <div className={cx(styles.fields, className)}>{children}</div>
  </div>
);

interface SectionRowProps {
  children: ReactNode;
  dataId?: string;
  className?: string;
}

export const SectionRow: FunctionComponent<SectionRowProps> = ({
  children,
  dataId = '',
  className = '',
}) => (
  <div data-id={dataId} className={cx(styles.sectionRow, className)}>
    {children}
  </div>
);

export default Section;
