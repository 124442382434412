import React from 'react';
import { VictoryLabel, VictoryLine } from 'victory';

import { COLOR_BLUE } from 'constants/colors';
import { t } from 'shared/utils';

import { FONT_FAMILY } from '../constants';

type CurrentDayLineProps = {
  referenceLineStyle: {
    data: { stroke: string };
  };
  currentDayLineData: object[];
  accessors: {
    x: (d: { index: number; day: string; totalSum: number }) => number;
    y: (d: { index: number; day: string; totalSum: number }) => number;
  };
  isCurrentDayLineInView: boolean;
};

const CurrentDayLine = ({
  referenceLineStyle,
  currentDayLineData,
  accessors,
  isCurrentDayLineInView,
  ...rest
}: CurrentDayLineProps) => (
  <VictoryLine
    {...rest}
    style={referenceLineStyle}
    data={currentDayLineData}
    labelComponent={
      <VictoryLabel
        style={{
          fontSize: 13,
          fontFamily: FONT_FAMILY,
          stroke: COLOR_BLUE,
          fill: COLOR_BLUE,
          display: `${isCurrentDayLineInView ? 'block' : 'none'}`,
        }}
        dy={2}
        renderInPortal // allows to render the element outside chart container
      />
    }
    labels={[t('dashboard.liquidity_chart.current_day_line_text').toUpperCase()]}
    {...accessors}
  />
);

export default CurrentDayLine;
