import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { fetchDeliveryNote as fetchDeliveryNoteAction } from 'actions/delivery-note';
import { fetchDeliveryNoteLineItems as fetchDeliveryNoteLineItemsAction } from 'actions/delivery-note/line-items';
import { SHOW_MODE } from 'constants/common/crud';
import { withWarningAlert } from 'shared/hoc';
import { t } from 'shared/utils';
import LoadingIcon from 'components/LoadingIcon';
import DeliveryNoteCreator from 'features/deliveryNotes/DeliveryNoteCreator/DeliveryNoteCreator';

class DeliveryNoteShow extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    isFetched: PropTypes.bool.isRequired,
    deliveryNoteId: PropTypes.string.isRequired,
    fetchDeliveryNote: PropTypes.func.isRequired,
    fetchDeliveryNoteLineItems: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { deliveryNoteId } = this.props;
    this.props.fetchDeliveryNote(deliveryNoteId);
    this.props.fetchDeliveryNoteLineItems(deliveryNoteId);
  }

  render() {
    const { isFetching, isFetched } = this.props;

    if (isFetching) return <LoadingIcon />;
    if (!isFetched) return null;

    return <DeliveryNoteCreator crudMode={SHOW_MODE} />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  isFetching: state.deliveryNote.isFetching,
  isFetched: state.deliveryNote.isFetched,
  deliveryNoteId: ownProps.match.params.id,
  buttonLabel: t('empty_entry_pages.delivery_notes.delivery_notes_warning_box.button_label'),
  content: t('empty_entry_pages.delivery_notes.delivery_notes_warning_box.content_1'),
  dataId: 'DeliveryNotes:orange-warning-container',
});

const mapDispatchToProps = (dispatch) => ({
  fetchDeliveryNote: (id) => dispatch(fetchDeliveryNoteAction(id)),
  fetchDeliveryNoteLineItems: (id) => dispatch(fetchDeliveryNoteLineItemsAction(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWarningAlert
)(DeliveryNoteShow);
