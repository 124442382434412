import { isFinite } from 'lodash';

import { SliderValues } from '../constants';

export const getNotRoundedMoneyNeeded = ({
  bridgePeriodInMonths,
  monthsToDeficit,
  moneyPerMonth,
}: {
  bridgePeriodInMonths: number;
  monthsToDeficit: number;
  moneyPerMonth: number;
}) => (bridgePeriodInMonths - monthsToDeficit) * Math.abs(moneyPerMonth);

export const roundMoneyNeeded = (notRoundedMoney: number) => {
  const roundedValue = notRoundedMoney < 50000 ? 1000 : 5000;

  return Math.ceil(notRoundedMoney / roundedValue) * roundedValue;
};

export const calculateMoneyNeeded = ({
  bridgePeriodInMonths,
  monthsToDeficit,
  moneyPerMonth,
}: {
  bridgePeriodInMonths: number;
  monthsToDeficit: number | undefined;
  moneyPerMonth: number;
}) => {
  if (
    moneyPerMonth >= 0 ||
    typeof monthsToDeficit !== 'number' ||
    monthsToDeficit >= SliderValues.max
  )
    return 0;

  const notRoundedMoney = getNotRoundedMoneyNeeded({
    bridgePeriodInMonths,
    monthsToDeficit,
    moneyPerMonth,
  });

  return roundMoneyNeeded(notRoundedMoney);
};

export const calculateMonthsToDeficit = ({
  balance,
  moneyPerMonth,
}: {
  balance: number;
  moneyPerMonth: number;
}) => {
  const result = Math.floor(Math.abs(balance / moneyPerMonth));

  return isFinite(result) ? result : undefined;
};
