import React, { Component } from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';
import { func, string } from 'prop-types';

import arrowIcon from 'images/icon-arrow.svg';
import isPressedEnter from 'shared/utils/keyboard-events';

import styles from './ExpandableTile.module.css';

class ExpandableTile extends Component {
  state = {
    isOpen: false,
  };

  componentDidUpdate(_, prevState) {
    if (this.state.isOpen && !prevState.isOpen && this.props.onExpand) {
      this.props.onExpand();
    }
  }

  toggleOpen = () => this.setState(({ isOpen }) => ({ isOpen: !isOpen }));

  injectProps = () => {
    const { tileComponent, children, ...props } = this.props;
    const { isOpen } = this.state;
    return { ...props, isOpen, toggleOpen: this.toggleOpen };
  };

  render() {
    const { tileComponent, children, className } = this.props;
    const { isOpen } = this.state;

    return (
      <div className={cx(styles.container, className)}>
        <div className={styles.tileContainer}>
          <div className={styles.tileWrapper}>{tileComponent(this.injectProps())}</div>
          <div
            className={cx(styles.arrow, { [styles.reversed]: isOpen })}
            onClick={this.toggleOpen}
            onKeyPress={isPressedEnter(this.toggleOpen)}
            role="button"
            data-id="open-task"
          >
            <InlineSvg src={arrowIcon} />
          </div>
        </div>
        <div className={cx(styles.childrenContainer, { [styles.isOpen]: isOpen })}>
          {children(this.injectProps())}
        </div>
      </div>
    );
  }
}

ExpandableTile.propTypes = {
  children: func.isRequired,
  tileComponent: func.isRequired,
  className: string,
  onExpand: func,
};

export default ExpandableTile;
