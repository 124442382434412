import React, { memo } from 'react';

import { formatDate, padString } from 'shared/utils';
import { AssignableResource } from 'types/entities/AssignableResource';
import IncomingInvoiceAmount from 'components/Table/IncomingInvoiceAmount';

import { Contractor, Date, Description, LeftColumn, Option } from './SelectResourcesField.styled';

type InvoiceOptionType = {
  invoice: AssignableResource;
  bankTransferAmount: number | undefined;
};

const InvoiceOption = ({
  invoice: { date, description, totalGrossAmount, contractor, creditNote },
  bankTransferAmount,
}: InvoiceOptionType) => (
  <Option data-id={`assignment-row-${date}`}>
    <LeftColumn>
      <Date>{formatDate(date)}</Date>
      <Description>{padString(description, 40)}</Description>
      <Contractor>{contractor}</Contractor>
    </LeftColumn>
    <IncomingInvoiceAmount
      bold
      amount={bankTransferAmount && bankTransferAmount < 0 ? -totalGrossAmount : totalGrossAmount}
      creditNote={creditNote}
      bankTransferAmount={bankTransferAmount}
    />
  </Option>
);

export default memo(InvoiceOption);
