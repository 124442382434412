import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { DISMISS, DONT_SHOW_AGAIN, OBS_OPEN_MODAL } from 'constants/piwik';
import { t } from 'shared/utils';
import { getObsTooltipDismissed } from 'store/slices/onboarding/selectors';
import { updateOnboarding } from 'store/slices/onboarding/thunks';
import Button, { ButtonAppearances } from 'components/Button';
import RawCheckbox from 'components/Form/RawCheckbox/RawCheckbox';
import OBSModalFlow from 'components/OBS/OBSModalFlow/OBSModalFlow';

import { trackEvent } from './OBSTooltip.utils';

import styles from './OBSTooltip.module.css';

type OBSTooltipProps = {
  showOnDashboard?: boolean;
};

const OBSTooltip = ({ showOnDashboard }: OBSTooltipProps) => {
  const dispatch = useDispatch();
  const [isOBSModalOpen, setIsOBSModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isTooltipVisible, setIstooltipVisible] = useState(true);
  const isObsTooltipDismissed = useSelector(getObsTooltipDismissed);

  const handleCheckboxChange = async () => {
    setIsChecked(!isChecked);
  };

  const handleClose = () => {
    if (isChecked) {
      dispatch(updateOnboarding({ obs_tooltip_dismissed: true }));
      trackEvent(DONT_SHOW_AGAIN);
    }
    trackEvent(DISMISS);
    setIstooltipVisible(false);
  };

  if (isObsTooltipDismissed || !isTooltipVisible) return null;

  return (
    <div
      data-id="OBS:tooltip-container"
      className={cx(styles.container, {
        [styles.showOnDashboard]: showOnDashboard,
      })}
    >
      <div data-id="OBS:tooltip-header-container" className={styles.headline}>
        {t('dashboard.liquidity_chart.threshold.tooltip.headline')}
      </div>
      <div data-id="OBS:tooltip-description-container" className={styles.message}>
        {t('dashboard.liquidity_chart.threshold.tooltip.message')}
      </div>
      <div className={styles.acceptButtonContainer}>
        <Button
          dataId="OBS:button-tooltip-accept"
          className={styles.acceptButton}
          onClick={() => {
            trackEvent(OBS_OPEN_MODAL);
            setIsOBSModalOpen(true);
          }}
          centered
          label={t('dashboard.liquidity.apply_for_funding.button')}
        />
      </div>
      <Button
        dataId="OBS:tooltip-button-close"
        onClick={handleClose}
        label="×"
        appearance={ButtonAppearances.flat}
        className={styles.closeButton}
      />
      <div className={styles.checkboxContainer}>
        <RawCheckbox
          name={'OBSCheckBox'}
          onChange={handleCheckboxChange}
          checked={isChecked}
          dataId="OBS:tooltip-checkbox-dismiss"
        />
        <span>{t('dashboard.liquidity.apply_for_funding.checkbox_label')}</span>
      </div>
      <OBSModalFlow isOBSModalOpen={isOBSModalOpen} setIsOBSModalOpen={setIsOBSModalOpen} />
    </div>
  );
};

export { OBSTooltip };
