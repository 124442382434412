import {
  FETCH_OLD_LINE_CATEGORIES_FAILURE,
  FETCH_OLD_LINE_CATEGORIES_REQUEST,
  FETCH_OLD_LINE_CATEGORIES_SUCCESS,
} from 'constants/incoming-invoice';

const defaultState = [];

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_OLD_LINE_CATEGORIES_REQUEST:
    case FETCH_OLD_LINE_CATEGORIES_FAILURE:
      return state;

    case FETCH_OLD_LINE_CATEGORIES_SUCCESS:
      return action.response.data.map((i) => ({
        ...i.attributes,
        id: i.id,
      }));
    default:
      return state;
  }
};
