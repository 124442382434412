import normalize from 'json-api-normalizer';
import build from 'redux-object';

import EntityPath from 'constants/entitiesPaths';
import {
  FETCH_LINE_CATEGORIES_FAILURE,
  FETCH_LINE_CATEGORIES_REQUEST,
  FETCH_LINE_CATEGORIES_SUCCESS,
} from 'constants/incoming-invoice';
import { buildCategories } from 'components/CategorySearch/utils';

const defaultState = [];

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_LINE_CATEGORIES_REQUEST:
    case FETCH_LINE_CATEGORIES_FAILURE:
      return state;

    case FETCH_LINE_CATEGORIES_SUCCESS:
      const ids = action.response.data.map((item) => item.id);
      const incomingInvoiceExpenseCategories = buildCategories(
        build(normalize(action.response || {}), EntityPath.Categories, ids)
      );

      return incomingInvoiceExpenseCategories;

    default:
      return state;
  }
};
