import React, { memo } from 'react';

import { t } from 'shared/utils';

import styles from './DownloadPDF.module.css';

type DownloadPDFProps = {
  onDownloadPDF: () => void;
  isDisabled?: boolean;
};

const DownloadPDF = ({ onDownloadPDF, isDisabled }: DownloadPDFProps) => (
  <>
    <button
      className={styles.button}
      onClick={onDownloadPDF}
      data-id="Summary:downloadPdf-button"
      disabled={isDisabled}
    >
      {t('features.financial_planning.liquidity_calculator.summary.download.button')}
    </button>
    <div className={styles.tooltip}>
      <div className={styles.tooltipArrow} />
      <div className={styles.tooltipContent}>
        {t('features.financial_planning.liquidity_calculator.summary.download.tooltip')}
      </div>
    </div>
  </>
);

export default memo(DownloadPDF);
