import { t } from 'shared/utils';

export const documentOCRIncompliant = {
  duration: 6000,
  title: t('notifications.incoming_invoices_creator.document_ocr_incompliant.title'),
  text: t('notifications.incoming_invoices_creator.document_ocr_incompliant.text'),
  variant: 'attention',
};

export const documentTooBig = {
  duration: 3000,
  title: t('notifications.incoming_invoices_creator.document_too_big.title'),
  text: t('notifications.incoming_invoices_creator.document_too_big.text'),
  variant: 'warning',
};

export const documentFormatNotPDF = {
  duration: 3000,
  title: t('notifications.incoming_invoices_creator.document_format_not_pdf.title'),
  text: t('notifications.incoming_invoices_creator.document_format_not_pdf.text'),
  variant: 'warning',
};
