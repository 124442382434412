import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { func } from 'prop-types';

import { getReportDates as getReportDatesAction } from 'actions/datev';
import { InfoBox } from 'containers/Datev/DefaultInfoBox/DefaultInfoBox';
import paths from 'routes/paths';
import { t, toMoment } from 'shared/utils';
import HeaderWithEdit from 'components/HeaderWithEdit/HeaderWithEdit';

import styles from './DynamicExportDateBox.module.css';

const DynamicExportDateBox = ({ getReportDates, nextReportDueDate, taxationInterval }) => {
  useEffect(() => {
    getReportDates();
  }, [getReportDates]);

  const taxationDue = moment().isBetween(
    toMoment(nextReportDueDate).startOf('month'),
    toMoment(nextReportDueDate).add(1, 'day')
  );

  return (
    <InfoBox headingTextKey="datev.dynamic_date.date_available.heading" hasButton={taxationDue}>
      <HeaderWithEdit hasMargin={false} buttonPath={paths.taxConsultantProfile}>
        <p className={styles.infoEntry}>
          {t(`datev.dynamic_date.date_available.info_text.${taxationInterval}`)}
        </p>
      </HeaderWithEdit>
      <div className={styles.largeText} data-id="Datev:dateOfNextExport">
        {nextReportDueDate}
      </div>
    </InfoBox>
  );
};

DynamicExportDateBox.propTypes = {
  getReportDates: func,
};

const mapStateToProps = (state) => ({
  nextReportDueDate: state.datev.dates.nextReportDueDate,
  taxationInterval: state.company.details.taxationInterval,
});

const mapDispatchToProps = (dispatch) => ({
  getReportDates: () => dispatch(getReportDatesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DynamicExportDateBox);
