import React from 'react';
import cx from 'classnames';

import styles from './FormLayout.module.css';

interface FormColumnProps {
  children: React.ReactNode;
  withLabel?: boolean;
}

const FormColumn = ({ children, withLabel = false }: FormColumnProps) => (
  <div className={cx(styles.column, { [styles.withLabel]: withLabel })}>{children}</div>
);

export default FormColumn;
