import React, { ReactNode } from 'react';
import cx from 'classnames';

import styles from './Hint.module.css';

export enum Type {
  Info = 'info',
  Success = 'success',
}

export enum ArrowPosition {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

type HintProps = {
  children: ReactNode;
  type?: Type;
  arrowPosition?: ArrowPosition;
};

const Hint = ({ children, type = Type.Info, arrowPosition = ArrowPosition.Top }: HintProps) => (
  <div
    className={cx(
      styles.hint,
      {
        [styles.hintInfo]: type === Type.Info,
        [styles.hintSuccess]: type === Type.Success,
      },
      {
        [styles.hintTop]: arrowPosition === ArrowPosition.Top,
        [styles.hintRight]: arrowPosition === ArrowPosition.Right,
        [styles.hintBottom]: arrowPosition === ArrowPosition.Bottom,
        [styles.hintLeft]: arrowPosition === ArrowPosition.Left,
      }
    )}
  >
    <div className={styles.arrow} />
    <div className={styles.box}>{children}</div>
  </div>
);

export default Hint;
