import React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import UnitSelect from './UnitSelect';

interface FormUnitSelectProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
}

const FormUnitSelect = ({
  input: { value, onChange },
  meta: { error },
  ...restProps
}: FormUnitSelectProps) => (
  <UnitSelect
    {...restProps}
    initialValue={value}
    onValueChange={onChange}
    invalid={error && error.length >= 1}
  />
);

export default FormUnitSelect;
