import React from 'react';
import { bool } from 'prop-types';

import ExistIcon from 'images/icon-exist-white.svg';
import { t } from 'shared/utils';

import styles from '../Description.module.css';

const PremiumVrBankCustomer = () => (
  <>
    <div className={styles.vrBankPremiumDescription}>
      <div className={styles.descriptionWrapper}>
        <div className={styles.existIcon}>
          <img src={ExistIcon} alt="exist" />
        </div>
        <div className={styles.vrDescription}>
          <span>{t('profile.payment_plan.description_vr_bank')}</span>
        </div>
      </div>
      <div className={styles.price}>
        <span>{t('profile.payment_plan.comparison_box.monthly_price')}</span>
        <span>10,00</span>
        <span>€*</span>
      </div>
    </div>
    <span className={styles.tax}>{t('profile.payment_plan.tax')}</span>
  </>
);

const PremiumDescription = ({ vrMainBankAccount }) =>
  vrMainBankAccount ? (
    <PremiumVrBankCustomer />
  ) : (
    <span className={styles.tax}>{t('profile.payment_plan.tax')}</span>
  );

PremiumDescription.propTypes = {
  vrMainBankAccount: bool.isRequired,
};

export default PremiumDescription;
