import React, { useCallback, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import cx from 'classnames';
import { snakeCase } from 'lodash';
import { bool, node, number, shape, string } from 'prop-types';
import { change, Field } from 'redux-form';

import honorifics from 'constants/honorifics';
import { shape as countriesShape } from 'reducers/countries';
import { clientCreatorSelector } from 'reducers/form';
import { withCountries } from 'shared/hoc';
import { noop, t } from 'shared/utils';
import { checkLength, serverValidationChecker as checker } from 'shared/utils/form-checking';
import Alert, { ALERT_TYPES } from 'components/Alert/Alert';
import Button from 'components/Button';
import Card from 'components/Card';
import { SelectField, TextareaField, TextField } from 'components/Form';
import Counter from 'components/Form/Counter';
import Section, { SectionRow } from 'components/Form/Section/Section';
import I18n from 'components/I18n';
import MaskedField from 'components/v2/Form/MaskedField/MaskedField';
import DeleteIcon from 'redesign/components/atoms/Icons/Delete';

import styles from './CreateClientSection.module.css';

const honorificsOptions = honorifics.map((h) => ({ value: h, label: h }));
const MAX_NOTES_LENGTH = 400;

const ClientSelectField = (props) => {
  const { input } = props;
  return <SelectField {...props} input={input} onBlur={noop} searchable />;
};

ClientSelectField.propTypes = {
  input: shape({}).isRequired,
};

const FormField = ({
  name,
  label,
  placeholder,
  readonly,
  children,
  hint,
  hidePlaceholder,
  ...rest
}) => (
  <div className={styles.formGroup}>
    {children}
    {hint}
    <Field
      id={name}
      name={name}
      disabled={readonly}
      label={hint ? '' : label || t(`form.fields.${snakeCase(name)}`)}
      placeholder={hidePlaceholder ? '' : placeholder || t(`form.fields.${snakeCase(name)}`)}
      checker={checker}
      {...rest}
    />
  </div>
);

FormField.propTypes = {
  name: string.isRequired,
  label: string,
  placeholder: string,
  readonly: bool,
  children: node,
  hint: string,
  hidePlaceholder: bool,
};

const CreateClientSection = ({
  readonly,
  isFarmpilot,
  isEditing,
  countries,
  isHonorificRequired,
  isLastNameRequired,
  isCompanyNameRequired,
  notesLength,
  hasDeliveryAddress,
}) => {
  const dispatch = useDispatch();
  const [showDeviatingAddress, setShowDeviatingAddress] = useState(false);

  const handleDeleteDeviatingAddress = useCallback(() => {
    setShowDeviatingAddress(false);
    dispatch(change('clientCreator', 'deliveryName', null));
    dispatch(change('clientCreator', 'deliveryCity', null));
    dispatch(change('clientCreator', 'deliveryStreet', null));
    dispatch(change('clientCreator', 'deliveryZipcode', null));
    dispatch(change('clientCreator', 'deliveryCountry', null));
  }, [dispatch]);

  const handleAddDeviatingAddress = useCallback(() => {
    setShowDeviatingAddress(true);
    dispatch(change('clientCreator', 'deliveryCountry', 'DE'));
  }, [dispatch]);

  return (
    <Card className={styles.main}>
      <Card.Header>
        <I18n t={isEditing ? 'clients.form.header.edit' : 'clients.form.header.new'} />
      </Card.Header>
      <Card.Body>
        {isFarmpilot && (
          <Alert
            type={ALERT_TYPES.WARNING}
            customClassName={styles.alert}
            text={t('clients.form.farmpilot_warning')}
            dataId="ClientPage:farmpilot-warning"
          />
        )}
        <div className={cx(styles.columns, styles.columnsPadded)}>
          <div className={styles.column}>
            <Section title={t('clients.form.sections.clients_data')}>
              <SectionRow>
                <FormField
                  name="honorific"
                  dataId="input-honorific"
                  component={SelectField}
                  clearable
                  options={honorificsOptions}
                  placeholder={t('forms.placeholders.select')}
                  required={isHonorificRequired}
                  readonly={readonly}
                />
                <FormField
                  name="firstName"
                  dataId="input-first-name"
                  component={TextField}
                  readonly={readonly}
                />
                <FormField
                  name="lastName"
                  dataId="input-last-name"
                  component={TextField}
                  required={isLastNameRequired}
                  readonly={readonly}
                />
              </SectionRow>
              <SectionRow>
                <FormField
                  name="companyName"
                  dataId="input-company-name"
                  component={TextField}
                  required={isCompanyNameRequired}
                  readonly={readonly}
                />
                <FormField
                  name="addressAddition"
                  dataId="input-adress-addition"
                  component={TextField}
                  placeholder={t('form.fields.placeholders.address_addition')}
                  readonly={readonly}
                />
                <FormField
                  name="email"
                  dataId="input-email"
                  component={TextField}
                  readonly={readonly}
                />
                <FormField
                  name="website"
                  dataId="input-website"
                  component={TextField}
                  readonly={readonly}
                />
              </SectionRow>
              <SectionRow>
                <FormField
                  name="phoneNumber"
                  dataId="input-phone-number"
                  component={TextField}
                  readonly={readonly}
                />
                <FormField
                  name="faxNumber"
                  dataId="input-fax-number"
                  component={TextField}
                  readonly={readonly}
                />
                <FormField
                  name="vatId"
                  dataId="input-vat-id"
                  component={TextField}
                  readonly={readonly}
                />
                <FormField
                  name="einvoiceRecipientReference"
                  dataId="input-einvoice-recipient-reference"
                  component={TextField}
                  placeholder={t('form.fields.placeholders.einvoice_recipient_reference')}
                  readonly={readonly}
                />
                <FormField
                  name="idNumber"
                  label={t('form.fields.client_number')}
                  placeholder={t('form.fields.client_number')}
                  component={TextField}
                  readonly
                  disabledPlaceHolder={t('revenue.form.number_disabled')}
                />
              </SectionRow>
            </Section>
            <Section title={t('clients.form.sections.postal_address')}>
              <SectionRow className={styles.fullWidth}>
                <FormField
                  name="street"
                  dataId="input-street"
                  component={TextField}
                  readonly={readonly}
                  required
                />
              </SectionRow>
              <div className={styles.zipCodeSection}>
                <FormField
                  name="zipcode"
                  dataId="input-zip-code"
                  component={TextField}
                  maxLength="15"
                  readonly={readonly}
                  required
                />
                <FormField
                  name="city"
                  dataId="input-city"
                  component={TextField}
                  readonly={readonly}
                  required
                />
              </div>
              <SectionRow className={styles.fullWidth}>
                <FormField
                  name="country"
                  component={ClientSelectField}
                  options={countries.data}
                  isLoading={countries.isFetching}
                  readonly={readonly}
                  required
                />
              </SectionRow>
            </Section>
            <Section title={t('clients.form.sections.deviating_adress')}>
              {showDeviatingAddress || hasDeliveryAddress ? (
                <>
                  <SectionRow className={styles.fullWidth}>
                    <FormField
                      name="deliveryName"
                      dataId="input-street"
                      component={TextField}
                      readonly={readonly}
                      required
                    />
                  </SectionRow>
                  <SectionRow className={styles.fullWidth}>
                    <FormField
                      name="deliveryStreet"
                      dataId="input-street"
                      component={TextField}
                      readonly={readonly}
                      required
                    />
                  </SectionRow>
                  <div className={styles.zipCodeSection}>
                    <FormField
                      name="deliveryZipcode"
                      dataId="input-zip-code"
                      component={TextField}
                      maxLength="15"
                      readonly={readonly}
                      required
                    />
                    <FormField
                      name="deliveryCity"
                      dataId="input-city"
                      component={TextField}
                      readonly={readonly}
                      required
                    />
                  </div>
                  <SectionRow className={styles.fullWidth}>
                    <FormField
                      name="deliveryCountry"
                      component={ClientSelectField}
                      options={countries.data}
                      isLoading={countries.isFetching}
                      readonly={readonly}
                      required
                    />
                  </SectionRow>
                  <div className={styles.deleteSection}>
                    <div className={styles.deleteIcon}>
                      <DeleteIcon />
                    </div>
                    <button
                      type="button"
                      onClick={handleDeleteDeviatingAddress}
                      className={styles.deleteDeliveryAddressButton}
                    >
                      {t('clients.form.sections.delete_deviating_address')}
                    </button>
                  </div>
                </>
              ) : (
                <div>
                  <div className={styles.noDeviatedDeliveryAddress}>
                    <p>{t('clients.form.sections.no_deviated_address')}</p>
                  </div>
                  <div>
                    <Button
                      label={t('clients.form.sections.add_deviated_address')}
                      onClick={handleAddDeviatingAddress}
                    />
                  </div>
                </div>
              )}
            </Section>

            <Section title={t('clients.form.sections.bank_data')}>
              <SectionRow>
                <FormField
                  name="bankName"
                  dataId="input-bank-name"
                  component={TextField}
                  readonly={readonly}
                />
                <FormField
                  name="iban"
                  dataId="input-iban"
                  component={MaskedField}
                  readonly={readonly}
                  mask="aa** **** **** **** **** **** **** **** **"
                  maskChar=" "
                />
                <FormField
                  name="bic"
                  dataId="input-bic"
                  component={TextField}
                  readonly={readonly}
                />
              </SectionRow>
            </Section>
            <Section title={t('suppliers.form.sections.other')}>
              <SectionRow className={styles.longField}>
                <FormField
                  name="notes"
                  dataId="input-notes"
                  component={TextareaField}
                  readonly={readonly}
                  maxLength={MAX_NOTES_LENGTH}
                  hidePlaceholder
                  hint={
                    <span className={styles.hint}>
                      {t('clients.form.sections.notes_description')}
                    </span>
                  }
                >
                  <div className={styles.notes}>
                    {t('clients.form.sections.notes_header')}
                    <Counter value={notesLength} max={MAX_NOTES_LENGTH} />
                  </div>
                </FormField>
              </SectionRow>
            </Section>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

CreateClientSection.propTypes = {
  readonly: bool,
  isEditing: bool,
  isFarmpilot: bool,
  isHonorificRequired: bool,
  isLastNameRequired: bool,
  isCompanyNameRequired: bool,
  notesLength: number,
  countries: countriesShape,
  hasDeliveryAddress: bool,
};

export default connect((state) => ({
  isHonorificRequired: Boolean(clientCreatorSelector(state, 'lastName')),
  isLastNameRequired: !clientCreatorSelector(state, 'companyName'),
  isCompanyNameRequired: !clientCreatorSelector(state, 'lastName'),
  notesLength: checkLength(clientCreatorSelector(state, 'notes')),
  hasDeliveryAddress: !!(
    clientCreatorSelector(state, 'deliveryName') &&
    clientCreatorSelector(state, 'deliveryStreet') &&
    clientCreatorSelector(state, 'deliveryZipcode') &&
    clientCreatorSelector(state, 'deliveryCountry') &&
    clientCreatorSelector(state, 'deliveryCity')
  ),
}))(withCountries(CreateClientSection));
