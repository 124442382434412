import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import { LOGOUT_SUCCESS } from 'constants/auth';
import datevExportReducer from 'containers/Datev/shared/DatevPreview/datevExportSlice';
import appSettingsReducer from 'reducers/app-settings';
import appUploadsReducer from 'reducers/app-uploads';
import authReducer from 'reducers/auth';
import bankAccountReducer from 'reducers/bank-account';
import bankDetailReducer from 'reducers/bank-details';
import bankTransfersReducer from 'reducers/bank-transfers';
import banksReducer from 'reducers/banks';
import clientsReducer from 'reducers/clients';
import companyReducer from 'reducers/company';
import confirmationBannerReducer from 'reducers/confirmation-banner';
import contentsReducer from 'reducers/contents';
import oldContractsReducer from 'reducers/contracts';
import countriesReducer from 'reducers/countries';
import customerNotificationsReducer from 'reducers/customer-notifications';
import datevReducer from 'reducers/datev';
import datevDownloadReducer from 'reducers/datev-download';
import defaultValuesReducer from 'reducers/default-values';
import deliveryNoteReducer from 'reducers/delivery-note';
import deliveryNotesReducer from 'reducers/delivery-notes';
import directDebitReducer from 'reducers/direct-debit';
import externalOriginCountReducer from 'reducers/external-origin-count';
import formReducer from 'reducers/form';
import grecaptchaReducer from 'reducers/grecaptcha';
import incomingInvoiceReducer from 'reducers/incoming-invoice';
import incomingInvoicesReducer from 'reducers/incoming-invoices';
import unpaidInternalSubscriptionFeesReducer from 'reducers/internal-subscription-fees';
import invoiceTemplatesReducer from 'reducers/invoice-templates';
import liquidityReducer from 'reducers/liquidity';
import menuReducer from 'reducers/menu';
import messageInboxReducer from 'reducers/message-inbox';
import monthlyOverviewReducer from 'reducers/monthly-overview';
import notificationsReducer from 'reducers/notifications';
import notificationsSidebarReducer from 'reducers/notifications-sidebar';
import numberRangesReducer from 'reducers/number-ranges';
import orderConfirmationReducer from 'reducers/order-confirmation';
import orderConfirmationsReducer from 'reducers/order-confirmations';
import outgoingInvoiceReducer from 'reducers/outgoing-invoice';
import outgoingInvoicesReducer from 'reducers/outgoing-invoices';
import paymentPlanReducer from 'reducers/payment-plan';
import paymentPlansReducer from 'reducers/payment-plans';
import piwikReducer from 'reducers/piwik';
import productCatalogGroupsReducer from 'reducers/product-catalog/groups';
import productCatalogItemsReducer from 'reducers/product-catalog/items';
import profileReducer from 'reducers/profile';
import proposalReducer from 'reducers/proposal';
import proposalsReducer from 'reducers/proposals';
import quarterlySummaryReducer from 'reducers/quarterly-summary';
import registrationReducer from 'reducers/registration';
import registrationRequestReducer from 'reducers/registration-request';
import summaryReducer from 'reducers/summary';
import suppliersReducer from 'reducers/suppliers';
import taxConsultantReducer from 'reducers/tax-consultant';
import unitsReducer from 'reducers/units';
import { history } from 'routes/history';
import contractsReducer from 'features/contracts/contractsSlice';
import liquidityCalculatorReducer from 'features/financialPlanning/liquidityCalculator/liquidityCalculatorSlice';
import paginationPreferencesReducer from 'features/paginationPreferences/paginationPreferencesSlice';

import { name as DashboardName } from './slices/dashboard/constants';
import { reducer as DashboardReducer } from './slices/dashboard/reducer';
import { name as DatevRangePickerName } from './slices/dateRangePicker/constants';
import { reducer as DatevRangePickerReducer } from './slices/dateRangePicker/reducer';
import { name as GlobalNotificationsName } from './slices/globalNotifications/constants';
import { reducer as GlobalNotificationsReducer } from './slices/globalNotifications/reducer';
import { name as OnboardingName } from './slices/onboarding/constants';
import { reducer as OnboardingReducer } from './slices/onboarding/reducer';
import { name as WelcomeTileName } from './slices/welcomeTile/constants';
import { reducer as WelcomeTileReducer } from './slices/welcomeTile/reducer';

const rootReducer = combineReducers({
  appSettings: appSettingsReducer,
  appUploads: appUploadsReducer,
  auth: authReducer,
  bankAccount: bankAccountReducer,
  bankDetails: bankDetailReducer,
  banks: banksReducer,
  bankTransfers: bankTransfersReducer,
  clients: clientsReducer,
  company: companyReducer,
  confirmationBanner: confirmationBannerReducer,
  contents: contentsReducer,
  contracts: contractsReducer,
  countries: countriesReducer,
  customerNotifications: customerNotificationsReducer,
  datev: datevReducer,
  datevDownload: datevDownloadReducer,
  datevExport: datevExportReducer,
  defaultValues: defaultValuesReducer,
  deliveryNote: deliveryNoteReducer,
  deliveryNotes: deliveryNotesReducer,
  directDebit: directDebitReducer,
  externalOriginCount: externalOriginCountReducer,
  form: formReducer,
  grecaptcha: grecaptchaReducer,
  incomingInvoices: incomingInvoicesReducer,
  incomingInvoice: incomingInvoiceReducer,
  invoiceTemplates: invoiceTemplatesReducer,
  liquidity: liquidityReducer,
  liquidityCalculator: liquidityCalculatorReducer,
  menu: menuReducer,
  messageInbox: messageInboxReducer,
  monthlyOverview: monthlyOverviewReducer,
  notifications: notificationsReducer,
  notificationsSidebar: notificationsSidebarReducer,
  numberRanges: numberRangesReducer,
  // TODO when old contracts reducer will be moved to slice, remember to remove this line
  oldContracts: oldContractsReducer,
  orderConfirmation: orderConfirmationReducer,
  orderConfirmations: orderConfirmationsReducer,
  outgoingInvoices: outgoingInvoicesReducer,
  outgoingInvoice: outgoingInvoiceReducer,
  paginationPreferences: paginationPreferencesReducer,
  paymentPlan: paymentPlanReducer,
  paymentPlans: paymentPlansReducer,
  piwik: piwikReducer,
  profile: profileReducer,
  productCatalogGroups: productCatalogGroupsReducer,
  productCatalogItems: productCatalogItemsReducer,
  proposal: proposalReducer,
  proposals: proposalsReducer,
  quarterlySummary: quarterlySummaryReducer,
  registration: registrationReducer,
  registrationRequest: registrationRequestReducer,
  router: connectRouter(history),
  summary: summaryReducer,
  suppliers: suppliersReducer,
  taxConsultant: taxConsultantReducer,
  units: unitsReducer,
  unpaidInternalSubscriptionFees: unpaidInternalSubscriptionFeesReducer,

  [OnboardingName]: OnboardingReducer,
  [WelcomeTileName]: WelcomeTileReducer,
  [GlobalNotificationsName]: GlobalNotificationsReducer,
  [DatevRangePickerName]: DatevRangePickerReducer,
  [DashboardName]: DashboardReducer,
});

export default (state: any, action: any) => {
  // TODO: Find a better way to clear store after logout
  if (action.type === LOGOUT_SUCCESS) {
    state = {
      router: state.router,
      confirmationBanner: state.confirmationBanner,
      auth: state.auth,
      [WelcomeTileName]: state[WelcomeTileName],
      [GlobalNotificationsName]: state[GlobalNotificationsName],
    };
  }

  return rootReducer(state, action);
};
