import React from 'react';

import I18n from 'components/I18n';

import paymentsStyles from '../../InvoicePaymentSection.module.css';
import styles from './NoPaymentsPlaceholder.module.css';

const NoPaymentsPlaceholder = () => (
  <div className={styles.container}>
    <I18n className={paymentsStyles.placeholderText} t="expenses.payments.no_payments" />
  </div>
);

export default NoPaymentsPlaceholder;
