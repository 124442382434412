import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import paths from 'routes/paths';
import { t } from 'shared/utils';

import IconSettings from './IconSettings';

import styles from './WelcomeTile.module.css';

const Settings = () => (
  <>
    <IconSettings className={cx(styles.icon, styles.iconSettings)} />
    <div className={styles.header}>3. {t('contracts.welcome_tile.columns.settings.header')}</div>
    <div className={styles.description}>
      {t('contracts.welcome_tile.columns.settings.description.1')}
      <Link to={paths.personalProfile}>
        {t('contracts.welcome_tile.columns.settings.description.2')}
      </Link>
      {t('contracts.welcome_tile.columns.settings.description.3')}
    </div>
  </>
);

export default memo(Settings);
