import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import { documentLayoutEnabledHelper, invoiceLayoutEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { useAppSelector } from 'shared/hooks/app';
import { t } from 'shared/utils';
import Tab from 'redesign/components/atoms/Tab/Tab';

import { Box, Tabs, Title, Wrapper } from './DocumentTemplate.styled';
import Routes from './Routes';

const DocumentTemplate = () => {
  const history = useHistory();
  const location = useLocation();
  const [tab, setTab] = useState(location.pathname);
  const isDocumentLayoutEnabled = useAppSelector(documentLayoutEnabledHelper);
  const isInvoiceLayoutEnabled = useAppSelector(invoiceLayoutEnabledHelper);

  const handleTabChange = useCallback(
    (_event, value: any) => {
      history.push(value);
    },
    [history]
  );

  useUpdateEffect(() => {
    setTab(location.pathname);
  }, [location.pathname]);

  return (
    <Wrapper>
      <Title>{t('features.settings.document_template.title')}</Title>
      <Box>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab value={paths.settingsTemplateLogoAndBriefpapier}>
            {t(
              `features.settings.document_template.tabs.${
                isInvoiceLayoutEnabled ? 'logo_and_briefpapier' : 'logo'
              }`
            )}
          </Tab>
          {isDocumentLayoutEnabled && (
            <Tab value={paths.settingsTemplateLayout}>
              {t('features.settings.document_template.tabs.layout')}
            </Tab>
          )}
          <Tab value={paths.settingsTemplateEdit}>
            {t('features.settings.document_template.tabs.data')}
          </Tab>
          {isDocumentLayoutEnabled && (
            <Tab value={paths.settingsTemplateTable}>
              {t('features.settings.document_template.tabs.table')}
            </Tab>
          )}
          <Tab value={paths.settingsTemplateDocumentsDefaults}>
            {t('features.settings.document_template.tabs.document_texts')}
          </Tab>
          <Tab value={paths.settingsTemplateEmailDefaults}>
            {t('features.settings.document_template.tabs.email_texts')}
          </Tab>
        </Tabs>
        <Routes />
      </Box>
    </Wrapper>
  );
};

export default DocumentTemplate;
