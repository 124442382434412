import {
  CREATE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
} from 'constants/direct-debit';

const defaultState = {
  details: {},
  error: false,
  isFetching: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        error: false,
        isFetching: true,
      };

    case FETCH_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };

    case FETCH_SUCCESS:
    case CREATE_SUCCESS:
      return {
        ...defaultState,
        details: {
          ...state.details,
          ...action.response.data.attributes,
        },
      };
    default:
      return state;
  }
};
