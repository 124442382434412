import React from 'react';
import { Portal } from 'react-portal';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';

const withOverlayLoader = (
  Component,
  {
    staticIconPosition = false,
    fixedPosition = false,
    alwaysOnTop = false,
    enlarged = false,
    misty = false,
    text = '',
  } = {}
) => {
  function WithOverlayLoader({ isLoading, ...rest }) {
    return (
      <React.Fragment>
        {isLoading && (
          <Portal>
            <Loading
              staticIconPosition={staticIconPosition}
              fixedPosition={fixedPosition}
              alwaysOnTop={alwaysOnTop}
              enlarged={enlarged}
              misty={misty}
              text={text}
            />
          </Portal>
        )}
        <Component isLoading={isLoading} {...rest} />
      </React.Fragment>
    );
  }
  WithOverlayLoader.propTypes = {
    isLoading: PropTypes.bool,
  };

  const withOverlayLoaderName = Component.displayName || Component.name || 'Component';

  WithOverlayLoader.displayName = `withOverlayLoader(${withOverlayLoaderName})`;
  return WithOverlayLoader;
};
export default withOverlayLoader;
