import React from 'react';

import { t } from 'shared/utils';
import CancelledStatusIcon from 'components/StatusIcons/Invoice/CancelledStatusIcon';
import DraftStatusIcon from 'components/StatusIcons/Invoice/DraftStatusIcon';
import PaidStatusIcon from 'components/StatusIcons/Invoice/PaidStatusIcon';

import { OrderConfirmationStatus } from '../types';
import { Status, Text } from './StatusBadge.styled';

type StatusBadgeProps = {
  status: OrderConfirmationStatus;
  dataId?: string;
};

const getStatusIcon = (status: OrderConfirmationStatus) => {
  switch (status) {
    case OrderConfirmationStatus.DRAFT:
      return <DraftStatusIcon />;
    case OrderConfirmationStatus.SENT:
      return <PaidStatusIcon />;
    case OrderConfirmationStatus.CANCELLED:
      return <CancelledStatusIcon />;
    default:
      return null;
  }
};

const StatusBadge = ({ status, dataId = 'status' }: StatusBadgeProps) => {
  if (!status) return null;

  return (
    <Status status={status}>
      {getStatusIcon(status)}
      <Text data-id={dataId}>{t(`order_confirmations.status.${status}`)}</Text>
    </Status>
  );
};

export default StatusBadge;
