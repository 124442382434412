import styled from 'styled-components';

import { Breakpoints } from 'redesign/styles/breakpoints';

export const Main = styled.main`
  display: flex;

  & > aside {
    width: 0;

    @media (min-width: ${Breakpoints.lg}) and (any-hover: hover) and (any-pointer: fine) {
      width: 56px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 72px);

  > div:first-child {
    flex-shrink: 0;
  }

  > div:last-child {
    position: relative;
    flex: 1;
  }
`;
