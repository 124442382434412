import React, { Component } from 'react';
import { throttle } from 'lodash';

import { isLgUp, isMdUp, isSmUp, isXlgUp, isXxlgUp } from 'shared/utils/breakpoints';

// TODO remove this HOC, use hooks instead
const withViewport = (Comp) => {
  class WithViewport extends Component {
    state = {
      isSmUp: isSmUp(),
      isMdUp: isMdUp(),
      isLgUp: isLgUp(),
      isXlgUp: isXlgUp(),
      isXxlgUp: isXxlgUp(),
    };

    componentDidMount() {
      window.addEventListener('resize', this.compareViewport);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.compareViewport);
    }

    compareViewport = throttle(() => {
      const isSmUpNow = isSmUp();
      const isMdUpNow = isMdUp();
      const isLgUpNow = isLgUp();
      const isXlgUpNow = isXlgUp();
      const isXxlgUpNow = isXxlgUp();
      if (this.state.isSmUp !== isSmUpNow) {
        this.setState({ isSmUp: isSmUpNow });
      }
      if (this.state.isMdUp !== isMdUpNow) {
        this.setState({ isMdUp: isMdUpNow });
      }
      if (this.state.isLgUp !== isLgUpNow) {
        this.setState({ isLgUp: isLgUpNow });
      }
      if (this.state.isXlgup !== isXlgUpNow) {
        this.setState({ isXlgUp: isXlgUpNow });
      }
      if (this.state.isXxlgUp !== isXxlgUpNow) {
        this.setState({ isXxlgUp: isXxlgUpNow });
      }
    }, 50);

    render() {
      return (
        <Comp
          {...this.props}
          isSmUp={this.state.isSmUp}
          isMdUp={this.state.isMdUp}
          isLgUp={this.state.isLgUp}
          isXlgUp={this.state.isXlgUp}
          isXxlgUp={this.state.isXxlgUp}
        />
      );
    }
  }

  const displayName = Comp.displayName || Comp.name || 'Component';

  WithViewport.displayName = `withViewport(${displayName})`;
  return WithViewport;
};
export default withViewport;
