import { getFarmpilotAppId } from 'selectors/app-settings';

const getFarmpilotConnectionSettings = (state, farmpilotAppId) =>
  state.profile.externalAppConnections[farmpilotAppId];

export const isFarmpilotTokenConnected = (state) => {
  const farmpilotAppId = getFarmpilotAppId(state);

  return !!getFarmpilotConnectionSettings(state, farmpilotAppId);
};

export const isFarmpilotCustomerConnectionActive = (state) => {
  const farmpilotAppId = getFarmpilotAppId(state);

  const settings = getFarmpilotConnectionSettings(state, farmpilotAppId);
  if (!settings) return false;

  return settings.active;
};

export const getFarmpilotCustomerId = (state) => {
  const farmpilotAppId = getFarmpilotAppId(state);

  const settings = getFarmpilotConnectionSettings(state, farmpilotAppId);
  if (!settings) return null;

  return settings.customerId;
};
