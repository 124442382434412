import React from 'react';
import cx from 'classnames';
import { func, string } from 'prop-types';

import isPressedEnter from 'shared/utils/keyboard-events';

import styles from './ImagePreview.module.css';

const ImagePreview = ({ url, onClick, notifyRenderFinish, imageClassName = '' }) => {
  const Image = (
    <img
      className={cx(styles.preview, imageClassName)}
      onLoad={notifyRenderFinish}
      alt=""
      src={url}
    />
  );

  return onClick ? (
    <div
      className={styles.previewWrapper}
      role="button"
      tabIndex="0"
      onKeyPress={isPressedEnter(onClick)}
      onClick={onClick}
    >
      {Image}
    </div>
  ) : (
    <div className={styles.previewWrapper}>{Image}</div>
  );
};

ImagePreview.propTypes = {
  url: string.isRequired,
  onClick: func,
  notifyRenderFinish: func,
  imageClassName: string,
};

export default ImagePreview;
