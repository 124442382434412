import { t } from 'shared/utils';

export const OPTION_PRIVATE_TRANSACTION = {
  value: 'private-transaction',
  apiValue: 'private_transaction',
  label: 'bank_transfers.label_assignment.modal.select_options.private_transaction',
  description: 'bank_transfers.label_assignment.modal.descritpions.private_transaction',
};

export const OPTION_INTERNAL_TRANSFER = {
  value: 'internal-transfer',
  apiValue: 'internal_transfer',
  label: 'bank_transfers.label_assignment.modal.select_options.internal_transfer',
  description: 'bank_transfers.label_assignment.modal.descritpions.internal_transfer',
};

export const OPTION_SALARIES = {
  value: 'salaries',
  apiValue: 'salaries',
  label: 'bank_transfers.label_assignment.modal.select_options.salaries',
  description: 'bank_transfers.label_assignment.modal.descritpions.salaries',
};

export const OPTION_RENT = {
  value: 'rent',
  apiValue: 'rent',
  label: 'bank_transfers.label_assignment.modal.select_options.rent',
  description: 'bank_transfers.label_assignment.modal.descritpions.rent',
};

export const OPTION_OTHER = {
  value: 'other',
  apiValue: 'other',
  label: 'bank_transfers.label_assignment.modal.select_options.other',
  description: 'bank_transfers.label_assignment.modal.descritpions.other',
};

export const POSTIVE_BANK_TRANSFER = [
  OPTION_PRIVATE_TRANSACTION,
  OPTION_OTHER,
  OPTION_INTERNAL_TRANSFER,
];

export const NEGATIVE_BANK_TRANSFER = [
  OPTION_PRIVATE_TRANSACTION,
  OPTION_SALARIES,
  OPTION_RENT,
  OPTION_OTHER,
  OPTION_INTERNAL_TRANSFER,
];

export const TRANSLATE_POSTIVE_BANK_TRANSFER = POSTIVE_BANK_TRANSFER.map((option) => ({
  ...option,
  label: t(option.label),
}));

export const TRANSLATE_NEGATIVE_BANK_TRANSFER = NEGATIVE_BANK_TRANSFER.map((option) => ({
  ...option,
  label: t(option.label),
}));

export const TRANSLATE_OPTION_ARRAY = [
  ...TRANSLATE_POSTIVE_BANK_TRANSFER,
  ...TRANSLATE_NEGATIVE_BANK_TRANSFER,
];
