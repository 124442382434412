import React, { memo, SVGProps } from 'react';

const Home = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 23" {...props}>
    <defs>
      <filter id="Home_svg__a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g transform="translate(-16 -17)" filter="url(#Home_svg__a)" fill="none" fillRule="evenodd">
      <path
        d="M26.106 37.167v-6.004h4.783v6.004c0 .66.538 1.2 1.195 1.2h3.587c.658 0 1.196-.54 1.196-1.2V28.76h2.032c.55 0 .813-.685.395-1.045l-9.996-9.042a1.201 1.201 0 00-1.602 0l-9.995 9.042c-.407.36-.156 1.045.394 1.045h2.033v8.406c0 .66.538 1.2 1.195 1.2h3.587c.658 0 1.196-.54 1.196-1.2z"
        fill="#FAFAFA"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(Home);
