import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

import { adapter } from './adapter';
import { name } from './constants';

const slice = (state: RootState) => state[name];

const selectors = adapter.getSelectors();

export const getEntities = createSelector(slice, (slice) => selectors.selectEntities(slice));
