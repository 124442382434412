import React from 'react';
import { snakeCase } from 'lodash';
import { Fields } from 'redux-form';

import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import PasswordField from 'components/Form/PasswordField';
import { PasswordFieldProps } from 'components/Form/PasswordField/PasswordField.types';

interface NewPasswordFieldProps extends PasswordFieldProps {
  name: string;
}

const NewPasswordField = ({ name, ...rest }: NewPasswordFieldProps) => (
  <Fields
    name={name}
    id={name}
    checker={serverValidationChecker}
    component={PasswordField}
    label={t(`profile.personal.${snakeCase(name)}`)}
    names={['password', 'passwordConfirm']}
    type="password"
    dataId={`${name}`}
    {...rest}
  />
);

export default NewPasswordField;
