import React from 'react';
import { Row } from 'react-flexbox-grid';
import cx from 'classnames';
import { node, string } from 'prop-types';

import styles from './TableSubRow.module.css';

export const TableSubRow = ({ className = '', children, ...rest }) => (
  <Row {...rest} className={cx(styles.row, className)}>
    {children}
  </Row>
);

TableSubRow.propTypes = {
  className: string,
  children: node,
  size: string,
  align: string,
};
