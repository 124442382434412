import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { SubmissionError } from 'redux-form';

import { initPayment as initPaymentAction } from 'actions/incoming-invoice/payments';
import { ACTION_NEW_PAYMENT } from 'constants/piwik';
import { InvoiceStatuses } from 'constants/statuses';
import { incomingInvoiceDetailsSelector } from 'selectors/incomingInvoice';
import { t } from 'shared/utils';
import { trackPaymentEvent } from 'shared/utils/tracking/payments-tracking';
import Button from 'components/Button';
import Card from 'components/Card';
import I18n from 'components/I18n';

import InvoiceFullyPaidPlaceholder from './components/InvoiceFullyPaidPlaceholder/InvoiceFullyPaidPlaceholder';
import NoBankAccountsPlaceholder from './components/NoBankAccountsPlaceholder/NoBankAccountsPlaceholder';
import NoPaymentsPlaceholder from './components/NoPaymentsPlaceholder/NoBankAccountsPlaceholder';
import UnsavedInvoiceModal from './components/UnsavedInvoiceModal/UnsavedInvoiceModal';
import PaymentCreator from './PaymentCreator/PaymentCreator';
import PaymentsTable from './PaymentsTable/PaymentsTable';

import styles from './InvoicePaymentSection.module.css';

const successStatus = 201;

class InvoicePaymentSection extends Component {
  static defaultProps = {
    payments: [],
  };

  state = {
    isCreatorVisible: false,
    isUnsavedInvoiceModalVisible: false,
    initialMount: true,
  };

  componentDidUpdate(prevProps) {
    const { redirectedFromSavedDraftInvoice, redirectedFromDatevModal } = this.props;

    const isRouterStateFromPersist =
      redirectedFromSavedDraftInvoice === prevProps.redirectedFromSavedDraftInvoice &&
      redirectedFromDatevModal === prevProps.redirectedFromDatevModal;

    if (
      this.state.initialMount &&
      (redirectedFromSavedDraftInvoice || redirectedFromDatevModal) &&
      !isRouterStateFromPersist &&
      !this.cantAddPayment &&
      !this.props.isDatevModalOpen
    ) {
      this.initPayment();
    }
  }

  get paymentsAmount() {
    return this.props.payments
      .map((payment) => parseFloat(payment.amount))
      .reduce((sum, amount) => sum + amount, 0);
  }

  get paymentsConditions() {
    const { payments, paymentsBankAccounts, invoiceAmount, invoiceStatus } = this.props;
    return {
      hasNoAccounts: isEmpty(paymentsBankAccounts),
      hasNoPayments: isEmpty(payments),
      isInvoicePaidByPayments: this.paymentsAmount >= invoiceAmount,
      isInvoicePaidByTransfers: invoiceStatus === InvoiceStatuses.PAID,
    };
  }

  get cantAddPayment() {
    const { readonly, invoiceAmount } = this.props;
    const { hasNoAccounts, isInvoicePaidByPayments, isInvoicePaidByTransfers } =
      this.paymentsConditions;

    return (
      readonly ||
      hasNoAccounts ||
      (invoiceAmount > 0 && (isInvoicePaidByPayments || isInvoicePaidByTransfers))
    );
  }

  setCreatorVisible = (isCreatorVisible) =>
    this.setState({ isCreatorVisible, initialMount: false });

  handleInit = async (data) => {
    const { initPayment, invoiceId } = this.props;
    const { response, errors } = await initPayment(invoiceId)(data);
    if (errors) throw new SubmissionError(errors);
    return response;
  };

  initPayment = async () => {
    const { handleSubmit, isInvoiceDraft } = this.props;
    const { status } = await handleSubmit(this.handleInit)();

    if (!(status === successStatus)) return null;
    if (isInvoiceDraft) return this.handleShowModal();
    return this.setCreatorVisible(true);
  };

  handleShowModal = () =>
    this.setState({
      isUnsavedInvoiceModalVisible: true,
    });

  handleCloseModal = () =>
    this.setState({
      isUnsavedInvoiceModalVisible: false,
    });

  cancelPayment = () => this.setCreatorVisible(false);

  finishPayment = () => this.setCreatorVisible(false);

  sortPayments = () => {
    const { payments } = this.props;
    const { hasNoPayments } = this.paymentsConditions;

    if (hasNoPayments) return payments;

    return payments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  };

  renderContent = () => {
    const { hasNoAccounts, hasNoPayments, isInvoicePaidByPayments } = this.paymentsConditions;
    if (hasNoAccounts && hasNoPayments) return <NoBankAccountsPlaceholder />;
    if (hasNoPayments) return <NoPaymentsPlaceholder />;

    const paymentsSortedDescByDate = this.sortPayments();

    return (
      <div>
        {isInvoicePaidByPayments && <InvoiceFullyPaidPlaceholder />}
        {hasNoAccounts && <NoBankAccountsPlaceholder hasMargin />}
        <PaymentsTable payments={paymentsSortedDescByDate} />
      </div>
    );
  };

  render() {
    const { readonly, saveDraftInvoice } = this.props;
    const { isCreatorVisible, isUnsavedInvoiceModalVisible } = this.state;

    return (
      <Card className={cx(styles.main, { [styles.readonly]: readonly })}>
        <Card.Header className={styles.heading}>
          <div className={styles.headingNameContainer}>
            <I18n className={styles.title} t="expenses.payments.heading" />
          </div>
          <div className={styles.headingItemsContainer}>
            <Button
              label={t('expenses.payments.add_payment')}
              disabled={this.cantAddPayment}
              className={styles.newPaymentButton}
              onClick={this.initPayment}
              onClickSideAction={trackPaymentEvent(ACTION_NEW_PAYMENT)}
              dataId="FigoPayments:button-open-payment-modal"
            />
          </div>
        </Card.Header>
        <Card.Body className={styles.cardBody}>
          <div className={styles.contentWrapper}>{this.renderContent()}</div>
        </Card.Body>
        <PaymentCreator
          isOpen={isCreatorVisible}
          cancelPayment={this.cancelPayment}
          finishPayment={this.finishPayment}
        />
        {isUnsavedInvoiceModalVisible && (
          <UnsavedInvoiceModal
            isOpen={isUnsavedInvoiceModalVisible}
            onAccept={() => {
              saveDraftInvoice();
              this.handleCloseModal();
            }}
            onClose={this.handleCloseModal}
          />
        )}
      </Card>
    );
  }
}

InvoicePaymentSection.propTypes = {
  readonly: bool.isRequired,
  payments: arrayOf(shape({})),
  paymentsBankAccounts: arrayOf(shape({})).isRequired,
  invoiceAmount: number.isRequired,
  invoiceStatus: string.isRequired,
  initPayment: func.isRequired,
  handleSubmit: func.isRequired,
  isInvoiceDraft: bool.isRequired,
  saveDraftInvoice: func.isRequired,
  redirectedFromSavedDraftInvoice: bool,
  redirectedFromDatevModal: bool,
  isDatevModalOpen: bool,
  invoiceId: number.isRequired,
};

const mapStateToProps = (state) => ({
  invoiceId: incomingInvoiceDetailsSelector(state).id,
  payments: state.incomingInvoice.payments.list,
  paymentsBankAccounts: state.incomingInvoice.paymentsBankAccounts.list,
  invoiceAmount: incomingInvoiceDetailsSelector(state).totalGrossAmount,
  invoiceStatus: incomingInvoiceDetailsSelector(state).status,
});

const mapDispatchToProps = (dispatch) => ({
  initPayment: (id) => (data) => dispatch(initPaymentAction(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePaymentSection);
