import React, {
  ComponentType,
  MemoExoticComponent,
  MouseEventHandler,
  ReactElement,
  SVGProps,
} from 'react';

import { t } from 'shared/utils';

import { Button, DoNotShowAgain, Icon, IconClose, Text, Wrapper } from './Infobar.styled';

export type InfobarProps = {
  color: 'red' | 'orange' | 'blue' | 'green';
  title: string;
  description: string;
  buttonText?: string;
  buttonIsDisabled?: boolean;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  onDoNotShowAgainClick?: MouseEventHandler<HTMLButtonElement>;
  onCloseClick?: MouseEventHandler<SVGSVGElement>;
  icon?:
    | ComponentType<SVGProps<SVGSVGElement>>
    | MemoExoticComponent<(props: SVGProps<SVGSVGElement>) => ReactElement>;
  dataId?: string;
  className?: string;
};

const Infobar = ({
  color,
  title,
  description,
  buttonText,
  buttonIsDisabled,
  onButtonClick,
  onCloseClick,
  onDoNotShowAgainClick,
  icon: InfobarIcon,
  dataId,
  className,
}: InfobarProps) => {
  return (
    <Wrapper
      $color={color}
      $hasIcon={!!InfobarIcon}
      $hasDoNotShowAgain={!!onDoNotShowAgainClick}
      $hasButton={!!buttonText}
      data-id={dataId}
      className={className}
    >
      {InfobarIcon && <Icon as={InfobarIcon} data-id="icon" />}
      <Text data-id="text">
        {title && <strong>{title}</strong>} {description}
      </Text>
      {buttonText && (
        <Button
          $variant="outlined"
          $color="white"
          onClick={onButtonClick}
          type="button"
          disabled={buttonIsDisabled}
          data-id="button"
        >
          {buttonText}
        </Button>
      )}
      <IconClose onClick={onCloseClick} width={32} height={32} data-id="close" />
      {onDoNotShowAgainClick && (
        <DoNotShowAgain type="button" onClick={onDoNotShowAgainClick} data-id="do-not-show-again">
          {t('redesign.atoms.infobar.do_not_show_again')}
        </DoNotShowAgain>
      )}
    </Wrapper>
  );
};

export default Infobar;
