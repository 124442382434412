import React from 'react';
import cx from 'classnames';

import styles from './CardView.module.css';

interface SectionProps {
  children: React.ReactNode;
  className?: string;
  dataId?: string;
}

interface HeadingSectionProps {
  children: React.ReactNode;
  className?: string;
  bold?: boolean;
  dataId?: string;
}

interface CenterViewProps {
  children: React.ReactNode;
  className?: string;
}

export const Section = ({ children, className, dataId }: SectionProps) => (
  <div data-id={dataId} className={cx(styles.section, className)}>
    {children}
  </div>
);

export const HeadingSection = ({
  children,
  className = '',
  bold = false,
  dataId = '',
}: HeadingSectionProps) => (
  <div
    data-id={dataId}
    className={cx(styles.section, styles.heading, className, {
      [styles.headingBold]: bold,
    })}
  >
    {children}
  </div>
);

export const CenterView = ({ children, className }: CenterViewProps) => (
  <div className={cx(styles.main, className)}>{children}</div>
);

export default CenterView;
