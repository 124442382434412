import React from 'react';

import { default as InvoiceTemplateComponent } from 'containers/Profile/InvoiceTemplate/InvoiceTemplate';
import { t } from 'shared/utils';

import { Heading, Wrapper } from './InvoiceTemplate.styled';

const InvoiceTemplate = () => {
  return (
    <Wrapper>
      <Heading>{t('profile.section_headers.template_edit')}</Heading>
      <InvoiceTemplateComponent />
    </Wrapper>
  );
};

export default InvoiceTemplate;
