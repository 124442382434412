import styled from 'styled-components';

import media from 'shared/styles/breakpoints';
import ModalComponent from 'components/Modal';

export const Modal = styled(ModalComponent)`
  max-width: 1000px;
  width: 100%;
`;

export const ModalBody = styled.div`
  padding: 20px;
  display: grid;
  grid-column-gap: 19px;
  grid-auto-columns: 100%;
  grid-row-gap: 20px;

  ${media.greaterThan('md-up')`
    grid-template-columns: 1fr 1fr;
  `}
`;

export const DropzoneWrapper = styled.div`
  grid-row: 2 / 3;

  ${media.greaterThan('md-up')`
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  `}
`;

export const DropzoneHint = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs};
  grid-row: 3 / 4;

  ${media.greaterThan('md-up')`
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  `}
`;

export const Fields = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 7px;
  grid-auto-columns: 1fr 1fr 1fr;
  grid-auto-rows: min-content;
  grid-row: 1 / 2;

  ${media.greaterThan('md-up')`
    grid-template-columns: 2 / 3;
  `}
`;

export const Radios = styled.div`
  display: flex;
  margin-top: 23px;
`;

export const Label = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
`;

export const Radio = styled.label`
  width: 50%;
  color: #5f656b;
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;

  input {
    margin-right: 7px;
  }
`;

export const DocumentNumber = styled.div`
  grid-row: 4 / 5;

  ${media.greaterThan('md-up')`
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  `}
`;

export const TransactionType = styled.div`
  grid-column: span 3;
`;

export const Description = styled.div`
  grid-column: span 3;
`;

export const Category = styled.div`
  grid-column: span 3;
`;

export const TransactionDate = styled.div`
  grid-column: span 2;
`;

export const DailyOrder = styled.div`
  display: grid;
  grid-column: 1 / 3;
  grid-row: 6 / 7;
  grid-column-gap: 20px;
  grid-row-gap: 7px;

  h3 {
    font-size: ${({ theme }) => theme.fontSize.xs};
    grid-column: 1 / 3;
  }
`;

export const DailyOrderPrefix = styled.div`
  grid-column: 1 / 2;
  grid-row: 3 / 4;
`;

export const DailyOrderId = styled.div`
  grid-column: 2 / 6;
  grid-row: 3 / 4;
`;

export const OrderIdOption = styled.div`
  display: flex;
`;

export const OrderIdOptionNumber = styled.div`
  width: 30px;
`;

export const OrderIdOptionDescription = styled.div`
  flex: 1;
  word-break: break-all;
`;

export const OrderIdOptionGrossAmount = styled.div`
  width: 80px;
  text-align: right;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-row: 8 / 9;

  > *:not(:last-child) {
    margin-right: 20px;
  }

  ${media.greaterThan('md-up')`
    grid-column: 1 / 3;
  `}
`;

export const Amount = styled.div`
  grid-column: span 3;
`;

export const NumbersWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[6]};

  grid-column: span 3;
`;

export const Vat = styled.div`
  flex: 1;

  .Select-menu {
    max-height: initial;
  }
`;

export const TaxAmount = styled.div`
  flex: 1;
`;

export const Reasoning = styled.div`
  grid-column: 1 / 3;
  grid-row: 7 / 8;
  display: grid;
  grid-row-gap: 7px;

  h3 {
    font-size: ${({ theme }) => theme.fontSize.xs};
    margin-top: ${({ theme }) => theme.space['1']};
  }
`;
