import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import {
  BANK_ACCOUNTS_FAILURE,
  BANK_ACCOUNTS_REQUEST,
  BANK_ACCOUNTS_SUCCESS,
  CLEAR_SINGLE_TEMPLATE,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DOWNLOAD_FAILURE,
  DOWNLOAD_REQUEST,
  DOWNLOAD_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SUCCESS,
  IUpdateBankOnInvoice,
  RESET_TEMPLATE_FAILURE,
  RESET_TEMPLATE_REQUEST,
  RESET_TEMPLATE_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/invoice-templates';
import {
  hideOnInvoiceFailure,
  hideOnInvoiceSuccess,
  showOnInvoiceFailure,
  showOnInvoiceSuccess,
} from 'notifications/bank-account';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';
import { Dispatch } from 'types/actions';

// Adds and Removes Bank Accounts on Invoice
export const apiUpdateBankOnInvoice = ({
  iban,
  bic,
  manual,
  bankName,
  bankAccountId,
  ordinalNumber,
}: IUpdateBankOnInvoice) => ({
  [CALL_API]: {
    endpoint: `/me/invoice_template_bank_accounts/${ordinalNumber}`,
    method: 'PUT',
    data: {
      iban,
      bic,
      manual,
      bank_name: bankName,
      bank_account_id: bankAccountId,
      ordinal_number: ordinalNumber,
    },
    types: [BANK_ACCOUNTS_REQUEST, BANK_ACCOUNTS_SUCCESS, BANK_ACCOUNTS_FAILURE],
  },
});

export const apiRemoveBankOnInvoice = ({ ordinalNumber }: IUpdateBankOnInvoice) => ({
  [CALL_API]: {
    endpoint: `/me/invoice_template_bank_accounts/${ordinalNumber}`,
    method: 'DELETE',
    types: [BANK_ACCOUNTS_REQUEST, BANK_ACCOUNTS_SUCCESS, BANK_ACCOUNTS_FAILURE],
  },
});

export const apiFetchBankOnInvoice = () => ({
  [CALL_API]: {
    endpoint: '/me/invoice_template_bank_accounts',
    method: 'GET',
    types: [BANK_ACCOUNTS_REQUEST, BANK_ACCOUNTS_SUCCESS, BANK_ACCOUNTS_FAILURE],
  },
});

export const apiFetchInvoiceTemplates = () => ({
  [CALL_API]: {
    params: { page: 1, perPage: 10000 },
    endpoint: '/me/invoice_templates',
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
  },
});

export const apiGetInvoiceTemplate = (id: string) => ({
  [CALL_API]: {
    endpoint: `/me/invoice_templates/${id}`,
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiDeleteInvoiceTemplate = (id: string) => ({
  [CALL_API]: {
    endpoint: `/me/invoice_templates/${id}`,
    method: 'DELETE',
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const apiResetTemplate = (id: string, fieldsToReset: any) => ({
  [CALL_API]: {
    endpoint: `/me/invoice_templates/${id}/reset_to_default`,
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data' },
    types: [RESET_TEMPLATE_REQUEST, RESET_TEMPLATE_SUCCESS, RESET_TEMPLATE_FAILURE],
    data: fieldsToReset,
  },
});

export const apiUpdateInvoiceTemplate = (id: string, data: any) => {
  const hasBankData = data.bankName || data.bic || data.iban || '';
  return {
    [CALL_API]: {
      data: {
        ...data,
        ...(hasBankData.length ? data : { bankName: '', bic: '', iban: '' }),
        logo: data.logo instanceof File ? data.logo : '',
        background: data.background instanceof File ? data.background : '',
      },
      endpoint: `/me/invoice_templates/${id}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'PUT',
      types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
    },
  };
};

export const apiDownloadTemplatePreview = () => ({
  [CALL_API]: {
    endpoint: `/me/previews/outgoing_invoice`,
    types: [DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE],
    responseType: 'arraybuffer',
  },
});

export const downloadTemplate = () => (dispatch: any) =>
  dispatch(apiDownloadTemplatePreview()).catch(apiErrorHandler);

export const clearInvoiceTemplate = () => ({
  type: CLEAR_SINGLE_TEMPLATE,
});

export const fetchInvoiceTemplates = () => (dispatch: any) =>
  dispatch(apiFetchInvoiceTemplates()).catch(apiErrorHandler);

export const getInvoiceTemplate = (id: string) => (dispatch: any) =>
  dispatch(apiGetInvoiceTemplate(id)).catch(apiErrorHandler);

export const getBankOnInvoice = () => (dispatch: any) =>
  dispatch(apiFetchBankOnInvoice()).catch(apiErrorHandler);

export const deleteInvoiceTemplate = (template: any) => (dispatch: any) =>
  dispatch(apiDeleteInvoiceTemplate(template)).catch(apiErrorHandler);

export const resetTemplate = (id: string, fieldsToReset: any) => (dispatch: Dispatch) =>
  dispatch(apiResetTemplate(id, fieldsToReset));

export const updateInvoiceTemplate = (template: any, data: any) => (dispatch: Dispatch) =>
  bindServerValidation(apiUpdateInvoiceTemplate(template, data), dispatch).catch((err: any) => {
    throw err.errors;
  });

// Add or remove bank account from invoice
export const toggleBankAccountInvoiceVisibility =
  (props: IUpdateBankOnInvoice) => (dispatch: Dispatch) => {
    const { iban, bankAccountId } = props;
    const addVisibleAccount = bankAccountId || iban;

    if (addVisibleAccount) {
      return bindServerValidation(apiUpdateBankOnInvoice(props), dispatch)
        .then(() => {
          dispatch(showNotification(showOnInvoiceSuccess));
        })
        .catch((err: any) => {
          apiErrorHandler(err);
          dispatch(showNotification(showOnInvoiceFailure));
        });
    }
    return bindServerValidation(apiRemoveBankOnInvoice(props), dispatch)
      .then(() => {
        dispatch(showNotification(hideOnInvoiceSuccess));
      })
      .catch((err: any) => {
        apiErrorHandler(err);
        dispatch(showNotification(hideOnInvoiceFailure));
      });
  };
