import { useLayoutEffect } from 'react';

type ScheduleUpdateOnFocusProps = {
  scheduleUpdate: () => void;
};

/**
 * Whole purpose of this component is to call schedule update when component is mounted.
 * This way it can calculate its initial position. It's important to use setTimeout to calculate it
 * in the right moment
 */
const ScheduleUpdateOnFocus = ({ scheduleUpdate }: ScheduleUpdateOnFocusProps) => {
  useLayoutEffect(() => {
    setTimeout(scheduleUpdate, 0);
  }, [scheduleUpdate]);

  return null;
};

export default ScheduleUpdateOnFocus;
