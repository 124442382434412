export const getTaxConsultantEmail = (state: any): string => {
  const { taxConsultantEmail, exportPassword: taxConsultantPassword } = state.company.details;

  if (taxConsultantEmail || taxConsultantPassword) return taxConsultantEmail;

  return state.datev.fetchedCredentials.email;
};

export const getTaxConsultantPassword = (state: any): string => {
  const { taxConsultantEmail, exportPassword: taxConsultantPassword } = state.company.details;

  if (taxConsultantEmail || taxConsultantPassword) return taxConsultantPassword;

  return state.datev.fetchedCredentials.password;
};
