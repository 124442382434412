import { CompanyAuthorizedPeopleProps } from './CompanyAuthorizedPeople';

/**
 * Check if fields array is empty.
 * Fields array comes from redux-form build in component so it's more complicated than just checking length.
 * Fields are empty when it has no elements OR all elements have status "destroy".
 * @param fields Array like object from FieldArray (redux-form build in component)
 * @param formSelector selector for redux-form fields in store
 */
export const areFieldsEmpty = (
  fields: CompanyAuthorizedPeopleProps['fields'],
  formSelector: CompanyAuthorizedPeopleProps['formSelector']
) => {
  let length = 0;

  fields.forEach((person: string) => {
    if (!formSelector(`${person}.destroy`)) length += 1;
  });

  return length === 0;
};
