import React, { memo } from 'react';
import cx from 'classnames';
import type { WrappedFieldArrayProps } from 'redux-form';

import { t } from 'shared/utils';
import InfoIcon from 'components/InfoIcon/InfoIcon';

import AddMoreButton from '../../ExpensesRevenues/AddMoreButton/AddMoreButton';
import Amount, { Color } from '../../ExpensesRevenues/Fields/Amount';
import Interval from '../../ExpensesRevenues/Fields/Interval';
import Name from '../../ExpensesRevenues/Fields/Name';
import { requiredAndTouchedChecker, requiredValidate } from '../../ExpensesRevenues/Fields/utils';
import RemoveButton from '../../ExpensesRevenues/RemoveButton/RemoveButton';
import Body from '../../ExpensesRevenues/Table/Body';
import BodyCell from '../../ExpensesRevenues/Table/BodyCell';
import BodyRow from '../../ExpensesRevenues/Table/BodyRow';
import HeaderCell from '../../ExpensesRevenues/Table/HeaderCell';
import HeaderRow from '../../ExpensesRevenues/Table/HeaderRow';
import TableComponent from '../../ExpensesRevenues/Table/Table';
import { trackEvent } from '../../utils';
import { NewRevenue } from '../constants';
import type { RecurringRevenue } from '../types';

import sharedTableStyles from '../../ExpensesRevenues/ExpensesRevenues.module.css';
import styles from './Table.module.css';

const Table = ({ fields }: WrappedFieldArrayProps<RecurringRevenue>) => (
  <>
    <TableComponent>
      <HeaderRow>
        <HeaderCell className={sharedTableStyles.nameHeaderCell}>
          {t(
            'features.financial_planning.liquidity_calculator.revenues.fields.recurring_revenues.name.table_header'
          )}
        </HeaderCell>
        <HeaderCell className={cx(sharedTableStyles.intervalHeaderCell, styles.intervalHeaderCell)}>
          <span className={styles.intervalHeaderText}>
            {t(
              'features.financial_planning.liquidity_calculator.revenues.fields.recurring_revenues.interval.table_header'
            )}
          </span>
          <InfoIcon
            className={styles.infoIcon}
            text={t(
              'features.financial_planning.liquidity_calculator.revenues.fields.recurring_revenues.interval.table_header_tooltip'
            )}
          />
        </HeaderCell>
        <HeaderCell className={sharedTableStyles.amountHeaderCell}>
          {t(
            'features.financial_planning.liquidity_calculator.revenues.fields.recurring_revenues.amount.table_header'
          )}
        </HeaderCell>
        <HeaderCell className={sharedTableStyles.actionHeaderCell} />
      </HeaderRow>
      <Body>
        {fields.map((revenue, index) => (
          <BodyRow key={index} dataId={`LiquidityCalculatorRevenues:row-${index}`}>
            <BodyCell className={sharedTableStyles.nameBodyCell}>
              <Name
                name={`${revenue}.name`}
                label={t(
                  'features.financial_planning.liquidity_calculator.revenues.fields.recurring_revenues.name.label'
                )}
                validate={requiredValidate}
                checker={requiredAndTouchedChecker}
                isLabelHidden
                dataId={'LiquidityCalculatorRevenues:input-name'}
              />
            </BodyCell>
            <BodyCell className={sharedTableStyles.intervalBodyCell}>
              <Interval
                name={`${revenue}.interval`}
                label={t(
                  'features.financial_planning.liquidity_calculator.revenues.fields.recurring_revenues.interval.label'
                )}
                validate={requiredValidate}
                checker={requiredAndTouchedChecker}
                isLabelHidden
                dataId={'LiquidityCalculatorRevenues:input-interval'}
              />
            </BodyCell>
            <BodyCell className={sharedTableStyles.amountBodyCell}>
              <Amount
                name={`${revenue}.amount`}
                label={t(
                  'features.financial_planning.liquidity_calculator.revenues.fields.recurring_revenues.amount.label'
                )}
                color={Color.Green}
                validate={requiredValidate}
                checker={requiredAndTouchedChecker}
                isLabelHidden
                dataId={'LiquidityCalculatorRevenues:input-amount'}
              />
            </BodyCell>
            <BodyCell className={sharedTableStyles.actionBodyCell}>
              <RemoveButton
                onClick={() => {
                  fields.remove(index);
                  trackEvent('Delete_Revenue');
                }}
                dataId="LiquidityCalculatorRevenues:button-remove"
              />
            </BodyCell>
          </BodyRow>
        ))}
      </Body>
    </TableComponent>
    <AddMoreButton
      onClick={() => {
        fields.push(NewRevenue);
        trackEvent('Add_Revenue');
      }}
      dataId="LiquidityCalculatorRevenues:button-addMore"
    >
      {`+ ${t('features.financial_planning.liquidity_calculator.revenues.add_revenue')}`}
    </AddMoreButton>
  </>
);

export default memo(Table);
