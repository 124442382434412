import React from 'react';

import Hint from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoicePaymentSection/PaymentCreator/components/Hint/Hint.jsx';
import ParallelContainer from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoicePaymentSection/PaymentCreator/components/ParallelContainer/ParallelContainer.jsx';
import { t } from 'shared/utils';
import { TextField } from 'components/Form';

type TextHtmlProps = {
  challenge: {
    format: 'TEXT' | 'HTML';
    type: 'EMBEDDED';
    created_at: string;
    data: string;
    id: string;
    label: string;
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

const TextHtml = ({ challenge, onChange, value }: TextHtmlProps) => {
  const { data, label } = challenge;

  return (
    <>
      <Hint text={data || label} />
      <ParallelContainer>
        <TextField
          name="tan"
          placeholder={t('figo_connection.challenges.embedded.tan')}
          value={value}
          onChange={onChange}
          dataId="FigoAuth:input-tan"
          required
        />
        <div />
      </ParallelContainer>
    </>
  );
};

export default TextHtml;
