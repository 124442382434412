import { t } from 'shared/utils';

export const updateFailure = {
  duration: 3000,
  title: t('proposals.form.save_failure.title'),
  variant: 'warning',
};

export const lineItemUpdateSuccess = {
  duration: 3000,
  title: t('notifications.proposals.line_items.update_success.title'),
  variant: 'success',
};

export const lineItemUpdateFailure = {
  duration: 3000,
  title: t('notifications.proposals.line_items.update_failure.title'),
  variant: 'warning',
};

export const lineItemCreateSuccess = {
  duration: 3000,
  title: t('notifications.proposals.line_items.create_success.title'),
  variant: 'success',
};

export const lineItemCreateFailure = {
  duration: 3000,
  title: t('notifications.proposals.line_items.create_failure.title'),
  variant: 'warning',
};

export const lineItemDeleteSuccess = {
  duration: 3000,
  title: t('notifications.proposals.line_items.delete_success.title'),
  variant: 'success',
};

export const lineItemDeleteFailure = {
  duration: 3000,
  title: t('notifications.proposals.line_items.delete_failure.title'),
  variant: 'warning',
};

export const sendSuccess = {
  duration: 3000,
  title: t('notifications.proposals.send_success.title'),
  variant: 'success',
};

export const downloadSuccess = {
  duration: 3000,
  title: t('notifications.proposals.download_success.title'),
  variant: 'success',
};
