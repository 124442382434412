import { connect } from 'react-redux';

import { toggleDisableAction } from 'actions/incoming-invoice';
import { createLineItem } from 'actions/incoming-invoice/line-items';
import { TYPE_EXPENSES } from 'constants/row-types';
import {
  incomingInvoiceDetailsSelector,
  incomingInvoiceFormValuesSelector,
} from 'selectors/incomingInvoice';
import { t } from 'shared/utils';

import { NewItemRow } from '../LineItems';

const categoryInfo = t('expenses.form.details_section.fields.invoice_line_category_id_info');

const mapStateToProps = (state) => ({
  invoiceId: incomingInvoiceDetailsSelector(state).id,
  insertedAsGross: incomingInvoiceDetailsSelector(state).insertedAsGross,
  isCreditNote: incomingInvoiceFormValuesSelector(state).creditNote,
  lineCategories: state.incomingInvoice.lineCategories,
});

const mapDispatchToProps = (dispatch) => ({
  createLineItem: (...args) => dispatch(createLineItem(...args)),
  handleDisableButton: (arg) => dispatch(toggleDisableAction(arg)),
});

const mergeProps = (
  { invoiceId, lineCategories, insertedAsGross, isCreditNote },
  dispatchProps,
  ownProps
) => ({
  ...ownProps,
  ...dispatchProps,
  invoiceInsertedAsGross: insertedAsGross,
  translationPath: 'expenses',
  rowType: TYPE_EXPENSES,
  isCreditNote,
  cells: [
    { name: 'position', type: 'positionInput' },
    { name: 'invoiceLineCategoryId', type: 'multiselect', info: categoryInfo },
    { name: 'vat', type: 'select' },
    { type: insertedAsGross ? 'grossLinked' : 'netLinked' },
  ],
  lineCategories,
  createLineItem: (values) => dispatchProps.createLineItem(invoiceId, values),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NewItemRow);
