import { format, isValid, parse } from 'date-fns';
import { kebabCase } from 'lodash';

import { GetContractsData } from 'api/me/contracts';
import { DATE_FORMAT_FULL_REVERSED_UNICODE, DATE_FORMAT_UNICODE } from 'constants/datetime';
import { piwikHelpers } from 'shared/utils/piwik';

import { INSTALLMENT_KEYS } from './constants';
import type { FormData } from './types';

export const prepareNewContractData = (data: Partial<FormData>) =>
  Object.entries(data).reduce(
    (acc: { installments?: object }, { 0: label, 1: value }) =>
      INSTALLMENT_KEYS.includes(label)
        ? { ...acc, installments: { ...acc.installments, [label]: value } }
        : { ...acc, [label]: value },
    {}
  );

export const trackEvent = piwikHelpers.trackEventCurried('Contracts');

export const formatSortBy = ({
  sortBy,
  keysMapping,
  defaultValue,
}: {
  sortBy: { id: string; desc?: boolean }[];
  keysMapping?: { [key: string]: string };
  defaultValue?: string;
}) => {
  if (!sortBy.length) return defaultValue;

  const [{ desc, id }] = sortBy;
  const order = desc ? '-' : '';
  const key = kebabCase(keysMapping?.[id] || id);

  return `${order}${key}`;
};

export const formatFilters = ({
  filters,
  globalFilter,
}: {
  filters: { id: string; value: any }[];
  globalFilter?: string;
}) => {
  const result: GetContractsData['filters'] = {};

  filters.forEach(({ id, value }) => {
    if (id === 'transactionDate') {
      const [from, to] = value;

      if (from) {
        const date = parse(from, DATE_FORMAT_UNICODE, new Date());

        if (isValid(date)) {
          result.transaction_date_gte = format(date, DATE_FORMAT_FULL_REVERSED_UNICODE);
        }
      }

      if (to) {
        const date = parse(to, DATE_FORMAT_UNICODE, new Date());

        if (isValid(date)) {
          result.transaction_date_lte = format(date, DATE_FORMAT_FULL_REVERSED_UNICODE);
        }
      }
    } else if (id === 'recurringTransactionInterval') {
      result.recurring_transaction_interval_id = value;
    } else if (id === 'grossAmount') {
      const [from, to] = value;

      if (from) {
        result.gross_amount_gte = from;
      }

      if (to) {
        result.gross_amount_lte = to;
      }
    }
  });

  if (globalFilter) {
    result.full_text_search = globalFilter;
  }

  return result;
};
