export const UPLOAD_REQUEST = 'IMPORT_CONTACTS/UPLOAD_REQUEST';
export const UPLOAD_SUCCESS = 'IMPORT_CONTACTS/UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'IMPORT_CONTACTS/UPLOAD_FAILURE';

export const STATUS_REQUEST = 'IMPORT_CONTACTS/STATUS_REQUEST';
export const STATUS_SUCCESS = 'IMPORT_CONTACTS/STATUS_SUCCESS';
export const STATUS_FAILURE = 'IMPORT_CONTACTS/STATUS_FAILURE';

export const TRANSFORM_ENTRIES_REQUEST = 'IMPORT_CONTACTS/TRANSFORM_ENTRIES_REQUEST';
export const TRANSFORM_ENTRIES_SUCCESS = 'IMPORT_CONTACTS/TRANSFORM_ENTRIES_SUCCESS';
export const TRANSFORM_ENTRIES_FAILURE = 'IMPORT_CONTACTS/TRANSFORM_ENTRIES_FAILURE';

export const FETCH_REQUEST = 'IMPORT_CONTACTS/FETCH_REQUEST';
export const FETCH_SUCCESS = 'IMPORT_CONTACTS/FETCH_SUCCESS';
export const FETCH_FAILURE = 'IMPORT_CONTACTS/FETCH_FAILURE';

export const DELETE_REQUEST = 'IMPORT_CONTACTS/DELETE_REQUEST';
export const DELETE_SUCCESS = 'IMPORT_CONTACTS/DELETE_SUCCESS';
export const DELETE_FAILURE = 'IMPORT_CONTACTS/DELETE_FAILURE';
