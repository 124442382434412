import React, { SVGAttributes } from 'react';

const CaretUp = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 12 7" {...props}>
    <g fill="none" fillRule="evenodd">
      <path opacity={0.87} d="M14.5-5.5v16h-16v-16z" />
      <path
        d="M11.631 6.63a.75.75 0 000-1.061L6.645.583a.598.598 0 00-.846 0L.813 5.569a.75.75 0 000 1.062.75.75 0 001.062 0l4.35-4.344 4.35 4.35a.748.748 0 001.056-.006z"
        fill="#0066B3"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default CaretUp;
