import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import iconDanger from 'images/danger-icon.svg';
import { FieldNotification, PasswordField } from 'components/Form';
import I18n from 'components/I18n';

import styles from './InvoiceDeletetion.module.css';

const InvoiceDeletetion = ({
  invoiceNumber,
  handlePassword,
  errorMessages = [],
  siteUrl,
  isFieldInvalid,
}) => (
  <Fragment>
    <h5 className={styles.headline}>
      <I18n t="revenue.delete.header_text_1" />
      {` ${invoiceNumber} `}
      <I18n t="revenue.delete.header_text_2" />
    </h5>
    <div className={styles.subheadline}>
      <img src={iconDanger} className={styles.icon} alt="Danger icon" />
      <I18n t="revenue.delete.subhead" paragraphs />
    </div>
    <p className={styles.normalText}>
      <I18n t="revenue.delete.normal_text_1" />
      <a
        href={`https://${siteUrl}/faq?deep_link=pages%2Fviewpage.action%3FpageId%3D10358391`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <I18n t="revenue.delete.normal_text_link" />
      </a>
      <I18n t="revenue.delete.normal_text_2" />
    </p>
    <I18n t="revenue.delete.bold_text" paragraphs className={styles.boldText} />

    <label htmlFor="password" className={styles.label}>
      <I18n t="revenue.delete.password_label" />
      <PasswordField
        invalid={isFieldInvalid}
        name="password"
        type="password"
        dataId="ConfirmationModal:input-password"
        onChange={(e) => handlePassword(e)}
      />
      {errorMessages.map((error, index) => (
        <FieldNotification show message={error} variant="warning" key={index} />
      ))}
    </label>
  </Fragment>
);

InvoiceDeletetion.propTypes = {
  handlePassword: PropTypes.func.isRequired,
  invoiceNumber: PropTypes.string,
  siteUrl: PropTypes.string.isRequired,
  isFieldInvalid: PropTypes.string,
  errorMessages: PropTypes.arrayOf(PropTypes.string),
};

export default reduxForm({
  form: 'invoiceDeletion',
})(InvoiceDeletetion);
