import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { compose } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';

import {
  createProductCatalogItem,
  fetchCategories,
  fetchProductCatalogItem,
  updateProductCatalogItem,
} from 'actions/product-catalog/items/items';
import { clickCancelProductCatalogItem as clickCancelProductCatalogItemAction } from 'actions/product-catalog/items/piwik';
import { FORM_NAME, PRODUCT_CATALOG_ITEMS } from 'constants/product-catalog/items';
import { getLineCategories, makeGetEntity } from 'reducers/common/resource';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { formatMoneyInput } from 'shared/utils';

const reduxFormConnect = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  persistentSubmitErrors: true,
});

const mapStateToProps = () => {
  const getEntity = makeGetEntity();
  const selector = formValueSelector(FORM_NAME);

  return (state, ownProps) => {
    const { id } = ownProps;
    const productCatalogItem = id
      ? getEntity(id)(state.productCatalogItems[PRODUCT_CATALOG_ITEMS], PRODUCT_CATALOG_ITEMS, id)
      : null;
    const {
      company: {
        details: { smallEntrepreneur },
      },
    } = state;
    const { netAmount = '', grossAmount = '' } = productCatalogItem || {};
    const getLineCategory = getLineCategories(state).find(
      (item) => item.id === String(productCatalogItem?.invoiceLineCategoryId)
    );

    return {
      id: ownProps.id,
      insertedAsGross: selector(state, 'insertedAsGross'),
      smallEntrepreneur,
      parsedNetAmount: selector(state, 'parsedNetAmount'),
      parsedGrossAmount: selector(state, 'parsedGrossAmount'),
      lineCategories: getLineCategories(state),
      selectedCategory: selector(state, 'invoiceLineCategoryId'),
      initialValues: {
        vat: selector(state, 'vat') || (ownProps.isNew && !smallEntrepreneur) ? 19 : 0,
        insertedAsGross: false,
        ...productCatalogItem,
        netAmount,
        grossAmount,
        invoiceLineCategoryId: getLineCategory,
        parsedNetAmount: netAmount === '' ? '' : formatMoneyInput(netAmount),
        parsedGrossAmount: grossAmount === '' ? '' : formatMoneyInput(grossAmount),
      },
    };
  };
};

const mapDispatchToProps = {
  submitForm: createProductCatalogItem,
  updateForm: updateProductCatalogItem,
  fetchForm: fetchProductCatalogItem,
  clickCancelProductCatalogItem: clickCancelProductCatalogItemAction,
  push: pushAction,
  fetchCategories,
};

const reduxStoreConnect = connect(mapStateToProps, mapDispatchToProps);

export const connection = compose(reduxStoreConnect, reduxFormConnect, withTransitionPrevent());
