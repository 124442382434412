import React from 'react';
import PropTypes from 'prop-types';

import { t } from 'shared/utils';

/*
 * Usage:
 * <I18n t="translation.key" interpolation_variable="value" />
 * t("translation.key", { interpolation_variable: "value" })
 */
const I18n = ({ t: key, paragraphs = false, ...rest }) => {
  const messages = String(t(key, rest)).split('\n');
  const Tag = paragraphs ? 'p' : 'span';
  const tags = messages.map((message, index) => (
    <Tag key={index} {...rest}>
      {message}
    </Tag>
  ));
  if (tags.length > 1) return <span>{tags}</span>;
  return tags[0];
};

I18n.propTypes = {
  t: PropTypes.string.isRequired,
  paragraphs: PropTypes.bool,
};

export default I18n;
