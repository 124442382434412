import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { connect } from 'react-redux';
import cx from 'classnames';
import normalize from 'json-api-normalizer';
import { get, isEmpty, reverse } from 'lodash';
import { bool, func, shape, string } from 'prop-types';
import build from 'redux-object';

import { duplicateOrderConfirmation as duplicateOrderConfirmationAction } from 'actions/order-confirmation';
import { fetchSubOrderConfirmations } from 'actions/order-confirmations';
import { FETCH_SUCCESS_SUB_ORDER_CONFIRMATIONS } from 'constants/order-confirmations';
import * as Piwik from 'constants/piwik';
import paths from 'routes/paths';
import tableStyles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import Amount from 'components/Amount';
import ArrrowIcon from 'components/ArrowIcon/ArrowIcon';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import Section from 'components/Form/Section/Section';
import I18n from 'components/I18n';
import ActionCancel from 'components/IndexActionsContainer/actionIcons/ActionCancel';
import ActionDelete from 'components/IndexActionsContainer/actionIcons/ActionDelete';
import ActionDuplicate from 'components/IndexActionsContainer/actionIcons/ActionDuplicate';
import ActionEdit from 'components/IndexActionsContainer/actionIcons/ActionEdit';
import ActionEditFromOrderConfirmation from 'components/IndexActionsContainer/actionIcons/ActionEditFromOrderConfirmation';
import ActionView from 'components/IndexActionsContainer/actionIcons/ActionView';
import IndexActionsContainer from 'components/IndexActionsContainer/IndexActionsContainer';
import LoadingIcon from 'components/LoadingIcon';

import StatusBadge from '../../StatusBadge/StatusBadge';
import SubOrderConfirmations from '../../SubOrderConfirmations/SubOrderConfirmations';
import { OrderConfirmationStatus } from '../../types';

import localStyles from '../OrderConfirmationsTable.module.css';

export const styles = { ...tableStyles, ...localStyles };

class OrderConfirmationRow extends Component {
  state = {
    isRowExpanded: false,
    orderConfirmations: [],
    isFetching: false,
  };

  handleShowClick = () =>
    piwikHelpers.trackEvent(
      Piwik.CATEGORY_ORDER_CONFIRMATIONS,
      Piwik.ACTION_SHOW_ORDER_CONFIRMATION
    );

  handleEditClick = () =>
    piwikHelpers.trackEvent(
      Piwik.CATEGORY_ORDER_CONFIRMATIONS,
      Piwik.ACTION_EDIT_ORDER_CONFIRMATION
    );

  handleTransformClick = () => {
    const { item, onTransform } = this.props;
    onTransform(item);
    piwikHelpers.trackEvent(
      Piwik.CATEGORY_ORDER_CONFIRMATIONS,
      Piwik.ACTION_INDEX_VIEW_TRANSFORM_ORDER_CONFIRMATION
    );
  };

  handleActionDuplicate = (event) => {
    event.preventDefault();

    const {
      duplicateOrderConfirmation,
      item: { id },
    } = this.props;

    duplicateOrderConfirmation(id);
  };

  setOrderConfirmationData = (response) => {
    const orderConfirmations = reverse(build(normalize(response), 'orderConfirmations'));

    this.setState({
      subOrderConfirmations: orderConfirmations,
      isFetching: false,
    });
  };

  handleOnClick = () => {
    this.state.isRowExpanded ? this.setState({ isRowExpanded: false }) : this.handleOnOpen();
  };

  handleOnOpen = () => {
    const { handleFetchSubOrderConfirmations, item: { id: orderConfirmationId } = {} } = this.props;
    this.setState({ isFetching: true });
    handleFetchSubOrderConfirmations(orderConfirmationId).then((response) => {
      if (response.type === FETCH_SUCCESS_SUB_ORDER_CONFIRMATIONS)
        this.setOrderConfirmationData(response);
      this.setState({ isRowExpanded: true });
    });
  };

  render() {
    const {
      item: orderConfirmation,
      onDelete,
      isEven,
      isIndexClassicView,
      showPath = paths.showOrderConfirmation,
      editPath = paths.editOrderConfirmation,
      cancelPath = paths.cancelOrderConfirmation,
    } = this.props;
    const { isRowExpanded, isFetching } = this.state;
    const permittedActions = get(orderConfirmation, 'meta.actions', {});
    const canShow = permittedActions.show;
    const canEdit = permittedActions.edit && !orderConfirmation.hasParentOrderConfirmation;
    const canDuplicate =
      permittedActions.duplicate && !orderConfirmation.hasParentOrderConfirmation;
    const canTransform =
      permittedActions.transform && !orderConfirmation.hasParentOrderConfirmation;
    const canCancel = permittedActions.cancel && !orderConfirmation.hasParentOrderConfirmation;
    const canDelete = permittedActions.delete && !orderConfirmation.hasParentOrderConfirmation;
    const isSubOrderConfirmationRowPresent =
      !isIndexClassicView &&
      (orderConfirmation.hasParentOrderConfirmation || !!orderConfirmation.paymentRemindersCount);
    const anySubOrderConfirmationsPresent = !isEmpty(this.state.subOrderConfirmations);
    const invoiceStatus = orderConfirmation.hasParentOrderConfirmation
      ? OrderConfirmationStatus.CANCELLED
      : orderConfirmation.draft
      ? OrderConfirmationStatus.DRAFT
      : OrderConfirmationStatus.SENT;

    return (
      <>
        <tr
          className={cx({
            [styles.rowEven]: isEven,
            [styles.rowOdd]: !isEven,
          })}
          data-id="OrderConfirmationRow"
        >
          <td>
            {isSubOrderConfirmationRowPresent && (
              <ArrrowIcon
                isOpen={isRowExpanded}
                onClick={this.handleOnClick}
                dataId="OrderConfirmationTable:icon-arrow"
              />
            )}
          </td>
          <td className={styles.column}>
            <div className={styles.cell}>
              <I18n t="order_confirmations.table.columns.status" className={styles.cellHeader} />
              <StatusBadge status={invoiceStatus} />
            </div>
          </td>
          <td className={styles.column}>
            <div className={styles.cell}>
              <I18n t="order_confirmations.table.columns.client" className={styles.cellHeader} />
              <CellContentWithTooltip dataId="OrderConfirmationRow:client-name">
                {get(orderConfirmation, 'relationships.client.name')}
              </CellContentWithTooltip>
            </div>
          </td>
          <td className={styles.column}>
            <div className={styles.cell}>
              <I18n
                t="order_confirmations.table.columns.order_confirmation_number"
                className={styles.cellHeader}
              />
              <CellContentWithTooltip dataId="OrderConfirmationRow:number">
                {orderConfirmation.orderConfirmationNumber}
              </CellContentWithTooltip>
            </div>
          </td>
          <td className={styles.column}>
            <div className={styles.cell}>
              <I18n t="order_confirmations.table.columns.subject" className={styles.cellHeader} />
              <CellContentWithTooltip dataId="OrderConfirmationRow:subject">
                {orderConfirmation.subject}
              </CellContentWithTooltip>
            </div>
          </td>
          <td className={styles.column}>
            <div className={styles.cell}>
              <I18n
                t="order_confirmations.table.columns.order_confirmation_date"
                className={styles.cellHeader}
              />
              <div className={styles.cellContent} data-id="OrderConfirmationRow:creation-date">
                {orderConfirmation.orderConfirmationDate}
              </div>
            </div>
          </td>
          <td className={styles.columnRight}>
            <div className={styles.cell}>
              <I18n
                t="order_confirmations.table.columns.total_gross_amount"
                className={styles.cellHeader}
              />
              <div className={styles.cellContent}>
                <Amount
                  dataId="OrderConfirmationRow:amount"
                  amount={orderConfirmation.totalGrossAmount}
                  currency={orderConfirmation.currency}
                />
              </div>
            </div>
          </td>
          <td className={styles.column}>
            <IndexActionsContainer>
              <ActionView
                id="outgoing-invoice-show-button"
                title={t('features.order_confirmation.table.actions.show')}
                onClick={this.handleShowClick}
                to={showPath(orderConfirmation.id)}
                label={t('features.order_confirmation.table.actions.show')}
                visible={canShow}
                dataId="OrderConfirmationRow:button-show"
              />
              <ActionEdit
                id="outgoing-invoice-edit-button"
                to={editPath(orderConfirmation.id)}
                title={t('features.order_confirmation.table.actions.edit')}
                onClick={this.handleEditClick}
                label={t('features.order_confirmation.table.actions.edit')}
                visible={canEdit}
                dataId="OrderConfirmationRow:button-edit"
              />
              <ActionDuplicate
                title={t('features.order_confirmation.table.actions.duplicate')}
                label={t('features.order_confirmation.table.actions.duplicate')}
                dataId="OrderConfirmationRow:button-duplicate"
                visible={canDuplicate}
                onClick={this.handleActionDuplicate}
              />
              <ActionEditFromOrderConfirmation
                title={t('features.order_confirmation.table.actions.transform')}
                dataId="OrderConfirmationRow:button-transform"
                onClick={this.handleTransformClick}
                label={t('features.order_confirmation.table.actions.transform')}
                visible={canTransform}
              />
              <ActionCancel
                title={t('features.order_confirmation.table.actions.cancel')}
                to={cancelPath(orderConfirmation.id)}
                dataId="OrderConfirmationRow:button-cancel"
                label={t('features.order_confirmation.table.actions.cancel')}
                visible={canCancel}
              />
              <ActionDelete
                title={t('features.order_confirmation.table.actions.delete')}
                onClick={() => onDelete(orderConfirmation)}
                label={t('features.order_confirmation.table.actions.delete')}
                visible={canDelete}
                dataId="OrderConfirmationRow:button-delete"
              />
            </IndexActionsContainer>
          </td>
        </tr>
        <tr>
          <td colSpan="8">
            {isSubOrderConfirmationRowPresent && anySubOrderConfirmationsPresent && (
              <AnimateHeight duration={200} height={isRowExpanded ? 'auto' : 0}>
                <Section
                  wrapperTheme={styles.section}
                  title={t('revenue.table.associated_documents')}
                >
                  {!isFetching ? (
                    <SubOrderConfirmations
                      subOrderConfirmations={this.state.subOrderConfirmations}
                      parentOrderConfirmation={orderConfirmation}
                      isEven={isEven}
                    />
                  ) : (
                    <LoadingIcon topDisabled />
                  )}
                </Section>
              </AnimateHeight>
            )}
          </td>
        </tr>
      </>
    );
  }
}

OrderConfirmationRow.propTypes = {
  orderConfirmation: shape({
    city: string,
    companyName: string,
    idNumber: string,
    lastName: string,
    hasParentOrderConfirmation: bool,
    meta: shape({
      actions: shape({
        delete: bool,
        edit: bool,
        show: bool,
        transform: bool,
        cancel: bool,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  showPath: func,
  editPath: func,
  onDelete: func.isRequired,
  onTransform: func.isRequired,
  duplicateOrderConfirmation: func.isRequired,
  handleFetchSubOrderConfirmations: func.isRequired,
  isRowExpanded: bool,
};

const mapDisptachToProps = (dispatch) => ({
  handleFetchSubOrderConfirmations: (...args) => dispatch(fetchSubOrderConfirmations(...args)),
  duplicateOrderConfirmation: (orderConfirmationId) =>
    dispatch(duplicateOrderConfirmationAction(orderConfirmationId)),
});

export default connect(null, mapDisptachToProps)(OrderConfirmationRow);
