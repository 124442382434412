import styled from 'styled-components';

import CancelIcon from 'images/icon-cancel.svg';
import media from 'shared/styles/breakpoints';

const Button = styled.button`
  min-width: 190px;
  max-width: 350px;
  margin: 0px 0px 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  height: 35px;

  ${media.greaterThan('sm-up')`
    margin: 30px 0;
    margin-bottom: 20px;
  `};
`;

export const Header = styled.div`
  display: flex;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: #4d4e4c;
`;

export const ModalBody = styled.div`
  display: flex;
  max-width: 700px;
  padding: 20px 30px;
  flex-flow: column;
`;

export const Close = styled.button`
  width: 12px;
  height: 12px;
  border: none;
  background: url(${CancelIcon}) center no-repeat;
`;

export const Headline = styled.div`
  margin-top: 10px;
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: bold;
  color: #4d4e4c;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.greaterThan('sm-up')`
    flex-direction: row;
  `};
`;

export const ButtonSecondaryContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  ${media.greaterThan('sm-up')`
    padding-right: 5px;
    justify-content: flex-end;
  `};
`;

export const ButtonSecondary = styled(Button)`
  border: 1px solid #0066b3;
  color: #0066b3;
  background: transparent;
  line-height: 1;
`;

export const ButtonPrimaryContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  ${media.greaterThan('sm-up')`
    justify-content: flex-start;
    padding-left: 5px;
  `};
`;

export const ButtonPrimary = styled(Button)`
  color: #fff;
  background: #0066b3;
  padding: 4px 10px;
  outline: 0;
  border: 0;
`;

export const OrderedList = styled.ol`
  padding-left: 16px;
  list-style: none;
  counter-reset: my-awesome-counter;
`;

export const ListItem = styled.li`
  counter-increment: my-awesome-counter;
  margin: 0 0 1.2rem;
  font-size: 14px;

  &::marker {
    font-size: 14px;
    font-weight: bold;
    content: counter(my-awesome-counter) '. ';
  }
`;
