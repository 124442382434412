import React, { Component } from 'react';
import { func } from 'prop-types';

import BankAccountsCreator from 'containers/Profile/BankAccounts/BankAccountsCreator/BankAccountsCreator';
import { t } from 'shared/utils';

import Header from '../components/Header/Header';
import PriceInfo from '../components/PriceInfo/PriceInfo';

import styles from './BankAccountCreatorWrapper.module.css';

class BankAccountCreatorWrapper extends Component {
  state = {
    isLoading: false,
  };

  setLoading = (isLoading) => this.setState({ isLoading });

  render() {
    const { isLoading } = this.state;
    const { finishCreation } = this.props;

    return (
      <>
        <Header
          title={t('profile.payment_plan.book_premium.header_1')}
          bold={t('profile.payment_plan.book_premium.header_2')}
          description={t('profile.payment_plan.book_premium.header_3')}
        />
        <PriceInfo />
        <div className={styles.creatorContainer}>
          <h3 className={styles.creatorHeader}>
            {t('profile.payment_plan.book_premium.creator_header')}
          </h3>
          <BankAccountsCreator
            onFinish={finishCreation}
            onCancel={finishCreation}
            isLoading={isLoading}
            setLoading={this.setLoading}
            hasExternalSteps
            redirectAfterSuccess={finishCreation}
          />
        </div>
      </>
    );
  }
}

BankAccountCreatorWrapper.propTypes = {
  finishCreation: func.isRequired,
};

export default BankAccountCreatorWrapper;
