import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { flow } from 'lodash';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { resetClientDefaults, updateClientDefaults } from 'actions/default-values';
import { ClientEmailsFormName } from 'containers/Clients/ClientEmailDefaults/ClientEmailDefaults';
import {
  DefaultValuesForm,
  FormFooterContainer,
  FormRow,
} from 'containers/Profile/InvoiceTemplate/DefaultValues/sharedComponents';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { RootState } from 'store';
import { DefaultValues } from 'types/entities/DefaultValues';
import { InfoTextField, TextareaField } from 'components/Form';
import InputsGroup from 'components/InputsGroup/InputsGroup';

import styles from './ProposalEmailsDefaults.module.css';

const FORM_NAME: ClientEmailsFormName = 'ClientProposalEmailsDefaults';

type FormData = Pick<
  DefaultValues,
  | 'emailProposalSubject'
  | 'emailProposalSalutation'
  | 'emailProposalSalutationContent'
  | 'emailProposalSignature'
>;

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    emailProposalSubject: state.defaultValues.values.emailProposalSubject,
    emailProposalSalutation: state.defaultValues.values.emailProposalSalutation,
    emailProposalSalutationContent: state.defaultValues.values.emailProposalSalutationContent,
    emailProposalSignature: state.defaultValues.values.emailProposalSignature,
  },
});

const mapDispatchToProps = {
  updateClientDefaults,
  resetClientDefaults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ProposalEmailsDefaultsProps = ConnectedProps<typeof connector> & {
  clientId: string;
};

const ProposalEmailsDefaults = ({
  resetClientDefaults,
  updateClientDefaults,
  handleSubmit,
  clientId,
  reset: resetForm,
}: ProposalEmailsDefaultsProps & InjectedFormProps<FormData, ProposalEmailsDefaultsProps>) => {
  return (
    <DefaultValuesForm
      onSubmit={handleSubmit((values) => updateClientDefaults(clientId, values, 'email_proposal'))}
      onReset={async () => {
        await resetClientDefaults(clientId, 'email_proposal');
        resetForm();
      }}
      sectionName="email_proposal"
      sectionLabel={t('invoice_templates.emails_defaults.proposal_defaults_section')}
      dataIdPrefix="ClientDocumentDefaults:"
    >
      <>
        <FormRow>
          <InputsGroup label={t('invoice_templates.emails_defaults.subject')}>
            <Field
              disabled
              className={styles.grouppedInput}
              name="emailProposalSubject"
              checker={serverValidationChecker}
              component={InfoTextField}
              message={t('invoice_templates.emails_defaults.subject_info')}
              dataId="ClientEmailDefaults:input-proposal-subject"
              isDisabledWithUnderline
            />
          </InputsGroup>
        </FormRow>

        <FormRow>
          <InputsGroup label={t('invoice_templates.emails_defaults.header_message')}>
            <Field
              name="emailProposalSalutation"
              component={InfoTextField}
              checker={serverValidationChecker}
              label={t('invoice_templates.emails_defaults.salutation')}
              message={t('invoice_templates.emails_defaults.salutation_info')}
              dataId="ClientEmailDefaults:input-proposal-salutation"
            />
            <Field
              name="emailProposalSalutationContent"
              component={TextareaField}
              checker={serverValidationChecker}
              label={t('invoice_templates.emails_defaults.salutation_content')}
              fullHeight
              dataId="ClientEmailDefaults:input-proposal-salutation-content"
            />
          </InputsGroup>
        </FormRow>
        <FormRow>
          <FormFooterContainer>
            <InputsGroup label={t('invoice_templates.emails_defaults.signature')}>
              <Field
                disabled
                className={styles.grouppedInput}
                name="emailProposalSignature"
                checker={serverValidationChecker}
                component={InfoTextField}
                message={t('invoice_templates.emails_defaults.signature_info')}
                dataId="ClientEmailDefaults:input-proposal-signature"
                isDisabledWithUnderline
              />
            </InputsGroup>
          </FormFooterContainer>
        </FormRow>
      </>
    </DefaultValuesForm>
  );
};

export default flow(
  withTransitionPrevent(),
  reduxForm<FormData, ProposalEmailsDefaultsProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    persistentSubmitErrors: true,
  }),
  connector
)(ProposalEmailsDefaults);
