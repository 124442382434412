import React from 'react';

import Currency from '../Currency/Currency';

import styles from './TodoFeatureDetailsHeader.module.css';

type TodoFeatureDetailsHeaderProps = {
  title: string;
  value: number | string;
};

const TodoFeatureDetailsHeader = ({ title, value }: TodoFeatureDetailsHeaderProps) => (
  <div className={styles.container}>
    <span>{title}</span>
    <Currency value={value} shouldDisplayColors={false} />
  </div>
);

export default TodoFeatureDetailsHeader;
