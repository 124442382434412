import styled, { css } from 'styled-components';

import media from 'shared/styles/breakpoints';
import Edit from 'redesign/components/atoms/Icons/Edit';

import { Row as BaseRow, Value as BaseValue } from '../Tooltip/Tooltip.styled';

export const Wrapper = styled.div`
  position: relative;
  padding: 24px 40px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 13px;
    width: calc(100% - (2 * 13px));
    height: 1px;
    background: ${({ theme }) => theme.colors.grey['070']};
  }
`;

export const TaxInfoContainer = styled.div`
  display: inline-block;
  max-width: 250px;

  ${media.greaterThan('sm-up')`
    max-width: 400px;
  `};
`;

export const TaxInfoDescription = styled.p`
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 0;
`;

const TaxInfoData = css`
  display: inline-block;
  font-weight: bold;
  font-size: 1.1rem;
`;

export const TaxDate = styled.p`
  ${TaxInfoData}
  margin-bottom: 20px;
`;

export const OrangeSquare = styled.div`
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-top: 5px;
  margin-right: 8px;
  vertical-align: top;
  background: ${({ theme }) => theme.colors.vrorange[100]};
`;

export const EditIcon = styled(Edit)`
  width: 22px;
  margin-left: 8px;
  vertical-align: text-top;
  color: ${({ theme }) => theme.colors.vrblue[100]};
`;

interface ITaxValue {
  isNegative?: boolean;
}
export const TaxValue = styled.div<ITaxValue>`
  ${TaxInfoData}
  color: ${({ isNegative, theme }) =>
    isNegative ? theme.colors.red[100] : theme.colors.green[125]};
`;

export const LiquidityDangerControl = styled.div`
  float: left;
  margin-top: 20px;

  ${media.greaterThan('md-up')`
    float:right;
    margin-top: -3px;
  `};
`;

export const TooltipWrapper = styled.div`
  font-size: 15px;
  font-weight: bold;
  line-height: 1.7;
  background: white;
  color: black;
`;

export const VerticalLine = styled.div`
  display: block;
  width: 100%;
  border-top: 1px solid black;
  margin: 5px 0;
`;

export const Row = styled(BaseRow)`
  white-space: nowrap;

  h5 {
    margin-right: 10px;
  }
`;

export const Value = styled(BaseValue)``;
