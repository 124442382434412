import React, { useCallback, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Link } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { useMedia, useUpdateEffect } from 'react-use';
import CookieModal from '@vr-smart-finanz/cookie-banner';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { logout } from 'actions/auth';
import { getContents } from 'api/contents';
import EntityPath from 'constants/entitiesPaths';
import AppLogo from 'images/app-logo.svg';
import { messagesEnabledHelper, todoListEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'shared/hooks/app';
import { t } from 'shared/utils';
import type { Content } from 'types/entities/Content';
import LegalsComponent from 'components/Legals';
import SiteSearch from 'redesign/components/molecules/SiteSearch/SiteSearch';
import { Breakpoints } from 'redesign/styles/breakpoints';

import {
  Arrow,
  IconClose,
  IconHamburger,
  IconSearch,
  IconUserprofile,
  Logo,
  Menu,
  MenuItem,
  MenuItemUsername,
  SearchContent,
  SearchIconWrapper,
  SearchWrapper,
  Username,
  UserProfile,
  UserProfileWrapper,
  Wrapper,
} from './Header.styled';
import { useCookies } from './hooks';
import { trackEvent } from './utils';

type HeaderProps = {
  onHamburgerClick: () => void;
};

const Header = ({ onHamburgerClick }: HeaderProps) => {
  const dispatch = useAppDispatch();
  const isMobile = useMedia(
    `(max-width: ${Breakpoints.mdMax}), not all and (any-hover: hover) and (any-pointer: fine)`
  );
  const [contents, setContents] = useState<Content[]>();
  const [activeContent, setActiveContent] = useState<Content | null>(null);
  const todoListEnabled = useAppSelector(todoListEnabledHelper);
  const messagesEnabled = useAppSelector(messagesEnabledHelper);
  const areNotificationsShown = todoListEnabled || messagesEnabled;
  const { firstName, lastName } = useAppSelector((state) => state.profile.credentials);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const profileMenuRef = useRef(null);
  const [profileMenuPopper, setProfileMenuPopper] = useState<HTMLElement | null>(null);
  const [showCookiesModal, cookiesPreselected, setShowCookiesModal, handleSaveCookies] =
    useCookies();
  const { styles, attributes } = usePopper(profileMenuRef.current, profileMenuPopper, {
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const handleLogoClick = useCallback(() => {
    trackEvent('logo');
  }, []);

  const handleHamburgerClick = useCallback(() => {
    onHamburgerClick();
    trackEvent('burger_open');
  }, [onHamburgerClick]);

  const handleShowProfileMenu = useCallback(() => {
    setShowProfileMenu((show) => !show);
  }, []);

  const handleCloseProfileMenu = useCallback(() => {
    setShowProfileMenu(false);
  }, []);

  const handleShowSearch = useCallback(() => {
    setShowSearch((show) => !show);
  }, []);

  const handleCloseSearch = useCallback(() => {
    setShowSearch(false);
  }, []);

  const handleLogout = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      event.stopPropagation();
      trackEvent('profile_logout');
      setShowProfileMenu(false);
      dispatch(logout());
    },
    [dispatch]
  );

  const handleContentClick = useCallback(
    (content: Content) => () => {
      setShowProfileMenu(false);
      setActiveContent(content);
    },
    []
  );

  const handleCloseContent = useCallback(() => {
    setActiveContent(null);
  }, []);

  const handleShowCookiesModal = useCallback(() => {
    setShowProfileMenu(false);
    setShowCookiesModal();
  }, [setShowCookiesModal]);

  useClickAway(profileMenuRef, handleCloseProfileMenu);

  useUpdateEffect(() => {
    trackEvent(`profile_${showProfileMenu ? 'open' : 'close'}`);
  }, [showProfileMenu]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getContents();
      const contents = build<Content>(normalize(response.data), EntityPath.Contents) || [];

      setContents(contents);
    };

    fetch();
  }, []);

  return (
    <Wrapper $areNotificationsShown={areNotificationsShown} data-id="main-header">
      <Logo as={Link} to={paths.home} onClick={handleLogoClick}>
        <img src={AppLogo} alt="VR Smart Guide" />
      </Logo>
      <IconHamburger onClick={handleHamburgerClick} />
      <SearchWrapper>
        {isMobile ? (
          <>
            <SearchIconWrapper onClick={handleShowSearch} $isActive={showSearch}>
              <IconSearch />
            </SearchIconWrapper>
            <SearchContent $isActive={showSearch} $areNotificationsShown={areNotificationsShown}>
              <SiteSearch onChange={handleCloseSearch} /> <IconClose onClick={handleCloseSearch} />
            </SearchContent>
          </>
        ) : (
          <SiteSearch />
        )}
      </SearchWrapper>
      <UserProfileWrapper ref={profileMenuRef} data-id="profile-menu">
        <UserProfile onClick={handleShowProfileMenu} $isActive={showProfileMenu}>
          <IconUserprofile />
          <Username>
            {firstName} {lastName}
          </Username>
          <Arrow $isOpen={showProfileMenu} />
        </UserProfile>
        {showProfileMenu && (
          <Menu ref={setProfileMenuPopper} style={styles.popper} {...attributes.popper}>
            <MenuItemUsername>
              {t('redesign.organisms.header.logged_in_as')} {firstName} {lastName}
            </MenuItemUsername>
            {contents
              ?.filter(({ web, showOnFooter }) => web && showOnFooter)
              .map((content) => (
                <MenuItem
                  key={content.id}
                  data-id={`LegalsFooter:button(${content.slug})`}
                  onClick={handleContentClick(content)}
                >
                  {content.title}
                </MenuItem>
              ))}
            <MenuItem onClick={handleShowCookiesModal}>
              {t('redesign.organisms.header.cookies')}
            </MenuItem>
            <MenuItem
              $color="red"
              as="a"
              href={paths.logout}
              onClick={handleLogout}
              rel="noreferrer noopener"
              data-id="logout"
            >
              {t('redesign.organisms.header.logout')}
            </MenuItem>
          </Menu>
        )}
      </UserProfileWrapper>
      {activeContent && (
        <LegalsComponent {...activeContent} isOpen onClose={handleCloseContent} isModal />
      )}
      <CookieModal
        dataProtectionUrl="https://www.vr-smart-guide.de/datenschutz-erklaerung"
        legalNoticeUrl="https://www.vr-smart-guide.de/impressum"
        onSave={handleSaveCookies}
        preselected={cookiesPreselected}
        show={showCookiesModal}
      />
    </Wrapper>
  );
};

export default Header;
