import React, { FunctionComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteProps, withRouter } from 'react-router-dom';
import cx from 'classnames';
import { parse } from 'query-string';
import { compose } from 'redux';

import CardView, { HeadingSection, Section } from 'components/CardView';
import Logo from 'components/Logo';

import formPageStyles from '../../Shared.module.css';
import localStyles from './Page.module.css';

interface PageMergeProps {
  iframe: string;
}

interface PageOwnProps {
  header: ReactNode;
  children: ReactNode;
}

interface PageProps extends PageMergeProps, PageOwnProps {}

const Page: FunctionComponent<PageProps> = ({ iframe, header, children }) => (
  <div className={formPageStyles.pageWrapper}>
    <div
      className={cx(formPageStyles.wrapper, {
        [localStyles.embedded]: iframe,
      })}
    >
      <div className={localStyles.mobileHeader}>
        <Logo />
      </div>
      <CardView
        className={cx(formPageStyles.card, {
          [localStyles.embeddedCard]: iframe,
        })}
      >
        <HeadingSection className={localStyles.cardSectionHeading} bold>
          {header}
        </HeadingSection>
        <Section className={cx(localStyles.cardSection)}>{children}</Section>
      </CardView>
    </div>
  </div>
);

const mergeProps = (
  _stateProps: null,
  _dispatchProps: null,
  ownProps: PageOwnProps & RouteProps
) => {
  const { iframe = '' } = parse(ownProps.location ? ownProps.location.search : '');

  return {
    ...ownProps,
    iframe,
  };
};

export default compose<FunctionComponent<PageOwnProps>>(
  withRouter,
  connect<null, null, PageProps>(null, null, mergeProps)
)(Page);
