import { CALL_NOTIFICATION, HIDE } from 'constants/notification';

export const NotificationVariants = {
  SUCCESS: 'success',
  ERROR: 'warning',
};

export const DEFAULT_NOTIFICATION_DURATION = 3000;

export const showNotification = (options: any, customOptions?: any) => ({
  [CALL_NOTIFICATION]: {
    ...options,
    ...customOptions,
    duration: options.duration || DEFAULT_NOTIFICATION_DURATION,
  },
});

export const hideNotification = (id: number) => ({
  type: HIDE,
  id,
});

export const showSuccessNotification = (message: string) =>
  showNotification({
    duration: DEFAULT_NOTIFICATION_DURATION,
    title: message,
    variant: NotificationVariants.SUCCESS,
  });

export const showErrorNotification = (
  message: string,
  { duration, CustomMessage }: { duration?: number; CustomMessage?: any } = {}
) =>
  showNotification({
    duration: duration || DEFAULT_NOTIFICATION_DURATION,
    title: message,
    variant: NotificationVariants.ERROR,
    CustomMessage,
  });
