import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { bool, func, shape, string } from 'prop-types';

import { fetchUserPlan as fetchUserPlanAction } from 'actions/payment-plan';
import { downgradeCancellation as downgradeCancellationAction } from 'actions/payment-plan/payment-plan';
import { CREATE_CANCELLATION_SUCCESS } from 'constants/payment-plan';
import { BOOK_PREMIUM, DOWNGRADE, PREMIUM_UID, SWTICH_TO_BASIC } from 'constants/payment-plans';

import BookDowngradeButton from './components/BookDowngradeButton';
import CancelBasicModal from './components/CancelBasicModal';

class ButtonPrimary extends PureComponent {
  state = {
    isOpen: false,
    isLoading: false,
  };

  openModal = () => this.setState({ isOpen: true });

  closeModal = () => this.setState({ isOpen: false });

  handleAction = () => {
    const { currentPaymentPlanDetails, currentPlan, handleModalOpen } = this.props;
    const shouldTriggerCancelBasicModal =
      currentPaymentPlanDetails.uid === PREMIUM_UID &&
      !currentPaymentPlanDetails.onTrial &&
      currentPaymentPlanDetails.latestHistoryEntryMode === DOWNGRADE;

    if (shouldTriggerCancelBasicModal) return this.openModal();

    return handleModalOpen(currentPlan === PREMIUM_UID ? BOOK_PREMIUM : SWTICH_TO_BASIC);
  };

  handleDowngradeCancellation = () => {
    const { downgradeCancellation, fetchUserPlan } = this.props;
    this.setState({ isLoading: true });

    downgradeCancellation().then((response) => {
      if (response.type === CREATE_CANCELLATION_SUCCESS) {
        fetchUserPlan();
      }

      this.closeModal();
      return this.setState({ isLoading: false });
    });
  };

  render() {
    const { isOpen, isLoading } = this.state;

    return (
      <Fragment>
        <BookDowngradeButton {...this.props} onClick={this.handleAction} />
        {isOpen && (
          <CancelBasicModal
            isOpen={isOpen}
            onAccept={this.handleDowngradeCancellation}
            onClose={this.closeModal}
            buttonDisabled={isLoading}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPaymentPlanDetails: state.paymentPlan.details,
});

const mapDispatchToProps = (dispatch) => ({
  downgradeCancellation: () => dispatch(downgradeCancellationAction()),
  fetchUserPlan: () => dispatch(fetchUserPlanAction()),
});

ButtonPrimary.propTypes = {
  currentPlan: string.isRequired,
  handleModalOpen: func.isRequired,
  downgradeCancellation: func.isRequired,
  fetchUserPlan: func.isRequired,
  currentPaymentPlanDetails: shape({
    onTrial: bool.isRequired,
    latestHistoryEntryMode: string.isRequired,
  }).isRequired,
};

export const DetachedPrimaryButton = ButtonPrimary;

export default connect(mapStateToProps, mapDispatchToProps)(ButtonPrimary);
