import React from 'react';

const BankTransfers = ({ dataId = '', className = '' }) => (
  <svg width={288} height={181} data-id={dataId} className={className}>
    <defs>
      <filter
        x="-12.7%"
        y="-11%"
        width="128.8%"
        height="128.8%"
        filterUnits="objectBoundingBox"
        id="prefix__a"
      >
        <feOffset dx={2} dy={4} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-27.9%"
        y="-18.3%"
        width="155.9%"
        height="142.4%"
        filterUnits="objectBoundingBox"
        id="prefix__c"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={4.5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-9.7%"
        y="-7%"
        width="119.3%"
        height="119.7%"
        filterUnits="objectBoundingBox"
        id="prefix__e"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <ellipse id="prefix__f" cx={36.219} cy={35.5} rx={36.219} ry={35.5} />
      <circle id="prefix__b" cx={59} cy={59} r={59} />
      <path id="prefix__d" d="M33.04 24.387h51.92v68.44H33.04z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M98.402 12.813c28.698 9.784 37.439 19.029 59.422 9.784 21.984-9.244 38.437-9.45 58.784-9.45 20.346 0 21.372 15.25 39.001 28.922 17.63 13.67 47.79 22.406 23 57.843-24.789 35.436-.625 2.016-42 36.9s-99.004 21.94-127.005 14.96c-28.001-6.982-25.374-20.259-52.002-33.909-26.628-13.65-47.255-8.055-55.858-20.189C-6.86 85.541 18.865 62.7 24.6 49.05 30.336 35.4 69.704 3.028 98.402 12.813z"
        fill="#F3F3F3"
      />
      <g transform="translate(42 11)">
        <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
        <use fill="#FFF" xlinkHref="#prefix__b" />
      </g>
      <g transform="translate(42 11)">
        <use fill="#000" filter="url(#prefix__c)" xlinkHref="#prefix__d" />
        <use fill="#FFF" xlinkHref="#prefix__d" />
      </g>
      <path
        d="M82.932 49.562h12.882a.803.803 0 00.812-.795.803.803 0 00-.812-.794H82.932a.803.803 0 00-.812.794c0 .439.364.795.812.795zm-.002 17.47l34.1.099a.803.803 0 00.815-.792.803.803 0 00-.81-.796l-34.1-.1a.803.803 0 00-.815.792.803.803 0 00.81.797zm.239-14.294h10.893a.803.803 0 00.812-.794.803.803 0 00-.812-.794H83.169a.803.803 0 00-.812.794c0 .438.363.794.812.794zm-.003 17.47l25.744.1a.803.803 0 00.815-.792.803.803 0 00-.808-.797l-25.745-.1a.803.803 0 00-.815.792.803.803 0 00.809.797zm-.234-14.294h12.882a.803.803 0 00.812-.794.803.803 0 00-.812-.794H82.932a.803.803 0 00-.812.794c0 .439.364.794.812.794zm-.002 17.47l35.724.1a.803.803 0 00.814-.792.803.803 0 00-.81-.797l-35.724-.099a.803.803 0 00-.814.792.803.803 0 00.81.796zm.408 20.555h8.931c.224 0 .406-.224.406-.5s-.182-.5-.406-.5h-8.931c-.224 0-.406.224-.406.5s.182.5.406.5zm15.832 0h8.932c.224 0 .406-.224.406-.5s-.182-.5-.406-.5H99.17c-.224 0-.406.224-.406.5s.182.5.406.5zm-15.832 2.382h6.495c.224 0 .406-.224.406-.5s-.182-.5-.406-.5h-6.495c-.224 0-.406.224-.406.5s.182.5.406.5zm15.832 0h6.496c.224 0 .406-.224.406-.5s-.182-.5-.406-.5H99.17c-.224 0-.406.224-.406.5s.182.5.406.5zm-15.832 2.383h8.931c.224 0 .406-.224.406-.5s-.182-.5-.406-.5h-8.931c-.224 0-.406.223-.406.5s.182.5.406.5zm15.832 0h8.932c.224 0 .406-.224.406-.5s-.182-.5-.406-.5H99.17c-.224 0-.406.223-.406.5s.182.5.406.5z"
        fill="#979797"
        fillRule="nonzero"
      />
      <g fill="#2382C8">
        <path d="M119.249 81.843h-6.51a.735.735 0 01-.726-.744c0-.412.325-.746.726-.746h6.51c.4 0 .725.334.725.746a.735.735 0 01-.726.744m0 2.214h-6.51a.736.736 0 01-.725-.746c0-.41.325-.744.726-.744h6.51c.4 0 .725.332.725.745a.736.736 0 01-.726.746" />
        <path d="M118.45 87.228c-2.853 0-5.175-2.276-5.175-5.073 0-2.798 2.322-5.075 5.175-5.075a5.25 5.25 0 011.979.387c.378.153.557.579.4.95a.742.742 0 01-.964.389 3.762 3.762 0 00-1.414-.276c-2.039 0-3.696 1.627-3.696 3.625 0 1.999 1.656 3.624 3.696 3.624.487 0 .964-.092 1.414-.275a.744.744 0 01.965.393.717.717 0 01-.4.947 5.225 5.225 0 01-1.978.387" />
      </g>
      <path
        stroke="#0066B3"
        strokeWidth={1.294}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M87.028 158.102h17.406v17.262H87.028z"
      />
      <path
        d="M108.719 156.597l-11.613 16.176c-.33.46-.839.69-1.346.687a1.636 1.636 0 01-1.339-.7l-5.042-7.176a1.642 1.642 0 01-.274-1.23 1.646 1.646 0 012.36-1.19l4.298 5.832 10.28-14.32c.265-.37.655-.596 1.071-.665.416-.068.858.022 1.227.288a1.642 1.642 0 01.377 2.298z"
        stroke="#FFF"
        strokeWidth={2}
        fill="#F60"
        fillRule="nonzero"
      />
      <path
        d="M133.207 157.326h.09c.974.093 1.958.148 2.954.167l.749.007h.245a.5.5 0 01.091.992l-.09.008H137c-1.283 0-2.549-.06-3.798-.178a.5.5 0 01-.082-.981l.087-.015zm12.02-.018a.5.5 0 01.049.999c-1.318.063-2.654.11-4.01.143a.5.5 0 01-.023-1c1.347-.032 2.675-.08 3.985-.142zm8.474-.148a.5.5 0 01-.446.549c-1.311.136-2.644.254-3.998.355a.5.5 0 01-.074-.998c1.344-.1 2.667-.217 3.97-.352a.5.5 0 01.548.446zm-28.13-1.422c1.25.404 2.523.739 3.815 1.004a.5.5 0 01-.201.98 36.835 36.835 0 01-3.922-1.032.5.5 0 01.307-.952zm36.072.308a.5.5 0 01-.409.577c-1.3.222-2.623.425-3.97.608a.5.5 0 11-.135-.991c1.337-.181 2.649-.382 3.937-.602a.5.5 0 01.577.408zm7.81-1.689a.5.5 0 01-.362.608 96.132 96.132 0 01-3.917.909.5.5 0 01-.206-.979 95.132 95.132 0 003.877-.899.5.5 0 01.608.361zm-51.088-1.844a37.817 37.817 0 003.512 1.804.5.5 0 11-.41.912 38.817 38.817 0 01-3.604-1.852.5.5 0 01.502-.864zm58.747-.544a.5.5 0 01-.301.64 79.205 79.205 0 01-3.821 1.269.5.5 0 11-.291-.957 78.206 78.206 0 003.773-1.252.5.5 0 01.64.3zm-65.216-4.098a45.051 45.051 0 003.102 2.454.5.5 0 01-.585.811 46.05 46.05 0 01-3.17-2.508.5.5 0 01.653-.757zm72.551.905a.5.5 0 01-.223.671 65.75 65.75 0 01-3.66 1.69.5.5 0 11-.39-.92 64.75 64.75 0 003.602-1.664.5.5 0 01.671.223zm-78.099-6.492a57.062 57.062 0 002.69 2.914.5.5 0 01-.71.704 58.062 58.062 0 01-2.737-2.966.5.5 0 01.757-.652zm-4.832-6.344a73.76 73.76 0 002.315 3.23.5.5 0 11-.796.604 74.759 74.759 0 01-2.347-3.273.5.5 0 01.828-.56zm-4.171-6.821a94.693 94.693 0 001.998 3.44.5.5 0 11-.853.521 95.692 95.692 0 01-2.02-3.477.5.5 0 01.875-.484zm-6.55-12.575l7.792 6.367-3.632 1.63.156.316c.137.276.275.55.413.822a.5.5 0 01-.892.452c-.198-.389-.394-.782-.59-1.178l-3.666 1.644.42-10.053z"
        fill="#F60"
        fillRule="nonzero"
      />
      <g transform="translate(173 92)">
        <use fill="#000" filter="url(#prefix__e)" xlinkHref="#prefix__f" />
        <use fill="#FFF" xlinkHref="#prefix__f" />
      </g>
      <g fillRule="nonzero">
        <path
          d="M212.969 129.393c-1.26.026-2.31.735-2.951 1.764a18 18 0 012.951-1.764"
          fill="#F26F21"
        />
        <path
          d="M209.848 125.742c0 .913-1.693 2.238-4.345 2.238-2.652 0-4.344-1.325-4.344-2.238 0-.011-.005-.02-.005-.033 0-.743 0-1.487-.002-2.218 1.112.753 2.627 1.207 4.35 1.207 1.726 0 3.242-.454 4.354-1.208v2.216c-.002.012-.008.024-.008.036m-4.345-12.007c2.626 0 4.305 1.32 4.339 2.245-.034.927-1.713 2.246-4.34 2.246-2.624 0-4.302-1.32-4.336-2.246.034-.926 1.712-2.245 4.337-2.245m-4.353 4.895c1.111.766 2.628 1.225 4.353 1.225 1.727 0 3.244-.46 4.356-1.226v2.168c-.002.02-.01.037-.01.058 0 .924-1.694 2.265-4.346 2.265s-4.344-1.341-4.344-2.265c0-.019-.008-.032-.008-.05v-2.175m10.625-2.96v-.007c0-.01-.006-.02-.006-.032-.068-2.23-2.736-3.901-6.267-3.901-3.53 0-6.198 1.671-6.264 3.901 0 .011-.007.021-.007.032v.014c0 .022-.01.041-.01.064l.01 19.066c0 2.25 2.755 4.011 6.27 4.011 1.276 0 2.447-.234 3.427-.638l-.003-2.23c-.754.48-1.928.863-3.423.863-2.652 0-4.344-1.188-4.344-2.006v-1.495c1.11.665 2.623 1.065 4.344 1.065 1.713 0 3.221-.396 4.33-1.056-.568-.475-.9-1.047-.9-1.662 0-.05.008-.097.013-.145-.754.476-1.935.858-3.443.858-2.652 0-4.344-1.173-4.344-1.98l-.002-.014c0-.623 0-1.287-.002-1.976 1.112.666 2.626 1.067 4.348 1.067 1.724 0 3.238-.401 4.35-1.068 0 .545 0 1.075-.002 1.58.49-.404 1.154-.735 1.928-.963l.01-13.277c0-.025-.012-.047-.013-.07"
          fill="#F26F21"
        />
        <path
          d="M214.239 142.73c-2.652 0-4.344-1.169-4.344-1.973l-.002-1.561c1.111.666 2.625 1.066 4.346 1.066 1.722 0 3.237-.401 4.348-1.067l-.002 1.566c0 .802-1.693 1.969-4.346 1.969m-4.344-11.072c0-.807 1.692-1.979 4.344-1.979 2.653 0 4.346 1.172 4.346 1.98s-1.693 1.98-4.346 1.98c-2.652 0-4.344-1.172-4.344-1.98m8.696 4.583c0 .012-.006.022-.006.034 0 .807-1.693 1.98-4.346 1.98-2.652 0-4.344-1.173-4.344-1.98 0-.011-.005-.02-.005-.031l-.002-1.67c1.11.668 2.626 1.07 4.351 1.07 1.726 0 3.242-.403 4.354-1.07l-.002 1.667m1.92-4.584c0-2.272-2.696-3.984-6.272-3.984-3.575 0-6.27 1.712-6.27 3.984 0 .017.003.033.003.05-.002.027-.015.05-.015.078l.011 8.971c0 2.267 2.696 3.978 6.271 3.978 3.576 0 6.273-1.709 6.273-3.973l.012-8.976c0-.03-.013-.056-.015-.085l.003-.043"
          fill="#0066B2"
        />
      </g>
      <path
        d="M218.7 79.382a.537.537 0 01.503.762l-3.717 8.044a.537.537 0 01-.96.03l-4.205-7.8a.537.537 0 01.456-.792zm-3.97-5.174c.155 1.31.287 2.643.395 4a.5.5 0 01-.997.079 104.633 104.633 0 00-.39-3.962.5.5 0 11.993-.117zm-1.288-7.974c.27 1.293.513 2.612.73 3.957a.5.5 0 01-.988.159 86.545 86.545 0 00-.721-3.912.5.5 0 11.979-.204zm-2.054-7.81c.405 1.258.781 2.547 1.127 3.865a.5.5 0 01-.967.254 71.741 71.741 0 00-1.112-3.813.5.5 0 01.952-.307zm-2.951-7.53a61.407 61.407 0 011.59 3.706.5.5 0 11-.931.364 60.408 60.408 0 00-1.564-3.646.5.5 0 01.905-.424zm-3.95-7.06a53.748 53.748 0 012.1 3.45.5.5 0 11-.873.487 52.748 52.748 0 00-2.06-3.385.5.5 0 01.834-.552zm-4.985-6.373c.91.988 1.782 2.015 2.615 3.08a.5.5 0 01-.788.616 48.915 48.915 0 00-2.562-3.018.5.5 0 01.735-.678zm-5.893-5.438a49.977 49.977 0 013.076 2.619.5.5 0 11-.678.735 48.976 48.976 0 00-3.015-2.566.5.5 0 11.617-.788zm-6.754-4.488a53.948 53.948 0 013.448 2.103.5.5 0 01-.553.834 52.948 52.948 0 00-3.384-2.065.5.5 0 01.49-.872zm-7.315-3.453a61.743 61.743 0 013.703 1.595.5.5 0 01-.426.905 60.743 60.743 0 00-3.643-1.57.5.5 0 11.366-.93zm-7.756-2.517l.089.015c1.318.35 2.606.73 3.864 1.139a.5.5 0 01-.309.95 72.172 72.172 0 00-3.812-1.122.5.5 0 01.08-.981h.088zM163.92 19.9l.09.006c1.345.222 2.663.47 3.956.745a.5.5 0 01-.208.978 87.014 87.014 0 00-3.91-.736.5.5 0 01-.015-.984l.087-.01zm-7.902-.977c1.355.114 2.687.25 3.997.41a.5.5 0 11-.122.994 105.144 105.144 0 00-3.959-.408.5.5 0 01.084-.996zm-20.061-.114a.5.5 0 11.062.998c-1.31.082-2.635.18-3.978.293a.5.5 0 11-.084-.996c1.35-.115 2.683-.213 4-.295zm12.043-.286c1.36.024 2.697.068 4.015.134a.5.5 0 11-.049.998 126.263 126.263 0 00-3.984-.133.5.5 0 11.018-1zm-4.024-.013a.5.5 0 01.012 1c-1.31.014-2.639.047-3.986.098a.5.5 0 11-.037-1c1.355-.05 2.692-.083 4.011-.098z"
        fill="#F60"
        fillRule="nonzero"
      />
      <g fill="#0066B3" fillRule="nonzero" stroke="#FFF">
        <path d="M213.3 42.374l1.534-2.418c.81-1.236 1.698-2.046 2.619-2.424.964-.395 1.975-.344 3.011.216a1.246 1.246 0 01.505 1.694 1.246 1.246 0 01-1.694.505c-.357-.193-.707-.209-1.069-.007-.454.253-.907.778-1.406 1.583l-1.378 2.173c-.354.578-.542 1.062-.527 1.487.014.36.194.656.502.927.587.319 1.073.463 1.501.388.425-.075.767-.373 1.096-.83l1.567-2.623a1.246 1.246 0 011.714-.431 1.246 1.246 0 01.432 1.714l-1.62 2.706c-.782 1.108-1.72 1.743-2.747 1.937-1.017.192-2.14-.049-3.319-.77-.991-.8-1.488-1.69-1.595-2.665-.105-.964.18-2.028.875-3.162z" />
        <path d="M226.36 37.526l-1.616 2.364c-.852 1.206-1.769 1.985-2.702 2.33-.977.362-1.987.275-3.002-.32a1.246 1.246 0 01-.446-1.711 1.246 1.246 0 011.71-.446c.35.205.7.233 1.069.045.463-.238.934-.747 1.46-1.533l1.453-2.124c.374-.566.58-1.042.579-1.467-.001-.36-.17-.663-.469-.944-.576-.34-1.057-.501-1.488-.44-.43.062-.783.352-1.131.804l-1.664 2.49a1.246 1.246 0 01-1.734.345 1.246 1.246 0 01-.345-1.734l1.71-2.555c.82-1.079 1.778-1.68 2.81-1.838 1.023-.156 2.137.124 3.29.885.963.835 1.428 1.741 1.5 2.72.072.967-.25 2.02-.984 3.13z" />
      </g>
    </g>
  </svg>
);

export default BankTransfers;
