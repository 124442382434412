import { PROCESSING_STATUS as ocrProcessingStatus } from 'constants/common/ocr';

const NAMESPACE = 'INCOMING_INVOICE/OCR';

export const PROCESSING_STATUS = ocrProcessingStatus;

export const PROCESS_REQUEST = `${NAMESPACE}/PROCESS_REQUEST`;
export const PROCESS_SUCCESS = `${NAMESPACE}/PROCESS_SUCCESS`;
export const PROCESS_FAILURE = `${NAMESPACE}/PROCESS_FAILURE`;

export const GET_REQUEST = `${NAMESPACE}/GET_REQUEST`;
export const GET_SUCCESS = `${NAMESPACE}/GET_SUCCESS`;
export const GET_FAILURE = `${NAMESPACE}/GET_FAILURE`;
