import { get } from 'lodash';

const fileTypes = ['contract-files', 'invoice-files', 'outgoing-invoice-files'];

export const DEFAULT_UPLOAD_INFO = {
  filesCount: 0,
  filesSize: 0,
  haveFilesMetOCRRequirements: false,
};

export const extractUploadedInvoiceFiles = (action) => {
  if (!get(action, 'response.included')) return [];

  return get(action, 'response.included')
    .filter((i) => fileTypes.includes(i.type))
    .map((i) => ({
      id: get(i, 'id'),
      extension: get(i, 'attributes.extension'),
      thumbnail: get(i, 'attributes.thumbnail'),
      sizeMebibytes: get(i, 'attributes.sizeMebibytes'),
    }));
};

export default {};
