import { isEmpty } from 'lodash';
import moment from 'moment';

import { DATE_FORMAT_FULL_REVERSED } from 'constants/datetime';
import { t } from 'shared/utils';
import { filenameRegex } from 'shared/utils/regex';

const sanitizeNumber = (number = '') => (number ? number.replace(filenameRegex, '_') : '');
const previewSuffix = t('filenames.preview');

export const incomingInvoiceUploadedFilename = (invoiceNumber, fileId) =>
  t('filenames.incoming_invoice_uploaded', {
    invoiceNumber,
    fileId,
  });

export const outgoingInvoiceUploadedFilename = (invoiceNumber, fileId) =>
  t('filenames.outgoing_invoice_uploaded', {
    invoiceNumber,
    fileId,
  });

export const contractFilename = (
  _contractId,
  _fileId,
  fileSubject,
  contractNumber = '',
  fileNumber
) =>
  !isEmpty(fileSubject)
    ? t('filenames.contract_subject', {
        contractNumber: !!contractNumber ? contractNumber : '',
        fileNumber,
        fileSubject: fileSubject.replace(/ /g, '_'),
      })
    : t('filenames.contract', {
        contractNumber: !!contractNumber ? contractNumber : '',
        fileNumber,
        fileSubject,
      });

export const outgoingInvoiceFilename = ({ invoiceNumber }) =>
  t('filenames.outgoing_invoice', {
    invoiceNumber: sanitizeNumber(invoiceNumber) || previewSuffix,
  });

export const correctionInvoiceFilename = ({ invoiceNumber }) =>
  t('filenames.correction_invoice', {
    invoiceNumber: sanitizeNumber(invoiceNumber) || previewSuffix,
  });

export const cancellationInvoiceFilename = ({ invoiceNumber }) =>
  t('filenames.cancellation_invoice', {
    invoiceNumber: sanitizeNumber(invoiceNumber) || previewSuffix,
  });

export const paymentReminderFilename = ({ invoiceNumber, ...rest }) =>
  t('filenames.payment_reminder', {
    ...rest,
    invoiceNumber: sanitizeNumber(invoiceNumber),
    currentDate: moment().format(DATE_FORMAT_FULL_REVERSED),
  });

export const proposalFilename = ({ proposalNumber }) =>
  t('filenames.proposal', {
    proposalNumber: sanitizeNumber(proposalNumber) || previewSuffix,
  });

export const orderConfirmationFilename = ({ orderConfirmationNumber }) =>
  t(
    `${
      orderConfirmationNumber?.charAt(orderConfirmationNumber?.length - 1) === 'S'
        ? 'filenames.order_confirmation_cancelled'
        : 'filenames.order_confirmation'
    }`,
    {
      orderConfirmationNumber: sanitizeNumber(orderConfirmationNumber) || previewSuffix,
    }
  );

export const deliveryNoteFilename = ({ deliveryNoteNumber }) =>
  t('filenames.delivery_note', {
    deliveryNoteNumber: sanitizeNumber(deliveryNoteNumber) || previewSuffix,
  });
