import React, { memo } from 'react';
import { matchPath } from 'react-router-dom';

import paths from 'routes/paths';
import { t } from 'shared/utils';
import IconExport from 'redesign/components/atoms/Icons/Export';

import { trackEventHandler } from '../utils';
import { Link, MenuItem } from './Shared.styled';

type ExportProps = {
  pathname: string;
};

const Export = ({ pathname }: ExportProps) => {
  const isDatev = !!matchPath(pathname, paths.datev);

  return (
    <MenuItem $isActive={isDatev}>
      <Link
        $level={1}
        to={paths.datev}
        $isActive={isDatev}
        data-id="export"
        onClick={trackEventHandler('export')}
      >
        <IconExport />
        {t('redesign.organisms.navigation.export')}
      </Link>
    </MenuItem>
  );
};

export default memo(Export);
