import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { addItemToProductCatalogGroup } from 'actions/product-catalog/groups/groups';
import { EDIT_MODE } from 'constants/common/crud';
import { productCatalogGroupSelector } from 'reducers/form';
import { t } from 'shared/utils';
import Card from 'components/Card';
import { TextField } from 'components/Form';
import FormField from 'components/Form/FormField';
import I18n from 'components/I18n';
import { LineItemsOrderManager } from 'components/LineItems';
import ProductCatalogItemsSelect from 'components/ProductCatalog/ItemsSelect';

import Table from './Table';

import styles from './GroupDetails.module.css';

class GroupDetails extends Component {
  state = {
    hasErrors: false,
  };

  addItem = async () => {
    const { addItem, groupId, quantity, selectItem, resetField } = this.props;
    if (!selectItem || !quantity) return;

    try {
      await addItem({ groupId, itemId: selectItem, quantity });
      resetField('quantity');
      resetField('selectItem');

      this.setState({ hasErrors: false });
    } catch (error) {
      this.setState({ hasErrors: true });
    }
  };
  render() {
    const { readonly, groupId, productCatalogItems, selectItem, quantity, areLineItemsEmpty } =
      this.props;

    return (
      <Card className={cx(styles.main, { [styles.readonly]: readonly })}>
        <Card.Header className={styles.heading}>
          <I18n className={styles.title} t="product_catalog.tab_headers.product_catalog_items" />
          <Field
            name="selectItem"
            className={styles.selectItem}
            dataId="selectItem"
            groupId={groupId}
            refetchOnOpen
            onSelectResetsInput={false}
            onBlurResetsInput
            onCloseResetsInput
            label={t('product_catalog.product_catalog_item.select_product_catalog_item')}
            component={ProductCatalogItemsSelect}
            productCatalogGroupItems={productCatalogItems}
            visible={!readonly}
          />
          <FormField
            name="quantity"
            className={styles.quantity}
            invalid={this.state.hasErrors}
            component={TextField}
            readonly={readonly}
          />
          <button
            className={styles.addItem}
            onClick={this.addItem}
            type="button"
            disabled={!selectItem || !quantity}
          >
            {t('product_catalog.product_catalog_group.add_product_catalog_item')}
          </button>
        </Card.Header>
        <Card.Body className={styles.cardBody}>
          <LineItemsOrderManager
            invoiceId={groupId}
            lineItems={productCatalogItems}
            updateLineItemsOrder={noop}
          >
            <Table
              groupId={groupId}
              existingItems={productCatalogItems}
              readonly={readonly}
              areLineItemsEmpty={areLineItemsEmpty}
              crudMode={EDIT_MODE}
            />
          </LineItemsOrderManager>
        </Card.Body>
      </Card>
    );
  }
}

GroupDetails.propTypes = {
  readonly: PropTypes.bool,
  groupId: PropTypes.string,
  quantity: PropTypes.string,
  selectItem: PropTypes.string,
  productCatalogItems: PropTypes.arrayOf(PropTypes.shape({})),
  addItem: PropTypes.func,
  resetField: PropTypes.func,
  areLineItemsEmpty: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  quantity: productCatalogGroupSelector(state, 'quantity'),
  selectItem: productCatalogGroupSelector(state, 'selectItem'),
});

const mapDispatchToProps = (dispatch) => ({
  addItem: (...args) => dispatch(addItemToProductCatalogGroup(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
