import React from 'react';
import { func, string } from 'prop-types';

import isPressedEnter from 'shared/utils/keyboard-events';
import IconNaviExpander from 'redesign/components/atoms/Icons/NaviExpander';

import styles from './SectionHeadline.module.css';

const SectionHeadline = ({ title, onArrowClick }) => (
  <div className={styles.container}>
    <span>{title}</span>
    <div
      onClick={onArrowClick}
      className={styles.arrow}
      onKeyPress={isPressedEnter(onArrowClick)}
      role="button"
    >
      <IconNaviExpander />
    </div>
  </div>
);

SectionHeadline.propTypes = {
  title: string.isRequired,
  onArrowClick: func.isRequired,
};

export default SectionHeadline;
