import React from 'react';
import cx from 'classnames';
import { bool, func, node, string } from 'prop-types';

import { noop } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';

import styles from '../StatusFiltersGroup.module.css';

const StatusFilter = ({ onClick, name, currentFilter, children, isDefault, dataId = '' }) => {
  const isActive = name === currentFilter;
  const isDefaultFilter = !isDefault ? name : undefined;
  const handleOnClick = !isActive ? () => onClick(isDefaultFilter) : noop;
  return (
    <div
      onClick={handleOnClick}
      role="button"
      className={cx(styles.filter, {
        [styles.filterActive]: isActive,
      })}
      onKeyPress={isPressedEnter(handleOnClick)}
      tabIndex="0"
      data-id={dataId}
    >
      {children}
    </div>
  );
};

StatusFilter.propTypes = {
  onClick: func,
  name: string,
  currentFilter: string,
  children: node,
  isDefault: bool,
  dataId: string,
};

StatusFilter.defaultProps = {
  onClick: (x) => x,
  name: '',
  currentFilter: '',
};

export default StatusFilter;
