import { CALL_API } from 'constants/api';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SUCCESS,
} from 'constants/units';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { ApiAction, ApiResponse, CallApiAction, Dispatch } from 'types/actions';
import { CreatedUnit, FetchedUnits } from 'types/models/units';

export type FetchUnits = ApiResponse<typeof INDEX_SUCCESS, FetchedUnits>;
export type CreateUnit = ApiResponse<typeof CREATE_SUCCESS, CreatedUnit>;
export type UnitActions = FetchUnits | CreateUnit;

export const apiFetch = (): CallApiAction<FetchUnits> => ({
  [CALL_API]: {
    endpoint: '/me/customer_line_item_units',
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
  },
});

export const apiCreate = (name: string): CallApiAction<CreateUnit> => ({
  [CALL_API]: {
    payload: { name },
    endpoint: '/me/customer_line_item_units',
    method: 'POST',
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const fetchUnits = (): ApiAction<FetchUnits> => (dispatch: Dispatch) =>
  dispatch(apiFetch()).catch<FetchUnits>(apiErrorHandler);

export const createUnit =
  (name: string): ApiAction<CreateUnit> =>
  (dispatch: Dispatch) =>
    dispatch(apiCreate(name)).catch<CreateUnit>(apiErrorHandler);
