import React, { ComponentType, ReactNode, Ref } from 'react';
import Downshift, { GetInputPropsOptions } from 'downshift';
import { WrappedFieldProps } from 'redux-form';

import { t } from 'shared/utils';
import RequiredAddon from 'components/Form/RequiredAddon/RequiredAddon';
import SearchIcon from 'components/Form/SelectField/DownshiftSelect/components/SearchIcon';
import IconCancel from 'components/Icons/IconCancel';
import IconLoading from 'components/Icons/IconLoading';

import {
  Clear,
  EmptyText,
  HelpText,
  Input,
  InputWrapper,
  Label,
  LabelContent,
  Loading,
  Menu,
  MenuItem,
  MenuWrapper,
  ResultsHeader,
  Wrapper,
} from './SuggestionsField.styled';
import { stateReducer } from './utils';

export type SuggestionsFieldProps<Item> = {
  placeholder?: string;
  required?: boolean;
  label: string;
  disabled?: boolean;
  emptyText: ReactNode;
  helpText?: ReactNode;
  search: ComponentType<{
    text: string | null;
    children(props: { loading: boolean; data: Item[]; error: string | null }): ReactNode;
  }>;
  itemToString: (item: Item | null) => string;
  option: ComponentType<{ item: Item; isHighlighted?: boolean }>;
};

interface GetInputPropsOptionsRef extends GetInputPropsOptions {
  ref?: Ref<HTMLInputElement>;
  as?: undefined;
}

const SuggestionsField = <Item,>({
  input,
  meta,
  placeholder,
  required,
  label,
  disabled,
  emptyText,
  helpText,
  itemToString,
  search: Search,
  option: Option,
}: SuggestionsFieldProps<Item> & WrappedFieldProps) => {
  const { name, value, onChange, onBlur, onFocus } = input;
  const { invalid } = meta;

  return (
    <Downshift<Item>
      onChange={onChange}
      itemToString={itemToString}
      selectedItem={value}
      stateReducer={stateReducer}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        selectedItem,
        clearSelection,
        getRootProps,
        highlightedIndex,
      }) => (
        <Wrapper data-id={name} {...getRootProps(undefined, { suppressRefError: true })}>
          <InputWrapper>
            <Input
              {...(getInputProps({
                name,
                placeholder,
                onFocus: onFocus,
                onBlur: () => onBlur(selectedItem),
                autoComplete: 'off',
                disabled,
              }) as GetInputPropsOptionsRef)}
              $isFilled={!!inputValue}
              $isInvalid={invalid}
            />
            <Label {...getLabelProps()}>
              <LabelContent>
                {label}
                {required && <RequiredAddon />}
              </LabelContent>
            </Label>
            {selectedItem && !disabled && (
              <Clear type="button" onClick={() => clearSelection()}>
                <IconCancel width="10" />
              </Clear>
            )}
            {!disabled && <SearchIcon />}
          </InputWrapper>
          <MenuWrapper {...getMenuProps()} data-id="menu">
            {isOpen && (
              <>
                <ResultsHeader>{t('shared.search_results')}:</ResultsHeader>
                <Search text={inputValue}>
                  {({ loading, data }) => {
                    if (!data.length && loading) {
                      return (
                        <Loading>
                          <IconLoading width="16" height="16" />
                        </Loading>
                      );
                    }

                    if (data.length) {
                      return (
                        <>
                          {helpText && <HelpText data-id="help-text">{helpText}</HelpText>}
                          <Menu>
                            {data.map((item, index) => (
                              <MenuItem
                                {...getItemProps({
                                  index,
                                  item,
                                })}
                                key={index}
                                data-id={`option-${index + 1}`}
                              >
                                <Option item={item} isHighlighted={highlightedIndex === index} />
                              </MenuItem>
                            ))}
                            {loading && (
                              <Loading>
                                <IconLoading width="16" height="16" />
                              </Loading>
                            )}
                          </Menu>
                        </>
                      );
                    } else {
                      return <EmptyText data-id="empty-text">{emptyText}</EmptyText>;
                    }
                  }}
                </Search>
              </>
            )}
          </MenuWrapper>
        </Wrapper>
      )}
    </Downshift>
  );
};

export default SuggestionsField;
