import styled from 'styled-components';

import { Breakpoints } from 'redesign/styles/breakpoints';

export const SupplierRow = styled.div`
  display: block;
  @media (min-width: ${Breakpoints.sm}) {
    display: flex;
    gap: ${({ theme }) => theme.space[4]};
  }
`;

export const AddNew = styled.button`
  height: ${({ theme }) => theme.space[6]};
  font-size: ${({ theme }) => theme.fontSize.xs};
  margin: ${({ theme }) => theme.space[4]} auto;
  font-weight: bold;
  display: block;
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.vrblue[100]};
  &::before {
    margin-right: 3px;
    content: '+';
  }
  @media (min-width: ${Breakpoints.sm}) {
    margin: auto 0;
  }
`;
