import { CALL_API } from 'constants/api';
import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/internal-subscription-fees';
import { apiErrorHandler } from 'shared/utils/error-handlers';

const apiFetchNumberOfUnpaidInternalInvoices = () => ({
  [CALL_API]: {
    endpoint: `/me/incoming_invoices/number_of_unpaid_internal_invoices`,
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const fetchNumberOfUnpaidInternalInvoices = () => (dispatch) =>
  dispatch(apiFetchNumberOfUnpaidInternalInvoices()).catch(apiErrorHandler);
