import { replace } from 'connected-react-router';
import { capitalize } from 'lodash';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';

import { setAfterLoginRoute } from 'actions/auth';
import paths from 'routes/paths';
import { isFarmpilotCustomerConnectionActive as isFarmpilotCustomerConnectionActiveSelector } from 'selectors/app-connections';
import { isFarmpilotIntegrationActive as isFarmpilotIntegrationActiveSelector } from 'selectors/app-settings';
import { farmpilot } from 'shared/utils';

export const paymentRemindersEnabledHelper = (state) =>
  state.profile.applicationFeatures.paymentReminders === true;

export const liquidityStatePreviewEnabledHelper = (state) =>
  state.profile.applicationFeatures.liquidityStatePreview === true;

export const productCatalogItemsEnabledHelper = (state) =>
  state.profile.applicationFeatures.productCatalogItems === true;

export const productCatalogGroupsEnabledHelper = (state) =>
  state.profile.applicationFeatures.productCatalogGroups === true;

export const productCatalogGroupsAndItemsEnabledHelper = (state) =>
  state.profile.applicationFeatures.productCatalogGroups === true &&
  state.profile.applicationFeatures.productCatalogItems === true;

export const suggestionsEnabledHelper = (state) =>
  state.profile.applicationFeatures.invoiceTransferConnectionSuggestions === true;

export const isOBSEnabled = (state) => state.profile.applicationFeatures.obs === true;

export const ocrEnabledHelper = (state) => state.profile.applicationFeatures.ocr === true;

export const paymentsEnabledHelper = (state) =>
  state.profile.applicationFeatures.invoicePayments === true;

export const eInvoicesEnabledHelper = (state) =>
  state.profile.applicationFeatures.eInvoices === true;

export const contactsImportHelper = (state) =>
  state.profile.applicationFeatures.contactsImport === true;

export const todoListEnabledHelper = (state) => state.profile.applicationFeatures.toDoList === true;

export const messagesEnabledHelper = (state) =>
  state.profile.applicationFeatures.messageInbox === true;

export const labelsEnabledHelper = (state) =>
  state.profile.applicationFeatures.bankTransferLabels === true;

export const defaultValuesEnabledHelper = (state) =>
  !!state.profile.applicationFeatures.documentsDefaultValues;

export const invoiceLayoutEnabledHelper = (state) =>
  !!state.profile.applicationFeatures.invoiceLayout;

export const documentLayoutEnabledHelper = (state) =>
  !!state.profile.applicationFeatures.documentLayout;

export const farmpilotEnabledHelper = (state) => !!state.profile.applicationFeatures.farmpilot;

export const liquidityCalculatorEnabledHelper = (state) =>
  !!state.profile.applicationFeatures.liquidityCalculator;

export const cashbookEnabledHelper = (state) => !!state.profile.applicationFeatures.cashbook;

export const deliveryNotesEnabledHelper = (state) =>
  !!state.profile.applicationFeatures.deliveryNote;

export const orderConfirmationEnabledHelper = (state) =>
  !!state.profile.applicationFeatures.orderConfirmation;

export const isGuest = (component, options = {}) => {
  const { redirectSuffix = '' } = options;
  const getRedirectPath = (path) => `${path}${redirectSuffix}`;

  return connectedReduxRedirect({
    authenticatedSelector: (state) =>
      state.auth.isAuthenticated === false ||
      // required for mail confirmation (AccountConfirmed, AccountAlreadyConfirmed and ConfirmationLinkOutdatedPublic) views
      state.router.location.pathname.includes(paths.registrationConfirmed) ||
      state.router.location.pathname.includes(paths.accountAlreadyConfirmed) ||
      state.router.location.pathname.includes(paths.confirmationLinkOutdated),
    authenticatingSelector: (state) =>
      state.auth.isFetching ||
      // required for mail confirmation (AccountConfirmed, AccountAlreadyConfirmed and ConfirmationLinkOutdatedPublic) views
      state.router.location.pathname.includes(paths.registrationConfirmed) ||
      state.router.location.pathname.includes(paths.accountAlreadyConfirmed) ||
      state.router.location.pathname.includes(paths.confirmationLinkOutdated),
    redirectPath: getRedirectPath(paths.home),
    redirectAction: replace,
    allowRedirectBack: false,
  })(component);
};

export const isAuthenticated = connectedReduxRedirect({
  authenticatedSelector: (state) => state.auth.isAuthenticated === true,
  authenticatingSelector: (state) => state.auth.isFetching,
  redirectAction: (location) => (dispatch, getState) => {
    const state = getState();
    dispatch(setAfterLoginRoute(state.router.location.pathname));
    dispatch(replace(location));
  },
  redirectPath: paths.login,
  allowRedirectBack: false,
});

const getClient = (state, clientId) => {
  const clientsList = state.clients.data || [];

  return clientsList.find(({ id }) => id === clientId);
};

export const canAccessEditClient = connectedReduxRedirect({
  authenticatedSelector: (state, ownProps) => {
    const client = getClient(state, ownProps.match.params.id);
    const isFarmpilotEnabled = farmpilotEnabledHelper(state);
    const isClientFromFarmpilot = !!client && farmpilot.isFarmpilotOrigin(client.origin);
    const isFarmpilotIntegrationActive = isFarmpilotIntegrationActiveSelector(state);
    const isFarmpilotCustomerConnectionActive = isFarmpilotCustomerConnectionActiveSelector(state);

    return (
      client &&
      (!isFarmpilotEnabled ||
        !isClientFromFarmpilot ||
        !isFarmpilotIntegrationActive ||
        !isFarmpilotCustomerConnectionActive)
    );
  },
  authenticatingSelector: (state, ownProps) => {
    const client = getClient(state, ownProps.match.params.id);
    const isReady =
      client &&
      state.appSettings.isFetched &&
      !state.appSettings.isFetching &&
      state.profile.isFetched &&
      !state.profile.isFetching;

    return !isReady;
  },
  redirectAction: replace,
  redirectPath: (state) => (state.auth.isAuthenticated ? paths.home : paths.login),
  allowRedirectBack: false,
});

export const paymentRemindersEnabled = connectedReduxRedirect({
  authenticatedSelector: (state) => paymentRemindersEnabledHelper(state),
  authenticatingSelector: (state) => !state.profile.isFetched || state.profile.isFetching,
  redirectAction: replace,
  redirectPath: (state) => (state.auth.isAuthenticated ? paths.home : paths.login),
  allowRedirectBack: false,
});

export const productCatalogItemsEnabled = connectedReduxRedirect({
  authenticatedSelector: (state) => productCatalogItemsEnabledHelper(state),
  authenticatingSelector: (state) => !state.profile.isFetched || state.profile.isFetching,
  redirectAction: replace,
  redirectPath: (state) => (state.auth.isAuthenticated ? paths.home : paths.login),
  allowRedirectBack: false,
});

export const productCatalogGroupsEnabled = connectedReduxRedirect({
  authenticatedSelector: (state) => productCatalogGroupsEnabledHelper(state),
  authenticatingSelector: (state) => !state.profile.isFetched || state.profile.isFetching,
  redirectAction: replace,
  redirectPath: (state) => (state.auth.isAuthenticated ? paths.home : paths.login),
  allowRedirectBack: false,
});

export const farmpilotEnabled = connectedReduxRedirect({
  authenticatedSelector: (state) => farmpilotEnabledHelper(state),
  authenticatingSelector: (state) => !state.profile.isFetched || state.profile.isFetching,
  redirectAction: replace,
  redirectPath: (state) => (state.auth.isAuthenticated ? paths.home : paths.login),
  allowRedirectBack: false,
});

export const visibleOnlyFor = (role = '') =>
  connectedAuthWrapper({
    authenticatedSelector: (state) => state.auth.roles.includes(role),
    wrapperDisplayName: `visibleOnly${capitalize(role)}`,
  });
