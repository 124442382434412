import 'intersection-observer';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

const withVisibility = (Comp) => {
  class WithVisibility extends Component {
    state = {
      inViewport: false,
    };

    componentDidMount() {
      const options = {
        threshold: [0, 0.25, 0.5, 0.75, 1],
      };
      this.observer = new IntersectionObserver(this.handleVisibility, options);
      this.observer.observe(this.element);
    }

    componentWillUnmount() {
      this.observer.disconnect();
    }

    handleVisibility = (entries) => {
      const { intersectionRect, intersectionRatio } = entries[0];
      const { top, bottom } = intersectionRect;
      this.setState({
        inViewport: Boolean(top || bottom),
        percentageVisibility: intersectionRatio,
      });
    };

    render() {
      const { inViewport, percentageVisibility } = this.state;
      return (
        <div
          ref={(el) => {
            this.element = el;
          }}
        >
          <Comp
            {...this.props}
            inViewport={inViewport}
            percentageVisibility={percentageVisibility}
          />
        </div>
      );
    }
  }

  const displayName = Comp.displayName || Comp.name || 'Component';

  WithVisibility.propTypes = {
    options: PropTypes.shape({}),
  };

  WithVisibility.displayName = `withVisibility(${displayName})`;
  return WithVisibility;
};
export default withVisibility;
