export const FORM_NAME = 'NewContract';

export const SUBJECT = 'subject';
export const TRANSACTION_DATE = 'transactionDate';
export const TRANSACTION_END_DATE = 'transactionEndDate';
export const RECURRING_TRANSACTION_INTERVAL_ID = 'recurringTransactionIntervalId';
export const NET_AMOUNT = 'netAmount';
export const TAX_RATE_ID = 'taxRateId';

export const INSTALLMENT_KEYS = [TRANSACTION_DATE, NET_AMOUNT, TRANSACTION_END_DATE, TAX_RATE_ID];

export const CATEGORY_CONTRACTS = 'Contracts';
