import React, { PureComponent } from 'react';
import InlineSvg from 'react-inlinesvg';
import { connect } from 'react-redux';
import cx from 'classnames';
import Downshift from 'downshift-legacy';
import { isEmpty, isString } from 'lodash';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { fetchUnits as fetchUnitsAction } from 'actions/units';
import searchIcon from 'images/icon-search-placeholder.svg';
import { noop, sortByAccuracy } from 'shared/utils';
import inputStyles from 'components/Form/TextField/TextField.module.css';

import styles from '../Selects.module.css';

class UnitSelect extends PureComponent {
  static defaultProps = {
    onUnitSelect: noop,
    onValueChange: noop,
    placeholder: '',
    initialValue: '',
    disabled: false,
    // When refactoring make sure that isLabelHidden shouldn't be true as a default
    isLabelHidden: true,
    onClick: noop,
    inputProps: {},
  };

  state = {
    inputValue: '',
  };

  componentDidMount() {
    const { fetchUnits, initialValue } = this.props;
    fetchUnits();

    if (initialValue) {
      this.handleInputChange(initialValue);
    }
  }

  componentDidUpdate({ initialValue: prevInitialValue }) {
    const { initialValue } = this.props;

    if (initialValue !== prevInitialValue) {
      this.handleInputChange(initialValue);
    }
  }

  handleInputChange = (inputValue) => {
    this.props.onValueChange(inputValue);
    this.setState({ inputValue });
  };

  render() {
    const {
      units = [],
      visible,
      className,
      placeholder,
      disabled,
      inputClassName,
      label,
      labelClassName,
      name,
      invalid,
      required,
      isLabelHidden,
      isFullWidth,
      onClick,
      dataIds = {
        container: 'UnitSelect:div-unitSelectContainer',
        input: 'UnitSelect:input-unitSelectInput',
      },
      inputProps,
    } = this.props;
    const { inputValue } = this.state;

    const items = units.map((item) => ({
      selected: Boolean(units.find((unit) => unit === inputValue)),
      label: item,
      value: item,
    }));

    const filteredItems = items.filter((item) =>
      item.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    const sortedItems = sortByAccuracy(filteredItems, inputValue, (item) => item.label);

    const onChange = (item) => this.props.onUnitSelect(item.id);
    const fieldLabel = label || placeholder;

    if (!visible) return null;

    return (
      <div className={cx(styles.main, className)} data-id={dataIds.container}>
        <Downshift
          onOuterClick={noop}
          itemToString={(item) => (isString(item) ? item : (item && item.name) || '')}
          onChange={onChange}
          defaultInputValue={this.props.initialValue}
          defaultSelectedItem={this.props.initialValue}
          onSelect={({ label: selectedLabel }) => this.handleInputChange(selectedLabel)}
          onInputValueChange={(value) => this.handleInputChange(value)}
          selectedItem={this.state.inputValue}
        >
          {({ getInputProps, getItemProps, isOpen, highlightedIndex, openMenu }) => (
            <div className={cx({ [styles.downshiftContainerFullWidth]: isFullWidth })}>
              <div className={cx(inputStyles.wrapper, className)}>
                <input
                  onClick={onClick}
                  {...getInputProps({
                    ...inputProps,
                    onBlur: (e) => e.preventDefault(),
                    onFocus: openMenu,
                  })}
                  autoComplete="new-password"
                  name={name}
                  className={cx(inputStyles.main, inputClassName, {
                    [styles.invalid]: invalid,
                    [inputStyles.withoutLabel]: isLabelHidden,
                    [inputStyles.mainFilled]: !!this.state.inputValue,
                  })}
                  placeholder={required && fieldLabel ? `${fieldLabel} *` : fieldLabel}
                  disabled={disabled}
                  data-id={dataIds.input}
                />
                <label
                  htmlFor={name}
                  className={cx(inputStyles.label, labelClassName, {
                    [styles.invalid]: invalid,
                  })}
                >
                  {!isLabelHidden && (
                    <span className={inputStyles.labelContent}>
                      {required && fieldLabel ? `${fieldLabel} *` : fieldLabel}
                    </span>
                  )}
                </label>
                <div className={inputStyles.addon}>
                  <InlineSvg src={searchIcon} />
                </div>
              </div>
              {isOpen && !isEmpty(sortedItems) ? (
                <div className={styles.items} data-id="UnitSelect:items-menu" tabIndex="-1">
                  {sortedItems.map((item, index) => (
                    <div
                      {...getItemProps({
                        key: item,
                        index,
                        item,
                      })}
                      data-id={`UnitSelect:items-option-${item.label}`}
                      className={cx(styles.item, {
                        [styles.highlighted]: highlightedIndex === index,
                      })}
                      key={index}
                    >
                      <div className={styles.creditorName}>{item.label}</div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

UnitSelect.propTypes = {
  invalid: bool,
  fetchUnits: func,
  name: string,
  visible: bool,
  onUnitSelect: func,
  onValueChange: func,
  className: string,
  placeholder: string,
  disabled: bool,
  initialValue: string,
  inputClassName: string,
  label: string,
  labelClassName: string,
  units: arrayOf(string).isRequired,
  required: bool,
  isLabelHidden: bool,
  isFullWidth: bool,
  onClick: func,
  dataIds: shape({ container: string, input: string }),
  inputProps: shape({}),
};

const mapDispatchToProps = (dispatch) => ({
  fetchUnits: (...args) => dispatch(fetchUnitsAction(...args)),
});

const mapStateToProps = (state) => ({
  units: state.units.allUnits,
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitSelect);
