import { t } from 'shared/utils';

export const updateSuccess = {
  duration: 3000,
  title: t('notifications.suppliers.update_success'),
  variant: 'success',
};

export const updateFailure = {
  duration: 3000,
  title: t('notifications.suppliers.update_failure'),
  variant: 'warning',
};
