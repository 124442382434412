import React from 'react';
import { number, string } from 'prop-types';

import { t } from 'shared/utils';

import styles from './PaymentCreatorHeader.module.css';

const PaymentCreatorHeader = ({ invoiceName = '', paymentStep }) => {
  const step = paymentStep > 6 ? 6 : paymentStep;

  return (
    <div className={styles.main}>
      <span>{`${t('expenses.payments.creator.heading')} ${invoiceName}`}</span>
      <span>{`${t('expenses.payments.creator.step')} ${step}/6`}</span>
    </div>
  );
};

PaymentCreatorHeader.propTypes = {
  paymentStep: number.isRequired,
  invoiceName: string,
};

export default PaymentCreatorHeader;
