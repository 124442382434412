import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { t } from 'shared/utils';

import styles from './SortingIndicator.module.css';

const SortingIndicator = ({ direction }) => (
  <div
    className={cx(styles.sorting, {
      [styles.sortedAscending]: direction === 'ASC',
      [styles.sortedDescending]: direction === 'DESC',
    })}
    title={t('tables.toggle_sorting')}
  >
    <div className={styles.ascendingIndicator} data-id="SortingIndicator:ascending" />
    <div className={styles.descendingIndicator} data-id="SortingIndicator:descending" />
  </div>
);

SortingIndicator.propTypes = {
  direction: PropTypes.string,
};

export default SortingIndicator;
