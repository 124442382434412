import { CALL_API } from 'constants/api';
import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/externalOriginCount';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiFetchExternalOriginCount = () => ({
  [CALL_API]: {
    endpoint: `/me/outgoing_invoices/external_origin_counts`,
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const fetchExternalOriginCount = () => (dispatch) =>
  dispatch(apiFetchExternalOriginCount()).catch(apiErrorHandler);
