import React from 'react';
import cx from 'classnames';
import noop from 'lodash/noop';
import snakeCase from 'lodash/snakeCase';
import { bool, func, string } from 'prop-types';
import { Field } from 'redux-form';

import { EDIT_MODE } from 'constants/common/crud';
import HintImage from 'images/hint.svg';
import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import BoxWithButton from 'components/BoxWithButton/BoxWithButton';
import Card from 'components/Card';
import CategorySearch from 'components/CategorySearch/CategorySearch';
import { DateField, TextField } from 'components/Form';
import I18n from 'components/I18n';
import { CategoryChangedConfirmationModal } from 'components/LineItems/components/CategoryChangedConfirmationModal/CategoryChangedConfirmationModal';
import { DatevWarningModal } from 'components/LineItems/components/DatevWarningModal/DatevWarningModal';
import { VatIdModal } from 'components/VatIdModal/VatIdModal';

import Client from './Client/Client';

import styles from './CreateOrderConfirmationSection.module.css';

const FormField = ({ name, readonly, message, placeholder, ...rest }) => (
  <div className={styles.formGroup}>
    <Field
      id={name}
      name={name}
      checker={checker}
      disabled={readonly}
      label={t(`features.order_confirmation.form.${snakeCase(name)}`)}
      placeholder={placeholder && t(`features.order_confirmation.form.${snakeCase(name)}`)}
      {...rest}
      message={message && t(`features.order_confirmation.form.${snakeCase(message)}`)}
    />
  </div>
);

FormField.propTypes = {
  name: string.isRequired,
  readonly: bool,
};

const CreateOrderConfirmationSection = ({
  readonly = false,
  onClientSelect = noop,

  lineCategories,
  selectedCategory,
  crudMode,
  setShowCategoryChangeConfirmation,
  showCategoryChangeConfirmation,
  category,
  isDeprecatedCategory,
  handleSelectCategory,
  showVatIdWarning,
  setShowVatIdWarning,
  showDifferentCategoryWarning,
  setShowDifferentCategoryWarning,
}) => {
  const isEditInvoice = crudMode === EDIT_MODE;

  return (
    <>
      <Card className={styles.main}>
        <Card.Header className={styles.header}>
          <I18n t="features.order_confirmation.form.header_data" />
        </Card.Header>
        <Card.Body>
          <div className={cx(styles.columns, styles.columnsPadded)}>
            <div className={styles.column}>
              <FormField
                name="subject"
                component={TextField}
                readonly={readonly}
                dataId="OrderConfirmationPage:input-subject"
              />
              <Client
                selectDataIds={{
                  container: 'OrderConfirmationPage:select-client',
                  input: 'OrderConfirmationPage:input-client',
                  menuOptionPrefix: 'OrderConfirmationPage:option-client',
                }}
                onChange={onClientSelect}
                readonly={readonly}
              />
            </div>
            <div className={styles.column}>
              <FormField
                name="orderConfirmationNumber"
                component={TextField}
                readonly
                dataId="OrderConfirmationPage:input-number"
                disabledPlaceHolder={t('revenue.form.number_disabled')}
              />
              <div className={cx(styles.columns)}>
                <div className={styles.column}>
                  <FormField
                    name="orderConfirmationDate"
                    dataIds={{ input: 'OrderConfirmationPage:container-date' }}
                    component={DateField}
                    readonly={readonly}
                    required
                  />
                </div>
                <div className={styles.column}>
                  <FormField
                    name="deliveryDate"
                    dataIds={{ input: 'OrderConfirmationPage:container-valid-unit' }}
                    component={DateField}
                    readonly={readonly}
                    message="delivery_date_info"
                  />
                </div>
              </div>
              <div>
                {isDeprecatedCategory && isEditInvoice && (
                  <BoxWithButton
                    isButtonDisabled
                    image={HintImage}
                    content={t('datev.deprecated_category_warning_order_confirmation')}
                    dataId="Orderconfirmation-CreateOrderconfirmationSection:orange-warning-container"
                  />
                )}

                <FormField
                  name="invoiceLineCategoryId"
                  dataId="OrderConfirmation:select-category"
                  label={`${t('revenue.form.category')} *`}
                  component={CategorySearch}
                  categories={lineCategories}
                  selectedItem={selectedCategory}
                  initialSelectedItem={category}
                  initialInputValue={category?.name}
                  onSelectCategory={handleSelectCategory}
                  disabled={readonly}
                  withIcon={!readonly}
                  withHint
                />
              </div>
            </div>
          </div>
          <DatevWarningModal
            isOpen={!showVatIdWarning && showDifferentCategoryWarning}
            onConfirm={() => setShowDifferentCategoryWarning(false)}
          />
          <CategoryChangedConfirmationModal
            isOpen={!showVatIdWarning && showCategoryChangeConfirmation}
            onConfirm={() => setShowCategoryChangeConfirmation(false)}
          />
          <VatIdModal isOpen={showVatIdWarning} onConfirm={() => setShowVatIdWarning(false)} />
        </Card.Body>
      </Card>
    </>
  );
};

CreateOrderConfirmationSection.propTypes = {
  readonly: bool,
  onClientSelect: func,
};

export default CreateOrderConfirmationSection;
