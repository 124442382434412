import React, { useCallback, useMemo, useState } from 'react';
import { useTable } from 'react-table';

import { updateBankAccount } from 'api/me/bankAccounts';
import { t } from 'shared/utils';
import { BankAccount } from 'types/entities/BankAccount';
import Link from 'redesign/components/atoms/Link/Link';
import Table from 'redesign/components/molecules/Table/Table';

import DeleteAccessModal from '../Modals/DeleteAccessModal';
import DeleteModal from '../Modals/DeleteModal';
import DeletionErrorModal from '../Modals/DeletionErrorModal';
import MainAccountModal from '../Modals/MainAccountModal';
import getColumns from './getColumns';
import { Header, Row, TableWrapper } from './TableItem.styled';

type ListProps = {
  data: BankAccount[];
  isLoading: boolean;
  onReload: () => void;
};

const TableItem = ({ data, isLoading, onReload }: ListProps) => {
  const [itemToDelete, setItemToDelete] = useState<BankAccount | null>(null);
  const [itemToEdit, setItemToEdit] = useState<Pick<
    BankAccount,
    'id' | 'name' | 'creditLine' | 'bankName'
  > | null>(null);
  const [newMainAccount, setNewMainAccount] = useState<BankAccount | null>(null);
  const [bankGroupToDelete, setBankGroupToDelete] = useState<string | null>(null);
  const currentEntry = data[0];
  const isAccessDeletable = data.map((item) => item.deletable === true);

  const showDeleteModal = useCallback(
    (item: BankAccount) => () => {
      setItemToDelete(item);
    },
    []
  );

  const handleEditMode = useCallback(
    (item: BankAccount) => () => {
      const { id, name, creditLine, bankName } = item;

      setItemToEdit({ id, name, creditLine, bankName });
    },
    []
  );

  const handleSaveEdit = useCallback(async () => {
    if (!itemToEdit) return;

    await updateBankAccount(itemToEdit.id, {
      name: itemToEdit.name,
      credit_line: itemToEdit.creditLine,
      bank_name: itemToEdit.bankName,
    });

    setItemToEdit(null);
    onReload();
  }, [itemToEdit, onReload]);

  const handleEndEditMode = useCallback(() => {
    setItemToEdit(null);
  }, []);

  const hideDeleteModal = useCallback(() => {
    setItemToDelete(null);
  }, []);

  const hideMainAccountModal = useCallback(() => {
    setNewMainAccount(null);
  }, []);

  const handleClickMain = useCallback(
    (item: BankAccount) => () => {
      setNewMainAccount(item);
    },
    []
  );

  const handleShowAccessDeletion = useCallback(
    (bankCode: string) => () => {
      setBankGroupToDelete(bankCode);
    },
    []
  );

  const hideDeleteAccessModal = useCallback(() => {
    setBankGroupToDelete(null);
  }, []);

  const columns = useMemo(
    () =>
      getColumns({
        showDeleteModal: showDeleteModal,
        handleEdit: handleEditMode,
        onEndEditMode: handleEndEditMode,
        onSaveEdit: handleSaveEdit,
        onClickMain: handleClickMain,
        itemToEdit,
      }),
    [
      showDeleteModal,
      handleEditMode,
      handleEndEditMode,
      handleSaveEdit,
      handleClickMain,
      itemToEdit,
    ]
  );

  const instance = useTable<BankAccount>({
    columns,
    data,
  });

  return (
    <div style={{ margin: '64px 0' }}>
      <Row>
        <Header data-id={'header-bankName'}>{currentEntry.bankName}</Header>
        <Link onClick={handleShowAccessDeletion(currentEntry.bankCode)}>
          {t('redesign.organisms.bank_accounts_list.delete_access')}
        </Link>
      </Row>
      <TableWrapper>
        <Table
          instance={instance}
          isLoading={isLoading}
          emptyText={t('redesign.organisms.bank_transfers_tile.list.empty')}
        />
      </TableWrapper>
      {itemToDelete &&
        (itemToDelete.deletable ? (
          <DeleteModal onReload={onReload} bankAccount={itemToDelete} onClose={hideDeleteModal} />
        ) : (
          <DeletionErrorModal
            isSingleAccount
            isMainAccount={currentEntry.main}
            onClose={hideDeleteModal}
          />
        ))}
      {bankGroupToDelete &&
        (isAccessDeletable[0] ? (
          <DeleteAccessModal
            bankCode={bankGroupToDelete}
            onReload={onReload}
            onClose={hideDeleteAccessModal}
          />
        ) : (
          <DeletionErrorModal
            isSingleAccount={false}
            isMainAccount={currentEntry.main}
            onClose={hideDeleteAccessModal}
          />
        ))}
      {newMainAccount && (
        <MainAccountModal
          onReload={onReload}
          onClose={hideMainAccountModal}
          bankAccount={newMainAccount}
        />
      )}
    </div>
  );
};

export default TableItem;
