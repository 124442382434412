import React from 'react';
import cx from 'classnames';
import { arrayOf, node, string } from 'prop-types';

import styles from './ParallelContainer.module.css';

const ParallelContainer = ({ children = [], className = '' }) => (
  <div className={cx(styles.container, className)}>{children}</div>
);

ParallelContainer.propTypes = {
  children: arrayOf(node),
  className: string,
};

export default ParallelContainer;
