import React from 'react';
import cx from 'classnames';
import { Field } from 'redux-form';

import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import Card from 'components/Card';
import HtmlField from 'components/v2/Form/HtmlField/HtmlField';

import styles from './Notes.module.css';

type NotesProps = {
  disabled?: boolean;
  className?: string;
};

const Notes = ({ disabled, className }: NotesProps) => (
  <Card className={cx(styles.wrapper, className)}>
    <label htmlFor="personalNotes">
      {t('delivery_notes.form.notes_1')}
      <br />
      {t('delivery_notes.form.notes_2')}
    </label>
    <Field
      name="personalNotes"
      component={HtmlField}
      checker={checker}
      dataId="DeliveryNotePage:footer"
      disabled={disabled}
    />
  </Card>
);

export default Notes;
