import React from 'react';
import cx from 'classnames';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

import TextField from 'components/Form/TextField';
import Tooltip from 'redesign/components/molecules/Tooltip/Tooltip';

import styles from './InfoTextField.module.css';

const blacklist = [
  'enableOnClickOutside',
  'disableOnClickOutside',
  'stopPropagation',
  'preventDefault',
  'outsideClickIgnoreClass',
  'eventTypes',
  'isFetching',
];

const InfoTextField = ({ className, message, ...rest }) => (
  <div className={cx(styles.wrapper, className)}>
    <TextField {...omit(rest, blacklist)} inputClassName={styles.input} />
    {message && <Tooltip className={styles.icon} message={message} />}
  </div>
);

InfoTextField.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

export default InfoTextField;
