import React, { memo, SVGProps } from 'react';

const Edit = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M.5 0h24v24H.5z" />
      <path
        d="M14.56 9.02l.92.92L6.42 19H5.5v-.92l9.06-9.06M18.16 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 000-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3.5 17.25V21h3.75L18.31 9.94l-3.75-3.75z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(Edit);
