import {
  ActionTypes,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FetchSuccessAction,
} from 'constants/externalOriginCount';

type ExternalOriginCountState = {
  isFetching: boolean;
  error: boolean;
  data: {
    farmpilot_drafts_count: number;
  };
};

const initialState: ExternalOriginCountState = {
  isFetching: false,
  error: false,
  data: { farmpilot_drafts_count: 0 },
};

export default (state = initialState, action: ActionTypes): ExternalOriginCountState => {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case FETCH_SUCCESS:
      const { response } = action as FetchSuccessAction;

      return {
        ...state,
        isFetching: false,
        data: response,
      };
    case FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    default:
      return state;
  }
};
