import { cloneElement } from 'react';
import { LOCATION_CHANGE } from 'connected-react-router';
import FileSaver from 'file-saver';
import {
  chunk,
  has,
  isArray,
  isEmpty,
  isNull,
  isPlainObject,
  isString,
  isUndefined,
  map,
  mapKeys,
  mapValues,
  omitBy,
  snakeCase,
} from 'lodash';
import moment from 'moment';
import nodeParseFloat from 'parse-float';

import { DATE_FORMAT } from 'constants/datetime';
import * as _array from 'shared/utils/array';
import * as _farmpilot from 'shared/utils/farmpilot';
import i18n from 'shared/utils/i18n';
import { ensureNumericMoneyValue } from 'shared/utils/money';
import { urlRegex } from 'shared/utils/regex';

export const noop = () => {};

export const redirect = (url) => {
  window.location = url;
};

export const toMoment = (x) => {
  if (moment.isMoment(x)) return x;

  const date = moment.utc(x, DATE_FORMAT);
  return date.isValid() ? date : moment.utc(x);
};

export const formatDate = (date) => {
  if (!date) return '';
  const converted = toMoment(date);
  return converted.isValid() ? converted.format(DATE_FORMAT) : '';
};

// Return 0-based month number
export const getMonthNumber = (monthNumber) => {
  if (typeof monthNumber !== 'number') return monthNumber;

  if (monthNumber < 0) return (12 + (monthNumber % 12)) % 12;
  return monthNumber % 12;
};

// Don't override if value is null.
export const extend = (obj, src) => ({ ...obj, ...omitBy(src, isNull) });

export const parseNumber = (value) => {
  if (typeof value === 'number') return value;
  const hasCommaDecimalMark = String(value).includes(',');
  const parsedNumber = hasCommaDecimalMark ? nodeParseFloat(value) : parseFloat(value);
  if (Number.isNaN(parsedNumber)) return 0;
  return parsedNumber;
};

// Usage: t("translation.key", { interpolation_variable: "value" })
export const t = (key, options = {}) => i18n.translate(key, options);

export const toCurrency = i18n.toCurrency;

export const pf = (value) => {
  if (typeof value === 'number') {
    return parseFloat(value.toFixed(3));
  }
  const hasCommaDecimalMark = String(value).includes(',');
  const parsedNumber = hasCommaDecimalMark ? nodeParseFloat(value) : parseFloat(value);
  if (Number.isNaN(parsedNumber)) {
    return 0;
  }
  return parsedNumber;
};

export const l = (k, type, options = {}) => {
  const key = pf(k);
  if (type === 'number') {
    return i18n.toNumber(key, options);
  }
  if (type === 'percentage') {
    return i18n.toPercentage(key, options);
  }

  return i18n.l(type, key, options);
};

export const formatMoney = (amount) => {
  // I can't believe I'm actually doing this but our legacy code makes it necessary
  if (typeof amount === 'string' && String(amount).match(/€|\$|£/)) {
    // checks if the `amount` is already formatted
    return amount;
  }

  return new Intl.NumberFormat('de', { style: 'currency', currency: 'EUR' }).format(
    ensureNumericMoneyValue(amount, 'de')
  );
};

export const formatMoneyInput = (amount = 0) => ensureNumericMoneyValue(amount);

export const formatAmountWithHistory = () => (amount) => ensureNumericMoneyValue(amount);

export const formatAmount = (value) => formatAmountWithHistory()(value);

export const parseCurrency = (amount) => ensureNumericMoneyValue(amount);

export const normalizeCurrency = (amount) => ensureNumericMoneyValue(amount);

export const currencyWithSignPrefix = (value, isNegative) => {
  const sign = isNegative ? '-' : '+';
  const prefix = Number(value) === 0 ? '' : sign;
  return `${prefix} ${formatMoney(value)}`;
};

export const formatPercentage = (amount) => l(amount, 'percentage');

export const formatIBAN = (iban) =>
  chunk(iban, 4)
    .map((x) => x.join(''))
    .join(' ');

export default {
  noop,
  t,
};

export const downloadData = (data, name, type = 'pdf') => {
  const blob = new Blob([data], { type: `application/${type}` });
  const fileName = `${name}.${type}`;

  FileSaver.saveAs(blob, fileName);
};

export const printData = (data, type = 'pdf') => {
  const blob = new Blob([data], { type: `application/${type}` });
  const url = URL.createObjectURL(blob);

  window.open(url, '_blank');
};

export const urlWithParams = (url, params) => {
  const paramQuery = map(params, (v, k) => `${k}=${v}`);
  return `${url}?${paramQuery.join('#')}`;
};

export const mapSupplier = (props) => {
  const { companyName, lastName, city } = props;

  const prefix = companyName || lastName;
  const postfix = city ? `, ${city}` : '';

  return {
    ...props,
    name: `${prefix}${postfix}`,
  };
};

export const mapClient = mapSupplier;

// adapted from https://github.com/odynvolk/map-keys-deep-lodash/blob/master/index.js
// to correctly map objects containing arrays
export const mapKeysDeep = (obj, cb) => {
  if (isUndefined(obj)) {
    throw new Error(`mapKeysDeep expects an object but got ${typeof obj}`);
  }
  if (isString(obj)) {
    return obj;
  }

  obj = mapKeys(obj, cb);

  const res = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const val = obj[key];
      if (isArray(val)) {
        res[key] = val.map((el) => mapKeysDeep(el, cb));
      } else if (isPlainObject(val)) {
        res[key] = mapKeysDeep(val, cb);
      } else {
        res[key] = val;
      }
    }
  }

  return res;
};

export const digitsOnly = (value) => value.replace(/[\D]/g, '');

export const resolveInvoiceType = (type = '') => {
  if (type.includes('incoming')) {
    return 'incoming-invoice';
  }
  return 'outgoing-invoice';
};

export const createFilteredReducer = (reducerFunction, reducerPredicate) => (state, action) => {
  const isInitializationCall = state === undefined;
  const shouldRunWrappedReducer = reducerPredicate(action) || isInitializationCall;
  return shouldRunWrappedReducer ? reducerFunction(state, action) : state;
};

export function sanitizeDateRange(dateRange) {
  return JSON.stringify(
    mapKeys(
      mapValues(dateRange, (value) => (value ? value.format(DATE_FORMAT) : value)),
      (_, key) => snakeCase(key)
    )
  );
}

export function sanitizeNumber(number) {
  const parsedNumericValue = parseFloat(number.toString().replace(',', '.'));

  return Number.isNaN(parsedNumericValue) ? '' : parsedNumericValue.toString().replace('.', ',');
}

export function getFormattedDateRange({ startDate = '', endDate = '' } = {}) {
  return startDate && endDate
    ? `${startDate.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)}`
    : '';
}

export function linkify(text = '', { openInNewTab = false } = {}) {
  return text.replace(
    urlRegex,
    (url) => `<a href="${url}" ${openInNewTab ? 'target="_blank"' : ''}>${url}</a>`
  );
}

export function addKeys(arr) {
  return arr.map((obj, idx) => {
    if (isPlainObject(obj) && has(obj, 'key') && obj.key === null) {
      return cloneElement(obj, { key: idx });
    }
    return obj;
  });
}

export const invalidRangeChecker = ({ start, end } = {}) => {
  if (!start || !Number(start)) return false;

  return String(end).length ? Number(end) < Number(start) : false;
};

export const isRouteAction = (action) => action.type === LOCATION_CHANGE;

export const getPathnameFromAction = (action) => action.payload.pathname;

export const parseStringToFloat = (value) =>
  typeof value === 'string' ? parseFloat(value.replace(/,/g, '.')) : value;

export const removeSpaces = (string) => string.replace(/\s/g, '');

export const padString = (str, length = 40) => {
  if (!str) return '-';
  return str.length >= length ? str.substring(0, length).padEnd(length + 3, '.') : str;
};

export const objectHasValues = (object) => !isEmpty(Object.keys(object).filter((k) => !!object[k]));

export const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

export const checkIsTruncated = (el) => el.scrollWidth > el.clientWidth;

export const array = _array;
export const farmpilot = _farmpilot;
export { mapToCamelCaseRecursive } from './mapToCamelCaseRecursive';
export { splitByPredicate, sortByPredicate, sortByAccuracy } from './collections';
