import React, { useEffect } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import { omit } from 'lodash';
import { bool, func, number } from 'prop-types';

import {
  CATEGORY_PRODUCT_CATALOG,
  PRODUCT_CATALOG_NEW_PRODUCT_GET_NEXT_PRODUCT_NUMBER,
  PRODUCT_CATALOG_NEW_PRODUCT_SELECT_UNIT,
  PRODUCT_CATALOG_NEW_PRODUCT_SWITCH_NET_GROSS,
} from 'constants/piwik';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import Card from 'components/Card';
import {
  CurrencyField,
  SelectField,
  StaticTextField,
  TextareaField,
  TextField,
} from 'components/Form';
import FormField from 'components/Form/FormField';
import { taxOptions } from 'components/LineItems/utils';
import FormControlledItemNumberSelect from 'components/Selects/ItemNumberSelect/FormControlledItemNumberSelect';
import FormUnitSelect from 'components/Selects/UnitSelect/FormControlledUnitSelect';
import NetGrossField from 'components/v2/Form/NetGrossField/NetGrossField';

import { connection } from './Creator.connections';

import styles from './Creator.module.css';

const FIELDS_PATH = 'product_catalog.product_catalog_item.fields';

const trackProductNumberClick = () =>
  piwikHelpers.trackEvent(
    CATEGORY_PRODUCT_CATALOG,
    PRODUCT_CATALOG_NEW_PRODUCT_GET_NEXT_PRODUCT_NUMBER
  );
const trackUnitSelectClick = () =>
  piwikHelpers.trackEvent(CATEGORY_PRODUCT_CATALOG, PRODUCT_CATALOG_NEW_PRODUCT_SELECT_UNIT);
const trackNetGrossClick = () =>
  piwikHelpers.trackEvent(CATEGORY_PRODUCT_CATALOG, PRODUCT_CATALOG_NEW_PRODUCT_SWITCH_NET_GROSS);

const Creator = ({
  id = null,
  updateForm,
  handleSubmit,
  submitForm,
  submitting,
  readonly,
  insertedAsGross,
  smallEntrepreneur,
  parsedNetAmount = '',
  parsedGrossAmount = '',
  clickCancelProductCatalogItem,
  fetchForm,
  fetchCategories,
  push,
}) => {
  useEffect(() => {
    if (id) fetchForm(id);
    fetchCategories();

    // Disabled eslint, because I want to call it one time
    // eslint-disable-next-line
  }, []);
  const generalSectionHeader = `product_catalog.product_catalog_item.section_headers.general_${
    id ? 'edit' : 'show'
  }`;
  const submit = async (formData) => {
    const invoiceLineCategoryId = formData?.invoiceLineCategoryId?.id;
    const ommitedUnnecessaryData = omit(formData, ['invoiceLineCategoryId']);
    const data = {
      ...ommitedUnnecessaryData,
      invoice_line_category_id: invoiceLineCategoryId,
    };

    return id ? updateForm({ id, data }) : submitForm({ data });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className={styles.section}>
        <Card className={styles.card}>
          <Card.Header>{t(generalSectionHeader)}</Card.Header>
          <Card.Body withMargin>
            <div className={styles.row}>
              <div className={styles.itemNumberCol}>
                <FormField
                  name="itemNumber"
                  label={t(`${FIELDS_PATH}.item_number.label`)}
                  placeholder={t(`${FIELDS_PATH}.item_number.placeholder`)}
                  required
                  component={FormControlledItemNumberSelect}
                  onClick={trackProductNumberClick}
                  shouldLabelStayUp
                  dataIds={{
                    input: 'ProductCatalogCreation:input-itemNumber',
                    suggestion: 'ProductCatalogCreation:div-itemNumberSuggestion',
                  }}
                />
              </div>
              <div className={styles.nameCol}>
                <FormField
                  name="name"
                  label={t(`${FIELDS_PATH}.name`)}
                  placeholder={t(`${FIELDS_PATH}.name`)}
                  required
                  component={TextField}
                  dataId="ProductCatalogCreation:input-name"
                />
              </div>
              <div className={styles.unitCol}>
                <FormField
                  name="unit"
                  label={t(`${FIELDS_PATH}.unit`)}
                  placeholder={t(`${FIELDS_PATH}.unit`)}
                  required
                  component={FormUnitSelect}
                  visible
                  isLabelHidden={false}
                  isFullWidth
                  onClick={trackUnitSelectClick}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.descriptionCol}>
                <FormField
                  name="description"
                  label={t(`${FIELDS_PATH}.description.label`)}
                  placeholder={t(`${FIELDS_PATH}.description.placeholder`)}
                  component={TextareaField}
                  dataId="ProductCatalogCreation:textarea-description"
                />
              </div>
              <div className={styles.internalNotesCol}>
                <FormField
                  name="internalNotes"
                  label={t(`${FIELDS_PATH}.internal_notes.label`)}
                  placeholder={t(`${FIELDS_PATH}.internal_notes.placeholder`)}
                  component={TextareaField}
                  dataId="ProductCatalogCreation:textarea-internalNotes"
                />
              </div>
            </div>
          </Card.Body>
        </Card>

        <Card className={styles.card}>
          <Card.Header>
            <div className={styles.priceHeader}>
              <span className={styles.priceLabel}>
                {t('product_catalog.product_catalog_item.section_headers.price')}
              </span>
              <FormField
                name="insertedAsGross"
                disabled={readonly}
                component={NetGrossField}
                onChange={() => {
                  trackNetGrossClick();
                }}
              />
            </div>
          </Card.Header>
          <Card.Body withMargin>
            <Row>
              <Col xs={12} sm={3}>
                <CurrencyField
                  className={styles.currencyField}
                  component={insertedAsGross ? StaticTextField : TextField}
                  dataId="ProductCatalogCreation:input-netAmount"
                  initialParsedValue={parsedNetAmount}
                  isCurrencySignVisible={!insertedAsGross}
                  isPlaceholderVisible={false}
                  isWithoutUnderline
                  label={t('form.fields.price_netto')}
                  name="netAmount"
                  readonly={readonly || insertedAsGross}
                  required
                  shouldLabelStayUp
                  valuePrefix="≈ "
                />
              </Col>
              <Col xs={12} sm={3}>
                <FormField
                  name="vat"
                  options={taxOptions}
                  component={SelectField}
                  readonly={readonly || smallEntrepreneur}
                  dataId="ProductCatalogCreation:select-vat"
                />
              </Col>
              <Col xs={12} sm={3}>
                <CurrencyField
                  name="grossAmount"
                  required
                  component={insertedAsGross ? TextField : StaticTextField}
                  initialParsedValue={parsedGrossAmount}
                  valuePrefix="≈ "
                  readonly={readonly || !insertedAsGross}
                  label={t('form.fields.price_brutto')}
                  className={styles.currencyField}
                  isWithoutUnderline
                  isPlaceholderVisible={false}
                  shouldLabelStayUp
                  dataId="ProductCatalogCreation:input-grossAmount"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <ActionPanel className={styles.creatorActionsSection}>
        <ActionButton
          type="submit"
          disabled={submitting}
          visible={!readonly}
          label={t('product_catalog.product_catalog_item.submit')}
          dataId="ProductCatalogCreation:button-save"
        />

        <ActionButton
          appearance="outlined"
          label={t('form.cancel')}
          Component={Link}
          to={paths.productCatalogItems}
          onClick={() => {
            clickCancelProductCatalogItem();
            push(paths.productCatalogItems);
          }}
          dataId="ProductCatalogCreation:button-cancel"
        />
      </ActionPanel>
    </form>
  );
};

Creator.propTypes = {
  id: number,
  handleSubmit: func,
  updateForm: func,
  submitForm: func,
  submitting: bool,
  readonly: bool,
  insertedAsGross: bool,
  smallEntrepreneur: bool,
  parsedNetAmount: number,
  parsedGrossAmount: number,
  clickCancelProductCatalogItem: func,
  fetchForm: func,
  push: func.isRequired,
};

export default connection(Creator);
