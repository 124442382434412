import styled from 'styled-components';

type LinkProps = {
  disabled?: boolean;
  $variant?: 'default' | 'action';
};

const Link = styled.a<LinkProps>`
  background: none;
  padding: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  font-weight: ${({ $variant }) => $variant === 'action' && 'bold'};

  &:hover {
    color: ${({ theme }) => theme.colors.vrblue['075']};
    text-decoration: underline;
  }

  &[disabled],
  &[disabled]:hover {
    color: ${({ theme }) => theme.colors.grey['020']};
    cursor: not-allowed;
    pointer-events: ${(props) => 'to' in props && 'none'};
  }
`;

export default Link;
