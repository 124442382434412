import React, { memo } from 'react';

import { t } from 'shared/utils';

import NewRevenueButton from '../NewRevenueButton/NewRevenueButton';
import { CardView, Content, HeadingSection, Headline } from '../Shared.styled';
import RevenueContractsTable from './RevenueContractsTable';

const RevenueContracts = () => (
  <CardView>
    <HeadingSection>
      <Headline>{t('contracts.index_view.revenues')}</Headline>
      <div>
        <NewRevenueButton />
      </div>
    </HeadingSection>
    <Content>
      <RevenueContractsTable />
    </Content>
  </CardView>
);

export default memo(RevenueContracts);
