import { getIsLoggedIn } from 'selectors/auth';

export const trackPageEnterForLoggedOutUser = (state) => {
  const isLoggedIn = getIsLoggedIn(state);

  if (isLoggedIn) return;

  window.gtag('config', 'AW-700169816');
};

export const trackRegistrationSuccess = () => {
  window.gtag('event', 'conversion', { send_to: 'AW-700169816/WlPMCPyJz7EBENj87s0C' });
};
