import React from 'react';

import ParallelContainer from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoicePaymentSection/PaymentCreator/components/ParallelContainer/ParallelContainer.jsx';
import ResizableContainer, {
  ResizeBase,
} from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoicePaymentSection/PaymentCreator/components/ResizableContainer/ResizableContainer.jsx';
import { t } from 'shared/utils';
import { isMdUp } from 'shared/utils/breakpoints';
import { TextField } from 'components/Form';

import { ImageSettings } from '../constants';

import styles from './Photo.module.css';

const INITIAL_PHOTO_DIMENSION = 150;

type PhotoProps = {
  challenge: {
    format: 'PHOTO';
    type: 'EMBEDDED';
    created_at: string;
    label: string;
    data: string;
    id: string;
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

const Photo = ({ challenge, onChange, value }: PhotoProps) => {
  const { data: photoData, label } = challenge;

  return (
    <>
      <span className={styles.hint} dangerouslySetInnerHTML={{ __html: label }} />
      <ParallelContainer className={styles.container}>
        <ResizableContainer
          initialWidth={INITIAL_PHOTO_DIMENSION}
          initialHeight={INITIAL_PHOTO_DIMENSION}
          minWidth={ImageSettings.MIN_WIDTH}
          minHeight={ImageSettings.MIN_HEIGHT}
          maxWidth={isMdUp() ? ImageSettings.MAX_WIDTH : ImageSettings.MAX_WIDTH_MOBILE}
          maxHeight={ImageSettings.MAX_HEIGHT}
          step={ImageSettings.STEP_SIZE}
          resizeBase={ResizeBase.WIDTH}
          keepAspectRatio
        >
          {({ width, height }: { width: number; height: number }) => (
            <img src={photoData} width={`${width}px`} height={`${height}px`} alt="" />
          )}
        </ResizableContainer>
        <TextField
          name="tan"
          placeholder={t('figo_connection.challenges.embedded.tan')}
          value={value}
          onChange={onChange}
          dataId="FigoAuth:input-tan"
          isCentered
          required
        />
      </ParallelContainer>
    </>
  );
};

export default Photo;
