import React, { useCallback, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import cx from 'classnames';
import { push as pushAction } from 'connected-react-router';
import { get, isEmpty } from 'lodash';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { deleteOrderConfirmation } from 'actions/order-confirmation';
import {
  clearFilters as clearFiltersAction,
  fetchOrderConfirmations,
  setQueryParam,
  sortOrderConfirmations,
} from 'actions/order-confirmations';
import { Resources } from 'constants/resources';
import { orderConfirmationEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import sharedStyles from 'shared/styles/components.module.css';
import { invalidRangeChecker, objectHasValues, t } from 'shared/utils';
import { dateFilterMapper } from 'shared/utils/filters/dateFilterMapper';
import { hasAnyFiltersActive as hasAnyFiltersActiveChecker } from 'shared/utils/hasAnyFiltersActive';
import { useDebouncedHasAnyFilters } from 'shared/utils/hooks/useDebouncedHasAnyFilters';
import { useRefetchOnPropsChange } from 'shared/utils/hooks/useRefetchOnPropsChange';
import CardView, { HeadingSection, Section } from 'components/CardView';
import If from 'components/Conditions/If';
import EmptyEntryPage from 'components/EmptyEntryPage/EmptyEntryPage';
import OrderConfirmationsEmptyStateImage from 'components/EmptyEntryPage/images/OrderConfirmations';
import { FiltersGroup } from 'components/Filter/FiltersGroup/FiltersGroup';
import I18n from 'components/I18n';
import Loading from 'components/Loading';
import { Pagination } from 'components/Pagination/Pagination';
import { Search, SearchDateGroup, SearchGroup } from 'components/Search';
import { CreationForbiddenMessage } from 'components/Table';

import NewOrderConfirmationButton from './NewOrderConfirmationButton';
import OrderConfirmationsTable from './OrderConfirmationsTable';

import localStyles from './OrderConfirmations.module.css';

const styles = { ...sharedStyles, ...localStyles };

const DATE_FILTERS = ['orderConfirmationDateFrom', 'orderConfirmationDateTo'];

const OrderConfirmations = ({
  canCreate,
  showModal,
  data,
  fetchOrderConfirmations,
  isFetching,
  pagination,
  remove,
  sort,
  sorting,
  filters,
  parsedFilters,
  setQueryAction,
  clearFilters,
  push,
}) => {
  /**
   * This flag is initially set to false and is set to true after first fetch
   * for order confirmations comes back. It is used for displaying the initial loader.
   */
  const history = useHistory();
  const [areOrderConfirmationsFetched, setAreOrderConfirmationsFetched] = useState(false);
  const debouncedHasAnyFiltersActive = useDebouncedHasAnyFilters(parsedFilters);
  const isOrderConfirmationEnabled = useSelector(orderConfirmationEnabledHelper);

  const fetch = useCallback(
    (pagination = { page: 1 }) => {
      const isIndexClassicView = objectHasValues(parsedFilters);

      fetchOrderConfirmations(
        { pagination_resource: Resources.ORDER_CONFIRMATIONS, ...pagination },
        sorting,
        {
          isIndexClassicView,
        }
      );
    },
    [fetchOrderConfirmations, sorting, parsedFilters]
  );

  useRefetchOnPropsChange({
    fetchFunction: async ({
      pagination: newPagination,
      sorting: newSorting,
      overwrittenValues,
      parsedFilters: newParsedFilters,
    }) => {
      const isIndexClassicView = objectHasValues(newParsedFilters);

      await fetchOrderConfirmations({ ...newPagination, ...overwrittenValues }, newSorting, {
        isIndexClassicView,
      });
      setAreOrderConfirmationsFetched(true);
    },
    props: { parsedFilters, pagination, sorting },
  });

  if (!isOrderConfirmationEnabled) {
    history.push(paths.home);
    return null;
  }

  if (!areOrderConfirmationsFetched) {
    return (
      <div className={styles.page}>
        <div className={styles.main}>
          <Loading />
        </div>
      </div>
    );
  }

  const hasAnyFiltersActive = hasAnyFiltersActiveChecker(filters);
  const areOrderConfirmationsEmpty = isEmpty(data);
  const isEmptyStateVisible =
    !isFetching && areOrderConfirmationsEmpty && !debouncedHasAnyFiltersActive;

  if (isEmptyStateVisible) {
    return (
      <div className={styles.page}>
        <CardView className={styles.card}>
          <Section className={styles.section}>
            <EmptyEntryPage
              dataIdPrefix="OrderConfirmations"
              Image={OrderConfirmationsEmptyStateImage}
              header={t('empty_entry_pages.order_confirmations.header')}
              subheader={t('empty_entry_pages.order_confirmations.subheader')}
              info={[
                t('empty_entry_pages.order_confirmations.info.row_1'),
                t('empty_entry_pages.order_confirmations.info.row_2'),
                t('empty_entry_pages.order_confirmations.info.row_3'),
              ]}
              createButtonLabel={t('empty_entry_pages.order_confirmations.button_label')}
              onCreateButtonClick={() => push(paths.newOrderConfirmation)}
            />
          </Section>
        </CardView>
      </div>
    );
  }

  const isInvalidRange = invalidRangeChecker({
    start: filters.amountHigherThan,
    end: filters.amountLowerThan,
  });

  return (
    <div className={styles.page}>
      <CardView className={styles.card}>
        <HeadingSection>
          <I18n t="features.order_confirmation.subheader.index" className={styles.headingText} />
          <div
            className={cx(styles.pullRight, {
              [styles.hiddenSection]: areOrderConfirmationsEmpty && !hasAnyFiltersActive,
            })}
          >
            <If ok={canCreate}>
              <NewOrderConfirmationButton showModal={showModal} />
            </If>
            <If ok={!canCreate}>
              {/* TODO translations */}
              <CreationForbiddenMessage section="order_confirmations" />
            </If>
          </div>
          <FiltersGroup
            className={styles.filtersGroup}
            isResetButtonDisabled={!hasAnyFiltersActive}
            filters={filters}
            onFiltersChange={({ name, value }) => setQueryAction(name, value)}
            onFiltersReset={clearFilters}
            placeholder={t('tables.filters.filters_group.order_confirmations.placeholder')}
            dataIds={{
              input: 'OrderConfirmations:input-search',
            }}
            additionalFilters={
              <SearchGroup
                setQueryAction={(name) => (value) => {
                  if (DATE_FILTERS.includes(name))
                    return setQueryAction(name, value, dateFilterMapper);
                  setQueryAction(name, value);
                }}
                clearFieldAction={(name) => setQueryAction(name)}
                filters={filters}
              >
                <SearchDateGroup
                  label={t('order_confirmations.table.columns.order_confirmation_date_range')}
                  startDateProps={{
                    name: 'orderConfirmationDateFrom',
                  }}
                  endDateProps={{
                    name: 'orderConfirmationDateTo',
                  }}
                />
                <Search
                  placeholder={t('expenses.table.columns.amount_higher_than')}
                  name="amountHigherThan"
                  invalid={isInvalidRange}
                  isCurrency
                  isCurrencySignVisible
                />
                <Search
                  placeholder={t('expenses.table.columns.amount_lower_than')}
                  name="amountLowerThan"
                  invalid={isInvalidRange}
                  type="tel"
                  isCurrency
                  isCurrencySignVisible
                />
              </SearchGroup>
            }
          />
        </HeadingSection>
        <Section className={styles.section}>
          {areOrderConfirmationsEmpty && hasAnyFiltersActive ? (
            <div
              className={localStyles.emptyTableMessage}
              data-id="OrderConfirmations:table-empty-info"
            >
              {t('tables.empty_order_confirmation')}
            </div>
          ) : (
            <OrderConfirmationsTable
              data={data}
              isFetching={isFetching}
              refresh={fetch}
              remove={remove}
              sort={sort}
              sorting={sorting}
            />
          )}
        </Section>
        <Section
          className={cx(styles.section, styles.pagination, {
            [styles.hiddenSection]: areOrderConfirmationsEmpty,
          })}
        >
          <Pagination
            {...pagination}
            request={fetch}
            isFetching={isFetching}
            resource={Resources.ORDER_CONFIRMATIONS}
          />
        </Section>
      </CardView>
    </div>
  );
};

OrderConfirmations.propTypes = {
  canCreate: bool.isRequired,
  showModal: bool.isRequired,
  data: arrayOf(shape({})).isRequired,
  fetchOrderConfirmations: func.isRequired,
  isFetching: bool.isRequired,
  pagination: shape({
    perPage: number,
    page: number,
  }).isRequired,
  remove: func.isRequired,
  sort: func.isRequired,
  sorting: shape({}).isRequired,
  filters: string.isRequired,
  parsedFilters: shape({}),
  setQueryAction: func.isRequired,
  clearFilters: func.isRequired,
  push: func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state.orderConfirmations,
  canCreate: get(state, 'orderConfirmations.meta.actions.create', false),
  showModal: !get(state, 'orderConfirmations.meta.bank-account-data-present', false),
});

const mapDispatchToProps = {
  fetchOrderConfirmations: fetchOrderConfirmations,
  remove: deleteOrderConfirmation,
  sort: sortOrderConfirmations,
  setQueryAction: setQueryParam,
  clearFilters: clearFiltersAction,
  push: pushAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmations);
