import React from 'react';
import { CellProps, Column } from 'react-table';

import { t } from 'shared/utils';
import { BankTransfer } from 'types/entities/BankTransfer';
import Amount from 'redesign/components/atoms/Amount/Amount';
import TableCell from 'redesign/components/atoms/TableCell/TableCell';
import TableHeader from 'redesign/components/atoms/TableHeader/TableHeader';

/* eslint-disable react/display-name */
const getColumns = (): Column<BankTransfer>[] => {
  return [
    {
      id: 'name',
      Header: t('features.homepage.bank_transfers_tile.list.name'),
      Cell: ({ row: { original } }: CellProps<BankTransfer>) => {
        return <TableCell title={original.purpose}>{original.purpose}</TableCell>;
      },
    },
    {
      Header: t('features.homepage.bank_transfers_tile.list.date'),
      accessor: 'date',
    },
    {
      id: 'total',
      Header: () => (
        <TableHeader align="right">
          {t('features.homepage.bank_transfers_tile.list.total')}
        </TableHeader>
      ),
      Cell: ({ row: { original } }: CellProps<BankTransfer>) => {
        return (
          <TableCell align="right">
            <Amount amount={original.amount} />
          </TableCell>
        );
      },
    },
  ];
};
/* eslint-enable react/display-name */

export default getColumns;
