import React from 'react';

const BankAccounts = ({ dataId = '', className = '' }) => (
  <svg width={288} height={149} data-id={dataId} className={className}>
    <defs>
      <rect id="prefix__c" x={0} y={0.69} width={66} height={42} rx={7} />
      <rect id="prefix__d" x={0} y={0.69} width={68} height={42} rx={7} />
      <filter
        x="-12.7%"
        y="-11%"
        width="128.8%"
        height="128.8%"
        filterUnits="objectBoundingBox"
        id="prefix__a"
      >
        <feOffset dx={2} dy={4} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" in="shadowBlurOuter1" />
      </filter>
      <circle id="prefix__b" cx={76} cy={73} r={59} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M189.591 146.266c-28.697-7.748-37.437-15.07-59.42-7.748-21.982 7.32-38.436 7.484-58.781 7.484-20.346 0-21.372-12.078-39-22.905-17.629-10.826-47.789-17.745-23-45.808 24.789-28.064.626-1.597 42-29.223 41.374-27.626 99-17.376 127-11.847 28 5.529 25.373 16.043 52 26.853 26.627 10.81 47.253 6.38 55.856 15.989 8.602 9.609-17.121 27.697-22.856 38.508-5.735 10.81-45.102 36.446-73.799 28.697z"
        fill="#F3F3F3"
      />
      <path
        d="M91 69c14 10 39 9 46-18s23-38 57-31m-89 77c2.744 9.333 13.264 9.667 31.558 1C164 85 168.116 114 223 90"
        stroke="#F60"
        strokeDasharray="4,4"
      />
      <g transform="translate(0 3)">
        <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
        <use fill="#FFF" xlinkHref="#prefix__b" />
      </g>
      <g fillRule="nonzero">
        <path
          d="M66.407 66.086H52.2c-.612 0-1.108-.48-1.108-1.071 0-.592.496-1.072 1.108-1.072h14.206c.613 0 1.109.48 1.109 1.072s-.496 1.071-1.11 1.071m0 9.552H52.202c-.612 0-1.108-.48-1.108-1.071 0-.592.496-1.072 1.108-1.072h14.206c.613 0 1.109.48 1.109 1.072s-.496 1.071-1.11 1.071m0 9.378H52.202c-.612 0-1.108-.48-1.108-1.072 0-.591.496-1.071 1.108-1.071h14.206c.613 0 1.109.48 1.109 1.071 0 .592-.496 1.072-1.11 1.072"
          fill="#F26F21"
        />
        <path
          d="M94.589 59.26H47.48c-.598 0-1.08.479-1.08 1.068v28.303c0 .59.482 1.068 1.08 1.068h37.594v-2.136H48.561v-7.299H85.07l-.001-2.136H48.562V70.83h36.502v-1.29h.014l-.002-.027c0-.284.076-.558.207-.82h-36.72v-7.297h44.945v5.231c.845.206 1.585.515 2.161.897v-7.196c0-.59-.483-1.068-1.08-1.068"
          fill="#0066B2"
        />
        <path
          d="M95.842 79.973c0 .873-1.896 2.14-4.868 2.14-2.97 0-4.865-1.267-4.865-2.14 0-.01-.005-.019-.005-.031l-.002-2.121c1.245.72 2.942 1.154 4.872 1.154 1.932 0 3.631-.434 4.877-1.155l-.001 2.119c-.002.012-.008.023-.008.034m-4.868-12.692c2.941 0 4.823 1.24 4.86 2.111-.037.871-1.919 2.112-4.86 2.112-2.94 0-4.82-1.24-4.857-2.112.037-.871 1.918-2.111 4.857-2.111M86.1 72.57c1.245.722 2.944 1.156 4.875 1.156 1.935 0 3.634-.435 4.88-1.157l-.001 2.046c-.001.018-.011.035-.011.054 0 .872-1.896 2.139-4.868 2.139-2.97 0-4.865-1.267-4.865-2.139 0-.017-.009-.03-.009-.046V72.57M98 69.37v-.007c0-.012-.006-.023-.006-.035-.076-2.41-3.064-4.215-7.02-4.215-3.953 0-6.941 1.806-7.016 4.215 0 .012-.007.023-.007.035v.015c0 .024-.012.044-.012.07l.012 20.599c0 2.43 3.085 4.333 7.023 4.333 1.428 0 2.74-.253 3.838-.689l-.003-2.41c-.845.519-2.16.933-3.835.933-2.97 0-4.865-1.284-4.865-2.167v-1.615c1.243.718 2.938 1.15 4.865 1.15 1.92 0 3.608-.428 4.85-1.14-.636-.514-1.008-1.132-1.008-1.797 0-.053.01-.104.015-.156-.844.514-2.167.926-3.857.926-2.97 0-4.865-1.266-4.865-2.138l-.002-.015-.002-2.136c1.245.72 2.94 1.153 4.87 1.153 1.93 0 3.627-.433 4.871-1.154v1.708c.549-.437 1.291-.795 2.159-1.042l.01-14.344c0-.027-.013-.05-.014-.077"
          fill="#F26F21"
        />
        <path
          d="M100.36 99.204c-2.97 0-4.865-1.283-4.865-2.166l-.002-1.714c1.244.731 2.94 1.17 4.867 1.17 1.929 0 3.625-.44 4.87-1.17l-.002 1.719c0 .88-1.897 2.16-4.868 2.16M95.495 87.05c0-.886 1.895-2.173 4.865-2.173 2.971 0 4.868 1.287 4.868 2.173 0 .887-1.897 2.174-4.868 2.174-2.97 0-4.865-1.287-4.865-2.174m9.74 5.032c0 .013-.007.024-.007.038 0 .886-1.897 2.174-4.868 2.174-2.97 0-4.865-1.288-4.865-2.174 0-.012-.007-.022-.007-.034l-.002-1.833c1.245.733 2.942 1.173 4.874 1.173 1.933 0 3.632-.441 4.877-1.174l-.002 1.83m2.151-5.032c0-2.494-3.02-4.375-7.026-4.375-4.004 0-7.024 1.88-7.024 4.375 0 .019.005.035.005.054-.003.03-.018.056-.018.086l.013 9.85c0 2.489 3.02 4.367 7.024 4.367 4.005 0 7.026-1.876 7.026-4.362l.014-9.855c0-.033-.015-.062-.017-.094 0-.015.003-.03.003-.046"
          fill="#0066B2"
        />
      </g>
      <g transform="translate(163.4 3)">
        <use stroke="#0063BB" strokeWidth={4} fill="#FFF" xlinkHref="#prefix__c" />
        <rect stroke="#FFF" strokeWidth={3} x={-1.5} y={-0.81} width={69} height={45} rx={7} />
        <path fill="#F60" d="M7.826 13.828h52.348v7.448H7.826z" />
        <ellipse fill="#0063BB" cx={50.739} cy={34.621} rx={3.957} ry={4.034} />
        <ellipse fill="#0063BB" cx={56.217} cy={34.621} rx={3.957} ry={4.034} />
        <rect fill="#0063BB" x={8} y={34} width={35} height={2} rx={0.75} />
      </g>
      <g transform="translate(202 83)">
        <use stroke="#0063BB" strokeWidth={4} fill="#FFF" xlinkHref="#prefix__d" />
        <rect stroke="#FFF" strokeWidth={3} x={-1.5} y={-0.81} width={71} height={45} rx={7} />
        <rect fill="#F60" x={42.413} y={8.172} width={13.63} height={9.931} rx={2} />
        <rect fill="#0063BB" x={5.859} y={14.379} width={11.152} height={1.862} rx={0.75} />
        <rect fill="#0063BB" x={46.13} y={26.793} width={3.717} height={1.862} rx={0.75} />
        <rect fill="#0063BB" x={20.109} y={14.379} width={18.587} height={1.862} rx={0.75} />
        <rect fill="#0063BB" x={12} y={27} width={7} height={2} rx={0.75} />
        <rect fill="#0063BB" x={6} y={27} width={4} height={2} rx={0.75} />
        <rect fill="#0063BB" x={29} y={27} width={7} height={2} rx={0.75} />
        <rect fill="#0063BB" x={20} y={27} width={7} height={2} rx={0.75} />
        <rect fill="#0063BB" x={38} y={27} width={7} height={2} rx={0.75} />
      </g>
      <path
        d="M167.55 124.1V107m-8.55 8.55h17.1M158.55 67.1V50M150 58.55h17.1"
        stroke="#0066B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.625}
      />
    </g>
  </svg>
);

export default BankAccounts;
