import React, { SVGAttributes } from 'react';

const IconUpload = (props: SVGAttributes<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 63" {...props}>
    <path
      d="M33 31.243l-6 6L22.757 33 36 19.757 49.243 33 45 37.243l-6-6V63h-6V31.243zM34.5 0c12.568 0 23.081 9.129 25.118 21.234C66.669 22.467 72 28.622 72 36c0 8.284-6.716 15-15 15H45v-6h12a9 9 0 009-9c0-4.98-4.043-9.027-9.054-8.991l-2.829.02-.186-2.823C53.26 13.989 44.778 6 34.5 6c-10.405 0-18.948 8.184-19.45 18.553l-.107 2.217-2.152.546C8.823 28.323 6 31.876 6 36a9 9 0 009 9h12v6H15C6.716 51 0 44.284 0 36c0-6.148 3.745-11.53 9.242-13.81C10.872 9.627 21.589 0 34.5 0z"
      fill="currentColor"
    />
  </svg>
);

export default IconUpload;
