import React, { Fragment } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import { bool, func, number, shape } from 'prop-types';

import paths from 'routes/paths';
import sharedStyles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';
import Amount from 'components/Amount';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import I18n from 'components/I18n';
import ActionView from 'components/IndexActionsContainer/actionIcons/ActionView';
import IndexActionsContainer from 'components/IndexActionsContainer/IndexActionsContainer';

import localStyles from './SubOrderConfirmationRow.module.css';

const styles = { ...sharedStyles, ...localStyles };

const SubOrderConfirmationRow = ({ subOrderConfirmation, isEven }) => {
  const permittedActions = get(subOrderConfirmation, 'meta.actions', {});

  return (
    <Fragment>
      <tr
        className={cx(styles.rowWrapper, {
          [styles.rowEven]: isEven,
          [styles.rowOdd]: !isEven,
        })}
      >
        {/* empty column is required for correct alignment of subinvoice */}
        <td className={styles.cell} />
        <td className={styles.cell}>
          <I18n t="order_confirmations.table.columns.client" className={styles.cellHeader} />
          <CellContentWithTooltip dataId="SubOrderConfirmationRow:client-name">
            {`${subOrderConfirmation.client.companyName}, ${subOrderConfirmation.client.city}`}
          </CellContentWithTooltip>
        </td>
        <td className={styles.cell}>
          <I18n
            t="order_confirmations.table.columns.order_confirmation_number"
            className={styles.cellHeader}
          />
          <CellContentWithTooltip dataId="SubOrderConfirmationRow:number">
            {subOrderConfirmation.orderConfirmationNumber}
          </CellContentWithTooltip>
        </td>
        <td className={styles.cell}>
          <I18n t="order_confirmations.table.columns.subject" className={styles.cellHeader} />
          <CellContentWithTooltip dataId="SubOrderConfirmationRow:subject">
            {subOrderConfirmation.subject}
          </CellContentWithTooltip>
        </td>
        <td className={cx(styles.cell, styles.columnCenter)}>
          <I18n
            t="order_confirmations.table.columns.order_confirmation_date"
            className={styles.cellHeader}
          />
          <div className={styles.cellContent} data-id="SubOrderConfirmationRow:creation-date">
            {subOrderConfirmation.orderConfirmationDate}
          </div>
        </td>
        <td className={cx(styles.cell, styles.columnRight)}>
          <I18n t="revenue.table.columns.total_gross_amount" className={styles.cellHeader} />
          <div className={styles.cellContent}>
            <Amount
              dataId="SubOrderConfirmationRow:amount"
              amount={subOrderConfirmation.totalGrossAmount}
              currency={subOrderConfirmation.currency}
            />
          </div>
        </td>
        <td className={styles.actions}>
          <IndexActionsContainer>
            <ActionView
              id="sub-order-confirmation-show-button"
              title={t('features.order_confirmation.table.actions.show')}
              to={paths.showOrderConfirmation(subOrderConfirmation.id)}
              label={t('features.order_confirmation.table.actions.show')}
              visible={permittedActions.show}
              dataId="SubOrderConfirmationRow:button-show"
            />
          </IndexActionsContainer>
        </td>
      </tr>
    </Fragment>
  );
};

SubOrderConfirmationRow.propTypes = {
  paymentReminderIndex: number,
  isPaymentReminder: bool,
  subOrderConfirmation: shape({
    meta: shape({
      actions: shape({
        cancel: bool,
        correct: bool,
        delete: bool,
        edit: bool,
        editPaymentInformation: bool,
        show: bool,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  paymentReminder: shape({}),
  onDelete: func,
  paymentRemindersEnabled: bool,
  isEven: bool.isRequired,
};

export default SubOrderConfirmationRow;
