import React, { SVGAttributes } from 'react';

const IconCancel = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 10 10" {...props}>
    <path
      d="M9.243 9.243c-.391.39-.741.673-1.132.282L5 6.415l-3.11 3.11c-.39.39-.742.109-1.133-.282C.367 8.852.085 8.5.475 8.11L3.585 5 .476 1.89C.085 1.5.366 1.147.757.756 1.148.367 1.5.085 1.89.475L5 3.585 8.11.476c.392-.39.742-.109 1.133.282.39.391.673.741.282 1.132L6.415 5l3.11 3.11c.39.392.109.742-.282 1.133z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default IconCancel;
