import React from 'react';
import { Link } from 'react-router-dom';
import { CellProps, Column, Row } from 'react-table';

import { OPTION_DISCOUNT, OPTION_JOKER, OPTION_PARTLY_PAID } from 'constants/assigment-select';
import paths from 'routes/paths';
import { formatMoney, padString, t } from 'shared/utils';
import { ResourceType } from 'types/entities/AssignableResource';
import { BankTransferConnection } from 'types/entities/BankTransferConnection';
import Comment from 'components/Comment/Comment';
import IconDelete from 'components/Icons/IconDelete';
import IconEdit from 'components/Icons/IconEdit';
import IncomingInvoiceAmount from 'components/Table/IncomingInvoiceAmount';
import InvoiceStatusBadge from 'components/Table/InvoiceStatusBadge/InvoiceStatusBadge';
import TableCell from 'components/v2/Table/TableCell/TableCell';
import TableHeader from 'components/v2/Table/TableHeader/TableHeader';

import { ConnectedResource } from '../../../../../../types';
import InstallmentStatusBadge from './InstallmentStatusBadge';
import StatusOptions from './StatusOptions';
import { Resource } from './Table';
import {
  ActionButton,
  ActionButtons,
  Amount,
  AmountToRight,
  InvoiceAmount,
  Reason,
} from './Table.styled';

const toNegative = (amount: number) => -1 * amount;

/* eslint-disable react/display-name */
export const getColumns = ({
  onEdit,
  onDelete,
}: {
  onEdit: (resource: ConnectedResource) => void;
  onDelete: (resource: ConnectedResource) => void;
}): Column<Resource>[] => [
  {
    Header: t('bank_transfers.table.assigned.header.date'),
    accessor: 'date',
  },
  {
    Header: t('bank_transfers.table.assigned.header.receiver'),
    accessor: 'receiver',
  },
  {
    Header: t('bank_transfers.table.assigned.header.description'),
    accessor: 'description',
  },
  {
    Header: <TableHeader>{t('bank_transfers.table.assigned.header.amount')}</TableHeader>,
    accessor: 'amount',
    Cell: ({ value }: CellProps<ConnectedResource>) => <TableCell>{value}</TableCell>,
  },
  {
    Header: t('bank_transfers.table.assigned.header.status'),
    accessor: 'status',
  },
  {
    Header: (
      <TableHeader align="right">
        {t('bank_transfers.table.assigned.header.amount_assigned')}
      </TableHeader>
    ),
    accessor: 'amountAssigned',
    Cell: ({ value }: CellProps<ConnectedResource>) => <TableCell align="right">{value}</TableCell>,
  },
  {
    Header: t('bank_transfers.table.assigned.header.comment'),
    accessor: 'comment',
  },
  {
    Header: t('bank_transfers.table.assigned.header.actions'),
    accessor: 'actions',
    Cell: ({ row }: { row: Row<ConnectedResource> }) => (
      <ActionButtons>
        <ActionButton onClick={() => onEdit(row.original)}>
          <IconEdit width="12" height="12" />
        </ActionButton>
        <ActionButton onClick={() => onDelete(row.original)}>
          <IconDelete width="12" height="12" />
        </ActionButton>
      </ActionButtons>
    ),
  },
];
/* eslint-enable react/display-name */

export const getTableData = ({
  connectedResources,
  bankTransferConnections,
}: {
  connectedResources: ConnectedResource[];
  bankTransferConnections: BankTransferConnection[];
}) =>
  connectedResources.reduce((resources: Resource[], resource: ConnectedResource) => {
    const bankTransferConnection = bankTransferConnections.find(
      (connection) =>
        Number(resource.id) === connection.transferConnectableId &&
        resource.resourceType === connection.transferConnectableType
    );

    if (!bankTransferConnection) return resources;

    const sharedValues = {
      id: resource.id,
      resourceType: resource.resourceType,
      bankTransferConnection: bankTransferConnection,
      comment: resource.jokerOptionJustification ? (
        <Comment
          text={
            <>
              <Reason>{t('bank_transfers.table.assigned.reason')}</Reason>
              <div>{resource.jokerOptionJustification}</div>
            </>
          }
        />
      ) : (
        ''
      ),
      actions: '',
    };

    if (
      resource.resourceType === ResourceType.IncomingInvoice ||
      resource.resourceType === ResourceType.OutgoingInvoice
    ) {
      const isExpense = resource.resourceType === ResourceType.IncomingInvoice;
      const amounts = {
        [OPTION_PARTLY_PAID.value]: {
          displayAmount: formatMoney(resource.grossAmountToBePaid),
          amount: resource.grossAmountToBePaid,
        },
        [OPTION_JOKER.value]: {
          displayAmount: formatMoney(resource.jokerOptionAmount),
          amount: resource.jokerOptionAmount,
        },
        [OPTION_DISCOUNT.value]: {
          displayAmount: formatMoney(resource.discountGrossValue),
          amount: resource.discountGrossValue,
        },
      };
      const displayName =
        resource.resourceType === ResourceType.IncomingInvoice
          ? resource.supplierDisplayName
          : resource.clientDisplayName;
      const showInvoice =
        resource.resourceType === ResourceType.IncomingInvoice
          ? paths.showIncomingInvoice
          : paths.showOutgoingInvoice;
      const amount =
        resource.resourceType === ResourceType.IncomingInvoice ? (
          <IncomingInvoiceAmount
            bold
            amount={resource.totalGrossAmount}
            creditNote={resource.creditNote}
          />
        ) : (
          <Amount>{formatMoney(resource.totalGrossAmount)}</Amount>
        );

      return [
        ...resources,
        {
          date: resource.invoiceDate || resource.creationDate,
          receiver: padString(displayName, 40),
          description: (
            <Link to={showInvoice(resource.id)} data-id="invoice-number">
              {resource.number}
            </Link>
          ),
          amount: (
            <InvoiceAmount>
              {amount}
              <StatusOptions
                dataId="rest-amount"
                selectedPaymentOption={bankTransferConnection!.selectedOption}
                amounts={amounts}
              />
            </InvoiceAmount>
          ),
          status: <InvoiceStatusBadge status={resource.status} />,
          amountAssigned: (
            <Amount isNegative={isExpense}>
              {formatMoney(
                isExpense
                  ? toNegative(bankTransferConnection!.amountAssigned)
                  : bankTransferConnection!.amountAssigned
              )}
            </Amount>
          ),
          ...sharedValues,
        },
      ];
    } else {
      // it's installment
      const vendor = resource.isRevenue ? resource.client : resource.supplier;
      const contractViewPath = resource.isRevenue
        ? paths.viewRevenueContract(resource.contractId)
        : paths.viewExpenseContract(resource.contractId, resource.contractType);
      const amounts = {
        [OPTION_PARTLY_PAID.value]: {
          displayAmount: formatMoney(resource.grossAmountToBePaid),
          amount: resource.grossAmountToBePaid,
        },
        [OPTION_JOKER.value]: {
          displayAmount: formatMoney(resource.jokerOptionAmount),
          amount: resource.jokerOptionAmount,
        },
      };
      const isExpense = !resource.isRevenue;

      return [
        ...resources,
        {
          date: resource.transactionDate,
          receiver: padString(vendor, 40),
          description: <Link to={contractViewPath}>{resource.contractSubject}</Link>,
          amount: (
            <>
              <AmountToRight isNegative={isExpense}>
                {formatMoney(isExpense ? toNegative(resource.grossAmount) : resource.grossAmount)}
              </AmountToRight>
              <StatusOptions
                selectedPaymentOption={bankTransferConnection!.selectedOption}
                amounts={amounts}
              />
            </>
          ),
          status: <InstallmentStatusBadge isPaid={resource.paid} />,
          amountAssigned: (
            <Amount isNegative={isExpense}>
              {formatMoney(
                isExpense
                  ? toNegative(bankTransferConnection!.amountAssigned)
                  : bankTransferConnection!.amountAssigned
              )}
            </Amount>
          ),
          ...sharedValues,
        },
      ];
    }
  }, []);
