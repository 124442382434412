import React, { useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { resetCustomerDefaults, updateCustomerDefaults } from 'actions/default-values';
import { getOrderConfirmation, GetOrderConfirmationData } from 'api/me/previews/orderConfirmation';
import { DocumentsFormName } from 'containers/Profile/InvoiceTemplate/DefaultValues/TabDocumentsDefaults/TabDocumentsDefaults';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { RootState } from 'store';
import { DefaultValues } from 'types/entities/DefaultValues';
import { TextField } from 'components/Form';
import InputsGroup from 'components/InputsGroup/InputsGroup';
import HtmlField from 'components/v2/Form/HtmlField/HtmlField';

import { FormRow } from '../../sharedComponents';
import DefaultValuesFormWithPreview from '../../sharedComponents/DefaultValuesForm/DefaultValuesFormWithPreview';

import styles from './OrderConfirmationDefaults.module.css';

export const FORM_NAME: DocumentsFormName = 'orderConfirmationDefaults';

type FormData = Pick<
  DefaultValues,
  | 'orderConfirmationSubject'
  | 'orderConfirmationSalutation'
  | 'orderConfirmationSalutationContent'
  | 'orderConfirmationFooter'
  | 'orderConfirmationDeliveryInfo'
>;

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    orderConfirmationSubject: state.defaultValues.values.orderConfirmationSubject,
    orderConfirmationSalutation: state.defaultValues.values.orderConfirmationSalutation,
    orderConfirmationSalutationContent:
      state.defaultValues.values.orderConfirmationSalutationContent,
    orderConfirmationFooter: state.defaultValues.values.orderConfirmationFooter,
    orderConfirmationDeliveryInfo: state.defaultValues.values.orderConfirmationDeliveryInfo,
  },
  formValues: {
    orderConfirmationSalutation: selector(state, 'orderConfirmationSalutation'),
    orderConfirmationSalutationContent: selector(state, 'orderConfirmationSalutationContent'),
    orderConfirmationFooter: selector(state, 'orderConfirmationFooter'),
  },
});

const mapDispatchToProps = {
  updateCustomerDefaults,
  resetCustomerDefaults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OrderConfirmationDefaultsProps = ConnectedProps<typeof connector>;

const OrderConfirmationDefaults = ({
  resetCustomerDefaults,
  updateCustomerDefaults,
  handleSubmit,
  reset: resetForm,
  formValues: {
    orderConfirmationSalutation,
    orderConfirmationSalutationContent,
    orderConfirmationFooter,
  },
  initialize,
  initialized,
  initialValues,
}: OrderConfirmationDefaultsProps &
  InjectedFormProps<FormData, OrderConfirmationDefaultsProps>) => {
  const topFields = useMemo(
    () => (
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.discount_subject')}>
          <Field
            className={styles.grouppedInput}
            name="orderConfirmationSubject"
            checker={serverValidationChecker}
            component={TextField}
            dataId="DocumentDefaults:input-order-confirmation-subject"
          />
        </InputsGroup>
      </FormRow>
    ),
    []
  );

  const values = useMemo(
    () => ({
      salutation_honorific: orderConfirmationSalutation,
      salutation_content: orderConfirmationSalutationContent,
      personal_notes: orderConfirmationFooter,
    }),
    [orderConfirmationSalutation, orderConfirmationSalutationContent, orderConfirmationFooter]
  );

  useEffect(() => {
    initialized && initialize(initialValues);
  }, []);

  return (
    <DefaultValuesFormWithPreview<GetOrderConfirmationData>
      onSubmit={handleSubmit((values) => updateCustomerDefaults(values, 'form_order_confirmation'))}
      onReset={async () => {
        await resetCustomerDefaults('form_order_confirmation');
        resetForm();
      }}
      sectionName="form_order_confirmation"
      sectionLabel={t('invoice_templates.documents_defaults.order_confirmation_defaults_section')}
      dataIdPrefix="DocumentDefaults:"
      fetchPreview={getOrderConfirmation}
      values={values}
      topFields={topFields}
    >
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.header')}>
          <Field
            name="orderConfirmationSalutation"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation')}
            tooltip={t('invoice_templates.documents_defaults.salutation_info')}
            dataId="DocumentDefaults:input-order-confirmation-salutation"
          />
          <br />
          <Field
            name="orderConfirmationSalutationContent"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation_content')}
            dataId="DocumentDefaults:input-order-confirmation-salutation-content"
          />
          <br />
          <Field
            name="orderConfirmationDeliveryInfo"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.delivery_info')}
            dataId="DocumentDefaults:input-order-confirmation-delivery-info"
          />
        </InputsGroup>
      </FormRow>
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.footer')}>
          <Field
            name="orderConfirmationFooter"
            component={HtmlField}
            checker={serverValidationChecker}
            dataId="DocumentDefaults:input-order-confirmation-footer"
          />
        </InputsGroup>
      </FormRow>
    </DefaultValuesFormWithPreview>
  );
};

const FormEnhancedComponent = reduxForm<FormData, OrderConfirmationDefaultsProps>({
  form: FORM_NAME,
  enableReinitialize: true,
  persistentSubmitErrors: true,
})(OrderConfirmationDefaults);

export default connector(FormEnhancedComponent);
