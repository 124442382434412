import React from 'react';
import { connect } from 'react-redux';
import { bool, func } from 'prop-types';

import HintImage from 'images/hint.svg';
import paths from 'routes/paths';
import { getPushAction } from 'test-helpers/actions';
import BoxWithButton from 'components/BoxWithButton/BoxWithButton';

import styles from './hoc.module.css';

const withWarningAlert = (Component) => {
  const WarningWithComponent = ({
    content,
    buttonLabel,
    dataId,
    isProfileFilled,
    push,
    ...rest
  }) => {
    const shouldShowWarningAlert = !isProfileFilled && isProfileFilled !== undefined;

    return (
      <>
        {shouldShowWarningAlert && (
          <BoxWithButton
            containerClassName={styles.boxWithButton}
            image={HintImage}
            content={content}
            buttonLabel={buttonLabel}
            onClick={() => push(paths.companyProfile)}
            dataId={dataId}
          />
        )}
        <Component push={push} {...rest} />
      </>
    );
  };

  const mapStateToProps = (state) => ({
    isProfileFilled: state.onboarding.data?.profileFilled,
  });

  const mapDispatchToProps = {
    push: getPushAction,
  };

  WarningWithComponent.propTypes = {
    isProfileFilled: bool.isRequired,
    push: func.isRequired,
  };

  return connect(mapStateToProps, mapDispatchToProps)(WarningWithComponent);
};

export default withWarningAlert;
