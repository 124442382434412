import styled, { css } from 'styled-components';

import WarningBulbIconComponent from 'redesign/components/atoms/Icons/WarningBulb';

type WrapperProps = {
  isShowOnRight?: boolean;
  posX?: any;
  posY?: any;
};

export const Wrapper = styled.div<WrapperProps>`
  left: ${({ posX }) => posX + 'px'};
  top: ${({ posY }) => posY + 'px'};
  position: fixed;
  z-index: 99;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.7;
  background: white;
  box-shadow: 0 4px 30px 0 rgba(16, 22, 31, 0.24);
  pointer-events: none;
  font-smooth: subpixel-antialiased;
  transform: ${({ isShowOnRight }) =>
    isShowOnRight
      ? 'translateY(-50%) translateX(20px)'
      : 'translateY(-50%) translateX(calc(-100% - 20px))'};
  &::after {
    position: absolute;
    content: '';
    z-index: 100;
    box-shadow: 0 4px 30px 0 rgba(16, 22, 31, 0.24);
    background: inherit;
    top: calc(50% - 10px);
    left: ${({ isShowOnRight }) => (isShowOnRight ? '-9px' : 'unset')};
    right: ${({ isShowOnRight }) => (isShowOnRight ? 'unset' : '-9px')};
    width: 10px;
    height: 20px;
    clip-path: ${({ isShowOnRight }) =>
      isShowOnRight ? 'polygon(0 50%, 100% 100%, 100% 0)' : 'polygon(100% 50%, 0 100%, 0 0)'};
  }
`;

export const Past = styled.div`
  padding: 0.5em 1em;
  width: 320px;
`;

export const PastTitle = styled.h5`
  font-weight: bold;
  padding-bottom: 12px;
`;

const Section = styled.div`
  border-top: 1px solid ${({ theme }) => theme.legacy.colors.secondaryColor};
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const RevenueSection = styled(Section)``;

export const ExpensesSection = styled(Section)``;

export const TotalBalanceSection = styled(Section)`
  border-top: unset;
  padding-bottom: 4px;
  position: relative;
  ::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: calc((100% + 1em));
    height: 100%;
    left: -0.5em;
    top: 0;
    background: #e9eff3;
  }
`;

export const Today = styled.div`
  padding: 0.5em 1em;
  width: 380px;
`;

export const TodayTitle = styled.h5`
  font-weight: bold;
  padding-bottom: 12px;
`;

export const AccountBalanceSection = styled(Section)``;

export const CashbookBalanceSection = styled(Section)``;

export const ExpectedRevenueSection = styled(Section)``;

export const ExpectedExpensesSection = styled(Section)``;

export const ExpectedTotalBalanceSection = styled.div`
  position: relative;
  ::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: calc((100% + 1em));
    height: 100%;
    left: -0.5em;
    top: 0;
    background: #e9eff3;
  }
`;

export const Taxes = styled.div`
  margin-top: 8px;
  position: relative;
  ::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: calc((100% + 1em));
    height: 100%;
    left: -0.5em;
    top: 0;
    background: #102257;
  }
`;

export const WarningBulbIcon = styled(WarningBulbIconComponent)`
  left: 2px;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 8px;
`;

export const TaxesTitle = styled.h5`
  line-height: 16px;
  padding-top: 8px;
  margin-bottom: 10px;
  margin-left: 36px;
  color: #fff;
  font-weight: bold;
`;

export const VerticalLine = styled.div`
  border-top: 1px solid #fff;
`;

export const Future = styled.div`
  width: 380px;
  padding: 0.5em 1em;
`;

export const FutureTitle = styled.h5`
  font-weight: bold;
`;

export const ExpectedTaxesSection = styled(Section)``;

type RowProps = {
  short?: boolean;
  shortest?: boolean;
  bold?: boolean;
  white?: boolean;
};

export const Row = styled.div<RowProps>`
  display: flex;
  justify-content: space-between;
  margin-left: ${({ short, shortest }) => (short ? '18px' : shortest ? '36px' : '0px')};
  ${({ white }) =>
    white &&
    css`
      & > h5 {
        color: #f9f9f9;
      }
    `}
  ${({ bold }) =>
    bold &&
    css`
      & > h5 {
        font-weight: bold;
      }
    `}
`;

type ValueProps = {
  isPositive?: boolean;
};

export const Value = styled.h5<ValueProps>`
  white-space: nowrap;
  color: ${({ isPositive, theme }) =>
    isPositive ? theme.legacy.colors.greenFont : theme.legacy.colors.redFont};
`;
