import { CALL_API } from 'constants/api';
import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/countries';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiFetch = () => ({
  [CALL_API]: {
    endpoint: '/countries',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const fetchCountries = () => (dispatch) => dispatch(apiFetch()).catch(apiErrorHandler);
