import { connect } from 'react-redux';

import { quickCreateClient } from 'actions/proposal';
import { FORM_NAME } from 'constants/proposal';
import ClientSection from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/CreateInvoiceSection/Client';
import { proposalSelector } from 'reducers/form';

const mapStateToProps = (state) => ({
  client: proposalSelector(state, 'client'),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitClient: (values) => quickCreateClient(FORM_NAME)(values)(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientSection);
