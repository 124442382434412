import styled from 'styled-components';

import media from 'shared/styles/breakpoints';

export const Modal = styled.div`
  max-width: 630px;
`;

export const Container = styled.div`
  display: flex;
  padding: 40px 58px;
  flex-direction: column;
  align-items: center;
`;

export const Inner = styled.div`
  text-align: left;
`;

export const Headline = styled.div`
  font-weight: bold;
  padding: 20px 0;
`;

export const WarningWithIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
`;

export const WarningIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const WarningText = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

export const SubmitPasswordText = styled.div`
  font-weight: bold;
`;

export const Buttons = styled.div`
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-left: 10px;
`;

export const Button = styled.div`
  margin-left: 10px;

  ${media.greaterThan('md-up')`
    margin-left: 0px;
  `};
`;
