import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

import { useAppDispatch } from 'shared/hooks/app';
import { RootState } from 'store';
import { CategoryResponse } from 'types/entities/Category';
import { TaxOption } from 'types/entities/TaxRate';
import {
  getCategory,
  getDefaultCategory,
  getDefaultTaxRate,
  getLineItemState,
  getTaxRates,
} from 'components/LineItems/utils';

export enum SectionType {
  proposal = 'proposal',
  outgoingInvoice = 'outgoingInvoice',
  orderConfirmation = 'orderConfirmation',
}

type FormName = 'proposalCreator' | 'outgoingInvoiceCreator' | 'orderConfirmationCreator';

interface ILineItemsControl {
  sectionType: SectionType;
  formName: FormName;
  isNewForm?: boolean;
}

const UseLineItemsControl = ({ sectionType, formName, isNewForm }: ILineItemsControl) => {
  const dispatch = useAppDispatch();

  const { lineCategories, lineItems } = useSelector((state: RootState) => state[sectionType]);
  const selectedCategory = useSelector((state: RootState) =>
    formValueSelector(formName)(state, 'invoiceLineCategoryId')
  );

  const [showCategoryChangeConfirmation, setShowCategoryChangeConfirmation] = useState(false);
  const [showDifferentCategoryWarning, setShowDifferentCategoryWarning] = useState(false);
  const [showVatIdWarning, setShowVatIdWarning] = useState(false);
  const [category, setCategory] = useState<CategoryResponse>();
  const [taxRates, setTaxRates] = useState<TaxOption[] | undefined>([]);
  const [taxRateId, setTaxRateId] = useState<number | undefined>();
  const [defaultVat, setDefaultVat] = useState<number | undefined>();
  const [isDeprecatedCategory, setIsDeprecated] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [lineItemsState, setLineItemsState] = useState({});

  const bufferLineItemsState: boolean[] = [];
  const defaultCategory = getDefaultCategory(lineCategories);
  const hasSavedLineItems = !!lineItems.length;

  const handleSetLineItemState = ({ id, open }: { id: number; open: boolean }) => {
    bufferLineItemsState[id] = open;
    setLineItemsState({ ...lineItemsState, ...bufferLineItemsState, [id]: open });
  };

  const handleSelectCategory = (item?: CategoryResponse, showModal = false) => {
    if (item) {
      setCategory(item);
      setTaxRateId(getDefaultTaxRate(item)?.id);
      setDefaultVat(getDefaultTaxRate(item)?.value);
      setTaxRates(getTaxRates(item.taxRates));
      setShowCategoryChangeConfirmation(showModal);
      dispatch(change(formName, 'invoiceLineCategoryId', item.id));
      dispatch(change(formName, 'vat', getDefaultTaxRate(item)?.value));
    }
  };

  const manualHandleSelectCategory = (item: CategoryResponse, showModal = true) => {
    const isShown = showModal && hasSavedLineItems;

    setIsInitial(false);
    if (item) {
      handleSelectCategory(item, !!item.id && isShown);
      setShowVatIdWarning(!!item.vatIdNumberRequired);
    }
  };

  // preselect categorySearch with default category if no other is chosen
  useEffect(() => {
    isInitial && isNewForm && handleSelectCategory(defaultCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineCategories, selectedCategory]);

  // Set either category to undefined if it's an old document (isDeprecated) or to the actual category
  // that was selected, when the document was saved
  useEffect(() => {
    const cat = getCategory(lineCategories, selectedCategory);

    if (lineCategories.length && isInitial) {
      if (!cat) {
        setIsDeprecated(true);
        handleSelectCategory(undefined, false);
      } else {
        setIsDeprecated(false);
        handleSelectCategory(cat, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineCategories, selectedCategory]);

  // Handle the saving behaviour of the document based on line items and selected Category
  useEffect(() => {
    //check if every line Item is saved/closed
    const allLineItemsSaved = getLineItemState(lineItemsState);
    const isNewDocument = isInitial && !isDeprecatedCategory;

    // If it's the first load of the page (isInitial) and its not an old Document (isDeprecated),
    // user can use Submit-Buttons of the Document
    if (isNewDocument || allLineItemsSaved) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [
    isDeprecatedCategory,
    isInitial,
    selectedCategory,
    lineCategories,
    lineItemsState,
    lineItems,
  ]);

  return {
    manualHandleSelectCategory,
    setLineItemState: handleSetLineItemState,
    setShowCategoryChangeConfirmation,
    setShowDifferentCategoryWarning,
    setShowVatIdWarning,
    showDifferentCategoryWarning,
    showCategoryChangeConfirmation,
    defaultCategory,
    isDeprecatedCategory,
    canSubmit,
    showVatIdWarning,
    category,
    taxRates,
    taxRateId,
    defaultVat,
    hasSavedLineItems,
    selectedCategory,
    lineCategories,
    isInitial,
  };
};

export default UseLineItemsControl;
