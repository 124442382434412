import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.css';

const Option = ({ item: { label }, dataId }) => (
  <div className={styles.option} data-id={dataId ? `${dataId}_${label}` : undefined}>
    {label}
  </div>
);

Option.propTypes = {
  item: PropTypes.shape({}),
  dataId: PropTypes.string,
};

export default Option;
