import React, { memo, ReactNode, useState } from 'react';
import cx from 'classnames';

import { trackEvent } from '../utils';
import CaretDown from './CaretDown';
import CaretUp from './CaretUp';

import styles from './Section.module.css';

type SectionProps = {
  title: ReactNode;
  children: ReactNode;
  defaultOpen?: boolean;
  eventName?: string;
  dataId?: string;
};

const Section = ({ title, children, defaultOpen = false, eventName, dataId }: SectionProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <section className={styles.wrapper}>
      <header
        className={styles.header}
        onClick={() => {
          setIsOpen(!isOpen);

          if (!isOpen && eventName) {
            trackEvent(eventName);
          }
        }}
      >
        <h3 className={styles.title} data-id={dataId}>
          {title}
        </h3>
        {isOpen ? <CaretUp width="12" /> : <CaretDown width="12" />}
      </header>
      <div className={cx(styles.content, { [styles.hide]: !isOpen })}>{children}</div>
    </section>
  );
};

export default memo(Section);
