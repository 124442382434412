import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import { NEW_MODE, SHOW_MODE } from 'constants/common/crud';
import { FORM_NAME } from 'constants/proposal';
import Remark from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/CreateInvoiceSection/Remark';
import IndividualContactSection from 'containers/OutgoingInvoices/OutgoingInvoiceCreator/IndividualContactSection';
import { withTransitionPrevent } from 'shared/hoc';
import If from 'components/Conditions/If';
import { LineItemsAutoSaveConsumer, LineItemsAutoSaveProvider } from 'components/LineItems';

import ActionsSection from './ActionsSection';
import CreateProposalSection from './CreateProposalSection';
import Notes from './CreateProposalSection/Notes/Notes';
import { mapDispatchToProps, mapStateToProps, mergeProps } from './helper';
import ProposalDetailsSection from './ProposalDetailsSection';
import ProposalSumSection from './ProposalSumSection';

import styles from './ProposalCreator.module.css';

const ProposalCreator = ({
  abort,
  canUpdate,
  canTransformIntoOrderConfirmation,
  canTransformIntoOutgoingInvoice,
  dirty,
  download,
  handleSubmit,
  proposal,
  save,
  saveAsDraft,
  saveAndTransformIntoOutgoingInvoice,
  saveAndTransformIntoOrderConfirmation,
  submitting,
  transformIntoOutgoingInvoice,
  transformIntoOrderConfirmation,
  currentClient,
  currentValues,
  individualContact = false,
  salutationHonorific,
  sendingDocumentsByEmailEnabled = false,
  crudMode,
  proposalDetails,
  change,
  saveWithoutRedirect,
  lineCategories,
  selectedCategory,
  fetchCompanyInfo,
  fetchClientDefaults,
  clearDefaultValues,
  initialValues,
  isSubjectDirty,
  hasSavedLineItems,
  defaultCategory,
  isDeprecatedCategory,
  handleSelectCategory,
  category,
  showCategoryChangeConfirmation,
  setShowCategoryChangeConfirmation,
  taxRateId,
  taxRates,
  defaultVat,
  isInitial,
  canSubmit,
  setLineItemState,
  setShowVatIdWarning,
  showVatIdWarning,
  showDifferentCategoryWarning,
  setShowDifferentCategoryWarning,
}) => {
  const isReadOnly = crudMode === SHOW_MODE;
  const proposalId = proposal.details.id;
  const showSalutation = !(isReadOnly && !individualContact);
  const isNew = crudMode === NEW_MODE;
  const handleClientSelected = async (client) => {
    if (!client) return;

    const subject = currentValues ? currentValues.subject : null;

    await fetchClientDefaults(client.id);

    if (isSubjectDirty && !initialValues.subject) change('subject', subject);
    change('client', client);
  };

  //fetch Company Info and clear default Values on unmount
  useEffect(() => {
    fetchCompanyInfo();

    return () => {
      clearDefaultValues();
    };
  }, []);

  return (
    <form>
      <LineItemsAutoSaveProvider>
        <div className={styles.main}>
          <div className={cx(styles.section, styles.creator)}>
            <CreateProposalSection
              onClientSelect={handleClientSelected}
              readonly={isReadOnly}
              lineCategories={lineCategories}
              hasSavedLineItems={hasSavedLineItems}
              defaultCategory={defaultCategory}
              selectedCategory={selectedCategory}
              crudMode={crudMode}
              change={change}
              isDeprecatedCategory={isDeprecatedCategory}
              handleSelectCategory={handleSelectCategory}
              category={category}
              showCategoryChangeConfirmation={showCategoryChangeConfirmation}
              setShowCategoryChangeConfirmation={setShowCategoryChangeConfirmation}
              setShowVatIdWarning={setShowVatIdWarning}
              showVatIdWarning={showVatIdWarning}
              showDifferentCategoryWarning={showDifferentCategoryWarning}
              setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
            />
            <If ok={showSalutation}>
              <IndividualContactSection
                change={change}
                client={currentClient}
                disabled={isReadOnly}
                salutationHonorific={salutationHonorific}
                dataIds={{
                  salutation: 'ProposalPage:input-salutation',
                  salutationContent: 'ProposalPage:input-salutation-content',
                }}
              />
            </If>
            <ProposalDetailsSection
              crudMode={crudMode}
              readonly={isReadOnly}
              defaultCategory={defaultCategory}
              selectedCategory={selectedCategory}
              lineCategories={lineCategories}
              taxRates={taxRates}
              taxRateId={taxRateId}
              defaultVat={defaultVat}
              isInitial={isInitial}
              setLineItemState={setLineItemState}
              setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
            />
            <div className={styles.bottomSection}>
              <Notes disabled={isReadOnly} />
              <ProposalSumSection insertedAsGross={proposalDetails.insertedAsGross} />
            </div>
            <Remark dataId="ProposalPage:notes" readonly={isReadOnly} />
          </div>
        </div>
        <LineItemsAutoSaveConsumer>
          {({ handleSave }) => (
            <ActionsSection
              currentClient={currentClient}
              wrapped={!isReadOnly}
              abort={abort}
              isFormDirty={dirty}
              isFormSubmitting={submitting}
              canSubmit={canSubmit}
              readonly={isReadOnly}
              showSave={canUpdate}
              showTransformIntoOrderConfirmation={canTransformIntoOrderConfirmation}
              showTransformIntoOutgoingInvoice={canTransformIntoOutgoingInvoice}
              download={download(proposal.details)}
              save={handleSave(handleSubmit(save(proposalId, isNew)))}
              saveAsDraft={handleSave(handleSubmit(saveAsDraft(proposalId, isNew)))}
              saveWithoutRedirect={handleSave(handleSubmit(saveWithoutRedirect(proposalId, isNew)))}
              saveAndTransformIntoOutgoingInvoice={handleSave(
                handleSubmit(saveAndTransformIntoOutgoingInvoice(proposalId, isNew))
              )}
              saveAndTransformIntoOrderConfirmation={handleSave(
                handleSubmit(saveAndTransformIntoOrderConfirmation(proposalId, isNew))
              )}
              transformIntoOutgoingInvoice={handleSave(
                handleSubmit(transformIntoOutgoingInvoice(proposalId))
              )}
              transformIntoOrderConfirmation={handleSave(
                handleSubmit(transformIntoOrderConfirmation(proposalId))
              )}
              sendingDocumentsByEmailEnabled={sendingDocumentsByEmailEnabled}
              crudMode={crudMode}
              isDraft={proposal.details.draft}
            />
          )}
        </LineItemsAutoSaveConsumer>
      </LineItemsAutoSaveProvider>
    </form>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    persistentSubmitErrors: true,
  }),
  withTransitionPrevent()
)(ProposalCreator);
