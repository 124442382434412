import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { noop, t } from 'shared/utils';
import If from 'components/Conditions/If';

import styles from './Thumbnail.module.css';

const Thumbnail = ({
  fileName,
  fileUrl,
  isActive,
  onClick,
  onRemove,
  readonly,
  setActiveNode,
  thumbnail,
}) => (
  <div className={styles.container} ref={isActive ? setActiveNode : noop}>
    <button
      className={cx(styles.thumbnail, readonly && styles.readonly, isActive && styles.isActive)}
      type="button"
      onClick={onClick}
      data-id="Gallery:button-previewThumbnail"
    >
      <img className={styles.thumbnailImage} src={thumbnail} alt="test" />
    </button>
    <div className={styles.buttons}>
      <a
        className={cx(styles.button, styles.download, readonly ? styles.isFull : '')}
        download={fileName}
        href={fileUrl}
        title={t('expenses.upload.actions.download')}
        data-id="Gallery:button-download"
      >
        {t('expenses.upload.actions.download')}
      </a>
      <If ok={!readonly}>
        <button
          className={cx(styles.button, styles.remove)}
          onClick={onRemove}
          title={t('expenses.upload.actions.remove')}
          type="button"
          data-id="Gallery:button-remove"
        />
      </If>
    </div>
  </div>
);

Thumbnail.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  setActiveNode: PropTypes.func.isRequired,
  thumbnail: PropTypes.string.isRequired,
};

export default Thumbnail;
