import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';

import { fetchAppUploads } from 'actions/app-uploads';
import { fetchNumberOfUnpaidInternalInvoices } from 'actions/internal-subscription-fees';
import paths from 'routes/paths';
import { appUploadsTotalSelector } from 'selectors/app-uploads';
import { unpaidInternalSubscriptionFeesTotalSelector } from 'selectors/internal-subscription-fees';
import { t } from 'shared/utils';
import IconExpenses from 'redesign/components/atoms/Icons/Expenses';

import { trackEventHandler } from '../utils';
import { Badge, Link, MenuItem } from './Shared.styled';

type ExpenseProps = {
  pathname: string;
  isNavOpen: boolean;
};

const Expense = ({ pathname, isNavOpen }: ExpenseProps) => {
  const isIncomingInvoices = !!matchPath(pathname, paths.incomingInvoices);
  const dispatch = useDispatch();
  const appUploadsTotal = useSelector(appUploadsTotalSelector);
  const unpaidInternalSubscriptionFeesTotal = useSelector(
    unpaidInternalSubscriptionFeesTotalSelector
  );
  const total = appUploadsTotal + unpaidInternalSubscriptionFeesTotal;

  useEffect(() => {
    dispatch(fetchAppUploads());
    dispatch(fetchNumberOfUnpaidInternalInvoices());
  }, [dispatch]);

  return (
    <MenuItem $isActive={isIncomingInvoices}>
      <Link
        $level={1}
        to={paths.incomingInvoices}
        $isActive={isIncomingInvoices}
        data-id="incoming-invoices"
        onClick={trackEventHandler('expenses_invoices')}
      >
        <IconExpenses />
        <span>
          {t('redesign.organisms.navigation.expense')}{' '}
          {!!total && (
            <Badge $isShifted={!isNavOpen} data-id="badge">
              {total}
            </Badge>
          )}
        </span>
      </Link>
    </MenuItem>
  );
};

export default memo(Expense);
