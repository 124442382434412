import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as pushAction } from 'connected-react-router';
import { get } from 'lodash';
import { arrayOf, bool, func, shape } from 'prop-types';

import { deleteDeliveryNote, sortDeliveryNotes } from 'actions/delivery-note';
import WarningSignImage from 'images/warning-sign.svg';
import paths from 'routes/paths';
import tableStyles from 'shared/styles/table.module.css';
import { t } from 'shared/utils';
import BoxWithButtonRedVariant from 'components/BoxWithButton/BoxWithButtonRedVariant/BoxWithButtonRedVariant';
import If from 'components/Conditions/If';
import I18n from 'components/I18n';
import { ConfirmationModal } from 'components/Modal';
import { CreationForbiddenMessage, EmptyState, TableHeader } from 'components/Table';

import NewDeliveryNotesButton from '../NewDeliveryNotesButton/NewDeliveryNotesButton';
import DeliveryNotesRow from './DeliveryNotesRow/DeliveryNotesRow';

import localStyles from './DeliveryNotesTable.module.css';

export const styles = { ...tableStyles, ...localStyles };

class DeliveryNotesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deletingItem: undefined,
    };
  }

  onClearConfirmation = () => {
    this.setState({ deletingItem: undefined });
  };

  onDelete = (item) => {
    this.onClearConfirmation();
    const { refresh, remove } = this.props;
    remove(item).then(refresh);
  };

  onRequestDelete = (deliveryNote) => {
    this.setState({ deletingItem: deliveryNote });
  };

  render() {
    const {
      sorting,
      isFetching,
      canCreate,
      showModal,
      data,
      sort,
      refresh,
      isProfileFilled,
      push,
    } = this.props;
    if (!data.length) {
      return (
        <EmptyState isFetching={isFetching}>
          <If ok={canCreate}>
            <NewDeliveryNotesButton showModal={showModal} />
          </If>
          <If ok={!canCreate}>
            <CreationForbiddenMessage section="delivery_notes" />
          </If>
        </EmptyState>
      );
    }

    const columns = [
      { column: 'delivery_note_status', sortable: false },
      { column: 'client', sortable: true },
      { column: 'delivery_note_number', sortable: true },
      { column: 'subject', sortable: true },
      { column: 'delivery_note_date', sortable: true },
    ];

    return (
      <div className={styles.invoicesTableWrapper}>
        {!isProfileFilled && (
          <BoxWithButtonRedVariant
            dataId="DeliveryNotes:warning-box"
            image={WarningSignImage}
            content={t('empty_entry_pages.delivery_notes.table_warning_box.content_1')}
            contentSecondLine={t('empty_entry_pages.delivery_notes.table_warning_box.content_2')}
            buttonLabel={t('empty_entry_pages.delivery_notes.table_warning_box.button_label')}
            onClick={() => push(paths.companyProfile)}
          />
        )}
        <table
          className={cx(styles.invoicesTable, {
            [styles.invoicesTableLoading]: isFetching,
            [styles.tableWarningMargin]: !isProfileFilled,
          })}
        >
          <thead>
            <tr>
              {columns.map((column) => (
                <TableHeader
                  {...column}
                  key={column.column}
                  columnWidthClass={styles.column}
                  currentSorting={sorting}
                  refresh={refresh}
                  section="delivery_notes"
                  sort={sort}
                  sortable={column.sortable}
                />
              ))}
              <TableHeader
                column="actions"
                columnWidthClass={styles.column}
                refresh={refresh}
                sort={sort}
              />
            </tr>
          </thead>
          <tbody>
            {data.length &&
              data.map((item) => {
                return (
                  <DeliveryNotesRow
                    key={item.id}
                    item={item}
                    onDelete={this.onRequestDelete}
                    onTransform={this.onTransform}
                  />
                );
              })}
          </tbody>
        </table>
        <ConfirmationModal
          dangerousAction
          isOpen={!!this.state.deletingItem}
          onClose={this.onClearConfirmation}
          closeLabel={t('features.delivery_note.table.confirm_delete.discard')}
          onConfirm={() => this.onDelete(this.state.deletingItem)}
        >
          <I18n t="features.delivery_note.table.confirm_delete.message" paragraphs />
        </ConfirmationModal>
      </div>
    );
  }
}

DeliveryNotesTable.propTypes = {
  canCreate: bool.isRequired,
  showModal: bool.isRequired,
  data: arrayOf(shape({})).isRequired,
  isFetching: bool.isRequired,
  push: func.isRequired,
  refresh: func.isRequired,
  remove: func.isRequired,
  sort: func.isRequired,
  sorting: shape({}).isRequired,
  isProfileFilled: bool.isRequired,
};

const mapStateToProps = (state) => ({
  canCreate: get(state, 'deliveryNotes.meta.actions.create', false),
  showModal: !get(state, 'deliveryNotes.meta.bank-account-data-present', false),
  isProfileFilled: state.onboarding.data?.profileFilled,
});

const mapDispatchToProps = {
  push: pushAction,
  remove: deleteDeliveryNote,
  sortDeliveryNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryNotesTable);
