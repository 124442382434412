import React, { Fragment } from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';
import { string } from 'prop-types';

import styles from '../Button.module.css';

const ButtonContent = ({ label, icon, rightIcon, svgClass }) => (
  <>
    {icon && (
      <div className={cx(styles.icon, svgClass)}>
        <InlineSvg src={icon} />
      </div>
    )}
    {label}
    {rightIcon && (
      <div className={cx(styles.icon, styles.rightIcon)}>
        <InlineSvg src={rightIcon} />
      </div>
    )}
  </>
);

ButtonContent.propTypes = {
  icon: string,
  svgClass: string,
  rightIcon: string,
  label: string.isRequired,
};

export default ButtonContent;
