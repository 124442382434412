import React from 'react';
import cx from 'classnames';

import arrowDownIcon from 'images/icon-arrow-down.svg';
import { t } from 'shared/utils';

import styles from '../Gallery.module.css';

type NextFileBtnProps = {
  onClick: () => void;
  isPreviewRendering?: boolean;
};

const NextFileBtn = ({ onClick, isPreviewRendering }: NextFileBtnProps) => (
  <button
    title={t('expenses.upload.next_file')}
    className={cx(styles.arrowNext, { [styles.arrowInactive]: isPreviewRendering })}
    type="button"
    onClick={onClick}
    data-id="Gallery:button-nextFile"
  >
    <img
      alt={t('expenses.upload.next_file')}
      className={styles.arrowNextIcon}
      src={arrowDownIcon}
      data-id="Gallery:icon-nextFile"
    />
  </button>
);

export default NextFileBtn;
