import styled from 'styled-components';

import ActionButton from 'components/ActionPanel/ActionButton';

export const Wrapper = styled.div`
  padding: 0 20px;
  text-align: center;

  p {
    max-width: 285px;
    margin: 0 auto;
    font-size: 15px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  padding: 40px 0;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(ActionButton)`
  height: 41px;
  margin: 0 5px;
`;
