import React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { DATE_FORMAT_UNICODE } from 'constants/datetime';
import { PIWIK_ACTION_REVENUE_ADD_CASH_PAYMENT, PIWIK_CATEGORY_REVENUE } from 'constants/piwik';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import bankTransferStyles from 'components/BankTransfer/BankTransfer.module.css';
import { DateField, FormField, TextareaField } from 'components/Form';

import styles from './AssignmentMode.module.css';

type FormData = {
  paidByUnspecifiedDate: string;
  paidByUnspecifiedReason: string;
};

type PaidByUnspecifiedAssignmentModeOwnProps = {
  onAccept: (date: string | null, reason: string | null) => void;
  onClose: () => void;
};

type PaidByUnspecifiedAssignmentModeProps = PaidByUnspecifiedAssignmentModeOwnProps &
  InjectedFormProps<FormData, PaidByUnspecifiedAssignmentModeOwnProps>;

const PaidByUnspecifiedAssignmentMode = ({
  onAccept,
  onClose,
  handleSubmit,
}: PaidByUnspecifiedAssignmentModeProps) => {
  const handleAccept = (values: FormData) => {
    piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_REVENUE_ADD_CASH_PAYMENT);
    return onAccept(values.paidByUnspecifiedDate || null, values.paidByUnspecifiedReason || null);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{t('bank_transfers.assigment.assigment_mode_title')}</div>
      <div className={cx(bankTransferStyles.assigmentMode, styles.details)}>
        <div className={bankTransferStyles.detailsSection}>
          <div className={styles.title}>{t('revenue.form.paid_by_unspecified')}</div>
        </div>
        <div className={cx(bankTransferStyles.selectSection, styles.selectSection)}>
          <div className={styles.paid}>
            <FormField
              name="paidByUnspecifiedDate"
              label={t(`revenue.form.paid_by_unspecified_date`)}
              placeholder={t(`revenue.form.paid_by_unspecified_date`)}
              component={DateField}
            />
          </div>
          <div className={styles.reason}>
            <FormField
              name="paidByUnspecifiedReason"
              label={t(`revenue.form.paid_by_unspecified_reason`)}
              placeholder={t(`revenue.form.paid_by_unspecified_reason`)}
              component={TextareaField}
              dataId="paid-by-unspecified-reason"
              minHeight={0}
            />
          </div>
        </div>
        <div className={bankTransferStyles.actionSection}>
          <div className={bankTransferStyles.actionButtons}>
            <button
              type="button"
              data-id="accept-assignment"
              className={bankTransferStyles.accept}
              title={t('bank_transfers.select.actions.accept_suggestion')}
              onClick={handleSubmit(handleAccept)}
            />
            <button
              type="button"
              className={bankTransferStyles.cancel}
              onClick={onClose}
              title={t('bank_transfers.select.actions.reject_suggestion')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default reduxForm<FormData, PaidByUnspecifiedAssignmentModeOwnProps>({
  form: 'UnspecifiedAssignmentMode',
  initialValues: {
    paidByUnspecifiedDate: format(new Date(), DATE_FORMAT_UNICODE),
  },
})(PaidByUnspecifiedAssignmentMode);
