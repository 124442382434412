import React from 'react';
import cx from 'classnames';
import { string } from 'prop-types';

import { t } from 'shared/utils';

import styles from './LegalsDescription.module.css';

const LegalsDescription = ({ className }) => (
  <span className={cx(styles.footer, className)}>{t('profile.payment_plan.modals_footer')}</span>
);

LegalsDescription.propTypes = {
  className: string,
};

export default LegalsDescription;
