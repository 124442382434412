import { Dictionary } from '@reduxjs/toolkit';
import { isToday, parseISO } from 'date-fns';

import { CATEGORY_EMAIL_VERIFICATION_INFOBAR } from 'constants/piwik';
import { piwikHelpers } from 'shared/utils/piwik';
import { Entry } from 'store/slices/globalNotifications/types';
import { GlobalNotification } from 'types/entities/GlobalNotification';

export const trackEvent = piwikHelpers.trackEventCurried(CATEGORY_EMAIL_VERIFICATION_INFOBAR);

export const filterOutClosedGlobalNotifications = (
  notifications: GlobalNotification[],
  closed: Dictionary<Entry>,
  email: string
) =>
  notifications.filter((notification) => {
    const wasClosed = closed[`${notification.id}-${email}`];

    if (wasClosed) {
      const date = parseISO(wasClosed.date);

      return !isToday(date);
    }

    return true;
  });
