import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { connectionCreateFailure } from 'notifications/bank-transfer-connections';

/**
 * Convert 'create connection' api response into notification error message object
 */
export default (apiResponse) => {
  const errors = get(apiResponse, 'response.data.errors', []);

  if (isEmpty(errors)) {
    return connectionCreateFailure;
  }

  return {
    ...connectionCreateFailure,
    title: errors[0].detail,
  };
};
