import React from 'react';
import { bool, func, shape, string } from 'prop-types';

import { Checkbox } from 'components/Form';
import I18n from 'components/I18n';
import ActionEdit from 'components/IndexActionsContainer/actionIcons/ActionEdit';

import BillingData from './components/BillingData/BillingData';
import CompanyEditForm from './components/CompanyEditForm/CompanyEditForm';

import styles from './BillingAddress.module.css';

const BillingAddress = ({
  companyDetails,
  isChecked,
  handleCheckboxToggle,
  handleCreateDirectDebit,
  isInEditState,
  handleOnEditCompanyForm,
}) => (
  <>
    {isInEditState ? (
      <div className={styles.form}>
        <CompanyEditForm
          name={companyDetails.name}
          initialValues={companyDetails}
          handleCreateDirectDebit={handleCreateDirectDebit}
        />
      </div>
    ) : (
      <div className={styles.dataContainer}>
        <BillingData {...companyDetails} />
        <ActionEdit dataId="edit-company-address" onClick={handleOnEditCompanyForm} />
      </div>
    )}
    <Checkbox
      onChange={handleCheckboxToggle}
      checked={isChecked}
      className={styles.checkbox}
      dataIds={{ input: 'PaymentPlans:directDebitCheckbox' }}
    >
      <I18n t="profile.payment_plan.direct_debit.checkbox" />
    </Checkbox>
  </>
);

BillingAddress.propTypes = {
  isChecked: bool.isRequired,
  handleCheckboxToggle: func.isRequired,
  handleCreateDirectDebit: func.isRequired,
  handleOnEditCompanyForm: func.isRequired,
  shouldEditCompanyData: bool.isRequired,
  companyDetails: shape({
    name: string,
    street: string,
    zipcode: string,
    city: string,
  }),
  isInEditState: bool,
};

export default BillingAddress;
