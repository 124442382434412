import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';

import { fetchExternalOriginCount } from 'actions/externalOriginCount';
import { deliveryNotesEnabledHelper } from 'routes/accesses';
import { orderConfirmationEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { farmpilotInvoicesCountSelector } from 'selectors/externalOriginCount';
import { t } from 'shared/utils';
import IconRevenue from 'redesign/components/atoms/Icons/Revenue';

import { trackEvent, trackEventHandler } from '../utils';
import { Arrow, Badge, Link, MenuItem } from './Shared.styled';
import { getMatches } from './utils';

type RevenueProps = {
  pathname: string;
  isNavOpen: boolean;
};

const URLS = {
  proposals: paths.proposals,
  outgoingInvoices: paths.outgoingInvoices,
  deliveryNotes: paths.deliveryNotes,
  orderConfirmation: paths.orderConfirmation,
};

const Revenue = ({ pathname, isNavOpen }: RevenueProps) => {
  const dispatch = useDispatch();
  const isOrderConfirmationEnabled = useSelector(orderConfirmationEnabledHelper);
  const farmpilotInvoicesCount = useSelector(farmpilotInvoicesCountSelector);
  const matches = getMatches(pathname, URLS);
  const isActive = Object.values(matches).some((match) => match);
  const [isOpen, setOpen] = useState(isActive);
  const isDeliveryNotesEnabled = useSelector(deliveryNotesEnabledHelper);

  const handleToggle = useCallback(() => {
    trackEvent(`revenues_${!isOpen ? 'open' : 'close'}`);
    setOpen((isOpen) => !isOpen);
  }, [isOpen]);

  useUpdateEffect(() => {
    setOpen(isActive);
  }, [pathname, isNavOpen]);

  useEffect(() => {
    dispatch(fetchExternalOriginCount());
  }, [dispatch]);

  return (
    <MenuItem $isActive={isActive} $isOpen={isOpen}>
      <Link $level={1} as="span" $isActive={isActive} onClick={handleToggle} data-id="revenue">
        <IconRevenue />
        <span>
          {t('redesign.organisms.navigation.revenue')}{' '}
          {!!farmpilotInvoicesCount && (!isOpen || (isOpen && !isNavOpen)) && (
            <Badge $isShifted={!isNavOpen} data-id="badge">
              {farmpilotInvoicesCount}
            </Badge>
          )}
        </span>
        <Arrow $isOpen={isOpen} />
      </Link>

      <ul>
        <li>
          <Link
            $level={2}
            to={paths.proposals}
            $isActive={matches.proposals}
            data-id="proposals"
            onClick={trackEventHandler('revenues_proposals')}
          >
            {t('redesign.organisms.navigation.proposals')}
          </Link>
        </li>
        {isOrderConfirmationEnabled && (
          <li>
            <Link
              $level={2}
              to={paths.orderConfirmation}
              $isActive={matches.orderConfirmation}
              data-id="orderConfirmation"
              onClick={trackEventHandler('order_confirmation')}
            >
              {t('redesign.organisms.navigation.order_confirmation')}
            </Link>
          </li>
        )}
        <li>
          <Link
            $level={2}
            to={paths.outgoingInvoices}
            $isActive={matches.outgoingInvoices}
            data-id="outgoing-invoices"
            onClick={trackEventHandler('revenues_invoices')}
          >
            <span>
              {t('redesign.organisms.navigation.outgoing_invoices')}{' '}
              {!!farmpilotInvoicesCount && <Badge data-id="badge">{farmpilotInvoicesCount}</Badge>}
            </span>
          </Link>
        </li>
        {isDeliveryNotesEnabled && (
          <li>
            <Link
              $level={2}
              to={paths.deliveryNotes}
              $isActive={matches.deliveryNotes}
              data-id="delivery-notes"
              onClick={trackEventHandler('delivery_notes')}
            >
              <span>{t('redesign.organisms.navigation.delivery_notes')}</span>
            </Link>
          </li>
        )}
      </ul>
    </MenuItem>
  );
};

export default memo(Revenue);
