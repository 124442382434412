import { connect } from 'react-redux';
import { branch, compose, lifecycle, renderComponent, withHandlers, withState } from 'recompose';
import { bindActionCreators } from 'redux';

import * as ImportContactsActionCreators from 'actions/import-contacts';
import withRouter from 'shared/utils/withRouter';
import Loading from 'components/Loading';

import { ImportContacts } from './ImportContacts';

const withFile = compose(
  withState('file', 'setFile', null),
  withHandlers({
    onFileChange:
      ({ setFile }) =>
      (e) =>
        setFile(e.target.files[0]),
  })
);

const withAPIActions = connect(null, (dispatch) => ({
  actions: bindActionCreators(ImportContactsActionCreators, dispatch),
}));

const createImport = compose(
  withState('isUploading', 'setIsUploading', false),
  withHandlers({
    createImport:
      ({ actions, file, importedModel, setIsUploading, setFile }) =>
      async () => {
        setIsUploading(true);
        try {
          return await actions.uploadContacts({ file, importedModel });
        } finally {
          setFile(null);
          setIsUploading(false);
        }
      },
  })
);

const fetchImportsOnMount = compose(
  withState('syncInProgress', 'setSyncInProgress', false),
  withState('importId', 'setImportId', null),
  withState('hasLoadedInitialData', 'setHasLoadedInitialData', false),
  lifecycle({
    async componentDidMount() {
      const { importedModel } = this.props;
      const { response } = await this.props.actions.getImports();
      const [lastImport] = response.data.filter(
        ({ attributes }) => attributes.importedModel === importedModel
      );
      const { id, attributes: { syncInProgress } = {} } = lastImport || {};
      this.props.setImportId(id);
      if (syncInProgress) {
        this.props.setSyncInProgress(true);
      }
      this.props.setHasLoadedInitialData(true); // Will render the component
    },
  }),
  branch(({ hasLoadedInitialData }) => !hasLoadedInitialData, renderComponent(Loading))
);

const enhance = compose(withAPIActions, withFile, createImport, fetchImportsOnMount, withRouter);

export default enhance(ImportContacts);
