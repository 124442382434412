import { JsonApiResponse } from 'json-api-normalizer';

import api from 'api';
import { Cashbook } from 'types/entities/Cashbook';

export const getCashbooks = () => {
  const url = '/me/cashbooks';

  return api.get<JsonApiResponse>(url);
};

export const addCashbook = (
  data: Pick<
    Cashbook,
    'startDate' | 'reminder' | 'reminderType' | 'reminderDay' | 'reminderHour' | 'weekdayReminder'
  > & {
    startAmount: number | null;
  }
) => {
  const url = '/me/cashbooks';

  return api.post<JsonApiResponse>(url, {
    start_date: data.startDate,
    start_amount: data.startAmount,
    reminder: data.reminder,
    reminder_type: data.reminderType,
    reminder_day: data.reminderDay,
    reminder_hour: data.reminderHour,
    weekday_reminder: data.weekdayReminder,
  });
};

export const editCashbook = (
  data: Pick<
    Cashbook,
    'reminder' | 'reminderType' | 'reminderDay' | 'reminderHour' | 'weekdayReminder'
  >,
  cashbookId: string
) => {
  const url = `/me/cashbooks/${cashbookId}`;

  return api.put<JsonApiResponse>(url, {
    reminder: data.reminder,
    reminder_type: data.reminderType,
    reminder_day: data.reminderDay,
    reminder_hour: data.reminderHour,
    weekday_reminder: data.weekdayReminder,
  });
};

export const downloadPdf = (cashbookId: string) => {
  const url = `/me/cashbooks/${cashbookId}/download`;

  return api.get<ArrayBuffer>(url, { responseType: 'arraybuffer' });
};
