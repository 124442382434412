import React from 'react';

import styles from '../StatusIcons.module.css';

const UnassignedStatusIcon = () => (
  <svg viewBox="0 0 20 20" className={styles.statusIcon} xmlns="http://www.w3.org/2000/svg">
    <g className={styles.strokeNeutral} fill="none" fillRule="evenodd">
      <g transform="translate(2.222 4)" fillRule="nonzero">
        <path
          d="M9.13 3.832l1.67-1.084c1.143-.645 2.072-.39 2.787.763.431.972.095 1.82-1.009 2.543l-1.446.917c-1.381.894-2.377.904-2.988.028"
          strokeLinecap="round"
        />
        <rect
          transform="rotate(21 4.774 6.63)"
          x="2.843"
          y="3.13"
          width="3.861"
          height="7"
          rx="1.931"
        />
      </g>
      <rect x=".5" y=".5" width="19" height="19" rx="2" />
    </g>
  </svg>
);

export default UnassignedStatusIcon;
