import React, { useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { resetCustomerDefaults, updateCustomerDefaults } from 'actions/default-values';
import { getPaymentReminder, GetPaymentReminderData } from 'api/me/previews/paymentReminder';
import { DocumentsFormName } from 'containers/Profile/InvoiceTemplate/DefaultValues/TabDocumentsDefaults/TabDocumentsDefaults';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { RootState } from 'store';
import { DefaultValues } from 'types/entities/DefaultValues';
import { TextField } from 'components/Form';
import InputsGroup from 'components/InputsGroup/InputsGroup';
import HtmlField from 'components/v2/Form/HtmlField/HtmlField';

import { FormRow } from '../../sharedComponents';
import DefaultValuesFormWithPreview from '../../sharedComponents/DefaultValuesForm/DefaultValuesFormWithPreview';

import styles from './PaymentReminderDefaults.module.css';

export const FORM_NAME: DocumentsFormName = 'paymentReminderDefaults';

type FormData = Pick<
  DefaultValues,
  | 'paymentReminderSubject'
  | 'paymentReminderSalutation'
  | 'paymentReminderSalutationContent'
  | 'paymentReminderFooter'
>;

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    paymentReminderSubject: state.defaultValues.values.paymentReminderSubject,
    paymentReminderSalutation: state.defaultValues.values.paymentReminderSalutation,
    paymentReminderSalutationContent: state.defaultValues.values.paymentReminderSalutationContent,
    paymentReminderFooter: state.defaultValues.values.paymentReminderFooter,
  },
  formValues: {
    paymentReminderSalutation: selector(state, 'paymentReminderSalutation'),
    paymentReminderSalutationContent: selector(state, 'paymentReminderSalutationContent'),
    paymentReminderFooter: selector(state, 'paymentReminderFooter'),
  },
});

const mapDispatchToProps = {
  updateCustomerDefaults,
  resetCustomerDefaults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PaymentReminderDefaultsProps = ConnectedProps<typeof connector>;

const PaymentReminderDefaults = ({
  formValues: {
    paymentReminderSalutation,
    paymentReminderSalutationContent,
    paymentReminderFooter,
  },
  resetCustomerDefaults,
  updateCustomerDefaults,
  handleSubmit,
  reset: resetForm,
  initialValues,
  initialized,
  initialize,
}: PaymentReminderDefaultsProps & InjectedFormProps<FormData, PaymentReminderDefaultsProps>) => {
  const topFields = useMemo(
    () => (
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.discount_subject')}>
          <Field
            disabled
            className={styles.grouppedInput}
            name="paymentReminderSubject"
            checker={serverValidationChecker}
            component={TextField}
            dataId="DocumentDefaults:input-payment-reminder-subject"
            isDisabledWithUnderline
          />
        </InputsGroup>
      </FormRow>
    ),
    []
  );

  const values = useMemo(
    () => ({
      introduction:
        paymentReminderSalutation && paymentReminderSalutationContent
          ? `${paymentReminderSalutation} ${paymentReminderSalutationContent}`
          : undefined,
      footer: paymentReminderFooter,
    }),
    [paymentReminderSalutation, paymentReminderSalutationContent, paymentReminderFooter]
  );

  useEffect(() => {
    initialized && initialize(initialValues);
  }, []);

  return (
    <DefaultValuesFormWithPreview<GetPaymentReminderData>
      onSubmit={handleSubmit((values) => updateCustomerDefaults(values, 'form_payment_reminder'))}
      onReset={async () => {
        await resetCustomerDefaults('form_payment_reminder');
        resetForm();
      }}
      sectionName="form_payment-reminder"
      sectionLabel={t('invoice_templates.documents_defaults.payment_reminder_defaults_section')}
      dataIdPrefix="DocumentDefaults:"
      values={values}
      fetchPreview={getPaymentReminder}
      topFields={topFields}
    >
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.header')}>
          <Field
            name="paymentReminderSalutation"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation')}
            tooltip={t('invoice_templates.documents_defaults.salutation_info')}
            dataId="DocumentDefaults:input-payment-reminder-salutation"
          />
          <br />
          <Field
            name="paymentReminderSalutationContent"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation_content')}
            dataId="DocumentDefaults:input-payment-reminder-salutation-content"
          />
        </InputsGroup>
      </FormRow>
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.footer')}>
          <Field
            name="paymentReminderFooter"
            component={HtmlField}
            checker={serverValidationChecker}
            dataId="DocumentDefaults:input-payment-reminder-footer"
          />
        </InputsGroup>
      </FormRow>
    </DefaultValuesFormWithPreview>
  );
};

const FormEnhancedComponent = reduxForm<FormData, PaymentReminderDefaultsProps>({
  form: FORM_NAME,
  enableReinitialize: true,
  persistentSubmitErrors: true,
})(PaymentReminderDefaults);

export default connector(FormEnhancedComponent);
