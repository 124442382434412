import React, { Component, Fragment } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { arrayOf, func, shape, string } from 'prop-types';
import { submit } from 'redux-form';

import { updateCompany as updateCompanyAction } from 'actions/company';
import { createDirectDebit as createDirectDebitAction } from 'actions/direct-debit';
import { showNotification as showNotificationAction } from 'actions/notification';
import { fetchUserPlan as fetchUserPlanAction } from 'actions/payment-plan';
import { upgradePlan as upgradePlanAction } from 'actions/payment-plan/payment-plan';
import { fetchProfile as fetchProfileAction } from 'actions/profile';
import { COMPANY_EDIT_FORM_NAME } from 'constants/payment-plans';
import { bookPremiumError } from 'notifications/payment-plans';
import { companyEditFormSelector } from 'reducers/form';
import { companyDetailsSelector } from 'selectors/company';
import { directDebitSelector } from 'selectors/direct-debit';
import { getPremiumPaymenPlan } from 'selectors/payment-plans';
import { t } from 'shared/utils';
import { getDirectDebitAccount } from 'shared/utils/direct-debit-mandate';
import Section from 'components/Form/Section/Section';

import BankAccountSelectField from '../components/BankAccountSelectField/BankAccountSelectField';
import Buttons from '../components/Buttons/Buttons';
import Header from '../components/Header/Header';
import AccountData from './components/AccountData/AccountData';
import BillingAddress from './components/BillingAddress/BillingAddress';
import DebitInfo from './components/DebitInfo/DebitInfo';

import styles from './DirectDebit.module.css';

class DirectDebit extends Component {
  state = {
    agreeCheckbox: false,
    isInEditState: this.getShouldEditCompanyData,
    setMainPaymentBankAccountLoading: false,
    isCreationInProgress: false,
    directDebitAccount: getDirectDebitAccount(
      this.props.paymentsBankAccounts,
      this.props.debitData.iban
    ),
  };

  selectAccount = (selectedAccount = {}) => {
    const { directDebitAccount } = this.state;

    if (directDebitAccount?.accountId === selectedAccount?.accountId) return;
    this.setState({ directDebitAccount: selectedAccount });
  };

  handleCheckboxToggle = () =>
    this.setState((prevState) => ({ agreeCheckbox: !prevState.agreeCheckbox }));

  handleOnEditCompanyForm = () => {
    this.setState({ isInEditState: true, agreeCheckbox: false });
  };

  get getShouldEditCompanyData() {
    const {
      companyDetails: { name, street, zipcode, city },
    } = this.props;
    return !name || !street || !zipcode || !city;
  }

  get debitData() {
    const {
      credentials: { mandateReference },
      creditorIdentificationNumber,
    } = this.props;
    const { directDebitAccount: { bankName, bic, iban } = {} } = this.state;

    return {
      name: bankName,
      bic,
      iban,
      direct_debit_mandate: creditorIdentificationNumber,
      mandate_reference: mandateReference,
    };
  }

  get isSubmitButtonDisabled() {
    const {
      companyEditFormData: { name, street, zipcode, city },
    } = this.props;
    const { isInEditState, agreeCheckbox, isCreationInProgress } = this.state;
    const validateFormFields = !name || !street || !zipcode || !city;
    const shouldValidateForm = isInEditState ? validateFormFields : false;

    return isCreationInProgress || !agreeCheckbox || shouldValidateForm;
  }

  handleActions = async () => {
    const {
      createDirectDebit,
      confirmDirectDebit,
      upgradePlan,
      showNotification,
      fetchUserPlan,
      fetchProfile,
      prmeiumPaymentPlan: { id },
    } = this.props;
    const {
      agreeCheckbox,
      directDebitAccount: { iban },
    } = this.state;

    this.setState({ isCreationInProgress: true });

    try {
      await createDirectDebit({ agreeCheckbox, bankIban: iban });
      await upgradePlan(id);
    } catch (error) {
      return showNotification();
    }

    fetchUserPlan();
    fetchProfile();
    this.setState({ isCreationInProgress: false });

    return confirmDirectDebit();
  };

  handleCreateDirectDebit = async (companyFormValues) => {
    const { updateCompany } = this.props;

    if (companyFormValues) await updateCompany(companyFormValues);

    this.handleActions();
  };

  onSubmit = () => {
    const { submitUpdateCompanyForm } = this.props;
    const { isInEditState } = this.state;

    if (this.getShouldEditCompanyData || isInEditState) return submitUpdateCompanyForm();
    return this.handleCreateDirectDebit();
  };

  render() {
    const {
      paymentsBankAccounts,
      companyDetails,
      onClose,
      debitData: { iban },
    } = this.props;
    const { agreeCheckbox, isInEditState, setMainPaymentBankAccountLoading } = this.state;

    return (
      <Fragment>
        <Header
          bold={t('profile.payment_plan.direct_debit.header_1')}
          description={t('profile.payment_plan.direct_debit.header_2')}
        />
        <Section title={t('profile.payment_plan.direct_debit.frist_section_title')}>
          <Grid className={styles.gridSection}>
            <Row>
              <Col xs={12} lg={8} className={styles.selectContainer}>
                <BankAccountSelectField
                  name="accountId"
                  disabled={setMainPaymentBankAccountLoading}
                  bankAccounts={paymentsBankAccounts}
                  component={BankAccountSelectField}
                  onSelect={this.selectAccount}
                  debitAccountIban={iban}
                  label={t('profile.payment_plan.direct_debit.select_label')}
                  hideTrailingContent
                />
              </Col>
            </Row>
          </Grid>
          <div className={styles.dataContainer}>
            <AccountData data={this.debitData} />
            <DebitInfo />
          </div>
        </Section>
        <Section
          title={t('profile.payment_plan.direct_debit.second_section_title')}
          className={styles.dataContainer}
        >
          <BillingAddress
            handleOnEditCompanyForm={this.handleOnEditCompanyForm}
            shouldEditCompanyData={this.getShouldEditCompanyData}
            companyDetails={companyDetails}
            handleCreateDirectDebit={this.handleCreateDirectDebit}
            handleCheckboxToggle={this.handleCheckboxToggle}
            isChecked={agreeCheckbox}
            isInEditState={isInEditState}
          />
        </Section>
        <Buttons
          backLabel={t('profile.payment_plan.direct_debit.button_back')}
          forwardLabel={t('profile.payment_plan.direct_debit.button_forward')}
          onClose={onClose}
          onSubmit={this.onSubmit}
          disableForward={this.isSubmitButtonDisabled}
          dataIds={{ forwardButton: 'PaymentPlans:finishCreation', backButton: 'back-button' }}
        />
      </Fragment>
    );
  }
}

DirectDebit.propTypes = {
  creditorIdentificationNumber: string.isRequired,
  paymentsBankAccounts: arrayOf(shape({})),
  onClose: func.isRequired,
  createDirectDebit: func.isRequired,
  submitUpdateCompanyForm: func.isRequired,
  updateCompany: func.isRequired,
  fetchProfile: func.isRequired,
  confirmDirectDebit: func.isRequired,
  upgradePlan: func.isRequired,
  fetchUserPlan: func.isRequired,
  companyDetails: shape({}),
  showNotification: func.isRequired,
  companyEditFormData: shape({}),
  credentials: shape({}),
  debitData: shape({
    number: string,
    iban: string,
  }),
  prmeiumPaymentPlan: shape({
    id: string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  companyDetails: companyDetailsSelector(state),
  companyEditFormData: companyEditFormSelector(state, 'name', 'street', 'zipcode', 'city'),
  credentials: state.profile.credentials,
  debitData: directDebitSelector(state),
  prmeiumPaymentPlan: getPremiumPaymenPlan(state),
  creditorIdentificationNumber: state.appSettings.directDebitMandates.creditorIdentificationNumber,
});

const mapDispatchToProps = (dispatch) => ({
  createDirectDebit: (...args) => dispatch(createDirectDebitAction(...args)),
  updateCompany: (...args) => dispatch(updateCompanyAction(...args)),
  submitUpdateCompanyForm: () => dispatch(submit(COMPANY_EDIT_FORM_NAME)),
  upgradePlan: (id) => dispatch(upgradePlanAction(id)),
  showNotification: () => dispatch(showNotificationAction(bookPremiumError)),
  fetchUserPlan: () => dispatch(fetchUserPlanAction()),
  fetchProfile: () => dispatch(fetchProfileAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DirectDebit);
