import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';
import { arrayOf, bool, func, shape } from 'prop-types';

import * as companyActions from 'actions/company';
import * as datevActions from 'actions/datev';
import { MODAL_HEADER_VARIANT } from 'constants/modal';
import { Resources } from 'constants/resources';
import ContactDetailsBox from 'containers/Datev/ContactDetailsBox/ContactDetailsBox';
import CreateAction from 'containers/Datev/CreateAction';
import DynamicExportDateBox from 'containers/Datev/DynamicExportDateBox/DynamicExportDateBox';
import Table from 'containers/Datev/Table/Table';
import paths from 'routes/paths';
import { t, toMoment } from 'shared/utils';
import CardView, { HeadingSection, Section } from 'components/CardView';
import I18n from 'components/I18n';
import { ConfirmationModal, InfoModal } from 'components/Modal';
import { Pagination } from 'components/Pagination/Pagination';

import styles from './Datev.module.css';

class Datev extends Component {
  componentDidMount() {
    const { fetchCompany, removeStartAndEndDateError, removeEndDateError } = this.props;

    this.fetchDateReports({ page: 1 });
    removeStartAndEndDateError();
    removeEndDateError();
    fetchCompany();
  }

  fetchDateReports = (pagination) => {
    const { getIndex, sorting } = this.props;
    getIndex(pagination, sorting);
  };

  sortBy = (column) => {
    this.props.sortAndRefresh(column);
  };

  handleConfirm = () => {
    const { setReportDatePeriod, dates } = this.props;
    setReportDatePeriod(
      toMoment(dates.nextReportStartDate).format('DD.MM.YYYY'),
      moment().format('DD.MM.YYYY')
    );
  };

  render() {
    const {
      isFetching,
      data,
      pagination,
      sorting,
      dates,
      removeEndDateError,
      removeStartAndEndDateError,
    } = this.props;
    const isEmpty = data.length === 0;

    return (
      <div className={styles.page}>
        <ConfirmationModal
          dangerousAction
          isOpen={dates.exportEndDateError}
          onClose={() => removeEndDateError()}
          onConfirm={() => this.handleConfirm()}
          closeLabel={t('datev.dynamic_date.info_messages.one_date_in_future.cancel')}
          confirmLabel={t('datev.dynamic_date.info_messages.one_date_in_future.continue')}
          header={t('datev.dynamic_date.info_messages.one_date_in_future.header')}
          headerVariant={MODAL_HEADER_VARIANT.SMALL}
          redirectLocation={paths.datevNew}
          hasWarningIcon
        >
          <I18n t="datev.dynamic_date.info_messages.one_date_in_future.content" />
        </ConfirmationModal>
        <InfoModal
          customClass="grow"
          isOpen={dates.exportStartAndEndDateError}
          onClose={() => removeStartAndEndDateError()}
          header={t('datev.dynamic_date.info_messages.both_dates_in_future.header')}
          headerVariant={MODAL_HEADER_VARIANT.SMALL}
          hasWarningIcon
        >
          <I18n t="datev.dynamic_date.info_messages.both_dates_in_future.content" />
        </InfoModal>
        <div className={cx(styles.boxContainer)}>
          <DynamicExportDateBox />
          <ContactDetailsBox />
        </div>
        <CardView className={styles.card}>
          <HeadingSection>
            <I18n t="datev.index.subheader" className={styles.headingText} />
            <div className={cx(styles.pullRight, { [styles.hiddenSection]: isEmpty })}>
              <CreateAction dataId="Datev:button-new-datev" />
            </div>
          </HeadingSection>
          <Section className={styles.section}>
            <Table data={data} sorting={sorting} isFetching={isFetching} sortBy={this.sortBy} />
          </Section>
          <Section
            className={cx(styles.section, styles.pagination, { [styles.hiddenSection]: isEmpty })}
          >
            <Pagination
              {...pagination}
              request={this.fetchDateReports}
              isFetching={isFetching}
              resource={Resources.DATEV}
            />
          </Section>
        </CardView>
      </div>
    );
  }
}

Datev.propTypes = {
  isFetching: bool.isRequired,
  data: arrayOf(shape({})).isRequired,
  pagination: shape({}).isRequired,
  sorting: shape({}).isRequired,
  push: func.isRequired,
  dates: shape({
    exportEndDateError: bool.isRequired,
    exportStartAndEndDateError: bool.isRequired,
  }).isRequired,
  setReportDatePeriod: func.isRequired,
  removeEndDateError: func.isRequired,
  removeStartAndEndDateError: func.isRequired,
  getIndex: func.isRequired,
  sortAndRefresh: func.isRequired,
  fetchCompany: func.isRequired,
};

const mapStateToProps = (state) => state.datev;

const mapDispatchToProps = (dispatch) => ({
  setReportDatePeriod: (startDate, endDate) =>
    dispatch(datevActions.setReportDatePeriod(startDate, endDate)),
  removeEndDateError: (...args) => dispatch(datevActions.removeEndDateError(...args)),
  removeStartAndEndDateError: (...args) =>
    dispatch(datevActions.removeStartAndEndDateError(...args)),
  getIndex: (...args) => dispatch(datevActions.getIndex(...args)),
  sortAndRefresh: (...args) => dispatch(datevActions.sortAndRefresh(...args)),
  fetchCompany: (...args) => dispatch(companyActions.fetchCompany(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Datev);
