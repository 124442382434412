import React, { Component } from 'react';
import { fill, get, mapValues } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { transformChartEntries } from 'containers/Dashboard/Overview/Chart';
import withViewport from 'shared/hoc/withViewport';
import colors from 'shared/styles/charts.module.css';
import { formatMoney } from 'shared/utils';
import getMonth from 'shared/utils/charts';
import I18n from 'components/I18n';

import styles from './Chart.module.css';

const CustomLegend = () => (
  <div className={styles.legend}>
    <div className={styles.legendCol}>
      <div className={styles.expensesUnpaid}>
        <I18n t="dashboard.revenue_cost.expenses_unpaid" />
      </div>
      <div className={styles.expensesPaid}>
        <I18n t="dashboard.revenue_cost.expenses_paid" />
      </div>
    </div>
  </div>
);

export class RawChart extends Component {
  static propTypes = {
    data: PropTypes.shape({}),
    isSmUp: PropTypes.bool,
    isXlgUp: PropTypes.bool,
  };

  static defaultProps = {
    isSmUp: true,
    isXlgUp: true,
  };

  renderTooltip = ({ payload = [{}] }) => {
    const data = get(payload && payload[0], 'payload');
    if (!data) {
      return null;
    }

    return (
      <div className={styles.label}>
        <div className={styles.sum}>{formatMoney(data.expensessum)}</div>
        <div className={styles.expensesUnpaid}>{formatMoney(data.expensesunpaid)}</div>
        <div className={styles.expensesPaid}>{formatMoney(data.expensespaid)}</div>
      </div>
    );
  };
  render() {
    const { data, isXlgUp, isSmUp } = this.props;
    const transformedData = transformChartEntries(data);
    const lastItem = transformedData.slice(-1).pop();
    const missingMonths = fill(Array(12 - transformedData.length), {}).map((_v, i) => ({
      ...mapValues(lastItem, () => null),
      month: getMonth(isXlgUp)[moment(get(lastItem, 'id'), 'YYYY-MM').month() + 1 + i],
    }));

    return (
      <div className={styles.main}>
        <CustomLegend />
        <div className={styles.chart}>
          <ResponsiveContainer debounce={50}>
            <BarChart
              stackOffset="sign"
              data={[...transformedData, ...missingMonths]}
              barSize={isSmUp ? 20 : 10}
              barGap={isSmUp ? 13 : 5}
              margin={{ top: 30, right: 20, left: 20, bottom: 5 }}
            >
              <XAxis
                dataKey="month"
                stroke="rgb(155, 155, 155)"
                axisLine={false}
                tickLine={false}
              />
              <YAxis tickLine={false} axisLine={false} stroke="rgb(155, 155, 155)" />
              <CartesianGrid vertical={false} strokeWidth={1} />
              <Tooltip cursor={false} animationDuration={300} content={this.renderTooltip} />
              <Bar
                dataKey="expensespaid"
                stackId="exp"
                isAnimationActive={false}
                fill={colors['expenses-paid-color']}
              />
              <Bar
                dataKey="expensesunpaid"
                stackId="exp"
                isAnimationActive={false}
                fill={colors['expenses-unpaid-color']}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default withViewport(RawChart);
