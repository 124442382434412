import { put, takeEvery } from 'redux-saga/effects';

import { fetchCustomerMessages } from 'actions/message-inbox';
import { HIDE_SIDEBAR } from 'constants/notifications-sidebar';

function* fetchMessages() {
  yield put(fetchCustomerMessages());
}

export default function* watchNotificationsSidebar() {
  yield takeEvery(HIDE_SIDEBAR, fetchMessages);
}
