import React, { useEffect, useRef, useState } from 'react';

import { checkIsTruncated, formatMoney, t } from 'shared/utils';
import { ResourceType } from 'types/entities/AssignableResource';
import { IncomingInvoice, OutgoingInvoice } from 'types/entities/Invoice';
import IncomingInvoiceAmount from 'components/Table/IncomingInvoiceAmount';

import {
  Amount,
  Box,
  ContractSubject,
  ContractTooltip,
  Date,
  Discount,
  DiscountWrapper,
  Icon,
  IconInvoice,
  Party,
  Type,
} from './BankTransferDetails.styled';

type InvoiceAssignmentType = {
  resource: {
    persistedSupplier: IncomingInvoice;
    persistedClient: Pick<OutgoingInvoice, 'persistedClient'>;
    contractSubject: string;
  } & IncomingInvoice &
    OutgoingInvoice;
  resourceType: string;
};

const InvoiceAssignment = ({
  resource: {
    creditNote,
    persistedSupplier,
    persistedClient,
    totalGrossAmount: amount,
    number,
    contractSubject,
    creationDate,
    grossAmountToBePaid,
    discountGrant,
    discountPercentage,
    discountPeriod,
    discountGrossValue,
    invoiceDate,
  },
  resourceType,
}: InvoiceAssignmentType) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const isIncomingInvoice = resourceType === ResourceType.IncomingInvoice;
  const supplierOrClient = persistedSupplier || persistedClient;
  const isInvoicePartlyPaid = grossAmountToBePaid !== amount;
  const partlyPaidAmount = isInvoicePartlyPaid && amount - grossAmountToBePaid;
  const contractNumberRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const contractNumber = contractNumberRef.current;
    if (contractNumber === null) return;

    setIsTruncated(checkIsTruncated(contractNumber));
  }, [contractNumberRef.current]);

  return (
    <Box>
      <Icon>
        <IconInvoice width={34} />
        <p>{t('bank_transfers.assigment.modal.details.invoice')}</p>
      </Icon>
      <Party>{supplierOrClient?.name || supplierOrClient?.companyName || ''}</Party>
      <DiscountWrapper>
        <ContractTooltip offset={0.1} message={number} isTooltipHidden={!isTruncated}>
          <ContractSubject ref={contractNumberRef}>{contractSubject || number}</ContractSubject>
        </ContractTooltip>
        <Discount>
          {discountGrant &&
            t('bank_transfers.assigment.modal.discount', {
              discountPeriod,
              discountPercentage,
              discountAmount: formatMoney(discountGrossValue),
            })}
          {discountGrant && partlyPaidAmount && <span> • </span>}
          {partlyPaidAmount && (
            <span data-id="assignment-box-invoice-assigned-amount">
              {`${t('bank_transfers.invoice_assignment.already_paid')} ${formatMoney(
                partlyPaidAmount
              )}`}
            </span>
          )}
        </Discount>
      </DiscountWrapper>
      <Date>{invoiceDate || creationDate}</Date>
      <Type>{t('bank_transfers.assigment.modal.details.open_amount')}</Type>
      {isIncomingInvoice ? (
        <IncomingInvoiceAmount
          dataId="assignment-box-bank-transfer-sum"
          bold
          amount={amount}
          creditNote={creditNote}
          inAssignment
        />
      ) : (
        <Amount isNegative={amount < 0} data-id="assignment-box-bank-transfer-sum">
          {formatMoney(amount)}
        </Amount>
      )}
    </Box>
  );
};

export default InvoiceAssignment;
