import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { bool, func, string } from 'prop-types';

import {
  getReportDates as getReportDatesAC,
  setEndDateError as setEndDateErrorAC,
  setReportDatePeriod as setReportDatePeriodAC,
  setStartAndEndDateError as setStartAndEndDateErrorAC,
} from 'actions/datev';
import { DATE_FORMAT } from 'constants/datetime';
import { DATEV, DE_DATEV_NEW_EXPORT } from 'constants/datev';
import paths from 'routes/paths';
import { canCreateDatevSelector } from 'selectors/datev';
import { t, toMoment } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import I18n from 'components/I18n';

import styles from './CreateAction.module.css';

class CreateAction extends Component {
  componentDidMount() {
    const { getReportDates } = this.props;
    getReportDates();
  }

  handleClick = (e) => {
    const {
      nextDueDateExport = false,
      lastReportEndDate,
      nextReportStartDate,
      nextReportEndDate,
      setReportDatePeriod,
      setEndDateError,
      setStartAndEndDateError,
    } = this.props;

    const isNextReportEndDateInFuture = moment(toMoment(nextReportEndDate)).isAfter(moment());
    const isNextReportStartDateInFuture = moment(toMoment(nextReportStartDate)).isAfter(moment());

    piwikHelpers.trackEvent(DATEV, DE_DATEV_NEW_EXPORT);

    if (nextDueDateExport) {
      if (isNextReportStartDateInFuture && isNextReportEndDateInFuture) {
        e.preventDefault();
        return setStartAndEndDateError();
      }
      if (isNextReportEndDateInFuture) {
        e.preventDefault();
        return setEndDateError();
      }

      return setReportDatePeriod(
        toMoment(nextReportStartDate).format(DATE_FORMAT),
        toMoment(nextReportEndDate).format(DATE_FORMAT)
      );
    }

    const countedLastReportEndDate = lastReportEndDate
      ? toMoment(lastReportEndDate)
      : moment().startOf('month').subtract(1, 'days');

    const countedNextReportStartDate = countedLastReportEndDate.isSameOrAfter(moment(), 'days')
      ? moment()
      : countedLastReportEndDate.clone().add(1, 'days');

    return setReportDatePeriod(
      countedNextReportStartDate.format(DATE_FORMAT),
      moment().format(DATE_FORMAT)
    );
  };

  render() {
    const { canCreate, customText = '', dataId = '' } = this.props;

    if (!canCreate) return null;

    return (
      <Link
        data-id={dataId}
        caption={t(customText || 'datev.table.add_new')}
        to={paths.datevNew}
        className={styles.newDatev}
        onClick={this.handleClick}
      >
        <I18n t={customText || 'datev.table.add_new'} />
      </Link>
    );
  }
}

CreateAction.propTypes = {
  dataId: string,
  canCreate: bool.isRequired,
  customText: string,
  getReportDates: func,
  lastReportEndDate: string,
  nextReportStartDate: string,
  nextReportEndDate: string,
  nextReportDueDate: string,
  nextDueDateExport: bool,
  setReportDatePeriod: func,
  setEndDateError: func,
  setStartAndEndDateError: func,
};

const mapStateToProps = (state) => ({
  canCreate: canCreateDatevSelector(state),
  lastReportEndDate: state.datev.dates.lastReportEndDate,
  nextReportStartDate: state.datev.dates.nextReportStartDate,
  nextReportEndDate: state.datev.dates.nextReportEndDate,
  nextReportDueDate: state.datev.dates.nextReportDueDate,
});

const mapDispatchToProps = (dispatch) => ({
  getReportDates: () => dispatch(getReportDatesAC()),
  setReportDatePeriod: (startDate, endDate) => dispatch(setReportDatePeriodAC(startDate, endDate)),
  setEndDateError: () => dispatch(setEndDateErrorAC()),
  setStartAndEndDateError: () => dispatch(setStartAndEndDateErrorAC()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAction);
