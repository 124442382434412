import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Counter.module.css';

const Counter = (props) => {
  const { value, max } = props;

  return (
    <div
      className={cx(styles.counter, {
        [styles.warning]: value > max,
      })}
    >
      {value}/{max}
    </div>
  );
};

Counter.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default Counter;
