import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { debounce, flow, isEmpty, isFunction } from 'lodash';
import { arrayOf, bool, func, shape } from 'prop-types';
import { change, reduxForm } from 'redux-form';

import { indexBanks } from 'actions/banks';
import { getBanks } from 'reducers/banks';
import { noop, t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import AdditionalInfo from 'components/AdditionalInfo/AdditionalInfo';
import If from 'components/Conditions/If';
import TextField from 'components/Form/TextField';
import LoadingIcon from 'components/LoadingIcon';

import BankOption from '../BankOption/BankOption';

import pageStyles from '../Page.module.css';
import styles from './Page1.module.css';

const validate = (values) => ({
  bankCode: isEmpty(values.bankCode),
});

const BankOptionNotFound = () => (
  <div className={cx(styles.bankOption, styles.notFound)} data-id="BankAccount:notFound">
    {t('bank_accounts.creator.no_banks_found')}
  </div>
);

const BankOptionLoading = () => (
  <div className={cx(styles.bankOption, styles.loading)}>
    <LoadingIcon />
  </div>
);

const SearchPhraseNotFound = () => (
  <div className={cx(styles.bankOption, styles.notFound)}>
    {t('bank_accounts.creator.no_search_phrase')}
  </div>
);

class Page1 extends Component {
  static defaultProps = {
    setLoading: noop,
  };

  state = {
    searchPhrase: '',
    fetchingBanks: false,
    selectedBankCode: '',
    banks: this.props.banks,
    pagination: { page: 1, perPage: 50 },
  };

  static getDerivedStateFromProps(props, state) {
    if (props.banks !== state.banks) {
      return {
        banks: props.banks,
      };
    }
    return null;
  }
  componentDidUpdate(_, prevState) {
    if (this.state.fetchingBanks !== prevState.fetchingBanks) {
      this.props.setLoading(this.state.fetchingBanks);
    }
  }

  updateBanks = debounce((filter) => {
    if (filter !== this.state.searchPhrase) return;

    this.props
      .indexBanks(this.state.pagination, { filter: { name: filter } })
      .then(() => this.setState({ fetchingBanks: false }));
  }, 500);

  handleBankSelection = (selectedBankCode) => () => {
    this.setState(
      {
        selectedBankCode,
      },
      this.props.change.bind(this, 'bankCode', selectedBankCode)
    );
  };

  handleBankSearch = (event) => {
    const searchPhrase = event.target.value;

    this.setState(
      {
        searchPhrase,
        fetchingBanks: true,
      },
      this.updateBanks.bind(this, searchPhrase)
    );
  };

  renderBankOption = ({ code, icon, name, bic }) => (
    <BankOption
      key={code}
      code={code}
      icon={icon}
      name={name}
      bic={bic}
      onClick={this.handleBankSelection(code)}
      selected={this.state.selectedBankCode}
    />
  );

  renderBankSection = () => {
    switch (true) {
      case isEmpty(this.state.searchPhrase):
        return <SearchPhraseNotFound />;

      case this.state.fetchingBanks:
        return <BankOptionLoading />;

      case isEmpty(this.state.banks):
        return <BankOptionNotFound />;

      default:
        return this.state.banks.map(this.renderBankOption);
    }
  };

  render() {
    const { onCancel, hasExternalSteps, handleSubmit } = this.props;
    const { banks, searchPhrase, fetchingBanks } = this.state;
    const hasResults = !isEmpty(banks) && !isEmpty(searchPhrase) && !fetchingBanks;

    return (
      <form className={pageStyles.page} onSubmit={handleSubmit} id="bank-account-creator-page-1">
        <div className={pageStyles.body}>
          <div>{t('bank_accounts.creator.please_choose_bank')}</div>

          <If ok={!hasExternalSteps}>
            <AdditionalInfo text={t('bank_accounts.creator.please_choose_bank_info')} />
          </If>

          <div className={styles.bankSelect}>
            <div className={styles.search}>
              <TextField
                data-id="BankAccount:searchBankField"
                name="bankName"
                className={styles.input}
                placeholder={t('bank_accounts.creator.placeholders.bank_name')}
                onChange={this.handleBankSearch}
                value={this.state.searchPhrase}
              />
              <span className={styles.icon} />
            </div>

            <div
              className={cx(styles.items, { [styles.itemsFound]: hasResults })}
              id="bank-account-creator-bank-results"
              data-id="BankAccount:query-results"
            >
              {this.renderBankSection()}
            </div>
          </div>
        </div>

        <div className={pageStyles.footer}>
          <If ok={isFunction(onCancel)}>
            <ActionButton
              appearance="outlined"
              onClick={onCancel}
              label={t('bank_accounts.creator.prev_step')}
              className={pageStyles.stepButton}
              type="button"
            />
          </If>
          <ActionButton
            type="submit"
            invalid={!this.state.selectedBankCode}
            disabled={!this.state.selectedBankCode}
            className={pageStyles.stepButton}
            label={t('bank_accounts.creator.next_step')}
            data-id="BankAccount:creatorFirstStepFinish"
          />
        </div>
      </form>
    );
  }
}

Page1.propTypes = {
  change: func.isRequired,
  indexBanks: func.isRequired,
  handleSubmit: func.isRequired,
  banks: arrayOf(shape({})).isRequired,
  setLoading: func,
  onCancel: func,
  hasExternalSteps: bool.isRequired,
};

export { Page1 as Page1Component };

export default flow(
  reduxForm({
    form: 'bankAccountCreator',
    destroyOnUnmount: false,
    validate,
  }),
  connect(
    (state) => ({
      banks: getBanks(state),
    }),
    (dispatch) => ({
      indexBanks: (...args) => dispatch(indexBanks(...args)),
      updateForm: (...args) => dispatch(change('bankAccountCreator', ...args)),
    })
  )
)(Page1);
