import React, { memo, useCallback, useEffect, useState } from 'react';
import { formatISO } from 'date-fns';

import { fetchSummary } from 'actions/summary';
import {
  CATEGORY_DASHBOARD,
  DASHBOARD_KPI_TILE_CHOSE_YEAR,
  DASHBOARD_KPI_TILE_OPEN_DATEPICKER,
} from 'constants/piwik';
import { useAppDispatch, useAppSelector } from 'shared/hooks/app';
import { formatMoney, t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import Loading from 'components/Loading';
import TileTitle from 'redesign/components/atoms/TileTitle/TileTitle';
import YearPickerComponent from 'redesign/components/molecules/YearPicker/YearPicker';

import { Amount, Box, Column, Header, Number, Subtitle, YearPicker } from './KPITile.styled';

type KPITileProps = {
  className?: string;
};

const KPITile = ({ className }: KPITileProps) => {
  const currentYear = new Date().getFullYear();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.summary.isFetching);
  const data = useAppSelector((state) => state.summary.data);
  const surplus = data.revenue - data.expenses;
  const monthlySurplus = data.monthlyRevenues - data.monthlyExpenses;
  const hasLargeNumber = [data.revenue, data.expenses, surplus].some(
    (value: number | undefined) => value && (value >= 1000000 || (value < 0 && value <= -1000000))
  );
  const [year, setYear] = useState(currentYear);
  const monthTranslation = t(
    `features.homepage.kpi_tile.${currentYear === year ? 'in_this_month' : 'in_month'}`
  );

  const handleYearChange = useCallback((year: number) => {
    setYear(year);
    piwikHelpers.trackEvent(CATEGORY_DASHBOARD, DASHBOARD_KPI_TILE_CHOSE_YEAR);
  }, []);

  const handleYearPickerOpen = useCallback(() => {
    piwikHelpers.trackEvent(CATEGORY_DASHBOARD, DASHBOARD_KPI_TILE_OPEN_DATEPICKER);
  }, []);

  useEffect(() => {
    const startDate = `${year}-01-01`;
    const endDate =
      year === currentYear ? formatISO(new Date(), { representation: 'date' }) : `${year}-12-31`;

    dispatch(fetchSummary({ startDate, endDate }));
  }, [dispatch, year, currentYear]);

  return (
    <section data-id="kpi-tile" className={className}>
      <Header>
        <TileTitle dataId="title">{t('features.homepage.kpi_tile.title')}</TileTitle>
        <YearPicker
          as={YearPickerComponent}
          value={year}
          onChange={handleYearChange}
          onOpen={handleYearPickerOpen}
        />
      </Header>
      <Box>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Column data-id="revenue">
              <Subtitle data-id="title">{t('features.homepage.kpi_tile.revenue_title')}</Subtitle>
              <Amount data-id="amount" $isSmall={hasLargeNumber}>
                {formatMoney(data.revenue)}
              </Amount>
              <div>
                <Number data-id="month">+{formatMoney(data.monthlyRevenues)}</Number>{' '}
                {monthTranslation}
              </div>
            </Column>
            <Column data-id="expenses">
              <Subtitle data-id="title">{t('features.homepage.kpi_tile.expenses_title')}</Subtitle>
              <Amount data-id="amount" $isSmall={hasLargeNumber}>
                {data.expenses !== 0 && '-'}
                {formatMoney(data.expenses)}
              </Amount>
              <div>
                <Number $isNegative data-id="month">
                  -{formatMoney(data.monthlyExpenses)}
                </Number>{' '}
                {monthTranslation}
              </div>
            </Column>
            <Column data-id="surplus">
              <Subtitle data-id="title">{t('features.homepage.kpi_tile.surplus_title')}</Subtitle>
              <Amount data-id="amount" $isSmall={hasLargeNumber}>
                {formatMoney(surplus)}
              </Amount>
              <div>
                <Number $isNegative={monthlySurplus < 0} data-id="month">
                  {monthlySurplus >= 0 && '+'}
                  {formatMoney(monthlySurplus)}
                </Number>{' '}
                {monthTranslation}
              </div>
            </Column>
          </>
        )}
      </Box>
    </section>
  );
};

export default memo(KPITile);
