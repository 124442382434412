import React, { memo, MouseEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import type { WrappedFieldArrayProps } from 'redux-form';
import { Field, FieldArray } from 'redux-form';

import BankAccountsCreatorModal from 'containers/Profile/BankAccounts/BankAccountsCreatorModal/BankAccountsCreatorModal';
import { t } from 'shared/utils';
import Amount from 'components/Amount';
import Checkbox from 'components/Form/Checkbox';
import IconRefresh from 'components/Icons/IconRefresh';
import Loading from 'components/Loading';
import SyncBankAccountButton from 'components/SyncBankAccountButton';

import * as formSelectors from '../formSelectors';
import { updateFormBankAccounts } from '../liquidityCalculatorSlice';
import Section from '../Section/Section';
import { trackEvent } from '../utils';
import { TIMEOUT_AFTER_TRANSATIONS_SYNC } from './constants';
import type { BankAccount } from './types';

import styles from './BankAccounts.module.css';

type BankAccountsListProps = {
  setShowAddModal: (show: boolean) => void;
  setIsLoading: (show: boolean) => void;
  isModalVisible: boolean;
};

const BankAccountsList = ({
  fields,
  isModalVisible,
  setShowAddModal,
  setIsLoading,
}: WrappedFieldArrayProps<BankAccount> & BankAccountsListProps) => {
  const dispatch = useDispatch();
  const totalBalance = useSelector(formSelectors.getBankAccountsTotalBalance);

  // This is called at the end of the figo auth flow
  const handleUpdateTransactions = useCallback(() => {
    setIsLoading(true);
    setTimeout(async () => {
      await dispatch(updateFormBankAccounts());
      setIsLoading(false);
    }, TIMEOUT_AFTER_TRANSATIONS_SYNC);
  }, [dispatch, setIsLoading]);

  // This is used when user is using save pin option.
  const handleSyncInProgress = useCallback(
    (value: boolean) => {
      setIsLoading(value);
    },
    [setIsLoading]
  );

  const handleShowModal = () => {
    setShowAddModal(true);
  };

  return (
    <div className={cx(styles.main, { [styles.empty]: !fields.length })}>
      {fields.length ? (
        <>
          {fields.map((name, index, fields) => {
            const account = fields.get(index);

            return (
              <div key={account.id} className={styles.account}>
                <div className={cx(styles.column, styles.name)}>
                  <Field
                    name={`${name}.isChecked`}
                    component={Checkbox}
                    type="checkbox"
                    dataId="LiquidityCalculatorAccounts:checkbox"
                    disabled={account.isDisabled}
                  >
                    {account.name}
                  </Field>
                </div>
                <div className={cx(styles.column, styles.balance)}>
                  {!account.isDisabled ? (
                    <Amount
                      amount={account.balance}
                      dataId="LiquidityCalculatorAccounts:accountBalance"
                    />
                  ) : (
                    <SyncBankAccountButton
                      bankAccount={account}
                      postLoginAction={handleUpdateTransactions}
                      setSyncInProgress={handleSyncInProgress}
                      customButton={({ onClick }: { onClick: () => void }) => (
                        <button
                          className={styles.update}
                          onClick={onClick}
                          data-id="LiquidityCalculatorAccounts:button-update"
                        >
                          {t(
                            'features.financial_planning.liquidity_calculator.bank_accounts.update_bank_account'
                          )}
                        </button>
                      )}
                    />
                  )}
                </div>
                <div className={cx(styles.column, styles.actions)}>
                  {!account.isDisabled && (
                    <SyncBankAccountButton
                      bankAccount={account}
                      postLoginAction={handleUpdateTransactions}
                      setSyncInProgress={handleSyncInProgress}
                      customButton={({ onClick }: { onClick: () => void }) => (
                        <button
                          className={styles.refresh}
                          onClick={onClick}
                          data-id="LiquidityCalculatorAccounts:button-refresh"
                        >
                          <IconRefresh />
                        </button>
                      )}
                    />
                  )}
                </div>
              </div>
            );
          })}
          <button
            className={styles.add}
            onClick={handleShowModal}
            disabled={isModalVisible}
            data-id="LiquidityCalculatorAccounts:button-addMoreAccounts"
          >
            + {t('features.financial_planning.liquidity_calculator.bank_accounts.add_bank_account')}
          </button>
          <div className={styles.summary}>
            {t('features.financial_planning.liquidity_calculator.bank_accounts.total_balance')}
            <span>
              <Amount amount={totalBalance} dataId="LiquidityCalculatorAccounts:totalBalance" />
            </span>
          </div>
        </>
      ) : (
        <button
          className={styles.addFirstBankAccount}
          onClick={handleShowModal}
          disabled={isModalVisible}
          data-id="LiquidityCalculatorAccounts:button-addFirstAccount"
        >
          {t('features.financial_planning.liquidity_calculator.bank_accounts.add_bank_account')}
        </button>
      )}
    </div>
  );
};

const BankAccounts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const dispatch = useDispatch();

  const handleCloseModal = useCallback(
    async (event: MouseEvent) => {
      setShowAddModal(false);

      // It's mean that it was invoked as a callback when user have added bank account
      if (!event) {
        setIsLoading(true);
        await dispatch(updateFormBankAccounts());
        setIsLoading(false);
        trackEvent('Add_Bank');
      }
    },
    [dispatch]
  );

  return (
    <>
      <Section
        title={t('features.financial_planning.liquidity_calculator.bank_accounts.title')}
        eventName="Expand_Step1"
        defaultOpen
      >
        <div className={styles.content}>
          <div className={styles.sidebar}>
            <h3 className={styles.title}>
              {t('features.financial_planning.liquidity_calculator.bank_accounts.subtitle')}
            </h3>
          </div>
          <FieldArray<BankAccountsListProps, BankAccount>
            name="bankAccounts"
            component={BankAccountsList}
            setShowAddModal={setShowAddModal}
            isModalVisible={showAddModal}
            setIsLoading={setIsLoading}
          />
        </div>
        {isLoading && <Loading />}
      </Section>
      <BankAccountsCreatorModal
        isActive={showAddModal}
        onClose={handleCloseModal}
        hasExternalSteps
      />
    </>
  );
};

export default memo(BankAccounts);
