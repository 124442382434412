import * as React from 'react';
import { SVGAttributes } from 'react';

const IconExclamation = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 34 109" {...props}>
    <path
      d="M1 1h32l-4.857 70.523H5.757L1 1zm4.783 85.114L5.735 108h23.404V86.114H5.783z"
      stroke="#C5002D"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconExclamation;
