import React, { Component } from 'react';
import cx from 'classnames';
import { identity, isEmpty, isNumber } from 'lodash';
import { arrayOf, bool, elementType, func, node, number, shape, string } from 'prop-types';

import { noop } from 'shared/utils';
import { addPassiveListener, removePassiveListener } from 'shared/utils/tab-visibility';

import Tab from './Tab';

import styles from './Tabs.module.css';

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: this.props.initialTab,
    };
  }

  componentWillUnmount() {
    if (this.container) {
      removePassiveListener(this.container, 'scroll', this.handleScroll);
      removePassiveListener(this.container, 'resize', this.handleScroll);
    }
  }

  onContainerMounted = (container) => {
    if (!container || this.container) return;
    this.container = container;
    addPassiveListener(this.container, 'scroll', this.handleScroll);
    addPassiveListener(this.container, 'resize', this.handleScroll);
    this.handleScroll(this.state.activeTabIndex);
  };

  switchTab = (activeTabIndex, heading = undefined, triggerEvent = true) => {
    if (activeTabIndex === this.state.activeTabIndex) return;

    const { trackSwitchTab, piwikTrackingEnabled } = this.props;

    this.setState({ activeTabIndex }, () => {
      if (!piwikTrackingEnabled) return;
      trackSwitchTab(heading);
    });
    triggerEvent && this.props.switchTabAddon(activeTabIndex);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.initialTab === this.props.initialTab) return;

    const section = this.props.sections[this.props.initialTab];

    this.switchTab(this.props.initialTab, section.heading, false);
  }

  handleScroll = (index) => {
    if (!this.container) return;

    if (!index) {
      this.container.scrollLeft = 0;
    } else if (isNumber(index)) {
      const tab = this.container.children[index];
      const { left } = tab.getBoundingClientRect();
      const { left: containerLeft } = this.container.getBoundingClientRect();
      this.container.scrollLeft += left - containerLeft;
    }
  };

  render() {
    const { activeTabIndex } = this.state;
    const { dataId, sections, tabsAddon: TabsAddon, emptyComponent: Empty, ...rest } = this.props;

    if (isEmpty(sections) && !Empty) return null;

    if (isEmpty(sections)) {
      return (
        <div className={cx(styles.main, this.props.theme.main)}>
          <div className={cx(styles.content, this.props.theme.content)}>
            <Empty />
          </div>
        </div>
      );
    }

    const {
      component: Content,
      heading: currentHeading,
      guard = identity,
      id: currentId,
      ...additionalProps
    } = sections[activeTabIndex];
    const ProtectedContent = guard(Content);

    return (
      <div className={cx(styles.main, this.props.theme.main)}>
        <div className={cx(styles.tabs, this.props.theme.tabs)} data-id={dataId}>
          <div
            className={cx(styles.primary, this.props.theme.primary)}
            ref={this.onContainerMounted}
          >
            {sections.map(
              ({ heading, guard: protect = identity, id, dataId: sectionDataId }, index) => {
                const ProtectedTab = protect(Tab);

                return (
                  <ProtectedTab
                    key={id || heading}
                    heading={heading}
                    theme={this.props.theme}
                    onClick={() => this.switchTab(index, heading)}
                    active={index === activeTabIndex}
                    dataId={sectionDataId}
                  />
                );
              }
            )}
          </div>
          {TabsAddon && <div className={cx(styles.addon, this.props.theme.addon)}>{TabsAddon}</div>}
        </div>
        <div className={cx(styles.content, this.props.theme.content)}>
          <ProtectedContent key={currentId || currentHeading} {...rest} {...additionalProps} />
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  dataId: string,
  theme: shape({
    main: string,
    tabs: string,
    addon: string,
    content: string,
    primary: string,
  }),
  initialTab: number,
  tabsAddon: node,
  emptyComponent: func,
  sections: arrayOf(
    shape({
      component: elementType.isRequired,
      guard: func,
      heading: string.isRequired,
    })
  ),
  switchTabAddon: func,
  trackSwitchTab: func,
  piwikTrackingEnabled: bool,
};

Tabs.defaultProps = {
  theme: {},
  initialTab: 0,
  switchTabAddon: noop,
  trackSwitchTab: noop,
};

export default Tabs;
