import React, { Component } from 'react';
import cx from 'classnames';
import { find, head } from 'lodash';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';

import { OPTION_DISCOUNT, OPTION_FULLY_PAID } from 'constants/assigment-select';
import {
  formatAmountWithHistory,
  formatMoney,
  formatMoneyInput,
  normalizeCurrency,
  parseCurrency,
  t,
} from 'shared/utils';
import DownshiftSelect from 'components/Form/SelectField/DownshiftSelect';
import TextField from 'components/Form/TextField';

import localStyles from './AssigmentSelect.module.css';

class AssigmentSelect extends Component {
  state = {
    parsedValue: '',
    normalizedValue: '',
    selectedValue: '',
    id: '',
  };

  // Update state when selectedValue or id changes
  static getDerivedStateFromProps({ selectedValue, inputValue, id }, prevState) {
    const { selectedValue: prevSelectedValue, id: prevId } = prevState;

    if (!id && selectedValue === prevSelectedValue) return null;

    if (id && selectedValue === prevSelectedValue && id === prevId) return null;

    const parsedValue = parseCurrency(inputValue);
    const normalizedValue = normalizeCurrency(parsedValue);
    return {
      parsedValue: formatMoneyInput(inputValue),
      normalizedValue,
      selectedValue,
      id,
    };
  }

  onInputChange = ({ target: { value } }) => {
    const parsedValue = parseCurrency(value);
    const normalizedValue = normalizeCurrency(parsedValue);

    this.setState({ parsedValue, normalizedValue });
    this.props.onInputChange({ target: { value: normalizedValue } });
  };

  render() {
    const {
      bankTransferAmount,
      customWrapperClass,
      customInputClass,
      initiallySelected = {},
      inputLabel,
      inputPlaceholder,
      inputVisible = false,
      onSelect,
      inputInvalid,
      selectOptions,
      discountGrossValue,
      containerClassName = {},
      creditNote = false,
    } = this.props;

    const { parsedValue, normalizedValue } = this.state;
    const OPTIONS = selectOptions
      .map((option) =>
        option.value === OPTION_DISCOUNT.value
          ? { ...option, label: `${t(option.translateKey)} (${formatMoney(discountGrossValue)})` }
          : { ...option, label: t(option.translateKey) }
      )
      .filter((option) => {
        if (creditNote && bankTransferAmount < 0) return option.value === OPTION_FULLY_PAID.value;

        return true;
      });
    const initialSelectedItem = find(OPTIONS, (option) => option.value === initiallySelected.value);

    return (
      <div className={cx(localStyles.wrapper, customWrapperClass)}>
        <div className={localStyles.selectWrapper}>
          <DownshiftSelect
            dataIds={{
              container: 'BankTransferAssignment:assignment-box-type-dropdown',
            }}
            defaultIcon
            options={OPTIONS}
            label={t('bank_transfers.assign_amount')}
            initialSelectedItem={initialSelectedItem || head(OPTIONS)}
            onSelect={onSelect}
            inputClassName={localStyles.input}
            labelClassName={localStyles.label}
            containerClassName={cx(localStyles.container, containerClassName)}
            inputDisabled
            hideMenuScroll
            isReinitializedOnInitialChange
          />
        </div>
        {inputVisible && (
          <TextField
            dataId="BankTransferAssignment:input-assignment-box-amount"
            invalid={inputInvalid}
            type="number"
            step="0.01"
            name="option"
            onChange={this.onInputChange}
            value={formatAmountWithHistory(parsedValue)(normalizedValue)}
            className={cx(localStyles.inputWrapper, customInputClass)}
            label={inputLabel}
            placeholderClassName={localStyles.placeholder}
            labelClassName={localStyles.inputLabel}
            placeholder={inputPlaceholder}
          />
        )}
      </div>
    );
  }
}

AssigmentSelect.propTypes = {
  bankTransferAmount: number,
  inputPlaceholder: string,
  inputLabel: string,
  onInputChange: func.isRequired,
  onSelect: func.isRequired,
  inputInvalid: bool,
  inputVisible: bool,
  customInputClass: oneOfType([string, shape({})]),
  customWrapperClass: oneOfType([string, shape({})]),
  initiallySelected: oneOfType([
    shape({
      label: string.isRequired,
      value: oneOfType([number, string, bool]),
    }),
    shape({}),
  ]),
  selectOptions: arrayOf(shape({})).isRequired,
  discountGrossValue: number,
  containerClassName: oneOfType([shape({}), string]),
  id: string, // eslint-disable-line
  creditNote: bool,
};

export default AssigmentSelect;
