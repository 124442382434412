import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { FROM_CONFIRMATION_QUERY_PARAM } from 'constants/registration';
import Authenticator from 'containers/Authenticator';
import LegalsFooter from 'containers/LegalsFooter';
import NotificationManager from 'containers/NotificationManager';
import PaymentPlanWizard from 'containers/PaymentPlan/PaymentPlanWizard/PaymentPlanWizard';
import config from 'routes/config';
import paths from 'routes/paths';
import PageTemplate from 'redesign/components/templates/Page/Page';

import {
  canAccessEditClient,
  farmpilotEnabled,
  isGuest,
  paymentRemindersEnabled,
  productCatalogGroupsEnabled,
  productCatalogItemsEnabled,
} from './accesses';
import fetchClient from './enhancers/fetchClient';

const ApplicationWrapper = ({ children, authenticated }) => (
  <CookiesProvider>
    {children}
    {authenticated && <PaymentPlanWizard />}
    <NotificationManager />
    {!authenticated && <LegalsFooter authenticated={false} absolutePosition />}
  </CookiesProvider>
);

ApplicationWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  authenticated: PropTypes.bool.isRequired,
};

const ApplicationWrapperConnected = connect((s) => ({
  authenticated: s.auth.isAuthenticated && !s.auth.isFetching,
}))(ApplicationWrapper);

const Routes = () => (
  <ApplicationWrapperConnected>
    <Switch>
      <Route path={paths.login} component={isGuest(config.login)} />
      <Route path={paths.unconfirmedUser(':token')} component={isGuest(config.unconfirmedUser)} />
      <Route path={paths.vrsoLoginBlocked} component={isGuest(config.vrsoLoginBlocked)} />
      <Route
        path={paths.vrsoResetPasswordBlocked}
        component={isGuest(config.vrsoResetPasswordBlocked)}
      />
      <Route
        exact
        path={paths.accountAlreadyConfirmed}
        component={isGuest(config.accountAlreadyConfirmed)}
      />
      <Route
        path={paths.registrationsDeletionTokenInvalid}
        component={config.registrationsDeletionTokenInvalid}
      />
      <Route path={paths.registrationsDeletion} component={config.registrationsDeletion} />
      <Route exact path={paths.register} component={isGuest(config.registration)} />
      <Route exact path={paths.companyInfo} component={isGuest(config.companyInfoRegistration)} />
      <Route exact path={paths.registerVrso} component={isGuest(config.registrationVrso)} />
      <Route exact path={paths.forgotPassword} component={isGuest(config.forgotPassword)} />
      <Route
        exact
        path={paths.companySelectRegistration}
        component={config.companySelectRegistration}
      />
      <Route exact path={paths.requestRegistration} component={config.requestRegistration} />

      <Route
        exact
        path={paths.registrationConfirmed}
        component={isGuest(config.registrationConfirmed, {
          redirectSuffix: `?${FROM_CONFIRMATION_QUERY_PARAM}=1`,
        })}
      />
      <Route
        exact
        path={paths.confirmationLinkOutdated}
        component={isGuest(config.confirmationLinkOutdated)}
      />
      <Route exact path={paths.emailTaken} component={config.emailTaken} />
      <Route exact path={paths.registrationSuccess} component={config.registrationSuccess} />
      <Route exact path={paths.registerError} component={config.registrationError} />
      <Route exact path={paths.resetPassword} component={config.resetPassword} />
      <Route exact path={paths.datevDownload} component={config.datevDownload} />
      <Route exact path={paths.invalidToken} component={config.invalidToken} />
      <Route path={paths.emailNotifications} component={config.emailNotifications} />
      <Route exact path={paths.bankAccountConnected} component={config.bankAccountConnected} />

      <Authenticator>
        <PageTemplate>
          <Switch>
            <Route exact path={paths.home} component={config.home} />

            {/* ///PROFILE/// */}

            <Redirect exact from={paths.profile} to={paths.personalProfile} />

            <Route exact path={paths.personalProfile} component={config.profile} />
            <Route exact path={paths.companyProfile} component={config.profile} />
            <Route exact path={paths.taxConsultantProfile} component={config.profile} />
            <Route exact path={paths.bankAccounts} component={config.profile} />
            <Route exact path={paths.paymentPlan} component={config.profile} />
            <Route exact path={paths.numberRanges} component={config.profile} />
            <Route exact path={paths.farmpilot} component={farmpilotEnabled(config.farmpilot)} />

            {/* ///REVENUE/// */}

            <Redirect exact from={paths.revenue} to={paths.outgoingInvoices} />

            <Route exact path={paths.outgoingInvoices} component={config.revenue} />
            <Route path={paths.financialPlanning} component={config.financialPlanning} />
            <Route exact path={paths.newOutgoingInvoice} component={config.revenueNew} />
            <Route exact path={paths.importOutgoingInvoice} component={config.revenueImport} />
            <Route
              exact
              path={paths.correctOutgoingInvoice(':id')}
              component={config.revenueCorrect}
            />
            <Route exact path={paths.editOutgoingInvoice(':id')} component={config.revenueEdit} />
            <Route exact path={paths.showOutgoingInvoice(':id')} component={config.revenueShow} />
            <Route
              exact
              path={paths.cancelOutgoingInvoice(':id')}
              component={config.revenueCancel}
            />
            <Route
              exact
              path={paths.createPaymentReminder(':id')}
              component={paymentRemindersEnabled(config.reminderNew)}
            />
            <Route exact path={paths.deliveryNotes} component={config.deliveryNotes} />
            <Route exact path={paths.deliveryNotesNew} component={config.deliveryNotesNew} />
            <Route
              exact
              path={paths.editDeliveryNote(':id')}
              component={config.deliveryNotesEdit}
            />
            <Route
              exact
              path={paths.showDeliveryNote(':id')}
              component={config.deliveryNotesShow}
            />

            {/* ///EXPENSES/// */}

            <Redirect exact from={paths.expenses} to={paths.incomingInvoices} />

            <Route exact path={paths.incomingInvoices} component={config.incomingInvoices} />
            <Route exact path={paths.newIncomingInvoice} component={config.incomingInvoicesNew} />
            <Route
              exact
              path={paths.editIncomingInvoice(':id')}
              component={config.incomingInvoicesEdit}
            />
            <Route
              exact
              path={paths.showIncomingInvoice(':id')}
              component={config.incomingInvoicesShow}
            />
            <Route
              exact
              path={paths.cancelIncomingInvoice(':id')}
              component={config.incomingInvoicesCancel}
            />

            <Route path={paths.settings} component={config.settings} />
            <Route path={paths.contracts} component={config.contracts} />
            <Route path={paths.cashbooks} component={config.cashbooks} />
            <Route path={paths.bankTransfers} component={config.bankTransfers} />

            {/* ///PROPOSALS/// */}

            <Route exact path={paths.proposals} component={config.proposals} />
            <Route exact path={paths.newProposal} component={config.proposalsNew} />
            <Route exact path={paths.editProposal(':id')} component={config.proposalsEdit} />
            <Route exact path={paths.showProposal(':id')} component={config.proposalsShow} />

            {/* ///ORDER CONFIRMATION/// */}

            <Route exact path={paths.orderConfirmation} component={config.orderConfirmations} />
            <Route
              exact
              path={paths.newOrderConfirmation}
              component={config.orderConfirmationsNew}
            />
            <Route
              exact
              path={paths.editOrderConfirmation(':id')}
              component={config.orderConfirmationsEdit}
            />
            <Route
              exact
              path={paths.showOrderConfirmation(':id')}
              component={config.orderConfirmationsShow}
            />
            <Route
              exact
              path={paths.cancelOrderConfirmation(':id')}
              component={config.orderConfirmationsCancel}
            />

            {/* ///DATEV/// */}

            <Route exact path={paths.datev} component={config.datev} />
            <Route exact path={paths.datevNew} component={config.datevCreator} />
            <Route exact path={paths.datevEdit} component={config.datevEditor} />

            {/* ///ADMINISTRATION/// */}

            <Redirect exact from={paths.administration} to={paths.clients} />

            <Route exact path={paths.clients} component={config.clients} />
            <Route exact path={paths.clientsNew} component={config.clientsNew} />
            <Route
              path={paths.editClient(':id')}
              component={fetchClient(canAccessEditClient(config.clientsEdit))}
            />
            <Route
              exact
              path={paths.showClientFarmpilot(':id')}
              component={(props) => config.clientsShowFarmpilot(props)}
            />
            <Route exact path={paths.clientsImport} component={config.clientsImport} />

            <Route exact path={paths.suppliers} component={config.suppliers} />
            <Route exact path={paths.suppliersImport} component={config.suppliersImport} />
            <Route exact path={paths.suppliersNew} component={config.suppliersNew} />
            <Route exact path={paths.showSupplier(':id')} component={config.suppliersShow} />
            <Route exact path={paths.editSupplier(':id')} component={config.suppliersEdit} />

            <Redirect exact from={paths.productCatalog} to={paths.productCatalogItems} />
            <Route
              exact
              path={paths.productCatalogItems}
              component={productCatalogItemsEnabled(config.productCatalog)}
            />
            <Route
              exact
              path={paths.editProductCatalogItem(':id')}
              component={productCatalogItemsEnabled(config.productCatalogItemsEdit)}
            />
            <Route
              exact
              path={paths.newProductCatalogItem}
              component={productCatalogItemsEnabled(config.productCatalogItemsNew)}
            />

            <Route
              exact
              path={paths.productCatalogGroups}
              component={productCatalogGroupsEnabled(config.productCatalog)}
            />
            <Route
              exact
              path={paths.newProductCatalogGroup}
              component={productCatalogGroupsEnabled(config.productCatalogGroupsNew)}
            />
            <Route
              exact
              path={paths.editProductCatalogGroup(':id')}
              component={productCatalogGroupsEnabled(config.productCatalogGroupsEdit)}
            />
            <Redirect to={paths.home} />
          </Switch>
        </PageTemplate>
      </Authenticator>
    </Switch>
  </ApplicationWrapperConnected>
);

export default Routes;
