import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { func, node, oneOfType } from 'prop-types';
import { reduxForm } from 'redux-form';

const InvoiceTemplateForm = ({ children }) => <Fragment>{children}</Fragment>;

InvoiceTemplateForm.propTypes = {
  children: oneOfType([func, node]).isRequired,
};

const FormEnhancedComponent = reduxForm({
  form: 'invoiceTemplate',
  enableReinitialize: true,
  persistentSubmitErrors: true,
})(InvoiceTemplateForm);

export default connect((state) => ({
  formData: state.form.invoiceTemplate || {},
  initialValues: state.invoiceTemplates.template,
}))(FormEnhancedComponent);
