import api from 'api';

export type GetProposalData = {
  salutation_honorific: string;
  salutation_content: string;
  personal_notes: string;
};

export const getProposal = (params: GetProposalData) => {
  const url = '/me/previews/proposal';

  return api.get<ArrayBuffer>(url, {
    params,
    responseType: 'arraybuffer',
  });
};
