import { JsonApiResponseSingle } from 'json-api-normalizer';

import api from '../index';

export const getDashboard = () => {
  const url = '/me/settings/widgets_setting';

  return api.get<JsonApiResponseSingle>(url);
};

export type UpdateDashboardData = {
  kpi?: boolean;
  monthly_overview?: boolean;
  liqui_chart?: boolean;
  bank_accounts?: boolean;
  bank_transfers?: boolean;
};

export const updateDashboard = (data: UpdateDashboardData) => {
  const url = '/me/settings/widgets_setting';

  return api.put<JsonApiResponseSingle>(url, data);
};
