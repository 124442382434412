export const PRODUCT_CATALOG_ITEMS = 'productCatalogItems';
export const FORM_NAME = 'productCatalogItemCreator';
export const NAMESPACE = 'PRODUCT_CATALOG_ITEMS';

export const GET_ITEM_NUMBER_REQUEST = 'GET_ITEM_NUMBER_REQUEST';
export const GET_ITEM_NUMBER_SUCCESS = 'GET_ITEM_NUMBER_SUCCESS';
export const GET_ITEM_NUMBER_FAILURE = 'GET_ITEM_NUMBER_FAILURE';

// Piwik
export const CLICK_CREATE_PRODUCT_CATALOG_ITEM = 'CLICK_CREATE_PRODUCT_CATALOG_ITEM';
export const CLICK_EDIT_PRODUCT_CATALOG_ITEM = 'CLICK_EDIT_PRODUCT_CATALOG_ITEM';
export const CLICK_CANCEL_PRODUCT_CATALOG_ITEM = 'CLICK_CANCEL_PRODUCT_CATALOG_ITEM';

export const SUBMIT_NEW_PRODUCT_CATALOG_ITEM_SUCCESS = 'SUBMIT_NEW_PRODUCT_CATALOG_ITEM_SUCCESS';
export const SUBMIT_NEW_PRODUCT_CATALOG_ITEM_FAILURE = 'SUBMIT_NEW_PRODUCT_CATALOG_ITEM_FAILURE';
