import React from 'react';

import { t } from 'shared/utils';

import styles from './NoMessagesInfo.module.css';

const NoMessagesInfo = () => (
  <div className={styles.container}>{t('sidebar.messages.no_messages')}</div>
);

export default NoMessagesInfo;
