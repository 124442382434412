import { createSelector } from 'reselect';

import { RegistrationSource } from 'constants/registration';
import { RootState } from 'store';

export const getRegistrationSource = (state: RootState) =>
  state.profile.credentials.registrationSource;

export const getIsFromVrso = createSelector(
  getRegistrationSource,
  (registrationSource) => registrationSource === RegistrationSource.Vrso
);

export const getEmail = (state: RootState) => state.profile.credentials.email;

export const getFirstName = (state: RootState) => state.profile.credentials.firstName;

export const getLastName = (state: RootState) => state.profile.credentials.lastName;
