import { fetchInvoiceSums } from 'actions/incoming-invoice/sums';
import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_ALL_FAILURE,
  DELETE_ALL_REQUEST,
  DELETE_ALL_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/incoming-invoice/line-items';
import {
  lineItemCreateSuccess,
  lineItemDeleteSuccess,
  lineItemUpdateSuccess,
} from 'notifications/incoming-invoices';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

import { toggleDisableAction } from '.';
import { fetchPayments } from './payments';

const defaultValues = {
  name: '',
  netAmount: '',
  vat: '',
  invoiceLineCategoryId: '',
  validForTax: true,
};

export const extractValues = (values) => ({ ...defaultValues, ...values });

export const apiFetchLineItems = (invoiceId) => ({
  [CALL_API]: {
    endpoint: `/me/incoming_invoices/${invoiceId}/invoice_line_items`,
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiDeleteLineItem = (invoiceId, id) => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: `/me/incoming_invoices/${invoiceId}/invoice_line_items/${id}`,
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const apiDeleteAllLineItems = (invoiceId) => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: `/me/incoming_invoices/${invoiceId}/line_items`,
    types: [DELETE_ALL_REQUEST, DELETE_ALL_SUCCESS, DELETE_ALL_FAILURE],
  },
});

export const apiUpdateLineItem = (invoiceId, id, data) => ({
  [CALL_API]: {
    data,
    method: 'PUT',
    endpoint: `/me/incoming_invoices/${invoiceId}/invoice_line_items/${id}`,
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const apiCreateLineItem = (invoiceId, data) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: `/me/incoming_invoices/${invoiceId}/invoice_line_items`,
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const fetchLineItems =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchLineItems(...args)).catch(apiErrorHandler);

export const deleteLineItem = (invoiceId, id) => (dispatch) => {
  dispatch(toggleDisableAction());

  return bindServerValidation(apiDeleteLineItem(invoiceId, id), dispatch, { isReduxForm: false })
    .then(() => {
      dispatch(showNotification(lineItemDeleteSuccess));
      dispatch(fetchInvoiceSums(invoiceId, true));
    })
    .catch((err) => {
      apiErrorHandler(err);
    })
    .finally(() => {
      dispatch(toggleDisableAction());
    });
};

export const deleteAllLineItems = (invoiceId) => (dispatch) =>
  bindServerValidation(apiDeleteAllLineItems(invoiceId), dispatch, { isReduxForm: false })
    .then(() => dispatch(fetchInvoiceSums(invoiceId, true)))
    .catch((err) => {
      apiErrorHandler(err);
    });

export const updateLineItem = (invoiceId, id, data) => (dispatch) => {
  dispatch(toggleDisableAction());

  return bindServerValidation(apiUpdateLineItem(invoiceId, id, data), dispatch, {
    isReduxForm: false,
  })
    .then(() => {
      dispatch(showNotification(lineItemUpdateSuccess));
      dispatch(fetchInvoiceSums(invoiceId));
      dispatch(fetchPayments(invoiceId));
    })
    .catch((err) => {
      apiErrorHandler(err);
      throw err;
    })
    .finally(() => {
      dispatch(toggleDisableAction());
    });
};

export const createLineItem = (invoiceId, data) => (dispatch) => {
  dispatch(toggleDisableAction());

  return bindServerValidation(apiCreateLineItem(invoiceId, data), dispatch, { isReduxForm: false })
    .then(() => {
      dispatch(showNotification(lineItemCreateSuccess));
      dispatch(fetchInvoiceSums(invoiceId));
    })
    .catch((err) => {
      apiErrorHandler(err);
      throw err;
    })
    .finally(() => {
      dispatch(toggleDisableAction());
    });
};
