import React from 'react';
import { Link } from 'react-router-dom';

import productionUrl from 'constants/productionUrl';
import ChatIcon from 'images/chat.svg';
import MailIcon from 'images/mail.svg';
import PhoneIcon from 'images/phone.svg';
import { t } from 'shared/utils';

import styles from './Contact.module.css';

const contacts = [
  {
    image: ChatIcon,
    description: t('payment_plan_wizard.contact_1'),
    link: `${productionUrl}/index.php?site=hilfeundsupport#faqs`,
  },
  {
    image: MailIcon,
    description: t('payment_plan_wizard.contact_2'),
    link: 'mailto:service@vrsg.de',
  },
  {
    image: PhoneIcon,
    description: t('payment_plan_wizard.contact_3'),
    link: 'tel:+49 6196 7853265',
  },
];

const Contact = () => (
  <div className={styles.contactContainer}>
    {contacts.map(({ link, image, description }, index) => (
      <div className={styles.contact} key={index}>
        <Link target="_blank" to={link}>
          <img src={image} alt={description} className={styles.image} />
        </Link>
        <span>{description}</span>
      </div>
    ))}
  </div>
);

export default Contact;
