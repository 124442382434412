import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatISO } from 'date-fns';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { adapter } from './adapter';
import { name } from './constants';
import { StateSlice } from './types';

const slice = createSlice({
  name,
  initialState: adapter.getInitialState(),
  reducers: {
    add: (state: StateSlice, action: PayloadAction<string>) => {
      const email = action.payload;

      adapter.upsertOne(state, { email, date: formatISO(new Date(), { representation: 'date' }) });
    },
  },
});

const persistConfig = {
  key: 'welcomeTile',
  version: 1,
  storage,
};

export const actions = slice.actions;
export const reducer = persistReducer(persistConfig, slice.reducer);
