export const NAMESPACE = `CUSTOMER_MESSAGES`;

export const FETCH_REQUEST = `${NAMESPACE}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${NAMESPACE}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${NAMESPACE}/FETCH_FAILURE`;

export const TOGGLE_READ_REQUEST = `${NAMESPACE}/TOGGLE_READ_REQUEST`;
export const TOGGLE_READ_SUCCESS = `${NAMESPACE}/TOGGLE_READ_SUCCESS`;
export const TOGGLE_READ_FAILURE = `${NAMESPACE}/TOGGLE_READ_FAILURE`;

export const DELETE_REQUEST = `${NAMESPACE}/DELETE_REQUEST`;
export const DELETE_SUCCESS = `${NAMESPACE}/DELETE_SUCCESS`;
export const DELETE_FAILURE = `${NAMESPACE}/DELETE_FAILURE`;

export const SET_ACTIVE_MESSAGE = `${NAMESPACE}/SET_ACTIVE_MESSAGE`;
