import React, { SVGAttributes } from 'react';

const IconDownloadWhite = (props: SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M1 0h30a1 1 0 011 1v30a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1z" />
      <path
        d="M25.333 17.333V24c0 .733-.6 1.333-1.333 1.333H8c-.733 0-1.333-.6-1.333-1.333v-6.667c0-.733-.6-1.333-1.334-1.333C4.6 16 4 16.6 4 17.333v8C4 26.8 5.2 28 6.667 28h18.666C26.8 28 28 26.8 28 25.333v-8C28 16.6 27.4 16 26.667 16c-.734 0-1.334.6-1.334 1.333zm-8-.44l2.507-2.506c.52-.52 1.36-.52 1.88 0 .52.52.52 1.36 0 1.88l-4.787 4.786c-.52.52-1.36.52-1.88 0l-4.786-4.786c-.52-.52-.52-1.36 0-1.88.52-.52 1.36-.52 1.88 0l2.52 2.506V5.333C14.667 4.6 15.267 4 16 4s1.333.6 1.333 1.333v11.56z"
        fill="#FFFFFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default IconDownloadWhite;
