import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { t } from 'shared/utils';
import Loading from 'components/Loading';
import { getPaginationPreferences } from 'features/paginationPreferences/paginationPreferencesSlice';

import EmptyMessage from '../EmptyMessage/EmptyMessage';
import * as actions from './expenseCashTransactionsSlice';
import ExpenseContractsTable from './ExpenseCashTransactionsTable';
import * as selectors from './selectors';

const ExpenseCashTransactions = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const data = useSelector(selectors.getData);
  const isValidDateRange = useSelector(selectors.getIsValidDateRange);
  const isEmpty = data.length === 0;

  useEffect(() => {
    const fetch = async () => {
      await Promise.all([dispatch(getPaginationPreferences()), dispatch(actions.getData())]);
      setIsLoading(false);
    };

    fetch();
  }, [dispatch]);

  if (isEmpty && isLoading) return <Loading />;

  if (isEmpty || !isValidDateRange) {
    return <EmptyMessage>{t('datev.creator.messages.no_transactions_found')}</EmptyMessage>;
  }

  return <ExpenseContractsTable />;
};

export default memo(ExpenseCashTransactions);
