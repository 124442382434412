import React, { useCallback, useEffect, useState } from 'react';
import { change, WrappedFieldProps } from 'redux-form';

import { CrudMode, EDIT_MODE, NEW_MODE, SHOW_MODE } from 'constants/common/crud';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import { CategoryResponse } from 'types/entities/Category';
import { ContractSource } from 'types/entities/Contract';
import { TaxOption } from 'types/entities/TaxRate';
import CategorySearch from 'components/CategorySearch/CategorySearch';
import { CurrencyField, DateField, FormField, SelectField, TextField } from 'components/Form';
import {
  getCategory,
  getDefaultCategory,
  getDefaultTaxRate,
  getTaxRatesOptions,
} from 'components/LineItems/utils';
import NumberField from 'components/v2/Form/NumberField/NumberField';
import {
  FORM_NAME,
  NET_AMOUNT,
  RECURRING_TRANSACTION_INTERVAL_ID,
  SUBJECT,
  TRANSACTION_DATE,
} from 'features/contracts/constants';
import { getLoanLineCategories } from 'features/contracts/contractsSlice';

import SupplierSection from './SupplierSection';

import styles from '../Fields.module.css';

type LoadExpenseProps = {
  readonly: boolean;
  crudMode: CrudMode;
  contractSource?: ContractSource;
  intervalOptions: { label: any; value: string }[];
  lineCategories: CategoryResponse[];
  selectedCategory: CategoryResponse;
  initialValues: any;
};

const LoanExpense = ({
  intervalOptions,
  readonly,
  crudMode,
  contractSource,
  lineCategories,
  selectedCategory,
  initialValues,
}: LoadExpenseProps) => {
  const dispatch = useAppDispatch();
  const defaultCategory = getDefaultCategory(lineCategories);
  const [category, setCategory] = useState<CategoryResponse>();
  const [taxRates, setTaxRates] = useState<TaxOption[]>();
  const initialCategory = getCategory(lineCategories, selectedCategory);

  const handleSelectCategory = (item: any) => {
    if (item?.id) {
      setCategory(item);
      setTaxRates(getTaxRatesOptions(item.taxRates));
      dispatch(change(FORM_NAME, 'taxRateId', getDefaultTaxRate(item)?.id));
      dispatch(change(FORM_NAME, 'invoiceLineCategoryId', item?.id));
      dispatch(change(FORM_NAME, 'vat', getDefaultTaxRate(item?.taxRates)?.value));
    }
  };

  const setInitialData = useCallback(() => {
    if (
      crudMode === NEW_MODE ||
      (!initialValues?.invoiceLineCategoryId && crudMode === EDIT_MODE)
    ) {
      handleSelectCategory(defaultCategory);
    }

    if (crudMode === SHOW_MODE && !initialCategory) {
      setCategory(undefined);
    }

    if (crudMode === SHOW_MODE && initialCategory) {
      setCategory(initialCategory);
      setTaxRates(getTaxRatesOptions(initialCategory.taxRates));

      dispatch(change(FORM_NAME, 'taxRateId', initialValues?.taxRateId));
      dispatch(change(FORM_NAME, 'invoiceLineCategoryId', initialValues?.invoiceLineCategoryId));
    }
  }, [initialValues]);

  useEffect(() => {
    dispatch(getLoanLineCategories());
  }, [dispatch]);

  useEffect(() => {
    setInitialData();
  }, [initialValues, lineCategories, dispatch, crudMode, setInitialData]);

  return (
    <>
      <FormField
        dataId="Contracts:input-subject"
        name={SUBJECT}
        component={TextField}
        disabled={readonly}
        required
      />
      <FormField
        dataId="Contracts:input-FirstTransaction"
        name={TRANSACTION_DATE}
        dataIds={{
          input: 'Contracts:input-FirstTransaction',
        }}
        component={DateField}
        disabled={readonly}
        required
      />
      <SupplierSection
        required
        className={styles.supplier}
        name="supplier"
        label={t('form.fields.contracts_supplier')}
        readonly={
          // TODO it will be just readonly. This form will not be available in edit mode for VRSO contracts.
          readonly || (crudMode === EDIT_MODE && contractSource === ContractSource.Vrso)
        }
      />
      <FormField
        name={RECURRING_TRANSACTION_INTERVAL_ID}
        component={SelectField}
        dataId="Contracts:dropdown-interval"
        label={t('form.fields.interval')}
        options={intervalOptions}
        disabled={readonly}
        checker={checker}
        required
      />
      <CurrencyField
        dataId="Contracts:input-netAmount"
        label={t('form.fields.contracts_net_amount')}
        name={NET_AMOUNT}
        component={TextField}
        disabled={readonly}
        precision={2}
        required
      />
      <FormField
        dataId="Contracts:input-contractNumber"
        name="contractNumber"
        component={TextField}
        disabled={readonly}
      />
      <CurrencyField
        dataId="Contracts:input-totalAmount"
        name="totalAmount"
        component={TextField}
        required
        disabled={readonly}
        precision={2}
      />
      <FormField
        dataId="Contracts:input-occurrences"
        name="occurrences"
        component={NumberField}
        disabled={readonly}
        disableNegatives
        disableDecimals
      />
      <FormField
        name="invoiceLineCategoryId"
        component={({ input: { onChange, value }, meta: { error } }: WrappedFieldProps) => (
          <>
            <CategorySearch
              dataId="Contracts:select-category"
              categories={lineCategories}
              selectedItem={selectedCategory || { ...value }}
              initialSelectedItem={category}
              initialInputValue={category?.name}
              disabled={readonly}
              invalid={error}
              label={`${t('recurring_expenses.form.fields.category')} *`}
              onSelectCategory={handleSelectCategory || onChange}
              withIcon={!readonly}
            />
          </>
        )}
      />
      <FormField
        dataId="Contracts:input-taxRateId"
        name="taxRateId"
        options={taxRates}
        component={SelectField}
        readonly={readonly}
        required
      />
    </>
  );
};

export default LoanExpense;
