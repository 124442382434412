import React from 'react';

import Page from 'pages/shared/Page/Page';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import Button from 'components/Button';

import styles from './EmailTaken.module.css';

const EmailTaken = () => (
  <Page header={t('email_taken.header')}>
    <div className={styles.title}>{t('email_taken.title')}</div>
    <div className={styles.subtitle}>{t('email_taken.subtitle')}</div>
    <div className={styles.noteTitle}>{t('email_taken.note.title')}</div>
    <div className={styles.noteSubtitle}>{t('email_taken.note.subtitle')}</div>
    <div className={styles.buttonWrapper}>
      <Button
        className={styles.button}
        label={t('email_taken.go_to_login')}
        redirect={paths.login}
      />
    </div>
  </Page>
);

export default EmailTaken;
