import React from 'react';

import { Dashboard } from 'types/entities/Dashboard';
import IconClose from 'redesign/components/atoms/Icons/Close';

import * as SC from './WidgetWrapper.styled';

interface IWidgetWrapper {
  id: keyof Dashboard;
  widgetId: string;
  children: React.ElementType;
  isVisible: boolean;
  isEditMode: boolean;
  className: string;
  removeAction: () => void;
}

const WidgetWrapper = ({
  widgetId,
  children,
  className,
  isVisible,
  isEditMode,
  removeAction,
}: IWidgetWrapper) => {
  return isVisible ? (
    <SC.Wrapper
      isEditMode={isEditMode}
      className={className}
      data-id={`dashboard-widget-${widgetId}`}
    >
      {children}
      {isEditMode && (
        <>
          <SC.BlockingOverlay />
          <SC.RemoveButton data-id={`dashboard-widget-deactivate-button-${widgetId}`}>
            <IconClose width={50} height={50} onClick={removeAction} data-id="close" />
          </SC.RemoveButton>
        </>
      )}
    </SC.Wrapper>
  ) : null;
};

export default WidgetWrapper;
