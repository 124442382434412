import React from 'react';

import LegalsFooter from 'containers/LegalsFooter';
import Header from 'containers/PaymentPlan/ModalsContainer/components/Header/Header';
import { t } from 'shared/utils';

import Contact from './components/Contact/Contact';

import styles from './PaymentPlanWizardFooter.module.css';

const PaymentPlanWizardFooter = () => (
  <div className={styles.container}>
    <div className={styles.contactSection}>
      <Header
        bold={t('payment_plan_wizard.footer_header_1')}
        description={t('payment_plan_wizard.footer_header_2')}
      />
      <Contact />
    </div>
    <div className={styles.legalsSection}>
      <LegalsFooter authenticated />
    </div>
    <div className={styles.orangeSection} />
  </div>
);

export default PaymentPlanWizardFooter;
