import React from 'react';
import cx from 'classnames';

import arrowDownIcon from 'images/icon-arrow-down.svg';
import { t } from 'shared/utils';

import styles from '../Gallery.module.css';

type PrevFileBtnProps = {
  onClick: () => void;
  isPreviewRendering?: boolean;
};

const PrevFileBtn = ({ onClick, isPreviewRendering }: PrevFileBtnProps) => (
  <button
    title={t('expenses.upload.previous_file')}
    className={cx(styles.arrowPrev, { [styles.arrowInactive]: isPreviewRendering })}
    type="button"
    onClick={onClick}
    data-id="Gallery:button-previousFile"
  >
    <img
      alt={t('expenses.upload.previous_file')}
      className={styles.arrowPrevIcon}
      src={arrowDownIcon}
      data-id="Gallery:icon-previousFile"
    />
  </button>
);

export default PrevFileBtn;
