import { UPDATE_FAILURE, UPDATE_REQUEST, UPDATE_SUCCESS } from 'constants/contracts';

const defaultState = {
  isUpdating: false,
  error: null,
};

export default (state = defaultState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case UPDATE_REQUEST:
      return {
        isUpdating: true,
        error: false,
      };

    case UPDATE_SUCCESS:
      return {
        isUpdating: false,
        error: false,
      };

    case UPDATE_FAILURE:
      return {
        isUpdating: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
