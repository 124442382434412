import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import normalize from 'json-api-normalizer';
import {
  filter as _filter,
  flatten,
  flow,
  get,
  isEqual,
  parseInt,
  pick,
  snakeCase,
  some,
} from 'lodash';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import build from 'redux-object';

import { fetchBankAccount, fetchBankAccountTransfers, syncBankAccount } from 'actions/bank-account';
import {
  clearSearchFilters as clearSearchFiltersAction,
  fetchAllBankTransfers,
  setQueryParam as setQueryParamAction,
  setQuickFilter as setQuickFilterAction,
} from 'actions/bank-transfer';
import {
  createBankTransferConnection,
  createBankTransferLabel,
  deleteBankTransferConnection,
  deleteBankTransferLabel,
  rejectConnectionSuggestion,
  updateBankTransferConnection,
  updateBankTransferLabel,
} from 'actions/bank-transfer-connections';
import { getValidation as getValidationAction } from 'actions/datev';
import { showNotification } from 'actions/notification';
import { QuickFilters } from 'constants/common/filters';
import { Resources } from 'constants/resources';
import { BankTransferStatuses } from 'constants/statuses';
import { bankTransferSyncFailure, rejectSuggestion } from 'notifications/bank-account';
import { suggestionsEnabledHelper } from 'routes/accesses';
import { formatDate, formatMoney, noop, parseStringToFloat, t } from 'shared/utils';
import { BankName } from 'types/entities/BankAccount';
import { HeadingSection, Section } from 'components/CardView';
import If from 'components/Conditions/If';
import { StatusFilter, StatusFilterGroup } from 'components/Filter';
import { FiltersGroup } from 'components/Filter/FiltersGroup/FiltersGroup';
import I18n from 'components/I18n';
import { Pagination } from 'components/Pagination/Pagination';
import SyncBankAccountButton from 'components/SyncBankAccountButton';
import CSVDownloadButton from 'components/Table/CSVDownloadButton/CSVDownloadButton';

import SearchSection from './SearchSection/SearchSection';
import BankTransfersTable from './Table';

import styles from './Manager.module.css';

const SUCCESSFUL_SYNC_DISABLE_BUTTON_TIME = 10000;
const SYNC_CHECK_PERIOD = 5000;
const SYNC_TIMEOUT = 30000;

const BankTransfersQuickFilters = [
  QuickFilters.ALL,
  BankTransferStatuses.FULLY_ASSIGNED,
  BankTransferStatuses.PARTLY_ASSIGNED,
  BankTransferStatuses.UNASSIGNED,
  QuickFilters.SUGGESTIONS,
];

const hasPeriodRangeSet = ({ period }) => !!period && !!period.endDate;

const hasAnySearchQuery = (searchFilters) => {
  // filter-out startDate, to exclude the case of returning true when only the startDate of a range is set (no endDate).
  const checkForQueries = flow([_filter, flatten, some]);
  return (
    checkForQueries(searchFilters, (_, key) => key !== 'period') || hasPeriodRangeSet(searchFilters)
  );
};

class BankTransfersManager extends Component {
  state = {
    bankAccount: {},
    bankTransfers: [],
    pagination: {
      page: 1,

      totalPages: 1,
    },
    currentSorting: {
      column: 'date',
      direction: 'DESC',
    },
    isFetchingAccount: false,
    isFetchingTransfers: true,
    backgroundSyncInProgress: false,
    accountSyncInBackground: null,
    hasBeenSyncedRecently: null,
    bankTransfersConnections: [],
    labels: [],
    /**
     * It is used to avoid race conditions when fetching bank transfers. To make sure the state is
     * set with the newest request, that called parsedFilter, we do need to keep track of a last parsedFilter with
     * which request was called.
     */
    lastParsedFilters: null,
  };

  componentDidMount() {
    const { isFetchingFromAllAcounts } = this.props;

    if (!isFetchingFromAllAcounts) {
      this.getBankAccount().then(({ response }) => {
        const fetchingInProgress = get(response, 'data.meta.fetchingInProgress', false);
        if (fetchingInProgress) {
          this.startCheckingBankAccount();
        }
      });
    }

    this.getBankTransfers();
  }

  componentWillUnmount() {
    const { hasBeenSyncedRecently } = this.state;
    this.disableBackgroundSync();
    if (hasBeenSyncedRecently) clearTimeout(hasBeenSyncedRecently);
  }

  componentDidUpdate(prevProps) {
    const {
      parsedFilters,
      filters: { quickFilter },
    } = this.props;
    const {
      parsedFilters: prevParsedFilters,
      filters: { quickFilter: prevQuickFilter },
    } = prevProps;

    const isFiltering =
      !isEqual(parsedFilters, prevParsedFilters) || !isEqual(quickFilter, prevQuickFilter);

    if (!isFiltering) {
      return;
    }
    const overrideValues = isFiltering ? { page: 1 } : undefined;
    this.getBankTransfers(overrideValues);
  }

  getBankTransfers = (overridePagination = {}) => {
    this.setState({ isFetchingTransfers: true });

    const { pagination, currentSorting } = this.state;
    const { totalPages, ...paginationParams } = pagination;
    const {
      bankAccountId,
      fetchTransfers,
      filters: { quickFilter },
      parsedFilters,
      isFetchingFromAllAcounts,
      fetchAllTransfers,
      datevFilters = {},
    } = this.props;

    this.setState({ lastParsedFilters: parsedFilters });
    const mergedFilters = { ...quickFilter, ...parsedFilters, ...datevFilters };

    const fetchBankTransfersAction = isFetchingFromAllAcounts ? fetchAllTransfers : fetchTransfers;

    const correctPaginationParams = { ...paginationParams, ...overridePagination };

    return fetchBankTransfersAction(
      bankAccountId,
      correctPaginationParams,
      currentSorting,
      mergedFilters
    ).then(({ response, headers }) => {
      const bankTransfers =
        build(normalize(response), 'bankTransfers', undefined, {
          eager: true,
          ignoreLinks: true,
        }) || [];

      const bankTransfersConnections =
        build(normalize(response), 'bankTransferConnections', undefined, {
          eager: true,
          ignoreLinks: true,
        }) || [];

      const labels =
        build(normalize(response), 'bankTransferLabels', undefined, {
          eager: true,
          ignoreLinks: true,
        }) || [];

      const orderedIds = response.data.map((object) => object.id);

      const orderedBankTransfers = orderedIds.map((id) =>
        bankTransfers.find((bankTransfer) => id === bankTransfer.id)
      );
      const parsedTotalPages = parseInt(headers['total-pages'], 10);

      /**
       * Only set state when the request was performed with the proper
       * parsed filters (last)
       */
      if (!isEqual(parsedFilters, this.state.lastParsedFilters)) return;

      this.setState({
        bankTransfers: orderedBankTransfers,
        isFetchingTransfers: false,
        pagination: { ...this.state.pagination, totalPages: parsedTotalPages },
        bankTransfersConnections,
        labels,
      });
    });
  };

  getBankAccount = () => {
    this.setState({ isFetchingAccount: true });
    const { bankAccountId, fetchAccount } = this.props;

    return fetchAccount(bankAccountId).then((res) => {
      const { response } = res;
      const bankAccount = build(normalize(response), 'bankAccounts', response.data.id, {
        eager: true,
        ignoreLinks: true,
      });

      this.setState({
        isFetchingAccount: false,
        bankAccount,
        backgroundSyncInProgress: response.data.meta.fetchingInProgress,
      });
      return res;
    });
  };

  disableBackgroundSync() {
    const { accountSyncInBackground } = this.state;
    if (accountSyncInBackground) {
      clearInterval(accountSyncInBackground);
      this.setState({ accountSyncInBackground: null });
    }
  }

  handleSortingChange = (column) => {
    const changeDirection = (sorting) => ({
      ...sorting,
      direction: sorting.direction === 'DESC' ? 'ASC' : 'DESC',
    });

    const currentSorting =
      column === this.state.currentSorting.column
        ? changeDirection(this.state.currentSorting)
        : { ...this.state.currentSorting, column };

    this.setState(
      { currentSorting },
      this.handlePaginationChange.bind(this, { page: 1 }, this.getBankTransfers)
    );
  };

  temporarilyDisableButton = () => {
    const timeoutId = setTimeout(() => {
      this.setState({
        hasBeenSyncedRecently: false,
      });
    }, SUCCESSFUL_SYNC_DISABLE_BUTTON_TIME);

    this.setState({
      hasBeenSyncedRecently: timeoutId,
    });
  };

  startCheckingBankAccount = () => {
    const getBankAccount = async () => {
      try {
        const { response: { data: { meta = {} } = {} } = {} } = await this.getBankAccount();
        const { fetchingInProgress, fetchingSuccessful } = meta;
        if (!fetchingInProgress) {
          this.disableBackgroundSync();
          if (fetchingSuccessful) {
            this.temporarilyDisableButton();
            this.getBankTransfers();
          } else {
            this.props.showNotification(bankTransferSyncFailure).catch(noop);
          }
        }
      } catch (errors) {
        this.disableBackgroundSync();
        this.props.showNotification(bankTransferSyncFailure).catch(noop);
        this.setState({ isFetchingAccount: false });
      }
    };
    const intervalId = setInterval(() => {
      getBankAccount();
    }, SYNC_CHECK_PERIOD);

    this.setState({ accountSyncInBackground: intervalId });
    setTimeout(() => {
      clearInterval(intervalId);
      this.setState({ accountSyncInBackground: null });
    }, SYNC_TIMEOUT);
  };

  syncBankAccount = async () => {
    await this.props.syncBankAccount(this.props.bankAccountId);
    this.startCheckingBankAccount();
  };

  updateDatevValidation = () => {
    const { isFetchingFromAllAcounts, getValidation, startDate, endDate } = this.props;
    if (isFetchingFromAllAcounts) getValidation(startDate, endDate, { hasAnimation: false });
  };

  connectResource =
    (bankTransferId) =>
    ({
      isInEditMode,
      resourceId,
      resourceType,
      assigmentOption,
      assignedValue,
      jokerOptionReason,
    }) =>
    async () => {
      const action = isInEditMode
        ? this.props.updateBankTransferConnection
        : this.props.createBankTransferConnection;

      await action(
        resourceId,
        resourceType,
        parseInt(bankTransferId),
        assigmentOption,
        parseStringToFloat(assignedValue),
        jokerOptionReason
      );

      this.updateDatevValidation();
      return this.getBankTransfers();
    };

  disconnectResource =
    (bankTransferId) =>
    ({ resourceId, resourceType }) =>
    async () => {
      await this.props.deleteBankTransferConnection(
        resourceId,
        resourceType,
        parseInt(bankTransferId)
      );

      this.updateDatevValidation();
      return this.getBankTransfers();
    };

  updateSuggestions = (notificationToShow) => {
    this.props.showNotification(notificationToShow).catch(noop);
    this.getBankTransfers();
  };

  rejectConnectionSuggestion =
    (bankTransferId) =>
    ({ invoiceId, resourceType }) =>
    async () => {
      await this.props.rejectConnectionSuggestion({
        invoiceId,
        resourceType,
        bankTransferId: parseInt(bankTransferId),
      });
      return this.updateSuggestions(rejectSuggestion);
    };

  handlePaginationChange = (pagination, callback = noop) => {
    if (this.state.isFetchingTransfers) {
      return;
    }
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
      },
      callback
    );
  };

  createLabel =
    (bankTransferId) =>
    async (label = {}) => {
      const action = label.isLabelInEditMode
        ? this.props.updateBankTransferLabel
        : this.props.createBankTransferLabel;
      await action({
        bankTransferId,
        category: snakeCase(label.selectedOption),
        amount_assigned: parseStringToFloat(label.amount),
        note: label.note,
        id: label.id,
      });

      this.updateDatevValidation();
      return this.getBankTransfers();
    };

  deleteLabel = (labelId) => {
    this.props.deleteBankTransferLabel(labelId);
    this.updateDatevValidation();
    return this.getBankTransfers();
  };

  render() {
    const {
      pagination,
      bankAccount,
      bankTransfers,
      currentSorting,
      isFetchingAccount,
      isFetchingTransfers,
      backgroundSyncInProgress,
      accountSyncInBackground,
      hasBeenSyncedRecently,
      bankTransfersConnections,
      labels,
    } = this.state;
    const {
      isFetchingFromAllAcounts,
      bankAccountId,
      suggestionsEnabled,
      setQuickFilter,
      clearSearchFilters,
      setQueryParam,
      filters: { quickFilter, searchFilters },
      datevFilters,
      parsedFilters,
    } = this.props;
    const bankTransfersHiddenWhileSyncInProgress = accountSyncInBackground ? [] : bankTransfers;
    const isEmpty = Boolean(!bankTransfersHiddenWhileSyncInProgress.length);
    const displayBalanceDate = bankAccount.figoBalanceDate
      ? formatDate(bankAccount.figoBalanceDate)
      : '-';
    const quickFilterSelector = quickFilter.status || quickFilter.with_any_invoice_suggestions;
    const mergedFilters = { ...quickFilter, ...parsedFilters, ...datevFilters };
    const hasAnySearchFiltersActive = hasAnySearchQuery(parsedFilters);
    const CSVExport = (
      <CSVDownloadButton
        endpoint={`bank_accounts/${bankAccountId}/bank_transfers_csv`}
        filters={mergedFilters}
        sorting={currentSorting}
        disabled={isEmpty}
        className={styles.csv}
      />
    );

    return (
      <section className={styles.main}>
        <If ok={!isFetchingFromAllAcounts && (!isFetchingAccount || accountSyncInBackground)}>
          <HeadingSection>
            <header className={styles.columns}>
              <div className={styles.leftColumn}>
                {suggestionsEnabled && (
                  <StatusFilterGroup
                    filterSelector={quickFilterSelector}
                    selectFilterAction={setQuickFilter}
                  >
                    {BankTransfersQuickFilters.map((filter) => (
                      <StatusFilter
                        key={filter}
                        isDefault={filter === BankTransfersQuickFilters.All}
                        name={filter === BankTransfersQuickFilters.All ? '' : filter}
                      >
                        <I18n className={styles.filterLabel} t={`tables.filters.${filter}`} />
                      </StatusFilter>
                    ))}
                  </StatusFilterGroup>
                )}
                <FiltersGroup
                  className={styles.filtersGroup}
                  isResetButtonDisabled={!hasAnySearchFiltersActive}
                  additionalFilters={<SearchSection searchFilters={searchFilters} />}
                  filters={searchFilters}
                  onFiltersChange={({ name, value }) => setQueryParam(name, value)}
                  onFiltersReset={clearSearchFilters}
                  placeholder={t('tables.filters.filters_group.bank_transfers.placeholder')}
                  dataIds={{
                    input: 'BankTransfers:input-search',
                  }}
                >
                  <div className={styles.csvExportFilters}>{CSVExport}</div>
                </FiltersGroup>
              </div>
              <div className={styles.rightColumn}>
                <div className={styles.bankInfo}>
                  {bankAccount.bankName === BankName.PayPal ? (
                    <>
                      <div>{bankAccount.bankName}</div>
                      <div>{bankAccount.number}</div>
                    </>
                  ) : (
                    <>
                      <div>{`${bankAccount.bankName}, ${bankAccount.name}`}</div>
                      <div>{bankAccount.iban}</div>
                    </>
                  )}
                </div>
                <div className={styles.accountInfo}>
                  {t('bank_transfers.table.header.balance')}: {formatMoney(bankAccount.balance)}
                </div>
                <div>
                  <div className={styles.updatedInfo}>
                    {t('bank_transfers.table.header.last_update_date')}: {displayBalanceDate}
                  </div>
                  <div className={styles.syncButton}>
                    <SyncBankAccountButton
                      bankAccount={this.state.bankAccount}
                      postLoginAction={this.startCheckingBankAccount}
                      disabled={Boolean(accountSyncInBackground || hasBeenSyncedRecently)}
                      setSyncInProgress={(value) =>
                        this.setState({ accountSyncInBackground: value })
                      }
                    />
                  </div>
                </div>
                <div className={styles.csvExportRightColumn}>{CSVExport}</div>
              </div>
            </header>
          </HeadingSection>
        </If>
        <Section
          className={cx(styles.section, {
            [styles.isFetching]: isFetchingTransfers,
            [styles.isEmpty]: !bankTransfers.length,
          })}
        >
          <BankTransfersTable
            data={bankTransfersHiddenWhileSyncInProgress}
            isFetchingFromAllAcounts={isFetchingFromAllAcounts}
            sorting={currentSorting}
            sort={this.handleSortingChange}
            refresh={this.getBankTransfers}
            actions={{
              connectResource: this.connectResource,
              disconnectResource: this.disconnectResource,
              rejectConnectionSuggestion: this.rejectConnectionSuggestion,
              createLabel: this.createLabel,
              deleteLabel: this.deleteLabel,
            }}
            backgroundSyncInProgress={backgroundSyncInProgress && accountSyncInBackground}
            isFetching={Boolean(isFetchingTransfers || accountSyncInBackground)}
            bankTransfersConnections={bankTransfersConnections}
            labels={labels}
          />
        </Section>
        <If ok={!!bankTransfersHiddenWhileSyncInProgress.length}>
          <Section
            className={cx(styles.section, styles.pagination, {
              [styles.isFetching]: isFetchingTransfers,
              [styles.isEmpty]: !bankTransfersHiddenWhileSyncInProgress.length,
            })}
          >
            <Pagination
              {...pagination}
              request={(paginationData) => {
                this.handlePaginationChange(paginationData, this.getBankTransfers);
              }}
              isFetching={isFetchingTransfers}
              resource={Resources.BANK_ACCOUNTS_BANK_TRANSFERS}
            />
          </Section>
        </If>
      </section>
    );
  }
}

BankTransfersManager.propTypes = {
  bankAccountId: string,
  columns: arrayOf(
    shape({
      column: string.isRequired,
      align: string,
    })
  ),
  fetchTransfers: func.isRequired,
  datevFilters: shape({}),
  fetchAllTransfers: func.isRequired,
  fetchAccount: func.isRequired,
  isFetchingFromAllAcounts: bool,
  syncBankAccount: func.isRequired,
  showNotification: func.isRequired,
  createBankTransferConnection: func.isRequired,
  deleteBankTransferConnection: func.isRequired,
  updateBankTransferConnection: func.isRequired,
  rejectConnectionSuggestion: func.isRequired,
  createBankTransferLabel: func.isRequired,
  updateBankTransferLabel: func.isRequired,
  deleteBankTransferLabel: func.isRequired,
  suggestionsEnabled: bool,
  filters: shape({}),
  parsedFilters: shape({}),
  setQuickFilter: func.isRequired,
  clearSearchFilters: func.isRequired,
  getValidation: func.isRequired,
  setQueryParam: func.isRequired,
  startDate: string,
  endDate: string,
};

export default connect(
  (state) => ({
    ...state.bankTransfers,
    suggestionsEnabled: suggestionsEnabledHelper(state),
    ...pick(get(state, 'form.DatevCreator.values'), ['startDate', 'endDate']),
  }),
  {
    fetchAccount: fetchBankAccount,
    fetchTransfers: fetchBankAccountTransfers,
    fetchAllTransfers: fetchAllBankTransfers,
    deleteBankTransferConnection,
    createBankTransferConnection,
    updateBankTransferConnection,
    rejectConnectionSuggestion,
    createBankTransferLabel,
    updateBankTransferLabel,
    deleteBankTransferLabel,
    showNotification,
    syncBankAccount,
    setQuickFilter: setQuickFilterAction,
    setQueryParam: setQueryParamAction,
    clearSearchFilters: clearSearchFiltersAction,
    getValidation: getValidationAction,
  }
)(BankTransfersManager);
