import { ReactNode } from 'react';

import PaymentPlan from 'containers/PaymentPlan/PaymentPlan';
import paths from 'routes/paths';
import { t } from 'shared/utils';

import BankAccountsSection from './BankAccounts/BankAccounts';
import CompanySection from './Company/Company';
import NumberRangesSection from './NumberRanges/NumberRangesSection';
import PersonalSection from './Personal/Personal';
import TaxConsultantForm from './TaxConsultantForm/TaxConsultantForm';

type ProfileRoute = {
  heading: string;
  subheading?: string;
  description?: ReactNode;
  component: any;
  path: string;
  dirtyPath?: string;
  reduxFormName?: string | null;
  dataId?: string;
  children?: {
    heading: string;
    path: string;
  }[];
};

export const routes: ProfileRoute[] = [
  {
    heading: t('profile.section_headers.user'),
    description: t('profile.section_description.user'),
    component: PersonalSection,
    path: paths.personalProfile,
    reduxFormName: 'profile',
    dataId: 'Profile:tab-user',
  },
  {
    heading: t('profile.section_headers.company'),
    description: t('profile.section_subheaders.company'),
    component: CompanySection,
    path: paths.companyProfile,
    reduxFormName: 'company',
    dataId: 'Profile:tab-company',
  },
  {
    heading: t('profile.section_headers.tax_consultant'),
    component: TaxConsultantForm,
    path: paths.taxConsultantProfile,
    reduxFormName: 'taxConsultant',
    dataId: 'Profile:tab-tax-consultant',
  },
  {
    heading: t('profile.section_headers.bank_accounts'),
    component: BankAccountsSection,
    path: paths.bankAccounts,
    reduxFormName: null,
    dataId: 'Profile:tab-account',
  },
  {
    heading: t('profile.section_headers.number_ranges'),
    description: t('profile.number_ranges.message'),
    component: NumberRangesSection,
    path: paths.numberRanges,
    reduxFormName: 'numberRanges',
    dirtyPath: 'numberRanges.dirty',
    dataId: 'Profile:tab-number-ranges',
  },
  {
    heading: t('profile.section_headers.payment_plan'),
    component: PaymentPlan,
    path: paths.paymentPlan,
    reduxFormName: 'company',
    dataId: 'Profile:tab-payment-plan',
  },
];
