import { INDEX_ACCOUNTS_SUCCESS } from 'constants/banks';

const ids = (action) => action.response.data.map(({ attributes }) => attributes.accountId);

const byId = (action) =>
  action.response.data.reduce(
    (acc, { attributes }) => ({
      ...acc,
      [attributes.accountId]: attributes,
    }),
    {}
  );

const defaultState = {
  ids: [],
  byId: {},
};

export const getBankAccount = (state, id) => state.banks.accounts.byId[id];

export const getBankAccounts = (state) =>
  state.banks.accounts.ids.map((i) => state.banks.accounts.byId[i]);

export default (state = defaultState, action) => {
  if (action.type !== INDEX_ACCOUNTS_SUCCESS) return state;

  return {
    ids: ids(action),
    byId: byId(action),
  };
};
