import React from 'react';
import cx from 'classnames';
import { bool, string } from 'prop-types';

import loadingIcon from 'images/icon-loading.gif';

import styles from './LoadingIcon.module.css';

const LoadingIcon = ({
  staticPosition = false,
  alwaysOnTop = false,
  text = '',
  enlarged = true,
  topDisabled = false,
  dataId = '',
}) => (
  <div
    data-id={dataId}
    className={cx(styles.loadingIconContainer, {
      [styles.alwaysOnTop]: alwaysOnTop,
      [styles.loadingIconContainerStatic]: staticPosition,
      [styles.topDisabled]: topDisabled,
    })}
  >
    <img
      src={loadingIcon}
      alt=""
      data-id="loadingIcon"
      className={cx(styles.loadingIcon, {
        [styles.loadingIconStatic]: staticPosition,
        [styles.loadingIconEnlarged]: enlarged,
      })}
    />
    {text && (
      <p
        className={cx(styles.loadingText, {
          [styles.loadingTextEnlarged]: enlarged,
        })}
      >
        {text}
      </p>
    )}
  </div>
);

LoadingIcon.propTypes = {
  staticPosition: bool,
  alwaysOnTop: bool,
  enlarged: bool,
  text: string,
  topDisabled: bool,
  dataId: string,
};

export default LoadingIcon;
