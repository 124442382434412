import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import LiquidityCalculator from './liquidityCalculator/LiquidityCalculator';
import Obs from './obs/Obs';

const Routes = () => {
  const match = useRouteMatch();

  if (!match) {
    return null;
  }

  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/liquidity-calculator`} />
      <Route exact path={`${match.path}/liquidity-calculator`}>
        <LiquidityCalculator />
      </Route>
      <Route exact path={`${match.path}/obs`}>
        <Obs />
      </Route>
    </Switch>
  );
};

export default Routes;
