import { isToday, parse } from 'date-fns';

import type { BankAccount } from './types';

export const isBankAccountUpToDate = (account: Pick<BankAccount, 'figoBalanceDate'>) => {
  const lastUpdated =
    account.figoBalanceDate && parse(account.figoBalanceDate, 'dd.MM.yyyy', new Date());
  const isUpToDate = lastUpdated && isToday(lastUpdated);

  return Boolean(isUpToDate);
};
