import { connect } from 'react-redux';

import { quickCreateSupplier } from 'actions/incoming-invoice';
import SupplierSection from 'containers/IncomingInvoices/IncomingInvoiceCreator/CreateInvoiceSection/Supplier';
import { RootState } from 'store';
import { FORM_NAME } from 'features/contracts/constants';
import { getContractsSupplier } from 'features/contracts/formSelectors';

const mapStateToProps = (state: RootState) => ({
  supplier: getContractsSupplier(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  onSubmitSupplier: (values: any) => quickCreateSupplier(FORM_NAME)(values)(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierSection);
