import React from 'react';
import cx from 'classnames';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { formatIBAN } from 'shared/utils';
import DownshiftSelect from 'components/Form/SelectField/DownshiftSelect';

import styles from './BankAccountSelectField.module.css';

const defaultLabelMapper = ({ iban, name }) => `${formatIBAN(iban)} (${name})`;

const BankAccountSelectField = ({
  bankAccounts,
  onSelect,
  shouldInitiallySelectBankAccount,
  initiallySelectedBankAccount,
  labelMapper = defaultLabelMapper,
  wrapperClassName,
  menuClassName,
  ...props
}) => {
  const validationChecker = ({ meta: { dirty, error } = {} }) => !dirty && error;
  const options = bankAccounts.map(({ name, iban, bankName, id, ...rest }) => ({
    ...rest,
    name,
    iban,
    bankName,
    id,
    label: labelMapper({ iban, bankName, id, name, ...rest }),
    value: id,
  }));

  const initalSelectedItem = shouldInitiallySelectBankAccount
    ? options.find(
        (option) => initiallySelectedBankAccount && option.id === initiallySelectedBankAccount.id
      )
    : undefined;

  return (
    <div className={cx(styles.selectWrapper, wrapperClassName)}>
      <DownshiftSelect
        {...props}
        defaultIcon
        validationChecker={validationChecker}
        menuClassName={cx(styles.menu, menuClassName)}
        onSelect={onSelect}
        options={options}
        initialSelectedItem={initalSelectedItem}
        isReinitializedOnInitialChange
      />
    </div>
  );
};

const BankAccountShape = shape({
  name: string.isRequired,
  iban: string.isRequired,
  bankName: string.isRequired,
  id: number.isRequired,
});

BankAccountSelectField.propTypes = {
  onSelect: func.isRequired,
  labelMapper: func,
  shouldInitiallySelectBankAccount: bool.isRequired,
  initiallySelectedBankAccount: BankAccountShape,
  bankAccounts: arrayOf(BankAccountShape),
  menuClassName: string,
  wrapperClassName: string,
};

export default BankAccountSelectField;
