import { connect } from 'react-redux';

import { createOrderConfirmationLineItem } from 'actions/order-confirmation/line-items';
import { TYPE_ORDER_CONFIRMATION } from 'constants/row-types';
import { productCatalogItemsEnabledHelper } from 'routes/accesses';

import NewItemRow from '../LineItems/NewItemRow';

const mapStateToProps = (state) => ({
  orderConfirmationId: state.orderConfirmation.details.id,
  smallEntrepreneur: state.company.details.smallEntrepreneur,
  insertedAsGross: state.orderConfirmation.details.insertedAsGross,
  productCatalogItemsEnabled: productCatalogItemsEnabledHelper(state),
  lineCategories: state.orderConfirmation.lineCategories,
});

const mapDispatchToProps = (dispatch) => ({
  createLineItem: (...args) => dispatch(createOrderConfirmationLineItem(...args)),
});

const mergeProps = (
  {
    orderConfirmationId,
    productCatalogItemsEnabled,
    smallEntrepreneur,
    insertedAsGross,
    lineCategories,
  },
  dispatchProps,
  ownProps
) => ({
  ...ownProps,
  ...dispatchProps,
  smallEntrepreneur,
  productCatalogItemsEnabled,
  invoiceInsertedAsGross: insertedAsGross,
  translationPath: 'features.order_confirmation',
  rowType: TYPE_ORDER_CONFIRMATION,
  cells: [
    { name: 'position', type: productCatalogItemsEnabled ? 'positionSelect' : 'positionInput' },
    { name: 'quantity', type: 'input' },
    { name: 'unit', type: 'unitSelect' },
    { type: insertedAsGross ? 'grossLinked' : 'netLinked' },
    { name: 'vat', type: 'select' },
    { name: insertedAsGross ? 'totalGrossAmount' : 'totalNetAmount', type: 'text' },
  ],
  extendedRowCells: [
    { name: 'itemNumber', type: productCatalogItemsEnabled ? 'itemNumberSelect' : 'itemNumber' },
    { name: 'description', type: 'counterInput' },
    { name: 'internalNotes', type: 'counterInput' },
  ],
  createLineItem: (formValues) => dispatchProps.createLineItem(orderConfirmationId, formValues),
  lineCategories,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NewItemRow);
