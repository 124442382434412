import React from 'react';
import cx from 'classnames';
import { Field } from 'redux-form';

import SectionWithMargin, { SectionRow } from 'containers/Profile/SectionWithMargin';
import { t } from 'shared/utils';
import { Checkbox, InfoTextField, SelectField, TextField } from 'components/Form';

import { CompanyFormType, FormSelector } from '../Company.types';
import { businessSectorOptions, INDEPENDENT_CONTRACTOR_COMPANY_FORM } from './constants';
import {
  formatRegistrationCourt,
  formatRegistrationNumber,
  parseRegistrationNumber,
} from './utils';

import styles from '../Company.module.css';

type CompanyInformationProps = {
  companyForms: CompanyFormType[];
  formSelector: FormSelector;
  selectedCompanyForm: string;
};

const CompanyInformation = ({
  companyForms,
  formSelector,
  selectedCompanyForm,
}: CompanyInformationProps) => {
  const currentCompanyForm = companyForms.find((form) => form.id === formSelector('form'));
  const currentRegistration = currentCompanyForm && currentCompanyForm.companyRegistration;
  const registrationRequired =
    currentRegistration === 'required' ||
    (currentRegistration === 'optional' && formSelector('registered'));
  const registrationOptional = currentRegistration === 'optional';
  const registrationForbidden = !registrationRequired;
  const prefixRequired =
    currentCompanyForm && registrationRequired && currentCompanyForm.registrationNumberPrefix;
  const companyFormOptions = companyForms.map((form) => ({
    value: form.id,
    label: t(`profile.company.company_forms.${form.id}`),
  }));
  const registrationNumberPrefix = prefixRequired
    ? `${currentCompanyForm!.registrationNumberPrefix} `
    : '';

  return (
    <SectionWithMargin
      wrapperTheme={cx(styles.sectionWithMargin, styles.companyInformationSection)}
      sectionTitleTheme={styles.companyInformationTitle}
      title={t('profile.company.sections.company_information')}
    >
      <SectionRow className={styles.row}>
        <Field
          name="name"
          component={InfoTextField}
          label={t('profile.company.labels.name')}
          placeholder={t('profile.company.placeholders.name')}
          message={t('profile.company.company_message')}
          dataId="input-name"
          required
        />
        <Field
          name="businessSector"
          component={SelectField}
          options={businessSectorOptions}
          clearable
          dataId="input-business-sector"
          label={t('profile.company.labels.business_sector')}
        />
        {selectedCompanyForm === INDEPENDENT_CONTRACTOR_COMPANY_FORM && (
          <Field
            name="registered"
            dataId="input-registered"
            component={Checkbox}
            visible={registrationOptional}
          >
            {t('profile.company.registered')}
          </Field>
        )}
      </SectionRow>
      <SectionRow className={styles.row}>
        <Field
          name="form"
          component={SelectField}
          options={companyFormOptions}
          clearable
          dataId="input-form"
          message={t('profile.company.company_select_message')}
          label={t('profile.company.labels.form')}
        />
        <Field
          name="registrationNumber"
          dataId="input-registration-number"
          component={TextField}
          label={t('profile.company.labels.registration_number')}
          placeholder={t('profile.company.placeholders.registration_number')}
          required={Boolean(registrationRequired)}
          disabled={registrationForbidden}
          parse={parseRegistrationNumber(registrationNumberPrefix)}
          format={formatRegistrationNumber(registrationNumberPrefix, registrationForbidden)}
          isDisabledWithUnderline
          validatePristine
        />
        <Field
          name="registrationCourt"
          component={TextField}
          dataId="input-registration-court"
          placeholder={t('profile.company.placeholders.registration_court')}
          required={Boolean(registrationRequired)}
          disabled={registrationForbidden}
          format={formatRegistrationCourt(registrationForbidden)}
          isDisabledWithUnderline
          validatePristine
        />
      </SectionRow>
    </SectionWithMargin>
  );
};

export default CompanyInformation;
