import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { FieldArray } from 'redux-form';

import { useIsMdUp, useIsXXLgUp } from 'shared/hooks/useMedia';
import { formatMoney, t } from 'shared/utils';
import Hint, { ArrowPosition } from 'components/Hint/Hint';

import Result, { Color } from '../ExpensesRevenues/Result/Result';
import Subtitle from '../ExpensesRevenues/Subtitle/Subtitle';
import SummaryHorizontal from '../ExpensesRevenues/SummaryHorizontal/SummaryHorizontal';
import SummaryVertical from '../ExpensesRevenues/SummaryVertical/SummaryVertical';
import Title from '../ExpensesRevenues/Title/Title';
import Left from '../ExpensesRevenues/TwoColumnLayout/Left';
import Right from '../ExpensesRevenues/TwoColumnLayout/Right';
import Row from '../ExpensesRevenues/TwoColumnLayout/Row';
import * as formSelectors from '../formSelectors';
import Section from '../Section/Section';
import * as selectors from '../selectors';
import List from './List/List';
import OtherRevenues from './OtherRevenues/OtherRevenues';
import Table from './Table/Table';
import type { OtherRevenue, RecurringRevenue } from './types';

import styles from '../ExpensesRevenues/ExpensesRevenues.module.css';

const Revenues = () => {
  const monthlyRevenues = useSelector(formSelectors.getMonthlyRevenues);
  const { averageRevenues } = useSelector(selectors.getAverageAccountDetailsForSelectedAccounts);
  const alert = useSelector(selectors.getExpensesAlert);
  const isDisabled = !!alert;
  const isMdUp = useIsMdUp();
  const isXXLgUp = useIsXXLgUp();
  const SummaryComponent = isMdUp ? SummaryHorizontal : SummaryVertical;
  const revenuesDifference = averageRevenues - monthlyRevenues;
  const summaryHint = (
    <div className={styles.summaryHint}>
      <Hint arrowPosition={isXXLgUp ? ArrowPosition.Right : ArrowPosition.Top}>
        <div className={styles.summaryHintText}>
          {t(
            revenuesDifference > 0
              ? 'features.financial_planning.liquidity_calculator.revenues.hint.positive'
              : 'features.financial_planning.liquidity_calculator.revenues.hint.negative',
            {
              amount: formatMoney(Math.abs(revenuesDifference)),
            }
          )}
        </div>
      </Hint>
    </div>
  );

  return (
    <Section
      title={t('features.financial_planning.liquidity_calculator.revenues.title')}
      eventName="Expand_Step3"
      dataId="LiquidityCalculatorSection:title-revenues"
    >
      <div className={cx(styles.content, { [styles.disabledSection]: isDisabled })}>
        <Row>
          <Left>
            <Title className={styles.title}>
              {t(
                'features.financial_planning.liquidity_calculator.revenues.recurring_revenues.title'
              )}
            </Title>
            <Subtitle className={styles.subtitle}>
              {t(
                'features.financial_planning.liquidity_calculator.revenues.recurring_revenues.subtitle'
              )}
            </Subtitle>
          </Left>
          <Right>
            {isMdUp ? (
              <div className={styles.table}>
                <FieldArray<{}, RecurringRevenue> name="recurringRevenues" component={Table} />
              </div>
            ) : (
              <div className={styles.list}>
                <FieldArray<{}, RecurringRevenue> name="recurringRevenues" component={List} />
              </div>
            )}
          </Right>
        </Row>
        <Row className={styles.otherRow}>
          <Left>
            <Title className={styles.title}>
              {t('features.financial_planning.liquidity_calculator.revenues.other_revenues.title')}
            </Title>
            <Subtitle className={styles.subtitle}>
              {t(
                'features.financial_planning.liquidity_calculator.revenues.other_revenues.subtitle'
              )}
            </Subtitle>
          </Left>
          <Right>
            <div className={styles.other}>
              <FieldArray<{}, OtherRevenue> name="otherRevenues" component={OtherRevenues} />
            </div>
          </Right>
        </Row>
        <Row className={styles.summaryRow}>
          {isXXLgUp && <Left>{!isDisabled && summaryHint}</Left>}
          <Right>
            <SummaryComponent
              title={t('features.financial_planning.liquidity_calculator.revenues.summary.title')}
              result={<Result result={monthlyRevenues} color={Color.Green} />}
              dataId={`LiquidityCalculatorRevenues:summary`}
            />
            {!isXXLgUp && summaryHint}
          </Right>
        </Row>
      </div>
    </Section>
  );
};

export default memo(Revenues);
