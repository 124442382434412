export const NAMESPACE = 'CONTRACTS_UPLOADED_FILES';

export const FETCH_REQUEST = `${NAMESPACE}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${NAMESPACE}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${NAMESPACE}/FETCH_FAILURE`;

export const UPLOAD_REQUEST = `${NAMESPACE}/UPLOAD_REQUEST`;
export const UPLOAD_SUCCESS = `${NAMESPACE}/UPLOAD_SUCCESS`;
export const UPLOAD_FAILURE = `${NAMESPACE}/UPLOAD_FAILURE`;

export const DELETE_REQUEST = `${NAMESPACE}/DELETE_REQUEST`;
export const DELETE_SUCCESS = `${NAMESPACE}/DELETE_SUCCESS`;
export const DELETE_FAILURE = `${NAMESPACE}/DELETE_FAILURE`;

export const DOWNLOAD_PREVIEW_REQUEST = `${NAMESPACE}/DOWNLOAD_PREVIEW_REQUEST`;
export const DOWNLOAD_PREVIEW_SUCCESS = `${NAMESPACE}/DOWNLOAD_PREVIEW_SUCCESS`;
export const DOWNLOAD_PREVIEW_FAILURE = `${NAMESPACE}/DOWNLOAD_PREVIEW_FAILURE`;

export const DOWNLOAD_CONTRACT_REQUEST = `${NAMESPACE}/DOWNLOAD_CONTRACT_REQUEST`;
export const DOWNLOAD_CONTRACT_SUCCESS = `${NAMESPACE}/DOWNLOAD_CONTRACT_SUCCESS`;
export const DOWNLOAD_CONTRACT_FAILURE = `${NAMESPACE}/DOWNLOAD_CONTRACT_FAILURE`;
