import styled from 'styled-components';

import media from 'shared/styles/breakpoints';
import ButtonComponent from 'components/Button';
import CardViewComponent, {
  HeadingSection as HeadingSectionComponent,
  Section as SectionComponent,
} from 'components/CardView';

export const HeadingSection = styled(HeadingSectionComponent)`
  padding: 15px 20px;
`;

export const Section = styled(SectionComponent)`
  padding: 17px 20px 64px;
`;

export const Description = styled.div`
  max-width: 766px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 24px;
`;

export const GeneralInputs = styled.div`
  display: grid;
  margin-bottom: 63px;
  grid-row-gap: 15px;

  ${media.greaterThan('md-up')`
     grid-template-columns: 161px 226px;
     grid-column-gap: 20px;
  `}
`;

export const Button = styled(ButtonComponent)`
  display: block;
  margin: 10px auto 0;
  padding: 10px 20px !important;

  ${media.greaterThan('md-up')`
     margin-right: 0;
     margin-left: auto;
  `}
`;

export const CardView = styled(CardViewComponent)`
  min-height: auto;
`;

export const NotificationTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin-bottom: 10px;
`;
