import { createSelector } from '@reduxjs/toolkit';
import { isToday, parseISO } from 'date-fns';

import { getEmail } from 'selectors/profile';
import { RootState } from 'store';

import { adapter } from './adapter';
import { name } from './constants';

const slice = (state: RootState) => state[name];

const selectors = adapter.getSelectors();

export const getShow = createSelector(slice, getEmail, (slice, email) => {
  const entry = selectors.selectById(slice, email);

  if (!entry) return true;

  const date = parseISO(entry.date);

  return !isToday(date);
});
