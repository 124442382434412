import styled from 'styled-components';

export const Obs = styled.div`
  min-width: 200px;
  font-size: 14px;
  background: white;
  box-shadow: 0 0 5px 0 rgba(16, 22, 31, 0.25);
  z-index: 1000;
  position: fixed;
`;
