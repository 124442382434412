import React, { ElementType } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import styles from './Action.module.css';

type ActionProps = {
  to?: string;
  dataId?: string;
  icon?: ElementType | null;
  label?: string;
  iconOnly?: boolean;
  visible?: boolean;
  href?: string;
};

const Action = ({
  dataId = '',
  icon: Icon = null,
  label = '',
  iconOnly = false,
  href = '',
  to = '',
  ...rest
}: ActionProps) => {
  const content = (
    <>
      <div className={styles.actionIcon}>{Icon && <Icon />}</div>
      <div className={styles.actionLabel}>{label}</div>
    </>
  );
  const sharedProps = {
    className: cx(styles.action, iconOnly && styles.iconOnly),
    'data-id': dataId,
  };

  if (href)
    return (
      <a rel="noopener noreferrer" target="_blank" href={href} {...sharedProps} {...rest}>
        {content}
      </a>
    );

  if (to)
    return (
      <Link to={to} {...sharedProps} {...rest}>
        {content}
      </Link>
    );

  return (
    <button {...sharedProps} {...rest}>
      {content}
    </button>
  );
};

export default Action;
