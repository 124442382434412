import React, { Component } from 'react';
import Select, { defaultArrowRenderer as arrowRenderer } from 'react-select';
import cx from 'classnames';
import { head } from 'lodash';
import PropTypes from 'prop-types';

import { noop, t } from 'shared/utils';
import device from 'shared/utils/device';
import NativeSelect from 'components/Form/NativeSelect/NativeSelect';
import InfoIcon from 'components/InfoIcon/InfoIcon';

import RequiredAddon from '../RequiredAddon/RequiredAddon';
import { optionWithKeyboardState } from './Option';

import 'react-select/dist/react-select.css';
import styles from './SelectField.module.css';

const Arrow = ({ hasTooltip, ...rest }) => {
  if (!hasTooltip) return arrowRenderer(rest);

  return <div className={styles.arrow}>{arrowRenderer(rest)}</div>;
};

Arrow.propTypes = {
  hasTooltip: PropTypes.bool,
};

/*  HACK: as for version 1.0.0-rc.3 react-select doesn't really support custom menuRenderer,
    it assumes the most outer element to be scrollable wrapper, which prevents from having static
    elements next to scrollable area. We are overriding two methods to make it possible for the
    menuRenderer to define which DOM node is the scrollable wrapper.
*/
class CustomSelect extends Select {
  shouldComponentUpdate(nextProps, nextState) {
    return !(nextProps.responsive && nextState.isOpen);
  }

  state = {
    inputValue: '',
    isFocused: false,
    isOpen: false,
    isPseudoFocused: false,
    required: false,
  };

  setMenuRef = (ref) => {
    this.menu = ref;
  };

  componentWillReceiveProps(nextProps) {
    const { hasValue, meta: { active, visited, initial } = {} } = nextProps;
    const { meta: { visited: wasVisited, initial: wasInitial } = {} } = this.props;

    if (this.props.preserveInput) {
      const reinitialized = (wasVisited && !visited) || wasInitial !== initial;

      this.setState({
        isFocused: active || hasValue || (reinitialized && this.state.isFocused),
        hasFocusHook: !active && hasValue,
      });
    }

    super.componentWillReceiveProps(nextProps);
  }

  renderMenu = (options, valueArray, focusedOption) => {
    if (options && options.length) {
      return this.props.menuRenderer(
        {
          focusedOption,
          focusOption: this.focusOption,
          instancePrefix: this._instancePrefix,
          labelKey: this.props.labelKey,
          onFocus: this.focusOption,
          onSelect: this.selectValue,
          optionClassName: this.props.optionClassName,
          optionComponent: optionWithKeyboardState(this.usingKeyboard),
          optionRenderer: this.props.optionRenderer || this.getOptionLabel,
          options,
          selectValue: this.selectValue,
          valueArray,
          valueKey: this.props.valueKey,
          onOptionRef: this.onOptionRef,
        },
        this.setMenuRef
      );
    } else if (this.props.noResultsText) {
      return <div className="Select-noresults">{this.props.noResultsText}</div>;
    }

    return null;
  };

  superhandleKeyDown(...args) {
    return super.handleKeyDown(...args);
  }

  handleKeyDown = (...args) => {
    this.usingKeyboard = true;

    return this.superhandleKeyDown(...args);
  };

  renderOuter = (options, valueArray, focusedOption) => {
    const menu = this.renderMenu(options, valueArray, focusedOption);

    if (!menu) {
      return null;
    }

    return (
      <div
        ref={(ref) => {
          this.menuContainer = ref;
        }}
        className="Select-menu-outer"
        style={this.props.menuContainerStyle}
        data-id="SelectMenu:listbox"
      >
        <div
          ref={(ref) => {
            if (!this.props.hasCustomMenuRef) this.setMenuRef(ref);
          }}
          role="listbox"
          className="Select-menu"
          id={`${this._instancePrefix}-list`} // eslint-disable-line
          style={this.props.menuStyle}
          onScroll={this.handleMenuScroll}
          onMouseDown={this.handleMouseDownOnMenu}
        >
          {menu}
        </div>
      </div>
    );
  };
}

class SelectField extends Component {
  static propTypes = {
    checker: PropTypes.func,
    className: PropTypes.string,
    selectClassName: PropTypes.string,
    clearable: PropTypes.bool,
    id: PropTypes.string,
    input: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    }),
    inputClassName: PropTypes.string,
    label: PropTypes.node,
    labelClassName: PropTypes.string,
    message: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    placeholder: PropTypes.node,
    required: PropTypes.bool,
    requiredClassName: PropTypes.string,
    uncontrolledInput: PropTypes.bool,
    hideOverflow: PropTypes.bool,
    searchable: PropTypes.bool,
    hasCustomMenuRef: PropTypes.bool,
    disableNative: PropTypes.bool,
    initialSearchValue: PropTypes.string,
    invalid: PropTypes.bool,
    preserveInput: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    options: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
        }),
        PropTypes.shape({}),
      ])
    ),
    dataId: PropTypes.string,
  };

  static defaultProps = {
    options: [],
    selectClassName: '',
    uncontrolledInput: false,
    placeholder: t('forms.placeholders.select'),
    initialSearchValue: '',
    hideOverflow: true,
    invalid: false,
  };

  constructor(props) {
    super(props);
    this.state = { hasValue: !!props.initialSearchValue, value: undefined };
  }

  componentWillReceiveProps(nextProps) {
    const { input, value, initialSearchValue } = this.props;
    const {
      input: nextInput,
      value: nextValue,
      initialSearchValue: nextInitialSearchValue,
    } = nextProps;
    const newValue = nextInput ? nextInput.value : nextValue;
    const oldValue = input ? input.value : value;

    if (nextInitialSearchValue && initialSearchValue !== nextInitialSearchValue) {
      this.instance.setState({ inputValue: nextInitialSearchValue });
      this.setState({ hasValue: true });
    }

    if (newValue === oldValue) return;
    this.setState({
      value: newValue,
    });
  }

  get value() {
    const { input, value } = this.props;
    const valueFromProps = input ? input.value : value;

    return this.state.value !== undefined ? this.state.value : valueFromProps;
  }

  handleChange = (...args) => {
    const {
      input: { onChange } = {},
      onChange: userOnChangeHandler,
      uncontrolledInput,
    } = this.props;

    if (uncontrolledInput) return;
    const { value = '' } = head(args) || {};

    if (userOnChangeHandler) {
      userOnChangeHandler(...args);
    } else {
      onChange(value);
    }
    this.setState({
      value,
    });
  };

  componentDidMount() {
    const { initialSearchValue } = this.props;
    // React-select does not provide API to set initial value for search value state
    if (initialSearchValue) {
      this.instance.setState({
        inputValue: initialSearchValue,
      });
    }
  }

  refHandler = (ref) => {
    // Since react-select v.1.0.0-beta closeMenu uses `this`, but doesn't bind the context automatically.
    this.closeMenu = ref ? ref.closeMenu.bind(ref) : noop;
    this.instance = ref;
  };

  closeMenu = () => {};

  inputChangeHandler = (value) => {
    const fallback = (val) => val;
    const onInputChange = this.props.onInputChange || fallback;

    this.setState({ hasValue: Boolean(value) });

    return onInputChange(value);
  };

  render() {
    const {
      checker = noop,
      className,
      selectClassName,
      clearable = false,
      id,
      input,
      inputClassName,
      label = '',
      labelClassName,
      placeholder,
      message = '',
      tooltipPlacement,
      required = false,
      requiredClassName,
      searchable = false,
      hasCustomMenuRef = false,
      value,
      disabled,
      disableNative,
      hideOverflow,
      invalid,
      preserveInput = false,
      dataId = '',
      ...rest
    } = this.props;
    const { hasValue, value: stateValue } = this.state;
    const isInvalid = checker(this.props) || invalid;
    const valueFromProps = input ? input.value : value;
    const hasTooltip = !!message;

    return (
      <div className={cx(styles.wrapper, className)} data-id={dataId || id || this.props.name}>
        <NativeSelect
          {...input}
          disabled={disabled}
          shown={device.touch && !disableNative}
          options={this.props.options}
          {...rest}
          value={this.value}
          onChange={this.handleChange}
        />
        <CustomSelect
          className={cx(styles.main, selectClassName, inputClassName, {
            warning: isInvalid,
            [styles.mainInvalid]: isInvalid,
            'Select-touch': device.touch && !disableNative,
          })}
          clearable={clearable}
          searchable={searchable}
          placeholder={placeholder}
          ref={this.refHandler}
          arrowRenderer={(props) => <Arrow hasTooltip={hasTooltip} {...props} />}
          disabled={disabled}
          autosize={!hideOverflow}
          {...input}
          {...rest}
          value={stateValue !== undefined ? stateValue : valueFromProps}
          onChange={this.handleChange}
          hasCustomMenuRef={hasCustomMenuRef}
          responsive={device.touch && !disableNative}
          onInputChange={this.inputChangeHandler}
          preserveInput={preserveInput}
          hasValue={hasValue}
          onBlur={() => input && input.onBlur(undefined)}
        />
        <label
          htmlFor={id}
          className={cx(styles.label, disabled && styles.labelDisabled, labelClassName)}
        >
          <span className={styles.labelContent}>
            {label}
            {required && <RequiredAddon className={requiredClassName} />}
          </span>
        </label>
        {hasTooltip && (
          <InfoIcon text={message} className={styles.tooltip} tooltipPlacement={tooltipPlacement} />
        )}
      </div>
    );
  }
}

export default SelectField;
