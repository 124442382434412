import styled, { css } from 'styled-components';

type WrapperProps = {
  $isActive?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  cursor: pointer;
  padding: ${({ theme }) => `${theme.space[4]} ${theme.space[4]} ${theme.space[1]}`};
  color: ${({ theme }) => theme.colors.grey['080']};
  border-bottom: 4px solid transparent;
  letter-spacing: 0.4px;
  line-height: 24px;
  white-space: nowrap;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.lightblue};
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.vrblue['025']};
  }

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      font-weight: bold;
      cursor: auto;
      color: ${theme.colors.vrblue['100']};
      border-color: ${theme.colors.vrblue['100']};

      &:hover,
      &:active {
        background: none;
      }
    `}
`;
