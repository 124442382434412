import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useCombobox, UseComboboxStateChange } from 'downshift';

import { t } from 'shared/utils';
import { CategoryResponse } from 'types/entities/Category';
import inputStyles from 'components/Form/TextField/TextField.module.css';
import IconLoading from 'components/Icons/IconLoading';

import {
  CloseIcon,
  Container,
  Hint,
  IconLamp,
  IconWrapper,
  LoadingWrapper,
  MainCategory,
  MatchingCategoriesHeader,
  Menu,
  SearchIcon,
  SubCategory,
} from './CategorySearch.styled';
import { stateReducer } from './utils';

type CategorySearchProps = {
  disabled?: boolean;
  placeholder?: string;
  categories: CategoryResponse[];
  onSelectCategory:
    | ((selectedItem: CategoryResponse | string | null | undefined) => void)
    | ((item: CategoryResponse) => void);
  onClearCategory?: () => void;
  invalid?: boolean;
  initialSelectedItem?: CategoryResponse;
  initialInputValue?: string;
  dataId?: string;
  selectedItem?: CategoryResponse;
  label?: string;
  hiddenLabel?: boolean;
  withIcon?: boolean;
  withHint?: boolean;
};

const CategorySearch = ({
  disabled,
  placeholder,
  categories,
  onSelectCategory,
  onClearCategory,
  invalid,
  initialSelectedItem,
  initialInputValue,
  selectedItem,
  label,
  dataId,
  hiddenLabel,
  withIcon,
  withHint = false,
}: CategorySearchProps) => {
  const [data, setData] = useState<CategoryResponse[]>([]);
  const [items, setItems] = useState<CategoryResponse[]>([]);
  const [value, setValue] = useState<string | undefined>('');
  const [hint, setHint] = useState<string | undefined>();
  const [item, setItem] = useState<CategoryResponse | undefined>();
  const [initialItem, setInitialItem] = useState<CategoryResponse | undefined>();

  const isFilled = !!value || !!item?.id || !!initialItem?.id;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSelectedItemChange = (props: UseComboboxStateChange<CategoryResponse> | null) => {
    const selectedItem = props?.selectedItem || ({} as CategoryResponse);
    onSelectCategory(selectedItem);
    setHint(selectedItem.hint);
    setValue(selectedItem?.name);
  };

  const handleInputValueChange = ({ inputValue }: UseComboboxStateChange<CategoryResponse>) => {
    setValue(inputValue);
    setItems(
      !inputValue
        ? categories
        : data
            .filter((item) => {
              return (
                item?.parentId === null ||
                item?.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
                item?.searchTerms?.some((item: any) =>
                  item.toLowerCase().includes(inputValue.toLocaleLowerCase())
                )
              );
            })
            .filter((item, index, array) => {
              if (item?.parentId === null) {
                const nextElement = array[index + 1];
                if (nextElement === undefined || nextElement?.parentId === null) return false;
              }

              return true;
            })
    );
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    openMenu,
    highlightedIndex,
    selectItem,
  } = useCombobox({
    items,
    stateReducer,
    initialSelectedItem,
    initialInputValue,
    selectedItem,
    itemToString: (item) => (item ? item.name : ''),
    onSelectedItemChange: handleSelectedItemChange,
    onInputValueChange: handleInputValueChange,
  });

  const clearInput = () => {
    setHint(undefined);
    handleSelectedItemChange(null);
    // @ts-expect-error
    selectItem(undefined);
    setItem(undefined);
    setInitialItem(undefined);
    onClearCategory && onClearCategory();
    inputRef.current?.focus();
    openMenu();
  };

  const setInitialData = () => {
    setData(categories);
    setItems(categories);
    setItem(selectedItem);
    setInitialItem(initialSelectedItem);
    setValue(initialInputValue);
  };

  useEffect(() => {
    if (categories.length) {
      setInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, selectedItem, initialInputValue]);

  return (
    <Container>
      <div className={cx(inputStyles.wrapper)} {...getComboboxProps()}>
        {withIcon && (
          <IconWrapper>
            {isFilled ? <CloseIcon onClick={() => clearInput()} /> : <SearchIcon />}
          </IconWrapper>
        )}

        <input
          {...getInputProps()}
          value={value}
          className={cx(inputStyles.main, {
            warning: invalid,
            [inputStyles.mainFilled]: isFilled,
            [inputStyles.mainInvalid]: invalid,
            [inputStyles.mainDisabled]: disabled,
            [inputStyles.withoutUnderlineWhenDisabled]: disabled,
            [inputStyles.mainHiddenLabel]: hiddenLabel,
          })}
          placeholder={placeholder}
          autoComplete="off"
          disabled={disabled}
          data-id={dataId}
          onClick={openMenu}
          ref={inputRef}
        />
        <label className={cx(inputStyles.label)}>
          <span
            className={cx(inputStyles.labelContent, {
              [inputStyles.labelContentEmpty]: !isFilled,
            })}
          >
            {label}
          </span>
        </label>
      </div>
      {hint && withHint && (
        <Hint>
          <div>
            <IconLamp />
          </div>
          <p>
            <span>{t('shared.hint')}: </span>
            {t('shared.hint_text')}
          </p>
        </Hint>
      )}
      <Menu {...getMenuProps()} data-id="category-search-menu">
        {isOpen && (
          <>
            <MatchingCategoriesHeader>{t('shared.matching_categories')}:</MatchingCategoriesHeader>
            {items.length ? (
              items.map((item, index) => {
                if (item?.parentId === null) {
                  return <MainCategory key={item?.id}>{item?.name}</MainCategory>;
                }

                return (
                  <SubCategory
                    key={item?.id}
                    highlighted={highlightedIndex === index}
                    {...getItemProps({ index, item })}
                    data-id={`CategorySearch:Option-${item?.name}`}
                  >
                    {item?.name}
                  </SubCategory>
                );
              })
            ) : (
              <>
                {categories.length ? (
                  <SubCategory>{t('shared.no_matching_categorie')}</SubCategory>
                ) : (
                  <LoadingWrapper>
                    <IconLoading width="16" height="16" />
                  </LoadingWrapper>
                )}
              </>
            )}
          </>
        )}
      </Menu>
    </Container>
  );
};

export default CategorySearch;
