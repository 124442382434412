import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { get } from 'lodash';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import {
  createBankTransferConnection,
  updateBankTransferConnection,
} from 'actions/bank-transfer-connections';
import {
  deleteMaybeSuggestion as deleteIncomingInvoiceSuggestion,
  fetchIncomingInvoice,
} from 'actions/incoming-invoice';
import { showNotification } from 'actions/notification';
import {
  deleteMaybeSuggestion as deleteOutgoingInvoiceSuggestion,
  fetchOutgoingInvoice,
} from 'actions/outgoing-invoice';
import { OPTION_FULLY_PAID, OPTION_JOKER, OPTION_PARTLY_PAID } from 'constants/assigment-select';
import { INCOMING_INVOICE, OUTGOING_INVOICE } from 'constants/folder-names';
import {
  PIWIK_ACTION_ASSIGN_BANK_TRANSFER,
  PIWIK_ACTION_MARK_INVOICE_FULLY_PAID,
  PIWIK_CATEGORY_EXPENSES,
  PIWIK_CATEGORY_REVENUE,
  PIWIK_CONVERSION_ASSIGN_BANK_TRANSFER_REVENUE,
} from 'constants/piwik';
import { connectionCreateFailure } from 'notifications/bank-transfer-connections';
import { formatDate, formatMoney, parseStringToFloat, t } from 'shared/utils';
import getConnectionError from 'shared/utils/get-connection-error';
import getSelectOptionByValue from 'shared/utils/get-select-option-by-value';
import { getAbsoluteValue } from 'shared/utils/number/number';
import { piwikHelpers } from 'shared/utils/piwik';
import { ResourceType } from 'types/entities/AssignableResource';
import JokerModal from 'components/Assigment/JokerModal';
import AssigmentSelect from 'components/AssigmentSelect/AssigmentSelect';
import bankTransferStyles from 'components/BankTransfer/BankTransfer.module.css';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';

import localStyles from './AssignmentMode.module.css';

// Default state for new bank transfer assignment
const DEFAULT_STATE = {
  assigmentInputValue: '',
  assigmentInputLabel: OPTION_FULLY_PAID.inputLabelTranslateKey
    ? t(OPTION_FULLY_PAID.inputLabelTranslateKey)
    : '',
  assigmentInputPlaceholder: OPTION_FULLY_PAID.inputPlaceholderTranslateKey
    ? t(OPTION_FULLY_PAID.inputPlaceholderTranslateKey)
    : '',
  assignmentInputVisible: false,
  isJokerModalOpen: false,
  jokerInputValue: '',
  assignmentSelectedOption: OPTION_FULLY_PAID,
  inputInvalid: false,
  initialSelectedItem: {
    ...OPTION_FULLY_PAID,
    label: t(OPTION_FULLY_PAID.translateKey),
  },
  bankTransferId: '',
};

// Get state for bank transfer assignment in edit mode
const getEditState = (props) => {
  const { transferConnection } = props;

  const initialSelected = getSelectOptionByValue(transferConnection.selectedOption);

  return {
    ...DEFAULT_STATE,
    initialSelectedItem: {
      ...initialSelected,
      label: t(initialSelected.translateKey),
    },
    assignmentSelectedOption: {
      ...initialSelected,
      label: t(initialSelected.translateKey),
    },
    assigmentInputLabel: initialSelected.inputLabelTranslateKey
      ? t(initialSelected.inputLabelTranslateKey)
      : '',
    assigmentInputPlaceholder: initialSelected.inputPlaceholderTranslateKey
      ? t(initialSelected.inputPlaceholderTranslateKey)
      : '',
    assignmentInputVisible: initialSelected.isInputVisible,
    assigmentInputValue: transferConnection.amountAssigned,
  };
};

class BankTransferAssignmentMode extends Component {
  state = !this.props.isEditMode ? DEFAULT_STATE : getEditState(this.props);

  static getDerivedStateFromProps(props, prevState) {
    const { bankTransferId: prevId } = prevState;
    const { id, isEditMode } = props;

    // If Assignment Mode received new bank transfer (detected by id)
    // Then reinitialize default state
    if (prevId !== id) {
      const initialState = !isEditMode ? DEFAULT_STATE : getEditState(props);

      return {
        ...initialState,
        bankTransferId: id,
      };
    }

    return null;
  }

  showJokerModal = () => {
    this.setState({
      isJokerModalOpen: true,
    });
  };

  hideJokerModal = () => {
    this.setState({
      jokerInputValue: '',
      isJokerModalOpen: false,
    });
  };

  getInputPrefillValue = (option) => {
    const { amountLeft } = this.props;
    const { value: assignmentValue } = option;

    if (assignmentValue === OPTION_PARTLY_PAID.value || assignmentValue === OPTION_JOKER.value) {
      return getAbsoluteValue(amountLeft);
    }

    return '';
  };

  handleOptionChange = (option) => {
    this.setState({
      assigmentInputLabel: option.inputLabelTranslateKey ? t(option.inputLabelTranslateKey) : '',
      assigmentInputPlaceholder: option.inputPlaceholderTranslateKey
        ? t(option.inputPlaceholderTranslateKey)
        : '',
      assignmentInputVisible: option.isInputVisible,
      assignmentSelectedOption: option,
      inputInvalid: false,
      assigmentInputValue: this.getInputPrefillValue(option),
    });
  };

  handleAmountInputChange = (event) =>
    this.setState({ assigmentInputValue: event.target.value, inputInvalid: false });

  handleJokerInputChange = (event) => this.setState({ jokerInputValue: event.target.value });

  handleAssigmentAccept = async () => {
    const {
      id,
      invoiceDetails,
      whichFolder,
      createConnection,
      updateConnection,
      onClose,
      isEditMode,
      validationErrorNotification,
    } = this.props;

    const {
      assignmentInputVisible,
      assigmentInputValue,
      jokerInputValue,
      assignmentSelectedOption,
      isJokerModalOpen,
    } = this.state;

    if (assignmentInputVisible && assigmentInputValue === '') {
      const errorMessage =
        assignmentSelectedOption.value === OPTION_JOKER.value
          ? t('bank_transfers.assigment.input_empty_joker')
          : t('bank_transfers.assigment.input_empty');

      validationErrorNotification({
        ...connectionCreateFailure,
        title: errorMessage,
      });
      return this.setState({
        inputInvalid: true,
      });
    }

    if (assignmentSelectedOption.value === OPTION_JOKER.value && !isJokerModalOpen) {
      return this.showJokerModal();
    }

    const deleteSuggestionFunction =
      whichFolder === OUTGOING_INVOICE
        ? deleteOutgoingInvoiceSuggestion
        : deleteIncomingInvoiceSuggestion;

    const actionToPerform = isEditMode ? updateConnection : createConnection;

    try {
      await actionToPerform(
        invoiceDetails.id,
        id,
        deleteSuggestionFunction,
        assignmentSelectedOption.value,
        parseStringToFloat(assigmentInputValue),
        jokerInputValue
      );
    } catch (error) {
      const errorMessage = getConnectionError(error);
      validationErrorNotification(errorMessage);
      return this.setState({ inputInvalid: true });
    }

    return onClose();
  };

  render() {
    const {
      assigmentInputValue,
      assigmentInputLabel,
      assigmentInputPlaceholder,
      isJokerModalOpen,
      jokerInputValue,
      assignmentInputVisible,
      inputInvalid,
      initialSelectedItem,
      assignmentSelectedOption,
      bankTransferId,
    } = this.state;
    const {
      date,
      creditorName,
      amount,
      purpose,
      onClose,
      selectOptions,
      invoiceDetails,
      invoiceDetails: { discountGrossValue },
    } = this.props;
    const creditNote = get(invoiceDetails, 'attributes.creditNote', false);
    const fullyPaidOptions =
      amount < 0
        ? selectOptions.filter((option) => option.value === OPTION_FULLY_PAID.value)
        : selectOptions;
    return (
      <div className={localStyles.wrapper}>
        <div className={localStyles.header}>
          {t('bank_transfers.assigment.assigment_mode_title')}
        </div>
        <div className={bankTransferStyles.assigmentMode}>
          <div className={bankTransferStyles.detailsSection}>
            <div className={bankTransferStyles.suggestedDate} data-id="assigned-transfer-date">
              {formatDate(date)}
            </div>
            <div className={bankTransferStyles.title}>
              <CellContentWithTooltip>{purpose}</CellContentWithTooltip>
            </div>
            <div className={bankTransferStyles.suggestedCreditor}>
              <CellContentWithTooltip>{creditorName}</CellContentWithTooltip>
            </div>
          </div>
          <div className={bankTransferStyles.selectSection}>
            <AssigmentSelect
              inputLabel={assigmentInputLabel}
              inputPlaceholder={assigmentInputPlaceholder}
              inputVisible={assignmentInputVisible}
              inputValue={assigmentInputValue}
              inputInvalid={inputInvalid}
              onInputChange={this.handleAmountInputChange}
              initiallySelected={initialSelectedItem}
              onSelect={this.handleOptionChange}
              selectOptions={creditNote ? fullyPaidOptions : selectOptions}
              discountGrossValue={discountGrossValue}
              containerClassName={localStyles.selectContainer}
              selectedValue={assignmentSelectedOption.value}
              id={bankTransferId}
            />
            <div
              className={cx(bankTransferStyles.suggestedAmount, localStyles.assignmentAmount, {
                [bankTransferStyles.amountNegative]: amount < 0,
              })}
            >
              {formatMoney(amount)}
            </div>
          </div>
          <div className={bankTransferStyles.actionSection}>
            <div className={bankTransferStyles.actionButtons}>
              <button
                type="button"
                className={bankTransferStyles.accept}
                data-id="accept-assignment"
                onClick={this.handleAssigmentAccept}
                title={t('bank_transfers.select.actions.accept_suggestion')}
              />
              <button
                type="button"
                className={bankTransferStyles.cancel}
                onClick={onClose}
                title={t('bank_transfers.select.actions.reject_suggestion')}
              />
            </div>
          </div>
        </div>
        <JokerModal
          isOpen={isJokerModalOpen}
          onClose={this.hideJokerModal}
          onAccept={this.handleAssigmentAccept}
          value={jokerInputValue}
          onChange={this.handleJokerInputChange}
        />
      </div>
    );
  }
}

BankTransferAssignmentMode.propTypes = {
  date: string.isRequired,
  id: string.isRequired,
  creditorName: string.isRequired,
  amount: number.isRequired,
  amountLeft: number.isRequired,
  purpose: string,
  onClose: func.isRequired,
  whichFolder: string.isRequired,
  createConnection: func.isRequired,
  updateConnection: func.isRequired,
  selectOptions: arrayOf(shape({})).isRequired,
  invoiceDetails: shape({}).isRequired,
  isEditMode: bool.isRequired,
  transferConnection: shape({}), // eslint-disable-line
  validationErrorNotification: func,
};

const trackEvents = (whichFolder) => {
  if (whichFolder === OUTGOING_INVOICE) {
    piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_ASSIGN_BANK_TRANSFER);
    piwikHelpers.trackGoal(PIWIK_CONVERSION_ASSIGN_BANK_TRANSFER_REVENUE);
  } else if (whichFolder === INCOMING_INVOICE) {
    piwikHelpers.trackEvent(PIWIK_CATEGORY_EXPENSES, PIWIK_ACTION_ASSIGN_BANK_TRANSFER);
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { whichFolder } = ownProps;
  const refreshInvoiceAction =
    whichFolder === OUTGOING_INVOICE ? fetchOutgoingInvoice : fetchIncomingInvoice;
  const resourceType =
    whichFolder === OUTGOING_INVOICE ? ResourceType.OutgoingInvoice : ResourceType.IncomingInvoice;

  return {
    validationErrorNotification: (message) => dispatch(showNotification(message)),
    createConnection: (
      invoiceId,
      bankTransferId,
      deleteSuggestion,
      assigmentOption,
      assignedValue,
      jokerOptionReason
    ) =>
      dispatch(
        createBankTransferConnection(
          invoiceId,
          resourceType,
          bankTransferId,
          assigmentOption,
          assignedValue,
          jokerOptionReason
        )
      ).then(() => {
        dispatch(deleteSuggestion({ bankTransferId }));

        dispatch(refreshInvoiceAction(invoiceId)).then(({ data }) => {
          // if the invoice has been marked as paid
          if (!ownProps.invoiceDetails.paid && data.attributes.paid) {
            // track marked as paid event
            piwikHelpers.trackEvent(PIWIK_CATEGORY_EXPENSES, PIWIK_ACTION_MARK_INVOICE_FULLY_PAID);
          }
        });

        // track bank transfer assignment events
        trackEvents(whichFolder);
      }),
    updateConnection: (
      invoiceId,
      bankTransferId,
      deleteSuggestion,
      assigmentOption,
      assignedValue,
      jokerOptionReason
    ) =>
      dispatch(
        updateBankTransferConnection(
          invoiceId,
          resourceType,
          bankTransferId,
          assigmentOption,
          assignedValue,
          jokerOptionReason
        )
      ).then(() => {
        dispatch(deleteSuggestion({ bankTransferId }));
        dispatch(refreshInvoiceAction(invoiceId)).then(({ data }) => {
          // if the invoice has been marked as paid
          if (!ownProps.invoiceDetails.paid && data.attributes.paid) {
            // track marked as paid event
            piwikHelpers.trackEvent(PIWIK_CATEGORY_EXPENSES, PIWIK_ACTION_MARK_INVOICE_FULLY_PAID);
          }
        });

        // track bank transfer assignment events
        trackEvents(whichFolder);
      }),
  };
};

export default connect(null, mapDispatchToProps)(BankTransferAssignmentMode);
