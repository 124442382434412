import React from 'react';
import cx from 'classnames';
import { compact, values } from 'lodash';
import { func, string } from 'prop-types';

import { noop, t } from 'shared/utils';
import Button from 'components/Button';
import If from 'components/Conditions/If';

import { SearchNavigationContext } from './SearchNavigationContext';

import styles from './SearchNavigation.module.css';

/**
 * Is responsible for displaying of buttons.
 */
const SearchNavigationButtons = ({
  buttonsContainerClass = '',
  onShowClick = noop,
  onResetClick = noop,
  onHideClick = noop,
}) => (
  <SearchNavigationContext.Consumer>
    {({ filters, clearFilters, isSearchOpen, setIsSearchOpen }) => (
      <div className={cx(styles.buttonsContainer, buttonsContainerClass)}>
        <Button
          className={styles.button}
          label={t(`tables.search.${isSearchOpen ? 'hide' : 'show'}`)}
          onClick={() => {
            setIsSearchOpen((isOpen) => !isOpen);

            if (isSearchOpen) onHideClick();
            else onShowClick();
          }}
          dataId="SearchNavigation:filterButton"
        />
        <If ok={isSearchOpen}>
          <Button
            className={styles.button}
            label={t('tables.search.reset')}
            onClick={() => {
              clearFilters();
              onResetClick();
            }}
            dataId="SearchNavigation:resetButton"
            disabled={compact(values(filters)).length === 0}
          />
        </If>
      </div>
    )}
  </SearchNavigationContext.Consumer>
);

SearchNavigationButtons.propTypes = {
  buttonsContainerClass: string,
  onShowClick: func,
  onResetClick: func,
  onHideClick: func,
};

SearchNavigationButtons.defaultProps = {
  buttonsContainerClass: '',
};

export default SearchNavigationButtons;
