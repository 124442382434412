import { initReactI18next } from 'react-i18next';
import translationsDe from 'config/locales/frontend.de.json';
import i18n from 'i18next';
// Use this when we finally integrate Phrase.com in-context editor
// import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';

// Use this if we ever support a language other than German
// const locale = navigator.language;
const locale = 'de';

i18n
  .use(initReactI18next)
  // Use this when we finally integrate Phrase.com in-context editor
  // .use(
  //   new PhraseInContextEditorPostProcessor({
  //     phraseEnabled: true,
  //     projectId: '843134b88e3476517b765719ec6850d0',
  //   })
  // )
  .init({
    resources: {
      de: { translation: translationsDe },
      en: { translation: translationsDe },
    },
    lng: 'de',
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false,
    },
    postProcess: ['phraseInContextEditor'],
    missingKeyHandler: (lng, _ns, key) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log(`⛔️ Missing translation key: ${key} in language ${lng}`);
      }
    },
  });

i18n.translate = i18n.t;
i18n.toPercentage = (num) => {
  // TODO: to avoid breaking tests, we're using a point as decimal separator
  if (num < 100) {
    return `${new Intl.NumberFormat('en-us', { minimumFractionDigits: 1 }).format(num)}%`;
  }

  return `${num}%`;
};

i18n.toNumber = (num) => {
  return Intl.NumberFormat(locale).format(num);
};

i18n.l = (_string, amount, options = {}) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: options.strip_insignificant_zeros ? 0 : 2,
  });

  return formatter.format(amount).replace(/ /g, ' ');
};

i18n.toCurrency = (amount, options) => {
  return i18n.l('currency', amount, options);
};

// legacy hack for i18n-js
window.I18n = {};

export default i18n;
