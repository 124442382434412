import React from 'react';
import { connect } from 'react-redux';
import { bool, func, shape, string } from 'prop-types';

import {
  downloadOutgoingInvoicePreview,
  getOutgoingInvoicePreview,
} from 'actions/outgoing-invoice';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import PreviewModalComponent from 'components/PreviewModal';

const PreviewModal = ({
  invoiceId,
  invoiceValues,
  isOpen,
  onClose,
  downloadPreview,
  getPreview,
}) => {
  const safeInvoiceValues = {
    ...invoiceValues,
    watermark: true,
  };

  const download = () => downloadPreview(invoiceId, safeInvoiceValues);

  return (
    <PreviewModalComponent
      title={t('modals.preview_outgoing_invoice.title')}
      getPreview={() => getPreview(invoiceId, safeInvoiceValues)}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ActionButton appearance="primary" onClick={download} label={t('revenue.form.download')} />
    </PreviewModalComponent>
  );
};

PreviewModal.propTypes = {
  invoiceId: string.isRequired,
  invoiceValues: shape({}).isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  downloadPreview: func.isRequired,
  getPreview: func.isRequired,
};

const mapStateToProps = (state) => ({
  invoiceId: state.outgoingInvoice.details.id,
  invoiceValues: state.form.outgoingInvoiceCreator.values,
});

export default connect(mapStateToProps, {
  downloadPreview: downloadOutgoingInvoicePreview,
  getPreview: getOutgoingInvoicePreview,
})(PreviewModal);
