import React from 'react';
import { bool, func } from 'prop-types';

import { MODAL_HEADER_VARIANT } from 'constants/modal';
import { t } from 'shared/utils';
import { ConfirmationModal } from 'components/Modal';

const HideFooterModal = ({ isOpen, onClose, onConfirm }) => (
  <ConfirmationModal
    headerVariant={MODAL_HEADER_VARIANT.SMALL}
    dangerousAction
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    closeLabel={t('invoice_templates.hide_footer_modal.abort')}
    confirmLabel={t('invoice_templates.hide_footer_modal.accept')}
    header={t('invoice_templates.hide_footer_modal.header')}
    hasWarningIcon
    withCloseButton
    dataIds={{
      modal: 'HideFooterModal:modal',
      abortButton: 'HideFooterModal:abortButton',
      acceptButton: 'HideFooterModal:acceptButton',
    }}
  >
    {t('invoice_templates.hide_footer_modal.content')}
  </ConfirmationModal>
);

HideFooterModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
};

export default HideFooterModal;
