import React, { memo, SVGProps } from 'react';

const Cashbooks = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path
        d="M18.059 1c.52 0 .941.427.941.955v9.233a6.474 6.474 0 00-1.883-.575V2.909h-5.642V9a.475.475 0 01-.748.389l-.063-.053L9 7.67 7.336 9.336a.475.475 0 01-.804-.254L6.525 9V2.909H4.882V20.09h5.34A6.698 6.698 0 0011.568 22H3.94A.948.948 0 013 21.044V1.955C3 1.427 3.421 1 3.941 1H18.06z"
        fillRule="nonzero"
      />
      <path d="M16.5 12a5.5 5.5 0 110 11 5.5 5.5 0 010-11zm.753 2.115c-1.383 0-2.564.877-3.052 2.116h-.36l-.076.007a.435.435 0 00-.347.431c0 .242.189.439.423.439h.142a3.514 3.514 0 00.004.816h-.146l-.076.006a.435.435 0 00-.347.432c0 .242.189.438.423.438h.372c.495 1.223 1.668 2.085 3.04 2.085.555 0 1.091-.142 1.569-.41a.423.423 0 10-.413-.738 2.36 2.36 0 01-1.156.301c-.891 0-1.673-.495-2.1-1.237l2.314-.001.076-.007a.435.435 0 00.347-.431.431.431 0 00-.423-.439h-2.626a2.653 2.653 0 01-.004-.816h2.63l.076-.006a.435.435 0 00.347-.432.431.431 0 00-.423-.438h-2.332c.423-.76 1.214-1.27 2.118-1.27.365 0 .719.084 1.042.242a.423.423 0 10.373-.76 3.205 3.205 0 00-1.415-.328z" />
    </g>
  </svg>
);

export default memo(Cashbooks);
