import React from 'react';

import styles from './FormLayout.module.css';

interface FormColumnsProps {
  children: React.ReactNode;
}

const FormColumns = ({ children }: FormColumnsProps) => (
  <div className={styles.columns}>{children}</div>
);

export default FormColumns;
