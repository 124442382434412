import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';
import { bool, func, number, string } from 'prop-types';

import {
  markAsRead as markAsReadAction,
  setActiveMessage as setActiveMessageAction,
} from 'actions/message-inbox';
import { DATE_FORMAT } from 'constants/datetime';
import isPressedEnter from 'shared/utils/keyboard-events';

import ActionsDropdown from './ActionsDropdown/ActionsDropdown';
import ReadMore from './ReadMore/ReadMore';

import messageNamespaceStyles from '../MessageNamespaceStyles.module.css';
import styles from './MessageTile.module.css';

const MessageTile = ({
  id,
  markedAsRead,
  releaseDate,
  category,
  headline,
  teaser,
  content,
  activeMessageId,
  setActiveMessage,
  markAsRead,
}) => {
  const isMessageActive = id === activeMessageId;

  const handleClick = () => {
    if (!markedAsRead) markAsRead(id);
    if (!content) return;
    setActiveMessage(id);
  };
  const formattedReleaseDate = moment(releaseDate).format(DATE_FORMAT);

  return (
    <div
      className={cx(styles.messageWrapper, messageNamespaceStyles.messageNamespace, {
        [styles.active]: isMessageActive,
      })}
    >
      <div>
        <span className={styles.releaseDate}>{formattedReleaseDate}</span> <span>{category}</span>
      </div>
      <ActionsDropdown
        markedAsRead={markedAsRead}
        isMessageActive={isMessageActive}
        messageId={id}
      />
      <h1>
        <span role="button" onClick={handleClick} onKeyPress={isPressedEnter(handleClick)}>
          {headline}
        </span>
      </h1>
      <div dangerouslySetInnerHTML={{ __html: teaser }}></div>
      {content ? <ReadMore isMessageActive={isMessageActive} onClick={handleClick} /> : null}
    </div>
  );
};

MessageTile.propTypes = {
  id: number.isRequired,
  markedAsRead: bool.isRequired,
  releaseDate: string.isRequired,
  category: string.isRequired,
  headline: string.isRequired,
  teaser: string.isRequired,
  content: string,
  setActiveMessage: func.isRequired,
  activeMessageId: number,
  markAsRead: func.isRequired,
};

const mapDispatchToProps = {
  markAsRead: markAsReadAction,
  setActiveMessage: setActiveMessageAction,
};

export default connect(null, mapDispatchToProps)(MessageTile);
