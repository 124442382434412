import { actionTypes } from 'redux-form';

import {
  OCR_MAX_FILES_COUNT as ocrMaxFilesCount,
  OCR_MAX_FILES_SIZE as ocrMaxFilesSize,
} from 'constants/common/ocr';
import { DESTROY_FORM, UPDATE_SUCCESS } from 'constants/outgoing-invoice';
import { DEFAULT_UPLOAD_INFO as defaultUploadInfo } from 'reducers/common/uploaded-files';
import fetchingReducer from 'reducers/utils/fetching';
import needsAnotherDatev from 'reducers/utils/needs-another-datev-report';

import bankTransfers from './bank-transfers';
import client, { persistedClient } from './client';
import details from './details';
import lineCategories from './line-categories';
import lineItems from './line-items';
import ocr from './ocr';
import paymentReminders from './payment-reminders';
import sums from './sums';
import uploadedFiles from './uploaded-files';

const needsDatevReducer = needsAnotherDatev(UPDATE_SUCCESS);

const outgoingInvoice = (state = {}, action) => ({
  ...fetchingReducer(state, action),
  bankTransfers: bankTransfers(state.bankTransfers, action),
  client: client(state.client, action),
  details: details(state.details, action),
  paymentReminders: paymentReminders(state.paymentReminders, action),
  lineCategories: lineCategories(state.lineCategories, action),
  lineItems: lineItems(state.lineItems, action),
  persistedClient: persistedClient(state.persistedClient, action),
  postCreateStatus: needsDatevReducer(state.postCreateStatus, action),
  sums: sums(state.sums, action),
  uploadedFiles: uploadedFiles(state.uploadedFiles, action),
  ocr: ocr(state.ocr, action),
});

export const OCR_MAX_FILES_COUNT = ocrMaxFilesCount;
export const OCR_MAX_FILES_SIZE = ocrMaxFilesSize;

export const DEFAULT_UPLOAD_INFO = defaultUploadInfo;

export default (state, action) => {
  const isOutgoingInvoiceCreatorDestroyed =
    (action.type === actionTypes.DESTROY && action.meta.form.includes('outgoingInvoiceCreator')) ||
    action.type === DESTROY_FORM;
  if (isOutgoingInvoiceCreatorDestroyed) {
    /* Clean all state on form quit */
    return { ...outgoingInvoice(undefined, action) };
  }

  return outgoingInvoice(state, action);
};
