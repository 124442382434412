import React from 'react';

import CashTransactions from './CashTransactions/CashTransactions';
import EmptyPage from './EmptyPage/EmptyPage';
import { useHasAnyCashbooks } from './hooks';

const Cashbooks = () => {
  const { isFetching, hasAnyCashbooks } = useHasAnyCashbooks();

  if (isFetching) return null;

  if (hasAnyCashbooks) {
    return <CashTransactions />;
  }

  return <EmptyPage />;
};

export default Cashbooks;
