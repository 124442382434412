import { useEffect, useState } from 'react';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { getBankAccounts } from 'api/me/bankAccounts';
import EntityPath from 'constants/entitiesPaths';
import { BankAccount } from 'types/entities/BankAccount';

export const useFetchBankAccounts = (reloadCounter: number) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Array<BankAccount>>([]);

  useEffect(() => {
    const fetch = async () => {
      let responseBankAccounts;

      setIsLoading(true);

      try {
        responseBankAccounts = await getBankAccounts();
      } catch (e) {
        setIsLoading(false);
        return;
      }
      const data =
        build<BankAccount>(normalize(responseBankAccounts.data), EntityPath.BankAccounts) || [];

      setData(data);
      setIsLoading(false);
    };

    fetch();
  }, [reloadCounter]);

  return { isLoading, data };
};
