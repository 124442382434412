import React, { memo, SVGProps } from 'react';

const EyeOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none">
      <path d="M0 .507h24v24H0z" />
      <path
        d="M12.167 6.507C8 6.507 4.442 8.962 3 12.428c1.442 3.466 5 5.921 9.167 5.921 4.166 0 7.725-2.455 9.166-5.921-1.441-3.466-5-5.921-9.166-5.921Zm0 9.868c-2.3 0-4.167-1.768-4.167-3.947 0-2.179 1.867-3.947 4.167-3.947s4.166 1.768 4.166 3.947c0 2.179-1.866 3.947-4.166 3.947Zm0-6.315c-1.384 0-2.5 1.058-2.5 2.368 0 1.31 1.116 2.368 2.5 2.368 1.383 0 2.5-1.057 2.5-2.368 0-1.31-1.117-2.368-2.5-2.368Z"
        fill="#0066B3"
      />
    </g>
  </svg>
);

export default memo(EyeOpen);
