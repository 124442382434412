import React, { Fragment } from 'react';

import { t } from 'shared/utils';

import styles from './DebitInfo.module.css';

const DebitInfo = () => (
  <Fragment>
    <span className={styles.boldTitle}>
      {t('profile.payment_plan.direct_debit.sepa_debit_mandate')}
    </span>
    <p>{t('profile.payment_plan.direct_debit.sepa_text')}</p>
    <p>{t('profile.payment_plan.direct_debit.period_text')}</p>
    <p>{t('profile.payment_plan.direct_debit.creditor_text')}</p>
  </Fragment>
);

export default DebitInfo;
