import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import { fetchNewCancelledOrderConfirmation as fetchNewCancelledOrderConfirmationAction } from 'actions/order-confirmation';
import { CANCEL_MODE } from 'constants/common/crud';
import { FORM_NAME } from 'constants/order-confirmation';
import { withTransitionPrevent, withWarningAlert } from 'shared/hoc';
import { t } from 'shared/utils';
import useLineItemsControl, { SectionType } from 'components/LineItems/useLineItemControl';
import LoadingIcon from 'components/LoadingIcon';

import OrderConfirmationCreator from '../OrderConfirmationCreator/OrderConfirmationCreator';

const OrderConfirmationCancel = ({
  fetchCancelledOrderConfirmation,
  isFetching,
  isFetched,
  orderConfirmationId,
}) => {
  const { category, taxRates, taxRateId, defaultVat, hasSavedLineItems } = useLineItemsControl({
    sectionType: SectionType.orderConfirmation,
    formName: FORM_NAME,
  });

  useEffect(() => {
    fetchCancelledOrderConfirmation(orderConfirmationId);
  }, []);

  if (isFetching) return <LoadingIcon />;
  if (!isFetched) return null;

  return (
    <OrderConfirmationCreator
      crudMode={CANCEL_MODE}
      category={category}
      taxrates={taxRates}
      taxRateId={taxRateId}
      defaultVat={defaultVat}
      canSubmit={true}
      hasSavedLineItems={hasSavedLineItems}
    />
  );
};

const mapStateToProps = (state, ownProps) => ({
  isFetching: state.orderConfirmation.isFetching,
  isFetched: state.orderConfirmation.isFetched,
  orderConfirmationId: ownProps.match.params.id,
  buttonLabel: t(
    'empty_entry_pages.order_confirmations.order_confirmation_warning_box.button_label'
  ),
  content: t('empty_entry_pages.order_confirmations.order_confirmation_warning_box.content_1'),
  dataId: 'OrderConfirmations:orange-warning-container',
});

const mapDispatchToProps = (dispatch) => ({
  fetchCancelledOrderConfirmation: (id) => dispatch(fetchNewCancelledOrderConfirmationAction(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    persistentSubmitErrors: true,
  }),
  withTransitionPrevent(),
  withWarningAlert
)(OrderConfirmationCancel);
