import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { fetchCompany } from 'actions/company';
import {
  abortChanges,
  downloadOutgoingInvoice,
  fetchNewOutgoingCancellationInvoice,
  sendOutgoingInvoice,
  updateOutgoingInvoice,
} from 'actions/outgoing-invoice';
import { CANCEL_MODE } from 'constants/common/crud';
import { FORM_NAME } from 'constants/outgoing-invoice';
import { PIWIK_ACTION_SAVE_CANCELL_INVOICE, PIWIK_CATEGORY_REVENUE } from 'constants/piwik';
import { outgoingInvoiceSelector } from 'reducers/form';
import { eInvoicesEnabledHelper } from 'routes/accesses';
import { withWarningAlert } from 'shared/hoc';
import { t } from 'shared/utils';
import getDateXDaysFromNow from 'shared/utils/getDateXDaysFromNow';
import { piwikHelpers } from 'shared/utils/piwik';
import { LineItemsAutoSaveProvider } from 'components/LineItems';
import useLineItemsControl, { SectionType } from 'components/LineItems/useLineItemControl';

import CreateInvoiceSection from '../OutgoingInvoiceCreator/CreateInvoiceSection/CreateInvoiceSection';
import ElectronicInvoiceInfo from '../OutgoingInvoiceCreator/CreateInvoiceSection/ElectronicInvoiceInfo/ElectronicInvoiceInfo';
import Notes from '../OutgoingInvoiceCreator/CreateInvoiceSection/Notes/Notes';
import IndividualContactSection from '../OutgoingInvoiceCreator/IndividualContactSection';
import InvoiceDetailsSection from '../OutgoingInvoiceCreator/InvoiceDetailsSection';
import InvoiceSumSection from '../OutgoingInvoiceCreator/InvoiceSumSection';
import ActionsSection from './ActionsSection';

import styles from './OutgoingInvoiceCancel.module.css';

const isReadonly = () => true;

const OutgoingInvoiceCancel = ({
  fetchNewOutgoingCancellationInvoice,
  fetchCompany,
  invoiceDetails,
  downloadInvoice,
  abort,
  invoiceId,
  submitting,
  saveInvoice,
  handleSubmit,
  currentClient,
  paymentPlan,
  eInvoicesEnabled,
  electronicInvoiceType,
  sendingDocumentsByEmailEnabled = false,
  sendInvoice,
}) => {
  const {
    manualHandleSelectCategory,
    setLineItemState,
    setShowCategoryChangeConfirmation,
    setShowVatIdWarning,
    showCategoryChangeConfirmation,
    defaultCategory,
    canSubmit,
    showVatIdWarning,
    category,
    taxRates,
    taxRateId,
    defaultVat,
    hasSavedLineItems,
    selectedCategory,
    lineCategories,
    isInitial,
  } = useLineItemsControl({ sectionType: SectionType.outgoingInvoice, formName: FORM_NAME });

  const downloadAndTrack = async () => {
    await downloadInvoice({ details: invoiceDetails });

    piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_SAVE_CANCELL_INVOICE);
  };

  const sendAndTrack = async ({ email, ccEmail }) => {
    await sendInvoice({ id: invoiceId, email, ccEmail });

    piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_SAVE_CANCELL_INVOICE);
  };

  useEffect(() => {
    fetchNewOutgoingCancellationInvoice(invoiceId);
    fetchCompany();
  }, []);

  return (
    <form>
      <LineItemsAutoSaveProvider>
        <div className={styles.main}>
          <div className={cx(styles.section, styles.creator)}>
            <CreateInvoiceSection
              isReadonly={isReadonly}
              lineCategories={lineCategories}
              crudMode={CANCEL_MODE}
              category={category}
              selectedCategory={selectedCategory}
              handleSelectCategory={manualHandleSelectCategory}
              defaultCategory={defaultCategory}
              hasSavedLineItems={hasSavedLineItems}
              showCategoryChangeConfirmation={showCategoryChangeConfirmation}
              setShowCategoryChangeConfirmation={setShowCategoryChangeConfirmation}
              showVatIdWarning={showVatIdWarning}
              setShowVatIdWarning={setShowVatIdWarning}
            />
            <IndividualContactSection
              dataIds={{
                salutation: 'OutgoingInvoicePage:input-salutation',
                salutationContent: 'OutgoingInvoicePage:input-salutation-content',
              }}
              client={currentClient}
            />
            <InvoiceDetailsSection
              crudMode={CANCEL_MODE}
              readonly
              defaultCategory={defaultCategory}
              selectedCategory={selectedCategory}
              lineCategories={lineCategories}
              taxRates={taxRates}
              taxRateId={taxRateId}
              defaultVat={defaultVat}
              isInitial={isInitial}
              setLineItemState={setLineItemState}
            />
            <div className={styles.bottomSection}>
              <Notes />
              <InvoiceSumSection />
              <ElectronicInvoiceInfo
                paymentPlan={paymentPlan}
                eInvoicesEnabled={eInvoicesEnabled}
                electronicInvoiceType={electronicInvoiceType}
              />
            </div>
            <ActionsSection
              currentClient={currentClient}
              abort={abort}
              saveInvoice={handleSubmit(saveInvoice(invoiceId))}
              downloadInvoice={downloadAndTrack}
              sendInvoice={sendAndTrack}
              isFormSubmitting={submitting}
              sendingDocumentsByEmailEnabled={sendingDocumentsByEmailEnabled}
              canSubmit={canSubmit}
            />
          </div>
        </div>
      </LineItemsAutoSaveProvider>
    </form>
  );
};

const ReduxForm = reduxForm({
  form: 'outgoingInvoiceCreator',
  enableReinitialize: true,
})(OutgoingInvoiceCancel);

const mapStateToProps = (state, ownProps) => {
  const client = outgoingInvoiceSelector(state, 'client') || get(state, 'outgoingInvoice.client');

  return {
    invoiceId: state.outgoingInvoice.details.id || ownProps.match.params.id,
    invoiceDetails: state.outgoingInvoice.details,
    initialValues: {
      ...state.outgoingInvoice.details,
      dueDate: getDateXDaysFromNow(14),
      creationDate: moment().format('DD.MM.YYYY'),
      client,
    },
    currentClient: client,
    sendingDocumentsByEmailEnabled: state.profile.applicationFeatures.sendingDocumentsByEmail,
    paymentPlan: state.paymentPlan.details.name,
    eInvoicesEnabled: eInvoicesEnabledHelper(state),
    electronicInvoiceType: outgoingInvoiceSelector(state, 'electronicInvoiceType'),
    buttonLabel: t('empty_entry_pages.outgoing_invoice.invoice_warning_box.button_label'),
    content: t('empty_entry_pages.outgoing_invoice.invoice_warning_box.content'),
    dataId: 'OutgoingInvoices:orange-warning-container',
  };
};

const mapDispatchToProps = (dispatch) => ({
  abort: () => dispatch(abortChanges()),
  downloadInvoice: (details) => dispatch(downloadOutgoingInvoice(details)),
  sendInvoice: (arg) => dispatch(sendOutgoingInvoice(arg)),
  saveInvoice: (id) => (data) => dispatch(updateOutgoingInvoice(id, { ...data, draft: false })),
  fetchNewOutgoingCancellationInvoice: (...args) =>
    dispatch(fetchNewOutgoingCancellationInvoice(...args)),
  fetchCompany,
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withWarningAlert)(ReduxForm);
