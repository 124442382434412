import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push } from 'connected-react-router';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose, withState } from 'recompose';

import {
  deleteProductCatalogItem,
  fetchProductCatalogItems,
  setPagination,
  sort,
} from 'actions/product-catalog/items/items';
import {
  clickCreateProductCatalogItem as clickCreateProductCatalogItemAction,
  clickEditProductCatalogItem as clickEditProductCatalogItemAction,
} from 'actions/product-catalog/items/piwik';
import {
  CATEGORY_PRODUCT_CATALOG,
  PRODUCT_CATALOG_INDEX_DELETE_PRODUCT,
  PRODUCT_CATALOG_INDEX_EDIT_PRODUCT,
} from 'constants/piwik';
import { PRODUCT_CATALOG_ITEMS } from 'constants/product-catalog/items';
import { Resources } from 'constants/resources';
import buttonStyles from 'containers/OutgoingInvoices/Table/Table.module.css';
import { makeGetList } from 'reducers/common/resource';
import paths from 'routes/paths';
import { formatMoney, formatPercentage, t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import ActionButton, { ACTION_BUTTON_TYPES } from 'components/ActionButton/ActionButton';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import If from 'components/Conditions/If';
import I18n from 'components/I18n';
import ActionDelete from 'components/IndexActionsContainer/actionIcons/ActionDelete';
import ActionEdit from 'components/IndexActionsContainer/actionIcons/ActionEdit';
import IndexActionsContainer from 'components/IndexActionsContainer/IndexActionsContainer';
import {
  Body as TableBody,
  Cell,
  Header as TableHeader,
  Table,
  TableHeader as HeaderCell,
  TableRow,
} from 'components/Table';

import ConfirmationModalWithAlert from '../Modals/ConfirmationModalWithAlert';
import SimpleConfirmationModal from '../Modals/SimpleConfirmationModal';

import styles from './Table.module.css';

const trackEditProduct = () =>
  piwikHelpers.trackEvent(CATEGORY_PRODUCT_CATALOG, PRODUCT_CATALOG_INDEX_EDIT_PRODUCT);
const trackDeleteProduct = () =>
  piwikHelpers.trackEvent(CATEGORY_PRODUCT_CATALOG, PRODUCT_CATALOG_INDEX_DELETE_PRODUCT);

class ProductCatalogItemsTable extends Component {
  static propTypes = {
    fetch: func.isRequired,
    isFetching: bool,
    data: arrayOf(shape({})),
    params: shape({
      pagination: shape({}),
      filters: shape({}),
      sorting: shape({
        column: string,
      }),
    }),
    sort: func.isRequired,
    isDeleting: bool.isRequired,
    currentEntity: shape({
      id: string,
      referencedByAnyGroup: bool,
    }),
    delete: func.isRequired,
    setDeletionModal: func.isRequired,
    setCurrentEntity: func.isRequired,
    push: func.isRequired,
    setPagination: func.isRequired,
    clickCreateProductCatalogItem: func.isRequired,
    clickEditProductCatalogItem: func.isRequired,
  };

  componentDidMount() {
    if (!this.props.params.sorting.column) {
      this.props.sort('itemNumber', 'DESC');
    }
  }

  editEntity = (entity) => {
    this.props.push(paths.editProductCatalogItem(entity.id));
    this.props.clickEditProductCatalogItem();
  };
  createEntity = () => {
    this.props.push(paths.newProductCatalogItem);
    this.props.clickCreateProductCatalogItem();
  };
  deleteEntity = async () => {
    await this.props.delete(this.props.currentEntity.id);
    this.props.fetch().then(({ data, headers: { page } }) => {
      const isFirstPage = page === '1';
      if (!data.length && !isFirstPage) {
        const {
          params: { pagination },
        } = this.props;
        this.props.setPagination({ ...pagination, page: 1 });
        this.props.fetch();
      }
    });
    this.props.setDeletionModal(false);
  };

  showDeletionConfirmation = (entity) => {
    this.props.setCurrentEntity(entity);
    this.props.setDeletionModal(true);
  };

  renderRow = (object = {}) => (
    <TableRow key={object.id} dataId={`ProductCatalogTable:row-${object.id}`}>
      <Cell>{object.itemNumber}</Cell>
      <Cell isVerticalOnMobile className={styles.subjectCell}>
        <div className={styles.subjectContainer}>
          <CellContentWithTooltip className={styles.cellWithTooltip}>
            {object.name}
          </CellContentWithTooltip>
          {object.internalNotes && (
            <CellContentWithTooltip
              className={cx(styles.cellWithTooltip, styles.internalNotesUnderName)}
            >
              {object.internalNotes}
            </CellContentWithTooltip>
          )}
        </div>
      </Cell>
      <Cell isVerticalOnMobile className={styles.internalNotesCell}>
        {object.internalNotes}
      </Cell>
      <Cell>{object.unit}</Cell>
      <Cell>
        <div className={styles.price}>{formatMoney(object.netAmount)}</div>
      </Cell>
      <Cell>{formatPercentage(object.vat)}</Cell>
      <Cell>
        <div className={styles.price}>{formatMoney(object.grossAmount)}</div>
      </Cell>
      <Cell cellContentClassName={styles.actionsCellContent} isWithoutHeaderOnMobile>
        <div className={styles.mobileActionButtons}>
          <ActionButton
            onClick={() => {
              this.editEntity(object);
              trackEditProduct();
            }}
            type={ACTION_BUTTON_TYPES.EDIT}
            data-id="ProductCatalogTable:button-mobile-edit"
          />
          <ActionButton
            onClick={() => {
              this.showDeletionConfirmation(object);
              trackDeleteProduct();
            }}
            type={ACTION_BUTTON_TYPES.DELETE}
            data-id="ProductCatalogTable:button-mobile-delete"
          />
        </div>
        <div className={styles.desktopActionButtons}>
          <IndexActionsContainer>
            <ActionEdit
              onClick={() => {
                this.editEntity(object);
                trackEditProduct();
              }}
              tabIndex="0"
              visible
              data-id="ProductCatalogTable:button-desktop-edit"
            />
            <ActionDelete
              onClick={() => {
                this.showDeletionConfirmation(object);
                trackDeleteProduct();
              }}
              tabIndex="0"
              visible
              data-id="ProductCatalogTable:button-desktop-delete"
            />
          </IndexActionsContainer>
        </div>
      </Cell>
    </TableRow>
  );

  render() {
    const { isFetching, params, data, isDeleting, setDeletionModal, currentEntity } = this.props;
    const isEmpty = Boolean(!data.length);
    const isReferencedByAnyGroup = currentEntity && currentEntity.referencedByAnyGroup;

    return (
      <div className={styles.table}>
        <If ok={isReferencedByAnyGroup}>
          <ConfirmationModalWithAlert
            isOpen={isDeleting}
            onClose={() => setDeletionModal(false)}
            onConfirm={this.deleteEntity}
          />
        </If>
        <If ok={!isReferencedByAnyGroup}>
          <SimpleConfirmationModal
            isOpen={isDeleting}
            onClose={() => setDeletionModal(false)}
            onConfirm={this.deleteEntity}
          />
        </If>
        <Table
          preventRefetchOnUpdate
          isFetching={isFetching}
          fetch={this.props.fetch}
          resource={Resources.PRODUCT_CATALOG_ITEMS}
          params={params}
          sort={this.props.sort}
          isPaginationHidden={isEmpty}
          isEmpty={isEmpty}
          emptyTableComponent={
            <div className={styles.emptyMessage}>
              {t('product_catalog.product_catalog_items.no_products_yet')}
            </div>
          }
          dataId="ProductCatalog:table"
        >
          {!isEmpty && (
            <TableHeader>
              <TableRow>
                <HeaderCell column="itemNumber" columnWidthClass={styles.numberColumn} sortable>
                  <I18n t="tables.headers.number" />
                </HeaderCell>
                <HeaderCell column="name" sortable>
                  <I18n t="tables.headers.subject" />
                </HeaderCell>
                <HeaderCell
                  column="internalNotes"
                  sortable
                  customClassName={styles.internalNotesHeader}
                >
                  <I18n t="tables.headers.internalNotes" />
                </HeaderCell>
                <HeaderCell column="unit" columnWidthClass={styles.unitColumn} sortable>
                  <I18n t="tables.headers.unit" />
                </HeaderCell>
                <HeaderCell column="netAmount" sortable>
                  <I18n t="tables.headers.net_amount" />
                </HeaderCell>
                <HeaderCell column="vat" sortable>
                  <I18n t="tables.headers.vat_percentage" />
                </HeaderCell>
                <HeaderCell column="grossAmount" sortable>
                  <I18n t="tables.headers.gross_amount_alt" />
                </HeaderCell>
                <HeaderCell>
                  <I18n t="tables.headers.actions" />
                </HeaderCell>
              </TableRow>
            </TableHeader>
          )}
          <TableBody>{data.map(this.renderRow)}</TableBody>
        </Table>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getList = makeGetList();

  return (state) => ({
    data: getList(state.productCatalogItems[PRODUCT_CATALOG_ITEMS], PRODUCT_CATALOG_ITEMS),
    isFetching: state.productCatalogItems[PRODUCT_CATALOG_ITEMS].isFetching,
    params: state.productCatalogItems[PRODUCT_CATALOG_ITEMS].params,
  });
};

const mapDispatchToProps = {
  fetch: fetchProductCatalogItems,
  delete: deleteProductCatalogItem,
  sort,
  push,
  setPagination,
  clickCreateProductCatalogItem: clickCreateProductCatalogItemAction,
  clickEditProductCatalogItem: clickEditProductCatalogItemAction,
};

const enhance = compose(
  withState('isDeleting', 'setDeletionModal', false),
  withState('currentEntity', 'setCurrentEntity', null),
  connect(makeMapStateToProps, mapDispatchToProps)
);

export const NewProductCatalogItemButton = ({ onClick }) => (
  <button
    className={buttonStyles.newInvoice}
    onClick={onClick}
    type="button"
    data-id="ProductCatalogCreation:button-addInitity"
  >
    <I18n t="product_catalog.product_catalog_items.create_new" />
  </button>
);
NewProductCatalogItemButton.propTypes = {
  onClick: func.isRequired,
};

export default enhance(ProductCatalogItemsTable);
