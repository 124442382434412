import React from 'react';

import styles from '../StatusIcons.module.css';

const FullPaymentInitiatedStatusIcon = () => (
  <svg viewBox="0 0 20 20" className={styles.statusIcon} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <rect className={styles.strokeSuccess} x=".5" y=".5" width="19" height="19" rx="2" />
      <g className={styles.fillSuccess} fillRule="nonzero">
        <path d="M9.733 12.656H6.889v.544c0 .769-.89 1.184-1.467.683l-3.112-2.7a.907.907 0 0 1 0-1.366l3.112-2.7c.576-.5 1.467-.086 1.467.683v.544h3.056a1.004 1.004 0 0 0-.625.706l-.18.742c-.08.326.008.647.198.881a1.025 1.025 0 0 0-.018.066l-.18.741c-.122.504.151.995.593 1.176zM16.646 9.183H13.24c.017-.05.038-.11.063-.175a2.428 2.428 0 0 1 .264-.505c.176-.263.43-.496.765-.699.33-.2.75-.3 1.26-.3.325 0 .594.035.807.104.221.072.406.14.555.203.06.026.129-.009.145-.073l.312-1.264a.108.108 0 0 0-.07-.128 14.333 14.333 0 0 0-.731-.22C16.306 6.04 15.927 6 15.47 6c-.066 0-.19.004-.376.012a4.833 4.833 0 0 0-1.851.447c-.424.194-.82.516-1.19.965-.165.2-.299.396-.402.588a4.306 4.306 0 0 0-.433 1.171h-.931a.107.107 0 0 0-.104.082l-.18.741a.108.108 0 0 0 .104.134h.964a4.24 4.24 0 0 0 0 .732h-.784a.107.107 0 0 0-.104.082l-.18.741a.108.108 0 0 0 .104.134h1.105l.02.091c.021.096.05.206.086.33.037.123.08.255.128.394.05.143.113.283.188.418.05.092.135.228.256.409.127.187.317.388.572.605a3.86 3.86 0 0 0 1.178.662c.473.175 1.07.262 1.794.262.147 0 .362-.01.644-.03a4.55 4.55 0 0 0 1.02-.218.108.108 0 0 0 .075-.109l-.072-1.36a.107.107 0 0 0-.145-.096 7.602 7.602 0 0 0-.202.081c-.02.009-.082.026-.18.049a4.489 4.489 0 0 1-1.032.13 2.39 2.39 0 0 1-.73-.104 2.56 2.56 0 0 1-.587-.268 2.192 2.192 0 0 1-.739-.753 1.92 1.92 0 0 1-.17-.38l-.037-.113h2.803c.05 0 .093-.035.104-.084l.168-.74a.108.108 0 0 0-.104-.133h-3.22a2.921 2.921 0 0 1-.015-.554c.003-.05.008-.108.017-.178h3.434c.05 0 .092-.034.104-.082l.18-.741a.108.108 0 0 0-.104-.134z" />
      </g>
    </g>
  </svg>
);

export default FullPaymentInitiatedStatusIcon;
