import normalize from 'json-api-normalizer';
import { get } from 'lodash';
import moment from 'moment';
import { combineReducers } from 'redux';
import { actionTypes } from 'redux-form';
import build from 'redux-object';

import {
  CREATE_REQUEST,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
} from 'constants/delivery-notes';

import lineItems from './line-items';
import sums from './sums';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
    case CREATE_REQUEST:
      return true;
    case FETCH_SUCCESS:
    case FETCH_FAILURE:
      return false;
    default:
      return state;
  }
};

const isFetched = (state = false, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return true;
    case FETCH_REQUEST:
    case FETCH_FAILURE:
      return false;
    default:
      return state;
  }
};

const mapDeliveryNote = (response) => {
  const { data: deliveryNote } = response;
  const normalizedDeliveryNote = build(normalize(response), 'deliveryNotes', deliveryNote.id);

  return {
    ...deliveryNote.attributes,
    id: deliveryNote.id,
    deliveryNoteDate: deliveryNote.attributes.deliveryNoteDate || moment().format('DD.MM.YYYY'),
    client: normalizedDeliveryNote && normalizedDeliveryNote.client,
  };
};

const details = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return mapDeliveryNote(action.response);
    default:
      return state;
  }
};

export const meta = (state = null, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return get(action, 'response.data.meta', null);
    default:
      return state;
  }
};

const deliveryNote = combineReducers({
  isFetching,
  isFetched,
  details,
  lineItems,
  meta,
  sums,
});

export default (state, action) => {
  if (action.type === actionTypes.DESTROY && action.meta.form.includes('deliveryNoteCreator')) {
    /* Clean all state on quit */
    return deliveryNote({}, action);
  }

  return deliveryNote(state, action);
};
