import React, { FunctionComponent, ReactNode } from 'react';

import styles from './ActionButtons.module.css';

interface ActionButtonsProps {
  children: ReactNode;
}

const ActionButtons: FunctionComponent<ActionButtonsProps> = ({ children, ...rest }) => (
  <div className={styles.buttons} {...rest}>
    {children}
  </div>
);

export default ActionButtons;
