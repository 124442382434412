import React, { useCallback, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import cx from 'classnames';
import { push as pushAction } from 'connected-react-router';
import { get, isEmpty } from 'lodash';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import {
  clearFilters as clearFiltersAction,
  deleteDeliveryNote,
  indexDeliveryNotes as indexDeliveryNotesAction,
  setQueryParam,
  sortDeliveryNotes,
} from 'actions/delivery-note';
import { Resources } from 'constants/resources';
import { deliveryNotesEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import { objectHasValues, t } from 'shared/utils';
import { hasAnyFiltersActive as hasAnyFiltersActiveChecker } from 'shared/utils/hasAnyFiltersActive';
import { useDebouncedHasAnyFilters } from 'shared/utils/hooks/useDebouncedHasAnyFilters';
import { useRefetchOnPropsChange } from 'shared/utils/hooks/useRefetchOnPropsChange';
import CardView, { HeadingSection, Section } from 'components/CardView';
import If from 'components/Conditions/If';
import EmptyEntryPage from 'components/EmptyEntryPage/EmptyEntryPage';
import { FiltersGroup } from 'components/Filter/FiltersGroup/FiltersGroup';
import I18n from 'components/I18n';
import Loading from 'components/Loading';
import { Pagination } from 'components/Pagination/Pagination';
import { CreationForbiddenMessage } from 'components/Table';
import CSVDownloadButton from 'components/Table/CSVDownloadButton/CSVDownloadButton';

import DeliveryNotesTable from './DeliveryNotesTable';
import NewDeliveryNotesButton from './NewDeliveryNotesButton/NewDeliveryNotesButton';

import styles from './DeliveryNotes.module.css';

const DeliveryNotes = ({
  canCreate,
  showModal,
  isFetching,
  data,
  remove,
  sort,
  indexDeliveryNotes,
  parsedFilters,
  push,
  filters,
  pagination,
  clearFilters,
  sorting,
  setQueryAction,
}) => {
  const { push: historyPush } = useHistory();
  const [areDeliveryNotesFetched, setAreDeliveryNotesFetched] = useState(false);
  const areDeliveryNotesEmpty = isEmpty(data);
  const debouncedHasAnyFiltersActive = useDebouncedHasAnyFilters(parsedFilters);
  const deliveryNotesEnabled = useSelector(deliveryNotesEnabledHelper);
  const isEmptyStateVisible = !isFetching && areDeliveryNotesEmpty && !debouncedHasAnyFiltersActive;
  const hasAnyFiltersActive = hasAnyFiltersActiveChecker(filters);

  const fetch = useCallback(
    (pagination = { page: 1 }) => {
      const isIndexClassicView = objectHasValues(parsedFilters);

      indexDeliveryNotes(
        { pagination_resource: Resources.DELIVERY_NOTES, ...pagination },
        sorting,
        {
          isIndexClassicView,
        }
      );
    },
    [indexDeliveryNotes, sorting, parsedFilters]
  );

  useRefetchOnPropsChange({
    fetchFunction: async ({
      pagination: newPagination,
      sorting: newSorting,
      overwrittenValues,
      parsedFilters: newParsedFilters,
    }) => {
      const isIndexClassicView = objectHasValues(newParsedFilters);
      await indexDeliveryNotes({ ...newPagination, ...overwrittenValues }, newSorting, {
        isIndexClassicView,
      });
      setAreDeliveryNotesFetched(true);
    },
    props: { parsedFilters, pagination, sorting },
  });

  if (!deliveryNotesEnabled) {
    historyPush(paths.home);
    return null;
  }

  if (!areDeliveryNotesFetched) {
    return (
      <div className={styles.page}>
        <div className={styles.main}>
          <Loading />
        </div>
      </div>
    );
  }

  if (isEmptyStateVisible) {
    return (
      <div className={styles.page}>
        <EmptyEntryPage
          dataIdPrefix="DeliveryNotes"
          header={t('empty_entry_pages.delivery_notes.headline')}
          subheader={t('empty_entry_pages.delivery_notes.subheadline')}
          info={[
            t('empty_entry_pages.delivery_notes.details.item_1'),
            t('empty_entry_pages.delivery_notes.details.item_2'),
            t('empty_entry_pages.delivery_notes.details.item_3'),
          ]}
          createButtonLabel={t('empty_entry_pages.delivery_notes.button_label')}
          onCreateButtonClick={() => push(paths.deliveryNotesNew)}
          customClasses={{ wrapper: styles.wrapper }}
        />
      </div>
    );
  }

  return (
    <div className={styles.page}>
      <CardView>
        <HeadingSection>
          <I18n t="revenue.subtitle_delivery_notes" className={styles.headingText} />
          <div
            className={cx(styles.pullRight, {
              [styles.hiddenSection]: areDeliveryNotesEmpty && !hasAnyFiltersActive,
            })}
          >
            <If ok={canCreate}>
              <NewDeliveryNotesButton showModal={showModal} />
            </If>
            <If ok={!canCreate}>
              {/* TODO translations */}
              <CreationForbiddenMessage section="delivery_notes" />
            </If>
          </div>
          <FiltersGroup
            className={styles.filtersGroup}
            isResetButtonDisabled={!hasAnyFiltersActive}
            filters={filters}
            onFiltersChange={({ name, value }) => setQueryAction(name)(value)}
            onFiltersReset={clearFilters}
            placeholder={t('tables.filters.filters_group.delivery_notes.placeholder')}
            dataIds={{
              input: 'DeliveryNotes:input-search',
            }}
          >
            <CSVDownloadButton
              filters={filters}
              sorting={sorting}
              endpoint="delivery_notes_csv"
              disabled={isEmpty(data)}
              className={styles.csvButton}
              dataId="DeliveryNotes:button-CSVExport"
            />
          </FiltersGroup>
        </HeadingSection>
        <Section className={styles.section}>
          {areDeliveryNotesEmpty && hasAnyFiltersActive ? (
            <div className={styles.emptyTableMessage} data-id="DeliveryNotes:table-empty-info">
              {t('tables.empty_delivery_notes')}
            </div>
          ) : (
            <DeliveryNotesTable
              data={data}
              isFetching={isFetching}
              refresh={fetch}
              remove={remove}
              sort={sort}
              sorting={sorting}
            />
          )}
        </Section>
        <Section
          className={cx(styles.pagination, {
            [styles.hiddenSection]: areDeliveryNotesEmpty,
          })}
        >
          <Pagination
            {...pagination}
            request={fetch}
            isFetching={isFetching}
            resource={Resources.DELIVERY_NOTES}
          />
        </Section>
      </CardView>
    </div>
  );
};

DeliveryNotes.propTypes = {
  canCreate: bool.isRequired,
  showModal: bool.isRequired,
  data: arrayOf(shape({})).isRequired,
  isFetching: bool.isRequired,
  pagination: shape({
    perPage: number,
    page: number,
  }).isRequired,
  remove: func.isRequired,
  sort: func.isRequired,
  sorting: shape({}).isRequired,
  filters: string.isRequired,
  parsedFilters: shape({}),
  push: func.isRequired,
  clearFilters: func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state.deliveryNotes,
  canCreate: get(state, 'deliveryNotes.meta.actions.create', false),
  showModal: !get(state, 'deliveryNotes.meta.bank-account-data-present', false),
});

const mapDispatchToProps = {
  indexDeliveryNotes: indexDeliveryNotesAction,
  push: pushAction,
  remove: deleteDeliveryNote,
  sort: sortDeliveryNotes,
  clearFilters: clearFiltersAction,
  setQueryAction: setQueryParam,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryNotes);
