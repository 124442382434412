import React from 'react';
import { shape, string } from 'prop-types';

import {
  DATEV_PREVIEW_CLIENTS,
  DATEV_PREVIEW_INCOMING_INVOICES,
  DATEV_PREVIEW_SUPPLIERS,
} from 'constants/datev';

import ClientRow from './ClientRow/ClientRow';
import IncomingInvoiceRow from './IncomingInvoiceRow/IncomingInvoiceRow';
import OutgoingInvoiceRow from './OutgoingInvoiceRow/OutgoingInvoiceRow';
import SupplierRow from './SupplierRow/SupplierRow';

const InvoiceRow = ({ type, data }) => {
  if (type === DATEV_PREVIEW_INCOMING_INVOICES) return <IncomingInvoiceRow invoice={data} />;
  if (type === DATEV_PREVIEW_CLIENTS) return <ClientRow client={data} />;
  if (type === DATEV_PREVIEW_SUPPLIERS) return <SupplierRow supplier={data} />;

  return <OutgoingInvoiceRow invoice={data} />;
};

InvoiceRow.propTypes = {
  type: string,
  data: shape({}).isRequired,
};

export default InvoiceRow;
