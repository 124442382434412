import { push } from 'connected-react-router';
import { get, identity, pickBy } from 'lodash';

import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import {
  CHANGE_PATH,
  REGISTRATION_REQUEST_FAILURE,
  REGISTRATION_REQUEST_REQUEST,
  REGISTRATION_REQUEST_SUCCESS,
  RESET_PERSISTED_CUSTOMER,
  SET_COMPANY,
} from 'constants/registration-request';
import paths from 'routes/paths';
import { bindServerValidation } from 'shared/utils/server-validation';

import { requestPasswordReset } from './password-reset';

export const setCompany = (company) => ({
  type: SET_COMPANY,
  payload: company,
});

export const resetPersistedCustomer = () => ({
  type: RESET_PERSISTED_CUSTOMER,
});

export const apiRequestRegistration = (formData) => ({
  [CALL_API]: {
    data: pickBy(formData, identity),
    method: 'POST',
    authRequired: false,
    endpoint: '/registration_requests',
    types: [
      REGISTRATION_REQUEST_REQUEST,
      REGISTRATION_REQUEST_SUCCESS,
      REGISTRATION_REQUEST_FAILURE,
    ],
    payload: { firstName: formData.firstName, lastName: formData.lastName },
  },
});

export const requestRegistration = (formData) => (dispatch) =>
  bindServerValidation(apiRequestRegistration(formData), dispatch, {
    isReduxForm: true,
    catchMiddleware: (error) => {
      /**
       * Unfortunately that was the only way to send 'email taken' status from the backend. When
       * 'email taken' is caught, we don't want redux-form to process it. We escape it with return and
       * redirect user to a different page.
       */
      if (get(error, 'response.data.errors[0].detail') === 'email taken') {
        dispatch(push(paths.emailTaken));
        dispatch(requestPasswordReset(formData.email));
        return;
      }

      if (error.response.status === 423) {
        dispatch(
          showNotification({
            duration: 7500,
            title: error.response.data.error,
            variant: 'warning',
          })
        );
      }

      throw error;
    },
  });

export const changePath = () => ({ type: CHANGE_PATH });
