import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { MODAL_HEADER_VARIANT } from 'constants/modal';
import { ModalComponentProps } from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import { ConfirmationModal as ConfirmationModalComponent } from 'components/Modal';

import * as selectors from '../selectors';

const ConfirmationModal = (props: ModalComponentProps) => {
  const isHelpNeeded = useSelector(selectors.getIsHelpNeeded);

  return (
    <ConfirmationModalComponent
      headerVariant={MODAL_HEADER_VARIANT.SMALL}
      dangerousAction
      closeLabel={t('transition_prevent_modal.cancel')}
      confirmLabel={t('transition_prevent_modal.confirm')}
      header={t('transition_prevent_modal.header')}
      hasWarningIcon
      withCloseButton
      {...props}
    >
      {t('features.financial_planning.liquidity_calculator.confirmation_modal.warning_1')}
      {isHelpNeeded &&
        ` ${t('features.financial_planning.liquidity_calculator.confirmation_modal.warning_2')}`}
      <br />
      <br />
      {t('features.financial_planning.liquidity_calculator.confirmation_modal.survey_1')}{' '}
      <a href="https://finstreet.typeform.com/to/Jh6fE4" target="_blank" rel="noopener noreferrer">
        {t('features.financial_planning.liquidity_calculator.confirmation_modal.survey_2')}
      </a>{' '}
      {t('features.financial_planning.liquidity_calculator.confirmation_modal.survey_3')}
    </ConfirmationModalComponent>
  );
};

export default memo(ConfirmationModal);
