import React from 'react';
import InlineSvg from 'react-inlinesvg';
import { bool, func, string } from 'prop-types';

import { BASIC_UID, BOOK_PREMIUM, PREMIUM_UID, SWTICH_TO_BASIC } from 'constants/payment-plans';
import iconSuccess from 'images/icon-success.svg';
import { formatDate, t } from 'shared/utils';

import Buttons from '../components/Buttons/Buttons';
import Header from '../components/Header/Header';

import styles from './Confirmation.module.css';

const getTranslations = (firstMountedModalType, onTrial, currentPlanEndDate) => {
  const date = formatDate(currentPlanEndDate);
  const isOnTrialTranslation = onTrial ? '_trial' : '';
  const mainUid = {
    [SWTICH_TO_BASIC]: BASIC_UID,
    [BOOK_PREMIUM]: PREMIUM_UID,
  };
  const getMainUid = mainUid[firstMountedModalType];

  return {
    header: t(`profile.payment_plan.confirmation.${getMainUid}${isOnTrialTranslation}.header_1`),
    description: t(
      `profile.payment_plan.confirmation.${getMainUid}${isOnTrialTranslation}.description`,
      { date }
    ),
  };
};

const Confirmation = ({
  firstMountedModalType,
  onTrial,
  currentPlanEndDate,
  redirectToPlanAdmin,
  redirectToHomepage,
}) => {
  const { header, description } = getTranslations(
    firstMountedModalType,
    onTrial,
    currentPlanEndDate
  );
  return (
    <div className={styles.main} data-id="PaymentPlans:confrimationScreen">
      <InlineSvg src={iconSuccess} />
      <Header bold={header} />
      <div className={styles.description}>{description}</div>
      <Buttons
        backLabel={t('profile.payment_plan.confirmation.button_settings')}
        forwardLabel={t('profile.payment_plan.confirmation.button_main_page')}
        onClose={redirectToPlanAdmin}
        onSubmit={redirectToHomepage}
        containerClassName={styles.buttonsContainer}
        dataIds={{
          backButton: 'PaymentPlans:confirmationScreenBack',
          forwardButton: 'PaymentPlans:confirmationScreenMainPage',
        }}
      />
    </div>
  );
};

Confirmation.propTypes = {
  firstMountedModalType: string.isRequired,
  onTrial: bool.isRequired,
  currentPlanEndDate: string,
  redirectToPlanAdmin: func.isRequired,
  redirectToHomepage: func.isRequired,
};

export default Confirmation;
