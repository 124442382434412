import { createSelector } from 'reselect';

import { isOBSEnabled as getIsOBSEnabled } from 'routes/accesses';
import { isOBSQualifiedSelector as getIsOBSQualified } from 'selectors/obs';
import { RootState } from 'store';
import type { BankAccountTransferAverage } from 'types/entities/BankAccountTransferAverage';

import type { BankAccount } from './BankAccounts/types';
import { isBankAccountUpToDate } from './BankAccounts/utils';
import { AlertMessage, SliderValues } from './constants';
import type { OtherExpense, RecurringExpense } from './Expenses/types';
import { getDefaultRecurringExpenses } from './Expenses/utils';
import { Interval } from './ExpensesRevenues/constants';
import { parseCurrencyStringToNumber, parseNumberToCurrencyString } from './ExpensesRevenues/utils';
import * as formSelectors from './formSelectors';
import { DefaultRecurringRevenues } from './Revenues/constants';
import type { OtherRevenue, RecurringRevenue } from './Revenues/types';
import { calculateMoneyNeeded, calculateMonthsToDeficit } from './Summary/utils';

type AverageAccountDetails = {
  balance: number;
  averageRevenues: number;
  averageExpenses: number;
};

export const getBridgePeriodInMonths = (state: RootState) =>
  state.liquidityCalculator.bridgePeriodInMonths;

const getExpenses = (state: RootState) => state.liquidityCalculator.expenseContracts.data;

export const getInitialRecurringExpenses = createSelector(getExpenses, (expenseContracts) => [
  ...getDefaultRecurringExpenses(
    expenseContracts
      .filter((contract) => contract.invoiceLineCategory)
      .map((contract) => contract.invoiceLineCategory.name)
  ),
  ...expenseContracts.map<RecurringExpense>((contract) => ({
    name: contract.subject,
    interval: contract.recurringTransactionInterval.name as Interval,
    amount: parseNumberToCurrencyString(contract.upcomingInstallment.grossAmount),
  })),
]);

const getRevenues = (state: RootState) => state.liquidityCalculator.revenueContracts.data;

export const getInitialRecurringRevenues = createSelector(getRevenues, (revenueContracts) => [
  ...DefaultRecurringRevenues,
  ...revenueContracts.map<RecurringRevenue>((contract) => ({
    name: contract.subject,
    interval: contract.recurringTransactionInterval.name as Interval,
    amount: parseNumberToCurrencyString(contract.upcomingInstallment.grossAmount),
  })),
]);

const getBankAccounts = (state: RootState) => state.liquidityCalculator.bankAccounts.data;

export const getInitialBankAccounts = createSelector(getBankAccounts, (bankAccounts) =>
  bankAccounts.map<BankAccount>((account) => {
    const isUpToDate = isBankAccountUpToDate(account);

    return {
      ...account,
      isChecked: isUpToDate,
      isDisabled: !isUpToDate,
    };
  })
);

export const getAverageAccountDetailsForSelectedAccounts = createSelector(
  [formSelectors.getSelectedBankAccountsIds, formSelectors.getBankAccounts],
  (selectedAccountsIds, bankAccounts) =>
    bankAccounts
      .filter((account: BankAccountTransferAverage) => selectedAccountsIds.includes(account.id))
      .reduce(
        (average: AverageAccountDetails, account: BankAccountTransferAverage) => ({
          // Requirement to handle negative balance as positive
          balance: average.balance + Math.abs(account.balance),
          averageRevenues: average.averageRevenues + account.meta.averageRevenues,
          averageExpenses: average.averageExpenses + account.meta.averageExpenses,
        }),
        {
          balance: 0,
          averageRevenues: 0,
          averageExpenses: 0,
        }
      )
);

export const getIsOBSReady = createSelector(
  [getIsOBSEnabled, getIsOBSQualified],
  (isOBSEnabled, isOBSQualified) => isOBSEnabled && isOBSQualified
);

export const getMoneyPerMonth = createSelector(
  formSelectors.getMonthlyRevenues,
  formSelectors.getMonthlyExpenses,
  (monthlyRevenuesTotal, monthlyExpensesTotal) => monthlyRevenuesTotal - monthlyExpensesTotal
);

export const getMonthsToDeficit = createSelector(
  [getAverageAccountDetailsForSelectedAccounts, getMoneyPerMonth],
  (accountsDetails, moneyPerMonth) =>
    calculateMonthsToDeficit({ balance: accountsDetails.balance, moneyPerMonth })
);

export const getMoneyNeeded = createSelector(
  [getBridgePeriodInMonths, getMonthsToDeficit, getMoneyPerMonth],
  (bridgePeriodInMonths, monthsToDeficit, moneyPerMonth) =>
    calculateMoneyNeeded({ bridgePeriodInMonths, monthsToDeficit, moneyPerMonth })
);

export const getIsHelpNeeded = createSelector(
  [getMoneyNeeded, getMonthsToDeficit],
  (moneyNeeded, monthsToDeficit) => moneyNeeded > 0 && typeof monthsToDeficit === 'number'
);

const getIsShortfallOutOfRange = createSelector(
  getMonthsToDeficit,
  (monthsToDeficit) => typeof monthsToDeficit === 'number' && monthsToDeficit >= SliderValues.max
);

const getIsProfitable = createSelector(getMoneyPerMonth, (moneyPerMonth) => moneyPerMonth > 0);

export const getIsNoShortfall = createSelector(
  [
    getIsShortfallOutOfRange,
    getIsProfitable,
    formSelectors.getMonthlyExpenses,
    formSelectors.getMonthlyRevenues,
  ],
  (isShortfallOutOfRange, isProfitable, monthlyExpenses, monthlyRevenues) =>
    isShortfallOutOfRange ||
    isProfitable ||
    (monthlyExpenses > 0 && monthlyRevenues > 0 && monthlyExpenses === monthlyRevenues)
);

export const getPDFData = createSelector(
  [
    getBridgePeriodInMonths,
    getMoneyNeeded,
    getMonthsToDeficit,
    getMoneyPerMonth,
    formSelectors.getValidRecurringExpenses,
    formSelectors.getValidRecurringRevenues,
    formSelectors.getValidOtherExpenses,
    formSelectors.getValidOtherRevenues,
    formSelectors.getSelectedBankAccountsIds,
  ],
  (
    bridgePeriodInMonths,
    moneyNeeded,
    monthsToDeficit,
    moneyPerMonth,
    validRecurringExpenses,
    validRecurringRevenues,
    validOtherExpenses,
    validOtherRevenues,
    selectedBankAccountsIds
  ) => ({
    bridgePeriodInMonths: bridgePeriodInMonths,
    bridgeAmount: moneyNeeded,
    monthsToShortfall: typeof monthsToDeficit === 'number' ? monthsToDeficit : null,
    shortfallAmount: Math.abs(moneyPerMonth),
    recurringExpenses: validRecurringExpenses.map((expense: RecurringExpense) => ({
      name: expense.name,
      type: expense.interval,
      amount: parseCurrencyStringToNumber(expense.amount),
    })),
    recurringRevenues: validRecurringRevenues.map((revenue: RecurringRevenue) => ({
      name: revenue.name,
      type: revenue.interval,
      amount: parseCurrencyStringToNumber(revenue.amount),
    })),
    additionalExpenses: validOtherExpenses.map((expense: OtherExpense) => ({
      name: expense.name,
      amount: parseCurrencyStringToNumber(expense.amount),
    })),
    additionalRevenues: validOtherRevenues.map((revenue: OtherRevenue) => ({
      name: revenue.name,
      amount: parseCurrencyStringToNumber(revenue.amount),
    })),
    bankAccounts: selectedBankAccountsIds,
  })
);

export const getAddAtLeastOneAccountAlert = createSelector(getBankAccounts, (bankAccounts) => {
  if (!bankAccounts.length) return AlertMessage.AddAtLeastOneAccount;
});

export const getChooseAtLeastOneAccountAlert = createSelector(
  formSelectors.getSelectedBankAccountsIds,
  (selectedIds) => {
    if (!selectedIds.length) return AlertMessage.ChooseAtLeastOneAccount;
  }
);

export const getUpdateAtLeastOneAccountAlert = createSelector(getBankAccounts, (bankAccounts) => {
  const atLeastOneAccountUpdated = bankAccounts.some(isBankAccountUpToDate);

  if (!atLeastOneAccountUpdated) return AlertMessage.UpdateAtLeastOneAccount;
});

export const getExpensesAlert = createSelector(
  getAddAtLeastOneAccountAlert,
  (addAtLeastOneAccount) => addAtLeastOneAccount
);

export const getSummaryAlert = createSelector(
  [getChooseAtLeastOneAccountAlert, getUpdateAtLeastOneAccountAlert],
  (chooseAtLeastOneAccount, updateAtLeastOneAccount) => {
    if (updateAtLeastOneAccount) return updateAtLeastOneAccount;
    if (chooseAtLeastOneAccount) return chooseAtLeastOneAccount;
  }
);
