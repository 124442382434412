import React from 'react';
import cx from 'classnames';
import { bool, func, number, shape, string } from 'prop-types';

import { PIWIK_ACTION_REMOVE_BANK_TRANSFER, PIWIK_CATEGORY_REVENUE } from 'constants/piwik';
import { formatDate, formatMoney } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import BankTransferStatusBadge from 'components/BankTransferStatusBadge/BankTransferStatusBadge';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';

import styles from './BankTransfer.module.css';

const BankTransfer = ({
  id,
  amount,
  canDelete,
  canEdit,
  creditorName,
  currency,
  date,
  onDelete,
  onEdit,
  purpose,
  status,
  amountLeft,
  readonly,
  transferConnection,
}) => {
  const handleEdit = () => {
    onEdit({
      id,
      amount,
      creditorName,
      currency,
      date,
      purpose,
      status,
      amountLeft,
      transferConnection,
    });
  };

  const handleDelete = () => {
    onDelete();
    piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_REMOVE_BANK_TRANSFER);
  };

  return (
    <div className={cx(styles.suggestedTransfer)} data-id="assignment-row">
      <div className={styles.detailsSection}>
        <div className={styles.suggestedDate} data-id="assigned-transfer-date">
          {formatDate(date)}
        </div>
        <div className={styles.title}>
          <CellContentWithTooltip>{purpose}</CellContentWithTooltip>
        </div>
        <div className={styles.suggestedCreditor}>
          <CellContentWithTooltip>{creditorName}</CellContentWithTooltip>
        </div>
      </div>
      <div className={styles.statusSection}>
        <div className={styles.transferStatus}>
          <BankTransferStatusBadge status={status} unusedAmount={amountLeft} />
        </div>
        <div className={styles.actionSection}>
          <div
            className={cx(styles.suggestedAmount, {
              [styles.amountNegative]: amount < 0,
            })}
            data-id="amount-assigned"
          >
            {formatMoney(amount, currency)}
          </div>
          {!readonly && (
            <div className={styles.actionButtons}>
              {canEdit && <button type="button" onClick={handleEdit} className={styles.edit} />}
              {canDelete && (
                <button type="button" onClick={handleDelete} className={styles.delete} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

BankTransfer.propTypes = {
  id: string.isRequired,
  amount: number.isRequired,
  canDelete: bool,
  canEdit: bool,
  creditorName: string.isRequired,
  currency: string,
  date: string.isRequired,
  onDelete: func.isRequired,
  onEdit: func.isRequired,
  purpose: string.isRequired,
  status: string.isRequired,
  amountLeft: number,
  readonly: bool,
  transferConnection: shape({}),
};

BankTransfer.defaultProps = {
  canDelete: false,
};

export default BankTransfer;
