import React from 'react';
import { func } from 'prop-types';

import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';

import styles from './Buttons.module.css';

const ButtonOutlined = ({ onClick }) => (
  <ActionButton
    appearance="flat"
    onClick={onClick}
    label={t('profile.payment_plan.comparison_box.see_more')}
    className={styles.button}
    fullWidth
  />
);

ButtonOutlined.propTypes = {
  onClick: func.isRequired,
};

export default ButtonOutlined;
