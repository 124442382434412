import styled from 'styled-components';

import Td from 'redesign/components/atoms/Td/Td';

export const Wrapper = styled.div`
  overflow-x: auto;
  position: relative;
`;

export const Empty = styled(Td)`
  color: ${({ theme }) => theme.colors.grey['050']};
  letter-spacing: 0.25px;
  line-height: 17px;
  text-align: center;
  border-bottom: 0;
`;
