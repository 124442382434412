import styled, { css } from 'styled-components';

import BaseIconSecure from 'redesign/components/atoms/Icons/Secure';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type InputWrapperProps = {
  $inactive?: boolean;
  $hasError?: boolean;
};

export const InputWrapper = styled.div<InputWrapperProps>`
  height: 40px;
  padding: 0 0.5rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grey['070']};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  overflow: hidden;

  :hover {
    box-shadow: 1px 1px 2px 0 rgba(15, 35, 100, 0.4);

    ${({ $inactive }) =>
      $inactive &&
      css`
        box-shadow: none;
      `}
  }

  :focus-within {
    border-color: ${({ theme }) => theme.colors.vrblue['100']};
    box-shadow: 1px 1px 2px 0 rgba(15, 35, 100, 0.4);
  }

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-width: 2px;
      border-color: ${({ theme }) => theme.colors.red['100']};
      background-color: ${({ theme }) => theme.colors.red['025']};
    `}

  ${({ $inactive }) =>
    $inactive &&
    css`
      cursor: not-allowed;
      border-color:  ${({ theme }) => theme.colors.grey['040']}};
    `};
`;

type InputProps = {
  $inactive?: boolean;
  $hasError?: boolean;
  $alignText?: 'left' | 'right';
};

export const Input = styled.input<InputProps>`
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  height: 40px;
  width: 100%;
  padding: 0;
  text-align: ${({ $alignText }) => ($alignText === 'left' ? 'left' : 'right')};

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey['070']};
    font-size: ${({ theme }) => theme.fontSize.sm};
  }

  ${({ $inactive }) =>
    $inactive &&
    css`
      cursor: not-allowed;

      ::placeholder {
        color: ${({ theme }) => theme.colors.grey['040']};
      }
    `};

  ${({ $hasError }) =>
    $hasError &&
    css`
      background-color: ${({ theme }) => theme.colors.red['025']};
    `}
`;

type IconWrapperProps = {
  $inactive?: boolean;
  $iconPosition?: 'left' | 'right';
};

export const IconWrapper = styled.div<IconWrapperProps>`
  margin-left: ${({ theme }) => theme.space['3']};
  color: ${({ $inactive, theme }) => $inactive && theme.colors.grey['040']};
  user-select: none;
  order: ${({ $iconPosition }) => ($iconPosition === 'left' ? '-1' : 'initial')};

  > svg {
    color: ${({ theme }) => theme.colors.grey['070']};
    font-size: ${({ theme }) => theme.fontSize.sm};

    ${({ $inactive }) =>
      $inactive &&
      css`
        color: ${({ theme }) => theme.colors.grey['040']};
        cursor: not-allowed;
      `};
  }
`;

export const IconSecure = styled(BaseIconSecure)`
  width: 24px;
  height: 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.red['100']};
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding-top: ${({ theme }) => theme.space[2]};
`;

export const IconSecureWrapper = styled.div`
  display: flex;
  align-items: center;
`;
