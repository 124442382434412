import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';

import styles from './EmptyState.module.css';

const EmptyState = ({ className, isFetching = false, children, id = '' }) => (
  <div className={cx(styles.invoicesTableWrapper, className)} id={id}>
    {isFetching && <Loading />}
    {!isFetching && <div className={styles.emptyState}>{children}</div>}
  </div>
);

EmptyState.propTypes = {
  isFetching: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

export default EmptyState;
