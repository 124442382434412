import { JsonApiResponse } from 'json-api-normalizer';

import api from '../index';

type SendProposalData = {
  subject: string | null;
  content: string;
  email: string;
  sendCopyToMe: boolean;
  carbonCopy: string;
};

export const sendProposalByEmail = (
  id: string,
  data: SendProposalData,
  grecaptchaResponse: string
) => {
  const url = `/me/proposals/${id}/send_by_email`;

  return api.put<JsonApiResponse>(url, {
    email: data.email,
    subject: data.subject,
    content: data.content,
    send_copy_to_me: data.sendCopyToMe,
    cc_email: data.carbonCopy,
    g_recaptcha_response: grecaptchaResponse,
  });
};
