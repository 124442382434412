export const getItem = (key) => () => {
  if (typeof window === 'undefined') {
    return null;
  }
  // eslint-disable-next-line no-undef
  return localStorage.getItem(key);
};

export const setItem = (key) => (value) => {
  if (typeof window === 'undefined') {
    return null;
  }
  // eslint-disable-next-line no-undef
  return localStorage.setItem(key, value);
};

export const clearItem = (key) => () => {
  if (typeof window === 'undefined') {
    return null;
  }
  // eslint-disable-next-line no-undef
  return localStorage.removeItem(key);
};

export const clearStore = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  // eslint-disable-next-line no-undef
  return localStorage.clear();
};
