import React, { memo, SVGProps } from 'react';

const Gift = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M29.933 13.596h-27.9V7.783h27.9zM28.9 13.596v15.5H3.067v-15.5z" />
      <path
        d="M3.067 30.064c-.571 0-1.034-.433-1.034-.968V14.564c-.57 0-1.033-.433-1.033-.968V7.783c0-.535.463-.969 1.033-.969h8.287C8.275 5.086 7.48 2.797 9.03 1.566c1.436-1.138 4.129-.47 6.24 1.22.062.049.114.1.16.156l.007.01.159.138c.339.302.641.62.9.944a7.94 7.94 0 011.259-1.248c2.112-1.689 4.808-2.361 6.233-1.217 1.533 1.232.717 3.508-1.322 5.247h7.266c.571 0 1.034.433 1.034.968v5.813c0 .535-.463.968-1.034.968v14.532c0 .535-.462.968-1.033.968zM4.1 28.126h10.85V14.564H4.099L4.1 28.126zm23.766-13.562h-10.85v13.562h10.85V14.564zM14.794 8.752L3.067 8.751v3.875H14.95V8.754c-.051 0-.104 0-.156-.002zm3.15 0l-.928-.001v3.875H28.9V8.751H18.205a4.208 4.208 0 01-.262 0zm-4.377-4.766c-1.345-.941-2.793-1.265-3.201-.941-.086.068-.116.263.028.65.208.559.72 1.212 1.451 1.797.987.79 2.097 1.236 2.835 1.323h.472a.56.56 0 00.259-.103.148.148 0 00.043-.063l-.004-.093c0-.061.001-.124.005-.187a1.7 1.7 0 00-.092-.308c-.22-.563-.74-1.22-1.468-1.803l-.086-.075-.051-.058zM22.584 3c-.51-.27-2.114.162-3.487 1.26-.73.583-1.249 1.24-1.464 1.804a1.836 1.836 0 00-.09.302c.008.095.01.19.005.284.01.029.025.05.043.065.06.048.144.081.248.1h.48c.736-.09 1.843-.535 2.816-1.316.73-.59 1.248-1.248 1.464-1.812.15-.39.123-.584.045-.647z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(Gift);
