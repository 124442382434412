import { CATEGORY_LOGO_AND_BRIEFPAPIER } from 'constants/piwik';
import { piwikHelpers } from 'shared/utils/piwik';
import { InvoiceTemplate } from 'types/entities/InvoiceTemplate';

import { FormData } from './types';

export const trackEvent = piwikHelpers.trackEventCurried(CATEGORY_LOGO_AND_BRIEFPAPIER);

export const getDefaultValues = (invoiceTemplate: InvoiceTemplate): FormData => ({
  logo: invoiceTemplate.logo,
  logoSizePercentage: invoiceTemplate.logoSizePercentage,
  logoPosition: invoiceTemplate.logoPosition,
  background: invoiceTemplate.background,
  logoOnAllPages: invoiceTemplate.logoOnAllPages,
  backgroundOnlyOnFirstPage: invoiceTemplate.backgroundOnlyOnFirstPage,
});
