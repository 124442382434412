import React, { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDebounce, usePreviousDistinct } from 'react-use';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual, snakeCase } from 'lodash';

import { showSuccessNotification } from 'actions/notification';
import { updateInvoiceTemplate } from 'api/me/invoiceTemplates';
import {
  TABLE_DEFAULTS_DOWNLOAD,
  TABLE_DEFAULTS_PREVIEW_MODE_DOWNLOAD,
  TABLE_DEFAULTS_PREVIEW_MODE_ENTER,
  TABLE_DEFAULTS_SAVE,
} from 'constants/piwik';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { InvoiceTemplate } from 'types/entities/InvoiceTemplate';
import Button from 'redesign/components/atoms/Button/Button';
import FieldWrapper from 'redesign/components/molecules/FieldWrapper/FieldWrapper';
import { InputField, InputFieldProps } from 'redesign/components/molecules/InputField/InputField';
import RouteLeavingGuard from 'redesign/components/organisms/RouteLeavingGuard/RouteLeavingGuard';

import DiscardChangesModal from '../DiscardChangesModal/DiscardChangesModal';
import Preview from '../Preview/Preview';
import {
  Buttons,
  Content,
  Description,
  FormWrapper,
  IconArrowDown,
  PreviewWrapper,
  Section,
  Title,
  Wrapper,
} from './Form.styled';
import { FormData } from './types';
import { getDefaultValues, trackEvent } from './utils';
import validationSchema from './validationSchema';

type FormProps = {
  invoiceTemplate: InvoiceTemplate;
};

const Form = ({ invoiceTemplate }: FormProps) => {
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState('headings');
  const [defaultValues, setDefaultValues] = useState(getDefaultValues(invoiceTemplate));
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isValid, isDirty, isSubmitSuccessful },
  } = useForm<FormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const [previewValues, setPreviewValues] = useState(defaultValues);
  const values = watch();
  const previousValues = usePreviousDistinct(values, isEqual);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    await updateInvoiceTemplate(invoiceTemplate.id, data);
    setDefaultValues(data);
    trackEvent(TABLE_DEFAULTS_SAVE);
    dispatch(showSuccessNotification(t('features.settings.document_template.table.save_success')));
  };

  const handleTitleClick = useCallback(
    (panel: string) => () => {
      setExpanded((active) => (active === panel ? '' : panel));
    },
    []
  );

  const handleOpenPreview = useCallback(() => {
    trackEvent(TABLE_DEFAULTS_PREVIEW_MODE_ENTER);
  }, []);

  const handleDownload = useCallback((isFromFullscreeenPreview: boolean) => {
    trackEvent(
      isFromFullscreeenPreview ? TABLE_DEFAULTS_PREVIEW_MODE_DOWNLOAD : TABLE_DEFAULTS_DOWNLOAD
    );
  }, []);

  useDebounce(
    () => {
      if (!isValid || isEqual(previewValues, values)) return;

      setPreviewValues(values);
    },
    500,
    [isValid, values]
  );

  useDebounce(
    () => {
      if (!isValid || !previousValues) return;

      Object.entries(values)
        .filter(([key, value]) => !!value && previousValues![key as keyof FormData] !== value)
        .forEach(([key, value]) => trackEvent(`change-${snakeCase(key)}`, value));
    },
    500,
    [isValid, previousValues]
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset, defaultValues]);

  return (
    <Wrapper>
      <RouteLeavingGuard blocked={isDirty} modal={DiscardChangesModal} />
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Section $isOpen={expanded === 'headings'}>
            <Title onClick={handleTitleClick('headings')}>
              {t('features.settings.document_template.table.headings.title')}
              <IconArrowDown $isRotated={expanded === 'headings'} />
            </Title>
            <Content>
              <Description>
                {t('features.settings.document_template.table.headings.description')}
              </Description>
              <Controller
                name="tableHeaderPositionText"
                control={control}
                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                  <FieldWrapper<InputFieldProps>
                    label={t(
                      'features.settings.document_template.table.fields.table_header_position_text'
                    )}
                    field={InputField}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    error={error?.message}
                  />
                )}
              />
              <Controller
                name="tableHeaderDescriptionText"
                control={control}
                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                  <FieldWrapper<InputFieldProps>
                    label={t(
                      'features.settings.document_template.table.fields.table_header_description_text'
                    )}
                    field={InputField}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    error={error?.message}
                  />
                )}
              />
              <Controller
                name="tableHeaderVatText"
                control={control}
                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                  <FieldWrapper<InputFieldProps>
                    label={t(
                      'features.settings.document_template.table.fields.table_header_vat_text'
                    )}
                    field={InputField}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    error={error?.message}
                  />
                )}
              />
              <Controller
                name="tableHeaderQuantityText"
                control={control}
                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                  <FieldWrapper<InputFieldProps>
                    label={t(
                      'features.settings.document_template.table.fields.table_header_quantity_text'
                    )}
                    field={InputField}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    error={error?.message}
                  />
                )}
              />
              <Controller
                name="tableHeaderUnitText"
                control={control}
                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                  <FieldWrapper<InputFieldProps>
                    label={t(
                      'features.settings.document_template.table.fields.table_header_unit_text'
                    )}
                    field={InputField}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    error={error?.message}
                  />
                )}
              />
              <Controller
                name="tableHeaderNetAmountText"
                control={control}
                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                  <FieldWrapper<InputFieldProps>
                    label={t(
                      'features.settings.document_template.table.fields.table_header_net_amount_text'
                    )}
                    field={InputField}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    error={error?.message}
                  />
                )}
              />
              <Controller
                name="tableHeaderTotalLineNetAmountText"
                control={control}
                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                  <FieldWrapper<InputFieldProps>
                    label={t(
                      'features.settings.document_template.table.fields.table_header_total_line_net_amount_text'
                    )}
                    field={InputField}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    error={error?.message}
                  />
                )}
              />
            </Content>
          </Section>
          <Section $isOpen={expanded === 'invoice'}>
            <Title onClick={handleTitleClick('invoice')}>
              {t('features.settings.document_template.table.invoice.title')}
              <IconArrowDown $isRotated={expanded === 'invoice'} />
            </Title>
            <Content>
              <Description>
                {t('features.settings.document_template.table.invoice.description')}
              </Description>
              <Controller
                name="tableSummaryNetTotalText"
                control={control}
                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                  <FieldWrapper<InputFieldProps>
                    label={t(
                      'features.settings.document_template.table.fields.table_summary_net_total_text'
                    )}
                    field={InputField}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    error={error?.message}
                  />
                )}
              />
              <Controller
                name="tableSummaryGrossTotalText"
                control={control}
                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                  <FieldWrapper<InputFieldProps>
                    label={t(
                      'features.settings.document_template.table.fields.table_summary_gross_total_text'
                    )}
                    field={InputField}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    error={error?.message}
                  />
                )}
              />
            </Content>
          </Section>
          <Buttons>
            <Button type="submit">{t('features.settings.document_template.table.save')}</Button>
          </Buttons>
        </div>
      </FormWrapper>
      <PreviewWrapper>
        <Preview
          values={{
            table_header_description_text: previewValues.tableHeaderDescriptionText,
            table_header_net_amount_text: previewValues.tableHeaderNetAmountText,
            table_header_position_text: previewValues.tableHeaderPositionText,
            table_header_quantity_text: previewValues.tableHeaderQuantityText,
            table_header_total_line_net_amount_text:
              previewValues.tableHeaderTotalLineNetAmountText,
            table_header_unit_text: previewValues.tableHeaderUnitText,
            table_header_vat_text: previewValues.tableHeaderVatText,
            table_summary_gross_total_text: previewValues.tableSummaryGrossTotalText,
            table_summary_net_total_text: previewValues.tableSummaryNetTotalText,
          }}
          onOpenPreview={handleOpenPreview}
          onDownload={handleDownload}
        />
      </PreviewWrapper>
    </Wrapper>
  );
};

export default Form;
