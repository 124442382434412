import { parse } from 'query-string';

export const getBlzCode = ({ location }) => {
  const query = parse(location.search);
  return { blz: query.blz || '' };
};

export const getEmail = ({ location }) => {
  const query = parse(location.search);
  return { email: query.email || '' };
};

export default {};
