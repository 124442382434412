import styled from 'styled-components';

import ArrowLeft from 'redesign/components/atoms/Icons/ArrowLeft';
import ArrowRight from 'redesign/components/atoms/Icons/ArrowRight';

type VictoryChartContainerProps = {
  height: number;
  padding: number;
};

export const VictoryChartContainer = styled.div<VictoryChartContainerProps>`
  width: 100%;
  height: ${({ height }) => `${height}px`};
  padding: ${({ padding }) => `0 ${padding}px`};
`;

export const LeftArrow = styled(ArrowLeft)`
  cursor: pointer;
`;

export const RightArrow = styled(ArrowRight)`
  cursor: pointer;
`;
