import React from 'react';

const Proposals = ({ dataId = '', className = '' }) => (
  <svg width={308} height={147} data-id={dataId} className={className}>
    <defs>
      <filter
        x="-9.7%"
        y="-7%"
        width="119.3%"
        height="119.7%"
        filterUnits="objectBoundingBox"
        id="prefix__a"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-29.7%"
        y="-19%"
        width="159.4%"
        height="145%"
        filterUnits="objectBoundingBox"
        id="prefix__c"
      >
        <feOffset dy={4} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={8} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <ellipse id="prefix__b" cx={36.219} cy={35.5} rx={36.219} ry={35.5} />
      <path id="prefix__d" d="M.289.289h87.615V115.78H.289z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M111 42c28.697 9.811-2.983-10.73 19-20s64.654 11 85 11 36.971-16.555 54.6-2.847C287.228 43.861 319.789 35.468 295 71c-24.789 35.532-3.026 19.174-44.4 54.153C209.226 160.132 158 122 130 115s-50.373 2.687-77-11c-26.627-13.687-28.654-5.924-37.256-18.09C7.14 73.742 32.864 50.84 38.6 37.152 44.335 23.466 82.303 32.189 111 42z"
        fill="#F3F3F3"
      />
      <path
        d="M39 78c-5.528 25.823 8.09 32.945 40.854 21.367C129 82 173.823 110.14 165 73c-8.823-37.14-74.853 31.01 91.305 5.476"
        stroke="#F60"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="4,4"
      />
      <g transform="translate(4 38)">
        <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
        <use fill="#FFF" xlinkHref="#prefix__b" />
      </g>
      <g transform="translate(204.711 11.711)">
        <use fill="#000" filter="url(#prefix__c)" xlinkHref="#prefix__d" />
        <use fill="#FFF" xlinkHref="#prefix__d" />
      </g>
      <path
        d="M218.318 35.92h21.739c.757 0 1.37-.6 1.37-1.34 0-.74-.613-1.34-1.37-1.34h-21.74c-.756 0-1.37.6-1.37 1.34 0 .74.614 1.34 1.37 1.34zm-.004 29.48l57.545.168c.756.003 1.372-.596 1.374-1.336.002-.74-.61-1.341-1.366-1.344l-57.545-.167c-.757-.002-1.372.596-1.374 1.336-.003.74.609 1.342 1.366 1.344zm.403-24.12H237.1c.756 0 1.37-.6 1.37-1.34 0-.74-.614-1.34-1.37-1.34h-18.383c-.756 0-1.37.6-1.37 1.34 0 .74.614 1.34 1.37 1.34zm-.005 29.481l43.444.168c.757.002 1.373-.595 1.376-1.335.003-.74-.608-1.343-1.365-1.345l-43.444-.168c-.757-.003-1.373.595-1.376 1.335-.003.74.608 1.342 1.365 1.345zm-.394-24.12h21.739c.757 0 1.37-.6 1.37-1.34 0-.74-.613-1.34-1.37-1.34h-21.74c-.756 0-1.37.6-1.37 1.34 0 .74.614 1.34 1.37 1.34zm-.004 29.48l60.285.168c.757.002 1.372-.596 1.374-1.337.002-.74-.61-1.341-1.366-1.343l-60.285-.168c-.757-.002-1.372.596-1.374 1.336-.003.74.609 1.342 1.366 1.344zm.689 34.686h15.071c.378 0 .685-.378.685-.844 0-.466-.307-.843-.685-.843h-15.071c-.379 0-.685.377-.685.843 0 .466.306.844.685.844zm26.717 0h15.071c.379 0 .685-.378.685-.844 0-.466-.306-.843-.685-.843H245.72c-.378 0-.685.377-.685.843 0 .466.307.844.685.844zm-26.717 4.02h10.96c.379 0 .686-.377.686-.843s-.307-.844-.685-.844h-10.961c-.379 0-.685.378-.685.844s.306.843.685.843zm26.717 0h10.961c.378 0 .685-.377.685-.843s-.307-.844-.685-.844H245.72c-.378 0-.685.378-.685.844s.307.843.685.843zm-26.717 4.02h15.071c.378 0 .685-.377.685-.843 0-.466-.307-.844-.685-.844h-15.071c-.379 0-.685.378-.685.844 0 .466.306.843.685.843zm26.717 0h15.071c.379 0 .685-.377.685-.843 0-.466-.306-.844-.685-.844H245.72c-.378 0-.685.378-.685.844 0 .466.307.843.685.843z"
        fill="#979797"
        fillRule="nonzero"
      />
      <g fill="#2382C8">
        <path d="M279.602 90.395h-10.985c-.674 0-1.225-.56-1.225-1.256 0-.695.55-1.258 1.225-1.258h10.985c.677 0 1.224.563 1.224 1.258 0 .695-.548 1.256-1.225 1.256m.001 3.735h-10.985c-.674 0-1.225-.563-1.225-1.258 0-.691.55-1.255 1.225-1.255h10.985c.677 0 1.224.56 1.224 1.257 0 .697-.548 1.26-1.225 1.26" />
        <path d="M278.254 99.482c-4.815 0-8.733-3.84-8.733-8.56 0-4.722 3.918-8.564 8.733-8.564a8.86 8.86 0 013.34.652c.637.26.94.978.675 1.603-.261.62-.99.917-1.627.657a6.35 6.35 0 00-2.386-.465c-3.44 0-6.237 2.744-6.237 6.117 0 3.373 2.795 6.115 6.237 6.115.822 0 1.626-.155 2.386-.465a1.256 1.256 0 011.629.664 1.21 1.21 0 01-.674 1.598 8.816 8.816 0 01-3.34.653" />
      </g>
      <g fillRule="nonzero">
        <path
          d="M28.473 92.975h23.405V57.847H28.473v35.128zm24.301 1.792H27.576a.896.896 0 01-.896-.896v-36.92c0-.495.401-.896.896-.896h25.198c.495 0 .897.401.897.896v36.92a.896.896 0 01-.897.896z"
          fill="#005CA9"
        />
        <path
          d="M43.567 63.264H32.422a.896.896 0 010-1.792h11.145a.896.896 0 010 1.792m2.655 5.333h-13.8a.896.896 0 010-1.793h13.8a.896.896 0 110 1.793m-4.858 5.332h-8.942a.896.896 0 110-1.792h8.942a.896.896 0 010 1.792m2.203 5.333H32.422a.896.896 0 010-1.792h11.145a.896.896 0 010 1.792"
          fill="#EB6608"
        />
        <path
          d="M34 86.198zm1.577-2.77a.213.213 0 00-.087.016c-.054.024-.157.109-.263.407-.312.876-.233 1.9-.163 2.803.004.055.007.109.008.16.103-.062.204-.126.305-.19.355-.783.626-1.592.749-2.431.023-.158.041-.333-.003-.431a.53.53 0 00-.175-.186.683.683 0 00-.371-.149zm-2.088 3.715c.018.075.05.18.108.32.023.059.026.065.188.126.103-.138.173-.245.216-.355l-.027-.036a3.264 3.264 0 01-.324-.034l-.161-.02zm.601 3.243a.506.506 0 01-.424-.783c.241-.37.482-.745.714-1.126l-.033.041a.506.506 0 01-.639.137c-.08-.043-.161-.073-.247-.105-.258-.096-.61-.229-.804-.708-.179-.444-.343-1.004-.031-1.404.308-.395.822-.325 1.162-.278.07.01.169.023.226.025-.054-.859-.058-1.786.258-2.673.179-.504.44-.829.8-.99.446-.204 1-.115 1.48.24.238.178.4.37.497.588.149.336.126.674.08.99a9.3 9.3 0 01-.463 1.806.51.51 0 01.172.22l.292.725.321-.25a.507.507 0 01.608-.012c.199.143.367.32.515.474.21.22.39.41.577.438.056.008.113.006.169.006.131 0 .294 0 .469.05.295.085.507.277.677.432.073.066.21.191.254.193.03-.003.106-.024.159-.04a6.021 6.021 0 011.588-.269.507.507 0 01.032 1.013c-.44.014-.876.087-1.296.216a1.627 1.627 0 01-.522.092c-.413-.016-.693-.27-.897-.456-.096-.087-.205-.187-.274-.206-.09-.026-.3.002-.51-.03-.529-.08-.878-.445-1.16-.74l-.099-.103-.23.18c-.091.07-.367.283-.714.18-.328-.098-.45-.4-.49-.499l-.159-.394c-.473.975-1.053 1.9-1.633 2.79a.507.507 0 01-.425.23z"
          fill="#005CA9"
        />
      </g>
      <g strokeLinecap="round" strokeLinejoin="round">
        <path stroke="#FFF" strokeWidth={4} d="M188.625 103.197l3.547 4.778 7.23-12.742" />
        <path
          stroke="#FFF"
          strokeWidth={4}
          d="M192.69 103.197l3.547 4.778 7.23-12.742m-3.037 26.82c2.31-3.203 6.487-9.75 6.487-9.75a3.75 3.75 0 00-4.957-.15l-5.528 5.55h-5.58c-1.162 0-1.035-2.063 0-2.063 7.673 0 6.465-4.17 6.465-4.17h-10.845l-6.75 7.08 5.168 7.305h8.167a9.097 9.097 0 007.373-3.803zm-17.633 5.714l-5.714-8.1"
        />
        <path stroke="#F60" strokeWidth={2} d="M188.625 103.197l3.547 4.778 7.23-12.742" />
        <path stroke="#0066B3" strokeWidth={2} d="M192.69 103.197l3.547 4.778 7.23-12.742" />
        <path
          d="M200.43 122.052c2.31-3.202 6.487-9.75 6.487-9.75a3.75 3.75 0 00-4.957-.15l-5.528 5.55h-5.58c-1.162 0-1.035-2.062 0-2.062 7.673 0 6.465-4.17 6.465-4.17h-10.845l-6.75 7.08 5.168 7.305h8.167a9.097 9.097 0 007.373-3.803z"
          stroke="#0066B3"
          strokeWidth={2}
          fill="#FFF"
          fillRule="nonzero"
        />
        <path stroke="#F60" strokeWidth={2} d="M182.797 127.767l-5.714-8.1" />
      </g>
      <g>
        <path
          stroke="#0066B3"
          strokeWidth={2}
          strokeLinejoin="round"
          d="M92 65h21.774v16.935H92z"
        />
        <path
          stroke="#0066B3"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M113.774 65l-10.887 10.484L92 65"
        />
        <path
          fill="#F60"
          fillRule="nonzero"
          d="M117 81.535l-5.387-4.438v3.1h-5.097v3.06h5.097v2.71z"
        />
        <path
          stroke="#F60"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M117 81.535l-5.387-4.438v3.1h-5.097v3.06h5.097v2.71z"
        />
      </g>
    </g>
  </svg>
);

export default Proposals;
