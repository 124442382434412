import React from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';

import ConfirmedSign from 'images/icon-confirmation.svg';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import AccountInfoPublicArea from 'components/AccountInfoPublicArea/AccountInfoPublicArea';

import styles from './AccountAlreadyConfirmed.module.css';

type AccountAlreadyConfirmedProps = {
  push: (path: string) => void;
};

const AccountAlreadyConfirmed = ({ push }: AccountAlreadyConfirmedProps) => (
  <AccountInfoPublicArea
    headingText={t('registration_confirmation.account_already_confirmed.heading')}
    icon={ConfirmedSign}
    infoText={[
      t('registration_confirmation.account_already_confirmed.text_1'),
      t('registration_confirmation.account_already_confirmed.text_2'),
    ]}
    buttonLabel={t('registration_confirmation.account_already_confirmed.button_label')}
    iconClassName={styles.icon}
    onClick={() => push(paths.login)}
  />
);

const mapDispatchToProps = {
  push: pushAction,
};

export default connect(null, mapDispatchToProps)(AccountAlreadyConfirmed);
