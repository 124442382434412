import { all, complement, flow, identity, map } from 'lodash/fp';
import { all as waitAll, cancel, fork, put, take, takeEvery } from 'redux-saga/effects';

import { fetchOverview } from 'actions/monthly-overview/monthly-overview';
import { fetchQuarterlySummary } from 'actions/quarterly-summary';
import { fetchSummary } from 'actions/summary';
import { DELETE_SUCCESS as INCOMING_INVOICE_DELETED } from 'constants/incoming-invoices';
import { DELETE_SUCCESS as OUTGOING_INVOICE_DELETED } from 'constants/outgoing-invoice';
import paths from 'routes/paths';
import { isRouteAction } from 'shared/utils';
import { deleteContractSuccess } from 'features/contracts/contractsSlice';

const isDashboardPath = ({ payload }) => payload && payload.pathname === paths.home;

const enteredDashboardConditions = [isRouteAction, isDashboardPath];
const leftDashboardConditions = [isRouteAction, complement(isDashboardPath)];

export const dashboardDataMutatingActions = [
  OUTGOING_INVOICE_DELETED,
  INCOMING_INVOICE_DELETED,
  deleteContractSuccess.toString(),
];

const validateActionAgainstConditions = (action) =>
  flow(
    map((condition) => condition(action)),
    all(identity)
  );

export const userHasEnteredDashboard = (action) =>
  validateActionAgainstConditions(action)(enteredDashboardConditions);

export const userHasLeftDashboard = (action) =>
  validateActionAgainstConditions(action)(leftDashboardConditions);

function* fetchDashboardData() {
  yield waitAll([put(fetchOverview(true, 5)), put(fetchSummary()), put(fetchQuarterlySummary())]);
}

export function* watchDashboardDataChange() {
  yield takeEvery(dashboardDataMutatingActions, fetchDashboardData);
}

export default function* watchDashboardEnter() {
  while (yield take(userHasEnteredDashboard)) {
    const backgroundTask = yield fork(watchDashboardDataChange);
    yield take(userHasLeftDashboard);
    yield cancel(backgroundTask);
  }
}
