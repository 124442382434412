import { fromPairs } from 'lodash';

import paths from 'routes/paths';

const INVOICE_TEMPLATE_FIELDS = {
  editInfo: ['qrCodesEnabled', 'show_validity_information'],
  editInfoPremium: [
    'show_sender_address',
    'show_contact_details',
    'show_footer',
    'qrCodesEnabled',
    'show_validity_information',
  ],
};

/**
 * Return invoice templates fields group name based on url and isInvoiceLayoutEnabled.
 */
const getInvoiceTemplateTab = (path: string, isInvoiceLayoutEnabled: boolean) => {
  switch (path) {
    case paths.settingsTemplateEdit:
      return isInvoiceLayoutEnabled ? 'editInfoPremium' : 'editInfo';
  }
};

/**
 * Get an object of visible fields on invoice templates form.
 * Visible fields are established based on current url and payment plan.
 * Visible fields are required by InvoiceTemplate component for resetting template.
 */
export const getInvoiceTemplateVisibleFields = ({
  path,
  isInvoiceLayoutEnabled,
}: {
  path: string;
  isInvoiceLayoutEnabled: boolean;
}) => {
  const tabName = getInvoiceTemplateTab(path, isInvoiceLayoutEnabled);

  if (!tabName) return {};

  return fromPairs(INVOICE_TEMPLATE_FIELDS[tabName].map((field: string) => [field, true]));
};
