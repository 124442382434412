import styled from 'styled-components';

import { Breakpoints } from 'redesign/styles/breakpoints';

import BaseBox from '../Box/Box';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
`;

type BoxProps = {
  $size?: 'small' | 'medium' | 'large' | 'full-screen';
};

export const Box = styled(BaseBox)<BoxProps>`
  max-width: calc(100% - 0.5rem);
  max-height: calc(100% - 0.5rem);
  padding: ${({ theme }) => theme.space['4']};
  overflow-y: auto;
  width: ${({ $size }) =>
    $size === 'small'
      ? '320px'
      : $size === 'medium'
      ? '560px'
      : $size === 'large'
      ? '880px'
      : '100%'};
  height: ${({ $size }) => ($size === 'full-screen' ? '100%' : 'auto')};
  position: relative;
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.sm}) {
    max-width: calc(100% - 1rem);
    max-height: calc(100% - 1rem);
  }

  @media (min-width: ${Breakpoints.md}) {
    max-width: calc(100% - 2rem);
    max-height: calc(100% - 2rem);
  }
`;
