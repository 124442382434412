import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';

import { fetchDirectDebit as fetchDirectDebitAction } from 'actions/direct-debit';
import { fetchPaymentsBankAccounts as fetchPaymentsBankAccountsAction } from 'actions/incoming-invoice/payments';
import { fetchPlans as fetchPlansAction } from 'actions/payment-plans';
import { PREMIUM_UID } from 'constants/payment-plans';
import { plansSelector } from 'selectors/payment-plans';
import { t } from 'shared/utils';
import { filteredPlans } from 'shared/utils/payment-plans';
import getDaysToPremiumBilling from 'shared/utils/premium-billing-date';
import LoadingIcon from 'components/LoadingIcon';
import Modal from 'components/Modal';

import ComparisonBox from '../ComparisonBox/ComparisonBox';
import CurrentPlanBadge from '../ComparisonBox/components/CurrentPlanBadge/CurrentPlanBadge';
import ComparisonTable from '../ComparisonTable/ComparisonTable';
import LegalsDescription from '../ModalsContainer/components/LegalsDescription/LegalsDescription';
import ModalsLayout from '../ModalsContainer/ModalsLayout/ModalsLayout';
import PaymentPlanWizardCommon from './components/PaymentPlanWizardCommon/PaymentPlanWizardCommon';
import PaymentPlanWizardFooter from './components/PaymentPlanWizardFooter/PaymentPlanWizardFooter';
import PaymentPlanWizardHeader from './components/PaymentPlanWizardHeader/PaymentPlanWizardHeader';
import { shouldDisplyaModal } from './utils';

import styles from './PaymentPlanWizard.module.css';

class PaymentPlanWizard extends Component {
  state = {
    modalType: null,
    isWizardOpen: false,
  };

  comparisonTableRef = React.createRef();

  componentDidUpdate(prevProps, prevState) {
    const {
      currentPaymentPlanDetails: prevCurrentPaymentPlanDetails,
      serializedAccountCreationDate: prevSerializedAccountCreationDate,
    } = prevProps;
    const { currentPaymentPlanDetails, serializedAccountCreationDate } = this.props;
    const { isWizardOpen } = prevState;

    if (
      (!isWizardOpen &&
        isEmpty(prevCurrentPaymentPlanDetails) &&
        !isEmpty(currentPaymentPlanDetails)) ||
      prevSerializedAccountCreationDate !== serializedAccountCreationDate
    ) {
      const { shouldOpenModal } = shouldDisplyaModal(currentPaymentPlanDetails) || {};

      if (shouldOpenModal) {
        this.fetchModalData();
        // this is state change due to asynchronous update from api
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ isWizardOpen: shouldOpenModal });
      }
    }
  }

  fetchModalData = () => {
    const { fetchPaymentsBankAccounts, fetchPlans, fetchDirectDebit } = this.props;

    fetchPaymentsBankAccounts();
    fetchPlans();
    fetchDirectDebit();
  };

  get shouldInitiallySelectMainBankAccount() {
    const { paymentsBankAccounts } = this.props;
    const isMainBankAccountPresent = paymentsBankAccounts.find((account) => account.main);

    return !!isMainBankAccountPresent;
  }

  setModalToRender = (modalType) => this.setState({ modalType });

  closeWizard = () => this.setState({ isWizardOpen: false });

  handleScrollToTable = () =>
    this.comparisonTableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  renderContent = () => {
    const { plans = [], currentPaymentPlanDetails = {} } = this.props;
    const { modalType } = this.state;
    const plansPresentedForUser = filteredPlans(plans);

    if (modalType)
      return (
        <ModalsLayout
          {...this.props}
          closePaymentPlanWizard={this.closeWizard}
          onClose={() => this.setModalToRender(null)}
          modalType={modalType}
          setModalToRender={this.setModalToRender}
          shouldInitiallySelectMainBankAccount={this.shouldInitiallySelectMainBankAccount}
          plans={plansPresentedForUser}
        />
      );

    const { displayDate } = getDaysToPremiumBilling(currentPaymentPlanDetails.trialEndDate);

    return (
      <Grid className={styles.fullHeight} fluid>
        <Row center="xs">
          <Col xs={12} lg={8}>
            <PaymentPlanWizardHeader displayDate={displayDate} />
          </Col>
          <Col xs={11} lg={7}>
            <Row>
              {plansPresentedForUser.map((plan, index) => (
                <Col xs={12} lg={6} className={styles.centerContent} key={index}>
                  {plan.uid === PREMIUM_UID && (
                    <CurrentPlanBadge label={t('payment_plan_wizard.plan_badge')} />
                  )}
                  <ComparisonBox
                    handleModalOpen={this.setModalToRender}
                    handleScrollToTable={this.handleScrollToTable}
                    plan={plan.uid}
                    details={plan}
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={12} lg={8} className={styles.spacer}>
            <ComparisonTable
              plans={plansPresentedForUser}
              tableRef={this.comparisonTableRef}
              handleModalOpen={this.setModalToRender}
            />
            <LegalsDescription />
          </Col>
          <Col xs={12}>
            <PaymentPlanWizardFooter />
          </Col>
        </Row>
      </Grid>
    );
  };

  render() {
    const { isWizardOpen } = this.state;
    const { isFetching } = this.props;

    return (
      isWizardOpen && (
        <Modal isOpen={isWizardOpen} onRequestClose={this.closeWizard} className={styles.container}>
          {isFetching ? (
            <div className={styles.loading}>
              <LoadingIcon />
            </div>
          ) : (
            <>
              <PaymentPlanWizardCommon closeWizard={this.closeWizard} />
              {this.renderContent()}
            </>
          )}
        </Modal>
      )
    );
  }
}

PaymentPlanWizard.propTypes = {
  serializedAccountCreationDate: string,
  fetchPaymentsBankAccounts: func.isRequired,
  fetchPlans: func.isRequired,
  fetchDirectDebit: func.isRequired,
  isFetching: bool.isRequired,
  paymentsBankAccounts: arrayOf(
    shape({
      accountId: string,
      action: shape({
        sync: bool,
      }),
      balance: number,
      bankCode: string,
      bankIdentifier: string,
      bankName: string,
      bic: string,
      credentials: arrayOf(
        shape({
          label: string,
          masked: bool,
        })
      ),
      creditLine: number,
      fetchingInProgress: bool,
      fetchingSuccessful: bool,
      figoBalanceDate: string,
      iban: string,
      id: string,
      main: bool,
      name: string,
      number: string,
      paymentApplicable: bool,
      savePin: bool,
      showOnInvoice: bool,
      updatedAt: string,
    })
  ),
  currentPaymentPlanDetails: shape({
    id: string,
    defaultPrice: number,
    discountAmount: number,
    trialEndDate: string,
    name: string,
    onTrial: bool,
    uid: string,
    vrMainBankAccount: bool,
    price: number,
    latestHistoryEntryMode: string,
  }),
  plans: arrayOf(
    shape({
      name: string,
      uid: string,
      price: number,
      defaultPrice: number,
      vrMainBankAccount: bool,
      discountAmount: oneOfType([number, string]),
      id: string,
    })
  ),
};

const mapStateToProps = (state) => ({
  isPaymentsBankAccountsFetching: state.incomingInvoice.paymentsBankAccounts.isFetching,
  paymentsBankAccounts: state.incomingInvoice.paymentsBankAccounts.list,
  currentPaymentPlanDetails: state.paymentPlan.details,
  serializedAccountCreationDate: state.profile.credentials.createdAt,
  isFetching: state.paymentPlans.isFetching,
  plans: plansSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchPaymentsBankAccounts: () => dispatch(fetchPaymentsBankAccountsAction()),
  fetchPlans: () => dispatch(fetchPlansAction()),
  fetchDirectDebit: () => dispatch(fetchDirectDebitAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPlanWizard);
