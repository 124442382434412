import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { reorderLineItems as reorderLineItemsAction } from 'actions/delivery-note';
import { fetchProductCatalogGroup } from 'actions/product-catalog/groups/groups';
import { IMPORT_MODE, NEW_MODE } from 'constants/common/crud';
import { deliveryNoteHasLineItems } from 'reducers';
import { AddLineItemButton } from 'components/AddLineItemButton/AddLineItemButton';
import Card from 'components/Card';
import I18n from 'components/I18n';
import { LineItemsOrderManager } from 'components/LineItems';

import Table from './Table/Table';

import styles from './DeliveryNoteDetailsSection.module.css';

const DeliveryNoteDetailsSection = ({
  readonly,
  crudMode,
  hasLineItems,
  deliveryNoteId,
  lineItems,
  reorderLineItems,
}) => {
  const [newItemsId, setNewItemsId] = useState(0);
  const [newItems, setNewItems] = useState([]);
  const hasProperCrudMode = [NEW_MODE, IMPORT_MODE].includes(crudMode);

  const queueItem = useCallback((item = {}, prefilled = false) => {
    setNewItemsId(newItemsId + 1);
    setNewItems([
      ...newItems,
      {
        markAsAccepted: prefilled,
        rowId: newItemsId,
        ...item,
        prefilled,
      },
    ]);
  }, []);

  useEffect(() => {
    if (!readonly && !hasLineItems && hasProperCrudMode && isEmpty(newItems)) queueItem();
  }, [readonly, hasLineItems, newItems]);

  const removeItem = (id) => () => {
    const toRemoveIndex = newItems.findIndex((a) => a.rowId === id);

    setNewItems([...newItems.slice(0, toRemoveIndex), ...newItems.slice(toRemoveIndex + 1)]);
  };

  return (
    <Card className={cx(styles.main, { [styles.readonly]: readonly })}>
      <Card.Header className={styles.heading}>
        <div className={styles.headingNameContainer}>
          <I18n className={styles.title} t="features.delivery_note.form.details_section.heading" />
        </div>
      </Card.Header>
      <Card.Body className={styles.cardBody}>
        <LineItemsOrderManager
          invoiceId={deliveryNoteId}
          lineItems={lineItems}
          updateLineItemsOrder={reorderLineItems}
        >
          <Table
            newItems={newItems}
            lineItems={lineItems}
            removeNewItem={removeItem}
            crudMode={crudMode}
            readonly={readonly}
          />
        </LineItemsOrderManager>
        <div className={styles.actionsContainer}>
          {!readonly && <AddLineItemButton onClick={() => queueItem()} />}
        </div>
      </Card.Body>
    </Card>
  );
};

DeliveryNoteDetailsSection.propTypes = {
  readonly: PropTypes.bool,
  fetchProductCatalogGroup: PropTypes.func.isRequired,
  reorderLineItems: PropTypes.func.isRequired,
  hasLineItems: PropTypes.bool,
  deliveryNoteId: PropTypes.string,
  crudMode: PropTypes.string,
  lineItems: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
  deliveryNoteId: state.deliveryNote.details.id,
  hasLineItems: deliveryNoteHasLineItems(state),
  lineItems: state.deliveryNote.lineItems,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProductCatalogGroup: (arg) => dispatch(fetchProductCatalogGroup(arg)),
  reorderLineItems: (...args) => dispatch(reorderLineItemsAction(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryNoteDetailsSection);
