import { showNotification } from 'actions/notification';
import { CALL_API } from 'constants/api';
import {
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/tax-consultant';
import { updateSuccess } from 'notifications/tax-consultant';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';
import { ApiAction, ApiResponse, CallApiAction, Dispatch } from 'types/actions';
import {
  FetchedTaxConsultantData,
  UpdatedTaxConsultantData,
  UpdateTaxConsultantDataPayload,
} from 'types/models/tax-consultant-data';

export type FetchTaxConsultantData = ApiResponse<typeof FETCH_SUCCESS, FetchedTaxConsultantData>;
export type UpdateTaxConsultantData = ApiResponse<typeof UPDATE_SUCCESS, UpdatedTaxConsultantData>;

const apiFetchTaxConsultant = (): CallApiAction<FetchTaxConsultantData> => ({
  [CALL_API]: {
    endpoint: '/me/company',
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

const apiUpdateTaxConsultant = (
  data: UpdateTaxConsultantDataPayload
): CallApiAction<UpdateTaxConsultantData> => ({
  [CALL_API]: {
    data,
    method: 'PUT',
    endpoint: '/me/company/tax_consultants',
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const fetchTaxConsultant = (): ApiAction<FetchTaxConsultantData> => (dispatch: Dispatch) =>
  dispatch(apiFetchTaxConsultant()).catch<FetchTaxConsultantData>(apiErrorHandler);

export const updateTaxConsultant =
  (data: UpdateTaxConsultantDataPayload): ApiAction<UpdateTaxConsultantData> =>
  (dispatch: Dispatch) =>
    bindServerValidation(apiUpdateTaxConsultant(data), dispatch).then(() =>
      dispatch(showNotification(updateSuccess))
    );
