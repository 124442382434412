import React, { InputHTMLAttributes } from 'react';
import cx from 'classnames';

import styles from './RawCheckbox.module.css';

type RawCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  containerClassName?: string;
  invalid?: boolean;
  checkboxId?: string;
  dataId?: string;
  onChange?: () => void;
};

const RawCheckbox = ({
  containerClassName,
  invalid = false,
  checkboxId,
  dataId,
  onChange,
  ...rest
}: RawCheckboxProps) => (
  <div className={cx(styles.wrapper, containerClassName)}>
    <input
      className={cx(styles.input, {
        [styles.invalid]: invalid,
      })}
      type="checkbox"
      {...rest}
      id={checkboxId}
      onChange={onChange}
      data-id={dataId}
      autoComplete="off"
    />
    <div className={styles.box} />
    <div className={styles.tick} />
  </div>
);

export default RawCheckbox;
