import normalize from 'json-api-normalizer';
import { get, head } from 'lodash';

import { FETCH_SUCCESS, REMINDER_UPDATE_SUCCESS } from 'constants/outgoing-invoice';

const getAttributes = ({ attributes } = {}) => attributes;

const extractPaymentReminder = (id, type, included) => {
  const reminder = included.find((item) => item.type === type && item.id === id);
  return getAttributes(reminder);
};

export const extractPaymentReminders = (data, included) => {
  const paymentReminders = get(data, 'relationships.payment-reminders.data') || [];
  return paymentReminders
    .map(({ id, type }) => extractPaymentReminder(id, type, included))
    .filter((reminder) => reminder);
};

export default (state = [], { type, response } = {}) => {
  let reminders;
  switch (type) {
    case FETCH_SUCCESS:
      reminders = normalize(response).paymentReminders || {};
      return Object.values(reminders).map(getAttributes);
    case REMINDER_UPDATE_SUCCESS:
      reminders = normalize(response).paymentReminders || {};
      const updatedReminder = head(Object.values(reminders).map(getAttributes));
      return state.map((reminder) =>
        reminder.id === updatedReminder.id ? updatedReminder : reminder
      );
    default:
      return state;
  }
};
