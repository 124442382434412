import React, { useEffect } from 'react';

import { getBankOnInvoice, toggleBankAccountInvoiceVisibility } from 'actions/invoice-templates';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import Section from 'components/Section/Section';
import { useFetchBankAccounts } from 'features/homepage/BankTransfersTile/BankTransfersTile.hooks';

import * as SC from '../TemplateTabEditInfo.styled';
import BankAccountPicker from './BankAccountPicker/BankAccountPicker';
import { SelectType, TBankDetails } from './types';

type BankAccountSectionProps = {
  onChange: () => void;
};

const BankAccountSection = ({ onChange }: BankAccountSectionProps) => {
  const dispatch = useAppDispatch();
  const { data: bankAccounts, isLoading } = useFetchBankAccounts();
  const showHint = !isLoading && !bankAccounts?.length;

  const handleToggleBankAccount = (bankAccount: TBankDetails, fieldIndex: number) => {
    if (!bankAccount) return;

    const isManualAccount = !bankAccount.id;

    let selectedBankAccount = {
      ordinalNumber: fieldIndex,
      manual: isManualAccount,
    } as TBankDetails;

    if (!bankAccount.id) {
      selectedBankAccount = { ...selectedBankAccount, ...bankAccount };
    } else {
      const bankAccountId = bankAccount.type !== SelectType.NONE ? +bankAccount.id : undefined;

      selectedBankAccount = {
        ...selectedBankAccount,
        bankAccountId,
      };
    }

    selectedBankAccount && dispatch(toggleBankAccountInvoiceVisibility(selectedBankAccount));
    onChange();
  };

  useEffect(() => {
    dispatch(getBankOnInvoice());
  }, [dispatch]);

  return (
    <Section title={t('invoice_templates.form.bankAccounts.bank_account_headline')}>
      <SC.Subtext
        dangerouslySetInnerHTML={{
          __html: t('invoice_templates.form.bankAccounts.bank_account_caption'),
        }}
      />
      {showHint && (
        <SC.BankAccountHint>{t('invoice_templates.form.bankAccounts.hint')}</SC.BankAccountHint>
      )}

      <BankAccountPicker
        index={1}
        onChange={(bankAccount) => handleToggleBankAccount(bankAccount, 1)}
        label={t('invoice_templates.form.bankAccounts.bank_account_label') + ' 1'}
        bankAccounts={bankAccounts}
      />
      <BankAccountPicker
        index={2}
        onChange={(bankAccount) => handleToggleBankAccount(bankAccount, 2)}
        label={t('invoice_templates.form.bankAccounts.bank_account_label') + ' 2'}
        bankAccounts={bankAccounts}
      />
    </Section>
  );
};

export default BankAccountSection;
