import React from 'react';
import { snakeCase } from 'lodash';
import { bool, node, number, string } from 'prop-types';

import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import { CurrencyField } from 'components/Form';

const CurrencyFieldWrapper = ({
  name,
  readonly,
  precision,
  translationBase,
  component,
  ...rest
}) => (
  <CurrencyField
    id={name}
    name={name}
    checker={checker}
    disabled={readonly}
    label={t(`${translationBase}.${snakeCase(name)}`)}
    placeholder={t(`${translationBase}.${snakeCase(name)}`)}
    component={component}
    precision={precision}
    {...rest}
  />
);

CurrencyFieldWrapper.propTypes = {
  name: string.isRequired,
  translationBase: string.isRequired,
  precision: number.isRequired,
  component: node.isRequired,
  readonly: bool,
};

export default CurrencyFieldWrapper;
