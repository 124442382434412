import { stringify } from 'query-string';

export default {
  landing: '/landing',
  forgotPassword: '/password/new',
  resetPassword: '/password/edit',
  home: '/',
  login: '/login',
  logout: '/logout',
  unconfirmedUser: (preloginToken, email = '') =>
    `/unconfirmed-email/${preloginToken}${email ? `?${stringify({ email })}` : ''}`,
  companySelectRegistration: '/registration/company-selection',
  requestRegistration: '/registration/',
  companyInfo: '/registration/company-info',
  emailTaken: '/email-taken',
  registrationSuccess: '/registration/completed',
  register: '/registrations/new',
  getRegisterPath: (email) => `/registrations/new?email=${email}`,
  registerVrso: '/registrations/vrso/:token',
  registrationConfirmed: '/registrations/confirm/:token',
  accountAlreadyConfirmed: '/registrations/already-confirmed',
  vrsoLoginBlocked: '/registrations/vrso/login-blocked',
  vrsoResetPasswordBlocked: '/registrations/vrso/reset-password-blocked',
  confirmationLinkOutdated: '/registrations/invalid/:token',
  registerError: '/register-error',
  accountConfirmed: '/account-confirmed',
  revenue: '/revenue',
  financialPlanning: '/financial-planning',
  outgoingInvoices: '/revenue/outgoing-invoices',
  newOutgoingInvoice: '/revenue/outgoing-invoices/new',
  importOutgoingInvoice: '/revenue/outgoing-invoices/import',
  editOutgoingInvoice: (id) => `/revenue/outgoing-invoices/${id}/edit`,
  showOutgoingInvoice: (id) => `/revenue/outgoing-invoices/${id}`,
  outgoingInvoiceFile: (invoiceId, id) =>
    `/api/me/outgoing_invoices/${invoiceId}/invoice_files/${id}`,
  cancelOutgoingInvoice: (id) => `/revenue/outgoing-invoices/${id}/cancel`,
  correctOutgoingInvoice: (id) => `/revenue/outgoing-invoices/${id}/correct`,
  createPaymentReminder: (id) => `/revenue/outgoing-invoices/${id}/reminder/new`,
  expenses: '/expenses',
  incomingInvoices: '/expenses/incoming-invoices',
  newIncomingInvoice: '/expenses/incoming-invoices/new',
  editIncomingInvoice: (id) => `/expenses/incoming-invoices/${id}/edit`,
  showIncomingInvoice: (id) => `/expenses/incoming-invoices/${id}`,
  cancelIncomingInvoice: (id) => `/expenses/incoming-invoices/${id}/cancel`,
  incomingInvoiceFile: (invoiceId, id) =>
    `/api/me/incoming_invoices/${invoiceId}/invoice_files/${id}`,
  administration: '/administration',
  suppliers: '/administration/suppliers',
  showSupplier: (id) => `/administration/suppliers/${id}`,
  editSupplier: (id) => `/administration/suppliers/${id}/edit`,
  suppliersNew: '/administration/suppliers/new',
  suppliersImport: '/administration/suppliers/import',
  clients: '/administration/clients',
  showClient: (id) => `/administration/clients/${id}`,
  editClient: (id) => `/administration/clients/${id}/edit`,
  showClientFarmpilot: (id) => `/administration/clients/${id}/farmpilot`,
  clientsNew: '/administration/clients/new',
  clientsImport: '/administration/clients/import',
  clientDocumentDefaults: (id) => `/administration/clients/${id}/edit/document-defaults`,
  clientEmailsDefaults: (id) => `/administration/clients/${id}/edit/email-defaults`,
  productCatalog: '/administration/product-catalog',
  productCatalogItems: '/administration/product-catalog/items',
  newProductCatalogItem: '/administration/product-catalog/items/new',
  editProductCatalogItem: (id) => `/administration/product-catalog/items/${id}/edit`,
  productCatalogGroups: '/administration/product-catalog/groups',
  newProductCatalogGroup: '/administration/product-catalog/groups/new',
  editProductCatalogGroup: (id) => `/administration/product-catalog/groups/${id}/edit`,
  profile: '/profile',
  companyProfile: '/profile/company',
  taxConsultantProfile: '/profile/tax-consultant',
  personalProfile: '/profile/personal',
  bankAccounts: '/profile/bank-accounts',
  paymentPlan: '/profile/payment-plan',
  numberRanges: '/profile/number-ranges',
  expansion: '/profile/expansion',
  farmpilot: '/profile/expansion/farmpilot',
  settings: '/settings',
  settingsTemplateLogoAndBriefpapier: '/settings/document-template/logo-and-briefpapier',
  settingsTemplateLayout: '/settings/document-template/layout',
  settingsTemplateEdit: '/settings/document-template/template-edit',
  settingsTemplateTable: '/settings/document-template/table',
  settingsTemplateDocumentsDefaults: '/settings/document-template/documents-defaults',
  settingsTemplateEmailDefaults: '/settings/document-template/email-defaults',
  proposals: '/proposals',
  newProposal: '/proposals/new',
  editProposal: (id) => `/proposals/${id}/edit`,
  showProposal: (id) => `/proposals/${id}`,
  orderConfirmation: '/order-confirmation',
  newOrderConfirmation: '/order-confirmation/new',
  editOrderConfirmation: (id) => `/order-confirmation/${id}/edit`,
  cancelOrderConfirmation: (id) => `/order-confirmation/${id}/cancel`,
  showOrderConfirmation: (id) => `/order-confirmation/${id}`,
  bankTransfers: '/bank-transfers',
  showbankTransfer: (id) => `/bank-transfers/${id}`,
  transactions: '/transactions',
  datev: '/datev',
  datevNew: '/datev/new',
  datevEdit: '/datev/edit/:id',
  datevEditLink: (id) => `/datev/edit/${id}`,
  datevDownload: '/datev/download/:id',
  invalidToken: '/invalid-token',
  contracts: '/contracts',
  editContract: (category, type = 'basic', id) => `/contracts/${category}/${type}/${id}/edit`,
  viewContract: (category, type = 'basic', id) => `/contracts/${category}/${type}/${id}`,
  newExpenseContract: (type = 'basic') => `/contracts/expenses/${type}/new`,
  editExpenseContract: (id) => `/contracts/expenses/basic/${id}/edit`,
  viewExpenseContract: (id, type = 'basic') => `/contracts/expenses/${type}/${id}`,
  newRevenueContract: '/contracts/revenues/basic/new',
  editRevenueContract: (id) => `/contracts/revenues/basic/${id}/edit`,
  viewRevenueContract: (id) => `/contracts/revenues/basic/${id}`,
  contractFile: (contractId, id) => `/api/me/contracts/${contractId}/contract_files/${id}`,
  contractDownload: (contractId, accessToken) =>
    `/api/me/contracts/${contractId}/contract_files/combined_file?access_token=${accessToken}`,
  emailNotifications: '/email-notifications',
  bankAccountConnected: '/bank-account-connected',
  registrationsDeletionTokenInvalid: '/registrations/remove/invalid',
  registrationsDeletion: '/registrations/remove/:token',
  liquidityCalculator: '/financial-planning/liquidity-calculator',
  obs: '/financial-planning/obs',
  cashbooks: '/cashbooks',
  deliveryNotes: '/delivery-notes',
  deliveryNotesNew: '/delivery-notes/new',
  editDeliveryNote: (id) => `/delivery-notes/${id}/edit`,
  showDeliveryNote: (id) => `/delivery-notes/${id}`,
};
