import React, { memo } from 'react';
import cx from 'classnames';

import { formatMoney } from 'shared/utils';

import styles from './Result.module.css';

export enum Color {
  Red = 'Red',
  Green = 'Green',
}

type ResultProps = {
  result: number;
  color: Color;
};

const Result = ({ result, color }: ResultProps) => {
  const formattedResult = formatMoney(result);
  const isZero = result === 0;

  return (
    <div
      className={cx(styles.result, {
        [styles.red]: !isZero && color === Color.Red,
        [styles.green]: !isZero && color === Color.Green,
      })}
    >
      {result < 0 ? `- ${formattedResult}` : formattedResult}
    </div>
  );
};

export default memo(Result);
