import React from 'react';
import { useDispatch } from 'react-redux';
import { change, FieldArrayFieldsProps } from 'redux-form';

import { t } from 'shared/utils';
import If from 'components/Conditions/If';

import { areFieldsEmpty } from './CompanyAuthorizedPeople.utils';
import CompanyAuthorizedPerson from './CompanyAuthorizedPerson/CompanyAuthorizedPerson';

import styles from './CompanyAuthorizedPeople.module.css';

export interface CompanyAuthorizedPersonData {
  honorific: string;
  degree: string;
  name: string;
  companyPosition: string;
  forGeneralPartner?: boolean;
  destroy?: boolean;
}
export interface CompanyAuthorizedPeopleProps {
  /**
   * Fields prop comes from FieldArray (redux-form build in component)
   */
  fields: FieldArrayFieldsProps<CompanyAuthorizedPersonData>;
  formSelector: (name: string) => string;
  forGeneralPartner?: boolean;
}

/**
 * This component gets fields prop from FieldArray component and uses this prop to display a list of authorized people.
 * There is a button for adding new authorized people below this list.
 * Button uses fields.push method from FieldArray.
 */
const CompanyAuthorizedPeople = ({
  formSelector,
  fields,
  forGeneralPartner = false,
}: CompanyAuthorizedPeopleProps) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.authorizedPeople} id="company-form-authorized-people">
      {fields.map((person, index) => (
        <If
          key={`${forGeneralPartner && 'gp_'}representative_${index}`}
          ok={!formSelector(`${person}.destroy`)}
        >
          <CompanyAuthorizedPerson
            person={person}
            forGeneralPartner={forGeneralPartner}
            onDelete={() => {
              // We can't do fields.remove() since on BE the only way to remove a person is by providing
              // { destroy: true } to the person's object
              dispatch(change('company', `${person}.destroy`, true));
            }}
          />
        </If>
      ))}
      {areFieldsEmpty(fields, formSelector) && (
        <p className={styles.noPeopleLabel}>{t('profile.company.no_authorized_people')}</p>
      )}
      <button
        type="button"
        data-id="button-add-authorized-person"
        className={styles.addPerson}
        onClick={() =>
          fields.push({
            honorific: '',
            degree: '',
            name: '',
            companyPosition: '',
            forGeneralPartner: forGeneralPartner,
          })
        }
        id="company-form-add-authorized-person"
      >
        {t('profile.company.add_person')}
      </button>
    </div>
  );
};

export default CompanyAuthorizedPeople;
