import normalize from 'json-api-normalizer';
import { get } from 'lodash';
import build from 'redux-object';

import EntityPath from 'constants/entitiesPaths';
import { FETCH_SUCCESS } from 'constants/outgoing-invoice';
import { mapClient } from 'reducers/clients';

export const extractPersistedClient = (id, type, included) => {
  if (!id || !type || !included) return null;

  const includedClient = included.find((item) => item.type === type && item.id === id);

  return includedClient ? mapClient(includedClient) : null;
};

export const persistedClient = (state = null, action) => {
  if (action.type !== FETCH_SUCCESS) return state;

  const { id, type } = get(action, 'response.data.relationships.persisted-client.data') || {};
  const included = get(action, 'response.included', []);

  return extractPersistedClient(id, type, included);
};

export default (state = null, action) => {
  if (action.type !== FETCH_SUCCESS) return state;

  const invoice = build(
    normalize(action.response),
    EntityPath.OutgoingInvoices,
    action.response.data.id
  );

  return invoice && invoice.client;
};
