import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { head } from 'lodash';

import { t } from 'shared/utils';
import { FullscreenGallery, Gallery, NextFileBtn, PrevFileBtn } from 'components/Dropzone/Gallery';
import Preview from 'components/Dropzone/Preview/Preview';
import Modal from 'components/Modal';

import PreviewActions from '../PreviewActions/PreviewActions';
import type {
  DeleteFile,
  DownloadFilePreview,
  FileNameGenerator,
  PreviewFile,
  TrackingEvents,
} from '../types';

import styles from './PreviewContainer.module.css';

const hasId = (id: string) => (object: { id: string }) => object.id === id;

type PreviewContainerProps = {
  accessToken: string;
  downloadFilePreview: DownloadFilePreview;
  deleteFile: DeleteFile;
  attachUrlWithParams: any;
  fileNameGenerator: FileNameGenerator;
  files: File[] & PreviewFile[];
  contractId: string;
  readonly: boolean;
  onNewFileButtonClick: () => void;
  trackingEvents?: TrackingEvents;
  fileSubject?: string;
  contractNumber?: string;
  isFullscreen?: boolean;
  onDownloadContractClick?: () => void;
  contractDownloadUrl?: string;
  isDownloadingContract: boolean;
};

export const PreviewContainer = ({
  accessToken,
  deleteFile,
  files,
  contractId,
  readonly,
  onNewFileButtonClick,
  downloadFilePreview,
  attachUrlWithParams,
  fileNameGenerator,
  trackingEvents,
  fileSubject,
  contractNumber,
  isFullscreen = false,
  onDownloadContractClick,
  contractDownloadUrl,
  isDownloadingContract,
}: PreviewContainerProps) => {
  let activeThumbnailNode: Element | null = null;
  const initialActiveFileId: string = head(files)?.id || '';
  const [activeFileId, setActiveFileId] = useState<string>(initialActiveFileId);
  const [activeFileIndex, setActiveFileIndex] = useState<number>(0);
  const [isGalleryVisible, setIsGalleryVisible] = useState<boolean>(true);
  const [isFullscreenPreviewOpen, setIsFullscreenPreviewOpen] = useState<boolean>(false);
  const [isPreviewRendering, setIsPreviewRendering] = useState<boolean>(false);
  const filesWithUrl: PreviewFile[] = files.map(attachUrlWithParams(contractId, accessToken));
  const activeFile: PreviewFile | undefined = filesWithUrl.find(hasId(activeFileId));
  const initialActiveFile: PreviewFile | undefined = filesWithUrl.find(hasId(initialActiveFileId));

  const handlePreviewThumbnailClick = () => {
    setIsGalleryVisible((isGalleryVisible) => !isGalleryVisible);
    isFullscreen && setIsFullscreenPreviewOpen((isFullscreenOpen) => !isFullscreenOpen);
  };

  const setActiveThumbnailNode = (node: Element | null) => {
    activeThumbnailNode = node;
  };

  const scrollPreviewIntoView = () => {
    if (activeThumbnailNode) {
      activeThumbnailNode.scrollIntoView(false);
    }
  };

  const setActiveFile = (id: string) => {
    setActiveFileIndex(files.findIndex(hasId(id)));
    setActiveFileId(id);
  };

  const setNextFileActive = () => {
    const nextFileId =
      activeFileIndex >= files.length - 1 ? files[0].id : files[activeFileIndex + 1].id;

    setActiveFile(nextFileId);
  };

  const setPrevFileActive = () => {
    const prevFileId =
      activeFileIndex <= 0 ? files[files.length - 1].id : files[activeFileIndex - 1].id;

    setActiveFile(prevFileId);
  };

  const handleUserKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { keyCode } = event;

      const isLeftArrowKeyDown = keyCode === 37;
      const isRightArrowKeyDown = keyCode === 39;
      const isEscapeKeyDown = keyCode === 27;

      if (isLeftArrowKeyDown) return setPrevFileActive();
      if (isRightArrowKeyDown) return setNextFileActive();
      if (isEscapeKeyDown) return handlePreviewThumbnailClick();
    },
    [activeFileId]
  );

  /**
   * Active file deletion listener
   */
  useEffect(() => {
    const isActiveFile = hasId(activeFileId);
    const isActiveFileDeleted = !files.find(isActiveFile);

    if (isActiveFileDeleted) {
      const nextFileIndex = activeFileIndex >= files.length ? files.length - 1 : activeFileIndex;

      setActiveFileId(files[nextFileIndex].id);
      setActiveFileIndex(nextFileIndex);
    }
  }, [files.length]);

  /**
   * Set PDF canvas position
   */
  useEffect(() => scrollPreviewIntoView(), [activeFileId]);

  /**
   * Keyboard event listener
   */
  useEffect(() => {
    if (isGalleryVisible) return;
    window.addEventListener('keydown', handleUserKeyDown);

    return () => {
      window.removeEventListener('keydown', handleUserKeyDown);
    };
  }, [handleUserKeyDown, isGalleryVisible]);

  // Display first file when gallery is displayed
  useEffect(() => {
    setActiveFileId(initialActiveFileId);
    setActiveFileIndex(0);
  }, [isGalleryVisible, initialActiveFileId]);

  const contractDownloadName = `${fileSubject}_${contractNumber}.pdf`;

  return (
    <div className={styles.main}>
      {isFullscreen && (
        <>
          <span className={styles.pages}>
            {files.length} {t(`contracts.new.${files.length === 1 ? 'page' : 'pages'}`)}
          </span>
          <PreviewActions
            onPreviewClick={handlePreviewThumbnailClick}
            onContractDownloadClick={onDownloadContractClick}
            contractDownloadUrl={contractDownloadUrl}
            contractDownloadName={contractDownloadName}
            isDownloadingContract={isDownloadingContract}
          />
        </>
      )}
      <Preview
        imageClassName={styles.preview}
        file={isFullscreen ? initialActiveFile : activeFile}
        isFullscreen={isFullscreen}
        contractId={contractId}
        downloadFilePreview={downloadFilePreview}
        setIsPreviewRendering={setIsPreviewRendering}
        onClick={handlePreviewThumbnailClick}
        isPreviewRendering={isPreviewRendering}
      />
      {!isFullscreen && (
        <Gallery
          activeFileId={activeFileId}
          className={cx(styles.gallery, {
            [styles.isHidden]: !isGalleryVisible,
          })}
          deleteFile={deleteFile}
          files={filesWithUrl}
          fileNameGenerator={fileNameGenerator}
          readonly={readonly}
          contractId={contractId}
          onNewFileButtonClick={onNewFileButtonClick}
          onNextSelect={setNextFileActive}
          onPrevSelect={setPrevFileActive}
          onSelect={setActiveFile}
          setActiveThumbnailNode={setActiveThumbnailNode}
          isPreviewRendering={isPreviewRendering}
          trackingEvents={trackingEvents}
          fileSubject={fileSubject}
          contractNumber={contractNumber}
          contractDownloadName={contractDownloadName}
          contractDownloadUrl={contractDownloadUrl}
          isDownloadingContract={isDownloadingContract}
        />
      )}
      {isFullscreen && (
        <Modal
          isOpen={isFullscreenPreviewOpen}
          className={styles.pdfModal}
          onRequestClose={handlePreviewThumbnailClick}
        >
          <Preview
            imageClassName={styles.preview}
            file={activeFile}
            isFullscreen
            contractId={contractId}
            downloadFilePreview={downloadFilePreview}
            setIsPreviewRendering={setIsPreviewRendering}
            onClick={handlePreviewThumbnailClick}
            isPreviewRendering={isPreviewRendering}
          />
          <FullscreenGallery
            activeFileId={activeFileId}
            className={styles.gallery}
            files={filesWithUrl}
            onSelect={setActiveFile}
            setActiveThumbnailNode={setActiveThumbnailNode}
            trackingEvents={trackingEvents}
            onDownloadContractClick={onDownloadContractClick}
            contractDownloadName={contractDownloadName}
            contractDownloadUrl={contractDownloadUrl}
            isDownloadingContract={isDownloadingContract}
          />
          <PrevFileBtn onClick={setPrevFileActive} isPreviewRendering={isPreviewRendering} />
          <NextFileBtn onClick={setNextFileActive} isPreviewRendering={isPreviewRendering} />
        </Modal>
      )}
    </div>
  );
};

export default PreviewContainer;
