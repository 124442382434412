import styled from 'styled-components';

import { Status as ContractStatus } from 'features/contracts/types';

export type StatusProps = {
  status: ContractStatus;
};

export const Status = styled.div<StatusProps>`
  display: flex;
  height: 30px;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: bold;
  text-transform: uppercase;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme, status }) =>
    status === ContractStatus.Active
      ? theme.legacy.colors.greenFont
      : theme.legacy.colors.greyFont};

  &:span {
    text-align: center;
  }
`;

export const Text = styled.span`
  margin-left: ${({ theme }) => theme.space[2]};
`;
