import React, { Component } from 'react';
import { isEqual } from 'lodash';
import { bool, func, shape, string } from 'prop-types';
import { Field } from 'redux-form';

import honorifics from 'constants/honorifics';
import { noop, t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import Card from 'components/Card';
import I18n from 'components/I18n';
import HtmlField from 'components/v2/Form/HtmlField/HtmlField';

import styles from './IndividualContactSection.module.css';

const defaultIsReadonly = () => false;

const SALUTATION_FIELD_NAME = 'salutationHonorific';
const CONTENT_FIELD_NAME = 'salutationContent';
const [sir, mrs, married_couple, family] = honorifics;

export function getSalutation(client) {
  if (!client || !client.lastName)
    return t('revenue.form.individual_contact.polite_return.company');

  const { honorific, lastName } = client;

  switch (honorific) {
    case sir:
      return t('revenue.form.individual_contact.polite_return.sir', {
        lastName,
      });
    case mrs:
      return t('revenue.form.individual_contact.polite_return.mrs', {
        lastName,
      });
    case family:
      return t('revenue.form.individual_contact.polite_return.family', {
        lastName,
      });
    case married_couple:
      return t('revenue.form.individual_contact.polite_return.married_couple', {
        lastName,
      });
    default:
      return t('revenue.form.individual_contact.polite_return.company');
  }
}

class IndividualContactSection extends Component {
  componentDidUpdate({ client: prevClient }) {
    const { client } = this.props;
    if (isEqual(client, prevClient) || !client) return;

    this.initializeSalutation();
  }

  initializeSalutation() {
    const { client = {}, change, salutationHonorific } = this.props;

    if (salutationHonorific === undefined) {
      change(SALUTATION_FIELD_NAME, getSalutation(client));
    }
  }

  render() {
    const { isReadonly, disabled, dataIds, children } = this.props;

    return (
      <Card className={styles.root}>
        <Card.Header>
          <I18n t="revenue.form.individual_contact.header" />
        </Card.Header>
        <Card.Body className={styles.body}>
          <div className={styles.row}>
            <div>
              <label htmlFor={SALUTATION_FIELD_NAME}>
                <I18n t="revenue.form.individual_contact.salutation" />
              </label>
              <Field
                dataId={dataIds.salutation}
                name={SALUTATION_FIELD_NAME}
                disabled={disabled || isReadonly(SALUTATION_FIELD_NAME)}
                component={HtmlField}
                checker={checker}
              />
            </div>
            <div>
              <label htmlFor={CONTENT_FIELD_NAME}>
                <I18n t="revenue.form.individual_contact.header" />
              </label>
              <Field
                dataId={dataIds.salutationContent}
                name={CONTENT_FIELD_NAME}
                disabled={disabled || isReadonly(CONTENT_FIELD_NAME)}
                component={HtmlField}
                checker={checker}
              />
            </div>
          </div>
          {children}
        </Card.Body>
      </Card>
    );
  }
}

IndividualContactSection.propTypes = {
  isReadonly: func,
  disabled: bool,
  client: shape({}),
  change: func,
  salutationHonorific: string,
  dataIds: shape({
    salutation: string,
    salutationContent: string,
  }),
};

IndividualContactSection.defaultProps = {
  client: null,
  isReadonly: defaultIsReadonly,
  disabled: false,
  change: noop,
  dataIds: {},
};

export default IndividualContactSection;
