import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

import { deleteCustomTask, toggleCustomTask } from 'actions/customer-notifications';
import { DATE_FORMAT_UNICODE } from 'constants/datetime';
import { CustomTask } from 'types/entities/Task';
import RawCheckbox from 'components/Form/RawCheckbox/RawCheckbox';
import IconDelete from 'components/Icons/IconDelete';

import { trackEvent } from '../utils';

import styles from './CustomTask.module.css';

type DoneTaskProps = {
  task: CustomTask;
};

const DoneTask = ({ task }: DoneTaskProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxClick = async () => {
    try {
      setIsLoading(true);
      await dispatch(toggleCustomTask(task));
      setIsLoading(false);
      trackEvent('Completed_Task_uncheck');
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = async (id: number) => {
    try {
      setIsLoading(true);
      await dispatch(deleteCustomTask(id));
      setIsLoading(false);
      trackEvent('Completed_Task_delete');
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.task}>
      <div className={styles.checkbox}>
        <RawCheckbox
          value="true"
          checked={true}
          onClick={handleCheckboxClick}
          disabled={isLoading}
        />
      </div>
      <div className={styles.description}>{task.description}</div>
      <div className={styles.dueDate}>
        {task.dueDate && format(new Date(task.dueDate), DATE_FORMAT_UNICODE)}
      </div>
      <div className={styles.iconContainer}>
        <button onClick={() => handleDeleteClick(task.id)} disabled={isLoading}>
          <IconDelete className={styles.icon} data-id="delete-task" />
        </button>
      </div>
    </div>
  );
};

export default DoneTask;
