import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { togglePaidByCash as paidByCashIncomingInvoice } from 'actions/incoming-invoices';
import { togglePaidByCash as paidByCashOugoingInvoice } from 'actions/outgoing-invoice';
import { incomingInvoiceSelector, outgoingInvoiceSelector } from 'reducers/form';
import { t } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';

import styles from './PaidByUnspecified.module.css';

const PaidByUnspecified = ({ handleOptionSelect, fullyPaid, canDelete, ...option }) => {
  const onClick = () => {
    handleOptionSelect({ ...option });
  };

  return (
    <div
      onClick={onClick}
      role="button"
      onKeyPress={isPressedEnter(() => onClick())}
      className={`${styles.bankTransfer} Select-option`}
    >
      <div data-id="mark-as-paid-option" className={styles.creditorName}>
        {t('revenue.form.paid_by_unspecified')}
      </div>
    </div>
  );
};

PaidByUnspecified.propTypes = {
  togglePaidByCashOugoingInvoice: PropTypes.func.isRequired,
  togglePaidByCashIncomingInvoice: PropTypes.func.isRequired,
  paidByCash: PropTypes.bool,
  fullyPaid: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool,
  handleOptionSelect: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  id: outgoingInvoiceSelector(state, 'id') || incomingInvoiceSelector(state, 'id'),
  paidByUnspecified:
    outgoingInvoiceSelector(state, 'paidByUnspecified') ||
    incomingInvoiceSelector(state, 'paidByUnspecified'),
  fullyPaid: outgoingInvoiceSelector(state, 'paid'),
  canDelete: !incomingInvoiceSelector(state, 'paid'),
});

const mapDispatchToProps = (dispatch) => ({
  togglePaidByCashOugoingInvoice: (...args) => dispatch(paidByCashOugoingInvoice(...args)),
  togglePaidByCashIncomingInvoice: (...args) => dispatch(paidByCashIncomingInvoice(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaidByUnspecified);
