import React from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';

import styles from './BoxWithButton.module.css';

export interface BoxWithButtonProps {
  content: string;
  buttonLabel?: string;
  dataId?: string;
  image?: string;
  onClick?: () => void;
  containerClassName?: string;
  iconClassName?: string;
  contentSecondLine?: string;
  isButtonDisabled?: boolean;
  onClose?: () => void;
}

const BoxWithButton = ({
  content,
  onClick,
  buttonLabel = '',
  image = '',
  dataId = '',
  containerClassName = '',
  iconClassName = '',
  contentSecondLine = '',
  isButtonDisabled,
  onClose,
}: BoxWithButtonProps) => (
  <div className={cx(styles.boxContainer, containerClassName)} data-id={dataId}>
    {Boolean(image) && (
      <div className={cx(styles.iconContainer, iconClassName)}>
        <InlineSvg className={styles.svg} src={image} />
      </div>
    )}

    <div className={styles.boxWrapper}>
      <div className={styles.content}>
        <span dangerouslySetInnerHTML={{ __html: content }} />
        {contentSecondLine && <span>{contentSecondLine}</span>}
      </div>
      {onClick && buttonLabel && (
        <div className={styles.buttonContainer}>
          <button
            className={styles.button}
            onClick={onClick}
            data-id={`${dataId}-button`}
            disabled={isButtonDisabled}
          >
            <span>{buttonLabel}</span>
          </button>
        </div>
      )}
    </div>

    {onClose && (
      <button className={styles.cancel} onClick={onClose} data-id={`${dataId}:button-close`} />
    )}
  </div>
);

export default BoxWithButton;
