import React from 'react';
import { Field } from 'redux-form';

import { t } from 'shared/utils';
import { Checkbox } from 'components/Form';

import { Headline, Wrapper } from './SignatureField.styled';

type SignatureFieldProps = {
  readOnly: boolean;
};

const SignatureField = ({ readOnly }: SignatureFieldProps) => {
  return (
    <Wrapper>
      <Headline>
        {t('features.delivery_note.form.details_section.fields.signature_headline')}
      </Headline>
      <br />
      <Field name="pdfSignature" component={Checkbox} disabled={readOnly}>
        {t('features.delivery_note.form.details_section.fields.signature_label')}
      </Field>
    </Wrapper>
  );
};

export default SignatureField;
