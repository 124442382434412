import { Moment } from 'moment';

export type TDefaultPresets = {
  [key: string]: TDatePreset;
};

export enum DefaultRangePresets {
  lastYear = 'last_year',
  lastQuarter = 'last_quarter',
  lastMonth = 'last_month',
}

export type TDatePreset = { label: string; start: Moment | null; end: Moment | null };

export type TDatePresets = {
  presets: DefaultRangePresets[];
  handleDatesChange: ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
};
