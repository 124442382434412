import React, { memo, ReactNode } from 'react';

import styles from './SummaryVertical.module.css';

type SummaryVerticalProps = {
  title: ReactNode;
  result: ReactNode;
};

const SummaryVertical = ({ title, result }: SummaryVerticalProps) => (
  <div className={styles.summary}>
    <div className={styles.text}>{title}</div>
    <div className={styles.value}>{result}</div>
  </div>
);

export default memo(SummaryVertical);
