import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

import { name } from './constants';

const slice = (state: RootState) => state[name];

export const getIsLoaded = createSelector(slice, (state) => !state.isLoading || !!state.data);

export const getDashboardSettings = createSelector(slice, (slice) => slice.data);
