import React, { SVGProps } from 'react';

const Planner = ({ dataId, ...props }: { dataId: string } & SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 166 132"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    data-id={dataId}
    {...props}
  >
    <defs>
      <filter
        x="-8.6%"
        y="-2.7%"
        width="118.3%"
        height="105.8%"
        filterUnits="objectBoundingBox"
        id="Planer_svg__a"
      >
        <feOffset dx={2} dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={10} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.186157844 0 0 0 0 0.241747249 0 0 0 0 0.422412817 0 0 0 0.4 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <filter id="Planer_svg__c">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 0.400000 0 0 0 0 0.000000 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="Planer_svg__d">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 0.400000 0 0 0 0 0.000000 0 0 0 1.000000 0"
        />
      </filter>
      <rect id="Planer_svg__b" x={0} y={0} width={339} height={1061} rx={5} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-87 -105)">
        <use fill="#000" filter="url(#Planer_svg__a)" xlinkHref="#Planer_svg__b" />
        <use fill="#FAFAFA" xlinkHref="#Planer_svg__b" />
      </g>
      <g>
        <g fill="#CCC" fillRule="nonzero">
          <path d="M-.039 50.38a.883.883 0 011.137-.23l.1.067 4.002 3.07a.883.883 0 01-.975 1.467l-.1-.066-4.001-3.07A.882.882 0 01-.04 50.38zM4.108 55.616a.882.882 0 011.105 1.372l-.087.07-4.117 2.907a.882.882 0 01-1.105-1.372l.087-.07 4.117-2.907zM6.554 56.26a.883.883 0 011.06.489l.039.104 1.456 4.868a.883.883 0 01-1.653.61l-.038-.105-1.456-4.867a.882.882 0 01.592-1.099zM8.223 54.35l5.018.1a.883.883 0 01.075 1.76l-.11.005-5.018-.1a.882.882 0 01-.075-1.76l.11-.004zM7.69 48.214a.883.883 0 011.7.465l-.03.107-1.644 4.805a.883.883 0 01-1.7-.464l.03-.107 1.644-4.806z" />
        </g>
        <g fill="#CCC" fillRule="nonzero">
          <path d="M159.04 64.628c.444.068.76.458.748.896l-.01.11-.775 5.027a.883.883 0 01-1.754-.158l.01-.11.774-5.027a.883.883 0 01.896-.748l.11.01zM150.712 68.915a.883.883 0 011.083-.435l.102.044 4.543 2.29a.882.882 0 01-.693 1.62l-.102-.044-4.542-2.29a.883.883 0 01-.391-1.185zM155.793 73.3a.883.883 0 011.332 1.152l-.079.09-3.582 3.612a.882.882 0 01-1.332-1.152l.079-.09 3.582-3.613zM158.337 73.494a.883.883 0 011.131.286l.057.095 2.328 4.523a.883.883 0 01-1.512.903l-.057-.096-2.328-4.522a.882.882 0 01.38-1.189zM164.678 70.494a.882.882 0 01.391 1.717l-.108.025-5.02.817a.882.882 0 01-.392-1.718l.108-.024 5.02-.817z" />
        </g>
        <g fill="#CCC" fillRule="nonzero">
          <path d="M80.04-.372c.444.068.76.458.748.896l-.01.11-.775 5.027a.883.883 0 01-1.754-.158l.01-.11.774-5.027a.883.883 0 01.896-.748l.11.01zM71.712 3.915a.883.883 0 011.083-.435l.102.044 4.543 2.29a.882.882 0 01-.693 1.62l-.102-.044-4.542-2.29a.883.883 0 01-.391-1.185zM76.793 8.3a.883.883 0 011.332 1.152l-.079.09-3.582 3.612a.882.882 0 01-1.332-1.152l.079-.09 3.582-3.613zM79.337 8.494a.883.883 0 011.131.286l.057.095 2.328 4.523a.883.883 0 01-1.512.903l-.057-.096-2.328-4.522a.882.882 0 01.38-1.189zM85.678 5.494a.882.882 0 01.391 1.717l-.108.025-5.02.817a.882.882 0 01-.392-1.718l.108-.024 5.02-.817z" />
        </g>
        <g fill="#CCC" fillRule="nonzero">
          <path d="M140.04 21.628c.444.068.76.458.748.896l-.01.11-.775 5.027a.883.883 0 01-1.754-.158l.01-.11.774-5.027a.883.883 0 01.896-.748l.11.01zM131.712 25.915a.883.883 0 011.083-.435l.102.044 4.543 2.29a.882.882 0 01-.693 1.62l-.102-.044-4.542-2.29a.883.883 0 01-.391-1.185zM136.793 30.3a.883.883 0 011.332 1.152l-.079.09-3.582 3.612a.882.882 0 01-1.332-1.152l.079-.09 3.582-3.613zM139.337 30.494a.883.883 0 011.131.286l.057.095 2.328 4.523a.883.883 0 01-1.512.903l-.057-.096-2.328-4.522a.882.882 0 01.38-1.189zM145.678 27.494a.882.882 0 01.391 1.717l-.108.025-5.02.817a.882.882 0 01-.392-1.718l.108-.024 5.02-.817z" />
        </g>
        <rect fill="#0F2364" x={27.5} y={27.5} width={76} height={104} rx={7} />
        <path fill="#FAFAFA" d="M33.5 36.5h64v89h-64z" />
        <rect fill="#C7CBCE" x={40.5} y={22.5} width={52} height={20} rx={6.5} />
        <path
          fill="#D8D8D8"
          d="M39.5 52.5h10v10h-10zM39.5 66.5h10v10h-10zM39.5 79.5h10v10h-10zM39.5 93.5h10v10h-10z"
        />
        <g filter="url(#Planer_svg__c)">
          <path d="M35.5 47.5h20v20h-20z" />
          <path
            d="M52.452 52.422a.803.803 0 00-1.069-.058l-.065.058-8.58 8.49-3.056-3.025a.803.803 0 00-1.134 0 .774.774 0 00-.059 1.049l.059.064 3.615 3.578a.814.814 0 001.077.058l.066-.058 9.146-9.043a.774.774 0 00.059-1.049l-.059-.064z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <g filter="url(#Planer_svg__d)">
          <path d="M35.5 60.5h20v20h-20z" />
          <path
            d="M52.452 65.422a.803.803 0 00-1.069-.058l-.065.058-8.58 8.49-3.056-3.025a.803.803 0 00-1.134 0 .774.774 0 00-.059 1.049l.059.064 3.615 3.578a.814.814 0 001.077.058l.066-.058 9.146-9.043a.774.774 0 00.059-1.049l-.059-.064z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <rect fill="#333" x={53.5} y={54.5} width={37} height={2} rx={1} />
        <rect fill="#333" x={53.5} y={68.5} width={37} height={2} rx={1} />
        <rect fill="#333" x={53.5} y={82.5} width={37} height={2} rx={1} />
        <rect fill="#333" x={53.5} y={95.5} width={37} height={2} rx={1} />
        <rect fill="#333" x={54.5} y={58.5} width={20} height={2} rx={1} />
        <rect fill="#333" x={54.5} y={72.5} width={20} height={2} rx={1} />
        <rect fill="#333" x={54.5} y={86.5} width={20} height={2} rx={1} />
        <rect fill="#333" x={54.5} y={99.5} width={20} height={2} rx={1} />
        <g fillRule="nonzero">
          <path
            d="M115.372 97.729c4.166 0 7.574-3.437 7.574-7.637h-15.147c0 4.2 3.408 7.637 7.573 7.637z"
            fill="#FF9C14"
          />
          <path
            d="M138.514 78.455V59.364c0-11.722-6.172-21.535-17.04-24.131v-2.597c0-3.169-2.537-5.727-5.68-5.727s-5.68 2.558-5.68 5.727v2.597c-10.83 2.596-17.04 12.37-17.04 24.13v19.092L85.5 86.09v3.818h60.588v-3.818l-7.574-7.636z"
            fill="#0066B3"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Planner;
