import React, { ChangeEvent, useCallback } from 'react';
import { FilterProps } from 'react-table';

import { invalidRangeChecker, parseCurrency } from 'shared/utils';
import { nonCalcCharsRegex } from 'shared/utils/regex';
import TextField from 'components/Form/TextField';

import { Wrapper } from './CurrencyRangeFilter.styled';

type CurrencyRangeFilterProps<T extends object> = FilterProps<T> & {
  labelFrom: string;
  labelTo: string;
};

const CurrencyRangeFilter = <T extends object>({
  column: { filterValue = [], setFilter, id },
  labelFrom,
  labelTo,
}: CurrencyRangeFilterProps<T>) => {
  const [from, to] = filterValue;
  const invalid = invalidRangeChecker({ start: from, end: to });

  const handleChangeFrom = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = parseCurrency(event.target.value.replace(nonCalcCharsRegex, ''));

      setFilter((old = []) => [value, old[1]]);
    },
    [setFilter]
  );

  const handleChangeTo = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = parseCurrency(event.target.value.replace(nonCalcCharsRegex, ''));

      setFilter((old = []) => [old[0], value]);
    },
    [setFilter]
  );

  return (
    <Wrapper data-id={`filter-${id}`}>
      <TextField
        name={`${id}-from`}
        label={labelFrom}
        value={from || ''}
        onChange={handleChangeFrom}
        invalid={invalid}
        dataId={`filter-${id}-from`}
        isCurrency
      />
      <TextField
        name={`${id}-to`}
        label={labelTo}
        value={to || ''}
        onChange={handleChangeTo}
        invalid={invalid}
        dataId={`filter-${id}-to`}
        isCurrency
      />
    </Wrapper>
  );
};

export default CurrencyRangeFilter;
