import React, { FunctionComponent } from 'react';
import cx from 'classnames';

import warning from 'images/hint.svg';
import Svg from 'components/Svg/Svg';

import styles from './Alert.module.css';

type Warning = 'WARNING';

export type AlertTypes = Warning;

export const ALERT_TYPES: {
  [key: string]: AlertTypes;
} = {
  WARNING: 'WARNING',
};

interface AlertProps {
  /** type of alert */
  type: AlertTypes;
  /** appended class */
  customClassName?: string;
  /** text of alert */
  text?: string;
  dataId?: string;
}

const ICONS_FOR_TYPES = {
  [ALERT_TYPES.WARNING]: warning,
};

const Alert: FunctionComponent<AlertProps> = ({
  type,
  customClassName = '',
  text = '',
  dataId = '',
}) => (
  <div
    className={cx(styles.alert, customClassName, {
      [styles.warning]: type === ALERT_TYPES.WARNING,
    })}
  >
    <div className={styles.icon}>
      <Svg icon={ICONS_FOR_TYPES[type]} width="29px" height="28px" />
    </div>
    <div className={styles.text} data-id={dataId}>
      {text}
    </div>
  </div>
);

export default Alert;
