import React, { useCallback } from 'react';

import { t } from 'shared/utils';
import type { RedirectChallenge } from 'types/entities/Figo';

import { Button, Buttons, Wrapper } from './Redirect.styled';

type RedirectProps = {
  challenge: RedirectChallenge;
  onCancel: () => void;
  className?: string;
};

const Redirect = ({ challenge, onCancel, className }: RedirectProps) => {
  const { location } = challenge;

  const handleGoToBank = useCallback(() => {
    window.open(location);
  }, [location]);

  return (
    <Wrapper data-id="redirect-challenge" className={className}>
      <p>{t('figo_connection.challenges.redirect.text_1')}</p>
      <br />
      <p>
        <strong>{t('figo_connection.challenges.redirect.text_2')}</strong>
      </p>
      <Buttons>
        <Button
          appearance="outlined"
          onClick={onCancel}
          label={t('bank_accounts.creator.prev_step')}
          dataId="button-back"
          type="button"
        />
        <Button
          onClick={handleGoToBank}
          label={t('figo_connection.challenges.redirect.go_to_bank')}
          dataId="button-next"
        />
      </Buttons>
    </Wrapper>
  );
};

export default Redirect;
