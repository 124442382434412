import React from 'react';

const IconTransferToInvoice = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
    <g fillRule="evenodd">
      <path d="M2.5 12.38v1.154h5.052V16c0-.123.983-1.22 2.95-3.29l-2.95-2.713v2.383H2.5zm12.67-7.075v9.98h-3.89l-1.556 1.534h5.446a1.55 1.55 0 0 0 1.556-1.535v-9.98c0-.844-.7-1.535-1.556-1.535H5.056A1.55 1.55 0 0 0 3.5 5.305v5.373l1.556-1.535V5.305H15.17z" />
      <path d="M0 0h20v20H0z" fill="none" />
    </g>
  </svg>
);

export default IconTransferToInvoice;
