import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import paths from 'routes/paths';
import { t } from 'shared/utils';
import ActionDropdown from 'components/ActionPanel/ActionDropdown';

import { trackEvent } from '../utils';

import styles from './NewContractDropdown.module.css';

const NewContractDropdown = () => {
  const history = useHistory();
  const actions = useMemo(
    () => [
      {
        name: t('contracts.index_view.dropdown.new_contract'),
        onClick: () => {
          history.push(paths.newExpenseContract('loan'));
          trackEvent('Create_Loan');
        },
        dataId: 'add-expense-loan',
      },
      {
        name: t('contracts.index_view.dropdown.edit_contract'),
        onClick: () => {
          history.push(paths.newExpenseContract());
          trackEvent('Create_Misc_Expense');
        },
        dataId: 'add-expense',
      },
    ],
    [history]
  );

  return (
    <ActionDropdown
      title={t('contracts.index_view.dropdown.title')}
      actions={actions}
      visible
      align="right"
      dataId="add-contract"
      onClick={() => trackEvent('Dropdown_Expense')}
      buttonClassName={styles.newButtonStyle}
    />
  );
};

export default NewContractDropdown;
