import React, { ChangeEvent, Fragment, memo, useEffect, useState } from 'react';

import { PER_PAGE_VALUES } from 'constants/pagination';
import { t } from 'shared/utils';

import {
  Button,
  Input,
  LastPage,
  Navigation,
  NextPage,
  PageNumber,
  PageSize,
  PreviousPage,
  Wrapper,
} from './Pagination.styled';

type PaginationProps = {
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  isLoading?: boolean;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onPageChange: (page: number) => void;
  onChangePageSize: (size: number) => void;
  className?: string;
};

const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageIndex,
  isLoading,
  pageSize,
  totalPages,
  onPreviousPage,
  onNextPage,
  onPageChange,
  onChangePageSize,
  className,
}: PaginationProps) => {
  const [page, setPage] = useState<string | number>(pageIndex + 1);

  const handlePageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(event.target.value);
  };

  const handlePageBlur = () => {
    const currentPage = Number(page);

    if (currentPage <= 0) {
      setPage(1);
      onPageChange(0);
    } else if (currentPage > totalPages) {
      setPage(totalPages);
      onPageChange(totalPages - 1);
    } else {
      onPageChange(currentPage - 1);
    }
  };

  const handleGoToLastPage = () => {
    onPageChange(totalPages - 1);
  };

  const handleChangePageSize = (size: number) => () => {
    onChangePageSize(size);
  };

  useEffect(() => {
    setPage(pageIndex + 1);
  }, [pageIndex]);

  return (
    <Wrapper className={className}>
      <Navigation>
        <PreviousPage
          onClick={onPreviousPage}
          disabled={!canPreviousPage || isLoading}
          aria-label={t('pagination.previous_page')}
        />
        <PageNumber>
          <Input
            type="number"
            value={page}
            onChange={handlePageChange}
            onBlur={handlePageBlur}
            size={1}
            minLength={1}
            min={1}
            max={totalPages}
            autoComplete="off"
            disabled={isLoading || totalPages <= 1}
            aria-label={t('pagination.select_page')}
          />{' '}
          <span>/</span>{' '}
          <LastPage disabled={!canNextPage || isLoading} onClick={handleGoToLastPage}>
            {totalPages}
          </LastPage>
        </PageNumber>
        <NextPage
          onClick={onNextPage}
          disabled={!canNextPage || isLoading}
          aria-label={t('pagination.next_page')}
        />
      </Navigation>
      <PageSize>
        {t('pagination.entries_per_page')}
        {PER_PAGE_VALUES.map((size, index) => (
          <Fragment key={size}>
            {index > 0 && <span>|</span>}{' '}
            <Button
              disabled={isLoading}
              isActive={pageSize === size}
              onClick={handleChangePageSize(size)}
              type="button"
            >
              {size}
            </Button>
          </Fragment>
        ))}
      </PageSize>
    </Wrapper>
  );
};

export default memo(Pagination);
