import styled, { css } from 'styled-components';

import media from 'shared/styles/breakpoints';
import Modal from 'components/Modal';

type Props = {
  $withBottomSpace?: boolean;
};

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;

  & > button {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }

  ${media.greaterThan('md-up')`
    margin: 0 30px 0 20px;
    justify-content: center;
  `}
`;

export const ModalContainer = styled(Modal)`
  max-width: 100%;

  ${media.greaterThan('xsm-up')`
    width: 80%
  `}

  ${media.greaterThan('md-up')`
    width: 60%
  `}

  ${media.greaterThan('lg-up')`
    width: 40%
  `}
`;

export const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${media.greaterThan('md-up')`
    flex-direction: row;
  `}
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
`;

export const CheckboxContainer = styled.div<Props>`
  padding-top: 15px;
  ${({ $withBottomSpace }) =>
    $withBottomSpace &&
    css`
      padding-bottom: 15px;
    `}
`;

export const TextAreaContainer = styled.div`
  margin-top: ${({ theme }) => theme.space['5']};
  margin-bottom: 3.125rem;
`;

export const RecaptchaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -0.625rem;
  margin-bottom: ${({ theme }) => theme.space['6']};
`;

export const SpaceWrapper = styled.div`
  padding-top: ${({ theme }) => theme.space['5']};
`;

export const Hint = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.grey['050']};
`;
