import styled from 'styled-components';

type NumberProps = {
  $isNegative?: boolean;
};

export const Number = styled.span<NumberProps>`
  color: ${({ theme, $isNegative }) =>
    $isNegative ? theme.colors.red['100'] : theme.colors.green['125']};
  font-weight: bold;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSize.sm};
  letter-spacing: 0.25px;
`;
