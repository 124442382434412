export const INCOMING_INVOICE = 'IncomingInvoice';
export const OUTGOING_INVOICE = 'OutgoingInvoice';

export const INCOMING_INVOICES = 'incomingInvoices';
export const OUTGOING_INVOICES = 'outgoingInvoices';

export const INCOMING_INVOICE_SNAKE_CASE = 'incoming_invoice';
export const OUTGOING_INVOICE_SNAKE_CASE = 'outgoing_invoice';

export const STATUS_PAID = 'paid';
