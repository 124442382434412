import styled from 'styled-components';

import media from 'shared/styles/breakpoints';
import { Section } from 'components/CardView';

export const Wrapper = styled.div`
  padding: 20px 20px 30px;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.greaterThan('lg-up')`
    flex-direction: row;
  `};
`;

export const Headline = styled.div`
  color: #ff6600;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  padding-bottom: 10px;
`;

export const SubHeadline = styled.div`
  color: #4d4e4c;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 25px;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 50px 0 20px 0;
`;

export const Description = styled.div`
  font-size: 16px;
  padding-bottom: 35px;
`;

export const SectionLeft = styled(Section)`
  flex: 0.4;
`;

export const SectionRight = styled(Section)`
  flex: 0.6;

  ${media.greaterThan('lg-up')`
    padding-top: 120px;
  `};
`;

export const RedirectionInformation = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #b0b0b0;
  text-align: center;
`;

export const RedirectionText = styled.div`
  width: 100%;

  ${media.greaterThan('xxlg-up')`
    width: 80%;
  `};
`;

export const ListItem = styled.li`
  font-size: 14px;
  list-style: disc;
`;

export const CheckMark = styled.div`
  width: 19px;
  height: 15px;
  margin-top: 4px;
  margin-right: 8px;
`;

export const Button = styled.button`
  width: 166px;
  height: 32px;
  background-color: #ff6600;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
`;

export const CollapsHeadline = styled.p`
  line-height: 19px;
  font-size: 14px;
  font-weight: bold;
`;

export const CollapsSubHeadline = styled.p`
  line-height: 19px;
  font-size: 14px;
  padding-top: 15px;
`;

export const UnorderedList = styled.ul`
  padding-left: 15px;
`;

export const Hint = styled.p`
  padding-top: 40px;
  font-size: 14px;
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 600px;

  ${media.greaterThan('sm-up')`
    height: 400px;
  `};
`;
