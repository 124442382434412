import styled from 'styled-components';

import media from 'shared/styles/breakpoints';

export const Wrapper = styled.div`
  padding: 20px 10px;

  ${media.greaterThan('sm-up')`
    padding: 20px 20px 30px;
  `}
`;
