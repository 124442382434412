import React from 'react';

import { CrudMode } from 'constants/common/crud';
import { CategoryResponse } from 'types/entities/Category';
import { ContractSource, ContractTemplates } from 'types/entities/Contract';

import { BasicExpense, BasicRevenue, LoanExpense } from './templates';

type ActiveFormProps = {
  readonly: boolean;
  crudMode: CrudMode;
  contractSource?: ContractSource;
  intervalOptions: { label: any; value: string }[];
  lineCategories: CategoryResponse[];
  type: ContractTemplates[keyof ContractTemplates];
  selectedCategory: CategoryResponse;
  initialValues: any;
};

const ActiveTemplate = (props: ActiveFormProps) => {
  switch (props.type) {
    case ContractTemplates.ExpensesBasic:
      return <BasicExpense {...props} />;
    case ContractTemplates.RevenuesBasic:
      return <BasicRevenue {...props} />;
    default:
      return <LoanExpense {...props} />;
  }
};

export default ActiveTemplate;
