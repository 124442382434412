import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { apiFetchBankAccounts } from 'actions/bank-account';
import { apiFetchOBSQualified } from 'actions/liquidity/liquidity';
import { liquidityCalculatorEnabledHelper } from 'routes/accesses';
import paths from 'routes/paths';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { t } from 'shared/utils';
import Card, { Body, Header } from 'components/Card';
import Loading from 'components/Loading';

import BankAccounts from './BankAccounts/BankAccounts';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import { FormName } from './constants';
import Expenses from './Expenses/Expenses';
import Introduction from './Introduction/Introduction';
import { getBankAccounts, getExpenses, getRevenues } from './liquidityCalculatorSlice';
import Revenues from './Revenues/Revenues';
import * as selectors from './selectors';
import Summary from './Summary/Summary';
import type { FormData } from './types';

import styles from './LiquidityCalculator.module.css';

const LiquidityCalculator = () => (
  <div className={styles.page}>
    <div className={styles.leftColumn}>
      <Card>
        <Header>{t('features.financial_planning.liquidity_calculator.header')}</Header>
        <Body>
          <Introduction />
          <BankAccounts />
          <Expenses />
          <Revenues />
        </Body>
      </Card>
    </div>
    <div className={styles.rightColumn}>
      <Summary />
    </div>
  </div>
);

const LiquidityCalculatorWithForm = compose<FC<{ initialValues: FormData }>>(
  reduxForm<FormData>({
    form: FormName,
  }),
  withTransitionPrevent({ ModalComponent: ConfirmationModal })
)(LiquidityCalculator);

const LiquidityCalculatorFormInitializer = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const isLiquidityCalculatorEnabled = useSelector(liquidityCalculatorEnabledHelper);
  const bankAccounts = useSelector(selectors.getInitialBankAccounts);
  const recurringExpenses = useSelector(selectors.getInitialRecurringExpenses);
  const recurringRevenues = useSelector(selectors.getInitialRecurringRevenues);

  useEffect(() => {
    const fetch = async () => {
      await Promise.all([
        dispatch(getExpenses()),
        dispatch(getRevenues()),
        dispatch(getBankAccounts()),

        /**
         * In addition we have to fetch all the user bank accounts because it's
         * needed for updating transactions modal.
         */
        dispatch(apiFetchBankAccounts()),
        dispatch(apiFetchOBSQualified()),
      ]);
      setIsLoading(false);
    };

    fetch();
  }, [dispatch]);

  if (!isLiquidityCalculatorEnabled) {
    history.push(paths.home);
    return null;
  }

  if (isLoading) return <Loading />;

  return (
    <LiquidityCalculatorWithForm
      initialValues={{
        bankAccounts,
        recurringExpenses,
        recurringRevenues,
        otherExpenses: [
          {
            name: t(
              'features.financial_planning.liquidity_calculator.expenses.fields.other_expenses.name.default_value'
            ),
            amount: '',
          },
        ],
        otherRevenues: [
          {
            name: t(
              'features.financial_planning.liquidity_calculator.revenues.fields.other_revenues.name.default_value'
            ),
            amount: '',
          },
        ],
      }}
    />
  );
};

export default LiquidityCalculatorFormInitializer;
