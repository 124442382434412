import React, {
  Children,
  cloneElement,
  isValidElement,
  MouseEvent,
  ReactNode,
  useCallback,
  useRef,
} from 'react';
import { useEffectOnce } from 'react-use';

import { Wrapper } from './Tabs.styled';

type TabsProps = {
  className?: string;
  children: ReactNode;
  value: any;
  onChange: (event: MouseEvent<HTMLDivElement>, newValue: any) => void;
};

const Tabs = ({ value, className, children, onChange }: TabsProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>, value: any) => {
      event.currentTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
      onChange(event, value);
    },
    [onChange]
  );

  useEffectOnce(() => {
    const wrapper = wrapperRef.current;
    const preset = wrapper?.querySelector<HTMLDivElement>(`[data-id="${value}"]`);

    if (!wrapper || !preset) return;

    wrapper.scrollTo({ left: preset.offsetLeft - wrapper.offsetLeft });
  });

  return (
    <Wrapper className={className} ref={wrapperRef}>
      {Children.map(children, (child, index) => {
        if (!isValidElement(child)) {
          return null;
        }

        const childValue = child.props.value === undefined ? index : child.props.value;

        return cloneElement(child, {
          isActive: value === childValue,
          onClick: handleClick,
          value: childValue,
        });
      })}
    </Wrapper>
  );
};

export default Tabs;
