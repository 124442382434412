import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { ITEMS_DROPPABLE_ID } from 'constants/product-catalog/groups';
import { t } from 'shared/utils';
import {
  LineItemsAutoSaveConsumer,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
} from 'components/LineItems';
import lineItemsStyles from 'components/LineItems/LineItems.module.css';

import Row from './Row';

const ProductCatalogItemsTable = ({
  existingItems = [],
  readonly,
  hasErrors,
  groupId,
  areLineItemsEmpty,
  crudMode,
}) => (
  <Table hasErrors={hasErrors || areLineItemsEmpty} readonly={readonly}>
    <TableHeader>
      <TableHeaderCell title={t('tables.headers.name')} />
      <TableHeaderCell title={t('tables.headers.unit')} />
      <TableHeaderCell title={t('tables.headers.net_amount')} alignRight />
      <TableHeaderCell title={t('tables.headers.gross_amount_alt')} alignRight />
      <TableHeaderCell title={t('tables.headers.vat_percentage')} />
      <TableHeaderCell title={t('tables.headers.quantity')} />
      <TableHeaderCell
        title={t('tables.headers.actions')}
        className={cx(lineItemsStyles.actionsHeader, {
          [lineItemsStyles.actionsCellHidden]: readonly,
        })}
        customWidth
      />
    </TableHeader>
    <LineItemsAutoSaveConsumer>
      {({ addEditLineItemCreator, removeEditLineItemCreator }) => (
        <TableBody droppableId={ITEMS_DROPPABLE_ID}>
          {({ provided }) => (
            <>
              {existingItems.map((item) => (
                <Row
                  key={item.id}
                  lineItem={item}
                  groupId={groupId}
                  readonly={readonly}
                  isDragDisabled
                  dndId={item.id}
                  dndIndex={item.ordinalNumber}
                  addEditLineItemCreator={addEditLineItemCreator}
                  removeEditLineItemCreator={removeEditLineItemCreator}
                  crudMode={crudMode}
                />
              ))}
              {provided.placeholder}
            </>
          )}
        </TableBody>
      )}
    </LineItemsAutoSaveConsumer>
  </Table>
);

ProductCatalogItemsTable.propTypes = {
  existingItems: PropTypes.arrayOf(PropTypes.shape({})),
  readonly: PropTypes.bool,
  hasErrors: PropTypes.bool,
  areLineItemsEmpty: PropTypes.bool,
  groupId: PropTypes.string.isRequired,
  crudMode: PropTypes.string.isRequired,
};

export default ProductCatalogItemsTable;
