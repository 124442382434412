import React, { Component } from 'react';
import cx from 'classnames';
import { bool, string } from 'prop-types';
import { Field } from 'redux-form';

import InfoTextField from 'components/Form/InfoTextField';
import TextField from 'components/Form/TextField';
import HiddenAutofillInput from 'components/HiddenAutofillInput/HiddenAutofillInput';

import styles from './PasswordField.module.css';

class PasswordField extends Component {
  state = {
    isHidden: true,
  };

  getFieldType() {
    return this.state.isHidden ? 'password' : 'text';
  }

  getComponent() {
    return this.props.infoMessage ? InfoTextField : TextField;
  }

  getFieldProps() {
    if (this.getComponent() === InfoTextField) {
      return { message: this.props.infoMessage };
    }
    return {};
  }

  iconClassNames() {
    return cx(styles.icon, {
      [styles.isActive]: !this.state.isHidden,
    });
  }

  changeFieldType = (e) => {
    e.preventDefault();
    this.setState({
      isHidden: !this.state.isHidden,
    });
  };

  renderField = () => {
    const { omitForm, infoMessage, hideIcon, isAutofillDisabled, className, ...props } = this.props;
    const InputComponent = this.getComponent();
    const InputField = omitForm ? InputComponent : Field;
    const conditionalProps = omitForm ? {} : { component: InputComponent };

    return (
      <>
        {isAutofillDisabled && <HiddenAutofillInput name={props.id} type="password" />}
        <InputField
          {...props}
          {...conditionalProps}
          type={this.getFieldType()}
          {...this.getFieldProps()}
          inputClassName={styles.input}
        />
      </>
    );
  };

  render() {
    const { infoMessage, hideIcon } = this.props;

    return (
      <div
        className={cx(styles.wrapper, this.props.className, {
          [styles.hideIcon]: hideIcon,
          [styles.info]: !!infoMessage,
        })}
      >
        {this.renderField()}
        <button
          className={this.iconClassNames()}
          onClick={this.changeFieldType}
          tabIndex="-1"
          type="button"
        />
      </div>
    );
  }
}

PasswordField.propTypes = {
  className: string,
  hideIcon: bool,
  infoMessage: string,
  omitForm: bool,
  isAutofillDisabled: bool,
};

PasswordField.defaultProps = {
  isAutofillDisabled: true,
};

export default PasswordField;
