import React from 'react';
import cx from 'classnames';
import { arrayOf, bool, func, node, oneOfType, string } from 'prop-types';

import SectionHeadline from 'components/NotificationsSidebar/components/SectionHeadline/SectionHeadline';

import styles from './Section.module.css';

const Section = ({ title, closeAction, isActive, className, children }) => (
  <div
    className={cx(styles.container, className, {
      [styles.active]: isActive,
    })}
  >
    <SectionHeadline title={title} onArrowClick={closeAction} />
    {children}
  </div>
);

Section.propTypes = {
  title: string.isRequired,
  closeAction: func.isRequired,
  isActive: bool.isRequired,
  children: oneOfType([node, arrayOf(node)]),
  className: string,
};

export default Section;
