import * as Yup from 'yup';

import { t } from 'shared/utils';

export default Yup.object({
  logoSizePercentage: Yup.number().required(
    t('features.settings.document_template.logo_and_briefpapier.errors.required')
  ),
  logoPosition: Yup.string().required(
    t('features.settings.document_template.logo_and_briefpapier.errors.required')
  ),
}).required();
