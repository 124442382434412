import React from 'react';

import TabDocumentsDefaults from 'containers/Profile/InvoiceTemplate/DefaultValues/TabDocumentsDefaults/TabDocumentsDefaults';
import { t } from 'shared/utils';

import { Heading, Wrapper } from './DocumentsDefaults.styled';

const DocumentsDefaults = () => {
  return (
    <Wrapper>
      <Heading>{t('profile.section_headers.documents_defaults')}</Heading>
      <TabDocumentsDefaults />
    </Wrapper>
  );
};

export default DocumentsDefaults;
