import React, { memo, SVGProps } from 'react';

const EyeClosed = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none">
      <path d="M0 .5h24v24H0V.5Zm0 0h24v24H0V.5Zm0 0h24v24H0V.5Zm0 0h24v24H0V.5Z" />
      <path
        d="M12 7c2.76 0 5 2.24 5 5 0 .51-.1 1-.24 1.46l3.06 3.06c1.39-1.23 2.49-2.77 3.18-4.53-1.73-4.38-6-7.49-11-7.49-1.27 0-2.49.2-3.64.57l2.17 2.17C11 7.1 11.49 7 12 7ZM2.71 3.66a.996.996 0 0 0 0 1.41l1.97 1.97A11.892 11.892 0 0 0 1 12c1.73 4.39 6 7.5 11 7.5 1.52 0 2.97-.3 4.31-.82l2.72 2.72a.996.996 0 1 0 1.41-1.41L4.13 3.66c-.39-.39-1.03-.39-1.42 0ZM12 17c-2.76 0-5-2.24-5-5 0-.77.18-1.5.49-2.14l1.57 1.57c-.03.18-.06.37-.06.57 0 1.66 1.34 3 3 3 .2 0 .38-.03.57-.07l1.57 1.57c-.65.32-1.37.5-2.14.5Zm2.97-5.33a2.97 2.97 0 0 0-2.64-2.64l2.64 2.64Z"
        fill="#0066B3"
      />
    </g>
  </svg>
);

export default memo(EyeClosed);
