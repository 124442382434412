import React from 'react';
import InlineSvg from 'react-inlinesvg';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push } from 'connected-react-router';
import { func } from 'prop-types';

import iconSuccess from 'images/icon-success.svg';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';

import pageStyles from '../Page.module.css';
import styles from './Page5.module.css';

const Page5 = ({ handleClose, goToBankTransfers }) => (
  <div className={pageStyles.page}>
    <div className={cx(pageStyles.body, styles.body)}>
      <InlineSvg className={styles.successIcon} src={iconSuccess} />
      <div className={cx(styles.title, styles.textCenter)}>
        {t('bank_accounts.creator.without_sync.message_title')}
      </div>
      <div className={styles.textCenter}>{t('bank_accounts.creator.without_sync.message')}</div>
    </div>

    <div className={pageStyles.footer}>
      <ActionButton
        appearance="outlined"
        onClick={handleClose}
        label={t('bank_accounts.creator.without_sync.close')}
        type="button"
        dataId="BankAccount:button-close"
      />
      <ActionButton
        label={t('bank_accounts.creator.without_sync.confirm')}
        onClick={() => {
          handleClose();
          goToBankTransfers();
        }}
        dataId="BankAccount:button-redirect"
      />
    </div>
  </div>
);

Page5.propTypes = {
  handleClose: func.isRequired,
  goToBankTransfers: func.isRequired,
};

export default connect(null, (dispatch) => ({
  goToBankTransfers: () => dispatch(push(paths.bankTransfers)),
}))(Page5);
