import React from 'react';
import { range } from 'lodash';

import { t } from 'shared/utils';
import { ReminderDay, ReminderType } from 'types/entities/Cashbook';

import IconClock from './IconClock';

export const NoReminderOption = 'No';

export const reminderTypeOptions = [
  {
    label: t('cashbooks.notifications.fields.reminderType.options.no'),
    value: NoReminderOption,
  },
  {
    label: t('cashbooks.notifications.fields.reminderType.options.daily'),
    value: ReminderType.Daily,
  },
  {
    label: t('cashbooks.notifications.fields.reminderType.options.weekly'),
    value: ReminderType.Weekly,
  },
  {
    label: t('cashbooks.notifications.fields.reminderType.options.monthly'),
    value: ReminderType.Monthly,
  },
];

export const reminderHourOptions = range(6, 24).map((hour: number) => ({
  label: `${String(hour).padStart(2, '0')}:00`,
  value: hour,
}));

export const reminderDayOptions = [
  {
    label: t('cashbooks.notifications.fields.day.options.monday'),
    value: ReminderDay.Monday,
  },
  {
    label: t('cashbooks.notifications.fields.day.options.tuesday'),
    value: ReminderDay.Tuesday,
  },
  {
    label: t('cashbooks.notifications.fields.day.options.wednesday'),
    value: ReminderDay.Wednesday,
  },
  {
    label: t('cashbooks.notifications.fields.day.options.thursday'),
    value: ReminderDay.Thursday,
  },
  {
    label: t('cashbooks.notifications.fields.day.options.friday'),
    value: ReminderDay.Friday,
  },
  {
    label: t('cashbooks.notifications.fields.day.options.saturday'),
    value: ReminderDay.Saturday,
  },
];

export enum MonthlyReminder {
  First = 'first',
  FirstWorking = 'first_working',
  Last = 'last',
  LastWorking = 'last_working',
}

export const monthlyReminderOptions = [
  {
    label: t('cashbooks.notifications.fields.monthly.options.first'),
    value: MonthlyReminder.First,
  },
  {
    label: t('cashbooks.notifications.fields.monthly.options.first_working'),
    value: MonthlyReminder.FirstWorking,
  },
  {
    label: t('cashbooks.notifications.fields.monthly.options.last'),
    value: MonthlyReminder.Last,
  },
  {
    label: t('cashbooks.notifications.fields.monthly.options.last_working'),
    value: MonthlyReminder.LastWorking,
  },
];

export const reminderHourLabel = (
  <span>
    <IconClock /> {t('cashbooks.notifications.fields.time.label')}
  </span>
);

export const reminderHourPlaceholder = reminderHourLabel;
