import styled from 'styled-components';

const ModalTitle = styled.h2`
  color: ${({ theme }) => theme.colors.grey['080']};
  font-size: ${({ theme }) => theme.fontSize['lg']};
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: ${({ theme }) => theme.space['4']};
`;

export default ModalTitle;
