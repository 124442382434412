import React, { useState } from 'react';
import { arrayOf, func, shape } from 'prop-types';

import { t } from 'shared/utils';
import { AccessMethodType } from 'types/entities/Figo';
import ActionButton from 'components/ActionPanel/ActionButton';
import AdditionalInfo from 'components/AdditionalInfo/AdditionalInfo';
import RadioGroup from 'components/RadioGroup/RadioGroup';
import RadioGroupOption from 'components/RadioGroup/RadioGroupOption/RadioGroupOption';

import pageStyles from '../Page.module.css';
import styles from './AccessMethodSelection.module.css';

const AccessMethodSelection = ({ accessMethods, handleNextStep, handleCancel }) => {
  const [value, setValue] = useState(AccessMethodType.Fints);

  const handleChange = (newValue) => {
    const isSameValue = newValue === value;
    if (isSameValue) return;

    setValue(newValue);
  };

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.body}>
        <div>{t('bank_accounts.creator.please_select_access_method')}</div>

        <AdditionalInfo text={t('bank_accounts.creator.please_select_access_method_info')} />

        <div className={styles.accessSelect}>
          <RadioGroup
            name="access-type"
            value={value}
            onChange={handleChange}
            className={styles.radioContainer}
          >
            {accessMethods.map((accessMethod, index) => (
              <RadioGroupOption
                option={{
                  label: accessMethod.account_types.join(', '),
                  value: accessMethod.type,
                  dataIds: {
                    input: `AccessMethodSelection:input-${accessMethod.type}`,
                    label: `AccessMethodSelection:label-${accessMethod.type}`,
                  },
                }}
                key={index}
              />
            ))}
          </RadioGroup>
        </div>
      </div>
      <div className={pageStyles.footer}>
        <ActionButton
          appearance="outlined"
          onClick={handleCancel}
          label={t('bank_accounts.creator.prev_step')}
          className={pageStyles.stepButton}
          type="button"
        />
        <ActionButton
          onClick={() => handleNextStep(value)}
          className={pageStyles.stepButton}
          label={t('bank_accounts.creator.next_step')}
          dataId="BankAccount:button-next"
        />
      </div>
    </div>
  );
};

AccessMethodSelection.propTypes = {
  accessMethods: arrayOf(shape({})).isRequired,
  handleNextStep: func.isRequired,
  handleCancel: func.isRequired,
};

export default AccessMethodSelection;
