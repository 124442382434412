import React, { useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { resetCustomerDefaults, updateCustomerDefaults } from 'actions/default-values';
import { getDeliveryNote, GetDeliveryNoteData } from 'api/me/previews/deliveryNote';
import { DocumentsFormName } from 'containers/Profile/InvoiceTemplate/DefaultValues/TabDocumentsDefaults/TabDocumentsDefaults';
import { t } from 'shared/utils';
import { serverValidationChecker } from 'shared/utils/form-checking';
import { RootState } from 'store';
import { DefaultValues } from 'types/entities/DefaultValues';
import { TextField } from 'components/Form';
import InputsGroup from 'components/InputsGroup/InputsGroup';
import HtmlField from 'components/v2/Form/HtmlField/HtmlField';

import { FormRow } from '../../sharedComponents';
import DefaultValuesFormWithPreview from '../../sharedComponents/DefaultValuesForm/DefaultValuesFormWithPreview';

import styles from './DeliveryNoteDefaults.module.css';

export const FORM_NAME: DocumentsFormName = 'deliveryNoteDefaults';

type FormData = Pick<
  DefaultValues,
  | 'deliveryNoteSubject'
  | 'deliveryNoteSalutation'
  | 'deliveryNoteSalutationContent'
  | 'deliveryNoteFooter'
>;

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    deliveryNoteSubject: state.defaultValues.values.deliveryNoteSubject,
    deliveryNoteSalutation: state.defaultValues.values.deliveryNoteSalutation,
    deliveryNoteSalutationContent: state.defaultValues.values.deliveryNoteSalutationContent,
    deliveryNoteFooter: state.defaultValues.values.deliveryNoteFooter,
  },
  formValues: {
    deliveryNoteSalutation: selector(state, 'deliveryNoteSalutation'),
    deliveryNoteSalutationContent: selector(state, 'deliveryNoteSalutationContent'),
    deliveryNoteFooter: selector(state, 'deliveryNoteFooter'),
  },
});

const mapDispatchToProps = {
  updateCustomerDefaults,
  resetCustomerDefaults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type DeliveryNoteDefaultsProps = ConnectedProps<typeof connector>;

const DeliveryNoteDefaults = ({
  resetCustomerDefaults,
  updateCustomerDefaults,
  handleSubmit,
  reset: resetForm,
  formValues: { deliveryNoteSalutation, deliveryNoteSalutationContent, deliveryNoteFooter },
  initialValues,
  initialize,
  initialized,
}: DeliveryNoteDefaultsProps & InjectedFormProps<FormData, DeliveryNoteDefaultsProps>) => {
  const topFields = useMemo(
    () => (
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.discount_subject')}>
          <Field
            className={styles.grouppedInput}
            name="deliveryNoteSubject"
            checker={serverValidationChecker}
            component={TextField}
            dataId="DocumentDefaults:input-delivery-note-subject"
          />
        </InputsGroup>
      </FormRow>
    ),
    []
  );

  const values = useMemo(
    () => ({
      salutation_honorific: deliveryNoteSalutation,
      salutation_content: deliveryNoteSalutationContent,
      personal_notes: deliveryNoteFooter,
    }),
    [deliveryNoteSalutation, deliveryNoteSalutationContent, deliveryNoteFooter]
  );

  useEffect(() => {
    initialized && initialize(initialValues);
  }, []);

  return (
    <DefaultValuesFormWithPreview<GetDeliveryNoteData>
      onSubmit={handleSubmit((values) => updateCustomerDefaults(values, 'form_delivery_note'))}
      onReset={async () => {
        await resetCustomerDefaults('form_delivery_note');
        resetForm();
      }}
      sectionName="form_delivery_note"
      sectionLabel={t('invoice_templates.documents_defaults.delivery_note_defaults_section')}
      dataIdPrefix="DocumentDefaults:"
      fetchPreview={getDeliveryNote}
      values={values}
      topFields={topFields}
    >
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.header')}>
          <Field
            name="deliveryNoteSalutation"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation')}
            tooltip={t('invoice_templates.documents_defaults.salutation_info')}
            dataId="DocumentDefaults:input-order-confirmation-salutation"
          />
          <br />
          <Field
            name="deliveryNoteSalutationContent"
            component={HtmlField}
            checker={serverValidationChecker}
            label={t('invoice_templates.documents_defaults.salutation_content')}
            dataId="DocumentDefaults:input-order-confirmation-salutation-content"
          />
        </InputsGroup>
      </FormRow>
      <FormRow>
        <InputsGroup label={t('invoice_templates.documents_defaults.footer')}>
          <Field
            name="deliveryNoteFooter"
            component={HtmlField}
            checker={serverValidationChecker}
            dataId="DocumentDefaults:input-order-confirmation-footer"
          />
        </InputsGroup>
      </FormRow>
    </DefaultValuesFormWithPreview>
  );
};

const FormEnhancedComponent = reduxForm<FormData, DeliveryNoteDefaultsProps>({
  form: FORM_NAME,
  enableReinitialize: true,
  persistentSubmitErrors: true,
})(DeliveryNoteDefaults);

export default connector(FormEnhancedComponent);
