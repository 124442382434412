import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { get } from 'lodash';

import { checkIsTruncated } from 'shared/utils';
import Tooltip from 'components/Tooltip/Tooltip';

import styles from './BankOption.module.css';

type BankOptionProps = {
  name: string;
  icon: {};
  code: string;
  bic: false;
  onClick: () => void;
  selected: string;
};

const BankOption = ({ name, icon, code, bic, onClick, selected }: BankOptionProps) => {
  const nameRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (nameRef && nameRef.current) setIsTruncated(checkIsTruncated(nameRef.current));
  }, [nameRef]);

  return (
    <button
      key={code}
      type="button"
      onClick={onClick}
      className={cx(styles.bankOption, {
        [styles.selected]: code === selected,
      })}
    >
      <div className={styles.container} data-id="BankAccount:bankOption">
        <img alt="" className={styles.logo} src={get(icon, 'resolutions.["48x48"]', icon)} />

        <div className={styles.containerColumn}>
          <Tooltip
            message={name || code}
            isTooltipHidden={!isTruncated}
            className={cx(styles.nameWrapper, bic && styles.nameWrapperClipped)}
            customTooltipStyles={styles.tooltip}
          >
            <div ref={nameRef} id={name} className={styles.name}>
              {name || code}
            </div>
          </Tooltip>
          {bic && <span className={styles.code}>BLZ: {code}</span>}
        </div>
      </div>
    </button>
  );
};

export default BankOption;
