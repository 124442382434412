import React, { SVGAttributes } from 'react';

const IconClock = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg width={12} height={12} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h12v12H0z" />
        <path
          d="M5.994 0A5.997 5.997 0 000 6c0 3.312 2.682 6 5.994 6A6.003 6.003 0 0012 6c0-3.312-2.688-6-6.006-6zM6 10.8A4.799 4.799 0 011.2 6c0-2.652 2.148-4.8 4.8-4.8 2.652 0 4.8 2.148 4.8 4.8 0 2.652-2.148 4.8-4.8 4.8zM5.832 3a.43.43 0 00-.432.432v2.832c0 .21.108.408.294.516l2.49 1.494c.204.12.468.06.588-.144a.426.426 0 00-.15-.594L6.3 6.156V3.432A.43.43 0 005.868 3h-.036z"
          fill="#B4B5B4"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default IconClock;
