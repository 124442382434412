import styled from 'styled-components';

import { Breakpoints } from 'redesign/styles/breakpoints';

const Td = styled.td`
  height: 48px;
  padding: 0 ${({ theme }) => theme.space['2']};
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.grey['080']};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey['040']};

  @media (min-width: ${Breakpoints.sm}) {
    padding: 0 ${({ theme }) => theme.space['4']};
  }
`;

export default Td;
