import React from 'react';
import cx from 'classnames';
import { Field } from 'redux-form';

import SectionWithMargin, { SectionRow } from 'containers/Profile/SectionWithMargin';
import { t } from 'shared/utils';
import { InfoTextField, TextField } from 'components/Form';

import { CompanyFormType, FormSelector } from '../Company.types';
import {
  formatRegistrationCourt,
  formatRegistrationNumber,
  parseRegistrationNumber,
} from './utils';

import styles from '../Company.module.css';

type CompanyInformationProps = {
  companyForms: CompanyFormType[];
  formSelector: FormSelector;
  selectedCompanyForm: string;
};

const GeneralPartnerCompanyInformation = ({
  companyForms,
  formSelector,
}: CompanyInformationProps) => {
  const currentCompanyForm = companyForms.find((form) => form.id === formSelector('form'));
  const currentRegistration = currentCompanyForm && currentCompanyForm.companyRegistration;
  const registrationRequired =
    currentRegistration === 'required' ||
    (currentRegistration === 'optional' && formSelector('registered'));
  const registrationForbidden = !registrationRequired;
  const prefixRequired =
    currentCompanyForm &&
    registrationRequired &&
    currentCompanyForm.additionalRegistrationNumberPrefix;
  const registrationNumberPrefix = prefixRequired
    ? `${currentCompanyForm!.additionalRegistrationNumberPrefix} `
    : '';

  return (
    <SectionWithMargin
      wrapperTheme={cx(styles.sectionWithMargin, styles.companyInformationSection)}
      sectionTitleTheme={styles.companyInformationTitle}
      title={t('profile.company.sections.general_partner_company_information')}
    >
      <SectionRow className={styles.row}>
        <Field
          name="generalPartnerName"
          component={InfoTextField}
          label={t('profile.company.labels.name')}
          placeholder={t('profile.company.placeholders.name')}
          message={t('profile.company.company_message')}
          dataId="input-general-partner-name"
          required
        />
      </SectionRow>
      <SectionRow className={styles.row}>
        <Field
          name="generalPartnerRegistrationNumber"
          dataId="input-general-partner-registration-number"
          component={TextField}
          label={t('profile.company.labels.registration_number')}
          placeholder={t('profile.company.placeholders.registration_number')}
          required={Boolean(registrationRequired)}
          disabled={registrationForbidden}
          parse={parseRegistrationNumber(registrationNumberPrefix)}
          format={formatRegistrationNumber(registrationNumberPrefix, registrationForbidden)}
          isDisabledWithUnderline
          validatePristine
        />
        <Field
          name="generalPartnerRegistrationCourt"
          component={TextField}
          dataId="input-general-partner-registration-court"
          placeholder={t('profile.company.placeholders.registration_court')}
          required={Boolean(registrationRequired)}
          disabled={registrationForbidden}
          format={formatRegistrationCourt(registrationForbidden)}
          isDisabledWithUnderline
          validatePristine
        />
      </SectionRow>
      <SectionRow className={styles.row}>
        <Field
          name="generalPartnerStreet"
          dataId="input-general-partner-street"
          component={TextField}
          label={t('profile.company.labels.street')}
          placeholder={t('profile.company.placeholders.street')}
          required
        />
        <Field
          name="generalPartnerZipcode"
          dataId="input-general-partner-zipcode"
          component={TextField}
          maxLength="15"
          label={t('profile.company.labels.zipcode')}
          placeholder={t('profile.company.placeholders.zipcode')}
          required
        />
        <Field
          name="generalPartnerCity"
          dataId="input-general-partner-city"
          component={TextField}
          label={t('profile.company.labels.city')}
          placeholder={t('profile.company.placeholders.city')}
          required
        />
      </SectionRow>
    </SectionWithMargin>
  );
};

export default GeneralPartnerCompanyInformation;
