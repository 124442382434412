import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';

import { WELCOME_TILE_CLOSE, WELCOME_TILE_DO_NOT_SHOW_AGAIN } from 'constants/piwik';
import { getEmail, getFirstName, getLastName } from 'selectors/profile';
import { useAppDispatch, useAppSelector } from 'shared/hooks/app';
import { t } from 'shared/utils';
import {
  getFinishedAllSteps,
  getIsLoaded,
  getOnboardingDismissed,
  getWelcomeTileDismissed,
} from 'store/slices/onboarding/selectors';
import { fetchOnboarding, updateOnboarding } from 'store/slices/onboarding/thunks';
import { actions } from 'store/slices/welcomeTile/reducer';
import { getShow } from 'store/slices/welcomeTile/selectors';
import Checkbox from 'redesign/components/atoms/Checkbox/Checkbox';
import IconClose from 'redesign/components/atoms/Icons/Close';
import PictureWelcomeDay from 'redesign/components/atoms/Pictures/WelcomeDay';
import PictureWelcomeEvening from 'redesign/components/atoms/Pictures/WelcomeEvening';
import PictureWelcomeMorning from 'redesign/components/atoms/Pictures/WelcomeMorning';

import { getTimeOfDay, trackEvent } from './utils';
import { Box, Close, Content, Description, Picture, Title } from './WelcomeTile.styled';

type WelcomeTileProps = {
  className?: string;
};

const WelcomeTile = ({ className }: WelcomeTileProps) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const email = useAppSelector(getEmail);
  const firstName = useAppSelector(getFirstName);
  const lastName = useAppSelector(getLastName);
  const timeOfDay = getTimeOfDay(new Date());
  const onboardingIsLoaded = useAppSelector(getIsLoaded);
  const onboardingDismissed = useAppSelector(getOnboardingDismissed);
  const welcomeTileDismissed = useAppSelector(getWelcomeTileDismissed);
  const hasFinishedAllSteps = useAppSelector(getFinishedAllSteps);
  const hasFinishedOnboarding = onboardingDismissed || hasFinishedAllSteps;
  const show = useAppSelector(getShow);

  const handleClose = useCallback(() => {
    setIsOpen(false);

    if (isChecked) {
      dispatch(updateOnboarding({ welcome_tile_dismissed: true }));
      trackEvent(WELCOME_TILE_DO_NOT_SHOW_AGAIN);
    } else {
      dispatch(actions.add(email));
      trackEvent(WELCOME_TILE_CLOSE);
    }
  }, [dispatch, isChecked, email]);

  const handleCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  }, []);

  useEffect(() => {
    dispatch(fetchOnboarding());
  }, [dispatch]);

  if (!show || !onboardingIsLoaded || !hasFinishedOnboarding || welcomeTileDismissed || !isOpen)
    return null;

  return (
    <Box data-id="welcome-tile" className={className}>
      <Close>
        <label>
          <Checkbox checked={isChecked} onChange={handleCheckboxChange} data-id="checkbox" />
          {t('features.homepage.welcome_tile.do_not_show_again')}
        </label>
        <IconClose width={32} height={32} onClick={handleClose} data-id="close" />
      </Close>
      <Content>
        <Title data-id="title">
          {t(`features.homepage.welcome_tile.good_${timeOfDay}`)}, {firstName} {lastName}!
        </Title>
        <Description data-id="description">
          {t('features.homepage.welcome_tile.description')}
        </Description>
        <Picture
          as={
            timeOfDay === 'morning'
              ? PictureWelcomeMorning
              : timeOfDay === 'day'
              ? PictureWelcomeDay
              : PictureWelcomeEvening
          }
        />
      </Content>
    </Box>
  );
};

export default memo(WelcomeTile);
