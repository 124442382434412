import NumberFormatParse from 'number-format-parse';

export const ensureNumericMoneyValue = (value?: string | number, language = navigator.language) => {
  if (typeof value === 'string') {
    if (value === '') {
      return '';
    }

    if (isNaN(Number(value)) === false) {
      return Number(value);
    }

    try {
      return new NumberFormatParse(language).parse(value.replace(' ', '')) || '';
    } catch (error) {
      console.error('error', error, { value });
    }
  }

  return value ?? '';
};

export const toGrossValue = (
  net: string | number,
  vat?: number | null,
  language = navigator.language
) => {
  if (net === '') {
    return '';
  }

  const safeNet = ensureNumericMoneyValue(net || 0, language);
  return Number(((safeNet * (vat || 0)) / 100 + safeNet).toFixed(3));
};

export const toNetValue = (
  gross: string | number,
  vat?: number | null,
  language = navigator.language
) => {
  if (gross === '') {
    return '';
  }

  const safeGross = ensureNumericMoneyValue(gross || 0, language);
  return Number((safeGross / (1 + (vat || 0) / 100)).toFixed(3));
};

export const pasteHandler = (e: any) => {
  e.preventDefault();
  const pastedValue = e.clipboardData.getData('text');
  const parsedValue = ensureNumericMoneyValue(pastedValue);
  e.target.value = parsedValue;
};

export const grossNetConversion = {
  toGross: toGrossValue,
  toNet: toNetValue,
};
