import React, { Component } from 'react';
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import cx from 'classnames';
import { func, shape, string } from 'prop-types';

import { t } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';
import If from 'components/Conditions/If';
import Preview from 'components/ImageUploader/Preview/Preview';
import Loading from 'components/Loading';

import styles from './Preview.module.css';

const pdfExtensions = ['pdf'];
const unavailableExtensions = ['tif', 'tiff'];

class PDFPreview extends Component {
  state = {
    previewLoading: false,
  };

  componentDidMount() {
    this.generatePreview();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fileId === this.props.fileId) return;

    this.generatePreview();
  }

  generatePreview = () => {
    if (!this.canvas) return;

    const { invoiceId, fileId, downloadFilePreview } = this.props;

    downloadFilePreview(invoiceId, fileId).then(({ rawResponse }) => {
      if (!rawResponse) return;
      this.setState({ previewLoading: true });

      pdfjs.getDocument(rawResponse).promise.then((pdf) => {
        pdf.getPage(1).then((page) => {
          const scale = 2;
          const viewport = page.getViewport({ scale });

          // Prepare canvas using PDF page dimensions.
          const { canvas } = this;
          if (!canvas) return;
          const context = canvas.getContext('2d');

          canvas.width = viewport.width;
          canvas.height = viewport.height;

          // Clear canvas for safety when updating existing one.
          context.clearRect(0, 0, viewport.width, viewport.height);

          // Render PDF page into canvas context.
          page
            .render({
              canvasContext: context,
              viewport,
            })
            .promise.then(() => {
              this.setState({ previewLoading: false });
              this.props.notifyRenderFinish();
            });
        });
      });
    });
  };

  render() {
    const { onClick } = this.props;
    return (
      <div className={styles.canvasContainer}>
        <canvas
          className={cx(styles.canvas, {
            [styles.isHidden]: this.state.previewLoading,
          })}
          onClick={onClick}
          ref={(canvas) => {
            this.canvas = canvas;
          }}
          role="button"
          tabIndex="0"
          onKeyPress={isPressedEnter(onClick)}
        />
        <If ok={this.state.previewLoading}>
          <Loading />
        </If>
      </div>
    );
  }
}

PDFPreview.propTypes = {
  downloadFilePreview: func.isRequired,
  onClick: func,
  notifyRenderFinish: func,
  fileId: string.isRequired,
  invoiceId: string.isRequired,
};

const InvoicePreview = ({
  file,
  invoiceId,
  downloadFilePreview,
  onClick,
  notifyRenderFinish,
  imageClassName = '',
  ...rest
}) => {
  const { extension, id } = file || {};

  if (pdfExtensions.includes(extension)) {
    return (
      <div className={styles.main}>
        <PDFPreview
          invoiceId={invoiceId}
          fileId={id}
          downloadFilePreview={downloadFilePreview}
          onClick={onClick}
          notifyRenderFinish={notifyRenderFinish}
          {...rest}
        />
        <If ok={unavailableExtensions.includes(extension)}>
          <div className={cx(styles.preview, styles.unavailable)} />
        </If>
        {unavailableExtensions.includes(extension) && (
          <p className={styles.previewUnavailable}>
            {t('expenses.upload.actions.overlay_unavailable')}
          </p>
        )}
      </div>
    );
  }

  return (
    <Preview
      imageClassName={imageClassName}
      notifyRenderFinish={notifyRenderFinish}
      file={file}
      onClick={onClick}
    />
  );
};

InvoicePreview.propTypes = {
  downloadFilePreview: func,
  notifyRenderFinish: func.isRequired,
  file: shape({
    extension: string.isRequired,
    id: string,
    url: string.isRequired,
  }).isRequired,
  invoiceId: string,
  onClick: func,
  imageClassName: string,
};

export default InvoicePreview;
