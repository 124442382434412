import React from 'react';
import { bool, func, shape, string } from 'prop-types';

import {
  BASIC_UID,
  DOWNGRADE,
  DOWNGRADE_CANCELLATION,
  PREMIUM_UID,
  UPGRADE,
} from 'constants/payment-plans';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';

import styles from '../Buttons.module.css';

const BookDowngradeButton = ({ currentPlan, onClick, currentPaymentPlanDetails = {} }) => {
  const isBasicButtonDisabled =
    currentPaymentPlanDetails.uid === BASIC_UID ||
    currentPaymentPlanDetails.latestHistoryEntryMode === DOWNGRADE_CANCELLATION ||
    (currentPaymentPlanDetails.uid === PREMIUM_UID &&
      currentPaymentPlanDetails.latestHistoryEntryMode === DOWNGRADE) ||
    (currentPaymentPlanDetails.uid === PREMIUM_UID &&
      currentPaymentPlanDetails.onTrial &&
      currentPaymentPlanDetails.latestHistoryEntryMode === UPGRADE);

  const isPremiumButtonDisabled =
    (currentPaymentPlanDetails.uid === PREMIUM_UID &&
      !currentPaymentPlanDetails.onTrial &&
      currentPaymentPlanDetails.latestHistoryEntryMode !== DOWNGRADE) ||
    currentPaymentPlanDetails.latestHistoryEntryMode === UPGRADE ||
    currentPaymentPlanDetails.latestHistoryEntryMode === DOWNGRADE_CANCELLATION;

  const isButtonDisabled =
    currentPlan === PREMIUM_UID ? isPremiumButtonDisabled : isBasicButtonDisabled;

  return (
    <ActionButton
      appearance="orange"
      onClick={onClick}
      label={t(`profile.payment_plan.comparison_box.button.${currentPlan}`)}
      className={styles.button}
      disabled={isButtonDisabled}
      dataId={`PaymentPlans:${currentPlan}Button`}
      fullWidth
    />
  );
};

BookDowngradeButton.propTypes = {
  currentPlan: string.isRequired,
  onClick: func.isRequired,
  currentPaymentPlanDetails: shape({
    onTrial: bool.isRequired,
    latestHistoryEntryMode: string.isRequired,
  }).isRequired,
};

export default BookDowngradeButton;
