import React from 'react';
import { arrayOf, node } from 'prop-types';

import styles from './FieldsContainer.module.css';

const FieldsContainer = ({ children }) => <div className={styles.container}>{children}</div>;

FieldsContainer.propTypes = {
  children: arrayOf(node),
};

export default FieldsContainer;
