import React from 'react';
import { string } from 'prop-types';

import styles from '../ComparisonTable.module.css';

const HeaderRow = ({ header }) => <div className={styles.headerRow}>{header}</div>;

HeaderRow.propTypes = {
  header: string.isRequired,
};

export default HeaderRow;
