import React, { Children, cloneElement } from 'react';
import { identity } from 'lodash';
import PropTypes from 'prop-types';
import { compose, lifecycle, setDisplayName } from 'recompose';

const Body = ({ children, ...rest }) => (
  <tbody>{Children.map(children, (child) => cloneElement(child, { ...rest }))}</tbody>
);

Body.propTypes = {
  children: PropTypes.node,
};

const enhance = compose(
  setDisplayName('Body'),
  lifecycle({
    checkIfEmpty() {
      const { setHasChildren = identity, children } = this.props;
      const childrenNumber = Children.count(children);
      setHasChildren(!childrenNumber);
    },
    componentDidMount() {
      this.checkIfEmpty();
    },
    componentDidUpdate() {
      this.checkIfEmpty();
    },
  })
);

export default enhance(Body);
