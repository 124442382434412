import React from 'react';
import cx from 'classnames';
import { bool, node, string } from 'prop-types';

import styles from './Card.module.css';

/**
 * Generic `Card` component, which has two compound components that can be used together or separately - `Header` and `Body`
 */
const Card = ({ children, className = '', ...rest }) => (
  <div className={cx(styles.main, className)} {...rest}>
    {children}
  </div>
);

Card.propTypes = {
  children: node,
  /** Class that will be appended to outer div for card */
  className: string,
};

Card.Header = ({ children, className = '' }) => (
  <div className={cx(styles.heading, className)}>{children}</div>
);

Card.Header.displayName = 'CardHeader';

Card.Header.propTypes = {
  children: node.isRequired,
  /** Class that will be appended to outer div for header */
  className: string,
};

Card.Body = ({ children, className = '', withMargin = false, dataId = '' }) => (
  <div
    className={cx(styles.body, className, {
      [styles.bodyMargin]: withMargin,
    })}
    data-id={dataId}
  >
    {children}
  </div>
);

Card.Body.displayName = 'CardBody';

Card.Body.propTypes = {
  children: node.isRequired,
  /** Class that will be appended to outer div for body */
  className: string,
  /** Should margin be added to body element */
  withMargin: bool,
  dataId: string,
};

Card.FullWidthOnMobile = ({ children, className = '' }) => (
  <div className={cx(styles.fullWidthOnMobile, className)}>{children}</div>
);

Card.FullWidthOnMobile.displayName = 'FullWidthOnMobile';

Card.FullWidthOnMobile.propTypes = {
  children: node.isRequired,
  className: node,
};

export const { Body } = Card;
export const { Header } = Card;
export const { FullWidthOnMobile } = Card;
export default Card;
