import React from 'react';

import styles from '../StatusIcons.module.css';

const PartlyAssignedStatusIcon = () => (
  <svg viewBox="0 0 20 20" className={styles.statusIcon} xmlns="http://www.w3.org/2000/svg">
    <g className={styles.strokeSuccess} fill="none" fillRule="evenodd">
      <rect width="19" height="19" x=".5" y=".5" rx="2" />
      <g fillRule="nonzero" strokeLinecap="round">
        <path d="M10.806 12.786l-1.052 1.76c-.768 1.08-1.742 1.26-2.922.539-.836-.685-.907-1.594-.211-2.728l.925-1.46c.875-1.41 1.792-1.856 2.75-1.337" />
        <path d="M9.765 7.586l1.115-1.669c.805-1.054 1.784-1.2 2.939-.437.812.713.85 1.625.116 2.734L12.96 9.64c-.924 1.379-1.856 1.792-2.796 1.24" />
      </g>
    </g>
  </svg>
);

export default PartlyAssignedStatusIcon;
