import styled from 'styled-components';

import ModalComponent from 'components/Modal';

export const Modal = styled(ModalComponent)`
  max-width: 660px;
  width: 100%;
`;

export const ModalBody = styled.div`
  padding: 20px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;

  > *:not(:last-child) {
    margin-right: 20px;
  }
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 36px;
`;

export const NotificationSectionWrapper = styled.div`
  margin-bottom: 116px;
`;
