import React, { memo } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import { t } from 'shared/utils';

import Amount, { Color } from '../../ExpensesRevenues/Fields/Amount';
import Name from '../../ExpensesRevenues/Fields/Name';
import {
  isAnyFieldFilled,
  requiredAndTouchedChecker,
  requiredValidate,
} from '../../ExpensesRevenues/Fields/utils';
import OtherLayout from '../../ExpensesRevenues/OtherLayout/OtherLayout';
import type { OtherRevenue } from '../types';

const OtherRevenues = ({ fields }: WrappedFieldArrayProps<OtherRevenue>) => (
  <div>
    {fields.map((otherRevenue, index) => {
      const anyFilled = isAnyFieldFilled<OtherRevenue>(fields.get(index));
      const validate = anyFilled ? requiredValidate : undefined;
      const checker = anyFilled ? requiredAndTouchedChecker : undefined;

      return (
        <OtherLayout
          key={index}
          nameField={
            <Name
              name={`${otherRevenue}.name`}
              label={t(
                'features.financial_planning.liquidity_calculator.expenses.fields.other_revenues.name.label'
              )}
              validate={validate}
              checker={checker}
              isLabelHidden
            />
          }
          amountField={
            <Amount
              name={`${otherRevenue}.amount`}
              label={t(
                'features.financial_planning.liquidity_calculator.expenses.fields.other_revenues.amount.label'
              )}
              color={Color.Green}
              validate={validate}
              checker={checker}
              isLabelHidden
            />
          }
        />
      );
    })}
  </div>
);

export default memo(OtherRevenues);
