import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';

import { showNotification } from 'actions/notification';
import { editCashbook } from 'api/me/cashbooks';
import { CASHBOOKS_EDIT_REMINDER } from 'constants/piwik';
import validationNotification from 'notifications/validation';
import { t } from 'shared/utils';
import {
  mapServerErrorsToFieldErrors,
  mapServerErrorsToNotificationError,
} from 'shared/utils/server-validation';
import { Cashbook } from 'types/entities/Cashbook';
import Button from 'components/Button';
import { ModalHeader } from 'components/Modal';
import { trackEventHandler } from 'features/cashbooks/utils';

import { getNotificationPayload } from '../../../Creator/utils';
import NotificationSection from '../../../NotificationSection/NotificationSection';
import { NotificationFormData } from '../../../NotificationSection/types';
import {
  Buttons,
  Modal,
  ModalBody,
  NotificationSectionWrapper,
  Subtitle,
  Title,
} from './NotificationModal.styled';

import styles from './NotificationModal.module.css';

const FormName = 'change-notification';

type NotificationModalOwnProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  cashbook: Cashbook;
};

type FormData = NotificationFormData;

type CreatorModalProps = NotificationModalOwnProps &
  InjectedFormProps<FormData, NotificationModalOwnProps>;

const NotificationModal = ({
  isOpen,
  onClose,
  handleSubmit,
  onSave,
  reset,
  cashbook,
}: CreatorModalProps) => {
  const [isSending, setIsSending] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = useCallback(
    async (formValues: FormData) => {
      setIsSending(true);

      const notificationPayload = getNotificationPayload(formValues);

      try {
        await editCashbook(notificationPayload, cashbook.id);
      } catch ({ response }) {
        const notification = validationNotification(mapServerErrorsToNotificationError(response));
        dispatch(showNotification(notification));
        setIsSending(false);
        throw new SubmissionError(mapServerErrorsToFieldErrors(response));
      }

      trackEventHandler(CASHBOOKS_EDIT_REMINDER);
      setIsSending(false);
      await onSave();

      handleClose();
    },
    [dispatch, history]
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        headerStyle={styles.header}
        headerContentStyle={styles.headerContent}
        onClose={onClose}
        withCloseButton
      >
        {t('cashbooks.cash_transactions.notification_modal.header')}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Title>{t('cashbooks.cash_transactions.notification_modal.title')}</Title>
          <Subtitle>{t('cashbooks.cash_transactions.notification_modal.subtitle')}</Subtitle>
          <NotificationSectionWrapper>
            <NotificationSection formName={FormName} />
          </NotificationSectionWrapper>
          <Buttons>
            <Button
              appearance="outlined"
              label={t('cashbooks.cash_transactions.notification_modal.cancel')}
              type="button"
              disabled={isSending}
              onClick={onClose}
            />
            <Button
              label={t('cashbooks.cash_transactions.notification_modal.save')}
              type="submit"
              disabled={isSending}
              data-id="submit-notifications"
            />
          </Buttons>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default reduxForm<FormData, NotificationModalOwnProps>({
  form: FormName,
  enableReinitialize: true,
  persistentSubmitErrors: true,
})(NotificationModal);
