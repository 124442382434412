import React from 'react';
import cx from 'classnames';
import { bool, func, node, oneOfType, string } from 'prop-types';

import styles from './Section.module.css';

const Section = ({ children, className = '', title, column = false }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.title}>{title}</div>
    <div className={cx(styles.content, { [styles.column]: column })}>{children}</div>
  </div>
);

Section.propTypes = {
  children: oneOfType([func, node]),
  className: string,
  title: oneOfType([string, oneOfType([func, node])]).isRequired,
  column: bool,
};

export default Section;
