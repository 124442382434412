import React, { ReactNode } from 'react';
import { CellProps, Column, Row } from 'react-table';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { getCashTransactionFiles } from 'api/me/cashbooks/cashTransactions';
import EntityPath from 'constants/entitiesPaths';
import { formatMoney, l, t } from 'shared/utils';
import { CashTransaction, TransactionType } from 'types/entities/CashTransaction';
import { CashTransactionFile } from 'types/entities/CashTransactionFile';
import IconCorrection from 'components/ActionIcons/IconCorrection';
import Comment from 'components/Comment/Comment';
import IconDelete from 'components/Icons/IconDelete';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import TableCell from 'components/v2/Table/TableCell/TableCell';
import TableHeader from 'components/v2/Table/TableHeader/TableHeader';

import {
  ActionButton,
  ActionCell,
  CommentBody,
  CommentHeader,
  CommentTooltip,
  CommentWrapper,
  GrossAmount,
  IconDownload,
  IconLock,
  IdNumber,
} from './CashTransactions.styled';

import styles from './utils.module.css';

export type RowData = {
  id: string;
  idNumber: string;
  documentNumber: string | null;
  transactionDate: string;
  description: string;
  grossAmount: ReactNode;
  vat: string;
  taxAmount: string;
  cashbookId: string;
  cashbookAmountAfterTransaction: string;
  reasonForDeletion: string | null;
  reasonForRetroactiveCreation: string | null;
  reasonForCorrection: string | null;
  empty: string;
  actions: null;
  hasFiles: boolean;
};

/* eslint-disable react/display-name */
export const getColumns = ({
  cashTransactions,
  onDelete,
  onEdit,
}: {
  cashTransactions: CashTransaction[];
  onDelete: (cashTransaction?: CashTransaction) => void;
  onEdit: (cashTransaction?: CashTransaction) => void;
}): Column<RowData>[] => [
  {
    Header: t('cashbooks.cash_transactions.table.headers.transaction_date'),
    accessor: 'transactionDate',
  },
  {
    Header: (
      <IdNumber>
        {t('cashbooks.cash_transactions.table.headers.id_number')}
        <InfoIcon
          tooltipPlacement="right"
          tooltipClassName={styles.tooltip}
          text={t('cashbooks.cash_transactions.table.headers.id_number_info')}
        />
      </IdNumber>
    ),
    accessor: 'idNumber',
    Cell: ({ value }: CellProps<RowData>) => <TableCell align="center">{value}</TableCell>,
  },
  {
    Header: t('cashbooks.cash_transactions.table.headers.document_number'),
    accessor: 'documentNumber',
  },
  {
    Header: t('cashbooks.cash_transactions.table.headers.description'),
    accessor: 'description',
  },
  {
    Header: (
      <TableHeader align="right">
        {t('cashbooks.cash_transactions.table.headers.gross_amount')}
      </TableHeader>
    ),
    accessor: 'grossAmount',
    Cell: ({ value }: CellProps<RowData>) => <TableCell align="right">{value}</TableCell>,
  },
  {
    Header: (
      <TableHeader align="right">{t('cashbooks.cash_transactions.table.headers.vat')}</TableHeader>
    ),
    accessor: 'vat',
    Cell: ({ value }: CellProps<RowData>) => <TableCell align="right">{value}</TableCell>,
  },
  {
    Header: (
      <TableHeader align="right">
        {t('cashbooks.cash_transactions.table.headers.tax_amount')}
      </TableHeader>
    ),
    accessor: 'taxAmount',
    Cell: ({ value }: CellProps<RowData>) => <TableCell align="right">{value}</TableCell>,
  },
  {
    Header: (
      <TableHeader align="right">
        {t('cashbooks.cash_transactions.table.headers.cashbook_amount_after_transaction')}
      </TableHeader>
    ),
    accessor: 'cashbookAmountAfterTransaction',
    Cell: ({ value }: CellProps<RowData>) => <TableCell align="right">{value}</TableCell>,
  },
  {
    Header: '',
    accessor: 'empty',
  },
  {
    Header: (
      <TableHeader align="right">
        {t('cashbooks.cash_transactions.table.headers.actions')}
      </TableHeader>
    ),
    accessor: 'actions',
    Cell: ({ row }: { row: Row<RowData> }) => {
      const downloadFile = async () => {
        let filesResponse = null;

        try {
          filesResponse = await getCashTransactionFiles(row.original.cashbookId, row.original.id);
        } catch (e) {
          return;
        }

        const files =
          build<CashTransactionFile>(
            normalize(filesResponse.data),
            EntityPath.CashTransactionFiles
          ) || [];

        if (files.length === 0) return;

        // for now we can only assign one file to cash entry
        const file = files[0];

        const download = document.createElement('a');
        download.href = file.file;
        download.download = file.filename;
        download.click();
      };

      const cashTransactionToDelete = cashTransactions.find(
        (entry) => entry.id === row.original.id
      );

      const downloadFileButton = row.original.hasFiles && (
        <ActionButton data-id="download" onClick={downloadFile}>
          <IconDownload height="14" />
        </ActionButton>
      );

      const comment = (row.original.reasonForDeletion ||
        row.original.reasonForRetroactiveCreation ||
        row.original.reasonForCorrection) && (
        <CommentWrapper data-id="button-reason-for-deletion">
          <Comment
            text={
              <CommentTooltip>
                {row.original.reasonForDeletion && (
                  <div>
                    <CommentHeader>
                      {t('cashbooks.cash_transactions.comment_deletion_header')}
                    </CommentHeader>
                    <CommentBody>{row.original.reasonForDeletion}</CommentBody>
                  </div>
                )}
                {row.original.reasonForRetroactiveCreation && (
                  <div>
                    <CommentHeader>
                      {t('cashbooks.cash_transactions.comment_creation_header')}
                    </CommentHeader>
                    <CommentBody>{row.original.reasonForRetroactiveCreation}</CommentBody>
                  </div>
                )}
                {row.original.reasonForCorrection && (
                  <div>
                    <CommentHeader>
                      {t('cashbooks.cash_transactions.comment_correction_header')}
                    </CommentHeader>
                    <CommentBody>{row.original.reasonForCorrection}</CommentBody>
                  </div>
                )}
              </CommentTooltip>
            }
          />
        </CommentWrapper>
      );

      return (
        <ActionCell>
          {row.original.reasonForDeletion ? (
            <>
              {comment}
              {downloadFileButton}
              <IconLock />
            </>
          ) : (
            <>
              {comment}
              {downloadFileButton}
              <ActionButton data-id="correct" onClick={() => onEdit(cashTransactionToDelete)}>
                <IconCorrection />
              </ActionButton>
              <ActionButton data-id="delete" onClick={() => onDelete(cashTransactionToDelete)}>
                <IconDelete height="14" />
              </ActionButton>
            </>
          )}
        </ActionCell>
      );
    },
  },
];
/* eslint-enable react/display-name */

export const getTableData = ({
  cashTransactions,
}: {
  cashTransactions: CashTransaction[];
}): RowData[] =>
  cashTransactions.map((cashTransaction) => {
    const isNegative = cashTransaction.transactionType === TransactionType.Expense;

    return {
      id: cashTransaction.id,
      documentNumber: cashTransaction.documentNumber,
      idNumber: cashTransaction.idNumber,
      transactionDate: cashTransaction.transactionDate,
      description: cashTransaction.description,
      grossAmount: (
        <GrossAmount isNegative={isNegative}>
          {formatMoney((isNegative ? -1 : 1) * cashTransaction.grossAmount)}
        </GrossAmount>
      ),
      vat: l(cashTransaction.vat, 'percentage', { precision: 2 }),
      taxAmount: formatMoney(cashTransaction.taxAmount),
      cashbookId: cashTransaction.cashbookId,
      cashbookAmountAfterTransaction: formatMoney(cashTransaction.cashbookAmountAfterTransaction),
      reasonForDeletion: cashTransaction.reasonForDeletion,
      reasonForRetroactiveCreation: cashTransaction.reasonForRetroactiveCreation,
      reasonForCorrection: cashTransaction.reasonForCorrection,
      hasFiles: cashTransaction.hasFiles,
      actions: null,
      empty: '',
    };
  });
