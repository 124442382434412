import React, { useRef } from 'react';
import cx from 'classnames';

import { DownloadContract, NextFileBtn, PrevFileBtn } from 'components/Dropzone/Gallery';
import Thumbnail from 'components/Dropzone/Thumbnail/Thumbnail';

import type { ActiveNode, PreviewFile, TrackingEvents } from '../types';

import styles from './Gallery.module.css';

type FullscreenGalleryProps = {
  activeFileId?: string;
  className?: string;
  files: PreviewFile[];
  onSelect: (fileId: string) => void;
  setActiveThumbnailNode: ActiveNode;
  trackingEvents?: TrackingEvents;
  onDownloadContractClick?: () => void;
  contractDownloadName: string;
  contractDownloadUrl?: string;
  isDownloadingContract: boolean;
};

const FullscreenGallery = ({
  activeFileId = '',
  className = '',
  files,
  onSelect,
  setActiveThumbnailNode,
  trackingEvents,
  onDownloadContractClick,
  contractDownloadName,
  contractDownloadUrl,
  isDownloadingContract,
}: FullscreenGalleryProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const thumbnailWidth = 75;

  const scroll = (scrollOffset: number) =>
    ref.current !== null && (ref.current.scrollLeft += scrollOffset);

  return (
    <div className={cx(styles.main, className)}>
      <div className={styles.thumbnailsContainer} ref={ref}>
        {files.map((file, index: number) => (
          <Thumbnail
            file={{ ...file, name: index }}
            key={file.id}
            isActive={file.id === activeFileId}
            onClick={() => onSelect(file.id)}
            setActiveThumbnailNode={setActiveThumbnailNode}
            trackingEvents={trackingEvents}
            fileNumber={index + 1}
            isFullscreen
          />
        ))}

        <DownloadContract
          onClick={onDownloadContractClick}
          contractDownloadUrl={contractDownloadUrl}
          contractDownloadName={contractDownloadName}
          isDownloadingContract={isDownloadingContract}
        />

        {files.length > 15 && (
          <>
            <PrevFileBtn onClick={() => scroll(-thumbnailWidth)} />
            <NextFileBtn onClick={() => scroll(thumbnailWidth)} />
          </>
        )}
      </div>
    </div>
  );
};

export default FullscreenGallery;
