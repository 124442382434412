import React from 'react';

import { SUPPORT_LINK } from 'constants/custom-links';
import { t } from 'shared/utils';

const FigoErrorMessage = () => (
  <>
    {t('bank_accounts.creator.error_notification_1')}
    {` `}
    <a
      href={SUPPORT_LINK}
      target="_blank"
      rel="noopener noreferrer"
      data-id="FlashNotification:message-link"
    >
      {t('bank_accounts.creator.error_notification_2')}
    </a>
  </>
);

export default FigoErrorMessage;
