import React, { ChangeEvent, useState } from 'react';
import { useKeyPress } from 'react-use';
import { WrappedFieldProps } from 'redux-form';

import TextField from 'components/Form/TextField';

import {
  getNumberWithinRange,
  isValueEmpty,
  isValueFloat,
  isValueFormatSatisfying,
  isValuePossiblyFloat,
  withComma,
  withDot,
} from './utils';

type NumberFieldProps = {
  dataId?: string;
  max?: number;
  min?: number;
  name: string;
  placeholder?: string;
  loopRange?: boolean;
  disableDecimals?: boolean;
  disableNegatives?: boolean;
} & WrappedFieldProps;

const NumberField = ({
  name,
  placeholder,
  input: { value: inputValue, onChange, ...input },
  dataId,
  max = 1e20,
  min = -1e20,
  loopRange = false,
  disableDecimals = false,
  disableNegatives = false,
  ...rest
}: NumberFieldProps) => {
  const [commaPressed] = useKeyPress(',');
  const [minusPressed] = useKeyPress('-');
  const [parsedValue, setValue] = useState(inputValue);

  const emitChange = (value: string) => {
    if (disableDecimals && commaPressed) {
      return;
    }

    if (disableNegatives && minusPressed) {
      return;
    }

    if (isValueEmpty(value)) {
      setValue(value);
      return;
    }

    if (isValueFormatSatisfying(value)) {
      const nextNumber = getNumberWithinRange({ loopRange, min, max, value: withDot(value) });
      let nextValue = String(nextNumber);

      if (isValuePossiblyFloat(value)) {
        // NOTE: return original string from input which ends with a comma ','
        nextValue = value;
      }

      if (isValueFloat(value)) {
        // NOTE: restore float precision
        nextValue = withComma(nextNumber.toFixed(value.split(',')[1].length));
      }

      setValue(nextValue);
    }
  };

  const handleInputChange = (value: string) => emitChange(value);

  return (
    <TextField
      name={name}
      dataId={dataId}
      input={{
        ...input,
        // TODO: replace with normalize as long as we have redux-form
        value: parsedValue,
        onChange: (event: ChangeEvent<HTMLInputElement>) => {
          handleInputChange(event.target.value);
          return onChange(parsedValue);
        },
      }}
      {...rest}
    />
  );
};

export default NumberField;
