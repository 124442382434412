import React from 'react';

import styles from '../StatusIcons.module.css';

const DraftStatusIcon = () => (
  <svg
    viewBox="0 0 20 20"
    className={styles.statusIcon}
    width="21"
    height="21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <rect className={styles.strokeNeutral} x=".5" y=".5" width="19" height="19" rx="2" />
      <path
        d="M11.667 4H6.333c-.733 0-1.326.6-1.326 1.333L5 14.667C5 15.4 5.593 16 6.327 16h7.34C14.4 16 15 15.4 15 14.667V7.333L11.667 4zM6.333 14.667V5.333H11V8h2.667v6.667H6.333z"
        className={styles.fillNeutral}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default DraftStatusIcon;
