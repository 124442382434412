import React, { Component } from 'react';
import { connect } from 'react-redux';
import { defaultMenuRenderer as menuRenderer } from 'react-select';
import cx from 'classnames';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import { fetchProductCatalogItems } from 'actions/product-catalog/items/items';
import { parseProductCatalogItems } from 'reducers/product-catalog/items';
import { noop, t } from 'shared/utils';
import SelectField from 'components/Form/SelectField';
import I18n from 'components/I18n';

import styles from './ItemsSelect.module.css';

const ItemOption = ({ name }) => (
  <div role="menuitem" className={styles.item}>
    <div className={styles.creditorName}>{name}</div>
  </div>
);

ItemOption.propTypes = {
  name: PropTypes.string.isRequired,
};

const getMenuRenderer = (fetchNextItems, isLastPage) => (props) =>
  (
    <div>
      {menuRenderer({
        ...props,
      })}
      <button
        className={cx(styles.fetchNext, { [styles.hidden]: isLastPage })}
        onClick={(event) => {
          event.preventDefault();
          fetchNextItems();
        }}
      >
        <I18n t="expenses.form.fetch_next_bank_transfers" />
      </button>
    </div>
  );

export class ItemsSelect extends Component {
  state = {
    items: [],
    inputValue: '',
    selectedItem: null,
    isFetchingItems: false,
    pagination: {
      page: 1,
      perPage: 5,
      totalPages: 1,
    },
    filters: { fullTextSearch: '' },
  };

  componentDidMount() {
    if (this.props.skipFetchOnMount) return;
    this.getItems();
  }

  createFilters = () => {
    const { groupId } = this.props;
    const { filters } = this.state;
    return {
      filters: {
        ...filters,
        ...(groupId && { assignableToGroupId: groupId }),
      },
    };
  };

  createPagination = () => {
    const { pagination } = this.state;
    return {
      pagination: {
        ...pagination,
        page: 1,
      },
    };
  };

  getItems = async () => {
    this.setState({ isFetchingItems: true });

    const { fetchItems } = this.props;
    const { response, headers } = await fetchItems({
      ...this.createPagination(),
      ...this.createFilters(),
    });
    const totalPages = parseInt(headers['total-pages'], 10);
    this.setState({
      items: parseProductCatalogItems({ response }),
      isFetchingItems: false,
      pagination: { ...this.state.pagination, totalPages, page: 1 },
    });
  };

  getMoreItems = async () => {
    this.setState({ isFetchingItems: true });

    const {
      pagination: { page, ...restPaginationProps },
    } = this.state;
    const { fetchItems } = this.props;

    const { response, headers } = await fetchItems({
      pagination: { page: page + 1, ...restPaginationProps },
      ...this.createFilters(),
    });
    const newPagination = {
      page: parseInt(headers.page, 10),
      totalPages: parseInt(headers['total-pages'], 10),
    };

    this.setState({
      items: [...this.state.items, ...parseProductCatalogItems({ response })],
      isFetchingItems: false,
      pagination: { ...this.state.pagination, ...newPagination },
    });
  };

  _handleFilterChange = (name) => {
    const filters = { fullTextSearch: name };
    this.setState({ filters }, () => this.handlePaginationChange({ page: 1 }));
  };

  handleFilterChange = debounce(this._handleFilterChange, 200);

  handlePaginationChange = (pagination) => {
    if (this.state.isFetchingItems) return;

    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
      },
      this.getItems
    );
  };

  closeMenu = () => {};

  addItem = () => {
    const { selectedItem } = this.state;
    if (!selectedItem) return;
    this.props.addItem(this.state.selectedItem);
    this.selectItem(undefined);
  };

  selectItem = (selectedItem) => {
    const selectedItemId = (selectedItem && selectedItem.id) || '';
    this.setState({ selectedItem, selectedItemId });
    const onChange = this.props.changeHandler || this.props.input.onChange;
    onChange(selectedItemId);
  };

  refHandler = (ref) => {
    // Since react-select v.1.0.0-beta closeMenu usess `this`, but doesn't bind the context automatically.
    this.instance = ref;
    this.closeMenu = ref ? ref.closeMenu.bind(ref) : noop;
  };

  handleInputChange = (e) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }

    if (this.state.inputValue !== e) {
      this.setState({ inputValue: e }, this.handleFilterChange(e));
    }
  };

  render() {
    const {
      productCatalogGroupItems = [],
      input,
      visible,
      className,
      clearable,
      placeholder,
      tabSelectsValue,
      onBlurResetsInput,
      onCloseResetsInput,
      onSelectResetsInput,
      refetchOnOpen = false,
    } = this.props;
    const { pagination, items, selectedItemId, isFetchingItems } = this.state;

    const allItems = items
      .map((item) => ({
        ...item,
        selected: Boolean(
          productCatalogGroupItems.find((selectedGroupItem) => item.id === selectedGroupItem.id)
        ),
        label: item.name,
        value: item.id,
      }))
      .filter((item) => !item.selected);

    const { page, totalPages } = pagination;
    const isLastPage = page === totalPages;

    if (!visible) return null;

    return (
      <div className={cx(styles.main, className)}>
        <SelectField
          {...this.props}
          onOpen={refetchOnOpen && this.getItems}
          autoload={false}
          clearable={clearable}
          filterOption={() => true}
          searchable
          options={allItems}
          onChange={this.selectItem}
          onBlurResetsInput={onBlurResetsInput}
          onCloseResetsInput={onCloseResetsInput}
          onSelectResetsInput={onSelectResetsInput}
          value={selectedItemId}
          onInputChange={this.handleInputChange}
          tabSelectsValue={tabSelectsValue}
          className={styles.itemsSelect}
          name={this.props.name || input.name}
          isLoading={isFetchingItems}
          menuRenderer={getMenuRenderer(() => this.getMoreItems(), isLastPage)}
          optionRenderer={(options) => <ItemOption {...options} />}
          ref={this.refHandler}
          placeholder={placeholder}
          noResultsText={t('expenses.form.empty_bank_transfers')}
          disableNative
        />
      </div>
    );
  }
}

ItemsSelect.propTypes = {
  productCatalogGroupItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  name: PropTypes.string,
  fetchItems: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  onSelectResetsInput: PropTypes.bool.isRequired,
  visible: PropTypes.bool,
  changeHandler: PropTypes.func,
  onKeyDown: PropTypes.func,
  skipFetchOnMount: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  className: PropTypes.string,
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlurResetsInput: PropTypes.bool,
  onCloseResetsInput: PropTypes.bool,
  tabSelectsValue: PropTypes.bool,
  initialValue: PropTypes.string,
  refetchOnOpen: PropTypes.bool,
  groupId: PropTypes.string,
};

ItemsSelect.defaultProps = {
  clearable: true,
  placeholder: t('forms.placeholders.select'),
  onBlurResetsInput: true,
  onCloseResetsInput: false,
  tabSelectsValue: true,
  initialValue: '',
  skipFetchOnMount: false,
};

const mapDispatchToProps = (dispatch) => ({
  fetchItems: (...args) => dispatch(fetchProductCatalogItems(...args)),
});

export default connect(null, mapDispatchToProps)(ItemsSelect);
