import React from 'react';
import cx from 'classnames';
import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';

import sharedStyles from 'shared/styles/table.module.css';

import localStyles from './InvoiceColumn.module.css';

const styles = { ...sharedStyles, ...localStyles };

const InvoiceColumn = ({ className, children, size = 'auto', dataId, align = 'left', ...rest }) => (
  <td
    {...rest}
    className={cx(styles.invoiceColumn, styles[size], styles[`pullRow${upperFirst(align)}`], {})}
  >
    <div className={className} data-id={dataId}>
      {children}
    </div>
  </td>
);

InvoiceColumn.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
  align: PropTypes.string,
  dataId: PropTypes.string,
};

export default InvoiceColumn;
