export const FETCH_CUSTOMER_DEFAULTS_REQUEST = 'DEFAULT_VALUES/FETCH_CUSTOMER_DEFAULTS_REQUEST';
export const FETCH_CUSTOMER_DEFAULTS_SUCCESS = 'DEFAULT_VALUES/FETCH_CUSTOMER_DEFAULTS_SUCCESS';
export const FETCH_CUSTOMER_DEFAULTS_FAILURE = 'DEFAULT_VALUES/FETCH_CUSTOMER_DEFAULTS_FAILURE';

export const UPDATE_CUSTOMER_DEFAULTS_REQUEST = 'DEFAULT_VALUES/UPDATE_CUSTOMER_DEFAULTS_REQUEST';
export const UPDATE_CUSTOMER_DEFAULTS_SUCCESS = 'DEFAULT_VALUES/UPDATE_CUSTOMER_DEFAULTS_SUCCESS';
export const UPDATE_CUSTOMER_DEFAULTS_FAILURE = 'DEFAULT_VALUES/UPDATE_CUSTOMER_DEFAULTS_FAILURE';

export const RESET_CUSTOMER_DEFAULTS_REQUEST = 'DEFAULT_VALUES/RESET_CUSTOMER_DEFAULTS_REQUEST';
export const RESET_CUSTOMER_DEFAULTS_SUCCESS = 'DEFAULT_VALUES/RESET_CUSTOMER_DEFAULTS_SUCCESS';
export const RESET_CUSTOMER_DEFAULTS_FAILURE = 'DEFAULT_VALUES/RESET_CUSTOMER_DEFAULTS_FAILURE';

export const FETCH_CLIENT_DEFAULTS_REQUEST = 'DEFAULT_VALUES/FETCH_CLIENT_DEFAULTS_REQUEST';
export const FETCH_CLIENT_DEFAULTS_SUCCESS = 'DEFAULT_VALUES/FETCH_CLIENT_DEFAULTS_SUCCESS';
export const FETCH_CLIENT_DEFAULTS_FAILURE = 'DEFAULT_VALUES/FETCH_CLIENT_DEFAULTS_FAILURE';

export const UPDATE_CLIENT_DEFAULTS_REQUEST = 'DEFAULT_VALUES/UPDATE_CLIENT_DEFAULTS_REQUEST';
export const UPDATE_CLIENT_DEFAULTS_SUCCESS = 'DEFAULT_VALUES/UPDATE_CLIENT_DEFAULTS_SUCCESS';
export const UPDATE_CLIENT_DEFAULTS_FAILURE = 'DEFAULT_VALUES/UPDATE_CLIENT_DEFAULTS_FAILURE';

export const RESET_CLIENT_DEFAULTS_REQUEST = 'DEFAULT_VALUES/RESET_CLIENT_DEFAULTS_REQUEST';
export const RESET_CLIENT_DEFAULTS_SUCCESS = 'DEFAULT_VALUES/RESET_CLIENT_DEFAULTS_SUCCESS';
export const RESET_CLIENT_DEFAULTS_FAILURE = 'DEFAULT_VALUES/RESET_CLIENT_DEFAULTS_FAILURE';

export const CLEAR_DEFAULT_VALUES = 'DEFAULT_VALUES/CLEAR_DEFAULT_VALUES';

export type FORM_SECTION =
  | 'form_invoice'
  | 'form_payment_reminder'
  | 'form_proposal'
  | 'form_order_confirmation'
  | 'form_delivery_note'
  | 'email_invoice'
  | 'email_payment_reminder'
  | 'email_proposal'
  | 'email_order_confirmation';

export interface FetchCustomerDefaultsActions {
  type:
    | typeof FETCH_CUSTOMER_DEFAULTS_REQUEST
    | typeof FETCH_CUSTOMER_DEFAULTS_SUCCESS
    | typeof FETCH_CUSTOMER_DEFAULTS_FAILURE;
}

export interface UpdateCustomerDefaultsActions {
  type:
    | typeof UPDATE_CUSTOMER_DEFAULTS_REQUEST
    | typeof UPDATE_CUSTOMER_DEFAULTS_SUCCESS
    | typeof UPDATE_CUSTOMER_DEFAULTS_FAILURE;
}

export interface ResetCustomerDefaultsActions {
  type:
    | typeof RESET_CUSTOMER_DEFAULTS_REQUEST
    | typeof RESET_CUSTOMER_DEFAULTS_SUCCESS
    | typeof RESET_CUSTOMER_DEFAULTS_FAILURE;
}

export interface FetchClientDefaultsActions {
  type:
    | typeof FETCH_CLIENT_DEFAULTS_REQUEST
    | typeof FETCH_CLIENT_DEFAULTS_SUCCESS
    | typeof FETCH_CLIENT_DEFAULTS_FAILURE;
}

export interface UpdateClientDefaultsActions {
  type:
    | typeof UPDATE_CLIENT_DEFAULTS_REQUEST
    | typeof UPDATE_CLIENT_DEFAULTS_SUCCESS
    | typeof UPDATE_CLIENT_DEFAULTS_FAILURE;
}

export interface ResetClientDefaultsActions {
  type:
    | typeof RESET_CLIENT_DEFAULTS_REQUEST
    | typeof RESET_CLIENT_DEFAULTS_SUCCESS
    | typeof RESET_CLIENT_DEFAULTS_FAILURE;
}

export type ClientDefaultsActions =
  | FetchCustomerDefaultsActions
  | UpdateCustomerDefaultsActions
  | ResetCustomerDefaultsActions
  | FetchClientDefaultsActions
  | UpdateClientDefaultsActions
  | ResetClientDefaultsActions;
