import IconBell from 'redesign/components/atoms/Icons/Bell';
import IconCheck from 'redesign/components/atoms/Icons/Check';
import IconCrown from 'redesign/components/atoms/Icons/Crown';
import IconGift from 'redesign/components/atoms/Icons/Gift';
import IconTip from 'redesign/components/atoms/Icons/Tip';
import IconWarning from 'redesign/components/atoms/Icons/Warning';

export const Icons = {
  warning: IconWarning,
  crown: IconCrown,
  tip: IconTip,
  gift: IconGift,
  check: IconCheck,
  bell: IconBell,
};
