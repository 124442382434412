import axios from 'axios';
import { get, merge, omit } from 'lodash';
import qs from 'qs';
import { bindActionCreators } from 'redux';

import { invalidateAndNotify as invalidateAndNotifyAC } from 'actions/auth';
import { showNotification as showNotificationAC } from 'actions/notification';
import { BASE_URL, CALL_API } from 'constants/api';
import contentTypes from 'constants/content-types';
import { serviceUnavailable } from 'notifications/service-unavailable';
import { getLoginProcessStatus } from 'reducers';
import {
  processParams,
  safelyNormalize,
  sanitizeResponse,
  timedOutRequestChecker,
  unauthorizedRequestChecker,
} from 'shared/utils/api-sanitization';
import { logger } from 'shared/utils/logger';

const paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'brackets' });

const apiCall = (endpoint, options, invalidateAndNotify, token) => {
  const defaultOptions = {
    method: 'GET',
    headers: { 'Content-Type': contentTypes.JSON, 'Accept-Version': 'v2' },
  };

  const { authRequired = true, omitBaseUrl = false, omitProcessParams = false } = options;

  const authOptions = authRequired ? { headers: { Authorization: `Bearer ${token}` } } : {};

  if (authRequired && !token) {
    invalidateAndNotify();
    return Promise.reject({
      hasToken: false,
      route: endpoint,
      message: 'No token saved!',
      actionType: '',
    });
  }

  let params = merge(defaultOptions, options, authOptions, { paramsSerializer });

  if (!omitProcessParams) {
    params = processParams(params);
  }

  const fullUrl = omitBaseUrl ? `${endpoint}` : `${BASE_URL}${endpoint}`;

  return axios(`${fullUrl}`, params);
};

const omitNonSerializableKeys = (response) =>
  omit(response, [
    'config',
    'config.transformRequest',
    'config.transformResponse',
    'config.paramsSerializer',
    'config.validateStatus',
    'request',
  ]);

export default (store) => (next) => (action) => {
  if (typeof action[CALL_API] === 'undefined') {
    return next(action);
  }

  const {
    endpoint,
    types,
    name,
    omitReducers = false,
    authRequired = true,
    payload,
  } = action[CALL_API];
  const [requestType, successType, errorType] = types;
  const showNotification = bindActionCreators(showNotificationAC, store.dispatch);
  const invalidateAndNotify = bindActionCreators(invalidateAndNotifyAC, store.dispatch);

  if (!omitReducers) store.dispatch({ type: requestType, name });

  const { auth: { token = '' } = {} } = store.getState();

  return apiCall(endpoint, action[CALL_API], invalidateAndNotify, token)
    .then(({ data, headers, status }) => {
      const { attachId, keysToCamelCase } = sanitizeResponse;
      if (omitReducers) {
        return Promise.resolve({
          ...data,
          ...action.additionalData,
          headers,
          type: successType,
          name,
          response: keysToCamelCase(attachId(data)),
          rawResponse: data, // required for downloading binary blobs
          payload,
        });
      }

      next({
        headers,
        type: successType,
        name,
        response: keysToCamelCase(attachId(data)),
        normalizedResponse: safelyNormalize(data),
        status,
        rawResponse: data,
        payload,
        ...action.additionalData,
      });

      return Promise.resolve({
        ...data,
        ...action.additionalData,
        headers,
        type: successType,
        name,
        response: keysToCamelCase(attachId(data)),
        rawResponse: data, // required for downloading binary blobs
        payload,
      });
    })
    .catch((err) => {
      const { response: rawResponse, message, stack } = err;
      const response = omitNonSerializableKeys(rawResponse);
      const isApplicationError = !response && message && stack;
      const { status } = response || {};
      if (isApplicationError) {
        logger.error(err);
      }

      const errorMessage = get(response, 'data.error', message);
      const isLoginAction = getLoginProcessStatus(store.getState());
      const isUnauthorized = unauthorizedRequestChecker(response);
      const requestTimedOut = timedOutRequestChecker(err);

      if (requestTimedOut) {
        showNotification(serviceUnavailable);
      }

      store.dispatch({
        error: true,
        name,
        errorMessage,
        type: errorType,
        response,
        status,
      });

      if (!isLoginAction && isUnauthorized && authRequired) {
        invalidateAndNotify();
      }

      return Promise.reject({
        message: errorMessage,
        route: endpoint,
        actionType: requestType,
        name,
        response,
      });
    });
};
