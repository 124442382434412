import moment from 'moment';
import { actionTypes } from 'redux-form';

import { DATE_FORMAT } from 'constants/datetime';

export default (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHANGE: {
      if (
        action.meta.form.includes('outgoingInvoiceCreator') &&
        action.meta.field === 'paidByCash'
      ) {
        if (action.payload) {
          return {
            ...state,
            values: {
              ...state.values,
              paidByCashDate: moment().format(DATE_FORMAT),
            },
          };
        }

        return {
          ...state,
          values: {
            ...state.values,
            paidByCashDate: null,
          },
        };
      }
      return state;
    }

    default:
      return state;
  }
};
