import {
  CHANGE_PATH,
  REGISTRATION_REQUEST_FAILURE,
  REGISTRATION_REQUEST_REQUEST,
  REGISTRATION_REQUEST_SUCCESS,
  RESET_PERSISTED_CUSTOMER,
  SET_COMPANY,
} from 'constants/registration-request';

const defaultState = {
  requestCreated: false,
  isFetching: false,
  serverError: false,
  token: '',
  persistedCustomer: {},
  company: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case RESET_PERSISTED_CUSTOMER:
      return {
        ...state,
        persistedCustomer: defaultState.persistedCustomer,
      };
    case REGISTRATION_REQUEST_REQUEST:
      return {
        ...state,
        isFetching: true,
        serverError: false,
      };
    case REGISTRATION_REQUEST_SUCCESS:
      return {
        ...state,
        requestCreated: true,
        isFetching: false,
        serverError: false,
        token: action.response.token,
        persistedCustomer: action.payload,
      };
    case REGISTRATION_REQUEST_FAILURE:
      return {
        ...state,
        requestCreated: false,
        isFetching: false,
        serverError: true,
      };
    case CHANGE_PATH:
      return defaultState;
    default:
      return state;
  }
};
