import React from 'react';
import cx from 'classnames';

import { currencyWithSignPrefix } from 'shared/utils';

import styles from './Currency.module.css';

type CurrencyProps = {
  value: number | string;
  shouldDisplayColors?: boolean;
  isNegative?: boolean;
};

const Currency = ({ value, shouldDisplayColors = true, isNegative = false }: CurrencyProps) => {
  const valueStyles = {
    [styles.positive]: shouldDisplayColors && !isNegative,
    [styles.negative]: shouldDisplayColors && isNegative,
  };

  return <span className={cx(valueStyles)}>{currencyWithSignPrefix(value, isNegative)}</span>;
};

export default Currency;
