import React from 'react';

import TabEmailsDefaults from 'containers/Profile/InvoiceTemplate/DefaultValues/TabEmailsDefaults/TabEmailsDefaults';
import { t } from 'shared/utils';

import { Heading, Wrapper } from './EmailsDefaults.styled';

const EmailsDefaults = () => {
  return (
    <Wrapper>
      <Heading>{t('profile.section_headers.emails_defaults')}</Heading>
      <TabEmailsDefaults />
    </Wrapper>
  );
};

export default EmailsDefaults;
