import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouteProps, useHistory, withRouter } from 'react-router-dom';
import { Push, push as pushAction } from 'connected-react-router';
import { get } from 'lodash';
import { compose } from 'redux';

import { setCompany } from 'actions/registration-request';
import {
  requestRegistration as requestRegistrationAction,
  resetPersistedCustomer as resetPersistedCustomerAction,
} from 'actions/registration-request';
import LoginFooter from 'pages/shared/LoginFooter/LoginFooter';
import Page from 'pages/shared/Page/Page';
import paths from 'routes/paths';
import { withFormInitialValues } from 'shared/hoc';
import { t } from 'shared/utils';
import { SubmitButton, TextField } from 'components/Form';

import localStyles from './CompanyInfoRegistration.module.css';

interface RequestRegistrationProps extends RouteProps {
  isFetching: boolean;
  requestRegistration: (data: FormData) => Promise<any>;
  push: Push;
  resetPersistedCustomer: () => void;
}

interface FormData {
  address: string;
  firstName: string;
  lastName: string;
  email: string;
  crefo_id: string;
  commercial_name: string;
  legal_entity_name: string;
  zip_code: string;
  state: string;
}

const RequestRegistration: FunctionComponent<RequestRegistrationProps> = ({
  push,
  resetPersistedCustomer,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedCompanyFromStore = useSelector((state) => state.registrationRequest.company);

  useEffect(() => {
    resetPersistedCustomer();
  }, [resetPersistedCustomer]);

  useEffect(() => {
    if (!selectedCompanyFromStore || Object.keys(selectedCompanyFromStore).length === 0) {
      history.push(paths.companySelectRegistration);
    }
  }, [selectedCompanyFromStore, history]);

  const [selectedCompany, setSelectedCompany] = useState<FormData>({
    firstName: selectedCompanyFromStore.firstName || '',
    lastName: selectedCompanyFromStore.lastName || '',
    email: selectedCompanyFromStore.email || '',
    crefo_id: selectedCompanyFromStore.crefo_id || '',
    commercial_name: selectedCompanyFromStore.commercial_name || '',
    legal_entity_name: selectedCompanyFromStore.legal_entity_name || '',
    address: selectedCompanyFromStore.address || '',
    city: selectedCompanyFromStore.city || '',
    zip_code: selectedCompanyFromStore.zip_code || '',
    state: selectedCompanyFromStore.state || '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [touchedFields, setTouchedFields] = useState<Record<string, boolean>>({});

  const handleManualFieldChange = (event: ChangeEvent<HTMLInputElement>, field: keyof FormData) => {
    const { value } = event.target;
    setSelectedCompany((prev) => ({
      ...prev,
      [field]: value,
    }));
    setTouchedFields((prev) => ({
      ...prev,
      [field]: true,
    }));
  };

  const validateZipCode = () => {
    const isValidZipCode = /^\d+$/.test(selectedCompany.zip_code);
    return (
      (isSubmitted || touchedFields.zip_code) &&
      (!isValidZipCode || selectedCompany.zip_code.trim() === '')
    );
  };

  const canSubmit = () => {
    const requiredFields = ['legal_entity_name', 'address', 'city', 'zip_code'];

    // Check that each required field is not empty
    const areRequiredFieldsValid = requiredFields.every(
      (field) => selectedCompany[field].trim() !== ''
    );

    // Check if zip code is valid
    const isValidZipCode = /^\d+$/.test(selectedCompany.zip_code);

    return areRequiredFieldsValid && isValidZipCode;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (canSubmit()) {
      dispatch(setCompany(selectedCompany));
      push(paths.requestRegistration);
    }
  };

  return (
    <Page header={t('request_registration.header')}>
      <form onSubmit={onSubmit}>
        <div className={localStyles.subtitle}>
          {selectedCompanyFromStore.new_company
            ? t('request_registration.second_subtitle_new_company')
            : t('request_registration.second_subtitle_selected')}
        </div>
        <TextField
          name="legal_entity_name"
          placeholder={t('request_registration.legal_entity_name') || ''}
          value={selectedCompany.legal_entity_name}
          onChange={(e) => handleManualFieldChange(e, 'legal_entity_name')}
          dataId="legal_entity_name"
        />
        <TextField
          name="address"
          placeholder={t('request_registration.company_address') || ''}
          value={selectedCompany.address}
          onChange={(e) => handleManualFieldChange(e, 'address')}
          dataId="address"
        />
        <TextField
          name="zip_code"
          placeholder={t('request_registration.zip_code') || ''}
          value={selectedCompany.zip_code}
          onChange={(e) => handleManualFieldChange(e, 'zip_code')}
          dataId="zip_code"
          invalid={validateZipCode()}
        />

        {validateZipCode() && (
          <div className={localStyles.errorMessage}>
            {t('request_registration.invalid_zip_code')}
          </div>
        )}

        <TextField
          name="city"
          placeholder={t('request_registration.city') || ''}
          value={selectedCompany.city}
          onChange={(e) => handleManualFieldChange(e, 'city')}
          dataId="city"
        />

        <SubmitButton
          value={t('request_registration.submit')}
          dataId="button-submit"
          active={canSubmit()}
        />
      </form>
      <LoginFooter />
    </Page>
  );
};

const mapDispatchToProps = {
  requestRegistration: requestRegistrationAction,
  push: pushAction,
  resetPersistedCustomer: resetPersistedCustomerAction,
};

export default compose<FunctionComponent<RequestRegistrationProps>>(
  withRouter,
  withFormInitialValues(({ router }) => {
    const email = get(router, 'location.query.email', '');
    return { email };
  }),
  connect(null, mapDispatchToProps)
)(RequestRegistration);
