import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { identity } from 'lodash';

import { insertComponent as insertComponentAction } from 'actions/number-ranges';
import isPressedEnter from 'shared/utils/keyboard-events';

import styles from './ComponentWithTooltip.module.css';

export type DataShape = {
  label: string;
  component: string;
  tooltip: string;
};

interface ComponentWithTooltipOwnProps {
  data: DataShape[];
}

interface ComponentWithTooltipDispatchProps {
  insertComponent?: (component: string) => void;
}

type ComponentWithTooltipProps = ComponentWithTooltipOwnProps & ComponentWithTooltipDispatchProps;

const ComponentWithTooltip: FunctionComponent<ComponentWithTooltipProps> = ({
  data,
  insertComponent = identity,
}) => {
  const handleClick = (element: { component: string }, event: React.MouseEvent) => {
    const target = event.target as HTMLInputElement;
    target.blur();
    event.preventDefault();
    insertComponent(element.component);
  };

  return (
    <div className={styles.componentsList}>
      {data.map((element) => (
        <div className={styles.buttonWrapper} key={element.component}>
          <div
            role="button"
            className={styles.component}
            key={element.component}
            onClick={(event) => handleClick(element, event)}
            onKeyPress={isPressedEnter(handleClick)}
            tabIndex={0}
          >
            <span className={styles.componentWrapper}>
              <div className={styles.componentLabel}>{element.label}</div>
              <div className={styles.componentExample}>{element.component}</div>
              <div className={styles.componentTooltip}>{element.tooltip}</div>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
export default connect<{}, ComponentWithTooltipDispatchProps, {}>(null, {
  insertComponent: insertComponentAction,
})(ComponentWithTooltip);
