import React from 'react';
import cx from 'classnames';

import { t } from 'shared/utils';
import IconDownloadWhite from 'components/Icons/IconDownloadWhite';
import Loading from 'components/Loading';

import styles from '../Gallery.module.css';

type DownloadContractProps = {
  onClick?: () => void;
  contractDownloadUrl?: string;
  contractDownloadName: string;
  isDownloadingContract: boolean;
};

const DownloadContract = ({
  onClick,
  contractDownloadUrl,
  contractDownloadName,
  isDownloadingContract,
}: DownloadContractProps) => (
  <a
    className={cx(styles.downloadFile, { [styles.downloadFileLoader]: isDownloadingContract })}
    onClick={isDownloadingContract ? undefined : onClick}
    title={t('expenses.upload.browse_files')}
    data-id="Gallery:button-onDownloadContract"
    download={contractDownloadName}
    href={contractDownloadUrl}
  >
    {isDownloadingContract ? <Loading /> : <IconDownloadWhite />}
  </a>
);

export default DownloadContract;
