import { get, pick } from 'lodash';

import { FetchTaxConsultantData, UpdateTaxConsultantData } from 'actions/tax-consultant';
import { FETCH_SUCCESS, UPDATE_SUCCESS } from 'constants/tax-consultant';
import { TaxConsultantDataFields } from 'types/models/tax-consultant-data';

type TaxConsultantAction = FetchTaxConsultantData | UpdateTaxConsultantData;

const defaultValues = {
  initial: {},
};

export default (state = defaultValues, action: TaxConsultantAction) => {
  switch (action.type) {
    case FETCH_SUCCESS:
    case UPDATE_SUCCESS: {
      const attributes = get(action, 'response.data.attributes', null);

      return {
        ...state,
        initial: pick(attributes, TaxConsultantDataFields),
        values: pick(attributes, TaxConsultantDataFields),
      };
    }
    default:
      return state;
  }
};
