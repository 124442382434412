import React from 'react';
import { CellProps, Column } from 'react-table';

import { t } from 'shared/utils';
import { BankAccount } from 'types/entities/BankAccount';
import { Cashbook } from 'types/entities/Cashbook';
import Amount from 'redesign/components/atoms/Amount/Amount';
import Checkbox from 'redesign/components/atoms/Checkbox/Checkbox';
import TableCell from 'redesign/components/atoms/TableCell/TableCell';
import TableHeader from 'redesign/components/atoms/TableHeader/TableHeader';

import { IconCashbooks } from './List.styled';
import { isBankAccount } from './utils';

type GetColumnsProps = {
  onClick: (item: BankAccount | Cashbook) => () => void;
  selected: Array<BankAccount | Cashbook>;
};

/* eslint-disable react/display-name */
const getColumns = ({ onClick, selected }: GetColumnsProps): Column<BankAccount | Cashbook>[] => {
  return [
    {
      id: 'image',
      Cell: ({ row: { original } }: CellProps<BankAccount | Cashbook>) => {
        return isBankAccount(original) ? (
          <img src={original.bank.icon?.resolutions['48X48']} alt={original.name} />
        ) : (
          <IconCashbooks />
        );
      },
    },
    {
      id: 'name',
      Header: t('features.homepage.bank_accounts_tile.list.name'),
      Cell: ({ row: { original } }: CellProps<BankAccount | Cashbook>) => {
        const name = isBankAccount(original)
          ? original.name
          : t('features.homepage.bank_accounts_tile.list.cashbook');

        return <TableCell title={name}>{name}</TableCell>;
      },
    },
    {
      id: 'total',
      Header: () => (
        <TableHeader align="right">
          {t('features.homepage.bank_accounts_tile.list.total')}
        </TableHeader>
      ),
      Cell: ({ row: { original } }: CellProps<BankAccount | Cashbook>) => {
        const total = isBankAccount(original) ? original.balance : original.currentAmount;

        return (
          <TableCell align="right">
            <Amount amount={total} />
          </TableCell>
        );
      },
    },
    {
      id: 'actions',
      Cell: ({ row: { original } }: CellProps<BankAccount | Cashbook>) => {
        return (
          <Checkbox
            checked={selected.indexOf(original) !== -1}
            onClick={onClick(original)}
            data-id="include-in-summary"
          />
        );
      },
    },
  ];
};
/* eslint-enable react/display-name */

export default getColumns;
