import React from 'react';
import { bool, string } from 'prop-types';

import InfoIcon from 'components/InfoIcon/InfoIcon';

import { TableHeaderCell } from '../TableHeaderCell/TableHeaderCell';

import styles from './TableHeaderCellWithInfo.module.css';

export const TableHeaderCellWithInfo = ({
  title,
  className,
  customWidth = false,
  infoText,
  alignRight = false,
}) => (
  <TableHeaderCell
    title={title}
    className={className}
    customWidth={customWidth}
    alignRight={alignRight}
  >
    {title}{' '}
    <span className={styles.wrap}>
      <InfoIcon text={infoText} />
    </span>
  </TableHeaderCell>
);

TableHeaderCellWithInfo.propTypes = {
  title: string,
  infoText: string,
  className: string,
  customWidth: bool,
  alignRight: bool,
};
