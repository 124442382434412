import React from 'react';
import cx from 'classnames';
import { upperFirst } from 'lodash';
import { bool, func, shape, string } from 'prop-types';

import styles from 'shared/styles/table.module.css';
import If from 'components/Conditions/If';
import InfoIcon from 'components/InfoIcon/InfoIcon';

import InvoiceColumn from '../InvoiceColumn';

const SortingIndicator = ({ active, direction }) => (
  <div className={styles.sortingIndicator}>
    <div
      className={cx(styles.sortingButton, styles.ascending, {
        [styles.isActive]: active && direction === 'ASC',
      })}
    />
    <div
      className={cx(styles.sortingButton, styles.descending, {
        [styles.isActive]: active && direction === 'DESC',
      })}
    />
  </div>
);

SortingIndicator.propTypes = {
  active: bool,
  direction: string,
};

const InvoiceHeader = ({
  handleSort,
  column,
  currentSort = {},
  label,
  sortable,
  info,
  align,
  ...rest
}) => (
  <InvoiceColumn
    {...rest}
    onClick={handleSort(column)}
    className={cx(styles.columnHeader, {
      [styles.sortable]: sortable,
      [styles[`pullHeader${upperFirst(align)}`]]: align,
    })}
  >
    {label}
    <If ok={sortable}>
      <SortingIndicator direction={currentSort.direction} active={currentSort.column === column} />
    </If>
    <If ok={info}>
      <InfoIcon className={styles.infoIcon} text={info} />
    </If>
  </InvoiceColumn>
);

InvoiceHeader.propTypes = {
  column: string,
  sortable: bool,
  handleSort: func,
  currentSort: shape({
    direction: string,
    column: string,
  }),
  info: string,
  align: string,
  label: string.isRequired,
};

export default InvoiceHeader;
