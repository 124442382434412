import React from 'react';

import { t } from 'shared/utils';
import ActiveStatusIcon from 'components/StatusIcons/Contract/ActiveStatusIcon';
import FinishedStatusIcon from 'components/StatusIcons/Contract/FinishedStatusIcon';
import { Status as ContractStatus } from 'features/contracts/types';

import { Status, Text } from './StatusBadge.styled';

type StatusBadgeProps = {
  status: ContractStatus;
  dataId?: string;
};

const getStatusIcon = (status: ContractStatus) => {
  switch (status) {
    case ContractStatus.Active:
      return <ActiveStatusIcon />;
    case ContractStatus.Finished:
      return <FinishedStatusIcon />;
    default:
      return null;
  }
};

const StatusBadge = ({ status, dataId = 'status' }: StatusBadgeProps) => {
  if (!status) return null;

  return (
    <Status status={status}>
      {getStatusIcon(status)}
      <Text data-id={dataId}>{t(`contracts.contract_status.${status}`)}</Text>
    </Status>
  );
};

export default StatusBadge;
