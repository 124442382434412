import { t } from 'shared/utils';

export const noBankAccount = {
  duration: 3000,
  title: t('profile.payment_plan.notifications.no_bank_account'),
  variant: 'warning',
};

export const bookPremiumError = {
  duration: 3000,
  title: t('profile.payment_plan.notifications.book_premium_error'),
  variant: 'warning',
};

export const directDebitSuccess = (date) => ({
  duration: 3000,
  title: t('profile.payment_plan.notifications.direct_debit_success', { date }),
  variant: 'success',
});
