import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 40px auto auto auto;
  justify-content: start;
  align-items: center;
  grid-column-gap: ${({ theme }) => theme.space['2']};
  color: ${({ theme }) => theme.colors.grey['080']};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  outline: 0;
  user-select: none;

  &:disabled,
  &:disabled:hover {
    color: ${({ theme }) => theme.colors.grey['020']};
    cursor: not-allowed;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.vrblue['075']};
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const PreviousButton = styled(Button)`
  svg {
    transform: rotateZ(90deg);
  }
`;

export const NextButton = styled(Button)`
  svg {
    transform: rotateZ(270deg);
  }
`;

export const GotoLastPageButton = styled(Button)`
  &:hover {
    text-decoration: underline;
  }
`;
