import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import WarningIcon from 'images/icon-warning.svg';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import { PasswordField } from 'components/Form';
import Modal from 'components/Modal';

import { checker, minValue8, required } from '../utils/validation';
import {
  Button,
  Buttons,
  Container,
  Headline,
  Inner,
  Modal as StyledModal,
  SubmitPasswordText,
  WarningIcon as StyledWarningIcon,
  WarningText,
  WarningWithIconContainer,
} from './DeleteVrsoContractModal.styled';

export type FormData = {
  password: string;
};

type DeleteVrsoContractModalProps = {
  onClose: () => void;
  isOpen: boolean;
  contractSubject: string;
};

const DeleteVrsoContractModal = ({
  onClose,
  isOpen,
  handleSubmit,
  invalid,
  submitting,
  contractSubject,
}: DeleteVrsoContractModalProps & InjectedFormProps<FormData, DeleteVrsoContractModalProps>) => {
  return (
    <StyledModal as={Modal} isOpen={isOpen} onRequestClose={onClose} contentLabel="Modal">
      <Container>
        <Inner>
          <Headline>
            {t('features.contracts.delete_vrso_contract_modal.headline', { contractSubject })}
          </Headline>
          <WarningWithIconContainer>
            <StyledWarningIcon>
              <img src={WarningIcon} alt="Warning_icon" />
            </StyledWarningIcon>
            <WarningText>{t('features.contracts.delete_vrso_contract_modal.text')}</WarningText>
          </WarningWithIconContainer>
          <SubmitPasswordText>
            {t('features.contracts.delete_vrso_contract_modal.confirm_password')}
          </SubmitPasswordText>
          <form onSubmit={handleSubmit}>
            <Field
              name="password"
              component={PasswordField}
              dataId="DeleteVrsoContractModal:input-password"
              type="password"
              validate={[required, minValue8]}
              checker={checker}
            />
            <Buttons>
              <ActionButton
                appearance="outlined"
                type="button"
                dataId="button-cancel"
                label={t('features.contracts.delete_vrso_contract_modal.cancel_button')}
                onClick={onClose}
              />
              <Button>
                <ActionButton
                  type="submit"
                  dataId="button-continue"
                  label={t('features.contracts.delete_vrso_contract_modal.delete_button')}
                  disabled={invalid || submitting}
                />
              </Button>
            </Buttons>
          </form>
        </Inner>
      </Container>
    </StyledModal>
  );
};

export default reduxForm<FormData, DeleteVrsoContractModalProps>({
  form: 'deleteVrsoContractModal',
})(DeleteVrsoContractModal);
