import React, { useState } from 'react';

import { showNotification } from 'actions/notification';
import { setMainBankAccount } from 'api/me/bankAccounts';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { BankAccount } from 'types/entities/BankAccount';
import Button from 'redesign/components/atoms/Button/Button';
import CircleIcon from 'redesign/components/atoms/CircleIcon/CircleIcon';
import IconWarning from 'redesign/components/atoms/Icons/Warning';
import ModalActions from 'redesign/components/atoms/ModalActions/ModalActions';
import Modal from 'redesign/components/molecules/Modal/Modal';

import { Content, Headline, IconWrapper, Message, ModalBody } from './Modals.styled';
import { selectBankAccount } from './notifications';

type ModalProps = {
  bankAccount: BankAccount;
  onClose: () => void;
  onReload: () => void;
};

const DeleteModal = ({ bankAccount, onClose, onReload }: ModalProps) => {
  const dispatch = useAppDispatch();
  const [isMainBankAccountFetching, setIsMainBankAccountFetching] = useState(false);

  const handleMainAccountSelection = async () => {
    setIsMainBankAccountFetching(true);
    await setMainBankAccount(bankAccount.id);
    onReload();
    onClose();
    dispatch(showNotification(selectBankAccount));
  };

  return (
    <Modal isOpen onRequestClose={onClose}>
      <ModalBody>
        <IconWrapper>
          <CircleIcon icon={IconWarning} color="red" />
        </IconWrapper>

        <Content>
          <Headline>{t('redesign.organisms.bank_accounts_list.select_account.header')}</Headline>
          <Message>{t('redesign.organisms.bank_accounts_list.select_account.label')}</Message>
          <Message bold>{bankAccount.name}</Message>
        </Content>
      </ModalBody>
      <ModalActions>
        <Button onClick={handleMainAccountSelection} disabled={isMainBankAccountFetching}>
          {t('redesign.organisms.bank_accounts_list.select_account.select')}
        </Button>
        <Button $variant="outlined" onClick={onClose}>
          {t('redesign.organisms.bank_accounts_list.select_account.cancel')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeleteModal;
