import styled from 'styled-components';

import BaseLabel from 'redesign/components/atoms/Label/Label';

export const Label = styled(BaseLabel)`
  display: inline-flex;
  align-items: center;

  > *:first-child {
    margin-right: ${({ theme }) => theme.space['2']};
  }
`;
