export type FarmpilotOrigins = 'farmpilot';

export const FARMPILOT_ORIGIN = 'farmpilot';

export const FARMPILOT_HANDBOOK_LINK =
  'https://www.vr-smart-guide.de/faq?deep_link=/display/ST/farmpilot';
export const FARMPILOT_LOGIN_PATH = 'https://portal.farmpilot.de/FarmpilotGUI/?l=en_US#/login';
export const FARMPILOT_LANDING_PAGE = 'https://vr-smart-guide.de/landwirtschaft';
export const FARMPILOT_PARTNER_SERVICE =
  'https://portal.farmpilot.de/FarmpilotGUI/#/partnerservice/list ';
