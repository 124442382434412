import React from 'react';

import Hint from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoicePaymentSection/PaymentCreator/components/Hint/Hint.jsx';
import ParallelContainer from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoicePaymentSection/PaymentCreator/components/ParallelContainer/ParallelContainer.jsx';
import ResizableContainer, {
  ResizeBase,
} from 'containers/IncomingInvoices/IncomingInvoiceCreator/InvoicePaymentSection/PaymentCreator/components/ResizableContainer/ResizableContainer.jsx';
import { useIsMdUp } from 'shared/hooks/useMedia';
import { t } from 'shared/utils';
import { TextField } from 'components/Form';

import { ImageSettings } from '../constants';
import TanBeamerV2 from './TanBeamerV2';

import styles from './Hhd.module.css';

const INITIAL_WIDGET_WIDTH = 205;
const INITIAL_WIDGET_HEIGTH = 100;

type HhdProps = {
  challenge: {
    format: 'HHD';
    type: 'EMBEDDED';
    created_at: string;
    label: string;
    data: string;
    id: string;
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  selectedTanSchemeName: string;
};

const Hhd = ({ challenge, onChange, value, selectedTanSchemeName }: HhdProps) => {
  const isMdUp = useIsMdUp();
  const { data, label } = challenge;

  const version = selectedTanSchemeName.includes('1.3') ? '1.3' : '1.4';

  return (
    <>
      <Hint text={label} />
      <ParallelContainer className={styles.container}>
        <ResizableContainer
          initialWidth={INITIAL_WIDGET_WIDTH}
          initialHeight={INITIAL_WIDGET_HEIGTH}
          minWidth={ImageSettings.MIN_WIDTH}
          minHeight={ImageSettings.MIN_HEIGHT}
          maxWidth={isMdUp ? ImageSettings.MAX_WIDTH : ImageSettings.MAX_WIDTH_MOBILE}
          maxHeight={ImageSettings.MAX_HEIGHT}
          step={ImageSettings.STEP_SIZE}
          resizeBase={ResizeBase.WIDTH}
          keepAspectRatio
        >
          {({ width }: { width: number; height: number }) => {
            return <TanBeamerV2 data={data} version={version} width={width} />;
          }}
        </ResizableContainer>
        <TextField
          name="tan"
          placeholder={t('figo_connection.challenges.embedded.tan')}
          value={value}
          onChange={onChange}
          dataId="FigoAuth:input-tan"
          required
          isCentered
        />
      </ParallelContainer>
    </>
  );
};

export default Hhd;
