import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import noop from 'lodash/noop';
import { func, node, string } from 'prop-types';

import styles from './LineItemContainer.module.css';

class LineItemContainerComponent extends Component {
  handleClickOutside = (event) => {
    const { outsideClickHandler = noop } = this.props;
    outsideClickHandler(event);
  };

  render() {
    const { outsideClickHandler, onClick, children, ...rest } = this.props;
    return (
      <div role="presentation" onClick={onClick} className={styles.lineItemGrid} {...rest}>
        {children}
      </div>
    );
  }
}

LineItemContainerComponent.propTypes = {
  className: string,
  children: node,
  outsideClickHandler: func,
  onClick: func,
};

export const LineItemContainer = onClickOutside(LineItemContainerComponent);
