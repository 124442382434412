import React, {
  MouseEvent as ReactMouseEvent,
  Ref,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Manager, Popper, Reference } from 'react-popper-legacy';
import cx from 'classnames';

import { t } from 'shared/utils';
import type { VrsoInstallment } from 'types/entities/Contract';

import { trackEvent } from '../../utils';
import Tooltip from './Tooltip';
import { calculateProgress } from './utils';

import styles from './Slider.module.css';

type SliderProps = {
  installments: VrsoInstallment[];
  currentDate: Date;
  onIdxChange: (idx: number | null) => void;
  initialIdx: number;
  idxToPay: number;
};

const Slider = ({ installments, currentDate, onIdxChange, initialIdx, idxToPay }: SliderProps) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [activeIdx, setActiveIdx] = useState<number | null>(initialIdx);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [renderTooltip, setRenderTooltip] = useState<boolean>(false);
  const indicatorInstallment = activeIdx !== null ? installments[activeIdx] : null;
  const bg = activeIdx ? (activeIdx / (installments.length - 1)) * 100 : 0;
  const progress = calculateProgress({ installments, currentDate, idxToPay });
  const handler = sliderRef.current?.querySelector(`.${styles.isActive}`);

  const getNearestInstallmentIdx = (event: ReactMouseEvent<HTMLDivElement>) => {
    if (!sliderRef.current) return;

    const mouseX = event.clientX;
    const dotsX = Array.from(sliderRef.current.querySelectorAll(`.${styles.dot}`)).map(
      (dot) => dot.getBoundingClientRect().left
    );

    const idx = dotsX.reduce(
      (prevIdx, currX, currIdx) =>
        Math.abs(currX - mouseX) < Math.abs(dotsX[prevIdx] - mouseX) ? currIdx : prevIdx,
      0
    );

    return idx;
  };

  useEffect(() => {
    onIdxChange(activeIdx);
  }, [onIdxChange, activeIdx]);

  useEffect(() => {
    if (!!sliderRef.current) {
      setRenderTooltip(true);
    }
  }, [sliderRef]);

  useLayoutEffect(() => {
    if (!handler) return;

    const listener = () => {
      trackEvent('Progress_Bar_Handle_Click');
    };
    handler.addEventListener('click', listener);

    return () => {
      handler.removeEventListener('click', listener);
    };
  }, [activeIdx, handler]);

  useLayoutEffect(() => {
    if (!isDragging || !sliderRef.current) return;

    const listener = (event: MouseEvent) => {
      const idx = getNearestInstallmentIdx(event as unknown as ReactMouseEvent<HTMLDivElement>);
      if (idx !== undefined) setActiveIdx(idx);
    };
    const slider = sliderRef.current;
    slider.addEventListener('mousemove', listener);

    return () => {
      slider.removeEventListener('mousemove', listener);
    };
  }, [isDragging]);

  return (
    <div
      ref={sliderRef}
      className={styles.slider}
      onClick={(event) => {
        if (event.target !== handler) {
          trackEvent('Progress_Bar_Body');
        }

        const idx = getNearestInstallmentIdx(event);
        if (idx !== undefined) setActiveIdx(idx);
      }}
      onMouseDown={() => {
        trackEvent('Progress_Bar_Handle_Drag');
        setIsDragging(true);
      }}
      onMouseUp={() => {
        setIsDragging(false);
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.bg} style={{ width: `${bg}%` }} />
        <div className={styles.bgLeft} style={{ width: `${bg}%` }} />
        <div
          className={cx(styles.progress, {
            [styles.isFilled]: progress === 100,
            [styles.isEmpty]: progress === 0,
          })}
          style={{ width: `${progress}%` }}
        >
          <span>{t('contracts.new.progress_bar.today')}</span>
        </div>
        <div className={cx(styles.progressLeft, { [styles.isEmpty]: progress === 0 })} />
        {progress === 100 && <div className={styles.progressRight} />}
        {bg === 100 && <div className={styles.bgRight} />}
        <Manager>
          <Reference>
            {({ ref }) => (
              <div className={styles.dots}>
                {installments.map((_, index: number) => {
                  const percentage = (index / (installments.length - 1)) * 100;
                  const isWhite =
                    !(progress === 0 && bg === 0) && (percentage <= progress || percentage <= bg);
                  const isActive = index === activeIdx;

                  return (
                    <button
                      ref={isActive ? (ref as Ref<HTMLButtonElement>) : undefined}
                      type="button"
                      className={cx(styles.dot, {
                        [styles.isWhite]: isWhite,
                        [styles.isActive]: isActive,
                        [styles.isNormal]: !isActive,
                        [styles.isDragging]: isDragging,
                      })}
                      onClick={(event) => {
                        (event.target as HTMLButtonElement).focus();
                      }}
                      onFocus={() => {
                        setActiveIdx(index);
                      }}
                      key={index}
                    />
                  );
                })}
              </div>
            )}
          </Reference>
          {renderTooltip && (
            <Popper
              placement="top"
              modifiers={{
                preventOverflow: {
                  padding: 0,
                  priority: ['left', 'right'],
                  boundariesElement: sliderRef.current!,
                },
                offset: { offset: '0, 7' },
                flip: { enabled: false },
              }}
            >
              {({ ref, style }) =>
                activeIdx !== null &&
                indicatorInstallment !== null && (
                  <Tooltip
                    ref={ref}
                    style={style}
                    indicatorInstallment={indicatorInstallment}
                    indicatorIndex={activeIdx}
                  />
                )
              }
            </Popper>
          )}
        </Manager>
      </div>
    </div>
  );
};

export default Slider;
