import React, { useEffect, useRef, useState } from 'react';

import {
  HbciTanBeamer,
  HbciTanBeamerBarBlack,
  HbciTanBeamerBarPadding,
  HbciTanBeamerBars,
  HbciTanBeamerBarWhite,
  HbciTanBeamerContent,
} from './TanBeamerV2.styled';
import { _parseHHDUC } from './utils';

type TanBeamerV2Props = {
  data: string;
  version: string;
  width: number;
};

const TanBeamerV2 = ({ data, version, width }: TanBeamerV2Props) => {
  let _step: number = 0;
  let _tb: string;
  let _timer = useRef<number | null>();

  const [CLK, setfCLK] = useState<boolean>();
  const [DATA0, setfDATA0] = useState<boolean>();
  const [DATA1, setfDATA1] = useState<boolean>();
  const [DATA2, setfDATA2] = useState<boolean>();
  const [DATA3, setfDATA3] = useState<boolean>();

  const initializeTanBeamer = (sChallengeHHDUC: string, sVersionHHDUC: string | boolean) => {
    _tb = String.fromCharCode(0x00, 0xff) + _parseHHDUC(sChallengeHHDUC, sVersionHHDUC === '1.4');
  };

  const onUpdateDisplay = (
    fCLK: boolean,
    fDATA0: boolean,
    fDATA1: boolean,
    fDATA2: boolean,
    fDATA3: boolean
  ) => {
    setfCLK(fCLK);
    setfDATA0(fDATA0);
    setfDATA1(fDATA1);
    setfDATA2(fDATA2);
    setfDATA3(fDATA3);
  };

  const step = () => {
    var nStep = _step;
    let n = _tb.charCodeAt(Math.floor(_step / 4));

    if (nStep & 0x0002) {
      n >>= 4;
    }
    onUpdateDisplay(
      !(nStep & 0x0001),
      Boolean(n & 0x01),
      Boolean(n & 0x02),
      Boolean(n & 0x04),
      Boolean(n & 0x08)
    );

    ++nStep;

    if (nStep / 4 >= _tb.length) {
      nStep = 2;
    }

    _step = nStep;
  };

  const handleStopTanBeamer = () => {
    if (_timer.current) {
      window.clearInterval(_timer.current);
      _timer.current = null;
    }

    onUpdateDisplay(false, false, false, false, false);
  };

  const handleStartTanBeamer = () => {
    handleStopTanBeamer();
    initializeTanBeamer(data, version);

    _timer.current = window.setInterval(() => {
      step();
    }, 100);
  };

  useEffect(() => {
    handleStartTanBeamer();

    return () => handleStopTanBeamer();
  }, []);

  return (
    <HbciTanBeamer width={width}>
      <HbciTanBeamerContent>
        <HbciTanBeamerBars>
          <tr>
            <HbciTanBeamerBarPadding />
            {CLK ? <HbciTanBeamerBarWhite /> : <HbciTanBeamerBarBlack />}
            <HbciTanBeamerBarPadding />
            <HbciTanBeamerBarPadding />
            {DATA0 ? <HbciTanBeamerBarWhite /> : <HbciTanBeamerBarBlack />}
            <HbciTanBeamerBarPadding />
            <HbciTanBeamerBarPadding />
            {DATA1 ? <HbciTanBeamerBarWhite /> : <HbciTanBeamerBarBlack />}
            <HbciTanBeamerBarPadding />
            <HbciTanBeamerBarPadding />
            {DATA2 ? <HbciTanBeamerBarWhite /> : <HbciTanBeamerBarBlack />}
            <HbciTanBeamerBarPadding />
            <HbciTanBeamerBarPadding />
            {DATA3 ? <HbciTanBeamerBarWhite /> : <HbciTanBeamerBarBlack />}
            <HbciTanBeamerBarPadding />
          </tr>
        </HbciTanBeamerBars>
      </HbciTanBeamerContent>
    </HbciTanBeamer>
  );
};

export default TanBeamerV2;
