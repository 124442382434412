import {
  CLICK_CANCEL,
  CLICK_CREATE,
  CLICK_EDIT,
  CREATE_FAILURE,
  CREATE_SUCCESS,
  PRODUCT_CATALOG_GROUPS,
} from 'constants/product-catalog/groups';

export const clickCreateProductCatalogGroup = () => (dispatch) =>
  dispatch({
    type: CLICK_CREATE,
    name: PRODUCT_CATALOG_GROUPS,
  });

export const clickEditProductCatalogGroup = () => (dispatch) =>
  dispatch({
    type: CLICK_EDIT,
    name: PRODUCT_CATALOG_GROUPS,
  });

export const clickCancelProductCatalogGroup = () => (dispatch) =>
  dispatch({
    type: CLICK_CANCEL,
    name: PRODUCT_CATALOG_GROUPS,
  });

export const submitNewProductCatalogGroupSuccess = () => (dispatch) =>
  dispatch({
    type: CREATE_SUCCESS,
    name: PRODUCT_CATALOG_GROUPS,
  });

export const submitNewProductCatalogGroupFailure = () => (dispatch) =>
  dispatch({
    type: CREATE_FAILURE,
    name: PRODUCT_CATALOG_GROUPS,
  });
