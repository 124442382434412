import { isString, values } from 'lodash';

import { UIDS } from 'constants/external-apps';

const getFarmpilotAppSettings = (externalApps) =>
  values(externalApps).find((externalApp) => externalApp.attributes.uid === UIDS.FARMPILOT);

export const getFarmpilotAppId = (state) => {
  const { externalApps = {} } = state.appSettings;

  const settings = getFarmpilotAppSettings(externalApps);
  if (!settings) return null;

  return settings.id;
};

export const isFarmpilotIntegrationActive = (state) => {
  const { externalApps = {} } = state.appSettings;

  const settings = getFarmpilotAppSettings(externalApps);
  if (!settings) return false;

  return settings.attributes.active;
};

export const isFarmpilotAnExternalApp = (state) => {
  const appId = getFarmpilotAppId(state);

  return isString(appId);
};
