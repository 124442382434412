import React, { memo, ReactNode } from 'react';

import styles from './Table.module.css';

type HeaderRowProps = {
  children: ReactNode;
};

const HeaderRow = ({ children }: HeaderRowProps) => (
  <div className={styles.headerRow}>{children}</div>
);

export default memo(HeaderRow);
