import styled from 'styled-components';

import EyeClosed from 'redesign/components/atoms/Icons/EyeClosed';
import EyeOpen from 'redesign/components/atoms/Icons/EyeOpen';

export const PasswordOpenIcon = styled(EyeOpen)`
  width: ${({ theme }) => theme.space['5']};
  height: ${({ theme }) => theme.space['5']};
`;

export const PasswordClosedIcon = styled(EyeClosed)`
  width: ${({ theme }) => theme.space['5']};
  height: ${({ theme }) => theme.space['5']};
`;
