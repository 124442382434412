import React from 'react';
import InlineSvg from 'react-inlinesvg';
import { string } from 'prop-types';

import existIcon from 'images/exist.svg';

import styles from '../List.module.css';

const ListItem = ({ title }) => (
  <span className={styles.listItem}>
    <InlineSvg src={existIcon} /> {title}
  </span>
);

ListItem.propTypes = {
  title: string.isRequired,
};

export default ListItem;
