import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import noop from 'lodash/noop';
import snakeCase from 'lodash/snakeCase';
import { bool, func, string } from 'prop-types';
import { Field } from 'redux-form';

import { deliveryNoteSelector } from 'reducers/form';
import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import Card from 'components/Card';
import { DateField, TextField } from 'components/Form';
import I18n from 'components/I18n';

import Client from './Client/Client';
import DeviatingDeliveryAddress from './DeviatingDeliveryAddress/DeviatingDeliveryAddress';

import styles from './CreateDeliveryNoteSection.module.css';

const FormField = ({ name, readonly, message, ...rest }) => (
  <div className={styles.formGroup}>
    <Field
      id={name}
      name={name}
      checker={checker}
      disabled={readonly}
      label={t(`features.delivery_note.form.${snakeCase(name)}`)}
      placeholder={t(`features.delivery_note.form.${snakeCase(name)}`)}
      {...rest}
      message={message && t(`features.delivery_note.form.${snakeCase(message)}`)}
    />
  </div>
);

FormField.propTypes = {
  name: string.isRequired,
  readonly: bool,
};

const CreateDeliveryNoteSection = ({
  readonly = false,
  onClientSelect = noop,
  client,
  handleOpenModalState,
  handleChange,
}) => (
  <>
    <Card className={styles.main}>
      <Card.Header className={styles.header}>
        <I18n t="features.delivery_note.form.header_data" />
      </Card.Header>
      <Card.Body>
        <div className={cx(styles.columns, styles.columnsPadded)}>
          <div className={styles.column}>
            <FormField
              name="subject"
              component={TextField}
              readonly={readonly}
              dataId="DeliveryNotePage:input-subject"
            />
            <Client
              selectDataIds={{
                container: 'DeliveryNotePage:select-client',
                input: 'DeliveryNotePage:input-client',
                menuOptionPrefix: 'DeliveryNotePage:option-client',
              }}
              onChange={onClientSelect}
              readonly={readonly}
            />
            {client && (
              <DeviatingDeliveryAddress
                handleOpenModalState={handleOpenModalState}
                client={client}
                readonly={readonly}
                handleChange={handleChange}
              />
            )}
          </div>
          <div className={styles.column}>
            <FormField
              name="deliveryNoteNumber"
              component={TextField}
              readonly
              dataId="DeliveryNotePage:input-number"
              disabledPlaceHolder={t('revenue.form.number_disabled')}
            />
            <div className={cx(styles.columns)}>
              <div className={styles.column}>
                <FormField
                  name="deliveryNoteDate"
                  dataIds={{ input: 'DeliveryNotePage:container-date' }}
                  component={DateField}
                  readonly={readonly}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  </>
);

CreateDeliveryNoteSection.propTypes = {
  readonly: bool,
  onClientSelect: func,
  triggerModalState: func,
};

const mapStateToProps = (state) => ({
  client: deliveryNoteSelector(state, 'client'),
});

export default connect(mapStateToProps, null)(CreateDeliveryNoteSection);
