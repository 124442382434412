import React, { memo } from 'react';
import cx from 'classnames';

import { formatMoney, t } from 'shared/utils';

import styles from './Total.module.css';

type TotalProps = {
  balance: number;
  monthlyExpenses: number;
  monthlyRevenues: number;
};

const Total = ({ balance, monthlyExpenses, monthlyRevenues }: TotalProps) => (
  <>
    <div className={styles.row}>
      <div className={styles.label}>
        {t('features.financial_planning.liquidity_calculator.summary.total.balance')}
      </div>
      <div
        className={cx(styles.value, styles.valuePositive)}
        data-id="LiquidityCalculatorSummary:value-balance"
      >
        {formatMoney(balance)}
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.label}>
        {t('features.financial_planning.liquidity_calculator.summary.total.monthly_expenses')}
      </div>
      <div
        className={cx(styles.value, styles.valueNegative)}
        data-id="LiquidityCalculatorSummary:expenses-amount"
      >
        - {formatMoney(monthlyExpenses)}
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.label}>
        {t('features.financial_planning.liquidity_calculator.summary.total.monthly_revenues')}
      </div>
      <div
        className={cx(styles.value, styles.valuePositive)}
        data-id="LiquidityCalculatorSummary:revenues-amount"
      >
        + {formatMoney(monthlyRevenues)}
      </div>
    </div>
  </>
);

export default memo(Total);
