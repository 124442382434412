import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.space['10']} 0;

  &:first-child {
    padding-top: 0;
  }
`;

export const Heading = styled.h3`
  font-size: ${({ theme }) => theme.fontSize['xl']};
  line-height: ${({ theme }) => theme.space['6']};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey['080']};
`;
