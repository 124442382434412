import { JsonApiResponse } from 'json-api-normalizer';

import api from 'api';

type GetInstallmentsData = {
  page?: number;
  per_page?: number;
  filters?: {
    transaction_date_lte?: string;
  };
};

export const getInstallments = (id: string, data?: GetInstallmentsData) => {
  const url = `/me/contracts/${id}/installments`;

  return api.get<JsonApiResponse>(url, {
    params: data,
  });
};
