import React, { ReactNode, useState } from 'react';
import { Field, WrappedFieldProps } from 'redux-form';

import { t } from 'shared/utils';
import IconCheck from 'components/Icons/IconCheck';
import IconCross from 'components/Icons/IconCross';

import checkers from './checkers';

import styles from './PasswordValidityPopover.module.css';

type PasswordValidityPopoverOwnProps = {
  minimumLength?: number;
  children: (args: { showPopover: () => void; hidePopover: () => void }) => ReactNode;
};

type PasswordValidityPopoverProps = PasswordValidityPopoverOwnProps & WrappedFieldProps;

const PasswordValidityPopover = ({
  minimumLength = 8,
  children,
  ...props
}: PasswordValidityPopoverProps) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const validations = [
    {
      isValid: !checkers.length(props),
      label: t('registration.password_errors.passwords_not_long_enough', {
        minimumLength,
      }),
    },
    {
      isValid: !checkers.bothCases(props),
      label: t('registration.password_errors.not_both_cases'),
    },
    {
      isValid: !checkers.specials(props),
      label: t('registration.password_errors.not_special_characters'),
    },
  ];

  return (
    <div className={styles.wrapper}>
      {children({
        showPopover: () => setIsPopoverVisible(true),
        hidePopover: () => setIsPopoverVisible(false),
      })}
      {isPopoverVisible && (
        <div className={styles.popoverWrapper}>
          <div className={styles.triangle} />
          {validations.map(({ isValid, label }) => (
            <div key={label} className={styles.row}>
              {isValid ? (
                <IconCheck className={styles.icon} />
              ) : (
                <IconCross className={styles.icon} />
              )}
              <div>{label}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const PasswordValidityPopoverField = (props: PasswordValidityPopoverOwnProps) => (
  <Field component={PasswordValidityPopover} name="password" {...props} />
);

export default PasswordValidityPopoverField;
