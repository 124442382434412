import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${({ theme }) => theme.space['5']};
  height: ${({ theme }) => theme.space['5']};
  min-width: ${({ theme }) => theme.space['5']};
  position: relative;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span svg {
    display: block;
  }

  &:checked ~ span {
    background: ${({ theme }) => theme.colors.vrblue['100']};
  }

  &:checked ~ span:hover {
    background: ${({ theme }) => theme.colors.vrblue['075']};
    border-color: ${({ theme }) => theme.colors.vrblue['075']};
  }

  &:checked ~ span:active {
    background: ${({ theme }) => theme.colors.vrblue['050']};
    border-color: ${({ theme }) => theme.colors.vrblue['050']};
  }

  &:disabled ~ span {
    border-color: ${({ theme }) => theme.colors.grey['070']};
    cursor: not-allowed;
    box-shadow: none;
  }

  &:disabled ~ span:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:checked:disabled ~ span {
    background-color: ${({ theme }) => theme.colors.grey['040']};
    border-color: ${({ theme }) => theme.colors.grey['040']};
  }

  &:checked:disabled ~ span:hover {
    background-color: ${({ theme }) => theme.colors.grey['040']};
  }
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 2px solid ${({ theme }) => theme.colors.vrblue['100']};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 1px 1px 2px 0 rgba(0, 102, 179, 0.3);
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.vrblue['025']};
    box-shadow: 3px 3px 5px 0 rgba(0, 102, 179, 0.3);
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.vrblue['100']};
  }

  svg {
    display: none;
    color: ${({ theme }) => theme.colors.white};
  }
`;
