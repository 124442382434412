import React, { useEffect, useRef, useState } from 'react';

import { checkIsTruncated, formatMoney, t } from 'shared/utils';
import { ResourceType } from 'types/entities/AssignableResource';
import { BankTransferItem } from 'types/entities/BankTransferItem';
import { Client } from 'types/entities/Client';
import { IncomingInvoice, OutgoingInvoice } from 'types/entities/Invoice';
import { Supplier } from 'types/entities/Supplier';
import IconSale from 'components/Icons/IconSale';

import {
  Amount,
  Box,
  ContractSubject,
  ContractTooltip,
  Date,
  Icon,
  Party,
  Type,
} from './BankTransferDetails.styled';
import InstallmentAssignment from './InstallmentAssignment';
import InvoiceAssignment from './InvoiceAssignment';

type BankTransferDetailsProps = {
  bankTransfer: BankTransferItem;
  resource: {
    transactionDate: string;
    grossAmount: number;
    grossAmountToBePaid: number;
    contractSubject: string;
    isRevenue: boolean;
    contractId: number;
    client?: Client;
    supplier?: Supplier;
  } & ({
    persistedSupplier: IncomingInvoice;
    persistedClient: Pick<OutgoingInvoice, 'persistedClient'>;
  } & IncomingInvoice &
    OutgoingInvoice);
  resourceType: string;
};

const BankTransferDetails = ({
  bankTransfer: { amount, creditorName, date, purpose },
  resource,
  resourceType,
}: BankTransferDetailsProps) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const isContract = resourceType === ResourceType.ContractInstallment;
  const contractNumberRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const contractNumber = contractNumberRef.current;
    if (contractNumber === null) return;

    setIsTruncated(checkIsTruncated(contractNumber));
  }, [contractNumberRef.current]);

  return (
    <>
      <Box>
        <Icon>
          <IconSale width={34} />
          <p>{t('bank_transfers.assigment.modal.details.sales')}</p>
        </Icon>
        <Party>{creditorName}</Party>
        <ContractTooltip offset={0.1} message={`${purpose || '-'}`} isTooltipHidden={!isTruncated}>
          <ContractSubject ref={contractNumberRef}>{purpose || '-'}</ContractSubject>
        </ContractTooltip>
        <Date>{date}</Date>
        <Type>{t('bank_transfers.assigment.modal.details.sales_amount')}</Type>
        <Amount isNegative={amount < 0}>{formatMoney(amount)}</Amount>
      </Box>

      {isContract ? (
        <InstallmentAssignment {...resource} />
      ) : (
        <InvoiceAssignment resource={resource} resourceType={resourceType} />
      )}
    </>
  );
};

export default BankTransferDetails;
