import head from 'lodash/head';

import { CALL_API } from 'constants/api';
import {
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DOWNLOAD_CONTRACT_FAILURE,
  DOWNLOAD_CONTRACT_REQUEST,
  DOWNLOAD_CONTRACT_SUCCESS,
  DOWNLOAD_PREVIEW_FAILURE,
  DOWNLOAD_PREVIEW_REQUEST,
  DOWNLOAD_PREVIEW_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  UPLOAD_FAILURE,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS,
} from 'constants/contracts/uploaded-files';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { piwikHelpers } from 'shared/utils/piwik';
import { bindServerValidation } from 'shared/utils/server-validation';
import { ApiResponseRaw, CallApiAction, Dispatch } from 'types/actions';
import { CATEGORY_CONTRACTS } from 'features/contracts/constants';

export type FetchAction = ApiResponseRaw<
  typeof FETCH_REQUEST | typeof FETCH_SUCCESS | typeof FETCH_FAILURE
>;
export type UploadAction = ApiResponseRaw<
  typeof UPLOAD_REQUEST | typeof UPLOAD_SUCCESS | typeof UPLOAD_FAILURE
>;
export type DeleteAction = ApiResponseRaw<
  typeof DELETE_REQUEST | typeof DELETE_SUCCESS | typeof DELETE_FAILURE
>;
export type DownloadContractAction = ApiResponseRaw<
  | typeof DOWNLOAD_CONTRACT_REQUEST
  | typeof DOWNLOAD_CONTRACT_SUCCESS
  | typeof DOWNLOAD_CONTRACT_FAILURE
>;
export type DownloadPreviewAction = ApiResponseRaw<
  | typeof DOWNLOAD_PREVIEW_REQUEST
  | typeof DOWNLOAD_PREVIEW_SUCCESS
  | typeof DOWNLOAD_PREVIEW_FAILURE
>;

export const apiFetchContractFiles = (contractId: string): CallApiAction<FetchAction> => ({
  [CALL_API]: {
    method: 'GET',
    endpoint: `/me/contracts/${contractId}/contract_files`,
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiUploadContractFile = (
  contractId: string,
  data: { file?: File }
): CallApiAction<UploadAction> => ({
  [CALL_API]: {
    data,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    endpoint: `/me/contracts/${contractId}/contract_files`,
    types: [UPLOAD_REQUEST, UPLOAD_SUCCESS, UPLOAD_FAILURE],
  },
});

export const apiDownloadContract = (contractId: string): CallApiAction<DownloadContractAction> => ({
  [CALL_API]: {
    method: 'GET',
    endpoint: `/me/contracts/${contractId}/contract_files/combined_file`,
    types: [DOWNLOAD_CONTRACT_REQUEST, DOWNLOAD_CONTRACT_SUCCESS, DOWNLOAD_CONTRACT_FAILURE],
    // @ts-ignore
    responseType: 'arraybuffer',
  },
});

export const apiDeleteContractFile = (
  contractId: string,
  id: string
): CallApiAction<DeleteAction> => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: `/me/contracts/${contractId}/contract_files/${id}`,
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  },
});

export const apiDownloadFilePreview = (
  contractId: string,
  id: string
): CallApiAction<DownloadPreviewAction> => ({
  [CALL_API]: {
    endpoint: `/me/contracts/${contractId}/contract_files/${id}`,
    types: [DOWNLOAD_PREVIEW_REQUEST, DOWNLOAD_PREVIEW_SUCCESS, DOWNLOAD_PREVIEW_FAILURE],
    // @ts-ignore
    responseType: 'arraybuffer',
  },
});

export const fetchContractFiles = (contractId: string) => (dispatch: Dispatch) =>
  dispatch(apiFetchContractFiles(contractId)).catch(apiErrorHandler);

export const uploadContractFile = (contractId: string, files: File[]) => (dispatch: Dispatch) =>
  bindServerValidation(apiUploadContractFile(contractId, { file: head(files) }), dispatch, {
    isReduxForm: false,
  })
    .then(() => piwikHelpers.trackEvent(CATEGORY_CONTRACTS, 'Contract_success'))
    .catch(apiErrorHandler);

export const downloadContract = (contractId: string) => (dispatch: Dispatch) =>
  dispatch(apiDownloadContract(contractId)).catch(apiErrorHandler);

export const deleteContractFile = (contractId: string, id: string) => (dispatch: Dispatch) =>
  dispatch(apiDeleteContractFile(contractId, id)).catch(apiErrorHandler);

export const downloadContractFile = (contractId: string, id: string) => (dispatch: Dispatch) =>
  dispatch(apiDownloadFilePreview(contractId, id)).catch(apiErrorHandler);
