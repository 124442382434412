import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { bool } from 'prop-types';

import paths from 'routes/paths';
import { t } from 'shared/utils';

import paymentsStyles from '../../InvoicePaymentSection.module.css';
import styles from './NoBankAccountsPlaceholder.module.css';

const NoBankAccountsPlaceholder = ({ hasMargin = false }) => (
  <div className={cx({ [styles.containerWithMargin]: hasMargin })}>
    <p className={paymentsStyles.placeholderText}>{t('expenses.payments.no_accounts_1')}</p>
    <p className={paymentsStyles.placeholderText}>{t('expenses.payments.no_accounts_2')}</p>
    <p className={cx(paymentsStyles.placeholderText, styles.link)}>
      <Link role="link" to={paths.bankAccounts}>
        {t('expenses.payments.no_accounts_link')}
      </Link>
    </p>
  </div>
);

NoBankAccountsPlaceholder.propTypes = {
  hasMargin: bool,
};

export default NoBankAccountsPlaceholder;
