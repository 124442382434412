import React, { ChangeEvent, useCallback } from 'react';
import { FilterProps } from 'react-table';
import { noop } from 'lodash';
import moment from 'moment';

import DateField from 'components/Form/DateField/DateField';

import { Fields, Label, Spearator, Wrapper } from './DateRangeFilter.styled';

type DateRangeFilterProps<T extends object> = FilterProps<T> & {
  label: string;
  minDateFrom?: moment.Moment;
  minDateTo?: moment.Moment;
};

const DateRangeFilter = <T extends object>({
  column: { filterValue = [], setFilter, id },
  label,
  minDateFrom,
  minDateTo,
}: DateRangeFilterProps<T>) => {
  const from = filterValue[0] || '';
  const to = filterValue[1] || '';

  const handleChangeFrom = useCallback(
    (newValue: string | ChangeEvent<HTMLInputElement>) => {
      const value = typeof newValue == 'string' ? newValue : newValue.target.value;

      setFilter((old = []) => [value, old[1]]);
    },
    [setFilter]
  );

  const handleChangeTo = useCallback(
    (newValue: string | ChangeEvent<HTMLInputElement>) => {
      const value = typeof newValue == 'string' ? newValue : newValue.target.value;

      setFilter((old = []) => [old[0], value]);
    },
    [setFilter]
  );

  return (
    <Wrapper data-id={`filter-${id}`}>
      <Label>{label}</Label>
      <Fields>
        <DateField
          placeholder="TT.MM.JJJJ"
          input={{
            name: `${id}-from`,
            value: from,
            onChange: handleChangeFrom,
            onBlur: noop,
            onFocus: noop,
            onDragStart: noop,
            onDrop: noop,
          }}
          minDate={minDateFrom}
          dataIds={{ input: `filter-${id}-from` }}
          isLabelHidden
        />
        <Spearator>bis</Spearator>
        <DateField
          placeholder="TT.MM.JJJJ"
          input={{
            name: `${id}To`,
            value: to,
            onChange: handleChangeTo,
            onBlur: noop,
            onFocus: noop,
            onDragStart: noop,
            onDrop: noop,
          }}
          minDate={minDateTo}
          dataIds={{ input: `filter-${id}-to` }}
          isLabelHidden
        />
      </Fields>
    </Wrapper>
  );
};

export default DateRangeFilter;
