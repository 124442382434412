import React from 'react';

import { t } from 'shared/utils';
import Button, { ButtonAppearances } from 'components/Button';

import { defaultPresets } from './presets';
import { ButtonWrapper } from './styled';
import { TDatePresets } from './types';

const DatePresets = (props: TDatePresets) => {
  const { presets, handleDatesChange } = props;

  return (
    <ButtonWrapper>
      {presets.map((key: string) => {
        const preset = Object.keys(defaultPresets).includes(key) && defaultPresets[key];

        if (preset) {
          const { label, start, end } = preset;

          return (
            <Button
              key={label}
              label={t(`date_ranges.${label}`)}
              appearance={ButtonAppearances.outlined}
              onClick={() => handleDatesChange({ startDate: start, endDate: end })}
            />
          );
        }
        return null;
      })}
    </ButtonWrapper>
  );
};

export default DatePresets;
