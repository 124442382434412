import type { JsonApiResponse } from 'json-api-normalizer';

import api from 'api';

type GetBankTransfersData = {
  sort?: string;
  page?: number;
  per_page?: number;
};

export const getBankTransfers = (id: string, data?: GetBankTransfersData) => {
  const url = `/me/bank_accounts/${id}/bank_transfers`;

  return api.get<JsonApiResponse>(url, {
    params: data,
  });
};
