import React, { SVGAttributes } from 'react';

const IntroductionSvg = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 218 87" {...props}>
      <defs>
        <ellipse id="liquidity-calculator_svg__b" cx={36.219} cy={35.5} rx={36.219} ry={35.5} />
        <filter
          x="-9.7%"
          y="-7%"
          width="119.3%"
          height="119.7%"
          filterUnits="objectBoundingBox"
          id="liquidity-calculator_svg__a"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M74.485 1.059c21.722 5.713 28.339 11.11 44.98 5.713 16.64-5.398 29.094-5.518 44.495-5.518 15.4 0 16.177 8.904 29.522 16.887 13.344 7.982 36.175 13.083 17.41 33.774-18.764 20.69-.474 1.177-31.793 21.545-31.319 20.37-74.94 12.811-96.135 8.735C61.769 78.12 63.757 70.367 43.6 62.397c-20.155-7.97-35.77-4.704-42.281-11.789-6.512-7.084 12.96-20.42 17.301-28.391 4.342-7.97 34.141-26.872 55.864-21.158z"
          fill="#F3F3F3"
        />
        <g transform="translate(72 8)">
          <use
            fill="#000"
            filter="url(#liquidity-calculator_svg__a)"
            xlinkHref="#liquidity-calculator_svg__b"
          />
          <use fill="#FFF" xlinkHref="#liquidity-calculator_svg__b" />
        </g>
        <g strokeLinejoin="round">
          <path
            stroke="#FFF"
            strokeWidth={3}
            strokeLinecap="round"
            d="M90 28.695h17.137v23.408H90z"
          />
          <path
            stroke="#FFF"
            strokeWidth={3}
            strokeLinecap="round"
            d="M92.98 31.715h11.177v4.53H92.98zM104.157 39.266h-2.235M104.157 42.287h-2.235M104.157 45.307h-2.235M99.686 39.266h-2.235M99.686 42.287h-2.235M99.686 45.307h-2.235M95.216 39.266H92.98M95.216 42.287H92.98M95.216 45.307H92.98M119.059 43.797h8.196V58.28c0 1.238-1.833 2.325-4.098 2.325-2.265 0-4.098-1.125-4.098-2.4V43.796z"
          />
          <path
            d="M119.059 44.023c0 1.246 1.833 2.266 4.105 2.266 2.273 0 4.098-1.005 4.098-2.266 0-1.26-1.833-2.265-4.098-2.265-2.265 0-4.105 1.027-4.105 2.265z"
            stroke="#FFF"
            strokeWidth={3}
            strokeLinecap="round"
          />
          <path
            d="M119.059 43.986c0 1.245 1.833 2.265 4.105 2.265 2.273 0 4.098-1.004 4.098-2.265M119.059 47.686c0 1.238 1.833 2.265 4.105 2.265 2.273 0 4.098-1.004 4.098-2.265M119.059 51.408c0 1.239 1.833 2.265 4.105 2.265 2.273 0 4.09-1.05 4.09-2.265M119.059 55.078c0 1.238 1.833 2.265 4.105 2.265 2.273 0 4.098-1.012 4.098-2.265"
            stroke="#FFF"
            strokeWidth={3}
            strokeLinecap="round"
          />
          <path
            d="M112.278 55.878h8.197v6.857c0 1.238-1.84 2.265-4.106 2.265-2.265 0-4.083-1.027-4.083-2.265l-.008-6.857z"
            stroke="#FFF"
            strokeWidth={3}
            strokeLinecap="round"
          />
          <path
            d="M112.286 56.03c0 1.245 1.84 2.265 4.105 2.265 2.265 0 4.106-1.005 4.106-2.266 0-1.26-1.84-2.265-4.106-2.265-2.265 0-4.105 1.027-4.105 2.265zM112.286 59.51c0 1.239 1.84 2.266 4.105 2.266 2.265 0 4.106-1.005 4.106-2.266M113.843 34.736v6.04M110.863 37.756h5.961"
            stroke="#FFF"
            strokeWidth={3}
            strokeLinecap="round"
          />
          <path
            stroke="#0066B3"
            strokeWidth={1.5}
            fill="#FFF"
            fillRule="nonzero"
            d="M90 28.695h17.137v23.408H90z"
          />
          <path
            stroke="#0066B3"
            strokeWidth={1.5}
            fill="#FFF"
            fillRule="nonzero"
            d="M92.98 31.715h11.177v4.53H92.98z"
          />
          <path
            stroke="#0066B3"
            strokeWidth={1.5}
            fill="#FFF"
            fillRule="nonzero"
            strokeLinecap="round"
            d="M104.157 39.266h-2.235M104.157 42.287h-2.235M104.157 45.307h-2.235M99.686 39.266h-2.235M99.686 42.287h-2.235M99.686 45.307h-2.235M95.216 39.266H92.98M95.216 42.287H92.98M95.216 45.307H92.98"
          />
          <path
            d="M119.059 43.797h8.196V58.28c0 1.238-1.833 2.325-4.098 2.325-2.265 0-4.098-1.125-4.098-2.4V43.796z"
            stroke="#F60"
            strokeWidth={1.5}
            fill="#FFF"
            fillRule="nonzero"
            strokeLinecap="round"
          />
          <path
            d="M119.059 44.023c0 1.246 1.833 2.266 4.105 2.266 2.273 0 4.098-1.005 4.098-2.266 0-1.26-1.833-2.265-4.098-2.265-2.265 0-4.105 1.027-4.105 2.265z"
            stroke="#F60"
            strokeWidth={1.5}
            fill="#FFF"
            fillRule="nonzero"
            strokeLinecap="round"
          />
          <path
            d="M119.059 43.986c0 1.245 1.833 2.265 4.105 2.265 2.273 0 4.098-1.004 4.098-2.265M119.059 47.686c0 1.238 1.833 2.265 4.105 2.265 2.273 0 4.098-1.004 4.098-2.265M119.059 51.408c0 1.239 1.833 2.265 4.105 2.265 2.273 0 4.09-1.05 4.09-2.265M119.059 55.078c0 1.238 1.833 2.265 4.105 2.265 2.273 0 4.098-1.012 4.098-2.265"
            stroke="#F60"
            strokeWidth={1.5}
            fill="#FFF"
            fillRule="nonzero"
            strokeLinecap="round"
          />
          <path
            d="M112.278 55.878h8.197v6.857c0 1.238-1.84 2.265-4.106 2.265-2.265 0-4.083-1.027-4.083-2.265l-.008-6.857z"
            stroke="#F60"
            strokeWidth={1.5}
            fill="#FFF"
            fillRule="nonzero"
            strokeLinecap="round"
          />
          <path
            d="M112.286 56.03c0 1.245 1.84 2.265 4.105 2.265 2.265 0 4.106-1.005 4.106-2.266 0-1.26-1.84-2.265-4.106-2.265-2.265 0-4.105 1.027-4.105 2.265zM112.286 59.51c0 1.239 1.84 2.266 4.105 2.266 2.265 0 4.106-1.005 4.106-2.266"
            stroke="#F60"
            strokeWidth={1.5}
            fill="#FFF"
            fillRule="nonzero"
            strokeLinecap="round"
          />
          <path
            stroke="#0066B3"
            strokeWidth={1.5}
            strokeLinecap="round"
            d="M113.843 34.736v6.04M110.863 37.756h5.961"
          />
        </g>
        <g strokeLinecap="round" strokeLinejoin="round">
          <path
            stroke="#FFF"
            strokeWidth={2.25}
            d="M61.366 64.817l-.011 5.087h4.987v-5.087zM61.242 55.14h.39c4.24-.163 5.843-1.548 5.843-3.645v-.068c0-1.323-1.037-2.385-2.986-2.385-1.95 0-3.451.774-4.998 2.317l-3.066-3.391c1.95-2.148 4.811-3.866 8.126-3.866 4.482-.006 8.024 2.724 8.024 7.184v.062c0 3.736-2.267 6.55-6.233 7.348v2.176h-5.1V55.14z"
          />
          <path
            stroke="#0066B3"
            strokeWidth={1.125}
            fill="#FFF"
            fillRule="nonzero"
            d="M61.366 64.817l-.011 5.087h4.987v-5.087zM61.242 55.14h.39c4.24-.163 5.843-1.548 5.843-3.645v-.068c0-1.323-1.037-2.385-2.986-2.385-1.95 0-3.451.774-4.998 2.317l-3.066-3.391c1.95-2.148 4.811-3.866 8.126-3.866 4.482-.006 8.024 2.724 8.024 7.184v.062c0 3.736-2.267 6.55-6.233 7.348v2.176h-5.1V55.14z"
          />
        </g>
        <g>
          <path
            stroke="#FFF"
            strokeWidth={2.25}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M145.106 28.15h12.2l2.353 2.222V47.85h-14.553z"
          />
          <path
            stroke="#FFF"
            strokeWidth={2.25}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M145.106 28.15h12.2l2.353 2.222V47.85h-14.553z"
          />
          <path
            stroke="#FFF"
            strokeWidth={2.25}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M145.106 28.15h12.2l2.353 2.222V47.85h-14.553z"
          />
          <path
            stroke="#FFF"
            strokeWidth={2.25}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M159.618 31.044h-2.942v-2.777"
          />
          <path
            stroke="#FFF"
            strokeWidth={2.25}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M145.106 28.15h12.2l2.353 2.222V47.85h-14.553z"
          />
          <path fill="#FFF" fillRule="nonzero" d="M145.106 28.15h12.2l2.353 2.222V47.85h-14.553z" />
          <path
            stroke="#0066B3"
            strokeWidth={1.125}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M145.106 28.15h12.2l2.353 2.222V47.85h-14.553z"
          />
          <path
            stroke="#0066B3"
            strokeWidth={1.125}
            fill="#0066B3"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M159.618 31.044h-2.942v-2.777"
          />
          <path
            stroke="#0066B3"
            strokeWidth={1.125}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M147.394 31.183h5.294M147.394 33.406h4.118M147.394 35.628h5.882M147.394 45.072h5.882"
          />
          <path
            stroke="#FFF"
            strokeWidth={2.25}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M154.912 42.433l8.317-7.855 1.665 1.566-8.323 7.862"
          />
          <path
            stroke="#FFF"
            strokeWidth={2.25}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M154.912 42.433l-.836 2.356 2.495-.783"
          />
          <path
            d="M154.912 42.444a1.227 1.227 0 011.664 0c.46.435.46 1.139 0 1.573"
            stroke="#FFF"
            strokeWidth={2.25}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            stroke="#0066B3"
            strokeWidth={1.125}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M154.912 42.433l8.317-7.855 1.665 1.566-8.323 7.862"
          />
          <path
            stroke="#0066B3"
            strokeWidth={1.125}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M154.912 42.433l-.836 2.356 2.495-.783"
          />
          <path
            d="M154.912 42.444a1.227 1.227 0 011.664 0c.46.435.46 1.139 0 1.573"
            stroke="#0066B3"
            strokeWidth={1.125}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default IntroductionSvg;
