import React from 'react';
import { bool, func, string } from 'prop-types';

import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import { ButtonAppearances } from 'components/Button';

import styles from './AbortButton.module.css';

const AbortButton = ({ visible, onClick, dataId }) => {
  if (!visible) return null;

  return (
    <div className={styles.transparentContainer}>
      <ActionButton
        fullWidth
        appearance={ButtonAppearances.outlined}
        onClick={onClick}
        label={t('features.order_confirmation.form.abort')}
        dataId={dataId}
      />
    </div>
  );
};

AbortButton.propTypes = {
  onClick: func,
  visible: bool,
  dataId: string,
};

export default AbortButton;
