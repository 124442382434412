import { t } from 'shared/utils';

import { Interval } from '../ExpensesRevenues/constants';
import { Category } from './types';

const ExpensePrefillingRules = {
  [Category.Rent]: [
    'Miete / Pacht',
    'Müllgebühren',
    'Nebenkosten',
    'Internet',
    'Stellplatz / Garagenmiete',
  ],
  [Category.Insurance]: [
    'Versicherungsbeiträge (ohne KFZ)',
    'Transportversicherung',
    'KFZ-Versicherung',
  ],
  [Category.Credit]: ['Kreditgebühren', 'Zinsen für Kredite (nicht Dispokredit)', 'Tilgung'],
  [Category.Withdrawals]: ['Privatentnahmen'],
};

/**
 * Function is used to return default, generic, expenses that will be added on top of user's expenses, if
 * passed categories names do not exist in prefilling rules.
 * @param {string[]} categoriesNames - list of categories names of expenses that user has
 */
export const getDefaultRecurringExpenses = (categoriesNames: string[]) =>
  [Category.Rent, Category.Insurance, Category.Credit, Category.Withdrawals]
    .filter((category: Category) =>
      ExpensePrefillingRules[category].every(
        (matchingCategory) => !categoriesNames.includes(matchingCategory)
      )
    )
    .map((category) => ({
      name: t(
        `features.financial_planning.liquidity_calculator.expenses.default_categories.${category}`
      ),
      interval: Interval.Monthly,
      amount: '',
    }));
