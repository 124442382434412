import React, { memo, SVGProps } from 'react';

const Search = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <filter id="search_(1)_svg__a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.000000 0 0 0 0 0.400000 0 0 0 0 0.701961 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g transform="translate(.5)" filter="url(#search_(1)_svg__a)" fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M15.932 15.253h-.88l-.313-.3a7.237 7.237 0 001.65-5.949c-.524-3.096-3.11-5.569-6.23-5.948-4.715-.579-8.682 3.386-8.103 8.098.38 3.119 2.854 5.703 5.952 6.226a7.248 7.248 0 005.951-1.648l.301.312v.88l4.737 4.733a1.178 1.178 0 001.66 0 1.176 1.176 0 000-1.66l-4.725-4.744zm-6.687 0A5.007 5.007 0 014.23 10.24a5.007 5.007 0 015.015-5.013 5.007 5.007 0 015.015 5.013 5.007 5.007 0 01-5.015 5.012z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(Search);
