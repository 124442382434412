import React, { useCallback, useRef, useState } from 'react';
import Modal from 'react-modal';
import { PDFPageProxy } from 'react-pdf';
import { useKeyPressEvent, useWindowSize } from 'react-use';

import { t } from 'shared/utils';
import LoadingIcon from 'components/LoadingIcon';
import IconDownload from 'redesign/components/atoms/Icons/Download';
import PagePicker from 'redesign/components/molecules/PagePicker/PagePicker';
import Zoom from 'redesign/components/molecules/Zoom/Zoom';

import { useUpdatePageNumberOnScroll, useUpdateZoom } from './hooks';
import {
  Document,
  Download,
  Header,
  HeaderContainer,
  IconClose,
  ModalContent,
  ModalOverlay,
  Page,
  PageWrapper,
  Title,
  Wrapper,
} from './PdfFullscreenPreview.styled';

export type PdfFullscreenPreviewProps = {
  title: string;
  onClose: () => void;
  onDownload: () => void;
  file: ArrayBuffer | string;
};

const PdfFullscreenPreview = ({ title, onClose, file, onDownload }: PdfFullscreenPreviewProps) => {
  const documentRef = useRef<HTMLDivElement | null>(null);
  const [pageRefs, setPageRefs] = useState<HTMLDivElement[]>([]);
  const [zoom, setZoom] = useState(25);
  const { width, height } = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const [numPages, setNumPages] = useState(1);
  const [pageWidth, setPageWidth] = useState<number>();
  const [pageHeight, setPageHeight] = useState<number>();
  const [page, setPage] = useState<number>(1);
  const [isDownloading, setIsDownloading] = useState(false);
  const scale = zoom * 0.01;

  const handleLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, []);

  const handleRenderSuccess = useCallback(() => {
    setIsLoading(false);
  }, []);

  const handleChangeZoom = useCallback((zoom: number) => {
    setZoom(zoom);
  }, []);

  const handlePageLoadSuccess = useCallback((page: PDFPageProxy) => {
    setPageWidth(page.originalWidth);
    setPageHeight(page.originalHeight);
  }, []);

  const handleChangePage = useCallback(
    (page: number) => {
      setPage(page);
      pageRefs[page - 1].scrollIntoView();
    },
    [pageRefs]
  );

  const handlePageRef = useCallback((pageRef: HTMLDivElement) => {
    setPageRefs((pageRefs) => [...pageRefs, pageRef]);
  }, []);

  const handleDownload = useCallback(async () => {
    setIsDownloading(true);
    await onDownload();
    setIsDownloading(false);
  }, [onDownload]);

  const handleGoToNextPage = useCallback(() => {
    if (isLoading || page === numPages) return;

    handleChangePage(page + 1);
  }, [page, numPages, handleChangePage, isLoading]);

  const handleGoToPreviousPage = useCallback(() => {
    if (isLoading || page === 1) return;

    handleChangePage(page - 1);
  }, [page, handleChangePage, isLoading]);

  useUpdatePageNumberOnScroll(documentRef, setPage, isLoading, zoom);

  useUpdateZoom(documentRef, setZoom, width, height, pageWidth, pageHeight);

  useKeyPressEvent('ArrowRight', handleGoToNextPage);

  useKeyPressEvent('ArrowLeft', handleGoToPreviousPage);

  return (
    <Modal
      isOpen
      overlayElement={({ style, ...props }, contentElement) => (
        <ModalOverlay {...props}>{contentElement}</ModalOverlay>
      )}
      contentElement={({ style, ...props }, contentElement) => (
        <ModalContent {...props}>{contentElement}</ModalContent>
      )}
      onRequestClose={onClose}
      shouldCloseOnEsc
      appElement={document.getElementById('pbw-react-root') as HTMLElement}
    >
      <Wrapper>
        <Header>
          <HeaderContainer>
            <div>
              <Title>{title}</Title>
              <IconClose onClick={onClose} />
            </div>
            <div>
              <PagePicker
                page={page}
                totalPages={numPages}
                onChange={handleChangePage}
                disabled={isLoading}
              />
              <Zoom zoom={zoom} onChange={handleChangeZoom} disabled={isLoading} />
              <Download
                as="button"
                onClick={handleDownload}
                disabled={isDownloading || isLoading}
                $variant="action"
              >
                <IconDownload />
                <span>{t('redesign.organisms.pdf_fullscreen_preview.download_preview')}</span>
              </Download>
            </div>
          </HeaderContainer>
        </Header>
        <Document file={file} loading="" onLoadSuccess={handleLoadSuccess} inputRef={documentRef}>
          {Array.from(new Array(numPages), (_el, index) => (
            <PageWrapper ref={handlePageRef} data-page-number={index + 1} key={`page_${index + 1}`}>
              <Page
                pageNumber={index + 1}
                loading=""
                onRenderSuccess={handleRenderSuccess}
                onLoadSuccess={index === 0 ? handlePageLoadSuccess : undefined}
                scale={scale}
              />
            </PageWrapper>
          ))}
          {isLoading && <LoadingIcon />}
        </Document>
      </Wrapper>
    </Modal>
  );
};

export default PdfFullscreenPreview;
