import { t } from 'shared/utils';
export const INDEPENDENT_CONTRACTOR_COMPANY_FORM = 'independent_contractor';

export const businessSectorOptions = [
  t('profile.company.business_sectors.a'),
  t('profile.company.business_sectors.b'),
  t('profile.company.business_sectors.c'),
  t('profile.company.business_sectors.d'),
  t('profile.company.business_sectors.e'),
  t('profile.company.business_sectors.f'),
  t('profile.company.business_sectors.g'),
  t('profile.company.business_sectors.h'),
  t('profile.company.business_sectors.i'),
  t('profile.company.business_sectors.j'),
  t('profile.company.business_sectors.k'),
  t('profile.company.business_sectors.l'),
  t('profile.company.business_sectors.m'),
  t('profile.company.business_sectors.n'),
  t('profile.company.business_sectors.o'),
  t('profile.company.business_sectors.p'),
  t('profile.company.business_sectors.q'),
  t('profile.company.business_sectors.r'),
  t('profile.company.business_sectors.s'),
  t('profile.company.business_sectors.t'),
  t('profile.company.business_sectors.u'),
].map((option) => ({ label: option, value: option }));

export const FORM_NAME = 'company';
