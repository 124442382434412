import React, { memo, SVGProps } from 'react';

const BankTransfers = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 120 88" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="#BFD9EC" fillRule="evenodd">
      <path d="m83.15 36.247-.241-.21a2.58 2.58 0 0 0-3.392.21l-.214.24c-.783 1.001-.714 2.452.214 3.372l10.147 10.085H53.072a2.566 2.566 0 0 0-2.572 2.557c0 1.41 1.155 2.55 2.572 2.55h36.592l-10.147 10.09-.214.24c-.783 1-.714 2.452.214 3.37a2.58 2.58 0 0 0 3.634 0L99.5 52.502 83.15 36.247ZM36.85 51.753l.241.21a2.58 2.58 0 0 0 3.392-.21l.214-.24c.783-1.001.714-2.452-.214-3.372L30.336 38.056h36.592a2.566 2.566 0 0 0 2.572-2.557c0-1.41-1.155-2.55-2.572-2.55H30.336l10.147-10.09.214-.24c.783-1 .714-2.452-.214-3.37a2.58 2.58 0 0 0-3.634 0L20.5 35.498l16.35 16.254Z" />
    </g>
  </svg>
);

export default memo(BankTransfers);
