import React from 'react';
import cx from 'classnames';
import { bool, node, shape, string } from 'prop-types';

import ActionEdit from 'components/IndexActionsContainer/actionIcons/ActionEdit';

import styles from './HeaderWithEdit.module.css';

const HeaderWithEdit = ({
  bold = false,
  hasMargin = true,
  buttonPath,
  iconDataId = '',
  children,
}) => (
  <div className={cx(styles.header, bold && styles.bold, hasMargin && styles.hasMargin)}>
    {children}
    <ActionEdit to={buttonPath} dataId={iconDataId} iconOnly />
  </div>
);

HeaderWithEdit.propTypes = {
  bold: bool,
  buttonPath: shape({}).isRequired,
  children: node,
  hasMargin: bool,
  iconDataId: string,
};

export default HeaderWithEdit;
