import { AxiosPromise } from 'axios';
import { JsonApiResponse } from 'json-api-normalizer';

import api from '../index';

export const getOutgoingInvoice = (id: string): AxiosPromise<JsonApiResponse> => {
  const url = `/me/outgoing_invoices/${id}`;

  return api.get(url);
};
