import React, { memo, ReactNode } from 'react';
import cx from 'classnames';

import styles from './Table.module.css';

type HeaderCellProps = {
  children?: ReactNode;
  className?: string;
};

const HeaderCell = ({ children, className = '' }: HeaderCellProps) => (
  <div className={cx(styles.headerCell, className)}>{children}</div>
);

export default memo(HeaderCell);
