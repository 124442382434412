import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { fetchCompany } from 'actions/company';
import { fetchNewOutgoingInvoice } from 'actions/outgoing-invoice';
import { NEW_MODE } from 'constants/common/crud';
import { FORM_NAME } from 'constants/outgoing-invoice';
import { withWarningAlert } from 'shared/hoc';
import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import useLineItemsControl, { SectionType } from 'components/LineItems/useLineItemControl';

import OutgoingInvoiceCreator from '../OutgoingInvoiceCreator/OutgoingInvoiceCreator';

const OutgoingInvoiceNew = () => {
  const dispatch = useAppDispatch();
  const {
    manualHandleSelectCategory,
    setLineItemState,
    setShowCategoryChangeConfirmation,
    setShowDifferentCategoryWarning,
    setShowVatIdWarning,
    showDifferentCategoryWarning,
    showCategoryChangeConfirmation,
    defaultCategory,
    canSubmit,
    showVatIdWarning,
    category,
    taxRates,
    taxRateId,
    defaultVat,
    selectedCategory,
    lineCategories,
    isInitial,
  } = useLineItemsControl({
    sectionType: SectionType.outgoingInvoice,
    formName: FORM_NAME,
    isNewForm: true,
  });

  const fetchData = () => {
    dispatch(fetchNewOutgoingInvoice());
    dispatch(fetchCompany());
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <OutgoingInvoiceCreator
      newInvoice
      destroyOnUnmount
      crudMode={NEW_MODE}
      selectedCategory={selectedCategory}
      lineCategories={lineCategories}
      defaultCategory={defaultCategory}
      handleSelectCategory={manualHandleSelectCategory}
      category={category}
      showCategoryChangeConfirmation={showCategoryChangeConfirmation}
      setShowCategoryChangeConfirmation={setShowCategoryChangeConfirmation}
      taxRateId={taxRateId}
      taxRates={taxRates}
      defaultVat={defaultVat}
      canSubmit={canSubmit}
      isInitial={isInitial}
      setLineItemState={setLineItemState}
      showVatIdWarning={showVatIdWarning}
      setShowVatIdWarning={setShowVatIdWarning}
      showDifferentCategoryWarning={showDifferentCategoryWarning}
      setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
    />
  );
};

const mapStateToProps = () => ({
  buttonLabel: t('empty_entry_pages.outgoing_invoice.invoice_warning_box.button_label'),
  content: t('empty_entry_pages.outgoing_invoice.invoice_warning_box.content'),
  dataId: 'OutgoingInvoices:orange-warning-container',
});

export default compose<any>(connect(mapStateToProps, null), withWarningAlert)(OutgoingInvoiceNew);
