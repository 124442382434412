import React from 'react';
import cx from 'classnames';
import { arrayOf, bool, func, node, oneOfType, string } from 'prop-types';

import { defaultGroupSize } from 'constants/index-actions-groups';
import getIndexActionsGroups from 'shared/utils/getIndexActionsGroups/get-index-actions-groups';
import IconsDropdown from 'components/IconsDropdown/IconsDropdown';
import actionStyles from 'components/IndexActionsContainer/actionIcons/Action.module.css';

import styles from './indexActionsContainer.module.css';

const IndexActionsContainer = ({ isCentered = false, children, className }) => {
  const [visibleActions, dropdownActions] = getIndexActionsGroups(children, defaultGroupSize);

  return (
    <div
      className={cx(
        styles.actionsContainer,
        {
          [styles.centeredActionIcons]: isCentered,
        },
        className
      )}
    >
      {visibleActions.map((action, index) => (
        <div className={actionStyles.actionNoLabel} key={index}>
          {action}
        </div>
      ))}
      <IconsDropdown withDropdownLabel actions={dropdownActions} />
    </div>
  );
};

IndexActionsContainer.propTypes = {
  children: oneOfType([func, node, arrayOf(oneOfType([func, node]))]).isRequired,
  isCentered: bool,
  className: string,
};

export default IndexActionsContainer;
