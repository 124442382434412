import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';

import LoginForm from 'containers/LoginForm/LoginForm';
import { t } from 'shared/utils';
import CardView, { HeadingSection, Section } from 'components/CardView';
import Logo from 'components/Logo';

import styles from '../Shared.module.css';

const LoginPage = ({ location }) => {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.wrapper}>
        <CardView className={styles.card}>
          <HeadingSection dataId="heading-section" className={styles.cardSectionHeading}>
            <div className={styles.desktopLogo}>
              <Logo className={styles.logo} />
            </div>
          </HeadingSection>
          <Section className={styles.cardSection}>
            <h3 data-id="card-title" className={styles.subtitle}>
              {t('login.title')}
            </h3>
            <LoginForm query={{ ...parse(location.search) }} />
          </Section>
        </CardView>
      </div>
    </div>
  );
};

LoginPage.propTypes = {
  bankEmployeeDashboard: PropTypes.bool,
  location: PropTypes.shape({}).isRequired,
};

export default LoginPage;
