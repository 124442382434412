import camelCase from 'lodash/camelCase';
import isObject from 'lodash/isObject';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';

export const mapToCamelCaseRecursive = <T>(mappedValue: T): T => {
  if (!isObject(mappedValue)) return mappedValue as T;

  const camelCaseObject = mapValues(mappedValue, (value) => mapToCamelCaseRecursive(value));

  return mapKeys(camelCaseObject, (_, key) => camelCase(key)) as unknown as T;
};
