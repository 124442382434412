import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { func, shape } from 'prop-types';

import { fetchNewIncomingCancellationInvoice } from 'actions/incoming-invoice';
import { CANCEL_MODE } from 'constants/common/crud';
import IncomingInvoiceCreator from 'containers/IncomingInvoices/IncomingInvoiceCreator/IncomingInvoiceCreator';
import LoadingIcon from 'components/LoadingIcon';

const IncomingInvoiceCancel = ({ match, fetchNewIncomingCancellationInvoice }) => {
  const {
    params: { id },
  } = match;

  const [isFetching, setIsFetching] = useState(false);
  const [lineItemsState, setLineItemsState] = useState({});

  const bufferLineItemsState = {};
  const handleLineItemStateChange = ({ id, open }) => {
    bufferLineItemsState[id] = open;
    setLineItemsState({ ...lineItemsState, ...bufferLineItemsState, [id]: open });
  };

  const fetchData = async (id) => {
    try {
      setIsFetching(true);
      await fetchNewIncomingCancellationInvoice(id);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  return isFetching ? (
    <LoadingIcon />
  ) : (
    <IncomingInvoiceCreator
      crudMode={CANCEL_MODE}
      isCancelInvoice
      setLineItemState={handleLineItemStateChange}
      canSubmit
    />
  );
};

IncomingInvoiceCancel.propTypes = {
  match: shape({}).isRequired,
  fetchNewIncomingCancellationInvoice: func.isRequired,
};

const mapDispatchToProps = {
  fetchNewIncomingCancellationInvoice,
};

export default connect(null, mapDispatchToProps)(IncomingInvoiceCancel);
