import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { bool, func, number } from 'prop-types';

import {
  deleteMessage as deleteMessageAction,
  fetchCustomerMessages as fetchCustomerMessagesAction,
  markAsUnread as markAsUnreadAction,
  setActiveMessage as setActiveMessageAction,
} from 'actions/message-inbox';
import { t } from 'shared/utils';
import IconDelete from 'components/ActionIcons/IconDelete';
import IconMarkAsUnread from 'components/ActionIcons/IconMarkAsUnread';
import IconsDropdown from 'components/IconsDropdown/IconsDropdown';
import Action from 'components/IndexActionsContainer/actionIcons/Action';

import styles from './ActionsDropdown.module.css';

const ActionsDropdown = ({
  markedAsRead,
  deleteMessage,
  markAsUnread,
  messageId,
  isMessageActive,
  fetchCustomerMessages,
  setActiveMessage,
}) => (
  <div className={styles.container}>
    <IconsDropdown
      className={cx({ [styles.messageActive]: isMessageActive })}
      alignRight
      actions={[
        <Action
          icon={IconDelete}
          onClick={() => {
            setActiveMessage(); //  // when setActiveMessage is called with no parameters, it sets activeMessageId to null, which results in closing the details view
            deleteMessage(messageId).then(fetchCustomerMessages);
          }}
          label={t('sidebar.messages.delete_message')}
          visible
          key={0}
        />,
        markedAsRead || isMessageActive ? (
          <Action
            icon={IconMarkAsUnread}
            onClick={() => markAsUnread(messageId).then(fetchCustomerMessages)}
            label={t('sidebar.messages.mark_as_unread')}
            visible
            key={1}
          />
        ) : null,
      ]}
    />
  </div>
);

ActionsDropdown.propTypes = {
  deleteMessage: func.isRequired,
  markAsUnread: func.isRequired,
  messageId: number.isRequired,
  isMessageActive: bool.isRequired,
  fetchCustomerMessages: func.isRequired,
  setActiveMessage: func.isRequired,
  markedAsRead: bool.isRequired,
};

const mapDispatchToProps = {
  deleteMessage: deleteMessageAction,
  markAsUnread: markAsUnreadAction,
  fetchCustomerMessages: fetchCustomerMessagesAction,
  setActiveMessage: setActiveMessageAction,
};

export default connect(null, mapDispatchToProps)(ActionsDropdown);
