import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export type ButtonProps = {
  $color?: 'blue' | 'darkblue' | 'orange' | 'white' | 'red';
  $variant?: 'contained' | 'outlined';
  disabled?: boolean;
  dataId?: string;
};

const contained = css<ButtonProps>`
  padding: ${({ theme }) => `${theme.space['3']} ${theme.space['4']}`};
  color: ${({ $color, theme }) => ($color === 'white' ? theme.colors.black : theme.colors.white)};
  background-color: ${({ $color, theme }) =>
    $color === 'blue'
      ? theme.colors.vrblue['100']
      : $color === 'darkblue'
      ? theme.colors.nightblue['100']
      : $color === 'orange'
      ? theme.colors.vrorange['100']
      : $color === 'white'
      ? theme.colors.white
      : $color === 'red'
      ? theme.colors.red['100']
      : ''};
  box-shadow: 1px 1px 2px 0
    ${({ $color, theme }) =>
      transparentize(
        0.7,
        $color === 'blue'
          ? theme.colors.vrblue['100']
          : $color === 'darkblue'
          ? theme.colors.nightblue['100']
          : $color === 'orange'
          ? theme.colors.vrorange['100']
          : $color === 'white'
          ? theme.colors.black
          : $color === 'red'
          ? theme.colors.red['100']
          : ''
      )};
  transition: box-shadow 200ms, background-color 200ms;

  &:hover,
  &:focus {
    color: ${({ $color, theme }) => ($color === 'white' ? theme.colors.black : theme.colors.white)};
    background-color: ${({ $color, theme }) =>
      $color === 'blue'
        ? theme.colors.vrblue['075']
        : $color === 'darkblue'
        ? theme.colors.nightblue['075']
        : $color === 'orange'
        ? theme.colors.vrorange['075']
        : $color === 'white'
        ? theme.colors.white
        : $color === 'red'
        ? theme.colors.red['075']
        : ''};
    box-shadow: 3px 3px 5px 0
      ${({ $color, theme }) =>
        transparentize(
          0.6,
          $color === 'blue'
            ? theme.colors.vrblue['100']
            : $color === 'darkblue'
            ? theme.colors.nightblue['100']
            : $color === 'orange'
            ? theme.colors.vrorange['100']
            : $color === 'white'
            ? theme.colors.black
            : $color === 'red'
            ? theme.colors.red['100']
            : ''
        )};
  }

  &:active {
    color: ${({ $color, theme }) => ($color === 'white' ? theme.colors.black : theme.colors.white)};
    background-color: ${({ $color, theme }) =>
      $color === 'blue'
        ? theme.colors.vrblue['050']
        : $color === 'darkblue'
        ? theme.colors.nightblue['050']
        : $color === 'orange'
        ? theme.colors.vrorange['050']
        : $color === 'white'
        ? theme.colors.white
        : $color === 'red'
        ? theme.colors.red['050']
        : ''};
    box-shadow: 1px 1px 5px 0
      ${({ $color, theme }) =>
        transparentize(
          0.6,
          $color === 'blue'
            ? theme.colors.vrblue['100']
            : $color === 'darkblue'
            ? theme.colors.nightblue['100']
            : $color === 'orange'
            ? theme.colors.vrorange['100']
            : $color === 'white'
            ? theme.colors.black
            : $color === 'red'
            ? theme.colors.red['100']
            : ''
        )};
  }

  &[disabled] {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.grey['040']};
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.grey['040']};
      box-shadow: none;
    }
  }
`;

const outlined = css<ButtonProps>`
  padding: 0.625rem 0.875rem;
  color: ${({ $color, theme }) =>
    $color === 'blue'
      ? theme.colors.vrblue['100']
      : $color === 'darkblue'
      ? theme.colors.nightblue['100']
      : $color === 'orange'
      ? theme.colors.vrorange['100']
      : $color === 'white'
      ? theme.colors.white
      : $color === 'red'
      ? theme.colors.red['100']
      : ''};
  background-color: transparent;
  border: 2px solid
    ${({ $color, theme }) =>
      $color === 'blue'
        ? theme.colors.vrblue['100']
        : $color === 'darkblue'
        ? theme.colors.nightblue['100']
        : $color === 'orange'
        ? theme.colors.vrorange['100']
        : $color === 'white'
        ? theme.colors.white
        : $color === 'red'
        ? theme.colors.red['100']
        : ''};
  box-shadow: 1px 1px 2px 0
    ${({ $color, theme }) =>
      transparentize(
        0.6,
        $color === 'blue'
          ? theme.colors.vrblue['100']
          : $color === 'darkblue'
          ? theme.colors.nightblue['100']
          : $color === 'orange'
          ? theme.colors.vrorange['100']
          : $color === 'white'
          ? theme.colors.white
          : $color === 'red'
          ? theme.colors.red['100']
          : ''
      )};
  transition: box-shadow 200ms, background-color 200ms;

  &:hover,
  &:focus {
    color: ${({ $color, theme }) =>
      $color === 'blue'
        ? theme.colors.vrblue['100']
        : $color === 'darkblue'
        ? theme.colors.nightblue['100']
        : $color === 'orange'
        ? theme.colors.vrorange['100']
        : $color === 'white'
        ? theme.colors.white
        : $color === 'red'
        ? theme.colors.red['100']
        : ''};
    background-color: ${({ $color, theme }) =>
      transparentize(
        0.9,
        $color === 'blue'
          ? theme.colors.vrblue['050']
          : $color === 'darkblue'
          ? theme.colors.nightblue['050']
          : $color === 'orange'
          ? theme.colors.vrorange['050']
          : $color === 'white'
          ? theme.colors.white
          : $color === 'red'
          ? theme.colors.red['050']
          : ''
      )};
    box-shadow: 3px 3px 5px 0
      ${({ $color, theme }) =>
        transparentize(
          0.6,
          $color === 'blue'
            ? theme.colors.vrblue['100']
            : $color === 'darkblue'
            ? theme.colors.nightblue['100']
            : $color === 'orange'
            ? theme.colors.vrorange['100']
            : $color === 'white'
            ? theme.colors.white
            : $color === 'red'
            ? theme.colors.red['100']
            : ''
        )};
  }

  &:active {
    color: ${({ $color, theme }) =>
      $color === 'blue'
        ? theme.colors.vrblue['100']
        : $color === 'darkblue'
        ? theme.colors.nightblue['100']
        : $color === 'orange'
        ? theme.colors.vrorange['100']
        : $color === 'white'
        ? theme.colors.white
        : $color === 'red'
        ? theme.colors.red['100']
        : ''};
    background-color: ${({ $color, theme }) =>
      transparentize(
        0.85,
        $color === 'blue'
          ? theme.colors.vrblue['075']
          : $color === 'darkblue'
          ? theme.colors.nightblue['050']
          : $color === 'orange'
          ? theme.colors.vrorange['075']
          : $color === 'white'
          ? theme.colors.white
          : $color === 'red'
          ? theme.colors.red['100']
          : ''
      )};
    box-shadow: 1px 1px 5px 0
      ${({ $color, theme }) =>
        transparentize(
          0.6,
          $color === 'blue'
            ? theme.colors.vrblue['100']
            : $color === 'darkblue'
            ? theme.colors.nightblue['100']
            : $color === 'orange'
            ? theme.colors.vrorange['100']
            : $color === 'white'
            ? theme.colors.white
            : $color === 'red'
            ? theme.colors.red['100']
            : ''
        )};
  }

  &[disabled] {
    color: ${({ theme }) => theme.colors.grey['040']};
    border-color: ${({ theme }) => theme.colors.grey['040']};
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => theme.colors.grey['040']};
      border-color: ${({ theme }) => theme.colors.grey['040']};
      background-color: transparent;
      box-shadow: none;
    }
  }
`;

const Button = styled.button<ButtonProps>`
  display: inline-block;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.sm};
  letter-spacing: 0.25px;
  line-height: 17px;
  white-space: nowrap;
  border-radius: 3px;
  outline: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &[disabled] {
    cursor: not-allowed;
    pointer-events: ${(props) => 'to' in props && 'none'};
  }

  ${({ $variant }) => ($variant === 'contained' ? contained : outlined)};
`;

Button.defaultProps = {
  $color: 'darkblue',
  $variant: 'contained',
};

export default Button;
