import React, { FunctionComponent } from 'react';

import { t } from 'shared/utils';

import SearchDate from '../SearchDate/SearchDate';

import styles from './SearchDateGroup.module.css';

interface DateProps {
  name: string;
  placeholder?: string;
  label?: string;
  className?: string;
  dataIds?: { container?: string; input?: string };
}

interface SearchDateGroupProps {
  filters?: { [key: string]: string };
  dataId?: string;
  setQueryAction?: (name: string) => (dispatch?: any) => (value: string) => void;
  clearFieldAction?: () => void;
  label: string;
  startDateProps: DateProps;
  endDateProps: DateProps;
  isPastDateDisabled?: boolean;
}

const getDateProps = (dateProps: DateProps): DateProps => ({
  label: 'TT.MM.JJJJ',
  placeholder: 'TT.MM.JJJJ',
  ...dateProps,
});

const SearchDateGroup: FunctionComponent<SearchDateGroupProps> = ({
  startDateProps,
  endDateProps,
  label,
  ...props
}) => (
  <div className={styles.container}>
    <p className={styles.label}>{label}</p>
    <SearchDate {...getDateProps(startDateProps)} {...props} />
    <p className={styles.endDateLabel}>{t('datev.creator.labels.date_range_end')}</p>
    <SearchDate {...getDateProps(endDateProps)} {...props} />
  </div>
);

export default SearchDateGroup;
