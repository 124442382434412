import moment from 'moment';

import { TDefaultPresets } from './types';

export const defaultPresets: TDefaultPresets = {
  last_quarter: {
    label: 'last_quarter',
    start: moment()
      .quarter(moment().quarter() - 1)
      .startOf('quarter'),
    end: moment()
      .quarter(moment().quarter() - 1)
      .endOf('quarter'),
  },
  last_month: {
    label: 'last_month',
    start: moment()
      .month(moment().month() - 1)
      .startOf('month'),
    end: moment()
      .month(moment().month() - 1)
      .endOf('month'),
  },
  last_year: {
    label: 'last_year',
    start: moment()
      .year(moment().year() - 1)
      .startOf('year'),
    end: moment()
      .year(moment().year() - 1)
      .endOf('year'),
  },
};
