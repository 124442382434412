import React from 'react';
import { Row, TableInstance } from 'react-table';

import Loading from 'components/Loading';
import BaseTable from 'redesign/components/atoms/Table/Table';
import Td from 'redesign/components/atoms/Td/Td';
import Th from 'redesign/components/atoms/Th/Th';

import { Empty, Wrapper } from './Table.styled';

export type TableProps<T extends object> = {
  instance: TableInstance<T>;
  isLoading?: boolean;
  getRowProps?: (row: Row<T>) => object;
  emptyText?: string;
};

const Table = <T extends object>({
  instance,
  isLoading,
  getRowProps,
  emptyText,
}: TableProps<T>) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, visibleColumns } =
    instance;

  return (
    <Wrapper>
      <BaseTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th {...column.getHeaderProps()} key={index} data-id={`header-${column.id}`}>
                  {column.render('Header')}
                </Th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length
            ? rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    {...(getRowProps && getRowProps(row))}
                    key={row.index}
                    data-id={`row-${row.index}`}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <Td {...cell.getCellProps()} key={index} data-id={`cell-${cell.column.id}`}>
                          {cell.render('Cell')}
                        </Td>
                      );
                    })}
                  </tr>
                );
              })
            : emptyText && <Empty colSpan={visibleColumns.length}>{emptyText}</Empty>}
        </tbody>
      </BaseTable>
      {isLoading && <Loading alwaysOnTop />}
    </Wrapper>
  );
};

export default Table;
