export type Predicate<T> = (item: T) => boolean;
export type Getter<T> = (item: T) => string;

export const splitByPredicate = <T>(collection: T[], predicate: Predicate<T>) => {
  const matchedItems: T[] = [];
  const notMatchedItems: T[] = [];

  const processItem = (item: T) => {
    if (predicate(item)) matchedItems.push(item);
    else notMatchedItems.push(item);
  };

  collection.forEach(processItem);
  return { matchedItems, notMatchedItems };
};

export const sortByPredicate = <T>(
  collection: T[],
  predicate: Predicate<T>,
  notMatchedFirst: boolean = false
): T[] => {
  const { matchedItems, notMatchedItems } = splitByPredicate(collection, predicate);

  return notMatchedFirst
    ? [...notMatchedItems, ...matchedItems]
    : [...matchedItems, ...notMatchedItems];
};

export const sortByAccuracy = <T>(
  collection: T[] = [],
  phrase: string,
  getterFunction: Getter<T>
): T[] => {
  const loweredPhrase = phrase.toLowerCase();
  const isMatchPredicate = (item: T) => getterFunction(item).toLowerCase() === loweredPhrase;

  const startsWithPredicate = (item: T) =>
    getterFunction(item).toLowerCase().startsWith(loweredPhrase);

  const sortedByStartMatch = sortByPredicate(collection, startsWithPredicate);
  return sortByPredicate(sortedByStartMatch, isMatchPredicate);
};
