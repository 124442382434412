import * as Resource from 'constants/common/resource';
import * as Piwik from 'constants/piwik';
import * as ProductCatalogItems from 'constants/product-catalog/items';
import { piwikHelpers } from 'shared/utils/piwik';

const productCatalogItemsActionSwitch = (actionType) => {
  switch (actionType) {
    // when user clicks on "neue Leistung hinzufügen" on index-page of product-catalog/items, then track with params category="Leistungskatalog/Leistungen", action="neue Leistung hinzufuegen"
    case ProductCatalogItems.CLICK_CREATE_PRODUCT_CATALOG_ITEM:
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_PRODUCT_CATALOG_ITEMS,
        Piwik.ACTION_NEW_PRODUCT_CATALOG_ITEM
      );
      break;

    // when user deletes product-catalog-items, then track with params category="Leistungskatalog/Leistungen", action="loeschen"
    case ProductCatalogItems.CLICK_EDIT_PRODUCT_CATALOG_ITEM:
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_PRODUCT_CATALOG_ITEMS,
        Piwik.ACTION_EDIT_PRODUCT_CATALOG_ITEM
      );
      break;

    // when user deletes product - catalog - items, then track with params category = "Leistungskatalog/Leistungen", action = "loeschen"
    case Resource.DELETE_SUCCESS:
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_PRODUCT_CATALOG_ITEMS,
        Piwik.ACTION_DELETE_PRODUCT_CATALOG_ITEM
      );
      break;

    // when user creates a new product-catalog-item successfully in create-page of product-catalog-items, then track with params category="Leistungskatalog/Leistungen", action="Leistung erstellen",  name="Erfolg"
    case Resource.CREATE_SUCCESS:
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_PRODUCT_CATALOG_ITEMS,
        Piwik.ACTION_CREATE_PRODUCT_CATALOG_ITEM,
        Piwik.NAME_CREATE_PRODUCT_CATALOG_ITEM_SUCCESS
      );
      piwikHelpers.trackGoal(16);
      break;

    // when user tries to save a product-catalog-item in create-page of product-catalog-items, but an error occurred, then track with params category="Leistungskatalog/Leistungen", action="Leistung erstellen", name="Fehler"
    case Resource.CREATE_FAILURE:
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_PRODUCT_CATALOG_ITEMS,
        Piwik.ACTION_CREATE_PRODUCT_CATALOG_ITEM,
        Piwik.NAME_CREATE_PRODUCT_CATALOG_ITEM_ERROR
      );
      break;

    // when user clicks on "Abbrechen"-button in create-page of product-catalog-items, then track with params category="Leistungskatalog/Leistungen", action="abbrechen"
    case ProductCatalogItems.CLICK_CANCEL_PRODUCT_CATALOG_ITEM:
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_PRODUCT_CATALOG_ITEMS,
        Piwik.ACTION_CREATE_PRODUCT_CATALOG_ITEM_CANCEL
      );
      break;

    default:
      break;
  }
};

export default productCatalogItemsActionSwitch;
