import React, { memo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import omit from 'lodash/omit';

import { downloadContractSuccess } from 'notifications/contracts';
import paths from 'routes/paths';
import { urlWithParams } from 'shared/utils';
import { t } from 'shared/utils';
import {
  contractFilename,
  incomingInvoiceUploadedFilename,
  outgoingInvoiceUploadedFilename,
} from 'shared/utils/file-naming';
import { piwikHelpers } from 'shared/utils/piwik';

import If from '../Conditions/If';
import Loading from '../Loading';
import EmptyStateBanner from './EmptyStateBanner/EmptyStateBanner';
import ReadonlyEmptyStateBanner from './EmptyStateBanner/ReadonlyEmptyStateBanner';
import PreviewContainer from './PreviewContainer/PreviewContainer';
import type {
  DeleteFile,
  DownloadFilePreview,
  Notification,
  PreviewFile,
  TrackingEvents,
} from './types';
import { uploadFile } from './utils';

import styles from './Dropzone.module.css';

const defaultAllowedFileExtensions: AllowedFileExtensions = [
  'image/x-png',
  'image/png',
  'image/jpeg',
  'image/tiff',
  'application/pdf',
];

type SupportedTemplates = 'incomingInvoiceFile' | 'outgoingInvoiceFile' | 'contractFile';

type AllowedFileExtensions =
  | ['image/x-png' | 'image/png' | 'image/jpeg' | 'image/tiff' | 'application/pdf']
  | string[];

type DropzoneProps = {
  accessToken: string;
  template: SupportedTemplates;
  deleteFile: DeleteFile;
  downloadFilePreview: DownloadFilePreview;
  readonly: boolean;
  ocrEnabled: boolean;
  files: File[] & PreviewFile[];
  fileId: string;
  uploadFileAction: (fileId: string, files: File[]) => Promise<any>;
  showNotification: (notification: Notification | {}) => void;
  getInvoiceOcrData?: (fileId: string) => any;
  trackingEvents?: TrackingEvents;
  allowedFileExtensions?: AllowedFileExtensions;
  fileSubject?: string;
  contractNumber?: string;
  isFullscreen?: boolean;
  onDownloadContract?: (contractId: string) => Promise<any>;
  contractDownloadUrl?: string;
};

const fileNameGenerator = (template: SupportedTemplates) => {
  switch (template) {
    case 'incomingInvoiceFile':
      return incomingInvoiceUploadedFilename;
    case 'outgoingInvoiceFile':
      return outgoingInvoiceUploadedFilename;
    default:
      return contractFilename;
  }
};

const Dropzone = ({
  accessToken,
  deleteFile,
  template,
  downloadFilePreview,
  fileId,
  uploadFileAction,
  files = [],
  readonly = false,
  ocrEnabled = false,
  showNotification,
  trackingEvents,
  allowedFileExtensions = defaultAllowedFileExtensions,
  fileSubject,
  contractNumber,
  isFullscreen = false,
  onDownloadContract,
  contractDownloadUrl,
}: DropzoneProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloadingContract, setIsDownloadingContract] = useState(false);
  const dispatch = useDispatch();

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      uploadFile({
        files: acceptedFiles,
        rejectedFiles,
        fileId,
        uploadFileAction,
        ocrEnabled,
        callbackStatus: setIsUploading,
        showNotification,
      });

      if (trackingEvents?.trackUpload) {
        const { category, name } = trackingEvents.trackUpload;
        piwikHelpers.trackEvent(category, name);
      }
    },
    [fileId]
  );

  const { getRootProps, getInputProps, open, isDragAccept } = useDropzone({
    multiple: false,
    onDrop,
    noClick: true,
    noDragEventsBubbling: true,
    maxSize: 8e6, // 8MB
    accept: allowedFileExtensions,
  });
  const isEmpty = files.length === 0;
  const emptyStateTitle = t(
    `expenses.upload.dropzone.${template === 'contractFile' ? 'contracts_title' : 'title'}`
  );
  const attachUrlWithParams = (id: string, token: string) => (file: File & { id: number }) => ({
    ...file,
    // @ts-ignore TODO: 'path[template]' -> implicit 'any'; remove when paths.js is refactored to ts
    url: urlWithParams(paths[template](id, file.id), {
      access_token: token,
    }),
  });

  const handleContractDownload = () => {
    if (!onDownloadContract) return;
    setIsDownloadingContract(true);
    // TODO: Tracking TBD
    // const { category, name } = trackingEvents.trackContractDownload;
    // piwikHelpers.trackEvent(category, name);
    onDownloadContract(fileId).then(() => {
      setIsDownloadingContract(false);
      dispatch(showNotification(downloadContractSuccess));
    });
  };

  return (
    <div className={styles.main}>
      <If ok={!readonly}>
        <div {...getRootProps()} className={styles.wrapper}>
          <If ok={isUploading}>
            <Loading />
          </If>
          <If ok={!isUploading}>
            <div
              className={cx(styles.previewContainer, styles.dropzone, {
                [styles.isActive]: isDragAccept,
              })}
              {...omit(getRootProps(), ['onClick'])}
            >
              <input {...getInputProps()} data-id="Dropzone:input" />
              <If ok={isEmpty}>
                <EmptyStateBanner
                  ocrEnabled={ocrEnabled}
                  onNewFileButtonClick={open}
                  title={emptyStateTitle}
                  isImport
                />
              </If>
              <If ok={!isEmpty}>
                <PreviewContainer
                  contractId={fileId}
                  accessToken={accessToken}
                  files={files}
                  readonly={readonly}
                  downloadFilePreview={downloadFilePreview}
                  deleteFile={deleteFile}
                  attachUrlWithParams={attachUrlWithParams}
                  fileNameGenerator={fileNameGenerator(template)}
                  onNewFileButtonClick={open}
                  trackingEvents={trackingEvents}
                  fileSubject={fileSubject}
                  contractNumber={contractNumber}
                  isFullscreen={isFullscreen}
                  onDownloadContractClick={handleContractDownload}
                  contractDownloadUrl={contractDownloadUrl}
                  isDownloadingContract={isDownloadingContract}
                />
              </If>
            </div>
          </If>
        </div>
      </If>
      <If ok={readonly}>
        {isEmpty ? (
          <div className={cx(styles.previewContainer, styles.dropzone)}>
            <ReadonlyEmptyStateBanner />
          </div>
        ) : (
          <div className={styles.previewContainer}>
            <PreviewContainer
              attachUrlWithParams={attachUrlWithParams}
              contractId={fileId}
              accessToken={accessToken}
              files={files}
              downloadFilePreview={downloadFilePreview}
              deleteFile={deleteFile}
              fileNameGenerator={fileNameGenerator(template)}
              readonly={readonly}
              onNewFileButtonClick={open}
              trackingEvents={trackingEvents}
              fileSubject={fileSubject}
              contractNumber={contractNumber}
              isFullscreen={isFullscreen}
              onDownloadContractClick={handleContractDownload}
              contractDownloadUrl={contractDownloadUrl}
              isDownloadingContract={isDownloadingContract}
            />
          </div>
        )}
      </If>
    </div>
  );
};

export default memo(Dropzone);
