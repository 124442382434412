import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { ONBOARDING_TILE_CLOSE, ONBOARDING_TILE_DO_NOT_SHOW_AGAIN } from 'constants/piwik';
import paths from 'routes/paths';
import { getEmail, getFirstName, getIsFromVrso, getLastName } from 'selectors/profile';
import { useAppDispatch, useAppSelector } from 'shared/hooks/app';
import { t } from 'shared/utils';
import {
  getFinishedAllSteps,
  getIsLoaded,
  getOnboardingDismissed,
  getVrsoContractId,
} from 'store/slices/onboarding/selectors';
import { fetchOnboarding, updateOnboarding } from 'store/slices/onboarding/thunks';
import { actions } from 'store/slices/welcomeTile/reducer';
import Box from 'redesign/components/atoms/Box/Box';
import Checkbox from 'redesign/components/atoms/Checkbox/Checkbox';
import IconClose from 'redesign/components/atoms/Icons/Close';

import {
  Button,
  Close,
  Content,
  PictureWelcome,
  Text,
  Title,
  Wrapper,
} from './OnboardingTile.styled';
import { trackEvent } from './utils';

type OnboardingTileProps = {
  className?: string;
};

const OnboardingTile = ({ className }: OnboardingTileProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const email = useAppSelector(getEmail);
  const firstName = useAppSelector(getFirstName);
  const lastName = useAppSelector(getLastName);
  const isVrsoUser = useAppSelector(getIsFromVrso);
  const vrsoContractId = useAppSelector(getVrsoContractId);
  const onboardingDismissed = useAppSelector(getOnboardingDismissed);
  const hasFinishedAllSteps = useAppSelector(getFinishedAllSteps);
  const isLoaded = useAppSelector(getIsLoaded);

  const handleClose = useCallback(() => {
    setIsOpen(false);

    if (isChecked) {
      dispatch(updateOnboarding({ onboarding_dismissed: true }));
      dispatch(actions.add(email));
      trackEvent(ONBOARDING_TILE_DO_NOT_SHOW_AGAIN);
    } else {
      trackEvent(ONBOARDING_TILE_CLOSE);
    }
  }, [dispatch, isChecked, email]);

  const handleCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  }, []);

  const handleButtonClick = useCallback(() => {
    history.push(paths.viewExpenseContract(vrsoContractId, 'loan'));
  }, [history, vrsoContractId]);

  useEffect(() => {
    dispatch(fetchOnboarding());
  }, [dispatch]);

  if (!isLoaded || onboardingDismissed || !isOpen || hasFinishedAllSteps) return null;

  return (
    <Box data-id="onboarding-tile" className={className}>
      <Wrapper>
        <Close>
          <label>
            <Checkbox checked={isChecked} onChange={handleCheckboxChange} data-id="checkbox" />
            {t('features.homepage.onboarding_tile.do_not_show_again')}
          </label>
          <IconClose width={32} height={32} onClick={handleClose} data-id="close" />
        </Close>
        <Content>
          <Title data-id="title">
            {t('features.homepage.onboarding_tile.header')}, {firstName} {lastName}!
          </Title>
          <Text>
            <p data-id="text-1">{t('features.homepage.onboarding_tile.text_1')}</p>
            {isVrsoUser && (
              <>
                <p data-id="text-2">{t('features.homepage.onboarding_tile.text_2')}</p>
                <p data-id="text-3">{t('features.homepage.onboarding_tile.text_3')}</p>
                <Button disabled={!vrsoContractId} onClick={handleButtonClick} data-id="contract">
                  {t('features.homepage.onboarding_tile.button')}
                </Button>
                <span data-id="text-4">{t('features.homepage.onboarding_tile.text_4')}</span>
              </>
            )}
          </Text>
          <PictureWelcome />
        </Content>
      </Wrapper>
    </Box>
  );
};

export default memo(OnboardingTile);
