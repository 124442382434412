import React, { Fragment } from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';
import { bool, number, string } from 'prop-types';

import PremiumPreviewLogo from 'images/premium-logo.svg';
import { t } from 'shared/utils';

import PriceContainer from './components/PriceContainer';

import styles from './Header.module.css';

const PremiumTitle = () => (
  <Fragment>
    <span>{t('profile.payment_plan.comparison_box.title.premium')}</span>
    <InlineSvg src={PremiumPreviewLogo} className={styles.premiumIcon} />
  </Fragment>
);

const title = {
  basic: t('profile.payment_plan.comparison_box.title.basic'),
  premium: <PremiumTitle />,
};

const Header = ({ currentPlan, isTable = false, defaultPrice }) => (
  <div className={cx(styles.header, isTable ? styles.directionColumn : styles.directionRow)}>
    <span className={styles.title}>{title[currentPlan]}</span>
    <span className={styles.pricing}>
      <PriceContainer defaultPrice={defaultPrice} />
    </span>
  </div>
);

Header.propTypes = {
  currentPlan: string.isRequired,
  isTable: bool,
  defaultPrice: number,
};

export default Header;
