import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { deleteSupplier, sortSuppliers } from 'actions/suppliers';
import { MODELS } from 'constants/contacts';
import { DE_SUPPLIER_DELETED, DE_SUPPLIER_MANAGEMENT } from 'constants/suppliers';
import TableButtons from 'containers/TableButtons/TableButtons';
import tableStyles from 'shared/styles/table.module.css';
import { piwikHelpers } from 'shared/utils/piwik';
import I18n from 'components/I18n';
import { ConfirmationModal } from 'components/Modal';
import { EmptyState, TableHeader } from 'components/Table';

import SupplierRow from './SupplierRow';

import localStyles from './SuppliersTable.module.css';

export const styles = { ...tableStyles, ...localStyles };

class SuppliersTable extends Component {
  state = {
    deletingItem: undefined,
  };

  onClearConfirmations = () => {
    this.setState({ deletingItem: undefined });
  };
  onDelete = (supplier) => {
    this.onClearConfirmations();
    const { refresh, remove } = this.props;
    remove(supplier).then(() => refresh());
  };

  onRequestDelete = (invoice) => {
    this.setState({ deletingItem: invoice });
  };

  fields = ['company_name', 'city', 'honorific', 'first_name', 'last_name', 'id_number', 'actions'];
  sortableFields = ['company_name', 'city', 'honorific', 'first_name', 'last_name', 'id_number'];

  render() {
    const { isFetching, data, sorting, sort, refresh } = this.props;
    if (!data.length) {
      return (
        <EmptyState isFetching={isFetching} id="empty-suppliers-table">
          <TableButtons whichFolder={MODELS.SUPPLIER} />
        </EmptyState>
      );
    }

    return (
      <div className={styles.invoicesTableWrapper}>
        <table
          className={cx(styles.invoicesTable, { [styles.invoicesTableLoading]: isFetching })}
          data-id="table"
        >
          <thead>
            <tr>
              {this.fields.map((field) => (
                <TableHeader
                  key={field}
                  columnWidthClass={styles.column}
                  refresh={refresh}
                  sort={sort}
                  column={field}
                  sortable={this.sortableFields.includes(field)}
                  currentSorting={sorting}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {data.length &&
              data.map((supplier, supplierIndex) => (
                <SupplierRow
                  key={supplier.id}
                  supplier={supplier}
                  onDelete={this.onRequestDelete}
                  index={supplierIndex}
                />
              ))}
          </tbody>
        </table>
        <ConfirmationModal
          dangerousAction
          isOpen={!!this.state.deletingItem}
          onClose={this.onClearConfirmations}
          onConfirm={() => {
            piwikHelpers.trackEvent(DE_SUPPLIER_MANAGEMENT, DE_SUPPLIER_DELETED);
            this.onDelete(this.state.deletingItem);
          }}
        >
          <I18n t="suppliers.confirm_delete" paragraphs />
        </ConfirmationModal>
      </div>
    );
  }
}

SuppliersTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sorting: PropTypes.shape({}).isRequired,
  isFetching: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  sort: PropTypes.func.isRequired,
};

export default connect(null, { sortSuppliers, deleteSupplier })(SuppliersTable);
