import styled from 'styled-components';

import BaseBox from 'redesign/components/atoms/Box/Box';
import BaseIconCashbooks from 'redesign/components/atoms/Icons/Cashbooks';
import { Breakpoints } from 'redesign/styles/breakpoints';

export const Box = styled(BaseBox)`
  height: 451px;
  padding: ${({ theme }) =>
    `${theme.space['6']} ${theme.space['2']} ${theme.space['4']} ${theme.space['2']}`};
  display: grid;
  grid-template-rows: 1fr auto;
`;

export const TableWrapper = styled.div`
  overflow-y: auto;

  th[data-id='header-actions'] {
    width: 36px;
  }

  img {
    display: block;
    width: 24px;
    height: 24px;
  }

  @media (min-width: ${Breakpoints.sm}) {
    th[data-id='header-actions'] {
      width: 52px;
    }

    th[data-id='header-image'] {
      width: 56px;
    }
  }
`;

export const IconCashbooks = styled(BaseIconCashbooks)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.vrblue['100']};
`;

export const Summary = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 2px solid ${({ theme }) => theme.colors.grey['070']};
  padding: ${({ theme }) => `${theme.space['2']} 44px 0 ${theme.space['2']}`};

  span:first-child {
    color: ${({ theme }) => theme.colors.grey['080']};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: bold;
    letter-spacing: 0.67px;
    line-height: 19px;
  }

  @media (min-width: ${Breakpoints.sm}) {
    padding: ${({ theme }) => `${theme.space['4']} 68px 0 ${theme.space['4']}`};
  }
`;
