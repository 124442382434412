import { compose } from 'recompose';
import { combineReducers } from 'redux';
import { actionTypes } from 'redux-form';

import { PRODUCT_CATALOG_ITEMS } from 'constants/product-catalog/items';
import { getResourceReducer } from 'reducers/common/resource';
import {
  createFilteredReducer,
  formatAmount,
  normalizeCurrency,
  parseCurrency,
} from 'shared/utils';
import { toGrossValue, toNetValue } from 'shared/utils/money';

import { FieldNames } from './constants';

const { netName, parsedNetName, grossName, parsedGrossName } = FieldNames;
const adjustCurrency = compose(normalizeCurrency, parseCurrency, formatAmount);

export const parseProductCatalogItems = ({ response }) =>
  response.data.map((x) => ({ id: x.id, ...x.attributes }));

export default combineReducers({
  [PRODUCT_CATALOG_ITEMS]: createFilteredReducer(
    getResourceReducer(),
    (action) => action.name === PRODUCT_CATALOG_ITEMS
  ),
});

const onPriceChange =
  ([sourceFieldName, computedFieldName, computedParsedFieldName] = [], vat) =>
  (value) => {
    if (isNaN(value) || value === '') {
      return;
    }
    const computedValue = Number(
      (sourceFieldName === 'netAmount' ? toGrossValue(value, vat) : toNetValue(value, vat)).toFixed(
        3
      )
    );

    return {
      [sourceFieldName]: value,
      [computedFieldName]: computedValue,
      [computedParsedFieldName]: computedValue !== '' ? computedValue : '',
    };
  };

const handleNetAmountChange = ({ payload, vat }) =>
  onPriceChange([netName, grossName, parsedGrossName], vat)(payload);

const handleGrossAmountChange = ({ payload, vat }) =>
  onPriceChange([grossName, netName, parsedNetName], vat)(payload);

const handleVatChange = ({ payload, netAmount, grossAmount, insertedAsGross }) =>
  insertedAsGross
    ? onPriceChange([grossName, netName, parsedNetName], payload)(grossAmount)
    : onPriceChange([netName, grossName, parsedGrossName], payload)(netAmount);

const handleInsertedAsGrossChange = ({ payload, netAmount, vat, grossAmount }) =>
  payload
    ? onPriceChange([grossName, netName, parsedNetName], vat)(adjustCurrency(grossAmount))
    : onPriceChange([netName, grossName, parsedGrossName], vat)(adjustCurrency(netAmount));

const keyHandlerMap = {
  netAmount: handleNetAmountChange,
  grossAmount: handleGrossAmountChange,
  vat: handleVatChange,
  insertedAsGross: handleInsertedAsGrossChange,
};

export const catalogReduxFormReducer = (state, { payload, type, meta = {} }) => {
  switch (type) {
    case actionTypes.CHANGE:
      const { vat, insertedAsGross, netAmount, grossAmount } = (state && state.values) || {};
      const callable = keyHandlerMap[meta.field];

      if (callable) {
        return {
          ...state,
          values: {
            ...state.values,
            ...callable({
              payload,
              netAmount,
              grossAmount,
              vat,
              insertedAsGross,
            }),
          },
        };
      }
    // falls through
    default:
      return state;
  }
};
