import React from 'react';
import { connect } from 'react-redux';
import { func, shape, string } from 'prop-types';

import { rejectConnectionSuggestion } from 'actions/bank-transfer-connections';
import { OUTGOING_INVOICE } from 'constants/folder-names';
import { incomingInvoiceSelector, outgoingInvoiceSelector } from 'reducers/form';
import { incomingInvoiceDetailsSelector } from 'selectors/incomingInvoice';
import SuggestionsComponent from 'components/Suggestions/Suggestions';

const SuggestionsOutgoing = connect(
  (state) => ({
    invoiceId: outgoingInvoiceSelector(state, 'id'),
    fullyPaid: outgoingInvoiceSelector(state, 'paid'),
    suggestions: state.outgoingInvoice.details.suggestedUnassignedBankTransfers,
  }),
  { rejectConnectionSuggestion }
)(SuggestionsComponent);

const SuggestionsIncoming = connect(
  (state) => ({
    invoiceId: incomingInvoiceSelector(state, 'id'),
    fullyPaid: incomingInvoiceSelector(state, 'paid'),
    suggestions: incomingInvoiceDetailsSelector(state).suggestedUnassignedBankTransfers,
  }),
  { rejectConnectionSuggestion }
)(SuggestionsComponent);

const Suggestions = ({ handleSuggestionSelect, assigmentModeData, whichFolder }) => {
  if (whichFolder === OUTGOING_INVOICE) {
    return (
      <SuggestionsOutgoing
        assigmentModeData={assigmentModeData}
        handleSuggestionSelect={handleSuggestionSelect}
      />
    );
  }

  return (
    <SuggestionsIncoming
      assigmentModeData={assigmentModeData}
      handleSuggestionSelect={handleSuggestionSelect}
    />
  );
};

Suggestions.propTypes = {
  handleSuggestionSelect: func.isRequired,
  assigmentModeData: shape({}),
  whichFolder: string.isRequired,
};

export default Suggestions;
