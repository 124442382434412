export enum Interval {
  Weekly = 'weekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Yearly = 'yearly',
}

export type RecurringTransactionInterval = {
  id: string;
  name: Interval;
};
