import * as Yup from 'yup';

import { t } from 'shared/utils';

export default Yup.object({
  taxationType: Yup.object().required(),
  fiscalYearBeginning: Yup.object().nullable(),
  taxationInterval: Yup.object().required(),
  accountChart: Yup.object().required(),
  taxConsultantId: Yup.string()
    .nullable()
    .matches(/.{4,}/, {
      excludeEmptyString: true,
      message: t('profile.tax_consultant.tax_consultant_id_message'),
    })
    .max(7, t('profile.tax_consultant.tax_consultant_id_message')),
  taxConsultantClientId: Yup.string().nullable(),
  taxConsultantEmail: Yup.string().email().nullable(),
}).required();
