import styled from 'styled-components';

import media from 'shared/styles/breakpoints';
import BaseIconArrowDown from 'components/Icons/IconArrowDown';
import BaseIconCancel from 'components/Icons/IconCancel';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  color: #0066b3;
  outline: none;
  font-weight: bold;
  padding: 0;

  &:disabled {
    opacity: 0.5;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  > * {
    margin-left: 15px;
    margin-bottom: 15px;
  }

  ${media.greaterThan('sm-up')`
    > * {
      margin-bottom: 0;
    }
  `}
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;

  > * {
    margin-right: 15px;
  }
`;

type IconArrowDownProps = {
  $isRotated?: boolean;
};

export const IconArrowDown = styled(BaseIconArrowDown)<IconArrowDownProps>`
  width: 10px;
  height: 6px;
  margin-right: 8px;

  ${({ $isRotated }) => $isRotated && 'transform: rotate(180deg)'};
`;

export const IconCancel = styled(BaseIconCancel)`
  width: 10px;
  height: 10px;
  margin-right: 8px;
`;

export const GlobalFilter = styled.div`
  margin-bottom: 15px;
  max-width: 400px;
`;
