import normalize from 'json-api-normalizer';
import { find, omit } from 'lodash';
import build from 'redux-object';

import {
  ACCEPT_SUGGESTION_SUCCESS,
  CREATE_SUCCESS,
  DELETE_SUCCESS,
} from 'constants/bank-transfer-connections';
import { CLEAR_ASSIGNED_BANK_TRANSFERS, FETCH_SUCCESS } from 'constants/incoming-invoice';
import { TOGGLE_CREDIT_NOTE_SUCCESS } from 'constants/incoming-invoices';
import { toMoment } from 'shared/utils';

const defaultState = [];

export const mapBankTransfer = (i) => ({
  ...omit(i.attributes, ['date']),
  date: toMoment(i.attributes.date),
  id: i.id,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case CREATE_SUCCESS:
    case DELETE_SUCCESS:
    case FETCH_SUCCESS:
      if (!action.response.included) return [];
      const [head] =
        build(normalize(action.response), 'incomingInvoices', undefined, {
          eager: true,
          ignoreLinks: true,
        }) || [];

      const response = head || {};

      if (!response.bankTransfers) return response.bankTransfers;

      return response.bankTransfers.map((bankTransfer) => {
        const transferConnection = find(
          response.bankTransferConnections,
          ({ bankTransferId }) => bankTransferId.toString() === bankTransfer.id
        );

        return {
          ...bankTransfer,
          transferConnection: transferConnection || null,
        };
      });

    case TOGGLE_CREDIT_NOTE_SUCCESS: {
      return defaultState;
    }
    case ACCEPT_SUGGESTION_SUCCESS:
      return [...state, mapBankTransfer(action.response.data)];

    case CLEAR_ASSIGNED_BANK_TRANSFERS:
      return [];

    default:
      return state;
  }
};
