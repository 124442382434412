import React, { memo, SVGProps } from 'react';

const WarningBulb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.8189 24C19.4463 24 24.8189 18.6274 24.8189 12C24.8189 5.37258 19.4463 0 12.8189 0C6.19148 0 0.818901 5.37258 0.818901 12C0.818901 18.6274 6.19148 24 12.8189 24Z"
      fill="#FF6600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1755 4.53675C13.8275 4.3483 15.3541 4.89532 16.4724 5.89218C17.6052 6.90192 18.3189 8.37405 18.3189 10.0136C18.3189 11.5097 17.7231 12.8646 16.7574 13.8565C16.0633 14.5691 15.6344 16.1922 15.4769 16.8298L10.5876 17.5274C10.1163 15.9111 9.56501 14.616 8.75837 13.7316C7.72746 12.6013 7.15923 11.0392 7.35819 9.34627C7.50543 8.09277 8.08314 6.96151 8.93766 6.10823C9.79259 5.25453 10.924 4.67967 12.1755 4.53675Z"
      fill="#FAFAFA"
      stroke="#FAFAFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3189 20H11.3189C11.0428 20 10.8189 19.7761 10.8189 19.5C10.8189 19.2239 11.0428 19 11.3189 19H14.3189C14.595 19 14.8189 19.2239 14.8189 19.5C14.8189 19.7761 14.595 20 14.3189 20Z"
      fill="#FAFAFA"
    />
    <path d="M12.8189 17V11.8479L14.8189 10" stroke="#FF6600" strokeLinecap="round" />
    <path d="M12.8189 12L10.8189 10" stroke="#FF6600" strokeLinecap="round" />
  </svg>
);

export default memo(WarningBulb);
