import { CALL_API } from 'constants/api';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  FETCH_BANK_ACCOUNTS_FAILURE,
  FETCH_BANK_ACCOUNTS_REQUEST,
  FETCH_BANK_ACCOUNTS_SUCCESS,
  GET_SCA_FAILURE,
  GET_SCA_REQUEST,
  GET_SCA_SUCCESS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SUCCESS,
  INIT_FAILURE,
  INIT_REQUEST,
  INIT_SUCCESS,
  NEW_FAILURE,
  NEW_REQUEST,
  NEW_SUCCESS,
  SELECT_SCA_FAILURE,
  SELECT_SCA_REQUEST,
  SELECT_SCA_SUCCESS,
  SOLVE_SCA_FAILURE,
  SOLVE_SCA_REQUEST,
  SOLVE_SCA_SUCCESS,
} from 'constants/incoming-invoice/payments';
import { pf } from 'shared/utils';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

export const apiInitPayment = (id, data) => ({
  [CALL_API]: {
    method: 'POST',
    data: { id, ...data, draft: false },
    endpoint: `/me/incoming_invoices_payments/init`,
    types: [INIT_REQUEST, INIT_SUCCESS, INIT_FAILURE],
  },
});

export const apiNewPayment = (incomingInvoiceId, data) => ({
  [CALL_API]: {
    method: 'POST',
    params: { incomingInvoiceId, ...data },
    endpoint: `/me/incoming_invoices_payments/new`,
    types: [NEW_REQUEST, NEW_SUCCESS, NEW_FAILURE],
  },
});

export const apiCreatePayment = (incomingInvoiceId, { accountId, figoPaymentId, credentials }) => ({
  [CALL_API]: {
    method: 'POST',
    data: {
      incoming_invoice_id: incomingInvoiceId,
      reuse_auth_method: false,
      account_id: accountId,
      figo_payment_id: figoPaymentId,
      credentials,
    },
    omitProcessParams: true,
    endpoint: `/me/incoming_invoices_payments/initiate`,
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
});

export const apiFetchPayments = (invoiceId) => ({
  [CALL_API]: {
    method: 'GET',
    endpoint: `/me/incoming_invoices/${invoiceId}/payments`,
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
  },
});

export const apiFetchPaymentsBankAccounts = () => ({
  [CALL_API]: {
    method: 'GET',
    endpoint: `/me/incoming_invoices_payments/accounts`,
    types: [FETCH_BANK_ACCOUNTS_REQUEST, FETCH_BANK_ACCOUNTS_SUCCESS, FETCH_BANK_ACCOUNTS_FAILURE],
  },
});

const extractValues = (values) => {
  const data = {
    ...values,
    supplierId: values.supplier?.id,
    discountPercentage: `${values.discountPercentage}`.includes(',')
      ? pf(values.discountPercentage)
      : parseFloat(values.discountPercentage) || null,
  };

  // We have remove supplier from data because it's an object used in a supplier field.
  delete data.supplier;

  return data;
};

export const initPayment = (id, data) => (dispatch) =>
  bindServerValidation(
    apiInitPayment(id, extractValues(data)),
    dispatch,
    {
      isReduxForm: true,
    },
    { supplier_id: { id: 'supplier' } }
  ).catch(apiErrorHandler);

export const newPayment =
  (...args) =>
  (dispatch) =>
    bindServerValidation(apiNewPayment(...args), dispatch, {
      isReduxForm: true,
    }).catch(apiErrorHandler);

export const createPayment =
  (...args) =>
  (dispatch) =>
    bindServerValidation(apiCreatePayment(...args), dispatch, {
      isReduxForm: true,
    }).catch(apiErrorHandler);

export const fetchPayments =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchPayments(...args)).catch(apiErrorHandler);

export const fetchPaymentsBankAccounts =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchPaymentsBankAccounts(...args)).catch(apiErrorHandler);

const apiGetSca = ({ syncId, paymentFigoId, accountFigoId }) => ({
  [CALL_API]: {
    method: 'GET',
    params: { syncId, paymentFigoId, accountFigoId },
    endpoint: `/me/incoming_invoices_payments/sca`,
    types: [GET_SCA_REQUEST, GET_SCA_SUCCESS, GET_SCA_FAILURE],
  },
});

/**
 * Get information about queued payment.
 * Payment could be completed or could be awaiting for authorization.
 * @param {*} { syncId, paymentFigoId, accountFigoId }
 */
export const getSca =
  ({ syncId, paymentFigoId, accountFigoId }) =>
  (dispatch) =>
    bindServerValidation(apiGetSca({ syncId, paymentFigoId, accountFigoId }), dispatch, {
      isReduxForm: false,
    }).catch(apiErrorHandler);

const apiSelectScaChallenge = ({
  methodId,
  accountFigoId,
  paymentFigoId,
  syncId,
  challengeId,
}) => ({
  [CALL_API]: {
    method: 'POST',
    params: {
      methodId,
      accountFigoId,
      paymentFigoId,
      syncId,
      challengeId,
    },
    endpoint: `/me/incoming_invoices_payments/select_sca_challenge`,
    types: [SELECT_SCA_REQUEST, SELECT_SCA_SUCCESS, SELECT_SCA_FAILURE],
  },
});

/**
 * Select which challenge we want to use for the given Figo payment.
 * @param {*} { methodId, accountFigoId, paymentFigoId, syncId, challengeId }
 */
export const selectScaChallenge =
  ({ methodId, accountFigoId, paymentFigoId, syncId, challengeId }) =>
  (dispatch) =>
    bindServerValidation(
      apiSelectScaChallenge({
        methodId,
        accountFigoId,
        paymentFigoId,
        syncId,
        challengeId,
      }),
      dispatch,
      {
        isReduxForm: true,
      }
    ).catch(apiErrorHandler);

const apiSolveScaChallenge = ({
  accountFigoId,
  paymentFigoId,
  syncId,
  challengeId,
  challengeResponse,
}) => ({
  [CALL_API]: {
    method: 'POST',
    params: {
      accountFigoId,
      paymentFigoId,
      syncId,
      challengeId,
      challengeResponse,
    },
    endpoint: `/me/incoming_invoices_payments/solve_sca_challenge`,
    types: [SOLVE_SCA_REQUEST, SOLVE_SCA_SUCCESS, SOLVE_SCA_FAILURE],
  },
});

/**
 * Send solved sca challenge to Figo.
 * @param {*} {  accountFigoId, paymentFigoId, syncId, challengeId, challengeResponse }
 */
export const solveScaChallenge =
  ({ accountFigoId, paymentFigoId, syncId, challengeId, challengeResponse }) =>
  (dispatch) =>
    bindServerValidation(
      apiSolveScaChallenge({
        accountFigoId,
        paymentFigoId,
        syncId,
        challengeId,
        challengeResponse,
      }),
      dispatch,
      {
        isReduxForm: true,
      }
    ).catch((error) => apiErrorHandler(error, { shouldThrow: true }));
