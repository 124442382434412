import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import Downshift from 'downshift-legacy';
import { compact, debounce, isString, sortBy } from 'lodash';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { fetchProductCatalogItems } from 'actions/product-catalog/items/items';
import { parseProductCatalogItems } from 'reducers/product-catalog/items';
import { formatMoney, noop, t } from 'shared/utils';
import inputStyles from 'components/Form/TextField/TextField.module.css';
import I18n from 'components/I18n';

import styles from '../Selects.module.css';

const SORT_ORDER = ['itemNumber', 'name', 'description', 'internalNotes', 'unit', 'netAmount'];

export class LineItemPositionSelect extends Component {
  static defaultProps = {
    onPositionSelect: noop,
    onValueChange: noop,
    placeholder: t('forms.placeholders.select'),
    initialValue: '',
    disabled: false,
    isLabelHidden: false,
    isInsertedAsGross: false,
    onClick: noop,
    onChange: noop,
  };

  state = {
    items: [],
    inputValue: '',
    isFetchingItems: false,
    pagination: {
      page: 1,
      perPage: 5,
      totalPages: 1,
    },
    filters: { fullTextSearch: '' },
  };

  componentDidMount() {
    const { initialValue } = this.props;
    this.getItems();

    if (initialValue) {
      this.handleInputChange(initialValue);
    }
  }

  getItems = async () => {
    this.setState({ isFetchingItems: true });

    const { pagination, filters } = this.state;
    const { fetchItems } = this.props;

    const { response, headers } = await fetchItems({ pagination, parsedFilters: filters });
    const totalPages = parseInt(headers['total-pages'], 10);

    this.setState({
      items: parseProductCatalogItems({ response }),
      isFetchingItems: false,
      pagination: { ...this.state.pagination, totalPages },
    });
  };

  getMoreItems = async () => {
    this.setState({ isFetchingItems: true });

    const {
      pagination: { page, ...restPaginationProps },
      filters,
    } = this.state;
    const { fetchItems } = this.props;

    const { response, headers } = await fetchItems({
      pagination: { page: page + 1, ...restPaginationProps },
      filters,
    });
    const newPagination = {
      page: parseInt(headers.page, 10),
      totalPages: parseInt(headers['total-pages'], 10),
    };

    this.setState({
      items: [...this.state.items, ...parseProductCatalogItems({ response })],
      isFetchingItems: false,
      pagination: { ...this.state.pagination, ...newPagination },
    });
  };

  _handleFilterChange = (name) => {
    const filters = { fullTextSearch: name };
    this.setState({ filters }, () => this.handlePaginationChange({ page: 1 }));
  };

  handleFilterChange = debounce(this._handleFilterChange, 200);

  handlePaginationChange = (pagination) => {
    if (this.state.isFetchingItems) return;

    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
      },
      this.getItems
    );
  };

  handleInputChange = (inputValue) => {
    this.props.onValueChange(inputValue);
    this.setState({ inputValue }, this.handleFilterChange(inputValue));
  };

  getOptionTitle = (item) => {
    const number = item.itemNumber
      ? `${t('forms.line_items.position_select.number_short')}: ${item.itemNumber}`
      : '';

    const textsToDisplay = [item.name, number, item.description];

    return compact(textsToDisplay).join(', ');
  };

  render() {
    const {
      productCatalogGroupItems = [],
      visible,
      className,
      placeholder,
      disabled,
      inputClassName,
      label,
      labelClassName,
      name,
      invalid,
      required,
      isLabelHidden,
      isInsertedAsGross,
      onClick,
      onChange: onAdditionalChange,
      dataId,
    } = this.props;
    const { pagination, items } = this.state;
    const allItems = sortBy(items, SORT_ORDER)
      .map((item) => ({
        ...item,
        selected: Boolean(
          productCatalogGroupItems.find((selectedGroupItem) => item.id === selectedGroupItem.id)
        ),
        label: item.name,
        value: item.id,
      }))
      .filter((item) => !item.selected);
    const { page, totalPages } = pagination;
    const isLastPage = page === totalPages;
    const onChange = (item) => {
      this.props.onPositionSelect(item.id);
      onAdditionalChange();
    };
    const fieldLabel = label || placeholder;

    if (!visible) return null;

    return (
      <div className={cx(styles.main, className)}>
        <Downshift
          onOuterClick={noop}
          itemToString={(item) => (isString(item) ? item : (item && item.name) || '')}
          onChange={onChange}
          defaultInputValue={this.props.initialValue}
          defaultSelectedItem={this.props.initialValue}
          onSelect={({ label: selectedLabel }) => this.handleInputChange(selectedLabel)}
          onInputValueChange={(value) => this.handleInputChange(value)}
          selectedItem={this.state.inputValue}
        >
          {({ getInputProps, getItemProps, isOpen, highlightedIndex, openMenu, getMenuProps }) => (
            <div>
              <div className={cx(inputStyles.wrapper, className, styles.visible)}>
                <input
                  {...getInputProps({
                    onBlur: (e) => e.preventDefault(),
                    onFocus: openMenu,
                  })}
                  data-id={dataId}
                  name={name}
                  className={cx(
                    inputStyles.main,
                    inputStyles.withoutLabel,
                    inputClassName,
                    styles.visible,
                    {
                      [styles.invalid]: invalid,
                      [inputStyles.mainFilled]: !!this.state.inputValue,
                    }
                  )}
                  placeholder={required && fieldLabel ? `${fieldLabel} *` : fieldLabel}
                  disabled={disabled}
                  onClick={onClick}
                  autoComplete="off"
                />
                {!isLabelHidden && (
                  <label
                    htmlFor={name}
                    className={cx(inputStyles.label, labelClassName, {
                      [styles.invalid]: invalid,
                    })}
                  >
                    <span className={inputStyles.labelContent}>
                      {required ? `${fieldLabel} *` : fieldLabel}
                    </span>
                  </label>
                )}
              </div>
              {isOpen ? (
                <div className={styles.items} {...getMenuProps()} tabIndex="-1">
                  {allItems.map((item, index) => {
                    const amount = isInsertedAsGross ? item.grossAmount : item.netAmount;

                    const texts = {
                      title: this.getOptionTitle(item),
                      subtitle: item.internalNotes,
                      price: `${formatMoney(amount)} / ${item.unit}`,
                    };

                    return (
                      <div
                        {...getItemProps({
                          key: item.id,
                          index,
                          item,
                        })}
                        className={cx(styles.item, styles.positionItem, {
                          [styles.highlighted]: highlightedIndex === index,
                        })}
                        key={index}
                      >
                        <div className={styles.contentContainer}>
                          <div className={styles.contentContainerLeftColumn}>
                            <div className={styles.optionTitle} title={texts.title}>
                              {texts.title}
                            </div>
                            {item.internalNotes && (
                              <div title={texts.subtitle} className={styles.optionSubtitle}>
                                {texts.subtitle}
                              </div>
                            )}
                          </div>
                          <div className={styles.optionPrice}>{texts.price}</div>
                        </div>
                      </div>
                    );
                  })}
                  <button
                    className={cx(styles.fetchNext, {
                      [styles.hidden]: isLastPage || totalPages === 0,
                    })}
                    onClick={(event) => {
                      event.preventDefault();
                      this.getMoreItems();
                    }}
                    tabIndex="-1"
                  >
                    <I18n t="expenses.form.fetch_next_bank_transfers" />
                  </button>
                  {totalPages === 0 && (
                    <div className={styles.noItems}>
                      <I18n className={styles.noItemsText} t="expenses.form.empty_bank_transfers" />
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

LineItemPositionSelect.propTypes = {
  invalid: bool,
  fetchItems: func,
  name: string,
  dataId: string,
  visible: bool,
  onPositionSelect: func,
  onValueChange: func,
  className: string,
  placeholder: string,
  disabled: bool,
  initialValue: string,
  inputClassName: string,
  label: string,
  labelClassName: string,
  productCatalogGroupItems: arrayOf(shape({})).isRequired,
  required: bool,
  isLabelHidden: bool,
  isInsertedAsGross: bool,
  onClick: func,
  onChange: func,
};

const mapDispatchToProps = (dispatch) => ({
  fetchItems: (...args) => dispatch(fetchProductCatalogItems(...args)),
});

export default connect(null, mapDispatchToProps)(LineItemPositionSelect);
