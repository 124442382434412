import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState, name } from './constants';
import { DateRangePickerId, StateSlice } from './types';

const slice = createSlice({
  name,
  initialState: initialState as StateSlice,
  reducers: {
    createDateRangePicker: (
      state: StateSlice,
      action: PayloadAction<{ id: DateRangePickerId }>
    ) => {
      if (!state.find((item) => item.id === action.payload.id)) {
        state.push({ id: action.payload.id, isValid: true });
      }
    },
    setIsValid: (state: StateSlice, action: PayloadAction<{ id: string; isValid: boolean }>) => {
      const index = state.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) state[index].isValid = action.payload.isValid;
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
