import styled from 'styled-components';

export const Tooltip = styled.div`
  padding: ${({ theme }) => theme.space['2']};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.black};
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.4);
  font-size: ${({ theme }) => theme.fontSize['sm']};
  letter-spacing: 0.25px;
  line-height: 17px;
  z-index: 10;
  width: 300px;
`;

export const Icon = styled.div`
  width: ${({ theme }) => theme.space['6']};
  height: ${({ theme }) => theme.space['6']};
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  &:hover {
    z-index: 5;
  }
`;
