import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { cashbookEnabledHelper } from 'routes/accesses';

import paths from '../../routes/paths';
import Cashbooks from './Cashbooks';
import { Page } from './Cashbooks.styled';
import Creator from './Creator/Creator';

const Routes = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const isCashbookEnabled = useSelector(cashbookEnabledHelper);

  if (!match) {
    return null;
  }

  if (!isCashbookEnabled) {
    history.push(paths.home);
    return null;
  }

  return (
    <Page>
      <Switch>
        <Route exact path={match.path}>
          <Cashbooks />
        </Route>
        <Route exact path={`${match.path}/new`}>
          <Creator />
        </Route>
      </Switch>
    </Page>
  );
};

export default Routes;
