import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatISO } from 'date-fns';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { adapter } from './adapter';
import { name } from './constants';
import { Entry, StateSlice } from './types';

const slice = createSlice({
  name,
  initialState: adapter.getInitialState(),
  reducers: {
    add: (state: StateSlice, action: PayloadAction<Pick<Entry, 'id' | 'email'>>) => {
      adapter.upsertOne(state, {
        ...action.payload,
        date: formatISO(new Date(), { representation: 'date' }),
      });
    },
  },
});

const persistConfig = {
  key: 'globalNotifications',
  version: 1,
  storage,
};

export const actions = slice.actions;
export const reducer = persistReducer(persistConfig, slice.reducer);
