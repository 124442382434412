import React from 'react';

import styles from './HiddenAutofillInput.module.css';

interface HiddenAutofillInputProps {
  name: string;
}

/**
 * Component is used to display an input field that will be prefilled by Chrome.
 * It is also absolutely positioned, because of that it is not visible.
 */

const HiddenAutofillInput = ({ name, ...rest }: HiddenAutofillInputProps) => (
  <input name={name} className={styles.hiddenInput} {...rest} tabIndex={-1} />
);

export default HiddenAutofillInput;
