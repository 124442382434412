import styled from 'styled-components';

import media from 'shared/styles/breakpoints';

import { Button } from './shared.styled';

export const PreviewButton = styled(Button)`
  ${media.greaterThan('xlg-up')`
    display: none;
  `}
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-areas: 'fields';

  ${media.greaterThan('xlg-up')`
    grid-template-areas: 'fields preview';
    grid-column-gap: 30px;
    grid-template-columns: minmax(auto, 500px) 1fr;
    align-items: start;
  `}
`;

export const Fields = styled.div`
  max-width: 500px;
  grid-area: fields;
`;

export const Preview = styled.div`
  position: relative;
  grid-area: preview;
  justify-self: start;

  canvas {
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 3px 3px 7px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
`;
