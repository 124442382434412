import { CALL_API } from 'constants/api';
import {
  FETCH_SUMS_FAILURE,
  FETCH_SUMS_REQUEST,
  FETCH_SUMS_SUCCESS,
} from 'constants/outgoing-invoice';
import { apiErrorHandler } from 'shared/utils/error-handlers';

export const apiFetchOutgoingInvoiceSums = (invoiceId) => ({
  [CALL_API]: {
    endpoint: `/me/outgoing_invoices/${invoiceId}`,
    types: [FETCH_SUMS_REQUEST, FETCH_SUMS_SUCCESS, FETCH_SUMS_FAILURE],
  },
});

export const fetchOutgoingInvoiceSums =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchOutgoingInvoiceSums(...args)).catch(apiErrorHandler);
