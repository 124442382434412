import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import { acceptReceiveOffers, rejectReceiveOffers } from 'actions/registration';
import styles from 'containers/RegistrationForm/Form/RegistrationForm.module.css';
import { t } from 'shared/utils';
import { AppDispatch } from 'store';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import CardView, { HeadingSection, Section } from 'components/CardView';
import I18n from 'components/I18n';

type ReceiveOffersProps = {
  accept: () => void;
  reject: () => void;
};

const ReceiveOffers = ({ accept, reject }: ReceiveOffersProps) => {
  const textSrc = '/content/receive-offers';

  return (
    <CardView className={styles.card}>
      <HeadingSection className={styles.cardSectionHeading}>
        <h3 className={styles.headingText}>
          <I18n t="registration.receive_offers.title" />
        </h3>
      </HeadingSection>
      <Section className={cx(styles.cardSection, styles.contentSection)}>
        <div className={styles.tos}>
          <iframe src={textSrc} title="receive-offers" />
        </div>
      </Section>
      <ActionPanel>
        <ActionButton
          appearance="primary"
          label={t('registration.receive_offers.agree')}
          onClick={accept}
        />
        <ActionButton
          appearance="outlined"
          label={t('registration.receive_offers.disagree')}
          onClick={reject}
          dataId="button-cancel-offers"
        />
      </ActionPanel>
    </CardView>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  accept: () => dispatch(acceptReceiveOffers()),
  reject: () => dispatch(rejectReceiveOffers()),
});

export default connect(null, mapDispatchToProps)(ReceiveOffers);
