import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import { clearDraftId } from 'actions/incoming-invoice';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import { ButtonAppearances } from 'components/Button';

import styles from './AbortButton.module.css';

const AbortButton = ({ goToDashboard, trackAbort }) => (
  <div className={styles.transparentContainer}>
    <ActionButton
      fullWidth
      appearance={ButtonAppearances.outlined}
      onClick={() => {
        trackAbort();
        goToDashboard();
      }}
      label={t('suppliers.form.abort')}
      dataId="button-abort"
    />
  </div>
);

AbortButton.propTypes = {
  goToDashboard: PropTypes.func.isRequired,
  trackAbort: PropTypes.func,
};

export default connect(null, (dispatch) => ({
  goToDashboard: () => {
    dispatch(push(paths.suppliers));
  },
  discardData: () => {
    clearDraftId();
    dispatch(push(paths.suppliers));
  },
}))(AbortButton);
