import React, { FunctionComponent } from 'react';
import cx from 'classnames';

import isPressedEnter from 'shared/utils/keyboard-events';

import styles from './Label.module.css';

interface LabelProps {
  dataId?: string;
  className?: string;
  onClick?: () => void;
}

const Label: FunctionComponent<LabelProps> = ({
  dataId = '',
  children,
  className = '',
  onClick,
}) => {
  if (onClick) {
    return (
      <div
        data-id={dataId}
        role="button"
        tabIndex={0}
        className={cx(styles.container, styles.clickable, className)}
        onClick={onClick}
        onKeyPress={isPressedEnter(onClick)}
      >
        {children}
      </div>
    );
  }

  return (
    <p data-id={dataId} className={cx(styles.container, className)}>
      {children}
    </p>
  );
};

export { Label };
