import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import { fetchProductCatalogGroups, setPagination } from 'actions/product-catalog/groups/groups';
import { clickCreateProductCatalogGroup as clickCreateProductCatalogGroupAction } from 'actions/product-catalog/groups/piwik';
import { PRODUCT_CATALOG_GROUPS } from 'constants/product-catalog/groups';
import { makeGetList } from 'reducers/common/resource';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { Body, Header } from 'components/Card';
import I18n from 'components/I18n';

import ProductCatalogGroupsTable, { NewProductCatalogGroupButton } from './common/Table';

import localStyles from '../ProductCatalog.module.css';

export const translatedIntervalName = (interval) =>
  t(`recurring_expenses.interval_options.${interval.name}`);

class ProductCatalogGroups extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    fetchProductCatalogGroups: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired,
    isEmpty: PropTypes.bool,
    clickCreateProductCatalogGroup: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.setPagination({ page: 1 });
    this.props.fetchProductCatalogGroups();
  }

  createEntity = () => {
    this.props.push(paths.newProductCatalogGroup);
    this.props.clickCreateProductCatalogGroup();
  };

  render() {
    const { isEmpty } = this.props;
    return (
      <div className={localStyles.card}>
        <Header className={localStyles.header}>
          <div className={localStyles.row}>
            <I18n t="product_catalog.product_catalog_groups.header.index" />
            {!isEmpty && <NewProductCatalogGroupButton onClick={this.createEntity} />}
          </div>
        </Header>
        <Body withMargin>
          <ProductCatalogGroupsTable />
        </Body>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getList = makeGetList();

  return (state) => ({
    isEmpty: Boolean(
      !getList(state.productCatalogGroups[PRODUCT_CATALOG_GROUPS], PRODUCT_CATALOG_GROUPS).length
    ),
  });
};

const mapDispatchToProps = {
  push,
  setPagination,
  fetchProductCatalogGroups,
  clickCreateProductCatalogGroup: clickCreateProductCatalogGroupAction,
};

const enhance = connect(makeMapStateToProps, mapDispatchToProps);

export default enhance(ProductCatalogGroups);
