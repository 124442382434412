import React from 'react';
import InlineSvg from 'react-inlinesvg';
import cx from 'classnames';
import { find } from 'lodash';
import moment from 'moment';
import { arrayOf, bool, func, number, shape } from 'prop-types';

import { DATE_FORMAT } from 'constants/datetime';
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'constants/notifications-sidebar';
import iconCancel from 'images/icon-cancel.svg';
import iconTrash from 'images/icon-trash--blue.svg';
import withViewport from 'shared/hoc/withViewport';

import messageNamespaceStyles from '../MessageNamespaceStyles.module.css';
import styles from './MessageDetails.module.css';

const MessageDetails = ({
  handleClose,
  isLgUp,
  activeMessageId,
  messages,
  deleteMessage,
  fetchCustomerMessages,
}) => {
  const activeMessage = activeMessageId ? find(messages, { id: activeMessageId }) : messages[0];
  const { id, releaseDate, category, headline, content } = activeMessage;
  const formattedReleaseDate = moment(releaseDate).format(DATE_FORMAT);

  const handleDelete = () => deleteMessage(id);
  return (
    <div
      className={cx(
        styles.container,
        messageNamespaceStyles.messageNamespace,
        IGNORE_OUTSIDE_CLICK_CLASS,
        {
          [styles.open]: !!activeMessageId,
          [styles.fullScreen]: !isLgUp,
        }
      )}
    >
      <article className={styles.message}>
        <div className={styles.actions}>
          <button
            className={styles.button}
            onClick={() => {
              handleDelete().then(handleClose()).then(fetchCustomerMessages);
            }}
          >
            <InlineSvg className={styles.trashIcon} src={iconTrash} />
          </button>
          <button className={styles.button} onClick={() => handleClose()}>
            <InlineSvg src={iconCancel} />
          </button>
        </div>
        <span className={styles.releaseDate}>{formattedReleaseDate}</span>
        <span className={styles.category}>{category}</span>
        <h1 className={styles.title}>{headline}</h1>
        <section className={styles.content} dangerouslySetInnerHTML={{ __html: content }}></section>
      </article>
    </div>
  );
};

MessageDetails.propTypes = {
  handleClose: func.isRequired,
  isLgUp: bool.isRequired,
  activeMessageId: number,
  messages: arrayOf(shape({})),
  deleteMessage: func.isRequired,
  fetchCustomerMessages: func.isRequired,
};

export default withViewport(MessageDetails);
