import find from 'lodash/find';
import get from 'lodash/get';

export default function parseIncomingInvoice(data, included) {
  const relationshipKey = get(data, 'relationships.persisted-supplier.data')
    ? 'persisted-supplier'
    : 'supplier';

  const relationshipId = get(data, `relationships.${relationshipKey}.data.id`);
  const relationshipType = get(data, `relationships.${relationshipKey}.data.type`);

  const relationship = find(
    included,
    (supplier) => supplier.id === relationshipId && supplier.type === relationshipType
  );

  return {
    ...data.attributes,
    creditNote: data.attributes.creditNote,
    [relationshipKey]: relationship.attributes,
  };
}
