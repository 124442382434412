import React, { useCallback, useState } from 'react';
import InlineSvg from 'react-inlinesvg';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { OBS_CLOSE_MODAL, OBS_REQUEST_NOW } from 'constants/piwik';
import checkmarkIcon from 'images/obs-modal/list-checkmark.svg';
import { isOBSEnabled } from 'routes/accesses';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import CardView, { HeadingSection } from 'components/CardView';
import OBSIframeModal from 'components/OBS/OBSIframeModal/OBSIframeModal';

import Collapsable from './Collapsable/Collapsable';
import {
  Button,
  CheckMark,
  CollapsHeadline,
  CollapsSubHeadline,
  Description,
  Headline,
  Hint,
  Iframe,
  LinkContainer,
  ListItem,
  RedirectionInformation,
  RedirectionText,
  SectionContainer,
  SectionLeft,
  SectionRight,
  SubHeadline,
  UnorderedList,
  Wrapper,
} from './Obs.styled';
import { trackEvent } from './utils';

const Obs = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isObsEnabled = useSelector(isOBSEnabled);
  const [isCanceled, setIsCanceled] = useState<boolean>(false);
  const history = useHistory();
  const dataIds = {
    container: 'OBS:iframe-modal-container',
    closeButton: 'OBS:iframe-modal-close',
  };

  const featuresItems = [
    t('features.financial_planning.obs.description_items.item_1.text'),
    t('features.financial_planning.obs.description_items.item_2.text'),
    t('features.financial_planning.obs.description_items.item_3.text'),
    t('features.financial_planning.obs.description_items.item_4.text'),
  ];
  const optionItems = [
    t('features.financial_planning.obs.more_information_items.item_1.text'),
    t('features.financial_planning.obs.more_information_items.item_2.text'),
    t('features.financial_planning.obs.more_information_items.item_3.text'),
    t('features.financial_planning.obs.more_information_items.item_4.text'),
    t('features.financial_planning.obs.more_information_items.item_5.text'),
  ];

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    setIsCanceled(false);
    trackEvent(OBS_CLOSE_MODAL);
  }, [isOpen, isCanceled]);

  const handleTriggerCancelPopup = useCallback(() => {
    setIsCanceled((isCanceled) => !isCanceled);
  }, [isCanceled]);

  const handleClick = useCallback(() => {
    setIsOpen(true);
    trackEvent(OBS_REQUEST_NOW);
  }, [isOpen]);

  if (!isObsEnabled) {
    history.push(paths.home);
    return null;
  }

  return (
    <Wrapper>
      <CardView>
        <HeadingSection bold={true}>
          {t('features.financial_planning.obs.page_headline')}
        </HeadingSection>
        <SectionContainer>
          <SectionLeft>
            <Headline>{t('features.financial_planning.obs.headline')}</Headline>
            <SubHeadline>{t('features.financial_planning.obs.sub_headline')}</SubHeadline>
            <Description>
              {t('features.financial_planning.obs.description_1')}
              {t('features.financial_planning.obs.description_2')}
            </Description>
            <div>
              <ul>
                {featuresItems.map((text, index) => (
                  <li key={index}>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <CheckMark>
                          <InlineSvg src={checkmarkIcon} />
                        </CheckMark>
                      </div>
                      <div>
                        <p>{text}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <Collapsable title={t('features.financial_planning.obs.more_information.header')}>
              <CollapsHeadline>
                {t('features.financial_planning.obs.more_information.headline')}
              </CollapsHeadline>
              <CollapsSubHeadline>
                {t('features.financial_planning.obs.more_information.sub_headline')}
              </CollapsSubHeadline>
              <br />
              <div>
                <UnorderedList>
                  {optionItems.map((text, index) => (
                    <ListItem key={index}>
                      <p>{text}</p>
                    </ListItem>
                  ))}
                </UnorderedList>
              </div>
              <Hint>{t('features.financial_planning.obs.hint')}</Hint>
            </Collapsable>
          </SectionLeft>
          <SectionRight>
            <Iframe src="https://onlinefinanzierung.vr-smart-finanz.de/teaser.html" title="OBS" />
            <LinkContainer>
              <Button onClick={handleClick} data-id="ask-for-loan">
                {t('features.financial_planning.obs.request_button')}
              </Button>
            </LinkContainer>
            <RedirectionInformation>
              <RedirectionText>
                {t('features.financial_planning.obs.redirect_information')}
              </RedirectionText>
            </RedirectionInformation>
          </SectionRight>
        </SectionContainer>
      </CardView>
      <OBSIframeModal
        isOpen={isOpen}
        onTriggerCancelPopup={handleTriggerCancelPopup}
        isCanceled={isCanceled}
        onCancel={handleCancel}
        dataIds={dataIds}
      />
    </Wrapper>
  );
};

export default Obs;
