import { CALL_API } from 'constants/api';
import {
  ACCESSES_FAILURE,
  ACCESSES_REQUEST,
  ACCESSES_SUCCESS,
  CONNECT_ACCOUNT_FAILURE,
  CONNECT_ACCOUNT_REQUEST,
  CONNECT_ACCOUNT_SUCCESS,
  CUSTOMER_BANKS_FAILURE,
  CUSTOMER_BANKS_REQUEST,
  CUSTOMER_BANKS_SUCCESS,
  DETAILS_FAILURE,
  DETAILS_REQUEST,
  DETAILS_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  GET_SCA_FAILURE,
  GET_SCA_REQUEST,
  GET_SCA_SUCCESS,
  INDEX_ACCOUNTS_FAILURE,
  // @FIX (rpunkfu): move to separate file
  INDEX_ACCOUNTS_REQUEST,
  INDEX_ACCOUNTS_SUCCESS,
  INDEX_FAILURE,
  INDEX_REQUEST,
  INDEX_SUCCESS,
  INITIALIZE_SCA_FAILURE,
  INITIALIZE_SCA_REQUEST,
  INITIALIZE_SCA_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  PRE_LOGIN_FAILURE,
  // NEW
  PRE_LOGIN_REQUEST,
  PRE_LOGIN_SUCCESS,
  SELECT_SCA_FAILURE,
  SELECT_SCA_REQUEST,
  SELECT_SCA_SUCCESS,
  SOLVE_SCA_FAILURE,
  SOLVE_SCA_REQUEST,
  SOLVE_SCA_SUCCESS,
} from 'constants/banks';
import { apiErrorHandler } from 'shared/utils/error-handlers';
import { bindServerValidation } from 'shared/utils/server-validation';

const apiIndex = (pagination = {}, filter = {}) => ({
  [CALL_API]: {
    params: { ...filter, ...pagination },
    endpoint: '/banks',
    types: [INDEX_REQUEST, INDEX_SUCCESS, INDEX_FAILURE],
  },
});

const apiFetchBank = (id) => ({
  [CALL_API]: {
    endpoint: `/banks/${id}`,
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

const apiLoginToBank = (data) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: '/banks/login',
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
  },
});

const apiIndexBankAccounts = (id) => ({
  [CALL_API]: {
    endpoint: `/banks/${id}/accounts`,
    types: [INDEX_ACCOUNTS_REQUEST, INDEX_ACCOUNTS_SUCCESS, INDEX_ACCOUNTS_FAILURE],
  },
});

const apiConnectBankAccount = (id, accountId, accessMethodType) => ({
  [CALL_API]: {
    method: 'POST',
    data: { accountId, accessMethodType },
    endpoint: `/banks/${id}/connect_account`,
    types: [CONNECT_ACCOUNT_REQUEST, CONNECT_ACCOUNT_SUCCESS, CONNECT_ACCOUNT_FAILURE],
  },
});

const apiFetchBankDetails = (iban) => ({
  [CALL_API]: {
    endpoint: `/banks/bank_details`,
    params: { iban },
    types: [DETAILS_REQUEST, DETAILS_SUCCESS, DETAILS_FAILURE],
  },
});

export const indexBanks =
  (...args) =>
  (dispatch) =>
    dispatch(apiIndex(...args)).catch(apiErrorHandler);

export const fetchBank =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchBank(...args));

export const loginToBank =
  ({ credentials, bankCode, savePin, disableFirstSync = false }, shouldCatch = true) =>
  (dispatch) => {
    const withValidation = bindServerValidation(
      apiLoginToBank({
        credentials,
        bankCode,
        savePin,
        disableFirstSync,
      }),
      dispatch,
      {
        isReduxForm: false,
        customTitle: 'notifications.banks.login_failure.title',
      }
    );

    if (shouldCatch) {
      return withValidation.catch(apiErrorHandler);
    }

    return withValidation;
  };

export const indexBankAccounts =
  (...args) =>
  (dispatch) =>
    dispatch(apiIndexBankAccounts(...args)).catch(apiErrorHandler);

export const fetchBankDetails =
  (...args) =>
  (dispatch) =>
    dispatch(apiFetchBankDetails(...args))
      .then(({ data }) => data)
      .catch(apiErrorHandler);

export const connectBankAccount =
  (...args) =>
  (dispatch) =>
    dispatch(apiConnectBankAccount(...args));

const apiPreLoginToBank = ({ credentials, bankCode, savePin }) => {
  return {
    [CALL_API]: {
      data: {
        credentials,
        bank_code: bankCode,
        save_pin: savePin,
      },
      omitProcessParams: true,
      method: 'POST',
      endpoint: '/banks/prelogin',
      types: [PRE_LOGIN_REQUEST, PRE_LOGIN_SUCCESS, PRE_LOGIN_FAILURE],
    },
  };
};

export const preLoginToBank =
  ({ credentials, bankCode, savePin }) =>
  (dispatch) =>
    dispatch(
      apiPreLoginToBank({
        credentials,
        bankCode,
        savePin,
      })
    );

const apiGetBankAccess = ({ credentials, bankCode, savePin, accessType, bankAccountID }) => ({
  [CALL_API]: {
    data: {
      credentials,
      bank_code: bankCode,
      save_pin: savePin,
      access_type: accessType,
      bank_account_id: bankAccountID,
    },
    omitProcessParams: true,
    method: 'PUT',
    endpoint: '/banks/accesses',
    types: [ACCESSES_REQUEST, ACCESSES_SUCCESS, ACCESSES_FAILURE],
  },
});

export const getBankAccess =
  ({ credentials, bankCode, savePin, accessMethodType, bankAccountID }) =>
  (dispatch) =>
    dispatch(
      apiGetBankAccess({
        credentials,
        bankCode,
        savePin,
        accessType: accessMethodType,
        bankAccountID,
      })
    );

const apiSynchronizeSavePin = (data) => ({
  [CALL_API]: {
    data,
    method: 'PUT',
    endpoint: '/banks/customer_banks',
    types: [CUSTOMER_BANKS_REQUEST, CUSTOMER_BANKS_SUCCESS, CUSTOMER_BANKS_FAILURE],
  },
});

export const synchronizeSavePin = () => (dispatch) =>
  bindServerValidation(apiSynchronizeSavePin(), dispatch, { isReduxForm: false });

const apiInitializeSca = ({ credentials, bankCode, savePin, accessFigoId, scope }) => ({
  [CALL_API]: {
    data: {
      credentials,
      bank_code: bankCode,
      save_pin: savePin,
      access_figo_id: accessFigoId,
      scope,
    },
    omitProcessParams: true,
    method: 'POST',
    endpoint: '/banks/sca',
    types: [INITIALIZE_SCA_REQUEST, INITIALIZE_SCA_SUCCESS, INITIALIZE_SCA_FAILURE],
  },
});

export const initializeSca =
  ({ credentials, bankCode, savePin, figo_id, scope = [] }) =>
  (dispatch) =>
    dispatch(
      apiInitializeSca({
        credentials,
        bankCode,
        savePin,
        accessFigoId: figo_id,
        scope,
      })
    );

const apiGetSca = (data) => ({
  [CALL_API]: {
    params: data,
    endpoint: '/banks/sca',
    types: [GET_SCA_REQUEST, GET_SCA_SUCCESS, GET_SCA_FAILURE],
  },
});

export const getSca = (data) => (dispatch) => dispatch(apiGetSca(data));

const apiSelectSca = (data) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: '/banks/select_sca_challenge',
    types: [SELECT_SCA_REQUEST, SELECT_SCA_SUCCESS, SELECT_SCA_FAILURE],
  },
});

export const selectSca = (data) => (dispatch) => dispatch(apiSelectSca(data));

const apiSolveSca = (data) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: '/banks/solve_sca_challenge',
    types: [SOLVE_SCA_REQUEST, SOLVE_SCA_SUCCESS, SOLVE_SCA_FAILURE],
  },
});

export const solveSca = (data) => (dispatch) => dispatch(apiSolveSca(data));
