import { paginationParams, sortingParams } from 'actions/helpers/table';
import { CALL_API } from 'constants/api';
import {
  FETCH_ALL_TRANSFERS_FAILURE,
  FETCH_ALL_TRANSFERS_REQUEST,
  FETCH_ALL_TRANSFERS_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  INDEX_SORT,
  NAMESPACE,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from 'constants/bank-transfer';
import {
  CLEAR_FILTERS,
  FilterTypes,
  QuickFilters,
  SET_DATE_RANGE,
  SET_QUICK_FILTER,
} from 'constants/common/filters';
import { apiErrorHandler } from 'shared/utils/error-handlers';

import * as actions from './index';

export const apiFetchBankTransfers = (id, pagination, sorting, filters) => ({
  [CALL_API]: {
    params: {
      ...paginationParams(pagination),
      ...sortingParams(sorting),
      filters,
    },
    endpoint: `/me/outgoing_invoices/${id}/available_bank_transfers`,
    types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
  },
});

export const apiFetchMoreBankTransfers = (id, { page } = {}, sorting, filters) => ({
  [CALL_API]: {
    params: {
      ...paginationParams({ page: page + 1 }),
      ...sortingParams(sorting),
      filters,
    },
    endpoint: `/me/outgoing_invoices/${id}/available_bank_transfers`,
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
});

export const sortBankTransfers = (column) => (dispatch) =>
  dispatch({
    type: INDEX_SORT,
    column,
  });

export const fetchBankTransfers = (id, pagination, sorting, filters) => (dispatch) =>
  dispatch(apiFetchBankTransfers(id, pagination, sorting, filters)).catch(apiErrorHandler);

export const fetchMoreBankTransfers = (id, pagination, sorting, filters) => (dispatch) =>
  dispatch(apiFetchMoreBankTransfers(id, pagination, sorting, filters)).catch(apiErrorHandler);

export const setQuickFilter = (value) => {
  const { WITH_ANY_INVOICE_SUGGESTIONS, STATUS } = FilterTypes;
  const filterType = value === QuickFilters.SUGGESTIONS ? WITH_ANY_INVOICE_SUGGESTIONS : STATUS;
  return {
    type: SET_QUICK_FILTER,
    name: NAMESPACE,
    payload: {
      [filterType]: value,
    },
  };
};

export const setQueryParam = actions.setQueryParam({ name: NAMESPACE });

export const setDateRange = (param) => (dispatch) => (values) =>
  dispatch({
    type: SET_DATE_RANGE,
    name: NAMESPACE,
    payload: {
      param,
      values,
    },
  });

export const clearSearchFilters = ({ clearStatus = false } = {}) => ({
  type: CLEAR_FILTERS,
  name: NAMESPACE,
  payload: {
    clearStatus,
  },
});

export const apiFetchAllBankTransfers = (pagination, sorting, filters) => ({
  [CALL_API]: {
    params: { ...pagination, ...sortingParams(sorting), filters },
    endpoint: `/me/bank_transfers`,
    types: [FETCH_ALL_TRANSFERS_REQUEST, FETCH_ALL_TRANSFERS_SUCCESS, FETCH_ALL_TRANSFERS_FAILURE],
  },
});

export const fetchAllBankTransfers = (_, pagination, currentSorting, filters) => (dispatch) =>
  dispatch(apiFetchAllBankTransfers(pagination, currentSorting, filters)).catch(apiErrorHandler);
