import React from 'react';
import { string } from 'prop-types';

import styles from './CurrentPlanBadge.module.css';

const CurrentPlanBadge = ({ label }) => <div className={styles.badge}>{label}</div>;

CurrentPlanBadge.propTypes = {
  label: string.isRequired,
};

export default CurrentPlanBadge;
