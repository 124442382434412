import { JsonApiResponseSingle } from 'json-api-normalizer';

import api from '../index';

export const getOnboarding = () => {
  const url = '/me/onboarding';

  return api.get<JsonApiResponseSingle>(url);
};

export type UpdateOnboardingData = {
  onboarding_dismissed?: boolean;
  obs_tooltip_dismissed?: boolean;
  welcome_tile_dismissed?: boolean;
  contracts_onboarding_dismissed?: boolean;
  visited_invoice_templates?: boolean;
  visited_task_planner?: boolean;
};

export const updateOnboarding = (data: UpdateOnboardingData) => {
  const url = '/me/onboarding';

  return api.put<JsonApiResponseSingle>(url, data);
};
