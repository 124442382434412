import { PropTypes } from 'prop-types';
import { combineReducers } from 'redux';

import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/countries';

export const getCountryNameByCode =
  ({ data }) =>
  (code) => {
    const result = data.find((country) => country.code === code);
    return result ? result.name : null;
  };

export const shape = PropTypes.shape({
  isFetching: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
});

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return true;
    case FETCH_SUCCESS:
    case FETCH_FAILURE:
      return false;
    default:
      return state;
  }
};

const data = (state = [], action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return action.response.data.map((country) => ({ ...country.attributes }));
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  data,
});
