import React, { memo, ReactNode } from 'react';

import Hint, { Type } from 'components/Hint/Hint';

import Success from './Success';

import styles from './HintWithNoHelp.module.css';

type HintWithNoHelpProps = {
  children: ReactNode;
  dataId?: string;
};

const HintWithNoHelp = ({ children, dataId }: HintWithNoHelpProps) => (
  <Hint type={Type.Success}>
    <span className={styles.content} data-id={dataId}>
      <div className={styles.icon}>
        <Success />
      </div>
      <div className={styles.text}>{children}</div>
    </span>
  </Hint>
);

export default memo(HintWithNoHelp);
