import { JsonApiResponse } from 'json-api-normalizer';

import api from 'api';
import { CashTransaction } from 'types/entities/CashTransaction';

export const addCashTransaction = (
  data: Pick<
    CashTransaction,
    | 'transactionType'
    | 'transactionDate'
    | 'description'
    | 'cashbookId'
    | 'reasonForRetroactiveCreation'
    | 'dailyOrderId'
  > & {
    grossAmount?: number;
    invoiceLineCategoryId?: string;
    taxAmount?: number;
    documentNumber?: string;
    taxRateId?: string;
  },
  cashTransactionFiles: File[]
) => {
  const url = `/me/cashbooks/${data.cashbookId}/cash_transactions`;
  const formData = new FormData();

  if (data.documentNumber) {
    formData.append('document_number', data.documentNumber);
  }

  if (data.transactionType !== undefined) {
    formData.append('transaction_type', data.transactionType);
  }

  if (data.transactionDate !== undefined) {
    formData.append('transaction_date', data.transactionDate);
  }

  if (data.invoiceLineCategoryId !== undefined) {
    formData.append('invoice_line_category_id', data.invoiceLineCategoryId);
  }

  if (data.description !== undefined) {
    formData.append('description', data.description);
  }

  if (data.grossAmount !== undefined) {
    formData.append('gross_amount', String(data.grossAmount));
  }

  if (data.taxAmount !== undefined) {
    formData.append('tax_amount', String(data.taxAmount));
  }

  if (data.taxRateId !== undefined) {
    formData.append('tax_rate_id', data.taxRateId);
  }

  if (data.reasonForRetroactiveCreation !== undefined) {
    formData.append('reason_for_retroactive_creation', String(data.reasonForRetroactiveCreation));
  }

  if (data.dailyOrderId !== undefined) {
    formData.append('daily_order_id', String(data.dailyOrderId));
  }

  for (let i = 0; i < cashTransactionFiles.length; i++) {
    formData.append('cash_transaction_files[][file]', cashTransactionFiles[i]);
  }

  return api.post<JsonApiResponse>(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getCashTransactions = (
  cashbookId: string,
  data: {
    transactionDate?: string;
  } = {},
  pagination: {
    page?: number;
    paginationResource?: string;
  } = {}
) => {
  const url = `/me/cashbooks/${cashbookId}/cash_transactions`;

  return api.get<JsonApiResponse>(url, {
    params: {
      transaction_date: data.transactionDate,
      ...pagination,
    },
  });
};

export const getCashTransactionFiles = (cashbookId: string, cashTransactionId: string) => {
  const url = `/me/cashbooks/${cashbookId}/cash_transactions/${cashTransactionId}/files`;

  return api.get<JsonApiResponse>(url);
};

export const deleteCashTransaction = ({
  cashbookId,
  cashTransactionId,
  data,
}: {
  cashbookId: string;
  cashTransactionId: string;
  data: { reasonForDeletion: string };
}) => {
  const url = `/me/cashbooks/${cashbookId}/cash_transactions/${cashTransactionId}`;

  return api.delete<JsonApiResponse>(url, {
    data: {
      reason_for_deletion: data.reasonForDeletion,
    },
  });
};

export const correctCashTransaction = (
  data: Pick<
    CashTransaction,
    | 'transactionType'
    | 'transactionDate'
    | 'description'
    | 'cashbookId'
    | 'reasonForRetroactiveCreation'
    | 'reasonForDeletion'
    | 'dailyOrderId'
  > & {
    grossAmount?: number;
    invoiceLineCategoryId?: string;
    taxAmount?: number;
    documentNumber?: string | null;
    taxRateId?: string;
  },
  cashbookId: string,
  cashTransactionId: string,
  cashTransactionFiles: File[]
) => {
  const url = `/me/cashbooks/${cashbookId}/cash_transactions/${cashTransactionId}/correct`;
  const formData = new FormData();

  if (data.documentNumber) {
    formData.append('document_number', data.documentNumber);
  }

  if (data.transactionType !== undefined) {
    formData.append('transaction_type', data.transactionType);
  }

  if (data.transactionDate !== undefined) {
    formData.append('transaction_date', data.transactionDate);
  }

  if (data.invoiceLineCategoryId !== undefined) {
    formData.append('invoice_line_category_id', data.invoiceLineCategoryId);
  }

  if (data.description !== undefined) {
    formData.append('description', data.description);
  }

  if (data.grossAmount !== undefined) {
    formData.append('gross_amount', String(data.grossAmount));
  }

  if (data.taxAmount !== undefined) {
    formData.append('tax_amount', String(data.taxAmount));
  }

  if (data.taxRateId !== undefined) {
    formData.append('tax_rate_id', data.taxRateId);
  }

  if (data.reasonForDeletion !== undefined) {
    formData.append('reason_for_deletion', String(data.reasonForDeletion));
  }

  if (data.reasonForRetroactiveCreation !== undefined) {
    formData.append('reason_for_retroactive_creation', String(data.reasonForRetroactiveCreation));
  }

  if (data.dailyOrderId !== undefined) {
    formData.append('daily_order_id', String(data.dailyOrderId));
  }

  for (let i = 0; i < cashTransactionFiles.length; i++) {
    formData.append('cash_transaction_files[][file]', cashTransactionFiles[i]);
  }

  return api.put<JsonApiResponse>(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
