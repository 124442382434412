import api from 'api';

export type GetOutgoingInvoiceData = {
  salutation_honorific?: string;
  salutation_content?: string;
  personal_notes?: string;
  header_version?: number;
  table_version?: number;
  footer_version?: number;
  multipage_document?: boolean;
  font_family?: string;
  font_size_address?: number;
  font_size_invoice_info?: number;
  font_size_subject?: number;
  font_size_table_content?: number;
  font_size_table_headers?: number;
  font_size_table_description?: number;
  font_size_notes?: number;
  font_size_footer?: number;
  watermark?: boolean;
  coloured_elements_hex?: string;
  invert_coloured_elements_text_color?: boolean;
  table_header_description_text?: string;
  table_header_net_amount_text?: string;
  table_header_position_text?: string;
  table_header_quantity_text?: string;
  table_header_total_line_net_amount_text?: string;
  table_header_unit_text?: string;
  table_header_vat_text?: string;
  table_summary_gross_total_text?: string;
  table_summary_net_total_text?: string;
  logo_size_percentage?: number;
  logo_position?: 'left' | 'center' | 'right';
  logo_on_all_pages?: boolean;
  background_only_on_first_page?: boolean;
};

export const getOutgoingInvoice = (params?: GetOutgoingInvoiceData) => {
  const url = '/me/previews/outgoing_invoice';

  return api.get<ArrayBuffer>(url, {
    params,
    responseType: 'arraybuffer',
  });
};
