import React from 'react';
import cx from 'classnames';
import { bool, string } from 'prop-types';

import Tooltip from 'redesign/components/molecules/Tooltip/Tooltip';

import styles from './InfoBox.module.css';

const InfoBox = ({ label, arrowPosition, isFullHeight }) => (
  <div
    className={cx(styles.infoBox, {
      [styles.arrowLeft]: arrowPosition === 'left',
      [styles.fullHeight]: isFullHeight,
    })}
  >
    <div className={styles.icon}>
      <Tooltip />
    </div>
    {label}
  </div>
);

InfoBox.propTypes = {
  label: string.isRequired,
  arrowPosition: string,
  isFullHeight: bool,
};

export default InfoBox;
