import React from 'react';
import PropTypes from 'prop-types';

import { InfoModal } from 'components/Modal';

import styles from './Legals.module.css';

/* eslint-disable react/no-danger */
const LegalsComponent = ({ title, isOpen, onClose, isModal, body, slug }) => {
  const dataIds = {
    modal: `LegalsFooter:modal-${slug}`,
    acceptButton: 'LegalsFooter:modal-accept-button',
  };

  if (isModal)
    return (
      <InfoModal
        header={title}
        isOpen={isOpen}
        onClose={onClose}
        dataIds={dataIds}
        customClass="footer-contents"
      >
        <div
          aria-label={title}
          dangerouslySetInnerHTML={{ __html: body }}
          className={styles.contentWrapper}
        />
      </InfoModal>
    );

  return (
    <div
      aria-label={title}
      data-id="LegalsFooter:non-modal-content"
      dangerouslySetInnerHTML={{ __html: body }}
      className={styles.contentWrapper}
    />
  );
};
/* eslint-enable react/no-danger */

LegalsComponent.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isModal: PropTypes.bool,
  slug: PropTypes.string,
};

export default LegalsComponent;
