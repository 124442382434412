import React, { Component } from 'react';
import cx from 'classnames';
import { func, number, string } from 'prop-types';

import { formatDate, formatMoney, t } from 'shared/utils';
import bankTransferStyles from 'components/BankTransfer/BankTransfer.module.css';
import BankTransferStatusBadge from 'components/BankTransferStatusBadge/BankTransferStatusBadge';

import localStyles from './SuggestedBankTransfer.module.css';

class SuggestedBankTransfer extends Component {
  state = {
    disabled: false,
  };

  handleOnReject = () => {
    this.setState({ disabled: true });
    this.props.onReject().then(() => {
      this.setState({ disabled: false });
    });
  };

  render() {
    const {
      amount,
      amountLeft,
      creditorName,
      currency,
      date,
      onAccept,
      onReject,
      purpose,
      status,
    } = this.props;

    return (
      <div
        className={cx(bankTransferStyles.suggestedTransfer, {
          [localStyles.disabled]: this.state.disabled,
        })}
      >
        <div className={bankTransferStyles.detailsSection}>
          <div className={bankTransferStyles.suggestedDate}>{formatDate(date)}</div>
          <div className={bankTransferStyles.title}>{purpose}</div>
          <div className={bankTransferStyles.suggestedCreditor}>{creditorName}</div>
        </div>
        <div className={bankTransferStyles.statusSection}>
          <div className={bankTransferStyles.transferStatus}>
            <BankTransferStatusBadge status={status} unusedAmount={amountLeft} />
          </div>
          <div className={bankTransferStyles.actionSection}>
            <div
              className={cx(bankTransferStyles.suggestedAmount, {
                [bankTransferStyles.amountNegative]: amount < 0,
              })}
            >
              {formatMoney(amount, currency)}
            </div>
            <div
              className={cx(bankTransferStyles.actionButtons, {
                [localStyles.hidden]: this.state.disabled,
              })}
            >
              {onAccept && (
                <button
                  type="button"
                  onClick={onAccept}
                  disabled={this.state.disabled}
                  className={bankTransferStyles.accept}
                  title={t('bank_transfers.select.actions.accept_suggestion')}
                />
              )}
              {onReject && (
                <button
                  type="button"
                  onClick={this.handleOnReject}
                  disabled={this.state.disabled}
                  className={bankTransferStyles.cancel}
                  title={t('bank_transfers.select.actions.reject_suggestion')}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SuggestedBankTransfer.propTypes = {
  amount: number.isRequired,
  amountLeft: number.isRequired,
  creditorName: string.isRequired,
  currency: string,
  date: string.isRequired,
  onAccept: func.isRequired,
  onReject: func.isRequired,
  purpose: string.isRequired,
  status: string.isRequired,
};

SuggestedBankTransfer.defaultProps = {
  currency: '',
};

export default SuggestedBankTransfer;
