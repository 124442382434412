import React from 'react';
import { element, func, oneOfType, shape } from 'prop-types';

import { MODELS } from 'constants/contacts';
import AccountAlreadyConfirmed from 'containers/AccountAlreadyConfirmed/AccountAlreadyConfirmed';
import AccountConfirmed from 'containers/AccountConfirmed/AccountConfirmed';
import Authenticator from 'containers/Authenticator';
import ClientPage from 'containers/Clients/ClientPage/ClientPage';
import Clients from 'containers/Clients/Clients';
import ConfirmationLinkOutdatedPublic from 'containers/ConfirmationLinkOutdatedPublic/ConfirmationLinkOutdatedPublic';
import Dashboard from 'containers/Dashboard';
import DatevCreator from 'containers/Datev/Creator';
import Datev from 'containers/Datev/Datev';
import DatevEditor from 'containers/Datev/Editor';
import ImportContacts from 'containers/ImportContacts';
import IncomingInvoices from 'containers/IncomingInvoices';
import IncomingInvoiceCancel from 'containers/IncomingInvoices/IncomingInvoiceCancel/IncomingInvoiceCancel';
import IncomingInvoiceEdit from 'containers/IncomingInvoices/IncomingInvoiceEdit';
import IncomingInvoiceNew from 'containers/IncomingInvoices/IncomingInvoiceNew';
import IncomingInvoiceShow from 'containers/IncomingInvoices/IncomingInvoiceShow';
import Revenue from 'containers/OutgoingInvoices';
import OutgoingInvoiceCancel from 'containers/OutgoingInvoices/OutgoingInvoiceCancel/OutgoingInvoiceCancel';
import OutgoingInvoiceCorrect from 'containers/OutgoingInvoices/OutgoingInvoiceCorrect';
import OutgoingInvoiceEdit from 'containers/OutgoingInvoices/OutgoingInvoiceEdit';
import OutgoingInvoiceImport from 'containers/OutgoingInvoices/OutgoingInvoiceImport';
import OutgoingInvoiceNew from 'containers/OutgoingInvoices/OutgoingInvoiceNew';
import OutgoingInvoiceShow from 'containers/OutgoingInvoices/OutgoingInvoiceShow';
import ReminderNew from 'containers/OutgoingInvoices/ReminderNew';
import ProductCatalog from 'containers/ProductCatalog';
import ProductCatalogGroups from 'containers/ProductCatalog/Groups';
import ProductCatalogGroupsEdit from 'containers/ProductCatalog/Groups/Edit';
import ProductCatalogGroupsNew from 'containers/ProductCatalog/Groups/New';
import ProductCatalogItems from 'containers/ProductCatalog/Items';
import ProductCatalogItemsEdit from 'containers/ProductCatalog/Items/Edit';
import ProductCatalogItemsNew from 'containers/ProductCatalog/Items/New';
import Farmpilot from 'containers/Profile/Farmpilot/Farmpilot';
import Profile from 'containers/Profile/Profile';
import Proposals from 'containers/Proposals';
import ProposalsEdit from 'containers/Proposals/ProposalsEdit';
import ProposalsNew from 'containers/Proposals/ProposalsNew';
import ProposalsShow from 'containers/Proposals/ProposalsShow';
import Suppliers from 'containers/Suppliers';
import SupplierCreator from 'containers/Suppliers/SupplierCreator';
import CompanyInfoRegistration from 'pages/CompanyRegistration/CompanyInfoRegistration';
import CompanySelectRegistration from 'pages/CompanyRegistration/CompanySelectRegistration';
import DatevDownloadPage from 'pages/DatevDownload';
import EmailTaken from 'pages/EmailTaken/EmailTaken';
import ForgotPassword from 'pages/ForgotPassword';
import InvalidTokenPage from 'pages/InvalidToken';
import LoginPage from 'pages/Login';
import Registration from 'pages/Registration/Registration';
import RegistrationVrso from 'pages/Registration/RegistrationVrso';
import RegistrationErrorPage from 'pages/RegistrationError';
import RegistrationsDeletion from 'pages/RegistrationsDeletion/RegistrationsDeletion';
import RegistrationsDeletionTokenInvalid from 'pages/RegistrationsDeletionTokenInvalid/RegistrationsDeletionTokenInvalid';
import RegistrationSuccess from 'pages/RegistrationSuccess/RegistrationSuccess';
import RequestRegistration from 'pages/RequestRegistration/RequestRegistration';
import ResetPassword from 'pages/ResetPassword';
import UnconfirmedUserPage from 'pages/UnconfirmedUserPage/UnconfirmedUserPage';
import BankTransfers from 'features/bankTransfers';
import Cashbooks from 'features/cashbooks';
import Contracts from 'features/contracts';
import DeliveryNotes from 'features/deliveryNotes/DeliveryNotes';
import DeliveryNotesEdit from 'features/deliveryNotes/DeliveryNotesEdit/DeliveryNotesEdit';
import DeliveryNotesNew from 'features/deliveryNotes/DeliveryNotesNew/DeliveryNotesNew';
import DeliveryNotesShow from 'features/deliveryNotes/DeliveryNotesShow/DeliveryNotesShow';
import EmailNotifications from 'features/emailNotifications';
import FinancialPlanning from 'features/financialPlanning';
import BankAccountConnected from 'features/landingPages/bankAccountConnected/BankAccountConnected';
import OrderConfirmations from 'features/orderConfirmation/OrderConfirmation';
import OrderConfirmationsCancel from 'features/orderConfirmation/OrderConfirmationCancel';
import OrderConfirmationsEdit from 'features/orderConfirmation/OrderConfirmationEdit/OrderConfirmationEdit';
import OrderConfirmationsNew from 'features/orderConfirmation/OrderConfirmationNew/OrderConfirmationNew';
import OrderConfirmationsShow from 'features/orderConfirmation/OrderConfirmationShow';
import VrsoLoginBlocked from 'features/registrations/vrso/VrsoLoginBlocked/VrsoLoginBlocked';
import VrsoResetPasswordBlocked from 'features/registrations/vrso/VrsoResetPasswordBlocked/VrsoResetPasswordBlocked';
import Settings from 'features/settings';

const RemountableComponent = ({ Component, ...props }) => (
  <Component key={props.match.params.id} {...props} />
);

RemountableComponent.propTypes = {
  Component: oneOfType([element, func]),
  match: shape({}),
};

/* eslint-disable react/display-name */
export default {
  login: LoginPage,
  unconfirmedUser: UnconfirmedUserPage,
  vrsoLoginBlocked: VrsoLoginBlocked,
  vrsoResetPasswordBlocked: VrsoResetPasswordBlocked,
  requestRegistration: RequestRegistration,
  companySelectRegistration: CompanySelectRegistration,
  companyInfoRegistration: CompanyInfoRegistration,
  emailTaken: EmailTaken,
  registrationSuccess: RegistrationSuccess,
  registration: Registration,
  registrationVrso: RegistrationVrso,
  registrationConfirmed: AccountConfirmed,
  accountAlreadyConfirmed: AccountAlreadyConfirmed,
  confirmationLinkOutdated: ConfirmationLinkOutdatedPublic,
  registrationError: RegistrationErrorPage,
  forgotPassword: ForgotPassword,
  resetPassword: ResetPassword,
  authenticator: Authenticator,
  datevDownload: DatevDownloadPage,
  home: Dashboard,
  financialPlanning: FinancialPlanning,
  revenue: Revenue,
  revenueNew: OutgoingInvoiceNew,
  revenueImport: OutgoingInvoiceImport,
  revenueEdit: (props) => <RemountableComponent Component={OutgoingInvoiceEdit} {...props} />,
  revenueShow: (props) => <RemountableComponent Component={OutgoingInvoiceShow} {...props} />,
  revenueCorrect: OutgoingInvoiceCorrect,
  revenueCancel: OutgoingInvoiceCancel,
  reminderNew: (props) => <RemountableComponent Component={ReminderNew} {...props} />,
  expenses: null,
  incomingInvoices: IncomingInvoices,
  incomingInvoicesNew: IncomingInvoiceNew,
  incomingInvoicesEdit: (props) => (
    <RemountableComponent Component={IncomingInvoiceEdit} {...props} />
  ),
  incomingInvoicesCancel: IncomingInvoiceCancel,
  incomingInvoicesShow: (props) => (
    <RemountableComponent Component={IncomingInvoiceShow} {...props} />
  ),
  bankTransfers: BankTransfers,
  administration: null,
  clients: Clients,
  clientsNew: ClientPage,
  clientsEdit: ClientPage,
  clientsShowFarmpilot: (props) => <ClientPage {...props} isFarmpilot />,
  clientsImport: (props) => <ImportContacts {...props} importedModel={MODELS.CLIENT} />,
  suppliers: Suppliers,
  suppliersShow: (props) => (
    <RemountableComponent Component={SupplierCreator} {...props} isReadonly />
  ),
  suppliersNew: SupplierCreator,
  suppliersEdit: (props) => <RemountableComponent Component={SupplierCreator} {...props} />,
  productCatalog: ProductCatalog,
  productCatalogItems: ProductCatalogItems,
  productCatalogItemsNew: ProductCatalogItemsNew,
  productCatalogItemsEdit: (props) => (
    <RemountableComponent Component={ProductCatalogItemsEdit} {...props} />
  ),
  productCatalogGroups: ProductCatalogGroups,
  productCatalogGroupsNew: ProductCatalogGroupsNew,
  productCatalogGroupsEdit: ProductCatalogGroupsEdit,
  suppliersImport: (props) => <ImportContacts {...props} importedModel={MODELS.SUPPLIER} />,
  profile: Profile,
  proposals: Proposals,
  proposalsNew: ProposalsNew,
  proposalsEdit: ProposalsEdit,
  proposalsShow: (props) => <RemountableComponent Component={ProposalsShow} {...props} />,
  orderConfirmations: OrderConfirmations,
  orderConfirmationsNew: OrderConfirmationsNew,
  orderConfirmationsEdit: (props) => (
    <RemountableComponent Component={OrderConfirmationsEdit} {...props} />
  ),
  orderConfirmationsShow: (props) => (
    <RemountableComponent Component={OrderConfirmationsShow} {...props} />
  ),
  orderConfirmationsCancel: (props) => (
    <RemountableComponent Component={OrderConfirmationsCancel} {...props} />
  ),
  datev: Datev,
  datevCreator: DatevCreator,
  datevEditor: DatevEditor,
  farmpilot: Farmpilot,
  invalidToken: InvalidTokenPage,
  contracts: Contracts,
  cashbooks: Cashbooks,
  settings: Settings,
  emailNotifications: EmailNotifications,
  bankAccountConnected: BankAccountConnected,
  registrationsDeletionTokenInvalid: RegistrationsDeletionTokenInvalid,
  registrationsDeletion: RegistrationsDeletion,
  deliveryNotes: DeliveryNotes,
  deliveryNotesNew: DeliveryNotesNew,
  deliveryNotesEdit: DeliveryNotesEdit,
  deliveryNotesShow: (props) => <RemountableComponent Component={DeliveryNotesShow} {...props} />,
};
/* eslint-enable react/display-name */
