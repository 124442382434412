import React, { Component, Fragment } from 'react';
import AnimateHeight from 'react-animate-height';
import { connect } from 'react-redux';
import cx from 'classnames';
import normalize from 'json-api-normalizer';
import { get, isEmpty, reverse } from 'lodash';
import { bool, func, number, shape } from 'prop-types';
import { compose } from 'recompose';
import build from 'redux-object';

import {
  duplicateIncomingInvoice as duplicateIncomingInvoiceAction,
  fetchSubInvoices,
} from 'actions/incoming-invoices';
import { FETCH_SUCCESS_SUB_INVOICES } from 'constants/incoming-invoices';
import {
  PIWIK_ACTION_EDIT_INCOMING_INVOICE,
  PIWIK_ACTION_SHOW_INCOMING_INVOICE,
  PIWIK_CATEGORY_EXPENSES,
} from 'constants/piwik';
import { InvoiceStatuses } from 'constants/statuses';
import paths from 'routes/paths';
import withViewport from 'shared/hoc/withViewport';
import styles from 'shared/styles/table.module.css';
import { formatMoney, formatPercentage, t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import ArrrowIcon from 'components/ArrowIcon/ArrowIcon';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import Section from 'components/Form/Section/Section';
import I18n from 'components/I18n';
import ActionAssignPayment from 'components/IndexActionsContainer/actionIcons/ActionAssignPayment';
import ActionCancel from 'components/IndexActionsContainer/actionIcons/ActionCancel';
import ActionDelete from 'components/IndexActionsContainer/actionIcons/ActionDelete';
import ActionDuplicate from 'components/IndexActionsContainer/actionIcons/ActionDuplicate';
import ActionEdit from 'components/IndexActionsContainer/actionIcons/ActionEdit';
import ActionView from 'components/IndexActionsContainer/actionIcons/ActionView';
import IndexActionsContainer from 'components/IndexActionsContainer/IndexActionsContainer';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import InvoiceColumn from 'components/InvoiceColumn/InvoiceColumn';
import LoadingIcon from 'components/LoadingIcon';
import sectionStyles from 'components/SubInvoices/SectionStyle.module.css';
import SubInvoices from 'components/SubInvoices/SubInvoices';
import IncomingInvoiceAmount from 'components/Table/IncomingInvoiceAmount';
import InvoiceStatusBadge from 'components/Table/InvoiceStatusBadge/InvoiceStatusBadge';

class InvoiceRow extends Component {
  state = {
    isRowExpanded: false,
    subInvoices: [],
    isFetching: false,
  };

  componentDidMount() {
    const { isXlgUp } = this.props;
    const { isRowExpanded } = this.state;

    if (!isXlgUp && !isRowExpanded) this.handleOnOpen();

    this.expandNewlyCancelledInvoice();
  }

  setInvoiceData = (response) => {
    const subInvoices = reverse(build(normalize(response), 'incomingInvoices'));
    this.setState({
      subInvoices,
      isFetching: false,
    });
  };

  handleOnClick = () => {
    const { isRowExpanded } = this.state;
    return isRowExpanded ? this.setState({ isRowExpanded: false }) : this.handleOnOpen();
  };

  expandNewlyCancelledInvoice = () => {
    const currentUrl = window.location.href;

    if (currentUrl.indexOf('expandCancelledInvoice') > 0) {
      const invoiceIdInUrl = currentUrl.substring(currentUrl.indexOf('=') + 1);
      const {
        invoice: { id },
      } = this.props;

      if (id === invoiceIdInUrl) this.handleOnOpen();
    }
  };

  handleOnOpen = () => {
    const { handleFetchSubInvoices, invoice: { id: invoiceId } = {} } = this.props;
    this.setState({ isFetching: true });
    handleFetchSubInvoices(invoiceId).then((response) => {
      if (response.type === FETCH_SUCCESS_SUB_INVOICES) this.setInvoiceData(response);
    });
    this.setState({ isRowExpanded: true });
  };

  /**
   * Send duplication request and redirect to new draft on success.
   */
  handleActionDuplicate = (event) => {
    // By default action icons are links. This stops default link behavior.
    event.preventDefault();

    const {
      duplicateIncomingInvoice,
      invoice: { id },
    } = this.props;

    duplicateIncomingInvoice(id);
  };

  render() {
    const {
      invoice,
      onEdit,
      onDelete,
      isIndexClassicView,
      isXlgUp,
      isEven,
      hasFilePresenceStatus = false,
      index,
    } = this.props;
    const { isRowExpanded, isFetching, subInvoices } = this.state;
    const fileStatus = invoice['files-present']
      ? InvoiceStatuses.HAS_FILE
      : InvoiceStatuses.NO_FILE;

    const status = (
      <InvoiceStatusBadge
        isIncomingInvoice
        status={hasFilePresenceStatus ? fileStatus : invoice.status}
      />
    );
    const persistedSupplierName = get(invoice, 'relationships.persistedSupplier.name', null);
    const supplier = persistedSupplierName || get(invoice, 'relationships.supplier.name', null);

    const discountGrossValueWithPercentage = invoice.discountGrossValue
      ? `${formatMoney(invoice.discountGrossValue, invoice.currency)} (${formatPercentage(
          invoice.discountPercentage
        )})`
      : '-';
    const totalGrossAmount = (
      <IncomingInvoiceAmount
        amount={invoice.totalGrossAmount}
        currency={invoice.currency}
        creditNote={invoice.creditNote}
        amendmentType={invoice.amendmentType}
      />
    );

    const permittedActions = get(invoice, 'meta.actions', {});

    const isSubInvoiceRowPresent = !isIndexClassicView && invoice.hasParentInvoice;

    const isOpenInvoiceStatus = invoice.status === InvoiceStatuses.OPEN;
    const isAppUploadInvoiceStatus = invoice.status === InvoiceStatuses.DRAFT; // a draft in incoming invoices == app_upload
    const isInternalSubscriptionFeeInvoice = isOpenInvoiceStatus && invoice.internalSubscriptionFee;
    const isBrandBadgedInvoice = isAppUploadInvoiceStatus || isInternalSubscriptionFeeInvoice;

    const appUploadInvoiceTooltipLocale = isAppUploadInvoiceStatus && 'app_upload_tooltip';
    const internalSubscriptionFeeInvoiceLocale =
      isInternalSubscriptionFeeInvoice && 'internal_subscription_fee_tooltip';
    const brandBadgeTooltipLocale =
      appUploadInvoiceTooltipLocale || internalSubscriptionFeeInvoiceLocale;

    return (
      <Fragment>
        <tr
          className={cx({
            [styles.rowEven]: isEven,
            [styles.rowOdd]: !isEven,
          })}
          data-id={`IncomingInvoiceTable:row-${index}`}
        >
          <InvoiceColumn className={styles.cell}>
            {isXlgUp && isSubInvoiceRowPresent && (
              <ArrrowIcon
                isOpen={isRowExpanded}
                onClick={this.handleOnClick}
                dataId="IncomingInvoice:icon-arrow"
              />
            )}
          </InvoiceColumn>
          <InvoiceColumn className={cx(styles.cell, styles.withTooltip)}>
            <I18n t="expenses.table.columns.status" className={styles.cellHeader} />
            <div className={styles.flexCenter}>
              <div
                className={cx(styles.cellContent, {
                  [styles.brandBadge]: isBrandBadgedInvoice,
                })}
              >
                {status}
              </div>
              {isBrandBadgedInvoice && (
                <InfoIcon
                  text={t(`revenue.table.tooltip.${brandBadgeTooltipLocale}`)}
                  tooltipPlacement={isXlgUp ? 'top' : 'left'}
                  tooltipClassName={styles.invoiceTooltip}
                />
              )}
            </div>
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell}>
            <I18n t="expenses.table.columns.supplier" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="IncomingInvoiceTable:cell-supplier">
              {supplier}
            </CellContentWithTooltip>
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell}>
            <I18n t="expenses.table.columns.number" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="IncomingInvoiceTable:cell-number" tooltip>
              {invoice.number}
            </CellContentWithTooltip>
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell}>
            <I18n t="expenses.table.columns.name" className={styles.cellHeader} />
            <CellContentWithTooltip dataId="IncomingInvoiceTable:cell-name">
              {invoice.name}
            </CellContentWithTooltip>
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell}>
            <I18n t="expenses.table.columns.invoice_date" className={styles.cellHeader} />
            <div className={styles.cellContent} data-id="IncomingInvoiceTable:cell-invoiceDate">
              {invoice.invoiceDate}
            </div>
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell}>
            <I18n t="expenses.table.columns.due_date" className={styles.cellHeader} />
            <div className={styles.cellContent} data-id="IncomingInvoiceTable:cell-dueDate">
              {invoice.dueDate}
            </div>
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell} align="right">
            <I18n t="expenses.table.columns.discount_gross_value" className={styles.cellHeader} />
            <CellContentWithTooltip
              className={styles.alignRight}
              dataId="IncomingInvoiceTable:cell-discountGrossValue"
            >
              {discountGrossValueWithPercentage}
            </CellContentWithTooltip>
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell} align="right">
            <I18n t="expenses.table.columns.total_gross_amount" className={styles.cellHeader} />
            <CellContentWithTooltip
              className={styles.alignRight}
              dataId="IncomingInvoiceTable:cell-totalGrossAmount"
            >
              {totalGrossAmount}
            </CellContentWithTooltip>
          </InvoiceColumn>
          <InvoiceColumn className={styles.cell}>
            <IndexActionsContainer isCentered={hasFilePresenceStatus}>
              <ActionView
                to={paths.showIncomingInvoice(invoice.id)}
                title={t('revenue.table.actions.show')}
                label={t('revenue.table.actions.show')}
                id="incoming-invoice-show-button"
                dataId="IncomingInvoices:button-show"
                visible={!hasFilePresenceStatus && permittedActions.show}
                onClick={() =>
                  piwikHelpers.trackEvent(
                    PIWIK_CATEGORY_EXPENSES,
                    PIWIK_ACTION_SHOW_INCOMING_INVOICE
                  )
                }
              />
              <ActionEdit
                title={t('revenue.table.actions.edit')}
                label={t('revenue.table.actions.edit')}
                dataId="IncomingInvoices:button-edit"
                onClick={(event) => {
                  event.preventDefault();
                  onEdit(invoice);
                  piwikHelpers.trackEvent(
                    PIWIK_CATEGORY_EXPENSES,
                    PIWIK_ACTION_EDIT_INCOMING_INVOICE
                  );
                }}
                visible={permittedActions.edit || permittedActions['edit-payment-information']}
              />
              <ActionDuplicate
                title={t('revenue.table.actions.duplicate')}
                label={t('revenue.table.actions.duplicate')}
                dataId="IncomingInvoices:button-duplicate"
                visible={permittedActions.duplicate}
                onClick={this.handleActionDuplicate}
              />
              <ActionAssignPayment
                to={`${paths.editIncomingInvoice(invoice.id)}#assign-payment`}
                title={t('revenue.table.actions.assign_payment')}
                label={t('revenue.table.actions.assign_payment')}
                id="incoming-invoice-payment-button"
                visible
                dataId="IncomingInvoice:button-payment"
              />
              <ActionCancel
                to={paths.cancelIncomingInvoice(invoice.id)}
                title={t('revenue.table.actions.cancel')}
                id="incoming-invoice-cancel-button"
                dataId="IncomingInvoices:button-cancel"
                label={t('revenue.table.actions.cancel')}
                visible={!hasFilePresenceStatus && permittedActions.cancel}
              />
              <ActionDelete
                title={t('revenue.table.actions.delete')}
                label={t('revenue.table.actions.delete')}
                dataId="IncomingInvoices:button-delete"
                onClick={(event) => {
                  event.preventDefault();
                  onDelete(invoice);
                }}
                visible={!hasFilePresenceStatus && permittedActions.delete}
              />
            </IndexActionsContainer>
          </InvoiceColumn>
          <InvoiceColumn />
        </tr>
        <tr>
          <td colSpan="10" className={sectionStyles.subInvoiceRow}>
            {isSubInvoiceRowPresent && !isEmpty(this.state.subInvoices) && (
              <AnimateHeight duration={200} height={isRowExpanded ? 'auto' : 0}>
                <Section
                  wrapperTheme={sectionStyles.section}
                  title={t('revenue.table.associated_documents')}
                >
                  {!isFetching ? (
                    <SubInvoices invoices={subInvoices} isIncomingInvoice isEven={isEven} />
                  ) : (
                    <LoadingIcon topDisabled />
                  )}
                </Section>
              </AnimateHeight>
            )}
          </td>
        </tr>
      </Fragment>
    );
  }
}
InvoiceRow.propTypes = {
  invoice: shape({}).isRequired,
  onEdit: func.isRequired,
  onDelete: func.isRequired,
  handleFetchSubInvoices: func.isRequired,
  isIndexClassicView: bool.isRequired,
  isXlgUp: bool.isRequired,
  isEven: bool.isRequired,
  hasFilePresenceStatus: bool.isRequired,
  duplicateIncomingInvoice: func.isRequired,
  index: number.isRequired,
};

const mapDisptachToProps = (dispatch) => ({
  handleFetchSubInvoices: (...args) => dispatch(fetchSubInvoices(...args)),
  duplicateIncomingInvoice: (invoiceId) => dispatch(duplicateIncomingInvoiceAction(invoiceId)),
});

export default compose(withViewport, connect(null, mapDisptachToProps))(InvoiceRow);
