import React, { useState } from 'react';
import { DropEvent, DropzoneOptions, useDropzone } from 'react-dropzone';

import { t } from 'shared/utils';
import LoadingIcon from 'components/LoadingIcon';
import Button from 'redesign/components/atoms/Button/Button';
import IconDelete from 'redesign/components/atoms/Icons/Delete';

import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import {
  Cancel,
  Delete,
  Empty,
  IconUploadCloud,
  Info,
  Loading,
  Preview,
  Title,
  Wrapper,
} from './Dropzone.styled';

export type DropzoneProps = {
  className?: string;
  file?: string | null;
  onDelete: () => void;
  fileInfo: string;
  deletionTitle: string;
  deletionButtonText: string;
  replacingTitle: string;
  replacingButtonText: string;
  onCancel?: () => void;
} & DropzoneOptions;

const Dropzone = ({
  file,
  className,
  onDelete,
  fileInfo,
  onDropAccepted,
  deletionTitle,
  deletionButtonText,
  replacingTitle,
  replacingButtonText,
  onCancel,
  ...rest
}: DropzoneProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showReplaceModal, setShowReplaceModal] = useState(false);
  const [drop, setDrop] = useState<[files: File[], event: DropEvent] | null>(null);

  const handleDropAccepted = async (files: File[], event: DropEvent) => {
    if (isUploading || isDeleting) return;

    if (file) {
      setShowReplaceModal(true);
      setDrop([files, event]);
      return;
    }

    setIsUploading(true);
    await onDropAccepted?.(files, event);
    setIsUploading(false);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      onDropAccepted: handleDropAccepted,
      ...rest,
    });

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    setShowDeleteModal(false);
    setIsDeleting(true);
    await onDelete();
    setIsDeleting(false);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const handleReplaceConfirm = async () => {
    setShowReplaceModal(false);

    if (file) {
      setIsDeleting(true);
      await onDelete();
      setIsDeleting(false);
    }

    if (drop) {
      setIsUploading(true);
      await onDropAccepted?.(...drop);
      setIsUploading(false);
      setDrop(null);
    }
  };

  const handleReplaceCancel = () => {
    setShowReplaceModal(false);
    setDrop(null);
  };

  return (
    <div className={className}>
      <Wrapper
        {...getRootProps({
          $isDragActive: isDragActive,
          $isDragAccept: isDragAccept,
          $isDragReject: isDragReject,
        })}
      >
        <input {...getInputProps()} />
        {isDeleting ? (
          <Loading>
            <LoadingIcon staticPosition />
            <p>{t('redesign.organisms.dropzone.deleting')}</p>
          </Loading>
        ) : isUploading ? (
          <Loading>
            <LoadingIcon staticPosition />
            <p>{t('redesign.organisms.dropzone.uploading')}</p>
            {onCancel && (
              <Cancel as="button" $variant="action" type="button" onClick={onCancel}>
                Abbrechen
              </Cancel>
            )}
          </Loading>
        ) : file ? (
          <Preview>
            <img src={file} alt={t('redesign.organisms.dropzone.preview')} />
          </Preview>
        ) : (
          <Empty>
            <IconUploadCloud $isDragActive={isDragActive} />
            <Title>{t('redesign.organisms.dropzone.title')}</Title>
            <Button type="button" onClick={open}>
              {t('redesign.organisms.dropzone.select')}
            </Button>
            <Info>{fileInfo}</Info>
          </Empty>
        )}
      </Wrapper>
      {file && (
        <Delete
          as="button"
          onClick={handleDelete}
          disabled={isUploading || isDeleting}
          $variant="action"
          type="button"
        >
          <IconDelete />
          {t('redesign.organisms.dropzone.delete')}
        </Delete>
      )}
      <ConfirmationModal
        isOpen={showDeleteModal}
        title={deletionTitle}
        buttonText={deletionButtonText}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      />
      <ConfirmationModal
        isOpen={showReplaceModal}
        title={replacingTitle}
        buttonText={replacingButtonText}
        onConfirm={handleReplaceConfirm}
        onCancel={handleReplaceCancel}
      />
    </div>
  );
};

export default Dropzone;
