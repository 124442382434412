const NAMESPACE = 'OUTGOING_INVOICE/UPLOADED_FILES';

export const UPLOAD_REQUEST = `${NAMESPACE}/UPLOAD_REQUEST`;
export const UPLOAD_SUCCESS = `${NAMESPACE}/UPLOAD_SUCCESS`;
export const UPLOAD_FAILURE = `${NAMESPACE}/UPLOAD_FAILURE`;

export const DELETE_REQUEST = `${NAMESPACE}/DELETE_REQUEST`;
export const DELETE_SUCCESS = `${NAMESPACE}/DELETE_SUCCESS`;
export const DELETE_FAILURE = `${NAMESPACE}/DELETE_FAILURE`;

export const DOWNLOAD_REQUEST = `${NAMESPACE}/DOWNLOAD_REQUEST`;
export const DOWNLOAD_SUCCESS = `${NAMESPACE}/DOWNLOAD_SUCCESS`;
export const DOWNLOAD_FAILURE = `${NAMESPACE}/DOWNLOAD_FAILURE`;
