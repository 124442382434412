import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get, isEmpty, omit, pick } from 'lodash';
import { func, shape, string } from 'prop-types';

import {
  indexClients,
  indexIncomingInvoices,
  indexOutgoingInvoices,
  indexSuppliers,
  previewClients,
  previewIncomingInvoices,
  previewOutgoingInvoices,
  previewSuppliers,
} from 'actions/datev/datevPreviewTable';
import {
  DATEV_PREVIEW_CLIENTS,
  DATEV_PREVIEW_INCOMING_INVOICES,
  DATEV_PREVIEW_OUTGOING_INVOICES,
  DATEV_PREVIEW_SUPPLIERS,
} from 'constants/datev';
import { Resources } from 'constants/resources';
import { formatDate, t } from 'shared/utils';
import { parseIncomingInvoice, parseOutgoingInvoice } from 'shared/utils/parseResponse';
import Loading from 'components/Loading';
import { Pagination } from 'components/Pagination/Pagination';

import DatevPreviewTable from '../DatevPreviewTable/DatevPreviewTable';
import EmptyMessage from '../EmptyMessage/EmptyMessage';

import styles from './DatevPreviewManager.module.css';

class DatevPreviewManager extends Component {
  state = {
    tableData: [],
    isFetchingData: false,
    pagination: { page: 1, perPage: 10 },
    currentSort: {},
  };

  componentDidMount() {
    this.fetchPreviewTable();
  }

  componentDidUpdate(prevProps) {
    const { datevDates: { values: { startDate, endDate } = {} } = {} } = this.props;
    const { datevDates: { values: { startDate: prevStartDate, endDate: prevEndDate } = {} } = {} } =
      prevProps;

    if (prevStartDate === startDate && prevEndDate === endDate) return;

    this.fetchPreviewTable();
  }

  parseResponse = ({ data, included }) => {
    const { type } = this.props;

    if (type === DATEV_PREVIEW_OUTGOING_INVOICES) {
      return data.map((invoice) => parseOutgoingInvoice(invoice, included));
    }

    if (type === DATEV_PREVIEW_INCOMING_INVOICES) {
      return data.map((invoice) => parseIncomingInvoice(invoice, included));
    }

    return data.map((row) => row.attributes);
  };

  getSorting = () => {
    const { currentSort } = this.state;
    const { type } = this.props;

    if (type === DATEV_PREVIEW_OUTGOING_INVOICES || type === DATEV_PREVIEW_INCOMING_INVOICES) {
      const column = type === DATEV_PREVIEW_OUTGOING_INVOICES ? 'creation_date' : 'invoice_date';
      return !isEmpty(currentSort) ? currentSort : { column, direction: 'ASC' };
    }

    return currentSort;
  };

  fetchPreviewTable() {
    const { fetchTableData, datevDates } = this.props;
    const { pagination } = this.state;

    this.setState({ isFetchingData: true });

    const params = pick(datevDates, ['values', 'initial']);
    const fetchParams = {
      startDate: formatDate(params.values ? params.values.startDate : params.initial.startDate),
      endDate: formatDate(params.values ? params.values.endDate : params.initial.endDate),
    };
    const currentSort = this.getSorting();

    fetchTableData(
      { pagination_resource: Resources.DATEV_EXPORTS, ...pagination },
      currentSort,
      fetchParams
    ).then(({ response, headers }) => {
      const totalPages = parseInt(headers['total-pages'], 10);

      this.setState({
        isFetchingData: false,
        tableData: this.parseResponse(response),
        pagination: { ...this.state.pagination, totalPages },
        currentSort,
      });
    });
  }

  handlePaginationChange = (pagination) => {
    this.setState({ pagination }, this.fetchPreviewTable);
  };

  handleSortChange = (column) => () => {
    const changeDirection = (sorting) => ({
      ...sorting,
      direction: sorting.direction === 'DESC' ? 'ASC' : 'DESC',
    });

    const currentSort =
      column === this.state.currentSort.column
        ? changeDirection(this.state.currentSort)
        : { ...this.state.currentSort, column };

    this.setState(
      {
        currentSort: omit(currentSort, ['value']),
      },
      this.fetchPreviewTable
    );
  };

  getEmptyMessage = () => {
    const { type } = this.props;

    switch (type) {
      case DATEV_PREVIEW_CLIENTS:
        return t('datev.creator.messages.no_customers_found');
      case DATEV_PREVIEW_SUPPLIERS:
        return t('datev.creator.messages.no_suppliers_found');
      default:
        return t('datev.creator.messages.no_invoices_found');
    }
  };

  render() {
    const { isFetchingData, currentSort, pagination, tableData } = this.state;

    if ((isEmpty(tableData) && !isFetchingData) || !this.props.isValidDateRange) {
      return <EmptyMessage>{this.getEmptyMessage()}</EmptyMessage>;
    }

    if (isFetchingData) {
      return <Loading />;
    }

    return (
      <div className={styles.main}>
        <DatevPreviewTable
          type={this.props.type}
          data={tableData}
          isFetching={isFetchingData}
          handleSort={this.handleSortChange}
          currentSort={currentSort}
        />

        <div className={styles.section}>
          <Pagination
            {...pagination}
            request={this.handlePaginationChange}
            isFetching={isFetchingData}
            resource={Resources.DATEV_EXPORTS}
          />
        </div>
      </div>
    );
  }
}

DatevPreviewManager.propTypes = {
  type: string.isRequired,
  fetchTableData: func.isRequired,
  datevDates: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  datevDates: state.form.DatevCreator,
  isValidDateRange: state.dateRangePicker[0]?.isValid,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchTableData: (...args) => {
    const { type } = ownProps;
    const datevReportId = get(ownProps, 'match.params.id', null);

    if (datevReportId) {
      if (type === DATEV_PREVIEW_INCOMING_INVOICES)
        return dispatch(indexIncomingInvoices(datevReportId, ...args));
      if (type === DATEV_PREVIEW_OUTGOING_INVOICES)
        return dispatch(indexOutgoingInvoices(datevReportId, ...args));
      if (type === DATEV_PREVIEW_CLIENTS) return dispatch(indexClients(datevReportId, ...args));
      if (type === DATEV_PREVIEW_SUPPLIERS) return dispatch(indexSuppliers(datevReportId, ...args));
    }

    if (type === DATEV_PREVIEW_INCOMING_INVOICES) return dispatch(previewIncomingInvoices(...args));
    if (type === DATEV_PREVIEW_OUTGOING_INVOICES) return dispatch(previewOutgoingInvoices(...args));
    if (type === DATEV_PREVIEW_CLIENTS) return dispatch(previewClients(...args));
    if (type === DATEV_PREVIEW_SUPPLIERS) return dispatch(previewSuppliers(...args));

    return dispatch(previewIncomingInvoices(...args));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatevPreviewManager));
