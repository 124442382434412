import 'react-dates/initialize';
import 'moment/locale/de';

import React, { useEffect, useMemo, useState } from 'react';
import { DateRangePicker as DateRangePickerBase, FocusedInputShape } from 'react-dates';
import moment from 'moment';

import { useAppDispatch } from 'shared/hooks/app';
import { t } from 'shared/utils';
import { actions } from 'store/slices/dateRangePicker/reducer';
import DatePresets from 'redesign/components/molecules/DatePresets/DatePresets';

import { DateRangePickerLegacy, DateRangePickerRedesign, WarningMessage } from './styled';
import { DateRangePickerVersion, TDateRange, TDateRangePickerProps } from './types';

import 'react-dates/lib/css/_datepicker.css';

const isOutsideRangeDefault = () => false;

const DateRangePicker = ({
  label,
  presets,
  variant = DateRangePickerVersion.redesign,
  startDateId,
  endDateId,
  period,
  isOutsideRange = isOutsideRangeDefault,
  id,
  ...props
}: TDateRangePickerProps) => {
  const dispatch = useAppDispatch();

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  const [range, setRange] = useState<TDateRange>({
    startDate: period?.startDate || moment().startOf('month'),
    endDate: period?.endDate || moment().startOf('month'),
  });

  const isDateRangeValid = useMemo(
    () => range.startDate !== null && range.endDate !== null,
    [range.startDate, range.endDate]
  );

  useEffect(() => {
    dispatch(actions.createDateRangePicker({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(actions.setIsValid({ id, isValid: isDateRangeValid }));
  }, [dispatch, id, isDateRangeValid]);

  const handleFocusChange = (focusedInput: FocusedInputShape | null) => {
    setFocusedInput(focusedInput);
  };

  const handleDatesChange = ({ startDate, endDate }: TDateRange) => {
    setRange((prevState) => ({ ...prevState, startDate, endDate }));
  };

  const renderDatePresets = () => presets && DatePresets({ presets, handleDatesChange });

  const renderDateRangePickerBase = (
    <DateRangePickerBase
      {...props}
      onDatesChange={handleDatesChange}
      onFocusChange={handleFocusChange}
      focusedInput={focusedInput}
      startDate={range.startDate}
      endDate={range.endDate}
      hideKeyboardShortcutsPanel
      verticalSpacing={10}
      isOutsideRange={isOutsideRange}
      renderCalendarInfo={renderDatePresets}
      startDatePlaceholderText={t('date_ranges.placeholder.start_date')}
      endDatePlaceholderText={t('date_ranges.placeholder.end_date')}
      startDateId={startDateId}
      endDateId={endDateId}
      minimumNights={0}
    />
  );

  const DateRangePicker =
    variant === DateRangePickerVersion.legacy ? DateRangePickerLegacy : DateRangePickerRedesign;

  return (
    <DateRangePicker
      $error={{ startDate: range.startDate === null, endDate: range.endDate === null }}
    >
      <label>{label}</label>
      {renderDateRangePickerBase}
      {!isDateRangeValid && <WarningMessage>{t('date_ranges.validation_message')}</WarningMessage>}
    </DateRangePicker>
  );
};

export default DateRangePicker;
