import { REHYDRATE } from 'redux-persist';
import { call, select, takeLatest } from 'redux-saga/effects';

import { trackPageEnterForLoggedOutUser } from 'shared/utils/gtag';

function* trackPageWithGtag() {
  const state = yield select();
  yield call(trackPageEnterForLoggedOutUser, state);
}

export default function* watchRehydrate() {
  yield takeLatest(REHYDRATE, trackPageWithGtag);
}
