import { Interval } from '../ExpensesRevenues/constants';

export enum Category {
  Rent = 'rent',
  Insurance = 'insurance',
  Credit = 'credit',
  Withdrawals = 'withdrawals',
}

export type RecurringExpense = {
  name: string;
  interval: Interval;
  amount: string;
};

export type OtherExpense = {
  name: string;
  amount: string;
};
