import React from 'react';
import PropTypes from 'prop-types';

import warning from 'images/icon-warning.svg';
import { t } from 'shared/utils';
import ActionButton from 'components/ActionPanel/ActionButton';
import Modal, { ModalHeader } from 'components/Modal';

import styles from './CancelAccountModal.module.css';

const CancelAccountModal = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.cancelAccountModal}>
    <ModalHeader>{t('modals.cancel_account.title')}</ModalHeader>
    <div className={styles.contentInner}>
      <img src={warning} alt="warning" />
      <div className={styles.message}>
        <p className={styles.noMargin}>
          {t('modals.cancel_account.text_1')} <a href="mailto:service@vrsg.de">service@vrsg.de</a>.{' '}
          {t('modals.cancel_account.text_2')}
        </p>
        <p>{t('modals.cancel_account.text_3')}</p>
        <p>
          <strong>{t('modals.cancel_account.warning')}</strong>
        </p>
        <p>{t('modals.cancel_account.text_4')}</p>
      </div>
      <div className={styles.buttons}>
        <ActionButton label={t('modals.cancel_account.close')} onClick={onClose} />
      </div>
    </div>
  </Modal>
);

CancelAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CancelAccountModal;
