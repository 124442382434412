import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { get, isEmpty, orderBy } from 'lodash';
import PropTypes from 'prop-types';

import { ITEMS_DROPPABLE_ID } from 'constants/proposal';
import { t } from 'shared/utils';
import {
  LineItemsAutoSaveConsumer,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
} from 'components/LineItems';
import lineItemsStyles from 'components/LineItems/LineItems.module.css';

import NewItemRow from './NewItemRow';
import Row from './Row';

const headers = (insertedAsGross, readonly) => [
  {
    name: t('proposals.form.details_section.fields.position'),
    className: lineItemsStyles.positionCell,
    customWidth: true,
  },
  { name: t('proposals.form.details_section.fields.quantity') },
  { name: t('proposals.form.details_section.fields.unit') },
  {
    name: insertedAsGross
      ? t('proposals.form.details_section.fields.gross_amount')
      : t('proposals.form.details_section.fields.net_amount'),
    alignRight: true,
  },
  { name: t('proposals.form.details_section.fields.vat') },
  {
    name: insertedAsGross
      ? t('proposals.form.details_section.fields.total_gross_amount')
      : t('proposals.form.details_section.fields.total_net_amount'),
    alignRight: true,
  },
  {
    name: t('proposals.form.details_section.fields.actions'),
    className: cx(lineItemsStyles.actionsHeader, { [lineItemsStyles.actionsCellHidden]: readonly }),
    customWidth: true,
  },
];

const LineItemsTable = ({
  lineItems = [],
  newItems = [],
  removeNewItem,
  readonly,
  hasErrors,
  insertedAsGross,
  crudMode,
  defaultCategory,
  selectedCategory,
  lineCategories,
  taxRates,
  taxRateId,
  defaultVat,
  isInitial,
  setLineItemState,
  setShowDifferentCategoryWarning,
}) => {
  const showLineItems = !isEmpty(lineCategories) && !isEmpty(lineItems);

  return (
    <Table hasErrors={hasErrors} readonly={readonly}>
      <TableHeader>
        {headers(insertedAsGross, readonly).map(
          ({ name, className, customWidth, alignRight }, index) => (
            <TableHeaderCell
              key={`${index}-${name}`}
              title={name}
              className={className}
              customWidth={customWidth}
              alignRight={alignRight}
            />
          )
        )}
      </TableHeader>

      <LineItemsAutoSaveConsumer>
        {({
          addNewLineItemCreator,
          addEditLineItemCreator,
          removeNewLineItemCreator,
          removeEditLineItemCreator,
        }) => (
          <TableBody droppableId={ITEMS_DROPPABLE_ID}>
            {({ provided }) => (
              <>
                {showLineItems &&
                  orderBy(lineItems, (item) => item.ordinalNumber).map((item) => (
                    <Row
                      key={item.id}
                      lineItem={item}
                      dndId={item.id}
                      dndIndex={item.ordinalNumber}
                      readonly={readonly}
                      lineItems={lineItems}
                      addEditLineItemCreator={addEditLineItemCreator}
                      removeEditLineItemCreator={removeEditLineItemCreator}
                      outgoingInvoiceSelectedCategory={selectedCategory}
                      defaultCategory={defaultCategory}
                      crudMode={crudMode}
                      lineCategories={lineCategories}
                      taxRates={taxRates}
                      taxRateId={taxRateId}
                      defaultVat={defaultVat}
                      isInitial={isInitial}
                      setLineItemState={setLineItemState}
                      setShowDifferentCategoryWarning={setShowDifferentCategoryWarning}
                    />
                  ))}
                {provided.placeholder}
                {newItems.map((item) => (
                  <NewItemRow
                    key={item.rowId}
                    newItem={item}
                    prefilled={item.prefilled}
                    markAsAccepted={item.markAsAccepted}
                    removeItem={removeNewItem(item.rowId)}
                    readonly={readonly}
                    addNewLineItemCreator={addNewLineItemCreator}
                    removeNewLineItemCreator={removeNewLineItemCreator}
                    outgoingInvoiceSelectedCategory={selectedCategory}
                    defaultCategory={defaultCategory}
                    crudMode={crudMode}
                    lineCategories={lineCategories}
                    taxRates={taxRates}
                    taxRateId={taxRateId}
                    defaultVat={defaultVat}
                    setLineItemState={setLineItemState}
                  />
                ))}
              </>
            )}
          </TableBody>
        )}
      </LineItemsAutoSaveConsumer>
    </Table>
  );
};

LineItemsTable.propTypes = {
  newItems: PropTypes.arrayOf(PropTypes.shape({})),
  lineItems: PropTypes.arrayOf(PropTypes.shape({})),
  removeNewItem: PropTypes.func,
  readonly: PropTypes.bool,
  hasErrors: PropTypes.bool,
  insertedAsGross: PropTypes.bool,
  crudMode: PropTypes.string,
  defaultCategory: PropTypes.shape({}),
  selectedCategory: PropTypes.shape({}),
};

export default connect((state) => ({
  hasErrors:
    get(state, 'form.proposalCreator.submitErrors.base') && !state.proposal.lineItems.length,
  insertedAsGross: state.proposal.details.insertedAsGross,
}))(LineItemsTable);
