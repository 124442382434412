import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import {
  getOutgoingInvoicesSummary,
  updateOutgoingInvoicesSummary,
} from 'api/me/outgoingInvoiceSummary';
import EntityPath from 'constants/entitiesPaths';
import { formatMoney, t } from 'shared/utils';
import { OutgoingInvoiceSummary } from 'types/entities/OutgoingInvoiceSummary';
import Loading from 'components/Loading';
import Checkbox from 'redesign/components/atoms/Checkbox/Checkbox';
import CalendarRound from 'redesign/components/atoms/Icons/CalendarRound';
import DocumentRound from 'redesign/components/atoms/Icons/DocumentRound';
import { Breakpoints } from 'redesign/styles/breakpoints';

import {
  Amount,
  Amounts,
  Box,
  Column,
  ContentWrapper,
  IconClose,
  IconOverdue,
  IconWrapper,
  Link,
  PreferenceWrapper,
  RoundIcon,
  Section,
  Subtitle,
  Summary,
  Tip,
  Tooltip,
} from './OutgoingInvoicesKPITile.styled';

const OutgoingInvoicesKPITile = () => {
  const [data, setData] = useState<OutgoingInvoiceSummary>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const isMobile = useMedia(`(max-width: ${Breakpoints.mdMax})`);
  const [isChecked, setIsChecked] = useState(false);
  const overdueInvoicesCount = data?.overdueInvoicesCount;
  const openInvoicesCount = data?.openInvoicesCount;
  const openDemand = formatMoney(data?.openDemandGrossAmountToBePaid);
  const invoices = isMobile ? openDemand : openInvoicesCount;
  const paymentDuration = data?.averagePaymentDurationDays
    ? `${data.averagePaymentDurationDays}`.replace('.', ',')
    : 0;
  const paymentRemindersCount = data?.paymentRemindedInvoicesCount;
  const overdueDemand = formatMoney(data?.overdueDemandGrossAmountToBePaid);
  const overdueInvoices = isMobile ? overdueDemand : overdueInvoicesCount;

  const handleClose = useCallback(() => {
    isChecked && updateOutgoingInvoicesSummary({ kpi_tile_tooltip_dismissed: true });
    setIsTooltipOpen(false);
  }, [isChecked]);

  const handleCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const response = await getOutgoingInvoicesSummary();
      const summary = build<OutgoingInvoiceSummary>(
        normalize(response.data),
        EntityPath.OutgoingInvoicesSummary,
        response.data.data.id
      );

      setData(summary);
      setIsLoading(false);
    };

    fetch();
  }, []);

  useEffect(() => {
    if (!data) return;

    const shouldHideTooltipMobile = data.kpiTileTooltipDismissed && isMobile;

    setIsTooltipOpen(!shouldHideTooltipMobile && data.paymentRemindedInvoicesCount !== 0);
  }, [data, isMobile]);

  return (
    <Section isTooltipOpen={isTooltipOpen} data-id="outgoing-invoices-kpi-tile">
      <Box>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Column data-id="open">
              <IconWrapper>
                <DocumentRound />
              </IconWrapper>
              <ContentWrapper>
                {isMobile && (
                  <Subtitle data-id="title">
                    {t('redesign.organisms.outgoing_invoices_kpi_tile.open.title')}
                  </Subtitle>
                )}
                <Amounts>
                  {!isMobile && (
                    <Subtitle data-id="title">
                      {t('redesign.organisms.outgoing_invoices_kpi_tile.open.open_invoices')}
                    </Subtitle>
                  )}
                  <Amount data-id="open-invoices-amount">{invoices}</Amount>
                  {isMobile && (
                    <Summary data-id="summary-invoices">
                      {t('redesign.organisms.outgoing_invoices_kpi_tile.summary_invoices', {
                        count: openInvoicesCount,
                      })}
                    </Summary>
                  )}
                </Amounts>
                {!isMobile && (
                  <Amounts>
                    <Subtitle data-id="open-demands-title">
                      {t('redesign.organisms.outgoing_invoices_kpi_tile.open.open_demands')}
                    </Subtitle>
                    <Amount>{openDemand}</Amount>
                  </Amounts>
                )}
              </ContentWrapper>
            </Column>
            <Column data-id="payment-duration">
              <IconWrapper>
                <CalendarRound />
              </IconWrapper>
              <ContentWrapper>
                <Amounts>
                  <Subtitle data-id="payment-duration-title">
                    {t('redesign.organisms.outgoing_invoices_kpi_tile.payment_time_duration.title')}
                  </Subtitle>
                </Amounts>
                <Amount data-id="payment-duration-days">
                  {t(
                    'redesign.organisms.outgoing_invoices_kpi_tile.payment_time_duration.duration',
                    { count: paymentDuration }
                  )}
                </Amount>
              </ContentWrapper>
            </Column>
            <Column data-id="overdue">
              <IconWrapper>
                <IconOverdue $hasOverdueInvoices={overdueInvoicesCount === 0} />
              </IconWrapper>
              <ContentWrapper>
                {overdueInvoicesCount !== 0 ? (
                  <>
                    {isMobile && (
                      <Subtitle data-id="overdue-invoices-title">
                        {t(
                          'redesign.organisms.outgoing_invoices_kpi_tile.overdue.overdue_invoices'
                        )}
                      </Subtitle>
                    )}
                    <Amounts>
                      {!isMobile && (
                        <Subtitle data-id="overdue-invoices-title">
                          {t(
                            'redesign.organisms.outgoing_invoices_kpi_tile.overdue.overdue_invoices'
                          )}
                        </Subtitle>
                      )}
                      <Amount data-id="overdue-invoices-amount">{overdueInvoices}</Amount>
                      {isMobile && (
                        <Summary data-id="overdue-invoices-summary">
                          {t('redesign.organisms.outgoing_invoices_kpi_tile.summary_invoices', {
                            count: overdueInvoicesCount,
                          })}
                        </Summary>
                      )}
                    </Amounts>
                    {!isMobile && (
                      <>
                        <Amounts>
                          <Subtitle data-id="sent-payment-reminders-title">
                            {t(
                              'redesign.organisms.outgoing_invoices_kpi_tile.overdue.sent_payment_reminders'
                            )}
                          </Subtitle>
                          <Amount data-id="sent-payment-reminders-amount">
                            {paymentRemindersCount}
                          </Amount>
                        </Amounts>
                        <Amounts>
                          <Subtitle data-id="overdue-demands-title">
                            {t(
                              'redesign.organisms.outgoing_invoices_kpi_tile.overdue.overdue_demands'
                            )}
                          </Subtitle>
                          <Amount data-id="overdue-demands-amount">{overdueDemand}</Amount>
                        </Amounts>
                      </>
                    )}
                  </>
                ) : (
                  <Subtitle
                    $hasOverdueInvoices={overdueInvoicesCount === 0}
                    data-id="overdue-invoices-empty-title"
                  >
                    {t('redesign.organisms.outgoing_invoices_kpi_tile.overdue.no_overdue_invoices')}
                  </Subtitle>
                )}{' '}
              </ContentWrapper>
            </Column>
          </>
        )}
      </Box>
      <Tooltip isTooltipOpen={isTooltipOpen} data-id="overdue-invoices-tooltip">
        <RoundIcon>
          <Tip />
        </RoundIcon>
        <IconClose onClick={handleClose} data-id="overdue-invoices-tooltip-close-icon" />
        <p>{t('redesign.organisms.outgoing_invoices_kpi_tile.tooltip.description_1')}</p>
        <p>{t('redesign.organisms.outgoing_invoices_kpi_tile.tooltip.description_2')}</p>
        <Link
          href="https://www.vr-smart-guide.de/inkasso-pairfinance?mtm_campaign=VR_Smart_Guide_App"
          target="_blank"
          rel="noopener noreferrer"
          data-id="overdue-invoices-tooltip-link"
        >
          {t('redesign.organisms.outgoing_invoices_kpi_tile.tooltip.link')}
        </Link>
        <PreferenceWrapper>
          <Checkbox checked={isChecked} onChange={handleCheckboxChange} data-id="checkbox" />
          {t('redesign.organisms.outgoing_invoices_kpi_tile.tooltip.do_not_show_again')}
        </PreferenceWrapper>
      </Tooltip>
    </Section>
  );
};

export default memo(OutgoingInvoicesKPITile);
