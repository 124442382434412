import React from 'react';
import { string } from 'prop-types';

import { Dropdown } from 'components/Dropdown';

import styles from '../ActionButton/ActionButton.module.css';

const ActionDropdown = ({ className = '', ...props }) => (
  <Dropdown buttonClassName={[styles.actionButton, styles.dropdownButton, className]} {...props} />
);

ActionDropdown.propTypes = {
  className: string,
};

export default ActionDropdown;
