import { RootState } from 'store';

export const getData = (state: RootState) => state.datevExport.expenseCashTransactions.data;

export const getIsLoading = (state: RootState) =>
  state.datevExport.expenseCashTransactions.isLoading;

export const getParams = (state: RootState) => state.datevExport.expenseCashTransactions.params;

export const getPaginationPreference = (state: RootState) =>
  state.paginationPreferences.data?.datevExports;

export const getIsValidDateRange = (state: RootState) => state.dateRangePicker[0]?.isValid;
