import styled from 'styled-components';

import SortArrow from 'images/sort-arrow.svg';
import SortArrowActive from 'images/sort-arrow-active.svg';

export const Wrapper = styled.div`
  display: inline-flex;
  margin-left: 5px;
  flex-direction: column;
`;

type IndicatorProps = {
  isActive?: boolean;
};

const Indicator = styled.div<IndicatorProps>`
  display: inline-block;
  width: 6px;
  height: 3px;
  margin: 1px 0;
  background: url(${SortArrow}) center no-repeat;

  ${({ isActive }) => isActive && `background-image: url(${SortArrowActive});`}
`;

export const AscIndicator = styled(Indicator)`
  transform: rotate(180deg);
`;

export const DescIndicator = styled(Indicator)``;
