import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as reduxPush } from 'connected-react-router';
import { bool, func, shape, string } from 'prop-types';

import { CANCEL_MODE, EDIT_MODE, NEW_MODE } from 'constants/common/crud';
import * as Piwik from 'constants/piwik';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import { piwikHelpers } from 'shared/utils/piwik';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import ActionDropdown from 'components/ActionPanel/ActionDropdown';
import { ButtonAppearances } from 'components/Button';
import I18n from 'components/I18n';
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'components/LineItems';
import { ConfirmationModal } from 'components/Modal';
import EmailEditor from 'features/orderConfirmation/EmailEditor/EmailEditor';

import AbortButton from './AbortButton';
import PreviewModal from './PreviewModal';

import styles from './ActionsSection.module.css';

class ActionsSection extends Component {
  state = {
    modalActive: false,
    sendEmailModalVisible: false,
    previewVisible: false,
  };

  isCancelMode = () => this.props.crudMode === CANCEL_MODE;

  onConfirm = () => {
    const { saveAndTransform } = this.props;
    saveAndTransform();
    this.hideModal();
  };

  onClose = () => {
    const { transform } = this.props;
    transform();
    this.hideModal();
  };

  onTransform = () => {
    const { transform } = this.props;
    piwikHelpers.trackEvent(
      Piwik.CATEGORY_ORDER_CONFIRMATIONS,
      Piwik.ACTION_SHOW_VIEW_TRANSFORM_ORDER_CONFIRMATION
    );
    transform();
  };

  onAbort = () => {
    const { abort, crudMode } = this.props;
    abort();

    if (crudMode === NEW_MODE) {
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_ORDER_CONFIRMATIONS,
        Piwik.ACTION_CREATE_ORDER_CONFIRMATION_CANCEL
      );
    }
  };

  onSaveAsDraft = () => {
    const { saveAsDraft, crudMode } = this.props;

    saveAsDraft();

    if (crudMode === NEW_MODE) {
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_ORDER_CONFIRMATIONS,
        Piwik.ACTION_CREATE_ORDER_CONFIRMATION_SAVE
      );
    }
    if (crudMode === EDIT_MODE) {
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_ORDER_CONFIRMATIONS,
        Piwik.ACTION_EDIT_ORDER_CONFIRMATION_SAVE
      );
    }
  };

  onPreview = () => {
    const { crudMode } = this.props;
    this.togglePreview();

    if (crudMode === EDIT_MODE) {
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_ORDER_CONFIRMATIONS,
        Piwik.ACTION_ORDER_CONFIRMATION_PREVIEW
      );
    }
  };

  setSendMailModal = (value) => this.setState({ sendEmailModalVisible: value });

  transformWithSaveConfirmation = () => {
    const { isFormDirty, transform, crudMode } = this.props;

    if (crudMode === NEW_MODE) {
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_ORDER_CONFIRMATIONS,
        Piwik.ACTION_NEW_VIEW_TRANSFORM_ORDER_CONFIRMATION
      );
    }
    if (crudMode === EDIT_MODE) {
      piwikHelpers.trackEvent(
        Piwik.CATEGORY_ORDER_CONFIRMATIONS,
        Piwik.ACTION_EDIT_VIEW_TRANSFORM_ORDER_CONFIRMATION
      );
    }

    if (isFormDirty) {
      this.showModal();
    } else {
      transform();
    }
  };

  showModal = () => this.setState({ modalActive: true });

  hideModal = () => this.setState({ modalActive: false });

  togglePreview = () => {
    const { previewVisible } = this.state;

    this.setState({
      previewVisible: !previewVisible,
    });
  };

  sendActions = () => {
    const downloadAction = {
      name: t('features.order_confirmation.form.download'),
      onClick: () => this.processDownloadOrderConfirmation(),
      tooltip: t('features.order_confirmation.form.download_tooltip'),
      dataId: 'download-pdf',
    };
    const sendByEmailAction = {
      name: t('features.order_confirmation.form.send_email'),
      onClick: () => this.setSendMailModal(true),
      tooltip: t('features.order_confirmation.form.send_email_tooltip'),
      dataId: 'OrderConfirmationPage:button-send-email',
    };

    return this.props.sendingDocumentsByEmailEnabled
      ? [downloadAction, sendByEmailAction]
      : [downloadAction];
  };

  downloadOrderConfirmation = (orderConfirmation = {}, isPiwikTracked = false) => {
    this.props.download(orderConfirmation, isPiwikTracked, this.isCancelMode());
  };

  saveAndDownloadOrderConfirmation = async () => {
    const { save, crudMode } = this.props;
    const updatedOrderConfirmation = await save();

    if (updatedOrderConfirmation.success) {
      const isPiwikTracked = crudMode === NEW_MODE || crudMode === EDIT_MODE;

      this.downloadOrderConfirmation(updatedOrderConfirmation, isPiwikTracked);
    }
  };

  processDownloadOrderConfirmation = () => {
    this.setSendMailModal(false);
    this.props.readonly && this.props.crudMode !== CANCEL_MODE
      ? this.downloadOrderConfirmation()
      : this.saveAndDownloadOrderConfirmation();
  };

  render() {
    const {
      isFormSubmitting,
      readonly,
      showSave = false,
      showTransform = false,
      currentClient = {},
      wrapped = false,
      push,
      isProfileFilled,
      crudMode,
      canSubmit,
    } = this.props;
    const { previewVisible, sendEmailModalVisible } = this.state;

    return (
      <ActionPanel
        wrapped={wrapped}
        className={cx(styles.creatorActionsSection, IGNORE_OUTSIDE_CLICK_CLASS)}
      >
        <ActionButton
          dataId="OrderConfirmationPage:button-save"
          visible={!readonly && showSave}
          label={t('features.order_confirmation.form.save')}
          onClick={this.onSaveAsDraft}
          disabled={!canSubmit || isFormSubmitting}
        />
        <ActionDropdown
          upwards
          visible
          disabled={!canSubmit || !isProfileFilled}
          actions={this.sendActions()}
          title={
            this.isCancelMode()
              ? t('features.order_confirmation.form.cancel')
              : t('features.order_confirmation.form.download_or_send')
          }
          dataId="OrderConfirmationPage:button-download-or-save"
        />
        <ActionButton
          dataId="OrderConfirmationPage:button-transform"
          visible={showTransform && !readonly}
          label={t('features.order_confirmation.form.save_and_transform')}
          onClick={this.transformWithSaveConfirmation}
          disabled={!canSubmit || isFormSubmitting}
        />
        <ActionButton
          dataId="OrderConfirmationPage:button-transform"
          visible={showTransform && readonly}
          label={t('features.order_confirmation.form.save_and_transform')}
          onClick={this.onTransform}
        />
        <ActionButton
          dataId="OrderConfirmationPage:button-preview"
          visible={!readonly}
          appearance={ButtonAppearances.outlined}
          onClick={this.onPreview}
          label={t('revenue.form.preview')}
        />
        <AbortButton
          dataId="OrderConfirmationPage:button-abort"
          visible={!readonly}
          onClick={this.onAbort}
        />
        <ConfirmationModal
          isOpen={this.state.modalActive}
          onClose={this.onClose}
          onConfirm={this.onConfirm}
        >
          <I18n t="features.order_confirmation.form.modal" />
        </ConfirmationModal>
        {sendEmailModalVisible && (
          <EmailEditor
            onClose={() => this.setSendMailModal(false)}
            client={currentClient}
            onSave={this.props.saveWithoutRedirect}
            readonly={readonly}
            crudMode={crudMode}
          />
        )}
        <PreviewModal onClose={this.togglePreview} isOpen={previewVisible} />
        <ActionButton
          appearance={ButtonAppearances.outlined}
          onClick={() => push(paths.orderConfirmation)}
          label={t('forms.actions.back_to_index')}
          visible={readonly}
        />
      </ActionPanel>
    );
  }
}

ActionsSection.propTypes = {
  save: func.isRequired,
  saveAsDraft: func.isRequired,
  showTransform: bool,
  showSave: bool,
  transform: func.isRequired,
  saveAndTransform: func.isRequired,
  download: func.isRequired,
  send: func.isRequired,
  abort: func.isRequired,
  isFormDirty: bool,
  isFormSubmitting: bool.isRequired,
  readonly: bool,
  currentClient: shape(),
  sendingDocumentsByEmailEnabled: bool,
  wrapped: bool,
  crudMode: string.isRequired,
  push: func.isRequired,
  isProfileFilled: bool.isRequired,
};

const mapStateToProps = (state) => ({
  isProfileFilled: state.onboarding.data?.profileFilled,
});

const mapDispatchToProps = {
  push: (path) => reduxPush(path),
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsSection);
