import React, { useContext } from 'react';

import Button from 'components/Button';

import { CustomTaskCreatorContext } from './CustomTaskCreator/CustomTaskCreator';
import { trackEvent } from './utils';

type CreateCustomTaskButtonProps = {
  className?: string;
  label: string;
};

const CreateCustomTaskButton = ({ className, label }: CreateCustomTaskButtonProps) => {
  const customTaskContext = useContext(CustomTaskCreatorContext);

  const handleClick = () => {
    if (customTaskContext.open) customTaskContext.open();
    trackEvent('New_Task_add');
  };

  return <Button label={label} className={className} onClick={handleClick} dataId="new-task" />;
};

export default CreateCustomTaskButton;
