import { t } from 'shared/utils';

export const tickPaidByCashSuccess = {
  duration: 3000,
  title: t('notifications.invoices.paid_by_cash.tick.success'),
  variant: 'success',
};

export const tickPaidByCashFailure = {
  duration: 3000,
  title: t('notifications.invoices.paid_by_cash.tick.failure'),
  variant: 'warning',
};

export const untickPaidByCashSuccess = {
  duration: 3000,
  title: t('notifications.invoices.paid_by_cash.untick.success'),
  variant: 'success',
};

export const untickPaidByCashFailure = {
  duration: 3000,
  title: t('notifications.invoices.paid_by_cash.untick.failure'),
  variant: 'warning',
};

export const tickPaidByUnspecifiedSuccess = {
  duration: 3000,
  title: t('notifications.invoices.paid_by_unspecified.tick.success'),
  variant: 'success',
};

export const tickPaidByUnspecifiedFailure = {
  duration: 3000,
  title: t('notifications.invoices.paid_by_unspecified.tick.failure'),
  variant: 'warning',
};

export const untickPaidByUnspecifiedSuccess = {
  duration: 3000,
  title: t('notifications.invoices.paid_by_unspecified.untick.success'),
  variant: 'success',
};

export const untickPaidByUnspecifiedFailure = {
  duration: 3000,
  title: t('notifications.invoices.paid_by_unspecified.untick.failure'),
  variant: 'warning',
};
