import React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';
import { bool, func, shape, string } from 'prop-types';

import styles from './Tab.module.css';

const Tab = ({ theme = {}, heading, active, onClick = noop, dataId = '' }) => (
  <div
    data-id={dataId}
    className={cx(styles.tab, theme.tab, {
      [styles.activeTab]: active,
      [styles.inactiveTab]: !active,
      [theme.activeTab]: active,
      [theme.inactiveTab]: !active,
    })}
  >
    <button type="button" onClick={onClick} data-id="TabHeader">
      {heading}
    </button>
  </div>
);

Tab.propTypes = {
  theme: shape({}),
  active: bool.isRequired,
  onClick: func,
  heading: string.isRequired,
  dataId: string,
};

export default Tab;
