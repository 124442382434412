import { actionTypes } from 'redux-form';

import {
  OCR_MAX_FILES_COUNT as ocrMaxFilesCount,
  OCR_MAX_FILES_SIZE as ocrMaxFilesSize,
} from 'constants/common/ocr';
import { UPDATE_SUCCESS } from 'constants/incoming-invoice';
import { DEFAULT_UPLOAD_INFO as defaultUploadInfo } from 'reducers/common/uploaded-files';
import fetchingReducer from 'reducers/utils/fetching';
import needsAnotherDatev from 'reducers/utils/needs-another-datev-report';

import allBankTransfers from './all-bank-transfers';
import bankTransfers from './bank-transfers';
import details from './details';
import lineCategories from './line-categories';
import lineItems from './line-items';
import ocr from './ocr';
import oldLineCategories from './old-line-categories';
import payments from './payments';
import paymentsBankAccounts from './payments-bank-accounts';
import { persistedSupplier } from './supplier';
import uploadedFiles from './uploaded-files';

const needsDatevReducer = needsAnotherDatev(UPDATE_SUCCESS);

const incomingInvoice = (state = {}, action) => {
  return {
    ...fetchingReducer(state, action),
    details: details(state.details, action),
    lineItems: lineItems(state.lineItems, action),
    lineCategories: lineCategories(state.lineCategories, action),
    oldLineCategories: oldLineCategories(state.oldLineCategories, action),
    bankTransfers: bankTransfers(state.bankTransfers, action),
    allBankTransfers: allBankTransfers(state.allBankTransfers, action),
    payments: payments(state.payments, action),
    paymentsBankAccounts: paymentsBankAccounts(state.paymentsBankAccounts, action),
    uploadedFiles: uploadedFiles(state.uploadedFiles, action),
    ocr: ocr(state.ocr, action),
    persistedSupplier: persistedSupplier(state.persistedSupplier, action),
    postCreateStatus: needsDatevReducer(state.postCreateStatus, action),
  };
};

export const OCR_MAX_FILES_COUNT = ocrMaxFilesCount;
export const OCR_MAX_FILES_SIZE = ocrMaxFilesSize;

export const DEFAULT_UPLOAD_INFO = defaultUploadInfo;

export default (state, action) => {
  if (action.type === actionTypes.DESTROY && action.meta.form.includes('invoiceCreator')) {
    /* Clean all state on quit */
    return incomingInvoice({}, action);
  }

  return incomingInvoice(state, action);
};
