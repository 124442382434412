import * as React from 'react';

const Alert = (props: any) => (
  <svg width={32} height={31} viewBox="0 0 32 31" {...props}>
    <defs>
      <path
        d="M15.84 0c.366 0 .707.176.922.47l.075.115 14.697 27.941c.198.355.195.792-.01 1.143-.182.315-.5.521-.853.563l-.134.008H1.143a1.151 1.151 0 01-1.054-1.593l.059-.121L14.843.585c.203-.362.583-.585.997-.585zm1.65 23.52h-3.3v3.36h3.3v-3.36zm0-13.44h-3.3V21h3.3V10.08z"
        id="prefix__a"
      />
    </defs>
    <use fill="#FFF" xlinkHref="#prefix__a" fillRule="evenodd" />
  </svg>
);

export default Alert;
