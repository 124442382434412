import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import IconArrowDown from 'redesign/components/atoms/Icons/ArrowDown';
import BaseIconClose from 'redesign/components/atoms/Icons/Close';
import BaseIconHamburger from 'redesign/components/atoms/Icons/Hamburger';
import BaseIconSearch from 'redesign/components/atoms/Icons/Search';
import BaseIconUserprofile from 'redesign/components/atoms/Icons/UserProfile';
import { Breakpoints } from 'redesign/styles/breakpoints';

type WrapperProps = {
  $areNotificationsShown?: boolean;
};

export const Wrapper = styled.header<WrapperProps>`
  display: grid;
  grid-template-areas: 'hamburger logo search profile';
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  height: 72px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 3px 3px 10px 0 ${({ theme }) => transparentize(0.6, theme.colors.nightblue['100'])};
  padding: ${({ theme }) => `0 ${theme.space['4']}`};
  position: relative;
  z-index: 50005;
  margin-right: ${({ $areNotificationsShown }) => $areNotificationsShown && '138px'};

  @media (min-width: ${Breakpoints.lg}) {
    margin-right: ${({ $areNotificationsShown }) => $areNotificationsShown && '169px'};
  }

  @media (min-width: ${Breakpoints.lg}) and (any-hover: hover) and (any-pointer: fine) {
    grid-template-columns: auto 1fr minmax(0, 476px) auto;
  }
`;

export const IconHamburger = styled(BaseIconHamburger)`
  width: 40px;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  grid-area: hamburger;
  margin: 0 0.4375rem 0 -0.4375rem;
  cursor: pointer;

  @media (min-width: ${Breakpoints.lg}) and (any-hover: hover) and (any-pointer: fine) {
    display: none;
  }
`;

export const Logo = styled.img`
  width: 160px;
  height: 14px;
  display: none;
  grid-area: logo;

  img {
    width: 160px;
    height: 14px;
  }

  @media (min-width: ${Breakpoints.sm}) {
    display: block;
  }
`;

export const SearchWrapper = styled.div`
  grid-area: search;
  margin: 0 ${({ theme }) => theme.space['2']};

  @media (min-width: ${Breakpoints.lg}) and (any-hover: hover) and (any-pointer: fine) {
    margin: 0 ${({ theme }) => theme.space['10']};
  }
`;

type SearchContentProps = {
  $isActive?: boolean;
  $areNotificationsShown?: boolean;
};

export const SearchContent = styled.div<SearchContentProps>`
  background-color: ${({ theme }) => theme.colors.white};
  display: ${({ $isActive }) => ($isActive ? 'grid' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  right: ${({ $areNotificationsShown }) => ($areNotificationsShown ? '-138px' : 0)};
  box-shadow: 3px 3px 10px 0 ${({ theme }) => transparentize(0.6, theme.colors.nightblue['100'])};
  padding: ${({ theme }) => theme.space['3']};
  grid-template-columns: minmax(0, 476px) auto;
  justify-content: center;
  align-items: center;
  grid-gap: ${({ theme }) => theme.space['2']};

  @media (min-width: ${Breakpoints.lg}) {
    right: ${({ $areNotificationsShown }) => $areNotificationsShown && '-169px'};
  }
`;

type SearchIconWrapperProps = {
  $isActive?: boolean;
};

export const SearchIconWrapper = styled.div<SearchIconWrapperProps>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  cursor: pointer;
  position: relative;

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.vrblue['075']};

      &::after {
        content: '';
        background-color: ${({ theme }) => theme.colors.vrblue['075']};
        position: absolute;
        bottom: -5px;
        width: 100%;
        height: 4px;
      }
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      &::after {
        content: '';
        background-color: ${({ theme }) => theme.colors.vrblue['100']};
        position: absolute;
        bottom: -5px;
        width: 100%;
        height: 4px;
      }
    `}
`;

export const IconSearch = styled(BaseIconSearch)`
  width: 40px;
`;

export const IconClose = styled(BaseIconClose)`
  width: 32px;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.vrblue['075']};
    }
  }
`;

export const UserProfileWrapper = styled.div`
  grid-area: profile;
`;

type UserProfileProps = {
  $isActive?: boolean;
};

export const UserProfile = styled.div<UserProfileProps>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  cursor: pointer;
  position: relative;

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.vrblue['075']};

      &::after {
        content: '';
        background-color: ${({ theme }) => theme.colors.vrblue['075']};
        position: absolute;
        bottom: -5px;
        width: 100%;
        height: 4px;
      }
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      &::after {
        content: '';
        background-color: ${({ theme }) => theme.colors.vrblue['100']};
        position: absolute;
        bottom: -5px;
        width: 100%;
        height: 4px;
      }
    `}
`;

export const IconUserprofile = styled(BaseIconUserprofile)`
  width: 40px;
`;

export const Username = styled.span`
  font-size: 15px;
  font-weight: bold;
  margin-left: ${({ theme }) => theme.space['2']};
  display: none;
  user-select: none;
  padding-right: 4px;

  @media (min-width: ${Breakpoints.sm}) {
    display: initial;
  }
`;

export const Menu = styled.div`
  min-width: 220px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.4);
  overflow: hidden;
`;

type MenuItemProps = {
  $color?: 'red';
};

export const MenuItem = styled.span<MenuItemProps>`
  display: block;
  font-weight: ${({ $color }) => $color === 'red' && 'bold'};
  letter-spacing: 0.66px;
  color: ${({ theme, $color }) =>
    $color === 'red' ? theme.colors.red['100'] : theme.colors.grey['080']};
  padding: ${({ theme }) => theme.space['3']};

  &:hover {
    background-color: ${({ theme }) => theme.colors.vrblue['025']};
    text-decoration: none;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.grey['080']};
    cursor: pointer;
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.vrblue['100']};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const MenuItemUsername = styled.span`
  display: block;
  letter-spacing: 0.29px;
  color: ${({ theme }) => theme.colors.grey['070']};
  padding: ${({ theme }) => theme.space['3']};

  @media (min-width: ${Breakpoints.sm}) {
    display: none;
  }
`;

type ArrowProps = {
  $isOpen: boolean;
};

export const Arrow = styled(IconArrowDown)<ArrowProps>`
  width: 24px;
  transition: transform 0.3s ease;
  display: none;

  @media (min-width: ${Breakpoints.sm}) {
    display: initial;
  }

  ${({ $isOpen }) => $isOpen && 'transform: rotate(180deg)'};
`;
