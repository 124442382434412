import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push as pushAction } from 'connected-react-router';
import noop from 'lodash/noop';
import snakeCase from 'lodash/snakeCase';
import { bool, func, string } from 'prop-types';
import { Field } from 'redux-form';

import { EDIT_MODE } from 'constants/common/crud';
import HintImage from 'images/hint.svg';
import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import BoxWithButton from 'components/BoxWithButton/BoxWithButton';
import Card from 'components/Card';
import CategorySearch from 'components/CategorySearch/CategorySearch';
import { DateField, TextField } from 'components/Form';
import I18n from 'components/I18n';
import { CategoryChangedConfirmationModal } from 'components/LineItems/components/CategoryChangedConfirmationModal/CategoryChangedConfirmationModal';
import { DatevWarningModal } from 'components/LineItems/components/DatevWarningModal/DatevWarningModal';
import { VatIdModal } from 'components/VatIdModal/VatIdModal';

import Client from './Client';

import styles from './CreateProposalSection.module.css';

const FormField = ({ name, readonly, placeholder, ...rest }) => (
  <div className={styles.formGroup}>
    <Field
      id={name}
      name={name}
      checker={checker}
      disabled={readonly}
      label={t(`proposals.form.${snakeCase(name)}`)}
      placeholder={placeholder && t(`proposals.form.${snakeCase(name)}`)}
      {...rest}
    />
  </div>
);

FormField.propTypes = {
  name: string.isRequired,
  readonly: bool,
};

const CreateProposalSection = ({
  readonly = false,
  onClientSelect = noop,
  lineCategories,
  selectedCategory,
  crudMode,
  isDeprecatedCategory,
  handleSelectCategory,
  category,
  showCategoryChangeConfirmation,
  setShowCategoryChangeConfirmation,
  showVatIdWarning,
  setShowVatIdWarning,
  showDifferentCategoryWarning,
  setShowDifferentCategoryWarning,
}) => {
  const isEditInvoice = crudMode === EDIT_MODE;
  return (
    <>
      <Card className={styles.main}>
        <Card.Header className={styles.header}>
          <I18n t="proposals.form.header_data" />
        </Card.Header>
        <Card.Body>
          <div className={cx(styles.columns, styles.columnsPadded)}>
            <div className={styles.column}>
              <FormField
                name="subject"
                component={TextField}
                readonly={readonly}
                dataId="ProposalPage:input-subject"
              />
              <Client
                selectDataIds={{
                  container: 'ProposalPage:select-client',
                  input: 'ProposalPage:input-client',
                  option: 'ProposalPage:option-client',
                }}
                onChange={onClientSelect}
                readonly={readonly}
              />
            </div>
            <div className={styles.column}>
              <FormField
                name="proposalNumber"
                component={TextField}
                readonly
                dataId="ProposalPage:input-number"
                disabledPlaceHolder={t('revenue.form.number_disabled')}
              />
              <div className={cx(styles.columns)}>
                <div className={styles.column}>
                  <FormField
                    name="proposalDate"
                    dataIds={{ input: 'ProposalPage:container-date' }}
                    component={DateField}
                    readonly={readonly}
                    required
                  />
                </div>
                <div className={styles.column}>
                  <FormField
                    name="validUntil"
                    dataIds={{ input: 'ProposalPage:container-valid-unit' }}
                    component={DateField}
                    readonly={readonly}
                    required
                  />
                </div>
              </div>
              {isDeprecatedCategory && isEditInvoice && (
                <BoxWithButton
                  isButtonDisabled
                  image={HintImage}
                  content={t('datev.deprecated_category_warning_proposal')}
                  dataId="Outgoinginvoices-CreateInvoiceSection:orange-warning-container"
                />
              )}

              <div>
                <FormField
                  name="invoiceLineCategoryId"
                  dataId="ProposalPage:select-category"
                  label={`${t('revenue.form.category')} *`}
                  component={CategorySearch}
                  categories={lineCategories}
                  selectedItem={selectedCategory}
                  onSelectCategory={handleSelectCategory}
                  disabled={readonly}
                  withIcon={!readonly}
                  initialSelectedItem={category}
                  initialInputValue={category?.name}
                  withHint
                />
              </div>
            </div>
          </div>
          <DatevWarningModal
            isOpen={!showVatIdWarning && showDifferentCategoryWarning}
            onConfirm={() => setShowDifferentCategoryWarning(false)}
          />
          <CategoryChangedConfirmationModal
            isOpen={!showVatIdWarning && showCategoryChangeConfirmation}
            onConfirm={() => setShowCategoryChangeConfirmation(false)}
          />
          <VatIdModal isOpen={showVatIdWarning} onConfirm={() => setShowVatIdWarning(false)} />
        </Card.Body>
      </Card>
    </>
  );
};

CreateProposalSection.propTypes = {
  readonly: bool,
  onClientSelect: func,
  push: func.isRequired,
};

const mapStateToProps = (state) => ({
  isDraft: state.proposal.details.draft,
});

const mapDispatchToProps = {
  push: pushAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProposalSection);
