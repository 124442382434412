import { FETCH_FAILURE, FETCH_REQUEST, FETCH_SUCCESS } from 'constants/payment-plan';

const defaultState = {
  details: {},
  features: [],
  error: false,
  isFetching: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        error: false,
        isFetching: true,
      };

    case FETCH_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };

    case FETCH_SUCCESS:
      const included = action.response.included || [];
      return {
        ...defaultState,
        details: {
          ...state.details,
          ...action.response.data.attributes,
        },
        features: [...included.map((item) => item.attributes)],
      };
    default:
      return state;
  }
};
