import React from 'react';

import styles from '../StatusIcons.module.css';

const UnpaidStatusIcon = () => (
  <svg viewBox="0 0 20 20" className={styles.statusIcon} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <rect className={styles.strokeNeutral} x=".5" y=".5" width="19" height="19" rx="2" />
      <g className={styles.fillNeutral} fillRule="nonzero">
        <path d="M10 14.207c-.543.297-1.268.467-2.045.467-1.617 0-3-.73-3-1.813V11.05a1.283 1.283 0 0 1-.005-.108c0-.023.002-.046.005-.068V4.549c0-.098.028-.19.076-.267A.777.777 0 0 0 5 4.5c0 .433.367.823.955 1.097v5.39c.068.357.895.766 1.995.766 1.144 0 2.005-.452 2.005-.812 0-.02 0-.04.003-.06l-.003-5.243c.515-.221.877-.528 1-.877l.003 5.521H10v3.925zM5.955 12.32v.541c0 .358.863.813 2 .813 1.134 0 2.004-.459 2.004-.812v-.551c-.538.282-1.247.442-2.009.442-.758 0-1.461-.156-1.995-.433z" />
        <path d="M9.955 4.54c0-.36-.864-.813-2.005-.813-1.145 0-2 .448-2 .813 0 .366.852.813 2 .813 1.14 0 2.005-.455 2.005-.813zm1 0c0 1.084-1.385 1.813-3.005 1.813-1.628 0-3-.72-3-1.813s1.375-1.813 3-1.813c1.62 0 3.005.727 3.005 1.813z" />
        <path d="M9.955 6.657a.5.5 0 0 1 1 0c0 1.084-1.385 1.813-3.005 1.813-1.618 0-2.995-.729-2.995-1.813a.5.5 0 0 1 1 0c0 .36.856.813 1.995.813 1.14 0 2.005-.455 2.005-.813z" />
        <path d="M9.955 8.801a.5.5 0 0 1 1 0c0 1.084-1.385 1.813-3.005 1.813-1.628 0-3-.72-3-1.813a.5.5 0 0 1 1 0c0 .366.852.813 2 .813 1.14 0 2.005-.455 2.005-.813z" />
        <g>
          <path d="M15.008 11.31c.039.072.06.153.06.24l-.013 3.936c0 1.096-1.376 1.813-3.005 1.813-1.617 0-3.005-.73-3.005-1.813V11.55c0 .444.387.843 1 1.118v2.82c0 .356.867.812 2.005.812 1.15 0 2.005-.446 2.005-.814l.01-2.827c.602-.274.98-.67.98-1.11a.78.78 0 0 0-.037-.238z" />
          <path d="M14.045 11.496c0-.36-.856-.812-1.995-.812-1.138 0-2.005.456-2.005.812 0 .359.86.813 1.996.813 1.138 0 2.004-.456 2.004-.813zm1 0c0 1.083-1.387 1.813-3.004 1.813-1.616 0-2.996-.73-2.996-1.813s1.388-1.812 3.005-1.812c1.618 0 2.995.726 2.995 1.812z" />
          <path d="M14.045 13.5a.5.5 0 1 1 1 0c0 1.083-1.387 1.813-3.004 1.813-1.621 0-2.996-.723-2.996-1.813a.5.5 0 0 1 1 0c0 .363.854.813 1.996.813 1.138 0 2.004-.456 2.004-.813z" />
        </g>
      </g>
    </g>
  </svg>
);

export default UnpaidStatusIcon;
