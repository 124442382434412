import React from 'react';
import cx from 'classnames';

import styles from './InputsGroup.module.css';

interface InputsGroupProps {
  children: React.ReactNode;
  label: string;
  containerClass?: string;
}

const InputsGroup = ({ children, label, containerClass = '' }: InputsGroupProps) => (
  <div className={cx(styles.container, containerClass)}>
    <div className={styles.label}>{label}</div>
    <div className={styles.inputs}>{children}</div>
  </div>
);

export default InputsGroup;
