import React, { memo, ReactNode } from 'react';

import { useIsMdUp } from 'shared/hooks/useMedia';

import Body from '../Table/Body';
import BodyCell from '../Table/BodyCell';
import BodyRow from '../Table/BodyRow';
import Table from '../Table/Table';

import sharedTableStyles from '../ExpensesRevenues.module.css';
import styles from './OtherLayout.module.css';

type OtherLayoutProps = {
  nameField: ReactNode;
  amountField: ReactNode;
};

const OtherLayout = ({ nameField, amountField }: OtherLayoutProps) => {
  const isMdUp = useIsMdUp();

  return isMdUp ? (
    <Table>
      <Body>
        <BodyRow>
          <BodyCell className={styles.nameBodyCell}>{nameField}</BodyCell>
          <BodyCell className={sharedTableStyles.amountBodyCell}>{amountField}</BodyCell>
          <BodyCell className={sharedTableStyles.actionBodyCell} />
        </BodyRow>
      </Body>
    </Table>
  ) : (
    <div className={styles.container}>
      {nameField}
      {amountField}
    </div>
  );
};

export default memo(OtherLayout);
