import React from 'react';
import cx from 'classnames';

import { t } from 'shared/utils';
import CardView, { HeadingSection, Section } from 'components/CardView';
import Logo from 'components/Logo';

import sharedStyles from '../Shared.module.css';
import localStyles from './RegistrationSuccess.module.css';

const RegistrationSuccess = () => (
  <div className={sharedStyles.pageWrapper}>
    <div className={sharedStyles.wrapper}>
      <CardView className={sharedStyles.card}>
        <HeadingSection className={sharedStyles.cardSectionHeading}>
          <Logo />
        </HeadingSection>
        <Section
          className={cx(
            sharedStyles.cardSection,
            sharedStyles.contentSection,
            sharedStyles.withExtraRightPadding
          )}
        >
          <p className={cx(localStyles.thanks)}>{t('request_registration.success_thank_you')}</p>
          <div className={cx(localStyles.instruction, localStyles.instructionLarge)}>
            <b>{t('request_registration.success_subtitle_1')}</b>
            <p>{t('request_registration.success_instruction_1')}</p>
          </div>
          <div className={localStyles.instruction}>
            <b>{t('request_registration.success_subtitle_2')}</b>
            <p>{t('request_registration.success_instruction_2')}</p>
          </div>
          <div className={localStyles.instruction}>
            <b>{t('request_registration.success_subtitle_3')}</b>
            <p>{t('request_registration.success_instruction_3')}</p>
          </div>
        </Section>
      </CardView>
    </div>
  </div>
);

export default RegistrationSuccess;
