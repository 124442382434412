import { camelCase, get, isEmpty, zipObjectDeep } from 'lodash';
import { SubmissionError } from 'redux-form';

import { showNotification } from 'actions/notification';
import validationNotification from 'notifications/validation';
import { mapKeysDeep } from 'shared/utils';

const uniqueErrorNotification = (error) => ({
  duration: 5000,
  title: error,
  variant: 'warning',
});

export const mapServerErrorsToFieldErrors = (errors) => {
  const ids = errors.map(({ id }) => id);
  const details = errors.map(({ detail }) => detail);

  return mapKeysDeep(zipObjectDeep(ids, details), (_, k) => camelCase(k));
};

export const mapServerErrorsToNotificationError = (errors) => errors.map(({ detail }) => detail);

export const bindServerValidation = (
  action,
  dispatch,
  {
    isReduxForm = true,
    customTitle = '',
    /* middleware can be used to modify errors object or do any other action that is required */
    catchMiddleware = (error) => {
      throw error;
    },
  } = {
    catchMiddleware: (error) => {
      throw error;
    },
  },
  errorModifiers
) =>
  dispatch(action)
    .catch(catchMiddleware)
    .catch(({ response, ...rest }) => {
      if (!response || response.status !== 422) {
        return Promise.reject({ response, ...rest });
      }

      const error = get(response, 'data.error', {});
      const errors = get(response, 'data.errors', []).map((error) => {
        const modified = errorModifiers && errorModifiers[error.id];

        return { ...error, ...modified };
      });

      !isEmpty(error) && dispatch(showNotification(uniqueErrorNotification(error)));

      if (!isEmpty(errors)) {
        const submissionErrors = mapServerErrorsToFieldErrors(errors);

        const notification = validationNotification(
          mapServerErrorsToNotificationError(errors, errorModifiers),
          customTitle
        );

        dispatch(showNotification(notification));

        throw isReduxForm ? new SubmissionError(submissionErrors) : submissionErrors;
      }
    });
