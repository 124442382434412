import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { Field, reduxForm } from 'redux-form';

import { passwordReset } from 'actions/password-reset';
import PasswordField from 'containers/RegistrationForm/Form/PasswordField/PasswordField';
import passwordValidator from 'containers/RegistrationForm/Form/PasswordField/validator';
import PasswordNotification from 'containers/RegistrationForm/Form/PasswordNotification/PasswordNotification';
import paths from 'routes/paths';
import { t } from 'shared/utils';
import CardView, { HeadingSection, Section } from 'components/CardView';
import { FieldsGroup, SubmitButton } from 'components/Form';
import I18n from 'components/I18n';
import Logo from 'components/Logo';

import formPageStyles from '../Shared.module.css';
import localStyles from './ResetPassword.module.css';

const styles = { ...formPageStyles, ...localStyles };

let Form = ({ handleSubmit, submitPasswordReset, submitting, submitSucceeded }) => {
  const formContents = () => (
    <div>
      <FieldsGroup>
        <PasswordField
          name="password"
          placeholder={`${t('password_reset.reset_password.password')}`}
          dataId="input-password"
        />
        <PasswordField
          name="passwordConfirm"
          placeholder={`${t('password_reset.reset_password.password_confirm')}`}
          dataId="input-password-confirm"
        />
        <PasswordNotification />
        <Field component="input" hidden name="resetPasswordToken" />
      </FieldsGroup>
      <FieldsGroup>
        <SubmitButton
          value={t('password_reset.reset_password.submit')}
          active={!submitting}
          large
          dataId="button-submit"
        />
      </FieldsGroup>
    </div>
  );
  const success = () => (
    <FieldsGroup className={styles.successMessage}>
      <I18n t="password_reset.reset_password.success" data-id="message-success" />
      <div className={styles.contentSection}>
        <Link to={paths.login} className={styles.button} data-id="link-login">
          {t('login.title')}
        </Link>
      </div>
    </FieldsGroup>
  );
  return (
    <form onSubmit={handleSubmit(submitPasswordReset)}>
      {submitSucceeded ? success() : formContents()}
    </form>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitPasswordReset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
};

const validate = (values) => ({
  password: passwordValidator(values),
  passwordConfirm: passwordValidator(values),
});

const mapDispatchToProps = (dispatch) => ({
  submitPasswordReset(values) {
    dispatch(passwordReset(values));
  },
});

Form = reduxForm({ form: 'reset_password', validate })(connect(null, mapDispatchToProps)(Form));

export default connect((s) => s.injected)(({ location }) => {
  const { reset_password_token: resetPasswordToken } = parse(location.search);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.wrapper}>
        <CardView className={styles.card}>
          <HeadingSection className={styles.cardSectionHeading}>
            <h3 className={styles.headingText}>{t('password_reset.reset_password.title')}</h3>
          </HeadingSection>
          <Section className={styles.cardSection}>
            <Logo className={styles.logo} />
            <Form initialValues={{ resetPasswordToken }} />
          </Section>
        </CardView>
      </div>
    </div>
  );
});
