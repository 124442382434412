import React from 'react';

import styles from '../StatusIcons.module.css';

const AppUploadStatusIcon = () => (
  <svg viewBox="0 0 20 20" className={styles.statusIcon} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <rect className={styles.strokeNeutral} x=".5" y=".5" width="19" height="19" rx="2" />
      <path d="M2 2h16v16H2z" />
      <g fillRule="nonzero">
        <path
          d="M13.714 2.007L7.286 2C6.579 2 6 2.655 6 3.455v13.09c0 .8.579 1.455 1.286 1.455h6.428c.707 0 1.286-.655 1.286-1.455V3.455c0-.8-.579-1.448-1.286-1.448zm.227 14.049H7.06V3.944h6.882v12.112z"
          className={styles.fillNeutral}
        />
        <g className={styles.strokeNeutral} strokeLinecap="round">
          <path d="M8.2 6.2h2.4M8.2 6.2v2.4" />
        </g>
        <g className={styles.strokeNeutral} strokeLinecap="round">
          <path d="M12.8 14.8h-2.4M12.8 14.8v-2.4" />
        </g>
      </g>
    </g>
  </svg>
);

export default AppUploadStatusIcon;
