import React, { Component } from 'react';
import { Cookies, withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import cx from 'classnames';
import { bool, func, instanceOf, number, string } from 'prop-types';

import {
  hideSidebar as hideSidebarAction,
  showSidebar as showSidebarAction,
} from 'actions/notifications-sidebar';
import {
  IGNORE_OUTSIDE_CLICK_CLASS as IGNORE_OUTSIDE_NOTIFICATIONS_SIDEBAR_CLICK_CLASS,
  SidebarSections,
} from 'constants/notifications-sidebar';
import { messagesEnabledHelper, todoListEnabledHelper } from 'routes/accesses';
import { getVisitedTaskPlanner } from 'store/slices/onboarding/selectors';
import { updateOnboarding } from 'store/slices/onboarding/thunks';
import { IGNORE_OUTSIDE_CLICK_CLASS as IGNORE_OUTSIDE_LINE_ITEM_CLICK_CLASS } from 'components/LineItems';
import SectionButton from 'components/NotificationsSidebar/NotificationsHeader/SectionButton/SectionButton';
import IconBell from 'redesign/components/atoms/Icons/Bell';
import IconInbox from 'redesign/components/atoms/Icons/Inbox';

import styles from './NotificationsHeader.module.css';

class NotificationsHeader extends Component {
  onSectionIconClick = (section) => () => {
    const { isSidebarOpen, activeSection, hideSidebar, updateOnboarding, isTaskPlannerVisited } =
      this.props;
    if (!isTaskPlannerVisited) updateOnboarding({ visited_task_planner: true });

    return isSidebarOpen && section === activeSection
      ? hideSidebar()
      : this.showSidebarAndScroll(section);
  };

  isSectionActive = (section) => section === this.props.activeSection;

  showSidebarAndScroll = (section) => {
    this.props.showSidebar(section);
  };

  render() {
    const { isSidebarOpen, todosCount, unreadMessagesTotal, todoListEnabled, messagesEnabled } =
      this.props;

    return (
      <div
        className={cx(
          styles.container,
          IGNORE_OUTSIDE_NOTIFICATIONS_SIDEBAR_CLICK_CLASS,
          IGNORE_OUTSIDE_LINE_ITEM_CLICK_CLASS,
          {
            [styles.fixed]: isSidebarOpen,
          }
        )}
      >
        {todoListEnabled && (
          <SectionButton
            count={todosCount}
            icon={IconBell}
            isActive={this.isSectionActive(SidebarSections.TODOS)}
            onClickAction={this.onSectionIconClick(SidebarSections.TODOS)}
            dataId="MainHeader:button-toToList"
          />
        )}
        {messagesEnabled && (
          <SectionButton
            count={unreadMessagesTotal}
            icon={IconInbox}
            isActive={this.isSectionActive(SidebarSections.MESSAGES)}
            onClickAction={this.onSectionIconClick(SidebarSections.MESSAGES)}
            dataId="MainHeader:button-message-inbox"
          />
        )}
      </div>
    );
  }
}

NotificationsHeader.propTypes = {
  isSidebarOpen: bool,
  activeSection: string,
  todosCount: number,
  unreadMessagesTotal: number,
  showSidebar: func,
  hideSidebar: func,
  todoListEnabled: bool,
  messagesEnabled: bool,
  cookies: instanceOf(Cookies).isRequired,
  isTaskPlannerVisited: bool,
};

const mapStateToProps = (state) => ({
  isSidebarOpen: state.notificationsSidebar.isOpen,
  activeSection: state.notificationsSidebar.activeSection,
  todosCount: !getVisitedTaskPlanner(state) ? 1 : state.customerNotifications.notificationsCount,
  todoListEnabled: todoListEnabledHelper(state),
  messagesEnabled: messagesEnabledHelper(state),
  unreadMessagesTotal: state.messageInbox.unreadMessagesTotal,
  isTaskPlannerVisited: getVisitedTaskPlanner(state),
});

const mapDispatchToProps = {
  showSidebar: showSidebarAction,
  hideSidebar: hideSidebarAction,
  updateOnboarding,
};

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(NotificationsHeader));
