import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchCountries } from 'actions/countries';
import { shape as countriesShape } from 'reducers/countries';

const parseCountriesForReactSelect = (countires) => ({
  ...countires,
  data: countires.data.map((country) => ({
    value: country.code,
    label: country.name,
  })),
});

const withCountries = (Comp) => {
  class WithCountries extends Component {
    static propTypes = {
      fetchCountries: PropTypes.func.isRequired,
      countries: countriesShape,
    };
    componentDidMount() {
      const { countries } = this.props;
      if (countries.data.length) return;
      this.props.fetchCountries();
    }

    render() {
      return <Comp {...this.props} />;
    }
  }

  const displayName = Comp.displayName || Comp.name || 'Component';

  WithCountries.displayName = `withCountries(${displayName})`;
  return connect(
    (state) => ({
      countries: parseCountriesForReactSelect(state.countries),
    }),
    { fetchCountries }
  )(WithCountries);
};
export default withCountries;
