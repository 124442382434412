import find from 'lodash/find';
import get from 'lodash/get';

export default function parseOutgoingInvoice(data, included) {
  const relationshipKey = get(data, 'relationships.persisted-client.data')
    ? 'persisted-client'
    : 'client';

  const relationshipId = get(data, `relationships.${relationshipKey}.data.id`);
  const relationshipType = get(data, `relationships.${relationshipKey}.data.type`);

  const relationship = find(
    included,
    (client) => client.id === relationshipId && client.type === relationshipType
  );

  return {
    ...data.attributes,
    creditNote: data.attributes.creditNote,
    [relationshipKey]: relationship.attributes,
  };
}
