import Option from 'react-select/lib/Option';

export const optionWithKeyboardState = (isUsingKeyboard) => {
  class ExtendedOption extends Option {
    constructor(...props) {
      super(...props);
      this.usingKeyboard = isUsingKeyboard;
    }
    handleMouseEnter = (event) => {
      if (!this.usingKeyboard) {
        this.onFocus(event);
      }
      this.usingKeyboard = false;
    };

    handleMouseMove = (event) => {
      if (!this.usingKeyboard) {
        this.onFocus(event);
      }
      this.usingKeyboard = false;
    };
  }

  return ExtendedOption;
};

export default optionWithKeyboardState;
