import React, { Component } from 'react';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { func, shape, string } from 'prop-types';

import ExpandableTile from 'components/NotificationsSidebar/components/ExpandableTile/ExpandableTile';

import TodoFeatureTile from '../TodoFeatureTile/TodoFeatureTile';

import styles from './TodoFeature.module.css';

const EmptyHeader = () => <div className={styles.emptyHeader} />;

class TodoFeature extends Component {
  injectProps = () => {
    const { detailsHeader, detailsFooter, taskComponent, ...props } = this.props;
    return { ...props };
  };

  renderTasks = () => {
    const {
      feature: { tasks = [] },
      taskComponent = noop,
    } = this.props;
    return tasks.map((task, index) => (
      <div className={styles.taskWrapper} key={index}>
        {taskComponent({ task, ...this.injectProps() })}
      </div>
    ));
  };

  render() {
    const {
      feature,
      featureType,
      featureIcon,
      detailsHeader = EmptyHeader,
      detailsFooter = noop,
      className,
      onExpand,
    } = this.props;

    if (isNil(feature) || !feature.tasksCount) return null;

    return (
      <ExpandableTile
        tileComponent={() => (
          <TodoFeatureTile feature={feature} featureType={featureType} featureIcon={featureIcon} />
        )}
        className={className}
        onExpand={onExpand}
      >
        {() => (
          <div className={styles.detailsContainer}>
            {detailsHeader(this.injectProps())}
            {this.renderTasks()}
            {detailsFooter(this.injectProps())}
          </div>
        )}
      </ExpandableTile>
    );
  }
}

TodoFeature.propTypes = {
  feature: shape({}),
  featureType: string,
  featureIcon: string,
  detailsHeader: func,
  detailsFooter: func,
  taskComponent: func.isRequired,
  className: string,
  onExpand: func,
};

export default TodoFeature;
