import React, { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';

import { t } from 'shared/utils';

import Table from './components/Table';

import styles from '../AssignmentSection.module.css';

const ResourcesSection = ({
  connectedResources,
  disconnectResource,
  connectResource,
  bankTransferConnections,
  anyLabels,
  onEdit,
}) => {
  const handleEdit = useCallback(
    (resource) => onEdit({ resourceType: resource.resourceType, id: resource.id }),
    [onEdit]
  );

  const handleDelete = useCallback(
    (resource) =>
      disconnectResource({
        resourceId: resource.id,
        resourceType: resource.resourceType,
      })(),
    [disconnectResource]
  );

  if (isEmpty(connectedResources) && !anyLabels) {
    return (
      <span className={styles.noAssignedResource}>
        {t('bank_transfers.table.no_assigned_resources')}
      </span>
    );
  }

  return (
    <Table
      connectedResources={connectedResources}
      bankTransferConnections={bankTransferConnections}
      connectResource={connectResource}
      disconnectResource={disconnectResource}
      onEdit={handleEdit}
      onDelete={handleDelete}
    />
  );
};

ResourcesSection.propTypes = {
  connectedResources: arrayOf(
    shape({
      id: string,
      type: string,
    })
  ),
  connectResource: func.isRequired,
  disconnectResource: func.isRequired,
  isFetching: bool,
  isInEditMode: bool,
  resourceInEditModeType: string,
  resourceInEditModeId: oneOfType([string, number]),
  handleHideResource: func,
  bankTransferConnections: oneOfType([arrayOf(shape({})), shape({})]),
  anyLabels: bool.isRequired,
  onEdit: func.isRequired,
};

export default ResourcesSection;
