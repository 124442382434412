import { t } from 'shared/utils';

export const NAMESPACE = `CUSTOMER_NOTIFICATIONS`;

export const FETCH_REQUEST = `${NAMESPACE}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${NAMESPACE}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${NAMESPACE}/FETCH_FAILURE`;
export const ADD_CUSTOMER_TASK = `${NAMESPACE}/ADD_CUSTOMER_TASK`;
export const UPDATE_CUSTOMER_TASK = `${NAMESPACE}/UPDATE_CUSTOMER_TASK`;
export const DELETE_CUSTOMER_TASK = `${NAMESPACE}/DELETE_CUSTOMER_TASK`;

export const ACTIVE_POLLING_LOOP_FREQUENCY = 30000;
export const EXECUTE_ACTIVE_POLLING_LOOP = `${NAMESPACE}/START_ACTIVE_POLLING`;

export const FeatureTypeTranslations = {
  INCOMING: t('sidebar.types.incoming'),
  OUTGOING: t('sidebar.types.outgoing'),
};

export const FeatureStatuses = {
  OPEN: 'open',
  CLOSED: 'closed',
};
