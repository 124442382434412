import React from 'react';
import { shape } from 'prop-types';

import { t } from 'shared/utils';

import styles from './AccountData.module.css';

const AccountData = ({ data }) => (
  <div className={styles.container}>
    {Object.entries(data).map(([key, value]) => (
      <div className={styles.text} key={`${key}_${value}`}>
        {value && (
          <>
            <span>{t(`profile.payment_plan.direct_debit.${key}`)}: </span>
            <span>{value}</span>
          </>
        )}
      </div>
    ))}
    <span>{t('profile.payment_plan.direct_debit.repeating_payment')}</span>
  </div>
);

AccountData.propTypes = {
  data: shape({}),
};

export default AccountData;
