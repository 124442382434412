import React, { memo, SVGProps } from 'react';

const Bell = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 33 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M.5 0h32v32H.5z" />
      <path
        d="M17 29c1.444 0 2.625-1.2 2.625-2.667h-5.25C14.375 27.8 15.556 29 17 29zm7.875-8v-6.667c0-4.093-2.14-7.52-5.906-8.426V5c0-1.107-.88-2-1.969-2-1.09 0-1.969.893-1.969 2v.907c-3.754.906-5.906 4.32-5.906 8.426V21L6.5 23.667V25h21v-1.333L24.875 21zm-2.625 1.333h-10.5v-8c0-3.306 1.982-6 5.25-6s5.25 2.694 5.25 6v8z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(Bell);
