import React, { ReactNode } from 'react';

import { IconArrowRedirect, Link, Title } from './TileTitle.styled';

export type TileTitleProps = {
  children: ReactNode;
  to?: string;
  dataId?: string;
  onClick?: () => void;
};

const TileTitle = ({ children, to, dataId, onClick }: TileTitleProps) => {
  return to ? (
    <Title data-id={dataId} onClick={onClick}>
      <Link to={to}>
        {children} <IconArrowRedirect />
      </Link>
    </Title>
  ) : (
    <Title data-id={dataId} onClick={onClick}>
      {children}
    </Title>
  );
};

export default TileTitle;
