import React, { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Field } from 'redux-form';

import { toggleTermsOfUse } from 'actions/registration';
import { t } from 'shared/utils';
import isPressedEnter from 'shared/utils/keyboard-events';
import { Checkbox } from 'components/Form';

import styles from '../RegistrationForm.module.css';

const TermsOfUseCheckbox = () => {
  const dispatch = useDispatch();

  const onClickTermsOfUse = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    dispatch(toggleTermsOfUse());
  };

  return (
    <Field component={Checkbox} name="termsAcceptance" dataIds={{ input: 'checkbox-terms' }}>
      <p>
        {`${t('registration.form.terms_of_use_1')} `}
        <span
          role="button"
          onKeyPress={isPressedEnter(onClickTermsOfUse)}
          className={styles.link}
          onClick={onClickTermsOfUse}
          tabIndex={-1}
          data-id="button-terms"
        >
          {`${t('registration.form.terms_of_use_2')}`}
        </span>
        <span>*</span>
      </p>
    </Field>
  );
};

export default TermsOfUseCheckbox;
