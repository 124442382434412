import styled from 'styled-components';

import BaseBox from 'redesign/components/atoms/Box/Box';
import BaseTabs from 'redesign/components/molecules/Tabs/Tabs';
import { Breakpoints } from 'redesign/styles/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${({ theme }) => `${theme.space['5']} ${theme.space['2']}`};

  @media (min-width: ${Breakpoints.sm}) {
    padding-left: ${({ theme }) => theme.space['4']};
    padding-right: ${({ theme }) => theme.space['4']};
  }
`;

export const Box = styled(BaseBox)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.grey['070']};
  font-size: ${({ theme }) => theme.fontSize['2xl']};
  letter-spacing: 0.5px;
  line-height: ${({ theme }) => theme.space['7']};
  margin-bottom: ${({ theme }) => theme.space['3']};
`;

export const Tabs = styled(BaseTabs)`
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.colors.grey['020']};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey['040']};
`;
