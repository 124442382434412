import React, { memo } from 'react';

import { t } from 'shared/utils';

import NewContractDropdown from '../NewContractDropdown/NewContractDropdown';
import { CardView, Content, HeadingSection, Headline } from '../Shared.styled';
import ExpenseContractsTable from './ExpenseContractsTable';

const ExpenseContracts = () => (
  <CardView>
    <HeadingSection>
      <Headline>{t('contracts.index_view.expenses')}</Headline>
      <div>
        <NewContractDropdown />
      </div>
    </HeadingSection>
    <Content>
      <ExpenseContractsTable />
    </Content>
  </CardView>
);

export default memo(ExpenseContracts);
