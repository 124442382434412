import React, { Component, createContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import noop from 'lodash/noop';

import TabContent from './TabContent/TabContent';
import TabHeader from './TabHeader/TabHeader';

export { TabContent, TabHeader };

export const TabbedNavigationContext = createContext<TabbedNavigationContextValue>({
  selectedTabName: null,
  selectTab: noop,
});

export interface TabbedNavigationContextValue {
  selectedTabName: string | null;
  selectTab: (tabName: string) => void;
}

interface TabbedNavigationPassProps {
  children: React.ReactNode | (() => React.ReactNode);
  onTabSelected?: (tabName: string | null) => void;
  initialTabName?: string;
  shouldTabChange?: boolean;
  tabsPaths?: {
    [key: string]: string;
  };
}

type TabbedNavigationProps = RouteComponentProps<{}, {}> & TabbedNavigationPassProps;

interface TabbedNavigationState {
  selectedTabName: string | null;
}

class TabbedNavigation extends Component<TabbedNavigationProps, TabbedNavigationState> {
  state = {
    selectedTabName: this.props.tabsPaths
      ? this.props.tabsPaths[this.props.location.pathname]
      : this.props.initialTabName || null,
  };

  componentDidMount() {
    const { onTabSelected } = this.props;
    const { selectedTabName } = this.state;

    if (onTabSelected) onTabSelected(selectedTabName);
  }

  componentDidUpdate(prevProps: RouteComponentProps<{}, {}>) {
    const {
      location: { pathname },
      tabsPaths = {},
    } = this.props;
    const {
      location: { pathname: prevPathname },
    } = prevProps;

    const tabFromPath = tabsPaths[pathname] as string;

    if (pathname !== prevPathname && tabFromPath) this.selectTab(tabFromPath);
  }

  selectTab = (tabName: string) => {
    const { onTabSelected = noop, shouldTabChange = true } = this.props;

    if (!shouldTabChange) return;

    this.setState({
      selectedTabName: tabName,
    });
    if (onTabSelected) onTabSelected(tabName);
  };

  render() {
    const { selectedTabName } = this.state;
    const { children } = this.props;

    return (
      <TabbedNavigationContext.Provider
        value={{
          selectedTabName,
          selectTab: this.selectTab,
        }}
      >
        {children}
      </TabbedNavigationContext.Provider>
    );
  }
}

export default withRouter(TabbedNavigation);
