import React, { ReactNode, useCallback, useState } from 'react';
import { TableInstance } from 'react-table';

import { t } from 'shared/utils';

import {
  Button,
  Buttons,
  Filters,
  GlobalFilter,
  IconArrowDown,
  IconCancel,
} from './TableFilters.styled';

type TableFiltersProps<T extends object> = {
  instance: TableInstance<T>;
  buttons?: ReactNode;
  globalFilter?: ReactNode;
};

const TableFilters = <T extends object>({
  instance,
  buttons,
  globalFilter,
}: TableFiltersProps<T>) => {
  const {
    allColumns,
    setAllFilters,
    setGlobalFilter,
    state: { filters, globalFilter: globalFilterValue },
  } = instance;
  const [showFilters, setShowFilters] = useState(false);
  const hasFilters = allColumns.some((column) => column.canFilter);
  const isResetFiltersDisabled = !filters.length && !globalFilterValue;

  const handleResetFilters = useCallback(() => {
    if (filters.length) setAllFilters([]);
    if (globalFilterValue) setGlobalFilter(undefined);
  }, [setAllFilters, filters, globalFilterValue, setGlobalFilter]);

  const handleShowFilters = useCallback(() => {
    setShowFilters((show) => !show);
  }, []);

  return (
    <div data-id="filters">
      {globalFilter && <GlobalFilter>{globalFilter}</GlobalFilter>}
      {hasFilters && (
        <>
          <Buttons>
            <Button onClick={handleShowFilters} data-id="show-more-filters">
              <IconArrowDown $isRotated={showFilters} />
              {showFilters
                ? t('tables.filters.filters_group.full_text_search.close_additional_filters')
                : t('tables.filters.filters_group.full_text_search.open_additional_filters')}
            </Button>
            <Button onClick={handleResetFilters} disabled={isResetFiltersDisabled}>
              <IconCancel />
              {t('tables.filters.filters_group.full_text_search.close_additional_filters')}
            </Button>
            {buttons}
          </Buttons>
          {showFilters && (
            <Filters>
              {allColumns
                .filter((it) => it.canFilter)
                .map((column) => (
                  <div key={column.id}>{column.render('Filter')}</div>
                ))}
            </Filters>
          )}
        </>
      )}
    </div>
  );
};

export default TableFilters;
