import { DETAILS_SUCCESS } from 'constants/banks';

type BankDetailsAction = {
  type: string;
  response: {
    bic: string;
    name: string;
  };
};

export default (state = {}, action: BankDetailsAction) => {
  switch (action.type) {
    case DETAILS_SUCCESS:
      return { data: action.response };
    default:
      return state;
  }
};
