import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { addPassiveListener, removePassiveListener } from 'shared/utils/tab-visibility';
import Tab from 'components/Tabs/Tab';
import styles from 'components/Tabs/Tabs.module.css';

class Tabs extends Component {
  static propTypes = {
    isDirty: bool,
    theme: shape({
      main: string,
      tabs: string,
      content: string,
      primary: string,
    }),
    sections: arrayOf(
      shape({
        component: func.isRequired,
        heading: string.isRequired,
        path: string.isRequired,
      })
    ),
    location: shape({
      pathname: string.isRequired,
    }).isRequired,
    goTo: func.isRequired,
    areTabsVisible: bool,
  };

  static defaultProps = {
    isDirty: false,
    theme: {},
  };

  componentDidMount() {
    addPassiveListener(this.container, 'scroll', this.handleScroll);
    addPassiveListener(this.container, 'resize', this.handleScroll);
    this.handleScroll(this.props.sections.indexOf(this.activeSection));
  }

  componentWillUnmount() {
    removePassiveListener(this.container, 'scroll', this.handleScroll);
    removePassiveListener(this.container, 'resize', this.handleScroll);
  }

  switchTab = (section) => {
    this.props.goTo(section.path);
    this.handleScroll(this.props.sections.indexOf(section));
  };

  handleScroll = (index) => {
    if (typeof index === 'number' && this.container) {
      if (!index) {
        this.container.scrollLeft = 0;
      } else {
        const tab = this.container.children[index];
        const { left } = tab.getBoundingClientRect();
        const { left: containerLeft } = this.container.getBoundingClientRect();
        this.container.scrollLeft += left - containerLeft - 65;
      }
    }
  };

  render() {
    const {
      sections,
      location: { pathname },
      areTabsVisible,
    } = this.props;

    if (isEmpty(sections)) return null;

    const activeSection = sections.find((s) => s.path === pathname);
    this.activeSection = activeSection;

    const { component: Content, heading: currentHeading, ...rest } = activeSection;

    return (
      <div className={cx(styles.main, this.props.theme.main)}>
        {areTabsVisible && (
          <div className={cx(styles.tabs, this.props.theme.tabs)}>
            <div
              className={cx(styles.primary, this.props.theme.primary)}
              ref={(ref) => {
                this.container = ref;
              }}
            >
              {sections.map((section) => (
                <Tab
                  key={section.heading}
                  heading={section.heading}
                  theme={this.props.theme}
                  onClick={() => this.switchTab(section)}
                  active={activeSection === section}
                />
              ))}
            </div>
          </div>
        )}
        <div className={cx(styles.content, this.props.theme.content)}>
          <Content key={currentHeading} {...rest} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  goTo: (path) => dispatch(push(path)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tabs));
