import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { quickCreateSupplier } from 'actions/incoming-invoice';
import { FORM_NAME } from 'constants/incoming-invoices';
import { getCountryNameByCode } from 'reducers';
import { incomingInvoiceSelector } from 'reducers/form';
import { withCountries } from 'shared/hoc';
import { t } from 'shared/utils';
import SupplierField from 'components/SupplierField/SupplierField';

import { AddNew, SupplierRow } from './Supplier.styled';
import SupplierQuickCreator from './SupplierQuickCreator';

import styles from './Supplier.module.css';

const EmptyText = () => (
  <>
    {t('expenses.form.supplier_select.empty_suppliers_list_1')}
    <br />
    {t('expenses.form.supplier_select.empty_suppliers_list_2')}
  </>
);

class SupplierSection extends Component {
  state = {
    view: 'dropdown', // or form
  };

  switchView = (view) => () => {
    this.setState({ view });
  };

  renderForm = () => {
    const onCancel = this.switchView('dropdown');
    const submitHandler = this.props.submitHandler || this.props.onSubmitSupplier;
    const onSubmit = (values) => submitHandler(values).then(onCancel);

    return <SupplierQuickCreator onCancel={onCancel} onSubmit={onSubmit} />;
  };

  renderDropdown = () => {
    const {
      label = t('expenses.form.supplier'),
      placeholder = t('expenses.form.supplier_placeholder'),
      readonly,
      getCountry,
      supplier,
    } = this.props;

    return (
      <div className={supplier ? styles.mainWithBg : styles.main}>
        <SupplierRow>
          <Field
            name="supplier"
            label={label}
            component={SupplierField}
            disabled={readonly}
            placeholder={placeholder}
            helpText={t('expenses.form.supplier_select.help_text')}
            emptyText={<EmptyText />}
            required
          />
          {!supplier && (
            <AddNew onClick={this.switchView('form')} data-id="add-new">
              {t('expenses.form.supplier_select.add_new')}
            </AddNew>
          )}
        </SupplierRow>
        {supplier && (
          <div className={styles.supplierDetails}>
            <p className={styles.supplierName}>
              {supplier.firstName} {supplier.lastName}
            </p>
            <p>{supplier.companyName}</p>
            <p>{supplier.idNumber}</p>
            <p>{supplier.street}</p>
            <p>
              {supplier.zipcode} {supplier.city}
            </p>
            <p>{getCountry(supplier.country)}</p>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { required, className } = this.props;
    const { view } = this.state;

    return (
      <div className={cx(styles.formGroup, { [styles.required]: required }, className)}>
        {view === 'form' ? this.renderForm() : this.renderDropdown()}
      </div>
    );
  }
}

SupplierSection.propTypes = {
  label: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  onSubmitSupplier: PropTypes.func.isRequired,
  submitHandler: PropTypes.func,
  getCountry: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
  supplier: ownProps.supplier || incomingInvoiceSelector(state, 'supplier'),
  getCountry: getCountryNameByCode(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmitSupplier:
    ownProps.onSubmitSupplier || ((values) => quickCreateSupplier(FORM_NAME)(values)(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withCountries(SupplierSection));
