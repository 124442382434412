import React, { memo } from 'react';
import { Field, Validator } from 'redux-form';

import TextField from 'components/Form/TextField';

// TODO add TextFieldProps when TextField will support TS
type NameProps = {
  name: string;
  label: string;
  checker?: (props: any) => boolean;
  validate?: Validator;
  isLabelHidden?: boolean;
  dataId?: string;
};

const Name = ({ name, label, checker, validate, dataId, ...rest }: NameProps) => (
  <Field
    name={name}
    label={label}
    component={TextField}
    checker={checker}
    validate={validate}
    dataId={dataId}
    {...rest}
  />
);

export default memo(Name);
