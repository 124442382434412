import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

const Header = ({ children, ...rest }) => (
  <thead>{Children.map(children, (child) => cloneElement(child, { ...rest }))}</thead>
);

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
