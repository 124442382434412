import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import normalize from 'json-api-normalizer';
import { isEmpty } from 'lodash';
import build from 'redux-object';

import { showNotification } from 'actions/notification';
import { getIncomingInvoice } from 'api/me/incomingInvoices';
import * as InstallmentsAPI from 'api/me/installments';
import { getOutgoingInvoice } from 'api/me/outgoingInvoices';
import {
  OPTION_DISCOUNT,
  OPTION_FULLY_PAID,
  OPTION_JOKER,
  OPTION_PARTLY_PAID,
} from 'constants/assigment-select';
import EntityPath from 'constants/entitiesPaths';
import { DE_ACCOUNT_REVENUE_ASSIGNED, DE_KONTOUMSAETZE } from 'constants/kontoumsaetze';
import { MODAL_HEADER_VARIANT } from 'constants/modal';
import { connectionCreateFailure } from 'notifications/bank-transfer-connections';
import withContext from 'shared/hoc/withContext';
import getConnectionError from 'shared/utils/get-connection-error';
import { t } from 'shared/utils/index';
import { piwikHelpers } from 'shared/utils/piwik';
import { ResourceType } from 'types/entities/AssignableResource';
import { BankTransferConnection } from 'types/entities/BankTransferConnection';
import { BankTransferItem } from 'types/entities/BankTransferItem';
import { ExpenseInstallment, RevenueInstallment } from 'types/entities/Installment';
import { IncomingInvoice, OutgoingInvoice } from 'types/entities/Invoice';
import ActionButton from 'components/ActionPanel/ActionButton';
import Loading from 'components/Loading';

import { UnassignedBankTransferAmountConsumer } from '../../../components/UnassignedAmountContext';
import { Actions, Modal, ModalBody, ModalHeader } from './AssignmentModal.styled';
import BankTransferDetails from './Body/BankTransferDetails/BankTransferDetails';
import Options from './Body/Options';
import Summary from './Body/Summary';

type AssignmentModalProps = {
  bankTransfer: BankTransferItem;
  isOpen: boolean;
  onClose: () => void;
  resourceType: string;
  id: string;
  bankTransferConnection?: any;
  isInEditMode: boolean;
  connectResource: any;
};

const AssignmentModal = ({
  bankTransfer,
  isOpen,
  onClose,
  resourceType,
  id,
  isInEditMode = false,
  connectResource,
}: AssignmentModalProps) => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>(OPTION_FULLY_PAID.value);
  const [jokerValue, setJokerValue] = useState('');
  const [bankTransferConnection, setBankTransferConnection] =
    useState<BankTransferConnection | null>(null);
  const [inputValue, setInputValue] = useState<string | number>('');
  const [resource, setResource] = useState<any>(null);
  const [isInputInvalid, setIsInputInvalid] = useState(false);
  const isFullyPaid = selectedOption === OPTION_FULLY_PAID.value;
  const isPartlyPaid = selectedOption === OPTION_PARTLY_PAID.value;
  const isJoker = selectedOption === OPTION_JOKER.value;
  const isDiscount = selectedOption === OPTION_DISCOUNT.value;
  const isInvoice =
    resourceType === ResourceType.OutgoingInvoice || resourceType === ResourceType.IncomingInvoice;
  const isJokerFilled = inputValue && jokerValue;
  const isExpense =
    resourceType === ResourceType.IncomingInvoice ||
    (resourceType === ResourceType.ContractInstallment && !resource?.isRevenue);

  const diff = useMemo(() => {
    const parseCurrencyToNumber = (amount: string) => Number(String(amount).replace(',', '.'));

    if (!resource) return 0;

    // calculate transfer amount that is available for this assignment
    const transferAmount = bankTransferConnection
      ? Math.abs(bankTransfer.amountLeft) + bankTransferConnection.amountAssigned
      : Math.abs(bankTransfer.amountLeft);

    if (isFullyPaid) {
      const diff = bankTransferConnection
        ? // if there is already bank connection, then reduce gross amount to be paid by amount that is already assigned
          transferAmount - (resource.grossAmountToBePaid + bankTransferConnection.amountAssigned)
        : transferAmount - resource.grossAmountToBePaid;

      // if there is not enough money, then display transfer available (possible error message in the future)
      return diff < 0 ? transferAmount : diff;
    } else if (isPartlyPaid || isJoker) {
      const amountToAssign = parseCurrencyToNumber(inputValue as string);
      const diff = transferAmount - amountToAssign;

      // display original transfer amount, if amount, that user tries to assign, is greater than amount that is still to
      // be paid
      if (
        bankTransferConnection &&
        amountToAssign > resource.grossAmountToBePaid + bankTransferConnection.amountAssigned
      ) {
        return transferAmount;
      }

      return diff < 0 ? transferAmount : diff;
    } else if (isDiscount) {
      const amountAfterDiscount = resource.totalGrossAmount - resource.totalDiscountAmount;
      const diff = transferAmount - amountAfterDiscount;

      return diff < 0 ? transferAmount : diff;
    }

    return 0;
  }, [
    bankTransfer.amount,
    isFullyPaid,
    isPartlyPaid,
    isJoker,
    isDiscount,
    inputValue,
    resource,
    bankTransferConnection,
  ]);

  useEffect(() => {
    const fetchResource = async () => {
      setIsFetching(true);
      switch (resourceType) {
        case ResourceType.IncomingInvoice: {
          const response = await getIncomingInvoice(id);
          const resResource = build<IncomingInvoice>(
            normalize(response.data),
            EntityPath.IncomingInvoices,
            id
          );
          setResource(resResource);
          setIsFetching(false);
          break;
        }

        case ResourceType.OutgoingInvoice: {
          const response = await getOutgoingInvoice(id);
          const resResource = build<OutgoingInvoice>(
            normalize(response.data),
            EntityPath.OutgoingInvoices,
            id
          );
          setResource(resResource);
          setIsFetching(false);
          break;
        }

        default: {
          const response = await InstallmentsAPI.getInstallment(id);
          const resResource = build<ExpenseInstallment | RevenueInstallment>(
            normalize(response.data),
            EntityPath.ContractInstallments,
            id
          );
          setResource(resResource);
          setIsFetching(false);
          break;
        }
      }
    };

    if (id) fetchResource();
  }, [id, resourceType]);

  useEffect(() => {
    if (!isInEditMode || !resource) return;

    const bankTransferConnection = resource.bankTransferConnections.find(
      (connection: BankTransferConnection) =>
        connection.bankTransferId === Number(bankTransfer.id) &&
        connection.transferConnectableId === Number(resource.id)
    );

    if (!bankTransferConnection) return;

    setBankTransferConnection(bankTransferConnection);
    setSelectedOption(bankTransferConnection.selectedOption);

    if (bankTransferConnection.selectedOption === OPTION_PARTLY_PAID.value) {
      setInputValue(String(bankTransferConnection.amountAssigned));
    } else if (bankTransferConnection.selectedOption === OPTION_JOKER.value) {
      setInputValue(String(bankTransferConnection.amountAssigned));
      setJokerValue(resource.jokerOptionJustification);
    }
  }, [isInEditMode, resource, bankTransfer]);

  const handleClose = () => {
    setSelectedOption(OPTION_FULLY_PAID.value);
    onClose();
  };

  const handleOnAccept = async () => {
    if (
      (selectedOption === OPTION_PARTLY_PAID.value || selectedOption === OPTION_JOKER.value) &&
      !inputValue
    ) {
      dispatch(
        showNotification({
          ...connectionCreateFailure,
          title: t('bank_transfers.assigment.input_empty'),
        })
      );
    }
    try {
      await connectResource({
        isInEditMode,
        assigmentOption: selectedOption,
        resourceId: id,
        resourceType: resourceType,
        assignedValue: isDiscount ? null : inputValue,
        ...(isJoker && { jokerOptionReason: jokerValue }),
      })();
      handleClose();
    } catch (error) {
      const errorMessage = await getConnectionError(error);
      setIsInputInvalid(true);
      dispatch(showNotification(errorMessage));
    }

    piwikHelpers.trackGoal(3);
    piwikHelpers.trackEvent(DE_KONTOUMSAETZE, DE_ACCOUNT_REVENUE_ASSIGNED);
  };

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    setJokerValue('');
    setInputValue('');
    setIsInputInvalid(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.valueAsNumber);
  };

  const handleJokerValueChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setJokerValue(e.target.value);

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <ModalHeader headerVariant={MODAL_HEADER_VARIANT.SMALL} onClose={handleClose} withCloseButton>
        {t('bank_transfers.assigment.modal.title')}
      </ModalHeader>
      {isFetching ? (
        <Loading />
      ) : (
        <>
          <ModalBody>
            <BankTransferDetails
              bankTransfer={bankTransfer}
              resource={resource}
              resourceType={resourceType}
            />
            <Options
              handleJokerValueChange={handleJokerValueChange}
              handleInputChange={handleInputChange}
              handleOptionChange={handleOptionChange}
              isPartlyPaid={isPartlyPaid}
              isJoker={isJoker}
              inputValue={inputValue as string}
              jokerValue={jokerValue}
              selectedOption={selectedOption}
              isInputInvalid={isInputInvalid}
              isInvoice={isInvoice}
              discountGrossValue={resource?.discountGrossValue}
            />
            <Summary amount={diff} isExpense={isExpense} />
          </ModalBody>
          <Actions>
            <ActionButton
              appearance="outlined"
              onClick={handleClose}
              label={t('bank_transfers.assigment.modal.cancel')}
            />
            <ActionButton
              appearance="primary"
              onClick={handleOnAccept}
              disabled={isEmpty(selectedOption) || (isJoker && !isJokerFilled)}
              label={t(
                `bank_transfers.assigment.modal.submit.${isInvoice ? 'invoice' : 'contract'}`
              )}
              dataId="submit-assignment"
            />
          </Actions>
        </>
      )}
    </Modal>
  );
};

export default withContext(UnassignedBankTransferAmountConsumer, AssignmentModal);
