import styled from 'styled-components';

import ModalComponent from 'components/Modal';
import NotificationComponent from 'components/Notification';

export const Modal = styled(ModalComponent)`
  max-width: 660px;
  width: 100%;
`;

export const ModalBody = styled.div`
  padding: 10px 17px 30px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 64px;

  > *:not(:last-child) {
    margin-right: 20px;
  }
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 45px;
`;

export const Notification = styled(NotificationComponent)`
  max-width: 100%;
  margin: 20px 0 0 0;
`;
