import React from 'react';
import camelCase from 'lodash/camelCase';
import { shape } from 'prop-types';

import { DATEV_CLIENTS_COLUMNS } from 'constants/datev';
import tableStyles from 'shared/styles/table.module.css';

import InvoiceColumn from '../../InvoiceColumn';

const ClientRow = ({ client }) => (
  <tr data-id="Datev:new-export-table-row">
    {DATEV_CLIENTS_COLUMNS.map(({ column }) => (
      <InvoiceColumn key={column} className={tableStyles.cell}>
        {client[camelCase(column)]}
      </InvoiceColumn>
    ))}
  </tr>
);

ClientRow.propTypes = {
  client: shape({}).isRequired,
};

export default ClientRow;
