import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface ClientPageLinkProps {
  isCreationMode: boolean;
  to: string;
  dataId?: string;
  className?: string;
}

/**
 * Wrapper component for tab on client page.
 * If page is in client creation mode then tabs shouldn't be displayed as links.
 * If page is in client edit mode then tabs should be links (there are links to client defaults).
 * This component will wrap "children" in either div or Link, depending on isCreationMode prop.
 */
const ClientPageLink: FunctionComponent<ClientPageLinkProps> = ({
  children,
  isCreationMode,
  to,
  dataId = '',
  className = '',
}) => {
  if (!isCreationMode)
    return (
      <Link to={to} role="link" data-id={dataId} className={className}>
        {children}
      </Link>
    );

  return (
    <div data-id={dataId} className={className}>
      {children}
    </div>
  );
};

export default ClientPageLink;
