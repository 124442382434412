import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose, withState } from 'recompose';

import {
  deleteProductCatalogGroup,
  fetchProductCatalogGroups,
  sort,
} from 'actions/product-catalog/groups/groups';
import {
  clickCreateProductCatalogGroup as clickCreateProductCatalogGroupAction,
  clickEditProductCatalogGroup as clickEditProductCatalogGroupAction,
} from 'actions/product-catalog/groups/piwik';
import { PRODUCT_CATALOG_GROUPS } from 'constants/product-catalog/groups';
import { Resources } from 'constants/resources';
import buttonStyles from 'containers/OutgoingInvoices/Table/Table.module.css';
import { makeGetList } from 'reducers/common/resource';
import paths from 'routes/paths';
import CellContentWithTooltip from 'components/CellContentWithTooltip/CellContentWithTooltip';
import I18n from 'components/I18n';
import ActionDelete from 'components/IndexActionsContainer/actionIcons/ActionDelete';
import ActionEdit from 'components/IndexActionsContainer/actionIcons/ActionEdit';
import IndexActionsContainer from 'components/IndexActionsContainer/IndexActionsContainer';
import { ConfirmationModal } from 'components/Modal';
import {
  Body as TableBody,
  Cell,
  Header as TableHeader,
  Table,
  TableHeader as HeaderCell,
  TableRow,
} from 'components/Table';

class ProductCatalogGroupsTable extends Component {
  static propTypes = {
    fetch: func.isRequired,
    isFetching: bool,
    data: arrayOf(shape({})),
    params: shape({
      pagination: shape({}),
      filters: shape({}),
      sorting: shape({
        column: string,
      }),
    }),
    sort: func.isRequired,
    isDeleting: bool.isRequired,
    currentEntity: shape({
      id: string,
    }),
    delete: func.isRequired,
    setDeletionModal: func.isRequired,
    setCurrentEntity: func.isRequired,
    push: func.isRequired,
    clickCreateProductCatalogGroup: func.isRequired,
    clickEditProductCatalogGroup: func.isRequired,
  };

  componentDidMount() {
    if (!this.props.params.sorting.column) {
      this.props.sort('name');
    }
  }

  editEntity = (entity) => () => {
    this.props.push(paths.editProductCatalogGroup(entity.id));
    this.props.clickEditProductCatalogGroup();
  };
  createEntity = () => {
    this.props.push(paths.newProductCatalogGroup);
    this.props.clickCreateProductCatalogGroup();
  };
  deleteEntity = async () => {
    await this.props.delete(this.props.currentEntity.id);
    this.props.fetch();
    this.props.setDeletionModal(false);
  };

  showDeletionConfirmation = (entity) => () => {
    this.props.setCurrentEntity(entity);
    this.props.setDeletionModal(true);
  };

  renderRow = (object = {}) => (
    <TableRow key={object.id}>
      <Cell>
        <CellContentWithTooltip>{object.name}</CellContentWithTooltip>
      </Cell>
      <Cell>
        <CellContentWithTooltip>{object.itemsCount}</CellContentWithTooltip>
      </Cell>
      <Cell>
        <IndexActionsContainer>
          <ActionEdit onClick={this.editEntity(object)} tabIndex="0" visible />
          <ActionDelete onClick={this.showDeletionConfirmation(object)} tabIndex="0" visible />
        </IndexActionsContainer>
      </Cell>
    </TableRow>
  );

  render() {
    const { isFetching, params, data, isDeleting, setDeletionModal } = this.props;
    const isEmpty = Boolean(!data.length);

    return (
      <Fragment>
        <ConfirmationModal
          dangerousAction
          isOpen={isDeleting}
          onClose={() => setDeletionModal(false)}
          onConfirm={this.deleteEntity}
        >
          <I18n t="product_catalog.product_catalog_groups.delete_confirmation_message" />
        </ConfirmationModal>
        <Table
          isFetching={isFetching}
          fetch={this.props.fetch}
          resource={Resources.PRODUCT_CATALOG_GROUPS}
          params={params}
          sort={this.props.sort}
          isEmpty={isEmpty}
          emptyTableComponent={<NewProductCatalogGroupButton onClick={this.createEntity} />}
        >
          <TableHeader>
            <TableRow>
              <HeaderCell column="name" sortable>
                <I18n t="tables.headers.name" />
              </HeaderCell>
              <HeaderCell column="itemsCount" sortable>
                <I18n t="tables.headers.items_count" />
              </HeaderCell>
              <HeaderCell width="12%">
                <I18n t="tables.headers.actions" />
              </HeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>{data.map(this.renderRow)}</TableBody>
        </Table>
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getList = makeGetList();

  return (state) => ({
    data: getList(state.productCatalogGroups[PRODUCT_CATALOG_GROUPS], PRODUCT_CATALOG_GROUPS),
    isFetching: state.productCatalogGroups[PRODUCT_CATALOG_GROUPS].isFetching,
    params: state.productCatalogGroups[PRODUCT_CATALOG_GROUPS].params,
  });
};

const mapDispatchToProps = {
  fetch: fetchProductCatalogGroups,
  delete: deleteProductCatalogGroup,
  sort,
  push,
  clickCreateProductCatalogGroup: clickCreateProductCatalogGroupAction,
  clickEditProductCatalogGroup: clickEditProductCatalogGroupAction,
};

const enhance = compose(
  withState('isDeleting', 'setDeletionModal', false),
  withState('currentEntity', 'setCurrentEntity', null),
  connect(makeMapStateToProps, mapDispatchToProps)
);

export const NewProductCatalogGroupButton = ({ onClick }) => (
  <button className={buttonStyles.newInvoice} onClick={onClick} type="button" data-id="addEntity">
    <I18n t="product_catalog.product_catalog_groups.create_new" />
  </button>
);

NewProductCatalogGroupButton.propTypes = {
  onClick: func.isRequired,
};

export default enhance(ProductCatalogGroupsTable);
