import React, { Component } from 'react';
import { extend, forOwn, get, keys, mapKeys, throttle } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import colors from 'shared/styles/charts.module.css';
import { formatMoney, t } from 'shared/utils';
import { isSmUp } from 'shared/utils/breakpoints';

import Legend from './Legend';

import styles from './Chart.module.css';

function getMonthViewport() {
  if (isSmUp()) return t('dashboard.revenue_cost.months', { returnObjects: true });
  return t('dashboard.revenue_cost.months_abrv', { returnObjects: true });
}

export const transformChartEntries = (entries, { range = 12 } = {}) =>
  keys(entries)
    .sort()
    .slice(-range)
    .map((id) => {
      const object = {};
      forOwn(entries[id].data.attributes, (value, key) => {
        const temp = mapKeys(value, (_v, k) => key + k);
        extend(object, {
          month: getMonthViewport()[moment(id, 'YYYY-MM').month()],
          ...temp,
          id,
        });
        return temp;
      });
      return object;
    });

export class Chart extends Component {
  static propTypes = {
    data: PropTypes.shape({}),
    monthsRange: PropTypes.number,
    dataId: PropTypes.string,
  };

  state = {
    revenue: false,
    // TODO use hook when component will be a function
    isSmUp: isSmUp(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.compareSmUp);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.compareSmUp);
  }

  compareSmUp = throttle(() => {
    const isSmUpNow = isSmUp();
    if (this.state.isSmUp !== isSmUpNow) {
      this.setState({ isSmUp: isSmUpNow });
    }
  }, 50);

  handleHover = (value) => {
    this.setState({ revenue: value });
  };

  renderTooltip = ({ payload = [{}] }) => {
    const data = get(payload && payload[0], 'payload');
    if (!data) {
      return null;
    }
    const { revenue } = this.state;
    if (revenue) {
      return (
        <div className={styles.label}>
          <div className={styles.sum}>{formatMoney(data.revenuessum)}</div>
          <div className={styles.revenueUnpaid}>{formatMoney(data.revenuesunpaid)}</div>
          <div className={styles.revenuePaid}>{formatMoney(data.revenuespaid)}</div>
          <div className={styles.recurringRevenues}>
            {formatMoney(data['revenuescontract-revenues'])}
          </div>
          <div className={styles.revenueCashTransactions}>
            {formatMoney(data['revenuesrevenue-cash-transactions'])}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.label}>
        <div className={styles.sum}>{formatMoney(data.expensessum)}</div>
        <div className={styles.expensesUnpaid}>{formatMoney(data.expensesunpaid)}</div>
        <div className={styles.expensesPaid}>{formatMoney(data.expensespaid)}</div>
        <div className={styles.recurringExpenses}>
          {formatMoney(data['expensescontract-expenses'])}
        </div>
        <div className={styles.expenseCashTransactions}>
          {formatMoney(data['expensesexpense-cash-transactions'])}
        </div>
      </div>
    );
  };

  render() {
    const { data, monthsRange, dataId } = this.props;

    return (
      <div className={styles.main}>
        <div className={styles.chart} data-id={dataId}>
          <ResponsiveContainer width="100%" debounce={50}>
            <BarChart
              stackOffset="sign"
              data={transformChartEntries(data, { range: monthsRange })}
              barSize={this.state.isSmUp ? 20 : 10}
              barGap={this.state.isSmUp ? 13 : 5}
              margin={{ top: 30, right: 20, left: 20, bottom: 5 }}
            >
              <XAxis
                dataKey="month"
                stroke="rgb(155, 155, 155)"
                axisLine={false}
                tickLine={false}
              />
              <YAxis tickLine={false} axisLine={false} stroke="rgb(155, 155, 155)" />
              <CartesianGrid vertical={false} strokeWidth={1} />
              <Tooltip cursor={false} animationDuration={300} content={this.renderTooltip} />
              <Bar
                onMouseEnter={() => this.handleHover(false)}
                dataKey="expensesexpense-cash-transactions"
                stackId="exp"
                isAnimationActive={false}
                fill={colors['expense-cash-transactions-color']}
              />
              <Bar
                onMouseEnter={() => this.handleHover(false)}
                dataKey="expensescontract-expenses"
                stackId="exp"
                isAnimationActive={false}
                fill={colors['recurring-expenses-color']}
              />
              <Bar
                onMouseEnter={() => this.handleHover(false)}
                dataKey="expensespaid"
                stackId="exp"
                isAnimationActive={false}
                fill={colors['expenses-paid-color']}
              />
              <Bar
                onMouseEnter={() => this.handleHover(false)}
                dataKey="expensesunpaid"
                stackId="exp"
                isAnimationActive={false}
                fill={colors['expenses-unpaid-color']}
              />
              <Bar
                onMouseEnter={() => this.handleHover(true)}
                dataKey="revenuesrevenue-cash-transactions"
                stackId="rev"
                isAnimationActive={false}
                fill={colors['revenue-cash-transactions-color']}
              />
              <Bar
                onMouseEnter={() => this.handleHover(true)}
                dataKey="revenuescontract-revenues"
                stackId="rev"
                isAnimationActive={false}
                fill={colors['recurring-revenues-color']}
              />
              <Bar
                onMouseEnter={() => this.handleHover(true)}
                dataKey="revenuespaid"
                stackId="rev"
                isAnimationActive={false}
                fill={colors['revenues-paid-color']}
              />
              <Bar
                onMouseEnter={() => this.handleHover(true)}
                dataKey="revenuesunpaid"
                stackId="rev"
                isAnimationActive={false}
                fill={colors['revenues-unpaid-color']}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <Legend />
      </div>
    );
  }
}

export default Chart;
