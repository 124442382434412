import React from 'react';
import InlineSvg from 'react-inlinesvg';
import { bool } from 'prop-types';

import existIcon from 'images/exist.svg';
import notExistIcon from 'images/not-exist.svg';

const Checkmark = ({ isPremium }) =>
  isPremium ? (
    <span>
      <InlineSvg src={existIcon} />
    </span>
  ) : (
    <span>
      <InlineSvg src={notExistIcon} />
    </span>
  );

Checkmark.propTypes = {
  isPremium: bool,
};

export default Checkmark;
