import { Link as BaseLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import BaseIconArrowRedirect from 'redesign/components/atoms/Icons/ArrowRedirect';

const base = css`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey['070']};
`;

export const Title = styled.h3`
  ${base}
  margin-bottom: ${({ theme }) => theme.space['1']};
`;

export const IconArrowRedirect = styled(BaseIconArrowRedirect)`
  width: 24px;
  color: ${({ theme }) => theme.colors.vrblue['100']};
  margin-left: ${({ theme }) => theme.space['2']};
`;

export const Link = styled(BaseLink)`
  ${base}
  display: inline-flex;
  align-items: center;

  &:hover,
  &:hover ${IconArrowRedirect} {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.vrblue['075']};
  }

  &:active,
  &:active ${IconArrowRedirect} {
    color: ${({ theme }) => theme.colors.vrblue['050']};
  }
`;
