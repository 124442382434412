import { connect } from 'react-redux';

import {
  deleteOrderConfirmationLineItem,
  updateOrderConfirmationLineItem,
} from 'actions/order-confirmation/line-items';
import { TYPE_ORDER_CONFIRMATION } from 'constants/row-types';
import { orderConfirmationSelector } from 'reducers/form';
import { productCatalogItemsEnabledHelper } from 'routes/accesses';

import EditItemRow from '../LineItems/EditItemRow';

const mapStateToProps = (state) => ({
  orderConfirmationId: state.orderConfirmation.details.id,
  lineCategories: state.orderConfirmation.lineCategories,
  insertedAsGross: state.orderConfirmation.details.insertedAsGross,
  smallEntrepreneur: state.company.details.smallEntrepreneur,
  productCatalogItemsEnabled: productCatalogItemsEnabledHelper(state),
  invoiceLineCategoryId: orderConfirmationSelector(state, 'invoiceLineCategoryId'),
});

const mapDispatchToProps = (dispatch) => ({
  deleteLineItem: (...args) => dispatch(deleteOrderConfirmationLineItem(...args)),
  updateLineItem: (...args) => dispatch(updateOrderConfirmationLineItem(...args)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { lineItem: { id: lineItemId } = {} } = ownProps;
  const { orderConfirmationId, productCatalogItemsEnabled, smallEntrepreneur, insertedAsGross } =
    stateProps;
  const { deleteLineItem, updateLineItem } = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,
    smallEntrepreneur,
    invoiceInsertedAsGross: insertedAsGross,
    translationPath: 'features.order_confirmation',
    rowType: TYPE_ORDER_CONFIRMATION,
    cells: [
      {
        name: 'position',
        type: productCatalogItemsEnabled ? 'positionSelect' : 'positionInput',
      },
      { name: 'quantity', type: 'quantity' },
      { name: 'unit', type: 'unitSelect' },
      { type: insertedAsGross ? 'grossLinked' : 'netLinked' },
      { name: 'vat', type: 'select' },
      {
        name: insertedAsGross ? 'totalGrossAmount' : 'totalNetAmount',
        type: 'text',
        textType: 'currency',
      },
    ],
    extendedRowCells: [
      {
        name: 'itemNumber',
        type: productCatalogItemsEnabled ? 'itemNumberSelect' : 'itemNumber',
      },
      { name: 'description', type: 'counterInput' },
      { name: 'internalNotes', type: 'counterInput' },
    ],
    updateLineItem: (formValues) => updateLineItem(orderConfirmationId, lineItemId, formValues),
    deleteLineItem: (values) => deleteLineItem(orderConfirmationId, lineItemId, values),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditItemRow);
