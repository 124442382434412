import React, { memo, SVGProps } from 'react';

const WelcomeEvening = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 311 154" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <linearGradient x1="50%" y1=".55%" x2="95.383%" y2="61.818%" id="Welcome-evening_svg__a">
        <stop stopColor="#EEE" offset="0%" />
        <stop stopColor="#EDEDED" offset="38.556%" />
        <stop stopColor="#D8D8D8" offset="100%" />
      </linearGradient>
      <linearGradient x1="59.542%" y1="70.838%" x2="0%" y2="19.361%" id="Welcome-evening_svg__b">
        <stop stopColor="#090547" offset="0%" />
        <stop stopColor="#3FCBFF" offset="100%" />
      </linearGradient>
      <linearGradient x1="55.26%" y1="56.818%" x2="0%" y2="41.856%" id="Welcome-evening_svg__c">
        <stop stopColor="#0066B3" offset="0%" />
        <stop stopColor="#3FCBFF" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="Welcome-evening_svg__d">
        <stop stopColor="#09BCF6" offset="0%" />
        <stop stopColor="#0487EA" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(168.376)">
        <ellipse
          fill="url(#Welcome-evening_svg__a)"
          cx={54.475}
          cy={54.174}
          rx={54.475}
          ry={54.174}
        />
        <ellipse fill="#FAFAFA" cx={65.039} cy={34.474} rx={19.479} ry={19.371} />
        <ellipse fill="#FAFAFA" cx={28.063} cy={56.8} rx={12.876} ry={12.805} />
        <ellipse fill="#FAFAFA" cx={54.475} cy={85.693} rx={7.593} ry={7.552} />
      </g>
      <g fill="#C7E8FB">
        <path d="M246.52 28.812h24.247M246.52 39.992h24.247M246.52 34.402h17.243" />
      </g>
      <path fill="#FFEDBA" d="M242.409 118.996l-84.147 26.09-12.58-40.164 84.146-26.09z" />
      <path
        stroke="#FBB900"
        strokeWidth={1.275}
        d="M242.409 118.996l-84.147 26.09-12.58-40.164 84.146-26.09z"
      />
      <path fill="#FFBF3A" d="M238.446 117.364l-78.38 24.301-10.946-34.948 78.38-24.301z" />
      <path
        stroke="#FBB900"
        strokeWidth={1.184}
        d="M238.446 117.364l-78.38 24.301-10.946-34.948 78.38-24.301z"
      />
      <path
        d="M210.338 107.05c2.798 8.93-2.211 18.427-11.187 21.21-8.976 2.783-18.52-2.201-21.318-11.131-2.797-8.93 2.212-18.426 11.188-21.21 8.976-2.782 18.52 2.201 21.317 11.132"
        fillOpacity={0.5}
        fill="#FFF"
      />
      <path
        d="M210.338 107.05c2.798 8.93-2.211 18.427-11.187 21.21-8.976 2.783-18.52-2.201-21.318-11.131-2.797-8.93 2.212-18.426 11.188-21.21 8.976-2.782 18.52 2.201 21.317 11.132z"
        stroke="#FFD760"
        strokeWidth={1.184}
      />
      <path
        d="M176.26 117.616c1.128 3.601-.891 7.43-4.51 8.552-3.62 1.123-7.469-.887-8.596-4.488-1.128-3.6.891-7.43 4.51-8.552 3.62-1.122 7.469.887 8.597 4.488"
        fill="#FFEDBA"
      />
      <path
        d="M176.26 117.616c1.128 3.601-.891 7.43-4.51 8.552-3.62 1.123-7.469-.887-8.596-4.488-1.128-3.6.891-7.43 4.51-8.552 3.62-1.122 7.469.887 8.597 4.488z"
        stroke="#FFD760"
        strokeWidth={0.5}
      />
      <path
        d="M226.329 102.093c1.128 3.6-.892 7.43-4.511 8.551-3.62 1.123-7.468-.886-8.596-4.488-1.128-3.6.892-7.43 4.511-8.552 3.62-1.122 7.468.888 8.596 4.489"
        fillOpacity={0.5}
        fill="#FFF"
      />
      <path
        d="M226.329 102.093c1.128 3.6-.892 7.43-4.511 8.551-3.62 1.123-7.468-.886-8.596-4.488-1.128-3.6.892-7.43 4.511-8.552 3.62-1.122 7.468.888 8.596 4.489z"
        stroke="#FFD760"
        strokeWidth={0.5}
      />
      <g>
        <path fill="#FFEDBA" d="M152.266 148.287h88.138v-42.069h-88.138z" />
        <path stroke="#FBB900" strokeWidth={1.275} d="M152.266 148.287h88.138v-42.069h-88.138z" />
        <path fill="#FFBF3A" d="M155.011 145.556h82.098V108.95H155.01z" />
        <path stroke="#FBB900" strokeWidth={1.184} d="M155.011 145.556h82.098V108.95H155.01z" />
        <path
          d="M213.358 127.39c0 9.353-7.621 16.936-17.023 16.936s-17.024-7.583-17.024-16.937c0-9.354 7.622-16.937 17.024-16.937 9.402 0 17.023 7.583 17.023 16.937"
          fillOpacity={0.5}
          fill="#FFF"
        />
        <path
          d="M213.358 127.39c0 9.353-7.621 16.936-17.023 16.936s-17.024-7.583-17.024-16.937c0-9.354 7.622-16.937 17.024-16.937 9.402 0 17.023 7.583 17.023 16.937z"
          stroke="#FFD760"
          strokeWidth={1.184}
        />
        <path
          d="M177.664 127.39c0 3.771-3.073 6.829-6.865 6.829-3.79 0-6.864-3.058-6.864-6.83 0-3.772 3.074-6.83 6.864-6.83 3.792 0 6.865 3.058 6.865 6.83"
          fill="#FFEDBA"
        />
        <path
          d="M177.664 127.39c0 3.771-3.073 6.829-6.865 6.829-3.79 0-6.864-3.058-6.864-6.83 0-3.772 3.074-6.83 6.864-6.83 3.792 0 6.865 3.058 6.865 6.83z"
          stroke="#FFD760"
          strokeWidth={0.5}
        />
        <path
          d="M230.107 127.39c0 3.771-3.073 6.829-6.864 6.829s-6.865-3.058-6.865-6.83c0-3.772 3.074-6.83 6.865-6.83 3.79 0 6.864 3.058 6.864 6.83"
          fill="#FFEDBA"
        />
        <path
          d="M230.107 127.39c0 3.771-3.073 6.829-6.864 6.829s-6.865-3.058-6.865-6.83c0-3.772 3.074-6.83 6.865-6.83 3.79 0 6.864 3.058 6.864 6.83z"
          stroke="#FFD760"
          strokeWidth={0.5}
        />
      </g>
      <g>
        <path
          d="M98.42 49.148c-.151-1.408 2.424-24.669-6.283-21.075-8.707 3.594-10.75 13.772-16.094 6.83C70.7 27.964 42.821-10.804 41.56 2.918c-1.892 20.592-7.786 7.53-17.573 3.607-21.14-8.471-41.1 15.655-.39 30.33 40.71 14.677 12.736 14.882 12.736 14.882S19.336 67.3 57.481 68.383c38.144 1.084 40.049 8.85 40.049 8.85l.89-28.085z"
          fill="url(#Welcome-evening_svg__b)"
          transform="translate(12.546 59.88)"
        />
        <path
          d="M77.171 104.18c-.76-4.159-5.455-26.61-17.757-38.24M53.198 115.837c1.671.724 22.844-3.582 31.62-5.218M29.45 78.716s61.208 28.242 75.402 50.306"
          stroke="#155FAA"
          strokeWidth={1.378}
          strokeLinecap="round"
        />
      </g>
      <g>
        <path fill="#FFF" d="M141.19 134.696l-52.17 15.161-21.66-73.779 52.17-15.16z" />
        <path
          stroke="#E6E6E6"
          strokeWidth={0.66}
          d="M141.19 134.696l-52.17 15.161-21.66-73.779 52.17-15.16z"
        />
        <path
          stroke="#4D4D4D"
          strokeWidth={1.981}
          strokeLinecap="round"
          d="M79.472 98.592l14.436-4.196M80.296 101.4l14.437-4.195"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.732}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M75.163 83.914l5.725-1.664M75.5 85.066l6.757-1.964M75.839 86.217l5.725-1.664"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.887}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M92.619 143.373l8.393-2.439"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.818}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M92.957 144.525l5.725-1.664"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.887}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M93.295 145.676l8.393-2.439"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.818}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M104.695 139.864l7.149-2.078M105.033 141.015l8.437-2.452M105.37 142.166l7.15-2.077M115.22 136.805l7.15-2.077M115.558 137.956l6.534-1.898M115.896 139.108l7.15-2.078"
        />
        <path
          stroke="#D2D0D0"
          strokeWidth={0.732}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M76.177 87.368l6.756-1.963"
        />
        <path
          stroke="#4D4D4D"
          strokeWidth={1.981}
          strokeLinecap="round"
          d="M80.977 103.718L93.5 100.08M84.189 114.344l8.51-2.652M119.473 103.35l5.355-1.669M94.898 111.007l21.362-6.657M85.698 119.138l8.512-2.652M120.983 108.144l5.354-1.669M96.407 115.801l21.363-6.656M86.872 122.867l8.51-2.652M122.156 111.873l5.355-1.668M97.58 119.531l21.364-6.657M88.38 127.662l8.513-2.652M123.666 116.668L129.02 115M99.09 124.325l21.363-6.656"
        />
        <text
          fontFamily="FrutigerVR-Bold, Frutiger VR"
          fontSize={15.569}
          fontWeight="bold"
          fill="#EB5C0B"
          transform="translate(67.36 60.917)"
        >
          <tspan x={58.757} y={64.422}>
            {'\u20AC'}
          </tspan>
        </text>
        <path
          d="M111.89 72.278a3.97 3.97 0 01-2.521 5.03c-2.092.697-4.356-.427-5.056-2.509a3.97 3.97 0 012.522-5.03c2.092-.696 4.356.427 5.056 2.51"
          fill="#EB5C0B"
        />
        <path
          d="M117.918 70.273a3.97 3.97 0 01-2.521 5.03c-2.093.696-4.357-.427-5.056-2.509a3.97 3.97 0 012.521-5.03c2.093-.696 4.357.427 5.056 2.509"
          fill="#52B4E7"
        />
        <path
          d="M116.085 75.074a3.97 3.97 0 01-2.521 5.03c-2.093.696-4.357-.427-5.056-2.51a3.97 3.97 0 012.521-5.029c2.093-.696 4.357.427 5.056 2.509"
          fill="#0F2364"
        />
      </g>
      <g>
        <path
          d="M76.841 21.19C70.288 19.168 42.957-11.61 0 4.799c0 0 12.48 38.697 63.107 25.59 1.053.2 18.902-4.241 18.902-4.241L76.84 21.19z"
          fill="url(#Welcome-evening_svg__c)"
          transform="translate(46.254 119.72)"
        />
        <path
          d="M126.314 145.967s-7.82-1.02-18.445-2.989c-15.577-2.885-44.238-8.412-56.055-15.135M103.967 141.867c.201-.2-5.034-10.217-14.297-13.423M84.434 138.661s-9.464 4.809-22.15 2.204M77.185 124.237s5.583 5.246 4.503 12.84M66.715 133.653s-2.82-7.412-9.062-10.818M100.419 141.955s-6.722 5.722-18.602 8.326"
          stroke="#26295B"
          strokeWidth={0.976}
          strokeLinecap="round"
        />
      </g>
      <g>
        <path fill="#436BEE" d="M209.123 148.798h17.168v-45.784h-17.168z" />
        <path fill="#2747B0" d="M233.554 148.798h17.168V76.215h-17.168z" />
        <path fill="#0F2364" d="M257.985 148.798h17.168V90.37h-17.168z" />
      </g>
      <path
        stroke="#615D5C"
        strokeWidth={1.275}
        strokeLinecap="round"
        d="M168.605 148.586l113.672.146"
      />
      <g>
        <path
          d="M193.338 150.654h-66.251c-3.84 0-6.952-3.096-6.952-6.916V56.075c0-3.82 3.113-6.916 6.952-6.916h66.25c3.84 0 6.952 3.096 6.952 6.916v87.663c0 3.82-3.112 6.916-6.951 6.916"
          fill="#0F2364"
        />
        <path
          d="M70.745 29.482H8.962c-.993 0-1.797-.8-1.797-1.788v-16.24c0-.987.804-1.788 1.797-1.788h61.783c.992 0 1.797.8 1.797 1.788v16.24c0 .987-.805 1.788-1.797 1.788"
          fill="url(#Welcome-evening_svg__d)"
          transform="translate(120.135 49.159)"
        />
        <path
          d="M139.58 103.113h-10.852c-.7 0-1.268-.565-1.268-1.262v-7.11c0-.697.568-1.261 1.268-1.261h10.851c.7 0 1.268.564 1.268 1.261v7.11c0 .697-.568 1.262-1.268 1.262M156.803 103.113H145.95c-.7 0-1.268-.565-1.268-1.262v-7.11c0-.697.568-1.261 1.268-1.261h10.852c.7 0 1.267.564 1.267 1.261v7.11c0 .697-.567 1.262-1.267 1.262M174.026 103.113h-10.852c-.7 0-1.267-.565-1.267-1.262v-7.11c0-.697.567-1.261 1.267-1.261h10.852c.7 0 1.267.564 1.267 1.261v7.11c0 .697-.567 1.262-1.267 1.262"
          fill="#0487EA"
        />
        <path
          d="M144.605 88.139h-16.083a1.06 1.06 0 01-1.062-1.057V84.22a1.06 1.06 0 011.062-1.058h16.083a1.06 1.06 0 011.062 1.058v2.862a1.06 1.06 0 01-1.062 1.057"
          fill="#E54065"
        />
        <path
          d="M168.03 88.139h-16.083a1.06 1.06 0 01-1.062-1.057V84.22a1.06 1.06 0 011.062-1.058h16.082a1.06 1.06 0 011.063 1.058v2.862a1.06 1.06 0 01-1.063 1.057"
          fill="#FBB900"
        />
        <path
          d="M191.454 88.139h-16.082a1.06 1.06 0 01-1.062-1.057V84.22a1.06 1.06 0 011.062-1.058h16.082a1.06 1.06 0 011.063 1.058v2.862a1.06 1.06 0 01-1.063 1.057"
          fill="#C7E8FB"
        />
        <path
          d="M191.454 88.139h-16.082a1.06 1.06 0 01-1.062-1.057V84.22a1.06 1.06 0 011.062-1.058h16.082a1.06 1.06 0 011.063 1.058v2.862a1.06 1.06 0 01-1.063 1.057z"
          stroke="#C7E8FB"
          strokeWidth={0.5}
        />
        <path
          d="M190.086 128.905h-8.525a2.425 2.425 0 01-2.431-2.418V95.898a2.425 2.425 0 012.43-2.418h8.526a2.425 2.425 0 012.43 2.418v30.589a2.425 2.425 0 01-2.43 2.418"
          fill="#FBB900"
        />
        <path
          d="M139.58 116.108h-10.852c-.7 0-1.268-.565-1.268-1.261v-7.11c0-.697.568-1.262 1.268-1.262h10.851c.7 0 1.268.565 1.268 1.261v7.11c0 .697-.568 1.262-1.268 1.262M156.803 116.108H145.95c-.7 0-1.268-.565-1.268-1.261v-7.11c0-.697.568-1.262 1.268-1.262h10.852c.7 0 1.267.565 1.267 1.261v7.11c0 .697-.567 1.262-1.267 1.262M174.026 116.108h-10.852c-.7 0-1.267-.565-1.267-1.261v-7.11c0-.697.567-1.262 1.267-1.262h10.852c.7 0 1.267.565 1.267 1.261v7.11c0 .697-.567 1.262-1.267 1.262M139.58 129.103h-10.852c-.7 0-1.268-.565-1.268-1.261v-7.11c0-.697.568-1.262 1.268-1.262h10.851c.7 0 1.268.565 1.268 1.261v7.11c0 .697-.568 1.262-1.268 1.262M156.803 129.103H145.95c-.7 0-1.268-.565-1.268-1.261v-7.11c0-.697.568-1.262 1.268-1.262h10.852c.7 0 1.267.565 1.267 1.261v7.11c0 .697-.567 1.262-1.267 1.262M174.026 129.103h-10.852c-.7 0-1.267-.565-1.267-1.261v-7.11c0-.697.567-1.262 1.267-1.262h10.852c.7 0 1.267.565 1.267 1.261v7.11c0 .697-.567 1.262-1.267 1.262"
          fill="#0487EA"
        />
        <path
          d="M156.111 142.098h-26.736a1.91 1.91 0 01-1.915-1.906v-5.821a1.91 1.91 0 011.915-1.906h26.736a1.91 1.91 0 011.915 1.906v5.821a1.91 1.91 0 01-1.915 1.906"
          fill="#FBB900"
        />
        <path
          d="M174.026 142.098h-10.852c-.7 0-1.267-.565-1.267-1.261v-7.11c0-.697.567-1.262 1.267-1.262h10.852c.7 0 1.267.565 1.267 1.261v7.111c0 .696-.567 1.26-1.267 1.26"
          fill="#0066B3"
        />
        <path
          d="M191.25 142.098h-10.853c-.7 0-1.267-.565-1.267-1.261v-7.11c0-.697.567-1.262 1.267-1.262h10.852c.7 0 1.268.565 1.268 1.261v7.111c0 .696-.568 1.26-1.268 1.26"
          fill="#EB5C0B"
        />
      </g>
      <g stroke="#979797" strokeLinecap="round" strokeWidth={2}>
        <path d="M286.95 12.572l-1.15 1.99M284.487 16.837l-1.15 1.99M288.46 17.516l-1.99-1.149M284.195 15.054l-1.99-1.15" />
      </g>
      <g stroke="#979797" strokeLinecap="round" strokeWidth={2}>
        <path d="M146.041 11.983l-2.263.4M141.191 12.838l-2.263.4M143.225 16.319l-.399-2.263M142.37 11.469l-.399-2.263" />
      </g>
      <g stroke="#979797" strokeLinecap="round" strokeWidth={2}>
        <path d="M84.313 20.565l-.97-2.083M82.232 16.101l-.971-2.082M79.397 18.967l2.083-.971M83.86 16.886l2.083-.971" />
      </g>
      <g stroke="#979797" strokeLinecap="round" strokeWidth={2}>
        <path d="M115.932 36.212l-1.149 1.99M113.47 40.477l-1.15 1.99M117.443 41.156l-1.99-1.15M113.178 38.693l-1.99-1.149" />
      </g>
      <g stroke="#979797" strokeLinecap="round" strokeWidth={2}>
        <path d="M43.96 30.958l-1.15 1.99M41.497 35.224l-1.149 1.99M45.471 35.902l-1.99-1.149M41.206 33.44l-1.99-1.15" />
      </g>
    </g>
  </svg>
);

export default memo(WelcomeEvening);
