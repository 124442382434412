import { isTest } from 'shared/utils/environment';

const getImpersonateToken = () => {
  if (isTest) {
    // We don't want to apply JSDOM to our every test, it slows tests down greatly
    return null;
  }

  const rootElement = document.getElementById('pbw-react-root');

  if (!rootElement) {
    return null;
  }

  const key = rootElement.getAttribute('data-token');

  return key;
};

export default getImpersonateToken;
