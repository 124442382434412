import React, { memo } from 'react';
import cx from 'classnames';

import { t } from 'shared/utils';

import IconBankAccount from './IconBankAccount';

import styles from './WelcomeTile.module.css';

const BankAccount = () => (
  <>
    <IconBankAccount className={cx(styles.icon, styles.iconBankAccount)} />
    <div className={styles.header}>
      2. {t('contracts.welcome_tile.columns.bank_account.header')}
    </div>
    <div className={styles.description}>
      {t('contracts.welcome_tile.columns.bank_account.description')}
    </div>
  </>
);

export default memo(BankAccount);
