import React, { memo } from 'react';

import { SUPPORT_LINK } from 'constants/custom-links';
import { t } from 'shared/utils';
import IconHelp from 'redesign/components/atoms/Icons/Help';

import { trackEventHandler } from '../utils';
import { Link, MenuItem } from './Shared.styled';

const Help = () => {
  return (
    <MenuItem>
      <Link
        $level={1}
        as="a"
        href={SUPPORT_LINK}
        target="_blank"
        rel="noopener noreferrer"
        data-id="help"
        onClick={trackEventHandler('help')}
      >
        <IconHelp />
        {t('redesign.organisms.navigation.help')}
      </Link>
    </MenuItem>
  );
};

export default memo(Help);
