import React from 'react';

import { TBankDetails } from '../../types';
import * as SC from './BankListItem.styled';

const BankListItem = ({ item }: { item: TBankDetails }) => {
  return (
    <>
      <SC.BankName>{item?.name}</SC.BankName>
      {item?.iban && (
        <SC.SelectBankItem>
          {item?.iban} • {item?.bankName}
        </SC.SelectBankItem>
      )}
    </>
  );
};

export default BankListItem;
