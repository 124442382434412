import React, { InputHTMLAttributes } from 'react';

import { Checkmark, Circle, Input, Wrapper } from './Radio.styled';

type RadioProps = InputHTMLAttributes<HTMLInputElement>;

const Radio = ({ className, onClick, ...props }: RadioProps) => {
  return (
    <Wrapper className={className}>
      <Input {...props} type="radio" />
      <Checkmark onClick={onClick}>
        <Circle />
      </Checkmark>
    </Wrapper>
  );
};

export default Radio;
