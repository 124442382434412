import React, { ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';
import { format, parse } from 'date-fns';
import { reduxForm } from 'redux-form';

import { addCustomTask, updateCustomTask } from 'actions/customer-notifications';
import { DATE_FORMAT_FULL_REVERSED_UNICODE, DATE_FORMAT_UNICODE } from 'constants/datetime';
import { t } from 'shared/utils';
import { CustomTask } from 'types/entities/Task';
import Button from 'components/Button';
import { DateField, TextField } from 'components/Form';
import FormField from 'components/Form/FormField';
import Modal from 'components/Modal';

import { trackEvent } from '../utils';

import styles from './CustomTaskCreator.module.css';

type DueDate = string | null;

type FormData = {
  description: string;
  dueDate: DueDate;
};

type OwnProps = {
  handleClose: () => void;
};

type CustomTaskCreatorContextType = {
  open: null | (() => void);
  close: null | (() => void);
  setEditedTask: null | ((task: CustomTask) => void);
};

export const CustomTaskCreatorContext = React.createContext<CustomTaskCreatorContextType>({
  open: null,
  close: null,
  setEditedTask: null,
});

const formatDueDateToUnicode = (dueDate: string) =>
  format(parse(dueDate, DATE_FORMAT_UNICODE, new Date()), DATE_FORMAT_FULL_REVERSED_UNICODE);

const Form = reduxForm<FormData, OwnProps>({
  form: 'CustomTaskCreator',
  enableReinitialize: true,
})(({ handleSubmit, handleClose, initialValues }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onAdd = (values: FormData) => {
    setIsLoading(true);
    const task = {
      ...values,
      dueDate: values.dueDate ? formatDueDateToUnicode(values.dueDate) : '',
    };

    return (dispatch(addCustomTask(task)) as any)
      .then(() => {
        handleClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onEdit = (values: FormData) => {
    setIsLoading(true);
    const task = {
      ...initialValues,
      ...values,
      dueDate: values.dueDate ? formatDueDateToUnicode(values.dueDate) : '',
    };

    return (dispatch(updateCustomTask(task)) as any)
      .then(() => {
        handleClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    handleClose();
    trackEvent('New_Task_cancel');
  };

  return (
    <form className={styles.modalBody} onSubmit={handleSubmit(initialValues ? onEdit : onAdd)}>
      <div className={styles.subject}>
        <label className={styles.label} htmlFor="description">
          {t('sidebar.tasks.new_task.title')}
        </label>
        <FormField
          name="description"
          id="description"
          component={TextField}
          isLabelHidden
          placeholder={t('sidebar.tasks.new_task.title_placeholder')}
          dataId="description"
        />
      </div>
      <div className={styles.dateWrapper}>
        <label className={styles.label} htmlFor="dueDate">
          {t('sidebar.tasks.new_task.date')}
        </label>
        <FormField
          name="dueDate"
          id="dueDate"
          component={DateField}
          isLabelHidden
          placeholder={t('sidebar.tasks.new_task.date_placeholder')}
          dataIds={{
            input: 'due-date',
          }}
          className={styles.date}
          onChange={(_: Event, newValue: DueDate, previousValue: DueDate) => {
            if (newValue !== previousValue) {
              trackEvent('New_Task_datepicker');
            }
          }}
        />
      </div>

      <div className={styles.actions}>
        <Button
          appearance="outlined"
          onClick={handleCancel}
          label={t('sidebar.tasks.new_task.cancel')}
        />
        <Button
          label={t('sidebar.tasks.new_task.submit')}
          type="submit"
          dataId="submit"
          disabled={isLoading}
          onClick={() => trackEvent('New_Task_save')}
        />
      </div>
    </form>
  );
});

type CustomTaskCreatorProps = {
  children: ReactNode;
};

const CustomTaskCreator = ({ children }: CustomTaskCreatorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editedTask, setEditedTask] = useState<CustomTask | null>(null);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setEditedTask(null);
    trackEvent('New_Task_close');
  };

  return (
    <CustomTaskCreatorContext.Provider
      value={{
        open: handleOpen,
        close: handleClose,
        setEditedTask: (task: CustomTask) => {
          setEditedTask(task);
        },
      }}
    >
      <>
        {children}
        <Modal isOpen={isOpen} onRequestClose={handleClose} className={styles.modal}>
          <div className={styles.modalHeader}>
            {t(`sidebar.tasks.new_task.header.${editedTask ? 'edit' : 'add'}`)}
          </div>
          <Form
            initialValues={
              editedTask
                ? {
                    ...editedTask,
                    dueDate: editedTask.dueDate
                      ? format(new Date(editedTask.dueDate), DATE_FORMAT_UNICODE)
                      : null,
                  }
                : undefined
            }
            handleClose={handleClose}
          />
        </Modal>
      </>
    </CustomTaskCreatorContext.Provider>
  );
};

export default CustomTaskCreator;
