import ReactPiwik, { TrackingCalls } from 'react-piwik';
import { History } from 'history';

import {
  PIWIK_PUSH_EVENT,
  PIWIK_PUSH_GOAL,
  PIWIK_PUSH_INTERACTION,
  SET_PIWIK_IFRAME_SRC,
} from 'constants/piwik';
import { Store } from 'store';

const configurePiwikHistory = (history: History, store: Store) => {
  const rootElement = document.getElementById('pbw-react-root');

  if (!rootElement || !rootElement.hasAttribute('data-piwik-enabled')) return;

  const url = rootElement.getAttribute('data-piwik-url');
  const siteId = rootElement.getAttribute('data-piwik-site-id');
  const trackErrors = rootElement.hasAttribute('data-piwik-track-errors');
  const cookiesAccepted = localStorage.getItem('cookies') === 'true';

  if (!url || !siteId || !trackErrors || !cookiesAccepted) return;

  const piwik = new ReactPiwik({
    url,
    siteId,
    trackErrors,
  });
  const statisticCookiesAccepted = localStorage.getItem('cookies:statistic') === 'true';

  if (!statisticCookiesAccepted) {
    ReactPiwik.push(['disableCookies']);
  }

  piwik.connectToHistory(history);
  store.dispatch({
    type: SET_PIWIK_IFRAME_SRC,
    payload: rootElement.getAttribute('data-piwik-iframe-src'),
  });
};

export default configurePiwikHistory;

const pushPiwik = (calls: TrackingCalls) => {
  if (window.piwikSpy) window.piwikSpy(calls);

  try {
    ReactPiwik.push(calls);
    return true;
  } catch (error) {
    return false;
  }
};

const trackClick = (target: string) => pushPiwik([PIWIK_PUSH_INTERACTION, target, 'click']);

const trackEvent = (...args: TrackingCalls) => {
  pushPiwik([PIWIK_PUSH_EVENT, ...args]);
};

const trackEventHandlerCurried =
  (category: string) =>
  (...args: TrackingCalls) =>
  () =>
    trackEvent(category, ...args);

const trackEventCurried =
  (category: string) =>
  (...args: TrackingCalls) =>
    trackEvent(category, ...args);

const trackGoal = (id: number) => pushPiwik([PIWIK_PUSH_GOAL, id]);

// global scope as we use it outside the react app
export const piwikHelpers = {
  trackClick,
  trackEvent,
  trackEventHandlerCurried,
  trackEventCurried,
  trackGoal,
};
