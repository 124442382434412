import { reduxForm } from 'redux-form';

import Form from 'containers/RegistrationForm/Form/RegistrationForm';

import type { FormData, FormOwnProps } from './Form/types';
import validate from './validators';

export default reduxForm<FormData, FormOwnProps>({
  form: 'registration',
  enableReinitialize: true,
  persistentSubmitErrors: true,
  keepDirtyOnReinitialize: true,
  validate,
})(Form);
