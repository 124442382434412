import React from 'react';
import cx from 'classnames';

import { t } from 'shared/utils';

import styles from './OcrInfo.module.css';

const OcrInfo = () => (
  <div>
    <p className={styles.sectionText}>{t('expenses.upload.dropzone.max_site_count')}</p>
    <p className={cx(styles.sectionText, styles.qualitySuggestion)}>
      {t('expenses.upload.dropzone.quality_suggestion')}
    </p>
  </div>
);

export default OcrInfo;
