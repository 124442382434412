import styled from 'styled-components';

import Amount from 'redesign/components/atoms/Amount/Amount';
import { Breakpoints } from 'redesign/styles/breakpoints';

export const Row = styled.div`
  display: flex;
  padding: ${({ theme }) => `${theme.space[4]} 0`};

  * {
    margin-right: 16px;
    font-weight: bold;
  }
`;
export const Header = styled.h1`
  font-size: ${({ theme }) => theme.fontSize['xl']};
  color: ${({ theme }) => theme.colors.grey['080']};
`;

export const TableWrapper = styled.div`
  overflow-y: auto;

  th[data-id='header-name'] {
    width: 220px;
  }

  th[data-id='header-iban'] {
    width: 220px;
  }

  th[data-id='header-balance'] {
    width: 110px;
  }

  th[data-id='header-creditLine'] {
    width: 130px;
  }

  th[data-id='header-main'] {
    width: 140px;
    text-align: center;
  }

  th[data-id='header-actions'] {
    width: 110px;
  }

  td[data-id='cell-main'] {
    text-align: center;

    div {
      margin: 0 auto;
    }
  }

  @media (min-width: ${Breakpoints.md}) {
    th[data-id='header-name'] {
      width: 22%;
    }

    th[data-id='header-iban'] {
      width: 22%;
    }
  }
`;

export const AmountCreditLine = styled(Amount)`
  color: ${({ theme }) => theme.colors.grey['080']};
  font-weight: normal;
`;

export const InputWrapper = styled.div`
  margin: ${({ theme }) => `${theme.space[3]} 0`};
`;
