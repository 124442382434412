import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { hideNotification } from 'actions/notification';
import GenericNotification, {
  BankAccountNotification,
  ValidationErrorsNotification,
} from 'components/Notification';

import styles from './NotificationManager.module.css';

const Components = {
  GenericNotification,
  BankAccountNotification,
  ValidationErrorsNotification,
};

const NotificationManager = ({ notifications, onHide }) => (
  <div className={styles.main}>
    {notifications?.map(({ id, component, ...rest }) => {
      const Notification = component ? Components[component] : Components.GenericNotification;
      return <Notification key={id} hide={() => onHide(id)} {...rest} />;
    })}
  </div>
);

NotificationManager.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  onHide: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    notifications: state.notifications,
  }),
  (dispatch) => ({
    onHide: (id) => dispatch(hideNotification(id)),
  })
)(NotificationManager);
