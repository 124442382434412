import moment from 'moment';

import { DATE_FORMAT, DATE_FORMAT_FULL_REVERSED } from 'constants/datetime';

interface DateFilterMapperOptions {
  inputDateFormat?: string;
  parsedDateFormat?: string;
}

/**
 * Get string value and return formated date.
 * If input date is valid, then return formatted date.
 * If input date is invalid, then return empty string.
 * @param value Date as string
 * @param options Options object
 */
export const dateFilterMapper = (value: string, options?: DateFilterMapperOptions) => {
  const dateValue = moment(value, (options && options.inputDateFormat) || DATE_FORMAT, true);
  const parsedFilterDate = dateValue.isValid()
    ? dateValue.format((options && options.parsedDateFormat) || DATE_FORMAT_FULL_REVERSED)
    : '';

  return parsedFilterDate;
};
