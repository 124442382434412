import styled from 'styled-components';

export const Hint = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

export const HintTitle = styled(Hint)`
  font-weight: bold;
`;

export const Notice = styled(Hint)`
  margin-top: ${({ theme }) => theme.space[8]};
`;
