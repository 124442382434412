import { t } from 'shared/utils';

export const bankLoginSuccess = {
  duration: 3000,
  title: t('notifications.banks.login_success.title'),
  variant: 'success',
};

export const addAccountSuccess = {
  duration: 3000,
  title: t('notifications.banks.add_account_success.title'),
  variant: 'success',
};

export const addAccountsSuccess = {
  duration: 3000,
  title: t('notifications.banks.add_accounts_success.title'),
  variant: 'success',
};

export const bankTransfersSyncInBackground = {
  duration: 8000,
  title: t('notifications.banks.sync_in_background.title'),
  text: t('notifications.banks.sync_in_background.text'),
  variant: 'success',
};
